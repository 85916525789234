import React from 'react'

import {ModalLayout, ModalFooter, MutationGuard} from '../../components'
import type {AppError} from '../../types'
import {TrezorFailedSign} from '../hw/trezor'

import {TxErrorFooter, TxLoadingFooter, TxBodyLayout as BL} from './common'
import {useClearTxSubmissionAuthorization} from './signAuthorization'

type SignTrezorScreenProps = {
  onClose: () => void
  onBack: () => unknown
  onSign: () => Promise<unknown>
  signProps: {
    isPending: boolean
    error: AppError
    data?: unknown
  }
  ModalHeader: React.ReactNode
  ReadyContent: React.ReactNode
}

export function SignTrezorScreen({
  ModalHeader,
  ReadyContent,
  onBack,
  onClose,
  onSign,
  signProps,
}: SignTrezorScreenProps) {
  useClearTxSubmissionAuthorization(onSign)

  const {Body, Footer} = (() => {
    if (signProps.error) {
      return {
        Body: (
          <BL centered>
            <TrezorFailedSign onRetry={onSign} />
          </BL>
        ),
        Footer: <TxErrorFooter {...{onBack, onClose}} />,
      }
    }
    if (signProps.isPending) {
      return {
        Body: <BL>{ReadyContent}</BL>,
        Footer: <TxLoadingFooter action="signing" />,
      }
    }
    return {Body: null, Footer: null}
  })()

  return (
    <ModalLayout
      header={ModalHeader}
      body={Body}
      footer={
        <ModalFooter hasDivider>
          {Footer}
          <MutationGuard {...signProps} error={null} hideLoader />
        </ModalFooter>
      }
    />
  )
}
