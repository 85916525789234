import assert from 'assert'

import {connectSnap, getSnap, metamaskSnapRequest} from '@nufi/metamask-snap'
import type {IdentitySecret} from '@nufi/wallet-common'

import type {LocalProfileId, ProfileMetadata, ProfileName} from 'src/appStorage'
import MasterProfileManager from 'src/appStorage/masterProfileManager'
import {hasCloudSyncBackup} from 'src/features/profileSync/application'
import type {MetamaskContext} from 'src/metamaskSnap/MetamaskContext'
import type {Blockchain} from 'src/wallet'

export const initExistingMetamaskProfile = async (args: {
  profileId: LocalProfileId
  identitySecret: IdentitySecret
}): Promise<void> => {
  await MasterProfileManager.initRemoteProfile(args)
}

export const findMetamaskProfile = async ({
  identitySecret,
}: {
  identitySecret: IdentitySecret
}): Promise<ProfileMetadata | null> => {
  const profileMeta =
    await MasterProfileManager.findProfileByIdentitySecret(identitySecret)
  return profileMeta?.loginType === 'metamask' ? profileMeta : null
}

export const createNewMetamaskProfile = async ({
  identitySecret,
  enabledBlockchains,
}: {
  identitySecret: IdentitySecret
  enabledBlockchains: readonly Blockchain[]
}): Promise<ProfileMetadata> => {
  await MasterProfileManager.createNewRemoteProfile({
    identitySecret,
    loginType: 'metamask',
    profileName: 'MetaMask' as ProfileName,
    enabledBlockchains,
  })

  const profileMeta =
    await MasterProfileManager.findProfileByIdentitySecret(identitySecret)
  assert(profileMeta != null)

  return profileMeta
}

export type MetamaskRawLoginOptions = {autoInstall?: boolean}

export const metamaskRawLogin = async (
  metamaskContext: MetamaskContext,
  options: MetamaskRawLoginOptions = {autoInstall: true},
) => {
  const {setInstalledSnap, state: metamaskState} = metamaskContext
  assert(
    metamaskState.isInitialized,
    'Metamask must be initialized before login',
  )
  if (metamaskState.installedSnap == null) {
    // Meant for cases when this function should be called silently. Note that `connectSnaps`
    // will prompt user to continue within Metamask UI.
    if (!options.autoInstall) {
      throw new Error('Snap not installed')
    }
    await connectSnap()
  }

  const installedSnap = await getSnap()
  if (installedSnap == null) {
    throw new Error('Snap not found')
  }
  if (metamaskState.installedSnap == null) {
    setInstalledSnap(installedSnap)
  }

  const identitySecret = (await metamaskSnapRequest(
    'nufi__getUserId',
    null,
  )) as IdentitySecret

  const isProfileBackedUp = await hasCloudSyncBackup({identitySecret}).catch(
    () => false,
  )
  const profileMeta = await findMetamaskProfile({identitySecret})
  return {profileMeta, isProfileBackedUp, identitySecret}
}
