// Crypto price volatility changes in real time
// and cause errors that mess up the UX.
// We are therefore offsetting the values to prevent this.

import BigNumber from 'bignumber.js'

const MIN_AMOUNT_OFFSET_MULTIPLIER = 1.05 // 5% offset - avoid price volatility errors
const MAX_AMOUNT_OFFSET_MULTIPLIER = 0.95 // 5% offset - avoid price volatility errors

// Important to keep the same number of decimals for tx building (i.e. ERC20 tokens)
export const increaseMinAmountByOffset = (
  amount: BigNumber,
  decimals: number,
) => new BigNumber(amount.times(MIN_AMOUNT_OFFSET_MULTIPLIER).toFixed(decimals))

// Important to keep the same number of decimals for tx building (i.e. ERC20 tokens)
export const decreaseMaxAmountByOffset = (
  amount: BigNumber,
  decimals: number,
) => new BigNumber(amount.times(MAX_AMOUNT_OFFSET_MULTIPLIER).toFixed(decimals))
