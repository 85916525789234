import type {
  IdentitySecret,
  LocalSecretProvider,
  RemoteSecretProvider,
  SecretProvider,
} from '@nufi/wallet-common'

import type {SecretData} from '../store/wallet'
import {useSecretStore} from '../store/wallet'
import {assert, safeAssertUnreachable} from '../utils/assertion'

import type {Mnemonic, RootSeed} from './types'
import {mnemonicToSeed} from './utils/mnemonic'

type SecretProviderArgs =
  | {
      mnemonicStorageType: 'local'
      mnemonic: Mnemonic
    }
  | {
      mnemonicStorageType: 'remote'
      identitySecret: IdentitySecret
    }

export async function initSecretProvider(
  args: SecretProviderArgs,
): Promise<void> {
  const store = useSecretStore.getState()
  const type = args.mnemonicStorageType

  switch (type) {
    case 'local': {
      const seed = await mnemonicToSeed(args.mnemonic)
      store.init({
        type: 'local',
        mnemonic: args.mnemonic,
        seed,
      })
      break
    }
    case 'remote': {
      store.init({
        type: 'remote',
        identitySecret: args.identitySecret,
      })
      break
    }
    default:
      safeAssertUnreachable(type)
  }
}

const getSecretData = (): SecretData => {
  const {data} = useSecretStore.getState()
  if (data == null) {
    throw new Error('Secret provider not initialized')
  }
  return data
}

export const localSecretProvider: LocalSecretProvider = {
  getMnemonic: (): Mnemonic => {
    const data = getSecretData()
    assert(data.type === 'local')
    return data.mnemonic
  },
  getSeed: (): RootSeed => {
    const data = getSecretData()
    assert(data.type === 'local')
    return data.seed
  },
  mnemonicStorageType: 'local',
}

export const remoteSecretProvider: RemoteSecretProvider = {
  getIdentitySecret: (): IdentitySecret => {
    const data = getSecretData()
    assert(data.type === 'remote')
    return data.identitySecret
  },
  mnemonicStorageType: 'remote',
}

export const getSecretProvider = (): SecretProvider => {
  const data = getSecretData()
  const {type} = data

  switch (type) {
    case 'local':
      return localSecretProvider
    case 'remote':
      return remoteSecretProvider
    default:
      return safeAssertUnreachable(type)
  }
}

export type InitSecretProvider = typeof initSecretProvider
