import {ReactQueryDevtools} from '@tanstack/react-query-devtools'
import React, {useRef, useEffect, useState} from 'react'

import config from '../../config'

export function ReactQueryDevtoolsPanel() {
  return config.isProd || config.isJest ? null : <_ReactQueryDevtoolsPanel />
}

// "R" + "Q" opens the devtools panel
// "R" -> "react", "Q" -> "query"
function _ReactQueryDevtoolsPanel() {
  const RPressed = useRef(false)
  const [showDevtools, setShowDevtools] = useState(false)

  const onKeyDown = (evt: KeyboardEvent) => {
    const charStr = getCharStrFromEvent(evt)

    if (charStr === 'R') {
      RPressed.current = true
    }
    if (charStr === 'Q' && RPressed.current) {
      setShowDevtools(!showDevtools)
    }
  }

  const onKeyUp = (evt: KeyboardEvent) => {
    const charStr = getCharStrFromEvent(evt)

    if (charStr === 'R') {
      RPressed.current = false
    }
  }

  useEffect(() => {
    window.addEventListener('keyup', onKeyUp)
    window.addEventListener('keydown', onKeyDown)
    return () => {
      window.removeEventListener('keyup', onKeyUp)
      window.removeEventListener('keydown', onKeyDown)
    }
  })

  return showDevtools && <ReactQueryDevtools initialIsOpen />
}

function getCharStrFromEvent(evt: KeyboardEvent) {
  evt = evt || window.event
  const charCode = evt.keyCode || evt.which
  return String.fromCharCode(charCode)
}
