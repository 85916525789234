import {getIsWebExtension} from '@nufi/frontend-common'
import type {FunctionComponent, ComponentProps, ReactNode} from 'react'
import React, {lazy, Suspense} from 'react'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type LazyComponentProps<T extends FunctionComponent<any>> = {
  load: () => Promise<{default: T}>
  fallback?: ReactNode
} & ComponentProps<T>

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const WebExtensionGuard = <T extends FunctionComponent<any>>({
  load,
  fallback = null,
  ...componentProps
}: LazyComponentProps<T>) => {
  const WebextensionComponent = lazy(load)

  return (
    <>
      {getIsWebExtension() && (
        <Suspense fallback={fallback}>
          <WebextensionComponent {...(componentProps && componentProps)} />
        </Suspense>
      )}
    </>
  )
}
