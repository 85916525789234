import {
  AccountSource,
  BlockchainApiImpl,
  LedgerCryptoProviderImpl,
  TrezorCryptoProvider,
  GridPlusCryptoProvider,
  MnemonicCryptoProvider,
  NftApiImpl,
  getConnection,
  SolanaAccountManager,
  SolanaWallet,
} from '@nufi/wallet-solana'
import type {
  BlockchainApi,
  SolanaAccountNetworkInfo,
  SolanaAccountOfflineInfo,
  SolanaAccountStoredData,
} from '@nufi/wallet-solana'

import config from 'src/config'

import {useSolanaStore} from '../../store/wallet'
import {
  AccountsStoreManagerImpl,
  addStandardAccounts as addAccounts,
} from '../AccountsStoreManager'
import {localSecretProvider} from '../secretProvider'
import {createAccountInfosUtils} from '../utils/walletUtils'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function _createSolanaManagers(blockchainApi: BlockchainApi) {
  const mnemonicCryptoProvider = new MnemonicCryptoProvider(localSecretProvider)
  const accountSource = new AccountSource()
  const ledgerCryptoProvider = new LedgerCryptoProviderImpl()
  const trezorCryptoProvider = new TrezorCryptoProvider()
  const gridPlusCryptoProvider = new GridPlusCryptoProvider()
  const accountsStore = new AccountsStoreManagerImpl<SolanaAccountStoredData>(
    useSolanaStore,
  )
  const nftApi = new NftApiImpl(config.solanaRpcUrl)

  const accountManager = new SolanaAccountManager({
    blockchainApi,
    mnemonicCryptoProvider,
    ledgerCryptoProvider,
    trezorCryptoProvider,
    gridPlusCryptoProvider,
  })

  const wallet = new SolanaWallet({
    accountManager,
    accountSource,
    mnemonicCryptoProvider,
    ledgerCryptoProvider,
    trezorCryptoProvider,
    gridPlusCryptoProvider,
    blockchainApi,
    nftApi,
    addAccounts,
  })

  const accountInfoUtils = createAccountInfosUtils<
    SolanaAccountStoredData,
    SolanaAccountNetworkInfo,
    SolanaAccountOfflineInfo
  >(accountManager)

  return {
    wallet,
    accountManager,
    accountsStore,
    blockchainApi,
    ...accountInfoUtils,
  }
}

const solanaBackendConnection = getConnection(config.solanaRpcUrl)

export const solana = _createSolanaManagers(
  new BlockchainApiImpl(solanaBackendConnection),
)
