import {buildTokenTransferParams} from '@nufi/wallet-evm'
import BigNumber from 'bignumber.js'
import type {SwapAsset} from 'common'
import React, {useEffect, useMemo} from 'react'

import {getExchangeAssetDetailOrFallback} from 'src/features/exchange/application'
import type {
  CommonValueFieldConstraints,
  ExchangeAssetsDetails,
} from 'src/features/exchange/domain'
import {
  useTrackMultiAssetTxSubmission,
  useTrackTxSubmission,
} from 'src/tracking'
import {validateAssetDecimalsCount} from 'src/utils/form'

import type {ExchangeBlockchain} from '../../../blockchainTypes'
import {QueryGuard} from '../../../components'
import {getBlockchainDecimals} from '../../../constants'
import {assert} from '../../../utils/assertion'
import type {AccountInfo} from '../../../wallet'
import {commonParseTokenAmount} from '../../../wallet'
import type {
  EvmAccountInfo,
  EvmAddress,
  EvmBlockchain,
  EvmContractAddress,
  EvmSignedTransaction,
  EvmTokenId,
  EvmTokenMetadata,
  EvmTransactionHash,
  EvmWei,
} from '../../../wallet/evm'
import {
  cachedGetTokenMetadata,
  cachedGetTokenTransferGasLimitEstimate,
  getMaxSendableNativeAmount,
  isEvmBlockchain,
  useAllTokenBalances,
  useGetTokenMetadata,
  useSignTransfer,
  useSubmitTransfer,
} from '../../../wallet/evm'
import {EVM_TRANSFER_GAS_UNITS} from '../../../wallet/evm/constants'
import {
  getEvmTokenId,
  isValidEvmAddress,
  nativeToWei,
  toChecksummedEvmAddress,
} from '../../../wallet/evm/sdk/utils'
import type {GasOptionsFormValues as LondonGasOptionsFormValues} from '../../send/evm/gas/LondonGasOptions'
import type {GasOptionsFormValues as SimpleGasOptionsFormValues} from '../../send/evm/gas/SimpleGasOptions'
import {getGasOptionsData} from '../../send/evm/gas/WithGasOptionsData'
import {useCustomMetadata} from '../CustomMetadataContainer'
import type {
  AmountFieldMaxAmountData,
  ExchangeDeps,
  WithAmountFieldProperties,
  WithGetAssetTokenDataProps,
  WithSignAndSubmitHandlers,
} from '../screens/common'
import {WithSummaryTxFee} from '../screens/common'
import {useCommonAmountValidation} from '../screens/details/common'
import type {DetailsValues} from '../screens/details/schema'
import type {SwapTokenData} from '../types'

import {useAmountFieldPropertiesState} from './common'

export type ExchangeEvmBlockchain = Extract<EvmBlockchain, ExchangeBlockchain>

type CustomMetadata<T extends ExchangeEvmBlockchain> = {
  gasOptions: LondonGasOptionsFormValues<T> | SimpleGasOptionsFormValues<T>
  fee: EvmWei<T> | null
}

const isEvmBlockchainAccountInfo = <T extends ExchangeEvmBlockchain>(
  accountInfo: AccountInfo,
): accountInfo is EvmAccountInfo<T> => isEvmBlockchain(accountInfo.blockchain)

const WithSignAndSubmitHandlersNative: WithSignAndSubmitHandlers<
  EvmSignedTransaction<ExchangeEvmBlockchain>,
  EvmTransactionHash,
  null
> = ({amount, toAddress, fromAccountInfo, children}) => {
  const blockchain = fromAccountInfo.blockchain as ExchangeEvmBlockchain
  type TBlockchain = typeof blockchain
  const {getNonReactiveMetadata} =
    useCustomMetadata<CustomMetadata<TBlockchain>>()

  const submit = useSubmitTransfer(blockchain)
  const sign = useSignTransfer(blockchain)

  useTrackTxSubmission(submit, {
    blockchain,
    provider: fromAccountInfo.cryptoProviderType,
    type: 'transfer_coin',
    value: nativeToWei<TBlockchain>(amount).toNumber(),
  })

  const onTxSign = () => {
    const context = getNonReactiveMetadata(blockchain)
    assert(context.gasOptions != null)
    return sign.mutateAsyncSilent({
      variant: 'nativeTransfer',
      amount: nativeToWei<TBlockchain>(amount),
      fromAccountId: fromAccountInfo.id,
      toAddress: toAddress as EvmAddress<TBlockchain>,
      nonce: null,
      gasOptions: context.gasOptions,
    })
  }

  const onTxSubmit = (signedTx: EvmSignedTransaction<TBlockchain>) =>
    submit.mutateAsyncSilent({signedTx, accountId: fromAccountInfo.id})

  return children({
    signProps: {...sign, mutateAsyncSilent: onTxSign},
    submitProps: {...submit, mutateAsyncSilent: onTxSubmit},
  })
}

const WithSignAndSubmitHandlersToken: WithSignAndSubmitHandlers<
  EvmSignedTransaction<ExchangeEvmBlockchain>,
  EvmTransactionHash,
  SwapTokenData
> = ({amount, toAddress, fromAccountInfo, fromToken, children}) => {
  const blockchain = fromAccountInfo.blockchain as ExchangeEvmBlockchain
  type TBlockchain = typeof blockchain
  const tokenId = fromToken.id as EvmTokenId<TBlockchain>
  const {getNonReactiveMetadata} =
    useCustomMetadata<CustomMetadata<TBlockchain>>()

  const tokenMetadataQuery = useGetTokenMetadata(blockchain, tokenId)

  const submit = useSubmitTransfer(blockchain)
  const sign = useSignTransfer(blockchain)

  return (
    <QueryGuard {...tokenMetadataQuery}>
      {(tokenMetadata) => {
        const tokenTransferParams = buildTokenTransferParams<TBlockchain>({
          tokenMetadata,
          fromAddress: fromAccountInfo.address as EvmAddress<TBlockchain>,
          toAddress: toAddress as EvmAddress<TBlockchain>,
          amount: new BigNumber(amount),
        })

        const onTxSign = () => {
          const context = getNonReactiveMetadata(blockchain)
          assert(context.gasOptions != null)
          return sign.mutateAsyncSilent({
            variant: 'tokenTransfer',
            fromAccountId: fromAccountInfo.id,
            nonce: null,
            gasOptions: context.gasOptions,
            ...tokenTransferParams,
          })
        }

        const onTxSubmit = (signedTx: EvmSignedTransaction<TBlockchain>) =>
          submit.mutateAsyncSilent({
            signedTx,
            accountId: fromAccountInfo.id,
          })

        return (
          <WithTokenTxSubmissionTracking
            blockchain={blockchain}
            tokenId={tokenId}
            tokenMetadata={tokenMetadata}
            submitProps={submit}
            fromAccountInfo={fromAccountInfo}
            amount={amount}
          >
            {children({
              signProps: {...sign, mutateAsyncSilent: onTxSign},
              submitProps: {...submit, mutateAsyncSilent: onTxSubmit},
            })}
          </WithTokenTxSubmissionTracking>
        )
      }}
    </QueryGuard>
  )
}

type WithTokenTxSubmissionTrackingProps<
  TBlockchain extends ExchangeEvmBlockchain,
> = {
  children: React.ReactNode
  blockchain: TBlockchain
  tokenId: EvmTokenId<TBlockchain>
  tokenMetadata: EvmTokenMetadata<TBlockchain>
  submitProps: ReturnType<typeof useSubmitTransfer>
  fromAccountInfo: AccountInfo
  amount: string
}
const WithTokenTxSubmissionTracking = <
  TBlockchain extends ExchangeEvmBlockchain,
>({
  children,
  blockchain,
  tokenId,
  tokenMetadata,
  submitProps,
  fromAccountInfo,
  amount,
}: WithTokenTxSubmissionTrackingProps<TBlockchain>) => {
  useTrackMultiAssetTxSubmission(submitProps, {
    blockchain,
    provider: fromAccountInfo.cryptoProviderType,
    sendContext: {
      type: 'single_token_transfer_tx',
      tokenAmount: commonParseTokenAmount(amount, tokenMetadata.decimals),
      tokenId,
      isNft: tokenMetadata.isNft,
    },
  })

  return <>{children}</>
}

const WithEvmSignAndSubmitHandlers: WithSignAndSubmitHandlers<
  EvmSignedTransaction<ExchangeEvmBlockchain>,
  EvmTransactionHash,
  SwapTokenData | null
> = ({fromToken, ...restProps}) => {
  return fromToken != null ? (
    <WithSignAndSubmitHandlersToken fromToken={fromToken} {...restProps} />
  ) : (
    <WithSignAndSubmitHandlersNative {...restProps} fromToken={null} />
  )
}

const WithEvmAmountFieldProperties: WithAmountFieldProperties = ({
  fromAccountInfo,
  values,
  fromToken,
  children,
}) => {
  assert(fromAccountInfo == null || isEvmBlockchainAccountInfo(fromAccountInfo))
  const blockchain = fromAccountInfo?.blockchain
  type TBlockchain = NonNullable<typeof blockchain>
  const {data, calculateAndSetData} = useAmountFieldPropertiesState()

  const {setNonReactiveMetadata} =
    useCustomMetadata<CustomMetadata<TBlockchain>>()

  const getConstraintsAndData = async (
    values: DetailsValues,
    fromAccountInfo: EvmAccountInfo<TBlockchain> | null,
    fromToken: SwapTokenData | null,
  ): Promise<{
    constraints: CommonValueFieldConstraints | null
    data: {fee?: BigNumber; maxAmount?: AmountFieldMaxAmountData} | null
  }> => {
    const maxTokenAmount = (() => {
      if (fromToken && fromAccountInfo?.address) {
        const value = fromToken.balances[fromAccountInfo.address]
        return value == null ? new BigNumber(0) : value
      }
      return undefined
    })()

    const emptyResponse = {constraints: null, data: null}
    if (blockchain == null || fromAccountInfo == null || values.amount === '') {
      return emptyResponse
    }

    // Consider dividing this logic into two separate token/native flows to make
    // it easier to follow and reason about.
    try {
      const tokenMetadata =
        fromToken?.id != null
          ? await cachedGetTokenMetadata<TBlockchain>(
              blockchain,
              fromToken.id as EvmTokenId<TBlockchain>,
            )
          : null
      if (fromToken?.id != null && !tokenMetadata) return emptyResponse

      // Note that as we do not know the swap address beforehand,
      // we get the estimate by simulating against an arbitrary EMPTY account address
      // (personally created on new mnemonic, see test mnemonics doc).
      // Gas limit for addresses that already have the given token
      // will be ~40% lower than gas limit for sending to an empty address,
      // and we want to cover the worst case scenario.
      // https://ethereum.stackexchange.com/a/72573
      // The proper address is used later once swap is created.
      const addressToEstimateAgainst =
        '0xf3624E80C3a60A22E8F355CA9DC3CBEC4b15EaC7' as EvmAddress<TBlockchain>
      if (!isValidEvmAddress(addressToEstimateAgainst)) return emptyResponse

      const maxDecimals = tokenMetadata
        ? tokenMetadata.decimals
        : getBlockchainDecimals(blockchain)

      const amount = commonParseTokenAmount(values.amount, maxDecimals)
      // Validate decimals because gas estimation fails if too many decimals.
      if (!validateAssetDecimalsCount(values.amount, maxDecimals)) {
        return {constraints: {hasError: true, maxDecimals}, data: null}
      }

      // Avoid gas estimates if it is obvious that balance is too high
      if (fromToken != null && maxTokenAmount != null) {
        if (maxTokenAmount.isLessThan(amount) || maxTokenAmount.isEqualTo(0)) {
          // Validation will fail on `maxTokenAmount` constraint
          return {
            constraints: {hasError: true, maxTokenAmount},
            data: {
              maxAmount: {
                value: maxTokenAmount,
                type: 'token',
                decimals: tokenMetadata!.decimals,
              },
            },
          }
        }
      }

      const gasLimit = !tokenMetadata
        ? EVM_TRANSFER_GAS_UNITS
        : await cachedGetTokenTransferGasLimitEstimate<TBlockchain>(
            blockchain,
            buildTokenTransferParams({
              amount,
              fromAddress: fromAccountInfo.address as EvmAddress<TBlockchain>,
              toAddress: addressToEstimateAgainst,
              tokenMetadata,
            }),
          )

      const gasOptions = await getGasOptionsData({
        blockchain,
        customLondonOptions: {
          gasLimit,
          // This has no effect currently and should be made optional.
          allowResetOnGasSuggestionsChange: false,
        },
        customSimpleOptions: {
          gasLimit,
        },
        customOpStackFeeModelOptions: {
          accountId: fromAccountInfo.id,
          tokenMetadata,
        },
      })

      // Gas options are not customizable in the Exchange flow and therefore
      // here we are hardcoding the default (i.e.) medium gas price.
      const fee = gasOptions.getTxFee(gasOptions.gasOptionsInitialValues)

      const maxNativeAmount = getMaxSendableNativeAmount<TBlockchain>(
        fromAccountInfo.balance,
        fee,
      ).maxAmount

      setNonReactiveMetadata(blockchain, {
        gasOptions: gasOptions.formValuesToGasOptions(
          gasOptions.gasOptionsInitialValues,
        ),
        fee,
      })

      return {
        constraints: {
          maxNativeAmount,
          maxDecimals,
          fee,
          maxTokenAmount,
        },
        data: {
          fee,
          maxAmount: fromToken
            ? {
                value: maxTokenAmount!,
                type: 'token',
                decimals: tokenMetadata!.decimals,
              }
            : {value: maxNativeAmount, type: 'native'},
        },
      }
    } catch (error) {
      return {constraints: {hasError: true, maxTokenAmount}, data: null}
    }
  }

  useEffect(() => {
    calculateAndSetData(() =>
      getConstraintsAndData(values, fromAccountInfo, fromToken),
    )
  }, [values, fromToken])

  const validate = useCommonAmountValidation(
    values,
    fromAccountInfo,
    fromToken,
    async (amount) =>
      (
        await getConstraintsAndData(
          {...values, amount},
          fromAccountInfo,
          fromToken,
        )
      ).constraints,
  )

  return children({
    validate,
    data,
  })
}

const useGetEvmAssetTokenData = (
  blockchain: ExchangeBlockchain | null,
  asset: SwapAsset | null,
  exchangeAssetsDetails: ExchangeAssetsDetails,
) => {
  assert(isEvmBlockchain(blockchain))
  const evmTokenBalancesQuery = useAllTokenBalances(blockchain)

  const data = useMemo(() => {
    const tokenBalances = evmTokenBalancesQuery.data
    if (!tokenBalances || !asset) return null

    const {contractAddress} = getExchangeAssetDetailOrFallback(
      asset,
      exchangeAssetsDetails,
    )
    if (!contractAddress) return null
    const matchedTokenBalances = tokenBalances.filter(
      ({token}) =>
        toChecksummedEvmAddress(
          // TODO ADLT-1821 check usage and overlaps of types EvmAddress<->EvmContractAddress
          // Remove the casts "as string as EvmAddress" after the appropriate type change
          token.contractAddress as string as EvmAddress<ExchangeEvmBlockchain>,
        ) ===
        toChecksummedEvmAddress(
          contractAddress as string as EvmAddress<ExchangeEvmBlockchain>,
        ),
    )
    const walletHasToken = matchedTokenBalances.length > 0

    // returns id of the token and balances object with account addresses as keys
    // and respective address (account) balances as values
    if (walletHasToken) {
      return {
        id: matchedTokenBalances[0]!.token.id,
        balances: Object.fromEntries(
          matchedTokenBalances.map((tb) => [tb.owner, tb.amount]),
        ) as {[key: EvmAddress<ExchangeEvmBlockchain>]: BigNumber},
      }
    } else {
      const tokenId = getEvmTokenId<EvmBlockchain>({
        contractAddress:
          contractAddress as EvmContractAddress<ExchangeEvmBlockchain>,
        standard: 'ERC20',
      })
      return {
        id: tokenId,
        balances: {},
      }
    }
  }, [blockchain, asset, exchangeAssetsDetails, evmTokenBalancesQuery.data])

  return {...evmTokenBalancesQuery, data}
}

const _WithGetAssetTokenData = ({
  asset,
  blockchain,
  children,
  exchangeAssetsDetails,
}: {
  asset: SwapAsset
  blockchain: ExchangeBlockchain
} & Pick<WithGetAssetTokenDataProps, 'children' | 'exchangeAssetsDetails'>) => {
  const query = useGetEvmAssetTokenData(
    blockchain,
    asset,
    exchangeAssetsDetails,
  )
  return children(query)
}

const WithEvmGetAssetTokenData: ExchangeDeps['WithGetAssetTokenData'] = ({
  asset,
  blockchain,
  children,
  ...restProps
}) => {
  if (asset == null || blockchain == null) {
    return children(null)
  }
  return (
    <_WithGetAssetTokenData {...{asset, blockchain, children}} {...restProps} />
  )
}

export function findMatchingTokenSwapAsset({
  exchangeAssets,
  tokenMetadata,
}: {
  exchangeAssets: ExchangeAssetsDetails
  tokenMetadata: EvmTokenMetadata<EvmBlockchain>
}) {
  const tokenMetadataContractAddress = tokenMetadata.contractAddress

  return Object.values(exchangeAssets).find((v) => {
    try {
      if (
        v?.blockchain === tokenMetadata.blockchain &&
        v?.contractAddress &&
        toChecksummedEvmAddress(
          v.contractAddress as string as EvmAddress<EvmBlockchain>,
        ) ===
          toChecksummedEvmAddress(
            tokenMetadataContractAddress as string as EvmAddress<EvmBlockchain>,
          )
      ) {
        return true
      }
      return false
    } catch (err) {
      // As "toChecksummedEvmAddress" can throw we are rather preventing this error
      // to not break the whole exchange functionality due to it.
      return false
    }
  })
}

export const evmExchangeDeps: ExchangeDeps = {
  WithAmountFieldProperties: WithEvmAmountFieldProperties,
  WithSignAndSubmitHandlers: WithEvmSignAndSubmitHandlers,
  WithSummaryTxFee,
  WithGetAssetTokenData: WithEvmGetAssetTokenData,
}
