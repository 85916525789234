import {ArrowBack as BackIcon} from '@mui/icons-material'
import {Divider, Grid, IconButton, Typography} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {usePlatformConfig} from 'src/dappConnector/PlatformConfig'

import {Modal} from '../../../components'
import {TopBar} from '../../components/TopBar'

type SettingsProps = {
  open: boolean
  onClose: () => void
}

export function Settings({open, onClose}: SettingsProps) {
  const classes = useStyles()
  const {t} = useTranslation()
  const {Settings} = usePlatformConfig()

  return (
    <Modal
      open={open}
      variant="full-width"
      onClose={onClose}
      className={classes.modal}
    >
      <TopBar
        leftComponent={
          <Grid container alignItems="center">
            <IconButton onClick={onClose} edge="start">
              <BackIcon />
            </IconButton>
            <Typography variant="h5" ml={1}>
              {t('Settings')}
            </Typography>
          </Grid>
        }
      />
      <Divider />
      <Grid container direction="column" px={6} py={2}>
        {Settings}
      </Grid>
    </Modal>
  )
}

const useStyles = makeStyles((theme) => ({
  modal: {
    backgroundColor: theme.palette.background.default,
  },
}))
