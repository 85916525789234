import {Box} from '@mui/material'
import type {FormikHelpers} from 'formik'
import {Formik} from 'formik'
import React from 'react'
import {useTranslation} from 'react-i18next'
import * as yup from 'yup'

import {CurrentPasswordField, InlineError} from '../../../components'
import {useCurrentLoginInfo} from '../../../store/auth'
import {safeAssertUnreachable} from '../../../utils/assertion'
import {useVerifyPassword} from '../../../utils/form'
import {RejectButton} from '../../components'
import type {RememberLoginState} from '../init/utils'
import {WithRememberLoginState} from '../init/utils'

import {MessageData} from './messageData'
import {SignLayout} from './SignLayout'
import {TxData} from './txData'
import type {SignScreenProps} from './types'
import {RetryButton, SignButton} from './utils'
import {VoteData} from './voteData/VoteData'

type ExtraProps = {enforceMnemonicPassword?: boolean}

export function MnemonicSignScreen(props: SignScreenProps & ExtraProps) {
  return (
    <WithRememberLoginState>
      {(rememberLoginData) => (
        <MnemonicSignScreenContent {...props} {...rememberLoginData} />
      )}
    </WithRememberLoginState>
  )
}

function MnemonicSignScreenContent({
  onSign,
  signProps,
  onFailure,
  signData,
  extraContent,
  extraOptionsProps,
  rememberLogin,
  enforceMnemonicPassword,
}: SignScreenProps & RememberLoginState & ExtraProps) {
  const {t} = useTranslation()
  const loginInfo = useCurrentLoginInfo()
  const verifyPassword = useVerifyPassword()
  type Schema = {password: string}
  const initialValues: Schema = {password: ''}

  const requiresPasswordValidation =
    enforceMnemonicPassword ||
    (loginInfo.loginType !== 'web3Auth' && rememberLogin !== true)

  const schema = yup.object().shape(
    requiresPasswordValidation
      ? {
          password: yup.string().required(t('Password is required.')),
        }
      : {},
  )

  const onSubmit = async (
    {password}: Schema,
    helpers: FormikHelpers<Schema>,
  ) => {
    const passwordVerified = await verifyPassword(password, helpers)
    if (!passwordVerified) return
    await onSign()
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={onSubmit}
    >
      {(formikProps) => {
        const {isSubmitting, handleSubmit} = formikProps
        return (
          <SignLayout
            component="form"
            onSubmit={handleSubmit}
            infoContent={
              <>
                {extraContent}
                <Box mb={requiresPasswordValidation ? 2 : 0}>
                  {(() => {
                    switch (signData.type) {
                      case 'sign-tx':
                        return (
                          <TxData
                            data={signData.data}
                            {...{extraOptionsProps}}
                          />
                        )
                      case 'sign-message':
                        return <MessageData data={signData.data} />
                      case 'sign-vote':
                        return <VoteData data={signData.data} />
                      case 'get-hw-wallet-public-keys':
                        // does not apply to mnemonic wallets
                        return <></>
                      default:
                        return safeAssertUnreachable(signData)
                    }
                  })()}
                </Box>
                {requiresPasswordValidation && (
                  <CurrentPasswordField {...{formikProps}} />
                )}
                {signProps.error && <InlineError error={signProps.error} />}
              </>
            }
            actionsProps={{
              leftButton: <RejectButton onClick={onFailure} size="small" />,
              rightButton: signProps.error ? (
                <RetryButton onClick={onSign} disabled={signProps.isPending} />
              ) : (
                <SignButton
                  disabled={signProps.isPending || isSubmitting}
                  title={t('Sign')}
                  type="submit"
                />
              ),
            }}
          />
        )
      }}
    </Formik>
  )
}
