import React, {useState} from 'react'

import type {TokenBlockchain} from '../../../../../blockchainTypes'
import {Modal} from '../../../../../components'
import {assert, safeAssertUnreachable} from '../../../../../utils/assertion'
import type {AccountId} from '../../../../../wallet'

import ImportTxScreen from './ImportTx/ImportTxScreen'
import SelectAccountScreen from './SelectAccountScreen'
import type {AccountDetailsFormData, ImportTokenScreenType} from './types'

type ImportTokenProps = {
  onClose: () => void
  blockchain: TokenBlockchain
  id?: AccountId
}

export default function ImportToken({
  onClose,
  blockchain,
  id,
}: ImportTokenProps) {
  const [activeScreen, setActiveScreen] =
    useState<ImportTokenScreenType>('select-account')
  const [formData, setFormData] = useState<AccountDetailsFormData>({
    blockchain,
    accountId: id,
  })

  return (
    <Modal onClose={onClose} variant="left">
      {(() => {
        switch (activeScreen) {
          case 'select-account':
            return (
              <SelectAccountScreen
                {...{onClose, formData, setFormData}}
                onSubmit={() => setActiveScreen('import-token')}
              />
            )
          case 'import-token':
            assert(formData.accountId !== undefined)
            return (
              <ImportTxScreen
                onBack={() => setActiveScreen('select-account')}
                onClose={onClose}
                blockchain={formData.blockchain}
                accountId={formData.accountId}
              />
            )
          default:
            return safeAssertUnreachable(activeScreen)
        }
      })()}
    </Modal>
  )
}
