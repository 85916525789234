import type {Handler} from '@nufi/dapp-client-core'

import {useDappConnectorStore} from 'src/store/dappConnector'

import {
  consentRejectedError,
  createApi,
  notEnabledError,
} from '../../../../dappConnector/api/cardano'
import {middleware} from '../../../../dappConnector/middlewares'
import type {Connector} from '../../../../dappConnector/types'
import {
  confirmSign,
  requestCreateCardanoCollateral,
  confirmInit,
} from '../../apiBridge'

export const cardanoDappConnectorApi = createApi({
  connectorState: useDappConnectorStore,
  uiHandlers: {
    confirmSign,
    requestCreateCardanoCollateral,
  },
})

const createHandler = (): Handler =>
  middleware.userConsent(
    middleware.resolveMethod(cardanoDappConnectorApi, {
      notEnabledError,
      passContext: false,
    }),
    confirmInit,
    {
      connectorKind: 'cardano',
      isEnabledMethod: 'isEnabled',
      enableMethod: 'enable',
      notEnabledError,
      consentRejectedError,
    },
  )

export const cardanoConnector: Connector = {createHandler}
