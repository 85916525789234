import React, {useEffect, useState} from 'react'

import {usePlatformConfig} from 'src/dappConnector/PlatformConfig'
import {useSetRegularLoggedIn, useSetWeb3AuthLoggedIn} from 'src/features/login'

import {useInitMasterProfileManager} from '../../../appStorage'
import {MutationGuard} from '../../../components'
import {useDappConnectorStore} from '../../../store/dappConnector'
import {assert, assertUnreachable} from '../../../utils/assertion'
import {useTryAutoLoginIntoDappConnectorProfile} from '../../mutations/profile'

import {ChooseAccountScreen} from './ChooseAccount'
import {ChooseProfileScreen} from './ChooseProfile'

export function InitScreen() {
  const [subScreen, setSubscreen] = useState<
    'choose-profile' | 'choose-account'
  >('choose-profile')

  const {screenInfo, blockchain} = useDappConnectorStore()
  assert(screenInfo.state === 'init', 'InitScreen: invalid screen type')
  assert(blockchain != null, 'InitScreen: blockchain must be defined')
  const initMasterProfileManager = useInitMasterProfileManager()
  const autoLogin = useTryAutoLoginIntoDappConnectorProfile(blockchain)
  const setRegularLoggedIn = useSetRegularLoggedIn()
  const setWeb3AuthLoggedIn = useSetWeb3AuthLoggedIn()
  const {rememberLoginHandlers} = usePlatformConfig()

  useEffect(() => {
    ;(async () => {
      const initialized = await initMasterProfileManager.mutateAsyncSilent()
      if (initialized) {
        const result = await autoLogin.mutateAsyncSilent(
          await rememberLoginHandlers?.getLastLogin(),
        )
        if (result?.success) {
          if (result?.web3AuthUserInfo) {
            setWeb3AuthLoggedIn(result?.web3AuthUserInfo)
          } else {
            setRegularLoggedIn()
          }
          setSubscreen('choose-account')
        }
      }
    })()
  }, [])

  const onReject = () => {
    screenInfo.onFailure()
    window.close()
  }

  return (
    <MutationGuard {...initMasterProfileManager}>
      <MutationGuard {...autoLogin}>
        {(() => {
          switch (subScreen) {
            case 'choose-profile':
              return (
                <ChooseProfileScreen
                  onNext={() => setSubscreen('choose-account')}
                  {...{onReject, blockchain}}
                />
              )
            case 'choose-account':
              return (
                <ChooseAccountScreen
                  onChooseOtherProfile={() => setSubscreen('choose-profile')}
                  {...{onReject}}
                />
              )
            default:
              return assertUnreachable()
          }
        })()}
      </MutationGuard>
    </MutationGuard>
  )
}
