import {Box} from '@mui/material'
import React from 'react'

import {useEllipsisStyles} from '../../../utils/layoutUtils'

type MaxWidthEllipsizeProps = {
  maxWidth: number | string
  children: React.ReactNode
}

/**
 * Useful for wrapping <Text-like /> (e.g. <Typography />)
 * elements and ellipsizing them.
 */
export const MaxWidthEllipsize = ({
  children,
  maxWidth,
}: MaxWidthEllipsizeProps) => {
  const {ellipsis} = useEllipsisStyles()

  return (
    <Box {...{maxWidth}} className={ellipsis}>
      {children}
    </Box>
  )
}
