import {Box, TextField} from '@mui/material'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import type {WalletKind} from 'src/wallet/walletKind'

import {
  MutationGuard,
  Modal,
  ModalLayout,
  useModalSharedStyles,
} from '../../../../components'
import {onChangeFactory} from '../../../../utils/form'
import {useRenameAccount} from '../../../../wallet'
import type {AccountName, AccountId} from '../../../../wallet'

import {
  RenameAccountModalFooter,
  RenameAccountModalHeader,
  useValidateAccountName,
} from './RenameAccountUtils'

type RenameAccountProps = {
  name: AccountName
  walletKind: WalletKind
  onClose: () => void
  id: AccountId
}

export default function RenameAccount({
  onClose,
  name,
  walletKind,
  id,
}: RenameAccountProps) {
  const {t} = useTranslation()
  const classes = useModalSharedStyles()
  const [formData, setFormData] = useState({name})
  const renameAccount = useRenameAccount(walletKind)
  const onChange = onChangeFactory(formData, setFormData)
  const {errorMessage} = useValidateAccountName(formData.name)

  const _onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange('name')(e)
  }

  const onSubmit = async () => {
    if (errorMessage) return
    await renameAccount.mutateAsyncSilent({
      accountId: id,
      name: formData.name as AccountName,
    })
    onClose()
  }

  return (
    <Modal onClose={onClose} variant="centered">
      <ModalLayout
        header={<RenameAccountModalHeader {...{onClose, walletKind, name}} />}
        body={
          <Box p={2} minWidth={500}>
            <Box className={classes.formField}>
              <TextField
                fullWidth
                variant="outlined"
                onChange={_onChange}
                label={t('Account name')}
                value={formData.name}
                error={!!errorMessage}
                helperText={errorMessage}
              />
            </Box>
            <MutationGuard {...renameAccount} />
          </Box>
        }
        footer={
          <RenameAccountModalFooter
            {...{onClose, onSubmit}}
            disabled={!!errorMessage}
          />
        }
      />
    </Modal>
  )
}
