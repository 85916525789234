import {request} from '@nufi/frontend-common'
import type {NufiUserId} from 'common'

import config from 'src/config'
import type {NufiAuthApi} from 'src/features/nufiAuth/domain'

type GetChallengeResponse = {
  message: string
}

type GetTokenResponse = {
  token: string
}

export class NufiAuthApiImpl implements NufiAuthApi {
  private apiUrl: string

  constructor(backendUrl: string) {
    this.apiUrl = `${backendUrl}/api/v1/auth`
  }

  async getChallenge(nufiId: NufiUserId) {
    return (
      await request<GetChallengeResponse>({url: `${this.apiUrl}/${nufiId}`})
    ).message
  }

  async getToken(challenge: string, signature: string) {
    return (
      await request<GetTokenResponse>({
        url: `${this.apiUrl}/${challenge}/${signature}`,
      })
    ).token
  }
}

export const nufiAuthApi = new NufiAuthApiImpl(config.backendUrl)
