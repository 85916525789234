import React, {useContext} from 'react'

import type {SendModalViewModel} from './viewModel'

const SendModalViewModelContext = React.createContext<SendModalViewModel>(
  {} as SendModalViewModel,
)

export const SendModalVMProvider = ({
  children,
  vm,
}: {
  children: React.ReactElement
  vm: SendModalViewModel
}) => {
  return (
    <SendModalViewModelContext.Provider value={vm}>
      {children}
    </SendModalViewModelContext.Provider>
  )
}

export const useSendModalVM = () => useContext(SendModalViewModelContext)
