import type {StoreContentHeader, KeyValueData} from '@nufi/storage'
import {decrypt, isRawEncryptedStoreContent} from '@nufi/storage'
import {mnemonicToSeed} from 'bip39'

import type {
  LocalProfileLoginType,
  ProfileLoginType,
} from 'src/appStorage/types'

import type {Mnemonic} from '../../../types'
import {AppStorageError} from '../../errors'
import type {
  LocalProfileId,
  ProfilePassword,
  ProfileBackup,
} from '../../sharedTypes'
import {isMnemonicData, isProfileData} from '../guards'
import type {
  MnemonicData,
  MnemonicStoragePath,
  ProfileData,
  ProfileDataEncryptionKey,
  ProfileStoragePath,
} from '../types'
import {deriveKey, isStringifiedObject} from '../utils'

export const profileIdToMnemonicStoragePath = (
  profileId: LocalProfileId,
): MnemonicStoragePath => `mnemonic-${profileId}` as MnemonicStoragePath

export const profileIdToProfileStoragePath = (
  profileId: LocalProfileId,
): ProfileStoragePath => `profile-${profileId}` as ProfileStoragePath

export const deriveProfileDataEncryptionKey = async (
  mnemonic: Mnemonic,
): Promise<ProfileDataEncryptionKey> => {
  const seed = await mnemonicToSeed(mnemonic)
  return deriveKey(seed)
}

export const isLocalProfileLoginType = (
  loginType: ProfileLoginType,
): loginType is LocalProfileLoginType => {
  return loginType === 'password' || loginType === 'web3Auth'
}

export const validateLocalProfileBackup = async (
  profileBackup: ProfileBackup,
  password: ProfilePassword,
): Promise<{
  profileData: ProfileData
  mnemonicData: MnemonicData
  profileHeader: StoreContentHeader
  mnemonicHeader: StoreContentHeader
}> => {
  if (!isStringifiedObject(profileBackup)) {
    throw new Error(AppStorageError.INVALID_BACKUP_CONTENT)
  }

  const parsedBackup = JSON.parse(profileBackup)
  if (!('mnemonic' in parsedBackup)) {
    throw new Error('Backup currently supported only for local profiles')
  }

  const {
    profile: serializedProfileContent,
    mnemonic: serializeMnemonicContent,
  } = parsedBackup

  if (!serializedProfileContent || !serializeMnemonicContent) {
    throw new Error(AppStorageError.INVALID_BACKUP_CONTENT)
  }

  if (!isStringifiedObject(serializeMnemonicContent)) {
    throw new Error(AppStorageError.INVALID_MNEMONIC_CONTENT)
  }
  const mnemonicContent = JSON.parse(serializeMnemonicContent)
  if (!isRawEncryptedStoreContent(mnemonicContent)) {
    throw new Error(AppStorageError.INVALID_MNEMONIC_CONTENT)
  }

  if (!isStringifiedObject(serializedProfileContent)) {
    throw new Error(AppStorageError.INVALID_PROFILE_CONTENT)
  }
  const profileContent = JSON.parse(serializedProfileContent)
  if (!isRawEncryptedStoreContent(profileContent)) {
    throw new Error(AppStorageError.INVALID_PROFILE_CONTENT)
  }

  let mnemonicData: KeyValueData | MnemonicData
  try {
    mnemonicData = await decrypt({password, value: mnemonicContent.data})
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('Could not decrypt profileBackup', err)
    throw new Error(AppStorageError.WRONG_PASSWORD)
  }

  if (!isMnemonicData(mnemonicData)) {
    throw new Error(AppStorageError.INVALID_MNEMONIC_DATA)
  }

  const profileDataEncryptionKey = await deriveProfileDataEncryptionKey(
    mnemonicData.mnemonic,
  )
  const profileData = await decrypt({
    password: profileDataEncryptionKey,
    value: profileContent.data,
  })
  if (!isProfileData(profileData)) {
    throw new Error(AppStorageError.INVALID_PROFILE_DATA)
  }

  return {
    profileData,
    mnemonicData,
    profileHeader: profileContent.header,
    mnemonicHeader: mnemonicContent.header,
  }
}
