import {blockchainMetadata} from 'src/wallet/constants'
import type {Blockchain} from 'src/wallet/types'

// some blockchains native asset is actually bridged native asset of another blockchain, e.g. ETH is bridged to Optimism, so Optimisms native asset is ETH
export const getPeggedNativeAssetBlockchain = (blockchain: Blockchain) => {
  const meta = blockchainMetadata[blockchain]
  return 'peggedNativeAssetBlockchain' in meta
    ? meta.peggedNativeAssetBlockchain
    : null
}
