import React from 'react'

import {Modal} from '../../components'
import {useBaseActionRouteOptions} from '../../router/portfolio'
import {safeAssertUnreachable} from '../../utils/assertion'
import {isEvmBlockchain} from '../../wallet/evm'

import {CommonReceiveModal} from './common/ReceiveModal'
import {FlowReceiveModal} from './flow/ReceiveModal'

type ReceiveModalProps = {
  onClose: () => unknown
}

const ReceiveModal = ({onClose}: ReceiveModalProps) => {
  const {blockchain, accountId} = useBaseActionRouteOptions()

  return (
    <Modal onClose={onClose} variant="left">
      {(() => {
        switch (blockchain) {
          case 'flow':
            return <FlowReceiveModal {...{onClose, accountId}} />
          default: {
            if (
              isEvmBlockchain(blockchain) ||
              blockchain === 'cardano' ||
              blockchain === 'solana'
            ) {
              return (
                <CommonReceiveModal
                  {...{onClose, accountId, blockchain}}
                  hideNftAmount={blockchain === 'cardano'}
                />
              )
            }
            return safeAssertUnreachable(blockchain)
          }
        }
      })()}
    </Modal>
  )
}

export default ReceiveModal
