import {Divider, Typography} from '@mui/material'
import type {
  FlowAccountInfo,
  FlowTokenId,
  FlowTokenMetadata,
  Nanoflow,
} from '@nufi/wallet-flow'
import {useFormikContext} from 'formik'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {
  AmountRow,
  AssetInfo,
  TotalRow,
  useModalSharedStyles,
} from '../../../../../../components'
import type {AccountInfo} from '../../../../../../types'
import CommonSummaryScreen from '../ImportTx/CommonSummaryScreen'

import type {ImportFlowTokenSchema} from './schema'

const FORM_ID = 'import-flow-token-summary-form'

type SummaryScreenProps = {
  onBack: () => unknown
  onSubmit: () => unknown
  accounts: AccountInfo[]
  summary: {
    account: FlowAccountInfo
    fee: Nanoflow
    tokenMetadata?: FlowTokenMetadata
    tokenId: FlowTokenId
  }
  ModalHeader: React.ReactNode
}

export default function SummaryScreen({
  onBack,
  onSubmit,
  summary,
  accounts,
  ModalHeader,
}: SummaryScreenProps) {
  const {t} = useTranslation()
  const classes = useModalSharedStyles()
  const {accountId} = useFormikContext<ImportFlowTokenSchema>().values

  return (
    <CommonSummaryScreen
      {...{onBack, onSubmit, accounts, accountId}}
      blockchain="flow"
      formId={FORM_ID}
      ModalHeader={ModalHeader}
    >
      {summary.tokenMetadata && (
        <>
          <Typography color="text.secondary" mb={1}>
            {t('To be imported')}
          </Typography>
          <AssetInfo type="token" tokenMetadata={summary.tokenMetadata} />
        </>
      )}
      <AmountRow
        label={t('Transaction fee')}
        amount={summary.fee}
        blockchain="flow"
      />
      <Divider className={classes.dividerY} />
      <TotalRow
        nativeAmount={summary.fee}
        blockchain="flow"
        totalText={t('Total')}
      />
    </CommonSummaryScreen>
  )
}
