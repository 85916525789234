import {sleep} from '../../../utils/helpers'

export function getLastFocusedWindow(): Promise<chrome.windows.Window> {
  return new Promise((resolve) => {
    chrome.windows.getLastFocused((lastFocusedWindow) =>
      resolve(lastFocusedWindow),
    )
  })
}

export function hideConnectorWindow({
  lastFocusedWindowId,
  senderTabId,
}: {
  lastFocusedWindowId: number
  senderTabId: number
}): Promise<void> {
  return new Promise(async (resolve, reject) => {
    if (!lastFocusedWindowId) {
      reject('No previously focused window')
      return
    }

    const window = await chrome.windows.get(lastFocusedWindowId)
    await chrome.windows.update(lastFocusedWindowId, {focused: true})
    await chrome.tabs.update(senderTabId, {active: true})

    // When using fullscreen on Mac, the connector window will also
    // be opened in fullscreen mode & can not be minimized in a single step.
    // It seems to be better UX to just avoid minimizing it in such case & only focus
    // the previously used window.
    if (window.state === 'normal') {
      const result = await chrome.tabs.query({currentWindow: true})
      const windowId = result[0]!.windowId
      await chrome.windows.update(windowId, {state: 'minimized'})
      resolve()
    } else {
      resolve()
    }
  })
}

export async function findTabWindowId(tabId: number): Promise<number | null> {
  try {
    const tab = await chrome.tabs.get(tabId)
    return tab.windowId ?? null
  } catch (e) {
    return null
  }
}

export type ConnectorUrlParams = {
  lastFocusedWindowId: number | null
  senderTabId: number | null
  senderOrigin: string | null
}

export function getConnectorUrlParams(): ConnectorUrlParams {
  const urlParams = new URLSearchParams(window.location.search)

  const lastFocusedWindowId = (() => {
    const lastFocusedWindowId = urlParams.get('lastFocusedWindowId')
    return lastFocusedWindowId ? parseInt(lastFocusedWindowId, 10) : null
  })()

  const senderTabId = (() => {
    const senderTabId = urlParams.get('senderTabId')
    return senderTabId ? parseInt(senderTabId, 10) : null
  })()

  const senderOrigin = (() => {
    const senderOrigin = urlParams.get('senderOrigin')
    return senderOrigin ? decodeURIComponent(senderOrigin) : null
  })()

  return {lastFocusedWindowId, senderTabId, senderOrigin}
}

export const isSenderServiceWorker = (sender: chrome.runtime.MessageSender) =>
  // DAPP_CONNECTOR_SECURITY
  // I have not seen this kind of check referenced anywhere, and can't prove
  // with complete certainty that a content script could not somehow craft
  // these values, but it seems very unlikely.
  sender.tab == null

export const sendRuntimeUnhandledMessage = <T>(m: T) => {
  chrome.runtime.sendMessage(
    m,
    // evaluating this error prevents an Uncaught (in promise) Error from being thrown in the host site
    // this can happen e.g. if no connector window to receive the message is open or it gets closed before properly responding
    // https://stackoverflow.com/questions/28431505/unchecked-runtime-lasterror-when-using-chrome-api
    () => chrome.runtime.lastError,
  )
}

export const sendTabsUnhandledMessage = <T>(tabId: number, m: T) => {
  chrome.tabs.sendMessage(tabId, m).catch(
    // eslint-disable-next-line no-console
    (e) => console.error(e),
  )
}

const retriedFn = async (
  fn: () => Promise<unknown>,
  intervalIndex: number,
  intervals: number[],
): Promise<unknown> => {
  if (intervalIndex > intervals.length) {
    return await fn()
  }
  try {
    return await fn()
  } catch (err) {
    await sleep(intervals[intervalIndex]!)
    return await retriedFn(fn, intervalIndex + 1, intervals)
  }
}

export const makeRetriedFn =
  (fn: (...args: any[]) => Promise<unknown>, waitTimes: number[]) =>
  async (...args: any[]) => {
    const _fn = () => fn(...args)
    return await retriedFn(_fn, 0, waitTimes)
  }
