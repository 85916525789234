import {Launch as ExternalIcon} from '@mui/icons-material'
import {Link} from '@mui/material'
import React from 'react'

import config from '../../../config'
import type {
  CardanoTokenAssetNameHex,
  CardanoTokenPolicyId,
  CardanoStakeKeyHex,
  CardanoRewardAddress,
} from '../../../wallet/cardano'
import {getAssetFingerprint} from '../../../wallet/cardano'
import {EllipsizedString} from '../../visual/atoms/TextUtils'
import {ExplorerLink, useCommonStyles, ValidatorLink} from '../common'

import {getCardanoTransactionExplorerLink} from './getLink'

export * from './getLink'

const cardanoValidatorExplorerLink = {
  mainnet: 'https://cexplorer.io/pool/',
  preprod: 'https://preprod.cexplorer.io/pool/',
  preview: 'https://preview.cexplorer.io/pool/',
  // TODO sanchonet
  sanchonet: 'https://sancho.cexplorer.io/pool/',
}[config.cardanoNetwork]

const cardanoStakeKeyExplorerLink = {
  mainnet: 'https://cexplorer.io/stake/',
  preprod: 'https://preprod.cexplorer.io/stake/',
  preview: 'https://preview.cexplorer.io/stake/',
  // TODO sanchonet
  sanchonet: 'https://sancho.cexplorer.io/stake/',
}[config.cardanoNetwork]

type CardanoTransactionExplorerLinkProps = {
  txId: string
}

export function CardanoTransactionExplorerLink({
  txId,
}: CardanoTransactionExplorerLinkProps) {
  return <ExplorerLink link={getCardanoTransactionExplorerLink(txId)} />
}

type CardanoValidatorExplorerLinkProps = {
  poolId: string
  poolName: React.ReactNode
}

export function CardanoValidatorExplorerLink({
  poolId,
  poolName,
}: CardanoValidatorExplorerLinkProps) {
  return (
    <ValidatorLink
      link={`${cardanoValidatorExplorerLink}${poolId}`}
      linkText={poolName}
    />
  )
}

type CardanoStakeKeyExplorerLinkProps = {
  stakeKey: CardanoStakeKeyHex
}

export function CardanoStakeKeyExplorerLink({
  stakeKey,
}: CardanoStakeKeyExplorerLinkProps) {
  const classes = useCommonStyles()
  const rewardAddressHex = `${
    config.cardanoNetwork === 'mainnet' ? 'e1' : 'e0'
  }${stakeKey}`

  return (
    <Link
      href={`${cardanoStakeKeyExplorerLink}${rewardAddressHex}`}
      className={classes.link}
      target="_blank"
    >
      <EllipsizedString
        value={stakeKey}
        endLength={6}
        widthClassName={classes.stakeKey}
      />
      <ExternalIcon color="primary" className={classes.icon} />
    </Link>
  )
}

const cardanoTokenExplorerLink = {
  mainnet: 'https://cexplorer.io/asset/',
  preprod: 'https://preprod.cexplorer.io/asset/',
  preview: 'https://preview.cexplorer.io/asset/',
  sanchonet: 'https://sancho.cexplorer.io/asset/',
}[config.cardanoNetwork]

export const CardanoTokenExplorerLink = ({
  policyId,
  assetNameHex,
}: {
  policyId: CardanoTokenPolicyId
  assetNameHex: CardanoTokenAssetNameHex
}) => (
  <ExplorerLink
    link={`${cardanoTokenExplorerLink}${getAssetFingerprint(
      policyId,
      assetNameHex,
    )}`}
  />
)

export const CardanoRewardAddressExplorerLink = ({
  rewardAddress,
}: {
  rewardAddress: CardanoRewardAddress
}) => <ExplorerLink link={`${cardanoStakeKeyExplorerLink}${rewardAddress}`} />
