import React from 'react'
import ContentLoader from 'react-content-loader'

import {SIDEBAR_WIDTH} from '../../../constants'
import {
  useResponsiveDimensions,
  ContentLoaderBackground,
} from '../../../utils/layoutUtils'

const _AssetItemLoading = ({maxWidth}: {maxWidth: number}) => (
  <ContentLoader
    viewBox={`0 0 ${maxWidth} 60`}
    backgroundColor={ContentLoaderBackground()}
  >
    <circle cx="20" cy="28" r="17" />
    <rect x="50" y="13" rx="4" ry="4" width={maxWidth - 50} height="11" />
    <rect x="50" y="32" rx="3" ry="3" width={maxWidth - 200} height="11" />
  </ContentLoader>
)

export const AssetItemLoading = () => {
  const dimensions = useResponsiveDimensions()
  return <_AssetItemLoading maxWidth={dimensions.width - SIDEBAR_WIDTH} />
}
