import {safeAssertUnreachable} from '@nufi/frontend-common'
import React, {useState} from 'react'

import {existingBlockchains} from 'src/blockchainTypes'
import {Modal} from 'src/components'
import {useEnabledExistingBlockchains} from 'src/features/profile/application'
import {useSetEnabledBlockchains} from 'src/features/walletStorage/application/enableBlockchain'
import {useGetBalances} from 'src/wallet'
import type {Blockchain} from 'src/wallet'

import {ConfirmChangesScreen} from './ConfirmChangesScreen'
import {ManageBlockchainsScreen} from './ManageBlockchainsScreen'

type SubScreen = 'manageBlockchains' | 'confirmChanges'

export const ManageBlockchainsModal = ({onClose}: {onClose: () => void}) => {
  const enabledBlockchains = useEnabledExistingBlockchains()
  const balances = useGetBalances()
  const setEnabledBlockchainsMutation = useSetEnabledBlockchains()

  const blockchainsWithBalance = new Set(
    enabledBlockchains.filter(
      (blockchain) =>
        balances.data?.coins[blockchain]?.isGreaterThan(0) ||
        balances.data?.tokens[blockchain]?.some((t) =>
          t.amount.isGreaterThan(0),
        ),
    ),
  )

  return (
    <Modal variant="left" onClose={onClose}>
      <ManageBlockchainsModalContent
        blockchains={existingBlockchains}
        enabledBlockchains={enabledBlockchains}
        blockchainsWithBalance={blockchainsWithBalance}
        setEnabledBlockchainsMutation={setEnabledBlockchainsMutation}
        onClose={onClose}
      />
    </Modal>
  )
}

export type ManageBlockchainsModalContentProps = {
  blockchains: readonly Blockchain[]
  enabledBlockchains: readonly Blockchain[]
  blockchainsWithBalance: Set<Blockchain>
  setEnabledBlockchainsMutation: ReturnType<typeof useSetEnabledBlockchains>
  onClose: () => void
}

export const ManageBlockchainsModalContent = ({
  blockchains,
  enabledBlockchains,
  blockchainsWithBalance,
  setEnabledBlockchainsMutation,
  onClose,
}: ManageBlockchainsModalContentProps) => {
  const [currentScreen, setCurrentScreen] =
    useState<SubScreen>('manageBlockchains')

  const disabledBlockchains = blockchains.filter(
    (b) => !enabledBlockchains.includes(b),
  )

  const [selectedBlockchains, setSelectedBlockchains] =
    useState(enabledBlockchains)

  const newlyDisabledBlockchains = enabledBlockchains.filter(
    (b) => !selectedBlockchains.includes(b),
  )

  const newlyDisabledBlockchainsWithBalance = newlyDisabledBlockchains.filter(
    (b) => blockchainsWithBalance.has(b),
  )

  const isConfirmationNeeded = newlyDisabledBlockchainsWithBalance.length > 0

  const setEnabledBlockchains = async () => {
    try {
      await setEnabledBlockchainsMutation.mutateAsync([...selectedBlockchains])
    } catch {
      return
    }

    onClose()
  }

  const onSubmit = async () => {
    if (isConfirmationNeeded) {
      setCurrentScreen('confirmChanges')
      return
    }

    await setEnabledBlockchains()
  }

  const onConfirmSubmit = async () => {
    await setEnabledBlockchains()
  }

  const isLoading = setEnabledBlockchainsMutation.isPending

  switch (currentScreen) {
    case 'manageBlockchains':
      return (
        <ManageBlockchainsScreen
          blockchains={[...enabledBlockchains, ...disabledBlockchains]}
          selectedBlockchains={selectedBlockchains}
          setSelectedBlockchains={setSelectedBlockchains}
          isLoading={isLoading}
          error={setEnabledBlockchainsMutation.error}
          isConfirmationNeeded={isConfirmationNeeded}
          onSubmit={onSubmit}
          onCancel={onClose}
        />
      )
    case 'confirmChanges':
      return (
        <ConfirmChangesScreen
          disabledBlockchainsWithBalance={newlyDisabledBlockchainsWithBalance}
          onSubmit={onConfirmSubmit}
          onBack={() => setCurrentScreen('manageBlockchains')}
          isLoading={isLoading}
          error={setEnabledBlockchainsMutation.error}
        />
      )
    default:
      return safeAssertUnreachable(currentScreen)
  }
}
