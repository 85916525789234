import type {CardanoAccountInfo} from '@nufi/wallet-cardano'
import {useInfiniteQuery} from '@tanstack/react-query'
import {secondsToMilliseconds} from 'date-fns'
import {useMemo} from 'react'

import {useNeverStaleQuery} from 'src/utils/query-utils'

import type {DexHunterOrder, DexHunterTokensMap} from '../domain'
import {addCancellingState, mergeDexHunterOrders} from '../domain'
import {dexHunterApi} from '../infrastructure'

import {dexHunterQueryKeys} from './queryKeys'
import {useDexHunterState} from './store'

export const useDexHunterTokens = () => {
  return useNeverStaleQuery<DexHunterTokensMap>({
    queryKey: dexHunterQueryKeys.tokens,
    queryFn: () => dexHunterApi.getTokens(),
  })
}

export const useDexHunterOrders = (account: CardanoAccountInfo) => {
  const {localCancellingOrderIds} = useDexHunterState()
  const pageSize = 50
  const infiniteQueryRes = useInfiniteQuery<DexHunterOrder[]>({
    queryKey: dexHunterQueryKeys.orders(account.address),
    queryFn: ({pageParam}) => {
      const page = (pageParam || 0) as number
      return dexHunterApi.getOrders(account.address, page, pageSize)
    },
    initialPageParam: undefined,
    getNextPageParam: (lastPage, pages): number | null =>
      lastPage.length === pageSize ? pages.length : null,
    refetchInterval: secondsToMilliseconds(30),
    staleTime: 0,
  })

  const data = useMemo(() => {
    if (!infiniteQueryRes.data) return infiniteQueryRes.data
    return addCancellingState(
      mergeDexHunterOrders(infiniteQueryRes.data.pages),
      localCancellingOrderIds,
    )
  }, [infiniteQueryRes.data, localCancellingOrderIds])

  return {
    ...infiniteQueryRes,
    data,
  }
}
