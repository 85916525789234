import {Box, FormControl, Alert, Link as MuiLink, styled} from '@mui/material'
import {
  assert,
  getIsWebExtensionInstalled,
  getIsWebExtension,
} from '@nufi/frontend-common'
import React, {useState} from 'react'
import {Trans, useTranslation} from 'react-i18next'

import {getAvailableBlockchains} from 'src/features/availableBlockchains/application'
import {
  useGetDAppCategories,
  useGetDAppBlockchains,
} from 'src/features/dappDirectory/application'
import type {DappCategory} from 'src/features/dappDirectory/domain'
import {useAuthStore} from 'src/store/auth'

import {existingBlockchains} from '../../blockchainTypes'
import {
  BatchQueryGuard,
  InlineLoading,
  CenteredError,
  CollapsibleSearchField,
  SearchableSelect,
  BlockchainSelectField,
  SafeCenterAligner,
  Icon,
  LabeledIcon,
} from '../../components'
import {externalLinks} from '../../constants'
import {isBlockchainSubset} from '../../utils/blockchainGuards'
import {FilterInputWrapper, FiltersWrapper} from '../../utils/layoutUtils'
import type {Blockchain} from '../../wallet'

import {DAppDirectoryContent} from './DAppDirectoryContent'

export const DAppDirectory = () => {
  const {t} = useTranslation()

  const [category, setCategory] = useState<DappCategory | null>(null)
  const [_blockchain, setBlockchain] = useState<Blockchain | null>(null)
  const [searchText, setSearchText] = useState('')
  const categoriesQuery = useGetDAppCategories()
  const blockchainsQuery = useGetDAppBlockchains()

  const {authState} = useAuthStore()
  assert(authState.status === 'logged_in')

  // This is a quick fix meant for metamask snap.
  // The issue is that by default no blockchain is selected, and the endpoint returns
  // results for all blockchains in such a case. Filtering such result is problematic from
  // pagination perspective, therefore we simply pre-select first enabled blockchain
  // in that case.
  const availableBlockchains = getAvailableBlockchains()
  const blockchain =
    _blockchain != null || availableBlockchains.length > 1
      ? _blockchain
      : availableBlockchains[0]!

  return (
    <BatchQueryGuard
      queries={{
        dAppCategories: categoriesQuery,
        blockchains: blockchainsQuery,
      }}
      ErrorElement={
        <CenteredError error={t('Could not load supported DApps')} />
      }
      LoadingElement={
        <SafeCenterAligner>
          <InlineLoading />
        </SafeCenterAligner>
      }
    >
      {({dAppCategories, blockchains}) => {
        return (
          <>
            <Box display="flex" justifyContent="center">
              <Alert severity="info" sx={{width: 'fit-content', mb: 2, py: 1}}>
                {!getIsWebExtension() && !getIsWebExtensionInstalled() ? (
                  <Trans
                    i18nKey="dapp_directory_not_installed_banner"
                    t={t}
                    components={{
                      InstallExtensionLink: (
                        <MuiLink
                          href={externalLinks.chromeExtensionLink}
                          target="_blank"
                          rel="noopener"
                        />
                      ),
                      RestoreWalletGuideLink: (
                        <MuiLink
                          href={externalLinks.restoreWalletGuideLink}
                          target="_blank"
                          rel="noopener"
                        />
                      ),
                    }}
                  />
                ) : (
                  <Trans
                    i18nKey="dapp_directory_installed_banner"
                    t={t}
                    components={{
                      HowToEmulateLink: (
                        <MuiLink
                          href={externalLinks.howToEmulateExtensionLink}
                          target="_blank"
                          rel="noopener"
                        />
                      ),
                    }}
                  />
                )}
              </Alert>
            </Box>
            <FiltersWrapper>
              <FilterInputWrapper>
                <BlockchainSelectField
                  value={blockchain}
                  onChange={setBlockchain}
                  allowedBlockchains={blockchains
                    .map(({name}) => name)
                    .filter(
                      (blockchain) =>
                        isBlockchainSubset(blockchain, availableBlockchains) &&
                        isBlockchainSubset(blockchain, existingBlockchains),
                    )}
                  label={t('Blockchain')}
                />
              </FilterInputWrapper>
              <FilterInputWrapper>
                <CategorySelectField
                  value={category}
                  onChange={setCategory}
                  categories={dAppCategories}
                />
              </FilterInputWrapper>
              <FilterInputWrapper>
                <CollapsibleSearchField
                  value={searchText}
                  onChange={setSearchText}
                />
              </FilterInputWrapper>
            </FiltersWrapper>
            <DAppDirectoryContent
              {...{
                blockchain,
                category,
                searchText,
              }}
            />
          </>
        )
      }}
    </BatchQueryGuard>
  )
}

function CategorySelectField({
  categories,
  onChange,
  value,
}: {
  categories: DappCategory[]
  value: DappCategory | null
  onChange: (value: DappCategory | null) => void
}) {
  const {t} = useTranslation()
  const isMetamaskCategory = (category: DappCategory) =>
    category.toLocaleLowerCase() === 'metamask'

  const metamaskOption = categories.find(isMetamaskCategory)

  const options = metamaskOption
    ? [
        metamaskOption,
        ...categories.filter((category) => !isMetamaskCategory(category)),
      ]
    : categories

  return (
    <FormControl variant="outlined" fullWidth>
      <SearchableSelect
        {...{onChange, value}}
        label={t('Category')}
        options={options}
        getOptionLabel={(category) => category}
        searchable
        renderRow={(category) =>
          category && isMetamaskCategory(category) ? (
            <LabeledIcon
              Icon={<Icon type="metamaskIcon" />}
              Label={category}
              iconPosition="start"
            />
          ) : (
            category
          )
        }
        renderStartAdornment={(category) =>
          category &&
          isMetamaskCategory(category) && (
            <IconWrapper>
              <Icon type="metamaskIcon" />
            </IconWrapper>
          )
        }
      />
    </FormControl>
  )
}

const IconWrapper = styled('div')(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  paddingRight: theme.spacing(1),
  paddingLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
}))
