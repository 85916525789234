/* eslint-disable @typescript-eslint/explicit-function-return-type */
import type {AccountId} from '@nufi/wallet-common'

import {conversionRatesQueryKeys} from 'src/features/conversionRates/application/queryKeys'

import {crossBlockchainQueryKeys} from '../../public/queries/queryKeys'

import {
  combinedQueryKeys,
  nftsQueryKeys,
  queryKeys,
  tokensQueryKeys,
} from './queries'

// Order of invalidation keys matters! More info can be found in docs/queries.md.

export const tokensInvalidationKeys = [
  // Nfts must be before tokens as some tokens queries are building on top
  // of nft queries.
  nftsQueryKeys.index,
  tokensQueryKeys.tokens,
  conversionRatesQueryKeys.index,
  combinedQueryKeys.balance.index,
]

export const stakeAccountsInvalidationKeys = [
  queryKeys.stakeAccounts,
  queryKeys.stakeAccountsInfo.index,
  queryKeys.rewardHistory.index,
  queryKeys.stakeTxHistory.index,
  queryKeys.totalStakedAPY,
]

export const accountsInvalidationKeys = [
  queryKeys.accounts,
  queryKeys.tokens,
  ...stakeAccountsInvalidationKeys,
  conversionRatesQueryKeys.index,
  queryKeys.balance.index,
  crossBlockchainQueryKeys.index,
]

export const getTxHistoryInvalidationKey = (accountId?: AccountId) => {
  if (accountId) {
    return queryKeys.txHistory.account(accountId).index
  } else {
    return queryKeys.txHistory.index
  }
}

export const submitTransferInvalidationKeys = [
  ...accountsInvalidationKeys,
  getTxHistoryInvalidationKey(),
]
