import React from 'react'

import {QueryGuard} from 'src/components/DataGuards'
import {FormattedComponentGuardProps} from 'src/components/formatting/utils'
import type {Blockchain, TokenId, TokenMetadata} from 'src/types'

import type {UseGetTokenMetadata} from '../queries'
import {useGetTokenMetadata} from '../queries'

type GuardProps = Pick<
  React.ComponentProps<typeof QueryGuard>,
  'LoadingElement' | 'ErrorElement'
>

type ViewModel = {
  useGetTokenMetadata: UseGetTokenMetadata
}

const defaultViewModel: ViewModel = {
  useGetTokenMetadata,
}

/**
 * If `tokenId` is defined will load the `tokenMetadata`, otherwise will return `undefined`.
 * Handy for code when `tokenId` is being passed instead of `tokenMetadata`, but subsequent
 * code requires `tokenMetadata`.
 */
export const WithWeakTokenMetadata = ({
  blockchain,
  tokenId,
  children,
  // This component is currently mainly used by conversion loading components
  guardProps = FormattedComponentGuardProps,
  vm = defaultViewModel,
}: {
  blockchain: Blockchain | null
  tokenId?: TokenId | null
  children: (d: TokenMetadata | undefined) => React.ReactElement
  guardProps?: GuardProps
  vm?: ViewModel
}) => {
  if (blockchain == null) return children(undefined)

  if (tokenId != null) {
    return (
      <WithTokenMetadata
        blockchain={blockchain}
        tokenId={tokenId}
        children={children}
        guardProps={guardProps}
        vm={vm}
      />
    )
  }
  return children(undefined)
}

/**
 * Given `tokenId` fetches `tokenMetadata`.
 * Handy for code when `tokenId` is being passed instead of `tokenMetadata`, but subsequent
 * code requires `tokenMetadata`.
 */
export const WithTokenMetadata = ({
  blockchain,
  tokenId,
  children,
  // This component is currently mainly used by conversion loading components
  guardProps = FormattedComponentGuardProps,
  vm = defaultViewModel,
}: {
  blockchain: Blockchain
  tokenId: TokenId
  children: (d: TokenMetadata) => React.ReactElement
  guardProps?: GuardProps
  vm?: ViewModel
}) => {
  const tokenMetadataQuery = vm.useGetTokenMetadata(tokenId, blockchain)
  return (
    <QueryGuard {...tokenMetadataQuery} {...guardProps}>
      {(data) => children(data)}
    </QueryGuard>
  )
}
