import {ErrorOutline as ErrorIcon} from '@mui/icons-material'
import {Alert, Grid, Link, Typography} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import {useTranslation} from 'react-i18next'

// TODO: can we reasonably abstract this?
import {ChangellyRequestError} from 'src/features/exchange/infrastructure/changelly'

type SwapErrorProps = {
  error: ChangellyRequestError | unknown
  errorMessage?: string
  isPartnerError?: boolean
}

export function SwapError({
  error,
  errorMessage,
  isPartnerError = false,
}: SwapErrorProps) {
  const {t} = useTranslation()
  const classes = useStyles()
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      className={classes.content}
    >
      <Grid
        item
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <Grid item container direction="column" alignItems="center">
          <ErrorIcon fontSize="large" color="error" />
          <Typography variant="h6">{t('An error occurred.')}</Typography>
        </Grid>
        {isPartnerError && (
          <Grid item container direction="column" alignItems="center">
            <Typography>
              {t("For more information please refer to our partner's")}
            </Typography>
            <Typography>
              <Link
                target="_blank"
                href="https://github.com/changelly/api-changelly#error-codes"
              >
                {t('Error code documentation')}
              </Link>
            </Typography>
          </Grid>
        )}
        <Grid item container direction="column" alignItems="center">
          <Alert severity="error" sx={{alignItems: 'center'}}>
            <Typography>
              {isPartnerError
                ? error instanceof ChangellyRequestError
                  ? `Code: ${error.code}, Message: ${error.message}`
                  : t("Unknown error on the partner's side")
                : errorMessage ||
                  t(
                    'We were unable to process the exchange, please try again. If the error persists please contact our support.',
                  )}
            </Typography>
          </Alert>
        </Grid>
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles((theme) => ({
  content: {
    minHeight: '100%',
    padding: theme.spacing(2),
  },
}))
