import {parseMediaUrl, replaceWithGateway} from '@nufi/frontend-common'

import {nftBlockchains} from '../../blockchainTypes'
import config from '../../config'
import type {Nft, TokenId} from '../../types'
import {assert, safeAssertUnreachable} from '../../utils/assertion'
import {isBlockchainSubset} from '../../utils/blockchainGuards'
import {isEvmBlockchain} from '../evm/utils'

export const openImageData = (imgData: string): void => {
  const image = new Image()
  image.src = imgData
  const newTab = window.open('')
  newTab?.document.write(image.outerHTML)
}

const THUMBNAIL_GATEWAY = config.thumbnailUrl
const CID_THUMBNAIL_GATEWAY = `${THUMBNAIL_GATEWAY}?cid=`

export const getIpfsImageThumbnailUri = (ipfsUrl: string): string =>
  replaceWithGateway(ipfsUrl, CID_THUMBNAIL_GATEWAY)

export const mediaUrlToThumbnailUri = (
  url: string | null | undefined,
): string =>
  url
    ? parseMediaUrl(url, CID_THUMBNAIL_GATEWAY, `${THUMBNAIL_GATEWAY}?url=`)
    : ''

type ParsedNftData = {
  name: string
  imageUri: string
  id: TokenId
  collectionName: null | string
}

export const parseNft = (nft: Nft): ParsedNftData => {
  assert(isBlockchainSubset(nft.blockchain, nftBlockchains))
  switch (nft.blockchain) {
    case 'cardano': {
      return {
        name: nft.name,
        imageUri: mediaUrlToThumbnailUri(nft.image),
        id: nft.id,
        collectionName: null, // TODO: get collection identifier
      }
    }
    case 'solana':
      return {
        name: nft.name,
        imageUri: mediaUrlToThumbnailUri(nft.image),
        id: nft.id,
        collectionName: nft.ticker,
      }
    case 'flow': {
      const mediaUri = nft.media.imageMedia?.uri || nft.media.videoMedia?.uri
      return {
        name: nft.metadata?.title,
        imageUri: mediaUrlToThumbnailUri(mediaUri),
        id: nft.id,
        collectionName: nft.contractMetadata.collection.name,
      }
    }
    default: {
      if (isEvmBlockchain(nft.blockchain)) {
        return {
          name: nft.name,
          imageUri: mediaUrlToThumbnailUri(nft.media.uri),
          id: nft.id,
          collectionName: nft.contractMetadata.name,
        }
      }
      return safeAssertUnreachable(nft.blockchain)
    }
  }
}
