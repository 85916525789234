import {assert} from '@nufi/frontend-common'

import type {UntypedBlockchain} from './blockchain'

export const isBlockchainSubset = <T extends ReadonlyArray<UntypedBlockchain>>(
  blockchain: unknown,
  blockchainSubset: T,
): blockchain is T[number] => blockchainSubset.includes(blockchain as T[number])

export function assertBlockchainIsInSubset<
  T extends ReadonlyArray<UntypedBlockchain>,
>(blockchain: unknown, blockchainSubset: T): asserts blockchain is T[number] {
  assert(
    isBlockchainSubset(blockchain, blockchainSubset),
    `Section not yet supported for: ${blockchain}`,
  )
}

export function assertBlockchainIsInSubsetOrNull<
  T extends ReadonlyArray<UntypedBlockchain>,
>(
  blockchain: unknown,
  blockchainSubset: T,
): asserts blockchain is T[number] | null {
  assert(
    blockchain == null || isBlockchainSubset(blockchain, blockchainSubset),
    `Section not yet supported for: ${blockchain}`,
  )
}
