import BigNumber from 'bignumber.js'
import React from 'react'

import {useGetBlockchainName, useGetCoinName} from 'src/utils/translations'
import {parseNft} from 'src/wallet/utils/nfts'

import type {Blockchain, TokenMetadata} from '../types'
import {
  getTokenNameAndIdentifier,
  isNft as getIsNft,
  useGetNft,
} from '../wallet'

import {AssetIcon} from './AssetIcon'
import {QueryGuard} from './DataGuards'
import {FormattedAsset, getTickerBasedIdentifier} from './formatting'
import {AssetAmountField} from './visual'

type AssetInfoProps =
  | ({
      type: 'native'
    } & NativeAssetInfoProps)
  | ({type: 'token'} & TokenInfoProps)

export function AssetInfo(props: AssetInfoProps) {
  return props.type === 'native' ? (
    <NativeAssetInfo {...props} />
  ) : (
    <TokenInfo {...props} />
  )
}

type NativeAssetInfoProps = {
  blockchain: Blockchain
  amount: BigNumber
}

const NativeAssetInfo = ({blockchain, amount}: NativeAssetInfoProps) => {
  const getTicker = useGetCoinName()
  const getName = useGetBlockchainName()

  return (
    <AssetAmountField
      isViewOnly
      assetName={getName(blockchain)}
      ticker={getTicker(blockchain)}
      id={getName(blockchain)}
      amount={amount}
      FormattedBalance={
        <FormattedAsset
          includeAssetSymbol
          amount={new BigNumber(amount)}
          blockchain={blockchain}
          isSensitiveInformation={false}
        />
      }
      renderAssetIcon={(iconSize: number) => (
        <AssetIcon
          exactSize={iconSize}
          blockchain={blockchain}
          showBlockchainBadge
        />
      )}
    />
  )
}

export type TokenInfoProps = {
  tokenMetadata: TokenMetadata
  amount?: BigNumber
}

const TokenInfo = ({tokenMetadata, amount}: TokenInfoProps) => {
  const {blockchain} = tokenMetadata
  const nftQuery = useGetNft(tokenMetadata.id, blockchain)

  return (
    <QueryGuard {...nftQuery} data={nftQuery.data}>
      {(nftInfo) => {
        const {assetName, tokenIdentifier} = getTokenNameAndIdentifier(
          nftInfo,
          tokenMetadata,
        )

        const isNft = getIsNft(tokenMetadata)

        const nftData = (isNft && !!nftInfo && parseNft(nftInfo)) || null

        return (
          <AssetAmountField
            isViewOnly
            amount={amount}
            helperText={null}
            assetName={assetName}
            ticker={getTickerBasedIdentifier(tokenMetadata) || undefined}
            id={tokenIdentifier}
            FormattedBalance={
              <FormattedAsset
                includeAssetSymbol
                amount={amount}
                blockchain={blockchain}
                tokenMetadata={tokenMetadata}
                isSensitiveInformation={false}
              />
            }
            renderAssetIcon={(iconSize: number) => (
              <AssetIcon
                exactSize={iconSize}
                blockchain={blockchain}
                tokenMetadata={tokenMetadata}
                showBlockchainBadge
              />
            )}
            {...(nftData
              ? {
                  isNft: true,
                  nftImgSrc: nftData.imageUri,
                  collectionName: nftData.collectionName ?? undefined,
                }
              : {
                  isNft: false,
                })}
          />
        )
      }}
    </QueryGuard>
  )
}
