export const colors = {
  common: {
    white: '#ffffff',
  },
  primary: {
    main: '#BAE440',
    light: '#D8FF52',
    dark: '#7AAD00',
    textContrast: '#8BB300',
  },
  secondary: {
    main: '#383838',
    light: '#5E5E5E',
    dark: '#2E2E2E',
    textContrast: '#ffffffdd',
  },
  divider: 'rgba(255, 255, 255, 0.12)',
  error: {
    main: '#F44336',
    light: '#E57373',
    dark: '#D32F2F',
    textContrast: '#FFFFFF',
    background: '#553939',
    alertContent: '#FAB3AE',
  },
  success: {
    background: '#3A473A',
    alertContent: '#B7DFB9',
  },
  warning: {
    background: '#4D4237',
    alertContent: '#FFD599',
  },
  info: {
    main: '#2196F3',
    light: '#64B5F6',
    dark: '#1976D2',
    textContrast: '#FFFFFF',
    background: '#343249',
    alertContent: '#C7CDFF',
  },
  background: {
    default: '#212121',
    paper: '#292929',
    overlay: 'rgba(255,255,255, 0.1)',
    overlayLight: 'rgba(255,255,255, 0.05)',
  },
  text: {
    secondary: 'rgba(255, 255, 255, 0.7)',
  },
  action: {
    active: 'rgba(255, 255, 255, 0.56)',
  },
  grey: {
    A400: '#303030',
  },
} as const

export const palette = {
  mode: 'dark',
  primary: {
    main: colors.primary.main,
    light: colors.primary.light,
    dark: colors.primary.textContrast,
  },
  secondary: {
    main: colors.secondary.main,
    light: colors.secondary.light,
    dark: colors.secondary.dark,
    contrastText: colors.secondary.textContrast,
  },
  divider: colors.divider,
  error: {
    main: colors.error.main,
    light: colors.error.light,
    dark: colors.error.dark,
    contrastText: colors.error.textContrast,
  },
  info: {
    main: colors.info.main,
    light: colors.info.light,
    dark: colors.info.dark,
    contrastText: colors.info.textContrast,
  },
  background: {
    ...colors.background,
  },
  text: {
    secondary: colors.text.secondary,
  },
  action: {
    active: colors.action.active,
  },
  grey: {
    A400: colors.grey.A400,
  },
  default: {main: colors.secondary.textContrast},
} as const
