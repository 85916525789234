import type {CardanoAddress} from '@nufi/wallet-cardano'
import type BigNumber from 'bignumber.js'
import _ from 'lodash'

import type {Cbor} from 'src/dappConnector/api/cardano/types'

export type DexHunterToken = {
  id: string
  ticker: string
}

export type DexHunterOrderStatus =
  | 'PENDING'
  | 'COMPLETED'
  | 'CANCELLED'
  | 'BATCHING'
  | 'EXPIRED'
  | 'LIMIT'
  | 'STOPLOSS'
  | 'CANCELLING' // our custom state

export type DexHunterOrder = {
  id: string
  txHash: string
  dex: {
    name: string
    url: string | null
    logo: string
    code: string
  }
  fromAssetId: string
  fromAmount: BigNumber
  toAssetId: string
  toAmount: BigNumber
  status: DexHunterOrderStatus
  submissionTime: string
}

export type DexHunterTokensMap = Record<DexHunterToken['id'], DexHunterToken>

export interface DexHunterApi {
  getTokens: () => Promise<DexHunterTokensMap>
  getOrders: (
    address: CardanoAddress,
    page: number,
    pageSize: number,
  ) => Promise<DexHunterOrder[]>
  cancelOrder: (
    orderId: DexHunterOrder['id'],
    address: CardanoAddress,
  ) => Promise<{cborTx: Cbor<'transaction'>}>
}

const NATIVE_ASSET_ID =
  '000000000000000000000000000000000000000000000000000000006c6f76656c616365'

const DEX_HUNTER_ADA_ASSET_INFO = {
  ticker: 'ADA',
} as const

type DexHunterAdaAsset = typeof DEX_HUNTER_ADA_ASSET_INFO

export type DexHunterAssetInfo =
  | {type: 'native'; info: DexHunterAdaAsset}
  | {type: 'token'; info: DexHunterToken | null}

export type DexHunterOrderAssetInfo = {
  fromAsset: DexHunterAssetInfo
  toAsset: DexHunterAssetInfo
}

export const getDexHunterOrderAssetsInfo = (
  tokens: DexHunterTokensMap,
  order: DexHunterOrder,
): DexHunterOrderAssetInfo => {
  const wrapToken = (token: DexHunterToken | undefined) => ({
    type: 'token' as const,
    info: token ?? null,
  })

  const nativeAssetResult = {
    type: 'native' as const,
    info: DEX_HUNTER_ADA_ASSET_INFO,
  }

  const fromAsset: DexHunterAssetInfo =
    order.fromAssetId === NATIVE_ASSET_ID
      ? nativeAssetResult
      : wrapToken(tokens[order.fromAssetId])

  const toAsset: DexHunterAssetInfo =
    order.toAssetId === NATIVE_ASSET_ID
      ? nativeAssetResult
      : wrapToken(tokens[order.toAssetId])

  return {fromAsset, toAsset}
}

export type LocalCancelingOrderStore = {
  localCancellingOrderIds: Set<DexHunterOrder['id']>
  registerCancellingOrderId: (orderId: DexHunterOrder['id']) => void
  unregisterCancellingOrderId: (orderId: DexHunterOrder['id']) => void
}

export const addCancellingState = (
  orders: DexHunterOrder[],
  localCancellingOrderIds: LocalCancelingOrderStore['localCancellingOrderIds'],
) => {
  return orders.map((order) => {
    const status =
      localCancellingOrderIds.has(order.id) && order.status === 'PENDING'
        ? 'CANCELLING'
        : order.status
    return {...order, status}
  })
}

export const mergeDexHunterOrders = (
  orders: DexHunterOrder[][],
): DexHunterOrder[] => _.uniqBy(orders.flat(), ({id}) => id)

// Note that "STOPLOSS" is cancelable via separate endpoint,
// but it does not seem to be possible to create "STOPLOSS" via
// the Widget, thus we are not supporting it.
export const isCancellable = (order: DexHunterOrder): boolean =>
  order.status === 'PENDING' || order.status === 'LIMIT'
