import type {MutationKey} from '@tanstack/react-query'
import orderBy from 'lodash/orderBy'
import {useEffect} from 'react'
import {v4 as uuidV4} from 'uuid'
import type {StateCreator} from 'zustand'
import create from 'zustand'
import {persist} from 'zustand/middleware'

import {mutationSubscriber} from 'src/utils/mutation-utils'
import {getAvailableEvmBlockchains} from 'src/wallet/evm/evmBlockchains'

import MasterProfileManager from '../../appStorage/masterProfileManager'
import {profileManagerLocator} from '../../appStorage/profileManager'
import type {AccountId} from '../../types'
import {pendingTxError} from '../../wallet/utils/errors'
import logger from '../logger'

import {
  makeTxStorage,
  makeShouldRehydrateOnStorageEvent,
  transformLocalToPersistent,
  mergePersistentWithLocal,
} from './persistentUtils'
import {processSubscriptionEvent} from './subscriptionUtils'
import type {
  KeyValueData,
  TransactionContext,
  TransactionInfo,
  TransactionState,
} from './types'

export * from './types'

// This should be bumped anytime the schema of data changes so data
// built with an earlier schema don't brick the app.
const VERSION = 0
const STORAGE_KEY = 'recentTransactions'
const getPersistentBlockchains = () => getAvailableEvmBlockchains()

const dependencies = {
  getCurrentProfileId: () => MasterProfileManager.getCurrentProfileId(),
  encryptProfileTxData: async (data: KeyValueData) =>
    await profileManagerLocator.instance().experimental.encryptCustomData(data),
  decryptProfileTxData: async (data: string) =>
    await profileManagerLocator.instance().experimental.decryptCustomData(data),
}

const txStorage = makeTxStorage(dependencies)
const shouldRehydrateOnStorageEvent =
  makeShouldRehydrateOnStorageEvent(dependencies)

// Zustand/partial utils are assigned here, as we need to turn off TS when assigning
// these to middlewares, as zustand/partial seems to have a bit odd types, plus
// we do not really perform serialize/deserialize the way zustand expects.
// This way we can at least check that values passed to helpers are correct.
const getStorage = () => ({
  getItem: txStorage.getCurrentProfileItem,
  setItem: txStorage.setCurrentProfileItem,
  removeItem: txStorage.removeItem,
})
const partialize = (state: TransactionState) =>
  transformLocalToPersistent(state, getPersistentBlockchains())
const merge = mergePersistentWithLocal
// We are not really transforming the data within these serializing methods
// (and according to expected `zustand` types),
// as this is done by the `txStorage` given, we need to keep data for
// all profiles, but init the state only with the data for a single profile.
// Therefore we just turn off TS for these methods.
const deserialize = (data: TransactionState): TransactionState => data
const serialize = (data: TransactionState): TransactionState => data

export const useTransactionState = create<TransactionState>(
  logger<TransactionState>('TxState:')(
    persist(
      (set, get) => ({
        transactions: [],
        getVisibleTransactions: () =>
          orderBy(
            get().transactions.filter((tx) => tx.clearedAt == null),
            (tx) => tx.createdAt.getTime(),
            'desc',
          ),
        pushTransaction: (tx) => {
          const _tx = {
            ...tx,
            customId: uuidV4(),
            createdAt: new Date(),
          }
          set(({transactions}) => ({
            transactions: [_tx, ...transactions],
          }))
        },
        updateTransaction: (tx) =>
          set(({transactions}) => ({
            transactions: transactions.map((m) =>
              m.customId === tx.customId ? tx : m,
            ),
          })),
        isTransactionPending: (accountId) =>
          get().transactions.some(
            // While retrying transactions, for a brief period context is undefined
            // As a failsafe, treat pending, contextless transactions as pending regardless of account ID
            (tx) =>
              tx.isPending &&
              (tx.context ? tx.context.accountId === accountId : true),
          ),
        clear: (tx: TransactionInfo) =>
          set(({transactions}) => ({
            transactions: transactions.map((transaction) =>
              transaction.customId === tx.customId
                ? {...transaction, clearedAt: new Date()}
                : transaction,
            ),
          })),
        clearNonPendingTxs: () =>
          set(({transactions}) => ({
            transactions: transactions.map((transaction) => {
              // We keep pending transactions, as removing
              // even these has bigger consequences for users, e.g.
              // users will lose the option to cancel/speedup them on ethereum.
              if (transaction.isPending) return transaction
              return {
                ...transaction,
                clearedAt:
                  transaction.clearedAt != null
                    ? transaction.clearedAt
                    : new Date(),
              }
            }),
          })),
        clearFailedTxs: () =>
          set(({transactions}) => ({
            transactions: transactions.map((transaction) => {
              if (!transaction.error) return transaction
              return {
                ...transaction,
                clearedAt:
                  transaction.clearedAt != null
                    ? transaction.clearedAt
                    : new Date(),
              }
            }),
          })),
      }),
      {
        version: VERSION,
        name: STORAGE_KEY,
        // See the above explanation, about why we suppress TS here.
        // eslint-disable-next-line
        // @ts-ignore
        deserialize,
        // eslint-disable-next-line
        // @ts-ignore
        serialize,
        // eslint-disable-next-line
        // @ts-ignore
        getStorage,
        // eslint-disable-next-line
        // @ts-ignore
        partialize,
        merge,
      },
    ) as StateCreator<TransactionState>,
  ),
)

export function useTrackTransactions(mutationKeys: MutationKey[]) {
  const pushTransaction = useTransactionState((state) => state.pushTransaction)
  const updateTransaction = useTransactionState(
    (state) => state.updateTransaction,
  )

  useEffect(() => {
    const unsubscribe = mutationSubscriber(mutationKeys, (m) => {
      const tx = processSubscriptionEvent({
        ...m,
        context: m.context as TransactionContext,
      })

      if (tx.context) {
        const transactions = useTransactionState.getState().transactions

        const existingTx = transactions.find(
          (t) =>
            (tx.mutationId != null && t.mutationId === tx.mutationId) ||
            // Avoid creating multiple entries when calling 'retry',
            // as this creates mutation with a new mutationId.
            (tx.context?.transactionId &&
              t.context?.transactionId === tx.context?.transactionId),
        )

        if (existingTx) {
          updateTransaction({
            ...tx,
            ...existingTx.context,
            customId: existingTx.customId,
            createdAt: existingTx.createdAt,
            clearedAt: existingTx.clearedAt,
          })
        } else {
          pushTransaction(tx)
        }
      }
    })
    return unsubscribe
  }, [])
}

export const refreshTransactionsStore = () => {
  // See: https://github.com/pmndrs/zustand/blob/main/docs/integrations/persisting-store-data.md
  // eslint-disable-next-line
  // @ts-ignore
  useTransactionState.persist.rehydrate()
}

export const clearPersistentTxStore = async () => {
  // Note that this will clear it only for the current profile,
  // as `setItem` is current profile aware
  await txStorage.setCurrentProfileItem(STORAGE_KEY, {
    version: VERSION,
    state: {transactions: []},
  })
}

// Wrapped in a function as `jest` is not a big fan of `window.addEventListener`
export const registerTransactionStoreListener = () => {
  window.addEventListener('storage', async (e) => {
    if (e.key === STORAGE_KEY) {
      if (
        // As each `rehydrate` causes write to a persistent storage, we need to avoid
        // 'event' -> 'rehydrate' -> 'event' -> 'rehydrate' infinite cycle, and only
        // rehydrate when data actually changed.
        // Note that `e.oldValue !== e.newValue` is not enough, as our encryption function
        // produces new value even if encrypting the same data. So such a check would still
        // cause re-save loop.
        await shouldRehydrateOnStorageEvent(
          STORAGE_KEY,
          useTransactionState.getState(),
        )
      ) {
        refreshTransactionsStore()
      } else {
        // eslint-disable-next-line no-console
        console.log('txStore: prevented unnecessary re-save of data')
      }
    }
  })
}

// Useful e.g. when pushing a transaction from connector window, without
// doing it via react-query
export const pushTransactionImperative = <T extends TransactionInfo['context']>(
  context: T,
) => {
  const {pushTransaction} = useTransactionState.getState()
  const now = new Date()

  pushTransaction({
    isPending: true,
    data: undefined,
    error: undefined,
    updatedAt: now,
    context,
  })
}

export const ensureNoPendingTx = (accountId: AccountId): void => {
  const isPending = useTransactionState
    .getState()
    .isTransactionPending(accountId)
  if (isPending) {
    throw new Error(pendingTxError)
  }
}
