import React from 'react'

import type {Web3AuthUserInfo} from 'src/features/login/domain'

import {TypographyLoader, ValueInCurrencyError} from '../visual'

export const formatWeb3AuthUserName = ({
  typeOfLogin,
  email,
  name,
}: Pick<Web3AuthUserInfo, 'typeOfLogin' | 'email' | 'name'>) =>
  (typeOfLogin === 'google' ? email : name) ?? 'unknown user'

export const FormattedComponentGuardProps = {
  LoadingElement: <TypographyLoader variant="body2" />,
  ErrorElement: <ValueInCurrencyError />,
}
