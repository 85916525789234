import {useTranslation} from 'react-i18next'

import type {TransactionInfo} from '../../../store/transactions'

// TODO: divide giant `TopBar` into more files,
// add them here, and create corresponding stories

export const getTxStatusColor = (tx: TransactionInfo) => {
  if (tx.context?.blockchain === 'ethereum') {
    if (tx.context.isDropped) return 'gray'
  }

  if (tx.isPending) return 'yellow'
  if (tx.error) return 'red'
  return 'green'
}

export const useGetTxStatusText = () => {
  const {t} = useTranslation()

  return (tx: TransactionInfo) => {
    if (tx.context?.blockchain === 'ethereum') {
      if (tx.context.isDropped) return t('is dropped and replaced')
    }

    if (tx.isPending) return t('is pending')
    if (tx.error) return t('failed')
    return t('success')
  }
}
