import {Close as CloseIcon} from '@mui/icons-material'
import type {IconButtonProps, Theme, DialogProps} from '@mui/material'
import {Dialog, Grid, Box, IconButton, Divider} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import React from 'react'

type Variant = 'left' | 'full-width' | 'centered'

type CloseButtonProps = {
  onClose: () => unknown
} & IconButtonProps

export function CloseButton({onClose, ...rest}: CloseButtonProps) {
  return (
    <IconButton
      edge="start"
      color="inherit"
      onClick={onClose}
      size="large"
      {...rest}
    >
      <CloseIcon />
    </IconButton>
  )
}

type ModalHeaderProps = {
  children?: React.ReactNode
  hasDivider?: boolean
  onClose?: () => unknown
}

export function ModalHeader({children, onClose, hasDivider}: ModalHeaderProps) {
  const classes = useModalHeaderStyles()

  return (
    <Box>
      <Box className={classes.container}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>{children}</Grid>
          {onClose && (
            <Grid item>
              <CloseButton onClose={onClose} />
            </Grid>
          )}
        </Grid>
      </Box>
      {hasDivider && <Divider />}
    </Box>
  )
}

type ModalFooterProps = {
  children?: React.ReactNode
  hasDivider?: boolean
}

export function ModalFooter({children, hasDivider}: ModalFooterProps) {
  const classes = useModalFooterStyles()

  return (
    <Box>
      {hasDivider && <Divider />}
      <Box className={classes.container}>{children}</Box>
    </Box>
  )
}

type ModalProps = {
  onClose?: () => unknown
  children: React.ReactNode
  variant: Variant
  dismissable?: boolean
  open?: boolean
  className?: string
  maxWidthOverride?: number
} & Omit<DialogProps, 'open'>

export function Modal({
  onClose,
  children,
  variant,
  dismissable = false,
  open = true,
  className = '',
  maxWidthOverride,
  ...rest
}: ModalProps) {
  const classes = useStyles({variant, maxWidthOverride})
  return (
    <Dialog
      classes={{
        paper: clsx(classes.root, className),
        paperScrollPaper: clsx(
          variant !== 'full-width' && classes.paperScrollPaper,
        ),
      }}
      open={open}
      fullScreen={variant === 'full-width'}
      onClose={onClose}
      disableEscapeKeyDown={!dismissable}
      {...rest}
    >
      {children}
    </Dialog>
  )
}

export const MODAL_RESPONSIVE_WIDTHS = {old: 500, zoomed: 550, default: 650}

const useStyles = makeStyles<
  Theme,
  {variant: Variant; maxWidthOverride?: number}
>((theme) => ({
  root: ({variant, maxWidthOverride}) => {
    const commonStyles = {
      display: 'flex',
      maxWidth: maxWidthOverride ?? 'none',
    }

    switch (variant) {
      case 'full-width':
        return {
          width: '100vw',
          height: '100vh',
          ...commonStyles,
        }
      case 'left':
        return {
          ...createCustomResponsiveClass(
            theme,
            MODAL_RESPONSIVE_WIDTHS,
            'width',
          ),
          position: 'absolute',
          right: 0,
          height: '100vh',
          ...commonStyles,
        }
      case 'centered':
        return commonStyles
      default:
        return {}
    }
  },
  body: {
    flex: 1,
  },
  paperScrollPaper: {
    maxHeight: 'calc(100% - 32px)',
    [theme.breakpoints.up('windowsZoomed')]: {
      maxHeight: 'calc(100% - 64px)',
    },
  },
}))

type ModalLayoutProps = {
  header?: React.ReactNode
  body: React.ReactNode
  footer?: React.ReactNode
  className?: string
}

export function ModalLayout({
  header,
  body,
  footer,
  className,
}: ModalLayoutProps) {
  const classes = useModalLayoutStyles()
  return (
    <>
      {header}
      <div className={clsx(classes.body, className)}>{body}</div>
      {footer}
    </>
  )
}

const useModalLayoutStyles = makeStyles(() => ({
  body: {
    flex: 1,
    overflow: 'auto',
  },
}))

const useModalHeaderStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(1, 2),
    [theme.breakpoints.up('windowsOld')]: {
      padding: theme.spacing(2),
    },
  },
}))
const useModalFooterStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(1, 2, 2, 2),
    [theme.breakpoints.up('windowsOld')]: {
      padding: theme.spacing(2),
    },
  },
}))

export const useModalSharedStyles = makeStyles((theme) => ({
  formField: {
    ...createResponsiveClasses(
      theme,
      [2, 'marginBottom'],
      [1.5, 'paddingBottom'],
    ),
  },
  divider: {
    ...createResponsiveClasses(theme, [3, 'marginBottom']),
  },
  dividerY: {
    ...createResponsiveClasses(theme, [1, 'marginTop'], [1, 'marginBottom']),
  },
  passwordHeight: {
    ...createCustomResponsiveClass(
      theme,
      {default: 110, zoomed: 82.5, old: 70},
      'minHeight',
    ),
  },
  commonBottomPadding: {
    ...createResponsiveClasses(theme, [2, 'paddingBottom']),
  },
  commonBottomMargin: {
    ...createResponsiveClasses(theme, [2, 'marginBottom']),
  },
  commonTopPadding: {
    ...createResponsiveClasses(theme, [2, 'paddingTop']),
  },
  commonTopMargin: {
    ...createResponsiveClasses(theme, [2, 'marginTop']),
  },
  formWrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  smallScreenDivider: {
    ...createCustomResponsiveClass(
      theme,
      {old: 1.5, zoomed: 1, default: 0},
      'marginTop',
    ),
  },
  responsiveAlert: {
    ...createResponsiveClasses(
      theme,
      [0.78, 'paddingTop'],
      [0.75, 'paddingBottom'],
    ),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  responsiveSpacing: {
    '& > *': {
      ...createResponsiveClasses(theme, [2, 'marginBottom']),
    },
  },
}))

type ResponsiveStates = {
  default: number
  zoomed: number
  old: number
}

type ResponsiveClassesArray = [number, SpacingKeys | SizeKeys]

type SpacingKeys =
  | 'margin'
  | 'padding'
  | 'marginBottom'
  | 'paddingBottom'
  | 'paddingTop'
  | 'paddingBottom'
  | 'marginTop'

const sizeKeys = ['minHeight', 'maxWidth', 'width', 'fontSize'] as const
type SizeKeys = (typeof sizeKeys)[number]

const isSizeKey = (x: SpacingKeys | SizeKeys): x is SizeKeys =>
  sizeKeys.includes(x as SizeKeys)

const createBreakpointSpecificClass = (
  theme: Theme,
  props: ResponsiveClassesArray[],
  multiplier: number,
) => {
  const obj = Object.assign(
    {},
    ...props.map((prop) => ({
      [prop[1]]: determinateClassProp(theme, prop[0], prop[1], multiplier),
    })),
  )
  return obj
}

const determinateClassProp = (
  theme: Theme,
  val: number,
  type: SpacingKeys | SizeKeys,
  multiplier: number,
) => (isSizeKey(type) ? val * multiplier : theme.spacing(val * multiplier))

// this function can be run only with single responsive property in class
export const createCustomResponsiveClass = (
  theme: Theme,
  val: ResponsiveStates,
  type: SpacingKeys | SizeKeys,
) => ({
  [type]: determinateClassProp(theme, val.old, type, 1),
  [theme.breakpoints.up('windowsOld')]: {
    [type]: determinateClassProp(theme, val.zoomed, type, 1),
  },
  [theme.breakpoints.up('windowsZoomed')]: {
    [type]: determinateClassProp(theme, val.default, type, 1),
  },
})

export const createResponsiveClasses = (
  theme: Theme, // as this function is used outside of components
  ...props: ResponsiveClassesArray[]
) => ({
  ...createBreakpointSpecificClass(theme, props, 0.5),
  [theme.breakpoints.up('windowsOld')]: {
    ...createBreakpointSpecificClass(theme, props, 0.75),
  },
  [theme.breakpoints.up('windowsZoomed')]: {
    ...createBreakpointSpecificClass(theme, props, 1),
  },
})

export default Modal
