import type {AccountId} from '@nufi/wallet-common'
import type {
  Lamports,
  SolanaAccountInfo,
  SolanaAddress,
  SolanaTokenId,
  SolanaTokenMetadata,
  SolanaTransaction,
  SolanaTxFeeParams,
} from '@nufi/wallet-solana'
import {calculateTxFeeAmount} from '@nufi/wallet-solana'
import BigNumber from 'bignumber.js'
import type {SwapAsset} from 'common'
import React, {useEffect, useMemo} from 'react'

import {QueryGuard} from 'src/components'
import {getExchangeAssetDetailOrFallback} from 'src/features/exchange/application'
import type {
  ExchangeAssetsDetails,
  SwapAssetDetail,
} from 'src/features/exchange/domain'
import {
  useTrackMultiAssetTxSubmission,
  useTrackTxSubmission,
} from 'src/tracking'
import type {AccountInfo} from 'src/wallet'
import {commonParseTokenAmount} from 'src/wallet'
import {
  cachedGetTxFeeParams,
  useGetAccounts,
  useGetTokenMetadata,
  useSignTransferAssets,
  useSubmitTransfer,
  solToLamports,
  cachedGetTokensByAccount,
  cachedGetTokenMetadata,
  useGetTokensByAccount,
  getMaxSendableSolanaAmount,
} from 'src/wallet/solana'

import {getBlockchainDecimals} from '../../../constants'
import {assert} from '../../../utils/assertion'
import {useCustomMetadata} from '../CustomMetadataContainer'
import type {
  AssetTokenData,
  ExchangeDeps,
  WithAmountFieldProperties,
  WithGetAssetTokenDataProps,
  WithSignAndSubmitHandlers,
} from '../screens/common'
import {WithSummaryTxFee} from '../screens/common'
import {useCommonAmountValidation} from '../screens/details/common'
import type {SwapTokenData} from '../types'

import type {AmountFieldConstraintsAndData} from './common'
import {useAmountFieldPropertiesState} from './common'

const getExchangeAssetId = (exchangeAssetDetail: SwapAssetDetail) =>
  exchangeAssetDetail.contractAddress

type CustomMetadata = {
  txFeeParams: SolanaTxFeeParams
  fee: Lamports
}

const WithSolanaSignAndSubmitHandlersNative: WithSignAndSubmitHandlers<
  SolanaTransaction,
  string
> = ({amount: nativeAmount, toAddress, fromAccountInfo, children}) => {
  const submit = useSubmitTransfer()
  const sign = useSignTransferAssets()
  const {getNonReactiveMetadata} = useCustomMetadata<CustomMetadata>()
  useTrackTxSubmission(submit, {
    blockchain: 'solana',
    provider: fromAccountInfo.cryptoProviderType,
    type: 'transfer_coin',
    value: solToLamports(nativeAmount).toNumber(),
  })

  const onTxSign = async () => {
    const context = getNonReactiveMetadata('solana')
    assert(context.txFeeParams != null)
    assert(nativeAmount !== '')
    return await sign.mutateAsyncSilent({
      sendContext: {
        solAmount: solToLamports(nativeAmount),
        tokens: [],
      },
      accountId: fromAccountInfo.id,
      toAddress: toAddress as SolanaAddress,
      txFeeParams: context.txFeeParams,
    })
  }

  const onTxSubmit = async (signedTx: SolanaTransaction) =>
    await submit.mutateAsyncSilent({signedTx, accountId: fromAccountInfo.id})

  return children({
    signProps: {...sign, mutateAsyncSilent: onTxSign},
    submitProps: {...submit, mutateAsyncSilent: onTxSubmit},
  })
}

const WithSolanaSignAndSubmitHandlersToken: WithSignAndSubmitHandlers<
  SolanaTransaction,
  string
> = ({
  amount: tokenAmount,
  toAddress,
  fromAccountInfo,
  children,
  fromToken,
}) => {
  const submit = useSubmitTransfer()
  const sign = useSignTransferAssets()
  const {getNonReactiveMetadata} = useCustomMetadata<CustomMetadata>()

  assert(fromToken != null)

  const tokenMetadataQuery = useGetTokenMetadata(fromToken.id as SolanaTokenId)

  return (
    <QueryGuard
      {...tokenMetadataQuery}
      data={tokenMetadataQuery.data ?? undefined}
    >
      {(tokenMetadata) => {
        const onTxSign = async () => {
          const context = getNonReactiveMetadata('solana')
          assert(context.txFeeParams != null)
          const tokenToSend = {
            amount: commonParseTokenAmount(tokenAmount, tokenMetadata.decimals),
            token: tokenMetadata,
          }
          return await sign.mutateAsyncSilent({
            sendContext: {
              solAmount: new BigNumber(0) as Lamports,
              tokens: [tokenToSend],
            },
            accountId: fromAccountInfo.id,
            toAddress: toAddress as SolanaAddress,
            txFeeParams: context.txFeeParams,
          })
        }

        const onTxSubmit = async (signedTx: SolanaTransaction) =>
          await submit.mutateAsyncSilent({
            signedTx,
            accountId: fromAccountInfo.id,
          })

        return (
          <WithTokenTxSubmissionTracking
            tokenId={fromToken.id as SolanaTokenId}
            tokenMetadata={tokenMetadata}
            submitProps={submit}
            fromAccountInfo={fromAccountInfo}
            amount={tokenAmount}
          >
            {children({
              signProps: {...sign, mutateAsyncSilent: onTxSign},
              submitProps: {...submit, mutateAsyncSilent: onTxSubmit},
            })}
          </WithTokenTxSubmissionTracking>
        )
      }}
    </QueryGuard>
  )
}
type WithTokenTxSubmissionTrackingProps = {
  children: React.ReactNode
  tokenId: SolanaTokenId
  tokenMetadata: SolanaTokenMetadata
  submitProps: ReturnType<typeof useSubmitTransfer>
  fromAccountInfo: AccountInfo
  amount: string
}
const WithTokenTxSubmissionTracking = ({
  children,
  tokenId,
  tokenMetadata,
  submitProps,
  fromAccountInfo,
  amount,
}: WithTokenTxSubmissionTrackingProps) => {
  useTrackMultiAssetTxSubmission(submitProps, {
    blockchain: 'solana',
    provider: fromAccountInfo.cryptoProviderType,
    sendContext: {
      type: 'single_token_transfer_tx',
      tokenAmount: commonParseTokenAmount(amount, tokenMetadata.decimals),
      tokenId,
      isNft: tokenMetadata.nftInfo != null,
    },
  })

  return <>{children}</>
}

const WithSolanaAmountFieldProperties: WithAmountFieldProperties = ({
  fromAccountInfo,
  values,
  fromToken,
  children,
}) => {
  assert(fromAccountInfo == null || fromAccountInfo?.blockchain === 'solana')
  const {data, calculateAndSetData} = useAmountFieldPropertiesState()
  const {setNonReactiveMetadata} = useCustomMetadata<CustomMetadata>()

  const getConstraintsAndData = async (
    fromToken: SwapTokenData | null,
    fromAccountInfo: SolanaAccountInfo | null,
  ): Promise<AmountFieldConstraintsAndData> => {
    const emptyResponse = {constraints: null, data: null}
    if (!fromAccountInfo) return emptyResponse

    try {
      const txFeeParams = await cachedGetTxFeeParams()
      const fee = calculateTxFeeAmount(txFeeParams)
      const nonReactiveMetadata = {
        txFeeParams,
        fee,
      }

      if (fromToken) {
        const tokensByAccount = await cachedGetTokensByAccount()
        const tokenMetadata = await cachedGetTokenMetadata(
          fromToken.id as SolanaTokenId,
        )
        assert(tokenMetadata != null)

        const token = tokensByAccount[fromAccountInfo.id]!.find(
          (t) => t.token.id === fromToken.id,
        )
        assert(token != null)

        const maxTokenAmount = token.amount

        const tokenDecimals = tokenMetadata.decimals
        setNonReactiveMetadata('solana', nonReactiveMetadata)

        return {
          constraints: {maxTokenAmount, maxDecimals: tokenDecimals},
          data: {
            maxAmount: {
              value: maxTokenAmount,
              decimals: tokenDecimals,
              type: 'token',
            },
            fee,
          },
        }
      } else {
        const maxNativeAmount = getMaxSendableSolanaAmount(
          fromAccountInfo.balance,
          fee,
        )

        const maxNativeDecimals = getBlockchainDecimals('solana')
        setNonReactiveMetadata('solana', nonReactiveMetadata)

        return {
          constraints: {maxNativeAmount, maxDecimals: maxNativeDecimals},
          data: {
            maxAmount: {
              value: maxNativeAmount,
              type: 'native',
            },
            fee,
          },
        }
      }
    } catch (err) {
      return {constraints: {hasError: true}, data: null}
    }
  }

  useEffect(() => {
    calculateAndSetData(() => getConstraintsAndData(fromToken, fromAccountInfo))
  }, [fromToken, fromAccountInfo])

  const validate = useCommonAmountValidation(
    values,
    fromAccountInfo,
    fromToken,
    async () =>
      (await getConstraintsAndData(fromToken, fromAccountInfo)).constraints,
  )
  return children({
    validate,
    data,
  })
}

const useGetTokenBalances = (
  asset: SwapAsset,
  exchangeAssetsDetails: ExchangeAssetsDetails,
) => {
  const accountsQuery = useGetAccounts()
  const tokensByAccountQuery = useGetTokensByAccount()

  const data = useMemo((): AssetTokenData | null => {
    const accounts = accountsQuery.data
    const tokensByAccount = tokensByAccountQuery.data
    if (!accounts || !tokensByAccount) return null

    const exchangeAsset = getExchangeAssetDetailOrFallback(
      asset,
      exchangeAssetsDetails,
    )
    const exchangeAssetId = getExchangeAssetId(exchangeAsset)

    if (!exchangeAssetId) return null

    const balances = {} as Record<AccountId, BigNumber>

    for (const account of accounts) {
      const amounts = tokensByAccount[account.id]!
      const targetAmount = amounts.find((a) => a.token.mint === exchangeAssetId)
      balances[account.id] = targetAmount?.amount || new BigNumber(0)
    }

    return {
      id: exchangeAssetId as SolanaTokenId,
      balances,
    }
  }, [accountsQuery.data, tokensByAccountQuery.data, asset])

  const isLoading = accountsQuery.isLoading || tokensByAccountQuery.isLoading
  const error = accountsQuery.error || accountsQuery.error

  return {isLoading, error, data}
}

const WithSolanaGetAssetTokenData: ExchangeDeps['WithGetAssetTokenData'] = ({
  asset,
  blockchain,
  children,
  ...restProps
}) => {
  assert(blockchain === 'solana')
  if (asset == null) {
    return children(null)
  }
  return (
    <_WithGetAssetTokenData {...{asset, blockchain, children}} {...restProps} />
  )
}

const _WithGetAssetTokenData = ({
  asset,
  children,
  exchangeAssetsDetails,
}: {
  asset: SwapAsset
} & Pick<WithGetAssetTokenDataProps, 'children' | 'exchangeAssetsDetails'>) => {
  const query = useGetTokenBalances(asset, exchangeAssetsDetails)
  return children(query)
}

const WithSolanaSignAndSubmitHandlers: WithSignAndSubmitHandlers<
  SolanaTransaction,
  string
> = ({fromToken, ...restProps}) => {
  return fromToken != null ? (
    <WithSolanaSignAndSubmitHandlersToken
      fromToken={fromToken}
      {...restProps}
    />
  ) : (
    <WithSolanaSignAndSubmitHandlersNative {...restProps} fromToken={null} />
  )
}

export function findMatchingTokenSwapAsset({
  exchangeAssets,
  tokenMetadata,
}: {
  exchangeAssets: ExchangeAssetsDetails
  tokenMetadata: SolanaTokenMetadata
}): SwapAssetDetail | undefined {
  const tokenMetadataMint = tokenMetadata.mint

  return Object.values(exchangeAssets).find(
    (v) =>
      v?.blockchain === tokenMetadata.blockchain &&
      tokenMetadataMint === getExchangeAssetId(v),
  )
}

export const solanaExchangeDeps: ExchangeDeps = {
  WithAmountFieldProperties: WithSolanaAmountFieldProperties,
  WithSignAndSubmitHandlers: WithSolanaSignAndSubmitHandlers,
  WithSummaryTxFee,
  WithGetAssetTokenData: WithSolanaGetAssetTokenData,
}
