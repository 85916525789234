import {CheckCircleOutline as SuccessIcon} from '@mui/icons-material'
import {Box, Link as MuiLink, Switch, Typography} from '@mui/material'
import React, {useEffect} from 'react'
import {Trans, useTranslation} from 'react-i18next'

import {Alert} from '../components'
import {externalLinks} from '../constants'

type NotificationPermissionSectionProps = {
  initialNotificationPermission: NotificationPermission | null
  notificationsPermission: NotificationPermission | null
  setNotificationsPermission: (
    permission: NotificationPermission | null,
  ) => void
}

export const NotificationPermissionSection = ({
  initialNotificationPermission,
  notificationsPermission,
  setNotificationsPermission,
}: NotificationPermissionSectionProps) => {
  const {t} = useTranslation()

  const askForNotificationsPermission = async () => {
    if ('Notification' in window && Notification.permission !== 'granted') {
      setNotificationsPermission(
        (await Notification.requestPermission()) || null,
      )
    }
  }

  useEffect(() => {
    askForNotificationsPermission()
  }, [])
  return (
    <Box>
      <Box
        display="flex"
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        {initialNotificationPermission !== 'denied' ? (
          notificationsPermission !== 'granted' ? (
            <>
              <Typography variant="h6">{t('Allow notifications')}</Typography>
              <Switch
                checked={false}
                onChange={() => askForNotificationsPermission()}
                size="small"
              />
            </>
          ) : (
            <Box display="flex" alignItems={'center'}>
              <SuccessIcon fontSize="medium" color="primary" />
              <Typography variant="h6" ml={1}>
                {t('wallet_connect_notification_allowed')}
              </Typography>
            </Box>
          )
        ) : (
          <Typography variant="h6">{t('Allow notifications')}</Typography>
        )}
      </Box>
      {initialNotificationPermission === 'denied' && (
        <Box mt={1}>
          <Alert severity="warning">
            <Trans
              i18nKey="wallet_connect_notifications_disabled_help"
              t={t}
              components={{
                Link: (
                  <MuiLink
                    color="primary"
                    target="_blank"
                    href={externalLinks.nufiWalletConnectNotificationInfo}
                  />
                ),
              }}
            />
          </Alert>
        </Box>
      )}

      <Box mt={1}>
        <Alert severity="info">
          <Trans
            i18nKey="wallet_connect_notification_info"
            t={t}
            components={{
              Link: (
                <MuiLink
                  color="primary"
                  target="_blank"
                  href={externalLinks.nufiWalletConnectNotificationInfo}
                />
              ),
            }}
          />
        </Alert>
      </Box>
    </Box>
  )
}

type PopupPermissionSectionProps = {
  popupsAllowed: boolean | null
  setPopupsAllowed: (allowed: boolean | null) => void
}

export const PopupPermissionSection = ({
  popupsAllowed,
  setPopupsAllowed,
}: PopupPermissionSectionProps) => {
  const {t} = useTranslation()

  const askForPopupPermission = async () => {
    if (popupsAllowed) {
      return
    }

    const top = window.screenTop + 50
    const left = window.screenLeft + window.screen.width - 50
    const testWindow = window.open(
      ``,
      'nufiTestConnectorWindow',
      `width=1,height=1,top=${top},left=${left}`,
    )
    if (testWindow) {
      testWindow.close()
      setPopupsAllowed(true)
    } else {
      setPopupsAllowed(false)
    }
  }

  useEffect(() => {
    askForPopupPermission()
  }, [])
  return (
    <Box>
      <Box
        display="flex"
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Typography variant="h5">{t('Allow popups')}</Typography>
      </Box>
      <Typography>
        {t(
          'We use a popup to automatically display the NuFi window for logging in and confirming transactions.',
        )}
      </Typography>
    </Box>
  )
}
