import {
  Visibility,
  VisibilityOff,
  Warning as WarningIcon,
} from '@mui/icons-material'
import {Box, Typography, IconButton, Tooltip, Badge, Chip} from '@mui/material'
import React from 'react'
import {Trans, useTranslation} from 'react-i18next'

import {FormattedMetamaskProfileName} from 'src/components/formatting/FormattedMetamaskProfileName'
import {useGetConversionRates} from 'src/features/conversionRates/application'
import type {Currency} from 'src/features/conversionRates/domain'
import {
  useSetAmountsVisibility,
  useSettings,
} from 'src/features/profile/application'
import {useWarnings} from 'src/features/warnings/application'
import {hasAssetsValuePotentiallyAffectingWarnings} from 'src/features/warnings/domain'

import {useCurrentProfileMeta} from '../../appStorage'
import {
  FormattedCurrency,
  NaError,
  BatchQueryGuard,
  LabeledIcon,
  AmountVisibilityWrapper,
  WithTooltip,
} from '../../components'
import {FormattedWeb3AuthProfileName} from '../../components/formatting'
import {shortcutKeys} from '../../constants'
import {useCurrentLoginInfo} from '../../store/auth'
import {calculateTotalAssetValue} from '../../utils/totalAssetValue'
import {getModifierNameByOS} from '../../utils/userAgentDetection'
import {useGetBalances, useGetAllTokensMetadata} from '../../wallet'
import {BackToDappNavigation} from '../loggedOutMain/appOpener'

import {LinearLoader} from './account/Loadings'

type StyledTotalAssetValueProps = {
  currency: Currency
}

function StyledTotalAssetValue({currency}: StyledTotalAssetValueProps) {
  const balancesQuery = useGetBalances()
  const convRate = useGetConversionRates()
  const tokensMetadataQuery = useGetAllTokensMetadata()

  const warnings = useWarnings()
  const valuePotentiallyAffectedByWarnings =
    hasAssetsValuePotentiallyAffectingWarnings(warnings)

  const {t} = useTranslation()

  return (
    <AmountVisibilityWrapper>
      <Box
        sx={{lineHeight: 1, display: 'inline-block', verticalAlign: 'middle'}}
      >
        <BatchQueryGuard
          queries={{
            balancesResult: balancesQuery,
            tokensMetadataResult: tokensMetadataQuery,
          }}
          ErrorElement={
            <NaError
              error={t('Could not load total portfolio value')}
              severity="neutral"
            />
          }
          LoadingElement={<LinearLoader />}
          loadingVariant="centered"
        >
          {({balancesResult, tokensMetadataResult}) => {
            if (!convRate.initialLoadingFinished) return <LinearLoader />
            return (
              <Box
                data-test-id="formatted-portfolio-value"
                sx={{display: 'flex', alignItems: 'center'}}
              >
                <FormattedCurrency
                  convertedBalance={{
                    type: 'success',
                    balance: calculateTotalAssetValue(
                      balancesResult,
                      currency,
                      convRate.rates,
                      tokensMetadataResult.tokensMetadata,
                    ),
                  }}
                  currency={currency}
                  isSensitiveInformation={false}
                />
                {(Object.values(convRate.rates).some(
                  (v) => v === 'not-loaded',
                ) ||
                  valuePotentiallyAffectedByWarnings ||
                  convRate.nativeCoinsError ||
                  convRate.tokenErrors.length > 0 ||
                  tokensMetadataResult.hasSomeError ||
                  balancesResult.hasSomeError) && (
                  <Box ml={1}>
                    <WithTooltip
                      title={t(
                        'Some values could not be loaded. The actual balance might be higher.',
                      )}
                    >
                      <WarningIcon color="warning" fontSize="small" />
                    </WithTooltip>
                  </Box>
                )}
              </Box>
            )
          }}
        </BatchQueryGuard>
      </Box>
    </AmountVisibilityWrapper>
  )
}

export function TotalAssetValue() {
  const {t} = useTranslation()
  const currentProfileMetaQuery = useCurrentProfileMeta()
  const currentLoginInfo = useCurrentLoginInfo()
  const {currency, areAmountsVisible} = useSettings()
  const savePortfolioValueVisibility = useSetAmountsVisibility()
  const revertVisibility = async () => {
    await savePortfolioValueVisibility.mutateAsyncSilent({
      areAmountsVisible: !areAmountsVisible,
    })
  }
  const commonTransProps = {
    values: {
      shortcutKeys: `${getModifierNameByOS(
        shortcutKeys.hideAmount.modifier,
      )} + ${shortcutKeys.hideAmount.key}`,
    },
    components: {
      Bold: (
        <Box component="span" sx={{fontWeight: 'bold', whiteSpace: 'nowrap'}} />
      ),
    },
  }

  const ProfileNameLabel = (() => {
    const profileMeta = currentProfileMetaQuery.data

    if (profileMeta == null) return null

    const commonChipProps = {
      'data-test-id': 'top-bar-wallet-name',
      color: 'primary',
      variant: 'outlined',
      sx: {mr: 2},
    } as const

    if (currentLoginInfo.loginType === 'web3Auth') {
      return (
        <Chip
          {...commonChipProps}
          label={
            <FormattedWeb3AuthProfileName
              iconSize={16}
              web3AuthUserInfo={currentLoginInfo.user}
              labelProps={{
                variant: 'caption',
              }}
            />
          }
        />
      )
    }
    if (currentLoginInfo.loginType === 'metamask') {
      return (
        <Chip
          {...commonChipProps}
          label={
            <FormattedMetamaskProfileName
              profileName={profileMeta.name}
              iconSize={16}
              labelProps={{
                variant: 'caption',
              }}
            />
          }
        />
      )
    }
    return (
      <Chip
        {...commonChipProps}
        label={<Typography variant="caption">{profileMeta.name}</Typography>}
      />
    )
  })()

  return (
    <Box sx={{display: 'flex', alignItems: 'center'}}>
      <Typography variant="h6" fontSize={16}>
        <Box component="span" display="flex" alignItems="center">
          <LabeledIcon
            spacing={0.5}
            iconPosition="end"
            Label={
              <>
                {ProfileNameLabel}
                <StyledTotalAssetValue {...{currency}} />
              </>
            }
            Icon={
              <Tooltip
                title={
                  <>
                    {areAmountsVisible ? (
                      <Trans
                        i18nKey="hide_amounts_tooltip_off"
                        t={t}
                        {...commonTransProps}
                      />
                    ) : (
                      <Trans
                        i18nKey="hide_amounts_tooltip_on"
                        t={t}
                        {...commonTransProps}
                      />
                    )}
                  </>
                }
              >
                <IconButton onClick={revertVisibility}>
                  {areAmountsVisible ? (
                    <Visibility />
                  ) : (
                    <Badge
                      sx={{
                        '& .MuiBadge-badge': {height: '6px', minWidth: '6px'},
                      }}
                      color="primary"
                      badgeContent=" "
                      variant="dot"
                    >
                      <VisibilityOff />
                    </Badge>
                  )}
                </IconButton>
              </Tooltip>
            }
          />
        </Box>
      </Typography>
      <Box ml={5}>
        <BackToDappNavigation />
      </Box>
    </Box>
  )
}
