import type {SolanaTokenMetadata, SolanaTokenTicker} from '@nufi/wallet-solana'

export function sanitizeTokenTicker(
  metadata: SolanaTokenMetadata,
): SolanaTokenMetadata {
  // WSOL
  if (metadata.mint === 'So11111111111111111111111111111111111111112') {
    // The ticker of Wrapped SOL is `SOL`, which is the same as the ticker
    // of the Solana coin itself. This may lead to confusion in the UI,
    // therefore we are overriding the ticker to `WSOL`
    return {...metadata, ticker: 'WSOL' as SolanaTokenTicker}
  }
  return metadata
}
