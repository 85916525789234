/* eslint-disable @typescript-eslint/explicit-function-return-type */
import type {AccountId, HexString} from '@nufi/wallet-common'
import {arraySum} from '@nufi/wallet-common'
import {getAffectedTokenIdFromTx, pubKeyToAddress} from '@nufi/wallet-evm'
import {useInfiniteQuery} from '@tanstack/react-query'
import type BigNumber from 'bignumber.js'
import _ from 'lodash'
import {useMemo} from 'react'

import {mergeQueryResultsState, useNeverStaleQuery} from 'src/utils/query-utils'

import {assert} from '../../../../utils/assertion'
import {minutesToMilliseconds} from '../../../utils/common'
import {getEvmManager} from '../../evmManagers'
import type {
  EvmBlockchain,
  EvmTokenId,
  EvmTxHistoryEntry,
  EvmTxBundle,
  EvmAssetTransfersResponse,
} from '../../types'

import {cachedGetAccounts, sumNativeBalances} from './core'
import {
  cachedGetTokenBalancesPerAccount,
  cachedGetTokenMetadata,
} from './tokens'

// Transaction history is not supported by the generic evm implementations right now
// and is only supported by Ethereum. To reuse the queries/mutations as much as possible
// we need to invalidate these query keys as part of useSubmitTransfer so these keys
// have been moved here and will be invalidated by useSubmitTransfer for any blockchain
// even though it's not really necessary right now.
export const combinedQueryKeys = {
  oneWayAssetTransfers: {
    index: <TBlockchain extends EvmBlockchain>(blockchain: TBlockchain) => [
      blockchain,
      'oneWayAssetTransfers',
    ],
    account: <TBlockchain extends EvmBlockchain>(
      blockchain: TBlockchain,
      id: AccountId | null,
      direction: 'in' | 'out',
      tokenId: EvmTokenId<EvmBlockchain> | null,
    ) => [
      ...combinedQueryKeys.oneWayAssetTransfers.index(blockchain),
      id,
      direction,
      tokenId,
    ],
  },
  txHistory: {
    index: <TBlockchain extends EvmBlockchain>(blockchain: TBlockchain) => [
      blockchain,
      'transactionsHistory',
    ],
    account: <TBlockchain extends EvmBlockchain>(
      blockchain: TBlockchain,
      id: AccountId | null,
      tokenId: EvmTokenId<EvmBlockchain> | null,
      txHashes: string[],
    ) => [
      ...combinedQueryKeys.txHistory.index(blockchain),
      id,
      tokenId,
      txHashes,
    ],
  },
  balance: {
    index: <TBlockchain extends EvmBlockchain>(blockchain: TBlockchain) => [
      blockchain,
      'balance',
    ],
    total: <TBlockchain extends EvmBlockchain>(
      blockchain: TBlockchain,
      tokenId: EvmTokenId<TBlockchain> | null,
    ) => [...combinedQueryKeys.balance.index(blockchain), 'total', tokenId],
    accounts: <TBlockchain extends EvmBlockchain>(
      blockchain: TBlockchain,
      tokenId: EvmTokenId<TBlockchain> | null,
    ) => [...combinedQueryKeys.balance.index(blockchain), 'account', tokenId],
  },
} as const

export const useGetTotalBalance = <TBlockchain extends EvmBlockchain>(
  blockchain: TBlockchain,
  tokenId: EvmTokenId<TBlockchain> | null,
  enabled = true,
) => {
  return useNeverStaleQuery({
    queryKey: combinedQueryKeys.balance.total(blockchain, tokenId),
    queryFn: async () => {
      if (tokenId == null) {
        const accounts = await cachedGetAccounts(blockchain)
        return sumNativeBalances(accounts)
      } else {
        const accountTokenBalances = await cachedGetTokenBalancesPerAccount(
          blockchain,
          tokenId,
        )
        return arraySum(
          accountTokenBalances.map(({balance}) => balance),
        ) as BigNumber
      }
    },
    enabled,
  })
}

export const useGetBalancesPerAccount = <TBlockchain extends EvmBlockchain>(
  blockchain: TBlockchain,
  tokenId: EvmTokenId<TBlockchain> | null,
  enabled = true,
) => {
  return useNeverStaleQuery({
    queryKey: combinedQueryKeys.balance.accounts(blockchain, tokenId),
    queryFn: async () => {
      if (tokenId == null) {
        const accounts = await cachedGetAccounts(blockchain)
        return accounts.map((account) => ({
          accountId: account.id,
          balance: account.balance,
        }))
      } else {
        return await cachedGetTokenBalancesPerAccount(blockchain, tokenId)
      }
    },
    enabled,
  })
}

const flattenTxHistoryQueryData = <TBlockchain extends EvmBlockchain>(
  queryResult: ReturnType<
    typeof useInfiniteQuery<EvmAssetTransfersResponse<TBlockchain>>
  >,
) => ({
  ...queryResult,
  data: queryResult.data?.pages.flatMap((p) => p.transfers),
})

// used to get incoming / outgoing transactions from alchemy, although without fee information
const usePaginatedOneWayAssetTransfers = <TBlockchain extends EvmBlockchain>(
  blockchain: TBlockchain,
  accountId: AccountId,
  direction: 'in' | 'out',
  tokenId: EvmTokenId<TBlockchain> | null,
  enabled = true,
) => {
  const transfersInfiniteQuery = useInfiniteQuery<
    EvmAssetTransfersResponse<TBlockchain>
  >({
    queryKey: combinedQueryKeys.oneWayAssetTransfers.account(
      blockchain,
      accountId,
      direction,
      tokenId,
    ), // add for chains
    queryFn: async ({pageParam: pageKey = undefined}) => {
      let assetTransferTokenParam = null
      if (tokenId) {
        const tokenMetadata = await cachedGetTokenMetadata(blockchain, tokenId)
        assetTransferTokenParam = {
          type: tokenMetadata.tokenStandard,
          address: tokenMetadata.contractAddress,
        }
      }
      const evmManager = getEvmManager(blockchain)
      const account = evmManager.accountsStore.getAccount(
        accountId as AccountId,
      )
      const maxCount = 1000
      const {transfers: txBundles, pageKey: nextPageKey} =
        await evmManager.blockchainApi.getAssetTransfers({
          address: pubKeyToAddress<TBlockchain>(account.publicKey),
          direction,
          token: assetTransferTokenParam,
          // method is computation-units-expensive regardless of max tx count, so fetch as many as possible (1000) which is still fast
          pagingParams: {maxCount, pageKey: pageKey as string | undefined},
        })

      // Relevant for ERC721 and ERC1155. Tx history can only be requested for the whole NFT collection (by contract address)
      // However, we need tx history for the specific NFT within the gallery, so compare tokenId precisely
      const relevantTxBundles = txBundles.filter(
        (txBundle) =>
          !tokenId ||
          txBundle.txs.some((tx) => getAffectedTokenIdFromTx(tx) === tokenId),
      )
      return {
        transfers: _.orderBy(
          relevantTxBundles,
          (txBundle) => txBundle.txs[0]!.metadata.blockTimestamp,
          'desc',
        ),
        pageKey: nextPageKey,
      }
    },
    // https://docs.alchemy.com/reference/transfers-api-quickstart#pagination
    // Each page key has a TTL (Time to Live) of 10 minutes so if you receive a response
    // with a pageKey value you must send the next request (with the pageKey) within
    // the 10 minute window, otherwise you will have to restart the entire request cycle.
    // -- make sure the stale time is less than 10 minutes
    staleTime: minutesToMilliseconds(5),
    enabled,
    initialPageParam: undefined,
    getNextPageParam: ({pageKey}: EvmAssetTransfersResponse<TBlockchain>) =>
      pageKey,
  })

  const data = flattenTxHistoryQueryData(transfersInfiniteQuery).data

  return {
    ...transfersInfiniteQuery,
    ...mergeQueryResultsState(transfersInfiniteQuery),
    data,
  }
}

const getQueryIdOfTxs = <TBlockchain extends EvmBlockchain>(
  txs: EvmTxBundle<TBlockchain>[] | undefined,
) => (txs?.length ? [txs[0]!.hash, txs[txs.length - 1]!.hash] : [])

// used to merge and enrich alchemy in+out transactions with fee info
function useGetPaginatedTransactionHistory<TBlockchain extends EvmBlockchain>(
  blockchain: TBlockchain,
  accountId: AccountId,
  tokenId: EvmTokenId<TBlockchain> | null,
  enabled = true,
) {
  const EVM_TX_HISTORY_PAGE_SIZE = 10
  const inTxsQuery = usePaginatedOneWayAssetTransfers(
    blockchain,
    accountId,
    'in',
    tokenId,
    enabled,
  )
  const inTxsData = inTxsQuery.data

  const outTxsQuery = usePaginatedOneWayAssetTransfers(
    blockchain,
    accountId,
    'out',
    tokenId,
    enabled,
  )
  const outTxsData = outTxsQuery.data

  type EvmTxHistoryPageParam = {
    usedInTxs: number
    usedOutTxs: number
  }
  const mergedTxHistoryInfiniteQuery = useInfiniteQuery<{
    data: EvmTxHistoryEntry<TBlockchain>[]
    nextPageParam: EvmTxHistoryPageParam | undefined
  }>({
    queryKey: combinedQueryKeys.txHistory.account(
      blockchain,
      accountId,
      tokenId,
      // dependent on data from other queries, extract hashes of boundary txs for efficiency
      [...getQueryIdOfTxs(inTxsData), ...getQueryIdOfTxs(outTxsData)],
    ),
    queryFn: async ({pageParam: _cursor = {usedInTxs: 0, usedOutTxs: 0}}) => {
      assert(!!inTxsData && !!outTxsData)
      const cursor = _cursor as EvmTxHistoryPageParam

      // fetch more in and out data if current not sufficient
      const {data: inTxs = [], hasNextPage: hasMoreInTxs} =
        inTxsData.length - cursor.usedInTxs < EVM_TX_HISTORY_PAGE_SIZE &&
        inTxsQuery.hasNextPage
          ? flattenTxHistoryQueryData(await inTxsQuery.fetchNextPage())
          : inTxsQuery
      const {data: outTxs = [], hasNextPage: hasMoreOutTxs} =
        outTxsData.length - cursor.usedOutTxs < EVM_TX_HISTORY_PAGE_SIZE &&
        outTxsQuery.hasNextPage
          ? flattenTxHistoryQueryData(await outTxsQuery.fetchNextPage())
          : outTxsQuery

      // window of in and out txs to consider - assumes sorted order
      const inTxsSlice = inTxs.slice(
        cursor.usedInTxs,
        cursor.usedInTxs + EVM_TX_HISTORY_PAGE_SIZE,
      )

      const outTxsSlice = outTxs.slice(
        cursor.usedOutTxs,
        cursor.usedOutTxs + EVM_TX_HISTORY_PAGE_SIZE,
      )

      // will be sized between <EVM_TX_HISTORY_PAGE_SIZE, 2*EVM_TX_HISTORY_PAGE_SIZE>, depending on duplicity
      const txHistoryPage = _.orderBy(
        [...inTxsSlice, ...outTxsSlice],
        (tx) => tx.txs[0]!.metadata.blockTimestamp,
        'desc',
      )

      // merge and deduplicate inward and outward tx bundles
      const deduplicatedTxBundles = _(txHistoryPage)
        .groupBy((txBundle) => txBundle.hash)
        .map((txBundleGroup, hash) => {
          return {
            hash: hash as HexString,
            txs: _.uniqBy(
              txBundleGroup.flatMap((txBundle) => txBundle.txs),
              // Do not deduplicate by txHash, since Alchemy returns duplicated transactions for multiple-effect txs.
              // Instead, use uniqueId, which is a concatenation of txHash and tx log index
              (tx) => tx.uniqueId,
            ),
          }
        })
        .value()

      const uniqTxBundleSlice = deduplicatedTxBundles.slice(
        0,
        EVM_TX_HISTORY_PAGE_SIZE,
      )

      const inTxsCountInPage = _.intersectionBy(
        uniqTxBundleSlice,
        inTxsSlice,
        (tx) => tx.hash,
      ).length
      const outTxsCountInPage = _.intersectionBy(
        uniqTxBundleSlice,
        outTxsSlice,
        (tx) => tx.hash,
      ).length

      const newCursor = {
        usedInTxs: cursor.usedInTxs + inTxsCountInPage,
        usedOutTxs: cursor.usedOutTxs + outTxsCountInPage,
      }

      const areAllDataUsed =
        !hasMoreInTxs &&
        !hasMoreOutTxs &&
        newCursor.usedInTxs === inTxs.length &&
        newCursor.usedOutTxs === outTxs.length

      const evmManager = getEvmManager(blockchain)
      const account = evmManager.accountsStore.getAccount(
        accountId as AccountId,
      )
      const txHistoryEntries =
        await evmManager.blockchainApi.getTransactionHistory(
          pubKeyToAddress<TBlockchain>(account.publicKey),
          uniqTxBundleSlice,
        )

      // in case we are interested in a specific tokenId, filter txs with non-null effects of given token
      const data = tokenId
        ? txHistoryEntries.filter(
            (entry) =>
              entry.tokenEffects.filter((e) => e.token.id === tokenId).length >
              0,
          )
        : txHistoryEntries

      return {
        data,
        nextPageParam: areAllDataUsed ? undefined : newCursor,
      }
    },
    staleTime: minutesToMilliseconds(5),
    enabled: enabled && !!inTxsData && !!outTxsData,
    initialPageParam: {usedInTxs: 0, usedOutTxs: 0},
    getNextPageParam: ({nextPageParam}) => nextPageParam,
  })

  const data = useMemo(
    () => mergedTxHistoryInfiniteQuery.data?.pages.flatMap((p) => p.data),
    [mergedTxHistoryInfiniteQuery.data?.pages, accountId],
  )

  return {
    ...mergedTxHistoryInfiniteQuery,
    ...mergeQueryResultsState(
      mergedTxHistoryInfiniteQuery,
      inTxsQuery,
      outTxsQuery,
    ),
    data,
  }
}

//
// __COMBINED__ QUERIES
//

export function useGetTransactionHistory<TBlockchain extends EvmBlockchain>(
  blockchain: TBlockchain,
  accountId: AccountId,
  tokenId: EvmTokenId<TBlockchain> | null,
  enabled = true,
) {
  const queries = {
    token: useGetPaginatedTransactionHistory(
      blockchain,
      accountId,
      tokenId,
      enabled && tokenId != null,
    ),
    native: useGetPaginatedTransactionHistory(
      blockchain,
      accountId,
      null,
      enabled && tokenId == null,
    ),
  }

  return queries[tokenId == null ? 'native' : 'token']
}
