import {assert} from '@nufi/frontend-common'
import {isBlockchainSubset} from '@nufi/wallet-common'
import {useQuery} from '@tanstack/react-query'

import type {TokenBlockchain} from 'src/blockchainTypes'
import {tokenBlockchains} from 'src/blockchainTypes'
import {getAvailableBlockchains} from 'src/features/availableBlockchains/application'
import type {
  ConversionRates,
  Currency,
  SuggestedCurrency,
  RateInfo,
} from 'src/features/conversionRates/domain'
import {useGetIsBlockchainEnabled} from 'src/features/profile/application'
import {useNeverStaleQuery} from 'src/utils/query-utils'
import {getHasAccounts} from 'src/wallet/public/queries/accounts'

import {getMockServices} from '../../../__tests__/storybook/MockServiceLocator'
import type {Blockchain, TokenId, TokenMetadata} from '../../../types'
import {allCurrenciesToCurrencyId} from '../infrastructure/coingecko/currencyIds'

import {
  getInMemoryCoinConversionRates,
  useGetCoinConversionRates,
} from './coinQueries'
import {defaultCacheOptions, defaultConversionRatesExtraArgs} from './common'
import {conversionRatesService} from './conversionRatesService'
import {conversionRatesQueryKeys} from './queryKeys'
import {
  getInMemoryBlockchainTokensConversionRates,
  useGetBlockchainTokensConversionRates,
} from './tokenQueries'

export * from './historicalRatesQueries'
export * from './queryKeys'
export * from './conversionRatesService'

// Consider storing the final state in `zustand` instead of RQ, as the overall
// complexity might decrease.
function _useGetConversionRates(): {
  tokenErrors: TokenBlockchain[]
  rates: ConversionRates
  initialLoadingFinished: boolean
  nativeCoinsError: boolean
} {
  const initialValue = {
    nativeCoinsError: false,
    tokenErrors: [],
    rates: {},
    initialLoadingFinished: true,
  }

  const availableTokenBlockchains = getAvailableBlockchains().filter((b) =>
    isBlockchainSubset(b, tokenBlockchains),
  )

  // Even though these queries are not really used here, they are called here so that react-query knows
  // that they are "active", and their corresponding `staleTime` applies correctly. If we only called these
  // queries inside the `useNeverStaleQuery` via `queryClient.cachedGet...` react-query would not consider
  // these queries used, and the stale time would not apply correctly.
  useGetCoinConversionRates(defaultConversionRatesExtraArgs)

  for (const b of availableTokenBlockchains) {
    // The order can not change at runtime.
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useGetBlockchainTokensConversionRates(b, defaultConversionRatesExtraArgs)
  }

  const isBlockchainEnabled = useGetIsBlockchainEnabled()

  // This query gets invalidated each time its children query data changes. This is because
  // the children queries are obliged to register `onSettled` event.
  // We are not using `useMemo` as we need to combine both coin rates and token rates, and doing
  // so for every component can again introduce app slowdown.
  //
  // It is also important not to call "cachedGet" queries that are invalidating this key here,
  // as e.g. on "error" this can cause infinite render loop cycle.
  const result = useNeverStaleQuery({
    queryKey: conversionRatesQueryKeys.all,
    queryFn: async () => {
      const result: {
        tokenErrors: TokenBlockchain[]
        rates: ConversionRates
        initialLoadingFinished: boolean
        nativeCoinsError: boolean
      } = initialValue

      const coinRates = await getInMemoryCoinConversionRates()
      if (coinRates.error != null) {
        result.nativeCoinsError = true
      } else {
        if (coinRates.data === undefined) {
          result.initialLoadingFinished = false
        } else {
          result.rates = {...result.rates, ...coinRates.data}
        }
      }

      const enabledTokenBlockchains = availableTokenBlockchains.filter((b) =>
        isBlockchainEnabled(b),
      )

      for (const blockchain of enabledTokenBlockchains) {
        if (getHasAccounts(blockchain)) {
          const tokenRatesResult =
            await getInMemoryBlockchainTokensConversionRates(blockchain)

          if (tokenRatesResult?.error != null) {
            result.tokenErrors.push(blockchain)
          } else {
            if (tokenRatesResult?.data === undefined) {
              result.initialLoadingFinished = false
            } else {
              result.rates = {...result.rates, ...tokenRatesResult.data}
            }
          }
        }
      }

      return result
    },
  })
  if (result.data == null) {
    return initialValue
  }
  return result.data
}

export type UseGetConversionRates = typeof _useGetConversionRates

export function useGetConversionRates() {
  const mockServices = getMockServices()
  return mockServices
    ? mockServices.useGetConversionRates()
    : _useGetConversionRates()
}

/**
 * Given a list of tokens metadata, belonging to a single blockchain,
 * fetches conversion rates for these tokens.
 * Note that it will not reuse possibly already loaded conversion rates
 * belonging to user tokens.
 * Therefore the hook is mostly meant for cases, when rates are requested
 * also for tokens not owned by user.
 */
function _useGetArbitraryTokensRates({
  blockchain,
  tokensMetadata,
  enabled,
}: {
  blockchain: TokenBlockchain
  tokensMetadata: TokenMetadata[]
  enabled: boolean
}) {
  assert(tokensMetadata.every((t) => t.blockchain === blockchain))

  return useQuery({
    queryKey: conversionRatesQueryKeys.tokensById(
      blockchain,
      tokensMetadata.map(({id}) => id),
    ),
    queryFn: async () => {
      const rates: ConversionRates =
        await conversionRatesService.getTokenConversionRates(tokensMetadata)
      return {rates}
    },
    ...defaultCacheOptions,
    enabled,
    // Retries handled internally be query fn
    retry: false,
  })
}
export type UseGetArbitraryTokensRates = typeof _useGetArbitraryTokensRates

export function useGetArbitraryTokensRates(
  args: Parameters<UseGetArbitraryTokensRates>[0],
) {
  const mockServices = getMockServices()
  return mockServices
    ? mockServices.useGetArbitraryTokensRates(args)
    : _useGetArbitraryTokensRates(args)
}

export const getTokenPriceChange = (
  blockchain: Blockchain,
  tokenId: TokenId,
  currency: Currency,
  conversionRates: ConversionRates | undefined,
): number | undefined =>
  conversionRates
    ? getAssetConversionRate({
        conversionRates,
        blockchain,
        tokenId,
        currency,
      })?.change24h
    : undefined

export const getCoinPriceChange = (
  blockchain: Blockchain,
  currency: Currency,
  conversionRates: ConversionRates | undefined,
): number | undefined =>
  conversionRates
    ? getAssetConversionRate({
        conversionRates,
        blockchain,
        currency,
      })?.change24h
    : undefined

const getAssetConversionRate = ({
  conversionRates,
  currency,
  blockchain,
  tokenId,
}: {
  conversionRates: ConversionRates
  currency: Currency
  blockchain: Blockchain
  tokenId?: TokenId
}): RateInfo | undefined => {
  const assetRates = conversionRates[tokenId ?? blockchain]
  return assetRates !== 'not-loaded' ? assetRates?.[currency] : undefined
}

export const suggestedCurrenciesToCurrencyId: Record<
  SuggestedCurrency,
  string
> = {
  USD: allCurrenciesToCurrencyId.USD,
  EUR: allCurrenciesToCurrencyId.EUR,
}
