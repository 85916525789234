import {Box, Grid, Portal, Typography} from '@mui/material'
import type {CSSProperties} from 'react'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {Switch, Route, useHistory} from 'react-router-dom'
import AutoSizer from 'react-virtualized-auto-sizer'
import {FixedSizeList as VirtualizedList} from 'react-window'

import type {SortDirection} from 'src/features/assets'
import type {
  ExchangeHistorySortBy,
  SwapRow,
} from 'src/features/swaps/application'
import {useExchangeHistory} from 'src/features/swaps/application'

import {
  ExchangeHistoryError,
  QueryGuard,
  SafeCenterAligner,
  useSortHandlers,
} from '../../components'
import {routeTo} from '../../router'
import {useRouteToExchangeAction} from '../../router/portfolio'
import {useScrollableStyles} from '../../utils/layoutUtils'
import {ExchangeModal, ExchangeDetailModal} from '../exchange'
import {OpenExchangeButton} from '../exchange/OpenExchangeButton'

import {ExchangeHistoryItem} from './exchangeHistoryList/ExchangeHistoryItem'
import {EXCHANGE_HISTORY_ITEM_HEIGHT} from './exchangeHistoryList/ExchangeHistoryItemUtils'
import {ExchangeHistoryListHeader} from './exchangeHistoryList/ExchangeHistoryListLayout'
import {ExchangeHistoryListLoading} from './exchangeHistoryList/Loadings'
import {usePortfolioPortalRef} from './PortfolioContext'

const defaultSortDirection: SortDirection = 'desc'

export function Exchange() {
  const history = useHistory()
  const {t} = useTranslation()
  const scrollableClasses = useScrollableStyles()
  const {setSortBy, sortBy, sortDirection} =
    useSortHandlers<ExchangeHistorySortBy>('createdAt', defaultSortDirection)
  const routeToExchange = useRouteToExchangeAction()
  const tabsPortalRef = usePortfolioPortalRef()

  const onClose = () => {
    history.goBack()
  }

  return (
    <Box className={scrollableClasses.scrollableParent}>
      <Portal container={tabsPortalRef}>
        <OpenExchangeButton
          label={t('Swap Crypto')}
          variant="primary"
          onOpen={routeToExchange}
          targetAsset="none"
        />
      </Portal>
      <Box>
        <ExchangeHistoryListHeader
          {...{sortBy, setSortBy, sortDirection, defaultSortDirection}}
        />
      </Box>
      <Box className={scrollableClasses.scrollableList}>
        <ExchangeHistoriesList {...{sortBy, sortDirection}} />
      </Box>
      {/* Action modals */}
      <Switch>
        <Route path={routeTo.portfolio.exchange.action(':blockchain').exchange}>
          <ExchangeModal onClose={onClose} />
        </Route>
        <Route path={routeTo.portfolio.exchange.detail(':exchangeId')}>
          <ExchangeDetailModal onClose={onClose} />
        </Route>
      </Switch>
    </Box>
  )
}

type ExchangeHistoryListProps = {
  sortBy: ExchangeHistorySortBy
  sortDirection: SortDirection
}

function ExchangeHistoriesList({
  sortBy,
  sortDirection,
}: ExchangeHistoryListProps) {
  const {t} = useTranslation()
  const exchangeHistoryQuery = useExchangeHistory(sortBy, sortDirection)
  const routeToExchange = useRouteToExchangeAction()
  return (
    <QueryGuard
      {...exchangeHistoryQuery}
      LoadingElement={<ExchangeHistoryListLoading />}
      ErrorElement={<ExchangeHistoryError />}
    >
      {(data) =>
        data.length === 0 ? (
          <SafeCenterAligner>
            <Grid
              container
              direction="column"
              spacing={2}
              alignItems="center"
              justifyContent="center"
            >
              <Grid item>
                <Typography variant="h5" align="center">
                  {t('No exchanges found')}
                </Typography>
              </Grid>
              <Grid item>
                <Box>
                  <OpenExchangeButton
                    label={t('Swap Crypto')}
                    variant="contained"
                    onOpen={routeToExchange}
                    targetAsset="none"
                  />
                </Box>
              </Grid>
            </Grid>
          </SafeCenterAligner>
        ) : (
          <AutoSizer>
            {({height, width}) => (
              <VirtualizedList
                itemData={data}
                itemCount={data.length}
                // `8` is used for space between items
                itemSize={EXCHANGE_HISTORY_ITEM_HEIGHT + 8}
                // without subtracting the `-20` strange flickering occurs
                height={height - 20}
                width={width}
              >
                {VirtualizedRow}
              </VirtualizedList>
            )}
          </AutoSizer>
        )
      }
    </QueryGuard>
  )
}

type VirtualizedRowProps = {
  index: number
  style: CSSProperties
  data: SwapRow[]
}

const VirtualizedRow = React.memo(
  ({index, style, data}: VirtualizedRowProps) => {
    const rowData = data[index]!
    return (
      <div style={style}>
        <ExchangeHistoryItem {...rowData} />
      </div>
    )
  },
)
