import type {Dispatch, ReducerAction, ReactNode} from 'react'
import {createContext, useContext} from 'react'

import type {RampCryptoAsset, RampOperation} from '../../../domain'

import type {RampFlowState, rampFlowStateReducer} from './rampFlowState'
import type {RampFlowViewModel} from './viewModel'

export type RampFlowLayoutProps = {
  header: ReactNode
  body: ReactNode
  footer: ReactNode
}

export type RampFlowBuyWidgetProps = {
  asset: RampCryptoAsset
  address: string
}

export type RampFlowSellWidgetProps = {
  asset: RampCryptoAsset
  address: string
  isPasswordLogin: boolean
}

export type RampFlowWidgetHeaderProps = {
  onBack: () => void
  operation: RampFlowState['operation']
}

export type RampFlowConfig = {
  dispatch: Dispatch<ReducerAction<typeof rampFlowStateReducer>>
  onCancel: () => void
  assetSelectFilter?: (
    asset: RampCryptoAsset,
    operation: RampOperation,
  ) => boolean
  renderLayout: (props: RampFlowLayoutProps) => ReactNode
  renderBuyWidget: (props: RampFlowBuyWidgetProps) => ReactNode
  renderSellWidget?: (props: RampFlowSellWidgetProps) => ReactNode
  renderWidgetHeader?: (props: RampFlowWidgetHeaderProps) => React.ReactNode
  vm: RampFlowViewModel
  fixedAccountAddress?: string
}

const configContext = createContext<RampFlowConfig | null>(null)

export const {Provider: RampFlowConfigProvider} = configContext

export const useRampFlowConfig = () => {
  const config = useContext(configContext)
  if (config == null) {
    throw Error('useRampFlowConfig could not find RampFlowConfigProvider')
  } else return config
}
