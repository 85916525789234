import type {Lovelaces} from '@nufi/wallet-cardano'
import {
  // Note that when imported from `../cardano` the flow tx build tests were failing.
  // It looks like that `../cardano` is importing some dependency that makes these
  // tests fail.
  // That should hopefully get resolved when fully separating cardano to workspace,
  // and dropping some of its current dependencies.
  getHumanReadableAssetNameFromHex,
  CARDANO_TOKEN_SEARCHING_KEYS,
  adaToLovelaces,
  lovelacesToAda,
} from '@nufi/wallet-cardano'
import type {Nanoflow} from '@nufi/wallet-flow'
import {
  FLOW_TOKEN_SEARCHING_KEYS,
  flowToNanoflow,
  nanoFlowToFlow,
} from '@nufi/wallet-flow'
import {
  SOLANA_TOKEN_SEARCHING_KEYS,
  lamportsToSol,
  solToLamports,
} from '@nufi/wallet-solana'
import type {Lamports} from '@nufi/wallet-solana'
import BigNumber from 'bignumber.js'
import _ from 'lodash'

import type {WalletKind} from 'src/wallet/walletKind'

import type {TokenBlockchain} from '../../blockchainTypes'
import {safeAssertUnreachable} from '../../utils/assertion'
import {EVM_TOKEN_SEARCHING_KEYS} from '../evm/constants'
import {nativeToWei, weiToNative} from '../evm/sdk/utils'
import type {EvmWei} from '../evm/types'
import {isEvmBlockchain} from '../evm/utils'
import type {
  Blockchain,
  TokenSearchingKeys,
  TokenMetadata,
  ProfileAccount,
  Nft,
  TokenId,
} from '../types'
import {parseNft} from '../utils/nfts'
export {getDefaultAccountId} from '../utils/common'

export const commonParseTokenAmount = (
  amount: string,
  decimals: number,
): BigNumber => {
  const conversionBase = new BigNumber(10).pow(decimals)
  return new BigNumber(amount).multipliedBy(conversionBase)
}

export const parseNativeAmount = (
  blockchain: Blockchain,
  amount: string,
): BigNumber => {
  switch (blockchain) {
    case 'cardano':
      return adaToLovelaces(amount)
    case 'solana':
      return solToLamports(amount)
    case 'flow':
      return flowToNanoflow(amount)
    default: {
      if (isEvmBlockchain(blockchain)) {
        return nativeToWei(amount)
      }
      return safeAssertUnreachable(blockchain)
    }
  }
}

export const formatNativeAmount = (
  blockchain: Blockchain,
  amount: BigNumber,
): string => {
  switch (blockchain) {
    case 'cardano':
      return lovelacesToAda(amount as Lovelaces)
    case 'solana':
      return lamportsToSol(amount as Lamports)
    case 'flow':
      return nanoFlowToFlow(amount as Nanoflow)
    default: {
      if (isEvmBlockchain(blockchain)) {
        return weiToNative(amount as EvmWei<typeof blockchain>)
      }
      return safeAssertUnreachable(blockchain)
    }
  }
}

export const commonFormatTokenAmount = (
  amount: BigNumber,
  decimals: number,
): string => {
  const conversionBase = new BigNumber(10).pow(decimals)

  return amount.dividedBy(conversionBase).toFixed()
}

export function getTokenSearchingKeys(
  blockchain: TokenBlockchain,
): TokenSearchingKeys {
  switch (blockchain) {
    case 'cardano':
      return CARDANO_TOKEN_SEARCHING_KEYS
    case 'solana':
      return SOLANA_TOKEN_SEARCHING_KEYS
    case 'flow':
      return FLOW_TOKEN_SEARCHING_KEYS
    default: {
      if (isEvmBlockchain(blockchain)) {
        return EVM_TOKEN_SEARCHING_KEYS
      }
      return safeAssertUnreachable(blockchain)
    }
  }
}

export function getTokenSearchingValues(
  tokenMetadata: Extract<TokenMetadata, {blockchain: TokenBlockchain}>,
): string[] {
  const keys = getTokenSearchingKeys(tokenMetadata.blockchain)
  return _.compact(
    keys.map((key) => tokenMetadata[key as keyof TokenMetadata] as string),
  )
}

export function parseTokenInfoFromMeta(tokenMetadata: TokenMetadata): {
  assetName: string | undefined
  tokenIdentifier: string
  blockchain: TokenBlockchain
  decimals: number
} {
  const {blockchain, name, decimals} = tokenMetadata
  switch (blockchain) {
    case 'cardano':
      return {
        assetName:
          name ||
          getHumanReadableAssetNameFromHex(tokenMetadata.assetNameHex) ||
          undefined,
        decimals,
        blockchain,
        tokenIdentifier: tokenMetadata.fingerprint,
      }
    case 'solana':
      return {
        assetName: name || undefined,
        decimals,
        blockchain,
        tokenIdentifier: tokenMetadata.mint,
      }
    case 'flow': {
      return {
        assetName: name || undefined,
        decimals,
        blockchain,
        tokenIdentifier: tokenMetadata.id,
      }
    }

    default: {
      if (isEvmBlockchain(blockchain)) {
        return {
          assetName: name || undefined,
          decimals,
          blockchain,
          tokenIdentifier: tokenMetadata.contractAddress,
        }
      }
      return safeAssertUnreachable(blockchain)
    }
  }
}

export const isAccountForBlockchain = (
  account: ProfileAccount,
  blockchain: Blockchain,
): boolean => {
  switch (account.type) {
    case 'standard':
      return account.blockchain === blockchain
    case 'evm':
      return isEvmBlockchain(blockchain)
    default:
      return safeAssertUnreachable(account)
  }
}

export const isAccountForWalletKind = (
  account: ProfileAccount,
  walletKind: WalletKind,
): boolean => {
  switch (account.type) {
    case 'standard':
      return account.blockchain === walletKind
    case 'evm':
      return walletKind === 'evm'
    default:
      return safeAssertUnreachable(account)
  }
}

export const isAccountForSomeWalletKind = (
  account: ProfileAccount,
  walletKinds: WalletKind[],
): boolean => {
  return walletKinds.some((walletKind) =>
    isAccountForWalletKind(account, walletKind),
  )
}

export const isNft = (tokenMetadata: TokenMetadata): boolean => {
  switch (tokenMetadata.blockchain) {
    case 'solana':
      return !!tokenMetadata.nftInfo
    case 'cardano':
    case 'flow':
      return !!tokenMetadata.isNft
    default: {
      if (isEvmBlockchain(tokenMetadata.blockchain)) {
        return !!tokenMetadata.isNft
      }
      return safeAssertUnreachable(tokenMetadata.blockchain)
    }
  }
}

export const getTokenNameAndIdentifier = (
  nftInfo: Nft | null,
  tokenMetadata: TokenMetadata,
): {
  assetName: string | undefined
  tokenIdentifier: string
  tokenId: TokenId
} => {
  if (nftInfo) {
    const {name, id} = parseNft(nftInfo)
    return {assetName: name, tokenIdentifier: id, tokenId: id}
  } else {
    const {assetName, tokenIdentifier} = parseTokenInfoFromMeta(tokenMetadata)
    return {assetName, tokenIdentifier, tokenId: tokenMetadata.id}
  }
}
