import {Grid} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {Alert} from 'src/components'

export const TransactionMismatchAlert = () => {
  const {t} = useTranslation()
  return (
    <Grid item>
      <Alert severity="warning">
        {t(
          `We weren't able to completely parse the transaction. Some information may be missing or transaction signing might fail.`,
        )}
      </Alert>
    </Grid>
  )
}
