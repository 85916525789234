import type {ModifierKey} from '../constants'

import {safeAssertUnreachable} from './assertion'
import {detectIncognito} from './detectIncognito'

export const isIncognito = async (): Promise<boolean> => {
  const promise = await new Promise<boolean>((resolve) => {
    detectIncognito((callback) => {
      resolve(callback.isPrivate)
    })
  }).catch((err) => {
    throw err
  })

  return promise
}

const getOSName = (): string | undefined =>
  // TS doesn't ship with type declarations for the userAgentData
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  navigator?.userAgentData?.platform || navigator?.platform

export const isMacOS = (): boolean => {
  const osName = getOSName()
  return osName ? /mac/.test(osName.toLowerCase()) : false
}

export const getModifierNameByOS = (modifier: ModifierKey) => {
  if (isMacOS()) {
    switch (modifier) {
      case 'Control':
        return 'Ctrl'
      case 'Alt':
        return 'Option'
      case 'Meta':
        return 'Command'
      case 'Shift':
        return 'Shift'
      default:
        return safeAssertUnreachable(modifier)
    }
  } else {
    switch (modifier) {
      case 'Control':
        return 'Ctrl'
      case 'Alt':
        return 'Alt'
      case 'Meta':
        return 'Windows'
      case 'Shift':
        return 'Shift'
      default:
        return safeAssertUnreachable(modifier)
    }
  }
}

export const getRefreshKeyLabel = () => {
  if (isMacOS()) {
    return 'CMD + R'
  }
  return 'F5'
}
