import {assert} from '@nufi/frontend-common'
import {
  assertBlockchainIsInSubset,
  isBlockchainSubset,
} from '@nufi/wallet-common'
import BigNumber from 'bignumber.js'
import {ethers, BigNumber as EthersBigNumber} from 'ethers'

import {evmBlockchains} from './blockchainTypes'
import type {
  EvmAddress,
  EvmContractAddress,
  EvmTokenMetadata,
  EvmTransactionReceipt,
  EvmWei,
  EvmTxEntry,
} from './sdk'
import {
  getAffectedTokenIdFromTx,
  nativeToWei,
  toChecksummedEvmAddress,
} from './sdk'
import type {EvmBlockchain, EvmTokenAmount, EvmTxHistoryEntry} from './types'

export const isEvmBlockchain = (
  blockchain: unknown,
): blockchain is EvmBlockchain => isBlockchainSubset(blockchain, evmBlockchains)

// Note that we can not use just `isEvmBlockchain(data.type)` here
// as TS wil only infer type of `data.type` but will infer nothing
// about the `data` object itself.
export const isEvmBlockchainMetadata = (
  data: unknown,
): data is Extract<
  EvmTokenMetadata<EvmBlockchain>,
  {blockchain: EvmBlockchain}
> =>
  typeof data === 'object' &&
  data !== null &&
  'blockchain' in data &&
  isEvmBlockchain(data.blockchain)

export function assertIsEvmBlockchain(
  blockchain: unknown,
): asserts blockchain is EvmBlockchain {
  assertBlockchainIsInSubset(blockchain, evmBlockchains)
}

export const hasEvmBlockchainProp = <T extends {blockchain: unknown}>(
  t: T | null | undefined,
): t is T & {blockchain: EvmBlockchain} =>
  isBlockchainSubset(t?.blockchain, evmBlockchains)

const getTokenEffect = (value: string, isRecipient: boolean): BigNumber =>
  new BigNumber(value).times(isRecipient ? 1 : -1)

const isTokenTransfer = (tx: EvmTxEntry<EvmBlockchain>): boolean =>
  !!tx.category &&
  ['erc20', 'erc721', 'erc1155', 'specialnft'].includes(tx.category)

const getTokenEffects = <TBlockchain extends EvmBlockchain>(
  address: EvmAddress<TBlockchain>,
  tx: EvmTxEntry<TBlockchain>,
  blockchain: TBlockchain,
): EvmTokenAmount<TBlockchain>[] => {
  // TODO: add token tx types - transfer, mint, burn
  // https://vacuum.atlassian.net/jira/software/projects/ADLT/boards/171?selectedIssue=ADLT-2089
  // if to 0x0000000000000000000000000000000000000000, is burn
  // if from 0x0000000000000000000000000000000000000000, is mint

  if (isTokenTransfer(tx)) {
    const contractAddress = tx.rawContract.address
    const tokenId = getAffectedTokenIdFromTx(tx)
    assert(contractAddress != null && tokenId !== null)

    const isSelfTx = tx.to === tx.from
    if (isSelfTx) return []

    // alchemy lowerCases all addresses, but blockchain returns them checksummed
    const checksummedAddress = toChecksummedEvmAddress(
      contractAddress as string as EvmAddress<TBlockchain>,
    ) as string as EvmContractAddress<TBlockchain>

    const token = {
      blockchain,
      contractAddress: checksummedAddress,
      id: tokenId,
    }

    const isRecipient = toChecksummedEvmAddress(tx.to) === address

    if (tx.category === 'erc1155') {
      return [
        {
          token,
          amount: getTokenEffect(tx.erc1155Metadata.value, isRecipient),
        },
      ]
    }

    if (tx.category === 'erc20') {
      assert(!!tx.rawContract.value)
      return [
        {
          token,
          amount: getTokenEffect(tx.rawContract.value, isRecipient),
        },
      ]
    }

    if (tx.category === 'erc721' || tx.category === 'specialnft') {
      return [
        {
          token,
          // these are always non-fungible (1)
          amount: getTokenEffect('1', isRecipient),
        },
      ]
    }
  }

  return []
}

const getFeeFromTransactionReceipt = <TBlockchain extends EvmBlockchain>(
  receipt: EvmTransactionReceipt,
): EvmWei<TBlockchain> => {
  const fee = new BigNumber(receipt.gasUsed.toString()).times(
    new BigNumber(receipt.effectiveGasPrice.toString()),
  )
  // to get fee from some l2 chains, such as optimism, we need to add l1Fee
  if (EthersBigNumber.isBigNumber(receipt.l1Fee)) {
    // fee(l2Fee) + l1Fee
    return fee.plus(
      new BigNumber(receipt.l1Fee.toString()),
    ) as EvmWei<TBlockchain>
  }
  return fee as EvmWei<TBlockchain>
}

export const formatEvmTxHistoryEntry = <TBlockchain extends EvmBlockchain>(
  address: EvmAddress<TBlockchain>,
  receipt: EvmTransactionReceipt,
  tx: EvmTxEntry<TBlockchain>,
  blockchain: TBlockchain,
): EvmTxHistoryEntry<TBlockchain> => {
  // there is no reason for these to be empty
  assert(
    !!receipt.gasUsed,
    `${blockchain} txHistory gasUsed should not be undefined`,
  )
  assert(
    !!tx.metadata.blockTimestamp,
    `${blockchain} txHistory timestamp should not be undefined`,
  )

  const fee = getFeeFromTransactionReceipt<TBlockchain>(receipt)

  const weiAmount = !isTokenTransfer(tx)
    ? nativeToWei((tx.value || 0).toString())
    : 0
  let weiEffect = (
    receipt.from === address ? fee.plus(weiAmount).negated() : new BigNumber(0)
  ) as EvmWei<TBlockchain>

  if (receipt.to === address)
    weiEffect = weiEffect.plus(weiAmount) as EvmWei<TBlockchain>

  return {
    weiEffect,
    txHash: tx.hash,
    time: new Date(tx.metadata.blockTimestamp),
    fee,
    tokenEffects: getTokenEffects(address, tx, blockchain),
  }
}

export const toBigNumber = (bigNumberish: ethers.BigNumberish): BigNumber => {
  return new BigNumber(ethers.BigNumber.from(bigNumberish).toString())
}

export const isContractCode = (codeByteString: string) => {
  return !!codeByteString && codeByteString !== '0x' && codeByteString !== '0x0'
}
