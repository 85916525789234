import {isAccountActive} from '@nufi/wallet-common'
import {evmBlockchains, getEvmAccountId} from '@nufi/wallet-evm'
import _ from 'lodash'

import type {ProfileData, ProfileSettings} from 'src/appStorage'
import type {
  Blockchain,
  CryptoProviderType,
  EvmProfileAccount,
  HexString,
  ProfileAccount,
  TokenImport,
  TokenVisibility,
} from 'src/types'
import {assert} from 'src/utils/assertion'
import {isEvmBlockchain} from 'src/wallet/evm/utils'

const accountKey = (account: {
  type: ProfileAccount['type']
  blockchain?: Blockchain
  publicKeyHex: HexString
  cryptoProviderType: CryptoProviderType
}) =>
  account.type === 'standard'
    ? `${account.blockchain}|${account.publicKeyHex}`
    : getEvmAccountId(account.publicKeyHex, account.cryptoProviderType)

const mergeSettings = (
  localSettings: ProfileSettings,
  remoteSettings: ProfileSettings,
): ProfileSettings => {
  // Take over settings with latest timestamp, remote on equal
  if (remoteSettings.updatedAt >= localSettings.updatedAt) {
    return remoteSettings
  } else {
    return localSettings
  }
}

/**
 * @returns an array with default accounts for each blockchain which has at least one account.
 */
const getNewDefaultAccounts = (
  accounts: (ProfileAccount & {
    blockchain: Blockchain
    isDefault?: boolean
  })[],
) => {
  // Consider only active (not forgotten) accounts
  const activeAccounts = accounts.filter(isAccountActive)

  // Extract blockchains
  const blockchains = new Set(activeAccounts.map((it) => it.blockchain))

  // Split accounts per blockchain i.e. the result is
  // [[cardanoAccount1, cardanoAccount2], [solanaAccount1, solanaAccount2], ...]
  const accountsPerBlockchain = [...blockchains].map((blockchain) =>
    activeAccounts.filter((account) => account.blockchain === blockchain),
  )

  // Sort the accounts so that the default accounts are first (a.isDefault). If there are multiple
  // default accounts take the one which was updated later (a.updatedAt). We sort by `publicKeyHex`
  // and `cryptoProviderType` as well to make the order deterministic.
  const sortedAccountsPerBlockchain = accountsPerBlockchain.map((it) =>
    _.orderBy(
      it,
      [
        (a) => a.isDefault,
        // Sort by `updatedAt` as well so that the account with the latest changes is first.
        (a) => a.updatedAt,
        (a) => a.publicKeyHex,
        (a) => a.cryptoProviderType,
      ],
      ['desc', 'desc', 'desc', 'desc'],
    ),
  )

  // Take first accounts from sorted accounts, i.e. latest from isDefault === true or latest from all (if there's no account with isDefault === true)
  return sortedAccountsPerBlockchain
    .map((it) => _.head(it))
    .filter((a): a is Exclude<typeof a, undefined> => a != null)
}

/**
 * Make sure there is exactly one default account per blockchain (if that blockchain has some account).
 */
const reassignDefaultAccounts = (
  accounts: ProfileAccount[],
  enabledBlockchains: readonly Blockchain[],
): ProfileAccount[] => {
  const [evmAccounts, nonEvmAccounts] = _.partition(
    accounts,
    (a): a is EvmProfileAccount => a.type === 'evm',
  )

  const newUpdatedAt = Date.now()

  const reassignedNonEvmAccounts = (() => {
    const newDefaultAccounts = getNewDefaultAccounts(nonEvmAccounts)

    return nonEvmAccounts.map((it) => {
      const newIsDefault = newDefaultAccounts.includes(it)

      return newIsDefault === it.isDefault
        ? it
        : {...it, isDefault: newIsDefault, updatedAt: newUpdatedAt}
    })
  })()

  const reassignedEvmAccounts = (() => {
    const transformedEvmAccounts = evmAccounts
      .map((it) =>
        // In order to not change the default account resolution because of evm accounts
        // we rather update the EVM accounts with properties which are used to determine
        // the default account. I.e. we rather adapt the EVM accounts to the default account
        // resolution than adapt the resolution to EVM accounts which might be more complicated
        // because of `defaultForBlockchains`.
        evmBlockchains
          .filter((evmBlockchain) => enabledBlockchains.includes(evmBlockchain))
          .map((evmBlockchain) => {
            return {
              ...it,
              blockchain: evmBlockchain,
              isDefault: it.defaultForBlockchains.includes(evmBlockchain),
            }
          }),
      )
      .flat()

    const newDefaultAccounts = getNewDefaultAccounts(transformedEvmAccounts)

    return evmAccounts.map((it) => {
      const newDefaultForBlockchains = newDefaultAccounts
        .filter((a) => accountKey(a) === accountKey(it))
        .map((a) => {
          assert(isEvmBlockchain(a.blockchain))
          return a.blockchain
        })

      return _.isEqual(newDefaultForBlockchains, it.defaultForBlockchains)
        ? it
        : {
            ...it,
            defaultForBlockchains: newDefaultForBlockchains,
            updatedAt: newUpdatedAt,
          }
    })
  })()

  return [...reassignedNonEvmAccounts, ...reassignedEvmAccounts]
}

const assertAccountsMergedOnlyOnce = (
  mergedAccounts: ProfileAccount[],
  localAccounts: ProfileAccount[],
  remoteAccounts: ProfileAccount[],
) => {
  localAccounts.forEach((la) =>
    assert(
      mergedAccounts.filter((ma) => accountKey(ma) === accountKey(la))
        .length === 1,
    ),
  )

  remoteAccounts.forEach((ca) =>
    assert(
      mergedAccounts.filter((ma) => accountKey(ma) === accountKey(ca))
        .length === 1,
    ),
  )
}

const mergeAccounts = (
  localAccounts: ProfileAccount[],
  remoteAccounts: ProfileAccount[],
  enabledBlockchains: readonly Blockchain[],
): ProfileAccount[] => {
  // Merge remote and local accounts
  const localAccountsMap = new Map(
    localAccounts.map((it) => [accountKey(it), it]),
  )

  const remoteAccountsMap = new Map(
    remoteAccounts.map((it) => [accountKey(it), it]),
  )

  const remoteAccountsToUse = remoteAccounts.filter((account) => {
    const localAccount = localAccountsMap.get(accountKey(account))

    return !localAccount || account.updatedAt >= localAccount.updatedAt
  })

  const localAccountsToUse = localAccounts.filter((account) => {
    const remoteAccount = remoteAccountsMap.get(accountKey(account))

    return !remoteAccount || account.updatedAt > remoteAccount.updatedAt
  })

  const mergedAccounts = remoteAccountsToUse.concat(localAccountsToUse)

  assertAccountsMergedOnlyOnce(mergedAccounts, localAccounts, remoteAccounts)

  const mergedAccountsWithReassignedDefaults = reassignDefaultAccounts(
    mergedAccounts,
    enabledBlockchains,
  )

  return mergedAccountsWithReassignedDefaults
}

const tokenImportKey = (token: TokenImport) =>
  `${token.blockchain}|${token.id}|${token.chainId}`

const assertTokensMergedOnlyOnce = (
  mergedTokens: TokenImport[],
  localTokens: TokenImport[],
  remoteTokens: TokenImport[],
) => {
  localTokens.forEach((lt) =>
    assert(
      mergedTokens.filter((mt) => tokenImportKey(mt) === tokenImportKey(lt))
        .length === 1,
    ),
  )

  remoteTokens.forEach((ct) =>
    assert(
      mergedTokens.filter((mt) => tokenImportKey(mt) === tokenImportKey(ct))
        .length === 1,
    ),
  )
}

export const mergeTokenImports = (
  localTokens: TokenImport[],
  remoteTokens: TokenImport[],
): TokenImport[] => {
  // Merge remote and local tokens
  const localTokensMap = new Map(
    localTokens.map((it) => [tokenImportKey(it), it]),
  )

  const remoteTokensMap = new Map(
    remoteTokens.map((it) => [tokenImportKey(it), it]),
  )

  const remoteTokensToUse = remoteTokens.filter((t) => {
    const localToken = localTokensMap.get(tokenImportKey(t))

    return !localToken || t.updatedAt >= localToken.updatedAt
  })

  const localTokensToUse = localTokens.filter((t) => {
    const remoteToken = remoteTokensMap.get(tokenImportKey(t))

    return !remoteToken || t.updatedAt > remoteToken.updatedAt
  })

  const mergedTokens = remoteTokensToUse.concat(localTokensToUse)

  assertTokensMergedOnlyOnce(mergedTokens, localTokens, remoteTokens)

  return mergedTokens
}

export const mergeTokenVisibilities = (
  localTokenVisibilities: TokenVisibility[],
  remoteTokenVisibilities: TokenVisibility[],
): TokenVisibility[] => {
  const concatenatedEntries = localTokenVisibilities.concat(
    remoteTokenVisibilities,
  )

  const result = _.chain(concatenatedEntries)
    .orderBy(['blockchain', 'tokenId', 'updatedAt'], ['desc', 'desc', 'desc'])
    // remove duplicates = uniqBy takes first one, discards others. Therefore make sure to sort updatedAt is descending.
    .uniqBy('tokenId')
    .value()

  return result
}

export const mergeProfileData = ({
  localProfileData,
  remoteProfileData,
}: {
  localProfileData: ProfileData
  remoteProfileData: ProfileData
}): ProfileData => {
  const settings = mergeSettings(
    localProfileData.settings,
    remoteProfileData.settings,
  )

  return {
    settings,
    accounts: mergeAccounts(
      localProfileData.accounts,
      remoteProfileData.accounts,
      settings.enabledBlockchains,
    ),
    tokenImports: mergeTokenImports(
      localProfileData.tokenImports,
      remoteProfileData.tokenImports,
    ),
    tokenVisibilities: mergeTokenVisibilities(
      localProfileData.tokenVisibilities,
      remoteProfileData.tokenVisibilities,
    ),
  }
}
