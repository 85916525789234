import {useTranslation} from 'react-i18next'
import * as yup from 'yup'

import {useCurrentLoginInfo} from '../../../store/auth'
import type {AccountInfo, AccountId} from '../../../wallet'
import {ensureAccountById} from '../../../wallet/utils/common'
import {isPasswordVerificationRequired} from '../../utils'

export type BaseDetailsValues = {
  amount: string
  accountId: AccountId
}

type SummaryDetailsValues = {
  password: string
}

export type BaseCreateStakeSchema = BaseDetailsValues & SummaryDetailsValues

type SummaryDetailsSchemaArgs = {
  accounts: Array<AccountInfo>
}

const _useSummaryDetailsSchema = ({accounts}: SummaryDetailsSchemaArgs) => {
  const {t} = useTranslation()
  const loginInfo = useCurrentLoginInfo()
  // `yup.when` can not be typed so we use this workaround
  const accountIdKey: keyof BaseCreateStakeSchema = 'accountId'
  return {
    password: yup.string().when(accountIdKey, ([accountId]: unknown[]) => {
      const selectedAccount = ensureAccountById(
        accounts,
        accountId as AccountId,
      )
      if (isPasswordVerificationRequired(loginInfo, selectedAccount)) {
        return yup.string().required(t('Password is required.'))
      }
      return yup.string()
    }),
  }
}

// ensure consistency of keys / values on TS level
export const useSummaryDetailsSchema: (
  ...args: Parameters<typeof _useSummaryDetailsSchema>
) => {
  [k in keyof SummaryDetailsValues]: ReturnType<
    typeof _useSummaryDetailsSchema
  >[k]
} = _useSummaryDetailsSchema
