/* eslint-disable @typescript-eslint/explicit-function-return-type */

import {cloudSyncServiceLocator} from 'src/features/profileSync/application'
import {INIT_WALLET_CONFIGURATIONS} from 'src/features/walletStorage/application'
import {useMutation} from 'src/utils/mutation-utils'
import {useVolatileImperativeQuery} from 'src/utils/query-utils'

import {getMockServices} from '../__tests__/storybook/MockServiceLocator'
import {clearPersistentTxStore} from '../store/transactions'
import type {Blockchain} from '../types'
import type {Mnemonic} from '../wallet/types'
import type {AutoDiscoveryType} from '../wallet/utils/walletUtils'

import {commonAppInit} from './initCoreServices'
import MasterProfileManager from './masterProfileManager'
import {localProfileManagerLocator} from './profileManager'
import {
  useCurrentProfileMeta,
  useLocalProfile,
  useProfileInfos,
} from './queries'
import type {
  ProfilePassword,
  ProfileName,
  LocalProfileId,
  ProfileBackup,
  LocalProfileLoginType,
} from './types'

export const useCreateAndInitLocalProfile = () =>
  useMutation(
    'createAndInitLocalProfile',
    async (args: {
      profileName: ProfileName
      password: ProfilePassword
      mnemonic: Mnemonic
      isHwUser: boolean
      isMnemonicActivated: boolean
      loginType: LocalProfileLoginType
      enabledBlockchains: readonly Blockchain[]
    }) => {
      await MasterProfileManager.createNewLocalProfile(args)
      const blockchainMap = (() => {
        if (args.isHwUser || !args.isMnemonicActivated) return {}
        return args.enabledBlockchains.reduce(
          (res, b) => {
            res[b] = 'usedOrFallback'
            return res
          },
          {} as Partial<Record<Blockchain, AutoDiscoveryType>>,
        )
      })()
      await commonAppInit(
        INIT_WALLET_CONFIGURATIONS.getCreateWalletArgs(
          blockchainMap,
          args.loginType,
        ),
      )
    },
  )

export const useRestoreProfileFromMnemonic = () =>
  useMutation(
    'restoreProfileFromMnemonic',
    async (
      args: {
        profileName: ProfileName
        password: ProfilePassword
        mnemonic: Mnemonic
      } & (
        | {isProfileSynced: true}
        | {isProfileSynced: false; enabledBlockchains: readonly Blockchain[]}
      ),
    ) => {
      const {isProfileSynced} = args

      await MasterProfileManager.createNewLocalProfile({
        ...args,
        // Note that always setting `isHwUser: false` when restoring from mnemonic may not be
        // correct. Though for now we decided to ignore this case & handle it later if there will
        // be any complains, or handle it automatically once Cloud sync will be implemented.
        isHwUser: false,
        isMnemonicActivated: true,
        loginType: 'password',
        // user is notified in the UI and has to trigger 'overwrite' action
        overwriteExistingProfile: true,
        // If profile is synced we don't need to set enabledBlockchains since they will
        // be fetched from and overwritten by cloud sync.
        enabledBlockchains: !isProfileSynced ? args.enabledBlockchains : [],
      })
      await commonAppInit(
        isProfileSynced
          ? INIT_WALLET_CONFIGURATIONS.getRestoreCloudSyncedMnemonicWallet(
              'password',
            )
          : INIT_WALLET_CONFIGURATIONS.getRestoreMnemonicWallet(
              'password',
              args.enabledBlockchains,
            ),
      )
    },
  )

export const useVerifyLocalProfilePassword = () =>
  useMutation(
    'verifyLocalProfilePassword',
    async (args: {profileId: LocalProfileId; password: ProfilePassword}) =>
      await MasterProfileManager.verifyLocalProfilePassword(args),
  )

export const useInitLocalProfile = () =>
  useMutation(
    'initLocalProfile',
    async (args: {
      profileId: LocalProfileId
      password: ProfilePassword
      loginType: LocalProfileLoginType
    }) => {
      await MasterProfileManager.initLocalProfile(args)
      await commonAppInit(
        INIT_WALLET_CONFIGURATIONS.getLoginToAppArgs(args.loginType),
      )
    },
  )

const checkProfileConflictFromMnemonic = async (args: {
  mnemonic: Mnemonic
  loginType: LocalProfileLoginType
  profileName: ProfileName
}) => await MasterProfileManager.checkProfileConflictFromMnemonic(args)

export type CheckProfileConflictFromMnemonic =
  typeof checkProfileConflictFromMnemonic

// Not an actual mutation, but cannot be query - because of arguments passing
export const useCheckProfileConflictFromMnemonic = () =>
  useVolatileImperativeQuery(
    async (args: {
      mnemonic: Mnemonic
      loginType: LocalProfileLoginType
      profileName: ProfileName
    }) => {
      const mockServices = getMockServices()
      return (
        mockServices?.checkProfileConflictFromMnemonic?.(args) ||
        checkProfileConflictFromMnemonic(args)
      )
    },
  )

export const useRestoreLocalProfileFromBackup = () =>
  useMutation(
    'restoreLocalFromBackup',
    async (args: {
      profileBackup: ProfileBackup
      profileName: ProfileName
      password: ProfilePassword
    }) => {
      await MasterProfileManager.restoreLocalProfileFromBackup(args)
      await commonAppInit(
        INIT_WALLET_CONFIGURATIONS.getRestoreWalletFromBackupArgs('password'),
      )
    },
  )

export const useChangeProfilePassword = () =>
  useMutation(
    'changeProfilePassword',
    async ({
      currentPassword,
      newPassword,
    }: {
      currentPassword: ProfilePassword
      newPassword: ProfilePassword
    }) => {
      await localProfileManagerLocator
        .instance()
        .changePassword(currentPassword, newPassword)
    },
  )

export const useInitMasterProfileManager = () =>
  useMutation('initMasterProfileManager', async () => {
    await MasterProfileManager.init()
    return true
  })

export function useExportProfile() {
  return useMutation(
    'exportProfile',
    async () => await localProfileManagerLocator.instance().export(),
  )
}

export function useRemoveCurrentProfile() {
  return useMutation('removeCurrentProfile', async () => {
    await MasterProfileManager.removeCurrentProfile()
    // Note that persistent tx store is not managed by our
    // `master` and `profile` managers and therefore we clear it here.
    // In the current architecture it even can not be, as moving
    // this `clearPersistentTxStore` call directly to `removeCurrentProfile`
    // would cause dependency cycles.
    await clearPersistentTxStore()
  })
}

export function useRenameCurrentProfile() {
  return useMutation(
    'removeCurrentProfile',
    async ({name}: {name: ProfileName}) => {
      await MasterProfileManager.renameCurrentProfile({name})
    },
    {
      invalidationKeys: [
        useProfileInfos.__key,
        useCurrentProfileMeta.__key,
        useLocalProfile.__key,
      ],
    },
  )
}

export function useSyncProfileData() {
  return useMutation('syncProfileData', async () => {
    return await cloudSyncServiceLocator.instance().syncProfileData()
  })
}
