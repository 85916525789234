import type {AccountId} from '@nufi/wallet-common'
import type {
  SolanaTokenId,
  SolanaTokenMetadata,
  SolanaTokenAmount,
} from '@nufi/wallet-solana'

import {useGetTokensByAccount} from '../../../wallet/solana'
import {useLoadTokens} from '../loadTokens'
import type {
  UseAssetsConversionParams,
  UseBlockchainAssetsReturnType,
} from '../types'

export function getSolanaTokenSortingLabel(
  tokenMetadata: SolanaTokenMetadata,
): string {
  const primaryLabel = `${tokenMetadata.name || tokenMetadata.ticker || ''}`

  return primaryLabel !== ''
    ? `${primaryLabel} (${tokenMetadata.mint})`
    : `${tokenMetadata.mint}`
}

function getNumAccountsOwningToken(
  tokensByAccount: Record<AccountId, SolanaTokenAmount[]>,
  tokenId: SolanaTokenId,
) {
  return Object.values(tokensByAccount).filter((tokens) =>
    tokens.some(({token}) => token.mint === tokenId),
  ).length
}

export function useSolanaTokens({
  conversionRates,
  currency,
  enabled,
}: UseAssetsConversionParams): UseBlockchainAssetsReturnType {
  const tokensByAccountQuery = useGetTokensByAccount(enabled)

  return useLoadTokens({
    blockchain: 'solana',
    conversionRates,
    currency,
    enabled,
    getNumAccountsOwningToken(
      {tokenId}: {tokenId: SolanaTokenId},
      {tokensByAccount},
    ) {
      return getNumAccountsOwningToken(tokensByAccount, tokenId)
    },
    getTokenSortingLabel: getSolanaTokenSortingLabel,
    extraQueries: {tokensByAccount: tokensByAccountQuery},
    getDefaultAccountId: (
      defaultAccountId,
      {tokenId, accounts},
      {tokensByAccount},
    ) => {
      const accountWithTokenAmount = Object.entries(tokensByAccount).find(
        ([, tokens]) =>
          tokens.some(
            ({token, amount}) =>
              token.mint === tokenId && amount.isGreaterThan(0),
          ),
      )?.[0]

      if (accountWithTokenAmount) {
        const defaultAccount = accounts.find(
          ({publicKey}) => publicKey.toString() === accountWithTokenAmount,
        )
        return defaultAccount?.id || defaultAccountId
      }
      return defaultAccountId
    },
  })
}
