import {SolanaDerivationPathType} from '@nufi/wallet-solana'
import type {SolanaAccountInfo} from '@nufi/wallet-solana'
import React from 'react'

import type {AccountInfo} from '../../../wallet'
import {useAddHwAccounts, useDiscoverHwAccounts} from '../../../wallet/solana'
import {useDerivationPathTypeState} from '../sharedActions/addAccount/AddAccountUtils'
import {
  AddHwAccount,
  useAddHwAccountState,
} from '../sharedActions/addAccount/AddHwAccount'

import {useDerivationPathTypeConfig} from './AddAccountUtils'

export function AddLedgerAccount() {
  const addHwAccountState = useAddHwAccountState()
  const derivationPathTypeConfig = useDerivationPathTypeConfig()

  const derivationPathTypeState = useDerivationPathTypeState(
    SolanaDerivationPathType.Bip44Change,
    derivationPathTypeConfig,
  )

  const discoveredAccountsQuery = useDiscoverHwAccounts(
    derivationPathTypeState.derivationPathType,
    'ledger',
  )
  const addAccounts = useAddHwAccounts('ledger')

  const onSubmit = async (newAccounts: AccountInfo[]) => {
    await addAccounts.mutateAsync({
      newAccounts: newAccounts as SolanaAccountInfo[],
    })
  }

  return (
    <AddHwAccount
      hwVendor="ledger"
      blockchain="solana"
      addAccountsMutation={addAccounts}
      {...{
        discoveredAccountsQuery,
        onSubmit,
        ...addHwAccountState,
        derivationPathTypeState,
      }}
    />
  )
}
