import {styled, Switch as MuiSwitch, Stack, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {
  BlockchainIcon,
  FooterLayout,
  InlineLoading,
  ModalFooter,
  ModalHeader,
  ModalLayout,
  SafeCenterAligner,
} from 'src/components'
import type {Blockchain} from 'src/types'
import {useGetBlockchainName} from 'src/utils/translations'

import {ManageBlockchainsError} from './ManageBlockchainsError'

type BlockchainsScreenProps = {
  blockchains: readonly Blockchain[]
  selectedBlockchains: readonly Blockchain[]
  setSelectedBlockchains: (selectedBlockchains: readonly Blockchain[]) => void
  isLoading: boolean
  error: unknown
  isConfirmationNeeded: boolean
  onSubmit: () => void
  onCancel: () => void
}

export const ManageBlockchainsScreen = ({
  blockchains,
  selectedBlockchains,
  setSelectedBlockchains,
  isLoading,
  error,
  isConfirmationNeeded,
  onSubmit,
  onCancel,
}: BlockchainsScreenProps) => {
  const {t} = useTranslation()

  const toggleBlockchain = (_blockchain: Blockchain) => {
    setSelectedBlockchains(
      selectedBlockchains.includes(_blockchain)
        ? selectedBlockchains.filter((b) => b !== _blockchain)
        : [...selectedBlockchains, _blockchain],
    )
  }

  return (
    <ModalLayout
      header={
        <ModalHeader hasDivider onClose={onCancel}>
          <Typography variant="body1">{t('Manage blockchains')}</Typography>
          <Typography variant="body2" color="textSecondary">
            {t(
              'Choose which blockchains you would like to see in your wallet.',
            )}
          </Typography>
        </ModalHeader>
      }
      body={
        <>
          {isLoading ? (
            <SafeCenterAligner>
              <InlineLoading />
            </SafeCenterAligner>
          ) : (
            <Stack sx={{padding: 2, gap: 2}}>
              <Stack>
                {blockchains.map((blockchain) => (
                  <BlockchainItem
                    blockchain={blockchain}
                    isSelected={selectedBlockchains.includes(blockchain)}
                    toggleBlockchain={toggleBlockchain}
                  />
                ))}
              </Stack>
              {error != null && <ManageBlockchainsError error={error} />}
            </Stack>
          )}
        </>
      }
      footer={
        <ModalFooter hasDivider>
          <FooterLayout
            leftBtnConf={{
              children: t('Cancel'),
              onClick: onCancel,
              disabled: isLoading,
            }}
            rightBtnConf={{
              children: isConfirmationNeeded ? t('Continue') : t('Confirm'),
              onClick: onSubmit,
              disabled: isLoading,
            }}
          />
        </ModalFooter>
      }
    />
  )
}

type BlockchainItemProps = {
  blockchain: Blockchain
  isSelected: boolean
  toggleBlockchain: (blockchain: Blockchain) => void
}

const BlockchainItem = ({
  blockchain,
  isSelected,
  toggleBlockchain,
}: BlockchainItemProps) => {
  const getBlockchainName = useGetBlockchainName()

  return (
    <BlockchainItemStack
      key={blockchain}
      direction="row"
      onClick={() => toggleBlockchain(blockchain)}
    >
      <BlockchainIcon blockchain={blockchain} />
      {getBlockchainName(blockchain)}
      <div style={{marginLeft: 'auto'}}>
        <MuiSwitch
          checked={isSelected}
          onChange={() => toggleBlockchain(blockchain)}
          size="small"
        />
      </div>
    </BlockchainItemStack>
  )
}

const BlockchainItemStack = styled(Stack)(({theme}) => ({
  padding: theme.spacing(1),
  borderRadius: 8,
  gap: theme.spacing(1),
  '&:hover': {
    cursor: 'pointer',
    backgroundColor: theme.palette.action.hover,
  },
}))
