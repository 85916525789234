import type {FlowTokenMetadata} from '@nufi/wallet-flow'
import React from 'react'

import {getCardanoTokenLabelStructure} from 'src/features/assets'

import type {TokenBlockchain} from '../../blockchainTypes'
import type {TokenMetadata} from '../../types'
import {safeAssertUnreachable} from '../../utils/assertion'
import type {CardanoTokenMetadata} from '../../wallet/cardano'
import type {EvmBlockchain, EvmTokenMetadata} from '../../wallet/evm'
import {isEvmBlockchain} from '../../wallet/evm'
import type {SolanaTokenMetadata} from '../../wallet/solana'
import {
  constantEllipsizeString,
  ConstantStringEllipsis,
} from '../visual/atoms/TextUtils'

type FormattedTokenLabelProps = {
  blockchain: TokenBlockchain
  tokenMetadata: TokenMetadata
  hideNonRegistered?: boolean
  withBraces?: boolean
}

export function FormattedTokenLabel({
  blockchain,
  tokenMetadata,
  hideNonRegistered,
  withBraces,
}: FormattedTokenLabelProps) {
  const tokenLabel = ((blockchain: TokenBlockchain) => {
    switch (blockchain) {
      case 'cardano':
        return (
          <FormattedCardanoTokenLabel
            tokenInfo={tokenMetadata as CardanoTokenMetadata}
            ellipsize
          />
        )
      case 'solana':
        return (
          <ConstantStringEllipsis
            value={getSolanaTokenLabel(tokenMetadata as SolanaTokenMetadata)}
          />
        )
      case 'flow':
        return (
          <ConstantStringEllipsis
            value={getFlowTokenLabel(tokenMetadata as FlowTokenMetadata)}
          />
        )
      default: {
        if (isEvmBlockchain(blockchain)) {
          return (
            <ConstantStringEllipsis
              value={getEvmTokenLabel(
                tokenMetadata as EvmTokenMetadata<typeof blockchain>,
              )}
            />
          )
        }
        return safeAssertUnreachable(blockchain)
      }
    }
  })(blockchain)

  const tickerBasedIdentifier = getTickerBasedIdentifier(tokenMetadata)
  const defaultFormat = (value: string | JSX.Element) => <>{value}</>
  const withBracketsFormat = (value: string | JSX.Element) => (
    <>
      {'('}
      {value}
      {')'}
    </>
  )

  const format = withBraces ? withBracketsFormat : defaultFormat
  return tickerBasedIdentifier ? (
    format(tickerBasedIdentifier)
  ) : hideNonRegistered ? (
    <>{''}</>
  ) : (
    format(tokenLabel)
  )
}

function formatNftLabel(name: string | null, ticker: string | null) {
  return name && ticker ? `${name} (${ticker})` : ticker
}

export function getTickerBasedIdentifier(
  tokenMetadata: TokenMetadata,
): string | null {
  switch (tokenMetadata.blockchain) {
    case 'solana':
      return tokenMetadata.nftInfo
        ? formatNftLabel(tokenMetadata.name, tokenMetadata.ticker)
        : tokenMetadata.ticker
    case 'flow': {
      return tokenMetadata.isNft
        ? formatNftLabel(tokenMetadata.name, tokenMetadata.ticker)
        : tokenMetadata.ticker
    }
    default:
      return tokenMetadata.ticker
  }
}

function getSolanaTokenLabel(tokenMetadata: SolanaTokenMetadata): string {
  return tokenMetadata.mint
}

type FormattedCardanoTokenLabelProps = {
  tokenInfo: CardanoTokenMetadata
  ellipsize?: boolean
}

// and ellipsize on a component level,
// gives possibility to later handle ellipsizing on CSS level if we wanted
export function FormattedCardanoTokenLabel({
  tokenInfo,
  ellipsize,
}: FormattedCardanoTokenLabelProps) {
  const {assetName, fingerprint} = getCardanoTokenLabelStructure(tokenInfo)
  if (assetName) {
    return (
      <>{`${assetName} (${
        ellipsize ? constantEllipsizeString(fingerprint) : fingerprint
      })`}</>
    )
  }
  return <>{ellipsize ? constantEllipsizeString(fingerprint) : fingerprint}</>
}

type FormattedFlowTokenLabelProps = {
  tokenInfo: FlowTokenMetadata
  ellipsize?: boolean
}

export function FormattedFlowTokenLabel({
  tokenInfo,
}: FormattedFlowTokenLabelProps) {
  return <>{tokenInfo.name}</>
}

export function getFlowTokenLabel(tokenMetadata: FlowTokenMetadata): string {
  return tokenMetadata.name
}

export function getEvmTokenLabel(
  tokenMetadata: EvmTokenMetadata<EvmBlockchain>,
): string {
  return tokenMetadata.contractAddress
}

export function FormattedEvmTokenLabel({
  tokenInfo,
  ellipsize,
}: {
  tokenInfo: EvmTokenMetadata<EvmBlockchain>
  ellipsize?: boolean | undefined
}) {
  const {name, ticker, contractAddress} = tokenInfo
  return (
    <>
      {[
        name || ticker,
        ellipsize ? constantEllipsizeString(contractAddress) : contractAddress,
      ]
        .filter(Boolean)
        .join(' ')}
    </>
  )
}
