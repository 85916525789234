import type {FlowAccountInfo, FlowTokenId, Nanoflow} from '@nufi/wallet-flow'
import {nanoFlowToFlow} from '@nufi/wallet-flow'
import {useTranslation} from 'react-i18next'
import * as yup from 'yup'

import type {AccountId} from '../../../../../../types'
import type {CommonSchema} from '../ImportTx/schema'

type DetailsSchema = {
  // this is indeed a contractId, but since we don't consider a something like "nft collection" or "contract" we must recast this to tokenId
  // TODO: https://vacuum.atlassian.net/jira/software/projects/ADLT/boards/171/backlog?selectedIssue=ADLT-1530
  contractId: FlowTokenId
  accountId: AccountId
}

export type ImportFlowTokenSchema = DetailsSchema & CommonSchema

const _useDetailsSchema = (
  account: FlowAccountInfo,
  txFee: Nanoflow,
  maxSendableAmount: Nanoflow,
) => {
  const {t} = useTranslation()

  // i18next-scanner reports error when declaring this insight `t` statement
  const formattedMinimalBalance = nanoFlowToFlow(
    account.balance.minus(maxSendableAmount) as Nanoflow,
  )

  return {
    contractId: yup.string().required(t('Please select a token.')),
    accountId: yup.string().test(
      'not-enough-amount-for-fee',
      t('FlowTxPlanError.notEnoughFundsForFee', {
        minimalBalance: formattedMinimalBalance,
      }),
      () => maxSendableAmount.isGreaterThanOrEqualTo(txFee),
    ),
  }
}

// ensure consistency of keys / values on TS level
export const useDetailsSchema: (
  ...args: Parameters<typeof _useDetailsSchema>
) => {
  [k in keyof DetailsSchema]: ReturnType<typeof _useDetailsSchema>[k]
} = _useDetailsSchema
