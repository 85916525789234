import {Box, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {AlertWithMargin, importEvmTokenTestIds} from '../utils'

import type {TokenDifferences, TokenProperty} from './common'

type TokenEffectsInfoProps = {
  differences: TokenDifferences
  warning: React.ReactNode
  prevValueLabel: string
  newValueLabel: string
}

export function TokenEffectsInfo({
  differences,
  warning,
  prevValueLabel,
  newValueLabel,
}: TokenEffectsInfoProps) {
  const {t} = useTranslation()
  return (
    <Box mt={1} rtl-data-test-id={importEvmTokenTestIds.differencesInfo}>
      <AlertWithMargin severity="warning">
        <Typography>{warning}</Typography>
        <Box sx={{mt: 1, mb: 1}}>
          {Object.keys(differences).map((_property) => {
            const property = _property as TokenProperty
            const label = {
              name: t('Name'),
              ticker: t('Symbol'),
              decimals: t('Decimals'),
            }[property]

            return (
              <Box key={property}>
                <Typography fontWeight="bold" textAlign="center">
                  {label}
                </Typography>
                <Box>
                  <TokenDifferenceRow
                    label={prevValueLabel}
                    value={differences[property]?.oldValue}
                  />
                  <TokenDifferenceRow
                    label={newValueLabel}
                    value={differences[property]?.newValue}
                  />
                </Box>
              </Box>
            )
          })}
        </Box>
      </AlertWithMargin>
    </Box>
  )
}

type TokenDifferenceRowProps = {
  label: string
  value?: string | number | null
}

function TokenDifferenceRow({label, value}: TokenDifferenceRowProps) {
  return (
    <Box sx={{display: 'flex'}}>
      <Typography fontWeight="bold">{label}</Typography>
      {value != null && <Typography ml={1}>{value}</Typography>}
    </Box>
  )
}
