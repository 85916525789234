import React from 'react'

import {
  useBaseActionRouteOptions,
  useTokenIdFromRoute,
} from 'src/router/portfolio'

import type {EvmBlockchain, EvmTokenId} from '../../../wallet/evm'
import {resolveEns} from '../../../wallet/evm'
import {isValidEvmAddress} from '../../../wallet/evm/sdk/utils'
import {SendModal} from '../common/sendMultipleAssetsModal/SendMultipleAssetsModal'
import type {SendSingleAssetFormInjectedPros} from '../common/sendMultipleAssetsModal/types'

import {initialNonceFormValues} from './common'
import type {GasOptionsProps} from './gas/types'
import {WithGasOptionsData} from './gas/WithGasOptionsData'
import {EvmSendNativeModal} from './native/SendModalContent'
import type {BaseSchema} from './schema'
import {useEvmSchema} from './schema'
import {EvmSendTokenModal} from './token/SendModalContent'

type EvmSendModalProps<TBlockchain extends EvmBlockchain> = {
  blockchain: TBlockchain
  onClose: () => void
}

export const EvmSendModal = <TBlockchain extends EvmBlockchain>({
  blockchain,
  onClose,
}: EvmSendModalProps<TBlockchain>) => {
  const {accountId} = useBaseActionRouteOptions()
  const tokenId = useTokenIdFromRoute() as EvmTokenId
  return (
    <WithGasOptionsData
      {...{
        blockchain,
        // note its NOT important that accountId is actually the one that is used in the
        // real transaction, we just need SOME accountId to get the estimate of l1GasCost
        // so changing account in the send modal has not effect
        // tokenId is important, as changing from FT to NFT might have an impact on the l1GasCost
        // for now tho, the UI does not allow changing token that is going to be sent,
        // so getting it e.g. from queryParams is a safe
        customOpStackFeeModelOptions: {accountId, tokenId},
      }}
    >
      {(gasProps) => <_EvmSendModal {...{blockchain, onClose, gasProps}} />}
    </WithGasOptionsData>
  )
}

const _EvmSendModal = <TBlockchain extends EvmBlockchain>({
  blockchain,
  onClose,
  gasProps,
}: EvmSendModalProps<TBlockchain> & {
  gasProps: GasOptionsProps<TBlockchain>
}) => {
  const schema = useEvmSchema(gasProps.gasSchemaOptions)

  return (
    <SendModal<BaseSchema>
      {...{
        blockchain,
        onClose,
        type: 'singleAsset',
        isAddressValid: isValidEvmAddress,
        getDefaultAccountId: ({defaultAccountId}) => defaultAccountId,
        getFieldsDependentTxFee: (fields) => gasProps.getTxFee(fields),
        nameServiceAddressLabel: 'ENS',
        // amount, assets, toAddress, accountId
        overrideBaseDetailsValidationSchema: (baseDetailsSchema) => ({
          ...baseDetailsSchema,
          ...schema,
        }),
        overrideInitialValues: (initialValues) => ({
          ...initialValues,
          ...initialNonceFormValues,
          ...gasProps.gasOptionsInitialValues,
        }),
      }}
      getIsAssetAmountRequired={(asset) => asset.type !== 'native'}
    >
      {(props: SendSingleAssetFormInjectedPros<BaseSchema>) => {
        const commonProps = {
          blockchain,
          onClose,
          gasProps,
          onResolveEnsHandle: (address: string) =>
            resolveEns(blockchain, address),
        }
        return props.txType === 'token' ? (
          <EvmSendTokenModal {...props} {...commonProps} />
        ) : (
          <EvmSendNativeModal {...props} {...commonProps} />
        )
      }}
    </SendModal>
  )
}
