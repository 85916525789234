import type {Theme} from '@mui/material'
import {Grid, Typography} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import _ from 'lodash'
import React from 'react'

import Paper from '../atoms/Paper'

type MnemonicCardProps = {
  mnemonic: Array<string>
  compact?: boolean
}

export function MnemonicCard({mnemonic, compact}: MnemonicCardProps) {
  const classes = useStyles({compact})
  const wordsPerLine = 3
  const chunkedWords = _.chunk(mnemonic, wordsPerLine)

  return (
    <Paper className={classes.wrapper}>
      {chunkedWords.map((chunk, chunkIndex) => (
        <Grid container key={chunkIndex}>
          {chunk.map((word, chunkWordIndex) => (
            <Grid
              item
              xs={4}
              className={classes.wordWrapper}
              key={chunkWordIndex}
            >
              <Typography
                className={classes.wordIndex}
                variant="body2"
                color="textSecondary"
              >
                {chunkIndex * wordsPerLine + (chunkWordIndex + 1)}.
              </Typography>
              <Typography className={classes.word} variant="body2">
                {word}
              </Typography>
            </Grid>
          ))}
        </Grid>
      ))}
    </Paper>
  )
}

const useStyles = makeStyles<Theme, {compact?: boolean}>((theme) => ({
  wrapper: {
    padding: ({compact}) => theme.spacing(compact ? 1 : 1.5),
  },
  wordWrapper: {
    display: 'flex',
    padding: ({compact}) =>
      compact ? theme.spacing(1, 1.5) : theme.spacing(1.5, 2),
    height: ({compact}) => (compact ? 40 : 50),
  },
  word: {
    marginLeft: ({compact}) => theme.spacing(compact ? 1 : 3),
  },
  wordIndex: {
    width: 24,
  },
}))
