import {Typography, Box} from '@mui/material'
import React from 'react'

import config from '../../config'

const DashboardPage = () => (
  <>
    <Box>
      <Typography variant="h4">NuFi</Typography>
      <pre>{JSON.stringify(config, null, 4)}</pre>
    </Box>
  </>
)

export default DashboardPage
