import {loadCardanoSerializationLib} from '@nufi/wallet-cardano'

import {initBigNumber} from '../bignumber'

import {initSentry} from './sentry'

/**
 * Shared setup for widget, connector and app
 */
export const initCommonLibs = async () => {
  initBigNumber()
  initSentry()

  // Magic for loading/caching wasm-based cardano-serialization-lib
  // to optimize app load time
  await loadCardanoSerializationLib()
}
