import {
  Star as DefaultIcon,
  StarBorder as NonDefaultIcon,
} from '@mui/icons-material'
import {
  List,
  Box,
  IconButton,
  Grid,
  Typography,
  ListItemButton,
} from '@mui/material'
import Alert from '@mui/material/Alert'
import makeStyles from '@mui/styles/makeStyles'
import BigNumber from 'bignumber.js'
import clsx from 'clsx'
import React, {useState} from 'react'
import {Trans, useTranslation} from 'react-i18next'

import {WithConversionRates} from 'src/features/conversionRates/ui'
import {WithWeakTokenMetadata} from 'src/wallet/public/ui'
import {blockchainToWalletKind} from 'src/wallet/walletKind'

import {
  QueryGuard,
  MutationGuard,
  FormattedAsset,
  AccountCardCol,
  Modal,
  AssetIconModalHeader,
  ModalLayout,
  ModalFooter,
  FooterLayout,
  LoadAccountsError,
  NaError,
  WithTooltip,
  FormattedAssetAsCurrency,
} from '../../../components'
import {useAssetDetailRouteOptions} from '../../../router/portfolio'
import type {
  AccountId,
  AccountIdRequestedBalance,
  AccountInfo,
  AccountName,
  Blockchain,
  TokenId,
} from '../../../types'
import {
  useScrollableStyles,
  useEllipsisStyles,
} from '../../../utils/layoutUtils'
import {useGetBlockchainName} from '../../../utils/translations'
import {
  useGetBalancesPerAccount,
  useGetAccounts,
  useSetDefaultAccount,
  useIsAssetAvailableForAccount,
} from '../../../wallet'
import {ASSET_UNAVAILABLE_REASON} from '../../../wallet/public/queries/types'
import {AddAccount} from '../sharedActions/addAccount/AddAccount'

import {AssetDetailAccountsLoading} from './Loadings'

type AssetDetailAccountsListProps = {
  blockchain: Blockchain
  tokenId: TokenId | null
  isEditable: boolean
  withTopMargin: boolean
  onAccountChange: (accountId: AccountId) => void
}

function getAccountBalance(
  accountBalances: AccountIdRequestedBalance[],
  accountId: AccountId,
) {
  const foundAccountBalances = accountBalances.find(
    (ab) => ab.accountId === accountId,
  )

  return foundAccountBalances ? foundAccountBalances.balance : new BigNumber(0)
}

export function AssetDetailAccountsList({
  blockchain,
  tokenId,
  isEditable,
  withTopMargin,
  onAccountChange,
}: AssetDetailAccountsListProps) {
  const {t} = useTranslation()
  const scrollableClasses = useScrollableStyles()
  const accountsQuery = useGetAccounts(blockchain)
  const balancesPerAccountQuery = useGetBalancesPerAccount(blockchain, tokenId)
  const setDefaultAccount = useSetDefaultAccount(blockchain)

  const [defaultAccountCandidate, setDefaultAccountCandidate] = useState<{
    name: AccountName
    id: AccountId
  } | null>(null)

  const onSetDefaultAccountCandidate = (
    e: React.SyntheticEvent,
    accountMeta: {id: AccountId; name: AccountName},
  ) => {
    e.stopPropagation()
    setDefaultAccountCandidate(accountMeta)
  }

  const onDefaultAccountModalClose = () => setDefaultAccountCandidate(null)

  return (
    <Box className={scrollableClasses.scrollableParent}>
      <QueryGuard
        loadingVariant="centered"
        {...balancesPerAccountQuery}
        ErrorElement={
          <NaError error={t('Could not load total balance for account')} />
        }
      >
        {(accountBalances) => (
          <QueryGuard
            {...accountsQuery}
            ErrorElement={<LoadAccountsError />}
            LoadingElement={<AssetDetailAccountsLoading />}
          >
            {(accounts) => {
              const accountsWithBalance = (accounts as AccountInfo[]).filter(
                (a) => accountBalances.find((ab) => ab.accountId === a.id),
              )
              return (
                <Box mt={withTopMargin ? 2.5 : 0}>
                  <List
                    component="nav"
                    className={scrollableClasses.scrollableList}
                  >
                    {accountsWithBalance.map((account) => (
                      <AssetDetailAccountListItem
                        key={account.id}
                        blockchain={blockchain}
                        tokenId={tokenId}
                        account={account}
                        accountBalances={accountBalances}
                        onSetDefaultAccountCandidate={
                          isEditable ? onSetDefaultAccountCandidate : undefined
                        }
                        onAccountChange={onAccountChange}
                      />
                    ))}
                  </List>
                </Box>
              )
            }}
          </QueryGuard>
        )}
      </QueryGuard>
      {isEditable && <AddAccount preselectedBlockchain={blockchain} />}
      {defaultAccountCandidate && (
        <ConfirmSetDefaultAccount
          {...{blockchain, setDefaultAccount}}
          onClose={onDefaultAccountModalClose}
          accountName={defaultAccountCandidate.name}
          accountId={defaultAccountCandidate.id}
        />
      )}
    </Box>
  )
}

type AssetDetailAccountListItemProps = {
  blockchain: Blockchain
  tokenId: TokenId | null
  account: AccountInfo
  accountBalances: AccountIdRequestedBalance[]
  onSetDefaultAccountCandidate?: (
    e: React.SyntheticEvent,
    accountMeta: {
      id: AccountId
      name: AccountName
    },
  ) => void
  onAccountChange: (accountId: AccountId) => void
}

const AssetDetailAccountListItem = ({
  tokenId,
  blockchain,
  account,
  accountBalances,
  onSetDefaultAccountCandidate,
  onAccountChange,
}: AssetDetailAccountListItemProps) => {
  const {t} = useTranslation()
  const tokenAvailableQuery = useIsAssetAvailableForAccount(
    tokenId,
    account,
    blockchain,
  )
  const {accountId: selectedAccountId} = useAssetDetailRouteOptions()
  const isSelected = selectedAccountId === account.id
  const classes = useAssetDetailAccountsListStyles()
  const {ellipsis} = useEllipsisStyles()

  return (
    <QueryGuard
      loadingVariant="centered"
      {...tokenAvailableQuery}
      ErrorElement={<NaError error={t('Could not load tokens for account')} />}
    >
      {(isTokenAvailableForAccountInfo) => (
        <WithTooltip
          title={
            isTokenAvailableForAccountInfo.available === true ? (
              ''
            ) : (
              <Typography>
                {isTokenAvailableForAccountInfo.unavailabilityReason ===
                ASSET_UNAVAILABLE_REASON.IMPORT_REQUIRED
                  ? t('Token needs to be manually imported for this account.')
                  : ''}
              </Typography>
            )
          }
        >
          <ListItemButton
            key={account.address}
            selected={account.id === selectedAccountId}
            onClick={() => onAccountChange(account.id)}
            className={clsx(
              classes.listItemWrapper,
              isSelected && classes.selectedListItemWrapper,
            )}
            disabled={!isTokenAvailableForAccountInfo.available}
          >
            <Box
              className={classes.leftSection}
              title={account.name}
              sx={{maxWidth: tokenId && '50%'}}
            >
              <AccountCardCol
                walletKind={blockchainToWalletKind(blockchain)}
                blockchain={blockchain}
                preferBlockchainIcon
                name={account.name}
                address={account.address}
                descriptionFontSize="caption"
                cryptoProviderType={account.cryptoProviderType}
                ellipsizeTitle
                ellipsisOptions={{width: 'calc(100% - 3.3rem)'}} // to exclude HW icon
              />
            </Box>
            <Box
              sx={{maxWidth: tokenId && '50%'}}
              className={clsx(
                classes.rightSection,
                tokenId && classes.tokenEllipsizeContainer,
              )}
            >
              <Grid
                container
                direction="column"
                alignItems="flex-end"
                justifyContent="center"
                className={clsx(tokenId && classes.tokenEllipsizeContainer)}
              >
                <Typography
                  variant="body2"
                  align="right"
                  className={clsx(
                    tokenId && ellipsis,
                    tokenId && classes.fullWidth,
                  )}
                >
                  <WithWeakTokenMetadata
                    blockchain={blockchain}
                    tokenId={tokenId}
                  >
                    {(tokenMetadata) => (
                      <FormattedAsset
                        amount={getAccountBalance(accountBalances, account.id)}
                        blockchain={blockchain}
                        tokenMetadata={tokenMetadata}
                        isSensitiveInformation
                      />
                    )}
                  </WithWeakTokenMetadata>
                </Typography>
                {tokenId == null && (
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="div"
                  >
                    <WithConversionRates>
                      {(conversionRates) => (
                        <FormattedAssetAsCurrency
                          blockchain={blockchain}
                          balance={getAccountBalance(
                            accountBalances,
                            account.id,
                          )}
                          includeCurrencySymbol
                          isSensitiveInformation
                          conversionRates={conversionRates}
                        />
                      )}
                    </WithConversionRates>
                  </Typography>
                )}
              </Grid>
              {onSetDefaultAccountCandidate && (
                <Box className={classes.favoriteAccountWrapper}>
                  {account.isDefault ? (
                    <Box className={classes.favoriteAccountIconWrapper}>
                      <DefaultIcon color="primary" />
                    </Box>
                  ) : (
                    <IconButton
                      onClick={(e) =>
                        onSetDefaultAccountCandidate(e, {
                          id: account.id,
                          name: account.name,
                        })
                      }
                      size="large"
                    >
                      <NonDefaultIcon />
                    </IconButton>
                  )}
                </Box>
              )}
              <Box
                className={clsx(
                  classes.tick,
                  isSelected && classes.selectedTick,
                )}
              />
            </Box>
          </ListItemButton>
        </WithTooltip>
      )}
    </QueryGuard>
  )
}

type ConfirmSetDefaultAccountProps = {
  blockchain: Blockchain
  accountName: AccountName
  accountId: AccountId
  onClose: () => unknown
  setDefaultAccount: ReturnType<typeof useSetDefaultAccount>
}

function ConfirmSetDefaultAccount({
  blockchain,
  accountName,
  accountId,
  onClose,
  setDefaultAccount,
}: ConfirmSetDefaultAccountProps) {
  const {t} = useTranslation()
  const getBlockchainName = useGetBlockchainName()

  const onSetDefault = async () => {
    await setDefaultAccount.mutateAsyncSilent({accountId})
    onClose()
  }

  return (
    <Modal onClose={onClose} variant="centered">
      <ModalLayout
        header={
          <AssetIconModalHeader onClose={onClose} blockchain={blockchain}>
            <Typography>{t('Set default account')}</Typography>
            <Typography variant="body2" color="textSecondary">
              {getBlockchainName(blockchain)}
            </Typography>
          </AssetIconModalHeader>
        }
        body={
          <Box p={2}>
            <Alert severity="info">
              <Typography>
                <Trans
                  i18nKey="change_default_account_info"
                  t={t}
                  values={{
                    blockchain: getBlockchainName(blockchain),
                    accountName,
                  }}
                  components={{
                    bold: (
                      <Box component="span" fontWeight="fontWeightMedium" />
                    ),
                  }}
                />
              </Typography>
            </Alert>
            {/* Looks better with extra space */}
            <Box height={40} />
            <MutationGuard {...setDefaultAccount} />
          </Box>
        }
        footer={
          <ModalFooter hasDivider>
            <FooterLayout
              leftBtnConf={{
                onClick: onClose,
                children: t('Close'),
              }}
              rightBtnConf={{
                onClick: onSetDefault,
                children: t('Change default account'),
                disabled: setDefaultAccount.isPending,
              }}
            />
          </ModalFooter>
        }
      />
    </Modal>
  )
}

const useAssetDetailAccountsListStyles = makeStyles((theme) => ({
  listItemWrapper: {
    padding: `0 ${theme.spacing(2)}`,
    marginBottom: theme.spacing(1),
    borderRadius: 4,
    border: `1px solid ${theme.palette.divider}`,
    minHeight: 80,
    background: theme.palette.background.paper,
  },
  selectedListItemWrapper: {
    borderRight: 'none',
  },
  favoriteAccountWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
  },
  tick: {
    position: 'absolute',
    inset: `0 0 0 auto`,
    width: 5,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
  },
  selectedTick: {
    background: theme.palette.primary.main,
  },
  leftSection: {
    flex: 1,
    overflow: 'hidden',
  },
  rightSection: {
    display: 'flex',
    height: '100%',
  },
  favoriteAccountIconWrapper: {
    paddingLeft: theme.spacing(2),
    display: 'flex',
  },
  tokenEllipsizeContainer: {
    flex: 1,
    overflow: 'hidden',
  },
  fullWidth: {
    width: '100%',
  },
}))
