import {Alert, Box, Typography} from '@mui/material'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {QueryGuard, Button} from '../../../components'
import type {AccountId} from '../../../wallet'
import {useAccountsWithNftSnapshot, getDefaultAccountId} from '../../../wallet'
import ImportToken from '../../portfolio/account/actions/importToken/ImportToken'

type ImportTokenWrapperProps = {
  accountId?: AccountId
}

export const ImportTokenBanner = ({accountId}: ImportTokenWrapperProps) => {
  const [open, setOpen] = useState(false)
  const accountsQuery = useAccountsWithNftSnapshot({blockchain: 'flow'})
  const {t} = useTranslation()

  return !accountsQuery.error ? (
    <>
      <Alert severity="info" sx={{py: 0, alignItems: 'center'}}>
        <Box display="flex" alignItems="center">
          <Typography>{t("Can't see your NFTs?")}</Typography>
          <Button
            sx={{ml: 2}}
            variant="outlined"
            textTransform="none"
            color="info"
            onClick={() => setOpen(true)}
          >
            {t('Import NFT')}
          </Button>
        </Box>
      </Alert>
      {open &&
        (accountId ? (
          <ImportToken
            blockchain="flow"
            onClose={() => setOpen(false)}
            id={accountId}
          />
        ) : (
          <QueryGuard {...accountsQuery} ErrorElement={<></>}>
            {(accounts) => (
              <ImportToken
                blockchain="flow"
                onClose={() => setOpen(false)}
                id={getDefaultAccountId(accounts) || ('' as AccountId)}
              />
            )}
          </QueryGuard>
        ))}
    </>
  ) : null
}
