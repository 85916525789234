import type {SigningKind} from '../../../store/dappConnector'
import type {HwVendor} from '../../../types'
import {safeAssertUnreachable} from '../../../utils/assertion'
import {isEvmBlockchain} from '../../../wallet/evm'
import type {ConnectorBlockchain} from '../../types'

const isSigningKindSupported = (
  kind: SigningKind,
  supportedKinds: SigningKind[],
) => {
  return supportedKinds.includes(kind)
}

export const isHwSignSupported = (
  kind: SigningKind,
  blockchain: ConnectorBlockchain,
  vendor: HwVendor,
) => {
  switch (blockchain) {
    case 'cardano':
      return vendor === 'ledger'
        ? isSigningKindSupported(kind, [
            'sign-tx',
            'sign-vote',
            'get-hw-wallet-public-keys',
          ])
        : isSigningKindSupported(kind, ['sign-tx'])
    case 'solana':
      return isSigningKindSupported(kind, ['sign-tx'])
    case 'flow':
      return false
    default: {
      if (isEvmBlockchain(blockchain)) {
        return isSigningKindSupported(kind, ['sign-tx', 'sign-message'])
      }
      return safeAssertUnreachable(blockchain)
    }
  }
}
