import {Typography, Box, styled} from '@mui/material'
import Alert from '@mui/material/Alert'
import React from 'react'
import {useTranslation} from 'react-i18next'

import gridPlusErrorSrc from '../../../assets/images/gridPlusError.svg'
import gridPlusSignSrc from '../../../assets/images/gridPlusSign.svg'
import {HelpSectionText} from '../common'

type HelpSectionProps = {
  severity: 'info' | 'error'
}

export function HelpSection({severity}: HelpSectionProps) {
  const {t} = useTranslation()

  return (
    <Alert severity={severity}>
      <Typography>
        <Box fontWeight="fontWeightBold">{t('Make sure that')}</Box>
      </Typography>
      <HelpSectionText>
        1. {t('Your GridPlus Lattice1 is connected')}
      </HelpSectionText>
      <HelpSectionText>
        2. {t('GridPlus Lattice1 firmware is up to date')}
      </HelpSectionText>
      {severity === 'error' && (
        <HelpSectionText>
          3. {t('gridplus_error_correct_wallet')}
        </HelpSectionText>
      )}
    </Alert>
  )
}

const GridPlusImg = styled('img')(({theme}) => ({
  height: 130,
  [theme.breakpoints.down('md')]: {
    height: 80,
  },
}))

export const gridPlusImages = {
  sign: <GridPlusImg src={gridPlusSignSrc} />,
  verify: <GridPlusImg src={gridPlusSignSrc} />,
  export: <GridPlusImg src={gridPlusSignSrc} />,
  setup: <GridPlusImg src={gridPlusSignSrc} />,
  error: <GridPlusImg src={gridPlusErrorSrc} />,
}
