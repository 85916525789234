import {arraySum} from '@nufi/wallet-common'
import BigNumber from 'bignumber.js'
import React from 'react'

import {QueryGuard, LoadStakingDataError} from '../../../components'
import {
  getAccountStakedBalance,
  useGetStakeAccounts,
} from '../../../wallet/cardano'
import {AssetItemLoading} from '../common/Loadings'
import {MyStakingCard} from '../common/myStakingUtils'

export function CardanoMyStakingCard() {
  const stakeAccountsQuery = useGetStakeAccounts()
  return (
    <QueryGuard
      {...stakeAccountsQuery}
      LoadingElement={<AssetItemLoading blockchain="cardano" />}
      ErrorElement={<LoadStakingDataError blockchain="cardano" />}
    >
      {(accounts) => {
        const rewardsBalance = arraySum(
          accounts.map((account) => account.rewards),
        )
        const availableBalance = arraySum(
          accounts.map((account) => account.balance),
        )
        const stakedBalance = arraySum(
          accounts.map((a) => getAccountStakedBalance(a)),
        )
        const stakedRatio = availableBalance.isGreaterThan(0)
          ? stakedBalance.dividedBy(availableBalance.plus(rewardsBalance))
          : new BigNumber(0)
        const hasAlreadyStaked =
          accounts.filter((a) => a.isStakingKeyRegistered).length > 0
        return (
          <MyStakingCard
            blockchain="cardano"
            {...{
              rewardsBalance,
              stakedBalance,
              availableBalance,
              hasAlreadyStaked,
              stakedRatio,
            }}
          />
        )
      }}
    </QueryGuard>
  )
}
