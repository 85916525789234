import {createPolygonNetworkConfig} from '@nufi/wallet-evm'

import config from 'src/config'

import {createBaseEvmManagers} from '../../createEvmManagers'

export const polygon = createBaseEvmManagers({
  blockchain: 'polygon',
  network: config.polygonNetwork,
  apiUrl: config.polygonAlchemyApiUrl,
  createNetworkConfig: createPolygonNetworkConfig,
})
