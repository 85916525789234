import {SolanaDerivationPathType} from '@nufi/wallet-solana'
import {useTranslation} from 'react-i18next'

import type {DerivationPathTypeConfig} from '../sharedActions/addAccount/AddAccountUtils'

export const useDerivationPathTypeConfig =
  (): DerivationPathTypeConfig<SolanaDerivationPathType> => {
    const {t} = useTranslation()
    return {
      [SolanaDerivationPathType.Bip44Root]: {
        label: t('SolanaDerivationPathType.Bip44Root.label'),
        description: t('SolanaDerivationPathType.Bip44Root.description'),
      },
      [SolanaDerivationPathType.Bip44]: {
        label: t('SolanaDerivationPathType.Bip44.label'),
        description: t('SolanaDerivationPathType.Bip44.description'),
      },
      [SolanaDerivationPathType.Bip44Change]: {
        label: t('SolanaDerivationPathType.Bip44Change.label'),
        description: t('SolanaDerivationPathType.Bip44Change.description'),
      },
    }
  }
