import _GlobalStyles from '@mui/material/GlobalStyles'
import React from 'react'

export const GlobalStyles = () => (
  <_GlobalStyles
    styles={(theme) => ({
      // Note: scrollbar styles are not applied to firefox but in general scrollbar looks there fine
      /* width */
      '*::-webkit-scrollbar': {
        width: 14,
        height: 14,
      },
      /* Handle */
      '*::-webkit-scrollbar-thumb': {
        background: theme.palette.grey['600'],
        borderRadius: 14,

        // https://stackoverflow.com/questions/21684101/css-vertical-scrollbar-padding-left-right-in-ul-possible
        backgroundClip: 'padding-box',
        border: '4px solid rgba(0, 0, 0, 0)',
      },
      /* Handle on hover */
      '*::-webkit-scrollbar-thumb:hover': {
        background: theme.palette.grey['700'],

        // https://stackoverflow.com/questions/21684101/css-vertical-scrollbar-padding-left-right-in-ul-possible
        // Getting weird behavior if those properties are not duplicated
        backgroundClip: 'padding-box',
        border: '4px solid rgba(0, 0, 0, 0)',
      },
    })}
  />
)
