import {
  constructEthereumConnection,
  createEthereumNetworkConfig,
  EthereumBlockchainApiImpl,
} from '@nufi/wallet-evm'

import config from 'src/config'

import {createEvmManagers} from '../../createEvmManagers'

export const ethereum = createEvmManagers({
  blockchain: 'ethereum',
  network: config.ethereumNetwork,
  apiUrl: config.ethereumAlchemyApiUrl,
  createNetworkConfig: createEthereumNetworkConfig,
  createBlockchainApi: (networkConfig) => {
    const connection = constructEthereumConnection(
      networkConfig.chainId,
      networkConfig.rpcNodeUrl,
    )
    return new EthereumBlockchainApiImpl(
      connection,
      networkConfig.chainId,
      networkConfig.balanceCheckerContractAddress,
    )
  },
})
