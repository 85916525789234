import {HelpOutline as HelpIcon} from '@mui/icons-material'
import CheckIcon from '@mui/icons-material/Check'
import {Typography, Divider, Box} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import ContentLoader from 'react-content-loader'
import {useTranslation} from 'react-i18next'

import {LabeledIcon, WithTooltip} from '../../components'
import {ContentLoaderBackground} from '../../utils/layoutUtils'

type SettingLabelProps = {children: string; info?: string}

export function SettingLabel({children, info}: SettingLabelProps) {
  const classes = useStyles()
  return (
    <Box display="flex" alignItems="center">
      <Box mb={2}>
        <Typography variant="subtitle1" color="textSecondary">
          {children}
        </Typography>
      </Box>
      {info && (
        <>
          <Box mr={0.5} />
          <WithTooltip title={<>{info}</>}>
            <HelpIcon fontSize="small" className={classes.helpIcon} />
          </WithTooltip>
        </>
      )}
    </Box>
  )
}

type SectionInfoProps = {label: string; description?: string}

export function SectionInfo({label, description}: SectionInfoProps) {
  return (
    <>
      <Typography variant="h6">{label}</Typography>
      {description && (
        <Typography color="textSecondary">{description}</Typography>
      )}
    </>
  )
}

type SettingsLayoutProps = {
  left: React.ReactNode
  right: React.ReactNode
  hideDivider?: boolean
}

export function SettingsLayout({
  left,
  right,
  hideDivider,
}: SettingsLayoutProps) {
  const classes = useStyles()
  return (
    <>
      {!hideDivider && <__Divider__ />}
      <Box pt={2} pb={2} className={classes.layoutWrapper}>
        <Box className={classes.left}>{left}</Box>
        <Box className={classes.right}>{right}</Box>
      </Box>
    </>
  )
}

function __Divider__() {
  return (
    <Box pt={2} pb={2}>
      <Divider />
    </Box>
  )
}

const SECTION_WIDTH = 470

export const useCommonStyles = makeStyles(() => ({
  sectionWrapper: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: SECTION_WIDTH,
  },
}))

const useStyles = makeStyles((theme) => ({
  layoutWrapper: {
    display: 'flex',
  },
  left: {
    maxWidth: 600,
    [theme.breakpoints.down('lg')]: {
      maxWidth: 500,
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: 400,
    },
    flex: 1,
  },
  right: {
    flex: 1,
    paddingRight: theme.spacing(2),
  },
  helpIcon: {
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(2),
  },
}))

export function ConfirmationStatusWrapper({
  isVisible,
  children,
}: {
  isVisible: boolean
  children: JSX.Element
}) {
  const {t} = useTranslation()
  const classes = useConfirmationStatusWrapperStyles()

  return (
    <Box position="relative">
      {children}
      {isVisible && (
        <LabeledIcon
          className={classes.saved}
          color="success.light"
          Label={<>{t('Saved')}</>}
          Icon={<CheckIcon fontSize="small" />}
          iconPosition="start"
          spacing={0.5}
          data-test-id="confirmation-status-saved"
        />
      )}
    </Box>
  )
}

const useConfirmationStatusWrapperStyles = makeStyles(() => ({
  saved: {
    position: 'absolute',
    top: '50%',
    right: '-80px',
    transform: 'translate(0%, -50%)',
  },
}))

export const ExportLoading = () => (
  <Box mt={2}>
    <ContentLoader
      width="100%"
      height="40"
      backgroundColor={ContentLoaderBackground()}
    >
      <rect x="0" y="0" rx="5" width="100%" height="40" />
    </ContentLoader>
  </Box>
)

export const WalletSettingsLoading = () => (
  <Box mt={2}>
    <ContentLoader
      width="470"
      height="145"
      backgroundColor={ContentLoaderBackground()}
    >
      <rect x="0" y="0" rx="5" width="20%" height="10" />
      <rect x="0" y="20" rx="5" width="100%" height="40" />
      <rect x="0" y="70" rx="5" width="100%" height="40" />
      <rect x="0" y="120" rx="5" width="100%" height="25" />
    </ContentLoader>
  </Box>
)
