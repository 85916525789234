// translations imported first to ensure they are loaded before any React component
import './translations/i18n'
import {CssBaseline} from '@mui/material'
import {ThemeProvider, StyledEngineProvider} from '@mui/material/styles'
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {QueryClientProvider} from '@tanstack/react-query'
import * as dateFnslocale from 'date-fns/locale'
import React from 'react'

import {ReactQueryDevtoolsPanel, WebExtensionGuard} from './components'
import config from './config'
import {ErrorBoundary} from './ErrorBoundary'
import queryClient from './queryClient'
import {GlobalStyles} from './theme/globalStyles'
import theme from './theme/theme'

type dateFnsLocaleProps = keyof typeof dateFnslocale
const isDateFnsLocale = (x: string): x is dateFnsLocaleProps =>
  Object.keys(dateFnslocale).includes(x)

// map string returned from browser to locale from date-fns, f.e. en-US => enUS
const mapLocaleToDateFns = (locale?: string): dateFnsLocaleProps => {
  const defaultLocale = 'enUS'
  if (!locale) return defaultLocale
  if (isDateFnsLocale(locale)) return locale
  // split on "-" https://developer.mozilla.org/en-US/docs/Web/API/Navigator/language#example
  // dateFnsLocaleProps uses locales imports in camelCase e.g. en-US => enUS
  const splitLocale = locale.split(/[-]/)
  // Safari < 10.2 returns in lowercase: 'en-us'
  const dateFnsLocale = [splitLocale[0], splitLocale[1]?.toUpperCase()].join('')
  return isDateFnsLocale(dateFnsLocale)
    ? dateFnsLocale
    : // if locale is f.e. skSK rather then default return sk
      isDateFnsLocale(splitLocale[0]!)
      ? splitLocale[0]
      : defaultLocale
}

function EmptyWrapper({children}: {children: JSX.Element}) {
  return children
}

export function AppWrapper({children}: {children: React.ReactNode}) {
  const _ErrorBoundary = config.isStorybook ? EmptyWrapper : ErrorBoundary

  return (
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>
        <CssBaseline />
        <_ErrorBoundary>
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={
              dateFnslocale[mapLocaleToDateFns(window.navigator.language)]
            }
          >
            <QueryClientProvider client={queryClient}>
              <WebExtensionGuard
                load={() => import('./webextension/CrossAppMessageListener')}
              />
              <GlobalStyles />
              {children}
              <ReactQueryDevtoolsPanel />
            </QueryClientProvider>
          </LocalizationProvider>
        </_ErrorBoundary>
      </StyledEngineProvider>
    </ThemeProvider>
  )
}
