import {Box} from '@mui/material'
import React from 'react'

import {useSettings} from 'src/features/profile/application'

import {Icon} from './atoms/Icon'

const asterisk = <Icon type="asteriskIcon" sx={{fontSize: '75%'}} />

export const AmountVisibilityWrapper = ({
  children,
  isSensitiveInformation = true,
}: {
  children: React.ReactNode
  isSensitiveInformation?: boolean
}) => {
  const {areAmountsVisible} = useSettings()
  return !areAmountsVisible && isSensitiveInformation ? (
    <Box display="inline-block">
      {Array(7)
        .fill(asterisk)
        .map((AsteriskElement: JSX.Element, i) => (
          <Box
            sx={{
              verticalAlign: 'middle',
              display: 'inline-block',
              lineHeight: 1,
            }}
            key={i}
          >
            {AsteriskElement}
          </Box>
        ))}
    </Box>
  ) : (
    <>{children}</>
  )
}
