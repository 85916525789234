import {InfoOutlined as InfoIcon} from '@mui/icons-material'
import {Grid, Box, Typography, styled} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {useUiStore} from 'src/store/ui'
import {useGetBlockchainName} from 'src/utils/translations'
import {blockchainToWalletKind} from 'src/wallet/walletKind'

import {
  Modal,
  ModalLayout,
  ModalHeader,
  ModalFooter,
  Button,
} from '../../../components/visual'

export function RecentlyEnabledBlockchainModal() {
  const {t} = useTranslation()
  const {recentlyEnabledBlockchain, setRecentlyEnabledBlockchain} = useUiStore()
  const getBlockchainName = useGetBlockchainName()

  const onClose = () => setRecentlyEnabledBlockchain(null)

  if (!recentlyEnabledBlockchain) {
    return null
  }

  const walletKind = blockchainToWalletKind(recentlyEnabledBlockchain)
  return (
    <Modal variant="centered">
      <ModalLayout
        header={
          <ModalHeader onClose={onClose} hasDivider>
            <Grid container spacing={2} alignItems="flex-end">
              <InfoIconStyledGrid item>
                <InfoIcon color="inherit" />
              </InfoIconStyledGrid>
              <Grid item>
                <Typography variant="h6">
                  {t('RecentlyEnabledBlockchainModal.title', {
                    blockchain: getBlockchainName(recentlyEnabledBlockchain),
                  })}
                </Typography>
              </Grid>
            </Grid>
          </ModalHeader>
        }
        body={
          <Box maxWidth={560} p={3}>
            <Typography>
              {walletKind === 'evm'
                ? t('RecentlyEnabledBlockchainModal.description.evm', {
                    blockchain: getBlockchainName(recentlyEnabledBlockchain),
                  })
                : t('RecentlyEnabledBlockchainModal.description', {
                    blockchain: getBlockchainName(recentlyEnabledBlockchain),
                  })}
            </Typography>
          </Box>
        }
        footer={
          <ModalFooter hasDivider>
            <Button
              textTransform="none"
              fullWidth
              variant="contained"
              color="primary"
              onClick={onClose}
            >
              {t('Continue')}
            </Button>
          </ModalFooter>
        }
      />
    </Modal>
  )
}
const InfoIconStyledGrid = styled(Grid)(({theme}) => ({
  color: theme.palette.info.main,
}))
