import type {FlowTokenId, FlowTokenMetadata} from '@nufi/wallet-flow'

import type {FlowTokenAccount} from '../../../wallet/flow'
import {useGetTokenAccounts} from '../../../wallet/flow'
import {useLoadTokens} from '../loadTokens'
import type {
  UseAssetsConversionParams,
  UseBlockchainAssetsReturnType,
} from '../types'

export function getFlowTokenSortingLabel(
  tokenMetadata: FlowTokenMetadata,
): string {
  return `${tokenMetadata.name} (${tokenMetadata.contractMetadata.contractAddress})`
}

const getOwningAccounts = (
  tokenAccounts: FlowTokenAccount[],
  tokenId: FlowTokenId,
) =>
  // we want to filter out accounts that don't have the token
  // so their wont be set as default
  tokenAccounts.filter((ta) =>
    ta.tokenBalances.find((t) => t.token.id === tokenId),
  )

export function useFlowTokens({
  conversionRates,
  currency,
  enabled,
}: UseAssetsConversionParams): UseBlockchainAssetsReturnType {
  const tokenAccountsQuery = useGetTokenAccounts(enabled)

  return useLoadTokens({
    blockchain: 'flow',
    conversionRates,
    currency,
    enabled,
    getNumAccountsOwningToken(
      {tokenId}: {tokenId: FlowTokenId},
      {tokenAccounts},
    ) {
      const owningAccounts = getOwningAccounts(tokenAccounts, tokenId)
      return owningAccounts.length
    },
    getTokenSortingLabel: getFlowTokenSortingLabel,
    getDefaultAccountId(
      defaultAccountId,
      {tokenId}: {tokenId: FlowTokenId},
      {tokenAccounts},
    ) {
      const owningAccounts = getOwningAccounts(tokenAccounts, tokenId)

      return (
        owningAccounts.find((a) => a.accountId === defaultAccountId)
          ?.accountId || owningAccounts[0]?.accountId
      )
    },
    extraQueries: {tokenAccounts: tokenAccountsQuery},
  })
}
