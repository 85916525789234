import {Error} from '@mui/icons-material/'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'

import type {AppError} from '../../types'
import type {AlertProps} from '../visual/atoms/Alert'
import {Alert} from '../visual/atoms/Alert'

import {SafeCenterAligner} from './atoms/SafeCenterAligner'
import {ModalLayout, ModalHeader, ModalFooter} from './Modal'
import {LabeledIconWithTooltip} from './molecules/LabeledIcon'
import {FooterLayout} from './templates/FooterLayout'

function isErrorWithMessage(error: unknown): error is {message: string} {
  return typeof error === 'object' && error != null && 'message' in error
}

const useLogError = (error: AppError) => {
  useEffect(() => {
    // eslint-disable-next-line no-console
    console.error(error)
  }, [error])
}

function useGetAndLogErrorMessage(error: AppError): string {
  const {t} = useTranslation()
  const message = (() => {
    const fallbackMessage = t('Could not load data')

    if (isErrorWithMessage(error)) {
      return error.message || fallbackMessage
    }

    if (typeof error === 'string') return error
    return fallbackMessage
  })()

  useLogError(error)
  return message
}

export function InlineError({
  error,
  onClose,
}: {
  error: AppError
  onClose?: AlertProps['onClose']
}) {
  const message = useGetAndLogErrorMessage(error)
  const classes = useErrorStyles()

  return (
    <Alert
      severity="error"
      text={message}
      wrapperClassName={clsx(classes.alert, classes.alertSpacing)}
      onClose={onClose}
    />
  )
}

export function NaError({
  error,
  severity = 'error',
  hideLabel = false,
}: {
  error: AppError
  severity?: 'error' | 'neutral' | 'warning'
  hideLabel?: boolean
}) {
  const message = useGetAndLogErrorMessage(error)
  // color is being applied to the MUI Box element
  const color = {
    error: 'error.main',
    neutral: 'text.secondary',
    warning: 'warning.main',
  }[severity]

  const {t} = useTranslation()
  return (
    <LabeledIconWithTooltip
      title={<>{message}</>}
      alignItems="center"
      color={color}
      Icon={<Error fontSize="inherit" />}
      Label={!hideLabel ? t('N/A') : ''}
      iconPosition="start"
      spacing={0.5}
    />
  )
}

export function ModalError({
  error,
  onClose,
  onBack,
  Header,
  ErrorElement,
}: {
  error: AppError
  onClose?: () => void
  onBack?: () => void
  Header?: React.ReactNode
  ErrorElement?: React.ReactNode
}) {
  const history = useHistory()
  const {t} = useTranslation()
  const _onClose = onClose || (() => history.goBack())
  const _onBack = onBack || (() => history.goBack())

  return (
    <ModalLayout
      header={Header || <ModalHeader onClose={_onClose} />}
      body={ErrorElement || <CenteredError error={error} />}
      footer={
        <ModalFooter hasDivider>
          <FooterLayout
            leftBtnConf={{
              onClick: _onBack,
              children: t('Back'),
            }}
            rightBtnConf={{
              variant: 'contained',
              disabled: true,
              children: t('Continue'),
            }}
          />
        </ModalFooter>
      }
    />
  )
}

export function CenteredError({error}: {error: AppError}) {
  const classes = useErrorStyles()

  return (
    <SafeCenterAligner className={classes.fullHeight}>
      <InlineError error={error} />
    </SafeCenterAligner>
  )
}

const useErrorStyles = makeStyles((theme) => ({
  fullHeight: {
    height: '100%',
    minHeight: 'inherit',
  },
  alert: {
    wordBreak: 'break-all',
  },
  alertSpacing: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))
