/* eslint-disable @typescript-eslint/explicit-function-return-type */

import type {AccountId, StakeAccountId} from '@nufi/wallet-common'
import type {
  SolanaStakeHistoryEntry,
  SolanaStakeHistoryRewardEntry,
  SolanaStakeHistoryTxEntry,
} from '@nufi/wallet-solana'
import {
  STAKE_HISTORY_PAGE_SIZE,
  createFetchRewardHistoryPage,
  createGetStakingHistoryForAccount,
} from '@nufi/wallet-solana'
import {useInfiniteQuery} from '@tanstack/react-query'
import {useMemo} from 'react'

import {mergeQueryResultsState} from 'src/utils/query-utils'

import {assert} from '../../../../utils/assertion'
import {ensureAccountById} from '../../../utils/common'
import {solana} from '../../solanaManagers'

import {useGetStakeAccountsInfo} from './staking'
import {QUERY_KEY_PREFIX as P} from './utils'

export const stakeHistoryQueryKeys = {
  rewardHistory: {
    index: [P, 'paginatedRewardHistory'],
    account: (accountId: AccountId) => ({
      stakeAccount: (stakeAccountId: StakeAccountId | null) =>
        [
          ...stakeHistoryQueryKeys.rewardHistory.index,
          accountId,
          stakeAccountId,
        ] as const,
    }),
  },
  stakeTxHistory: {
    index: [P, 'paginatedStakeTxHistory'],
    account: (accountId: AccountId) => ({
      stakeAccount: (stakeAccountId: StakeAccountId | null) =>
        [
          ...stakeHistoryQueryKeys.stakeTxHistory.index,
          accountId,
          stakeAccountId,
        ] as const,
    }),
  },
  wholeStakeHistory: {
    index: [P, 'paginatedWholeStakeHistory'],
    account: (accountId: AccountId) => ({
      stakeAccount: (stakeAccountId: StakeAccountId | null) =>
        [
          ...stakeHistoryQueryKeys.wholeStakeHistory.index,
          accountId,
          stakeAccountId,
        ] as const,
    }),
  },
} as const

function flattenRewardQueryPages(
  queryResult: ReturnType<
    typeof useInfiniteQuery<{
      rewards: SolanaStakeHistoryRewardEntry[]
    }>
  >,
) {
  return {
    ...queryResult,
    data: queryResult.data?.pages.flatMap((p) => p.rewards),
  }
}

const fetchRewardHistoryPage = createFetchRewardHistoryPage(() => ({
  wallet: solana.wallet,
}))

/**
 * Returns paginated reward history throughout all stake accounts
 * of an account, if 'stakeAccountId' is not provided (null)
 */
function useGetPaginatedRewardHistory(
  accountId: AccountId,
  stakeAccountId: StakeAccountId | null,
  enabled = true,
) {
  const stakeAccountsQuery = useGetStakeAccountsInfo(enabled)
  const stakeAccounts = stakeAccountsQuery.data

  const rewardHistoryInfiniteQuery = useInfiniteQuery<{
    rewards: SolanaStakeHistoryRewardEntry[]
    nextPageParam: number | undefined
  }>({
    queryKey: stakeHistoryQueryKeys.rewardHistory
      .account(accountId)
      .stakeAccount(stakeAccountId),
    queryFn: async ({pageParam: lastSinceEpoch = undefined}) => {
      assert(!!stakeAccounts)
      const targetAccount = ensureAccountById(stakeAccounts, accountId)
      const {rewards, hasNextPage, sinceEpoch} = await fetchRewardHistoryPage(
        targetAccount,
        stakeAccountId,
        lastSinceEpoch as number | undefined,
      )
      return {
        rewards,
        // undefined to signal "no next page"
        nextPageParam: hasNextPage ? sinceEpoch : undefined,
      }
    },
    // rewards arrive once in two days, no need to refetch often
    staleTime: Infinity,
    enabled: enabled && !!stakeAccounts,
    initialPageParam: undefined,
    getNextPageParam: ({nextPageParam}) => nextPageParam,
  })

  const data = useMemo(
    () => flattenRewardQueryPages(rewardHistoryInfiniteQuery).data,
    [stakeAccounts, rewardHistoryInfiniteQuery.data?.pages, accountId],
  )

  return {
    ...rewardHistoryInfiniteQuery,
    ...mergeQueryResultsState(stakeAccountsQuery, rewardHistoryInfiniteQuery),
    data,
  }
}

function flattenStakeTxQueryPages(
  queryResult: ReturnType<
    typeof useInfiniteQuery<{
      txHistory: SolanaStakeHistoryTxEntry[]
    }>
  >,
) {
  return {
    ...queryResult,
    data: queryResult.data?.pages.flatMap((p) => p.txHistory),
  }
}

export const getStakingHistoryForAccount = createGetStakingHistoryForAccount(
  () => ({accountManager: solana.accountManager}),
)

/**
 * Returns paginated stake transaction history throughout all stake accounts
 * of an account, if 'stakeAccountId' is not provided (null)
 */
function useGetPaginatedStakeTxHistory(
  accountId: AccountId,
  stakeAccountId: StakeAccountId | null,
  enabled = true,
) {
  const stakeTxHistoryInfiniteQuery = useInfiniteQuery<{
    txHistory: SolanaStakeHistoryTxEntry[]
    nextPageParam: string | undefined
  }>({
    queryKey: stakeHistoryQueryKeys.stakeTxHistory
      .account(accountId)
      .stakeAccount(stakeAccountId),
    queryFn: async ({pageParam: before = undefined}) => {
      const account = solana.accountsStore.getAccount(accountId)
      const {stakeHistoryEntries, hasMorePages} =
        await getStakingHistoryForAccount({
          account,
          stakeAccountId,
          before: before as string | undefined,
        })
      const oldestStakeTx = stakeHistoryEntries[stakeHistoryEntries.length - 1]

      return {
        txHistory: [...stakeHistoryEntries].sort(
          (a, b) => b.timeIssued.getTime() - a.timeIssued.getTime(),
        ),
        nextPageParam:
          hasMorePages && oldestStakeTx ? oldestStakeTx.signature : undefined,
        // https://react-query.tanstack.com/guides/infinite-queries
        // required to be strictly undefined by the API in case of no next page
      }
    },
    staleTime: Infinity,
    enabled,
    initialPageParam: undefined,
    getNextPageParam: ({nextPageParam}) => nextPageParam,
  })

  const data = useMemo(
    () => flattenStakeTxQueryPages(stakeTxHistoryInfiniteQuery).data,
    [stakeTxHistoryInfiniteQuery.data?.pages, accountId],
  )

  return {
    ...stakeTxHistoryInfiniteQuery,
    data,
  }
}

/**
 * Returns paginated whole stake history throughout all stake accounts
 * of an account, if 'stakeAccountId' is not provided (null)
 * If a stakeAccountId is passed, this function fetches stake history only
 * for that specific stake account
 */
export function useGetPaginatedWholeStakeHistory(
  accountId: AccountId,
  stakeAccountId: StakeAccountId | null,
  enabled = true,
) {
  const stakeTxQuery = useGetPaginatedStakeTxHistory(
    accountId,
    stakeAccountId,
    enabled,
  )
  const stakeTxsData = stakeTxQuery.data

  const rewardsQuery = useGetPaginatedRewardHistory(
    accountId,
    stakeAccountId,
    enabled,
  )
  const rewardsData = rewardsQuery.data

  type SolanaStakeHistoryCursor = {
    usedRewards: number
    usedStakeTxs: number
  }
  const stakeHistoryInfiniteQuery = useInfiniteQuery<{
    data: SolanaStakeHistoryEntry[]
    nextPageParam: SolanaStakeHistoryCursor | undefined
  }>({
    queryKey: stakeHistoryQueryKeys.wholeStakeHistory
      .account(accountId)
      .stakeAccount(stakeAccountId),
    queryFn: async ({
      pageParam: _cursor = {usedRewards: 0, usedStakeTxs: 0},
    }) => {
      assert(!!stakeTxsData && !!rewardsData)
      const cursor = _cursor as SolanaStakeHistoryCursor

      const {data: txHistory = [], hasNextPage: hasMoreStakeTxs} =
        stakeTxsData.length - cursor.usedStakeTxs < STAKE_HISTORY_PAGE_SIZE &&
        stakeTxQuery.hasNextPage
          ? flattenStakeTxQueryPages(await stakeTxQuery.fetchNextPage())
          : stakeTxQuery

      const {data: rewardHistory = [], hasNextPage: hasMoreRewards} =
        rewardsData.length - cursor.usedRewards < STAKE_HISTORY_PAGE_SIZE &&
        rewardsQuery.hasNextPage
          ? flattenRewardQueryPages(await rewardsQuery.fetchNextPage())
          : rewardsQuery

      const stakeHistoryPage = [
        ...txHistory.slice(cursor.usedStakeTxs),
        ...rewardHistory.slice(cursor.usedRewards),
      ]
        .sort((a, b) => b.effectiveSlot - a.effectiveSlot)
        .slice(0, STAKE_HISTORY_PAGE_SIZE)

      const rewardsCountInPage = stakeHistoryPage.filter(
        (e) => e.type === 'reward',
      ).length
      const stakeTxCountInPage = stakeHistoryPage.length - rewardsCountInPage

      const newCursor = {
        usedRewards: cursor.usedRewards + rewardsCountInPage,
        usedStakeTxs: cursor.usedStakeTxs + stakeTxCountInPage,
      }

      const allDataUsed =
        !hasMoreStakeTxs &&
        !hasMoreRewards &&
        newCursor.usedRewards === rewardHistory.length &&
        newCursor.usedStakeTxs === txHistory.length

      return {
        data: stakeHistoryPage,
        nextPageParam: allDataUsed ? undefined : newCursor,
      }
    },
    enabled: enabled && !!stakeTxsData && !!rewardsData,
    initialPageParam: undefined,
    getNextPageParam: ({nextPageParam}) => nextPageParam,
  })

  const data = useMemo(
    () => stakeHistoryInfiniteQuery.data?.pages.flatMap((p) => p.data),
    [stakeHistoryInfiniteQuery.data?.pages, accountId],
  )

  return {
    ...stakeHistoryInfiniteQuery,
    ...mergeQueryResultsState(
      stakeHistoryInfiniteQuery,
      stakeTxQuery,
      rewardsQuery,
    ),
    data,
  }
}
