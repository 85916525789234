export const getAbsoluteFavIconUrl = (
  origin: string,
  favIconUrl: string,
): string => {
  if (favIconUrl.startsWith('http://') || favIconUrl.startsWith('https://')) {
    return favIconUrl
  }
  const url = new URL(origin)
  url.pathname = favIconUrl
  return url.href
}
