import {safeJsonParse, safeJsonStringify} from '@walletconnect/safe-json'

function parseEntry<T>(entry: [string, string | null]): [string, T] {
  return [entry[0], safeJsonParse(entry[1] ?? '') as T]
}
export class SessionStorageKeyValueStorage {
  private readonly localStorage = window.sessionStorage

  public async getKeys(): Promise<string[]> {
    return Object.keys(this.localStorage)
  }

  public async getEntries<T>(): Promise<[string, T][]> {
    return Object.entries(this.localStorage).map(parseEntry<T>)
  }

  public async getItem<T>(key: string): Promise<T | undefined> {
    const item = this.localStorage.getItem(key)
    if (item === null) {
      return undefined
    }
    // TODO: fix this annoying type casting
    return safeJsonParse(item) as T
  }

  public async setItem<T>(key: string, value: T): Promise<void> {
    this.localStorage.setItem(key, safeJsonStringify(value))
  }

  public async removeItem(key: string): Promise<void> {
    this.localStorage.removeItem(key)
  }
}

export default SessionStorageKeyValueStorage
