import {Box, Divider, Grid, Typography} from '@mui/material'
import {BigNumber} from 'bignumber.js'
import type {FormikProps} from 'formik'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'

import type {StakingBlockchain} from '../../../../blockchainTypes'
import {
  ModalLayout,
  ModalFooter,
  FooterLayout,
  AmountRow,
  AccountSelectField,
  useModalSharedStyles,
  CurrentPasswordField,
} from '../../../../components'
import {useIsPasswordVerificationRequired} from '../../../../store/auth'
import type {AppError, AccountId} from '../../../../types'
import type {SelectChangeFn} from '../../../../utils/form'
import type {AccountInfo} from '../../../../wallet'
import type {CardanoStakeAccountInfo} from '../../../../wallet/cardano'
import {WithdrawModalHeader} from '../../common/utils'
import type {WithdrawRewardsSchema} from '../schema'

import {TotalStakeRow} from './StakeModalDetailScreen'

type SummaryScreenProps<T extends WithdrawRewardsSchema> = {
  stakeAccounts: CardanoStakeAccountInfo[]
  blockchain: StakingBlockchain
  onClose: () => void
  onBack: () => unknown
  onSubmit: () => unknown
  summary: {
    availableWithdrawAmount: BigNumber
    fee: BigNumber | undefined
    fromAccount: AccountInfo
    isLoading: boolean
    error: AppError
  }
  formikProps: FormikProps<T>
  disabled?: boolean
}

const FORM_ID = 'withdraw-rewards-form-confirm-details'

export default function SummaryScreen<T extends WithdrawRewardsSchema>({
  stakeAccounts,
  onBack,
  onSubmit,
  blockchain,
  onClose,
  summary,
  formikProps,
  disabled,
}: SummaryScreenProps<T>) {
  const {t} = useTranslation()
  const classes = useModalSharedStyles()
  const {values, handleChange} = formikProps
  type FormField = keyof typeof values
  const {availableWithdrawAmount, fee, fromAccount, isLoading, error} = summary
  const totalAmount =
    !error && fee ? availableWithdrawAmount.minus(fee) : new BigNumber(0)

  const passwordRequired = useIsPasswordVerificationRequired(fromAccount)

  useEffect(() => {
    // reset password field if the "passwordRequired" changes to `false`, otherwise
    // A. password validation may be failing (user provided incorrect password previously),
    // but he sees no feedback
    // B. the password field is in error state when changing the accounts even without providing
    // wrong password & submitting the form yet
    if (!passwordRequired) {
      handleChange<FormField>('password')('')
    }
  }, [passwordRequired])

  // TODO: handle tx plan errors more nicely
  const ErrorContent = error ? (
    <Typography noWrap color="error">
      {(error as Error).message}
    </Typography>
  ) : null

  const stakeAccountItems = stakeAccounts.map((sa) => ({
    ...sa,
    accountId: sa.id,
  }))

  return (
    <form
      onSubmit={onSubmit}
      noValidate
      id={FORM_ID}
      className={classes.formWrapper}
    >
      <ModalLayout
        header={<WithdrawModalHeader {...{onClose, blockchain}} />}
        body={
          <Box p={2}>
            <Grid container>
              <Grid item xs={12}>
                <AccountSelectField
                  className={classes.formField}
                  label={t('Account')}
                  value={values.accountId}
                  onChange={
                    handleChange('accountId') as SelectChangeFn<AccountId>
                  }
                  items={stakeAccountItems}
                  blockchain={blockchain}
                  showBlockchainIcon
                />
              </Grid>
              <Grid item xs={12}>
                <Divider className={classes.dividerY} />
              </Grid>
              <Grid item xs={12}>
                <Box>
                  <AmountRow
                    label={t('Available rewards')}
                    amount={availableWithdrawAmount}
                    isLoading={isLoading}
                    blockchain={blockchain}
                  />
                  <AmountRow
                    label={t('Transaction fee')}
                    amount={fee}
                    isLoading={isLoading}
                    blockchain={blockchain}
                  />
                  <Divider className={classes.dividerY} />
                  <TotalStakeRow
                    amount={totalAmount}
                    fee={undefined}
                    isLoading={isLoading}
                    blockchain={blockchain}
                    totalText={t('Total to withdraw')}
                    totalDescription={t('Rewards - fee')}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                {ErrorContent}
              </Grid>
            </Grid>
          </Box>
        }
        footer={
          <ModalFooter hasDivider>
            {passwordRequired && (
              <CurrentPasswordField
                {...{formikProps}}
                className={classes.passwordHeight}
              />
            )}
            <FooterLayout
              leftBtnConf={{
                onClick: onBack,
                children: t('Back'),
              }}
              rightBtnConf={{
                children: t('Withdraw'),
                type: 'submit',
                form: FORM_ID,
                disabled,
              }}
            />
          </ModalFooter>
        }
      />
    </form>
  )
}
