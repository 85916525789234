import {HelpOutline as HelpIcon} from '@mui/icons-material'
import {
  Grid,
  InputAdornment,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import type {FormikProps} from 'formik'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {
  LabeledIconWithTooltip,
  useModalSharedStyles,
  WithTooltip,
} from '../../../../components'
import {
  sanitizeAmountFieldValue,
  isValidAmountFieldValue,
  getHasFormError,
} from '../../../../utils/form'

export const GasOptionsHeader = (): React.ReactNode => {
  const {t} = useTranslation()
  return (
    <Typography variant="subtitle2">
      <LabeledIconWithTooltip
        Icon={<HelpIcon fontSize="small" />}
        Label={t('Gas')}
        title={<>{t('gas_section_explanation')}</>}
        tooltipFontVariant="medium"
      />
    </Typography>
  )
}

export const GasFieldsWrapper = ({children}: {children: React.ReactNode}) => (
  <Grid container spacing={2} pt={2}>
    {children}
  </Grid>
)

export type GasFieldProps = {
  field: string
  label: string
  // We allow, `any` as we do not directly assign to schema and in order
  // to make this component more reusable and generic.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formikProps: FormikProps<any>
  deselectPrioritySetting?: () => void
  gasFeeFieldWarningValidation?: (value: string) => string | false
  endAdornment?: React.ReactNode
  explanation?: string | React.ReactNode
}

export type GasFieldStatus = {
  changedByUser: boolean
}

export const GasField = ({
  field,
  label,
  formikProps,
  deselectPrioritySetting,
  gasFeeFieldWarningValidation,
  explanation,
  endAdornment = undefined,
}: GasFieldProps) => {
  const classes = useModalSharedStyles()
  const theme = useTheme()
  const {values, handleChange, errors, handleBlur, setStatus, status} =
    formikProps
  const hasErrorAfterTouched = getHasFormError(formikProps, 'after-touched')
  type FormField = keyof typeof values

  const warningText = gasFeeFieldWarningValidation
    ? gasFeeFieldWarningValidation(formikProps.values[field])
    : false
  const hasError = hasErrorAfterTouched(field)

  return (
    <Grid item xs={12} className={classes.formField}>
      <TextField
        fullWidth
        variant="outlined"
        label={label}
        onBlur={(e) => {
          // without handleBlur the "touched" fields are not populated
          const onBlur = handleBlur<FormField>(field) as (e: unknown) => void
          onBlur(e)
        }}
        onChange={(e) => {
          const sanitized = sanitizeAmountFieldValue(e.target.value)
          const isValid = isValidAmountFieldValue(sanitized) || sanitized === ''
          if (!isValid) return
          // Note that the order here matters otherwise Formik causes
          // that validation is delayed by one key stroke.
          deselectPrioritySetting && deselectPrioritySetting()
          handleChange<FormField>(field)(sanitized)

          const currentFieldStatus =
            status?.[field] == null ? {} : status[field]

          setStatus({
            ...(status || {}),
            [field]: {
              ...currentFieldStatus,
              changedByUser: true,
            } as GasFieldStatus,
          })
        }}
        error={hasErrorAfterTouched(field)}
        value={values[field]}
        helperText={
          hasErrorAfterTouched(field) ? <>{errors[field]}</> : warningText
        }
        color={hasError ? 'error' : warningText ? 'warning' : 'primary'}
        FormHelperTextProps={{
          sx: {
            color: warningText ? theme.palette.warning.main : 'inherit',
          },
        }}
        InputProps={{
          endAdornment: endAdornment ? (
            <InputAdornment position="end">{endAdornment}</InputAdornment>
          ) : undefined,
          startAdornment: explanation ? (
            <InputAdornment position="start">
              <WithTooltip title={explanation}>
                <HelpIcon fontSize="small" />
              </WithTooltip>
            </InputAdornment>
          ) : undefined,
        }}
      />
    </Grid>
  )
}
