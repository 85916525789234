import {ErrorOutline as ErrorIcon} from '@mui/icons-material'
import {Grid, Box, Typography} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {useInitializationStateStore} from '../../store/wallet'
import {useGetBlockchainOrWalletKindName} from '../../utils/translations'
import {
  Modal,
  ModalLayout,
  ModalHeader,
  ModalFooter,
  Button,
  Alert,
} from '../visual'

export function WalletInitWarningModal() {
  const {t} = useTranslation()
  const getBlockchainOrWalletKindName = useGetBlockchainOrWalletKindName()
  const classes = useStyles()

  const [warningSeen, setWarningSeen] = useState(false)
  const onClose = () => setWarningSeen(true)

  const initializationFailures =
    useInitializationStateStore().failedWalletInitializations
  if (
    warningSeen ||
    !initializationFailures ||
    initializationFailures.length === 0
  ) {
    return null
  }

  return (
    <Modal variant="centered">
      <ModalLayout
        header={
          <ModalHeader onClose={onClose} hasDivider>
            <Grid container spacing={2} alignItems="flex-end">
              <Grid item className={classes.errorIcon}>
                <ErrorIcon color="inherit" />
              </Grid>
              <Grid item>
                <Typography variant="h6">
                  {t('Problem while signing into the wallet')}
                </Typography>
              </Grid>
            </Grid>
          </ModalHeader>
        }
        body={
          <Box maxWidth={560} p={2}>
            <Alert severity="warning">
              <>
                <Typography>
                  <Box component="span" fontWeight="fontWeightBold">
                    {t(
                      'We have noticed problems with the following blockchains:',
                    )}
                  </Box>
                </Typography>
                {initializationFailures.map((walletKind) => (
                  <Typography key={walletKind}>
                    {getBlockchainOrWalletKindName(walletKind)}
                  </Typography>
                ))}
              </>
            </Alert>
            <Alert
              severity="warning"
              text={t(
                'We may temporarily not show all your accounts for the above blockchains, however, the accounts will be loaded on the next successful login.',
              )}
            />
            <Alert severity="warning">
              <Typography>
                {t(
                  'You may also experience issues with loading the data or sending transactions, but this does not have any effect on your funds.',
                )}
              </Typography>
              <Typography>
                <Box fontWeight="fontWeightBold" component="span">
                  {t('Your funds are always safe.')}
                </Box>
              </Typography>
            </Alert>
            <Box mt={2}>
              <Typography variant="body2">
                {t(
                  'We apologize for the inconvenience and suggest trying again later.',
                )}
              </Typography>
            </Box>
          </Box>
        }
        footer={
          <ModalFooter hasDivider>
            <Button
              textTransform="none"
              fullWidth
              variant="outlined"
              onClick={onClose}
            >
              {t('I understand')}
            </Button>
          </ModalFooter>
        }
      />
    </Modal>
  )
}

const useStyles = makeStyles((theme) => ({
  errorIcon: {
    color: theme.palette.warning.main,
  },
}))
