import {Stack, styled} from '@mui/material'
import type {FormikProps} from 'formik'
import React from 'react'
import {useTranslation} from 'react-i18next'

import type {
  EvmBlockchain,
  EvmSignedTransaction,
  EvmAccountInfo,
  EvmTransactionHash,
  EvmAddress,
} from '../../../../wallet/evm'
import {useSubmitTransfer, useResolveEns} from '../../../../wallet/evm'
import type {SendModalContentProps} from '../../common/sendMultipleAssetsModal/SendMultipleAssetsModalContent'
import {SendModalContent} from '../../common/sendMultipleAssetsModal/SendMultipleAssetsModalContent'
import {DeviceReadyState} from '../../common/utils'
import {
  useGetExtraSummaryContent,
  NonceField,
  MaybeSendingFundsToContractAddressWarning,
} from '../common'
import type {GasSchema} from '../gas/types'
import type {BaseSchema} from '../schema'
import type {EvmSendModalCommonContentProps} from '../types'

type EvmSendModalContentProps<TBlockchain extends EvmBlockchain> =
  EvmSendModalCommonContentProps<TBlockchain> & {
    disabled?: boolean
  } & Pick<
      SendModalContentProps<
        BaseSchema,
        EvmSignedTransaction<TBlockchain>,
        EvmTransactionHash
      >,
      | 'signProps'
      | 'ErrorContent'
      | 'getAssetFieldProps'
      | 'onBeforeDetailsSubmit'
    >

export const EvmSendModalContent = <TBlockchain extends EvmBlockchain>({
  blockchain,
  gasProps,
  formikProps,
  fromAccount: _fromAccount,
  onResolveEnsHandle,
  disabled,
  signProps,
  txFee,
  onBeforeDetailsSubmit,
  ...props
}: EvmSendModalContentProps<TBlockchain>) => {
  const submit = useSubmitTransfer(blockchain)
  const resolveEns = useResolveEns(blockchain, onResolveEnsHandle)
  const {t} = useTranslation()
  const {values} = formikProps

  const fromAccount = _fromAccount as EvmAccountInfo<TBlockchain>
  const getExtraSummaryContent = useGetExtraSummaryContent()

  const onTxSubmit = async (signedTx: EvmSignedTransaction<TBlockchain>) =>
    submit.mutateAsyncSilent({signedTx, accountId: values.accountId})

  return (
    <SendModalContent<
      BaseSchema,
      EvmSignedTransaction<TBlockchain>,
      EvmTransactionHash
    >
      onBeforeDetailsSubmit={onBeforeDetailsSubmit}
      {...{
        ...props,
        DeviceReadyState,
        feeProps: {
          fee: txFee,
          customLabel: t('Network fee (max):'),
        },
        formikProps,
        blockchain,
        fromAccount,
        type: 'singleAsset',
        disabled,
        signProps,
        submitProps: {
          ...submit,
          mutateAsyncSilent: onTxSubmit,
        },
        customField: (
          <>
            {gasProps.renderGasOptions(
              formikProps as unknown as FormikProps<GasSchema>,
            )}
            <NonceField
              {...{formikProps, blockchain}}
              fromAddress={fromAccount.address}
            />
          </>
        ),
        extraContent: (
          <ExtraContentStack spacing={1}>
            {getExtraSummaryContent(values.nonce)}
            {values.addressType !== 'internal' && (
              <MaybeSendingFundsToContractAddressWarning
                address={values.toAddress as EvmAddress<typeof blockchain>}
                {...{blockchain}}
              />
            )}
          </ExtraContentStack>
        ),
        humanReadableToAddressName:
          formikProps.values.toAddressNameServiceState.status === 'valid'
            ? `ENS: ${formikProps.values.toAddress}`
            : undefined,
        addressNameService: {
          data: resolveEns.data || null,
          isLoading: resolveEns.isPending,
          onSubmit: (address: string) => resolveEns.mutateAsyncSilent(address),
          shouldEnableNameService: (address: string) => address.includes('.'),
          getValidateSuccessLabel: () => t('ENS address resolved'),
          getValidateLabel: () => t('Resolve ENS address'),
        },
      }}
    />
  )
}

const ExtraContentStack = styled(Stack)(({theme}) => ({
  '& > *:first-child': {
    marginTop: theme.spacing(1),
  },
}))
