import {Box} from '@mui/material'
import type {SolanaAccountWithStakeAccounts} from '@nufi/wallet-solana'
import {sumRewards, sumStakedAmounts} from '@nufi/wallet-solana'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {
  FormattedAsset,
  FormattedPercentage,
  QueryGuard,
} from '../../../../components'
import {sumAccountInfoBalances} from '../../../../wallet'
import {useGetTotalStakedAPY} from '../../../../wallet/solana'
import {
  StakeSummary,
  SummaryCard,
} from '../../common/overview/StakeOverviewUtils'

import {RecentRewardsIconTooltip} from './common'

type StakeSummaryProps = {
  accounts: SolanaAccountWithStakeAccounts[]
}

export default function SolanaStakeSummary({accounts}: StakeSummaryProps) {
  const {t} = useTranslation()

  const totalStakedAPYQuery = useGetTotalStakedAPY()
  const combinedStakeAccounts = accounts.map((a) => a.stakeAccounts).flat()
  const rewardSum = sumRewards(combinedStakeAccounts)
  const stakedSum = sumStakedAmounts(combinedStakeAccounts)
  const balanceSum = sumAccountInfoBalances(accounts)

  return (
    <Box>
      <StakeSummary>
        <SummaryCard
          label={t('Total Staked APY %')}
          value={
            <QueryGuard {...totalStakedAPYQuery}>
              {(APY) => <FormattedPercentage value={APY} />}
            </QueryGuard>
          }
        />
        <SummaryCard
          label={t('Available balance')}
          value={
            <FormattedAsset
              amount={balanceSum}
              blockchain="solana"
              isSensitiveInformation
            />
          }
        />
        <SummaryCard
          label={<RecentRewardsIconTooltip />}
          value={
            <FormattedAsset
              amount={rewardSum}
              blockchain="solana"
              isSensitiveInformation
            />
          }
        />
        <SummaryCard
          label={t('Total staked')}
          value={
            <FormattedAsset
              amount={stakedSum}
              blockchain="solana"
              isSensitiveInformation
            />
          }
        />
      </StakeSummary>
    </Box>
  )
}
