import {VisibilityOff as HiddenIcon} from '@mui/icons-material'
import {Box, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {Alert} from '../../../components'

export const HiddenNftImage = () => {
  return (
    <Box
      sx={{
        background: 'black',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <HiddenIcon sx={{fontSize: 100}} />
    </Box>
  )
}

export const HiddenNftsBanner = () => {
  const {t} = useTranslation()
  return (
    <Box px={2} pb={1}>
      <Alert severity="warning">
        <Typography>
          {t(
            'You are viewing hidden NFTs. We are automatically hiding suspicious NFTs. You can always un-hide them.',
          )}
        </Typography>
      </Alert>
    </Box>
  )
}
