import DoneIcon from '@mui/icons-material/CheckCircle'
import {Box, InputAdornment, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React from 'react'
import {Trans, useTranslation} from 'react-i18next'

import type {Blockchain} from '../types'

import {CopyToClipboard} from './CopyToClipboard'
import {AddressExplorerLink} from './explorerLinks'
import type {ExternalOrInternalAddressFieldProps} from './ExternalOrInternalAddressField'
import {ExternalOrInternalAddressField} from './ExternalOrInternalAddressField'
import {Alert, Button} from './visual/atoms'
import {IpfsImage} from './visual/atoms/IpfsImage'

export type AddressNameServiceState =
  | {status: 'valid'; resolvedAddress: string} // field manually validated by user and not changed since then
  | {status: 'not-validated'} // not yet validated by user or changed since last validation
  | {status: 'invalid'} // validation failed
  | {status: 'disabled'} // name service not active

type ExternalOrInternalAddressFieldWithNSProps = Omit<
  ExternalOrInternalAddressFieldProps,
  'blockchain'
> & {
  blockchain: Blockchain
  nameService?: {
    validateLabel: string
    validatedLabel: string
    validationState: AddressNameServiceState
    onValidate: (address: string) => Promise<void>
    isLoading: boolean
    extras?: {
      image?: string
    }
  }
}

/**
 * ExternalOrInternalAddressField with address name service support
 */
export function ExternalOrInternalAddressFieldWithNS({
  nameService,
  ...restProps
}: ExternalOrInternalAddressFieldWithNSProps) {
  const {t} = useTranslation()
  const classes = useStyles()

  if (!nameService) return <ExternalOrInternalAddressField {...restProps} />

  const {
    validateLabel,
    validatedLabel,
    validationState,
    onValidate,
    isLoading,
    extras,
  } = nameService
  const {value, blockchain} = restProps

  const nameServiceActive = validationState.status !== 'disabled'

  const externalAddressInputProps = {
    endAdornment: nameServiceActive && (
      <InputAdornment position="end">
        {(() => {
          if (validationState.status === 'valid')
            return (
              <>
                <Typography color="primary" variant="body2">
                  {validatedLabel}
                </Typography>
                <Box ml={1} />
                <DoneIcon fontSize="small" color="primary" />
              </>
            )

          return (
            <Button
              className={classes.validateButton}
              textTransform="none"
              variant="outlined"
              color="default"
              onClick={() => onValidate(value.address)}
              disabled={isLoading}
              loading={isLoading}
            >
              {validateLabel}
            </Button>
          )
        })()}
      </InputAdornment>
    ),
  }

  return (
    <>
      <ExternalOrInternalAddressField
        {...restProps}
        {...{blockchain, value, externalAddressInputProps}}
        externalAddressInputProps={externalAddressInputProps}
      />
      {validationState.status === 'valid' && (
        <Box className={classes.resolvedData}>
          <Box display="flex">
            {extras?.image && (
              <Box mt={0.5}>
                <IpfsImage size={70} src={extras.image} />
              </Box>
            )}
            <Box pl={extras?.image ? 2 : 0}>
              <Typography className={classes.resolvedAddress} variant="caption">
                {validationState.resolvedAddress}
              </Typography>
              <Box display="flex" alignItems="center">
                <Typography variant="caption">
                  <AddressExplorerLink
                    size="small"
                    address={validationState.resolvedAddress}
                    blockchain={blockchain}
                  />
                </Typography>
                <CopyToClipboard
                  hoverableBackgroundFontSize="small"
                  iconFontSize="small"
                  value={validationState.resolvedAddress}
                />
              </Box>
            </Box>
          </Box>
          <Box mt={1} />
          <Alert severity="warning">
            <Typography variant="body2" display="block">
              <Trans
                i18nKey="address_name_service_warning"
                t={t}
                components={{
                  bold: <Box component="span" fontWeight="fontWeightMedium" />,
                }}
                values={{value: value.address}}
              />
            </Typography>
          </Alert>
          <Box mt={0.5} />
        </Box>
      )}
    </>
  )
}

const useStyles = makeStyles(() => ({
  validateButton: {
    paddingTop: 2,
    paddingBottom: 2,
    fontSize: 14,
    minHeight: 0,
  },
  resolvedData: {
    position: 'relative',
    bottom: 8,
  },
  resolvedAddress: {
    wordBreak: 'break-all',
    display: 'block',
  },
}))
