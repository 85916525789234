import {Box, Typography, Paper} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {Light as SyntaxHighlighter} from 'react-syntax-highlighter'
import json from 'react-syntax-highlighter/dist/esm/languages/hljs/json'
import {a11yDark} from 'react-syntax-highlighter/dist/esm/styles/hljs'

import type {SignScreenData} from '../../../../store/dappConnector'
import theme from '../../../../theme/theme'
import {useSignContext} from '../SignContext'

import {formatHexMessage, formatTypedMessage} from './format'

SyntaxHighlighter.registerLanguage('json', json)

export type MessageDataProps = {
  data: SignScreenData<'sign-message'>
}

export function MessageData({data}: MessageDataProps) {
  const {t} = useTranslation()
  const {origin} = useSignContext()

  return (
    <Box display="flex" flexDirection="column" justifyContent="center">
      <Typography variant="body2" align="center" fontWeight="bold">
        {origin}
      </Typography>
      <Typography variant="body2" align="center" marginBottom={1}>
        {t('requests a signature of the below message:')}
      </Typography>
      <Paper>
        <Box p={2} height={150} overflow="auto">
          {'messagesTyped' in data.data &&
            data.data.messagesTyped.map((message, index) => (
              <SyntaxHighlighter
                key={index}
                language="json"
                style={a11yDark}
                customStyle={{
                  backgroundColor: theme.palette.background.paper,
                  fontSize: 14,
                  margin: 0,
                  padding: 0,
                }}
              >
                {formatTypedMessage(message)}
              </SyntaxHighlighter>
            ))}
          {'messageHex' in data.data && (
            <Typography variant="caption" sx={{wordWrap: 'break-word'}}>
              {formatHexMessage(data.data.messageHex)}
            </Typography>
          )}
        </Box>
      </Paper>
    </Box>
  )
}
