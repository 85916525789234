import {CheckCircleOutline} from '@mui/icons-material'
import {Typography, styled} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {useSignContext} from 'src/dappConnector/pages/sign/SignContext'

import {Button, Alert} from '../../../../../components/visual'

type SuccessScreenProps = {
  onSuccess: () => void
}

export function SuccessScreen({onSuccess}: SuccessScreenProps) {
  const {t} = useTranslation()
  const {layoutProps} = useSignContext()
  return (
    <Wrapper width={layoutProps.screenWidth}>
      <SuccessIconWrapper>
        <SuccessIcon color="inherit" />
      </SuccessIconWrapper>
      <Typography align="center" variant="h6">
        {t('Transaction successfully submitted.')}
      </Typography>
      <Alert severity="info">
        <Typography>
          {t('dapp_connector_dapp_may_warn_about_collateral')}
        </Typography>
      </Alert>
      <div>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          textTransform="none"
          type="submit"
          onClick={onSuccess}
        >
          {t('Continue')}
        </Button>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled('div', {shouldForwardProp: (prop) => prop !== 'width'})<{
  width?: number | string
}>(({width, theme}) => ({
  width,
  margin: '0 auto',
  '& > *': {
    marginTop: theme.spacing(1),
  },
}))

const SuccessIconWrapper = styled('div')(({theme}) => ({
  color: theme.palette.success.main,
  display: 'flex',
  justifyContent: 'center',
}))

const SuccessIcon = styled(CheckCircleOutline)({
  width: 48,
  height: 48,
})
