import {Divider} from '@mui/material'
import type {FlowAccountInfo, FlowTokenMetadata} from '@nufi/wallet-flow'
import {useFormikContext} from 'formik'
import React from 'react'
import {Trans, useTranslation} from 'react-i18next'

import type {Blockchain} from '../../../types'
import {ensureAccountById} from '../../../wallet/utils/common'
import type {ImportFlowTokenSchema} from '../../portfolio/account/actions/importToken/flow/schema'
import type {SubmitProps} from '../../transaction'
import {
  SubmitScreen,
  SuccessBody,
  SuccessFooter,
  useActiveScreenState,
} from '../../transaction'
import {ReceiveModalAddress, ReceiveModalAssetName} from '../Helpers'

type ReceiveModalSubmitScreenProps = {
  blockchain: Blockchain
  onBack: () => void
  onClose: () => void
  onNewTransaction: (() => unknown) | null
  submitProps: SubmitProps
  renderModalHeader: (tokenMetadata?: FlowTokenMetadata) => React.ReactElement
  accountInfos: FlowAccountInfo[]
  tokenMetadata?: FlowTokenMetadata
  isNftView: boolean
}

export const ReceiveModalSubmitScreen = (
  props: ReceiveModalSubmitScreenProps,
) => {
  const {t} = useTranslation()
  const {setActiveScreen} = useActiveScreenState()
  const {values} = useFormikContext<ImportFlowTokenSchema>()
  const {
    blockchain,
    submitProps,
    onNewTransaction,
    onClose,
    accountInfos,
    tokenMetadata,
  } = props
  const {address} = ensureAccountById(accountInfos, values.accountId)
  const CustomSuccessBody = submitProps.data ? (
    <>
      <SuccessBody
        txHash={submitProps.data}
        blockchain={blockchain}
        accountId={values.accountId}
        customSuccessText={
          <Trans
            i18nKey="token_imported_successfully"
            t={t}
            components={{
              AssetName: (
                <ReceiveModalAssetName
                  {...{tokenMetadata, blockchain}}
                  ellipsize={false}
                />
              ),
            }}
            values={{
              tokenType: props.isNftView ? t('NFT collection') : t('token'),
            }}
          />
        }
      />
      <Divider sx={{my: 2}} />
      <ReceiveModalAddress
        address={address}
        isToken={false}
        blockchain={blockchain}
        assetName={<ReceiveModalAssetName blockchain={blockchain} />}
      />
    </>
  ) : null
  const CustomSuccessFooter = (
    <SuccessFooter
      leftActionText={t('Close')}
      onLeftAction={onClose}
      onRightAction={() => (onNewTransaction ? onNewTransaction() : null)}
      rightActionText={t('Receive different token')}
    />
  )
  return (
    <SubmitScreen
      {...props}
      ModalHeader={props.renderModalHeader(tokenMetadata)}
      accountId={values.accountId}
      CustomSuccessBody={CustomSuccessBody}
      CustomSuccessFooter={CustomSuccessFooter}
      onRetry={() => Promise.resolve(setActiveScreen('details'))}
    />
  )
}
