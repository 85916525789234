import React from 'react'

import {BatchQueryGuard, QueryGuard} from 'src/components/DataGuards'
import {FormattedComponentGuardProps} from 'src/components/formatting/utils'
import type {Blockchain, TokenId, TokenMetadata} from 'src/types'
import {useGetTokenMetadata} from 'src/wallet'

import {useGetArbitraryTokensRates, useGetConversionRates} from '../application'
import type {ConversionRates} from '../domain'

/**
 * Return conversion rates belonging to all supported
 * native assets and tokens owned by user wallet.
 */
export const WithConversionRates = ({
  children,
}: {
  children: (d: ConversionRates) => React.ReactElement
}) => children(useGetConversionRates().rates)

/**
 * Use already loaded conversion rates if available at the time of request,
 * or fetch conversion rates for the token directly (handy for not owned tokens).
 */
export function WithArbitraryAssetConversionRates({
  blockchain,
  tokenId,
  children,
}: {
  blockchain: Blockchain
  tokenId?: TokenId
  children: (d: {
    conversionRates: ConversionRates
    tokenMetadata?: TokenMetadata
  }) => React.ReactElement
}) {
  const conversionRates = useGetConversionRates()

  if (tokenId != null) {
    return (
      <_WithOwnOrRemoteTokenRates
        blockchain={blockchain}
        tokenId={tokenId}
        children={children}
      />
    )
  }
  return children({
    conversionRates: conversionRates.rates,
  })
}

function _WithOwnOrRemoteTokenRates({
  blockchain,
  children,
  tokenId,
}: {
  blockchain: Blockchain
  tokenId: TokenId
  children: (d: {
    conversionRates: ConversionRates
    tokenMetadata: TokenMetadata
  }) => React.ReactElement
}) {
  const walletConversionRates = useGetConversionRates()
  const tokenMetadataQuery = useGetTokenMetadata(tokenId, blockchain)
  const walletRate = walletConversionRates.rates[tokenId]

  const arbitraryTokensRatesQuery = useGetArbitraryTokensRates({
    blockchain,
    tokensMetadata: tokenMetadataQuery.data ? [tokenMetadataQuery.data] : [],
    enabled: !walletRate && tokenMetadataQuery.data != null,
  })

  // Use existing rates
  if (walletRate != null) {
    return (
      <QueryGuard {...tokenMetadataQuery} {...FormattedComponentGuardProps}>
        {(tokenMetadata) =>
          children({
            conversionRates: walletConversionRates.rates,
            tokenMetadata,
          })
        }
      </QueryGuard>
    )
  }

  // Load separate rate.
  // -> Either token not owned by user
  // -> Or conversion rates not yet loaded (very improbable, we do not
  // even handle it as its not worth it)
  return (
    <BatchQueryGuard
      queries={{
        arbitraryTokensRates: arbitraryTokensRatesQuery,
        tokenMetadata: tokenMetadataQuery,
      }}
      {...FormattedComponentGuardProps}
    >
      {({arbitraryTokensRates, tokenMetadata}) =>
        children({
          conversionRates: arbitraryTokensRates.rates,
          tokenMetadata,
        })
      }
    </BatchQueryGuard>
  )
}
