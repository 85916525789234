import {Box, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {InlineLoading, Paper, QueryGuard} from '../../../../../components'
import type {
  EvmBlockchain,
  SimulateEvmTransaction,
} from '../../../../../wallet/evm'
import {useSimulateEvmTransaction} from '../../../../../wallet/evm'
import type {TxAssetChangesSimulationResponse} from '../../../../../wallet/evm/sdk/alchemy/types'
import type {BlockchainTxDataProps} from '../types'

type WithSimulationDataProps = {
  txParams: BlockchainTxDataProps<EvmBlockchain>['txParams']
  children: (
    txSimulationResult: TxAssetChangesSimulationResponse | null,
  ) => React.ReactNode
  simulateEvmTransaction: SimulateEvmTransaction
  blockchain: EvmBlockchain
}

export function WithSimulationData({
  txParams,
  children,
  simulateEvmTransaction,
  blockchain,
}: WithSimulationDataProps) {
  const simulationData = {
    data: txParams.data,
    to: txParams.to,
    from: txParams.from,
    value: txParams.value,
  }
  const simulateTxQuery = useSimulateEvmTransaction(
    blockchain,
    simulationData,
    simulateEvmTransaction,
  )

  return (
    <QueryGuard {...simulateTxQuery} LoadingElement={<SimulatingDataLoading />}>
      {(txSimulationResult) => <>{children(txSimulationResult)}</>}
    </QueryGuard>
  )
}

export function SimulatingDataLoading() {
  const {t} = useTranslation()
  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      rtl-data-test-id="loading-simulation-data"
    >
      <Typography variant="h6" mt={2}>
        {t('Simulating transaction')}
      </Typography>
      <Typography variant="caption" mb={1}>
        {t('This might take a few seconds ...')}
      </Typography>
      <Box mb={2}>
        <InlineLoading />
      </Box>
    </Paper>
  )
}
