/* eslint-disable @typescript-eslint/explicit-function-return-type */

import type {StakingBlockchain} from '../../../blockchainTypes'
import {
  useGetHasActiveStaking as useHasActiveCardanoStaking,
  useGetTotalStakedAPY as useCardanoTotalStakedAPY,
} from '../../cardano'
import {
  useGetHasActiveStaking as useHasActiveFlowStaking,
  useGetAPY as useGetFlowAPY,
} from '../../flow'
import {
  useGetHasActiveStaking as useHasActiveSolanaStaking,
  useGetTotalStakedAPY as useSolanaTotalStakedAPY,
} from '../../solana'

export function useHasActiveStaking() {
  const {
    data: hasSolanaStaking,
    error: solanaError,
    isLoading: solanaIsLoading,
  } = useHasActiveSolanaStaking()
  const {
    data: hasCardanoStaking,
    error: cardanoError,
    isLoading: cardanoIsLoading,
  } = useHasActiveCardanoStaking()
  const {
    data: hasFlowStaking,
    error: flowError,
    isLoading: flowIsLoading,
  } = useHasActiveFlowStaking()

  return {
    isLoading: solanaIsLoading || cardanoIsLoading || flowIsLoading,
    error: solanaError || cardanoError || flowError,
    // We want to return `true` if we know that there is any active staking,
    // even if some queries fail
    data: hasSolanaStaking || hasCardanoStaking || hasFlowStaking,
  }
}

export function useTotalStakedAPY(blockchain: StakingBlockchain) {
  const queries = {
    solana: useSolanaTotalStakedAPY(blockchain === 'solana'),
    cardano: useCardanoTotalStakedAPY(),
    flow: useGetFlowAPY(),
  }
  return queries[blockchain]
}
