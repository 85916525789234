import {Box} from '@mui/material'
import type BigNumber from 'bignumber.js'
import React from 'react'

import type {ConvertedBalanceResponse} from 'src/features/assets/domain/conversions'
import {convertBalance} from 'src/features/assets/domain/conversions'
import type {ConversionRates} from 'src/features/conversionRates/domain'
import {useSettings} from 'src/features/profile/application'

import type {Blockchain, TokenMetadata} from '../../types'
import {AmountVisibilityWrapper} from '../visual/AmountVisibilityWrapper'
import {SomeValueInCurrencyErroIndicator} from '../visual/ErrorMessages'

import {FormattedCurrency} from './FormattedCurrency'

type FormattedBlockchainAssetsAsCurrencyProps = {
  nativeAmount: BigNumber
  blockchain: Blockchain
  includeCurrencySymbol?: boolean
  tokens?: Array<{tokenMetadata: TokenMetadata; amount: BigNumber}>
  isSensitiveInformation: boolean
  shouldUseCompactNotation?: boolean
  // If set to `true` will render N/A if any of the known assets
  // that should have conversion rates fail to load. Otherwise it will
  // sum what can be summed.
  requireAllBalancesDefined?: boolean
  conversionRates: ConversionRates
}

const getFullAmount = (
  balances: ConvertedBalanceResponse[],
  requireAllBalancesDefined: boolean,
): {hasSomeError: boolean; balanceRes: ConvertedBalanceResponse} => {
  const hasSomeError = balances.some((tv) => tv.type === 'rates-not-loaded')

  if (hasSomeError && requireAllBalancesDefined) {
    return {
      hasSomeError,
      balanceRes: {
        type: 'rates-not-loaded',
      },
    }
  }

  const balance = balances.reduce(
    (acc, curr) => (curr.type === 'success' ? acc + curr.balance : acc),
    0,
  )
  return {
    hasSomeError,
    balanceRes: {
      type: 'success',
      balance,
    },
  }
}

export const FormattedBlockchainAssetsAsCurrency = ({
  nativeAmount,
  blockchain,
  includeCurrencySymbol = true,
  requireAllBalancesDefined = true,
  tokens,
  shouldUseCompactNotation,
  isSensitiveInformation,
  conversionRates,
}: FormattedBlockchainAssetsAsCurrencyProps) => {
  const {currency} = useSettings()

  const nativeBalance = convertBalance({
    balance: nativeAmount,
    blockchain,
    currency,
    conversionRates,
  })

  const tokenBalances = (tokens || []).map(({amount, tokenMetadata}) => {
    return convertBalance({
      balance: amount,
      blockchain,
      currency,
      conversionRates,
      tokenMetadata,
    })
  })

  const fullAmount = getFullAmount(
    [...tokenBalances, nativeBalance],
    requireAllBalancesDefined,
  )

  const shouldDisplayErrorIndicator = fullAmount.hasSomeError

  return (
    <AmountVisibilityWrapper isSensitiveInformation={isSensitiveInformation}>
      <Box sx={{display: 'flex', alignItems: 'center'}}>
        {/* Show warning indicator in case we obtained value to show to a user, but
            some rates were not properly loaded. */}
        {shouldDisplayErrorIndicator && (
          <Box sx={{position: 'relative', bottom: 1}}>
            <SomeValueInCurrencyErroIndicator />
          </Box>
        )}
        <FormattedCurrency
          convertedBalance={fullAmount.balanceRes}
          currency={currency}
          includeCurrencySymbol={includeCurrencySymbol}
          isSensitiveInformation={false}
          shouldUseCompactNotation={shouldUseCompactNotation}
        />
      </Box>
    </AmountVisibilityWrapper>
  )
}
