import React, {useState} from 'react'
import * as yup from 'yup'

import {getInputFieldValueFromValidation} from 'src/utils/form'

import type {CardanoTokenId, CardanoAccountInfo} from '../../../wallet/cardano'
import {
  signTransaction,
  submitTransaction,
  getTransferAssetsTxPlan,
  isValidCardanoPaymentAddress,
  getCardano,
  adaToLovelaces,
} from '../../../wallet/cardano'
import {SendModal} from '../common/sendMultipleAssetsModal/SendMultipleAssetsModal'
import type {SendMultiAssetFormInjectedProps} from '../common/sendMultipleAssetsModal/types'
import type {BaseSendSchema as FormSchema, AssetSchema} from '../common/types'

import {minAmountValidationErrorKey} from './common'
import {CardanoSendModalContent} from './SendModalContent'
import type {CardanoSideEffectProps} from './types'
import {findTokenBalance, getMinAdaAmount} from './utils'

export type CardanoSendModalProps = {
  onClose: () => unknown
} & Partial<CardanoSideEffectProps>

export function CardanoSendModal({
  onClose,
  onResolveAdaHandle = getCardano().adaHandle.resolve,
  onResolveAdaDomain = getCardano().adaDomains.resolve,
  onSignTransaction = signTransaction,
  onSubmitTransaction = submitTransaction,
  onGetTransferAdaTxPlan = getTransferAssetsTxPlan,
}: CardanoSendModalProps) {
  const defaultMinAmount = getMinAdaAmount()
  const [minAda, setMinAda] = useState(defaultMinAmount)
  const blockchain = 'cardano'

  const amountLessThanMinValidation = yup
    .string()
    .test(
      'amount-not-less-than-min',
      minAmountValidationErrorKey,
      function (amount, context) {
        if (amount === undefined) return true
        if (context.options.context?.shouldSkipMinAmountValidation) return true
        const ctx: AssetSchema = context.parent
        if (ctx.type === 'token') return true

        const fieldAmount = getInputFieldValueFromValidation(context)
        const parsedAmount = adaToLovelaces(fieldAmount)
        return (
          parsedAmount.isGreaterThanOrEqualTo(minAda) &&
          parsedAmount.isGreaterThanOrEqualTo(0)
        )
      },
    )

  return (
    <SendModal<FormSchema>
      {...{
        type: 'multiAsset',
        blockchain,
        onClose,
        defaultMinAmount,
        isAddressValid: (address) => isValidCardanoPaymentAddress(address),
        getAvailableTokenBalance: (account, tokenId) =>
          findTokenBalance(
            account as CardanoAccountInfo,
            tokenId as CardanoTokenId,
          ),
        shouldUseDetailsSubmitGuard: true,
        overrideBaseDetailsValidationSchema: (schema) => ({
          ...schema,
          assets: schema.assets.concat(
            yup.array().of(
              yup.object().shape({
                amount: amountLessThanMinValidation,
              }),
            ),
          ),
        }),
        overrideInitialValues: ({assets, ...initialValues}) => ({
          ...initialValues,
          assets: [
            {
              type: 'native',
              amount: '1',
            },
            ...assets.filter(({type}) => type === 'token'),
          ],
        }),
      }}
    >
      {(props: SendMultiAssetFormInjectedProps<FormSchema>) => (
        <CardanoSendModalContent
          {...{
            onClose,
            ...props,
            defaultMinAmount,
            onResolveAdaHandle,
            onResolveAdaDomain,
            onSignTransaction,
            onSubmitTransaction,
            onGetTransferAdaTxPlan,
            minAda,
            setMinAda,
          }}
        />
      )}
    </SendModal>
  )
}
