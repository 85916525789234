import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material'
import {Box, Grid, List} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {Aligner, TextButton} from '../../../../components'

type ShowStakingCardDetailsProps = {
  expanded: boolean
  length: number
}

export function ShowStakingCardDetails({
  expanded,
  length,
}: ShowStakingCardDetailsProps) {
  const {t} = useTranslation()
  return (
    <>
      {length > 0 ? (
        <TextButton
          color="textSecondary"
          fontWeight="medium"
          onClick={() => null}
          label={`${
            expanded ? t('Hide staking') : t('Show staking')
          } (${length})`}
          Icon={
            expanded ? (
              <ExpandLessIcon color="inherit" />
            ) : (
              <ExpandMoreIcon color="inherit" />
            )
          }
        />
      ) : null}
    </>
  )
}

export function StakeAccountList({
  Header,
  AccountList,
}: {
  Header: React.ReactNode
  AccountList: React.ReactNode
}) {
  const classes = useStakeAccountListStyles()

  return (
    <Box>
      <Box className={classes.stakeAccountHeader}>{Header}</Box>
      <List className={classes.stakeAccountList}>{AccountList}</List>
    </Box>
  )
}

type StakeParentCardLayoutProps = {
  accountCol: React.ReactNode
  balanceCol: React.ReactNode
  rewardsCol: React.ReactNode
  stakedCol: React.ReactNode
  inactiveCol?: React.ReactNode
  actionCol?: React.ReactNode
}

export function StakeParentCardLayout({
  accountCol,
  balanceCol,
  rewardsCol,
  stakedCol,
  inactiveCol,
  actionCol = null,
}: StakeParentCardLayoutProps) {
  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item xs={2}>
        <Aligner align={'left'}>{accountCol}</Aligner>
      </Grid>
      <Grid item xs={2}>
        <Aligner align={'right'}>{balanceCol}</Aligner>
      </Grid>
      <Grid item xs={2}>
        <Aligner align={'right'}>{rewardsCol}</Aligner>
      </Grid>
      <Grid item xs={2}>
        <Aligner align={'right'}>{stakedCol}</Aligner>
      </Grid>
      <Grid item xs={2}>
        <Aligner align={'right'}>{inactiveCol}</Aligner>
      </Grid>
      <Grid item xs={2}>
        <Aligner align={'right'}>{actionCol}</Aligner>
      </Grid>
    </Grid>
  )
}

const useStakeAccountListStyles = makeStyles((theme) => ({
  stakeAccountList: {
    padding: theme.spacing(0.5, 2),
    width: '100%',
  },
  stakeAccountHeader: {
    background: theme.palette.background.paper,
    padding: theme.spacing(0.5, 2),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}))

export const useStakeParentAccountRowStyles = makeStyles((theme) => ({
  headerWrapper: {
    marginBottom: theme.spacing(1),
  },
}))

export const useStakeAccountListItemLayoutStyles = makeStyles((theme) => ({
  // TODO: do this properly and generically
  textOverflowingContainer: {
    overflow: 'hidden',
    paddingLeft: theme.spacing(2),
  },
  firstCol: {
    paddingLeft: theme.spacing(2),
  },
  lastCol: {
    paddingRight: theme.spacing(2),
  },
}))
