import {KeyboardArrowDown as ArrowDownIcon} from '@mui/icons-material'
import type {TypographyProps} from '@mui/material'
import {
  Paper,
  Grid,
  Divider as MuiDivider,
  Box,
  Typography,
  styled,
} from '@mui/material'
import type BigNumber from 'bignumber.js'
import React from 'react'
import {useTranslation} from 'react-i18next'

import type {Blockchain, AccountInfo, TokenMetadata} from '../types'
import {useCryptoProviderTypeNames} from '../utils/translations'
import {useGetAccounts, isHwVendor} from '../wallet'
import {findAccountByAddress} from '../wallet/utils/common'

import {AssetIcon} from './AssetIcon'
import {FormattedAsset} from './formatting'

type FormattedDomainNameProps = {
  domain: string
  address: string
}

const FormattedDomainName = ({domain, address}: FormattedDomainNameProps) => (
  <FormattedAddress address={`${domain} (${address})`} />
)

type FormattedAccountProps = {
  account: AccountInfo
  showAddress?: boolean
}

function FormattedAccount({
  account,
  showAddress = true,
}: FormattedAccountProps) {
  const cryptoProviderTypeNames = useCryptoProviderTypeNames()

  return (
    <>
      <AccountName variant="body2">{account.name}</AccountName>
      {isHwVendor(account.cryptoProviderType) && (
        <CryptoProviderType>
          ({cryptoProviderTypeNames[account.cryptoProviderType]})
        </CryptoProviderType>
      )}
      {showAddress && <FormattedAddress address={account.address} />}
    </>
  )
}

type TransferCardSummaryProps = {
  blockchain: Blockchain
  amount?: BigNumber
  toAddress: string
  humanReadableToAddressName?: string
  fromAccount: AccountInfo
  tokenMetadata?: TokenMetadata
  tokensToSendCount?: number
  domainNameAddress?: string
}

export function TransferCardSummary({
  blockchain,
  amount,
  toAddress,
  humanReadableToAddressName,
  fromAccount,
  tokenMetadata,
  tokensToSendCount,
  domainNameAddress,
}: TransferCardSummaryProps) {
  const walletAccounts = useGetAccounts(blockchain)
  const {t} = useTranslation()

  const matchedToAccount = walletAccounts?.data
    ? findAccountByAddress(walletAccounts.data, toAddress, blockchain)
    : null

  const _AssetIcon = (
    <AssetIcon
      exactSize={24}
      blockchain={blockchain}
      tokenMetadata={tokenMetadata}
    />
  )

  const _FormattedAddress = domainNameAddress ? (
    <FormattedDomainName {...{domain: toAddress, address: domainNameAddress}} />
  ) : (
    <FormattedAddress address={toAddress} />
  )

  return (
    <Wrapper elevation={0}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Box display="flex" alignItems="center">
          {_AssetIcon}
          <FormattedAccount account={fromAccount} showAddress={false} />
        </Box>
        <Box textAlign="right">
          {amount && (
            <FormattedAsset
              amount={amount}
              blockchain={blockchain}
              tokenMetadata={tokenMetadata}
              isSensitiveInformation={false}
            />
          )}
          {!!tokensToSendCount && (
            <Typography textAlign="right">
              {t('token_count', {count: tokensToSendCount})}
            </Typography>
          )}
        </Box>
      </Grid>
      <DividerWrapper>
        <ArrowDownIcon />
        <Divider />
      </DividerWrapper>
      <Box display="flex">
        {_AssetIcon}
        {matchedToAccount ? (
          <FormattedAccount account={matchedToAccount} />
        ) : (
          <Box display="flex" flexDirection="column">
            {humanReadableToAddressName && (
              <Box mb={1}>
                <FormattedAddress address={humanReadableToAddressName} />
              </Box>
            )}
            {_FormattedAddress}
          </Box>
        )}
      </Box>
    </Wrapper>
  )
}

const AccountName = styled(Typography)(({theme}) => ({
  marginLeft: theme.spacing(2),
}))

const Divider = styled(MuiDivider)(({theme}) => ({
  flex: 1,
  marginLeft: theme.spacing(2),
}))

const CryptoProviderType = styled((props: TypographyProps) => (
  <Typography variant="body2" color="textSecondary" {...props} />
))(({theme}) => ({
  marginLeft: theme.spacing(1),
}))

type FormattedAddressProps = {
  address: string
}

const FormattedAddress = styled(
  ({address, ...props}: FormattedAddressProps & TypographyProps) => (
    <Typography variant="body2" color="textSecondary" {...props}>
      {address}
    </Typography>
  ),
)(({theme}) => ({
  marginLeft: theme.spacing(2),
  wordBreak: 'break-all',
  userSelect: 'all',
}))

const Wrapper = styled(Paper)(({theme}) => ({
  padding: theme.spacing(1),
  border: `1px solid ${theme.palette.divider}`,
  background: theme.palette.background.default,
  [theme.breakpoints.up('windowsOld')]: {
    padding: theme.spacing(1.5),
  },
  [theme.breakpoints.up('windowsZoomed')]: {
    padding: theme.spacing(2),
  },
}))

const DividerWrapper = styled('div')(({theme}) => ({
  padding: theme.spacing(0.5, 0),
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.up('windowsOld')]: {
    padding: theme.spacing(0.75, 0),
  },
  [theme.breakpoints.up('windowsZoomed')]: {
    padding: theme.spacing(1, 0),
  },
}))
