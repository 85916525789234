import {ExpandMore as ExpandMoreIcon} from '@mui/icons-material'
import {Box, Typography} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {
  Modal,
  ModalLayout,
  useModalSharedStyles,
  ModalHeader,
  ModalFooter,
  Button,
  TextButton,
} from '../../components'
import {getAppVersion} from '../../utils/appVersion'

import {newsData} from './news/data'

type NewsModalHeaderProps = {
  onClose: () => unknown
}

export const NewsModalHeader = ({onClose}: NewsModalHeaderProps) => {
  const {t} = useTranslation()

  return (
    <ModalHeader onClose={onClose} hasDivider>
      <Typography variant="h5">{t('NuFi News')}</Typography>
    </ModalHeader>
  )
}

type NewsModalFooterProps = {
  onClose: () => unknown
}

export function NewsModalFooter({onClose}: NewsModalFooterProps) {
  const {t} = useTranslation()

  return (
    <ModalFooter hasDivider>
      <Button
        fullWidth
        textTransform="none"
        variant="contained"
        color="primary"
        onClick={() => onClose()}
        type="submit"
        children={t('Close')}
        data-test-id="close-news-button"
      />
    </ModalFooter>
  )
}

type NewsArticleProps = {
  date: Date
  heading: string
  body: React.ReactNode
}

function NewsArticle({date, heading, body}: NewsArticleProps) {
  const classes = {...useModalSharedStyles(), ...useNewsModalStyles()}

  return (
    <Box className={clsx(classes.responsiveSpacing, classes.newsArticle)} p={1}>
      <Typography variant="h5">{heading}</Typography>
      <Typography color="text.secondary" mt={-1}>
        {date.toLocaleDateString()}
      </Typography>
      <Box fontSize="body2.fontSize" color="text.secondary" px={1}>
        {body}
      </Box>
    </Box>
  )
}

const compareAppVersions = (
  currentAppVersion: string,
  prevAppVersion: string,
) =>
  currentAppVersion.localeCompare(prevAppVersion, undefined, {
    numeric: true,
    sensitivity: 'base',
  }) // 0: equal, 1: currentAppVersion is bigger, -1: prevAppVersion is bigger

const isCurrentAppVersionNewer = (prevAppVersion?: string) =>
  prevAppVersion
    ? compareAppVersions(getAppVersion(), prevAppVersion) === 1
    : true

const isAppVersionBiggerOrEqual = (
  appVersion: string,
  comparedAppVersion: string,
) => compareAppVersions(appVersion, comparedAppVersion) !== -1

const getUnreadNewsData = (prevAppVersion?: string) =>
  prevAppVersion
    ? newsData.filter(({appVersion}) =>
        isAppVersionBiggerOrEqual(appVersion, prevAppVersion),
      )
    : newsData

const NEWS_APP_VERSION = 'newsAppVersion'
const getStoredNewsAppVersion = () =>
  window.localStorage.getItem(NEWS_APP_VERSION) || undefined

export const getHasUnreadNews = () =>
  isCurrentAppVersionNewer(getStoredNewsAppVersion())

export type NewsState = 'unread' | 'all'
const newsArticlesPerStep = 3

export function NewsModal({
  onClose,
  show,
}: {
  onClose: () => void
  show: NewsState
}) {
  const classes = {...useNewsModalStyles(), ...useModalSharedStyles()}
  const {t} = useTranslation()
  const [amountToShow, setAmountToShow] = useState(newsArticlesPerStep)
  const newsArticles =
    show === 'unread' ? getUnreadNewsData(getStoredNewsAppVersion()) : newsData
  const articlesToShow = newsArticles.slice(0, amountToShow)

  const closeShowNewsBanner = () => {
    if (show === 'unread') {
      window.localStorage.setItem(NEWS_APP_VERSION, getAppVersion())
    }
    onClose()
  }

  const onShowMore = () => {
    const totalLength = newsArticles.length
    const newLength = amountToShow + newsArticlesPerStep
    setAmountToShow(newLength > totalLength ? totalLength : newLength)
  }

  return (
    <>
      {articlesToShow?.length ? (
        <Modal
          onClose={closeShowNewsBanner}
          variant="centered"
          className={classes.modal}
          sx={{zIndex: (theme) => theme.zIndex.modal + 1}}
        >
          <ModalLayout
            header={<NewsModalHeader onClose={closeShowNewsBanner} />}
            body={
              <Box p={2} className={classes.responsiveSpacing}>
                {articlesToShow.map((article) => (
                  <NewsArticle key={article.appVersion} {...article} />
                ))}
                {newsArticles.length > articlesToShow.length && (
                  <TextButton
                    color="primary"
                    label={t('Show more')}
                    onClick={() => onShowMore()}
                    Icon={<ExpandMoreIcon />}
                  />
                )}
              </Box>
            }
            footer={<NewsModalFooter onClose={closeShowNewsBanner} />}
          />
        </Modal>
      ) : null}
    </>
  )
}

const useNewsModalStyles = makeStyles((theme) => ({
  modal: {
    minWidth: 600,
    maxWidth: 900,
  },
  newsArticle: {
    '&:not(:last-child)': {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    '& a': {
      textDecoration: 'none',
      color: theme.palette.primary.main,
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
}))
