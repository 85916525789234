import {request, safeAssertUnreachable} from '@nufi/frontend-common'

import config from 'src/config'
import {notNullish} from 'src/utils/helpers'

import type {RampAsset, RampService} from '../../domain'

import type {MoonpayCurrency} from './constants'
import type {GetTokenId} from './helpers'
import {transformCryptoCurrency, transformFiatCurrency} from './helpers'

export class MoonpayService implements RampService {
  constructor(
    private deps: {
      apiKey: string
      getTokenId: GetTokenId
    },
  ) {}

  async getAssets() {
    const params = new URLSearchParams({apiKey: this.deps.apiKey})
    const currencies = await request<MoonpayCurrency[]>({
      url: `${MoonpayService.BASE_URL}/v3/currencies?${params}`,
      headers: {accept: 'application/json'},
    })
    const assets = (
      await Promise.all(
        currencies.map<Promise<RampAsset | null>>(async (currency) => {
          switch (currency.type) {
            case 'crypto':
              return await transformCryptoCurrency(
                currency,
                this.isSandbox(),
                this.deps.getTokenId,
              )
            case 'fiat':
              return transformFiatCurrency(currency)
            default:
              return safeAssertUnreachable(currency)
          }
        }),
      )
    ).filter(notNullish)

    return this.isSandbox()
      ? assets.filter((asset) => asset.supportsSandbox)
      : assets
  }

  async signUrl(urlToSign: string) {
    const {signature} = await request<{signedUrl: string; signature: string}>({
      url: `${config.backendUrl}/api/v1/moonpay/signUrl`,
      method: 'POST',
      body: JSON.stringify({originalUrl: urlToSign}),
      headers: {'Content-Type': 'application/json'},
      timeout: 10_000,
    })
    return {signature}
  }

  async getBlockchainTxId(txId: string): Promise<string | null> {
    const params = new URLSearchParams({apiKey: this.deps.apiKey})
    const response = await request<{cryptoTransactionId: string | null}>({
      url: `${MoonpayService.BASE_URL}/v1/transactions/${txId}?${params}`,
      headers: {accept: 'application/json'},
    })
    return response.cryptoTransactionId
  }

  /**
   * Moonpay makes testnet transactions in sandbox:
   * - Sepolia Testnet for ETH (Ethereum)
   * - Sepolia Testnet for ERC-20 tokens
   * - Testnet3 for Bitcoin
   * - BitcoinCash Testnet for Bitcoin Cash
   * - Binance Testnet for Binance Coin
   * - Jungle2.0 Testnet for EOS
   * - Litecoin Testnet for Litecoin
   * - Stellar Testnet for Stellar
   * @see https://dev.moonpay.com/docs/sandbox-testing#how-to-use-the-sandbox-widget
   */
  isSandbox() {
    return this.deps.apiKey.includes('_test_')
  }

  async hasWidgetSupport(parentUrl: string) {
    const response = await request<{supported: boolean}>({
      method: 'GET',
      url: `${config.backendUrl}/api/v1/moonpay/hasWidgetSupport?parentUrl=${encodeURIComponent(parentUrl)}`,
      headers: {accept: 'application/json'},
    })
    return response.supported
  }

  private static readonly BASE_URL = 'https://api.moonpay.com'
}
