import {Sentry} from '@nufi/frontend-common'
import {useState, useEffect, useRef, useCallback} from 'react'

import queryClient from 'src/queryClient'

type UsePaginateDataParams<T> = {
  data: T | undefined
  pageSize?: number
  resetOnDataChange?: boolean
}

type PaginateDataResult<T> = {
  hasNextPage: boolean | undefined
  onNextPage: () => void
  page: number
  reset: () => void
  pageData: T | undefined
}

/**
Used to paginate large set of data when no paginated endpoint exist on the server.
Useful if rendering all the items at once could cause the app to crash.
*/

export function usePaginateData<
  T extends Array<unknown>, // Using `T extends Array<unknown>` instead of just `T` to accept union of arrays.
>({
  data,
  pageSize = 10,
  resetOnDataChange = true,
}: UsePaginateDataParams<T>): PaginateDataResult<T> {
  const [page, setPage] = useState(1)

  const hasNextPage =
    data !== undefined ? pageSize * page < data.length : undefined

  const onNextPage = () => {
    setPage((page) => page + 1)
  }

  const reset = () => {
    page !== 1 && setPage(1)
  }

  // reset page to "1" on data change
  useEffect(() => {
    resetOnDataChange && reset()
  }, [data])

  const pageData = (data ? data.slice(0, pageSize * page) : undefined) as
    | T
    | undefined

  return {
    hasNextPage,
    onNextPage,
    page,
    reset,
    pageData,
  }
}

export type QueryResultStateProperties = {
  isLoading: boolean
  isFetching: boolean
  dataUpdatedAt: number
  error: unknown
  isSuccess: boolean
}

export function mergeQueryResultsState(
  ...queryResults: QueryResultStateProperties[]
): QueryResultStateProperties {
  const isLoading = queryResults.some((q) => q.isLoading)
  const isFetching = queryResults.some((q) => q.isFetching)
  const isSuccess = queryResults.every((q) => q.isSuccess)
  const dataUpdatedAt = Math.max(...queryResults.map((q) => q.dataUpdatedAt))
  const error = queryResults.find((q) => q.error)
  return {isLoading, isFetching, isSuccess, dataUpdatedAt, error}
}

/**
 * Ensures that supplied queries `data` will not be `undefined`
 * once it was set (unless the component using this hook unmounts).
 *
 * This is handy inside "action-modals" (e.g. transactions), as once some mutation
 * runs, the mutation can invalidate a key of the query rendered higher in the component
 * hierarchy before the corresponding `<QueryGuard />` and this mutation definition.
 * If this query uses a composite-key and the key will change, this will cause
 * the query to return `undefined` (until the new data are fetched), and the `<QueryGuard />`
 * will unmount the component where the mutation is defined, resulting in the loss of mutation
 * state (e.g. "txHash" required for "Success screen"), thus some assertions failing and app crashing.
 *
 * To tackle the issue we can either:
 *   * move mutations before such `<QueryGuard />`:
 *    (would solve the problem of app crashing but `<QueryGuard />` can render unnecessary spinner for a while,
 *    that may not be desired. Also results in extra props passing.)
 *
 *   * use `keepPreviousData` option of `useQuery`:
 *     (this can possibly cause errors when joining data as not all data would be up-to-date,
 *     but we plan to further investigate and experiment with this option)
 *
 *   * make sure that the submission screen does not depend on the data that may change:
 *     (would require bigger refactor, e.g. by having <Formik /> per screen with input fields,
 *     and managing extra state/context to synchronize data, though this option can be viable for future
 *     and have many benefits, it can not be implemented quickly)
 *
 *   * SUGGESTED: use this hook and wrap all query hooks used within the "action-modal". By wrapping
 *     all hooks, the component need 0 knowledge, about whether the query keys can change in unwanted way.
 */
export function useLastDefinedData<
  TData,
  TQuery extends {data: TData | undefined},
  TQueriesMap extends Record<string, TQuery>,
>(queries: TQueriesMap) {
  const _queryData = useRef<Record<string, TData | undefined>>({})

  // No need to do this in `useEffect` as we are storing `_queryData` in `useRef`
  // and therefore this does not lead to re-render
  Object.keys(queries).forEach((key) => {
    _queryData.current[key] =
      queries[key]!.data !== undefined
        ? queries[key]!.data
        : _queryData.current[key]
  })

  const result: TQueriesMap = Object.keys(queries).reduce((acc, _key) => {
    const key = _key as keyof TQueriesMap
    acc[key] = {...queries[key], data: _queryData.current[_key]}
    return acc
  }, {} as TQueriesMap)
  return result
}

/**
 * Simple custom `useQuery` like function that only has
 * `error`, `isLoading`, `data` and `fetch` properties.
 * Useful for situations when you want to always re-query the
 * data on component re-mount and do it manually as a response
 * to some user action or initial mount.
 * The hook does not use global cache, so the data are lost on unmount.
 */
export function useVolatileImperativeQuery<TArgs, TData>(
  fn: (args: TArgs) => Promise<TData>,
) {
  const [state, setState] = useState<{
    error: unknown
    isLoading: boolean
    data: TData | undefined
  }>({
    error: undefined,
    isLoading: false,
    data: undefined,
  })

  const fetch = useCallback(
    async (args: TArgs): Promise<TData | undefined> => {
      setState((state) => ({...state, isLoading: true, error: undefined}))

      try {
        const data = await fn(args)
        setState((state) => ({...state, isLoading: false, data}))
        return data
      } catch (error) {
        Sentry.captureException(error)
        setState((state) => ({...state, isLoading: false, error}))
        return undefined
      }
    },
    [setState],
  )

  return {...state, fetch}
}

/**
 * react-query v5 migration workaround:
 * setting {cancelRefetch: false} to restore behavior from RQ3
 * because otherwise some recursively triggered re-fetches
 * get cancelled which results in unwanted query errors and broken data
 * (e.g. token metadata query can be cancelled by recursive queries from total balance)
 * see https://tanstack.com/query/v5/docs/framework/react/guides/migrating-to-react-query-4#consistent-behavior-for-cancelrefetch
 */
export const invalidateAllQueryClientQueries = async () =>
  await queryClient.invalidateQueries({}, {cancelRefetch: false})
