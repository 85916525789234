import React from 'react'

import {QueryGuard} from 'src/components'
import type {AccountInfo, Blockchain} from 'src/types'

import {useRampFlowConfig} from '../state/rampFlowConfig'

type WithAccountsQueryProps = {
  blockchain: Blockchain
  children: (accounts: AccountInfo[]) => JSX.Element
}

export const WithAccountsQuery = ({
  blockchain,
  children,
}: WithAccountsQueryProps) => {
  const {vm} = useRampFlowConfig()
  return (
    <QueryGuard loadingVariant="centered" {...vm.useGetAccounts(blockchain)}>
      {children}
    </QueryGuard>
  )
}
