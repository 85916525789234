import type {SwapAsset} from 'common'
import {useTranslation} from 'react-i18next'
import * as yup from 'yup'

import type {AddressType} from '../../../../types'

import type {DetailsScreenViewModel} from './viewModel'

export type DetailsValues = {
  amount: string
  toAddress: string
  fromAddress: string
  fromAsset: SwapAsset
  toAsset: SwapAsset
  fromAddressType: AddressType
  toAddressType: AddressType
  extraId: string | null
}

export const useDetailsSchema = ({
  validateExchangeAddress,
}: {
  validateExchangeAddress: DetailsScreenViewModel['validateExchangeAddress']
}) => {
  const {t} = useTranslation()
  const fromAddressTypeKey: keyof DetailsValues = 'fromAddressType'

  return {
    amount: yup
      .number()
      .typeError(t('Invalid number'))
      .positive(t('Amount must be a positive number.'))
      .required(t('Amount is required.')),
    fromAddress: yup
      .string()
      .when(fromAddressTypeKey, ([fromAddressType]: unknown[]) => {
        const base = yup.string()
        if ((fromAddressType as AddressType) === 'internal') {
          return base.required(t('Account is required.'))
        }
        return base
      }),
    toAddress: yup
      .string()
      .test('toAddress-is-valid', async function (toAddress) {
        const fields = this.parent as DetailsValues
        if (toAddress) {
          const {result} = await validateExchangeAddress(
            fields.toAsset,
            toAddress,
          )
          if (!result) {
            return this.createError({message: t('Invalid address')})
          } else {
            return true
          }
        } else {
          if (fields.toAddressType === 'internal') {
            return this.createError({message: t('Account is required.')})
          } else {
            return this.createError({message: t('Address is required.')})
          }
        }
      }),
    accountId: yup.string(),
    fromAsset: yup.mixed<SwapAsset>(),
    toAsset: yup.mixed<SwapAsset>(),
    fromAddressType: yup.string(),
    toAddressType: yup.string(),
    extraId: yup.string().nullable(),
  }
}
