import {useEffect, useRef} from 'react'

import type {LocalProfileId, ProfileName, ProfilePassword} from 'src/appStorage'
import {
  useCreateAndInitLocalProfile,
  useInitLocalProfile,
  useLocalProfile,
} from 'src/appStorage'
import config from 'src/config'
import {getAvailableBlockchains} from 'src/features/availableBlockchains/application'
import {useAuthStore} from 'src/store/auth'
import {broadcastLoginToOtherTabs} from 'src/utils/loginBroadcast'
import {getStaticMutationProperties} from 'src/utils/mutation-utils'
import type {Mnemonic} from 'src/wallet'

import type {Web3AuthUserInfo} from '../domain'

export const useSetRegularLoggedIn = () =>
  useAuthStore((state) => state.setRegularLoggedIn)
export const useSetWeb3AuthLoggedIn = () =>
  useAuthStore((state) => state.setWeb3AuthLoggedIn)

export const useLoginToProfile = (options?: {skipBroadcastLogin?: boolean}) => {
  const setRegularLoggedIn = useSetRegularLoggedIn()
  const setWeb3AuthLoggedIn = useSetWeb3AuthLoggedIn()
  const initProfile = useInitLocalProfile()

  const fn = async (params: {
    profileId: LocalProfileId
    password: ProfilePassword
    web3AuthUserInfo?: Web3AuthUserInfo
  }) => {
    await initProfile.mutateAsync({
      profileId: params.profileId,
      password: params.password,
      loginType: params.web3AuthUserInfo != null ? 'web3Auth' : 'password',
    })
    if (params.web3AuthUserInfo != null) {
      setWeb3AuthLoggedIn(params.web3AuthUserInfo)
    } else {
      setRegularLoggedIn()
    }
    if (!options?.skipBroadcastLogin) {
      await broadcastLoginToOtherTabs(params.profileId)
    }
  }

  return {
    ...getStaticMutationProperties(initProfile),
    onLogin: fn,
  }
}

export const useAutoLogin = () => {
  const setLoggedIn = useSetRegularLoggedIn()
  const login = useLoginToProfile()
  const didRun = useRef(false)
  const createProfile = useCreateAndInitLocalProfile()
  const localProfileQuery = useLocalProfile(config.demoMnemonic as Mnemonic)

  useEffect(() => {
    // Note: await is not allowed directly in `useEffect`
    const fn = async () => {
      if (localProfileQuery.isLoading) return

      if (didRun.current) return
      didRun.current = true

      if (localProfileQuery.data != null) {
        await login
          .onLogin({
            profileId: localProfileQuery.data.localProfileId,
            password: config.demoPassword as ProfilePassword,
          })
          .catch((err) => {
            console.error(err) // eslint-disable-line no-console
            throw new Error('Could not auto-login!')
          })
      } else {
        await createProfile.mutateAsync({
          profileName: 'dev-wallet' as ProfileName,
          password: config.demoPassword as ProfilePassword,
          mnemonic: config.demoMnemonic as Mnemonic,
          isHwUser: false,
          isMnemonicActivated: true,
          loginType: 'password',
          enabledBlockchains: getAvailableBlockchains(),
        })
        setLoggedIn()
      }
    }
    fn()
  }, [localProfileQuery.data])

  return {
    isPending: login.isPending || createProfile.isPending,
    error: login.error || createProfile.error,
    data: null,
  }
}
