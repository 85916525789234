import create from 'zustand'

import logger from './logger'

const failedProfileSyncStatuses = [
  'failed', // General failure - server error, exceptions, ...
  'disabled', // Sync disabled via feature flag.
  'paused', // Sync paused due to version mismatch.
] as const

export type FailedProfileSyncStatus = (typeof failedProfileSyncStatuses)[number]

export type ProfileSyncStatus =
  | 'not-initialized'
  | 'success'
  | FailedProfileSyncStatus

export type ProfileSyncState = {
  lastSyncStatus: ProfileSyncStatus
  lastSyncAt: number | null
  lastSyncAttemptAt: number | null
  init: (lastSync: number | null) => void
  setSuccessfulSync: (syncAt: number) => void
  setFailedSync: (
    syncAttemptAt: number,
    status?: FailedProfileSyncStatus,
  ) => void
}

export const useProfileSyncState = create<ProfileSyncState>(
  logger<ProfileSyncState>('ProfileSyncState:')((set) => ({
    lastSyncStatus: 'not-initialized',
    lastSyncAt: null,
    lastSyncAttemptAt: null,
    init: (lastSyncAt) =>
      set({
        lastSyncAt,
      }),
    setSuccessfulSync: (syncAt: number) =>
      set({
        lastSyncStatus: 'success',
        lastSyncAt: syncAt,
        lastSyncAttemptAt: syncAt,
      }),
    setFailedSync: (
      syncAttemptAt: number,
      status: FailedProfileSyncStatus = 'failed',
    ) =>
      set({
        lastSyncStatus: status,
        lastSyncAttemptAt: syncAttemptAt,
      }),
  })),
)

export type UseProfileSyncState = typeof useProfileSyncState
