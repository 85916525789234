/* eslint-disable @typescript-eslint/explicit-function-return-type */

import type {
  CardanoPaymentAddress,
  CardanoAccountInfo,
} from '@nufi/wallet-cardano'
import {calculateAdaSentOut} from '@nufi/wallet-cardano'
import type {AccountId} from '@nufi/wallet-common'
import {useQuery} from '@tanstack/react-query'

import queryClient from '../../../../queryClient'
import {assert} from '../../../../utils/assertion'
import {discoverHwAccounts, getAccounts} from '../../../public/accounts'
import {
  cachedGetAccounts as cachedGetGenericAccounts,
  useGetAccounts as useGetGenericAccounts,
} from '../../../public/queries/accounts'
import type {AllAvailableAccountsResponse} from '../../../types'
import {
  DEFAULT_DISCOVERY_QUERY_OPTIONS,
  minutesToMilliseconds,
} from '../../../utils/common'
import {getCardano} from '../../cardanoManagers'

import {
  getAccountInfoForDiscoveryResponse,
  QUERY_KEY_PREFIX as P,
} from './utils'

export const coreQueryKeys = {
  accounts: getAccounts.__key(P),
  discoverHotAccounts: [P, 'discoverHotAccounts'],
  discoverLedgerAccounts: discoverHwAccounts.__key(P, 'ledger'),
  discoverTrezorAccounts: discoverHwAccounts.__key(P, 'trezor'),
  discoverGridPlusAccounts: discoverHwAccounts.__key(P, 'gridPlus'),
  maxSendableAmount: (id: AccountId, addressTo?: string) => [
    P,
    'maxSendableAmount',
    id,
    addressTo,
  ],
} as const

//
// __CACHED__ QUERIES
//

export function useDiscoverAllHotAccounts() {
  return useQuery({
    queryKey: coreQueryKeys.discoverHotAccounts,
    queryFn: async (): Promise<AllAvailableAccountsResponse> => {
      const accounts = getCardano().accountsStore.getAllAccounts()
      const response =
        await getCardano().wallet.discoverAllHotAccounts(accounts)
      return getAccountInfoForDiscoveryResponse(response)
    },
    ...DEFAULT_DISCOVERY_QUERY_OPTIONS,
  })
}

export function useDiscoverLedgerAccounts(enabled: boolean) {
  return useQuery({
    queryKey: coreQueryKeys.discoverLedgerAccounts,
    queryFn: async (): Promise<AllAvailableAccountsResponse> => {
      const accounts = getCardano().accountsStore.getAllAccounts()
      const response =
        await getCardano().wallet.discoverLedgerAccounts(accounts)
      return getAccountInfoForDiscoveryResponse(response)
    },
    ...DEFAULT_DISCOVERY_QUERY_OPTIONS,
    enabled,
  })
}

export function useDiscoverTrezorAccounts(enabled: boolean) {
  return useQuery({
    queryKey: coreQueryKeys.discoverTrezorAccounts,
    queryFn: async (): Promise<AllAvailableAccountsResponse> => {
      const accounts = getCardano().accountsStore.getAllAccounts()
      const response =
        await getCardano().wallet.discoverTrezorAccounts(accounts)
      return getAccountInfoForDiscoveryResponse(response)
    },
    ...DEFAULT_DISCOVERY_QUERY_OPTIONS,
    enabled,
  })
}

//
// __COMPUTED__ QUERIES
//

export const cachedGetAccounts = async (): Promise<CardanoAccountInfo[]> => {
  return cachedGetGenericAccounts<CardanoAccountInfo>('cardano')
}

export const useGetAccounts = (enabled = true) => {
  return useGetGenericAccounts<CardanoAccountInfo>('cardano', enabled)
}

export type MaxSendableCardanoAmountArgs =
  | {
      accountInfo: CardanoAccountInfo
      addressTo?: string
    }
  | undefined

async function getMaxSendableCardanoAmount(args: MaxSendableCardanoAmountArgs) {
  const {addressTo = undefined, accountInfo = undefined} =
    args != null ? args : {}
  assert(!!accountInfo)
  const txPlan = await getCardano().accountManager.getTransferMaxAdaTxPlan(
    getCardano().accountsStore.getAccount(accountInfo.id),
    // TODO: (EXCHANGE REFACTOR)
    // Remove changelly hardcoded address from here! It should only be concern of the
    // "exchange" part of the codebase.
    (addressTo ||
      'DdzFFzCqrht74Py82SWJVFYEVEwY6NkHb1q72iKJDAR4RMojWi6V49ZhK8hjf1ZuDYxGx4qABMUuzxgQeZZ2DnJo4Sg2hptKm77EviWS') as CardanoPaymentAddress,
    accountInfo,
  )
  return {maxAmount: calculateAdaSentOut(txPlan), fee: txPlan.fee}
}
getMaxSendableCardanoAmount.__options = {
  staleTime: minutesToMilliseconds(1),
  refetchOnWindowFocus: false,
}

export function useGetMaxSendableCardanoAmount(
  args: MaxSendableCardanoAmountArgs,
  enabled = true,
) {
  const {addressTo = undefined, accountInfo = undefined} =
    args != null ? args : {}
  return useQuery({
    queryKey: coreQueryKeys.maxSendableAmount(
      accountInfo?.id || ('' as AccountId),
      addressTo || '',
    ),
    queryFn: () => getMaxSendableCardanoAmount(args),
    ...getMaxSendableCardanoAmount.__options,
    enabled,
  })
}

export async function getMaxSendableCardanoAmountImperative(
  accountInfo: CardanoAccountInfo,
) {
  return await queryClient.fetchQuery({
    queryKey: coreQueryKeys.maxSendableAmount(accountInfo.id),
    queryFn: () => getMaxSendableCardanoAmount({accountInfo}),
    ...getMaxSendableCardanoAmount.__options,
  })
}
