import type {
  SingleKeyAsyncStorage,
  SingleKeySyncStorage,
} from 'src/features/storage/domain'

export const createSingleKeySyncStorage = <T>(
  storageKey: string,
  options?: StorageOptions<T>,
): SingleKeySyncStorage<T> => {
  const engine = options?.engine || window.localStorage
  const validate = options?.validate || (() => true)

  const get = () => {
    const item = engine.getItem(storageKey)
    if (item == null) return null
    try {
      const res = JSON.parse(item)
      if (!validate(res)) {
        engine.removeItem(storageKey)
        return null
      }
      return res
    } catch (e) {
      engine.removeItem(storageKey)
      return {}
    }
  }

  const set = (v: T | null) => {
    engine.setItem(storageKey, JSON.stringify(v))
  }

  const remove = () => {
    engine.removeItem(storageKey)
  }

  return {get, set, remove}
}

export const createSingleKeyAsyncStorage = <T>(
  storageKey: string,
  options?: StorageOptions<T>,
): SingleKeyAsyncStorage<T> =>
  createSingleKeySyncStorage<T>(
    storageKey,
    options,
    // Sync methods can be used in async fashion, but TS does not know that
  ) as unknown as SingleKeyAsyncStorage<T>

type StorageOptions<T> = {
  validate?: (v: T) => boolean
  engine?: Storage
}
