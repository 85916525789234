import {Sentry} from '@nufi/frontend-common'
import {endOfDay, startOfDay} from 'date-fns'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'

import type {CsvExportBlockchain} from '../../../../../blockchainTypes'
import {FullScreenLoading} from '../../../../../components'
import type {AccountId, AccountInfo} from '../../../../../types'

import {simulateDownload} from './ExportHistoryUtils'
import {useCSVFileConstants} from './utils/common'
import {createCSVObject} from './utils/generic'

type ExportHistorySubmitScreenProps = {
  blockchainToAccountIds: Record<CsvExportBlockchain, AccountId[]>
  setLoadingCSV: (isLoadingCsv: boolean) => void
  setCSVError: (csvError: boolean) => void
  startDate: Date
  endDate: Date
  accounts: AccountInfo[]
}

export function ExportHistorySubmitScreen({
  blockchainToAccountIds,
  startDate,
  endDate,
  setLoadingCSV,
  setCSVError,
  accounts,
}: ExportHistorySubmitScreenProps) {
  const {t} = useTranslation()
  const csvConstants = useCSVFileConstants()
  useEffect(() => {
    const fn = async () => {
      try {
        setCSVError(false)
        const {filename, dataURI} = await createCSVObject(
          csvConstants,
          blockchainToAccountIds,
          startOfDay(startDate),
          endOfDay(endDate),
          accounts,
        )
        simulateDownload(dataURI, filename)
      } catch (e) {
        Sentry.captureException(e)
        setCSVError(true)
      }
      setLoadingCSV(false)
    }
    fn()
  }, [])
  return (
    <FullScreenLoading
      description={t('Please wait, this might take a while.')}
    />
  )
}
