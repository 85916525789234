/* eslint-disable @typescript-eslint/explicit-function-return-type */

import type {
  CardanoToken,
  CardanoAddress,
  CardanoRewardDistribution,
  CardanoTxHistoryEntry,
  CardanoAccountInfo,
  CardanoStakingHistoryEntry,
} from '@nufi/wallet-cardano'
import {createIsValidCardanoPaymentAddress} from '@nufi/wallet-cardano'
import type {AccountName} from '@nufi/wallet-common'

import {getCardano} from '../cardanoManagers'

import {cachedGetAccountStakeHistory} from './queries/staking'

type CSVCardanoExtraData = {
  accountName: AccountName
  address: CardanoAddress
}

export type CSVCardanoRewardDistribution = CardanoRewardDistribution &
  CSVCardanoExtraData

export type CSVCardanoTxHistoryEntry = CardanoTxHistoryEntry &
  CSVCardanoExtraData

export type CSVCardanoStakingHistoryEntry = CardanoStakingHistoryEntry &
  CSVCardanoExtraData

export function getTransactionHistory(accounts: CardanoAccountInfo[]) {
  return Promise.all(
    accounts.map(async (account) => {
      const history =
        await getCardano().accountManager.getTransactionHistory(account)
      return history.map((tx) => ({
        ...tx,
        accountName: account.name,
        address: account.address,
      }))
    }),
  )
}

export function getAccountsStakeHistory(accounts: CardanoAccountInfo[]) {
  return Promise.all(
    accounts.map(async ({id, address}) => {
      const history = await cachedGetAccountStakeHistory(id)
      return history.map((tx) => {
        return {
          ...tx,
          address,
        }
      })
    }),
  )
}

export function getTokensMetadata(cardanoTokens: CardanoToken[]) {
  return getCardano().wallet.getTokenMetadata(cardanoTokens)
}

export const isValidCardanoPaymentAddress = createIsValidCardanoPaymentAddress(
  () => getCardano(),
)
