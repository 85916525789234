import {Box} from '@mui/material'
import {isBlockchainSubset} from '@nufi/wallet-common'
import type {SwapAsset} from 'common'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {BatchQueryGuard, InlineError} from 'src/components'
import {getExchangeAssetDetailOrFallback} from 'src/features/exchange/application'
import type {ExchangeAssetsDetails} from 'src/features/exchange/domain'
import type {BaseQuery} from 'src/utils/query-utils'

import {exchangeBlockchains} from '../../../blockchainTypes'
import type {ExchangeBlockchain} from '../../../blockchainTypes'
import type {AccountInfo, useGetAccounts} from '../../../wallet'
import {useExchangeConf} from '../ExchangeConfContext'
import type {AssetTokenDataQuery} from '../screens/common'
import type {SwapTokenData} from '../types'

type ViewModel = {
  useGetAccounts: BaseQuery<typeof useGetAccounts>
}

export type WithExchangeAssetsQueriesProps = {
  fromAsset: SwapAsset | null
  toAsset: SwapAsset | null
} & Pick<WithExchangeAssetQueryProps, 'vm' | 'exchangeAssetsDetails'> &
  (
    | {
        children: (data: {
          to: ExchangeAssetQueries
          from: ExchangeAssetQueries
        }) => React.ReactNode
        childrenPropsType: 'queries'
      }
    | {
        children: (data: {
          to: ExchangeAssetData
          from: ExchangeAssetData
        }) => React.ReactNode
        childrenPropsType: 'guardedData'
      }
  )

/**
 * Applies `WithExchangeAssetData` to from/to exchange asset, to fetch
 * both these data more conveniently in cases both of them are needed.
 */
export function WithExchangeAssetsQueries({
  fromAsset,
  exchangeAssetsDetails,
  toAsset,
  vm,
  ...rest
}: WithExchangeAssetsQueriesProps) {
  const commonProps = {vm, exchangeAssetsDetails}
  return (
    <WithExchangeAssetQuery
      asset={fromAsset}
      {...commonProps}
      childrenPropsType={rest.childrenPropsType}
    >
      {(_fromData: ExchangeAssetQueries | ExchangeAssetData) => (
        <WithExchangeAssetQuery
          asset={toAsset}
          {...commonProps}
          childrenPropsType={rest.childrenPropsType}
        >
          {(_toData: ExchangeAssetQueries | ExchangeAssetData) => {
            if (rest.childrenPropsType === 'guardedData') {
              const toData = _toData as ExchangeAssetData
              const fromData = _fromData as ExchangeAssetData

              return rest.children({
                from: fromData,
                to: toData,
              })
            }
            const toData = _toData as ExchangeAssetQueries
            const fromData = _fromData as ExchangeAssetQueries

            return rest.children({
              from: fromData,
              to: toData,
            })
          }}
        </WithExchangeAssetQuery>
      )}
    </WithExchangeAssetQuery>
  )
}

export type ExchangeAssetData = {
  accounts: AccountInfo[]
  token: SwapTokenData | null
  internalBlockchain: ExchangeBlockchain | null
}

export type ExchangeAssetQueries = {
  accountsQuery: UseAccountsResult
  tokenQuery: AssetTokenDataQuery
  internalBlockchain: ExchangeBlockchain | null
}

export type WithExchangeAssetQueryProps = {
  asset: SwapAsset | null
  exchangeAssetsDetails: ExchangeAssetsDetails
  vm: ViewModel
} & (
  | {
      children: (data: ExchangeAssetData) => React.ReactNode
      childrenPropsType: 'guardedData'
    }
  | {
      children: (data: ExchangeAssetQueries) => React.ReactNode
      childrenPropsType: 'queries'
    }
)

/**
 * This component is used to retrieve data relevant for a chosen exchange asset.
 *
 * The data for internal accounts and token info is either retrieved in the form of query
 * structure or in the form of query result.
 * That is because this gives consumers more freedom in terms how they wish to handle
 * error and loading states, e.g. still rendering some UI when data for some asset
 * can not be loaded.
 * Alternative would be to only return query, but this would force all consumers to
 * wrap the result into some query guard, which would lead to a lot of repetition.
 *
 * The queries are accessed via this component, and not directly via hooks,
 * because we need to handle scenarios like unsupported blockchain. As the underlying
 * hooks are not designed to support such cases, and we can not call hooks conditionally,
 * we are wrapping them inside components, to bypass the rules of hooks.
 */
export function WithExchangeAssetQuery({
  exchangeAssetsDetails,
  asset,
  vm,
  ...rest
}: WithExchangeAssetQueryProps) {
  const {exchangeConf} = useExchangeConf()
  const internalBlockchain = getInternalBlockchainFromAsset(
    exchangeAssetsDetails,
    asset,
  )
  const WithGetAssetTokenDataQuery = internalBlockchain
    ? exchangeConf[internalBlockchain].WithGetAssetTokenData
    : WithGetEmptyToAssetTokenData
  return (
    <WithGetAccountsQuery blockchain={internalBlockchain} {...{vm}}>
      {(_accountsQuery) => (
        <WithGetAssetTokenDataQuery
          blockchain={internalBlockchain}
          asset={asset}
          exchangeAssetsDetails={exchangeAssetsDetails}
        >
          {(_tokenQuery) => {
            const emptyAccountsQuery = {isLoading: false, error: null, data: []}
            const emptyTokensQuery = {isLoading: false, error: null, data: null}

            const accountsQuery = _accountsQuery || emptyAccountsQuery
            const tokenQuery = _tokenQuery || emptyTokensQuery

            if (rest.childrenPropsType === 'queries') {
              return rest.children({
                accountsQuery,
                tokenQuery,
                internalBlockchain,
              }) as React.JSX.Element
            }

            return (
              <BatchQueryGuard
                ErrorElement={<ExchangeAssetDataError />}
                loadingVariant="centered"
                queries={{
                  accounts: {
                    ...accountsQuery,
                    data: accountsQuery.data ?? undefined,
                  },
                  token: tokenQuery,
                }}
              >
                {({accounts, token}) =>
                  rest.children({
                    accounts,
                    token,
                    internalBlockchain,
                  }) as React.JSX.Element
                }
              </BatchQueryGuard>
            )
          }}
        </WithGetAssetTokenDataQuery>
      )}
    </WithGetAccountsQuery>
  )
}

type UseAccountsResult = ReturnType<ViewModel['useGetAccounts']>

type _WithGetAccountsQueryProps = {
  children: (injected: UseAccountsResult) => React.ReactElement
  blockchain: ExchangeBlockchain
  vm: ViewModel
}

type WithGetAccountsQueryProps = {
  children: (injected: UseAccountsResult | null) => React.ReactElement
  blockchain: ExchangeBlockchain | null
  vm: ViewModel
}

// Allows calling useGetAccounts conditionally as it does not accept
// nullable blockchain.
function WithGetAccountsQuery({
  children,
  blockchain,
  vm,
}: WithGetAccountsQueryProps) {
  if (blockchain == null) return children(null)
  return <_WithGetAccountsQuery {...{blockchain, children, vm}} />
}

function _WithGetAccountsQuery({
  children,
  blockchain,
  vm,
}: _WithGetAccountsQueryProps) {
  const accountsQuery = vm.useGetAccounts(blockchain)
  return children(accountsQuery)
}

function WithGetEmptyToAssetTokenData({
  children,
}: {
  children: (injected: AssetTokenDataQuery | null) => React.ReactElement
}) {
  return children(null)
}

// EXCHANGE_REFACTOR
// TODO: relocate me when introducing proper `domain` folder.
export const getInternalBlockchainFromAsset = (
  exchangeAssetsDetails: ExchangeAssetsDetails,
  asset: SwapAsset | null,
) => {
  if (!asset) return null

  // EXCHANGE_REFACTOR
  // TODO: revisit 'getExchangeAssetDetailOrFallback', the
  // "fallback" is confusing.
  const blockchain = getExchangeAssetDetailOrFallback(
    asset,
    exchangeAssetsDetails,
  ).blockchain as ExchangeBlockchain | null
  return blockchain && isBlockchainSubset(blockchain, exchangeBlockchains)
    ? blockchain
    : null
}

export function ExchangeAssetDataError() {
  const {t} = useTranslation()
  return (
    <Box mb={3}>
      <InlineError error={t('Could not load accounts or tokens data')} />
    </Box>
  )
}
