import {Grid, Box, Typography, TextField} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import type {FormikHelpers} from 'formik'
import {Formik} from 'formik'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import * as yup from 'yup'

import type {ProfilePassword} from '../../appStorage'
import {useChangeProfilePassword} from '../../appStorage'
import {Button, MutationGuard} from '../../components'
import {getHasFormError, useCommonValidations} from '../../utils/form'

import {useCommonStyles, ConfirmationStatusWrapper} from './utils'

export default function ChangeProfilePassword() {
  const {t} = useTranslation()
  const classes = useStyles()
  const [showConfirmed, setShowConfirmed] = useState(false)
  const commonClasses = useCommonStyles()
  const commonValidators = useCommonValidations()

  const changeProfilePassword = useChangeProfilePassword()

  const _formSchema = {
    currentPassword: yup.string().required(t('Current password is required.')),
    newPassword: yup
      .string()
      .concat(commonValidators.passwordPower)
      .required(t('New password is required.')),
    confirmedNewPassword: yup
      .string()
      .oneOf([yup.ref('newPassword')], t('New passwords must match.'))
      .required(t('New passwords must match.')),
  }
  type FormField = keyof typeof _formSchema
  type FormData = {[k in FormField]: string}
  const formSchema = yup.object().shape(_formSchema)

  const initialValues: FormData = {
    currentPassword: '',
    newPassword: '',
    confirmedNewPassword: '',
  }

  const onSubmitProfilePassword = async (
    values: FormData,
    {setErrors, resetForm}: FormikHelpers<FormData>,
  ) => {
    try {
      setShowConfirmed(false)
      setErrors({currentPassword: undefined})
      await changeProfilePassword.mutateAsync({
        currentPassword: values.currentPassword as ProfilePassword,
        newPassword: values.newPassword as ProfilePassword,
      })
      resetForm()
      setShowConfirmed(true)
    } catch (err) {
      setErrors({currentPassword: t('Current password does not match')})
    }
  }

  const onFormFocus = () => {
    showConfirmed && setShowConfirmed(false)
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={formSchema}
      onSubmit={onSubmitProfilePassword}
    >
      {(formikProps) => {
        const {handleSubmit, values, errors, isSubmitting, handleChange} =
          formikProps
        const hasError = getHasFormError(formikProps)

        return (
          <Box className={commonClasses.sectionWrapper}>
            <Box color="warning.main">
              <Typography color="inherit" variant="body1" component="div">
                <Box mt={-0.1}>{t('Important')}</Box>
              </Typography>
              <Typography
                className={classes.warnings}
                variant="body2"
                color="inherit"
              >
                {t(
                  'We will not be able to recover your password if it is lost. Store your password in a safe place.',
                )}
              </Typography>
            </Box>

            <form
              className={classes.form}
              onSubmit={handleSubmit}
              noValidate
              onFocus={onFormFocus}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    value={values.currentPassword}
                    onChange={handleChange<FormField>('currentPassword')}
                    label={t('Current password')}
                    type="password"
                    error={hasError('currentPassword')}
                    helperText={
                      hasError('currentPassword') && errors.currentPassword
                    }
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    value={values.newPassword}
                    onChange={handleChange<FormField>('newPassword')}
                    label={t('New Password')}
                    type="password"
                    error={hasError('newPassword')}
                    helperText={hasError('newPassword') && errors.newPassword}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    value={values.confirmedNewPassword}
                    onChange={handleChange<FormField>('confirmedNewPassword')}
                    label={t('Confirm new password')}
                    type="password"
                    error={hasError('confirmedNewPassword')}
                    helperText={
                      hasError('confirmedNewPassword') &&
                      errors.confirmedNewPassword
                    }
                    fullWidth
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <ConfirmationStatusWrapper isVisible={showConfirmed}>
                    <Button
                      fullWidth
                      textTransform="none"
                      variant="contained"
                      type="submit"
                      color="secondary"
                      disabled={isSubmitting}
                    >
                      {t('Change password')}
                    </Button>
                  </ConfirmationStatusWrapper>
                </Grid>
              </Grid>
            </form>

            {/* Passing error={null} as we handle exception as an incorrect password */}
            <MutationGuard {...changeProfilePassword} error={null} />
          </Box>
        )
      }}
    </Formik>
  )
}

const useStyles = makeStyles((theme) => ({
  form: {
    marginTop: theme.spacing(3),
  },
  warnings: {
    marginTop: theme.spacing(1),
  },
}))
