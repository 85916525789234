import {Box, Divider} from '@mui/material'
import React from 'react'

import {QueryGuard, LoadStakingDataError} from '../../../../components'
import {useScrollableStyles} from '../../../../utils/layoutUtils'
import {useGetStakeAccountsInfo} from '../../../../wallet/solana'
import {StakingTabContentLoading} from '../../common/Loadings'

import StakeAccountList from './StakeAccountList'
import StakeSummary from './StakeSummary'

function StakeAccountsOverview() {
  const stakeAccountsInfoQuery = useGetStakeAccountsInfo()
  const {scrollableParent} = useScrollableStyles()

  return (
    <QueryGuard
      {...stakeAccountsInfoQuery}
      LoadingElement={<StakingTabContentLoading />}
      ErrorElement={<LoadStakingDataError blockchain="solana" />}
    >
      {(accounts) => (
        <Box className={scrollableParent}>
          <StakeSummary accounts={accounts} />
          <Divider />
          <StakeAccountList accounts={accounts} />
        </Box>
      )}
    </QueryGuard>
  )
}

export default StakeAccountsOverview
