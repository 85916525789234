import {Divider, Grid, Tooltip} from '@mui/material'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import type {Vote} from '@nufi/wallet-cardano'
import _ from 'lodash'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {EllipsizedString, QueryGuard} from '../../../../components'
import type {SignScreenData} from '../../../../store/dappConnector'
import type {HexString} from '../../../../types'
import {useGetCatalystProposal} from '../../../../wallet/cardano'

type SummaryItemProps = {
  label: React.ReactNode | string
  value: React.ReactNode | string
}

const SummaryItem = ({label, value}: SummaryItemProps) => (
  <Box display="flex" justifyContent="space-between" alignItems="baseline">
    <Typography color="textSecondary" sx={{mr: 1}}>
      {label}
    </Typography>
    <Typography>{value}</Typography>
  </Box>
)

type FormattedChoiceProps = {
  choice: number
  votePlanId: HexString
  proposalIndex: number
}
const FormattedChoice = ({
  choice,
  votePlanId,
  proposalIndex,
}: FormattedChoiceProps) => {
  const proposalQuery = useGetCatalystProposal(votePlanId, proposalIndex)
  let choiceName: string | undefined | null
  try {
    choiceName =
      proposalQuery.data &&
      (_.invert(proposalQuery.data.chain_vote_options)[choice] ||
        'Unknown choice')
  } catch (e) {
    /* empty */
  }

  return (
    <QueryGuard
      {...proposalQuery}
      data={choiceName}
      LoadingElement={<Typography fontWeight="bold">{`${choice}`}</Typography>}
      ErrorElement={
        <Typography fontWeight="bold">{`Unknown choice (${choice})`}</Typography>
      }
    >
      {(choiceName) => (
        <Typography fontWeight="bold">{`${choiceName} (${choice})`}</Typography>
      )}
    </QueryGuard>
  )
}

const _VoteData = ({vote, voteIndex}: {vote: Vote; voteIndex: number}) => {
  const {t} = useTranslation()
  const proposalQuery = useGetCatalystProposal(
    vote.proposal.votePlanId,
    vote.proposal.proposalIndex,
  )

  return (
    <>
      <Box display="flex" justifyContent="center" alignItems="center" py={1}>
        <Typography variant="h5">
          {t('vote_number', {voteNumber: voteIndex + 1})}
        </Typography>
      </Box>
      <Box py={1}>
        <Box py={0.5}>
          <Divider />
        </Box>
        <SummaryItem
          label={t('Subject')}
          value={
            <QueryGuard {...proposalQuery}>
              {(proposalInfo) => <>{proposalInfo?.proposal_title}</>}
            </QueryGuard>
          }
        />
        <SummaryItem
          label={t('Vote plan id')}
          value={
            <Tooltip title={vote.proposal.votePlanId}>
              <Typography>
                <EllipsizedString
                  value={vote.proposal.votePlanId}
                  startLength={10}
                  endLength={5}
                />
              </Typography>
            </Tooltip>
          }
        />
        <SummaryItem
          label={t('Proposal index')}
          value={vote.proposal.proposalIndex}
        />
        <SummaryItem
          label={t('Public vote')}
          value={vote.proposal.votePublic ? t('Yes') : t('No')}
        />
        <SummaryItem
          label={t('Choice')}
          value={
            <FormattedChoice
              choice={vote.choice}
              votePlanId={vote.proposal.votePlanId}
              proposalIndex={vote.proposal.proposalIndex}
            />
          }
        />
      </Box>
    </>
  )
}

export type VoteDataProps = {data: SignScreenData<'sign-vote'>}
export const VoteData = ({
  data: {
    data: {votes},
  },
}: VoteDataProps) => {
  return (
    <Paper>
      <Grid container direction="column" py={2} px={3}>
        {votes.map((vote, voteIndex) => _VoteData({vote, voteIndex}))}
      </Grid>
    </Paper>
  )
}
