import {assert} from '@nufi/frontend-common'
import type {AccountId} from '@nufi/wallet-common'
import _ from 'lodash'
import queryString from 'query-string'
import React, {useEffect, useRef} from 'react'
import {useHistory, useLocation} from 'react-router-dom'

import {
  BatchQueryGuard,
  InlineLoading,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalLayout,
  SafeCenterAligner,
} from 'src/components'
import {useMoonpayAssets} from 'src/features/ramp/application'
import type {RampCryptoAsset} from 'src/features/ramp/domain'
import {RampOperation} from 'src/features/ramp/domain'
import {MoonpayFlow} from 'src/features/ramp/ui/moonpay/MoonpayFlow'
import type {RampFlowState} from 'src/features/ramp/ui/rampFlow/state/rampFlowState'
import {RampFlowStep} from 'src/features/ramp/ui/rampFlow/state/rampFlowState'
import {useAuthStore} from 'src/store/auth'
import type {Blockchain, TokenId} from 'src/types'
import {useGetAccounts} from 'src/wallet'
import {ensureAccountById} from 'src/wallet/utils/common'

type MoonpayFlowModalProps = {
  onClose: () => void
}

export const MoonpayFlowModal = ({onClose}: MoonpayFlowModalProps) => {
  const {authState} = useAuthStore()
  assert(authState.status === 'logged_in')

  return (
    <WithInitialMoonpayState>
      {(initialState) => (
        <Modal onClose={onClose} variant="left">
          <MoonpayFlow
            onCancel={onClose}
            renderWidgetHeader={() => null}
            renderLayout={({header, body, footer}) => (
              <ModalLayout
                header={<ModalHeader onClose={onClose}>{header}</ModalHeader>}
                body={body}
                footer={
                  footer && <ModalFooter hasDivider>{footer}</ModalFooter>
                }
              />
            )}
            isPasswordLogin={authState.info.loginType === 'password'}
            initialState={initialState}
          />
        </Modal>
      )}
    </WithInitialMoonpayState>
  )
}

type WithInitialMoonpayStateProps = {
  children: (initialState: RampFlowState | undefined) => React.ReactElement
}

const WithInitialMoonpayState = ({children}: WithInitialMoonpayStateProps) => {
  const options = useGetDefaultOptions()

  if (options == null) {
    return children(undefined)
  }

  return <_WithInitialMoonpayState queryOptions={options} children={children} />
}

type _WithInitialMoonpayStateProps = WithInitialMoonpayStateProps & {
  queryOptions: RampQueryStringDefaultOptions
}

const _WithInitialMoonpayState = ({
  children,
  queryOptions,
}: _WithInitialMoonpayStateProps) => {
  const moonpayAssetsQuery = useMoonpayAssets()
  const accountsQuery = useGetAccounts(queryOptions.blockchain)

  return (
    <BatchQueryGuard
      queries={{
        accounts: accountsQuery,
        moonpayAssets: moonpayAssetsQuery,
      }}
      LoadingElement={
        <SafeCenterAligner>
          <InlineLoading />
        </SafeCenterAligner>
      }
    >
      {({accounts, moonpayAssets}) => {
        const initialAsset = moonpayAssets.find(
          (a) =>
            (queryOptions.operation === RampOperation.BUY || a.supportsSell) &&
            a.type === 'crypto' &&
            a.blockchain === queryOptions.blockchain &&
            (a.tokenId ?? null) === (queryOptions.tokenId ?? null),
        ) as RampCryptoAsset | undefined

        const initialState: RampFlowState | undefined = initialAsset
          ? {
              account: {
                address: ensureAccountById(accounts, queryOptions.accountId)
                  .address,
              },
              operation: queryOptions.operation,
              step: RampFlowStep.DETAILS,
              asset: initialAsset,
            }
          : undefined

        return children(initialState)
      }}
    </BatchQueryGuard>
  )
}

export type RampQueryStringDefaultOptions = {
  accountId: AccountId
  blockchain: Blockchain
  operation: RampOperation
  tokenId: TokenId | null
}

const useGetDefaultOptions = () => {
  const prevOptions = useRef<RampQueryStringDefaultOptions | null>(null)
  const location = useLocation()
  const history = useHistory()

  useEffect(() => {
    history.replace({search: ''})
  }, [])

  try {
    const parsed = queryString.parse(location.search)

    // We do not want to return different value once the search string
    // is removed from the URL and the hook re-runs.
    prevOptions.current =
      parsed != null && !_.isEmpty(parsed)
        ? (parsed as RampQueryStringDefaultOptions)
        : prevOptions.current
    return prevOptions.current
  } catch (err) {
    return null
  }
}
