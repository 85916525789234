import {Typography, Grid, FormControl, Box, Chip} from '@mui/material'
import type {SwapAsset} from 'common'
import React from 'react'

import {SearchableSelect} from 'src/components'
import type {ExchangeAssetsDetails} from 'src/features/exchange/domain'

type ExchangeAssetSelectFieldProps = {
  value: SwapAsset
  onChange: (value: SwapAsset | null) => void
  exchangeAssetsDetails: ExchangeAssetsDetails
  label?: string
  selectClasses?: Record<string, string>
  className?: string
  error?: boolean
  disabledAssets?: SwapAsset[]
  testId?: string
}

export function ExchangeAssetSelectField({
  onChange,
  value,
  label,
  className = '',
  selectClasses = {},
  error = false,
  exchangeAssetsDetails,
  disabledAssets = [],
  testId,
}: ExchangeAssetSelectFieldProps) {
  return (
    <FormControl
      variant="outlined"
      fullWidth
      className={className}
      rtl-data-test-id={testId}
    >
      <SearchableSelect
        {...{onChange, value, selectClasses, label, error}}
        options={Object.keys(exchangeAssetsDetails) as SwapAsset[]}
        getOptionLabel={(asset) =>
          `${exchangeAssetsDetails[asset]!.fullName} (${exchangeAssetsDetails[
            asset
          ]!.ticker.toUpperCase()})`
        }
        getOptionDisabled={(asset) => disabledAssets.includes(asset)}
        listItemOptions={{
          ITEM_SIZE: 60,
          LISTBOX_PADDING: 8,
          MAX_DISPLAYED_ITEMS: 6,
        }}
        renderRow={(option) => {
          const assetDetail = exchangeAssetsDetails[option]
          if (!assetDetail) return null

          return (
            <>
              <img height="24" width="24" src={assetDetail.image} />
              <Grid container direction="column" ml={2}>
                <Grid item>
                  <Grid container spacing={1}>
                    <Grid item>
                      <Typography>{assetDetail.fullName}</Typography>
                    </Grid>
                    {/* Only allow protocol chip for tokens */}
                    {assetDetail.protocol &&
                      assetDetail.protocol?.toUpperCase() !==
                        assetDetail.ticker.toUpperCase() && (
                        <Grid item>
                          <Chip
                            color="primary"
                            variant="outlined"
                            size="small"
                            label={assetDetail.protocol}
                          />
                        </Grid>
                      )}
                  </Grid>
                </Grid>
                <Grid item>
                  <Typography>{assetDetail.ticker.toUpperCase()}</Typography>
                </Grid>
              </Grid>
            </>
          )
        }}
        renderStartAdornment={(asset) => {
          if (!asset) return undefined

          const assetDetail = exchangeAssetsDetails[asset]
          if (!assetDetail) return null

          return (
            asset && (
              <Box ml={1} mr={1} display="flex">
                <img height="24" width="24" src={assetDetail.image} />
              </Box>
            )
          )
        }}
        searchable
      />
    </FormControl>
  )
}
