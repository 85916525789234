import {Box, Tab, Tabs} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  matchPath,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom'

import {useIsBlockchainEnabled} from 'src/features/profile/application'

import type {StakingBlockchain} from '../../blockchainTypes'
import {
  Breadcrumbs,
  BreadcrumbLabel,
  BreadcrumbBlockchainItem,
} from '../../components'
import {routeTo} from '../../router'
import {useScrollableStyles} from '../../utils/layoutUtils'
import {useHasAccounts} from '../../wallet'
import {NoAccountsScreen} from '../portfolio/account/NoAccountsScreen'

type StakeDetailHeaderProps = {
  onBack: () => unknown
  blockchain: StakingBlockchain
}

function StakeDetailHeader({onBack, blockchain}: StakeDetailHeaderProps) {
  const {t} = useTranslation()
  return (
    <Breadcrumbs
      items={[
        <BreadcrumbLabel key={0} onClick={onBack} label={t('My Staking')} />,
        <BreadcrumbBlockchainItem key={1} blockchain={blockchain} />,
      ]}
    />
  )
}

export type StakeActionsRoutesProps = {
  onModalClose: () => unknown
}

type StakeDetailProps = {
  blockchain: StakingBlockchain
  StakeAccountsOverview: React.FunctionComponent
  StakeHistory?: React.FunctionComponent
  AccountActionsRoutes?: (props: StakeActionsRoutesProps) => JSX.Element
  EpochInfo?: () => JSX.Element
}

export default function StakeDetail({
  blockchain,
  StakeAccountsOverview,
  StakeHistory,
  EpochInfo,
  AccountActionsRoutes,
}: StakeDetailProps) {
  const history = useHistory()
  const onBack = () => history.push(routeTo.staking.myStaking.index)
  const location = useLocation()
  const {t} = useTranslation()
  const classes = useStyles()
  const {scrollableList} = useScrollableStyles()
  const hasAccounts = useHasAccounts(blockchain)
  const isBlockchainEnabled = useIsBlockchainEnabled(blockchain)

  const value = (() => {
    if (
      matchPath(
        location.pathname,
        routeTo.staking.myStaking.blockchain(blockchain).history,
      )
    ) {
      return routeTo.staking.myStaking.blockchain(blockchain).history
    }
    if (
      matchPath(
        location.pathname,
        routeTo.staking.myStaking.blockchain(blockchain).index,
      )
    ) {
      return routeTo.staking.myStaking.blockchain(blockchain).index
    }
    return routeTo.staking.myStaking.blockchain(blockchain).index
  })()

  const onChange = (event: React.ChangeEvent<unknown>, value: string) => {
    history.replace(value)
  }

  const onModalClose = () =>
    history.push(routeTo.staking.myStaking.blockchain(blockchain).index)

  return (
    <Box className={classes.wrapper}>
      <StakeDetailHeader onBack={onBack} blockchain={blockchain} />
      {/*
      [AddAccount or EnableBlockchain] No accounts logic is currently duplicated
      in multiple places. We couldn't think of a way to abstract which would be
      worth it so we settled on a bit of duplication. All the places with duplication
      are tagged with the [AddAccount or EnableBlockchain] tag so they would be
      easily searchable.
      */}
      {!hasAccounts || !isBlockchainEnabled ? (
        <NoAccountsScreen blockchain={blockchain} />
      ) : (
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'baseline',
            }}
          >
            <Tabs
              className={classes.tabs}
              {...{value, onChange}}
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab
                value={routeTo.staking.myStaking.blockchain(blockchain).index}
                label={t('Stake accounts')}
              />
              {StakeHistory && (
                <Tab
                  value={
                    routeTo.staking.myStaking.blockchain(blockchain).history
                  }
                  label={t('History')}
                />
              )}
            </Tabs>
            {EpochInfo && (
              <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                <EpochInfo />
              </Box>
            )}
          </Box>
          <Box className={scrollableList}>
            <Switch>
              {StakeHistory && (
                <Route
                  exact
                  path={
                    routeTo.staking.myStaking.blockchain(blockchain).history
                  }
                  component={StakeHistory}
                />
              )}
              <Route
                path={routeTo.staking.myStaking.blockchain(blockchain).index}
                component={StakeAccountsOverview}
              />
            </Switch>
          </Box>
          {!!AccountActionsRoutes && (
            <Route
              path={routeTo.staking.myStaking
                .blockchain(blockchain)
                .account(':accountId')}
            >
              <AccountActionsRoutes onModalClose={onModalClose} />
            </Route>
          )}
        </>
      )}
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  tabs: {
    marginTop: theme.spacing(3),
  },
  wrapper: {
    height: '100%',
    display: 'flex',
    paddingRight: theme.spacing(2),
    flexDirection: 'column',
  },
}))
