import {useParams} from 'react-router-dom'

import type {AssetDetailBlockchain} from '../blockchainTypes/blockchainTypes'
import type {AccountId} from '../wallet'

export type ActivityPathParams = {
  blockchain: AssetDetailBlockchain
  accountId?: AccountId
}

export const useActivityRouteOptions = () => {
  const pathParams = useParams<ActivityPathParams>()

  return {
    blockchain: pathParams.blockchain,
    accountId: pathParams.accountId,
  }
}
