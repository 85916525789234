import {Box, Grid, Typography, Divider} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import BigNumber from 'bignumber.js'
import type {FormikProps} from 'formik'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {WithConversionRates} from 'src/features/conversionRates/ui'

import type {StakingBlockchain} from '../../../../blockchainTypes'
import {
  ValidatorSelect,
  ModalFooter,
  ModalLayout,
  AccountSelectField,
  FooterLayout,
  FormattedAsset,
  InlineLoading,
  useModalSharedStyles,
  createResponsiveClasses,
  FormattedAssetAsCurrency,
} from '../../../../components'
import type {AppError, AccountId, AccountInfo} from '../../../../types'
import type {SelectChangeFn} from '../../../../utils/form'
import {getHasFormError} from '../../../../utils/form'
import type {CardanoStakepoolInfo} from '../../../../wallet/cardano'
import {
  stakepoolIdToBech32,
  isValidStakepoolId,
  findValidator,
  createEmptyValidatorFromId,
} from '../../../../wallet/cardano'
import {StakeModalHeader} from '../../common/utils'
import {formatStakepoolTicker} from '../formatting'
import type {ActivateStakeSchema} from '../schema'

import FormattedValidatorInfo from './StakeModalValidatorInfo'

const FORM_ID = 'create-stake-account-form-details'

type DetailsScreenProps<T extends ActivateStakeSchema> = {
  blockchain: StakingBlockchain
  accounts: Array<AccountInfo>
  validators: CardanoStakepoolInfo[]
  recommendedValidator: CardanoStakepoolInfo
  formikProps: FormikProps<T>
  disabled?: boolean
  onClose: () => void
  error?: AppError
}

export default function DetailsScreen<T extends ActivateStakeSchema>({
  accounts,
  disabled,
  formikProps,
  blockchain,
  onClose,
  validators,
  error,
  recommendedValidator,
}: DetailsScreenProps<T>) {
  const classes = {...useStyles(), ...useModalSharedStyles()}
  const {t} = useTranslation()

  const {values, handleChange, errors} = formikProps
  const accountItems = accounts.map((a) => ({
    accountId: a.id,
    address: a.address,
    balance: a.balance,
    name: a.name,
    cryptoProviderType: a.cryptoProviderType,
  }))

  const validator = (() => {
    if (!isValidStakepoolId(values.validatorId)) {
      return undefined
    }
    return (
      findValidator(validators, values.validatorId) ||
      createEmptyValidatorFromId(values.validatorId)
    )
  })()

  // TODO: handle tx plan errors more nicely
  const ErrorContent = error ? (
    <Typography noWrap color="error">
      {(error as Error).message}
    </Typography>
  ) : null

  const getOptionLabel = (stakepoolInfo: CardanoStakepoolInfo) =>
    formatStakepoolTicker(
      stakepoolInfo.poolId,
      validators,
      t('Unknown ticker'),
      t('Unknown pool'),
    )

  const hasError = getHasFormError(formikProps)

  type FormField = keyof typeof values
  return (
    <ModalLayout
      header={<StakeModalHeader {...{onClose, blockchain}} />}
      body={
        <Box className={classes.contentWrapper}>
          <Box p={2}>
            <form onSubmit={formikProps.handleSubmit} noValidate id={FORM_ID}>
              <Grid container>
                <Grid item xs={12}>
                  <AccountSelectField
                    className={classes.formField}
                    label={t('Account')}
                    value={values.accountId}
                    onChange={
                      handleChange<FormField>(
                        'accountId',
                      ) as SelectChangeFn<AccountId>
                    }
                    items={accountItems}
                    blockchain={blockchain}
                    showBlockchainIcon
                  />
                </Grid>
                <Grid item xs={12}>
                  <Divider className={classes.divider} />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle2"
                    className={classes.commonBottomMargin}
                  >
                    {t('Staking pool')}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Box className={classes.commonTopMargin}>
                    <ValidatorSelect
                      getOptionId={({poolId}) => poolId}
                      label={t('Ticker, Name, PoolId, pool1...')}
                      labelWhenSelected={t('Selected pool')}
                      getOptionLabel={getOptionLabel}
                      renderOption={getOptionLabel}
                      getOptionValid={isValidStakepoolId}
                      options={validators}
                      recommendedOption={recommendedValidator}
                      recommendedGroupLabel={t('Recommended pool')}
                      otherGroupLabel={t('Other')}
                      getOptionKeyword={({poolId, ticker, name}) =>
                        `${poolId || ''},${ticker || ''},${
                          name || ''
                        },${stakepoolIdToBech32(poolId)}`
                      }
                      value={validator || null}
                      createNotFoundOptionFromInput={createEmptyValidatorFromId}
                      onChange={(validator) => {
                        handleChange<FormField>('validatorId')(
                          validator?.poolId || '',
                        )
                      }}
                      error={hasError('validatorId')}
                      helperText={
                        hasError('validatorId') && <>{errors.validatorId}</>
                      }
                    />
                  </Box>
                </Grid>
                {validator && (
                  <Grid item xs={12} className={classes.stakepoolInfo}>
                    <FormattedValidatorInfo validatorInfo={validator} />
                  </Grid>
                )}
                <Grid item xs={12}>
                  {ErrorContent}
                </Grid>
              </Grid>
            </form>
          </Box>
        </Box>
      }
      footer={
        <ModalFooter hasDivider>
          <FooterLayout
            leftBtnConf={{
              onClick: onClose,
              children: t('Back'),
            }}
            rightBtnConf={{
              children: t('Continue'),
              type: 'submit',
              form: FORM_ID,
              disabled,
            }}
          />
        </ModalFooter>
      }
    />
  )
}

const useStyles = makeStyles((theme) => ({
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
  },
  totalRow: {
    margin: theme.spacing(1),
  },
  stakepoolInfo: {
    marginLeft: theme.spacing(0.25),
    marginTop: theme.spacing(2),
  },
}))

type TotalStakeRowProps = {
  amount: BigNumber | undefined
  fee: BigNumber | undefined
  blockchain: StakingBlockchain
  totalText: string
  totalDescription?: string
  feeText?: string
  isLoading?: boolean
}

export function TotalStakeRow({
  amount,
  fee,
  blockchain,
  totalText,
  isLoading,
  totalDescription = '',
  feeText = '',
}: TotalStakeRowProps) {
  const classes = useTotalRowStyles()
  return (
    <Grid container justifyContent="space-between" className={classes.totalRow}>
      <Grid item>
        <Grid container direction="column">
          <Typography variant="h6">{totalText}</Typography>
          <Typography variant={'body2'} color="textSecondary">
            {totalDescription}
          </Typography>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container direction="column" alignItems="flex-end">
          <Grid container direction="column">
            <Grid item className={classes.amount}>
              {isLoading && <InlineLoading size={20} />}
              {!isLoading && (
                <Typography variant="h6">
                  <FormattedAsset
                    includeAssetSymbol
                    amount={amount || new BigNumber(0)}
                    blockchain={blockchain}
                    isSensitiveInformation={false}
                  />
                </Typography>
              )}
              <Typography color="textSecondary" className={classes.currency}>
                (~&nbsp;
                <WithConversionRates>
                  {(conversionRates) => (
                    <FormattedAssetAsCurrency
                      includeCurrencySymbol
                      balance={amount || new BigNumber(0)}
                      blockchain={blockchain}
                      isSensitiveInformation={false}
                      conversionRates={conversionRates}
                    />
                  )}
                </WithConversionRates>
                )
              </Typography>
            </Grid>
          </Grid>
          {fee && (
            <Grid item className={classes.amount}>
              <Typography variant={'body2'} color="textSecondary">
                {feeText}:{' '}
                <FormattedAsset
                  includeAssetSymbol
                  amount={fee}
                  blockchain={blockchain}
                  isSensitiveInformation={false}
                />
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

const useTotalRowStyles = makeStyles((theme) => ({
  totalRow: {
    ...createResponsiveClasses(
      theme,
      [1.5, 'paddingTop'],
      [1.5, 'paddingBottom'],
    ),
  },
  amount: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  currency: {
    paddingLeft: theme.spacing(0.5),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
}))
