import {safeAssertUnreachable} from '@nufi/frontend-common'
import React from 'react'

import type {AddHwAccountPhase} from './AddAccountUtils'
import {AddAccountLayout} from './AddAccountUtils'
import {PairHardwareAccountTimeline} from './Timelines'

export type AddHwAccountProps = {
  addAccountPhase: AddHwAccountPhase
  setAddAccountPhase: (addAccountPhase: AddHwAccountPhase) => void
  ChooseAccountPhaseContent: JSX.Element
  ConfirmationPhaseContent: JSX.Element
}

export function AddHwAccountLayout({
  addAccountPhase,
  setAddAccountPhase,
  ChooseAccountPhaseContent,
  ConfirmationPhaseContent,
}: AddHwAccountProps) {
  switch (addAccountPhase) {
    case 'choose-account':
      return (
        <AddAccountLayout
          leftContent={<PairHardwareAccountTimeline step="pair-device" />}
        >
          {ChooseAccountPhaseContent}
        </AddAccountLayout>
      )
    case 'confirm-account':
      return (
        <AddAccountLayout
          leftContent={<PairHardwareAccountTimeline step="name" />}
          onBack={() => setAddAccountPhase('choose-account')}
        >
          {ConfirmationPhaseContent}
        </AddAccountLayout>
      )
    default:
      return safeAssertUnreachable(addAccountPhase)
  }
}
