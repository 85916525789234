import {Typography, Box, styled} from '@mui/material'
import Alert from '@mui/material/Alert'
import React from 'react'
import {useTranslation} from 'react-i18next'

import trezorErrorSrc from '../../../assets/images/trezorError.png'
import trezorSignSrc from '../../../assets/images/trezorSign.png'
import {HelpSectionText} from '../common'

type HelpSectionProps = {
  severity: 'info' | 'error'
}

export function HelpSection({severity}: HelpSectionProps) {
  const {t} = useTranslation()

  return (
    <Alert severity={severity}>
      <Typography>
        <Box fontWeight="fontWeightBold">{t('Make sure that')}</Box>
      </Typography>
      <HelpSectionText>1. {t('Your Trezor is connected')}</HelpSectionText>
      <HelpSectionText>2. {t('Trezor firmware is up to date')}</HelpSectionText>
      <HelpSectionText>3. {t('Trezor Suite is not running')}</HelpSectionText>
      {severity === 'error' && (
        <HelpSectionText>
          4. {t('trezor_error_correct_passphrase')}
        </HelpSectionText>
      )}
    </Alert>
  )
}

const TrezorImg = styled('img')(({theme}) => ({
  height: 130,
  [theme.breakpoints.down('md')]: {
    height: 80,
  },
}))

export const trezorImages = {
  sign: <TrezorImg src={trezorSignSrc} />,
  verify: <TrezorImg src={trezorSignSrc} />,
  export: <TrezorImg src={trezorSignSrc} />,
  setup: <TrezorImg src={trezorSignSrc} />,
  error: <TrezorImg src={trezorErrorSrc} />,
}
