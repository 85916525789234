import type {AccountStoredData} from '../../store/wallet'
import type {AccountNetworkInfo, AccountOfflineInfo} from '../types'

export type {AutoDiscoveryType} from '@nufi/wallet-common'

export type AccountInfosUtils<
  D extends AccountStoredData,
  N extends AccountNetworkInfo,
  O extends AccountOfflineInfo,
> = {
  getAccountNetworkInfos: (data: D[]) => Promise<N[]>
  getAccountOfflineInfos: (data: D[]) => O[]
  getAccountInfos: (data: D[]) => Promise<(O & N)[]>
}

export interface AccountManager<
  D extends AccountStoredData,
  N extends AccountNetworkInfo,
  O extends AccountOfflineInfo,
> {
  getAccountNetworkInfo: (data: D) => Promise<N>
  getAccountOfflineInfo: (data: D) => O
  getAccountInfo: (data: D) => Promise<O & N>
}

export function createAccountInfosUtils<
  D extends AccountStoredData,
  N extends AccountNetworkInfo,
  S extends AccountOfflineInfo,
>(accountManager: AccountManager<D, N, S>): AccountInfosUtils<D, N, S> {
  const getAccountNetworkInfos = async (
    accounts: Array<D>,
  ): Promise<Array<N>> =>
    await Promise.all(
      accounts.map((a) => accountManager.getAccountNetworkInfo(a)),
    )

  const getAccountOfflineInfos = (accounts: Array<D>): Array<S> =>
    accounts.map((a) => accountManager.getAccountOfflineInfo(a))

  const getAccountInfos = async (accounts: Array<D>): Promise<Array<S & N>> => {
    const networkInfo = await getAccountNetworkInfos(accounts)
    return accounts.map((a, i) => ({
      ...accountManager.getAccountOfflineInfo(a),
      ...networkInfo[i]!,
    }))
  }

  return {getAccountNetworkInfos, getAccountOfflineInfos, getAccountInfos}
}
