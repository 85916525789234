import React, {useState, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import * as yup from 'yup'

import {useAccountNameValidation} from 'src/utils/account'

import {FooterLayout, ModalFooter} from '../../../../components'
import type {WalletKind} from '../../../../types'
import type {AccountName} from '../../../../wallet'
import {AccountActionModalHeader} from '../utils'

type RenameAccountModalHeaderProps = {
  onClose: () => unknown
  walletKind: WalletKind
  name: AccountName
}

export function RenameAccountModalHeader({
  name,
  walletKind,
  onClose,
}: RenameAccountModalHeaderProps) {
  const {t} = useTranslation()
  return (
    <AccountActionModalHeader
      label={`${t('Rename')} ${name}`}
      walletKind={walletKind}
      onClose={onClose}
    />
  )
}

type RenameAccountModalFooterProps = {
  onClose: () => unknown
  onSubmit: () => Promise<void>
  disabled?: boolean
}

export function RenameAccountModalFooter({
  onClose,
  onSubmit,
  disabled,
}: RenameAccountModalFooterProps) {
  const {t} = useTranslation()

  return (
    <ModalFooter hasDivider>
      <FooterLayout
        leftBtnConf={{
          onClick: onClose,
          children: t('Back'),
        }}
        rightBtnConf={{
          variant: 'contained',
          type: 'submit',
          children: t('Rename'),
          onClick: onSubmit,
          disabled,
        }}
      />
    </ModalFooter>
  )
}

export const useValidateAccountName = (accountName: string) => {
  const [errorMessage, setErrorMessage] = useState('')
  const schema = useAccountNameValidation()

  useEffect(() => {
    const validate = async (value: string) => {
      try {
        await schema.validate(value, {abortEarly: false})
        setErrorMessage('')
      } catch (e) {
        if (e instanceof yup.ValidationError) {
          setErrorMessage(e.errors[0]!)
        } else setErrorMessage(e as string)
      }
    }
    validate(accountName)
  }, [accountName])
  return {errorMessage}
}
