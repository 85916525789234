import {Extension as ExtensionIcon} from '@mui/icons-material'
import type {Theme} from '@mui/material'
import {Box, useTheme, Typography} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  getIsWebExtensionInstalled,
  getIsWebExtension,
} from '@nufi/frontend-common'
import clsx from 'clsx'
import React from 'react'
import {useTranslation} from 'react-i18next'

import BackgroundImg from '../../../assets/images/welcomePageBG.png'
import {externalLinks} from '../../../constants'
import {useBackgroundImageStyles} from '../../../utils/layoutUtils'
import {
  Button,
  IconBackgroundWrapper,
  HideBelowBreakpoint,
} from '../../visual/atoms'
import {Icon} from '../atoms/Icon'
import {SafeCenterAligner} from '../atoms/SafeCenterAligner'

const SIDEBAR_WIDTH = 472

const useStyles = makeStyles<Theme>((theme) => ({
  wrapper: {
    display: 'flex',
    height: '100%',
    width: '100%',
    position: 'relative',
    overflow: 'auto',
    minHeight: '100vh',
  },
  left: {
    width: SIDEBAR_WIDTH,
    minWidth: SIDEBAR_WIDTH,
    display: 'flex',
    padding: 0,
    backgroundColor: theme.palette.background.paper,
    flexShrink: 2,
    overflow: 'auto',
    [theme.breakpoints.down('lg')]: {
      overflow: 'visible',
      minWidth: 0,
    },
  },
  right: {
    flex: 1,
    flexShrink: 0,
    display: 'flex',
    minWidth: 'fit-content',
    padding: theme.spacing(4, 6),
  },
  rightMobileSpacing: {
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2, 2),
    },
  },
  rightOpaque: {
    width: SIDEBAR_WIDTH,
    flexShrink: 10,
  },
  leftImgContainer: {
    position: 'fixed',
    height: '100vh',
    top: 0,
    left: 0,
    width: SIDEBAR_WIDTH,
    minWidth: SIDEBAR_WIDTH,
    [theme.breakpoints.down('lg')]: {
      position: 'static',
      height: 'auto',
      width: '100%',
      minWidth: 0,
    },
  },
}))

type SideBarLayoutProps = {
  left?: JSX.Element
  right: JSX.Element
  background?: string
  showAnnouncement?: boolean
  extraSidebarContent?: JSX.Element
}

export default function SideBarLayout({
  left,
  right,
  background,
  showAnnouncement,
  extraSidebarContent,
}: SideBarLayoutProps) {
  const bgImageClass = useBackgroundImageStyles({image: BackgroundImg})
  const classes = useStyles()
  const theme = useTheme()
  const {t} = useTranslation()

  const leftContent = (
    <div className={classes.left}>
      <div className={clsx(bgImageClass.bgImage, classes.leftImgContainer)}>
        <SafeCenterAligner>
          {left || (
            <Box textAlign="center">
              <Icon type="appLogo" exactSize={170} sx={{height: 'auto'}} />
              {showAnnouncement && !getIsWebExtension() && (
                <Box
                  mt={1}
                  p={1}
                  maxWidth={250}
                  borderTop={3}
                  borderColor="primary.main"
                >
                  <Typography sx={{mb: 1.5}}>
                    {t(
                      "Connect to NFT marketplaces, DEXes, and other DApps with NuFi's Chrome extension",
                    )}
                  </Typography>
                  <Button
                    startIcon={
                      <IconBackgroundWrapper
                        padding={0.75}
                        sx={{bgcolor: 'background.default'}}
                      >
                        <ExtensionIcon color="inherit" fontSize="inherit" />
                      </IconBackgroundWrapper>
                    }
                    href={externalLinks.chromeExtensionLink}
                    target="_blank"
                    fullWidth
                    variant="contained"
                    color="primary"
                    textTransform="capitalize"
                    sx={{
                      '& .MuiButton-startIcon': {
                        color: (theme) => theme.palette.primary.main,
                      },
                      '&:hover > .MuiButton-startIcon': {
                        color: (theme) => theme.palette.primary.dark,
                      },
                    }}
                  >
                    {getIsWebExtensionInstalled()
                      ? t('open browser extension')
                      : t('install browser extension')}
                  </Button>
                  {extraSidebarContent}
                </Box>
              )}
            </Box>
          )}
        </SafeCenterAligner>
      </div>
    </div>
  )
  return (
    <Box
      className={clsx(classes.wrapper)}
      style={{
        backgroundColor: background || theme.palette.background.default,
      }}
    >
      <HideBelowBreakpoint breakpoint="md">{leftContent}</HideBelowBreakpoint>
      <SafeCenterAligner
        height="auto"
        className={clsx(classes.right, classes.rightMobileSpacing)}
        padding={0}
      >
        {right}
      </SafeCenterAligner>
      <HideBelowBreakpoint breakpoint="md">
        <Box className={classes.rightOpaque} />
      </HideBelowBreakpoint>
    </Box>
  )
}
