import {
  AttachMoney as WithdrawIcon,
  PlayCircleOutline as ActivateStakingIcon,
} from '@mui/icons-material'
import {Grid, Paper, Box, Avatar} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'

import {useGetConversionRates} from 'src/features/conversionRates/application'
import type {ConversionRates} from 'src/features/conversionRates/domain'

import {
  Aligner,
  SplitButton,
  ListHeaderTitle,
  AccountCardCol,
  FormattedAssetPair,
  ValidatorExplorerLink,
} from '../../../../components'
import {routeTo} from '../../../../router'
import {getAccountStakedBalance} from '../../../../wallet/cardano'
import type {CardanoStakeAccountInfo} from '../../../../wallet/cardano'
import {
  ScrollableList,
  NewStakingButton,
} from '../../common/overview/StakeOverviewUtils'
import {FormattedStakepoolTicker} from '../formatting'

type CardanoStakeAccountListProps = {
  accounts: CardanoStakeAccountInfo[]
}

export default function CardanoStakeAccountList({
  accounts,
}: CardanoStakeAccountListProps) {
  const classes = useStyles()
  const {rates} = useGetConversionRates()

  return (
    <ScrollableList>
      {accounts.map((account) => (
        <Paper key={account.id} className={classes.wrapper}>
          <Box className={classes.mainContent}>
            <Box mt={2} />
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item className={classes.accountInfoWrapper}>
                <StakeParentAccountRow
                  stakeAccount={account}
                  conversionRates={rates}
                />
              </Grid>
            </Grid>
          </Box>
        </Paper>
      ))}
    </ScrollableList>
  )
}

const useStyles = makeStyles((theme) => ({
  accountInfoWrapper: {
    marginBottom: theme.spacing(1),
    width: '100%',
  },
  wrapper: {
    width: '100%',
    cursor: 'pointer',
    background: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
    marginBottom: theme.spacing(2),
  },
  mainContent: {
    padding: theme.spacing(0.5, 2),
  },
}))

type StakeParentCardLayoutProps = {
  accountCol: React.ReactNode
  balanceCol: React.ReactNode
  rewardsCol: React.ReactNode
  stakedCol: React.ReactNode
  actionCol: React.ReactNode
  validatorCol: React.ReactNode
}

function StakeParentCardLayout({
  accountCol,
  balanceCol,
  rewardsCol,
  stakedCol,
  actionCol,
  validatorCol,
}: StakeParentCardLayoutProps) {
  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item xs={2}>
        <Aligner align="left">{accountCol}</Aligner>
      </Grid>
      <Grid item xs={2}>
        <Aligner align="right">{balanceCol}</Aligner>
      </Grid>
      <Grid item xs={2}>
        <Aligner align="right">{rewardsCol}</Aligner>
      </Grid>
      <Grid item xs={2}>
        <Aligner align="right">{stakedCol}</Aligner>
      </Grid>
      <Grid item xs={2}>
        <Aligner align="right">{validatorCol}</Aligner>
      </Grid>
      <Grid item xs={1}>
        <Aligner align="right">{actionCol}</Aligner>
      </Grid>
    </Grid>
  )
}

type StakeParentAccountRowProps = {
  stakeAccount: CardanoStakeAccountInfo
  conversionRates: ConversionRates
}

function StakeParentAccountRow({
  stakeAccount,
  conversionRates,
}: StakeParentAccountRowProps) {
  const classes = useStakeParentAccountRowStyles()
  const {t} = useTranslation()
  const history = useHistory()
  const openStakingModal = () =>
    history.push(
      routeTo.staking.myStaking.cardano.account(stakeAccount.id).delegate,
    )

  const openWithdrawModal = () =>
    history.push(
      routeTo.staking.myStaking.cardano.account(stakeAccount.id)
        .withdrawRewards,
    )

  const enableWithdraw =
    stakeAccount.rewards.gt(0) && stakeAccount.balance.gt(0)

  return (
    <>
      <Grid container className={classes.headerWrapper}>
        <StakeParentCardLayout
          accountCol={<ListHeaderTitle>{t('Account')}</ListHeaderTitle>}
          balanceCol={
            <ListHeaderTitle>{t('Available balance')}</ListHeaderTitle>
          }
          rewardsCol={<ListHeaderTitle>{t('Reward balance')}</ListHeaderTitle>}
          stakedCol={<ListHeaderTitle>{t('Staked')}</ListHeaderTitle>}
          validatorCol={<ListHeaderTitle>{t('Validator')}</ListHeaderTitle>}
          actionCol={<ListHeaderTitle>{''}</ListHeaderTitle>}
        />
      </Grid>
      <StakeParentCardLayout
        accountCol={
          <AccountCardCol
            walletKind={'cardano'}
            blockchain={'cardano'}
            name={stakeAccount.name}
            cryptoProviderType={stakeAccount.cryptoProviderType}
          />
        }
        balanceCol={
          <FormattedAssetPair
            amount={stakeAccount.balance}
            blockchain={stakeAccount.blockchain}
            isSensitiveInformation
            conversionProps={{
              conversionRates,
            }}
          />
        }
        rewardsCol={
          <FormattedAssetPair
            amount={stakeAccount.rewards}
            blockchain={stakeAccount.blockchain}
            isSensitiveInformation
            conversionProps={{
              conversionRates,
            }}
          />
        }
        stakedCol={
          <FormattedAssetPair
            amount={getAccountStakedBalance(stakeAccount)}
            blockchain={stakeAccount.blockchain}
            isSensitiveInformation
            conversionProps={{
              conversionRates,
            }}
          />
        }
        validatorCol={
          <Grid item container>
            {stakeAccount.delegation ? (
              <Grid container>
                {stakeAccount.delegation.iconUrl && (
                  <Box mx={1}>
                    <Avatar
                      variant="rounded"
                      alt="Validator Avatar"
                      src={stakeAccount.delegation.iconUrl}
                    />
                  </Box>
                )}
                <ValidatorExplorerLink
                  blockchain={'cardano'}
                  validatorId={stakeAccount.delegation.poolId}
                  validatorName={
                    <FormattedStakepoolTicker
                      poolId={stakeAccount.delegation.poolId}
                      verbose={false}
                    />
                  }
                />
              </Grid>
            ) : (
              <FormattedStakepoolTicker poolId={null} verbose={false} />
            )}
          </Grid>
        }
        actionCol={
          <Grid item container>
            {stakeAccount.delegation ? (
              <SplitButton
                options={[
                  {
                    Icon: ActivateStakingIcon,
                    label: t('Change validator'),
                    onClick: openStakingModal,
                  },
                  {
                    Icon: WithdrawIcon,
                    label: t('Withdraw rewards'),
                    onClick: openWithdrawModal,
                    disabled: !enableWithdraw,
                  },
                ]}
              />
            ) : (
              <NewStakingButton
                openStakingModal={openStakingModal}
                blockchain={stakeAccount.blockchain}
              />
            )}
          </Grid>
        }
      />
    </>
  )
}

const useStakeParentAccountRowStyles = makeStyles((theme) => ({
  headerWrapper: {
    marginBottom: theme.spacing(1),
  },
  accountType: {
    fontSize: 12,
    color: theme.palette.grey['600'],
  },
}))
