import {Typography, Link as MuiLink, styled} from '@mui/material'
import capitalize from 'lodash/capitalize'
import React from 'react'
import {Trans, useTranslation} from 'react-i18next'

import {externalLinks} from 'src/constants'

import {Alert} from './visual'

type Web3AuthMigrationInfoProps = {
  loginProvider: string
  userName: string
  variant?: 'normal' | 'small'
}

export const Web3AuthMigrationInfo = ({
  variant = 'normal',
  loginProvider,
  userName,
}: Web3AuthMigrationInfoProps) => {
  const {t} = useTranslation()
  const textVariant = {
    normal: {
      alert: 'body1',
      help: 'body2',
    } as const,
    small: {
      alert: 'body2',
      help: 'caption',
    } as const,
  }[variant]

  return (
    <ContentWrapper>
      <Alert severity="info">
        <Typography variant={textVariant.alert}>
          <Trans
            i18nKey="web3auth_migration_explanation"
            t={t}
            components={{
              Bold: <b />,
            }}
            values={{
              loginProvider: capitalize(loginProvider),
              userName,
            }}
          />
        </Typography>
      </Alert>
      <Typography
        variant={textVariant.help}
        color="textSecondary"
        mt="auto"
        textAlign="center"
      >
        <Trans
          i18nKey="web3auth_migration_support_link"
          t={t}
          components={{
            Link: <Link target="_blank" href={externalLinks.nufiContactLink} />,
          }}
        />
      </Typography>
    </ContentWrapper>
  )
}

const ContentWrapper = styled('div')(({theme}) => ({
  maxWidth: 500,
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  '& > :not(:last-child)': {
    marginBottom: theme.spacing(2),
  },
}))

const Link = styled(MuiLink)({
  whiteSpace: 'nowrap',
})
