import type {FlowAccountInfo} from '@nufi/wallet-flow'
import React from 'react'

import {useLastDefinedData} from 'src/utils/query-utils'

import {LoadAccountsModalError, QueryGuard} from '../../../../../../components'
import {safeAssertUnreachable} from '../../../../../../utils/assertion'
import type {AccountInfo} from '../../../../../../wallet'
import {useGetAccounts} from '../../../../../../wallet'
import type {EvmAccountInfo} from '../../../../../../wallet/evm'
import {isEvmBlockchain} from '../../../../../../wallet/evm'
import {WithActiveScreenState} from '../../../../../transaction'
import {ImportEvmTokens} from '../evm/ImportToken'
import {ImportFlowTokenTx} from '../flow'
import type {ImportTxProps} from '../types'

type ImportTxScreenProps = Omit<ImportTxProps<AccountInfo>, 'accounts'>

export default function ImportTxScreen({
  onClose,
  onBack,
  blockchain,
  accountId,
}: ImportTxScreenProps) {
  const {accountsQuery} = useLastDefinedData({
    accountsQuery: useGetAccounts(blockchain),
  })

  return (
    <WithActiveScreenState initialScreen="details">
      <QueryGuard
        {...accountsQuery}
        ErrorElement={<LoadAccountsModalError />}
        loadingVariant="centered"
      >
        {(accounts) => (
          <ImportTxScreenContent
            {...{onClose, onBack, accounts, blockchain, accountId}}
          />
        )}
      </QueryGuard>
    </WithActiveScreenState>
  )
}

type ImportTxScreenContentProps = ImportTxProps<AccountInfo>

function ImportTxScreenContent({
  onClose,
  onBack,
  accounts,
  blockchain,
  accountId,
}: ImportTxScreenContentProps) {
  switch (blockchain) {
    case 'cardano':
    case 'solana':
      return null
    case 'flow':
      return (
        <ImportFlowTokenTx
          {...{onClose, onBack, accountId}}
          accounts={accounts as FlowAccountInfo[]}
        />
      )
    default: {
      if (isEvmBlockchain(blockchain)) {
        return (
          <ImportEvmTokens
            {...{blockchain, onClose, onBack, accountId}}
            accounts={accounts as EvmAccountInfo<typeof blockchain>[]}
          />
        )
      }
      return safeAssertUnreachable(blockchain)
    }
  }
}
