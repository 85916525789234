import type {
  EvmBlockchain,
  EvmTokenAmount,
  EvmTokenId,
  EvmTokenMetadata,
} from '../../../wallet/evm'
import {useAllTokenBalances} from '../../../wallet/evm'
import {useLoadTokens} from '../loadTokens'
import type {
  UseAssetsConversionParams,
  UseBlockchainAssetsReturnType,
} from '../types'

export function getEvmTokenSortingLabel(
  tokenMetadata: EvmTokenMetadata<EvmBlockchain>,
): string {
  const primaryLabel = `${tokenMetadata.name || tokenMetadata.ticker || ''}`

  return primaryLabel !== ''
    ? `${primaryLabel} (${tokenMetadata.contractAddress})`
    : `${tokenMetadata.contractAddress}`
}

const getNumAccountsOwningToken = <TBlockchain extends EvmBlockchain>(
  tokens: EvmTokenAmount<TBlockchain>[],
  tokenId: EvmTokenId<TBlockchain>,
) => tokens.filter((t) => t.token.id === tokenId).length

export function useEvmTokens<TBlockchain extends EvmBlockchain>(
  blockchain: TBlockchain,
  {conversionRates, currency, enabled}: UseAssetsConversionParams,
): UseBlockchainAssetsReturnType {
  const tokensQuery = useAllTokenBalances(blockchain, enabled)

  return useLoadTokens({
    blockchain,
    conversionRates,
    currency,
    enabled,
    getNumAccountsOwningToken(
      {tokenId}: {tokenId: EvmTokenId<TBlockchain>},
      {tokens},
    ) {
      return getNumAccountsOwningToken(tokens, tokenId)
    },
    getTokenSortingLabel: getEvmTokenSortingLabel,
    extraQueries: {tokens: tokensQuery},
  })
}
