import {Box} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {useGetConversionRates} from 'src/features/conversionRates/application'
import type {ConversionRates} from 'src/features/conversionRates/domain'
import {blockchainToWalletKind} from 'src/wallet/walletKind'

import {
  FormattedAsset,
  AssetIcon,
  AccordionCard,
  AccountCardCol,
  FormattedBlockchainAssetsAsCurrency,
  FormattedAssetAsCurrency,
} from '../../../../components'
import type {TokenAmount, AccountInfo, TokenMetadata} from '../../../../types'
import {LoadAssetsError} from '../../assetList/AssetsList'
import {AccountListHeader, AccountListItemLayout} from '../AccountListLayout'
import {AssetRow} from '../AccountListUtils'

import {
  Actions,
  ActionsCol,
  ExpansionButton,
  useAccountListCardCommons,
  useVirtualizedAssetListData,
  VirtualizedAssetList,
} from './common'

type AccountCardProps = {
  accountInfo: AccountInfo
  StakingCol: React.ReactNode
  tokensMetadata: TokenMetadata[]
  stakingColText?: string
  tokens: Array<TokenAmount>
  tokensError?: unknown | undefined
  extendedAccountInfo?: {label: string; value: string; link?: JSX.Element}[]
  conversionRates?: ConversionRates
}

export function AccountListCard({
  accountInfo,
  tokens: _tokens,
  StakingCol,
  stakingColText,
  extendedAccountInfo,
  tokensError,
  tokensMetadata,
  conversionRates,
}: AccountCardProps) {
  const {rates} = useGetConversionRates()

  const {
    hideZeroBalances,
    hideNfts,
    action,
    onCloseAction,
    onOpenAction,
    actionRef,
    expanded,
    onCardClick,
    getBlockchainName,
    tokensMetadataMap,
  } = useAccountListCardCommons({tokensMetadata})

  const {id, address, balance, blockchain, cryptoProviderType, name} =
    accountInfo
  const classes = useStyles()
  const {t} = useTranslation()

  const _AssetIcon = <AssetIcon blockchain={blockchain} />

  const tokens = _tokens.filter((t) => tokensMetadataMap[t.token.id] != null)

  // We only consider tokens for the asset list here (non-evm account card).
  // Native asset is displayed separately so that it would always be on top.
  const assets = tokens.map(({amount, token}) => ({
    type: 'token' as const,
    blockchain,
    token,
    tokenMetadata: tokensMetadataMap[token.id]!,
    amount,
  }))

  const virtualizedAssetListData = useVirtualizedAssetListData({
    assets,
    accountId: id,
    hideZeroBalances,
    hideNfts,
    tokensMetadataMap,
    conversionRates,
    blockchain,
  })

  const filteredTokensLength = virtualizedAssetListData.assets.length

  return (
    <>
      <AccordionCard
        elevation={expanded ? 1 : 0}
        expanded={expanded}
        onCardClick={onCardClick}
        doNotMountDetailsIfNotExpanded
        mainContent={
          <>
            <AccountListHeader stakingColText={stakingColText} />
            <Box mt={2} />

            <AccountListItemLayout
              titleCol={
                <AccountCardCol
                  {...{
                    walletKind: blockchainToWalletKind(blockchain),
                    blockchain,
                    name,
                    cryptoProviderType,
                    address,
                  }}
                />
              }
              priceCol={
                <FormattedBlockchainAssetsAsCurrency
                  blockchain={blockchain}
                  nativeAmount={balance}
                  tokens={tokens.map(({amount, token}) => ({
                    tokenMetadata: tokensMetadataMap[token.id]!,
                    amount,
                  }))}
                  includeCurrencySymbol
                  isSensitiveInformation
                  requireAllBalancesDefined={false}
                  conversionRates={rates}
                />
              }
              balanceCol={
                <FormattedAsset
                  amount={balance}
                  blockchain={blockchain}
                  isSensitiveInformation
                />
              }
              stakingCol={StakingCol}
              actionsCol={
                <ActionsCol
                  blockchain={blockchain}
                  accountInfo={accountInfo}
                  actionRef={actionRef}
                  onOpenAction={onOpenAction}
                />
              }
            />
          </>
        }
        actions={
          <ExpansionButton
            expanded={expanded}
            assetsCount={filteredTokensLength + 1}
          />
        }
        details={
          <>
            <Box className={classes.assetsHeader}>
              <AccountListHeader
                titleColText={t('Asset')}
                priceColText={t('Asset value')}
              />
            </Box>
            {/* Native asset */}
            <AssetRow
              id={id}
              blockchain={blockchain}
              description={getBlockchainName(blockchain)}
              Icon={_AssetIcon}
              disableBottomBorder={!tokens || tokens?.length === 0}
              priceCol={
                <FormattedAssetAsCurrency
                  isSensitiveInformation
                  blockchain={blockchain}
                  balance={balance}
                  includeCurrencySymbol
                  conversionRates={rates}
                />
              }
              balanceCol={
                <FormattedAsset
                  amount={balance}
                  blockchain={blockchain}
                  isSensitiveInformation
                />
              }
            />
            {/* We need wrapper` around AutoSizer otherwise it can not size itself properly */}
            {!tokensError ? (
              <VirtualizedAssetList
                virtualizedAssetListData={virtualizedAssetListData}
              />
            ) : (
              <LoadAssetsError error={{type: 'tokens', blockchain}} />
            )}
          </>
        }
      />
      <Actions
        action={action}
        walletKind={blockchainToWalletKind(blockchain)}
        blockchain={blockchain}
        accountInfo={accountInfo}
        extendedAccountInfo={extendedAccountInfo}
        onCloseAction={onCloseAction}
      />
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  assetsHeader: {
    background: theme.palette.background.paper,
    padding: theme.spacing(0.5, 2),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}))
