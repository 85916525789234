import {Sentry} from '@nufi/frontend-common'
import type {EvmContractAddress} from '@nufi/wallet-evm'
import {getEvmTokenId, isEvmBlockchain} from '@nufi/wallet-evm'
import {getFlowContractId, getFlowTokenId} from '@nufi/wallet-flow'
import type {SolanaMintAddress} from '@nufi/wallet-solana'
import {useQuery} from '@tanstack/react-query'
import {useEffect} from 'react'

import config from 'src/config'
import type {ConnectorBlockchain} from 'src/dappConnector/types'
import {useMutation} from 'src/utils/mutation-utils'
import {cachedGetErc20TokenList} from 'src/wallet/evm'
import {cachedGetFungibleTokenList} from 'src/wallet/flow/application'
import {cachedGetOffChainMetadata} from 'src/wallet/solana'
import {useWidgetStore} from 'src/widget/store/widgetStore'

import {MoonpayService} from '../infrastructure/moonpay'

export * from './paymentUrl'

export const moonpayService = new MoonpayService({
  apiKey: config.moonpayApiKey,
  getTokenId: async (params) => {
    try {
      if (isEvmBlockchain(params.blockchain)) {
        const tokensList = await cachedGetErc20TokenList(params.blockchain)

        const contractAddress = params.contractAddress as EvmContractAddress<
          typeof params.blockchain
        >

        const tokenId = getEvmTokenId<typeof params.blockchain>({
          standard: 'ERC20',
          contractAddress,
        })

        // This is here for extra safety to make sure that we are only listing
        // ERC20 and not possibly some tokens that we do not support well.
        if (tokensList[tokenId] == null) {
          return null
        }
        return tokenId
      }

      if (params.blockchain === 'solana') {
        const tokensList = await cachedGetOffChainMetadata()
        const mint = params.contractAddress as SolanaMintAddress
        // This is here for extra safety to make sure that we are only listing
        // tokens that we support.
        if (tokensList[mint] == null) {
          return null
        }
        return mint
      }

      if (params.blockchain === 'flow') {
        const tokens = await cachedGetFungibleTokenList()

        const targetContract = tokens.find(
          (t) =>
            t.id ===
            getFlowTokenId(
              getFlowContractId(params.contractAddress, params.contractName),
            ),
        )

        // This is here for extra safety to make sure that we are only listing
        // tokens that we support.
        if (targetContract == null) {
          return null
        }

        return targetContract.id
      }

      return null
    } catch (err) {
      Sentry.captureException(err)
      return null
    }
  },
})

const keyPrefix = ['ramp', 'moonpay'] as const

export const useMoonpayAssets = () =>
  useQuery({
    queryKey: [...keyPrefix, 'fetchSupportedAssets'] as const,
    queryFn: () => moonpayService.getAssets(),
  })

export const useMoonpayBlockchainTxId = (rampTxId: string) =>
  useQuery({
    queryKey: [...keyPrefix, 'fetchTxBlockchainId', rampTxId] as const,
    queryFn: () => moonpayService.getBlockchainTxId(rampTxId),
  })

export const useGetMoonpayUrlSignature = () =>
  useMutation(
    [...keyPrefix, 'fetchUrlSignature'] as const,
    async (urlToSign: string) => {
      const {signature} = await moonpayService.signUrl(urlToSign)
      return signature
    },
  )

export const useLoadEmbeddedRampSupportStatus = () => {
  const {
    embeddedRampSupportStatus,
    setEmbeddedRampSupportStatus,
    origin,
    blockchain,
  } = useWidgetStore()

  const isEnabledByConfig = (blockchain: ConnectorBlockchain) =>
    config.isEmbeddedMoonpayEnabled &&
    config.embeddedMoonpaySellEnabledBlockchains.includes(blockchain)

  const isOriginWhitelistedQuery = useQuery({
    queryKey: [
      ...keyPrefix,
      'isOriginWhitelistedForEmbeddedRamp',
      origin,
    ] as const,
    queryFn: async () => moonpayService.hasWidgetSupport(origin!),
    enabled:
      blockchain != null &&
      origin != null &&
      isEnabledByConfig(blockchain) &&
      embeddedRampSupportStatus === 'unknown',
  })

  useEffect(() => {
    if (embeddedRampSupportStatus !== 'unknown' || !blockchain) return

    if (!isEnabledByConfig(blockchain) || isOriginWhitelistedQuery.error) {
      setEmbeddedRampSupportStatus('disabled')
      return
    }

    if (isOriginWhitelistedQuery.data !== undefined) {
      setEmbeddedRampSupportStatus(
        isOriginWhitelistedQuery.data ? 'enabled' : 'disabled',
      )
    }
  }, [
    isOriginWhitelistedQuery.data,
    isOriginWhitelistedQuery.error,
    blockchain,
  ])
}

export {signAndSubmitCardanoTxWithConnector} from './connector'
