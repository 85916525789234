import {getProxiedUrl, request, withRetry} from '@nufi/frontend-common'
import {objectEntries} from 'common/src/typeUtils'

import {existingBlockchains} from 'src/blockchainTypes'
import type {
  CoinConversionRates,
  Currency,
  HistoricalConversionRates,
  HistoricalConversionRatesGranularity,
  TokenConversionRates,
  CoingeckoConversionRatesApi,
} from 'src/features/conversionRates/domain'
import type {Blockchain, TokenMetadata} from 'src/types'
import {safeAssertUnreachable} from 'src/utils/assertion'
import {isNft} from 'src/wallet'
import type {EvmBlockchain, EvmTokenMetadata} from 'src/wallet/evm'

import type {CoingeckoBlockchainMetadata} from './blockchainMetadata'
import {coingeckoBlockchainMetadata} from './blockchainMetadata'
import {allCurrenciesToCurrencyId} from './currencyIds'
import {
  blockchainToCoingeckoId,
  parseCoinConversionRates,
  parseHistoricalConversionRates,
  parseTokenConversionRates,
} from './parseUtils'

type BlockchainWithContractAddressSupport = {
  [K in keyof CoingeckoBlockchainMetadata]: CoingeckoBlockchainMetadata[K] extends {
    supportsQueryingByContractAddress: true
  }
    ? K
    : never
}[keyof CoingeckoBlockchainMetadata]

const supportedNativeAssetCoingeckoIds = Object.values(
  coingeckoBlockchainMetadata,
).map(({nativeAssetCoingeckoId}) => nativeAssetCoingeckoId)

const blockchainsWithContractAddressSupport = objectEntries(
  coingeckoBlockchainMetadata,
)
  .filter(
    ([, {supportsQueryingByContractAddress}]) =>
      supportsQueryingByContractAddress,
  )
  .map(([blockchain]) => blockchain)

type NonEvmTokenMetadataWithContractAddressSupport = Extract<
  TokenMetadata,
  {blockchain: BlockchainWithContractAddressSupport}
>

type EvmTokenMetadataWithContractAddressSupport = Extract<
  {
    [K in EvmBlockchain]: EvmTokenMetadata<K>
  }[EvmBlockchain],
  {blockchain: BlockchainWithContractAddressSupport}
>

// we need to treat EvmTokenMetadata as its types as EvmTokenMetadata<EvmBlockchains>
// instead of EvmTokenMetadata<'ethereum'> | EvmTokenMetadata<'polygon'> etc.
// which makes the NonEvmTokenMetadataWithContractAddressSupport evaluate to never
// if BlockchainWithContractAddressSupport includes EVM blockchains
export type TokenMetadataWithContractAddressSupport =
  | NonEvmTokenMetadataWithContractAddressSupport
  | EvmTokenMetadataWithContractAddressSupport

type SupportedTokensPerPlatform = Partial<
  Record<
    string, // platformId
    Map<
      string, // contractAddress
      string // coingeckoId
    >
  >
>

const tokenMetadataToContractAddress = (
  token: TokenMetadataWithContractAddressSupport,
  supportedTokensPerPlatform: SupportedTokensPerPlatform,
): string => {
  switch (token.blockchain) {
    case 'solana':
      return token.id
    case 'ethereum':
    case 'milkomedaC1':
    case 'polygon':
    case 'optimism':
    case 'arbitrumOne':
    case 'base':
      // for evm tokens, coingecko have the contract address lowercased
      return token.contractAddress.toLowerCase()
    case 'flow':
      // 0x3c5959b568896393-FUSD -> A.3C5959B568896393.FUSD
      return token.id.replace('0x', 'A.').replace('-', '.').toUpperCase()
    case 'cardano':
      return supportedTokensPerPlatform['cardano']?.get(
        token.policyId + token.assetNameHex,
      )
        ? token.policyId + token.assetNameHex
        : token.policyId
    default:
      return safeAssertUnreachable(token)
  }
}

const getDayOfYear = () => {
  const now = Date.UTC(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate(),
  )
  const diff = now - Date.UTC(new Date().getFullYear(), 0, 0)
  const days = diff / 24 / 60 / 60 / 1000
  return days
}

const conversionRatesGranularityToDays: Record<
  HistoricalConversionRatesGranularity,
  string
> = {
  '1D': '1',
  '1W': '7',
  '1M': '30',
  '6M': `${30 * 6}`,
  YTD: `${getDayOfYear()}`,
  '1Y': '365',
  MAX: 'max',
}

const getCoingeckoUrl = (path: string): string => {
  const baseUrl = 'https://api.coingecko.com/api/v3'
  return getProxiedUrl(`${baseUrl}${path}`, 'coingecko')
}

const getCoingeckoFallbackUrl = (path: string): string => {
  const baseUrl = 'https://api.coingecko.com/api/v3'
  return `${baseUrl}${path}&cache_policy=coingecko`
}

export const coingeckoConnection = (): CoingeckoConversionRatesApi => {
  async function coingeckoFetch<TResponse>(path: string): Promise<TResponse> {
    try {
      return await request<TResponse>({
        url: getCoingeckoUrl(path),
        timeout: 10000,
      })
    } catch (e) {
      // hotfix as currently (~March 30, 2023) coingecko seems to be blocking our proxy
      // so we fallback to querying coingecko directly from the client which seems to work fine
      return await request<TResponse>({
        url: getCoingeckoFallbackUrl(path),
        timeout: 10000,
      })
    }
  }

  async function _fetchConversionsRatesByCoingeckoIds(coingeckoIds: string[]) {
    const params = new URLSearchParams({
      ids: coingeckoIds.join(','),
      vs_currencies: Object.values(allCurrenciesToCurrencyId).join(','),
      include_24hr_change: 'true',
    })
    // e.g. {"cardano": {"usd": 1.23, "usd_24h_change": 0.12, "eur": 2.34, ...}, ...}
    return coingeckoFetch<{[x: string]: {[x: string]: number}}>(
      `/simple/price?${params.toString()}`,
    )
  }

  type CoingeckoCoinsListItem = {
    id: string
    symbol: string
    name: string
    platforms: {[x: string]: string} // e.g. {"ethereum": "0x1234", "binance-smart-chain": "0x1234"}
  }
  async function fetchCoingeckoCoinsList() {
    return coingeckoFetch<CoingeckoCoinsListItem[]>(
      `/coins/list?include_platform=true`,
    )
  }

  let _coingeckoCoinsListCache: CoingeckoCoinsListItem[] | null = null
  async function cachedFetchCoingeckoCoinsList() {
    if (_coingeckoCoinsListCache) {
      return _coingeckoCoinsListCache
    }
    const result = await fetchCoingeckoCoinsList()
    _coingeckoCoinsListCache = result

    return result
  }

  async function getSupportedTokensPerPlatform() {
    const coinsList = await cachedFetchCoingeckoCoinsList()

    const result: SupportedTokensPerPlatform = {}
    coinsList.forEach((listItem) => {
      Object.entries(listItem.platforms).forEach(
        ([platformId, contractAddress]) => {
          if (!result[platformId]) {
            result[platformId] = new Map()
          }
          result[platformId].set(contractAddress, listItem.id)
        },
      )
    })

    return result
  }

  async function _fetchHistoricalConversionRates(
    blockchain: Blockchain,
    currency: Currency,
    granularity: HistoricalConversionRatesGranularity,
  ) {
    const daysToFetch = conversionRatesGranularityToDays[granularity]

    const path =
      `/coins/${blockchainToCoingeckoId(
        blockchain,
      )}/market_chart?vs_currency=` +
      `${allCurrenciesToCurrencyId[currency]}&days=${daysToFetch}`

    return coingeckoFetch<{prices: number[][]}>(path)
  }

  async function getCoinConversionRates(): Promise<CoinConversionRates> {
    const rates = await _fetchConversionsRatesByCoingeckoIds(
      supportedNativeAssetCoingeckoIds,
    )
    return parseCoinConversionRates(existingBlockchains, rates)
  }

  async function getHistoricalConversionRates(
    blockchain: Blockchain,
    currency: Currency,
    granularity: HistoricalConversionRatesGranularity,
  ): Promise<HistoricalConversionRates> {
    const rates = (await _fetchHistoricalConversionRates(
      blockchain,
      currency,
      granularity,
    )) as {prices: number[][]}
    return parseHistoricalConversionRates(rates)
  }

  const supportsRatesForContractAddress = (
    token: Pick<TokenMetadata, 'blockchain'>,
  ): token is TokenMetadataWithContractAddressSupport => {
    return blockchainsWithContractAddressSupport.includes(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      token.blockchain as any,
    )
  }

  async function enhanceTokensWithCoingeckoId(
    tokenMetadata: TokenMetadata[],
  ): Promise<TokenMetadata[]> {
    const supportedTokensPerPlatform = await getSupportedTokensPerPlatform()
    return tokenMetadata.map((token) => {
      const platformId =
        coingeckoBlockchainMetadata[token.blockchain]?.platformId
      const coingeckoId =
        platformId && supportedTokensPerPlatform[platformId]
          ? supportedTokensPerPlatform[platformId].get(
              tokenMetadataToContractAddress(token, supportedTokensPerPlatform),
            )
          : undefined

      return {
        ...token,
        coingeckoId: token.coingeckoId ?? coingeckoId,
      }
    })
  }

  async function getTokenConversionRatesByCoingeckoIds(
    tokenMetadata: TokenMetadata[],
  ): Promise<TokenConversionRates> {
    const coingeckoIds = tokenMetadata
      .map((token) => token.coingeckoId)
      .filter((coingeckoId): coingeckoId is string => coingeckoId != null)

    const ratesByCoingeckoId = coingeckoIds.length
      ? await _fetchConversionsRatesByCoingeckoIds(coingeckoIds)
      : {}

    return parseTokenConversionRates(ratesByCoingeckoId, tokenMetadata)
  }

  async function getTokenConversionRates(
    tokenMetadata: TokenMetadata[],
  ): Promise<TokenConversionRates> {
    const tokensWithCoingeckoId = (
      await enhanceTokensWithCoingeckoId(tokenMetadata)
    ).filter((token) => token.coingeckoId != null)

    return withRetry(
      () => getTokenConversionRatesByCoingeckoIds(tokensWithCoingeckoId),
      {maxRetries: 5},
    )
  }

  function supportsToken(token: TokenMetadata) {
    return (
      !isNft(token) &&
      (token.coingeckoId != null || supportsRatesForContractAddress(token))
    )
  }

  return {
    getCoinConversionRates,
    getTokenConversionRates,
    getHistoricalConversionRates,
    allCurrenciesToCurrencyId,
    supportsToken,
  }
}
