import {
  SOLANA_DEFAULT_REWARDS_EPOCH_DEPTH,
  sumRewards,
  sumStakedAmounts,
} from '@nufi/wallet-solana'
import BigNumber from 'bignumber.js'
import React from 'react'

import {QueryGuard, LoadStakingDataError} from '../../../components'
import {sumAccountInfoBalances} from '../../../wallet'
import {useGetStakeAccountsInfo} from '../../../wallet/solana'
import {AssetItemLoading} from '../common/Loadings'
import {MyStakingCard} from '../common/myStakingUtils'

export function SolanaMyStakingCard() {
  const stakeAccountsInfoQuery = useGetStakeAccountsInfo()

  return (
    <QueryGuard
      {...stakeAccountsInfoQuery}
      LoadingElement={<AssetItemLoading blockchain="solana" />}
      ErrorElement={<LoadStakingDataError blockchain="solana" />}
    >
      {(accounts) => {
        const combinedStakeAccounts = accounts
          .map((a) => a.stakeAccounts)
          .flat()
        const rewardsBalance = sumRewards(combinedStakeAccounts)
        const stakedBalance = sumStakedAmounts(combinedStakeAccounts)
        const availableBalance = sumAccountInfoBalances(accounts)
        const stakedRatio = stakedBalance
          .plus(availableBalance)
          .isGreaterThan(0)
          ? stakedBalance.dividedBy(stakedBalance.plus(availableBalance))
          : new BigNumber(0)
        // Simplified condition used just for UI appearance
        const hasAlreadyStaked = combinedStakeAccounts.length > 0
        return (
          <MyStakingCard
            blockchain="solana"
            {...{
              hasAlreadyStaked,
              rewardsBalance,
              rewardsEpochDepth: SOLANA_DEFAULT_REWARDS_EPOCH_DEPTH,
              stakedBalance,
              availableBalance,
              stakedRatio,
            }}
          />
        )
      }}
    </QueryGuard>
  )
}
