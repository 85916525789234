/* eslint-disable @typescript-eslint/explicit-function-return-type */

import {coreQueryKeys} from './core'
import {nftsQueryKeys} from './nfts'
import {stakingQueryKeys} from './staking'
import {tokensQueryKeys} from './tokens'

export * from './core'
export * from './tokens'
export * from './nfts'
export * from './staking'
export * from './combined'
export * from './catalyst'

export const queryKeys = {
  ...coreQueryKeys,
  ...stakingQueryKeys,
  ...tokensQueryKeys,
  ...nftsQueryKeys,
}
