import {HelpOutline} from '@mui/icons-material'
import {Box, Button, Divider, Grid, Paper, Typography} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import {useTranslation, Trans} from 'react-i18next'
import {useHistory} from 'react-router-dom'

import type {StakingBlockchain} from '../../blockchainTypes'
import {
  ListHeaderTooltipTitle,
  LabeledIconWithTooltip,
  Aligner,
  BlockchainIcon,
} from '../../components'
import {protocolsOverview, getBlockchainExternalLinks} from '../../constants'
import {routeTo} from '../../router'
import {useGetBlockchainName, useGetCoinName} from '../../utils/translations'

import {useStakingBlockchains} from './utils'

type ProtocolCardLayoutProps = {
  protocolCol: React.ReactNode
  APYCol: React.ReactNode
  minBalanceCol: React.ReactNode
  lockupCol: React.ReactNode
  feeCol: React.ReactNode
}

function ProtocolCardLayout({
  protocolCol,
  APYCol,
  minBalanceCol,
  lockupCol,
  feeCol,
}: ProtocolCardLayoutProps) {
  return (
    <Box p={2}>
      <Grid container justifyContent="space-between" alignContent="center">
        <Grid item xs={3}>
          {protocolCol}
        </Grid>
        <Grid item xs={1}>
          <Aligner align="right">{APYCol}</Aligner>
        </Grid>
        <Grid item xs={3}>
          <Aligner align="right">{minBalanceCol}</Aligner>
        </Grid>
        <Grid item xs={2}>
          <Aligner align="right">{lockupCol}</Aligner>
        </Grid>
        <Grid item xs={2}>
          <Aligner align="right">{feeCol}</Aligner>
        </Grid>
      </Grid>
    </Box>
  )
}

function ProtocolCardHeaderEntry({
  content,
  tooltipText = '',
}: {
  content: React.ReactNode
  tooltipText?: string
}) {
  return (
    <ListHeaderTooltipTitle desc={tooltipText}>
      {content}
    </ListHeaderTooltipTitle>
  )
}

function ProtocolCardValueEntry({content}: {content: React.ReactNode}) {
  return <Typography variant="h6">{content}</Typography>
}

function ProtocolCardBottomRow({blockchain}: {blockchain: StakingBlockchain}) {
  const {t} = useTranslation()
  const classes = useStyles()
  const history = useHistory()

  return (
    <Box display="flex" justifyContent="flex-end" p={2}>
      <Button
        variant="outlined"
        color="primary"
        className={classes.button}
        href={getBlockchainExternalLinks(blockchain).learnMore}
        target="_blank"
      >
        {t('Learn More')}
      </Button>
      <Box ml={2}>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={() =>
            history.push(routeTo.staking.myStaking.blockchain(blockchain).index)
          }
        >
          {t('Start Staking')}
        </Button>
      </Box>
    </Box>
  )
}

function MinimumBalanceTooltip({blockchain}: {blockchain: StakingBlockchain}) {
  const {t} = useTranslation()

  return (
    <Box fontSize="body2.fontSize">
      <Trans
        i18nKey="minimum_balance"
        t={t}
        tOptions={{context: blockchain, contextType: 'stakingBlockchain'}}
        values={protocolsOverview.minimumBalance(blockchain)}
        components={{
          p: <p />,
          ul: <ul style={{listStyle: 'none', padding: '0px'}} />,
          li: <li />,
          Price: <Box color="warning.light" my={0.5} />,
        }}
      />
    </Box>
  )
}

function MinimumBalance({blockchain}: {blockchain: StakingBlockchain}) {
  return (
    <LabeledIconWithTooltip
      Label={protocolsOverview.minimumBalance(blockchain).minimum}
      Icon={<HelpOutline />}
      title={<MinimumBalanceTooltip blockchain={blockchain} />}
      spacing={0.5}
    />
  )
}

function ProtocolCard({blockchain}: {blockchain: StakingBlockchain}) {
  const {t} = useTranslation()
  const getBlockchainName = useGetBlockchainName()
  const getCoinName = useGetCoinName()
  return (
    <Box mt={1}>
      <Paper elevation={0} variant="outlined">
        <ProtocolCardLayout
          protocolCol={<>{t('Protocol')}</>}
          APYCol={
            <ProtocolCardHeaderEntry
              content={t('APY %')}
              tooltipText={t('Annual Percentage Yield')}
            />
          }
          minBalanceCol={
            <ProtocolCardHeaderEntry
              content={t('Minimum Balance')}
              tooltipText={t('Minimum balance required for staking')}
            />
          }
          lockupCol={
            <ProtocolCardHeaderEntry
              content={t('Locked')}
              tooltipText={t(
                'Assets are locked and inaccessible during the staking period.',
              )}
            />
          }
          feeCol={
            <ProtocolCardHeaderEntry
              content={t('Validator Fee')}
              tooltipText={t(
                'Average commission rate earned by the validators (taken from the rewards before they are distributed).',
              )}
            />
          }
        />

        <ProtocolCardLayout
          protocolCol={
            <ProtocolCardValueEntry
              content={
                <Box display="flex" alignItems="center">
                  <BlockchainIcon blockchain={blockchain} exactSize={24} />
                  <Box pl={2}>
                    {getBlockchainName(blockchain)} ({getCoinName(blockchain)})
                  </Box>
                </Box>
              }
            />
          }
          APYCol={
            <ProtocolCardValueEntry
              content={protocolsOverview.APY(blockchain)}
            />
          }
          minBalanceCol={
            <ProtocolCardValueEntry
              content={<MinimumBalance blockchain={blockchain} />}
            />
          }
          lockupCol={
            <ProtocolCardValueEntry
              content={protocolsOverview.locked(blockchain)}
            />
          }
          feeCol={
            <ProtocolCardValueEntry
              content={protocolsOverview.validatorFee(blockchain)}
            />
          }
        />
        <Divider />
        <ProtocolCardBottomRow blockchain={blockchain} />
      </Paper>
    </Box>
  )
}

export default function ProtocolsPage() {
  const stakingBlockchains = useStakingBlockchains()
  return (
    <Box mr={3} pt={1}>
      {stakingBlockchains
        .concat()
        .sort()
        .map((blockchain) => (
          <ProtocolCard key={blockchain} blockchain={blockchain} />
        ))}
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  infoIcon: {
    marginRight: theme.spacing(1),
  },
  button: {
    textTransform: 'none',
  },
}))
