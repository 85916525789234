import {Warning as WarningIcon} from '@mui/icons-material'
import type {TextFieldProps} from '@mui/material'
import {InputAdornment, Tooltip} from '@mui/material'
import BigNumber from 'bignumber.js'
import React from 'react'

import type {RowEndAdornmentProps} from 'src/components'
import {
  AssetIcon,
  FormattedAsset,
  FormattedTokenAmount,
  AssetAmountField,
  WithTooltip,
  getTickerBasedIdentifier,
} from 'src/components'
import type {Blockchain, Nft, TokenMetadata} from 'src/types'
import {isValidAmountFieldValue, sanitizeAmountFieldValue} from 'src/utils/form'
import {useGetBlockchainName, useGetCoinName} from 'src/utils/translations'
import {getTokenNameAndIdentifier, isNft} from 'src/wallet'
import {WithWeakTokenMetadata} from 'src/wallet/public/ui'

import type {NativeAssetSchema, TokenSchema, Asset} from './types'
import type {MaxAmountButtonProps} from './utils'
import {MaxAmountButton, isBalanceNonFungible} from './utils'

type NativeAssetAmountFieldProps = {
  className?: string
  onDelete?: () => void
  balance: BigNumber
  asset: NativeAssetSchema
  onBlur: (e: React.FocusEvent<HTMLInputElement, Element>) => void
}

export const NativeAssetAmountField = ({
  blockchain,
  onDelete,
  asset,
  balance,
  className,
  onBlur,
  ...rest
}: Pick<
  UseGetCommonAssetFieldProps,
  'blockchain' | 'getAssetFieldProps' | 'errorMessage' | 'name' | 'onChange'
> &
  NativeAssetAmountFieldProps) => {
  const {disableDelete, ...commonProps} = useGetCommonAssetFieldProps({
    asset,
    blockchain,
    ...rest,
  })

  const getTicker = useGetCoinName()
  const getName = useGetBlockchainName()

  return (
    <AssetAmountField
      className={className}
      assetName={getName(blockchain)}
      ticker={getTicker(blockchain)}
      id={getName(blockchain)}
      isViewOnly={false}
      value={asset.amount}
      rowEndAdornment={{
        type: 'deleteIcon',
        onDelete: disableDelete ? undefined : onDelete,
      }}
      FormattedBalance={
        <FormattedAsset
          includeAssetSymbol={false}
          amount={balance}
          blockchain={blockchain}
          isSensitiveInformation={false}
        />
      }
      onBlur={onBlur}
      {...commonProps}
    />
  )
}

type TokenAmountFieldProps = {
  asset: TokenSchema
  onDelete?: () => void
  className?: string
  balance: BigNumber
  nftInfo?: Nft | null
  tokenMetadata: TokenMetadata
  onBlur: (e: React.FocusEvent<HTMLInputElement, Element>) => void
} & Pick<
  UseGetCommonAssetFieldProps,
  'blockchain' | 'getAssetFieldProps' | 'errorMessage' | 'name' | 'onChange'
>

const NON_FUNGIBLE_AMOUNT = new BigNumber(1)

export const TokenAmountField = ({
  asset,
  blockchain,
  onDelete,
  className,
  nftInfo,
  tokenMetadata,
  balance,
  onBlur,
  ...rest
}: TokenAmountFieldProps) => {
  const {assetName, tokenIdentifier} = getTokenNameAndIdentifier(
    nftInfo || null,
    tokenMetadata,
  )

  const isNonFungible = isBalanceNonFungible(
    isNft(tokenMetadata),
    tokenMetadata.decimals,
    balance,
  )

  const ticker = getTickerBasedIdentifier(tokenMetadata) || undefined

  const {amount} = asset
  const {onChange, disableDelete, ...commonProps} = useGetCommonAssetFieldProps(
    {
      asset,
      blockchain,
      ...rest,
    },
  )

  const props = {
    ...commonProps,
    className,
    assetName,
    ticker,
    id: tokenIdentifier,
    rowEndAdornment: {
      type: 'deleteIcon' as const,
      onDelete: disableDelete ? undefined : onDelete,
    },
  }
  return (
    <>
      {isNonFungible ? (
        <AssetAmountField
          isViewOnly
          {...props}
          amount={NON_FUNGIBLE_AMOUNT}
          FormattedBalance={
            <FormattedAsset
              includeAssetSymbol
              amount={NON_FUNGIBLE_AMOUNT}
              tokenMetadata={tokenMetadata}
              blockchain={blockchain}
              isSensitiveInformation={false}
            />
          }
        />
      ) : (
        <AssetAmountField
          {...props}
          onBlur={onBlur}
          onChange={onChange}
          isViewOnly={false}
          value={amount}
          FormattedBalance={
            <FormattedTokenAmount
              blockchain={blockchain}
              tokenInfo={tokenMetadata}
              value={balance}
              isSensitiveInformation={false}
            />
          }
        />
      )}
    </>
  )
}

export type GetAssetFieldArgs = (
  asset: Asset,
  fieldState: {errorMessage?: string; name: string},
) => {
  maxAmountOptions?: MaxAmountButtonProps
  disabled?: boolean
  helperText?: React.ReactNode
  FieldInfoTooltip?: JSX.Element | undefined
  disableDelete?: boolean
  inputEndAdornment?: React.ReactNode
  rowEndAdornment?: RowEndAdornmentProps
  inputProps?: TextFieldProps['inputProps']
}

type UseGetCommonAssetFieldProps = {
  asset: Asset
  blockchain: Blockchain
  onChange: (value: string) => void
  errorMessage?: string
  name: string
  getAssetFieldProps?: GetAssetFieldArgs
}

const useGetCommonAssetFieldProps = ({
  errorMessage,
  onChange,
  name,
  asset,
  blockchain,
  getAssetFieldProps,
}: UseGetCommonAssetFieldProps) => {
  const fieldProps = getAssetFieldProps?.(asset, {
    errorMessage,
    name,
  })

  const InfoIcon = fieldProps?.FieldInfoTooltip ? (
    <Tooltip title={fieldProps?.FieldInfoTooltip}>
      <WarningIcon
        fontSize="small"
        color="warning"
        sx={{mr: 1, height: 'inherit'}}
      />
    </Tooltip>
  ) : undefined

  return {
    name,
    renderAssetIcon: (iconSize: number) => (
      <WithWeakTokenMetadata
        blockchain={blockchain}
        tokenId={asset.type === 'token' ? asset.tokenId : undefined}
      >
        {(tokenMetadata) => (
          <AssetIcon
            exactSize={iconSize}
            blockchain={blockchain}
            tokenMetadata={tokenMetadata}
            showBlockchainBadge
          />
        )}
      </WithWeakTokenMetadata>
    ),
    helperText: errorMessage,
    onChange: (value: string) => {
      const sanitized = sanitizeAmountFieldValue(value)
      const isValid = isValidAmountFieldValue(sanitized) || sanitized === ''
      if (!isValid) return
      onChange(sanitized)
    },
    error: !!errorMessage,
    InfoIcon,
    FieldProps: fieldProps
      ? {
          InputProps: {
            endAdornment:
              fieldProps.inputEndAdornment ||
              (fieldProps.maxAmountOptions && (
                <InputAdornment position="end">
                  {fieldProps.maxAmountOptions?.disabled &&
                  fieldProps.maxAmountOptions?.helperText ? (
                    <WithTooltip
                      title={fieldProps.maxAmountOptions.helperText}
                      placement="left"
                    >
                      <MaxAmountButton {...fieldProps.maxAmountOptions} />
                    </WithTooltip>
                  ) : (
                    <MaxAmountButton {...fieldProps.maxAmountOptions} />
                  )}
                </InputAdornment>
              )),
          },
          inputProps: fieldProps.inputProps,
        }
      : undefined,
    ...fieldProps,
  }
}
