import {Settings as SettingsIcon} from '@mui/icons-material'
import {Stack, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {Button, SafeCenterAligner} from 'src/components'
import {useUiStore} from 'src/store/ui'

export const NoBlockchainsEnabledScreen = () => {
  const {t} = useTranslation()
  const {setIsBlockchainManagementModalOpen} = useUiStore()
  return (
    <SafeCenterAligner>
      <Stack sx={{gap: 2}}>
        <Typography variant="h5">{t('No blockchains enabled')}</Typography>
        <Button
          variant="contained"
          textTransform="none"
          size="large"
          color="primary"
          onClick={() => setIsBlockchainManagementModalOpen(true)}
          startIcon={<SettingsIcon />}
        >
          {t('Manage blockchains')}
        </Button>
      </Stack>
    </SafeCenterAligner>
  )
}
