import BigNumber from 'bignumber.js'

import type {LondonGasPriority, EvmWei} from './sdk'
import {gweiToWei} from './sdk'
import type {EvmTokenSearchingKeys} from './types'

/**
 * The number of confirmations (new blocks) required to be mined on top of our transaction
 * in order for us to consider it final. Only after this do we show it as successful.
 *
 * According to https://blog.ethereum.org/2015/09/14/on-slow-and-fast-block-times/, this
 * number is very close to actual finality. This changes when we consider the economical model,
 * but in that case we would need to wait 1+ day, which is unrealistic.
 *
 * UPDATE: given metamask does not wait for more confirmation (and we want to support tx "cancel" | "speedup"
 * within having 2 pending states) we only wait for a single confirmation.
 */
export const NUMBER_OF_CONFIRMATIONS_REQUIRED = 1

/**
 * Maximum number of ETH accounts supported by NuFi wallet.
 */
export const MAX_ACCOUNT_COUNT = 10

export const FALLBACK_PRIORITY_GAS_LEVELS: Record<LondonGasPriority, EvmWei> = {
  // empirically acquired values from metamask, and testnet/mainnet explorers
  low: gweiToWei('1.5'),
  medium: gweiToWei('2'),
  high: gweiToWei('2.5'),
}

/**
 * Amount of gas units required to send ETH from EOA (externally owned account) to another EOA.
 *
 * See https://ethereum.org/en/developers/docs/gas
 */
export const EVM_TRANSFER_GAS_UNITS = new BigNumber(21000) as EvmWei

export const EVM_DECIMALS = {
  WEI: 0,
  GWEI: 9,
  BASE: 18,
}

// https://github.com/MetaMask/metamask-extension/blob/7ba0f78a84c2c3a9bbd17b9d921a0700fafa0750/ui/pages/send/send.utils.js#L81
// metamask multiplies the estimate by 1.5 to ensure smart contract txs go through,
// since estimates can be inaccurate with more complex smart contracts (e.g., calling other contracts)
export const CONTRACT_GAS_LIMIT_ESTIMATE_MULTIPLIER = 1.5

// https://legacy.ethgasstation.info/blog/gas-limit/#:~:text=Simple%20transfers%20typically%20require%20a,limit%20will%20need%20to%20be.
// Most ERC20 tokens are executable in <21_000, 100_000> gas limit interval,
// although most complex smart contracts can even require 200_000 or more
export const FALLBACK_CONTRACT_GAS_LIMIT = 100_000

export const EVM_TOKEN_SEARCHING_KEYS: EvmTokenSearchingKeys = [
  'name',
  'id',
  'ticker',
]
