import {Typography, InputAdornment, TextField} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import type BigNumber from 'bignumber.js'
import React from 'react'
import {useTranslation} from 'react-i18next'

import type {Blockchain} from '../types'

import {FormattedAsset} from './formatting'
import {InlineLoading} from './visual/Loading'

type TransactionFeeProps = {
  amount: BigNumber | undefined
  blockchain: Blockchain
  label?: string
  isLoading?: boolean
  includeAssetSymbol?: boolean
}

export function TransactionFee({
  amount,
  blockchain,
  isLoading,
  label,
  includeAssetSymbol = true,
}: TransactionFeeProps) {
  const {t} = useTranslation()
  const classes = useStyles()

  const LoadingContent = (
    <Typography noWrap className={classes.fullWidth}>
      {t('Calculating fee...')}
    </Typography>
  )

  const FeeContent = amount ? (
    <Typography noWrap className={classes.fullWidth}>
      <FormattedAsset
        blockchain={blockchain}
        amount={amount}
        includeAssetSymbol={includeAssetSymbol}
        isSensitiveInformation={false}
      />
    </Typography>
  ) : null

  const NotCalculatedYetContent = (
    <Typography noWrap className={classes.fullWidth}>
      {t('Not calculated')}
    </Typography>
  )

  return (
    <TextField
      label={label || t('Network fee')}
      variant="outlined"
      disabled
      fullWidth
      InputProps={{
        startAdornment: isLoading
          ? LoadingContent
          : amount != null
            ? FeeContent
            : NotCalculatedYetContent,
        endAdornment: isLoading ? (
          <InputAdornment position="end">
            <InlineLoading size={20} />
          </InputAdornment>
        ) : null,
      }}
    />
  )
}

const useStyles = makeStyles(() => ({
  fullWidth: {
    width: '100%',
  },
}))
