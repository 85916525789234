import {Box, Tabs, Tab, tabsClasses} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  useLocation,
  useHistory,
  Switch,
  Route,
  Redirect,
  matchPath,
} from 'react-router-dom'

import {IS_EXCHANGE_ENABLED} from '../../constants'
import {routeTo} from '../../router'
import {useCallbackRef} from '../../utils/general'
import {useTabsStyles} from '../../utils/layoutUtils'
import {NftsPage} from '../nfts'

import {Accounts} from './Accounts'
import {AssetDetail, NftDetail} from './assetDetail/AssetDetail'
import {Assets} from './Assets'
import {Exchange} from './Exchange'
import {
  PortalContentContext,
  PortfolioPageContextProvider,
  usePortfolioPageContext,
} from './PortfolioContext'

function PortfolioTabs() {
  const classes = useStyles()
  const {t} = useTranslation()
  const location = useLocation()
  const history = useHistory()
  const {commonTabStyles} = useTabsStyles()
  const {callbackRef, htmlNode} = useCallbackRef()

  const value = (() => {
    if (matchPath(location.pathname, routeTo.portfolio.assets.index)) {
      return routeTo.portfolio.assets.index
    }
    if (matchPath(location.pathname, routeTo.portfolio.accounts.index)) {
      return routeTo.portfolio.accounts.index
    }
    if (matchPath(location.pathname, routeTo.portfolio.nfts.index)) {
      return routeTo.portfolio.nfts.index
    }
    if (matchPath(location.pathname, routeTo.portfolio.exchange.index)) {
      return routeTo.portfolio.exchange.index
    }
    return routeTo.portfolio.assets.index
  })()

  const onChange = (event: React.ChangeEvent<unknown>, value: string) => {
    history.replace(value)
  }

  const {blockchain} = usePortfolioPageContext()

  return (
    <Box className={classes.wrapper}>
      <Tabs
        className={commonTabStyles}
        {...{value, onChange}}
        indicatorColor="primary"
        textColor="primary"
        // make height constant across tabs with Swap, Buy/Sell, Add Account buttons
        sx={{[`.${tabsClasses.flexContainer}`]: {minHeight: 66}}}
      >
        <Tab value={routeTo.portfolio.assets.index} label={t('Assets')} />
        <Tab value={routeTo.portfolio.nfts.index} label={t('NFTs')} />
        <Tab
          value={routeTo.portfolio.accounts.index}
          label={t('Accounts')}
          data-test-id="portfolio-accounts-tab"
        />
        {IS_EXCHANGE_ENABLED && (
          <Tab
            value={routeTo.portfolio.exchange.index}
            label={t('Exchange History')}
          />
        )}
        <Tab
          component="div"
          role="none" // remove aria-role as this doesn't behave as a "tab"
          sx={{marginLeft: 'auto'}}
          label={<Box ref={callbackRef} />}
        />
      </Tabs>

      <PortalContentContext.Provider value={htmlNode}>
        <Box className={classes.content}>
          <Switch>
            <Route path={routeTo.portfolio.assets.index} component={Assets} />
            <Route
              path={routeTo.portfolio.nfts.blockchain(':blockchain').index}
              component={NftsPage}
            />
            <Route
              path={routeTo.portfolio.accounts.index}
              component={Accounts}
            />
            <Redirect
              path={routeTo.portfolio.nfts.index}
              to={routeTo.portfolio.nfts.blockchain(blockchain || 'none').index}
            />
            {IS_EXCHANGE_ENABLED && (
              <Route
                path={routeTo.portfolio.exchange.index}
                component={Exchange}
              />
            )}
            <Redirect
              path={routeTo.portfolio.index}
              to={routeTo.portfolio.assets.index}
            />
          </Switch>
        </Box>
      </PortalContentContext.Provider>
    </Box>
  )
}

const PortfolioPage = () => {
  return (
    <PortfolioPageContextProvider>
      <Switch>
        <Route
          path={routeTo.portfolio.assets.blockchain(':blockchain').detail.index}
          component={AssetDetail}
        />
        <Route
          path={routeTo.portfolio.nfts.blockchain(':blockchain').detail.index}
          component={NftDetail}
        />
        <Route component={PortfolioTabs} />
      </Switch>
    </PortfolioPageContextProvider>
  )
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  tabs: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  content: {
    flex: 1,
    overflow: 'auto',
  },
}))

export default PortfolioPage
