import {ToggleButtonGroup, ToggleButton} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {IS_EMBEDDED_MOONPAY_SELL_ENABLED} from 'src/constants'

import {RampOperation} from '../../../../../domain'

import {useRampFlowDetailsForm} from './detailsForm'

export const RampFlowOperationToggle = () => {
  const {t} = useTranslation()
  const {
    formDispatch,
    values: {operation},
  } = useRampFlowDetailsForm()

  const isSellDisabled = !IS_EMBEDDED_MOONPAY_SELL_ENABLED

  return (
    <ToggleButtonGroup
      color="primary"
      exclusive
      fullWidth
      value={operation}
      onChange={(_, operation: unknown) => {
        if (
          operation === RampOperation.BUY ||
          operation === RampOperation.SELL
        ) {
          formDispatch({type: 'setOperation', payload: {operation}})
        }
      }}
    >
      <ToggleButton value={RampOperation.BUY}>
        <span>{t('Buy crypto')}</span>
      </ToggleButton>
      <ToggleButton value={RampOperation.SELL} disabled={isSellDisabled}>
        <span>{t('Sell crypto')}</span>
      </ToggleButton>
    </ToggleButtonGroup>
  )
}
