import * as _ from 'lodash'
import {useCallback, useEffect, useInsertionEffect, useRef} from 'react'

import type {HexString} from '../types'

export function sleep(ms: number): Promise<void> {
  return new Promise((r) => setTimeout(r, ms))
}

type CallbackFn = (() => Promise<unknown>) | (() => unknown)

/**
 * Wrapper around `useEffect` and `setInterval` combo that re-runs the `useEffect` on `fn` or
 * `pollInterval` change.
 * @param fn function to delay
 * @param pollInterval delay interval
 */
export function useEffectWithPoll<T extends CallbackFn>(
  fn: T,
  pollInterval: number,
) {
  useEffect(() => {
    const _interval = setInterval(() => {
      fn()
    }, pollInterval)
    return () => clearInterval(_interval)
  }, [fn, pollInterval])
}

/**
 * Wrapper around `useEffect` and `setTimeout` combo that re-runs the `useEffect` on `fn` or
 * `delay` change.
 * @param fn function to poll
 * @param delay delay
 */
export function useEffectWithDelay<T extends CallbackFn>(fn: T, delay: number) {
  useEffect(() => {
    const _timeout = setTimeout(() => {
      fn()
    }, delay)
    return () => clearTimeout(_timeout)
  }, [fn, delay])
}

/**
 * IMPORTANT: **THE RETURN VALUE OF THIS HOOK CANNOT BE CALLED IN RENDER, ONLY IN EVENT HANDLERS!**
 *
 * Takes an event `handler` and returns a stable reference to it, meaning that the return value
 * of this hook never changes, but if the `handler` does, then the returned function will still
 * correctly call the fresh `handler`.
 *
 * Named "experimental" because there might be edge cases but it's still useful if you need it.
 *
 * This polyfill using `useInsertionEffect()` has been suggested by Dan Abramov but has since been
 * removed from React docs. An older implementation can be found in legacy React docs as
 * {@link https://legacy.reactjs.org/docs/hooks-faq.html#how-to-read-an-often-changing-value-from-usecallback `useEventCallback()`}.
 *
 * In the new docs, it seems to have been replaced by {@link https://react.dev/reference/react/experimental_useEffectEvent `useEffectEvent()`},
 * which is similar but not exactly the same.
 *
 * @see {@link https://blog.bitsrc.io/a-look-inside-the-useevent-polyfill-from-the-new-react-docs-d1c4739e8072 Explanation of `useEvent()`}
 * @see {@link https://github.com/reactjs/rfcs/pull/220 Obsolete React RFC on `useEvent()`}
 *
 * @summary Userland implementation of `useEvent()` from an obsolete React RFC.
 */
export const useExperimentalEvent = <TArgs extends unknown[], TReturn>(
  handler: (...args: TArgs) => TReturn,
): typeof handler => {
  const ref = useRef<typeof handler>(() => {
    throw Error("Can't call event handler from useExperimentalEvent in render!")
  })

  useInsertionEffect(() => {
    ref.current = handler
  }, [handler])

  return useCallback<typeof handler>((...args) => {
    const currentHandler = ref.current // getting rid of `this` context
    return currentHandler(...args)
  }, [])
}

export const asyncNoop = () => Promise.resolve()

export function notEmpty<TValue>(
  value: TValue | null | undefined,
): value is TValue {
  if (value === null || value === undefined) return false
  return true
}

export const isHexString = (data: unknown): data is HexString =>
  _.isString(data) && data.length % 2 === 0 && /^[0-9a-fA-F]*$/.test(data)

/** Lodash unzip with stronger typing for the simple case of two elements. */
export const unzipPairs = <T0, T1>(pairs: [T0, T1][]): [T0[], T1[]] =>
  _.unzip(pairs) satisfies (T0 | T1)[][] as [T0[], T1[]]

export const notNullish = <T>(value: T): value is NonNullable<T> =>
  value != null
