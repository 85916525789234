import {Box, Typography} from '@mui/material'
import type {FormikProps} from 'formik'
import React from 'react'
import {Trans, useTranslation} from 'react-i18next'

import {Alert, QueryGuard} from '../../../components'
import {getHasFormError} from '../../../utils/form'
import type {
  EvmAccountInfo,
  EvmAddress,
  EvmBlockchain,
} from '../../../wallet/evm'
import {useIsContractAddress} from '../../../wallet/evm'

import type {NonceFieldState} from './Nonce'
import {NonceField as _NonceField} from './Nonce'
import type {BaseSchema as FormSchema} from './schema'

export const useGetExtraSummaryContent = () => {
  const {t} = useTranslation()
  return (nonceState: NonceFieldState) =>
    nonceState.mode === 'custom' && (
      <Alert severity="warning">
        <Typography>
          <Trans
            i18nKey="using_custom_nonce_alert"
            t={t}
            values={{nonce: nonceState.value}}
            components={{
              bold: <Box component="span" fontWeight="fontWeightBold" />,
            }}
          />
        </Typography>
      </Alert>
    )
}

type MaybeSendingFundsToContractAddressWarningProps<T extends EvmBlockchain> = {
  address: EvmAddress<T>
  blockchain: T
}

export const MaybeSendingFundsToContractAddressWarning = <
  T extends EvmBlockchain,
>({
  address,
  blockchain,
}: MaybeSendingFundsToContractAddressWarningProps<T>) => {
  const {t} = useTranslation()

  return (
    <QueryGuard
      {...useIsContractAddress(blockchain, address)}
      LoadingElement={<></>}
      ErrorElement={<></>}
    >
      {(isContractAddress) =>
        isContractAddress ? (
          <Alert severity="error">
            <Typography>
              <Trans
                i18nKey="sending_funds_to_contract_address_alert"
                t={t}
                components={{
                  bold: <Box component="span" fontWeight="fontWeightBold" />,
                }}
              />
            </Typography>
          </Alert>
        ) : null
      }
    </QueryGuard>
  )
}

type NonceFieldProps<TBlockchain extends EvmBlockchain> = {
  blockchain: TBlockchain
  formikProps: FormikProps<FormSchema>
  fromAddress: EvmAccountInfo<TBlockchain>['address']
}

export const NonceField = <TBlockchain extends EvmBlockchain>({
  blockchain,
  formikProps,
  fromAddress,
}: NonceFieldProps<TBlockchain>) => {
  const {values, setFieldValue, errors} = formikProps
  const hasErrorAfterTouched = getHasFormError(formikProps, 'after-touched')

  return (
    <Box mt={0.5}>
      <_NonceField
        blockchain={blockchain}
        state={values.nonce}
        fromAddress={fromAddress}
        setState={(value) => {
          setFieldValue('nonce', value)
        }}
        error={hasErrorAfterTouched('nonce')}
        helperText={
          hasErrorAfterTouched('nonce') ? errors.nonce?.value : undefined
        }
      />
    </Box>
  )
}

export const initialNonceFormValues = {
  nonce: {
    mode: 'auto',
    value: '',
  },
} as const

export const parseNonceFormValues = (
  values: FormikProps<FormSchema>['values'],
) => {
  return {
    nonce:
      values.nonce.mode === 'custom' ? parseInt(values.nonce.value, 10) : null,
  }
}
