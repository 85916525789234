import {Box} from '@mui/material'
import React from 'react'

import {useBackgroundImageStyles} from '../../../utils/layoutUtils'
import {getIpfsImageThumbnailUri} from '../../../wallet/utils/nfts'

type IpfsImageProps = {
  src: string
  size: number
}

export function IpfsImage({src, size}: IpfsImageProps) {
  const {bgImage} = useBackgroundImageStyles({
    image: getIpfsImageThumbnailUri(src),
  })
  return (
    <Box className={bgImage} height={size} width={size} borderRadius="5px" />
  )
}
