import {Box, Grid, Typography, alpha, styled} from '@mui/material'
import type {EvmAccountInfo, EvmBlockchain} from '@nufi/wallet-evm'
import BigNumber from 'bignumber.js'
import type {Dictionary} from 'lodash'
import _ from 'lodash'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {BlockchainIcon, EllipsizedString, Icon, Paper} from 'src/components'
import {FormattedBlockchainAssetsAsCurrency} from 'src/components/formatting/FormattedBlockchainAssetsAsCurrency'
import {FormattedCurrency} from 'src/components/formatting/FormattedCurrency'
import type {
  ConversionRates,
  Currency,
} from 'src/features/conversionRates/domain'
import {WithConversionRates} from 'src/features/conversionRates/ui'
import {useSettings} from 'src/features/profile/application'
import {calculateTotalAssetValue} from 'src/utils/totalAssetValue'
import {useGetBlockchainName} from 'src/utils/translations'
import {useEnabledEvmBlockchains} from 'src/wallet/evm'
import type {TokenAmount, TokenMetadata} from 'src/wallet/types'

type EvmBlockchainCellLayoutProps = {
  blockchainName: string
  blockchainIcon: React.ReactNode
  balanceElement: React.ReactNode
  isSelected: boolean
  onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

function EvmBlockchainCellLayout({
  blockchainName,
  blockchainIcon,
  balanceElement,
  isSelected,
  onClick,
}: EvmBlockchainCellLayoutProps) {
  return (
    <EvmBlockchainCellLayoutWrapper
      elevation={0}
      isSelected={isSelected}
      onClick={onClick}
    >
      <Box sx={{alignContent: 'left', paddingY: 0.5}}>
        <Grid
          container
          sx={{
            alignItems: 'center',
            justifyContent: 'left',
            paddingLeft: 1,
            columnGap: 1,
          }}
        >
          <Grid item sx={{display: 'flex'}}>
            {blockchainIcon}
          </Grid>
          <Grid item>
            <Typography variant="body2" color="textSecondary">
              <EllipsizedString
                TypographyProps={{variant: 'body2', color: 'textSecondary'}}
                value={blockchainName}
                endLength={3}
              />
            </Typography>
            <Typography component="span" color="textPrimary">
              {balanceElement}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </EvmBlockchainCellLayoutWrapper>
  )
}

type EvmBlockchainCellProps = {
  blockchain: EvmBlockchain
  nativeAmount: BigNumber
  tokens?: Array<{tokenMetadata: TokenMetadata; amount: BigNumber}>
  isSelected: boolean
  onClick: (blockchain: EvmBlockchain) => void
}

function EvmBlockchainCell({
  blockchain,
  nativeAmount,
  tokens,
  isSelected,
  onClick,
}: EvmBlockchainCellProps) {
  const getBlockchainName = useGetBlockchainName()

  return (
    <EvmBlockchainCellLayout
      blockchainName={getBlockchainName(blockchain)}
      blockchainIcon={<BlockchainIcon blockchain={blockchain} />}
      balanceElement={
        <WithConversionRates>
          {(conversionRates) => (
            <FormattedBlockchainAssetsAsCurrency
              nativeAmount={nativeAmount}
              tokens={tokens}
              blockchain={blockchain}
              includeCurrencySymbol
              isSensitiveInformation
              requireAllBalancesDefined
              shouldUseCompactNotation
              conversionRates={conversionRates}
            />
          )}
        </WithConversionRates>
      }
      isSelected={isSelected}
      onClick={(e) => {
        e.stopPropagation()
        onClick(blockchain)
      }}
    />
  )
}

type AllEvmBlockchainCellProps = {
  sortedBlockchainsBalances: Record<EvmBlockchain, number>
  selectedBlockchain: EvmBlockchain | null
  onSelectBlockchain: (blockchain: EvmBlockchain | null) => void
}

function AllEvmBlockchainCell({
  sortedBlockchainsBalances,
  selectedBlockchain,
  onSelectBlockchain,
}: AllEvmBlockchainCellProps) {
  const {t} = useTranslation()
  const {currency} = useSettings()

  const totalValue = _.sum(Object.values(sortedBlockchainsBalances))

  return (
    <EvmBlockchainCellLayout
      blockchainName={t('All chains')}
      blockchainIcon={<Icon type="ethereumDefaultTokenIcon" />}
      balanceElement={
        <FormattedCurrency
          convertedBalance={{type: 'success', balance: totalValue}}
          currency={currency}
          isSensitiveInformation
          shouldUseCompactNotation
        />
      }
      isSelected={selectedBlockchain == null}
      onClick={(e) => {
        e.stopPropagation()
        onSelectBlockchain(null)
      }}
    />
  )
}

type GetSortedBlockchainsBalancesArgs = {
  accountInfos: EvmAccountInfo<EvmBlockchain>[]
  tokenAmounts: TokenAmount[]
  tokensMetadataMap: Dictionary<TokenMetadata>
  conversionRates?: ConversionRates
  selectedBlockchain: EvmBlockchain | null
  currency: Currency
  enabledEvmBlockchains: EvmBlockchain[]
}

const getSortedBlockchainsBalances = ({
  accountInfos,
  tokenAmounts,
  tokensMetadataMap,
  conversionRates,
  selectedBlockchain,
  currency,
  enabledEvmBlockchains,
}: GetSortedBlockchainsBalancesArgs) =>
  Object.fromEntries(
    (
      accountInfos
        .filter(
          (a) =>
            selectedBlockchain || enabledEvmBlockchains.includes(a.blockchain),
        )
        .map((a) => [
          a.blockchain as EvmBlockchain,
          calculateTotalAssetValue(
            {
              coins: {[a.blockchain]: a.balance},
              tokens: {
                [a.blockchain]: tokenAmounts.filter(
                  (t) => t.token.blockchain === a.blockchain,
                ),
              },
            },
            currency,
            conversionRates || {},
            {
              [a.blockchain]: Object.values(tokensMetadataMap).filter(
                (t) => t.blockchain === a.blockchain,
              ) as TokenMetadata[],
            },
          ),
        ]) as [EvmBlockchain, number][]
    ).sort(([, a], [, b]) => {
      return b - a
    }),
  ) as Record<EvmBlockchain, number>

type EvmBlockchainsColProps = {
  accountInfos: EvmAccountInfo<EvmBlockchain>[]
  tokenAmounts: TokenAmount[]
  tokensMetadataMap: Dictionary<TokenMetadata>
  conversionRates?: ConversionRates
  showAllBlockchainsCell: boolean
  selectedBlockchain: EvmBlockchain | null
  onSelectBlockchain: (blockchain: EvmBlockchain | null) => void
}

export function EvmBlockchainsCol({
  accountInfos,
  tokenAmounts,
  tokensMetadataMap,
  conversionRates,
  showAllBlockchainsCell,
  selectedBlockchain,
  onSelectBlockchain,
}: EvmBlockchainsColProps) {
  const {currency} = useSettings()
  const enabledEvmBlockchains = useEnabledEvmBlockchains()

  const sortedBlockchainsBalances = getSortedBlockchainsBalances({
    accountInfos,
    tokenAmounts,
    tokensMetadataMap,
    conversionRates,
    selectedBlockchain,
    currency,
    enabledEvmBlockchains,
  })

  return (
    <Grid container spacing={1} display="flex">
      {showAllBlockchainsCell && (
        <Grid item key="all-blockchains" width={150}>
          <AllEvmBlockchainCell
            sortedBlockchainsBalances={sortedBlockchainsBalances}
            selectedBlockchain={selectedBlockchain}
            onSelectBlockchain={onSelectBlockchain}
          />
        </Grid>
      )}
      {(Object.keys(sortedBlockchainsBalances) as EvmBlockchain[]).map(
        (blockchain) => {
          return (
            <Grid item key={blockchain} width={150}>
              <EvmBlockchainCell
                blockchain={blockchain}
                nativeAmount={
                  accountInfos.find((a) => a.blockchain === blockchain)
                    ?.balance ?? new BigNumber(0)
                }
                tokens={tokenAmounts
                  .filter((v) => v.token.blockchain === blockchain)
                  .filter((v) => tokensMetadataMap[v.token.id] != null)
                  .map((v) => ({
                    tokenMetadata: tokensMetadataMap[v.token.id]!,
                    amount: v.amount,
                  }))}
                isSelected={selectedBlockchain === blockchain}
                onClick={onSelectBlockchain}
              />
            </Grid>
          )
        },
      )}
    </Grid>
  )
}

const EvmBlockchainCellLayoutWrapper = styled(Paper, {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})<{isSelected: boolean}>(({theme, isSelected}) => ({
  border: 'none',
  borderRadius: 4,
  ...(isSelected && {
    backgroundColor: alpha(theme.palette.primary.main, 0.16),
    color: theme.palette.primary.contrastText,
  }),
}))
