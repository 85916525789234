import {Box, Divider, Grid, TextField, Typography} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  isValidSolanaPubKey,
  createEmptyValidatorInfoFromId,
  findValidator,
} from '@nufi/wallet-solana'
import type {SolanaValidatorInfo, SolanaAccountInfo} from '@nufi/wallet-solana'
import clsx from 'clsx'
import type {FormikProps} from 'formik'
import React from 'react'
import {useTranslation} from 'react-i18next'

import type {StakingBlockchain} from '../../../../blockchainTypes'
import {
  ModalFooter,
  ModalLayout,
  FooterLayout,
  ValidatorSelect,
  useModalSharedStyles,
  ellipsizeString,
  TextButton,
  WithTooltip,
  AccountSelectField,
} from '../../../../components'
import type {AccountId} from '../../../../types'
import {
  getHasFormError,
  isValidAmountFieldValue,
  sanitizeAmountFieldValue,
} from '../../../../utils/form'
import type {SelectChangeFn} from '../../../../utils/form'
import {useGetCoinName} from '../../../../utils/translations'
import {StakeModalHeader} from '../../common/utils'
import type {SetupStakeAccountSchema} from '../schema'
import {
  getRecommendedValidatorOption,
  getRecommendedValidatorId,
} from '../utils'

import {VALIDATOR_ELLIPSIZE_LENGTH} from './constants'
import FormattedValidatorInfo from './StakeModalValidatorInfo'

const FORM_ID = 'setup-stake-account-form-details'

type DetailsScreenProps<T extends SetupStakeAccountSchema> = {
  blockchain: StakingBlockchain
  accounts: SolanaAccountInfo[]
  formikProps: FormikProps<T>
  disabled?: boolean
  onClose: () => void
  validators: SolanaValidatorInfo[]
  maxAmountOptions?: {
    onMaxAmount?: () => unknown
    disabled?: boolean
    helperText?: string
  }
}

export default function DetailsScreen<T extends SetupStakeAccountSchema>({
  accounts,
  disabled,
  formikProps,
  blockchain,
  onClose,
  validators,
  maxAmountOptions,
}: DetailsScreenProps<T>) {
  const classes = {...useStyles(), ...useModalSharedStyles()}
  const {t} = useTranslation()
  const getCoinName = useGetCoinName()

  const {values, handleChange, errors, handleBlur} = formikProps
  type FormField = keyof typeof values

  const getOptionLabel = (validator: SolanaValidatorInfo): string => {
    const pubKeyString = validator.publicKey.toString()
    const ellipsizedPubKey = ellipsizeString(
      pubKeyString,
      VALIDATOR_ELLIPSIZE_LENGTH,
      VALIDATOR_ELLIPSIZE_LENGTH,
    )
    return `${
      validator.metadata?.name || t('Unknown name')
    } (${ellipsizedPubKey})`
  }

  const hasError = getHasFormError(formikProps)
  const hasErrorAfterTouched = getHasFormError(formikProps, 'after-touched')
  const recommendedOptionId = getRecommendedValidatorId(validators)

  const accountItems = accounts.map((a) => ({
    accountId: a.id,
    address: a.address,
    balance: a.balance,
    name: a.name,
    cryptoProviderType: a.cryptoProviderType,
  }))

  const MaxButton = maxAmountOptions && (
    <TextButton
      color="text.primary"
      disabled={!!maxAmountOptions?.disabled}
      label={t('MAX')}
      onClick={maxAmountOptions?.onMaxAmount}
    />
  )

  return (
    <ModalLayout
      header={<StakeModalHeader {...{onClose, blockchain}} />}
      body={
        <Box className={classes.contentWrapper}>
          <Box p={2}>
            <form onSubmit={formikProps.handleSubmit} noValidate id={FORM_ID}>
              <Grid container>
                <Grid item xs={12}>
                  <AccountSelectField
                    className={classes.formField}
                    label={t('Account')}
                    value={values.accountId}
                    onChange={
                      handleChange<FormField>(
                        'accountId',
                      ) as SelectChangeFn<AccountId>
                    }
                    items={accountItems}
                    blockchain={blockchain}
                    showBlockchainIcon
                  />
                </Grid>
                <Grid item xs={12}>
                  <Divider className={classes.divider} />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle2"
                    className={classes.commonBottomPadding}
                  >
                    {t('Stake amount and validator')}
                  </Typography>
                </Grid>
                <Grid item xs={12} pt={1}>
                  <TextField
                    className={classes.formField}
                    fullWidth
                    variant="outlined"
                    label={`${t('Amount')} (${getCoinName(blockchain)})`}
                    onBlur={(e) => {
                      // without handleBlur the "touched" fields are not populated
                      const onBlur = handleBlur<FormField>('amount') as (
                        e: unknown,
                      ) => void
                      onBlur(e)
                    }}
                    onChange={(e) => {
                      const sanitized = sanitizeAmountFieldValue(e.target.value)
                      const isValid =
                        isValidAmountFieldValue(sanitized) || sanitized === ''
                      if (!isValid) return
                      handleChange<FormField>('amount')(sanitized)
                    }}
                    error={hasErrorAfterTouched('amount')}
                    value={values.amount}
                    helperText={
                      hasErrorAfterTouched('amount') && <>{errors.amount}</>
                    }
                    InputProps={{
                      endAdornment:
                        maxAmountOptions?.disabled &&
                        maxAmountOptions?.helperText ? (
                          <WithTooltip
                            title={maxAmountOptions.helperText}
                            placement="left"
                          >
                            {MaxButton}
                          </WithTooltip>
                        ) : (
                          MaxButton
                        ),
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Box
                    className={clsx(
                      classes.commonTopMargin,
                      classes.commonBottomMargin,
                    )}
                  >
                    <ValidatorSelect
                      getOptionId={({publicKey}) => publicKey.toString()}
                      label={t('Validator Name, Id, ...')}
                      labelWhenSelected={t('Selected validator')}
                      renderOption={getOptionLabel}
                      getOptionLabel={getOptionLabel}
                      getOptionValid={isValidSolanaPubKey}
                      options={validators}
                      recommendedOption={getRecommendedValidatorOption(
                        validators,
                        recommendedOptionId,
                      )}
                      recommendedGroupLabel={t('Recommended validator')}
                      otherGroupLabel={t('Other')}
                      getOptionKeyword={({metadata, publicKey}) =>
                        `${metadata?.name},${publicKey.toString()}`
                      }
                      value={
                        findValidator(validators, values.validatorId) || null
                      }
                      createNotFoundOptionFromInput={
                        createEmptyValidatorInfoFromId
                      }
                      onChange={(validator) => {
                        handleChange<FormField>('validatorId')(
                          validator?.publicKey.toString() || '',
                        )
                      }}
                      error={hasError('validatorId')}
                      helperText={
                        hasError('validatorId') && <>{errors.validatorId}</>
                      }
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} className={classes.validatorInfo}>
                  {values.validatorId && (
                    <FormattedValidatorInfo
                      validatorInfo={findValidator(
                        validators,
                        values.validatorId,
                      )}
                    />
                  )}
                </Grid>
              </Grid>
            </form>
          </Box>
        </Box>
      }
      footer={
        <ModalFooter hasDivider>
          <Grid item xs={12}>
            <FooterLayout
              leftBtnConf={{
                onClick: onClose,
                children: t('Back'),
              }}
              rightBtnConf={{
                children: t('Continue'),
                type: 'submit',
                form: FORM_ID,
                disabled,
              }}
            />
          </Grid>
        </ModalFooter>
      }
    />
  )
}

const useStyles = makeStyles((theme) => ({
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
  },
  validatorInfo: {
    marginLeft: theme.spacing(0.25),
  },
}))
