import type {Theme} from '@mui/material'
import {Box, alpha} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, {useRef, useState} from 'react'
import {Route, Redirect, Switch} from 'react-router-dom'

import {
  useSetAmountsVisibility,
  useSettings,
} from 'src/features/profile/application'
import {RecentlyEnabledBlockchainModal} from 'src/features/profile/ui'
import {ManageBlockchainsModal} from 'src/features/walletStorage/ui'
import {useCurrentLoginInfo} from 'src/store/auth'
import {useUiStore} from 'src/store/ui'

import {
  WalletInitWarningModal,
  ConversionRatesProvider,
  IncognitoBanner,
  useIncognitoHandlers,
  Icon,
  GiveFeedbackLink,
} from '../../components'
import {usePreventConcurrentLogin} from '../../components/AutoLogout'
import {useKeyboardShortcut} from '../../components/useKeyboardShortcut'
import config from '../../config'
import {
  SIDEBAR_WIDTH,
  TOPBAR_WIDTH,
  CONVERSION_RATES_PROVIDER_HEIGHT,
  externalLinks,
  shortcutKeys,
  DASHBOARD_LOGGED_IN_MIN_WIDTH,
} from '../../constants'
import type {SocialMediaType} from '../../constants'
import {routeTo} from '../../router'
import {ProfileTracking} from '../../tracking/profile/ProfileTracking'
import {Dashboard as WalletConnectDashboard} from '../../walletConnect/Dashboard'
import ActivityPage from '../activity'
import {DAppDirectory} from '../dAppDirectory'
import DashboardPage from '../dashboard'
import {DexHunterPage} from '../dexHunter'
import {ExchangeHistorySyncNote} from '../exchange/components/ExchangeHistorySyncNote'
import {AutoLoginRoutingActions} from '../loggedOutMain/appOpener'
import NotFoundPage from '../NotFound'
import PortfolioPage from '../portfolio'
import {AutoLoginAddAccount} from '../portfolio/sharedActions/addAccount/AddAccount'
import SettingsPage from '../settings'
import StakingPage from '../staking'
import {ReferenceElementContext} from '../utils'

import {MAIN_RIGHT_SPACING} from './constants'
import {NewsModal, getHasUnreadNews} from './NewsModal'
import type {NewsState} from './NewsModal'
import {
  PROMO_BANNER_HEIGHT,
  PromoBanner,
  hasSeenPromoBanner,
  setHasSeenPromoBanner,
} from './PromoBanner'
import Sidebar from './Sidebar'
import TopBar from './TopBar'

const useStyles = makeStyles<Theme, {isPromoBannerOpen: boolean}>((theme) => ({
  wrapper: {
    display: 'flex',
    height: '100vh',
    flexDirection: 'column',
    minWidth: DASHBOARD_LOGGED_IN_MIN_WIDTH,
    // overflowX: 'scroll' sets overflowY to auto, therefore it is used in media query. Check https://www.brunildo.org/test/Overflowxy2.html
    [`@media (max-width: ${DASHBOARD_LOGGED_IN_MIN_WIDTH}px)`]: {
      overflowX: 'scroll',
    },
  },
  sidebar: {
    width: SIDEBAR_WIDTH,
  },
  main: {
    flex: 1,
    height: '100%',
    overflow: 'auto',
    padding: theme.spacing(2, 0, 1, 0),
    paddingRight: MAIN_RIGHT_SPACING,
  },
  innerWrapper: ({isPromoBannerOpen}) => ({
    display: 'flex',
    height: `calc(100% - ${TOPBAR_WIDTH + CONVERSION_RATES_PROVIDER_HEIGHT + (isPromoBannerOpen ? PROMO_BANNER_HEIGHT : 0)}px)`,
  }),
  conversionRatesProvider: {
    borderTop: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: theme.spacing(2),
    height: CONVERSION_RATES_PROVIDER_HEIGHT,
    marginLeft: SIDEBAR_WIDTH,
    marginRight: MAIN_RIGHT_SPACING,
  },
  socialMediaLinks: {
    display: 'flex',
    color: theme.palette.action.active,
    '&:hover': {
      color: alpha(theme.palette.primary.main, 0.8),
    },
  },
}))

const MainPage = () => {
  const currentLoginInfo = useCurrentLoginInfo()
  const isMetamaskLogin = currentLoginInfo.loginType === 'metamask'

  const [isPromoBannerOpen, setIsPromoBannerOpen] = useState(
    !isMetamaskLogin && !hasSeenPromoBanner(),
  )

  const onClosePromoBanner = () => {
    setIsPromoBannerOpen(false)
    setHasSeenPromoBanner(true)
  }

  const classes = useStyles({isPromoBannerOpen})
  const containerRef = useRef<HTMLElement>(null)
  const [newsModalState, setNewsModalState] = useState<{
    isOpen: boolean
    show: NewsState
  }>({
    isOpen: !isMetamaskLogin && getHasUnreadNews(),
    show: 'unread',
  })
  const {showIncognitoBanner, onClose} = useIncognitoHandlers()

  const {areAmountsVisible} = useSettings()
  const setPortfolioValueVisibility = useSetAmountsVisibility()

  const onHideAmountShortcut = async () => {
    if (setPortfolioValueVisibility.isPending === false) {
      await setPortfolioValueVisibility.mutateAsyncSilent({
        areAmountsVisible: !areAmountsVisible,
      })
    }
  }

  const {isBlockchainManagementModalOpen, setIsBlockchainManagementModalOpen} =
    useUiStore()

  useKeyboardShortcut(shortcutKeys.hideAmount, onHideAmountShortcut)
  usePreventConcurrentLogin()

  return (
    <Box className={classes.wrapper}>
      {isPromoBannerOpen && <PromoBanner onClose={onClosePromoBanner} />}
      <AutoLoginAddAccount />
      <AutoLoginRoutingActions />
      <WalletInitWarningModal />
      <RecentlyEnabledBlockchainModal />
      {isBlockchainManagementModalOpen && (
        <ManageBlockchainsModal
          onClose={() => setIsBlockchainManagementModalOpen(false)}
        />
      )}
      <ProfileTracking />
      <TopBar
        onNotificationsClick={() =>
          setNewsModalState({show: 'all', isOpen: true})
        }
      />
      {showIncognitoBanner && <IncognitoBanner onClose={() => onClose()} />}
      <Route path={routeTo.portfolio.exchange.index}>
        <ExchangeHistorySyncNote />
      </Route>
      {newsModalState.isOpen && (
        <NewsModal
          show={newsModalState.show}
          onClose={() =>
            setNewsModalState((prevState) => ({...prevState, isOpen: false}))
          }
        />
      )}
      <Box className={classes.innerWrapper}>
        <Box className={classes.sidebar}>
          <Sidebar />
        </Box>
        <Box className={classes.main} {...{ref: containerRef}}>
          <Switch>
            <Redirect exact from={routeTo.main} to={routeTo.portfolio.index} />
            <Route path={routeTo.dashboard} component={DashboardPage} />
            <Route
              path={routeTo.staking.index}
              render={() => (
                <ReferenceElementContext.Provider value={containerRef}>
                  <StakingPage />
                </ReferenceElementContext.Provider>
              )}
            />
            <Route path={routeTo.staking.index} component={StakingPage} />
            <Route path={routeTo.portfolio.index} component={PortfolioPage} />
            <Route path={routeTo.settings.index} component={SettingsPage} />
            <Route path={routeTo.dAppDirectory} component={DAppDirectory} />
            {config.isWalletConnectEnabled && (
              <Route
                path={routeTo.walletConnect.dashboard}
                component={WalletConnectDashboard}
              />
            )}
            <Route path={routeTo.activity.index} component={ActivityPage} />
            {config.isDexHunterEnabled && (
              <Route path={routeTo.dexHunter.index} component={DexHunterPage} />
            )}
            <Route exact path={routeTo.notFound} component={NotFoundPage} />
            <Redirect to={routeTo.notFound} />
          </Switch>
        </Box>
      </Box>
      <Box className={classes.conversionRatesProvider}>
        <Box
          display="flex"
          flexDirection="row"
          gap={1}
          pl={0.5}
          alignItems="center"
        >
          {Object.entries(externalLinks.socialMediaLinks).map(
            ([medium, link]) => (
              <a
                key={medium}
                href={link}
                target="_blank"
                className={classes.socialMediaLinks}
              >
                <Icon
                  color="inherit"
                  type={medium as SocialMediaType}
                  exactSize={20}
                />
              </a>
            ),
          )}
          <GiveFeedbackLink />
        </Box>
        <ConversionRatesProvider />
      </Box>
    </Box>
  )
}

export default MainPage
