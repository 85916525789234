import {
  Error as ButtonWarningIcon,
  WarningAmber as ItemWarningIcon,
} from '@mui/icons-material'
import {
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
  Typography,
  styled,
} from '@mui/material'
import React, {useEffect, useState, useRef} from 'react'
import {useTranslation} from 'react-i18next'

import {WithTooltip} from 'src/components'
import {
  useGetCommonAppWarningTexts,
  useWarnings,
} from 'src/features/warnings/application'
import type {CommonAppWarning, Warning} from 'src/features/warnings/domain'
import {hasNewWarnings} from 'src/features/warnings/domain'

export function WarningsIndicator() {
  const {t} = useTranslation()

  const lastSeenWarnings = useRef<number | null>(null)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const spanRef = useRef<null | HTMLSpanElement>(null)
  const isPopoverOpen = !!anchorEl

  const warnings = useWarnings()

  const showPopover = () => {
    setAnchorEl(spanRef.current!)
  }

  const hidePopover = () => {
    setAnchorEl(null)
    lastSeenWarnings.current = Date.now()
  }

  useEffect(() => {
    if (!isPopoverOpen && hasNewWarnings(lastSeenWarnings.current, warnings)) {
      showPopover()
    }
  }, [warnings, isPopoverOpen])

  return (
    <>
      {/* We are using this is as the anchor as we need to open popover in the
          correct place also automatically without user's interaction.
          Therefore its also important to keep this anchor rendered at all times.    
      */}
      <Typography
        component="span"
        ref={spanRef}
        sx={{position: 'relative', top: 52}}
      />
      {warnings.length !== 0 ? (
        <>
          <WithTooltip title={<>{t('Warnings')}</>}>
            <IconButton onClick={showPopover}>
              <ButtonWarningIcon color="error" />
            </IconButton>
          </WithTooltip>

          <Popover
            open={isPopoverOpen}
            anchorEl={anchorEl}
            onClose={hidePopover}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <WarningsList warnings={warnings} />
          </Popover>
        </>
      ) : null}
    </>
  )
}

export type WarningsListProps = {
  warnings: Warning[]
}

export function WarningsList({warnings}: WarningsListProps) {
  const getAppWarningTexts = useGetCommonAppWarningTexts()
  if (warnings.length === 0) return null

  return (
    <List>
      {warnings.map((w, i) => {
        const {title, description} = getAppWarningTexts(w as CommonAppWarning)
        return (
          <WarningListItem
            key={JSON.stringify(w.key)}
            divider={i !== warnings.length - 1}
          >
            <ListItemIcon>
              <ItemWarningIcon color="error" fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={title} secondary={description} />
          </WarningListItem>
        )
      })}
    </List>
  )
}

const WarningListItem = styled(ListItem)({
  // Keep fixed width otherwise auto display of Popover behaves weird,
  // e.g. in cases when warnings are removed and appear again.
  width: 500,
})
