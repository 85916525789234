import React from 'react'

import {ModalLayout, ModalFooter, MutationGuard} from '../../components'
import type {AppError, Blockchain} from '../../types'
import {LedgerFailedSign} from '../hw/ledger'

import {TxErrorFooter, TxLoadingFooter, TxBodyLayout as BL} from './common'
import {useClearTxSubmissionAuthorization} from './signAuthorization'

type SignLedgerScreenProps = {
  blockchain: Blockchain
  onClose: () => void
  onBack: () => unknown
  onSign: () => Promise<unknown>
  signProps: {
    isPending: boolean
    error: AppError
    data?: unknown
  }
  ModalHeader: React.ReactNode
  ReadyContent: React.ReactNode
}

export function SignLedgerScreen({
  ModalHeader,
  ReadyContent,
  onBack,
  onClose,
  onSign,
  signProps,
  blockchain,
}: SignLedgerScreenProps) {
  useClearTxSubmissionAuthorization(onSign)

  const {Body, Footer} = (() => {
    if (signProps.error) {
      return {
        Body: (
          <BL centered>
            <LedgerFailedSign
              blockchain={blockchain}
              onRetry={onSign}
              error={signProps.error}
            />
          </BL>
        ),
        Footer: <TxErrorFooter {...{onBack, onClose}} />,
      }
    }
    if (signProps.isPending) {
      return {
        Body: <BL>{ReadyContent}</BL>,
        Footer: <TxLoadingFooter action="signing" />,
      }
    }
    return {Body: null, Footer: null}
  })()

  return (
    <ModalLayout
      header={ModalHeader}
      body={Body}
      footer={
        <ModalFooter hasDivider>
          {Footer}
          <MutationGuard error={null} hideLoader isPending={false} />
        </ModalFooter>
      }
    />
  )
}
