import config from 'src/config'
import {authenticatedRequest} from 'src/features/nufiAuth/application'

import type {SwapsApi} from '../domain/swapsApi'
import {NuFiSwapsApi} from '../infrastructure'

export const swapsApi: SwapsApi = new NuFiSwapsApi(
  config.backendUrl,
  authenticatedRequest,
)
