import {
  Typography,
  Box,
  Grid,
  TextField,
  FormGroup,
  FormHelperText,
  Checkbox,
  FormControlLabel,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import type {FormikHelpers} from 'formik'
import {Formik} from 'formik'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import * as yup from 'yup'

import {
  ModalLayout,
  ModalHeader,
  Modal,
  ModalFooter,
  Button,
  MnemonicCard,
  PlainTextSwitch,
  PlainTextInput,
} from '../../components'
import {getHasFormError, useVerifyPassword} from '../../utils/form'
import {getMnemonic, getMnemonicLength} from '../../wallet'

type RecoveryPhraseModalProps = {
  onClose: () => void
  skipPasswordVerification?: boolean
}

export default function RecoveryPhraseModal({
  onClose,
  skipPasswordVerification = false,
}: RecoveryPhraseModalProps) {
  const [verified, setVerified] = useState(false)
  const classes = useStyles()

  return (
    <Modal
      className={classes.modal}
      onClose={onClose}
      variant="centered"
      dismissable
    >
      <ModalLayout
        header={<RecoveryPhraseModalHeader {...{onClose}} />}
        body={
          verified ? (
            <RecoveryPhraseScreen {...{onClose}} />
          ) : (
            <VerificationScreen
              skipPasswordVerification={skipPasswordVerification}
              setVerified={setVerified}
            />
          )
        }
      />
    </Modal>
  )
}

type Schema = {
  password: string
  warningChecked: boolean
}

const FORM_ID = 'recovery-phrase-form-verification'

type VerificationScreenProps = {
  setVerified: (value: boolean) => void
  skipPasswordVerification?: boolean
}

const VerificationScreen = ({
  setVerified,
  skipPasswordVerification = false,
}: VerificationScreenProps) => {
  const classes = useStyles()
  const {t} = useTranslation()
  const verifyPassword = useVerifyPassword()

  const schema = yup.object().shape({
    password: yup.string().when([], {
      is: () => !skipPasswordVerification,
      then: (schema) => schema.required(t('Password is required.')),
    }),
    warningChecked: yup.bool().oneOf([true], t('Field must be checked.')),
  })

  const onSubmit = async (
    values: Schema,
    formikHelpers: FormikHelpers<Schema>,
  ) => {
    const passwordVerified =
      skipPasswordVerification ||
      (await verifyPassword(values.password, formikHelpers))
    if (!passwordVerified) return
    setVerified(true)
  }

  return (
    <Formik
      initialValues={{password: '', warningChecked: false}}
      validationSchema={schema}
      onSubmit={onSubmit}
    >
      {(formikProps) => (
        <form
          onSubmit={formikProps.handleSubmit}
          noValidate
          id={FORM_ID}
          className={classes.form}
        >
          <ModalLayout
            body={
              <Box p={2}>
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <Typography color="textSecondary">
                      {t('confirm_secret_phrase_reveal')}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            onChange={formikProps.handleChange<
                              keyof typeof formikProps.values
                            >('warningChecked')}
                          />
                        }
                        label={
                          <Typography color="textSecondary">
                            {t(
                              "I'm in private and fully understand the risks of showing my recovery phrase.",
                            )}
                          </Typography>
                        }
                      />
                      {getHasFormError(formikProps)('warningChecked') && (
                        <FormHelperText className={classes.error}>
                          {formikProps.errors.warningChecked}
                        </FormHelperText>
                      )}
                    </FormGroup>
                  </Grid>
                </Grid>
              </Box>
            }
            footer={
              <ModalFooter hasDivider>
                {!skipPasswordVerification && (
                  <Box mt={1} mb={3}>
                    <TextField
                      value={formikProps.values.password}
                      onChange={formikProps.handleChange<
                        keyof typeof formikProps.values
                      >('password')}
                      label={t('Enter wallet password')}
                      error={getHasFormError(formikProps)('password')}
                      type="password"
                      helperText={
                        getHasFormError(formikProps)('password') &&
                        formikProps.errors.password
                      }
                      variant="outlined"
                      fullWidth
                    />
                  </Box>
                )}
                <Button
                  fullWidth
                  textTransform="none"
                  variant="contained"
                  form={FORM_ID}
                  color="primary"
                  type="submit"
                >
                  {t('Show recovery phrase')}
                </Button>
              </ModalFooter>
            }
          />
        </form>
      )}
    </Formik>
  )
}

type RecoveryPhraseScreenProps = {
  onClose: () => void
}

const RecoveryPhraseScreen = ({onClose}: RecoveryPhraseScreenProps) => {
  const mnemonic = getMnemonic()
  const [plainTextMode, setPlainTextMode] = useState(false)

  const classes = useStyles()
  const {t} = useTranslation()

  return (
    <ModalLayout
      body={
        <Box p={2}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Box className={classes.infoTextWrapper}>
                <Typography color="textSecondary">
                  {t('write_down_secret_phrase', {length: getMnemonicLength()})}
                </Typography>
              </Box>
              <Box flex="1">
                <PlainTextSwitch
                  state={plainTextMode}
                  setState={setPlainTextMode}
                />
              </Box>
            </Grid>
            <Grid item>
              {plainTextMode ? (
                <PlainTextInput mnemonicValue={mnemonic} />
              ) : (
                <MnemonicCard mnemonic={mnemonic.split(' ')} />
              )}
            </Grid>
          </Grid>
        </Box>
      }
      footer={
        <ModalFooter hasDivider>
          <Button
            fullWidth
            textTransform="none"
            variant="contained"
            color="primary"
            type="submit"
            onClick={onClose}
          >
            {t('I have written down my recovery phrase.')}
          </Button>
        </ModalFooter>
      }
    />
  )
}

type RecoveryPhraseModalHeaderProps = {
  onClose: () => void
}

const RecoveryPhraseModalHeader = ({
  onClose,
}: RecoveryPhraseModalHeaderProps) => {
  const {t} = useTranslation()

  return (
    <ModalHeader onClose={onClose} hasDivider>
      <Typography variant="h6">{t('Show Recovery Phrase?')}</Typography>
    </ModalHeader>
  )
}

const useStyles = makeStyles((theme) => ({
  form: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  modal: {
    maxWidth: '550px',
  },
  infoTextWrapper: {
    margin: theme.spacing(1, 1),
  },
  error: {
    color: theme.palette.error.main,
  },
}))
