import {Typography, styled} from '@mui/material'
import type {EvmTokenMetadata} from '@nufi/wallet-evm'
import React from 'react'

import {
  LabeledIcon,
  AssetIcon,
  EllipsizedString,
  EvmTokenExplorerLink,
} from '../../../../../../components'
import type {
  EvmBlockchain,
  EvmErc20Metadata,
} from '../../../../../../wallet/evm'

export const TokenDetailInfo = <TBlockchain extends EvmBlockchain>({
  tokenMetadata,
  withExplorerLink,
}: {tokenMetadata: EvmTokenMetadata<TBlockchain>} & {
  withExplorerLink?: boolean
}) => (
  <LabeledIcon
    Icon={
      <AssetIcon
        blockchain={tokenMetadata.blockchain}
        tokenMetadata={tokenMetadata}
      />
    }
    Label={
      <TokenDetailInfoWrapper>
        <TokenDetailNameAligner
          title={`${tokenMetadata.name} (${tokenMetadata.ticker})`}
        >
          <Typography variant="body2" noWrap>
            {tokenMetadata.name}
          </Typography>
          <Typography variant="body2">{` (${tokenMetadata.ticker})`}</Typography>
        </TokenDetailNameAligner>
        {withExplorerLink && (
          <TokenDetailExplorerLink
            id={tokenMetadata.id}
            blockchain={tokenMetadata.blockchain}
            contractAddress={tokenMetadata.contractAddress}
          />
        )}
      </TokenDetailInfoWrapper>
    }
    iconPosition="start"
  />
)

const TokenDetailInfoWrapper = styled('div')({
  flex: 1,
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  gap: '5px',
})

const TokenDetailNameAligner = styled('div')({
  display: 'flex',
  alignItems: 'flex-end',
  gap: '4px',
  '& > *': {
    lineHeight: '1!important',
  },
})

// IMPORTANT: this class name is being used in onClick handler
// to prevent selecting token when clicked on explorer link
export const tokenDetailExplorerLinkClassName =
  'tokenDetailExplorerLinkClassName'

export const TokenDetailExplorerLink = <TBlockchain extends EvmBlockchain>({
  id,
  contractAddress,
  blockchain,
}: Pick<
  EvmErc20Metadata<TBlockchain>,
  'id' | 'contractAddress' | 'blockchain'
>) => (
  <EvmTokenExplorerLink
    size="inherit"
    {...{blockchain, tokenId: id}}
    className={tokenDetailExplorerLinkClassName}
    label={
      <EllipsizedString
        value={contractAddress}
        startLength={13}
        endLength={8}
        TypographyProps={{
          lineHeight: 1,
          variant: 'caption',
          color: 'inherit',
        }}
      />
    }
  />
)
