import {SolanaDerivationPathType} from '@nufi/wallet-solana'
import type {SolanaAccountInfo} from '@nufi/wallet-solana'
import React from 'react'

import type {AccountInfo, HotVendor} from '../../../types'
import {useAddHotAccounts, useDiscoverHotAccounts} from '../../../wallet/solana'
import {useDerivationPathTypeState} from '../sharedActions/addAccount/AddAccountUtils'
import {AddHotAccount as _AddHotAccount} from '../sharedActions/addAccount/AddHotAccount'

import {useDerivationPathTypeConfig} from './AddAccountUtils'

type AddHotAccountProps = {
  cryptoProviderType: HotVendor
}

export const AddHotAccount = ({cryptoProviderType}: AddHotAccountProps) => {
  const addAccounts = useAddHotAccounts(cryptoProviderType)
  const derivationPathTypeConfig = useDerivationPathTypeConfig()
  const derivationPathTypeState = useDerivationPathTypeState(
    SolanaDerivationPathType.Bip44Change,
    derivationPathTypeConfig,
  )
  const discoveredAccountsQuery = useDiscoverHotAccounts(
    derivationPathTypeState.derivationPathType,
  )

  const onSubmit = async (newAccounts: AccountInfo[]) => {
    await addAccounts.mutateAsync({
      newAccounts: newAccounts as SolanaAccountInfo[],
    })
  }

  return (
    <_AddHotAccount
      blockchain="solana"
      addAccountsMutation={addAccounts}
      {...{
        onSubmit,
        discoveredAccountsQuery,
        derivationPathTypeState,
        cryptoProviderType,
      }}
    />
  )
}
