/* eslint-disable @typescript-eslint/explicit-function-return-type */

import {Sentry, getMediaUri} from '@nufi/frontend-common'
import type {AccountId} from '@nufi/wallet-common'
import type {
  SolanaTokenId,
  SolanaTokenMetadata,
  SolanaMintAddress,
  SolanaAddress,
  SolanaNft,
  SolanaTokenAmount,
  SolanaAccountInfo,
  SolanaOffChainMetadata,
} from '@nufi/wallet-solana'
import {
  getMetaplexMetadata,
  calculateTokenAmounts,
  isValidSolanaPubKey,
  buildCNftTokenAmounts,
  defaultTokenFromTokenId,
} from '@nufi/wallet-solana'
import {useQuery} from '@tanstack/react-query'
import _ from 'lodash'

import config from 'src/config'
import {CommonAppWarnings, updateWarnings} from 'src/features/warnings/domain'
import {fetchNeverStaleQuery, useNeverStaleQuery} from 'src/utils/query-utils'
import {solana} from 'src/wallet/solana/solanaManagers'

import queryClient from '../../../../../queryClient'
import {cachedGetAccounts} from '../common'
import {cachedGetAllNfts, cachedGetNft} from '../nfts'

import {tokensQueryKeys} from './queryKeys'
import {fetchParsedTokenList} from './tokenList'
import {sanitizeTokenTicker} from './utils'

export {tokensQueryKeys} from './queryKeys'

//
// __CACHED__ QUERIES
//

// TODO: return token meta + token amount to the UI in a single hook
// TODO: consider filtering out zero balances

function cachedGetTokenAccounts() {
  return fetchNeverStaleQuery({
    queryKey: tokensQueryKeys.tokenAccounts(),
    queryFn: async () => {
      const accounts = await cachedGetAccounts()
      return solana.wallet.getTokenAccountsInfo(accounts)
    },
  })
}

export function useGetTokenAccountRent(enabled = true) {
  return useNeverStaleQuery({
    queryKey: tokensQueryKeys.tokenAccountRent,
    queryFn: () =>
      solana.wallet.getTokenAccountMinimumBalanceForRentExemption(),
    enabled,
  })
}

export type UseGetTokenAccountRent = typeof useGetTokenAccountRent

async function getOffChainMetadata(): Promise<
  Record<SolanaMintAddress, SolanaOffChainMetadata>
> {
  const tokenList = await fetchParsedTokenList(config.solanaClusterName)
  return _.keyBy(tokenList, (token) => token.id)
}

getOffChainMetadata.__key = tokensQueryKeys.offChainMeta

export function cachedGetOffChainMetadata() {
  return fetchNeverStaleQuery({
    queryKey: getOffChainMetadata.__key,
    queryFn: getOffChainMetadata,
  })
}

export function useGetOffChainMetadata(enabled = true) {
  return useNeverStaleQuery({
    queryKey: getOffChainMetadata.__key,
    queryFn: getOffChainMetadata,
    enabled,
  })
}

function joinTokenMetadata(
  onChainMeta: SolanaTokenMetadata,
  offChainMetadata: SolanaOffChainMetadata | null,
  nft: SolanaNft | null,
  metaplexOffChainMeta: Partial<SolanaTokenMetadata> | null,
): SolanaTokenMetadata {
  const logoUri =
    nft?.image || metaplexOffChainMeta?.logoURI || offChainMetadata?.logoURI

  return sanitizeTokenTicker({
    // Generally, off-chain metadata is supposed to contain more info than on chain meta,
    // therefore its fields overwrite on-chain meta (where some of the fields may be undefined/null)
    // An exception to this is on-chain decimals which are inherently more reliable than off-chain info.
    // e.g. "SCAM" token seems to have off-chain (token-list) decimals incorrectly set (5 instead of 9)
    ...onChainMeta,
    ...offChainMetadata,
    ...metaplexOffChainMeta,
    decimals: onChainMeta.decimals,
    // On-chain metadata does not seem to contain `name` and `ticker`, so in case
    // of NFT we replace these with NFT values
    name:
      nft?.name || metaplexOffChainMeta?.name || offChainMetadata?.name || null,
    ticker:
      nft?.ticker ||
      metaplexOffChainMeta?.ticker ||
      offChainMetadata?.ticker ||
      null,
    // some tokens seem to be hosted on urls blacklisted by chrome,
    // causing chrome to crash with "deceptive site ahead" warning, proxy-ing
    // through our "url proxy" should prevent that
    logoURI: logoUri && getMediaUri({url: logoUri, enableUrlProxy: true}),
    nftInfo: metaplexOffChainMeta?.nftInfo || (nft && {type: nft.type}) || null,
  })
}

// Important: make sure this stays consistent with `getTokenMetadata`
async function getTokensMetadata(): Promise<SolanaTokenMetadata[]> {
  let hasWarning = false
  const partialTokenFailureWarning =
    CommonAppWarnings.PARTIAL_TOKENS_FAILURE('solana')
  const tokens = (await cachedGetTokens()).map((ta) => ta.token)
  const offchainMetadata = await cachedGetOffChainMetadata()

  const onChainTokensMetadata =
    await solana.wallet.getTokenMetadataOnChain(tokens)

  const ownedNfts = await (async () => {
    try {
      return (await cachedGetAllNfts()).allNfts
    } catch (err) {
      Sentry.captureException(err)
      hasWarning = true
      return {}
    }
  })()

  const metaplex = await (async () => {
    try {
      return await getMetaplexMetadata(
        onChainTokensMetadata.map(({mint}) => mint),
        config.solanaRpcUrl,
      )
    } catch (err) {
      Sentry.captureException(err)
      hasWarning = true
      return {}
    }
  })()

  const joinedTokensMeta = onChainTokensMetadata.map((onChainMeta) => {
    const {mint, id} = onChainMeta
    const nft = ownedNfts[id] || null
    const offChainMeta = offchainMetadata[id]!

    return joinTokenMetadata(onChainMeta, offChainMeta, nft, metaplex[mint]!)
  })

  for (const meta of joinedTokensMeta) {
    await queryClient.prefetchQuery({
      queryKey: tokensQueryKeys.tokenMetadata.token(meta.id),
      queryFn: () => meta,
      staleTime: Infinity,
    })
  }

  updateWarnings(hasWarning ? [partialTokenFailureWarning] : [], [
    partialTokenFailureWarning.key,
  ])
  return joinedTokensMeta
}

getTokensMetadata.__key = tokensQueryKeys.tokenMetadata.all

export function cachedGetTokensMetadata() {
  return fetchNeverStaleQuery({
    queryKey: getTokensMetadata.__key(),
    queryFn: getTokensMetadata,
  })
}

export function useGetTokensMetadata(enabled = true) {
  return useNeverStaleQuery({
    queryKey: getTokensMetadata.__key(),
    queryFn: getTokensMetadata,
    enabled,
  })
}

// Important: make sure this stays consistent with `getTokensMetadata`
const getTokenMetadata = (tokenId: SolanaTokenId) => async () => {
  const offChainMetadata = await cachedGetOffChainMetadata()
  const token = defaultTokenFromTokenId(tokenId)

  const offChainMeta = offChainMetadata[token.id]!
  const onChainMeta = (await solana.wallet.getTokenMetadataOnChain([token]))[0]!
  const nft = await cachedGetNft(tokenId)
  const metaplexMetadata = await getMetaplexMetadata(
    [token.mint],
    config.solanaRpcUrl,
  )
  return joinTokenMetadata(
    onChainMeta,
    offChainMeta,
    nft,
    metaplexMetadata[token.mint]!,
  )
}

getTokenMetadata.__key = tokensQueryKeys.tokenMetadata.token

export function useGetTokenMetadata(tokenId: SolanaTokenId, enabled = true) {
  return useNeverStaleQuery({
    queryKey: getTokenMetadata.__key(tokenId),
    queryFn: getTokenMetadata(tokenId),
    enabled,
  })
}

export function cachedGetTokenMetadata(tokenId: SolanaTokenId) {
  return fetchNeverStaleQuery({
    queryKey: getTokenMetadata.__key(tokenId),
    queryFn: getTokenMetadata(tokenId),
  })
}

//
// __COMPUTED__ QUERIES
//

async function getTokens() {
  const [tokenAccounts, {allNfts}] = await Promise.all([
    cachedGetTokenAccounts(),
    cachedGetAllNfts(),
  ])
  const nfts = Object.values(allNfts) || []
  return [
    ...buildCNftTokenAmounts({
      cNfts: nfts.filter((nft) => nft.type === 'compressed'),
    }),
    ...calculateTokenAmounts(tokenAccounts),
  ]
}

getTokens.__key = tokensQueryKeys.tokens

export function cachedGetTokens() {
  return fetchNeverStaleQuery({queryKey: getTokens.__key, queryFn: getTokens})
}

export function useGetTokens(enabled = true) {
  return useNeverStaleQuery({
    queryKey: getTokens.__key,
    queryFn: getTokens,
    enabled,
  })
}

export function useGetAccountTokens(
  account: SolanaAccountInfo,
  enabled = true,
) {
  return useNeverStaleQuery({
    queryKey: tokensQueryKeys.accountTokens(account.id),
    queryFn: async () => {
      const [tokenAccounts, {nftsByAccount}] = await Promise.all([
        cachedGetTokenAccounts(),
        cachedGetAllNfts(),
      ])
      const nfts = nftsByAccount[account.id] || []
      return [
        ...buildCNftTokenAmounts({
          cNfts: nfts.filter((nft) => nft.type === 'compressed'),
        }),
        ...calculateTokenAmounts(tokenAccounts, account.publicKey),
      ]
    },
    enabled,
  })
}

const getTokensByAccount = async (): Promise<
  Record<AccountId, SolanaTokenAmount[]>
> => {
  const [accounts, tokenAccounts, {nftsByAccount}] = await Promise.all([
    cachedGetAccounts(),
    cachedGetTokenAccounts(),
    cachedGetAllNfts(),
  ])

  const tokensByAccount = accounts?.map((account) => {
    const nfts = nftsByAccount[account.id] || []
    return {
      accountId: account.id,
      tokens: [
        ...buildCNftTokenAmounts({
          cNfts: nfts.filter((nft) => nft.type === 'compressed'),
        }),
        ...calculateTokenAmounts(tokenAccounts, account.publicKey),
      ],
    }
  })

  return _.chain(tokensByAccount).keyBy('accountId').mapValues('tokens').value()
}

export type UseGetTokensByAccount = typeof useGetTokensByAccount

export function useGetTokensByAccount(enabled = true) {
  return useNeverStaleQuery({
    queryKey: tokensQueryKeys.tokensByAccount(),
    queryFn: getTokensByAccount,
    enabled,
  })
}

export function cachedGetTokensByAccount() {
  return fetchNeverStaleQuery({
    queryKey: tokensQueryKeys.tokensByAccount(),
    queryFn: getTokensByAccount,
  })
}

export function useGetAddressInfo(toAddress: SolanaAddress, enabled = true) {
  return useQuery({
    queryKey: tokensQueryKeys.addressInfo(toAddress),
    queryFn: () => {
      if (!isValidSolanaPubKey(toAddress)) return null
      return solana.accountManager.getAddressInfo(toAddress)
    },
    enabled,
  })
}

export type UseGetAddressInfo = typeof useGetAddressInfo
