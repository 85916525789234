import {Box} from '@mui/material'
import {arraySum} from '@nufi/wallet-common'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {
  FormattedAsset,
  FormattedPercentage,
  QueryGuard,
} from '../../../../components'
import type {CardanoStakeAccountInfo} from '../../../../wallet/cardano'
import {
  getAccountStakedBalance,
  useGetTotalStakedAPY,
} from '../../../../wallet/cardano'
import {
  StakeSummary,
  SummaryCard,
} from '../../common/overview/StakeOverviewUtils'

type StakeSummaryProps = {
  accounts: CardanoStakeAccountInfo[]
}

export default function CardanoStakeSummary({accounts}: StakeSummaryProps) {
  const {t} = useTranslation()
  const totalStakedAPYQuery = useGetTotalStakedAPY()
  const rewardSum = arraySum(accounts.map((account) => account.rewards))
  const stakedSum = arraySum(accounts.map((a) => getAccountStakedBalance(a)))
  const balanceSum = arraySum(accounts.map((account) => account.balance))

  return (
    <Box>
      <StakeSummary>
        <SummaryCard
          label={t('Total Staked APY %')}
          value={
            <QueryGuard {...totalStakedAPYQuery}>
              {(APY) => <FormattedPercentage value={APY} />}
            </QueryGuard>
          }
        />
        <SummaryCard
          label={t('Available balance')}
          value={
            <FormattedAsset
              amount={balanceSum}
              blockchain="cardano"
              isSensitiveInformation
            />
          }
        />
        <SummaryCard
          label={t('Total rewards')}
          value={
            <FormattedAsset
              amount={rewardSum}
              blockchain="cardano"
              isSensitiveInformation
            />
          }
        />
        <SummaryCard
          label={t('Total staked')}
          value={
            <FormattedAsset
              amount={stakedSum}
              blockchain="cardano"
              isSensitiveInformation
            />
          }
        />
      </StakeSummary>
    </Box>
  )
}
