import type {CardanoTokenId} from '@nufi/wallet-cardano'

import {QUERY_KEY_PREFIX as P} from '../utils'

export const tokensQueryKeys = {
  tokens: [P, 'tokens'],
  balances: {
    index: () => [...tokensQueryKeys.tokens, 'balances'],
    tokensPerAccount: (tokenId: CardanoTokenId | null) => [
      ...tokensQueryKeys.balances.index(),
      'perAccount',
      tokenId,
    ],
  },
  tokenMetadata: {
    index: () => [...tokensQueryKeys.tokens, 'tokenMetadata'],
    all: () => [...tokensQueryKeys.tokenMetadata.index(), 'all'],
    combined: () => [...tokensQueryKeys.tokenMetadata.index(), 'combined'],
    token: (id: CardanoTokenId | null) => [
      ...tokensQueryKeys.tokenMetadata.index(),
      'token',
      id,
    ],
    tokenCombined: (id: CardanoTokenId | null) => [
      ...tokensQueryKeys.tokenMetadata.token(id),
      'combined',
    ],
  },
} as const
