/* eslint-disable @typescript-eslint/explicit-function-return-type */

import {Sentry} from '@nufi/frontend-common'
import type {FlowAccountInfo, FlowTokenId} from '@nufi/wallet-flow'
import type {SolanaAccountInfo, SolanaTokenId} from '@nufi/wallet-solana'
import type {UseQueryResult} from '@tanstack/react-query'
import {useQuery} from '@tanstack/react-query'
import type BigNumber from 'bignumber.js'
import {objectEntries} from 'common/src/typeUtils'
import _, {groupBy} from 'lodash'

import {useEnabledExistingBlockchains} from 'src/features/profile/application/enabledBlockchains'
import {useNeverStaleQuery} from 'src/utils/query-utils'

import {getMockServices} from '../../../__tests__/storybook/MockServiceLocator'
import {tokenBlockchains} from '../../../blockchainTypes'
import type {TokenBlockchain} from '../../../blockchainTypes'
import {
  useGetCombinedTokensMetadata as useGetCardanoTokensMetadata,
  useGetCombinedTokenMetadata as useGetCardanoTokenMetadata,
  useGetTokens as useCardanoTokens,
  cachedGetTokensMetadata as cachedGetCardanoTokensMetadata,
  cachedGetTokens as cachedGetCardanoTokens,
  cachedGetAccounts as cachedGetCardanoAccounts,
} from '../../cardano'
import type {CardanoAccountInfo, CardanoTokenId} from '../../cardano'
import {
  useGetTokensMetadata as useGetEvmTokensMetadata,
  useGetTokenMetadata as useGetEvmTokenMetadata,
  useGetTokens as useEvmTokens,
  useGetAccountTokens as useEvmAccountTokens,
  useGetIsAssetAvailable as useIsEvmTokenAvailable,
  useEvmQueryPerBlockchain,
  useEvmQueryPerEnabledBlockchain,
  cachedGetTokensMetadata as cachedGetEvmTokensMetadata,
  cachedGetTokens as cachedGetEvmTokens,
  fnPerEvmBlockchain,
  cachedGetAllTokenBalances as cachedGetAllEvmTokenBalances,
} from '../../evm'
import type {EvmAccountInfo, EvmTokenId} from '../../evm'
import {
  useGetTokensMetadata as useGetFlowTokensMetadata,
  useGetTokenMetadata as useGetFlowTokenMetadata,
  useGetTokens as useFlowTokens,
  useGetAccountTokens as useFlowAccountTokens,
  cachedGetTokensMetadata as cachedGetFlowTokensMetadata,
  cachedGetTokens as cachedGetFlowTokens,
  cachedGetTokenAccounts,
} from '../../flow'
import {
  useGetTokensMetadata as useGetSolanaTokensMetadata,
  cachedGetTokensMetadata as cachedGetSolanaTokensMetadata,
  cachedGetTokens as cachedGetSolanaTokens,
  useGetTokenMetadata as useGetSolanaTokenMetadata,
  useGetTokens as useSolanaTokens,
  useGetAccountTokens as useSolanaAccountTokens,
  cachedGetTokensByAccount as cachedGetSolanaTokensByAccount,
} from '../../solana'
import type {AccountId, AccountInfo, TokenId, TokenMetadata} from '../../types'

import {getHasAccounts} from './accounts'
import {crossBlockchainQueryKeys} from './queryKeys'
import type {AssetAvailableForAccountInfo} from './types'

export function cachedGetTokens(blockchain: TokenBlockchain) {
  const queries = {
    solana: cachedGetSolanaTokens,
    cardano: cachedGetCardanoTokens,
    flow: cachedGetFlowTokens,
    ...fnPerEvmBlockchain(
      (evmBlockchain) => () => cachedGetEvmTokens(evmBlockchain),
    ),
  }
  return queries[blockchain]()
}

export function useTokens(blockchain: TokenBlockchain, enabled = true) {
  const queries = {
    solana: useSolanaTokens(enabled && blockchain === 'solana'),
    cardano: useCardanoTokens(enabled && blockchain === 'cardano'),
    flow: useFlowTokens(enabled && blockchain === 'flow'),
    ...useEvmQueryPerEnabledBlockchain((evmBlockchain) =>
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useEvmTokens(evmBlockchain, enabled && blockchain === evmBlockchain),
    ),
  }
  return queries[blockchain]
}

export function cachedGetTokensMetadata(blockchain: TokenBlockchain) {
  const queries = {
    solana: cachedGetSolanaTokensMetadata,
    cardano: cachedGetCardanoTokensMetadata,
    flow: cachedGetFlowTokensMetadata,
    ...fnPerEvmBlockchain(
      (evmBlockchain) => () => cachedGetEvmTokensMetadata(evmBlockchain),
    ),
  }
  return queries[blockchain]()
}

export type TokenBalancesByAccount = Record<
  AccountId,
  Record<TokenId, BigNumber>
>
export async function cachedGetTokenBalancesByAccount(): Promise<TokenBalancesByAccount> {
  const queries = {
    solana: async () => {
      const res = await cachedGetSolanaTokensByAccount()
      return objectEntries(res).map(([accountId, tokens]) => ({
        accountId,
        tokenBalances: Object.fromEntries(
          tokens.map((t) => [t.token.id, t.amount]),
        ),
      }))
    },
    cardano: async () => {
      const accounts = await cachedGetCardanoAccounts()
      return accounts.map((account) => ({
        accountId: account.id,
        tokenBalances: Object.fromEntries(
          account.tokensBalance.map((t) => [t.token.id, t.amount]),
        ),
      }))
    },
    flow: async () => {
      const tokenBalancesPerAccount = await cachedGetTokenAccounts()
      return tokenBalancesPerAccount.map(({accountId, tokenBalances}) => ({
        accountId,
        tokenBalances: Object.fromEntries(
          tokenBalances.map(({token, amount}) => [token.id, amount]),
        ),
      }))
    },
    ...fnPerEvmBlockchain((evmBlockchain) => async () => {
      const tokenBalances = await cachedGetAllEvmTokenBalances(evmBlockchain)
      const tokenBalancesByAccount = groupBy(tokenBalances, 'accountId')

      return objectEntries(tokenBalancesByAccount).map(
        ([accountId, balances]) => ({
          accountId: accountId as AccountId,
          tokenBalances: Object.fromEntries(
            balances.map((t) => [t.token.id, t.amount]),
          ),
        }),
      )
    }),
  }

  return _(await Promise.all(tokenBlockchains.map((b) => queries[b]())))
    .flatten()
    .keyBy('accountId')
    .mapValues((v) => v.tokenBalances)
    .value()
}

export function useGetTokenBalancesByAccount() {
  const enabledBlockchains = useEnabledExistingBlockchains()
  return useNeverStaleQuery({
    queryKey: crossBlockchainQueryKeys.allTokensBalances(enabledBlockchains),
    queryFn: async () => await cachedGetTokenBalancesByAccount(),
  })
}

export function useGetAllTokensMetadata() {
  const enabledBlockchains = useEnabledExistingBlockchains()
  return useNeverStaleQuery({
    queryKey: crossBlockchainQueryKeys.allTokensMetadata(enabledBlockchains),
    queryFn: async () => {
      let hasSomeError = false
      const tokensMetadata = {} as Partial<
        Record<TokenBlockchain, TokenMetadata[]>
      >

      // Using Promise.all to load in parallel
      await Promise.all(
        enabledBlockchains.map(async (blockchain) => {
          if (
            getHasAccounts(blockchain) &&
            tokenBlockchains.includes(blockchain)
          ) {
            try {
              tokensMetadata[blockchain] =
                await cachedGetTokensMetadata(blockchain)
            } catch (err) {
              Sentry.captureException(err)
              hasSomeError = true
            }
          }
        }),
      )

      return {
        hasSomeError,
        tokensMetadata,
      }
    },
  })
}

function _useGetTokensMetadata(blockchain: TokenBlockchain, enabled = true) {
  const queries = {
    solana: useGetSolanaTokensMetadata(blockchain === 'solana' && enabled),
    cardano: useGetCardanoTokensMetadata(blockchain === 'cardano' && enabled),
    flow: useGetFlowTokensMetadata(blockchain === 'flow' && enabled),
    ...useEvmQueryPerBlockchain((evmBlockchain) =>
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useGetEvmTokensMetadata(
        evmBlockchain,
        blockchain === evmBlockchain && enabled,
      ),
    ),
  }

  return queries[blockchain] as UseQueryResult<TokenMetadata[]>
}

export type UseGetTokensMetadata = typeof _useGetTokensMetadata

export const useGetTokensMetadata: UseGetTokensMetadata = (
  ...args: Parameters<UseGetTokensMetadata>
) => {
  const mockServices = getMockServices()
  return mockServices
    ? mockServices.useGetTokensMetadata(...args)
    : _useGetTokensMetadata(...args)
}

// TODO: Consider only exposing (tokenMetadata + token amounts) via a single
// hook, so that fewer token hooks are exposed.
// https://vacuum.atlassian.net/browse/ADLT-909
function _useAccountTokens(
  blockchain: TokenBlockchain,
  account: AccountInfo,
  enabled = true,
) {
  const queries = {
    cardano: {
      isLoading: false,
      data:
        blockchain === 'cardano'
          ? (account as CardanoAccountInfo).tokensBalance
          : undefined,
      error: null,
    },
    solana: useSolanaAccountTokens(
      account as SolanaAccountInfo,
      blockchain === 'solana' && enabled,
    ),
    flow: useFlowAccountTokens(
      account as FlowAccountInfo,
      blockchain === 'flow' && enabled,
    ),
    ...useEvmQueryPerBlockchain((evmBlockchain) =>
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useEvmAccountTokens(
        evmBlockchain,
        account as EvmAccountInfo<typeof evmBlockchain>,
        undefined,
        blockchain === evmBlockchain && enabled,
      ),
    ),
  }
  return queries[blockchain]
}

export type UseAccountTokens = typeof _useAccountTokens

export function useAccountTokens(...args: Parameters<UseAccountTokens>) {
  const mockServices = getMockServices()
  return mockServices
    ? mockServices.useAccountTokens(...args)
    : _useAccountTokens(...args)
}

export function useIsAssetAvailableForAccount(
  tokenId: TokenId | null,
  account: AccountInfo,
  blockchain: TokenBlockchain,
) {
  const useTokenAlwaysAvailable = useQuery({
    queryKey: ['useIsAssetAvailableForAccount'],
    queryFn: () =>
      ({
        available: true,
      }) as AssetAvailableForAccountInfo,
  })

  const queries = {
    solana: useTokenAlwaysAvailable,
    cardano: useTokenAlwaysAvailable,
    flow: useTokenAlwaysAvailable,
    ...useEvmQueryPerBlockchain((evmBlockchain) =>
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useIsEvmTokenAvailable(
        evmBlockchain,
        tokenId as EvmTokenId<typeof evmBlockchain> | null,
        account as EvmAccountInfo<typeof evmBlockchain>,
        blockchain === evmBlockchain,
      ),
    ),
  }
  return queries[blockchain]
}

function _useGetTokenMetadata(
  tokenId: TokenId,
  blockchain: TokenBlockchain,
  enabled = true,
) {
  const queries = {
    solana: useGetSolanaTokenMetadata(
      tokenId as SolanaTokenId,
      enabled && blockchain === 'solana',
    ),
    cardano: useGetCardanoTokenMetadata(
      tokenId as CardanoTokenId,
      enabled && blockchain === 'cardano',
    ),
    flow: useGetFlowTokenMetadata(
      tokenId as FlowTokenId,
      enabled && blockchain === 'flow',
    ),
    ...useEvmQueryPerBlockchain((evmBlockchain) =>
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useGetEvmTokenMetadata(
        evmBlockchain,
        tokenId as EvmTokenId<typeof evmBlockchain>,
        enabled && blockchain === evmBlockchain,
      ),
    ),
  }

  return queries[blockchain] as UseQueryResult<TokenMetadata>
}

export type UseGetTokenMetadata = typeof _useGetTokenMetadata

export const useGetTokenMetadata: UseGetTokenMetadata = (...args) => {
  const mockServices = getMockServices()
  return mockServices
    ? mockServices.useGetTokenMetadata(...args)
    : _useGetTokenMetadata(...args)
}
