import {Box, styled, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {CopyToClipboard} from 'src/components'

import {useSignContext} from '../../../SignContext'

type RawTxProps = {
  rawTx: string
}

export const RawTx = ({rawTx}: RawTxProps) => {
  const {t} = useTranslation()
  const {layoutProps} = useSignContext()
  const backgroundColor = {
    paper: 'background.paper',
    default: 'background.default',
  }[layoutProps.txDataBgColor || 'paper']

  return (
    <Box sx={{backgroundColor}} pb={0.25}>
      <TopContainer>
        <Typography variant="caption">{t('Raw transaction')}</Typography>
        <CopyToClipboard
          value={rawTx}
          iconFontSize="small"
          hoverableBackgroundFontSize="medium"
        />
      </TopContainer>
      <StyledRawTx title={rawTx}>{rawTx}</StyledRawTx>
    </Box>
  )
}

const StyledRawTx = styled('p')(({theme: {typography, palette, spacing}}) => ({
  wordBreak: 'break-all',
  ...typography.caption,
  color: palette.text.secondary,
  padding: spacing(0, 2),
  margin: spacing(0, 0, 2, 0),
  borderBottomLeftRadius: 8,
  borderBottomRightRadius: 8,
  height: '100%',
  maxHeight: '200px',
  position: 'relative',
  overflow: 'hidden',
  textAlign: 'center',
  display: '-webkit-box',
  WebkitLineClamp: '8',
  WebkitBoxOrient: 'vertical',
}))

const TopContainer = styled('div')(({theme: {spacing}}) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: spacing(0, 2),
  borderTopLeftRadius: 8,
  borderTopRightRadius: 8,
}))
