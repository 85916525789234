import type {
  FlowAccountInfo,
  FlowDelegationInfo,
  FlowSignedTransaction,
  FlowUpdateDelegatorTxType,
} from '@nufi/wallet-flow'
import {flowToNanoflow} from '@nufi/wallet-flow'
import React from 'react'

import type {AccountId} from '../../../../../types'
import {useSignTx, useSubmitTx} from '../../../../../wallet/flow'
import {
  useActiveSchema,
  useActiveScreenState,
  useFormikOnSubmit,
} from '../../../../transaction'
import {DebouncedFormik} from '../../../../utils/DebouncedFormik'
import {useSummarySchema} from '../schemas'
import {useSafeAsyncFlowTxPlanState} from '../utils'

import {FlowUpdateDelegatorContent} from './ModalContent'
import {useUpdateDelegatorSchema} from './schema'
import type {FormSchema} from './types'

type FlowUpdateDelegatorFormProps = {
  onClose: () => unknown
  accountId: AccountId
  delegation: FlowDelegationInfo
  accountInfo: FlowAccountInfo
  txType: FlowUpdateDelegatorTxType
}

export function FlowUpdateDelegatorForm({
  onClose,
  accountId,
  accountInfo,
  delegation,
  txType,
}: FlowUpdateDelegatorFormProps) {
  const updateDelegator = useSignTx()
  const submit = useSubmitTx()
  const {setActiveScreen} = useActiveScreenState()
  const [txPlanState, safeFetchAndUpdateTxPlan] =
    useSafeAsyncFlowTxPlanState<FormSchema>([accountInfo], txType)

  const schema = useActiveSchema({
    details: useUpdateDelegatorSchema({
      txType,
      accountInfo,
      delegation,
    }),
    summary: useSummarySchema({accounts: [accountInfo]}),
  })

  const initialValues: FormSchema = {
    accountId,
    amount: '',
    password: '',
  }

  const onTxSign = async (values: FormSchema) =>
    await updateDelegator.mutateAsyncSilent({
      txPlan: {
        type: txType,
        args: {
          nodeId: delegation.nodeId,
          delegatorId: delegation.id,
          amount: flowToNanoflow(values.amount),
        },
      },
      accountInfo,
    })

  const onTxSubmit = async (
    accountId: AccountId,
    signedTx: FlowSignedTransaction,
  ) => await submit.mutateAsyncSilent({accountId, signedTx})

  const onTxSignAndSubmit = async (values: FormSchema) => {
    const signedTx = await onTxSign(values)
    if (!signedTx) return
    setActiveScreen('submit')
    await onTxSubmit(values.accountId as AccountId, signedTx)
  }

  const onSubmit = useFormikOnSubmit<FormSchema>({
    accounts: [accountInfo],
    onTxSignAndSubmit,
    onDetails: async (v, {setSubmitting, setFieldError}) => {
      const {error} = await safeFetchAndUpdateTxPlan(v, true)
      if (!error) {
        setSubmitting(false)
        setActiveScreen('summary')
      } else {
        setFieldError('amount', (error as Error).message)
      }
    },
  })

  return (
    // TODO: decide if we need to use DebouncedFormik at all
    <DebouncedFormik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={onSubmit}
      onValidated={async ({values, isValid}) => {
        if (isValid) {
          safeFetchAndUpdateTxPlan(values)
        }
      }}
    >
      {(formikProps) => (
        <FlowUpdateDelegatorContent
          {...{
            formikProps,
            setActiveScreen,
            onClose,
            onTxSubmit,
            onTxSignAndSubmit,
            txType,
            accountInfo,
            delegation,
            txPlan: txPlanState.data,
          }}
          submitProps={submit}
          signProps={updateDelegator}
        />
      )}
    </DebouncedFormik>
  )
}
