import {Box, Typography, Alert as MaterialAlert, Collapse} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import cn from 'clsx'
import React, {useState} from 'react'

type CommonAlertProps = {
  severity: 'error' | 'info' | 'success' | 'warning'
  overrides?: {[key: string]: string}
  wrapperClassName?: string
  className?: string
  hideIcon?: boolean
  fullWidthContent?: boolean
  // quick workaround to allow closing error elements
  // consider making this component fully controlled.
  onClose?: (setOpen: (isOpen: boolean) => void) => void
}

export type AlertProps =
  | (CommonAlertProps & {
      text: string | React.ReactNode
    })
  | (CommonAlertProps & {
      children: React.ReactNode | React.ReactNode[]
    })

export function Alert({
  severity,
  overrides,
  className,
  wrapperClassName,
  onClose,
  hideIcon = false,
  fullWidthContent,
  ...restProps
}: AlertProps) {
  const classes = useStyles()
  const [open, setOpen] = useState(true)

  return (
    <Collapse in={open} unmountOnExit>
      <Box className={cn(classes.centeredIconAlert, wrapperClassName)}>
        <MaterialAlert
          severity={severity}
          classes={{
            ...overrides,
            message: cn(
              fullWidthContent && classes.message,
              overrides?.message && overrides.message,
            ),
          }}
          className={className}
          {...(hideIcon ? {icon: false} : {})}
          onClose={
            onClose
              ? () => {
                  onClose(setOpen)
                }
              : undefined
          }
          rtl-data-test-id={
            'rtl-data-test-id' in restProps
              ? restProps['rtl-data-test-id']
              : undefined
          }
        >
          {'text' in restProps ? (
            <Typography>{restProps.text}</Typography>
          ) : (
            restProps.children
          )}
        </MaterialAlert>
      </Box>
    </Collapse>
  )
}

const useStyles = makeStyles(() => ({
  centeredIconAlert: {
    width: '100%',
    '& .MuiAlert-icon': {
      alignItems: 'center',
    },
  },
  message: {
    width: '100%',
  },
}))
