import {request} from '@nufi/frontend-common'
import NodeDetailsManager from '@toruslabs/fetch-node-details'
import type Torus from '@toruslabs/torus.js'
import {legacyKeyLookup, GetPubKeyOrKeyAssign} from '@toruslabs/torus.js-legacy'
import type {LOGIN_TYPE} from 'customauth'

import type {Web3AuthLoginProvider, Web3AuthNetworkType} from './types'
import {WEB3_AUTH_LOGIN_PROVIDERS} from './types'

export const isValidWeb3AuthLoginProvider = (provider: string) =>
  !!WEB3_AUTH_LOGIN_PROVIDERS.includes(provider as Web3AuthLoginProvider)

type VerifierIdExistsParams = {
  network: Web3AuthNetworkType
  verifier: string
  verifierId: string
}
export const verifierIdExists = async ({
  network,
  verifier,
  verifierId,
}: VerifierIdExistsParams): Promise<boolean> => {
  const fetchNodeDetails = new NodeDetailsManager({network})
  const {torusNodeEndpoints} = await fetchNodeDetails.getNodeDetails({
    verifier,
    verifierId,
  })
  const legacyKeyLookupResult = await legacyKeyLookup(
    torusNodeEndpoints,
    verifier,
    verifierId,
  )

  return !!legacyKeyLookupResult.keyResult?.keys.length
}

export const getVerifierIdInfo = async ({
  network,
  verifier,
  verifierId,
}: VerifierIdExistsParams): Promise<{
  keyExists: boolean
  keyCreatedAt?: Date
}> => {
  const fetchNodeDetails = new NodeDetailsManager({network})
  const {torusNodeEndpoints} = await fetchNodeDetails.getNodeDetails({
    verifier,
    verifierId,
  })
  const legacyKeyLookupResult = await legacyKeyLookup(
    torusNodeEndpoints,
    verifier,
    verifierId,
  )

  if (!legacyKeyLookupResult.keyResult?.keys.length) {
    return {keyExists: false}
  }

  const keyLookupResult = await GetPubKeyOrKeyAssign({
    endpoints: torusNodeEndpoints,
    network,
    verifier,
    verifierId,
  })
  const keyCreatedAt = Math.min(
    ...keyLookupResult.lookupResults
      .map((rpcResult) => rpcResult?.result?.keys?.[0]!.created_at)
      .filter((x): x is number => x != null),
  )

  return {
    keyExists: true,
    keyCreatedAt: keyCreatedAt ? new Date(keyCreatedAt * 1000) : undefined,
  }
}

type ImportPrivateKeyParams = {
  authInstance: Torus
  network: Web3AuthNetworkType
  verifier: string
  verifierId: string
  idToken: string
  privateKey: Buffer
}
export const importPrivateKey = async ({
  authInstance,
  network,
  verifier,
  verifierId,
  idToken,
  privateKey,
}: ImportPrivateKeyParams) => {
  const keyAlreadyExists = await verifierIdExists({
    verifier,
    verifierId,
    network,
  })
  // not strictly necessary but we really want to make sure we don't overwrite an existing key
  if (keyAlreadyExists) throw new Error('Cannot import: key already exists')

  const fetchNodeDetails = new NodeDetailsManager({network})
  const {torusNodeEndpoints, torusIndexes, torusNodePub} =
    await fetchNodeDetails.getNodeDetails({
      verifier,
      verifierId,
    })
  return await authInstance.importPrivateKey(
    torusNodeEndpoints,
    torusIndexes,
    torusNodePub,
    verifier,
    {verifier_id: verifierId},
    idToken,
    privateKey.toString('hex'),
  )
}

export const copyWeb3AuthSession = async (
  sourceKey: string,
  targetKey: string,
) => {
  const sessionToCopy = window.localStorage.getItem(sourceKey)

  if (sessionToCopy) {
    window.localStorage.setItem(targetKey, sessionToCopy)
  } else {
    window.localStorage.removeItem(targetKey)
  }
}

const revokeDiscordAccessToken = async (args: {
  accessToken: string
  nufiBackendUrl: string
}) => {
  await request({
    url: `${args.nufiBackendUrl}/api/v1/discord/revoke`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      token: args.accessToken,
    }),
  })
}

const revokeFacebookAccessToken = async (accessToken: string) => {
  await request({
    url: `https://graph.facebook.com/me/permissions?access_token=${accessToken}`,
    method: 'DELETE',
  })
}

export const invalidateAccessTokenIfNeeded = async (args: {
  accessToken: string
  nufiBackendUrl: string
  loginProvider: LOGIN_TYPE
}) => {
  try {
    if (args.loginProvider === 'discord') {
      // to understand, why we need this, see Web3Auth docs:
      // https://github.com/Web3Auth/web3auth-docs/blob/383e3ed30606b22c409c19992637e5e6d0bcd942/src/pages/content-hub/guides/discord.mdx#L167
      await revokeDiscordAccessToken({
        accessToken: args.accessToken,
        nufiBackendUrl: args.nufiBackendUrl,
      })
    } else if (args.loginProvider === 'facebook') {
      // facebook otherwise keeps logging the user into the same account with
      // no option to reasonably change that
      // inspired by Kukai: https://github.com/kukai-wallet/kukai/blob/ca0a629a1d7cb918f15fdd623901a10713b06360/src/app/services/torus/torus.service.ts#L253
      await revokeFacebookAccessToken(args.accessToken)
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e)
  }
}
