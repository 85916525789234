import type {EvmBlockchain} from '../../wallet/evm'
import {evmBlockchains} from '../../wallet/evm'

const DEFAULT_EVM_BLOCKCHAIN = 'ethereum'

const EVM_BLOCKCHAIN_DAPPS: Record<EvmBlockchain, string[]> = {
  ethereum: [], // ethereum is the default anyways
  milkomedaC1: [
    'adaswap.com',
    'blueshift.fi',
    'lemuria.finance',
    'milkomeda.com',
    'milkomeda.muesliswap.com',
    'milkyswap.exchange',
    'milkyway.sh',
    'occamx.fi',
    'paimastudios.com',
  ],
  polygon: [], // TODO: are there any polygon specific dapps?
  optimism: [],
  arbitrumOne: [],
  base: [],
}
/**
 * In the case of `evm` ConnectorKind we try to determine the correct
 * evm blockchain based on the origin (dApp) which is connecting to our dApp connector.
 */
export const getInitialEvmBlockchain = (origin: string): EvmBlockchain => {
  return (
    evmBlockchains.find((evmBlockchain) =>
      EVM_BLOCKCHAIN_DAPPS[evmBlockchain]?.some((dapp) =>
        origin.includes(dapp),
      ),
    ) || DEFAULT_EVM_BLOCKCHAIN
  )
}
