import {
  ExpandMore as ExpandMoreIcon,
  DeleteForever as DeleteIcon,
} from '@mui/icons-material'
import {
  Grid,
  Typography,
  Tabs,
  Tab,
  Box,
  List,
  ListItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  Link,
  styled,
  accordionSummaryClasses,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import type {TxHistoryEntry} from '@nufi/wallet-common'
import type BigNumber from 'bignumber.js'
import clsx from 'clsx'
import _ from 'lodash'
import React from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {useHistory, useLocation, matchPath} from 'react-router-dom'

import {TxHistoryEffect} from 'src/components/TxHistoryEffect'
import {WithConversionRates} from 'src/features/conversionRates/ui'
import {useIsBlockchainEnabled} from 'src/features/profile/application'
import {useGetSwaps} from 'src/features/swaps/application'
import {WithWeakTokenMetadata} from 'src/wallet/public/ui'

import type {
  AssetDetailBlockchain,
  ExplorerLinkBlockchain,
} from '../../../blockchainTypes'
import {explorerLinkBlockchains} from '../../../blockchainTypes'
import {
  QueryGuard,
  FormattedDateTime,
  FormattedAsset,
  Breadcrumbs,
  BreadcrumbBlockchainItem,
  BreadcrumbLabel,
  PagingButton,
  LoadHistoryError,
  TotalBalanceInlineError,
  CopyToClipboard,
  MultilineString,
  Button,
  InternalLink,
  WithTooltip,
  NFTBadge,
  AddressExplorerLink,
  TransactionExplorerLink,
  getAddressExplorerLink,
  Icon,
  ellipsizeString,
  FormattedAssetAsCurrency,
} from '../../../components'
import {MaxWidthEllipsize} from '../../../components/visual/atoms/MaxWidthEllipsis'
import {IS_EXCHANGE_ENABLED} from '../../../constants'
import {routeTo} from '../../../router'
import {
  useRouteToAssetDetailAction,
  useAssetDetailRouteOptions,
  useRouteToAssets,
} from '../../../router/portfolio'
import type {AppError, Blockchain, TokenId} from '../../../types'
import {isBlockchainSubset} from '../../../utils/blockchainGuards'
import {useScrollableStyles} from '../../../utils/layoutUtils'
import {useGetBlockchainName} from '../../../utils/translations'
import type {AccountId} from '../../../wallet'
import {
  useAllAccounts,
  useGetNft,
  useGetTotalBalances,
  useHasAccounts,
} from '../../../wallet'
import {OpenExchangeButton} from '../../exchange/OpenExchangeButton'
import {useForgetModalUtils} from '../account/actions/ForgetToken'
import {NoAccountsScreen} from '../account/NoAccountsScreen'

import {AssetDetailHistoryLoading} from './Loadings'

type AssetDetailHeaderProps = {
  onBack: () => unknown
  blockchain: AssetDetailBlockchain
  tokenId: TokenId | null
  parentSectionLabel: string
}

export function AssetDetailHeader({
  onBack,
  blockchain,
  tokenId,
  parentSectionLabel,
}: AssetDetailHeaderProps) {
  return (
    <Breadcrumbs
      items={[
        <BreadcrumbLabel key={0} onClick={onBack} label={parentSectionLabel} />,
        <BreadcrumbBlockchainItem key={1} {...{tokenId, blockchain}} />,
      ]}
    />
  )
}

type AssetDetailActionsProps = {
  tokenId?: TokenId
  accountId: AccountId
  blockchain: AssetDetailBlockchain
}

export const AssetDetailActions = ({
  tokenId,
  accountId,
  blockchain,
}: AssetDetailActionsProps) => {
  const {t} = useTranslation()
  const onSend = useRouteToAssetDetailAction('send', tokenId)
  const onReceive = useRouteToAssetDetailAction('receive', tokenId)
  const onExchange = useRouteToAssetDetailAction('exchange', tokenId)
  const onForgetTokenSuccess = useRouteToAssets()

  const forgetModalUtils = useForgetModalUtils({
    accountId,
    blockchain,
    tokenId,
    onForgetTokenSuccess,
  })

  return (
    <>
      <Grid container spacing={2}>
        <Grid item>
          <Button
            startIcon={<Icon type="sendIcon" exactSize={24} />}
            variant="outlined"
            textTransform="none"
            size="large"
            onClick={onSend}
          >
            {t('Send')}
          </Button>
        </Grid>
        <Grid item>
          <Button
            startIcon={<Icon type="receiveIcon" exactSize={24} />}
            variant="outlined"
            textTransform="none"
            size="large"
            onClick={onReceive}
          >
            {t('Receive')}
          </Button>
        </Grid>
        {IS_EXCHANGE_ENABLED && (
          <Grid item>
            <OpenExchangeButton
              variant="secondary"
              onOpen={onExchange}
              targetAsset={{blockchain, tokenId}}
            />
          </Grid>
        )}
        {forgetModalUtils && (
          <Grid item>
            <Button
              startIcon={<DeleteIcon />}
              variant="text"
              textTransform="none"
              size="large"
              onClick={forgetModalUtils.openModal}
              color="warning"
            >
              {t('Forget')}
            </Button>
          </Grid>
        )}
      </Grid>
      {forgetModalUtils &&
        forgetModalUtils.isModalOpen &&
        forgetModalUtils.ForgetTokenModal}
    </>
  )
}

type AssetDetailBalanceInfoProps = {
  blockchain: AssetDetailBlockchain
  tokenId: TokenId | null
}

export const AssetDetailBalanceInfo = ({
  blockchain,
  tokenId,
}: AssetDetailBalanceInfoProps) => {
  const balanceQuery = useGetTotalBalances(blockchain, tokenId)

  return (
    <Box display="flex" alignItems="flex-end">
      <QueryGuard ErrorElement={<TotalBalanceInlineError />} {...balanceQuery}>
        {(balance) => {
          const commonProps = {balance, blockchain}
          return tokenId == null ? (
            <AssetDetailBalanceInfoContent {...commonProps} />
          ) : (
            <TokenDetailBalanceInfoContent {...commonProps} tokenId={tokenId} />
          )
        }}
      </QueryGuard>
    </Box>
  )
}

type AssetDetailBalanceInfoContentProps = {
  balance: BigNumber
  blockchain: AssetDetailBlockchain
  tokenId?: TokenId | null
  isNft?: boolean
}

const AssetDetailBalanceInfoContent = ({
  blockchain,
  tokenId,
  balance,
  isNft,
}: AssetDetailBalanceInfoContentProps) => {
  const {t} = useTranslation()

  return (
    <WithWeakTokenMetadata blockchain={blockchain} tokenId={tokenId}>
      {(tokenMetadata) => (
        <Box display="flex" alignItems="center">
          <Typography color="textSecondary" variant="body2" pr={1}>
            {t('Total balance:')}
          </Typography>
          <Typography
            variant="body2"
            component="div"
            display="flex"
            alignItems="center"
          >
            <MaxWidthEllipsize maxWidth={600}>
              <FormattedAsset
                blockchain={blockchain}
                amount={balance}
                includeAssetSymbol
                tokenMetadata={tokenMetadata}
                isSensitiveInformation
              />
            </MaxWidthEllipsize>
            &nbsp; (
            <WithConversionRates>
              {(conversionRates) => (
                <FormattedAssetAsCurrency
                  blockchain={blockchain}
                  balance={balance}
                  tokenMetadata={tokenMetadata}
                  includeCurrencySymbol
                  isSensitiveInformation
                  conversionRates={conversionRates}
                />
              )}
            </WithConversionRates>
            ){!!isNft && <NFTBadge />}
          </Typography>
        </Box>
      )}
    </WithWeakTokenMetadata>
  )
}

const TokenDetailBalanceInfoContent = ({
  blockchain,
  tokenId,
  balance,
}: AssetDetailBalanceInfoContentProps & {tokenId: TokenId}) => {
  const nftInfoQuery = useGetNft(tokenId, blockchain)

  return (
    <QueryGuard
      {...nftInfoQuery}
      data={nftInfoQuery.data}
      ErrorElement={<TotalBalanceInlineError />}
    >
      {(nftInfo) => (
        <AssetDetailBalanceInfoContent
          balance={balance}
          blockchain={blockchain}
          tokenId={tokenId}
          isNft={!!nftInfo}
        />
      )}
    </QueryGuard>
  )
}

const useTabsStyles = makeStyles((theme) => ({
  tabs: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}))

type AssetDetailTabsProps = {
  tabs: Array<{value: string; label: string}>
}

export const AssetDetailTabs = ({tabs}: AssetDetailTabsProps) => {
  const classes = useTabsStyles()
  const location = useLocation()
  const history = useHistory()

  const value = tabs.find(({value}) =>
    matchPath(location.pathname, value.split('?')[0]!),
  )?.value

  const onChange = (event: React.ChangeEvent<unknown>, value: string) => {
    history.replace(value)
  }

  return (
    <Tabs
      {...{value, onChange}}
      indicatorColor="primary"
      textColor="primary"
      className={classes.tabs}
    >
      {tabs.map(({value, label}) => (
        <Tab key={value} {...{value, label}} />
      ))}
    </Tabs>
  )
}

const useHistoryStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flex: 1,
    height: '100%',
    overflow: 'auto',
  },
  itemLeft: {
    flex: 1,
    paddingRight: theme.spacing(1),
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  itemRight: {
    flex: 2,
    padding: theme.spacing(1),
    [theme.breakpoints.down('lg')]: {
      flex: 1,
    },
  },
}))

type AssetDetailAccountWrapperProps = {
  children: React.ReactNode
}

const AssetDetailAccountWrapper = ({
  children,
}: AssetDetailAccountWrapperProps) => {
  const classes = useHistoryStyles()
  return <Box className={classes.wrapper}>{children}</Box>
}

type AssetDetailAccountsItemProps = {
  variant: 'left' | 'right'
  children: React.ReactNode
}

const AssetDetailAccountItem = ({
  variant,
  children,
}: AssetDetailAccountsItemProps) => {
  const classes = useHistoryStyles()
  return (
    <Box
      className={variant === 'left' ? classes.itemLeft : classes.itemRight}
      overflow="auto"
      height="100%"
    >
      {children}
    </Box>
  )
}

const useLayoutStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  header: {
    paddingRight: theme.spacing(2),
  },
  content: {
    padding: theme.spacing(0, 2, 1, 0),
  },
  tabs: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'auto',
  },
}))

type AssetDetailLayoutProps = {
  Header: React.ReactNode
  LeftContent: React.ReactNode
  RightContent: React.ReactNode
  Tabs: React.ReactNode
  TabsContent: React.ReactNode | React.ReactNode[]
  blockchain: AssetDetailBlockchain
}

export const AssetDetailLayout = ({
  Header,
  LeftContent,
  RightContent,
  Tabs,
  TabsContent,
  blockchain,
}: AssetDetailLayoutProps) => {
  const classes = useLayoutStyles()
  const scrollableClasses = useScrollableStyles()
  const hasAccounts = useHasAccounts(blockchain)
  const isBlockchainEnabled = useIsBlockchainEnabled(blockchain)
  return (
    <Box className={clsx(classes.wrapper, scrollableClasses.scrollableParent)}>
      <Box className={classes.header}>{Header}</Box>
      <Box className={classes.content}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>{LeftContent}</Grid>
          <Grid item>{hasAccounts && RightContent}</Grid>
        </Grid>
      </Box>
      <Box className={classes.tabs}>
        {/*
        [AddAccount or EnableBlockchain] No accounts logic is currently duplicated
        in multiple places. We couldn't think of a way to abstract which would be
        worth it so we settled on a bit of duplication. All the places with duplication
        are tagged with the [AddAccount or EnableBlockchain] tag so they would be
        easily searchable.
        */}
        {!hasAccounts || !isBlockchainEnabled ? (
          <NoAccountsScreen blockchain={blockchain} />
        ) : (
          <>
            {Tabs}
            <Box className={scrollableClasses.scrollableList}>
              {TabsContent}
            </Box>
          </>
        )}
      </Box>
    </Box>
  )
}

const TransactionHistoryNotSupportedExplorerLink = ({
  address,
  blockchain,
}: {
  address: string
  blockchain: ExplorerLinkBlockchain
}) => {
  const {t} = useTranslation()
  const getBlockchainName = useGetBlockchainName()
  const explorerUrl = getAddressExplorerLink(address, blockchain)

  return (
    <Typography sx={{textAlign: 'center'}}>
      <Trans
        i18nKey="transaction_history_not_available_with_explorer_link"
        t={t}
        components={{
          ExplorerLink: (
            <Link href={explorerUrl} target="_blank" rel="noopener" />
          ),
        }}
        values={{
          blockchain: getBlockchainName(blockchain),
        }}
      />
    </Typography>
  )
}

export const TransactionHistoryNotSupportedForBlockchain = ({
  blockchain,
  accountId,
}: {
  blockchain: Blockchain
  accountId: AccountId
}) => {
  const {t} = useTranslation()
  const getBlockchainName = useGetBlockchainName()
  const accountsQuery = useAllAccounts()

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100%',
      }}
    >
      <QueryGuard
        {...accountsQuery}
        error={accountsQuery.errorKeys.includes(blockchain)}
        ErrorElement={<LoadHistoryError />}
        loadingVariant="centered"
      >
        {(accounts) => {
          const accountInfo = accounts.find((a) => a.id === accountId)
          if (
            accountInfo &&
            isBlockchainSubset(blockchain, explorerLinkBlockchains)
          ) {
            return (
              <TransactionHistoryNotSupportedExplorerLink
                address={accountInfo.address}
                blockchain={blockchain}
              />
            )
          } else {
            return (
              <Typography sx={{textAlign: 'center'}}>
                {t('transaction_history_not_available', {
                  blockchain: getBlockchainName(blockchain),
                })}
              </Typography>
            )
          }
        }}
      </QueryGuard>
    </Box>
  )
}

export const SectionNotSupportedForBlockchain = ({
  blockchain,
  section,
}: {
  blockchain: Blockchain
  section: 'asset_detail'
}) => {
  const {t} = useTranslation()
  const getBlockchainName = useGetBlockchainName()

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100%',
      }}
    >
      <Typography sx={{textAlign: 'center'}}>
        {section === 'asset_detail' &&
          t('asset_detail_not_available', {
            blockchain: getBlockchainName(blockchain),
          })}
      </Typography>
    </Box>
  )
}

const useAssetDetailHistoryStyles = makeStyles((theme) => ({
  accordion: {
    width: '100%',
    borderRadius: '0 !important',
    boxShadow: 'none',
    backgroundColor: 'inherit',
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 0,
    paddingTop: 0,
  },
  divider: {
    height: 1,
    width: '100%',
  },
  explorerLink: {
    fontSize: '17px',
  },
}))

type AssetDetailHistoryProps = {
  isLoading: boolean
  error: AppError
  blockchain: AssetDetailBlockchain
  tokenId: TokenId | null
  transactions?: Array<TxHistoryEntry<AssetDetailBlockchain, TokenId>>
  transactionType: (txType: string) => JSX.Element
  pagingParams?: {
    onNextPage: () => void
    hasNextPage?: boolean
  }
}

export const AssetDetailHistory = ({
  isLoading,
  error,
  blockchain,
  tokenId,
  transactions,
  transactionType,
  pagingParams,
}: AssetDetailHistoryProps) => {
  const classes = useAssetDetailHistoryStyles()
  const scrollableClasses = useScrollableStyles()
  const {t} = useTranslation()
  const history = useHistory()
  const {accountId} = useAssetDetailRouteOptions()
  const {data: swaps} = useGetSwaps()
  const swapsPayinHashMap = swaps ? _.keyBy(swaps, 'payinHash') : null
  const swapsPayoutHashMap = swaps ? _.keyBy(swaps, 'payoutHash') : null
  const accountsQuery = useAllAccounts()

  const onExchangeDetail = (swapId: string) =>
    history.push(
      routeTo.portfolio.assets
        .blockchain(blockchain)
        .detail.account(accountId)
        .tab('history')
        .exchangeDetail(swapId),
    )

  return (
    <Box className={scrollableClasses.scrollableParent}>
      <QueryGuard
        {...{isLoading, error}}
        data={transactions}
        LoadingElement={
          <Box mt={3}>
            <AssetDetailHistoryLoading />
          </Box>
        }
        ErrorElement={
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <LoadHistoryError />
            <QueryGuard
              {...accountsQuery}
              error={accountsQuery.errorKeys.includes(blockchain)}
            >
              {(accounts) => {
                const accountInfo = accounts.find((a) => a.id === accountId)
                return accountInfo ? (
                  <AddressExplorerLink
                    size="medium"
                    address={accountInfo.address}
                    blockchain={blockchain}
                  />
                ) : null
              }}
            </QueryGuard>
          </Box>
        }
      >
        {(transactions) => (
          <>
            <List className={scrollableClasses.scrollableList}>
              {transactions.length === 0 && (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  height="100%"
                >
                  <Typography variant="h5">
                    {t('No transaction history.')}
                  </Typography>
                </Box>
              )}
              {transactions.map((txHistoryEntry, index) => {
                const payinHashSwap =
                  swapsPayinHashMap &&
                  swapsPayinHashMap[txHistoryEntry.transactionId]
                const payoutHashSwap =
                  swapsPayoutHashMap &&
                  swapsPayoutHashMap[txHistoryEntry.transactionId]
                const swapId = payinHashSwap
                  ? payinHashSwap.id
                  : payoutHashSwap
                    ? payoutHashSwap.id
                    : null
                return (
                  <ListItem
                    key={txHistoryEntry.transactionId}
                    className={classes.listItem}
                  >
                    <Accordion className={classes.accordion}>
                      <HistoryAccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Grid container justifyContent="space-between">
                          <Grid item>
                            <Grid container alignItems="center" spacing={2}>
                              {/* TODO Use icon for unified tx type */}
                              <Grid item>
                                <Grid container direction="column">
                                  <Grid item>
                                    {txHistoryEntry.operations.map(
                                      (ix, ixIndex) => (
                                        <Box
                                          key={`${txHistoryEntry.transactionId}-operation-${ixIndex}`}
                                        >
                                          {transactionType(ix)}
                                        </Box>
                                      ),
                                    )}
                                  </Grid>
                                  <Grid item>
                                    <Typography
                                      variant="body2"
                                      color="textSecondary"
                                    >
                                      <FormattedDateTime
                                        dateTime={txHistoryEntry.time}
                                        variant="datetime"
                                      />
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    mt={1}
                                    fontSize={(theme) =>
                                      theme.typography.body2.fontSize
                                    }
                                  >
                                    <TransactionExplorerLink
                                      txId={txHistoryEntry.transactionId}
                                      blockchain={blockchain}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                              {swapId && (
                                <Grid item>
                                  <WithTooltip
                                    title={
                                      <>
                                        {t(
                                          'This transaction is a part of an exchange.',
                                        )}
                                      </>
                                    }
                                  >
                                    <Icon
                                      type="exchangeIcon"
                                      fontSize="large"
                                      color="primary"
                                    />
                                  </WithTooltip>
                                </Grid>
                              )}
                            </Grid>
                          </Grid>
                          <Grid item xs={4}>
                            <>
                              {txHistoryEntry.effects
                                .filter(
                                  (effect) =>
                                    tokenId == null ||
                                    effect.tokenId === tokenId,
                                )
                                .map((effect) => (
                                  <Grid
                                    container
                                    direction="column"
                                    alignItems="flex-end"
                                    justifyContent="center"
                                    key={effect.tokenId || effect.blockchain}
                                  >
                                    <TxHistoryEffect effect={effect} />
                                  </Grid>
                                ))}
                            </>
                          </Grid>
                        </Grid>
                      </HistoryAccordionSummary>
                      <AccordionDetails>
                        <HistoryAccordionDetailsGrid>
                          <Typography variant="caption" color="textSecondary">
                            {t('Transaction Id')}
                          </Typography>
                          <Typography variant="caption" color="textSecondary">
                            {t('Fee')}
                          </Typography>
                          <CopyToClipboard
                            Label={
                              <MultilineString
                                variant="body2"
                                label={ellipsizeString(
                                  txHistoryEntry.transactionId,
                                  10,
                                  10,
                                )}
                              />
                            }
                            value={txHistoryEntry.transactionId}
                            iconFontSize="small"
                          />
                          <Typography variant="body2">
                            <FormattedAsset
                              amount={txHistoryEntry.fee}
                              blockchain={blockchain}
                              isSensitiveInformation={false}
                            />
                          </Typography>
                          {swapId != null && (
                            <InternalLink
                              title={t('View exchange detail')}
                              onClick={() => onExchangeDetail(swapId ?? '')}
                              icon={false}
                            />
                          )}
                        </HistoryAccordionDetailsGrid>
                      </AccordionDetails>
                    </Accordion>
                    {transactions.length - 1 !== index && (
                      <Divider
                        orientation="horizontal"
                        className={classes.divider}
                      />
                    )}
                  </ListItem>
                )
              })}
            </List>
            {pagingParams && transactions.length > 0 && (
              <PagingButton {...{pagingParams, isLoading}} />
            )}
          </>
        )}
      </QueryGuard>
    </Box>
  )
}

type AssetDetailAccountsLayoutProps = {
  renderAccounts: () => React.ReactNode
  renderHistory: (accountId: AccountId) => React.ReactNode
}

export const AssetDetailAccountsLayout = ({
  renderAccounts,
  renderHistory,
}: AssetDetailAccountsLayoutProps) => {
  const {accountId} = useAssetDetailRouteOptions()

  return (
    <AssetDetailAccountWrapper>
      <AssetDetailAccountItem variant="left">
        {renderAccounts()}
      </AssetDetailAccountItem>
      <AssetDetailAccountItem variant="right">
        {renderHistory(accountId)}
      </AssetDetailAccountItem>
    </AssetDetailAccountWrapper>
  )
}

const HistoryAccordionDetailsGrid = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  placeItems: 'center start',
  maxWidth: 590,
})

const HistoryAccordionSummary = styled(AccordionSummary)({
  [`.${accordionSummaryClasses.expandIconWrapper}`]: {
    marginTop: -35, // alignment
  },
})
