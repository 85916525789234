/* eslint-disable @typescript-eslint/explicit-function-return-type */
import type {
  CardanoPoolIdHex,
  CardanoTokenAmount,
  Lovelaces,
  CardanoTxPlan,
  CardanoSignedTx,
  CardanoPaymentAddress,
  CardanoTxVoteDelegation,
  CardanoAccountInfo,
  CardanoStakeAccountInfo,
  CardanoNewAccount,
  CardanoAccountStoredData,
  AdaHandleResolver,
  AdaDomainsResolver,
} from '@nufi/wallet-cardano'
import type {AccountId} from '@nufi/wallet-common'

import {useMutation} from 'src/utils/mutation-utils'

import {ensureNoPendingTx} from '../../../store/transactions'
import type {TransactionContext} from '../../../store/transactions'
import {sleep} from '../../../utils/helpers'
import type {HotVendor} from '../../types'
import {_saveAccounts} from '../../utils/accountsMutations'
import {getCardano} from '../cardanoManagers'

import {accountsInvalidationKeys} from './invalidationKeys'

export const mutationKeys = {
  cardanoAddHotAccount: ['cardanoAddHotAccount'],
  cardanoAddLedgerAccount: ['cardanoAddLedgerAccount'],
  cardanoAddTrezorAccount: ['cardanoAddTrezorAccount'],
  cardanoSignTransactionFunds: ['cardanoSignTransactionFunds'],
  cardanoSubmitTransaction: ['cardanoSubmitTransaction'],
  cardanoGetMaxTransferAdaTxPlan: ['cardanoGetMaxTransferAdaTxPlan'],
  cardanoGetTransferAdaTxPlan: ['cardanoGetTransferAdaTxPlan'],
  cardanoGetTransferTokenTxPlan: ['cardanoGetTransferTokenTxPlan'],
  cardanoGetDelegateTxPlan: ['cardanoGetDelegateTxPlan'],
  cardanoGetWithdrawTxPlan: ['cardanoGetWithdrawTxPlan'],
  cardanoVerifyAccountAddress: ['cardanoVerifyAccountAddress'],
  cardanoResolveAdaHandle: ['cardanoResolveAdaHandle'],
  cardanoResolveAdaDomain: ['cardanoResolveAdaDomain'],
}

async function saveAccounts(
  accounts: CardanoAccountStoredData[],
): Promise<void> {
  await _saveAccounts('cardano', accounts)
}

export type AddAccountArgs = {
  newAccounts: CardanoNewAccount[]
}

export function useAddHotAccounts(hotVendor: HotVendor) {
  return useMutation(
    mutationKeys.cardanoAddHotAccount,
    async ({newAccounts}: AddAccountArgs) => {
      const accounts = getCardano().accountsStore.getAllAccounts()
      const updatedAccounts = getCardano().wallet.addHotAccounts(
        accounts,
        newAccounts,
        hotVendor,
      )
      await saveAccounts(updatedAccounts)
    },
    {invalidationKeys: accountsInvalidationKeys},
  )
}

export function useAddLedgerAccounts() {
  return useMutation(
    mutationKeys.cardanoAddLedgerAccount,
    async ({newAccounts}: AddAccountArgs) => {
      const accounts = getCardano().accountsStore.getAllAccounts()
      const updatedAccounts = getCardano().wallet.addLedgerAccounts(
        accounts,
        newAccounts,
      )
      await saveAccounts(updatedAccounts)
    },
    {invalidationKeys: accountsInvalidationKeys},
  )
}

export function useAddTrezorAccounts() {
  return useMutation(
    mutationKeys.cardanoAddTrezorAccount,
    async ({newAccounts}: AddAccountArgs) => {
      const accounts = getCardano().accountsStore.getAllAccounts()
      const updatedAccounts = getCardano().wallet.addTrezorAccounts(
        accounts,
        newAccounts,
      )
      await saveAccounts(updatedAccounts)
    },
    {invalidationKeys: accountsInvalidationKeys},
  )
}

export const signTransaction = async ({
  accountId,
  txPlan,
}: {
  accountId: AccountId
  txPlan: CardanoTxPlan
}) => {
  ensureNoPendingTx(accountId)
  await sleep(1000) // solely for better "retry" UX
  const accountData = getCardano().accountsStore.getAccount(accountId)
  return getCardano().accountManager.signTx(accountData, txPlan)
}

export type SignTransactionResolver = typeof signTransaction

export function useSignTransaction(
  resolver: SignTransactionResolver = signTransaction,
) {
  return useMutation(mutationKeys.cardanoSignTransactionFunds, resolver)
}

export type CardanoSubmitTxArgs = {
  accountId: AccountId
  signedTx: CardanoSignedTx
  txPlan: CardanoTxPlan
}

export const submitTransaction = async ({signedTx}: CardanoSubmitTxArgs) => {
  await sleep(1000) // solely for better "retry" UX
  const res =
    await getCardano().accountManager.sendAndConfirmTransaction(signedTx)
  return res
}

export type SubmitTransactionResolver = typeof submitTransaction

export function useSubmitTransaction(
  resolver: SubmitTransactionResolver = submitTransaction,
) {
  return useMutation(mutationKeys.cardanoSubmitTransaction, resolver, {
    invalidationKeys: accountsInvalidationKeys,
    onMutate: (params): TransactionContext => ({
      blockchain: 'cardano' as const,
      accountId: params.accountId,
      transactionId: params.signedTx.txHash,
      txPlan: params.txPlan,
    }),
  })
}

export type TransferFundsPlanArgsBase = {
  addressTo: CardanoPaymentAddress
  accountInfo: Pick<CardanoAccountInfo, 'id' | 'utxos'>
}

type TransferMaxFundsPlanArgs = TransferFundsPlanArgsBase

export function useGetMaxTransferAdaTxPlan() {
  return useMutation(
    mutationKeys.cardanoGetMaxTransferAdaTxPlan,
    async ({accountInfo, addressTo}: TransferMaxFundsPlanArgs) => {
      ensureNoPendingTx(accountInfo.id)
      const txPlan = await getCardano().accountManager.getTransferMaxAdaTxPlan(
        getCardano().accountsStore.getAccount(accountInfo.id),
        addressTo,
        accountInfo,
      )
      return txPlan
    },
  )
}

export type TransferFundsPlanArgs = TransferFundsPlanArgsBase & {
  amount?: Lovelaces
  tokenAmounts?: CardanoTokenAmount[]
}

export const getTransferAssetsTxPlan = async ({
  accountInfo,
  addressTo,
  amount,
  tokenAmounts,
}: TransferFundsPlanArgs) => {
  ensureNoPendingTx(accountInfo.id)
  const txPlan = await getCardano().accountManager.getTransferAdaTxPlan(
    getCardano().accountsStore.getAccount(accountInfo.id),
    addressTo,
    accountInfo,
    amount,
    tokenAmounts,
  )
  return txPlan
}

export type GetTransferAssetsTxPlanResolver = typeof getTransferAssetsTxPlan

export function useGetTransferAssetsTxPlan(
  resolver: GetTransferAssetsTxPlanResolver = getTransferAssetsTxPlan,
) {
  return useMutation(mutationKeys.cardanoGetTransferAdaTxPlan, resolver)
}

export type TransferTokenPlanArgs = TransferFundsPlanArgsBase & {
  tokenAmounts: CardanoTokenAmount[]
}

export type DelegatePlanArgs = {
  accountInfo: Pick<
    CardanoStakeAccountInfo,
    'id' | 'utxos' | 'isStakingKeyRegistered'
  >
  stakepoolId: CardanoPoolIdHex
}

export function useGetDelegateTxPlan() {
  return useMutation(
    mutationKeys.cardanoGetDelegateTxPlan,
    async ({accountInfo, stakepoolId}: DelegatePlanArgs) => {
      ensureNoPendingTx(accountInfo.id)
      const txPlan = await getCardano().accountManager.getDelegateTxPlan(
        getCardano().accountsStore.getAccount(accountInfo.id),
        stakepoolId,
        accountInfo,
      )
      return txPlan
    },
  )
}

type WithdrawPlanArgs = {
  accountInfo: Pick<
    CardanoStakeAccountInfo,
    'id' | 'utxos' | 'rewards' | 'voteDelegation'
  >
  voteDelegation: CardanoTxVoteDelegation | null
}

export function useGetWithdrawRewardsTxPlan() {
  return useMutation(
    mutationKeys.cardanoGetWithdrawTxPlan,
    async ({accountInfo, voteDelegation}: WithdrawPlanArgs) => {
      ensureNoPendingTx(accountInfo.id)
      const txPlan = await getCardano().accountManager.getWithdrawRewardsTxPlan(
        getCardano().accountsStore.getAccount(accountInfo.id),
        voteDelegation,
        accountInfo,
      )
      return txPlan
    },
  )
}

export function useVerifyAccountAddress() {
  return useMutation(
    mutationKeys.cardanoVerifyAccountAddress,
    async (accountId: AccountId) => {
      await getCardano().accountManager.verifyAddress(
        getCardano().accountsStore.getAccount(accountId),
      )
    },
  )
}

// resolver passed from outside to make components using this hook testable
export function useResolveAdaHandle(resolver: AdaHandleResolver) {
  return useMutation(mutationKeys.cardanoResolveAdaHandle, resolver)
}

// resolver passed from outside to make components using this hook testable
export function useResolveAdaDomain(resolver: AdaDomainsResolver) {
  return useMutation(mutationKeys.cardanoResolveAdaHandle, resolver)
}
