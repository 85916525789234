import type {FormikHelpers, FormikProps, FormikValues} from 'formik'
import _ from 'lodash'
import React, {useState, useCallback, useRef} from 'react'

import {getMockServices} from 'src/__tests__/storybook/MockServiceLocator'

import type {AsyncDebounceArgs} from '../../../../utils/debouncing'
import {useAsyncDebounce} from '../../../../utils/debouncing'

import {SubmitGuard} from './SubmitGuard'

type State<TResult> = {data: TResult | null; error: unknown | null}

type OnPreSubmit<TValues extends FormikValues> = (
  values: TValues,
  formikHelpers: FormikHelpers<TValues>,
) => void

export type DebouncedFnArgs<TValues extends FormikValues> = {
  values: TValues
  formikProps: FormikProps<TValues>
}

type DebouncedGuardItem = {
  error: unknown
  finished: boolean
  timeout: number
}

export type RenderSubmitGuard<TValues extends FormikValues> = (props: {
  formikProps: FormikProps<TValues>
  items: DebouncedGuardItem[]
}) => JSX.Element

export function useAsyncDebouncedState<TValues extends FormikValues, TResult>({
  timeout,
  state,
  fn,
  unlockTimeout = 0,
  ...restProps
}: {
  state: State<TResult>
  fn: (args: DebouncedFnArgs<TValues>) => Promise<TResult>
  unlockTimeout?: null | number
} & Pick<
  AsyncDebounceArgs<TResult, DebouncedFnArgs<TValues>>,
  'leading' | 'shouldSkip' | 'timeout'
> &
  (
    | {
        setState: (v: State<TResult>) => void
      }
    | {
        onSuccess: (v: TResult) => void
        onFailure: (err: Error) => void
      }
  )) {
  // Used to disable updates caused by debounced function
  // in case that some "blocking update" is activated (e.g. user clicks on send MAX button).
  // Debounced calls that are started & finished when this flag is "true" will not be run
  // or will not result in the state change.
  const isBlockedRef = useRef(false)

  const lockDebouncing = useCallback(() => {
    isBlockedRef.current = true
  }, [])

  /*
     As `debouncedFn` is normally expected to be called from `useEffect`, the "blocking
     call" (e.g. to calculate "Send MAX") may update one that `useEffect` dependencies.
     In such a case this would result in the `useEffect` running again, resulting in `debouncedFn`
     being called again, resulting in possibly overriding of the value set from the "blocking call".
     Example of this is a "Send MAX" blocking call that sets the "amount", though the `useEffect`
     wrapping the `debouncedFn` depends on that "amount".
     In such a case resetting `isBlockedRef` directly would cause the `debouncedFn` to fire immediately
     and override just updated value from the "blocking call" (e.g. the "amount" example).
     For this reason we by default give a component a time to re-render and only change the `isBlockedRef` in the next "tick".
     The behavior can be turned off by passing `unlockTimeout=null` or one can specify greater timeout.
    */
  const unlockDebouncing = useCallback(() => {
    if (unlockTimeout == null) {
      isBlockedRef.current = false
    } else {
      setTimeout(() => {
        isBlockedRef.current = false
      }, unlockTimeout)
    }
  }, [])

  const {finished, debouncedFn} = useAsyncDebounce({
    timeout,
    fn: async (
      args: DebouncedFnArgs<TValues>,
    ): Promise<TResult | undefined> => {
      // Avoid overriding state if some "blocking update" was started.
      // Return just `undefined` and ignore this value
      if (isBlockedRef.current) return undefined
      return await fn(args)
    },
    onFailure: (err) => {
      // Avoid overriding state if some "blocking update" was started
      if (isBlockedRef.current) return

      'onFailure' in restProps
        ? restProps.onFailure(err)
        : restProps.setState({
            data: null,
            error: err,
          })
    },
    onSuccess: (newTxPlan) => {
      // Avoid overriding state if some "blocking update" was started
      if (isBlockedRef.current || newTxPlan === undefined) return

      'onSuccess' in restProps
        ? restProps.onSuccess(newTxPlan)
        : restProps.setState({
            data: newTxPlan,
            error: null,
          })
    },
    leading: restProps.leading,
    shouldSkip: restProps.shouldSkip,
  })

  const onDataChange = useCallback(
    async (data: DebouncedFnArgs<TValues>) => {
      if (isBlockedRef.current) return
      await debouncedFn(data)
    },
    [debouncedFn],
  )

  return {
    submitGuardMeta: {error: state.error, finished, timeout},
    onDataChange,
    finished,
    lockDebouncing,
    unlockDebouncing,
  }
}

export const _DEBOUNCING_TIMEOUT_SAFE_FACTOR = 2

export const getCardanoTxPlanDebounceTimeoutInMs = (): number => {
  const mockServices = getMockServices()
  return mockServices
    ? mockServices.cardano.TX_PLAN_DEBOUNCE_TIMEOUT_IN_MS
    : 1000
}

type OnSubmit<TValues> =
  | ((
      values: FormikProps<TValues>['values'],
      formikHelpers: FormikHelpers<TValues>,
    ) => void)
  | ((
      values: FormikProps<TValues>['values'],
      formikHelpers: FormikHelpers<TValues>,
    ) => Promise<void>)

/**
 * This hooks provides utils that ensures that the provided
 * `onSubmit` gets only called after all async debounced calls
 * are finished.
 * @returns `onPreSubmit` use this function instead of the `onSubmit` callback
 * when initializing the formik. The function will active debounced guard on form submit.
 * @returns `renderSubmitGuard` use this function to render the submit guard somewhere in your
 * form and pass meta from all async debounced functions to it. It will render an overlay
 * that will disappear when all those debounced functions are finished. Then it will validate the form
 * again and only then will call the initially provided `onSubmit` callback.
 */
export function useSubmitGuard<TValues extends FormikValues>({
  onSubmit,
}: {
  onSubmit: OnSubmit<TValues>
}) {
  const [debounceGuardActive, setDebounceGuardActive] = useState(false)

  const setDebounceGuardOn = useCallback(
    () => setDebounceGuardActive(true),
    [setDebounceGuardActive],
  )
  const setDebounceGuardOff = useCallback(
    () => setDebounceGuardActive(false),
    [setDebounceGuardActive],
  )

  const renderSubmitGuard: RenderSubmitGuard<TValues> = useCallback(
    ({formikProps, items}) => (
      <>
        {debounceGuardActive ? (
          <SubmitGuard
            error={items.find((i) => !!i.error)}
            onSuccess={async () => {
              const errors = await formikProps.validateForm()
              if (_.isEmpty(errors)) {
                await onSubmit(formikProps.values, formikProps)
              }
              setDebounceGuardOff()
            }}
            finished={items.every((i) => i.finished)}
            onFailure={setDebounceGuardOff}
            sleepTimeout={
              Math.max(...items.map((i) => i.timeout)) *
              _DEBOUNCING_TIMEOUT_SAFE_FACTOR
            }
          />
        ) : null}
      </>
    ),
    [debounceGuardActive, onSubmit],
  )

  const onPreSubmit: OnPreSubmit<TValues> = useCallback(
    async (values, formikHelpers) => {
      setDebounceGuardOn()
      formikHelpers.setSubmitting(false)
    },
    [setDebounceGuardOn],
  )

  return {renderSubmitGuard, onPreSubmit}
}
