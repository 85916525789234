import type {TooltipProps} from '@mui/material'
import {Box, Grid, Tooltip} from '@mui/material'
import React from 'react'

import type {ButtonProps} from '../atoms/Button'
import Button from '../atoms/Button'

export type FooterLayoutProps = {
  leftBtnConf?: ButtonProps
  rightBtnConf?: ButtonProps
  leftItem?: React.ReactNode
  rightItem?: React.ReactNode
  leftBtnTooltipTitle?: TooltipProps['title']
  rightBtnTooltipTitle?: TooltipProps['title']
}

export function FooterLayout({
  leftBtnConf,
  rightBtnConf,
  leftItem,
  rightItem,
  leftBtnTooltipTitle = '',
  rightBtnTooltipTitle = '',
}: FooterLayoutProps) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Tooltip title={leftBtnTooltipTitle}>
          <Box display="flex" alignItems="inherit" justifyContent="inherit">
            {leftItem || (
              <Button
                fullWidth
                textTransform="none"
                color="primary"
                variant="outlined"
                {...leftBtnConf}
              />
            )}
          </Box>
        </Tooltip>
      </Grid>
      <Grid item xs={6}>
        <Tooltip title={rightBtnTooltipTitle}>
          <Box display="flex" alignItems="inherit" justifyContent="inherit">
            {rightItem || (
              <Button
                fullWidth
                textTransform="none"
                variant="contained"
                color="primary"
                {...rightBtnConf}
              />
            )}
          </Box>
        </Tooltip>
      </Grid>
    </Grid>
  )
}
