import type {Currency} from 'src/features/conversionRates/domain'

const _allCurrenciesToCurrencyId: Record<Currency, string> = {
  USD: 'usd',
  EUR: 'eur',
  CNY: 'cny',
  GBP: 'gbp',
  JPY: 'jpy',
  RUB: 'rub',
  CHF: 'chf',
  CZK: 'czk',
  CAD: 'cad',
  AUD: 'aud',
  INR: 'inr',
  TRY: 'try',
  BRL: 'brl',
  MXN: 'mxn',
  SGD: 'sgd',
  PLN: 'pln',
  THB: 'thb',
  PHP: 'php',
  IDR: 'idr',
  VND: 'vnd',
  HUF: 'huf',
  AED: 'aed',
  NOK: 'nok',
  KRW: 'krw',
  UAH: 'uah',
  ARS: 'ars',
  NZD: 'nzd',
  MYR: 'myr',
}

export const allCurrenciesToCurrencyId = Object.keys(_allCurrenciesToCurrencyId)
  .sort()
  .reduce(
    (acc, key) => ({
      ...acc,
      [key]: _allCurrenciesToCurrencyId[key as Currency],
    }),
    {},
  ) as Record<Currency, string>

/**
 * Coingecko appends this to the "vs_currency" id,
 * @example {"usd": {"eth": 0.00028344, "eth_24h_change": -1.5102221370889388}}
 */
const SUFFIX_24H_CHANGE = '_24h_change'

/** @see {@link SUFFIX_24H_CHANGE} */
export const is24hChangeKey = (coingeckoId: string): boolean =>
  coingeckoId.endsWith(SUFFIX_24H_CHANGE)

/** @see {@link SUFFIX_24H_CHANGE} */
export const strip24hChangeSuffix = (coingeckoId: string): string =>
  is24hChangeKey(coingeckoId)
    ? coingeckoId.slice(0, -SUFFIX_24H_CHANGE.length)
    : coingeckoId
