import {evmBlockchains} from '@nufi/wallet-evm'

export const txHistoryEvmBlockchains = [...evmBlockchains] as const

export const exchangeEvmBlockchains = [
  'ethereum',
  'polygon',
  'optimism',
  'arbitrumOne',
  'base',
] as const

export const tokenEvmBlockchains = [...evmBlockchains] as const

export const nftEvmBlockchains = [
  'ethereum',
  'polygon',
  'optimism',
  'arbitrumOne',
  'base',
] as const

export const explorerLinkEvmBlockchains = [...evmBlockchains] as const

export const sendEvmBlockchains = [...evmBlockchains] as const

export const assetDetailEvmBlockchains = [...evmBlockchains] as const

export const csvExportEvmBlockchains = [] as const

export const profileTokenImportEvmBlockchains = [...evmBlockchains] as const

export const moonpayEvmBlockchains = ['ethereum'] as const

export const existingEvmBlockchains = [...evmBlockchains] as const
