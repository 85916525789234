import {Alert} from '@mui/material'
import {LedgerTransportErrors} from '@nufi/wallet-common'
import {LedgerEvmErrors} from '@nufi/wallet-evm'
import {LedgerSolanaErrors} from '@nufi/wallet-solana'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {Failure} from 'src/pages/utils/Failure'
import type {FailureProps} from 'src/pages/utils/Failure'

import type {Blockchain} from '../../../types'

import {HelpSection, ledgerImages, UnsupportedBrowserMessage} from './common'

type LedgerFailureProps = {
  blockchain: Blockchain
  onRetry?: () => Promise<unknown>
  error: unknown
}

const useCommonFailureProps = (
  props: LedgerFailureProps,
  defaultMessage: string,
) => {
  const {error, blockchain, onRetry} = props
  const {t} = useTranslation()

  const defaultProps: FailureProps = {
    message: defaultMessage,
    HelpSection: <HelpSection {...{blockchain}} severity="error" />,
    Image: ledgerImages.error,
    onRetry,
  }
  const getFailureProps = (message: string): FailureProps => {
    switch (message) {
      case LedgerTransportErrors.Unsupported:
        return {
          ...defaultProps,
          message: t('Unsupported browser.'),
          onRetry: undefined,
          HelpSection: <UnsupportedBrowserMessage />,
        }
      case LedgerSolanaErrors.BlindSigningDisabled:
        return {
          ...defaultProps,
          message: t('Action requires enabled blind signing'),
          onRetry: undefined,
          HelpSection: (
            <Alert severity="error">
              {LedgerSolanaErrors.BlindSigningDisabled}
            </Alert>
          ),
        }
      case LedgerSolanaErrors.PubKeyMismatch:
        return {
          ...defaultProps,
          message: t('Hardware wallet public key mismatch'),
          HelpSection: (
            <Alert severity="error">{LedgerSolanaErrors.PubKeyMismatch}</Alert>
          ),
        }
      case LedgerEvmErrors.BlindSigningDisabled:
        return {
          ...defaultProps,
          message: t('Action requires enabled blind signing'),
          onRetry: undefined,
          HelpSection: (
            <Alert severity="error">
              {t(
                'Blind signing is required for Token transactions. Enable "smart contract data" or "blind signing" on your Ledger device.',
              )}
            </Alert>
          ),
        }
      default:
        return defaultProps
    }
  }
  if (error && typeof error === 'object' && 'message' in error) {
    const errorMessage: {message: string} = error as {message: string}
    return getFailureProps(errorMessage.message)
  } else if (typeof error === 'string') return getFailureProps(error)
  else return defaultProps
}

export function LedgerFailedSign(props: LedgerFailureProps) {
  const {t} = useTranslation()
  const FailureProps = useCommonFailureProps(
    props,
    t('Could not sign the transaction.'),
  )

  return <Failure {...FailureProps} />
}

export function LedgerFailedExport(props: LedgerFailureProps) {
  const {t} = useTranslation()
  const FailureProps = useCommonFailureProps(
    props,
    t('Could not export Ledger keys.'),
  )

  return <Failure {...FailureProps} />
}

export function LedgerFailedVerify(props: LedgerFailureProps) {
  const {t} = useTranslation()
  const FailureProps = useCommonFailureProps(
    props,
    t('Could not verify address.'),
  )

  return <Failure {...FailureProps} />
}
