import type {Theme} from '@mui/material'
import {Grid, Typography, Box, styled} from '@mui/material'
import type {ClassNameMap} from '@mui/styles'
import {makeStyles} from '@mui/styles'
import clsx from 'clsx'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {AddressExplorerButton} from 'src/components/explorerLinks'
import {useGetWalletKindName} from 'src/utils/translations'

import type {Blockchain, CryptoProviderType, WalletKind} from '../../../types'
import {useEllipsisStyles} from '../../../utils/layoutUtils'
import {CopyToClipboard} from '../../CopyToClipboard'
import {BlockchainIcon, CryptoProviderIcon, WalletKindIcon} from '../atoms/Icon'
import {ellipsizeString} from '../atoms/TextUtils'

type IconColProps = IconColSharedProps & {
  Icon: React.ReactNode
  title: string | React.ReactNode
  description?: string | React.ReactNode
  titleIcon?: React.ReactNode
  titleIconSpacing?: number
  columnSpacing?: number
}

export type IconColSharedProps = {
  ellipsizeTitle?: boolean
  ellipsisOptions?: EllipsisOptions
}

type EllipsisOptions = {
  width?: string
}

/**
 * When using **ellipsizeTitle** make sure that the parent of this component has set desired width.
 */
export function IconCol({
  Icon,
  title,
  description,
  titleIcon,
  titleIconSpacing,
  ellipsizeTitle,
  ellipsisOptions,
  columnSpacing,
}: IconColProps) {
  const classes: ClassNameMap<string> = {
    ...useIconColEllipsisStyles(ellipsisOptions || {}),
    ...useEllipsisStyles(),
  }
  return (
    <Grid
      container
      alignItems="center"
      className={clsx(ellipsizeTitle && classes.ellipsisContainer)}
      columnSpacing={columnSpacing ?? 2}
    >
      <Grid item display="flex">
        {Icon}
      </Grid>
      <Grid
        className={clsx(ellipsizeTitle && classes.ellipsisTextContainer)}
        item
      >
        <Box display="flex" alignItems="center">
          <Typography
            component="span"
            className={clsx(ellipsizeTitle && classes.ellipsis)}
            color="textPrimary"
          >
            {title}
          </Typography>
          {titleIcon && (
            <Box ml={titleIconSpacing ?? 0} display="inline-flex">
              {titleIcon}
            </Box>
          )}
        </Box>
        {typeof description === 'string' ? (
          <Typography variant="body2" color="textSecondary">
            {description}
          </Typography>
        ) : (
          description
        )}
      </Grid>
    </Grid>
  )
}

type AccountCardColProps = IconColSharedProps & {
  walletKind: WalletKind
  blockchain: Blockchain | null
  name: string
  cryptoProviderType: CryptoProviderType
  address?: string
  descriptionFontSize?: 'body2' | 'caption'
  preferBlockchainIcon?: boolean
}

/**
 * When using **ellipsizeTitle** make sure that the parent of this component has set desired width.
 */
export function AccountCardCol({
  walletKind,
  blockchain,
  name,
  cryptoProviderType,
  address,
  descriptionFontSize,
  preferBlockchainIcon = false,
  ...rest
}: AccountCardColProps) {
  const {t} = useTranslation()
  const getWalletKindName = useGetWalletKindName()
  return (
    <IconCol
      Icon={
        preferBlockchainIcon && blockchain != null ? (
          <BlockchainIcon blockchain={blockchain} />
        ) : (
          <WalletKindIcon walletKind={walletKind} />
        )
      }
      title={name}
      description={
        <AccountCardDescriptionWrapper
          descriptionFontSize={descriptionFontSize || 'body2'}
        >
          {address && (
            <AddressWrapper>
              <Box mr={0.5}>
                <span title={address}>{ellipsizeString(address, 8, 3)}</span>
              </Box>
              <CopyToClipboard
                value={address}
                hoverableBackgroundFontSize="small"
                iconFontSize="inherit"
                stopPropagation
              />
              <AddressExplorerButton
                blockchain={blockchain}
                address={address}
                disabledTooltipTitle={
                  walletKind === 'evm'
                    ? t('Please choose a blockchain first.')
                    : undefined
                }
              />
            </AddressWrapper>
          )}
          <span>{getWalletKindName(walletKind)}</span>
        </AccountCardDescriptionWrapper>
      }
      titleIcon={<CryptoProviderIcon cryptoProviderType={cryptoProviderType} />}
      titleIconSpacing={1}
      {...rest}
    />
  )
}

const AccountCardDescriptionWrapper = styled('div')<{
  descriptionFontSize: 'body2' | 'caption'
}>(({theme: {typography}, descriptionFontSize}) => ({
  ...typography[descriptionFontSize],
}))

const AddressWrapper = styled('div')(({theme: {spacing}}) => ({
  display: 'flex',
  alignItems: 'center',
  margin: spacing(-0.5, 0),
}))

const useIconColEllipsisStyles = makeStyles<Theme, EllipsisOptions>(() => ({
  ellipsisContainer: {
    flexWrap: 'nowrap',
  },
  ellipsisTextContainer: {
    width: (ellipsisOptions) => ellipsisOptions?.width ?? '100%',
    overflow: 'hidden',
  },
}))
