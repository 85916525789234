import type {TypographyProps, Theme} from '@mui/material'
import {Typography, Box} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import React from 'react'
import type {SyntheticEvent} from 'react'

type TextButtonProps = {
  label: string
  disabled?: boolean
  onClick?: (e: React.SyntheticEvent) => unknown
  fontWeight?: 'regular' | 'medium'
  Icon?: React.ReactNode
  color?: TypographyProps['color']
  variant?: TypographyProps['variant']
  className?: string
  testId?: string
}

export default function TextButton({
  label,
  onClick,
  fontWeight,
  color = 'primary',
  variant,
  disabled,
  Icon,
  className,
  testId,
}: TextButtonProps) {
  const classes = useStyles({disabled, color})

  const onClickDisabled = (e: SyntheticEvent) => {
    e.stopPropagation()
  }

  const _fontWeight = (
    {
      regular: 'fontWeightRegular',
      medium: 'fontWeightMedium',
    } as const
  )[fontWeight || 'regular']

  return (
    <Typography
      component="div"
      className={clsx(classes.root, className)}
      color={color}
      variant={variant || 'body1'}
      onClick={!disabled ? onClick : onClickDisabled}
      {...(testId ? {'rtl-data-test-id': testId} : {})}
    >
      <Box display="flex" justifyContent="center" alignItems="center">
        <Box fontWeight={_fontWeight}>{label}</Box>
        {Icon && (
          <Box
            ml={0.5}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            {Icon}
          </Box>
        )}
      </Box>
    </Typography>
  )
}

const useStyles = makeStyles<
  Theme,
  {disabled?: boolean; color?: TypographyProps['color']}
>((theme) => ({
  root: ({disabled, color}) => ({
    cursor: 'default',
    opacity: disabled ? 0.5 : 1,
    '&:hover': !disabled
      ? {
          cursor: 'pointer',
          color:
            color === 'primary'
              ? theme.palette.primary.dark
              : theme.palette.primary.main,
        }
      : {},
  }),
}))
