import {useContext, createContext} from 'react'

import type {NumberFormat} from 'src/components'
import type {ConnectorBlockchain} from 'src/dappConnector/types'
import type {
  SignScreenInfo,
  CreateCardanoCollateralScreenInfo,
  SigningKind,
} from 'src/store/dappConnector'
import type {AccountOfflineInfo} from 'src/types'
import {assert} from 'src/utils/assertion'

export type SignState<
  // Consider moving `create-cardano-collateral` into SigningKind
  // as this leads to messy code.
  TState extends SigningKind | CreateCardanoCollateralScreenInfo['state'],
> = {
  selectedAccount: AccountOfflineInfo | null
  blockchain: ConnectorBlockchain | null
  origin: string | null
  signData: TState extends SigningKind
    ? SignScreenInfo<SigningKind>
    : CreateCardanoCollateralScreenInfo
  favIconUrl: string | null
  layoutProps: {
    maxDetailsHeight: number | string
    txDataBgColor?: 'default' | 'paper'
    screenWidth?: number | string
    signLayoutOverflow?: 'scroll' | 'auto' | 'visible'
  }
  hideProfileInfo?: boolean
  enforceMnemonicPassword?: boolean
  selectedAccountAmountFormat?: NumberFormat
}

export const SignContext = createContext<
  | SignState<SigningKind>
  | SignState<CreateCardanoCollateralScreenInfo['state']>
  | null
>(null)

export const useSignContext = () => {
  const signContext = useContext(SignContext)
  assert(signContext != null, 'Sign: context not provided')
  return signContext
}
