import {Box, Grid, Link, Typography, styled} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import React from 'react'
import {Trans, useTranslation} from 'react-i18next'

import {externalLinks} from 'src/constants'
import {useAvailableExternalLoginProviders} from 'src/features/login'

import type {ProfileMetadata} from '../../appStorage'
import type {ButtonProps} from '../../components'
import {SimpleTimeline, Button} from '../../components'

import type {WalletType} from './types'

export const useCommonProfileStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: 448,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  spacing: {
    '& > :not(:first-child)': {
      marginTop: theme.spacing(4),
    },
  },
  widerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 500,
  },
  headerSubtitle: {
    marginTop: theme.spacing(1),
  },
  form: {
    marginTop: theme.spacing(3),
  },
  submitButton: {
    marginTop: theme.spacing(3),
  },
}))

type NavigateBackWrapperProps = {
  children: JSX.Element
  justifyContent?: 'center' | 'left'
}

export function NavigateBackWrapper({
  children,
  justifyContent = 'center',
}: NavigateBackWrapperProps) {
  return (
    <Box display="flex" justifyContent={justifyContent} mt={3}>
      {children}
    </Box>
  )
}

type CreateProfileTimelineProps = {
  step: 'name' | 'password' | 'recovery' | 'blockchains' | 'done'
  walletType?: WalletType
}

export function CreateProfileTimeline({
  step,
  walletType,
}: CreateProfileTimelineProps) {
  const {t} = useTranslation()
  const isHwUser = walletType === 'hw'

  const stepMapping = {
    name: t('Wallet name'),
    password: t('Password'),
    recovery: t('Recovery phrase'),
    ...(isHwUser ? {} : {blockchains: t('Choose blockchains')}),
    ...(isHwUser ? {} : {done: t('Done')}),
  }

  return (
    <Box>
      <Typography variant="h5">{t('Create new wallet')}</Typography>
      <Box mt={3}>
        <SimpleTimeline
          steps={Object.values(stepMapping)}
          activeStep={stepMapping[step] as string}
        />
      </Box>
    </Box>
  )
}

export const profileNameExists = (
  name: string,
  profiles: Array<ProfileMetadata>,
) => profiles.some((p) => p.name === name)

type ButtonChoiceProps = {
  preferredOption: {
    onClick: () => void
    label: string
    'data-test-id': string
  }
  otherOption: {
    onClick: () => void
    label: string
    'data-test-id': string
  }
}

export function ButtonChoice({
  otherOption,
  preferredOption,
}: ButtonChoiceProps) {
  return (
    <Grid container direction="column" spacing={2}>
      <Grid item data-test-id={preferredOption['data-test-id']}>
        <Button
          textTransform="none"
          fullWidth
          variant="contained"
          color="primary"
          onClick={preferredOption.onClick}
        >
          {preferredOption.label}
        </Button>
      </Grid>
      <Grid item data-test-id={otherOption['data-test-id']}>
        <Button
          textTransform="none"
          fullWidth
          variant="outlined"
          color="primary"
          onClick={otherOption.onClick}
        >
          {otherOption.label}
        </Button>
      </Grid>
    </Grid>
  )
}

export const ExternalProvidersWrapper = ({
  children,
  title,
}: {
  children: React.ReactNode
  title?: string
}) => {
  const {t} = useTranslation()

  const providers = useAvailableExternalLoginProviders()

  const totalProvidersCount = Object.values(providers)
    .map((value) => (Array.isArray(value) ? value.length : value ? 1 : 0))
    .reduce((sum, length) => sum + length, 0)

  return (
    <Box py={3}>
      <Typography textAlign="center" color="textSecondary">
        {title || t('Or connect with')}
      </Typography>
      <ExternalProviderContainer
        childrenWidth={totalProvidersCount < 5 ? '50%' : '33%'}
      >
        {children}
      </ExternalProviderContainer>
      <Typography
        mt={2}
        component="p"
        variant="body2"
        color="textSecondary"
        textAlign="center"
      >
        <Trans
          i18nKey="web3auth_deprecated_social_platform_link"
          t={t}
          components={{
            Link: <Link target="_blank" href={externalLinks.nufiContactLink} />,
          }}
        />
      </Typography>
    </Box>
  )
}

type ExternalProviderContainerProps = {
  childrenWidth: '33%' | '50%'
}

const ExternalProviderContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'childrenWidth',
})<ExternalProviderContainerProps>(({theme, childrenWidth}) => {
  const sideSpacing = 4 // px
  return {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    rowGap: `${sideSpacing * 2}px`,
    marginTop: theme.spacing(2),
    [`& > .${externalProviderButtonClass}`]: {
      width: `calc(${childrenWidth} - ${sideSpacing * 2}px)`,
      margin: `0px ${sideSpacing}px`,
    },
  }
})

const externalProviderButtonClass = 'ExternalProviderButton'

export const ExternalProviderButton = styled(
  ({className, ...props}: ButtonProps) => (
    <Button
      {...props}
      className={clsx(externalProviderButtonClass, className)}
    />
  ),
)({
  borderRadius: 8,
})

ExternalProviderButton.defaultProps = {
  variant: 'outlined',
  color: 'default',
  textTransform: 'capitalize',
}
