import type {
  CardanoAccountInfo,
  CardanoTransactionHash,
} from '@nufi/wallet-cardano'

import type {Cbor} from 'src/dappConnector/api/cardano/types'
import {ensureNoPendingTx} from 'src/store/transactions'
import {useMutation} from 'src/utils/mutation-utils'
import {combineRawTxAndSignatures} from 'src/wallet/cardano'

import type {DexHunterOrder} from '../domain'
import {dexHunterApi} from '../infrastructure'

import type {DexHunterAccountConnector} from './connector'
import {dexHunterQueryKeys} from './queryKeys'

export const useGetCancelDexHunterOrderTx = (account: CardanoAccountInfo) => {
  return useMutation(
    ['getCancelDexHunterOrderTx', account.id],
    async (order: DexHunterOrder) => {
      const {cborTx} = await dexHunterApi.cancelOrder(order.id, account.address)
      return cborTx
    },
  )
}

// Thats used to avoid showing redundant errors, as user already see them in the signing modal.
export const USER_REJECTED_ERROR = 'USER_REJECTED'

export const useSignDexHunterTx = (
  account: CardanoAccountInfo,
  connector: DexHunterAccountConnector,
) => {
  return useMutation(
    ['signDexHunterTx', account.id],
    async (cborTx: string): Promise<Cbor<'transaction_witness_set'>> => {
      ensureNoPendingTx(account.id)
      try {
        return await connector.signTx(cborTx as Cbor<'transaction'>, true)
      } catch (err) {
        throw USER_REJECTED_ERROR
      }
    },
  )
}

export const useSubmitDexHunterTx = (
  account: CardanoAccountInfo,
  connector: DexHunterAccountConnector,
) => {
  return useMutation(
    ['submitDexHunterTx', account.id],
    async ({
      rawTx,
      rawTxWitnesses,
    }: {
      rawTx: Cbor<'transaction'>
      rawTxWitnesses: Cbor<'transaction_witness_set'>
    }) => {
      const rawSignedTx = combineRawTxAndSignatures(
        Buffer.from(rawTx, 'hex'),
        Buffer.from(rawTxWitnesses, 'hex'),
      )

      const result = await connector.submitTx(
        rawSignedTx.toString('hex') as Cbor<'transaction'>,
      )
      return result as CardanoTransactionHash
    },
    {invalidationKeys: [dexHunterQueryKeys.orders(account.address)]},
  )
}
