import {PowerSettingsNew, Settings as SettingsIcon} from '@mui/icons-material'
import {Typography, Grid} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {
  useEnabledExistingBlockchains,
  useGetIsBlockchainEnabled,
} from 'src/features/profile/application'
import {EnableBlockchain} from 'src/features/walletStorage/ui'
import {useUiStore} from 'src/store/ui'
import {useGetHasAccounts} from 'src/wallet'

import {Button, SafeCenterAligner} from '../../../components'
import type {Blockchain} from '../../../types'
import {useGetBlockchainName} from '../../../utils/translations'
import {AddAccount} from '../sharedActions/addAccount/AddAccount'

type NoAccountsScreenProps = {
  blockchain?: Blockchain
  message?: string
}

export function NoAccountsScreen({blockchain, message}: NoAccountsScreenProps) {
  const {t} = useTranslation()
  const getBlockchainName = useGetBlockchainName()

  const enabledBlockchains = useEnabledExistingBlockchains()
  const hasAccounts = useGetHasAccounts()
  const isBlockchainEnabled = useGetIsBlockchainEnabled()
  const {setIsBlockchainManagementModalOpen} = useUiStore()

  // [AddAccount or EnableBlockchain] No accounts logic is currently duplicated
  // in multiple places. We couldn't think of a way to abstract which would be
  // worth it so we settled on a bit of duplication. All the places with duplication
  // are tagged with the [AddAccount or EnableBlockchain] tag so they would be
  // easily searchable.
  const {title, description, actionElement} = (() => {
    if (enabledBlockchains.length === 0) {
      return {
        title: t('No blockchains enabled'),
        description: undefined,
        actionElement: (
          <Button
            variant="contained"
            textTransform="none"
            size="large"
            color="primary"
            onClick={() => setIsBlockchainManagementModalOpen(true)}
            startIcon={<SettingsIcon />}
          >
            {t('Manage blockchains')}
          </Button>
        ),
      }
    }

    if (blockchain == null || !hasAccounts(blockchain)) {
      return {
        title: t('no_accounts', {
          blockchain: blockchain ? getBlockchainName(blockchain) : '',
        }),
        description:
          message ?? t('Add account to Send, Receive or Stake with NuFi'),
        actionElement: <AddAccount preselectedBlockchain={blockchain} />,
      }
    }

    if (!isBlockchainEnabled(blockchain)) {
      return {
        title: t('blockchain_not_activated', {
          blockchain: getBlockchainName(blockchain),
        }),
        description: message,
        actionElement: (
          <EnableBlockchain
            blockchain={blockchain}
            startIcon={<PowerSettingsNew />}
            displayConfirmationModal
          />
        ),
      }
    }

    // We should never get here but apparently the component can be rerendered
    // even if `hasAccounts` and `isBlockchainEnabled` are true already.
    return {title: '', description: '', actionElement: ''}
  })()

  return (
    <SafeCenterAligner>
      <Grid
        container
        direction="column"
        spacing={2}
        alignItems="center"
        justifyContent="center"
      >
        <Grid item>
          <Typography variant="h5">{title}</Typography>
        </Grid>
        {description && (
          <Grid item>
            <Typography variant="body1">{description}</Typography>
          </Grid>
        )}
        <Grid item>{actionElement}</Grid>
      </Grid>
    </SafeCenterAligner>
  )
}
