import {useMutation} from 'src/utils/mutation-utils'

import {queryKeys} from './queries'
import {swapsApi} from './swapsApi'

const P = 'swaps'

export const mutationKeys = {
  putSwap: [P, 'putSwap'],
}

export function useSaveSwap() {
  return useMutation(mutationKeys.putSwap, swapsApi.putSwap, {
    invalidationKeys: [queryKeys.getSwaps],
  })
}
