import type {FlowSignedTransaction} from '@nufi/wallet-flow'
import type {FormikProps} from 'formik'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'

import {routeTo} from '../../../../../router'
import type {AccountId} from '../../../../../types'
import {assert} from '../../../../../utils/assertion'
import type {
  useSignTx,
  useSubmitTx,
} from '../../../../../wallet/flow/public/mutations'
import {SubmitScreen, SuccessBody, SuccessFooter} from '../../../../transaction'
import {StakeModalHeader} from '../../../common/utils'

import type {FormSchema} from './types'

type SetupStakingSubmitScreenProps = {
  onSignAndSubmitGoBack: () => void
  onNewTransaction: (() => void) | null
  formikProps: FormikProps<FormSchema>
  submitProps: ReturnType<typeof useSubmitTx>
  signProps: ReturnType<typeof useSignTx>
  onClose: () => unknown
  onTxSubmit: (
    accountId: AccountId,
    signedTx: FlowSignedTransaction,
  ) => Promise<unknown>
}

export const SetupStakingSubmitScreen = ({
  onSignAndSubmitGoBack,
  onNewTransaction,
  formikProps,
  signProps,
  submitProps,
  onClose,
  onTxSubmit,
}: SetupStakingSubmitScreenProps) => {
  const {t} = useTranslation()
  const history = useHistory()
  const blockchain = 'flow'

  const openRegisterDelegatorModal = () =>
    history.push(
      routeTo.staking.myStaking.flow.account(formikProps.values.accountId)
        .registerDelegator,
    )

  const signedTransaction = signProps.data
  assert(!!signedTransaction)
  return (
    <SubmitScreen
      accountId={formikProps.values.accountId}
      onBack={onSignAndSubmitGoBack}
      CustomSuccessBody={
        <SuccessBody
          customSuccessText={t(
            'Staking collection created successfully. Click continue to create a staking account.',
          )}
          accountId={formikProps.values.accountId}
          txHash={submitProps.data as string}
          blockchain={blockchain}
        />
      }
      CustomSuccessFooter={
        <SuccessFooter
          leftActionText={t('Close')}
          onLeftAction={onClose}
          onRightAction={openRegisterDelegatorModal}
          rightActionText={t('Continue')}
        />
      }
      onRetry={() =>
        onTxSubmit(formikProps.values.accountId, signedTransaction)
      }
      ModalHeader={<StakeModalHeader {...{blockchain, onClose}} />}
      {...{onClose, submitProps, blockchain, onNewTransaction}}
    />
  )
}
