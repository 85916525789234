import {Grid, Typography, Box} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {isBlockchainSubset} from '@nufi/wallet-common'
import type BigNumber from 'bignumber.js'
import type {SwapAsset} from 'common'
import type {FC} from 'react'
import React from 'react'
import {useTranslation} from 'react-i18next'

import type {useGetExchangeParams} from 'src/features/exchange/application'
import type {ExchangeAssetsDetails} from 'src/features/exchange/domain'
import type {BaseQueryWithRefetch} from 'src/utils/query-utils'

import type {ExchangeBlockchain} from '../../../blockchainTypes'
import {
  Alert,
  InlineError,
  InlineLoading,
  ModalLayout,
  useModalSharedStyles,
} from '../../../components'
import type {AccountInfo, TokenId} from '../../../types'
import {assert} from '../../../utils/assertion'
import type {BaseSignTxFlowProps} from '../../transaction/multiStepFormUtils/BaseSignTxFlow'
import {
  ExchangeModalHeader,
  getInternalBlockchainFromAsset,
} from '../components'
import {
  blockchainToSwapAsset,
  TOKEN_INTERNAL_TX_BLOCKCHAINS,
} from '../constants'
import {useCustomMetadata} from '../CustomMetadataContainer'
import type {SwapTokenData} from '../types'

import type {DetailsValues} from './details/schema'

type LoadingOrErrorProps = {isError: boolean; onClose: () => void}

export const LoadingOrError = ({isError, onClose}: LoadingOrErrorProps) => {
  const classes = useStyles()
  const {t} = useTranslation()
  return (
    <ModalLayout
      header={<ExchangeModalHeader onClose={onClose} />}
      body={
        isError ? (
          <Box p={2}>
            <InlineError error={t('Could not load exchange data')} />
          </Box>
        ) : (
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            className={classes.fullHeight}
          >
            <Typography variant="h6">
              {t('Loading exchange data...')}
            </Typography>
            <Box pt={2}>
              <InlineLoading />
            </Box>
          </Grid>
        )
      }
    />
  )
}

export type SignAndSubmitHandlers<
  TSignResponse,
  TSubmitResponse extends string,
> = {
  signProps: BaseSignTxFlowProps<TSignResponse, TSubmitResponse>['signProps']
  submitProps: BaseSignTxFlowProps<
    TSignResponse,
    TSubmitResponse
  >['submitProps']
}

export type WithSignAndSubmitHandlers<
  TSignResponse,
  TSubmitResponse extends string,
  TFromToken extends SwapTokenData | null = SwapTokenData | null,
> = FC<{
  amount: string
  fromAccountInfo: AccountInfo
  toAddress: string
  fromToken: TFromToken
  children: (
    injected: SignAndSubmitHandlers<TSignResponse, TSubmitResponse>,
  ) => React.ReactElement
}>

export type AmountFieldMaxAmountData =
  | {
      type: 'native'
      value: BigNumber
    }
  | {
      type: 'token'
      value: BigNumber
      decimals: number
    }
  | {
      type: 'external'
      value: BigNumber
    }

export type AmountFieldDataProperties = {
  maxAmount: {
    isLoading: boolean
    data?: AmountFieldMaxAmountData
    error?: unknown
  }
  fee: {
    isLoading: boolean
    data?: BigNumber
    error?: unknown
  }
}

export type WithAmountFieldPropertiesProps = {
  fromAccountInfo: AccountInfo | null
  values: DetailsValues
  exchangeParamsQuery: ReturnType<
    BaseQueryWithRefetch<typeof useGetExchangeParams>
  >
  fromToken: SwapTokenData | null
  children: (injected: {
    validate: (value: DetailsValues['amount']) => Promise<string | undefined>
    data: AmountFieldDataProperties
  }) => React.ReactElement
}

export type WithAmountFieldProperties = FC<WithAmountFieldPropertiesProps>

export type WithSummaryTxFeeProps = {
  blockchain: ExchangeBlockchain | null
  fromExternal: boolean
  children: (injected: {fee: BigNumber | null}) => React.ReactElement
}

export type WithSummaryTxFeeType = FC<WithSummaryTxFeeProps>

export type AssetTokenData = {
  id: TokenId
  balances: Record<string, BigNumber>
}

export type AssetTokenDataQuery = {
  isLoading: boolean
  error: unknown
  data: AssetTokenData | null
}

export type WithGetAssetTokenDataProps = {
  blockchain: ExchangeBlockchain | null
  asset: SwapAsset | null
  exchangeAssetsDetails: ExchangeAssetsDetails
  children: (injected: AssetTokenDataQuery | null) => React.ReactElement
}

export type WithGetAssetTokenDataType = FC<WithGetAssetTokenDataProps>

export type ExchangeDeps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  WithSignAndSubmitHandlers: WithSignAndSubmitHandlers<any, any>
  WithAmountFieldProperties: WithAmountFieldProperties
  WithSummaryTxFee: WithSummaryTxFeeType
  // EXCHANGE_REFACTOR
  // TODO: rename to WithGetAssetTokenDataQuery as it indeed returns query
  WithGetAssetTokenData: WithGetAssetTokenDataType
}

export type ExchangeDepsConfig = Record<ExchangeBlockchain, ExchangeDeps>

export const WithSummaryTxFee: WithSummaryTxFeeType = ({
  children,
  fromExternal,
  blockchain,
}) => {
  const {getNonReactiveMetadata} = useCustomMetadata<{fee: BigNumber}>()

  if (fromExternal || blockchain == null) return children({fee: null})

  const context = getNonReactiveMetadata(blockchain)
  assert(context.fee != null)
  return children({fee: context.fee})
}

const useStyles = makeStyles(() => ({
  fullHeight: {
    height: '100%',
  },
}))

export const WithNoTokensGetAssetTokenData: ExchangeDeps['WithGetAssetTokenData'] =
  ({children}) => children(null)

export const isAssetInternallySwappable = (
  exchangeAssetsDetails: ExchangeAssetsDetails,
  asset: SwapAsset,
) => {
  const blockchain = getInternalBlockchainFromAsset(
    exchangeAssetsDetails,
    asset,
  )
  if (blockchain == null) return false
  const isToken = asset !== blockchainToSwapAsset(blockchain)

  if (isToken) {
    return isBlockchainSubset(blockchain, TOKEN_INTERNAL_TX_BLOCKCHAINS)
  } else {
    return true
  }
}

type ExchangeAssetNotificationProps = {
  children: React.ReactNode
  testId?: string
}

export const ExchangeAssetNotification = ({
  children,
  testId,
}: ExchangeAssetNotificationProps) => {
  const classes = useModalSharedStyles()
  return (
    <Box className={classes.formField} rtl-data-test-id={testId}>
      <Alert severity="warning">{children}</Alert>
    </Box>
  )
}
