import React, {useContext, useState} from 'react'

import type {Blockchain} from 'src/types'

export const PortalContentContext = React.createContext<null | HTMLElement>(
  null,
)

export const usePortfolioPortalRef = () => useContext(PortalContentContext)

type PortfolioPageContextType = {
  blockchain: Blockchain | null
  setBlockchain: (blockchain: Blockchain | null) => void
  hideZeroBalances: boolean
  setHideZeroBalances: (hideZeroBalances: boolean) => void
  hideNfts: boolean
  setHideNfts: (hideNfts: boolean) => void
}

const PortfolioPageContext =
  React.createContext<PortfolioPageContextType | null>(null)

export function usePortfolioPageContext(): PortfolioPageContextType {
  const context = useContext(PortfolioPageContext)
  if (context == null) {
    throw new Error('Missing provider for portfolio page context!')
  }
  return context
}

export const PortfolioPageContextProvider = ({
  children,
}: React.PropsWithChildren) => {
  const [blockchain, setBlockchain] = useState<Blockchain | null>(null)
  const [hideZeroBalances, setHideZeroBalances] = useState(true)
  const [hideNfts, setHideNfts] = useState(true)

  return (
    <PortfolioPageContext.Provider
      value={{
        blockchain,
        hideZeroBalances,
        hideNfts,
        setBlockchain,
        setHideZeroBalances,
        setHideNfts,
      }}
    >
      {children}
    </PortfolioPageContext.Provider>
  )
}
