import {Paper} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import type {SolanaStakeHistoryEntry} from '@nufi/wallet-solana'
import React from 'react'

import {assertUnreachable} from '../../../../utils/assertion'
import {ScrollableList} from '../../common/overview/StakeOverviewUtils'

import {
  DeactivationHistoryEntryRow,
  DelegationHistoryEntryRow,
  RewardHistoryEntryRow,
  WithdrawalHistoryEntryRow,
  SplitHistoryEntryRow,
  InitializeHistoryEntryRow,
} from './StakeHistoryUtils'

type SolanaStakeHistoryListProps = {
  stakeHistory: SolanaStakeHistoryEntry[]
}

function StakeHistoryEntryRow({entry}: {entry: SolanaStakeHistoryEntry}) {
  switch (entry.type) {
    case 'reward':
      return <RewardHistoryEntryRow {...entry} />
    case 'delegate':
      return <DelegationHistoryEntryRow {...entry} />
    case 'deactivate':
      return <DeactivationHistoryEntryRow {...entry} />
    case 'initialize':
      return <InitializeHistoryEntryRow {...entry} />
    case 'withdraw':
      return <WithdrawalHistoryEntryRow {...entry} />
    case 'split':
      return <SplitHistoryEntryRow {...entry} />
    default:
      return assertUnreachable()
  }
}

export default function SolanaStakeHistoryList({
  stakeHistory,
}: SolanaStakeHistoryListProps) {
  const classes = useStyles()
  return (
    <ScrollableList>
      {stakeHistory.map((entry, idx) => (
        <Paper
          key={`${entry.effectiveSlot.toString()}-${idx}`}
          className={classes.wrapper}
          elevation={0}
        >
          <StakeHistoryEntryRow entry={entry} />
        </Paper>
      ))}
    </ScrollableList>
  )
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    cursor: 'pointer',
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1, 0),
    background: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
  },
}))
