// Kept in .ts file so it can be imported from service-worker

import type {EvmBlockchain} from '../../../wallet/evm'
import {getEvmNetworkConfig} from '../../../wallet/evm/evmNetworkConfig'

export const getEvmAddressExplorerLink = (
  blockchain: EvmBlockchain,
  address: string,
) => {
  const {blockExplorerUrl} = getEvmNetworkConfig(blockchain)
  return `${blockExplorerUrl}/address/${address}`
}

export const getEvmTokenHistoryExplorerLink = (
  blockchain: EvmBlockchain,
  address: string,
) => {
  const {blockExplorerUrl} = getEvmNetworkConfig(blockchain)
  return `${blockExplorerUrl}/address/${address}#tokentxns`
}

export const getEvmTransactionExplorerLink = (
  blockchain: EvmBlockchain,
  txId: string,
) => {
  const {blockExplorerUrl} = getEvmNetworkConfig(blockchain)
  return `${blockExplorerUrl}/tx/${txId}`
}
