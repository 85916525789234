import type {MasterProfileData} from './types'

export const DEFAULT_MASTER_PROFILE_DATA: MasterProfileData = {
  profiles: [],
  lastLoggedInLocalProfileId: null,
}

export enum ProfileConflictCode {
  NO_CONFLICT,
  SAME_PROFILE_EXISTS_CONFLICT,
  DIFFERENT_PROFILE_NAME_CONFLICT,
}

export enum StoreType {
  MasterProfile = 'master_profile',
  UserProfileData = 'user_profile_data',
  UserProfileMnemonic = 'user_profile_mnemonic',
}
