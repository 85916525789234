import type {TypographyProps} from '@mui/material'
import {Grid, Typography} from '@mui/material'
import type BigNumber from 'bignumber.js'
import clsx from 'clsx'
import React from 'react'

import type {ConvertedBalanceResponse} from 'src/features/assets/domain/conversions'
import type {ConversionRates} from 'src/features/conversionRates/domain'
import {useSettings} from 'src/features/profile/application'
import {spread} from 'src/utils/reactHelpers'

import type {Blockchain, TokenMetadata} from '../../types'
import {useEllipsisStyles} from '../../utils/layoutUtils'
import {Aligner, TypographyLoader} from '../visual'

import {FormattedAsset} from './FormattedAsset'
import type {NumberFormat} from './FormattedAssetAmount'
import {FormattedAssetAsCurrency} from './FormattedAssetAsCurrency'
import {FormattedCurrency} from './FormattedCurrency'

export type FormattedAssetPairProps = {
  amount: BigNumber
  blockchain: Blockchain
  tokenMetadata?: TokenMetadata
  ellipsizeLabel?: boolean
  shouldUseCompactNotation?: boolean
  isSensitiveInformation: boolean

  // FormattedAsset only props
  variant?: TypographyProps['variant']
  includeAssetSymbol?: boolean
  amountFormat?: NumberFormat
  color?: TypographyProps['color']
  conversionProps: ConversionRatesVariantProps | ConvertedBalanceVariantProps
  prefix?: string
}

type ConversionRatesVariantProps = {
  // Preferable for cases when rendering list of items, thus the conversion
  // data can only be accessed/fetched in parent.
  conversionRates: ConversionRates
}

type ConvertedBalanceVariantProps = {
  // Preferable for cases when already having converted balance, e.g.
  // from previous computations.
  convertedBalance: ConvertedBalanceResponse | {type: 'is-loading'}
}

export function FormattedAssetPair({
  tokenMetadata,
  ellipsizeLabel,
  variant = 'body2',
  amountFormat,
  color,
  amount,
  blockchain,
  isSensitiveInformation,
  includeAssetSymbol,
  shouldUseCompactNotation,
  conversionProps,
  prefix,
}: FormattedAssetPairProps) {
  const {currency} = useSettings()

  return (
    <FormattedAssetPairLayout
      ellipsizeLabel={ellipsizeLabel}
      variant={variant}
      color={color}
      primaryElement={
        <FormattedAsset
          {...spread(
            {
              amount,
              blockchain,
              isSensitiveInformation,
              includeAssetSymbol,
              amountFormat,
              tokenMetadata,
              prefix,
            },
            FormattedAsset,
          )}
        />
      }
      secondaryElement={
        'convertedBalance' in conversionProps ? (
          conversionProps.convertedBalance.type === 'is-loading' ? (
            <TypographyLoader variant="body2" />
          ) : (
            <FormattedCurrency
              convertedBalance={conversionProps.convertedBalance}
              currency={currency}
              isSensitiveInformation={isSensitiveInformation}
              shouldUseCompactNotation={shouldUseCompactNotation}
              includeCurrencySymbol
            />
          )
        ) : (
          <FormattedAssetAsCurrency
            balance={amount}
            includeCurrencySymbol
            isSensitiveInformation={isSensitiveInformation}
            blockchain={blockchain}
            conversionRates={conversionProps.conversionRates}
            tokenMetadata={tokenMetadata}
          />
        )
      }
    />
  )
}

type FormattedAssetPairLayoutProps = {
  primaryElement: React.ReactNode
  secondaryElement: React.ReactNode
  variant?: TypographyProps['variant']
  ellipsizeLabel?: boolean
  color?: TypographyProps['color']
}

export function FormattedAssetPairLayout({
  primaryElement,
  secondaryElement,
  variant = 'body2',
  ellipsizeLabel,
  color = 'textPrimary',
}: FormattedAssetPairLayoutProps) {
  const classes = useEllipsisStyles()

  return (
    <Grid container item direction="column">
      <Typography
        variant={variant}
        color={color}
        textAlign="end"
        width={ellipsizeLabel ? '100%' : undefined}
        className={clsx(ellipsizeLabel && classes.ellipsis)}
      >
        {primaryElement}
      </Typography>
      <Typography variant={variant} color="inherit" component="div">
        <Aligner align="right">{secondaryElement}</Aligner>
      </Typography>
    </Grid>
  )
}
