import type {TextFieldProps} from '@mui/material'
import {TextField} from '@mui/material'
import type {FormikProps} from 'formik'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {getHasFormError} from '../../../utils/form'

/**
 * Use for individual password fields outside of login forms,
 * that require user's current password, to prevent new password suggestions provided by browser.
 */

export const currentPasswordTestIds = {
  passwordField: 'current-password-field',
}

type CurrentPasswordFieldProps<T extends {password: string}> =
  TextFieldProps & {
    formikProps: FormikProps<T>
  }
export function CurrentPasswordField<T extends {password: string}>({
  formikProps,
  ...textFieldProps
}: CurrentPasswordFieldProps<T>) {
  const {t} = useTranslation()
  const {values, handleChange, errors} = formikProps
  type FormField = keyof typeof values
  const hasError = getHasFormError(formikProps)

  const testIdProps = {'rtl-data-test-id': currentPasswordTestIds.passwordField}

  const inputProps = textFieldProps.inputProps
    ? {
        ...testIdProps,
        ...textFieldProps.inputProps,
      }
    : testIdProps

  return (
    <TextField
      value={values.password}
      onChange={handleChange<FormField>('password')}
      label={t('Enter wallet password')}
      error={hasError('password')}
      type="password"
      helperText={hasError('password') && <>{errors.password}</>}
      variant="outlined"
      autoFocus
      fullWidth
      autoComplete="current-password"
      {...textFieldProps}
      inputProps={inputProps}
    />
  )
}
