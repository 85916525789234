import type {
  Cbor,
  TransactionUnspentOutput,
} from '../../dappConnector/api/cardano/types'
import {cardanoDappConnectorApi} from '../../webextension/dappConnector/connectors/cardano'

import type {API, Handler} from './types'

type MethodReturnTypes = {
  cardano_signTx: Promise<Cbor<'transaction_witness_set'>>
  cardano_signData: Promise<Cbor<'transaction_witness_set'>>
  cardano_submitTx: Promise<string>
  cardano_getBalance: Promise<Cbor<'value'>>
  cardano_getCollateral: Promise<TransactionUnspentOutput[]>
  cardano_getUtxos: Promise<TransactionUnspentOutput[] | undefined>
  cardano_getNetworkId: Promise<number>
  cardano_getUsedAddresses: Promise<Cbor<'address'>[]>
  cardano_getUnusedAddresses: Promise<Cbor<'address'>[]>
  cardano_getChangeAddress: Promise<Cbor<'address'>>
  cardano_getRewardAddress: Promise<Cbor<'address'>>
  cardano_getRewardAddresses: Promise<Cbor<'address'>[]>
}

const api: API<'cip34', MethodReturnTypes> = () => {
  return {
    cardano_signTx: async (params) => {
      return cardanoDappConnectorApi.signTx(params[0], params[1])
    },
    cardano_signData: async (params) => {
      return cardanoDappConnectorApi.signTx(params[0], params[1])
    },
    cardano_submitTx: async (params) =>
      cardanoDappConnectorApi.submitTx(params[0]),
    cardano_getBalance: async () => cardanoDappConnectorApi.getBalance(),
    cardano_getCollateral: async () => cardanoDappConnectorApi.getCollateral(),
    cardano_getUtxos: async () => cardanoDappConnectorApi.getUtxos(),
    cardano_getNetworkId: async () => cardanoDappConnectorApi.getNetworkId(),
    cardano_getUsedAddresses: async () =>
      cardanoDappConnectorApi.getUsedAddresses(),
    cardano_getUnusedAddresses: async () =>
      cardanoDappConnectorApi.getUnusedAddresses(),
    cardano_getChangeAddress: async () =>
      cardanoDappConnectorApi.getChangeAddress(),
    cardano_getRewardAddress: async () =>
      (await cardanoDappConnectorApi.getRewardAddresses())[0]!,
    cardano_getRewardAddresses: async () =>
      cardanoDappConnectorApi.getRewardAddresses(),
  }
}

export const cip34Handler: Handler<'cip34'> = (chain) => {
  return (method, params) => api(chain)[method](params)
}
