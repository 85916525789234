/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {getIsWebExtension} from '@nufi/frontend-common'
import type {EvmBlockchain} from '@nufi/wallet-evm'
import type {MutationKey} from '@tanstack/react-query'
import _ from 'lodash'

import {cloudSyncServiceLocator} from 'src/features/profileSync/application'
import {AccountStorageServiceLocator} from 'src/features/walletStorage/application'
import {useMutation} from 'src/utils/mutation-utils'
import {blockchainToWalletKind} from 'src/wallet/walletKind'
import type {WalletKind} from 'src/wallet/walletKind'

import type {
  AccountStoredData,
  WalletKindAccountStoredData,
} from '../../store/wallet'
import type {AccountId, AccountName, Blockchain} from '../../types'
import type {CrossAppMessage} from '../../webextension/dappConnector/messaging/types'
import {
  renameAccount,
  setDefaultEvmAccount,
  setDefaultStandardAccount,
  softDeleteEvmAccount,
  softDeleteStandardAccount,
} from '../AccountsStoreManager'

import {getWalletKindWalletManagers} from './walletManagerUtils'

export async function _saveAccounts(
  walletKind: WalletKind,
  accounts: AccountStoredData[],
): Promise<void> {
  await AccountStorageServiceLocator.instance().saveAccountsForWalletKind(
    walletKind,
    accounts,
  )

  if (getIsWebExtension()) {
    chrome.runtime.sendMessage<CrossAppMessage>({
      isCrossAppMessage: true,
      senderContext: 'app',
      targetContext: 'connectorWindow',
      type: 'cross-app-event',
      target: 'broadcast',
      method: 'refresh',
      args: [],
    })
  }

  await cloudSyncServiceLocator.instance().syncProfileData()
}

async function mutateAccounts<TWalletKind extends WalletKind>(
  walletKind: TWalletKind,
  fn: (
    accounts: WalletKindAccountStoredData<TWalletKind>[],
  ) => WalletKindAccountStoredData<TWalletKind>[],
) {
  const accounts = getWalletKindWalletManagers(
    walletKind,
  ).accountsStore.getAllAccounts() as WalletKindAccountStoredData<TWalletKind>[]
  const updatedAccounts = fn(accounts)

  const changedAccounts = _.differenceWith(updatedAccounts, accounts, _.isEqual)

  await _saveAccounts(
    walletKind,
    updatedAccounts.map((a) =>
      changedAccounts.includes(a) ? {...a, updatedAt: Date.now()} : a,
    ),
  )
}

export function _useForgetAccount(
  walletKind: WalletKind,
  invalidationKeys: MutationKey[],
) {
  return useMutation(
    [walletKind, 'forgetAccount'],
    async ({accountId}: {accountId: AccountId}) => {
      if (walletKind !== 'evm') {
        await mutateAccounts(walletKind, (accounts) =>
          softDeleteStandardAccount(accounts, accountId),
        )
      } else {
        await mutateAccounts(walletKind, (accounts) =>
          softDeleteEvmAccount(accounts, accountId),
        )
      }
    },
    {invalidationKeys},
  )
}

export function _useRenameAccount(
  walletKind: WalletKind,
  invalidationKeys: MutationKey[],
) {
  return useMutation(
    [walletKind, 'renameAccount'],
    async ({accountId, name}: {accountId: AccountId; name: AccountName}) => {
      await mutateAccounts(walletKind, (accounts) =>
        renameAccount<AccountStoredData>(accounts, accountId, name),
      )
    },
    {invalidationKeys},
  )
}

export function _useSetDefaultAccount(
  blockchain: Blockchain,
  invalidationKeys: MutationKey[],
) {
  const walletKind = blockchainToWalletKind(blockchain)
  return useMutation(
    [walletKind, 'setDefaultAccount'],
    async ({accountId}: {accountId: AccountId}) => {
      if (walletKind !== 'evm') {
        await mutateAccounts(walletKind, (accounts) =>
          setDefaultStandardAccount(accounts, accountId),
        )
      } else {
        await mutateAccounts(walletKind, (accounts) =>
          setDefaultEvmAccount(accounts, accountId, [
            blockchain as EvmBlockchain,
          ]),
        )
      }
    },
    {invalidationKeys},
  )
}
