import {safeAssertUnreachable} from '@nufi/frontend-common'
import type BigNumber from 'bignumber.js'
import {useEffect, useRef} from 'react'

import type {WeakUseMutationResult} from 'src/utils/mutation-utils'

import type {SendMultipleAssetsBlockchains} from '../pages/send/common/types'
import type {Blockchain, TokenId} from '../wallet/types'

import type {TxTrackingArgs, TxTrackingArgsBase} from './types'
import {trackTransactionSubmission} from './utils'

export const useTrackTxSubmission = <TSubmitResponse>(
  submitProps: WeakUseMutationResult<
    TSubmitResponse,
    unknown,
    unknown,
    unknown
  >,
  args: TxTrackingArgs,
) => {
  const trackedData = useRef(false)
  useEffect(() => {
    if (
      (submitProps.isPending || submitProps.isSuccess) &&
      trackedData.current === false
    ) {
      trackedData.current = true
      trackTransactionSubmission({...args})
    }
  }, [submitProps])
}

type TrackSingleTokenTx = {
  type: 'single_token_transfer_tx'
  tokenAmount: BigNumber
  tokenId: TokenId
  isNft: boolean
}

type TrackMultiAssetTx = {
  type: 'multi_asset_transfer_tx'
  nativeAmountToSend: BigNumber
  tokensToSendCount: number
  nftsToSendCount: number
}

type TrackNativeTx = {
  type: 'native_transfer_tx'
  nativeAmountToSend: BigNumber
}

export const useTrackMultiAssetTxSubmission = <TSubmitResponse>(
  submitProps: WeakUseMutationResult<
    TSubmitResponse,
    unknown,
    unknown,
    unknown
  >,
  args: Omit<TxTrackingArgsBase, 'blockchain'> & {
    blockchain: Blockchain
    sendContext: TrackSingleTokenTx | TrackMultiAssetTx | TrackNativeTx
  },
) => {
  const {provider} = args

  useTrackTxSubmission(submitProps, {
    ...{provider},
    ...(() => {
      switch (args.sendContext.type) {
        case 'single_token_transfer_tx':
          return {
            blockchain: args.blockchain as Blockchain,
            type: 'transfer_token',
            id: args.sendContext.tokenId,
            isNft: args.sendContext.isNft,
            value: args.sendContext.tokenAmount.toNumber(),
          }
        case 'multi_asset_transfer_tx':
          return {
            blockchain: args.blockchain as SendMultipleAssetsBlockchains,
            type: 'transfer_assets',
            value: args.sendContext.nativeAmountToSend.toNumber(),
            tokens: args.sendContext.tokensToSendCount,
            nfts: args.sendContext.nftsToSendCount,
          }
        case 'native_transfer_tx':
          return {
            blockchain: args.blockchain as Blockchain,
            type: 'transfer_coin',
            value: args.sendContext.nativeAmountToSend.toNumber(),
          }
        default:
          return safeAssertUnreachable(args.sendContext)
      }
    })(),
  })
}
