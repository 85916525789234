import type {RequestContext} from '@nufi/dapp-client-core'
import {assert, safeAssertUnreachable} from '@nufi/frontend-common'
import {
  arbitrumOneChainIds,
  baseChainIds,
  ethereumChainIds,
  isEvmBlockchain,
  isValidEvmAddress,
  milkomedaC1ChainIds,
  optimismChainIds,
  polygonChainIds,
  toHexString,
} from '@nufi/wallet-evm'
import type {
  EvmBlockchain,
  EvmSignedPersonalData,
  EvmSignedTypedData,
  EvmTransactionHash,
  PlainEvmAddress,
  TokenStandard,
  EvmContractAddress,
  EvmAddress,
  EvmChainId,
} from '@nufi/wallet-evm'
import * as wcUtils from '@walletconnect/utils'
import {objectEntries} from 'common/src/typeUtils'
import {isHexPrefixed} from 'ethereumjs-util'
import * as yup from 'yup'

import config from 'src/config'
import {useDappConnectorStore} from 'src/store/dappConnector'
import {evmDappConnectorApi} from 'src/webextension/dappConnector/connectors/evm'
import type {
  Permission,
  RequestedPermission,
} from 'src/webextension/dappConnector/connectors/evm/types'

import type {API, Handler} from './types'
import {assertShape, prefixedHex} from './validation'

type MethodReturnTypes = {
  eth_accounts: Promise<PlainEvmAddress[]>
  eth_requestAccounts: Promise<PlainEvmAddress[]>
  eth_signTypedData: Promise<EvmSignedTypedData>
  eth_signTypedData_v3: Promise<EvmSignedTypedData>
  eth_signTypedData_v4: Promise<EvmSignedTypedData>
  eth_sendTransaction: Promise<EvmTransactionHash>
  personal_sign: Promise<EvmSignedPersonalData>
  wallet_addEthereumChain: Promise<null>
  wallet_switchEthereumChain: Promise<null>
  wallet_getPermissions: Promise<Permission[]>
  wallet_requestPermissions: Promise<RequestedPermission[]>
  wallet_watchAsset: Promise<boolean>
}

const SWITCH_ETHEREUM_CHAIN_PARAMS_SCHEMAS = [
  yup
    .object({
      chainId: yup
        .string()
        .required()
        .matches(/^(0x[a-fA-F0-9]+|eip155:\d+)$/),
    })
    .required(),
] as const

const SEND_TX_PARAMS_SCHEMA = [
  yup
    .object({
      to: prefixedHex(),
      from: prefixedHex()
        .required()
        .test((s) => isValidEvmAddress(s!)),
      data: prefixedHex(),
      gas: prefixedHex(),
      gasPrice: prefixedHex(),
      value: prefixedHex(),
      nonce: prefixedHex(),
    })
    .required(),
] as const

const PERSONAL_SIGN_PARAMS_SCHEMAS = [
  prefixedHex().required(), // message
  prefixedHex()
    .required()
    .test((s) => isValidEvmAddress(s!)), // address
  yup.string(), // optional password (may be "" as is the case on Rarible)
] as const

const SIGN_TYPED_DATA_PARAMS_SCHEMA = yup
  .array(
    yup
      .mixed<string | object>()
      .required()
      .test((x) => typeof x === 'string' || typeof x === 'object'),
  )
  .length(2)
  .required()

const WATCH_ASSET_PARAMS_SCHEMAS = [
  yup
    .object({
      type: yup
        .mixed<TokenStandard>()
        .oneOf(['ERC20', 'ERC721', 'ERC1155'])
        .required(),
      options: yup
        .object({
          address: yup
            .mixed<EvmContractAddress<EvmBlockchain>>()
            .required()
            .test((s) => typeof s === 'string' && isValidEvmAddress(s)),
          symbol: yup.string(),
          decimals: yup.number(),
          image: yup.string(),
          tokenId: yup.string(),
        })
        .required(),
    })
    .required(),
] as const

function parseTypedDataParams(params: (string | object)[]): {
  data: object
  address: EvmAddress
} {
  // This method has inconsistent parameters across dApps, so we try and find which is the address
  // and which is the data.
  const address = params
    .slice(0, 2)
    .find((param) => typeof param === 'string' && isValidEvmAddress(param))
  assert(address != null)

  const data = params[1 - params.indexOf(address)]
  assert(data != null)

  return {
    address,
    data: typeof data === 'string' ? JSON.parse(data) : data,
  }
}

function evmBlockchainToChainId(blockchain: EvmBlockchain): EvmChainId {
  switch (blockchain) {
    case 'ethereum':
      return ethereumChainIds[config.ethereumNetwork]
    case 'polygon':
      return polygonChainIds[config.polygonNetwork]
    case 'optimism':
      return optimismChainIds[config.optimismNetwork]
    case 'milkomedaC1':
      return milkomedaC1ChainIds[config.milkomedaC1Network]
    case 'arbitrumOne':
      return arbitrumOneChainIds[config.arbitrumOneNetwork]
    case 'base':
      return baseChainIds[config.baseNetwork]
    default:
      return safeAssertUnreachable(blockchain)
  }
}

function evmChainIdToBlockchain(chainId: number): EvmBlockchain | undefined {
  const blockchainToChainIds = {
    ethereum: ethereumChainIds,
    polygon: polygonChainIds,
    optimism: optimismChainIds,
    milkomedaC1: milkomedaC1ChainIds,
    arbitrumOne: arbitrumOneChainIds,
    base: baseChainIds,
  } satisfies Record<EvmBlockchain, Record<string, EvmChainId>>

  return objectEntries(blockchainToChainIds).find(([, chainIds]) =>
    Object.values<number>(chainIds).includes(chainId),
  )?.[0]
}

function getCurrentEvmChainIdFromStore() {
  const {blockchain} = useDappConnectorStore.getState()
  assert(
    isEvmBlockchain(blockchain),
    'EVM dApp connector found non-EVM chain in store',
  )
  return evmBlockchainToChainId(blockchain) satisfies number
}

const EMPTY_CONTEXT: RequestContext = {trusted: {origin: ''}}

const api: API<'eip155', MethodReturnTypes> = () => ({
  async eth_accounts() {
    return evmDappConnectorApi.eth_accounts(EMPTY_CONTEXT)
  },
  async eth_requestAccounts() {
    return evmDappConnectorApi.eth_requestAccounts(EMPTY_CONTEXT)
  },
  async eth_signTypedData(params: unknown[]) {
    assertShape(SIGN_TYPED_DATA_PARAMS_SCHEMA, params)
    /*
     * WalletConnect protocol expects v3/v4-like params for eth_signTypedData:
     * https://docs.walletconnect.com/advanced/multichain/rpc-reference/ethereum-rpc#eth_signtypeddata
     *
     * Metamask reroutes v1 to v3:
     * https://github.com/MetaMask/metamask-mobile/blob/4867f0b57525aa27f9d86412e536fe52a5d58539/app/core/WalletConnect/WalletConnectV2.ts#L440
     *
     * Uniswap treats v1 like v4:
     * https://github.com/Uniswap/interface/blob/08d6805b45b2d632a4ad1fed09113da044d342e0/apps/mobile/src/features/walletConnect/signWcRequestSaga.ts#L56
     *
     * Rainbow claims v4 is backwards compatible with v3 and decides between v1 and v4 based on shape of message:
     * https://github.com/rainbow-me/rainbow/blob/0e0405194d591d17e13902808e8e00be978191e3/src/model/wallet.ts#L458
     *
     * Data for v1 should be an array, so that's how we discriminate between versions.
     */
    const {address, data} = parseTypedDataParams(params)
    if (Array.isArray(data)) {
      return evmDappConnectorApi.eth_signTypedData_v1(EMPTY_CONTEXT, {
        params: [data, address],
      })
    } else {
      return evmDappConnectorApi.eth_signTypedData_v4(EMPTY_CONTEXT, {
        params: [address, JSON.stringify(data)],
      })
    }
  },
  async eth_signTypedData_v3(params: unknown[]) {
    assertShape(SIGN_TYPED_DATA_PARAMS_SCHEMA, params)

    const {address, data} = parseTypedDataParams(params)
    return evmDappConnectorApi.eth_signTypedData_v3(EMPTY_CONTEXT, {
      params: [address, JSON.stringify(data)],
    })
  },
  async eth_signTypedData_v4(params: unknown[]) {
    assertShape(SIGN_TYPED_DATA_PARAMS_SCHEMA, params)

    const {address, data} = parseTypedDataParams(params)
    return evmDappConnectorApi.eth_signTypedData_v4(EMPTY_CONTEXT, {
      params: [address, JSON.stringify(data)],
    })
  },
  async eth_sendTransaction(params: unknown[]) {
    assertShape(SEND_TX_PARAMS_SCHEMA[0], params[0])

    const {gas: gasLimit, ...txRequest} = params[0]
    return evmDappConnectorApi.eth_sendTransaction(EMPTY_CONTEXT, {
      params: [{...txRequest, gasLimit}],
    })
  },
  async personal_sign(params: unknown[]) {
    assertShape(PERSONAL_SIGN_PARAMS_SCHEMAS[0], params[0])
    assertShape(PERSONAL_SIGN_PARAMS_SCHEMAS[1], params[1])
    assertShape(PERSONAL_SIGN_PARAMS_SCHEMAS[2], params[2])

    return evmDappConnectorApi.personal_sign(EMPTY_CONTEXT, {
      params: [params[0], params[1], params[2] ?? ''],
    })
  },
  async wallet_addEthereumChain() {
    // We ignore this call in case the dApp wants to add a chain that we already support.
    // If it's a chain we don't support, we fail when switching chain anyway.
    return null
  },
  async wallet_switchEthereumChain(params: unknown[]) {
    assertShape(SWITCH_ETHEREUM_CHAIN_PARAMS_SCHEMAS[0], params[0])

    const currentChainId = toHexString(getCurrentEvmChainIdFromStore())

    const proposedChainId = isHexPrefixed(params[0].chainId)
      ? params[0].chainId // Llamaswap provides chainId like '0x89' instead of 'eip155:137'
      : toHexString(
          parseInt(wcUtils.parseChainId(params[0].chainId).reference, 10),
        )

    if (evmChainIdToBlockchain(parseInt(proposedChainId, 16)) == null) {
      throw Error('Unrecognized chain ID')
    }

    if (currentChainId !== proposedChainId) {
      await evmDappConnectorApi.wallet_switchEthereumChain(EMPTY_CONTEXT, {
        currentChainId,
        proposedChainId,
      })
    }
    return null
  },
  async wallet_getPermissions() {
    return evmDappConnectorApi.wallet_getPermissions(EMPTY_CONTEXT)
  },
  async wallet_requestPermissions() {
    return evmDappConnectorApi.wallet_requestPermissions(EMPTY_CONTEXT)
  },
  async wallet_watchAsset(params: unknown[]) {
    assertShape(WATCH_ASSET_PARAMS_SCHEMAS[0], params[0])

    return evmDappConnectorApi.wallet_watchAsset(EMPTY_CONTEXT, {
      params: params[0],
    })
  },
})

export const eip155Handler: Handler<'eip155'> =
  (chain) => async (method, params) => {
    const chainApi = api(chain)
    await chainApi.wallet_switchEthereumChain([{chainId: chain}])
    return await chainApi[method](params)
  }
