import {VpnKey as KeyIcon} from '@mui/icons-material'
import {Box, Paper, Typography} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'

import {Button} from '../../components'
import {routeTo} from '../../router'
import {getMnemonicLength} from '../../wallet'

import RecoveryPhraseModal from './RecoveryPhraseModal'
import {useCommonStyles} from './utils'

export default function RecoveryPhrase({
  skipPasswordVerification = false,
}: {
  skipPasswordVerification?: boolean
}) {
  const {t} = useTranslation()
  const classes = useStyles()
  const commonClasses = useCommonStyles()

  const [showRecoveryPhraseModal, setShowRecoveryPhraseModal] = useState(false)

  return (
    <Box className={commonClasses.sectionWrapper}>
      <Paper elevation={0} className={classes.recoveryInfo}>
        <Box className={classes.iconWrapper}>
          <KeyIcon fontSize="inherit" color="inherit" />
        </Box>
        <Typography>
          {t('secret_recovery_phrase', {length: getMnemonicLength()})}
        </Typography>
      </Paper>
      <Button
        className={classes.recovery}
        fullWidth
        textTransform="none"
        variant="contained"
        color="secondary"
        onClick={() => {
          setShowRecoveryPhraseModal(true)
        }}
      >
        {t('View secret phrase')}
      </Button>

      {showRecoveryPhraseModal && (
        <RecoveryPhraseModal
          onClose={() => {
            setShowRecoveryPhraseModal(false)
          }}
          skipPasswordVerification={skipPasswordVerification}
        />
      )}
    </Box>
  )
}

export function ActivateRecoveryPhrase() {
  const history = useHistory()
  const {t} = useTranslation()
  const classes = useStyles()
  const commonClasses = useCommonStyles()

  const onActivate = () => {
    history.push(routeTo.settings.activateMnemonic)
  }

  return (
    <Box className={commonClasses.sectionWrapper}>
      <Button
        fullWidth
        textTransform="none"
        variant="contained"
        color="secondary"
        onClick={onActivate}
        className={classes.recovery}
      >
        {t('Activate')}
      </Button>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  recovery: {
    marginTop: theme.spacing(2),
  },
  recoveryInfo: {
    background: theme.palette.action.hover,
    height: 150,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconWrapper: {
    color: theme.palette.action.active,
    fontSize: 48,
  },
}))
