import {VisibilityOff as HiddenIcon} from '@mui/icons-material'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import {Box, alpha} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {LabeledIcon, ElevatedCard} from '../../../components'
import type {NftPage} from '../../../types'

export function HiddenNftsCard({
  onClick,
  hiddenNftsPage,
}: {
  onClick: () => void
  hiddenNftsPage: NftPage
}) {
  const classes = useStyles()
  const {t} = useTranslation()

  return (
    <ElevatedCard
      type="normal"
      className={clsx(classes.hiddenNftsCard)}
      onClick={onClick}
    >
      <Box className={clsx(classes.nftThumbnail, classes.hiddenContentWrapper)}>
        <HiddenIcon color="warning" sx={{fontSize: HIDDEN_ICON_SIZE}} />
        <LabeledIcon
          className={classes.hiddenContent}
          Icon={<ArrowRightAltIcon />}
          Label={
            <>
              {t('HIDDEN NFTs')}
              &nbsp;
              {'('}
              {hiddenNftsPage.items.length || 0}
              {hiddenNftsPage.hasNextPage ? '+' : ''}
              {')'}
            </>
          }
        />
      </Box>
    </ElevatedCard>
  )
}

const HIDDEN_ICON_SIZE = 100
const BORDER_RADIUS = 5
const THUMBNAIL_SIZE = 200
const useStyles = makeStyles((theme) => {
  const backgroundGradient = {
    background: `linear-gradient(90deg, ${alpha(
      theme.palette.background.default,
      0.6,
    )} 0%, rgba(255, 255, 255, 0) 100%)`,
  }
  return {
    nftThumbnail: {
      height: THUMBNAIL_SIZE,
      width: THUMBNAIL_SIZE,
      borderTopLeftRadius: BORDER_RADIUS,
      borderTopRightRadius: BORDER_RADIUS,
    },
    hiddenContentWrapper: {
      borderRadius: BORDER_RADIUS,
      ...backgroundGradient,
      height: '100%',
      display: 'block',
      justifyContent: 'center',
      alignContent: 'center',
      textAlign: 'center',
    },
    hiddenContent: {
      justifyContent: 'center',
    },
    hiddenNftsCard: {
      color: theme.palette.text.secondary,
      height: 268,
      fontWeight: 500,
      '&:hover': {
        color: theme.palette.primary.main,
        background: theme.palette.background.paper,
      },
      background: theme.palette.background.paper,
    },
  }
})
