import type {SvgIconComponent} from '@mui/icons-material'
import {
  RawOn as RawIcon,
  Preview as PreviewIcon,
  Message as MessageIcon,
} from '@mui/icons-material'
import {Typography, IconButton, styled} from '@mui/material'
import {safeAssertUnreachable} from '@nufi/frontend-common'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {RawTx} from './details/RawTx'

type Tabs = 'raw' | 'details' | 'description'

export type TxDataLayoutProps = {
  details: React.ReactNode
  rawTx?: string
  txDescription?: React.ReactNode
}

export function TxDataLayout({
  details,
  rawTx,
  txDescription,
}: TxDataLayoutProps) {
  const [activeTab, setActiveTab] = useState<Tabs>(
    txDescription ? 'description' : 'details',
  )
  const {t} = useTranslation()

  const tabButtons: {tab: Tabs; Icon: SvgIconComponent; disabled?: boolean}[] =
    [
      ...(txDescription
        ? [{tab: 'description' as const, Icon: MessageIcon}]
        : []),
      {tab: 'details' as const, Icon: PreviewIcon},
      {tab: 'raw' as const, Icon: RawIcon, disabled: !rawTx},
    ]

  return (
    <>
      <Container>
        <Typography variant="body2" fontWeight="bold">
          {t('Transaction info')}
        </Typography>
        <div>
          {tabButtons.map(({tab, Icon, disabled}) => (
            <IconButton
              size="small"
              onClick={() => {
                setActiveTab(tab)
              }}
              disabled={disabled}
              color={activeTab === tab ? 'primary' : 'default'}
              rtl-data-test-id={`tx-layout-tab-${tab}`}
            >
              <Icon fontSize="medium" />
            </IconButton>
          ))}
        </div>
      </Container>
      <div>
        {(() => {
          switch (activeTab) {
            case 'description':
              return txDescription
            case 'details':
              return details
            case 'raw':
              return rawTx ? <RawTx rawTx={rawTx} /> : null
            default:
              return safeAssertUnreachable(activeTab)
          }
        })()}
      </div>
    </>
  )
}

const Container = styled('div')(({theme: {spacing, palette}}) => ({
  borderBottom: `1px solid ${palette.divider}`,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  width: '100%',
  paddingBottom: spacing(0.25),
  margin: spacing(1),
}))
