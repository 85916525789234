import {ContentStore, StoreManager, EncryptionType} from '@nufi/storage'
import type {StorageDriver, StoreContentHeader} from '@nufi/storage'

import {StoreType} from 'src/appStorage/constants'

import type {Blockchain, Mnemonic} from '../../../types'
import {getAppVersion} from '../../../utils/appVersion'
import {safeAssertUnreachable} from '../../../utils/assertion'
import {AppStorageError} from '../../errors'
import type {
  LocalProfileId,
  ProfilePassword,
  ProfileBackup,
  ProfileMigrations,
} from '../../sharedTypes'
import {DEFAULT_PROFILE_DATA} from '../constants'
import {isMnemonicData, isProfileData} from '../guards'
import {ProfileManager, profileManagerLocator} from '../profileManager'
import type {
  MnemonicData,
  MnemonicStoragePath,
  ProfileData,
  ProfileDataEncryptionKey,
  ProfileStoragePath,
} from '../types'

import {deriveProfileDataEncryptionKey} from './utils'

export {validateLocalProfileBackup} from './utils'

const profileIdToMnemonicStoragePath = (
  profileId: LocalProfileId,
): MnemonicStoragePath => `mnemonic-${profileId}` as MnemonicStoragePath

const profileIdToProfileStoragePath = (
  profileId: LocalProfileId,
): ProfileStoragePath => `profile-${profileId}` as ProfileStoragePath

type InitParams =
  | {action: 'login'}
  | {
      action: 'create-profile'
      mnemonic: Mnemonic
      isHwUser: boolean
      isMnemonicActivated: boolean
      enabledBlockchains: readonly Blockchain[]
    }
  | {
      action: 'restore-backup'
      mnemonic: Mnemonic
      mnemonicHeader: StoreContentHeader
      profileParams: ProfileData
      profileHeader: StoreContentHeader
    }

export class LocalProfileManager extends ProfileManager {
  private mnemonicStoreManager: StoreManager<MnemonicData, ProfilePassword>
  private password: ProfilePassword

  constructor(
    mnemonicStoreManager: StoreManager<MnemonicData, ProfilePassword>,
    profileStoreManager: StoreManager<ProfileData, ProfileDataEncryptionKey>,
    password: ProfilePassword,
  ) {
    super('local', profileStoreManager)
    this.password = password
    this.mnemonicStoreManager = mnemonicStoreManager
  }

  override init = async (params: InitParams): Promise<void> => {
    const mnemonicStoreParams = (() => {
      const action = params.action
      switch (action) {
        case 'restore-backup':
          return {
            password: this.password,
            backupData: {
              data: {mnemonic: params.mnemonic},
              header: params.mnemonicHeader,
            },
          }
        case 'create-profile':
          return {
            password: this.password,
            initialData: {mnemonic: params.mnemonic},
          }
        case 'login':
          return {password: this.password, initialData: null}
        default:
          return safeAssertUnreachable(action)
      }
    })()

    await this.mnemonicStoreManager.open(mnemonicStoreParams)
    const storedMnemonic = (await this.mnemonicStoreManager.getContent())
      .mnemonic
    const encryptionKey = await deriveProfileDataEncryptionKey(storedMnemonic)

    this.setProfileDataEncryptionKey(encryptionKey)

    const profileStoreParams = (() => {
      const action = params.action
      switch (action) {
        case 'restore-backup':
          return {
            password: encryptionKey,
            backupData: {
              data: params.profileParams,
              header: params.profileHeader,
            },
          }
        case 'create-profile':
          return {
            password: encryptionKey,
            initialData: {
              ...DEFAULT_PROFILE_DATA,
              settings: {
                ...DEFAULT_PROFILE_DATA.settings,
                isHwUser: params.isHwUser,
                isMnemonicActivated: params.isMnemonicActivated,
                enabledBlockchains: params.enabledBlockchains,
              },
            },
          }
        case 'login':
          return {password: encryptionKey, initialData: null}
        default:
          return safeAssertUnreachable(action)
      }
    })()

    await this.profileStoreManager.open(profileStoreParams)
  }

  override removeProfile = async (): Promise<void> => {
    await this.profileStoreManager.removeContent()
    await this.mnemonicStoreManager.removeContent()
  }

  changePassword = async (
    currentPassword: ProfilePassword,
    newPassword: ProfilePassword,
  ): Promise<void> => {
    if (currentPassword !== this.password)
      throw new Error(AppStorageError.WRONG_PASSWORD)

    if (this.mnemonicStoreManager != null) {
      await this.mnemonicStoreManager.changePassword(newPassword)
    }
    this.password = newPassword
  }

  /**
   * Returns current password. As we want to avoid using this call
   * from arbitrary places, we require to pass the "reason" so that
   * we are aware why is the password being exposed.
   *
   * @param reason indicates reason why exposing the password
   */
  exposePassword = (reason: 'autoLogin'): ProfilePassword => {
    switch (reason) {
      case 'autoLogin':
        return this.password
      default:
        return safeAssertUnreachable(reason)
    }
  }

  verifyPassword = async (password: ProfilePassword): Promise<boolean> =>
    this.password === password &&
    (await this.mnemonicStoreManager.verifyPassword(password))

  getMnemonic = async (): Promise<Mnemonic> =>
    (await this.mnemonicStoreManager.getContent()).mnemonic

  export = async (): Promise<ProfileBackup> => {
    const backupData = {
      mnemonic: await this.mnemonicStoreManager.serialize(),
      profile: await this.profileStoreManager.serialize(),
    }
    return JSON.stringify(backupData) as ProfileBackup
  }
}

export const isLocalProfileManager = (
  profileManager: ProfileManager,
): profileManager is LocalProfileManager => {
  return profileManager.mnemonicStorageType === 'local'
}

export const localProfileManagerLocator = {
  instance: (): LocalProfileManager => {
    const instance = profileManagerLocator.instance() as LocalProfileManager
    if (isLocalProfileManager(instance)) return instance
    throw new Error('ProfileManager is not local profile manager')
  },
  create: (
    storageDriver: StorageDriver,
    profileId: LocalProfileId,
    password: ProfilePassword,
    migrations: ProfileMigrations = {mnemonic: {}, profileData: {}},
  ): LocalProfileManager => {
    const mnemonicStoreManager = new StoreManager({
      typeGuard: isMnemonicData,
      store: new ContentStore({
        storageKey: profileIdToMnemonicStoragePath(profileId),
        storageDriver,
      }),
      migrations: migrations.mnemonic,
      appVersion: getAppVersion(),
      storeType: StoreType.UserProfileMnemonic,
      encryptionType: EncryptionType.Aes256,
    })
    const profileStoreManager = new StoreManager({
      typeGuard: isProfileData,
      store: new ContentStore({
        storageKey: profileIdToProfileStoragePath(profileId),
        storageDriver,
      }),
      migrations: migrations.profileData,
      appVersion: getAppVersion(),
      storeType: StoreType.UserProfileData,
      encryptionType: EncryptionType.Aes256,
    })

    profileManagerLocator.init(
      new LocalProfileManager(
        mnemonicStoreManager,
        profileStoreManager,
        password,
      ),
    )
    return profileManagerLocator.instance() as LocalProfileManager
  },
  destroy: () => {
    profileManagerLocator.destroy()
  },
}
