import {Box, Grid, Typography, TextField} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import BigNumber from 'bignumber.js'
import type {SwapAsset} from 'common'
import {isChangellyError} from 'common'
import type {FormikProps} from 'formik'
import {Field, Formik, useFormikContext} from 'formik'
import type {FC} from 'react'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import * as yup from 'yup'

import type {
  ExchangeAssetsDetails,
  ExchangeParams,
} from 'src/features/exchange/domain'
import {WithWeakTokenMetadata} from 'src/wallet/public/ui'

import {isExchangeBlockchain} from '../../../../blockchainTypes'
import type {ExchangeBlockchain} from '../../../../blockchainTypes'
import {
  ModalFooter,
  ModalLayout,
  ExternalOrInternalAddressField,
  FooterLayout,
  QueryGuard,
  useModalSharedStyles,
  InlineError,
  TextButton,
  InlineLoading,
  Alert,
  FormattedNativeAmount,
  BatchQueryGuard,
} from '../../../../components'
import {blockchainToCoin} from '../../../../constants'
import {useTransactionState} from '../../../../store/transactions'
import type {AccountInfo, AddressType, AppError} from '../../../../types'
import {assert} from '../../../../utils/assertion'
import {
  getHasFormError,
  isValidAmountFieldValue,
  sanitizeAmountFieldValue,
} from '../../../../utils/form'
import {commonFormatTokenAmount} from '../../../../wallet'
import {findAccountByAddress} from '../../../../wallet/utils/common'
import {assetBaseUnitToMain} from '../../../../wallet/utils/parseUtils'
import type {ExchangeAssetQueries} from '../../components'
import {
  ExchangeAssetDataError,
  ExchangeModalHeader,
  ExtraIdHelp,
  ExtraIdMessage,
  FormattedExchangeRate,
  WithExchangeAssetsQueries,
} from '../../components'
import {ExchangeAssetSelectField} from '../../components/ExchangeAssetSelectField'
import {useChangellyErrorsTranslations} from '../../constants'
import {useExchangeConf} from '../../ExchangeConfContext'
import type {ExchangeModalViewModel} from '../../exchangeModalViewModel'
import type {SwapTokenData} from '../../types'
import {
  getChangellyErrorTranslationKey,
  getDisabledAssets,
  verifyMinMaxAmount,
} from '../../utils'
import type {WithAmountFieldPropertiesProps} from '../common'
import {
  ExchangeAssetNotification,
  isAssetInternallySwappable,
  LoadingOrError,
} from '../common'

import {AssetsDivider} from './AssetsDivider'
import type {DetailsValues} from './schema'
import {useDetailsSchema} from './schema'
import type {DetailsScreenViewModel} from './viewModel'

const FORM_ID = 'exchange-form-details'

export type InitialInitializationData = {
  fromAsset: SwapAsset
  toAsset: SwapAsset
  toAddress: string
}

type InitializationData =
  | {
      previousData: DetailsValues
    }
  | {
      initialData: InitialInitializationData
    }

export type DetailsScreenProps = {
  onClose: () => void
  onSubmit: (data: DetailsValues) => void
  initializationData: InitializationData
  exchangeAssetsDetails: ExchangeAssetsDetails
  vm: DetailsScreenViewModel
}

export function DetailsScreen({
  onClose,
  onSubmit,
  initializationData,
  exchangeAssetsDetails,
  vm,
}: DetailsScreenProps) {
  const {t} = useTranslation()

  const {initialFromAsset, initialToAsset} = (() => {
    const data =
      'previousData' in initializationData
        ? initializationData.previousData
        : initializationData.initialData
    return {
      initialFromAsset: data.fromAsset,
      initialToAsset: data.toAsset,
    }
  })()

  const getExchangeParamsQuery = vm.useGetExchangeParams(
    initialFromAsset,
    initialToAsset,
  )

  // EXCHANGE_REFACTOR
  // Note that these accounts are not loaded at once for all blockchains.
  // Consider using stored accounts data instead.
  const {data: allAccounts} = vm.useAllAccounts()

  const _baseDetailsSchema = useDetailsSchema(vm)
  const baseDetailsSchema = {
    ..._baseDetailsSchema,
    amount: _baseDetailsSchema.amount.concat(
      yup.number().test('amount-min-max-bounds', async function (amount) {
        if (amount === undefined) return true
        const fields = this.parent as DetailsValues
        try {
          const {minAmount, maxAmount} = await vm.getExchangeParamsImperative(
            fields.fromAsset,
            fields.toAsset,
          )
          return verifyMinMaxAmount(amount, minAmount, maxAmount)
            ? true
            : this.createError({
                message: t('amount-min-max-bounds', {
                  minAmount,
                  maxAmount,
                  asset: fields.fromAsset,
                }),
              })
        } catch (e) {
          return this.createError({
            message: t('Could not verify amount. Please contact support.'),
          })
        }
      }),
    ),
  }

  if (getExchangeParamsQuery.isLoading && !getExchangeParamsQuery.data) {
    return <LoadingOrError isError={false} onClose={() => null} />
  }

  const getAddressType = makeGetAddressType(allAccounts, exchangeAssetsDetails)

  const initialValues: DetailsValues =
    'previousData' in initializationData
      ? // Note that the `initialFromAsset` and `initialToAsset` is consistent
        // with `fromAsset` and `toAsset` properties contained in `restProps.initializationData`.
        initializationData.previousData
      : {
          amount: getExchangeParamsQuery.data?.minAmount.toString() || '',
          toAddress: initializationData.initialData.toAddress,
          fromAddress: '',
          fromAddressType: getAddressType(
            initializationData.initialData.fromAsset,
            'from',
          ),
          toAddressType: getAddressType(
            initializationData.initialData.toAsset,
            'to',
          ),
          fromAsset: initializationData.initialData.fromAsset,
          toAsset: initializationData.initialData.toAsset,
          extraId: null,
        }

  const schema = yup.object().shape(baseDetailsSchema)

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={onSubmit}
    >
      <ExchangeQueriesLoader
        {...({
          onClose,
          exchangeAssetsDetails,
          vm,
          getAddressType,
        } satisfies ExchangeQueriesLoaderProps)}
      />
    </Formik>
  )
}

type ExchangeQueriesLoaderProps = {
  onClose: () => void
  exchangeAssetsDetails: ExchangeAssetsDetails
  vm: DetailsScreenViewModel
  getAddressType: (value: SwapAsset, type: 'from' | 'to') => AddressType
}

function ExchangeQueriesLoader({
  vm,
  exchangeAssetsDetails,
  getAddressType,
  onClose,
}: ExchangeQueriesLoaderProps) {
  const formikProps = useFormikContext<DetailsValues>()
  const {values} = formikProps

  return (
    <WithExchangeAssetsQueries
      fromAsset={values.fromAsset}
      toAsset={values.toAsset}
      exchangeAssetsDetails={exchangeAssetsDetails}
      vm={vm}
      childrenPropsType="queries"
    >
      {(assetsData) => (
        <DetailsScreenForm
          {...({
            onClose,
            exchangeAssetsDetails,
            assetsData,
            getAddressType,
            vm,
          } satisfies DetailsScreenFormProps)}
        />
      )}
    </WithExchangeAssetsQueries>
  )
}

type DetailsScreenFormProps = ExchangeQueriesLoaderProps & {
  assetsData: {
    to: ExchangeAssetQueries
    from: ExchangeAssetQueries
  }
  getAddressType: (value: SwapAsset, type: 'from' | 'to') => AddressType
}

function DetailsScreenForm({
  vm,
  onClose,
  exchangeAssetsDetails,
  assetsData,
  getAddressType,
}: DetailsScreenFormProps) {
  const classes = {...useModalSharedStyles(), ...useStyles()}
  const {t} = useTranslation()
  const formikProps = useFormikContext<DetailsValues>()
  const {values, setValues, resetForm} = formikProps

  const {
    from: {
      accountsQuery: fromAccountsQuery,
      internalBlockchain: internalFromBlockchain,
      tokenQuery: fromTokenQuery,
    },
    to: {
      accountsQuery: toAccountsQuery,
      internalBlockchain: internalToBlockchain,
      tokenQuery: toTokenQuery,
    },
  } = assetsData

  const exchangeParamsQuery = vm.useGetExchangeParams(
    values.fromAsset,
    values.toAsset,
  )

  useEffect(() => {
    // after changing an asset (from/to) set amount to at least minAmount
    const executeAsync = async () => {
      const result = await exchangeParamsQuery.refetch()
      if (result.data?.minAmount.gt(values.amount)) {
        setValues({
          ...values,
          amount: result.data.minAmount.toString(),
        })
      }
    }
    executeAsync()
  }, [values.fromAsset, values.toAsset])

  const fromAccount =
    internalFromBlockchain && values.fromAddressType === 'internal'
      ? findAccountByAddress(
          fromAccountsQuery.data || [],
          values.fromAddress,
          internalFromBlockchain,
        ) ?? null
      : null

  const {isTransactionPending} = useTransactionState()

  const isConflictingTxPending =
    fromAccount != null ? isTransactionPending(fromAccount.id) : false
  const conflictingTxTooltipText = isConflictingTxPending
    ? t('tx_pending_error')
    : ''

  // We are not checking for "data" as e.g. tokenQueries are allowed to
  // return `null`.
  const someAssetQueryIsLoading =
    toAccountsQuery.isLoading ||
    toTokenQuery.isLoading ||
    fromAccountsQuery.isLoading ||
    fromTokenQuery.isLoading

  const someAssetQueryHasError = !!(
    toAccountsQuery.error ||
    toTokenQuery.error ||
    fromAccountsQuery.error ||
    fromTokenQuery.error
  )

  const submitDisabled =
    isConflictingTxPending || someAssetQueryIsLoading || someAssetQueryHasError

  return (
    <ModalLayout
      header={<ExchangeModalHeader onClose={onClose} />}
      body={
        <form onSubmit={formikProps.handleSubmit} noValidate id={FORM_ID}>
          <Box p={2}>
            <Grid container direction="row">
              <Grid item container direction="column" xs>
                <Typography variant="h6" className={classes.commonBottomMargin}>
                  {t('From')}
                </Typography>
                <ExchangeAssetSelectField
                  testId="from-asset-select"
                  {...{exchangeAssetsDetails}}
                  selectClasses={{root: classes.formField}}
                  value={values.fromAsset}
                  disabledAssets={[
                    values.toAsset,
                    ...getDisabledAssets(exchangeAssetsDetails, 'from'),
                  ]}
                  onChange={(value) => {
                    if (!value) return

                    const fromAddressType = getAddressType(value, 'from')
                    resetForm({
                      values: {
                        ...values,
                        fromAsset: value,
                        fromAddress: '',
                        fromAddressType,
                      },
                    })
                  }}
                  label={t('Asset')}
                  className={classes.commonTopMargin}
                />
                {/* Note that if we support the asset internally, and some error
                occurs while loading the data, this would disable also external swap. */}
                <BatchQueryGuard
                  ErrorElement={<ExchangeAssetDataError />}
                  queries={{
                    accounts: {
                      ...fromAccountsQuery,
                      data: fromAccountsQuery.data ?? undefined,
                    },
                    fromToken: fromTokenQuery,
                  }}
                >
                  {({accounts: fromAccounts, fromToken}) => (
                    <FromAssetFields
                      {...({
                        fromToken,
                        formikProps,
                        exchangeParamsQuery,
                        vm,
                        fromAccount,
                        fromAccounts,
                        internalFromBlockchain,
                        exchangeAssetsDetails,
                      } satisfies Partial<FromAssetFieldsProps>)}
                    />
                  )}
                </BatchQueryGuard>
              </Grid>
              <AssetsDivider
                exchangeAssetsDetails={exchangeAssetsDetails}
                getAddressType={getAddressType}
              />
              <Grid item container direction="column" xs>
                <Typography variant="h6" className={classes.commonBottomMargin}>
                  {t('To')}
                </Typography>
                <ExchangeAssetSelectField
                  {...{exchangeAssetsDetails}}
                  testId="exchange-to-asset"
                  selectClasses={{root: classes.formField}}
                  value={values.toAsset}
                  disabledAssets={[
                    values.fromAsset,
                    ...getDisabledAssets(exchangeAssetsDetails, 'to'),
                  ]}
                  onChange={(value) => {
                    if (!value) return

                    const toAddressType = getAddressType(value, 'to')
                    resetForm({
                      values: {
                        ...values,
                        extraId: null, // Ensure this is not preserved
                        toAsset: value,
                        toAddress: '',
                        toAddressType,
                      },
                    })
                  }}
                  label={t('Asset')}
                  className={classes.commonTopMargin}
                />
                {/* Note that if we support the asset internally, and some error
                occurs while loading the data, this would disable also external swap. */}
                <BatchQueryGuard
                  ErrorElement={<ExchangeAssetDataError />}
                  queries={{
                    accounts: {
                      ...toAccountsQuery,
                      data: toAccountsQuery.data ?? undefined,
                    },
                    toToken: toTokenQuery,
                  }}
                >
                  {({accounts: toAccounts, toToken}) => (
                    <ToAssetFields
                      exchangeParams={exchangeParamsQuery.data}
                      {...({
                        toAccounts,
                        exchangeAssetsDetails,
                        formikProps,
                        internalToBlockchain,
                        toToken,
                        vm,
                      } satisfies Partial<ToAssetFieldsProps>)}
                    />
                  )}
                </BatchQueryGuard>
              </Grid>
            </Grid>
          </Box>
        </form>
      }
      footer={
        <ModalFooter hasDivider>
          <FooterLayout
            leftBtnConf={{
              onClick: onClose,
              children: t('Back'),
            }}
            rightBtnConf={{
              variant: 'contained',
              form: FORM_ID,
              type: 'submit',
              children: t('Continue'),
              disabled: submitDisabled,
            }}
            rightBtnTooltipTitle={conflictingTxTooltipText}
          />
        </ModalFooter>
      }
    />
  )
}

const ExternalWithAmountFieldProperties: FC<WithAmountFieldPropertiesProps> = ({
  children,
  exchangeParamsQuery,
}) => {
  const maxAmount = exchangeParamsQuery?.data?.maxAmount

  return children({
    validate: async () => undefined,
    data: {
      fee: {
        data: undefined,
        isLoading: false,
      },
      maxAmount: {
        data: maxAmount ? {value: maxAmount, type: 'external'} : undefined,
        isLoading: exchangeParamsQuery.isLoading,
      },
    },
  })
}

type FromAssetFieldsProps = {
  exchangeAssetsDetails: ExchangeAssetsDetails
  fromAccounts: AccountInfo[]
  fromAccount: AccountInfo | null
  internalFromBlockchain: ExchangeBlockchain | null
  fromToken: SwapTokenData | null
  formikProps: FormikProps<DetailsValues>
  exchangeParamsQuery: ReturnType<
    ExchangeModalViewModel['useGetExchangeParams']
  >
  vm: DetailsScreenViewModel
}

const FromAssetFields = ({
  exchangeAssetsDetails,
  fromAccounts: _fromAccounts,
  fromToken,
  fromAccount,
  formikProps,
  exchangeParamsQuery,
  internalFromBlockchain,
  vm,
}: FromAssetFieldsProps) => {
  const {t} = useTranslation()
  const classes = {...useModalSharedStyles(), ...useStyles()}
  const {values, setValues, errors, handleChange, handleBlur} = formikProps
  type FormField = keyof typeof values
  const {exchangeConf} = useExchangeConf()

  const isInternallySwappable = isAssetInternallySwappable(
    exchangeAssetsDetails,
    values.fromAsset,
  )

  const fromAssetNotification =
    exchangeAssetsDetails[values.fromAsset]?.notifications?.payin

  const fromAccounts = isInternallySwappable ? _fromAccounts : []

  const WithAmountFieldProperties =
    internalFromBlockchain != null
      ? exchangeConf[internalFromBlockchain].WithAmountFieldProperties
      : ExternalWithAmountFieldProperties

  const fromAddressErrorMessage = getHasFormError(
    formikProps,
    'after-submit',
  )('fromAddress')
    ? (errors.fromAddress as string)
    : undefined

  return (
    <>
      <WithWeakTokenMetadata
        blockchain={internalFromBlockchain}
        tokenId={fromToken?.id}
        vm={vm}
      >
        {(tokenMetadata) => (
          <ExternalOrInternalAddressField
            testIds={{
              setAddressFieldExternal: 'from-set-address-field-external',
              addressFieldExternal: 'from-address-field-external',
              addressFieldInternal: 'from-address-field-internal',
              setAddressFieldInternal: 'from-set-address-field-internal',
            }}
            hideCopyAddress
            formFieldClass={classes.formField}
            blockchain={internalFromBlockchain}
            tokenMetadata={tokenMetadata}
            items={fromAccounts.map((a) => ({
              accountId: a.id,
              address: a.address,
              name: a.name,
              cryptoProviderType: a.cryptoProviderType,
              ...(fromToken
                ? {
                    customBalanceRows: {
                      primary: {
                        tokenMetadata,
                        balance:
                          fromToken.balances[a.address] ?? new BigNumber(0),
                      },
                      secondary: {
                        balance: a.balance,
                      },
                    },
                  }
                : {
                    balance: a.balance,
                  }),
            }))}
            value={{
              address: values.fromAddress,
              addressType: values.fromAddressType,
            }}
            onChange={(value) => {
              setValues({
                ...values,
                fromAddress: value.address,
                fromAddressType: value.addressType,
              })
            }}
            errorMessage={fromAddressErrorMessage}
            label={
              values.fromAddressType === 'external'
                ? t('Refund address')
                : t('Account')
            }
            chipLabel={t('Fund from')}
            renderExternalAddressField={() => (
              <Alert
                overrides={{
                  root: classes.alertRoot,
                  message: classes.alertMessage,
                }}
                severity="info"
                text={t('An exchange address will be provided later.')}
              />
            )}
          />
        )}
      </WithWeakTokenMetadata>
      {fromAssetNotification != null && (
        <ExchangeAssetNotification
          testId="from-asset-notification"
          children={fromAssetNotification}
        />
      )}
      <WithAmountFieldProperties
        fromAccountInfo={fromAccount}
        values={values}
        exchangeParamsQuery={exchangeParamsQuery}
        fromToken={fromToken}
      >
        {({data, validate}) => (
          <>
            <Box className={classes.formField}>
              <Field name="amount" validate={validate}>
                {() => (
                  <TextField
                    fullWidth
                    variant="outlined"
                    rtl-data-test-id="exchange-from-field-amount"
                    label={`${t('Amount to exchange')} (${values.fromAsset})`}
                    onBlur={(e) => {
                      // without handleBlur the "touched" fields are not populated
                      const onBlur = handleBlur<FormField>('amount') as (
                        e: unknown,
                      ) => void
                      onBlur(e)
                    }}
                    onChange={(e) => {
                      const sanitized = sanitizeAmountFieldValue(e.target.value)
                      const isValid =
                        isValidAmountFieldValue(sanitized) || sanitized === ''
                      if (!isValid) return
                      handleChange<FormField>('amount')(sanitized)
                    }}
                    autoComplete="off"
                    error={getHasFormError(
                      formikProps,
                      'after-touched',
                    )('amount')}
                    value={values.amount}
                    helperText={
                      getHasFormError(formikProps, 'after-touched')('amount') &&
                      errors.amount
                    }
                    InputProps={{
                      endAdornment: data.maxAmount.isLoading ? (
                        <InlineLoading />
                      ) : (
                        <TextButton
                          className={classes.maxButton}
                          color="textPrimary"
                          label={t('MAX')}
                          disabled={
                            values.fromAddressType === 'external' ||
                            !data.maxAmount.data
                          }
                          testId="amount-field-max"
                          onClick={() => {
                            const maxAmountValue = (() => {
                              if (data.maxAmount.data == null) return null

                              if (values.fromAddressType === 'internal') {
                                if (internalFromBlockchain == null) return null // should not really happen, but due to TS

                                if (data.maxAmount.data.type === 'token') {
                                  assert(data.maxAmount.data.type === 'token')
                                  return commonFormatTokenAmount(
                                    data.maxAmount.data.value,
                                    data.maxAmount.data.decimals,
                                  )
                                } else {
                                  assert(data.maxAmount.data.type === 'native')
                                  return assetBaseUnitToMain(
                                    internalFromBlockchain,
                                    data.maxAmount.data.value,
                                  )
                                }
                              } else {
                                assert(data.maxAmount.data.type === 'external')
                                return data.maxAmount.data.toString()
                              }
                            })()

                            if (maxAmountValue != null) {
                              setValues({
                                ...values,
                                amount: maxAmountValue,
                              })
                            }
                          }}
                        />
                      ),
                    }}
                  />
                )}
              </Field>
            </Box>
            <Box className={classes.formField}>
              {data.fee.data && internalFromBlockchain && (
                <Grid container spacing={0.5}>
                  <Grid item>
                    <Typography color="textSecondary">
                      {t('Network fee')}:
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography>
                      ~
                      <FormattedNativeAmount
                        blockchain={internalFromBlockchain}
                        value={data.fee.data}
                        isSensitiveInformation={false}
                      />{' '}
                      {blockchainToCoin(internalFromBlockchain)}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Box>
          </>
        )}
      </WithAmountFieldProperties>
    </>
  )
}

type ToAssetFieldsProps = {
  exchangeAssetsDetails: ExchangeAssetsDetails
  exchangeParams: ExchangeParams | undefined
  toAccounts: AccountInfo[]
  internalToBlockchain: ExchangeBlockchain | null
  toToken: SwapTokenData | null
  formikProps: FormikProps<DetailsValues>
  vm: DetailsScreenViewModel
}

const ToAssetFields = ({
  vm,
  internalToBlockchain,
  toToken,
  toAccounts,
  formikProps,
  exchangeAssetsDetails,
  exchangeParams,
}: ToAssetFieldsProps) => {
  const {t} = useTranslation()
  const classes = {...useModalSharedStyles(), ...useStyles()}
  const {values, setValues, handleChange, errors} = formikProps
  type FormField = keyof typeof values

  const assetDetails = exchangeAssetsDetails[values.toAsset]
  const extraIdName = assetDetails?.extraIdName

  const toAssetNotification =
    exchangeAssetsDetails[values.toAsset]?.notifications?.payout ||
    (extraIdName != null ? (
      <ExtraIdMessage extraIdName={extraIdName} asset={values.toAsset} />
    ) : null)

  const isAmountValid = !!(
    values.amount !== '' &&
    exchangeParams?.minAmount?.lte(values.amount) &&
    exchangeParams?.maxAmount?.gte(values.amount)
  )

  const exchangeConditionsQuery = vm.useGetExchangeConditions({
    from: values.fromAsset,
    to: values.toAsset,
    amountFrom: new BigNumber(values.amount),
    enabled: isAmountValid,
  })

  const toAddressErrorMessage = getHasFormError(
    formikProps,
    'after-submit',
  )('toAddress')
    ? (errors.toAddress as string)
    : undefined

  return (
    <>
      <WithWeakTokenMetadata
        blockchain={internalToBlockchain}
        tokenId={toToken?.id}
        vm={vm}
      >
        {(tokenMetadata) => (
          <ExternalOrInternalAddressField
            hideCopyAddress
            testIds={{
              addressFieldExternal: 'to-asset-address-field-external',
              addressFieldInternal: 'to-asset-address-field-internal',
              setAddressFieldExternal: 'to-asset-set-address-field-external',
              setAddressFieldInternal: 'to-asset-set-address-field-internal',
            }}
            formFieldClass={classes.formField}
            blockchain={internalToBlockchain}
            tokenMetadata={tokenMetadata}
            items={toAccounts.map((a) => ({
              accountId: a.id,
              address: a.address,
              name: a.name,
              cryptoProviderType: a.cryptoProviderType,
              ...(toToken
                ? {
                    customBalanceRows: {
                      primary: {
                        tokenMetadata,
                        balance:
                          toToken.balances[a.address] ?? new BigNumber(0),
                      },
                      secondary: {
                        balance: a.balance,
                      },
                    },
                  }
                : {
                    balance: a.balance,
                  }),
            }))}
            value={{
              address: values.toAddress,
              addressType: values.toAddressType,
            }}
            onChange={(value: {address: string; addressType: AddressType}) => {
              setValues({
                ...values,
                toAddress: value.address,
                toAddressType: value.addressType,
              })
            }}
            errorMessage={toAddressErrorMessage}
            chipLabel={t('Receive to')}
            label={
              values.toAddressType === 'external'
                ? t('Address to receive')
                : t('Account')
            }
          />
        )}
      </WithWeakTokenMetadata>
      {toAssetNotification != null && (
        <ExchangeAssetNotification
          testId="to-asset-notification"
          children={toAssetNotification}
        />
      )}
      <Box className={classes.formField}>
        <TextField
          fullWidth
          disabled
          variant="outlined"
          label={`${t('Estimated amount to receive')} (${values.toAsset})`}
          rtl-data-test-id="exchange-estimated-amount-to-receive"
          value={
            exchangeConditionsQuery.data?.amountTo != null
              ? `~${exchangeConditionsQuery.data.amountTo.toString()}`
              : ''
          }
        />
      </Box>
      {extraIdName != null && (
        <Box className={classes.formField}>
          <TextField
            fullWidth
            variant="outlined"
            label={extraIdName}
            value={values.extraId}
            onChange={handleChange<FormField>('extraId')}
            rtl-data-test-id="extra-id-field"
            InputProps={{
              endAdornment: <ExtraIdHelp extraIdName={extraIdName} />,
            }}
          />
        </Box>
      )}
      <Grid item container justifyContent="flex-end">
        {isAmountValid && (
          <QueryGuard
            {...exchangeConditionsQuery}
            ErrorElement={
              <ExchangeConditionsError error={exchangeConditionsQuery.error} />
            }
          >
            {(exchangeConditions) => (
              <Typography>
                <FormattedExchangeRate
                  exchangeRate={exchangeConditions.rate}
                  fromAsset={exchangeConditions.fromAsset}
                  toAsset={exchangeConditions.toAsset}
                />
              </Typography>
            )}
          </QueryGuard>
        )}
      </Grid>
    </>
  )
}

const ExchangeConditionsError = ({error}: {error: AppError}) => {
  // EXCHANGE_REFACTOR
  // TODO: Abstract "changelly" language away from this component
  const {t} = useTranslation()
  const changellyErrorsTranslations = useChangellyErrorsTranslations()
  const exchangeConditionsErrorTranslationKey =
    error && isChangellyError(error)
      ? getChangellyErrorTranslationKey(error)
      : null

  return (
    <Box sx={{width: '100%'}} rtl-data-test-id="exchange-conditions-error">
      <InlineError
        error={
          exchangeConditionsErrorTranslationKey
            ? changellyErrorsTranslations[exchangeConditionsErrorTranslationKey]
            : t('Could not load exchange rate.')
        }
      />
    </Box>
  )
}

const makeGetAddressType =
  (allAccounts: AccountInfo[], exchangeAssetsDetails: ExchangeAssetsDetails) =>
  (value: SwapAsset, type: 'from' | 'to'): AddressType => {
    const blockchain = exchangeAssetsDetails[value]?.blockchain
    if (blockchain == null || !isExchangeBlockchain(blockchain)) {
      return 'external'
    }
    const hasSomeAccountAvailable = allAccounts.some(
      (a) => a.blockchain === blockchain,
    )
    if (type === 'to') {
      return hasSomeAccountAvailable ? 'internal' : 'external'
    }
    return hasSomeAccountAvailable &&
      isAssetInternallySwappable(exchangeAssetsDetails, value)
      ? 'internal'
      : 'external'
  }

const useStyles = makeStyles((theme) => ({
  maxButton: {
    userSelect: 'none',
  },
  alertRoot: {
    padding: '0px 16px',
  },
  alertMessage: {
    padding: '16.5px 0px',
    [theme.breakpoints.down('windowsZoomed')]: {
      padding: '13.875px 0px',
    },
    [theme.breakpoints.down('windowsOld')]: {
      padding: '11px 0px',
    },
  },
}))
