import type {FlowTokenId} from '@nufi/wallet-flow'
import React from 'react'

import {ExplorerLink} from '../common'

import {
  getFlowTokenExplorerLink,
  getFlowTransactionExplorerLink,
} from './getLink'

export * from './getLink'

export const FlowTransactionExplorerLink = ({txId}: {txId: string}) => (
  <ExplorerLink link={getFlowTransactionExplorerLink(txId)} />
)

export const FlowTokenExplorerLink = ({tokenId}: {tokenId: FlowTokenId}) => (
  <ExplorerLink link={getFlowTokenExplorerLink(tokenId)} />
)
