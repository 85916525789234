/* eslint-disable @typescript-eslint/explicit-function-return-type */

import {useGetAccounts} from './core'

//
// __COMPUTED__ QUERIES
//

export function useGetHasActiveStaking() {
  const accountsQuery = useGetAccounts()
  return {
    ...accountsQuery,
    data: accountsQuery.data?.some(
      (a) => !!a.stakingInfo.isStakingCollectionRegistered,
    ),
  }
}
