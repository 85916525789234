import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import type {SignState} from 'src/dappConnector/pages/sign/SignContext'
import {SignContext} from 'src/dappConnector/pages/sign/SignContext'

import {useDappConnectorStore} from '../../../../../store/dappConnector'
import {assert, safeAssertUnreachable} from '../../../../../utils/assertion'
import type {CardanoSignedTx} from '../../../../../wallet/cardano'
import {
  useSignTransaction,
  useSubmitTransaction,
} from '../../../../../wallet/cardano'
import {ActionHeader} from '../../../../components'
import {layoutProps} from '../../../sign/constants'

import {SignTxScreen} from './SignTx'
import {SubmitTxScreen} from './SubmitTx'
import {SuccessScreen} from './Success'

export function DappCreateCardanoCollateralScreen() {
  const {
    selectedAccount,
    screenInfo,
    blockchain,
    setIdleScreen,
    origin,
    favIconUrl,
  } = useDappConnectorStore()
  assert(
    screenInfo.state === 'create-cardano-collateral',
    'CreateCardanoCollateral: invalid screen type',
  )
  assert(
    selectedAccount != null,
    'CreateCardanoCollateral: no selected account',
  )
  assert(origin != null, 'OriginInfo: origin not provided')

  const onSuccess = () => {
    screenInfo.onCreated()
    setIdleScreen()
  }
  const onRejected = () => {
    screenInfo.onRejected()
    setIdleScreen()
  }

  return (
    <CreateCardanoCollateralScreen
      contextValue={{
        selectedAccount,
        signData: {
          ...screenInfo,
          onCreated: onSuccess,
          onRejected,
        },
        blockchain,
        origin,
        favIconUrl,
        layoutProps,
      }}
    />
  )
}

type NotNull<T> = {
  [P in keyof T]: NonNullable<T[P]>
}

type Ensure<T, K extends keyof T> = T & NotNull<Pick<T, K>>

export function CreateCardanoCollateralScreen({
  contextValue,
}: {
  contextValue: Ensure<
    SignState<'create-cardano-collateral'>,
    'selectedAccount' | 'origin'
  >
}) {
  const {t} = useTranslation()
  const {
    selectedAccount,
    signData,
    blockchain,
    origin,
    favIconUrl,
    hideProfileInfo,
  } = contextValue
  assert(
    blockchain === 'cardano',
    'CreateCardanoCollateral: blockchain not Cardano',
  )

  const [subScreen, setSubscreen] = useState<
    'sign-collateral-tx' | 'submit' | 'success'
  >('sign-collateral-tx')
  const signTxMutation = useSignTransaction()
  const submitTxMutation = useSubmitTransaction()

  const {txPlan, rawTx} = signData.data

  const onTxSign = async () =>
    await signTxMutation.mutateAsync({
      accountId: selectedAccount.id,
      txPlan,
    })
  const onTxSubmit = async (signedTx: CardanoSignedTx) => {
    await submitTxMutation.mutateAsync({
      accountId: selectedAccount.id,
      signedTx,
      txPlan,
    })
    setSubscreen('success')
  }

  return (
    <SignContext.Provider value={contextValue}>
      <ActionHeader
        {...{origin, favIconUrl, hideProfileInfo}}
        message={t('Create collateral')}
        selectedAccount={selectedAccount}
        mode="account-change-disabled"
        contentWidth={contextValue.layoutProps.screenWidth}
      />
      <>
        {(() => {
          switch (subScreen) {
            case 'sign-collateral-tx':
              return (
                <SignTxScreen
                  selectedAccount={selectedAccount}
                  txData={{type: blockchain, data: {txPlan, rawTx}}}
                  onSign={async () => {
                    const signedTx = await onTxSign()
                    setSubscreen('submit')
                    await onTxSubmit(signedTx)
                  }}
                  onFailure={signData.onRejected}
                  signProps={signTxMutation}
                />
              )
            case 'submit':
              return (
                <SubmitTxScreen
                  submitProps={submitTxMutation}
                  onRetry={() => {
                    assert(signTxMutation.data != null)
                    onTxSubmit(signTxMutation.data)
                  }}
                  onRejected={signData.onRejected}
                />
              )
            case 'success':
              return <SuccessScreen onSuccess={signData.onCreated} />
            default:
              return safeAssertUnreachable(subScreen)
          }
        })()}
      </>
    </SignContext.Provider>
  )
}
