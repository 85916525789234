import _ from 'lodash'

import type {
  RateInfosPerCurrency,
  CoinConversionRates,
  Currency,
  HistoricalConversionRates,
  TokenConversionRates,
  RateInfo,
} from 'src/features/conversionRates/domain'
import type {Blockchain, TokenMetadata} from 'src/types'
import {assert} from 'src/utils/assertion'

import {coingeckoBlockchainMetadata} from './blockchainMetadata'
import {
  allCurrenciesToCurrencyId,
  is24hChangeKey,
  strip24hChangeSuffix,
} from './currencyIds'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type PartialRecord<K extends keyof any, T> = Partial<Record<K, T>>

export const blockchainToCoingeckoId = (blockchain: Blockchain): string => {
  return coingeckoBlockchainMetadata[blockchain].nativeAssetCoingeckoId
}

const currencyIdToCurrency = _.invert(
  allCurrenciesToCurrencyId satisfies Record<Currency, string>,
) as Readonly<Record<string, Currency>>

const mapRatesToCurrencies = (
  rates: PartialRecord<string, number>,
): RateInfosPerCurrency =>
  _(rates)
    .entries()
    // Map rates to currentRate or change24h based on id and then strip id of suffix
    .map(([coingeckoId, rate]): [Currency, Partial<RateInfo>] => [
      currencyIdToCurrency[strip24hChangeSuffix(coingeckoId)]!,
      rate == null
        ? {}
        : is24hChangeKey(coingeckoId)
          ? {change24h: rate}
          : {currentRate: rate},
    ])
    .filter(([currency]) => currency != null)
    .groupBy(([currency]) => currency)
    // Merge array of partial RateInfos into single object
    .mapValues((array) =>
      array.reduce(
        (acc: Partial<RateInfo>, [, rate]) => ({...acc, ...rate}),
        {},
      ),
    )
    // Discard partial RateInfos with missing currentRate
    .pickBy(
      (rate): rate is typeof rate & {currentRate: NonNullable<unknown>} =>
        rate?.currentRate != null,
    )
    // Using intermediate satisfies because somehow TS allows assigning
    // _.Dictionary<Partial<RateInfo>> to RateInfosPerCurrency
    .value() satisfies Partial<{[currency: string]: RateInfo}>

export const parseCoinConversionRates = (
  blockchains: Readonly<Blockchain[]>,
  rates: PartialRecord<string, PartialRecord<string, number>>,
): CoinConversionRates =>
  blockchains.reduce((acc: CoinConversionRates, blockchain) => {
    const coinId = blockchainToCoingeckoId(blockchain)
    if (coinId == null) return acc

    const coinRates = rates[coinId]
    if (coinRates == null) {
      acc[blockchain] = 'not-loaded'
      return acc
    }
    return {...acc, [blockchain]: mapRatesToCurrencies(coinRates)}
  }, {})

export const parseTokenContractConversionRates = (
  rates: PartialRecord<string, PartialRecord<string, number>>,
  tokenMetadata: Array<
    Omit<TokenMetadata, 'contractAddress'> & {contractAddress: string}
  >,
): TokenConversionRates =>
  tokenMetadata.reduce((acc: TokenConversionRates, tm) => {
    const tokenRates = rates[tm.contractAddress]
    if (tokenRates == null) {
      // `undefined` (as opposed to 'not-loaded') gets shown in UI as N/A without any errors
      // i.e. total portfolio value is displayed and considered valid even if token rate is not available
      acc[tm.id] = undefined
      return acc
    }

    return {...acc, [tm.id]: mapRatesToCurrencies(tokenRates)}
  }, {})

export const parseTokenConversionRates = (
  rates: PartialRecord<string, PartialRecord<string, number>>,
  tokenMetadata: Array<TokenMetadata>,
): TokenConversionRates =>
  Object.fromEntries(
    tokenMetadata.map((tm) => {
      const tokenRate = tm.coingeckoId ? rates[tm.coingeckoId] : undefined

      return [tm.id, tokenRate ? mapRatesToCurrencies(tokenRate) : 'not-loaded']
    }),
  )

export const parseHistoricalConversionRates = (rates: {
  prices: Array<Array<number>>
}): HistoricalConversionRates => {
  const prices = rates.prices
  const historicalConversionRates = [] as HistoricalConversionRates

  prices.forEach((data: Array<number>) => {
    assert(data.length === 2, 'Historical conversion data not well structured!')

    const dataPoint = {timestamp: data[0]!, rate: data[1]!}
    historicalConversionRates.push(dataPoint)
  })

  return historicalConversionRates
}
