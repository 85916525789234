import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import {
  Box,
  List,
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
  Portal,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {isAccountActive} from '@nufi/wallet-common'
import type {FlowTokenMetadata} from '@nufi/wallet-flow'
import type {SolanaTokenMetadata} from '@nufi/wallet-solana'
import _ from 'lodash'
import React, {useRef} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory, Switch, Route} from 'react-router-dom'

import {getAvailableBlockchains} from 'src/features/availableBlockchains/application'
import {useGetConversionRates} from 'src/features/conversionRates/application'
import type {ConversionRates} from 'src/features/conversionRates/domain'
import {useEvmStore} from 'src/store/wallet'

import {Modal, LoadBlockchainAccountsError} from '../../components'
import config from '../../config'
import {routeTo} from '../../router'
import {safeAssertUnreachable} from '../../utils/assertion'
import {useScrollableStyles} from '../../utils/layoutUtils'
import type {AccountInfo, Blockchain, TokenMetadata} from '../../wallet'
import {useAllAccounts, useGetTokensMetadata} from '../../wallet'
import type {CardanoTokenMetadata} from '../../wallet/cardano'
import type {EvmAccountInfo, EvmBlockchain} from '../../wallet/evm'
import {isEvmBlockchain} from '../../wallet/evm'
import {ExchangeModal} from '../exchange'
import {ReceiveModal} from '../receive'
import {SendModal} from '../send/SendModal'
import {ReferenceElementContext as AccountListContainerRef} from '../utils'

import {AccountsCardLoading, AccountsListLoading} from './account/Loadings'
import {NoAccountsScreen} from './account/NoAccountsScreen'
import {Filters} from './assetList/Filters'
import {CardanoAccountCard} from './cardano/AccountCard'
import {EvmAccountCard} from './evm/AccountCard'
import {FlowAccountCard} from './flow/AccountCard'
import {
  usePortfolioPortalRef,
  usePortfolioPageContext,
} from './PortfolioContext'
import {
  AddAccount,
  AddAccountModal,
} from './sharedActions/addAccount/AddAccount'
import {SolanaAccountCard} from './solana/AccountCard'

type AccountsListProps = {
  blockchain: Blockchain | null
}

function AccountsList({blockchain}: AccountsListProps) {
  const {
    data: _data,
    isLoading: areAccountsLoading,
    errorKeys: _errorKeys,
    loadings,
  } = useAllAccounts()
  const classes = useStyles()
  const scrollableClasses = useScrollableStyles()
  const boundaryRef = useRef<HTMLElement>(null)

  const storedEvmAccounts = useEvmStore().accounts || []

  const data = _data.filter(
    (d) => blockchain == null || d.blockchain === blockchain,
  )
  const errorKeys = _errorKeys.filter(
    (e) => blockchain == null || e === blockchain,
  )

  const [evmAccounts, nonEvmAccounts] = _.partition(
    data,
    (d): d is EvmAccountInfo<EvmBlockchain> => isEvmBlockchain(d.blockchain),
  )

  const isLoading =
    blockchain == null ? areAccountsLoading : loadings[blockchain]
  const {rates} = useGetConversionRates()

  const metadataQueries = Object.fromEntries(
    getAvailableBlockchains().map((blockchain) => [
      blockchain,
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useGetTokensMetadata(blockchain),
    ]),
  ) as Record<Blockchain, ReturnType<typeof useGetTokensMetadata>>
  const sortedMetadataQueries = [
    ...Object.entries(metadataQueries).filter(([, query]) => !!query.data),
    ...Object.entries(metadataQueries).filter(
      ([, query]) => !query.data && !query.error,
    ),
    ...Object.entries(metadataQueries).filter(([, query]) => !!query.error),
  ]

  const [evmMetadataQueries, nonEvmMetadataQueries] = _.partition(
    sortedMetadataQueries,
    ([blockchain]) => isEvmBlockchain(blockchain),
  )

  const combinedEvmMetadataQuery = evmMetadataQueries.reduce(
    (acc, [blockchain, {isLoading, data}]) => {
      return {
        loadingBlockchains: isLoading
          ? [...acc.loadingBlockchains, blockchain]
          : acc.loadingBlockchains,
        data: data ? [...acc.data, ...data] : acc.data,
      } as {
        loadingBlockchains: EvmBlockchain[]
        data: TokenMetadata[]
      }
    },
    {loadingBlockchains: [] as EvmBlockchain[], data: [] as TokenMetadata[]},
  )

  const nonEvmAccountsByBlockchain = _.groupBy(
    nonEvmAccounts,
    ({blockchain}) => blockchain,
  )

  const groupedEvmAccounts = storedEvmAccounts
    .filter((storedAccount) => isAccountActive(storedAccount))
    .map((storedAccount) => ({
      storedAccount,
      accountInfos: evmAccounts.filter((a) => a.id === storedAccount.id),
    }))
    .filter(({accountInfos}) => accountInfos.length > 0)

  const hasAccounts =
    groupedEvmAccounts.length !== 0 || nonEvmAccounts.length !== 0

  return (
    <>
      {!hasAccounts && !isLoading && !errorKeys.length && (
        <NoAccountsScreen blockchain={blockchain || undefined} />
      )}
      <Box
        className={scrollableClasses.scrollableList}
        id="account-list"
        ref={boundaryRef}
      >
        <AccountListContainerRef.Provider value={boundaryRef}>
          <List component="nav" className={classes.accountsList}>
            {nonEvmMetadataQueries.map(([blockchain, query]) => {
              // We are intentionally not using query guard here, so that we can
              // proceed even if tokens loading fail.
              if (query.isLoading && !query.error) {
                return <AccountsCardLoading />
              }
              const tokensMetadata = query.data || []
              const hasTokensError = !!query.error

              return (
                <>
                  {(nonEvmAccountsByBlockchain[blockchain] ?? []).map(
                    (accountInfo) => (
                      <NonEvmAccountCard
                        key={accountInfo.id}
                        tokensMetadata={tokensMetadata}
                        accountInfo={accountInfo}
                        hasTokensError={hasTokensError}
                        conversionRates={rates}
                      />
                    ),
                  )}
                </>
              )
            })}
            {groupedEvmAccounts.map(({storedAccount, accountInfos}) => {
              return (
                <EvmAccountCard
                  storedAccountData={storedAccount}
                  accountInfos={accountInfos}
                  tokensMetadata={combinedEvmMetadataQuery.data || []}
                  conversionRates={rates}
                />
              )
            })}
          </List>
        </AccountListContainerRef.Provider>
        {errorKeys.map((blockchain) => (
          <Box mt={1} key={blockchain}>
            {<LoadBlockchainAccountsError {...{blockchain}} />}
          </Box>
        ))}
        {isLoading && (
          <Box mt={1}>
            <AccountsListLoading />
          </Box>
        )}
      </Box>
    </>
  )
}

type AccountCardProps = {
  accountInfo: Exclude<AccountInfo, EvmAccountInfo<EvmBlockchain>>
  hasTokensError: boolean
  tokensMetadata: TokenMetadata[]
  conversionRates: ConversionRates | undefined
}

function NonEvmAccountCard({
  accountInfo,
  tokensMetadata,
  conversionRates,
}: AccountCardProps) {
  const blockchain = accountInfo.blockchain

  return (
    <>
      {(() => {
        switch (blockchain) {
          case 'solana':
            return (
              <SolanaAccountCard
                {...{accountInfo, conversionRates}}
                tokensMetadata={tokensMetadata as SolanaTokenMetadata[]}
              />
            )
          case 'cardano':
            return (
              <CardanoAccountCard
                {...{accountInfo, conversionRates}}
                tokensMetadata={tokensMetadata as CardanoTokenMetadata[]}
              />
            )
          case 'flow':
            return (
              <FlowAccountCard
                {...{accountInfo, conversionRates}}
                tokensMetadata={tokensMetadata as FlowTokenMetadata[]}
              />
            )
          default:
            return safeAssertUnreachable(blockchain)
        }
      })()}
    </>
  )
}

function HwWalletHint() {
  const {t} = useTranslation()
  const history = useHistory()

  const onHwWalletHintClose = () => {
    history.push(routeTo.portfolio.accounts.addAccount.hw.index)
  }
  const classes = useHwWalletHintStyles()
  return (
    <Modal variant="centered">
      <Card raised className={classes.hintLayoutWrapper}>
        <CardContent>
          <Box
            color="success.main"
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Typography variant="h6" component="span" color="textPrimary">
              {t('Pair hardware wallet')}
            </Typography>
            <ArrowForwardIcon className={classes.hintIcon} />
          </Box>
          <Typography variant="body1" component="p" color="textSecondary">
            {t(
              'You can pair your hardware wallet or create new accounts by clicking on this button.',
            )}
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            fullWidth
            color="primary"
            variant="contained"
            // Playwright selectors does not work with animated element so we turn
            // the animation off when running tests.
            // See e.g. https://github.com/microsoft/playwright/issues/16048
            className={config.isJest ? classes.closeButton : undefined}
            onClick={onHwWalletHintClose}
            data-test-id="wallet-hint"
          >
            {t('Got it')}
          </Button>
        </CardActions>
        <Box />
      </Card>
    </Modal>
  )
}

export function Accounts() {
  const {
    blockchain,
    setBlockchain,
    hideNfts,
    setHideNfts,
    hideZeroBalances,
    setHideZeroBalances,
  } = usePortfolioPageContext()
  const history = useHistory()
  const scrollableClasses = useScrollableStyles()
  const tabsPortalRef = usePortfolioPortalRef()
  const onClose = () => {
    history.goBack()
  }

  return (
    <Box className={scrollableClasses.scrollableParent}>
      <Portal container={tabsPortalRef}>
        <AddAccount />
      </Portal>
      <Filters
        zeroBalances={{
          shouldHide: hideZeroBalances,
          toggleHide: () => setHideZeroBalances(!hideZeroBalances),
        }}
        nfts={{
          shouldHide: hideNfts,
          toggleHide: () => setHideNfts(!hideNfts),
        }}
        onBlockchainChange={setBlockchain}
        {...{blockchain}}
      />

      <AccountsList {...{blockchain}} />

      <Switch>
        <Route path={routeTo.portfolio.accounts.newlyCreatedHwWallet}>
          <HwWalletHint />
        </Route>
        <Route
          path={
            routeTo.portfolio.accounts.account(':blockchain', ':accountId').send
          }
        >
          <SendModal onClose={onClose} />
        </Route>
        <Route
          path={
            routeTo.portfolio.accounts.account(':blockchain', ':accountId')
              .receive
          }
        >
          <ReceiveModal onClose={onClose} />
        </Route>
        <Route
          path={
            routeTo.portfolio.accounts.account(':blockchain', ':accountId')
              .exchange
          }
        >
          <ExchangeModal onClose={onClose} />
        </Route>
        <Route
          path={routeTo.portfolio.accounts.addAccount.index}
          render={() => <AddAccountModal {...{onClose}} />}
        />
      </Switch>
    </Box>
  )
}

const addAccountPosition = {
  right: 24,
  top: 100,
}
const tip = {
  position: 25,
  width: 15,
}

const useStyles = makeStyles((theme) => ({
  accountsList: {
    padding: 0,
    marginBottom: theme.spacing(1),
  },
}))

const useHwWalletHintStyles = makeStyles((theme) => ({
  hintLayoutWrapper: {
    overflow: 'visible',
    position: 'fixed',
    right: addAccountPosition.right + 134 + tip.width, // TODO better specify button width
    top: addAccountPosition.top + 5,
    backgroundColor: theme.palette.background.paper,
    transform: `translate(0, -${tip.position}%)`,
    maxWidth: 320,
    '&:after': {
      content: "''",
      position: 'absolute',
      top: `${tip.position}%`,
      left: '100%',
      borderWidth: tip.width,
      borderStyle: 'solid',
      borderColor: `transparent transparent transparent ${theme.palette.background.paper}`,
    },
  },
  '@keyframes pulse': {
    '0%': {
      transform: 'scale(0.95)',
    },
    '70%': {
      transform: 'scale(1)',
    },
    '100%': {
      transform: 'scale(0.95)',
    },
  },
  hintIcon: {
    marginLeft: theme.spacing(0.5),
    height: 30,
    width: 30,
    display: 'inline',
  },
  closeButton: {
    transform: 'scale(1)',
    animation: '$pulse 2s infinite',
  },
}))
