import {isEvmBlockchain} from '@nufi/wallet-evm'
import type {BigNumber} from 'bignumber.js'

import {convertBalance} from 'src/features/assets/domain/conversions'
import type {
  ConversionRates,
  Currency,
} from 'src/features/conversionRates/domain'
import {valueDividedByDecimals} from 'src/utils/formatting'
import {getEvmNetworkConfig} from 'src/wallet/evm'

import type {AssetAmount} from '../types'

export const isAssetVisibleWhenHidingZeroBalances = ({
  isAlwaysVisible,
  balance,
}: {
  isAlwaysVisible: boolean
  balance: BigNumber
}) => isAlwaysVisible || !balance.isZero()

const isNativeAsset = (assetAmount: AssetAmount) =>
  assetAmount.type === 'native'

const compareWithNativeAsset = (a: AssetAmount, b: AssetAmount) => {
  // Don't care about non-evm native assets.
  if (!isEvmBlockchain(a.blockchain) || !isEvmBlockchain(b.blockchain)) {
    return 0
  }

  // Put native tokens first.
  if (isNativeAsset(a) && !isNativeAsset(b)) return -1
  if (isNativeAsset(b) && !isNativeAsset(a)) return 1

  // Both assets are native, compare by chainId (the theory is that most popular
  // chains have lower chainId, mainly ETH has chainId 1 so it should always be first).
  const aChainId = getEvmNetworkConfig(a.blockchain).chainId
  const bChainId = getEvmNetworkConfig(b.blockchain).chainId

  return aChainId - bChainId
}

/**
 * Sorts tokens by their converted balance in descending order.
 *
 * Rules:
 * - Tokens with a positive converted balance are sorted first.
 * - Then EVM native tokens are put first and/or compared by chainId.
 * - Then tokens with a positive amount that cannot be converted
 *   (because of missing conversion rate) are sorted by their amount.
 * - The last are tokens with a zero balance (regardless of conversion rate).
 *
 * Example where currency is USD and all decimals are 0:
 *     200 USD | 200 TokenA (largest USD value) >
 *     180 USD | 20 ETH (EVM, chainId 1) >
 *     180 USD | 200 MILK (EVM, chainId 2001) >
 *  >  0.1 USD | 300 TokenB (second-largest USD value) >
 *  >    0 USD |   0 MATIC (EVM, native, known USD value but amount is zero) >
 *  >  N/A USD | 500 TokenC (largest unconverted amount but unknown USD value) >
 *  >  N/A USD | 100 TokenD (second-largest unconverted amount given unknown USD value) >
 *  >    0 USD |   0 TokenE (known USD value but amount is zero) >=
 *  >= N/A USD |   0 TokenF (unknown USD value and amount is zero) >=
 *  >=   0 USD |   0 TokenG (conversion rate has no effect on order if amount is zero)
 */
export const sortAssetsByValue = (
  assetAmounts: readonly AssetAmount[],
  conversionRates: ConversionRates | undefined,
  currency: Currency,
): AssetAmount[] =>
  assetAmounts
    .map<[balance: number, assetAmount: AssetAmount]>((assetAmount) => {
      if (
        assetAmount.amount.gt(0) && // don't put converted zero balance above tokens with positive quantity
        conversionRates
      ) {
        const response = convertBalance({
          balance: assetAmount.amount,
          blockchain: assetAmount.blockchain,
          conversionRates,
          currency,
          tokenMetadata:
            assetAmount.type === 'token'
              ? assetAmount.tokenMetadata
              : undefined,
        })
        if (response.type === 'success') return [response.balance, assetAmount]
      }
      return [-1, assetAmount]
    })
    .sort(([aBalance, aAsset], [bBalance, bAsset]) => {
      // compare by converted balance
      const balanceDiff = bBalance - aBalance
      if (balanceDiff) return balanceDiff

      // put native tokens first
      if (isNativeAsset(aAsset) || isNativeAsset(bAsset)) {
        return compareWithNativeAsset(aAsset, bAsset)
      }

      // compare by token amount
      return valueDividedByDecimals(
        bAsset.amount,
        bAsset.type === 'token' ? bAsset.tokenMetadata?.decimals || 0 : 0,
      ).comparedTo(
        valueDividedByDecimals(
          aAsset.amount,
          aAsset.type === 'token' ? aAsset.tokenMetadata?.decimals || 0 : 0,
        ),
      )
    })
    .map(([, assetAmount]) => assetAmount)
