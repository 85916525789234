import {Alert, Box, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {useDappConnectorOriginInfo} from '../../../../../store/dappConnector'
import {assert} from '../../../../../utils/assertion'
import {useSignContext} from '../../SignContext'
import {TransactionScript} from '../common/details'
import type {BlockchainTxDataProps} from '../types'

const blockchain = 'flow'

export function FlowTxData({
  data: {signable},
}: {
  data: BlockchainTxDataProps<typeof blockchain>
}) {
  const {t} = useTranslation()
  const {selectedAccount} = useSignContext()
  assert(selectedAccount?.blockchain === blockchain)

  // https://flow.com/post/flow-blockchain-mainstream-adoption-easy-onboarding-wallets
  // https://github.com/onflow/flips/pull/53
  const isLinkAccountTx = !!signable.voucher.cadence.includes(
    '#allowAccountLinking',
  )

  const {origin} = useDappConnectorOriginInfo()
  assert(origin != null, 'OriginInfo: origin not provided')

  // TODO: Create some proper UI here, and display other important information
  // besides just the cadence code.

  return (
    <>
      {isLinkAccountTx && (
        <Box mb={2} mt={2}>
          <Alert severity="warning">
            <Typography>
              {t('dapp_connector_hybrid_custody_explanation', {
                host: new URL(origin).hostname,
              })}
            </Typography>
          </Alert>
        </Box>
      )}
      <TransactionScript script={signable.voucher.cadence} />
    </>
  )
}
