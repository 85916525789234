import type {SxProps, Theme} from '@mui/material'
import {Box} from '@mui/material'
import React, {useState, useEffect} from 'react'

import {useBackgroundImageStyles} from '../../../utils/layoutUtils'
import {InlineLoading} from '../Loading'

import {SafeCenterAligner} from './SafeCenterAligner'

type ImageWithLoadingProps = {
  url: string
  placeholder: string
  sx?: SxProps<Theme>
  backgroundSize?: 'contain' | 'cover'
}

export const ImageWithLoading = ({
  url,
  placeholder,
  sx,
  backgroundSize = 'contain',
}: ImageWithLoadingProps) => {
  const [loadedImageUrl, setLoadedImageUrl] = useState<
    string | null | undefined
  >(undefined)

  useEffect(() => {
    // Create a new image and wait for it to load, browsers cache images by default.
    // After the image is loaded, it can be used in components.
    // This way we can display spinner without having to use react-query.
    const img = new Image()
    img.src = url
    img.onload = () => setLoadedImageUrl(url)
    img.onerror = () => setLoadedImageUrl(null)
  }, [url])

  const {bgImage} = useBackgroundImageStyles({
    image: loadedImageUrl || placeholder,
    backgroundSize: loadedImageUrl ? backgroundSize : '80%', // 80% for placeholder
  })

  return loadedImageUrl !== undefined ? (
    <Box width="100%" height="100%" className={bgImage} sx={sx} />
  ) : (
    <SafeCenterAligner>
      <Box sx={{pointerEvents: 'none'}}>
        <InlineLoading />
      </Box>
    </SafeCenterAligner>
  )
}
