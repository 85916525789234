import _ from 'lodash'
import queryString from 'query-string'
import {useLocation} from 'react-router-dom'

import type {TokenId} from '../types'

export function useQueryParams() {
  return queryString.parse(useLocation().search)
}

/**
 * Given pathname and query string params, constructs URL string.
 */
export function withQueryString<
  P extends number | string | string[] | number[] | null,
>(
  path: string,
  queryParams: Record<string, P>,
  currentQueryString?: string,
): string {
  const newQueryObj = {
    ...(currentQueryString ? queryString.parse(currentQueryString) : {}),
    ...Object.entries(queryParams).reduce(
      (acc, [key, value]) => {
        if (value === null) return acc
        acc[key] = value as NonNullable<P>
        return acc
      },
      {} as Record<string, NonNullable<P>>,
    ),
  }

  const newQueryString = !_.isEmpty(newQueryObj)
    ? `?${queryString.stringify(newQueryObj)}`
    : ''
  return `${path}${newQueryString}`
}

export function encodeTokenId(tokenId?: TokenId | null) {
  return {token: tokenId || null}
}
