import type {GridProps} from '@mui/material'
import {Box, Grid} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import {useFormikContext} from 'formik'
import type {PropsWithChildren} from 'react'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {
  FooterLayout,
  ModalFooter,
  ModalLayout,
  ReadOnlyAccountField,
  useModalSharedStyles,
} from '../../../../../../components'
import type {AccountId, AccountInfo, Blockchain} from '../../../../../../types'
import {getHasFormError} from '../../../../../../utils/form'
import {ensureAccountById} from '../../../../../../wallet/utils/common'

type CommonDetailsScreenProps = PropsWithChildren<{
  onBack: () => void
  onSubmit: () => unknown
  accounts: AccountInfo[]
  accountId: AccountId
  blockchain: Blockchain
  formId: string
  ModalHeader: React.ReactNode
  containerGridProps?: GridProps
  accountFieldGridProps?: GridProps
  variant?: 'normal' | 'stretched'
}>

export default function CommonDetailsScreen({
  onBack,
  onSubmit,
  accounts,
  accountId,
  formId,
  blockchain,
  ModalHeader,
  children,
  variant = 'normal',
}: CommonDetailsScreenProps) {
  const classes = {...useStyles(), ...useModalSharedStyles()}
  const {t} = useTranslation()
  const account = ensureAccountById(accounts, accountId)
  const formikProps = useFormikContext<{accountId: AccountId}>()
  const hasError = getHasFormError(formikProps)

  return (
    <form onSubmit={onSubmit} id={formId} className={classes.formWrapper}>
      <ModalLayout
        header={ModalHeader}
        body={
          <Box className={classes.contentWrapper}>
            <Box p={2} height="100%">
              <Grid
                container
                className={clsx(
                  variant === 'stretched' && classes.stretchedContainer,
                )}
              >
                <Grid
                  item
                  xs={12}
                  className={clsx(
                    classes.commonBottomMargin,
                    variant === 'stretched' && classes.accountFieldContainer,
                  )}
                >
                  <ReadOnlyAccountField
                    blockchain={blockchain}
                    value={account}
                    label={t('Account')}
                    error={hasError('accountId')}
                    helperText={
                      hasError('accountId') && (
                        <>{formikProps.errors.accountId}</>
                      )
                    }
                  />
                </Grid>
                {children}
              </Grid>
            </Box>
          </Box>
        }
        footer={
          <ModalFooter hasDivider>
            <FooterLayout
              leftBtnConf={{
                onClick: onBack,
                children: t('Back'),
              }}
              rightBtnConf={{
                children: t('Continue'),
                type: 'submit',
                form: formId,
              }}
            />
          </ModalFooter>
        }
      />
    </form>
  )
}

const useStyles = makeStyles({
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
  },
  stretchedContainer: {
    flexDirection: 'column',
    flexWrap: 'nowrap',
    height: '100%',
  },
  accountFieldContainer: {
    // important so we don't have override all possible breakpoints
    // we don't want this to be bigger than its content at any resolution
    flexBasis: 'fit-content!important',
  },
})
