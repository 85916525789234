import {toChecksummedEvmAddress} from '@nufi/wallet-evm'
import _ from 'lodash'

import type {ProfileManager} from '../appStorage/profileManager'
import type {TokenImportState} from '../store/wallet'
import {useTokenImportStore} from '../store/wallet'
import type {
  TokenId,
  TokenImport,
  BlockchainAddress,
  Blockchain,
} from '../types'
import {safeAssertUnreachable, assert} from '../utils/assertion'

import type {EvmAddress, EvmBlockchain, EvmChainId} from './evm/types'
import {isEvmBlockchain} from './evm/utils'

type TokenFilterParams = {id?: TokenId} & (
  | {
      blockchain: Exclude<Blockchain, EvmBlockchain>
    }
  | {
      blockchain: EvmBlockchain
      chainId: EvmChainId
      address?: BlockchainAddress
    }
)

const isEvmTokenFilters = (
  data: TokenFilterParams,
): data is Extract<TokenFilterParams, {blockchain: EvmBlockchain}> =>
  isEvmBlockchain(data.blockchain)

function matchFilters(filters: TokenFilterParams, token: TokenImport): boolean {
  const matchesNetwork = (token: TokenImport): boolean => {
    const tokenBlockchain = token.blockchain
    if (isEvmBlockchain(tokenBlockchain)) {
      assert(isEvmTokenFilters(filters))
      return filters.chainId === token.chainId
    } else {
      // Provide network information into `TokenImport` type if adding
      // other than EVM blockchain!
      return safeAssertUnreachable(tokenBlockchain)
    }
  }

  const matchesEvmFilters = (
    token: Extract<TokenImport, {blockchain: EvmBlockchain}>,
  ): boolean => {
    if (isEvmBlockchain(token.blockchain)) {
      assert(isEvmTokenFilters(filters))
      return filters.address
        ? token.addresses.includes(
            toChecksummedEvmAddress<typeof token.blockchain>(
              filters.address as string as EvmAddress<typeof token.blockchain>,
            ),
          )
        : true
    }
    return true
  }

  return (
    filters.blockchain === token.blockchain &&
    (filters.id ? filters.id === token.id : true) &&
    matchesNetwork(token) &&
    (isEvmBlockchain(token.blockchain) ? matchesEvmFilters(token) : true)
  )
}

export interface TokenImportStoreManager {
  initialize(): Promise<void>
  reload: () => Promise<void>
  getVisibleTokens(filters: TokenFilterParams): TokenImport[]
  getAllTokens(): TokenImport[]
  setTokens(tokens: TokenImport[]): void
  getToken(filters: TokenFilterParams): TokenImport | null
}

type TokenImportStore = {
  getState: () => TokenImportState<TokenImport>
}

class TokenImportStoreManagerImpl implements TokenImportStoreManager {
  constructor(
    private tokenImportStore: TokenImportStore,
    private profileManager: ProfileManager,
  ) {}

  initialize: TokenImportStoreManager['initialize'] = async () => {
    await this.reload()
  }

  reload: TokenImportStoreManager['reload'] = async () => {
    const {tokenImports: localTokens} =
      await this.profileManager.getProfileData()
    this.setTokens(localTokens)
  }

  getAllTokens: TokenImportStoreManager['getAllTokens'] = () => {
    return this.getTokens().allTokens
  }

  getVisibleTokens: TokenImportStoreManager['getVisibleTokens'] = (filters) => {
    const tokens = this.getTokens().visibleTokens
    const filteredTokens = tokens.filter((t) => matchFilters(filters, t))
    return filteredTokens
  }

  getToken: TokenImportStoreManager['getToken'] = (filters) => {
    const tokens = this.getTokens().visibleTokens
    const token = tokens.find((t) => matchFilters(filters, t))
    if (!token) return null
    return token
  }

  setTokens: TokenImportStoreManager['setTokens'] = (tokens: TokenImport[]) => {
    const {setTokens} = this.tokenImportStore.getState()
    setTokens(tokens)
  }

  private getTokens(): {
    visibleTokens: Array<TokenImport>
    allTokens: Array<TokenImport>
  } {
    const {tokens} = this.tokenImportStore.getState()
    return {
      allTokens: tokens,
      visibleTokens: tokens.filter((t) => t.deletedAt == null),
    }
  }
}

function tokenExists(tokens: TokenImport[], token: TokenImport): boolean {
  return tokens.some((t) => matchFilters(token, t))
}

/**
 * Add token into array of tokens if the token with the same ID does not already
 * exist. If it exists it will extend the token's 'addresses' array with a new address.
 */
export function addToken(
  tokens: TokenImport[],
  newToken: TokenImport,
): TokenImport[] {
  // e.g. token was previously imported on another account / soft-deleted
  if (tokenExists(tokens, newToken)) {
    const _tokens = tokens.map((existingToken) => {
      if (matchFilters(newToken, existingToken)) {
        if (isEvmBlockchain(newToken.blockchain)) {
          const mergedAddresses = _.union(
            newToken.addresses,
            existingToken.addresses,
          )
          // overwrite name, ticker, deletedAt, etc. with newly set values
          return {
            ...newToken,
            addresses: mergedAddresses,
          }
        }
        safeAssertUnreachable(newToken.blockchain)
      }
      return existingToken
    })
    return _tokens
  } else {
    const _tokens = [...tokens, newToken]
    return _tokens
  }
}

export function softDeleteToken(
  tokens: TokenImport[],
  token: TokenImport,
): TokenImport[] {
  return tokens.map((t) => {
    if (matchFilters(token, t)) {
      if (isEvmBlockchain(token.blockchain)) {
        // deletion of an ETH token is done by passing the token with a single address to delete in "addresses" field
        assert(!!token.addresses[0])
        const addresses = t.addresses.filter((a) => a !== token.addresses[0])

        return {
          ...t,
          // if it was the last address, soft delete token as well
          ...(addresses.length === 0 && {deletedAt: Date.now()}),
          addresses,
        }
      }
      safeAssertUnreachable(token.blockchain)
    }
    return t
  })
}

let tokenImportStoreManager: TokenImportStoreManagerImpl | null

export const TokenImportStoreManagerProvider = {
  initialize: async (profileManager: ProfileManager): Promise<void> => {
    tokenImportStoreManager = new TokenImportStoreManagerImpl(
      useTokenImportStore,
      profileManager,
    )
    await tokenImportStoreManager.initialize()
  },
  instance: (): TokenImportStoreManagerImpl => {
    if (!tokenImportStoreManager) {
      throw new Error('TokenImportStoreManagerProvider not initialized')
    }
    return tokenImportStoreManager
  },
}
