import {assert} from './assertion'

export function createServiceLocator<T, U>(
  initializer: (args: U) => T,
  label?: string,
) {
  let instance: T | null

  return {
    init: (args: U): T => {
      instance = initializer(args)
      return instance
    },
    instance: () => {
      assert(instance != null, `${label} service locator not defined`)
      return instance
    },
    destroy: () => {
      instance = null
    },
  }
}

export function createAsyncServiceLocator<T, U>(
  initializer: (args: U) => Promise<T>,
  label?: string,
) {
  let instance: T | null

  return {
    init: async (args: U): Promise<T> => {
      instance = await initializer(args)
      return instance
    },
    instance: () => {
      assert(instance != null, `${label} service locator not defined`)
      return instance
    },
    destroy: () => {
      instance = null
    },
  }
}
