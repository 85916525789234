import {Sentry} from '@nufi/frontend-common'
import {
  isEvmBlockchain,
  evmChainIdToCaip2,
  ethereumChainIds,
  optimismChainIds,
  polygonChainIds,
  milkomedaC1ChainIds,
  arbitrumOneChainIds,
  baseChainIds,
} from '@nufi/wallet-evm'
import type {ProposalTypes, SessionTypes} from '@walletconnect/types'
import * as wcUtils from '@walletconnect/utils'
import _ from 'lodash'

import {getInitialEvmBlockchain} from 'src/dappConnector/utils/initialEvmBlockchain'
import {blockchainToWalletKind} from 'src/wallet/walletKind'

import config from '../../config'
import type {AccountInfo} from '../../types'
import {assert, safeAssertUnreachable} from '../../utils/assertion'
import {addressToHex} from '../../wallet/cardano'
import {supportedNamespaces} from '../constants'
import type {OnSessionProposalArgs} from '../core/hooks'
import type {WalletConnectBlockchain, WalletConnectChain} from '../types'
import {isEnabledWalletConnectBlockchain} from '../utils'

import {
  WalletConnectSessionProposalError,
  confirmConnection,
  sendMessageToWebWallet,
} from './utils'

const isChainConfiguredNetwork = (chain: WalletConnectChain): boolean => {
  switch (chain) {
    case 'solana:4sGjMW1sUnHzSxGspuhpqLDx6wiyjNtZ':
      return 'mainnet' === config.solanaClusterName
    case 'cip34:1-764824073':
      return 'mainnet' === config.cardanoNetwork
    case 'cip34:0-1':
      return 'preprod' === config.cardanoNetwork
    case 'cip34:0-2':
      return 'preview' === config.cardanoNetwork
    case 'cip34:0-4':
      return 'sanchonet' === config.cardanoNetwork
    case evmChainIdToCaip2(ethereumChainIds.homestead):
      return 'homestead' === config.ethereumNetwork
    case evmChainIdToCaip2(ethereumChainIds.sepolia):
      return 'sepolia' === config.ethereumNetwork
    case evmChainIdToCaip2(optimismChainIds.mainnet):
      return 'mainnet' === config.optimismNetwork
    case evmChainIdToCaip2(optimismChainIds.sepolia):
      return 'sepolia' === config.optimismNetwork
    case evmChainIdToCaip2(polygonChainIds.mainnet):
      return 'mainnet' === config.polygonNetwork
    case evmChainIdToCaip2(polygonChainIds.amoy):
      return 'amoy' === config.polygonNetwork
    case evmChainIdToCaip2(milkomedaC1ChainIds.mainnet):
      return 'mainnet' === config.milkomedaC1Network
    case evmChainIdToCaip2(milkomedaC1ChainIds.devnet):
      return 'devnet' === config.milkomedaC1Network
    case evmChainIdToCaip2(arbitrumOneChainIds.mainnet):
      return 'mainnet' === config.arbitrumOneNetwork
    case evmChainIdToCaip2(arbitrumOneChainIds.sepolia):
      return 'sepolia' === config.arbitrumOneNetwork
    case evmChainIdToCaip2(baseChainIds.mainnet):
      return 'mainnet' === config.baseNetwork
    case evmChainIdToCaip2(baseChainIds.sepolia):
      return 'sepolia' === config.baseNetwork
    default:
      return safeAssertUnreachable(chain)
  }
}

const chainToToBlockchain = (
  chain: WalletConnectChain,
): WalletConnectBlockchain => {
  switch (chain) {
    case 'solana:4sGjMW1sUnHzSxGspuhpqLDx6wiyjNtZ':
      return 'solana'
    case 'cip34:1-764824073':
    case 'cip34:0-1':
    case 'cip34:0-2':
    case 'cip34:0-4':
      return 'cardano'
    case evmChainIdToCaip2(ethereumChainIds.homestead):
    case evmChainIdToCaip2(ethereumChainIds.sepolia):
      return 'ethereum'
    case evmChainIdToCaip2(optimismChainIds.mainnet):
    case evmChainIdToCaip2(optimismChainIds.sepolia):
      return 'optimism'
    case evmChainIdToCaip2(polygonChainIds.mainnet):
    case evmChainIdToCaip2(polygonChainIds.amoy):
      return 'polygon'
    case evmChainIdToCaip2(milkomedaC1ChainIds.mainnet):
    case evmChainIdToCaip2(milkomedaC1ChainIds.devnet):
      return 'milkomedaC1'
    case evmChainIdToCaip2(arbitrumOneChainIds.mainnet):
    case evmChainIdToCaip2(arbitrumOneChainIds.sepolia):
      return 'arbitrumOne'
    case evmChainIdToCaip2(baseChainIds.mainnet):
    case evmChainIdToCaip2(baseChainIds.sepolia):
      return 'base'
    default:
      return safeAssertUnreachable(chain)
  }
}

const getAccount = (
  chain: WalletConnectChain,
  accountInfo: AccountInfo,
  proposerName: string,
) => {
  assert(
    blockchainToWalletKind(accountInfo.blockchain) ===
      blockchainToWalletKind(chainToToBlockchain(chain)),
    `Chain ID ${chain} incompatible with account blockchain ${accountInfo.blockchain}!`,
  )
  // MinSwap operates on some experimental implementation which is a WIP so we have to hack it for them
  // until either everybody accepts or they go back to current standard version
  if (accountInfo.blockchain === 'cardano' && proposerName === 'Minswap DEX') {
    assert(chainToToBlockchain(chain) === 'cardano')
    return `${chain}:${accountInfo.rewardAddressHex}-${addressToHex(
      accountInfo.address,
    )}`
  }
  return `${chain}:${accountInfo.address}`
}

const blockchainToStandard = (
  blockchain: WalletConnectBlockchain,
): keyof typeof supportedNamespaces => {
  switch (blockchain) {
    case 'cardano':
      return 'cip34'
    case 'ethereum':
    case 'milkomedaC1':
    case 'optimism':
    case 'polygon':
    case 'arbitrumOne':
    case 'base':
      return 'eip155'
    case 'solana':
      return 'solana'
    default:
      return safeAssertUnreachable(blockchain)
  }
}

const configuredSupportedNamespaces = _(supportedNamespaces)
  .mapValues(({chains, methods, events}) => ({
    methods: [...methods],
    events: [...events],
    chains: chains.filter(
      (chain) =>
        isEnabledWalletConnectBlockchain(chainToToBlockchain(chain)) &&
        isChainConfiguredNetwork(chain),
    ),
  }))
  .omitBy(({chains}) => !chains?.length)
  .value() satisfies ProposalTypes.RequiredNamespaces

/**
 * WalletConnect supports multi-chain sessions but we decide on a single blockchain in advance
 * and then report support only for blockchains supported by the dApp connector for the selected chain.
 * It may happen that the proposal is empty, which means that the dApp is requesting that we approve as many
 * namespaces as we support. In this case we try to guess the blockchain.
 *
 * NOTE: It may be the case that the proposal is unfeasible, in which case it should be rejected
 * later when building session namespaces, whereas this function is a best-effort candidate selector.
 */
export const getSessionCandidate = (
  proposal: Pick<
    ProposalTypes.Struct,
    'requiredNamespaces' | 'optionalNamespaces'
  > & {proposer: {metadata: {url: string}}},
): {
  blockchain: WalletConnectBlockchain
  namespace: ProposalTypes.RequiredNamespace & {chains: WalletConnectChain[]}
} => {
  const candidateChainIds: string[] = [
    // First try to derive the blockchain from proposal namespaces
    wcUtils.getChainsFromRequiredNamespaces(proposal.requiredNamespaces),
    wcUtils.getChainsFromRequiredNamespaces(proposal.optionalNamespaces),
    // Prefer EVM chains as fallback (arbitrary)
    configuredSupportedNamespaces.eip155?.chains,
    // Try all chains as last resort
    ...Object.values(configuredSupportedNamespaces).map((ns) => ns.chains),
  ].flatMap((chains) => chains || [])

  // Candidate is given by first chain that maps to a supported namespace
  const selectedCandidate = candidateChainIds
    .map((chain) => {
      const namespace = _.find(
        configuredSupportedNamespaces,
        (ns?: {chains: string[]}) => !!ns?.chains.includes(chain),
      )
      return namespace
        ? {
            // We can assert chain is WalletConnectChain because if namespace isn't null, then
            // chain must appear somewhere in supportedNamespaces.
            blockchain: chainToToBlockchain(chain as WalletConnectChain),
            determiningChainId: chain as WalletConnectChain,
            namespace,
          }
        : undefined
    })
    .filter((ns) => !!ns)[0]

  // Failing this assertion implies that no chain is supported regardless of proposal
  // because `candidateChainIds` is a superset of all supported chains.
  assert(selectedCandidate != null)

  if (isEvmBlockchain(selectedCandidate.blockchain)) {
    // For EVM-supporting dApps, try and guess the right chain since the dApp just lists
    // all chains it supports, not necessarily ones it prefers.
    selectedCandidate.blockchain = getInitialEvmBlockchain(
      proposal.proposer.metadata.url,
    )
  }

  if (
    selectedCandidate.namespace.chains.length > 1 &&
    !isEvmBlockchain(selectedCandidate.blockchain)
  ) {
    // For non-EVM blockchains, we only support one chain per namespace.
    selectedCandidate.namespace.chains = [selectedCandidate.determiningChainId]
  }

  return _.pick(selectedCandidate, ['blockchain', 'namespace'])
}

const buildAccountsFromChains = (
  chains: WalletConnectChain[],
  accountInfo: AccountInfo,
  proposerName: string,
): string[] => {
  return chains.map((chain) => getAccount(chain, accountInfo, proposerName))
}

const buildSessionNamespaces = (
  proposal: ProposalTypes.Struct,
  standard: string,
  {
    chains = [],
    methods,
    events,
    accounts,
  }: ProposalTypes.RequiredNamespace & {accounts: string[]},
): SessionTypes.Namespaces => {
  try {
    return wcUtils.buildApprovedNamespaces({
      proposal,
      supportedNamespaces: {[standard]: {chains, methods, events, accounts}},
    })
  } catch (e) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const message: unknown = (e as any)?.message

    const isNonConformingNamespacesError =
      typeof message === 'string' &&
      message.startsWith(
        wcUtils.getInternalError('NON_CONFORMING_NAMESPACES').message,
      )

    if (isNonConformingNamespacesError) {
      if (message.includes("namespaces keys don't satisfy")) {
        throw new WalletConnectSessionProposalError(
          'UNSUPPORTED_NAMESPACE_KEY',
          e,
        )
      } else if (message.includes("namespaces chains don't satisfy")) {
        throw new WalletConnectSessionProposalError('UNSUPPORTED_CHAINS', e)
      } else if (message.includes("namespaces methods don't satisfy")) {
        throw new WalletConnectSessionProposalError('UNSUPPORTED_METHODS', e)
      } else if (message.includes("namespaces events don't satisfy")) {
        throw new WalletConnectSessionProposalError('UNSUPPORTED_EVENTS', e)
      } else if (message.includes("namespaces accounts don't satisfy")) {
        throw new WalletConnectSessionProposalError('UNSUPPORTED_ACCOUNTS', e)
      }
    }
    throw e
  }
}

/**
 * Attempts to build a namespace satisfying given proposal with dummy accounts.
 * This helps us fail early by checking whether there's point in asking the user for connection confirmation.
 */
const ensureProposalFeasibility = (
  proposal: ProposalTypes.Struct,
  standard: string,
  {chains = [], methods, events}: ProposalTypes.RequiredNamespace,
): void => {
  buildSessionNamespaces(proposal, standard, {
    chains,
    methods,
    events,
    accounts: chains.map((chain) => wcUtils.formatAccountWithChain('0', chain)),
  })
}

export const onSessionProposal = async ({
  proposal,
  approve,
  reject,
}: OnSessionProposalArgs): Promise<void> => {
  try {
    const {blockchain, namespace} = getSessionCandidate(proposal)
    const standard = blockchainToStandard(blockchain)

    // Don't ask for user input if the proposal isn't feasible
    ensureProposalFeasibility(proposal, standard, namespace)

    const accountInfo = await confirmConnection(blockchain, {
      origin: proposal.proposer.metadata.url,
      favIconUrl: proposal.proposer.metadata.icons?.[0],
    })
    assert(
      blockchainToWalletKind(blockchain) ===
        blockchainToWalletKind(accountInfo.blockchain),
      'Confirmed account for blockchain incompatible with selected dApp connector!',
    )

    const sessionNamespaces = buildSessionNamespaces(proposal, standard, {
      ...namespace,
      accounts: buildAccountsFromChains(
        namespace.chains,
        accountInfo,
        proposal.proposer.metadata.name,
      ),
    })

    await approve(sessionNamespaces)

    // we notify the web UI that user approved the session
    sendMessageToWebWallet({
      type: 'walletConnectSessionApproved',
      data: proposal.proposer.metadata,
    })
  } catch (e) {
    if (e instanceof WalletConnectSessionProposalError) {
      await reject(e.key, e.cause)
    } else {
      Sentry.captureException(e)
      await reject('USER_REJECTED', e)
    }
  }
}
