import {
  ArrowDropUp as AscIcon,
  ArrowDropDown as DescIcon,
  HelpOutline as HelpIcon,
} from '@mui/icons-material'
import {Typography, Box, Divider} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, {useState} from 'react'

import type {SortDirection} from 'src/features/assets'

import {LabeledIconWithTooltip} from './LabeledIcon'

export function ListHeaderTitle({
  children,
}: {
  children: string | React.ReactNode
}) {
  return (
    <Typography variant="caption" color="textSecondary" component="div">
      {children}
    </Typography>
  )
}

type ListHeaderTooltipTitleProps = {
  children: string | React.ReactNode
  desc: string
}

export function ListHeaderTooltipTitle({
  children,
  desc,
}: ListHeaderTooltipTitleProps) {
  const classes = useStyles()
  return (
    <LabeledIconWithTooltip
      Label={<ListHeaderTitle>{children}</ListHeaderTitle>}
      Icon={<HelpIcon className={classes.helpIcon} fontSize="small" />}
      title={desc}
      spacing={0}
    />
  )
}

export function ListHeaderWrapper({children}: {children: React.ReactNode}) {
  return (
    <Box>
      <Box mb={1}>{children}</Box>
      <Divider />
      <Box mb={2} />
    </Box>
  )
}

export function ListLayoutWrapper({children}: {children: React.ReactNode}) {
  return (
    <Box pl={2} pr={2} color="text.disabled">
      {children}
    </Box>
  )
}

type SortableHeaderProps<SortBy> = {
  label: string
  setSortBy: (v: SortBy) => void
  column: SortBy
  sortBy: SortBy
  sortDirection: SortDirection
  defaultSortDirection: SortDirection
}

export function SortableHeader<SortBy>({
  setSortBy,
  label,
  column,
  sortBy,
  sortDirection,
  defaultSortDirection,
}: SortableHeaderProps<SortBy>) {
  const classes = useStyles()
  return (
    <Box
      display="flex"
      alignItems="center"
      onClick={() => setSortBy(column)}
      className={classes.clickable}
    >
      <ListHeaderTitle>{label}</ListHeaderTitle>
      <Box display="flex">
        {(() => {
          if (sortBy === column) {
            return sortDirection === 'asc' ? (
              <AscIcon color="primary" />
            ) : (
              <DescIcon color="primary" />
            )
          }
          return defaultSortDirection === 'asc' ? (
            <AscIcon color="disabled" />
          ) : (
            <DescIcon color="disabled" />
          )
        })()}
      </Box>
    </Box>
  )
}

export function useSortHandlers<SortBy>(
  defaultSortBy: SortBy,
  defaultSortDirection: SortDirection,
) {
  const [sortBy, _setSortBy] = useState<SortBy>(defaultSortBy)
  const [sortDirection, setSortDirection] =
    useState<SortDirection>(defaultSortDirection)

  const setSortBy = (newSortBy: SortBy) => {
    if (sortBy === newSortBy) {
      setSortDirection(sortDirection === 'desc' ? 'asc' : 'desc')
    } else {
      _setSortBy(newSortBy)
      setSortDirection(defaultSortDirection)
    }
  }
  return {sortBy, sortDirection, setSortBy}
}

const useStyles = makeStyles((theme) => ({
  helpIcon: {
    marginLeft: theme.spacing(0.5),
    color: theme.palette.action.active,
    fontSize: 15,
  },
  clickable: {
    cursor: 'pointer',
    userSelect: 'none',
  },
}))
