/* eslint-disable @typescript-eslint/explicit-function-return-type */

import {Sentry} from '@nufi/frontend-common'

import {useSettingsStore} from 'src/features/profile/application'
import {
  cloudSyncServiceLocator,
  remoteProfileStorage,
} from 'src/features/profileSync/application'
import {AccountStorageServiceLocator} from 'src/features/walletStorage/application'
import {reloadAppState} from 'src/features/walletStorage/application/reloadAppState'
import type {AccountsStorageServiceInitializeArgs} from 'src/features/walletStorage/domain'

import {nufiAuthTokenManager} from '../features/nufiAuth/application'
import {useProfileSyncState} from '../store/profileSync'
import {
  refreshTransactionsStore,
  registerTransactionStoreListener,
} from '../store/transactions'
import {useInitializationStateStore} from '../store/wallet'
import {TokenImportStoreManagerProvider} from '../wallet/TokenImportStoreManager'
import {TokenVisibilityStoreManagerProvider} from '../wallet/TokenVisibilityStoreManager'

import MasterProfileManager from './masterProfileManager'
import {profileManagerLocator} from './profileManager'

export const initWalletStoreManagers = async (
  args: AccountsStorageServiceInitializeArgs,
) => {
  const profileManager = profileManagerLocator.instance()
  useSettingsStore
    .getState()
    .setSettings((await profileManager.getProfileData()).settings)
  await AccountStorageServiceLocator.init({
    profileManager: profileManagerLocator.instance(),
    args,
    initializationStore: {
      init: (e) => useInitializationStateStore.getState().init(e),
      isInitialized: () => useInitializationStateStore.getState().initialized,
    },
  })
  await TokenImportStoreManagerProvider.initialize(profileManager)
  await TokenVisibilityStoreManagerProvider.initialize(profileManager)

  registerTransactionStoreListener()
  refreshTransactionsStore()
}

export const initCloudSync = () => {
  const {setSuccessfulSync, setFailedSync} = useProfileSyncState.getState()
  const profileManager = profileManagerLocator.instance()

  return cloudSyncServiceLocator.init({
    deps: {
      profileManager,
      masterProfileManager: MasterProfileManager,
      remoteProfileStorage,
    },
    reloadAppState,
    onDisabled: () => setFailedSync(Date.now(), 'disabled'),
    onPaused: () => setFailedSync(Date.now(), 'paused'),
    onFailure: () => setFailedSync(Date.now()),
    onSuccess: () => setSuccessfulSync(Date.now()),
  })
}

export type CloudSyncStrategy =
  | 'blocking-sync'
  | 'non-blocking-sync'
  | 'no-sync'

export const commonAppInit = async (
  accountsInitArgs: AccountsStorageServiceInitializeArgs,
  options?: {
    cloudSyncStrategy?: CloudSyncStrategy
  },
) => {
  const cloudSyncStrategy = options?.cloudSyncStrategy || 'blocking-sync'

  await initWalletStoreManagers(accountsInitArgs)
  const cloudSyncService = initCloudSync()

  try {
    // fail silently so we don't interrupt login because of api token error
    await nufiAuthTokenManager.loadApiToken()
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e)
    Sentry.captureException(e)
  }

  useProfileSyncState
    .getState()
    .init((await MasterProfileManager.getCurrentProfileMeta()).syncedAt)

  if (cloudSyncStrategy !== 'no-sync') {
    const promise = cloudSyncService.syncProfileData()
    if (cloudSyncStrategy === 'blocking-sync') {
      await promise
    }
  }

  return {cloudSyncService}
}
