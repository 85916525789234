import type {Blockchain} from 'src/types'

import type {Warning} from '../domain'

export const CommonAppWarningsKeys = {
  NATIVE_ASSET_FAILURE: ['NATIVE_ASSET_FAILURE'],
  TOKENS_FAILURE: ['TOKENS_FAILURE'],
  PARTIAL_TOKENS_FAILURE: ['PARTIAL_TOKENS_FAILURE'],
}

export const CommonAppWarnings = {
  PARTIAL_TOKENS_FAILURE: (blockchain: Blockchain) => ({
    key: [...CommonAppWarningsKeys.PARTIAL_TOKENS_FAILURE, blockchain],
    type: 'PARTIAL_TOKENS_FAILURE' as const,
    assetsValuePotentiallyAffected: true,
    blockchain,
  }),
  NATIVE_ASSET_FAILURE: (blockchain: Blockchain) => ({
    key: [...CommonAppWarningsKeys.NATIVE_ASSET_FAILURE, blockchain],
    type: 'NATIVE_ASSET_FAILURE' as const,
    assetsValuePotentiallyAffected: true,
    blockchain,
  }),
  TOKENS_FAILURE: (blockchain: Blockchain) => ({
    key: [...CommonAppWarningsKeys.TOKENS_FAILURE, blockchain],
    type: 'TOKENS_FAILURE' as const,
    assetsValuePotentiallyAffected: true,
    blockchain,
  }),
}

type _CommonAppWarningsKeys = keyof typeof CommonAppWarnings
export type UnpublishedCommonAppWarning = ReturnType<
  (typeof CommonAppWarnings)[_CommonAppWarningsKeys]
>

export type CommonAppWarning = Warning & UnpublishedCommonAppWarning
