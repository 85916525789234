import {useQuery, useInfiniteQuery} from '@tanstack/react-query'
import {useMemo} from 'react'

import {isBlockchainAvailable} from 'src/features/availableBlockchains/application'
import type {
  GetDappsParams,
  GetDappsResponse,
} from 'src/features/dappDirectory/domain'

import {dappDirectoryApi} from '../infrastructure/nufiCmsApi'

const P = 'dappDirectory'

type DAppQuery = Omit<GetDappsParams, 'pagination'> & {pageSize: number}

export const queryKeys = {
  getDapps: (params: DAppQuery) => [P, 'dApps', {params}],
  getBlockchains: [P, 'blockchains'],
  getDappCategories: [P, 'dAppCategories'],
}

function flattenGetDappsQueryPages(
  queryResult: ReturnType<typeof useInfiniteQuery<GetDappsResponse>>,
) {
  return {
    ...queryResult,
    data: queryResult.data?.pages.flatMap((p) => p.data),
  }
}

export function useGetDApps(params: DAppQuery, enabled = true) {
  const dAppsInfiniteQuery = useInfiniteQuery<GetDappsResponse>({
    queryKey: queryKeys.getDapps(params),
    queryFn: async ({pageParam: page = 1}) => {
      const {pageSize, ...filters} = params
      const result = await dappDirectoryApi.getDapps({
        ...filters,
        pagination: {pageSize, page: page as number},
      })
      // Note that it would be better to pass the list of blockchains to server
      const data = result.data
        .map((d) => ({
          ...d,
          blockchains: d.blockchains.filter((b) =>
            isBlockchainAvailable(b.name),
          ),
        }))
        .filter((d) => d.blockchains.length > 0)
      return {
        meta: result.meta,
        data,
      }
    },
    enabled,
    initialPageParam: 1,
    getNextPageParam: ({
      meta: {
        pagination: {page, pageCount},
      },
    }) => (pageCount > page ? page + 1 : undefined),
  })

  const data = useMemo(
    () => flattenGetDappsQueryPages(dAppsInfiniteQuery).data,
    [dAppsInfiniteQuery.data?.pages, params],
  )

  return {
    ...dAppsInfiniteQuery,
    data,
  }
}

export function useGetDAppCategories(enabled = true) {
  return useQuery({
    queryKey: queryKeys.getDappCategories,
    queryFn: async () => await dappDirectoryApi.getDappCategories(),
    enabled,
    gcTime: Infinity,
    staleTime: Infinity,
  })
}

export function useGetDAppBlockchains(enabled = true) {
  return useQuery({
    queryKey: queryKeys.getBlockchains,
    queryFn: async () => {
      // Note that it would be better to pass the list of blockchains to server
      const data = await dappDirectoryApi.getDappBlockchains()
      return data.filter((d) => isBlockchainAvailable(d.name))
    },
    enabled,
    gcTime: Infinity,
    staleTime: Infinity,
  })
}
