import {
  EvmLedgerCryptoProvider,
  EvmMnemonicCryptoProvider,
  EvmTrezorCryptoProvider,
} from '@nufi/wallet-evm'
import {EvmGridPlusCryptoProvider} from '@nufi/wallet-evm/dist/cryptoProviders/gridPlusCryptoProvider'

import {localSecretProvider} from '../secretProvider'

export const cryptoProviders = {
  mnemonicCryptoProvider: new EvmMnemonicCryptoProvider(localSecretProvider),
  ledgerCryptoProvider: new EvmLedgerCryptoProvider(),
  trezorCryptoProvider: new EvmTrezorCryptoProvider(),
  gridPlusCryptoProvider: new EvmGridPlusCryptoProvider(),
}
