import {request} from '@nufi/frontend-common'
import type {CardanoAddress} from '@nufi/wallet-cardano'
import BigNumber from 'bignumber.js'

import config from 'src/config'
import type {Cbor} from 'src/dappConnector/api/cardano/types'

import type {
  DexHunterApi,
  DexHunterOrderStatus,
  DexHunterTokensMap,
} from '../domain'

import {getDexInfo} from './dexes'

class DexHunterApiImpl implements DexHunterApi {
  private baseUrl = 'https://dexhunter.sbase.ch'

  private commonHeaders = {
    'X-Partner-Id': config.dexHunterPartnerCode,
    'Content-Type': 'application/json',
  }

  getTokens = async () => {
    const result = await request<DexHunterTokenApiResult[]>({
      method: 'GET',
      url: `${this.baseUrl}/swap/tokens`,
      headers: this.commonHeaders,
    })
    return result.reduce((acc: DexHunterTokensMap, t) => {
      acc[t.token_id] = {
        id: t.token_id,
        ticker: t.ticker,
      }
      return acc
    }, {})
  }

  getOrders = async (
    address: CardanoAddress,
    page: number,
    pageSize: number,
  ) => {
    const result = await request<DexHunterOrderApiResult[]>({
      method: 'POST',
      url: `${this.baseUrl}/swap/orders/${address}`,
      body: JSON.stringify({
        page,
        perPage: pageSize,
      }),
      headers: this.commonHeaders,
    })
    return result.map((order) => ({
      id: order._id,
      txHash: order.tx_hash,
      fromAssetId: order.token_id_in,
      fromAmount: new BigNumber(order.amount_in),
      toAssetId: order.token_id_out,
      toAmount: new BigNumber(order.actual_out_amount),
      status: order.status,
      submissionTime: order.submission_time,
      dex: getDexInfo(order.dex),
    }))
  }

  cancelOrder = async (orderId: string, address: string) => {
    const {cbor} = await request<DexHunterCancelOrderApiResult>({
      method: 'POST',
      url: `${this.baseUrl}/swap/cancel`,
      headers: this.commonHeaders,
      body: JSON.stringify({
        order_id: orderId,
        address,
      }),
    })
    return {cborTx: cbor as Cbor<'transaction'>}
  }
}

export const dexHunterApi = new DexHunterApiImpl()

type DexHunterTokenApiResult = {
  icon: string
  is_verified: boolean
  price: number
  supply: string
  ticker: string
  token1_amt: string
  token2_amt: string
  token_ascii: string
  token_decimals: string
  token_id: string
  token_policy: string
}

type DexHunterOrderApiResult = {
  _id: string
  tx_hash: string
  dex: string
  submission_time: string
  actual_out_amount: number
  amount_in: number
  token_id_in: string
  token_id_out: string
  status: DexHunterOrderStatus
}

type DexHunterCancelOrderApiResult = {
  cbor: string
}
