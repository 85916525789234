/* eslint-disable @typescript-eslint/explicit-function-return-type */

import {getFlowContractId, parseFlowTokenId} from '@nufi/wallet-flow'
import type {
  FlowAccountInfo,
  FlowNFTContractMetadata,
  FlowTokenAmount,
  FlowTokenId,
  FlowTokenMetadata,
} from '@nufi/wallet-flow'
import _ from 'lodash'

import {fetchNeverStaleQuery, useNeverStaleQuery} from 'src/utils/query-utils'
import {cachedGetFungibleTokenList} from 'src/wallet/flow/application'

import type {AccountId, AccountIdRequestedBalance} from '../../../../types'
import {flow} from '../../../flowManagers'
import {cachedGetAccounts} from '../core'

import {cachedGetSupportedTokenContracts} from './contractMetadata'
import {cachedGetAllNfts} from './nfts'
import {tokensQueryKeys} from './queryKeys'
import {
  aggregateTokenAmounts,
  getTokenMetadataFromContract,
  getFlowNftTokenMetadata,
  getNftTokenAmount,
} from './utils'

//
// __CACHED__ QUERIES
//

function cachedGetFungibleTokenAccounts() {
  return fetchNeverStaleQuery({
    queryKey: tokensQueryKeys.fungibleTokenAccounts(),
    queryFn: async () => {
      const accounts = await cachedGetAccounts()
      const tokenList = await cachedGetFungibleTokenList()
      return await Promise.all(
        accounts.map(async (a) => ({
          accountId: a.id,
          tokenBalances: await flow.accountManager.getFungibleTokenBalances(
            a.address,
            tokenList.map((t) => t.contractMetadata),
          ),
        })),
      )
    },
  })
}

//
// __COMPUTED__ QUERIES
//

// there is no notion of tokenAccount in flow, but for simplicity, we consider
// tokenBalances bound to a accountId to be a "tokenAccount"

export type FlowTokenAccount = {
  accountId: AccountId
  tokenBalances: FlowTokenAmount[]
}

async function getTokenAccounts() {
  const fungibleTokens = await cachedGetFungibleTokenAccounts()
  const nfts = await cachedGetAllNfts()

  return fungibleTokens.map((token) => {
    const nftsForAccountId = nfts.nftsByAccount[token.accountId]
    const nftAmounts = (nftsForAccountId || []).map((nft) =>
      getNftTokenAmount(nft),
    )
    return {
      accountId: token.accountId,
      tokenBalances: [...token.tokenBalances, ...nftAmounts],
    }
  })
}

getTokenAccounts.__key = tokensQueryKeys.tokenAccounts

export function cachedGetTokenAccounts() {
  return fetchNeverStaleQuery({
    queryKey: getTokenAccounts.__key(),
    queryFn: getTokenAccounts,
  })
}

export function useGetTokenAccounts(enabled = true) {
  return useNeverStaleQuery({
    queryKey: getTokenAccounts.__key(),
    queryFn: getTokenAccounts,
    enabled,
  })
}

async function getTokensMetadata() {
  const nfts = await cachedGetAllNfts()
  const tokenMetadataBundle = await cachedGetFungibleTokenList()
  const supportedTokenContracts = await cachedGetSupportedTokenContracts()

  const nftContractMetadata = supportedTokenContracts.filter(
    (tc): tc is FlowNFTContractMetadata => !tc.isFungible,
  )
  const nftMetadata: FlowTokenMetadata[] = Object.values(nfts.allNfts).map(
    (nft) => getFlowNftTokenMetadata(nft, nftContractMetadata),
  )
  // We need token metadata also for collections that user does not have, in reality
  // we need this data only in context of collection metadata so it its ok if it is only partial
  const partialNotOwnedNftMetadata = nftContractMetadata
    .map((tc) => getTokenMetadataFromContract(tc))
    .filter((tm): tm is FlowTokenMetadata => !!tm)
  return [
    ...tokenMetadataBundle,
    ..._.uniqBy([...nftMetadata, ...partialNotOwnedNftMetadata], 'id'),
  ]
}

getTokensMetadata.__key = tokensQueryKeys.tokenMetadata.all

export function cachedGetTokensMetadata() {
  return fetchNeverStaleQuery({
    queryKey: getTokensMetadata.__key(),
    queryFn: getTokensMetadata,
  })
}

export function useGetTokensMetadata(enabled = true) {
  return useNeverStaleQuery({
    queryKey: getTokensMetadata.__key(),
    queryFn: getTokensMetadata,
    enabled,
  })
}

// TODO: allow fetching arbitrary, not just own token. It may be needed for dapp connector.
// See cardano implementation
export function useGetAccountTokens(account: FlowAccountInfo, enabled = true) {
  return useNeverStaleQuery({
    queryKey: tokensQueryKeys.accountTokens(account.id),
    queryFn: async () => {
      const tokenAccounts = await cachedGetTokenAccounts()
      return tokenAccounts.find(({accountId}) => accountId === account.id)
        ?.tokenBalances
    },
    enabled,
  })
}

const getTokens = () => async () => {
  const tokenAccounts = await cachedGetTokenAccounts()
  return aggregateTokenAmounts(
    tokenAccounts.flatMap(({tokenBalances}) => tokenBalances),
  )
}

export function useGetTokens(enabled = true) {
  return useNeverStaleQuery({
    queryKey: tokensQueryKeys.index,
    queryFn: getTokens(),
    enabled,
  })
}

export function cachedGetTokens() {
  return fetchNeverStaleQuery({
    queryKey: tokensQueryKeys.index,
    queryFn: getTokens(),
  })
}

export function useGetTokenMetadata(tokenId: FlowTokenId, enabled = true) {
  return useNeverStaleQuery({
    queryKey: tokensQueryKeys.tokenMetadata.token(tokenId),
    queryFn: async () => {
      const tokensMetadata = await cachedGetTokensMetadata()
      const tokenMetadata = tokensMetadata.find((t) => t.id === tokenId)
      if (tokenMetadata) {
        return tokenMetadata
      }
      // if we cant find token metadata, it means user does not have that specific token
      // in that case we try to match it to the same contract at least
      const {contractAddress, contractName} = parseFlowTokenId(tokenId)
      const contractId = getFlowContractId(contractAddress, contractName)
      return tokensMetadata.find(
        (t) =>
          getFlowContractId(
            t.contractMetadata.contractAddress,
            t.contractMetadata.contractName,
          ) === contractId,
      )
    },
    enabled,
  })
}

export function cachedGetTokenBalancesPerAccount(tokenId: FlowTokenId) {
  return fetchNeverStaleQuery({
    queryKey: tokensQueryKeys.balancesPerAccount(tokenId),
    queryFn: async () => {
      const tokenAccounts = await cachedGetTokenAccounts()
      return tokenAccounts.reduce((acc, curr) => {
        const tokenBalance = curr.tokenBalances.find(
          (tb) => tb.token.id === tokenId,
        )?.amount
        return tokenBalance
          ? [...acc, {accountId: curr.accountId, balance: tokenBalance}]
          : acc
      }, [] as AccountIdRequestedBalance[])
    },
  })
}

export * from './queryKeys'
export * from './nfts'
export * from './tokenSetup'
export * from './contractMetadata'
export * from './utils'
