import type {
  MessageTypes,
  TypedDataV1,
  TypedMessage,
} from '@metamask/eth-sig-util'
import {
  recoverPersonalSignature,
  SignTypedDataVersion,
} from '@metamask/eth-sig-util'
import type {
  ErrorResponse,
  Handler,
  RequestContext,
} from '@nufi/dapp-client-core'
import {sansHexPrefix} from '@nufi/wallet-common'

import {middleware} from '../../../../dappConnector/middlewares'
import type {Connector} from '../../../../dappConnector/types'
import {useDappConnectorStore} from '../../../../store/dappConnector'
import {pushTransactionImperative} from '../../../../store/transactions'
import {isDroppedTxError} from '../../../../store/transactions/subscriptionUtils'
import {assert, safeAssertUnreachable} from '../../../../utils/assertion'
import type {EvmSignedTypedData} from '../../../../wallet/evm/cryptoProviders/types'
import {getEvmManager} from '../../../../wallet/evm/evmManagers'
import {
  toChecksummedEvmAddress,
  toPlainEvmAddress,
} from '../../../../wallet/evm/sdk/utils'
import type {
  EvmAddress,
  EvmUnsignedTransaction,
  EvmWei,
  PlainEvmAddress,
  EvmAccountOfflineInfo,
  EvmBlockchain,
} from '../../../../wallet/evm/types'
import {assertIsEvmBlockchain, toBigNumber} from '../../../../wallet/evm/utils'
import {createEvmChromeNotification} from '../../../chromeNotifications'
import {
  confirmInit,
  confirmSign,
  requestEvmChainChange,
  requestEvmTokenImport,
} from '../../apiBridge'

import {ProviderRpcError} from './injectedConnector'
import type {
  API,
  Permission,
  RequestedPermission,
  SignTypedDataV1Params,
  SignTypedDataV3Params,
  SignTypedDataV4Params,
} from './types'

const notEnabledError = new ProviderRpcError(
  'Unauthorized',
) as unknown as ErrorResponse
const consentRejectedError = new ProviderRpcError(
  'User-Rejected-Request',
) as unknown as ErrorResponse

const getEvmState = () => {
  const {selectedAccount, blockchain} = useDappConnectorStore.getState()
  assert(selectedAccount != null && selectedAccount.blockchain === blockchain)
  assertIsEvmBlockchain(blockchain)

  return {
    selectedAccount: selectedAccount as EvmAccountOfflineInfo<
      typeof blockchain
    >,
    blockchain,
  }
}

const getAccounts = (): PlainEvmAddress[] => {
  const {selectedAccount} = getEvmState()
  return [
    toPlainEvmAddress<typeof selectedAccount.blockchain>(
      selectedAccount.address,
    ),
  ]
}

// https://eips.ethereum.org/EIPS/eip-2255
// https://docs.metamask.io/wallet/reference/rpc-api/#restricted-methods
// https://github.com/MetaMask/rpc-cap#rpc-methods
const defaultGetPermissionsResponse = (
  context: RequestContext,
): Permission[] => {
  // NuFi does not have a concept of permissions. Therefore we always
  // return accounts permissions if user is logged in. If user is logged out
  // injected script returns [] instead.

  const accounts = getAccounts()

  return [
    {
      invoker: context.trusted.origin,
      parentCapability: 'eth_accounts',
      caveats: [
        {
          type: 'restrictReturnedAccounts',
          value: accounts,
        },
      ],
    },
  ]
}

// https://eips.ethereum.org/EIPS/eip-2255
// https://docs.metamask.io/wallet/reference/rpc-api/#restricted-methods
// https://github.com/MetaMask/rpc-cap#rpc-methods
const defaultRequestPermissionsResponse = (): RequestedPermission[] => {
  // NuFi does not have a concept of permissions. Therefore we always
  // return accounts permissions if user is logged in. If user is logged out
  // injected script initializes login.
  return [
    {
      parentCapability: 'eth_accounts',
    },
  ]
}

const signTypedDataHandlerFactory =
  (version: SignTypedDataVersion) =>
  async (
    context: RequestContext,
    {
      params,
    }: {
      params:
        | SignTypedDataV1Params
        | SignTypedDataV3Params
        | SignTypedDataV4Params
    },
  ): Promise<EvmSignedTypedData> => {
    const {selectedAccount, blockchain} = getEvmState()

    const payload = (() => {
      switch (version) {
        case SignTypedDataVersion.V1:
          return {
            version: SignTypedDataVersion.V1 as const,
            data: params[0] as TypedDataV1,
          }
        case SignTypedDataVersion.V3: {
          return {
            version: SignTypedDataVersion.V3 as const,
            data: JSON.parse(params[1]) as TypedMessage<MessageTypes>,
          }
        }
        case SignTypedDataVersion.V4:
          return {
            version: SignTypedDataVersion.V4 as const,
            data: JSON.parse(params[1]) as TypedMessage<MessageTypes>,
          }
        default:
          return safeAssertUnreachable(version)
      }
    })()

    const uiData =
      payload.version === SignTypedDataVersion.V1
        ? {
            type: 'typed' as const,
            // No strict typing needed here, so to make our life easier we
            // allow for `any`
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            messagesTyped: payload.data as Record<string, any>[],
          }
        : {type: 'typed' as const, messagesTyped: [payload.data.message]}

    const signedData = await confirmSign(
      'sign-message',
      {
        type: 'ethereum',
        data: uiData,
      },
      async () => {
        const {accountManager, accountsStore} = getEvmManager(blockchain)
        const storedAccount = accountsStore.getAccount(selectedAccount.id)
        return await accountManager.signTypedData(storedAccount, payload)
      },
      () => consentRejectedError,
    )

    return signedData
  }

export const evmDappConnectorApi: API = {
  rpcRequest: async (context, method, args) => {
    const {blockchain} = getEvmState()
    return await getEvmManager(blockchain).blockchainApi.rpcRequest(
      method,
      args,
    )
  },
  eth_accounts: getAccounts,
  eth_requestAccounts: getAccounts,
  wallet_getPermissions: defaultGetPermissionsResponse,
  wallet_requestPermissions: defaultRequestPermissionsResponse,
  eth_signTypedData: signTypedDataHandlerFactory(SignTypedDataVersion.V1),
  eth_signTypedData_v1: signTypedDataHandlerFactory(SignTypedDataVersion.V1),
  eth_signTypedData_v3: signTypedDataHandlerFactory(SignTypedDataVersion.V3),
  eth_signTypedData_v4: signTypedDataHandlerFactory(SignTypedDataVersion.V4),
  wallet_watchAsset: async (context, {params: data}) => {
    if (
      toChecksummedEvmAddress(
        data.options.address as unknown as EvmAddress<EvmBlockchain>,
      ) !== (data.options.address as unknown as EvmAddress<EvmBlockchain>)
    ) {
      throw new ProviderRpcError(
        'InvalidInput',
        '`wallet_watchAsset` contract address must be checksummed.',
      )
    }

    if (!(data.options.name == null || typeof data.options.name === 'string')) {
      throw new ProviderRpcError(
        'InvalidInput',
        '`wallet_watchAsset` invalid contract name',
      )
    }

    if (
      !(data.options.symbol == null || typeof data.options.symbol === 'string')
    ) {
      throw new ProviderRpcError(
        'InvalidInput',
        '`wallet_watchAsset` invalid contract symbol',
      )
    }

    if (
      !(
        data.options.decimals == null ||
        typeof data.options.decimals === 'number'
      )
    ) {
      throw new ProviderRpcError(
        'InvalidInput',
        '`wallet_watchAsset` invalid contract decimals',
      )
    }

    // Malicious data could potentially cause XSS, but react should safe us there, as doing custom
    // detection of "<script>" is usually very fragile.
    return await requestEvmTokenImport(data)
  },
  wallet_switchEthereumChain: async (
    context,
    data: {
      proposedChainId: string
      currentChainId: string
    },
  ) => await requestEvmChainChange(data),
  personal_sign: async (context, {params: [payload]}) => {
    const {selectedAccount, blockchain} = getEvmState()
    const {accountManager, accountsStore} = getEvmManager(blockchain)

    const storedAccount = accountsStore.getAccount(selectedAccount.id)

    const signMessage = async () =>
      await accountManager.signPersonalMessage(storedAccount, payload)

    const signedData = await confirmSign(
      'sign-message',
      {
        type: 'ethereum',
        data: {messageHex: sansHexPrefix(payload), type: 'hex'},
      },
      signMessage,
      () => consentRejectedError,
    )

    return signedData
  },
  personal_ecRecover: async (context, {params: [message, signature]}) => {
    return recoverPersonalSignature({
      data: message,
      signature,
    }) as PlainEvmAddress
  },
  eth_sendTransaction: async (context, {params}) => {
    assert(params.length === 1, 'Can not handle more than 1 ETH tx at once')
    const [unsignedTxData] = params

    const {selectedAccount, blockchain} = getEvmState()

    // Hacky solution for uniswap, as it passes `gas` property, that should mean `gasLimit`
    if ('gas' in unsignedTxData) {
      unsignedTxData.gasLimit = (
        unsignedTxData as EvmUnsignedTransaction & {gas: string}
      ).gas
    }

    if (unsignedTxData.gasLimit == null) {
      try {
        const estimatedGas =
          await getEvmManager(blockchain).blockchainApi.estimateGasLimit(
            unsignedTxData,
          )
        unsignedTxData.gasLimit = estimatedGas.toString()
      } catch (err) {
        // We ignore error for now
        // TODO inform the user that the tx about to be signed may
        // fail/gas estimation failed, like Metamask does
      }
    }

    if ('from' in unsignedTxData) {
      assert(
        unsignedTxData.from?.toLocaleLowerCase() ===
          selectedAccount.address.toLocaleLowerCase(),
        "'from' field does not match selected account",
      )
    }

    return await confirmSign(
      'sign-tx',
      {
        type: blockchain,
        data: {
          txParams: unsignedTxData,
        },
      },
      async (extraOptions) => {
        assertIsEvmBlockchain(extraOptions?.type)
        assert(extraOptions?.type === blockchain)

        const {accountManager, blockchainApi, networkConfig, accountsStore} =
          getEvmManager(blockchain)

        const storedAccount = accountsStore.getAccount(selectedAccount.id)

        const signedTx = await accountManager.signTx(storedAccount, {
          version: networkConfig.gasConfig.type,
          toAddress: unsignedTxData.to as EvmAddress<typeof blockchain>,
          gasOptions: extraOptions.data.gasOptions,
          variant: 'dappTx',
          // Note that not all transactions need to specify value
          amount: toBigNumber(unsignedTxData.value ?? '0') as EvmWei<
            typeof blockchain
          >,
          nonce: null,
          data: unsignedTxData.data,
        })
        const {txHash} = await blockchainApi.sendTransaction(signedTx.body, {
          onConfirmSuccess: (data) => {
            createEvmChromeNotification({
              type: 'success',
              blockchain,
              ...data,
            })
          },
          onConfirmFailure: (err, data) => {
            createEvmChromeNotification({
              type: isDroppedTxError(err) ? 'dropped' : 'failed',
              blockchain,
              ...data,
            })
          },
        })

        pushTransactionImperative({
          accountId: selectedAccount.id,
          blockchain,
          transactionId: txHash,
          nonce: signedTx.meta.nonce,
        })
        return txHash
      },
      () => consentRejectedError,
    )
  },
}

const createHandler = (): Handler =>
  middleware.userConsent(
    middleware.resolveMethod(evmDappConnectorApi, {
      notEnabledError,
      passContext: true,
    }),
    confirmInit,
    {
      connectorKind: 'evm',
      isEnabledMethod: 'isEnabled',
      enableMethod: 'enable',
      consentRejectedError,
      notEnabledError,
    },
  )

const connector: Connector = {createHandler}

export default connector
