import BigNumber from 'bignumber.js'
import {useMemo} from 'react'

import {getBlockchainDecimals} from 'src/constants'

import {useGetBlockchainName, useGetCoinName} from '../../utils/translations'
import type {Blockchain} from '../../wallet'
import {
  getDefaultAccountId,
  sumAccountInfoBalances,
  useGetAccounts,
  useNativeBalance,
} from '../../wallet'
import {getCoinPriceChange} from '../conversionRates/application'

import {getHasSomeError, getHasSomeIsLoading} from './common'
import {convertBalance} from './domain/conversions'
import type {
  AssetRow,
  AssetRowCurrencyProps,
  UseAssetsConversionParams,
  UseBlockchainAssetsReturnType,
} from './types'

export function useLoadCoin(
  blockchain: Blockchain,
  {conversionRates, currency, enabled}: UseAssetsConversionParams,
): UseBlockchainAssetsReturnType {
  const getCoinName = useGetCoinName()
  const getBlockchainName = useGetBlockchainName()
  const accountsQuery = useGetAccounts(blockchain, enabled)
  const totalBalanceQuery = useNativeBalance(blockchain, enabled)

  const sortingLabel = getBlockchainName(blockchain).toLocaleLowerCase()
  const searchingLabels = [
    sortingLabel,
    getCoinName(blockchain).toLocaleLowerCase(),
  ]
  const commonDataValues = {
    isNft: false,
    sortingLabel,
    searchingLabels,
    blockchain,
  }

  const data: AssetRow[] = useMemo(() => {
    if (!enabled) {
      return []
    }

    if (totalBalanceQuery?.data && accountsQuery.data) {
      const totalBalance = totalBalanceQuery.data
      const accounts = accountsQuery.data
      const defaultAccountId = getDefaultAccountId(accounts)
      const availableBalance = sumAccountInfoBalances(accounts)

      const balancesToCurrency: AssetRowCurrencyProps = (() => {
        return {
          totalBalanceToCurrency: convertBalance({
            balance: totalBalance,
            blockchain,
            currency,
            conversionRates: conversionRates.rates,
          }),
          availableBalanceToCurrency: convertBalance({
            balance: availableBalance,
            blockchain,
            currency,
            conversionRates: conversionRates.rates,
          }),
          price: convertBalance({
            balance: new BigNumber(1).shiftedBy(
              getBlockchainDecimals(blockchain),
            ),
            blockchain,
            currency,
            conversionRates: conversionRates.rates,
          }),
          priceChange: getCoinPriceChange(
            blockchain,
            currency,
            conversionRates.rates,
          ),
        }
      })()

      return [
        {
          ...commonDataValues,
          totalBalance,
          availableBalance,
          ...balancesToCurrency,
          owningAccountsCount: accounts.length,
          defaultAccountId,
        },
      ]
    }
    return []
  }, [enabled, totalBalanceQuery?.data, accountsQuery.data, conversionRates])

  // TODO: avoid `totalBalanceQuery` being possibly undefined for some blockchains
  // and simplify
  const queries = totalBalanceQuery
    ? [accountsQuery, totalBalanceQuery]
    : [accountsQuery]

  const hasError = getHasSomeError(queries)
  const isLoading = getHasSomeIsLoading(queries)

  return {
    blockchain,
    data,
    isLoading,
    hasError,
    assetType: 'native',
  }
}
