import {flowContractsAddresses} from '@nufi/wallet-flow'
import type {
  FlowAccountInfo,
  FlowAddress,
  Nanoflow,
  FlowTokenEffect,
} from '@nufi/wallet-flow'
import type BigNumber from 'bignumber.js'
import _ from 'lodash'

import config from '../../../../../../config'
import {getBlockchainDecimals} from '../../../../../../constants'
import {
  formatCSVAmount,
  formatCSVTokenAmount,
} from '../../../../../../utils/formatting'
import type {CSVFlowTxHistoryEntry} from '../../../../../../wallet/flow/public/queries/direct'
import {getTxHistory} from '../../../../../../wallet/flow/public/queries/direct'

import type {
  CommonEntryFields,
  CreateCSVRows,
  CSVConstants,
  TxEntry,
} from './common'
import {formatToUTC, TxSummaryType, filterEntriesByDate} from './common'

function getCurrencyName(tokenEffect: FlowTokenEffect) {
  if (tokenEffect.ticker === 'FLOW' || tokenEffect.ticker === 'FlowToken')
    return 'FLOW'
  return `${tokenEffect.ticker || ''}(${tokenEffect.token.id})`
}

type TransferCommonEntryFields = CommonEntryFields & {
  isToken: boolean
}

const getCommonTokenTransferProps = (tokenEffect: FlowTokenEffect) => {
  return {
    type:
      tokenEffect.type === 'Deposit'
        ? TxSummaryType.RECEIVED
        : TxSummaryType.SENT,
    currency: getCurrencyName(tokenEffect),
    received: tokenEffect.type === 'Deposit' ? tokenEffect.amount : undefined,
    sent: tokenEffect.type === 'Withdraw' ? tokenEffect.amount : undefined,
    isToken: true,
  }
}

const createFungibleTokenEntries = (
  tokenEffects: FlowTokenEffect[],
  commonEntryFields: TransferCommonEntryFields,
  payedFee: Nanoflow | undefined,
): TxEntry[] => {
  const flowTokenAddress = flowContractsAddresses[config.flowNetwork][
    '0xFLOWTOKENADDRESS'
  ] as FlowAddress

  return tokenEffects
    .map((transfer) => {
      if (transfer.contractAddress === flowTokenAddress) {
        const commonTokenProps = getCommonTokenTransferProps(transfer)
        return {
          ...commonEntryFields,
          ...commonTokenProps,
          isToken: false,
          // add the fee to the FlowToken entry - there should be exactly one of those in each transaction
          fee: payedFee,
          // fee is added/subtracted to/from the FLOW token effect during parsing so we need to subtract/add it back
          sent:
            payedFee != null && transfer.type === 'Withdraw'
              ? transfer.amount.minus(payedFee)
              : commonTokenProps.sent,
          received:
            payedFee != null && transfer.type === 'Deposit'
              ? transfer.amount.plus(payedFee)
              : commonTokenProps.received,
        }
      } else {
        return {
          ...commonEntryFields,
          // explicitly set fee to undefined to make TS happy about the filter below
          fee: undefined,
          // flow fungible tokens have the same amount of decimals as the native FLOW
          decimals: getBlockchainDecimals('flow'),
          ...getCommonTokenTransferProps(transfer),
        }
      }
    })
    .filter(
      (txEntry) => !txEntry.sent?.isEqualTo(0) || !txEntry.fee?.isEqualTo(0),
    )
}

const createNftTransferEntry = (
  transfer: FlowTokenEffect,
  commonEntryFields: TransferCommonEntryFields,
): TxEntry => ({
  ...commonEntryFields,
  decimals: 0,
  ...getCommonTokenTransferProps(transfer),
})

const createTokenTransferEntries = (
  txHistoryEntry: CSVFlowTxHistoryEntry,
  commonEntryFields: TransferCommonEntryFields,
): TxEntry[] => {
  const {tokenEffects, fee, payerAddress, accountAddress} = txHistoryEntry

  // we need to know which account payed for the transaction
  const payedFee =
    payerAddress === accountAddress && fee.isGreaterThan(0) ? fee : undefined

  const nftTokenEffects = tokenEffects.filter(
    (tokenEffect) => tokenEffect.isNft,
  )
  const nftEntries = nftTokenEffects.map((nft) =>
    createNftTransferEntry(nft, commonEntryFields),
  )

  const ftEntries = createFungibleTokenEntries(
    tokenEffects.filter((tokenEffect) => !tokenEffect.isNft),
    commonEntryFields,
    payedFee,
  )

  return [...nftEntries, ...ftEntries]
}

const createTransactionEntries = (
  txHistory: CSVFlowTxHistoryEntry[],
): TxEntry[] =>
  txHistory.flatMap((transaction) => {
    const {timeIssued, accountName, transactionId, accountAddress} = transaction
    const commonEntryFields = {
      isToken: false,
      accountName,
      txHash: transactionId,
      timeIssued,
      address: accountAddress,
    }
    return createTokenTransferEntries(transaction, commonEntryFields)
  })

export const _createCSVRows = async (
  csvConstants: CSVConstants,
  startDate: Date,
  endDate: Date,
  txHistoriesPerAccount: CSVFlowTxHistoryEntry[],
) => {
  const filteredTxHistoriesPerAccount = filterEntriesByDate(
    Object.values(
      _.groupBy(txHistoriesPerAccount, (entry) => entry.accountName),
    ),
    startDate,
    endDate,
  )

  const {delimiter} = csvConstants
  const transactionsEntries: TxEntry[] = createTransactionEntries(
    filteredTxHistoriesPerAccount.flat(),
  )
  const entries: Array<TxEntry> = _.sortBy(transactionsEntries, (entry) =>
    entry.timeIssued.getTime(),
  )

  const csv = entries.map((entry) => {
    const printAmount = (num: BigNumber) =>
      entry.isToken
        ? formatCSVTokenAmount(num, entry.decimals || 0)
        : formatCSVAmount(num, 'flow')
    return {
      date: entry.timeIssued,
      row: [
        formatToUTC(entry.timeIssued),
        entry.txHash,
        entry.address,
        entry.accountName,
        entry.type,
        entry.received && printAmount(entry.received),
        entry.received !== undefined ? entry.currency : undefined,
        entry.sent && printAmount(entry.sent),
        entry.sent !== undefined ? entry.currency : undefined,
        entry.fee && formatCSVAmount(entry.fee, 'flow'),
        entry.fee !== undefined ? 'FLOW' : undefined,
      ]
        .map((value) =>
          value === undefined ? delimiter : `${value}${delimiter}`,
        )
        .join(''),
    }
  })
  return csv
}

export const createCSVRows: CreateCSVRows<FlowAccountInfo> = async (
  csvConstants: CSVConstants,
  accounts: FlowAccountInfo[],
  startDate: Date,
  endDate: Date,
) => {
  if (accounts.length === 0) return []

  const txHistoriesPerAccount = await getTxHistory(accounts, startDate)

  return _createCSVRows(csvConstants, startDate, endDate, txHistoriesPerAccount)
}
