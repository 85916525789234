import {
  ErrorOutline as ErrorIcon,
  HourglassFullTwoTone as WaitingIcon,
  ConfirmationNumber as ConfirmationIcon,
  Check as SuccessIcon,
  Loop as RefundIcon,
  PanTool as OnHoldIcon,
  Timer as ExpiredIcon,
} from '@mui/icons-material'
import {Grid, Typography} from '@mui/material'
import type {PartnerSwapId, Swap, SwapAsset, SwapStatus} from 'common'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {useStatusTranslations} from 'src/features/swaps/application'

import {
  FormattedDateTime,
  Icon,
  LabeledIcon,
  WithTooltip,
} from '../../../components'
import {FormattedAmount} from '../../exchange/utils'

import {
  ExchangeHistoryItemLayout,
  useExchangeHistoryItemHandlers,
} from './ExchangeHistoryItemUtils'

type StatusConfig = {
  title: string
  tooltip: React.ReactNode
  icon: JSX.Element
}

const useStatusConfig = (
  currencyFrom: SwapAsset,
  currencyTo: SwapAsset,
  amount: string,
  isInternalSwap: boolean,
  partnerSwapId: PartnerSwapId,
  status: SwapStatus,
): StatusConfig => {
  const {t} = useTranslation()
  const statusTranslations = useStatusTranslations()

  switch (status) {
    case 'waiting':
      return isInternalSwap
        ? {
            title: statusTranslations.waiting,
            tooltip: t('SwapStatus.WAITING_INTERNAL.tooltip', {
              currencyFrom,
              amount,
            }),
            icon: <WaitingIcon />,
          }
        : {
            title: statusTranslations.waiting,
            tooltip: t('SwapStatus.WAITING_EXTERNAL.tooltip', {
              currencyFrom,
              amount,
            }),
            icon: <WaitingIcon />,
          }
    case 'confirming':
      return {
        title: statusTranslations.confirming,
        tooltip: t('SwapStatus.CONFIRMING.tooltip'),
        icon: <ConfirmationIcon />,
      }
    case 'exchanging':
      return {
        title: statusTranslations.exchanging,
        tooltip: t('SwapStatus.EXCHANGING.tooltip', {currencyFrom, currencyTo}),
        icon: <Icon type="exchangeIcon" />,
      }
    case 'sending':
      return {
        title: statusTranslations.sending,
        tooltip: t('SwapStatus.SENDING.tooltip', {currencyTo}),
        icon: <Icon type="sendIcon" />,
      }
    case 'finished':
      return {
        title: statusTranslations.finished,
        tooltip: t('SwapStatus.FINISHED.tooltip', {currencyTo}),
        icon: <SuccessIcon />,
      }
    case 'failed':
      return {
        title: statusTranslations.failed,
        tooltip: t('SwapStatus.FAILED.tooltip'),
        icon: <ErrorIcon />,
      }
    case 'refunded':
      return {
        title: statusTranslations.refunded,
        tooltip: t('SwapStatus.REFUNDED.tooltip'),
        icon: <RefundIcon />,
      }
    case 'hold':
      return {
        title: statusTranslations.hold,
        tooltip: t('SwapStatus.HOLD.tooltip'),
        icon: <OnHoldIcon />,
      }
    case 'expired':
      return {
        title: statusTranslations.expired,
        tooltip: t('SwapStatus.EXPIRED.tooltip'),
        icon: <ExpiredIcon />,
      }
    case 'waiting_expired':
      return {
        title: statusTranslations.waiting_expired,
        tooltip: t('SwapStatus.EXPIRED.tooltip'),
        icon: <ExpiredIcon />,
      }
    case 'overdue':
      return {
        title: statusTranslations.overdue,
        tooltip: t('SwapStatus.EXPIRED.tooltip'),
        icon: <ExpiredIcon />,
      }
    default:
      return {
        title: t('SwapStatus.UNEXPECTED.title'),
        tooltip: t('SwapStatus.UNEXPECTED.tooltip'),
        icon: <ErrorIcon />,
      }
  }
}

type ExchangeHistoryItemProps = Pick<
  Swap,
  | 'id'
  | 'createdAt'
  | 'status'
  | 'currencyFrom'
  | 'currencyTo'
  | 'amountExpectedFrom'
  | 'amountExpectedTo'
  | 'amountFrom'
  | 'amountTo'
  | 'nufiAddressFrom'
  | 'partnerSwapId'
>

export function ExchangeHistoryItem({
  id,
  createdAt,
  status,
  currencyFrom,
  currencyTo,
  amountExpectedFrom,
  amountExpectedTo,
  amountFrom,
  amountTo,
  nufiAddressFrom,
  partnerSwapId,
}: ExchangeHistoryItemProps) {
  const {onClick} = useExchangeHistoryItemHandlers(id)
  const {title, tooltip, icon}: StatusConfig = useStatusConfig(
    currencyFrom,
    currencyTo,
    amountExpectedFrom,
    !!nufiAddressFrom,
    partnerSwapId,
    status,
  )

  return (
    <ExchangeHistoryItemLayout
      {...{onClick}}
      createdAt={
        <Typography>
          <FormattedDateTime dateTime={createdAt} />
        </Typography>
      }
      from={
        <Grid container spacing={1}>
          <Grid item>
            <Typography color="textPrimary">
              <FormattedAmount
                expectedAmount={amountExpectedFrom}
                amount={amountFrom}
                canBeEstimate={false}
              />
            </Typography>
          </Grid>
          <Grid item>
            <Typography>{currencyFrom}</Typography>
          </Grid>
        </Grid>
      }
      to={
        <Grid container spacing={1}>
          <Grid item>
            <Typography color="textPrimary">
              <FormattedAmount
                expectedAmount={amountExpectedTo}
                amount={amountTo}
                canBeEstimate
              />
            </Typography>
          </Grid>
          <Grid item>
            <Typography>{currencyTo}</Typography>
          </Grid>
        </Grid>
      }
      status={
        <WithTooltip title={<Typography>{tooltip}</Typography>}>
          <LabeledIcon
            Icon={icon}
            Label={<Typography color="textPrimary">{title}</Typography>}
            spacing={0.5}
          />
        </WithTooltip>
      }
    />
  )
}
