import {Edit} from '@mui/icons-material'
import {Stack} from '@mui/material'
import BigNumber from 'bignumber.js'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {assert} from '../../../../../utils/assertion'
import type {
  EvmAccountOfflineInfo,
  EvmBlockchain,
  EvmWei,
  SimulateEvmTransaction,
} from '../../../../../wallet/evm'
import {simulateEvmTransaction as _simulateEvmTransaction} from '../../../../../wallet/evm'
import {useSignContext} from '../../SignContext'
import type {ExtraOptionsProps} from '../../types'
import {TxDetail} from '../common/details/TxDetail'
import {TxDataLayout} from '../common/TxDataLayout'
import type {BlockchainTxDataProps} from '../types'

import {
  CommonWarningMessages,
  GasInfoRow,
  GeneralRow,
  ValueFieldAlert,
} from './common'
import {SimulatedTxData} from './SimulatedTxData'
import {WithSimulationData} from './WithSimulationData'

export type EvmTxDataProps = {
  blockchain: EvmBlockchain
  data: BlockchainTxDataProps<EvmBlockchain>
  extraOptionsProps: ExtraOptionsProps | null
  // Can be supplied from outside to make testing easy
  simulateEvmTransaction?: SimulateEvmTransaction
}

export function EvmTxData({
  blockchain,
  data: {txParams},
  extraOptionsProps,
  simulateEvmTransaction = _simulateEvmTransaction,
}: EvmTxDataProps) {
  const {t} = useTranslation()
  const {selectedAccount} = useSignContext()
  assert(selectedAccount != null)

  const _value = txParams.value
  const value = new BigNumber(
    _value == null ? 0 : (_value as number | string),
  ) as EvmWei

  assert(
    extraOptionsProps != null && extraOptionsProps.type === blockchain,
    `Custom sign options must be defined for ${blockchain}`,
  )

  const {gasOptions, fee} = extraOptionsProps.data

  const commonWarningMessagesContent = (
    <CommonWarningMessages
      {...{blockchain, fee}}
      txValue={value}
      gasLimit={gasOptions.gasLimit}
    />
  )

  const gasActions = [
    {
      label: t('Edit gas settings'),
      onClick: extraOptionsProps.onShowOptions,
      icon: <Edit />,
    },
  ]

  return (
    <WithSimulationData {...{txParams, simulateEvmTransaction, blockchain}}>
      {(txSimulationResult) => {
        return txSimulationResult == null ? (
          // Fallback to simple UI in case the tx simulation fails
          <Stack rtl-data-test-id="evm-tx-data-no-simulation" spacing={1}>
            <TxDataLayout
              details={
                <TxDetail
                  blockchain={blockchain}
                  fee={fee}
                  nativeDiff={value.negated()}
                  customBottomRows={[
                    {
                      summary: t('General'),
                      details: (
                        <GeneralRow {...txParams} blockchain={blockchain} />
                      ),
                    },
                    {
                      summary: t('Gas settings'),
                      details: <GasInfoRow {...gasOptions} />,
                      actions: gasActions,
                    },
                  ]}
                />
              }
              rawTx={txParams.data as string}
            />
            <ValueFieldAlert blockchain={blockchain} />
            <div>{commonWarningMessagesContent}</div>
          </Stack>
        ) : (
          <SimulatedTxData
            {...{
              blockchain,
              txSimulationResult,
              fee,
              summaryExtraActions: gasActions,
            }}
            selectedAccount={
              selectedAccount as EvmAccountOfflineInfo<EvmBlockchain>
            }
            txParams={{...txParams, customGasOptions: gasOptions}}
            warningMessagesContent={commonWarningMessagesContent}
            gasActions={gasActions}
          />
        )
      }}
    </WithSimulationData>
  )
}
