import {Box, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {SimpleTimeline} from '../../../../components'
import {assert} from '../../../../utils/assertion'

type AddHotAccountTimelineProps = {
  step: 'blockchain' | 'choose-account' | 'name' | 'done'
}

export function AddHotAccountTimeline({step}: AddHotAccountTimelineProps) {
  const {t} = useTranslation()
  const stepMapping = {
    blockchain: t('Select blockchain'),
    'choose-account': t('Choose accounts'),
    name: t('Edit account names'),
    done: t('Done'),
  }
  return (
    <AddAccountTimeline
      {...{step, stepMapping}}
      header={t('Create new accounts')}
    />
  )
}

type PairHardwareAccountTimelineProps = {
  step: 'device' | 'blockchain' | 'pair-device' | 'name' | 'done'
}

export function PairHardwareAccountTimeline({
  step,
}: PairHardwareAccountTimelineProps) {
  const {t} = useTranslation()
  const stepMapping = {
    blockchain: t('Select blockchain'),
    device: t('Select device'),
    'pair-device': t('Pair device'),
    name: t('Edit account names'),
    done: t('Done'),
  }
  return (
    <AddAccountTimeline
      {...{step, stepMapping}}
      header={t('Pair hardware wallet')}
    />
  )
}

type Step =
  | 'device'
  | 'blockchain'
  | 'pair-device'
  | 'name'
  | 'done'
  | 'choose-account'

type AddAccountTimelineProps = {
  step: Step
  stepMapping: {[key in Step]?: string}
  header: string
}

export function AddAccountTimeline({
  step,
  stepMapping,
  header,
}: AddAccountTimelineProps) {
  const activeStep = stepMapping[step]
  assert(!!activeStep)
  return (
    <Box>
      <Typography variant="h5">{header}</Typography>
      <Box mt={3}>
        <SimpleTimeline
          steps={Object.values(stepMapping)}
          activeStep={activeStep}
        />
      </Box>
    </Box>
  )
}
