import {Box, styled, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import type {
  EvmAddress,
  EvmBlockchain,
  EvmContractAddress,
  EvmErc20Metadata,
  EvmERC20TokenList,
  EvmProfileTokenImportInfo,
} from '../../../../../wallet/evm'

import {AlertWithMargin, importEvmTokenTestIds} from './utils'

export function auditToken<TBlockchain extends EvmBlockchain>({
  blockchain,
  chainId,
  accountAddress,
  contractAddress,
  tokenList,
  profileTokenImports,
  tokenName,
  tokenTicker,
}: {
  blockchain: TBlockchain
  chainId: number
  accountAddress: EvmAddress<TBlockchain>
  contractAddress: EvmContractAddress<TBlockchain>
  tokenList: EvmERC20TokenList<TBlockchain>
  profileTokenImports: EvmProfileTokenImportInfo<TBlockchain>[]
  tokenName?: string
  tokenTicker?: string
}) {
  const isTooSimilar = (current: string | null, value?: string) => {
    if (
      value == null ||
      current == null ||
      value.trim() === '' ||
      current?.trim() === ''
    ) {
      return false
    }
    // This is a fairly naive check, but with anything more "fuzzy" we could get a lot
    // of false positives
    return (
      current?.toLocaleLowerCase().trim() === value.toLocaleLowerCase().trim()
    )
  }

  const tickerSimilarToWhiteList = Object.values<EvmErc20Metadata<TBlockchain>>(
    tokenList,
  ).some((t) => isTooSimilar(t.ticker, tokenTicker))

  const nameSimilarToWhiteList = Object.values<EvmErc20Metadata<TBlockchain>>(
    tokenList,
  ).some((t) => isTooSimilar(t.name, tokenName))

  // Revisit if refactoring TokenStoreManagerProvider
  const isTargetToken = (t: EvmProfileTokenImportInfo<TBlockchain>) =>
    !(contractAddress === t.token.contractAddress) &&
    t.owner === accountAddress &&
    t.token.blockchain === blockchain &&
    // user tokens are added per network
    chainId === t.token.chainId

  const tickerSimilarToProfileTokenImports = profileTokenImports.some(
    (t) => isTargetToken(t) && isTooSimilar(t.token.ticker, tokenTicker),
  )

  const nameSimilarToProfileTokenImports = profileTokenImports.some(
    (t) => isTargetToken(t) && isTooSimilar(t.token.name, tokenName),
  )

  return {
    tickerSimilarToWhiteList,
    nameSimilarToWhiteList,
    nameSimilarToProfileTokenImports,
    tickerSimilarToProfileTokenImports,
  }
}

export type TokenAuditResult = ReturnType<typeof auditToken>

type TokenAuditWarningsProps = {
  tokenAuditResult: TokenAuditResult
}

export function TokenAuditWarnings({
  tokenAuditResult,
}: TokenAuditWarningsProps) {
  const {t} = useTranslation()
  if (Object.values(tokenAuditResult).every((value) => value === false)) {
    return null
  }

  type WarningItem = {testId: string; message: string}

  const warnings: WarningItem[] = [
    tokenAuditResult.nameSimilarToProfileTokenImports && {
      message: t('evm_token_property_similar_to_profile_token', {
        property: t('name'),
      }),
      testId: importEvmTokenTestIds.audit.tokenNameSimilarToProfileTokenImport,
    },
    tokenAuditResult.tickerSimilarToProfileTokenImports && {
      message: t('evm_token_property_similar_to_profile_token', {
        property: t('symbol'),
      }),
      testId:
        importEvmTokenTestIds.audit.tokenSymbolSimilarToProfileTokenImport,
    },
    tokenAuditResult.nameSimilarToWhiteList && {
      message: t('evm_token_property_similar_to_whitelisted_token', {
        property: t('name'),
      }),
      testId: importEvmTokenTestIds.audit.tokenNameSimilarToWhitelistedToken,
    },
    tokenAuditResult.tickerSimilarToWhiteList && {
      message: t('evm_token_property_similar_to_whitelisted_token', {
        property: t('symbol'),
      }),
      testId: importEvmTokenTestIds.audit.tokenSymbolSimilarToWhitelistedToken,
    },
  ].filter((item): item is WarningItem => !!item)

  return (
    <AlertWithMargin
      severity="error"
      rtl-data-test-id={importEvmTokenTestIds.audit.warningWrapper}
    >
      <MessagesWrapper>
        {warnings.map(({message, testId}, index) => (
          <Typography key={index} rtl-data-test-id={testId}>
            {message}
          </Typography>
        ))}
      </MessagesWrapper>
    </AlertWithMargin>
  )
}

const MessagesWrapper = styled(Box)(({theme}) => ({
  '& > :not(:first-child)': {
    marginTop: theme.spacing(2),
  },
}))
