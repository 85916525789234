import type {ChipProps} from '@mui/material'
import {Chip} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'

import {colors} from '../theme/palette'

export function TestnetIndicator({size}: {size?: ChipProps['size']}) {
  const classes = useStyles()
  return (
    <Chip label="Testnet" size={size} className={classes.testnetIndicator} />
  )
}
const useStyles = makeStyles({
  testnetIndicator: {
    background: colors.warning.background,
    color: colors.warning.alertContent,
  },
})
