import {Sentry, sleep} from '@nufi/frontend-common'
import type {SolanaTransaction} from '@nufi/wallet-solana'
import {isVersionedTransaction} from '@nufi/wallet-solana'
import {PublicKey, Transaction, TransactionInstruction} from '@solana/web3.js'
import bs58 from 'bs58'

import type {SerializedTransaction} from 'src/webextension/dappConnector/connectors/solana/types'

import {assert} from '../../utils/assertion'
import {solana} from '../../wallet/solana/solanaManagers'
import {
  solanaDappConnectorApi,
  signTransaction,
  getAccountPublicKey,
  deserializeTx,
} from '../../webextension/dappConnector/connectors/solana'

import type {API, Handler} from './types'

// NOTE: return types and request types from https://docs.walletconnect.com/advanced/rpc-reference/solana-rpc#solana_signtransaction

type TxInstruction = {
  data: number[]
  keys: {
    pubkey: string
    isSigner: boolean
    isWritable: boolean
  }[]
  programId: string
}

type TxParams = {
  feePayer?: string
  instructions?: TxInstruction[]
  recentBlockhash?: string
  signatures?: {
    pubkey: string
    signature: string
  }[]
  transaction?: string
}

const getSignaturesFromTx = (tx: SolanaTransaction) => {
  return (
    isVersionedTransaction(tx)
      ? tx.signatures.map((sig) => Buffer.from(sig))
      : tx.signatures.map((sig) => sig.signature)
  )
    .filter((sig): sig is Buffer => !!sig)
    .map((sig) => sig.toString('hex'))
}

function assertIsTxParams(params: unknown): asserts params is TxParams {
  if (!params || typeof params !== 'object') {
    assert(false, 'Invalid tx params')
  }
  if ('transaction' in params && typeof params.transaction === 'string') {
    return
  }
  if ('instructions' in params && Array.isArray(params.instructions)) {
    return
  }
  assert(false, 'Invalid tx params')
}

const parseInstruction = (
  instruction: TxInstruction,
): TransactionInstruction => {
  return new TransactionInstruction({
    data: Buffer.from(instruction.data),
    keys: instruction.keys.map(({pubkey, isSigner, isWritable}) => ({
      pubkey: new PublicKey(pubkey),
      isSigner,
      isWritable,
    })),
    programId: new PublicKey(instruction.programId),
  })
}

const parseTransaction = (txParams: TxParams): SolanaTransaction => {
  if (txParams.transaction) {
    return deserializeTx(txParams.transaction as SerializedTransaction)
  }

  const feePayer = txParams.feePayer
    ? new PublicKey(txParams.feePayer)
    : undefined
  const signatures = (txParams.signatures || [])
    // some dapps send also the signature for the user, which has the pubKey
    // but has signature field `null`
    .filter(({pubkey, signature}) => pubkey && signature)
    .map(({pubkey, signature}) => ({
      publicKey: new PublicKey(pubkey),
      signature: Buffer.from(signature),
    }))
  const tx = new Transaction({
    ...txParams,
    feePayer,
    signatures,
    recentBlockhash: txParams.recentBlockhash,
  })
  if (txParams.instructions) {
    txParams.instructions.forEach((i) => tx.add(parseInstruction(i)))
  }
  return tx
}

type MessageParams = {
  message: string
  pubkey: string
}

function assertIsMessageParams(
  params: unknown,
): asserts params is MessageParams {
  assert(
    !!(
      params &&
      typeof params === 'object' &&
      'message' in params &&
      'pubkey' in params
    ),
    'Invalid message params',
  )
}

type MethodReturnTypes = {
  solana_signMessage: Promise<{signature: string}>
  solana_signTransaction: Promise<{signature: string}>
  solana_getAccounts: Promise<{pubkey: string}[]>
  solana_requestAccounts: Promise<{pubkey: string}[]>
}

const api: API<'solana', MethodReturnTypes> = () => {
  return {
    solana_signMessage: async (params) => {
      assertIsMessageParams(params)
      const signature = Buffer.from(
        await solanaDappConnectorApi.signMessage([
          ...Buffer.from(bs58.decode(params.message)),
        ]),
      )
      return {signature: bs58.encode(signature)}
    },
    solana_signTransaction: async (params) => {
      assertIsTxParams(params)
      const unsignedTx = parseTransaction(params)

      // we get the pre-existing signatures from the tx so we can later find the new signature
      // which needs to be returned
      const preExistingSignatures = getSignaturesFromTx(unsignedTx)

      const {signedTx, forceTxSending} = await signTransaction({
        unsignedTx,
        requestType: 'sign',
      })

      const newSignature = getSignaturesFromTx(signedTx).find(
        (sig) => !preExistingSignatures.includes(sig),
      )

      assert(!!newSignature)

      // TMP hack as it seems several DApps have their own tx submission
      // after signing broken, so we just submit the tx with some delay
      // to give the DApp a chance to send it first (just in case)
      if (forceTxSending) {
        sleep(3000)
          .then(() =>
            solana.accountManager.simulateTransaction(signedTx, {
              accountPubKeys: [],
              verifySignatures: true,
            }),
          )
          .then(async (res) => {
            if (!res.value.err) {
              await solana.accountManager.sendAndConfirmTransaction(signedTx)
            }
          })
          .catch((e) => Sentry.captureException(e))
      }

      return {signature: bs58.encode(Buffer.from(newSignature, 'hex'))}
    },
    // yes, solana_getAccounts and solana_requestAccounts have the same interface
    solana_getAccounts: async () => {
      return [{pubkey: getAccountPublicKey().toBase58()}]
    },
    solana_requestAccounts: async () => {
      return [{pubkey: getAccountPublicKey().toBase58()}]
    },
  }
}

export const solanaHandler: Handler<'solana'> = (chain) => {
  return (method, params) => api(chain)[method](params)
}
