import {Box, Divider} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import type {
  FlowAccountInfo,
  FlowDelegationInfo,
  FlowUpdateDelegatorTxType,
  Nanoflow,
} from '@nufi/wallet-flow'
import type {FormikProps} from 'formik'
import React from 'react'
import {useTranslation} from 'react-i18next'

import type {StakingBlockchain} from '../../../../../blockchainTypes'
import {
  AmountRow,
  CurrentPasswordField,
  FooterLayout,
  ModalFooter,
  ModalLayout,
  StakeTransitionCardSummary,
  useModalSharedStyles,
} from '../../../../../components'
import {useIsPasswordVerificationRequired} from '../../../../../store/auth'
import type {SummarySchema} from '../schemas'

import {
  SummaryScreenAmountHeading,
  TransitionCardSourceContentProps,
  TransitionCardTargetContent,
  UpdateDelegatorTotalField,
} from './utils'

type SummaryScreenProps<T extends SummarySchema> = {
  blockchain: StakingBlockchain
  onBack: () => unknown
  onSubmit: () => unknown
  summary: {
    fee: Nanoflow
    amount: Nanoflow
    fromAccount: FlowAccountInfo
    delegation: FlowDelegationInfo
  }
  formikProps: FormikProps<T>
  txType: FlowUpdateDelegatorTxType
  ModalHeader: React.ReactNode
}

const FORM_ID = 'update-delegator-summary-form'

export default function SummaryScreen<T extends SummarySchema>({
  onBack,
  onSubmit,
  blockchain,
  summary,
  formikProps,
  txType,
  ModalHeader,
}: SummaryScreenProps<T>) {
  const {t} = useTranslation()
  const isPasswordVerificationRequired = useIsPasswordVerificationRequired(
    summary.fromAccount,
  )
  const classes = {...useStyles(), ...useModalSharedStyles()}

  return (
    <form
      onSubmit={onSubmit}
      noValidate
      id={FORM_ID}
      className={classes.formWrapper}
    >
      <ModalLayout
        header={ModalHeader}
        body={
          <Box p={2}>
            <StakeTransitionCardSummary
              {...{...summary, blockchain}}
              sourceContent={
                // txType specific
                <TransitionCardSourceContentProps
                  txType={txType}
                  delegation={summary.delegation}
                  accountInfo={summary.fromAccount}
                />
              }
              targetContent={
                // txType specific
                <TransitionCardTargetContent
                  txType={txType}
                  accountInfo={summary.fromAccount}
                  delegation={summary.delegation}
                />
              }
            />
            <Box className={classes.commonTopMargin}>
              <AmountRow
                label={
                  // txType specific
                  <SummaryScreenAmountHeading {...{txType}} />
                }
                amount={summary.amount}
                blockchain={blockchain}
              />
              <AmountRow
                label={t('Network fee')}
                amount={summary.fee}
                blockchain={blockchain}
              />
              {['withdrawRewards', 'withdrawUnstaked'].includes(txType) && (
                <Divider className={classes.divider} />
              )}
              {/* txType specific */}
              <UpdateDelegatorTotalField
                txType={txType}
                amount={summary.amount}
                fee={summary.fee}
              />
            </Box>
          </Box>
        }
        footer={
          <ModalFooter hasDivider>
            {isPasswordVerificationRequired && (
              <CurrentPasswordField
                {...{formikProps}}
                className={classes.passwordHeight}
              />
            )}
            <FooterLayout
              leftBtnConf={{
                onClick: onBack,
                children: t('Back'),
              }}
              rightBtnConf={{
                children: t('Submit'),
                type: 'submit',
                form: FORM_ID,
                disabled: formikProps.isSubmitting,
              }}
            />
          </ModalFooter>
        }
      />
    </form>
  )
}

const useStyles = makeStyles((theme) => ({
  validatorId: {
    flex: 2,
    paddingLeft: theme.spacing(1),
    width: '10vw',
  },
  alert: {
    marginTop: theme.spacing(2),
  },
}))
