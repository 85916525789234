import React, {useContext, useRef} from 'react'

import type {ExchangeBlockchain} from '../../blockchainTypes'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type MetaData = Partial<Record<ExchangeBlockchain, any>>

type MetaContextType<T> = {
  setNonReactiveMetadata: (blockchain: ExchangeBlockchain, data: T) => void
  getNonReactiveMetadata: (blockchain: ExchangeBlockchain) => T
}

export const MetaContext = React.createContext({} as MetaContextType<unknown>)

/**
 * Used for sharing blockchain specific information between injected parts
 * of ExchangeModal. Currently the data is stored in `ref` as we do not need
 * them reactive which saves unnecessary re-render. If needed you can however
 * also store some other data in local state. The `ExchangeModal` is not aware
 * about what is stored in this data, it just provides them for injected
 * blockchain specific components.
 */
export const CustomMetadataContainer = ({
  children,
}: {
  children: React.ReactElement
}) => {
  const meta = useRef<MetaData>({})

  function setNonReactiveMetadata<T>(blockchain: ExchangeBlockchain, data: T) {
    if (!(blockchain in meta.current)) {
      meta.current[blockchain] = {}
    }
    meta.current[blockchain] = data
  }

  function getNonReactiveMetadata<T>(blockchain: ExchangeBlockchain): T {
    return meta.current[blockchain]
  }

  return (
    <MetaContext.Provider
      value={{setNonReactiveMetadata, getNonReactiveMetadata}}
    >
      {children}
    </MetaContext.Provider>
  )
}

export function useCustomMetadata<T>() {
  return useContext(MetaContext) as MetaContextType<T>
}
