import {getIsWebExtension} from '@nufi/frontend-common'

import config from 'src/config'

import {WEB3_AUTH_LOGIN_PROVIDERS} from '../services'

export const useAvailableExternalLoginProviders = () => {
  return {
    web3Auth: WEB3_AUTH_LOGIN_PROVIDERS,
    metamask: !getIsWebExtension() && config.isSnapEnabled,
  }
}
