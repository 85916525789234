import {
  initCloudSync,
  initWalletStoreManagers,
} from 'src/appStorage/initCoreServices'
import MasterProfileManager from 'src/appStorage/masterProfileManager'
import type {
  LocalProfileId,
  LocalProfileLoginType,
  ProfilePassword,
} from 'src/appStorage/types'
import type {Web3AuthUserInfo} from 'src/features/login/domain'
import {INIT_WALLET_CONFIGURATIONS} from 'src/features/walletStorage/application'
import type {AccountsStorageServiceInitializeArgs} from 'src/features/walletStorage/domain'
import queryClient from 'src/queryClient'
import type {Blockchain} from 'src/types'
import {useMutation} from 'src/utils/mutation-utils'

const commonDappConnectorInit = async (
  args: AccountsStorageServiceInitializeArgs,
) => {
  await initWalletStoreManagers(args)
  initCloudSync()
}

export const useInitDappConnectorProfile = (
  blockchain: Blockchain,
  loginType: LocalProfileLoginType,
) =>
  useMutation(
    'initDappConnectorProfile',
    async (args: {profileId: LocalProfileId; password: ProfilePassword}) => {
      await MasterProfileManager.initLocalProfile(args)
      await commonDappConnectorInit(
        INIT_WALLET_CONFIGURATIONS.getLoginToConnectorArgs(
          blockchain,
          loginType,
        ),
      )
      // Make sure to reload queries belonging to previous profile, as profile change is allowed
      await queryClient.refetchQueries({}, {cancelRefetch: false})
    },
  )

export const useTryAutoLoginIntoDappConnectorProfile = (
  blockchain: Blockchain,
) =>
  useMutation(
    'tryAutoLoginIntoDappConnectorProfile',
    async (
      args:
        | {
            profileId: LocalProfileId
            password: ProfilePassword
            web3AuthUserInfo?: Web3AuthUserInfo | undefined
          }
        | undefined,
    ) => {
      if (args) {
        try {
          await MasterProfileManager.initLocalProfile(args)
        } catch {
          return {success: false}
        }
        await commonDappConnectorInit(
          INIT_WALLET_CONFIGURATIONS.getLoginToConnectorArgs(
            blockchain,
            'password',
          ),
        )
        return {success: true, web3AuthUserInfo: args.web3AuthUserInfo}
      }
      return {success: false}
    },
  )
