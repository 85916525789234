// Kept in .ts file so it can be imported from service-worker

import config from '../../../config'

const cardanoTxExplorerLink = {
  mainnet: 'https://cexplorer.io/tx/',
  preprod: 'https://preprod.cexplorer.io/tx/',
  preview: 'https://preview.cexplorer.io/tx/',
  // TODO sanchonet
  sanchonet: 'https://sancho.cexplorer.io/tx/',
}[config.cardanoNetwork]

export const getCardanoTransactionExplorerLink = (txId: string) =>
  `${cardanoTxExplorerLink}${txId}`

const cardanoAddressExplorerLink = {
  mainnet: 'https://cexplorer.io/address/',
  preprod: 'https://preprod.cexplorer.io/address/',
  preview: 'https://preview.cexplorer.io/address/',
  // TODO sanchonet
  sanchonet: 'https://sancho.cexplorer.io/address/',
}[config.cardanoNetwork]

export const getCardanoAddressExplorerLink = (address: string) =>
  `${cardanoAddressExplorerLink}${address}`
