import type {EvmBlockchain} from '@nufi/wallet-evm'
import {evmBlockchains} from '@nufi/wallet-evm'

import {isBlockchainAvailable} from 'src/features/availableBlockchains/application'
import {useGetIsBlockchainEnabled} from 'src/features/profile/application/enabledBlockchains'

export const getAvailableEvmBlockchains = (): EvmBlockchain[] =>
  evmBlockchains.filter((evmBlockchain) => isBlockchainAvailable(evmBlockchain))

export const useEnabledEvmBlockchains = (): EvmBlockchain[] => {
  const isBlockchainEnabled = useGetIsBlockchainEnabled()
  return evmBlockchains.filter((evmBlockchain) =>
    isBlockchainEnabled(evmBlockchain),
  )
}
