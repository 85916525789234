import React, {useContext} from 'react'

import type {ExchangeDepsConfig} from './screens/common'

const ExchangeConfContext = React.createContext(
  {} as {exchangeConf: ExchangeDepsConfig},
)

export const ExchangeConfContextProvider = ({
  children,
  exchangeConf,
}: {
  exchangeConf: ExchangeDepsConfig
  children: React.ReactElement
}) => {
  return (
    <ExchangeConfContext.Provider value={{exchangeConf}}>
      {children}
    </ExchangeConfContext.Provider>
  )
}

export const useExchangeConf = () => useContext(ExchangeConfContext)
