import React from 'react'

import {safeAssertUnreachable} from '../../../utils/assertion'
import type {TokenMetadata} from '../../../wallet'
import {isEvmBlockchain} from '../../../wallet/evm'
import {CardanoTokenDetailInfo} from '../cardano/TokenDetailInfo'
import {EvmTokenDetailInfo} from '../evm/TokenDetailInfo'
import {FlowTokenDetailInfo} from '../flow/TokenDetailInfo'
import {SolanaTokenDetailInfo} from '../solana/TokenDetailInfo'

import type {NftInfo} from './types'

type TokenInfoProps = {
  token: TokenMetadata
  nftInfo: NftInfo
}

export function TokenDetailInfo({token, nftInfo}: TokenInfoProps) {
  switch (token.blockchain) {
    case 'cardano':
      return <CardanoTokenDetailInfo {...{token, nftInfo}} />
    case 'solana':
      return <SolanaTokenDetailInfo {...{token, nftInfo}} />
    case 'flow':
      return <FlowTokenDetailInfo {...{token, nftInfo}} />
    default: {
      if (isEvmBlockchain(token.blockchain)) {
        return (
          <EvmTokenDetailInfo
            blockchain={token.blockchain}
            {...{token, nftInfo}}
          />
        )
      }
      return safeAssertUnreachable(token.blockchain)
    }
  }
}
