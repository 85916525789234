import {
  Box,
  Typography,
  Grid,
  Divider,
  FormGroup,
  FormControlLabel,
  FormHelperText,
  Checkbox,
  Link,
} from '@mui/material'
import {makeStyles} from '@mui/styles'
import BigNumber from 'bignumber.js'
import type {FormikHelpers, FormikProps} from 'formik'
import {Formik} from 'formik'
import React from 'react'
import {Trans, useTranslation} from 'react-i18next'
import * as yup from 'yup'

import {isExchangeBlockchain} from 'src/blockchainTypes'
import type {
  ExchangeAssetsDetails,
  ExchangeConditions,
} from 'src/features/exchange/domain'

import {
  ModalLayout,
  ModalFooter,
  FooterLayout,
  QueryGuard,
  useModalSharedStyles,
  CenteredError,
  CurrentPasswordField,
} from '../../../../components'
import {externalLinks} from '../../../../constants'
import {
  useCurrentLoginInfo,
  useIsPasswordVerificationRequired,
} from '../../../../store/auth'
import {getHasFormError} from '../../../../utils/form'
import type {AccountInfo} from '../../../../wallet'
import {
  ensureAccountByAddress,
  findAccountByAddress,
} from '../../../../wallet/utils/common'
import {assetBaseUnitToMain} from '../../../../wallet/utils/parseUtils'
import {isPasswordVerificationRequired} from '../../../utils'
import {
  ExchangeModalHeader,
  ExtraIdMessage,
  ExtraIdHelp,
  ExchangeCardSummary,
  FormattedExchangeRate,
  FormattedExchangeAmount,
  WithExchangeAssetsQueries,
} from '../../components'
import {blockchainToNativeSwapAsset} from '../../constants'
import {useExchangeConf} from '../../ExchangeConfContext'
import type {ExchangeModalViewModel} from '../../exchangeModalViewModel'
import {useExchangeModalViewModel} from '../../exchangeModalViewModel'
import type {AccountConf} from '../../types'
import {ExchangeAssetNotification} from '../common'
import type {WithSummaryTxFeeType} from '../common'
import type {DetailsValues} from '../details/schema'

import type {SummaryValues} from './schema'
import {useSummarySchema} from './schema'

type ViewModel = Pick<
  ExchangeModalViewModel,
  'useGetAccounts' | 'useVerifyPassword' | 'useGetExchangeConditions'
>

type SummaryScreenProps = {
  onClose: () => void
  onSubmit: () => Promise<void>
  onBack: () => unknown
  prevScreensData: DetailsValues
  initialData: SummaryValues | null
  exchangeAssetsDetails: ExchangeAssetsDetails
  vm: ViewModel
}

const FORM_ID = 'exchange-form-confirm-summary'

const ExternalWithSummaryTxFee: WithSummaryTxFeeType = ({children}) =>
  children({fee: null})

export function SummaryScreen({
  prevScreensData,
  onClose,
  ...restProps
}: SummaryScreenProps) {
  const {exchangeConf} = useExchangeConf()
  const vm = useExchangeModalViewModel()
  return (
    <WithExchangeAssetsQueries
      fromAsset={prevScreensData.fromAsset}
      toAsset={prevScreensData.toAsset}
      exchangeAssetsDetails={restProps.exchangeAssetsDetails}
      vm={vm}
      childrenPropsType="guardedData"
    >
      {({
        from: {
          internalBlockchain: internalFromBlockchain,
          accounts: fromAccounts,
        },
        to: {accounts: toAccounts},
      }) => {
        const WithSummaryTxFee =
          internalFromBlockchain != null
            ? exchangeConf[internalFromBlockchain].WithSummaryTxFee
            : ExternalWithSummaryTxFee

        return (
          <WithSummaryTxFee
            blockchain={internalFromBlockchain}
            fromExternal={prevScreensData.fromAddressType === 'external'}
          >
            {({fee: rawFee}) => {
              const parsedFee =
                internalFromBlockchain != null && rawFee != null
                  ? new BigNumber(
                      assetBaseUnitToMain(internalFromBlockchain, rawFee),
                    )
                  : null

              return (
                <_SummaryScreen
                  networkFee={parsedFee}
                  {...{onClose, toAccounts, fromAccounts, prevScreensData}}
                  {...restProps}
                />
              )
            }}
          </WithSummaryTxFee>
        )
      }}
    </WithExchangeAssetsQueries>
  )
}

function _SummaryScreen({
  prevScreensData,
  initialData,
  onClose,
  onSubmit: _onSubmit,
  onBack,
  networkFee,
  fromAccounts,
  toAccounts,
  exchangeAssetsDetails,
  vm,
}: Omit<SummaryScreenProps, 'exchangeConf'> & {
  networkFee: BigNumber | null
  fromAccounts: AccountInfo[]
  toAccounts: AccountInfo[]
}) {
  const initialValues: SummaryValues = initialData || {
    password: '',
    agreement: false,
  }

  const toAssetBlockchain =
    exchangeAssetsDetails[prevScreensData.toAsset]?.blockchain
  const fromAssetBlockchain =
    exchangeAssetsDetails[prevScreensData.fromAsset]?.blockchain

  const fromAccount =
    prevScreensData.fromAddressType === 'internal' &&
    isExchangeBlockchain(fromAssetBlockchain)
      ? findAccountByAddress(
          fromAccounts,
          prevScreensData.fromAddress,
          fromAssetBlockchain,
        ) ?? null
      : null

  const summarySchema = useSummarySchema({fromAccount})
  const verifyPassword = vm.useVerifyPassword()
  const loginInfo = useCurrentLoginInfo()

  const validationSchema = yup.object().shape(summarySchema)

  const onSubmit = async (
    values: SummaryValues,
    formikHelpers: FormikHelpers<SummaryValues>,
  ) => {
    if (fromAccount) {
      if (isPasswordVerificationRequired(loginInfo, fromAccount)) {
        const passwordVerified = await verifyPassword(
          values.password,
          formikHelpers,
        )
        if (!passwordVerified) return
      }
    }
    _onSubmit()
  }

  return (
    <Formik {...{initialValues, onSubmit, validationSchema}}>
      {(formikProps) => (
        <SummaryScreenForm
          {...{
            formikProps,
            onClose,
            onBack,
            prevScreensData,
            toAccounts,
            fromAccounts,
            networkFee,
            exchangeAssetsDetails,
            fromAccount,
            vm,
          }}
          toConf={{
            address: prevScreensData.toAddress,
            addressType: prevScreensData.toAddressType,
            account:
              prevScreensData.toAddressType === 'internal' &&
              isExchangeBlockchain(toAssetBlockchain)
                ? ensureAccountByAddress(
                    toAccounts,
                    prevScreensData.toAddress,
                    toAssetBlockchain,
                  )
                : undefined,
          }}
          fromConf={{
            address: prevScreensData.fromAddress,
            addressType: prevScreensData.fromAddressType,
            account:
              prevScreensData.fromAddressType === 'internal' &&
              isExchangeBlockchain(fromAssetBlockchain)
                ? ensureAccountByAddress(
                    fromAccounts,
                    prevScreensData.fromAddress,
                    fromAssetBlockchain,
                  )
                : undefined,
          }}
          fromAmount={new BigNumber(prevScreensData.amount)}
        />
      )}
    </Formik>
  )
}

type SummaryScreenFormProps = {
  onClose: () => void
  onBack: () => unknown
  formikProps: FormikProps<SummaryValues>
  prevScreensData: DetailsValues
  fromAccount: AccountInfo | null
  fromConf: AccountConf
  toConf: AccountConf
  fromAmount: ExchangeConditions['amountFrom']
  networkFee: BigNumber | null
  exchangeAssetsDetails: ExchangeAssetsDetails
  vm: ViewModel
}

function SummaryScreenForm({
  onBack,
  onClose,
  formikProps,
  prevScreensData,
  fromAccount,
  toConf,
  fromConf,
  fromAmount,
  networkFee,
  exchangeAssetsDetails,
  vm,
}: SummaryScreenFormProps) {
  const {t} = useTranslation()
  const classes = {...useModalSharedStyles(), ...useStyles()}
  const {handleSubmit, values, handleChange, errors} = formikProps
  const exchangeConditionsQuery = vm.useGetExchangeConditions({
    from: prevScreensData.fromAsset,
    to: prevScreensData.toAsset,
    amountFrom: new BigNumber(prevScreensData.amount),
  })

  type FormField = keyof typeof values
  const hasError = getHasFormError(formikProps, 'after-submit')

  const networkFeeInfo =
    !!networkFee && fromConf.account
      ? {
          amount: networkFee,
          swapAsset: blockchainToNativeSwapAsset(fromConf.account.blockchain),
        }
      : null

  const needsPw = useIsPasswordVerificationRequired(fromAccount)

  const extraIdName =
    exchangeAssetsDetails[prevScreensData.toAsset]!.extraIdName

  return (
    <form
      onSubmit={handleSubmit}
      noValidate
      id={FORM_ID}
      className={classes.formWrapper}
    >
      <ModalLayout
        header={<ExchangeModalHeader onClose={onClose} />}
        body={
          <QueryGuard
            {...exchangeConditionsQuery}
            ErrorElement={
              <CenteredError error={t('Could not load exchange data')} />
            }
          >
            {(exchange) => (
              <Box p={2}>
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <ExchangeCardSummary
                      {...{exchangeAssetsDetails}}
                      fromAsset={prevScreensData.fromAsset}
                      toAsset={prevScreensData.toAsset}
                      fromAmount={fromAmount}
                      toConf={toConf}
                      fromConf={fromConf}
                      toAmount={exchange.amountTo}
                    />
                  </Grid>
                  <Grid item>
                    <Grid container direction="column" spacing={1}>
                      {extraIdName != null && (
                        <Grid
                          item
                          container
                          direction="row"
                          justifyContent="space-between"
                        >
                          <Grid item>
                            <ExtraIdHelp
                              extraIdName={extraIdName}
                              size="small"
                              includeLabel
                            />
                          </Grid>
                          <Grid item>
                            <Typography>{`${
                              prevScreensData.extraId || '-'
                            }`}</Typography>
                          </Grid>
                        </Grid>
                      )}
                      {networkFeeInfo && (
                        <Grid
                          item
                          container
                          direction="row"
                          justifyContent="space-between"
                        >
                          <Grid item>
                            <Typography color="textSecondary">
                              {t('Network fee')}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <FormattedExchangeAmount
                              amount={networkFeeInfo.amount}
                              swapAssetDetail={
                                exchangeAssetsDetails[networkFeeInfo.swapAsset]!
                              }
                              includeAssetSymbol
                              isEstimate
                              isSensitiveInformation={false}
                            />
                          </Grid>
                        </Grid>
                      )}
                      <Grid
                        item
                        container
                        direction="row"
                        justifyContent="space-between"
                      >
                        <Grid item>
                          <Typography color="textSecondary">
                            {t('Exchange rate')}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography>
                            <FormattedExchangeRate
                              exchangeRate={exchange.rate}
                              fromAsset={exchange.fromAsset}
                              toAsset={exchange.toAsset}
                            />
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        container
                        direction="row"
                        justifyContent="space-between"
                      >
                        <Grid item>
                          <Typography color="textSecondary">
                            {t('Exchange fee')}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <FormattedExchangeAmount
                            amount={exchange.fee.plus(exchange.networkFee)}
                            swapAssetDetail={
                              exchangeAssetsDetails[exchange.toAsset]!
                            }
                            includeAssetSymbol
                            isEstimate
                            isSensitiveInformation={false}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {extraIdName != null && !prevScreensData.extraId && (
                    <Grid item>
                      <ExchangeAssetNotification>
                        <ExtraIdMessage
                          extraIdName={extraIdName}
                          asset={prevScreensData.toAsset}
                        />
                      </ExchangeAssetNotification>
                    </Grid>
                  )}
                  <Grid item>
                    <Divider />
                  </Grid>
                  <Grid item>
                    <Grid
                      item
                      container
                      direction="row"
                      justifyContent="space-between"
                    >
                      <Grid item>
                        <Grid container direction="column">
                          <Typography variant="h6">{t('Total')}</Typography>
                          <Typography variant="body2" color="textSecondary">
                            {networkFeeInfo?.swapAsset === exchange.fromAsset
                              ? t('(Amount + Network fee)')
                              : t(
                                  '(Payin transaction network fee is not included)',
                                )}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Grid
                          container
                          direction="column"
                          alignItems="flex-end"
                        >
                          <Typography variant="h6">
                            <FormattedExchangeAmount
                              amount={
                                networkFeeInfo?.swapAsset === exchange.fromAsset
                                  ? fromAmount.plus(networkFeeInfo.amount)
                                  : fromAmount
                              }
                              swapAssetDetail={
                                exchangeAssetsDetails[exchange.fromAsset]!
                              }
                              includeAssetSymbol
                              isEstimate={
                                networkFeeInfo?.swapAsset !== exchange.fromAsset
                              }
                              isSensitiveInformation={false}
                            />
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            )}
          </QueryGuard>
        }
        footer={
          <ModalFooter hasDivider>
            <FormGroup className={classes.formField}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.agreement}
                    onChange={handleChange<FormField>('agreement')}
                  />
                }
                label={
                  <>
                    <Trans
                      i18nKey="changelly_terms_of_use"
                      t={t}
                      components={{
                        RestrictedCountriesLink: (
                          <Link
                            href={externalLinks.changellyRestrictedCountries}
                            target="_blank"
                            rel="noopener"
                          />
                        ),
                        TermsLink: (
                          <Link
                            href={externalLinks.changellyTermsOfUse}
                            target="_blank"
                            rel="noopener"
                          />
                        ),
                      }}
                    />
                  </>
                }
              />
              {hasError('agreement') && (
                <FormHelperText className={classes.error}>
                  {errors.agreement}
                </FormHelperText>
              )}
            </FormGroup>
            {needsPw && (
              <CurrentPasswordField
                {...{formikProps}}
                className={classes.passwordHeight}
              />
            )}
            <FooterLayout
              leftBtnConf={{
                onClick: onBack,
                children: t('Back'),
              }}
              rightBtnConf={{
                variant: 'contained',
                form: FORM_ID,
                type: 'submit',
                children: t('Exchange'),
              }}
            />
          </ModalFooter>
        }
      />
    </form>
  )
}

const useStyles = makeStyles((theme) => ({
  error: {
    color: theme.palette.error.main,
  },
}))
