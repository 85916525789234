import {safeAssertUnreachable} from '@nufi/frontend-common'
import React from 'react'

import type {CryptoProviderType} from '../../../wallet'
import {isHotVendor} from '../../../wallet'
import type {EvmBlockchain} from '../../../wallet/evm'

import {AddGridPlusAccount} from './AddGridPlusAccount'
import {AddHotAccount} from './AddHotAccount'
import {AddLedgerAccount} from './AddLedgerAccount'
import {AddTrezorAccount} from './AddTrezorAccount'

type AddEvmAccountProps<TBlockchain extends EvmBlockchain> = {
  blockchain: TBlockchain
  cryptoProviderType: CryptoProviderType
}

const AddEvmAccount = <TBlockchain extends EvmBlockchain>({
  blockchain,
  cryptoProviderType,
}: AddEvmAccountProps<TBlockchain>) => {
  if (isHotVendor(cryptoProviderType)) {
    return <AddHotAccount {...{blockchain, cryptoProviderType}} />
  }
  switch (cryptoProviderType) {
    case 'ledger':
      return <AddLedgerAccount blockchain={blockchain} />
    case 'trezor':
      return <AddTrezorAccount blockchain={blockchain} />
    case 'gridPlus':
      return <AddGridPlusAccount blockchain={blockchain} />
    default:
      return safeAssertUnreachable(cryptoProviderType)
  }
}

export default AddEvmAccount
