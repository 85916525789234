import {Box} from '@mui/material'
import {Sentry} from '@nufi/frontend-common'
import React, {useEffect, useState} from 'react'

import {DappCreateCardanoCollateralScreen} from 'src/dappConnector/pages/blockchains/cardano/createCollateral'
import {ChangeEvmChain} from 'src/dappConnector/pages/blockchains/evm/changeChain'
import {ImportEvmToken} from 'src/dappConnector/pages/blockchains/evm/importToken'

import {AppWrapper} from '../../AppWrapper'
import {ConnectorWindowLayout} from '../../dappConnector/ConnectorWindowLayout'
import {IdleScreen} from '../../dappConnector/pages/Idle'
import {InitScreen} from '../../dappConnector/pages/init'
import {DappConnectorSignScreen} from '../../dappConnector/pages/sign'
import {useQueryParams} from '../../router'
import {useDappConnectorStore} from '../../store/dappConnector'
import {PageViewTracking, initializeTracking} from '../../tracking'
import {safeAssertUnreachable} from '../../utils/assertion'
import {onSessionProposal, onSessionRequest} from '../connectionEventHandlers'
import {PROJECT_ID, PROJECT_METADATA} from '../constants'
import type {WalletConnectError} from '../core/hooks'
import useInitWalletConnect, {
  useWalletConnectEventsManager,
} from '../core/hooks'
import {redirectToRootIfNotWalletConnectRedirect} from '../utils'

import {ConnectingScreen} from './ConnectingScreen'
import {ErrorScreen} from './ErrorScreen'
import {useBeforeUnload} from './utils'

export function WalletConnectConnectorWindow() {
  return (
    <AppWrapper>
      <ConnectorWindowLayout hideLayoutContentForScreens={['splash', 'error']}>
        <App />
      </ConnectorWindowLayout>
    </AppWrapper>
  )
}

function App() {
  const {screenInfo, setErrorScreen} = useDappConnectorStore()
  const queryParams = useQueryParams()

  const [analyticsInitialized, setAnalyticsInitialized] = useState(false)

  useEffect(() => redirectToRootIfNotWalletConnectRedirect())

  useEffect(() => {
    initializeTracking()
    setAnalyticsInitialized(true)
  }, [])

  const onError = async (error: WalletConnectError) => {
    setErrorScreen(error)
    const {origin, e, ...rest} = error.params
    Sentry.captureException(error, {
      tags: {origin},
      extra: {originalError: e, context: JSON.stringify(rest)},
    })
  }

  const initialized = useInitWalletConnect(
    PROJECT_ID,
    PROJECT_METADATA,
    onError,
  )

  const {disconnectSession} = useWalletConnectEventsManager({
    initialized,
    uri: queryParams.uri as string,
    onSessionProposal,
    onSessionRequest,
    onSessionDelete: async () => {
      window.close()
    },
    onError,
  })

  // NOTE: this works only if the window is closed by the user, if the window crashes the
  // session might not get disconnected
  useBeforeUnload(() => disconnectSession('USER_DISCONNECTED'))

  if (!analyticsInitialized) {
    return null
  }

  const Screen = (() => {
    switch (screenInfo.state) {
      case 'init':
        return InitScreen
      case 'sign-tx':
      case 'sign-message':
      case 'sign-vote':
      case 'get-hw-wallet-public-keys':
        return DappConnectorSignScreen
      case 'create-cardano-collateral':
        return DappCreateCardanoCollateralScreen
      case 'idle':
        return IdleScreen
      case 'splash':
        return ConnectingScreen
      case 'change-evm-chain':
        return ChangeEvmChain
      case 'import-evm-token':
        return ImportEvmToken
      case 'error': {
        return ErrorScreen
      }
      default:
        return safeAssertUnreachable(screenInfo)
    }
  })()

  return (
    <>
      <PageViewTracking />
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        p={4}
        minWidth={450}
        height="100%"
      >
        <Screen />
      </Box>
    </>
  )
}
