import {SwapHoriz as ExchangeIcon} from '@mui/icons-material'
import {Grid, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {ModalHeader} from '../../../components'

import {ExchangeHistorySyncNote} from './ExchangeHistorySyncNote'

type ExchangeModalHeaderProps = {
  onClose: () => void
  title?: string
}

export const ExchangeModalHeader = ({
  onClose,
  title,
}: ExchangeModalHeaderProps) => {
  const {t} = useTranslation()
  return (
    <>
      <ModalHeader onClose={onClose} hasDivider>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <ExchangeIcon color="primary" fontSize="large" />
          </Grid>
          <Grid item>
            <Typography variant="h6" color="textSecondary">
              {title ?? t('Exchange assets')}
            </Typography>
          </Grid>
        </Grid>
      </ModalHeader>
      <ExchangeHistorySyncNote />
    </>
  )
}
