import {Box, Divider, Grid, Typography} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import type {
  FlowAccountInfo,
  FlowDelegationInfo,
  FlowUpdateDelegatorTxType,
} from '@nufi/wallet-flow'
import type {FormikProps} from 'formik'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {
  Alert,
  FooterLayout,
  ModalFooter,
  ModalLayout,
  useModalSharedStyles,
} from '../../../../../components'
import type {MaxAmountOptions} from '../../types'
import {AmountField} from '../../utils'

import type {UpdateDelegatorDetailSchema} from './schema'
import {
  DetailScreenAlertText,
  DetailScreenAmountHeading,
  UpdateDelegatorAccountField,
} from './utils'

const FORM_ID = 'update-delegator-details-form'

type DetailsScreenProps<T extends UpdateDelegatorDetailSchema> = {
  formikProps: FormikProps<T>
  disabled?: boolean
  onClose: () => void
  txType: FlowUpdateDelegatorTxType
  accountInfo: FlowAccountInfo
  delegation: FlowDelegationInfo
  ModalHeader: React.ReactNode
  maxAmountOptions?: MaxAmountOptions
}

export default function DetailsScreen<T extends UpdateDelegatorDetailSchema>({
  disabled,
  formikProps,
  onClose,
  txType,
  accountInfo,
  delegation,
  ModalHeader,
  maxAmountOptions,
}: DetailsScreenProps<T>) {
  const classes = {...useStyles(), ...useModalSharedStyles()}
  const {t} = useTranslation()

  return (
    <form
      onSubmit={formikProps.handleSubmit}
      id={FORM_ID}
      className={classes.formWrapper}
    >
      <ModalLayout
        header={ModalHeader}
        body={
          <Box className={classes.contentWrapper}>
            <Box p={2}>
              <Grid container>
                <Grid item xs={12}>
                  {/* txType specific */}
                  <UpdateDelegatorAccountField
                    txType={txType}
                    accountInfo={accountInfo}
                    delegation={delegation}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Divider className={classes.divider} />
                </Grid>
                <Grid item xs={12}>
                  <Alert
                    // txType specific
                    text={<DetailScreenAlertText {...{txType}} />}
                    severity="info"
                    className={classes.commonBottomMargin}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle2"
                    className={classes.commonBottomMargin}
                  >
                    {/* txType specific */}
                    <DetailScreenAmountHeading {...{txType}} />
                  </Typography>
                </Grid>
                <Grid item xs={12} className={classes.formField}>
                  <AmountField
                    formikProps={formikProps}
                    blockchain="flow"
                    maxAmountOptions={maxAmountOptions}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
        }
        footer={
          <ModalFooter hasDivider>
            <FooterLayout
              leftBtnConf={{
                onClick: onClose,
                children: t('Back'),
              }}
              rightBtnConf={{
                children: t('Continue'),
                type: 'submit',
                form: FORM_ID,
                disabled,
              }}
            />
          </ModalFooter>
        }
      />
    </form>
  )
}

const useStyles = makeStyles(() => ({
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
  },
}))
