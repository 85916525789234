import {Stack} from '@mui/material'
import {assert} from '@nufi/frontend-common'
import type {CardanoVotingGovernanceKey} from '@nufi/wallet-cardano'
import {
  getMovementsDifference,
  getExternalReceiversInfo,
  getTokenMovementsDifferenceCounts,
} from '@nufi/wallet-cardano'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {ChipRow, TxDetailRow} from '../common/details'
import {TxDetail} from '../common/details/TxDetail'
import {TxDataLayout} from '../common/TxDataLayout'
import type {BlockchainTxDataProps} from '../types'

import {TransactionMismatchAlert} from './common'
import {useTags} from './useTags'

type CardanoTxDataProps = {
  data: BlockchainTxDataProps<'cardano'>
}

export function CardanoTxData({data: {txPlan, rawTx}}: CardanoTxDataProps) {
  const {nativeDiff, tokensDiff} = getMovementsDifference(txPlan)
  const txNotFullyParsed = txPlan.type === 'dApp' && !txPlan.matchesDAppTx

  const externalReceiversInfo = getExternalReceiversInfo(txPlan)
  const tags = useTags(txPlan)

  const {receivingTokensCount, sendingTokensCount} =
    getTokenMovementsDifferenceCounts(tokensDiff)

  const {t} = useTranslation()

  const getVotingRegistrationRows = (
    delegations: CardanoVotingGovernanceKey[],
  ) => {
    if (delegations.length === 1 && delegations[0]) {
      return {
        summary: <VotingRegistrationRow {...delegations[0]} keyNumber={1} />,
        details: null,
      }
    }

    return {
      summary: (
        <ChipRow
          label={t('Voting delegation')}
          chipContent={delegations.length}
        />
      ),
      details: delegations.map((delegation, i) => (
        <VotingRegistrationRow {...delegation} keyNumber={1 + i} />
      )),
    }
  }

  return (
    <Stack spacing={1}>
      <TxDataLayout
        details={
          <TxDetail
            nativeDiff={nativeDiff}
            tokensMovement={{
              tokensDiff: tokensDiff.map((bundle) => ({
                tokenId: bundle.token.id,
                amount: bundle.amount,
              })),
              getTokenInfoItems: (tokenMetadata) => {
                assert(tokenMetadata.blockchain === 'cardano')

                return [
                  {key: t('Policy id'), value: tokenMetadata.policyId},
                  {key: t('Fingerprint'), value: tokenMetadata.fingerprint},
                ]
              },
              receivingTokensCount,
              sendingTokensCount,
            }}
            externalReceiversInfo={externalReceiversInfo}
            tags={tags}
            fee={txPlan.fee}
            blockchain="cardano"
            customBottomRows={
              txPlan.auxiliaryData?.type === 'votingRegistration'
                ? [
                    getVotingRegistrationRows(
                      txPlan.auxiliaryData.delegation.delegations,
                    ),
                  ]
                : undefined
            }
          />
        }
        rawTx={rawTx}
      />
      {txNotFullyParsed && <TransactionMismatchAlert />}
    </Stack>
  )
}

const VotingRegistrationRow = ({
  votingKey,
  weight,
  keyNumber,
}: CardanoVotingGovernanceKey & {keyNumber: number}) => {
  const {t} = useTranslation()
  return (
    <TxDetailRow
      left={t('voting_key_title', {
        weight,
        keyNumber,
      })}
      right={`0x${votingKey.toString()}`}
    />
  )
}
