import _ from 'lodash'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {CardanoTokenExplorerLink} from '../../../components'
import {assert} from '../../../utils/assertion'
import type {CardanoTokenMetadata} from '../../../wallet/cardano'
import {getHumanReadableAssetNameFromHex} from '../../../wallet/cardano'
import {NftDetailPage} from '../../nfts/NftDetail'
import {AboutRow} from '../assetDetail/AboutRow'
import type {NftInfo} from '../assetDetail/types'

type CardanoTokenDetailInfoProps = {
  token: CardanoTokenMetadata
  nftInfo: NftInfo
}

export function CardanoTokenDetailInfo({
  token,
  nftInfo,
}: CardanoTokenDetailInfoProps) {
  const {t} = useTranslation()
  const {policyId, assetNameHex, fingerprint} = token

  return nftInfo ? (
    <NftTokenDetailInfo token={token} nftInfo={nftInfo} />
  ) : (
    <>
      <AboutRow label={t('Policy id')} value={policyId} />
      <AboutRow
        label={t('Asset name')}
        value={getHumanReadableAssetNameFromHex(assetNameHex)}
      />
      <AboutRow label={t('Fingerprint')} value={fingerprint} />
      <AboutRow
        label={t('More details')}
        value={
          <CardanoTokenExplorerLink
            policyId={policyId}
            assetNameHex={assetNameHex}
          />
        }
      />
    </>
  )
}

type NftTokenDetailInfoProps = {token: CardanoTokenMetadata; nftInfo: NftInfo}

function NftTokenDetailInfo({token, nftInfo}: NftTokenDetailInfoProps) {
  const {t} = useTranslation()
  if (!nftInfo) return null
  assert(nftInfo.blockchain === 'cardano')

  return (
    <NftDetailPage
      details={[
        {label: t('Policy id'), value: token.policyId},
        {label: t('Asset name'), value: token.assetNameHex},
        {label: t('Fingerprint'), value: token.fingerprint},
        {
          label: t('More details'),
          value: (
            <CardanoTokenExplorerLink
              policyId={token.policyId}
              assetNameHex={token.assetNameHex}
            />
          ),
        },
      ]}
      traits={Object.keys(nftInfo.tags).map((tag) => ({
        label: _.upperFirst(tag),
        value: nftInfo.tags[tag],
      }))}
      blockchain={nftInfo.blockchain}
      nftName={
        nftInfo.name || getHumanReadableAssetNameFromHex(token.assetNameHex)
      }
      collectionName={null} // TODO: find a collection name for cardano nfts
    />
  )
}
