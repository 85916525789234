import {Beenhere as ValidatorPlaceholderIcon} from '@mui/icons-material'
import {Box, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import type Bignumber from 'bignumber.js'
import type {FormikProps} from 'formik'
import React from 'react'
import {useTranslation} from 'react-i18next'

import type {StakingBlockchain} from '../../../../blockchainTypes'
import {
  ModalLayout,
  ModalFooter,
  StakeTransitionCardSummary,
  FooterLayout,
  Alert,
  useModalSharedStyles,
  CurrentPasswordField,
  ValidatorTransitionCardContent,
  EllipsizedString,
} from '../../../../components'
import {useIsPasswordVerificationRequired} from '../../../../store/auth'
import {getHasFormError} from '../../../../utils/form'
import type {AccountInfo} from '../../../../wallet'
import StakeSummary from '../../common/actionModals/StakeSummary'
import {StakeModalHeader} from '../../common/utils'
import type {BaseActivateStakeSchema, SetupStakeAccountSchema} from '../schema'

import {VALIDATOR_ELLIPSIZE_LENGTH} from './constants'

/**
 * This summary screen is shared between
 *  - stake activation of an existing stake account
 *  - creation of a new stake account + its activation in one step
 */

type SummaryScreenProps<
  T extends SetupStakeAccountSchema | BaseActivateStakeSchema,
> = {
  blockchain: StakingBlockchain
  onClose: () => void
  onBack: () => unknown
  onSubmit: () => unknown
  summary: {
    amount: Bignumber
    fee: Bignumber
    fromAccount: AccountInfo
    validatorName: string
  }
  formikProps: FormikProps<T>
}

const FORM_ID = 'common-delegate-form-confirm-details'

export default function SummaryScreen<
  T extends SetupStakeAccountSchema | BaseActivateStakeSchema,
>({
  onBack,
  onSubmit,
  blockchain,
  onClose,
  summary,
  formikProps,
}: SummaryScreenProps<T>) {
  const {t} = useTranslation()
  const classes = {...useStyles(), ...useModalSharedStyles()}
  const hasImmediateError = getHasFormError(formikProps, 'immediate')
  const isPasswordVerificationRequired = useIsPasswordVerificationRequired(
    summary.fromAccount,
  )

  return (
    <form
      onSubmit={onSubmit}
      noValidate
      id={FORM_ID}
      className={classes.formWrapper}
    >
      <ModalLayout
        header={<StakeModalHeader {...{onClose, blockchain}} />}
        body={
          <Box p={2}>
            <Box className={classes.commonBottomMargin}>
              <Alert
                text={t(
                  'Check all the details and activate staking. Your funds will be delegated to the selected validator.',
                )}
                severity="info"
              />
            </Box>
            <StakeTransitionCardSummary
              {...{...summary}}
              targetContent={
                <ValidatorTransitionCardContent
                  CustomIcon={<ValidatorPlaceholderIcon />}
                >
                  <span className={classes.toValidator}>
                    <EllipsizedString
                      value={summary.validatorName}
                      startLength={VALIDATOR_ELLIPSIZE_LENGTH}
                      endLength={VALIDATOR_ELLIPSIZE_LENGTH}
                    />
                  </span>
                  <Typography
                    className={classes.validatorLabel}
                    variant="body2"
                    color="textSecondary"
                  >
                    ({t('Validator')})
                  </Typography>
                </ValidatorTransitionCardContent>
              }
            />
            <Box className={classes.commonTopMargin}>
              <StakeSummary {...{blockchain, ...summary}} />
            </Box>
          </Box>
        }
        footer={
          <ModalFooter hasDivider>
            {hasImmediateError('hasSufficientFeeFunds') && (
              <Box mb={2}>
                <Alert
                  severity="error"
                  text={formikProps.errors.hasSufficientFeeFunds as string}
                />
              </Box>
            )}
            {isPasswordVerificationRequired && (
              <CurrentPasswordField
                {...{formikProps}}
                className={classes.passwordHeight}
              />
            )}
            <FooterLayout
              leftBtnConf={{
                onClick: onBack,
                children: t('Back'),
              }}
              rightBtnConf={{
                children: t('Submit'),
                type: 'submit',
                form: FORM_ID,
                disabled: formikProps.isSubmitting || !formikProps.isValid,
              }}
            />
          </ModalFooter>
        }
      />
    </form>
  )
}

const useStyles = makeStyles((theme) => ({
  toValidator: {
    marginLeft: theme.spacing(1),
  },
  validatorLabel: {
    paddingLeft: theme.spacing(0.5),
  },
}))
