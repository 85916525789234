/* eslint-disable @typescript-eslint/explicit-function-return-type */

import type {
  CardanoTokenId,
  CardanoTokenMetadata,
  CardanoAccountInfo,
  CardanoNft,
} from '@nufi/wallet-cardano'
import * as _ from 'lodash'

import {fetchNeverStaleQuery, useNeverStaleQuery} from 'src/utils/query-utils'

import {getAllNfts as getAllNftsGeneric} from '../../../public/nfts'
import {getCardano} from '../../cardanoManagers'

import {cachedGetAccounts} from './core'
import {
  cachedGetTokenMetadata,
  cachedGetTokensMetadata,
} from './tokens/metadata'
import {QUERY_KEY_PREFIX as P} from './utils'

export const nftsQueryKeys = {
  index: [P, 'nfts'],
  nftItem: (id: CardanoTokenId | null) => [...nftsQueryKeys.index, 'nft', id],
} as const

//
// __CACHED__ QUERIES
//

function getIsRegisteredTokenFn(tokenMetas: CardanoTokenMetadata[]) {
  /*  Data from token registry takes precedence and we don't consider a token to be an
      NFT in that case. We consider so because:
      - Each entry is a combination of policyId and assetName, if it's a project with
        hundreds or thousands of different NFTs, it's not feasible to create that many
        entries in the registry
      - The NFT metadata standard already provides a way to attach metadata to the NFT
        through the `721` label
      There might be some entries that don't even despite these assumptions. We decided
      to ignore those as there shouldn't be to many of them.
  */
  const tokenRegistrationStatuses = new Map(
    tokenMetas.map((tm) => [tm.id, tm.registered]),
  )
  return (tokenId: CardanoTokenId) => tokenRegistrationStatuses.get(tokenId)
}

export function useGetNft(tokenId: CardanoTokenId, enabled = true) {
  return useNeverStaleQuery({
    queryKey: useGetNft.__key(tokenId),
    queryFn: async () => {
      const tokenMetadata = await cachedGetTokenMetadata(tokenId)
      return tokenMetadata.registered
        ? null
        : await getCardano().wallet.getNft(tokenId as CardanoTokenId)
    },
    enabled,
  })
}

useGetNft.__key = nftsQueryKeys.nftItem

async function getAccountNfts(
  account: CardanoAccountInfo,
  tokensMetadata: CardanoTokenMetadata[],
): Promise<CardanoNft[]> {
  const accountTokenIds = _.uniq(
    account.utxos.flatMap((u) => u.tokenBundle).map((ta) => ta.token.id),
  )

  const isRegisteredToken = getIsRegisteredTokenFn(tokensMetadata)
  const possibleNftTokenIds = accountTokenIds.filter(
    (tokenId) => !isRegisteredToken(tokenId),
  )

  return await getCardano().wallet.getNfts(possibleNftTokenIds)
}

async function getAllNfts() {
  const accounts = await cachedGetAccounts()
  const tokensMetadata = await cachedGetTokensMetadata()

  return getAllNftsGeneric({
    blockchain: 'cardano',
    accounts,
    getAccountNfts: (account: CardanoAccountInfo) =>
      getAccountNfts(account, tokensMetadata),
    getSingleNftQueryKey: (nft: CardanoNft) => useGetNft.__key(nft.id),
  })
}

getAllNfts.__key = nftsQueryKeys.index

export function cachedGetAllNfts() {
  return fetchNeverStaleQuery({queryKey: getAllNfts.__key, queryFn: getAllNfts})
}

export function useGetAllNfts(enabled = true) {
  return useNeverStaleQuery({
    queryKey: getAllNfts.__key,
    queryFn: getAllNfts,
    enabled,
  })
}
