import {Sentry} from '@nufi/frontend-common'
import type {
  Lamports,
  SolanaPubKey,
  SolanaTransaction,
  SolanaTxFeeParams,
  SolanaUnversionedTransaction,
  SolanaVersionedTransaction,
} from '@nufi/wallet-solana'
import {
  DEFAULT_COMPUTE_UNIT_LIMIT,
  addPriorityFeesToUnversionedTx,
  addPriorityFeesToVersionedTx,
  isVersionedTransaction,
  parseVersionedMessage,
} from '@nufi/wallet-solana'
import uniqBy from 'lodash/uniqBy'

import {solana} from 'src/wallet/solana/solanaManagers'

const getExternalTxFeeParams = async (
  tx: SolanaTransaction,
  affectedAccounts: SolanaPubKey[],
): Promise<SolanaTxFeeParams | null> => {
  const [signatureFee, computeUnitPrice, simulationResult] = await Promise.all([
    solana.wallet.getSignatureFee(),
    solana.blockchainApi.getSuggestedComputeUnitPrice(affectedAccounts),
    solana.blockchainApi
      .simulateTransaction(tx, {
        accountPubKeys: [],
        replaceRecentBlockhash: true,
      })
      .catch(() => null),
  ])
  if (simulationResult?.value.err) {
    return null
  }

  const estimatedUnitsConsumed = simulationResult?.value.unitsConsumed ?? 0

  const txFeeParams = {
    signatureFee,
    computeUnitPrice,
    computeUnitLimit: Math.max(
      estimatedUnitsConsumed * 1.5,
      DEFAULT_COMPUTE_UNIT_LIMIT,
    ),
  }

  // TMP logging to debug tx timeout related issues
  Sentry.addBreadcrumb({
    level: 'info',
    category: 'solana.feeEstimate',
    message: `txFeeParams result: ${JSON.stringify(txFeeParams)}`,
  })

  return txFeeParams
}

export const sanitizeVersionedTxFees = async (
  tx: SolanaVersionedTransaction,
) => {
  const parsedMessage = await parseVersionedMessage(
    tx.message,
    solana.blockchainApi.getAccountInfo,
  )
  // inspired by https://github.com/helium/helium-program-library/blob/68da8e38e769a22bca0492156695b9677978d139/packages/spl-utils/src/priorityFees.ts#L20
  const uniqueWritableAccounts = uniqBy(
    parsedMessage.instructions
      .map((ix) => ix.keys.filter((k) => k.isWritable))
      .flat()
      .map((k) => k.pubkey),
    (k) => k.toBase58(),
  )
  const txFeeParams = await getExternalTxFeeParams(tx, uniqueWritableAccounts)

  if (!txFeeParams) {
    return tx
  }

  return addPriorityFeesToVersionedTx(tx, parsedMessage, txFeeParams)
}

export const sanitizeUnversionedTxFees = async (
  tx: SolanaUnversionedTransaction,
) => {
  // if there are issues due to passing too many "affected" accounts, consider
  // using Trezor Suite implementation:
  // https://github.com/trezor/trezor-suite/blob/fdee6adf67ac69a29c9e6f95c9e6331b345b4ce7/packages/blockchain-link/src/workers/solana/fee.ts#L38
  const affectedAccounts = tx.compileMessage().accountKeys
  const txFeeParams = await getExternalTxFeeParams(tx, affectedAccounts)

  if (!txFeeParams) {
    return tx
  }

  return addPriorityFeesToUnversionedTx(tx, txFeeParams)
}

export const sanitizeAndEstimateTxFee = async <T extends SolanaTransaction>(
  tx: T,
): Promise<{tx: T; estimatedFee: Lamports | null}> => {
  const sanitizedTx = isVersionedTransaction(tx)
    ? await sanitizeVersionedTxFees(tx)
    : await sanitizeUnversionedTxFees(tx)

  const [originalTxFee, sanitizedTxFee] = await Promise.all([
    solana.blockchainApi.getFeeForMessage(tx),
    solana.blockchainApi.getFeeForMessage(sanitizedTx),
  ])

  // this is a defensive check to ensure that we modify the
  // transaction fee only if we have a valid fee estimate
  // to prevent doing any modifications to the tx
  // without making the user being aware of the impact
  if (sanitizedTxFee) {
    return {tx: sanitizedTx as T, estimatedFee: sanitizedTxFee}
  }

  return {tx, estimatedFee: originalTxFee}
}
