import type {Theme} from '@mui/material'
import {Box, Typography} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import React from 'react'

import type {ElevatedCardType} from '../atoms/ElevatedCard'
import ElevatedCard from '../atoms/ElevatedCard'
import {IconBackgroundWrapper} from '../atoms/Icon'

type Dimensions = {width: number; height: number}

type BaseActionCardProps = {
  icon: JSX.Element
  onClick: () => unknown
  disabled?: boolean
  type?: ElevatedCardType
  cardType?: 'square' | 'rectangular'
  dimensions?: Dimensions
}

type ActionCardProps =
  | (BaseActionCardProps & {text: string})
  | (BaseActionCardProps & {Text: React.ReactNode})

export function ActionCard({
  onClick,
  icon,
  disabled,
  type,
  dimensions = {width: 210, height: 210},
  cardType = 'square',
  ...rest
}: ActionCardProps) {
  const classes = useStyles({dimensions})

  const text = 'text' in rest ? rest.text : null
  const Text = 'Text' in rest ? rest.Text : null

  return (
    <ElevatedCard
      className={clsx({
        [classes.rectangularActionCard]: cardType === 'rectangular',
        [classes.squareActionCard]: cardType === 'square',
      })}
      {...{onClick, disabled, type}}
    >
      <Box
        className={clsx({
          [classes.rectangularIconWrapper]: cardType === 'rectangular',
          [classes.squareIconWrapper]: cardType === 'square',
        })}
      >
        <IconBackgroundWrapper padding={cardType === 'square' ? 2 : 1.25}>
          {icon}
        </IconBackgroundWrapper>
      </Box>
      <Box>
        {text ? (
          <Typography variant={cardType === 'square' ? 'body1' : 'body2'}>
            {text}
          </Typography>
        ) : (
          Text
        )}
      </Box>
    </ElevatedCard>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  rectangularActionCard: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1.5),
  },
  rectangularIconWrapper: {
    marginRight: theme.spacing(1.5),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  squareActionCard: ({dimensions}: {dimensions: Dimensions}) => ({
    ...dimensions,
    margin: 0,
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  squareIconWrapper: {
    width: 76,
    height: 76,
    marginBottom: theme.spacing(2),
  },
}))
