import {
  cardanoBlockchainMeta,
  cardanoBlockchainName,
} from '@nufi/wallet-cardano'
import {
  ethereumMetadata,
  milkomedaC1Metadata,
  optimismMetadata,
  polygonMetadata,
  arbitrumOneMetadata,
  baseMetadata,
} from '@nufi/wallet-evm'
import {flowBlockchainMeta, flowBlockchainName} from '@nufi/wallet-flow'
import {solanaBlockchainMeta, solanaBlockchainName} from '@nufi/wallet-solana'

import type {existingBlockchains} from '../blockchainTypes'

import type {BlockchainMetadata} from './types'

export const blockchainMetadata = {
  [cardanoBlockchainName]: {
    ...cardanoBlockchainMeta,
    cryptoPanicCurrency: 'ADA',
  },
  [solanaBlockchainName]: {
    ...solanaBlockchainMeta,
    cryptoPanicCurrency: 'SOL',
  },
  [flowBlockchainName]: {
    ...flowBlockchainMeta,
    cryptoPanicCurrency: 'FLOW',
  },
  ethereum: {
    ...ethereumMetadata,
    cryptoPanicCurrency: 'ETH',
  },
  milkomedaC1: {
    ...milkomedaC1Metadata,
    cryptoPanicCurrency: 'MILKADA',
  },
  optimism: {
    ...optimismMetadata,
    cryptoPanicCurrency: 'OP',
  },
  polygon: {
    ...polygonMetadata,
    cryptoPanicCurrency: 'MATIC',
  },
  arbitrumOne: {
    ...arbitrumOneMetadata,
    cryptoPanicCurrency: 'ARB',
  },
  base: {
    ...baseMetadata,
    cryptoPanicCurrency: 'BASE',
  },
} as const satisfies Record<
  (typeof existingBlockchains)[number],
  BlockchainMetadata
>
