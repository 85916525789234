import {
  Check as DelegatedIcon,
  Close as UndelegatedIcon,
} from '@mui/icons-material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import type {ConversionRates} from 'src/features/conversionRates/domain'

import {
  QueryGuard,
  LabeledIcon,
  CardanoRewardAddressExplorerLink,
} from '../../../components'
import type {
  CardanoAccountInfo,
  CardanoTokenMetadata,
} from '../../../wallet/cardano'
import {useGetStakeAccounts} from '../../../wallet/cardano'
import {findAccountById} from '../../../wallet/utils/common'
import {AccountListCard} from '../account/accountListCards'
import {LinearLoader} from '../account/Loadings'

type CardanoAccountCardProps = {
  accountInfo: CardanoAccountInfo
  tokensMetadata: CardanoTokenMetadata[]
  conversionRates?: ConversionRates
}

export function CardanoAccountCard({
  accountInfo,
  ...rest
}: CardanoAccountCardProps) {
  const tokens = accountInfo.tokensBalance
  const stakeAccountsQuery = useGetStakeAccounts()
  const {t} = useTranslation()
  const extendedAccountInfo = [
    {
      label: t('Account extended public key'),
      value: accountInfo.shelleyXpubKey,
    },
    {label: t('Staking key CBOR hex'), value: accountInfo.stakingKeyCborHex},
    {
      label: t('Reward address'),
      value: accountInfo.rewardAddress,
      link: (
        <CardanoRewardAddressExplorerLink
          rewardAddress={accountInfo.rewardAddress}
        />
      ),
    },
    // "Reward address hex" includes also network id besides the hash itself,
    // so the description is not really accurate, but we are keeping it consistent
    // with Adalite where it's shown like this
    {label: t('Staking key hash hex'), value: accountInfo.rewardAddressHex},
  ]

  return (
    <AccountListCard
      {...{tokens, accountInfo, ...rest}}
      extendedAccountInfo={extendedAccountInfo}
      StakingCol={
        <QueryGuard {...stakeAccountsQuery} LoadingElement={<LinearLoader />}>
          {(stakeAccounts) => {
            const isDelegated =
              // ensureAccountById might crash shortly after a new account is added
              // and stake accounts are not refreshed yet, even if they are already stale
              !!findAccountById(stakeAccounts, accountInfo.id)?.delegation
            return isDelegated ? (
              <LabeledIcon
                color="success.light"
                Label={<>{t('YES')}</>}
                Icon={<DelegatedIcon fontSize="small" />}
                iconPosition="start"
                spacing={0.5}
              />
            ) : (
              <LabeledIcon
                color="error.light"
                Label={<>{t('NO')}</>}
                Icon={<UndelegatedIcon fontSize="small" />}
                iconPosition="start"
                spacing={0.5}
              />
            )
          }}
        </QueryGuard>
      }
      stakingColText={t('Staked')}
    />
  )
}
