import {Grid} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import React from 'react'

type AlignerProps = {
  align: 'left' | 'right' | 'center'
  children: React.ReactNode
  containerClassName?: string
  itemClassName?: string
}

export function Aligner({
  align,
  children,
  containerClassName,
  itemClassName,
}: AlignerProps) {
  const _align = (
    {
      left: 'flex-start',
      right: 'flex-end',
      center: 'center',
    } as const
  )[align]

  return (
    <Grid container justifyContent={_align} className={containerClassName}>
      <Grid item className={itemClassName}>
        {children}
      </Grid>
    </Grid>
  )
}

export function NoOverflowAligner({
  containerClassName,
  itemClassName,
  ...restProps
}: AlignerProps) {
  const classes = useStyles()

  return (
    <Aligner
      {...restProps}
      containerClassName={clsx(classes.hideOverflow, containerClassName)}
      itemClassName={clsx(classes.hideOverflow, itemClassName)}
    />
  )
}

const useStyles = makeStyles(() => ({
  hideOverflow: {
    overflow: 'hidden',
  },
}))
