import {Sentry} from '@nufi/frontend-common'

import type {TokenMetadata} from 'src/wallet'
import {cachedGetTokensMetadata} from 'src/wallet'

import {createCommonFromAssetAmountValidation} from '../domain'

export const commonFromAssetValidation = createCommonFromAssetAmountValidation({
  getTokenDecimals: async (blockchain, tokenId) => {
    const tokensMetadata = (await cachedGetTokensMetadata(
      blockchain,
    )) as TokenMetadata[]
    const token = tokensMetadata.find((tm) => tm.id === tokenId)
    if (token == null) {
      Sentry.captureMessage(
        `Exchange value validation: Could not get token decimals for token:${tokenId}, blockchain:${blockchain}`,
      )
      throw new Error('Could not get token decimals.')
    }
    return token.decimals
  },
})
