import type {ErrorResponse} from '@nufi/dapp-client-core'
import {getIsWebExtension} from '@nufi/frontend-common'

import type {ConnectorKind, MessageToClient} from 'src/dappConnector/types'

import {getInitialEvmBlockchain} from '../../dappConnector/utils/initialEvmBlockchain'
import {useDappConnectorStore} from '../../store/dappConnector'
import type {
  SignScreenResponse,
  CreateCardanoCollateralScreenData,
  SigningKind,
  SignScreenData,
  SignScreenExtraOptions,
  ChangeEvmChainScreenData,
  ImportEvmTokenParams,
} from '../../store/dappConnector'
import type {AccountOfflineInfo} from '../../types'
import type {EvmBlockchain} from '../../wallet/evm'

import {sendRuntimeUnhandledMessage} from './messaging/helpers'

function showNotification(title: string) {
  if ('Notification' in window && Notification.permission === 'granted') {
    const notification = new Notification(title)
    notification.onclick = () => window.focus()
  }
}

export function focusConnectorWindow() {
  // this fn gets called also from the web walletConnect connector window in which
  // chrome api is not available
  if (getIsWebExtension()) {
    chrome.tabs.query({currentWindow: true}, (result) => {
      const windowId = result[0]!.windowId
      chrome.windows.update(windowId, {focused: true})
    })
  } else {
    showNotification('Action required: click to review details')
  }
}

const promiseWithWindowFocus = <T>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fn: (resolve: (value: T) => void, reject: (reason?: any) => void) => void,
) => {
  return new Promise<T>((resolve, reject) => {
    fn(resolve, reject)
    focusConnectorWindow()
  })
}

// Avoid reusing partial UI state when request for another tx sign or another
// similar action is requested, by first reverting to idle screen.
const withScreenReset = (fn: (...args: any[]) => unknown) => {
  const {setIdleScreen, setOverlayDialog, overlayDialog} =
    useDappConnectorStore.getState()
  setIdleScreen()
  // We do not want settings and other such dialogs to be displayed
  // when transaction or other action was requested via dapp.
  if (overlayDialog !== 'none') {
    setOverlayDialog('none')
  }
  fn()
}

/**
 *
 * @param kind the kind of signature that needs to be confirmed
 * @param data data passed to the interactive "Sign UI" flow
 * @param onSign function responsible for transaction signing, called once user press "Sign" via the UI
 * @param onFailure function that should return the `ErrorResponse` object (but NOT throw!!!)
 * @returns Promise that resolves once user complete the sign flow and rejects on manual user rejection
 */
export function confirmSign<
  Kind extends SigningKind,
  T extends SignScreenResponse<Kind>,
>(
  kind: Kind,
  data: SignScreenData<Kind>,
  // UI layer is allowed to pass back data selected by user
  onSign: (customSignData?: SignScreenExtraOptions<Kind>) => Promise<T>,
  onFailure: () => ErrorResponse,
): Promise<T> {
  return promiseWithWindowFocus((resolve, reject) => {
    const {setSignScreen} = useDappConnectorStore.getState()

    withScreenReset(() => {
      setSignScreen({
        state: kind,
        data,
        onSign: async (customSignData) => {
          const tx = await onSign(customSignData)

          // A function is returned so that `signing` and `finishing the ui flow` can be decoupled,
          // leaving the freedom for some confirmation UI
          return {onFinish: () => resolve(tx)}
        },
        onFailure: () => reject(onFailure()),
      })
    })
  })
}

export function confirmInit(
  originInfo: {
    origin: string
    favIconUrl?: string
  },
  connectorKind: ConnectorKind,
): Promise<boolean> {
  return promiseWithWindowFocus((resolve) => {
    const {setInitScreen, screenInfo, origin} = useDappConnectorStore.getState()

    if (screenInfo.state === 'init' && connectorKind === 'evm') {
      // We do nothing in such a scenario because:
      // 1. Dapp wants to connect to Milkomeda
      // 2. User switched chain to Ethereum
      // 3. We emitted "chainChanged" event
      // 4. Dapp re-requested init with the same blockchain
      // 5. This would result to the reset of user's choice so we just drop this
      // request and hope that the dapp will continue to work.
      resolve(false)
    } else {
      setInitScreen({
        // Initial blockchain is set based on connectorKind. `ConnectorKind` type maps
        // 1 to 1 with the `ConnectorBlockchain` type except for `ConnectorKind.evm` which can
        // be used by multiple blockchains (e.g. Ethereum and Milkomeda C1).
        blockchain:
          connectorKind === 'evm'
            ? getInitialEvmBlockchain(originInfo.origin)
            : connectorKind,
        origin: originInfo.origin,
        favIconUrl: originInfo.favIconUrl || null,
        onFinish: () => {
          // Dispatch event specifying that the connector window is ready, to make it easier for
          // independent event listeners to react to this change.
          // Once "change account" will be implemented, we can make this a general
          // "accountChanged" event with data specific for respective blockchains.
          sendRuntimeUnhandledMessage<MessageToClient>({
            senderContext: 'connectorWindow',
            targetContext: 'serviceWorker',
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            targetOrigin: origin!,
            type: 'event',
            connectorKind,
            method: 'connectorWindowOpen',
            args: [],
          })

          return resolve(true)
        },
        onFailure: () => resolve(false),
      })
    }
  })
}

export function requestCreateCardanoCollateral(
  data: CreateCardanoCollateralScreenData,
): Promise<boolean> {
  return promiseWithWindowFocus((resolve) => {
    const {setCreateCardanoCollateralScreen} = useDappConnectorStore.getState()

    withScreenReset(() => {
      setCreateCardanoCollateralScreen({
        data,
        onCreated: async () => resolve(true),
        onRejected: async () => resolve(false),
      })
    })
  })
}

export function requestEvmChainChange(
  data: ChangeEvmChainScreenData,
): Promise<boolean> {
  return promiseWithWindowFocus((resolve) => {
    const {
      setChangeEvmChainScreen,
      changeEvmChainAndSetIdleScreen,
      setIdleScreen,
    } = useDappConnectorStore.getState()

    setChangeEvmChainScreen({
      data,
      onChange: async (
        blockchain: EvmBlockchain,
        account: AccountOfflineInfo,
      ) => {
        changeEvmChainAndSetIdleScreen({blockchain, account})
        resolve(true)
      },
      onRejected: async () => {
        setIdleScreen()
        resolve(false)
      },
    })
  })
}

export function requestEvmTokenImport(
  data: ImportEvmTokenParams<EvmBlockchain>,
): Promise<boolean> {
  return promiseWithWindowFocus((resolve) => {
    const {setImportEvmTokenScreen, setIdleScreen} =
      useDappConnectorStore.getState()

    withScreenReset(() => {
      setImportEvmTokenScreen({
        data,
        onImport: () => {
          setIdleScreen()
        },
        onRejected: () => {
          setIdleScreen()
        },
      })
      // Immediately resolving according to https://eips.ethereum.org/EIPS/eip-747
      resolve(true)
    })
  })
}
