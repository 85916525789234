import {
  assertBlockchainIsInSubset,
  assertBlockchainIsInSubsetOrNull,
  isBlockchainSubset,
} from '@nufi/wallet-common'

import type {Blockchain} from '../types'

export * from '@nufi/wallet-common/src/blockchainGuards'

export const BlockchainSubsetGuard = <T extends ReadonlyArray<Blockchain>>({
  children,
  blockchain,
  blockchainSubset,
}: {
  children: (blockchain: T[number]) => JSX.Element | null
  blockchain: unknown
  blockchainSubset: T
}) => {
  assertBlockchainIsInSubset(blockchain, blockchainSubset)
  return children(blockchain)
}

export const BlockchainSubsetOrNullGuard = <
  T extends ReadonlyArray<Blockchain>,
>({
  children,
  blockchain,
  blockchainSubset,
}: {
  children: (blockchain: T[number] | null) => JSX.Element | null
  blockchain: unknown
  blockchainSubset: T
}) => {
  assertBlockchainIsInSubsetOrNull(blockchain, blockchainSubset)
  return children(blockchain)
}

type EmptyResultIfBlockchainNotSupportedParams<
  TQuery,
  TBlockchain extends Blockchain,
  TEmptyData,
> = {
  queries: {[b in TBlockchain]: TQuery}
  blockchain: unknown
  blockchainSubset: ReadonlyArray<TBlockchain>
  emptyData?: TEmptyData
}

export const emptyResultIfBlockchainNotSupported = <
  TQuery,
  TBlockchain extends Blockchain,
  TEmptyData,
>({
  queries,
  blockchain,
  blockchainSubset,
  emptyData,
}: EmptyResultIfBlockchainNotSupportedParams<
  TQuery,
  TBlockchain,
  TEmptyData
>) => {
  if (!isBlockchainSubset(blockchain, blockchainSubset)) {
    return {
      data: emptyData,
      error: undefined,
      isLoading: false,
      isFetching: false,
      dataUpdatedAt: 0,
      isSuccess: true,
    }
  }

  return queries[blockchain]
}
