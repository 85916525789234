/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {conversionRatesQueryKeys} from 'src/features/conversionRates/application/queryKeys'

import {crossBlockchainQueryKeys} from '../../public/queries/queryKeys'
import {getAvailableEvmBlockchains} from '../evmBlockchains'
import type {EvmBlockchain} from '../types'

import {
  combinedQueryKeys,
  coreQueryKeys,
  nftsQueryKeys,
  tokensQueryKeys,
} from './queries'

// Order of invalidation keys matters! More info can be found in docs/queries.md.

export const tokensInvalidationKeys = (blockchain: EvmBlockchain) => [
  // Nfts must be before tokens as some tokens queries are building on top
  // of nft queries.
  nftsQueryKeys.nfts.index(blockchain),
  tokensQueryKeys.index(blockchain),
  conversionRatesQueryKeys.index,
  combinedQueryKeys.balance.index(blockchain),
]

export const accountsInvalidationKeys = (blockchain: EvmBlockchain) => [
  coreQueryKeys.accounts(blockchain),
  coreQueryKeys.getTotalNativeBalance(blockchain),
  ...tokensInvalidationKeys(blockchain),
  crossBlockchainQueryKeys.index,
]

export const getEvmCrossChainAccountInvalidationKeys = () =>
  getAvailableEvmBlockchains().map(accountsInvalidationKeys).flat()

export const submitTransferInvalidationKeys = (blockchain: EvmBlockchain) => [
  ...accountsInvalidationKeys(blockchain),
  combinedQueryKeys.txHistory.index(blockchain),
  combinedQueryKeys.oneWayAssetTransfers.index(blockchain),
]
