import {useTranslation} from 'react-i18next'

import type {Blockchain} from '../../types'

export const useCustomErrorMessage = (error: Error, blockchain: Blockchain) => {
  const {t} = useTranslation()

  if (blockchain === 'ethereum') {
    if (
      'reason' in error &&
      (error as unknown as {reason: string}).reason ===
        'replacement fee too low'
    ) {
      return t('replacement_fee_too_low')
    }
    if (
      'message' in error &&
      (error as unknown as {message: string}).message ===
        'transaction underpriced'
    ) {
      return t('transaction_underpriced')
    }
    return null
  }
  return null
}
