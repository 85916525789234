import {HelpOutline} from '@mui/icons-material'
import {Grid, Typography} from '@mui/material'
import clsx from 'clsx'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {Aligner, LabeledIconWithTooltip} from '../../../../components'
import {useStakeAccountListItemLayoutStyles} from '../../common/overview/StakeAccountListLayoutUtils'

export function StakeAccountListHeader() {
  const {t} = useTranslation()

  return (
    <StakeAccountListItemLayout
      validatorCol={
        <Typography variant="caption" color="textSecondary">
          {t('Validator')}
        </Typography>
      }
      stakedCol={
        <Typography variant="caption" color="textSecondary">
          {t('Staked')}
        </Typography>
      }
      committedCol={
        <Typography variant="caption" color="textSecondary">
          <LabeledIconWithTooltip
            Label={t('Committed')}
            Icon={<HelpOutline fontSize="small" />}
            title={
              <>
                {t(
                  'Committed tokens will become staked the next epoch. You can retrieve tokens that are committed by the "Unstake tokens" action.',
                )}
              </>
            }
            spacing={0.5}
          />
        </Typography>
      }
      rewardsCol={
        <Typography variant="caption" color="textSecondary">
          {t('Rewards')}
        </Typography>
      }
      requestedToUnstakeCol={
        <Typography variant="caption" color="textSecondary">
          <LabeledIconWithTooltip
            Label={t('Requested to unstake')}
            Icon={<HelpOutline fontSize="small" />}
            title={
              <>
                {t(
                  'Tokens requested to unstake will become "Unstaking" at the start of the next epoch.',
                )}
              </>
            }
            spacing={0.5}
          />
        </Typography>
      }
      lockedCol={
        <Typography variant="caption" color="textSecondary">
          <LabeledIconWithTooltip
            Label={t('Unstaking')}
            Icon={<HelpOutline fontSize="small" />}
            title={
              <>
                {t(
                  'Unstaking tokens are locked, they will become unstaked the next epoch',
                )}
              </>
            }
            spacing={0.5}
          />
        </Typography>
      }
      unstakedCol={
        <Typography variant="caption" color="textSecondary">
          {t('Unstaked')}
        </Typography>
      }
      actionsCol={
        <Typography variant="caption" color="textSecondary">
          {''}
        </Typography>
      }
    />
  )
}

type AccountListItemLayoutProps = {
  validatorCol: React.ReactNode
  stakedCol: React.ReactNode
  requestedToUnstakeCol: React.ReactNode
  committedCol: React.ReactNode
  unstakedCol: React.ReactNode
  rewardsCol: React.ReactNode
  lockedCol: React.ReactNode
  actionsCol: React.ReactNode
  tableBodyStyles?: boolean
}

export function StakeAccountListItemLayout({
  validatorCol,
  stakedCol,
  committedCol,
  rewardsCol,
  requestedToUnstakeCol,
  lockedCol,
  unstakedCol,
  actionsCol,
  tableBodyStyles,
}: AccountListItemLayoutProps) {
  const classes = useStakeAccountListItemLayoutStyles()

  const columns = 12 // ensure default MUI Grid
  const xsRatio = columns / 8 // 7 as number of the Grid Items

  return (
    <Grid container alignItems="center">
      <Grid item xs={xsRatio} columns={columns}>
        <Aligner
          align="left"
          containerClassName={clsx(tableBodyStyles && classes.firstCol)}
        >
          {validatorCol}
        </Aligner>
      </Grid>
      <Grid item xs={xsRatio} columns={columns}>
        <Aligner align="right">{stakedCol}</Aligner>
      </Grid>
      <Grid item xs={xsRatio} columns={columns}>
        <Aligner align="right">{committedCol}</Aligner>
      </Grid>
      <Grid item xs={xsRatio} columns={columns}>
        <Aligner align="right">{rewardsCol}</Aligner>
      </Grid>
      <Grid item xs={xsRatio} columns={columns}>
        <Aligner align="right">{requestedToUnstakeCol}</Aligner>
      </Grid>
      <Grid item xs={xsRatio} columns={columns}>
        <Aligner align="right">{lockedCol}</Aligner>
      </Grid>
      <Grid item xs={xsRatio} columns={columns}>
        <Aligner align="right" itemClassName={classes.textOverflowingContainer}>
          {unstakedCol}
        </Aligner>
      </Grid>
      <Grid item xs={xsRatio} columns={columns}>
        <Aligner
          containerClassName={clsx(tableBodyStyles && classes.lastCol)}
          align="right"
        >
          {actionsCol}
        </Aligner>
      </Grid>
    </Grid>
  )
}
