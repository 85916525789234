import {useTranslation} from 'react-i18next'
import * as yup from 'yup'

import {useCurrentLoginInfo} from '../../../../store/auth'
import type {AccountInfo} from '../../../../types'
import {isPasswordVerificationRequired} from '../../../utils'

export type SummaryValues = {
  password: string
  agreement: boolean
}

type SummaryDetailsSchemaArgs = {
  fromAccount: AccountInfo | null
}

export const useSummarySchema = ({fromAccount}: SummaryDetailsSchemaArgs) => {
  const {t} = useTranslation()
  const loginInfo = useCurrentLoginInfo()

  return {
    password: yup.string().when([], {
      is: () => {
        if (fromAccount) {
          return isPasswordVerificationRequired(loginInfo, fromAccount)
        }
        return false
      },
      then: (schema) => schema.required(t('Password is required.')),
    }),
    agreement: yup
      .boolean()
      .oneOf(
        [true],
        t(
          "You must agree to the Changelly's Terms of use to proceed with the exchange.",
        ),
      ),
  }
}
