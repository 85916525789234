import type {BoxProps} from '@mui/material'
import {styled, Box} from '@mui/material'
import React from 'react'

import type {ActionButtonsType} from '../../components/buttonUtils'
import {ActionButtons} from '../../components/buttonUtils'

import {useSignContext} from './SignContext'

export type SignLayoutProps = {
  infoContent: React.ReactNode
  actionsProps: ActionButtonsType
} & (
  | ({
      component: 'div'
    } & Omit<BoxProps<'div'>, 'component'>)
  | ({
      component: 'form'
    } & Omit<BoxProps<'form'>, 'component'>)
)

export const SignLayout = ({
  infoContent,
  actionsProps,
  ...rest
}: SignLayoutProps) => {
  const {layoutProps} = useSignContext()
  const {signLayoutOverflow, screenWidth} = layoutProps
  return (
    <Box
      {...rest}
      sx={{
        ...wrapperStyles,
        width: screenWidth,
        ...(signLayoutOverflow && {overflow: signLayoutOverflow}),
        ...(rest.sx && rest.sx),
      }}
    >
      <InfoWrapper overflow={signLayoutOverflow}>{infoContent}</InfoWrapper>
      <ActionsWrapper>
        <ActionButtons {...actionsProps} />
      </ActionsWrapper>
    </Box>
  )
}

const wrapperStyles = {
  margin: '0 auto',
  maxWidth: '100%',
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
} as const

const InfoWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'overflow',
})<{
  overflow?: string
}>(({overflow}) => ({
  width: '100%',
  ...(overflow && {overflow}),
}))

const ActionsWrapper = styled('div')(() => ({
  width: '100%',
  marginTop: 'auto',
}))
