import {
  AccountBalanceWallet as CreateNewWalletIcon,
  RestorePage as RestoreWalletIcon,
  Usb as PairHwWalletIcon,
} from '@mui/icons-material'
import {Box, Grid, Typography} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'

import {useAvailableExternalLoginProviders} from 'src/features/login'

import {SideBarLayout, ActionCard, HelpSectionLink} from '../../components'
import {routeTo} from '../../router'
import {ExternalProvidersWrapper} from '../profile/common'
import {useRouteToCreateProfile} from '../profile/CreateProfilePage'
import {LoginWithMetamaskButton} from '../profile/metamaskLogin/LoginWithMetamaskButton'
import {Web3AuthLoginApp} from '../profile/Web3AuthLogin'

export default function WelcomePage() {
  const {t} = useTranslation()
  const history = useHistory()
  const classes = useStyles()

  const routeToCreateProfile = useRouteToCreateProfile()
  const handleCreateWallet = () => routeToCreateProfile(false)
  const handlePairHwWallet = () => routeToCreateProfile(true)

  const handleRestoreWallet = () => {
    history.push(routeTo.restoreProfile)
  }

  const cardDimensions = {width: 180, height: 200}

  const {metamask} = useAvailableExternalLoginProviders()

  return (
    <SideBarLayout
      showAnnouncement
      right={
        <Box className={classes.wrapper}>
          <Typography variant="h5">{t('Get Started')}</Typography>
          <Box pt={1}>
            <Typography variant="body1" color="textSecondary">
              {t('Create a new wallet or restore an existing one.')}
            </Typography>
          </Box>

          <Box pt={3}>
            <Grid
              container
              spacing={2}
              direction={{xs: 'column', md: 'row'}}
              alignItems="center"
            >
              <Grid item sm={4}>
                <ActionCard
                  onClick={handleCreateWallet}
                  icon={
                    <CreateNewWalletIcon fontSize="large" color="primary" />
                  }
                  Text={<CardText title={t('Create New Wallet')} />}
                  dimensions={cardDimensions}
                />
              </Grid>
              <Grid item sm={4}>
                <ActionCard
                  onClick={handlePairHwWallet}
                  icon={<PairHwWalletIcon fontSize="large" color="primary" />}
                  Text={
                    <CardText
                      title={t('Pair Hardware Wallet')}
                      description={t('(Ledger / Trezor / GridPlus)')}
                    />
                  }
                  dimensions={cardDimensions}
                />
              </Grid>
              <Grid item sm={4}>
                <ActionCard
                  onClick={handleRestoreWallet}
                  icon={<RestoreWalletIcon fontSize="large" color="primary" />}
                  Text={<CardText title={t('Restore Wallet')} />}
                  dimensions={cardDimensions}
                />
              </Grid>
            </Grid>
          </Box>
          <ExternalProvidersWrapper>
            <>{metamask && <LoginWithMetamaskButton />}</>
            <Web3AuthLoginApp />
          </ExternalProvidersWrapper>
          <HelpSectionLink />
        </Box>
      }
    />
  )
}

type CardTextProps = {
  title: string
  description?: string
}

function CardText({title, description}: CardTextProps) {
  return (
    <>
      <Typography marginY={!description ? '12px' : '0'} textAlign="center">
        {title}
      </Typography>
      <Typography color="textSecondary" textAlign="center" fontSize="small">
        {description}
      </Typography>
    </>
  )
}

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 580,
  },
}))
