import {safeAssertUnreachable} from '@nufi/frontend-common'
import _ from 'lodash'
import React, {useReducer} from 'react'

import {notNullish} from 'src/utils/helpers'

import {RampOperation} from '../../domain'

import type {UnSupportedOperationProps} from './screens/detailsScreen/form/RampFlowDetailsForm'
import {RampFlowDetailsScreen} from './screens/detailsScreen/RampFlowDetailsScreen'
import {RampFlowWidgetScreen} from './screens/widgetScreen/RampFlowWidgetScreen'
import {RampFlowConfigProvider} from './state/rampFlowConfig'
import type {RampFlowConfig} from './state/rampFlowConfig'
import {RampFlowStep, rampFlowStateReducer} from './state/rampFlowState'
import type {RampFlowState} from './state/rampFlowState'

export type RampFlowProps = {
  initialState?: RampFlowState
  screens?: Partial<typeof SCREENS>
  isPasswordLogin: boolean
} & UnSupportedOperationProps &
  Omit<RampFlowConfig, 'dispatch'>

// at this point RampFlow should have no idea about a specific ramp such as moonpay
export const RampFlow = ({
  assetSelectFilter,
  getIsOperationSupportedInApp,
  renderOperationNotSupportedContent,
  onCancel,
  renderLayout,
  renderBuyWidget,
  renderSellWidget,
  renderWidgetHeader,
  vm,
  initialState = {step: RampFlowStep.DETAILS, operation: RampOperation.BUY},
  screens: customScreens,
  isPasswordLogin,
}: RampFlowProps) => {
  const [state, dispatch] = useReducer(rampFlowStateReducer, initialState)
  // need to remove {[screen]: undefined} from customScreens because it would override SCREENS
  const screens = {...SCREENS, ..._.pickBy(customScreens, notNullish)}

  return (
    <RampFlowConfigProvider
      value={{
        assetSelectFilter,
        dispatch,
        onCancel,
        renderLayout,
        renderBuyWidget,
        renderSellWidget,
        renderWidgetHeader,
        vm,
      }}
    >
      {(() => {
        switch (state.step) {
          case RampFlowStep.DETAILS:
            return (
              <screens.Details
                initialOperation={state.operation}
                initialAsset={state.asset}
                initialAccount={state.account}
                getIsOperationSupportedInApp={getIsOperationSupportedInApp}
                renderOperationNotSupportedContent={
                  renderOperationNotSupportedContent
                }
              />
            )
          case RampFlowStep.WIDGET:
            return (
              <screens.Widget
                asset={state.asset}
                account={state.account}
                operation={state.operation}
                isPasswordLogin={isPasswordLogin}
              />
            )
          default:
            return safeAssertUnreachable(state)
        }
      })()}
    </RampFlowConfigProvider>
  )
}

const SCREENS = {
  Details: RampFlowDetailsScreen,
  Widget: RampFlowWidgetScreen,
}
