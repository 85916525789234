import {useTranslation} from 'react-i18next'
import * as yup from 'yup'

import type {AccountId} from '../../../types'

export type StakeDetailsValues = {
  accountId: AccountId
  validatorId: string
}

export type StakeSummaryValues = {
  password: string
}
const _useBaseDetailsSchema = () => {
  const {t} = useTranslation()

  return {
    validatorId: yup
      .string()
      .typeError(t('Invalid string'))
      .required(t('Validator is required.')),
    accountId: yup.string(),
  }
}

// ensure consistency of keys / values on TS level
export const useBaseDetailsSchema: (
  ...args: Parameters<typeof _useBaseDetailsSchema>
) => {
  [k in keyof StakeDetailsValues]: ReturnType<typeof _useBaseDetailsSchema>[k]
} = _useBaseDetailsSchema

export type ActivateStakeSchema = StakeDetailsValues & StakeSummaryValues

export type WithdrawRewardsSchema = {
  accountId: AccountId
} & StakeSummaryValues

const _useWithdrawRewardsSchema = () => ({
  accountId: yup.string(),
})

export const useWithdrawRewardsSchema: (
  ...args: Parameters<typeof _useWithdrawRewardsSchema>
) => {
  [k in keyof {
    accountId: string
  }]: ReturnType<typeof _useWithdrawRewardsSchema>[k]
} = _useWithdrawRewardsSchema
