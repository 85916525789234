import React from 'react'

import type {AccountInfo, HotVendor} from '../../../types'
import type {CardanoAccountStoredData} from '../../../wallet/cardano'
import {
  MAX_ACCOUNT_COUNT,
  useAddHotAccounts,
  useDiscoverAllHotAccounts,
} from '../../../wallet/cardano'
import {AddHotAccount as _AddHotAccount} from '../sharedActions/addAccount/AddHotAccount'

type AddHotAccountProps = {
  cryptoProviderType: HotVendor
}

export const AddHotAccount = ({cryptoProviderType}: AddHotAccountProps) => {
  const addAccounts = useAddHotAccounts(cryptoProviderType)
  const discoveredAccountsQuery = useDiscoverAllHotAccounts()

  const onSubmit = async (newAccounts: AccountInfo[]) => {
    await addAccounts.mutateAsync({
      newAccounts: newAccounts as CardanoAccountStoredData[],
    })
  }

  return (
    <_AddHotAccount
      blockchain="cardano"
      addAccountsMutation={addAccounts}
      {...{
        onSubmit,
        discoveredAccountsQuery,
        cryptoProviderType,
      }}
      extras={{
        maxAccountLimit: MAX_ACCOUNT_COUNT,
        prevMustBeUsed: true,
      }}
    />
  )
}
