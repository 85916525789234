import {evmBlockchains} from '@nufi/wallet-evm'

import {existingBlockchains} from 'src/blockchainTypes'
import config from 'src/config'
import type {Blockchain, WalletKind} from 'src/wallet/types'

export type AvailableBlockchains = Array<Blockchain> | 'all'

let availableBlockchains: AvailableBlockchains = 'all'

export const setAvailableBlockchains = (
  blockchains: Array<Blockchain> | 'all',
) => {
  availableBlockchains = blockchains
}

export const isBlockchainAvailable = (blockchain: Blockchain): boolean => {
  const isBlockchainConfigEnabled = (() => {
    switch (blockchain) {
      case 'cardano':
      case 'solana':
      case 'flow':
      case 'ethereum':
        return true
      case 'milkomedaC1':
        return config.isMilkomedaC1Enabled
      case 'polygon':
        return config.isPolygonEnabled
      case 'optimism':
        return config.isOptimismEnabled
      case 'arbitrumOne':
        return config.isArbitrumOneEnabled
      case 'base':
        return config.isBaseEnabled
      default:
        return false
    }
  })()

  return (
    (availableBlockchains === 'all' ||
      availableBlockchains.includes(blockchain)) &&
    isBlockchainConfigEnabled
  )
}

export const filterAvailableBlockchains = <T extends Blockchain>(
  blockchains: readonly T[],
): ReadonlyArray<T> =>
  blockchains.filter((blockchain) => isBlockchainAvailable(blockchain))

export const getAvailableBlockchains = (): readonly Blockchain[] => {
  return filterAvailableBlockchains<Blockchain>(existingBlockchains)
}

export function isWalletKindAvailable(walletKind: WalletKind): boolean {
  switch (walletKind) {
    case 'cardano':
    case 'solana':
    case 'flow':
      return isBlockchainAvailable(walletKind)
    case 'evm':
      return evmBlockchains.some((b) => isBlockchainAvailable(b))
    default:
      return false
  }
}
