import {useTranslation} from 'react-i18next'

import type {WalletKind} from 'src/wallet/walletKind'
import {isWalletKind} from 'src/wallet/walletKind'

import type {TokenBlockchain} from '../blockchainTypes'
import type {Blockchain} from '../types'
import type {CryptoProviderType, HwVendor, TokenSearchingKeys} from '../wallet'
import {blockchainMetadata} from '../wallet/constants'
import {getTokenSearchingKeys} from '../wallet/public/utils'

import {safeAssertUnreachable} from './assertion'

export const useGetCoinName = () => {
  const {t} = useTranslation()
  return (blockchain: Blockchain) => {
    const coin = blockchainMetadata[blockchain].coin
    switch (coin) {
      case 'ADA':
        return t('ADA')
      case 'SOL':
        return t('SOL')
      case 'FLOW':
        return t('FLOW')
      case 'ETH':
        return t('ETH')
      case 'MILKADA':
        return t('MILKADA')
      case 'MATIC':
        return t('MATIC')
      default:
        return safeAssertUnreachable(coin)
    }
  }
}

export const useGetTokenSearchingKeyLabel = (blockchain: TokenBlockchain) => {
  const {t} = useTranslation()
  const translations: Record<TokenSearchingKeys[number], string> = {
    name: t('name'),
    mint: t('mint'),
    ticker: t('ticker'),
    id: t('id'),
    policyId: t('policy Id'),
    fingerprint: t('fingerprint'),
  }
  const keys = getTokenSearchingKeys(blockchain)
  return keys.map((key: TokenSearchingKeys[number]) => translations[key])
}

export const useGetBlockchainName = () => {
  const {t} = useTranslation()
  return (blockchain: Blockchain) => {
    switch (blockchain) {
      case 'cardano':
        return t('Cardano')
      case 'solana':
        return t('Solana')
      case 'flow':
        return t('Flow')
      case 'ethereum':
        return t('Ethereum')
      case 'milkomedaC1':
        return t('Milkomeda C1')
      case 'polygon':
        return t('Polygon')
      case 'optimism':
        return t('Optimism')
      case 'arbitrumOne':
        return t('Arbitrum One')
      case 'base':
        return t('Base')
      default:
        return safeAssertUnreachable(blockchain)
    }
  }
}

export const useGetWalletKindName = () => {
  const {t} = useTranslation()
  const getBlockchainName = useGetBlockchainName()
  return (walletKind: WalletKind) => {
    if (walletKind === 'evm') {
      return t('EVM')
    }
    return getBlockchainName(walletKind)
  }
}

export const useGetBlockchainOrWalletKindName = () => {
  const getBlockchainName = useGetBlockchainName()
  const getWalletKindName = useGetWalletKindName()

  return (blockchainOrWalletKind: Blockchain | WalletKind) => {
    return isWalletKind(blockchainOrWalletKind)
      ? getWalletKindName(blockchainOrWalletKind)
      : getBlockchainName(blockchainOrWalletKind)
  }
}

export const useGetNativeAssetName = () => {
  const {t} = useTranslation()
  return (blockchain: Blockchain) => {
    const coin = blockchainMetadata[blockchain].coin
    switch (coin) {
      case 'ADA':
        return t('Cardano')
      case 'SOL':
        return t('Solana')
      case 'FLOW':
        return t('Flow')
      case 'MILKADA':
        return t('MilkADA')
      case 'ETH':
        return t('Ether')
      case 'MATIC':
        return t('Matic')
      default:
        return safeAssertUnreachable(coin)
    }
  }
}

export const useHwVendorNames = () => {
  const {t} = useTranslation()
  const translations: Record<HwVendor, string> = {
    trezor: t('Trezor'), // TODO: refactor to switch as for blockchain
    ledger: t('Ledger'),
    gridPlus: t('GridPlus Lattice1'),
  }
  return translations
}

export const useCryptoProviderTypeNames = () => {
  const {t} = useTranslation()
  const hwVendorNames = useHwVendorNames()

  const translations: Record<CryptoProviderType, string> = {
    ...hwVendorNames,
    mnemonic: t(''),
    metamask: t(''),
  }
  return translations
}
