import {ThemeProvider, createTheme} from '@mui/material'
import type {CardanoAccountInfo} from '@nufi/wallet-cardano'
import type {AccountId} from '@nufi/wallet-common'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {AccountSelectField as _AccountSelectField} from 'src/components'

import theme from '../../theme/theme'

export const AccountSelectField = ({
  accounts,
  selectedAccount,
  setSelectedAccount,
}: {
  accounts: CardanoAccountInfo[]
  selectedAccount: CardanoAccountInfo | null
  setSelectedAccount: (a: CardanoAccountInfo | null) => void
}) => {
  const {t} = useTranslation()
  // override our green theme with white, not to clash with DexHunter's blue colors
  const whiteTheme = createTheme(theme, {
    palette: {
      primary: {
        main: '#FFFFFF',
      },
    },
  })

  const accountItems = accounts.map((a) => ({
    ...a,
    accountId: a.id,
  }))

  return (
    <ThemeProvider theme={whiteTheme}>
      <_AccountSelectField
        label={t('Account')}
        value={selectedAccount?.id || undefined}
        onChange={(e) => {
          const accountId = e.target.value as AccountId
          const account = accounts.find((a) => a.id === accountId)
          setSelectedAccount(account ?? null)
        }}
        items={accountItems}
        blockchain={'cardano'}
        hideCopyAddress
        showBlockchainIcon
      />
    </ThemeProvider>
  )
}
