import type {FormikProps, FormikHelpers} from 'formik'
import {Formik} from 'formik'
import React, {useEffect, useState} from 'react'

import {QueryGuard, StakingModalError} from '../../../../components'
import config from '../../../../config'
import {useTrackTxSubmission} from '../../../../tracking'
import type {AppError} from '../../../../types'
import {assert} from '../../../../utils/assertion'
import {useVerifyPassword} from '../../../../utils/form'
import type {AccountId} from '../../../../wallet'
import type {
  CardanoSignedTx,
  CardanoStakeAccountInfo,
  CardanoTxPlan,
  CardanoTxVoteDelegation,
} from '../../../../wallet/cardano'
import {
  useGetWithdrawRewardsTxPlan,
  useSignTransaction,
  useGetStakeAccounts,
  useSubmitTransaction,
  CardanoTxDRepType,
} from '../../../../wallet/cardano'
import {ensureAccountById} from '../../../../wallet/utils/common'
import type {ActiveScreen} from '../../../send/common/utils'
import {
  SignTxFlow,
  useActiveSchema,
  useActiveScreenState,
  useFormikOnSubmit,
  useHandleTxOnSubmit,
  WithActiveScreenState,
  WithdrawSubmitScreen,
  WithCardanoExtras,
} from '../../../transaction'
import type {RenderSubmitGuard} from '../../../utils/debouncedFormUtils/debouncing'
import {
  getCardanoTxPlanDebounceTimeoutInMs,
  useAsyncDebouncedState,
  useSubmitGuard,
} from '../../../utils/debouncedFormUtils/debouncing'
import {useSummaryDetailsSchema} from '../../common/schema'
import {DeviceReadyState, WithdrawModalHeader} from '../../common/utils'
import type {WithdrawRewardsSchema} from '../schema'
import {useWithdrawRewardsSchema} from '../schema'

import WithdrawSummaryScreen from './WithdrawSummaryScreen'

type FormSchema = WithdrawRewardsSchema

type UICachedTxPlan = {
  data: CardanoTxPlan | null
  error: AppError | null
}

export default function CardanoWithdrawModal({
  onClose,
  stakeAccountId,
}: {
  onClose: () => unknown
  stakeAccountId: AccountId
}) {
  const stakeAccountsQuery = useGetStakeAccounts()
  return (
    <WithActiveScreenState initialScreen="summary">
      <QueryGuard
        {...stakeAccountsQuery}
        ErrorElement={<StakingModalError blockchain="cardano" />}
        loadingVariant="centered"
      >
        {(stakeAccounts) => (
          <CardanoWithdrawForm {...{onClose, stakeAccounts, stakeAccountId}} />
        )}
      </QueryGuard>
    </WithActiveScreenState>
  )
}

type CardanoWithdrawFormProps = {
  onClose: () => unknown
  stakeAccounts: CardanoStakeAccountInfo[]
  stakeAccountId: AccountId
}

function CardanoWithdrawForm({
  onClose,
  stakeAccounts,
  stakeAccountId,
}: CardanoWithdrawFormProps) {
  const sign = useSignTransaction()
  const submit = useSubmitTransaction()
  const withdrawRewardsScheme = useWithdrawRewardsSchema()
  const {setActiveScreen} = useActiveScreenState()
  const summaryDetailsSchema = useSummaryDetailsSchema({
    accounts: stakeAccounts,
  })

  const [txPlan, setTxPlan] = useState<UICachedTxPlan>({
    error: null,
    data: null,
  })

  const schema = useActiveSchema({
    details: {},
    summary: {
      ...withdrawRewardsScheme,
      ...summaryDetailsSchema,
    },
  })

  const initialValues: FormSchema = {
    accountId: stakeAccountId as unknown as AccountId,
    password: '',
  }

  const onTxSign = async (values: FormSchema) => {
    assert(txPlan.data != null, 'Tx plan must be defined')

    return await sign.mutateAsyncSilent({
      accountId: values.accountId as AccountId,
      txPlan: txPlan.data,
    })
  }

  const onTxSubmit = async (
    accountId: AccountId,
    signedTx: CardanoSignedTx,
  ) => {
    assert(txPlan.data != null, 'Tx plan must be defined')

    return await submit.mutateAsyncSilent({
      signedTx,
      accountId,
      txPlan: txPlan.data,
    })
  }

  const onTxSignAndSubmit = async (values: FormSchema) => {
    const signedTx = await onTxSign(values)
    if (!signedTx) return
    setActiveScreen('submit')
    await onTxSubmit(stakeAccountId as unknown as AccountId, signedTx)
  }

  const verifyPassword = useVerifyPassword()
  const handleTxOnSubmit = useHandleTxOnSubmit({onTxSignAndSubmit})

  const {onPreSubmit, renderSubmitGuard} = useSubmitGuard<FormSchema>({
    onSubmit: async (values) => {
      const stakeAccount = ensureAccountById(stakeAccounts, values.accountId)
      await handleTxOnSubmit({
        account: stakeAccount,
        values,
      })
    },
  })

  const onSubmit = useFormikOnSubmit<FormSchema>({
    accounts: stakeAccounts,
    onTxSignAndSubmit,
    onSummary: async (values, formikHelpers: FormikHelpers<FormSchema>) => {
      const passwordIsCorrect = await verifyPassword(
        values.password,
        formikHelpers,
      )
      if (!passwordIsCorrect) return
      onPreSubmit(values, formikHelpers)
    },
  })

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={onSubmit}
    >
      {(formikProps) => (
        <CardanoWithdrawModalContent
          {...{
            formikProps,
            stakeAccounts,
            setActiveScreen,
            onClose,
            onTxSubmit,
            onTxSignAndSubmit,
            txPlan,
            setTxPlan,
            stakeAccountId,
            renderSubmitGuard,
          }}
          submitProps={submit}
          signProps={sign}
        />
      )}
    </Formik>
  )
}

type CardanoWithdrawModalContentProps = {
  setActiveScreen: (screen: ActiveScreen) => void
  stakeAccounts: CardanoStakeAccountInfo[]
  stakeAccountId: AccountId
  formikProps: FormikProps<FormSchema>
  submitProps: ReturnType<typeof useSubmitTransaction>
  signProps: ReturnType<typeof useSignTransaction>
  onClose: () => unknown
  setTxPlan: (txPlan: UICachedTxPlan) => void
  txPlan: UICachedTxPlan
  onTxSubmit: (
    accountId: AccountId,
    signedTx: CardanoSignedTx,
  ) => Promise<unknown>
  onTxSignAndSubmit: (values: FormSchema) => Promise<unknown>
  renderSubmitGuard: RenderSubmitGuard<FormSchema>
}

function CardanoWithdrawModalContent({
  stakeAccounts,
  setActiveScreen,
  formikProps,
  submitProps,
  onClose,
  txPlan,
  setTxPlan,
  onTxSubmit,
  onTxSignAndSubmit,
  signProps,
  renderSubmitGuard,
}: CardanoWithdrawModalContentProps) {
  const fetchWithdrawRewardsTxPlan = useGetWithdrawRewardsTxPlan()

  const {values} = formikProps
  const stakeAccount = ensureAccountById(stakeAccounts, values.accountId)

  const cardanoNetwork = config.cardanoNetwork
  const voteDelegation: CardanoTxVoteDelegation | null =
    cardanoNetwork === 'sanchonet' && stakeAccount.voteDelegation == null
      ? {
          dRep: {
            type: CardanoTxDRepType.ALWAYS_ABSTAIN,
          },
        }
      : null

  const {
    onDataChange,
    finished: debouncedCalcFinished,
    submitGuardMeta,
  } = useAsyncDebouncedState<FormSchema, CardanoTxPlan>({
    timeout: getCardanoTxPlanDebounceTimeoutInMs(),
    state: txPlan,
    setState: setTxPlan,
    shouldSkip: false,
    fn: async () =>
      await fetchWithdrawRewardsTxPlan.mutateAsync({
        accountInfo: stakeAccount,
        voteDelegation,
      }),
    leading: true,
  })

  // try recalculating txPlan
  useEffect(() => {
    onDataChange({values, formikProps})
  }, [values.accountId])

  const blockchain = 'cardano'
  const ModalHeader = <WithdrawModalHeader {...{blockchain, onClose}} />

  const onAccountChange = (accountId: AccountId) => {
    setActiveScreen('summary')
    formikProps.setFieldValue('accountId', accountId)
  }

  const filteredStakeAccounts = stakeAccounts.filter(
    ({rewards, balance}) => rewards.gt(0) && balance.gt(0),
  )

  useTrackTxSubmission(submitProps, {
    blockchain,
    provider: stakeAccount.cryptoProviderType,
    type: 'withdraw_rewards',
    value: stakeAccount.rewards.toNumber(),
  })

  return (
    <WithCardanoExtras
      extras={{txPlan: txPlan.data, txHash: signProps.data?.txHash}}
    >
      <SignTxFlow
        {...{
          onClose,
          formikProps,
          ModalHeader,
          blockchain,
          signProps,
          submitProps,
          DeviceReadyState,
          onTxSignAndSubmit,
        }}
        onTxSubmit={onTxSubmit}
        initialScreen="summary"
        renderSummary={() => {
          const summary = {
            fee: txPlan.data?.fee,
            availableWithdrawAmount: stakeAccount.rewards,
            fromAccount: stakeAccount,
            isLoading: !debouncedCalcFinished,
            error: txPlan.error,
          }
          return (
            <>
              {renderSubmitGuard({formikProps, items: [submitGuardMeta]})}
              <WithdrawSummaryScreen
                onBack={onClose}
                onSubmit={formikProps.handleSubmit}
                stakeAccounts={filteredStakeAccounts}
                disabled={!!txPlan.error}
                {...{summary, formikProps, onClose, blockchain}}
              />
            </>
          )
        }}
        renderSubmit={(signAndSubmitUtils) => {
          const signedTransaction = signProps.data
          assert(!!signedTransaction)
          const rewardAccountLeft = filteredStakeAccounts.find(
            ({id}) => id !== stakeAccount.id,
          )
          return (
            <WithdrawSubmitScreen
              accountId={stakeAccount.id}
              onBack={signAndSubmitUtils.onSignAndSubmitGoBack}
              onRetry={() => onTxSubmit(stakeAccount.id, signedTransaction)}
              {...(rewardAccountLeft
                ? {
                    onNewWithdrawal: () => {
                      signAndSubmitUtils.onSignAndSubmitGoBack()
                      formikProps.resetForm()
                      if (rewardAccountLeft) {
                        onAccountChange(rewardAccountLeft.id)
                      }
                    },
                  }
                : {})}
              {...{onClose, submitProps, blockchain, ModalHeader}}
            />
          )
        }}
      />
    </WithCardanoExtras>
  )
}
