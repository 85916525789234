import type {SelectChangeEvent} from '@mui/material'
import {
  Box,
  Typography,
  Grid,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  ListSubheader,
} from '@mui/material'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {
  conversionRatesService,
  suggestedCurrenciesToCurrencyId,
} from 'src/features/conversionRates/application'
import type {Currency} from 'src/features/conversionRates/domain'
import {useSetCurrency, useSettings} from 'src/features/profile/application'

import {MutationGuard} from '../../components'

import {useCommonStyles, ConfirmationStatusWrapper} from './utils'

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 400,
    },
  },
  disableAutoFocusItem: true,
}

function CurrencyMenuItem(props: {value: string; currency: string}) {
  return (
    <MenuItem {...props} sx={{ml: 1}}>
      <Grid container justifyContent="space-between">
        <Typography>{props.currency}</Typography>
      </Grid>
    </MenuItem>
  )
}

export default function ChangeCurrency() {
  const {currency} = useSettings()
  const commonClasses = useCommonStyles()
  const {t} = useTranslation()
  const [formData, setFormData] = useState({currency})
  const [uxLoading, setUxLoading] = useState(false)
  const [showConfirmed, setShowConfirmed] = useState(false)
  const saveCurrency = useSetCurrency()

  const onChange = async (e: SelectChangeEvent<Currency>) => {
    const value = e.target.value as Currency
    setFormData({currency: value})
    setShowConfirmed(false)
    setUxLoading(true)
    await new Promise((resolve) => setTimeout(resolve, 600))
    await saveCurrency.mutateAsyncSilent({currency: value})
    setUxLoading(false)
    setShowConfirmed(true)
  }

  const label = t('Currency')

  return (
    <>
      <Box className={commonClasses.sectionWrapper}>
        <ConfirmationStatusWrapper isVisible={showConfirmed}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel>{label}</InputLabel>
            <Select
              label={label}
              value={formData.currency}
              onChange={onChange}
              data-test-id="default-currency-select"
              MenuProps={MenuProps}
              renderValue={(selected) => <Typography>{selected}</Typography>}
            >
              <ListSubheader>{t('Suggested')}</ListSubheader>
              {Object.keys(suggestedCurrenciesToCurrencyId).map((currency) => (
                <CurrencyMenuItem
                  value={currency}
                  key={currency}
                  {...{currency}}
                />
              ))}
              <ListSubheader>{t('All')}</ListSubheader>
              {Object.keys(
                conversionRatesService.allCurrenciesToCurrencyId,
              ).map((currency) => (
                <CurrencyMenuItem
                  value={currency}
                  key={currency}
                  {...{currency}}
                />
              ))}
            </Select>
          </FormControl>
        </ConfirmationStatusWrapper>
        <MutationGuard
          {...saveCurrency}
          isPending={saveCurrency.isPending || uxLoading}
        />
      </Box>
    </>
  )
}
