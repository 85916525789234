import React from 'react'

import type {SigningKind} from 'src/store/dappConnector'
import {useDappConnectorStore} from 'src/store/dappConnector'
import {assert} from 'src/utils/assertion'

import {isEvmBlockchain} from '../../../wallet/evm'

import {layoutProps} from './constants'
import {EvmSign} from './customBlockchains/evm'
import {SolanaSign} from './customBlockchains/solana'
import {SignScreen as GenericSignScreen} from './Sign'
import type {SignState} from './SignContext'
import {SignContext} from './SignContext'

// TODO: keep in extension folder in distinguish by other means
export const DappConnectorSignScreen = () => {
  const {
    screenInfo,
    setIdleScreen,
    origin,
    blockchain,
    selectedAccount,
    favIconUrl,
  } = useDappConnectorStore()

  assert(
    screenInfo.state === 'sign-tx' ||
      screenInfo.state === 'sign-message' ||
      screenInfo.state === 'sign-vote' ||
      screenInfo.state === 'get-hw-wallet-public-keys',
    'Sign: invalid screen type',
  )

  return (
    <SignScreen
      signState={{
        blockchain,
        favIconUrl,
        selectedAccount,
        origin,
        signData: {
          ...screenInfo,
          onSign: async (data) => {
            const sign = await screenInfo.onSign(data)
            return {
              onFinish: () => {
                sign.onFinish()
                setIdleScreen()
              },
            }
          },
          onFailure: () => {
            screenInfo.onFailure()
            setIdleScreen()
          },
        },
        layoutProps,
      }}
    />
  )
}

export function SignScreen({signState}: {signState: SignState<SigningKind>}) {
  return (
    <SignContext.Provider value={signState}>
      {(() => {
        if (isEvmBlockchain(signState.blockchain)) {
          return <EvmSign />
        } else if (signState.blockchain === 'solana') {
          return <SolanaSign />
        } else {
          return <GenericSignScreen />
        }
      })()}
    </SignContext.Provider>
  )
}
