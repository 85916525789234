import create from 'zustand'

import logger from 'src/store/logger'

import type {LocalCancelingOrderStore} from '../domain'

type DexHunterState = LocalCancelingOrderStore

export const useDexHunterState = create<DexHunterState>(
  logger<DexHunterState>('DexHunterState:')((set, get) => ({
    localCancellingOrderIds: new Set(),
    registerCancellingOrderId: (orderId) =>
      set({
        localCancellingOrderIds: new Set([
          orderId,
          ...get().localCancellingOrderIds,
        ]),
      }),
    unregisterCancellingOrderId: (orderId) =>
      set({
        localCancellingOrderIds: new Set(
          [...get().localCancellingOrderIds].filter((item) => item !== orderId),
        ),
      }),
  })),
)
