import BigNumber from 'bignumber.js'
import _ from 'lodash'

import {convertBalance} from 'src/features/assets/domain/conversions'
import type {
  ConversionRates,
  Currency,
} from 'src/features/conversionRates/domain'

import type {Blockchain, TokenAmount, TokenMetadata} from '../types'

export const calculateTotalAssetValue = (
  balances: {
    coins: Partial<Record<Blockchain, BigNumber>>
    tokens: Partial<Record<Blockchain, TokenAmount[]>>
  },
  currency: Currency,
  conversionRates: ConversionRates,
  tokensMetadata: Partial<Record<Blockchain, TokenMetadata[]>>,
): number => {
  const coinAssetKeys = Object.keys(balances.coins) as Blockchain[]

  const nativeBalance = _.sum(
    coinAssetKeys.map((blockchain) => {
      const convertedBalanceRes = convertBalance({
        balance: balances.coins[blockchain] ?? new BigNumber(0),
        blockchain,
        currency,
        conversionRates,
      })
      if (convertedBalanceRes.type === 'success') {
        return convertedBalanceRes.balance
      }
      return 0
    }),
  )

  const tokenAssetKeys = Object.keys(balances.tokens) as Blockchain[]

  const tokenBalance = _.chain(tokenAssetKeys)
    .flatMap((blockchain) =>
      balances.tokens[blockchain]?.map((tokenAmount) => ({
        tokenMeta: tokensMetadata[blockchain]?.find(
          (meta) => meta.id === tokenAmount.token.id,
        ),
        tokenAmount,
      })),
    )
    .compact()
    .map(({tokenAmount, tokenMeta}) => {
      if (tokenMeta) {
        const convertBalanceRes = convertBalance({
          balance: tokenAmount.amount,
          blockchain: tokenMeta.blockchain,
          currency,
          conversionRates,
          tokenMetadata: tokenMeta,
        })
        return convertBalanceRes.type === 'success'
          ? convertBalanceRes.balance
          : 0
      }
      return 0
    })
    .sum()
    .value()

  return nativeBalance + tokenBalance
}
