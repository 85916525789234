import {Cancel as ClearInputIcon} from '@mui/icons-material'
import {TextField as MuiTextField, InputAdornment} from '@mui/material'
import type {TextFieldProps as MuiTextFieldProps} from '@mui/material/TextField'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'

type ClearableTextFieldProps = MuiTextFieldProps & {
  onClear: () => unknown
}

export default function ClearableTextField(props: ClearableTextFieldProps) {
  const {value, onClear, ...restProps} = props
  const classes = useStyles()

  return (
    <MuiTextField
      InputProps={{
        endAdornment: typeof value === 'string' && value && (
          <InputAdornment position="end">
            <ClearInputIcon
              className={classes.clearIcon}
              color="action"
              onClick={onClear}
            />
          </InputAdornment>
        ),
      }}
      {...restProps}
      value={value}
    />
  )
}

const useStyles = makeStyles(() => ({
  clearIcon: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
}))
