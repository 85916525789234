import {Box, Grid} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import type {SolanaValidatorInfo} from '@nufi/wallet-solana'
import {
  isValidSolanaPubKey,
  createEmptyValidatorInfoFromId,
  findValidator,
} from '@nufi/wallet-solana'
import clsx from 'clsx'
import type {FormikProps} from 'formik'
import React from 'react'
import {useTranslation} from 'react-i18next'

import type {StakingBlockchain} from '../../../../blockchainTypes'
import {
  ModalFooter,
  ModalLayout,
  FooterLayout,
  Alert,
  ValidatorSelect,
  useModalSharedStyles,
  ellipsizeString,
} from '../../../../components'
import {getHasFormError} from '../../../../utils/form'
import {StakeModalHeader} from '../../common/utils'
import type {BaseActivateStakeSchema} from '../schema'
import {
  getRecommendedValidatorOption,
  getRecommendedValidatorId,
} from '../utils'

import {VALIDATOR_ELLIPSIZE_LENGTH} from './constants'
import FormattedValidatorInfo from './StakeModalValidatorInfo'

const FORM_ID = 'activate-stake-form-details'

type DetailsScreenProps<T extends BaseActivateStakeSchema> = {
  blockchain: StakingBlockchain
  formikProps: FormikProps<T>
  disabled?: boolean
  onClose: () => void
  validators: SolanaValidatorInfo[]
}

export default function DetailsScreen<T extends BaseActivateStakeSchema>({
  disabled,
  formikProps,
  blockchain,
  onClose,
  validators,
}: DetailsScreenProps<T>) {
  const classes = {...useStyles(), ...useModalSharedStyles()}
  const {t} = useTranslation()

  const {values, handleChange, errors} = formikProps
  type FormField = keyof typeof values

  const getOptionLabel = (validator: SolanaValidatorInfo): string => {
    const pubKeyString = validator.publicKey.toString()
    const ellipsizedPubKey = ellipsizeString(
      pubKeyString,
      VALIDATOR_ELLIPSIZE_LENGTH,
      VALIDATOR_ELLIPSIZE_LENGTH,
    )
    return `${
      validator.metadata?.name || t('Unknown name')
    } (${ellipsizedPubKey})`
  }

  const hasError = getHasFormError(formikProps)
  const recommendedOptionId = getRecommendedValidatorId(validators)

  return (
    <ModalLayout
      header={<StakeModalHeader {...{onClose, blockchain}} />}
      body={
        <Box className={classes.contentWrapper}>
          <Box p={2}>
            <form onSubmit={formikProps.handleSubmit} noValidate id={FORM_ID}>
              <Grid container>
                <Grid item xs={12}>
                  <Box mb={2}>
                    <Alert
                      text={t(
                        'Choose a validator and activate staking. When your staking account is activated, rewards will be sent to your reward balance automatically according to the rewards schedule.',
                      )}
                      severity="info"
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box
                    className={clsx(
                      classes.commonTopMargin,
                      classes.commonBottomMargin,
                    )}
                  >
                    <ValidatorSelect
                      getOptionId={({publicKey}) => publicKey.toString()}
                      label={t('Validator Name, Id, ...')}
                      labelWhenSelected={t('Selected validator')}
                      renderOption={getOptionLabel}
                      getOptionLabel={getOptionLabel}
                      getOptionValid={isValidSolanaPubKey}
                      options={validators}
                      recommendedOption={getRecommendedValidatorOption(
                        validators,
                        recommendedOptionId,
                      )}
                      recommendedGroupLabel={t('Recommended validator')}
                      otherGroupLabel={t('Other')}
                      getOptionKeyword={({metadata, publicKey}) =>
                        `${metadata?.name},${publicKey.toString()}`
                      }
                      value={
                        findValidator(validators, values.validatorId) || null
                      }
                      createNotFoundOptionFromInput={
                        createEmptyValidatorInfoFromId
                      }
                      onChange={(validator) => {
                        handleChange<FormField>('validatorId')(
                          validator?.publicKey.toString() || '',
                        )
                      }}
                      error={hasError('validatorId')}
                      helperText={
                        hasError('validatorId') && <>{errors.validatorId}</>
                      }
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} className={classes.validatorInfo}>
                  {values.validatorId && (
                    <FormattedValidatorInfo
                      validatorInfo={findValidator(
                        validators,
                        values.validatorId,
                      )}
                    />
                  )}
                </Grid>
              </Grid>
            </form>
          </Box>
        </Box>
      }
      footer={
        <ModalFooter hasDivider>
          <Grid item xs={12}>
            <FooterLayout
              leftBtnConf={{
                onClick: onClose,
                children: t('Back'),
              }}
              rightBtnConf={{
                children: t('Continue'),
                type: 'submit',
                form: FORM_ID,
                disabled,
              }}
            />
          </Grid>
        </ModalFooter>
      }
    />
  )
}

const useStyles = makeStyles((theme) => ({
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
  },
  validatorInfo: {
    marginLeft: theme.spacing(0.25),
  },
}))
