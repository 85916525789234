// Kept in .ts file so it can be imported from service-worker

import type {FlowTokenId} from '@nufi/wallet-flow'
import {parseFlowTokenId, getFlowdiverContractId} from '@nufi/wallet-flow'

import config from '../../../config'

const flowdiverBaseUrl = `https://${
  config.flowNetwork === 'testnet' ? 'testnet.' : ''
}flowdiver.io`

export const getFlowAddressExplorerLink = (address: string) =>
  `${flowdiverBaseUrl}/account/${address}`

export const getFlowTransactionExplorerLink = (txId: string) =>
  `${flowdiverBaseUrl}/tx/${txId}`

export const getFlowTokenExplorerLink = (tokenId: FlowTokenId) => {
  const {contractAddress, contractName} = parseFlowTokenId(tokenId)
  return `${flowdiverBaseUrl}/contract/${getFlowdiverContractId(
    contractAddress,
    contractName,
  )}`
}
