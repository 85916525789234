import {sleep} from '@nufi/frontend-common'
import {useIsFetching, useIsMutating} from '@tanstack/react-query'
import {useCallback, useEffect, useRef, useState} from 'react'

import {reloadAppState} from 'src/features/walletStorage/application/reloadAppState'

import {useSyncProfileData} from '../appStorage'

export function useRefetch(performProfileSync: boolean = true) {
  const syncProfileData = useSyncProfileData()

  const isFetching = useIsFetching() > 0
  const isMutating = useIsMutating() > 0

  const refetch = useCallback(async () => {
    let shouldReloadAppState = true
    if (performProfileSync) {
      const syncResult = await syncProfileData.mutateAsync()
      shouldReloadAppState =
        syncResult.status !== 'success' || !syncResult.appStateReloaded
    }

    if (shouldReloadAppState) {
      await reloadAppState()
    }
  }, [performProfileSync])

  const {isFakeLoading, throttledFn: onThrottledRefetch} =
    useThrottleWithFakeLoading(refetch, {
      throttleInterval: 30_000,
    })

  const isRefetching = isFetching || isMutating || isFakeLoading

  return {refetch: onThrottledRefetch, isRefetching}
}

/**
 * Used to ensure that even small loading states are noticeable by users, and to have
 * smoother UX.
 * @param isFetching actual fetching state of some operation
 * @returns boolean indicating loading state, that when set to `true` last for at least 2 seconds
 */
export const useNoticeableLoading = (isFetching: boolean): boolean => {
  const [noticeableLoading, setNoticeableLoading] = useState(isFetching)
  const timeout = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    if (isFetching && !timeout.current) {
      setNoticeableLoading(true)
      timeout.current = setTimeout(() => {
        timeout.current = null
        setNoticeableLoading(false)
      }, 2000)
    }
  }, [isFetching])

  return noticeableLoading || isFetching
}

/**
 * Used to ensure that a function run at most once within throttle interval.
 * @param fn function to throttle
 * @param options configuration options
 * @returns isFakeLoading: boolean, set to true when throttling is active. Represents tmp fake loading state
 * @returns throttledFn: throttled original function that sets `isFakeLoading` when throttled
 */
export const useThrottleWithFakeLoading = <T>(
  fn: () => Promise<T>,
  options: {
    throttleInterval: number
  },
) => {
  const lastRefreshTime = useRef<number | null>(null)
  const [isFakeLoading, setIsFakeLoading] = useState(false)

  const {throttleInterval} = options

  const throttledFn = useCallback(async () => {
    const currentTime = Date.now()
    const shouldFakeLoad =
      lastRefreshTime.current &&
      currentTime - lastRefreshTime.current < throttleInterval

    if (shouldFakeLoad) {
      setIsFakeLoading(true)
      await sleep(2000)
      setIsFakeLoading(false)
      return
    } else {
      lastRefreshTime.current = Date.now()
      await fn()
    }
  }, [setIsFakeLoading, fn, throttleInterval])

  return {isFakeLoading, throttledFn}
}
