import {HelpOutline as HelpIcon} from '@mui/icons-material'
import {Box, Checkbox, FormControlLabel, FormGroup} from '@mui/material'
import {assert} from '@nufi/frontend-common'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {LabeledIcon, WithTooltip} from 'src/components'

import {SignScreen} from '../Sign'
import {useSignContext} from '../SignContext'
import type {BlockchainTxDataProps} from '../txData/types'

const SOLANA_CONNECTOR_TRY_SENDING_VIA_NUFI_RPC_KEY =
  'solanaConnectorTrySendingViaNuFiRpc'

export function SolanaSign() {
  const {signData} = useSignContext()

  if (signData.state === 'sign-tx') {
    return <SolanaSignTx />
  }

  return <SignScreen />
}

function SolanaSignTx() {
  const {t} = useTranslation()
  const {signData} = useSignContext()
  assert(signData.state === 'sign-tx', 'Sign: invalid screen type')
  const {requestType} = signData.data.data as BlockchainTxDataProps<'solana'>
  const [forceTxSending, setForceTxSending] = useState(
    window.localStorage.getItem(
      SOLANA_CONNECTOR_TRY_SENDING_VIA_NUFI_RPC_KEY,
    ) === 'true' ?? false,
  )

  return (
    <>
      <SignScreen
        extraOptionsProps={{
          type: 'solana',
          data: {forceTxSending},
          showOptions: false,
          onShowOptions: () => {},
          onHideOptions: () => {},
          onTxSignReset: () => {},
          onTxSignStart: () => {},
          customOptionsScreen: <></>,
        }}
      />
      {requestType === 'sign' && (
        <Box mt={2}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={forceTxSending}
                  onChange={() => {
                    const newSetting = !forceTxSending
                    window.localStorage.setItem(
                      SOLANA_CONNECTOR_TRY_SENDING_VIA_NUFI_RPC_KEY,
                      newSetting ? 'true' : 'false',
                    )
                    setForceTxSending(newSetting)
                  }}
                />
              }
              label={
                <LabeledIcon
                  justifyContent="center"
                  Label={t('solana_dapp_connector_try_sending_via_nufi_node')}
                  Icon={
                    <WithTooltip
                      title={t(
                        'solana_dapp_connector_try_sending_via_nufi_node_tooltip',
                      )}
                    >
                      <HelpIcon />
                    </WithTooltip>
                  }
                />
              }
            />
          </FormGroup>
        </Box>
      )}
    </>
  )
}
