import {createTheme} from '@mui/material/styles'

import {breakpoints} from './breakpoints'
import components from './components'
import {palette} from './palette'
import {typography} from './typography'

import './css/fonts.css'

export default createTheme({
  breakpoints,
  palette,
  typography,
  components,
})
