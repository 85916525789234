import {Box, Typography, Paper, styled, Divider} from '@mui/material'
import React from 'react'
import {Trans, useTranslation} from 'react-i18next'

import {
  Alert,
  BatchQueryGuard,
  InlineLoading,
  MutationGuard,
  QueryGuard,
} from '../../../../../components'
import {TokenDetailInfo} from '../../../../../pages/portfolio/account/actions/importToken/evm/common'
import type {TokenCardBalanceLoaderProps} from '../../../../../pages/portfolio/account/actions/importToken/evm/SummaryScreen'
import {TokenCardBalanceLoader} from '../../../../../pages/portfolio/account/actions/importToken/evm/SummaryScreen'
import {EvmImportTokenBalanceInfo} from '../../../../../pages/portfolio/account/actions/importToken/evm/TokenDetailInfo'
import {BaseTokenInfo} from '../../../../../pages/portfolio/account/actions/importToken/ImportTokenUtils'
import {AboutRow} from '../../../../../pages/portfolio/assetDetail/AboutRow'
import {EvmTokenDetailInfo} from '../../../../../pages/portfolio/evm/TokenDetailInfo'
import type {
  ImportEvmAssetScreenInfo,
  ImportEvmTokenParams,
} from '../../../../../store/dappConnector'
import {useDappConnectorStore} from '../../../../../store/dappConnector'
import {assert} from '../../../../../utils/assertion'
import type {
  EvmAccountOfflineInfo,
  EvmAddress,
  EvmBlockchain,
  EvmErc20Metadata,
  EvmProfileTokenImportInfo,
  EvmTokenId,
  GetErc20TokenBalance,
  GetErc20TokenList,
  GetProfileTokenImports,
  ImportEvmTokens,
  TokenStandard,
} from '../../../../../wallet/evm'
import {
  getEvmNetworkConfig,
  isEvmBlockchain,
  useErc20TokenList,
  useGetProfileTokenImports,
  useGetTokenMetadata,
  useImportEvmTokens,
} from '../../../../../wallet/evm'
import {getEvmManager} from '../../../../../wallet/evm/evmManagers'
import {getEvmTokenId} from '../../../../../wallet/evm/sdk/utils'
import {
  ActionButtons,
  PrimaryActionButton,
  RejectButton,
} from '../../../../components'

import type {TokenAuditResult} from './tokenAudit'
import {auditToken, TokenAuditWarnings} from './tokenAudit'
import type {TokenDifferences} from './tokenEffects/common'
import {getTokenImportEffect} from './tokenEffects/tokenEffects'
import {TokenEffectsInfo} from './tokenEffects/TokenEffectsInfo'
import type {UnsupportedCaseProps} from './utils'
import {
  AlertWithMargin,
  importEvmTokenTestIds,
  ImportTokenLayout,
  UnsupportedCase,
} from './utils'

export function ImportEvmToken() {
  const {blockchain, screenInfo, selectedAccount} = useDappConnectorStore()

  assert(screenInfo.state === 'import-evm-token')
  assert(selectedAccount != null)
  assert(isEvmBlockchain(blockchain))

  type Blockchain = typeof blockchain

  return (
    <ImportEvmTokenContent
      {...{blockchain}}
      selectedAccount={selectedAccount as EvmAccountOfflineInfo<Blockchain>}
      screenInfo={screenInfo as ImportEvmAssetScreenInfo<Blockchain>}
    />
  )
}

export type ImportEvmTokenContentProps<TBlockchain extends EvmBlockchain> = {
  blockchain: TBlockchain
  selectedAccount: EvmAccountOfflineInfo<TBlockchain>
  screenInfo: ImportEvmAssetScreenInfo<TBlockchain>
  // Supplied to easily unit test
  importTokens?: ImportEvmTokens
  getProfileTokenImports?: GetProfileTokenImports
  getErc20TokenBalance?: GetErc20TokenBalance
  getErc20TokenList?: GetErc20TokenList
}

const supportedImportTokenStandards: TokenStandard[] = ['ERC20']

export function ImportEvmTokenContent<TBlockchain extends EvmBlockchain>({
  blockchain,
  screenInfo,
  selectedAccount,
  importTokens,
  getErc20TokenBalance,
  getProfileTokenImports,
  getErc20TokenList,
}: ImportEvmTokenContentProps<TBlockchain>) {
  const importTokensMutation = useImportEvmTokens(blockchain, importTokens)
  const profileTokenImportsQuery = useGetProfileTokenImports(
    blockchain,
    getProfileTokenImports,
  )
  const useTokenListQuery = useErc20TokenList(blockchain, getErc20TokenList)

  const accountAddress = selectedAccount.address
  const onConfirm = async (metadata: EvmErc20Metadata<TBlockchain>) => {
    await importTokensMutation.mutateAsync({
      tokens: [metadata],
      address: accountAddress,
    })
    screenInfo.onImport()
  }

  return (
    <WithValidatedInput {...{screenInfo, blockchain}}>
      {(tokenId) => (
        <BatchQueryGuard
          queries={{
            profileTokenImports: profileTokenImportsQuery,
            tokenList: useTokenListQuery,
          }}
        >
          {({tokenList, profileTokenImports}) => {
            const {
              networkConfig: {chainId},
            } = getEvmManager(blockchain)

            const tokenAuditResult = auditToken({
              blockchain,
              chainId,
              accountAddress,
              profileTokenImports,
              tokenList,
              tokenName: screenInfo.data.options.name,
              tokenTicker: screenInfo.data.options.symbol,
              contractAddress: screenInfo.data.options.address,
            })

            const commonProps = {
              blockchain,
              getErc20TokenBalance,
              accountAddress,
              tokenAuditResult,
              requestData: screenInfo.data,
              onClose: screenInfo.onRejected,
              onSubmit: onConfirm,
            }

            const whitelistToken = tokenList[tokenId]
            if (whitelistToken != null) {
              return (
                <ImportTokenLayout type="import">
                  <WhiteListedTokenCase
                    token={whitelistToken}
                    // We want to signal to the app that user can recognize the token
                    onClose={screenInfo.onImport}
                  />
                </ImportTokenLayout>
              )
            }

            // Revisit if refactoring TokenStoreManagerProvider
            const existingProfileTokenImport = profileTokenImports.find(
              (p) =>
                p.owner === accountAddress &&
                p.token.id === tokenId &&
                p.token.blockchain === blockchain &&
                // user tokens are added per network
                p.token.chainId === chainId,
            )

            return (
              <>
                {(() => {
                  if (existingProfileTokenImport) {
                    return (
                      <ImportTokenLayout type="update">
                        <ExistingProfileTokenImportCase
                          {...commonProps}
                          profileTokenImport={existingProfileTokenImport}
                        />
                      </ImportTokenLayout>
                    )
                  }

                  return (
                    <ImportTokenLayout type="import">
                      <NewTokenCase {...commonProps} {...{tokenId}} />
                    </ImportTokenLayout>
                  )
                })()}
                <MutationGuard {...importTokensMutation} />
              </>
            )
          }}
        </BatchQueryGuard>
      )}
    </WithValidatedInput>
  )
}

type CommonTokenCaseProps<TBlockchain extends EvmBlockchain> = {
  blockchain: TBlockchain
  onClose: () => void
  onSubmit: (metadata: EvmErc20Metadata<TBlockchain>) => void
  accountAddress: EvmAddress<TBlockchain>
  getErc20TokenBalance?: GetErc20TokenBalance
  tokenAuditResult: TokenAuditResult
}

type ExistingProfileTokenImportCaseProps<TBlockchain extends EvmBlockchain> =
  CommonTokenCaseProps<TBlockchain> & {
    requestData: ImportEvmTokenParams<TBlockchain>
    profileTokenImport: EvmProfileTokenImportInfo<TBlockchain>
  }

function ExistingProfileTokenImportCase<TBlockchain extends EvmBlockchain>({
  blockchain,
  requestData,
  profileTokenImport,
  getErc20TokenBalance,
  tokenAuditResult,
  accountAddress,
  onClose,
  onSubmit,
}: ExistingProfileTokenImportCaseProps<TBlockchain>) {
  const {t} = useTranslation()

  const tokenMeta = profileTokenImport.token
  assert(tokenMeta.tokenStandard === 'ERC20')
  const metadata: EvmErc20Metadata<TBlockchain> =
    tokenMeta as EvmErc20Metadata<TBlockchain>

  const {differences, updatedMetadata} = getTokenImportEffect(
    requestData,
    metadata,
  )

  if (Object.values(differences).length === 0) {
    return (
      <Box rtl-data-test-id={importEvmTokenTestIds.alreadyImported}>
        <AlertWithMargin severity="warning">
          <Typography>{t('You already have this token imported.')}</Typography>
        </AlertWithMargin>
        <TokenCard
          {...{blockchain, getErc20TokenBalance}}
          address={accountAddress}
          token={updatedMetadata}
        />
        <PrimaryActionButton title={t('Close')} onClick={onClose} />
      </Box>
    )
  }

  return (
    <TokenMutationCommonContent
      labels={{
        submit: t('Update'),
        effectsInfo: {
          newValueLabel: t('New:'),
          prevValueLabel: t('Current:'),
          warning: t(
            'Do you really want to update these properties of your already imported token?',
          ),
        },
      }}
      {...{
        blockchain,
        accountAddress,
        differences,
        onClose,
        onSubmit,
        getErc20TokenBalance,
        tokenAuditResult,
      }}
      tokenMetadata={updatedMetadata}
    />
  )
}

type NewTokenCaseProps<TBlockchain extends EvmBlockchain> =
  CommonTokenCaseProps<TBlockchain> & {
    requestData: ImportEvmTokenParams<TBlockchain>
    tokenId: EvmTokenId<TBlockchain>
  }

function NewTokenCase<TBlockchain extends EvmBlockchain>({
  getErc20TokenBalance,
  accountAddress,
  blockchain,
  tokenId,
  onClose,
  onSubmit,
  requestData,
  tokenAuditResult,
}: NewTokenCaseProps<TBlockchain>) {
  const {t} = useTranslation()

  const tokenMetadataQuery = useGetTokenMetadata<typeof blockchain>(
    blockchain,
    tokenId,
  )

  return (
    <>
      <QueryGuard
        {...tokenMetadataQuery}
        LoadingElement={
          <Paper
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              m: 2,
              p: 2,
              width: '100%',
            }}
          >
            <Typography mb={1}>{t('Loading token data ...')}</Typography>
            <InlineLoading />
          </Paper>
        }
        ErrorElement={
          <Alert severity="error">
            {t('Unexpected error when loading token metadata.')}
          </Alert>
        }
      >
        {(_metadata) => {
          // Just a paranoid check if Dapp was lying about token standard and
          // were able to detect it somehow.
          if (
            !supportedImportTokenStandards.includes(_metadata.tokenStandard)
          ) {
            return <NonErc20TokenCase {...{onClose}} />
          }

          assert(_metadata.tokenStandard === 'ERC20')
          const metadata = _metadata as EvmErc20Metadata<TBlockchain>

          const {differences: _differences, updatedMetadata} =
            getTokenImportEffect(requestData, metadata)
          // This condition is here to avoid reporting diffs when
          // no real data exists on chain, and we just find diff against fallback metadata.
          const differences: TokenDifferences =
            updatedMetadata.existenceConfirmed ? _differences : {}

          return (
            <TokenMutationCommonContent
              labels={{
                submit: t('Import'),
                effectsInfo: {
                  newValueLabel: t('To import:'),
                  prevValueLabel: t('Found on chain:'),
                  warning: t('import_evm_token_differences_warning'),
                },
              }}
              {...{
                blockchain,
                accountAddress,
                differences,
                onClose,
                onSubmit,
                getErc20TokenBalance,
                tokenAuditResult,
              }}
              tokenMetadata={updatedMetadata}
            />
          )
        }}
      </QueryGuard>
      {!tokenMetadataQuery.data && (
        <Box width="100%">
          <ActionButtons
            leftButton={<RejectButton onClick={onClose} />}
            rightButton={<PrimaryActionButton disabled title={t('Import')} />}
          />
        </Box>
      )}
    </>
  )
}

type TokenMutationCaseContentProps<TBlockchain extends EvmBlockchain> = {
  differences: TokenDifferences
  tokenMetadata: EvmErc20Metadata<TBlockchain>
  labels: {
    submit: string
    effectsInfo: {
      warning: string
      newValueLabel: string
      prevValueLabel: string
    }
  }
} & CommonTokenCaseProps<TBlockchain>

function TokenMutationCommonContent<TBlockchain extends EvmBlockchain>({
  accountAddress,
  blockchain,
  differences,
  onSubmit,
  tokenAuditResult,
  tokenMetadata,
  getErc20TokenBalance,
  onClose,
  labels,
}: TokenMutationCaseContentProps<TBlockchain>) {
  return (
    <>
      <TokenAuditWarnings {...{tokenAuditResult}} />
      {Object.keys(differences).length > 0 && (
        <TokenEffectsInfo
          {...{differences}}
          newValueLabel={labels.effectsInfo.newValueLabel}
          prevValueLabel={labels.effectsInfo.prevValueLabel}
          warning={labels.effectsInfo.warning}
        />
      )}
      <TokenCard
        {...{blockchain, getErc20TokenBalance}}
        address={accountAddress}
        token={tokenMetadata}
      />
      <Box width="100%">
        <ActionButtons
          leftButton={<RejectButton onClick={onClose} />}
          rightButton={
            <PrimaryActionButton
              onClick={() => onSubmit(tokenMetadata)}
              title={labels.submit}
            />
          }
        />
      </Box>
    </>
  )
}

type WhiteListedTokenCaseProps<TBlockchain extends EvmBlockchain> = {
  token: EvmErc20Metadata<TBlockchain>
  onClose: () => void
}

function WhiteListedTokenCase<TBlockchain extends EvmBlockchain>({
  token,
  onClose,
}: WhiteListedTokenCaseProps<TBlockchain>) {
  const {t} = useTranslation()
  return (
    <>
      <Box mt={2} mb={2} rtl-data-test-id={importEvmTokenTestIds.whitelisted}>
        <AlertWithMargin severity="info">
          <Typography>
            <Trans
              i18nKey="import_evm_token_whitelist_warning"
              t={t}
              components={{
                bold: <Box component="span" fontWeight="fontWeightBold" />,
              }}
              values={{
                token: token.name || token.ticker || t('This token'),
              }}
            />
          </Typography>
        </AlertWithMargin>
      </Box>
      <PrimaryActionButton onClick={onClose} title={t('Close')} />
    </>
  )
}

function NonErc20TokenCase({onClose}: Pick<UnsupportedCaseProps, 'onClose'>) {
  const {t} = useTranslation()
  return (
    <UnsupportedCase
      message={t('NuFi only supports importing ERC20 tokens.')}
      testId={importEvmTokenTestIds.unsupportedTokenType}
      {...{onClose}}
    />
  )
}

function UnsupportedChainIdCase({
  onClose,
}: Pick<UnsupportedCaseProps, 'onClose'>) {
  const {t} = useTranslation()
  return (
    <UnsupportedCase
      message={t(
        'NuFi does not support importing tokens for other than currently active chain id.',
      )}
      testId={importEvmTokenTestIds.unsupportedChainId}
      {...{onClose}}
    />
  )
}

function InvalidContractIdCase({
  onClose,
}: Pick<UnsupportedCaseProps, 'onClose'>) {
  const {t} = useTranslation()
  return (
    <UnsupportedCase
      message={t('Invalid contract id supplied by the DApp.')}
      testId={importEvmTokenTestIds.invalidContract}
      {...{onClose}}
    />
  )
}

function TokenCard<TBlockchain extends EvmBlockchain>({
  address,
  blockchain,
  token,
  getErc20TokenBalance,
}: Omit<TokenCardBalanceLoaderProps<TBlockchain>, 'children'>) {
  const {t} = useTranslation()
  return (
    <Box mt={2} rtl-data-test-id={importEvmTokenTestIds.tokenCard}>
      <TokenCardBalanceLoader
        {...{address, blockchain, token, getErc20TokenBalance}}
      >
        {(balance) => {
          return (
            <Paper>
              <TokenPropertiesPaddingWrapper>
                <TokenDetailInfo tokenMetadata={token} />
              </TokenPropertiesPaddingWrapper>
              <Divider />
              <TokenPropertiesPaddingWrapper>
                <TokenPropertiesWrapper>
                  {/* Not part of the header as it can easily overflow */}
                  <AboutRow
                    label={t('Balance')}
                    value={
                      <EvmImportTokenBalanceInfo
                        {...{token, blockchain, balance}}
                      />
                    }
                  />
                  <BaseTokenInfo token={token} />
                  <EvmTokenDetailInfo {...{token, blockchain}} nftInfo={null} />
                </TokenPropertiesWrapper>
              </TokenPropertiesPaddingWrapper>
            </Paper>
          )
        }}
      </TokenCardBalanceLoader>
    </Box>
  )
}

type WithValidatedInputProps<TBlockchain extends EvmBlockchain> = {
  blockchain: TBlockchain
  screenInfo: ImportEvmAssetScreenInfo<TBlockchain>
  children: (tokenId: EvmTokenId<TBlockchain>) => JSX.Element
}

function WithValidatedInput<TBlockchain extends EvmBlockchain>({
  blockchain,
  screenInfo,
  children,
}: WithValidatedInputProps<TBlockchain>) {
  if (!supportedImportTokenStandards.includes(screenInfo.data.type)) {
    return <NonErc20TokenCase onClose={screenInfo.onRejected} />
  }

  const targetChainId = screenInfo.data.options.chainId
  const currentChainId = getEvmNetworkConfig(blockchain).chainId
  if (targetChainId != null && targetChainId !== currentChainId) {
    return <UnsupportedChainIdCase onClose={screenInfo.onRejected} />
  }

  let _tokenId: EvmTokenId<TBlockchain> | null = null
  try {
    _tokenId = getEvmTokenId({
      standard: 'ERC20',
      contractAddress: screenInfo.data.options.address,
    })
  } catch (err) {
    return <InvalidContractIdCase onClose={screenInfo.onRejected} />
  }
  assert(_tokenId != null)
  const tokenId = _tokenId as EvmTokenId<TBlockchain>
  return children(tokenId)
}

const TokenPropertiesWrapper = styled('div')(() => ({
  '& *': {
    fontSize: '12px!important',
    lineHeight: '2!important',
  },
}))

const TokenPropertiesPaddingWrapper = styled('div')(({theme}) => ({
  padding: theme.spacing(1.5),
}))
