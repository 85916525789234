/* eslint-disable @typescript-eslint/explicit-function-return-type */

import {Sentry} from '@nufi/frontend-common'
import type {FlowTokenId} from '@nufi/wallet-flow'
import type {SolanaTokenId} from '@nufi/wallet-solana'
import type BigNumber from 'bignumber.js'

import {useEnabledExistingBlockchains} from 'src/features/profile/application'
import {useNeverStaleQuery} from 'src/utils/query-utils'

import {getMockServices} from '../../../__tests__/storybook/MockServiceLocator'
import type {TokenBlockchain} from '../../../blockchainTypes'
import {tokenBlockchains} from '../../../blockchainTypes'
import type {TokenId} from '../../../types'
import type {CardanoTokenId} from '../../cardano'
import {
  useGetTotalNativeBalance as useCardanoNativeBalance,
  useGetBalancesPerAccount as useCardanoAccountBalances,
  useGetTotalBalance as useCardanoTotalBalance,
  cachedGetTotalNativeBalance as cachedGetCardanoTotalNativeBalance,
} from '../../cardano'
import type {EvmTokenId} from '../../evm'
import {
  useGetTotalNativeBalance as useEvmNativeBalance,
  useGetBalancesPerAccount as useEvmAccountBalances,
  useGetTotalBalance as useEvmTotalBalance,
  useEvmQueryPerBlockchain,
  useEvmQueryPerEnabledBlockchain,
  cachedGetTotalNativeBalance as cachedGetEvmTotalNativeBalance,
  fnPerEvmBlockchain,
} from '../../evm'
import {
  useGetBalancesPerAccount as useFlowAccountBalances,
  useGetTotalNativeBalance as useFlowNativeBalance,
  useGetTotalBalance as useFlowTotalBalance,
  cachedGetTotalNativeBalance as cachedGetFlowTotalNativeBalance,
} from '../../flow'
import {
  useGetTotalNativeBalance as useSolanaNativeBalance,
  useGetBalancesPerAccount as useSolanaAccountBalances,
  useGetTotalBalance as useSolanaTotalBalance,
  cachedGetTotalNativeBalance as cachedGetSolanaTotalNativeBalance,
} from '../../solana'
import type {Blockchain, TokenAmount} from '../../types'

import {getHasAccounts} from './accounts'
import {crossBlockchainQueryKeys} from './queryKeys'
import {cachedGetTokens} from './tokens'

export function cachedTotalNativeBalance(blockchain: Blockchain) {
  const queries = {
    solana: cachedGetSolanaTotalNativeBalance,
    cardano: cachedGetCardanoTotalNativeBalance,
    flow: cachedGetFlowTotalNativeBalance,
    ...fnPerEvmBlockchain(
      (evmBlockchain) => () => cachedGetEvmTotalNativeBalance(evmBlockchain),
    ),
  }
  return queries[blockchain]()
}

// allow null so that the query does not have to be called conditionally
function _useGetBalancesPerAccount(
  blockchain: Blockchain,
  tokenId: TokenId | null,
  enabled = true,
) {
  const queryPerBlockchain = {
    solana: useSolanaAccountBalances(
      tokenId as SolanaTokenId | null,
      enabled && blockchain === 'solana',
    ),
    cardano: useCardanoAccountBalances(
      tokenId as CardanoTokenId | null,
      enabled && blockchain === 'cardano',
    ),
    flow: useFlowAccountBalances(
      tokenId as FlowTokenId | null,
      enabled && blockchain === 'flow',
    ),
    ...useEvmQueryPerBlockchain((evmBlockchain) =>
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useEvmAccountBalances(
        evmBlockchain,
        tokenId as EvmTokenId<typeof evmBlockchain> | null,
        enabled && blockchain === evmBlockchain,
      ),
    ),
  }
  return queryPerBlockchain[blockchain]
}

export type UseGetBalancesPerAccount = typeof _useGetBalancesPerAccount

export const useGetBalancesPerAccount: UseGetBalancesPerAccount = (...args) => {
  const mockServices = getMockServices()
  return mockServices?.useGetBalancesPerAccount
    ? mockServices.useGetBalancesPerAccount(...args)
    : _useGetBalancesPerAccount(...args)
}

export function useNativeBalance(blockchain: Blockchain, enabled = true) {
  const queries = {
    solana: useSolanaNativeBalance(enabled && blockchain === 'solana'),
    cardano: useCardanoNativeBalance(enabled && blockchain === 'cardano'),
    flow: useFlowNativeBalance(enabled && blockchain === 'flow'),
    // It is fine to call the hook conditionally, as the condition value can not change
    ...useEvmQueryPerEnabledBlockchain((evmBlockchain) =>
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useEvmNativeBalance(
        evmBlockchain,
        enabled && blockchain === evmBlockchain,
      ),
    ),
  }
  return queries[blockchain]
}

export function useGetTotalBalances(
  blockchain: Blockchain,
  tokenId: TokenId | null,
) {
  const queryPerBlockchain = {
    solana: useSolanaTotalBalance(
      tokenId as SolanaTokenId | null,
      blockchain === 'solana',
    ),
    cardano: useCardanoTotalBalance(
      tokenId as CardanoTokenId | null,
      blockchain === 'cardano',
    ),
    flow: useFlowTotalBalance(
      tokenId as FlowTokenId | null,
      blockchain === 'flow',
    ),
    ...useEvmQueryPerBlockchain((evmBlockchain) =>
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useEvmTotalBalance(
        evmBlockchain,
        tokenId as EvmTokenId<typeof evmBlockchain> | null,
        blockchain === evmBlockchain,
      ),
    ),
  }
  return queryPerBlockchain[blockchain]
}

export function useGetBalances() {
  const enabledExistingBlockchains = useEnabledExistingBlockchains()
  return useNeverStaleQuery({
    queryKey: crossBlockchainQueryKeys.balancesInfo(enabledExistingBlockchains),
    queryFn: async () => {
      let hasSomeError = false
      const nativeBalancesMap = {} as Partial<Record<Blockchain, BigNumber>>
      const tokensBalancesMap = {} as Partial<
        Record<TokenBlockchain, TokenAmount[]>
      >

      // Using Promise.all to load in parallel
      await Promise.all(
        enabledExistingBlockchains.map(async (blockchain) => {
          if (!getHasAccounts(blockchain)) {
            return
          }
          try {
            const loadNativeBalance = async () => {
              nativeBalancesMap[blockchain] =
                await cachedTotalNativeBalance(blockchain)
            }
            const loadTokens = async () => {
              if (tokenBlockchains.includes(blockchain)) {
                tokensBalancesMap[blockchain] =
                  await cachedGetTokens(blockchain)
              }
            }
            // Using Promise.all to load in parallel
            await Promise.all([loadNativeBalance(), loadTokens()])
          } catch (err) {
            Sentry.captureException(err)
            hasSomeError = true
          }
        }),
      )

      return {
        hasSomeError,
        coins: nativeBalancesMap,
        tokens: tokensBalancesMap,
      }
    },
  })
}
