import type {
  Lamports,
  SolanaTransaction,
  SolanaAccountInfo,
  SolanaValidatorInfo,
  SolanaValidatorPubKey,
  SolanaTxFeeParams,
} from '@nufi/wallet-solana'
import {
  solToLamports,
  lamportsToSol,
  calculateTxFeeAmount,
} from '@nufi/wallet-solana'
import {PublicKey} from '@solana/web3.js'
import type {FormikProps} from 'formik'
import {Formik} from 'formik'
import React from 'react'
import {useTranslation} from 'react-i18next'
import * as yup from 'yup'

import {BatchQueryGuard, StakingModalError} from '../../../../components'
import {getBlockchainDecimals} from '../../../../constants'
import {useTrackTxSubmission} from '../../../../tracking'
import {assert} from '../../../../utils/assertion'
import type {AccountId} from '../../../../wallet'
import {
  useGetAccounts,
  useGetTxFeeParams,
  useSignSetupStakeAccount,
  useSubmitStakeInstruction,
  useGetIsStakeAccountCreated,
  getMaxSolAmountWithFeeReserve,
  useGetValidatorsSortedByStake,
} from '../../../../wallet/solana'
import {ensureAccountById} from '../../../../wallet/utils/common'
import {
  WithActiveScreenState,
  useActiveScreenState,
  useActiveSchema,
  useFormikOnSubmit,
  SignTxFlow,
  SetupStakeAccountSubmitScreen,
} from '../../../transaction'
import {useSummaryDetailsSchema} from '../../common/schema'
import type {ActiveScreen} from '../../common/utils'
import {DeviceReadyState, StakeModalHeader} from '../../common/utils'
import type {SetupStakeAccountSchema} from '../schema'
import {useValidatorSchema, useBaseDetailsSchema} from '../schema'
import {getRecommendedValidatorId} from '../utils'

import SummaryScreen from './CommonDelegateSummaryScreen'
import DetailsScreen from './SetupStakeAccountDetailsScreen'

type FormSchema = SetupStakeAccountSchema

export default function SolanaSetupStakeAccountModal({
  onClose,
  accountId,
}: {
  onClose: () => unknown
  accountId: AccountId
}) {
  const accountsQuery = useGetAccounts()
  const txFeeParamsQuery = useGetTxFeeParams({
    affectedAccounts: [new PublicKey(accountId)],
  })
  const validatorsQuery = useGetValidatorsSortedByStake()

  return (
    <WithActiveScreenState>
      <BatchQueryGuard
        queries={{
          accounts: accountsQuery,
          txFeeParams: txFeeParamsQuery,
          validators: validatorsQuery,
        }}
        ErrorElement={<StakingModalError blockchain="solana" />}
        loadingVariant="centered"
      >
        {({accounts, txFeeParams, validators}) =>
          accounts && (
            <SolanaSetupStakeAccountForm
              {...{onClose, accountId, accounts, txFeeParams, validators}}
            />
          )
        }
      </BatchQueryGuard>
    </WithActiveScreenState>
  )
}

type SolanaSetupStakeAccountFormProps = {
  onClose: () => unknown
  accountId: AccountId
  accounts: SolanaAccountInfo[]
  txFeeParams: SolanaTxFeeParams
  validators: SolanaValidatorInfo[]
}

function SolanaSetupStakeAccountForm({
  onClose,
  accountId,
  accounts,
  txFeeParams,
  validators,
}: SolanaSetupStakeAccountFormProps) {
  const {t} = useTranslation()
  const setupStakeAccount = useSignSetupStakeAccount()
  const submit = useSubmitStakeInstruction()
  const {setActiveScreen} = useActiveScreenState()
  const summaryDetailsSchema = useSummaryDetailsSchema({accounts})
  const baseDetailsSchema = useBaseDetailsSchema({
    decimals: getBlockchainDecimals('solana'),
  })
  const validatorSchema = useValidatorSchema()
  const txFeeAmount = calculateTxFeeAmount(txFeeParams)

  const schema = useActiveSchema({
    details: {
      ...baseDetailsSchema,
      ...validatorSchema,
      amount: baseDetailsSchema.amount.concat(
        yup
          .number()
          .min(0.01, t('Minimal stake amount is 0.01 SOL'))
          .test(
            'amount-not-more-than-balance',
            t('Cannot stake more than available'),
            // `function` keyword needed for `this` to behave correctly
            function (amount, context) {
              if (amount === undefined) return true
              const {accountId}: FormSchema = this.parent
              const account = ensureAccountById(accounts, accountId)
              // passing original value because JS numbers don't preserve original notation
              // originalValue not exposed in yup TS types, see open issue in yup::
              // https://github.com/jquense/yup/issues/1591
              return account.balance.isGreaterThanOrEqualTo(
                solToLamports(
                  (context as unknown as {originalValue: string}).originalValue,
                ),
              )
            },
          )
          // handle the amount between maxStakeable and balanceAmount
          .test(
            'amount-not-more-than-max-stakeable',
            t(
              'Cannot stake more than available. Some extra funds are kept for future transactions and rent.',
            ),
            function (amount, context) {
              if (amount === undefined) return true
              const {accountId}: FormSchema = this.parent
              const account = ensureAccountById(accounts, accountId)
              const maxStakeableAmount = getMaxSolAmountWithFeeReserve(
                account.balance as Lamports,
                txFeeAmount,
              )
              // passing original value because JS numbers don't preserve original notation
              // originalValue not exposed in yup TS types, see open issue in yup::
              // https://github.com/jquense/yup/issues/1591
              return maxStakeableAmount.isGreaterThanOrEqualTo(
                solToLamports(
                  (context as unknown as {originalValue: string}).originalValue,
                ),
              )
            },
          ),
      ),
    },
    summary: summaryDetailsSchema,
  })

  const initialValues: FormSchema = {
    amount: '',
    validatorId: getRecommendedValidatorId(validators) || '',
    accountId: accountId || accounts[0]!.id,
    password: '',
    hasSufficientFeeFunds: true,
  }

  const onTxSign = async (values: FormSchema) =>
    await setupStakeAccount.mutateAsyncSilent({
      amount: solToLamports(values.amount),
      accountId: values.accountId as AccountId,
      validator: values.validatorId as SolanaValidatorPubKey,
      txFeeParams,
    })

  const onTxSubmit = async (
    accountId: AccountId,
    signedTx: SolanaTransaction,
  ) => await submit.mutateAsyncSilent({signedTx, accountId})

  const onTxSignAndSubmit = async (values: FormSchema) => {
    const signatureResult = await onTxSign(values)
    if (!signatureResult) return
    setActiveScreen('submit')
    await onTxSubmit(values.accountId as AccountId, signatureResult.tx)
  }

  const onSubmit = useFormikOnSubmit<FormSchema>({
    accounts,
    onTxSignAndSubmit,
  })

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={onSubmit}
    >
      {(formikProps) => (
        <SolanaStakeModalContent
          {...{
            formikProps,
            accounts,
            setActiveScreen,
            onClose,
            txFee: txFeeAmount,
            onTxSubmit,
            onTxSignAndSubmit,
            validators,
          }}
          submitProps={submit}
          signProps={setupStakeAccount}
        />
      )}
    </Formik>
  )
}

type SolanaStakeModalContentProps = {
  setActiveScreen: (screen: ActiveScreen) => void
  accounts: SolanaAccountInfo[]
  formikProps: FormikProps<FormSchema>
  submitProps: ReturnType<typeof useSubmitStakeInstruction>
  signProps: ReturnType<typeof useSignSetupStakeAccount>
  onClose: () => unknown
  txFee: Lamports
  onTxSubmit: (
    accountId: AccountId,
    signedTx: SolanaTransaction,
  ) => Promise<unknown>
  onTxSignAndSubmit: (values: FormSchema) => Promise<unknown>
  validators: SolanaValidatorInfo[]
}

function SolanaStakeModalContent({
  accounts,
  setActiveScreen,
  formikProps,
  submitProps,
  onClose,
  txFee,
  onTxSubmit,
  onTxSignAndSubmit,
  signProps,
  validators,
}: SolanaStakeModalContentProps) {
  const newStakeAccountPubKey = submitProps.isSuccess
    ? signProps.data?.stakePubKey
    : undefined
  const isStakeAccountCreated = !!useGetIsStakeAccountCreated({
    stakePubKey: newStakeAccountPubKey,
    pollInterval: 1000 * 5,
  }).data

  const summary = {
    amount: solToLamports(formikProps.values.amount),
    fee: txFee,
    fromAccount: ensureAccountById(accounts, formikProps.values.accountId),
    validatorName: formikProps.values.validatorId,
  }

  const blockchain = 'solana'
  const ModalHeader = <StakeModalHeader {...{blockchain, onClose}} />

  const _signProps = {
    // slim down signProps for SignTxFlow (no data.stakePubKey needed there)
    ...signProps,
    data: signProps.data?.tx,
  }

  const fromAccount = ensureAccountById(accounts, formikProps.values.accountId)

  const showSendMaxOption = (() => {
    const sendableNativeFunds = getMaxSolAmountWithFeeReserve(
      fromAccount.balance as Lamports,
      txFee,
    )
    // hide "max" button if not enough funds for the "fee"
    return sendableNativeFunds.isGreaterThanOrEqualTo(0)
  })()

  const maxAmountOptions = showSendMaxOption
    ? {
        onMaxAmount: () => {
          const amountKey: keyof FormSchema = 'amount' // due to TS
          const sendableFunds = getMaxSolAmountWithFeeReserve(
            fromAccount.balance as Lamports,
            txFee,
          )
          formikProps.setFieldValue(amountKey, lamportsToSol(sendableFunds))
        },
      }
    : undefined

  useTrackTxSubmission(submitProps, {
    blockchain,
    provider: summary.fromAccount.cryptoProviderType,
    type: 'create_stake_account',
    value: summary.amount.toNumber(),
  })

  return (
    <SignTxFlow
      {...{
        onClose,
        formikProps,
        ModalHeader,
        blockchain,
        submitProps,
        DeviceReadyState,
        onTxSignAndSubmit,
      }}
      signProps={_signProps}
      onTxSubmit={onTxSubmit}
      initialScreen="details"
      renderDetails={() => (
        <DetailsScreen
          {...{
            blockchain,
            onClose,
            formikProps,
            accounts,
            maxAmountOptions,
            validators,
          }}
        />
      )}
      renderSummary={() => (
        <SummaryScreen<SetupStakeAccountSchema>
          onBack={() => setActiveScreen('details')}
          onSubmit={formikProps.handleSubmit}
          {...{summary, formikProps, onClose, blockchain}}
        />
      )}
      renderSubmit={(signAndSubmitUtils) => {
        const signedTransaction = signProps.data
        assert(!!signedTransaction)
        return (
          <SetupStakeAccountSubmitScreen
            accountId={formikProps.values.accountId as AccountId}
            onBack={signAndSubmitUtils.onSignAndSubmitGoBack}
            onRetry={() =>
              onTxSubmit(
                formikProps.values.accountId as AccountId,
                signedTransaction.tx,
              )
            }
            onNewStaking={() => {
              signAndSubmitUtils.onSignAndSubmitGoBack()
              formikProps.resetForm()
            }}
            {...{
              onClose,
              submitProps,
              blockchain,
              ModalHeader,
              isStakeAccountCreated,
            }}
          />
        )
      }}
    />
  )
}
