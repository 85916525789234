import type {BaseSendSchema} from '../common/types'

import {useLondonGasSchema, useSimpleGasSchema} from './gas/schema'
import type {GasSchema} from './gas/types'
import type {NonceFieldState} from './Nonce'
import {useNonceSchema} from './Nonce'

type NonceSchema = {nonce: NonceFieldState}

// Nice-to-have: Try considering a nicer type than (LondonUiGasSchema | SimpleGasSchema)
// as this always has to be retyped when assigning somewhere.
export type BaseSchema = BaseSendSchema & GasSchema & NonceSchema

export type GasSchemaParams =
  | {
      type: 'london'
    }
  | {
      type: 'simple'
    }

export const useEvmSchema = (gasParams: GasSchemaParams) => {
  const londonGasValidationSchema = useLondonGasSchema()
  const simpleGasValidationSchema = useSimpleGasSchema()
  const gasSchema =
    gasParams.type === 'simple'
      ? simpleGasValidationSchema
      : londonGasValidationSchema
  const nonceSchema = useNonceSchema()

  return {
    ...gasSchema,
    ...nonceSchema,
  }
}
