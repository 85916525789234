import type {Theme} from '@mui/material'
import {styled} from '@mui/material'
import {makeStyles, useTheme} from '@mui/styles'
import _ from 'lodash'
import {useState, useEffect} from 'react'

export const useScrollableStyles = makeStyles(() => ({
  scrollableParent: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  scrollableList: {
    flex: 1,
    overflow: 'auto',
  },
}))

export const useResponsiveDimensions = () => {
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  })
  useEffect(() => {
    const handleResize = _.debounce(() => {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      })
    }, 300)
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })
  return dimensions
}

export const useEllipsisStyles = makeStyles(() => ({
  ellipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))

export type bgImageStylesProps = {
  image: string
  backgroundSize?: string
}

export const useBackgroundImageStyles = makeStyles<Theme, bgImageStylesProps>({
  bgImage: {
    backgroundImage: (props) => `url("${props?.image}")`,
    backgroundPosition: 'center',
    backgroundSize: (props) => `${props?.backgroundSize ?? 'cover'}`,
    backgroundRepeat: 'no-repeat',
  },
})

export const ContentLoaderBackground = () => {
  const theme = useTheme()

  return theme.palette.background.paper
}

export const useTabsStyles = makeStyles((theme) => ({
  commonTabStyles: {
    marginBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}))

export const FiltersWrapper = styled('div')(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  marginBottom: theme.spacing(1),
}))

export const FilterInputWrapper = styled('div')({
  width: '260px',
})
