/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {getAvailableEvmBlockchains} from '../evmBlockchains'
import type {EvmBlockchain} from '../types'
import {evmBlockchains} from '../types'

/**
 * Returns Record<EvmBlockchain, TResult>
 * but should be returning Record<TBlockchain, TResult<TBlockchain>>
 * which seems to be currently impossible to express in TypeScript
 * (higher kinded types) https://github.com/Microsoft/TypeScript/issues/1213
 *
 * To explain on an example: for token metadata, return type ends up being
 * Record<EvmBlockchain, EvmTokenMetadata<'ethereum' | 'polygon'>}
 * The return type should be {ethereum: EvmTokenMetadata<'ethereum'>, polygon: EvmTokenMetadata<'polygon'>}
 * because when you later do Extract<EvmTokenMetadata<'ethereum' | 'polygon'>, {blockchain: 'polygon'}>
 * it gets evaluated to never even tho it should be EvmTokenMetadata<'polygon'>
 */

export const fnPerEvmBlockchain = <TResult>(
  fn: (evmBlockchain: EvmBlockchain) => TResult,
  onlyEnabledBlockchains = false,
) => {
  const _evmBlockchains = onlyEnabledBlockchains
    ? getAvailableEvmBlockchains()
    : evmBlockchains

  return Object.fromEntries(
    _evmBlockchains.map((evmBlockchain) => [evmBlockchain, fn(evmBlockchain)]),
  ) as {[evmBlockchain in EvmBlockchain]: TResult}
}

const _useEvmQueryPerBlockchain = <TResult>(
  useQuery: (evmBlockchain: EvmBlockchain) => TResult,
  onlyEnabledBlockchains = false,
) => {
  // This function should only be called in order to spread evm blockchain queries
  // into a "queryPerBlockchain" object and so the order in which the hooks are run
  // should not change.
  return fnPerEvmBlockchain(useQuery, onlyEnabledBlockchains)
}

export const useEvmQueryPerBlockchain = <TResult>(
  useQuery: (evmBlockchain: EvmBlockchain) => TResult,
) => _useEvmQueryPerBlockchain(useQuery, false)

export const useEvmQueryPerEnabledBlockchain = <TResult>(
  useQuery: (evmBlockchain: EvmBlockchain) => TResult,
) => _useEvmQueryPerBlockchain(useQuery, true)
