import type {
  BlockchainApi,
  FlowAccountNetworkInfo,
  FlowAccountOfflineInfo,
  FlowAccountStoredData,
} from '@nufi/wallet-flow'
import {
  AccountSource,
  BlockchainApiImpl,
  FlowAccountManager,
  FlowportApiConnection,
  FlowWallet,
  LedgerCryptoProvider,
  MnemonicCryptoProvider,
  NodeApiConnection,
  setupFcl,
  onflowNodeOperatorsApi,
} from '@nufi/wallet-flow'
import {FlowdiverApiConnectionImpl} from '@nufi/wallet-flow/dist/sdk/flowdiverApiConnection'

import config from 'src/config'
import {useFlowStore} from 'src/store/wallet'

import {
  AccountsStoreManagerImpl,
  addStandardAccounts,
} from '../AccountsStoreManager'
import {localSecretProvider} from '../secretProvider'
import {createAccountInfosUtils} from '../utils/walletUtils'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function _createFlowManagers(blockchainApi: BlockchainApi) {
  // flow sdk(fcl) needs to be setup globally before
  // blockchainApi (which depends on it) is actually used
  setupFcl(config.flowNetwork, config.flowAccessNodeUrl)

  const mnemonicCryptoProvider = new MnemonicCryptoProvider(localSecretProvider)
  const ledgerCryptoProvider = new LedgerCryptoProvider()

  const accountSource = new AccountSource(blockchainApi)

  const accountManager = new FlowAccountManager({
    blockchainApi,
    mnemonicCryptoProvider,
    ledgerCryptoProvider,
  })

  const wallet = new FlowWallet({
    accountSource,
    mnemonicCryptoProvider,
    ledgerCryptoProvider,
    blockchainApi,
    addStandardAccounts,
  })

  const accountsStore = new AccountsStoreManagerImpl<FlowAccountStoredData>(
    useFlowStore,
  )

  const accountInfoUtils = createAccountInfosUtils<
    FlowAccountStoredData,
    FlowAccountNetworkInfo,
    FlowAccountOfflineInfo
  >(accountManager)

  return {
    wallet,
    accountsStore,
    accountManager,
    ...accountInfoUtils,
    blockchainApi,
  }
}

export const flow = _createFlowManagers(
  new BlockchainApiImpl(
    config.flowNetwork,
    new NodeApiConnection(),
    new FlowportApiConnection(config.flowFlowportApiUrl),
    new FlowdiverApiConnectionImpl(config.flowdiverApiUrl),
    onflowNodeOperatorsApi,
  ),
)
