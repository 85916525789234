import {HelpOutline as HelpIcon} from '@mui/icons-material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {Route} from 'react-router-dom'

import {Modal, StakingEpochInfo, WithTooltip} from '../../../components'
import config from '../../../config'
import {routeTo} from '../../../router'
import {useStakeRouteOptions} from '../../../router/staking'
import type {CardanoNetwork} from '../../../wallet/cardano'
import {getCurrentEpochIndex, getEpochStartDate} from '../../../wallet/cardano'
import {EpochInfoText} from '../common/overview/StakeOverviewUtils'
import {CurrentEpochInfoText} from '../common/utils'
import StakeDetail from '../StakeDetail'
import type {StakeActionsRoutesProps} from '../StakeDetail'

import StakeModal from './actionModals/StakeModal'
import WithdrawModal from './actionModals/WithdrawModal'
import StakeHistory from './history/StakeHistory'
import StakeAccountsOverview from './overview/StakeAccountOverview'

function AccountActionsRoutes({onModalClose}: StakeActionsRoutesProps) {
  const {accountId} = useStakeRouteOptions()
  return (
    <>
      <Route
        exact
        path={routeTo.staking.myStaking.cardano.account(':accountId').delegate}
      >
        <Modal onClose={onModalClose} variant="left">
          <StakeModal onClose={onModalClose} stakeAccountId={accountId} />
        </Modal>
      </Route>
      <Route
        exact
        path={
          routeTo.staking.myStaking.cardano.account(':accountId')
            .withdrawRewards
        }
      >
        <Modal onClose={onModalClose} variant="left">
          <WithdrawModal onClose={onModalClose} stakeAccountId={accountId} />
        </Modal>
      </Route>
    </>
  )
}

const EpochInfo = () => {
  const {t} = useTranslation()
  const cardanoNetwork = config.cardanoNetwork as CardanoNetwork
  const epoch = getCurrentEpochIndex(cardanoNetwork)
  const nextEpoch = epoch + 1
  const epochStart = getEpochStartDate(epoch, cardanoNetwork)
  const nextEpochStart = getEpochStartDate(nextEpoch, cardanoNetwork)
  return (
    <>
      <CurrentEpochInfoText value={epoch} />
      <EpochInfoText label={t('Started')} date={epochStart} />
      <EpochInfoText label={t('Next epoch')} value={nextEpoch} />
      <EpochInfoText sx={{mr: 1}} label={t('Starts')} date={nextEpochStart} />
      <WithTooltip
        placement="top"
        title={<StakingEpochInfo blockchain="cardano" />}
      >
        <HelpIcon fontSize="small" color="action" />
      </WithTooltip>
    </>
  )
}

export default function CardanoStakeDetail() {
  return (
    <StakeDetail
      blockchain={'cardano'}
      StakeAccountsOverview={StakeAccountsOverview}
      StakeHistory={StakeHistory}
      AccountActionsRoutes={AccountActionsRoutes}
      EpochInfo={EpochInfo}
    />
  )
}
