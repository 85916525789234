import React, {useContext} from 'react'

import type {SolanaSendModalSubScreens} from './types'
import type {SolanaSendModalViewModel} from './viewModel'

type Context = {
  vm: SolanaSendModalViewModel
  subScreens: SolanaSendModalSubScreens
}

const SolanaSendModalViewModelContext = React.createContext<Context>(
  {} as Context,
)

export const SolanaSendModalProvider = ({
  children,
  vm,
  subScreens,
}: {
  children: React.ReactElement
  vm: SolanaSendModalViewModel
  subScreens: SolanaSendModalSubScreens
}) => {
  return (
    <SolanaSendModalViewModelContext.Provider value={{vm, subScreens}}>
      {children}
    </SolanaSendModalViewModelContext.Provider>
  )
}

export const useSolanaSendModalProvider = () =>
  useContext(SolanaSendModalViewModelContext)
