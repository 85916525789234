/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {
  FungibleTokenListApi,
  FlowFansTokenListApiImpl,
  flowTokenContractAddressByNetwork,
} from '@nufi/wallet-flow'

import config from 'src/config'
import {fetchNeverStaleQuery} from 'src/utils/query-utils'
import {QUERY_KEY_PREFIX as P} from 'src/wallet/flow/public/utils'

export const tokensQueryKeys = {
  fungibleTokenList: [P, 'fungibleTokenList'],
} as const

const fungibleTokenListApi = new FungibleTokenListApi(
  config.flowNetwork,
  new FlowFansTokenListApiImpl(flowTokenContractAddressByNetwork),
)

export function cachedGetFungibleTokenList() {
  return fetchNeverStaleQuery({
    queryKey: tokensQueryKeys.fungibleTokenList,
    queryFn: () => {
      return fungibleTokenListApi.getTokenList()
    },
  })
}
