import type {HwVendor, AccountInfo} from '../../../types'
import type {CardanoAccountStoredData} from '../../../wallet/cardano'
import {
  MAX_ACCOUNT_COUNT,
  useAddLedgerAccounts,
  useAddTrezorAccounts,
  useDiscoverLedgerAccounts,
  useDiscoverTrezorAccounts,
} from '../../../wallet/cardano'
import {useAddHwAccountState} from '../sharedActions/addAccount/AddHwAccount'

export function useCommonAddHwAccountProps(
  hwVendor: Exclude<HwVendor, 'gridPlus'>,
) {
  const addHwAccountState = useAddHwAccountState()

  const discoveredAccountsQuery = {
    ledger: useDiscoverLedgerAccounts(hwVendor === 'ledger'),
    trezor: useDiscoverTrezorAccounts(hwVendor === 'trezor'),
  }[hwVendor]

  const addAccountsMutation = {
    ledger: useAddLedgerAccounts(),
    trezor: useAddTrezorAccounts(),
  }[hwVendor]

  const onSubmit = async (newAccounts: AccountInfo[]) => {
    await addAccountsMutation.mutateAsync({
      newAccounts: newAccounts as CardanoAccountStoredData[],
    })
  }

  return {
    discoveredAccountsQuery,
    onSubmit,
    addAccountsMutation,
    ...addHwAccountState,
    extras: {
      maxAccountLimit: MAX_ACCOUNT_COUNT,
      prevMustBeUsed: true,
    },
  }
}
