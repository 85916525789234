import {useGetConversionRates} from 'src/features/conversionRates/application'

import {useTrackMultiAssetTxSubmission} from '../../../../tracking'
import {
  useGetAccounts,
  useGetTokensMetadata,
  useGetTokenMetadata,
  useGetBalancesPerAccount,
  useAccountTokens,
  useGetAllNfts,
} from '../../../../wallet'

export const sendModalViewModel = {
  useGetAccounts,
  useGetTokensMetadata,
  useGetTokenMetadata,
  useGetBalancesPerAccount,
  useTrackMultiAssetTxSubmission,
  useGetConversionRates,
  useAccountTokens,
  useGetAllNfts,
}

export type SendModalViewModel = typeof sendModalViewModel
