/* eslint-disable @typescript-eslint/explicit-function-return-type */

import type {
  CardanoTokenId,
  CardanoTokenMetadata,
  CardanoTokenName,
  CardanoTxHistoryEntry,
  CardanoNft,
} from '@nufi/wallet-cardano'

import {useNeverStaleQuery} from 'src/utils/query-utils'

import type {AllNftsResult} from '../../../../public/nfts'
import {cachedGetAllNfts} from '../nfts'

import {cachedGetTokensMetadata, cachedGetTokenMetadata} from './metadata'
import {tokensQueryKeys} from './queryKeys'

export {tokensQueryKeys} from './queryKeys'
export {
  cachedGetTokensMetadata,
  useGetTokens,
  cachedGetTokens,
} from './metadata'

//
// __COMPUTED__ QUERIES
//

async function getCombinedTokensMetadata(
  tokensMetadata: CardanoTokenMetadata[],
  nfts: AllNftsResult<CardanoTokenId, CardanoNft>,
) {
  return tokensMetadata.map((v) => {
    const nftName = nfts.allNfts?.[v.id]?.name
    return {
      ...v,
      isNft: !!nfts.allNfts[v.id],
      ...(nftName && {name: nftName as CardanoTokenName}),
    }
  })
}

export function useGetCombinedTokensMetadata(enabled = true) {
  return useNeverStaleQuery({
    queryKey: tokensQueryKeys.tokenMetadata.combined(),
    queryFn: async () => {
      const tokensMetadata = await cachedGetTokensMetadata()
      const nfts = await cachedGetAllNfts()
      return getCombinedTokensMetadata(tokensMetadata, nfts)
    },
    enabled,
  })
}

export function useGetCombinedTokenMetadata(
  tokenId: CardanoTokenId,
  enabled = true,
) {
  return useNeverStaleQuery({
    queryKey: tokensQueryKeys.tokenMetadata.tokenCombined(tokenId),
    queryFn: async () => {
      const tokenMetadata = await cachedGetTokenMetadata(tokenId)
      const nfts = await cachedGetAllNfts()

      const nftName = nfts.allNfts?.[tokenId]?.name
      return {
        ...tokenMetadata,
        isNft: !!nfts.allNfts[tokenId],
        ...(nftName && {name: nftName as CardanoTokenName}),
      }
    },
    enabled,
  })
}

export function getTokenTransactionHistory(
  tokenId: CardanoTokenId | null,
  txHistory: CardanoTxHistoryEntry[],
) {
  return txHistory.filter((entry) =>
    entry.tokenBundleEffect.find((effect) => effect.token.id === tokenId),
  )
}
