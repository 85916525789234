import React from 'react'
import {Route, Switch} from 'react-router-dom'

import {routeTo} from '../router'

import {WalletConnectConnectorWindow} from './connectorWindow'
import {WalletConnectRedirect} from './RedirectTab'

export const WalletConnectConnector = () => {
  return (
    <Switch>
      <Route
        path={routeTo.walletConnect.connector.redirect}
        render={() => <WalletConnectRedirect />}
      />
      <Route
        path={routeTo.walletConnect.connector.window}
        render={() => <WalletConnectConnectorWindow />}
      />
    </Switch>
  )
}
