import {Box, Grid, TextField, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import {Sentry, getIsWebExtension} from '@nufi/frontend-common'
import type {FormikHelpers} from 'formik'
import {Formik} from 'formik'
import type {ReactNode} from 'react'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import * as yup from 'yup'

import {
  Alert,
  Button,
  Icon,
  SafeCenterAligner,
  SideBarLayout,
} from './components'
import type {AppError} from './types'
import {getHasFormError} from './utils/form'
import {sendUserFeedback} from './utils/sentry'

type ErrorBoundaryProps = {
  errorContent?: ReactNode
  children: ReactNode
}

type ErrorBoundaryState = {
  error: AppError | null
}

// only class components can be error boundaries - https://reactjs.org/docs/error-boundaries.html
export class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  state: ErrorBoundaryState = {
    error: null,
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return {error}
  }

  componentDidCatch(error: Error) {
    Sentry.captureException(error)
  }

  render() {
    if (this.state.error != null) {
      return this.props.errorContent ?? <ErrorContent />
    }

    return this.props.children
  }
}

const ErrorContent = () => {
  const [isUserFeedbackSubmitted, setIsUserFeedbackSubmitted] =
    useState<boolean>(false)

  const reloadPage = () => {
    window.location.reload()
  }

  const content = !isUserFeedbackSubmitted ? (
    <UserFeedbackForm
      onUserFeedbackSubmitted={() => setIsUserFeedbackSubmitted(true)}
      reloadPage={reloadPage}
    />
  ) : (
    <UserFeedbackSubmitted reloadPage={reloadPage} />
  )

  return getIsWebExtension() ? (
    <SafeCenterAligner>
      <Icon type="appLogo" exactSize={100} />
      {content}
    </SafeCenterAligner>
  ) : (
    <SideBarLayout right={content} />
  )
}

type UserFeedbackFormProps = {
  onUserFeedbackSubmitted: () => void
  reloadPage: () => void
}

const UserFeedbackForm = ({
  onUserFeedbackSubmitted,
  reloadPage,
}: UserFeedbackFormProps) => {
  const {t} = useTranslation()
  const classes = useStyles()

  const _formSchema = {
    name: yup.string(),
    email: yup
      .string()
      .email(t('Please enter a valid e-mail or leave the field empty')),
    userFeedback: yup
      .string()
      .required(t('Please tell us what happened before submitting')),
  }
  type FormField = keyof typeof _formSchema
  type FormData = {[k in FormField]: string}
  const formSchema = yup.object().shape(_formSchema)

  const initialValues: FormData = {name: '', email: '', userFeedback: ''}

  const onSubmit = async (
    values: FormData,
    {setErrors}: FormikHelpers<FormData>,
  ) => {
    try {
      setErrors({userFeedback: undefined})
      await sendUserFeedback(
        Sentry.lastEventId() || null,
        values.name || 'user',
        values.email || 'user@email.com',
        values.userFeedback,
      )
      onUserFeedbackSubmitted()
    } catch (err) {
      setErrors({userFeedback: t('Unexpected error.')})
    }
  }

  return (
    <Formik
      onSubmit={onSubmit}
      validationSchema={formSchema}
      initialValues={initialValues}
    >
      {(formikProps) => {
        const {handleChange, handleSubmit, values, isSubmitting, errors} =
          formikProps
        const hasError = getHasFormError(formikProps)

        return (
          <Box className={classes.wrapper}>
            <Alert severity="error">
              <Typography variant="h5">
                {t('Oops! Something went wrong!')}
              </Typography>
            </Alert>
            <Typography component="p" variant="body2" color="textSecondary">
              {t(
                "Our team has been notified. If you'd like to help, tell us what happened.",
              )}
            </Typography>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    value={values.name}
                    onChange={handleChange<FormField>('name')}
                    label={t('Name')}
                    variant="outlined"
                    fullWidth
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    value={values.email}
                    onChange={handleChange<FormField>('email')}
                    label={t('E-mail')}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                {hasError('email') && (
                  <Grid item xs={12}>
                    <Typography color="error">{errors.email}</Typography>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <TextField
                    value={values.userFeedback}
                    onChange={handleChange<FormField>('userFeedback')}
                    label={t('What happened?')}
                    type="normal"
                    multiline
                    rows={3}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                {hasError('userFeedback') && (
                  <Grid item xs={12}>
                    <Typography color="error">{errors.userFeedback}</Typography>
                  </Grid>
                )}
                <Grid item xs={6}>
                  <Button
                    variant="outlined"
                    color="primary"
                    fullWidth
                    onClick={reloadPage}
                    disabled={isSubmitting}
                  >
                    {t('Reload page')}
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    disabled={isSubmitting}
                    variant="contained"
                    type="submit"
                    color="primary"
                    fullWidth
                  >
                    {t('Submit report')}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        )
      }}
    </Formik>
  )
}

type UserFeedbackSubmittedProps = {
  reloadPage: () => void
}

const UserFeedbackSubmitted = ({reloadPage}: UserFeedbackSubmittedProps) => {
  const {t} = useTranslation()
  const classes = useStyles()

  return (
    <Box className={classes.wrapper}>
      <Alert
        severity="info"
        text={t('Thank you for submitting your report!')}
      />
      <Button variant="outlined" color="primary" fullWidth onClick={reloadPage}>
        {t('Reload page')}
      </Button>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 350,
    maxWidth: 440,
    '& > :not(:first-child)': {
      marginTop: theme.spacing(4),
    },
  },
}))
