import React, {useContext, useState} from 'react'

import {useStepTracking} from '../../../tracking'

export type MultiStepFormContentScreen = 'details' | 'summary'

export type ActiveScreen =
  | MultiStepFormContentScreen
  | 'sign-trezor'
  | 'sign-ledger'
  | 'sign-mnemonic'
  | 'sign-gridplus'
  | 'submit'

const _useActiveScreenState = (initialScreen: MultiStepFormContentScreen) =>
  useState<ActiveScreen>(initialScreen)

type ActiveScreenContextValue = {
  activeScreen: ReturnType<typeof _useActiveScreenState>[0]
  setActiveScreen: ReturnType<typeof _useActiveScreenState>[1]
}

const ActiveScreenContext = React.createContext({} as ActiveScreenContextValue)

type WithActiveScreenStateProps = {
  children: React.ReactElement
  initialScreen?: MultiStepFormContentScreen
}

export function WithActiveScreenState({
  children,
  initialScreen = 'details',
}: WithActiveScreenStateProps) {
  const [activeScreen, setActiveScreen] = _useActiveScreenState(initialScreen)
  useStepTracking(activeScreen)
  return (
    <ActiveScreenContext.Provider value={{activeScreen, setActiveScreen}}>
      {children}
    </ActiveScreenContext.Provider>
  )
}

export const useActiveScreenState = () => useContext(ActiveScreenContext)
