import React, {useContext} from 'react'

export function createVMContextHandlers<T>() {
  type Context = T

  const context = React.createContext<Context>({} as Context)

  const ViewModelProvider = ({
    children,
    vm,
  }: {
    children: React.ReactElement
    vm: T
  }) => {
    return <context.Provider value={vm}>{children}</context.Provider>
  }

  const useViewModel = () => useContext(context)

  return {useViewModel, ViewModelProvider}
}
