import type {
  ProposalTypes,
  SessionTypes,
  SignClientTypes,
} from '@walletconnect/types'
import {getSdkError, parseUri} from '@walletconnect/utils'
import type {Web3WalletTypes} from '@walletconnect/web3wallet'
import {useCallback, useEffect, useRef, useState} from 'react'

import {walletConnect} from './sdk'
import {
  getSessionProposal,
  disconnectActiveSession,
  getActiveSession,
} from './utils'

type SdkErrorKey = Parameters<typeof getSdkError>[0]

export default function useInitWalletConnect(
  projectId: string,
  metadata: Web3WalletTypes.Options['metadata'],
  onError: (error: WalletConnectError) => Promise<void>,
) {
  const [initialized, setInitialized] = useState(false)

  const onInitialize = useCallback(async () => {
    try {
      await walletConnect.init(projectId, metadata)
      setInitialized(true)
    } catch (e: unknown) {
      onError(new WalletConnectError({errorKey: 'INIT_FAILED', e}))
    }
  }, [])

  useEffect(() => {
    if (!initialized) {
      onInitialize()
    }
  }, [initialized, onInitialize])

  return initialized
}

export type OnSessionProposalArgs = {
  proposal: ProposalTypes.Struct
  approve: (
    namespaces: Record<string, SessionTypes.BaseNamespace>,
  ) => Promise<void>
  reject: (errorKey: SdkErrorKey, confirmInitError?: unknown) => Promise<void>
}

export type OnSessionRequestArgs = {
  approve: (payload: unknown) => Promise<void>
  reject: (errorKey: SdkErrorKey, apiError?: unknown) => Promise<void>
  params: SignClientTypes.EventArguments['session_request']['params']
}

type WalletConnectErrorParams = {
  origin?: string
  e?: unknown
} & (
  | ({
      errorKey: SdkErrorKey
    } & (
      | {
          type: 'sessionRequest'
          requestEvent: SignClientTypes.EventArguments['session_request']
        }
      | {type: 'sessionProposal'; proposal: ProposalTypes.Struct}
    ))
  | {
      errorKey: 'GETTING_PROPOSAL_FAILED' | 'INIT_FAILED' | 'UNKNOWN_ERROR'
    }
)

export class WalletConnectError extends Error {
  readonly params: WalletConnectErrorParams
  constructor(params: WalletConnectErrorParams) {
    super(params.errorKey)
    this.params = params
  }
}

type WalletConnectEventsManagerArgs = {
  initialized: boolean
  uri: string
  onSessionProposal: (args: OnSessionProposalArgs) => Promise<void>
  onSessionRequest: (args: OnSessionRequestArgs) => Promise<void>
  onSessionDelete: (
    args?: SignClientTypes.EventArguments['session_delete'],
  ) => Promise<void>
  onError: (error: WalletConnectError) => Promise<void>
}

export function useWalletConnectEventsManager({
  initialized,
  uri,
  onSessionProposal,
  onSessionRequest,
  onSessionDelete,
  onError: _onError,
}: WalletConnectEventsManagerArgs) {
  const sessionTopic = useRef<string | null>(null)
  /**
   * Each connector window handles a single session, we check if the event topic
   * is matching the current session of this window, if not we ignore the request/event
   */
  const respondIfCurrentSession = async (
    event: {topic: string},
    respond: () => Promise<void>,
  ) => {
    if (event.topic === sessionTopic.current) {
      await respond()
    } else {
      // eslint-disable-next-line no-console
      console.log(
        `Received WalletConnect event from different session ${JSON.stringify(
          event,
        )}`,
      )
    }
  }

  const disconnectSession = (errorKey: SdkErrorKey) => {
    if (sessionTopic.current) {
      const activeSession = getActiveSession(sessionTopic.current)
      if (activeSession) return disconnectActiveSession(activeSession, errorKey)
    }
    return Promise.resolve()
  }

  const onError = (params: WalletConnectErrorParams) => {
    const origin =
      params?.origin ||
      walletConnect
        .instance()
        .core.pairing.getPairings()
        .find(({topic}) => topic === parseUri(uri).topic)?.peerMetadata?.url

    // replace the origin with the one from the pairing if available
    const _params = {
      ...params,
      origin,
    } as WalletConnectErrorParams
    _onError(new WalletConnectError(_params))
    disconnectSession('USER_DISCONNECTED')
  }

  const handleRejection = (
    rejectFn: () => Promise<void>,
    errorParams: WalletConnectErrorParams,
  ) => {
    rejectFn()
    // we do not trigger the `onError` logic for user rejections
    if (errorParams.errorKey !== 'USER_REJECTED') {
      onError(errorParams)
    }
  }

  const onSessionProposalCallback = useCallback(
    async (proposal: ProposalTypes.Struct) => {
      const approve: OnSessionProposalArgs['approve'] = async (namespaces) => {
        const {topic} = await walletConnect.instance().approveSession({
          id: proposal.id,
          namespaces,
        })
        // by approving the session we also establish the session topic for this connector window
        sessionTopic.current = topic
      }

      const reject: OnSessionProposalArgs['reject'] = async (
        errorKey,
        confirmInitError,
      ) =>
        handleRejection(
          async () => {
            await walletConnect.instance().rejectSession({
              id: proposal.id,
              reason: getSdkError(errorKey),
            })
          },
          {
            type: 'sessionProposal',
            errorKey,
            origin: proposal.proposer.metadata.url,
            e: confirmInitError,
            proposal,
          },
        )

      // we allow responding to session proposals only if there is no current session
      if (sessionTopic.current === null) {
        await onSessionProposal({proposal, approve, reject})
      }
    },
    [onSessionProposal, sessionTopic.current],
  )

  const onSessionRequestCallback = useCallback(
    async (requestEvent: SignClientTypes.EventArguments['session_request']) => {
      const {topic, params, id} = requestEvent

      const approve: OnSessionRequestArgs['approve'] = async (payload) => {
        const response = {id, result: payload, jsonrpc: '2.0'}
        await walletConnect.instance().respondSessionRequest({topic, response})
      }

      const reject: OnSessionRequestArgs['reject'] = async (
        errorKey,
        apiError,
      ) =>
        handleRejection(
          async () => {
            const response = {
              id,
              jsonrpc: '2.0',
              error: getSdkError(errorKey),
            }
            await walletConnect
              .instance()
              .respondSessionRequest({topic, response})
          },
          {
            type: 'sessionRequest',
            errorKey,
            origin: requestEvent.verifyContext.verified.origin,
            e: apiError,
            requestEvent,
          },
        )

      // NOTE: some dapps send events even before we set the current session topic
      // so for now we cannot check if the session matches
      // respondIfCurrentSession(requestEvent, () =>)
      onSessionRequest({approve, reject, params})
    },
    [onSessionRequest, sessionTopic.current],
  )

  const onSessionDeleteCallback = useCallback(
    async (deleteEvent: SignClientTypes.EventArguments['session_delete']) => {
      // some dapps fire the delete event after the connector window is closed, which
      // is then sometimes received by the next connector window, we want react only to
      // events relevant to the current connector window
      respondIfCurrentSession(deleteEvent, () => onSessionDelete(deleteEvent))
    },
    [onSessionDelete, sessionTopic.current],
  )

  useEffect(() => {
    if (!initialized) {
      return
    }

    walletConnect.instance().on('session_request', onSessionRequestCallback)
    walletConnect.instance().on('session_delete', onSessionDeleteCallback)

    if (sessionTopic.current === null) {
      getSessionProposal(uri)
        .then((res) => {
          if (res) {
            onSessionProposalCallback(res)
          } else {
            onError({
              errorKey: 'GETTING_PROPOSAL_FAILED',
            })
          }
        })
        // reject may happen when user tries to pair with the same topic a second time,
        // after rejecting the session the first time https://github.com/orgs/WalletConnect/discussions/3119
        .catch((e) => onError({errorKey: 'UNKNOWN_ERROR', e}))
    }
    // eslint-disable-next-line consistent-return
    return () => {
      walletConnect.instance().off('session_request', onSessionRequestCallback)
      walletConnect.instance().off('session_delete', onSessionDeleteCallback)
    }
  }, [initialized])

  return {
    disconnectSession,
  }
}
