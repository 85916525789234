import {styled, Tooltip} from '@mui/material'
import React, {useEffect, useRef, useState} from 'react'

/**
 *
 * **Warning:** Avoid using this component with a very deep children structure,
 * as it loops through children recursively, which may lead to performance issues or
 * stack overflow errors in extreme cases. If your child structure is deeply nested,
 * consider refactoring to reduce the depth or use an alternative approach that does
 * not rely on deep recursion.
 *
 */
export const WithOverflowTooltip = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [isOverflowing, setIsOverflowing] = useState(false)
  const rightElementRef = useRef<HTMLDivElement | null>(null)

  function getChildrenWithOverflowProperty(element: HTMLDivElement) {
    const potentiallyOverflowingElements: Element[] = []
    getDescendants(element)

    function getDescendants(node: Element) {
      Array.from(node.children).forEach((child) => {
        if (getComputedStyle(child).overflow === 'hidden') {
          potentiallyOverflowingElements.push(child)
        }
        getDescendants(child)
      })
    }

    return potentiallyOverflowingElements
  }

  useEffect(() => {
    if (rightElementRef.current) {
      const element = rightElementRef.current
      const childrenWithOverflowProperty =
        getChildrenWithOverflowProperty(element)
      const hasOverflowX = [...childrenWithOverflowProperty, element].some(
        (_node) => {
          const node = _node as HTMLElement
          return node.offsetWidth < node.scrollWidth
        },
      )

      if (hasOverflowX) {
        setIsOverflowing(true)
      }
    }
  }, [])

  return (
    <Tooltip title={isOverflowing ? children : null} placement="top">
      <OverflowWrapper ref={rightElementRef}>{children}</OverflowWrapper>
    </Tooltip>
  )
}

const OverflowWrapper = styled('div')({
  overflow: 'hidden',
  maxWidth: '100%',
})
