import {ArrowForwardIosSharp as ArrowIcon} from '@mui/icons-material'
import type {AccordionDetailsProps} from '@mui/material'
import {
  styled,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  Typography,
  AccordionActions as MuiAccordionActions,
  Button,
  Chip,
} from '@mui/material'
import React, {useState} from 'react'

import {useSignContext} from 'src/dappConnector/pages/sign/SignContext'
import type {Blockchain} from 'src/wallet'

import {EllipsizedAddress} from './EllipsizedAddress'
import {WithOverflowTooltip} from './WithOverflowTooltip'

export type TxAccordionRowProps = {
  summary: React.ReactNode
  details?: React.ReactNode
  divideDetailsChildren?: boolean
  defaultExpanded?: boolean
  actions?: {
    onClick: () => void
    icon?: React.ReactNode
    label: string
  }[]
}

export const TxAccordionRow = ({
  summary,
  details,
  divideDetailsChildren,
  defaultExpanded = false,
  actions,
}: TxAccordionRowProps) => {
  const [expanded, setExpanded] = useState<boolean>(defaultExpanded)

  const handleChange = () => {
    setExpanded((prevState) => !prevState)
  }

  const {layoutProps} = useSignContext()

  const backgroundColors = {
    paper: {
      summary: 'background.paper',
      detail: 'background.default',
    },
    default: {
      summary: 'background.default',
      detail: 'background.paper',
    },
  }[layoutProps.txDataBgColor || 'paper']

  return (
    <Accordion
      expanded={expanded}
      onChange={details ? handleChange : undefined}
    >
      <AccordionSummary
        expandIcon={details ? undefined : null}
        sx={{backgroundColor: backgroundColors.summary}}
      >
        {typeof summary === 'string' ? (
          <Typography variant="body2">{summary}</Typography>
        ) : (
          summary
        )}
      </AccordionSummary>
      {details && (
        <AccordionDetails
          divideChildren={divideDetailsChildren}
          sx={{backgroundColor: backgroundColors.detail}}
        >
          {details}
        </AccordionDetails>
      )}

      {actions && actions.length > 0 && (
        <AccordionActions>
          {actions.map(({label, icon, ...buttonProps}) => (
            <Button {...buttonProps} startIcon={icon}>
              {label}
            </Button>
          ))}
        </AccordionActions>
      )}
    </Accordion>
  )
}

const borderRadiusValue = 8

const Accordion = styled(MuiAccordion)(({theme}) => ({
  border: `1px solid ${theme.palette.divider}`,

  '& .MuiAccordionSummary-root.Mui-expanded': {
    borderBottom: 'none',
  },
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:first-of-type': {
    overflow: 'hidden',
    borderTopLeftRadius: borderRadiusValue,
    borderTopRightRadius: borderRadiusValue,
  },
  '&:last-child': {
    overflow: 'hidden',
    borderBottomLeftRadius: borderRadiusValue,
    borderBottomRightRadius: borderRadiusValue,
  },
  '&:before': {
    display: 'none',
  },
}))

Accordion.defaultProps = {
  disableGutters: true,
  elevation: 0,
  square: true,
}

const AccordionSummary = styled(MuiAccordionSummary)(({theme}) => ({
  maxWidth: '100%',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    margin: theme.spacing(0, 1, 0, 0),
    alignItems: 'center',
    overflow: 'hidden',
  },
}))

AccordionSummary.defaultProps = {
  expandIcon: <ArrowIcon sx={{fontSize: '0.9rem'}} />,
}

const AccordionDetails = styled(MuiAccordionDetails)<
  {divideChildren?: boolean} & AccordionDetailsProps
>(({theme: {typography, spacing, palette}, divideChildren}) => ({
  ...typography.caption,
  borderBottomLeftRadius: borderRadiusValue,
  borderBottomRightRadius: borderRadiusValue,
  padding: spacing(1, 2),
  '& > *': {
    '&:not(:first-of-type)': {
      paddingTop: spacing(1),
    },
    '&:not(:last-child)': {
      paddingBottom: spacing(1),
      ...(divideChildren && {
        borderBottom: `1px solid ${palette.divider}`,
      }),
    },
  },
}))

const AccordionActions = styled(MuiAccordionActions)(({theme}) => ({
  justifyContent: 'center',
  backgroundColor: theme.palette.background.default,
  borderBottomLeftRadius: borderRadiusValue,
  borderBottomRightRadius: borderRadiusValue,
}))

export const RowSpaceBetweenWrapper = styled('div')(({theme}) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  gap: theme.spacing(1),
}))

export const AddressRow = ({
  label,
  address,
  blockchain,
}: {
  label: string
  address: string
  blockchain: Blockchain
}) => (
  <RowSpaceBetweenWrapper>
    <Typography variant="body2">{label}</Typography>
    <Typography color="textSecondary" component="div">
      <EllipsizedAddress address={address} blockchain={blockchain} />
    </Typography>
  </RowSpaceBetweenWrapper>
)

type RowProps = {
  left: React.ReactNode
  right: React.ReactNode
}

export const ChipRow = ({
  label,
  chipContent,
}: {
  label: string
  chipContent: React.ReactNode
}) => (
  <RowSpaceBetweenWrapper>
    <Typography variant="body2">{label}</Typography>
    <Typography variant="caption">
      <Chip label={chipContent} size="small" />
    </Typography>
  </RowSpaceBetweenWrapper>
)

export const TxDetailRow = ({left, right}: RowProps) => {
  return (
    <RowSpaceBetweenWrapper>
      <Typography variant="body2" component="div">
        {left}
      </Typography>
      <WithOverflowTooltip>
        <TxDetailRightSection color="textSecondary">
          {right}
        </TxDetailRightSection>
      </WithOverflowTooltip>
    </RowSpaceBetweenWrapper>
  )
}

const TxDetailRightSection = styled('div')(({theme}) => ({
  ...theme.typography.body1,
  color: theme.palette.text.secondary,
}))
