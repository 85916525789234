import _ from 'lodash'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {QueryGuard, ellipsizeString} from '../../../../components'
import type {
  CardanoPoolIdHex,
  CardanoStakepoolInfo,
} from '../../../../wallet/cardano'
import {
  findValidator,
  isValidStakepoolBech32Id,
  isValidStakepoolId,
  stakepoolIdToBech32,
  useGetStakepools,
} from '../../../../wallet/cardano'

type FormattedStakepoolTickerProps = {
  poolId: CardanoPoolIdHex | null
  verbose?: boolean
}

export function FormattedStakepoolTicker({
  poolId,
  verbose = true,
}: FormattedStakepoolTickerProps) {
  const {t} = useTranslation()
  const stakePoolsQuery = useGetStakepools()

  return (
    <QueryGuard {...stakePoolsQuery}>
      {(validators) => (
        <>
          {formatStakepoolTicker(
            poolId,
            validators,
            t('Unknown ticker'),
            t('Unknown pool'),
            verbose,
          )}
        </>
      )}
    </QueryGuard>
  )
}

export function formatStakepoolTicker(
  poolId: CardanoPoolIdHex | null,
  validators: CardanoStakepoolInfo[],
  noTickerText: string,
  unknownPoolText: string,
  verbose = true,
) {
  if (!poolId) {
    return '-'
  }

  if (!isValidStakepoolId(poolId)) {
    return poolId
  }
  const foundValidator = findValidator(validators, poolId)
  const unescapedName = foundValidator?.name
    ? _.unescape(foundValidator.name)
    : undefined

  if (foundValidator && (foundValidator.ticker || unescapedName)) {
    const verboseTickerText = `[${foundValidator.ticker || noTickerText}] ${
      unescapedName || ''
    }`

    return verbose
      ? verboseTickerText
      : `${foundValidator.ticker || noTickerText}`
  }

  const verbosePoolText = ellipsizeString(
    isValidStakepoolBech32Id(poolId) ? poolId : stakepoolIdToBech32(poolId),
    20,
    10,
  )

  return `${unknownPoolText}${verbose ? ` (${verbosePoolText})` : ''}`
}
