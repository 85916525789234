import {Box, FormControl, Typography} from '@mui/material'
import type {AccountId, StakeAccountId} from '@nufi/wallet-common'
import type {SolanaAccountWithStakeAccounts} from '@nufi/wallet-solana'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {
  PagingButton,
  QueryGuard,
  SearchableSelect,
  LoadStakingDataError,
  Alert,
} from '../../../../components'
import type {StakeHistoryAccountOption} from '../../../../types'
import {useScrollableStyles} from '../../../../utils/layoutUtils'
import {
  useGetStakeAccountsInfo,
  useGetPaginatedWholeStakeHistory,
} from '../../../../wallet/solana'
import {StakeHistoryListHeader} from '../../common/history/StakeHistoryLayout'
import {StakingTabContentLoading} from '../../common/Loadings'

import StakeHistoryList from './StakeHistoryList'

export default function StakeHistory() {
  const stakeAccountsQuery = useGetStakeAccountsInfo()
  return (
    <QueryGuard
      {...stakeAccountsQuery}
      ErrorElement={<LoadStakingDataError blockchain="solana" />}
      loadingVariant="centered"
    >
      {(accounts) => <StakeHistoryScreen accounts={accounts} />}
    </QueryGuard>
  )
}

function StakeHistoryScreen({
  accounts,
}: {
  accounts: SolanaAccountWithStakeAccounts[]
}) {
  const {t} = useTranslation()
  const {scrollableParent} = useScrollableStyles()

  // never empty, always has at least the parent account(s)
  const stakeHistoryOptions = createHistoryOptionList(accounts)
  const [selectedOption, setSelectedOption] =
    useState<StakeHistoryAccountOption>(stakeHistoryOptions[0]!)

  return (
    <Box className={scrollableParent}>
      <Box my={1}>
        <FormControl variant="outlined" fullWidth>
          <SearchableSelect
            label={t('Account')}
            options={stakeHistoryOptions}
            value={selectedOption}
            onChange={setSelectedOption as (value: unknown) => void}
            getOptionLabel={(option) => option.label}
            isOptionEqualToValue={(option, value) =>
              option.label === value.label
            }
            searchable
          />
        </FormControl>
      </Box>

      {selectedOption ? (
        <StakeHistoryContent
          accountId={selectedOption.accountId}
          stakeAccountId={
            selectedOption.stakeAccountId ? selectedOption.stakeAccountId : null
          }
        />
      ) : (
        <Box m={1}>
          <Typography>
            {t('Please select an account from the select.')}
          </Typography>
        </Box>
      )}
    </Box>
  )
}

function StakeHistoryContent({
  accountId,
  stakeAccountId,
}: {
  accountId: AccountId
  stakeAccountId: StakeAccountId | null
}) {
  const {t} = useTranslation()
  const stakeHistoryQuery = useGetPaginatedWholeStakeHistory(
    accountId,
    stakeAccountId,
  )
  const {isLoading, fetchNextPage, hasNextPage, isFetching} = stakeHistoryQuery
  const {scrollableParent, scrollableList} = useScrollableStyles()

  return (
    <QueryGuard
      {...stakeHistoryQuery}
      LoadingElement={
        <>
          <Alert
            text={t(
              'Solana stake history can take a while to load. Please stand by.',
            )}
            severity="info"
          />
          <StakingTabContentLoading />
        </>
      }
      ErrorElement={<LoadStakingDataError blockchain="solana" />}
    >
      {(stakeHistory) => (
        <Box className={scrollableList}>
          <Box className={scrollableParent}>
            <StakeHistoryListHeader />
            <Box className={scrollableList}>
              <StakeHistoryList stakeHistory={stakeHistory} />
              <Box textAlign="center">
                <PagingButton
                  pagingParams={{onNextPage: fetchNextPage, hasNextPage}}
                  isLoading={isLoading || isFetching}
                  loadingText={t(
                    'Fetching... Please stand by, this action can take a while.',
                  )}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </QueryGuard>
  )
}

function createHistoryOptionList(
  accounts: SolanaAccountWithStakeAccounts[],
): StakeHistoryAccountOption[] {
  return accounts.flatMap((a) => [
    {
      accountId: a.id,
      label: a.name,
    },
    ...a.stakeAccounts.map((sa) => ({
      accountId: a.id,
      label: `> ${sa.creationDate ? sa.creationDate.toLocaleString() : ''}${
        sa.validator ? ` | ${sa.validator.toString()}` : ''
      }`,
      stakeAccountId: sa.id,
    })),
  ])
}
