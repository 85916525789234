import {
  getExchangeParamsImperative,
  useExchangeCreateSwap,
  useGetExchangeAssetsDetails,
  useGetExchangeConditions,
  useGetExchangeParams,
  validateExchangeAddress,
} from 'src/features/exchange/application'
import {useSaveSwap} from 'src/features/swaps/application'
import {useVerifyPassword} from 'src/utils/form'
import type {BaseQuery, BaseQueryWithRefetch} from 'src/utils/query-utils'
import {createVMContextHandlers} from 'src/utils/viewModel'
import {useAllAccounts, useGetAccounts, useGetTokenMetadata} from 'src/wallet'

type ExchangeModalViewModelBaseQueries = {
  useGetAccounts: BaseQuery<typeof useGetAccounts>
  useGetExchangeAssetsDetails: BaseQuery<typeof useGetExchangeAssetsDetails>
  useGetExchangeConditions: BaseQuery<typeof useGetExchangeConditions>
  useGetExchangeParams: BaseQueryWithRefetch<typeof useGetExchangeParams>
}

const exchangeModalViewModelRest = {
  useAllAccounts,
  useExchangeCreateSwap,
  useSaveSwap,
  useVerifyPassword,
  getExchangeParamsImperative,
  validateExchangeAddress,
  useGetTokenMetadata,
}

export type ExchangeModalViewModel = ExchangeModalViewModelBaseQueries &
  typeof exchangeModalViewModelRest

export const exchangeModalViewModel: ExchangeModalViewModel = {
  useGetExchangeParams,
  useGetExchangeConditions,
  useGetAccounts,
  useGetExchangeAssetsDetails,
  ...exchangeModalViewModelRest,
}

export const {
  ViewModelProvider: ExchangeModalVMContextProvider,
  useViewModel: useExchangeModalViewModel,
} = createVMContextHandlers<ExchangeModalViewModel>()
