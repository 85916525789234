import {
  Check as SuccessIcon,
  ErrorOutline as ErrorIcon,
  WarningAmber as WarningIcon,
} from '@mui/icons-material'
import {Box, Card, Grid, Link, Typography} from '@mui/material'
import React from 'react'
import {Trans, useTranslation} from 'react-i18next'

import {useSyncProfileData} from '../../appStorage'
import {
  Alert,
  Button,
  FormattedDateTime,
  LabeledIconWithTooltip,
} from '../../components'
import {externalLinks} from '../../constants'
import type {
  ProfileSyncState,
  ProfileSyncStatus as ProfileSyncStatusType,
} from '../../store/profileSync'

import {useCommonStyles} from './utils'

const useStatusConfig = (status: ProfileSyncStatusType) => {
  const {t} = useTranslation()

  return {
    'not-initialized': {
      title: t('N/A'),
      description: '',
      icon: <ErrorIcon color="error" />,
      severity: 'error' as const,
    },
    success: {
      title: t('ProfileSyncStatus.SUCCESS.title'),
      description: t('ProfileSyncStatus.SUCCESS.description'),
      icon: <SuccessIcon color="primary" />,
      severity: 'success' as const,
    },
    failed: {
      title: t('ProfileSyncStatus.FAILED.title'),
      description: (
        <Trans
          i18nKey="ProfileSyncStatus.FAILED.description"
          t={t}
          components={{
            Link: (
              <Link
                color="primary"
                target="_blank"
                href={externalLinks.nufiHelpLink}
              />
            ),
          }}
        />
      ),
      icon: <ErrorIcon color="error" />,
      severity: 'error' as const,
    },
    disabled: {
      title: t('ProfileSyncStatus.DISABLED.title'),
      description: t('ProfileSyncStatus.DISABLED.description'),
      icon: <WarningIcon color="warning" />,
      severity: 'warning' as const,
    },
    paused: {
      title: t('ProfileSyncStatus.PAUSED.title'),
      description: (
        <Trans
          i18nKey="ProfileSyncStatus.PAUSED.description"
          t={t}
          components={{
            Link: (
              <Link
                color="primary"
                target="_blank"
                href={externalLinks.nufiHelpLink}
              />
            ),
          }}
        />
      ),
      icon: <WarningIcon color="warning" />,
      severity: 'warning' as const,
    },
  }[status]
}

export type ProfileSyncStatusProps = {
  lastSyncStatus: ProfileSyncState['lastSyncStatus']
  lastSyncAt: ProfileSyncState['lastSyncAt']
  lastSyncAttemptAt: ProfileSyncState['lastSyncAttemptAt']
}

export const ProfileSyncStatus = ({
  lastSyncStatus,
  lastSyncAt,
  lastSyncAttemptAt,
}: ProfileSyncStatusProps) => {
  const {t} = useTranslation()
  const commonClasses = useCommonStyles()
  const syncProfileData = useSyncProfileData()

  const statusConfig = useStatusConfig(lastSyncStatus)

  const invokeCloudSync = async () => {
    await syncProfileData.mutateAsync()
  }

  const ProfileSyncStatusSuccess = () => (
    <>
      <Card>
        <Grid
          item
          container
          justifyContent="space-between"
          alignItems="center"
          p={2}
        >
          <Grid item>
            <Typography color="textSecondary">{t('Status')}</Typography>
          </Grid>
          <Grid item>
            <LabeledIconWithTooltip
              Label={
                <Typography textAlign="right">
                  {t('Your wallet is synchronized')}
                </Typography>
              }
              iconPosition="end"
              Icon={statusConfig.icon}
              spacing={0.5}
              title={statusConfig.description}
            />
          </Grid>
        </Grid>
      </Card>
      <Card>
        <Grid
          item
          container
          justifyContent="space-between"
          alignItems="center"
          p={2}
        >
          <Grid item>
            <Typography color="textSecondary">{t('Last sync')}</Typography>
          </Grid>
          <Grid item>
            <Typography textAlign="right">
              {lastSyncAt ? (
                <FormattedDateTime
                  dateTime={new Date(lastSyncAt)}
                  variant="datetime"
                />
              ) : (
                t('N/A')
              )}
            </Typography>
          </Grid>
        </Grid>
      </Card>
    </>
  )

  const ProfileSyncStatusFailed = () => (
    <>
      <Card>
        <Grid
          item
          container
          justifyContent="space-between"
          alignItems="center"
          p={2}
        >
          <Grid item>
            <Typography color="textSecondary">{t('Status')}</Typography>
          </Grid>
          <Grid item>
            <LabeledIconWithTooltip
              Label={
                <Typography textAlign="right">
                  {t('Last synced at')}{' '}
                  {lastSyncAt ? (
                    <FormattedDateTime
                      dateTime={new Date(lastSyncAt)}
                      variant="datetime"
                    />
                  ) : (
                    t('N/A')
                  )}
                </Typography>
              }
              iconPosition="end"
              Icon={statusConfig.icon}
              spacing={0.5}
              title={statusConfig.description}
            />
          </Grid>
        </Grid>
      </Card>
      <Card>
        <Grid
          item
          container
          justifyContent="space-between"
          alignItems="center"
          p={2}
        >
          <Grid item>
            <Typography color="textSecondary">{t('Last attempt')}</Typography>
          </Grid>
          <Grid item>
            <Typography textAlign="right">
              {lastSyncAttemptAt ? (
                <FormattedDateTime
                  dateTime={new Date(lastSyncAttemptAt)}
                  variant="datetime"
                />
              ) : (
                t('N/A')
              )}
            </Typography>
          </Grid>
        </Grid>
      </Card>
      {statusConfig.description && (
        <Grid item container>
          <Alert
            severity={statusConfig.severity}
            text={statusConfig.description}
          />
        </Grid>
      )}
    </>
  )

  return (
    <Box className={commonClasses.sectionWrapper}>
      <Grid container direction="column" gap={2}>
        {lastSyncStatus === 'success' ? (
          <ProfileSyncStatusSuccess />
        ) : (
          <ProfileSyncStatusFailed />
        )}
        <Grid item container>
          <Button
            fullWidth
            textTransform="none"
            variant="contained"
            color="secondary"
            loading={syncProfileData.isPending}
            disabled={syncProfileData.isPending}
            onClick={invokeCloudSync}
          >
            {syncProfileData.isPending ? t('Syncing') : t('Sync now')}
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}
