import {Sentry} from '@nufi/frontend-common'
import {useQuery} from '@tanstack/react-query'

import {getAvailableBlockchains} from 'src/features/availableBlockchains/application'

import queryClient from '../../../queryClient'
import type {CoinConversionRates} from '../domain'

import type {ConversionRateRefetchParams} from './common'
import {conversionRatesService} from './conversionRatesService'
import {conversionRatesQueryKeys} from './queryKeys'

type CoinConversionRatesResult = {
  data?: CoinConversionRates
  error?: unknown
}

const getCoinConversionRates = async (): Promise<CoinConversionRatesResult> => {
  try {
    const data = await conversionRatesService.getCoinConversionRates()
    return {data}
  } catch (error) {
    Sentry.captureException(error)
    return {
      data: getAvailableBlockchains().reduce((res: CoinConversionRates, t) => {
        res[t] = 'not-loaded'
        return res
      }, {}),
    }
  }
}
getCoinConversionRates.__key = conversionRatesQueryKeys.coins

export function useGetCoinConversionRates({
  refetchOptions,
}: ConversionRateRefetchParams) {
  return useQuery({
    queryKey: getCoinConversionRates.__key,
    queryFn: getCoinConversionRates,
    ...refetchOptions,
  })
}

export function cachedGetCoinConversionRates({
  refetchOptions,
}: ConversionRateRefetchParams) {
  return queryClient.fetchQuery({
    queryKey: getCoinConversionRates.__key,
    queryFn: getCoinConversionRates,
    ...refetchOptions,
  })
}

export async function getInMemoryCoinConversionRates() {
  const queryKey = conversionRatesQueryKeys.coins
  // Used to return the current data without waiting on a network request that
  // may take long time or fail. We are assuming that this data will be eventually
  // consistent, because the query consuming this data should be invalidated
  // every time this data changes.
  return {
    ...(await queryClient.getQueryData(queryKey)),
  } as CoinConversionRatesResult
}
