import type {SxProps} from '@mui/material'
import {TextField} from '@mui/material'
import type {
  FlowDelegationInfo,
  FlowEpochInfo,
  FlowNodeId,
  FlowNodeRole,
  FlowStakeAccountId,
  FlowStakingInfo,
} from '@nufi/wallet-flow'
import type {FormikProps} from 'formik'
import React from 'react'
import {useTranslation} from 'react-i18next'

import type {StakingBlockchain} from '../../../blockchainTypes'
import {
  TextButton,
  WithTooltip,
  ellipsizeString,
  StringEllipsis,
} from '../../../components'
import {assert} from '../../../utils/assertion'
import {
  sanitizeAmountFieldValue,
  isValidAmountFieldValue,
  getHasFormError,
} from '../../../utils/form'
import {useGetCoinName} from '../../../utils/translations'

import type {ValidatorOption} from './actionModals/registerDelegator/types'
import type {MaxAmountOptions} from './types'

export function useMappedNodeRoles(nodeRole: FlowNodeRole) {
  const {t} = useTranslation()
  const mappedNodeRoleToKeys: {[key in FlowNodeRole]: string} = {
    collection: t('Collection'),
    consensus: t('Consensus'),
    execution: t('Execution'),
    verification: t('Verification'),
    access: t('Access'),
  }

  return mappedNodeRoleToKeys[nodeRole]
}

export function FormattedValidatorType({nodeRole}: {nodeRole: FlowNodeRole}) {
  const mappedNodeRole = useMappedNodeRoles(nodeRole)
  return <>{mappedNodeRole}</>
}

export function FormattedValidatorName({name}: {name?: string}) {
  const {t} = useTranslation()
  return <>{name || t('Unknown name')}</>
}

export function FormattedValidatorOption({
  validatorOption,
}: {
  validatorOption: ValidatorOption
}) {
  const {t} = useTranslation()
  const nodeRole = useMappedNodeRoles(validatorOption.role)
  const ellipsizedNodeId = ellipsizeString(validatorOption.id, 10, 10)
  return (
    <>{`${
      validatorOption.name || t('Unknown name')
    } - ${nodeRole} (${ellipsizedNodeId})`}</>
  )
}

type AmountFieldProps<T> = {
  blockchain: StakingBlockchain
  formikProps: FormikProps<T>
  maxAmountOptions?: MaxAmountOptions
}

export function AmountField<T extends Record<string, unknown>>({
  blockchain,
  formikProps,
  maxAmountOptions,
}: AmountFieldProps<T>) {
  const {t} = useTranslation()
  const getCoinName = useGetCoinName()
  const {values, handleChange, errors, handleBlur} = formikProps

  const MaxButton = maxAmountOptions && (
    <TextButton
      color="text.primary"
      disabled={!!maxAmountOptions?.disabled}
      label={t('MAX')}
      onClick={maxAmountOptions?.onMaxAmount}
    />
  )

  const hasErrorAfterTouched = getHasFormError(formikProps, 'after-touched')
  type FormField = keyof typeof values
  return (
    <TextField
      fullWidth
      variant="outlined"
      label={`${t('Amount')} (${getCoinName(blockchain)})`}
      onBlur={(e) => {
        // without handleBlur the "touched" fields are not populated
        const onBlur = handleBlur<FormField>('amount') as (e: unknown) => void
        onBlur(e)
      }}
      onChange={(e) => {
        const sanitized = sanitizeAmountFieldValue(e.target.value)
        const isValid = isValidAmountFieldValue(sanitized) || sanitized === ''
        if (!isValid) return
        handleChange<FormField>('amount')(sanitized)
      }}
      error={hasErrorAfterTouched('amount')}
      value={values.amount}
      helperText={hasErrorAfterTouched('amount') && <>{errors.amount}</>}
      InputProps={{
        endAdornment:
          maxAmountOptions?.disabled && maxAmountOptions?.helperText ? (
            <WithTooltip title={maxAmountOptions.helperText} placement="left">
              {MaxButton}
            </WithTooltip>
          ) : (
            <>{MaxButton}</>
          ),
      }}
    />
  )
}

export function getFlowStakeAccountId(
  validatorId: FlowNodeId,
  delegatorId: string,
) {
  return `${validatorId}-${delegatorId}` as FlowStakeAccountId
}

export function parseFlowStakeAccountId(stakeAccountId: FlowStakeAccountId) {
  const [validator, delegationId] = stakeAccountId.split('-')
  return {
    validatorId: validator! satisfies string as FlowNodeId,
    delegatorId: delegationId!,
  }
}

export const assertIsDelegating = (
  stakingInfo: FlowStakingInfo,
  validatorId: FlowNodeId,
  delegatorId: string,
): FlowDelegationInfo => {
  assert(stakingInfo.isStakingCollectionRegistered)
  const delegator = stakingInfo.delegations.find(
    ({nodeId, id}) => nodeId === validatorId && delegatorId === id,
  )
  assert(!!delegator)
  return delegator
}

export function useMappedEpochPhases() {
  const {t} = useTranslation()
  const mappedEpochPhases: {[key in FlowEpochInfo['epochPhase']]: string} = {
    'staking-auction': t('Staking auction'),
    'epoch-commit': t('Epoch commit'),
    'epoch-setup': t('Epoch setup'),
  }

  return mappedEpochPhases
}

type StakingStringEllipsisProps = {
  sx?: SxProps
  value: string
}

export const StakingStringEllipsis = ({
  sx,
  value,
}: StakingStringEllipsisProps) => (
  <StringEllipsis
    value={value}
    length={7}
    width="10vw"
    ellipsizedTextSx={{flex: 2, ...sx}}
  />
)
