import type {FlowTokenMetadata} from '@nufi/wallet-flow'
import {parseFlowTokenId} from '@nufi/wallet-flow'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {
  ExplorerLink,
  FlowTokenExplorerLink,
  getFlowTokenExplorerLink,
} from '../../../components'
import {assert} from '../../../utils/assertion'
import {NftDetailPage} from '../../nfts/NftDetail'
import {AboutRow} from '../assetDetail/AboutRow'
import type {NftInfo} from '../assetDetail/types'

type FlowTokenDetailInfoProps = {
  token: FlowTokenMetadata
  nftInfo: NftInfo
}

export function FlowTokenDetailInfo({
  nftInfo,
  token,
}: FlowTokenDetailInfoProps) {
  const {t} = useTranslation()
  if (!nftInfo)
    return (
      <>
        <AboutRow
          label={t('Type')}
          value={
            token.contractMetadata.isFungible ? t('Fungible token') : t('NFT')
          }
        />
        {token.decimals !== undefined && (
          <AboutRow label={t('Decimals')} value={token.decimals} />
        )}
        <AboutRow
          label={t('Contract address')}
          value={
            <ExplorerLink
              link={getFlowTokenExplorerLink(token.id)}
              size="inherit"
              label={parseFlowTokenId(token.id).contractAddress}
            />
          }
        />
      </>
    )

  assert(nftInfo.blockchain === 'flow')

  const details = [
    {label: t('Collection'), value: nftInfo.contractMetadata.collection.name},
    {label: t('Description'), value: nftInfo.metadata.description},
    {
      label: t('More details'),
      value: <FlowTokenExplorerLink tokenId={token.id} />,
    },
  ]

  const traits = nftInfo.metadata.tags.map((t) => ({
    label: t.name,
    value: t.value,
  }))

  return (
    <NftDetailPage
      details={details}
      traits={traits}
      blockchain={nftInfo.blockchain}
      nftName={token.name}
      collectionName={nftInfo.contractMetadata.collection.name}
    />
  )
}
