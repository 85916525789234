import type BigNumber from 'bignumber.js'
import React from 'react'

type FormattedPercentageProps = {
  value: BigNumber
}

export function FormattedPercentage({value}: FormattedPercentageProps) {
  return <>{value.multipliedBy(100).toFormat(2)}%</>
}
