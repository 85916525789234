import {Box, Grid, Typography} from '@mui/material'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Route, Switch, useHistory, useLocation} from 'react-router-dom'

import {
  filterAvailableBlockchains,
  getAvailableBlockchains,
} from 'src/features/availableBlockchains/application'
import {
  useEnabledExistingBlockchains,
  useIsBlockchainEnabled,
} from 'src/features/profile/application'
import {ManageBlockchainsIconButton} from 'src/features/walletStorage/ui'
import {NoBlockchainsEnabledScreen} from 'src/features/walletStorage/ui/NoBlockchainsEnabledScreen'

import {nftBlockchains} from '../../blockchainTypes'
import {
  Modal,
  QueryGuard,
  LoadBlockchainAccountsError,
  SafeCenterAligner,
  Button,
  Icon,
  CollapsibleSearchField,
  BlockchainSelectField,
} from '../../components'
import config from '../../config'
import {routeTo} from '../../router'
import {useNFTRouteOptions} from '../../router/nft'
import {assert} from '../../utils/assertion'
import {
  useScrollableStyles,
  FiltersWrapper,
  FilterInputWrapper,
} from '../../utils/layoutUtils'
import {useGetBlockchainName} from '../../utils/translations'
import type {AccountId, Blockchain, Nft} from '../../wallet'
import {
  getDefaultAccountId,
  useAccountsWithNftSnapshot,
  useHasAccounts,
} from '../../wallet'
import {NoAccountsScreen} from '../portfolio/account/NoAccountsScreen'
import {NftDetail} from '../portfolio/assetDetail/AssetDetail'
import {usePortfolioPageContext} from '../portfolio/PortfolioContext'
import {ReceiveModal} from '../receive'
import {SendModal} from '../send/SendModal'

import {ImportTokenBanner} from './flow/ImportTokenBanner'
import {
  NftList,
  useNftListTwoRowsItemsCount,
  BackdropBackground,
  NFTAccountsContentLoading,
} from './layoutUtils'
import {NftPerAccountList} from './NftPerAccountList'
import {NftSelectionStateProvider} from './selectNftsState'
import type {NftLocationState} from './types'

const useNftBlockchains = () => {
  // Use the general Blockchain type (rather than the NftBlockchain type)
  // since the rest of the file is kept general as well (at least for now).
  const supportedBlockchains =
    filterAvailableBlockchains<Blockchain>(nftBlockchains)
  const betaBlockchains = filterAvailableBlockchains<Blockchain>([])

  return {supportedBlockchains, betaBlockchains}
}

const SelectionScreenWrapper = ({children}: {children: React.ReactElement}) => (
  <NftSelectionStateProvider>
    <BackdropBackground />
    {children}
  </NftSelectionStateProvider>
)

const _NftPerAccountList = () => (
  <SelectionScreenWrapper>
    <NftPerAccountList nftVisibility="visible" />
  </SelectionScreenWrapper>
)

const _HiddenNftPerAccountList = () => (
  <SelectionScreenWrapper>
    <NftPerAccountList nftVisibility="hidden" />
  </SelectionScreenWrapper>
)

const _NftPerBlockchainList = (props: NftPerBlockchainListProps) => (
  <SelectionScreenWrapper>
    <NftPerBlockchainList {...props} />
  </SelectionScreenWrapper>
)

export function NftsPage() {
  const {blockchain, setBlockchain} = usePortfolioPageContext()
  const history = useHistory()
  const location = useLocation<NftLocationState>()
  const background = location.state && location.state.background
  const onClose = () => history.goBack()
  const {scrollableParent} = useScrollableStyles()

  return (
    <Box pt={1} className={scrollableParent}>
      <Switch location={background || location}>
        <Route
          path={routeTo.portfolio.nfts.blockchain(':blockchain').detail.index}
          component={NftDetail}
        />
        <Route
          exact
          path={
            routeTo.portfolio.nfts
              .blockchain(':blockchain')
              .account(':accountId').index
          }
          component={_NftPerAccountList}
        />
        {config.isNftHidingEnabled && (
          <Route
            exact
            path={
              routeTo.portfolio.nfts
                .blockchain(':blockchain')
                .account(':accountId').hiddenNFTs
            }
            component={() => <_HiddenNftPerAccountList />}
          />
        )}
        <Route
          path={routeTo.portfolio.nfts.blockchain(':blockchain').index}
          render={() => (
            <_NftPerBlockchainList
              onBlockchainChange={setBlockchain}
              {...{blockchain}}
            />
          )}
        />
      </Switch>
      <Route
        exact
        path={
          routeTo.portfolio.nfts.blockchain(':blockchain').account(':accountId')
            .receive
        }
      >
        <ReceiveModal onClose={() => history.goBack()} />
      </Route>
      {background && (
        <Route
          path={routeTo.portfolio.nfts
            .blockchain(':blockchain')
            .send(':accountId')}
        >
          <Modal onClose={onClose} variant="left">
            <SendModal onClose={onClose} />
          </Modal>
        </Route>
      )}
    </Box>
  )
}

type NftPerBlockchainListProps = {
  blockchain: Blockchain | null
  onBlockchainChange: (b: Blockchain | null) => void
}

function NftPerBlockchainList({
  blockchain: selectFieldBlockchain,
  onBlockchainChange,
}: NftPerBlockchainListProps) {
  const {t} = useTranslation()
  const history = useHistory()
  const {blockchain: urlBlockchain} = useNFTRouteOptions()
  const {scrollableParent} = useScrollableStyles()
  const getBlockchainName = useGetBlockchainName()
  const [searchText, setSearchText] = useState('')
  const enabledExistingBlockchains = useEnabledExistingBlockchains()
  const disabledExistingBlockchains = getAvailableBlockchains().filter(
    (b) => !enabledExistingBlockchains.includes(b),
  )
  const {supportedBlockchains, betaBlockchains} = useNftBlockchains()

  const onChange = (value: Blockchain | null) => {
    onBlockchainChange(value)
    const selectedBlockchain = value == null ? 'none' : value
    history.push(routeTo.portfolio.nfts.blockchain(selectedBlockchain).index)
  }

  return (
    <Box className={scrollableParent}>
      <Box
        pb={1}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <FiltersWrapper>
          <FilterInputWrapper>
            <BlockchainSelectField
              value={selectFieldBlockchain}
              getBlockchainName={(b) => {
                if (betaBlockchains.includes(b))
                  return `${getBlockchainName(b)} (Beta)`
                return getBlockchainName(b)
              }}
              getOptionDisabled={(b) =>
                !enabledExistingBlockchains.includes(b) ||
                !supportedBlockchains.includes(b)
              }
              {...{onChange}}
              allowedBlockchains={[
                ...enabledExistingBlockchains,
                ...disabledExistingBlockchains,
              ]}
              label={t('Blockchain')}
            />
          </FilterInputWrapper>
          <ManageBlockchainsIconButton />
          {selectFieldBlockchain && (
            <FilterInputWrapper>
              <CollapsibleSearchField
                value={searchText}
                onChange={setSearchText}
              />
            </FilterInputWrapper>
          )}
        </FiltersWrapper>
        {urlBlockchain === 'flow' && <ImportTokenBanner />}
      </Box>
      {(() => {
        if (enabledExistingBlockchains.length === 0)
          return <NoBlockchainsEnabledScreen />
        if (urlBlockchain === 'none')
          return (
            <SafeCenterAligner>
              <Typography variant="h6">
                {t('Please select a blockchain')}
              </Typography>
            </SafeCenterAligner>
          )
        if (!supportedBlockchains.includes(urlBlockchain))
          return (
            <SafeCenterAligner>
              <Typography variant="h6">
                {t('nfts_not_yet_supported', {
                  blockchain: getBlockchainName(urlBlockchain),
                })}
              </Typography>
            </SafeCenterAligner>
          )
        return <LoadAccountsNftSnapshots searchText={searchText} />
      })()}
    </Box>
  )
}

type LoadAccountsNftSnapshotsProps = {
  searchText: string
}

function LoadAccountsNftSnapshots({searchText}: LoadAccountsNftSnapshotsProps) {
  const {blockchain} = useNFTRouteOptions()
  assert(blockchain !== 'none')
  const accountsQuery = useAccountsWithNftSnapshot({blockchain})
  const {scrollableList} = useScrollableStyles()
  const maxItemsCount = useNftListTwoRowsItemsCount()
  const {t} = useTranslation()
  const getBlockchainName = useGetBlockchainName()
  const history = useHistory()

  const hasAccounts = useHasAccounts(blockchain)
  const isBlockchainEnabled = useIsBlockchainEnabled(blockchain)

  const navigateToReceiveModal = (accountId: AccountId) => {
    history.push(
      routeTo.portfolio.nfts.blockchain(blockchain).account(accountId).receive,
    )
  }

  // Cast required because TS doesn't allow Array prototype methods on arrays of union typed elements
  const noAccountHasNfts =
    (accountsQuery.data as {nftSnapshot: {items: Nft[]}}[] | undefined)?.every(
      (a) => a.nftSnapshot?.items.length === 0,
    ) || false

  // [AddAccount or EnableBlockchain] No accounts logic is currently duplicated
  // in multiple places. We couldn't think of a way to abstract which would be
  // worth it so we settled on a bit of duplication. All the places with duplication
  // are tagged with the [AddAccount or EnableBlockchain] tag so they would be
  // easily searchable.
  if (!hasAccounts || !isBlockchainEnabled)
    return <NoAccountsScreen blockchain={blockchain} message="" />

  return (
    <QueryGuard
      {...accountsQuery}
      LoadingElement={<NFTAccountsContentLoading />}
      ErrorElement={<LoadBlockchainAccountsError blockchain={blockchain} />}
    >
      {(accounts) => (
        <Box className={scrollableList}>
          {noAccountHasNfts && (
            <SafeCenterAligner>
              <Grid
                container
                direction="column"
                spacing={2}
                alignItems="center"
                justifyContent="center"
              >
                <Grid item>
                  <Typography variant="h5">
                    {t('no_nfts_found_in_your_accounts', {
                      blockchain: getBlockchainName(blockchain),
                    })}
                  </Typography>
                </Grid>
                <Grid item>
                  <Box>
                    <Button
                      variant="contained"
                      textTransform="none"
                      size="large"
                      color="primary"
                      onClick={() =>
                        navigateToReceiveModal(
                          getDefaultAccountId(accounts) || ('' as AccountId),
                        )
                      }
                      startIcon={<Icon type="receiveIcon" exactSize={24} />}
                    >
                      {t('Receive')}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </SafeCenterAligner>
          )}
          {accountsQuery.data?.map((account) =>
            (account.nftSnapshot?.items.length || 0) > 0 ? (
              <NftList
                key={account.id}
                showHeader
                account={account}
                blockchain={blockchain}
                maxItemsCount={maxItemsCount}
                searchText={searchText}
                nftVisibility={'visible'}
              />
            ) : null,
          )}
        </Box>
      )}
    </QueryGuard>
  )
}
