import {isHumanReadable} from '@nufi/frontend-common'

import type {HexString} from '../../../../types'

export function formatHexMessage(messageHex: HexString): string | null {
  const decodeFns = [
    (msg: HexString) => Buffer.from(msg, 'hex').toString('ascii'),
    (msg: HexString) =>
      msg.length % 4 === 0 && Buffer.from(msg, 'hex').toString('utf16le'),
    // utf16 (big endian)
    (msg: HexString) =>
      msg.length % 4 === 0 &&
      Buffer.from(msg, 'hex').swap16().toString('utf16le'),
  ]

  for (const decodeFn of decodeFns) {
    const decodedMessage = decodeFn(messageHex)
    if (!decodedMessage) continue

    const isReadable = isHumanReadable(decodedMessage, {
      allowWhiteSpaces: true,
    })

    if (isReadable) {
      return `"${decodedMessage}"`
    }
  }

  return `${messageHex} (hex)`
}

export function formatTypedMessage(
  messageObject: Record<string, unknown>,
): string {
  return JSON.stringify(messageObject, null, 2)
}
