import React, {Suspense, lazy} from 'react'

import type {RampFlowBuyWidgetProps} from '../rampFlow/state/rampFlowConfig'

import {MoonpayWidgetLoading} from './loading'

export const importMoonpayBuyWidget = () => import(`./widgets/MoonpayBuyWidget`)

export const MoonpayFlowBuyer = ({
  address,
  asset: {rampId: moonpayCurrencyCode},
}: RampFlowBuyWidgetProps) => (
  <Suspense fallback={<MoonpayWidgetLoading />}>
    <LazyMoonpayBuyWidget
      accountAddress={address}
      allowedCryptoCurrencyCodes={[moonpayCurrencyCode]}
    />
  </Suspense>
)

const LazyMoonpayBuyWidget = lazy(() =>
  importMoonpayBuyWidget().then((mod) => ({default: mod.MoonpayBuyWidget})),
)
