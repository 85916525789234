import {Box, Typography, Divider} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {blockchainToWalletKind} from 'src/wallet/walletKind'

import {
  Modal,
  ModalLayout,
  FormattedAsset,
  ModalFooter,
  FooterLayout,
  Alert,
  MutationGuard,
} from '../../../../components'
import {useCryptoProviderTypeNames} from '../../../../utils/translations'
import type {AccountInfo} from '../../../../wallet'
import {useForgetAccount} from '../../../../wallet'
import {
  AccountActionModalHeader,
  AccountDetailList,
  AccountDetailRow,
} from '../utils'

type ForgetAccountProps = {
  accountInfo: AccountInfo
  onClose: () => void
}

export default function ForgetAccount({
  accountInfo: {name, balance, blockchain, cryptoProviderType, id},
  onClose,
}: ForgetAccountProps) {
  const {t} = useTranslation()
  const classes = useStyles()
  const cryptoProviderTypeNames = useCryptoProviderTypeNames()
  const walletKind = blockchainToWalletKind(blockchain)
  const forgetAccount = useForgetAccount(walletKind)

  const onForgetAccount = async () => {
    await forgetAccount.mutateAsyncSilent({accountId: id})
    onClose()
  }

  return (
    <Modal onClose={onClose} variant="centered" dismissable>
      <ModalLayout
        header={
          <AccountActionModalHeader
            label={`Forget ${name}`}
            walletKind={walletKind}
            onClose={onClose}
          />
        }
        body={
          <Box minWidth={500}>
            <AccountDetailList className={classes.contentBox}>
              <AccountDetailRow
                label={t('Balance')}
                value={
                  <Typography>
                    <FormattedAsset
                      blockchain={blockchain}
                      amount={balance}
                      isSensitiveInformation
                    />
                  </Typography>
                }
              />
              <AccountDetailRow
                label={t('Wallet type')}
                value={
                  cryptoProviderType !== 'mnemonic'
                    ? `${t('Hardware')} (${
                        cryptoProviderTypeNames[cryptoProviderType]
                      })`
                    : t('Hot')
                }
              />
            </AccountDetailList>
            <Divider />
            <Box className={classes.contentBox}>
              <Alert
                severity="warning"
                text={t(
                  "You can re-add this account at any time using the 'Add Account' function.",
                )}
              />
            </Box>
            <MutationGuard {...forgetAccount} />
          </Box>
        }
        footer={
          <ModalFooter hasDivider>
            <FooterLayout
              leftBtnConf={{
                onClick: onClose,
                children: t('Close'),
              }}
              rightBtnConf={{
                variant: 'contained',
                color: 'warning',
                onClick: onForgetAccount,
                children: t('Forget account'),
                disabled: forgetAccount.isPending,
              }}
            />
          </ModalFooter>
        }
      />
    </Modal>
  )
}

const useStyles = makeStyles((theme) => ({
  contentBox: {
    padding: theme.spacing(2),
  },
}))
