import create from 'zustand'

import type {Blockchain} from 'src/wallet/types'

import logger from './logger'

export type UiState = {
  recentlyEnabledBlockchain: Blockchain | null
  setRecentlyEnabledBlockchain: (
    recentlyEnabledBlockchain: Blockchain | null,
  ) => void
  isBlockchainManagementModalOpen: boolean
  setIsBlockchainManagementModalOpen: (
    isBlockchainManagementModalOpen: boolean,
  ) => void
}

export const useUiStore = create<UiState>(
  logger<UiState>('UiState:')((set) => ({
    recentlyEnabledBlockchain: null,
    setRecentlyEnabledBlockchain: (recentlyEnabledBlockchain) =>
      set({recentlyEnabledBlockchain}),
    isBlockchainManagementModalOpen: false,
    setIsBlockchainManagementModalOpen: (isBlockchainManagementModalOpen) =>
      set({isBlockchainManagementModalOpen}),
  })),
)

export type UseUiStore = typeof useUiStore
