import makeStyles from '@mui/styles/makeStyles'
import BigNumber from 'bignumber.js'
import type {SwapAsset} from 'common'
import React, {useState} from 'react'

import {formatSwapAssetTicker} from 'src/features/exchange/application'
import type {ExchangeAssetsDetails} from 'src/features/exchange/domain'

import type {ExchangeBlockchain} from '../../blockchainTypes'
import {BatchQueryGuard, Modal} from '../../components'
import {
  useBaseActionRouteOptions,
  useTokenIdFromRoute,
} from '../../router/portfolio'
import {assert, safeAssertUnreachable} from '../../utils/assertion'
import {getDefaultAccountId} from '../../wallet'
import type {TokenId, UseGetTokenMetadata} from '../../wallet'
import {isEvmBlockchain} from '../../wallet/evm'
import {findAccountById} from '../../wallet/utils/common'
import {authorizeTxSubmission} from '../transaction/signAuthorization'

import {findMatchingTokenSwapAsset} from './blockchains/crossBlockchain'
import {blockchainToSwapAsset, EXCHANGE_MODAL_WIDTH} from './constants'
import {CustomMetadataContainer} from './CustomMetadataContainer'
import {ExchangeConfContextProvider} from './ExchangeConfContext'
import {EXCHANGE_CONFIG} from './exchangeConfig'
import type {ExchangeModalViewModel} from './exchangeModalViewModel'
import {
  ExchangeModalVMContextProvider,
  exchangeModalViewModel,
  useExchangeModalViewModel,
} from './exchangeModalViewModel'
import {LoadingOrError} from './screens/common'
import type {ExchangeDepsConfig} from './screens/common'
import {DetailsScreen} from './screens/details'
import type {InitialInitializationData} from './screens/details'
import type {DetailsValues} from './screens/details/schema'
import {SignTxScreen} from './screens/signTx'
import {SummaryScreen} from './screens/summary'
import type {SummaryValues} from './screens/summary/schema'
import {SwapResultScreen} from './screens/swapResult'
import {getInitialSwapAssets, useExchangeScreenState} from './utils'

type SubScreens = {
  SwapResult: React.FC<{
    onClose: () => void
    onBack: () => void
    onReset: () => void
    createSwapMutation: ReturnType<
      ExchangeModalViewModel['useExchangeCreateSwap']
    >
    saveSwapMutation: ReturnType<ExchangeModalViewModel['useSaveSwap']>
  }>
}

// Search for EXCHANGE REFACTOR to finish the first phase or
// exchange refactor.

type ExchangeModalProps = {
  onClose: () => unknown
}

export const ExchangeModal = ({onClose}: ExchangeModalProps) => (
  <_ExchangeModal
    {...{onClose}}
    exchangeConf={EXCHANGE_CONFIG}
    vm={exchangeModalViewModel}
    subScreens={{
      SwapResult: SwapResultScreen,
    }}
  />
)

export type ExchangeModalWithConfigProps = {
  exchangeConf: ExchangeDepsConfig
  vm: ExchangeModalViewModel
  subScreens: SubScreens
} & ExchangeModalProps

/**
 * This component behaves as a 'controller' for the whole exchange flow.
 * Its responsibilities are navigating between screens which are responsible for
 * handling various steps of the exchange flow.
 *
 * This component does not manage any form nor any form validation. Validation
 * and form management are responsibilities of the underlying screens. These screens
 * can however save their data into this component state once the data are successfully
 * submitted and validated, so that the data can be passed to another screens which
 * needs them resp. can be passed as default values to individuals screens
 * when user navigates back.
 *
 * This component is blockchain logic agnostic, meaning that it does not contain any blockchain
 * specific logic. All the blockchain specific logic should be passed via "exchangeConf"
 * property. This makes the component easier to test and it should not require any changes
 * to its underlying logic when adding new blockchain except possibly for adding another
 * value into "exchangeConf" if we need to specialize some other part of the flow.
 *
 * If you needed to add new fields for some specific blockchain, you can wrap them into
 * <Field /> component from Formik and use the field level validation, thus making the parent
 * unaware of blockchain specific logic.
 *
 * Feel free to add/update/change the way how is the specific blockchain behavior injected
 * by the "exchangeConf" but always make sure that the blockchain specific logic is not contained
 * in this component nor in its screens (details / summary / submit etc).
 *
 * This component also provides `CustomMetadataContainer` wrapper which serves as a container
 * for any blockchain specific data that the blockchain specific components need to save
 * for subsequent interactions. This component is unaware of specific structure of this data
 * and only provides a "storage" mechanisms for these specialized components.
 */
export const _ExchangeModal = ({
  onClose,
  exchangeConf,
  vm,
  subScreens,
}: ExchangeModalWithConfigProps) => {
  const classes = useStyles()
  const {
    data: assetsDetails,
    isLoading: assetDetailsIsLoading,
    error: assetDetailsError,
  } = vm.useGetExchangeAssetsDetails()

  const isLoadingOrError =
    assetDetailsIsLoading || !assetsDetails || assetDetailsError

  return (
    <ExchangeConfContextProvider {...{exchangeConf}}>
      <CustomMetadataContainer>
        <ExchangeModalVMContextProvider {...{vm}}>
          <Modal onClose={onClose} variant="left" className={classes.modal}>
            {isLoadingOrError ? (
              <LoadingOrError isError={!!assetDetailsError} onClose={onClose} />
            ) : (
              <ExchangeModalContent
                exchangeAssets={assetsDetails}
                {...{onClose, assetsDetails, subScreens}}
              />
            )}
          </Modal>
        </ExchangeModalVMContextProvider>
      </CustomMetadataContainer>
    </ExchangeConfContextProvider>
  )
}

type ExchangeModalContentProps = {
  exchangeAssets: ExchangeAssetsDetails
  subScreens: SubScreens
} & ExchangeModalProps

function ExchangeModalContent({
  onClose,
  subScreens,
  exchangeAssets,
}: ExchangeModalContentProps) {
  const [combinedFormData, setCombinedFormData] = useState<{
    details: DetailsValues | null
    summary: SummaryValues | null
  }>({details: null, summary: null})
  const vm = useExchangeModalViewModel()
  const [activeScreen, setActiveScreen] = useExchangeScreenState()
  const createSwapMutation = vm.useExchangeCreateSwap()
  const saveSwapMutation = vm.useSaveSwap()

  const onCreateSwap = async (values: DetailsValues) => {
    const {
      fromAsset: from,
      toAsset: to,
      toAddress: address,
      amount,
      fromAddress: refundAddress,
      extraId,
    } = values
    const changellySwap = await createSwapMutation.mutateAsyncSilent({
      from,
      to,
      address,
      amountFrom: new BigNumber(amount),
      refundAddress: refundAddress || null,
      extraId,
    })
    if (changellySwap?.id) {
      await saveSwapMutation.mutateAsyncSilent({
        partnerSwapId: changellySwap.id,
        partner: 'changelly',
        nufiAddressFrom:
          values.fromAddressType === 'internal'
            ? values.fromAddress
            : undefined,
        nufiAddressTo:
          values.toAddressType === 'internal' ? values.toAddress : undefined,
      })
    }
  }

  const onSubmit = {
    details: (values: DetailsValues) => {
      setCombinedFormData((data) => ({...data, details: values}))
      setActiveScreen('summary')
    },
    summary: async () => {
      const activateSign = authorizeTxSubmission(() =>
        setActiveScreen('signTx'),
      )
      assert(combinedFormData.details != null)
      if (combinedFormData.details.fromAddressType === 'internal') {
        activateSign()
      } else {
        setActiveScreen('swapResult')
      }
      await onCreateSwap(combinedFormData.details)
    },
  }

  switch (activeScreen) {
    case 'details':
      return (
        // Note that `WithGetInitializationData` is a responsibility of `ExchangeModal` because
        // it is responsible for passing either stored or fresh initial data and the `WithGetInitializationData`
        // logic/responsibility is connected to it.
        <WithGetInitializationData {...{exchangeAssets, onClose}}>
          {(initialInitializationData) => (
            <DetailsScreen
              onSubmit={onSubmit.details}
              initializationData={
                combinedFormData.details == null
                  ? {initialData: initialInitializationData}
                  : {previousData: combinedFormData.details}
              }
              exchangeAssetsDetails={exchangeAssets}
              {...{
                onClose,
                vm,
              }}
            />
          )}
        </WithGetInitializationData>
      )
    case 'summary': {
      assert(combinedFormData.details != null)
      return (
        <SummaryScreen
          prevScreensData={combinedFormData.details}
          initialData={combinedFormData.summary}
          onSubmit={onSubmit.summary}
          onBack={() => setActiveScreen('details')}
          exchangeAssetsDetails={exchangeAssets}
          {...{onClose, vm}}
        />
      )
    }
    case 'signTx': {
      assert(combinedFormData.details != null)
      return (
        <SignTxScreen
          onBack={() => setActiveScreen('details')}
          onSuccess={() => setActiveScreen('swapResult')}
          formData={combinedFormData.details}
          exchangeAssetsDetails={exchangeAssets}
          {...{createSwapMutation, saveSwapMutation, onClose, vm}}
        />
      )
    }
    case 'swapResult':
      return (
        <subScreens.SwapResult
          onBack={() => setActiveScreen('details')}
          onReset={() => {
            setActiveScreen('details')
          }}
          {...{onClose, createSwapMutation, saveSwapMutation}}
        />
      )
    default:
      return safeAssertUnreachable(activeScreen)
  }
}

type WithGetInitializationDataProps = {
  children: (injected: InitialInitializationData) => React.ReactElement
  onClose: () => void
  exchangeAssets: ExchangeAssetsDetails
}

const WithGetInitializationData = ({
  children,
  onClose,
  exchangeAssets,
}: WithGetInitializationDataProps) => {
  const vm = useExchangeModalViewModel()
  const {blockchain: _internalBlockchain, accountId: initialAccountId} =
    useBaseActionRouteOptions()
  const tokenId = useTokenIdFromRoute()
  const internalBlockchain = _internalBlockchain as ExchangeBlockchain

  const getAccountsQuery = vm.useGetAccounts(internalBlockchain)

  return (
    <WithTokenMetadataQuery blockchain={internalBlockchain} tokenId={tokenId}>
      {({tokenMetadataQuery}) => (
        <BatchQueryGuard
          queries={{
            accounts: getAccountsQuery,
            tokenMetadata: tokenMetadataQuery,
          }}
          LoadingElement={<LoadingOrError isError={false} {...{onClose}} />}
          ErrorElement={<LoadingOrError isError {...{onClose}} />}
        >
          {({tokenMetadata, accounts}) => {
            if (!accounts) return null

            const toAsset = ((): SwapAsset => {
              if (tokenMetadata == null)
                return blockchainToSwapAsset(internalBlockchain)

              if (
                isEvmBlockchain(internalBlockchain) ||
                internalBlockchain === 'solana'
              ) {
                const targetSwapAsset = findMatchingTokenSwapAsset({
                  exchangeAssets,
                  tokenMetadata,
                })
                return (
                  (targetSwapAsset?.ticker &&
                    // EXCHANGE_REFACTOR
                    // Forcing to call `formatSwapAssetTicker` is weird and fragile.
                    formatSwapAssetTicker(targetSwapAsset?.ticker)) ||
                  blockchainToSwapAsset(internalBlockchain)
                )
              }

              // For other blockchains we can not reliably check that its "ticker" field
              // matches some changelly token and it is not a scam. Therefore we just fallback
              // to the blockchain asset. We are aware that this does not constitute the best UX
              // but we do so for security concerns.
              return blockchainToSwapAsset(internalBlockchain)
            })()

            const {_toAddress} = (() => {
              const defaultAccountId = getDefaultAccountId(
                accounts.filter((a) => a.blockchain === internalBlockchain),
              )
              const targetAccountId = initialAccountId || defaultAccountId
              return {
                _toAddress: targetAccountId
                  ? findAccountById(accounts, targetAccountId)?.address || ''
                  : '',
              }
            })()

            const {initialFromAsset, initialToAsset} = getInitialSwapAssets({
              exchangeAssetsDetails: exchangeAssets,
              preferredFromAsset: 'BTC' as SwapAsset,
              preferredToAsset: toAsset,
              toAssetFallbackBlockchain: internalBlockchain,
            })

            // As the preferred asset can change in `getInitialSwapAssets` if not supported by
            // changelly, we reset accountId and address if the blockchain of this asset has changed.
            const wasToAssetBlockchainChanged =
              internalBlockchain !== initialToAsset.detail.blockchain

            return children({
              toAsset: initialToAsset.swapAsset,
              fromAsset: initialFromAsset.swapAsset,
              toAddress: wasToAssetBlockchainChanged ? '' : _toAddress,
            })
          }}
        </BatchQueryGuard>
      )}
    </WithTokenMetadataQuery>
  )
}

const WithTokenMetadataQuery = ({
  children,
  blockchain,
  tokenId,
}: {
  children: (props: {
    tokenMetadataQuery:
      | ReturnType<UseGetTokenMetadata>
      | {data: null; isLoading: false; error: undefined}
  }) => React.ReactElement
  blockchain: ExchangeBlockchain
  tokenId?: TokenId
}) => {
  if (tokenId == null) {
    return <_WithDummyTokenMetadataQuery {...{children}} />
  }

  return <_WithRealTokenMetadataQuery {...{children, tokenId, blockchain}} />
}

const _WithDummyTokenMetadataQuery = ({
  children,
}: {
  children: (props: {
    tokenMetadataQuery: {data: null; isLoading: false; error: undefined}
  }) => React.ReactElement
}) => {
  return children({
    // Needed to pass QueryGuard in case we do not handle token
    tokenMetadataQuery: {data: null, isLoading: false, error: undefined},
  })
}

const _WithRealTokenMetadataQuery = ({
  children,
  tokenId,
  blockchain,
}: {
  children: (props: {
    tokenMetadataQuery: ReturnType<UseGetTokenMetadata>
  }) => React.ReactElement
  tokenId: TokenId
  blockchain: ExchangeBlockchain
}) => {
  const vm = useExchangeModalViewModel()
  const tokenMetadataQuery = vm.useGetTokenMetadata(tokenId, blockchain)

  return children({
    tokenMetadataQuery,
  })
}

const useStyles = makeStyles(() => ({
  modal: {
    width: EXCHANGE_MODAL_WIDTH,
  },
}))
