import type {EvmBlockchain} from '@nufi/wallet-evm'
import type {OpStackBlockchain} from '@nufi/wallet-evm/dist/chains/opStack/blockchainTypes'
import type {UseQueryResult} from '@tanstack/react-query'
import BigNumber from 'bignumber.js'
import React from 'react'

import {QueryGuard, TransactionModalError} from 'src/components'
import type {AccountId} from 'src/types'
import {useLastDefinedData} from 'src/utils/query-utils'
import type {EvmTokenMetadata, EvmWei} from 'src/wallet/evm'
import {useGetTokenMetadata} from 'src/wallet/evm'
import {
  cachedGetL1Fee,
  useGetL1Fee,
  useGetL1FeeFromUnsignedTx,
} from 'src/wallet/evm/chains/opStack/public'
import {calculateLondonFee, nativeToWei} from 'src/wallet/evm/sdk/utils'
import type {EvmTokenId, EvmUnsignedTransaction} from 'src/wallet/evm/types'

import {inferMaxFeePerGasFromForm as inferLondonMaxFeePerGasFromForm} from '../gas/LondonGasOptions'
import type {LondonGasSchema} from '../gas/schema'
import type {GasSchema} from '../gas/types'

import type {FeeModelData, WithFeeModelDataProps} from './WithFeeModelData'

export type OpStackFeeModelCustomOptions =
  | {
      accountId: AccountId
      tokenId?: EvmTokenId
    }
  | {
      unsignedTx: EvmUnsignedTransaction
    }

export type OpStackFeeModelExchangeCustomOptions<
  TBlockchain extends EvmBlockchain,
> = {
  accountId: AccountId
  tokenMetadata: EvmTokenMetadata<TBlockchain> | null
}

export const getTxFeeFunction =
  <TBlockchain extends OpStackBlockchain>(l1Fee: EvmWei<TBlockchain>) =>
  (values: GasSchema) => {
    const l2Fee = calculateLondonFee<TBlockchain>({
      maxFeePerGas: inferLondonMaxFeePerGasFromForm(values as LondonGasSchema),
      gasLimit: new BigNumber(values.gasLimit) as EvmWei<TBlockchain>,
    })
    return l2Fee.plus(l1Fee) as EvmWei<TBlockchain>
  }

export const getOpStackFeeModelData = async <
  TBlockchain extends OpStackBlockchain,
>(
  blockchain: TBlockchain,
  customOpStackFeeModelOptions: OpStackFeeModelExchangeCustomOptions<TBlockchain>,
): Promise<FeeModelData<TBlockchain>> => {
  const {accountId, tokenMetadata} = customOpStackFeeModelOptions
  const l1Fee = await cachedGetL1Fee({
    blockchain,
    accountId,
    tokenMetadata,
  })
  return {
    getTxFee: getTxFeeFunction(l1Fee),
  }
}

export const WithOpStackFeeModelData = <TBlockchain extends OpStackBlockchain>({
  blockchain,
  customOpStackFeeModelOptions,
  ...restProps
}: Omit<WithFeeModelDataProps<TBlockchain>, 'feeModelType'>) => {
  if ('unsignedTx' in customOpStackFeeModelOptions) {
    return (
      <WithUnsignedTxL1Fee
        {...{
          blockchain,
          ...restProps,
          unsignedTx: customOpStackFeeModelOptions.unsignedTx,
        }}
      />
    )
  }

  const {tokenId, accountId} = customOpStackFeeModelOptions
  if (tokenId) {
    return (
      <WithTokenL1Fee
        {...{
          blockchain,
          ...restProps,
          tokenId: tokenId as EvmTokenId<TBlockchain>,
          accountId,
        }}
      />
    )
  }

  return (
    <WithL1Fee
      {...{
        blockchain,
        ...restProps,
        tokenMetadata: null,
        accountId,
      }}
    />
  )
}

type WithL1FeeBaseProps<TBlockchain extends OpStackBlockchain> = Omit<
  WithFeeModelDataProps<TBlockchain>,
  'customOpStackFeeModelOptions' | 'feeModelType'
>

const WithUnsignedTxL1Fee = <TBlockchain extends OpStackBlockchain>({
  blockchain,
  disableQuery,
  unsignedTx,
  ...restProps
}: WithL1FeeBaseProps<TBlockchain> & {
  unsignedTx: EvmUnsignedTransaction
}) => {
  const {l1FeeQuery} = useLastDefinedData({
    l1FeeQuery: useGetL1FeeFromUnsignedTx({
      blockchain,
      unsignedTx,
      enabled: !disableQuery,
    }),
  })
  return _withOpStackFeeModelData({
    l1FeeQuery,
    disableQuery,
    ...restProps,
  })
}

const WithTokenL1Fee = <TBlockchain extends OpStackBlockchain>({
  blockchain,
  disableQuery,
  tokenId,
  ...restProps
}: WithL1FeeBaseProps<TBlockchain> & {
  accountId: AccountId
  tokenId: EvmTokenId<TBlockchain>
}) => {
  const {tokenMetadataQuery} = useLastDefinedData({
    tokenMetadataQuery: useGetTokenMetadata(blockchain, tokenId, !disableQuery),
  })
  return (
    <QueryGuard
      {...tokenMetadataQuery}
      ErrorElement={<TransactionModalError />}
      loadingVariant="centered"
    >
      {(tokenMetadata) => {
        return (
          <WithL1Fee
            {...{
              blockchain,
              ...restProps,
              tokenMetadata,
            }}
          />
        )
      }}
    </QueryGuard>
  )
}

const WithL1Fee = <TBlockchain extends OpStackBlockchain>({
  blockchain,
  disableQuery,
  tokenMetadata,
  accountId,
  ...restProps
}: WithL1FeeBaseProps<TBlockchain> & {
  tokenMetadata: EvmTokenMetadata<TBlockchain> | null
  accountId: AccountId
}) => {
  const {l1FeeQuery} = useLastDefinedData({
    l1FeeQuery: useGetL1Fee({
      blockchain,
      tokenMetadata,
      accountId,
      enabled: !disableQuery,
    }),
  })
  return _withOpStackFeeModelData({
    l1FeeQuery,
    disableQuery,
    ...restProps,
  })
}

const _withOpStackFeeModelData = <TBlockchain extends OpStackBlockchain>({
  children,
  l1FeeQuery,
}: Omit<WithL1FeeBaseProps<TBlockchain>, 'blockchain'> & {
  l1FeeQuery: UseQueryResult<EvmWei<TBlockchain>, unknown>
}) => {
  // getting the l1Fee takes a while which slows the loading of the gasOptions significantly
  // for this reason we as static value, taken from couple of measurement which is supposed to be a bit
  // higher then the usual l1Fee, as the default value
  // worst case scenario, the transaction fails with insufficient balance, but only if
  // the l1Fee took seconds to fetch & user managed to click through the sendModal
  // in that period of time & also he decided to send MAX and the static fallback value was not enough
  // we also do not use QueryGuard here, as it would show error screen in case l1FeeQuery failed
  // which is arguable worse UX then just using the default value
  const initialStaticL1GasCostEstimate = nativeToWei<TBlockchain>('0.00004')
  const l1Fee = l1FeeQuery.data || initialStaticL1GasCostEstimate
  return children({getTxFee: getTxFeeFunction(l1Fee)})
}
