import type {Blockchain} from '../types'

import {_csvExportBlockchains, exchangeBlockchains} from './blockchainArrays'
import type {ExchangeBlockchain} from './blockchainTypes'
export * from './blockchainArrays'
export * from './blockchainTypes'

// Export dynamic types from here: (e.g. types that access `config` for tmp
// disabled features).
// The reason is to keep `blockchainTypes.ts` app-imports free, otherwise we encounter
// issues when transpiling files for usage with i18next-next scanner.

export const csvExportBlockchains = _csvExportBlockchains

export type CsvExportBlockchain = Extract<
  Blockchain,
  (typeof csvExportBlockchains)[number]
>

export const isExchangeBlockchain = (
  blockchain?: string | null,
): blockchain is ExchangeBlockchain => {
  return exchangeBlockchains.includes(blockchain as ExchangeBlockchain)
}
