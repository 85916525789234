import type {SelectChangeEvent} from '@mui/material'
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material'
import {assert} from '@nufi/frontend-common'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {
  ActionButtons,
  RejectButton,
} from 'src/dappConnector/components/buttonUtils'
import {usePlatformConfig} from 'src/dappConnector/PlatformConfig'
import {isBlockchainEnabled} from 'src/features/profile/application'
import {EnableBlockchain} from 'src/features/walletStorage/ui'
import {useEvmStore} from 'src/store/wallet'

import {Alert, BlockchainIcon, Modal} from '../../../../../components'
import {useDappConnectorStore} from '../../../../../store/dappConnector'
import {assertBlockchainIsInSubset} from '../../../../../utils/blockchainGuards'
import {useGetBlockchainName} from '../../../../../utils/translations'
import type {AccountOfflineInfo} from '../../../../../wallet'
import type {
  EvmAccountOfflineInfo,
  EvmAccountStoredData,
  EvmBlockchain,
  EvmChainId,
} from '../../../../../wallet/evm'
import {
  evmBlockchains,
  getAvailableEvmBlockchains,
} from '../../../../../wallet/evm'
import {getEvmManager} from '../../../../../wallet/evm/evmManagers'

import {
  allowedChainIds as _allowedChainIds,
  emitChainChanged as _emitChainChanged,
  ChangeNetworkHeader,
  NETWORKS_CONF,
} from './common'

type EvmBlockchainSelectProps = {
  label?: string
}

export function EvmBlockchainSelect({label}: EvmBlockchainSelectProps) {
  return (
    <EvmBlockchainSelectWrapper
      {...{
        label,
        getAccountOfflineInfo: (blockchain, account) =>
          getEvmManager(blockchain).accountManager.getAccountOfflineInfo(
            account,
          ),
        emitChainChanged: _emitChainChanged,
        allowedChainIds: _allowedChainIds,
      }}
    />
  )
}

export type EvmBlockchainSelectWrapperProps = {
  label?: string
  // Passing side-effect-like props from outside
  // to use this component easily inside storybook
  allowedChainIds: number[]
  emitChainChanged: (chainId: number) => void
  getAccountOfflineInfo: <T extends EvmBlockchain>(
    blockchain: T,
    account: EvmAccountStoredData,
  ) => EvmAccountOfflineInfo<T>
}

export function EvmBlockchainSelectWrapper({
  allowedChainIds,
  label,
  emitChainChanged,
  getAccountOfflineInfo,
}: EvmBlockchainSelectWrapperProps) {
  const {t} = useTranslation()

  const storedEvmAccounts = useEvmStore().accounts || []

  const getBlockchainName = useGetBlockchainName()
  const {
    blockchain: evmBlockchain,
    setBlockchain: _setBlockchain,
    changeEvmChain,
    selectedAccount,
  } = useDappConnectorStore()
  const {lastAccountIdHandlers} = usePlatformConfig()

  assertBlockchainIsInSubset(evmBlockchain, evmBlockchains)

  const [blockchainCandidateInfo, setBlockchainCandidateInfo] = useState<{
    blockchain: EvmBlockchain
    chainId: number
  } | null>(null)

  const changeBlockchain = (evmBlockchain: EvmBlockchain, chainId: number) => {
    _setBlockchain(evmBlockchain)
    emitChainChanged(chainId)
  }

  const changeBlockchainAndAccount = async (
    evmBlockchain: EvmBlockchain,
    chainId: number,
    account: AccountOfflineInfo,
  ) => {
    await lastAccountIdHandlers?.updateLastAccountId(evmBlockchain, account.id)
    changeEvmChain({blockchain: evmBlockchain, account})
    emitChainChanged(chainId)
  }

  const onBlockchainChange = (_evmBlockchain: EvmBlockchain) => {
    const chainId = parseInt(
      (Object.entries(NETWORKS_CONF).find(
        ([key, value]) =>
          allowedChainIds.includes(parseInt(key, 10) as EvmChainId) &&
          value.blockchain === _evmBlockchain,
      ) || [])[0] as string,
      10,
    )

    // If user has not chosen any account yet, all we change is blockchain
    if (selectedAccount == null) {
      changeBlockchain(_evmBlockchain, chainId)
      return
    }

    // If blockchain is not enabled, we just set the candidate info
    // so we can display the EnableBlockchain modal.
    if (!isBlockchainEnabled(_evmBlockchain)) {
      setBlockchainCandidateInfo({blockchain: _evmBlockchain, chainId})
      return
    }

    assert(selectedAccount.type === 'evm')
    const storedAccount = storedEvmAccounts.find(
      (a) => a.id === selectedAccount.id,
    )
    assert(storedAccount != null)

    const targetAccountInfo = getAccountOfflineInfo(
      _evmBlockchain,
      storedAccount,
    )
    changeBlockchainAndAccount(_evmBlockchain, chainId, targetAccountInfo)
  }

  if (blockchainCandidateInfo != null) {
    const candidateBlockchain = blockchainCandidateInfo.blockchain
    const onClose = () => setBlockchainCandidateInfo(null)
    return (
      <Modal onClose={onClose} variant="full-width">
        <Box sx={{p: 2, maxWidth: 450, margin: '0 auto', alignSelf: 'center'}}>
          <ChangeNetworkHeader />
          <Alert severity="info">
            <Typography textAlign="left">
              {t('blockchain_not_activated_long', {
                blockchain: getBlockchainName(candidateBlockchain),
              })}
            </Typography>
          </Alert>
          <Box mt={2} />
          <ActionButtons
            leftButton={<RejectButton title={t('Close')} onClick={onClose} />}
            rightButton={
              <EnableBlockchain
                blockchain={candidateBlockchain}
                fullWidth
                variant="contained"
                onBlockchainEnabled={() => {
                  onBlockchainChange(candidateBlockchain)
                  onClose()
                }}
              />
            }
          />
        </Box>
      </Modal>
    )
  }

  return (
    <FormControl variant="outlined">
      {label && <InputLabel>{label}</InputLabel>}
      <Select
        value={evmBlockchain}
        onChange={(e: SelectChangeEvent) =>
          onBlockchainChange(e.target.value as EvmBlockchain)
        }
        label={label}
        sx={{minWidth: 200}}
      >
        {getAvailableEvmBlockchains().map((blockchain) => (
          <MenuItem key={blockchain} value={blockchain}>
            <Box display="flex">
              <BlockchainIcon
                blockchain={blockchain}
                exactSize={24}
                sx={{mr: 1.5}}
              />
              {getBlockchainName(blockchain)}
            </Box>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
