import {Box, Typography, styled} from '@mui/material'
import {assert} from '@nufi/frontend-common'
import React, {useEffect} from 'react'
import {Trans, useTranslation} from 'react-i18next'

import {Alert} from 'src/components'
import {ExternalSellButton} from 'src/pages/buySell/common'
import {colors} from 'src/theme/palette'
import {spread} from 'src/utils/reactHelpers'
import {useGetAccounts} from 'src/wallet'

import img from '../../../../assets/images/moonpayLogo.png'
import {
  useMoonpayBlockchainTxId,
  useMoonpayAssets,
  getMoonpaySellUrl,
} from '../../application'
import {RampOperation} from '../../domain'
import type {RampFlowProps} from '../rampFlow/RampFlow'
import {RampFlow} from '../rampFlow/RampFlow'
import type {RampFlowViewModel} from '../rampFlow/state/viewModel'

import {MoonpayFlowBuyer, importMoonpayBuyWidget} from './MoonpayFlowBuyer'
import {MoonpayFlowSignerAndSeller} from './MoonpayFlowSignerAndSeller'

export const moonpayFlowViewModel: RampFlowViewModel = {
  useGetAccounts,
  useRampBlockchainTxId: useMoonpayBlockchainTxId,
  useRampAssets: useMoonpayAssets,
}

export type MoonpayFlowProps = Omit<
  RampFlowProps,
  | 'vm'
  | `render${string}Widget`
  | 'getIsOperationSupportedInApp'
  | 'renderOperationNotSupportedContent'
>

export const MoonpayFlow = (props: MoonpayFlowProps) => {
  useEffect(() => {
    importMoonpayBuyWidget() // prefetch moonpay code
  }, [])

  return (
    <RampFlow
      vm={moonpayFlowViewModel}
      renderBuyWidget={(props) => (
        <MoonpayFlowBuyer {...spread(props, MoonpayFlowBuyer)} />
      )}
      renderSellWidget={(props) => (
        <MoonpayFlowSignerAndSeller
          {...spread(props, MoonpayFlowSignerAndSeller)}
        />
      )}
      getIsOperationSupportedInApp={(operation, asset) =>
        operation === RampOperation.BUY ||
        (operation === RampOperation.SELL &&
          asset.blockchain === 'cardano' &&
          asset.tokenId == null)
      }
      // Note that alternative to this approach is letting user choose the
      // account, construct signed url and pass it to moonpay. The
      // current approach was chosen as it is simpler, and could be
      // easily changed in the future.
      renderOperationNotSupportedContent={(operation, asset) => {
        assert(operation === RampOperation.SELL)
        const moonpayUrl = getMoonpaySellUrl({
          baseCurrencyCode: asset.rampId,
        })
        return <ExternalSellContent redirectUrl={moonpayUrl} />
      }}
      {...spread(props, RampFlow)}
    />
  )
}

const ExternalSellContent = ({redirectUrl}: {redirectUrl: string}) => {
  const {t} = useTranslation()

  return (
    <ExternalSellContentWrapper>
      <Alert severity="info" text={t('moonpay_sell_redirect_alert')} />
      <InfoText>
        <Trans
          i18nKey="moonpay_sell_redirect_info"
          t={t}
          components={{
            bold: (
              <Box
                component="span"
                style={{wordBreak: 'break-all'}}
                fontWeight="fontWeightMedium"
              />
            ),
          }}
        />
      </InfoText>
      <MoonpayImgWrapper>
        <img src={img} width="60%" />
      </MoonpayImgWrapper>
      <Box sx={{paddingLeft: 5, paddingRight: 5, width: '100%'}}>
        <ExternalSellButton redirectUrl={redirectUrl} />
      </Box>
    </ExternalSellContentWrapper>
  )
}

const ExternalSellContentWrapper = styled('div')(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100%',
  padding: theme.spacing(2),
}))

const InfoText = styled(Typography)({
  paddingLeft: '50px',
  paddingRight: '16px',
  marginTop: '20px',
  color: colors.info.alertContent,
})

const MoonpayImgWrapper = styled('div')(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingBottom: theme.spacing(4),
  paddingTop: theme.spacing(4),
}))
