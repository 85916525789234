import {API_VERSION} from '@nufi/dapp-client-cardano'
import {set} from '@nufi/dapp-client-core'
import {Sentry, assert} from '@nufi/frontend-common'
import type {CardanoSignedTxBytes} from '@nufi/wallet-cardano'
import {useEffect, useState} from 'react'

import {createApi} from 'src/dappConnector/api/cardano'
import type {API as CardanoDappConnectorApi} from 'src/dappConnector/api/cardano/types'
import {dappConnectorsConfig} from 'src/dappConnector/config'
import {txStoreActions} from 'src/features/txSubmission/cardano/application'
import type {SignScreenInfo, SigningKind} from 'src/store/dappConnector'
import {invalidateQueryKeys} from 'src/utils/mutation-utils'
import {
  accountsInvalidationKeys,
  getRawTxHash,
  getCardano,
} from 'src/wallet/cardano'
import type {CardanoAccountOfflineInfo} from 'src/wallet/cardano'

import {dexHunterQueryKeys} from './queryKeys'

export type DexHunterAccountConnector = CardanoDappConnectorApi

export const useInjectAppCardanoWalletConnector = (
  account: CardanoAccountOfflineInfo | null,
  setSignScreenContext: (c: SignScreenContext | null) => void,
): DexHunterAccountConnector | null => {
  type InjectionData = {
    account: CardanoAccountOfflineInfo
    accountConnector: DexHunterAccountConnector
  }

  const [injectionData, setInjectionData] = useState<InjectionData | null>(null)

  useEffect(() => {
    const fn = async () => {
      if (account) {
        const connector = getDexHunterConnector(account, setSignScreenContext)
        const accountConnector = await connector.enable()
        set(window, ['cardano', 'nufi'], connector)
        setInjectionData({account, accountConnector})
      }
    }
    fn()
  }, [account])

  return injectionData?.account.id && injectionData.account.id === account?.id
    ? injectionData.accountConnector
    : null
}

export type SignScreenContext = SignScreenInfo<SigningKind>

type DexHunterConnector = {
  name: string
  icon: string
  apiVersion: string
  enable: () => Promise<DexHunterAccountConnector>
  isEnabled: () => Promise<boolean>
}

const getDexHunterConnector = (
  selectedAccount: CardanoAccountOfflineInfo | null,
  setSignScreenContext: (c: SignScreenContext | null) => void,
): DexHunterConnector => ({
  name: dappConnectorsConfig.name,
  icon: dappConnectorsConfig.icons.default,
  apiVersion: API_VERSION,
  enable: () => {
    const cardanoApi = createApi({
      connectorState: {
        getState: () => ({
          selectedAccount,
        }),
      },
      uiHandlers: {
        confirmSign: (kind, data, onSign, onFailure) =>
          new Promise((resolve, reject) => {
            setSignScreenContext({
              state: kind,
              data,
              onSign: async () => {
                const tx = await onSign()
                return {
                  onFinish: () => {
                    assert(
                      selectedAccount != null &&
                        data.type === 'cardano' &&
                        !!data.data &&
                        'txPlan' in data.data,
                    )
                    const txPlan = data.data.txPlan
                    txStoreActions.registerTxCandidateWithTxPlan(
                      selectedAccount.id,
                      txPlan,
                    )
                    setSignScreenContext(null)
                    return resolve(tx)
                  },
                }
              },
              onFailure: () => {
                setSignScreenContext(null)
                reject(onFailure())
              },
            })
          }),
        // We do not really expect collateral to be requested.
        requestCreateCardanoCollateral: async () => false,
      },
    })

    const submitTxWithSideEffects: typeof cardanoApi.submitTx = async (
      signedTx,
    ) => {
      const txHash = getRawTxHash(Buffer.from(signedTx, 'hex'))
      try {
        assert(selectedAccount != null)
        txStoreActions.pushTxCandidate(selectedAccount.id, txHash)

        const result = await cardanoApi.submitTx(signedTx)

        // No actual need to wait for this
        getCardano()
          .accountManager.confirmTransaction({
            bytes: Buffer.from(signedTx, 'hex') as CardanoSignedTxBytes,
            txHash,
          })
          .then(() => {
            invalidateQueryKeys(accountsInvalidationKeys)

            const accountAddress = selectedAccount?.address
            if (accountAddress) {
              invalidateQueryKeys([dexHunterQueryKeys.orders(accountAddress)])
            }
            txStoreActions.updatePendingTx(txHash, {type: 'data', data: result})
          })
          .catch((error) => {
            Sentry.captureException(error)
            txStoreActions.updatePendingTx(txHash, {type: 'error', error})
          })

        return result
      } catch (error) {
        txStoreActions.updatePendingTx(txHash, {type: 'error', error})
        throw error
      }
    }

    return Promise.resolve({
      ...cardanoApi,
      submitTx: submitTxWithSideEffects,
    })
  },
  isEnabled: () => Promise.resolve(true),
})
