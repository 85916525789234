/**
 * @deprecated Use safeAssertUnreachable instead
 */
export const assertUnreachable = (): never => {
  throw new Error('Unreachable switch case')
}

// https://stackoverflow.com/questions/39419170/how-do-i-check-that-a-switch-block-is-exhaustive-in-typescript
export const safeAssertUnreachable = (x: never): never => {
  throw new Error(`Unreachable switch case:${x}`)
}

export function assert(cond: boolean, errMsg?: string): asserts cond {
  if (!cond) throw new Error(`Assertion failed${errMsg ? `: ${errMsg}` : ''}`)
}
