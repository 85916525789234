import type {FlowAccountInfo, FlowTokenMetadata} from '@nufi/wallet-flow'
import React from 'react'
import {useTranslation} from 'react-i18next'

import type {ConversionRates} from 'src/features/conversionRates/domain'

import {FormattedAsset} from '../../../components'
import {getTotalStakingBalance, useGetAccountTokens} from '../../../wallet/flow'
import {AccountListCard} from '../account/accountListCards'
import {AccountItemLoading} from '../account/Loadings'

type FlowAccountCardProps = {
  accountInfo: FlowAccountInfo
  tokensMetadata: FlowTokenMetadata[]
  conversionRates?: ConversionRates
}

export function FlowAccountCard({accountInfo, ...rest}: FlowAccountCardProps) {
  const tokensQuery = useGetAccountTokens(accountInfo)
  const {t} = useTranslation()
  // we do not use QueryGuard here since we do not want to handle error state at this level
  if (tokensQuery.isLoading && !tokensQuery.error) {
    return <AccountItemLoading />
  }
  return (
    <AccountListCard
      {...{
        accountInfo,
        tokens: tokensQuery.data || [],
        tokensError: tokensQuery.error,
        ...rest,
      }}
      StakingCol={
        <FormattedAsset
          amount={getTotalStakingBalance(accountInfo.stakingInfo)}
          blockchain={'flow'}
          isSensitiveInformation
        />
      }
      stakingColText={t('Staking balance')}
    />
  )
}
