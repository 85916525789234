import {InfoOutlined as InfoIcon} from '@mui/icons-material'
import type {ButtonProps} from '@mui/material'
import {Grid, Box, Typography, styled} from '@mui/material'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {
  Button,
  Modal,
  ModalLayout,
  ModalHeader,
  ModalFooter,
  FooterLayout,
  MutationGuard,
} from 'src/components'
import {useEnableBlockchain} from 'src/features/walletStorage/application/enableBlockchain'
import type {Blockchain} from 'src/types'
import {useGetBlockchainName} from 'src/utils/translations'

export const EnableBlockchain = ({
  blockchain,
  buttonWidth,
  fullWidth,
  variant,
  startIcon,
  displayConfirmationModal,
  onBlockchainEnabled,
}: {
  blockchain: Blockchain
  buttonWidth?: string | number
  fullWidth?: boolean
  variant?: ButtonProps['variant']
  startIcon?: ButtonProps['startIcon']
  displayConfirmationModal?: boolean
  onBlockchainEnabled?: () => void
}) => {
  const {t} = useTranslation()
  const enableBlockchainMutation = useEnableBlockchain()

  const [isModalOpen, setIsModalOpen] = useState(false)

  const onModalClose = () => {
    setIsModalOpen(false)
  }

  const onEnableBlockchain = async () => {
    try {
      await enableBlockchainMutation.mutateAsync(blockchain)
    } catch (e) {
      return
    }
    onBlockchainEnabled?.()
    onModalClose()
  }

  const onButtonClick = () => {
    if (displayConfirmationModal) {
      setIsModalOpen(true)
    } else {
      onEnableBlockchain()
    }
  }

  return (
    <>
      <Button
        sx={{width: buttonWidth}}
        fullWidth={fullWidth}
        variant={variant || 'outlined'}
        color="primary"
        onClick={(e) => {
          e.stopPropagation()
          onButtonClick()
        }}
        startIcon={startIcon}
        textTransform="none"
        data-test-id="portfolio-enable-blockchain-button"
        data-enable-blockchain-blockchain={blockchain}
      >
        {t('Activate')}
      </Button>
      {isModalOpen && (
        // Wrap modal so that on click isn't propagated to parent since otherwise
        // clicking the modal's backdrop would invoke parent's `onClick`
        // function thus redirecting the user.
        <Box onClick={(e) => e.stopPropagation()}>
          <ConfirmEnableBlockchainModal
            blockchain={blockchain}
            onEnableBlockchain={onEnableBlockchain}
            enableBlockchainMutation={enableBlockchainMutation}
            onClose={onModalClose}
          />
        </Box>
      )}
    </>
  )
}

function ConfirmEnableBlockchainModal({
  blockchain,
  onEnableBlockchain,
  enableBlockchainMutation,
  onClose,
}: {
  blockchain: Blockchain
  onEnableBlockchain: () => void
  enableBlockchainMutation: ReturnType<typeof useEnableBlockchain>
  onClose: () => void
}) {
  const {t} = useTranslation()
  const getBlockchainName = useGetBlockchainName()

  return (
    <Modal variant="centered">
      <ModalLayout
        header={
          <ModalHeader hasDivider>
            <Grid container spacing={2} alignItems="flex-end">
              <InfoIconStyledGrid item>
                <InfoIcon color="inherit" />
              </InfoIconStyledGrid>
              <Grid item>
                <Typography variant="h6">
                  {t('ConfirmEnableBlockchainModal.title', {
                    blockchain: getBlockchainName(blockchain),
                  })}
                </Typography>
              </Grid>
            </Grid>
          </ModalHeader>
        }
        body={
          <Box maxWidth={560} p={3}>
            <Typography>
              {t('ConfirmEnableBlockchainModal.description', {
                blockchain: getBlockchainName(blockchain),
              })}
            </Typography>
            <MutationGuard {...enableBlockchainMutation} />
          </Box>
        }
        footer={
          <ModalFooter hasDivider>
            <FooterLayout
              leftBtnConf={{
                children: t('Cancel'),
                onClick: (e) => {
                  e.stopPropagation()
                  onClose()
                },
              }}
              rightBtnConf={{
                children: t('Activate'),
                onClick: (e) => {
                  e.stopPropagation()
                  onEnableBlockchain()
                },
              }}
            />
          </ModalFooter>
        }
      />
    </Modal>
  )
}

const InfoIconStyledGrid = styled(Grid)(({theme}) => ({
  color: theme.palette.info.main,
}))
