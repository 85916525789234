import {Sentry, HttpRequestError} from '@nufi/frontend-common'
import type {StoreContentHeader, StoreManagerContent} from '@nufi/storage'
import type {SyncCounter} from 'common'
import _ from 'lodash'

import {StorageError} from 'src/appStorage/errors'

import type {ProfileData} from '../../../../appStorage'
import type {MasterProfileManager} from '../../../../appStorage/masterProfileManager'
import type {ProfileManager} from '../../../../appStorage/profileManager'
import {existingBlockchains} from '../../../../blockchainTypes'
import {assert} from '../../../../utils/assertion'
import {sleep} from '../../../../utils/helpers'
import type {
  ProfileAccount,
  StandardProfileAccount,
} from '../../../../wallet/types'
import type {RemoteProfileStorage} from '../remoteProfileStorage'

import {mergeProfileData} from './merge'

export {mergeProfileData} from './merge'

type SyncingStoreManagerContent = {
  profileContent: StoreManagerContent<ProfileData>
  syncCounter: SyncCounter
}

type CloudSyncDeps = {
  masterProfileManager: MasterProfileManager
  profileManager: ProfileManager
  remoteProfileStorage: RemoteProfileStorage
}

type SyncResult =
  | {
      status: 'success'
      mergedContent?: undefined
      appStateReloaded: boolean
    }
  | {status: 'paused'; mergedContent?: undefined}
  | {
      status: 'out-of-sync'
      mergedContent: SyncingStoreManagerContent
    }
  | {status: 'failed'; mergedContent?: undefined}

const validateHeaders = (
  localHeader: StoreContentHeader,
  cloudHeader: StoreContentHeader,
) => {
  const localHeaderRest = _.omit(localHeader, ['appVersion', 'schemaVersion'])
  const cloudHeaderRest = _.omit(cloudHeader, ['appVersion', 'schemaVersion'])

  if (!_.isEqual(localHeaderRest, cloudHeaderRest)) {
    throw Error('Incompatible local and cloud data header')
  }
}

export const ensureAllBlockchainsSupported = (accounts: ProfileAccount[]) => {
  const accountsBlockchains = _.uniq(
    accounts
      // no need to check for evm accounts since they are implicitly supported
      .filter(
        (account): account is StandardProfileAccount =>
          account.type === 'standard',
      )
      .map((account) => account.blockchain)
      .flat(),
  )

  const unsupportedAccountBlockchains = accountsBlockchains.filter(
    (b) => !existingBlockchains.includes(b),
  )

  // For now, if we have unsupported blockchains, abort sync
  if (unsupportedAccountBlockchains.length) {
    // TODO: Refer to cloud sync spec for how to handle new blockchains in the future (if needed)
    // https://docs.google.com/document/d/13X-H3qgLlfAwKn7-Bb_qLaJrzyjM5b9adupScgN8AyU/edit#heading=h.qyqj63tsfsad
    throw Error(
      `Profile contains accounts with unsupported blockchains, ${JSON.stringify(
        unsupportedAccountBlockchains,
      )}`,
    )
  }
}

export type CloudSyncServiceFactoryParams = {
  deps: CloudSyncDeps
  reloadAppState: () => Promise<void>
  onSuccess: () => void
  onFailure: () => void
  onDisabled: () => void
  onPaused: () => void
  isEnabled?: boolean
}

export function cloudSyncServiceFactory({
  deps,
  reloadAppState,
  onSuccess,
  onFailure,
  onDisabled,
  onPaused,
  isEnabled = true,
}: CloudSyncServiceFactoryParams) {
  const updateCounter = async (
    syncCounter: SyncingStoreManagerContent['syncCounter'],
  ) => await deps.masterProfileManager.setCloudSyncData(syncCounter)

  const updateDependentStorages = async (
    syncedContent: SyncingStoreManagerContent,
  ) => {
    await updateCounter(syncedContent.syncCounter)
    await deps.profileManager.saveData(syncedContent.profileContent.data)

    await reloadAppState()
  }

  const shouldSync = async (
    localProfileContent: StoreManagerContent<ProfileData>,
  ) => {
    const cloudSyncBackup = await deps.remoteProfileStorage.getProfile()
    if (cloudSyncBackup == null) {
      return true
    }

    const cloudProfileContent =
      await deps.profileManager.decryptSerializedProfile(
        cloudSyncBackup.serializedProfile,
      )

    return !_.isEqual(localProfileContent, cloudProfileContent)
  }

  const trySyncByMerging = async (
    contentToSync: SyncingStoreManagerContent,
  ): Promise<SyncResult> => {
    // Do not catch failures (no connect, auth failed, no profile), let it boil up
    const cloudSyncingProfile = await deps.remoteProfileStorage.getProfile()
    assert(
      cloudSyncingProfile != null,
      'We should not get here. Profile does not exist. Nothing to merge.',
    )

    const cloudContent = {
      profileContent: await deps.profileManager.decryptSerializedProfile(
        cloudSyncingProfile.serializedProfile,
      ),
      syncCounter: cloudSyncingProfile.syncCounter,
    }

    validateHeaders(
      contentToSync.profileContent.header,
      cloudContent.profileContent.header,
    )

    let migratedCloudProfileContent
    try {
      migratedCloudProfileContent = deps.profileManager.migrateProfileContent(
        cloudContent.profileContent,
      )
    } catch (e) {
      if (
        e instanceof Error &&
        e.message === StorageError.BACKUP_NEWER_THAN_APP
      ) {
        return {status: 'paused'}
      }

      throw e
    }

    ensureAllBlockchainsSupported(migratedCloudProfileContent.data.accounts)

    const mergedContent = {
      profileContent: {
        header: contentToSync.profileContent.header,
        data: mergeProfileData({
          localProfileData: contentToSync.profileContent.data,
          remoteProfileData: migratedCloudProfileContent.data,
        }),
      },
      syncCounter: cloudContent.syncCounter,
    }

    await updateDependentStorages(mergedContent)

    if (_.isEqual(mergedContent.profileContent, cloudContent.profileContent)) {
      return {status: 'success', appStateReloaded: true}
    }

    const mergedContentToSync = {
      ...mergedContent,
      syncCounter: (mergedContent.syncCounter + 1) as SyncCounter,
    }

    const mergedSerializedContentToSync = {
      serializedProfile: await deps.profileManager.encryptToSerializedProfile(
        mergedContentToSync.profileContent,
      ),
      syncCounter: mergedContentToSync.syncCounter,
    }

    try {
      await deps.remoteProfileStorage.postProfile(mergedSerializedContentToSync)
    } catch (e) {
      // Profile exists, but we have invalid counter - 409 -> resync
      if (e instanceof HttpRequestError && e.httpStatus === 409) {
        return {status: 'out-of-sync', mergedContent}
      }

      // On all other failures (no connect, auth failed, invalid request, no profile & invalid counter) -> rethrow
      throw e
    }

    await updateCounter(mergedContentToSync.syncCounter)

    return {status: 'success', appStateReloaded: true}
  }

  const trySyncByOverwriting = async (
    localContent: SyncingStoreManagerContent,
  ) => {
    if (!(await shouldSync(localContent.profileContent))) {
      return 'success'
    }

    const serializedContentToSync = {
      serializedProfile: await deps.profileManager.encryptToSerializedProfile(
        localContent.profileContent,
      ),
      syncCounter: localContent.syncCounter,
    }

    try {
      await deps.remoteProfileStorage.postProfile(serializedContentToSync)
    } catch (e) {
      // Profile exists, but we have invalid counter - 409 -> resync
      if (e instanceof HttpRequestError && e.httpStatus === 409) {
        return 'out-of-sync'
      }

      // On all other failures (no connect, auth failed, invalid request, no profile & invalid counter) -> rethrow
      throw e
    }

    await updateCounter(localContent.syncCounter)

    return 'success'
  }

  const syncProfileData = async (): Promise<SyncResult> => {
    if (!isEnabled) {
      onDisabled()
      return {status: 'paused'}
    }

    const storeContent = await deps.profileManager.getStoreContent()
    const currentSyncCounter =
      await deps.masterProfileManager.getCurrentSyncCounter()

    const localContent = {
      profileContent: storeContent,
      syncCounter: currentSyncCounter,
    }

    let syncResult
    let syncError

    let contentToSync = {
      ...localContent,
      syncCounter: (localContent.syncCounter + 1) as SyncCounter,
    }

    for (const delayMs of [0, 100, 1000, 2000]) {
      await sleep(delayMs)

      try {
        syncResult = await trySyncByOverwriting(contentToSync)
        break
      } catch (e) {
        syncResult = 'failed'
        syncError = e
      }
    }

    if (syncResult === 'failed') {
      onFailure()
      Sentry.captureException(syncError)
      return {status: 'failed'}
    } else if (syncResult === 'success') {
      onSuccess()
      return {status: 'success', appStateReloaded: false}
    }

    for (const delayMs of [0, 100, 1000, 2000]) {
      await sleep(delayMs)

      try {
        const syncResult = await trySyncByMerging(contentToSync)

        if (syncResult.status === 'success') {
          onSuccess()
          return syncResult
        } else if (syncResult.status === 'paused') {
          onPaused()
          return syncResult
        }

        contentToSync = syncResult.mergedContent as SyncingStoreManagerContent
      } catch (e) {
        syncResult = 'failed'
        syncError = e
      }
    }

    onFailure()
    Sentry.captureException(syncError)
    return {status: 'failed'}
  }

  return {syncProfileData}
}

export type CloudSyncService = ReturnType<typeof cloudSyncServiceFactory>
