import {Grid, Box, Typography} from '@mui/material'
import {DEFAULT_MNEMONIC_LENGTH} from '@nufi/wallet-common'
import clsx from 'clsx'
import {Formik} from 'formik'
import React from 'react'
import {Trans, useTranslation} from 'react-i18next'
import * as yup from 'yup'

import type {ProfileMetadata} from '../../appStorage'
import {getMnemonicHash} from '../../appStorage'
import {
  SideBarLayout,
  Button,
  NavigateBack,
  MnemonicCard,
  PlainTextSwitch,
  PlainTextInput,
  Alert,
} from '../../components'
import config from '../../config'
import {getHasFormError} from '../../utils/form'
import type {Mnemonic} from '../../wallet'
import {generateMnemonic} from '../../wallet'

import {
  useCommonProfileStyles,
  NavigateBackWrapper,
  CreateProfileTimeline,
  ButtonChoice,
} from './common'
import type {WalletType} from './types'

type RecoveryPhrasePageProps = {
  onBack: () => unknown
  onSkipMnemonicSetup: (recoveryPhrase: Mnemonic) => unknown
  onGoToMnemonicSetup: () => unknown
  walletType: WalletType
}

export function SkipRecoveryPhrasePage({
  onBack,
  onSkipMnemonicSetup,
  onGoToMnemonicSetup,
  walletType,
}: RecoveryPhrasePageProps) {
  const {t} = useTranslation()
  const commonClasses = useCommonProfileStyles()

  return (
    <SideBarLayout
      left={<CreateProfileTimeline step="recovery" {...{walletType}} />}
      right={
        <Box className={clsx(commonClasses.wrapper, commonClasses.spacing)}>
          <Box>
            <Typography variant="h5">
              {t('Create NuFi wallet recovery phrase?')}
            </Typography>
            <Typography
              className={commonClasses.headerSubtitle}
              variant="body1"
              color="textSecondary"
            >
              {t(
                'If you plan to add non-hardware wallet accounts to your NuFi wallet, you will need to create and confirm a recovery phrase first.',
              )}
            </Typography>
            <Box mt={3}>
              <Alert
                severity="info"
                text={t(
                  "If you only want to use your hardware wallet's accounts, you can skip this step and, if necessary, activate the recovery phrase later from Settings.",
                )}
              />
            </Box>
          </Box>
          <ButtonChoice
            preferredOption={{
              label: t('Create recovery phrase now'),
              onClick: onGoToMnemonicSetup,
              'data-test-id': 'create-recovery-phrase-now',
            }}
            otherOption={{
              label: t('Continue without recovery phrase'),
              onClick: () => onSkipMnemonicSetup(generateMnemonic()),
              'data-test-id': 'continue-without-recovery-phrase',
            }}
          />
          <NavigateBackWrapper>
            <NavigateBack onBack={onBack} />
          </NavigateBackWrapper>
        </Box>
      }
    />
  )
}

type SetupRecoveryPhrasePageProps = {
  onBack: () => unknown
  profiles?: Array<ProfileMetadata>
  mnemonic?: Mnemonic
  plainTextMode: boolean
  setPlainTextMode: (value: React.SetStateAction<boolean>) => void
  onSubmit: (recoveryPhrase: Mnemonic) => unknown
  walletType: WalletType
}

export function SetupRecoveryPhrasePage(props: SetupRecoveryPhrasePageProps) {
  return (
    <SideBarLayout
      left={
        <CreateProfileTimeline step="recovery" walletType={props.walletType} />
      }
      right={<SetupRecoveryPhrasePageContent {...props} />}
    />
  )
}

export function SetupRecoveryPhrasePageContent({
  onSubmit,
  onBack,
  profiles,
  mnemonic,
  setPlainTextMode,
  plainTextMode,
}: Omit<SetupRecoveryPhrasePageProps, 'walletType'>) {
  const {t} = useTranslation()
  const commonClasses = useCommonProfileStyles()
  type FormData = {mnemonic: Mnemonic}
  type FormField = keyof FormData
  const _formSchema: {[k in FormField]: yup.AnySchema} = {
    mnemonic: yup
      .string()
      // Can help during development when using demo mnemonic ENV variable
      // as otherwise this is almost impossibly unlikely to happen
      .test(
        'mnemonic-does-not-exist',
        t('Mnemonic already exists.'),
        async (value) => {
          if (value === undefined) return true
          const mnemonicHash = await getMnemonicHash(value as Mnemonic)
          return !(profiles || []).some(
            (p) =>
              p.mnemonicStorageType === 'local' &&
              p.mnemonicHash === mnemonicHash,
          )
        },
      ),
  }
  const formSchema = yup.object().shape(_formSchema)

  const onSubmitRecoveryPhrase = (values: FormData) => {
    onSubmit(values.mnemonic as Mnemonic)
  }
  const initialValues: FormData = {
    mnemonic:
      mnemonic || (config.demoMnemonic as Mnemonic) || generateMnemonic(),
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={formSchema}
      onSubmit={onSubmitRecoveryPhrase}
    >
      {(formikProps) => {
        const {handleSubmit, values, errors, isSubmitting} = formikProps
        const hasError = getHasFormError(formikProps)
        return (
          <Box className={commonClasses.wrapper}>
            <Typography variant="h5">
              {t('NuFi wallet recovery phrase')}
            </Typography>

            <Typography
              className={commonClasses.headerSubtitle}
              variant="body1"
              color="textSecondary"
            >
              <Trans
                i18nKey="recovery_phrase_write_down"
                t={t}
                values={{length: DEFAULT_MNEMONIC_LENGTH}}
                components={{
                  bold: <Box component="span" fontWeight="fontWeightBold" />,
                }}
              />
            </Typography>
            {/* Hard coded minHeight to prevent content jump when using Switch to PlainText */}
            <Box minHeight={500}>
              <form
                className={commonClasses.form}
                onSubmit={handleSubmit}
                noValidate
              >
                <Box display="flex" justifyContent="flex-end">
                  <PlainTextSwitch
                    state={plainTextMode}
                    setState={setPlainTextMode}
                  />
                </Box>
                <Grid container>
                  <Grid item xs={12}>
                    {plainTextMode ? (
                      <PlainTextInput mnemonicValue={values.mnemonic} />
                    ) : (
                      <MnemonicCard mnemonic={values.mnemonic.split(' ')} />
                    )}
                    {hasError('mnemonic') && (
                      <Typography variant="caption" color="error">
                        <>{errors.mnemonic}</>
                      </Typography>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    data-test-id="confirm-mnemonic-written-down"
                  >
                    <Button
                      className={commonClasses.submitButton}
                      textTransform="none"
                      fullWidth
                      variant="contained"
                      type="submit"
                      color="primary"
                      disabled={isSubmitting}
                    >
                      {t('I have written it down')}
                    </Button>
                  </Grid>
                </Grid>
              </form>

              <NavigateBackWrapper>
                <NavigateBack onBack={onBack} />
              </NavigateBackWrapper>
            </Box>
          </Box>
        )
      }}
    </Formik>
  )
}
