import {isEvmBlockchain} from '@nufi/wallet-evm'

import {existingBlockchains} from 'src/blockchainTypes/blockchainArrays'
import {
  isBlockchainAvailable,
  isWalletKindAvailable,
} from 'src/features/availableBlockchains/application/availableBlockchains'
import type {Blockchain, WalletKind} from 'src/types'

import {getSettings, useSettings} from './store'

function _isBlockchainEnabled(
  enabledBlockchains: readonly Blockchain[],
  blockchain: Blockchain,
): boolean {
  return (
    isBlockchainAvailable(blockchain) && enabledBlockchains.includes(blockchain)
  )
}

export function isBlockchainEnabled(blockchain: Blockchain): boolean {
  const {enabledBlockchains} = getSettings()
  return _isBlockchainEnabled(enabledBlockchains, blockchain)
}

export function useIsBlockchainEnabled(blockchain: Blockchain): boolean {
  return useGetIsBlockchainEnabled()(blockchain)
}

export function useGetIsBlockchainEnabled(): (
  blockchain: Blockchain,
) => boolean {
  const {enabledBlockchains} = useSettings()

  return (blockchain: Blockchain) => {
    return _isBlockchainEnabled(enabledBlockchains, blockchain)
  }
}

export function useFilterEnabledBlockchains(): <T extends Blockchain>(
  blockchains: readonly T[],
) => ReadonlyArray<T> {
  const isBlockchainEnabled = useGetIsBlockchainEnabled()
  return (blockchains) => blockchains.filter(isBlockchainEnabled)
}

export const useEnabledExistingBlockchains = () => {
  const filterEnabledBlockchains = useFilterEnabledBlockchains()
  return filterEnabledBlockchains(existingBlockchains)
}

export function getEnabledExistingBlockchains() {
  return existingBlockchains.filter(isBlockchainEnabled)
}

export function useIsWalletKindEnabled(): (walletKind: WalletKind) => boolean {
  const {enabledBlockchains} = useSettings()

  return (walletKind: WalletKind) => {
    const _isWalletKindAvailable = isWalletKindAvailable(walletKind)
    switch (walletKind) {
      case 'cardano':
      case 'solana':
      case 'flow':
        return _isWalletKindAvailable && enabledBlockchains.includes(walletKind)
      case 'evm':
        return (
          _isWalletKindAvailable &&
          enabledBlockchains.some((b) => isEvmBlockchain(b))
        )
      default:
        return false
    }
  }
}
