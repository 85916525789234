import {useEffect} from 'react'

export const useBeforeUnload = (fn: () => unknown) => {
  useEffect(() => {
    const handleUnload = async (e: BeforeUnloadEvent) => {
      e.preventDefault()
      await fn()
    }
    window.addEventListener('beforeunload', handleUnload)
    return () => {
      window.removeEventListener('beforeunload', handleUnload)
    }
  }, [fn])
}
