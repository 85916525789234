import {Typography} from '@mui/material'
import type {SolanaTxInstructions} from '@nufi/wallet-solana'
import React from 'react'
import {useTranslation} from 'react-i18next'

type FormattedSolanaInstructionProps = {
  txInstruction: typeof SolanaTxInstructions.type
}

export const FormattedSolanaInstruction = ({
  txInstruction,
}: FormattedSolanaInstructionProps): React.JSX.Element => {
  const {t} = useTranslation()
  let formattedInstruction = ''

  switch (txInstruction) {
    case 'transfer':
    case 'transferChecked':
      formattedInstruction = t('Transfer')
      break
    case 'transferWithSeed':
      formattedInstruction = t('Transfer with seed')
      break
    case 'tokentransfer':
    case 'tokentransferChecked':
    case 'associatedTokentransfer':
    case 'associatedTokentransferChecked':
      formattedInstruction = t('Token transfer')
      break
    case 'withdraw':
      formattedInstruction = t('Withdraw')
      break
    case 'associatedTokencreate':
      formattedInstruction = t('Create associated token account')
      break
    case 'createAccount':
      formattedInstruction = t('Create account')
      break
    case 'createAccountWithSeed':
      formattedInstruction = t('Create account with seed')
      break
    case 'initialize':
      formattedInstruction = t('Initialize stake account')
      break
    case 'delegate':
      formattedInstruction = t('Delegate')
      break
    case 'split':
      formattedInstruction = t('Split')
      break
    case 'authorize':
      formattedInstruction = t('Authorize')
      break
    case 'authorizeWithSeed':
      formattedInstruction = t('Authorize with seed')
      break
    case 'deactivate':
      formattedInstruction = t('Deactivate')
      break
    case 'unknown':
    default:
      formattedInstruction = t('Unknown instruction(s)')
  }

  return <Typography>{formattedInstruction}</Typography>
}
