import {CloudUpload as UploadIcon} from '@mui/icons-material'
import type {SvgIcon} from '@mui/material'
import {Box, Typography} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'

import Button from '../atoms/Button'

type SingleFileUploadProps = {
  id: string
  title: string
  Icon?: typeof SvgIcon
  error?: string | null
  value: File | null
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => unknown
}

export default function SingleFileUpload({
  id,
  title,
  onChange,
  value,
  error,
  Icon = UploadIcon,
}: SingleFileUploadProps) {
  const classes = useStyles()

  return (
    <>
      <Box className={classes.wrapper}>
        <Box className={classes.inputWrapper}>
          <input
            accept="application/JSON"
            className={classes.input}
            id={id}
            type="file"
            onChange={onChange}
          />
          <label htmlFor={id}>
            <Button
              textTransform="none"
              color="primary"
              variant="outlined"
              startIcon={<Icon />}
              component="span"
              /* Button TS does not know about "component=span" */
              /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
              /* @ts-ignore */
              onChange={onChange}
            >
              {title}
            </Button>
          </label>
        </Box>
        {value && <Typography variant="caption">{value.name}</Typography>}
      </Box>
      {error && (
        <Typography className={classes.error} color="error" variant="caption">
          {error}
        </Typography>
      )}
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  inputWrapper: {
    paddingRight: theme.spacing(1.5),
  },
  input: {
    display: 'none',
  },
  error: {
    display: 'block',
    marginTop: theme.spacing(1),
  },
}))
