import {CloudDownload as DownloadIcon} from '@mui/icons-material'
import {Box, Paper} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import {useTranslation} from 'react-i18next'

import type {ProfileName, ProfileBackup} from '../../appStorage'
import {useExportProfile, useCurrentProfileMeta} from '../../appStorage'
import {
  MutationGuard,
  QueryGuard,
  Button,
  SafeCenterAligner,
  InlineError,
} from '../../components'

import {useCommonStyles, ExportLoading} from './utils'

export default function ExportProfile() {
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const {t} = useTranslation()
  const exportProfile = useExportProfile()
  const profileMetaQuery = useCurrentProfileMeta()

  const onExport = async (fileName: ProfileName) => {
    const exportedProfile = await exportProfile.mutateAsyncSilent()
    if (exportedProfile) {
      await downloadProfileBackup(fileName, exportedProfile)
    }
  }

  return (
    <Box className={commonClasses.sectionWrapper}>
      <Paper elevation={0} className={classes.downloadInfo}>
        <SafeCenterAligner>
          <Box className={classes.iconWrapper}>
            <DownloadIcon fontSize="inherit" color="inherit" />
          </Box>
        </SafeCenterAligner>
      </Paper>

      <QueryGuard
        {...profileMetaQuery}
        ErrorElement={<InlineError error={t('Could not export wallet')} />}
        LoadingElement={<ExportLoading />}
      >
        {(profileMeta) => (
          <Button
            className={classes.export}
            fullWidth
            textTransform="none"
            variant="contained"
            color="secondary"
            onClick={() => onExport(profileMeta.name)}
          >
            {t('Export wallet')}
          </Button>
        )}
      </QueryGuard>

      <MutationGuard {...exportProfile} />
    </Box>
  )
}

async function downloadProfileBackup(fileName: string, backup: ProfileBackup) {
  // https://stackoverflow.com/questions/55613438/reactwrite-to-json-file-or-export-download-no-server

  const blob = new Blob([backup], {type: 'application/json'})
  const href = await URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = href
  link.download = `${fileName}.json`
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

const useStyles = makeStyles((theme) => ({
  export: {
    marginTop: theme.spacing(2),
  },
  downloadInfo: {
    background: theme.palette.action.hover,
    height: 150,
    fontSize: 60,
  },
  iconWrapper: {
    color: theme.palette.action.active,
  },
}))
