import {safeAssertUnreachable} from '@nufi/frontend-common'

import {createReducer} from 'src/utils/reducerUtils'

import type {RampOperation, RampCryptoAsset} from '../../../domain'
import type {RampFlowAccountField} from '../screens/detailsScreen/form/detailsForm'

export enum RampFlowStep {
  DETAILS = 0,
  WIDGET = 1,
}

export type RampFlowState =
  | {
      step: RampFlowStep.DETAILS
      operation: RampOperation
      asset?: RampCryptoAsset
      account?: RampFlowAccountField
      txId?: string
    }
  | {
      step: RampFlowStep.WIDGET
      operation: RampOperation
      asset: RampCryptoAsset
      account: RampFlowAccountField
      txId?: string
    }

export type FullRampFlowState = Required<RampFlowState>

export const rampFlowStateReducer = createReducer<RampFlowState>()({
  proceedToWidget(
    state,
    payload: Pick<FullRampFlowState, 'asset' | 'account' | 'operation'>,
  ) {
    return {...state, ...payload, step: RampFlowStep.WIDGET}
  },

  goBack(state) {
    switch (state.step) {
      case RampFlowStep.WIDGET:
        return {...state, step: RampFlowStep.DETAILS}
      case RampFlowStep.DETAILS:
        return state
      default:
        return safeAssertUnreachable(state)
    }
  },
})
