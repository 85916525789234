import type {SwapAsset} from 'common'
import {useTranslation} from 'react-i18next'

import {getPeggedNativeAssetBlockchain} from 'src/utils/blockchain'

import type {ExchangeBlockchain} from '../../blockchainTypes'
import {exchangeBlockchains} from '../../blockchainTypes'
import type {Blockchain} from '../../types'
import {safeAssertUnreachable} from '../../utils/assertion'
import {isBlockchainSubset} from '../../utils/blockchainGuards'

export const CHANGELLY_URL = 'https://changelly.com'
export const MIN_AMOUNT_OFFSET_MULTIPLIER = 1.05 // 5% offset - avoid price volatility errors
export const MAX_AMOUNT_OFFSET_MULTIPLIER = 0.95 // 5% offset - avoid price volatility errors
export const DEFAULT_EXCHANGE_DECIMALS = 8
export const EXCHANGE_MODAL_WIDTH = 950
export const EXCHANGE_MODAL_DIVIDER_HEIGHT = 400

// Only tokens of some blockchains are currently identifiable on-chain by some id (Changelly limitation)
export const TOKEN_INTERNAL_TX_BLOCKCHAINS = [
  'solana',
  'ethereum',
  'polygon',
  'optimism',
  'arbitrumOne',
  'base',
] as const satisfies Readonly<ExchangeBlockchain[]>

type ChangellyErrorCode = -32602

type ChangellyErrors = {
  code: ChangellyErrorCode
  message: string
  translationKey: ChangellyErrorTranslationKey
}[]

type ChangellyErrorTranslationKey = 'changelly_error_disabled_currency_pair'

// We don't use all errors, documentation doesn't represent the reality
// Add new error translations as they are used https://github.com/changelly/api-changelly#error-codes
export const CHANGELLY_ERROR_TRANSLATION_KEYS: ChangellyErrors = [
  {
    code: -32602,
    message: "Can't exchange this currencies",
    translationKey: 'changelly_error_disabled_currency_pair',
  },
]

export function useChangellyErrorsTranslations(): {
  [key in ChangellyErrorTranslationKey]: string
} {
  const {t} = useTranslation()
  return {
    changelly_error_disabled_currency_pair: t(
      'changelly_error_disabled_currency_pair',
    ),
  }
}

// TODO: consider moving to server conf, to allow for modifications without extension release
export const changellyBlockchainToNufiBlockchain = (
  blockchain: string | null,
): ExchangeBlockchain | null => {
  // The identity mapping is there as these constants may possibly diverge
  // and we want to ensure that we are returning correct ExchangeBlockchain on the TS level
  const mapping = {
    cardano: 'cardano',
    solana: 'solana',
    flow: 'flow',
    polygon: 'polygon',
    ethereum: 'ethereum',
    optimism: 'optimism',
    arbitrum: 'arbitrumOne',
  } as const

  return blockchain != null && blockchain in mapping
    ? mapping[blockchain as keyof typeof mapping]
    : null
}

// TODO: consider moving to server conf, to allow for modifications without extension release
export const blockchainToSwapAsset = (blockchain: Blockchain): SwapAsset => {
  switch (blockchain) {
    case 'cardano':
      return 'ADA' as SwapAsset
    case 'solana':
      return 'SOL' as SwapAsset
    case 'flow':
      return 'FLOW' as SwapAsset
    case 'ethereum':
      return 'ETH' as SwapAsset
    case 'polygon':
      return 'MATICPOLYGON' as SwapAsset
    case 'optimism':
    case 'arbitrumOne':
    case 'base':
      // L2s' (optimism, arbitrum, base, ...) native token is bridged ETH, bridged ETH is not among swap assets but
      // other tokens on optimism are, so we need to return something but, nothing
      // that can be matched to any swap asset
      return 'UNKNOWN_SWAP_ASSET' as SwapAsset
    default: {
      if (!isBlockchainSubset(blockchain, exchangeBlockchains)) {
        // Return a default value instead of null or safeAssertUnreachable
        // so that new blockchains don't have to be explicitly added here
        // with dummy values until exchange support is added. The logic down
        // the line handles unknown assets gracefully so this should be safe.
        return 'UNKNOWN_SWAP_ASSET' as SwapAsset
      }
      return safeAssertUnreachable(blockchain)
    }
  }
}

export const blockchainToNativeSwapAsset = (
  blockchain: Blockchain,
): SwapAsset => {
  return blockchainToSwapAsset(
    getPeggedNativeAssetBlockchain(blockchain) ?? blockchain,
  )
}
