import {Warning as WarningIcon} from '@mui/icons-material'
import type {TypographyProps} from '@mui/material'
import {Box, Typography} from '@mui/material'
import type {
  EvmUnsignedTransaction,
  TxAssetChangeSimulationResponse,
} from '@nufi/wallet-evm'
import {weiToGwei} from '@nufi/wallet-evm'
import BigNumber from 'bignumber.js'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {Alert, Icon, QueryGuard, WithTooltip} from 'src/components'
import {blockchainToCoin} from 'src/constants'
import {assert} from 'src/utils/assertion'
import {createNumberFormatter} from 'src/utils/formatting'
import type {AccountInfo, Blockchain, TokenId} from 'src/wallet'
import {useGetAccounts} from 'src/wallet'
import type {EvmBlockchain, EvmWei} from 'src/wallet/evm'
import {WithTokenMetadata} from 'src/wallet/public/ui'
import {ensureAccountById} from 'src/wallet/utils/common'

import type {EvmCustomSignOptions} from '../../customBlockchains/evm'
import {useSignContext} from '../../SignContext'
import {
  AddressRow,
  AssetMovementRow,
  AssetMovementRowBase,
  TxDetailRow,
  RowSpaceBetweenWrapper,
  TokenAssetLoadingElement,
  TokenAssetErrorElement,
} from '../common/details'

// This value was estimated on ad-hoc basis
const MAX_REASONABLE_GAS_LIMIT = new BigNumber(500_000)

type WarningMessageProps = {
  testId: string
  label: string
  severity?: 'warning' | 'error'
}

export function WarningMessage({
  testId,
  label,
  severity = 'warning',
}: WarningMessageProps) {
  return (
    <Box sx={{pb: 1}}>
      <Alert severity={severity}>
        <Typography
          variant="caption"
          fontWeight="bold"
          rtl-data-test-id={testId}
        >
          {label}
        </Typography>
      </Alert>
    </Box>
  )
}

type CommonWarningMessagesProps = {
  blockchain: EvmBlockchain
  txValue: EvmWei
  fee: EvmWei
  gasLimit: BigNumber
}

export function CommonWarningMessages({
  blockchain,
  txValue,
  fee,
  gasLimit,
}: CommonWarningMessagesProps) {
  const {t} = useTranslation()
  const accountsQuery = useGetAccounts(blockchain)

  const {selectedAccount} = useSignContext()
  assert(selectedAccount != null)

  return (
    <QueryGuard {...accountsQuery}>
      {(accounts: AccountInfo[]) => {
        const account = ensureAccountById(accounts, selectedAccount.id)

        const isGasUnreasonable = gasLimit.isGreaterThan(
          MAX_REASONABLE_GAS_LIMIT,
        )

        const totalTxCost = BigNumber.sum(txValue, fee)
        const hasInsufficientFunds = totalTxCost.isGreaterThan(account.balance)

        const warningMessages = [
          ...(isGasUnreasonable
            ? [
                {
                  id: 'estimated_gas_limit_too_high',
                  label: t('estimated_gas_limit_too_high'),
                },
              ]
            : []),
          ...(hasInsufficientFunds
            ? [{id: 'insufficient_funds', label: t('insufficient_funds')}]
            : []),
        ]

        return (
          <>
            {warningMessages.map(({id, label}) => (
              <WarningMessage key={id} testId={id} label={label} />
            ))}
          </>
        )
      }}
    </QueryGuard>
  )
}

type ValueFieldAlertProps<TBlockchain extends EvmBlockchain> = {
  blockchain: TBlockchain
}

export function ValueFieldAlert<TBlockchain extends EvmBlockchain>({
  blockchain,
}: ValueFieldAlertProps<TBlockchain>) {
  const {t} = useTranslation()
  return (
    <Alert severity="info">
      <Typography
        variant="caption"
        rtl-data-test-id="evm_tx_details_value_description"
      >
        {t('evm_tx_details_value_description', {
          coin: blockchainToCoin(blockchain),
        })}
      </Typography>
    </Alert>
  )
}

export const GeneralRow = ({
  from,
  to,
  blockchain,
}: Pick<EvmUnsignedTransaction, 'to' | 'from'> & {blockchain: Blockchain}) => {
  const {t} = useTranslation()

  return (
    <>
      {from && (
        <AddressRow label={t('From')} address={from} blockchain={blockchain} />
      )}

      {to && (
        <AddressRow label={t('To')} address={to} blockchain={blockchain} />
      )}
    </>
  )
}

export const GasInfoRow = <TBlockchain extends EvmBlockchain>(
  props: EvmCustomSignOptions<TBlockchain>,
) => {
  const {t} = useTranslation()

  const gasLimitInfo = (
    <TxDetailRow left={t('Gas limit')} right={props.gasLimit.toString()} />
  )
  return (
    <>
      {props.type === 'london' && (
        <>
          <TxDetailRow
            left={t('Max fee per gas')}
            right={
              <>
                {weiToGwei(props.maxFeePerGas).toString()}
                &nbsp;(GWEI)
              </>
            }
          />

          {gasLimitInfo}
        </>
      )}

      {props.type === 'simple' && (
        <>
          <TxDetailRow
            left={t('Gas price')}
            right={
              <>
                {weiToGwei(props.gasPrice).toString()}
                &nbsp;(GWEI)
              </>
            }
          />
          {gasLimitInfo}
        </>
      )}
    </>
  )
}

type TokenApprovalRowProps = {
  tokenId: TokenId | null
  isUnknownToken: boolean
  blockchain: Blockchain
  approval: TxAssetChangeSimulationResponse
}

export const TokenApprovalRow = ({
  tokenId,
  isUnknownToken,
  approval,
  blockchain,
}: TokenApprovalRowProps) => {
  const {t} = useTranslation()

  return (
    <RowSpaceBetweenWrapper>
      {tokenId ? (
        <WithTokenMetadata
          key={tokenId}
          tokenId={tokenId}
          blockchain={blockchain}
          guardProps={{
            LoadingElement: <TokenAssetLoadingElement />,
            ErrorElement: <TokenAssetErrorElement tokenId={tokenId} />,
          }}
        >
          {(tokenMetadata) => (
            <AssetMovementRow
              amount={new BigNumber(approval.amount)}
              blockchain={blockchain}
              tokenMetadata={tokenMetadata}
              type="token"
              tokenInfoItems={[
                {
                  key: t('Contract address'),
                  value: approval.contractAddress || '',
                },
              ]}
              extraAssetContent={
                isUnknownToken ? (
                  <UnknownTokenWarning
                    testId={'evm_connector_unknown_token-approval'}
                  />
                ) : undefined
              }
            />
          )}
        </WithTokenMetadata>
      ) : (
        <AssetMovementRowBase
          assetIcon={<Icon type={`${blockchain}DefaultTokenIcon`} />}
          assetName={approval.name || ''}
          assetInfoItems={[
            {
              key: t('Contract address'),
              value: approval.contractAddress || '',
            },
          ]}
          movementContent={
            <ApprovalAmount
              ticker={approval.symbol}
              amount={new BigNumber(approval.amount)}
            />
          }
        />
      )}
    </RowSpaceBetweenWrapper>
  )
}

type ApprovalAmountProps = {
  ticker: string | null
  amount: BigNumber
  typographyProps?: TypographyProps
}

export function ApprovalAmount({
  ticker,
  amount,
  typographyProps = {},
}: ApprovalAmountProps) {
  const MAX_DECIMALS = 4
  const decimals = amount.decimalPlaces()
  const rounded = decimals > MAX_DECIMALS
  const roundedFormatter = createNumberFormatter({
    maximumFractionDigits: Math.min(decimals, MAX_DECIMALS),
  })

  const formattedAmount = amount.isGreaterThan(Number.MAX_SAFE_INTEGER)
    ? `${amount.toExponential(6)}... ${ticker || ''}`
    : `${roundedFormatter.format(amount.toNumber())}${
        rounded ? '...' : ''
      } ${ticker || ''}`

  return (
    <Box display="inline-block" component="div">
      <WithTooltip title={`${amount.toString()} ${ticker || ''}`}>
        <Typography variant="body2" {...typographyProps}>
          {formattedAmount}
        </Typography>
      </WithTooltip>
    </Box>
  )
}

export const UnknownTokenWarning = ({testId}: {testId?: string}) => {
  const {t} = useTranslation()
  return (
    <div rtl-data-test-id={testId}>
      <WithTooltip title={t('evm_connector_unknown_token')}>
        <WarningIcon color="warning" />
      </WithTooltip>
    </div>
  )
}
