/* eslint-disable @typescript-eslint/explicit-function-return-type */

import {assert, sleep} from '@nufi/frontend-common'
import type {AccountId, HotVendor, HwVendor} from '@nufi/wallet-common'
import {getSolanaTransactionId} from '@nufi/wallet-solana'
import type {
  SolanaTxFeeParams,
  SolanaAddress,
  Lamports,
  SolanaValidatorPubKey,
  SolanaTransaction,
  SolanaSendContext,
  SolanaNewAccount,
  SolanaAccountStoredData,
} from '@nufi/wallet-solana'
import type {PublicKey as SolanaPubKey} from '@solana/web3.js'

import config from 'src/config'
import type {TransactionContext} from 'src/store/transactions'
import {ensureNoPendingTx} from 'src/store/transactions'
import {useMutation, invalidateQueryKeys} from 'src/utils/mutation-utils'

import {_saveAccounts} from '../../utils/accountsMutations'
import {solana} from '../solanaManagers'

import {
  accountsInvalidationKeys,
  getTxHistoryInvalidationKey,
  submitTransferInvalidationKeys,
} from './invalidationKeys'

async function saveAccounts(
  accounts: SolanaAccountStoredData[],
): Promise<void> {
  await _saveAccounts('solana', accounts)
}

export const mutationKeys = {
  solanaSubmitTransfer: ['solanaSubmitTransfer'],
  solanaSignTransferAssets: ['solanaSignTransferAssets'],
  solanaAddHwAccount: {
    gridPlus: ['solanaAddGridPlusAccount'],
    ledger: ['solanaAddLedgerAccount'],
    trezor: ['solanaAddTrezorAccount'],
  },
  solanaAddHotAccount: ['solanaAddHotAccount'],
  solanaSignSetupStakeAccount: ['solanaSignSetupStakeAccount'],
  solanaSignDelegate: ['solanaSignDelegate'],
  solanaSubmitStakeInstruction: ['solanaSubmitStakeInstruction'],
  solanaUndelegate: ['solanaUndelegate'],
  solanaSplitStake: ['solanaSplitStake'],
  solanaWithdraw: ['solanaWithdraw'],
  solanaVerifyAccountAddress: ['solanaVerifyAccountAddress'],
}

export function useSubmitTransfer() {
  return useMutation(
    mutationKeys.solanaSubmitTransfer,
    async ({signedTx}: {signedTx: SolanaTransaction; accountId: AccountId}) => {
      await sleep(1000) // solely for better "retry" UX
      return await solana.accountManager.sendAndConfirmTransaction(signedTx)
    },
    {
      invalidationKeys: submitTransferInvalidationKeys,
      onMutate: (params): TransactionContext => {
        const transactionId = getSolanaTransactionId(params.signedTx)
        assert(transactionId != null)
        return {
          blockchain: 'solana' as const,
          accountId: params.accountId,
          transactionId,
        }
      },
    },
  )
}

export type UseSubmitTransfer = typeof useSubmitTransfer

export function useSignTransferAssets() {
  return useMutation(
    mutationKeys.solanaSignTransferAssets,
    async ({
      toAddress,
      accountId,
      sendContext,
      txFeeParams,
    }: {
      accountId: AccountId
      toAddress: SolanaAddress
      sendContext: SolanaSendContext
      txFeeParams: SolanaTxFeeParams
    }) => {
      ensureNoPendingTx(accountId)
      await sleep(1000) // solely for better "retry" UX
      const account = solana.accountsStore.getAccount(accountId)

      return await solana.accountManager.signTransferAssetsTx({
        account,
        toAddress,
        sendContext,
        txFeeParams,
        solanaRpcUrl: config.solanaRpcUrl,
      })
    },
  )
}

export type UseSignTransferAssets = typeof useSignTransferAssets

export type AddAccountArgs = {
  newAccounts: SolanaNewAccount[]
}

export function useAddHwAccounts(hwVendor: HwVendor) {
  return useMutation(
    mutationKeys.solanaAddHwAccount[hwVendor],
    async ({newAccounts}: AddAccountArgs) => {
      const accounts = solana.accountsStore.getAllAccounts()
      const updatedAccounts = await solana.wallet.addHwAccounts(
        accounts,
        newAccounts,
        hwVendor,
      )
      await saveAccounts(updatedAccounts)
    },
    {
      invalidationKeys: accountsInvalidationKeys,
    },
  )
}

export function useAddHotAccounts(hotVendor: HotVendor) {
  return useMutation(
    mutationKeys.solanaAddHotAccount,
    async ({newAccounts}: AddAccountArgs) => {
      const accounts = solana.accountsStore.getAllAccounts()
      const updatedAccounts = await solana.wallet.addHotAccounts(
        accounts,
        newAccounts,
        hotVendor,
      )
      await saveAccounts(updatedAccounts)
    },
    {
      invalidationKeys: accountsInvalidationKeys,
    },
  )
}

export function useSignSetupStakeAccount() {
  return useMutation(
    mutationKeys.solanaSignSetupStakeAccount,
    async ({
      accountId,
      amount,
      validator,
      txFeeParams,
    }: {
      accountId: AccountId
      amount: Lamports
      validator: SolanaValidatorPubKey
      txFeeParams: SolanaTxFeeParams
    }) => {
      ensureNoPendingTx(accountId)
      await sleep(1000) // solely for better "retry" UX
      const account = solana.accountsStore.getAccount(accountId)
      return await solana.accountManager.setupStakeAccount({
        account,
        validator,
        amount,
        txFeeParams,
      })
    },
  )
}

export function useSignDelegate() {
  return useMutation(
    mutationKeys.solanaSignDelegate,
    async ({
      accountId,
      validator,
      stakePubKey,
      isOwnerAuthorizedStaker,
      txFeeParams,
    }: {
      accountId: AccountId
      validator: SolanaValidatorPubKey
      stakePubKey: SolanaPubKey
      isOwnerAuthorizedStaker: boolean
      txFeeParams: SolanaTxFeeParams
    }) => {
      ensureNoPendingTx(accountId)
      await sleep(1000) // solely for better "retry" UX
      const account = solana.accountsStore.getAccount(accountId)
      return await solana.accountManager.delegate({
        account,
        validator,
        stakePubKey,
        isOwnerAuthorizedStaker,
        txFeeParams,
      })
    },
  )
}

export type SubmitStakeInstructionArgs = {
  signedTx: SolanaTransaction
  accountId: AccountId
}

export function useSubmitStakeInstruction() {
  return useMutation(
    mutationKeys.solanaSubmitStakeInstruction,
    async ({signedTx}: SubmitStakeInstructionArgs) => {
      const res =
        await solana.accountManager.sendAndConfirmTransaction(signedTx)

      // Wait for load balancing to assure smooth UX.
      // It may take some time for all nodes to be aware of the tx and for
      // the stake account state to update, so to assure a smooth user flow
      // we prefer to wait here.
      await sleep(5000)

      return res
    },
    {
      onSuccess: (data, {accountId}) => {
        invalidateQueryKeys([
          ...accountsInvalidationKeys,
          getTxHistoryInvalidationKey(accountId),
        ])
      },
      onMutate: (params): TransactionContext => {
        const transactionId = getSolanaTransactionId(params.signedTx)
        assert(transactionId != null)
        return {
          blockchain: 'solana' as const,
          accountId: params.accountId,
          transactionId,
        }
      },
    },
  )
}

export function useSignUndelegate() {
  return useMutation(
    mutationKeys.solanaUndelegate,
    async ({
      accountId,
      stakeAccountKey,
      txFeeParams,
      isOwnerAuthorizedStaker,
    }: {
      accountId: AccountId
      stakeAccountKey: SolanaPubKey
      txFeeParams: SolanaTxFeeParams
      isOwnerAuthorizedStaker: boolean
    }) => {
      ensureNoPendingTx(accountId)
      const account = solana.accountsStore.getAccount(accountId)
      return await solana.accountManager.undelegate({
        account,
        stakeAccountKey,
        txFeeParams,
        isOwnerAuthorizedStaker,
      })
    },
  )
}

export function useSignWithdraw() {
  return useMutation(
    mutationKeys.solanaWithdraw,
    async ({
      accountId,
      stakeAccountKey,
      amount,
      txFeeParams,
    }: {
      accountId: AccountId
      stakeAccountKey: SolanaPubKey
      amount: Lamports
      txFeeParams: SolanaTxFeeParams
    }) => {
      ensureNoPendingTx(accountId)
      const account = solana.accountsStore.getAccount(accountId)
      return await solana.accountManager.withdraw({
        account,
        stakeAccountKey,
        amount,
        txFeeParams,
      })
    },
  )
}

export function useVerifyAccountAddress() {
  return useMutation(
    mutationKeys.solanaVerifyAccountAddress,
    async (accountId: AccountId) => {
      const account = solana.accountsStore.getAccount(accountId)
      await solana.accountManager.verifyAddress(account)
    },
  )
}
