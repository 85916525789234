import type {ProfileManager} from '../appStorage/profileManager'
import type {TokenVisibilityState} from '../store/wallet'
import {useTokenVisibilityStore} from '../store/wallet'
import type {TokenVisibility, TokenVisibilityBase} from '../types'

export interface TokenVisibilityStoreManager {
  initialize(): Promise<void>
  reload: () => Promise<void>

  getTokenVisibilities(): TokenVisibility[]
  setTokenVisibilities(tokenVisibilities: TokenVisibility[]): void
}

type TokenVisibilityStore = {
  getState: () => TokenVisibilityState<TokenVisibility>
}

class TokenVisibilityStoreManagerImpl implements TokenVisibilityStoreManager {
  constructor(
    private tokensStore: TokenVisibilityStore,
    private profileManager: ProfileManager,
  ) {}

  initialize: TokenVisibilityStoreManager['initialize'] = async () => {
    await this.reload()
  }

  reload: TokenVisibilityStoreManager['reload'] = async () => {
    const {tokenVisibilities} = await this.profileManager.getProfileData()
    this.setTokenVisibilities(tokenVisibilities)
  }

  getTokenVisibilities: TokenVisibilityStoreManager['getTokenVisibilities'] =
    () => {
      const {tokenVisibilities} = this.tokensStore.getState()
      return tokenVisibilities
    }

  setTokenVisibilities: TokenVisibilityStoreManager['setTokenVisibilities'] = (
    tokenVisibilities: TokenVisibility[],
  ) => {
    const {setTokenVisibilities} = this.tokensStore.getState()
    setTokenVisibilities(tokenVisibilities)
  }
}

const matchToken = (
  tokenA: TokenVisibilityBase,
  tokenB: TokenVisibilityBase,
): boolean =>
  tokenA.blockchain === tokenB.blockchain && tokenA.tokenId === tokenB.tokenId

const addTokenVisibility = (
  tokenVisibilities: TokenVisibility[],
  newToken: TokenVisibilityBase,
  visibility: 'hidden' | 'unhidden',
): TokenVisibility[] => [
  ...tokenVisibilities.filter((t) => !matchToken(t, newToken)),
  {...newToken, updatedAt: Date.now(), visibility},
]

export const hideToken = (
  tokenVisibilities: TokenVisibility[],
  token: TokenVisibilityBase,
): TokenVisibility[] => addTokenVisibility(tokenVisibilities, token, 'hidden')

export const unhideToken = (
  tokenVisibilities: TokenVisibility[],
  token: TokenVisibilityBase,
): TokenVisibility[] => addTokenVisibility(tokenVisibilities, token, 'unhidden')

let tokenVisibilityStoreManager: TokenVisibilityStoreManagerImpl | null

export const TokenVisibilityStoreManagerProvider = {
  initialize: async (profileManager: ProfileManager): Promise<void> => {
    tokenVisibilityStoreManager = new TokenVisibilityStoreManagerImpl(
      useTokenVisibilityStore,
      profileManager,
    )
    await tokenVisibilityStoreManager.initialize()
  },
  instance: (): TokenVisibilityStoreManagerImpl => {
    if (!tokenVisibilityStoreManager) {
      throw new Error('TokenVisibilityStoreManager not initialized')
    }
    return tokenVisibilityStoreManager
  },
}
