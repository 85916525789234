import {Box, Divider} from '@mui/material'
import React from 'react'

import {QueryGuard, LoadStakingDataError} from '../../../../components'
import {useScrollableStyles} from '../../../../utils/layoutUtils'
import {useGetAccounts} from '../../../../wallet/flow'
import {StakingTabContentLoading} from '../../common/Loadings'

import FlowStakeAccountList from './StakeAccountList'
import StakeSummary from './StakeSummary'

function StakeAccountsOverview() {
  const stakeAccountsQuery = useGetAccounts()
  const {scrollableParent} = useScrollableStyles()

  return (
    <QueryGuard
      {...stakeAccountsQuery}
      LoadingElement={<StakingTabContentLoading />}
      ErrorElement={<LoadStakingDataError blockchain="flow" />}
    >
      {(accounts) => (
        <Box className={scrollableParent}>
          <StakeSummary accounts={accounts} />
          <Divider />
          <FlowStakeAccountList accounts={accounts} />
        </Box>
      )}
    </QueryGuard>
  )
}

export default StakeAccountsOverview
