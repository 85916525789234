import {Launch, Close as CloseIcon} from '@mui/icons-material'
import type {
  LinkProps,
  TypographyProps,
  ButtonProps,
  Theme,
} from '@mui/material'
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Typography,
  Link,
  styled,
  Button,
} from '@mui/material'
import {formatDistanceToNow} from 'date-fns'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {useGetBlockchainName} from 'src/utils/translations'

import {explorerLinkBlockchains} from '../../../blockchainTypes'
import {getTransactionExplorerLink} from '../../../components/explorerLinks'
import {
  Alert,
  InlineLoading,
  TransactionStatusIcon,
} from '../../../components/visual'
import type {TransactionInfo} from '../../../store/transactions'
import {colors} from '../../../theme/palette'
import i18n from '../../../translations/i18n'
import type {SupportedLanguage} from '../../../translations/supportedLanguages'
import {toDateFnsLocale} from '../../../translations/utils'
import {isBlockchainSubset} from '../../../utils/blockchainGuards'
import {skipForwardingProps} from '../../../utils/stylingUtils'

import {getTxStatusColor, useGetTxStatusText} from './utils'

const defaultGridSpan = 12 // MUI default
const closeGridSpan = 1
const txStatusGridSpan = 0.7

export type TransactionItemProps = {
  tx: TransactionInfo
  accountName: string | undefined
  showBottomDivider: boolean
  txIdToClear: string | null
  resetTxIdToClear: () => void
  setTxIdToClear: (id: string) => void
  onDetails: (tx: TransactionInfo) => void
  onClear: (tx: TransactionInfo) => void
  takingTooLongLink?: string
}

export function TransactionItem({
  tx,
  onDetails,
  onClear,
  txIdToClear,
  setTxIdToClear,
  resetTxIdToClear,
  accountName,
  showBottomDivider,
  takingTooLongLink,
}: TransactionItemProps) {
  const {t} = useTranslation()
  const getTxStatusText = useGetTxStatusText()
  const getBlockchainName = useGetBlockchainName()

  return (
    <Grid container direction="row" key={tx.mutationId} sx={{minWidth: 450}}>
      {!tx.context ? (
        <InlineLoading />
      ) : (
        <Grid container direction="column">
          <Grid container item alignItems="center" direction="row">
            <Grid item xs={txStatusGridSpan}>
              <TransactionStatusIcon type={getTxStatusColor(tx)} />
            </Grid>
            <Grid
              container
              item
              direction="column"
              xs={defaultGridSpan - txStatusGridSpan - closeGridSpan}
            >
              <Grid item>
                <TxStatusTypo
                  statusColor={
                    tx.isPending ? 'warning' : tx.error ? 'error' : 'default'
                  }
                >
                  {t('transaction_status', {
                    blockchain: getBlockchainName(tx.context.blockchain),
                    status: getTxStatusText(tx),
                  })}
                </TxStatusTypo>
              </Grid>
              <Grid item>
                <Typography color="textSecondary" variant="body2">
                  {accountName}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item xs={1} justifySelf="flex-end">
              {txIdToClear !== tx.customId && (
                <IconButton
                  edge={false}
                  color="inherit"
                  onClick={() => {
                    if (!tx.isPending) {
                      // If tx is not pending clear it immediately
                      onClear(tx)
                    } else {
                      // If tx is pending, require extra confirmation before clearing it
                      setTxIdToClear(tx.customId)
                    }
                  }}
                  size="small"
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              )}
            </Grid>
          </Grid>
          <Grid item container>
            <Grid
              item
              container
              xs={defaultGridSpan - txStatusGridSpan}
              alignItems="center"
              ml="auto"
              columnSpacing={1}
            >
              <Grid item>
                <Typography variant="caption" color="text.disabled">
                  {formatDistanceToNow(tx.updatedAt, {
                    includeSeconds: false,
                    addSuffix: true,
                    locale: toDateFnsLocale(i18n.language as SupportedLanguage),
                  })}
                </Typography>
              </Grid>
              {tx.isPending ? (
                <>
                  {takingTooLongLink && (
                    <Grid item>
                      <InfoDivider />
                      <TxStatusLink
                        statusColor="warning"
                        href={takingTooLongLink}
                      >
                        {t('Taking too long?')}
                        <ExternalIcon fontSize="inherit" />
                      </TxStatusLink>
                    </Grid>
                  )}
                </>
              ) : (
                <Grid item>
                  <InfoDivider />
                  <TxStatusLink
                    statusColor="text"
                    onClick={() => onDetails(tx)}
                  >
                    {t('Details')}
                  </TxStatusLink>
                </Grid>
              )}
              <Grid item>
                {tx.context.transactionId &&
                  isBlockchainSubset(
                    tx.context.blockchain,
                    explorerLinkBlockchains,
                  ) && (
                    <>
                      <InfoDivider />
                      <TxStatusLink
                        href={getTransactionExplorerLink(
                          tx.context.transactionId,
                          tx.context.blockchain,
                        )}
                      >
                        {t('Explorer')}
                        <ExternalIcon fontSize="inherit" />
                      </TxStatusLink>
                    </>
                  )}
              </Grid>
            </Grid>
          </Grid>
          {/* User requested pending tx removal */}
          {txIdToClear === tx.customId && (
            <ConfirmPendingTxRemoval
              onClear={() => onClear(tx)}
              onCancel={resetTxIdToClear}
            />
          )}
        </Grid>
      )}
      {showBottomDivider && (
        <Grid item xs={12}>
          <TxStatusDivider />
        </Grid>
      )}
    </Grid>
  )
}

type ConfirmPendingTxRemovalProps = {
  onClear: () => void
  onCancel: () => void
}

function ConfirmPendingTxRemoval({
  onCancel,
  onClear,
}: ConfirmPendingTxRemovalProps) {
  const {t} = useTranslation()
  return (
    <Box sx={{mt: 1}}>
      <Alert severity="warning" hideIcon fullWidthContent>
        <Typography variant="body2">
          {t('delete_pending_tx_confirmation')}
        </Typography>
        <ActionButtonsWrapper>
          <ActionButton onClick={onCancel} color="default" variant="outlined">
            {t('No, keep')}
          </ActionButton>
          <ActionButton onClick={onClear} color="warning" variant="contained">
            {t('Yes, clear')}
          </ActionButton>
        </ActionButtonsWrapper>
      </Alert>
    </Box>
  )
}

const ActionButtonsWrapper = styled('div')(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  marginTop: theme.spacing(1),
  gap: theme.spacing(1),
}))

const ActionButton = styled(Button)<ButtonProps>({
  minHeight: 0,
  minWidth: 0,
  fontSize: 12,
  textTransform: 'none',
})

ActionButton.defaultProps = {
  size: 'small',
}

const TxStatusDivider = styled(Divider)(({theme}) => ({
  margin: theme.spacing(2, 0),
}))

type TxStatusTypoProps = {
  statusColor?: StatusColor
}

const TxStatusTypo = styled(Typography, {
  shouldForwardProp: (prop) =>
    skipForwardingProps<TxStatusTypoProps>(prop, ['statusColor']),
})<TypographyProps & TxStatusTypoProps>(({theme, statusColor}) => ({
  color: selectTxStatusColor(theme, statusColor),
}))

TxStatusTypo.defaultProps = {
  variant: 'subtitle2',
}

const InfoDivider = styled(Typography)(({theme}) => ({
  paddingTop: theme.spacing(1),
  paddingRight: theme.spacing(1),
}))

InfoDivider.defaultProps = {
  variant: 'caption',
  children: '|',
}

type TxStatusLinkProps = {statusColor?: StatusColor}

const TxStatusLink = styled(Link, {
  shouldForwardProp: (prop) =>
    skipForwardingProps<TxStatusLinkProps>(prop, ['statusColor']),
})<LinkProps & TxStatusLinkProps>(({theme, statusColor}) => ({
  color: selectTxStatusColor(theme, statusColor),
  cursor: 'pointer',
}))

TxStatusLink.defaultProps = {
  variant: 'caption',
  target: '_blank',
}

const ExternalIcon = styled(Launch)(({theme}) => ({
  verticalAlign: 'middle',
  marginLeft: theme.spacing(0.5),
}))

type StatusColor = 'default' | 'warning' | 'text' | 'error' | 'textSecondary'

const selectTxStatusColor = (theme: Theme, statusColor?: StatusColor) =>
  ({
    warning: colors.warning.alertContent,
    default: undefined,
    error: colors.error.alertContent,
    text: theme.palette.text.primary,
    textSecondary: theme.palette.text.disabled,
  })[statusColor || 'default']
