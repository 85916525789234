import {Typography} from '@mui/material'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import type {StakingBlockchain} from '../../../blockchainTypes'
import {AssetIconModalHeader} from '../../../components'
import type {HwVendor} from '../../../types'
import {useGetCoinName, useGetBlockchainName} from '../../../utils/translations'
import {DeviceReadyState as _DeviceReadyState} from '../../transaction/common'

import {EpochInfoText} from './overview/StakeOverviewUtils'

type AssetDetailHeaderProps = {
  onClose: () => unknown
  blockchain: StakingBlockchain
  customTitle?: string | React.ReactNode
}

// TODO: reuse differs only in "translation" text
export const StakeModalHeader = ({
  onClose,
  blockchain,
  customTitle,
}: AssetDetailHeaderProps) => {
  const {t} = useTranslation()
  const getCoinName = useGetCoinName()
  const getBlockchainName = useGetBlockchainName()

  return (
    <AssetIconModalHeader onClose={onClose} blockchain={blockchain}>
      <>
        <Typography variant="body1">
          {customTitle || t('stake_asset', {asset: getCoinName(blockchain)})}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {getBlockchainName(blockchain)}
        </Typography>
      </>
    </AssetIconModalHeader>
  )
}

// TODO: reuse differs only in "translation" text
export const UndelegateModalHeader = ({
  onClose,
  blockchain,
}: AssetDetailHeaderProps) => {
  const {t} = useTranslation()
  const getCoinName = useGetCoinName()
  const getBlockchainName = useGetBlockchainName()

  return (
    <AssetIconModalHeader onClose={onClose} blockchain={blockchain}>
      <>
        <Typography variant="body1">
          {t('undelegate_asset', {asset: getCoinName(blockchain)})}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {getBlockchainName(blockchain)}
        </Typography>
      </>
    </AssetIconModalHeader>
  )
}

// TODO: reuse differs only in "translation" text
export const WithdrawModalHeader = ({
  onClose,
  blockchain,
}: AssetDetailHeaderProps) => {
  const {t} = useTranslation()
  const getCoinName = useGetCoinName()
  const getBlockchainName = useGetBlockchainName()

  return (
    <AssetIconModalHeader onClose={onClose} blockchain={blockchain}>
      <>
        <Typography variant="body1">
          {t('withdraw_asset', {asset: getCoinName(blockchain)})}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {getBlockchainName(blockchain)}
        </Typography>
      </>
    </AssetIconModalHeader>
  )
}

type DeviceReadyStateProps = {hwVendor: HwVendor}

export const DeviceReadyState = ({hwVendor}: DeviceReadyStateProps) => {
  const {t} = useTranslation()
  return (
    <_DeviceReadyState
      {...{hwVendor}}
      alertMessage={t(
        'Always verify that the staking funds are not leaving your wallet.',
      )}
    />
  )
}

export type ActiveScreen =
  | 'details'
  | 'summary'
  | 'sign-trezor'
  | 'sign-ledger'
  | 'sign-mnemonic'
  | 'sign-gridplus'
  | 'submit'

export const useSetupStakeAccountScreenState = () =>
  useState<ActiveScreen>('details')

export const CurrentEpochInfoText = ({value}: {value: number}) => {
  const {t} = useTranslation()
  return <EpochInfoText label={t('Current epoch')} value={value} />
}
