import type {WalletKind} from 'src/wallet/walletKind'

import type {Blockchain} from '../../types'
import {safeAssertUnreachable} from '../../utils/assertion'
import type {Cardano} from '../cardano/cardanoManagers'
import {getCardano} from '../cardano/cardanoManagers'
import {arbitrumOne} from '../evm/chains/arbitrumOne/managers'
import {ethereum} from '../evm/chains/ethereum/managers'
import {milkomedaC1} from '../evm/chains/milkomedaC1/managers'
import {base} from '../evm/chains/opStack/base/managers'
import {optimism} from '../evm/chains/opStack/optimism/managers'
import {polygon} from '../evm/chains/polygon/managers'
import {evm} from '../evm/commonEvmManagers'
import {flow} from '../flow/flowManagers'
import {solana} from '../solana/solanaManagers'

type WalletManagers =
  | typeof solana
  | Cardano
  | typeof flow
  | typeof ethereum
  | typeof milkomedaC1
  | typeof polygon
  | typeof optimism
  | typeof arbitrumOne
  | typeof base

export function getWalletManagers(blockchain: Blockchain): WalletManagers {
  switch (blockchain) {
    case 'cardano':
      return getCardano()
    case 'solana':
      return solana
    case 'flow':
      return flow
    case 'ethereum':
      return ethereum
    case 'milkomedaC1':
      return milkomedaC1
    case 'polygon':
      return polygon
    case 'optimism':
      return optimism
    case 'arbitrumOne':
      return arbitrumOne
    case 'base':
      return base
    default:
      return safeAssertUnreachable(blockchain)
  }
}

type WalletKindWalletManagers =
  | typeof solana
  | Cardano
  | typeof flow
  | typeof evm

export function getWalletKindWalletManagers(
  walletKind: WalletKind,
): WalletKindWalletManagers {
  switch (walletKind) {
    case 'cardano':
      return getCardano()
    case 'solana':
      return solana
    case 'flow':
      return flow
    case 'evm':
      return evm
    default:
      return safeAssertUnreachable(walletKind)
  }
}
