/* eslint-disable @typescript-eslint/explicit-function-return-type */
import type {SolanaTransaction} from '@nufi/wallet-solana'
import {isVersionedTransaction, parseAccounts} from '@nufi/wallet-solana'
import type {Message, VersionedMessage} from '@solana/web3.js'
import {useQuery} from '@tanstack/react-query'

import {solana} from '../../solanaManagers'

import {QUERY_KEY_PREFIX as P} from './utils'
export const transactionQueryKeys = {
  transactionSimulation: (txMessage: Message | VersionedMessage) => [
    P,
    'transactionSimulation',
    {txMessage: txMessage.serialize().toString('base64')},
  ],
  getFeeForMessage: (txMessage: Message | VersionedMessage) => [
    P,
    'getFeeForMessage',
    {txMessage: txMessage.serialize().toString('base64')},
  ],
}

export function useGetTransactionSimulation(transaction: SolanaTransaction) {
  const message = isVersionedTransaction(transaction)
    ? transaction.message
    : transaction.compileMessage()
  const accountPubKeys = parseAccounts(transaction)
  return useQuery({
    queryKey: transactionQueryKeys.transactionSimulation(message),
    queryFn: async () => {
      const simulationResult = await solana.accountManager
        .simulateTransaction(transaction, {accountPubKeys})
        .catch((err) => {
          // Workaround to treat lower-level RPC errors as valid simulation results
          // instead of throwing an error which complicates handling in the query/UI layer.
          // Relies on hardcoded solana web3.js error message
          // see https://github.com/solana-labs/solana-web3.js/blob/3496637abd0de4700f2e54e397535e3463d3a46d/packages/library-legacy/src/connection.ts#L5686
          if (
            err instanceof Error &&
            err.message.startsWith('failed to simulate transaction:')
          ) {
            return null
          }
          throw err
        })
      return {accountPubKeys, simulationResult}
    },
    refetchInterval: 10000,
  })
}
