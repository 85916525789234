import {HttpRequestError} from '@nufi/frontend-common'
import type {SyncingProfile} from 'common'

import type {AuthenticatedRequest} from 'src/features/nufiAuth/infrastructure'
import type {RemoteProfileStorage} from 'src/features/profileSync/domain'

export class RemoteProfileStorageApi implements RemoteProfileStorage {
  constructor(
    private backendUrl: string,
    private authenticatedRequest: AuthenticatedRequest,
  ) {}

  private getApiEndpoint = (): string => `${this.backendUrl}/api/v1/profiles`

  getProfile = async (): Promise<SyncingProfile | null> => {
    try {
      return await this.authenticatedRequest<SyncingProfile>({
        url: this.getApiEndpoint(),
      })
    } catch (e) {
      if (e instanceof HttpRequestError && e.httpStatus === 404) {
        return null
      }
      throw e
    }
  }

  postProfile = async (content: SyncingProfile): Promise<void> => {
    await this.authenticatedRequest<string>({
      url: this.getApiEndpoint(),
      method: 'POST',
      body: JSON.stringify(content),
      headers: {'Content-Type': 'application/json'},
    })
  }
}
