import React from 'react'
import {useTranslation} from 'react-i18next'

import {CenteredError} from '../../../../components'
import {ErrorBoundary} from '../../../../ErrorBoundary'
import type {SignScreenData} from '../../../../store/dappConnector'
import {assert} from '../../../../utils/assertion'
import type {EvmBlockchain} from '../../../../wallet/evm'
import {isEvmBlockchain} from '../../../../wallet/evm'
import type {ExtraOptionsProps} from '../types'

import {CardanoTxData} from './cardano'
import {EvmTxData} from './evm'
import {FlowTxData} from './flow'
import {SolanaTxData} from './solana'

type TxDataProps = {
  data: SignScreenData<'sign-tx'>
  extraOptionsProps?: ExtraOptionsProps<'sign-tx'>
}

const isEvmBlockchainData = (
  data: TxDataProps['data'],
): data is Extract<TxDataProps['data'], {type: EvmBlockchain}> =>
  isEvmBlockchain(data.type)

export function TxData({data, extraOptionsProps}: TxDataProps) {
  const {t} = useTranslation()

  return (
    <ErrorBoundary
      errorContent={
        <CenteredError
          error={t('Unable to display the transaction details.')}
        />
      }
    >
      {(() => {
        // Note that we can not use just `isEvmBlockchain(data.type)` here
        // as TS wil only infer type of `data.type` but will infer nothing
        // about the `data` object itself.
        if (isEvmBlockchainData(data)) {
          assert(extraOptionsProps != null)
          return (
            <EvmTxData
              blockchain={data.type}
              data={data.data}
              {...{extraOptionsProps}}
            />
          )
        } else {
          const Component = {
            cardano: CardanoTxData,
            solana: SolanaTxData,
            flow: FlowTxData,
          }[data.type] as (props: typeof data) => JSX.Element
          return <Component {...data} />
        }
      })()}
    </ErrorBoundary>
  )
}
