import {Box, Grid, Divider} from '@mui/material'
import {useFormikContext} from 'formik'
import _ from 'lodash'
import React from 'react'
import {useTranslation} from 'react-i18next'

import type {CsvExportBlockchain} from '../../../../../blockchainTypes'
import {ModalLayout, useModalSharedStyles} from '../../../../../components'
import {assert} from '../../../../../utils/assertion'
import type {SelectChangeFn} from '../../../../../utils/form'
import type {AccountInfo, Blockchain} from '../../../../../wallet/types'

import {maxDateValue, minDateValue} from './constants'
import type {ExportHistorySelectItem} from './ExportHistoryUtils'
import {
  ExportModalHeader,
  ExportModalFooter,
  ExportSelectAccounts,
  DateField,
  EXPORT_FORM_ID,
  ExportModalErrorAlerts,
} from './ExportHistoryUtils'
import type {ExportHistorySchema} from './types'

type ExportHistoryDetailScreenProps = {
  onClose: () => unknown
  accounts: AccountInfo[]
  errorKeys: CsvExportBlockchain[]
}

export function ExportHistoryDetailScreen({
  onClose,
  accounts,
  errorKeys,
}: ExportHistoryDetailScreenProps) {
  const {t} = useTranslation()
  const classes = useModalSharedStyles()
  const {values, setFieldValue, errors, isValid, handleSubmit} =
    useFormikContext<ExportHistorySchema>()

  const handleDelete = (
    e: React.MouseEvent,
    value: ExportHistorySelectItem,
  ): void => {
    const selectedBlockchainToAccountIds = {
      ...values.blockchainToAccountIds,
      [value.blockchain]: values.blockchainToAccountIds[
        value.blockchain
      ].filter((currentValue) => currentValue !== value.accountId),
    }

    const blockchainToAccountIds: keyof ExportHistorySchema =
      'blockchainToAccountIds'
    setFieldValue(blockchainToAccountIds, selectedBlockchainToAccountIds)
  }

  const handleChange: SelectChangeFn<ExportHistorySelectItem[]> = (event) => {
    const items = event.target.value as ExportHistorySelectItem[]
    const newItem = _.last(items)

    // event.target.value always has at least one element - the item that a user clicked on
    assert(newItem != null)
    const {blockchain, accountId} = newItem

    const updated = {
      ...values.blockchainToAccountIds,
      // remove an element in case it already being in the list
      // add an element in case it not being in the list
      [blockchain]: _.xor(values.blockchainToAccountIds[blockchain], [
        accountId,
      ]),
    }

    const blockchainToAccountIds: keyof ExportHistorySchema =
      'blockchainToAccountIds'
    setFieldValue(blockchainToAccountIds, updated)
  }

  return (
    <ModalLayout
      header={<ExportModalHeader {...{onClose}} />}
      body={
        <form onSubmit={handleSubmit} noValidate id={EXPORT_FORM_ID}>
          <Box p={2}>
            <Box className={classes.formField}>
              <Box className={classes.formField}>
                <ExportSelectAccounts
                  {...{
                    accounts: accounts.filter(
                      (accountInfo) =>
                        !(errorKeys as Blockchain[]).includes(
                          accountInfo.blockchain,
                        ),
                    ),
                    handleDelete,
                    handleChange,
                  }}
                  value={values.blockchainToAccountIds}
                  error={!!errors?.blockchainToAccountIds}
                  helperText={errors?.blockchainToAccountIds as string}
                />
              </Box>
              <Box className={classes.formField}>
                <Divider />
              </Box>
              <Box className={classes.formField}>
                <DateField
                  label={t('Start date')}
                  value={values.startDate}
                  onChange={(newValue) => {
                    const startDate: keyof ExportHistorySchema = 'startDate'
                    setFieldValue(startDate, newValue)
                  }}
                  renderInputProps={{
                    error: !!errors?.startDate,
                    helperText: <>{errors?.startDate}</>,
                  }}
                  minDate={minDateValue}
                  maxDate={maxDateValue}
                />
              </Box>
              <Box className={classes.formField}>
                <DateField
                  label={t('End date')}
                  value={values.endDate}
                  onChange={(newValue) => {
                    const endDate: keyof ExportHistorySchema = 'endDate'
                    setFieldValue(endDate, newValue)
                  }}
                  renderInputProps={{
                    error: !!errors?.endDate,
                    helperText: errors?.endDate as React.ReactNode,
                  }}
                  minDate={minDateValue}
                  maxDate={maxDateValue}
                />
              </Box>
              <Grid container direction="column" spacing={2}>
                <ExportModalErrorAlerts errorKeys={errorKeys} />
              </Grid>
            </Box>
          </Box>
        </form>
      }
      footer={<ExportModalFooter onClose={onClose} disabled={!isValid} />}
    />
  )
}
