import {Box, Typography} from '@mui/material'
import type {FlowTransferType} from '@nufi/wallet-flow'
import type {SolanaTxInstructions} from '@nufi/wallet-solana'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'

import {FormattedFlowInstruction} from 'src/wallet/flow/public/ui'

import type {TxHistoryBlockchain} from '../../../blockchainTypes'
import {txHistoryBlockchains} from '../../../blockchainTypes'
import {InternalLink, QueryGuard} from '../../../components'
import {routeTo} from '../../../router'
import {useAssetDetailRouteOptions} from '../../../router/portfolio'
import {safeAssertUnreachable} from '../../../utils/assertion'
import {isBlockchainSubset} from '../../../utils/blockchainGuards'
import type {AccountId, TokenId} from '../../../wallet'
import {
  useGetTokenMetadata,
  useGetPaginatedTransactionHistory,
} from '../../../wallet'
import {isEvmBlockchain} from '../../../wallet/evm'
import {
  AssetDetailAccountsLayout,
  AssetDetailHistory,
  TransactionHistoryNotSupportedForBlockchain,
} from '../assetDetail'
import {AssetDetailAccountsList} from '../assetDetail/AccountsList'
import {FormattedCardanoTxType} from '../cardano/FormattedTxType'
import {FormattedEvmTxType} from '../evm/FormattedTxType'
import {FormattedSolanaInstruction} from '../solana/FormattedSolanaInstruction'

type CommonTransactionHistoryProps = {
  accountId: AccountId
  blockchain: TxHistoryBlockchain
}

export const FormattedTxType = ({
  txType,
  blockchain,
}: {
  txType: string
  blockchain: TxHistoryBlockchain
}) => {
  switch (blockchain) {
    case 'solana':
      return (
        <FormattedSolanaInstruction
          txInstruction={txType as typeof SolanaTxInstructions.type}
        />
      )
    case 'cardano':
      return <FormattedCardanoTxType txType={txType} />
    case 'flow':
      return (
        <FormattedFlowInstruction
          txInstruction={txType as typeof FlowTransferType.type}
        />
      )
    default: {
      if (isEvmBlockchain(blockchain)) {
        return <FormattedEvmTxType txType={txType} />
      }
      return safeAssertUnreachable(blockchain)
    }
  }
}

const TransactionHistory = ({
  accountId,
  tokenId,
  blockchain,
}: CommonTransactionHistoryProps & {
  tokenId: TokenId | null
}) => {
  const {isLoading, error, pageData, hasNextPage, onNextPage} =
    useGetPaginatedTransactionHistory(blockchain, accountId, tokenId)

  return (
    <AssetDetailHistory
      isLoading={isLoading}
      {...{error}}
      blockchain={blockchain}
      tokenId={tokenId}
      pagingParams={{hasNextPage, onNextPage}}
      transactions={pageData}
      transactionType={(txType: string) => (
        <FormattedTxType txType={txType} blockchain={blockchain} />
      )}
    />
  )
}

/**
 * Needed as a temporary solution for compressed NFTs on Solana, which do not support single-NFT history yet. (ADLT-2553)
 */
const _TmpSolanaCNftExplanation = ({accountId}: {accountId: AccountId}) => {
  const history = useHistory()
  const {t} = useTranslation()
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      text-align="center"
      height="100%"
    >
      <Typography
        variant="h5"
        justifyContent="center"
        flexWrap="wrap"
        display="flex"
      >
        {t('Transfers of this compressed NFT can be found in the')}&nbsp;
        <InternalLink
          title={t("owner account's full history")}
          onClick={() =>
            history.push(
              routeTo.portfolio.assets
                .blockchain('solana')
                .detail.account(accountId)
                .tab('history').index,
            )
          }
        />
      </Typography>
    </Box>
  )
}

const _TokenTransactionHistory = ({
  accountId,
  tokenId,
  blockchain,
}: CommonTransactionHistoryProps & {
  tokenId: TokenId
}) => {
  const tokenMetadata = useGetTokenMetadata(tokenId, blockchain)
  return (
    <QueryGuard {...tokenMetadata}>
      {(data) => {
        if (
          blockchain === 'solana' &&
          data.blockchain === 'solana' &&
          data.nftInfo?.type === 'compressed'
        ) {
          return <_TmpSolanaCNftExplanation accountId={accountId} />
        }
        return (
          <TransactionHistory
            {...{
              accountId,
              tokenId,
              blockchain,
            }}
          />
        )
      }}
    </QueryGuard>
  )
}

const _NativeTransactionHistory = (props: CommonTransactionHistoryProps) => (
  <TransactionHistory {...{...props, tokenId: null}} />
)

type AssetDetailAccountsProps = {
  isEditable?: boolean
  withTopMargin?: boolean
  onAccountChange: (accountId: AccountId) => void
}

export const AssetDetailAccounts = ({
  isEditable = true,
  withTopMargin = false,
  onAccountChange,
}: AssetDetailAccountsProps) => {
  const {tokenId, blockchain} = useAssetDetailRouteOptions()
  return (
    <AssetDetailAccountsLayout
      renderAccounts={() => (
        <AssetDetailAccountsList
          blockchain={blockchain}
          tokenId={tokenId}
          isEditable={isEditable}
          withTopMargin={withTopMargin}
          onAccountChange={onAccountChange}
        />
      )}
      renderHistory={(selectedAccountId) => {
        const accountId = selectedAccountId
        if (!isBlockchainSubset(blockchain, txHistoryBlockchains)) {
          return (
            <TransactionHistoryNotSupportedForBlockchain
              blockchain={blockchain}
              accountId={accountId}
            />
          )
        }

        return tokenId ? (
          <_TokenTransactionHistory
            {...{
              blockchain,
              tokenId,
              accountId,
            }}
          />
        ) : (
          <_NativeTransactionHistory
            {...{
              blockchain,
              accountId,
            }}
          />
        )
      }}
    />
  )
}
