import type {FlowAccountInfo} from '@nufi/wallet-flow'

import {useBalanceBiggerThanFeeValidation, useSummarySchema} from '../schemas'

type SetupStakeSummarySchemaArgs = {
  accounts: Array<FlowAccountInfo>
}

type SetupStakeSummarySchema = {
  password: string
  accountId: string
}

const _useSetupStakeSummarySchema = ({
  accounts,
}: SetupStakeSummarySchemaArgs) => {
  const summarySchemaValidation = useSummarySchema({accounts})
  const balanceBiggerThanFeeValidation = useBalanceBiggerThanFeeValidation({
    accounts,
    txType: 'setupStakingCollection',
  })
  return {
    password: summarySchemaValidation.password,
    accountId: balanceBiggerThanFeeValidation,
  }
}

// ensure consistency of keys / values on TS level
export const useSetupStakeSummarySchema: (
  ...args: Parameters<typeof _useSetupStakeSummarySchema>
) => {
  [k in keyof SetupStakeSummarySchema]: ReturnType<
    typeof _useSetupStakeSummarySchema
  >[k]
} = _useSetupStakeSummarySchema
