/* eslint-disable @typescript-eslint/no-explicit-any */

export const id = <T>(x: T) => x

export function believeMeIs<T>(x: any): asserts x is T {
  // empty
}

// https://github.com/Microsoft/TypeScript/issues/27024#issuecomment-420227672
export type Equals<T, S> = [T] extends [S]
  ? [S] extends [T]
    ? true
    : false
  : false

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const typeAssert = <_ extends true>() => undefined

type ValueOf<T> = T[keyof T]
type Entries<T> = [keyof T, ValueOf<T>][]
export function objectEntries<T extends object>(obj: T): Entries<T> {
  return Object.entries(obj) as Entries<T>
}

export function objectKeys<T extends object>(obj: T): (keyof T)[] {
  return Object.keys(obj) as (keyof T)[]
}
