import type {SyntheticEvent} from 'react'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {useGetExchangeAssetsDetails} from 'src/features/exchange/application'
import type {ExchangeAssetsDetails} from 'src/features/exchange/domain'

import type {ButtonProps} from '../../components'
import {Button, Icon, TextButton} from '../../components'
import type {Blockchain, TokenId} from '../../types'
import {useGetTokenMetadata} from '../../wallet'
import {isEvmBlockchain} from '../../wallet/evm'

import {findMatchingTokenSwapAsset} from './blockchains/crossBlockchain'
import {blockchainToSwapAsset} from './constants'

type ButtonVariant = 'primary' | 'secondary' | 'contained'

type TargetAssetConf = {blockchain: Blockchain; tokenId?: TokenId}

type OpenExchangeButtonProps = {
  variant: ButtonVariant | 'text'
  // We explicitly require "none" so that there is a lower change of forgetting
  // to pass a value where needed.
  targetAsset: TargetAssetConf | 'none'
  onOpen: (e: SyntheticEvent) => void
  label?: string
}

const buttonVariantProps: {
  [key in ButtonVariant]: {
    variant: ButtonProps['variant']
    color: ButtonProps['color']
    size: ButtonProps['size']
  }
} = {
  primary: {
    variant: 'outlined',
    color: 'primary',
    size: 'medium',
  },
  secondary: {
    variant: 'outlined',
    color: 'default',
    size: 'large',
  },
  contained: {
    variant: 'contained',
    color: 'primary',
    size: 'medium',
  },
}

const WithIsButtonEnabledForAsset = ({
  children,
  targetAsset,
}: {
  children: (enabled: boolean) => React.ReactElement
  targetAsset: TargetAssetConf
}) => {
  const exchangeAssetsDetailQuery = useGetExchangeAssetsDetails()

  // Once loaded this will re-run
  if (exchangeAssetsDetailQuery.data == null) return children(false)

  const assetBlockchainSupported =
    exchangeAssetsDetailQuery.data[
      blockchainToSwapAsset(targetAsset.blockchain)
    ] != null

  // Native asset case
  if (targetAsset.tokenId == null) return children(assetBlockchainSupported)

  return (
    <WithIsButtonEnabledForTokenAsset
      targetBlockchain={targetAsset.blockchain}
      targetTokenId={targetAsset.tokenId}
      exchangeAssetDetails={exchangeAssetsDetailQuery.data}
      children={children}
    />
  )
}

const WithIsButtonEnabledForTokenAsset = ({
  children,
  targetBlockchain,
  targetTokenId,
  exchangeAssetDetails,
}: {
  children: (enabled: boolean) => React.ReactElement
  targetBlockchain: Blockchain
  targetTokenId: TokenId
  exchangeAssetDetails: ExchangeAssetsDetails
}) => {
  const tokenMetadata = useGetTokenMetadata(targetTokenId, targetBlockchain)

  const assetBlockchainSupported =
    exchangeAssetDetails[blockchainToSwapAsset(targetBlockchain)] != null

  if (isEvmBlockchain(targetBlockchain) || targetBlockchain === 'solana') {
    // Once loaded this will re-run
    if (!tokenMetadata.data) return children(false)

    const targetSwapAsset = findMatchingTokenSwapAsset({
      exchangeAssets: exchangeAssetDetails,
      tokenMetadata: tokenMetadata.data,
    })

    return children(targetSwapAsset?.blockchain === targetBlockchain)
  }

  // In case the blockchain is not supported by changelly we also consider its tokens
  // not being supported. This is for example the case of Flow, but we do not want to hardcode it
  // so that it can start working immediately once changelly fixes it.
  if (!assetBlockchainSupported) return children(false)
  // We consider all non-evm tokens supported even though we cannot reliably check that, given
  // the "ticker" field is only reasonable field that we can check for. We are aware that user
  // may have "malicious" token with the same ticker that is a ticker of some changelly
  // asset and if creating such a swap the funds could be lost forever. We allow the exchange button to be
  // enabled despite that, because in the actual Exchange modal, we fallback to native asset for tokens
  // that cannot be reliably matched to changelly assets.
  return children(true)
}

const WithIsButtonEnabled = ({
  children,
  targetAsset,
}: {
  children: (enabled: boolean) => React.ReactElement
  targetAsset: OpenExchangeButtonProps['targetAsset']
}) => {
  if (targetAsset === 'none') return children(true)
  // Extracted to a separate component so that we can call "useGetTokenMetadata" conditionally
  return <WithIsButtonEnabledForAsset {...{targetAsset, children}} />
}

export const OpenExchangeButton = ({
  variant,
  targetAsset,
  onOpen,
  label,
}: OpenExchangeButtonProps) => {
  const {t} = useTranslation()

  return (
    <WithIsButtonEnabled {...{targetAsset}}>
      {(enabled) => {
        const disabled = !enabled
        const RenderedButton =
          variant === 'text' ? (
            <TextButton
              {...{disabled}}
              color="textSecondary"
              fontWeight="medium"
              onClick={onOpen}
              label={label || t('Exchange')}
            />
          ) : (
            <Button
              {...{disabled}}
              onClick={onOpen}
              startIcon={<Icon type="exchangeIcon" exactSize={24} />}
              textTransform="none"
              {...buttonVariantProps[variant]}
            >
              {label || t('Exchange')}
            </Button>
          )
        return RenderedButton
      }}
    </WithIsButtonEnabled>
  )
}
