import type {
  CardanoTokenMetadata,
  CardanoTokenName,
  CardanoTokenTicker,
  CardanoAllAvailableAccountsResponse,
  CardanoAccountInfo,
  CardanoTokenPolicyId,
} from '@nufi/wallet-cardano'
import {formatAssetNameHex} from '@nufi/wallet-cardano'

import type {
  AllAvailableAccountsResponse,
  Blockchain,
  HexString,
} from '../../../types'
import {getCardano} from '../../cardanoManagers'

export const QUERY_KEY_PREFIX: Blockchain = 'cardano'

export const getAccountInfoForDiscoveryResponse = async (
  response: CardanoAllAvailableAccountsResponse,
): Promise<AllAvailableAccountsResponse<CardanoAccountInfo>> => {
  if ('canNotAddAccountReason' in response) return response
  return {
    accounts: await getCardano().getAccountInfos(response.accounts),
  }
}

const PREPROD_ASSETS = {
  tWRT: {
    policyId:
      '35b3c3572ee71ec7d0ba8c006eab0fa70fc76d09b15488650106730a' as CardanoTokenPolicyId,
    assetNameHex: '74575254' as HexString,
  },
} as const

const MAINNET_ASSETS = {
  WRT: {
    policyId:
      'c0ee29a85b13209423b10447d3c2e6a50641a15c57770e27cb9d5073' as CardanoTokenPolicyId,
    assetNameHex: '57696e67526964657273' as HexString,
  },
} as const

// this helper is meant as a quick-fix to flag suspicious tokens in WR DEX
// if we choose to offboard WR DEX from NuFi UI this can probably be removed
export const sanitizeFakeTokenMetadata = (
  meta: CardanoTokenMetadata,
): CardanoTokenMetadata => {
  if (
    formatAssetNameHex(meta.assetNameHex).toUpperCase() === 'WRT' &&
    ![MAINNET_ASSETS.WRT.policyId, PREPROD_ASSETS.tWRT.policyId].includes(
      meta.policyId as unknown as CardanoTokenPolicyId,
    )
  ) {
    return {
      ...meta,
      name: 'WRT [FAKE!]' as CardanoTokenName,
      ticker: 'WRT [FAKE!]' as CardanoTokenTicker,
    }
  }

  return meta
}
