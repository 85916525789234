import React from 'react'
import {useTranslation} from 'react-i18next'
import {Switch, Route} from 'react-router-dom'

import {useSettings} from 'src/features/profile/application'

import {useProfileInfos} from '../../appStorage'
import {QueryGuard, WebExtensionGuard} from '../../components'
import {routeTo} from '../../router'
import {useCurrentLoginInfo} from '../../store/auth'
import {useProfileSyncState} from '../../store/profileSync'
import {getMnemonicLength} from '../../wallet'

import ActivateMnemonicPage from './ActivateMnemonic'
import ChangeCurrency from './ChangeCurrency'
import ChangeProfilePassword from './ChangePassword'
import DeleteProfile from './DeleteProfile'
import ExportProfile from './ExportProfile'
import {GridPlusTroubleshooting} from './GridPlusTroubleshooting'
import ManageBlockchains from './ManageBlockchains'
import {ProfileSyncStatus} from './ProfileSyncStatus'
import RecoveryPhrase, {ActivateRecoveryPhrase} from './RecoveryPhrase'
import RenameProfile from './RenameProfile'
import {SectionInfo, SettingsLayout, WalletSettingsLoading} from './utils'
import Web3Auth2Factor from './Web3Auth2Factor'

export default function Settings() {
  return (
    <Switch>
      <Route path={routeTo.settings.index} exact component={SettingsList} />
      <Route
        path={routeTo.settings.activateMnemonic}
        component={ActivateMnemonicPage}
      />
    </Switch>
  )
}

function SettingsList() {
  const {t} = useTranslation()
  const profileInfosQuery = useProfileInfos()
  const settings = useSettings()
  const profileSyncState = useProfileSyncState()
  const loginInfo = useCurrentLoginInfo()

  const sectionEnabled = {
    changePassword: loginInfo.loginType === 'password',
    manage2fa:
      loginInfo.loginType === 'web3Auth' &&
      loginInfo.user.currentLoginVersion === 'v1',
    showMnemonic:
      (loginInfo.loginType === 'password' && settings.isMnemonicActivated) ||
      loginInfo.loginType === 'web3Auth',
    exportWallet: loginInfo.loginType === 'password',
    activateMnemonic:
      loginInfo.loginType === 'password' && !settings.isMnemonicActivated,
    deleteProfile: loginInfo.loginType === 'password',
  } as const

  return (
    <>
      <WebExtensionGuard load={() => import('./extension/ExtensionSettings')} />
      <SettingsLayout
        left={<SectionInfo label={t('Default currency')} />}
        right={<ChangeCurrency />}
      />
      <SettingsLayout
        left={<SectionInfo label={t('Rename wallet')} />}
        right={
          loginInfo.loginType === 'password' && (
            <QueryGuard
              {...profileInfosQuery}
              LoadingElement={<WalletSettingsLoading />}
            >
              {(profiles) => <RenameProfile profiles={profiles} />}
            </QueryGuard>
          )
        }
      />
      <SettingsLayout
        left={
          <SectionInfo
            label={t('Manage blockchains')}
            description={t(
              'Choose which blockchains you would like to see in your wallet.',
            )}
          />
        }
        right={<ManageBlockchains />}
      />
      {sectionEnabled['changePassword'] && (
        <SettingsLayout
          left={
            <SectionInfo
              label={t('Security')}
              description={t('Protect your wallet with a strong password.')}
            />
          }
          right={<ChangeProfilePassword />}
        />
      )}
      {sectionEnabled['manage2fa'] && (
        <SettingsLayout
          left={
            <SectionInfo
              label={t('Security')}
              description={t('Two-Factor Authentication (2FA)')}
            />
          }
          right={<Web3Auth2Factor />}
        />
      )}
      <SettingsLayout
        left={
          <SectionInfo
            label={t('Wallet sync status')}
            description={t(
              'Wallet accounts and settings are synchronized across devices.',
            )}
          />
        }
        right={<ProfileSyncStatus {...profileSyncState} />}
      />
      {sectionEnabled['exportWallet'] && (
        <SettingsLayout
          left={
            <SectionInfo
              label={t('Export wallet')}
              description={t(
                'Export your wallet settings and data for transfer to other devices.',
              )}
            />
          }
          right={<ExportProfile />}
        />
      )}
      {sectionEnabled['showMnemonic'] && (
        <SettingsLayout
          left={
            <SectionInfo
              label={t('Recovery phrase (mnemonic)')}
              description={t('write_down_secret_phrase', {
                length: getMnemonicLength(),
              })}
            />
          }
          right={
            <RecoveryPhrase
              skipPasswordVerification={loginInfo.loginType === 'web3Auth'}
            />
          }
        />
      )}
      {sectionEnabled['activateMnemonic'] && (
        <SettingsLayout
          left={
            <SectionInfo
              label={t('Activate NuFi recovery phrase (mnemonic)')}
              description={t(
                'Unlock NuFi’s full potential by activating the NuFi recovery phrase. You will be able to add non-hardware wallet accounts derived from this phrase.',
              )}
            />
          }
          right={<ActivateRecoveryPhrase />}
        />
      )}
      {sectionEnabled['deleteProfile'] && (
        <SettingsLayout
          left={
            <SectionInfo
              label={t('Delete wallet')}
              description={t(
                'Permanently delete your NuFi wallet from this device.',
              )}
            />
          }
          right={<DeleteProfile />}
        />
      )}
      <SettingsLayout
        left={
          <SectionInfo
            label={t('GridPlus troubleshooting')}
            description={t(
              'Troubleshoot issues with your Lattice1 hardware wallet.',
            )}
          />
        }
        right={<GridPlusTroubleshooting />}
      />
    </>
  )
}
