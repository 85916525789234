import _ from 'lodash'
import qs from 'qs'

import type {PaginationQuery, FilterStructure, FiltersKeys} from './types'

// https://docs.strapi.io/developer-docs/latest/developer-resources/database-apis-reference/rest/filtering-locale-publication.html#filtering
// strapi recommends qs for parsing nested objects to create more complex queries
// consider replacing current 'query-string' library with qs
// 'query-string' doesn't support nesting
export const generateStrapiQueryString = <TDataSchema>(
  filters: {[key in keyof Partial<TDataSchema>]: {[key: string]: unknown}},
  populate: (keyof Partial<TDataSchema>)[],
  sort: string[],
  pagination?: PaginationQuery,
) =>
  qs.stringify(
    {
      filters,
      populate,
      sort,
      ...(pagination && {pagination}),
    },
    {
      encodeValuesOnly: true, // optional: prettify URL for debugging
    },
  )

export const createFilter = <
  TFilterType extends FilterStructure<string[], FiltersKeys, unknown>,
>(
  objectKeys: TFilterType['objectKeys'],
  filterKey: TFilterType['filterKey'],
  value: TFilterType['value'] | null,
) => {
  return value ? _.set<TFilterType>({}, [...objectKeys, filterKey], value) : {}
}

export const formatCmsMediaUrl = (mediaUrl: string, cmsUrl: string) => {
  // for local db and media storage the urls are not full urls pointing to the media,
  // only the path on the same server api e.g. "/uploads/xyz.png"
  // on the deployed version of the CMS with permanent storage it's ok to use just the media url
  return `${mediaUrl.startsWith('/') ? cmsUrl : ''}${mediaUrl}`
}
