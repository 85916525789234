import React from 'react'
import ContentLoader from 'react-content-loader'

import {SIDEBAR_WIDTH} from '../../../constants'
import {
  useResponsiveDimensions,
  ContentLoaderBackground,
} from '../../../utils/layoutUtils'

const _ExchangeHistoryListLoading = ({maxWidth}: {maxWidth: number}) => (
  <ContentLoader
    viewBox={`0 0 ${maxWidth} 120`}
    backgroundColor={ContentLoaderBackground()}
  >
    <rect x="0" y="8" rx="4" ry="4" width={maxWidth} height="44" />
    <rect x="0" y="76" rx="4" ry="4" width={maxWidth} height="44" />
  </ContentLoader>
)

export const ExchangeHistoryListLoading = () => {
  const dimensions = useResponsiveDimensions()
  return (
    <_ExchangeHistoryListLoading maxWidth={dimensions.width - SIDEBAR_WIDTH} />
  )
}
