import BigNumber from 'bignumber.js'

import {MINIMUM_FRACTION_DIGITS, getBlockchainDecimals} from '../constants'
import type {Blockchain} from '../types'

export const createNumberFormatter = (
  options: Intl.NumberFormatOptions | undefined,
) => {
  return new Intl.NumberFormat('en-US', {
    useGrouping: true,
    ...(options ?? {}),
  })
}

export const valueDividedByDecimals = (
  value: BigNumber,
  maximumFractionDigits: number,
) => value.dividedBy(new BigNumber(10).pow(maximumFractionDigits))

export const isGreaterThanMaxSafeInt = (value: BigNumber) =>
  value.isGreaterThan(Number.MAX_SAFE_INTEGER)

// As CSV format is comma specific, we can format the values with dots and spaces only
export const formatCSVNumber = (
  value: BigNumber,
  minimumFractionDigits: number,
  maximumFractionDigits: number,
) => {
  const numberFormatter = createNumberFormatter({
    minimumFractionDigits,
    maximumFractionDigits,
  })
  const v = valueDividedByDecimals(value, maximumFractionDigits)
  if (isGreaterThanMaxSafeInt(v)) {
    const fmt = {
      decimalSeparator: '.',
      groupSeparator: '',
    }
    return v.toFormat(maximumFractionDigits, fmt)
  } else
    return numberFormatter
      .formatToParts(v.toNumber())
      .map(({type, value}) => {
        switch (type) {
          case 'group':
            return ''
          case 'decimal':
            return '.'
          default:
            return value
        }
      })
      .reduce((string, part) => string + part)
}

export const formatCSVAmount = (value: BigNumber, blockchain: Blockchain) =>
  formatCSVNumber(
    value,
    MINIMUM_FRACTION_DIGITS,
    getBlockchainDecimals(blockchain),
  )

export const formatCSVTokenAmount = (value: BigNumber, decimals: number) =>
  formatCSVNumber(value, 0, decimals)
