import {Box, LinearProgress} from '@mui/material'
import {safeAssertUnreachable} from '@nufi/frontend-common'
import type {IdentitySecret} from '@nufi/wallet-common'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

import type {LocalProfileId, ProfileMetadata} from 'src/appStorage'
import loginFailImg from 'src/assets/images/onboarding/login-fail.png'
import loggingInImg from 'src/assets/images/onboarding/onboarding-logging-in.png'
import {MutationGuard} from 'src/components'
import {
  useMetamaskLogin,
  useSetMetamaskProfileLoggedIn,
} from 'src/features/login/application/metamask/common'
import {
  useCreateAndInitMainAppMetamaskProfile,
  useInitMetamaskMainAppProfile,
} from 'src/features/login/application/metamask/mainApp'
import {OnboardingContent} from 'src/features/login/ui'
import {useConnectingToMetamaskContent} from 'src/metamaskSnap/SnapSetup'
import type {Blockchain} from 'src/types'
import {broadcastLoginToOtherTabs} from 'src/utils/loginBroadcast'

import {Onboarding} from './onboarding'

type LoginScreens =
  | {type: 'connect-metamask'}
  | {
      type: 'log-into-existing-profile'
    }
  | {
      type: 'create-new-profile'
      identitySecret: IdentitySecret
      isProfileBackedUp: boolean
    }

export function LoginFlow({
  onCancel,
  blockchain,
}: {
  onCancel: () => void
  blockchain: Blockchain
}) {
  const metamaskLoginMutation = useMetamaskLogin()
  const initExistingMetamaskProfileMutation = useInitMetamaskMainAppProfile()

  const setMetamaskLoggedIn = useSetMetamaskProfileLoggedIn()

  const [step, setStep] = useState<LoginScreens>({type: 'connect-metamask'})

  const onLogin = async (profileId: LocalProfileId) => {
    await broadcastLoginToOtherTabs(profileId)
    setMetamaskLoggedIn()
  }

  const onLogIntoExistingProfile = async (
    profileId: LocalProfileId,
    identitySecret: IdentitySecret,
  ) => {
    try {
      await initExistingMetamaskProfileMutation.mutateAsync({
        profileId,
        identitySecret,
      })
      onLogin(profileId)
    } catch {
      // handled by MutationGuard
    }
  }

  useEffect(() => {
    const loginToMetamaskProfile = async () => {
      const metamaskResult = await metamaskLoginMutation.mutateAsyncSilent()

      if (!metamaskResult) return // handled by MutationGuard

      const {profileMeta, identitySecret, isProfileBackedUp} = metamaskResult

      if (profileMeta) {
        setStep({type: 'log-into-existing-profile'})
        await onLogIntoExistingProfile(
          profileMeta.localProfileId,
          identitySecret,
        )
      } else {
        setStep({
          type: 'create-new-profile',
          isProfileBackedUp,
          identitySecret,
        })
      }
    }
    loginToMetamaskProfile()
  }, [])

  switch (step.type) {
    case 'connect-metamask':
      return (
        <MutationGuard
          {...metamaskLoginMutation}
          ErrorElement={<LoggingIntoWalletError onClose={onCancel} />}
          LoadingElement={<ConnectingToMetamask />}
        />
      )
    case 'log-into-existing-profile':
      return (
        <MutationGuard
          {...initExistingMetamaskProfileMutation}
          ErrorElement={<LoggingIntoWalletError onClose={onCancel} />}
          LoadingElement={<LoggingIntoWallet />}
        />
      )
    case 'create-new-profile':
      return (
        <Onboarding
          blockchain={blockchain}
          onCancel={onCancel}
          skipTermsAndConditions={step.isProfileBackedUp}
          renderCreateProfileScreen={(SuccessScreen) => (
            <WithCreateAndInitMetamaskProfile
              identitySecret={step.identitySecret}
              onCloseError={onCancel}
            >
              {(data) => (
                <SuccessScreen
                  onContinue={() => onLogin(data.localProfileId)}
                  blockchain={blockchain}
                />
              )}
            </WithCreateAndInitMetamaskProfile>
          )}
        />
      )
    default:
      return safeAssertUnreachable(step)
  }
}

const WithCreateAndInitMetamaskProfile = ({
  identitySecret,
  onCloseError,
  children,
}: {
  identitySecret: IdentitySecret
  onCloseError: () => void
  children: (data: ProfileMetadata) => JSX.Element
}) => {
  const createAndInitMetamaskProfileMutation =
    useCreateAndInitMainAppMetamaskProfile()

  useEffect(() => {
    createAndInitMetamaskProfileMutation.mutateAsyncSilent({
      identitySecret,
    })
  }, [])

  return (
    <MutationGuard
      {...createAndInitMetamaskProfileMutation}
      data={createAndInitMetamaskProfileMutation.data}
      ErrorElement={<LoggingIntoWalletError onClose={onCloseError} />}
      LoadingElement={<LoggingIntoWallet />}
      children={children}
    />
  )
}

const ConnectingToMetamask = () => {
  const {LoaderElement, title} = useConnectingToMetamaskContent()

  return (
    <OnboardingContent title={title} description={null}>
      <LoaderElement />
    </OnboardingContent>
  )
}

const LoggingIntoWallet = () => {
  const {t} = useTranslation()

  return (
    <OnboardingContent
      title={t('Setting up your wallet')}
      imgSrc={loggingInImg}
    >
      <Box sx={{width: '80%'}}>
        <LinearProgress color="inherit" />
      </Box>
    </OnboardingContent>
  )
}

const LoggingIntoWalletError = ({onClose}: {onClose: () => void}) => {
  const {t} = useTranslation()

  return (
    <OnboardingContent
      title={t('onboarding_logging_in_fail_title')}
      imgSrc={loginFailImg}
      actions={{
        primary: {
          onClick: () => {
            location.reload()
          },
          children: t('Retry'),
        },
        secondary: {
          onClick: onClose,
          children: t('Go back'),
        },
      }}
      description={t('onboarding_logging_in_fail_description')}
    />
  )
}
