import type {StakeAccountId, AccountId} from '@nufi/wallet-common'
import {calculateTxFeeAmount} from '@nufi/wallet-solana'
import type {
  Lamports,
  SolanaTransaction,
  SolanaAccountInfo,
  SolanaAccountWithStakeAccounts,
  SolanaStakeAccountInfo,
  SolanaTxFeeParams,
} from '@nufi/wallet-solana'
import {PublicKey} from '@solana/web3.js'
import type {FormikProps} from 'formik'
import {Formik} from 'formik'
import React from 'react'

import {QueryGuard, StakingModalError} from '../../../../components'
import {useTrackTxSubmission} from '../../../../tracking'
import {assert} from '../../../../utils/assertion'
import {
  useGetTxFeeParams,
  useSubmitStakeInstruction,
  useGetStakeAccountsInfo,
  useSignUndelegate,
} from '../../../../wallet/solana'
import {ensureAccountById} from '../../../../wallet/utils/common'
import {
  useFormikOnSubmit,
  WithActiveScreenState,
  useActiveScreenState,
  SignTxFlow,
  useActiveSchema,
  UndelegateSubmitScreen,
} from '../../../transaction'
import {useSummaryDetailsSchema} from '../../common/schema'
import {DeviceReadyState, UndelegateModalHeader} from '../../common/utils'
import type {BaseUndelegateStakeSchema} from '../schema'
import {useStakeFeePayerSchema} from '../schema'
import {findStakeAccountAndParentById} from '../utils'

import UndelegateSummaryScreen from './UndelegateSummaryScreen'

type FormSchema = BaseUndelegateStakeSchema

export default function SolanaUndelegateModal({
  onClose,
  stakeAccountId,
}: {
  onClose: () => unknown
  stakeAccountId: StakeAccountId
}) {
  const stakeAccountsQuery = useGetStakeAccountsInfo()
  const txFeeParamsQuery = useGetTxFeeParams({
    affectedAccounts: [new PublicKey(stakeAccountId)],
  })

  return (
    <WithActiveScreenState initialScreen="summary">
      <QueryGuard
        {...stakeAccountsQuery}
        ErrorElement={<StakingModalError blockchain="solana" />}
        loadingVariant="centered"
      >
        {(accountsWithStakeInfo) => (
          <QueryGuard
            {...txFeeParamsQuery}
            ErrorElement={<StakingModalError blockchain="solana" />}
            loadingVariant="centered"
          >
            {(txFeeParams) => (
              <SolanaUndelegateForm
                {...{
                  onClose,
                  accountsWithStakeInfo,
                  txFeeParams,
                  stakeAccountId,
                }}
              />
            )}
          </QueryGuard>
        )}
      </QueryGuard>
    </WithActiveScreenState>
  )
}

type SolanaUndelegateFormProps = {
  onClose: () => unknown
  accountsWithStakeInfo: SolanaAccountWithStakeAccounts[]
  txFeeParams: SolanaTxFeeParams
  stakeAccountId: StakeAccountId
}

function SolanaUndelegateForm({
  onClose,
  accountsWithStakeInfo,
  txFeeParams,
  stakeAccountId,
}: SolanaUndelegateFormProps) {
  const undelegate = useSignUndelegate()
  const submit = useSubmitStakeInstruction()
  const {activeScreen, setActiveScreen} = useActiveScreenState()
  const summaryDetailsSchema = useSummaryDetailsSchema({
    accounts: accountsWithStakeInfo,
  })
  const {parentAccount, stakeAccount} = findStakeAccountAndParentById(
    accountsWithStakeInfo,
    stakeAccountId,
  )
  const txFeeAmount = calculateTxFeeAmount(txFeeParams)
  const accountId = parentAccount.id
  const account = ensureAccountById(accountsWithStakeInfo, accountId)
  const feePayerSchema = useStakeFeePayerSchema({account, txFee: txFeeAmount})

  const schema = useActiveSchema({
    details: {},
    summary: {
      ...summaryDetailsSchema,
      ...feePayerSchema,
    },
  })

  const initialValues: FormSchema = {
    accountId,
    password: '',
    hasSufficientFeeFunds: true,
  }

  const onTxSign = async () =>
    await undelegate.mutateAsyncSilent({
      accountId,
      stakeAccountKey: stakeAccount.publicKey,
      txFeeParams,
      isOwnerAuthorizedStaker: stakeAccount.isOwnerAuthorizedStaker,
    })

  const onTxSubmit = async (
    accountId: AccountId,
    signedTx: SolanaTransaction,
  ) => await submit.mutateAsyncSilent({signedTx, accountId})

  const onTxSignAndSubmit = async () => {
    const signedTx = await onTxSign()
    if (!signedTx) return
    setActiveScreen('submit')
    await onTxSubmit(accountId, signedTx)
  }

  const onSubmit = useFormikOnSubmit<FormSchema>({
    accounts: accountsWithStakeInfo,
    onTxSignAndSubmit,
  })

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={onSubmit}
    >
      {(formikProps) => (
        <SolanaUndelegateModalContent
          {...{
            formikProps,
            accounts: accountsWithStakeInfo,
            setActiveScreen,
            activeScreen,
            onClose,
            txFee: txFeeAmount,
            onTxSubmit,
            onTxSignAndSubmit,
            stakeAccount,
            accountId,
          }}
          submitProps={submit}
          signProps={undelegate}
        />
      )}
    </Formik>
  )
}

type SolanaUndelegateModalContentProps = {
  accounts: SolanaAccountInfo[]
  formikProps: FormikProps<FormSchema>
  submitProps: ReturnType<typeof useSubmitStakeInstruction>
  signProps: ReturnType<typeof useSignUndelegate>
  onClose: () => unknown
  txFee: Lamports
  onTxSubmit: (
    accountId: AccountId,
    signedTx: SolanaTransaction,
  ) => Promise<unknown>
  onTxSignAndSubmit: (values: FormSchema) => Promise<unknown>
  stakeAccount: SolanaStakeAccountInfo
  accountId: AccountId
}

function SolanaUndelegateModalContent({
  accounts,
  formikProps,
  submitProps,
  onClose,
  txFee,
  onTxSubmit,
  onTxSignAndSubmit,
  signProps,
  stakeAccount,
  accountId,
}: SolanaUndelegateModalContentProps) {
  const summary = {
    amount: stakeAccount.balance,
    fee: txFee,
    fromAccount: ensureAccountById(accounts, accountId),
    validatorName: stakeAccount.validator?.toString() || '',
  }

  const blockchain = 'solana'
  const ModalHeader = <UndelegateModalHeader {...{blockchain, onClose}} />

  useTrackTxSubmission(submitProps, {
    blockchain,
    provider: summary.fromAccount.cryptoProviderType,
    type: 'unstake',
    value: summary.amount.toNumber(),
  })

  return (
    <SignTxFlow
      {...{
        onClose,
        formikProps,
        ModalHeader,
        blockchain,
        signProps,
        submitProps,
        DeviceReadyState,
        onTxSignAndSubmit,
      }}
      onTxSubmit={onTxSubmit}
      initialScreen="summary"
      renderSummary={() => (
        <UndelegateSummaryScreen
          onBack={onClose}
          onSubmit={formikProps.handleSubmit}
          {...{summary, formikProps, onClose, blockchain}}
        />
      )}
      renderSubmit={(signAndSubmitUtils) => {
        const signedTransaction = signProps.data
        assert(!!signedTransaction)
        return (
          <UndelegateSubmitScreen
            accountId={formikProps.values.accountId as AccountId}
            onBack={signAndSubmitUtils.onSignAndSubmitGoBack}
            onRetry={() =>
              onTxSubmit(
                formikProps.values.accountId as AccountId,
                signedTransaction,
              )
            }
            {...{onClose, submitProps, blockchain, ModalHeader}}
          />
        )
      }}
    />
  )
}
