import {assert} from '@nufi/frontend-common'
import create from 'zustand'

import {
  getMinimalMockServices,
  getMockServices,
} from 'src/__tests__/storybook/MockServiceLocator'
import type {ProfileSettings} from 'src/appStorage/profileManager'
import logger from 'src/store/logger'

type SettingsState = {
  settings: ProfileSettings | null
  setSettings: (settings: ProfileSettings) => void
}

export const useSettingsStore = create<SettingsState>(
  logger<SettingsState>('SettingsStore:')((set) => ({
    settings: null,
    setSettings: (settings) => set({settings}),
  })),
)

export function getSettings(): ProfileSettings {
  const settings = useSettingsStore.getState().settings
  assert(settings != null)
  return settings
}

export function useSettings(): ProfileSettings {
  const {settings} = useSettingsStore()

  const mockServices = getMockServices()
  const minimalMockServices = getMinimalMockServices()

  if (mockServices?.loadSettings) return mockServices.loadSettings()
  if (minimalMockServices?.loadSettings)
    return minimalMockServices.loadSettings()

  assert(settings != null)
  return settings
}
