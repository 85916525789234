import BigNumber from 'bignumber.js'
import type {SwapAsset} from 'common'

import {changellyBlockchainToNufiBlockchain} from 'src/pages/exchange/constants'

import type {
  ExchangeAssetsDetails,
  ExchangeConditions,
  ExchangeApiSwap,
} from '../../domain'

import type {
  ExchangeConditionsResponse,
  ExchangeParamsResponse,
  CreateSwapResponse,
  ExchangeAssetsDetailsResponse,
} from './types'

export const formatChangellyTicker = (ticker: string) =>
  ticker.toUpperCase() as SwapAsset

export const parseExchangeAssetsDetails = (
  assetsDetailsResponse: ExchangeAssetsDetailsResponse,
): ExchangeAssetsDetails => {
  const parsedAssetsDetails = {} as ExchangeAssetsDetails
  assetsDetailsResponse.forEach((assetDetail) => {
    if (assetDetail.enabled) {
      const nufiBlockchain = changellyBlockchainToNufiBlockchain(
        assetDetail.blockchain,
      )
      parsedAssetsDetails[formatChangellyTicker(assetDetail.ticker)] = {
        ...assetDetail,
        blockchain: nufiBlockchain ?? assetDetail.blockchain,
      }
    }
  })
  return parsedAssetsDetails
}

export const parseExchangeConditions = (
  response: ExchangeConditionsResponse,
): ExchangeConditions => {
  if (!response || !response[0]) {
    throw new Error('Exchange amount response ill-formatted')
  }

  return {
    amountFrom: new BigNumber(response[0].amountFrom),
    amountTo: new BigNumber(response[0].amountTo),
    fee: new BigNumber(response[0].fee),
    networkFee: new BigNumber(response[0].networkFee),
    rate: new BigNumber(response[0].rate),
    fromAsset: response[0].from.toUpperCase() as SwapAsset,
    toAsset: response[0].to.toUpperCase() as SwapAsset,
  }
}

export const parseExchangeParams = (paramsResponse: ExchangeParamsResponse) => {
  if (!paramsResponse || !paramsResponse[0]) {
    throw new Error('Exchange parameter response ill-formatted')
  }

  return {
    minAmount: new BigNumber(paramsResponse[0].minAmountFloat),
    maxAmount: new BigNumber(paramsResponse[0].maxAmountFloat),
  }
}

export const parseCreateSwap = ({
  id,
  payinAddress,
  currencyFrom,
  currencyTo,
}: CreateSwapResponse) =>
  ({
    id,
    payinAddress,
    currencyFrom: currencyFrom.toUpperCase() as SwapAsset,
    currencyTo: currencyTo.toUpperCase() as SwapAsset,
  }) as ExchangeApiSwap
