import {decrypt, encrypt} from '@nufi/storage'
import type {
  KeyValueData,
  StoreManager,
  StoreManagerContent,
} from '@nufi/storage'
import type {SerializedProfile} from 'common'

import type {ProfileAccount, TokenImport, TokenVisibility} from '../../types'
import {createServiceLocator} from '../../utils/serviceLocator'
import type {ProfileMetadata} from '../types'

import type {
  ProfileData,
  ProfileDataEncryptionKey,
  ProfileSettings,
} from './types'
import {decryptSerializedProfile} from './utils'

export abstract class ProfileManager {
  private profileDataEncryptionKey: ProfileDataEncryptionKey | null = null

  constructor(
    public mnemonicStorageType: ProfileMetadata['mnemonicStorageType'],
    protected profileStoreManager: StoreManager<
      ProfileData,
      ProfileDataEncryptionKey
    >,
  ) {}

  /**
   * Subclasses extending this class should
   * open `profileStoreManager` and after that
   * call `super.setProfileDataEncryptionKey`.
   */
  // eslint-disable-next-line
  abstract init: (params: any) => Promise<void>

  // TODO: does this break if being arrow function?
  protected setProfileDataEncryptionKey(
    profileDataEncryptionKey: ProfileDataEncryptionKey,
  ) {
    this.profileDataEncryptionKey = profileDataEncryptionKey
  }

  abstract removeProfile: () => Promise<void>

  getProfileData = async (): Promise<ProfileData> =>
    await this.profileStoreManager.getContent()

  saveData = async (data: ProfileData): Promise<void> => {
    await this.profileStoreManager.setContent(data)
  }

  saveProfileAccounts = async (accounts: ProfileAccount[]): Promise<void> => {
    const {settings, tokenImports, tokenVisibilities} =
      await this.getProfileData()
    await this.profileStoreManager.setContent({
      accounts,
      settings,
      tokenImports,
      tokenVisibilities,
    })
  }

  saveProfileSettings = async (settings: ProfileSettings): Promise<void> => {
    const {accounts, tokenImports, tokenVisibilities} =
      await this.getProfileData()
    await this.profileStoreManager.setContent({
      accounts,
      settings,
      tokenImports,
      tokenVisibilities,
    })
  }

  saveProfileTokenImports = async (
    tokenImports: TokenImport[],
  ): Promise<void> => {
    const {accounts, settings, tokenVisibilities} = await this.getProfileData()
    await this.profileStoreManager.setContent({
      accounts,
      settings,
      tokenVisibilities,
      tokenImports,
    })
  }

  saveProfileTokensVisibility = async (
    tokenVisibilities: TokenVisibility[],
  ): Promise<void> => {
    const {accounts, settings, tokenImports} = await this.getProfileData()
    await this.profileStoreManager.setContent({
      accounts,
      settings,
      tokenImports,
      tokenVisibilities,
    })
  }

  getStoreContent = async (): Promise<StoreManagerContent<ProfileData>> =>
    await this.profileStoreManager.getStoreContent()

  migrateProfileContent = (
    contentToMigrate: StoreManagerContent<KeyValueData>,
  ): StoreManagerContent<ProfileData> =>
    this.profileStoreManager.migrateData(contentToMigrate)

  decryptSerializedProfile = async (
    data: SerializedProfile,
  ): Promise<StoreManagerContent<KeyValueData>> => {
    return decryptSerializedProfile(data, this.getProfileDataEncryptionKey())
  }

  encryptToSerializedProfile = async (
    content: StoreManagerContent<ProfileData>,
  ): Promise<SerializedProfile> => {
    const encryptedProfileData = await encrypt({
      value: content.data,
      password: this.getProfileDataEncryptionKey(),
    })

    return JSON.stringify({
      header: content.header,
      data: encryptedProfileData,
    }) as SerializedProfile
  }

  // Note: (richard)
  // These utils are currently used for saving pending/created transactions.
  // We should consider having an "abstracted" store for them, same as we have
  // for profile and mnemonic.
  // The current solution was used due to simplicity, and should be challenged soon.
  //
  // Alternative suggestion:
  // Make `profileDataEncryptionKey` accessible outside of the `profileManager` scope.
  experimental = {
    encryptCustomData: async (data: KeyValueData): Promise<string> => {
      return encrypt({
        password: this.getProfileDataEncryptionKey(),
        value: data,
      })
    },
    decryptCustomData: async (data: string): Promise<KeyValueData> => {
      return decrypt({
        password: this.getProfileDataEncryptionKey(),
        value: data,
      })
    },
  }

  private getProfileDataEncryptionKey = (): ProfileDataEncryptionKey => {
    if (this.profileDataEncryptionKey == null) {
      throw new Error('ProfileManager encryption key must be defined')
    }
    return this.profileDataEncryptionKey
  }
}

export const profileManagerLocator = createServiceLocator(
  (profileManager: ProfileManager) => profileManager,
)
