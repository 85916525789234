import CloseIcon from '@mui/icons-material/Close'
import {Alert, IconButton, Link, styled} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {Link as RouterLink} from 'react-router-dom'

import {createSingleKeySyncStorage} from 'src/features/storage/infrastructure'
import {routeTo} from 'src/router'

const storedHideSolanaStakingBannerKey = 'hide-nufi-solana-staking-fee-banner'

const promoBannerStorage = createSingleKeySyncStorage(
  storedHideSolanaStakingBannerKey,
)

export const setHasSeenPromoBanner = (value: boolean) => {
  promoBannerStorage.set(value)
}

export const hasSeenPromoBanner = () => {
  return promoBannerStorage.get()
}

export const PromoBanner = ({onClose}: {onClose: () => void}) => {
  const {t} = useTranslation()

  return (
    <Banner
      action={
        <IconButton
          aria-label="close"
          color="inherit"
          size="small"
          onClick={onClose}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      }
    >
      {t(
        'Enjoy 0% commission fees and extra MEV rewards by staking your Solana with our NuFi validator!',
      )}
      &nbsp;
      <Link
        color="inherit"
        sx={{cursor: 'pointer'}}
        component={RouterLink}
        to={routeTo.staking.myStaking.blockchain('solana').index}
      >
        {t('Go to Staking Solana')} &rarr;
      </Link>
    </Banner>
  )
}

export const PROMO_BANNER_HEIGHT = 48 // px

const Banner = styled(Alert)(({theme: {palette}}) => ({
  justifyContent: 'space-between',
  background: palette.primary.main,
  color: palette.primary.contrastText,
  borderRadius: 0,
  fontWeight: 500,
  height: PROMO_BANNER_HEIGHT,
  '& .MuiAlert-action': {
    marginLeft: 'unset',
  },
}))

Banner.defaultProps = {
  icon: <></>,
}
