import type {
  FormikConfig,
  FormikErrors,
  FormikProps,
  FormikValues,
} from 'formik'
import {Formik} from 'formik'
import _ from 'lodash'
import React, {useCallback, useEffect, useRef, useState} from 'react'

export type OnValidatedArgs<TValues> = {
  values: FormikProps<TValues>['values']
  isValid: boolean
  errors: FormikErrors<TValues>
}

type OnValidated<TValues> =
  | ((values: OnValidatedArgs<TValues>) => Promise<void>)
  | ((values: OnValidatedArgs<TValues>) => void)

function Form<TValues>({
  formikProps,
  children,
  onValidated,
  timeout,
}: {
  onValidated?: OnValidated<TValues>
  formikProps: FormikProps<TValues>
  children: (formikProps: FormikProps<TValues>) => JSX.Element
  timeout: number
}) {
  const firstLoad = useRef(true)

  const prevDebouncedFn = useRef<_.DebouncedFunc<
    (values: FormikProps<TValues>['values']) => Promise<void>
  > | null>(null)

  const debouncedValidate = useCallback(
    _.debounce(async (values: FormikProps<TValues>['values']) => {
      const errors = await formikProps.validateForm(values)
      onValidated && onValidated({values, isValid: _.isEmpty(errors), errors})
    }, timeout),
    [formikProps.validateForm, onValidated],
  )

  // Make sure that there are no "zombie" debounces
  useEffect(() => {
    if (prevDebouncedFn.current) {
      prevDebouncedFn.current.cancel()
    }
    prevDebouncedFn.current = debouncedValidate
  }, [debouncedValidate])

  useEffect(() => {
    if (!firstLoad.current && formikProps.isSubmitting) {
      // Cancel debounced function before submitting the form
      debouncedValidate.cancel()
    }
    firstLoad.current = false
  }, [formikProps.isSubmitting])

  // `debouncedValidate` is intentionally not included in the `deps` so that
  // if recreated (e.g. by providing new instance of `onValidated`) it is not run
  // unnecessarily. Otherwise this can lead to worse UX.
  useEffect(() => {
    debouncedValidate(formikProps.values)
  }, [formikProps.values])

  return children(formikProps)
}

type DebouncedFormikProps<TValues> = FormikConfig<TValues> & {
  children: (formikProps: FormikProps<TValues>) => JSX.Element
  onValidated?: OnValidated<TValues>
  timeout?: number
}

/**
 * Wrapper around Formik component that debounces the validation by 500ms
 *
@param onValidated called called after each debounced validation. The caller can e.g.
use this callback to fetch some extra data when chosen fields change. This can be done
race-conditions safely with the usage of `useSafeAsyncState`.

@param timeout debounce interval in ms, default = 100ms

@returns regular Formik component but with the validation disabled on onChange and onBlur events
and the validation instead being called in useEffect and debounced as a whole

Suggested usage:

* when not needing debounce at all use standard `<Formik />`

* when need to debounce (e.g. validation is costly), but is synchronous use `<DebouncedFormik />`

* when need to debounce (e.g. validation is costly), and need to calculate some async values from
  the data and store them in state use `<DebouncedFormik /> + useSafeAsyncState`.

Alternatives to avoid:

* debouncing "yup" functions:

  -> the functions can be called outside of the "yup" scope and their effect will be ignored

* field level debounce:

  -> ensuring that what is "seen" is also "submitted" becomes difficult (e.g. user change something
     and immediately submits)

  -> issues on data reset as data is not centralized
*/
export function DebouncedFormik<TValues extends FormikValues>({
  children,
  onValidated,
  timeout = 100,
  ...restProps
}: DebouncedFormikProps<TValues>) {
  return (
    <Formik validateOnChange={false} validateOnBlur={false} {...restProps}>
      {(formikProps) => (
        <Form {...{formikProps, onValidated, timeout}}>{children}</Form>
      )}
    </Formik>
  )
}

type State<TData> = {
  data: TData | null
  error: unknown | null
}

type Result<TValues, TData> = [
  State<TData>,
  (values: TValues, forceUpdate?: boolean) => Promise<State<TData>>,
]

/**
 * Avoids race conditions when storing the data in a single `useState`
 * but the data are controlled by running callbacks that can end in different order.
 * @param fn async function that fetched state data
 * @returns [state, safeRefetchAndUpdateState]
 * @todo ADD TESTS!!!
 */
export function useSafeAsyncState<TValues, TData>(
  fn: (values: TValues) => Promise<TData>,
): Result<TValues, TData> {
  const [state, setState] = useState<{
    data: TData | null
    error: unknown | null
  }>({
    data: null,
    error: null,
  })
  const prevInvocation = useRef<number | null>(Date.now())

  const safeRefetchAndUpdateState = useCallback(
    async (values: TValues, forceUpdate = false) => {
      const now = Date.now()
      prevInvocation.current = now

      let data: TData | null = null
      let error: unknown = null
      try {
        data = await fn(values)
      } catch (err) {
        error = err
      }

      // If some other call was fired meanwhile, ignore the result unless
      // the `forceUpdate` was passed
      if (prevInvocation.current === now || forceUpdate) {
        setState({error, data})
        return {error, data}
      }
      return state
    },
    [fn],
  )

  return [state, safeRefetchAndUpdateState]
}
