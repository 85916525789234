import {
  ErrorOutline as ErrorIcon,
  CheckCircle as SuccessIcon,
} from '@mui/icons-material'
import {Alert, Box, Grid, Typography} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import {useTranslation} from 'react-i18next'

import type {WeakUseMutationResult} from 'src/utils/mutation-utils'

import {
  ModalLayout,
  ModalFooter,
  Button,
  FooterLayout,
  InlineLoading,
  SafeCenterAligner,
} from '../../../../../../components'
import type {AppError} from '../../../../../../types'

type MutationProps = WeakUseMutationResult<void, unknown, unknown, unknown>

type ResultScreenProps = {
  onBack: () => void
  onClose: () => void
  onRetry: () => Promise<unknown>
  mutationProps: MutationProps
  ModalHeader: React.ReactNode
}

export const TokenImportResultScreen = ({
  ModalHeader,
  onBack,
  onClose,
  onRetry,
  mutationProps,
}: ResultScreenProps) => {
  const {t} = useTranslation()

  const {Body, Footer} = (() => {
    if (mutationProps.isSuccess && !mutationProps.error) {
      return {
        Body: <SuccessBody />,
        Footer: (
          <Box width="inherit" px={1} py={2}>
            <Button
              textTransform="none"
              onClick={onClose}
              color="primary"
              variant="outlined"
              fullWidth
            >
              {t('Close')}
            </Button>
          </Box>
        ),
      }
    }
    if (mutationProps.error) {
      return {
        Body: <ErrorBody {...{onRetry, error: mutationProps.error}} />,
        Footer: <ErrorFooter {...{onClose, onBack}} />,
      }
    }
    const loadingText = t('Importing tokens...')
    return {
      Body: <LoadingBody {...{loadingText}} />,
      Footer: <LoadingFooter {...{onClose, loadingText}} />,
    }
  })()

  return (
    <ModalLayout
      header={ModalHeader}
      body={<SafeCenterAligner fullWidth>{Body}</SafeCenterAligner>}
      footer={
        <ModalFooter hasDivider={!mutationProps.isPending}>
          {Footer}
        </ModalFooter>
      }
    />
  )
}

const SuccessBody = () => {
  const {t} = useTranslation()
  const classes = useStyles()

  return (
    <Grid
      spacing={{xs: 1, windowsZoomed: 2}}
      container
      alignItems="center"
      justifyContent="center"
      direction="column"
      className={classes.fullHeight}
    >
      <Grid item>
        <Box className={classes.successIconWrapper}>
          <Box>
            <SuccessIcon color="inherit" className={classes.icon} />
          </Box>
        </Box>
      </Grid>
      <Grid item>
        <Typography align="center" variant="h6" lineHeight={1}>
          <span className={classes.successText}>{t('Tokens imported.')}</span>
        </Typography>
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles((theme) => ({
  fullHeight: {
    height: '100%',
  },
  successIconWrapper: {
    color: theme.palette.success.main,
  },
  icon: {
    width: 40,
    height: 40,
    [theme.breakpoints.up('windowsZoomed')]: {
      width: 60,
      height: 60,
    },
  },
  successText: {
    fontSize: '90%',
    [theme.breakpoints.up('windowsZoomed')]: {
      fontSize: '100%',
    },
  },
  errorIconWrapper: {
    color: theme.palette.error.main,
  },
  errorButtonWrapper: {
    color: theme.palette.error.main,
  },
  errorMessage: {
    fontStyle: 'italic',
    maxHeight: 200,
    overflow: 'auto',
    wordBreak: 'break-word',
  },
}))

type ErrorBodyProps = {
  error: AppError
  onRetry: () => unknown
}

const ErrorBody = ({error, onRetry}: ErrorBodyProps) => {
  const {t} = useTranslation()
  const classes = useStyles()
  const castedError = error as Error

  return (
    <Box height="100%" display="flex" alignItems="center">
      <Grid spacing={2} container justifyContent="center">
        <Grid item xs={12}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            direction="column"
          >
            <Box className={classes.errorIconWrapper}>
              <ErrorIcon color="inherit" className={classes.icon} />
            </Box>
            <Typography variant={'h6'}>
              {t('Could not import tokens.')}
            </Typography>
          </Grid>
        </Grid>
        <Grid item className={classes.errorButtonWrapper}>
          <Button
            fullWidth
            textTransform="none"
            variant="outlined"
            color="inherit"
            onClick={onRetry}
          >
            {t('Retry')}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Alert severity={'error'}>
            <Typography>
              <Box component="span" fontWeight="fontWeightBold">
                {t('Please try again.')}
              </Box>
            </Typography>
            <br />
            <Typography>
              {t(
                'If the error persists, please contact our support & provide the error displayed below.',
              )}
            </Typography>
            <br />
            <Typography className={classes.errorMessage}>
              {JSON.stringify(castedError && castedError.message, null, 2)}
            </Typography>
          </Alert>
        </Grid>
      </Grid>
    </Box>
  )
}

const ErrorFooter = ({
  onBack,
  onClose,
}: {
  onBack: () => unknown
  onClose: () => unknown
}) => {
  const {t} = useTranslation()

  return (
    <FooterLayout
      leftBtnConf={{
        onClick: onBack,
        children: t('Back'),
      }}
      rightBtnConf={{
        onClick: onClose,
        children: t('Close'),
      }}
    />
  )
}

const LoadingBody = ({loadingText}: {loadingText: string}) => (
  <Grid
    spacing={2}
    container
    alignItems="center"
    justifyContent="center"
    direction="column"
    height="100%"
  >
    <Grid item>
      <Typography variant="h6">{loadingText}</Typography>
    </Grid>
    <Grid item>
      <InlineLoading />
    </Grid>
  </Grid>
)

const LoadingFooter = ({
  onClose,
  loadingText,
}: {
  onClose?: () => unknown
  loadingText: string
}) => {
  const {t} = useTranslation()

  return (
    <FooterLayout
      leftBtnConf={{
        disabled: true,
        children: t('Close'),
        onClick: onClose,
      }}
      rightBtnConf={{
        disabled: true,
        children: loadingText,
      }}
    />
  )
}
