import type {
  EvmAccountInfo,
  EvmAccountStoredData,
  EvmBlockchain,
} from '@nufi/wallet-evm'
import React from 'react'

import type {ConversionRates} from 'src/features/conversionRates/domain'
import type {TokenMetadata} from 'src/types'
import {useEvmAccountTokens} from 'src/wallet/evm/public/queries'

import {EvmAccountListCard} from '../account/accountListCards'

type EvmAccountCardProps = {
  storedAccountData: EvmAccountStoredData
  accountInfos: EvmAccountInfo<EvmBlockchain>[]
  tokensMetadata: TokenMetadata[]
  conversionRates?: ConversionRates
}

export const EvmAccountCard = ({
  storedAccountData,
  accountInfos,
  tokensMetadata,
  conversionRates,
}: EvmAccountCardProps) => {
  // These props are shared by evm accounts so it's OK to take them from the first account.
  const {id, address} = accountInfos[0]!
  const {tokens, partialLoadings, errors} = useEvmAccountTokens({id, address})

  return (
    <EvmAccountListCard
      {...{
        tokens,
        errors,
        partialLoadings,
        accountInfos,
        conversionRates,
        tokensMetadata,
        storedAccountData,
      }}
    />
  )
}
