import {Box, Portal} from '@mui/material'
import BigNumber from 'bignumber.js'
import Fuse from 'fuse.js'
import React, {useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Switch, Route, useHistory} from 'react-router-dom'

import type {AssetsSortBy, AssetRow, SortDirection} from 'src/features/assets'
import {useAssets} from 'src/features/assets'
import {isAssetVisibleWhenHidingZeroBalances} from 'src/features/assets/domain/accountAssets'
import {
  useEnabledExistingBlockchains,
  useSettings,
} from 'src/features/profile/application'
import {NoBlockchainsEnabledScreen} from 'src/features/walletStorage/ui/NoBlockchainsEnabledScreen'

import {useSortHandlers} from '../../components'
import {
  IS_BUY_SELL_ENABLED,
  FUSE_TOKEN_SEARCHING_THRESHOLD,
  IS_EXCHANGE_ENABLED,
} from '../../constants'
import {routeTo} from '../../router'
import {
  useRouteToBuySellAction,
  useAssetsRouteToExchange,
} from '../../router/portfolio'
import {useScrollableStyles} from '../../utils/layoutUtils'
import {BuySellModal, OpenBuySellButton} from '../buySell/BuySellModal'
import {ExchangeModal, OpenExchangeButton} from '../exchange'
import {ReceiveModal} from '../receive'
import {SendModal} from '../send/SendModal'

import {AssetListHeader} from './assetList/AssetListLayout'
import {AssetsList} from './assetList/AssetsList'
import {Filters} from './assetList/Filters'
import {
  usePortfolioPortalRef,
  usePortfolioPageContext,
} from './PortfolioContext'

const defaultSortDirection: SortDirection = 'desc'

export function Assets() {
  const {
    blockchain,
    setBlockchain,
    hideNfts,
    setHideNfts,
    hideZeroBalances,
    setHideZeroBalances,
  } = usePortfolioPageContext()

  const history = useHistory()
  const {t} = useTranslation()
  const {currency} = useSettings()
  const scrollableClasses = useScrollableStyles()
  const tabsPortalRef = usePortfolioPortalRef()

  const {setSortBy, sortBy, sortDirection} = useSortHandlers<AssetsSortBy>(
    'totalBalanceToCurrency',
    defaultSortDirection,
  )
  const useAssetsQuery = useAssets(currency, sortBy, sortDirection)
  const routeToBuySell = useRouteToBuySellAction()
  const routeToExchange = useAssetsRouteToExchange(blockchain)

  const [searchText, setSearchText] = useState('')

  const enabledBlockchains = useEnabledExistingBlockchains()

  const onClose = () => history.goBack()

  const {data, errors, partialLoadings} = useAssetsQuery

  const assetRowData: AssetRow[] = useMemo(() => {
    // filter by selected blockchain
    let _data =
      blockchain != null
        ? data.filter((d) => d.blockchain === blockchain)
        : data

    // filter nfts
    _data = hideNfts ? _data.filter((d) => !d.isNft) : _data

    // filter zero balances
    _data = hideZeroBalances
      ? _data.filter((d) =>
          isAssetVisibleWhenHidingZeroBalances({
            // Native assets should always be visible here.
            isAlwaysVisible: d.tokenMetadata == null,
            balance: d.availableBalance,
          }),
        )
      : _data

    _data = (() => {
      if (searchText === '') return _data
      const searchKey: keyof (typeof _data)[number] = 'searchingLabels'

      const fuse = new Fuse(_data, {
        keys: [searchKey],
        threshold: FUSE_TOKEN_SEARCHING_THRESHOLD,
      })

      return fuse.search(searchText).map((item) => item.item)
    })()

    return _data
  }, [data, hideNfts, blockchain, hideNfts, hideZeroBalances, searchText])

  const filteredAmount = useMemo(
    () =>
      assetRowData.reduce(
        (res, d) =>
          res.plus(
            d.totalBalanceToCurrency?.type === 'success'
              ? d.totalBalanceToCurrency.balance
              : new BigNumber(0),
          ),
        new BigNumber(0),
      ),
    [assetRowData],
  )

  return (
    <Box className={scrollableClasses.scrollableParent}>
      <Portal container={tabsPortalRef}>
        <Box display="flex" gap={2}>
          {IS_EXCHANGE_ENABLED && (
            <OpenExchangeButton
              label={t('Swap Crypto')}
              variant="primary"
              onOpen={routeToExchange}
              targetAsset="none"
            />
          )}
          {IS_BUY_SELL_ENABLED && (
            <OpenBuySellButton onClick={() => routeToBuySell()} />
          )}
        </Box>
      </Portal>

      <Filters
        filteredAmount={filteredAmount.toNumber()}
        search={{
          text: searchText,
          onTextChange: setSearchText,
        }}
        zeroBalances={{
          shouldHide: hideZeroBalances,
          toggleHide: () => setHideZeroBalances(!hideZeroBalances),
        }}
        nfts={{
          shouldHide: hideNfts,
          toggleHide: () => setHideNfts(!hideNfts),
        }}
        onBlockchainChange={setBlockchain}
        {...{blockchain}}
      />

      {enabledBlockchains.length === 0 ? (
        <NoBlockchainsEnabledScreen />
      ) : (
        <>
          <Box>
            <AssetListHeader
              {...{sortBy, setSortBy, sortDirection, defaultSortDirection}}
            />
          </Box>
          <Box className={scrollableClasses.scrollableList}>
            <AssetsList
              data={assetRowData}
              {...{blockchain, partialLoadings, errors}}
            />
          </Box>
        </>
      )}

      <ActionModals {...{onClose}} />
    </Box>
  )
}

type ActionModalsProps = {
  onClose: () => void
}

function ActionModals({onClose}: ActionModalsProps) {
  return (
    <Switch>
      <Route
        path={
          routeTo.portfolio.assets
            .blockchain(':blockchain')
            .action(':accountId').send
        }
      >
        <SendModal onClose={onClose} />
      </Route>
      <Route
        path={
          routeTo.portfolio.assets
            .blockchain(':blockchain')
            .action(':accountId').receive
        }
      >
        <ReceiveModal onClose={onClose} />
      </Route>
      <Route path={routeTo.portfolio.assets.blockchain(':blockchain').exchange}>
        <ExchangeModal onClose={onClose} />
      </Route>
      {IS_BUY_SELL_ENABLED && (
        <Route path={routeTo.portfolio.assets.buySell}>
          <BuySellModal />
        </Route>
      )}
    </Switch>
  )
}
