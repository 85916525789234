import React, {useEffect, useState} from 'react'
import {useHistory, useLocation} from 'react-router-dom'

import {AppOpenerLoading, WithAutoLogin} from 'src/components/appOpener'
import {AppOpenerProvider} from 'src/components/appOpener/viewModel'
import type {AppOpenerViewModel} from 'src/features/appOpener/application'
import {
  createAppOpenerViewModel,
  useAppOpenerStore,
} from 'src/features/appOpener/application'
import type {
  PlatformSpecificAppOpenerParams,
  OpenAppIntent,
  OpenLoggedInAppIntent,
} from 'src/features/appOpener/domain'
import {parseAppOpenerQueryString} from 'src/features/appOpener/domain'
import {assert} from 'src/utils/assertion'

type WithAppOpenerProps = {
  children: JSX.Element
  vm?: AppOpenerViewModel
}

export function WithAppOpener({
  children,
  vm = createAppOpenerViewModel({
    // We silence these errors so that user can still fallback to standard login
    ignoreAutoLoginError: true,
  }),
}: WithAppOpenerProps) {
  return (
    <AppOpenerProvider {...{vm}}>
      <WithLoadQueryParams>
        {(queryParams) => {
          if (queryParams) {
            const {intent} = queryParams

            // Not comparing `queryParams.intent.type` as then TS can not infer
            // discriminative union.
            return intent.type === 'openLoggedIn' ? (
              <WithAutoLogin {...queryParams}>{children}</WithAutoLogin>
            ) : (
              children
            )
          }
          return children
        }}
      </WithLoadQueryParams>
    </AppOpenerProvider>
  )
}

type OpenAppQueryParams = {
  platformParams: PlatformSpecificAppOpenerParams
  intent: OpenLoggedInAppIntent
}

type WithLoadQueryParamsProps = {
  children: (params: OpenAppQueryParams | null) => JSX.Element
}

function WithLoadQueryParams({children}: WithLoadQueryParamsProps) {
  const [isLoading, setIsLoading] = useState(true)
  const {setAction, setReferrerTabId} = useAppOpenerStore()

  const [intent, setIntent] = useState<OpenAppIntent | null>(null)
  const [platformParams, setPlatformParams] =
    useState<PlatformSpecificAppOpenerParams | null>(null)

  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    const {intent, platformParams} = parseAppOpenerQueryString(location.search)

    if (intent != null) {
      assert(platformParams != null, 'Platform params must be defined')

      if (intent.type === 'openLoggedIn') {
        intent.action && setAction(intent.action)
      }

      if (platformParams.platform === 'extension') {
        setReferrerTabId(platformParams.referrerTabId)
      }

      setIntent(intent)
      setPlatformParams(platformParams)

      history.replace({
        search: '',
      })
    }

    setIsLoading(false)
  }, [])

  if (isLoading) return <AppOpenerLoading />
  if (
    intent == null ||
    platformParams == null ||
    intent.type === 'openLoggedOut'
  )
    return children(null)
  return children({intent, platformParams})
}
