import {ArrowUpward, Launch} from '@mui/icons-material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import type {ButtonProps} from '../../components/visual/atoms/Button'
import {LabeledIconButton} from '../../components/visual/molecules/LabeledIconButton'

export const commonSellBuyButtonProps: ButtonProps = {
  fullWidth: true,
  textTransform: 'none',
  color: 'primary',
  variant: 'contained',
  target: '_blank',
}

export const ExternalSellButton = ({redirectUrl}: {redirectUrl: string}) => {
  const {t} = useTranslation()

  return (
    <LabeledIconButton
      buttonProps={{
        ...commonSellBuyButtonProps,
        href: redirectUrl,
      }}
      Label={t('Sell')}
      LabelIcon={<ArrowUpward />}
      EndIcon={<Launch color="inherit" fontSize="small" />}
    />
  )
}
