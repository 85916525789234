import type {SolanaAccountInfo, SolanaTokenMetadata} from '@nufi/wallet-solana'
import React from 'react'
import {useTranslation} from 'react-i18next'

import type {ConversionRates} from 'src/features/conversionRates/domain'

import {FormattedAsset, QueryGuard} from '../../../components'
import {
  useGetAccountTokens,
  useGetStakeAccountsInfo,
  sumStakedAmounts,
} from '../../../wallet/solana'
import {findAccountById} from '../../../wallet/utils/common'
import {AccountListCard} from '../account/accountListCards'
import {AccountItemLoading, LinearLoader} from '../account/Loadings'

type SolanaAccountCardProps = {
  accountInfo: SolanaAccountInfo
  tokensMetadata: SolanaTokenMetadata[]
  conversionRates?: ConversionRates
}

export function SolanaAccountCard({
  accountInfo,
  ...rest
}: SolanaAccountCardProps) {
  const tokensQuery = useGetAccountTokens(accountInfo)
  const stakeAccountsInfoQuery = useGetStakeAccountsInfo()
  const {t} = useTranslation()

  // we do not use QueryGuard here since we do not want to handle error state at this level
  if (tokensQuery.isLoading && !tokensQuery.error) {
    return <AccountItemLoading />
  }

  return (
    <AccountListCard
      tokens={tokensQuery.data || []}
      tokensError={tokensQuery.error}
      {...{accountInfo, ...rest}}
      StakingCol={
        <QueryGuard
          {...stakeAccountsInfoQuery}
          LoadingElement={<LinearLoader />}
        >
          {(accountsWithStakeInfo) => {
            const stakeAccounts =
              findAccountById(accountsWithStakeInfo, accountInfo.id)
                ?.stakeAccounts ?? []
            const stakedSum = sumStakedAmounts(stakeAccounts)
            return (
              <FormattedAsset
                amount={stakedSum}
                blockchain="solana"
                isSensitiveInformation
              />
            )
          }}
        </QueryGuard>
      }
      stakingColText={t('Staked & Locked')}
    />
  )
}
