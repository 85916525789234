import {Sentry, assert} from '@nufi/frontend-common'
import type {
  CardanoAccountInfo,
  CardanoPaymentAddress,
  CardanoTokenAmount,
  CardanoTransactionHash,
  Lovelaces,
} from '@nufi/wallet-cardano'

import {createApi} from 'src/dappConnector/api/cardano'
import type {Cbor} from 'src/dappConnector/api/cardano/types'
import {txStoreActions} from 'src/features/txSubmission/cardano/application'
import type {SignScreenInfo, SigningKind} from 'src/store/dappConnector'
import {invalidateQueryKeys} from 'src/utils/mutation-utils'
import {
  getCardano,
  buildTxBody,
  buildSignedTx,
  combineRawTxAndSignatures,
  accountsInvalidationKeys,
} from 'src/wallet/cardano'

type SignScreenContext = SignScreenInfo<SigningKind>

type SignAndSubmitCardanoTxWithConnectorArgs = {
  accountId: CardanoAccountInfo['id']
  addressTo: CardanoPaymentAddress
  amount?: Lovelaces
  tokenAmounts?: CardanoTokenAmount[]
  setSignScreenContext: (c: SignScreenContext | null) => void
}

export const signAndSubmitCardanoTxWithConnector = async ({
  accountId,
  addressTo,
  amount,
  tokenAmounts,
  setSignScreenContext,
}: SignAndSubmitCardanoTxWithConnectorArgs): Promise<CardanoTransactionHash> => {
  const account = getCardano().accountsStore.getAccount(accountId)
  const accountInfo = await getCardano().accountManager.getAccountInfo(account)
  const connector = createApi({
    connectorState: {
      getState: () => ({selectedAccount: accountInfo}),
    },
    uiHandlers: {
      confirmSign: (signingKind, signingData, getSignature, cancelSigning) => {
        assert(signingKind === 'sign-tx' && signingData.type === 'cardano')
        return new Promise((res, rej) => {
          setSignScreenContext({
            state: signingKind,
            data: signingData,
            onSign: async () => {
              const tx = await getSignature()
              return {
                onFinish: () => {
                  setSignScreenContext(null)
                  return res(tx)
                },
              }
            },
            onFailure: () => {
              setSignScreenContext(null)
              rej(cancelSigning())
            },
          })
        })
      },
      requestCreateCardanoCollateral: async () => false,
    },
  })
  const txPlan = await getCardano().accountManager.getTransferAdaTxPlan(
    account,
    addressTo,
    accountInfo,
    amount,
    tokenAmounts,
  )
  const tx = buildSignedTx(buildTxBody(txPlan), [])
  const cborWitnesses = await connector.signTx(
    tx.bytes.toString('hex') as Cbor<'transaction'>,
    true,
  )
  const signedTxBytes = combineRawTxAndSignatures(
    tx.bytes,
    Buffer.from(cborWitnesses, 'hex'),
  )

  const txHash = buildTxBody(txPlan).hash

  try {
    txStoreActions.registerTxCandidateWithTxPlan(accountInfo.id, txPlan)
    txStoreActions.pushTxCandidate(accountInfo.id, txHash)
    const result = await connector.submitTx(
      signedTxBytes.toString('hex') as Cbor<'transaction'>,
    )

    // No actual need to wait for this
    getCardano()
      .accountManager.confirmTransaction({
        bytes: tx.bytes,
        txHash,
      })
      .then(() => {
        invalidateQueryKeys(accountsInvalidationKeys)
        txStoreActions.updatePendingTx(txHash, {type: 'data', data: result})
      })
      .catch((error) => {
        Sentry.captureException(error)
        txStoreActions.updatePendingTx(txHash, {type: 'error', error})
      })

    return txHash
  } catch (error) {
    txStoreActions.updatePendingTx(txHash, {type: 'error', error})
    throw error
  }
}
