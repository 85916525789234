import BigNumber from 'bignumber.js'

import {useTrackTxSubmission} from 'src/tracking'
import {validateAssetDecimalsCount} from 'src/utils/form'

import {getBlockchainDecimals} from '../../../constants'
import {assert} from '../../../utils/assertion'
import type {
  CardanoAccountInfo,
  CardanoPaymentAddress,
  CardanoSignedTx,
  CardanoTransactionHash,
  CardanoTxPlan,
  Lovelaces,
} from '../../../wallet/cardano'
import {
  adaToLovelaces,
  getMaxSendableCardanoAmountImperative,
  useGetMaxSendableCardanoAmount,
  useGetTransferAssetsTxPlan,
  useSignTransaction,
  useSubmitTransaction,
} from '../../../wallet/cardano'
import {useCustomMetadata} from '../CustomMetadataContainer'
import type {
  ExchangeDeps,
  WithAmountFieldProperties,
  WithSignAndSubmitHandlers,
} from '../screens/common'
import {
  WithNoTokensGetAssetTokenData,
  WithSummaryTxFee,
} from '../screens/common'
import {useCommonAmountValidation} from '../screens/details/common'

type CustomMetadata =
  | {
      step: 'DetailsFeeEstimate'
      fee: Lovelaces | null
    }
  | {
      step: 'SubmitTx'
      txPlan: CardanoTxPlan
    }

// This address is used for amount/fee computation before getting the actual address from changelly.
// It was was taken from our (similar canoe ...) test mnemonic (just in case the address accidentally ends up in a tx)
const CARDANO_PAYIN_PLACEHOLDER_ADDRESS =
  'DdzFFzCqrht74Py82SWJVFYEVEwY6NkHb1q72iKJDAR4RMojWi6V49ZhK8hjf1ZuDYxGx4qABMUuzxgQeZZ2DnJo4Sg2hptKm77EviWS' as CardanoPaymentAddress

// Reuse as much as possible
const WithCardanoAmountFieldProperties: WithAmountFieldProperties = ({
  fromAccountInfo,
  values,
  fromToken,
  children,
}) => {
  const {setNonReactiveMetadata} = useCustomMetadata<CustomMetadata>()
  assert(fromAccountInfo == null || fromAccountInfo?.blockchain === 'cardano')
  const fetchAdaTxPlan = useGetTransferAssetsTxPlan()

  const maxSendableAmountQuery = useGetMaxSendableCardanoAmount(
    fromAccountInfo
      ? {
          accountInfo: fromAccountInfo,
          addressTo: CARDANO_PAYIN_PLACEHOLDER_ADDRESS,
        }
      : undefined,
    !!fromAccountInfo,
  )
  const maxSendableAmountQueryCommonProps = {
    isLoading: maxSendableAmountQuery.isLoading,
    error: maxSendableAmountQuery.error,
  }

  const maxDecimals = getBlockchainDecimals('cardano')

  const validate = useCommonAmountValidation(
    values,
    fromAccountInfo,
    fromToken,
    async (amount) => {
      if (!fromAccountInfo) return null

      if (!validateAssetDecimalsCount(amount, maxDecimals)) {
        return {hasError: true, maxDecimals}
      }

      try {
        const {maxAmount} =
          await getMaxSendableCardanoAmountImperative(fromAccountInfo)
        let txPlan: CardanoTxPlan | null = null

        try {
          txPlan = await fetchAdaTxPlan.mutateAsync({
            accountInfo: fromAccountInfo,
            addressTo: CARDANO_PAYIN_PLACEHOLDER_ADDRESS,
            amount: adaToLovelaces(amount),
          })
        } catch (error) {
          return {maxAmount, hasError: true}
        }

        const fee = txPlan.fee
        setNonReactiveMetadata('cardano', {
          step: 'DetailsFeeEstimate',
          fee: txPlan.fee,
        })
        return {fee, maxNativeAmount: maxAmount, maxDecimals}
      } catch (err) {
        return {
          maxNativeAmount: new BigNumber(0),
          fee: new BigNumber(Infinity),
          maxDecimals,
        }
      }
    },
  )

  const maxAmountValue = maxSendableAmountQuery.data?.maxAmount
  return children({
    validate,
    data: {
      maxAmount: {
        data: maxAmountValue
          ? {value: maxAmountValue, type: 'native'}
          : undefined,
        ...maxSendableAmountQueryCommonProps,
      },
      fee: {
        data: maxSendableAmountQuery.data?.fee,
        ...maxSendableAmountQueryCommonProps,
      },
    },
  })
}

const WithCardanoSignAndSubmitHandlers: WithSignAndSubmitHandlers<
  CardanoSignedTx,
  CardanoTransactionHash
> = ({fromAccountInfo, children, toAddress, amount}) => {
  const submit = useSubmitTransaction()
  const sign = useSignTransaction()
  const fetchAdaTxPlan = useGetTransferAssetsTxPlan()
  const {getNonReactiveMetadata, setNonReactiveMetadata} =
    useCustomMetadata<CustomMetadata>()

  useTrackTxSubmission(submit, {
    blockchain: 'cardano',
    provider: fromAccountInfo.cryptoProviderType,
    type: 'transfer_coin',
    value: adaToLovelaces(amount).toNumber(),
  })

  const onTxSign = async () => {
    const txPlan = await fetchAdaTxPlan.mutateAsync({
      accountInfo: fromAccountInfo as CardanoAccountInfo,
      addressTo: toAddress as CardanoPaymentAddress,
      amount: adaToLovelaces(amount),
    })
    setNonReactiveMetadata('cardano', {step: 'SubmitTx', txPlan})

    return await sign.mutateAsyncSilent({
      accountId: fromAccountInfo.id,
      txPlan,
    })
  }

  const onTxSubmit = async (signedTx: CardanoSignedTx) => {
    const meta = getNonReactiveMetadata('cardano')
    assert(meta.step === 'SubmitTx', 'Cardano Tx plan must be defined')
    return await submit.mutateAsyncSilent({
      signedTx,
      accountId: fromAccountInfo.id,
      txPlan: meta.txPlan,
    })
  }

  return children({
    signProps: {...sign, mutateAsyncSilent: onTxSign},
    submitProps: {...submit, mutateAsyncSilent: onTxSubmit},
  })
}

export const cardanoExchangeDeps: ExchangeDeps = {
  WithSignAndSubmitHandlers: WithCardanoSignAndSubmitHandlers,
  WithAmountFieldProperties: WithCardanoAmountFieldProperties,
  WithSummaryTxFee,
  WithGetAssetTokenData: WithNoTokensGetAssetTokenData,
}
