import {
  Paper,
  Box,
  Typography,
  Grid,
  Tab,
  Tabs,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import React, {useState, useContext, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'

import type {StakingBlockchain} from '../../blockchainTypes'
import {HelpDeskLink, Button, BlockchainIcon} from '../../components'
import {routeTo} from '../../router'
import {useBackgroundImageStyles} from '../../utils/layoutUtils'
import {useGetBlockchainName} from '../../utils/translations'
import {ReferenceElementContext} from '../utils'

import {useStakingBlockchains} from './utils'

type StakingListProps = {
  list: string[]
  Icon?: JSX.Element
  isBulletList?: boolean
}

export function StakingList({
  list,
  Icon,
  isBulletList = false,
}: StakingListProps) {
  const {ul} = useIsBulletListStyles()

  return (
    <List className={clsx(isBulletList && ul)}>
      {list.map((item, i) => (
        <StakingListItem
          key={i}
          text={item}
          Icon={Icon}
          isBulletList={isBulletList}
        />
      ))}
    </List>
  )
}

type CardListItemProps = {
  heading: string
  text: string
  bg: string
}

export function CardListItem({heading, text, bg}: CardListItemProps) {
  const {bgImage} = useBackgroundImageStyles({image: bg})

  return (
    <Grid item xs={6}>
      <Box height={306} className={bgImage} borderRadius="8px" p={3}>
        <Typography paragraph variant="h5" component="h4">
          {heading}
        </Typography>
        <Typography paragraph color="textSecondary" align="justify">
          {text}
        </Typography>
      </Box>
    </Grid>
  )
}

export function StakingListItem({
  text,
  Icon,
  isBulletList = false,
}: {
  text: string
  Icon?: JSX.Element
  isBulletList?: boolean
}) {
  const wrapInTypo = (text: string) => (
    <Typography
      color="textSecondary"
      variant="body1"
      component="span"
      children={text}
    />
  )

  return (
    <>
      {!isBulletList && Icon && (
        <ListItem disableGutters alignItems="flex-start">
          <ListItemIcon>{Icon}</ListItemIcon>
          <ListItemText primary={wrapInTypo(text)} />
        </ListItem>
      )}
      {isBulletList && <li>{wrapInTypo(text)}</li>}
    </>
  )
}

type ImageTextRowProps = {
  textHeader: string
  textSubHeader?: string
  children: React.ReactNode
  image: React.ReactNode
  imagePosition: 'left' | 'right'
}

export function ImageTextRow({
  textHeader,
  textSubHeader,
  image,
  imagePosition,
  children,
}: ImageTextRowProps) {
  const imageElement = (
    <Grid item xs={6}>
      <Box
        display="flex"
        justifyContent={imagePosition === 'left' ? 'flex-start' : 'flex-end'}
      >
        {image}
      </Box>
    </Grid>
  )
  const textElement = (
    <Grid item xs={6}>
      <Box
        pl={imagePosition === 'left' ? 3 : 0}
        pr={imagePosition === 'left' ? 0 : 3}
      >
        <Typography paragraph variant="h4" component="h2">
          {textHeader}
        </Typography>
        {textSubHeader && (
          <Typography
            paragraph
            variant="h6"
            component="h3"
            color="textSecondary"
          >
            {textSubHeader}
          </Typography>
        )}
        {children}
      </Box>
    </Grid>
  )

  return (
    <>
      <Grid container alignItems="center" justifyContent="center">
        {imagePosition === 'left' ? (
          <>
            {imageElement}
            {textElement}
          </>
        ) : (
          <>
            {textElement}
            {imageElement}
          </>
        )}
      </Grid>
      <Box my={10}>
        <Divider />
      </Box>
    </>
  )
}

type SupportedProtocolCardProps = {
  blockchain: StakingBlockchain
  xs: number
}

const muiFullGrid = 12 // default numbers of Grid columns in @MUI

function SupportedProtocolCard({blockchain, xs}: SupportedProtocolCardProps) {
  const classes = useStyles()
  const {t} = useTranslation()
  const history = useHistory()
  const getBlockchainName = useGetBlockchainName()

  return (
    <Grid item xs={xs} columns={muiFullGrid}>
      <Box display="flex" justifyContent="center">
        <BlockchainIcon blockchain={blockchain} exactSize={72} />
      </Box>
      <Paper elevation={0} className={classes.supportedProtocolCard}>
        <Typography variant="h5">{getBlockchainName(blockchain)}</Typography>
        <Typography variant="body1" color="textSecondary" paragraph>
          {blockchain}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => history.push(routeTo.staking.protocols)}
        >
          {t('Start Staking')}
        </Button>
      </Paper>
    </Grid>
  )
}

function EmptyProtocolCard({xs}: {xs: number}) {
  const classes = useStyles()

  return (
    <Grid item xs={xs} columns={muiFullGrid}>
      <Paper elevation={0} className={classes.comingSoonProtocolCard} />
    </Grid>
  )
}

// adjust Grid items width according to number of supported protocols
// toggles between 2 and 3 items in rows
// returns Array of Grid widths with the length of nItems
// f.e. 5 supported blockchains will result in [4,4,4,6,6] which results in
// Grid of 2 rows, first having 3 items, second 2 items
const createSupportedProtocolsGrid = (nItems: number) => {
  if (nItems === 1) return [muiFullGrid]

  switch (nItems % 3) {
    case 1:
      return [
        ...Array(nItems - 4 ?? 0).fill(muiFullGrid / 3),
        ...Array(4).fill(muiFullGrid / 2),
      ]
    case 2:
      return [
        ...Array(nItems - 2).fill(muiFullGrid / 3),
        ...Array(2).fill(muiFullGrid / 2),
      ]
    default:
      return Array(nItems).fill(muiFullGrid / 3)
  }
}

export function SupportedProtocols() {
  const classes = useStyles()
  const {t} = useTranslation()
  const stakingBlockchains = useStakingBlockchains()
  const items = stakingBlockchains.length
  const protocolsGridMap = createSupportedProtocolsGrid(items)

  return (
    <>
      <Box display="flex" justifyContent="center" pb={4} pt={2}>
        <Typography paragraph variant="h5">
          {t('We support popular protocols')}
        </Typography>
      </Box>
      <Grid container spacing={3}>
        {stakingBlockchains
          .concat()
          .sort()
          .map((blockchain, i) => (
            <SupportedProtocolCard
              key={blockchain}
              blockchain={blockchain}
              xs={protocolsGridMap[i]}
            />
          ))}
        {[
          ...Array(muiFullGrid / protocolsGridMap[protocolsGridMap.length - 1]),
        ].map((item, i) => (
          <EmptyProtocolCard
            key={i}
            xs={protocolsGridMap[protocolsGridMap.length - 1]}
          />
        ))}
      </Grid>
      <Box display="flex" justifyContent="center">
        <Typography variant="subtitle1" className={classes.comingSoonText}>
          {t('More protocols coming soon...')}
        </Typography>
      </Box>
    </>
  )
}

export function HelpDeskSection() {
  const classes = useStyles()
  return (
    <Box alignContent="center" className={classes.helpSection}>
      <Divider />
      <Box className={classes.supportTextWrapper}>
        <HelpDeskLink />
      </Box>
    </Box>
  )
}

type ContentLabelRefPair = {
  title: string
  ref: React.MutableRefObject<unknown>
}

export function ContentsBar({
  contentSections,
}: {
  contentSections: ContentLabelRefPair[]
}) {
  const classes = useStyles()
  const {t} = useTranslation()
  const referenceToScrollContainer = useContext(ReferenceElementContext).current

  const [activeTab, setActiveTab] = useState(0)
  const handleChange = (
    e: React.ChangeEvent<unknown>,
    clickedTabIndex: number,
  ) => {
    setActiveTab(clickedTabIndex)
    const targetRef = contentSections[clickedTabIndex]!
      .ref as React.MutableRefObject<HTMLElement>
    targetRef.current.scrollIntoView({
      behavior: 'smooth',
    })
  }

  useEffect(() => {
    const handleScroll = () => {
      // get section closest to top of the screen
      const currentTopSection = contentSections.findIndex((section) => {
        const {current} = section.ref as React.MutableRefObject<HTMLElement>
        return current?.getBoundingClientRect().top - 63 > 0 // 63 is for top navbar
      })
      if (currentTopSection >= 0) {
        setActiveTab(currentTopSection)
      }
    }
    referenceToScrollContainer?.addEventListener('scroll', handleScroll)
    return () =>
      referenceToScrollContainer?.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <Box className={classes.contentsWrapper}>
      <Typography
        variant="subtitle1"
        className={classes.contentsTitle}
        paragraph
      >
        {t('Contents')}
      </Typography>
      <Tabs
        orientation="vertical"
        value={activeTab}
        onChange={handleChange}
        classes={{
          root: classes.contentTabs,
          indicator: 'indicator',
        }}
      >
        {contentSections.map((section) => (
          <Tab
            key={section.title}
            label={section.title}
            sx={{alignItems: 'flex-start'}}
          />
        ))}
      </Tabs>
    </Box>
  )
}

const useIsBulletListStyles = makeStyles((theme) => ({
  ul: {
    listStyle: 'disc',
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(4),
    '& > *': {
      marginBottom: theme.spacing(1),
    },
  },
}))

const useStyles = makeStyles((theme) => ({
  supportedProtocolCard: {
    marginTop: theme.spacing(-4),
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(3),
    textAlign: 'center',
    width: '100%',
    background: theme.palette.background.paper,
    minHeight: '200px',
  },
  comingSoonProtocolCard: {
    background: `linear-gradient(180deg, ${theme.palette.background.paper} 0%, rgba(255, 255, 255, 0) 100%)`,
    minHeight: '200px',
  },
  comingSoonText: {
    marginTop: theme.spacing(-16),
    color: theme.palette.text.primary,
  },
  helpSection: {
    marginTop: theme.spacing(20),
    width: '100%',
    textAlign: 'center',
  },
  contentsWrapper: {
    position: 'absolute',
  },
  contentsTitle: {
    color: theme.palette.grey['700'],
    textTransform: 'uppercase',
  },
  contentTabs: {
    borderLeft: `1px solid ${theme.palette.divider}`,
    '& .MuiTab-wrapper': {
      alignItems: 'flex-start',
      textTransform: 'none',
    },
    '& .indicator': {
      left: 0,
      backgroundColor: theme.palette.secondary.contrastText,
    },
  },
  divider: {
    marginRight: '-1px',
  },
  supportTextWrapper: {
    margin: '24px auto',
    maxWidth: '450px',
  },
}))
