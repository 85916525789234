import {objectKeys} from 'common/src/typeUtils'

import {safeAssertUnreachable} from '../../utils/assertion'
import {supportedNamespaces} from '../constants'
import type {OnSessionRequestArgs} from '../core/hooks'
import {cip34Handler} from '../methodHandlers/cardano'
import {eip155Handler} from '../methodHandlers/evm'
import {solanaHandler} from '../methodHandlers/solana'
import type {
  SupportedChains,
  SupportedMethods,
  WalletConnectStandard,
} from '../types'

import type {SessionRequestParams} from './types'
import {isStringSubset} from './utils'

type Request<TStandard> = TStandard extends WalletConnectStandard
  ? {
      standard: TStandard
      chain: SupportedChains<TStandard>
      method: SupportedMethods<TStandard>
      params: SessionRequestParams
    }
  : never

const handleRequest = <TStandard extends WalletConnectStandard>(
  req: Request<TStandard>,
) => {
  switch (req.standard) {
    case 'solana': {
      return solanaHandler(req.chain)(req.method, req.params)
    }
    case 'cip34': {
      return cip34Handler(req.chain)(req.method, req.params)
    }
    case 'eip155': {
      return eip155Handler(req.chain)(req.method, req.params)
    }
    default:
      return safeAssertUnreachable(req)
  }
}

/**
 * Checks if the request is consistent and supported, e.g. if method and chain correspond to the the same supported standard
 */
const isSupportedRequest = (req: {
  standard: string
  chain: string
  method: string
  params: SessionRequestParams
}): req is Request<WalletConnectStandard> => {
  if (!isStringSubset(req.standard, objectKeys(supportedNamespaces))) {
    return false
  }

  const namespace = supportedNamespaces[req.standard]

  if (!isStringSubset(req.chain, namespace.chains)) {
    return false
  }

  if (!isStringSubset(req.method, namespace.methods)) {
    return false
  }

  return true
}

export const onSessionRequest = async ({
  params,
  approve,
  reject,
}: OnSessionRequestArgs) => {
  const chain = params.chainId

  const [standard] = chain.split(':')

  const req = {
    standard: standard!,
    chain,
    method: params.request.method,
    params: params.request.params,
  }

  if (!isSupportedRequest(req)) {
    return reject('UNSUPPORTED_METHODS')
  }

  return handleRequest(req)
    .then(approve)
    .catch((e) => reject('USER_REJECTED', e))
}
