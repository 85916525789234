import type {AccountId} from '@nufi/wallet-common'
import React, {useContext, createContext} from 'react'

import type {LocalProfileId, ProfilePassword} from 'src/appStorage'
import type {OpenAppIntent} from 'src/features/appOpener/domain'
import type {Web3AuthUserInfo} from 'src/features/login/domain'
import type {Blockchain} from 'src/types'

import {
  ChangeAccountAfterInitMessage,
  ChangeAccountBeforeInitMessage,
} from './components/Messages'

export type ChangeAccountVariants = 'before-init' | 'after-init'

export type PlatformConfig = {
  openNuFi: (intent: OpenAppIntent) => void
  openDapp: (origin: string) => Promise<void>
  Settings: React.ReactNode
  lastAccountIdHandlers?: {
    getLastUsedAccountId: (
      blockchain: Blockchain,
    ) => Promise<AccountId | undefined>
    updateLastAccountId: (
      blockchain: Blockchain,
      newAccountId: AccountId,
    ) => Promise<void>
  }
  changeAccount?: {
    hints: Record<ChangeAccountVariants, React.ReactNode>
  }
  rememberLoginHandlers?: {
    getRememberLogin: () => Promise<boolean>
    saveRememberLogin: (values: {
      rememberLogin: boolean
      profileId: LocalProfileId
      password: ProfilePassword
      web3AuthUserInfo?: Web3AuthUserInfo
    }) => Promise<void>
    getLastLogin: () => Promise<
      | {
          profileId: LocalProfileId
          password: ProfilePassword
          web3AuthUserInfo?: Web3AuthUserInfo
        }
      | undefined
    >
  }
  notificationsPermission?: {
    hasPermission: () => Promise<boolean>
    requestPermission: ({alwaysAsk}: {alwaysAsk: boolean}) => Promise<boolean>
    setPermissionsRequested: () => Promise<void>
  }
}

export const defaultPlatformConfig = {
  openNuFi: () => window.open(window.origin),
  openDapp: async (origin: string) => {
    window.open(origin)
  },
  Settings: null,
  changeAccount: {
    hints: {
      'after-init': <ChangeAccountAfterInitMessage />,
      'before-init': <ChangeAccountBeforeInitMessage />,
    },
  },
}

export const PlatformConfigContext = createContext<PlatformConfig>(
  defaultPlatformConfig,
)

export const usePlatformConfig = () => useContext(PlatformConfigContext)
