import type {EvmTokenMetadata} from '@nufi/wallet-evm'
import {isEvmBlockchain} from '@nufi/wallet-evm'
import type {SolanaTokenMetadata} from '@nufi/wallet-solana'

import type {ExchangeAssetsDetails} from 'src/features/exchange/domain'
import type {TokenMetadata} from 'src/types'

import {findMatchingTokenSwapAsset as findEvmMatchingTokenSwapAsset} from './evm'
import {findMatchingTokenSwapAsset as findSolanaMatchingTokenSwapAsset} from './solana'

export function findMatchingTokenSwapAsset<T extends TokenMetadata>({
  exchangeAssets,
  tokenMetadata,
}: {
  exchangeAssets: ExchangeAssetsDetails
  tokenMetadata: T
}) {
  const blockchain = tokenMetadata.blockchain

  if (isEvmBlockchain(blockchain)) {
    return findEvmMatchingTokenSwapAsset({
      exchangeAssets,
      tokenMetadata: tokenMetadata as EvmTokenMetadata<typeof blockchain>,
    })
  }
  if (blockchain === 'solana') {
    return findSolanaMatchingTokenSwapAsset({
      exchangeAssets,
      tokenMetadata: tokenMetadata as SolanaTokenMetadata,
    })
  }
  return null
}
