import {
  CardanoTxPlanErrorMessage,
  ledgerExpectedErrors,
} from '@nufi/wallet-cardano'

import {AppStorageError} from '../appStorage/errors'
import {pendingTxError} from '../wallet/utils/errors'

// Some "errors" are expected. F.e. when user types wrong password, or rejects call to HW device.
const expectedErrors: string[] = [
  ...ledgerExpectedErrors,
  AppStorageError.WRONG_PASSWORD,
  ...Object.values(CardanoTxPlanErrorMessage),
  pendingTxError,
]

export const isExpectedError = (error: unknown): boolean => {
  if (typeof error === 'string') {
    return expectedErrors.includes(error)
  }
  const errorMessage = (error as {message?: string}).message
  return errorMessage ? expectedErrors.includes(errorMessage) : false
}
