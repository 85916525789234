import {dexes, DEXHUNTER_DEX_INFO} from '@dexhunterio/swaps/lib/constants/dexes'
import {capitalize} from 'lodash'

export const getDexInfo = (dexCode: string) => {
  if (dexCode === DEXHUNTER_DEX_INFO.code) {
    return DEXHUNTER_DEX_INFO
  }

  return dexCode in dexes
    ? dexes[dexCode as keyof typeof dexes]
    : // just a "best effort" fallback
      {
        name: capitalize(dexCode),
        url: null,
        logo: `https://storage.googleapis.com/dexhunter-images/public/${dexCode.toLowerCase()}.png`,
        code: dexCode,
      }
}
