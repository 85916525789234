/* eslint-disable @typescript-eslint/explicit-function-return-type */

import type {
  SolanaPubKey,
  SolanaAccountWithStakeAccounts,
  SolanaAccountInfo,
  SolanaStakeAccountPartialData,
} from '@nufi/wallet-solana'
import {getTotalStakedAPY, sumStakedAmounts} from '@nufi/wallet-solana'
import {useQuery} from '@tanstack/react-query'

import config from 'src/config'
import {fetchNeverStaleQuery, useNeverStaleQuery} from 'src/utils/query-utils'

import queryClient from '../../../../queryClient'
import {minutesToMilliseconds} from '../../../utils/common'
import {solana} from '../../solanaManagers'

import {cachedGetAccounts} from './common'
import {QUERY_KEY_PREFIX as P} from './utils'

//
// __CACHED__ QUERIES
//

export const stakingQueryKeys = {
  isStakeAccountCreated: (stakePubKey: SolanaPubKey | undefined) => [
    P,
    'isStakeAccountCreated',
    stakePubKey?.toString(),
  ],
  epochSchedule: [P, 'epochSchedule'],
  epochInfo: [P, 'epochInfo'],
  stakeAccounts: [P, 'stakeAccounts'],
  stakeAccountsInfo: {
    index: [P, 'stakeAccountsInfo'],
    info: (
      accounts?: SolanaAccountInfo[],
      stakeAccounts?: SolanaStakeAccountPartialData[],
    ) => [
      ...stakingQueryKeys.stakeAccountsInfo.index,
      accounts && stakeAccounts
        ? {
            accounts: accounts.map((a) => a.id),
            stakeAccounts: stakeAccounts.map((sa) => sa.id),
          }
        : undefined,
    ],
  },
  validators: [P, 'validators'],
  totalStakedAPY: [P, 'totalStakedAPY'],
} as const

async function getStakeAccounts() {
  const accounts = await cachedGetAccounts()
  return solana.wallet.getStakeAccounts(accounts)
}

getStakeAccounts.__key = stakingQueryKeys.stakeAccounts

export function cachedGetStakeAccounts() {
  return fetchNeverStaleQuery({
    queryKey: getStakeAccounts.__key,
    queryFn: getStakeAccounts,
  })
}

export function useGetStakeAccounts(enabled = true) {
  return useNeverStaleQuery({
    queryKey: getStakeAccounts.__key,
    queryFn: getStakeAccounts,
    enabled,
  })
}

export function orderByTotalStakedAmount<
  T extends SolanaAccountWithStakeAccounts,
>(accounts: T[]) {
  const getTotalStakedAmount = (account: T) =>
    sumStakedAmounts(account.stakeAccounts)

  return [...accounts].sort((a, b) =>
    getTotalStakedAmount(a).isLessThan(getTotalStakedAmount(b)) ? 1 : -1,
  )
}

async function getStakeAccountsInfo() {
  const accounts = await cachedGetAccounts()
  const stakeAccounts = await cachedGetStakeAccounts()

  const stakeAccountsInfo = await solana.wallet.getStakingInfo(
    accounts,
    stakeAccounts,
  )
  return orderByTotalStakedAmount(
    accounts.map(
      (account): SolanaAccountWithStakeAccounts => ({
        ...account,
        stakeAccounts:
          stakeAccountsInfo.find((i) => i.accountId === account.id)
            ?.stakeAccounts || [],
      }),
    ),
  )
}

getStakeAccountsInfo.__key = stakingQueryKeys.stakeAccountsInfo.index

export function cachedGetStakeAccountsInfo() {
  return fetchNeverStaleQuery({
    queryKey: getStakeAccountsInfo.__key,
    queryFn: getStakeAccountsInfo,
  })
}

export function useGetStakeAccountsInfo(enabled = true) {
  return useNeverStaleQuery({
    queryKey: getStakeAccountsInfo.__key,
    queryFn: getStakeAccountsInfo,
    enabled,
  })
}

async function getValidatorsSortedByStake() {
  return await solana.wallet.getValidatorsSortedByStake(
    config.backendUrl,
    config.solanaClusterName,
  )
}

getValidatorsSortedByStake.__key = stakingQueryKeys.validators

export function cachedGetValidatorsSortedByStake() {
  return fetchNeverStaleQuery({
    queryKey: getValidatorsSortedByStake.__key,
    queryFn: getValidatorsSortedByStake,
  })
}

export function useGetValidatorsSortedByStake(enabled = true) {
  return useNeverStaleQuery({
    queryKey: getValidatorsSortedByStake.__key,
    queryFn: getValidatorsSortedByStake,

    enabled,
  })
}

export function useGetEpochScheduleEstimate() {
  return useQuery({
    queryKey: stakingQueryKeys.epochSchedule,
    queryFn: async () => await solana.wallet.getEpochScheduleEstimate(),
    refetchInterval: minutesToMilliseconds(15),
  })
}

export function useGetEpochInfo() {
  return useQuery({
    queryKey: stakingQueryKeys.epochInfo,
    queryFn: async () => await solana.wallet.getEpochInfo(),
    refetchInterval: minutesToMilliseconds(15),
  })
}

/**
 * Will return `useQuery<boolean, ...>` indicating whether the stake account was created
 * for the given stake public key. There is a delay when creating stake account on Solana,
 * thus the hook accepts `pollInterval` param that specifies the polling interval.
 */
export function useGetIsStakeAccountCreated({
  stakePubKey,
  pollInterval,
}: {
  stakePubKey: SolanaPubKey | undefined
  pollInterval: number
}) {
  const useIsStakeAccountCreatedQueryKey =
    stakingQueryKeys.isStakeAccountCreated(stakePubKey)
  const isCreated =
    queryClient.getQueryData(useIsStakeAccountCreatedQueryKey) === true

  return useQuery({
    queryKey: useIsStakeAccountCreatedQueryKey,
    queryFn: async () => {
      if (!stakePubKey) return false
      const stakeAccounts = await cachedGetStakeAccounts()
      return stakeAccounts.some(
        (sa) => sa.publicKey.toString() === stakePubKey.toString(),
      )
    },
    enabled: !!stakePubKey && !isCreated,
    refetchInterval: pollInterval,
  })
}

//
// __COMPUTED__ QUERIES
//

export function useGetHasActiveStaking() {
  const stakeAccountsQuery = useGetStakeAccounts()
  return {
    ...stakeAccountsQuery,
    data: !!stakeAccountsQuery.data?.length,
  }
}

export function useGetTotalStakedAPY(enabled = true) {
  return useNeverStaleQuery({
    queryKey: stakingQueryKeys.totalStakedAPY,
    queryFn: async () => {
      const stakeAccountsInfo = await cachedGetStakeAccountsInfo()
      const validators = await cachedGetValidatorsSortedByStake()

      return getTotalStakedAPY({stakeAccountsInfo, validators})
    },
    enabled,
  })
}
