import {Search, Clear} from '@mui/icons-material'
import {InputAdornment, Box, TextField, IconButton} from '@mui/material'
import {makeStyles} from '@mui/styles'
import clsx from 'clsx'
import React, {useState, useRef} from 'react'
import {useTranslation} from 'react-i18next'

import {DebouncedField} from '../../DebouncedField'

type CollapsibleSearchFieldProps = {
  value: string
  onChange: (v: string) => void
}

export function CollapsibleSearchField({
  value,
  onChange,
}: CollapsibleSearchFieldProps) {
  const {t} = useTranslation()
  const classes = useStyles()
  const [focused, setFocused] = useState(false)
  const inputRef = useRef<HTMLDivElement>(null)

  const onExpand = () => {
    const focus = () => inputRef.current?.querySelector('input')?.focus()

    // will activate input "grow"
    focus()
    // Hacky solution that ensures that the input is really focused for writing
    // as without this sometimes a strange behavior occurs (due to size of input being 0
    // during the first `focus` call)
    setTimeout(focus, 100)
  }

  return (
    <Box
      width="100%"
      className={clsx(
        !focused && classes.cursorPointer,
        !focused && classes.hoverableWrapper,
      )}
      onClick={onExpand}
    >
      <DebouncedField delay={500} defaultValue={value} {...{onChange}}>
        {(_value, _onChange) => (
          <TextField
            value={_value}
            onBlur={() => _value === '' && setFocused(false)}
            onFocus={() => setFocused(true)}
            onChange={(e) => _onChange(e.target.value)}
            className={clsx(
              classes.input,
              !focused ? classes.collapsedInput : classes.expandedInput,
            )}
            variant={focused ? 'outlined' : 'standard'}
            placeholder={t('Search...')}
            ref={inputRef}
            InputProps={{
              startAdornment: (
                <InputAdornment
                  position="start"
                  className={clsx(!focused && classes.cursorPointer)}
                >
                  <Search id="search-icon" />
                </InputAdornment>
              ),
              endAdornment: focused && (
                <InputAdornment
                  position="end"
                  className={classes.cursorPointer}
                >
                  <Box visibility={value === '' ? 'hidden' : 'visible'}>
                    <IconButton size="small" onClick={() => _onChange('')}>
                      <Clear fontSize="small" />
                    </IconButton>
                  </Box>
                </InputAdornment>
              ),
            }}
          />
        )}
      </DebouncedField>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  hoverableWrapper: {
    '&:hover #search-icon': {
      color: theme.palette.primary.main,
    },
  },
  input: {
    transition: 'width 0.3s',
  },
  collapsedInput: {
    width: 0,
  },
  expandedInput: {
    width: '100%',
  },
  cursorPointer: {
    cursor: 'pointer',
  },
}))
