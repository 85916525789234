import type {FlowAccountInfo} from '@nufi/wallet-flow'
import type {SolanaAccountInfo} from '@nufi/wallet-solana'
import _ from 'lodash'

import {useFilterEnabledBlockchains} from 'src/features/profile/application'

import type {CsvExportBlockchain} from '../../../../../../blockchainTypes'
import {csvExportBlockchains} from '../../../../../../blockchainTypes'
import type {AccountId, AccountInfo} from '../../../../../../types'
import {isBlockchainSubset} from '../../../../../../utils/blockchainGuards'
import {useAllAccounts} from '../../../../../../wallet'
import type {CardanoAccountInfo} from '../../../../../../wallet/cardano'

import {createCSVRows as cardanoCreateCSVRows} from './cardano'
import type {CSVFile, CSVConstants, CreateCSVRows} from './common'
import {rowsToCSVObject} from './common'
import {createCSVRows as flowCreateCSVRows} from './flow'
import {createCSVRows as solanaCreateCSVRows} from './solana'

export const useCsvExportBlockchains = () => {
  const filterEnabledBlockchains = useFilterEnabledBlockchains()
  return filterEnabledBlockchains(csvExportBlockchains)
}

const filterSelectedAccounts = <TAccountInfo extends AccountInfo>(
  blockchain: TAccountInfo['blockchain'],
  accounts: AccountInfo[],
  ids: AccountId[],
): TAccountInfo[] => {
  return accounts.filter(
    (account) =>
      account.blockchain === blockchain && ids.some((id) => id === account.id),
  ) as TAccountInfo[]
}

export const createCSVObject = async (
  csvConstants: CSVConstants,
  blockchainToAccountIds: Record<CsvExportBlockchain, AccountId[]>,
  startDate: Date,
  endDate: Date,
  accounts: AccountInfo[],
): Promise<CSVFile> => {
  const withCommon =
    <TAccountInfo extends AccountInfo, T = null>(
      createCSVRows: CreateCSVRows<TAccountInfo, T>,
    ) =>
    (accounts: TAccountInfo[], blockchainSpecific: T) =>
      createCSVRows(
        csvConstants,
        accounts,
        startDate,
        endDate,
        blockchainSpecific,
      )

  return rowsToCSVObject(
    csvConstants,
    (
      await Promise.all([
        withCommon<CardanoAccountInfo>(cardanoCreateCSVRows)(
          filterSelectedAccounts(
            'cardano',
            accounts,
            blockchainToAccountIds.cardano,
          ),
          null,
        ),
        withCommon<SolanaAccountInfo>(solanaCreateCSVRows)(
          filterSelectedAccounts(
            'solana',
            accounts,
            blockchainToAccountIds.solana,
          ),
          null,
        ),
        withCommon<FlowAccountInfo>(flowCreateCSVRows)(
          filterSelectedAccounts('flow', accounts, blockchainToAccountIds.flow),
          null,
        ),
      ])
    ).flat(),
  )
}

export const useSupportedCSVExportAccounts = () => {
  const {data, isLoading, errorKeys} = useAllAccounts()
  const blockchains = useCsvExportBlockchains()

  return {
    data: data.filter((accountInfo) =>
      isBlockchainSubset(accountInfo.blockchain, blockchains),
    ),
    isLoading,
    errorKeys: _.intersection(blockchains, errorKeys) as CsvExportBlockchain[],
  }
}
