import {Grid, Typography} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'

import {MultilineString} from '../atoms'

type DictionaryRowProps = {
  label: string
  value: string | JSX.Element
}

export function DictionaryRow({label, value}: DictionaryRowProps) {
  const classes = useStyles()
  return (
    <Grid
      container
      wrap="nowrap"
      justifyContent="space-between"
      className={classes.dictionaryRow}
    >
      <Grid item mr={2}>
        <Typography noWrap color="textSecondary">
          {label}
        </Typography>
      </Grid>
      <Grid item>
        <Grid container direction="column" alignItems="flex-end">
          <MultilineString variant="subtitle1" label={value} />
        </Grid>
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles((theme) => ({
  dictionaryRow: {
    padding: theme.spacing(0.75, 0),
    [theme.breakpoints.up('windowsOld')]: {
      padding: theme.spacing(1.125, 0),
    },
    [theme.breakpoints.up('windowsZoomed')]: {
      padding: theme.spacing(1.5, 0),
    },
  },
}))
