import {Box, Grid, Typography} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {blockchainToWalletKind} from 'src/wallet/walletKind'

import {
  Modal,
  ModalLayout,
  FormattedAsset,
  ModalHeader,
  AssetIcon,
  AddressExplorerLink,
} from '../../../components'
import type {AccountInfo} from '../../../types'
import {useEllipsisStyles} from '../../../utils/layoutUtils'
import {
  useGetCoinName,
  useGetBlockchainName,
  useCryptoProviderTypeNames,
} from '../../../utils/translations'
import {isHwVendor} from '../../../wallet'

import {AccountDetailRow, AccountDetailList} from './utils'

type AccountDetailsProps = {
  accountInfo: AccountInfo
  onClose: () => void
  extendedAccountInfo?: {label: string; value: string; link?: JSX.Element}[]
}

export default function AccountDetails({
  accountInfo: {
    name,
    balance,
    blockchain,
    address,
    cryptoProviderType,
    formattedDerivationPath,
  },
  onClose,
  extendedAccountInfo,
}: AccountDetailsProps) {
  const {t} = useTranslation()
  const classes = {...useStyles(), ...useEllipsisStyles()}
  const getBlockchainName = useGetBlockchainName()
  const getCoinName = useGetCoinName()
  const cryptoProviderTypeNames = useCryptoProviderTypeNames()

  const walletKind = blockchainToWalletKind(blockchain)

  return (
    <Modal onClose={onClose} variant="centered" dismissable>
      <ModalLayout
        header={
          <ModalHeader onClose={onClose} hasDivider>
            <Typography variant="h5">{t('Account details')}</Typography>
          </ModalHeader>
        }
        body={
          <Box py={2} px={3} minWidth={500}>
            <AccountDetailList>
              <AccountDetailRow label={t('Account name')} value={name} />
              {walletKind !== 'evm' && (
                <AccountDetailRow
                  label={t('Blockchain')}
                  value={
                    <Grid container spacing={2} className={classes.iconRow}>
                      <Grid item>
                        <Typography>
                          {getBlockchainName(blockchain)} (
                          {getCoinName(blockchain)})
                        </Typography>
                      </Grid>
                      <Grid item>
                        <AssetIcon blockchain={blockchain} exactSize={24} />
                      </Grid>
                    </Grid>
                  }
                />
              )}
              {walletKind !== 'evm' && (
                <AccountDetailRow
                  label={t('Balance')}
                  value={
                    <Typography>
                      <FormattedAsset
                        blockchain={blockchain}
                        amount={balance}
                        isSensitiveInformation
                      />
                    </Typography>
                  }
                />
              )}
              <AccountDetailRow
                label={t('Wallet type')}
                value={
                  isHwVendor(cryptoProviderType)
                    ? `${t('Hardware')} (${
                        cryptoProviderTypeNames[cryptoProviderType]
                      })`
                    : t('Hot')
                }
              />
              <AccountDetailRow
                label={t('Account address')}
                value={
                  <Grid container alignItems="center">
                    <Typography className={classes.address}>
                      {address}
                    </Typography>
                    {walletKind !== 'evm' && (
                      <AddressExplorerLink
                        address={address}
                        blockchain={blockchain}
                        size="medium"
                      />
                    )}
                  </Grid>
                }
              />
              <AccountDetailRow
                label={t('Account derivation path')}
                value={formattedDerivationPath}
              />
              {extendedAccountInfo?.map(({label, value, link}, i) =>
                link ? (
                  <AccountDetailRow
                    key={i}
                    label={label}
                    value={
                      <Grid container alignItems="center">
                        <Typography className={classes.address}>
                          {value}
                        </Typography>
                        {link}
                      </Grid>
                    }
                  />
                ) : (
                  <AccountDetailRow key={i} label={label} value={value} />
                ),
              )}
            </AccountDetailList>
          </Box>
        }
      />
    </Modal>
  )
}

const useStyles = makeStyles((theme) => ({
  address: {
    marginRight: theme.spacing(1),
  },
  iconRow: {
    maxHeight: '32px',
  },
}))
