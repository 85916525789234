import {safeAssertUnreachable} from '@nufi/frontend-common'
import React from 'react'

import type {CryptoProviderType} from '../../../wallet'
import {isHotVendor} from '../../../wallet'

import {AddGridPlusAccount} from './AddGridPlusAccount'
import {AddHotAccount} from './AddHotAccount'
import {AddLedgerAccount} from './AddLedgerAccount'
import {AddTrezorAccount} from './AddTrezorAccount'

type AddSolanaAccountProps = {
  cryptoProviderType: CryptoProviderType
}

const AddSolanaAccount = ({cryptoProviderType}: AddSolanaAccountProps) => {
  if (isHotVendor(cryptoProviderType)) {
    return <AddHotAccount {...{cryptoProviderType}} />
  }
  switch (cryptoProviderType) {
    case 'ledger':
      return <AddLedgerAccount />
    case 'trezor':
      return <AddTrezorAccount />
    case 'gridPlus':
      return <AddGridPlusAccount />
    default:
      return safeAssertUnreachable(cryptoProviderType)
  }
}

export default AddSolanaAccount
