import {Box, Divider, Typography} from '@mui/material'
import type BigNumber from 'bignumber.js'
import {useFormikContext} from 'formik'
import React from 'react'
import ContentLoader from 'react-content-loader'
import {useTranslation} from 'react-i18next'

import {
  CenteredError,
  FooterLayout,
  ModalFooter,
  ModalLayout,
  QueryGuard,
  ReadOnlyAccountField,
  useModalSharedStyles,
} from '../../../../../../components'
import type {AccountInfo} from '../../../../../../types'
import {ContentLoaderBackground} from '../../../../../../utils/layoutUtils'
import type {
  EvmAddress,
  EvmBlockchain,
  EvmTokenMetadata,
  GetErc20TokenBalance,
} from '../../../../../../wallet/evm'
import {useGetErc20TokenBalance} from '../../../../../../wallet/evm'
import {ensureAccountById} from '../../../../../../wallet/utils/common'
import {ScrollableList} from '../../../../../staking/common/overview/StakeOverviewUtils'
import {TokenInfo} from '../ImportTokenUtils'

import type {ImportEvmTokenSchema} from './schema'

const getFormId = (blockchain: EvmBlockchain) =>
  `import-${blockchain}-token-summary-form`

type SummaryScreenProps<TBlockchain extends EvmBlockchain> = {
  blockchain: TBlockchain
  onBack: () => unknown
  onSubmit: () => unknown
  accounts: AccountInfo[]
  summary: {
    address: EvmAddress<TBlockchain>
    tokens: EvmTokenMetadata<TBlockchain>[]
  }
  ModalHeader: React.ReactNode
  getErc20TokenBalance?: GetErc20TokenBalance
}

export const SummaryScreen = <TBlockchain extends EvmBlockchain>({
  blockchain,
  onBack,
  onSubmit,
  summary,
  accounts,
  ModalHeader,
  getErc20TokenBalance,
}: SummaryScreenProps<TBlockchain>) => {
  const formId = getFormId(blockchain)

  const {t} = useTranslation()
  const classes = useModalSharedStyles()
  const {accountId} =
    useFormikContext<ImportEvmTokenSchema<TBlockchain>>().values
  const account = ensureAccountById(accounts, accountId)
  const isSingleToken = summary.tokens.length === 1

  return (
    <form
      onSubmit={onSubmit}
      noValidate
      id={formId}
      className={classes.formWrapper}
    >
      <ModalLayout
        header={ModalHeader}
        body={
          <Box p={2}>
            <ReadOnlyAccountField
              blockchain={blockchain}
              value={account}
              label={t('Account')}
            />
            <Box className={classes.commonTopMargin}>
              <Box mt={2}>
                <Divider />
              </Box>
              <Box mt={2}>
                <Typography variant="subtitle2">
                  {isSingleToken
                    ? t('Token to be imported')
                    : t('Tokens to be imported')}
                </Typography>
              </Box>
              <Box width="100%">
                <ScrollableList>
                  {summary.tokens.map((token) => (
                    <TokenCardBalanceLoader
                      key={token.id}
                      {...{blockchain, token, getErc20TokenBalance}}
                      address={summary.address}
                    >
                      {(balance) => (
                        <TokenInfo
                          {...{blockchain, token, balance}}
                          renderCommonRows={false}
                        />
                      )}
                    </TokenCardBalanceLoader>
                  ))}
                </ScrollableList>
              </Box>
            </Box>
          </Box>
        }
        footer={
          <ModalFooter hasDivider>
            <FooterLayout
              leftBtnConf={{
                onClick: onBack,
                children: t('Back'),
              }}
              rightBtnConf={{
                children: t('Import'),
                type: 'submit',
                form: formId,
              }}
            />
          </ModalFooter>
        }
      />
    </form>
  )
}

export type TokenCardBalanceLoaderProps<TBlockchain extends EvmBlockchain> = {
  blockchain: TBlockchain
  token: EvmTokenMetadata<TBlockchain>
  address: EvmAddress<TBlockchain>
  getErc20TokenBalance?: GetErc20TokenBalance
  children: (balance: BigNumber) => JSX.Element
}

export const TokenCardBalanceLoader = <TBlockchain extends EvmBlockchain>({
  blockchain,
  token,
  address,
  getErc20TokenBalance,
  children,
}: TokenCardBalanceLoaderProps<TBlockchain>) => {
  const {t} = useTranslation()
  const tokenBalanceQuery = useGetErc20TokenBalance(
    blockchain,
    token.contractAddress,
    address,
    getErc20TokenBalance,
  )

  return (
    <QueryGuard
      {...tokenBalanceQuery}
      LoadingElement={<TokenCardBalanceWrapperLoading />}
      ErrorElement={
        <CenteredError error={t('Could not load token balances.')} />
      }
    >
      {(balance) => children(balance)}
    </QueryGuard>
  )
}

export const TokenCardBalanceWrapperLoading = () => (
  <ContentLoader
    width="100%"
    height={47}
    backgroundColor={ContentLoaderBackground()}
  >
    <circle cx="20" cy="18" r="12" />
    <rect x="45" y="0" rx="4" width="calc(100% - 45px)" height={35} />
  </ContentLoader>
)
