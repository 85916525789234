import {Link} from '@mui/material'
import {CardanoTxPlanErrorMessage} from '@nufi/wallet-cardano'
import React from 'react'

/*
TODO: proper revisit of error handling and what type can `txPlanError` actually be
TODO: consider mapping chosen text to i18next custom texts
TODO: consider reusing for other cardano action modals
*/
export function cardanoTxPlanErrorToString(txPlanError: unknown): string {
  const errorString = (() => {
    if (typeof txPlanError === 'string') return txPlanError
    if (txPlanError instanceof Error) return txPlanError.message
    return 'Unknown error'
  })()

  // TODO: handle me properly (i18n, decide what level of details should user see)
  // The error messages from "txPlanning" are quite low-level, thus we translate them
  // to a more understandable messages from user perspective
  if (
    errorString === CardanoTxPlanErrorMessage.notEnoughFundsForNonAdaInChange ||
    errorString === CardanoTxPlanErrorMessage.notEnoughFunds
  ) {
    // Directly referring to 'ADA' compared to 'funds' is more understandable
    // e.g. when sending tokens, but not having enough 'ADA' for fee
    // We can narrow these messages down to ADA because FE already validates
    // that no more than available tokens are sent
    return 'Not enough ADA'
  }

  return errorString
}

export const MinAmountButton = ({onClick, ...rest}: {onClick: () => void}) => (
  <Link
    variant="caption"
    component="button"
    // if this component (or any other component inside SendForm's <form/>) gets rendered as <button />
    // it needs to contain type="button", otherwise it will be seen as submit button and gets called on "enter" keystroke.
    type="button"
    sx={{px: 0.25}}
    onClick={(e) => {
      e.preventDefault()
      onClick()
    }}
    {...rest}
  />
)

export const minAmountValidationErrorKey = 'amount_too_low'
