/* eslint-disable @typescript-eslint/explicit-function-return-type */

import {useQuery} from '@tanstack/react-query'

import type {Mnemonic} from '../wallet/types'

import MasterProfileManager from './masterProfileManager'

export function useProfileInfos() {
  return useQuery({
    queryKey: useProfileInfos.__key,
    queryFn: async () => (await MasterProfileManager.getData()).profiles,
    gcTime: 0,
  })
}
useProfileInfos.__key = ['profileInfos']

export function useLocalProfile(mnemonic: Mnemonic) {
  return useQuery({
    queryKey: [useLocalProfile.__key, mnemonic],
    queryFn: () =>
      MasterProfileManager.findProfile({mnemonic, loginType: 'password'}),
    gcTime: 0,
  })
}
useLocalProfile.__key = ['localProfile']

export function useLastLoggedInLocalProfileId() {
  return useQuery({
    queryKey: ['lastLoggedInLocalProfileId'],
    queryFn: () => MasterProfileManager.getLastLoggedInLocalProfileId(),
    gcTime: 0,
  })
}

export function useCurrentProfileMeta() {
  return useQuery({
    queryKey: useCurrentProfileMeta.__key,
    queryFn: () => MasterProfileManager.getCurrentProfileMeta(),
    gcTime: 0,
  })
}
useCurrentProfileMeta.__key = ['currentProfileMeta']
