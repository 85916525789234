import type {FlowAccountOfflineInfo} from '@nufi/wallet-flow'
import {MAX_ACCOUNT_COUNT} from '@nufi/wallet-flow'
import React from 'react'

import type {AccountInfo, HotVendor} from '../../../types'
import {useAddAccounts, useDiscoverAllHotAccounts} from '../../../wallet/flow'
import {AddHotAccount as _AddHotAccount} from '../sharedActions/addAccount/AddHotAccount'

type AddHotAccountProps = {
  cryptoProviderType: HotVendor
}

export const AddHotAccount = ({cryptoProviderType}: AddHotAccountProps) => {
  const addAccounts = useAddAccounts()
  const discoveredAccountsQuery = useDiscoverAllHotAccounts()

  const onSubmit = async (newAccounts: AccountInfo[]) => {
    await addAccounts.mutateAsync({
      newAccounts: newAccounts as FlowAccountOfflineInfo[],
    })
  }

  return (
    <_AddHotAccount
      blockchain="flow"
      addAccountsMutation={addAccounts}
      {...{
        onSubmit,
        discoveredAccountsQuery,
        cryptoProviderType,
      }}
      extras={{
        maxAccountLimit: MAX_ACCOUNT_COUNT,
        prevMustBeUsed: true,
      }}
    />
  )
}
