import {SwapHoriz as ExchangeIcon} from '@mui/icons-material'
import {Box, Divider, IconButton, styled} from '@mui/material'
import type {SwapAsset} from 'common'
import {useFormikContext} from 'formik'
import React from 'react'
import {useTranslation} from 'react-i18next'

import type {ExchangeAssetsDetails} from 'src/features/exchange/domain'
import type {AddressType} from 'src/types'

import {WithTooltip} from '../../../../components'
import {EXCHANGE_MODAL_DIVIDER_HEIGHT} from '../../constants'

import type {DetailsValues} from './schema'

type AssetsDividerProps = {
  exchangeAssetsDetails: ExchangeAssetsDetails
  getAddressType: (value: SwapAsset, type: 'from' | 'to') => AddressType
}

export function AssetsDivider({
  exchangeAssetsDetails,
  getAddressType,
}: AssetsDividerProps) {
  const {t} = useTranslation()
  const formikProps = useFormikContext<DetailsValues>()
  const {values, resetForm} = formikProps

  const fromAsset = exchangeAssetsDetails[values.fromAsset]
  const toAsset = exchangeAssetsDetails[values.toAsset]

  const isFromAssetSwitchable = fromAsset?.enabled && fromAsset?.enabledTo
  const isToAssetSwitchable = toAsset?.enabled && toAsset?.enabledFrom

  const onSwitchFromAndToAssets = () => {
    if (isFromAssetSwitchable && isToAssetSwitchable) {
      const fromAddressType = getAddressType(values.toAsset, 'from')
      const toAddressType = getAddressType(values.fromAsset, 'to')

      const newValues: DetailsValues = {
        fromAsset: values.toAsset,
        fromAddress:
          values.toAddressType === 'internal' ? values.toAddress : '', // Avoid refund (external) address error in swap creation
        fromAddressType,
        toAsset: values.fromAsset,
        toAddress: values.fromAddress,
        toAddressType,
        amount: values.amount,
        extraId: null, // Ensure this is not preserved
      }

      resetForm({
        values: newValues,
      })
    }
  }

  return (
    <Box position="relative">
      <Box
        position="absolute"
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          backgroundColor: (theme) => theme.palette.background.paper,
          top: EXCHANGE_MODAL_DIVIDER_HEIGHT / 2,
          left: '50%',
          transform: 'translate(-50%,-50%)',
        }}
      >
        <WithTooltip
          title={
            !isFromAssetSwitchable ? (
              <>
                {t('from_asset_disabled_switch', {
                  fromAsset: values.fromAsset,
                })}
              </>
            ) : !isToAssetSwitchable ? (
              <>
                {t('to_asset_disabled_switch', {
                  toAsset: values.toAsset,
                })}
              </>
            ) : (
              ''
            )
          }
        >
          <IconButton
            onClick={onSwitchFromAndToAssets}
            disabled={!isFromAssetSwitchable || !isToAssetSwitchable}
            sx={{
              border: '1px solid',
              borderRadius: '50%',
              borderColor: (theme) => theme.palette.secondary.light,
              ':hover': {
                backgroundColor: (theme) => theme.palette.action.hover,
              },
            }}
          >
            <ExchangeIcon
              color={
                isFromAssetSwitchable && isToAssetSwitchable
                  ? 'primary'
                  : 'disabled'
              }
              fontSize="large"
            />
          </IconButton>
        </WithTooltip>
      </Box>
      <DividerLine orientation="vertical" flexItem />
    </Box>
  )
}

const DividerLine = styled(Divider)(({theme}) => ({
  marginLeft: theme.spacing(5),
  marginRight: theme.spacing(5),
  height: EXCHANGE_MODAL_DIVIDER_HEIGHT,
}))
