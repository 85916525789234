import type {
  FlowAccountInfo,
  FlowNodeInfo,
  FlowSignedTransaction,
} from '@nufi/wallet-flow'
import {flowToNanoflow} from '@nufi/wallet-flow'
import type {FormikProps} from 'formik'
import React from 'react'

import type {AccountId} from '../../../../../types'
import {assert} from '../../../../../utils/assertion'
import type {useSignTx, useSubmitTx} from '../../../../../wallet/flow'
import {ensureAccountById} from '../../../../../wallet/utils/common'
import {SignTxFlow} from '../../../../transaction'
import {DeviceReadyState} from '../../../../transaction/common'
import type {ActiveScreen} from '../../../common/utils'
import {StakeModalHeader} from '../../../common/utils'
import type {FlowUICachedTxPlan} from '../types'
import {ensureValidatorByNodeId, getSendMaxAmountOptions} from '../utils'

import DetailsScreen from './DetailsScreen'
import SummaryScreen from './SummaryScreen'
import type {FormSchema} from './types'

type FlowStakeModalContentProps = {
  setActiveScreen: (screen: ActiveScreen) => void
  accounts: FlowAccountInfo[]
  validators: FlowNodeInfo[]
  formikProps: FormikProps<FormSchema>
  submitProps: ReturnType<typeof useSubmitTx>
  signProps: ReturnType<typeof useSignTx>
  onClose: () => unknown
  onTxSubmit: (
    accountId: AccountId,
    signedTx: FlowSignedTransaction,
  ) => Promise<unknown>
  onTxSignAndSubmit: (values: FormSchema) => Promise<unknown>
  txPlan: FlowUICachedTxPlan | null
}

export function FlowStakeModalContent({
  accounts,
  setActiveScreen,
  formikProps,
  submitProps,
  onClose,
  onTxSubmit,
  onTxSignAndSubmit,
  signProps,
  validators,
  txPlan,
}: FlowStakeModalContentProps) {
  const blockchain = 'flow'
  const ModalHeader = <StakeModalHeader {...{blockchain, onClose}} />
  const amountKey: keyof FormSchema = 'amount' // due to TS
  const maxAmountOptions = getSendMaxAmountOptions(
    ensureAccountById(accounts, formikProps.values.accountId),
    'registerDelegator',
    formikProps,
    amountKey,
  )

  return (
    <SignTxFlow
      {...{
        onClose,
        formikProps,
        ModalHeader,
        blockchain,
        submitProps,
        DeviceReadyState,
        onTxSignAndSubmit,
        onTxSubmit,
      }}
      signProps={signProps}
      initialScreen="details"
      renderDetails={() => (
        <DetailsScreen
          accounts={accounts}
          validators={validators}
          {...{blockchain, onClose, formikProps, maxAmountOptions}}
        />
      )}
      renderSummary={() => {
        assert(!!txPlan?.fee)
        const summary = {
          fromAccount: ensureAccountById(
            accounts,
            formikProps.values.accountId,
          ),
          fee: txPlan.fee,
          validator: ensureValidatorByNodeId(
            validators,
            formikProps.values.validatorId,
          ),
          amount: flowToNanoflow(formikProps.values.amount),
        }
        return (
          <SummaryScreen
            onBack={() => setActiveScreen('details')}
            onSubmit={formikProps.handleSubmit}
            {...{summary, formikProps, onClose, blockchain, submitProps}}
          />
        )
      }}
    />
  )
}
