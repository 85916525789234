import type {
  FlowAccountInfo,
  FlowSignedTransaction,
  Nanoflow,
} from '@nufi/wallet-flow'
import type {FormikProps} from 'formik'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'

import {useTrackTxSubmission} from '../../../../../tracking'
import type {AccountId} from '../../../../../types'
import type {useSignTx, useSubmitTx} from '../../../../../wallet/flow'
import {ensureAccountById} from '../../../../../wallet/utils/common'
import {SignTxFlow} from '../../../../transaction'
import {DeviceReadyState, StakeModalHeader} from '../../../common/utils'

import {SetupStakingSubmitScreen} from './SubmitScreen'
import SummaryScreen from './SummaryScreen'
import type {FormSchema} from './types'

type SetupStakingCollectionContentProps = {
  accounts: FlowAccountInfo[]
  formikProps: FormikProps<FormSchema>
  submitProps: ReturnType<typeof useSubmitTx>
  signProps: ReturnType<typeof useSignTx>
  onClose: () => unknown
  onTxSubmit: (
    accountId: AccountId,
    signedTx: FlowSignedTransaction,
  ) => Promise<unknown>
  onTxSignAndSubmit: (values: FormSchema) => Promise<unknown>
  txFee: Nanoflow
}

export function SetupStakingCollectionContent({
  accounts,
  formikProps,
  submitProps,
  onClose,
  onTxSubmit,
  onTxSignAndSubmit,
  signProps,
  txFee,
}: SetupStakingCollectionContentProps) {
  const {t} = useTranslation()
  useEffect(() => {
    formikProps.validateForm()
  }, [])

  const summary = {
    fromAccount: ensureAccountById(accounts, formikProps.values.accountId),
    fee: txFee,
  }

  const blockchain = 'flow'
  const ModalHeader = (
    <StakeModalHeader
      customTitle={t('Setup staking collection')}
      {...{blockchain, onClose}}
    />
  )

  useTrackTxSubmission(submitProps, {
    blockchain: summary.fromAccount.blockchain,
    provider: summary.fromAccount.cryptoProviderType,
    type: 'setupStakingCollection',
  })

  return (
    <SignTxFlow
      {...{
        onClose,
        formikProps,
        ModalHeader,
        blockchain,
        submitProps,
        DeviceReadyState,
        onTxSignAndSubmit,
      }}
      signProps={signProps}
      onTxSubmit={onTxSubmit}
      initialScreen="summary"
      renderSummary={() => (
        <SummaryScreen
          onBack={onClose}
          onSubmit={formikProps.handleSubmit}
          {...{
            summary,
            formikProps,
            onClose,
            blockchain,
            ModalHeader,
            error: <>{formikProps.errors.accountId}</>,
          }}
        />
      )}
      renderSubmit={({onSignAndSubmitGoBack}) => (
        <SetupStakingSubmitScreen
          onTxSubmit={onTxSubmit}
          onNewTransaction={null}
          {...{
            onSignAndSubmitGoBack,
            signProps,
            submitProps,
            onClose,
            formikProps,
          }}
        />
      )}
    />
  )
}
