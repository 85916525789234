import React from 'react'
import {useTranslation} from 'react-i18next'

import type {Blockchain} from '../../types'
import {useGetBlockchainName} from '../../utils/translations'
import type {AccountName} from '../../wallet'

import type {AlertProps} from './atoms/Alert'
import {InlineError, ModalError, CenteredError, NaError} from './Error'

function useTranslationsUtils(blockchain: Blockchain) {
  const {t} = useTranslation()
  const getBlockchainName = useGetBlockchainName()
  return {t, blockchainLabel: getBlockchainName(blockchain)}
}

type BlockchainProps = {blockchain: Blockchain}

export function LoadHistoryError() {
  const {t} = useTranslation()
  return <InlineError error={t('could_not_load_history')} />
}

export function LoadBlockchainAccountsError(props: BlockchainProps) {
  const {t, blockchainLabel} = useTranslationsUtils(props.blockchain)
  return (
    <InlineError
      error={t('could_not_load_blockchain_accounts', {
        blockchain: blockchainLabel,
      })}
    />
  )
}

export function LoadBlockchainAccountsModalError(props: BlockchainProps) {
  const {t, blockchainLabel} = useTranslationsUtils(props.blockchain)
  return (
    <CenteredError
      error={t('could_not_load_blockchain_accounts', {
        blockchain: blockchainLabel,
      })}
    />
  )
}

export function LoadAccountsError() {
  const {t} = useTranslation()
  return <InlineError error={t('could_not_load_accounts')} />
}

export function LoadAccountsModalError() {
  const {t} = useTranslation()
  return <CenteredError error={t('could_not_load_accounts')} />
}

export function LoadAccountError() {
  const {t} = useTranslation()
  return <InlineError error={t('Could not load account')} />
}

export function LoadStakingDataError(props: BlockchainProps) {
  const {t, blockchainLabel} = useTranslationsUtils(props.blockchain)
  return (
    <InlineError
      error={t('could_not_load_staking_data', {blockchain: blockchainLabel})}
    />
  )
}

export function TotalBalanceInlineError() {
  const {t} = useTranslation()
  return <InlineError error={t('could_not_load_total_balance')} />
}

export function TotalBalanceNaError() {
  const {t} = useTranslation()
  return <NaError error={t('could_not_load_total_balance')} />
}

export function LoadChartError() {
  const {t} = useTranslation()
  return <InlineError error={t('could_not_load_chart_data')} />
}

export function ValueInCurrencyError() {
  const {t} = useTranslation()
  return (
    <NaError error={t('could_not_load_value_in_currency')} severity="neutral" />
  )
}

export function SomeValueInCurrencyErroIndicator() {
  const {t} = useTranslation()
  return (
    <NaError
      error={t('could_not_load_some_value_in_currency')}
      severity="neutral"
      hideLabel
    />
  )
}

export function LoadAccountTokenError({tokenId}: {tokenId: string}) {
  const {t} = useTranslation()
  return <InlineError error={t('could_not_load_account_token', {tokenId})} />
}

export function TransactionModalError() {
  const {t} = useTranslation()
  return <ModalError error={t('Could not load transaction data')} />
}

export function StakingModalError(props: BlockchainProps) {
  const {t, blockchainLabel} = useTranslationsUtils(props.blockchain)
  return (
    <ModalError
      error={t('could_not_load_staking_data', {blockchain: blockchainLabel})}
    />
  )
}

export function TokensError() {
  const {t} = useTranslation()
  return <CenteredError error={t('Could not load tokens data')} />
}

export function AssetCreateAccountError(props: BlockchainProps) {
  const {t, blockchainLabel} = useTranslationsUtils(props.blockchain)
  return (
    <CenteredError
      error={t('could_not_load_create_account', {blockchain: blockchainLabel})}
    />
  )
}

export function NFTAccountsError({accountName}: {accountName: AccountName}) {
  const {t} = useTranslation()
  return (
    <InlineError error={t('could_not_load_nfts_for_account', {accountName})} />
  )
}

export function TokenRowError({
  onClose,
  tokenName,
}: {
  tokenName?: string
  onClose?: AlertProps['onClose']
}) {
  const {t} = useTranslation()
  return (
    <InlineError
      error={t('could_not_load_token_data', {tokenName: tokenName || ''})}
      {...{onClose}}
    />
  )
}

export function ExchangeHistoryError() {
  const {t} = useTranslation()
  return <InlineError error={t('Could not load exchange history')} />
}

export function ExportHistoryError() {
  const {t} = useTranslation()
  return (
    <InlineError
      error={t(
        'Could not load data necessary for exporting transaction history',
      )}
    />
  )
}

export function ImportTxError({
  blockchain,
  onBack,
  onClose,
}: BlockchainProps & {
  onBack: () => void
  onClose: () => void
}) {
  const {t, blockchainLabel} = useTranslationsUtils(blockchain)
  return (
    <ModalError
      error={t('could_not_load_tx_fee', {
        blockchain: blockchainLabel,
      })}
      onBack={onBack}
      onClose={onClose}
    />
  )
}
