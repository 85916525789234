import queryString from 'query-string'
import {useLocation, useParams, useHistory} from 'react-router-dom'

import type {RampQueryStringDefaultOptions} from 'src/pages/buySell/MoonpayFlowModal'

import type {AssetDetailBlockchain} from '../blockchainTypes'
import type {BaseAction, AssetTab, generateAssetDetailRoutes} from '../router'
import {
  routeTo,
  appendBaseAction,
  withQueryString,
  encodeTokenId,
} from '../router'
import type {Blockchain, TokenId} from '../types'
import type {AccountId, CryptoProviderType} from '../wallet'

function useQueryParams() {
  return queryString.parse(useLocation().search)
}

// TOKENS
export const useTokenIdRouteOption = (): TokenId | null => {
  const queryParams = useQueryParams()
  return (queryParams?.token as TokenId) || null
}

// ASSET DETAIL
export type AssetDetailPathParams = {
  blockchain: AssetDetailBlockchain
  accountId: AccountId
  tab: AssetTab
}
export type AssetDetailQueryParams = {tokenId?: TokenId}
export type AssetDetailParams = AssetDetailPathParams & AssetDetailQueryParams

export const useRouteToAssets = () => {
  const history = useHistory()
  return () => history.push(routeTo.portfolio.assets.index)
}

export const useRouteToAssetDetail = (
  blockchain: Blockchain,
  accountId: AccountId,
  tokenId?: TokenId,
) => {
  const history = useHistory()
  return () =>
    history.push(
      withQueryString(
        routeTo.portfolio.assets
          .blockchain(blockchain)
          .detail.account(accountId)
          .tab('history').index,
        encodeTokenId(tokenId),
      ),
    )
}

export const useRouteToAssetDetailAction = (
  action: BaseAction,
  tokenId?: TokenId,
) => {
  const history = useHistory()
  const location = useLocation()
  return () =>
    history.push(
      withQueryString(
        appendBaseAction(location.pathname, action),
        encodeTokenId(tokenId),
      ),
    )
}

export const useAssetDetailRouteOptions = () => {
  const pathParams = useParams<AssetDetailPathParams>()

  return {
    accountId: pathParams.accountId,
    blockchain: pathParams.blockchain,
    tab: pathParams.tab,
    tokenId: useTokenIdRouteOption(),
  }
}

type AssetDetailRoutes = ReturnType<typeof generateAssetDetailRoutes>

export type BlockchainAssetDetailRoutes = {
  blockchain: (blockchain: AssetDetailBlockchain | ':blockchain') => {
    index: string
    detail: AssetDetailRoutes
  }
}

// ACCOUNT
export type AccountActionParams = {blockchain: Blockchain; accountId: AccountId}

export const useRouteToAccountAction = (
  action: BaseAction,
  blockchain: Blockchain,
  accountId: AccountId,
  tokenId?: TokenId,
) => {
  const history = useHistory()
  return () =>
    history.push(
      withQueryString(
        routeTo.portfolio.accounts.account(blockchain, accountId)[action],
        encodeTokenId(tokenId),
      ),
    )
}

// EXCHANGE
type ExchangeActionParams = {blockchain: Blockchain; accountId: AccountId}
type ExchangeDetailParams = {exchangeId: string}

export const useExchangeDetailRouteOptions = (): ExchangeDetailParams =>
  useParams<ExchangeDetailParams>()

const DEFAULT_EXCHANGE_BLOCKCHAIN = 'cardano'

export const useRouteToExchangeAction = (
  blockchain?: Blockchain,
  tokenId?: TokenId,
) => {
  const history = useHistory()
  return () =>
    history.push(
      withQueryString(
        routeTo.portfolio.exchange.action(
          blockchain || DEFAULT_EXCHANGE_BLOCKCHAIN,
        ).exchange,
        encodeTokenId(tokenId),
      ),
    )
}

export const useAssetsRouteToExchange = (blockchain?: Blockchain | null) => {
  const history = useHistory()
  return () =>
    history.push(
      routeTo.portfolio.assets.blockchain(
        blockchain || DEFAULT_EXCHANGE_BLOCKCHAIN,
      ).exchange,
    )
}

// BUY/SELL
export const useRouteToBuySellAction = () => {
  const history = useHistory()

  return (options?: RampQueryStringDefaultOptions) => {
    const baseUrl = routeTo.portfolio.assets.buySell

    if (options != null) {
      const url = withQueryString(baseUrl, options)
      history.push(url)
    } else {
      history.push(routeTo.portfolio.assets.buySell)
    }
  }
}

// BASE ACTION MODAL
export const useBaseActionRouteOptions = (): AccountActionParams =>
  useParams<AssetDetailParams | AccountActionParams | ExchangeActionParams>()

export const useAddAccountRouteOptions = () =>
  useParams<{
    blockchain: Blockchain
    cryptoProviderType: CryptoProviderType
  }>()

// TOKENS
export const useTokenIdFromRoute = <T extends string = TokenId>():
  | T
  | undefined => {
  const token = new URLSearchParams(useLocation().search).get('token')
  return token !== null ? (token as T) : undefined
}
