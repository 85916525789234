import type {EvmAccountStoredData} from '@nufi/wallet-evm'
import {
  createGetIsAccountUsed,
  EvmAccountSource,
  EvmWallet,
} from '@nufi/wallet-evm'

import config from 'src/config'

import {addEvmAccounts as addAccounts} from '../AccountsStoreManager'
import type {AccountsStoreManager} from '../AccountsStoreManager'

import {accountsStore} from './evmAccountStore'
import {cryptoProviders} from './evmCryptoProviders'
import {getEvmManager} from './evmManagers'

const createEvmManagers = (): {
  wallet: EvmWallet
  accountsStore: AccountsStoreManager<EvmAccountStoredData>
} => {
  const accountSource = new EvmAccountSource(
    createGetIsAccountUsed(
      (blockchain) => getEvmManager(blockchain).blockchainApi,
    ),
  )

  const wallet = new EvmWallet(config.isTestnet, {
    accountSource,
    addAccounts,
    ...cryptoProviders,
  })

  return {
    wallet,
    accountsStore,
  }
}

export const evm = createEvmManagers()
