import {Refresh as RefreshIcon} from '@mui/icons-material'
import {Grid, IconButton, Tooltip, Box, styled} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {InlineLoading} from '../../components/visual/Loading'

type ReloadButtonProps = {
  onReload: () => unknown
  isLoading: boolean
}

/**
 * This refresh button variant has its "refresh" icon replaced by loading indicator
 * in case it is loading. It is suitable for usage in cases where we do not expect
 * a lot of unreliable queries being run or there is little space left like in dapp connector.
 */
export function CompactReloadButton({onReload, isLoading}: ReloadButtonProps) {
  const {t} = useTranslation()

  return (
    <Grid container justifyContent="flex-end" alignItems="center">
      <IconButton disabled={isLoading} onClick={onReload} size="large">
        {!isLoading ? (
          <Tooltip title={<>{t('Reload page')}</>}>
            <RefreshIcon color="primary" />
          </Tooltip>
        ) : (
          <InlineLoading color="primary" size={24} />
        )}
      </IconButton>
    </Grid>
  )
}

/**
 * This refresh button variant has its "refresh" icon always clickable
 * and the loading indicator is displayed to the right of it in a case when it is active.
 * This is useful in cases when we want to give users a chance to perform
 * refresh even when something else is loading for a long time (e.g. some
 * blockchain api has issues and we are retrying it in a background)
 * and thus would prevent them from performing the refresh.
 */
export function WideReloadButton({onReload, isLoading}: ReloadButtonProps) {
  const {t} = useTranslation()

  return (
    <Grid container justifyContent="flex-end" alignItems="center">
      <LoadingIndicatorWrapper
        sx={{
          visibility: isLoading ? 'visible' : 'hidden',
        }}
      >
        <InlineLoading color="inherit" size={24} />
      </LoadingIndicatorWrapper>
      <IconButton onClick={onReload} size="large">
        <Tooltip title={<>{t('Reload page')}</>}>
          <RefreshIcon color="primary" />
        </Tooltip>
      </IconButton>
    </Grid>
  )
}

const LoadingIndicatorWrapper = styled(Box)(({theme}) => ({
  marginRight: theme.spacing(1.5),
  marginLeft: theme.spacing(3),
  display: 'flex',
  color: theme.palette.action.active,
}))
