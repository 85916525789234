import type BigNumber from 'bignumber.js'
import React from 'react'

import {FormattedAssetPriceChange} from 'src/components/formatting/FormattedAssetPriceChange'
import type {ConvertedBalanceResponse} from 'src/features/assets/domain/conversions'
import {useSettings} from 'src/features/profile/application'

import type {TokenBlockchain} from '../../../blockchainTypes'
import {
  FormattedAssetPair,
  FormattedAssetPairLayout,
  FormattedCurrency,
  FormattedTokenName,
  TypographyLoader,
} from '../../../components'
import type {AccountId, TokenMetadata} from '../../../types'

import {AssetItemLayout} from './AssetItemUtils'

type AssetItemProps = {
  blockchain: TokenBlockchain
  tokenMetadata: TokenMetadata
  totalBalance: BigNumber
  defaultAccountId?: AccountId
  isNft: boolean
  totalBalanceToCurrency?: ConvertedBalanceResponse
  price?: ConvertedBalanceResponse
  priceChange?: number
}

export function TokenAssetItem({
  blockchain,
  totalBalance,
  tokenMetadata,
  defaultAccountId,
  isNft,
  totalBalanceToCurrency,
  priceChange,
  price,
}: AssetItemProps) {
  const {currency} = useSettings()

  const formattedTokenAmount = (
    <FormattedAssetPair
      amount={totalBalance}
      blockchain={blockchain}
      tokenMetadata={tokenMetadata}
      ellipsizeLabel
      isSensitiveInformation
      amountFormat="compact"
      conversionProps={{
        convertedBalance: totalBalanceToCurrency || {type: 'is-loading'},
      }}
    />
  )

  return (
    <AssetItemLayout
      blockchain={blockchain}
      defaultAccountId={defaultAccountId}
      isNft={isNft}
      priceCol={
        <FormattedAssetPairLayout
          primaryElement={
            price == null ? (
              <TypographyLoader variant="body2" />
            ) : (
              <FormattedCurrency
                currency={currency}
                isSensitiveInformation={false}
                convertedBalance={price}
              />
            )
          }
          secondaryElement={
            priceChange == null ? null : (
              <FormattedAssetPriceChange percentage={priceChange} colorize />
            )
          }
        />
      }
      FormattedLabel={
        <FormattedTokenName
          blockchain={blockchain}
          tokenInfo={tokenMetadata}
          ellipsize
          ellipsizeLength={30}
        />
      }
      totalBalanceCol={formattedTokenAmount}
      availableBalanceCol={formattedTokenAmount}
      tokenMetadata={tokenMetadata}
    />
  )
}
