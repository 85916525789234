import React from 'react'
import {useTranslation} from 'react-i18next'
import {Route, Switch} from 'react-router-dom'

import {filterVisibleProfiles, useProfileInfos} from '../../appStorage'
import {
  QueryGuard,
  CenteredError,
  TopScreenIncognitoBanner,
  useIncognitoHandlers,
} from '../../components'
import {routeTo} from '../../router'
import {CreateProfilePage, RestoreProfilePage, MetamaskLogin} from '../profile'
import LoginPage from '../profile/Login'

import WelcomePage from './WelcomePage'

export default function LogoutMain() {
  const {showIncognitoBanner, onClose} = useIncognitoHandlers()
  const profileInfosQuery = useProfileInfos()
  const {t} = useTranslation()

  return (
    <>
      {showIncognitoBanner && (
        <TopScreenIncognitoBanner onClose={() => onClose()} />
      )}
      <QueryGuard
        {...profileInfosQuery}
        ErrorElement={<CenteredError error={t('Could not load wallet info')} />}
        loadingVariant="centered"
      >
        {(profiles) => (
          <Switch>
            <Route
              exact
              path={routeTo.createProfile}
              render={() => <CreateProfilePage profiles={profiles} />}
            />
            <Route
              exact
              path={routeTo.metamaskLogin}
              render={() => <MetamaskLogin />}
            />
            <Route
              exact
              path={routeTo.restoreProfile}
              render={() => <RestoreProfilePage />}
            />
            {filterVisibleProfiles(profiles).length > 0 ? (
              <Route
                exact
                path={routeTo.auth}
                render={() => <LoginPage profiles={profiles} />}
              />
            ) : (
              <Route exact path={routeTo.auth} component={WelcomePage} />
            )}
          </Switch>
        )}
      </QueryGuard>
    </>
  )
}
