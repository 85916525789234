import {Avatar, Box, Grid, Link} from '@mui/material'
import type {SolanaValidatorInfo} from '@nufi/wallet-solana'
import BigNumber from 'bignumber.js'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {
  FormattedAsset,
  FormattedPercentage,
  SolanaValidatorLink,
} from '../../../../components'
import ValidatorInfoItem from '../../common/actionModals/StakeModalValidatorInfo'

type FormattedValidatorInfoProps = {
  validatorInfo: SolanaValidatorInfo | undefined
}

export default function FormattedValidatorInfo({
  validatorInfo,
}: FormattedValidatorInfoProps) {
  const {t} = useTranslation()
  const metadata = validatorInfo?.metadata
  const [validatorInfoXs, avatarXs] = metadata?.avatarUrl ? [10, 2] : [12, 0]

  return (
    <Grid container direction="row">
      <Grid container xs={validatorInfoXs}>
        {metadata?.name && (
          <ValidatorInfoItem itemName={t('Name')} itemValue={metadata.name} />
        )}
        {metadata?.apy ? ( // ternary due to apy possibly being 0.000x and rendering "0"
          <ValidatorInfoItem
            itemName={t('APY')}
            itemValue={
              <FormattedPercentage value={new BigNumber(metadata.apy)} />
            }
          />
        ) : null}
        {metadata?.details && (
          <ValidatorInfoItem
            itemName={t('Description')}
            itemValue={metadata.details}
          />
        )}
        <ValidatorInfoItem
          itemName={t('Public key')}
          itemValue={
            <SolanaValidatorLink
              validatorId={validatorInfo?.publicKey.toString() as string}
              validatorName={validatorInfo?.publicKey.toString()}
            />
          }
        />
        <ValidatorInfoItem
          itemName={t('Tax')}
          itemValue={
            validatorInfo?.taxPercentage && `${validatorInfo.taxPercentage}%`
          }
        />
        <ValidatorInfoItem
          itemName={t('Active stake')}
          itemValue={
            validatorInfo?.activatedStake && (
              <FormattedAsset
                amount={validatorInfo?.activatedStake}
                blockchain={'solana'}
                isSensitiveInformation={false}
              />
            )
          }
        />
        {metadata?.website && (
          <ValidatorInfoItem
            itemName={t('Homepage')}
            itemValue={
              <Link rel="noopener" target="_blank" href={metadata.website}>
                {metadata.website}
              </Link>
            }
          />
        )}
      </Grid>
      <Grid container xs={avatarXs} justifyContent="flex-end">
        {metadata?.avatarUrl && (
          <Box my={1} mx={1}>
            <Avatar
              variant="rounded"
              alt="Validator Avatar"
              src={metadata.avatarUrl}
            />
          </Box>
        )}
      </Grid>
    </Grid>
  )
}
