import {assertBlockchainIsInSubset} from '@nufi/wallet-common'
import {opStackBlockchains} from '@nufi/wallet-evm'
import type {OpStackBlockchain} from '@nufi/wallet-evm'
import BigNumber from 'bignumber.js'
import React from 'react'

import {safeAssertUnreachable} from 'src/utils/assertion'
import type {EvmBlockchain, EvmFeeModelType, EvmWei} from 'src/wallet/evm'
import {
  calculateLondonFee,
  calculateSimpleFee,
  gweiToWei,
} from 'src/wallet/evm/sdk/utils'

import {inferMaxFeePerGasFromForm as inferLondonMaxFeePerGasFromForm} from '../gas/LondonGasOptions'
import type {LondonGasSchema, SimpleGasSchema} from '../gas/schema'
import type {GasSchema} from '../gas/types'

import {getOpStackFeeModelData, WithOpStackFeeModelData} from './opStack'
import type {
  OpStackFeeModelExchangeCustomOptions,
  OpStackFeeModelCustomOptions,
} from './opStack'

const getPreLondonTxFee = <TBlockchain extends EvmBlockchain>(
  values: GasSchema,
) =>
  calculateSimpleFee<TBlockchain>({
    gasPrice: gweiToWei((values as SimpleGasSchema).gasPrice),
    gasLimit: new BigNumber(values.gasLimit) as EvmWei<TBlockchain>,
  })

const getLondonTxFee = <TBlockchain extends EvmBlockchain>(values: GasSchema) =>
  calculateLondonFee<TBlockchain>({
    maxFeePerGas: inferLondonMaxFeePerGasFromForm(values as LondonGasSchema),
    gasLimit: new BigNumber(values.gasLimit) as EvmWei<TBlockchain>,
  })

export type FeeModelData<TBlockchain extends EvmBlockchain> = {
  getTxFee: (values: GasSchema) => EvmWei<TBlockchain>
}

export type WithFeeModelDataProps<TBlockchain extends EvmBlockchain> = {
  blockchain: TBlockchain
  feeModelType: EvmFeeModelType
  customOpStackFeeModelOptions: OpStackFeeModelCustomOptions
  children: (feeModelData: FeeModelData<TBlockchain>) => JSX.Element
  disableQuery?: boolean
}

export const WithFeeModelData = <TBlockchain extends EvmBlockchain>({
  blockchain,
  feeModelType,
  children,
  customOpStackFeeModelOptions,
}: WithFeeModelDataProps<TBlockchain>) => {
  switch (feeModelType) {
    case 'pre-london':
      return children({getTxFee: getPreLondonTxFee})
    case 'london': {
      return children({getTxFee: getLondonTxFee})
    }
    case 'opStack':
      assertBlockchainIsInSubset(blockchain, opStackBlockchains)
      return (
        <WithOpStackFeeModelData
          {...{
            blockchain,
            customOpStackFeeModelOptions,
            children: children as (
              feeModelData: FeeModelData<OpStackBlockchain>,
            ) => JSX.Element,
          }}
        />
      )
    default:
      return safeAssertUnreachable(feeModelType)
  }
}

export const getFeeModelData = async <TBlockchain extends EvmBlockchain>({
  blockchain,
  feeModelType,
  customOpStackFeeModelOptions,
}: {
  blockchain: TBlockchain
  feeModelType: EvmFeeModelType
  customOpStackFeeModelOptions: OpStackFeeModelExchangeCustomOptions<TBlockchain>
}): Promise<FeeModelData<TBlockchain>> => {
  switch (feeModelType) {
    case 'pre-london':
      return {getTxFee: getPreLondonTxFee}
    case 'opStack':
      assertBlockchainIsInSubset(blockchain, opStackBlockchains)
      return getOpStackFeeModelData(
        blockchain,
        customOpStackFeeModelOptions as OpStackFeeModelExchangeCustomOptions<
          typeof blockchain
        >,
      ) as Promise<FeeModelData<TBlockchain>>
    case 'london': {
      return {getTxFee: getLondonTxFee}
    }
    default:
      return safeAssertUnreachable(feeModelType)
  }
}
