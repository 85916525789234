import {Settings} from '@mui/icons-material'
import {IconButton} from '@mui/material'
import {styled} from '@mui/styles'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {WithTooltip} from 'src/components'
import {useUiStore} from 'src/store/ui'

export const ManageBlockchainsIconButton = () => {
  const {t} = useTranslation()
  const {setIsBlockchainManagementModalOpen} = useUiStore()

  return (
    <StyledIconButton onClick={() => setIsBlockchainManagementModalOpen(true)}>
      <WithTooltip title={t('Manage blockchains')}>
        <Settings />
      </WithTooltip>
    </StyledIconButton>
  )
}

const StyledIconButton = styled(IconButton)(({theme}) => ({
  padding: 0,
  '&:hover': {
    color: theme.palette.primary.main,
    backgroundColor: 'transparent',
  },
}))
