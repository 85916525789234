import {Stack, Typography} from '@mui/material'
import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {TokenSelectField} from 'src/components'
import type {TokenMetadata} from 'src/types'

import {isOperationSupportedOnAsset} from '../../../../../domain'
import type {RampCryptoAsset} from '../../../../../domain'
import {useRampFlowConfig} from '../../../state/rampFlowConfig'

import {useRampFlowDetailsForm} from './detailsForm'

export const RampFlowAssetSelect = () => {
  const {t} = useTranslation()
  const {
    formDispatch,
    values: {operation, asset},
    errors,
    touched,
  } = useRampFlowDetailsForm()

  const {assetSelectFilter, vm} = useRampFlowConfig()
  const {data, isLoading, isError: isFetchError} = vm.useRampAssets()

  const options = useMemo(() => {
    return (data ?? [])
      .filter((asset) => isOperationSupportedOnAsset(operation, asset))
      .flatMap((asset) => (asset.type === 'crypto' ? [asset] : []))
      .filter(
        assetSelectFilter
          ? (asset) => assetSelectFilter(asset, operation)
          : () => true,
      )
      .map((cryptoAsset) => rampCryptoAssetToOption(cryptoAsset))
  }, [data, operation, assetSelectFilter])

  const errorMessage: string | undefined = isFetchError
    ? t('Something went wrong')
    : touched.asset
      ? errors.asset
      : undefined

  return (
    <Stack spacing={1}>
      <TokenSelectField
        options={options}
        isLoading={isLoading}
        error={!!errorMessage}
        label={t('Select asset')}
        value={asset ? rampCryptoAssetToOption(asset) : null}
        onChange={(option) =>
          formDispatch({type: 'setAsset', payload: {asset: option?.rampAsset}})
        }
      />
      <Typography color="error" variant="caption" paragraph={false}>
        {errorMessage}
      </Typography>
    </Stack>
  )
}

const rampCryptoAssetToOption = (asset: RampCryptoAsset) => ({
  id: asset.rampId,
  name: asset.name as TokenMetadata['name'],
  blockchain: asset.blockchain,
  tokenId: asset.tokenId ?? undefined,
  rampAsset: asset,
})
