import {Box, LinearProgress} from '@mui/material'
import React from 'react'
import ContentLoader from 'react-content-loader'

import {SIDEBAR_WIDTH} from '../../../constants'
import {
  useResponsiveDimensions,
  ContentLoaderBackground,
} from '../../../utils/layoutUtils'

import {AccountListItemLayout} from './AccountListLayout'

const _AccountItemLoading = ({maxWidth}: {maxWidth: number}) => (
  <ContentLoader
    viewBox={`0 0 ${maxWidth} 80`}
    backgroundColor={ContentLoaderBackground()}
  >
    <circle cx="20" cy="27" r="16" />
    <rect x="50" y="13" rx="4" ry="4" width={maxWidth - 50} height="10" />
    <rect x="50" y="28" rx="3" ry="3" width={maxWidth - 200} height="10" />
    <rect x="5" y="50" rx="3" ry="3" width={maxWidth - 50} height="25" />
  </ContentLoader>
)

export const AccountItemLoading = () => {
  const dimensions = useResponsiveDimensions()
  return <_AccountItemLoading maxWidth={dimensions.width - SIDEBAR_WIDTH} />
}

export const AccountsListLoading = () => (
  <>
    <AccountItemLoading />
    <AccountItemLoading />
    <AccountItemLoading />
  </>
)

export const AccountsCardLoading = () => {
  const createTextContentLoader = (width: number, height: number) => (
    <Box
      sx={{
        width,
        height: height + 4, // safe margin
      }}
    >
      <ContentLoader
        viewBox={`0 0 ${width} ${height}`}
        backgroundColor={ContentLoaderBackground()}
      >
        <rect x="0" y="0" rx="4" ry="4" width={width} height={height} />
      </ContentLoader>
    </Box>
  )
  return (
    <Box
      sx={{
        bgcolor: 'background.paper',
        py: 3,
        px: 2,
        width: '100%',
        height: 180, // keep consistent with AccountCard height
        mb: 2,
      }}
    >
      <AccountListItemLayout
        titleCol={createTextContentLoader(50, 14)}
        priceCol={createTextContentLoader(100, 14)}
        balanceCol={createTextContentLoader(100, 14)}
        stakingCol={createTextContentLoader(100, 14)}
        actionsCol={createTextContentLoader(100, 14)}
      />
      <Box height={30} />
      <AccountListItemLayout
        titleCol={
          <Box {...{width: '100%', height: 54}}>
            <ContentLoader
              viewBox="0 0 100% 50"
              backgroundColor={ContentLoaderBackground()}
            >
              <circle cx="12" cy="25" r="12" />
              <rect x="30" y="0" rx="4" ry="4" width="50" height="16" />
              <rect x="30" y="22" rx="4" ry="4" width="80%" height="12" />
              <rect x="30" y="40" rx="4" ry="4" width="80" height="12" />
            </ContentLoader>
          </Box>
        }
        priceCol={createTextContentLoader(100, 20)}
        balanceCol={createTextContentLoader(100, 20)}
        stakingCol={createTextContentLoader(100, 20)}
        actionsCol={createTextContentLoader(150, 32)}
      />
      <Box height={20} />
      <Box
        sx={{
          width: '100%',
          height: 20,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {createTextContentLoader(150, 16)}
      </Box>
    </Box>
  )
}

export const LinearLoader = () => (
  <Box width="60px">
    <LinearProgress />
  </Box>
)

const _AccountTokenItemLoading = ({maxWidth}: {maxWidth: number}) => (
  <ContentLoader
    viewBox={`0 0 ${maxWidth} 54`}
    backgroundColor={ContentLoaderBackground()}
  >
    <circle cx="18" cy="28" r="15" />
    <rect x="50" y="13" rx="4" ry="4" width={maxWidth - 50} height="10" />
    <rect x="50" y="27" rx="3" ry="3" width={maxWidth - 200} height="10" />
  </ContentLoader>
)

export const AccountTokenItemLoading = () => {
  const dimensions = useResponsiveDimensions()
  return (
    <_AccountTokenItemLoading maxWidth={dimensions.width - SIDEBAR_WIDTH} />
  )
}
