import {buildTokenTransferParams} from '@nufi/wallet-evm'
import BigNumber from 'bignumber.js'
import React, {useEffect} from 'react'

import {
  commonFormatTokenAmount,
  commonParseTokenAmount,
} from '../../../../wallet'
import type {
  EvmAddress,
  EvmBlockchain,
  EvmAccountInfo,
  EvmTokenMetadata,
} from '../../../../wallet/evm'
import {
  useSignTransfer,
  cachedGetTokenTransferGasLimitEstimate,
} from '../../../../wallet/evm'
import {ensureSingleTokenAmount} from '../../common/utils'
import {parseNonceFormValues} from '../common'
import {EvmSendModalContent} from '../common/SendModalContent'
import type {GasFieldStatus} from '../gas/common'
import {
  useDebouncedGasLimit,
  validAmountForGasLimitEstimationOrZero,
} from '../gas/gasLimitEstimate'
import type {BaseSchema} from '../schema'
import type {EvmSendTokenModalProps} from '../types'

export const EvmSendTokenModal = <TBlockchain extends EvmBlockchain>({
  metadataById,
  tokenId,
  fromAccount,
  formikProps,
  blockchain,
  gasProps,
  tokenBalances,
  ...rest
}: EvmSendTokenModalProps<TBlockchain>) => {
  const sign = useSignTransfer(blockchain)
  const {values, setValues, errors, status} = formikProps

  const gasLimitKey: keyof BaseSchema = 'gasLimit'
  const gasLimitStatus = status?.[gasLimitKey] as GasFieldStatus | undefined

  const tokenBalance =
    tokenBalances.find((t) => t.accountId === fromAccount.id)?.balance ||
    new BigNumber(0)

  const addressToEstimateAgainst = (
    values.toAddress && !errors.toAddress
      ? values.toAddress
      : fromAccount.address
  ) as EvmAddress<TBlockchain>

  const tokenMetadata = metadataById[tokenId] as EvmTokenMetadata<TBlockchain>

  const amount = commonParseTokenAmount(
    ensureSingleTokenAmount(values.assets),
    tokenMetadata.decimals,
  )

  const gasLimitEstimateData = buildTokenTransferParams({
    tokenMetadata,
    fromAddress: (fromAccount as EvmAccountInfo<TBlockchain>).address,
    toAddress: addressToEstimateAgainst,
    amount: validAmountForGasLimitEstimationOrZero(amount, tokenBalance),
  })

  const {
    gasLimitSubmitOverlay,
    getDebouncedGasLimit,
    createOnBeforeDetailsSubmit,
  } = useDebouncedGasLimit({
    blockchain,
    setGasLimit: (gasLimit) => setValues((values) => ({...values, gasLimit})),
    getGasLimitEstimate: cachedGetTokenTransferGasLimitEstimate<TBlockchain>,
    timeoutMs: 500,
  })

  useEffect(() => {
    getDebouncedGasLimit?.({
      gasLimitArgs: gasLimitEstimateData,
      values,
      gasLimitStatus,
    })
    // JSON.stringify used so that we do not have to use more verbose `useMemo`
  }, [getDebouncedGasLimit, JSON.stringify(gasLimitEstimateData)])

  const onTxSign = async () =>
    sign.mutateAsyncSilent({
      gasOptions: gasProps.formValuesToGasOptions(values),
      fromAccountId: values.accountId,
      variant: 'tokenTransfer',
      ...parseNonceFormValues(values),
      ...buildTokenTransferParams({
        tokenMetadata: metadataById[tokenId] as EvmTokenMetadata<TBlockchain>,
        fromAddress: (fromAccount as EvmAccountInfo<TBlockchain>).address,
        toAddress: values.toAddress as EvmAddress<TBlockchain>,
        amount: new BigNumber(ensureSingleTokenAmount(values.assets)),
      }),
    })

  return (
    <>
      {gasLimitSubmitOverlay}
      <EvmSendModalContent
        onBeforeDetailsSubmit={createOnBeforeDetailsSubmit({
          gasLimitArgs: gasLimitEstimateData,
          values,
          gasLimitStatus,
        })}
        {...rest}
        {...{
          metadataById,
          tokenId,
          fromAccount,
          formikProps,
          blockchain,
          gasProps,
          tokenBalances,
        }}
        getAssetFieldProps={(asset, {name}) => ({
          maxAmountOptions: tokenBalance.isGreaterThan(0)
            ? {
                onMaxAmount: () => {
                  formikProps.setFieldValue(
                    name,
                    commonFormatTokenAmount(
                      tokenBalance,
                      metadataById[tokenId]!.decimals,
                    ),
                  )
                },
              }
            : undefined,
        })}
        signProps={{
          ...sign,
          mutateAsyncSilent: onTxSign,
        }}
      />
    </>
  )
}
