import {Box} from '@mui/material'
import React from 'react'

export const TransBold = <Box component="span" fontWeight="fontWeightMedium" />
export const TransBoldNoBreak = (
  <Box
    component="span"
    fontWeight="fontWeightMedium"
    style={{wordBreak: 'break-all'}}
  />
)
