import React, {useState} from 'react'

import {InlineLoading, MutationGuard} from 'src/components'
import type {WeakUseMutationResult} from 'src/utils/mutation-utils'

import type {Blockchain, CryptoProviderType, HwVendor} from '../../../types'
import {safeAssertUnreachable} from '../../../utils/assertion'
import {SignGridPlusScreen} from '../SignGridPlus'
import {SignLedgerScreen} from '../SignLedger'
import {BaseSignMnemonicScreen} from '../SignMnemonic'
import {SignTrezorScreen} from '../SignTrezor'
import {BaseSubmitScreen} from '../Submit'

export type BaseSignTxFlowProps<
  TSignResponse,
  TSubmitResponse extends string,
> = {
  blockchain: Blockchain
  cryptoProviderType: CryptoProviderType
  onClose: () => void
  onBack: () => void
  onSuccess: (signedTx: TSubmitResponse) => void
  signProps: WeakUseMutationResult<TSignResponse, unknown, unknown, unknown> & {
    mutateAsyncSilent: () => Promise<TSignResponse | null>
  }
  submitProps: WeakUseMutationResult<
    TSubmitResponse,
    unknown,
    unknown,
    unknown
  > & {
    mutateAsyncSilent: (tx: TSignResponse) => Promise<TSubmitResponse | null>
  }
  ModalHeader: React.ReactNode
  DeviceReadyState: ({hwVendor}: {hwVendor: HwVendor}) => React.ReactElement
  safeDistanceFromEventInMs?: number
}

/**
 * Component responsible for signing and submitting of transactions, displaying
 * loading and error states. Note that:
 * * mnemonic transactions are automatically submitted when this component gets mounted, so it
 * should be rendered only when user already invoked sign tx flow (e.g. clicked on "Sign" button)
 * * `authorizeTxSubmission` should be used to wrap function that handles user interaction, using
 * which user invokes the sign flow. By default there can not be more than 3 seconds gap between calling this function and
 * rendering this component, otherwise it will throw an error. You can customize this using
 * `safeDistanceFromEventInMs` if ever needing bigger time frame.
 * * this component does not handle "success" state, but only calls `onSuccess` function and it should be
 * the parent responsibility to handle it.
 * * `onBack` handler should unmount this component, otherwise it will stay empty
 */
export function BaseSignTxFlow<TSignResponse, TSubmitResponse extends string>({
  blockchain,
  cryptoProviderType,
  signProps,
  submitProps,
  onClose,
  onSuccess,
  onBack: _onBack,
  ModalHeader,
  DeviceReadyState,
  safeDistanceFromEventInMs = 3000,
}: BaseSignTxFlowProps<TSignResponse, TSubmitResponse>) {
  const [signTxFlowState, setSignTxFlowState] = useState<
    'signing' | 'submitting' | 'fading-away'
  >('signing')

  const onBack = () => {
    // Setting state to "fading-away" is required so that the assertion for "signProps" data result
    // does not fail (after mutations are being reset).
    setSignTxFlowState('fading-away')

    signProps.reset()
    submitProps.reset()

    _onBack()
  }

  const onTxSubmit = async (signedTx: TSignResponse) => {
    const result = await submitProps.mutateAsyncSilent(signedTx)
    result && onSuccess(result)
  }

  const onTxSignAndSubmit = async () => {
    const signedTx = await signProps.mutateAsyncSilent()
    if (!signedTx) return
    setSignTxFlowState('submitting')
    await onTxSubmit(signedTx)
  }

  const commonProps = {
    blockchain,
    onClose,
    onBack,
    ModalHeader,
  }

  switch (signTxFlowState) {
    case 'fading-away':
      return null
    case 'signing':
      switch (cryptoProviderType) {
        case 'metamask':
        case 'mnemonic':
          return (
            <BaseSignMnemonicScreen
              onRetry={onTxSignAndSubmit}
              {...{
                ...commonProps,
                signProps,
                onTxSignAndSubmit,
                safeDistanceFromEventInMs,
              }}
            />
          )
        case 'ledger':
          return (
            <SignLedgerScreen
              onSign={onTxSignAndSubmit}
              ReadyContent={<DeviceReadyState hwVendor="ledger" />}
              signProps={signProps}
              {...commonProps}
            />
          )
        case 'trezor':
          return (
            <SignTrezorScreen
              onSign={onTxSignAndSubmit}
              ReadyContent={<DeviceReadyState hwVendor="trezor" />}
              signProps={signProps}
              {...commonProps}
            />
          )
        case 'gridPlus':
          return (
            <SignGridPlusScreen
              onSign={onTxSignAndSubmit}
              ReadyContent={<DeviceReadyState hwVendor="gridPlus" />}
              signProps={signProps}
              {...commonProps}
            />
          )
        default:
          return safeAssertUnreachable(cryptoProviderType)
      }
    case 'submitting': {
      // seems like in react-query v5 mutation props no longer change
      // right after mutateAsync is called, therefore we need to wrap
      // the component in a MutationGuard
      return (
        <MutationGuard {...signProps} LoadingElement={<InlineLoading />}>
          {(signedTransaction) => (
            <BaseSubmitScreen
              onRetry={() => onTxSubmit(signedTransaction)}
              {...{...commonProps, submitProps}}
            />
          )}
        </MutationGuard>
      )
    }
    default:
      return safeAssertUnreachable(signTxFlowState)
  }
}
