import type {Theme} from '@mui/material'
import {Paper as MuiPaper} from '@mui/material'
import type {PaperProps as MuiPaperProps} from '@mui/material/Paper'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'

type CustomPaperProps = {
  defaultListMargin?: boolean
  defaultListPadding?: boolean
}

type PaperProps = MuiPaperProps & CustomPaperProps

export default function Paper({
  defaultListMargin,
  defaultListPadding,
  ...restProps
}: PaperProps) {
  const classes = useStyles({defaultListMargin, defaultListPadding})

  const paperClasses = {
    root: classes.root,
  }

  return <MuiPaper elevation={0} {...restProps} classes={paperClasses} />
}

const useStyles = makeStyles<Theme, CustomPaperProps>((theme) => ({
  root: ({defaultListMargin, defaultListPadding}) => ({
    border: `1px solid ${theme.palette.divider}`,
    marginBottom: defaultListMargin ? theme.spacing(1) : 0,
    padding: defaultListPadding ? theme.spacing(1) : 0,
  }),
}))
