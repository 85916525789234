import {safeAssertUnreachable} from '@nufi/frontend-common'
import type {CardanoTxPlan} from '@nufi/wallet-cardano'
import {CardanoTxCertificateType} from '@nufi/wallet-cardano'
import {useTranslation} from 'react-i18next'

export function useTags(txPlan: CardanoTxPlan): string[] {
  const {t} = useTranslation()

  const commonLabels = {
    delegation: t('Delegation'),
    metadata: t('Metadata'),
    mint: t('Mint'),
    rewardsWithdrawal: t('Rewards withdrawal'),
  }

  const tags = []

  if (txPlan.mint != null) {
    tags.push(commonLabels.mint)
  }
  if (txPlan.auxiliaryData?.type === 'arbitraryHash') {
    tags.push(commonLabels.metadata)
  }
  if (txPlan.auxiliaryData?.type === 'votingRegistration') {
    tags.push(t('Voting delegation'))
  }
  if (txPlan.withdrawals && txPlan.withdrawals.length > 0) {
    tags.push(commonLabels.rewardsWithdrawal)
  }
  if (txPlan.votingProcedures != null) {
    tags.push(t('Governance action vote'))
  }

  if (txPlan.proposalProcedures != null) {
    tags.push(t('Governance action proposal'))
  }

  if (txPlan.donation != null) {
    tags.push(t('Donation'))
  }

  const certificateTags =
    txPlan.certificates
      ?.map((c) => {
        const type = c.type
        switch (type) {
          case CardanoTxCertificateType.DELEGATION:
            return commonLabels.delegation
          case CardanoTxCertificateType.STAKING_KEY_REGISTRATION:
          case CardanoTxCertificateType.STAKING_KEY_REGISTRATION_CONWAY:
            return t('Staking registration')
          case CardanoTxCertificateType.STAKING_KEY_DEREGISTRATION:
          case CardanoTxCertificateType.STAKING_KEY_DEREGISTRATION_CONWAY:
            return t('Staking deregistration')
          case CardanoTxCertificateType.VOTE_DELEGATION:
            return t('Vote delegation')
          case CardanoTxCertificateType.STAKE_AND_VOTE_DELEGATION:
            return [commonLabels.delegation, t('Vote delegation')]
          case CardanoTxCertificateType.STAKE_REGISTRATION_AND_DELEGATION:
            return [t('Staking registration'), commonLabels.delegation]
          case CardanoTxCertificateType.STAKE_REGISTRATION_AND_VOTE_DELEGATION:
            return [t('Staking registration'), t('Vote delegation')]
          case CardanoTxCertificateType.STAKE_REGISTRATION_AND_STAKE_AND_VOTE_DELEGATION:
            return [
              t('Staking registration'),
              commonLabels.delegation,
              t('Vote delegation'),
            ]
          case CardanoTxCertificateType.AUTHORIZE_COMMITTEE_HOT:
            return t('Committee authorization')
          case CardanoTxCertificateType.RESIGN_COMMITTEE_COLD:
            return t('Committee resignation')
          case CardanoTxCertificateType.DREP_REGISTRATION:
            return t('DRep registration')
          case CardanoTxCertificateType.DREP_DEREGISTRATION:
            return t('DRep deregistration')
          case CardanoTxCertificateType.DREP_UPDATE:
            return t('DRep update')
          default:
            return safeAssertUnreachable(type)
        }
      })
      .flat() || []

  return [...tags, ...certificateTags]
}
