import {Stack} from '@mui/material'
import {safeAssertUnreachable} from '@nufi/frontend-common'
import React from 'react'

import {RampOperation} from 'src/features/ramp/domain'

import {RampFlowHeaderWithBackButton} from '../../components/RampFlowHeader'
import {RampFlowScreenLayout} from '../../components/RampFlowLayout'
import {useRampFlowConfig} from '../../state/rampFlowConfig'
import type {FullRampFlowState, RampFlowState} from '../../state/rampFlowState'

export type RampFlowWidgetScreenProps = {
  asset: FullRampFlowState['asset']
  account: FullRampFlowState['account']
  operation: RampFlowState['operation']
  isPasswordLogin: boolean
}

export const RampFlowWidgetScreen = ({
  account,
  asset,
  operation,
  isPasswordLogin,
}: RampFlowWidgetScreenProps) => {
  const {renderBuyWidget, renderSellWidget, renderWidgetHeader, dispatch} =
    useRampFlowConfig()

  const onBack = () => dispatch({type: 'goBack', payload: {}})

  return (
    <RampFlowScreenLayout
      header={
        renderWidgetHeader ? (
          renderWidgetHeader({onBack, operation})
        ) : (
          <RampFlowHeaderWithBackButton onBack={onBack} />
        )
      }
      body={
        <Stack height="100%" alignItems="center">
          {(() => {
            switch (operation) {
              case RampOperation.BUY:
                return renderBuyWidget?.({
                  asset,
                  address: account.address,
                })
              case RampOperation.SELL:
                return renderSellWidget?.({
                  asset,
                  address: account.address,
                  isPasswordLogin,
                })
              default:
                return safeAssertUnreachable(operation)
            }
          })()}
        </Stack>
      }
      footer={null}
    />
  )
}
