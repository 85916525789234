import {Box, Divider, Typography} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import {useTranslation} from 'react-i18next'

import type {NftBlockchain} from '../../blockchainTypes'
import {ElevatedCard} from '../../components'
import {useGetBlockchainName} from '../../utils/translations'
import {AboutRow} from '../portfolio/assetDetail/AboutRow'

type NftDetailPageProps = {
  blockchain: NftBlockchain
  collectionName: string | null
  nftName: string
  details: {label: string; value: React.ReactNode}[]
  traits: {label: string; value: React.ReactNode}[]
}

export function NftDetailPage({
  blockchain,
  collectionName,
  nftName,
  details,
  traits,
}: NftDetailPageProps) {
  const {t} = useTranslation()
  const classes = useStyles()
  const getBlockchainName = useGetBlockchainName()
  return (
    <ElevatedCard type="light" tableCard className={classes.elevatedCard}>
      <Typography
        color="textSecondary"
        variant="body2"
        className={classes.sectionHeader}
      >
        {collectionName}
      </Typography>
      <Typography color="primary" variant="h4" className={classes.nftName}>
        {nftName}
      </Typography>
      <Typography variant="h6" className={classes.sectionHeader}>
        {t('About')}
      </Typography>
      <Divider className={classes.nftTagsDivider} />
      <NftTraitRow
        label={t('Blockchain')}
        value={getBlockchainName(blockchain)}
      />
      <NftTraitRow label={t('Name')} value={nftName} />
      {details.map((t, i) => (
        <NftTraitRow key={i} label={t.label} value={t.value} />
      ))}
      {!!traits.length && (
        <>
          <Typography variant="h6" className={classes.sectionHeader}>
            {t('NFT traits')}
          </Typography>
          <Divider className={classes.nftTagsDivider} />
          {traits.map((t, i) => (
            <NftTraitRow key={i} label={t.label} value={t.value} />
          ))}
        </>
      )}
    </ElevatedCard>
  )
}

type NftTraitRowProps = {
  label: string
  value: React.ReactNode
}

function NftTraitRow({label, value}: NftTraitRowProps) {
  const classes = useStyles()
  return (
    <Box className={classes.nftTraitRow}>
      <AboutRow label={label} value={value} />
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  sectionHeader: {
    marginTop: theme.spacing(2),
  },
  nftTagsDivider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
  },
  elevatedCard: {
    paddingTop: theme.spacing(1),
    padding: theme.spacing(4),
  },
  nftTraitRow: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
  },
  nftName: {
    marginBottom: theme.spacing(1.25),
    marginTop: theme.spacing(0.5),
  },
}))
