import {Box} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {useDappConnectorStore} from 'src/store/dappConnector'
import {assert} from 'src/utils/assertion'

import type {AlertProps} from '../../../../../components'
import {Alert} from '../../../../../components'
import {OriginMessage, RejectButton} from '../../../../components'
import {OUTER_CONTENT_WIDTH} from '../../../../constants'

const createTestId = (testId: string) => `import-evm-token-${testId}`

export const importEvmTokenTestIds = {
  invalidContract: createTestId('invalid-contract'),
  invalidChecksum: createTestId('invalidChecksum'),
  unsupportedChainId: createTestId('unsupported-chain-id'),
  unsupportedTokenType: createTestId('unsupported-token-type'),
  differencesInfo: createTestId('differences-info'),
  whitelisted: createTestId('similar-to-whitelisted'),
  alreadyImported: createTestId('already-imported'),
  tokenCard: createTestId('token-card'),
  audit: {
    warningWrapper: createTestId('audit-warning'),
    tokenNameSimilarToProfileTokenImport: createTestId(
      'name-similar-to-profile-imported-token-warning',
    ),
    tokenSymbolSimilarToProfileTokenImport: createTestId(
      'symbol-similar-to-profile-imported-token-warning',
    ),
    tokenNameSimilarToWhitelistedToken: createTestId(
      'name-similar-to-whitelisted-token-warning',
    ),
    tokenSymbolSimilarToWhitelistedToken: createTestId(
      'symbol-similar-to-whitelisted-token-warning',
    ),
  },
}

type ImportTokenLayoutProps = {
  children: React.ReactNode
  type: 'import' | 'update'
}

export function ImportTokenLayout({children, type}: ImportTokenLayoutProps) {
  const {t} = useTranslation()
  const {origin, favIconUrl} = useDappConnectorStore()
  assert(origin != null, 'OriginInfo: origin not provided')

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <OriginMessage
        {...{origin, favIconUrl}}
        message={
          type === 'import'
            ? t('Import following token?')
            : t('Update following token?')
        }
      />
      <Box width={OUTER_CONTENT_WIDTH} mt={2}>
        {children}
      </Box>
    </Box>
  )
}

export type UnsupportedCaseProps = {
  testId: string
  onClose: () => void
  message: React.ReactNode
}

export function UnsupportedCase({
  onClose,
  message,
  testId,
}: UnsupportedCaseProps) {
  return (
    <ImportTokenLayout type="import">
      <Box rtl-data-test-id={testId}>
        <AlertWithMargin severity="error">{message}</AlertWithMargin>
        <RejectButton onClick={onClose} />
      </Box>
    </ImportTokenLayout>
  )
}

export function AlertWithMargin(props: AlertProps) {
  return (
    <Box mt={1} mb={1}>
      <Alert {...props} />
    </Box>
  )
}
