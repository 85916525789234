import type {OpenloginUserInfo} from '@web3auth/openlogin-adapter'

import type {localProfileManagerLocator} from 'src/appStorage'
import type {MasterProfileManager} from 'src/appStorage/masterProfileManager'
import MasterProfileManagerInstance from 'src/appStorage/masterProfileManager'
import type {
  LocalProfileId,
  ProfileMetadata,
  ProfileName,
  ProfilePassword,
} from 'src/appStorage/types'
import config from 'src/config'
import type {AutoLoginData} from 'src/features/appOpener/domain'
import {getAvailableBlockchains} from 'src/features/availableBlockchains/application'
import type {
  IWeb3AuthConnectionV1,
  IWeb3AuthConnectionV2,
} from 'src/features/login/infrastructure'
import {
  createWeb3AuthConnectionV1,
  createWeb3AuthConnectionV2,
} from 'src/features/login/infrastructure'
import type {Web3AuthLoginProvider} from 'src/features/login/infrastructure/web3Auth/types'
import type {HasCloudSyncBackupParams} from 'src/features/profileSync/application'
import {hasCloudSyncBackup} from 'src/features/profileSync/application'
import {mergeProfileData} from 'src/features/profileSync/domain'
import type {Blockchain, Mnemonic} from 'src/types'
import {assert} from 'src/utils/assertion'
import {entropyToMnemonic, validateMnemonic} from 'src/wallet/utils/mnemonic'

import type {Web3AuthUserInfo} from '../../domain'

type Web3AuthMigrationInfo = {
  currentLoginVersion: 'v1' | 'v2'
  migratedFromV1: boolean
}
type RawWeb3AuthLoginResponse = {
  web3AuthUserInfo: Web3AuthUserInfo
  profileMnemonic: Mnemonic
  localProfileMeta: ProfileMetadata | null
  isProfileBackedUp: boolean
}

type CreateWeb3AuthLoginServiceDeps = {
  masterProfileManager: MasterProfileManager
  web3AuthConnectionV1: IWeb3AuthConnectionV1
  web3AuthConnectionV2: IWeb3AuthConnectionV2
  hasCloudSyncBackup: (params: HasCloudSyncBackupParams) => Promise<boolean>
  isWeb3AuthV2MigrationEnabled: boolean
}

export const createWeb3AuthPassword = (mnemonic: Mnemonic) =>
  mnemonic as string as ProfilePassword

export const getWeb3AuthMnemonic = (password: ProfilePassword) => {
  assert(
    !!validateMnemonic(password),
    'Can not turn web3Auth password into mnemonic',
  )
  return password as string as Mnemonic
}

export type ConfirmMigrationArgs = {
  typeOfLogin: Web3AuthLoginProvider
  email?: string
  name?: string
}

export interface IWeb3AuthLoginService {
  rawWeb3AuthLogin: (args: {
    loginProvider: Web3AuthLoginProvider
    sessionNamespace?: string
    sessionTime?: number
    confirmV2Migration: (props: ConfirmMigrationArgs) => Promise<boolean>
  }) => Promise<RawWeb3AuthLoginResponse>
  rawWeb3AuthLogout: (args: {sessionNamespace?: string}) => Promise<void>
  rawWeb3AuthRehydrateSession: (args: {
    sessionNamespace?: string
  }) => Promise<RawWeb3AuthLoginResponse | null>
  initExistingProfile: (
    profileId: LocalProfileId,
    profileMnemonic: Mnemonic,
  ) => Promise<void>
  initNewProfile: (
    profileMnemonic: Mnemonic,
    web3AuthUserInfo: Web3AuthUserInfo,
    enabledBlockchains: readonly Blockchain[],
  ) => Promise<void>
  loginOrCreateNewProfile: (args: {
    loginProvider: Web3AuthLoginProvider
    sessionNamespace?: string
    sessionTime?: number
    confirmV2Migration: (props: ConfirmMigrationArgs) => Promise<boolean>
    enabledBlockchains: readonly Blockchain[]
  }) => Promise<RawWeb3AuthLoginResponse & {newProfileCreated: boolean}>
}

export const createWeb3AuthLoginService = ({
  hasCloudSyncBackup,
  masterProfileManager,
  web3AuthConnectionV1,
  web3AuthConnectionV2,
  isWeb3AuthV2MigrationEnabled,
}: CreateWeb3AuthLoginServiceDeps): IWeb3AuthLoginService => {
  const getLoginMigrationInfo = async (params: {
    typeOfLogin: OpenloginUserInfo['typeOfLogin']
    verifierId: string
  }): Promise<Web3AuthMigrationInfo> => {
    const {typeOfLogin, verifierId} = params
    if (!web3AuthConnectionV2.isSupportedLoginProvider(typeOfLogin)) {
      return {
        currentLoginVersion: 'v1',
        migratedFromV1: false,
      }
    }

    const v1VerifierInfo = await web3AuthConnectionV1.getVerifierIdInfo(
      typeOfLogin,
      verifierId,
    )
    const v2VerifierInfo = await web3AuthConnectionV2.getVerifierIdInfo(
      typeOfLogin,
      verifierId,
    )

    // this check may look too cumbersome but it deals with the
    // edge-case that users may create accounts on other wallets
    // on the legacy torus network on other wallets (e.g. app.tor.us)
    // after using NuFi
    const migratedFromV1 =
      v1VerifierInfo.keyCreatedAt && v2VerifierInfo.keyCreatedAt
        ? v1VerifierInfo.keyCreatedAt.getTime() <
          v2VerifierInfo.keyCreatedAt.getTime()
        : false

    const currentLoginVersion =
      v1VerifierInfo.keyExists && !v2VerifierInfo.keyExists ? 'v1' : 'v2'

    return {
      currentLoginVersion,
      migratedFromV1,
    }
  }

  const _loginWithMigration = async (args: {
    loginProvider: Web3AuthLoginProvider
    sessionNamespace?: string
    sessionTime?: number
    confirmV2Migration: (props: ConfirmMigrationArgs) => Promise<boolean>
  }): Promise<{
    userInfo: Web3AuthUserInfo
    privateKey: Buffer
  }> => {
    const {loginProvider, sessionNamespace, sessionTime, confirmV2Migration} =
      args
    if (
      !isWeb3AuthV2MigrationEnabled ||
      !web3AuthConnectionV2.isSupportedLoginProvider(loginProvider)
    ) {
      const {userInfo, privateKey} = await web3AuthConnectionV1.login(
        loginProvider,
        {
          sessionNamespace,
          sessionTime,
        },
      )
      return {
        userInfo: {
          ...userInfo,
          currentLoginVersion: 'v1',
          migratedFromV1: false,
        },
        privateKey,
      }
    }

    const verifierResponse =
      await web3AuthConnectionV2.loginVerifier(loginProvider)
    const {verifierId, idToken, email, name, typeOfLogin} = verifierResponse
    const migrationInfo = await getLoginMigrationInfo(verifierResponse)
    const shouldMigrate = migrationInfo.currentLoginVersion === 'v1'

    if (shouldMigrate) {
      const confirmed = await confirmV2Migration({
        email,
        name,
        typeOfLogin,
      })
      if (!confirmed) {
        throw new Error('Login aborted, migration rejected')
      }

      const {userInfo, privateKey} = await web3AuthConnectionV1.login(
        loginProvider,
        {
          sessionNamespace,
          sessionTime: 0, // no point in preserving session when migrating
        },
      )

      if (userInfo.verifierId !== verifierId) {
        throw new Error('Migration aborted due to social account mismatch')
      }

      // Note that if the user took more than 60 seconds to make the v1 login
      // this will fail. For the sake of simplicity, we just ask the user to
      // repeat the login process which should be naturally faster next time.
      // https://web3auth.io/docs/troubleshooting/jwt-errors#expired-token
      await web3AuthConnectionV2.importPrivateKey({
        loginProvider,
        verifierId,
        idToken,
        privateKey,
      })

      return {
        privateKey,
        userInfo: {
          ...userInfo,
          currentLoginVersion: 'v2',
          migratedFromV1: true,
        },
      }
    }

    const {userInfo, privateKey} = await web3AuthConnectionV2.loginTorusNetwork(
      verifierResponse,
      {
        sessionTime,
        sessionNamespace,
      },
    )

    return {
      privateKey,
      userInfo: {
        ...userInfo,
        ...migrationInfo,
      },
    }
  }

  const loginToProfile = async (web3AuthPrivateKey: Buffer) => {
    const profileMnemonic = entropyToMnemonic(web3AuthPrivateKey)

    const localProfileMeta = await masterProfileManager.findProfile({
      mnemonic: profileMnemonic,
      loginType: 'web3Auth',
    })
    const isProfileBackedUp =
      localProfileMeta != null
        ? true
        : await hasCloudSyncBackup({mnemonic: profileMnemonic}).catch(
            () => false,
          )

    return {
      profileMnemonic,
      localProfileMeta,
      isProfileBackedUp,
    }
  }

  const rawWeb3AuthLogin: IWeb3AuthLoginService['rawWeb3AuthLogin'] = async ({
    loginProvider,
    sessionNamespace,
    sessionTime,
    confirmV2Migration,
  }) => {
    const {privateKey, userInfo: web3AuthUserInfo} = await _loginWithMigration({
      loginProvider,
      sessionNamespace,
      sessionTime,
      confirmV2Migration,
    })
    const profileLoginResult = await loginToProfile(privateKey)

    return {
      web3AuthUserInfo,
      ...profileLoginResult,
    }
  }

  const rawWeb3AuthLogout: IWeb3AuthLoginService['rawWeb3AuthLogout'] = async (
    args,
  ) => {
    await web3AuthConnectionV1.logout(args)
    await web3AuthConnectionV2.logout(args)
  }

  const rawWeb3AuthRehydrateSession: IWeb3AuthLoginService['rawWeb3AuthRehydrateSession'] =
    async (args) => {
      const web3AuthLoginResult =
        (await web3AuthConnectionV2.rehydrateSession(args)) ??
        (await web3AuthConnectionV1.rehydrateSession(args))

      if (!web3AuthLoginResult) {
        return null
      }
      const profileLoginResult = await loginToProfile(
        web3AuthLoginResult.privateKey,
      )

      const migrationInfo = await getLoginMigrationInfo({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        typeOfLogin: web3AuthLoginResult.userInfo.typeOfLogin!,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        verifierId: web3AuthLoginResult.userInfo.verifierId!,
      })

      return {
        web3AuthUserInfo: {
          ...web3AuthLoginResult.userInfo,
          ...migrationInfo,
        },
        ...profileLoginResult,
      }
    }

  const initExistingProfile: IWeb3AuthLoginService['initExistingProfile'] =
    async (profileId, profileMnemonic): Promise<void> => {
      await masterProfileManager.initLocalProfile({
        profileId,
        password: createWeb3AuthPassword(profileMnemonic),
      })
    }

  const initNewProfile: IWeb3AuthLoginService['initNewProfile'] = async (
    profileMnemonic,
    web3AuthUserInfo,
    enabledBlockchains,
  ): Promise<void> => {
    await masterProfileManager.createNewLocalProfile({
      profileName:
        `${web3AuthUserInfo.typeOfLogin}:${web3AuthUserInfo.email}` as ProfileName,
      password: createWeb3AuthPassword(profileMnemonic),
      mnemonic: profileMnemonic,
      isHwUser: false,
      isMnemonicActivated: true,
      loginType: 'web3Auth',
      enabledBlockchains,
    })
  }

  const loginOrCreateNewProfile: IWeb3AuthLoginService['loginOrCreateNewProfile'] =
    async (args) => {
      const info = await rawWeb3AuthLogin(args)
      const {profileMnemonic, localProfileMeta, web3AuthUserInfo} = info
      const profileId = localProfileMeta?.localProfileId

      if (profileId != null) {
        await initExistingProfile(profileId, profileMnemonic)
        return {newProfileCreated: false, ...info}
      } else {
        await initNewProfile(
          profileMnemonic,
          web3AuthUserInfo,
          args.enabledBlockchains,
        )
        return {newProfileCreated: true, ...info}
      }
    }

  return {
    rawWeb3AuthLogin,
    rawWeb3AuthLogout,
    rawWeb3AuthRehydrateSession,
    loginOrCreateNewProfile,
    initExistingProfile,
    initNewProfile,
  }
}

export const web3AuthLoginService = createWeb3AuthLoginService({
  hasCloudSyncBackup,
  masterProfileManager: MasterProfileManagerInstance,
  web3AuthConnectionV1: createWeb3AuthConnectionV1(
    config.web3AuthNetworkV1,
    config.web3AuthClientIdV1,
  ),
  web3AuthConnectionV2: createWeb3AuthConnectionV2(
    config.web3AuthNetworkV2,
    config.web3AuthClientIdV2,
    config.backendUrl,
  ),
  isWeb3AuthV2MigrationEnabled: config.isWeb3AuthV2MigrationEnabled,
})

export {isValidWeb3AuthLoginProvider} from 'src/features/login/infrastructure/web3Auth/utils'
export * from './types'

type CreateWeb3AuthAutoLoginServiceDeps = {
  masterProfileManager: MasterProfileManager
  web3AuthLoginService: IWeb3AuthLoginService
  // Note that it would be ideal if profileManager was directly
  // retrievable from master profile manager.
  localProfileManagerLocator: typeof localProfileManagerLocator
}

export type Web3AuthAutoLoginService = {
  initProfile: (
    autoLoginData: Extract<AutoLoginData, {loginType: 'web3Auth'}>,
    platform: 'web' | 'extension',
  ) => Promise<LocalProfileId>
}

export const MissingProfileDataErrorMessage = 'Missing profile data'

export const createWeb3AuthAutoLoginService = ({
  masterProfileManager,
  web3AuthLoginService,
  localProfileManagerLocator,
}: CreateWeb3AuthAutoLoginServiceDeps): Web3AuthAutoLoginService => {
  /**
   * Given autoLogin data:
   * * Initialize the same profile as the one used in the opener. If no such
   * profile is found, the profile is created.
   * * When running on web, there is a possibility that the same accounts
   * would not be present in the main app. Therefore the merge with local
   * data is performed and the `profileData` must be sent along.
   */
  const initProfile: Web3AuthAutoLoginService['initProfile'] = async (
    autoLoginData,
    platform,
  ) => {
    // In case of e.g. Widget and browser with sandboxed storage the profileId from widget
    // will be different from profile id in the main app.
    const targetProfile = await masterProfileManager.findProfile({
      mnemonic: autoLoginData.web3AuthData.mnemonic,
      loginType: 'web3Auth',
    })

    if (platform === 'web' && autoLoginData.profileData == null) {
      // Case when profile was e.g. only initialized in Widget with sandboxed storage.
      // In that case we need to ensure that we do not end up in profile with some
      // accounts missing (e.g. if cloud sync fails). Therefore we require opener
      // profile data being send along.
      //
      // Another case when merging data is when the web3auth profile in the main app
      // already exists, but it was initialized without target blockchain accounts.
      throw new Error(MissingProfileDataErrorMessage)
    }

    if (targetProfile != null) {
      await web3AuthLoginService.initExistingProfile(
        targetProfile.localProfileId,
        autoLoginData.web3AuthData.mnemonic,
      )
    } else {
      await web3AuthLoginService.initNewProfile(
        autoLoginData.web3AuthData.mnemonic,
        autoLoginData.web3AuthData.userInfo,
        getAvailableBlockchains(),
      )
    }

    if (autoLoginData.profileData != null) {
      const profileManager = localProfileManagerLocator.instance()
      const currentProfileData = await profileManager.getProfileData()

      const mergedData = mergeProfileData({
        localProfileData: currentProfileData,
        remoteProfileData: autoLoginData.profileData,
      })

      await profileManager.saveData(mergedData)
    }

    const finalProfileId = masterProfileManager.getCurrentProfileId()
    assert(finalProfileId != null)

    return finalProfileId
  }

  return {initProfile}
}
