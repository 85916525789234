/* eslint-disable @typescript-eslint/explicit-function-return-type */

import type {CardanoTokenId, CardanoTokenBundle} from '@nufi/wallet-cardano'
import {
  defaultMetadataFromToken,
  sumTokenBundles,
  tokenFromTokenId,
} from '@nufi/wallet-cardano'
import _ from 'lodash'

import {fetchNeverStaleQuery, useNeverStaleQuery} from 'src/utils/query-utils'

import queryClient from '../../../../../queryClient'
import {getCardano} from '../../../cardanoManagers'
import {cachedGetAccounts} from '../core'
import {sanitizeFakeTokenMetadata} from '../utils'

import {tokensQueryKeys} from './queryKeys'

export {tokensQueryKeys} from './queryKeys'

// These utils were moved here to avoid dependency cycles between NFT module

//
// __CACHED__ QUERIES
//

export async function getTokenMetadata(tokenId: CardanoTokenId) {
  const token = tokenFromTokenId(tokenId)
  const tokenMetadata = (await getCardano().wallet.getTokenMetadata([token]))[0]
  return sanitizeFakeTokenMetadata(
    tokenMetadata || defaultMetadataFromToken(token),
  )
}

export async function cachedGetTokenMetadata(tokenId: CardanoTokenId) {
  return fetchNeverStaleQuery({
    queryKey: tokensQueryKeys.tokenMetadata.token(tokenId),
    queryFn: () => getTokenMetadata(tokenId),
  })
}
export async function getTokensMetadata(tokenIds: CardanoTokenId[]) {
  const tokens = tokenIds.map((tokenId) => tokenFromTokenId(tokenId))
  const metadata = await getCardano().wallet.getTokenMetadata(tokens)
  const metadataById = _.keyBy(metadata, 'id')

  // Fetch missing `meta` and place it into cache
  tokens.forEach((token) => {
    metadataById[token.id] = sanitizeFakeTokenMetadata(
      metadataById[token.id] || defaultMetadataFromToken(token),
    )
  })
  return Object.values(metadataById)
}

async function _getTokensMetadata(tokens: CardanoTokenBundle) {
  const tokenIds = tokens.map((t) => t.token.id)

  const result = await getTokensMetadata(tokenIds)

  for (const meta of result) {
    await queryClient.prefetchQuery({
      queryKey: tokensQueryKeys.tokenMetadata.token(meta.id),
      queryFn: () => meta,
      staleTime: Infinity,
    })
  }

  return result
}

export async function cachedGetTokensMetadata() {
  return fetchNeverStaleQuery({
    queryKey: tokensQueryKeys.tokenMetadata.all(),
    queryFn: async () => {
      const tokens = await cachedGetTokens()
      return _getTokensMetadata(tokens)
    },
  })
}

//
// __COMPUTED__ QUERIES
//

async function getTokens() {
  const accounts = await cachedGetAccounts()
  return sumTokenBundles(accounts.map((ai) => ai.tokensBalance))
}

getTokens.__key = tokensQueryKeys.tokens

export async function cachedGetTokens() {
  return fetchNeverStaleQuery({queryKey: getTokens.__key, queryFn: getTokens})
}

export function useGetTokens(enabled = true) {
  return useNeverStaleQuery({
    queryKey: getTokens.__key,
    queryFn: getTokens,
    enabled,
  })
}
