import {Box, Grid, Typography, styled} from '@mui/material'
import type BigNumber from 'bignumber.js'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {WithConversionRates} from 'src/features/conversionRates/ui'

import type {Blockchain, TokenMetadata} from '../types'

import {FormattedAsset, FormattedBlockchainAssetsAsCurrency} from './formatting'

type TotalRowProps = {
  nativeAmount: BigNumber
  blockchain: Blockchain
  totalText: string
  tokenAmount?: BigNumber
  tokenMetadata?: TokenMetadata
  feeText?: string
  tokensToSendCount?: number
}

export function TotalRow({
  nativeAmount,
  tokenAmount,
  blockchain,
  totalText,
  tokenMetadata,
  tokensToSendCount,
  feeText = '',
}: TotalRowProps) {
  const {t} = useTranslation()

  return (
    <Container>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Grid container direction="column">
            <ResponsiveHeading>{totalText}</ResponsiveHeading>
            <Typography variant="body2" color="textSecondary">
              {feeText}
            </Typography>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="column" alignItems="flex-end">
            <ResponsiveHeading>
              <Grid container direction="column">
                {tokenAmount && !tokensToSendCount && (
                  <Grid item textAlign="right">
                    <FormattedAsset
                      includeAssetSymbol
                      amount={tokenAmount}
                      blockchain={blockchain}
                      tokenMetadata={tokenMetadata}
                      isSensitiveInformation={false}
                    />
                  </Grid>
                )}
                {!!tokensToSendCount && (
                  <Grid item textAlign="right">
                    {t('token_count', {count: tokensToSendCount})}
                  </Grid>
                )}
                <Grid item textAlign="right">
                  <FormattedAsset
                    includeAssetSymbol
                    amount={nativeAmount}
                    blockchain={blockchain}
                    isSensitiveInformation={false}
                  />
                </Grid>
              </Grid>
            </ResponsiveHeading>
            <Typography color="textSecondary">
              <Box display="flex">
                ~&nbsp;
                <WithConversionRates>
                  {(conversionRates) => (
                    <FormattedBlockchainAssetsAsCurrency
                      includeCurrencySymbol
                      nativeAmount={nativeAmount}
                      tokens={
                        tokenMetadata && tokenAmount
                          ? [{tokenMetadata, amount: tokenAmount}]
                          : undefined
                      }
                      blockchain={blockchain}
                      isSensitiveInformation={false}
                      conversionRates={conversionRates}
                    />
                  )}
                </WithConversionRates>
              </Box>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}

const Container = styled(Grid)(({theme}) => ({
  padding: theme.spacing(0.75, 0),
  [theme.breakpoints.up('windowsOld')]: {
    padding: theme.spacing(1.125, 0),
  },
  [theme.breakpoints.up('windowsZoomed')]: {
    padding: theme.spacing(1.5, 0),
  },
}))

TotalRow.defaultProps = {
  container: true,
  justifyContent: 'space-between',
}

const ResponsiveHeading = styled(Typography)(({theme}) => ({
  fontSize: '1.05rem',
  [theme.breakpoints.up('windowsOld')]: {
    fontSize: '1.15rem',
  },
  [theme.breakpoints.up('windowsZoomed')]: {
    fontSize: '1.25rem',
  },
}))

ResponsiveHeading.defaultProps = {
  variant: 'h6',
}
