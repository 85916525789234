import create from 'zustand'

import logger from 'src/store/logger'

import {registerUpdateWarningsListener, mergeWarnings} from '../domain'
import type {Warning} from '../domain'

export * from './commonWarningsTexts'

// We are using `Warning` type here, so this state can be reused across
// all platforms, e.g. allow widget having its own separate warnings.
type WarningsState = {
  warnings: Warning[]
  setWarnings: (warnings: Warning[]) => void
}

const useWarningsStore = create<WarningsState>(
  logger<WarningsState>('Warnings:')((set) => ({
    warnings: [],
    setWarnings: (warnings) => set({warnings}),
  })),
)

let upToDateWarnings = [] as Warning[]
let updatedReactiveStateTimeout: NodeJS.Timeout | null = null

registerUpdateWarningsListener(({newWarnings, keysToClear}) => {
  const {setWarnings} = useWarningsStore.getState()

  const mergedWarnings = mergeWarnings({
    currentWarnings: upToDateWarnings,
    newWarnings,
    keysToClear,
  })
  if (JSON.stringify(upToDateWarnings) === JSON.stringify(mergedWarnings))
    return

  upToDateWarnings = mergedWarnings

  // We are not updating the state immediately, to have smoother UI when
  // a lot of warnings are being fired during the same time frame.
  if (updatedReactiveStateTimeout != null) {
    clearTimeout(updatedReactiveStateTimeout)
  }
  updatedReactiveStateTimeout = setTimeout(() => {
    setWarnings(upToDateWarnings)
  }, 2000)
})

export const useWarnings = () => useWarningsStore().warnings
