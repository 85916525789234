import type {
  FlowAddress,
  FlowTokenContractMetadata,
  FlowTokenId,
} from '@nufi/wallet-flow'

import type {AccountId} from '../../../../types'
import {QUERY_KEY_PREFIX as P} from '../../utils'

export const tokensQueryKeys = {
  index: [P, 'tokens'],
  accountTokens: (accountId: AccountId) => [
    ...tokensQueryKeys.index,
    'account',
    accountId,
  ],
  tokenMetadata: {
    index: () => [...tokensQueryKeys.index, 'tokenMetadata'],
    all: () => [...tokensQueryKeys.tokenMetadata.index(), 'all'],
    token: (tokenId: FlowTokenId | null) => [
      ...tokensQueryKeys.tokenMetadata.index(),
      'token',
      tokenId,
    ],
  },
  balancesPerAccount: (tokenId: FlowTokenId | null) => [
    ...tokensQueryKeys.index,
    'balancesPerAccount',
    tokenId,
  ],
  fungibleTokenAccounts: () => [
    ...tokensQueryKeys.index,
    'fungibleTokenAccounts',
  ],
  tokenAccounts: () => [...tokensQueryKeys.index, 'tokenAccounts'],
  isTokenSetupForAddress: (
    contractMetadata: FlowTokenContractMetadata,
    address: FlowAddress,
    mustBeFullySetUp: boolean,
  ) => [
    ...tokensQueryKeys.index,
    'isTokenSetupForAddress',
    [
      contractMetadata.contractAddress,
      contractMetadata.contractName,
      address,
      mustBeFullySetUp,
    ].join(','),
  ],
  nftCollectionsSetupInfoForAccount: (address: FlowAddress) => [
    P,
    'nftCollectionsSetupInfoForAccount',
    address,
  ],
  supportedTokenContracts: [P, 'supportedTokenContracts'],
  setupTokensContractForAccount: (address: FlowAddress) => [
    P,
    'supportedTokenContracts',
    address,
  ],
} as const
