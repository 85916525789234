import {Box, Grid, Typography} from '@mui/material'
import {differenceInSeconds} from 'date-fns'
import React, {useEffect, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useIdleTimer} from 'react-idle-timer'

import {useCurrentProfileMeta} from '../appStorage'
import {assert, assertUnreachable} from '../utils/assertion'
import {logout} from '../utils/general'
import type {LoginBroadcastMessage} from '../utils/loginBroadcast'
import {setBroadcastLoginListener} from '../utils/loginBroadcast'

import {Button, Modal, ModalFooter, ModalHeader, ModalLayout} from '.'

type AutoLogoutProps = {
  idleInSeconds: number
  showDialogBeforeInSeconds: number
}

export function AutoLogout({
  idleInSeconds,
  showDialogBeforeInSeconds,
}: AutoLogoutProps) {
  assert(
    idleInSeconds > showDialogBeforeInSeconds,
    "'idleInSeconds' must be greater then 'showDialogBeforeInSeconds'",
  )

  const [showWarningDialog, setShowWarningDialog] = useState(false)
  const [logoutAt, setLogoutAt] = useState(new Date())

  const onIdle = () => {
    setLogoutAt(new Date(getLastActiveTime() + idleInSeconds * 1000))
    setShowWarningDialog(true)
  }

  const onClose = () => {
    setShowWarningDialog(false)
  }

  const {getLastActiveTime} = useIdleTimer({
    timeout: 1000 * (idleInSeconds - showDialogBeforeInSeconds),
    onIdle,
    debounce: 500,
  })

  if (!showWarningDialog) return null

  return <LogoutWarningDialog logoutAt={logoutAt} onClose={() => onClose()} />
}

const SHOW_LOGOUT_REASON_KEY = 'showLogoutReason'
type AutoLogoutReason = 'inactivity' | 'concurrentLogin' | 'restoreProfile'

const autoLogoutStorage = {
  setReason: (reason: AutoLogoutReason) => {
    window.sessionStorage.setItem(SHOW_LOGOUT_REASON_KEY, reason)
  },
  getReason: (): AutoLogoutReason | null =>
    window.sessionStorage.getItem(
      SHOW_LOGOUT_REASON_KEY,
    ) as AutoLogoutReason | null,
  reset: () => {
    window.sessionStorage.removeItem(SHOW_LOGOUT_REASON_KEY)
  },
}

export const usePreventConcurrentLogin = () => {
  const profileMeta = useCurrentProfileMeta()

  useEffect(() => {
    if (profileMeta.data) {
      setBroadcastLoginListener((msg: LoginBroadcastMessage) => {
        if (profileMeta.data.localProfileId === msg.localProfileId) {
          autoLogoutStorage.setReason(msg.loginType)
          logout()
        }
      })
    }
  }, [profileMeta.data])
}

export function LogoutReasonDialog() {
  const {t} = useTranslation()

  const [logoutReason, setLogoutReason] = useState<AutoLogoutReason>()

  useEffect(() => {
    const logoutReason = autoLogoutStorage.getReason()
    if (logoutReason) {
      setLogoutReason(logoutReason)
    }
  }, [])

  const onClose = () => {
    setLogoutReason(undefined)
    autoLogoutStorage.reset()
  }

  if (!logoutReason) return null

  const {headerText, reasonText} = (() => {
    switch (logoutReason) {
      case 'inactivity':
        return {
          headerText: t('Inactivity logout'),
          reasonText: t('You have been logged out due to inactivity'),
        }
      case 'concurrentLogin':
        return {
          headerText: t('Concurrent login'),
          reasonText: t(
            'You have been logged out, a login to the same wallet was detected in another tab.',
          ),
        }
      case 'restoreProfile':
        return {
          headerText: t('Wallet restored'),
          reasonText: t(
            'You have been logged out, restore of the same wallet was detected in another tab.',
          ),
        }
      default:
        return assertUnreachable()
    }
  })()

  return (
    <Modal variant="centered">
      <ModalLayout
        header={
          <ModalHeader onClose={() => onClose()} hasDivider>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <Typography variant="h6">{headerText}</Typography>
              </Grid>
            </Grid>
          </ModalHeader>
        }
        body={
          <Box minWidth={440} p={3} pl={2}>
            <Typography variant="subtitle1">{reasonText}</Typography>
          </Box>
        }
        footer={
          <ModalFooter hasDivider>
            <Button
              variant="contained"
              color="primary"
              onClick={() => onClose()}
            >
              {t('Ok')}
            </Button>
          </ModalFooter>
        }
      />
    </Modal>
  )
}

type LogoutWarningDialogProps = {
  logoutAt: Date
  onClose: () => void
}

function LogoutWarningDialog({logoutAt, onClose}: LogoutWarningDialogProps) {
  const {t} = useTranslation()

  const [countdownSeconds, setCountdownSeconds] = useState(
    differenceInSeconds(logoutAt, Date.now()),
  )

  useTimer(() => {
    setCountdownSeconds(differenceInSeconds(logoutAt, Date.now()))
  }, 500)

  useEffect(() => {
    if (countdownSeconds <= 0) {
      autoLogoutStorage.setReason('inactivity')
      logout()
    }
  }, [countdownSeconds])

  return (
    <Modal variant="centered">
      <ModalLayout
        header={
          <ModalHeader onClose={() => onClose()} hasDivider>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <Typography variant="h6">{t('Inactivity warning')}</Typography>
              </Grid>
            </Grid>
          </ModalHeader>
        }
        body={
          <Box minWidth={440} p={3} pl={2}>
            <Typography variant="subtitle1">
              {t('auto_logout_in_X_seconds', {
                seconds: Math.max(countdownSeconds, 0),
              })}
            </Typography>
          </Box>
        }
        footer={
          <ModalFooter hasDivider>
            <Button
              variant="contained"
              color="primary"
              onClick={() => onClose()}
            >
              {t('Stay logged in')}
            </Button>
          </ModalFooter>
        }
      />
    </Modal>
  )
}

function useTimer(callback: () => void, tickInterval: number | null) {
  const _callback = useRef(callback)

  useEffect(() => {
    _callback.current = callback
  }, [callback])

  useEffect(() => {
    if (tickInterval === null) return () => null
    const timerId = setInterval(() => _callback.current(), tickInterval)

    return () => clearInterval(timerId)
  }, [tickInterval])
}
