import {
  AddCircle as AddHotAccountIcon,
  Usb as AddHwAccountIcon,
  VpnKey as AddImportedAccountIcon,
  Add as AddAccountIcon,
} from '@mui/icons-material'
import {Typography, Grid, styled, Box} from '@mui/material'
import _ from 'lodash'
import React, {useState, useContext, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory, Route, Switch} from 'react-router-dom'

import {useAppOpenerStore} from 'src/features/appOpener/application'
import {filterAvailableBlockchains} from 'src/features/availableBlockchains/application'
import {useIsBlockchainEnabled} from 'src/features/profile/application'
import {useSettings} from 'src/features/profile/application/store'
import {useEnableBlockchain} from 'src/features/walletStorage/application/enableBlockchain'
import {useUiStore} from 'src/store/ui'
import {clearDiscoverHwAccountsCache} from 'src/wallet/public/accounts'
import {useHasAccounts} from 'src/wallet/public/queries/accounts'

import {
  Modal,
  ModalLayout,
  ModalHeader,
  ActionCard,
  Icon,
  BlockchainSelectField,
  Button,
  CryptoProviderIcon,
  MutationGuard,
} from '../../../../components'
import {routeTo} from '../../../../router'
import {useAddAccountRouteOptions} from '../../../../router/portfolio'
import {useGetCurrentHotVendor} from '../../../../store/auth'
import type {
  Blockchain,
  CryptoProviderType,
  HotVendor,
  HwVendor,
} from '../../../../types'
import {safeAssertUnreachable} from '../../../../utils/assertion'
import {useGetBlockchainName} from '../../../../utils/translations'
import {evmBlockchains, isEvmBlockchain} from '../../../../wallet/evm'
import AddCardanoAccount from '../../cardano/AddAccount'
import AddEvmAccount from '../../evm/AddAccount'
import AddFlowAccount from '../../flow/AddAccount'
import AddSolanaAccount from '../../solana/AddAccount'

import {
  AddAccountLayout,
  SectionHeader,
  SectionMessage,
  AccountCreatedPage,
  SectionNextButton,
} from './AddAccountUtils'
import {AddHotAccountTimeline, PairHardwareAccountTimeline} from './Timelines'

type ImportAccountType = 'hot' | 'hw'

type SelectBlockchainProps = {
  value: Blockchain
  onChange: (blockchain: Blockchain) => unknown
  importedAccountType: ImportAccountType
}

const getLedgerBlockchains = (): ReadonlyArray<Blockchain> =>
  filterAvailableBlockchains(['cardano', 'solana', 'flow', ...evmBlockchains])
const getTrezorBlockchains = (): ReadonlyArray<Blockchain> =>
  filterAvailableBlockchains(['cardano', 'solana', ...evmBlockchains])
const getGridPlusBlockchains = (): ReadonlyArray<Blockchain> =>
  filterAvailableBlockchains(['solana', ...evmBlockchains])
const getHwBlockchains = (): ReadonlyArray<Blockchain> =>
  _.uniq([
    ...getLedgerBlockchains(),
    ...getTrezorBlockchains(),
    ...getGridPlusBlockchains(),
  ])
const getHotBlockchains = (): ReadonlyArray<Blockchain> =>
  filterAvailableBlockchains(['cardano', 'solana', 'flow', ...evmBlockchains])

const getHwBlockchainOptionExtraContent = (option: Blockchain) => (
  <Grid container pl={1} spacing={1}>
    {getLedgerBlockchains().includes(option) && (
      <Grid display="flex" item>
        <CryptoProviderIcon cryptoProviderType={'ledger'} exactSize={24} />
      </Grid>
    )}
    <Grid item display="flex">
      {getTrezorBlockchains().includes(option) ? (
        <CryptoProviderIcon cryptoProviderType={'trezor'} exactSize={24} />
      ) : (
        // space placeholder so that hw types are lined up
        <Box width={24} />
      )}
    </Grid>
    <Grid item display="flex">
      {getGridPlusBlockchains().includes(option) ? (
        <CryptoProviderIcon cryptoProviderType={'gridPlus'} exactSize={24} />
      ) : (
        // space placeholder so that hw types are lined up
        <Box width={24} />
      )}
    </Grid>
  </Grid>
)

const getAddAccountPath = (
  provider: CryptoProviderType,
  blockchain: Blockchain,
) =>
  routeTo.portfolio.accounts.addAccount
    .cryptoProviderType(provider)
    .blockchain(blockchain)

type HwCardTextProps = {
  title: React.ReactNode
  description?: React.ReactNode
}

function HwCardText({title, description}: HwCardTextProps) {
  return (
    <>
      <Typography textAlign="center">{title}</Typography>
      <Typography color="textSecondary" textAlign="center" variant="caption">
        {description}
      </Typography>
    </>
  )
}

const SelectBlockchain = ({
  value,
  onChange,
  importedAccountType,
}: SelectBlockchainProps) => {
  const {t} = useTranslation()
  const blockchains = (() => {
    switch (importedAccountType) {
      case 'hot':
        return getHotBlockchains()
      case 'hw':
        return getHwBlockchains()
      default:
        return safeAssertUnreachable(importedAccountType)
    }
  })()

  return (
    <BlockchainSelectField
      label={t('Blockchain')}
      allowedBlockchains={blockchains}
      value={value}
      onChange={(value) => value && onChange(value)}
      dataTestId="add-account-blockchain-select-field"
      renderOptionExtraContent={
        importedAccountType === 'hw'
          ? getHwBlockchainOptionExtraContent
          : undefined
      }
    />
  )
}

type SelectBlockchainScreenProps = {
  getAddAccountRedirect: (blockchain: Blockchain) => string
  importedAccountType: ImportAccountType
  initialValue: Blockchain
}

const HwProviderDescriptionRow = ({
  cryptoProviderType,
}: {
  cryptoProviderType: HwVendor
}) => {
  const {t} = useTranslation()

  const title = {
    ledger: t('Ledger Nano S/X'),
    trezor: t('Trezor'),
    gridPlus: t('GridPlus Lattice1'),
  }[cryptoProviderType]

  return (
    <Box display="flex" pb={1}>
      <CryptoProviderIcon cryptoProviderType={cryptoProviderType} />{' '}
      <Typography pl={1}>{title}</Typography>
    </Box>
  )
}

const useSetSelectedBlockchainOnMount = ({
  blockchain,
}: {
  blockchain: Blockchain
}) => {
  const {selectedBlockchain, setSelectedBlockchain} = useAddAccountContext()
  useEffect(() => {
    if (selectedBlockchain == null) {
      setSelectedBlockchain(blockchain)
    }
  }, [])
}

export const SelectBlockchainConfirmButton = ({
  selectedBlockchain,
  getAddAccountRedirect,
  enableBlockchainMutation,
}: {
  selectedBlockchain: Blockchain
  getAddAccountRedirect: (blockchain: Blockchain) => string
  enableBlockchainMutation: ReturnType<typeof useEnableBlockchain>
}) => {
  const {t} = useTranslation()
  const hasAccounts = useHasAccounts(selectedBlockchain)
  const isBlockchainEnabled = useIsBlockchainEnabled(selectedBlockchain)
  const history = useHistory()
  const {setRecentlyEnabledBlockchain} = useUiStore()

  const onEnableBlockchain = async () => {
    try {
      await enableBlockchainMutation.mutateAsync(selectedBlockchain)
    } catch (e) {
      return
    }

    setRecentlyEnabledBlockchain(selectedBlockchain)
    history.push(routeTo.portfolio.accounts.index)
  }

  const onContinue = () => {
    history.push(getAddAccountRedirect(selectedBlockchain))
  }

  const shouldEnableBlockchain = !isBlockchainEnabled && hasAccounts

  const {label, onClick} = shouldEnableBlockchain
    ? {
        label: t('Activate blockchain'),
        onClick: onEnableBlockchain,
      }
    : {
        label: t('Continue'),
        onClick: onContinue,
      }

  return <SectionNextButton label={label} onClick={onClick} />
}

const SelectBlockchainScreen = ({
  getAddAccountRedirect,
  importedAccountType,
  initialValue,
}: SelectBlockchainScreenProps) => {
  const {t} = useTranslation()
  const {selectedBlockchain, setSelectedBlockchain} = useAddAccountContext()

  useSetSelectedBlockchainOnMount({blockchain: initialValue})
  const _selectedBlockchain = selectedBlockchain || initialValue

  const enableBlockchainMutation = useEnableBlockchain()

  return (
    <>
      <SectionHeader>{t('Select blockchain')}</SectionHeader>
      {importedAccountType === 'hot' ? (
        <SectionMessage>
          {t('More blockchains are coming soon.')}
        </SectionMessage>
      ) : (
        <>
          <SectionMessage hasMarginBottom={false}>
            {t(
              'More blockchains are coming soon. We support the following hardware wallet devices:',
            )}
          </SectionMessage>
          <Box pl={2} py={2}>
            <HwProviderDescriptionRow cryptoProviderType="ledger" />
            <HwProviderDescriptionRow cryptoProviderType="trezor" />
            <HwProviderDescriptionRow cryptoProviderType="gridPlus" />
          </Box>
        </>
      )}

      <SelectBlockchain
        importedAccountType={importedAccountType}
        value={_selectedBlockchain}
        onChange={setSelectedBlockchain}
      />
      <SelectBlockchainConfirmButton
        selectedBlockchain={_selectedBlockchain}
        getAddAccountRedirect={getAddAccountRedirect}
        enableBlockchainMutation={enableBlockchainMutation}
      />
      <MutationGuard {...enableBlockchainMutation} />
    </>
  )
}

const AddHotAccount = () => {
  const {hotVendor} = useAddAccountContext()

  return (
    <AddAccountLayout leftContent={<AddHotAccountTimeline step="blockchain" />}>
      <>
        <SelectBlockchainScreen
          getAddAccountRedirect={(blockchain) =>
            getAddAccountPath(hotVendor, blockchain)
          }
          importedAccountType="hot"
          initialValue="cardano"
        />
      </>
    </AddAccountLayout>
  )
}

const AddHwAccount = () => {
  const history = useHistory()

  // Need to be explicit as `HwWalletHint` uses custom route, so that
  // the back action will close it.
  const onBack = () => history.push(routeTo.portfolio.accounts.index)

  return (
    <AddAccountLayout
      onBack={onBack}
      leftContent={<PairHardwareAccountTimeline step="blockchain" />}
    >
      <>
        <SelectBlockchainScreen
          getAddAccountRedirect={(blockchain) =>
            routeTo.portfolio.accounts.addAccount.hw.blockchain(blockchain)
          }
          importedAccountType="hw"
          initialValue="cardano"
        />
      </>
    </AddAccountLayout>
  )
}

const ChooseHwCryptoProviderType = () => {
  const {t} = useTranslation()
  const history = useHistory()
  const {blockchain} = useAddAccountRouteOptions()
  // If user skipped the blockchain step (add account directly from some blockchain), set blockchain context
  // explicitly, so that going back one step correctly pre-selects the given blockchain in dropdown
  useSetSelectedBlockchainOnMount({blockchain})
  const getBlockchainName = useGetBlockchainName()

  const onLedger = () => {
    clearDiscoverHwAccountsCache(blockchain, 'ledger')
    history.push(getAddAccountPath('ledger', blockchain))
  }

  const onTrezor = () => {
    clearDiscoverHwAccountsCache(blockchain, 'trezor')
    history.push(getAddAccountPath('trezor', blockchain))
  }

  const onGridPlus = () => {
    clearDiscoverHwAccountsCache(blockchain, 'gridPlus')
    history.push(getAddAccountPath('gridPlus', blockchain))
  }

  const isTrezorSupportedForBlockchain =
    getTrezorBlockchains().includes(blockchain)
  const isLedgerSupportedForBlockchain =
    getLedgerBlockchains().includes(blockchain)
  const isGridPlusSupportedForBlockchain =
    getGridPlusBlockchains().includes(blockchain)

  const deviceNotSupportedText = t('device_not_supported_for_hw_device', {
    blockchain: getBlockchainName(blockchain),
  })

  // Hack to always keep hw card title on the same line
  const deviceSupportedText = <span>&nbsp;</span>

  return (
    <AddAccountLayout
      leftContent={<PairHardwareAccountTimeline step="device" />}
      maxWidth={800}
    >
      <>
        <SectionHeader>{t('Select your hardware wallet')}</SectionHeader>
        <Grid container spacing={2} mt={1}>
          <Grid item>
            <ActionCard
              disabled={!isLedgerSupportedForBlockchain}
              Text={
                <HwCardText
                  title={t('Ledger Nano S/X')}
                  description={
                    isLedgerSupportedForBlockchain
                      ? deviceSupportedText
                      : deviceNotSupportedText
                  }
                />
              }
              onClick={isLedgerSupportedForBlockchain ? onLedger : () => null}
              icon={<Icon type="ledgerIcon" exactSize={42} color="primary" />}
            />
          </Grid>
          <Grid item>
            <ActionCard
              disabled={!isTrezorSupportedForBlockchain}
              Text={
                <HwCardText
                  title={t('Trezor')}
                  description={
                    isTrezorSupportedForBlockchain
                      ? deviceSupportedText
                      : deviceNotSupportedText
                  }
                />
              }
              onClick={isTrezorSupportedForBlockchain ? onTrezor : () => null}
              icon={<Icon type="trezorIcon" exactSize={42} color="primary" />}
            />
          </Grid>
          <Grid item>
            <ActionCard
              disabled={!isGridPlusSupportedForBlockchain}
              Text={
                <HwCardText
                  title={t('GridPlus Lattice1')}
                  description={
                    isGridPlusSupportedForBlockchain
                      ? deviceSupportedText
                      : deviceNotSupportedText
                  }
                />
              }
              onClick={
                isGridPlusSupportedForBlockchain ? onGridPlus : () => null
              }
              icon={<Icon type="gridPlusIcon" exactSize={42} color="primary" />}
            />
          </Grid>
        </Grid>
      </>
    </AddAccountLayout>
  )
}

type ChooseCryptoProviderTypeProps = {
  onClose: () => unknown
  preselectedBlockchain?: Blockchain
}

const ChooseCryptoProviderType = ({
  onClose,
  preselectedBlockchain,
}: ChooseCryptoProviderTypeProps) => {
  const {t} = useTranslation()
  const history = useHistory()
  const settings = useSettings()
  const hotVendor = useGetCurrentHotVendor()

  const onAddHotAccount = () => {
    if (hotVendor === 'mnemonic' && !settings.isMnemonicActivated) {
      history.push(routeTo.settings.activateMnemonic)
    } else {
      const hotPath =
        routeTo.portfolio.accounts.addAccount.cryptoProviderType(hotVendor)
      // push starting path even if blockchain skip is applied, so that "go back" works properly
      history.push(hotPath.index)
      preselectedBlockchain &&
        history.push(hotPath.blockchain(preselectedBlockchain))
    }
    // original modal can now be closed
    onClose()
  }
  const onAddHwAccount = () => {
    const hwPath = routeTo.portfolio.accounts.addAccount.hw
    // push starting path even if blockchain skip is applied, so that "go back" works properly
    history.push(hwPath.index)
    preselectedBlockchain &&
      history.push(hwPath.blockchain(preselectedBlockchain))
    // original modal can now be closed
    onClose()
  }

  const iconProps = {
    fontSize: 'large',
    color: 'primary',
  } as const

  return (
    <Modal variant="centered">
      <ModalLayout
        header={<ModalHeader onClose={onClose} />}
        body={
          <>
            <Typography align="center" variant="h6">
              {t('How do you want to add a new accounts?')}
            </Typography>
            <Grid
              container
              justifyContent="space-between"
              sx={{p: 2, pt: 0, width: '100%', overflow: 'auto'}}
              wrap="nowrap"
            >
              <GridItem>
                <ActionCard
                  text={t('Add new accounts')}
                  onClick={onAddHotAccount}
                  icon={
                    <AddHotAccountIcon
                      {...iconProps}
                      data-test-id="create-new-account-button"
                    />
                  }
                />
              </GridItem>
              <GridItem>
                <ActionCard
                  Text={
                    <ImportAccountWrapper>
                      <Typography>{t('Add new HW accounts')}</Typography>
                      <CryptoProviderDescription>
                        {t('(Ledger / Trezor / GridPlus)')}
                      </CryptoProviderDescription>
                    </ImportAccountWrapper>
                  }
                  onClick={onAddHwAccount}
                  icon={<AddHwAccountIcon {...iconProps} />}
                />
              </GridItem>
              <GridItem>
                <ActionCard
                  Text={
                    <ImportAccountWrapper>
                      <Typography>{t('Import account')}</Typography>
                      <CryptoProviderDescription
                        sx={{
                          fontStyle: 'italic',
                        }}
                      >
                        {t('Coming soon')}
                      </CryptoProviderDescription>
                    </ImportAccountWrapper>
                  }
                  onClick={() => null}
                  disabled
                  icon={<AddImportedAccountIcon {...iconProps} />}
                />
              </GridItem>
            </Grid>
          </>
        }
      />
    </Modal>
  )
}

const GridItem = styled(Grid)(({theme: {spacing}}) => ({
  padding: spacing(2, 1, 1, 1),
}))
GridItem.defaultProps = {
  item: true,
}

const ImportAccountWrapper = styled('div')({
  position: 'relative',
})

const CryptoProviderDescription = styled(Typography)({
  position: 'absolute',
  width: '100%',
})

CryptoProviderDescription.defaultProps = {
  align: 'center',
  variant: 'caption',
}

const AddBlockchainSpecificAccount = () => {
  const {blockchain, cryptoProviderType} = useAddAccountRouteOptions()

  // If user skipped the blockchain step (add account directly from some blockchain), set blockchain context
  // explicitly, so that going back one step correctly pre-selects the given blockchain in dropdown
  useSetSelectedBlockchainOnMount({blockchain})

  switch (blockchain) {
    case 'solana': {
      return <AddSolanaAccount cryptoProviderType={cryptoProviderType} />
    }
    case 'cardano': {
      return <AddCardanoAccount cryptoProviderType={cryptoProviderType} />
    }
    case 'flow': {
      return <AddFlowAccount cryptoProviderType={cryptoProviderType} />
    }
    default: {
      if (isEvmBlockchain(blockchain)) {
        return (
          <AddEvmAccount
            blockchain={blockchain}
            cryptoProviderType={cryptoProviderType}
          />
        )
      }
      return null
    }
  }
}

type AddAccountModalProps = {
  onClose: () => unknown
}

export const AddAccountModal = ({onClose}: AddAccountModalProps) => {
  const hotVendor = useGetCurrentHotVendor()
  return (
    <Modal onClose={onClose} variant="full-width">
      <AddAccountContextProvider {...{hotVendor}}>
        <Switch>
          <Route
            path={routeTo.portfolio.accounts.addAccount.hw.index}
            component={AddHwAccount}
            exact
          />
          <Route
            path={routeTo.portfolio.accounts.addAccount.hw.blockchain(
              ':blockchain',
            )}
            component={ChooseHwCryptoProviderType}
          />
          <Route
            path={
              routeTo.portfolio.accounts.addAccount.cryptoProviderType(
                ':cryptoProviderType',
              ).created
            }
            component={AccountCreatedPage}
          />
          <Route
            path={routeTo.portfolio.accounts.addAccount
              .cryptoProviderType(':cryptoProviderType')
              .blockchain(':blockchain')}
            component={AddBlockchainSpecificAccount}
          />
          <Route
            path={
              routeTo.portfolio.accounts.addAccount.cryptoProviderType(
                hotVendor,
              ).index
            }
            component={AddHotAccount}
          />
        </Switch>
      </AddAccountContextProvider>
    </Modal>
  )
}

type AddAccountContextType = {
  hotVendor: HotVendor
  selectedBlockchain: Blockchain | null
  setSelectedBlockchain: (blockchain: Blockchain) => void
}
const AddAccountContext = React.createContext({} as AddAccountContextType)

type AddAccountContextProviderProps = {
  hotVendor: HotVendor
  children: React.ReactNode
}

const AddAccountContextProvider = ({
  children,
  hotVendor,
}: AddAccountContextProviderProps) => {
  const [selectedBlockchain, setSelectedBlockchain] =
    useState<Blockchain | null>(null)
  return (
    <AddAccountContext.Provider
      value={{selectedBlockchain, setSelectedBlockchain, hotVendor}}
    >
      {children}
    </AddAccountContext.Provider>
  )
}

const useAddAccountContext = () => useContext(AddAccountContext)

export const AddAccount = ({
  preselectedBlockchain,
  buttonWidth,
}: {
  preselectedBlockchain?: Blockchain
  buttonWidth?: number
}) => {
  const {t} = useTranslation()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const onClose = () => setIsModalOpen(false)

  return (
    <>
      <Button
        sx={{width: buttonWidth}}
        variant="outlined"
        color="primary"
        onClick={(e) => {
          e.stopPropagation()
          setIsModalOpen(true)
        }}
        startIcon={<AddAccountIcon />}
        textTransform="none"
        data-test-id="portfolio-add-account-button"
        data-add-account-blockchain={preselectedBlockchain}
      >
        {t('Add account')}
      </Button>
      {isModalOpen && (
        <Box onClick={(e) => e.stopPropagation()}>
          <ChooseCryptoProviderType {...{onClose, preselectedBlockchain}} />
        </Box>
      )}
    </>
  )
}

export const AutoLoginAddAccount = () => {
  const {action, clearAction} = useAppOpenerStore()

  if (action?.type !== 'addAccount') {
    return null
  }

  return (
    <ChooseCryptoProviderType
      onClose={clearAction}
      preselectedBlockchain={action.blockchain}
    />
  )
}
