import {arraySum} from '@nufi/wallet-common'
import {getFlowContractId} from '@nufi/wallet-flow'
import type {
  FlowNft,
  FlowTokenAmount,
  FlowTokenContractMetadata,
  FlowTokenId,
  FlowTokenMetadata,
  FlowTokenName,
  FlowTokenTicker,
  FlowContractId,
  FlowNFTContractMetadata,
} from '@nufi/wallet-flow'
import BigNumber from 'bignumber.js'
import _ from 'lodash'

import {getBlockchainDecimals} from 'src/constants'

const getStaticNftContractMetadata = (
  nftCollections: FlowNFTContractMetadata[],
  contractId: FlowContractId,
): FlowNFTContractMetadata | undefined =>
  nftCollections.find(
    (c) => getFlowContractId(c.contractAddress, c.contractName) === contractId,
  )

export const getFlowNftTokenMetadata = (
  nft: FlowNft,
  nftCollections: FlowNFTContractMetadata[],
): FlowTokenMetadata => ({
  id: nft.id,
  blockchain: 'flow',
  name: nft.metadata.title as FlowTokenName,
  ticker: (getStaticNftContractMetadata(
    nftCollections,
    getFlowContractId(
      nft.contractMetadata.contractAddress,
      nft.contractMetadata.contractName,
    ),
  )?.collection.name || nft.contractMetadata.contractName) as FlowTokenTicker,
  // if we don't have the contract metadata defined, we fallback to the default one
  contractMetadata: nft.contractMetadata,
  decimals: 0,
  // TODO: rethink / unify fetching and storing of nft info in the token metadata
  isNft: true,
})

export const aggregateTokenAmounts = (
  tokenAmounts: FlowTokenAmount[],
): FlowTokenAmount[] =>
  _(tokenAmounts)
    .groupBy(({token}) => token.id)
    .map((tokenGroup) => ({
      token: tokenGroup[0]!.token, // we are mapping over grouped tokens so this is safe
      amount: arraySum(tokenGroup.map(({amount}) => amount)),
    }))
    .flatten()
    .value()

export const getNftTokenAmount = (nft: FlowNft): FlowTokenAmount => ({
  token: {id: nft.id, blockchain: nft.blockchain},
  amount: new BigNumber(1), // there is always only single instance of NFT
})

// we don't have contract-specific metadata so we get cast it to
// to TokenMetadata since most logic relies on it
export function getTokenMetadataFromContract(
  contractMetadata?: FlowTokenContractMetadata,
): FlowTokenMetadata | undefined {
  if (!contractMetadata) {
    return undefined
  }
  const tokenId = getFlowContractId(
    contractMetadata.contractAddress,
    contractMetadata.contractName,
  ) as unknown as FlowTokenId

  const commonTokenMetadata = {
    id: tokenId,
    name: contractMetadata.collection.name as FlowTokenName,
    blockchain: 'flow' as const,
    ticker: (contractMetadata.collection.ticker ||
      contractMetadata.contractName) as FlowTokenTicker,
  }

  if (contractMetadata.isFungible) {
    return {
      ...commonTokenMetadata,
      isNft: false,
      contractMetadata,
      decimals: getBlockchainDecimals('flow'),
    }
  }

  return {
    ...commonTokenMetadata,
    isNft: true,
    contractMetadata,
    decimals: 0,
  }
}

// we find contractMetadata by tokenId since the generic logic does not consider a contractId
export function findContractMetadataByTokenId(
  tokenContracts: FlowTokenContractMetadata[],
  tokenId: FlowTokenId | null,
): FlowTokenContractMetadata | undefined {
  if (!tokenId) {
    return undefined
  }
  return tokenContracts.find(
    (tc) =>
      (getFlowContractId(
        tc.contractAddress,
        tc.contractName,
      ) as unknown as FlowTokenId) === tokenId,
  )
}
