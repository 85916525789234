import {DeleteForever as DeleteIcon} from '@mui/icons-material'
import {Grid, Typography, IconButton, styled} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {tokenBlockchains} from '../../../blockchainTypes'
import {
  TextButton,
  IconCol,
  FormattedTokenName,
  WithTooltip,
} from '../../../components'
import {IS_EXCHANGE_ENABLED} from '../../../constants'
import {
  useRouteToAccountAction,
  useRouteToAssetDetail,
} from '../../../router/portfolio'
import type {Blockchain, TokenMetadata} from '../../../types'
import {BlockchainSubsetGuard} from '../../../utils/blockchainGuards'
import {useGetNativeAssetName} from '../../../utils/translations'
import type {AccountId} from '../../../wallet'
import {OpenExchangeButton} from '../../exchange/OpenExchangeButton'

import {AccountListItemLayout} from './AccountListLayout'
import {useForgetModalUtils} from './actions/ForgetToken'

type SharedAssetRowProps = {
  description: string
  Icon: React.ReactNode
  balanceCol: React.ReactNode
  priceCol: React.ReactNode
  disableBottomBorder?: boolean
}

type AssetRowProps = {
  id: AccountId
  blockchain: Blockchain
  tokenMetadata?: TokenMetadata
} & SharedAssetRowProps

export function AssetRow({
  blockchain,
  id,
  balanceCol,
  priceCol,
  Icon,
  description,
  tokenMetadata,
  disableBottomBorder,
}: AssetRowProps) {
  const routeToSend = useRouteToAccountAction(
    'send',
    blockchain,
    id,
    tokenMetadata?.id,
  )
  const routeToReceive = useRouteToAccountAction(
    'receive',
    blockchain,
    id,
    tokenMetadata?.id,
  )
  const routeToExchange = useRouteToAccountAction(
    'exchange',
    blockchain,
    id,
    tokenMetadata?.id,
  )

  const routeToAssetDetail = useRouteToAssetDetail(
    blockchain,
    id,
    tokenMetadata?.id,
  )

  const onClick = () => routeToAssetDetail()

  const onSend = (e: React.SyntheticEvent) => {
    e.stopPropagation()
    routeToSend()
  }

  const onReceive = (e: React.SyntheticEvent) => {
    e.stopPropagation()
    routeToReceive()
  }

  const onExchange = (e: React.SyntheticEvent) => {
    e.stopPropagation()
    routeToExchange()
  }

  const commonAssetRowProps = {
    balanceCol,
    priceCol,
    Icon,
    description,
    disableBottomBorder,
    onClick,
    onSend,
    onReceive,
    onExchange,
    accountId: id,
    blockchain,
  }

  return !tokenMetadata
    ? _NativeAssetRow(commonAssetRowProps)
    : _TokenAssetRow({
        tokenMetadata,
        ...commonAssetRowProps,
      })
}

type _CommonAssetRowProps = {
  onClick: (e: React.SyntheticEvent) => unknown
  onReceive: (e: React.SyntheticEvent) => unknown
  onSend: (e: React.SyntheticEvent) => unknown
  onExchange: (e: React.SyntheticEvent) => unknown
  tokenMetadata?: TokenMetadata
  accountId: AccountId
  blockchain: Blockchain
} & SharedAssetRowProps

type _NativeAssetRowProps = _CommonAssetRowProps & {
  tokenMetadata?: undefined
}

const _NativeAssetRow = ({blockchain, ...restProps}: _NativeAssetRowProps) => {
  const getNativeAssetName = useGetNativeAssetName()

  return _AssetRow({
    title: getNativeAssetName(blockchain),
    blockchain,
    ...restProps,
  })
}

type _TokenAssetRowProps = _CommonAssetRowProps & {
  tokenMetadata: TokenMetadata
}

const _TokenAssetRow = ({
  tokenMetadata,
  blockchain,
  ...restProps
}: _TokenAssetRowProps) => {
  return (
    <BlockchainSubsetGuard
      blockchain={blockchain}
      blockchainSubset={tokenBlockchains}
    >
      {(tokenBlockchain) =>
        _AssetRow({
          title: (
            <FormattedTokenName
              blockchain={tokenBlockchain}
              tokenInfo={tokenMetadata}
              ellipsize
            />
          ),
          tokenMetadata,
          blockchain,
          ...restProps,
        })
      }
    </BlockchainSubsetGuard>
  )
}

type _AssetRowProps = _CommonAssetRowProps & {
  title: string | JSX.Element
}

function _AssetRow({
  blockchain,
  accountId,
  title,
  onClick,
  onReceive,
  onSend,
  onExchange,
  balanceCol,
  priceCol,
  Icon,
  description,
  tokenMetadata,
  disableBottomBorder,
}: _AssetRowProps) {
  const {t} = useTranslation()

  const forgetModalUtils = useForgetModalUtils({
    accountId,
    blockchain,
    tokenId: tokenMetadata?.id,
  })

  return (
    <>
      <AssetRowContainer onClick={onClick} {...{disableBottomBorder}}>
        <AccountListItemLayout
          titleCol={
            <SpacedBox leftSpacing={tokenMetadata ? 3 : 0}>
              <IconCol ellipsizeTitle {...{Icon, description, title}} />
            </SpacedBox>
          }
          priceCol={
            <Typography variant="body2" component="div">
              {priceCol}
            </Typography>
          }
          balanceCol={
            <Typography color="textPrimary" variant="body2" component="div">
              {balanceCol}
            </Typography>
          }
          actionsCol={
            <Grid
              container
              spacing={2}
              justifyContent="flex-end"
              alignItems="center"
            >
              {forgetModalUtils && (
                <Grid item>
                  <WithTooltip title={<>{t('Forget token')}</>}>
                    <IconButton
                      onClick={(e: React.SyntheticEvent) => {
                        e?.stopPropagation()
                        forgetModalUtils.openModal()
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </WithTooltip>
                </Grid>
              )}
              <Grid item>
                <TextButton
                  color="textSecondary"
                  onClick={onSend}
                  label={t('Send')}
                  fontWeight="medium"
                />
              </Grid>
              <Grid item>
                <TextButton
                  color="textSecondary"
                  onClick={onReceive}
                  label={t('Receive')}
                  fontWeight="medium"
                />
              </Grid>
              {IS_EXCHANGE_ENABLED && (
                <Grid item>
                  <OpenExchangeButton
                    variant="text"
                    onOpen={onExchange}
                    targetAsset={{blockchain, tokenId: tokenMetadata?.id}}
                  />
                </Grid>
              )}
            </Grid>
          }
        />
      </AssetRowContainer>
      {forgetModalUtils &&
        forgetModalUtils.isModalOpen &&
        forgetModalUtils.ForgetTokenModal}
    </>
  )
}

type AssetRowContainerProps = {
  disableBottomBorder?: boolean
}

export const ASSET_ROW_HEIGHT = 70

const AssetRowContainer = styled('div')<AssetRowContainerProps>(
  ({theme, disableBottomBorder}) => ({
    height: ASSET_ROW_HEIGHT,
    boxSizing: 'border-box',
    cursor: 'pointer',
    background: theme.palette.background.paper,
    padding: theme.spacing(1.5, 2),
    borderBottom: !disableBottomBorder
      ? `1px solid ${theme.palette.divider}`
      : 'none',
    '&:hover': {
      background: theme.palette.background.default,
    },
    width: '100%',
  }),
)

const SpacedBox = styled('div')<{leftSpacing?: number; bottomSpacing?: number}>(
  ({theme, leftSpacing, bottomSpacing}) => ({
    marginLeft: theme.spacing(leftSpacing || 3),
    bottomSpacing: theme.spacing(bottomSpacing || 0),
  }),
)
