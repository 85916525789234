import type BigNumber from 'bignumber.js'
import React from 'react'
import {useTranslation} from 'react-i18next'

import type {Blockchain} from 'src/types'

import {TxAccordionRow, AssetAmount, TxDetailRow} from './common'
import {AssetMovementRow} from './common/AssetMovementRow'

type NativeMovementProps = {
  fee: BigNumber | null
  amount: BigNumber | null
  blockchain: Blockchain
}

export const NativeMovementRow = ({
  amount,
  fee,
  blockchain,
}: NativeMovementProps) => {
  const {t} = useTranslation()
  return (
    <TxAccordionRow
      defaultExpanded
      summary={
        <AssetMovementRow
          amount={amount}
          blockchain={blockchain}
          type="native"
          withAmountSignPrefix
        />
      }
      details={
        <>
          {amount && (
            <Row
              label={amount.isNegative() ? t('Send') : t('Receive')}
              // `.plus` as fee is already expected to be part of diff
              amount={amount?.plus(fee ?? 0)}
              blockchain={blockchain}
            />
          )}
          {fee && (
            <Row
              label={t('Fee')}
              amount={fee.negated()}
              blockchain={blockchain}
            />
          )}
        </>
      }
    />
  )
}

type RowProps = Pick<NativeMovementProps, 'blockchain'> & {
  label: string
  amount: BigNumber
}

const Row = ({blockchain, amount, label}: RowProps) => {
  return (
    <TxDetailRow
      left={label}
      right={
        <AssetAmount
          blockchain={blockchain}
          amount={amount}
          withAmountSignPrefix
        />
      }
    />
  )
}
