import type {AutoDiscoveryType} from '@nufi/wallet-common'

import type {LocalProfileLoginType} from 'src/appStorage'
import type {ProfileManager} from 'src/appStorage/profileManager'
import {isWalletKindAvailable} from 'src/features/availableBlockchains/application'
import type {AccountStoredData} from 'src/store/wallet'
import type {Blockchain} from 'src/types'
import {createAsyncServiceLocator} from 'src/utils/serviceLocator'
import {initCardanoWallet} from 'src/wallet/cardano/cardanoManagers'
import {evm} from 'src/wallet/evm/commonEvmManagers'
import {flow} from 'src/wallet/flow/flowManagers'
import {initSecretProvider} from 'src/wallet/secretProvider'
import {solana} from 'src/wallet/solana/solanaManagers'
import {blockchainToWalletKind} from 'src/wallet/walletKind'
import type {WalletKind} from 'src/wallet/walletKind'
import type {WidgetLoginType} from 'src/widget/features/login/domain'

import {AccountsStorageService} from '../domain/accountsStorageService'
import type {
  InitializationStore,
  WalletManager,
  AccountsStorageServiceInitializeArgs,
} from '../domain/accountsStorageService'

export const INIT_WALLET_CONFIGURATIONS = {
  getLoginToConnectorArgs: (
    blockchain: Blockchain,
    loginType: LocalProfileLoginType,
  ): AccountsStorageServiceInitializeArgs => ({
    accountLoad: {
      type: 'selectedWalletKinds',
      walletKinds: [blockchainToWalletKind(blockchain)],
    },
    accountDiscovery: {type: 'skip'},
    loginType,
  }),
  getLoginToAppArgs: (
    loginType: LocalProfileLoginType,
  ): AccountsStorageServiceInitializeArgs => ({
    accountLoad: {type: 'allWalletKinds'},
    accountDiscovery: {type: 'skip'},
    loginType,
  }),
  getCreateWalletArgs: (
    blockchains: Partial<Record<Blockchain, AutoDiscoveryType>>,
    loginType: LocalProfileLoginType,
  ): AccountsStorageServiceInitializeArgs => ({
    accountLoad: {type: 'allWalletKinds'},
    accountDiscovery: {
      type: 'perWalletKind',
      walletKinds: Object.entries(blockchains).reduce(
        (res, [blockchain, discoveryType]) => {
          res[blockchainToWalletKind(blockchain as Blockchain)] = discoveryType
          return res
        },
        {} as Partial<Record<WalletKind, AutoDiscoveryType>>,
      ),
    },
    loginType,
  }),
  getRestoreMnemonicWallet: (
    loginType: LocalProfileLoginType,
    blockchains: readonly Blockchain[],
  ): AccountsStorageServiceInitializeArgs => ({
    accountLoad: {
      type: 'selectedWalletKinds',
      walletKinds: blockchains.map(blockchainToWalletKind),
    },
    accountDiscovery: {
      type: 'perWalletKind',
      walletKinds: Object.fromEntries(
        blockchains.map((blockchain) => [
          blockchainToWalletKind(blockchain),
          'usedOrFallback',
        ]),
      ),
    },
    loginType,
  }),
  getRestoreCloudSyncedMnemonicWallet: (
    loginType: LocalProfileLoginType,
  ): AccountsStorageServiceInitializeArgs => ({
    accountLoad: {type: 'allWalletKinds'},
    accountDiscovery: {type: 'skip'},
    loginType,
  }),
  getRestoreWalletFromBackupArgs: (
    loginType: LocalProfileLoginType,
  ): AccountsStorageServiceInitializeArgs => ({
    accountLoad: {type: 'allWalletKinds'},
    accountDiscovery: {type: 'usedOnly'},
    loginType,
  }),
  getCreateBlockchainRestrictedWalletArgs: (
    loginType: WidgetLoginType,
    blockchains: readonly Blockchain[],
  ): AccountsStorageServiceInitializeArgs => ({
    accountLoad: {
      type: 'selectedWalletKinds',
      walletKinds: blockchains.map(blockchainToWalletKind),
    },
    accountDiscovery: {
      type: 'perWalletKind',
      walletKinds: {cardano: 'usedOrFallback'},
    },
    loginType,
  }),
  getLoginToBlockchainRestrictedWalletArgs: (
    loginType: WidgetLoginType,
    blockchains: readonly Blockchain[],
  ): AccountsStorageServiceInitializeArgs => ({
    accountLoad: {
      type: 'selectedWalletKinds',
      walletKinds: blockchains.map(blockchainToWalletKind),
    },
    accountDiscovery: {type: 'skip'},
    loginType,
  }),
}

export const AccountStorageServiceLocator = createAsyncServiceLocator(
  async ({
    args,
    profileManager,
    initializationStore,
  }: {
    profileManager: ProfileManager
    args: AccountsStorageServiceInitializeArgs
    initializationStore: InitializationStore
  }) => {
    const accountStorageService = new AccountsStorageService(
      (loginType) =>
        [
          solana,
          initCardanoWallet(loginType),
          flow,
          evm,
        ] as WalletManager<AccountStoredData>[],
      profileManager,
      initializationStore,
      initSecretProvider,
      isWalletKindAvailable,
    )
    await accountStorageService.initialize(args)
    return accountStorageService
  },
)
