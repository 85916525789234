import {SvgIcon, Badge, styled} from '@mui/material'
import {getWrTestnetTokenId} from '@nufi/wallet-cardano'
import React from 'react'

import {ReactComponent as WingRidersAvalancheIcon} from '../assets/icons/wingriders/wavax.svg'
import {ReactComponent as WingRidersBinanceIcon} from '../assets/icons/wingriders/wbnb.svg'
import {ReactComponent as WingRidersBitcoinIcon} from '../assets/icons/wingriders/wbtc.svg'
import {ReactComponent as WingRidersDogecoinIcon} from '../assets/icons/wingriders/wdoge.svg'
import {ReactComponent as WingRidersPolkadotIcon} from '../assets/icons/wingriders/wdot.svg'
import {ReactComponent as WingRidersEthereumIcon} from '../assets/icons/wingriders/weth.svg'
import {ReactComponent as WingRidersLunaIcon} from '../assets/icons/wingriders/wluna.svg'
import {ReactComponent as WingRidersShibaInuIcon} from '../assets/icons/wingriders/wshiba.svg'
import {ReactComponent as WingRidersUsdCoinIcon} from '../assets/icons/wingriders/wusdc.svg'
import {ReactComponent as WingRidersUsdTetherIcon} from '../assets/icons/wingriders/wusdt.svg'
import {ReactComponent as WingRidersXrpIcon} from '../assets/icons/wingriders/wxrp.svg'
import config from '../config'
import type {Blockchain, TokenMetadata} from '../types'
import {safeAssertUnreachable} from '../utils/assertion'
import {getPeggedNativeAssetBlockchain} from '../utils/blockchain'
import {isEvmBlockchain} from '../wallet/evm'

import {Icon, iconDims} from './visual/atoms/Icon'
import type {IconProps} from './visual/atoms/Icon'

const getTokenIcon = (tokenMetadata: TokenMetadata): string | undefined => {
  switch (tokenMetadata.blockchain) {
    case 'solana':
      return tokenMetadata.logoURI
    case 'cardano':
      return tokenMetadata.logoHex != null
        ? `data:image/png;base64,${tokenMetadata.logoHex}`
        : undefined
    case 'flow': {
      const collection = tokenMetadata.contractMetadata.collection
      if ('logoHex' in collection) {
        return collection.logoHex
      }
      return collection.uri
    }
    default: {
      if (isEvmBlockchain(tokenMetadata.blockchain)) {
        return tokenMetadata.logoURI
      }
      return safeAssertUnreachable(tokenMetadata.blockchain)
    }
  }
}

const defaultIconSize = iconDims.small.height
const iconBadgeSize = Math.round(defaultIconSize * 0.6)

type AssetIconProps = Omit<IconProps, 'type'> & {
  blockchain: Blockchain
  tokenMetadata?: TokenMetadata | null
}

export const AssetIcon = ({
  blockchain,
  tokenMetadata,
  exactSize = defaultIconSize,
  showBlockchainBadge,
  ...rest
}: AssetIconProps & ShowBlockchainBadge) => {
  if (tokenMetadata) {
    return (
      <TokenIcon
        {...rest}
        {...{
          type: `${blockchain}DefaultTokenIcon`,
          blockchain,
          tokenMetadata,
          exactSize,
          showBlockchainBadge,
        }}
      />
    )
  }

  // some blockchain native assets are just pegged native assets from another blockchain
  // for these blockchain we display the native assets as if it was a token
  const peggedNativeAssetBlockchain = getPeggedNativeAssetBlockchain(blockchain)
  if (peggedNativeAssetBlockchain) {
    return (
      <BlockchainBadge blockchain={blockchain} exactSize={iconBadgeSize}>
        <Icon
          {...rest}
          exactSize={exactSize}
          type={peggedNativeAssetBlockchain}
        />
      </BlockchainBadge>
    )
  }

  return <Icon {...rest} exactSize={exactSize} type={blockchain} />
}

type ShowBlockchainBadge = {
  showBlockchainBadge?: boolean
}

export const TokenIcon = ({
  blockchain,
  exactSize,
  showBlockchainBadge,
  tokenMetadata,
  ...props
}: TokenIconProps & ShowBlockchainBadge) => {
  if (config.cardanoNetwork !== 'mainnet') {
    const tokenId = tokenMetadata.id
    const WRTestnetIcon = tokenId && WR_TESTNET_ICONS[tokenId]
    if (WRTestnetIcon) {
      const WRIcon = (
        <SvgIcon
          width={exactSize}
          height={exactSize}
          viewBox="0 0 32 32"
          {...props}
          style={exactSize ? {fontSize: exactSize} : {}}
        >
          <WRTestnetIcon />
        </SvgIcon>
      )
      return showBlockchainBadge ? (
        <BlockchainBadge blockchain={blockchain} exactSize={iconBadgeSize}>
          {WRIcon}
        </BlockchainBadge>
      ) : (
        WRIcon
      )
    }
  }

  return showBlockchainBadge ? (
    <BlockchainBadge blockchain={blockchain} exactSize={iconBadgeSize}>
      <_TokenIcon {...props} {...{blockchain, exactSize, tokenMetadata}} />
    </BlockchainBadge>
  ) : (
    <_TokenIcon {...props} {...{blockchain, exactSize, tokenMetadata}} />
  )
}

type TokenIconProps = IconProps & {
  blockchain: Blockchain
  tokenMetadata: TokenMetadata
}

const _TokenIcon = ({
  tokenMetadata,
  exactSize = defaultIconSize,
  ...iconProps
}: TokenIconProps) => {
  const iconDims = {
    width: exactSize,
    height: exactSize,
  }

  const iconURI = getTokenIcon(tokenMetadata)
  return iconURI ? (
    <img
      src={iconURI}
      width={iconDims.width}
      height={iconDims.height}
      style={{borderRadius: '50%'}}
    />
  ) : (
    <Icon {...iconProps} exactSize={exactSize} />
  )
}

type BlockchainBadgeProps = {
  blockchain: Blockchain
  children: React.ReactNode
  exactSize?: number
}

const BlockchainBadge = ({
  blockchain,
  children,
  exactSize,
}: BlockchainBadgeProps) => {
  return (
    <BlockchainBadgeRoot
      badgeContent={
        <BadgeIconWrapper title={blockchain}>
          <Icon type={blockchain} exactSize={exactSize || 16} />
        </BadgeIconWrapper>
      }
    >
      {children}
    </BlockchainBadgeRoot>
  )
}

const BadgeIconWrapper = styled('span')(({theme: {palette}}) => ({
  display: 'inline-flex',
  background: palette.background.paper,
  padding: '2.5px',
  borderRadius: '50%',
}))

const BlockchainBadgeRoot = styled(Badge)({
  background: 'transparent',
  backgroundColor: 'transparent',
  '& .MuiBadge-badge': {
    padding: 0,
    height: 'unset',
    minWidth: 'unset',
  },
})

const WR_TESTNET_ICONS = {
  [getWrTestnetTokenId('wBTC')]: WingRidersBitcoinIcon,
  [getWrTestnetTokenId('wETH')]: WingRidersEthereumIcon,
  [getWrTestnetTokenId('wBNB')]: WingRidersBinanceIcon,
  [getWrTestnetTokenId('wUSDT')]: WingRidersUsdTetherIcon,
  [getWrTestnetTokenId('wUSDC')]: WingRidersUsdCoinIcon,
  [getWrTestnetTokenId('wDOT')]: WingRidersPolkadotIcon,
  [getWrTestnetTokenId('wLUNA')]: WingRidersLunaIcon,
  [getWrTestnetTokenId('wDOGE')]: WingRidersDogecoinIcon,
  [getWrTestnetTokenId('wAVAX')]: WingRidersAvalancheIcon,
  [getWrTestnetTokenId('wSHIBA')]: WingRidersShibaInuIcon,
  [getWrTestnetTokenId('wXRP')]: WingRidersXrpIcon,
}
