import {Divider, Stack, styled} from '@mui/material'
import {Form} from 'formik'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {FooterLayout} from 'src/components'
import type {RampCryptoAsset, RampOperation} from 'src/features/ramp/domain'

import {RampFlowHeaderWithTitle} from '../../../components/RampFlowHeader'
import {RampFlowScreenLayout} from '../../../components/RampFlowLayout'
import {useRampFlowConfig} from '../../../state/rampFlowConfig'

import {useRampFlowDetailsForm} from './detailsForm'
import {RampFlowAccountSelect} from './RampFlowAccountSelect'
import {RampFlowAssetSelect} from './RampFlowAssetSelect'
import {RampFlowOperationToggle} from './RampFlowOperationToggle'

export type UnSupportedOperationProps = {
  getIsOperationSupportedInApp: (
    operation: RampOperation,
    asset: RampCryptoAsset,
  ) => boolean
  renderOperationNotSupportedContent: (
    operation: RampOperation,
    asset: RampCryptoAsset,
  ) => React.ReactNode
}

type RampFlowDetailsFormProps = UnSupportedOperationProps

export const RampFlowDetailsForm = ({
  getIsOperationSupportedInApp,
  renderOperationNotSupportedContent,
}: RampFlowDetailsFormProps) => {
  const {t} = useTranslation()
  const {onCancel} = useRampFlowConfig()
  const {
    values: {asset, operation},
  } = useRampFlowDetailsForm()

  const operationSupported =
    asset && getIsOperationSupportedInApp(operation, asset)

  return (
    <StyledForm>
      <RampFlowScreenLayout
        header={<RampFlowHeaderWithTitle />}
        body={
          <Stack spacing={3} px={2} py={2}>
            <RampFlowOperationToggle />
            <RampFlowAssetSelect />
            {operationSupported ? (
              <>
                <Divider />
                <RampFlowAccountSelect />
              </>
            ) : (
              // Note that alternative to this approach is letting user choose the
              // account, and construct proper url. The
              // current approach was chosen as it is simpler, and could be
              // easily changed in the future.
              asset && renderOperationNotSupportedContent(operation, asset)
            )}
          </Stack>
        }
        footer={
          <FooterLayout
            leftBtnConf={{children: t('Close'), onClick: onCancel}}
            rightBtnConf={{
              children: t('Continue'),
              type: 'submit',
              // Note that alternative to this approach is letting user choose the
              // account, and construct proper url. The
              // current approach was chosen as it is simpler, and could be
              // easily changed in the future.
              disabled: !operationSupported,
            }}
          />
        }
      />
    </StyledForm>
  )
}

const StyledForm = styled(Form)({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
})
