import {Box, Grid, Link as MuiLink} from '@mui/material'
import type {SolanaAccountWithStakeAccounts} from '@nufi/wallet-solana'
import {
  sumRewards,
  sumStakedAmounts,
  sumInactiveAmounts,
  findValidator,
} from '@nufi/wallet-solana'
import type BigNumber from 'bignumber.js'
import React, {useState, useRef, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'

import {useGetConversionRates} from 'src/features/conversionRates/application'
import type {ConversionRates} from 'src/features/conversionRates/domain'
import {WithConversionRates} from 'src/features/conversionRates/ui'

import {
  AccordionCard,
  QueryGuard,
  ListHeaderTitle,
  AccountCardCol,
  FormattedAssetPair,
  getSolanaParentAccountRewardsLink,
} from '../../../../components'
import {routeTo} from '../../../../router'
import {useGetValidatorsSortedByStake} from '../../../../wallet/solana'
import {
  useStakeParentAccountRowStyles,
  StakeParentCardLayout,
  ShowStakingCardDetails,
  StakeAccountList,
} from '../../common/overview/StakeAccountListLayoutUtils'
import {
  ScrollableList,
  NewStakingButton,
} from '../../common/overview/StakeOverviewUtils'

import {RecentRewardsIconTooltip} from './common'
import StakeAccountCard from './StakeAccountCard'
import {StakeAccountListHeader} from './StakeAccountListLayout'

type SolanaStakeAccountListProps = {
  accounts: SolanaAccountWithStakeAccounts[]
}

export default function SolanaStakeAccountList({
  accounts,
}: SolanaStakeAccountListProps) {
  const {rates} = useGetConversionRates()
  return (
    <ScrollableList>
      {accounts.map((account) => (
        <SolanaStakeAccountListItem
          key={account.id}
          account={account}
          conversionRates={rates}
        />
      ))}
    </ScrollableList>
  )
}

type SolanaStakeAccountListItemProps = {
  account: SolanaAccountWithStakeAccounts
  conversionRates: ConversionRates
}

function SolanaStakeAccountListItem({
  account,
  conversionRates,
}: SolanaStakeAccountListItemProps) {
  const actionRef = useRef<HTMLElement>(null)
  const validatorsSortedByStakeQuery = useGetValidatorsSortedByStake()

  const accountRewardSum = sumRewards(account.stakeAccounts)
  const accountStakedSum = sumStakedAmounts(account.stakeAccounts)
  const accountInactiveSum = sumInactiveAmounts(account.stakeAccounts)

  const [expanded, setExpanded] = useState(false)
  const onCardClick = (
    event: React.ChangeEvent<unknown>,
    isExpanded: boolean,
  ) => {
    account.stakeAccounts.length > 0 &&
      !actionRef?.current?.contains(event.target as Node) &&
      setExpanded(isExpanded)
  }

  useEffect(() => {
    if (account.stakeAccounts.length === 0) {
      setExpanded(false)
    }
  }, [account.stakeAccounts.length])

  return (
    <AccordionCard
      elevation={expanded ? 1 : 0}
      expanded={expanded}
      onCardClick={onCardClick}
      mainContent={
        <StakeParentAccountRow
          account={account}
          rewardAmount={accountRewardSum}
          stakedAmount={accountStakedSum}
          inactiveAmount={accountInactiveSum}
          actionRef={actionRef}
          conversionRates={conversionRates}
        />
      }
      actions={
        <ShowStakingCardDetails
          expanded={expanded}
          length={account.stakeAccounts.length}
        />
      }
      details={
        <StakeAccountList
          Header={<StakeAccountListHeader />}
          AccountList={
            <QueryGuard {...validatorsSortedByStakeQuery}>
              {(validators) => (
                <>
                  {account.stakeAccounts.map((stakeAccount) => (
                    <StakeAccountCard
                      key={stakeAccount.publicKey.toString()}
                      accountId={account.id}
                      stakeAccount={stakeAccount}
                      validatorInfo={
                        stakeAccount.validator
                          ? findValidator(
                              validators,
                              stakeAccount.validator.toString(),
                            )
                          : undefined
                      }
                    />
                  ))}
                </>
              )}
            </QueryGuard>
          }
        />
      }
    />
  )
}

type StakeParentAccountRowProps = {
  account: SolanaAccountWithStakeAccounts
  rewardAmount: BigNumber
  stakedAmount: BigNumber
  inactiveAmount: BigNumber
  actionRef: React.RefObject<HTMLElement>
  conversionRates: ConversionRates
}

export function StakeParentAccountRow({
  account,
  rewardAmount,
  stakedAmount,
  inactiveAmount,
  actionRef,
  conversionRates,
}: StakeParentAccountRowProps) {
  const classes = useStakeParentAccountRowStyles()
  const {t} = useTranslation()
  const history = useHistory()
  const openStakingModal = () =>
    history.push(
      routeTo.staking.myStaking.solana.account(account.id).setupStakeAccount,
    )

  return (
    <>
      <Grid container className={classes.headerWrapper}>
        <StakeParentCardLayout
          accountCol={<ListHeaderTitle>{t('Account')}</ListHeaderTitle>}
          balanceCol={
            <ListHeaderTitle>{t('Available balance')}</ListHeaderTitle>
          }
          rewardsCol={
            <ListHeaderTitle>{<RecentRewardsIconTooltip />}</ListHeaderTitle>
          }
          stakedCol={<ListHeaderTitle>{t('Staked & locked')}</ListHeaderTitle>}
          inactiveCol={
            <ListHeaderTitle>{t('Inactive & locked')}</ListHeaderTitle>
          }
        />
      </Grid>
      <StakeParentCardLayout
        accountCol={
          <AccountCardCol
            walletKind={'solana'}
            blockchain={'solana'}
            name={account.name}
            cryptoProviderType={account.cryptoProviderType}
          />
        }
        balanceCol={
          <WithConversionRates>
            {(conversionRates) => (
              <FormattedAssetPair
                amount={account.balance}
                blockchain={account.blockchain}
                isSensitiveInformation
                conversionProps={{
                  conversionRates,
                }}
              />
            )}
          </WithConversionRates>
        }
        rewardsCol={
          <MuiLink
            onClick={(event) => event.stopPropagation()}
            onFocus={(event) => event.stopPropagation()}
            target="_blank"
            href={getSolanaParentAccountRewardsLink(account.address)}
          >
            <FormattedAssetPair
              amount={rewardAmount}
              blockchain={account.blockchain}
              color="inherit"
              isSensitiveInformation
              conversionProps={{
                conversionRates,
              }}
            />
          </MuiLink>
        }
        stakedCol={
          <FormattedAssetPair
            amount={stakedAmount}
            blockchain={account.blockchain}
            isSensitiveInformation
            conversionProps={{
              conversionRates,
            }}
          />
        }
        inactiveCol={
          <FormattedAssetPair
            amount={inactiveAmount}
            blockchain={account.blockchain}
            isSensitiveInformation
            conversionProps={{
              conversionRates,
            }}
          />
        }
        actionCol={
          <Grid item container justifyContent="flex-end">
            <Box ref={actionRef}>
              <NewStakingButton
                openStakingModal={openStakingModal}
                blockchain={account.blockchain}
              />
            </Box>
          </Grid>
        }
      />
    </>
  )
}
