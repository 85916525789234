import {
  Languages,
  createMultiChainClient,
} from '@blowfishxyz/api-client/v20230605'

import config from 'src/config'

export type {WarningInner as ScanDomainWarning} from '@blowfishxyz/api-client/v20230605'

export const blowfishClient = createMultiChainClient({
  basePath: 'https://api.blowfish.xyz',
  // consider to use a proxy server to not expose API key
  apiKey: config.blowfishApiKey,
  language: Languages.En,
})
