import {safeAssertUnreachable} from '@nufi/frontend-common'
import type {AccountId, StakeAccountId} from '@nufi/wallet-common'
import type {
  Lamports,
  SolanaTransaction,
  SolanaAccountInfo,
  SolanaAccountWithStakeAccounts,
  SolanaValidatorPubKey,
  SolanaStakeAccountInfo,
  SolanaValidatorInfo,
  SolanaTxFeeParams,
} from '@nufi/wallet-solana'
import {calculateTxFeeAmount} from '@nufi/wallet-solana'
import {PublicKey} from '@solana/web3.js'
import type {FormikHelpers, FormikProps} from 'formik'
import {Formik} from 'formik'
import React from 'react'
import {useHistory} from 'react-router-dom'
import * as yup from 'yup'

import {SignGridPlusScreen} from 'src/pages/transaction/SignGridPlus'

import {
  BatchQueryGuard,
  InlineLoading,
  SafeCenterAligner,
  StakingModalError,
} from '../../../../components'
import {routeTo} from '../../../../router'
import {useTrackTxSubmission} from '../../../../tracking'
import {assert, assertUnreachable} from '../../../../utils/assertion'
import {useVerifyPassword} from '../../../../utils/form'
import {
  useGetTxFeeParams,
  useSubmitStakeInstruction,
  useSignDelegate,
  useGetStakeAccountsInfo,
  useGetValidatorsSortedByStake,
} from '../../../../wallet/solana'
import {ensureAccountById} from '../../../../wallet/utils/common'
import {
  SignLedgerScreen,
  SignMnemonicScreen,
  SignTrezorScreen,
  SubmitStakeScreen,
} from '../../../transaction'
import {useSummaryDetailsSchema} from '../../common/schema'
import type {ActiveScreen} from '../../common/utils'
import {
  DeviceReadyState,
  StakeModalHeader,
  useSetupStakeAccountScreenState,
} from '../../common/utils'
import type {BaseActivateStakeSchema} from '../schema'
import {useValidatorSchema, useStakeFeePayerSchema} from '../schema'
import {
  findStakeAccountAndParentById,
  getRecommendedValidatorId,
} from '../utils'

import ActivateStakeDetailsScreen from './ActivateStakeDetailsScreen'
import SummaryScreen from './CommonDelegateSummaryScreen'

type FormSchema = BaseActivateStakeSchema

export default function SolanaActivateStakeModal({
  onClose,
  stakeAccountId,
}: {
  onClose: () => unknown
  stakeAccountId: StakeAccountId
}) {
  const stakeAccountsQuery = useGetStakeAccountsInfo()
  const txFeeParamsQuery = useGetTxFeeParams({
    affectedAccounts: [new PublicKey(stakeAccountId)], // TODO: include parent account id as well
  })
  const validatorsQuery = useGetValidatorsSortedByStake()

  return (
    <BatchQueryGuard
      queries={{
        accountsWithStakeInfo: stakeAccountsQuery,
        txFeeParams: txFeeParamsQuery,
        validators: validatorsQuery,
      }}
      ErrorElement={<StakingModalError blockchain="solana" />}
      loadingVariant="centered"
    >
      {({accountsWithStakeInfo, txFeeParams, validators}) => {
        // Wait till the stake info is loaded as the account could be just created
        const stakeAccountLoaded = accountsWithStakeInfo.some((a) =>
          a.stakeAccounts.some((sa) => sa.id === stakeAccountId),
        )
        if (!stakeAccountLoaded) {
          return (
            <SafeCenterAligner>
              <InlineLoading />
            </SafeCenterAligner>
          )
        }
        return (
          <SolanaActivateStakeForm
            {...{
              onClose,
              accountsWithStakeInfo,
              txFeeParams,
              stakeAccountId,
              validators,
            }}
          />
        )
      }}
    </BatchQueryGuard>
  )
}

type SolanaActivateStakeFormProps = {
  onClose: () => unknown
  accountsWithStakeInfo: SolanaAccountWithStakeAccounts[]
  txFeeParams: SolanaTxFeeParams
  stakeAccountId: StakeAccountId
  validators: SolanaValidatorInfo[]
}

function SolanaActivateStakeForm({
  onClose,
  accountsWithStakeInfo,
  txFeeParams,
  stakeAccountId,
  validators,
}: SolanaActivateStakeFormProps) {
  const delegate = useSignDelegate()
  const submit = useSubmitStakeInstruction()
  const verifyPassword = useVerifyPassword()
  const [activeScreen, setActiveScreen] = useSetupStakeAccountScreenState()
  const summaryDetailsSchema = useSummaryDetailsSchema({
    accounts: accountsWithStakeInfo,
  })
  const validatorSchema = useValidatorSchema()
  const {parentAccount, stakeAccount} = findStakeAccountAndParentById(
    accountsWithStakeInfo,
    stakeAccountId,
  )
  const accountId = parentAccount.id
  const account = ensureAccountById(accountsWithStakeInfo, accountId)
  const txFeeAmount = calculateTxFeeAmount(txFeeParams)
  const feePayerSchema = useStakeFeePayerSchema({account, txFee: txFeeAmount})

  const schemas = {
    details: validatorSchema,
    summary: {
      ...summaryDetailsSchema,
      ...feePayerSchema,
    },
    'sign-ledger': {},
    'sign-trezor': {},
    'sign-mnemonic': {},
    'sign-gridplus': {},
    submit: {},
  }
  const schema = yup.object().shape(schemas[activeScreen])

  const initialValues: FormSchema = {
    accountId,
    validatorId: getRecommendedValidatorId(validators) || '',
    password: '',
    hasSufficientFeeFunds: true,
  }

  const onTxSign = async (values: FormSchema) =>
    await delegate.mutateAsyncSilent({
      accountId,
      stakePubKey: stakeAccount.publicKey,
      validator: values.validatorId as SolanaValidatorPubKey,
      txFeeParams,
      isOwnerAuthorizedStaker: stakeAccount.isOwnerAuthorizedStaker,
    })

  const onTxSubmit = async (
    accountId: AccountId,
    signedTx: SolanaTransaction,
  ) => await submit.mutateAsyncSilent({signedTx, accountId})

  const onTxSignAndSubmit = async (values: FormSchema) => {
    const signedTx = await onTxSign(values)
    if (!signedTx) return
    setActiveScreen('submit')
    await onTxSubmit(accountId, signedTx)
  }

  const onSubmit = {
    details: (
      values: FormSchema,
      {setSubmitting}: FormikHelpers<FormSchema>,
    ) => {
      setActiveScreen('summary')
      setSubmitting(false)
    },
    summary: async (
      values: FormSchema,
      formikHelpers: FormikHelpers<FormSchema>,
    ) => {
      const passwordVerified = await verifyPassword(
        values.password,
        formikHelpers,
      )
      if (!passwordVerified) return

      switch (account.cryptoProviderType) {
        case 'mnemonic': {
          setActiveScreen('sign-mnemonic')
          await onTxSignAndSubmit(values)
          break
        }
        case 'ledger': {
          setActiveScreen('sign-ledger')
          break
        }
        case 'trezor': {
          setActiveScreen('sign-trezor')
          break
        }
        case 'gridPlus': {
          setActiveScreen('sign-gridplus')
          break
        }
        default:
          assertUnreachable()
      }
    },
    // not handled on formik level
    'sign-mnemonic': () => {
      /**/
    },
    'sign-ledger': () => {
      /**/
    },
    'sign-trezor': () => {
      /**/
    },
    'sign-gridplus': () => {
      /**/
    },
    submit: () => {
      /**/
    },
  }[activeScreen]

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={onSubmit}
    >
      {(formikProps) => (
        <SolanaStakeModalContent
          {...{
            formikProps,
            accounts: accountsWithStakeInfo,
            setActiveScreen,
            activeScreen,
            onClose,
            txFee: txFeeAmount,
            onTxSubmit,
            onTxSignAndSubmit,
            stakeAccount,
            accountId,
            validators,
          }}
          submitProps={submit}
          signProps={delegate}
        />
      )}
    </Formik>
  )
}

type SolanaSendModalContentProps = {
  activeScreen: ActiveScreen
  setActiveScreen: (screen: ActiveScreen) => void
  accounts: SolanaAccountInfo[]
  formikProps: FormikProps<FormSchema>
  submitProps: ReturnType<typeof useSubmitStakeInstruction>
  signProps: ReturnType<typeof useSignDelegate>
  onClose: () => unknown
  txFee: Lamports
  onTxSubmit: (
    accountId: AccountId,
    signedTx: SolanaTransaction,
  ) => Promise<unknown>
  onTxSignAndSubmit: (values: FormSchema) => Promise<unknown>
  stakeAccount: SolanaStakeAccountInfo
  accountId: AccountId
  validators: SolanaValidatorInfo[]
}

function SolanaStakeModalContent({
  activeScreen,
  accounts,
  setActiveScreen,
  formikProps,
  submitProps,
  onClose,
  txFee,
  onTxSubmit,
  onTxSignAndSubmit,
  signProps,
  stakeAccount,
  accountId,
  validators,
}: SolanaSendModalContentProps) {
  const history = useHistory()

  const summary = {
    amount: stakeAccount.balance,
    fee: txFee,
    fromAccount: ensureAccountById(accounts, accountId),
    validatorName: formikProps.values.validatorId,
  }

  const blockchain = 'solana'
  const ModalHeader = <StakeModalHeader {...{blockchain, onClose}} />

  const onSignAndSubmitGoBack = () => {
    signProps.reset()
    submitProps.reset()
    setActiveScreen('details')
  }

  const openSetupStakeAccountModal = () =>
    history.push(
      routeTo.staking.myStaking.solana.account(accountId).setupStakeAccount,
    )

  useTrackTxSubmission(submitProps, {
    blockchain,
    provider: summary.fromAccount.cryptoProviderType,
    type: 'stake',
    id: summary.validatorName,
    value: summary.amount.toNumber(),
  })

  switch (activeScreen) {
    case 'details':
      return (
        <ActivateStakeDetailsScreen
          {...{blockchain, onClose, formikProps, accounts, validators}}
        />
      )
    case 'summary':
      return (
        <SummaryScreen<BaseActivateStakeSchema>
          onBack={() => setActiveScreen('details')}
          onSubmit={formikProps.handleSubmit}
          {...{summary, formikProps, onClose, blockchain}}
        />
      )
    case 'sign-mnemonic':
      return (
        <SignMnemonicScreen
          onRetry={() => onTxSignAndSubmit(formikProps.values)}
          onBack={() => setActiveScreen('details')}
          {...{onClose, signProps, blockchain, ModalHeader}}
        />
      )
    case 'sign-ledger':
      return (
        <SignLedgerScreen
          onBack={onSignAndSubmitGoBack}
          onSign={() => onTxSignAndSubmit(formikProps.values)}
          ReadyContent={<DeviceReadyState hwVendor="ledger" />}
          {...{onClose, signProps, blockchain, ModalHeader}}
        />
      )
    case 'sign-trezor':
      return (
        <SignTrezorScreen
          onBack={onSignAndSubmitGoBack}
          onSign={() => onTxSignAndSubmit(formikProps.values)}
          ReadyContent={<DeviceReadyState hwVendor="trezor" />}
          {...{onClose, signProps, blockchain, ModalHeader}}
        />
      )
    case 'sign-gridplus':
      return (
        <SignGridPlusScreen
          onBack={onSignAndSubmitGoBack}
          onSign={() => onTxSignAndSubmit(formikProps.values)}
          ReadyContent={<DeviceReadyState hwVendor="gridPlus" />}
          {...{onClose, signProps, blockchain, ModalHeader}}
        />
      )
    case 'submit': {
      const signedTransaction = signProps.data
      assert(!!signedTransaction)
      return (
        <SubmitStakeScreen
          accountId={accountId}
          onBack={onSignAndSubmitGoBack}
          onRetry={() => onTxSubmit(accountId, signedTransaction)}
          onNewStaking={() => {
            onSignAndSubmitGoBack()
            formikProps.resetForm()
            openSetupStakeAccountModal()
          }}
          {...{onClose, submitProps, blockchain, ModalHeader}}
        />
      )
    }
    default:
      return safeAssertUnreachable(activeScreen)
  }
}
