import ReactGA from 'react-ga4'

import {getPathWithoutParams} from './utils'

/**
  We override the send and event fn so we can replace the `page_title` field by location hash
  we do this since lots of google analytics reports focus on page title and since we have
  the same page title for every page, we get no reasonable data from it
  https://www.zhsorchards.com/blog/google-analytics-set-up/one-key-change-to-make-ga4-content-reports-useful/
*/

const event = (name: string, params?: Record<string, unknown>): void => {
  ReactGA.event(name, {
    ...params,
    page_title: getPathWithoutParams(),
  })
}

// https://developers.google.com/analytics/devguides/collection/analyticsjs/command-queue-reference#send
type SendParams = {
  hitType:
    | 'event'
    | 'pageview'
    | 'timing'
    | 'screenview'
    | 'transaction'
    | 'item'
    | 'social'
    | 'exception'
}

const send = <T extends SendParams>(fieldObject: T): void => {
  ReactGA.send({
    ...fieldObject,
    page_title: getPathWithoutParams(),
  })
}

export const tracking = {
  ...ReactGA,
  // reexporting `gtag` because its incorrectly typed in the lib d.ts file
  gtag: ReactGA.gtag,
  event,
  send,
}

export * from './utils'
