import {Box, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {sendBlockchains} from '../../blockchainTypes'
import {Modal, ModalLayout} from '../../components'
import {useBaseActionRouteOptions} from '../../router/portfolio'
import type {Blockchain} from '../../types'
import {safeAssertUnreachable} from '../../utils/assertion'
import {isBlockchainSubset} from '../../utils/blockchainGuards'
import {useGetBlockchainName} from '../../utils/translations'
import {isEvmBlockchain} from '../../wallet/evm'

import {CardanoSendModal} from './cardano/SendModal'
import {SendModalHeader} from './common/utils'
import {EvmSendModal} from './evm/SendModal'
import {FlowSendModal} from './flow/SendModal'
import {SolanaSendModal} from './solana/SendModal'

type SendingNotSupportedModalContentProps = {
  blockchain: Blockchain
  onClose: () => unknown
}

const SendingNotSupportedModalContent = ({
  blockchain,
  onClose,
}: SendingNotSupportedModalContentProps) => {
  const {t} = useTranslation()
  const getBlockchainName = useGetBlockchainName()
  return (
    <ModalLayout
      header={
        <SendModalHeader
          {...{
            blockchain,
            onClose,
          }}
        />
      }
      body={
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            height: '100%',
          }}
        >
          <Typography sx={{textAlign: 'center'}}>
            {t('sending_not_available', {
              blockchain: getBlockchainName(blockchain),
            })}
          </Typography>
        </Box>
      }
    />
  )
}

type SendModalProps = {
  onClose: () => unknown
}

export const SendModal = ({onClose}: SendModalProps) => {
  const {blockchain} = useBaseActionRouteOptions()

  return (
    <Modal onClose={onClose} variant="left">
      {(() => {
        if (!isBlockchainSubset(blockchain, sendBlockchains)) {
          return <SendingNotSupportedModalContent {...{onClose, blockchain}} />
        }

        switch (blockchain) {
          case 'solana':
            return <SolanaSendModal onClose={onClose} />
          case 'cardano':
            return <CardanoSendModal onClose={onClose} />
          case 'flow':
            return <FlowSendModal onClose={onClose} />
          default: {
            if (isEvmBlockchain(blockchain)) {
              return <EvmSendModal onClose={onClose} blockchain={blockchain} />
            }
            return safeAssertUnreachable(blockchain)
          }
        }
      })()}
    </Modal>
  )
}
