import {Grid, Typography} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {Aligner} from '../../../../components'

import {RecentRewardsIconTooltip} from './common'

export function StakeAccountListHeader() {
  const {t} = useTranslation()

  return (
    <StakeAccountListItemLayout
      creationDateCol={
        <Typography variant="caption" color="textSecondary">
          {t('Creation date')}
        </Typography>
      }
      stakingBalanceCol={
        <Typography variant="caption" color="textSecondary">
          {t('Staking balance')}
        </Typography>
      }
      rewardBalanceCol={
        <Typography variant="caption" color="textSecondary">
          <RecentRewardsIconTooltip />
        </Typography>
      }
      validatorCol={
        <Typography variant="caption" color="textSecondary">
          {t('Validator')}
        </Typography>
      }
      nextRewardsCol={
        <Typography variant="caption" color="textSecondary">
          {t('Next rewards')}
        </Typography>
      }
      actionsCol={
        <Typography variant="caption" color="textSecondary">
          {''}
        </Typography>
      }
    />
  )
}

type AccountListItemLayoutProps = {
  creationDateCol: React.ReactNode
  stakingBalanceCol: React.ReactNode
  rewardBalanceCol: React.ReactNode
  validatorCol: React.ReactNode
  nextRewardsCol: React.ReactNode
  actionsCol: React.ReactNode
  tableBodyStyles?: boolean
}

export function StakeAccountListItemLayout({
  creationDateCol,
  stakingBalanceCol,
  rewardBalanceCol,
  validatorCol,
  nextRewardsCol,
  actionsCol,
  tableBodyStyles,
}: AccountListItemLayoutProps) {
  const classes = useStyles()
  return (
    <Grid container alignItems="center">
      <Grid item xs={2}>
        <Aligner
          align="left"
          containerClassName={clsx(tableBodyStyles && classes.firstCol)}
        >
          {creationDateCol}
        </Aligner>
      </Grid>
      <Grid item xs={2}>
        <Aligner align="right">{stakingBalanceCol}</Aligner>
      </Grid>
      <Grid item xs={2}>
        <Aligner align="right">{rewardBalanceCol}</Aligner>
      </Grid>
      <Grid item xs={2}>
        <Aligner align="right" itemClassName={classes.textOverflowingContainer}>
          {validatorCol}
        </Aligner>
      </Grid>
      <Grid item xs={2}>
        <Aligner align="right">{nextRewardsCol}</Aligner>
      </Grid>
      <Grid item xs={2} md={2}>
        <Aligner
          containerClassName={clsx(tableBodyStyles && classes.lastCol)}
          align="right"
        >
          {actionsCol}
        </Aligner>
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles((theme) => ({
  // TODO: do this properly and generically
  textOverflowingContainer: {
    overflow: 'hidden',
    paddingLeft: theme.spacing(2),
  },
  firstCol: {
    paddingLeft: theme.spacing(2),
  },
  lastCol: {
    paddingRight: theme.spacing(2),
  },
}))
