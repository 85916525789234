import {
  AccountBalanceWallet as WalletIcon,
  Settings as SettingsIcon,
} from '@mui/icons-material'
import {
  Box,
  Typography,
  Divider,
  Link,
  IconButton,
  Button,
  styled,
  Alert,
  AlertTitle,
} from '@mui/material'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

import type {OpenAppIntent} from 'src/features/appOpener/domain'
import {getAbsoluteFavIconUrl} from 'src/utils/url'
import {isWalletConnectRoute} from 'src/utils/walletConnect'

import {
  BlockchainIcon,
  CompactReloadButton,
  MutationGuard,
  TestnetIndicator,
  WithTooltip,
} from '../components'
import config from '../config'
import {useAuthStore} from '../store/auth'
import type {ScreenInfo} from '../store/dappConnector'
import {useDappConnectorStore} from '../store/dappConnector'
import {useRefetch} from '../utils/refetch'
import {useGetBlockchainName} from '../utils/translations'
import {isEvmBlockchain} from '../wallet/evm'

import {TopBar} from './components/TopBar'
import {INNER_CONTENT_WIDTH} from './constants'
import {EvmBlockchainSelect} from './pages/blockchains/evm/changeChain/EvmBlockchainSelect'
import {MaliciousSiteWarnings} from './pages/maliciousSiteWarnings'
import {Settings} from './pages/settings'
import {usePlatformConfig} from './PlatformConfig'
import {useDomainScan} from './scanDomain/queries'
import type {ConnectorBlockchain} from './types'

type ConnectorWindowLayoutProps = {
  children: React.ReactNode
  hideLayoutContentForScreens?: ScreenInfo['state'][]
}

export function ConnectorWindowLayout({
  children,
  hideLayoutContentForScreens,
}: ConnectorWindowLayoutProps) {
  const {t} = useTranslation()
  const {blockchain, origin, screenInfo, overlayDialog, setOverlayDialog} =
    useDappConnectorStore()

  const [showOriginWarnings, setShowOriginWarnings] = useState(false)
  const domainScanQuery = useDomainScan(origin)

  const hasScanWarnings =
    !!domainScanQuery.data && domainScanQuery.data.length > 0

  useEffect(() => {
    if (hasScanWarnings) {
      setShowOriginWarnings(true)
    }
  }, [domainScanQuery.data])

  const {isRefetching, refetch} = useRefetch()
  const {authState} = useAuthStore()

  const isLoggedIn = authState.status === 'logged_in'

  const intent: OpenAppIntent = (() => {
    if (isLoggedIn) {
      return {
        type: 'openLoggedIn',
        loginType: authState.info.loginType,
      }
    }
    return {type: 'openLoggedOut'}
  })()

  const {openNuFi, Settings: PlatformSettings} = usePlatformConfig()

  const evmBlockchainSelectableScreens: (typeof screenInfo.state)[] = [
    'idle',
    'init',
  ]

  const canChangeEvmBlockchain =
    isEvmBlockchain(blockchain) &&
    evmBlockchainSelectableScreens.includes(screenInfo.state) &&
    // Changing accounts isn't supported in our WalletConnect handlers yet.
    // TODO: Make this reactive, currently React Router isn't being used
    // in web extension/dapp connector, so we can't call useLocation().
    !isWalletConnectRoute(window.location.pathname)

  useEffect(() => {
    if (!isLoggedIn) return

    const handleFocus = () => {
      refetch() // already throttled by useRefetch
    }

    window.addEventListener('focus', handleFocus)
    // eslint-disable-next-line consistent-return
    return () => window.removeEventListener('focus', handleFocus)
  }, [refetch, isLoggedIn])

  if (hideLayoutContentForScreens?.includes(screenInfo.state)) {
    return <Wrapper>{children}</Wrapper>
  }

  return (
    <Wrapper>
      <TopBar
        leftComponent={
          <WithTooltip title={<>{t('Open Wallet')}</>}>
            <Button
              onClick={() => openNuFi(intent)}
              startIcon={<WalletIcon color="primary" />}
            >
              {t('Wallet')}
            </Button>
          </WithTooltip>
        }
        middleComponent={
          blockchain && (
            <Box sx={{display: 'flex', alignItems: 'center'}}>
              {canChangeEvmBlockchain ? (
                <EvmBlockchainSelect />
              ) : (
                <StaticBlockchain {...{blockchain}} />
              )}
              {config.isTestnet && (
                <Box ml={2}>
                  <TestnetIndicator size="small" />
                </Box>
              )}
            </Box>
          )
        }
        rightComponent={
          <Box sx={{display: 'flex', alignItems: 'center'}}>
            {authState.status === 'logged_in' && (
              <CompactReloadButton
                onReload={refetch}
                isLoading={isRefetching}
              />
            )}
            {PlatformSettings && (
              <WithTooltip title={<>{t('Settings')}</>}>
                <IconButton onClick={() => setOverlayDialog('settings')}>
                  <SettingsIcon />
                </IconButton>
              </WithTooltip>
            )}
          </Box>
        }
      />
      <Divider />
      <OriginInfo
        isMaliciousSite={hasScanWarnings}
        onOpenOriginWarning={() => setShowOriginWarnings(true)}
      />
      <Divider />
      <ContentWrapper>
        <Settings
          open={overlayDialog === 'settings'}
          onClose={() => setOverlayDialog('none')}
        />
        {domainScanQuery.data && (
          <MaliciousSiteWarnings
            originWarnings={domainScanQuery.data}
            open={showOriginWarnings}
            onContinue={() => setShowOriginWarnings(false)}
            onReject={() => {
              window.close()
            }}
          />
        )}

        <Box>{children}</Box>

        <Box p={4} pt={0}>
          {/* Do not close/refresh connector info */}
          <Box maxWidth={INNER_CONTENT_WIDTH} textAlign="center">
            <Typography variant="caption" align="center">
              {t('do_not_close_or_refresh_connector')}
            </Typography>
          </Box>

          {/* Help & Feedback links */}
          <Box
            display="flex"
            justifyContent="center"
            minWidth={INNER_CONTENT_WIDTH}
            mt={2}
          >
            <Box display="flex">
              <Link
                variant="caption"
                href="https://support.nu.fi/support/home"
                target="_blank"
              >
                {t('Get help')}
              </Link>
            </Box>
            <Box ml={2} mr={2}>
              <Divider orientation="vertical" />
            </Box>
            <Box display="flex">
              <Link
                variant="caption"
                href="https://nufi.sleekplan.app/"
                target="_blank"
              >
                {t('Give feedback')}
              </Link>
            </Box>
          </Box>
        </Box>
      </ContentWrapper>
      <MutationGuard {...domainScanQuery} error={null} />
    </Wrapper>
  )
}

const OriginInfo = ({
  isMaliciousSite,
  onOpenOriginWarning,
}: {
  isMaliciousSite: boolean
  onOpenOriginWarning: () => void
}) => {
  const {favIconUrl, origin} = useDappConnectorStore()
  const {openDapp} = usePlatformConfig()

  if (!origin) return null

  const originLink = (
    <Link
      sx={{cursor: 'pointer'}}
      variant="body2"
      onClick={() => openDapp(origin)}
    >
      {origin}
    </Link>
  )

  return (
    <>
      {isMaliciousSite ? (
        <MaliciousSiteAlert onSeeMore={onOpenOriginWarning}>
          {originLink}
        </MaliciousSiteAlert>
      ) : (
        <OriginLinkWrapper>
          {favIconUrl && (
            <FavIconWrapper>
              <img
                width={12}
                height={12}
                src={getAbsoluteFavIconUrl(origin, favIconUrl)}
              />
            </FavIconWrapper>
          )}
          {originLink}
        </OriginLinkWrapper>
      )}
    </>
  )
}

const MaliciousSiteAlert = ({
  children,
  onSeeMore,
}: {
  children: React.ReactNode
  onSeeMore: () => void
}) => {
  const {t} = useTranslation()

  return (
    <OriginAlert
      severity="error"
      action={
        <Button color="inherit" size="small" onClick={onSeeMore}>
          {t('See more')}
        </Button>
      }
    >
      <AlertTitle sx={{mb: 0}}>
        {t('Caution: potential malicious website')}
      </AlertTitle>
      {children}
    </OriginAlert>
  )
}

function StaticBlockchain({blockchain}: {blockchain: ConnectorBlockchain}) {
  const getBlockchainName = useGetBlockchainName()
  return (
    <Box display="flex">
      <BlockchainIcon {...{blockchain}} />
      <Typography sx={{px: 1}}>{getBlockchainName(blockchain)}</Typography>
    </Box>
  )
}

const Wrapper = styled('div')({
  width: '100%',
  height: '100vh',
})

const ContentWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})

const FavIconWrapper = styled('div')(({theme}) => ({
  paddingRight: theme.spacing(0.5),
  display: 'flex',
}))

const OriginLinkWrapper = styled('div')(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  paddingLeft: theme.spacing(2),
}))

const OriginAlert = styled(Alert)({
  '& .MuiAlert-icon': {
    alignItems: 'center',
  },
  '& .MuiAlert-message': {
    padding: 0,
  },
  '& .MuiAlert-action': {
    alignItems: 'center',
  },
})
