import React, {useContext, useRef} from 'react'

import type {CardanoTxPlan} from '../../../wallet/cardano'

/*
Some blockchains may want to access specific data during the multi-step transaction process and
each blockchain may want different or no data, make it difficult to handle by some generic props,
type by TS, and such props would need to be passed via multiple levels.
We therefore use Content-Extras approach, so each blockchain can wrap its tx-modals into specific
context provider and its subparts can access it whenever needed. Moreover having Context wrapper and
consumer per blockchain makes it easy to type with TS.
*/

// /////////////////// CARDANO /////////////////////////////////////////////////////////////////

export type CardanoExtras = {
  txPlan: CardanoTxPlan
  txHash: string
}

const CardanoExtrasContext = React.createContext(
  undefined as CardanoExtras | undefined,
)

type WithCardanoExtrasProps = {
  children: React.ReactElement
  extras: {
    txPlan?: CardanoTxPlan | null
    txHash?: string
  }
}

export function WithCardanoExtras({children, extras}: WithCardanoExtrasProps) {
  const _extras =
    extras.txPlan != null && extras.txHash != null
      ? {txPlan: extras.txPlan, txHash: extras.txHash}
      : undefined

  // we are only setting extras on re-render, not changing them from component
  // thus using `useRef` instead of `useState` to avoid +1 render on each parent re-render
  const extrasRef = useRef<CardanoExtras | undefined>(_extras)
  extrasRef.current = _extras

  return (
    <CardanoExtrasContext.Provider value={extrasRef.current}>
      {children}
    </CardanoExtrasContext.Provider>
  )
}

export const useCardanoExtras = () => useContext(CardanoExtrasContext)
