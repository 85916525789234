import type {SelectChangeEvent} from '@mui/material'
import BigNumber from 'bignumber.js'
import type {FormikHelpers, FormikProps} from 'formik'
import type React from 'react'
import {useTranslation} from 'react-i18next'
import * as yup from 'yup'

import type {ProfilePassword} from '../appStorage'
import {verifyPassword} from '../appStorage'
import {assert, assertUnreachable} from '../utils/assertion'

export type SelectChangeFn<T> = (e: SelectChangeEvent<T>) => void

export type FormEvent =
  | React.ChangeEvent<{value: unknown}>
  | React.ChangeEvent<HTMLInputElement>

export function onChangeFactory<
  T extends Record<string | number | symbol, unknown>,
>(data: T, setData: (data: T) => void) {
  return (key: keyof T) => (e: FormEvent) => {
    setData({...data, [key]: e.currentTarget?.value || e.target.value})
  }
}

type FormValidationStrategy = 'after-submit' | 'after-touched' | 'immediate'

export function getHasFormError<T extends Record<string, unknown>>(
  formProps: FormikProps<T>,
  strategy: FormValidationStrategy = 'after-submit',
) {
  return (field: keyof T) => {
    const {errors, touched} = formProps

    switch (strategy) {
      case 'after-submit':
        return formProps.submitCount > 0 && !!(errors[field] && touched[field])
      case 'after-touched':
        return !!(errors[field] && touched[field])
      case 'immediate':
        return !!errors[field]
      default:
        return assertUnreachable()
    }
  }
}

export function useCommonValidations() {
  const {t} = useTranslation()
  return {
    passwordPower: yup
      .string()
      .min(8, t('Password must have at least 8 characters.'))
      .max(40, t('Password can not be longer than 40 characters.')),
  }
}

// https://stackoverflow.com/questions/54738221/typescript-array-find-possibly-undefind
export type ArrayWithUndefined<T extends Array<unknown>> = Array<
  T[number] | undefined
>

export const isValidAmountFieldValue = (value: string): boolean => {
  const validStartWithZero = (v: string) =>
    !!v.match(/^0\.[0-9]*$/) || v === '0'
  const validNonZeroStart = (v: string) => !!v.match(/^[1-9][0-9]*\.?[0-9]*$/)
  return validStartWithZero(value) || validNonZeroStart(value)
}

export const isFnKey = (e: React.KeyboardEvent<HTMLDivElement>): boolean =>
  e.ctrlKey ||
  e.shiftKey ||
  e.altKey ||
  !!e.key.toLowerCase().match(/.*arrow.*/)

export const isDigit = (value: string): boolean => !!value.match(/^[0-9]$/)

export const isValidPositiveIntegerFieldValue = (
  value: string,
  options?: {max?: BigNumber | null},
): boolean => {
  const validWholeNumber = (v: string) => !!v.match(/^[1-9][0-9]*$/)
  return (
    validWholeNumber(value) &&
    (options?.max != null
      ? new BigNumber(value).isLessThanOrEqualTo(options.max)
      : true)
  )
}

/**
 * Trim passed `value` and if encounter single , (comma) turn it into . (dot)
 */
export const sanitizeAmountFieldValue = (value: string): string =>
  value.trim().replace(/^(\d*),(\d*)$/, (match, p1, p2, offset, string) => {
    if (!match) return string
    return [p1, p2].join('.')
  })

export type SchemaWithPassword = {
  password: string
}

export const useVerifyPassword = () => {
  const {t} = useTranslation()

  return async <
    FormikHelper extends Pick<
      FormikHelpers<SchemaWithPassword>,
      'setErrors' | 'setSubmitting'
    >,
  >(
    password: string,
    {setErrors, setSubmitting}: FormikHelper,
  ) => {
    if (password && !(await verifyPassword(password as ProfilePassword))) {
      setErrors({password: t('Incorrect password')})
      setSubmitting(false)
      return false
    }
    return true
  }
}

export const validateAssetDecimalsCount = (
  value: string,
  decimals: number,
): boolean => {
  const rgx = new RegExp(`^\\d*[.,]?\\d{0,${decimals}}$`)
  return rgx.test(value)
}

export const getParentSchema = <TContext, TYupSchema>(
  context: yup.TestContext<TContext>,
): TYupSchema => {
  // There is no easy way how to access parent of the parent context,
  // this approach extracts values from context in a way that is
  // not intended and can change in future version
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const schema = (context as any)?.options?.from?.[1]?.value as TYupSchema

  // Assert to easily notice possible breaking change in the future
  assert(schema != null)

  return schema
}

export const getInputFieldValueFromValidation = <TContext>(
  context: yup.TestContext<TContext>,
): string =>
  // passing original value because JS numbers don't preserve original notation
  // originalValue not exposed in yup TS types, see open issue in yup::
  // https://github.com/jquense/yup/issues/1591
  (context as unknown as {originalValue: string}).originalValue
