import {Grid, Typography, Box} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import type Bignumber from 'bignumber.js'
import {BigNumber} from 'bignumber.js'
import React from 'react'

import {WithConversionRates} from 'src/features/conversionRates/ui'

import type {Blockchain, TokenMetadata} from '../types'

import {FormattedAsset, FormattedAssetAsCurrency} from './formatting'
import {InlineLoading} from './visual/Loading'

type AmountRowProps = {
  label?: string | JSX.Element
  amount: Bignumber | undefined
  isLoading?: boolean
  blockchain: Blockchain
  tokenMetadata?: TokenMetadata
}

export function AmountRow({
  amount,
  blockchain,
  label,
  tokenMetadata,
  isLoading,
}: AmountRowProps) {
  const classes = useStyles()
  return (
    <Grid
      container
      justifyContent={label ? 'space-between' : undefined}
      className={classes.amountRow}
    >
      {label && <Typography color="textSecondary">{label}</Typography>}
      <Grid item>
        <Grid container direction="column" alignItems="flex-end">
          {isLoading ? (
            <Box className={classes.amountLoading}>
              <InlineLoading size={20} />
            </Box>
          ) : (
            <Typography variant="subtitle1">
              <FormattedAsset
                includeAssetSymbol
                amount={amount || new BigNumber(0)}
                tokenMetadata={tokenMetadata}
                blockchain={blockchain}
                isSensitiveInformation={false}
              />
            </Typography>
          )}
          <Typography variant="subtitle1" color="textSecondary" component="div">
            ~&nbsp;
            <WithConversionRates>
              {(conversionRates) => (
                <FormattedAssetAsCurrency
                  includeCurrencySymbol
                  balance={amount || new BigNumber(0)}
                  blockchain={blockchain}
                  tokenMetadata={tokenMetadata}
                  isSensitiveInformation={false}
                  conversionRates={conversionRates}
                />
              )}
            </WithConversionRates>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles((theme) => ({
  amountRow: {
    padding: theme.spacing(0.75, 0),
    [theme.breakpoints.up('windowsOld')]: {
      padding: theme.spacing(1.125, 0),
    },
    [theme.breakpoints.up('windowsZoomed')]: {
      padding: theme.spacing(1.5, 0),
    },
  },
  amountLoading: {
    paddingTop: theme.spacing(0.25),
    paddingBottom: theme.spacing(0.25),
  },
}))
