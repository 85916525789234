import {
  AccountBalanceWallet as PortfolioIcon,
  History as ActivityIcon,
  BarChart as StakingIcon,
  Settings as SettingsIcon,
  Extension as ExtensionIcon,
} from '@mui/icons-material'
import type {SvgIconProps} from '@mui/material'
import {Box, Grid, Typography, MenuItem} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {Link, Route} from 'react-router-dom'

import {useAuthStore} from 'src/store/auth'

import {Icon} from '../../components'
import config from '../../config'
import {routeTo} from '../../router'

const useStyles = makeStyles((theme) => ({
  item: {
    width: 94,
    height: 72,
    borderRadius: 4,
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    color: theme.palette.text.secondary,
    '&:hover': {
      background: theme.palette.action.hover,
      color: theme.palette.primary.main,
    },
    padding: 0,
  },
  activeItem: {
    background: theme.palette.background.paper,
    padding: 0,
  },
  menu: {
    padding: 0,
    height: '100%',
    background: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: theme.spacing(2),
  },
}))

type SidebarItemProps = {
  path: string
  label: string
  Icon: React.FunctionComponent<Pick<SvgIconProps, 'color'>>
}

const SidebarItem = ({path, label, Icon}: SidebarItemProps) => {
  const classes = useStyles()

  return (
    <Route
      path={path}
      children={({match}) => (
        <MenuItem
          component={Link}
          to={path}
          className={`${classes.item} ${match ? classes.activeItem : ''}`}
        >
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Icon color={match ? 'primary' : 'inherit'} />
            <Typography
              color={match ? 'primary' : 'inherit'}
              variant="caption"
              sx={{whiteSpace: 'break-spaces', textAlign: 'center'}}
            >
              {label}
            </Typography>
          </Grid>
        </MenuItem>
      )}
    />
  )
}

const WalletConnectIcon = ({color}: Pick<SvgIconProps, 'color'>) => (
  <Icon color={color} type="walletConnect" />
)

const CardanoTokenIcon = ({color}: Pick<SvgIconProps, 'color'>) => (
  <Icon color={color} type="cardanoTokenColorlessIcon" exactSize={32} />
)

const Sidebar = () => {
  const {t} = useTranslation()
  const classes = useStyles()
  const {authState} = useAuthStore()

  return (
    <Box className={classes.menu}>
      {/* <SidebarItem path={routeTo.dashboard} label={t('Dashboard')} Icon={DashboardIcon} /> */}
      <Box data-test-id="sidebar-menu-portfolio">
        <SidebarItem
          path={routeTo.portfolio.index}
          label={t('Portfolio')}
          Icon={PortfolioIcon}
        />
      </Box>
      <SidebarItem
        path={routeTo.staking.index}
        label={t('Staking')}
        Icon={StakingIcon}
      />
      <SidebarItem
        path={routeTo.activity.index}
        label={t('Activity')}
        Icon={ActivityIcon}
      />
      {config.isDAppDirectoryEnabled && (
        <Box data-test-id="sidebar-menu-dAppDirectory">
          <SidebarItem
            path={routeTo.dAppDirectory}
            label={t('DApps')}
            Icon={ExtensionIcon}
          />
        </Box>
      )}
      {config.isDexHunterEnabled && (
        <SidebarItem
          path={routeTo.dexHunter.index}
          label={t('DexHunter')}
          Icon={CardanoTokenIcon}
        />
      )}
      {config.isWalletConnectEnabled &&
        !(
          authState.status === 'logged_in' &&
          authState.info.loginType === 'metamask'
        ) && (
          <Box>
            <SidebarItem
              path={routeTo.walletConnect.dashboard}
              label={t('WalletConnect (beta)')}
              Icon={WalletConnectIcon}
            />
          </Box>
        )}
      <Box data-test-id="sidebar-menu-settings">
        <SidebarItem
          path={routeTo.settings.index}
          label={t('Settings')}
          Icon={SettingsIcon}
        />
      </Box>
    </Box>
  )
}

export default Sidebar
