import type {SwapAsset} from 'common'

import type {ExchangeAssetsDetails, SwapAssetDetail} from '../domain'
import {formatChangellyTicker} from '../infrastructure/changelly/parsing'

export const getExchangeAssetDetailOrFallback = (
  asset: SwapAsset,
  availableDetails: ExchangeAssetsDetails,
): SwapAssetDetail => {
  if (!availableDetails[asset]) {
    if (asset === 'FLOW') {
      // hotfix: FLOW as of March '23 is missing completely
      // from ExchangeAssetDetails returned by Changelly
      return {
        name: 'flow',
        ticker: 'FLOW',
        fullName: 'Flow',
        protocol: 'FLOW',
        blockchain: 'flow',
        image: 'https://web-api.changelly.com/api/coins/flow.png',
        enabled: false,
        enabledFrom: false,
        enabledTo: false,
        extraIdName: null,
        transactionUrl: 'https://flowdiver.io/tx/%1$s',
        notifications: {},
        contractAddress: undefined,
      }
    }

    return {
      name: asset.toLowerCase(),
      ticker: asset,
      fullName: asset.toLowerCase(),
      protocol: null,
      blockchain: null,
      image: '',
      enabled: false,
      enabledFrom: false,
      enabledTo: false,
      extraIdName: null,
      transactionUrl: '',
      notifications: {},
      contractAddress: undefined,
    }
  }

  // polygon is technically a token on its chain, but we have custom
  // handling for it in the app which clashes with our logic for "proper" tokens
  if (asset === 'MATICPOLYGON') {
    return {
      ...availableDetails[asset],
      contractAddress: undefined,
    } as SwapAssetDetail
  }

  return availableDetails[asset] as SwapAssetDetail
}

export const formatSwapAssetTicker = formatChangellyTicker
