import {accountsInvalidationKeys as cardanoAccountsInvalidationKeys} from '../cardano'
import {getEvmCrossChainAccountInvalidationKeys} from '../evm/public/invalidationKeys'
import {accountsInvalidationKeys as flowAccountsInvalidationKeys} from '../flow'
import {accountsInvalidationKeys as solanaAccountsInvalidationKeys} from '../solana'

export const walletKindAccountInvalidationKeys = {
  cardano: cardanoAccountsInvalidationKeys,
  flow: flowAccountsInvalidationKeys,
  solana: solanaAccountsInvalidationKeys,
  evm: getEvmCrossChainAccountInvalidationKeys(),
}
