import {assert, safeAssertUnreachable} from '@nufi/frontend-common'
import {isBlockchainSubset} from '@nufi/wallet-common'
import {getFlowContractId} from '@nufi/wallet-flow'
import {sortBy} from 'lodash'

import {nftBlockchains} from 'src/blockchainTypes'

import {isEvmBlockchain} from '../../evm'
import type {Nft} from '../../types'

/**
 * Used to sort nfts, so that related nfts from the same collection are displayed next to each other.
 */
export const sortNfts = (nfts: Nft[]): Nft[] => {
  assert(
    nfts.length === 0 ||
      nfts.every((nft) => nft.blockchain === nfts[0]!.blockchain),
    'all nfts must belong to the same blockchain',
  )
  return sortBy(nfts, (nft) => {
    assert(isBlockchainSubset(nft.blockchain, nftBlockchains))
    if (nft.blockchain === 'cardano') {
      return nft.policyId
    } else if (nft.blockchain === 'solana') {
      return nft.ticker
    } else if (nft.blockchain === 'flow') {
      return getFlowContractId(
        nft.contractMetadata.contractAddress,
        nft.contractMetadata.contractName,
      )
    } else if (isEvmBlockchain(nft.blockchain)) {
      return nft.contractMetadata.name
    } else {
      return safeAssertUnreachable(nft.blockchain)
    }
  })
}
