import BigNumber from 'bignumber.js'
import React from 'react'

import {QueryGuard, LoadStakingDataError} from '../../../components'
import {
  useGetAccounts,
  sumRewards,
  sumBalances,
  sumStakedBalances,
} from '../../../wallet/flow'
import {AssetItemLoading} from '../common/Loadings'
import {MyStakingCard} from '../common/myStakingUtils'

export function FlowMyStakingCard() {
  const stakeAccountsQuery = useGetAccounts()
  return (
    <QueryGuard
      {...stakeAccountsQuery}
      LoadingElement={<AssetItemLoading blockchain="flow" />}
      ErrorElement={<LoadStakingDataError blockchain="flow" />}
    >
      {(accountsInfos) => {
        const rewardsBalance = sumRewards(accountsInfos)
        const availableBalance = sumBalances(accountsInfos)
        const stakedBalance = sumStakedBalances(accountsInfos)
        const totalBalance = availableBalance.plus(stakedBalance)
        const stakedRatio = totalBalance.isGreaterThan(0)
          ? stakedBalance.dividedBy(totalBalance)
          : new BigNumber(0)
        const hasAlreadyStaked = accountsInfos.some(
          ({stakingInfo}) => stakingInfo.isStakingCollectionRegistered,
        )
        return (
          <MyStakingCard
            blockchain="flow"
            {...{
              rewardsBalance,
              stakedBalance,
              availableBalance,
              hasAlreadyStaked,
              stakedRatio,
            }}
          />
        )
      }}
    </QueryGuard>
  )
}
