import {initialSchemaVersion} from '@nufi/storage'
import type {SyncCounter} from 'common'
import _ from 'lodash'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type KeyValueData = Record<string, any>

export const masterProfileMigrations = {
  [initialSchemaVersion]: (oldData: KeyValueData): KeyValueData => oldData,
  '0.2.0': (oldData: KeyValueData): KeyValueData => ({
    ...oldData,
    syncCounter: 0 as SyncCounter,
    syncedAt: 0,
  }),
  // Fix previous migration which incorrectly added the properties
  // at the top level instead of individual profiles. The migration
  // has unfortunately been released so it couldn't be fixed directly.
  '0.2.1': (oldData: KeyValueData): KeyValueData => ({
    ..._.omit(oldData, ['syncCounter', 'syncedAt']),
    profiles: oldData.profiles.map((profile: KeyValueData) => ({
      ...profile,
      syncCounter: profile.syncCounter ?? 0,
      syncedAt: profile.syncedAt ?? 0,
    })),
  }),
  '0.3.0': (oldData: KeyValueData): KeyValueData => ({
    ...oldData,
    profiles: oldData.profiles.map((profile: KeyValueData) => ({
      ...profile,
      isWeb3AuthLogin: false,
    })),
  }),
  '0.4.0': (oldData: KeyValueData): KeyValueData => ({
    ...oldData,
    profiles: oldData.profiles.map((profile: KeyValueData) => {
      const newValue = {
        ...profile,
        loginType: profile.isWeb3AuthLogin ? 'web3Auth' : 'password',
      } as Record<string, unknown> & {
        isWeb3AuthLogin?: boolean
        loginType: string
      }
      delete newValue.isWeb3AuthLogin
      return newValue
    }),
  }),
  '0.5.0': (oldData: KeyValueData): KeyValueData => ({
    ...oldData,
    profiles: oldData.profiles.map(
      (profile: KeyValueData) =>
        ({
          ...profile,
          mnemonicStorageType: 'local',
        }) as Record<string, unknown> & {
          mnemonicStorageType: 'local'
        },
    ),
  }),
}

export const mnemonicMigrations = {}

export const profileDataMigrations = {
  [initialSchemaVersion]: (oldData: KeyValueData): KeyValueData => oldData,
  '0.2.0': (oldData: KeyValueData): KeyValueData => {
    type OldSymbol = 'SOL' | 'ADA'

    const symbolToBlockchain: Record<OldSymbol, string> = {
      ADA: 'cardano',
      SOL: 'solana',
    }

    const newAccounts = oldData.accounts.map(
      (oldAccount: {symbol: OldSymbol}) => ({
        ..._.omit(oldAccount, 'symbol'),
        blockchain: symbolToBlockchain[oldAccount.symbol],
      }),
    )

    return {...oldData, accounts: newAccounts}
  },
  '0.3.0': (oldData: KeyValueData): KeyValueData => {
    const newSettings = {
      ...oldData.settings,
      isHwUser: false,
      isMnemonicActivated: true,
    }

    return {...oldData, settings: newSettings}
  },
  '0.4.0': (oldData: KeyValueData): KeyValueData => {
    const newSettings = {...oldData.settings, updatedAt: 0}

    type _ProfileAccount = Record<string, unknown>
    const newAccounts = oldData.accounts.map((oldAccount: _ProfileAccount) => ({
      ...oldAccount,
      updatedAt: 0,
    }))

    return {settings: newSettings, accounts: newAccounts}
  },
  '0.5.0': (oldData: KeyValueData): KeyValueData => ({
    ...oldData,
    settings: {...oldData.settings, areAmountsVisible: true},
  }),
  '0.6.0': (oldData: KeyValueData): KeyValueData => ({
    ...oldData,
    tokens: [],
  }),
  // empty migration for flow Bip44 ledger accounts which would not work in older app versions
  // https://github.com/vacuumlabs/nufi/pull/1399
  '0.6.1': (oldData: KeyValueData): KeyValueData => oldData,
  '0.7.0': (oldData: KeyValueData): KeyValueData => {
    const getChainId = (blockchain: string, network: string) => {
      if (blockchain === 'ethereum') {
        return {
          homestead: 1,
          goerli: 5,
        }[network]
      }
      if (blockchain === 'milkomedaC1') {
        return {
          mainnet: 2001,
          devnet: 200101,
        }[network]
      }
      if (blockchain === 'polygon') {
        return {
          mainnet: 137,
          mumbai: 80001,
        }[network]
      }
      // This should not really happen but we rather handle this gracefully.
      return null
    }

    const oldTokens: ({
      network: string
      blockchain: string
    } & Record<string, unknown>)[] = oldData.tokens

    const tokens = oldTokens
      .map((t) => {
        const {network, blockchain, ...rest} = t
        const chainId = getChainId(blockchain, network)
        return chainId ? {...rest, blockchain, chainId} : null
      })
      // This should not be needed but we rather handle this gracefully. In worst case
      // user loses an imported token that is corrupted anyways.
      .filter((t) => t != null)

    return {...oldData, tokens}
  },
  // harden Solana account paths
  '0.8.0': (oldData: KeyValueData): KeyValueData => {
    type _ProfileAccount = {blockchain: string; derivationPath: number[]}
    const newAccounts = oldData.accounts.map((oldAccount: _ProfileAccount) => {
      if (oldAccount.blockchain !== 'solana') {
        return oldAccount
      }

      const _HARDENED_THRESHOLD = 0x80000000
      return {
        ...oldAccount,
        derivationPath: oldAccount.derivationPath.map((p) => {
          if (p < _HARDENED_THRESHOLD) {
            return p + _HARDENED_THRESHOLD
          }
          return p
        }),
      }
    })

    return {...oldData, accounts: newAccounts}
  },
  // Migrate evm accounts so that we store them as a single account
  // per public key and crypto provider type. First we split accounts
  // into evm and non-evm accounts. Evm accounts get grouped by public
  // key and crypto provider type. Accounts under the same key will be
  // merged into a single evm account. Non-evm accounts need to be migrated
  // as well because of the `type` prop.
  '0.9.0': (oldData: KeyValueData): KeyValueData => {
    const evmBlockchains = ['ethereum', 'milkomedaC1', 'polygon', 'optimism']

    const [evmAccounts, nonEvmAccounts] = _.partition(oldData.accounts, (a) =>
      evmBlockchains.includes(a.blockchain),
    )

    const groupedEvmAccounts = evmAccounts.reduce((acc, a) => {
      const accountKey = `${a.publicKeyHex}-${a.cryptoProviderType}`
      if (acc[accountKey] == null) {
        acc[accountKey] = []
      }
      acc[accountKey].push(a)
      return acc
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    }, {}) as Record<string, any[]>

    const migratedEvmAccounts = Object.values(groupedEvmAccounts).map(
      (accounts) => {
        const [firstAccount] = accounts
        return {
          type: 'evm',
          cryptoProviderType: firstAccount.cryptoProviderType,
          // EVM account `accountName` will not be used so we leave it empty. If ever becomes used
          // we will probably need to create another migration to name the accounts properly.
          accountName: '',
          derivationType: firstAccount.derivationType,
          derivationPath: firstAccount.derivationPath,
          publicKeyHex: firstAccount.publicKeyHex,
          updatedAt: Math.max(...accounts.map((a) => a.updatedAt)),
          isEnabledForBlockchains: accounts
            .filter((a) => a.deletedAt == null)
            .map((a) => a.blockchain),
          isDefaultForBlockchains: accounts
            .filter((a) => a.isDefault)
            .map((a) => a.blockchain),
          legacyAccountNames: accounts.reduce((acc, a) => {
            acc[a.blockchain] = a.accountName
            return acc
          }, {}),
        }
      },
    )

    const migratedNonEvmAccounts = nonEvmAccounts.map((a) => ({
      ...a,
      type: 'standard',
    }))

    return {
      ...oldData,
      accounts: [...migratedNonEvmAccounts, ...migratedEvmAccounts],
    }
  },
  '0.10.0': (oldData: KeyValueData): KeyValueData => ({
    ...oldData,
    settings: {
      ...oldData.settings,
      widgetMeta: {
        hasUsedWidgetBefore: false,
      },
    },
  }),
  '0.11.0': (oldData: KeyValueData): KeyValueData => {
    type _ProfileAccount = {deletedAt?: number} & (
      | {type: 'standard'; blockchain: string}
      | {type: 'evm'; isEnabledForBlockchains: string[]}
    )

    const enabledBlockchains = _.uniq(
      oldData.accounts
        .filter((a: _ProfileAccount) => a.deletedAt == null)
        .map((a: _ProfileAccount) =>
          a.type === 'standard' ? a.blockchain : a.isEnabledForBlockchains,
        )
        .flat(),
    )

    const newSettings = {
      ...oldData.settings,
      enabledBlockchains,
    }

    return {
      ...oldData,
      settings: newSettings,
    }
  },
  // Migrate EVM accounts. Remove `isEnabledForBlockchains` field,
  // rename `isDefaultForBlockchains` to `defaultForBlockchains`
  // and generate account names for EVM grouped accounts.
  '0.12.0': (oldData: KeyValueData): KeyValueData => {
    const [evmAccounts, nonEvmAccounts] = _.partition(
      oldData.accounts,
      (a) => a.type === 'evm',
    )

    const generateName = (
      existingAccounts: KeyValueData[],
      accountData: KeyValueData,
    ) => {
      const defaultNameTemplate = (
        ordinal: number,
        accountData: KeyValueData,
      ) => {
        const getDefaultAccountNameSuffix = (accountData: KeyValueData) => {
          switch (accountData.derivationType) {
            case 'Bip44Address':
              return ''
            case 'Bip44Account':
              return ' (Ledger Live)'
            case 'Legacy':
              return ' (Legacy)'
            case 'TestnetBip44Address':
              return ' (Testnet)'
            default:
              return ''
          }
        }

        const cryptoProviderType = accountData.cryptoProviderType
        const type = ['ledger', 'trezor'].includes(cryptoProviderType)
          ? `_${cryptoProviderType}`
          : ''

        return `EVM${type}_${ordinal}${getDefaultAccountNameSuffix(accountData)}`
      }

      let ordinal = 1
      while (
        existingAccounts.some(
          (a) => a.accountName === defaultNameTemplate(ordinal, accountData),
        )
      ) {
        ordinal++
      }

      return defaultNameTemplate(ordinal, accountData)
    }

    const migratedEvmAccounts = evmAccounts
      .map((evmAccount) => ({
        ...evmAccount,
        defaultForBlockchains: [...evmAccount.isDefaultForBlockchains],
      }))
      .map(
        (evmAccount) =>
          _.omit(
            evmAccount,
            'isDefaultForBlockchains',
            'isEnabledForBlockchains',
          ) as KeyValueData,
      )
      .reduce<KeyValueData[]>((renamedAccounts, account) => {
        renamedAccounts.push({
          ...account,
          accountName: generateName(renamedAccounts, account),
        } as KeyValueData)
        return renamedAccounts
      }, [])

    return {
      ...oldData,
      accounts: [...nonEvmAccounts, ...migratedEvmAccounts],
    }
  },
  '0.13.0': (oldData: KeyValueData): KeyValueData => {
    const migratedData: KeyValueData = {
      ...oldData,
      tokenImports: oldData.tokens,
      tokenVisibilities: [],
    }
    delete migratedData.tokens
    return migratedData
  },
}
