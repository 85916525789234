import type {ConnectorPlatform} from '@nufi/dapp-client-core'

import icon from /* webpackImportAllow: 'injectionPayload' */ '../../assets/favicon.svg'
import fclDiscoveryIcon from /* webpackImportAllow: 'injectionPayload' */ '../../assets/icons/fcl-discovery.svg'

import type {ConnectorKind} from './types'

type SpecificDappConnectorConfig<K extends ConnectorKind> = {
  cardano: {
    isCip62Enabled: boolean
    isCip95Enabled: boolean
  }
  solana: {
    clusterName: string | undefined
  }
  flow: {
    walletAddress: string
  }
  evm: {
    evmprovidersId: string
  }
}[K]

/* The static configuration is determined at compile time, and must not change
 * afterwards.  */
export type DappConnectorsConfig = {
  appId: string
  connectorPlatform: ConnectorPlatform
  name: string
  description: string
  icons: {
    default: string
    fclDiscovery: string
  }
  website: string
  connectors: {[K in ConnectorKind]: SpecificDappConnectorConfig<K>}
}

/* Injected using webpack's DefinePlugin. */
declare const __DAPP_CONNECTORS_CONFIG__: Omit<DappConnectorsConfig, 'icons'>
export const dappConnectorsConfig = {
  // __DAPP_CONNECTORS_CONFIG__ is injected during the build time by webpack
  // In scenarios like Storybook, we intentionally ignore this configuration
  // as it's challenging for effective mocking.
  ...((typeof __DAPP_CONNECTORS_CONFIG__ === 'undefined'
    ? {}
    : __DAPP_CONNECTORS_CONFIG__) as Omit<DappConnectorsConfig, 'icons'>),
  icons: {
    default: icon,
    fclDiscovery: fclDiscoveryIcon,
  },
}
