import {Sentry} from '@nufi/frontend-common'
import {MutationCache, QueryCache, QueryClient} from '@tanstack/react-query'

import {isExpectedError} from './utils/expectedErrors'

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error) => {
      if (!isExpectedError(error)) {
        Sentry.captureException(error)
      }
    },
    onSettled: (data, err, q) => {
      // onSettled for individual queries removed in react-query v5
      // this is a workaround to compensate for that
      if (q.meta?.onSettled && typeof q.meta.onSettled === 'function') {
        q.meta.onSettled()
      }
    },
  }),
  mutationCache: new MutationCache({
    onError: (error) => {
      if (!isExpectedError(error)) {
        Sentry.captureException(error)
      }
    },
  }),
  defaultOptions: {
    queries: {
      // This causes a lot of loadings each time when navigating to a different part of app,
      // which is quite a bad UX
      retryOnMount: false,
      // We avoid refetching failed queries, as RQ has currently a bit weird handling
      // of error states (reset to `error vs null` multiple times), which is possibly
      // a bug and we rather avoid it.
      // Also we have retries in place and when something is truly down, there is
      // not much chance it will refetch.
      // Finally user can always initiate refetch on its own.
      refetchOnWindowFocus: (q) => q.isStale() && q.state.status === 'success',
    },
  },
})

export default queryClient
