import type {
  SolanaAllAvailableAccountsResponse,
  SolanaAccountInfo,
} from '@nufi/wallet-solana'

import type {AllAvailableAccountsResponse, Blockchain} from '../../../types'
import {solana} from '../../solanaManagers'

export const QUERY_KEY_PREFIX: Blockchain = 'solana'

export const getAccountInfoForDiscoveryResponse = async (
  response: SolanaAllAvailableAccountsResponse,
): Promise<AllAvailableAccountsResponse<SolanaAccountInfo>> => {
  if ('canNotAddAccountReason' in response) return response
  return {
    accounts: await solana.getAccountInfos(response.accounts),
    otherAccounts: await solana.getAccountInfos(response.otherAccounts),
  }
}
