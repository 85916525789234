import type {CoreTypes} from '@walletconnect/types'
import type {SdkErrorKey} from '@walletconnect/utils'

import {useDappConnectorStore} from '../../store/dappConnector'
import type {AccountInfo} from '../../types'
import type {WalletConnectBlockchain} from '../types'

export const isStringSubset = <T extends ReadonlyArray<string>>(
  item: unknown,
  subset: T,
): item is T[number] => subset.includes(item as T[number])

export function confirmConnection(
  blockchain: WalletConnectBlockchain,
  originInfo: {
    origin: string
    favIconUrl?: string
  },
): Promise<AccountInfo> {
  return new Promise((resolve, reject) => {
    const {setInitScreen} = useDappConnectorStore.getState()

    setInitScreen({
      blockchain,
      origin: originInfo.origin,
      favIconUrl: originInfo.favIconUrl || null,
      onFinish: (accountInfo) => {
        return resolve(accountInfo)
      },
      onFailure: () =>
        reject(new WalletConnectSessionProposalError('USER_REJECTED')),
    })
  })
}

type MessageForWebWallet = {
  type: 'walletConnectSessionApproved'
  data: CoreTypes.Metadata
}

export function sendMessageToWebWallet(msg: MessageForWebWallet) {
  if (window.opener) window.opener.postMessage(msg)
}

export class WalletConnectSessionProposalError extends Error {
  name = 'WalletConnectSessionProposalError'

  constructor(
    public key: SdkErrorKey,
    public cause?: unknown,
  ) {
    super(key)
  }
}
