import React, {useContext, useState} from 'react'

import type {TokenId, AccountId} from '../../wallet'

type NftsSelectionState = {
  targetAccount: AccountId | null
  selectedNfts: TokenId[]
}

type NftsSelectionContextType = {
  targetAccount: AccountId | null
  selectedNfts: TokenId[]
  resetSelection: () => void
  initializeSelection: (accountId: AccountId, tokenId: TokenId | null) => void
  toggleSelectNft: (tokenId: TokenId) => void
}

const NftSelectionContext = React.createContext({} as NftsSelectionContextType)

type NftSelectionStateProviderProps = {children: React.ReactNode}

export function NftSelectionStateProvider({
  children,
}: NftSelectionStateProviderProps) {
  const [nftSelectionState, setNftSelectionState] =
    useState<NftsSelectionState>({
      targetAccount: null,
      selectedNfts: [],
    })
  const {selectedNfts, targetAccount} = nftSelectionState

  const toggleSelectNft = (tokenId: TokenId) => {
    if (!selectedNfts.includes(tokenId)) {
      setNftSelectionState((s) => ({
        ...s,
        selectedNfts: [...selectedNfts, tokenId],
      }))
    } else {
      setNftSelectionState((s) => ({
        ...s,
        selectedNfts: selectedNfts.filter((id) => id !== tokenId),
      }))
    }
  }

  const resetSelection = () => {
    setNftSelectionState({targetAccount: null, selectedNfts: []})
  }

  const initializeSelection = (
    accountId: AccountId,
    tokenId: TokenId | null,
  ) => {
    setNftSelectionState({
      targetAccount: accountId,
      selectedNfts: tokenId ? [tokenId] : [],
    })
  }

  return (
    <NftSelectionContext.Provider
      value={{
        selectedNfts,
        targetAccount,
        resetSelection,
        initializeSelection,
        toggleSelectNft,
      }}
    >
      {children}
    </NftSelectionContext.Provider>
  )
}

export const useNftSelectionState = () => useContext(NftSelectionContext)
