import {VisibilityOff} from '@mui/icons-material'
import {Grid, Box, Typography} from '@mui/material'
import BigNumber from 'bignumber.js'
import React from 'react'
import ContentLoader from 'react-content-loader'
import {useTranslation} from 'react-i18next'
import {useParams, useHistory} from 'react-router-dom'

import type {NftBlockchain} from '../../blockchainTypes'
import {
  QueryGuard,
  Breadcrumbs,
  BreadcrumbLabel,
  LoadAccountError,
  AccountSelectField,
} from '../../components'
import {SIDEBAR_WIDTH} from '../../constants'
import {routeTo} from '../../router'
import {
  useScrollableStyles,
  useResponsiveDimensions,
  ContentLoaderBackground,
} from '../../utils/layoutUtils'
import type {
  AccountId,
  AccountInfo,
  AccountInfoWithNftSnapshot,
  NftPage,
} from '../../wallet'
import {useAccountsWithNftSnapshot} from '../../wallet'
import {ensureAccountById} from '../../wallet/utils/common'

import {HiddenNftsBanner} from './hiddenNfts'
import type {NftVisibility} from './hiddenNfts'
import {
  NftList,
  NftsSelection,
  HeaderDivider,
  FullWidthCardLoaders,
} from './layoutUtils'

function NFTAccountDetailLoading() {
  const dimensions = useResponsiveDimensions()
  const maxWidth = dimensions.width - SIDEBAR_WIDTH
  return (
    <ContentLoader
      viewBox={`0 0 ${maxWidth} 400`}
      backgroundColor={ContentLoaderBackground()}
    >
      <rect x="0" y="30" rx="15" width="350" height="30" />
      <rect x={maxWidth - 200} y="40" rx="10" width="150" height="20" />
      <FullWidthCardLoaders />
    </ContentLoader>
  )
}

type FormattedNftsCountHintProps = {
  accountNftSnapshot: NftPage
}
function FormattedNftsCountHint({
  accountNftSnapshot,
}: FormattedNftsCountHintProps) {
  const {t} = useTranslation()
  if (accountNftSnapshot.hasNextPage) {
    return <>{t('nft_count_plus', {amount: accountNftSnapshot.items.length})}</>
  }

  return <>{t('nft_count', {amount: accountNftSnapshot.items.length})}</>
}

type HeaderProps = {
  blockchain: NftBlockchain
  onBack: () => unknown
  account: AccountInfo
  accounts: AccountInfoWithNftSnapshot[]
  nftVisibility: NftVisibility
}

function Header({
  onBack,
  blockchain,
  account,
  accounts,
  nftVisibility,
}: HeaderProps) {
  const {t} = useTranslation()
  const history = useHistory()

  const onChange = (selectedAccountId: AccountId) => {
    const routeBase = routeTo.portfolio.nfts
      .blockchain(blockchain)
      .account(selectedAccountId)
    history.replace(
      nftVisibility === 'visible' ? routeBase.index : routeBase.hiddenNFTs,
    )
  }

  const accountItems = accounts.map((a) => ({
    accountId: a.id,
    address: a.address,
    balance: new BigNumber(0),
    name: a.name,
    extra: a.nftSnapshot ? (
      <Box ml={4}>
        <Typography variant="body2" color="textPrimary">
          <FormattedNftsCountHint accountNftSnapshot={a.nftSnapshot} />
        </Typography>
      </Box>
    ) : undefined,
    cryptoProviderType: a.cryptoProviderType,
  }))

  return (
    <Grid container justifyContent="space-between">
      <Breadcrumbs
        items={[
          <BreadcrumbLabel key={0} onClick={onBack} label={t('NFT Gallery')} />,
          <Box key={1} ml={0.5} minWidth={280}>
            <AccountSelectField
              value={account.id}
              onChange={(e) => onChange(e.target.value as AccountId)}
              items={accountItems}
              blockchain={blockchain}
              hideAmount
              variant="standard"
              hideCopyAddress
              nameToAmountRatio="2:1"
              showBlockchainIcon
            />
          </Box>,
          ...(nftVisibility === 'hidden'
            ? [
                <Box display="flex" pl={0.5}>
                  <VisibilityOff color="warning" />
                  <Typography pl={1}>{t('Hidden NFTs')}</Typography>
                </Box>,
              ]
            : []),
        ]}
      />
      <NftsSelection
        accountId={account.id}
        blockchain={blockchain}
        nftVisibility={nftVisibility}
      />
    </Grid>
  )
}

export function NftPerAccountList({
  nftVisibility,
}: {
  nftVisibility: NftVisibility
}) {
  const {accountId, blockchain} = useParams<{
    accountId: AccountId
    blockchain: NftBlockchain
  }>()
  const history = useHistory()

  const accountsQuery = useAccountsWithNftSnapshot({
    blockchain,
    visibility: nftVisibility === 'visible' ? 'visible' : 'hidden',
  })
  const {scrollableList, scrollableParent} = useScrollableStyles()

  const onBack = () => {
    history.push(routeTo.portfolio.nfts.blockchain(blockchain).index)
  }

  return (
    <QueryGuard
      {...accountsQuery}
      LoadingElement={<NFTAccountDetailLoading />}
      ErrorElement={<LoadAccountError />}
    >
      {(accounts) => {
        const account = ensureAccountById(
          accounts as AccountInfoWithNftSnapshot[],
          accountId,
        )
        return (
          <Box className={scrollableParent}>
            <Header
              onBack={onBack}
              account={account}
              accounts={accounts}
              blockchain={blockchain}
              nftVisibility={nftVisibility}
            />
            <HeaderDivider />
            <Box className={scrollableList}>
              {nftVisibility === 'hidden' && <HiddenNftsBanner />}
              <NftList
                key={account.id}
                account={account}
                blockchain={blockchain}
                nftVisibility={nftVisibility}
              />
            </Box>
          </Box>
        )
      }}
    </QueryGuard>
  )
}
