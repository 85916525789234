import {isBlockchainEnabled} from 'src/features/profile/application'
import queryClient from 'src/queryClient'

import type {
  AccountStoredData,
  __UniversalAccountsStoredData,
} from '../../store/wallet'
import {getAccountStore} from '../../store/wallet'
import {assert} from '../../utils/assertion'
import {getVisibleAccounts} from '../AccountsStoreManager'
import {evm} from '../evm/commonEvmManagers'
import type {EvmAccountDerivationParams} from '../evm/cryptoProviders/types'
import {isEvmBlockchain} from '../evm/utils'
import type {
  AccountInfo,
  AccountNetworkInfo,
  AccountOfflineInfo,
  Blockchain,
  HwVendor,
} from '../types'
import {sortAccounts} from '../utils/common'
import {getWalletManagers} from '../utils/walletManagerUtils'

const getAccountsFn = async <TAccountInfo extends AccountInfo>(
  blockchain: Blockchain,
): Promise<TAccountInfo[]> => {
  // Rather than disabling the `useGetAccounts` query we return empty result
  // from this function if the blockchain is disabled. This is because this
  // function can also get called imperatively from `cachedGetAccounts` and thus
  // the imperative call could return different results than the query. This way
  // we make sure the results are consistent.
  if (!isBlockchainEnabled(blockchain)) {
    return []
  }

  const rawAccounts = getAccountStore(blockchain).getState().accounts || []

  const sortedAccounts = sortAccounts<AccountStoredData>(rawAccounts)

  const visibleAccounts = getVisibleAccounts(sortedAccounts).filter((a) => {
    if (isEvmBlockchain(blockchain)) {
      const supportedDerivationPathTypes =
        evm.wallet.getSupportedDerivationPathTypes(a.cryptoProviderType)
      return supportedDerivationPathTypes.includes(
        (a.derivationParams as EvmAccountDerivationParams).type,
      )
    }

    return true
  })

  const offlineAccountsInfo = getWalletManagers(
    blockchain,
  ).getAccountOfflineInfos(
    visibleAccounts as __UniversalAccountsStoredData,
  ) as AccountOfflineInfo[]

  const accountsNetworkInfo = (await getWalletManagers(
    blockchain,
  ).getAccountNetworkInfos(
    visibleAccounts as __UniversalAccountsStoredData,
  )) as AccountNetworkInfo[]

  return visibleAccounts.map((oI) => {
    const offlineInfo = offlineAccountsInfo.find((a) => a.id === oI.id)
    const networkInfo = accountsNetworkInfo.find((a) => a.id === oI.id)
    assert(!!offlineInfo && !!networkInfo)
    return {...oI, ...networkInfo, ...offlineInfo}
  }) as TAccountInfo[]
}

export const getAccounts = {
  fn: getAccountsFn,
  __key: (blockchain: Blockchain): string[] => [blockchain, 'accounts'],
}

export const discoverHwAccounts = {
  __key: (blockchain: Blockchain, hwVendor: HwVendor): string[] => [
    blockchain,
    'discoverHwAccounts',
    hwVendor,
  ],
}

export function clearDiscoverHwAccountsCache(
  blockchain: Blockchain,
  hwVendor: HwVendor,
): void {
  queryClient.removeQueries({
    queryKey: discoverHwAccounts.__key(blockchain, hwVendor),
  })
}
