import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  Info,
} from '@mui/icons-material'
import {Box} from '@mui/material'
import {assert} from '@nufi/frontend-common'
import {
  assertBlockchainIsInSubset,
  isBlockchainSubset,
} from '@nufi/wallet-common'
import {isEvmBlockchain} from '@nufi/wallet-evm'
import _ from 'lodash'
import React, {useContext, useMemo, useRef, useState} from 'react'
import type {CSSProperties} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import AutoSizer from 'react-virtualized-auto-sizer'
import {FixedSizeList as VirtualizedList} from 'react-window'

import {
  csvExportBlockchains,
  explorerLinkBlockchains,
  tokenBlockchains,
  txHistoryBlockchains,
} from 'src/blockchainTypes'
import {
  AssetIcon,
  FormattedAsset,
  SplitButton,
  TextButton,
  getAccountExplorerLink,
  FormattedAssetAsCurrency,
} from 'src/components'
import {
  isAssetVisibleWhenHidingZeroBalances,
  sortAssetsByValue,
} from 'src/features/assets/domain/accountAssets'
import type {AssetAmount} from 'src/features/assets/types'
import type {ConversionRates} from 'src/features/conversionRates/domain'
import {WithConversionRates} from 'src/features/conversionRates/ui'
import {useSettings} from 'src/features/profile/application'
import {routeTo} from 'src/router'
import {BlockchainSubsetGuard} from 'src/utils/blockchainGuards'
import {useGetBlockchainName} from 'src/utils/translations'
import {isNft} from 'src/wallet/public/utils'
import type {
  AccountId,
  AccountInfo,
  Blockchain,
  TokenMetadata,
  WalletKind,
} from 'src/wallet/types'

import {ReferenceElementContext as AccountListContainerRef} from '../../../utils'
import {usePortfolioPageContext} from '../../PortfolioContext'
import AccountDetails from '../AccountDetails'
import {ASSET_ROW_HEIGHT, AssetRow} from '../AccountListUtils'
import {ExportHistoryModal} from '../actions/exportHistory/ExportHistoryModal'
import ForgetAccount from '../actions/ForgetAccount'
import ImportToken from '../actions/importToken/ImportToken'
import RenameAccount from '../actions/RenameAccount'

export const useAccountListCardCommons = ({
  tokensMetadata,
}: {
  tokensMetadata: TokenMetadata[]
}) => {
  const {hideZeroBalances, hideNfts, blockchain} = usePortfolioPageContext()
  const [action, setAction] = useState<ManageAccountAction | null>(null)
  const onCloseAction = () => setAction(null)
  const onOpenAction = (action: ManageAccountAction) => () => setAction(action)

  const actionRef = useRef<HTMLDivElement>(null)
  const [expanded, setExpanded] = useState(false)
  const onCardClick = (
    event: React.ChangeEvent<unknown>,
    isExpanded: boolean,
  ) => {
    !actionRef?.current?.contains(event.target as Node) &&
      setExpanded(isExpanded)
  }
  const getBlockchainName = useGetBlockchainName()

  const tokensMetadataMap = useMemo(
    () => _.keyBy(tokensMetadata, ({id}) => id),
    [tokensMetadata],
  )

  return {
    hideZeroBalances,
    hideNfts,
    portfolioBlockchain: blockchain,
    action,
    onCloseAction,
    onOpenAction,
    actionRef,
    expanded,
    setExpanded,
    onCardClick,
    getBlockchainName,
    tokensMetadataMap,
  }
}

type UseVirtualizedAssetListDataArgs = {
  assets: AssetAmount[]
  accountId: AccountId
  hideZeroBalances: boolean
  hideNfts: boolean
  tokensMetadataMap: Record<string, TokenMetadata>
  conversionRates: ConversionRates | undefined
  blockchain: Blockchain | null
}

export const useVirtualizedAssetListData = ({
  assets,
  accountId,
  hideZeroBalances,
  hideNfts,
  tokensMetadataMap,
  conversionRates,
  blockchain,
}: UseVirtualizedAssetListDataArgs) => {
  const {currency} = useSettings()

  return useMemo(() => {
    const filteredAssets = assets
      .filter((assetAmount) => {
        if (!hideZeroBalances) {
          return true
        }

        return isAssetVisibleWhenHidingZeroBalances({
          // For EVM blockchains we want to hide even native assets with zero balance.
          isAlwaysVisible: !isEvmBlockchain(assetAmount.blockchain)
            ? assetAmount.type === 'native'
            : false,
          balance: assetAmount.amount,
        })
      })
      .filter((assetAmount) => {
        if (!hideNfts) {
          return true
        }

        if (assetAmount.type === 'token' && assetAmount.tokenMetadata != null) {
          return !isNft(assetAmount.tokenMetadata)
        }

        return true
      })
      .filter(
        (assetAmount) =>
          blockchain == null || assetAmount.blockchain === blockchain,
      )

    return {
      assets: sortAssetsByValue(filteredAssets, conversionRates, currency),
      blockchain,
      accountId,
    }
  }, [
    assets,
    accountId,
    hideZeroBalances,
    tokensMetadataMap,
    currency,
    conversionRates,
    blockchain,
  ])
}

type ExpansionButtonProps = {
  expanded: boolean
  assetsCount: number
}

export const ExpansionButton = ({
  expanded,
  assetsCount,
}: ExpansionButtonProps) => {
  const {t} = useTranslation()
  const expansionLabel = `${
    expanded ? t('Hide assets') : t('Show assets')
  } (${assetsCount})`

  return (
    <TextButton
      color="textSecondary"
      fontWeight="medium"
      onClick={() => null}
      label={expansionLabel}
      Icon={
        expanded ? (
          <ExpandLessIcon color="inherit" />
        ) : (
          <ExpandMoreIcon color="inherit" />
        )
      }
    />
  )
}

type VirtualizedTokenListProps = {
  virtualizedAssetListData: {
    assets: AssetAmount[]
    blockchain: Blockchain | null
    accountId: AccountId
  }
}

export const VirtualizedAssetList = ({
  virtualizedAssetListData,
}: VirtualizedTokenListProps) => {
  const MAX_TOKEN_LIST_HEIGHT = 400

  const assetsLength = virtualizedAssetListData.assets.length

  return (
    <div
      key={assetsLength}
      style={{
        height: Math.min(
          MAX_TOKEN_LIST_HEIGHT,
          assetsLength * ASSET_ROW_HEIGHT,
        ),
        width: '100%',
      }}
    >
      <AutoSizer>
        {({width, height}) => (
          <VirtualizedList
            itemData={virtualizedAssetListData}
            itemCount={assetsLength}
            itemSize={ASSET_ROW_HEIGHT}
            {...{width, height}}
          >
            {VirtualizedAssetRow}
          </VirtualizedList>
        )}
      </AutoSizer>
    </div>
  )
}

type VirtualizedAssetRowProps = {
  index: number
  style: CSSProperties
  data: {
    assets: AssetAmount[]
    blockchain: Blockchain | null
    accountId: AccountId
  }
}

export const VirtualizedAssetRow = React.memo(
  ({index, style, data}: VirtualizedAssetRowProps) => {
    const {assets, blockchain, accountId: id} = data
    const assetAmount = assets[index]!
    const getBlockchainName = useGetBlockchainName()
    const {tokenMetadata, amount: balance} = assetAmount
    const _blockchain = blockchain || assetAmount.blockchain

    const rowKey =
      assetAmount.type === 'native' ? _blockchain : assetAmount.tokenMetadata.id

    return (
      <div style={style}>
        <AssetRow
          key={rowKey}
          {...{id, balance, blockchain: _blockchain}}
          balanceCol={
            <FormattedAsset
              amount={balance}
              blockchain={_blockchain}
              tokenMetadata={tokenMetadata}
              includeAssetSymbol={false}
              isSensitiveInformation
            />
          }
          priceCol={
            <WithConversionRates>
              {(conversionRates) => (
                <FormattedAssetAsCurrency
                  blockchain={_blockchain}
                  balance={balance}
                  includeCurrencySymbol
                  isSensitiveInformation
                  tokenMetadata={tokenMetadata}
                  conversionRates={conversionRates}
                />
              )}
            </WithConversionRates>
          }
          description={getBlockchainName(_blockchain)}
          Icon={
            <AssetIcon
              blockchain={_blockchain}
              tokenMetadata={assetAmount.tokenMetadata}
              showBlockchainBadge
            />
          }
          tokenMetadata={tokenMetadata}
          disableBottomBorder={index === assets.length - 1}
        />
      </div>
    )
  },
)

export type ManageAccountAction =
  | 'rename'
  | 'show-details'
  | 'forget'
  | 'export'
  | 'import-token'

type ActionsColProps = {
  blockchain: Blockchain | null
  accountInfo: AccountInfo
  actionRef: React.RefObject<HTMLDivElement>
  onOpenAction: (action: ManageAccountAction) => () => void
}

export const ActionsCol = ({
  blockchain,
  accountInfo,
  actionRef,
  onOpenAction,
}: ActionsColProps) => {
  const {t} = useTranslation()
  const history = useHistory()

  const accountsContainerRef = useContext(AccountListContainerRef).current

  const onShowExplorerLink = () => {
    assert(blockchain != null)
    assertBlockchainIsInSubset(blockchain, explorerLinkBlockchains)
    window.open(
      getAccountExplorerLink(accountInfo.address, blockchain),
      '_blank',
    )
  }

  const onOpenAccountHistory = () => {
    assert(blockchain != null)
    history.push(
      routeTo.portfolio.assets
        .blockchain(blockchain)
        .detail.account(accountInfo.id)
        .tab('history').index,
    )
  }

  const tooltipTitle =
    blockchain == null ? t('Please choose a blockchain first.') : undefined

  return (
    <Box ref={actionRef}>
      <SplitButton
        label={t('Manage account')}
        popperBoundary={accountsContainerRef}
        options={[
          {
            label: t('Account details'),
            onClick: onOpenAction('show-details'),
          },
          {
            label: t('Rename account'),
            onClick: onOpenAction('rename'),
          },
          {
            label: t('Export History'),
            onClick: onOpenAction('export'),
            disabled: !isBlockchainSubset(blockchain, csvExportBlockchains),
            tooltipTitle: isEvmBlockchain(accountInfo.blockchain)
              ? t('Export history is not available for EVM blockchains.')
              : undefined,
          },
          {
            label: t('Show account history'),
            onClick: onOpenAccountHistory,
            disabled: !isBlockchainSubset(blockchain, txHistoryBlockchains),
            tooltipTitle,
          },
          {
            label:
              blockchain === 'ethereum'
                ? t('Import tokens')
                : t('Import token'),
            onClick: onOpenAction('import-token'),
            disabled:
              !isBlockchainSubset(blockchain, tokenBlockchains) &&
              !isEvmBlockchain(accountInfo.blockchain),
          },
          {
            label: t('Show account on explorer'),
            onClick: onShowExplorerLink,
            disabled: !isBlockchainSubset(blockchain, explorerLinkBlockchains),
            tooltipTitle,
          },
          {
            label: t('Forget account'),
            onClick: onOpenAction('forget'),
          },
        ].map((option) => ({
          ...option,
          EndIcon: option.disabled ? Info : undefined,
        }))}
      />
    </Box>
  )
}

type ActionsProps = {
  action: ManageAccountAction | null
  walletKind: WalletKind
  blockchain: Blockchain | null
  accountInfo: AccountInfo
  extendedAccountInfo?: {label: string; value: string; link?: JSX.Element}[]
  onCloseAction: () => void
}

export const Actions = ({
  action,
  walletKind,
  blockchain,
  accountInfo,
  extendedAccountInfo,
  onCloseAction,
}: ActionsProps) => {
  const {id, name} = accountInfo
  return (
    <>
      {action === 'rename' && (
        <RenameAccount {...{walletKind, id, name}} onClose={onCloseAction} />
      )}
      {action === 'show-details' && (
        <AccountDetails
          accountInfo={accountInfo}
          onClose={onCloseAction}
          extendedAccountInfo={extendedAccountInfo}
        />
      )}
      {action === 'forget' && (
        <ForgetAccount accountInfo={accountInfo} onClose={onCloseAction} />
      )}
      {action === 'export' && (
        <BlockchainSubsetGuard
          blockchain={blockchain}
          blockchainSubset={csvExportBlockchains}
        >
          {(csvExportBlockchain) => (
            <ExportHistoryModal
              id={id}
              blockchain={csvExportBlockchain}
              onClose={onCloseAction}
            />
          )}
        </BlockchainSubsetGuard>
      )}
      {action === 'import-token' && (
        <BlockchainSubsetGuard
          blockchain={blockchain || accountInfo.blockchain}
          blockchainSubset={tokenBlockchains}
        >
          {(tokenBlockchain) => (
            <ImportToken
              id={id}
              blockchain={tokenBlockchain}
              onClose={onCloseAction}
            />
          )}
        </BlockchainSubsetGuard>
      )}
    </>
  )
}
