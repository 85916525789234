import type {Theme} from '@mui/material'
import {Paper} from '@mui/material'
import type {PaperProps as MuiPaperProps} from '@mui/material/Paper'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import React, {useState} from 'react'

export type ElevatedCardType = 'normal' | 'light'

type CustomElevatedCardProps = {
  type?: ElevatedCardType
  disabled?: boolean
  tableCard?: boolean
}

type ElevatedCardProps = MuiPaperProps & CustomElevatedCardProps

export default function ElevatedCard({
  type,
  disabled,
  tableCard,
  onClick,
  ...rest
}: ElevatedCardProps) {
  const classes = useStyles({type, disabled, isClickable: !!onClick})
  const [hasHover, setHasHover] = useState(false)

  const activeElevation = !disabled ? (type === 'normal' ? 4 : 2) : 0
  const inactiveElevation = !disabled ? (type === 'normal' ? 2 : 0) : 0

  return (
    <Paper
      {...rest}
      onClick={onClick}
      classes={{root: clsx(classes.root, tableCard && classes.tableCardStyles)}}
      elevation={hasHover ? activeElevation : inactiveElevation}
      onMouseEnter={() => setHasHover(true)}
      onMouseLeave={() => setHasHover(false)}
    />
  )
}

const useStyles = makeStyles<
  Theme,
  CustomElevatedCardProps & {isClickable: boolean}
>((theme) => ({
  root: ({disabled, isClickable}) => ({
    opacity: disabled ? 0.5 : 1,
    '&:hover': {
      cursor: isClickable ? (disabled ? 'not-allowed' : 'pointer') : 'auto',
      backgroundColor: isClickable ? theme.palette.action.hover : undefined,
    },
  }),
  tableCardStyles: {
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1, 0),
    boxShadow: theme.shadows[1],
  },
}))
