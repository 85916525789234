import _ from 'lodash'

import {profileManagerLocator} from 'src/appStorage/profileManager/profileManager'
import {useSettingsStore} from 'src/features/profile/application'
import {cloudSyncServiceLocator} from 'src/features/profileSync/application'
import {AccountStorageServiceLocator} from 'src/features/walletStorage/application'
import {invalidateQueryKeys, useMutation} from 'src/utils/mutation-utils'
import {walletKindAccountInvalidationKeys} from 'src/wallet/public/accountInvalidationKeys'
import type {Blockchain, WalletKind} from 'src/wallet/types'
import {getWalletKindWalletManagers} from 'src/wallet/utils/walletManagerUtils'
import {blockchainToWalletKind} from 'src/wallet/walletKind'

import {getProfileDataWithUpdatedEnabledBlockchains} from '../domain'

const getModifiedBlockchains = ({
  oldEnabledBlockchains,
  newEnabledBlockchains,
}: {
  oldEnabledBlockchains: readonly Blockchain[]
  newEnabledBlockchains: readonly Blockchain[]
}) => {
  const newlyEnabledBlockchains = _.difference(
    newEnabledBlockchains,
    oldEnabledBlockchains,
  )
  const newlyDisabledBlockchains = _.difference(
    oldEnabledBlockchains,
    newEnabledBlockchains,
  )

  return {
    newlyEnabledBlockchains,
    newlyDisabledBlockchains,
  }
}

const setEnabledBlockchains = async (enabledBlockchains: Blockchain[]) => {
  // get deps
  const profileManager = profileManagerLocator.instance()
  const getWalletKindAccounts = (walletKind: WalletKind) =>
    getWalletKindWalletManagers(walletKind).accountsStore.getVisibleAccounts()
  const accountStorageService = AccountStorageServiceLocator.instance()

  const profileData = await profileManager.getProfileData()

  const {newlyEnabledBlockchains, newlyDisabledBlockchains} =
    getModifiedBlockchains({
      oldEnabledBlockchains: profileData.settings.enabledBlockchains,
      newEnabledBlockchains: enabledBlockchains,
    })

  const modifiedBlockchains = [
    ...newlyEnabledBlockchains,
    ...newlyDisabledBlockchains,
  ]

  if (modifiedBlockchains.length === 0) {
    return
  }

  const updatedData = getProfileDataWithUpdatedEnabledBlockchains({
    allEnabledBlockchains: enabledBlockchains,
    newlyEnabledBlockchains,
    profileData,
    getWalletKindAccounts,
    getUpdatedAccounts: accountStorageService.getUpdatedAccountsForWalletKinds,
  })

  await profileManager.saveData(updatedData)
  const newProfileData = await profileManager.getProfileData()

  // update settings and accounts in reactive state synchronously
  useSettingsStore.getState().setSettings(newProfileData.settings)
  accountStorageService.setInMemoryAccounts(
    newProfileData.accounts,
    newlyEnabledBlockchains,
  )

  await cloudSyncServiceLocator.instance().syncProfileData()

  _.uniq(modifiedBlockchains.map(blockchainToWalletKind)).forEach(
    (walletKind) =>
      invalidateQueryKeys(walletKindAccountInvalidationKeys[walletKind]),
  )
}

export const useEnableBlockchain = () => {
  return useMutation(['enableBlockchain'], async (blockchain: Blockchain) => {
    const currentEnabledBlockchains = (
      await profileManagerLocator.instance().getProfileData()
    ).settings.enabledBlockchains

    await setEnabledBlockchains(
      _.uniq([...currentEnabledBlockchains, blockchain]),
    )
  })
}

export const useSetEnabledBlockchains = () => {
  return useMutation(['setEnabledBlockchains'], setEnabledBlockchains)
}
