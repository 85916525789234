import {Typography, Grid, Box} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import React from 'react'

import type {WalletKind} from 'src/wallet/walletKind'

import {Icon, IconModalHeader, iconDims} from '../../../components'
import type {Blockchain} from '../../../types'
import {useGetBlockchainOrWalletKindName} from '../../../utils/translations'

const defaultIconSize = iconDims.small.height

type AccountActionModalHeaderProps = {
  onClose: () => unknown
  label: string
} & ({walletKind: WalletKind} | {blockchain: Blockchain})

export function AccountActionModalHeader({
  onClose,
  label,
  ...rest
}: AccountActionModalHeaderProps) {
  const blockchainOrWalletKind = (() => {
    if ('walletKind' in rest) {
      return rest.walletKind
    } else {
      return rest.blockchain
    }
  })()

  const getBlockchainOrWalletKindName = useGetBlockchainOrWalletKindName()
  return (
    <IconModalHeader
      onClose={onClose}
      iconElement={
        <Icon exactSize={defaultIconSize} type={blockchainOrWalletKind} />
      }
    >
      <>
        <Typography variant="body1">{label}</Typography>
        <Typography variant="body2" color="textSecondary">
          {getBlockchainOrWalletKindName(blockchainOrWalletKind)}
        </Typography>
      </>
    </IconModalHeader>
  )
}

type AccountDetailRowProps = {
  label: string
  value: string | React.ReactNode
}

export function AccountDetailRow({label, value}: AccountDetailRowProps) {
  const classes = useAccountDetailStyles()
  return (
    <Grid container direction="column" item>
      <Grid item>
        <Typography variant="caption" color="textSecondary">
          {label}
        </Typography>
      </Grid>
      <Grid item className={classes.valueRow}>
        {typeof value === 'string' ? <Typography>{value}</Typography> : value}
      </Grid>
    </Grid>
  )
}

type AccountDetailListProps = {
  children: React.ReactNode | React.ReactNode[]
  className?: string
}

export function AccountDetailList({
  children,
  className,
}: AccountDetailListProps) {
  const classes = useAccountDetailStyles()
  return <Box className={clsx(classes.list, className)}>{children}</Box>
}

const useAccountDetailStyles = makeStyles((theme) => ({
  valueRow: {
    overflowWrap: 'anywhere',
  },
  list: {
    '& > *': {
      paddingBottom: theme.spacing(1.5),
    },
  },
}))
