import {flowToNanoflow, parseFlowTokenId} from '@nufi/wallet-flow'
import type {
  Nanoflow,
  FlowAddress,
  FlowTokenMetadata,
  FlowTxPlan,
} from '@nufi/wallet-flow'

import {commonParseTokenAmount} from '../../../wallet'
import type {useResolveAddressName} from '../../../wallet/flow'
import {getIsAddressValidImperative} from '../../../wallet/flow'
import type {BaseSendSchema as FormSchema} from '../common/types'
import {ensureNativeAmount, ensureSingleTokenAmount} from '../common/utils'

export const isValidFlowAddressFormat = (address: string) =>
  // we check if the address is a valid string beginning with `0x` and followed
  // by 16 letters long hex string
  /^0x[0-9a-f]{16}$/.test(address)

export const isValidFlowAddress = async (address: string): Promise<boolean> => {
  if (isValidFlowAddressFormat(address)) {
    return getIsAddressValidImperative({address})
  }
  return Promise.resolve(false)
}

export const getSendModalTxPlan = ({
  tokenData,
  values,
}: {
  tokenData?: FlowTokenMetadata
  values: FormSchema
}): FlowTxPlan => {
  const address = (
    values.toAddressNameServiceState.status === 'valid'
      ? values.toAddressNameServiceState.resolvedAddress
      : values.toAddress
  ) as FlowAddress
  if (tokenData) {
    return !tokenData.contractMetadata.isFungible
      ? {
          type: 'transferNft',
          args: {
            address,
            withdrawId: parseFlowTokenId(tokenData.id).id,
          },
          contractMetadata: tokenData.contractMetadata,
        }
      : {
          type: 'transferToken',
          args: {
            amount: commonParseTokenAmount(
              ensureSingleTokenAmount(values.assets),
              tokenData.decimals,
            ) as Nanoflow,
            address,
          },
          contractMetadata: tokenData.contractMetadata,
        }
  } else
    return {
      type: 'transferFlow',
      args: {
        amount: flowToNanoflow(ensureNativeAmount(values.assets)),
        address,
      },
    }
}

export const getAddressNameServiceUtils = (
  resolveAddressNameProps: ReturnType<typeof useResolveAddressName>,
) => ({
  data: resolveAddressNameProps.data || null,
  isLoading: resolveAddressNameProps.isPending,
  onSubmit: (address: string) =>
    resolveAddressNameProps.mutateAsyncSilent(address),
  shouldEnableNameService: (address: string) => {
    // inspired by https://github.com/findonflow/find/blob/50e510f3b976c9179cf00483a688b537d8058efc/contracts/FIND.cdc#L1835
    // "A FIND name has to be lower-cased alphanumeric or dashes and between 3 and 16 characters"
    // https://github.com/findonflow/find/blob/50e510f3b976c9179cf00483a688b537d8058efc/contracts/FIND.cdc#L1899
    // .find names can have optional .find suffix
    return /^([a-zA-Z0-9_]{3,16})(\.find)?$/.test(address)
  },
})
