import type {StakeAccountId, AccountId} from '@nufi/wallet-common'
import type {
  Lamports,
  SolanaTransaction,
  SolanaAccountInfo,
  SolanaAccountWithStakeAccounts,
  SolanaStakeAccountInfo,
  SolanaTxFeeParams,
} from '@nufi/wallet-solana'
import {calculateTxFeeAmount} from '@nufi/wallet-solana'
import {PublicKey} from '@solana/web3.js'
import type {FormikProps} from 'formik'
import {Formik} from 'formik'
import React, {useRef} from 'react'
import {useTranslation} from 'react-i18next'
import * as yup from 'yup'

import {QueryGuard, StakingModalError} from '../../../../components'
import {getBlockchainDecimals} from '../../../../constants'
import {useTrackTxSubmission} from '../../../../tracking'
import {assert} from '../../../../utils/assertion'
import {
  useGetTxFeeParams,
  useSubmitStakeInstruction,
  useGetStakeAccountsInfo,
  useSignWithdraw,
  solToLamports,
  lamportsToSol,
} from '../../../../wallet/solana'
import {ensureAccountById} from '../../../../wallet/utils/common'
import {
  SignTxFlow,
  useActiveSchema,
  useActiveScreenState,
  useFormikOnSubmit,
  WithActiveScreenState,
  WithdrawSubmitScreen,
} from '../../../transaction'
import {useSummaryDetailsSchema} from '../../common/schema'
import {DeviceReadyState, WithdrawModalHeader} from '../../common/utils'
import type {BaseWithdrawStakeSchema} from '../schema'
import {useBaseDetailsSchema, useStakeFeePayerSchema} from '../schema'
import {findStakeAccountAndParentById} from '../utils'

import WithdrawSummaryScreen from './WithdrawSummaryScreen'

type FormSchema = BaseWithdrawStakeSchema

export default function SolanaWithdrawModal({
  onClose,
  stakeAccountId,
}: {
  onClose: () => unknown
  stakeAccountId: StakeAccountId
}) {
  const stakeAccountsQuery = useGetStakeAccountsInfo()
  const txFeeParamsQuery = useGetTxFeeParams({
    affectedAccounts: [new PublicKey(stakeAccountId)],
  })
  return (
    <WithActiveScreenState initialScreen="summary">
      <QueryGuard
        {...stakeAccountsQuery}
        ErrorElement={<StakingModalError blockchain="solana" />}
        loadingVariant="centered"
      >
        {(accountsWithStakeInfo) => (
          <QueryGuard
            {...txFeeParamsQuery}
            ErrorElement={<StakingModalError blockchain="solana" />}
            loadingVariant="centered"
          >
            {(txFeeParams) => (
              <SolanaWithdrawForm
                {...{
                  onClose,
                  accountsWithStakeInfo,
                  txFeeParams,
                  stakeAccountId,
                }}
              />
            )}
          </QueryGuard>
        )}
      </QueryGuard>
    </WithActiveScreenState>
  )
}

type SolanaWithdrawFormProps = {
  onClose: () => unknown
  accountsWithStakeInfo: SolanaAccountWithStakeAccounts[]
  txFeeParams: SolanaTxFeeParams
  stakeAccountId: StakeAccountId
}

/**
 * A stake account is destroyed upon withdrawing all stake from it. On mount, the stake
 * account and its parent account are found via stakeAccountId. The useRef hook keeps its set
 * value throughout the whole lifecycle of the component. This ensures that even after
 * the withdrawal is submitted and data updated, the parent account and stake account from here
 * are retained and are safe to use even after the stake account's destruction in SOL network.
 */
function useInitialParentAndStakeAccount(
  accountsWithStakeInfo: SolanaAccountWithStakeAccounts[],
  stakeAccountId: StakeAccountId,
) {
  const parentAccountRef = useRef<SolanaAccountWithStakeAccounts>()
  const stakeAccountRef = useRef<SolanaStakeAccountInfo>()

  if (!parentAccountRef.current || !stakeAccountRef.current) {
    const {parentAccount: foundParentAccount, stakeAccount: foundStakeAccount} =
      findStakeAccountAndParentById(accountsWithStakeInfo, stakeAccountId)
    parentAccountRef.current = foundParentAccount
    stakeAccountRef.current = foundStakeAccount
  }

  return {
    parentAccount: parentAccountRef.current,
    stakeAccount: stakeAccountRef.current,
  }
}

function SolanaWithdrawForm({
  onClose,
  accountsWithStakeInfo,
  txFeeParams,
  stakeAccountId,
}: SolanaWithdrawFormProps) {
  const {t} = useTranslation()
  const withdraw = useSignWithdraw()
  const submit = useSubmitStakeInstruction()
  const {setActiveScreen} = useActiveScreenState()
  const baseDetailsSchema = useBaseDetailsSchema({
    decimals: getBlockchainDecimals('solana'),
  })
  const summaryDetailsSchema = useSummaryDetailsSchema({
    accounts: accountsWithStakeInfo,
  })
  const {parentAccount, stakeAccount} = useInitialParentAndStakeAccount(
    accountsWithStakeInfo,
    stakeAccountId,
  )
  const accountId = parentAccount.id
  const account = ensureAccountById(accountsWithStakeInfo, accountId)
  const txFeeAmount = calculateTxFeeAmount(txFeeParams)
  const feePayerSchema = useStakeFeePayerSchema({account, txFee: txFeeAmount})

  const schema = useActiveSchema({
    details: {},
    summary: {
      ...baseDetailsSchema,
      ...summaryDetailsSchema,
      ...feePayerSchema,
      amount: baseDetailsSchema.amount.concat(
        yup
          .number()
          .test(
            'amount-not-more-than-max',
            t('Cannot withdraw more than available'),
            function (amount, context) {
              if (amount === undefined) return true
              const maxWithdrawAmount = stakeAccount.balance
              // passing original value because JS numbers don't preserve original notation
              // originalValue not exposed in yup TS types, see open issue in yup::
              // https://github.com/jquense/yup/issues/1591
              return maxWithdrawAmount.isGreaterThanOrEqualTo(
                solToLamports(
                  (context as unknown as {originalValue: string}).originalValue,
                ),
              )
            },
          )
          .test(
            'amount-not-less-than-min',
            t('Cannot withdraw less than transaction fee'),
            function (amount, context) {
              if (amount === undefined) return true
              const minWithdrawAmount = txFeeAmount
              // passing original value because JS numbers don't preserve original notation
              // originalValue not exposed in yup TS types, see open issue in yup::
              // https://github.com/jquense/yup/issues/1591
              return minWithdrawAmount.isLessThanOrEqualTo(
                solToLamports(
                  (context as unknown as {originalValue: string}).originalValue,
                ),
              )
            },
          ),
      ),
    },
  })

  const initialValues: FormSchema = {
    amount: '0', // required for the totalAmount not to start as NaN
    accountId,
    password: '',
    hasSufficientFeeFunds: true,
  }

  const onTxSign = async (values: FormSchema) =>
    await withdraw.mutateAsyncSilent({
      accountId,
      stakeAccountKey: stakeAccount.publicKey,
      amount: solToLamports(values.amount),
      txFeeParams,
    })

  const onTxSubmit = async (
    accountId: AccountId,
    signedTx: SolanaTransaction,
  ) => await submit.mutateAsyncSilent({signedTx, accountId})

  const onTxSignAndSubmit = async (values: FormSchema) => {
    const signedTx = await onTxSign(values)
    if (!signedTx) return
    setActiveScreen('submit')
    await onTxSubmit(accountId, signedTx)
  }

  const onSubmit = useFormikOnSubmit<FormSchema>({
    accounts: accountsWithStakeInfo,
    onTxSignAndSubmit,
  })

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={onSubmit}
    >
      {(formikProps) => (
        <SolanaWithdrawModalContent
          {...{
            formikProps,
            accounts: accountsWithStakeInfo,
            onClose,
            txFee: txFeeAmount,
            onTxSubmit,
            onTxSignAndSubmit,
            stakeAccount,
            parentAccount,
          }}
          submitProps={submit}
          signProps={withdraw}
        />
      )}
    </Formik>
  )
}

type SolanaWithdrawModalContentProps = {
  accounts: SolanaAccountInfo[]
  formikProps: FormikProps<FormSchema>
  submitProps: ReturnType<typeof useSubmitStakeInstruction>
  signProps: ReturnType<typeof useSignWithdraw>
  onClose: () => unknown
  txFee: Lamports
  onTxSubmit: (
    accountId: AccountId,
    signedTx: SolanaTransaction,
  ) => Promise<unknown>
  onTxSignAndSubmit: (values: FormSchema) => Promise<unknown>
  stakeAccount: SolanaStakeAccountInfo
  parentAccount: SolanaAccountWithStakeAccounts
}

function SolanaWithdrawModalContent({
  accounts,
  formikProps,
  submitProps,
  onClose,
  txFee,
  onTxSubmit,
  onTxSignAndSubmit,
  signProps,
  stakeAccount,
  parentAccount,
}: SolanaWithdrawModalContentProps) {
  const accountId = parentAccount.id

  const availableWithdrawAmount = stakeAccount.balance
  const summary = {
    amount: solToLamports(formikProps.values.amount),
    fee: txFee,
    fromAccount: ensureAccountById(accounts, accountId),
    parentAccountName: parentAccount.name,
    availableWithdrawAmount,
  }

  const blockchain = 'solana'
  const ModalHeader = <WithdrawModalHeader {...{blockchain, onClose}} />

  useTrackTxSubmission(submitProps, {
    blockchain,
    provider: summary.fromAccount.cryptoProviderType,
    type: 'withdraw_rewards',
    value: summary.amount.toNumber(),
  })

  return (
    <SignTxFlow
      {...{
        onClose,
        formikProps,
        ModalHeader,
        blockchain,
        signProps,
        submitProps,
        DeviceReadyState,
        onTxSignAndSubmit,
      }}
      onTxSubmit={onTxSubmit}
      initialScreen="summary"
      renderSummary={() => (
        <WithdrawSummaryScreen
          onBack={onClose}
          onSubmit={formikProps.handleSubmit}
          maxAmountOptions={{
            onMaxAmount: () => {
              const amountKey: keyof FormSchema = 'amount' // due to TS
              formikProps.setFieldValue(
                amountKey,
                lamportsToSol(availableWithdrawAmount as Lamports),
              )
            },
          }}
          {...{summary, formikProps, onClose, blockchain}}
        />
      )}
      renderSubmit={(signAndSubmitUtils) => {
        const signedTransaction = signProps.data
        assert(!!signedTransaction)

        return (
          <WithdrawSubmitScreen
            accountId={accountId}
            onBack={signAndSubmitUtils.onSignAndSubmitGoBack}
            onRetry={() => onTxSubmit(accountId, signedTransaction)}
            {...{onClose, submitProps, blockchain, ModalHeader}}
          />
        )
      }}
    />
  )
}
