import type {Theme, ButtonProps as MuiButtonProps} from '@mui/material'
import {Button as MuiButton, Box} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'

import {InlineLoading} from '../Loading'

type CustomButtonProps = {
  textTransform?: 'capitalize' | 'uppercase' | 'none'
  loading?: boolean
  target?: React.HTMLAttributeAnchorTarget
}
export type ButtonProps = MuiButtonProps & CustomButtonProps

export default function Button(props: ButtonProps) {
  const {textTransform, children, loading, color, ...restProps} = props
  const classes = useStyles({textTransform})

  return (
    <MuiButton {...restProps} color={color || 'default'} classes={classes}>
      {loading && (
        <Box className={classes.loadingWrapper}>
          <InlineLoading size={18} color="inherit" />
        </Box>
      )}
      {children}
    </MuiButton>
  )
}

const useStyles = makeStyles<Theme, CustomButtonProps>((theme) => ({
  root: ({textTransform}) => ({
    textTransform: textTransform || 'uppercase',
    minHeight: 42,
  }),
  loadingWrapper: {
    marginRight: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
}))
