import {isEvmBlockchain} from '@nufi/wallet-evm'
import React from 'react'

import {MutationGuard} from 'src/components'
import {usePlatformConfig} from 'src/dappConnector/PlatformConfig'
import {useGetIsBlockchainEnabled} from 'src/features/profile/application'
import {useEnableBlockchain} from 'src/features/walletStorage/application/enableBlockchain'
import {useEvmStore} from 'src/store/wallet'

import {useDappConnectorStore} from '../../../../../store/dappConnector'
import {assert} from '../../../../../utils/assertion'
import type {EvmBlockchain} from '../../../../../wallet/evm'
import {getEvmManager} from '../../../../../wallet/evm/evmManagers'

import {ChangeEvmChain as ChangeEvmChainContent} from './ChangeEvmChain'

export function ChangeEvmChain() {
  const isBlockchainEnabled = useGetIsBlockchainEnabled()
  const {
    screenInfo,
    selectedAccount,
    blockchain: currentBlockchain,
  } = useDappConnectorStore()
  assert(screenInfo.state === 'change-evm-chain')
  assert(isEvmBlockchain(currentBlockchain))

  const storedAccounts = useEvmStore().accounts || []
  const {lastAccountIdHandlers} = usePlatformConfig()

  const selectedStoredAccount = storedAccounts.find(
    (a) => a.id === selectedAccount?.id,
  )
  assert(selectedStoredAccount != null)

  const enableBlockchainMutation = useEnableBlockchain()

  return (
    <>
      <ChangeEvmChainContent
        onAccept={async (blockchain: EvmBlockchain) => {
          if (!isBlockchainEnabled(blockchain)) {
            try {
              await enableBlockchainMutation.mutateAsync(blockchain)
            } catch {
              return
            }
          }

          const accountOfflineInfo = getEvmManager(
            blockchain,
          ).accountManager.getAccountOfflineInfo(selectedStoredAccount)

          await lastAccountIdHandlers?.updateLastAccountId(
            blockchain,
            accountOfflineInfo.id,
          )
          screenInfo.onChange(blockchain, accountOfflineInfo)
        }}
        onReject={screenInfo.onRejected}
        currentChainId={screenInfo.data.currentChainId}
        proposedChainId={screenInfo.data.proposedChainId}
      />
      <MutationGuard {...enableBlockchainMutation} />
    </>
  )
}
