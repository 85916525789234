import {Box} from '@mui/material'
import type BigNumber from 'bignumber.js'
import clsx from 'clsx'
import type {FormikProps} from 'formik'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {WithWeakTokenMetadata} from 'src/wallet/public/ui'

import {
  ModalLayout,
  ModalFooter,
  TransferCardSummary,
  FooterLayout,
  useModalSharedStyles,
  CurrentPasswordField,
} from '../../../components'
import {useIsPasswordVerificationRequired} from '../../../store/auth'
import type {Blockchain, AccountInfo} from '../../../types'

import {TransferDetailsSummary} from './TransferDetailsSummary'
import type {TxSummarySendContext, BaseSendSchema, AssetSchema} from './types'
import {getAmountsToSend} from './utils'

export type SummaryScreenProps<T extends BaseSendSchema> = {
  blockchain: Blockchain
  onBack: () => unknown
  onSubmit: () => unknown
  summary: {
    assets: AssetSchema[]
    fee: BigNumber
    toAddress: string
    humanReadableToAddressName?: string
    fromAccount: AccountInfo
  }
  formikProps: FormikProps<T>
  ModalHeader: React.ReactNode
  extraContent?: React.ReactNode
  sendContext: TxSummarySendContext
}

const FORM_ID = 'send-form-confirm-details'

export default function SummaryScreen<T extends BaseSendSchema>({
  onBack,
  onSubmit,
  blockchain,
  summary,
  formikProps,
  ModalHeader,
  sendContext,
  extraContent,
}: SummaryScreenProps<T>) {
  const {t} = useTranslation()
  const classes = useModalSharedStyles()
  const isPasswordVerificationRequired = useIsPasswordVerificationRequired(
    summary.fromAccount,
  )
  const tokenId =
    sendContext.type !== 'native' ? sendContext?.tokenId : undefined

  const {nativeAmount, tokensToSendCount, singleTokenAmount} = getAmountsToSend(
    {
      blockchain,
      txFee: summary.fee,
      assets: summary.assets,
      sendContext,
    },
  )

  return (
    <form
      onSubmit={onSubmit}
      noValidate
      id={FORM_ID}
      className={classes.formWrapper}
    >
      <ModalLayout
        header={ModalHeader}
        body={
          <Box
            px={2}
            className={clsx(
              classes.commonTopPadding,
              classes.commonBottomPadding,
            )}
          >
            <WithWeakTokenMetadata blockchain={blockchain} tokenId={tokenId}>
              {(tokenMetadata) => (
                <>
                  <TransferCardSummary
                    tokenMetadata={tokenMetadata}
                    blockchain={blockchain}
                    amount={singleTokenAmount || nativeAmount}
                    tokensToSendCount={tokensToSendCount}
                    {...summary}
                  />
                  {extraContent}
                  <Box className={classes.commonTopMargin}>
                    <TransferDetailsSummary
                      {...{blockchain, sendContext, ...summary}}
                    />
                  </Box>
                </>
              )}
            </WithWeakTokenMetadata>
          </Box>
        }
        footer={
          <ModalFooter hasDivider>
            {isPasswordVerificationRequired && (
              <CurrentPasswordField
                {...{formikProps}}
                className={classes.passwordHeight}
              />
            )}
            <FooterLayout
              leftBtnConf={{
                onClick: onBack,
                children: t('Back'),
              }}
              rightBtnConf={{
                variant: 'contained',
                form: FORM_ID,
                type: 'submit',
                disabled: formikProps.isSubmitting,
                children: t('Submit'),
              }}
            />
          </ModalFooter>
        }
      />
    </form>
  )
}
