import type {AccountId, StakeAccountId} from '@nufi/wallet-common'
import {useParams} from 'react-router-dom'

type StakeRouteParams = {
  accountId: AccountId
  stakeAccountId: StakeAccountId
}

export const useStakeRouteOptions = (): StakeRouteParams =>
  useParams<StakeRouteParams>()
