import React, {useEffect} from 'react'

import {formatNativeAmount} from '../../../../wallet'
import type {
  EvmAddress,
  EvmBlockchain,
  EvmAccountInfo,
  EvmWei,
} from '../../../../wallet/evm'
import {
  useSignTransfer,
  getMaxSendableNativeAmount,
  cachedGetGasLimit,
} from '../../../../wallet/evm'
import {nativeToWei} from '../../../../wallet/evm/sdk/utils'
import {ensureNativeAmount} from '../../common/utils'
import {parseNonceFormValues} from '../common'
import {EvmSendModalContent} from '../common/SendModalContent'
import type {GasFieldStatus} from '../gas/common'
import {
  useDebouncedGasLimit,
  validAmountForGasLimitEstimationOrZero,
} from '../gas/gasLimitEstimate'
import type {BaseSchema} from '../schema'
import type {EvmSendNativeModalProps} from '../types'

export const EvmSendNativeModal = <TBlockchain extends EvmBlockchain>({
  fromAccount,
  formikProps,
  blockchain,
  gasProps,
  txFee,
  ...rest
}: EvmSendNativeModalProps<TBlockchain>) => {
  const sign = useSignTransfer(blockchain)
  const {values, errors, setValues, setFieldValue, status} = formikProps

  const gasLimitKey: keyof BaseSchema = 'gasLimit'
  const gasLimitStatus = status?.[gasLimitKey] as GasFieldStatus | undefined

  const addressToEstimateAgainst = (
    values.toAddress && !errors.toAddress
      ? values.toAddress
      : fromAccount.address
  ) as EvmAddress<TBlockchain>

  const gasLimitEstimateData = {
    from: fromAccount.address,
    to: addressToEstimateAgainst,
    value: validAmountForGasLimitEstimationOrZero(
      nativeToWei(ensureNativeAmount(values.assets)),
      fromAccount.balance,
    ).toString(),
  }

  const {
    gasLimitSubmitOverlay,
    getDebouncedGasLimit,
    createOnBeforeDetailsSubmit,
  } = useDebouncedGasLimit({
    blockchain,
    setGasLimit: (gasLimit) => setValues((values) => ({...values, gasLimit})),
    getGasLimitEstimate: cachedGetGasLimit<TBlockchain>,
    timeoutMs: 500,
  })

  useEffect(() => {
    getDebouncedGasLimit?.({
      gasLimitArgs: gasLimitEstimateData,
      values,
      gasLimitStatus,
    })
    // JSON.stringify used so that we do not have to use more verbose `useMemo`
  }, [getDebouncedGasLimit, JSON.stringify(gasLimitEstimateData)])

  const maxSendableAmount = getMaxSendableNativeAmount(
    (fromAccount as EvmAccountInfo<TBlockchain>).balance,
    txFee as EvmWei<TBlockchain>,
  ).maxAmount
  const showSendMaxOption = maxSendableAmount.isGreaterThanOrEqualTo(0)

  const onTxSign = async () =>
    sign.mutateAsyncSilent({
      gasOptions: gasProps.formValuesToGasOptions(values),
      fromAccountId: values.accountId,
      ...parseNonceFormValues(values),
      amount: nativeToWei(ensureNativeAmount(values.assets)),
      toAddress: values.toAddress as EvmAddress<TBlockchain>,
      variant: 'nativeTransfer',
    })

  return (
    <>
      {gasLimitSubmitOverlay}
      <EvmSendModalContent
        onBeforeDetailsSubmit={createOnBeforeDetailsSubmit({
          gasLimitArgs: gasLimitEstimateData,
          values,
          gasLimitStatus,
        })}
        {...rest}
        {...{
          fromAccount,
          formikProps,
          blockchain,
          gasProps,
          txFee,
        }}
        getAssetFieldProps={(asset, {name}) => ({
          maxAmountOptions: showSendMaxOption
            ? {
                onMaxAmount: () => {
                  setFieldValue(
                    name,
                    formatNativeAmount(blockchain, maxSendableAmount),
                  )
                },
              }
            : undefined,
        })}
        signProps={{
          ...sign,
          mutateAsyncSilent: onTxSign,
        }}
      />
    </>
  )
}
