import {Error} from '@mui/icons-material'
import type {SxProps, Theme} from '@mui/material'
import {Box, Grid, List, Paper, Typography} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import ContentLoader from 'react-content-loader'
import {useTranslation} from 'react-i18next'

import {Button, FormattedDateTime, LabeledIcon} from '../../../../components'
import {
  useScrollableStyles,
  ContentLoaderBackground,
} from '../../../../utils/layoutUtils'
import {useGetCoinName} from '../../../../utils/translations'
import type {Blockchain} from '../../../../wallet/types'

type NewStakingButtonProps = {
  openStakingModal: () => void
  preventEventsPropagation?: boolean
  blockchain: Blockchain
}

export function NewStakingButton({
  openStakingModal,
  preventEventsPropagation = false,
  blockchain,
}: NewStakingButtonProps) {
  const {t} = useTranslation()
  const getCoinName = useGetCoinName()

  const _openStakingModal = (event: React.SyntheticEvent) => {
    preventEventsPropagation && event.stopPropagation()
    openStakingModal()
  }

  return (
    <Button
      variant="outlined"
      textTransform="none"
      color="primary"
      onClick={_openStakingModal}
    >
      {t('Stake')} {getCoinName(blockchain)}
    </Button>
  )
}

export function SummaryCard({
  label,
  value,
}: {
  label: React.ReactNode
  value: React.ReactNode
}) {
  const classes = useStakeSummaryStyles()

  return (
    <Paper variant="outlined" elevation={0} className={classes.summaryCard}>
      <Grid container item direction="column" alignItems="center" spacing={2}>
        <Grid item>
          <Box className={classes.summaryLabel}>{label}</Box>
        </Grid>
        <Grid item>
          <Box className={classes.summaryValue}>{value}</Box>
        </Grid>
      </Grid>
    </Paper>
  )
}

type StakeSummaryProps = {
  children: React.ReactNode
}

export function StakeSummary({children}: StakeSummaryProps) {
  const classes = useStakeSummaryStyles()

  return (
    <Box
      display="flex"
      className={classes.summaryRow}
      border={1}
      borderColor="divider"
    >
      {children}
    </Box>
  )
}

const useStakeSummaryStyles = makeStyles((theme) => ({
  summaryCard: {
    maxWidth: '25%',
    width: '100%',
    marginRight: theme.spacing(3),
    border: 'none',
    borderRadius: 0,
    '&:not(:last-child)': {
      borderRight: `1px solid ${theme.palette.divider}`,
    },
  },
  summaryLabel: {
    color: theme.palette.grey['600'],
    fontSize: 12,
  },
  summaryValue: {
    fontSize: 20,
  },
  summaryRow: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    background: theme.palette.background.paper,
    padding: theme.spacing(2),
  },
}))

type StakeAccountListProps = {
  children: React.ReactNode
}

export function ScrollableList({children}: StakeAccountListProps) {
  const {scrollableList} = useScrollableStyles()
  const classes = useScrollableListStyles()

  return (
    <Box mt={2} className={scrollableList}>
      <List component="nav" className={classes.list}>
        {children}
      </List>
    </Box>
  )
}

const useScrollableListStyles = makeStyles(() => ({
  list: {
    padding: 0,
  },
}))

export function EpochInfoText({
  label,
  sx,
  ...rest
}: {
  label: string
  sx?: SxProps<Theme>
} & (
  | {
      date: Date
    }
  | {
      value: React.ReactNode
    }
)) {
  return (
    <Box mr={2} sx={sx}>
      <Typography display="inline" color="textSecondary" variant="body2">
        {label}:
      </Typography>{' '}
      <Typography display="inline-flex" variant="body2">
        {'date' in rest ? (
          <FormattedDateTime
            dateTime={rest.date}
            dateTimeOptions={{timeZoneName: 'short'}}
          />
        ) : (
          rest.value
        )}
      </Typography>
    </Box>
  )
}

export function EpochInfoLoading() {
  return (
    <ContentLoader
      width="550"
      height="48"
      backgroundColor={ContentLoaderBackground()}
    >
      <rect x="0" y="38" rx="5" height="10" width="100%" />
    </ContentLoader>
  )
}

export function EpochInfoError() {
  const {t} = useTranslation()

  return (
    <LabeledIcon
      color="text.secondary"
      Icon={<Error fontSize="small" />}
      Label={<Typography>{t('Could not load epoch info')}</Typography>}
      iconPosition="start"
    />
  )
}
