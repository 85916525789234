import {useCallback, useEffect, useRef, useState} from 'react'

import {trackProfileAction} from 'src/tracking/utils'

import {mutationKeys as cardanoMutationKeys} from '../wallet/cardano'
import {
  getAvailableEvmBlockchains,
  mutationKeys as evmMutationKeys,
} from '../wallet/evm'
import {mutationKeys as flowMutationKeys} from '../wallet/flow'
import {mutationKeys as solanaMutationKeys} from '../wallet/solana'

export const logout = () => {
  trackProfileAction('logout')
  window.location.reload()
}

// taken from https://usehooks.com/useMemoCompare/ & adjusted
export function useMemoCompare<T>(
  next: T,
  compare: (previous: T | undefined, next: T) => boolean,
): T {
  const isInitialRun = useRef(true)
  const previousRef = useRef<T>()
  const previous = previousRef.current
  const isEqual = compare(previous, next)

  // If not equal update previousRef to next value.
  // We only update if not equal so that this hook continues to return
  // the same old value if compare keeps returning true.
  useEffect(() => {
    isInitialRun.current = false
    if (!isEqual) {
      previousRef.current = next
    }
  })

  // To avoid `!!prev` checks, in the `compare` fn
  if (isInitialRun.current) return next

  // Finally, if equal then return the previous value
  return isEqual ? (previous as T) : next
}

export function useJsonMemoCompare<T>(next: T): T {
  return useMemoCompare(
    next,
    (prev, next) => JSON.stringify(prev) === JSON.stringify(next),
  )
}

export const trackedTransactionKeys = [
  flowMutationKeys.flowSubmitTx,
  cardanoMutationKeys.cardanoSubmitTransaction,
  solanaMutationKeys.solanaSubmitTransfer,
  solanaMutationKeys.solanaSubmitStakeInstruction,
  ...getAvailableEvmBlockchains().map((evmBlockchain) =>
    evmMutationKeys.submitTransfer(evmBlockchain),
  ),
]

// The useRef doesn’t notify you when its content changes.
// Mutating the .current property doesn’t cause a re-render.ref
// See:
// https://reactjs.org/docs/hooks-reference.html#useref
// https://reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node

export const useCallbackRef = () => {
  const [htmlNode, setHtmlNode] = useState<HTMLElement | null>(null)

  const callbackRef = useCallback((node: HTMLElement) => {
    if (node !== null) {
      setHtmlNode(node)
    }
  }, [])

  return {callbackRef, htmlNode}
}
