import type {MnemonicData, ProfileData} from './types'

// These guards should serve as run-time kill switches against storing corrupted
// or totally different data than intended

export const isMnemonicData = (value: unknown): value is MnemonicData => {
  if (!(value instanceof Object)) {
    return false
  }

  const mnemonicData = value as MnemonicData
  if (!mnemonicData.mnemonic || typeof mnemonicData.mnemonic !== 'string') {
    return false
  }

  return true
}

export const isProfileData = (value: unknown): value is ProfileData => {
  if (!(value instanceof Object)) {
    return false
  }

  const profileData = value as ProfileData
  if (!(profileData.settings instanceof Object)) {
    return false
  }

  if (!Array.isArray(profileData.accounts)) {
    return false
  }

  return true
}
