import type BigNumber from 'bignumber.js'
import type {SwapAsset} from 'common'
import React from 'react'

import {DEFAULT_EXCHANGE_DECIMALS} from '../constants'

type FormattedExchangeRateProps = {
  exchangeRate: BigNumber
  fromAsset: SwapAsset
  toAsset: SwapAsset
}

export const FormattedExchangeRate = ({
  exchangeRate,
  fromAsset,
  toAsset,
}: FormattedExchangeRateProps) => {
  const formattedExchangeRate = exchangeRate
    .decimalPlaces(DEFAULT_EXCHANGE_DECIMALS)
    .toString()
  return <>{`1 ${fromAsset} = ~${formattedExchangeRate} ${toAsset}`}</>
}
