/* eslint-disable @typescript-eslint/explicit-function-return-type */

import {Sentry} from '@nufi/frontend-common'
import {arraySum} from '@nufi/wallet-common'
import {isEvmBlockchain} from '@nufi/wallet-evm'
import type {EvmDerivationPathType} from '@nufi/wallet-evm'
import {useQuery} from '@tanstack/react-query'
import BigNumber from 'bignumber.js'
import _ from 'lodash'

import {
  getEnabledExistingBlockchains,
  useIsBlockchainEnabled,
} from 'src/features/profile/application/enabledBlockchains'
import {fetchNeverStaleQuery, useNeverStaleQuery} from 'src/utils/query-utils'
import {discoverHwAccounts} from 'src/wallet/public/accounts'

import config from '../../../../config'
import queryClient from '../../../../queryClient'
import {sleep} from '../../../../utils/helpers'
import {
  cachedGetAccounts as cachedGetGenericAccounts,
  useGetAccounts as useGetGenericAccounts,
} from '../../../public/queries/accounts'
import type {
  AllAvailableAccountsResponse,
  CryptoProviderType,
} from '../../../types'
import {
  DEFAULT_DISCOVERY_QUERY_OPTIONS,
  secondsToMilliseconds,
} from '../../../utils/common'
import {evm} from '../../commonEvmManagers'
import {getEvmManager} from '../../evmManagers'
import type {
  EvmAddress,
  EvmBlockchain,
  EvmAccountInfo,
  EvmWei,
  LondonGasSuggestions,
  EvmUnsignedTransaction,
} from '../../types'

import {getAccountInfoForDiscoveryResponse} from './utils'

export const coreQueryKeys = {
  accounts: <TBlockchain extends EvmBlockchain>(blockchain: TBlockchain) => [
    blockchain,
    'accounts',
  ],
  discoverAccounts: {
    index: ['evm', 'discoverAccounts'],
    cryptoProviderType: (cryptoProviderType: CryptoProviderType) => ({
      index: [...coreQueryKeys.discoverAccounts.index, cryptoProviderType],
      derivationPathType: (derivationPathType: EvmDerivationPathType) => [
        ...coreQueryKeys.discoverAccounts.cryptoProviderType(cryptoProviderType)
          .index,
        derivationPathType,
      ],
    }),
  },
  discoverHotAccounts: {
    index: <TBlockchain extends EvmBlockchain>(blockchain: TBlockchain) => [
      blockchain,
      'discoverHotAccounts',
    ],
    derivationPathType: <TBlockchain extends EvmBlockchain>(
      blockchain: TBlockchain,
      derivationPathType: EvmDerivationPathType,
    ) => [
      ...coreQueryKeys.discoverHotAccounts.index(blockchain),
      derivationPathType,
    ],
  },
  discoverLedgerAccounts: {
    index: <TBlockchain extends EvmBlockchain>(blockchain: TBlockchain) =>
      discoverHwAccounts.__key(blockchain, 'ledger'),
    derivationPath: <TBlockchain extends EvmBlockchain>(
      blockchain: TBlockchain,
      derivationPathType: EvmDerivationPathType,
    ) => [
      ...coreQueryKeys.discoverLedgerAccounts.index(blockchain),
      derivationPathType,
    ],
  },
  discoverTrezorAccounts: {
    index: <TBlockchain extends EvmBlockchain>(blockchain: TBlockchain) =>
      discoverHwAccounts.__key(blockchain, 'trezor'),
    derivationPath: <TBlockchain extends EvmBlockchain>(
      blockchain: TBlockchain,
      derivationPathType: EvmDerivationPathType,
    ) => [
      ...coreQueryKeys.discoverTrezorAccounts.index(blockchain),
      derivationPathType,
    ],
  },
  getAPY: <TBlockchain extends EvmBlockchain>(blockchain: TBlockchain) => [
    blockchain,
    'getAPY',
  ],
  getTransactionFee: <TBlockchain extends EvmBlockchain>(
    blockchain: TBlockchain,
  ) => [blockchain, 'getTransactionFee'],
  getGasSuggestions: <TBlockchain extends EvmBlockchain>(
    blockchain: TBlockchain,
  ) => [blockchain, 'getGasSuggestions'],
  getGasPrice: <TBlockchain extends EvmBlockchain>(blockchain: TBlockchain) => [
    blockchain,
    'getGasPrice',
  ],
  estimateGasLimit: <TBlockchain extends EvmBlockchain>(
    blockchain: TBlockchain,
    txData: EvmUnsignedTransaction,
  ) => [blockchain, 'estimateGasLimit', txData],
  getTotalNativeBalance: <TBlockchain extends EvmBlockchain>(
    blockchain: TBlockchain,
  ) => [blockchain, 'getTotalNativeBalance'],
  simulateTx: <TBlockchain extends EvmBlockchain>(
    blockchain: TBlockchain,
    txData: EvmUnsignedTransaction,
  ) => [blockchain, 'simulateTx', txData],
  isContractAddress: <TBlockchain extends EvmBlockchain>(
    blockchain: TBlockchain,
    address: EvmAddress<TBlockchain>,
  ) => [blockchain, 'isContractAddress', address],
}

//
// __CACHED__ QUERIES
//

export const useDiscoverAllEvmAccounts = <TBlockchain extends EvmBlockchain>(
  blockchain: TBlockchain,
  cryptoProviderType: CryptoProviderType,
  derivationPathType: EvmDerivationPathType,
) =>
  useQuery({
    queryKey: coreQueryKeys.discoverAccounts
      .cryptoProviderType(cryptoProviderType)
      .derivationPathType(derivationPathType),
    queryFn: async (): Promise<
      AllAvailableAccountsResponse<EvmAccountInfo<EvmBlockchain>>
    > => {
      const accounts = evm.accountsStore.getAllAccounts()

      const enabledEvmBlockchains =
        getEnabledExistingBlockchains().filter(isEvmBlockchain)

      // The `blockchain` parameter of this fn might not yet be enabled. That's
      // why we can't rely on it being a part of `enabledEvmBlockchains` and
      // why we need to add it to the list of blockchains manually.
      const blockchains = _.uniq([blockchain, ...enabledEvmBlockchains])

      const response = await evm.wallet.discoverAccounts(
        blockchains,
        cryptoProviderType,
        accounts,
        derivationPathType,
      )

      return getAccountInfoForDiscoveryResponse(blockchain, response)
    },
    ...DEFAULT_DISCOVERY_QUERY_OPTIONS,
  })

const getGasSuggestions =
  <TBlockchain extends EvmBlockchain>(blockchain: TBlockchain) =>
  async (): Promise<LondonGasSuggestions<TBlockchain>> => {
    // Fees on testnets are sometimes insanely high, so we allow for mocking during development
    // is needed for testing exchange or other parts of app.
    if (config.mockEvmGasOptions) {
      return {
        highPriorityFeePerGas: new BigNumber(1000) as EvmWei<TBlockchain>,
        mediumPriorityFeePerGas: new BigNumber(500) as EvmWei<TBlockchain>,
        slowPriorityFeePerGas: new BigNumber(100) as EvmWei<TBlockchain>,
        suggestedBaseFeePerGas: new BigNumber(681632000) as EvmWei<TBlockchain>,
      }
    }
    const evmManager = getEvmManager(blockchain)
    return await evmManager.blockchainApi.getGasSuggestions()
  }

export const useGetGasSuggestions = <TBlockchain extends EvmBlockchain>(
  blockchain: TBlockchain,
  enabled = true,
) =>
  useQuery({
    queryKey: coreQueryKeys.getGasSuggestions(blockchain),
    queryFn: getGasSuggestions<TBlockchain>(blockchain),
    // refresh since fee depends on last block
    // In Ethereum, the average block time is between 12 to 14 seconds and is evaluated after each block.
    // src: https://ethereum.org/en/developers/docs/blocks/#block-time
    refetchInterval: secondsToMilliseconds(10),
    enabled,
    // refetchOnWindowFocus causes a lot of unnecessary re-render on window re-focus
    // and the interval is anyway generous.
    refetchOnWindowFocus: false,
  })

export const cachedGetGasSuggestions = <TBlockchain extends EvmBlockchain>(
  blockchain: TBlockchain,
) =>
  queryClient.fetchQuery({
    queryKey: coreQueryKeys.getGasSuggestions(blockchain),
    queryFn: getGasSuggestions<TBlockchain>(blockchain),
  })

const getGasPrice =
  <TBlockchain extends EvmBlockchain>(blockchain: TBlockchain) =>
  async () => {
    // Fees on testnets are sometimes insanely high, so we allow for mocking during development
    // is needed for testing exchange or other parts of app.
    if (config.mockEvmGasOptions) {
      return new BigNumber(100) as EvmWei<TBlockchain>
    }

    const evmManager = getEvmManager(blockchain)
    return evmManager.blockchainApi.getGasPrice()
  }

export const useGetGasPrice = <TBlockchain extends EvmBlockchain>(
  blockchain: TBlockchain,
  enabled = true,
) =>
  useQuery({
    queryKey: coreQueryKeys.getGasPrice(blockchain),
    queryFn: getGasPrice(blockchain),
    enabled,
  })

export const cachedGetGasPrice = <TBlockchain extends EvmBlockchain>(
  blockchain: TBlockchain,
) =>
  queryClient.fetchQuery({
    queryKey: coreQueryKeys.getGasPrice(blockchain),
    queryFn: getGasPrice(blockchain),
  })

const estimateGasLimit =
  <TBlockchain extends EvmBlockchain>(
    blockchain: TBlockchain,
    txData: EvmUnsignedTransaction,
  ) =>
  async () => {
    const {blockchainApi} = getEvmManager(blockchain)
    return blockchainApi.estimateGasLimit(txData)
  }

export const cachedGetGasLimit = <TBlockchain extends EvmBlockchain>(
  blockchain: TBlockchain,
  txData: EvmUnsignedTransaction,
) =>
  queryClient.fetchQuery({
    queryKey: coreQueryKeys.estimateGasLimit(blockchain, txData),
    queryFn: estimateGasLimit(blockchain, txData),
    staleTime: secondsToMilliseconds(15),
  })

//
// __COMPUTED__ QUERIES
//

export const cachedGetAccounts = async <TBlockchain extends EvmBlockchain>(
  blockchain: TBlockchain,
): Promise<EvmAccountInfo<TBlockchain>[]> => {
  return cachedGetGenericAccounts<EvmAccountInfo<TBlockchain>>(blockchain)
}

export const useGetAccounts = <TBlockchain extends EvmBlockchain>(
  blockchain: TBlockchain,
  enabled = true,
) => {
  return useGetGenericAccounts<EvmAccountInfo<TBlockchain>>(blockchain, enabled)
}

export const sumNativeBalances = <TBlockchain extends EvmBlockchain>(
  accounts: EvmAccountInfo<TBlockchain>[],
) => {
  return arraySum(accounts.map((a) => a.balance))
}

const getTotalNativeBalance =
  <TBlockchain extends EvmBlockchain>(blockchain: TBlockchain) =>
  async () => {
    const accounts = await cachedGetAccounts(blockchain)
    return sumNativeBalances(accounts)
  }

export const useGetTotalNativeBalance = <TBlockchain extends EvmBlockchain>(
  blockchain: TBlockchain,
  enabled = true,
) => {
  return useNeverStaleQuery({
    queryKey: coreQueryKeys.getTotalNativeBalance(blockchain),
    queryFn: getTotalNativeBalance(blockchain),
    enabled,
  })
}

export const cachedGetTotalNativeBalance = <TBlockchain extends EvmBlockchain>(
  blockchain: TBlockchain,
) => {
  return fetchNeverStaleQuery({
    queryKey: coreQueryKeys.getTotalNativeBalance(blockchain),
    queryFn: getTotalNativeBalance(blockchain),
  })
}

export const getMaxSendableNativeAmount = <TBlockchain extends EvmBlockchain>(
  availableBalance: EvmWei<TBlockchain>,
  txFee: EvmWei<TBlockchain>,
) => ({
  maxAmount: BigNumber.max(
    availableBalance.minus(txFee),
    0,
  ) as EvmWei<TBlockchain>,
  fee: txFee,
})

// TODO: this is dummy, there is no eth staking support yet
export const useGetAPY = <TBlockchain extends EvmBlockchain>(
  blockchain: TBlockchain,
  enabled = true,
) => {
  const isBlockchainEnabled = useIsBlockchainEnabled(blockchain)
  return useQuery({
    queryKey: coreQueryKeys.getAPY(blockchain),
    queryFn: () => new BigNumber(0),
    staleTime: Infinity,
    enabled: isBlockchainEnabled && enabled,
  })
}

export const fetchNextNonce = <TBlockchain extends EvmBlockchain>({
  blockchain,
  address,
}: {
  blockchain: TBlockchain
  address: EvmAddress<TBlockchain>
}) => getEvmManager(blockchain).blockchainApi.getNextNonce(address)

export const useIsContractAddress = <TBlockchain extends EvmBlockchain>(
  blockchain: TBlockchain,
  address: EvmAddress<TBlockchain>,
  enabled = true,
) =>
  useQuery({
    queryKey: coreQueryKeys.isContractAddress(blockchain, address),
    queryFn: () =>
      getEvmManager(blockchain).blockchainApi.isContractAddress(address),
    enabled,
  })

export async function simulateEvmTransaction<
  TBlockchain extends EvmBlockchain,
>(args: {blockchain: TBlockchain; simulationData: EvmUnsignedTransaction}) {
  try {
    const res = await getEvmManager(args.blockchain).blockchainApi.simulate(
      args.simulationData,
    )

    if (res == null || res.error != null) {
      return null
    }
    return res
  } catch (err) {
    // We handle simulation errors gracefully, as some types of transactions
    // might just fail, and not all blockchains are supported.
    // But we are curios about the failures so we log into Sentry.
    Sentry.captureException(err)
    return null
  }
}

export type SimulateEvmTransaction = typeof simulateEvmTransaction

export function useSimulateEvmTransaction<TBlockchain extends EvmBlockchain>(
  blockchain: TBlockchain,
  simulationData: EvmUnsignedTransaction,
  fn: SimulateEvmTransaction = simulateEvmTransaction,
) {
  return useQuery({
    queryKey: coreQueryKeys.simulateTx(blockchain, simulationData),
    queryFn: async () => {
      /**
       * Before dapps request a transaction they often fire a lot of simple requests to alchemy
       * API via our connector proxy. Alchemy throughput (https://docs.alchemy.com/reference/throughput)
       * is calculated based on how much resources individual calls consume. So before firing this
       * call they already consume some of it.
       * Given that this call is quite resources hungry it often resolves to first getting 429 response.
       * This is eventually resolved due to react-query retries mechanism, but it slows down the simulation
       * on average. Therefore we wait some time before making the request, to avoid first
       * 429 response and being faster on average.
       * We experienced this behavior on Uniswap.
       */
      if (!config.isStorybook) {
        await sleep(1500)
      }
      return await fn({blockchain, simulationData})
    },
    // Turned off, otherwise this is prone for rate limit.
    refetchOnWindowFocus: false,
  })
}
