import {useTranslation} from 'react-i18next'
import * as yup from 'yup'

import {useCurrentLoginInfo} from '../../../../../../store/auth'
import type {AccountId, AccountInfo} from '../../../../../../types'
import {ensureAccountById} from '../../../../../../wallet/utils/common'
import {isPasswordVerificationRequired} from '../../../../../utils'

type SummarySchema = {
  password: string
}

// SignTxFlow requires the accountId
type TxSchema = {
  accountId: AccountId
}

export type CommonSchema = SummarySchema & TxSchema

type SummaryDetailsSchemaArgs = {
  accounts: Array<AccountInfo>
}
const _useSummarySchema = ({accounts}: SummaryDetailsSchemaArgs) => {
  const {t} = useTranslation()
  const loginInfo = useCurrentLoginInfo()

  const accountIdKey = 'accountId'
  return {
    password: yup.string().when(accountIdKey, ([accountId]: unknown[]) => {
      const selectedAccount = ensureAccountById(
        accounts,
        accountId as AccountId,
      )
      if (isPasswordVerificationRequired(loginInfo, selectedAccount)) {
        return yup.string().required(t('Password is required.'))
      }
      return yup.string()
    }),
  }
}

// ensure consistency of keys / values on TS level
export const useSummarySchema: (
  ...args: Parameters<typeof _useSummarySchema>
) => {
  [k in keyof SummarySchema]: ReturnType<typeof _useSummarySchema>[k]
} = _useSummarySchema
