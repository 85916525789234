import {Cancel, Warning} from '@mui/icons-material'
import {Box, Typography, styled, Grid} from '@mui/material'
import Alert from '@mui/material/Alert'
import {safeAssertUnreachable} from '@nufi/frontend-common'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {
  Button,
  FooterLayout,
  InlineLoading,
  SafeCenterAligner,
  StillPendingLink,
} from '../../components'
import type {AppError, HwVendor} from '../../types'
import {assertUnreachable} from '../../utils/assertion'
import type {Blockchain} from '../../wallet'
import {gridPlusImages} from '../hw/gridPlus'
import {ledgerImages} from '../hw/ledger'
import {trezorImages} from '../hw/trezor'

import {useCustomErrorMessage} from './customErrorHandling'
import {useAdjustErrorProps} from './submitErrorHandling'

type TxErrorFooterProps = {
  onBack: () => unknown
  onClose: () => unknown
}

export function TxErrorFooter({onBack, onClose}: TxErrorFooterProps) {
  const {t} = useTranslation()

  return (
    <FooterLayout
      leftBtnConf={{
        onClick: onBack,
        children: t('Back'),
      }}
      rightBtnConf={{
        onClick: onClose,
        children: t('Close'),
      }}
    />
  )
}

type TxLoadingAction =
  | 'signing'
  | 'submitting'
  | 'creatingStakeAccount'
  | 'calculatingFee'
  | 'other'

const useLoadingActionText = (action: TxLoadingAction) => {
  const {t} = useTranslation()
  switch (action) {
    case 'signing':
      return t('Signing')
    case 'submitting':
      return t('Submitting')
    case 'creatingStakeAccount':
      return t('Finalizing')
    case 'calculatingFee':
      return t('Calculating fee')
    case 'other':
      return t('...')
    default:
      return assertUnreachable()
  }
}

export function TxLoadingFooter({
  action,
  closable = false,
  onClose,
}: {
  action: TxLoadingAction
  closable?: boolean
  onClose?: () => unknown
}) {
  const {t} = useTranslation()
  const text = useLoadingActionText(action)

  return (
    <FooterLayout
      leftBtnConf={{
        disabled: !closable,
        children: t('Close'),
        onClick: onClose,
      }}
      rightBtnConf={{
        disabled: true,
        children: text,
      }}
    />
  )
}

type TxLoadingBodyProps = {
  action: TxLoadingAction
  blockchain?: Blockchain
  isDappConnectorWindow?: boolean
}

export function TxLoadingBody({
  action,
  blockchain,
  isDappConnectorWindow,
}: TxLoadingBodyProps) {
  const text = useLoadingActionText(action)
  return (
    <Grid
      spacing={2}
      container
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        height: '100%',
        flexWrap: 'nowrap',
      }}
    >
      <Grid item>
        <Typography variant="h6">{text}</Typography>
      </Grid>
      <Grid item>
        <InlineLoading />
      </Grid>
      <HelperTextBox>
        {blockchain && (
          <StillPendingLink
            blockchain={blockchain}
            isDappConnectorWindow={isDappConnectorWindow}
          />
        )}
      </HelperTextBox>
    </Grid>
  )
}

type BodyLayoutProps = {
  children: React.ReactNode
  centered?: boolean
  fullWidth?: boolean
}

export function TxBodyLayout({children, centered, fullWidth}: BodyLayoutProps) {
  return centered ? (
    <SafeCenterAligner fullWidth={fullWidth}>{children}</SafeCenterAligner>
  ) : (
    <Box p={2} width={fullWidth ? '100%' : 'auto'}>
      {children}
    </Box>
  )
}

type CommonBodyProps = {
  severity: 'error' | 'warning'
  error: AppError
  blockchain: Blockchain
  type: 'sign' | 'submit'
  onRetry?: () => unknown
  defaultTitle?: string
  size?: 'small' | 'medium'
}

function CommonBody(props: CommonBodyProps) {
  const {t} = useTranslation()

  const {title, onRetry, customContent, titleVariant} =
    useAdjustErrorProps(props)
  const error = props.error as Error

  const Icon = props.severity === 'warning' ? WarningIcon : ErrorIcon

  const customErrorMessage = useCustomErrorMessage(error, props.blockchain)

  const isSmall = props.size === 'small'

  return (
    <Box height="100%" display="flex" alignItems="center">
      <Grid spacing={isSmall ? 1 : 2} container justifyContent="center">
        <Grid item xs={12}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            direction="column"
          >
            <Icon size={props.size} />
            <Typography variant={titleVariant || isSmall ? 'body1' : 'h6'}>
              {title}
            </Typography>
          </Grid>
        </Grid>
        {onRetry && (
          <Grid item>
            <Button
              fullWidth
              textTransform="none"
              variant="outlined"
              color={props.severity === 'warning' ? 'warning' : 'error'}
              size={isSmall ? 'small' : undefined}
              onClick={onRetry}
            >
              {t('Retry')}
            </Button>
          </Grid>
        )}
        <Grid item xs={12}>
          <Alert severity={props.severity}>
            {customContent != null
              ? customContent
              : !!onRetry && (
                  <Typography>
                    <Box component="span" fontWeight="fontWeightBold">
                      {t('Please try again.')}
                    </Box>
                  </Typography>
                )}
            <br />
            <Typography>
              {t(
                'If the error persists, please contact our support & provide the error displayed below.',
              )}
            </Typography>
            <br />
            <ErrorMessage>
              {customErrorMessage ||
                JSON.stringify(error && error.message, null, 2)}
            </ErrorMessage>
          </Alert>
        </Grid>
      </Grid>
    </Box>
  )
}

export function TxWarningBody(props: Omit<CommonBodyProps, 'severity'>) {
  return <CommonBody severity="warning" {...props} />
}

export function TxErrorBody(props: Omit<CommonBodyProps, 'severity'>) {
  return <CommonBody severity="error" {...props} />
}

type DeviceReadyStateProps = {
  hwVendor: HwVendor
  alertMessage?: React.ReactNode
}

export const DeviceReadyState = ({
  hwVendor,
  alertMessage,
}: DeviceReadyStateProps) => {
  const {t} = useTranslation()

  const deviceImage = ((hwVendor: HwVendor) => {
    switch (hwVendor) {
      case 'ledger':
        return ledgerImages.sign
      case 'trezor':
        return trezorImages.sign
      case 'gridPlus':
        return gridPlusImages.sign
      default:
        return safeAssertUnreachable(hwVendor)
    }
  })(hwVendor)

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      direction="column"
    >
      {alertMessage && <Alert severity="warning">{alertMessage}</Alert>}
      <ConfirmNote variant="h6" align="center">
        {t(
          'Please confirm the operation on your device to finalize the transaction.',
        )}
      </ConfirmNote>
      <ImgWrapper>{deviceImage}</ImgWrapper>
    </Grid>
  )
}

const ConfirmNote = styled(Typography)(({theme}) => ({
  maxWidth: 300,
  marginTop: theme.spacing(8),
}))

const ImgWrapper = styled('div')(({theme}) => ({
  marginTop: theme.spacing(6),
  display: 'block',
}))

type IconProps = {
  size?: CommonBodyProps['size']
}

const getIconsSize = (size: IconProps['size'] = 'medium') => {
  return {
    medium: {
      width: 60,
      height: 60,
    },
    small: {
      width: 48,
      height: 48,
    },
  }[size]
}

const WarningIcon = styled(Warning)<IconProps>(({theme, size}) => ({
  color: theme.palette.warning.main,
  ...getIconsSize(size),
}))

const ErrorIcon = styled(Cancel)<IconProps>(({theme, size}) => ({
  color: theme.palette.error.main,
  ...getIconsSize(size),
}))

const ErrorMessage = styled(Typography)({
  fontStyle: 'italic',
  maxHeight: 200,
  overflow: 'auto',
  wordBreak: 'break-word',
})

const HelperTextBox = styled('div')(({theme}) => ({
  zIndex: theme.zIndex.modal + 1, // To overlay over MutationGuard
  minHeight: 20, // To prevent content jum
  maxWidth: 300,
  marginLeft: theme.spacing(3),
  marginTop: theme.spacing(2),
}))
