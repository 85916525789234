import React from 'react'

import {
  BatchQueryGuard,
  QueryGuard,
  TransactionModalError,
} from '../../../components'
import {useTokenIdFromRoute} from '../../../router/portfolio'
import type {Blockchain, TokenId, AccountId, AccountInfo} from '../../../wallet'
import {
  useGetAccounts,
  useGetBalancesPerAccount,
  useGetNft,
  useGetTokenMetadata,
} from '../../../wallet'
import {ReceiveModalBody} from '../Helpers'

type CommonReceiveModalProps = {
  accountId: AccountId
  onClose: () => unknown
  blockchain: Blockchain
  hideNftAmount: boolean
}

export const CommonReceiveModal = ({
  onClose,
  accountId,
  blockchain,
  hideNftAmount,
}: CommonReceiveModalProps) => {
  const accountsQuery = useGetAccounts(blockchain)
  const tokenId = useTokenIdFromRoute() as TokenId | null
  const defaultAccountId = accountId || ('' as AccountId)
  const commonQueryGuardProps = {
    ErrorElement: <TransactionModalError />,
    loadingVariant: 'centered' as const,
  }

  return (
    <QueryGuard {...accountsQuery} {...commonQueryGuardProps}>
      {(accountInfos) => {
        const commonProps = {
          onClose,
          accountInfos,
          blockchain,
          defaultAccountId,
        }
        return tokenId == null ? (
          <ReceiveModalBody {...commonProps} />
        ) : (
          <ReceiveTokenModalBody
            tokenId={tokenId}
            hideNftAmount={hideNftAmount}
            {...commonProps}
          />
        )
      }}
    </QueryGuard>
  )
}

const ReceiveTokenModalBody = ({
  tokenId,
  blockchain,
  hideNftAmount,
  onClose,
  accountInfos,
  defaultAccountId,
}: {
  tokenId: TokenId
  blockchain: Blockchain
  hideNftAmount: boolean
  onClose: () => void
  accountInfos: AccountInfo[]
  defaultAccountId: AccountId
}) => {
  const tokensQuery = useGetBalancesPerAccount(blockchain, tokenId, true)
  const tokenMetadataQuery = useGetTokenMetadata(tokenId, blockchain)
  const nftInfoQuery = useGetNft(tokenId, blockchain)

  return (
    <BatchQueryGuard
      queries={{
        tokenMetadata: tokenMetadataQuery,
        tokenBalances: tokensQuery,
        nftInfo: nftInfoQuery,
      }}
      ErrorElement={<TransactionModalError />}
      loadingVariant={'centered'}
    >
      {({tokenMetadata, tokenBalances, nftInfo}) =>
        tokenMetadata && (
          <ReceiveModalBody
            blockchain={blockchain}
            onClose={onClose}
            accountInfos={accountInfos}
            defaultAccountId={defaultAccountId}
            isNft={hideNftAmount && !!nftInfo}
            tokenBalances={{
              data: tokenMetadata,
              balances: tokenBalances,
            }}
          />
        )
      }
    </BatchQueryGuard>
  )
}
