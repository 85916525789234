import {ArrowBack as GoBackIcon} from '@mui/icons-material'
import {Box, Typography} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import type {ReactNode} from 'react'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'

type NavigateBackProps = {
  onBack?: () => unknown
  label?: ReactNode
}

export default function NavigateBack({onBack, label}: NavigateBackProps) {
  const {t} = useTranslation()
  const history = useHistory()
  const classes = useBackNavStyles()

  const _onBack = onBack || (() => history.goBack())

  return (
    <Box onClick={_onBack} className={classes.backWrapper}>
      <GoBackIcon />
      <Typography data-test-id="back-button" className={classes.backText}>
        {label || t('Back')}
      </Typography>
    </Box>
  )
}

export const useBackNavStyles = makeStyles((theme) => ({
  backWrapper: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.action.active,
    '&:hover': {
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
  },
  backText: {
    paddingLeft: theme.spacing(1),
  },
}))
