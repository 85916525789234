import type {TypographyProps} from '@mui/material'
import {Typography} from '@mui/material'
import React from 'react'

import type {Web3AuthUserInfo} from 'src/features/login/domain'

import type {Web3AuthLoginProvider} from '../../features/login/services/web3AuthLogin/types'
import {LabeledIcon, Web3AuthProviderIcon} from '../visual'

export function FormattedWeb3AuthProfileName({
  web3AuthUserInfo,
  iconSize,
  labelProps,
}: {
  web3AuthUserInfo: Web3AuthUserInfo
  iconSize?: number
  labelProps?: TypographyProps
}) {
  return (
    <LabeledIcon
      Icon={
        <Web3AuthProviderIcon
          {...(iconSize ? {size: iconSize} : {})}
          provider={web3AuthUserInfo.typeOfLogin as Web3AuthLoginProvider}
        />
      }
      Label={
        <Typography {...(labelProps || {})}>
          {formatWeb3AuthProfileName(web3AuthUserInfo)}
        </Typography>
      }
      iconPosition="start"
    />
  )
}

export const formatWeb3AuthProfileName = ({
  typeOfLogin,
  email,
  name,
}: Pick<Web3AuthUserInfo, 'typeOfLogin' | 'email' | 'name'>) =>
  (typeOfLogin === 'google' ? email : name) ?? 'unknown user'
