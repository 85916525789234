import {
  constructEvmConnection,
  createBaseNetworkConfig,
  OpStackBlockchainApiImpl,
} from '@nufi/wallet-evm'

import config from 'src/config'

import {createEvmManagers} from '../../../createEvmManagers'

export const base = createEvmManagers({
  blockchain: 'base',
  network: config.baseNetwork,
  apiUrl: config.baseAlchemyApiUrl,
  createNetworkConfig: createBaseNetworkConfig,
  createBlockchainApi: (networkConfig) => {
    const connection = constructEvmConnection<'base'>(
      networkConfig.rpcNodeUrl,
      networkConfig.chainId,
      networkConfig.rpcNodeUrl,
    )
    return new OpStackBlockchainApiImpl(
      'base',
      connection,
      networkConfig.chainId,
      networkConfig.balanceCheckerContractAddress,
    )
  },
})
