import type {EvmBlockchain} from '@nufi/wallet-evm'
import {useTranslation} from 'react-i18next'

import {useGetBlockchainName} from 'src/utils/translations'
import {evm} from 'src/wallet/evm/commonEvmManagers'

import type {CryptoProviderType} from '../../../types'
import type {EvmDerivationPathType} from '../../../wallet/evm/cryptoProviders/types'
import type {DerivationPathTypeConfig} from '../sharedActions/addAccount/AddAccountUtils'

export const useDerivationPathTypeConfig = (
  cryptoProviderType: CryptoProviderType,
): DerivationPathTypeConfig<EvmDerivationPathType> => {
  const {t} = useTranslation()
  const supportedDerivationPathTypes =
    evm.wallet.getSupportedDerivationPathTypes(cryptoProviderType)

  const pathTypeConfig = {
    Bip44Address: {
      label: t('EvmDerivationPathType.Bip44Address.label'),
      description: t('EvmDerivationPathType.Bip44Address.description'),
    },
    Bip44Account: {
      label: t('EvmDerivationPathType.Bip44Account.label'),
      description: t('EvmDerivationPathType.Bip44Account.description'),
    },
    Legacy: {
      label: t('EvmDerivationPathType.Legacy.label'),
      description: t('EvmDerivationPathType.Legacy.description'),
    },
    TestnetBip44Address: {
      label: t('EvmDerivationPathType.TestnetBip44Address.label'),
      description: t('EvmDerivationPathType.TestnetBip44Address.description'),
    },
  }

  return Object.fromEntries(
    Object.entries(pathTypeConfig).map(([pathType, config]) => [
      pathType,
      {
        ...config,
        disabled: !supportedDerivationPathTypes.includes(
          pathType as EvmDerivationPathType,
        ),
      },
    ]),
  ) as DerivationPathTypeConfig<EvmDerivationPathType>
}

export const useMergedEvmAccountsAlert = (blockchain: EvmBlockchain) => {
  const {t} = useTranslation()
  const getBlockchainName = useGetBlockchainName()
  return t(
    `Accounts added for ${getBlockchainName(blockchain)} will be added for all EVM chains.`,
  )
}
