import {Box, FormControl, Typography} from '@mui/material'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {
  QueryGuard,
  LoadStakingDataError,
  SearchableSelect,
} from '../../../../components'
import type {AccountId, StakeHistoryAccountOption} from '../../../../types'
import {useScrollableStyles} from '../../../../utils/layoutUtils'
import type {CardanoStakeAccountInfo} from '../../../../wallet/cardano'
import {
  useGetAccountStakeHistory,
  useGetStakeAccounts,
} from '../../../../wallet/cardano'
import {StakeHistoryListHeader} from '../../common/history/StakeHistoryLayout'
import {StakingTabContentLoading} from '../../common/Loadings'

import StakeHistoryList from './StakeHistoryList'

export default function StakeHistory() {
  const accountsQuery = useGetStakeAccounts()
  return (
    <QueryGuard
      {...accountsQuery}
      ErrorElement={<LoadStakingDataError blockchain="cardano" />}
      loadingVariant="centered"
    >
      {(accounts) => <StakeHistoryScreen accounts={accounts} />}
    </QueryGuard>
  )
}

function StakeHistoryScreen({accounts}: {accounts: CardanoStakeAccountInfo[]}) {
  const {t} = useTranslation()
  const {scrollableParent} = useScrollableStyles()

  const stakeHistoryOptions: StakeHistoryAccountOption[] = accounts.map(
    (a) => ({
      accountId: a.id,
      label: a.name,
    }),
  )

  const [selectedOption, setSelectedOption] =
    useState<StakeHistoryAccountOption>(stakeHistoryOptions[0]!)

  return (
    <Box className={scrollableParent}>
      <Box my={1}>
        <FormControl variant="outlined" fullWidth>
          <SearchableSelect
            label={t('Account')}
            options={stakeHistoryOptions}
            value={selectedOption}
            onChange={setSelectedOption as (value: unknown) => void}
            getOptionLabel={(option) => option.label}
            isOptionEqualToValue={(option, value) =>
              option.label === value.label
            }
            searchable
          />
        </FormControl>
      </Box>

      {selectedOption ? (
        <StakeHistoryContent accountId={selectedOption.accountId} />
      ) : (
        <Box m={1}>
          <Typography>
            {t('Please select an account from the select.')}
          </Typography>
        </Box>
      )}
    </Box>
  )
}

function StakeHistoryContent({accountId}: {accountId: AccountId}) {
  const {t} = useTranslation()
  const stakeHistoryQuery = useGetAccountStakeHistory(accountId)
  const {scrollableParent, scrollableList} = useScrollableStyles()

  return (
    <QueryGuard
      {...stakeHistoryQuery}
      LoadingElement={<StakingTabContentLoading />}
      ErrorElement={<LoadStakingDataError blockchain="cardano" />}
    >
      {(stakeHistory) =>
        stakeHistory.length ? (
          <Box className={scrollableList}>
            <Box className={scrollableParent}>
              <StakeHistoryListHeader />
              <StakeHistoryList stakeHistory={stakeHistory} />
            </Box>
          </Box>
        ) : (
          <Box m={1}>
            <Typography>{t('No staking history.')}</Typography>
          </Box>
        )
      }
    </QueryGuard>
  )
}
