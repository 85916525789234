import {Box, useTheme} from '@mui/material'
import React from 'react'

const priceChangeFormatter = new Intl.NumberFormat(undefined, {
  style: 'percent',
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
  signDisplay: 'exceptZero',
})

type FormattedAssetPriceChangeProps = {
  percentage: number
  colorize?: boolean
}

export const FormattedAssetPriceChange = ({
  percentage,
  colorize,
}: FormattedAssetPriceChangeProps) => {
  const theme = useTheme()

  const color =
    !colorize || percentage === 0
      ? undefined
      : percentage > 0
        ? theme.palette.success.light
        : theme.palette.error.light

  const factor = percentage / 100

  return (
    <Box component="span" color={color}>
      {priceChangeFormatter.format(factor)}
    </Box>
  )
}
