import {Core} from '@walletconnect/core'
import type {IWeb3Wallet, Web3WalletTypes} from '@walletconnect/web3wallet'
import {Web3Wallet} from '@walletconnect/web3wallet'

import {assert} from '../../utils/assertion'

import {SessionStorageKeyValueStorage} from './storage'

let web3wallet: IWeb3Wallet | undefined

export const walletConnect = {
  instance: () => {
    assert(!!web3wallet)
    return web3wallet
  },
  init: async (
    projectId: string,
    metadata: Web3WalletTypes.Options['metadata'],
  ) => {
    const core = new Core({
      // logger: 'debug', // TODO: only for development, remove
      projectId,
      // use in-memory storage so that multiple WalletConnect
      // window instances' storage doesn't class, an additional benefit
      // being privacy preservation after closing the NuFi app
      storage: new SessionStorageKeyValueStorage(),
    })

    web3wallet = await Web3Wallet.init({
      core,
      metadata,
    })
    return web3wallet
  },
}
