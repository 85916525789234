import type {
  CardanoTxPlan,
  CardanoSignedData,
  CardanoSigningResult,
  RawSignedVote,
  Vote,
} from '@nufi/wallet-cardano'
import type {FlowTxSignature} from '@nufi/wallet-flow'
import type {
  Lamports,
  SolanaMessageSignature,
  SolanaTransaction,
  ParsedInstruction,
} from '@nufi/wallet-solana'
import create from 'zustand'
import type {SetState, StateCreator, StoreApi, UseBoundStore} from 'zustand'
import {subscribeWithSelector} from 'zustand/middleware'

import {getMockServices} from '../__tests__/storybook/MockServiceLocator'
import type {CardanoVotingCredentials} from '../dappConnector/api/cardano/types'
import type {EvmCustomSignOptions} from '../dappConnector/pages/sign/customBlockchains/evm'
import type {ScanDomainWarning} from '../dappConnector/scanDomain/blowfish'
import type {ConnectorBlockchain} from '../dappConnector/types'
import type {
  EvmAccountOfflineInfo,
  EvmBlockchain,
  EvmContractAddress,
  EvmTransactionHash,
  EvmUnsignedTransaction,
  EvmWei,
  TokenStandard,
} from '../wallet/evm'
import type {
  EvmSignedPersonalData,
  EvmSignedTypedData,
} from '../wallet/evm/cryptoProviders/types'
import type {AccountInfo, AccountOfflineInfo, HexString} from '../wallet/types'
import type {WalletConnectError} from '../walletConnect/core/hooks'
import type * as Flow from '../webextension/dappConnector/connectors/flow/types'

import logger from './logger'

export type SigningKind =
  | 'sign-tx'
  | 'sign-message'
  | 'sign-vote'
  | 'get-hw-wallet-public-keys'

export type SolanaSignTxResult = {
  signedTx: SolanaTransaction
  forceTxSending: boolean
}

export type SignScreenResponse<Kind extends SigningKind> = {
  'sign-tx':
    | CardanoSigningResult
    | SolanaSignTxResult
    | FlowTxSignature
    | EvmTransactionHash
  'sign-message':
    | CardanoSignedData
    | SolanaMessageSignature
    | FlowTxSignature
    | EvmSignedPersonalData
    | EvmSignedTypedData
  'sign-vote': RawSignedVote[]
  'get-hw-wallet-public-keys': CardanoVotingCredentials
}[Kind]

type BlockchainSignData<T extends ConnectorBlockchain, D = unknown> = {
  type: T
  data: D
}

export type SignScreenData<Kind extends SigningKind> = {
  'sign-tx':
    | BlockchainSignData<'cardano', {txPlan: CardanoTxPlan; rawTx?: string}>
    | BlockchainSignData<
        'solana',
        {
          tx: SolanaTransaction
          rawTx: Buffer
          parsedInstructions: ParsedInstruction[]
          estimatedFee: Lamports | null
          requestType: 'sign' | 'signAndSend'
        }
      >
    | BlockchainSignData<'flow', {signable: Flow.Signable}>
    | BlockchainSignData<EvmBlockchain, {txParams: EvmUnsignedTransaction}>
  'sign-message':
    | BlockchainSignData<'cardano', {messageHex: HexString}>
    | BlockchainSignData<'solana', {messageHex: HexString}>
    | BlockchainSignData<'flow', {messageHex: HexString}>
    | BlockchainSignData<
        EvmBlockchain,
        | {messageHex: HexString; type: 'hex'}
        | {messagesTyped: Record<string, unknown>[]; type: 'typed'}
      >
  'sign-vote': BlockchainSignData<'cardano', {votes: Vote[]}>
  'get-hw-wallet-public-keys': BlockchainSignData<'cardano', undefined>
}[Kind]

type EvmSignScreenSignTxExtraOptions<TBlockchain extends EvmBlockchain> =
  BlockchainSignData<
    TBlockchain,
    {
      gasOptions: EvmCustomSignOptions<TBlockchain>
      fee: EvmWei<TBlockchain>
    }
  >

export type SignScreenExtraOptions<Kind extends SigningKind> = {
  'sign-tx':
    | EvmSignScreenSignTxExtraOptions<EvmBlockchain>
    | BlockchainSignData<'solana', {forceTxSending: boolean}>
  'sign-message': undefined
  'sign-vote': undefined
  'get-hw-wallet-public-keys': undefined
}[Kind]

export type CreateCardanoCollateralScreenData = {
  txPlan: CardanoTxPlan
  rawTx: string
}

export type ChangeEvmChainScreenData = {
  currentChainId: string
  proposedChainId: string
}

type SplashScreenInfo = {
  // Initial state that handles no interactions, and is only used
  // as a placeholder state before transition to "init" with a proper "onFinish"
  // callback is called.
  // There is no return to this state.
  state: 'splash'
}

type InitScreenInfo = {
  // Handles profile & account selection. Once completed the app goes to the "idle" state.
  state: 'init'
  onFinish: (selectedAccount: AccountInfo) => void
  onFailure: () => void
}

type IdleScreenInfo = {
  // Handles "waiting" state. When in "idle" the extension window is meant to be minimized and
  // shows info about currently selected profile/account.
  // After interactions (init/sign) are completed, the app returns to "idle" state.
  state: 'idle'
}

export type SignScreenInfo<Kind extends SigningKind> = {
  state: Kind
  data: SignScreenData<Kind>
  // Handles tx signing. After completion the app transitions to "idle" state.
  onSign: (
    customSignData: SignScreenExtraOptions<Kind>,
  ) => Promise<{onFinish: () => void}>
  onFailure: () => void
}

export type CreateCardanoCollateralScreenInfo = {
  // Handles requesting user for creating Cardano collateral.
  // After completion the app transitions to "idle" state.
  state: 'create-cardano-collateral'
  data: CreateCardanoCollateralScreenData
  onCreated: () => void
  onRejected: () => void
}

export type ChangeEvmChainScreenInfo = {
  state: 'change-evm-chain'
  data: ChangeEvmChainScreenData
  onChange: (
    blockchain: EvmBlockchain,
    account: EvmAccountOfflineInfo<EvmBlockchain>,
  ) => void
  onRejected: () => void
}

type ErrorScreenInfo = {
  state: 'error'
  error?: WalletConnectError
}

// See
// https://docs.metamask.io/guide/rpc-api.html#parameters-6
// https://eips.ethereum.org/EIPS/eip-747
export type ImportEvmTokenParams<TBlockchain extends EvmBlockchain> = {
  // Note that according to https://eips.ethereum.org/EIPS/eip-747 the ERC20 is more legacy and we should also take a look
  // as EIP-1046 https://eips.ethereum.org/EIPS/eip-1046
  type: TokenStandard
  options: {
    address: EvmContractAddress<TBlockchain>
    symbol?: string // ticker
    chainId?: number // See: https://eips.ethereum.org/EIPS/eip-747
    decimals?: number
    // Not explicitly supported by metamask, but we expose it, as we allow setting
    // token name when importing token in-app.
    name?: string
    //
    // Note that this is the property that metamask explicitly support. However,
    // we ignore it until it is also part of our in-app token import flow.
    // image?: string
  } & Partial<Record<string, string | number>>
}

export type ImportEvmAssetScreenInfo<
  TBlockchain extends EvmBlockchain = EvmBlockchain,
> = {
  state: 'import-evm-token'
  data: ImportEvmTokenParams<TBlockchain>
  onImport: () => void
  onRejected: () => void
}

export type CommonScreenInfo =
  | SignScreenInfo<SigningKind>
  | CreateCardanoCollateralScreenInfo

export type ScreenInfo =
  | SplashScreenInfo
  | InitScreenInfo
  | IdleScreenInfo
  | SignScreenInfo<SigningKind>
  | CreateCardanoCollateralScreenInfo
  | ChangeEvmChainScreenInfo
  | ImportEvmAssetScreenInfo
  | ErrorScreenInfo

type OverlayDialogState = 'none' | 'settings' | 'origin-warnings'

export type SetCreateCardanoCollateralScreen = (
  params: Omit<CreateCardanoCollateralScreenInfo, 'state'>,
) => void

export type CoreDappConnectorState<B, T> = {
  screenInfo: T
  blockchain: B | null
  // Even though we actually store the whole `AccountInfo`, we want to avoid
  // using its network properties from `zustand` as they might be stale.
  // Thus we declare this type as `AccountOfflineInfo`.
  // For network properties we use `react-query` or we fetch them on demand.
  selectedAccount: AccountOfflineInfo | null
  setBlockchain: (blockchain: B) => void
  setSignScreen: <Kind extends SigningKind>(
    screenInfo: SignScreenInfo<Kind>,
  ) => void
  setDefaultScreen: () => void
  setCreateCardanoCollateralScreen: SetCreateCardanoCollateralScreen
  setSelectedAccount: (account: AccountOfflineInfo) => void
  favIconUrl: string | null
  origin: string | null
}

// TODO: extract to `dappConnector` folder
export const createCoreDappConnectorStateHandlers = <B, T>({
  set,
  defaultScreenInfo,
  initialScreenInfo,
}: {
  set: SetState<CoreDappConnectorState<B, T>>
  initialScreenInfo: T
  defaultScreenInfo: T
}): ReturnType<StateCreator<CoreDappConnectorState<B, T>>> => ({
  blockchain: null,
  screenInfo: initialScreenInfo,
  selectedAccount: null,
  favIconUrl: null,
  origin: null,
  setBlockchain: (blockchain) => set({blockchain}),
  setSelectedAccount: (selectedAccount) => set({selectedAccount}),
  setDefaultScreen: () => {
    set({
      screenInfo: defaultScreenInfo,
    })
  },
  setSignScreen: (screenInfo) => {
    // (Richard) Why do we perform the assertion below?
    // The argument of type `SignScreenInfo<Kind>` can not be safely
    // casted to `SignScreenInfo<SigningKind>`. The `Kind` is by nature
    // dynamic, but `ScreenInfo/State` definition is static. This causes
    // issues when inferring types of functions that use generic type as their
    // argument (e.g. `onSign`).
    // An alternative could be having whole `State` generic, but I was not able
    // to do this successfully and it felt just like moving the same problem to a different place.
    set({screenInfo: screenInfo as unknown as T})
  },
  setCreateCardanoCollateralScreen: (params) =>
    set({screenInfo: {state: 'create-cardano-collateral', ...params} as T}),
})

type State = CoreDappConnectorState<ConnectorBlockchain, ScreenInfo> & {
  rememberLogin: boolean | null
  setRememberLogin: (rememberLogin: boolean) => void
  screenInfo: ScreenInfo
  setInitScreen: (
    params: {
      blockchain: ConnectorBlockchain
      origin: string
      favIconUrl: string | null
    } & Omit<InitScreenInfo, 'state'>,
  ) => void
  setIdleScreen: () => void
  setChangeEvmChainScreen: (
    params: Omit<ChangeEvmChainScreenInfo, 'state'>,
  ) => void
  changeEvmChain: (params: {
    blockchain: EvmBlockchain
    account: AccountOfflineInfo
  }) => void
  changeEvmChainAndSetIdleScreen: (params: {
    blockchain: EvmBlockchain
    account: AccountOfflineInfo
  }) => void
  setImportEvmTokenScreen: (
    params: Omit<ImportEvmAssetScreenInfo, 'state'>,
  ) => void
  overlayDialog: OverlayDialogState
  setOverlayDialog: (state: OverlayDialogState) => void
  setErrorScreen: (error?: ErrorScreenInfo['error']) => void
  originWarnings: ScanDomainWarning[] | null
  setOriginWarnings: (warnings: ScanDomainWarning[]) => void
}

export const useDappConnectorStore = create(
  subscribeWithSelector<State>(
    logger<State>('DappConnectorState:')((set) => ({
      ...createCoreDappConnectorStateHandlers({
        set: set as unknown as SetState<
          CoreDappConnectorState<ConnectorBlockchain, ScreenInfo>
        >,
        initialScreenInfo: {state: 'splash'},
        defaultScreenInfo: {state: 'idle'},
      }),
      rememberLogin: null,
      setRememberLogin: (rememberLogin: boolean) => set({rememberLogin}),
      overlayDialog: 'none',
      setInitScreen: ({
        blockchain,
        favIconUrl,
        origin,
        onFinish,
        onFailure,
      }) => {
        set({
          blockchain,
          selectedAccount: null,
          favIconUrl,
          screenInfo: {
            state: 'init',
            onFinish,
            onFailure,
          },
          origin,
        })
      },
      setIdleScreen: () => set({screenInfo: {state: 'idle'}}),
      setChangeEvmChainScreen: (params) =>
        set({screenInfo: {state: 'change-evm-chain', ...params}}),
      changeEvmChainAndSetIdleScreen: (params) =>
        set({
          screenInfo: {state: 'idle'},
          selectedAccount: params.account,
          blockchain: params.blockchain,
        }),
      changeEvmChain: (params) =>
        set({selectedAccount: params.account, blockchain: params.blockchain}),
      setImportEvmTokenScreen: (params) =>
        set({screenInfo: {state: 'import-evm-token', ...params}}),
      setOverlayDialog: (state) => set({overlayDialog: state}),
      setErrorScreen: (error) => {
        set({screenInfo: {state: 'error', error}})
      },
      originWarnings: null,
      setOriginWarnings: (warnings: ScanDomainWarning[]) =>
        set({originWarnings: warnings}),
    })),
  ),
) satisfies UseBoundStore<State, StoreApi<State>>

export const useDappConnectorOriginInfo = () => {
  const mockServices = getMockServices()
  if (mockServices) {
    return mockServices.useDappConnectorOriginInfo()
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const state = useDappConnectorStore()

  return {
    origin: state.origin,
    faviconUrl: state.favIconUrl,
  }
}
