import {Box} from '@mui/material'
import {assert} from '@nufi/frontend-common'
import type {CardanoPaymentAddress, Lovelaces} from '@nufi/wallet-cardano'
import BigNumber from 'bignumber.js'
import React, {Suspense, lazy, useState} from 'react'

import {QueryGuard} from 'src/components'
import {SignScreen} from 'src/dappConnector/pages/sign'
import type {SignScreenInfo, SigningKind} from 'src/store/dappConnector'
import {dappLayoutProps} from 'src/widget/pages/utils'

import {signAndSubmitCardanoTxWithConnector} from '../../application'
import {MOONPAY_COIN_TO_BLOCKCHAIN} from '../../infrastructure/moonpay/constants'
import {isMoonpaySupportedCoinCode} from '../../infrastructure/moonpay/helpers'
import type {RampFlowSellWidgetProps} from '../rampFlow/state/rampFlowConfig'
import {useRampFlowConfig} from '../rampFlow/state/rampFlowConfig'

import {MoonpayWidgetLoading} from './loading'
import type {MoonpaySellWidgetProps} from './widgets/MoonpaySellWidget'

export const importMoonpaySellWidget = () =>
  import(`./widgets/MoonpaySellWidget`)

export const MoonpayFlowSignerAndSeller = ({
  address,
  asset,
  isPasswordLogin,
}: RampFlowSellWidgetProps) => {
  const [signScreenContext, setSignScreenContext] =
    useState<SignScreenInfo<SigningKind> | null>(null)

  const {vm} = useRampFlowConfig()
  const accountsQuery = vm.useGetAccounts(asset.blockchain)

  return (
    <Suspense fallback={<MoonpayWidgetLoading />}>
      <QueryGuard {...accountsQuery} LoadingElement={<MoonpayWidgetLoading />}>
        {(accounts) => {
          const account = accounts.find((a) => a.address === address)
          assert(!!account)
          return (
            <>
              <LazyMoonpaySellWidget
                visible={!signScreenContext}
                accountAddress={address}
                cryptoCurrencyCode={asset.rampId}
                onInitiateDeposit={async (deposit) => {
                  assert(isAdaDeposit(deposit))
                  const txHash = await signAndSubmitCardanoTxWithConnector({
                    accountId: account!.id,
                    addressTo:
                      deposit.depositWalletAddress as CardanoPaymentAddress,
                    amount: new BigNumber(
                      deposit.cryptoCurrencyAmountSmallestDenomination,
                    ) as Lovelaces,
                    tokenAmounts: [],
                    setSignScreenContext,
                  })
                  return {depositId: txHash}
                }}
              />
              {signScreenContext && (
                <Box
                  sx={{
                    padding: 2,
                    width: '100%',
                    height: '100%',
                    overflow: 'auto',
                  }}
                >
                  <SignScreen
                    signState={{
                      selectedAccount: account!,
                      origin: 'https://sell.moonpay.com/',
                      favIconUrl: 'https://sell.moonpay.com/favicon.ico',
                      blockchain: 'cardano',
                      signData: signScreenContext,
                      layoutProps: dappLayoutProps,
                      hideProfileInfo: true,
                      enforceMnemonicPassword:
                        account.cryptoProviderType === 'mnemonic' &&
                        isPasswordLogin,
                    }}
                  />
                </Box>
              )}
            </>
          )
        }}
      </QueryGuard>
    </Suspense>
  )
}

const LazyMoonpaySellWidget = lazy(() =>
  importMoonpaySellWidget().then((mod) => ({default: mod.MoonpaySellWidget})),
)

const isAdaDeposit = (
  deposit: Parameters<MoonpaySellWidgetProps['onInitiateDeposit']>[0],
) => {
  const cryptoCurrencyCode = deposit.cryptoCurrency.code
  const isCoin = isMoonpaySupportedCoinCode(cryptoCurrencyCode)
  return isCoin && MOONPAY_COIN_TO_BLOCKCHAIN[cryptoCurrencyCode] === 'cardano'
}
