import React from 'react'

import {useSettings} from 'src/features/profile/application'

import {useAssetDetailRouteOptions} from '../../../router/portfolio'
import {
  AssetDetailInfoLayout,
  HistoricalConversionRates,
  News,
  TokenDetailInfoLayout,
} from '../assetDetail/Info'

export const AssetDetailInfo = () => {
  const {currency} = useSettings()
  const {tokenId, blockchain} = useAssetDetailRouteOptions()

  return tokenId ? (
    <TokenDetailInfoLayout
      renderNews={() => <News {...{blockchain}} />}
      tokenId={tokenId}
    />
  ) : (
    <AssetDetailInfoLayout
      renderHistoricalConversionRates={(blockchain) => (
        <HistoricalConversionRates
          {...{blockchain, currency}}
          defaultGranularity="1D"
        />
      )}
      renderNews={() => <News {...{blockchain}} />}
    />
  )
}
