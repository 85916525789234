import type {SwapStatus} from 'common'
import {useTranslation} from 'react-i18next'

export const isValidSwapAmount = (
  amount: string | null,
): amount is Exclude<string | null, null | undefined> =>
  // changelly can return amounts as an empty string or '0'
  // and just to be sure we also check for null if they ever
  // decide to change the empty string or '0' logic
  amount != null && amount !== '' && amount !== '0'

type StatusTranslations = {
  [S in SwapStatus]: string
}

export function useStatusTranslations(): StatusTranslations {
  const {t} = useTranslation()
  return {
    waiting: t('SwapStatus.WAITING_INTERNAL.stepLabel'),
    confirming: t('SwapStatus.CONFIRMING.stepLabel'),
    exchanging: t('SwapStatus.EXCHANGING.stepLabel'),
    sending: t('SwapStatus.SENDING.stepLabel'),
    finished: t('SwapStatus.FINISHED.stepLabel'),
    failed: t('SwapStatus.FAILED.title'),
    refunded: t('SwapStatus.REFUNDED.title'),
    hold: t('SwapStatus.HOLD.title'),
    expired: t('SwapStatus.EXPIRED.title'),
    waiting_expired: t('SwapStatus.EXPIRED.title'),
    overdue: t('SwapStatus.EXPIRED.title'),
  }
}
