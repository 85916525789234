import {safeAssertUnreachable} from '@nufi/frontend-common'
import BigNumber from 'bignumber.js'
import _ from 'lodash'
import {useMemo, useRef} from 'react'

import {useGetConversionRates} from 'src/features/conversionRates/application'
import type {Currency} from 'src/features/conversionRates/domain'
import {unzipPairs} from 'src/utils/helpers'

import {getBlockchainDecimals} from '../../constants'
// assets loaders
import {useMemoCompare, useJsonMemoCompare} from '../../utils/general'
import {getAvailableEvmBlockchains} from '../../wallet/evm'
import type {Blockchain} from '../../wallet/types'
import {isBlockchainAvailable} from '../availableBlockchains/application'
import {useGetIsBlockchainEnabled} from '../profile/application'
import {
  updateWarnings,
  CommonAppWarnings,
  CommonAppWarningsKeys,
} from '../warnings/domain'

import {useCardanoTokens} from './blockchains/cardano'
import {useEvmTokens} from './blockchains/evm'
import {useFlowTokens} from './blockchains/flow'
import {useSolanaTokens} from './blockchains/solana'
import {useLoadCoin} from './loadCoin'
import type {
  AssetRow,
  UseBlockchainAssetsReturnType,
  UseAssetsReturnType,
  AssetsSortBy,
  AssetType,
  BlockchainBoundedError,
} from './types'

// assets loaders

export * from './types'
export {
  getCardanoTokenSortingLabel,
  getCardanoTokenLabelStructure,
} from './blockchains/cardano'
export {getSolanaTokenSortingLabel} from './blockchains/solana'
export {getFlowTokenSortingLabel} from './blockchains/flow'
export {getEvmTokenSortingLabel} from './blockchains/evm'

export type SortDirection = 'asc' | 'desc'

export function useAssets(
  currency: Currency,
  sortBy: AssetsSortBy,
  direction: SortDirection,
): UseAssetsReturnType {
  const conversionRates = useGetConversionRates()
  const isBlockchainEnabled = useGetIsBlockchainEnabled()

  const getCommonArgs = (blockchain: Blockchain) => ({
    conversionRates,
    currency,
    enabled: isBlockchainEnabled(blockchain),
  })

  const queries = [
    /* eslint-disable react-hooks/rules-of-hooks */
    ...(isBlockchainAvailable('cardano')
      ? [
          useLoadCoin('cardano', getCommonArgs('cardano')),
          useCardanoTokens(getCommonArgs('cardano')),
        ]
      : []),
    ...(isBlockchainAvailable('solana')
      ? [
          useLoadCoin('solana', getCommonArgs('solana')),
          useSolanaTokens(getCommonArgs('solana')),
        ]
      : []),
    ...(isBlockchainAvailable('flow')
      ? [
          useLoadCoin('flow', getCommonArgs('flow')),
          useFlowTokens(getCommonArgs('flow')),
        ]
      : []),
    /* eslint-enable react-hooks/rules-of-hooks */
    ...getAvailableEvmBlockchains()
      .map((evmBlockchain) => [
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useLoadCoin(evmBlockchain, getCommonArgs(evmBlockchain)),
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEvmTokens(evmBlockchain, getCommonArgs(evmBlockchain)),
      ])
      .flat(),
  ]

  const partialLoadings = useGetLoadingBlockchains(queries)
  const errors = useGetErrors(queries)
  const data = useFlattenedData(queries)
  const sortedData = useSortedData(data, sortBy, direction)

  const warnings = errors.map((error) => {
    const {type} = error
    switch (type) {
      case 'native':
        return CommonAppWarnings.NATIVE_ASSET_FAILURE(error.blockchain)
      case 'tokens':
        return CommonAppWarnings.TOKENS_FAILURE(error.blockchain)
      default:
        return safeAssertUnreachable(type)
    }
  })

  updateWarnings(warnings, [
    // Ensure that previous errors are fully unpublished
    CommonAppWarningsKeys.NATIVE_ASSET_FAILURE,
    CommonAppWarningsKeys.TOKENS_FAILURE,
  ])

  return {partialLoadings, data: sortedData, errors}
}

function useSortedData(
  data: AssetRow[],
  sortBy: AssetsSortBy,
  direction: SortDirection,
): AssetRow[] {
  return useMemo(() => {
    const sanitizeAmount = (v?: number) =>
      v == null || Number.isNaN(v) ? -1 : v

    /** This helps us put positive token amounts above zero fiat amounts as in `sortTokensByValue` */
    const coerceFiatBalance = (v?: number) => v ?? 0

    const divideByDecimals = (v: BigNumber, decimals: number) => {
      if (decimals === 0) return v.toNumber()
      return v.dividedBy(new BigNumber(10).pow(decimals)).toNumber()
    }

    type CompareBy = (row: AssetRow) => number | string

    const compareAmount: CompareBy = (row) => {
      if (!row.tokenMetadata) {
        const decimals = getBlockchainDecimals(row.blockchain)
        return divideByDecimals(row.totalBalance, decimals)
      }

      const decimals = row.tokenMetadata?.decimals || 0
      return divideByDecimals(row.totalBalance, decimals)
    }

    const compareLabel: CompareBy = (row) =>
      row.sortingLabel.toLocaleLowerCase()

    const compareAvailableFiat: CompareBy = (row) => {
      const amount =
        row.availableBalanceToCurrency?.type === 'success'
          ? row.availableBalanceToCurrency.balance
          : undefined
      return sanitizeAmount(coerceFiatBalance(amount))
    }

    const compareTotalFiat: CompareBy = (row) => {
      const amount =
        row.totalBalanceToCurrency?.type === 'success'
          ? row.totalBalanceToCurrency.balance
          : undefined
      return sanitizeAmount(coerceFiatBalance(amount))
    }

    const comparePrice: CompareBy = (row) => {
      const amount =
        row.price?.type === 'success' ? row.price.balance : undefined
      return sanitizeAmount(amount)
    }

    const compareAccountsCount: CompareBy = (row) => row.owningAccountsCount

    const comparatorsWithDirections = ((): [CompareBy, SortDirection][] => {
      switch (sortBy) {
        case 'availableBalanceToCurrency':
          return [
            [compareAvailableFiat, direction],
            [compareAmount, direction],
            [compareAccountsCount, direction],
            [compareLabel, 'asc'],
          ]
        case 'totalBalanceToCurrency':
          return [
            [compareTotalFiat, direction],
            [compareAmount, direction],
            [compareAccountsCount, direction],
            [compareLabel, 'asc'],
          ]
        case 'owningAccountsCount':
          return [
            [compareAccountsCount, direction],
            [compareTotalFiat, direction],
            [compareAmount, direction],
            [compareLabel, 'asc'],
          ]
        case 'sortingLabel':
          return [[compareLabel, direction]]
        case 'price':
          return [[comparePrice, direction]]
        default:
          return safeAssertUnreachable(sortBy)
      }
    })()

    const [comparators, directions] = unzipPairs(comparatorsWithDirections)
    return _.orderBy(data, comparators, directions)
  }, [data, sortBy, direction])
}

function useGetErrors(
  queries: UseBlockchainAssetsReturnType[],
): BlockchainBoundedError[] {
  type BlockchainBoundedError = {type: AssetType; blockchain: Blockchain}
  const previousErrors = useRef<BlockchainBoundedError[]>([])

  const errors: BlockchainBoundedError[] = useJsonMemoCompare(
    queries
      .filter(({data, hasError, isLoading, blockchain, assetType}) => {
        // TMP fix
        // We are often experiencing 429 for some chains on refresh. Therefore we
        // only report error if not having any data.
        return (
          (data.length === 0 && hasError) ||
          // We are using this condition as ReactQuery is not preserving "error" state
          // on refresh. Though we prefer to keep it until we are sure that we loaded some
          // data. Otherwise we would have "flashing UI" due to removing some error card
          // just to show it again in few seconds and management of Warnings gets even more tricky.
          // The better alternative might be to wrap `useQuery` custom hook that would enforce
          // such behavior for all queries across the app.
          (isLoading &&
            // When `isLoading` is `true` it means that there are no data.
            previousErrors.current.some(
              (pe) => pe.blockchain === blockchain && pe.type === assetType,
            ))
        )
      })
      .map(({assetType, blockchain}) => ({
        type: assetType,
        blockchain,
      }))
      .filter(({type, blockchain}, i, arr) => {
        if (type === 'native') return true
        if (type === 'tokens') {
          return !arr.some(
            (q) => q.blockchain === blockchain && q.type === 'native',
          )
        }
        return true
      }),
  )

  previousErrors.current = errors

  return errors
}

function useGetLoadingBlockchains(
  queries: UseBlockchainAssetsReturnType[],
): Blockchain[] {
  return useJsonMemoCompare(
    _.uniq(
      // Avoid displaying loading state in case of error, as queries are by default
      // retried indefinitely
      queries
        .filter((q) => q.isLoading && !q.hasError)
        .map((q) => q.blockchain),
    ),
  )
}

function useFlattenedData(
  queries: UseBlockchainAssetsReturnType[],
): AssetRow[] {
  // Combine `queries.data` into single array and keep the reference
  // if only `[]` (empty-array) change for `[]` with new reference
  // Note: we could instead just use `useJsonMemoCompare` but current approach should be
  // more performant if we deal with really a lot of tokens
  const _data = useMemoCompare(
    queries.map(({data}) => data),
    (prev, next) =>
      _.zip(prev, next).every((zipped) => {
        // Keep previous value if only empty array references changed
        if (zipped[0]?.length === 0 && zipped[1]?.length === 0) return true
        // Otherwise compare arrays by reference
        return zipped[0] === zipped[1]
      }),
  )

  return useMemo(() => _.flatten(_data), _data)
}
