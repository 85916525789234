import type {CardMediaProps} from '@mui/material'
import {Link, CardMedia, styled, Box} from '@mui/material'
import {getMediaUri, isImageData} from '@nufi/frontend-common'
import React from 'react'

import {nftBlockchains} from '../../../blockchainTypes'
import type {Nft} from '../../../types'
import {assert, safeAssertUnreachable} from '../../../utils/assertion'
import {isBlockchainSubset} from '../../../utils/blockchainGuards'
import {isEvmBlockchain} from '../../../wallet/evm'
import {openImageData} from '../../../wallet/utils/nfts'
import {HiddenNftImage} from '../../nfts/hiddenNfts'

const ImageNftCard = ({mediaUrl}: {mediaUrl: string}) => {
  return isImageData(mediaUrl) ? (
    <ImageCard mediaUrl={mediaUrl} onClick={() => openImageData(mediaUrl)} />
  ) : (
    <Link href={mediaUrl} rel="noopener" target="_blank">
      <ImageCard mediaUrl={mediaUrl} />
    </Link>
  )
}

export const NftMedia = ({nft}: {nft: Nft}) => {
  assert(isBlockchainSubset(nft.blockchain, nftBlockchains))

  if (nft.isHidden) {
    return (
      <Box height={IMAGE_HEIGHT_PX}>
        <HiddenNftImage />
      </Box>
    )
  }

  switch (nft.blockchain) {
    case 'cardano':
      return nft.image ? (
        <ImageNftCard mediaUrl={getMediaUri({url: nft.image})} />
      ) : null
    case 'solana':
      return nft.image ? <ImageNftCard mediaUrl={nft.image} /> : null
    case 'flow': {
      const videoUri = nft.media.videoMedia?.uri
      if (videoUri) {
        return <VideoNftCard src={getMediaUri({url: videoUri})} />
      }
      // || '' is fine because if the uri is undefined, there is nothing to display anyway
      const imageUri = getMediaUri({url: nft.media.imageMedia?.uri || ''})
      if (imageUri) {
        return <ImageNftCard mediaUrl={imageUri} />
      }
      return null
    }
    default: {
      if (isEvmBlockchain(nft.blockchain)) {
        return nft.media.uri ? <ImageNftCard mediaUrl={nft.media.uri} /> : null
      }
      return safeAssertUnreachable(nft.blockchain)
    }
  }
}

type ImageCardProps = {
  mediaUrl: string
  onClick?: () => void
}

const IMAGE_HEIGHT_PX = 500
const ImageCard = styled('div')<ImageCardProps>(({theme, mediaUrl}) => ({
  width: '100%',
  height: IMAGE_HEIGHT_PX,
  marginBottom: theme.spacing(2),
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  backgroundPosition: 'center',
  backgroundColor: theme.palette.background.paper,
  borderRadius: 5,
  cursor: 'pointer',
  backgroundImage: `url("${mediaUrl}")`,
}))

const VideoNftCard = styled(CardMedia)<
  CardMediaProps<'video', {component?: 'video'}>
>(({theme}) => ({
  width: '100%',
  marginBottom: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
}))

VideoNftCard.defaultProps = {
  component: 'video',
  controls: true,
  muted: true,
  loop: true,
  autoPlay: true,
}
