import type {SwapAsset} from 'common'

import {useMutation} from 'src/utils/mutation-utils'

import type {ExchangeConditions} from '../domain'

import {exchangeApi} from './exchangeApi'

export function useExchangeCreateSwap() {
  return useMutation(
    'exchangeCreateSwap',
    async ({
      from,
      to,
      address,
      amountFrom,
      refundAddress,
      extraId,
    }: {
      from: SwapAsset
      to: SwapAsset
      address: string
      amountFrom: ExchangeConditions['amountFrom']
      refundAddress: string | null
      extraId: string | null
    }) =>
      await exchangeApi.createSwap(
        from,
        to,
        address,
        amountFrom,
        refundAddress,
        extraId,
      ),
  )
}
