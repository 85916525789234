import {getIsWebExtension} from '@nufi/frontend-common'
import queryString from 'query-string'
import {matchPath} from 'react-router-dom'

import config from '../../config'
import {routeTo} from '../../router'

const _getAllPathsWithParams = (
  current: Record<string, unknown> | string | unknown,
  res: Array<string>,
) => {
  if (typeof current === 'string') {
    // routes with query params are handled separately so we dismiss them
    if (
      !config.isProd &&
      current.includes('undefined') &&
      !current.includes('?')
    ) {
      // NOTE(Peter): all paths defined in router has to have a default value
      // so we can call current() without arguments
      throw new Error(
        `Route ${current} is defined without default param, add default value for route param.`,
      )
    }
    // react-router param placeholders are prefixed with ":"
    if (current.includes(':')) {
      res.push(current)
    }
  }
  if (typeof current === 'object' && current) {
    Object.values(current).forEach((c) => _getAllPathsWithParams(c, res))
  }
  if (typeof current === 'function') {
    _getAllPathsWithParams(current(), res)
  }
}

const pathsWithParams: Array<string> = []
_getAllPathsWithParams(routeTo, pathsWithParams)

// these params don't need to be and replaced
const allowedUrlParamKeys = ['blockchain', 'tab', 'cryptoProviderType']

const shouldKeepParam = (paramKey: string) =>
  allowedUrlParamKeys.includes(paramKey)

const shouldKeepParamQuery = (paramQuery: string) =>
  // remove all query params
  !paramQuery

// since we have accountId and other params in the url, we must remove them
// so they don't create unique urls for each user and they don't pollute the stats
// we either remove them or replace them with generic string in form of ":{param_name}"
export function removeUrlParams(url: string) {
  const queryParams = queryString.parse(location.search)
  const queryParamsStrings = Object.values(queryParams)
    .flat()
    .flatMap((p) => (p ? encodeURIComponent(p) : []))

  // removes query params from the url
  const locationWithoutQueryParams = queryParamsStrings.reduce(
    (acc, curr) => (shouldKeepParamQuery(curr) ? acc : acc.replace(curr, '')),
    url,
  )

  const pathToMatch = getIsWebExtension()
    ? window.location.hash.split('#')[1] ?? ''
    : window.location.pathname

  // matches current path to the paths with params in order the get current params
  // https://github.com/remix-run/react-router/issues/7026
  const match = matchPath(pathToMatch, {
    path: pathsWithParams,
    exact: true,
  })

  // takes params from the current url and replaces them with :param_name
  return match?.params
    ? Object.entries(match.params).reduce(
        (acc, [key, value]) =>
          shouldKeepParam(key) ? acc : acc.replace(value as string, `:${key}`),
        locationWithoutQueryParams,
      )
    : locationWithoutQueryParams
}

export const getPathWithoutParams = () => {
  // we add `ext|` and `wallet|` prefixes to the urls to be able to to differentiate the easily in GA
  if (getIsWebExtension()) {
    return `ext|${removeUrlParams(window.location.hash.split('#')[1]!)}`
  }
  return `wallet|${removeUrlParams(window.location.pathname)}`
}
