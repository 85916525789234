import {
  Warning as WarningIcon,
  Cancel as UndelegateIcon,
  AttachMoney as WithdrawIcon,
  PlayCircleOutline as ActivateStakingIcon,
} from '@mui/icons-material'
import {
  Box,
  Typography,
  Link as MuiLink,
  LinearProgress,
  Avatar,
  Grid,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import type {
  SolanaStakeAccountInfo,
  SolanaStakeActivation,
  SolanaValidatorInfo,
} from '@nufi/wallet-solana'
import {formatDistanceToNow, isBefore} from 'date-fns'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'

import {useGetEpochScheduleEstimate} from 'src/wallet/solana'

import type {ActivationIconType} from '../../../../components'
import {
  QueryGuard,
  FormattedDateTime,
  FormattedAsset,
  ActivationIcon,
  IconCol,
  Modal,
  NaError,
  ValidatorExplorerLink,
  LabeledIconWithTooltip,
  SplitButton,
  getSolanaStakeAccountLink,
  getSolanaStakeAccountRewardsLink,
} from '../../../../components'
import {routeTo} from '../../../../router'
import type {SupportedLanguage} from '../../../../translations/supportedLanguages'
import {toDateFnsLocale} from '../../../../translations/utils'
import type {AccountId} from '../../../../types'
import {StakeAccountCardWrapper} from '../../common/overview/StakeAccountCardUtils'
import UndelegateModal from '../actionModals/UndelegateModal'
import {stakeActivationToRewardDate} from '../utils'

import {StakeAccountListItemLayout} from './StakeAccountListLayout'

export default function StakeAccountCard({
  stakeAccount,
  validatorInfo,
  accountId,
}: {
  stakeAccount: SolanaStakeAccountInfo
  validatorInfo: SolanaValidatorInfo | undefined
  accountId: AccountId
}) {
  const epochScheduleEstimateQuery = useGetEpochScheduleEstimate()
  const {t} = useTranslation()

  const creationDate = stakeAccount.creationDate

  const activationIconTypes: {
    [key in SolanaStakeActivation]: ActivationIconType
  } = {
    inactive: 'gray',
    active: 'green',
    activating: 'yellow',
    deactivating: 'gray',
  }

  return (
    <StakeAccountCardWrapper>
      <StakeAccountListItemLayout
        tableBodyStyles
        creationDateCol={
          creationDate ? (
            <IconCol
              Icon={
                <ActivationIcon
                  type={activationIconTypes[stakeAccount.state]}
                />
              }
              title={
                <MuiLink
                  onClick={(event) => event.stopPropagation()}
                  onFocus={(event) => event.stopPropagation()}
                  target="_blank"
                  href={getSolanaStakeAccountLink(
                    stakeAccount.publicKey.toString(),
                  )}
                >
                  <FormattedDateTime dateTime={creationDate} />
                </MuiLink>
              }
              description={<StateCol state={stakeAccount.state} />}
            />
          ) : (
            t('N/A')
          )
        }
        stakingBalanceCol={
          <Typography variant="body2">
            <FormattedAsset
              amount={stakeAccount.balance}
              blockchain="solana"
              isSensitiveInformation
            />
          </Typography>
        }
        rewardBalanceCol={
          <MuiLink
            target="_blank"
            href={getSolanaStakeAccountRewardsLink(
              stakeAccount.publicKey.toString(),
            )}
          >
            <Typography variant="body2">
              <FormattedAsset
                amount={stakeAccount.recentRewards}
                blockchain="solana"
                isSensitiveInformation
              />
            </Typography>
          </MuiLink>
        }
        validatorCol={
          <Grid container columnSpacing={1} wrap="nowrap" alignItems="center">
            {validatorInfo?.metadata?.avatarUrl && (
              <Grid item>
                <Avatar
                  sx={{width: 24, height: 24}}
                  variant="rounded"
                  alt="Validator Avatar"
                  src={validatorInfo.metadata.avatarUrl}
                />
              </Grid>
            )}
            {stakeAccount.validator && (
              <Grid item>
                <ValidatorExplorerLink
                  blockchain="solana"
                  validatorId={stakeAccount.validator.toString()}
                  validatorName={
                    validatorInfo?.metadata?.name || t('Validator')
                  }
                />
              </Grid>
            )}
          </Grid>
        }
        nextRewardsCol={
          <QueryGuard {...epochScheduleEstimateQuery}>
            {(epochScheduleEstimate) => {
              const noRewardRender = <Typography />
              if (!epochScheduleEstimate) return noRewardRender
              const nextRewardDate = stakeActivationToRewardDate(
                stakeAccount.state,
                epochScheduleEstimate,
              )
              return nextRewardDate ? (
                <Typography variant="body2">
                  <FormattedDateTime dateTime={nextRewardDate} />
                </Typography>
              ) : (
                noRewardRender
              )
            }}
          </QueryGuard>
        }
        actionsCol={
          <SolanaStakeActionsMenu
            stakeAccount={stakeAccount}
            accountId={accountId}
          />
        }
      />
    </StakeAccountCardWrapper>
  )
}

function TimeInfo({children}: {children: string}) {
  return (
    <Typography variant="body2" component="span">
      <Box fontStyle="italic" ml={1} display="inline">
        ({children})
      </Box>
    </Typography>
  )
}

function StateChangeTimeInfo({date}: {date: Date}) {
  const now = new Date()
  const {i18n, t} = useTranslation()
  const formattedTimeDiff = formatDistanceToNow(date, {
    includeSeconds: true,
    addSuffix: false,
    locale: toDateFnsLocale(i18n.language as SupportedLanguage),
  })

  if (isBefore(date, now)) {
    return <UnknownStateChangeTime />
  }

  return <TimeInfo>{t('time_left', {timeDiff: formattedTimeDiff})}</TimeInfo>
}

function UnknownStateChangeTime() {
  const {t} = useTranslation()
  return <TimeInfo>{t('soon...')}</TimeInfo>
}

type FormattedStateProps = {
  state: SolanaStakeActivation
  extra?: React.ReactElement
}

function FormattedState({state, extra}: FormattedStateProps) {
  const {icon, label} = useFormattedStateStyles()
  const {t} = useTranslation()

  return (
    <>
      {state === 'inactive' ? (
        <LabeledIconWithTooltip
          Icon={<WarningIcon fontSize="small" className={icon} />}
          Label={
            <Typography variant="body2" className={label}>
              {state}
            </Typography>
          }
          title={
            <>
              {t(
                'Staking account inactive - activate the staking and select a validator or withdraw from staking account',
              )}
            </>
          }
          iconPosition="start"
        />
      ) : (
        <>
          <Typography variant="body2" component="span">
            {state}
          </Typography>
          {extra}
        </>
      )}
    </>
  )
}
const useFormattedStateStyles = makeStyles((theme) => ({
  label: {
    textTransform: 'uppercase',
  },
  icon: {
    color: theme.palette.warning.main,
  },
}))

function StateCol({state}: {state: SolanaStakeActivation}) {
  const epochScheduleEstimateQuery = useGetEpochScheduleEstimate()
  const renderTimeInfo = state === 'activating' || state === 'deactivating'
  const {t} = useTranslation()

  return (
    <QueryGuard
      {...epochScheduleEstimateQuery}
      LoadingElement={<LinearProgress />}
      ErrorElement={<NaError error={t('Could not load status')} />}
    >
      {(epochScheduleEstimate) => (
        <FormattedState
          {...{state}}
          extra={
            renderTimeInfo ? (
              epochScheduleEstimate ? (
                <StateChangeTimeInfo
                  date={epochScheduleEstimate.currentEpochEnd}
                />
              ) : (
                <UnknownStateChangeTime />
              )
            ) : undefined
          }
        />
      )}
    </QueryGuard>
  )
}

function SolanaStakeActionsMenu({
  stakeAccount,
  accountId,
}: {
  stakeAccount: SolanaStakeAccountInfo
  accountId: AccountId
}) {
  const history = useHistory()
  const {t} = useTranslation()
  const openActivateStakeModal = () =>
    history.push(
      routeTo.staking.myStaking.solana
        .account(accountId)
        .stakeAccount(stakeAccount.id).delegate,
    )
  const [showUndelegateModal, setShowUndelegateModal] = useState(false)

  const openUndelegateModal = () => {
    setShowUndelegateModal(true)
  }
  const closeUndelegateModal = () => {
    setShowUndelegateModal(false)
  }

  const openWithdrawModal = () =>
    history.push(
      routeTo.staking.myStaking.solana
        .account(accountId)
        .stakeAccount(stakeAccount.id).withdraw,
    )

  const enableUndelegate =
    stakeAccount.state === 'active' || stakeAccount.state === 'activating'
  const enableWithdraw = stakeAccount.state === 'inactive'
  const enableActivateStake =
    stakeAccount.state === 'inactive' || stakeAccount.state === 'activating'

  return (
    <>
      <SplitButton
        popperPlacement="bottom-start"
        options={[
          {
            label:
              stakeAccount.state === 'activating'
                ? t('Change validator')
                : t('Activate staking'),
            Icon: ActivateStakingIcon,
            onClick: openActivateStakeModal,
            disabled: !enableActivateStake,
          },
          {
            label: t('Stop staking'),
            Icon: UndelegateIcon,
            onClick: openUndelegateModal,
            disabled: !enableUndelegate,
          },
          {
            label: t('Withdraw'),
            Icon: WithdrawIcon,
            onClick: openWithdrawModal,
            disabled: !enableWithdraw,
          },
        ]}
      />
      {showUndelegateModal && (
        <Modal onClose={closeUndelegateModal} variant="left">
          <UndelegateModal
            onClose={closeUndelegateModal}
            stakeAccountId={stakeAccount.id}
          />
        </Modal>
      )}
    </>
  )
}
