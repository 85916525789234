import {Grid, Typography, Box, styled} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {Button} from '../../components'

export type FailureProps = {
  message: string
  onRetry?: () => Promise<unknown>
  HelpSection?: React.ReactNode
  Image?: React.ReactNode
}

export function Failure({message, onRetry, HelpSection, Image}: FailureProps) {
  const {t} = useTranslation()

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      direction="column"
    >
      <ErrorMessageWrapper>
        <Typography variant="h6" align="center" marginBottom={1}>
          {message}
        </Typography>
      </ErrorMessageWrapper>
      {Image && <ImgWrapper>{Image}</ImgWrapper>}
      <Box>
        <ErrorButtonWrapper>
          {onRetry && (
            <Button
              textTransform="none"
              variant="outlined"
              color="inherit"
              onClick={onRetry}
              sx={{minWidth: 80}}
            >
              {t('Retry')}
            </Button>
          )}
        </ErrorButtonWrapper>
        {HelpSection && <Box mt={2}>{HelpSection}</Box>}
      </Box>
    </Grid>
  )
}

const ErrorMessageWrapper = styled('div')(({theme}) => ({
  marginBottom: theme.spacing(1),
}))

const ErrorButtonWrapper = styled('div')(({theme}) => ({
  marginTop: theme.spacing(1),
  display: 'flex',
  justifyContent: 'center',
  color: theme.palette.error.main,
}))

const ImgWrapper = styled('div')(({theme}) => ({
  marginTop: theme.spacing(1),
  display: 'flex',
  justifyContent: 'center',
  marginBottom: theme.spacing(1),
}))
