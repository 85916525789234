import type {OutlinedTextFieldProps} from '@mui/material'
import {Typography, Box, Grid, TextField, Chip} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'

import {useGetBlockchainName} from 'src/utils/translations'

import type {AccountId, AddressType, Blockchain, TokenMetadata} from '../types'

import type {AccountSelectItem} from './AccountSelectField'
import {AccountSelectField, findItemByAccountId} from './AccountSelectField'
import {WithTooltip} from './visual'

export const extIntTestIds = {
  addressFieldExternal: 'address-field-external',
  addressFieldInternal: 'address-field-internal',
  setAddressFieldInternal: 'set-address-field-internal',
  setAddressFieldExternal: 'set-address-field-external',
}

// Note that single state wrapping both values was used to avoid nasty issues
// when debouncing `onChange` handler for only one of the values.
export type AccountAddressWithType = {address: string; addressType: AddressType}

export type ExternalOrInternalAddressFieldProps = {
  blockchain: Blockchain | null
  items: Array<AccountSelectItem>
  value: AccountAddressWithType
  onChange: (args: AccountAddressWithType) => unknown
  errorMessage?: string
  label: string
  chipLabel: string
  tokenMetadata?: TokenMetadata
  formFieldClass?: string
  hideAmount?: boolean
  renderExternalAddressField?: () => JSX.Element
  externalAddressInputProps?: OutlinedTextFieldProps['InputProps']
  testIds?: typeof extIntTestIds
  hideCopyAddress?: boolean
  disabled?: boolean
}

export function ExternalOrInternalAddressField({
  value,
  onChange,
  label,
  chipLabel,
  items,
  errorMessage,
  blockchain,
  tokenMetadata,
  formFieldClass,
  externalAddressInputProps,
  hideAmount = false,
  renderExternalAddressField,
  testIds,
  hideCopyAddress,
  disabled,
}: ExternalOrInternalAddressFieldProps) {
  const classes = useStyles()
  const {t} = useTranslation()
  const getBlockchainName = useGetBlockchainName()

  const _onAddressTypeChange = (type: AddressType) => {
    onChange({address: '', addressType: type})
  }

  const isInternalDisabled = items.length === 0

  useEffect(() => {
    if (isInternalDisabled && value.addressType === 'internal') {
      _onAddressTypeChange('external')
    }
  })

  return (
    <Box>
      <Grid container>
        <Grid item xs={12}>
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <Grid
                container
                spacing={2}
                alignItems="center"
                className={classes.header}
              >
                <Grid item>
                  <Typography variant="subtitle2">{chipLabel}</Typography>
                </Grid>
                <Grid item>
                  <Chip
                    variant={
                      value.addressType === 'external' ? 'filled' : 'outlined'
                    }
                    color={
                      value.addressType === 'external' ? 'primary' : 'default'
                    }
                    onClick={() => _onAddressTypeChange('external')}
                    label={t('External address')}
                    disabled={disabled}
                    rtl-data-test-id={
                      testIds?.setAddressFieldExternal ||
                      extIntTestIds.setAddressFieldExternal
                    }
                  />
                </Grid>
                <Grid item>
                  <WithTooltip
                    title={
                      blockchain != null && isInternalDisabled
                        ? t('no_accounts_or_disabled', {
                            blockchain: getBlockchainName(blockchain),
                          })
                        : undefined
                    }
                  >
                    <Chip
                      variant={
                        value.addressType === 'internal' ? 'filled' : 'outlined'
                      }
                      color={
                        value.addressType === 'internal' ? 'primary' : 'default'
                      }
                      onClick={() => _onAddressTypeChange('internal')}
                      label={t('My account')}
                      disabled={isInternalDisabled || disabled}
                      rtl-data-test-id={
                        testIds?.setAddressFieldInternal ??
                        extIntTestIds.setAddressFieldInternal
                      }
                    />
                  </WithTooltip>
                </Grid>
              </Grid>
            </Grid>
            <Grid item className={clsx(formFieldClass)}>
              {value.addressType === 'internal' && blockchain ? (
                <div
                  rtl-data-test-id={
                    testIds?.addressFieldInternal ??
                    extIntTestIds.addressFieldInternal
                  }
                >
                  <AccountSelectField
                    disabled={disabled}
                    onChange={(e) =>
                      onChange({
                        address: findItemByAccountId(
                          e.target.value as AccountId,
                          items,
                        ).address,
                        addressType: value.addressType,
                      })
                    }
                    error={!!errorMessage}
                    value={findItemByAddress(value.address, items)?.accountId}
                    tokenMetadata={tokenMetadata}
                    label={label}
                    items={items}
                    blockchain={blockchain}
                    hideAmount={hideAmount}
                    hideCopyAddress={hideCopyAddress}
                  />
                </div>
              ) : renderExternalAddressField ? (
                renderExternalAddressField()
              ) : (
                <TextField
                  fullWidth
                  label={label}
                  inputProps={{
                    'rtl-data-test-id':
                      testIds?.addressFieldExternal ??
                      extIntTestIds.addressFieldExternal,
                  }}
                  variant="outlined"
                  autoComplete="off"
                  onChange={(e) =>
                    // remove all white spaces from string
                    onChange({
                      address: e.target.value.replace(/\s/g, ''),
                      addressType: value.addressType,
                    })
                  }
                  value={value.address}
                  error={!!errorMessage}
                  InputProps={externalAddressInputProps}
                  disabled={disabled}
                />
              )}
              {errorMessage && (
                <Typography
                  className={classes.error}
                  color="error"
                  variant="caption"
                >
                  {errorMessage}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

const findItemByAddress = (
  address: string,
  items: AccountSelectItem[],
): AccountSelectItem | undefined => {
  const found = items.find((i) => i.address === address)
  return found
}

const useStyles = makeStyles((theme) => ({
  header: {
    marginBottom: theme.spacing(1.5),
  },
  error: {
    display: 'block',
    marginTop: theme.spacing(1),
  },
}))
