import {HelpOutline, HelpOutline as HelpIcon} from '@mui/icons-material'
import {Tooltip, Box} from '@mui/material'
import {
  calculateTxFeeAmount,
  isAccountSolAddress,
  solToLamports,
} from '@nufi/wallet-solana'
import type {
  SolanaTokenId,
  SolanaTokenMetadata,
  Lamports,
  SolanaTransaction,
  SolanaTokenAmount,
  SolanaAddress,
  SolanaAccountInfo,
} from '@nufi/wallet-solana'
import BigNumber from 'bignumber.js'
import React, {useEffect, useState} from 'react'
import {Trans, useTranslation} from 'react-i18next'

import {LabeledIcon} from '../../../components'
import {blockchainToCoin} from '../../../constants'
import {assert} from '../../../utils/assertion'
import type {AccountId} from '../../../wallet'
import {commonFormatTokenAmount, commonParseTokenAmount} from '../../../wallet'
import {
  getMaxSendableSolanaAmount,
  lamportsToSol,
  getTokensRequiringNewAssociatedTokenAccounts,
} from '../../../wallet/solana'
import type {SendMultiAssetFormInjectedProps} from '../common/sendMultipleAssetsModal/types'
import type {AssetSchema, BaseSendSchema as FormSchema} from '../common/types'
import {DeviceReadyState, findNativeAsset, findTokens} from '../common/utils'

import {useSolanaSendModalProvider} from './viewModelProvider'

type SolanaSendModalContentProps =
  SendMultiAssetFormInjectedProps<FormSchema> & {
    getTokenBalances: (account: SolanaAccountInfo) => SolanaTokenAmount[]
    onClose: () => unknown
  }

export function SolanaSendModalContent({
  accounts,
  formikProps,
  txFee,
  metadataById,
  getTokenBalances,
  fromAccount,
  setTxFee,
  ...rest
}: SolanaSendModalContentProps) {
  const {subScreens, vm} = useSolanaSendModalProvider()
  const submit = vm.useSubmitTransfer()
  const sign = vm.useSignTransferAssets()
  const {t} = useTranslation()
  const blockchain = 'solana'
  const {values} = formikProps
  const {toAddress, assets} = values
  const [rentFee, setRentFee] = useState<BigNumber>(new BigNumber(0))
  const {data: minimalRentFee}: {data: BigNumber | undefined} =
    vm.useGetTokenAccountRent()
  const {data: toAddressTokenAccountInfo} = vm.useGetAddressInfo(
    toAddress as SolanaAddress,
  )
  const sendableNativeFunds = getMaxSendableSolanaAmount(
    fromAccount.balance as Lamports,
    txFee as Lamports,
  )
  const includedTokensWithMetadata = formikProps.values.assets
    .filter((a): a is AssetSchema & {type: 'token'} => a.type === 'token')
    .map((t) => metadataById[t.tokenId] as SolanaTokenMetadata)
  const {data: txFeeParams, isLoading: isTxFeeParamsLoading} =
    vm.useGetTxFeeParams({includedTokens: includedTokensWithMetadata})
  const tokenBalances = getTokenBalances(fromAccount as SolanaAccountInfo)

  const onDataChange = () => {
    const fee = txFeeParams ? calculateTxFeeAmount(txFeeParams) : undefined
    if (
      toAddressTokenAccountInfo &&
      isAccountSolAddress(toAddressTokenAccountInfo.toAccountInfo) &&
      fee
    ) {
      const {rentFee} = getTokensRequiringNewAssociatedTokenAccounts({
        tokenIds: findTokens(assets).map(
          ({tokenId}) => tokenId as SolanaTokenId,
        ),
        minimalRentFee,
        tokenMetadataById: metadataById as Record<
          SolanaTokenId,
          SolanaTokenMetadata
        >,
        recipientTokenAccounts: toAddressTokenAccountInfo.toTokenAccounts,
      })
      setTxFee(rentFee.plus(fee))
      setRentFee(rentFee)
    } else {
      setRentFee(new BigNumber(0))
      setTxFee(fee || new BigNumber(0))
    }
  }
  useEffect(() => onDataChange(), [txFeeParams, minimalRentFee])

  const onSign = async () => {
    const {assets, accountId, toAddress}: FormSchema = values
    const nativeAmount = solToLamports(findNativeAsset(assets)?.amount || '0')
    const tokens = findTokens(assets).map(({amount, tokenId}) => {
      const tokenData = metadataById[tokenId]!
      assert(tokenData.blockchain === 'solana')
      return {
        amount: commonParseTokenAmount(amount, tokenData.decimals),
        token: tokenData,
      }
    })
    assert(!!txFeeParams, 'txFeeParams are not defined')
    return await sign.mutateAsyncSilent({
      sendContext: {
        tokens,
        solAmount: nativeAmount,
      },
      accountId: accountId as AccountId,
      toAddress: toAddress as SolanaAddress,
      txFeeParams,
    })
  }

  const onSubmit = async (signedTx: SolanaTransaction) =>
    await submit.mutateAsyncSilent({signedTx, accountId: values.accountId})

  // i18next-scanner reports error when declaring this insight `t` statement
  const formattedRentFee = `${lamportsToSol(
    (minimalRentFee || new BigNumber(0)) as Lamports,
  )} ${blockchainToCoin('solana')}`

  return (
    <subScreens.SendModalContent
      {...{
        feeProps: {
          isLoading: isTxFeeParamsLoading,
          fee: txFee,
          customLabel: rentFee.isGreaterThan(0) ? (
            <Box display="flex" gap={0.5}>
              {t('Network fee')} +{' '}
              {
                <LabeledIcon
                  Label={t('Rent Fee')}
                  Icon={
                    <Tooltip
                      title={
                        <Trans
                          i18nKey="total_amount_sol_tooltip"
                          t={t}
                          components={{
                            Icon: (
                              <HelpIcon
                                fontSize="small"
                                sx={{
                                  mr: 1,
                                  height: 'inherit',
                                  fontSize: '120%',
                                }}
                              />
                            ),
                          }}
                          values={{
                            rentFee: formattedRentFee,
                          }}
                        />
                      }
                    >
                      <HelpOutline fontSize="small" />
                    </Tooltip>
                  }
                  iconPosition="end"
                />
              }
              {':'}
            </Box>
          ) : undefined,
        },
        blockchain,
        formikProps,
        accounts,
        metadataById,
        onDataChange,
        DeviceReadyState,
        tokenAmounts: tokenBalances.filter(({amount}) =>
          amount.isGreaterThan(0),
        ),
        signProps: {
          ...sign,
          mutateAsyncSilent: onSign,
        },
        submitProps: {
          ...submit,
          mutateAsyncSilent: onSubmit,
        },
        ...rest,
        type: 'multiAsset',
        disabled: isTxFeeParamsLoading,
      }}
      getAssetFieldProps={(asset, {name}) => ({
        maxAmountOptions:
          asset.type === 'native'
            ? sendableNativeFunds.gt(0)
              ? {
                  onMaxAmount: () => {
                    formikProps.setFieldValue(
                      name,
                      lamportsToSol(sendableNativeFunds),
                    )
                  },
                }
              : undefined
            : {
                onMaxAmount: () => {
                  const {decimals} = metadataById[asset.tokenId]!
                  const max = tokenBalances.find(
                    ({token}) => token.id === asset.tokenId,
                  )?.amount
                  assert(!!max)
                  formikProps.setFieldValue(
                    name,
                    commonFormatTokenAmount(max, decimals),
                  )
                },
              },
      })}
    />
  )
}
