import {Box} from '@mui/material'
import BigNumber from 'bignumber.js'
import React, {useEffect, useRef} from 'react'
import {useTranslation} from 'react-i18next'

import {FUSE_TOKEN_SEARCHING_THRESHOLD} from 'src/constants'
import {
  useGetBlockchainName,
  useGetCoinName,
  useGetTokenSearchingKeyLabel,
} from 'src/utils/translations'

import {AssetIcon, BlockchainIcon, FormattedAsset} from '../../../../components'
import {safeAssertUnreachable} from '../../../../utils/assertion'
import type {
  AccountIdRequestedBalance,
  AccountInfo,
  TokenMetadata,
  TokenId,
  TokenAmount,
  TokenSearchingKeys,
} from '../../../../wallet'
import {
  getTokenSearchingKeys,
  isNft,
  parseTokenInfoFromMeta,
} from '../../../../wallet'
import {ensureAccountById} from '../../../../wallet/utils/common'
import type {DetailsAndSummaryTxFlowState} from '../../../transaction/multiStepFormUtils/baseSignTxFlowUtils'
import {getDefaultTxFlowScreens} from '../../../transaction/multiStepFormUtils/baseSignTxFlowUtils'
import type {AddAssetsProps} from '../AddAssets'
import {AddAssets} from '../AddAssets'
import DetailsScreen, {SendSummary} from '../DetailsScreen'
import SummaryScreen from '../SummaryScreen'
import {schemaKeys} from '../types'
import type {BaseSendSchema, AssetSchema} from '../types'
import {SendModalHeader, getAmountsToSend, isBalanceNonFungible} from '../utils'

import type {
  BaseSendModalContentProps,
  MultiOrSingleAssetProps,
  SingleAssetTokenOrNativeProps,
  SendMultipleAssetsTxFlowState,
} from './types'
import {useSendModalVM} from './viewModelProvider'

export type SendModalContentProps<
  T extends BaseSendSchema,
  TSignResponse,
  TSubmitResponse extends string,
> = MultiOrSingleAssetProps<
  SendMultipleAssetsModalContentProps<T, TSignResponse, TSubmitResponse>,
  BaseSendModalContentProps<T, TSignResponse, TSubmitResponse> &
    SingleAssetModalContentProps
>

export function SendModalContent<
  T extends BaseSendSchema,
  TSignResponse,
  TSubmitResponse extends string,
>(props: SendModalContentProps<T, TSignResponse, TSubmitResponse>) {
  return props.type === 'multiAsset' ? (
    <SendMultipleAssetsModalContent {...props} />
  ) : (
    <_SendModalContent {...props} />
  )
}

export type SendMultipleAssetsModalContentProps<
  T extends BaseSendSchema,
  TSignResponse,
  TSubmitResponse extends string,
> = {
  hasPreselectedTokens: boolean
  accounts: Array<AccountInfo>
  metadataById: Record<TokenId, TokenMetadata>
  screenState: SendMultipleAssetsTxFlowState
  setScreenState: (state: SendMultipleAssetsTxFlowState) => void
  tokenAmounts: TokenAmount[]
  requiredAssets?: AddAssetsProps['requiredAssets']
} & BaseSendModalContentProps<T, TSignResponse, TSubmitResponse>

export function SendMultipleAssetsModalContent<
  T extends BaseSendSchema,
  TSignResponse,
  TSubmitResponse extends string,
>({
  tokenAmounts,
  onDataChange,
  requiredAssets,
  ...props
}: SendMultipleAssetsModalContentProps<T, TSignResponse, TSubmitResponse>) {
  const {t} = useTranslation()

  const {blockchain, formikProps, metadataById, setScreenState, accounts} =
    props
  const initialLoad = useRef(true)

  const {values, setFieldValue} = formikProps

  // refresh assets list on account change
  useEffect(() => {
    if (!initialLoad.current) {
      const filteredAssets = values.assets
        .filter((asset) => {
          if (asset.type === 'token') {
            return tokenAmounts.some(({token}) => token.id === asset.tokenId)
          } else {
            return asset
          }
        })
        .map((token) => ({...token, amount: ''}))

      setFieldValue(
        schemaKeys.assets,
        filteredAssets.length === 0
          ? [{type: 'native', amount: ''}]
          : filteredAssets,
      )
    } else {
      initialLoad.current = false
    }
  }, [values.accountId])

  const keys = getTokenSearchingKeys(blockchain) as TokenSearchingKeys
  const keyLabels = useGetTokenSearchingKeyLabel(blockchain)

  const placeholderKeys = (keyLabels as string[]).reduce(
    (text, value, i, array) =>
      text + (i < array.length - 1 ? ', ' : ` ${t('or')} `) + value,
  )

  const getBlockchainName = useGetBlockchainName()
  const getBlockchainTicker = useGetCoinName()

  return (
    <_SendModalContent
      {...props}
      onDataChange={onDataChange}
      renderAddAssets={(ModalHeader) => (
        <AddAssets
          requiredAssets={requiredAssets}
          onSubmit={(v) => {
            setFieldValue(schemaKeys.assets, v)
            setScreenState('details')
          }}
          fuseOptions={{
            keys: keys.map((key) => ({
              name: key,
              getFn: (props: AssetSchema) => {
                if (props.type === 'token') {
                  return metadataById[props.tokenId]?.[
                    key as keyof TokenMetadata
                  ] as string
                } else {
                  return blockchain
                }
              },
            })),
            threshold: FUSE_TOKEN_SEARCHING_THRESHOLD,
          }}
          searchPlaceholderText={`${t('Search by')} ${placeholderKeys}`}
          accountName={ensureAccountById(accounts, values.accountId).name}
          assetList={[
            {
              type: 'native',
              amount: ensureAccountById(
                accounts,
                values.accountId,
              ).balance.toString(),
              assetName: getBlockchainName(blockchain),
              ticker: getBlockchainTicker(blockchain),
            },
            ...tokenAmounts
              // This can indeed be `null` if user sends his tokens away and `metadataById`
              // is invalidated sooner than `tokenBalances`.
              .filter(({token}) => metadataById[token.id] != null)
              .map(({token, amount}) => {
                const tokenMetadata = metadataById[token.id]!
                const {tokenIdentifier, assetName, decimals} =
                  parseTokenInfoFromMeta(tokenMetadata)
                const {id, ticker} = tokenMetadata
                return {
                  type: 'token' as const,
                  tokenId: id,
                  tokenIdentifier,
                  assetName,
                  ticker,
                  isNft: isBalanceNonFungible(
                    isNft(tokenMetadata),
                    decimals,
                    amount,
                  ),
                  amount: amount.toString(),
                }
              }),
          ]}
          selected={values.assets}
          onBack={() => setScreenState('details')}
          renderIcon={(option) => (
            <Box ml={1} display="flex">
              {option.type === 'token' ? (
                <AssetIcon
                  blockchain={blockchain}
                  tokenMetadata={metadataById[option.tokenId]}
                />
              ) : (
                <BlockchainIcon blockchain={blockchain} />
              )}
            </Box>
          )}
          renderFormattedAsset={(props) => (
            <FormattedAsset
              blockchain={blockchain}
              tokenMetadata={
                props.type === 'token' ? metadataById[props.tokenId] : null
              }
              amount={new BigNumber(props.amount)}
              isSensitiveInformation
            />
          )}
          {...{
            ModalHeader,
          }}
        />
      )}
      type="multiAsset"
    />
  )
}

type SingleAssetModalContentProps = SingleAssetTokenOrNativeProps<{
  txType: 'token'
  metadataById: Record<TokenId, TokenMetadata>
  tokenBalances: AccountIdRequestedBalance[]
}> & {
  screenState: DetailsAndSummaryTxFlowState
  setScreenState: (state: DetailsAndSummaryTxFlowState) => void
}

type _SendModalContentProps<
  T extends BaseSendSchema,
  TSignResponse,
  TSubmitResponse extends string,
> = BaseSendModalContentProps<T, TSignResponse, TSubmitResponse> &
  MultiOrSingleAssetProps<
    {
      metadataById: Record<TokenId, TokenMetadata>
      renderAddAssets: (ModalHeader: JSX.Element) => JSX.Element
      screenState: SendMultipleAssetsTxFlowState
      setScreenState: (state: SendMultipleAssetsTxFlowState) => void
    },
    SingleAssetModalContentProps
  >

function _SendModalContent<
  T extends BaseSendSchema,
  TSignResponse,
  TSubmitResponse extends string,
>({
  feeProps,
  toAddress,
  blockchain,
  onClose,
  formikProps,
  accounts,
  submitProps,
  renderDetails,
  onDataChange,
  humanReadableToAddressName,
  signProps,
  DeviceReadyState,
  onBeforeDetailsSubmit,
  ...rest
}: _SendModalContentProps<T, TSignResponse, TSubmitResponse>) {
  const vm = useSendModalVM()
  const {values} = formikProps
  const isSingleTokenTx = rest.type === 'singleAsset' && rest.txType === 'token'
  const _toAddress = toAddress || values.toAddress
  const fee = feeProps?.fee || new BigNumber(0)

  const sendContext =
    rest.type === 'multiAsset'
      ? {
          type: 'multiAsset' as const,
          metadataById: rest.metadataById,
        }
      : isSingleTokenTx
        ? {
            type: 'token' as const,
            tokenId: rest.tokenId,
            tokenMetadata: rest.metadataById[rest.tokenId]!,
          }
        : {type: 'native' as const}

  const {
    nativeAmount: nativeAmountToSend,
    tokensToSendCount,
    totalNativeAmountSpent,
    singleTokenAmount,
  } = getAmountsToSend({
    assets: values.assets,
    blockchain,
    txFee: fee,
    sendContext,
  })
  const account = ensureAccountById(accounts, values.accountId)

  const ModalHeader = (
    <SendModalHeader
      {...{
        blockchain,
        onClose,
      }}
      token={isSingleTokenTx ? rest.metadataById[rest.tokenId] : undefined}
    />
  )

  useEffect(() => {
    onDataChange?.({
      values,
      formikProps,
    })
  }, [_toAddress, values.accountId, values.assets])

  vm.useTrackMultiAssetTxSubmission(submitProps, {
    blockchain,
    provider: account.cryptoProviderType,
    sendContext: (() => {
      if (rest.type === 'multiAsset') {
        return {
          type: 'multi_asset_transfer_tx',
          tokensToSendCount,
          nftsToSendCount: values.assets.filter(
            (a) =>
              a.type === 'token' &&
              rest.metadataById[a.tokenId] &&
              isNft(rest.metadataById[a.tokenId]!),
          ).length,
          nativeAmountToSend: totalNativeAmountSpent,
        }
      } else if (isSingleTokenTx) {
        return {
          type: 'single_token_transfer_tx',
          tokenAmount: singleTokenAmount as BigNumber,
          tokenId: rest.tokenId,
          isNft:
            !!rest.metadataById[rest.tokenId] &&
            isNft(rest.metadataById[rest.tokenId]!),
        }
      } else {
        return {
          type: 'native_transfer_tx',
          nativeAmountToSend: totalNativeAmountSpent,
        }
      }
    })(),
  })
  const commonModalProps = {
    blockchain,
    onClose,
    formikProps,
    ModalHeader,
  }
  const {signAndSubmitScreen, successScreen} = getDefaultTxFlowScreens<
    TSignResponse,
    TSubmitResponse
  >({
    account,
    resetForm: formikProps.resetForm,
    onClose,
    setScreenState: rest.setScreenState,
    DeviceReadyState,
    ModalHeader,
    signProps,
    submitProps,
  })

  const getDetailScreenProps = () =>
    rest.type === 'multiAsset'
      ? {
          onAddAssetsClick: () => rest.setScreenState('addAssets'),
        }
      : rest.txType === 'token'
        ? {
            sendContext: {type: rest.type},
            ErrorContent: rest.ErrorContent,
          }
        : {sendContext: {type: rest.type}}

  return (
    <>
      {(() => {
        switch (rest.screenState) {
          case 'details': {
            const DefaultDetailsScreen = (
              <DetailsScreen
                account={account}
                onBeforeDetailsSubmit={onBeforeDetailsSubmit}
                summary={
                  <SendSummary
                    blockchain={blockchain}
                    nativeAmountToSend={
                      !isSingleTokenTx ? nativeAmountToSend : undefined
                    }
                    tokensToSendCount={tokensToSendCount}
                    feeProps={feeProps}
                    tokenAmountToSend={singleTokenAmount}
                    isMultiassetSend={!isSingleTokenTx}
                    tokenMetadata={
                      isSingleTokenTx
                        ? rest.metadataById[rest.tokenId]
                        : undefined
                    }
                  />
                }
                {...{
                  accounts,
                  ...commonModalProps,
                  ...rest,
                  tokenBalances: isSingleTokenTx
                    ? {
                        data: rest.metadataById[rest.tokenId]!,
                        balances: rest.tokenBalances,
                      }
                    : undefined,
                  ...('tokenId' in rest && {
                    customAccountSelectBalances: {
                      primary: {
                        tokenMetadata: rest.metadataById[rest.tokenId],
                        balances: rest.tokenBalances,
                      },
                      secondary: {
                        balances: accounts.map(({id, balance}) => ({
                          accountId: id,
                          balance,
                        })),
                      },
                    },
                  }),
                  ...getDetailScreenProps(),
                }}
                sendContext={{
                  type: rest.type,
                }}
              />
            )

            return renderDetails?.(DefaultDetailsScreen) || DefaultDetailsScreen
          }
          case 'addAssets':
            return rest.type === 'multiAsset'
              ? rest.renderAddAssets(ModalHeader)
              : null
          case 'summary':
            return (
              <SummaryScreen
                onSubmit={formikProps.handleSubmit}
                sendContext={sendContext}
                summary={{
                  fee,
                  assets: values.assets,
                  fromAccount: account,
                  toAddress: _toAddress,
                  humanReadableToAddressName,
                }}
                {...{
                  onBack: () => rest.setScreenState('details'),
                  tokensToSendCount,
                  ...commonModalProps,
                }}
                extraContent={rest.extraContent}
              />
            )
          case 'signAndSubmit':
            return signAndSubmitScreen
          case 'success':
            return successScreen
          default:
            return safeAssertUnreachable(rest)
        }
      })()}
    </>
  )
}
