import type {Handler, ErrorResponse} from '@nufi/dapp-client-core'
import {Sentry, sleep} from '@nufi/frontend-common'
import type {
  SolanaAccountOfflineInfo,
  SolanaMessage,
  SolanaTransaction,
} from '@nufi/wallet-solana'
import {
  SOLANA_DEFAULT_SERIALIZATION_PARAMS,
  parseTransactionInstructions,
} from '@nufi/wallet-solana'
import {PublicKey, Transaction, VersionedTransaction} from '@solana/web3.js'

import {solana} from 'src/wallet/solana/solanaManagers'

import {middleware} from '../../../../dappConnector/middlewares'
import type {Connector} from '../../../../dappConnector/types'
import {useDappConnectorStore} from '../../../../store/dappConnector'
import type {HexString} from '../../../../types'
import {assert} from '../../../../utils/assertion'
import {confirmInit, confirmSign} from '../../apiBridge'

import {SolanaApiError, apiErrorMessages} from './injectedConnector'
import {sanitizeAndEstimateTxFee} from './txFeeSanitization'
import type {API, SerializedTransaction} from './types'

const connectorKind = 'solana'

export const serializeTx = (tx: SolanaTransaction) =>
  Buffer.from(tx.serialize(SOLANA_DEFAULT_SERIALIZATION_PARAMS)).toString(
    'base64',
  ) as SerializedTransaction

export const deserializeTx = (tx: SerializedTransaction) => {
  try {
    return Transaction.from(Buffer.from(tx, 'base64'))
  } catch (e) {
    return VersionedTransaction.deserialize(Buffer.from(tx, 'base64'))
  }
}

const consentRejectedError = new SolanaApiError(
  apiErrorMessages.REJECTED_BY_USER,
) as unknown as ErrorResponse
const notEnabledError = new Error('Not enabled.') as unknown as ErrorResponse

export const signTransaction = async ({
  unsignedTx,
  requestType,
}: {
  unsignedTx: SolanaTransaction
  requestType: 'sign' | 'signAndSend'
}) => {
  const {selectedAccount} = useDappConnectorStore.getState()
  assert(selectedAccount != null)
  const accountId = selectedAccount.id
  const data = solana.accountsStore.getAccount(accountId)

  const {tx: txToSign, estimatedFee} = await sanitizeAndEstimateTxFee(
    unsignedTx,
  ).catch((e) => {
    Sentry.captureException(e)
    return {tx: unsignedTx, estimatedFee: null}
  })

  const parsedInstructions = await parseTransactionInstructions(
    txToSign,
    solana.blockchainApi.getAccountInfo,
  )

  const result = await confirmSign(
    'sign-tx',
    {
      type: 'solana',
      data: {
        tx: txToSign,
        estimatedFee,
        rawTx: Buffer.from(serializeTx(txToSign), 'base64'),
        parsedInstructions,
        requestType,
      },
    },
    async (extraOptions) => {
      assert(extraOptions?.type === 'solana')
      return {
        forceTxSending: extraOptions.data.forceTxSending,
        signedTx: await solana.accountManager
          .getCryptoProviderByType(data.cryptoProviderType)
          .signTransaction(txToSign, {
            pubKey: data.publicKey,
            params: data.derivationParams,
          }),
      }
    },
    () => consentRejectedError,
  )
  return result
}

const signMessage = async (message: Buffer) => {
  const {selectedAccount} = useDappConnectorStore.getState()
  assert(selectedAccount != null)
  const accountId = selectedAccount.id
  const data = solana.accountsStore.getAccount(accountId)

  const onSign = () =>
    solana.accountManager
      .getCryptoProviderByType(data.cryptoProviderType)
      .signMessage(message as SolanaMessage, data.derivationParams)

  return confirmSign(
    'sign-message',
    {
      type: 'solana',
      data: {messageHex: message.toString('hex') as HexString},
    },
    onSign,
    () => consentRejectedError,
  )
}

export const getAccountPublicKey = () => {
  const {selectedAccount} = useDappConnectorStore.getState()
  assert(selectedAccount != null)
  return new PublicKey((selectedAccount as SolanaAccountOfflineInfo).address)
}

export const solanaDappConnectorApi: API = {
  async signTransaction(serializedTx) {
    const {signedTx, forceTxSending} = await signTransaction({
      unsignedTx: deserializeTx(serializedTx),
      requestType: 'sign',
    })

    // TMP hack as it seems several DApps have their own tx submission
    // after signing broken, so we just submit the tx with some delay
    // to give the DApp a chance to send it first (just in case)
    if (forceTxSending) {
      sleep(3000)
        .then(() =>
          solana.accountManager.simulateTransaction(signedTx, {
            accountPubKeys: [],
            verifySignatures: true,
          }),
        )
        .then(async (res) => {
          if (!res.value.err) {
            await solana.accountManager.sendAndConfirmTransaction(signedTx)
          }
        })
        .catch((e) => Sentry.captureException(e))
    }

    return serializeTx(signedTx)
  },
  async signAndSendTransaction(transaction, sendOptions) {
    const {signedTx} = await signTransaction({
      unsignedTx: deserializeTx(transaction),
      requestType: 'signAndSend',
    })
    return {
      signature: await solana.accountManager.sendAndConfirmTransaction(
        signedTx,
        sendOptions,
      ),
    }
  },
  async signMessage(message) {
    return [...(await signMessage(Buffer.from(message)))]
  },
  async getPublicKey() {
    const publicKey = getAccountPublicKey()
    return [...publicKey.toBytes()]
  },
}
export const createHandler = (): Handler =>
  middleware.userConsent(
    middleware.resolveMethod(solanaDappConnectorApi, {
      notEnabledError,
      passContext: false,
    }),
    confirmInit,
    {
      connectorKind,
      enableMethod: 'connect',
      disableMethod: 'disconnect',
      notEnabledError,
      consentRejectedError,
    },
  )

const connector: Connector = {createHandler}

export default connector
