import {Box, Stack, styled, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import shield from 'src/assets/images/shield.png'
import {
  Alert,
  FooterLayout,
  InlineLoading,
  ModalFooter,
  ModalLayout,
  SafeCenterAligner,
} from 'src/components'
import type {Blockchain} from 'src/types'
import {useGetBlockchainName} from 'src/utils/translations'

import {ManageBlockchainsError} from './ManageBlockchainsError'

type ConfirmChangesScreenProps = {
  disabledBlockchainsWithBalance: readonly Blockchain[]
  onSubmit: () => void
  onBack: () => void
  isLoading: boolean
  error: unknown
}

export const ConfirmChangesScreen = ({
  disabledBlockchainsWithBalance,
  onSubmit,
  onBack,
  isLoading,
  error,
}: ConfirmChangesScreenProps) => {
  const {t} = useTranslation()
  const getBlockchainName = useGetBlockchainName()
  return (
    <ModalLayout
      body={
        <>
          {isLoading ? (
            <SafeCenterAligner>
              <InlineLoading />
            </SafeCenterAligner>
          ) : (
            <ContentWrapper>
              <img src={shield} />
              <Typography variant="h5">{t('Confirm changes')}</Typography>
              {disabledBlockchainsWithBalance.length > 0 && (
                <Box>
                  <Alert severity="info">
                    {t(
                      'ConfirmManageBlockhainsModal.disablingBlockchainsWithBalance',
                      {
                        blockchains: disabledBlockchainsWithBalance
                          .map((b) => getBlockchainName(b))
                          .join(', '),
                      },
                    )}
                  </Alert>
                </Box>
              )}
              {error != null && <ManageBlockchainsError error={error} />}
            </ContentWrapper>
          )}
        </>
      }
      footer={
        <ModalFooter hasDivider>
          <FooterLayout
            leftBtnConf={{
              children: t('Back'),
              onClick: onBack,
              disabled: isLoading,
            }}
            rightBtnConf={{
              children: t('Confirm'),
              onClick: onSubmit,
              disabled: isLoading,
            }}
          />
        </ModalFooter>
      }
    />
  )
}

const ContentWrapper = styled(Stack)(({theme}) => ({
  padding: theme.spacing(2),
  gap: theme.spacing(2),
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
}))
