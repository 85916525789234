import {BroadcastChannel} from 'broadcast-channel'

import type {LocalProfileId} from '../appStorage/types'

type LoginType = 'concurrentLogin' | 'restoreProfile'

export type LoginBroadcastMessage = {
  localProfileId: LocalProfileId
  loginType: LoginType
}

const loginBroadcastChannel = new BroadcastChannel('nufi-login')

export const broadcastLoginToOtherTabs = async (account: LocalProfileId) => {
  await loginBroadcastChannel.postMessage({
    localProfileId: account,
    loginType: 'concurrentLogin',
  })
}

export const broadcastRestoreProfileToOtherTabs = async (
  account: LocalProfileId,
) => {
  await loginBroadcastChannel.postMessage({
    localProfileId: account,
    loginType: 'restoreProfile',
  })
}

export const setBroadcastLoginListener = async (
  fn: (msg: LoginBroadcastMessage) => void,
) => {
  loginBroadcastChannel.onmessage = fn
}
