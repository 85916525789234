import {Box, Typography, styled} from '@mui/material'
import clsx from 'clsx'
import React from 'react'
import {useTranslation} from 'react-i18next'

import img from '../../assets/images/hwVendorsLogos.png'
import {SideBarLayout, NavigateBack} from '../../components'

import {
  useCommonProfileStyles,
  NavigateBackWrapper,
  ButtonChoice,
} from './common'
import type {WalletType} from './types'

type WalletTypePageProps = {
  onSubmit: (walletType: WalletType) => unknown
}

export default function WalletTypePage({onSubmit}: WalletTypePageProps) {
  const {t} = useTranslation()
  const commonClasses = useCommonProfileStyles()

  const onSelectHwWallet = () => onSubmit('hw')
  const onSelectMnemonicWallet = () => onSubmit('mnemonic')

  return (
    <SideBarLayout
      showAnnouncement
      right={
        <Box className={clsx(commonClasses.wrapper, commonClasses.spacing)}>
          <Box>
            <Typography variant="h5" data-test-id="wallet-type-screen-header">
              {t('Connect your hardware wallet (optional)')}
            </Typography>
            <Typography
              className={commonClasses.headerSubtitle}
              variant="body1"
              color="textSecondary"
            >
              {t(
                "It's easy to connect your Trezor Model T/Safe 3/Safe 5, Ledger Nano S/X or GridPlus Lattice1 to your NuFi wallet.",
              )}
            </Typography>
          </Box>

          <WalletTypeImg src={img} />

          <Box>
            <ButtonChoice
              preferredOption={{
                label: t('Yes, I have a hardware wallet'),
                onClick: onSelectHwWallet,
                'data-test-id': 'has-hw-wallet-choice',
              }}
              otherOption={{
                label: t("No, I don't have a hardware wallet"),
                onClick: onSelectMnemonicWallet,
                'data-test-id': 'does-not-have-hw-wallet-choice',
              }}
            />
            <NavigateBackWrapper>
              <NavigateBack />
            </NavigateBackWrapper>
          </Box>
        </Box>
      }
    />
  )
}

const WalletTypeImg = styled('img')({
  height: 'auto',
  width: '100%',
})
