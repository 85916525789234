import {Box} from '@mui/material'
import React from 'react'

import type {ButtonProps} from '../atoms'
import {Button} from '../atoms'

import {LabeledIcon} from './LabeledIcon'

type LabeledIconButtonProps = {
  buttonProps?: ButtonProps
  Label: React.ReactNode
  LabelIcon: React.ReactNode
  EndIcon?: React.ReactNode
}

export function LabeledIconButton({
  buttonProps,
  Label,
  LabelIcon,
  EndIcon,
}: LabeledIconButtonProps) {
  return (
    <Button {...buttonProps}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="100%"
        position="relative"
      >
        <LabeledIcon Label={Label} Icon={LabelIcon} iconPosition="start" />
        {EndIcon && (
          <Box component="span" display="flex" position="absolute" right={0}>
            {EndIcon}
          </Box>
        )}
      </Box>
    </Button>
  )
}
