import {Box, Typography} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'

import {useActivateMnemonic} from 'src/features/profile/application'

import {
  MainSectionHeader,
  Button,
  SafeCenterAligner,
  MutationGuard,
  Alert,
  NavigateBack,
} from '../../components'
import {routeTo} from '../../router'
import {assertUnreachable} from '../../utils/assertion'
import {getMnemonic} from '../../wallet'
import {NavigateBackWrapper} from '../profile/common'
import {RecoveryPhraseConfirmPageContent} from '../profile/RecoveryPhraseConfirmPage'
import {SetupRecoveryPhrasePageContent} from '../profile/RecoveryPhrasePage'

export default function ActivateMnemonicPage() {
  const {t} = useTranslation()
  const classes = useStyles()
  const [phase, setPhase] = useState<
    'info' | 'writeDownMnemonic' | 'confirmMnemonic' | 'success'
  >('info')
  const [plainTextMode, setPlainTextMode] = useState<boolean>(false)
  const mnemonic = getMnemonic()
  const history = useHistory()
  const activateMnemonic = useActivateMnemonic()

  const goToInfo = () => setPhase('info')
  const goToWriteDownMnemonic = () => setPhase('writeDownMnemonic')
  const goToConfirmMnemonic = () => setPhase('confirmMnemonic')
  const goToAccounts = () => history.push(routeTo.portfolio.accounts.index)
  const onSubmit = async () => {
    await activateMnemonic.mutateAsyncSilent()
    setPhase('success')
  }

  const onInfoBack = () => history.goBack()

  switch (phase) {
    case 'info':
      return (
        <SafeCenterAligner>
          <Box className={classes.content}>
            <MainSectionHeader>
              {t('Activate NuFi recovery phrase')}
            </MainSectionHeader>
            <Typography>
              {t(
                'Activate your recovery phrase to unlock your wallet’s full potential.',
              )}
            </Typography>
            <Typography>{t('Once activated you can:')}</Typography>

            <Box mt={2} />
            <Alert severity="info">
              <Typography>
                {t(
                  "Add non-hardware wallet accounts for all supported blockchains and manage these alongside your hardware wallet's accounts.",
                )}
              </Typography>
            </Alert>

            <Box mt={2} />
            <Alert severity="info">
              <Typography>
                {t('Use services that are not available for hardware wallets.')}
              </Typography>
            </Alert>

            <Box mt={2} />
            <Alert severity="info">
              <Typography>
                {t(
                  'Restore your wallet including all accounts, account names and other custom data using this single phrase (coming soon).',
                )}
              </Typography>
            </Alert>

            <Box mt={2} />
            <Button
              textTransform="none"
              onClick={goToWriteDownMnemonic}
              className={classes.btn}
              color="primary"
              variant="contained"
            >
              {t('Activate')}
            </Button>

            <NavigateBackWrapper>
              <NavigateBack onBack={onInfoBack} />
            </NavigateBackWrapper>
          </Box>
        </SafeCenterAligner>
      )
    case 'writeDownMnemonic':
      return (
        <Box className={classes.wrapper}>
          <Box className={classes.content}>
            <SetupRecoveryPhrasePageContent
              onBack={goToInfo}
              onSubmit={goToConfirmMnemonic}
              {...{plainTextMode, setPlainTextMode, mnemonic}}
            />
          </Box>
        </Box>
      )
    case 'confirmMnemonic':
      return (
        <Box className={classes.wrapper}>
          <Box className={classes.content}>
            <RecoveryPhraseConfirmPageContent
              {...{mnemonic, plainTextMode, onSubmit}}
              onBack={goToWriteDownMnemonic}
            />
          </Box>
          <MutationGuard {...activateMnemonic} />
        </Box>
      )
    case 'success':
      return (
        <SafeCenterAligner>
          <Box className={classes.content}>
            <Typography variant="h5">
              {t('Recovery phrase successfully activated')}
            </Typography>

            <Box mt={2} />
            <Typography>
              {t('You can start adding non-hardware wallet accounts')}
            </Typography>
            <Box mt={2} />
            <Button
              textTransform="none"
              fullWidth
              variant="contained"
              color="primary"
              onClick={goToAccounts}
            >
              {t('Continue')}
            </Button>
          </Box>
        </SafeCenterAligner>
      )
    default:
      return assertUnreachable()
  }
}

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  content: {
    maxWidth: 500,
  },
  btn: {
    width: '100%',
  },
}))
