import _mapValues from 'lodash/mapValues'
import React from 'react'

import type {AppError} from '../types'

import {SafeCenterAligner} from './visual/atoms/SafeCenterAligner'
import {NaError, InlineError, CenteredError, ModalError} from './visual/Error'
import {InlineLoading, FullScreenLoading} from './visual/Loading'

type QueryData<TData> = {
  isLoading: boolean
  isIdle?: boolean
  error: AppError
  data: TData
}

type ErrorVariant = 'inline' | 'na' | 'centered' | 'modal'
type LoadingVariant = 'inline' | 'centered'

type QueryGuardMeta = {
  errorVariant?: ErrorVariant
  loadingVariant?: LoadingVariant
  LoadingElement?: React.ReactNode
  ErrorElement?: JSX.Element
}

type QueryGuardProps<TData> = {
  children:
    | ((data: Exclude<TData, undefined>) => JSX.Element | null)
    | JSX.Element
} & QueryData<TData> &
  QueryGuardMeta

const defaultErrorVariant: ErrorVariant = 'na'
const defaultLoadingVariant: LoadingVariant = 'inline'
const defaultLoadingElement = <InlineLoading />

function isDefined<TData>(data: TData): data is Exclude<TData, undefined> {
  return data !== undefined
}

function handleQueryGuardResponse<TData>({
  data,
  children,
  error,
  isLoading,
  isIdle,
  LoadingElement,
  loadingVariant,
  errorVariant,
  ErrorElement,
}: {
  data: TData | undefined
  children: ((data: TData) => JSX.Element | null) | JSX.Element
  ErrorElement?: JSX.Element
  error: unknown
  isLoading: boolean
  isIdle?: boolean
  LoadingElement: React.ReactNode
  loadingVariant: LoadingVariant
  errorVariant: ErrorVariant
}) {
  const ErrorType = (
    {
      inline: InlineError,
      na: NaError,
      centered: CenteredError,
      modal: ModalError,
    } as const
  )[errorVariant]

  if (error) {
    if (ErrorElement) return ErrorElement
    return <ErrorType error={error} />
  }
  if (isDefined(data)) {
    return typeof children === 'function' ? children(data) : children
  }
  return isLoading || isIdle ? (
    loadingVariant === 'inline' ? (
      <>{LoadingElement}</>
    ) : (
      <SafeCenterAligner>{LoadingElement}</SafeCenterAligner>
    )
  ) : null
}

export function QueryGuard<TData>({
  isLoading,
  isIdle,
  error,
  data,
  children,
  LoadingElement = defaultLoadingElement,
  ErrorElement,
  errorVariant = defaultErrorVariant,
  loadingVariant = defaultLoadingVariant,
}: QueryGuardProps<TData>) {
  return handleQueryGuardResponse({
    data: isDefined(data) ? data : undefined,
    children,
    ErrorElement,
    LoadingElement,
    error,
    errorVariant,
    isLoading,
    loadingVariant,
    isIdle,
  })
}

type BatchResultProperties<
  TData,
  TQueriesMap extends Record<string, QueryData<TData>>,
> = {
  [Property in keyof TQueriesMap]: Exclude<
    TQueriesMap[Property]['data'],
    undefined
  >
}

type BatchQueryGuardProps<
  TData,
  TQueriesMap extends Record<string, QueryData<TData>>,
> = {
  queries: TQueriesMap
  children:
    | ((data: BatchResultProperties<TData, TQueriesMap>) => JSX.Element | null)
    | JSX.Element
} & QueryGuardMeta

export function BatchQueryGuard<
  TData,
  TQueriesMap extends Record<string, QueryData<TData>>,
>({
  queries: queriesMap,
  children,
  ErrorElement,
  LoadingElement = defaultLoadingElement,
  errorVariant = defaultErrorVariant,
  loadingVariant = defaultLoadingVariant,
}: BatchQueryGuardProps<TData, TQueriesMap>) {
  const queries = Object.values(queriesMap)
  const error = queries.find((q) => q.error)?.error
  const isLoading = queries.some((q) => q.isLoading)
  const isIdle = queries.some((q) => q.isIdle)
  const allDataDefined = queries.every((q) => isDefined(q.data))

  return handleQueryGuardResponse({
    data: allDataDefined
      ? (_mapValues(queriesMap, (v) => v.data) as BatchResultProperties<
          TData,
          TQueriesMap
        >)
      : undefined,
    children,
    ErrorElement,
    LoadingElement,
    errorVariant,
    loadingVariant,
    isLoading,
    isIdle,
    error,
  })
}

type MutationGuardProps<TData> = {
  isPending: boolean
  error: AppError
  data?: TData
  hideLoader?: boolean
  LoadingElement?: JSX.Element
  ErrorElement?: JSX.Element
  children?: ((data: Exclude<TData, undefined>) => JSX.Element) | JSX.Element
}

function handleMutationGuardResponse<TData>({
  isPending,
  error,
  data,
  LoadingElement,
  ErrorElement,
  children,
  hideLoader,
}: MutationGuardProps<TData>) {
  if (error) {
    return ErrorElement || <InlineError error={error} />
  }
  if (isPending) {
    return LoadingElement || <FullScreenLoading hideLoader={hideLoader} />
  }
  if (children && isDefined(data)) {
    return typeof children === 'function' ? children(data) : children
  }
  return null
}

export function MutationGuard<TData>(props: MutationGuardProps<TData>) {
  return handleMutationGuardResponse(props)
}

type MultipleMutationsGuardQueryMap<TData> = {
  error: AppError
  isPending: boolean
  data?: TData
}

type MultipleMutationsGuardProps<
  TData,
  TMutationsMap extends Record<string, MultipleMutationsGuardQueryMap<TData>>,
> = {
  mutations: TMutationsMap
} & Pick<
  MutationGuardProps<unknown>,
  'LoadingElement' | 'ErrorElement' | 'children'
>

export function MultipleMutationsGuard<
  TData,
  TMutationsMap extends Record<string, MultipleMutationsGuardQueryMap<TData>>,
>({
  mutations: mutationsMap,
  ErrorElement,
  LoadingElement,
  children,
}: MultipleMutationsGuardProps<TData, TMutationsMap>) {
  const mutations = Object.values(mutationsMap)
  const error = mutations.find((q) => q.error)?.error
  const isPending = mutations.some((q) => q.isPending)
  const allDataDefined = mutations.every((q) => isDefined(q.data))
  return handleMutationGuardResponse({
    data: allDataDefined
      ? (_mapValues(mutationsMap, (v) => v.data) as MultipleMutationsGuardProps<
          TData,
          TMutationsMap
        >)
      : undefined,
    isPending,
    error,
    ErrorElement,
    LoadingElement,
    children,
  })
}
