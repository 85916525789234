export const customBreakpoints = {
  windowsOld: 1367,
  assetDetailNews: 1400,
  windowsZoomed: 1600,
} as const

export const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920,
    ...customBreakpoints,
  },
}
