import {Box, Typography, Grid, Link as MuiLink} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import React, {useRef} from 'react'
import {Trans, useTranslation} from 'react-i18next'

import benefitsAPY from '../../assets/images/benefitsAPY.png'
import benefitsControl from '../../assets/images/benefitsControl.png'
import benefitsEasy from '../../assets/images/benefitsEasy.png'
import benefitsSafety from '../../assets/images/benefitsSafety.png'
import howDoesItWork from '../../assets/images/howDoesItWork.png'
import howDoIStart from '../../assets/images/howDoIStart.png'
import whatIsStaking from '../../assets/images/whatIsStaking.png'
import WhatIsStakingHeader from '../../assets/images/whatIsStakingHeader.png'
import {Icon} from '../../components'
import {externalLinks} from '../../constants'
import {useBackgroundImageStyles} from '../../utils/layoutUtils'

import {
  ImageTextRow,
  SupportedProtocols,
  HelpDeskSection,
  ContentsBar,
  StakingList,
  CardListItem,
} from './WhatIsStakingUtils'

function StakingLink() {
  const {t} = useTranslation()
  return (
    <Typography
      component="p"
      variant="body1"
      align="left"
      color="textSecondary"
    >
      <Trans
        i18nKey="staking_link"
        t={t}
        components={{
          StakingLink: (
            <MuiLink target="_blank" href={externalLinks.stakingInfo} />
          ),
        }}
      />
    </Typography>
  )
}

function StakingIntroduction({title}: {title: string}) {
  const {t} = useTranslation()

  const whatIsStakingItems = [
    t(
      'Proof of Stake (PoS) blockchains use staked crypto to determine who will verify and add new transactions to the next block. This is called block mining.',
    ),
    t(
      'The crypto used in block mining belongs to crypto holders worldwide who have staked their crypto using a wallet like NuFi. By staking, you make your crypto available to the blockchain, enabling it to work safely.',
    ),
    t(
      'While your crypto is staked and used in block mining it earns rewards for helping out. It’s like depositing cash into a savings account and earning interest from a bank.',
    ),
  ]

  return (
    <Box mt={2} pt={2}>
      <ImageTextRow
        textHeader={title}
        textSubHeader={t(
          'Staking is a way to earn regular passive income by letting the blockchain use your crypto for important tasks.',
        )}
        image={<img src={whatIsStaking} />}
        imagePosition="right"
      >
        <StakingList
          list={whatIsStakingItems}
          Icon={<Icon type="listIcon" />}
        />
      </ImageTextRow>
    </Box>
  )
}

function HowDoesItWork({title}: {title: string}) {
  const {t} = useTranslation()
  const howDoesItWorkItems = [
    t(
      'A validator is where you stake your crypto. The validator uses your staked crypto to mine new blocks on your behalf.',
    ),
    t(
      'Validators compete to mine each new block. The more crypto a validator has staked, the more collective power it has, and the more likely it is to be chosen.',
    ),
    t(
      'The chosen validator is rewarded by the blockchain and the reward is shared between the validator and the people whose crypto was used in the process.',
    ),
    t(
      'Staking ensures that the blockchain operates in a secure and decentralized way.',
    ),
  ]

  return (
    <Box mt={14} pt={2}>
      <ImageTextRow
        textHeader={title}
        textSubHeader={t(
          'On PoS blockchains, the job of mining new blocks is awarded to something called a validator.',
        )}
        image={<img src={howDoesItWork} />}
        imagePosition="left"
      >
        <StakingList
          list={howDoesItWorkItems}
          Icon={<Icon type="listIcon" />}
        />
      </ImageTextRow>
    </Box>
  )
}

function WhatAreBenefits({title}: {title: string}) {
  const {t} = useTranslation()
  const whatAreTheBenefitsItems = [
    {
      heading: t('High APYs'),
      text: t(
        'Annual percentage yield (APY) varies by blockchain but is significantly higher than APYs offered by traditional institutions like banks.',
      ),
      bg: benefitsAPY,
    },
    {
      heading: t('You are in control'),
      text: t(
        'NuFi’s wallet is non-custodial, meaning only you have access to your private keys and nobody can touch your crypto while it’s staked. Staking on Binance or on any other exchange’s custodial wallet means giving your private keys to a middleman and complying with their rules.',
      ),
      bg: benefitsControl,
    },
    {
      heading: t('Your funds are safe'),
      text: t(
        'In NuFi, your crypto doesn’t leave your wallet and you don’t transfer ownership of your crypto while staking. You can unstake at any time, though some blockchains require a minimum ‘cool down’ period before your crypto is fully unstaked and becomes available.',
      ),
      bg: benefitsSafety,
    },
    {
      heading: t('Easy to start'),
      text: t(
        'Unlike Bitcoin mining, the environmental impact of staking is minimal and staking on a PoS blockchain doesn’t require expensive equipment or technical expertise.',
      ),
      bg: benefitsEasy,
    },
  ]

  return (
    <Box mt={14} pt={2}>
      <Box mb={8} maxWidth={700} mx="auto" textAlign="center">
        <Typography paragraph variant="h4" component="h2">
          {title}
        </Typography>
        <Typography paragraph variant="h6" component="h3" color="textSecondary">
          {t(
            'While staking, you collect rewards periodically in the form of the crypto you stake. Rewards are paid directly into your account and, on most blockchains, are re-staked automatically.',
          )}
        </Typography>
      </Box>
      <Grid container spacing={3}>
        {whatAreTheBenefitsItems.map(({bg, text, heading}, i) => (
          <CardListItem key={i} {...{bg, text, heading}} />
        ))}
      </Grid>
    </Box>
  )
}

function HowDoIStart({title}: {title: string}) {
  const {t} = useTranslation()
  const howDoIStartItems = [
    t(
      'Create a staking account from the ‘My Staking’ tab, stake your crypto with a validator and then wait to collect rewards.',
    ),
    t(
      'Each blockchain runs on its own production schedule and may require a ‘warm up’ period before you start receiving rewards. After that, you’ll receive rewards directly into your account every epoch (a fixed period of time which regulates how often rewards are recorded, calculated, and paid).',
    ),
    t(
      'Some blockchains require a small refundable deposit when you first create a staking account.',
    ),
  ]

  return (
    <Box mt={14} pt={2}>
      <ImageTextRow
        textHeader={title}
        textSubHeader={t('It takes less than a minute to start staking.')}
        image={<img src={howDoIStart} />}
        imagePosition="right"
      >
        <StakingList list={howDoIStartItems} Icon={<Icon type="listIcon" />} />
        <Box mt={2}>
          <StakingLink />
        </Box>
      </ImageTextRow>
    </Box>
  )
}

export default function WhatIsStakingPage() {
  const classes = useStyles()
  const bgImageClass = useBackgroundImageStyles({image: WhatIsStakingHeader})
  const {t} = useTranslation()
  const contentSections = [
    {
      title: t('What is Staking?'),
      ref: useRef(),
      SectionComponent: StakingIntroduction,
    },
    {
      title: t('How does it work?'),
      ref: useRef(),
      SectionComponent: HowDoesItWork,
    },
    {
      title: t('What are the benefits?'),
      ref: useRef(),
      SectionComponent: WhatAreBenefits,
    },
    {title: t('How do I start?'), ref: useRef(), SectionComponent: HowDoIStart},
    {
      title: t('Supported protocols'),
      ref: useRef(),
      SectionComponent: SupportedProtocols,
    },
  ]

  return (
    <Grid container>
      <Grid item xs={10}>
        <Box mt={2} mb={3} mr={3}>
          <Box className={clsx(classes.headerCard, bgImageClass.bgImage)}>
            <Box className={classes.headerCardTextWrapper}>
              <Typography className={classes.headerText}>
                {t('Stake & Earn')}
              </Typography>
            </Box>
          </Box>
          {contentSections.map(({title, ref, SectionComponent}) => (
            <Box ref={ref} key={title}>
              <SectionComponent title={title} />
            </Box>
          ))}
          <Box>
            <HelpDeskSection />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={2}>
        <Box mt={2} mr={3}>
          <ContentsBar contentSections={contentSections} />
        </Box>
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles((theme) => ({
  headerCard: {
    height: '240px',
  },
  headerText: {
    color: theme.palette.common.white,
    fontSize: 48,
  },
  headerCardTextWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
}))
