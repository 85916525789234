import type {IdentitySecret} from '@nufi/wallet-common'

import type {LocalProfileId} from 'src/appStorage'
import {commonAppInit} from 'src/appStorage/initCoreServices'
import {setAvailableBlockchains} from 'src/features/availableBlockchains/application'
import {INIT_WALLET_CONFIGURATIONS} from 'src/features/walletStorage/application'
import {useMetamaskContext} from 'src/metamaskSnap/MetamaskContext'
import type {MetamaskContext} from 'src/metamaskSnap/MetamaskContext'
import {broadcastLoginToOtherTabs} from 'src/utils/loginBroadcast'
import {useMutation} from 'src/utils/mutation-utils'

import * as metamaskService from '../../services/metamaskLogin'

import {useSetMetamaskProfileLoggedIn} from './common'

export const snapSupportedBlockchains = ['cardano' as const]

const initMainAppMetamaskProfile = async (args: {
  profileId: LocalProfileId
  identitySecret: IdentitySecret
}) => {
  try {
    setAvailableBlockchains(snapSupportedBlockchains)
    await metamaskService.initExistingMetamaskProfile(args)
    await commonAppInit(
      INIT_WALLET_CONFIGURATIONS.getLoginToBlockchainRestrictedWalletArgs(
        'metamask',
        snapSupportedBlockchains,
      ),
    )
  } catch (err) {
    setAvailableBlockchains('all')
    throw err
  }
}

export const useInitMetamaskMainAppProfile = () =>
  useMutation('initMainAppMetamaskProfile', initMainAppMetamaskProfile)

const createAndInitMainAppMetamaskProfile = async (args: {
  identitySecret: IdentitySecret
}) => {
  try {
    setAvailableBlockchains(snapSupportedBlockchains)
    const profileMeta = await metamaskService.createNewMetamaskProfile({
      ...args,
      enabledBlockchains: snapSupportedBlockchains,
    })
    await commonAppInit(
      INIT_WALLET_CONFIGURATIONS.getCreateBlockchainRestrictedWalletArgs(
        'metamask',
        snapSupportedBlockchains,
      ),
    )
    return profileMeta
  } catch (err) {
    setAvailableBlockchains('all')
    throw err
  }
}

export const useCreateAndInitMainAppMetamaskProfile = () =>
  useMutation(
    'useCreateAndInitMainAppMetamaskProfile',
    createAndInitMainAppMetamaskProfile,
  )

export const useAutoLoginToMainAppMetamaskProfile = (options?: {
  skipBroadcastLogin: boolean
  onBeforeOnNewProfileLogin?: (logIn: () => void) => void
}) => {
  const setMetamaskLoggedIn = useSetMetamaskProfileLoggedIn()
  const metamaskContext = useMetamaskContext()

  return async () => {
    const {localProfileId, localProfileCreated} =
      await createOrInitMetamaskMainAppProfile(metamaskContext)

    if (!options?.skipBroadcastLogin) {
      await broadcastLoginToOtherTabs(localProfileId)
    }
    if (options?.onBeforeOnNewProfileLogin && localProfileCreated) {
      options?.onBeforeOnNewProfileLogin(setMetamaskLoggedIn)
      return
    }

    setMetamaskLoggedIn()
  }
}

const createOrInitMetamaskMainAppProfile = async (
  metamaskContext: MetamaskContext,
): Promise<{
  localProfileId: LocalProfileId
  localProfileCreated: boolean
}> => {
  const metamaskLoginData =
    await metamaskService.metamaskRawLogin(metamaskContext)
  const {profileMeta, identitySecret} = metamaskLoginData

  if (profileMeta) {
    await initMainAppMetamaskProfile({
      profileId: profileMeta.localProfileId,
      identitySecret,
    })
    return {
      localProfileId: profileMeta.localProfileId,
      localProfileCreated: false,
    }
  } else {
    const {localProfileId} = await createAndInitMainAppMetamaskProfile({
      identitySecret,
    })
    return {
      localProfileId,
      localProfileCreated: true,
    }
  }
}
