import type BigNumber from 'bignumber.js'
import {useState} from 'react'

import type {CommonValueFieldConstraints} from 'src/features/exchange/domain'

import type {
  AmountFieldDataProperties,
  AmountFieldMaxAmountData,
} from '../screens/common'

export type AmountFieldConstraintsAndData = {
  constraints: CommonValueFieldConstraints | null
  data: {fee?: BigNumber; maxAmount?: AmountFieldMaxAmountData} | null
}

export function useAmountFieldPropertiesState() {
  const initialData = {
    isLoading: true,
    data: undefined,
    error: null,
  }

  // We are storing `AmountFieldDataProperties` in extra state, as it is then
  // easier to use imperative logic compared to combining queries.
  // This allows for cases when fn containing imperative logic can be used
  // as the AmountField validation fn, and also its return value can be
  // stored in the reactive state that can later be passed to UI.
  const [data, setData] = useState<AmountFieldDataProperties>({
    fee: initialData,
    maxAmount: initialData,
  })

  const calculateAndSetData = (
    fn: () => Promise<AmountFieldConstraintsAndData>,
  ) => {
    setData((previous) => ({
      fee: {
        ...previous.fee,
        isLoading: true,
      },
      maxAmount: {
        ...previous.maxAmount,
        isLoading: true,
      },
    }))
    fn()
      .then(({data}) => {
        // We are ignoring error here as UI is not using it anyways.
        setData({
          fee: {
            data: data?.fee,
            isLoading: false,
          },
          maxAmount: {
            data: data?.maxAmount,
            isLoading: false,
          },
        })
      })
      .catch((error) => {
        setData((previous) => ({
          fee: {
            ...previous.fee,
            isLoading: false,
            error,
          },
          maxAmount: {
            ...previous.maxAmount,
            isLoading: false,
            error,
          },
        }))
      })
  }

  return {
    data,
    calculateAndSetData,
  }
}
