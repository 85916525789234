import {Box} from '@mui/material'
import React from 'react'

// When using `justify-content: center` and `align-items: center` and the centered content overflows
// it is being cropped out. See below issue:
// https://stackoverflow.com/questions/33454533/cant-scroll-to-top-of-flex-item-that-is-overflowing-container

type SafeCenterAlignerProps = {
  children: React.ReactNode
  className?: string
  height?: string
  fullWidth?: boolean
  padding?: number
}

export function SafeCenterAligner({
  children,
  className,
  height = '100%',
  fullWidth,
  padding,
}: SafeCenterAlignerProps) {
  return (
    <Box
      p={padding || 2}
      display="flex"
      height={height}
      className={className || ''}
    >
      <Box margin="auto" width={fullWidth ? '100%' : 'auto'}>
        {children}
      </Box>
    </Box>
  )
}
