import type {EvmBlockchain} from '@nufi/wallet-evm'
import BigNumber from 'bignumber.js'
import type {DebouncedFunc} from 'lodash'
import React, {useCallback, useEffect, useState} from 'react'

import {FullScreenLoading} from 'src/components'
import {getAsyncDebounce} from 'src/utils/debouncing'

import type {BaseSchema} from '../schema'

import type {GasFieldStatus} from './common'

/**
 * Validate amount for gas limit estimation - gas limit estimation happens before form validation
 * so we need to perform validation manually here. Gas limit estimation fails if a decimal number is
 * passed as amount i.e. if the number of decimals entered by the user is larger than the asset's
 * decimals count. For some chains (e.g. Arbitrum) gas limit estimation also requires that the
 * amount is less than the available balance.
 * @returns `amount` if it's valid, 0 otherwise.
 */
export function validAmountForGasLimitEstimationOrZero(
  amount: BigNumber | null,
  balance: BigNumber,
) {
  const isValidAmount =
    amount != null && amount.isInteger() && amount.isLessThanOrEqualTo(balance)
  return isValidAmount ? amount : new BigNumber(0)
}

export function getShouldRecomputeGasLimit(
  gasLimitStatus: GasFieldStatus | undefined,
) {
  return !gasLimitStatus?.changedByUser
}

type UseDebouncedGasLimitProps<
  TBlockchain extends EvmBlockchain,
  TGasLimitArgs,
> = {
  blockchain: TBlockchain
  setGasLimit: (gasLimit: string) => void
  getGasLimitEstimate: (
    blockchain: TBlockchain,
    gasLimitArgs: TGasLimitArgs,
  ) => Promise<BigNumber>
  timeoutMs?: number
  onFailure?: (error: Error) => void
}

export function useDebouncedGasLimit<
  TBlockchain extends EvmBlockchain,
  TGasLimitArgs,
>({
  blockchain,
  timeoutMs,
  getGasLimitEstimate,
  setGasLimit,
  onFailure,
}: UseDebouncedGasLimitProps<TBlockchain, TGasLimitArgs>) {
  const [showOverlay, setShowOverlay] = useState(false)

  type DebouncedFnParams = {
    gasLimitArgs: TGasLimitArgs
    values: BaseSchema
    gasLimitStatus: GasFieldStatus | undefined
  }

  const [getDebouncedGasLimit, setGetDebouncedGasLimit] =
    useState<DebouncedFunc<(params: DebouncedFnParams) => Promise<void>>>()

  const stableGetGasLimitEstimate = useCallback(
    async ({
      gasLimitArgs,
      values,
      gasLimitStatus,
    }: {
      gasLimitArgs: TGasLimitArgs
      values: BaseSchema
      gasLimitStatus: GasFieldStatus | undefined
    }) => {
      if (getShouldRecomputeGasLimit(gasLimitStatus)) {
        const result = await getGasLimitEstimate(blockchain, gasLimitArgs)
        return result.toString()
      }
      return values.gasLimit
    },
    [],
  )

  useEffect(() => {
    getDebouncedGasLimit?.cancel()

    setGetDebouncedGasLimit(() =>
      getAsyncDebounce<string, DebouncedFnParams>({
        onSuccess: setGasLimit,
        fn: stableGetGasLimitEstimate,
        timeout: timeoutMs || 500,
        onFailure:
          onFailure ||
          (() => {
            //
          }),
      }),
    )
  }, [stableGetGasLimitEstimate])

  // Cancel debounced function on unmount
  useEffect(() => {
    return () => {
      getDebouncedGasLimit?.cancel()
    }
  }, [])

  // The idea behind this callback is to ensure that at the time of submit,
  // we validate against up-to-date gas limit, as the last one could otherwise
  // be thrown away by the debounce.
  const createOnBeforeDetailsSubmit =
    ({values, gasLimitArgs, gasLimitStatus}: DebouncedFnParams) =>
    async () => {
      // Note that this runs for all, even unsuccessful submits
      getDebouncedGasLimit?.cancel()
      setShowOverlay(true)

      try {
        const gasLimit = await stableGetGasLimitEstimate({
          gasLimitArgs,
          values,
          gasLimitStatus,
        })
        setGasLimit(gasLimit)
      } catch (err) {
        // We simply ignore the error, add some error indicator if ever needed
      } finally {
        setShowOverlay(false)
      }
    }

  return {
    gasLimitSubmitOverlay: showOverlay ? <FullScreenLoading /> : null,
    createOnBeforeDetailsSubmit,
    getDebouncedGasLimit,
  }
}
