/* eslint-disable @typescript-eslint/explicit-function-return-type */
import type {
  OpStackBlockchain,
  OpStackBlockchainApiImpl,
} from '@nufi/wallet-evm'

import type {EvmWalletManagers} from '../../evmManagers'

import {base} from './base/managers'
import {optimism} from './optimism/managers'

export const getOpStackManagers = () => ({
  base,
  optimism,
})

export const getOpStackManager = <TBlockchain extends OpStackBlockchain>(
  blockchain: TBlockchain,
) => {
  const opStackManagers = getOpStackManagers()
  return opStackManagers[blockchain] as unknown as EvmWalletManagers<
    TBlockchain,
    (typeof opStackManagers)[TBlockchain]['networkConfig']['network'],
    OpStackBlockchainApiImpl<TBlockchain>
  >
}
