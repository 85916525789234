import {createArbitrumOneNetworkConfig} from '@nufi/wallet-evm'

import config from 'src/config'

import {createBaseEvmManagers} from '../../createEvmManagers'

export const arbitrumOne = createBaseEvmManagers({
  blockchain: 'arbitrumOne',
  network: config.arbitrumOneNetwork,
  apiUrl: config.arbitrumOneAlchemyApiUrl,
  createNetworkConfig: createArbitrumOneNetworkConfig,
})
