import {HelpOutline} from '@mui/icons-material'
import {Grid, Typography} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'

import {MultilineString, WithTooltip} from '../../../../components'

type ValidatorInfoItemProps = {
  itemName: string
  itemValue: string | React.ReactNode | undefined
  tooltipTitle?: string
}

export default function ValidatorInfoItem({
  itemName,
  itemValue,
  tooltipTitle,
}: ValidatorInfoItemProps) {
  const classes = useStyles()

  return (
    <Grid item xs={12} className={classes.item}>
      <Typography
        className={classes.itemName}
        variant="body2"
        color="textSecondary"
      >
        {itemName}:
      </Typography>
      <MultilineString variant="body2" label={itemValue || ''} />
      {tooltipTitle && (
        <WithTooltip title={<>{tooltipTitle}</>} fontVariant="medium">
          <HelpOutline className={classes.icon} fontSize="small" />
        </WithTooltip>
      )}
    </Grid>
  )
}

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'flex',
    marginBottom: theme.spacing(0.75),
    '& svg': {
      height: theme.typography.fontSize,
    },
  },
  itemName: {
    marginRight: theme.spacing(0.5),
    width: 90,
    flexShrink: 0,
  },
  icon: {
    position: 'relative',
    top: theme.spacing(0.25),
  },
}))
