import React from 'react'

import type {EvmBlockchain, EvmTokenId} from '../../../wallet/evm'
import {getEvmNetworkConfig} from '../../../wallet/evm'
import type {ExplorerLinkProps} from '../common'
import {ExplorerLink} from '../common'

import {getEvmTransactionExplorerLink} from './getLink'

export * from './getLink'

type EvmTransactionExplorerLinkProps = {
  blockchain: EvmBlockchain
  txId: string
}

export const EvmTransactionExplorerLink = ({
  blockchain,
  txId,
}: EvmTransactionExplorerLinkProps) => (
  <ExplorerLink link={getEvmTransactionExplorerLink(blockchain, txId)} />
)

type EvmTokenExplorerLinkProps<TBlockchain extends EvmBlockchain> = {
  blockchain: TBlockchain
  tokenId: EvmTokenId<TBlockchain>
} & Partial<Omit<ExplorerLinkProps, 'disabled' | 'link'>>

export const EvmTokenExplorerLink = <TBlockchain extends EvmBlockchain>({
  blockchain,
  tokenId,
  ...rest
}: EvmTokenExplorerLinkProps<TBlockchain>) => {
  const {blockExplorerUrl} = getEvmNetworkConfig(blockchain)
  return (
    <ExplorerLink link={`${blockExplorerUrl}/token/${tokenId}`} {...rest} />
  )
}
