/* eslint-disable @typescript-eslint/explicit-function-return-type */

import type {CardanoTokenId} from '@nufi/wallet-cardano'
import type {AccountId} from '@nufi/wallet-common'
import {arraySum} from '@nufi/wallet-common'
import BigNumber from 'bignumber.js'

import {useNeverStaleQuery} from 'src/utils/query-utils'

import {getCardano} from '../../cardanoManagers'

import {cachedGetAccounts} from './core'
import {cachedGetTotalNativeBalance} from './staking'
import {getTokenTransactionHistory} from './tokens'
import {cachedGetTokens} from './tokens/metadata'
import {QUERY_KEY_PREFIX as P} from './utils'

export const combinedQueryKeys = {
  balance: {
    index: [P, 'balance'],
    perAccount: (tokenId: CardanoTokenId | null) => [
      ...combinedQueryKeys.balance.index,
      'perAccount',
      tokenId,
    ],
    total: (tokenId: CardanoTokenId | null) => [
      ...combinedQueryKeys.balance.index,
      'total',
      tokenId,
    ],
  },
  txHistory: {
    index: [P, 'txHistory'],
    account: (accountId: AccountId, tokenId: CardanoTokenId | null) => [
      ...combinedQueryKeys.txHistory.index,
      'accountId',
      accountId,
      'token',
      tokenId,
    ],
  },
}

export function useGetBalancesPerAccount(
  tokenId: CardanoTokenId | null,
  enabled = true,
) {
  return useNeverStaleQuery({
    queryKey: combinedQueryKeys.balance.perAccount(tokenId),
    queryFn: async () => {
      const accounts = await cachedGetAccounts()
      if (tokenId == null) {
        return accounts.map((accountInfo) => ({
          accountId: accountInfo.id,
          balance: accountInfo.balance,
        }))
      } else {
        return accounts.map((account) => ({
          accountId: account.id,
          balance: arraySum(
            account.tokensBalance
              .filter((tb) => tb.token.id === tokenId)
              .map((tb) => tb.amount),
          ),
        }))
      }
    },
    enabled,
  })
}

export function useGetTotalBalance(
  tokenId: CardanoTokenId | null,
  enabled = true,
) {
  return useNeverStaleQuery({
    queryKey: combinedQueryKeys.balance.total(tokenId),
    queryFn: async () => {
      if (tokenId == null) {
        return await cachedGetTotalNativeBalance()
      } else {
        const tokens = await cachedGetTokens()
        const tokenAmount = tokens.find(
          (tokenAmount) => tokenAmount.token.id === tokenId,
        )
        return tokenAmount != null ? tokenAmount.amount : new BigNumber(0)
      }
    },
    enabled,
  })
}

export function useGetTransactionHistory(
  accountId: AccountId,
  tokenId: CardanoTokenId | null,
  enabled = true,
) {
  return useNeverStaleQuery({
    queryKey: combinedQueryKeys.txHistory.account(accountId, tokenId),
    queryFn: async () => {
      const txHistory = await getCardano().accountManager.getTransactionHistory(
        getCardano().accountsStore.getAccount(accountId as AccountId),
      )
      if (tokenId == null) {
        return txHistory
      } else {
        return getTokenTransactionHistory(tokenId, txHistory)
      }
    },
    enabled,
  })
}
