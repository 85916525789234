import {HelpOutline as HelpIcon} from '@mui/icons-material'
import {Box, Checkbox, FormControlLabel} from '@mui/material'
import React from 'react'
import {Trans, useTranslation} from 'react-i18next'

import {usePlatformConfig} from 'src/dappConnector/PlatformConfig'
import {
  useCreateAndInitWeb3AuthProfile,
  useSetRegularLoggedIn,
  useSetWeb3AuthLoggedIn,
} from 'src/features/login'
import type {Web3AuthUserInfo} from 'src/features/login/domain'
import {createWeb3AuthPassword} from 'src/features/login/services'
import {ExternalProvidersWrapper} from 'src/pages/profile/common'
import {invalidateAllQueryClientQueries} from 'src/utils/query-utils'

import type {
  LocalProfileId,
  ProfilePassword,
  ProfileMetadata,
} from '../../../appStorage'
import {
  filterVisibleProfiles,
  useLastLoggedInLocalProfileId,
  useProfileInfos,
} from '../../../appStorage'
import {
  BatchQueryGuard,
  CenteredError,
  MutationGuard,
  WithTooltip,
  TransBold,
  MultipleMutationsGuard,
} from '../../../components'
import {LoginForm} from '../../../pages/profile/Login'
import {Web3AuthLoginFlow} from '../../../pages/profile/Web3AuthLogin'
import {useDappConnectorStore} from '../../../store/dappConnector'
import type {Blockchain, Mnemonic} from '../../../types'
import {assert} from '../../../utils/assertion'
import {RejectButton, ActionButtons, OriginMessage} from '../../components'
import {OUTER_CONTENT_WIDTH} from '../../constants'
import {useInitDappConnectorProfile} from '../../mutations/profile'

import type {RememberLoginState} from './utils'
import {
  InitNotPossible,
  ReturnToDappMessage,
  WithRememberLoginState,
} from './utils'

type ChooseProfileScreenProps = {
  onNext: () => void
  onReject: () => void
  blockchain: Blockchain
}

export function ChooseProfileScreen(props: ChooseProfileScreenProps) {
  return (
    <WithRememberLoginState>
      {(rememberLoginData) => (
        <ChooseProfileScreenContent {...props} {...rememberLoginData} />
      )}
    </WithRememberLoginState>
  )
}

type Web3AuthLoginDappConnectorProps = {
  rememberLogin: boolean
  blockchain: Blockchain
  onNext: () => void
  hasOtherLoginOptions?: boolean
}

function Web3AuthLoginDappConnector({
  rememberLogin,
  blockchain,
  onNext,
  hasOtherLoginOptions = true,
}: Web3AuthLoginDappConnectorProps) {
  const {t} = useTranslation()
  const profileLogin = useInitDappConnectorProfile(blockchain, 'web3Auth')
  const initProfile = useCreateAndInitWeb3AuthProfile()
  const setLoggedIn = useSetWeb3AuthLoggedIn()
  const {rememberLoginHandlers} = usePlatformConfig()
  const onLogIntoExistingProfile = async ({
    profileMnemonic,
    web3AuthUserInfo,
    profileId,
  }: {
    profileId: LocalProfileId
    profileMnemonic: Mnemonic
    web3AuthUserInfo: Web3AuthUserInfo
  }) => {
    await profileLogin.mutateAsync({
      profileId,
      password: createWeb3AuthPassword(profileMnemonic),
    })

    setLoggedIn(web3AuthUserInfo)
    rememberLoginHandlers?.saveRememberLogin({
      rememberLogin,
      profileId,
      password: createWeb3AuthPassword(profileMnemonic),
      web3AuthUserInfo,
    })

    onNext()
  }

  const onLogIntoNewProfile = async ({
    profileMnemonic,
    web3AuthUserInfo,
    blockchains,
  }: {
    profileMnemonic: Mnemonic
    web3AuthUserInfo: Web3AuthUserInfo
    blockchains: Blockchain[]
  }) => {
    const newProfileMeta = await initProfile.mutateAsync({
      web3AuthUserInfo,
      profileMnemonic,
      blockchains,
    })
    onLogIntoExistingProfile({
      profileId: newProfileMeta.localProfileId,
      profileMnemonic,
      web3AuthUserInfo,
    })
  }

  return (
    <ExternalProvidersWrapper
      title={
        !hasOtherLoginOptions
          ? t('Log in with a social account to open a wallet.')
          : undefined
      }
    >
      <MultipleMutationsGuard
        mutations={{
          profileLogin,
          initProfile,
        }}
      />
      <Web3AuthLoginFlow
        preselectedBlockchains={[blockchain]}
        onLogIntoExistingProfile={onLogIntoExistingProfile}
        onLogIntoNewProfile={onLogIntoNewProfile}
        hasOtherLoginOptions={hasOtherLoginOptions}
      />
    </ExternalProvidersWrapper>
  )
}

export type _ChooseProfileScreenProps = ChooseProfileScreenProps &
  RememberLoginState & {
    profiles: ProfileMetadata[]
    lastLoggedInLocalProfileId: LocalProfileId | null
    onLogin: (values: {
      profileId: LocalProfileId
      password: ProfilePassword
    }) => Promise<unknown>
    onReload: () => Promise<void>
  }

export function _ChooseProfileScreenContent({
  onNext,
  onReject,
  blockchain,
  rememberLogin,
  setRememberLogin,
  profiles,
  lastLoggedInLocalProfileId,
  onLogin,
  onReload,
}: _ChooseProfileScreenProps) {
  const {t} = useTranslation()

  const onRememberLoginChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRememberLogin(e.target.checked)
  }
  const {origin, favIconUrl} = useDappConnectorStore()
  const {rememberLoginHandlers} = usePlatformConfig()

  assert(origin != null, 'OriginInfo: origin not provided')

  return (
    <>
      <OriginMessage
        message={t('Wants to connect to your NuFi wallet')}
        {...{origin, favIconUrl}}
      />
      <Box mt={4} />
      {filterVisibleProfiles(profiles).length > 0 ? (
        <>
          <Box width={OUTER_CONTENT_WIDTH}>
            <LoginForm
              {...{profiles, lastLoggedInLocalProfileId, onLogin}}
              submitButtonProps={{
                renderControls: (SubmitButton) => (
                  <ActionButtons
                    marginTop={0}
                    leftButton={<RejectButton onClick={onReject} />}
                    rightButton={SubmitButton}
                  />
                ),
                message: t('Connect'),
                textTransform: 'none',
              }}
              formEndContent={
                <>
                  {/* `fit-content` needed so that the tooltip activation
                  area does not span the whole available width */}
                  <Box width="fit-content">
                    <Web3AuthLoginDappConnector
                      onNext={onNext}
                      rememberLogin={rememberLogin}
                      blockchain={blockchain}
                    />
                  </Box>
                  <Box width="fit-content">
                    <WithTooltip
                      title={<>{t('keep_me_logged_in_info')}</>}
                      disableInteractive
                    >
                      {rememberLoginHandlers && (
                        <>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={rememberLogin}
                                onChange={onRememberLoginChange}
                              />
                            }
                            label={<>{t('Keep me logged in')}</>}
                          />
                          <Box display="flex" alignItems="center" ml={-1}>
                            <HelpIcon fontSize="small" />
                          </Box>
                        </>
                      )}
                    </WithTooltip>
                  </Box>
                </>
              }
            />
          </Box>
        </>
      ) : profiles.length === 0 ? (
        <>
          <InitNotPossible
            intent={{
              type: 'openLoggedOut',
            }}
            onReload={onReload}
            primaryMessage={t('no_profile_primary_message')}
            secondaryMessage={
              <>
                <Trans
                  i18nKey="no_profile_secondary_message"
                  t={t}
                  components={{
                    bold: TransBold,
                  }}
                />

                <ReturnToDappMessage />
              </>
            }
          />
          <Box width="fit-content">
            <Web3AuthLoginDappConnector
              blockchain={blockchain}
              onNext={onNext}
              rememberLogin={rememberLogin}
            />
          </Box>
        </>
      ) : (
        <Box width="fit-content">
          <Web3AuthLoginDappConnector
            blockchain={blockchain}
            onNext={onNext}
            rememberLogin={rememberLogin}
            hasOtherLoginOptions={false}
          />
        </Box>
      )}
    </>
  )
}

export function ChooseProfileScreenContent({
  onNext,
  onReject,
  blockchain,
  rememberLogin,
  setRememberLogin,
}: ChooseProfileScreenProps & RememberLoginState) {
  const lastLoggedInLocalProfileIdQuery = useLastLoggedInLocalProfileId()
  const profileInfosQuery = useProfileInfos()
  const {t} = useTranslation()
  const profileLogin = useInitDappConnectorProfile(blockchain, 'password')
  const {screenInfo} = useDappConnectorStore()
  const setRegularLoggedIn = useSetRegularLoggedIn()
  const {rememberLoginHandlers} = usePlatformConfig()

  assert(screenInfo.state === 'init', 'ChooseAccount: invalid screen type')

  const onLogin = async (values: {
    profileId: LocalProfileId
    password: ProfilePassword
  }) => {
    await profileLogin.mutateAsync(values)
    rememberLoginHandlers?.saveRememberLogin({rememberLogin, ...values})
    setRegularLoggedIn()
    onNext()
  }

  return (
    <>
      <BatchQueryGuard
        queries={{
          profiles: profileInfosQuery,
          lastLoggedInLocalProfileId: lastLoggedInLocalProfileIdQuery,
        }}
        ErrorElement={<CenteredError error={t('Could not load wallet info')} />}
        loadingVariant="centered"
      >
        {({profiles, lastLoggedInLocalProfileId}) => (
          <_ChooseProfileScreenContent
            onNext={onNext}
            onReject={onReject}
            blockchain={blockchain}
            rememberLogin={rememberLogin}
            setRememberLogin={setRememberLogin}
            onLogin={onLogin}
            profiles={profiles}
            onReload={async () => {
              // We invalidate all queries as it is safer when we have denormalized cache
              await invalidateAllQueryClientQueries()
            }}
            lastLoggedInLocalProfileId={lastLoggedInLocalProfileId}
          />
        )}
      </BatchQueryGuard>
      <MutationGuard {...profileLogin} error={null} />
    </>
  )
}
