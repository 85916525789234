import {isHumanReadable} from '@nufi/frontend-common'

import type {
  CardanoTokenId,
  CardanoAccountInfo,
  CardanoTokenMetadata,
} from '../../../wallet/cardano'
import {formatAssetNameHex} from '../../../wallet/cardano'
import {useLoadTokens} from '../loadTokens'
import type {
  UseAssetsConversionParams,
  UseBlockchainAssetsReturnType,
} from '../types'

type CardanoTokenLabelStructure = {
  assetName?: string
  fingerprint: string
}

// avoid formatting outside the component, e.g. using ellipsize
export function getCardanoTokenLabelStructure(
  tokenInfo: CardanoTokenMetadata,
): CardanoTokenLabelStructure {
  const {assetNameHex, fingerprint} = tokenInfo
  const formattedAssetName = assetNameHex && formatAssetNameHex(assetNameHex)
  if (isHumanReadable(formattedAssetName)) {
    return {assetName: formattedAssetName, fingerprint}
  }
  return {fingerprint}
}

// used for sorting with full, non ellipsized data, so no info is lost
export function getCardanoTokenSortingLabel(tokenInfo: CardanoTokenMetadata) {
  const structure = getCardanoTokenLabelStructure(tokenInfo)
  const primaryLabel = `${
    tokenInfo.name || structure.assetName || tokenInfo.ticker || ''
  }`

  return primaryLabel !== ''
    ? `${primaryLabel} (${structure.fingerprint})`
    : `${structure.fingerprint}`
}

function getNumAccountsOwningToken({
  accounts,
  tokenId,
}: {
  accounts: CardanoAccountInfo[]
  tokenId: CardanoTokenId
}) {
  return accounts.filter((account) =>
    account.tokensBalance.map((t) => t.token.id).includes(tokenId),
  ).length
}

export function useCardanoTokens({
  conversionRates,
  currency,
  enabled,
}: UseAssetsConversionParams): UseBlockchainAssetsReturnType {
  return useLoadTokens({
    blockchain: 'cardano',
    conversionRates,
    currency,
    getNumAccountsOwningToken,
    getTokenSortingLabel: getCardanoTokenSortingLabel,
    getDefaultAccountId: (defaultAccountId, {accounts, tokenId}) =>
      accounts.find((account) =>
        account.tokensBalance.some(({token}) => token.id === tokenId),
      )?.id || defaultAccountId,
    enabled,
  })
}
