import React from 'react'

import type {AccountInfo} from '../../../types'
import type {EvmAccountInfo, EvmBlockchain} from '../../../wallet/evm'
import {useAddAccounts, useDiscoverAllEvmAccounts} from '../../../wallet/evm'
import {MAX_ACCOUNT_COUNT} from '../../../wallet/evm/constants'
import {useDerivationPathTypeState} from '../sharedActions/addAccount/AddAccountUtils'
import {
  AddHwAccount,
  useAddHwAccountState,
} from '../sharedActions/addAccount/AddHwAccount'

import {
  useDerivationPathTypeConfig,
  useMergedEvmAccountsAlert,
} from './AddAccountUtils'

export function AddLedgerAccount<TBlockchain extends EvmBlockchain>({
  blockchain,
}: {
  blockchain: TBlockchain
}) {
  const addHwAccountState = useAddHwAccountState()
  const derivationPathTypeConfig = useDerivationPathTypeConfig('ledger')

  const derivationPathTypeState = useDerivationPathTypeState(
    'Bip44Address',
    derivationPathTypeConfig,
  )
  const alert = useMergedEvmAccountsAlert(blockchain)

  const discoveredAccountsQuery = useDiscoverAllEvmAccounts(
    blockchain,
    'ledger',
    derivationPathTypeState.derivationPathType,
  )
  const addAccounts = useAddAccounts()

  const onSubmit = async (newAccounts: AccountInfo[]) => {
    await addAccounts.mutateAsync({
      newAccounts: newAccounts as EvmAccountInfo<TBlockchain>[],
    })
  }

  return (
    <AddHwAccount
      hwVendor="ledger"
      blockchain={blockchain}
      addAccountsMutation={addAccounts}
      {...{
        discoveredAccountsQuery,
        onSubmit,
        derivationPathTypeState,
        ...addHwAccountState,
      }}
      extras={{
        maxAccountLimit: MAX_ACCOUNT_COUNT,
        prevMustBeUsed: true,
        alert,
      }}
    />
  )
}
