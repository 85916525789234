/* eslint-disable @typescript-eslint/explicit-function-return-type */

import _, {keyBy} from 'lodash'

import queryClient from '../../queryClient'
import {TokenVisibilityStoreManagerProvider} from '../TokenVisibilityStoreManager'
import type {AccountId, AccountInfo, Blockchain, Nft, TokenId} from '../types'

export type AllNftsResult<T extends TokenId, N extends Nft> = {
  nftsByAccount: Record<AccountId, N[]>
  allNfts: Record<T, N>
}

function applyNftVisibility<T extends TokenId, N extends Nft>(
  blockchain: Blockchain,
  nftsByAccount: AllNftsResult<T, N>['nftsByAccount'],
) {
  const tokenVisibilitiesMap = keyBy(
    TokenVisibilityStoreManagerProvider.instance()
      .getTokenVisibilities()
      .filter((tv) => tv.blockchain === blockchain),
    (tv) => tv.tokenId,
  )
  const _nftsByAccount = Object.fromEntries(
    Object.entries(nftsByAccount).map(([account, nfts]) => {
      const nftsWithVisibility = nfts.map((nft) => {
        const match = tokenVisibilitiesMap[nft.id]
        return match ? {...nft, isHidden: match.visibility === 'hidden'} : nft
      })
      return [account, nftsWithVisibility]
    }),
  )
  return _nftsByAccount
}

function processAllNfts<T extends TokenId, N extends Nft>(
  nftsByAccount: AllNftsResult<T, N>['nftsByAccount'],
  blockchain: Blockchain,
): AllNftsResult<T, N> {
  const _nftsByAccount = applyNftVisibility<T, N>(blockchain, nftsByAccount)
  const uniqueNftsByAccount: AllNftsResult<T, N>['nftsByAccount'] = _.mapValues(
    _nftsByAccount,
    (v) => _.uniqBy(v, (token) => token.id),
  )

  const allNftsArray = _.uniqBy(
    _.flatten(Object.values(_nftsByAccount)),
    (token) => token.id,
  )

  const allNfts = _.keyBy(allNftsArray, (token) => token.id) as AllNftsResult<
    T,
    N
  >['allNfts']

  return {
    nftsByAccount: uniqueNftsByAccount,
    allNfts,
  }
}

async function prefetchNfts<N extends Nft>(
  nfts: Record<TokenId, N>,
  getSingleNftQueryKey: (n: N) => unknown[],
): Promise<void> {
  for (const n of Object.values(nfts)) {
    await queryClient.prefetchQuery({
      queryKey: getSingleNftQueryKey(n),
      queryFn: () => n,
      staleTime: Infinity,
    })
  }
}

export const getAllNfts = async <
  A extends AccountInfo,
  T extends TokenId,
  N extends Nft,
>({
  blockchain,
  accounts,
  getAccountNfts,
  getSingleNftQueryKey,
}: {
  blockchain: Blockchain
  accounts: A[]
  getAccountNfts: (account: A) => Promise<N[]>
  getSingleNftQueryKey: (nft: N) => (string | null)[]
}) => {
  const _nftsByAccount: AllNftsResult<T, N>['nftsByAccount'] = {}

  for (const account of accounts) {
    const {id} = account
    const accountsNfts = await getAccountNfts(account)
    _nftsByAccount[id] = [...(_nftsByAccount[id] || []), ...accountsNfts]
  }

  const result = processAllNfts(_nftsByAccount, blockchain)

  await prefetchNfts(result.allNfts, getSingleNftQueryKey)

  return result
}
