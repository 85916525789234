import React from 'react'
import {useTranslation} from 'react-i18next'
import {Route, Switch, useHistory} from 'react-router-dom'

import {assetDetailBlockchains} from '../../../blockchainTypes'
import {routeTo} from '../../../router'
import type {BlockchainAssetDetailRoutes} from '../../../router/portfolio'
import {useAssetDetailRouteOptions} from '../../../router/portfolio'
import type {Blockchain} from '../../../types'
import {isBlockchainSubset} from '../../../utils/blockchainGuards'
import {ExchangeDetailModal, ExchangeModal} from '../../exchange'
import {ReceiveModal} from '../../receive'
import {SendModal} from '../../send/SendModal'

import {BlockchainAssetDetail} from './BlockchainAssetDetail'
import {SectionNotSupportedForBlockchain} from './Helpers'

type AssetDetailTemplateProps = {
  routes: BlockchainAssetDetailRoutes
  parentSectionLabel: string
  onBack: () => void
}

const DetailRenderer = ({
  routes,
  parentSectionLabel,
  onBack,
  blockchain,
}: AssetDetailTemplateProps & {blockchain: Blockchain}) => {
  if (!isBlockchainSubset(blockchain, assetDetailBlockchains)) {
    return (
      <SectionNotSupportedForBlockchain
        blockchain={blockchain}
        section="asset_detail"
      />
    )
  }

  return (
    <BlockchainAssetDetail
      {...{
        onBack,
        parentSectionLabel,
        routes,
        blockchain,
      }}
    />
  )
}

const getBlockchainDetailRoute = (routes: BlockchainAssetDetailRoutes) =>
  routes.blockchain(':blockchain').detail

const getAccountDetailRoute = (routes: BlockchainAssetDetailRoutes) =>
  getBlockchainDetailRoute(routes).account(':accountId')

export const AssetDetailTemplate = ({
  routes,
  parentSectionLabel,
  onBack,
}: AssetDetailTemplateProps) => {
  const {blockchain} = useAssetDetailRouteOptions()
  const history = useHistory()
  const onClose = () => history.goBack()

  return (
    <>
      <Route path={getAccountDetailRoute(routes).tab(':tab').index}>
        <DetailRenderer {...{routes, parentSectionLabel, onBack, blockchain}} />
      </Route>
      <Route path={getBlockchainDetailRoute(routes).preview}>
        <DetailRenderer {...{routes, parentSectionLabel, onBack, blockchain}} />
      </Route>
      <Switch>
        <Route path={getAccountDetailRoute(routes).tab(':tab').send}>
          <SendModal onClose={onClose} />
        </Route>
        <Route path={getAccountDetailRoute(routes).tab(':tab').receive}>
          <ReceiveModal onClose={onClose} />
        </Route>
        <Route
          path={getAccountDetailRoute(routes)
            .tab('history')
            .exchangeDetail(':exchangeId')}
        >
          <ExchangeDetailModal onClose={onClose} />
        </Route>
        <Route path={getAccountDetailRoute(routes).tab(':tab').exchange}>
          <ExchangeModal onClose={onClose} />
        </Route>
      </Switch>
    </>
  )
}

export const AssetDetail = () => {
  const {t} = useTranslation()
  const history = useHistory()
  return (
    <AssetDetailTemplate
      routes={routeTo.portfolio.assets}
      parentSectionLabel={t('Portfolio Manager')}
      onBack={() => history.push(routeTo.portfolio.assets.index)}
    />
  )
}

export const NftDetail = () => {
  const {t} = useTranslation()
  const history = useHistory()

  return (
    <AssetDetailTemplate
      routes={routeTo.portfolio.nfts}
      parentSectionLabel={t('NFT Gallery')}
      onBack={() => history.goBack()}
    />
  )
}
