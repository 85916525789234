import type {StateCreator, SetState, GetState, StoreApi, State} from 'zustand'

import config from '../config'

const removeActions = <S extends State>(state: S) =>
  Object.entries(state)
    .filter(([, value]) => typeof value !== 'function')
    .reduce((res, [key, value]) => ({...res, [key]: value}), {})

const logStateToConsole = <S extends State>(key: string, state: S) => {
  if (!config.isProd) {
    const css = 'color: #40826a; font-weight: bold;'
    // eslint-disable-next-line no-console
    console.log('%c%s', css, key, removeActions(state))
  }
}

// https://github.com/pmndrs/zustand/issues/75#issuecomment-554188107
const logger =
  <S extends State>(key: string) =>
  (c: StateCreator<S>) =>
  (set: SetState<S>, get: GetState<S>, api: StoreApi<S>) =>
    c(
      (args) => {
        set(args)
        logStateToConsole(key, get())
      },
      get,
      api,
    )

logger.log = logStateToConsole

export default logger
