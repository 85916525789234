import BigNumber from 'bignumber.js'
import _ from 'lodash'
import {useMemo} from 'react'

import type {QueryResultStateProperties} from 'src/utils/query-utils'

import type {TokenBlockchain} from '../../blockchainTypes'
import type {TokenMetadata, AccountInfo, TokenId, AccountId} from '../../wallet'
import {
  getDefaultAccountId,
  useGetAccounts,
  useGetTokensMetadata,
  useTokens,
  getTokenSearchingValues,
  isNft,
} from '../../wallet'
import {getTokenPriceChange} from '../conversionRates/application'

import {getHasSomeError, getHasSomeIsLoading} from './common'
import {convertBalance} from './domain/conversions'
import type {
  AssetRow,
  AssetRowCurrencyProps,
  UseAssetsConversionParams,
  UseBlockchainAssetsReturnType,
} from './types'

type ExtraQuery<T_Data> = QueryResultStateProperties & {data: T_Data}

type DefinedExtraQueries<
  T_ExtraQueryData,
  T_ExtraQueries extends Record<string, ExtraQuery<T_ExtraQueryData>>,
> = {
  [Property in keyof T_ExtraQueries]: Exclude<
    T_ExtraQueries[Property]['data'],
    undefined
  >
}

type CommonInjectedDeps<T_Account, T_TokenId> = {
  accounts: T_Account[]
  tokenId: T_TokenId
}

type Params<
  T_ExtraQueryData,
  T_ExtraQueries extends Record<string, ExtraQuery<T_ExtraQueryData>>,
  T_Account,
  T_TokenId,
  T_TokenMeta,
> = UseAssetsConversionParams & {
  blockchain: TokenBlockchain
  getTokenSortingLabel: (meta: T_TokenMeta) => string
  getNumAccountsOwningToken: (
    deps: CommonInjectedDeps<T_Account, T_TokenId>,
    extraQueries: DefinedExtraQueries<T_ExtraQueryData, T_ExtraQueries>,
  ) => number
  getDefaultAccountId?: (
    defaultAccountId: AccountId | undefined,
    deps: CommonInjectedDeps<T_Account, T_TokenId>,
    extraQueries: DefinedExtraQueries<T_ExtraQueryData, T_ExtraQueries>,
  ) => AccountId | undefined
  extraQueries?: T_ExtraQueries
}

export function useLoadTokens<
  T_ExtraQueryData,
  T_ExtraQueries extends Record<string, ExtraQuery<T_ExtraQueryData>>,
  T_Account extends AccountInfo,
  T_TokenId extends TokenId,
  T_TokenMeta extends TokenMetadata,
>({
  enabled,
  conversionRates,
  currency,
  blockchain,
  getTokenSortingLabel,
  getNumAccountsOwningToken,
  getDefaultAccountId: _getDefaultAccountId,
  // This hook will wait for these queries to finish and will inject their
  // resolved defined values into respective functions
  extraQueries = {} as T_ExtraQueries,
}: Params<
  T_ExtraQueryData,
  T_ExtraQueries,
  T_Account,
  T_TokenId,
  T_TokenMeta
>): UseBlockchainAssetsReturnType {
  const tokensQuery = useTokens(blockchain, enabled)
  const accountsQuery = useGetAccounts(blockchain, enabled)
  const tokensMetadataQuery = useGetTokensMetadata(blockchain, enabled)

  const hasError = getHasSomeError([
    accountsQuery,
    tokensMetadataQuery,
    ...Object.values(extraQueries),
  ])

  const isLoading = getHasSomeIsLoading([
    accountsQuery,
    tokensMetadataQuery,
    ...Object.values(extraQueries),
  ])

  const data = useMemo(() => {
    const _data: AssetRow[] = []
    if (
      enabled &&
      tokensQuery?.data &&
      accountsQuery.data &&
      tokensMetadataQuery.data &&
      Object.values(extraQueries).every((q) => q.data)
    ) {
      const tokens = tokensQuery.data
      const nativeAccounts = accountsQuery.data as T_Account[]
      const defaultAccountId = getDefaultAccountId<T_Account>(nativeAccounts)
      const tokensMetadata = tokensMetadataQuery.data as T_TokenMeta[]

      for (const tokenData of tokens) {
        const {amount, token} = tokenData
        const tokenId = token.id as T_TokenId

        const metadata = tokensMetadata.find((s) => s.id === tokenId)
        // When adding a new account, token & metadata are loaded separately, skipping the token
        // if the metadata is not loaded yet
        if (!metadata) continue

        const definedExtraQueries = _.mapValues(
          extraQueries,
          (q) => q.data,
        ) as DefinedExtraQueries<T_ExtraQueryData, T_ExtraQueries>

        const balancesToCurrency: AssetRowCurrencyProps = (() => {
          const convertedBalanceRes = convertBalance({
            balance: amount,
            blockchain,
            currency,
            conversionRates: conversionRates.rates,
            tokenMetadata: metadata,
          })
          return {
            totalBalanceToCurrency: convertedBalanceRes,
            availableBalanceToCurrency: convertedBalanceRes,
            price: convertBalance({
              balance: new BigNumber(1).shiftedBy(metadata.decimals),
              blockchain,
              currency,
              conversionRates: conversionRates.rates,
              tokenMetadata: metadata,
            }),
            priceChange: getTokenPriceChange(
              blockchain,
              tokenId,
              currency,
              conversionRates.rates,
            ),
          }
        })()

        const commonInjectedDeps = {
          accounts: nativeAccounts,
          tokenId,
        }

        const sortingLabel = getTokenSortingLabel(metadata).toLocaleLowerCase()
        const tokenSearchingValues = getTokenSearchingValues(metadata)

        _data.push({
          blockchain,
          totalBalance: amount,
          availableBalance: amount,
          ...balancesToCurrency,
          owningAccountsCount: getNumAccountsOwningToken(
            commonInjectedDeps,
            definedExtraQueries,
          ),
          tokenMetadata: metadata,
          defaultAccountId: _getDefaultAccountId
            ? _getDefaultAccountId(
                defaultAccountId,
                commonInjectedDeps,
                definedExtraQueries,
              )
            : defaultAccountId,
          sortingLabel,
          searchingLabels: tokenSearchingValues,
          isNft: isNft(metadata),
        })
      }
    }
    return _data
  }, [
    enabled,
    tokensQuery?.data,
    accountsQuery.data,
    tokensMetadataQuery.data,
    conversionRates,
    ...Object.values(extraQueries).map((q) => q.data),
  ])

  return {
    blockchain,
    data,
    isLoading,
    hasError,
    assetType: 'tokens',
  }
}
