import {useParams} from 'react-router-dom'

import type {NftBlockchain} from '../blockchainTypes'
import type {AccountId} from '../wallet'

export type NFTPathParams = {
  blockchain: NftBlockchain | 'none'
  accountId?: AccountId
}

export const useNFTRouteOptions = () => {
  const pathParams = useParams<NFTPathParams>()

  return {
    blockchain: pathParams.blockchain,
    accountId: pathParams.accountId,
  }
}
