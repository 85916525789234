import {Error} from '@mui/icons-material/'
import {Typography, Box} from '@mui/material'
import type BigNumber from 'bignumber.js'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {
  IconCol,
  AssetIcon,
  FormattedTokenName,
  CopyToClipboard,
  TypographyLoader,
  LabeledIcon,
  ellipsizeString,
  MaxWidthEllipsize,
} from 'src/components'
import {useGetNativeAssetName} from 'src/utils/translations'
import type {Blockchain, TokenId, TokenMetadata} from 'src/wallet'

import {AssetAmount} from './AssetAmount'
import {RowSpaceBetweenWrapper} from './TxAccordionRow'
import {WithOverflowTooltip} from './WithOverflowTooltip'

type AssetItemProps = {
  blockchain: Blockchain
  amount: BigNumber | null
  extraAssetContent?: React.ReactNode
  withAmountSignPrefix?: boolean
} & (
  | {
      type: 'token'
      tokenMetadata: TokenMetadata
      tokenInfoItems: {value: string; key: string}[]
    }
  | {type: 'native'}
)

export const AssetMovementRow = ({
  blockchain,
  amount,
  extraAssetContent,
  withAmountSignPrefix,
  ...rest
}: AssetItemProps) => {
  const getNativeAssetName = useGetNativeAssetName()
  const {t} = useTranslation()

  const isToken = rest.type === 'token'

  return (
    <AssetMovementRowBase
      assetIcon={
        <AssetIcon
          exactSize={20}
          tokenMetadata={isToken ? rest.tokenMetadata : null}
          blockchain={blockchain}
        />
      }
      assetName={
        isToken ? (
          <MaxWidthEllipsize maxWidth={125}>
            <FormattedTokenName
              tokenInfo={rest.tokenMetadata}
              blockchain={blockchain}
              ellipsizeLength={15}
            />
          </MaxWidthEllipsize>
        ) : (
          getNativeAssetName(blockchain)
        )
      }
      extraAssetContent={extraAssetContent}
      assetInfoItems={isToken ? rest.tokenInfoItems : []}
      movementContent={
        <>
          {amount ? (
            <AssetAmount
              blockchain={blockchain}
              amount={amount}
              tokenId={isToken ? rest.tokenMetadata.id : undefined}
              withAmountSignPrefix={withAmountSignPrefix}
            />
          ) : (
            <>{t('N/A')}</>
          )}
        </>
      }
    />
  )
}

export const AssetMovementRowBase = ({
  assetIcon,
  assetName,
  movementContent,
  extraAssetContent,
  assetInfoItems,
}: {
  assetIcon: React.ReactNode
  assetName: React.ReactNode
  movementContent: React.ReactNode
  extraAssetContent?: React.ReactNode
  assetInfoItems: {value: string; key: string}[]
}) => {
  const {t} = useTranslation()

  return (
    <>
      <Box display="flex" alignItems="center" gap={1}>
        <IconCol
          columnSpacing={1}
          Icon={assetIcon}
          title={
            <Typography
              component="div"
              variant={assetInfoItems.length > 0 ? 'caption' : 'inherit'}
            >
              {assetName}
              <Box display="flex" gap={0.5}>
                {assetInfoItems.map(({key, value}) => (
                  <CopyToClipboard
                    key={key}
                    variant="text"
                    copyText={t('copy_token_info_item', {
                      value,
                    })}
                    breakCopyText
                    value={value}
                    label={key}
                  />
                ))}
              </Box>
            </Typography>
          }
        />
        {extraAssetContent && extraAssetContent}
      </Box>

      <Box ml="auto" maxWidth="40%" color="text.secondary" height="fit-content">
        <WithOverflowTooltip>{movementContent}</WithOverflowTooltip>
      </Box>
    </>
  )
}

export const TokenAssetLoadingElement = () => {
  return (
    <RowSpaceBetweenWrapper>
      <TypographyLoader variant="body2" />{' '}
      <div>
        <TypographyLoader variant="caption" width={60} />
        <Box textAlign="right">
          <TypographyLoader variant="caption" width={30} />
        </Box>
      </div>
    </RowSpaceBetweenWrapper>
  )
}

export const TokenAssetErrorElement = ({tokenId}: {tokenId: TokenId}) => {
  const {t} = useTranslation()

  return (
    <LabeledIcon
      Label={
        <Typography variant="inherit" color="error.light">
          {t('could_not_load_token_data', {
            tokenName: ellipsizeString(tokenId, 5, 3),
          })}
        </Typography>
      }
      Icon={<Error color="error" />}
      iconPosition="start"
    />
  )
}
