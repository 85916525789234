import type {SolanaTokenId, SolanaAddress} from '@nufi/wallet-solana'

import type {AccountId} from '../../../../types'
import {QUERY_KEY_PREFIX as P} from '../utils'

export const tokensQueryKeys = {
  tokens: [P, 'tokens'],
  tokenAccounts: () => [...tokensQueryKeys.tokens, 'accounts'],
  accountTokens: (accountId: AccountId) => [
    ...tokensQueryKeys.tokens,
    'account',
    accountId,
  ],
  tokensByAccount: () => [...tokensQueryKeys.tokens, 'tokensByAccount'],
  addressInfo: (address: SolanaAddress) => [
    ...tokensQueryKeys.tokens,
    'address',
    address,
  ],
  tokenMetadata: {
    index: () => [...tokensQueryKeys.tokens, 'tokenMetadata'],
    all: () => [...tokensQueryKeys.tokenMetadata.index(), 'all'],
    token: (id: SolanaTokenId | null) => [
      ...tokensQueryKeys.tokenMetadata.index(),
      'token',
      id,
    ],
  },
  balance: {
    index: () => [...tokensQueryKeys.tokens, 'balance'],
    tokensPerAccount: (tokenId: SolanaTokenId | null) => [
      ...tokensQueryKeys.balance.index(),
      'perAccount',
      tokenId,
    ],
    totalToken: (tokenId: SolanaTokenId | null) => [
      ...tokensQueryKeys.balance.index(),
      'totalToken',
      tokenId,
    ],
  },
  offChainMeta: [P, 'offChainMeta'],
  tokenAccountRent: [P, 'tokenAccountRent'],
} as const
