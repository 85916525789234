import type {FlowAccountInfo, FlowNodeId} from '@nufi/wallet-flow'
import {flowToNanoflow, nanoFlowToFlow} from '@nufi/wallet-flow'
import {useTranslation} from 'react-i18next'
import * as yup from 'yup'

import {getBlockchainDecimals} from '../../../../../constants'
import type {AccountId} from '../../../../../types'
import {getMinStakableFlowAmountImperative} from '../../../../../wallet/flow'
import {
  useAmountRequiredValidation,
  useAmountDecimalValidation,
} from '../../../../send/common/schema'
import {useSendAmountValidation} from '../schemas'

import type {FormSchema} from './types'

export type RegisterDelegatorDetailSchema = {
  validatorId: FlowNodeId
  amount: string
  accountId: AccountId
}

const _useRegisterDelegatorDetailSchema = ({
  accounts,
}: {
  accounts: FlowAccountInfo[]
}) => {
  const {t} = useTranslation()
  const amountRequiredValidation = useAmountRequiredValidation()
  const decimalAmountValidation = useAmountDecimalValidation({
    decimals: getBlockchainDecimals('flow'),
  })

  const baseAmountValidation = decimalAmountValidation.concat(
    amountRequiredValidation,
  )
  const sendAmountValidation = useSendAmountValidation({
    accounts,
    txType: 'registerDelegator',
  })
  return {
    validatorId: yup.string().required(t('Validator is required')),
    amount: baseAmountValidation.concat(sendAmountValidation).concat(
      yup
        .string()
        .test(
          'stake-not-below-min',
          async function (amount, {createError, path}) {
            if (amount === undefined) return true
            const values: FormSchema = this.parent
            const minStakeAmount = await getMinStakableFlowAmountImperative()
            if (flowToNanoflow(values.amount).lt(minStakeAmount)) {
              return createError({
                path,
                message: t('FlowRegisterDelegatorModal.minStakeError', {
                  minStake: nanoFlowToFlow(minStakeAmount),
                }),
              })
            }

            return true
          },
        ),
    ),
    accountId: yup.string(),
  }
}

// ensure consistency of keys / values on TS level
export const useRegisterDelegatorDetailSchema: (
  ...args: Parameters<typeof _useRegisterDelegatorDetailSchema>
) => {
  [k in keyof RegisterDelegatorDetailSchema]: ReturnType<
    typeof _useRegisterDelegatorDetailSchema
  >[k]
} = _useRegisterDelegatorDetailSchema
