import {Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

export const FormattedCardanoTxType = ({txType}: {txType: string}) => {
  const {t} = useTranslation()
  let translatedTxType = ''

  switch (txType) {
    case 'transfer':
      translatedTxType = t('Transfer')
      break
    case 'withdraw':
      translatedTxType = t('Withdraw')
      break
    case 'delegate':
      translatedTxType = t('Delegate')
      break
    default:
      translatedTxType = t('Unknown')
      break
  }

  return <Typography>{translatedTxType}</Typography>
}
