import {Box, Typography, Alert} from '@mui/material'
import type {FormikHelpers} from 'formik'
import {Formik} from 'formik'
import _ from 'lodash'
import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import * as yup from 'yup'

import {
  SideBarLayout,
  NavigateBack,
  Button,
  TextButton,
  MnemonicConfirmationField,
  PlainTextInput,
} from '../../components'
import {getHasFormError} from '../../utils/form'
import type {Mnemonic} from '../../wallet'

import {
  useCommonProfileStyles,
  NavigateBackWrapper,
  CreateProfileTimeline,
} from './common'
import type {WalletType} from './types'

type MnemonicConfirmation = Array<string | null>

type Props = {
  mnemonic: Mnemonic
  plainTextMode: boolean
  onSubmit: () => unknown
  onBack: () => unknown
  walletType: WalletType
}

export default function RecoveryPhraseConfirmPage(props: Props) {
  return (
    <SideBarLayout
      left={
        <CreateProfileTimeline step="recovery" walletType={props.walletType} />
      }
      right={<RecoveryPhraseConfirmPageContent {...props} />}
    />
  )
}

export function RecoveryPhraseConfirmPageContent({
  onBack,
  onSubmit,
  mnemonic,
  plainTextMode,
}: Omit<Props, 'walletType'>) {
  const {t} = useTranslation()
  const commonClasses = useCommonProfileStyles()
  const wordsToCropCount = 4
  const minWordsDistance = 10

  // memoized as the reference is passed as dependency to the next `useMemo`
  const splitMnemonic = useMemo(() => mnemonic.split(' '), [mnemonic])

  // memoized due to randomness
  const croppedOutParts = useMemo(() => {
    const samplingIndexes = _.range(0, splitMnemonic.length).map(
      (item, index) => index,
    )
    // find random but "balanced" positions
    let positions: Array<number> = []
    do {
      // Note: we want user to look at most of his mnemonic, therefore we only accept
      // configurations when Min/Max positions are at least `minWordsDistance` apart
      positions = _.sampleSize(samplingIndexes, wordsToCropCount)
    } while (Math.max(...positions) - Math.min(...positions) < minWordsDistance)
    return positions.map((index) => ({index, word: splitMnemonic[index]!}))
  }, [splitMnemonic])

  const initialValues = {
    mnemonicConfirmation: splitMnemonic.map((w, i) =>
      croppedOutParts.some(({index}) => index === i) ? null : w,
    ),
  }

  const ERRORS = {
    required: 'REQUIRED',
    invalid: 'INVALID',
  }

  type FormData = {
    mnemonicConfirmation: MnemonicConfirmation
  }
  type FormField = keyof FormData
  const _formSchema: {[k in keyof FormData]: yup.AnySchema} = {
    mnemonicConfirmation: yup
      .array()
      .test(
        'mnemonic-confirmation-is-required',
        ERRORS.required,
        (value: MnemonicConfirmation | undefined) => {
          if (value === undefined) return true
          return !value.some((v) => v == null)
        },
      ),
  }
  const formSchema = yup.object().shape(_formSchema)

  const onFormSubmit = (
    values: FormData,
    {setErrors}: FormikHelpers<FormData>,
  ) => {
    if (values.mnemonicConfirmation.join(',') !== splitMnemonic.join(',')) {
      setErrors({mnemonicConfirmation: ERRORS.invalid})
      return
    }
    onSubmit()
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={formSchema}
      onSubmit={onFormSubmit}
    >
      {(formikProps) => {
        const {
          handleSubmit,
          errors,
          values,
          resetForm,
          isSubmitting,
          setFieldValue,
        } = formikProps

        const hasError = getHasFormError(formikProps)

        const showError = hasError('mnemonicConfirmation')
        const showRequiredErrors =
          showError && errors.mnemonicConfirmation === ERRORS.required
        const showInvalidError =
          showError && errors.mnemonicConfirmation === ERRORS.invalid

        return (
          <Box className={commonClasses.wrapper}>
            <Typography variant="h5">{t('Confirm recovery phrase')}</Typography>

            <Typography
              className={commonClasses.headerSubtitle}
              variant="body1"
              color="textSecondary"
            >
              {plainTextMode
                ? t(
                    'Enter your recovery phrase in full by typing each word or pasting from clipboard. Be sure to use only lower case letters.',
                  )
                : t(
                    'Fill in the missing information by clicking on the words below the box.',
                  )}
            </Typography>

            <form
              className={commonClasses.form}
              onSubmit={handleSubmit}
              noValidate
            >
              {!showInvalidError ? (
                <>
                  {plainTextMode ? (
                    <PlainTextInput
                      onChange={(e) =>
                        setFieldValue(
                          'mnemonicConfirmation',
                          e.target.value.trim().split(/\s+/),
                        )
                      }
                      error={showRequiredErrors}
                    />
                  ) : (
                    <MnemonicConfirmationField
                      value={values.mnemonicConfirmation}
                      onChange={(value) => {
                        // Note: setFieldValue is not generic
                        const fieldName: FormField = 'mnemonicConfirmation'
                        setFieldValue(fieldName, value)
                      }}
                      hasError={showRequiredErrors}
                      croppedOutParts={croppedOutParts}
                    />
                  )}
                </>
              ) : (
                <>
                  <Alert severity="error">
                    <>
                      <Typography>
                        {t('The combination you supplied is not correct.')}
                      </Typography>
                      <TextButton
                        label={t('Try again...')}
                        onClick={() => resetForm()}
                      />
                    </>
                  </Alert>
                </>
              )}

              {!showInvalidError && (
                <Button
                  className={commonClasses.submitButton}
                  textTransform="none"
                  color="primary"
                  variant="contained"
                  type="submit"
                  fullWidth
                  disabled={isSubmitting}
                >
                  {t('Confirm')}
                </Button>
              )}
            </form>

            <NavigateBackWrapper>
              <NavigateBack onBack={onBack} />
            </NavigateBackWrapper>
          </Box>
        )
      }}
    </Formik>
  )
}
