/* eslint-disable @typescript-eslint/explicit-function-return-type */

import type {FlowTokenId} from '@nufi/wallet-flow'
import type {SolanaTokenId} from '@nufi/wallet-solana'
import {useQuery} from '@tanstack/react-query'
import Fuse from 'fuse.js'
import {useMemo} from 'react'

import {mergeQueryResultsState, usePaginateData} from 'src/utils/query-utils'

import {getMockServices} from '../../../__tests__/storybook/MockServiceLocator'
import type {NftBlockchain} from '../../../blockchainTypes'
import {nftBlockchains} from '../../../blockchainTypes'
import {FUSE_TOKEN_SEARCHING_THRESHOLD} from '../../../constants'
import {safeAssertUnreachable} from '../../../utils/assertion'
import {emptyResultIfBlockchainNotSupported} from '../../../utils/blockchainGuards'
import type {CardanoTokenId} from '../../cardano'
import {
  useGetAllNfts as useGetAllCardanoNfts,
  useGetNft as useGetCardanoNft,
} from '../../cardano'
import type {EvmTokenId} from '../../evm'
import {
  isEvmBlockchain,
  useEvmQueryPerBlockchain,
  useGetAllNfts as useGetAllEvmNfts,
  useGetNft as useGetEvmNft,
} from '../../evm'
import {
  useGetAllNfts as useGetAllFlowNfts,
  useGetNft as useGetFlowNft,
} from '../../flow'
import {
  useGetAllNfts as useGetAllSolanaNfts,
  useGetNft as useGetSolanaNft,
} from '../../solana'
import type {
  AccountId,
  AccountInfoWithNftSnapshot,
  Blockchain,
  Nft,
  TokenId,
} from '../../types'

import {useGetAccounts} from './accounts'
import {sortNfts} from './utils'

const NFT_PAGE_SIZE = 20

// TODO refactor useGetAllNfts functions to reuse logic for all blockchains
function _useGetAllNfts(blockchain: Blockchain) {
  const queries = {
    cardano: useGetAllCardanoNfts(blockchain === 'cardano'),
    solana: useGetAllSolanaNfts(blockchain === 'solana'),
    flow: useGetAllFlowNfts(blockchain === 'flow'),
    ...useEvmQueryPerBlockchain((evmBlockchain) =>
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useGetAllEvmNfts(evmBlockchain, blockchain === evmBlockchain),
    ),
  }

  return emptyResultIfBlockchainNotSupported({
    queries,
    blockchain,
    blockchainSubset: nftBlockchains,
    emptyData: null,
  })
}

export type UseGetAllNfts = typeof _useGetAllNfts

export const useGetAllNfts: UseGetAllNfts = (
  ...args: Parameters<UseGetAllNfts>
) => {
  const mockServices = getMockServices()
  return mockServices
    ? mockServices.useGetAllNfts(...args)
    : _useGetAllNfts(...args)
}

const _filterNftsByVisibility = (
  nfts: Nft[],
  visibility?: 'visible' | 'hidden',
) =>
  nfts.length && visibility
    ? nfts.filter((nft) => (visibility === 'hidden') === !!nft.isHidden)
    : nfts

export function useGetAccountNfts({
  blockchain,
  accountId,
  searchText,
  visibility,
}: {
  blockchain: NftBlockchain
  accountId: AccountId
  searchText: string
  visibility?: 'visible' | 'hidden'
}) {
  const allNftsQuery = useGetAllNfts(blockchain)
  const accountData = allNftsQuery.data
    ? allNftsQuery.data.nftsByAccount[accountId]
    : []
  // useMemo necessary for not altering the data, which would break pagination
  const filteredAccountData = useMemo(
    () => _filterNftsByVisibility(accountData!, visibility),
    [accountData, visibility],
  )

  // Search NFTs by different properties based on blockchain
  const keys = (() => {
    switch (blockchain) {
      case 'cardano':
        return ['name', 'policyId', 'fingerprint']
      case 'solana':
        return ['name', 'ticker', 'description']
      case 'flow':
        return [
          'contractMetadata.contractAddress',
          'contractMetadata.contractName',
          'contractMetadata.collection.name',
          'metadata.title',
          'metadata.description',
        ]
      default: {
        if (isEvmBlockchain(blockchain)) {
          return ['name', 'description', 'contractMetadata.name']
        }
        return safeAssertUnreachable(blockchain)
      }
    }
  })()

  const fuse = new Fuse(filteredAccountData, {
    keys,
    threshold: FUSE_TOKEN_SEARCHING_THRESHOLD,
  })
  const data =
    searchText === ''
      ? filteredAccountData
      : fuse.search(searchText).map((item) => item.item)
  const sortedNfts = useMemo(() => sortNfts(data), [data])
  // The data are loaded all at once, so we just paginate for NFT account detail
  // to avoid rendering of too many items for performance reasons.
  // Alternative (improve UX): Consider using windowing instead, as the data are already loaded.
  const paginatedData = usePaginateData({
    data: sortedNfts,
    pageSize: NFT_PAGE_SIZE,
  })

  const result = useMemo(
    () => ({
      ...allNftsQuery,
      data: paginatedData.pageData,
      hasNextPage: paginatedData.hasNextPage,
      fetchNextPage: paginatedData.onNextPage,
    }),
    [allNftsQuery, paginatedData, searchText, visibility],
  )

  return result
}

export function useAccountsWithNftSnapshot({
  blockchain,
  visibility,
}: {
  blockchain: NftBlockchain
  visibility?: 'visible' | 'hidden'
}) {
  const allNftsQuery = useGetAllNfts(blockchain)
  const accountsQuery = useGetAccounts(blockchain)

  const data: AccountInfoWithNftSnapshot[] | undefined = useMemo(() => {
    if (!allNftsQuery.data || !accountsQuery.data) return undefined
    const allNfts = allNftsQuery.data
    const accounts = accountsQuery.data

    return (
      accounts
        // this memo depends on 2 cached queries and it's expected for both of them to share same data (accounts)
        // queries can be updated incrementally (f.e. invalidating queries when adding account)
        // that can result in inconsistent state when:
        // 1. accountsQuery is up to date and triggers re-calculation of the data
        // 2. updating of allNftsQuery is still in progress when accountsQuery triggers re-calculation (returning old data)
        // we want to filter inconsistencies in data in these "in between" states
        .filter((account) => !!allNfts.nftsByAccount[account.id])
        .map((account) => {
          const accountNfts = allNfts.nftsByAccount[account.id]!
          const filteredNfts = _filterNftsByVisibility(accountNfts, visibility)
          const firstPageNfts = filteredNfts.slice(0, NFT_PAGE_SIZE)

          return {
            ...account,
            nftSnapshot: {
              items: firstPageNfts,
              hasNextPage: filteredNfts.length > NFT_PAGE_SIZE,
              nextOffset: firstPageNfts.length,
            },
          } as AccountInfoWithNftSnapshot
        })
    )
  }, [allNftsQuery, accountsQuery])

  return {
    data,
    ...mergeQueryResultsState(allNftsQuery, accountsQuery),
  }
}

// TODO refactor useGetNft functions to reuse logic for all blockchains
export function useGetNft(tokenId: TokenId, blockchain: Blockchain) {
  const queries = {
    solana: useGetSolanaNft(tokenId as SolanaTokenId, blockchain === 'solana'),
    cardano: useGetCardanoNft(
      tokenId as CardanoTokenId,
      blockchain === 'cardano',
    ),
    flow: useGetFlowNft(tokenId as FlowTokenId, blockchain === 'flow'),
    ...useEvmQueryPerBlockchain((evmBlockchain) =>
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useGetEvmNft(
        evmBlockchain,
        tokenId as EvmTokenId<typeof evmBlockchain> | null,
        blockchain === evmBlockchain,
      ),
    ),
  }

  return emptyResultIfBlockchainNotSupported({
    queries,
    blockchain,
    blockchainSubset: nftBlockchains,
    emptyData: null,
  })
}

export function useImageMediaType(url: string) {
  return useQuery({
    queryKey: ['imageHeaders', url],
    queryFn: async (): Promise<string | null> => {
      const response = await fetch(url, {
        method: 'HEAD',
      })
      const originalMIME = response.headers.get('original-content-type')

      if (originalMIME) {
        const [type, ext] = originalMIME.split('/')

        return type === 'video' || ext === 'gif'
          ? 'video'
          : type === 'image'
            ? 'image'
            : null
      }

      return null
    },
    gcTime: Infinity,
    staleTime: Infinity,
  })
}
