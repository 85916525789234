import React, {useEffect} from 'react'

import {MutationGuard, ModalLayout, ModalFooter} from '../../components'
import type {AppError} from '../../types'
import type {Blockchain} from '../../wallet'

import {
  TxLoadingFooter,
  TxLoadingBody,
  TxErrorFooter,
  TxBodyLayout,
  TxErrorBody,
} from './common'
import {consumeTxSubmissionAuthorization} from './signAuthorization'

type SignMnemonicScreenProps = {
  blockchain: Blockchain
  onBack: () => void
  onClose: () => void
  onRetry: () => Promise<unknown>
  signProps: {
    isPending: boolean
    error: AppError
  }
  ModalHeader: React.ReactNode
}

export function SignMnemonicScreen({
  ModalHeader,
  onClose,
  onRetry,
  onBack,
  signProps,
  blockchain,
}: SignMnemonicScreenProps) {
  const {Body, Footer} = (() => {
    if (signProps.error) {
      return {
        Body: (
          <TxErrorBody
            type="sign"
            error={signProps.error}
            {...{onRetry, blockchain}}
          />
        ),
        Footer: <TxErrorFooter onBack={onBack} onClose={onClose} />,
      }
    }
    // We do not handle "success" state here as immediate redirect to
    // "submit" screen from outside should follow
    return {
      Body: <TxLoadingBody action="signing" blockchain={blockchain} />,
      Footer: <TxLoadingFooter action="signing" />,
    }
  })()

  return (
    <ModalLayout
      header={ModalHeader}
      body={<TxBodyLayout centered>{Body}</TxBodyLayout>}
      footer={
        <ModalFooter hasDivider={!signProps.isPending}>
          {Footer}
          <MutationGuard {...signProps} error={null} hideLoader />
        </ModalFooter>
      }
    />
  )
}

type BaseSignMnemonicScreenProps = SignMnemonicScreenProps & {
  onTxSignAndSubmit: () => Promise<void>
  safeDistanceFromEventInMs: number
}

export function BaseSignMnemonicScreen({
  onTxSignAndSubmit,
  safeDistanceFromEventInMs,
  ...restProps
}: BaseSignMnemonicScreenProps) {
  useEffect(() => {
    consumeTxSubmissionAuthorization(safeDistanceFromEventInMs)
    onTxSignAndSubmit()
    // Note that it is crucial that this runs exactly once!
  }, [])

  return <SignMnemonicScreen {...restProps} />
}
