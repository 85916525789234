import React from 'react'

import type {ExchangeAssetsDetails} from 'src/features/exchange/domain'
import {findAccountByAddress} from 'src/wallet/utils/common'

import {MutationGuard} from '../../../../components'
import {assert} from '../../../../utils/assertion'
import {DeviceReadyState} from '../../../transaction/common'
import {BaseSignTxFlow} from '../../../transaction/multiStepFormUtils/BaseSignTxFlow'
import {ExchangeModalHeader, WithExchangeAssetQuery} from '../../components'
import {useExchangeConf} from '../../ExchangeConfContext'
import type {ExchangeModalViewModel} from '../../exchangeModalViewModel'
import {SwapError} from '../../SwapError'
import {LoadingOrError} from '../common'
import type {DetailsValues} from '../details/schema'

type SignTxScreenProps = {
  createSwapMutation: ReturnType<
    ExchangeModalViewModel['useExchangeCreateSwap']
  >
  saveSwapMutation: ReturnType<ExchangeModalViewModel['useSaveSwap']>
  formData: DetailsValues
  onBack: () => void
  onSuccess: () => void
  onClose: () => void
  exchangeAssetsDetails: ExchangeAssetsDetails
  vm: Pick<ExchangeModalViewModel, 'useGetAccounts'>
}

/**
 * Wait for create/save mutation swap initialized by parent,
 * and handles the signing flow for internal swaps.
 */
export function SignTxScreen({
  formData,
  onBack,
  onSuccess,
  onClose,
  createSwapMutation,
  saveSwapMutation,
  exchangeAssetsDetails,
  vm,
}: SignTxScreenProps) {
  const {exchangeConf} = useExchangeConf()
  return (
    <MutationGuard
      {...createSwapMutation}
      LoadingElement={<LoadingOrError isError={false} onClose={onClose} />}
      ErrorElement={<SwapError error={createSwapMutation.error} />}
    >
      <MutationGuard
        {...saveSwapMutation}
        LoadingElement={<LoadingOrError isError={false} onClose={onClose} />}
        ErrorElement={<SwapError error={saveSwapMutation.error} />}
      >
        {(swap) => (
          <WithExchangeAssetQuery
            asset={formData.fromAsset}
            {...{exchangeAssetsDetails, vm}}
            childrenPropsType="guardedData"
          >
            {({
              accounts: fromAccounts,
              internalBlockchain: fromInternalBlockchain,
              token: fromToken,
            }) => {
              assert(fromInternalBlockchain != null)
              const {WithSignAndSubmitHandlers} =
                exchangeConf[fromInternalBlockchain]

              const accountInfo = findAccountByAddress(
                fromAccounts,
                formData.fromAddress,
                fromInternalBlockchain,
              )
              assert(accountInfo != null)
              return (
                <WithSignAndSubmitHandlers
                  toAddress={swap.payinAddress}
                  amount={formData.amount}
                  fromAccountInfo={accountInfo}
                  fromToken={fromToken}
                >
                  {({signProps, submitProps}) => (
                    <BaseSignTxFlow
                      /* Changelly swap can take some time to create so we are skipping this */
                      safeDistanceFromEventInMs={Infinity}
                      cryptoProviderType={accountInfo.cryptoProviderType}
                      ModalHeader={<ExchangeModalHeader onClose={onClose} />}
                      {...{
                        onBack,
                        onSuccess,
                        onClose,
                        submitProps,
                        signProps,
                        DeviceReadyState,
                      }}
                      blockchain={fromInternalBlockchain}
                    />
                  )}
                </WithSignAndSubmitHandlers>
              )
            }}
          </WithExchangeAssetQuery>
        )}
      </MutationGuard>
    </MutationGuard>
  )
}
