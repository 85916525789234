import BigNumber from 'bignumber.js'

import type {CardanoAccountInfo, CardanoTokenId} from '../../../wallet/cardano'
import {getCardano} from '../../../wallet/cardano'

export const findTokenBalance = (
  account: CardanoAccountInfo,
  tokenId: CardanoTokenId,
) =>
  account.tokensBalance.find(
    (cardanoTokenAmount) => cardanoTokenAmount.token.id === tokenId,
  )?.amount || new BigNumber(0)

export const getMinAdaAmount = () =>
  getCardano().wallet.getNetworkInfo().minimumUtxoVal
