import type {EvmBlockchainApi, EvmNetworkConfig} from '@nufi/wallet-evm'
import {
  BaseEvmAccountManager,
  BaseEvmBlockchainApiImpl,
  constructEvmConnection,
} from '@nufi/wallet-evm'

import {createAccountInfosUtils} from '../utils/walletUtils'

import {accountsStore} from './evmAccountStore'
import {cryptoProviders} from './evmCryptoProviders'
import type {EvmWalletManagers} from './evmManagers'
import type {
  EvmAccountNetworkInfo,
  EvmAccountOfflineInfo,
  EvmAccountStoredData,
  EvmBlockchain,
} from './types'

type CreateBaseEvmManagersParams<
  TBlockchain extends EvmBlockchain,
  TNetwork extends string,
> = {
  blockchain: TBlockchain
  network: TNetwork
  createNetworkConfig: (
    network: TNetwork,
    apiUrl: string,
  ) => EvmNetworkConfig<TBlockchain, TNetwork>
  apiUrl: string
}

export const createBaseEvmManagers = <
  TBlockchain extends EvmBlockchain,
  TNetwork extends string,
>({
  blockchain,
  network,
  apiUrl,
  createNetworkConfig,
}: CreateBaseEvmManagersParams<TBlockchain, TNetwork>): EvmWalletManagers<
  TBlockchain,
  TNetwork
> => {
  return createEvmManagers({
    blockchain,
    network,
    apiUrl,
    createNetworkConfig,
    createBlockchainApi: (networkConfig) => {
      const connection = constructEvmConnection<TBlockchain>(
        apiUrl,
        networkConfig.chainId,
        apiUrl,
      )
      return new BaseEvmBlockchainApiImpl<TBlockchain>(
        connection,
        blockchain,
        networkConfig.chainId,
        networkConfig.balanceCheckerContractAddress,
      )
    },
  })
}

type CreateEvmManagersParams<
  TBlockchain extends EvmBlockchain,
  TNetwork extends string,
  TBlockchainApi extends EvmBlockchainApi<TBlockchain>,
> = CreateBaseEvmManagersParams<TBlockchain, TNetwork> & {
  createBlockchainApi: (
    networkConfig: EvmNetworkConfig<TBlockchain, TNetwork>,
  ) => TBlockchainApi
}

export const createEvmManagers = <
  TBlockchain extends EvmBlockchain,
  TNetwork extends string,
  TBlockchainApi extends EvmBlockchainApi<TBlockchain>,
>({
  blockchain,
  network,
  apiUrl,
  createNetworkConfig,
  createBlockchainApi,
}: CreateEvmManagersParams<
  TBlockchain,
  TNetwork,
  TBlockchainApi
>): EvmWalletManagers<TBlockchain, TNetwork, TBlockchainApi> => {
  const networkConfig = createNetworkConfig(network, apiUrl)

  const blockchainApi = createBlockchainApi(networkConfig)

  const accountManager = new BaseEvmAccountManager(blockchain, {
    blockchainApi,
    ...cryptoProviders,
  })

  const accountInfoUtils = createAccountInfosUtils<
    EvmAccountStoredData,
    EvmAccountNetworkInfo<TBlockchain>,
    EvmAccountOfflineInfo<TBlockchain>
  >(accountManager)

  return {
    networkConfig,
    accountManager,
    blockchainApi,
    accountsStore,
    ...accountInfoUtils,
  }
}
