import type BigNumber from 'bignumber.js'
import {formatInTimeZone} from 'date-fns-tz'
import {Base64} from 'js-base64'
import {useTranslation} from 'react-i18next'

import type {AccountInfo, AccountName} from '../../../../../../types'

export const formatToUTC = (date: Date) =>
  formatInTimeZone(date, 'UTC', "MM/dd/yyyy hh:mm aa 'UTC'")

export const enum TxSummaryType {
  RECEIVED = 'Received',
  SENT = 'Sent',
  REWARD_AWARDED = 'Reward awarded',
  EMPTY = 'Could not fetch transaction details',
}

export type TxEntry = {
  type: TxSummaryType
  txHash?: string
  timeIssued: Date
  fee?: BigNumber
  currency: string
  isToken: boolean
  sent?: BigNumber
  received?: BigNumber
  decimals?: number
  accountName: AccountName
  address: AccountInfo['address']
}

export type CommonEntryFields = {
  txHash: string
  timeIssued: Date
  accountName: AccountName
  address: AccountInfo['address']
}

export type CSVRowWithDate = {
  date: Date | null
  row: string
}

export type CSVConstants = {
  delimiter: string
  rowsDelimiter: string
  headers: string
}

export const useCSVFileConstants = (): CSVConstants => {
  const {t} = useTranslation()
  const delimiter = ','
  const rowsDelimiter = '\n'
  const headers = [
    t('Date'),
    t('Transaction ID'),
    t('Address'),
    t('Account Name'),
    t('Type'),
    t('Received amount'),
    t('Received currency'),
    t('Sent amount'),
    t('Sent currency'),
    t('Fee amount'),
    t('Fee currency'),
  ]
    .map((header) => `${header}${delimiter}`)
    .join('')
  return {
    delimiter,
    rowsDelimiter,
    headers,
  }
}

export type CSVFile = {
  filename: string
  dataURI: string
}

export const rowsToCSVObject = (
  csvConstants: CSVConstants,
  rowsWithDate: CSVRowWithDate[],
): CSVFile => {
  const {headers, rowsDelimiter} = csvConstants

  const sortedRows = rowsWithDate
    .sort((a, b) => (b.date?.getTime() ?? 0) - (a.date?.getTime() ?? 0))
    .map(({row}) => row)

  const fileContents = `${headers}${rowsDelimiter}${sortedRows.join(
    rowsDelimiter,
  )}`
  const filetype = 'text/plain'

  return {
    filename: 'nufi-transactions.csv',
    dataURI: `data:${filetype};base64,${Base64.encode(fileContents)}`,
  }
}

export type CreateCSVRows<TAccountInfo extends AccountInfo, T = null> = (
  csvConstants: CSVConstants,
  accounts: TAccountInfo[],
  startDate: Date,
  endDate: Date,
  blockchainSpecific: T,
) => Promise<CSVRowWithDate[]>

export const filterEntriesByDate = <T extends {timeIssued: Date | null}>(
  entries: T[][],
  startDate: Date,
  endDate: Date,
): T[][] =>
  entries.map((innerEntries) =>
    innerEntries.filter(
      ({timeIssued}) =>
        // in some chains, the time may in theory be missing (e.g. staking rewards in Solana)
        // so to be on the safe side, we always include these rewards in any reports and
        // leave up to the user to filter them further
        timeIssued == null ||
        (timeIssued.getTime() >= startDate.getTime() &&
          timeIssued.getTime() <= endDate.getTime()),
    ),
  )
