import type {
  FlowAccountInfo,
  FlowNodeId,
  FlowNodeInfo,
  FlowTxPlan,
} from '@nufi/wallet-flow'
import {nanoFlowToFlow} from '@nufi/wallet-flow'
import type {FormikProps} from 'formik'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {ModalError} from '../../../../components'
import type {AccountId} from '../../../../types'
import {assert} from '../../../../utils/assertion'
import {getMaxSendableFlowAmountImperative} from '../../../../wallet/flow'
import {ensureAccountById} from '../../../../wallet/utils/common'
import {useSafeAsyncState} from '../../../utils/DebouncedFormik'

import type {FlowUICachedTxPlan} from './types'

export function StakingDisabledModalError() {
  const {t} = useTranslation()
  return (
    <ModalError
      error={t(
        'Staking on Flow is temporarily disabled. Try again later. Towards the end of each epoch (every ~7 days) all staking actions are temporarily disabled so new validators can be added and rewards calculated.',
      )}
    />
  )
}

export const findDelegation = (
  accountInfo: FlowAccountInfo,
  nodeId: FlowNodeId,
) =>
  accountInfo.stakingInfo.isStakingCollectionRegistered
    ? accountInfo.stakingInfo.delegations.find((d) => d.nodeId === nodeId)
    : undefined

export function useSafeAsyncFlowTxPlanState<
  TForm extends {accountId: AccountId},
>(accounts: FlowAccountInfo[], txType: FlowTxPlan['type']) {
  return useSafeAsyncState<TForm, FlowUICachedTxPlan>(
    async (values) =>
      await getMaxSendableFlowAmountImperative(
        ensureAccountById(accounts, values.accountId),
        txType,
      ),
  )
}

export function getSendMaxAmountOptions<TSchema>(
  account: FlowAccountInfo,
  txType: FlowTxPlan['type'],
  formikProps: FormikProps<TSchema>,
  fieldName: string,
) {
  return {
    onMaxAmount: async () => {
      getMaxSendableFlowAmountImperative(account, txType)
        .then(({maxAmount}) => {
          formikProps.setFieldValue(fieldName, nanoFlowToFlow(maxAmount))
        })
        .catch((e) => {
          formikProps.setFieldError(fieldName, (e as Error).message)
        })
    },
  }
}

export const ensureValidatorByNodeId = (
  validators: FlowNodeInfo[],
  nodeId: FlowNodeId,
): FlowNodeInfo => {
  const validator = validators.find((v) => v.nodeId === nodeId)
  assert(
    !!validator,
    `ensureValidatorById: Validator with id: ${nodeId} not found`,
  )
  return validator
}
