import {VisibilityOff as HideIcon} from '@mui/icons-material'
import {Box, Typography} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {
  Button,
  Modal,
  ModalLayout,
  ModalHeader,
  ModalFooter,
  MutationGuard,
  FullScreenLoading,
} from 'src/components'

import {useHideTokens, useUnhideTokens} from '../../../wallet'
import type {Blockchain, TokenId} from '../../../wallet'

import type {NftVisibility} from './types'

export const ManageNftVisibilityButton = ({
  nftVisibility,
  blockchain,
  selectedNfts,
  resetSelection,
}: {
  nftVisibility: NftVisibility
  blockchain: Blockchain
  selectedNfts: TokenId[]
  resetSelection: () => void
}) => {
  const {t} = useTranslation()
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)
  const onClose = () => setShowConfirmationDialog(false)
  const hideTokens = useHideTokens(blockchain)
  const unhideTokens = useUnhideTokens(blockchain)
  const tokenVisibilityMutation =
    nftVisibility === 'visible' ? hideTokens : unhideTokens
  const actionButtonLabel =
    nftVisibility === 'visible' ? t('Hide selected') : t('Un-hide selected')
  const mutationLoadingLabel =
    nftVisibility === 'visible' ? t('Hiding tokens') : t('Un-hiding tokens')

  return (
    <>
      <Button
        variant="outlined"
        onClick={() => {
          setShowConfirmationDialog(true)
        }}
        size="large"
        textTransform="none"
        color="warning"
        startIcon={<HideIcon fontSize="medium" />}
      >
        {actionButtonLabel}
      </Button>
      <ManageNftVisibilityModal
        tokenVisibilityMutation={tokenVisibilityMutation}
        showModal={showConfirmationDialog}
        onClose={onClose}
        nftVisibility={nftVisibility}
        blockchain={blockchain}
        selectedNfts={selectedNfts}
        resetSelection={resetSelection}
      />
      <MutationGuard
        {...tokenVisibilityMutation}
        error={null}
        LoadingElement={
          <FullScreenLoading description={mutationLoadingLabel} />
        }
      />
    </>
  )
}

const ManageNftVisibilityModal = ({
  onClose,
  nftVisibility,
  blockchain,
  selectedNfts,
  resetSelection,
  showModal,
  tokenVisibilityMutation,
}: {
  onClose: () => void
  nftVisibility: NftVisibility
  blockchain: Blockchain
  selectedNfts: TokenId[]
  resetSelection: () => void
  showModal: boolean
  tokenVisibilityMutation: ReturnType<
    typeof useHideTokens | typeof useUnhideTokens
  >
}) => {
  const {t} = useTranslation()
  const classes = useStyles()

  const onConfirm = async () => {
    const tokens = selectedNfts.map((tokenId) => ({
      blockchain,
      tokenId,
    }))

    onClose()
    await tokenVisibilityMutation.mutateAsyncSilent({tokens})
    resetSelection()
  }

  return (
    showModal && (
      <Modal
        className={classes.modal}
        onClose={onClose}
        variant="centered"
        dismissable
      >
        <ModalLayout
          header={
            <ModalHeader onClose={onClose} hasDivider>
              <Typography variant="h6">
                {nftVisibility === 'visible'
                  ? t('Hide selected NFTs?')
                  : t('Un-hide selected NFTs?')}
              </Typography>
            </ModalHeader>
          }
          body={
            <Box p={2}>
              <Typography>
                {nftVisibility === 'visible'
                  ? t(
                      'Selected NFTs will be hidden from portfolio and NFT overview. You can always find or un-hide them in the Hidden NFTs section.',
                    )
                  : t(
                      'Selected NFTs will appear in the portfolio and NFT overview. You can always hide them again.',
                    )}
              </Typography>
            </Box>
          }
          footer={
            <ModalFooter hasDivider>
              <Button
                fullWidth
                textTransform="none"
                variant="contained"
                color="primary"
                type="submit"
                onClick={onConfirm}
              >
                {nftVisibility === 'visible' ? t('Hide') : t('Un-hide')}
              </Button>
            </ModalFooter>
          }
        />
      </Modal>
    )
  )
}

const useStyles = makeStyles(() => ({
  modal: {
    maxWidth: '550px',
  },
}))
