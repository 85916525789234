import {safeAssertUnreachable} from '@nufi/frontend-common'

import type {Currency} from 'src/features/conversionRates/domain'
import type {Blockchain, TokenId} from 'src/types'

export interface RampService {
  getAssets(): Promise<RampAsset[]>
  getBlockchainTxId(rampTxId: string): Promise<string | null>
  signUrl(urlToSign: string): Promise<{signature: string}>
  isSandbox(): boolean
  hasWidgetSupport(parentUrl: string): Promise<boolean>
}

export type RampAsset = RampCryptoAsset | RampFiatAsset

export type RampCryptoAsset = RampAssetCommonFields & {
  type: 'crypto'
  blockchain: Blockchain
  tokenId: TokenId | null
  addressRegex: string
}

export type RampFiatAsset = RampAssetCommonFields & {
  type: 'fiat'
  currency: Currency
}

type RampAssetCommonFields = {
  name: string
  rampId: string
  supportsSell: boolean
  supportsSandbox: boolean
}

export enum RampOperation {
  BUY = 'buy',
  SELL = 'sell',
}

export const isOperationSupportedOnAsset = (
  operation: RampOperation,
  asset: RampAsset,
): boolean => {
  switch (operation) {
    case RampOperation.BUY:
      return true
    case RampOperation.SELL:
      return asset.supportsSell
    default:
      return safeAssertUnreachable(operation)
  }
}
