import {hasEvmBlockchainProp} from '../../wallet/evm/utils'

import type {TransactionInfoCandidate} from './types'

export const isDroppedTxError = (_error: unknown) => {
  const error = _error as Error & {replacement: unknown}
  // https://docs.ethers.io/v5/api/providers/types/#providers-TransactionResponse
  return error?.replacement != null
}

export const processSubscriptionEvent = (
  tx: TransactionInfoCandidate,
): TransactionInfoCandidate => {
  if (hasEvmBlockchainProp(tx.context)) {
    if (tx.error) {
      // When an eth tx gets replaced an error is thrown from react-query mutation.
      // We need to keep such error at mutation level (to show it to user if he
      // does not close submission window). But it has a different semantics
      // for tx tracking purposes.
      if (isDroppedTxError(tx.error)) {
        return {
          ...tx,
          error: undefined,
          context: {...tx.context, isDropped: true},
        }
      }
    }
  }
  return tx
}
