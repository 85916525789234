/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {safeAssertUnreachable} from '@nufi/frontend-common'
import {formatTxHistoryEntries as formatCardanoTxHistoryEntries} from '@nufi/wallet-cardano'
import type {TxHistoryEntry} from '@nufi/wallet-common'
import type {EvmBlockchain, EvmTxHistoryEntry} from '@nufi/wallet-evm'
import {isEvmBlockchain} from '@nufi/wallet-evm'
import type {FlowTokenId, FlowTxHistoryEntry} from '@nufi/wallet-flow'
import type {SolanaTokenId, SolanaTxHistoryEntry} from '@nufi/wallet-solana'
import {useEffect} from 'react'

import {usePaginateData} from 'src/utils/query-utils'
import {formatTxHistoryEntries as formatEvmTxHistoryEntries} from 'src/wallet/evm/public/queries/utils'

import type {TxHistoryBlockchain} from '../../../blockchainTypes'
import type {CardanoTokenId, CardanoTxHistoryEntry} from '../../cardano'
import {useGetTransactionHistory as useGetCardanoTransactionHistory} from '../../cardano'
import {
  useGetTransactionHistory as useGetEvmTransactionHistory,
  useEvmQueryPerEnabledBlockchain,
} from '../../evm'
import type {EvmTokenId} from '../../evm/types'
import {
  useGetTransactionHistory as useGetFlowTransactionHistory,
  formatFlowTxHistoryEntries,
} from '../../flow'
import {
  useGetTransactionHistory as useGetSolanaTransactionHistory,
  formatTxHistoryEntries as formatSolanaTxHistoryEntries,
} from '../../solana'
import type {AccountId, TokenId} from '../../types'

export const useGetTransactionHistory = (
  blockchain: TxHistoryBlockchain,
  accountId: AccountId,
  tokenId: TokenId | null,
) => {
  const queryPerBlockchain = {
    solana: useGetSolanaTransactionHistory(
      accountId,
      tokenId as SolanaTokenId | null,
      blockchain === 'solana',
    ),
    cardano: useGetCardanoTransactionHistory(
      accountId,
      tokenId as CardanoTokenId | null,
      blockchain === 'cardano',
    ),
    flow: useGetFlowTransactionHistory(
      accountId,
      tokenId as FlowTokenId | null,
      blockchain === 'flow',
    ),
    ...useEvmQueryPerEnabledBlockchain((evmBlockchain) =>
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useGetEvmTransactionHistory(
        evmBlockchain,
        accountId,
        tokenId as EvmTokenId<typeof evmBlockchain> | null,
        blockchain === evmBlockchain,
      ),
    ),
  }
  return queryPerBlockchain[blockchain]
}

const formatTransactions = (
  blockchain: TxHistoryBlockchain,
  txs:
    | SolanaTxHistoryEntry[]
    | CardanoTxHistoryEntry[]
    | FlowTxHistoryEntry[]
    | EvmTxHistoryEntry<EvmBlockchain>[]
    | undefined,
): TxHistoryEntry<TxHistoryBlockchain, TokenId>[] | undefined => {
  switch (blockchain) {
    case 'flow':
      return formatFlowTxHistoryEntries(txs as FlowTxHistoryEntry[])
    case 'solana':
      return formatSolanaTxHistoryEntries(txs as SolanaTxHistoryEntry[])
    case 'cardano':
      return formatCardanoTxHistoryEntries(txs as CardanoTxHistoryEntry[])
    default: {
      if (isEvmBlockchain(blockchain)) {
        return formatEvmTxHistoryEntries(
          blockchain,
          txs as EvmTxHistoryEntry<typeof blockchain>[],
        )
      }
      return safeAssertUnreachable(blockchain)
    }
  }
}

export const useGetPaginatedTransactionHistory = (
  blockchain: TxHistoryBlockchain,
  accountId: AccountId,
  tokenId: TokenId | null,
) => {
  const {
    data: transactions,
    isLoading,
    error,
    isFetching,
    refetch,
    ...restProps
  } = useGetTransactionHistory(blockchain, accountId, tokenId)

  // pagination used for queries returning full data on single fetch
  let {pageData, hasNextPage, onNextPage, reset} = usePaginateData({
    data: transactions,
    pageSize: 20,
    resetOnDataChange: false,
  })

  // for infinite queries, override the "paginated data" hook properties
  if ('hasNextPage' in restProps) {
    pageData = transactions
    hasNextPage = restProps.hasNextPage
    onNextPage = () => restProps.fetchNextPage()
    // eslint-disable-next-line no-empty-function, @typescript-eslint/no-empty-function
    reset = () => {}
  }

  // Keep same page on global refresh & refresh only on accountId change
  useEffect(() => {
    reset()
  }, [accountId])

  return {
    isLoading: isLoading || isFetching,
    error,
    pageData: formatTransactions(blockchain, pageData),
    hasNextPage,
    onNextPage,
    refetch,
  }
}
