import BigNumber from 'bignumber.js'
import {useTranslation} from 'react-i18next'
import * as yup from 'yup'

import {useGetCoinName} from 'src/utils/translations'

import {useCurrentLoginInfo} from '../../../store/auth'
import {
  getParentSchema,
  getInputFieldValueFromValidation,
  validateAssetDecimalsCount,
} from '../../../utils/form'
import type {AccountInfo, AccountId, Blockchain} from '../../../wallet'
import {ensureAccountById} from '../../../wallet/utils/common'
import {isPasswordVerificationRequired} from '../../utils'

import type {
  BaseDetailsSchema,
  SummaryDetailsSchema,
  BaseSendSchema,
  AssetSchema,
  TokenSchema,
  Asset,
} from './types'

const useTooManyDecimalsMessage = () => {
  const {t} = useTranslation()
  const getDecimalsMessage = (decimals: number) =>
    decimals > 0
      ? t('too_many_decimals_for_asset', {decimals})
      : t('Amount must be a whole number')

  return getDecimalsMessage
}

type UseSendAssetsValidationParams<
  TSchema extends BaseSendSchema = BaseSendSchema,
> = {
  blockchain: Blockchain
  getAvailableBalance: (
    accountId: AccountId,
    asset: AssetSchema,
    fields: TSchema,
  ) => BigNumber
  getDecimals: (asset: AssetSchema) => number
  getParsedNativeAmount: (fieldAmount: string) => BigNumber
  getParsedTokenAmount: (fieldAmount: string, asset: TokenSchema) => BigNumber
  getIsRequired: (asset: Asset) => boolean
}

export const useSendAssetsValidation = <TSchema extends BaseSendSchema>({
  getAvailableBalance,
  getDecimals,
  getParsedNativeAmount,
  getParsedTokenAmount,
  getIsRequired,
  blockchain,
}: UseSendAssetsValidationParams<TSchema>) => {
  const {t} = useTranslation()
  const tooManyDecimalsMessage = useTooManyDecimalsMessage()
  const getCoinName = useGetCoinName()
  return yup.array().of(
    yup.object().shape({
      amount: yup
        .string()
        .test(
          'is-required-and-positive',
          t('Amount must be a positive number.'),
          (amount, context) => {
            const asset: AssetSchema = context.parent
            const isRequired = getIsRequired(asset)
            if (!isRequired) return true

            if (amount === undefined) {
              return context.createError({
                message: t('Amount is required.'),
              })
            }

            return new BigNumber(amount).isGreaterThan(0)
          },
        )
        .test(
          'send-native-amount-not-more-than-max',
          t("Amount with network fees can't exceed available account balance."),
          function (amount, context) {
            if (amount === undefined) return true
            const formSchema: TSchema = getParentSchema(context)
            const {accountId} = formSchema
            const asset: AssetSchema = context.parent
            const fieldAmount = getInputFieldValueFromValidation(context)
            const availableBalance = getAvailableBalance(
              accountId,
              asset,
              formSchema,
            )
            const hasNativeAmountField = formSchema.assets?.some(
              ({type}) => type === 'native',
            )
            if (asset.type === 'native') {
              return availableBalance.isGreaterThanOrEqualTo(
                getParsedNativeAmount(fieldAmount),
              )
            } else if (!hasNativeAmountField) {
              // if native amount field is not present
              // validate that fees do not exceed native balance
              const nativeAmount = new BigNumber(0).toString()
              const availableNativeBalance = getAvailableBalance(
                accountId,
                {type: 'native', amount: nativeAmount},
                formSchema,
              )

              const isFeeGreaterThanNativeBalance =
                availableNativeBalance.isLessThan(
                  getParsedNativeAmount(nativeAmount),
                )

              if (isFeeGreaterThanNativeBalance) {
                return context.createError({
                  message: t('network_fees_exceed_account_balance', {
                    coin: getCoinName(blockchain),
                  }),
                })
              }
            }
            return true
          },
        )
        .test(
          'send-asset-amount-not-more-than-max',
          t('Cannot send more than available'),
          function (amount, context) {
            if (amount === undefined) return true
            const formSchema: TSchema = getParentSchema(context)
            const {accountId} = formSchema
            const asset: AssetSchema = context.parent
            if (asset.type === 'native') return true

            const fieldAmount = getInputFieldValueFromValidation(context)
            const availableBalance = getAvailableBalance(
              accountId,
              asset,
              formSchema,
            )

            return availableBalance.isGreaterThanOrEqualTo(
              getParsedTokenAmount(fieldAmount, asset),
            )
          },
        )
        .test('too-many-decimals', '', function (amount, context) {
          if (amount === undefined) return true
          const asset: AssetSchema = context.parent
          const fieldAmount = getInputFieldValueFromValidation(context)
          const decimals = getDecimals(asset)
          const isValid = validateAssetDecimalsCount(fieldAmount, decimals)
          const message: yup.ValidationError = context.createError({
            message: tooManyDecimalsMessage(decimals),
          })

          return isValid || message
        }),
    }),
  )
}

export const useAmountDecimalValidation = ({decimals}: {decimals?: number}) => {
  const tooManyDecimalsMessage = useTooManyDecimalsMessage()

  return yup
    .string()
    .test(
      'too-many-decimals',
      tooManyDecimalsMessage(decimals || 0),
      (amount, context) => {
        if (amount === undefined || decimals === undefined) return true
        const fieldAmount = getInputFieldValueFromValidation(context)

        return validateAssetDecimalsCount(fieldAmount, decimals)
      },
    )
}

export const useAmountRequiredValidation = () => {
  const {t} = useTranslation()

  return yup
    .string()
    .typeError(t('Invalid number'))
    .test(
      'is-number-positive',
      t('Amount must be a positive number.'),
      (amount) => {
        if (amount === undefined) return true
        return new BigNumber(amount).isGreaterThan(0)
      },
    )
    .required(t('Amount is required.'))
}

export type BaseDetailsSchemaArgs = {
  isAddressValid: (address: string) => boolean | Promise<boolean>
  nameServiceAddressLabel?: string
}

const _useBaseDetailsSchema = ({
  isAddressValid,
  nameServiceAddressLabel: _nameServiceAddressLabel,
}: BaseDetailsSchemaArgs) => {
  const {t} = useTranslation()
  const nameServiceAddressLabel = _nameServiceAddressLabel || t('address')
  const isInNameServiceMode = (values: BaseDetailsSchema) =>
    values.toAddressNameServiceState.status !== 'disabled'

  return {
    assets: yup.array(),
    toAddress: yup
      .string()
      .test(
        'address-in-name-space-mode-must-be-resolved',
        function (value, context) {
          if (value === undefined) return true
          const values: BaseDetailsSchema = context.parent
          const {toAddressNameServiceState} = values
          if (!isInNameServiceMode(values)) return true
          if (toAddressNameServiceState.status === 'invalid') {
            return this.createError({
              message: t('address_not_found_in_name_service', {
                name: nameServiceAddressLabel,
              }),
            })
          }
          if (toAddressNameServiceState.status !== 'valid') {
            return this.createError({
              message: t('address_must_be_resolved_with_name_service', {
                name: nameServiceAddressLabel,
              }),
            })
          }
          return true
        },
      )
      .test(
        'address-is-valid',
        t('Invalid address'),
        async (value, context) => {
          if (value === undefined) return true
          const values: BaseDetailsSchema = context.parent
          // Avoid validating address in name service mode.
          // In this mode the address is validated on demand.
          if (isInNameServiceMode(values)) return true
          return await isAddressValid(value)
        },
      )
      .required(t('Address is required.')),
    accountId: yup.string(),
    addressType: yup.string(),
    toAddressNameServiceState: yup.object(),
  }
}

// ensure consistency of keys / values on TS level
export const useBaseDetailsSchema: (
  ...args: Parameters<typeof _useBaseDetailsSchema>
) => {
  [k in keyof BaseDetailsSchema]: ReturnType<typeof _useBaseDetailsSchema>[k]
} = _useBaseDetailsSchema

type SummaryDetailsSchemaArgs = {
  accounts: Array<AccountInfo>
}

const _useSummaryDetailsSchema = ({accounts}: SummaryDetailsSchemaArgs) => {
  const {t} = useTranslation()
  const loginInfo = useCurrentLoginInfo()
  // `yup.when` can not be typed so we use this workaround
  const accountIdKey: keyof BaseSendSchema = 'accountId'
  return {
    password: yup.string().when(accountIdKey, ([accountId]: unknown[]) => {
      const selectedAccount = ensureAccountById(
        accounts,
        accountId as AccountId,
      )
      if (isPasswordVerificationRequired(loginInfo, selectedAccount)) {
        return yup.string().required(t('Password is required.'))
      }
      return yup.string()
    }),
  }
}

// ensure consistency of keys / values on TS level
export const useSummaryDetailsSchema: (
  ...args: Parameters<typeof _useSummaryDetailsSchema>
) => {
  [k in keyof SummaryDetailsSchema]: ReturnType<
    typeof _useSummaryDetailsSchema
  >[k]
} = _useSummaryDetailsSchema

export const staticInitialSendFormValues = {
  toAddress: '',
  addressType: 'external',
  toAddressNameServiceState: {status: 'disabled'},
  password: '',
} as const
