import type {
  AutocompleteRenderGroupParams,
  AutocompleteRenderOptionState,
} from '@mui/material'
import {TextField, Autocomplete, ListSubheader, Typography} from '@mui/material'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import type {ListChildComponentProps} from 'react-window'
import {VariableSizeList} from 'react-window'

type RenderOptionResultChild<T> = {
  props: React.LiHTMLAttributes<HTMLLIElement>
  option: T
  state: AutocompleteRenderOptionState | undefined
}

// overriding default @mui AutocompleteRenderGroupParams['children'] type to be more specific
export type RenderOptionResult<T> =
  | RenderOptionResultChild<T>
  | (Omit<AutocompleteRenderGroupParams, 'children'> & {
      children: RenderOptionResultChild<T>[]
    })

type SearchableSelectProps<T> = {
  options: T[]
  value: T | null
  // getOptionLabel: Used to determine the string value for a given option. It's used to fill the
  // input (and the list box options if renderOption is not provided).
  getOptionLabel: (option: T) => string
  isLoading?: boolean
  onChange: (value: T | null) => void
  renderStartAdornment?: (value?: T) => React.ReactNode
  groupBy?: (option: T) => string
  filterOptions?: React.ComponentProps<typeof Autocomplete<T>>['filterOptions']
  renderGroup?:
    | ((params: AutocompleteRenderGroupParams) => React.ReactNode)
    | undefined
  label?: string
  labelWhenSelected?: string
  selectClasses?: Record<string, string>
  searchable?: boolean
  error?: boolean
  helperText?: React.ReactNode
  PaperComponent?: React.ComponentType<{
    children?: React.ReactNode
  }>
  ListboxComponent?: React.ComponentType
  getOptionDisabled?: (option: T) => boolean
  // isOptionEqualToValue: "Used to determine if an option is selected,considering the current value
  // Uses strict equality by default." (Autocomplete API can't compare options which are objects)
  isOptionEqualToValue?: (option: T, value: T) => boolean
  noOptionsText?: string
  renderRow?: renderRowArgs<T>
  customRenderRow?: customRenderRowArgs<T>
  listItemOptions?: Partial<ListItemOptionsKeys>
  MAX_DISPLAYED_ITEMS?: number
}

const defaultListItemOptions = {
  ITEM_SIZE: 36,
  GROUP_ITEM_SIZE: 48,
  LISTBOX_PADDING: 8,
  MAX_DISPLAYED_ITEMS: 6,
}

/**
 *
 * ### Uses virtualization by default
 * #### Rendering options:
 * ```jsx
 * // Used to determine the string value for a given option, fills the input and list box options if renderRow is not provided.
 * getOptionLabel(option: T) => string
 *
 * // Render the option, uses getOptionLabel() by default.
 * renderRow(option: T) => ReactNode // option: The option to render.
 *
 * // customize renderRow, enables to customize ReactChildren and render them in virtualized list using renderRow()
 * customRenderRow(props: React.PropsWithChildren, renderRow: (option: T, items) => ReactNode) => ReactNode
 *
 * // virtualization requires fixed size items
 * type listItemOptions = {ITEM_SIZE?: number, GROUP_ITEM_SIZE?: number, LISTBOX_PADDING?: number, MAX_DISPLAYED_ITEMS?: number} // in px
 * ```
 *
 */
export default function SearchableSelect<T>({
  label,
  options,
  getOptionLabel,
  isLoading,
  onChange,
  groupBy,
  filterOptions,
  renderStartAdornment,
  PaperComponent,
  labelWhenSelected,
  error,
  helperText,
  selectClasses = {},
  searchable = false,
  value,
  renderRow,
  getOptionDisabled,
  isOptionEqualToValue,
  noOptionsText,
  customRenderRow,
  listItemOptions,
  MAX_DISPLAYED_ITEMS,
}: SearchableSelectProps<T>) {
  const {t} = useTranslation()
  const [shouldShowSelectedLabel, setShowSelectedLabel] = useState(false)
  return (
    <Autocomplete
      loading={isLoading}
      blurOnSelect
      classes={selectClasses}
      onChange={(event, newValue) => {
        if (labelWhenSelected && newValue) {
          setShowSelectedLabel(true)
        }
        return onChange(newValue)
      }}
      onInputChange={(e, value, reason) => {
        if (labelWhenSelected && (reason === 'input' || reason === 'clear')) {
          setShowSelectedLabel(false)
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          error={error}
          helperText={helperText}
          label={shouldShowSelectedLabel ? labelWhenSelected : label}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            ...(renderStartAdornment &&
              value && {startAdornment: renderStartAdornment(value)}),
          }}
          inputProps={{
            ...params.inputProps,
            readOnly: !searchable,
            autoComplete: 'off', // disable autocomplete and autofill
          }}
          // TODO: handle better, see comments in Assets file (portfolio)
          classes={
            selectClasses.autocompleteLabel
              ? {root: selectClasses.autocompleteLabel}
              : {}
          }
        />
      )}
      // only affecting the type of ListChildComponentProps
      renderOption={(props, option, state) =>
        ({
          props,
          option,
          state,
          // We cast this as ReactNode for renderOption requirements.
          // Under the hood, these props are passed to renderListboxComponent
          // where we maintain full control over them and decide what gets rendered
          // inspired by https://mui.com/components/autocomplete/#virtualization
        }) as unknown as React.ReactNode
      }
      // only affecting the type of ListChildComponentProps
      // the casting to React.ReactNode is explained for renderOption
      renderGroup={(params) => params as unknown as React.ReactNode}
      disableClearable={!searchable}
      disableListWrap
      noOptionsText={noOptionsText || t('No options')}
      {...{
        options,
        getOptionLabel,
        value,
        groupBy,
        filterOptions,
        PaperComponent,
        getOptionDisabled,
        isOptionEqualToValue,
      }}
      ListboxComponent={renderListboxComponent({
        listItemOptions: {
          ...defaultListItemOptions,
          ...(MAX_DISPLAYED_ITEMS ? {MAX_DISPLAYED_ITEMS} : {}),
          ...(listItemOptions || {}),
        },
        renderRow: renderRow || ((option: T) => getOptionLabel(option)),
        customRenderRow,
      })}
    />
  )
}

// inspired by https://mui.com/components/autocomplete/#virtualization
const OuterElementContext = React.createContext({})

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext)
  return <div ref={ref} {...props} {...outerProps} />
})

function useResetCache(data: unknown) {
  const ref = React.useRef<VariableSizeList>(null)
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true)
    }
  }, [data])
  return ref
}

function renderListItem<T>(
  props: ListChildComponentProps<RenderOptionResult<T>[]>,
  renderRow: renderRowArgs<T>,
  listItemOptions: ListItemOptionsKeys,
) {
  const {data, index, style} = props
  const dataSet = data[index]
  const inlineStyle = {
    ...style,
    top: (style.top as number) + listItemOptions.LISTBOX_PADDING,
  }

  // rendering Group Header if grouping is used
  if (dataSet?.hasOwnProperty('group')) {
    const {key, group} = dataSet as AutocompleteRenderGroupParams

    return (
      <ListSubheader key={key} component="div" style={inlineStyle}>
        {group}
      </ListSubheader>
    )
  } else {
    const {props, option} = dataSet as RenderOptionResultChild<T>
    return (
      <Typography component="li" {...props} style={inlineStyle}>
        {renderRow(option)}
      </Typography>
    )
  }
}

type ListItemOptionsKeys = {
  ITEM_SIZE: number // px
  GROUP_ITEM_SIZE: number // px
  LISTBOX_PADDING: number // px
  MAX_DISPLAYED_ITEMS: number
}

type renderRowArgs<T> = (
  option: RenderOptionResultChild<T>['option'],
) => React.ReactNode

type renderVariableSizeListArgs<T> = (
  renderRow: renderRowArgs<T>,
  items: RenderOptionResult<T>[],
) => React.ReactNode

type customRenderRowArgs<T> = (
  props: Omit<React.HTMLAttributes<HTMLElement>, 'children'> & {
    children: RenderOptionResult<T>[]
  },
  renderRow: renderVariableSizeListArgs<T>,
) => React.ReactNode

type renderListboxComponentArgs<T> = {
  listItemOptions: ListItemOptionsKeys
  renderRow: renderRowArgs<T>
  customRenderRow?: customRenderRowArgs<T>
}

function renderListboxComponent<T>({
  listItemOptions,
  renderRow,
  customRenderRow,
}: renderListboxComponentArgs<T>) {
  return React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>(
    function ListboxComponent(props, ref) {
      const {ITEM_SIZE, GROUP_ITEM_SIZE, LISTBOX_PADDING, MAX_DISPLAYED_ITEMS} =
        listItemOptions
      const {children, ...other} = props

      // children can be grouped if groupBy is being applied
      // destructuring the potential grouping to flat array
      const itemData: RenderOptionResult<T>[] = (
        children as RenderOptionResult<T>[]
      ).flatMap(
        (item: RenderOptionResult<T> & {children?: RenderOptionResult<T>[]}) =>
          item.children ? [item, ...item.children] : item,
      )
      const itemCount = itemData.length

      const getChildSize = (child: RenderOptionResult<T>) =>
        child.hasOwnProperty('group') ? GROUP_ITEM_SIZE : ITEM_SIZE

      // get height of opened listbox
      const getHeight = () =>
        itemCount > MAX_DISPLAYED_ITEMS
          ? ITEM_SIZE * MAX_DISPLAYED_ITEMS
          : itemData.map(getChildSize).reduce((a, b) => a + b, 0)

      const gridRef = useResetCache(itemCount)

      const renderVariableSizeList: renderVariableSizeListArgs<T> = (
        renderRow,
        items,
      ) => (
        <OuterElementContext.Provider value={other}>
          <VariableSizeList
            itemData={items}
            height={getHeight() + 2 * LISTBOX_PADDING}
            width="100%"
            ref={gridRef}
            outerElementType={OuterElementType}
            innerElementType="div"
            itemSize={(index) => getChildSize(items[index]!)}
            overscanCount={5}
            itemCount={items.length}
          >
            {(props) => renderListItem(props, renderRow, listItemOptions)}
          </VariableSizeList>
        </OuterElementContext.Provider>
      )

      return (
        <div ref={ref}>
          {customRenderRow?.(
            {...props, children: itemData},
            renderVariableSizeList,
          ) || renderVariableSizeList(renderRow, itemData)}
        </div>
      )
    },
  )
}
