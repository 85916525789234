import {Typography, Box} from '@mui/material'
import React from 'react'

type MainSectionHeaderProps = {
  children: string | JSX.Element
  disableBottomSpacing?: boolean
}

export function MainSectionHeader({
  children,
  disableBottomSpacing = false,
}: MainSectionHeaderProps) {
  return (
    <Typography variant="h5">
      <Box mb={disableBottomSpacing ? 0 : 2}>{children}</Box>
    </Typography>
  )
}
