import _ from 'lodash'

import type {Currency} from 'src/features/conversionRates/domain'
import type {Blockchain} from 'src/types'
import {notNullish} from 'src/utils/helpers'

export const MOONPAY_NETWORK_CODE_TO_BLOCKCHAIN: Partial<
  Record<string, Blockchain>
> = {
  cardano: 'cardano',
  ethereum: 'ethereum',
  polygon: 'polygon',
  solana: 'solana',
  flow: 'flow',
  optimism: 'optimism',
  arbitrum: 'arbitrumOne',
  base: 'base',
}

export const MOONPAY_COIN_TO_BLOCKCHAIN: Record<string, Blockchain> = {
  ada: 'cardano',
  eth: 'ethereum',
  flow: 'flow',
  matic_polygon: 'polygon',
  sol: 'solana',
  eth_optimism: 'optimism',
  eth_arbitrum: 'arbitrumOne',
  eth_base: 'base',
} as const

export type MoonpaySupportedCoinCode = keyof typeof MOONPAY_COIN_TO_BLOCKCHAIN

export const MOONPAY_SUPPORTED_COINS = Object.keys(
  MOONPAY_COIN_TO_BLOCKCHAIN,
) as MoonpaySupportedCoinCode[]

export const CURRENCY_TO_MOONPAY_CURRENCY_CODE: Partial<
  Record<Currency, string>
> = {
  AUD: 'aud',
  BRL: 'brl',
  CAD: 'cad',
  CHF: 'chf',
  CZK: 'czk',
  EUR: 'eur',
  GBP: 'gbp',
  IDR: 'idr',
  MXN: 'mxn',
  NOK: 'nok',
  NZD: 'nzd',
  PLN: 'pln',
  THB: 'thb',
  TRY: 'try',
  USD: 'usd',
  VND: 'vnd',
}

export const MOONPAY_CURRENCY_CODE_TO_CURRENCY = _(
  CURRENCY_TO_MOONPAY_CURRENCY_CODE,
)
  .pickBy(notNullish)
  .invert()
  .value() satisfies _.Dictionary<string> as Partial<Record<string, Currency>>

export type MoonpayCurrency = MoonpayFiatCurrency | MoonpayCryptoCurrency

export type MoonpayFiatCurrency = {
  /** Unique identifier for the currency. */
  id: string
  /** Time at which the object was created. Returned as an ISO 8601 string. */
  createdAt: string
  /** Time at which the object was last updated. Returned as an ISO 8601 string. */
  updatedAt: string
  /** The currency's type. */
  type: 'fiat'
  /** The currency's name. */
  name: string
  /** The currency's code. */
  code: string
  /** The currency's precision (number of digits after decimal point). */
  precision: number
  /** Represents the minimum transaction buy amount when using this currency as a base currency. */
  minBuyAmount: number | null
  /** Represents the maximum transaction buy amount when using this currency as a base currency. */
  maxBuyAmount: number | null
  /** Whether sales for this currency are supported. */
  isSellSupported: boolean
}

export type MoonpayCryptoCurrency = {
  /** Unique identifier for the currency. */
  id: string
  /** Time at which the object was created. Returned as an ISO 8601 string. */
  createdAt: string
  /** Time at which the object was last updated. Returned as an ISO 8601 string. */
  updatedAt: string
  /** The currency's type. */
  type: 'crypto'
  /** The currency's name. */
  name: string
  /** The currency's code. */
  code: string
  /** The currency's precision (number of digits after decimal point). */
  precision: number
  /** Represents the minimum amount of cryptocurrency you can buy. */
  minBuyAmount: number | null
  /** Represents the maximum amount of cryptocurrency you can buy. */
  maxBuyAmount: number | null
  /** The minimum amount of cryptocurrency you can sell. */
  minSellAmount: number | null
  /** The maximum amount of cryptocurrency you can sell. */
  maxSellAmount: number | null
  /** A regular expression which you can test against your end user's wallet addresses. */
  addressRegex: string
  /** A regular expression which you can test against your end user's testnet wallet addresses. */
  testnetAddressRegex: string
  /** Whether the currency supports address tags. */
  supportsAddressTag: boolean
  /** A regular expression which you can test against a wallet address tag. Defined only if the currency supports address tags. */
  addressTagRegex: string | null
  /** Whether the currency supports test mode. */
  supportsTestMode: boolean
  /** Whether purchases for this currency are suspended. If the currency is suspended, exchange rates may not be available and it is not possible to create a transaction with this currency. */
  isSuspended: boolean
  /** Whether purchases for this currency are supported in the US. */
  isSupportedInUs: boolean
  /** Whether sales for this currency are supported. */
  isSellSupported: boolean
  /** A list with all the US states for this currency that are not supported. */
  notAllowedUSStates: string[]
  /** A list with all the ISO 3166-1 alpha-2 country codes for this currency that are not supported. */
  notAllowedCountries: string[]
  /** Additional metadata for the currency. */
  metadata: {
    /** Unique contract address where the token smart contract is hosted. */
    contractAddress: string | null
    /** ID used to identify different EVM compatible chains. */
    chainId: string | null
    /** Name of the cryptocurrency */
    networkCode: string
  }
}
