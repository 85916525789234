import {
  Button,
  Grid,
  Box,
  Typography,
  useTheme,
  capitalize,
  alpha,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {useGetHistoricalConversionRates} from 'src/features/conversionRates/application'
import type {
  Currency,
  HistoricalConversionRatesGranularity,
} from 'src/features/conversionRates/domain'

import type {AssetDetailBlockchain} from '../../../blockchainTypes'
import {QueryGuard, LoadChartError} from '../../../components'
import {RateChart} from '../../../components/visual/charts/RateChart'
import {useAssetDetailRouteOptions} from '../../../router/portfolio'
import type {Blockchain, Nft, TokenId} from '../../../types'
import {useGetNft, useGetTokenMetadata} from '../../../wallet'
import {blockchainMetadata} from '../../../wallet/constants'
import {parseNft} from '../../../wallet/utils/nfts'

import {AboutRow} from './AboutRow'
import {
  AssetDetailNFTDescriptionLoading,
  AssetDetailNFTAboutLoading,
} from './Loadings'
import {NftMedia} from './NftMedia'
import {TokenDetailInfo} from './TokenDetailInfo'

type AssetDetailInfoLayoutProps = {
  renderHistoricalConversionRates: (blockchain: Blockchain) => React.ReactNode
  renderNews: () => React.ReactNode
}

export const AssetDetailInfoLayout = ({
  renderHistoricalConversionRates,
  renderNews,
}: AssetDetailInfoLayoutProps) => {
  const {blockchain} = useAssetDetailRouteOptions()
  const classes = useLayoutStyles()

  return (
    <Box className={clsx(classes.wrapper)}>
      <Box className={classes.historicalRates}>
        {renderHistoricalConversionRates(blockchain)}
      </Box>
      <Box className={classes.news}>{renderNews()}</Box>
    </Box>
  )
}

type TokenDetailInfoLayoutProps = {
  renderNews: () => React.ReactNode
  tokenId: TokenId
}

export const TokenDetailInfoLayout = ({
  renderNews,
  tokenId,
}: TokenDetailInfoLayoutProps) => {
  const classes = useLayoutStyles()
  const {blockchain} = useAssetDetailRouteOptions()
  const nftInfoQuery = useGetNft(tokenId, blockchain)

  function renderTokenLayout(
    LeftSection: React.ReactNode,
    RightSection: React.ReactNode,
  ) {
    return (
      <>
        <Box className={classes.about}>{LeftSection}</Box>
        <Box className={clsx(classes.news, classes.tokensNews)}>
          {RightSection}
        </Box>
      </>
    )
  }

  return (
    <Box className={clsx(classes.wrapper, classes.tokensWrapper)}>
      <QueryGuard
        {...nftInfoQuery}
        data={nftInfoQuery.data}
        errorVariant="inline"
        LoadingElement={<AssetDetailNFTDescriptionLoading />}
      >
        {(nftInfo) =>
          nftInfo
            ? renderTokenLayout(
                <NftMedia nft={nftInfo} />,
                <About {...{nftInfo, tokenId}} />,
              )
            : renderTokenLayout(<About {...{nftInfo, tokenId}} />, renderNews())
        }
      </QueryGuard>
    </Box>
  )
}

const useLayoutStyles = makeStyles((theme) => ({
  wrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  tokensWrapper: {
    [theme.breakpoints.up('assetDetailNews')]: {
      flexDirection: 'row',
    },
  },
  about: {
    // Not resizing "about" even if there is extra space otherwise it is more difficult
    // to read the details as the values are too much apart
    width: 650,
    paddingTop: theme.spacing(2),
  },
  news: {
    paddingTop: theme.spacing(4),
    minHeight: 500,
    flex: 1,
  },
  tokensNews: {
    [theme.breakpoints.up('assetDetailNews')]: {
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(1.5),
    },
  },
  historicalRates: {
    borderRadius: '5px',
    padding: theme.spacing(3),
    marginTop: theme.spacing(2),
    background: theme.palette.background.paper,
  },
}))

type HistoricalConversionRatesProps = {
  blockchain: Blockchain
  currency: Currency
  defaultGranularity: HistoricalConversionRatesGranularity
}

const granularityToText = {
  '1D': 'Today',
  '1W': 'Last Week',
  '1M': 'Last Month',
  '6M': 'Last 6 Months',
  YTD: 'This Year',
  '1Y': 'Last 12 Months',
  MAX: 'Max',
}

export const HistoricalConversionRates = ({
  blockchain,
  currency,
  defaultGranularity,
}: HistoricalConversionRatesProps) => {
  const classes = useRatesStyles()
  const {t} = useTranslation()
  const [stateData, setStateData] = useState({
    granularity: defaultGranularity,
    text: t(granularityToText[defaultGranularity]),
  })

  const {error, isLoading, data} = useGetHistoricalConversionRates(
    blockchain,
    currency,
    stateData.granularity,
  )

  return (
    <Grid
      container
      direction="column"
      spacing={2}
      className={classes.historicalRatesWrapper}
    >
      <Grid item container direction="row">
        <Box flexGrow={1}>
          <Typography variant="h5">{t(stateData.text)}</Typography>
        </Box>
        <Box>
          {(
            Object.keys(
              granularityToText,
            ) as HistoricalConversionRatesGranularity[]
          ).map((granularity, index) => (
            <Button
              color={
                granularity === stateData.granularity ? 'primary' : 'default'
              }
              sx={
                granularity === stateData.granularity
                  ? {
                      backgroundColor: (theme) =>
                        alpha(theme.palette.primary.main, 0.1),
                      border: (theme) =>
                        `1px solid ${alpha(theme.palette.primary.main, 0.5)}`,
                    }
                  : {}
              }
              key={index}
              onClick={() => {
                setStateData({
                  text: t(granularityToText[granularity]),
                  granularity,
                })
              }}
            >
              {t(granularity)}
            </Button>
          ))}
        </Box>
      </Grid>
      <Grid item className={classes.historicalRatesChartsWrapper}>
        <QueryGuard
          ErrorElement={<LoadChartError />}
          {...{error, isLoading}}
          data={data}
          loadingVariant="centered"
        >
          {(data) => (
            <RateChart
              data={data}
              currency={currency}
              granularity={stateData.granularity}
            />
          )}
        </QueryGuard>
      </Grid>
    </Grid>
  )
}

const useRatesStyles = makeStyles(() => ({
  historicalRatesWrapper: {
    width: '100%',
  },
  historicalRatesChartsWrapper: {
    width: '100%',
    height: 300,
  },
}))

type NewsProps = {
  blockchain: AssetDetailBlockchain
  disableHeader?: boolean
}

const blockchainToCryptopanicCurrency = (
  blockchain: AssetDetailBlockchain,
): string => {
  return blockchainMetadata[blockchain].cryptoPanicCurrency
}

export const News = ({blockchain, disableHeader = false}: NewsProps) => {
  const theme = useTheme()
  // hardcoding font family to sans, available options are just sans | mono | serif
  // see widget configurator/docs: https://cryptopanic.com/developers/widgets/
  const widgetTheme = `bg_color=${theme.palette.background.default.substring(
    1,
  )}&font_family=sans&header_bg_color=${theme.palette.background.paper.substring(
    1,
  )}&header_text_color=${theme.palette.text.primary.substring(
    1,
  )}&link_color=${theme.palette.secondary.contrastText.substring(
    1,
  )}&text_color=${theme.palette.secondary.contrastText.substring(1)}`

  const {t} = useTranslation()
  const cryptopanicUrl = 'https://cryptopanic.com/widgets/news/'
  const classes = useNewsStyles()
  return (
    <Box display="flex" flexDirection="column" height="100%">
      {!disableHeader && (
        <Typography className={classes.header} variant="h5">
          {t('News')}
        </Typography>
      )}
      <Box flex="1">
        <iframe
          scrolling="yes"
          frameBorder="0"
          src={`${cryptopanicUrl}?currencies=${blockchainToCryptopanicCurrency(
            blockchain,
          )}&news_feed=recent&posts_limit=10&title=${t(
            'Latest News',
          )}&${widgetTheme}`}
          width="100%"
          height="100%"
        />
      </Box>
    </Box>
  )
}

const useNewsStyles = makeStyles((theme) => ({
  header: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingBottom: theme.spacing(1),
  },
  content: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}))

type AboutProps = {
  tokenId: TokenId
  nftInfo: Nft | null
}

export const About = ({tokenId, nftInfo}: AboutProps) => {
  const {t} = useTranslation()
  const classes = useAboutStyles()
  const {blockchain} = useAssetDetailRouteOptions()
  const {data, isLoading, error} = useGetTokenMetadata(tokenId, blockchain)

  return (
    <QueryGuard
      errorVariant="inline"
      {...{data, error, isLoading}}
      LoadingElement={<AssetDetailNFTAboutLoading />}
    >
      {(tokenMetadata) => (
        <>
          {!nftInfo && (
            <Typography className={classes.header} variant="h5">
              {t('About')}
            </Typography>
          )}
          <Grid
            container
            direction="column"
            spacing={2}
            classes={{root: classes.gridRoot}}
            className={classes.content}
          >
            {!nftInfo && (
              <>
                <AboutRow
                  label={t('Blockchain')}
                  value={capitalize(tokenMetadata.blockchain)}
                />
                <AboutRow
                  label={t('Name')}
                  value={
                    tokenMetadata.name ??
                    (nftInfo && parseNft(nftInfo).name) ??
                    t('N/A')
                  }
                />
              </>
            )}
            <TokenDetailInfo token={tokenMetadata} nftInfo={nftInfo} />
          </Grid>
        </>
      )}
    </QueryGuard>
  )
}

const useAboutStyles = makeStyles((theme) => ({
  header: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingBottom: theme.spacing(1),
  },
  content: {
    marginTop: theme.spacing(0),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  nft: {
    width: '100%',
    height: 350,
    marginBottom: theme.spacing(2),
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundColor: theme.palette.background.paper,
    borderRadius: 5,
  },
  gridRoot: {
    width: '100%',
    marginLeft: 0,
  },
}))
