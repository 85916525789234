import {Box, TextField, InputAdornment} from '@mui/material'
import {isEvmBlockchain} from '@nufi/wallet-evm'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {isWalletConnectRoute} from 'src/utils/walletConnect'

import {useCurrentProfileMeta} from '../../appStorage'
import {
  AccountSelectField,
  QueryGuard,
  Web3AuthProviderIcon,
} from '../../components'
import type {NumberFormat} from '../../components/formatting'
import {formatWeb3AuthProfileName} from '../../components/formatting'
import type {Web3AuthLoginProvider} from '../../features/login'
import {useCurrentLoginInfo} from '../../store/auth'
import type {AccountOfflineInfo, AccountInfo, AccountId} from '../../types'
import {useGetAccounts} from '../../wallet'
import {OUTER_CONTENT_WIDTH} from '../constants'

import {OriginMessage} from './OriginMessage'
import type {OriginMessageProps} from './OriginMessage'

type ModeProps =
  | {
      mode: 'account-change-possible'
      selectedAccount: AccountOfflineInfo
      setSelectedAccount: (account: AccountOfflineInfo) => unknown
    }
  | {
      mode: 'account-change-disabled'
      selectedAccount: AccountOfflineInfo
    }

type ActionHeaderProps = {
  message: React.ReactElement | string
  hideProfileInfo?: boolean
  contentWidth?: number | string
  selectedAccountAmountFormat?: NumberFormat
} & ModeProps &
  OriginMessageProps

export function ActionHeader({
  message,
  origin,
  favIconUrl,
  selectedAccount,
  hideProfileInfo,
  selectedAccountAmountFormat,
  contentWidth = OUTER_CONTENT_WIDTH,
  ...restProps
}: ActionHeaderProps) {
  const {t} = useTranslation()
  const accountsQuery = useGetAccounts(selectedAccount.blockchain)
  const currentProfileMetaQuery = useCurrentProfileMeta()
  const currentLoginInfo = useCurrentLoginInfo()

  const originFontVariant = origin.length > 20 ? 'body1' : undefined

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <OriginMessage
        {...{origin, favIconUrl, message, originFontVariant, contentWidth}}
      />
      <Box mt={1} />
      {selectedAccount && (
        <QueryGuard {...accountsQuery}>
          {(accounts: AccountInfo[]) => {
            const accountInfo = accounts.find(
              (a) => a.id === selectedAccount.id,
            )
            if (!accountInfo) return null
            const canChangeAccount =
              restProps.mode === 'account-change-possible' &&
              isEvmBlockchain(accountInfo.blockchain) &&
              // Changing accounts isn't supported in our WalletConnect handlers yet.
              // TODO: Make this reactive, currently React Router isn't being used
              // in web extension/dapp connector, so we can't call useLocation().
              !isWalletConnectRoute(window.location.pathname)
            return (
              <Box width={contentWidth} maxWidth="100%">
                {!hideProfileInfo && (
                  <>
                    <TextField
                      value={
                        currentLoginInfo.loginType === 'web3Auth'
                          ? formatWeb3AuthProfileName(currentLoginInfo.user)
                          : currentProfileMetaQuery.data?.name ??
                            t('Loading...')
                      }
                      label={t('Wallet')}
                      variant="outlined"
                      disabled
                      fullWidth
                      size="small"
                      InputProps={{
                        startAdornment: currentLoginInfo.loginType ===
                          'web3Auth' && (
                          <InputAdornment position="start">
                            <Web3AuthProviderIcon
                              provider={
                                currentLoginInfo.user
                                  .typeOfLogin as Web3AuthLoginProvider
                              }
                              size={18}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Box mt={2} />
                  </>
                )}
                <AccountSelectField
                  label={'Account'}
                  onChange={(e) => {
                    const accountId = e.target.value as AccountId
                    const account = accounts.find((a) => a.id === accountId)
                    if (canChangeAccount && account) {
                      restProps.setSelectedAccount(account)
                    }
                  }}
                  value={accountInfo.id}
                  items={accounts.map((a) => ({...a, accountId: a.id}))}
                  blockchain={accountInfo.blockchain}
                  disabled={!canChangeAccount}
                  size="small"
                  showExplorerButton
                  amountFormat={selectedAccountAmountFormat}
                  showBlockchainIcon
                />
              </Box>
            )
          }}
        </QueryGuard>
      )}
    </Box>
  )
}
