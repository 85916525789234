// TODO: introduce error handling strategy (tmp)
export enum AppStorageError {
  PROFILE_EXISTS = 'Profile exists',
  PROFILE_DOES_NOT_EXIST = 'Profile does not exist',
  PROFILE_NOT_INITIALIZED = 'Profile not initialized',
  PROFILE_MANAGER_NOT_INITIALIZED = 'Profile manager instance not initialized',
  WRONG_PASSWORD = 'Wrong password',
  INVALID_BACKUP_CONTENT = 'Corrupted store - invalid backup content',
  INVALID_MNEMONIC_CONTENT = 'Corrupted store - invalid mnemonic content',
  INVALID_MNEMONIC_DATA = 'Corrupted store - invalid mnemonic data',
  INVALID_PROFILE_CONTENT = 'Corrupted store - invalid profile content',
  INVALID_PROFILE_DATA = 'Corrupted store - invalid profile data',
}

export const isCorruptedStoreError = (error: Error) =>
  [
    AppStorageError.INVALID_BACKUP_CONTENT,
    AppStorageError.INVALID_MNEMONIC_CONTENT,
    AppStorageError.INVALID_MNEMONIC_DATA,
    AppStorageError.INVALID_PROFILE_CONTENT,
    AppStorageError.INVALID_PROFILE_DATA,
  ].includes(error.message as AppStorageError)

export {StorageError} from '@nufi/storage'
