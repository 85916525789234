import {Box, TextField, Typography, Divider, Grid} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import type Bignumber from 'bignumber.js'
import clsx from 'clsx'
import type {FormikProps} from 'formik'
import React from 'react'
import {useTranslation} from 'react-i18next'

import type {StakingBlockchain} from '../../../../blockchainTypes'
import {
  ModalLayout,
  ModalFooter,
  FooterLayout,
  Alert,
  AmountRow,
  TextButton,
  TotalRow,
  useModalSharedStyles,
  WithTooltip,
  CurrentPasswordField,
} from '../../../../components'
import {TransactionFee} from '../../../../components/TransactionFee'
import {useIsPasswordVerificationRequired} from '../../../../store/auth'
import {
  getHasFormError,
  isValidAmountFieldValue,
  sanitizeAmountFieldValue,
} from '../../../../utils/form'
import {useGetCoinName} from '../../../../utils/translations'
import type {AccountInfo} from '../../../../wallet'
import {WithdrawModalHeader} from '../../common/utils'
import type {BaseWithdrawStakeSchema} from '../schema'

type SummaryScreenProps<T extends BaseWithdrawStakeSchema> = {
  blockchain: StakingBlockchain
  onClose: () => void
  onBack: () => unknown
  onSubmit: () => unknown
  summary: {
    availableWithdrawAmount: Bignumber
    amount: Bignumber
    fee: Bignumber
    fromAccount: AccountInfo
    parentAccountName: string
  }
  formikProps: FormikProps<T>
  maxAmountOptions?: {
    onMaxAmount?: () => unknown
    disabled?: boolean
    helperText?: string
  }
}

const FORM_ID = 'withdraw-stake-form-confirm-details'

export default function SummaryScreen<T extends BaseWithdrawStakeSchema>({
  onBack,
  onSubmit,
  blockchain,
  onClose,
  summary,
  formikProps,
  maxAmountOptions,
}: SummaryScreenProps<T>) {
  const {t} = useTranslation()
  const classes = {...useStyles(), ...useModalSharedStyles()}
  const getCoinName = useGetCoinName()
  const {values, handleChange, errors, handleBlur} = formikProps
  type FormField = keyof typeof values
  const hasErrorAfterTouched = getHasFormError(formikProps, 'after-touched')
  const hasImmediateError = getHasFormError(formikProps, 'immediate')
  const isPasswordVerificationRequired = useIsPasswordVerificationRequired(
    summary.fromAccount,
  )

  const MaxButton = maxAmountOptions && (
    <TextButton
      color="text.primary"
      disabled={!!maxAmountOptions?.disabled}
      label={t('MAX')}
      onClick={maxAmountOptions?.onMaxAmount}
    />
  )

  return (
    <form
      onSubmit={onSubmit}
      noValidate
      id={FORM_ID}
      className={classes.formWrapper}
    >
      <ModalLayout
        header={<WithdrawModalHeader {...{onClose, blockchain}} />}
        body={
          <Box p={2}>
            <Box className={classes.commonBottomMargin}>
              <Alert
                overrides={{root: classes.responsiveAlert}}
                text={`${t('Funds will be withdrawn to the parent account')} (${
                  summary.parentAccountName
                })`}
                severity="info"
              />
            </Box>
            <AmountRow
              label={t('Available to withdraw')}
              amount={summary.availableWithdrawAmount}
              blockchain={blockchain}
            />
            <Divider className={classes.dividerY} />
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="subtitle2" className={classes.formField}>
                  {t('Amount and fee')}
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.formField}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label={`${t('Amount')} (${getCoinName(blockchain)})`}
                  onBlur={(e) => {
                    // without handleBlur the "touched" fields are not populated
                    const onBlur = handleBlur<FormField>('amount') as (
                      e: unknown,
                    ) => void
                    onBlur(e)
                  }}
                  onChange={(e) => {
                    const sanitized = sanitizeAmountFieldValue(e.target.value)
                    const isValid =
                      isValidAmountFieldValue(sanitized) || sanitized === ''
                    if (!isValid) return
                    handleChange<FormField>('amount')(sanitized)
                  }}
                  error={hasErrorAfterTouched('amount')}
                  value={values.amount}
                  helperText={
                    hasErrorAfterTouched('amount') && <>{errors.amount}</>
                  }
                  InputProps={{
                    endAdornment:
                      maxAmountOptions?.disabled &&
                      maxAmountOptions?.helperText ? (
                        <WithTooltip
                          title={maxAmountOptions.helperText}
                          placement="left"
                        >
                          {MaxButton}
                        </WithTooltip>
                      ) : (
                        MaxButton
                      ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TransactionFee
                  blockchain={blockchain}
                  amount={summary.fee}
                  label={t('Transaction fee')}
                />
              </Grid>
              <Grid item xs={12} className={classes.smallScreenDivider}>
                <Box
                  className={clsx(
                    classes.commonTopMargin,
                    classes.commonBottomMargin,
                  )}
                >
                  <Alert
                    text={`${t('Fees paid by parent account')} (${
                      summary.parentAccountName
                    })`}
                    severity="info"
                  />
                </Box>
              </Grid>
            </Grid>
            <Divider className={classes.dividerY} />
            <AmountRow
              label={t('Transaction fee')}
              amount={summary.fee}
              blockchain={blockchain}
            />
            <TotalRow
              nativeAmount={summary.amount}
              blockchain={blockchain}
              totalText={t('Total to withdraw')}
            />
          </Box>
        }
        footer={
          <ModalFooter hasDivider>
            {hasImmediateError('hasSufficientFeeFunds') && (
              <Box mb={2}>
                <Alert
                  severity="error"
                  text={errors.hasSufficientFeeFunds as string}
                />
              </Box>
            )}
            {isPasswordVerificationRequired && (
              <CurrentPasswordField
                {...{formikProps}}
                className={classes.passwordHeight}
              />
            )}
            <FooterLayout
              leftBtnConf={{
                onClick: onBack,
                children: t('Back'),
              }}
              rightBtnConf={{
                children: t('Withdraw'),
                type: 'submit',
                form: FORM_ID,
                disabled: formikProps.isSubmitting || !formikProps.isValid,
              }}
            />
          </ModalFooter>
        }
      />
    </form>
  )
}

const useStyles = makeStyles(() => ({
  centeringWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
}))
