import config from 'src/config'
import {createServiceLocator} from 'src/utils/serviceLocator'

import type {CloudSyncService, CloudSyncServiceFactoryParams} from '../domain'
import {cloudSyncServiceFactory} from '../domain'

export const cloudSyncServiceLocator = createServiceLocator(
  (params: CloudSyncServiceFactoryParams): CloudSyncService =>
    cloudSyncServiceFactory({
      isEnabled: config.isCloudSyncEnabled,
      ...params,
    }),
)
