import {
  CheckCircle as SelectedIcon,
  PlayCircle as PlayCircleIcon,
  TripOrigin as UnselectedIcon,
} from '@mui/icons-material'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import type {Theme} from '@mui/material'
import {Box, Typography, lighten, alpha} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import type BigNumber from 'bignumber.js'
import clsx from 'clsx'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import nftPlaceholder from '../../assets/icons/nftPlaceholder.svg'
import type {NftBlockchain} from '../../blockchainTypes'
import {LabeledIcon, ElevatedCard, ShadowCopy} from '../../components'
import {ImageWithLoading} from '../../components/visual/atoms/ImageWithLoading'
import theme from '../../theme/theme'
import type {AccountId, Nft, TokenId} from '../../types'
import {useGetBalancesPerAccount, useImageMediaType} from '../../wallet'
import {parseNft} from '../../wallet/utils/nfts'

import {HiddenNftImage} from './hiddenNfts'
import {formatNftAmount} from './utils'

type SelectionProps = {
  isSelected?: boolean
  onSelect?: (e: React.SyntheticEvent) => unknown
  isSelecting?: boolean
  initializeSelection: () => void
  isNftSelectionIconVisible?: boolean
}

type NftCardWrapperProps = SelectionProps & {
  Image: React.ReactNode
  Heading: React.ReactNode
  onClick: (e: React.SyntheticEvent) => unknown
  amount?: BigNumber
  mediaType?: string | null
  className?: string
}

function NftCardWrapper({
  Image,
  Heading,
  onClick,
  className,
  onSelect,
  isSelected,
  isSelecting,
  amount,
  mediaType,
  initializeSelection,
  isNftSelectionIconVisible,
}: NftCardWrapperProps) {
  const classes = useStyles()

  return (
    <CheckboxLayover
      isSelected={isSelected}
      onSelect={onSelect}
      isSelecting={isSelecting}
      initializeSelection={initializeSelection}
      isNftSelectionIconVisible={isNftSelectionIconVisible}
    >
      <ElevatedCard
        type="normal"
        className={clsx(classes.nftCard, className)}
        onClick={onClick}
      >
        <Box position="relative">
          {amount && (
            <Box
              display="flex"
              justifyContent="center"
              position="absolute"
              left={0}
              top={0}
              m={1}
              p={0.5}
              sx={{
                backgroundColor: theme.palette.common.white,
                borderRadius: 2,
                minWidth: 28,
                pointerEvents: 'none', // prevents breaking nft selection if this element is mouse-overed
              }}
            >
              <Typography
                variant="body2"
                color={theme.palette.common.black}
                maxWidth={THUMBNAIL_SIZE - 16}
              >
                {formatNftAmount(amount.toNumber())}
              </Typography>
            </Box>
          )}
          {mediaType === 'video' && (
            <Box
              display="flex"
              position="absolute"
              right={0}
              top={0}
              m={1}
              sx={{pointerEvents: 'none'}} // prevents breaking nft selection if this element is mouse-overed
            >
              <PlayCircleIcon stroke={'black'} stroke-width={1} />
            </Box>
          )}
        </Box>
        <Box className={classes.nftThumbnail}>{Image}</Box>
        <Box className={classes.headingBox}>{Heading}</Box>
      </ElevatedCard>
    </CheckboxLayover>
  )
}

export function NftShowMoreCard({onClick}: {onClick: () => unknown}) {
  const classes = useStyles()
  const {t} = useTranslation()
  return (
    <ElevatedCard
      type="normal"
      className={clsx(classes.showMoreCard)}
      onClick={onClick}
    >
      <Box
        className={clsx(classes.nftThumbnail, classes.showMoreContentWrapper)}
      >
        <LabeledIcon
          className={classes.showMoreContent}
          Icon={<ArrowRightAltIcon />}
          Label={t('SHOW ALL')}
        />
      </Box>
    </ElevatedCard>
  )
}

type NftCardProps = {
  blockchain: NftBlockchain
  accountId: AccountId
  nft: Nft
  onClick: () => unknown
  onSelect: (tokenId: TokenId) => unknown
  isSelecting: boolean
  isSelected: boolean
  initializeSelection: () => void
}

type NftCardContentProps = Omit<NftCardProps, 'blockchain'> & {
  amount?: BigNumber
  isNftSelectionIconVisible?: boolean
}

const NftCardContent = ({
  nft,
  onClick,
  onSelect,
  isSelected,
  isSelecting,
  amount,
  initializeSelection,
  isNftSelectionIconVisible,
}: NftCardContentProps) => {
  const classes = useStyles()
  const {name, imageUri, id, collectionName} = parseNft(nft)

  const onCheckNft = () => onSelect(id)

  const {data: mediaType} = useImageMediaType(imageUri)

  return (
    <NftCardWrapper
      Image={
        nft.isHidden ? (
          <HiddenNftImage />
        ) : (
          <ImageWithLoading
            url={imageUri}
            placeholder={nftPlaceholder}
            sx={{
              borderTopLeftRadius: BORDER_RADIUS,
              borderTopRightRadius: BORDER_RADIUS,
              backgroundColor: ({palette}) => palette.common.black,
            }}
          />
        )
      }
      Heading={
        <>
          <Typography
            variant="body2"
            title={name}
            className={classes.collectionName}
          >
            {collectionName}
          </Typography>
          <Typography
            variant="subtitle1"
            title={name}
            className={classes.headingText}
          >
            {name}
          </Typography>
        </>
      }
      className={classes.nftCardHoverEffect}
      onSelect={onCheckNft}
      onClick={onClick}
      isSelected={isSelected}
      isSelecting={isSelecting}
      amount={amount}
      mediaType={mediaType}
      initializeSelection={initializeSelection}
      isNftSelectionIconVisible={isNftSelectionIconVisible}
    />
  )
}

export const NftCard = (props: NftCardProps) => {
  const balancesPerAccountQuery = useGetBalancesPerAccount(
    props.blockchain,
    props.nft.id,
  )
  const balance = balancesPerAccountQuery.data?.find(
    (accBalance) => accBalance.accountId === props.accountId,
  )?.balance

  return balance?.gt(1) ? (
    <ShadowCopy
      copyCount={2}
      content={<NftCardContent {...props} amount={balance} />}
      shadowContent={
        // Otherwise shadow copies would show unselected/selected icons as well, which is wrong
        <NftCardContent {...props} isNftSelectionIconVisible={false} />
      }
    />
  ) : (
    <NftCardContent {...props} />
  )
}

type CheckboxLayoverProps = SelectionProps & {
  children: React.ReactNode
}

const NftSelectionIconLayover = ({
  isSelected,
  onSelect,
  isSelecting,
  initializeSelection,
  isNftMouseOvered,
}: Omit<SelectionProps, 'isNftSelectionIconVisible'> & {
  isNftMouseOvered: boolean
}) => {
  const classes = useCheckboxLayoverStyles({checked: isSelected})

  return (
    <>
      {isSelected ? (
        <SelectedIcon color="primary" className={classes.checkboxLayoverIcon} />
      ) : isSelecting || isNftMouseOvered ? (
        <UnselectedIcon
          className={classes.unselectedLayoverIcon}
          onClick={(e) => {
            isSelecting ? onSelect?.(e) : initializeSelection()
          }}
        />
      ) : null}
      {isSelecting && (
        <label className={classes.checkboxLayoverLabel}>
          <input
            type="checkbox"
            className={classes.checkboxLayoverInput}
            checked={isSelected}
            onChange={onSelect}
          />
        </label>
      )}
    </>
  )
}

function CheckboxLayover({
  isSelected,
  onSelect,
  isSelecting,
  initializeSelection,
  isNftSelectionIconVisible = true,
  children,
}: CheckboxLayoverProps) {
  const [isNftMouseOvered, setIsNftMouseOvered] = useState(false)
  return (
    <Box
      position="relative"
      onMouseEnter={() => setIsNftMouseOvered(true)}
      onMouseLeave={() => setIsNftMouseOvered(false)}
    >
      {isNftSelectionIconVisible ? (
        <NftSelectionIconLayover
          isSelected={isSelected}
          onSelect={onSelect}
          isSelecting={isSelecting}
          initializeSelection={initializeSelection}
          isNftMouseOvered={isNftMouseOvered}
        />
      ) : null}
      {children}
    </Box>
  )
}

export const BORDER_RADIUS = 5
export const THUMBNAIL_SIZE = 200
const LINE_HEIGHT = 1.25
const FONT_SIZE = 1.1
export const LINE_SIZE = LINE_HEIGHT * FONT_SIZE

type CheckboxWrapperStyles = {
  checked?: boolean
}

const useCheckboxLayoverStyles = makeStyles<Theme, CheckboxWrapperStyles>(
  (theme) => ({
    checkboxLayoverInput: {
      appearance: 'none',
      position: 'absolute',
      top: 0,
      left: 0,
    },
    checkboxLayoverLabel: {
      borderRadius: BORDER_RADIUS,
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      background: (props) =>
        props?.checked
          ? 'rgba(255,255,255, 0.25)'
          : alpha(theme.palette.background.paper, 0),
      cursor: 'pointer',
    },
    checkboxLayoverIcon: {
      display: 'block',
      position: 'absolute',
      backgroundColor: theme.palette.background.default,
      right: '0',
      top: '0',
      transform: 'translate(25%,-25%)',
      zIndex: 2,
      pointerEvents: 'none',
      borderRadius: '50%',
      fontSize: 28,
    },
    unselectedLayoverIcon: {
      display: 'block',
      position: 'absolute',
      backgroundColor: `${alpha(theme.palette.background.default, 0.4)}`,
      right: '0',
      top: '0',
      transform: 'translate(25%,-25%)',
      zIndex: 2,
      pointerEvents: 'auto',
      cursor: 'pointer',
      '&:hover': {
        color: `${alpha(theme.palette.primary.main, 1)}`,
      },
      borderRadius: '50%',
      fontSize: 28,
    },
  }),
)

const useStyles = makeStyles((theme) => {
  const lightBackground = {
    background: lighten(theme.palette.background.default, 0.04),
  }
  const backgroundGradient = {
    background: `linear-gradient(90deg, ${alpha(
      theme.palette.background.default,
      0.6,
    )} 0%, rgba(255, 255, 255, 0) 100%)`,
  }
  return {
    nftThumbnail: {
      height: THUMBNAIL_SIZE,
      width: THUMBNAIL_SIZE,
      borderTopLeftRadius: BORDER_RADIUS,
      borderTopRightRadius: BORDER_RADIUS,
    },
    nftCard: {
      width: THUMBNAIL_SIZE,
      borderTopLeftRadius: BORDER_RADIUS,
      borderTopRightRadius: BORDER_RADIUS,
      cursor: 'pointer',
      backgroundColor: theme.palette.background.default,
    },
    nftCardHoverEffect: {
      '&:hover': {
        filter: 'brightness(0.8)',
        ...lightBackground,
      },
    },
    headingBox: {
      width: '100%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      height: `${LINE_SIZE * 2 + 1.5}rem`,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: theme.palette.grey[200],
      borderBottomLeftRadius: BORDER_RADIUS,
      borderBottomRightRadius: BORDER_RADIUS,
    },
    headingText: {
      width: '100%',
      whiteSpace: 'nowrap',
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      lineHeight: LINE_HEIGHT,
      fontSize: `${FONT_SIZE}rem`,
      textAlign: 'center',
      color: theme.palette.common.black,
      fontWeight: 600,
    },
    collectionName: {
      width: '100%',
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      textAlign: 'center',
      color: theme.palette.getContrastText(theme.palette.grey[200]),
    },
    showMoreContentWrapper: {
      borderRadius: BORDER_RADIUS,
      ...backgroundGradient,
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignContent: 'center',
    },
    showMoreContent: {
      position: 'relative',
    },
    showMoreCard: {
      color: theme.palette.text.secondary,
      fontWeight: 500,
      height: 268,
      '&:hover': {
        color: theme.palette.primary.main,
        background: theme.palette.background.paper,
      },
      background: theme.palette.background.paper,
    },
  }
})
