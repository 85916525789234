import {
  BarChart as StakeIcon,
  CallReceived as RewardReceived,
  VpnKey as StakeRegistration,
  ArrowDownward as Withdrawal,
} from '@mui/icons-material'
import {Typography} from '@mui/material'
import {useTheme} from '@mui/material/styles'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {
  CardanoTransactionExplorerLink,
  IconCol,
  ValidatorExplorerLink,
  CardanoStakeKeyExplorerLink,
} from '../../../../components'
import type {
  WithAccountName,
  CardanoRewardDistribution,
  CardanoTxHistoryDelegation,
  CardanoTxHistoryStakeDeregistration,
  CardanoTxHistoryStakeRegistration,
  CardanoTxHistoryWithdrawal,
} from '../../../../wallet/cardano'
import {StakeHistoryListItemLayout} from '../../common/history/StakeHistoryLayout'
import {
  FormattedAssetEffect,
  FormattedDateTimeWithEpochNo,
  NoValue,
  StakeHistoryInformationColumn,
  StakeHistoryTitleWithAccount,
} from '../../common/history/StakeHistoryUtils'
import {FormattedStakepoolTicker} from '../formatting'

export function RewardHistoryEntryRow({
  timeIssued,
  epochNo,
  poolId,
  rewards,
  forDelegationInEpoch,
  rewardType,
  accountName,
}: WithAccountName<CardanoRewardDistribution>) {
  const {t} = useTranslation()
  const theme = useTheme()
  return (
    <StakeHistoryListItemLayout
      typeCol={
        <IconCol
          Icon={<RewardReceived />}
          title={
            <StakeHistoryTitleWithAccount
              title={t('Received reward')}
              accountName={accountName}
            />
          }
          description={
            <FormattedDateTimeWithEpochNo
              dateTime={timeIssued}
              epochNo={epochNo}
            />
          }
        />
      }
      amountCol={
        <FormattedAssetEffect
          blockchain={'cardano'}
          color={theme.palette.info.light}
          amount={rewards}
        />
      }
      informationCol={
        <StakeHistoryInformationColumn>
          <Typography noWrap variant="body2" color="textSecondary">
            {rewardType === 'REGULAR' &&
              `${t('For delegation in epoch')}: ${forDelegationInEpoch}`}
            {rewardType === 'ITN' && t('Testnet reward')}
            {rewardType === 'TREASURY' && t('Voting reward')}
          </Typography>
        </StakeHistoryInformationColumn>
      }
      explorerCol={
        <ValidatorExplorerLink
          blockchain={'cardano'}
          validatorId={poolId}
          validatorName={
            <FormattedStakepoolTicker poolId={poolId} verbose={false} />
          }
        />
      }
    />
  )
}

export function DelegationHistoryEntryRow({
  timeIssued,
  epochNo,
  poolId,
  txHash,
  accountName,
}: WithAccountName<CardanoTxHistoryDelegation>) {
  const {t} = useTranslation()
  return (
    <StakeHistoryListItemLayout
      typeCol={
        <IconCol
          Icon={<StakeIcon />}
          title={
            <StakeHistoryTitleWithAccount
              title={t('Delegation')}
              accountName={accountName}
            />
          }
          description={
            <FormattedDateTimeWithEpochNo
              dateTime={timeIssued}
              epochNo={epochNo}
            />
          }
        />
      }
      amountCol={<NoValue />}
      informationCol={
        <StakeHistoryInformationColumn>
          <Typography noWrap variant="body2" color="textSecondary">
            {t('To new pool')}&nbsp;
          </Typography>
          <ValidatorExplorerLink
            blockchain={'cardano'}
            validatorId={poolId}
            validatorName={
              <FormattedStakepoolTicker poolId={poolId} verbose={false} />
            }
          />
        </StakeHistoryInformationColumn>
      }
      explorerCol={<CardanoTransactionExplorerLink txId={txHash} />}
    />
  )
}

export function RegistrationHistoryEntryRow({
  timeIssued,
  epochNo,
  txHash,
  stakeKeyHex,
  accountName,
  depositPaid,
}: WithAccountName<CardanoTxHistoryStakeRegistration>) {
  const {t} = useTranslation()
  return (
    <StakeHistoryListItemLayout
      typeCol={
        <IconCol
          Icon={<StakeRegistration />}
          title={
            <StakeHistoryTitleWithAccount
              title={t('Stake key registration')}
              accountName={accountName}
            />
          }
          description={
            <FormattedDateTimeWithEpochNo
              dateTime={timeIssued}
              epochNo={epochNo}
            />
          }
        />
      }
      amountCol={
        <FormattedAssetEffect blockchain={'cardano'} amount={depositPaid} />
      }
      informationCol={
        <StakeHistoryInformationColumn>
          <Typography variant="body2" color="textSecondary">
            {t('Stake key')}
          </Typography>
          &nbsp;
          <CardanoStakeKeyExplorerLink stakeKey={stakeKeyHex} />
        </StakeHistoryInformationColumn>
      }
      explorerCol={<CardanoTransactionExplorerLink txId={txHash} />}
    />
  )
}

export function DeregistrationHistoryEntryRow({
  timeIssued,
  epochNo,
  txHash,
  stakeKeyHex,
  accountName,
  depositReturned,
}: WithAccountName<CardanoTxHistoryStakeDeregistration>) {
  const {t} = useTranslation()
  return (
    <StakeHistoryListItemLayout
      typeCol={
        <IconCol
          Icon={<StakeRegistration />}
          title={
            <StakeHistoryTitleWithAccount
              title={t('Stake key deregistration')}
              accountName={accountName}
            />
          }
          description={
            <FormattedDateTimeWithEpochNo
              dateTime={timeIssued}
              epochNo={epochNo}
            />
          }
        />
      }
      amountCol={
        <FormattedAssetEffect blockchain={'cardano'} amount={depositReturned} />
      }
      informationCol={
        <StakeHistoryInformationColumn>
          <Typography variant="body2" color="textSecondary">
            {t('Stake key')}
          </Typography>
          &nbsp;
          <CardanoStakeKeyExplorerLink stakeKey={stakeKeyHex} />
        </StakeHistoryInformationColumn>
      }
      explorerCol={<CardanoTransactionExplorerLink txId={txHash} />}
    />
  )
}

export function WithdrawalHistoryEntryRow({
  timeIssued,
  txHash,
  rewards,
  epochNo,
  accountName,
}: WithAccountName<CardanoTxHistoryWithdrawal>) {
  const {t} = useTranslation()
  return (
    <StakeHistoryListItemLayout
      typeCol={
        <IconCol
          Icon={<Withdrawal />}
          title={
            <StakeHistoryTitleWithAccount
              title={t('Reward withdrawal')}
              accountName={accountName}
            />
          }
          description={
            <FormattedDateTimeWithEpochNo
              dateTime={timeIssued}
              epochNo={epochNo}
            />
          }
        />
      }
      amountCol={
        <FormattedAssetEffect blockchain={'cardano'} amount={rewards} />
      }
      informationCol={<NoValue />}
      explorerCol={<CardanoTransactionExplorerLink txId={txHash} />}
    />
  )
}
