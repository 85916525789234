import React from 'react'

import {AddHwAccount} from '../sharedActions/addAccount/AddHwAccount'

import {useCommonAddHwAccountProps} from './AddAccountUtils'

export function AddTrezorAccount() {
  const commonProps = useCommonAddHwAccountProps('trezor')
  return (
    <AddHwAccount hwVendor="trezor" blockchain="cardano" {...commonProps} />
  )
}
