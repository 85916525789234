import {ApiError, ApiErrorCode} from '@nufi/dapp-client-cardano'
import type {ErrorResponse} from '@nufi/dapp-client-core'
import {Sentry} from '@nufi/frontend-common'
import type {Vote, RawSignedVote} from '@nufi/wallet-cardano'
import {
  MAX_COLLATERAL_AMOUNT,
  buildTxBody,
  removeUtxo,
  addressHexToBech32,
  buildSignedTx,
  cborPubKeyHexToPubKeyHex,
  VotingPurpose,
  getRawTxHash,
  parseRawTx,
  PUBLIC_KEY_LENGTH,
} from '@nufi/wallet-cardano'
import BigNumber from 'bignumber.js'
import * as cbor from 'cbor'

import type {
  CreateCardanoCollateralScreenData,
  SigningKind,
  SignScreenData,
  SignScreenExtraOptions,
  SignScreenResponse,
} from 'src/store/dappConnector'

import {dappConnectorsConfig} from '../../../dappConnector/config'
import type {
  AccountOfflineInfo,
  HexString,
  PromiseReturnType,
} from '../../../types'
import {assert} from '../../../utils/assertion'
import {isHexString} from '../../../utils/helpers'
import {isHwVendor} from '../../../wallet'
import {getCollateral, getCardano} from '../../../wallet/cardano'
import type {
  CardanoAccountOfflineInfo,
  CardanoSignedTxBytes,
  Lovelaces,
  CardanoPubKey,
  CardanoSigningResult,
  CardanoDAppTxPlan,
} from '../../../wallet/cardano'

import {ensureCatalystVotingPurpose} from './injectedUtils'
import type {API, Cbor} from './types'
import {
  encodeAddress,
  encodeBalance,
  encodeUtxo,
  fetchAccountInfo,
  encodeWitnesses,
  encodeSignedData,
  ensureValidDelegations,
  ensureValidVotes,
  ensureValidVoteSettings,
} from './utils'

export type DappConnectorState = {
  getState: () => {
    selectedAccount: AccountOfflineInfo | null
  }
}

export interface MethodWalletApi extends API {
  enable: () => Promise<boolean>
  isEnabled: () => Promise<boolean>
}

export const consentRejectedError = new ApiError(
  ApiErrorCode.Refused,
  'Rejected by user',
) as unknown as ErrorResponse

export const notEnabledError = new ApiError(
  ApiErrorCode.Refused,
  'Not enabled.',
) as unknown as ErrorResponse

export type ConfirmSign = <
  Kind extends SigningKind,
  T extends SignScreenResponse<Kind>,
>(
  kind: Kind,
  data: SignScreenData<Kind>,
  onSign: (customSignData?: SignScreenExtraOptions<Kind>) => Promise<T>,
  onFailure: () => ErrorResponse,
) => Promise<T>

export type RequestCreateCardanoCollateral = (
  data: CreateCardanoCollateralScreenData,
) => Promise<boolean>

export const getExistingCollateral = async (
  account: AccountOfflineInfo,
): Promise<PromiseReturnType<API['getCollateral']>> => {
  const accountInfo = await fetchAccountInfo(account.id)
  const collateral = getCollateral(accountInfo.utxos)
  return collateral ? [encodeUtxo(collateral)] : []
}

export const createApi = ({
  connectorState,
  uiHandlers,
}: {
  connectorState: DappConnectorState
  uiHandlers: {
    confirmSign: ConfirmSign
    requestCreateCardanoCollateral: RequestCreateCardanoCollateral
  }
}): API => ({
  async getExtensions() {
    return [
      ...(dappConnectorsConfig.connectors.cardano.isCip62Enabled
        ? [{cip: 62}]
        : []),
      ...(dappConnectorsConfig.connectors.cardano.isCip95Enabled
        ? [{cip: 95}]
        : []),
    ]
  },
  async getNetworkId() {
    return await getCardano().accountManager.getNetworkInfo().networkId
  },
  async getUtxos() {
    // TODO include optional amount/paginate parameters as per the specs
    const {selectedAccount} = connectorState.getState()
    assert(selectedAccount != null)
    const {utxos: allUtxos} = await fetchAccountInfo(selectedAccount.id)

    // exclude collateral so dApps don't accidentally spend it
    // Nami does the same: https://github.com/Berry-Pool/nami-wallet/blob/7e4deb8dc9c8e33fde847b84889d26d0329a72d1/src/api/extension/index.js#L302
    let utxosToReturn = allUtxos
    const collateral = getCollateral(allUtxos)
    if (collateral) {
      utxosToReturn = removeUtxo(utxosToReturn, collateral)
    }

    return utxosToReturn.map((u) => encodeUtxo(u))
  },
  async getBalance() {
    const {selectedAccount} = connectorState.getState()
    assert(selectedAccount != null)
    const {balance, tokensBalance} = await fetchAccountInfo(selectedAccount.id)

    return encodeBalance(balance, tokensBalance)
  },
  async getUsedAddresses() {
    const {selectedAccount} = connectorState.getState()
    assert(selectedAccount != null)
    const {usedAddresses} = await fetchAccountInfo(selectedAccount.id)
    return usedAddresses.map(({address}) => encodeAddress(address))
  },
  getUnusedAddresses() {
    return Promise.resolve([])
  },
  getChangeAddress() {
    const {selectedAccount} = connectorState.getState()
    assert(selectedAccount != null)
    return Promise.resolve(
      encodeAddress((selectedAccount as CardanoAccountOfflineInfo).address),
    )
  },
  getRewardAddresses() {
    const {selectedAccount} = connectorState.getState()
    assert(selectedAccount != null)
    return Promise.resolve([
      encodeAddress(
        (selectedAccount as CardanoAccountOfflineInfo).rewardAddress,
      ),
    ])
  },
  async signTx(
    tx: Cbor<'transaction'>,
    // For now we ignore the partialSign flag and assume it to be true.
    // Seems like most dapps set this flag to true "just in case" anyway,
    // even if not needed, e.g. SundaeSwap
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    partialSign: boolean,
  ) {
    const {selectedAccount: _selectedAccount} = connectorState.getState()
    assert(_selectedAccount != null)
    const selectedAccount = _selectedAccount as CardanoAccountOfflineInfo
    const account = getCardano().accountsStore.getAccount(selectedAccount.id)
    const ownAddress =
      await getCardano().accountManager.addressWithParams(account)
    const ownRewardAddress =
      await getCardano().accountManager.rewardAddressWithParams(account)
    const ownDRepCredential =
      await getCardano().accountManager.dRepCredential(account)
    const ownCommitteeColdCredential =
      await getCardano().accountManager.committeeColdCredential(account)
    const ownCommitteeHotCredential =
      await getCardano().accountManager.committeeHotCredential(account)
    const {utxos} = await fetchAccountInfo(selectedAccount.id)

    let txPlan: CardanoDAppTxPlan
    try {
      txPlan = parseRawTx({
        rawTx: Buffer.from(tx, 'hex'),
        ownUtxos: utxos,
        ownAddresses: [ownAddress],
        ownRewardAddress,
        ownDRepCredential,
        ownCommitteeColdCredential,
        ownCommitteeHotCredential,
        network: getCardano().accountManager.getNetworkInfo().network,
      })
    } catch (e) {
      Sentry.captureException(e)
      throw new ApiError(
        ApiErrorCode.InvalidRequest,
        e instanceof Error && e.message
          ? e.message
          : 'Error parsing transaction',
      )
    }

    const _signTx = async (): Promise<CardanoSigningResult> =>
      await getCardano().accountManager.witnessTx(
        getCardano().accountsStore.getAccount(selectedAccount.id),
        txPlan,
      )

    const {witnesses} = await uiHandlers.confirmSign(
      'sign-tx',
      {
        type: 'cardano',
        data: {txPlan, rawTx: tx},
      },
      _signTx,
      () => consentRejectedError,
    )
    return encodeWitnesses(witnesses)
  },
  async signData(address: string, sigStructure: Cbor<'Sig_structure'>) {
    const {selectedAccount: _selectedAccount} = connectorState.getState()
    assert(_selectedAccount != null)
    const selectedAccount = _selectedAccount as CardanoAccountOfflineInfo

    const ownBaseAddress = await getCardano().accountManager.addressWithParams(
      getCardano().accountsStore.getAccount(selectedAccount.id),
    )
    const ownRewardAddress =
      await getCardano().accountManager.rewardAddressWithParams(
        getCardano().accountsStore.getAccount(selectedAccount.id),
      )

    const addressBech32 = isHexString(address)
      ? addressHexToBech32(address)
      : address
    const signingAddress = (() => {
      if (addressBech32 === ownBaseAddress.address) {
        return ownBaseAddress
      } else if (addressBech32 === ownRewardAddress.address) {
        return ownRewardAddress
      } else {
        throw new ApiError(
          ApiErrorCode.Refused,
          'The address supplied to signData() is unknown',
        )
      }
    })()

    const signMessage = async () =>
      await getCardano().accountManager.signData(
        getCardano().accountsStore.getAccount(selectedAccount.id),
        Buffer.from(sigStructure, 'hex'),
        signingAddress,
      )

    const signedData = await uiHandlers.confirmSign(
      'sign-message',
      {
        type: 'cardano',
        data: {messageHex: sigStructure},
      },
      signMessage,
      () => consentRejectedError,
    )

    return encodeSignedData(signedData)
  },
  async submitTx(tx: Cbor<'transaction'>) {
    const txHash = getRawTxHash(Buffer.from(tx, 'hex'))

    await getCardano().accountManager.sendTransaction({
      bytes: Buffer.from(tx, 'hex') as CardanoSignedTxBytes,
      txHash,
    })

    return txHash
  },
  async getCollateral() {
    const {selectedAccount} = connectorState.getState()
    assert(selectedAccount != null)
    const accountInfo = await fetchAccountInfo(selectedAccount.id)
    const collateral = getCollateral(accountInfo.utxos)

    if (collateral == null) {
      const accountStoredData = getCardano().accountsStore.getAccount(
        selectedAccount.id,
      )
      let txPlan, rawTx
      try {
        txPlan = await getCardano().accountManager.getCreateCollateralTxPlan(
          accountStoredData,
          new BigNumber(MAX_COLLATERAL_AMOUNT) as Lovelaces,
          accountInfo,
        )
        rawTx = buildTxBody(txPlan).bytes.toString('hex')
        await uiHandlers.requestCreateCardanoCollateral({txPlan, rawTx})
      } catch (e) {
        // this may fail e.g. if wallet doesn't have enough funds.
        // In such case, there is no point in requesting the user
        // to create a collateral and we just silently return
      }

      // We intentionally don't return the new collateral created
      // by requestCreateCardanoCollateral(), because some dApps may request
      // utxos before collaterals, which would result in the subsequent tx being
      // based on stale utxos and therefore invalid. That's why we rather pretend
      // there are no collaterals (yet) and prompt the user to repeat the action
      // they were trying to do again.
      return []
    }

    return collateral ? [encodeUtxo(collateral)] : []
  },

  // CIP-0062
  async signVotes(votes: Vote[], settingsJson: string) {
    const {selectedAccount} = connectorState.getState()
    assert(selectedAccount != null)
    ensureValidVotes(votes)
    ensureValidVoteSettings(settingsJson)

    const _signVotes = async (): Promise<RawSignedVote[]> =>
      await getCardano().accountManager.signCatalystVotes(
        getCardano().accountsStore.getAccount(selectedAccount.id),
        votes,
        settingsJson,
      )

    const result = await uiHandlers.confirmSign(
      'sign-vote',
      {
        type: 'cardano',
        data: {
          votes,
        },
      },
      _signVotes,
      () => consentRejectedError,
    )

    return result.map((r) => r.toString('hex') as HexString)
  },
  async getVotingCredentials() {
    const selectedAccount = connectorState.getState()
      .selectedAccount as CardanoAccountOfflineInfo
    assert(selectedAccount != null)
    const isHwWallet = isHwVendor(selectedAccount.cryptoProviderType)

    const _getVotingCredentials = async () => ({
      votingKey: (
        await getCardano().accountManager.getCatalystVotingPublicKey(
          selectedAccount,
        )
      ).toString('hex') as HexString,
      stakingCredential: cbor
        .decode(selectedAccount.stakingKeyCborHex)
        .toString('hex') as HexString,
    })

    if (!isHwWallet) {
      return await _getVotingCredentials()
    }
    if (selectedAccount.cryptoProviderType !== 'ledger') {
      throw new ApiError(
        ApiErrorCode.Refused,
        `getVotingCredentials() not supported by ${selectedAccount.cryptoProviderType}`,
      )
    }

    return await uiHandlers.confirmSign(
      'get-hw-wallet-public-keys',
      {
        type: 'cardano',
        data: undefined,
      },
      _getVotingCredentials,
      () => consentRejectedError,
    )
  },
  async submitDelegation({delegations, purpose}) {
    ensureValidDelegations(delegations)
    ensureCatalystVotingPurpose([purpose])

    const parsePubKey = (hex: string): CardanoPubKey => {
      const buf = Buffer.from(hex, 'hex')
      assert(buf.length === PUBLIC_KEY_LENGTH)
      return buf as CardanoPubKey
    }

    const {selectedAccount: _selectedAccount} = connectorState.getState()
    assert(_selectedAccount != null)
    const selectedAccount = _selectedAccount as CardanoAccountOfflineInfo
    const accountInfo = await fetchAccountInfo(selectedAccount.id)

    const txPlan =
      await getCardano().accountManager.getVotingRegistrationTxPlan(
        selectedAccount,
        accountInfo,
        {
          delegations: delegations.map((d) => ({
            ...d,
            votingKey: parsePubKey(d.votingKey),
          })),
          purpose: VotingPurpose.CATALYST,
        },
      )
    assert(txPlan.auxiliaryData?.type === 'votingRegistration')

    const _signTx = async (): Promise<CardanoSigningResult> =>
      await getCardano().accountManager.witnessTx(
        getCardano().accountsStore.getAccount(selectedAccount.id),
        txPlan,
      )

    const {witnesses, votingRegistrationAuxiliaryData} =
      await uiHandlers.confirmSign(
        'sign-tx',
        {
          type: 'cardano',
          data: {
            txPlan,
          },
        },
        _signTx,
        () => consentRejectedError,
      )
    assert(votingRegistrationAuxiliaryData != null)

    const txBodySigned = buildTxBody({
      ...txPlan,
      auxiliaryData: {
        type: 'arbitraryHash',
        hash: votingRegistrationAuxiliaryData.auxiliaryDataHash,
      },
    })
    const signedTx = buildSignedTx(
      txBodySigned,
      witnesses,
      votingRegistrationAuxiliaryData.cborEncodedData,
    )

    await getCardano().accountManager.sendTransaction(signedTx)

    const {stakingKey, votingRewardsDestination, nonce} =
      txPlan.auxiliaryData.delegation
    return {
      certificate: {
        delegations,
        stakingPub: stakingKey.toString('hex'),
        paymentAddress: votingRewardsDestination.address,
        nonce,
        purpose,
      },
      signature: votingRegistrationAuxiliaryData.signature.toString('hex'),
      txHash: signedTx.txHash,
    }
  },

  // CIP-0095
  async getPubDRepKey() {
    const selectedAccount = connectorState.getState()
      .selectedAccount as CardanoAccountOfflineInfo
    assert(selectedAccount != null)

    return cborPubKeyHexToPubKeyHex(
      selectedAccount.dRepKeyCborHex,
    ) as string as HexString
  },
  async getRegisteredPubStakeKeys() {
    const selectedAccount = connectorState.getState()
      .selectedAccount as CardanoAccountOfflineInfo
    assert(selectedAccount != null)

    const stakeAccountDetails =
      await getCardano().accountManager.getStakeAccountDetails(selectedAccount)

    if (!stakeAccountDetails.isStakingKeyRegistered) {
      return []
    }

    return [
      cborPubKeyHexToPubKeyHex(
        stakeAccountDetails.stakingKeyCborHex,
      ) as string as HexString,
    ]
  },
  async getUnregisteredPubStakeKeys() {
    const selectedAccount = connectorState.getState()
      .selectedAccount as CardanoAccountOfflineInfo
    assert(selectedAccount != null)
    const stakeAccountDetails =
      await getCardano().accountManager.getStakeAccountDetails(selectedAccount)

    if (stakeAccountDetails.isStakingKeyRegistered) {
      return []
    }

    return [
      cborPubKeyHexToPubKeyHex(
        stakeAccountDetails.stakingKeyCborHex,
      ) as string as HexString,
    ]
  },
})
