import {assert} from '@nufi/frontend-common'
import {objectEntries, objectKeys} from 'common/src/typeUtils'
import _ from 'lodash'

import type {ProfileLoginType, ProfileManager} from 'src/appStorage'
import {isLocalProfileManager} from 'src/appStorage/profileManager/localProfileManager'
import {isRemoteProfileManager} from 'src/appStorage/profileManager/remoteProfileManager'
import {
  isAccountForSomeWalletKind,
  isAccountForWalletKind,
} from 'src/wallet/public/utils'
import type {InitSecretProvider} from 'src/wallet/secretProvider'
import type {Blockchain, ProfileAccount} from 'src/wallet/types'
import type {AutoDiscoveryType} from 'src/wallet/utils/walletUtils'
import {blockchainToWalletKind} from 'src/wallet/walletKind'
import type {WalletKind} from 'src/wallet/walletKind'

type WalletInitializationFailures = Array<Blockchain | WalletKind>

export type AccountsStorageServiceInitializeArgs = {
  loginType: ProfileLoginType
  accountLoad:
    | {
        type: 'allWalletKinds'
      }
    | {type: 'selectedWalletKinds'; walletKinds: readonly WalletKind[]}
  // Note that not all of these are used. This was created flexible to allow
  // changing the logic from outside without updating the inner logic
  // or interface of `AccountStorageService`.
  accountDiscovery:
    | {type: 'skip'}
    | {type: 'usedOnly'}
    | {type: 'usedOrFallback'}
    | {
        type: 'perWalletKind'
        // Not using array per category, as it would potentially allow for inconsistencies
        // when some blockchain is present in more than one array
        walletKinds: Partial<Record<WalletKind, AutoDiscoveryType>>
      }
}

type Managers<T> = {
  managersToInitialize: WalletManager<T>[]
  managersToSkip: WalletManager<T>[]
  allManagers: WalletManager<T>[]
}

const isWalletKindEnabled = (
  profileEnabledBlockchains: readonly Blockchain[],
  walletKind: WalletKind,
  isWalletKindAvailable: (walletKind: WalletKind) => boolean,
) => {
  const profileEnabledWalletKinds = profileEnabledBlockchains.map((b) =>
    blockchainToWalletKind(b),
  )
  return (
    profileEnabledWalletKinds.includes(walletKind) &&
    isWalletKindAvailable(walletKind)
  )
}

const getManagers = <T>(
  walletManagers: WalletManager<T>[],
  accountLoad: AccountsStorageServiceInitializeArgs['accountLoad'],
  profileEnabledBlockchains: readonly Blockchain[],
  isWalletKindAvailable: (walletKind: WalletKind) => boolean,
): Managers<T> => {
  const [managersToInitialize, managersToSkip] = _.partition(
    walletManagers,
    ({wallet}) =>
      (accountLoad.type === 'allWalletKinds' ||
        accountLoad.walletKinds.includes(wallet.walletKind)) &&
      isWalletKindEnabled(
        profileEnabledBlockchains,
        wallet.walletKind,
        isWalletKindAvailable,
      ),
  )
  return {managersToInitialize, managersToSkip, allManagers: walletManagers}
}

export type InitializationStore = {
  isInitialized: () => boolean
  init: (failures: WalletInitializationFailures) => void
}

export type AccountsStore<T> = {
  setAccounts: (accounts: T[]) => void
  getAllAccounts: () => T[]
}

type WalletBase<T> = {
  profileAccountsToAccounts: (accounts: ProfileAccount[]) => T[]
  accountsToProfileAccounts: (accounts: T[]) => ProfileAccount[]
  init: (args: {
    onError: (blockchain: Blockchain | WalletKind) => void
    accounts: ProfileAccount[]
    autoDiscoveryType: AutoDiscoveryType
    enabledBlockchains: readonly Blockchain[]
  }) => Promise<T[]>
}

type EvmWallet<T> = WalletBase<T> & {
  walletKind: 'evm'
}

type StandardWallet<T> = WalletBase<T> & {
  walletKind: Exclude<WalletKind, 'evm'>
}

type WalletManagerBase<T> = {
  accountsStore: AccountsStore<T>
}

type EvmWalletManager<T> = WalletManagerBase<T> & {
  wallet: EvmWallet<T>
}

type StandardWalletManager<T> = WalletManagerBase<T> & {
  wallet: StandardWallet<T>
}

export type WalletManager<T> = EvmWalletManager<T> | StandardWalletManager<T>

export type InitTokenStorageProvider = (
  profileManager: ProfileManager,
) => Promise<void>

/**
 * Handles:
 *  * load accounts from permanent storage, account discovery, save accounts
 *    back to permanent storage.
 *  * detection of errors during wallet initialization
 *  * save in-memory state into permanent storage
 *  * reload in-memory accounts
 */
export class AccountsStorageService<T> {
  private managers: Managers<T> | null = null

  constructor(
    private getAndInitAllWalletManagers: (
      loginType: ProfileLoginType,
    ) => WalletManager<T>[],
    private profileManager: ProfileManager,
    private initializationStore: InitializationStore,
    private initSecretProvider: InitSecretProvider,
    private isWalletKindAvailable: (walletKind: WalletKind) => boolean,
  ) {}

  private isInitialized = (): boolean =>
    this.initializationStore.isInitialized()

  private allBlockchainsAccountsToProfileAccounts = (): ProfileAccount[] => {
    assert(this.isInitialized(), 'AccountsStorageService not initialized yet')
    const {allManagers} = this.getManagers()

    return _.flatten(
      allManagers.map(({wallet, accountsStore}) =>
        wallet.accountsToProfileAccounts(accountsStore.getAllAccounts()),
      ),
    )
  }

  private setManagers = async (
    allWalletManagers: WalletManager<T>[],
    accountLoad: AccountsStorageServiceInitializeArgs['accountLoad'],
    profileEnabledBlockchains: readonly Blockchain[],
  ): Promise<void> => {
    this.managers = getManagers<T>(
      allWalletManagers,
      accountLoad,
      profileEnabledBlockchains,
      this.isWalletKindAvailable,
    )
  }

  private getManagers = (): Managers<T> => {
    if (this.managers == null) {
      throw new Error('Managers must be initialized before accessing them')
    }
    return this.managers
  }

  private getWalletManager = (walletKind: WalletKind): WalletManager<T> => {
    const {allManagers} = this.getManagers()

    const walletManager = allManagers.find(
      (m) => m.wallet.walletKind === walletKind,
    )
    assert(walletManager != null)
    return walletManager
  }

  private getUpdatedInMemoryAccounts = (
    storedAccounts: ProfileAccount[],
    blockchains?: Blockchain[],
  ) => {
    const {allManagers} = this.getManagers()

    const walletKinds = blockchains?.map(blockchainToWalletKind)

    allManagers
      .filter(
        (m) => walletKinds == null || walletKinds.includes(m.wallet.walletKind),
      )
      .forEach(({accountsStore, wallet}) => {
        const _accounts = wallet.profileAccountsToAccounts(
          storedAccounts.filter((a) =>
            isAccountForWalletKind(a, wallet.walletKind),
          ),
        )

        accountsStore.setAccounts(_accounts)
      })
  }

  reloadInMemoryAccounts = async (blockchains?: Blockchain[]) => {
    const {accounts} = await this.profileManager.getProfileData()
    this.getUpdatedInMemoryAccounts(accounts, blockchains)
  }

  setInMemoryAccounts = (
    accounts: ProfileAccount[],
    blockchains?: Blockchain[],
  ) => {
    this.getUpdatedInMemoryAccounts(accounts, blockchains)
  }

  initialize = async (
    args: AccountsStorageServiceInitializeArgs,
  ): Promise<WalletManager<T>[]> => {
    // FIXME
    // Initialization of secretProvider should be considered a standalone responsibility
    // and should not mix with the AccountsStorageService.
    // Decouple this when updating this class.
    if (isRemoteProfileManager(this.profileManager)) {
      const identitySecret = this.profileManager.getIdentitySecret()
      await this.initSecretProvider({
        mnemonicStorageType: 'remote',
        identitySecret,
      })
    } else if (isLocalProfileManager(this.profileManager)) {
      const mnemonic = await this.profileManager.getMnemonic()
      await this.initSecretProvider({mnemonicStorageType: 'local', mnemonic})
    } else {
      throw new Error('Unsupported profile manager type')
    }

    const failedWalletInitializations: WalletInitializationFailures = []

    const onAccountInitFailure = (blockchain: Blockchain | WalletKind) => {
      failedWalletInitializations.push(blockchain)
    }

    const {accounts, settings} = await this.profileManager.getProfileData()
    const {accountDiscovery, accountLoad} = args

    const allWalletManagers = this.getAndInitAllWalletManagers(args.loginType)
    await this.setManagers(
      allWalletManagers,
      accountLoad,
      settings.enabledBlockchains,
    )
    const {managersToInitialize, managersToSkip} = this.getManagers()

    const getAutoDiscoveryType = (
      walletKind: WalletKind,
    ): AutoDiscoveryType => {
      if (accountDiscovery.type === 'perWalletKind') {
        // if blockchain was not specified it is only reasonable to skip it
        return accountDiscovery.walletKinds[walletKind] || 'skip'
      }
      return accountDiscovery.type
    }

    await Promise.all(
      managersToInitialize.map(async ({wallet, accountsStore}) => {
        const accountsStoredData = await wallet.init({
          accounts: accounts.filter((account) =>
            isAccountForWalletKind(account, wallet.walletKind),
          ),
          onError: onAccountInitFailure,
          autoDiscoveryType: getAutoDiscoveryType(wallet.walletKind),
          enabledBlockchains: settings.enabledBlockchains,
        })
        accountsStore.setAccounts(accountsStoredData)
      }),
    )

    // Don't initialize these managers but still fill in their accounts so we
    // don't have to handle having different accounts in state and in storage.
    managersToSkip.forEach(({wallet, accountsStore}) => {
      accountsStore.setAccounts(
        wallet.profileAccountsToAccounts(
          accounts.filter((a) => isAccountForWalletKind(a, wallet.walletKind)),
        ),
      )
    })

    this.initializationStore.init(_.uniq(failedWalletInitializations))

    // store state accounts into storage (there might be new accounts from auto-discovery)
    const allBlockchainInMemoryAccounts =
      this.allBlockchainsAccountsToProfileAccounts()
    await this.profileManager.saveProfileAccounts(allBlockchainInMemoryAccounts)

    return managersToInitialize
  }

  // Return updated accounts without saving them. This is useful for reusing the
  // update logic while being able to save the accounts atomically along with
  // other profile data e.g. settings.
  getUpdatedAccountsForWalletKinds = (
    accountsPerWalletKind: Partial<Record<WalletKind, T[]>>,
  ): ProfileAccount[] => {
    assert(this.isInitialized(), 'AccountsStorageService not initialized yet')
    const _allInMemoryAccounts = this.allBlockchainsAccountsToProfileAccounts()

    const updatedAccounts = objectEntries(accountsPerWalletKind).reduce(
      (acc, [walletKind, accounts]) => {
        assert(accounts != null)
        const {wallet} = this.getWalletManager(walletKind)
        return [...acc, ...wallet.accountsToProfileAccounts(accounts)]
      },
      [] as ProfileAccount[],
    )

    const updatedWalletKinds = objectKeys(accountsPerWalletKind)
    const allAccounts = [
      ..._allInMemoryAccounts.filter(
        (a) => !isAccountForSomeWalletKind(a, updatedWalletKinds),
      ),
      ...updatedAccounts,
    ]

    return allAccounts
  }

  // Update and also save the accounts.
  saveAccountsForWalletKind = async (
    walletKind: WalletKind,
    accounts: T[],
  ): Promise<void> => {
    assert(this.isInitialized(), 'AccountsStorageService not initialized yet')

    const allAccounts = this.getUpdatedAccountsForWalletKinds({
      [walletKind]: accounts,
    } as Record<WalletKind, T[]>)

    await this.profileManager.saveProfileAccounts(allAccounts)

    this.getWalletManager(walletKind).accountsStore.setAccounts(accounts)
  }
}
