import {Chip, Grid, Typography} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import _ from 'lodash'
import React, {useState} from 'react'

import Paper from '../atoms/Paper'

type CroppedPart = {word: string; index: number}

type MnemonicConfirmationFieldProps = {
  value: Array<string | null>
  onChange: (value: Array<string | null>) => unknown
  croppedOutParts: Array<CroppedPart>
  hasError?: boolean
}

export default function MnemonicConfirmationField({
  value,
  onChange,
  croppedOutParts,
  hasError,
}: MnemonicConfirmationFieldProps) {
  const [croppedConf, setCroppedConf] = useState<{
    placedIndexesMap: {[key in number]: number}
    availableIndexes: Array<number>
  }>({
    placedIndexesMap: {},
    availableIndexes: croppedOutParts.map(({index}) => index),
  })

  const classes = useStyles()

  const onChooseItem = (croppedPart: CroppedPart) => {
    const firstNullableIndex = value.findIndex((v) => v == null)
    onChange(
      value.map((w, index) =>
        index === firstNullableIndex ? croppedPart.word : w,
      ),
    )
    setCroppedConf({
      placedIndexesMap: {
        ...croppedConf.placedIndexesMap,
        [firstNullableIndex]: croppedPart.index,
      },
      availableIndexes: croppedConf.availableIndexes.filter(
        (index) => index !== croppedPart.index,
      ),
    })
  }

  const onRemoveItem = (index: number) => {
    const croppedPartIndex = croppedConf.placedIndexesMap[index]
    onChange(value.map((w, i) => (i === index ? null : w)))
    setCroppedConf({
      placedIndexesMap: _.omit(croppedConf.placedIndexesMap, index),
      availableIndexes: [...croppedConf.availableIndexes, croppedPartIndex!],
    })
  }

  const wordsPerLine = 3
  const wordsPalletteRows = _.chunk(value, wordsPerLine)

  return (
    <>
      <Paper
        className={classes.wordsPalette}
        data-test-id="mnemonic-card-confirm"
        data-cropped-parts={JSON.stringify(croppedOutParts)}
      >
        {wordsPalletteRows.map((row, rowIndex) => (
          <Grid container key={rowIndex}>
            {row.map((word, colIndex) => (
              <Grid item xs={4} className={classes.wordWrapper} key={colIndex}>
                <Typography
                  className={classes.wordIndex}
                  variant="body2"
                  color="textSecondary"
                >
                  {rowIndex * wordsPerLine + (colIndex + 1)}
                </Typography>
                {(() => {
                  const wordIndex = rowIndex * wordsPerLine + colIndex
                  if (word == null) {
                    return (
                      <Typography
                        className={`${classes.wordMissingUnfilled} ${
                          hasError ? classes.error : ''
                        }`}
                      />
                    )
                  } else if (croppedConf.placedIndexesMap[wordIndex] != null) {
                    return (
                      <Chip
                        className={classes.wordMissingFilled}
                        label={word}
                        onDelete={() => onRemoveItem(wordIndex)}
                        size="small"
                        color="default"
                      />
                    )
                  } else {
                    return (
                      <Typography className={classes.word} variant="body2">
                        {word}
                      </Typography>
                    )
                  }
                })()}
              </Grid>
            ))}
          </Grid>
        ))}
      </Paper>
      <Grid className={classes.wordsSelection} container spacing={2}>
        {croppedConf.availableIndexes.map((index) => {
          const croppedPart = croppedOutParts.find(
            ({index: partIndex}) => partIndex === index,
          ) as CroppedPart
          return (
            <Grid key={index} item>
              <Chip
                label={croppedPart.word}
                // Using data property here, as accessing via "text" in fields was flaky
                data-cropped-word={croppedPart.word}
                onClick={() => onChooseItem(croppedPart)}
              />
            </Grid>
          )
        })}
      </Grid>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  wordWrapper: {
    display: 'flex',
    padding: theme.spacing(1.5, 2),
    height: 50,
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0.5, 1),
    },
  },
  word: {
    marginLeft: theme.spacing(3),
  },
  wordMissingUnfilled: {
    marginLeft: theme.spacing(3),
    borderBottomWidth: '1.5',
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.divider,
    flex: 1,
  },
  wordMissingFilled: {
    marginLeft: theme.spacing(3),
  },
  wordIndex: {
    width: 24,
  },
  wordsPalette: {
    padding: theme.spacing(1.5),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0.5),
    },
  },
  wordsSelection: {
    marginTop: theme.spacing(2),
    // specified to avoid jumps in UI when selection disappears
    height: 50,
  },
  error: {
    borderBottomColor: theme.palette.error.main,
  },
}))
