import assert from 'assert'

import {
  FileCopy as ContentCopy,
  Launch as ExternalIcon,
} from '@mui/icons-material'
import type {
  SelectChangeEvent,
  Breakpoints,
  Breakpoint,
  SelectProps,
} from '@mui/material'
import {
  Typography,
  Grid,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  styled,
  FormHelperText,
  IconButton,
} from '@mui/material'
import type BigNumber from 'bignumber.js'
import React from 'react'
import {useTranslation} from 'react-i18next'

import type {Blockchain, AccountId, TokenMetadata} from '../../types'
import {useEllipsisStyles} from '../../utils/layoutUtils'
import {isHotVendor} from '../../wallet'
import type {CryptoProviderType} from '../../wallet'
import {AssetIcon} from '../AssetIcon'
import {CopyToClipboard} from '../CopyToClipboard'
import {
  CustomExplorerButton,
  getSafeAddressExplorerLink,
} from '../explorerLinks'
import type {NumberFormat} from '../formatting'
import {FormattedAsset} from '../formatting'
import {BlockchainIcon, CryptoProviderIcon} from '../visual/atoms/Icon'
import {ellipsizeString} from '../visual/atoms/TextUtils'

export const findItemByAccountId = (
  accountId: AccountId,
  items: AccountSelectItem[],
): AccountSelectItem => {
  const found = items.find((i) => i.accountId === accountId)
  assert(found != null)
  return found
}
const iconSize = 24 // px
export function AccountSelectItemContent({
  blockchain,
  tokenMetadata,
  name,
  cryptoProviderType,
  extra,
  hideAmount,
  address,
  showDescription = true,
  nameToAmountRatio,
  size,
  amountFormat,
  showBlockchainIcon,
  ...rest
}: AccountSelectItemContentProps) {
  const classes = useEllipsisStyles()

  // by default we want to prevent ellipsizing of account's amount
  // for some fields it makes sense to make name wider than amount
  // f.e. NFTs screens showing amounts without decimals
  const [nameGridSize, amountGridSize] = {
    '2:1': [8, 4],
    '1:1': [6, 6],
  }[nameToAmountRatio || '1:1']

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      wrap="nowrap"
      gap={2}
    >
      <Grid item xs={nameGridSize}>
        <Grid container alignItems="center" wrap="nowrap">
          {showBlockchainIcon ? (
            <BlockchainIcon exactSize={iconSize} blockchain={blockchain} />
          ) : (
            <AssetIcon
              exactSize={iconSize}
              blockchain={blockchain}
              tokenMetadata={tokenMetadata}
              showBlockchainBadge
            />
          )}

          <AccountNameWrapper
            maxWidth={`calc(100% - ${
              isHotVendor(cryptoProviderType) ? iconSize : 2 * iconSize
            }px)`}
          >
            {showDescription ? (
              <>
                <AccountNameText
                  small={size === 'small'}
                  withDescription
                  className={classes.ellipsis}
                  title={typeof name === 'string' ? name : undefined}
                >
                  {name}
                </AccountNameText>
                <AccountDescriptionText
                  small={size === 'small'}
                  className={classes.ellipsis}
                >
                  <span title={address}>
                    {ellipsizeString(
                      address,
                      size === 'small' ? 5 : 10,
                      size === 'small' ? 3 : 5,
                    )}
                  </span>
                </AccountDescriptionText>
              </>
            ) : (
              <AccountNameText
                small={size === 'small'}
                className={classes.ellipsis}
                title={typeof name === 'string' ? name : undefined}
              >
                {name}
              </AccountNameText>
            )}
          </AccountNameWrapper>
          <CryptoProviderIcon
            cryptoProviderType={cryptoProviderType}
            exactSize={iconSize}
          />
        </Grid>
      </Grid>
      {!hideAmount && (
        <Grid
          item
          xs={amountGridSize}
          justifyContent="flex-end"
          textAlign="right"
        >
          {'customBalanceRows' in rest ? (
            <>
              <AccountNameText
                small={size === 'small'}
                withDescription={!!rest.customBalanceRows.secondary}
              >
                <FormattedAsset
                  amount={rest.customBalanceRows.primary.balance}
                  tokenMetadata={rest.customBalanceRows.primary.tokenMetadata}
                  blockchain={blockchain}
                  hideNonRegistered
                  isSensitiveInformation
                  amountFormat={amountFormat}
                />
              </AccountNameText>
              {rest.customBalanceRows.secondary && (
                <AccountDescriptionText small={size === 'small'}>
                  <FormattedAsset
                    amount={rest.customBalanceRows.secondary?.balance}
                    tokenMetadata={
                      rest.customBalanceRows.secondary?.tokenMetadata
                    }
                    blockchain={blockchain}
                    hideNonRegistered
                    isSensitiveInformation
                    amountFormat={amountFormat}
                  />
                </AccountDescriptionText>
              )}
            </>
          ) : (
            <AmountText noWrap small={size === 'small'}>
              <FormattedAsset
                amount={rest.balance}
                blockchain={blockchain}
                tokenMetadata={tokenMetadata}
                hideNonRegistered
                isSensitiveInformation
                amountFormat={amountFormat}
              />
            </AmountText>
          )}
        </Grid>
      )}
      {extra}
    </Grid>
  )
}

type AccountSelectItemContentProps = CommonProps & AccountSelectItem

type CommonProps = {
  blockchain: Blockchain
  hideAmount?: boolean
  showDescription?: boolean
  nameToAmountRatio?: '1:1' | '2:1'
  size?: SelectProps['size']
  amountFormat?: NumberFormat
} & (
  | {tokenMetadata: TokenMetadata | undefined; showBlockchainIcon?: false}
  | {tokenMetadata?: undefined; showBlockchainIcon: true}
)

type CustomBalanceRowProps = {
  balance: BigNumber
  tokenMetadata?: TokenMetadata
}

export type AccountSelectItem = {
  accountId: AccountId
  address: string
  name: React.ReactNode
  cryptoProviderType: CryptoProviderType
  extra?: React.ReactNode
} & (
  | {balance: BigNumber}
  | {
      customBalanceRows: {
        primary: CustomBalanceRowProps
        secondary?: CustomBalanceRowProps
      }
    }
)

type ChangeAccountIdEvent = SelectChangeEvent<AccountId>

export type AccountSelectFieldProps = {
  value?: AccountId
  items: Array<AccountSelectItem>
  onChange: (e: ChangeAccountIdEvent) => void
  label?: string
  className?: string
  error?: boolean
  disabled?: boolean
  name?: string
  variant?: 'standard' | 'outlined' | 'filled'
  helperText?: React.ReactNode
  hideCopyAddress?: boolean
  showExplorerButton?: boolean
  MenuProps?: SelectProps['MenuProps']
} & CommonProps

export function AccountSelectField({
  items,
  blockchain,
  onChange,
  value,
  label,
  name,
  error = false,
  className = '',
  disabled = false,
  hideCopyAddress = false,
  hideAmount = false,
  variant = 'outlined',
  showDescription = true,
  showExplorerButton = false,
  nameToAmountRatio,
  helperText,
  size,
  MenuProps,
  amountFormat,
  ...rest
}: AccountSelectFieldProps) {
  const {t} = useTranslation()
  const selectedAccountAddress =
    value && findItemByAccountId(value, items).address

  const addressExplorerLink: string | undefined = selectedAccountAddress
    ? getSafeAddressExplorerLink(selectedAccountAddress, blockchain)
    : undefined

  return (
    <>
      <FormControl
        variant={variant}
        className={className}
        fullWidth
        error={error}
      >
        <SelectWrapper>
          {label && <InputLabel>{label}</InputLabel>}
          <StyledSelect
            description={showDescription}
            onChange={(e) => {
              onChange(e as ChangeAccountIdEvent)
            }}
            value={value ?? ''}
            {...{label, disabled, name, size, MenuProps, hideCopyAddress}}
            renderValue={(selected) => {
              const item = findItemByAccountId(selected as AccountId, items)
              return (
                <AccountSelectItemContent
                  {...{
                    blockchain,
                    hideAmount,
                    showDescription,
                    ...item,
                    nameToAmountRatio,
                    size,
                    amountFormat,
                    ...rest,
                  }}
                />
              )
            }}
          >
            {items.map(({accountId, ...props}) => (
              <MenuItem
                key={accountId}
                value={accountId}
                aria-label={props.address}
              >
                <AccountSelectItemContent
                  {...{
                    blockchain,
                    hideAmount,
                    showDescription,
                    accountId,
                    nameToAmountRatio,
                    amountFormat,
                    ...props,
                    ...rest,
                  }}
                />
              </MenuItem>
            ))}
          </StyledSelect>
          {!hideCopyAddress && (
            <AdornmentButton size="small" disabled={!selectedAccountAddress}>
              <CopyToClipboard
                value={selectedAccountAddress || ''}
                copyText={t('Copy address')}
                content={
                  <CopyIconWrapper>
                    <ContentCopy
                      fontSize="small"
                      color={!selectedAccountAddress ? 'disabled' : 'action'}
                    />
                  </CopyIconWrapper>
                }
              />
            </AdornmentButton>
          )}
          {showExplorerButton && (
            <CustomExplorerButton
              disabled={!addressExplorerLink}
              element={
                <AdornmentButton
                  size="small"
                  disabled={!addressExplorerLink}
                  href={addressExplorerLink ?? ''}
                  target="_blank"
                >
                  <ExternalIcon
                    fontSize="small"
                    color={!addressExplorerLink ? 'disabled' : 'action'}
                  />
                </AdornmentButton>
              }
            />
          )}
        </SelectWrapper>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </>
  )
}

const borderColor = 'rgba(255, 255, 255, 0.23)'
const adornmentWidth = 40 // px
const smallScreenAdornmentWidth = adornmentWidth * 0.75

export const outlinedSelectAccountFieldOverrides = (
  breakpoints: Breakpoints,
  // regardless of the resolution of the screen, force specific type of styles
  sizeByKey?: Extract<Breakpoint, 'windowsZoomed' | 'windowsOld'>,
) => {
  const stylesByBreakpointKey = {
    windowsZoomed: {
      padding: '11.875px 14px',
    },
    windowsOld: {
      height: '26px',
      padding: '10px 14px',
    },
  }
  return {
    '.MuiOutlinedInput-input': {
      ...(sizeByKey
        ? stylesByBreakpointKey[sizeByKey]
        : {
            display: 'flex',
            height: '26px',
            padding: '15.5px 14px',
            ...Object.entries(stylesByBreakpointKey).map(
              ([breakpoint, styles]) => ({
                [breakpoints.down(breakpoint as Breakpoint)]: styles,
              }),
            ),
          }),
    },
  }
}

const StyledSelect = styled(Select, {
  shouldForwardProp: (prop) =>
    prop !== 'description' && prop !== 'hideCopyAddress',
})<{
  description: boolean
  hideCopyAddress?: boolean
  showExplorerButton?: boolean
}>(
  ({
    theme: {
      breakpoints,
      shape: {borderRadius},
    },
    description,
    hideCopyAddress,
    showExplorerButton,
    size,
  }) => ({
    width: '100%',
    borderRadius: 0,
    borderTopLeftRadius: borderRadius,
    borderBottomLeftRadius: borderRadius,
    ':last-child': {
      borderTopRightRadius: borderRadius,
      borderBottomRightRadius: borderRadius,
    },
    '.MuiOutlinedInput-notchedOutline': {
      // make sure account field has same color as CopyAddressButton
      borderColor,
    },
    ...(description && {
      ...outlinedSelectAccountFieldOverrides(
        breakpoints,
        size === 'small' ? 'windowsOld' : undefined,
      ),
      '.MuiSelect-standard': {
        padding: '2px 0 7px',
        [breakpoints.up('windowsOld')]: {
          height: '28px!important',
        },
      },
    }),
    maxWidth: `calc(100% - ${(Number(!hideCopyAddress) + Number(!!showExplorerButton)) * adornmentWidth}px)`,
    [breakpoints.down('md')]: {
      maxWidth: `calc(100% - ${(Number(!hideCopyAddress) + Number(!!showExplorerButton)) * smallScreenAdornmentWidth}px)`,
    },
  }),
)

type SmallFieldTextProps = {
  small: boolean
}

const AccountNameText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'withDescription' && prop !== 'small',
})<{withDescription?: boolean} & SmallFieldTextProps>(
  ({theme, withDescription, small}) =>
    withDescription
      ? {
          display: 'block',
          lineHeight: 1,
          paddingBottom: '4px',
          ...(small
            ? {fontSize: theme.typography.caption.fontSize, padding: '2px 0'}
            : {
                fontSize: theme.typography.body2.fontSize,
                [theme.breakpoints.down('windowsOld')]: {
                  fontSize: theme.typography.caption.fontSize,
                  padding: '2px 0',
                },
              }),
        }
      : {
          ...theme.typography.body1,
        },
)

const AccountDescriptionText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'small',
})<SmallFieldTextProps>(({theme, small}) => ({
  display: 'block',
  lineHeight: 1,
  color: theme.palette.text.secondary,
  ...(small
    ? {fontSize: '0.65rem'}
    : {
        fontSize: theme.typography.caption.fontSize,
        [theme.breakpoints.down('windowsOld')]: {
          fontSize: '0.65rem',
        },
      }),
}))

const AmountText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'small',
})<SmallFieldTextProps>(({theme, small}) => ({
  textAlign: 'right',
  ...(small
    ? theme.typography.body2
    : {
        ...theme.typography.body1,
        [theme.breakpoints.down('windowsOld')]: {
          ...theme.typography.body2,
        },
      }),
}))

const AccountNameWrapper = styled('div')<{maxWidth?: string}>(
  ({theme, maxWidth}) => ({
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    maxWidth: maxWidth || '100%',
  }),
)

const SelectWrapper = styled('div')({
  display: 'inline-flex',
  width: '100%',
  // needed instead of alignSelf on children because Tooltip can introduce intermediate <div>
  alignItems: 'stretch',
})

const AdornmentButton = styled(IconButton)(
  ({
    theme: {
      breakpoints,
      shape: {borderRadius},
    },
  }) => ({
    positions: 'relative',
    width: adornmentWidth,
    color: borderColor,
    border: '1px solid',
    borderRadius: '0',
    ':last-child': {
      borderTopRightRadius: borderRadius,
      borderBottomRightRadius: borderRadius,
    },
    borderLeft: 0,
    padding: 0,
    [breakpoints.down('md')]: {
      width: smallScreenAdornmentWidth,
    },
  }),
) as typeof IconButton // to retain generic type

const CopyIconWrapper = styled('div')({
  position: 'absolute',
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  top: 0,
  left: 0,
  px: 1,
})
