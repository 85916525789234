import {Box} from '@mui/material'
import clsx from 'clsx'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {Button} from 'src/components'
import {useUiStore} from 'src/store/ui'

import {useCommonStyles} from './utils'

export default function ManageBlockchains() {
  const commonClasses = useCommonStyles()
  const {t} = useTranslation()
  const {setIsBlockchainManagementModalOpen} = useUiStore()

  return (
    <Box className={clsx(commonClasses.sectionWrapper)}>
      <Button
        variant="contained"
        textTransform="none"
        size="large"
        color="secondary"
        onClick={() => setIsBlockchainManagementModalOpen(true)}
      >
        {t('Manage blockchains')}
      </Button>
    </Box>
  )
}
