import React from 'react'

type FormattedDateTimeVariant = 'datetime' | 'date' | 'time'

type FormattedDateTimeProps = {
  dateTime: Date
  variant?: FormattedDateTimeVariant
  dateTimeOptions?: Intl.DateTimeFormatOptions
}

export const FormattedDateTime = ({
  dateTime,
  variant = 'datetime',
  dateTimeOptions,
}: FormattedDateTimeProps) => (
  <>
    {variant === 'datetime'
      ? dateTime.toLocaleString(undefined, dateTimeOptions)
      : variant === 'date'
        ? dateTime.toLocaleDateString()
        : variant === 'time'
          ? dateTime.toLocaleTimeString()
          : null}
  </>
)
