import type {TypographyProps, Theme, SxProps} from '@mui/material'
import {Typography, styled} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import React from 'react'

import {useEllipsisStyles} from '../../../utils/layoutUtils'

const selectableStyles: SxProps = {
  'user-select': 'all',
}

type StringEllipsisProps = {
  value: string
  length: number
  width?: string | number
  maxWidth?: string | number
  ellipsizedTextSx?: SxProps
}

// NOTE: for the ellipsis to work width or maxWidth must be set
export const StringEllipsis = ({
  value,
  length,
  ellipsizedTextSx,
  ...rest
}: StringEllipsisProps) => {
  return (
    <StringEllipsisWrapper>
      <EllipsizedText {...rest} sx={ellipsizedTextSx}>
        {value.slice(0, -length)}
      </EllipsizedText>
      {value.slice(-length)}
    </StringEllipsisWrapper>
  )
}

const StringEllipsisWrapper = styled('span')({
  display: 'inline-flex',
  ...selectableStyles,
})
const EllipsizedText = styled('span')<
  Pick<StringEllipsisProps, 'maxWidth' | 'width'>
>(({maxWidth, width}) => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  ...(maxWidth && {maxWidth}),
  ...(width && {width}),
}))

type ConstantStringEllipsisProps = {
  value: string
  length?: number
}

const DEFAULT_LENGTH = 10

export function constantEllipsizeString(
  value: string,
  length = DEFAULT_LENGTH,
) {
  return value?.length > length ? `${value.slice(0, length)}...` : value
}

export function ConstantStringEllipsis({
  value,
  length,
}: ConstantStringEllipsisProps) {
  return <>{constantEllipsizeString(value, length)}</>
}

type MultilineStringProps = TypographyProps & {
  label: React.ReactNode
  isSelectable?: boolean
}

export function MultilineString({
  className,
  label,
  isSelectable = true,
  ...typoProps
}: MultilineStringProps) {
  const classes = useMultilineStringStyles()

  return (
    <Typography
      className={clsx(
        className,
        classes.breakWord,
        isSelectable && classes.selectable,
      )}
      {...typoProps}
    >
      {label}
    </Typography>
  )
}

export type EllipsizedStringProps = {
  value: string
  isSelectable?: boolean
  endLength?: number
  TypographyProps?: TypographyProps
  className?: string
} & (
  | {
      startLength?: number
    }
  | (
      | {
          width: number
        }
      | {widthClassName: string}
    )
)
const defaultStartLength = 8

const arrayFromSx = (sx: SxProps<Theme>) => (Array.isArray(sx) ? sx : [sx])

export function ellipsizeString(
  input: string,
  startLength: number,
  endLength: number,
) {
  return input.length <= startLength + endLength
    ? input
    : `${input.slice(0, startLength)}...${input.slice(
        -Math.min(input.length - startLength, endLength),
      )}`
}

export const EllipsizedString = ({
  value,
  isSelectable = false,
  endLength = 5,
  TypographyProps,
  ...rest
}: EllipsizedStringProps) => {
  const sx = TypographyProps?.sx || []
  const {ellipsis} = useEllipsisStyles()
  const isConstantLength = 'startLength' in rest

  return (
    <Typography
      {...TypographyProps}
      title={value}
      sx={[
        ...(isSelectable ? arrayFromSx(selectableStyles) : []),
        !isConstantLength && {
          display: 'inline-flex',
          maxWidth: '100%',
          width: 'width' in rest ? rest.width : undefined,
        },
        ...arrayFromSx(sx),
      ]}
      onCopy={(e) => {
        e.clipboardData.setData('text/plain', value)
        e.preventDefault()
      }}
    >
      {!isConstantLength ? (
        <>
          <span
            className={clsx(
              ellipsis,
              'widthClassName' in rest && rest.widthClassName,
              'className' in rest && rest.className,
            )}
          >
            {value.slice(0, -endLength)}
          </span>
          {value.slice(-endLength)}
        </>
      ) : (
        <>
          {ellipsizeString(
            value,
            rest.startLength || defaultStartLength,
            endLength,
          )}
        </>
      )}
    </Typography>
  )
}

const useMultilineStringStyles = makeStyles(() => ({
  breakWord: {
    wordBreak: 'break-all',
  },
  selectable: selectableStyles,
}))
