import {ArrowDownward, Launch} from '@mui/icons-material'
import {Box, Grid, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import type {SyntheticEvent} from 'react'
import React from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'

import config from 'src/config'
import {
  getMoonpayBuyUrl,
  getMoonpaySellUrl,
} from 'src/features/ramp/application'
import {routeTo} from 'src/router'

import img from '../../assets/images/moonpayLogo.png'
import {
  Alert,
  Button,
  FooterLayout,
  Icon,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalLayout,
} from '../../components'
import {LabeledIconButton} from '../../components/visual/molecules/LabeledIconButton'
import {colors} from '../../theme/palette'

import {ExternalSellButton, commonSellBuyButtonProps} from './common'
import {MoonpayFlowModal} from './MoonpayFlowModal'

type OpenBuySellButtonProps = {
  onClick: (e: SyntheticEvent) => void
}

export const OpenBuySellButton = ({onClick}: OpenBuySellButtonProps) => {
  const {t} = useTranslation()

  return (
    <Button
      startIcon={<Icon type="buySellIcon" exactSize={24} />}
      variant="outlined"
      color="primary"
      textTransform="none"
      onClick={onClick}
    >
      {t('Buy/Sell Crypto')}
    </Button>
  )
}

type BuySellModalHeaderProps = {
  onClose: () => void
}

export const BuySellModalHeader = ({onClose}: BuySellModalHeaderProps) => {
  const {t} = useTranslation()
  return (
    <ModalHeader onClose={onClose} hasDivider>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <Icon type="buySellIcon" color="primary" fontSize="large" />
        </Grid>
        <Grid item>
          <Typography variant="h6" color="textSecondary">
            {t('Buy/Sell Crypto')}
          </Typography>
        </Grid>
      </Grid>
    </ModalHeader>
  )
}

const BuySellModalContent = () => {
  const {t} = useTranslation()
  const classes = useStyles()

  return (
    <Box
      p={2}
      display="flex"
      flexDirection="column"
      className={classes.minFullHeight}
    >
      <Alert severity="info" text={t('buy_sell_modal_alert')} />
      <Typography className={classes.infoText}>
        <Trans
          i18nKey="buy_sell_modal_buying"
          t={t}
          components={{
            bold: (
              <Box
                component="span"
                style={{wordBreak: 'break-all'}}
                fontWeight="fontWeightMedium"
              />
            ),
          }}
        />
      </Typography>
      <Typography className={classes.infoText}>
        <Trans
          i18nKey="buy_sell_modal_selling"
          t={t}
          components={{
            bold: (
              <Box
                component="span"
                style={{wordBreak: 'break-all'}}
                fontWeight="fontWeightMedium"
              />
            ),
          }}
        />
      </Typography>
      <Box flex="1" alignItems="center" justifyContent="center" display="flex">
        <img src={img} width="60%" />
      </Box>
    </Box>
  )
}

const BuySellModalFooter = () => {
  const {t} = useTranslation()

  return (
    <ModalFooter hasDivider>
      <FooterLayout
        leftItem={
          <LabeledIconButton
            buttonProps={{
              ...commonSellBuyButtonProps,
              href: getMoonpayBuyUrl(),
            }}
            Label={t('Buy')}
            LabelIcon={<ArrowDownward />}
            EndIcon={<Launch color="inherit" fontSize="small" />}
          />
        }
        rightItem={<ExternalSellButton redirectUrl={getMoonpaySellUrl()} />}
      />
    </ModalFooter>
  )
}

export const BuySellModal = () => {
  const history = useHistory()

  if (config.isEmbeddedMoonpayEnabled) {
    // React router "goBack" does not work fine when embedding iframe
    // which lead to the need to click on "close" button multiple times.
    // Therefore we are using this tricky solution, which is not ideal,
    // as it does not clean up history fully, though still provides better UX.
    const onClose = () => {
      history.replace(routeTo.portfolio.assets.index)
    }

    return <MoonpayFlowModal onClose={onClose} />
  }

  const onClose = () => history.goBack()

  return (
    <Modal onClose={onClose} variant="left">
      <ModalLayout
        header={<BuySellModalHeader onClose={onClose} />}
        body={<BuySellModalContent />}
        footer={<BuySellModalFooter />}
      />
    </Modal>
  )
}

const useStyles = makeStyles(() => ({
  minFullHeight: {
    minHeight: '100%',
  },
  infoText: {
    paddingLeft: '50px',
    paddingRight: '16px',
    marginTop: '20px',
    color: colors.info.alertContent,
  },
}))
