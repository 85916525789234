import {
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  Divider,
  Paper,
  Box,
  AccordionActions,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import withStyles from '@mui/styles/withStyles'
import clsx from 'clsx'
import React from 'react'

import {Aligner} from '../atoms'

type AccordionCardProps = {
  elevation: number
  expanded: boolean
  onCardClick: (event: React.ChangeEvent<unknown>, isExpanded: boolean) => void
  mainContent: React.ReactNode
  actions?: React.ReactNode
  details: React.ReactNode
  doNotMountDetailsIfNotExpanded?: boolean
  hasVerticalMainContentMargin?: boolean
}

export function AccordionCard({
  elevation,
  expanded,
  onCardClick,
  mainContent,
  actions,
  details,
  doNotMountDetailsIfNotExpanded,
  hasVerticalMainContentMargin = true,
}: AccordionCardProps) {
  const classes = useStyles()
  return (
    <Accordion
      elevation={elevation}
      expanded={expanded}
      onChange={onCardClick}
      className={classes.accordionCard}
      classes={{root: classes.accordionExpanded}}
      // optimization option (https://mui.com/components/accordion/#performance)
      TransitionProps={
        doNotMountDetailsIfNotExpanded ? {unmountOnExit: true} : undefined
      }
    >
      <AccordionSummary>
        <Paper
          elevation={expanded ? 2 : 1}
          variant="elevation"
          className={clsx(classes.wrapper, classes.wrapperExpanded)}
        >
          <Box
            width="100%"
            // Would use 0, but 0.5 is the smallest unit with which expanding the accordion
            // does not make it jump up and down a pixel
            my={hasVerticalMainContentMargin ? 2 : 0.5}
            className={classes.mainContentPadding}
          >
            {mainContent}
          </Box>
          {expanded && (
            <Box width="100%">
              <Divider />
            </Box>
          )}
          {actions && (
            <Paper
              elevation={0}
              className={clsx({[classes.wrapperExpanded]: expanded})}
            >
              <AccordionActions>
                <Aligner align="center">{actions}</Aligner>
              </AccordionActions>
            </Paper>
          )}
        </Paper>
      </AccordionSummary>
      <AccordionDetails>
        <Box width="100%" boxShadow={1}>
          {details}
        </Box>
      </AccordionDetails>
    </Accordion>
  )
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
    cursor: 'pointer',
    background: theme.palette.background.paper,
  },
  mainContentPadding: {
    padding: theme.spacing(0.5, 2),
  },
  accordionCard: {
    marginBottom: theme.spacing(2),
  },
  wrapperExpanded: {
    background: theme.palette.grey.A400,
  },
  accordionExpanded: {
    '&.Mui-expanded': {
      marginBottom: theme.spacing(4),
      '&:last-child': {
        marginBottom: theme.spacing(4),
      },
    },
  },
}))

const Accordion = withStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1.5),
    '&:first-child': {
      marginTop: '0 !important',
    },
    '&:before': {
      display: 'none',
    },
  },
}))(MuiAccordion)

const AccordionSummary = withStyles({
  root: {
    padding: 0,
  },
  content: {
    margin: '0!important',
  },
})(MuiAccordionSummary)

const AccordionDetails = withStyles(() => ({
  root: {
    padding: 0,
  },
}))(MuiAccordionDetails)
