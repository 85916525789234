import {
  Clear as CancelIcon,
  ChevronRight as AccountDetailIcon,
  LibraryAddCheckOutlined as SelectNftsIcon,
} from '@mui/icons-material'
import {
  Box,
  lighten,
  Divider,
  Backdrop,
  ClickAwayListener,
  Grid,
  Typography,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {assert} from '@nufi/frontend-common'
import React, {useEffect, useRef} from 'react'
import ContentLoader from 'react-content-loader'
import {useTranslation} from 'react-i18next'
import {useHistory, useLocation} from 'react-router-dom'

import {blockchainToWalletKind} from 'src/wallet/walletKind'

import type {NftBlockchain} from '../../blockchainTypes'
import {nftBlockchains} from '../../blockchainTypes'
import {
  PagingButton,
  AccountCardCol,
  Icon,
  NFTAccountsError,
  Button,
  TextButton,
  WithTooltip,
  BatchQueryGuard,
} from '../../components'
import config from '../../config'
import {SIDEBAR_WIDTH, DASHBOARD_LOGGED_IN_MIN_WIDTH} from '../../constants'
import {encodeTokenId, routeTo, withQueryString} from '../../router'
import {assertBlockchainIsInSubset} from '../../utils/blockchainGuards'
import {
  useResponsiveDimensions,
  ContentLoaderBackground,
} from '../../utils/layoutUtils'
import {useGetBlockchainName} from '../../utils/translations'
import type {AccountId, AccountInfo, Nft, NftPage, TokenId} from '../../wallet'
import {useAccountsWithNftSnapshot, useGetAccountNfts} from '../../wallet'
import {ensureAccountById} from '../../wallet/utils/common'
import {isSendMultipleAssetsSupported} from '../send/common/utils'

import type {NftVisibility} from './hiddenNfts'
import {ManageNftVisibilityButton, HiddenNftsCard} from './hiddenNfts'
import {
  NftCard,
  NftShowMoreCard,
  THUMBNAIL_SIZE,
  BORDER_RADIUS,
  LINE_SIZE,
} from './NftCard'
import {useNftSelectionState} from './selectNftsState'

export function FullWidthCardLoaders() {
  const maxItemsCount = useNftListTwoRowsItemsCount()
  // for some reason, if we set the width exactly to THUMBNAIL_SIZE its 4px wider
  const NFT_CARD_WIDTH = THUMBNAIL_SIZE - 4
  const NFT_LIST_MARGIN_LEFT = 8
  return (
    <>
      {[...Array(maxItemsCount / 2)].map((cardLoader, i) => (
        <React.Fragment key={i}>
          <rect
            x={
              (NFT_CARD_WIDTH + NFT_CARD_MARGIN_HORIZ) * i +
              NFT_LIST_MARGIN_LEFT
            }
            y="90"
            rx={BORDER_RADIUS}
            width={NFT_CARD_WIDTH}
            height={NFT_CARD_WIDTH}
          />
          <rect
            x={
              (NFT_CARD_WIDTH + NFT_CARD_MARGIN_HORIZ) * i +
              NFT_LIST_MARGIN_LEFT
            }
            y={NFT_CARD_WIDTH + 92}
            rx={BORDER_RADIUS}
            width={NFT_CARD_WIDTH}
            height={LINE_SIZE * 48}
          />
        </React.Fragment>
      ))}
    </>
  )
}

export function NFTAccountsContentLoading() {
  const dimensions = useResponsiveDimensions()
  const maxWidth = dimensions.width - SIDEBAR_WIDTH - 50
  return (
    <ContentLoader
      viewBox={`0 0 ${maxWidth} 430`}
      backgroundColor={ContentLoaderBackground()}
    >
      <rect x="0" y="10" rx="5" width={maxWidth} height="60" />
      <FullWidthCardLoaders />
    </ContentLoader>
  )
}

type NftsSelectionProps = {
  accountId: AccountId
  blockchain: NftBlockchain
  nftVisibility: NftVisibility
}

export function NftsSelection({
  accountId,
  blockchain,
  nftVisibility,
}: NftsSelectionProps) {
  const {t} = useTranslation()
  const {targetAccount, resetSelection, selectedNfts, initializeSelection} =
    useNftSelectionState()
  const history = useHistory()
  const location = useLocation()
  const getBlockchainName = useGetBlockchainName()

  const onSend = () => {
    resetSelection()
    const nftSendRoute = routeTo.portfolio.nfts
      .blockchain(blockchain)
      .send(accountId)

    if (isSendMultipleAssetsSupported(blockchain)) {
      // https://stackoverflow.com/questions/52328891/when-using-hashrouter-in-reactjs-this-props-location-state-is-undefined-on-page
      // !!! Always pass `location.state` via {state: yourState} syntax otherwise it is `undefined` in extension (HashRouter)
      history.push({
        pathname: nftSendRoute,
        state: {
          background: location,
          selectedNfts,
        },
      })
    } else {
      // Single NFT sending:
      assert(selectedNfts.length === 1)
      history.push({
        pathname: nftSendRoute,
        search: withQueryString('', encodeTokenId(selectedNfts[0])),
        state: {
          background: location,
        },
      })
    }
  }

  const isActive = targetAccount === accountId

  const sendingEnabled =
    isSendMultipleAssetsSupported(blockchain) || selectedNfts.length === 1

  return (
    <BackdropStandOutItem>
      {/* prevent events such as clickAway listeners within BackdropStandOut */}
      <Box onClick={(e) => e.stopPropagation()}>
        <Grid container spacing={2}>
          {isActive && selectedNfts.length > 0 && (
            <>
              <Grid item>
                <WithTooltip
                  title={
                    !sendingEnabled ? (
                      <Typography>
                        {t('sending_only_one_nft_supported', {
                          blockchain: getBlockchainName(blockchain),
                        })}
                      </Typography>
                    ) : (
                      ''
                    )
                  }
                >
                  <Button
                    variant="outlined"
                    onClick={onSend}
                    size="large"
                    textTransform="none"
                    color="primary"
                    startIcon={<Icon type="sendIcon" exactSize={24} />}
                    disabled={!sendingEnabled}
                  >
                    {t('Send selected')}
                  </Button>
                </WithTooltip>
              </Grid>
              {config.isNftHidingEnabled && (
                <Grid item>
                  <ManageNftVisibilityButton
                    nftVisibility={nftVisibility}
                    blockchain={blockchain}
                    selectedNfts={selectedNfts}
                    resetSelection={resetSelection}
                  />
                </Grid>
              )}
            </>
          )}
          <Grid item>
            <Button
              variant="outlined"
              textTransform="none"
              size="large"
              onClick={() => {
                !isActive
                  ? initializeSelection(accountId, null)
                  : resetSelection()
              }}
              startIcon={!isActive ? <SelectNftsIcon /> : <CancelIcon />}
            >
              {!isActive ? t('Select NFTs') : t('Reset selection')}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </BackdropStandOutItem>
  )
}

type NftListHeaderProps = {
  account: AccountInfo
  showControls: boolean
}

function NftListHeader({account, showControls}: NftListHeaderProps) {
  const history = useHistory()
  const {t} = useTranslation()
  const classes = useStyles()

  assertBlockchainIsInSubset(account.blockchain, nftBlockchains)

  const navigateToAccountDetail = (accountId: AccountId) => {
    history.push(
      routeTo.portfolio.nfts.blockchain(account.blockchain).account(accountId)
        .index,
    )
  }

  const navigateToReceiveModal = (accountId: AccountId) => {
    history.push(
      routeTo.portfolio.nfts.blockchain(account.blockchain).account(accountId)
        .receive,
    )
  }

  return (
    <Box className={classes.headingContainer}>
      <Box flex={1}>
        <AccountCardCol
          walletKind={blockchainToWalletKind(account.blockchain)}
          blockchain={account.blockchain}
          name={account.name}
          cryptoProviderType={account.cryptoProviderType}
        />
      </Box>
      {showControls && (
        <Box>
          <Grid container display="flex" alignItems="center" spacing={2}>
            {
              <Grid item>
                <NftsSelection
                  accountId={account.id}
                  blockchain={account.blockchain}
                  nftVisibility="visible"
                />
              </Grid>
            }
            <Grid item>
              <Button
                variant="outlined"
                size="large"
                textTransform="none"
                onClick={() => navigateToReceiveModal(account.id)}
                startIcon={<Icon type="receiveIcon" exactSize={24} />}
              >
                {t('Receive')}
              </Button>
            </Grid>
            <Grid item>
              <TextButton
                label={t('Account details')}
                color="textSecondary"
                onClick={() => navigateToAccountDetail(account.id)}
                Icon={<AccountDetailIcon />}
              />
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  )
}

type NftListProps = {
  blockchain: NftBlockchain
  account: AccountInfo
  maxItemsCount?: number
  showHeader?: boolean
  searchText?: string
  nftVisibility: NftVisibility
}

// Estimate amount of NFTs that fit into two rows.
// TODO: consider handling better using `useRef` this is a quick workaround
export const useNftListTwoRowsItemsCount = () => {
  const {width} = useResponsiveDimensions()
  const _availableWidth =
    Math.max(DASHBOARD_LOGGED_IN_MIN_WIDTH, width) - SIDEBAR_WIDTH
  const availableWidth = _availableWidth - 50 // safety gap
  const _EXPECTED_CARD_SIZE = THUMBNAIL_SIZE + NFT_CARD_MARGIN_HORIZ
  return Math.floor(availableWidth / _EXPECTED_CARD_SIZE) * 2
}

export function NftList({
  blockchain,
  account,
  maxItemsCount,
  showHeader = false,
  searchText = '',
  nftVisibility,
}: NftListProps) {
  const accountNftsQuery = useGetAccountNfts({
    blockchain,
    accountId: account.id,
    searchText,
    visibility: nftVisibility === 'visible' ? 'visible' : 'hidden',
  })
  const hiddenNftSnapshotQuery = useAccountsWithNftSnapshot({
    blockchain,
    visibility: 'hidden',
  })
  const {hasNextPage, fetchNextPage, isLoading, isFetching} = accountNftsQuery
  const classes = useStyles()
  const {targetAccount, resetSelection} = useNftSelectionState()

  return (
    <BatchQueryGuard
      queries={{accountNftsQuery, hiddenNftSnapshotQuery}}
      LoadingElement={<NFTAccountsContentLoading />}
      ErrorElement={<NFTAccountsError accountName={account.name} />}
    >
      {({
        accountNftsQuery: accountNfts,
        hiddenNftSnapshotQuery: hiddenNftSnapshot,
      }) => {
        const hiddenNftsPage = ensureAccountById(
          hiddenNftSnapshot,
          account.id,
        ).nftSnapshot

        return (
          <BackdropFocusableItem
            active={targetAccount === account.id}
            onClickAway={resetSelection}
            // Scroll only if limited number of items are displayed for better UX.
            // Otherwise, if a bottom NFT is clicked, the page would scroll to the top.
            shouldScrollIntoViewOnActivation={!!maxItemsCount}
          >
            <Box mb={3}>
              {(accountNfts.length > 0 || !!hiddenNftsPage?.items.length) && (
                <>
                  {showHeader && (
                    <NftListHeader account={account} showControls />
                  )}
                  <NftListContent
                    hasNextPage={hasNextPage}
                    accountNfts={accountNfts}
                    hiddenNftsPage={hiddenNftsPage}
                    blockchain={blockchain}
                    account={account}
                    maxItemsCount={maxItemsCount}
                    nftVisibility={nftVisibility}
                  />
                  {!maxItemsCount && !!hasNextPage && (
                    <Box width="100%" textAlign="center">
                      <PagingButton
                        className={classes.pagingButton}
                        pagingParams={{onNextPage: fetchNextPage, hasNextPage}}
                        isLoading={isLoading || isFetching}
                      />
                    </Box>
                  )}
                </>
              )}
            </Box>
          </BackdropFocusableItem>
        )
      }}
    </BatchQueryGuard>
  )
}

const NftListContent = ({
  accountNfts,
  hiddenNftsPage,
  hasNextPage,
  blockchain,
  account,
  maxItemsCount,
  nftVisibility,
}: {
  accountNfts: Nft[]
  hiddenNftsPage?: NftPage
  hasNextPage?: boolean
  blockchain: NftBlockchain
  account: AccountInfo
  maxItemsCount?: number
  nftVisibility: NftVisibility
}) => {
  const classes = useStyles()
  const history = useHistory()
  const {toggleSelectNft, selectedNfts, targetAccount, initializeSelection} =
    useNftSelectionState()

  const navigateToAssetDetail = (tokenId: TokenId, accountId: AccountId) => {
    history.push(
      withQueryString(
        routeTo.portfolio.nfts
          .blockchain(blockchain)
          .detail.account(accountId)
          .tab('info').index,
        encodeTokenId(tokenId),
      ),
    )
  }

  const navigateToHiddenNFTs = (accountId: AccountId) => {
    history.push(
      routeTo.portfolio.nfts.blockchain(blockchain).account(accountId)
        .hiddenNFTs,
    )
  }

  const navigateToAccountDetail = (accountId: AccountId) => {
    history.push(
      routeTo.portfolio.nfts.blockchain(blockchain).account(accountId).index,
    )
  }

  /**
   * The following code determines whether to render "hidden NFTs card" and/or "all NFTs card",
   * while taking into account the maximum number of items to display. If the maximum number of items
   * would be exceeded by appending these elements, the last NFT card(s) are replaced instead of appended to.
   * This makes sure that the UI/UX is not broken and that no elements are lost by such replacements.
   */

  const isInNftOverviewSection = !!maxItemsCount
  const shouldRenderHiddenNftsCard =
    config.isNftHidingEnabled &&
    nftVisibility !== 'hidden' &&
    !!hiddenNftsPage?.items.length

  const appendingHiddenCardWouldCauseOverflow =
    isInNftOverviewSection &&
    accountNfts.length + (shouldRenderHiddenNftsCard ? 1 : 0) > maxItemsCount

  const shouldRenderShowAllNftsCard =
    isInNftOverviewSection &&
    (hasNextPage || appendingHiddenCardWouldCauseOverflow)

  const numberOfTrailingNftsToReplace =
    // showAllNfts always replaces the last nft card if rendered
    +shouldRenderShowAllNftsCard +
    (hasNextPage || appendingHiddenCardWouldCauseOverflow
      ? +shouldRenderHiddenNftsCard
      : // don't replace, but append hidden card if it does not mess up UI
        0)

  const elementsToRender = accountNfts
    .slice(
      0,
      (maxItemsCount || accountNfts.length) - numberOfTrailingNftsToReplace,
    )
    .map((nft) => (
      <NftCard
        key={nft.id}
        onClick={() => navigateToAssetDetail(nft.id, account.id)}
        blockchain={blockchain}
        accountId={account.id}
        nft={nft}
        isSelecting={targetAccount === account.id}
        onSelect={toggleSelectNft}
        initializeSelection={() => initializeSelection(account.id, nft.id)}
        isSelected={selectedNfts.some((id) => id === nft.id)}
      />
    ))

  shouldRenderHiddenNftsCard &&
    elementsToRender.push(
      <HiddenNftsCard
        hiddenNftsPage={hiddenNftsPage}
        onClick={() => navigateToHiddenNFTs(account.id)}
      />,
    )

  shouldRenderShowAllNftsCard &&
    elementsToRender.push(
      <NftShowMoreCard onClick={() => navigateToAccountDetail(account.id)} />,
    )

  return (
    <Box mt={1} className={classes.nftContainer}>
      {elementsToRender}
    </Box>
  )
}

export function HeaderDivider() {
  const classes = useStyles()
  return <Divider className={classes.divider} />
}

type BackdropBackgroundProps = {
  onClose: () => unknown
  open: boolean
}

function _BackdropBackground({onClose, open}: BackdropBackgroundProps) {
  const classes = useBackdropStyles()
  return <Backdrop {...{open}} onClick={onClose} className={classes.backdrop} />
}

export function BackdropBackground() {
  const {targetAccount, resetSelection} = useNftSelectionState()
  return (
    <_BackdropBackground
      open={!!targetAccount}
      onClose={() => resetSelection()}
    />
  )
}

type BackdropStandOutItemProps = {children: React.ReactNode | React.ReactNode[]}

function BackdropStandOutItem({children}: BackdropStandOutItemProps) {
  return <Box zIndex={2}>{children}</Box>
}

type BackdropFocusableItemProps = {
  active: boolean
  children: React.ReactElement
  onClickAway: () => unknown
  shouldScrollIntoViewOnActivation?: boolean
}

function BackdropFocusableItem({
  active,
  children,
  onClickAway,
  shouldScrollIntoViewOnActivation = true,
}: BackdropFocusableItemProps) {
  const {exclude} = useBackdropStyles()
  const [scrolled, setScrolled] = React.useState(false)
  const ref = useRef() as React.MutableRefObject<HTMLElement>

  useEffect(() => {
    if (shouldScrollIntoViewOnActivation) {
      setScrolled(false)
      if (active && ref.current && !scrolled) {
        ref.current.scrollIntoView({
          behavior: 'smooth',
        })
        setScrolled(true)
      }
    }
  }, [active])

  return (
    <ClickAwayListener onClickAway={active ? onClickAway : () => {}}>
      <Box ref={ref}>
        <Box className={active ? exclude : ''}>{children}</Box>
      </Box>
    </ClickAwayListener>
  )
}

const NFT_CARD_MARGIN_HORIZ = 20

const useBackdropStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 1,
    // without this property the scrolling is broken
    // close `onClickOutside` is handled using `ClickAwayListener`
    pointerEvents: 'none',
  },
  exclude: {
    position: 'relative',
    zIndex: 1,
    cursor: 'pointer',
    background: theme.palette.background.default,
    boxShadow: theme.shadows[15],
    borderRadius: 6,
    overflow: 'auto',
  },
}))

const useStyles = makeStyles((theme) => ({
  nftContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignContent: 'flex-start',
    listStyle: 'none',
    padding: theme.spacing(0, 2),
    '& > *': {
      marginTop: 16,
      marginRight: NFT_CARD_MARGIN_HORIZ,
    },
  },
  headingContainer: {
    background: lighten(theme.palette.background.default, 0.04),
    boxShadow: theme.shadows[1],
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    borderRadius: 6,
    display: 'flex',
    justifyContent: 'space-between',
  },
  pagingButton: {
    marginTop: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
}))
