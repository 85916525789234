import type {BoxProps, ChipTypeMap} from '@mui/material'
import {Box, Chip} from '@mui/material'
import React from 'react'

type NFTBadgeProps = ChipTypeMap['props'] & {
  boxProps?: BoxProps
}

export function NFTBadge({size = 'small', boxProps, ...rest}: NFTBadgeProps) {
  return (
    <Box ml={1} display="inline" {...boxProps}>
      <Chip
        label="NFT"
        color="primary"
        variant="outlined"
        {...{size, ...rest}}
      />
    </Box>
  )
}
