import type {AccountId, AccountName} from '@nufi/wallet-common'
import {
  createFetchAccountWholeRewardHistory,
  createFetchAccountWholeTxHistory,
} from '@nufi/wallet-solana'
import type {
  SolanaAddress,
  SolanaMintAddress,
  SolanaStakeHistoryRewardEntry,
  SolanaTokenMetadata,
  SolanaTxHistoryEntry,
  SolanaAccountInfo,
} from '@nufi/wallet-solana'
import _ from 'lodash'

import {ensureAccountById} from '../../utils/common'
import {solana} from '../solanaManagers'

import {cachedGetTokensMetadata} from './queries'

type CSVSolanaExtraData = {
  accountName: AccountName
  address: SolanaAddress
}

export type CSVSolanaTxHistoryEntry = SolanaTxHistoryEntry & CSVSolanaExtraData

export type CSVSolanaStakeHistoryRewardEntry = SolanaStakeHistoryRewardEntry &
  CSVSolanaExtraData

const fetchAccountWholeTxHistory = createFetchAccountWholeTxHistory(() => ({
  accountManager: solana.accountManager,
}))

export async function getTxHistories(
  accounts: SolanaAccountInfo[],
  startDate: Date,
): Promise<CSVSolanaTxHistoryEntry[][]> {
  return await Promise.all(
    accounts.map(async ({id, address}) => {
      const account = solana.accountsStore.getAccount(id as AccountId)
      const history = await fetchAccountWholeTxHistory(account, startDate)

      return history.map((tx) => ({...tx, accountName: account.name, address}))
    }),
  )
}

const fetchAccountWholeRewardHistory = createFetchAccountWholeRewardHistory(
  () => ({
    wallet: solana.wallet,
  }),
)

export async function getRewardHistories(
  accounts: SolanaAccountInfo[],
  startDate: Date,
): Promise<CSVSolanaStakeHistoryRewardEntry[][]> {
  const storedAccounts = await Promise.all(
    accounts.map(async ({id}) => {
      return solana.accountsStore.getAccount(id)
    }),
  )

  const stakeAccountsPartial =
    await solana.wallet.getStakeAccounts(storedAccounts)
  const stakeAccountsInfo = await solana.wallet.getStakingInfo(
    storedAccounts,
    stakeAccountsPartial,
  )

  const stakeAccounts = accounts.map((account) => ({
    ...account,
    stakeAccounts:
      stakeAccountsInfo.find((i) => i.accountId === account.id)
        ?.stakeAccounts || [],
  }))

  if (!stakeAccounts?.length) return []

  return await Promise.all(
    accounts.map(async ({id, address}) => {
      const targetAccount = ensureAccountById(stakeAccounts, id)
      const history = await fetchAccountWholeRewardHistory({
        targetAccount,
        startDate,
      })
      return history.map((entry) => ({...entry, address}))
    }),
  )
}

export async function getTokenMap(): Promise<
  Record<SolanaMintAddress, SolanaTokenMetadata>
> {
  const tokensMetadata = await cachedGetTokensMetadata()
  return _.keyBy(tokensMetadata, (m) => m.id)
}
