import {conversionRatesQueryKeys} from 'src/features/conversionRates/application/queryKeys'

import {crossBlockchainQueryKeys} from '../../public/queries/queryKeys'

import {
  combinedQueryKeys,
  nftsQueryKeys,
  queryKeys,
  stakingQueryKeys,
  tokensQueryKeys,
} from './queries'

// Order of invalidation keys matters! More info can be found in docs/queries.md.

export const tokensInvalidationKeys = [
  // Nfts must be before tokens as some tokens queries are building on top
  // of nft queries.
  nftsQueryKeys.index,
  tokensQueryKeys.tokens,
  conversionRatesQueryKeys.index,
  combinedQueryKeys.balance.index,
]

export const accountsInvalidationKeys = [
  queryKeys.accounts,
  stakingQueryKeys.index,
  tokensQueryKeys.tokens,
  nftsQueryKeys.index,
  conversionRatesQueryKeys.index,
  combinedQueryKeys.balance.index,
  combinedQueryKeys.txHistory.index,
  queryKeys.stakeHistory.index,
  crossBlockchainQueryKeys.index,
]
