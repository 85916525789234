import {Grid, Typography, TextField} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import _ from 'lodash'
import React from 'react'

import Paper from '../atoms/Paper'

type MnemonicFieldProps = {
  value: Array<string>
  onChange: (value: Array<string>) => unknown
  hasError?: boolean
  errorWords: Array<string>
}

export default function MnemonicField({
  value,
  onChange,
  hasError,
  errorWords,
}: MnemonicFieldProps) {
  const classes = useStyles()
  const chunkedWords = _.chunk(value, 3)

  return (
    <>
      <Paper className={classes.wordsPalette}>
        {chunkedWords.map((chunk, chunkIndex) => (
          <Grid container key={chunkIndex}>
            {chunk.map((word, chunkWordIndex) => {
              const wordIndex = chunkIndex * 3 + chunkWordIndex
              return (
                <Grid
                  item
                  xs={4}
                  className={classes.wordWrapper}
                  key={chunkWordIndex}
                >
                  <Typography
                    className={classes.wordIndex}
                    variant="body2"
                    color="textSecondary"
                  >
                    {wordIndex + 1}.
                  </Typography>
                  <TextField
                    variant="standard"
                    data-test-id={`mnemonic-field-input-${wordIndex}`}
                    rtl-data-test-id={`mnemonic-field-input-${wordIndex}`}
                    value={value[wordIndex]}
                    onChange={(e) => {
                      const parseNewValue = e.target.value.trim().split(/\s+/)
                      if (parseNewValue?.length > 0) {
                        onChange(
                          value.map((v, i) =>
                            i >= wordIndex
                              ? parseNewValue?.[i - wordIndex] ?? v
                              : v,
                          ),
                        )
                      } else {
                        onChange(
                          value.map((v, i) =>
                            i === wordIndex ? parseNewValue[0]! : v,
                          ),
                        )
                      }
                    }}
                    error={
                      hasError &&
                      (value[wordIndex] === '' ||
                        errorWords.includes(value[wordIndex]!))
                    }
                  />
                </Grid>
              )
            })}
          </Grid>
        ))}
      </Paper>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  wordWrapper: {
    display: 'flex',
    padding: theme.spacing(1.5, 2),
    height: 50,
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0.5, 1),
    },
  },
  wordIndex: {
    width: 60,
    marginTop: theme.spacing(1),
  },
  wordsPalette: {
    padding: theme.spacing(1.5),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0.5),
    },
  },
  error: {
    borderBottomColor: theme.palette.error.main,
  },
}))
