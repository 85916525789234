import {Sentry} from '@nufi/frontend-common'
import type {Snap} from '@nufi/metamask-snap'
import {
  detectSnaps,
  getSnap,
  getIsFlask,
  isMinimalRequiredVersion,
  getIsEthereumProviderConnected,
  isMetaMaskProviderInitialized,
  initMetaMaskProvider,
} from '@nufi/metamask-snap'
import type {ReactNode} from 'react'
import React, {useContext, createContext, useEffect, useState} from 'react'

import config from 'src/config'

export type MetamaskState =
  | {
      snapsDetected: boolean
      isFlask: boolean
      installedSnap?: Snap
      isInitialized: true
      isMinimalRequiredVersion: boolean
    }
  | {
      isInitialized: false
    }

const initialState: MetamaskState = {
  isInitialized: false,
}

export type MetamaskContext = {
  state: MetamaskState
  setInstalledSnap: (snap: Snap) => void
}

export const MetaMaskContext = createContext<MetamaskContext>({
  state: initialState,
  setInstalledSnap: () => null,
})

/**
 * MetaMask context provider to handle MetaMask and snap status.
 *
 * @param props - React Props.
 * @param props.children - React component to be wrapped by the Provider.
 * @returns JSX.
 */

export const MetaMaskProvider = ({children}: {children: ReactNode}) => {
  // TODO: Drop this ENV check once fully supporting snap in production
  return !config.isSnapEnabled ? (
    <>{children}</>
  ) : (
    <_MetaMaskProvider>{children}</_MetaMaskProvider>
  )
}

const _MetaMaskProvider = ({children}: {children: ReactNode}) => {
  const [state, setState] = useState<MetamaskState>(initialState)

  const setMetamaskNotDetected = () => {
    setState({
      isInitialized: true,
      isFlask: false,
      snapsDetected: false,
      installedSnap: undefined,
      isMinimalRequiredVersion: false,
    })
  }

  useEffect(() => {
    const initMetamaskState = async () => {
      try {
        await initMetaMaskProvider()

        // at this point, we know that the provider should be initialized
        if (!isMetaMaskProviderInitialized()) {
          setMetamaskNotDetected()
          return
        }

        // determines if RPC methods are even callable
        // https://github.com/MetaMask/providers/issues/65
        const isEthereumProviderConnected = getIsEthereumProviderConnected()
        if (isEthereumProviderConnected) {
          const hasSnaps = await detectSnaps()
          if (hasSnaps) {
            // Note these are not expected to throw
            const snap = await getSnap()
            const isFlask = await getIsFlask()
            const isMinimalRequiredVersionPayload =
              await isMinimalRequiredVersion()

            setState({
              isInitialized: true,
              isFlask,
              snapsDetected: true,
              installedSnap: snap,
              isMinimalRequiredVersion: isMinimalRequiredVersionPayload,
            })
          } else {
            setMetamaskNotDetected()
          }
        } else {
          setMetamaskNotDetected()
        }
      } catch (e) {
        Sentry.captureException(e)
        setMetamaskNotDetected()
        return
      }
    }
    initMetamaskState()
  }, [])

  const setInstalledSnap = (snap: Snap) => {
    setState((s) => ({
      ...s,
      installedSnap: snap,
    }))
  }

  return (
    <MetaMaskContext.Provider value={{state, setInstalledSnap}}>
      {children}
    </MetaMaskContext.Provider>
  )
}

export const useMetamaskContext = () => useContext(MetaMaskContext)
