import type {TypographyProps} from '@mui/material'
import {Typography} from '@mui/material'
import React from 'react'

import type {ProfileName} from 'src/appStorage'

import {Icon, LabeledIcon} from '../visual'

export function FormattedMetamaskProfileName({
  iconSize,
  labelProps,
  profileName,
}: {
  iconSize?: number
  labelProps?: TypographyProps
  profileName: ProfileName
}) {
  return (
    <LabeledIcon
      Icon={<Icon exactSize={iconSize} type="metamaskIcon" />}
      Label={<Typography {...(labelProps || {})}>{profileName}</Typography>}
      iconPosition="start"
    />
  )
}
