import {
  CheckCircleOutlined as RestakeIcon,
  HighlightOff as DoesNotRestakeIcon,
  AccessTimeOutlined as TimeIcon,
} from '@mui/icons-material'
import {Grid, Typography} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import type BigNumber from 'bignumber.js'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'

import {useGetConversionRates} from 'src/features/conversionRates/application'

import type {StakingBlockchain} from '../../../blockchainTypes'
import {
  HidableGridItem,
  Aligner,
  ListHeaderWrapper,
  ListHeaderTitle,
  ListLayoutWrapper,
  AssetCard,
  ElevatedCard,
  Button,
  WithTooltip,
  FormattedAssetPair,
  FormattedPercentage,
  QueryGuard,
} from '../../../components'
import {getBlockchainRewardsAutoStake} from '../../../constants'
import {routeTo} from '../../../router'
import {useGetCoinName} from '../../../utils/translations'
import {useTotalStakedAPY} from '../../../wallet'

type MyStakingCardProps = {
  blockchain: StakingBlockchain
  availableBalance: BigNumber
  stakedBalance: BigNumber
  rewardsBalance: BigNumber
  rewardsEpochDepth?: number
  stakedRatio: BigNumber
  hasAlreadyStaked: boolean
}

export function MyStakingCard({
  blockchain,
  availableBalance,
  stakedBalance,
  rewardsBalance,
  rewardsEpochDepth,
  hasAlreadyStaked,
  stakedRatio,
}: MyStakingCardProps) {
  const {t} = useTranslation()
  const history = useHistory()
  const classes = useStyles()
  const getCoinName = useGetCoinName()
  const apyQuery = useTotalStakedAPY(blockchain)
  const isAutoRestaking = getBlockchainRewardsAutoStake(blockchain)
  const {rates} = useGetConversionRates()

  return (
    <ElevatedCard type="light" tableCard>
      <MyStakingListItemLayout
        assetCol={<AssetCard blockchain={blockchain} />}
        apyCol={
          <QueryGuard {...apyQuery}>
            {(APY) => (
              <Typography variant="body2">
                <FormattedPercentage value={APY} />
              </Typography>
            )}
          </QueryGuard>
        }
        balanceCol={
          <FormattedAssetPair
            blockchain={blockchain}
            amount={availableBalance}
            isSensitiveInformation
            conversionProps={{
              conversionRates: rates,
            }}
          />
        }
        rewardsCol={
          <Grid container alignItems="center" spacing={1}>
            <Grid item>
              <FormattedAssetPair
                blockchain={blockchain}
                amount={rewardsBalance}
                isSensitiveInformation
                conversionProps={{
                  conversionRates: rates,
                }}
              />
            </Grid>
            <Grid item>
              <Grid item>
                <WithTooltip
                  title={`${getCoinName(blockchain)} ${
                    isAutoRestaking
                      ? t('rewards are re-staked automatically')
                      : t('rewards are not automatically staked')
                  }`}
                >
                  {isAutoRestaking ? (
                    <RestakeIcon color="primary" fontSize="small" />
                  ) : (
                    <DoesNotRestakeIcon color="warning" fontSize="small" />
                  )}
                </WithTooltip>
              </Grid>
              {rewardsEpochDepth && (
                <Grid item pt={0.5}>
                  <WithTooltip
                    title={`${getCoinName(blockchain)} ${t('rewards_aggregated_over_N_epochs', {epochs: rewardsEpochDepth})}`}
                  >
                    <TimeIcon color="warning" fontSize="small" />
                  </WithTooltip>
                </Grid>
              )}
            </Grid>
          </Grid>
        }
        stakedCol={
          <FormattedAssetPair
            blockchain={blockchain}
            amount={stakedBalance}
            isSensitiveInformation
            conversionProps={{
              conversionRates: rates,
            }}
          />
        }
        stakedPercentageCol={
          <Typography variant="body2">
            <FormattedPercentage value={stakedRatio} />
          </Typography>
        }
        actionsCol={
          <Button
            className={classes.myStakingButton}
            textTransform="none"
            variant={hasAlreadyStaked ? 'outlined' : 'contained'}
            color="primary"
            onClick={() =>
              history.push(
                routeTo.staking.myStaking.blockchain(blockchain).index,
              )
            }
          >
            {hasAlreadyStaked ? t('My Staking') : t('Start Staking')}
          </Button>
        }
      />
    </ElevatedCard>
  )
}

export function MyStakingListHeader() {
  const {t} = useTranslation()

  return (
    <ListHeaderWrapper>
      <MyStakingListItemLayout
        assetCol={<ListHeaderTitle>{t('Asset')}</ListHeaderTitle>}
        apyCol={<ListHeaderTitle>{t('APY %')}</ListHeaderTitle>}
        balanceCol={<ListHeaderTitle>{t('Available balance')}</ListHeaderTitle>}
        rewardsCol={<ListHeaderTitle>{t('Rewards')}</ListHeaderTitle>}
        stakedCol={<ListHeaderTitle>{t('Staked')}</ListHeaderTitle>}
        stakedPercentageCol={<ListHeaderTitle>{t('Staked %')}</ListHeaderTitle>}
        actionsCol={<ListHeaderTitle>{t('Actions')}</ListHeaderTitle>}
      />
    </ListHeaderWrapper>
  )
}

type AccountListItemLayoutProps = {
  assetCol: React.ReactNode
  apyCol: React.ReactNode
  balanceCol: React.ReactNode
  rewardsCol: React.ReactNode
  stakedCol: React.ReactNode
  stakedPercentageCol: React.ReactNode
  actionsCol: React.ReactNode
}

export function MyStakingListItemLayout({
  assetCol,
  apyCol,
  balanceCol,
  rewardsCol,
  stakedCol,
  stakedPercentageCol,
  actionsCol,
}: AccountListItemLayoutProps) {
  return (
    <ListLayoutWrapper>
      <Grid container alignItems="center">
        <Grid item xs={4} sm={2}>
          <Aligner align="left">{assetCol}</Aligner>
        </Grid>
        <Grid item xs={2} md={1}>
          <Aligner align="right">{apyCol}</Aligner>
        </Grid>
        <Grid item xs={2}>
          <Aligner align="right">{balanceCol}</Aligner>
        </Grid>
        <Grid item xs={2}>
          <Aligner align="right">{rewardsCol}</Aligner>
        </Grid>
        <HidableGridItem sm={2} display={{xs: 'none', sm: 'block'}}>
          <Aligner align="right">{stakedCol}</Aligner>
        </HidableGridItem>
        <HidableGridItem md={1} display={{xs: 'none', md: 'block'}}>
          <Aligner align="right">{stakedPercentageCol}</Aligner>
        </HidableGridItem>
        <Grid item xs={2}>
          <Aligner align="right">{actionsCol}</Aligner>
        </Grid>
      </Grid>
    </ListLayoutWrapper>
  )
}

const useStyles = makeStyles(() => ({
  myStakingButton: {
    minWidth: 140,
    height: 40,
  },
}))
