import {Box} from '@mui/material'
import type BigNumber from 'bignumber.js'
import React from 'react'

import type {TokenBlockchain} from 'src/blockchainTypes'
import type {TokenId} from 'src/wallet'
import type {ExternalReceiversInfo} from 'src/wallet/dappConnector/types'

import type {TxAccordionRowProps} from './common'
import {TxAccordionRow} from './common'
import {ExternalReceivers} from './ExternalReceivers'
import {NativeMovementRow} from './NativeMovementRow'
import {Tags} from './Tags'
import {TokenMovementRow} from './TokenMovementRow'
import type {TokenMovementProps} from './TokenMovementRow'

type TxDetailProps<DTokenId extends TokenId = TokenId> = {
  nativeDiff: BigNumber | null
  fee: BigNumber | null
  blockchain: TokenBlockchain
  externalReceiversInfo?: ExternalReceiversInfo<DTokenId>
  tags?: string[]
  customBottomRows?: TxAccordionRowProps[]
  tokensMovement?: Pick<
    TokenMovementProps,
    | 'getTokenInfoItems'
    | 'receivingTokensCount'
    | 'sendingTokensCount'
    | 'tokensDiff'
    | 'getExtraAssetContent'
  >
}

export const TxDetail = ({
  nativeDiff,
  fee,
  blockchain,
  externalReceiversInfo,
  tags,
  customBottomRows,
  tokensMovement,
}: TxDetailProps) => {
  return (
    <Box px={2}>
      <div>
        {(nativeDiff || fee) && (
          <NativeMovementRow
            amount={nativeDiff}
            fee={fee}
            blockchain={blockchain}
          />
        )}

        {tokensMovement && tokensMovement.tokensDiff.length > 0 && (
          <TokenMovementRow
            tokensDiff={tokensMovement.tokensDiff}
            blockchain={blockchain}
            getTokenInfoItems={tokensMovement.getTokenInfoItems}
            receivingTokensCount={tokensMovement.receivingTokensCount}
            sendingTokensCount={tokensMovement.sendingTokensCount}
            getExtraAssetContent={tokensMovement.getExtraAssetContent}
          />
        )}
        {externalReceiversInfo &&
          Object.keys(externalReceiversInfo).length > 0 && (
            <ExternalReceivers
              externalReceiversInfo={externalReceiversInfo}
              blockchain={blockchain}
            />
          )}

        {tags && tags.length > 0 && <Tags tags={tags} />}
        {customBottomRows?.map((rowData) => <TxAccordionRow {...rowData} />)}
      </div>
    </Box>
  )
}
