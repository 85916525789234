import type {
  CircularProgressProps,
  SxProps,
  Theme,
  TypographyProps,
} from '@mui/material'
import {
  Backdrop,
  Box,
  CircularProgress,
  Typography,
  useTheme,
  styled,
} from '@mui/material'
import React from 'react'
import ContentLoader from 'react-content-loader'

import {ContentLoaderBackground} from '../../utils/layoutUtils'

type FullScreenLoadingProps = {
  description?: string
  hideLoader?: boolean
  backdropSx?: SxProps<Theme>
}

export const FullScreenLoading = ({
  description,
  hideLoader = false,
  backdropSx,
}: FullScreenLoadingProps) => {
  return (
    <FullScreenLoadingBackdrop open sx={backdropSx}>
      {!hideLoader ? (
        <Box display="flex" flexDirection="column" alignItems="center">
          <CircularProgress color="inherit" />
          {description && (
            <Box mt={2}>
              <Typography variant="body2">{`${description}…`}</Typography>
            </Box>
          )}
        </Box>
      ) : null}
    </FullScreenLoadingBackdrop>
  )
}

const FullScreenLoadingBackdrop = styled(Backdrop)(({theme}) => ({
  zIndex: theme.zIndex.drawer + 1,
  color: '#fff',
}))

type InlineLoadingProps = {
  size?: number | string
  color?: CircularProgressProps['color']
}

export const InlineLoading = ({size, color}: InlineLoadingProps) => {
  size = size || 26
  return <CircularProgress {...{size, color}} />
}

export function LoadingWithLabel({
  progress,
  content,
  sx,
}: {
  progress: number
  content: React.ReactNode
  sx?: SxProps<Theme>
}) {
  return (
    <Box sx={{position: 'relative', display: 'inline-flex', ...sx}}>
      <CircularProgress variant="determinate" value={progress} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {content}
        </Typography>
      </Box>
    </Box>
  )
}

type LoaderDims<T> = {height?: T; width?: T}

export const CircleLoader = ({height = 16, width = 16}: LoaderDims<number>) => (
  <ContentLoader
    width={width}
    height={height}
    backgroundColor={ContentLoaderBackground()}
  >
    <circle x="0" y="0" cx={width / 2} cy={height / 2} r={width / 2} />
  </ContentLoader>
)
type TypographyLoaderProps = {
  variant?: TypographyProps['variant']
} & LoaderDims<number | string>

export const TypographyLoader = ({
  variant,
  height,
  width = 60,
}: TypographyLoaderProps) => {
  const theme = useTheme()
  const {fontSize, lineHeight} =
    theme.typography[!variant || variant === 'inherit' ? 'body1' : variant]
  return (
    <ContentLoader
      style={{lineHeight, display: 'inline-block', verticalAlign: 'middle'}}
      width={width}
      height={height || fontSize}
      backgroundColor={ContentLoaderBackground()}
    >
      <rect x="0" y="0" rx="8" width="100%" height={height || fontSize} />
    </ContentLoader>
  )
}
