import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'

import {TermsAndConditionsLink} from '../../components'

type Props = {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  value: boolean
  helperText: React.ReactNode
  error: boolean
  dataTestId?: string
}

export const TermsAndConditionsCheckbox = ({
  onChange,
  value,
  helperText,
  error,
  dataTestId,
}: Props) => {
  const classes = useStyles()
  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox
            data-test-id={dataTestId || 'terms-and-conditions-checkbox'}
            rtl-data-test-id={dataTestId || 'terms-and-conditions-checkbox'}
            value={value}
            color="primary"
            onChange={onChange}
          />
        }
        label={<TermsAndConditionsLink />}
      />
      {error && (
        <FormHelperText className={classes.error}>{helperText}</FormHelperText>
      )}
    </FormGroup>
  )
}

const useStyles = makeStyles((theme) => ({
  error: {
    color: theme.palette.error.main,
  },
}))
