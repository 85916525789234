import {Box, Typography} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import type Bignumber from 'bignumber.js'
import type {FormikProps} from 'formik'
import React from 'react'
import {useTranslation} from 'react-i18next'

import type {StakingBlockchain} from '../../../../blockchainTypes'
import {
  ModalLayout,
  ModalFooter,
  FooterLayout,
  Alert,
  useModalSharedStyles,
  AmountRow,
  MultilineString,
  CurrentPasswordField,
} from '../../../../components'
import {useIsPasswordVerificationRequired} from '../../../../store/auth'
import {getHasFormError} from '../../../../utils/form'
import type {AccountInfo} from '../../../../wallet'
import {TxBodyLayout} from '../../../transaction/common'
import {UndelegateModalHeader} from '../../common/utils'
import type {BaseUndelegateStakeSchema} from '../schema'

type SummaryScreenProps<T extends BaseUndelegateStakeSchema> = {
  blockchain: StakingBlockchain
  onClose: () => void
  onBack: () => unknown
  onSubmit: () => unknown
  summary: {
    amount: Bignumber
    fee: Bignumber
    fromAccount: AccountInfo
    validatorName: string
  }
  formikProps: FormikProps<T>
}

const FORM_ID = 'undelegate-stake-form-confirm-details'

export default function SummaryScreen<T extends BaseUndelegateStakeSchema>({
  onBack,
  onSubmit,
  blockchain,
  onClose,
  summary,
  formikProps,
}: SummaryScreenProps<T>) {
  const {t} = useTranslation()
  const classes = {...useStyles(), ...useModalSharedStyles()}
  const {errors} = formikProps
  const hasImmediateError = getHasFormError(formikProps, 'immediate')
  const isPasswordVerificationRequired = useIsPasswordVerificationRequired(
    summary.fromAccount,
  )

  return (
    <form
      onSubmit={onSubmit}
      noValidate
      id={FORM_ID}
      className={classes.formWrapper}
    >
      <ModalLayout
        header={<UndelegateModalHeader {...{onClose, blockchain}} />}
        body={
          <Box className={classes.centeringWrapper}>
            <Box p={2}>
              <Alert
                text={t(
                  'Please note that if you stop staking, you will no longer be able to receive rewards. Account deactivation takes up to 2 days. After deactivating your account, you will be able to withdraw funds.',
                )}
                severity="warning"
              />
            </Box>
            <UndelegationDetails {...summary} />
          </Box>
        }
        footer={
          <ModalFooter hasDivider>
            {hasImmediateError('hasSufficientFeeFunds') && (
              <Box mb={2}>
                <Alert
                  severity="error"
                  text={errors.hasSufficientFeeFunds as string}
                />
              </Box>
            )}
            {isPasswordVerificationRequired && (
              <CurrentPasswordField
                {...{formikProps}}
                className={classes.passwordHeight}
              />
            )}
            <FooterLayout
              leftBtnConf={{
                onClick: onBack,
                children: t('Back'),
              }}
              rightBtnConf={{
                children: t('Stop staking'),
                type: 'submit',
                form: FORM_ID,
                disabled: formikProps.isSubmitting || !formikProps.isValid,
                className: classes.warningButton,
              }}
            />
          </ModalFooter>
        }
      />
    </form>
  )
}

function UndelegationDetails({
  validatorName, // TODO: later handle actual validator names, icons and urls
  amount,
  fee,
}: {
  validatorName: string
  amount: Bignumber
  fee: Bignumber
}) {
  const {t} = useTranslation()

  return (
    <TxBodyLayout centered fullWidth>
      <Box boxShadow={1} bgcolor="background.default" p={3}>
        <Box fontWeight={600} fontSize="h6.fontSize" mb={2}>{`${t(
          'You are stopping staking from',
        )}`}</Box>
        <Box
          width="100%"
          display="flex"
          flexWrap="wrap"
          justifyContent="space-between"
        >
          <Typography color="textSecondary">{`${t('Validator')}`}</Typography>
          <MultilineString label={validatorName} />
        </Box>
        <AmountRow
          label={t('Staking Balance')}
          amount={amount}
          blockchain="solana"
        />
        <AmountRow
          label={t('Transaction fee')}
          amount={fee}
          blockchain="solana"
        />
      </Box>
    </TxBodyLayout>
  )
}

const useStyles = makeStyles((theme) => ({
  centeringWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  warningButton: {
    backgroundColor: theme.palette.warning.main,
  },
}))
