import _ from 'lodash'
import {useCallback, useEffect} from 'react'

import type {ModifierKey} from '../constants'

// array of input types that are safe to fire shortcut from
const SHORTCUT_SAFE_INPUT_TYPES: HTMLInputElement['type'][] = [
  'button',
  'checkbox',
  'file',
  'radio',
  'range',
  'reset',
  'submit',
]

export const useKeyboardShortcut = (
  shortcut: {
    modifier: ModifierKey
    code: string
    key: string
  },
  callback: () => void,
  throttleTimeout: number | null = 500,
) => {
  const onKeyDown = useCallback(
    _.throttle(
      (evt: KeyboardEvent) => {
        // prevent shortcut firing when triggered from input field
        const nodeName = (
          evt.target as HTMLElement
        )?.nodeName.toLocaleLowerCase()
        if (
          /textarea|select/i.test(nodeName) ||
          (/input/i.test(nodeName) &&
            !SHORTCUT_SAFE_INPUT_TYPES.includes(
              (evt.target as HTMLInputElement).type,
            ))
        ) {
          return
        }
        if (
          evt.getModifierState?.(shortcut.modifier) &&
          evt.code === shortcut.code
        ) {
          callback()
        }
      },
      throttleTimeout || undefined,
      {leading: true},
    ),
    [callback],
  )

  useEffect(() => {
    window.addEventListener('keydown', onKeyDown)
    return () => {
      window.removeEventListener('keydown', onKeyDown)
    }
  })
}
