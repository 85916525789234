import {useTransactionState} from '..'
import type {TransactionInfo} from '../types'

export const updateTransactionState = async ({
  origTx,
  updatedTx,
}: {
  origTx: TransactionInfo
  updatedTx: TransactionInfo
}) => {
  const {updateTransaction, transactions} = useTransactionState.getState()

  const origTxCurrentState = transactions.find(
    (t) => t.customId === origTx.customId,
  )
  // This check is here because transactions can also be updated by react-query observer.
  // and so we skip the current periodic update if the state has changed
  // in the meantime in order to not overwrite the ongoing react-query observer update.
  // Remove it if we decide to cancel tx observation by react-query.
  // Until then, it prevents cases like these:
  // 1. Periodic observer starts to run, and we fetch current transactions.
  // 2. React-query updates one of the transactions in some way.
  // 3. Periodic observer does not notice this change, or notice another change, resulting
  // in update from react-query being lost.
  if (JSON.stringify(origTx) !== JSON.stringify(origTxCurrentState)) return

  // Avoid state update if nothing really changed
  if (JSON.stringify(origTx) !== JSON.stringify(updatedTx)) {
    updateTransaction(updatedTx)
  }
}
