import {Grid} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {
  Aligner,
  NoOverflowAligner,
  ListHeaderTitle,
  ListLayoutWrapper,
} from '../../../components'

type EvmAccountListHeaderProps = {
  titleColText?: string
  blockchainsColText?: string
  actionsColText?: string
}

export function EvmAccountListHeader({
  titleColText,
  blockchainsColText,
  actionsColText,
}: EvmAccountListHeaderProps) {
  const {t} = useTranslation()

  return (
    <EvmAccountListItemLayout
      titleCol={
        <ListHeaderTitle>{titleColText || t('Account')}</ListHeaderTitle>
      }
      blockchainsCol={
        <ListHeaderTitle>
          {blockchainsColText || t('EVM Blockchains')}
        </ListHeaderTitle>
      }
      actionsCol={
        <ListHeaderTitle>{actionsColText || t('Actions')}</ListHeaderTitle>
      }
    />
  )
}

type EvmAccountListItemLayoutProps = {
  titleCol: React.ReactNode
  blockchainsCol: React.ReactNode
  actionsCol: React.ReactNode
}

export function EvmAccountListItemLayout({
  titleCol,
  blockchainsCol,
  actionsCol,
}: EvmAccountListItemLayoutProps) {
  return (
    <ListLayoutWrapper>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item xs={3}>
          <NoOverflowAligner align="left">{titleCol}</NoOverflowAligner>
        </Grid>
        <Grid item xs={7}>
          <Aligner align="left">{blockchainsCol}</Aligner>
        </Grid>
        <Grid item xs={2} md={2}>
          <Aligner align="right">{actionsCol}</Aligner>
        </Grid>
      </Grid>
    </ListLayoutWrapper>
  )
}
