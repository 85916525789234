import {ArrowBack as GoBackIcon} from '@mui/icons-material'
import {Typography} from '@mui/material'
import {Sentry} from '@nufi/frontend-common'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {useAppOpenerStore} from 'src/features/appOpener/application'

import {Button} from '../../../components'
import {assert} from '../../../utils/assertion'
import type {CrossAppMessage} from '../../../webextension/dappConnector/messaging/types'

type ReferrerMeta = {
  favIconUrl: string
  origin: string
  lastFocusedWindowId: number
  senderTabId: number
}

export function BackToDappNavigation() {
  const {referrerTabId, clearReferrerTabId} = useAppOpenerStore()
  return referrerTabId != null ? (
    <BackToDappNavigationContent
      {...{referrerTabId}}
      onClearReferrerTabId={clearReferrerTabId}
    />
  ) : null
}

type BackToDappNavigationContentProps = {
  referrerTabId: number
  onClearReferrerTabId: () => void
}

function BackToDappNavigationContent({
  referrerTabId,
  onClearReferrerTabId,
}: BackToDappNavigationContentProps) {
  const {t} = useTranslation()
  const [referrerMeta, setReferrerMeta] = useState<ReferrerMeta | null>(null)

  useEffect(() => {
    // Move into service if becomes more complex or a need to test arises
    chrome.runtime
      .sendMessage<CrossAppMessage>({
        isCrossAppMessage: true,
        senderContext: 'app',
        targetContext: 'connectorWindow',
        target: {tabId: referrerTabId},
        method: 'getDappMeta',
        type: 'cross-app-request',
        args: [],
      })
      .then(async (response: ReferrerMeta) => {
        assert(response != null)
        setReferrerMeta(response)
      })
      .catch((err) => {
        // not critical functionality so we just display nothing
        // and log to sentry
        Sentry.captureException(err)
      })
  }, [referrerTabId])

  const onClick = async () => {
    if (!referrerMeta) {
      onClearReferrerTabId()
      return
    }

    try {
      // Move into service if becomes more complex or a need to test arises
      const dappTab = await chrome.tabs.get(referrerMeta.senderTabId)

      assert(dappTab.url != null)
      const domain = new URL(dappTab.url)
      assert(domain.origin === referrerMeta.origin)

      // focus dapp
      await chrome.windows.update(referrerMeta.lastFocusedWindowId, {
        focused: true,
      })
      await chrome.tabs.update(referrerMeta.senderTabId, {active: true})

      // focus connector
      const connectorTab = await chrome.tabs.get(referrerTabId)
      await chrome.windows.update(connectorTab.windowId, {
        focused: true,
      })
    } catch (err) {
      // not critical functionality so we just log to sentry
      Sentry.captureException(err)
    } finally {
      onClearReferrerTabId()
    }
  }

  return referrerMeta ? (
    <Button
      color="primary"
      variant="outlined"
      textTransform="none"
      startIcon={<GoBackIcon color="primary" />}
      onClick={onClick}
    >
      <>
        <Typography mr={2}>{t('Back to')}</Typography>
        <img width={20} height={20} src={referrerMeta.favIconUrl} />
        <Typography ml={1}>({referrerMeta.origin})</Typography>
      </>
    </Button>
  ) : null
}
