import {
  getSecretProviderFromIdentity,
  getSecretProviderFromMnemonic,
} from '@nufi/wallet-common'
import type {IdentitySecret} from '@nufi/wallet-common'

import config from 'src/config'
import {createAuthTokenManager} from 'src/features/nufiAuth/application'
import {getNufiAuthSecretFromSecretProvider} from 'src/features/nufiAuth/domain'
import {makeAuthenticatedRequest} from 'src/features/nufiAuth/infrastructure'
import type {Mnemonic} from 'src/types'

import {RemoteProfileStorageApi} from '../infrastructure/remoteProfileStorageApi'

export type HasCloudSyncBackupParams =
  | {mnemonic: Mnemonic}
  | {identitySecret: IdentitySecret}

export const hasCloudSyncBackup = async (param: HasCloudSyncBackupParams) => {
  if (!config.isCloudSyncEnabled) {
    return false
  }

  const secretProvider =
    'mnemonic' in param
      ? await getSecretProviderFromMnemonic(param.mnemonic)
      : getSecretProviderFromIdentity(param.identitySecret)

  const remoteProfileStorage = new RemoteProfileStorageApi(
    config.backendUrl,
    makeAuthenticatedRequest(
      createAuthTokenManager(() =>
        getNufiAuthSecretFromSecretProvider(secretProvider),
      ),
    ),
  )

  const cloudSyncBackup = await remoteProfileStorage.getProfile()

  // TODO we should additionally check for whether the cloud sync backup
  // is usable in this instance of the app (e.g. backup may have newer schema version
  // or be somehow corrupted)

  return cloudSyncBackup != null
}
