/* eslint-disable @typescript-eslint/explicit-function-return-type */

import {
  filterSupportedTokenContractsForCryptoProvider,
  getFlowContractId,
} from '@nufi/wallet-flow'
import type {FlowAddress, FlowTokenContractMetadata} from '@nufi/wallet-flow'

import {fetchNeverStaleQuery, useNeverStaleQuery} from 'src/utils/query-utils'

import type {CryptoProviderType} from '../../../../types'
import {flow} from '../../../flowManagers'

import {cachedGetSupportedTokenContracts} from './contractMetadata'
import {tokensQueryKeys} from './queryKeys'

//
// __CACHED__ QUERIES
//

type IsTokenSetupForAddressArgs = {
  contractMetadata: FlowTokenContractMetadata
  address: FlowAddress
  mustBeFullySetUp: boolean
}

async function cachedGetNftCollectionsSetupInfoForAccount(
  address: FlowAddress,
) {
  return fetchNeverStaleQuery({
    queryKey: tokensQueryKeys.nftCollectionsSetupInfoForAccount(address),
    queryFn: () => flow.accountManager.getNftCollectionsSetupInfo(address),
  })
}

const getIsTokenSetupForAddress =
  (args: IsTokenSetupForAddressArgs) => async () => {
    const accountNftCollectionSetupInfo =
      await cachedGetNftCollectionsSetupInfoForAccount(args.address)

    const setupInfo =
      accountNftCollectionSetupInfo[
        getFlowContractId(
          args.contractMetadata.contractAddress,
          args.contractMetadata.contractName,
        )
      ]
    if (setupInfo) {
      return args.mustBeFullySetUp
        ? setupInfo.isFullySetup
        : setupInfo.isReceivable
    }

    return flow.accountManager.isTokenSetupForAddress(
      args.contractMetadata,
      args.address,
      args.mustBeFullySetUp,
    )
  }

getIsTokenSetupForAddress.__key = tokensQueryKeys.isTokenSetupForAddress

export async function cachedGetIsTokenSetupForAddress(
  args: IsTokenSetupForAddressArgs,
) {
  return fetchNeverStaleQuery({
    queryKey: getIsTokenSetupForAddress.__key(
      args.contractMetadata,
      args.address,
      args.mustBeFullySetUp,
    ),
    queryFn: getIsTokenSetupForAddress(args),
  })
}

export function useGetIsTokenSetupForAddress(
  args: IsTokenSetupForAddressArgs,
  enabled = true,
) {
  return useNeverStaleQuery({
    queryKey: getIsTokenSetupForAddress.__key(
      args.contractMetadata,
      args.address,
      args.mustBeFullySetUp,
    ),
    queryFn: getIsTokenSetupForAddress(args),

    enabled,
  })
}

export function useGetNotSetupTokensForAccount(
  args: {address: FlowAddress; cryptoProviderType: CryptoProviderType},
  enabled = true,
) {
  return useNeverStaleQuery({
    queryKey: tokensQueryKeys.setupTokensContractForAccount(args.address),
    queryFn: async () => {
      const supportedTokenContracts = await cachedGetSupportedTokenContracts()
      return (
        await Promise.all(
          filterSupportedTokenContractsForCryptoProvider(
            supportedTokenContracts,
            args.cryptoProviderType,
          ).map(async (contractMetadata) => {
            const isFullySetup = await cachedGetIsTokenSetupForAddress({
              address: args.address,
              contractMetadata,
              mustBeFullySetUp: true,
            })
            return {
              contractMetadata,
              isFullySetup,
            }
          }),
        )
      )
        .filter((tc) => !tc.isFullySetup)
        .map((tc) => tc.contractMetadata)
    },
    enabled,
  })
}

//
// __COMPUTED__ QUERIES
//
