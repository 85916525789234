import {
  Launch as ExternalIcon,
  ConfirmationNumber as ConfirmationIcon,
  Loop as RefundIcon,
  PanTool as OnHoldIcon,
  Timer as ExpiredIcon,
  HelpOutline as HelpIcon,
  ErrorOutlineOutlined,
} from '@mui/icons-material'
import {
  Typography,
  Grid,
  Link,
  Stepper,
  Step,
  StepLabel,
  Box,
  Paper,
  Alert,
  Divider,
  Chip,
  Tooltip,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import BigNumber from 'bignumber.js'
import clsx from 'clsx'
import type {Swap, SwapAsset, SwapStatus} from 'common'
import {QRCodeSVG} from 'qrcode.react'
import React from 'react'
import type {PropsWithChildren} from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'

import type {useExchangeCreateSwap} from 'src/features/exchange/application'
import {
  getExchangeAssetDetailOrFallback,
  useGetExchangeAssetsDetails,
} from 'src/features/exchange/application'
import type {ExchangeAssetsDetails} from 'src/features/exchange/domain'
import type {useSaveSwap} from 'src/features/swaps/application'
import {useGetSwap} from 'src/features/swaps/application'

import type {ExchangeBlockchain} from '../../../../blockchainTypes'
import {isExchangeBlockchain} from '../../../../blockchainTypes'
import {
  MutationGuard,
  ModalLayout,
  ModalFooter,
  FooterLayout,
  QueryGuard,
  Icon,
  CopyToClipboard,
  StringEllipsis,
  useCommonStyles,
  TransactionExplorerLink,
  ExplorerLink,
  LabeledIcon,
  WithTooltip,
  StatusIcons,
} from '../../../../components'
import {routeTo} from '../../../../router'
import {useGetAccounts} from '../../../../wallet'
import type {AccountId, AccountInfo} from '../../../../wallet'
import {findAccountByAddress} from '../../../../wallet/utils/common'
import {ExchangeModalHeader, WithExchangeAssetsQueries} from '../../components'
import {CHANGELLY_URL, EXCHANGE_MODAL_WIDTH} from '../../constants'
import {ExchangeConfContextProvider} from '../../ExchangeConfContext'
import {EXCHANGE_CONFIG} from '../../exchangeConfig'
import type {ExchangeDepsConfig} from '../../screens/common'
import {SwapError} from '../../SwapError'
import {FormattedAmount} from '../../utils'

type SwapResultScreenProps = {
  onClose: () => void
  onBack: () => void
  onReset: () => void
  createSwapMutation: ReturnType<typeof useExchangeCreateSwap>
  saveSwapMutation: ReturnType<typeof useSaveSwap>
}

export function SwapResultScreen({
  onClose,
  onBack,
  onReset,
  createSwapMutation,
  saveSwapMutation,
}: SwapResultScreenProps) {
  const {t} = useTranslation()

  return (
    <ModalLayout
      header={
        <ExchangeModalHeader onClose={onClose} title={t('Exchange detail')} />
      }
      body={
        <MutationGuard
          {...createSwapMutation}
          ErrorElement={
            <SwapError error={createSwapMutation.error} isPartnerError />
          }
        >
          <MutationGuard
            {...saveSwapMutation}
            ErrorElement={<SwapError error={saveSwapMutation.error} />}
          >
            {({id}) => <SwapDetail id={id} />}
          </MutationGuard>
        </MutationGuard>
      }
      footer={
        <ModalFooter hasDivider>
          {(() => {
            const isSuccess =
              !createSwapMutation.isPending &&
              !createSwapMutation.isError &&
              !!createSwapMutation.data
            return (
              <FooterLayout
                leftBtnConf={{
                  onClick: isSuccess ? onReset : onBack,
                  children: isSuccess ? t('New exchange') : t('Back'),
                }}
                rightBtnConf={{
                  onClick: onClose,
                  children: t('Close'),
                }}
              />
            )
          })()}
        </ModalFooter>
      }
    />
  )
}

type SwapDetailProps = {
  id: string
}

function SwapDetail({id}: SwapDetailProps) {
  const {t} = useTranslation()
  const swapQuery = useGetSwap(id)
  return (
    <QueryGuard
      {...swapQuery}
      loadingVariant="centered"
      ErrorElement={
        <SwapError
          error={swapQuery.error}
          errorMessage={t('Could not load the exchange detail')}
        />
      }
    >
      {(swap) => <SwapSuccess {...swap} />}
    </QueryGuard>
  )
}

type SuccessSwapStatus = Extract<
  SwapStatus,
  'waiting' | 'confirming' | 'exchanging' | 'sending' | 'finished'
>

const {ErrorIcon, SuccessIcon, WaitingIcon} = StatusIcons

const useSuccessStatusesConfig = (
  isFromExternalAddress: boolean,
  currencyFrom: string,
  currencyTo: string,
  amount: string,
) => {
  const {t} = useTranslation()
  const waitingTexts = isFromExternalAddress
    ? {
        title: t('SwapStatus.WAITING_EXTERNAL.title'),
        stepLabel: t('SwapStatus.WAITING_EXTERNAL.stepLabel'),
        description: t('SwapStatus.WAITING_EXTERNAL.description', {
          amount,
          currencyFrom,
        }),
      }
    : {
        title: t('SwapStatus.WAITING_INTERNAL.title'),
        stepLabel: t('SwapStatus.WAITING_INTERNAL.stepLabel'),
        description: t('SwapStatus.WAITING_INTERNAL.description', {
          amount,
          currencyFrom,
        }),
      }
  return {
    waiting: {value: 0, ...waitingTexts, icon: <WaitingIcon />},
    confirming: {
      value: 1,
      title: t('SwapStatus.CONFIRMING.title'),
      stepLabel: t('SwapStatus.CONFIRMING.stepLabel'),
      description: t('SwapStatus.CONFIRMING.description'),
      icon: <ConfirmationIcon />,
    },
    exchanging: {
      value: 2,
      title: t('SwapStatus.EXCHANGING.title'),
      stepLabel: t('SwapStatus.EXCHANGING.stepLabel'),
      description: t('SwapStatus.EXCHANGING.description', {
        currencyFrom,
        currencyTo,
      }),
      icon: <Icon type="exchangeIcon" />,
    },
    sending: {
      value: 3,
      title: t('SwapStatus.SENDING.title'),
      stepLabel: t('SwapStatus.SENDING.stepLabel'),
      description: t('SwapStatus.SENDING.description', {currencyTo}),
      icon: <Icon type="sendIcon" />,
    },
    finished: {
      value: 4,
      title: t('SwapStatus.FINISHED.title'),
      stepLabel: t('SwapStatus.FINISHED.stepLabel'),
      description: t('SwapStatus.FINISHED.description', {currencyTo}),
      icon: <SuccessIcon />,
    },
  }
}

type OtherSwapStatus = Extract<
  SwapStatus,
  'failed' | 'refunded' | 'hold' | 'expired' | 'overdue' | 'waiting_expired'
>

const useOtherStatusesConfig = (partnerSwapId: string) => {
  const {t} = useTranslation()
  return {
    failed: {
      title: t('SwapStatus.FAILED.title'),
      description: (
        <Trans
          i18nKey="SwapStatus.FAILED.description"
          t={t}
          components={{
            ChangellyLink: (
              <Link
                href={`${CHANGELLY_URL}/transaction/${partnerSwapId}`}
                target="_blank"
                rel="noopener"
              />
            ),
          }}
        />
      ),
      icon: <ErrorIcon />,
    },
    refunded: {
      title: t('SwapStatus.REFUNDED.title'),
      description: t('SwapStatus.REFUNDED.description'),
      icon: <RefundIcon />,
    },
    hold: {
      title: t('SwapStatus.HOLD.title'),
      description: (
        <Trans
          i18nKey="SwapStatus.HOLD.description"
          t={t}
          components={{
            EmailLink: <Link href="mailto:security@changelly.com" />,
          }}
        />
      ),
      icon: <OnHoldIcon />,
    },
    expired: {
      title: t('SwapStatus.EXPIRED.title'),
      description: t('SwapStatus.EXPIRED.description'),
      icon: <ExpiredIcon />,
    },
    waiting_expired: {
      title: t('SwapStatus.EXPIRED.title'),
      description: t('SwapStatus.EXPIRED.description'),
      icon: <ExpiredIcon />,
    },
    overdue: {
      title: t('SwapStatus.EXPIRED.title'),
      description: t('SwapStatus.EXPIRED.description'),
      icon: <ExpiredIcon />,
    },
  }
}

const Address = ({address}: {address: string}) => (
  <StringEllipsis value={address} length={7} maxWidth={250} />
)

const HeaderAddress = ({address}: {address: string}) => (
  <StringEllipsis value={address} length={7} maxWidth={170} />
)

type SwapSuccessProps = Swap

export const SwapSuccess = (swap: SwapSuccessProps) => (
  <_SwapSuccess {...swap} exchangeConf={EXCHANGE_CONFIG} />
)

export function _SwapSuccess({
  exchangeConf,
  ...swap
}: SwapSuccessProps & {exchangeConf: ExchangeDepsConfig}) {
  const {
    partnerSwapId,
    currencyFrom,
    currencyTo,
    status,
    amountExpectedFrom,
    nufiAddressFrom,
    payinAddress,
  } = swap
  const isFromExternalAddress = !nufiAddressFrom
  const classes = {...useStyles(), ...useCommonStyles()}
  const {t} = useTranslation()
  const exchangeAssetsDetailsQuery = useGetExchangeAssetsDetails()
  const successStatusesConfig = useSuccessStatusesConfig(
    isFromExternalAddress,
    currencyFrom,
    currencyTo,
    amountExpectedFrom,
  )
  const otherStatusesConfig = useOtherStatusesConfig(partnerSwapId)

  const isSuccessStatus = status in successStatusesConfig
  const isOtherStatus = status in otherStatusesConfig
  const statusConfig = isSuccessStatus
    ? successStatusesConfig[status as SuccessSwapStatus]
    : isOtherStatus
      ? otherStatusesConfig[status as OtherSwapStatus]
      : undefined

  return (
    <Grid container className={classes.content}>
      <Grid item container direction="column" spacing={4}>
        {statusConfig == null ? (
          <Grid item mt={1}>
            <Status
              title={t('SwapStatus.UNEXPECTED.title')}
              description={
                <Trans
                  i18nKey="SwapStatus.UNEXPECTED.description"
                  t={t}
                  components={{
                    ChangellyLink: (
                      <Link
                        href={`${CHANGELLY_URL}/transaction/${partnerSwapId}`}
                        target="_blank"
                        rel="noopener"
                      />
                    ),
                  }}
                />
              }
              icon={<ErrorIcon />}
            />
          </Grid>
        ) : (
          <>
            {isSuccessStatus && (
              <Grid item className={classes.stepper}>
                <Stepper
                  activeStep={
                    successStatusesConfig[status as SuccessSwapStatus].value
                  }
                >
                  {Object.values(successStatusesConfig).map((step) => (
                    <Step key={step.stepLabel}>
                      <StepLabel>{step.stepLabel}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Grid>
            )}
            <Grid item mt={1}>
              <Status
                title={statusConfig.title}
                description={statusConfig.description}
                icon={statusConfig.icon}
              >
                {isFromExternalAddress && status === 'waiting' && (
                  <Grid
                    container
                    direction="column"
                    alignItems="center"
                    spacing={2}
                  >
                    {swap.payinExtraIdName && (
                      <>
                        <Grid item>
                          <Alert
                            severity="warning"
                            icon={false}
                            classes={{message: classes.alert}}
                          >
                            <Trans
                              i18nKey="extra_id_alert_mandatory"
                              t={t}
                              values={{extraIdName: swap.payinExtraIdName}}
                              components={{
                                bold: (
                                  <Box
                                    component="span"
                                    fontWeight="fontWeightMedium"
                                  />
                                ),
                              }}
                            />
                            <Grid
                              item
                              container
                              mb={-1}
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Grid item>
                                <Grid container spacing={1} alignItems="center">
                                  <Grid item>
                                    <Typography color="textSecondary">
                                      {swap.payoutExtraIdName}:
                                    </Typography>
                                  </Grid>
                                  <Grid item display="flex">
                                    <HeaderAddress
                                      address={swap.payoutExtraId || '-'}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                              {swap.payoutExtraId && (
                                <Grid item className={classes.icon}>
                                  <CopyToClipboard value={swap.payoutExtraId} />
                                </Grid>
                              )}
                            </Grid>
                          </Alert>
                        </Grid>
                      </>
                    )}
                    <Grid item>
                      <PayinAddress address={payinAddress} />
                    </Grid>
                  </Grid>
                )}
              </Status>
            </Grid>
            <Grid item className={classes.divider}>
              <Divider orientation="horizontal" />
            </Grid>
            <Grid item>
              <ExchangeConfContextProvider exchangeConf={exchangeConf}>
                <QueryGuard
                  {...exchangeAssetsDetailsQuery}
                  loadingVariant="centered"
                >
                  {(exchangeAssetsDetails) => (
                    <WithExchangeAssetsQueries
                      fromAsset={currencyFrom}
                      toAsset={currencyTo}
                      {...{exchangeAssetsDetails}}
                      vm={{
                        useGetAccounts,
                      }}
                      childrenPropsType="guardedData"
                    >
                      {({from, to}) => (
                        <ExchangeDetails
                          {...swap}
                          exchangeAssetsDetails={exchangeAssetsDetails}
                          toAccounts={to.accounts}
                          fromAccounts={from.accounts}
                        />
                      )}
                    </WithExchangeAssetsQueries>
                  )}
                </QueryGuard>
              </ExchangeConfContextProvider>
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  )
}

type StatusScreenProps = PropsWithChildren<{
  title: string
  description: React.ReactNode
  icon: JSX.Element
}>

function Status({title, description, icon, children}: StatusScreenProps) {
  const classes = {...useStyles(), ...useCommonStyles()}

  return (
    <Grid item container direction="column" alignItems="center">
      <Grid item>
        <LabeledIcon
          Label={<Typography variant="h5">{title}</Typography>}
          Icon={icon}
        />
      </Grid>
      <Grid item mt={2} width={EXCHANGE_MODAL_WIDTH / 2}>
        <Typography align="center">{description}</Typography>
      </Grid>
      <Grid>
        {children && (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            className={classes.statusContent}
          >
            {children}
          </Box>
        )}
      </Grid>
    </Grid>
  )
}

function PayinAddress({address}: {address: string}) {
  const {t} = useTranslation()
  const classes = {...useStyles(), ...useCommonStyles()}
  const qrCodeSize = 184
  return (
    <Grid container direction="column" alignItems="center" spacing={2}>
      <Grid item xs={12}>
        <Paper elevation={0} className={classes.infoPaper}>
          <Grid item container alignItems="center">
            <Grid item>
              <Grid container spacing={1}>
                <Grid item>
                  <Typography color="textSecondary">
                    {t('Payin address')}:
                  </Typography>
                </Grid>
                <Grid item display="flex">
                  <HeaderAddress {...{address}} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item className={classes.icon}>
              <CopyToClipboard value={address} />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item>
        <QRCodeSVG value={address} size={qrCodeSize} includeMargin />
      </Grid>
    </Grid>
  )
}

function AssetDetails({
  assetDetails,
  currency,
}: {
  assetDetails?: ExchangeAssetsDetails[SwapAsset]
  currency: SwapAsset
}) {
  const {t} = useTranslation()
  return (
    <Grid item container xs={6} spacing={2} alignItems="center">
      <Grid item>
        <Grid item container spacing={1}>
          <Grid item>
            <Typography color="textSecondary">{t('Asset')}:</Typography>
          </Grid>
          <Grid item>
            <Typography>
              {assetDetails?.fullName || t('N/A')} ({currency})
            </Typography>
          </Grid>
          {assetDetails &&
            assetDetails.protocol?.toUpperCase() !== currency && (
              <Grid item>
                <Chip
                  color="primary"
                  variant="outlined"
                  size="small"
                  label={assetDetails.protocol}
                />
              </Grid>
            )}
        </Grid>
        <Grid item container spacing={1}>
          <Grid item>
            <Typography color="textSecondary">{t('Blockchain')}:</Typography>
          </Grid>
          <Grid item>
            <Typography textTransform="capitalize">
              {assetDetails?.blockchain || t('N/A')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        {!assetDetails && (
          <WithTooltip
            title={
              <>
                {t(
                  'Could not load exchange asset details. The asset might have been temporarily removed by Changelly or there is some other problem.',
                )}
              </>
            }
          >
            <ErrorOutlineOutlined fontSize="large" color="error" />
          </WithTooltip>
        )}
      </Grid>
    </Grid>
  )
}

type ExchangeDetailsProps = {
  exchangeAssetsDetails: ExchangeAssetsDetails
  fromAccounts: AccountInfo[]
  toAccounts: AccountInfo[]
} & Swap

function ExchangeDetails({
  partnerSwapId,
  currencyFrom,
  currencyTo,
  swapCreatedAt,
  amountExpectedFrom,
  amountExpectedTo,
  amountFrom,
  amountTo,
  nufiAddressFrom,
  nufiAddressTo,
  payinAddress,
  payinHash,
  payinExtraId,
  payinExtraIdName,
  payoutAddress,
  payoutHash,
  payoutExtraId,
  payoutExtraIdName,
  exchangeAssetsDetails,
  fromAccounts,
  toAccounts,
  networkFee,
  totalFee,
}: ExchangeDetailsProps) {
  const {t} = useTranslation()
  const classes = {...useStyles(), ...useCommonStyles()}
  const fromAssetDetails = getExchangeAssetDetailOrFallback(
    currencyFrom,
    exchangeAssetsDetails,
  )
  const toAssetDetails = getExchangeAssetDetailOrFallback(
    currencyTo,
    exchangeAssetsDetails,
  )
  const fromBlockchain = isExchangeBlockchain(fromAssetDetails.blockchain)
    ? fromAssetDetails.blockchain
    : null
  const toBlockchain = isExchangeBlockchain(toAssetDetails.blockchain)
    ? toAssetDetails.blockchain
    : null
  const payinHashLink = payinHash
    ? fromAssetDetails?.transactionUrl.replace('%1$s', payinHash)
    : null
  const payoutHashLink = payoutHash
    ? toAssetDetails?.transactionUrl.replace('%1$s', payoutHash)
    : null
  const fees =
    totalFee && networkFee
      ? new BigNumber(totalFee).plus(new BigNumber(networkFee))
      : null

  return (
    <Grid item className={classes.details}>
      <Grid container direction="column" spacing={1} p={2} pt={0}>
        <Grid item container alignItems="center">
          <Grid item xs={6}>
            <Typography variant="h6" color="textSecondary">
              {t('From')}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6" color="textSecondary">
              {t('To')}
            </Typography>
          </Grid>
        </Grid>

        <Grid item container mb={3}>
          <Grid item container alignItems="center" spacing={1} xs={6}>
            <Grid item>
              <Typography variant="h6">
                <FormattedAmount
                  expectedAmount={amountExpectedFrom}
                  amount={amountFrom}
                  canBeEstimate={false}
                />{' '}
                {currencyFrom}
              </Typography>
            </Grid>
            <Grid item>
              <AccountLabel
                blockchain={fromBlockchain}
                nufiAddress={nufiAddressFrom}
                accounts={fromAccounts}
              />
            </Grid>
          </Grid>
          <Grid item container alignItems="center" spacing={1} xs={6}>
            <Grid item>
              {/* When fees are not yet available, show "~" to indicate it's an estimate */}
              <Typography variant="h6">
                <FormattedAmount
                  expectedAmount={amountExpectedTo}
                  amount={amountTo}
                  canBeEstimate
                />{' '}
                {currencyTo}
              </Typography>
            </Grid>
            <Grid item>
              <AccountLabel
                blockchain={toBlockchain}
                nufiAddress={nufiAddressTo}
                accounts={toAccounts}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item container mb={3}>
          <AssetDetails
            assetDetails={fromAssetDetails}
            currency={currencyFrom}
          />
          <AssetDetails assetDetails={toAssetDetails} currency={currencyTo} />
        </Grid>

        {(payinHash || payoutHash) && (
          <Grid item container>
            <Grid item xs={6}>
              {payinHash &&
                (fromBlockchain ? (
                  <TransactionExplorerLink
                    blockchain={fromBlockchain}
                    txId={payinHash}
                  />
                ) : (
                  payinHashLink && <ExplorerLink link={payinHashLink} />
                ))}
            </Grid>

            <Grid item xs={6}>
              {payoutHash &&
                (toBlockchain ? (
                  <TransactionExplorerLink
                    blockchain={toBlockchain}
                    txId={payoutHash}
                  />
                ) : (
                  payoutHashLink && <ExplorerLink link={payoutHashLink} />
                ))}
            </Grid>
          </Grid>
        )}

        {(payinExtraIdName || payoutExtraIdName) && (
          <Grid item container>
            <Grid item container spacing={1} xs={6}>
              {payinExtraIdName && (
                <>
                  <Grid item>
                    <Typography color="textSecondary">
                      {payinExtraIdName}:
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    alignItems="center"
                    className={classes.iconRowWrapper}
                  >
                    <Address address={payinExtraId || '-'} />
                    {payinExtraId && (
                      <Grid item className={classes.icon}>
                        <CopyToClipboard value={payinExtraId} />
                      </Grid>
                    )}
                  </Grid>
                </>
              )}
            </Grid>
            <Grid item container spacing={1} xs={6}>
              {payoutExtraIdName && (
                <>
                  <Grid item>
                    <Typography color="textSecondary">
                      {payoutExtraIdName}:
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    alignItems="center"
                    className={classes.iconRowWrapper}
                  >
                    <Address address={payoutExtraId || '-'} />
                    {payoutExtraId && (
                      <Grid item className={classes.icon}>
                        <CopyToClipboard value={payoutExtraId} />
                      </Grid>
                    )}
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        )}

        <Grid item container>
          <Grid item container spacing={1} xs={6}>
            <Grid item>
              <Typography color="textSecondary">
                {t('Payin address')}:
              </Typography>
            </Grid>
            <Grid
              item
              container
              alignItems="center"
              className={classes.iconRowWrapper}
            >
              <Address address={payinAddress} />

              <Grid item className={classes.icon}>
                <CopyToClipboard value={payinAddress} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item container spacing={1} xs={6}>
            <Grid item>
              <Typography color="textSecondary">
                {t('Payout address')}:
              </Typography>
            </Grid>
            <Grid
              item
              container
              alignItems="center"
              className={classes.iconRowWrapper}
            >
              <Address address={payoutAddress} />
              <Grid item className={classes.icon}>
                <CopyToClipboard value={payoutAddress} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item container spacing={1} mt={2}>
          <Grid item>
            <Typography color="textSecondary">{t('Date')}:</Typography>
          </Grid>
          <Grid item>
            <Typography>{swapCreatedAt.toLocaleString()}</Typography>
          </Grid>
        </Grid>

        <Grid item container spacing={1}>
          <Grid item>
            <Typography color="textSecondary">{t('Changelly Id')}:</Typography>
          </Grid>
          <Grid item>
            <Link
              href={`${CHANGELLY_URL}/transaction/${partnerSwapId}`}
              target="_blank"
              rel="noopener"
            >
              <Grid container spacing={0.5}>
                <Grid item>
                  <Typography>{partnerSwapId}</Typography>
                </Grid>
                <Grid item>
                  <ExternalIcon color="primary" fontSize="medium" />
                </Grid>
              </Grid>
            </Link>
          </Grid>
        </Grid>

        {fees && (
          <Grid item container spacing={1}>
            <Grid item>
              <Typography color="textSecondary">
                {t('Exchange fee')}:
              </Typography>
            </Grid>
            <Grid item>
              <Typography>
                {fees.toString()} {currencyTo}
              </Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

type AccountLabelProps = {
  blockchain: ExchangeBlockchain | null
  nufiAddress: string | null
  accounts: AccountInfo[]
}

const AccountLabel = ({
  blockchain,
  nufiAddress,
  accounts,
}: AccountLabelProps) => {
  const {t} = useTranslation()
  const classes = {...useStyles(), ...useCommonStyles()}
  const history = useHistory()
  const account =
    nufiAddress && blockchain
      ? findAccountByAddress(accounts, nufiAddress, blockchain)
      : null

  const onAccountClick = (accountId: AccountId) => {
    if (blockchain) {
      history.push(
        routeTo.portfolio.assets
          .blockchain(blockchain)
          .detail.account(accountId)
          .tab('history').index,
      )
    }
  }

  return blockchain && nufiAddress ? (
    account ? (
      <Typography
        onClick={() => onAccountClick(account.id)}
        className={classes.link}
      >
        ({account.name})
      </Typography>
    ) : (
      <Grid container alignItems="center">
        <Typography color="textSecondary">({t('Unknown account')}</Typography>
        <Tooltip title={<>{t('unknown_account_tooltip')}</>}>
          <HelpIcon
            fontSize="small"
            className={clsx(classes.icon, classes.helpIcon)}
          />
        </Tooltip>
        <Typography color="textSecondary">)</Typography>
      </Grid>
    )
  ) : (
    <Typography color="textSecondary">({t('External address')})</Typography>
  )
}

const useStyles = makeStyles((theme) => ({
  content: {
    minHeight: '100%',
    padding: theme.spacing(2),
  },
  stepper: {
    width: '100%',
  },
  statusContent: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  infoPaper: {
    background: theme.palette.action.selected,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(0.5),
  },
  divider: {
    width: '100%',
  },
  details: {
    width: '100%',
  },
  iconRowWrapper: {
    marginTop: `-${theme.spacing(2)}`, // Compensate for address icon height
  },
  alert: {
    textAlign: 'center',
  },
  helpIcon: {
    color: theme.palette.text.secondary,
  },
}))
