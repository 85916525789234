import {Sentry, request} from '@nufi/frontend-common'
import type {NftBlacklist, SolanaMintAddress} from '@nufi/wallet-solana'

import {fetchNeverStaleQuery} from 'src/utils/query-utils'

import {QUERY_KEY_PREFIX as P} from './utils'

const blacklistQueryKeys = {
  index: [P, 'nftBlacklist'],
} as const

type NftBlacklistResponse = {
  blocklist: string[] // sanitized dapp urls, e.g. "sollet.cc",
  nftBlocklist: SolanaMintAddress[] // mint addresses
  treeFilters: SolanaMintAddress[] // cNft tree parent mint addresses
  // Not used at the moment due to potentially filtering out valid NFTs with no way
  // by the user to retrieve them. If we implemented some "Show spam NFTs" section,
  // we could use this as well
  stringFilters: unknown // prefixes, regex-es to filter by, not used ATM
}

async function getNftBlacklist(): Promise<NftBlacklist> {
  try {
    const response = await request<NftBlacklistResponse>({
      url: 'https://cdn.jsdelivr.net/gh/solflare-wallet/blocklist-automation@master/dist/blocklist.json',
    })
    return {
      urls: new Set(response.blocklist.map((url) => url.toLowerCase())),
      nftMints: new Set(response.nftBlocklist),
      cNftTrees: new Set(response.treeFilters),
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Failed to fetch NFT blacklist', error)
    Sentry.captureMessage(`[Solana] Failed to fetch NFT blacklist ${error}`)
    return {
      urls: new Set(),
      nftMints: new Set(),
      cNftTrees: new Set(),
    }
  }
}

getNftBlacklist.__key = blacklistQueryKeys.index

export function cachedGetNftBlacklist(): Promise<NftBlacklist> {
  return fetchNeverStaleQuery({
    queryKey: getNftBlacklist.__key,
    queryFn: getNftBlacklist,
  })
}
