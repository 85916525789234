import {makeStyles} from '@mui/styles'
import React from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'

import {useSettings} from 'src/features/profile/application'
import {useGetCurrentHotVendor} from 'src/store/auth'

import {Alert} from '../../../components'
import {routeTo} from '../../../router'

export const ExchangeHistorySyncNote = () => {
  const {t} = useTranslation()
  const history = useHistory()
  const classes = useStyles()
  const {isMnemonicActivated} = useSettings()
  const hotVendor = useGetCurrentHotVendor()

  return hotVendor === 'mnemonic' && !isMnemonicActivated ? (
    <Alert severity="info">
      <Trans
        i18nKey="exchange_history_sync_note"
        t={t}
        components={{
          ActivateMnemonicLink: (
            <span
              onClick={() => history.push(routeTo.settings.activateMnemonic)}
              className={classes.infoLink}
            />
          ),
        }}
      />
    </Alert>
  ) : null
}

const useStyles = makeStyles((theme) => ({
  infoLink: {
    color: theme.palette.info.light,
    textDecoration: 'none',
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
}))
