import {Launch as ExternalIcon} from '@mui/icons-material'
import type {SxProps, Theme} from '@mui/material'
import {Link as MuiLink, styled, IconButton} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import type {ReactNode} from 'react'
import React from 'react'
import {useTranslation} from 'react-i18next'

import WithTooltip from '../visual/atoms/WithTooltip'

export type ExplorerLinkProps = (
  | {
      disabled?: false
      link: string
    }
  | {
      disabled: true
      link?: undefined
    }
) & {
  size?: 'small' | 'medium' | 'inherit'
  label?: React.ReactNode
  linkSx?: SxProps<Theme>
  className?: string
}

export type BlockchainAddressExplorerLinkProps = {
  address: string
  size: NonNullable<ExplorerLinkProps['size']>
}

export function ExplorerLink({
  link,
  size,
  disabled,
  label,
  linkSx,
  className,
}: ExplorerLinkProps) {
  const {t} = useTranslation()

  const explorerLinkContent = (
    <>
      {label || t('View on Explorer')}
      <ExternalIcon
        fontSize={size || 'medium'}
        color={disabled ? 'disabled' : 'primary'}
      />
    </>
  )

  return !disabled ? (
    <LinkWithIcon target="_blank" href={link} sx={linkSx} className={className}>
      {explorerLinkContent}
    </LinkWithIcon>
  ) : (
    <UnavailableLinkTooltip>{explorerLinkContent}</UnavailableLinkTooltip>
  )
}

type ExplorerButtonProps = (
  | {
      disabled: false
      link: string
    }
  | {
      disabled: true
      link?: undefined
    }
) & {
  disabledTooltipTitle?: string
}

export function ExplorerButton({
  link = '',
  disabled,
  disabledTooltipTitle,
}: ExplorerButtonProps) {
  return (
    <CustomExplorerButton
      disabled={disabled}
      disabledTooltipTitle={disabledTooltipTitle}
      element={
        <IconButton target="_blank" href={link} size="small" {...{disabled}}>
          <ExternalIcon fontSize="inherit" />
        </IconButton>
      }
    />
  )
}

type CustomExplorerButtonProps = {
  disabled: boolean
  element: ReactNode
  disabledTooltipTitle?: string
}

export function CustomExplorerButton({
  disabled,
  element,
  disabledTooltipTitle,
}: CustomExplorerButtonProps) {
  const {t} = useTranslation()

  return !disabled ? (
    <WithTooltip title={t('View on Explorer')}>{element}</WithTooltip>
  ) : (
    <UnavailableLinkTooltip title={disabledTooltipTitle}>
      {element}
    </UnavailableLinkTooltip>
  )
}

type ValidatorLinkProps = {
  link: string
  linkText: React.ReactNode
}

export function ValidatorLink({link, linkText}: ValidatorLinkProps) {
  return (
    <LinkWithIcon target="_blank" href={link}>
      {linkText}
      <ExternalIcon color="primary" />
    </LinkWithIcon>
  )
}

type UnavailableLinkTooltipProps = {
  title?: string
} & React.PropsWithChildren

const UnavailableLinkTooltip = ({
  title,
  children,
}: UnavailableLinkTooltipProps) => {
  const {t} = useTranslation()
  const classes = useCommonStyles()

  return (
    <WithTooltip
      title={title || t('Explorer link not available.')}
      className={classes.disabledTooltip}
    >
      {children}
    </WithTooltip>
  )
}

export const useCommonStyles = makeStyles((theme) => ({
  link: {
    marginRight: theme.spacing(0.5),
    color: theme.palette.primary.main,
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
  icon: {
    marginLeft: theme.spacing(0.5),
  },
  stakeKey: {
    width: '10vw',
  },
  disabledTooltip: {
    color: theme.palette.text.disabled,
    '&:hover': {
      cursor: 'pointer',
    },
  },
}))

const LinkWithIcon = styled(MuiLink)({
  display: 'flex',
  alignItems: 'center',
  gap: 4,
})
