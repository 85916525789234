import type BigNumber from 'bignumber.js'
import React from 'react'

import {FormattedAssetPriceChange} from 'src/components/formatting/FormattedAssetPriceChange'
import type {ConvertedBalanceResponse} from 'src/features/assets/domain/conversions'
import {useSettings} from 'src/features/profile/application'

import {
  FormattedAssetPair,
  FormattedAssetPairLayout,
  FormattedCurrency,
  TypographyLoader,
} from '../../../components'
import type {AccountId, Blockchain} from '../../../types'
import {useGetNativeAssetName} from '../../../utils/translations'

import {AssetItemLayout} from './AssetItemUtils'

type AssetItemProps = {
  blockchain: Blockchain
  totalBalance: BigNumber
  totalBalanceToCurrency?: ConvertedBalanceResponse
  availableBalance: BigNumber
  availableBalanceToCurrency?: ConvertedBalanceResponse
  owningAccountsCount: number
  defaultAccountId?: AccountId
  priceChange?: number
  price?: ConvertedBalanceResponse
}

export function NativeAssetItem({
  blockchain,
  totalBalance,
  availableBalance,
  defaultAccountId,
  totalBalanceToCurrency,
  availableBalanceToCurrency,
  priceChange,
  price,
}: AssetItemProps) {
  const {currency} = useSettings()
  const getNativeAssetName = useGetNativeAssetName()

  return (
    <AssetItemLayout
      priceCol={
        <FormattedAssetPairLayout
          primaryElement={
            price == null ? (
              <TypographyLoader variant="body2" />
            ) : (
              <FormattedCurrency
                isSensitiveInformation={false}
                convertedBalance={price}
                currency={currency}
              />
            )
          }
          secondaryElement={
            priceChange == null ? null : (
              <FormattedAssetPriceChange percentage={priceChange} colorize />
            )
          }
        />
      }
      blockchain={blockchain}
      defaultAccountId={defaultAccountId}
      FormattedLabel={getNativeAssetName(blockchain)}
      totalBalanceCol={
        <FormattedAssetPair
          amount={totalBalance}
          blockchain={blockchain}
          ellipsizeLabel
          isSensitiveInformation
          amountFormat="compact"
          conversionProps={{
            convertedBalance: totalBalanceToCurrency || {type: 'is-loading'},
          }}
        />
      }
      availableBalanceCol={
        <FormattedAssetPair
          amount={availableBalance}
          blockchain={blockchain}
          ellipsizeLabel
          isSensitiveInformation
          amountFormat="compact"
          conversionProps={{
            convertedBalance: availableBalanceToCurrency || {
              type: 'is-loading',
            },
          }}
        />
      }
      isNft={false}
    />
  )
}
