/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {useQuery} from '@tanstack/react-query'

import {QUERY_KEY_PREFIX as P} from 'src/wallet/flow/public/utils'
import {hoursToMilliseconds} from 'src/wallet/utils/common'

import {flow} from '../flowManagers'

const maxNodeIdsToNamesAge = hoursToMilliseconds(12)

const flowNodeOperatorsQueryKeys = {
  nodeIdsToNames: [P, 'nodeIdsToNames'],
} as const

export function useGetNodeIdsToNames() {
  return useQuery({
    queryKey: flowNodeOperatorsQueryKeys.nodeIdsToNames,
    queryFn: () => flow.blockchainApi.getNodeIdsToNames(),
    gcTime: maxNodeIdsToNamesAge,
    staleTime: maxNodeIdsToNamesAge,
  })
}
