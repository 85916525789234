import type {MasterProfileData} from './types'

// These guards should serve as run-time kill switches against storing corrupted
// or totally different data than intended

export const isMasterProfileData = (
  value: unknown,
): value is MasterProfileData => {
  if (!(value instanceof Object)) {
    return false
  }

  const masterProfileData = value as MasterProfileData
  if (
    masterProfileData.lastLoggedInLocalProfileId === 'undefined' ||
    (masterProfileData.lastLoggedInLocalProfileId != null &&
      typeof masterProfileData.lastLoggedInLocalProfileId !== 'string')
  ) {
    return false
  }

  if (!Array.isArray(masterProfileData.profiles)) {
    return false
  }

  return true
}
