import type {
  FlowAccountInfo,
  FlowSignedTransaction,
  FlowTokenId,
  FlowTokenMetadata,
  FlowTransactionId,
  FlowTxPlan,
} from '@nufi/wallet-flow'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'

import {useLastDefinedData} from 'src/utils/query-utils'

import {QueryGuard, TransactionModalError} from '../../../components'
import {useTokenIdFromRoute} from '../../../router/portfolio'
import type {AccountId} from '../../../wallet'
import {
  useSignTx,
  useSubmitTx,
  useGetAccounts,
  useResolveAddressName,
  resolveAddressName,
} from '../../../wallet/flow'
import {ensureAccountById} from '../../../wallet/utils/common'
import {
  useSafeAsyncFlowTxPlanState,
  getSendMaxAmountOptions,
} from '../../staking/flow/actionModals/utils'
import {SendModal} from '../common/sendMultipleAssetsModal/SendMultipleAssetsModal'
import {SendModalContent} from '../common/sendMultipleAssetsModal/SendMultipleAssetsModalContent'
import type {SendSingleAssetFormInjectedPros} from '../common/sendMultipleAssetsModal/types'
import type {BaseSendSchema as FormSchema} from '../common/types'
import {DeviceReadyState, getFormAddress} from '../common/utils'

import {useTransferDetailSchema} from './schema'
import {
  isValidFlowAddress,
  getSendModalTxPlan,
  getAddressNameServiceUtils,
} from './utils'

type FlowSendModalProps = {
  onClose: () => unknown
}

export function FlowSendModal(props: FlowSendModalProps) {
  const {accountsQuery} = useLastDefinedData({
    accountsQuery: useGetAccounts(),
  })

  return (
    <QueryGuard
      {...accountsQuery}
      ErrorElement={<TransactionModalError />}
      loadingVariant="centered"
    >
      {(accounts) => (
        <FlowSendModalForm
          {...{
            accounts,
            ...props,
          }}
        />
      )}
    </QueryGuard>
  )
}

function FlowSendModalForm({
  onClose,
  accounts,
}: FlowSendModalProps & {accounts: FlowAccountInfo[]}) {
  const blockchain = 'flow'
  const tokenId = useTokenIdFromRoute() as FlowTokenId | undefined

  // this might be a bit misleading, at this stage we cannot determine if the token is
  // a nft, in which case txPlanType should be `transferNft`, in terms of transaction planning this txPlanType is
  // not taken into account
  const txPlanType: FlowTxPlan['type'] = tokenId
    ? 'transferToken'
    : 'transferFlow'

  return (
    <SendModal<FormSchema>
      type="singleAsset"
      {...{onClose, blockchain}}
      isAddressValid={(address) => isValidFlowAddress(address)}
      getDefaultAccountId={({defaultAccountId, tokenBalances}) =>
        tokenBalances?.find(({balance}) => balance.gt(0))?.accountId ||
        defaultAccountId
      }
      overrideBaseDetailsValidationSchema={useTransferDetailSchema({
        accounts,
        tokenId,
        txType: txPlanType,
      })}
    >
      {(data: SendSingleAssetFormInjectedPros<FormSchema>) => (
        <FlowSendModalContent
          {...data}
          {...{blockchain, onClose, txPlanType}}
        />
      )}
    </SendModal>
  )
}

function FlowSendModalContent(
  props: SendSingleAssetFormInjectedPros<FormSchema> &
    FlowSendModalProps & {txPlanType: FlowTxPlan['type']},
) {
  const {t} = useTranslation()
  const resolveAddressNameQuery = useResolveAddressName(resolveAddressName)
  const {formikProps, accounts, txPlanType} = props
  const tokenData =
    'tokenId' in props
      ? (props.metadataById?.[props.tokenId] as FlowTokenMetadata)
      : undefined
  const {values} = formikProps
  const formAddress = getFormAddress(values)
  const submit = useSubmitTx()
  const sign = useSignTx()
  const [txPlanState, safeFetchAndUpdateTxPlan] =
    useSafeAsyncFlowTxPlanState<FormSchema>(
      accounts as FlowAccountInfo[],
      // TODO: once we implement variable tx fees, this txPlanType needs to have a context of the token is nft or not,
      txPlanType,
    )
  useEffect(() => {
    safeFetchAndUpdateTxPlan(values)
  }, [values.accountId])

  const onTxSign = async () => {
    const txPlan: FlowTxPlan = getSendModalTxPlan({tokenData, values})
    const accountInfo = ensureAccountById(
      accounts,
      values.accountId as AccountId,
    ) as FlowAccountInfo
    return await sign.mutateAsyncSilent({txPlan, accountInfo})
  }

  const onTxSubmit = async (signedTx: FlowSignedTransaction) =>
    await submit.mutateAsyncSilent({accountId: values.accountId, signedTx})

  return (
    <SendModalContent<FormSchema, FlowSignedTransaction, FlowTransactionId>
      {...{
        ...props,
        DeviceReadyState,
        blockchain: 'flow',
        type: 'singleAsset',
        signProps: {
          ...sign,
          mutateAsyncSilent: onTxSign,
        },
        submitProps: {
          ...submit,
          mutateAsyncSilent: onTxSubmit,
        },
        feeProps: {
          fee: txPlanState.data ? txPlanState.data.fee : undefined,
        },
        toAddress: formAddress,
      }}
      humanReadableToAddressName={
        formikProps.values.toAddressNameServiceState.status === 'valid' &&
        formikProps.values.toAddress
          ? `${t('.find name')}: ${formikProps.values.toAddress}`
          : undefined
      }
      addressNameService={{
        ...getAddressNameServiceUtils(resolveAddressNameQuery),
        getValidateSuccessLabel: () => t('.find name resolved'),
        getValidateLabel: () => t('.find name'),
      }}
      getAssetFieldProps={(asset, {name}) => ({
        maxAmountOptions:
          asset.type === 'native'
            ? getSendMaxAmountOptions(
                props.fromAccount as FlowAccountInfo,
                txPlanType,
                formikProps,
                name,
              )
            : undefined,
      })}
    />
  )
}
