// translations imported first to ensure they are loaded before any React component
import './translations/i18n'
import makeStyles from '@mui/styles/makeStyles'
import {getIsWebExtension} from '@nufi/frontend-common'
import React, {useEffect, useState} from 'react'
import {BrowserRouter, HashRouter, Route, Switch} from 'react-router-dom'

import {useInitMasterProfileManager} from './appStorage'
import {AppWrapper} from './AppWrapper'
import {MutationGuard} from './components'
import {AutoLogout} from './components/AutoLogout'
import config from './config'
import {MetaMaskProvider} from './metamaskSnap/MetamaskContext'
import LoggedInMainPage from './pages/loggedInMain'
import LoggedOutMainPage from './pages/loggedOutMain'
import {WithAppOpener} from './pages/loggedOutMain/appOpener'
import {AutoLogin} from './pages/profile/Login'
import {routeTo} from './router'
import {LoggedInRoute, LoggedOutRoute} from './router/auth'
import {useTrackTransactions} from './store/transactions'
import {PageViewTracking, initializeTracking} from './tracking'
import {trackedTransactionKeys} from './utils/general'
import {WalletConnectConnector} from './walletConnect'

// I couldn't find any common type that TypeScript would accept here. The
// interface of HashRouter should mostly be compatible, so we will just cast
// everything to the type of BrowserRouter for now.
const Router = (getIsWebExtension()
  ? HashRouter
  : BrowserRouter) as unknown as typeof BrowserRouter

export default function App() {
  return (
    <AppWrapper>
      <MetaMaskProvider>
        <_App />
      </MetaMaskProvider>
    </AppWrapper>
  )
}

function _App() {
  useGlobalAppStyles()

  const [analyticsInitialized, setAnalyticsInitialized] = useState(false)
  const initMasterProfileManager = useInitMasterProfileManager()

  useEffect(() => {
    initMasterProfileManager.mutateAsyncSilent()
    initializeTracking()
    setAnalyticsInitialized(true)
  }, [])
  useTrackTransactions(trackedTransactionKeys)

  if (!analyticsInitialized) return null

  return (
    <MutationGuard {...initMasterProfileManager}>
      <>
        {!config.isProd && config.autoLogin && <AutoLogin />}
        <Router>
          <PageViewTracking />
          <WithAppOpener>
            <Switch>
              {config.isWalletConnectEnabled && !getIsWebExtension() && (
                <Route
                  path={routeTo.walletConnect.connector.index}
                  render={() => <WalletConnectConnector />}
                />
              )}
              <LoggedOutRoute path={routeTo.auth}>
                <LoggedOutMainPage />
              </LoggedOutRoute>
              <LoggedInRoute path={routeTo.main}>
                <AutoLogout
                  idleInSeconds={15 * 60}
                  showDialogBeforeInSeconds={60}
                />
                <LoggedInMainPage />
              </LoggedInRoute>
            </Switch>
          </WithAppOpener>
        </Router>
      </>
    </MutationGuard>
  )
}

export const useGlobalAppStyles = makeStyles({
  '@global': {
    '#root': {
      minHeight: '100vh',
      width: '100vw',
      maxWidth: '100%',
    },
  },
})
