import {Sentry} from '@nufi/frontend-common'
import BigNumber from 'bignumber.js'
import _ from 'lodash'

import {assert} from '../../../../../../utils/assertion'
import type {HexString, TokenId} from '../../../../../../wallet'
import type {TokensDiff} from '../../../../../../wallet/dappConnector/types'
import type {
  EvmAccountOfflineInfo,
  EvmAddress,
  EvmBlockchain,
  EvmContractAddress,
  EvmTokenId,
} from '../../../../../../wallet/evm'
import type {
  TxAssetChangeSimulationResponse,
  TxAssetChangesSimulationResponse,
} from '../../../../../../wallet/evm/sdk/alchemy/types'
import {
  getEvmTokenId,
  toPlainEvmAddress,
} from '../../../../../../wallet/evm/sdk/utils'

function isTokenType(change: TxAssetChangeSimulationResponse) {
  return change.assetType !== 'NATIVE'
}

type GetTokenMovementsCountsResponse = {
  receivingTokensCount: number
  sendingTokensCount: number
}

export function getTokenMovementsCounts(
  txSimulationResult: TxAssetChangesSimulationResponse,
  selectedAccount: EvmAccountOfflineInfo<EvmBlockchain>,
): GetTokenMovementsCountsResponse {
  const selectedAccountAddress = toPlainEvmAddress<EvmBlockchain>(
    selectedAccount.address,
  )

  const getUniqueTokenChangesCount = (
    changes: TxAssetChangeSimulationResponse[],
    getTargetAddress: (
      change: TxAssetChangeSimulationResponse,
    ) => HexString | undefined,
  ) =>
    _.uniq(
      changes
        .filter(
          (change) =>
            change.changeType === 'TRANSFER' &&
            isTokenType(change) &&
            toPlainEvmAddress(
              getTargetAddress(change) as unknown as EvmAddress<EvmBlockchain>,
            ) === selectedAccountAddress,
        )
        .map((change) => change.symbol),
    ).length

  const receivingTokensCount = getUniqueTokenChangesCount(
    txSimulationResult.changes,
    (c) => c.to,
  )
  const sendingTokensCount = getUniqueTokenChangesCount(
    txSimulationResult.changes,
    (c) => c.from,
  )
  return {receivingTokensCount, sendingTokensCount}
}

export function getTokenId(
  change: TxAssetChangeSimulationResponse,
): EvmTokenId<EvmBlockchain> | null {
  try {
    if (change.assetType === 'ERC20') {
      assert(change.contractAddress != null)
      return getEvmTokenId<EvmBlockchain>({
        contractAddress:
          change.contractAddress as unknown as EvmContractAddress<EvmBlockchain>,
        standard: 'ERC20',
      })
    }
    if (
      change.assetType === 'ERC1155' ||
      change.assetType === 'ERC721' ||
      change.assetType === 'SPECIAL_NFT'
    ) {
      assert(change.contractAddress != null)
      assert(change.tokenId != null)
      return getEvmTokenId<EvmBlockchain>({
        contractAddress:
          change.contractAddress as unknown as EvmContractAddress<EvmBlockchain>,
        standard: change.assetType,
        nftWithinCollectionId: change.tokenId,
      })
    }
    throw new Error('Unsupported assetType')
  } catch (err) {
    Sentry.captureMessage(
      `Custom error: Unsupported assetType:${change.assetType}|contractAddress:${change.contractAddress}|tokenId:${change.tokenId}`,
    )
    return null
  }
}

export function getNativeDiff(
  txSimulationResult: TxAssetChangesSimulationResponse,
  selectedAccount: EvmAccountOfflineInfo<EvmBlockchain>,
): BigNumber {
  const selectedAccountAddress = toPlainEvmAddress<EvmBlockchain>(
    selectedAccount.address,
  )

  const nativeDiff = txSimulationResult.changes.reduce((result, change) => {
    if (change.changeType !== 'TRANSFER') return result
    if (change.assetType !== 'NATIVE') return result

    // Note that:
    // 1. If user is sending to the same address the effects will cancel out.
    // 2. We do not specifically handle sending to/from other address owned by the same wallet
    if (
      selectedAccountAddress ===
      toPlainEvmAddress<EvmBlockchain>(
        change.to as unknown as EvmAddress<EvmBlockchain>,
      )
    ) {
      result = result.plus(new BigNumber(change.rawAmount))
    }
    if (
      selectedAccountAddress ===
      toPlainEvmAddress<EvmBlockchain>(
        change.from as unknown as EvmAddress<EvmBlockchain>,
      )
    ) {
      result = result.minus(new BigNumber(change.rawAmount))
    }

    return result
  }, new BigNumber(0))
  return nativeDiff
}

type TokensDiffResponse = {
  data: TokensDiff
  hasErrors: boolean
}

export function getTokensDiff(
  txSimulationResult: TxAssetChangesSimulationResponse,
  selectedAccount: EvmAccountOfflineInfo<EvmBlockchain>,
): TokensDiffResponse {
  const selectedAccountAddress = toPlainEvmAddress<EvmBlockchain>(
    selectedAccount.address,
  )

  const response = txSimulationResult.changes.reduce(
    (result, change) => {
      if (!isTokenType(change)) return result

      const tokenId = getTokenId(change)
      if (tokenId == null) {
        return {...result, hasErrors: true}
      }

      if (change.changeType !== 'TRANSFER') {
        return result
      }

      if (
        selectedAccountAddress ===
        toPlainEvmAddress<EvmBlockchain>(
          change.from as unknown as EvmAddress<EvmBlockchain>,
        )
      ) {
        result.tokensDiffMap[tokenId] = {
          amount: new BigNumber(
            result.tokensDiffMap[tokenId]?.amount || 0,
          ).minus(new BigNumber(change.rawAmount)),
          tokenId,
        }
      }
      if (
        selectedAccountAddress ===
        toPlainEvmAddress<EvmBlockchain>(
          change.to as unknown as EvmAddress<EvmBlockchain>,
        )
      ) {
        result.tokensDiffMap[tokenId] = {
          amount: new BigNumber(
            result.tokensDiffMap[tokenId]?.amount || 0,
          ).plus(new BigNumber(change.rawAmount)),
          tokenId,
        }
      }
      return result
    },
    {
      tokensDiffMap: {} as Record<TokenId, TokensDiff[number]>,
      hasErrors: false,
    },
  )

  return {
    hasErrors: response.hasErrors,
    data: Object.values(response.tokensDiffMap),
  }
}
