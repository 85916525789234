import React from 'react'

import {isHotVendor} from '../../../wallet'
import type {CryptoProviderType} from '../../../wallet'

import {AddHotAccount} from './AddHotAccount'
import {AddLedgerAccount} from './AddLedgerAccount'

type AddFlowAccountProps = {
  cryptoProviderType: CryptoProviderType
}

const AddFlowAccount = ({cryptoProviderType}: AddFlowAccountProps) => {
  if (isHotVendor(cryptoProviderType)) {
    return <AddHotAccount {...{cryptoProviderType}} />
  }
  switch (cryptoProviderType) {
    case 'ledger':
      return <AddLedgerAccount />
    default:
      return null
  }
}

export default AddFlowAccount
