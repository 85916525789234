/* eslint-disable @typescript-eslint/explicit-function-return-type */

import {fetchNeverStaleQuery, useNeverStaleQuery} from 'src/utils/query-utils'

import {getAllNfts as getAllNftsGeneric} from '../../../../public/nfts'
import {getEvmManager} from '../../../evmManagers'
import type {
  EvmAccountInfo,
  EvmAddress,
  EvmBlockchain,
  EvmNft,
  EvmTokenId,
} from '../../../types'
import {cachedGetAccounts} from '../core'

export const nftsQueryKeys = {
  nftItem: <TBlockchain extends EvmBlockchain>(
    blockchain: TBlockchain,
    id: EvmTokenId<TBlockchain> | null,
  ) => [blockchain, 'nft', id],
  nfts: {
    index: <TBlockchain extends EvmBlockchain>(blockchain: TBlockchain) => [
      blockchain,
      'nfts',
    ],
  },
} as const

//
// __CACHED__ QUERIES
//

const getAllNfts =
  <TBlockchain extends EvmBlockchain>(blockchain: TBlockchain) =>
  async () => {
    const accounts = await cachedGetAccounts(blockchain)
    return getAllNftsGeneric({
      blockchain,
      accounts,
      getAccountNfts: (account: EvmAccountInfo<TBlockchain>) =>
        getEvmManager(blockchain).accountManager.getNfts(
          account.address as EvmAddress<TBlockchain>,
        ),
      getSingleNftQueryKey: (nft: EvmNft<TBlockchain>) =>
        useGetNft.__key(blockchain, nft.id),
    })
  }

getAllNfts.__key = <TBlockchain extends EvmBlockchain>(
  blockchain: TBlockchain,
) => nftsQueryKeys.nfts.index(blockchain)

export const cachedGetAllNfts = async <TBlockchain extends EvmBlockchain>(
  blockchain: TBlockchain,
) => {
  return fetchNeverStaleQuery({
    queryKey: getAllNfts.__key(blockchain),
    queryFn: getAllNfts(blockchain),
  })
}

// TODO refactor useGetAllNfts functions to reuse logic for all blockchains
export function useGetAllNfts<TBlockchain extends EvmBlockchain>(
  blockchain: TBlockchain,
  enabled = true,
) {
  return useNeverStaleQuery({
    queryKey: getAllNfts.__key(blockchain),
    queryFn: getAllNfts(blockchain),
    enabled,
  })
}

//
// __COMPUTED__ QUERIES
//

// TODO: allow fetching arbitrary, not just own NFT. It may be needed for dapp connector.
// See cardano implementation
export function useGetNft<TBlockchain extends EvmBlockchain>(
  blockchain: TBlockchain,
  tokenId: EvmTokenId<TBlockchain> | null,
  enabled = true,
) {
  return useNeverStaleQuery({
    queryKey: useGetNft.__key(blockchain, tokenId),
    queryFn: async () => {
      const {allNfts} = await cachedGetAllNfts(blockchain)
      if (tokenId != null) {
        const nft = allNfts[tokenId]
        return nft || null
      }
      return null
    },
    enabled,
  })
}

useGetNft.__key = nftsQueryKeys.nftItem
