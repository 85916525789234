import {Typography, Box, Link as MuiLink} from '@mui/material'
import Alert from '@mui/material/Alert'
import React from 'react'
import {Trans, useTranslation} from 'react-i18next'

import ledgerErrorSrc from '../../../assets/images/ledgerError.png'
import ledgerSignSrc from '../../../assets/images/ledgerSign.png'
import {externalLinks} from '../../../constants'
import type {Blockchain} from '../../../types'
import {useGetBlockchainName} from '../../../utils/translations'
import {HelpSectionText} from '../common'

type HelpSectionProps = {
  blockchain: Blockchain
  severity: 'info' | 'error'
}

export function HelpSection({blockchain, severity}: HelpSectionProps) {
  const {t} = useTranslation()
  const getBlockchainName = useGetBlockchainName()

  return (
    <Alert severity={severity}>
      <Typography>
        <Box component="span" fontWeight="fontWeightBold">
          {t('Make sure that')}
        </Box>
      </Typography>
      <HelpSectionText>
        1. {t('Your Ledger is connected and unlocked')}
      </HelpSectionText>
      <HelpSectionText>
        2.{' '}
        {t('ledger_error_app_running', {
          blockchain: getBlockchainName(blockchain),
        })}
      </HelpSectionText>
      <HelpSectionText>
        3.{' '}
        {t('ledger_error_app_up_to_date', {
          blockchain: getBlockchainName(blockchain),
        })}
      </HelpSectionText>
      <HelpSectionText>4. {t('Ledger firmware is up to date')}</HelpSectionText>
      <HelpSectionText>5. {t('Ledger Live is not running')}</HelpSectionText>
      {severity === 'error' && (
        <HelpSectionText>
          6. {t('ledger_error_correct_passphrase')}
        </HelpSectionText>
      )}
    </Alert>
  )
}

export function UnsupportedBrowserMessage() {
  const {t} = useTranslation()

  return (
    <Alert severity="error">
      <Typography component="p">
        <Trans
          i18nKey="unsupported_browser"
          t={t}
          components={{
            ChromeLink: (
              <MuiLink
                color="primary"
                target="_blank"
                href={externalLinks.downloadChrome}
              />
            ),
          }}
        />
      </Typography>
    </Alert>
  )
}

export const ledgerImages = {
  sign: <img src={ledgerSignSrc} />,
  verify: <img src={ledgerSignSrc} />,
  export: <img src={ledgerSignSrc} />,
  setup: <img src={ledgerSignSrc} />,
  error: <img src={ledgerErrorSrc} />,
}
