import type {Locale} from 'date-fns'
import {enUS} from 'date-fns/locale'

import type {SupportedLanguage} from './supportedLanguages'

export function toDateFnsLocale(language: SupportedLanguage): Locale {
  const languageToDateFnsLocale = {
    en: enUS,
  }

  return languageToDateFnsLocale[language]
}
