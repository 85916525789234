/**
 * By default, **styled** function from **MUI** forwards all properties to customized component.
 *
 * These properties that doesn't exist on MUI component's API are than forwarded into DOM by MUI.
 *
 * That results in invalid HTML properties, and a lot of console errors
 */
export const skipForwardingProps = <
  CustomProps extends Record<string, unknown>,
>(
  prop: PropertyKey,
  propsToBeSkipped: Array<keyof CustomProps>,
) => !propsToBeSkipped.includes(prop as string)
