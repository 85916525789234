import {assert} from '../utils/assertion'

import {localSecretProvider} from './secretProvider'
import type {Mnemonic, ValidMnemonicLength} from './types'
import {isMnemonicLengthValid} from './utils/mnemonic'

export * from './public/queries'
export * from './public/mutations'
export * from './public/utils'

export {
  isHwVendor,
  isHotVendorObject,
  isHotVendor,
  isHwVendorObject,
} from '@nufi/wallet-common'

export const getMnemonic = (): Mnemonic => localSecretProvider.getMnemonic()
export const getMnemonicLength = (): ValidMnemonicLength => {
  const length = localSecretProvider.getMnemonic().split(' ').length
  assert(isMnemonicLengthValid(length))
  return length
}
export * from './utils/mnemonic'
export {getSecretProvider, initSecretProvider} from './secretProvider'
export * from './types'

export {sumAccountInfoBalances} from './utils/common'
export {useGenerateAccountName} from './utils/accountsQueries'
