import {request} from '@nufi/frontend-common'
import {clusterToChainId} from '@nufi/wallet-solana'
import type {
  SolanaOffChainMetadata,
  SolanaTokenId,
  SolanaMintAddress,
  SolanaTokenName,
  SolanaTokenTicker,
  SolanaCluster,
} from '@nufi/wallet-solana'

type CommunityTokenListEntry = {
  address: string
  chainId: 101 | 103
  name: string
  symbol: string
  verified: boolean
  decimals: number
  holders: number | null
  logoURI: string
  tags: string[]
  extensions?: {
    coingeckoId?: string
  }
}

type CommunityTokenListEntryResponse = {
  tokens: CommunityTokenListEntry[]
}

export const fetchParsedTokenList = async (
  solanaClusterName: SolanaCluster,
): Promise<SolanaOffChainMetadata[]> => {
  const response = await request<CommunityTokenListEntryResponse>({
    // Community token list used by explorer.solana.com, maintained by solflare (https://github.com/solflare-wallet/utl-api)
    // A public api is also provided (https://token-list-api.solana.cloud), but at the time of implementation
    // it contained some inconsistencies with the cdn, which is more reliable.
    url: 'https://cdn.jsdelivr.net/gh/solflare-wallet/token-list@latest/solana-tokenlist.json',
  })

  const matchingChainId = clusterToChainId[solanaClusterName]
  const tokenList = response.tokens.filter(
    (token) => token.chainId === matchingChainId,
  )

  return tokenList.map((token) => ({
    blockchain: 'solana' as const,
    id: token.address as SolanaTokenId,
    mint: token.address as SolanaMintAddress,
    name: token.name as SolanaTokenName,
    ticker: token.symbol as SolanaTokenTicker,
    decimals: token.decimals,
    logoURI: token.logoURI,
    coingeckoId: token.extensions?.coingeckoId,
  }))
}
