import BigNumber from 'bignumber.js'
import _ from 'lodash'

import {
  formatCSVTokenAmount,
  formatCSVAmount,
} from '../../../../../../utils/formatting'
import type {
  CardanoTokenBundle,
  CardanoTokenMetadata,
  Lovelaces,
  CardanoAccountInfo,
} from '../../../../../../wallet/cardano'
import type {
  CSVCardanoRewardDistribution,
  CSVCardanoTxHistoryEntry,
  CSVCardanoStakingHistoryEntry,
} from '../../../../../../wallet/cardano/public/direct'
import {
  getTransactionHistory,
  getAccountsStakeHistory,
  getTokensMetadata,
} from '../../../../../../wallet/cardano/public/direct'

import type {
  CSVConstants,
  CommonEntryFields,
  TxEntry,
  CreateCSVRows,
} from './common'
import {formatToUTC, TxSummaryType, filterEntriesByDate} from './common'

const createRewardsEntries = (stakingRewards: CSVCardanoRewardDistribution[]) =>
  stakingRewards.map((stakingReward: CSVCardanoRewardDistribution) => ({
    type: TxSummaryType.REWARD_AWARDED,
    timeIssued: stakingReward.timeIssued,
    received: stakingReward.rewards,
    currency: 'ADA',
    accountName: stakingReward.accountName,
    isToken: false,
    address: stakingReward.address,
  }))

const createTokenEntries = (
  tokenBundleEffect: CardanoTokenBundle,
  tokenMetadata: _.Dictionary<CardanoTokenMetadata>,
  commonEntryFields: CommonEntryFields,
): TxEntry[] =>
  tokenBundleEffect.map(({amount, token}) => {
    const ticker = tokenMetadata?.[token.id]?.ticker
    const fingerprint =
      tokenMetadata?.[token.id]?.fingerprint || token.fingerprint
    const decimals = tokenMetadata?.[token.id]?.decimals

    return {
      ...(!amount.isNegative()
        ? {
            type: TxSummaryType.RECEIVED,
            received: amount as Lovelaces,
          }
        : {
            type: TxSummaryType.SENT,
            sent: amount.abs() as Lovelaces,
          }),
      currency: ticker ? `${ticker} (${fingerprint})` : fingerprint,
      decimals,
      isToken: true,
      ...commonEntryFields,
    }
  })

const createTransactionEntries = (
  txHistories: CSVCardanoTxHistoryEntry[][],
  tokensMetadata: _.Dictionary<CardanoTokenMetadata>,
) =>
  txHistories.flatMap((transactions) =>
    transactions.flatMap((transaction) => {
      const {
        txHash,
        timeIssued,
        lovelaceEffect,
        fee,
        tokenBundleEffect,
        withdrawals,
        accountName,
        address,
      } = transaction
      const commonEntryFields = {
        accountName,
        txHash,
        timeIssued,
        address,
      }
      const summedWithdrawals = withdrawals.reduce(
        (a, b) => a.plus(b.rewards),
        new BigNumber(0),
      )
      if (withdrawals.length) {
        return [
          {
            ...commonEntryFields,
            type: TxSummaryType.SENT,
            sent: lovelaceEffect
              ?.minus(summedWithdrawals)
              .abs()
              .minus(fee) as Lovelaces,
            fee,
            currency: 'ADA',
            isToken: false,
          },
        ]
      } else if (!lovelaceEffect?.isNegative()) {
        return [
          {
            ...commonEntryFields,
            type: TxSummaryType.RECEIVED,
            received: lovelaceEffect,
            currency: 'ADA',
            isToken: false,
          },
          ...createTokenEntries(
            tokenBundleEffect,
            tokensMetadata,
            commonEntryFields,
          ),
        ]
      } else {
        return [
          {
            ...commonEntryFields,
            type: TxSummaryType.SENT,
            sent: lovelaceEffect?.abs().minus(fee) as Lovelaces,
            fee,
            currency: 'ADA',
            isToken: false,
          },
          ...createTokenEntries(
            tokenBundleEffect,
            tokensMetadata,
            commonEntryFields,
          ),
        ]
      }
    }),
  )

/**
 * Exported for testing purposes only, call createCSVRows instead
 */
export const _createCSVRows = async (
  csvConstants: CSVConstants,
  txHistoriesPerAccount: CSVCardanoTxHistoryEntry[][],
  stakingHistoriesPerAccount: CSVCardanoStakingHistoryEntry[][],
  tokensMetadata: _.Dictionary<CardanoTokenMetadata>,
) => {
  const {delimiter} = csvConstants

  const transactionsEntries: TxEntry[] = createTransactionEntries(
    txHistoriesPerAccount,
    tokensMetadata,
  )

  const rewardsEntries = createRewardsEntries(
    stakingHistoriesPerAccount.flatMap(
      (stakingHistory) =>
        stakingHistory.filter(
          (item) => item.type === 'reward',
        ) as Array<CSVCardanoRewardDistribution>,
    ),
  )

  const entries: Array<TxEntry> = _.sortBy(
    [...transactionsEntries, ...rewardsEntries],
    (entry) => entry.timeIssued.getTime(),
  )

  return entries.map((entry) => {
    const printAmount = (num: Lovelaces | BigNumber) =>
      entry.isToken
        ? formatCSVTokenAmount(num, entry.decimals || 0)
        : formatCSVAmount(num, 'cardano')
    return {
      date: entry.timeIssued,
      row: [
        formatToUTC(entry.timeIssued),
        entry.txHash,
        entry.address,
        entry.accountName,
        entry.type,
        entry.received && printAmount(entry.received),
        entry.received !== undefined ? entry.currency : undefined,
        entry.sent && printAmount(entry.sent),
        entry.sent !== undefined ? entry.currency : undefined,
        entry.fee && formatCSVAmount(entry.fee, 'cardano'),
        entry.fee !== undefined ? 'ADA' : undefined,
      ]
        .map((value) =>
          value === undefined ? delimiter : `${value}${delimiter}`,
        )
        .join(''),
    }
  })
}

export const createCSVRows: CreateCSVRows<CardanoAccountInfo> = async (
  csvConstants: CSVConstants,
  accounts: CardanoAccountInfo[],
  startDate: Date,
  endDate: Date,
) => {
  const accountIds = accounts.map(({id}) => id)
  if (accountIds.length === 0) return []

  const txHistoriesPerAccount: CSVCardanoTxHistoryEntry[][] =
    filterEntriesByDate(
      await getTransactionHistory(accounts),
      startDate,
      endDate,
    )

  const stakingHistoriesPerAccount = filterEntriesByDate(
    await getAccountsStakeHistory(accounts),
    startDate,
    endDate,
  )

  const tokensMetadata = _.keyBy(
    await getTokensMetadata(
      _(txHistoriesPerAccount)
        .chain()
        .flatten()
        .flatMap((txHistory) =>
          txHistory.tokenBundleEffect.map(
            (cardanoTokenAmount) => cardanoTokenAmount.token,
          ),
        )
        .uniqBy((cardanoToken) => cardanoToken.id)
        .value(),
    ),
    (cardanoTokenMetadata) => cardanoTokenMetadata.id,
  )

  return _createCSVRows(
    csvConstants,
    txHistoriesPerAccount,
    stakingHistoriesPerAccount,
    tokensMetadata,
  )
}
