import {
  Typography,
  Box,
  Grid,
  TextField,
  ButtonGroup,
  lighten,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {safeAssertUnreachable} from '@nufi/frontend-common'
import {
  DEFAULT_MNEMONIC_LENGTH,
  validMnemonicLengths,
} from '@nufi/wallet-common'
import clsx from 'clsx'
import type {FormikHelpers} from 'formik'
import {Formik, useFormikContext} from 'formik'
import _ from 'lodash'
import React, {useState} from 'react'
import {useTranslation, Trans} from 'react-i18next'
import * as yup from 'yup'

import {hasCloudSyncBackup} from 'src/features/profileSync/application'

import type {
  ProfilePassword,
  ProfileName,
  ProfileMetadata,
} from '../../../appStorage'
import {
  useRestoreProfileFromMnemonic,
  useCheckProfileConflictFromMnemonic,
} from '../../../appStorage'
import {ProfileConflictCode} from '../../../appStorage/constants'
import {
  NavigateBack,
  MnemonicField,
  Button,
  MutationGuard,
  useModalSharedStyles,
  createResponsiveClasses,
  createCustomResponsiveClass,
  Alert,
} from '../../../components'
import config from '../../../config'
import {trackProfileAction} from '../../../tracking'
import {assert} from '../../../utils/assertion'
import type {ArrayWithUndefined} from '../../../utils/form'
import {getHasFormError} from '../../../utils/form'
import type {Blockchain, Mnemonic, ValidMnemonicLength} from '../../../wallet'
import {
  validateMnemonic,
  isMnemonicWordValid,
  isMnemonicLengthValid,
} from '../../../wallet'
import {ChooseBlockchains} from '../ChooseBlockchains'
import {NavigateBackWrapper, useCommonProfileStyles} from '../common'
import {TermsAndConditionsCheckbox} from '../TermsAndConditionsCheckbox'

import {
  commonInitialValues,
  useRecoverSchemaWithoutUniqueProfileName,
} from './common'
import type {CommonRestoreFormSchema} from './types'

const normalizeMnemonicWord = (word: string) => word.trim()

const wordsToMnemonic = (words: string[]) =>
  words.map((v) => normalizeMnemonicWord(v)).join(' ') as Mnemonic

type MnemonicWords = Array<string>

type RestoreFromMnemonicProps = {
  onSuccess: () => unknown
  onScreenChanged: (screenChanged: RestoreFromMnemonicScreenType) => void
}

type FormData = CommonRestoreFormSchema & {
  confirmedPassword: string
  mnemonic: MnemonicWords
  mnemonicLength: ValidMnemonicLength
  recoverMnemonic: string
  selectedBlockchains: Blockchain[]
}
type FormField = keyof FormData

export default function RestoreFromMnemonic({
  onSuccess,
  onScreenChanged,
}: RestoreFromMnemonicProps) {
  const restoreProfile = useRestoreProfileFromMnemonic()

  const onSubmit = async (values: FormData, isProfileSynced: boolean) => {
    await restoreProfile.mutateAsync({
      profileName: values.profileName as ProfileName,
      mnemonic: wordsToMnemonic(values.mnemonic),
      password: values.password as ProfilePassword,
      ...(isProfileSynced
        ? {isProfileSynced}
        : {isProfileSynced, enabledBlockchains: values.selectedBlockchains!}),
    })
    onSuccess()
    trackProfileAction('restore_mnemonic_profile')
  }

  return (
    <RestoreFromMnemonicContent
      onSubmit={onSubmit}
      onScreenChanged={onScreenChanged}
      isRecovering={restoreProfile.isPending}
    />
  )
}

type RestoreFromMnemonicContentProps = {
  onSubmit: (values: FormData, isProfileSynced: boolean) => Promise<void>
  onScreenChanged: (screenChanged: RestoreFromMnemonicScreenType) => void
  isRecovering: boolean
}

type RestoreFromMnemonicScreenType =
  | 'setMnemonic'
  | 'setProfileProperties'
  | 'selectBlockchains'

export function RestoreFromMnemonicContent({
  onSubmit,
  onScreenChanged,
  isRecovering,
}: RestoreFromMnemonicContentProps) {
  const {t} = useTranslation()
  const {fetch: checkProfileConflict} = useCheckProfileConflictFromMnemonic()
  const [existingProfile, setExistingProfile] =
    useState<ProfileMetadata | null>(null)
  const [activeScreen, setActiveScreen] =
    useState<RestoreFromMnemonicScreenType>('setMnemonic')

  const _setActiveScreen = (screen: RestoreFromMnemonicScreenType) => {
    setActiveScreen(screen)
    onScreenChanged(screen)
  }

  const baseRecoverSchema = useRecoverSchemaWithoutUniqueProfileName()

  const _formSchema = {
    setMnemonic: {
      termsChecked: baseRecoverSchema.termsChecked,
      mnemonicLength: yup.number(),
      mnemonic: yup
        .array()
        .test(
          'mnemonic-is-required',
          t('Please fill in all the words.'),
          (value: ArrayWithUndefined<MnemonicWords> | undefined) => {
            if (value === undefined) return true
            return !value.some((v) => !v)
          },
        )
        .test(
          'mnemonic-is-valid',
          t('Invalid mnemonic'),
          (value: ArrayWithUndefined<MnemonicWords> | undefined) => {
            if (value === undefined) return true
            const mnemonic = value
              .map((v) => (v !== undefined ? normalizeMnemonicWord(v) : ''))
              .join(' ') as Mnemonic
            return validateMnemonic(mnemonic)
          },
        ),
    },
    setProfileProperties: {
      password: baseRecoverSchema.password,
      confirmedPassword: yup
        .string()
        .oneOf([yup.ref('password')], t('Passwords must match.'))
        .required(t('Passwords must match.')),
      profileName: baseRecoverSchema.profileName.test(
        'different-mnemonic-wallet-name-conflict',
        t('This name is already used for another wallet.'),
        async (value, context) => {
          if (value === undefined) return true
          const {mnemonic}: FormData = context.parent
          if (!mnemonic) return true
          const profileConflictResults = await checkProfileConflict({
            mnemonic: wordsToMnemonic(mnemonic),
            loginType: 'password',
            profileName: value as ProfileName,
          })
          return (
            profileConflictResults?.code !==
            ProfileConflictCode.DIFFERENT_PROFILE_NAME_CONFLICT
          )
        },
      ),
      recoverMnemonic: yup.string(),
    },
    selectBlockchains: {},
  }
  const formSchema = yup.object().shape(_formSchema[activeScreen])
  const initialMnemonicLength = (
    config.demoMnemonic
      ? config.demoMnemonic.split(' ').length
      : DEFAULT_MNEMONIC_LENGTH
  ) as ValidMnemonicLength
  assert(isMnemonicLengthValid(initialMnemonicLength))
  const initialValues: FormData = {
    ...commonInitialValues,
    mnemonic: config.demoMnemonic
      ? config.demoMnemonic.split(' ')
      : _.range(0, DEFAULT_MNEMONIC_LENGTH).map(() => ''),
    mnemonicLength: initialMnemonicLength,
    confirmedPassword: '',
    recoverMnemonic: '',
    selectedBlockchains: ['cardano'],
  }

  const onFormSubmit = {
    setMnemonic: async (
      values: FormData,
      {resetForm, setSubmitting}: FormikHelpers<FormData>,
    ) => {
      _setActiveScreen('setProfileProperties')
      setSubmitting(false)
      const profileConflictResults = await checkProfileConflict({
        mnemonic: wordsToMnemonic(values.mnemonic),
        loginType: 'password',
        profileName: values.profileName as ProfileName,
      })
      assert(profileConflictResults != null)
      const mnemonicExists =
        profileConflictResults.code ===
        ProfileConflictCode.SAME_PROFILE_EXISTS_CONFLICT

      if (mnemonicExists) {
        setExistingProfile(profileConflictResults.existingProfile)
      }
      resetForm({
        values: {
          ...values,
          ...(mnemonicExists && {
            profileName: profileConflictResults.existingProfile.name,
          }),
        },
      })

      return
    },
    setProfileProperties: async (
      values: FormData,
      {setErrors, setSubmitting}: FormikHelpers<FormData>,
    ) => {
      if (
        await hasCloudSyncBackup({
          mnemonic: wordsToMnemonic(values.mnemonic),
        }).catch(() => false)
      ) {
        try {
          await onSubmit(values, true)
        } catch (_err: unknown) {
          setErrors({recoverMnemonic: t('Unexpected error.')})
        }
      } else {
        _setActiveScreen('selectBlockchains')
      }
      setSubmitting(false)
    },
    selectBlockchains: async (
      values: FormData,
      {setErrors, setSubmitting}: FormikHelpers<FormData>,
    ) => {
      try {
        await onSubmit(values, false)
      } catch (_err: unknown) {
        setErrors({recoverMnemonic: t('Unexpected error.')})
      } finally {
        setSubmitting(false)
      }
    },
  }[activeScreen]

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={formSchema}
      onSubmit={onFormSubmit}
    >
      {({values, resetForm}) => {
        switch (activeScreen) {
          case 'setMnemonic':
            return <SetMnemonicScreen isRecovering={isRecovering} />
          case 'setProfileProperties':
            return (
              <SetProfilePropertiesScreen
                onBack={() => {
                  resetForm({
                    values: {
                      ...values,
                      profileName: commonInitialValues.profileName,
                      password: commonInitialValues.password,
                      confirmedPassword: commonInitialValues.password,
                    },
                  })
                  setExistingProfile(null)
                  _setActiveScreen('setMnemonic')
                }}
                {...{isRecovering, existingProfile}}
              />
            )
          case 'selectBlockchains':
            return (
              <ChooseBlockchainsScreen
                onBack={() => _setActiveScreen('setProfileProperties')}
                {...{isRecovering}}
              />
            )
          default:
            return safeAssertUnreachable(activeScreen)
        }
      }}
    </Formik>
  )
}

type SetMnemonicScreenProps = {
  isRecovering: boolean
}

const SetMnemonicScreen = ({isRecovering}: SetMnemonicScreenProps) => {
  const {t} = useTranslation()
  const commonClasses = useCommonProfileStyles()
  const classes = {...useStyles(), ...useModalSharedStyles()}

  const formikProps = useFormikContext<FormData>()
  const {handleSubmit, errors, values, isSubmitting, setFieldValue} =
    formikProps

  const hasError = getHasFormError(formikProps)

  const errorWords = values.mnemonic
    .map((w) => normalizeMnemonicWord(w))
    .filter((w) => !isMnemonicWordValid(w))

  const getOnChange = (value: ValidMnemonicLength) => () => {
    assert(isMnemonicLengthValid(value))
    const mnemonicLengthFieldName: FormField = 'mnemonicLength'
    setFieldValue(mnemonicLengthFieldName, value)

    const mnemonicFieldName: FormField = 'mnemonic'
    const newMnemonic = _.range(0, value).map(() => '')
    setFieldValue(mnemonicFieldName, newMnemonic)
  }

  return (
    <form
      className={clsx(commonClasses.form, commonClasses.spacing)}
      onSubmit={handleSubmit}
      noValidate
    >
      <Box>
        <Grid
          container
          justifyContent="flex-end"
          alignItems="center"
          className={classes.upperContainer}
        >
          <ButtonGroup variant="contained">
            {validMnemonicLengths.map((length) => (
              <Button
                key={length}
                rtl-data-test-id={`mnemonic-length-${length}`}
                className={clsx(
                  classes.button,
                  values.mnemonicLength === length && classes.activeButton,
                  values.mnemonicLength !== length && classes.inactiveButton,
                )}
                size="small"
                onClick={getOnChange(length)}
                color={values.mnemonicLength === length ? 'primary' : 'default'}
              >
                {length}
              </Button>
            ))}
          </ButtonGroup>
        </Grid>
        <MnemonicField
          value={values.mnemonic}
          onChange={(value) => {
            // Note: setFieldValue is not generic
            const fieldName: FormField = 'mnemonic'
            setFieldValue(fieldName, value)
          }}
          hasError={hasError('mnemonic')}
          errorWords={errorWords}
        />
        {hasError('mnemonic') && errors.mnemonic && (
          <Box mt={0.5}>
            <Typography variant="caption" color="error">
              {errors.mnemonic}
            </Typography>
          </Box>
        )}
      </Box>
      <TermsAndConditionsCheckbox
        value={formikProps.values.termsChecked}
        onChange={formikProps.handleChange<keyof typeof formikProps.values>(
          'termsChecked',
        )}
        error={getHasFormError(formikProps)('termsChecked')}
        helperText={formikProps.errors.termsChecked}
      />
      <Box>
        <Grid item xs={12}>
          <Button
            fullWidth
            textTransform="none"
            color="primary"
            variant="contained"
            type="submit"
            disabled={isSubmitting}
          >
            {t('Continue')}
          </Button>
        </Grid>

        <NavigateBackWrapper>
          <NavigateBack />
        </NavigateBackWrapper>
      </Box>

      {/* Passing error={null} as we are handling error manually */}
      <MutationGuard isPending={isRecovering} error={null} />
    </form>
  )
}

type SetProfilePropertiesScreenProps = {
  onBack: () => void
  isRecovering: boolean
  existingProfile: ProfileMetadata | null
}

const SetProfilePropertiesScreen = ({
  onBack,
  isRecovering,
  existingProfile,
}: SetProfilePropertiesScreenProps) => {
  const {t} = useTranslation()
  const commonClasses = useCommonProfileStyles()
  const classes = {...useStyles(), ...useModalSharedStyles()}

  const formikProps = useFormikContext<FormData>()
  const {values, handleSubmit, handleChange, errors, isSubmitting} = formikProps
  const hasError = getHasFormError(formikProps)

  const profileExists = existingProfile != null

  return (
    <form
      className={clsx(commonClasses.form, commonClasses.spacing)}
      onSubmit={handleSubmit}
      noValidate
    >
      <Box className={classes.commonTopMargin}>
        <Grid container>
          <Grid item xs={12} className={classes.formField}>
            {profileExists ? (
              <Alert
                rtl-data-test-id="overwrite-existing-wallet-alert"
                severity="error"
                text={
                  <Trans
                    i18nKey="overwrite_existing_wallet_alert"
                    t={t}
                    components={{
                      b: <b />,
                    }}
                    values={{existingProfileName: existingProfile.name}}
                  />
                }
              />
            ) : (
              <Typography variant="body1" color="textSecondary">
                {t(
                  'Your wallet name and password will be used to access your wallet on NuFi.',
                )}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} className={classes.formField}>
            <TextField
              data-test-id="wallet-name-field"
              value={values.profileName}
              onChange={handleChange<FormField>('profileName')}
              label={
                profileExists ? t('Existing wallet name') : t('Wallet name')
              }
              error={hasError('profileName')}
              helperText={hasError('profileName') && <>{errors.profileName}</>}
              inputProps={{
                'rtl-data-test-id': 'wallet-name-field',
              }}
              variant="outlined"
              fullWidth
              autoFocus
            />
          </Grid>
          <Grid item xs={12} className={classes.formField}>
            <TextField
              data-test-id="wallet-password-field"
              value={values.password}
              onChange={handleChange<FormField>('password')}
              label={t('Password')}
              type="password"
              error={hasError('password')}
              helperText={hasError('password') && <>{errors.password}</>}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} className={classes.formField}>
            <TextField
              data-test-id="wallet-password-confirm-field"
              value={values.confirmedPassword}
              onChange={handleChange<FormField>('confirmedPassword')}
              label={t('Confirm password')}
              type="password"
              error={hasError('confirmedPassword')}
              helperText={
                hasError('confirmedPassword') && <>{errors.confirmedPassword}</>
              }
              fullWidth
              variant="outlined"
            />
          </Grid>
        </Grid>
      </Box>
      {profileExists ? (
        <Button
          fullWidth
          textTransform="none"
          color="error"
          variant="contained"
          type="submit"
          disabled={isSubmitting}
        >
          {t('Overwrite my wallet')}
        </Button>
      ) : (
        <Button
          fullWidth
          textTransform="none"
          color="primary"
          variant="contained"
          type="submit"
          disabled={isSubmitting}
        >
          {t('Continue')}
        </Button>
      )}

      <NavigateBackWrapper>
        <NavigateBack onBack={onBack} />
      </NavigateBackWrapper>
      {/* Passing error={null} as we are handling error manually */}
      <MutationGuard isPending={isRecovering} error={null} />
    </form>
  )
}

type ChooseBlockchainsScreenProps = {
  onBack: () => void
  isRecovering: boolean
}

const ChooseBlockchainsScreen = ({
  onBack,
  isRecovering,
}: ChooseBlockchainsScreenProps) => {
  const {t} = useTranslation()
  const commonClasses = useCommonProfileStyles()
  const classes = {...useStyles(), ...useModalSharedStyles()}

  const formikProps = useFormikContext<FormData>()
  const {values, handleSubmit, setFieldValue, isSubmitting, errors} =
    formikProps

  const hasError = getHasFormError(formikProps)

  const onSubmit = (blockchains: Blockchain[]) => {
    const selectedBlockchainsFieldName: FormField = 'selectedBlockchains'
    setFieldValue(selectedBlockchainsFieldName, blockchains)
  }

  return (
    <form
      className={clsx(commonClasses.form, commonClasses.spacing)}
      onSubmit={handleSubmit}
    >
      <Box className={classes.commonTopMargin}>
        <Grid container>
          <Grid item xs={12} className={classes.formField}>
            <ChooseBlockchains
              preselectedBlockchains={values.selectedBlockchains}
              onSubmit={onSubmit}
              disabled={isSubmitting}
              customButtonLabel={t('Recover')}
            />
          </Grid>
          <Grid item xs={12} className={classes.formField}>
            {hasError('recoverMnemonic') && errors.recoverMnemonic && (
              <Box mt={0.5}>
                <Typography variant="caption" color="error">
                  <>{errors.recoverMnemonic}</>
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>

        <NavigateBackWrapper>
          <NavigateBack onBack={onBack} />
        </NavigateBackWrapper>
        {/* Passing error={null} as we are handling error manually */}
        <MutationGuard isPending={isRecovering} error={null} />
      </Box>
    </form>
  )
}

const useStyles = makeStyles((theme) => ({
  formTitle: {
    ...createResponsiveClasses(theme, [4, 'marginTop']),
  },
  modalContent: {
    ...createCustomResponsiveClass(
      theme,
      {old: 450, zoomed: 550, default: 600},
      'maxWidth',
    ),
  },
  responsiveH5: {
    fontSize: '1.15rem',
    [theme.breakpoints.up('windowsZoomed')]: {
      fontSize: '1.5rem',
    },
  },
  button: {
    minHeight: 30,
  },
  activeButton: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: lighten(theme.palette.background.paper, 0.04),
    },
  },
  inactiveButton: {
    backgroundColor: theme.palette.background.default,
    '&:hover': {
      backgroundColor: lighten(theme.palette.background.default, 0.04),
    },
  },
  upperContainer: {
    marginBottom: theme.spacing(1.5),
  },
}))
