import {Menu as MenuIcon, Help as LearnMoreIcon} from '@mui/icons-material'
import {Box, Divider, Grid, Tab, Tabs, Typography} from '@mui/material'
import {assert} from '@nufi/frontend-common'
import type {AccountId} from '@nufi/wallet-common'
import queryString from 'query-string'
import React, {Suspense, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {
  matchPath,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom'

import type {SignScreenContext} from 'src/features/dex/dexHunter/application'
import {useInjectAppCardanoWalletConnector} from 'src/features/dex/dexHunter/application'
import {DexHunterSignScreen} from 'src/features/dex/dexHunter/ui'
import {routeTo} from 'src/router'
import {useAuthStore} from 'src/store/auth'
import {useScrollableStyles, useTabsStyles} from 'src/utils/layoutUtils'

import {
  Modal,
  AssetIconModalHeader,
  ModalLayout,
  QueryGuard,
  FullScreenLoading,
  SplitButton,
} from '../../components'
import theme from '../../theme/theme'
import {useGetBlockchainName} from '../../utils/translations'
import type {CardanoAccountInfo} from '../../wallet/cardano'
import {useGetAccounts} from '../../wallet/cardano'
import {NoAccountsScreen} from '../portfolio/account/NoAccountsScreen'

import {AccountSelectField} from './AccountSelectField'
import {OrderHistory} from './OrderHistory'

const DexHunterInterface = React.lazy(
  () => import('src/features/dex/dexHunter/ui/DexHunterInterface'),
)

const DexHunterContent = ({accounts}: {accounts: CardanoAccountInfo[]}) => {
  const {t} = useTranslation()
  const history = useHistory()
  const location = useLocation()

  const {selectedAccount, setSelectedAccount} =
    useSelectedAccountState(accounts)
  const [signScreenContext, setSignScreenContext] =
    useState<SignScreenContext | null>(null)
  const connector = useInjectAppCardanoWalletConnector(
    selectedAccount,
    setSignScreenContext,
  )
  const {commonTabStyles} = useTabsStyles()

  const onTabChange = (event: React.ChangeEvent<unknown>, value: string) => {
    history.replace(value)
  }

  const tabValue = (() => {
    if (matchPath(location.pathname, routeTo.dexHunter.orderHistory)) {
      return routeTo.dexHunter.orderHistory
    }
    return routeTo.dexHunter.index
  })()

  const scrollableClasses = useScrollableStyles()

  return (
    <Suspense fallback={<FullScreenLoading />}>
      <Box className={scrollableClasses.scrollableParent}>
        {selectedAccount && connector && signScreenContext && (
          <MainAppDexHunterSignScreenModal
            {...{signScreenContext, selectedAccount}}
          />
        )}
        <Tabs
          className={commonTabStyles}
          {...{value: tabValue, onChange: onTabChange}}
          indicatorColor="primary"
          textColor="primary"
          sx={{flexBasis: 'fit-content'}}
        >
          <Tab value={routeTo.dexHunter.index} label={t('Swap')} />
          <Tab
            value={routeTo.dexHunter.orderHistory}
            label={t('Orders History')}
          />
        </Tabs>
        <Switch>
          <Route exact path={routeTo.dexHunter.index}>
            <Box
              sx={{
                display: 'flex',
                height: '100%',
                paddingBottom: theme.spacing(2),
              }}
            >
              <Grid
                container
                sx={{
                  background: theme.palette.background.paper,
                  padding: theme.spacing(3),
                  borderRadius: theme.spacing(3),
                  display: 'flex',
                  direction: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: DEXHUNTER_UI_WIDTHS.ROOT,
                  margin: 'auto',
                }}
              >
                <Grid
                  item
                  sx={{
                    marginBottom: theme.spacing(2),
                    width: '100%',
                  }}
                >
                  <Title />
                </Grid>

                <Grid item width={DEXHUNTER_UI_WIDTHS.CHILD}>
                  <AccountSelectField
                    {...{accounts, selectedAccount, setSelectedAccount}}
                  />
                </Grid>

                <Grid
                  item
                  sx={{
                    paddingTop: theme.spacing(2),
                    paddingBottom: theme.spacing(1),
                    width: DEXHUNTER_UI_WIDTHS.CHILD,
                  }}
                >
                  <Divider />
                </Grid>

                {selectedAccount && connector && (
                  <Grid item>
                    <DexHunterInterface
                      width={530}
                      selectedAccount={selectedAccount}
                    />
                  </Grid>
                )}
              </Grid>
            </Box>
          </Route>
          <Route path={routeTo.dexHunter.orderHistory}>
            {selectedAccount && connector && (
              <OrderHistory
                accounts={accounts}
                setSelectedAccount={setSelectedAccount}
                selectedAccount={selectedAccount}
                connector={connector}
              />
            )}
          </Route>
        </Switch>
      </Box>
    </Suspense>
  )
}

const Title = () => {
  const {t} = useTranslation()
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
      }}
    >
      <Typography variant="h6">
        {t('Swap Cardano tokens with DexHunter')}
      </Typography>
      <Box position="absolute" right={0}>
        <SplitButton
          popperPlacement="bottom-end"
          icon={<MenuIcon />}
          options={[
            {
              label: t('Learn more'),
              onClick: () => {
                window.open(
                  'https://support.nu.fi/support/solutions/articles/80001129458',
                )
              },
              Icon: LearnMoreIcon,
            },
          ]}
        />
      </Box>
    </Box>
  )
}

export const MainAppDexHunterSignScreenModal = ({
  signScreenContext,
  selectedAccount,
}: {
  signScreenContext: SignScreenContext
  selectedAccount: CardanoAccountInfo
}) => {
  const getBlockchainName = useGetBlockchainName()
  const {authState} = useAuthStore()
  assert(authState.status === 'logged_in')

  return (
    <Modal variant="left" maxWidthOverride={450}>
      <ModalLayout
        header={
          <AssetIconModalHeader
            onClose={() => signScreenContext.onFailure()}
            blockchain="cardano"
          >
            <Typography variant="body1">{'DexHunter'}</Typography>
            <Typography variant="body2" color="textSecondary">
              {getBlockchainName('cardano')}
            </Typography>
          </AssetIconModalHeader>
        }
        body={
          <Box
            sx={{
              padding: theme.spacing(4),
            }}
          >
            <DexHunterSignScreen
              signScreenContext={signScreenContext}
              selectedAccount={selectedAccount}
              isPasswordLogin={authState.info.loginType === 'password'}
            />
          </Box>
        }
      />
    </Modal>
  )
}

export const DexHunterPage = () => {
  const accountsQuery = useGetAccounts()
  return (
    <QueryGuard {...accountsQuery}>
      {(accounts) => {
        if (accounts.length === 0) {
          return <NoAccountsScreen blockchain={'cardano'} />
        }
        return <DexHunterContent accounts={accounts} />
      }}
    </QueryGuard>
  )
}

type QueryStringAccountInfo = {
  accountId: AccountId
}

export const encodeDefaultAccountId = (
  accountId: AccountId,
): QueryStringAccountInfo => ({
  accountId,
})

const useGetDefaultAccountId = () => {
  const location = useLocation()

  try {
    const parsed = queryString.parse(location.search) as
      | QueryStringAccountInfo
      | undefined
    return parsed?.accountId || null
  } catch (err) {
    return null
  }
}

const useSelectedAccountState = (accounts: CardanoAccountInfo[]) => {
  const history = useHistory()

  const defaultAccountId = useGetDefaultAccountId()
  const [selectedAccount, setSelectedAccount] =
    useState<CardanoAccountInfo | null>(null)

  useEffect(function setDefaultAccountOnMount() {
    if ((!selectedAccount && defaultAccountId) || accounts.length > 0) {
      const defaultAccount = accounts.find((a) => a.id === defaultAccountId)
      setSelectedAccount(defaultAccount || accounts[0]!)
      // drop search string from url
      history.replace({search: ''})
    }
  }, [])

  return {selectedAccount, setSelectedAccount}
}

const DEXHUNTER_UI_WIDTHS = {
  ROOT: 500,
  CHILD: 430,
}
