import type {Web3AuthNetworkType, Web3AuthLoginProvider} from '../types'

export const supportedLoginProviders = [
  'google',
  'discord',
  'facebook',
] as const satisfies Web3AuthLoginProvider[]
export type SupportedWeb3AuthLoginProvider =
  (typeof supportedLoginProviders)[number]

type VerifierConfig = {
  clientId: string
  verifier: string
}
export const verifierMaps: Partial<
  Record<
    Web3AuthNetworkType,
    Record<SupportedWeb3AuthLoginProvider, VerifierConfig>
  >
> = {
  sapphire_devnet: {
    google: {
      clientId:
        '1098069246895-7vciduugtpnegfgusd6m6dipo8qbt251.apps.googleusercontent.com',
      verifier: 'nufi-w3a-google-test',
    },
    discord: {
      clientId: '1202579284858773554',
      verifier: 'nufi-w3a-discord-test',
    },
    facebook: {
      clientId: '299465176445302',
      verifier: 'nufi-w3a-facebook-test',
    },
  },
  sapphire_mainnet: {
    google: {
      clientId:
        '1098069246895-5ns46eiu0ii3hlia74qi9fb9pbuhr747.apps.googleusercontent.com',
      verifier: 'nufi-w3a-google',
    },
    discord: {
      clientId: '1198914229370114078',
      verifier: 'nufi-w3a-discord',
    },
    facebook: {
      clientId: '926052998552403',
      verifier: 'nufi-w3a-facebook',
    },
  },
}
