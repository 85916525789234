import {useEffect} from 'react'
import {useLocation} from 'react-router-dom'

import {removeUrlParams, tracking} from '../sdk'

const trackPageView = (location: string): void => {
  tracking.send({hitType: 'pageview', page_location: location})
}

// for page views that don't trigger url change, and use local state for changing content
// we create an artificial pageview with ":step-{step}"
// so it can be used as regular pageview in stats
export function useStepTracking(step: unknown) {
  useEffect(() => {
    const stepLocation = `${removeUrlParams(
      window.location.toString(),
    )}/:step-${step}`
    trackPageView(stepLocation)
  }, [step])
}

// we need to track pageview manually to avoid sending accountIds and other params in the url
export function usePageViewTracking() {
  const location = useLocation()
  useEffect(() => {
    trackPageView(removeUrlParams(window.location.toString()))
  }, [location])
}
