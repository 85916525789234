/* eslint-disable @typescript-eslint/explicit-function-return-type */

import type {CustomUseMutationResult} from 'src/utils/mutation-utils'
import {blockchainToWalletKind} from 'src/wallet/walletKind'

import type {ProfileTokenImportBlockchain} from '../../blockchainTypes'
import type {Blockchain} from '../../types'
import {
  useVerifyAccountAddress as useVerifyCardanoAccountAddress,
  accountsInvalidationKeys as cardanoAccountsInvalidationKeys,
  tokensInvalidationKeys as cardanoTokensInvalidationKeys,
} from '../cardano'
import {
  getEvmCrossChainAccountInvalidationKeys,
  tokensInvalidationKeys as evmTokensInvalidationKeys,
  useEvmQueryPerBlockchain,
  useVerifyAccountAddress as useVerifyEvmAccountAddress,
} from '../evm'
import {
  accountsInvalidationKeys as flowAccountsInvalidationKeys,
  tokensInvalidationKeys as flowTokensInvalidationKeys,
} from '../flow'
import {
  useVerifyAccountAddress as useVerifySolanaAccountAddress,
  accountsInvalidationKeys as solanaAccountsInvalidationKeys,
  tokensInvalidationKeys as solanaTokensInvalidationKeys,
} from '../solana'
import type {AccountId, WalletKind} from '../types'
import {
  _useRenameAccount,
  _useSetDefaultAccount,
  _useForgetAccount,
} from '../utils/accountsMutations'
import {_useForgetToken} from '../utils/tokenMutations'
import {
  _useHideTokens,
  _useUnhideTokens,
} from '../utils/tokenVisibilityMutations'

export function useVerifyAccountAddress(blockchain: Blockchain) {
  const verificationPerBlockchain = {
    solana: useVerifySolanaAccountAddress(),
    cardano: useVerifyCardanoAccountAddress(),
    // Flow does not support address verification
    flow: {} as CustomUseMutationResult<void, unknown, AccountId, unknown>,
    ...useEvmQueryPerBlockchain((evmBlockchain) =>
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useVerifyEvmAccountAddress(evmBlockchain),
    ),
  }
  return verificationPerBlockchain[blockchain]
}

export function useSetDefaultAccount(blockchain: Blockchain) {
  const walletKind = blockchainToWalletKind(blockchain)
  const mutationPerWalletKind = {
    solana: _useSetDefaultAccount('solana', solanaAccountsInvalidationKeys),
    cardano: _useSetDefaultAccount('cardano', cardanoAccountsInvalidationKeys),
    flow: _useSetDefaultAccount('flow', flowAccountsInvalidationKeys),
    evm: _useSetDefaultAccount(
      blockchain,
      getEvmCrossChainAccountInvalidationKeys(),
    ),
  }
  return mutationPerWalletKind[walletKind]
}

export function useForgetAccount(walletKind: WalletKind) {
  const mutationPerWalletKind = {
    solana: _useForgetAccount('solana', solanaAccountsInvalidationKeys),
    cardano: _useForgetAccount('cardano', cardanoAccountsInvalidationKeys),
    flow: _useForgetAccount('flow', flowAccountsInvalidationKeys),
    evm: _useForgetAccount('evm', getEvmCrossChainAccountInvalidationKeys()),
  }
  return mutationPerWalletKind[walletKind]
}

export function useRenameAccount(walletKind: WalletKind) {
  const mutationPerWalletKind = {
    solana: _useRenameAccount('solana', solanaAccountsInvalidationKeys),
    // TODO: properly rework cardano staking queries to be reactive,
    // then `cardanoRenameAccountInvalidationKeys` will not be needed
    cardano: _useRenameAccount('cardano', cardanoAccountsInvalidationKeys),
    flow: _useRenameAccount('flow', flowAccountsInvalidationKeys),
    evm: _useRenameAccount('evm', getEvmCrossChainAccountInvalidationKeys()),
  }
  return mutationPerWalletKind[walletKind]
}

export function useForgetToken(blockchain: ProfileTokenImportBlockchain) {
  const mutationPerBlockchain = {
    ...useEvmQueryPerBlockchain((evmBlockchain) =>
      // eslint-disable-next-line react-hooks/rules-of-hooks
      _useForgetToken(evmBlockchain, evmTokensInvalidationKeys(evmBlockchain)),
    ),
  }

  return mutationPerBlockchain[blockchain]
}

export function useHideTokens(blockchain: Blockchain) {
  const mutationPerWalletKind = {
    solana: _useHideTokens('solana', solanaTokensInvalidationKeys),
    cardano: _useHideTokens('cardano', cardanoTokensInvalidationKeys),
    flow: _useHideTokens('flow', flowTokensInvalidationKeys),
    ...useEvmQueryPerBlockchain((evmBlockchain) =>
      _useHideTokens(evmBlockchain, evmTokensInvalidationKeys(evmBlockchain)),
    ),
  }
  return mutationPerWalletKind[blockchain]
}

export function useUnhideTokens(blockchain: Blockchain) {
  const mutationPerWalletKind = {
    solana: _useUnhideTokens('solana', solanaTokensInvalidationKeys),
    cardano: _useUnhideTokens('cardano', cardanoTokensInvalidationKeys),
    flow: _useUnhideTokens('flow', flowTokensInvalidationKeys),
    ...useEvmQueryPerBlockchain((evmBlockchain) =>
      _useUnhideTokens(evmBlockchain, evmTokensInvalidationKeys(evmBlockchain)),
    ),
  }
  return mutationPerWalletKind[blockchain]
}
