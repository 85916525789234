import React from 'react'

import {explorerLinkBlockchains} from '../../blockchainTypes'
import type {Blockchain} from '../../types'
import {safeAssertUnreachable} from '../../utils/assertion'
import {isBlockchainSubset} from '../../utils/blockchainGuards'
import {isEvmBlockchain} from '../../wallet/evm'

import {
  CardanoTransactionExplorerLink,
  CardanoValidatorExplorerLink,
} from './cardano'
import type {BlockchainAddressExplorerLinkProps} from './common'
import {ExplorerLink, ExplorerButton} from './common'
import {EvmTransactionExplorerLink} from './evm'
import {FlowTransactionExplorerLink} from './flow'
import {getAddressExplorerLink} from './getLink'
import {SolanaTransactionExplorerLink, SolanaValidatorLink} from './solana'

export * from './getLink'

type TransactionExplorerLinkProps = {
  txId: string
  blockchain: Blockchain
}

export function TransactionExplorerLink({
  txId,
  blockchain,
}: TransactionExplorerLinkProps) {
  if (!isBlockchainSubset(blockchain, explorerLinkBlockchains)) {
    return <ExplorerLink disabled />
  }

  switch (blockchain) {
    case 'cardano':
      return <CardanoTransactionExplorerLink txId={txId} />
    case 'solana':
      return <SolanaTransactionExplorerLink txId={txId} />
    case 'flow':
      return <FlowTransactionExplorerLink txId={txId} />
    default: {
      if (isEvmBlockchain(blockchain)) {
        return (
          <EvmTransactionExplorerLink blockchain={blockchain} txId={txId} />
        )
      }
      return safeAssertUnreachable(blockchain)
    }
  }
}

type ValidatorExplorerLinkProps = {
  blockchain: 'cardano' | 'solana'
  validatorId: string
  validatorName: React.ReactNode
}

export function ValidatorExplorerLink({
  blockchain,
  validatorId,
  validatorName,
}: ValidatorExplorerLinkProps) {
  switch (blockchain) {
    case 'cardano':
      return (
        <CardanoValidatorExplorerLink
          poolId={validatorId}
          poolName={validatorName}
        />
      )
    case 'solana':
      return (
        <SolanaValidatorLink
          validatorId={validatorId}
          validatorName={validatorName}
        />
      )
    default:
      return safeAssertUnreachable(blockchain)
  }
}

type AddressExplorerLinkProps = {
  blockchain: Blockchain
} & BlockchainAddressExplorerLinkProps

export function AddressExplorerLink({
  address,
  blockchain,
  size,
}: AddressExplorerLinkProps) {
  const link = getSafeAddressExplorerLink(address, blockchain)

  if (link == null) {
    return <ExplorerLink disabled />
  }

  return <ExplorerLink {...{size, link}} />
}

type AddressExplorerButtonProps = {
  address: string
  blockchain: Blockchain | null
  disabledTooltipTitle?: string
}

export function AddressExplorerButton({
  address,
  blockchain,
  disabledTooltipTitle,
}: AddressExplorerButtonProps) {
  const link = getSafeAddressExplorerLink(address, blockchain)

  if (link == null) {
    return (
      <ExplorerButton disabled disabledTooltipTitle={disabledTooltipTitle} />
    )
  }

  return <ExplorerButton disabled={false} {...{link}} />
}

export function getSafeAddressExplorerLink(
  address: string,
  blockchain: Blockchain | null,
): string | undefined {
  return !isBlockchainSubset(blockchain, explorerLinkBlockchains)
    ? undefined
    : getAddressExplorerLink(address, blockchain)
}
