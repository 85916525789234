import config from 'src/config'
import {getSecretProvider} from 'src/wallet/secretProvider'

import type {GetNufiAuthSecret} from '../domain'
import {
  NufiAuthTokenManager,
  getNufiAuthSecretFromSecretProvider,
} from '../domain'
import {makeAuthenticatedRequest, nufiAuthApi} from '../infrastructure'

export const createAuthTokenManager = (getSecret: GetNufiAuthSecret) =>
  new NufiAuthTokenManager(
    getSecret,
    nufiAuthApi,
    config.apiJwtPublicKey,
    config.apiEauthPrefix,
  )

export const nufiAuthTokenManager = createAuthTokenManager(() =>
  getNufiAuthSecretFromSecretProvider(getSecretProvider()),
)

export const authenticatedRequest =
  makeAuthenticatedRequest(nufiAuthTokenManager)
