import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import {useHistory} from 'react-router-dom'

import {ElevatedCard} from '../../../components'
import {routeTo} from '../../../router'

import {ExchangeHistoryListItemLayout} from './ExchangeHistoryListLayout'

export const useExchangeHistoryItemHandlers = (id: string) => {
  const history = useHistory()

  const onClick = () => {
    history.push(routeTo.portfolio.exchange.detail(id))
  }

  return {onClick}
}

type ExchangeHistoryItemLayoutProps = {
  onClick: (e: React.SyntheticEvent) => unknown
  createdAt: React.ReactNode
  from: React.ReactNode
  to: React.ReactNode
  status: React.ReactNode
}

export function ExchangeHistoryItemLayout({
  onClick,
  createdAt,
  from,
  to,
  status,
}: ExchangeHistoryItemLayoutProps) {
  const classes = useStyles()

  return (
    <ElevatedCard
      type="light"
      tableCard
      onClick={onClick}
      className={classes.wrapper}
    >
      <ExchangeHistoryListItemLayout
        createdAtCol={createdAt}
        fromCol={from}
        toCol={to}
        statusCol={status}
      />
    </ElevatedCard>
  )
}

export const EXCHANGE_HISTORY_ITEM_HEIGHT = 44

const useStyles = makeStyles((theme) => ({
  wrapper: {
    cursor: 'pointer',
    height: EXCHANGE_HISTORY_ITEM_HEIGHT,
    background: theme.palette.background.paper,
  },
}))
