import type {SelectChangeEvent} from '@mui/material'
import {Box} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import type {TokenBlockchain} from '../../../../../blockchainTypes'
import {
  AccountSelectField,
  Alert,
  BlockchainSelectField,
  LoadBlockchainAccountsModalError,
  ModalLayout,
  QueryGuard,
  useModalSharedStyles,
} from '../../../../../components'
import type {AccountInfo} from '../../../../../types'
import type {SelectChangeFn} from '../../../../../utils/form'
import {useGetBlockchainName} from '../../../../../utils/translations'
import type {AccountId} from '../../../../../wallet'
import {useGetAccounts} from '../../../../../wallet'
import type {EvmBlockchain} from '../../../../../wallet/evm'
import {evmBlockchains} from '../../../../../wallet/evm'

import {
  ImportTokenModalFooter,
  ImportTokenModalHeader,
  useTokenBlockchains,
} from './ImportTokenUtils'
import type {AccountDetailsFormData} from './types'

type SelectAccountScreenProps = {
  onClose: () => void
  onSubmit: () => void
  formData: AccountDetailsFormData
  setFormData: (values: AccountDetailsFormData) => void
}

export default function SelectAccountScreen({
  onClose,
  onSubmit,
  formData,
  setFormData,
}: SelectAccountScreenProps) {
  const {t} = useTranslation()
  const classes = useModalSharedStyles()
  const {blockchain, accountId} = formData
  const accountsQuery = useGetAccounts(blockchain)
  const getBlockchainName = useGetBlockchainName()
  const {blockchains, blockchainsWithTokenAutoImport} = useTokenBlockchains()

  const onChangeBlockchain = (blockchain: TokenBlockchain) => {
    setFormData({blockchain, accountId: undefined})
  }

  const onAccountChange = (e: SelectChangeEvent) => {
    setFormData({
      ...formData,
      accountId: e.target.value as AccountId,
    })
  }

  const alertTexts: {[key in TokenBlockchain]: string} = {
    cardano: t('automatic_import_token_info', {
      blockchain: getBlockchainName('cardano'),
    }),
    solana: t('automatic_import_token_info', {
      blockchain: getBlockchainName('solana'),
    }),
    flow: t('flow_import_token_info'),
    ...(Object.fromEntries(
      evmBlockchains.map((evmBlockchain) => [
        evmBlockchain,
        t('evm_import_token_info', {
          blockchain: getBlockchainName(evmBlockchain),
        }),
      ]),
    ) as Record<EvmBlockchain, string>),
  }

  return (
    <ModalLayout
      header={<ImportTokenModalHeader {...{onClose}} />}
      body={
        <Box p={2} minWidth={500}>
          <Box className={classes.formField}>
            <BlockchainSelectField
              label={t('Blockchain')}
              allowedBlockchains={blockchains}
              value={blockchain}
              onChange={(value) => value && onChangeBlockchain(value)}
            />
          </Box>
          {!blockchainsWithTokenAutoImport.includes(blockchain) && (
            <QueryGuard
              {...accountsQuery}
              ErrorElement={
                <LoadBlockchainAccountsModalError blockchain={blockchain} />
              }
              loadingVariant="centered"
            >
              {(accounts: AccountInfo[]) => (
                <Box className={classes.formField}>
                  <_AccountSelectField
                    {...{accountId, accounts, blockchain}}
                    onChange={onAccountChange}
                  />
                </Box>
              )}
            </QueryGuard>
          )}
          <Box className={classes.formField}>
            <Alert
              text={alertTexts[blockchain]}
              severity="info"
              className={classes.commonBottomMargin}
            />
          </Box>
        </Box>
      }
      footer={
        <ImportTokenModalFooter
          {...{onClose, onSubmit, blockchain, accountId}}
        />
      }
    />
  )
}

type AccountSelectFieldProps = {
  accountId?: AccountId
  accounts: AccountInfo[]
  blockchain: TokenBlockchain
  onChange: SelectChangeFn<AccountId>
}

const _AccountSelectField = ({
  accountId,
  accounts,
  blockchain,
  onChange,
}: AccountSelectFieldProps) => {
  const {t} = useTranslation()
  const {blockchainsWithTokenAutoImport} = useTokenBlockchains()
  const accountItems = accounts.map((a) => ({
    accountId: a.id,
    name: a.name,
    address: a.address,
    balance: a.balance,
    cryptoProviderType: a.cryptoProviderType,
  }))

  return (
    <AccountSelectField
      label={t('Account')}
      value={accountId}
      onChange={onChange}
      items={accountItems}
      blockchain={blockchain}
      variant="outlined"
      disabled={blockchainsWithTokenAutoImport.includes(blockchain)}
      showBlockchainIcon
    />
  )
}
