import type {
  SolanaStakeAccountInfo,
  SolanaEpochScheduleEstimate,
  SolanaStakeActivation,
  SolanaAccountWithStakeAccounts,
  SolanaValidatorInfo,
  SolanaCluster,
} from '@nufi/wallet-solana'
import {SOLANA_VALIDATORS} from '@nufi/wallet-solana'

import config from '../../../config'
import {assert, assertUnreachable} from '../../../utils/assertion'

export function stakeActivationToRewardDate(
  state: SolanaStakeActivation,
  epochSchedule: SolanaEpochScheduleEstimate,
): Date | null {
  switch (state) {
    case 'active':
      return epochSchedule.currentEpochEnd
    case 'deactivating':
      return epochSchedule.currentEpochEnd
    case 'activating':
      return epochSchedule.nextEpochEnd
    case 'inactive':
      return null // no rewards to be obtained
    default:
      return assertUnreachable()
  }
}

export const findStakeAccountAndParentById = (
  accountsWithStakeInfo: SolanaAccountWithStakeAccounts[],
  stakeAccountId: string,
): {
  stakeAccount: SolanaStakeAccountInfo
  parentAccount: SolanaAccountWithStakeAccounts
} => {
  const parentAccount = accountsWithStakeInfo.find((a) =>
    a.stakeAccounts.some((sa) => sa.id === stakeAccountId),
  )
  const stakeAccount = parentAccount?.stakeAccounts.find(
    (sa) => sa.id === stakeAccountId,
  )
  assert(
    !!parentAccount && !!stakeAccount,
    `ensureStakeAccountById: Stake account with id: ${stakeAccountId} not found`,
  )
  return {stakeAccount, parentAccount}
}

export function getRecommendedValidatorId(validators: SolanaValidatorInfo[]) {
  const currentCluster = config.solanaClusterName as SolanaCluster
  const validatorId = SOLANA_VALIDATORS[currentCluster]
  return validatorId || validators[0]!.publicKey.toString()
}

export function getRecommendedValidatorOption(
  validators: SolanaValidatorInfo[],
  id: string,
) {
  return validators.find((v) => v.publicKey.toString() === id) || validators[0]!
}
