/* eslint-disable @typescript-eslint/explicit-function-return-type */

import {MIN_SOL_ACCOUNT_LAMPORTS_FOR_RENT_EXEMPT} from '@nufi/wallet-solana'
import type {
  SolanaPubKey,
  SolanaDerivationPathType,
  SolanaAccountInfo,
  Lamports,
} from '@nufi/wallet-solana'
import {useQuery} from '@tanstack/react-query'
import BigNumber from 'bignumber.js'

import {discoverHwAccounts, getAccounts} from '../../../public/accounts'
import type {AllAvailableAccountsResponse, HwVendor} from '../../../types'
import {
  DEFAULT_DISCOVERY_QUERY_OPTIONS,
  minutesToMilliseconds,
} from '../../../utils/common'
import {solana} from '../../solanaManagers'

import {
  getAccountInfoForDiscoveryResponse,
  QUERY_KEY_PREFIX as P,
} from './utils'

export const coreQueryKeys = {
  accounts: getAccounts.__key(P),
  discoverHotAccounts: {
    index: [P, 'discoverHotAccounts'],
    derivationPath: (derivationPathType: SolanaDerivationPathType) => [
      ...coreQueryKeys.discoverHotAccounts.index,
      derivationPathType,
    ],
  },
  discoverHwAccounts: (hwVendor: HwVendor) => ({
    index: discoverHwAccounts.__key(P, hwVendor),
    derivationPath: (derivationPathType: SolanaDerivationPathType) => [
      ...coreQueryKeys.discoverHwAccounts(hwVendor).index,
      derivationPathType,
    ],
  }),
  maxSendableAmount: (balance: Lamports) => [P, 'maxSendableAmount', balance],
  onChainAccountsInfo: (accounts: SolanaPubKey[]) => [
    P,
    'onChainAccountsInfo',
    ...accounts.map((k) => k.toBase58()),
  ],
} as const

//
// __CACHED__ QUERIES
//

export function useDiscoverHotAccounts(
  derivationPathType: SolanaDerivationPathType,
  enabled = true,
) {
  return useQuery({
    queryKey:
      coreQueryKeys.discoverHotAccounts.derivationPath(derivationPathType),
    queryFn: async (): Promise<
      AllAvailableAccountsResponse<SolanaAccountInfo>
    > => {
      const accounts = solana.accountsStore.getAllAccounts()
      const response = await solana.wallet.discoverHotAccounts(
        accounts,
        derivationPathType,
      )
      return getAccountInfoForDiscoveryResponse(response)
    },
    ...DEFAULT_DISCOVERY_QUERY_OPTIONS,
    enabled,
  })
}

export function useDiscoverHwAccounts(
  derivationPathType: SolanaDerivationPathType,
  hwVendor: HwVendor,
) {
  return useQuery({
    queryKey: coreQueryKeys
      .discoverHwAccounts(hwVendor)
      .derivationPath(derivationPathType),
    queryFn: async (): Promise<
      AllAvailableAccountsResponse<SolanaAccountInfo>
    > => {
      const accounts = solana.accountsStore.getAllAccounts()
      const response = await solana.wallet.discoverHwAccounts(
        accounts,
        derivationPathType,
        hwVendor,
      )
      return getAccountInfoForDiscoveryResponse(response)
    },
    ...DEFAULT_DISCOVERY_QUERY_OPTIONS,
    staleTime: minutesToMilliseconds(1),
    gcTime: minutesToMilliseconds(1),
  })
}

//
// __COMPUTED__ QUERIES
//

export type MaxSendableSolanaAmountArgs =
  | {
      balance: Lamports
    }
  | undefined

// On rare cases, there is an edge case where this calculation will return the incorrect
// value for some accounts that aren't rent-exempt and have a really small balance,
// in the region of 10000 lamports (0.00001 SOL) and less. In some cases, it seems that
// the rent to be paid in the next installment is reserved on the account, but not noted
// anywhere. Other wallets (Sollet, Phantom, Solflare) all fail at properly identifying
// the max sendable amount and there is currently no way to query it from the blockchain.
// The error message in the console displays the max sendable amount, in case it's necessary.
export function getMaxSendableSolanaAmount(balance: Lamports, txFee: Lamports) {
  return BigNumber.max(balance.minus(txFee), 0) as Lamports
}

// Used in staking so some balance is left to pay fees for stake activation and withdrawal
export function getMaxSolAmountWithFeeReserve(
  balance: Lamports,
  txFee: Lamports,
) {
  return getMaxSendableSolanaAmount(balance, txFee)
    .minus(MIN_SOL_ACCOUNT_LAMPORTS_FOR_RENT_EXEMPT) // avoid leaving less than rent on parent account
    .minus(txFee.times(10)) as Lamports // leave enough funds for a few tx fees, since parent account pays fees
}

// Note that we depend on this returning the account infos in the order
// corresponding to `accountPubKeys`. Make sure that you are calling this with
// a stable ordering of `accountPubKeys` since we can't cache across different
// orders.
export function useGetOnChainAccountsInfo(accountPubKeys: SolanaPubKey[]) {
  return useQuery({
    queryKey: coreQueryKeys.onChainAccountsInfo(accountPubKeys),
    queryFn: () =>
      Promise.all(
        accountPubKeys.map((k) =>
          solana.accountManager.getOnChainAccountInfo(k),
        ),
      ),
    refetchInterval: 3000,
  })
}
