import type {AccountId, StakeAccountId} from '@nufi/wallet-common'
import type {FlowStakeAccountId} from '@nufi/wallet-flow'

import type {Blockchain, CryptoProviderType} from '../types'
export * from './queryString'

const ACTIVITY = 'activity'
const PORTFOLIO = 'portfolio'
const ASSETS = 'assets'
const DETAIL = 'detail'
const EXCHANGE = 'exchange'
const BUY_SELL = 'buySell'
const STAKING = 'staking'
const AUTH = 'auth'
const DEXHUNTER = 'dexHunter'
const NFTS = 'nfts'
const SETTINGS = 'settings'
export const WALLET_CONNECT = 'wallet_connect'

export type BaseAction = 'send' | 'receive' | 'exchange'
export type AssetTab = 'history' | 'info'

const generateBaseActions = (prefix: string) => ({
  send: `${prefix}/send`,
  receive: `${prefix}/receive`,
  exchange: `${prefix}/exchange`,
})

export const appendBaseAction = (prefix: string, action: BaseAction) =>
  `${prefix}/${action}`

export const generateAssetDetailRoutes = (basePath: string) => ({
  index: `${basePath}/${DETAIL}`,
  account: (accountId: AccountId | ':accountId' = ':accountId') => ({
    tab: (tab: AssetTab | ':tab' = ':tab') => ({
      index: `${basePath}/${DETAIL}/${accountId}/${tab}`,
      ...generateBaseActions(`${basePath}/${DETAIL}/${accountId}/${tab}`),
      exchangeDetail: (exchangeId: string | ':exchangeId' = ':exchangeId') =>
        `${basePath}/${DETAIL}/${accountId}/${tab}/exchange/${exchangeId}`,
    }),
  }),
  preview: `${basePath}/${DETAIL}/preview`,
})

const getAccountStakingActionPrefix = (
  blockchain: Blockchain | ':blockchain',
  accountId: AccountId | ':accountId',
) => `/${STAKING}/myStaking/${blockchain}/${accountId}`

const getStakeAccountStakingActionPrefix = (
  blockchain: Blockchain | ':blockchain',
  accountId: AccountId | ':accountId',
  stakeAccountId: StakeAccountId | FlowStakeAccountId | ':stakeAccountId',
) => `/${STAKING}/myStaking/${blockchain}/${accountId}/${stakeAccountId}`

const stakingActionsRoutes = {
  flow: {
    account: (accountId: AccountId | ':accountId' = ':accountId') => ({
      setupStaking: `${getAccountStakingActionPrefix(
        'flow',
        accountId,
      )}/setupStaking`,
      registerDelegator: `${getAccountStakingActionPrefix(
        'flow',
        accountId,
      )}/registerDelegator`,
      stakeAccount: (
        stakeAccountId:
          | FlowStakeAccountId
          | ':stakeAccountId' = ':stakeAccountId',
      ) => ({
        index: getStakeAccountStakingActionPrefix(
          'flow',
          accountId,
          stakeAccountId,
        ),
        stakeNewTokens: `${getStakeAccountStakingActionPrefix(
          'flow',
          accountId,
          stakeAccountId,
        )}/stakeNewTokens`,
        withdrawRewards: `${getStakeAccountStakingActionPrefix(
          'flow',
          accountId,
          stakeAccountId,
        )}/withdrawRewards`,
        requestUnstaking: `${getStakeAccountStakingActionPrefix(
          'flow',
          accountId,
          stakeAccountId,
        )}/requestUnstaking`,
        restakeRewarded: `${getStakeAccountStakingActionPrefix(
          'flow',
          accountId,
          stakeAccountId,
        )}/restakeRewarded`,
        restakeUnstaked: `${getStakeAccountStakingActionPrefix(
          'flow',
          accountId,
          stakeAccountId,
        )}/restakeUnstaked`,
        withdrawUnstaked: `${getStakeAccountStakingActionPrefix(
          'flow',
          accountId,
          stakeAccountId,
        )}/withdrawUnstaked`,
      }),
    }),
  },
  cardano: {
    account: (accountId: AccountId | ':accountId' = ':accountId') => ({
      delegate: `${getAccountStakingActionPrefix(
        'cardano',
        accountId,
      )}/delegate`,
      withdrawRewards: `${getAccountStakingActionPrefix(
        'cardano',
        accountId,
      )}/withdrawRewards`,
    }),
  },
  solana: {
    account: (accountId: AccountId | ':accountId' = ':accountId') => ({
      setupStakeAccount: `${getAccountStakingActionPrefix(
        'solana',
        accountId,
      )}/setupStakeAccount`,
      stakeAccount: (
        stakeAccountId: StakeAccountId | ':stakeAccountId' = ':stakeAccountId',
      ) => ({
        index: getStakeAccountStakingActionPrefix(
          'solana',
          accountId,
          stakeAccountId,
        ),
        delegate: `${getStakeAccountStakingActionPrefix(
          'solana',
          accountId,
          stakeAccountId,
        )}/delegate`,
        withdraw: `${getStakeAccountStakingActionPrefix(
          'solana',
          accountId,
          stakeAccountId,
        )}/withdraw`,
      }),
    }),
  },
}

export const routeTo = {
  main: '/',
  auth: `/${AUTH}`,
  createProfile: `/${AUTH}/create_wallet`,
  restoreProfile: `/${AUTH}/restore_wallet`,
  metamaskLogin: `/${AUTH}/metamask_login`,
  notFound: '/notFound',
  activity: {
    index: `/${ACTIVITY}`,
    blockchain: (blockchain: Blockchain | ':blockchain' = ':blockchain') => ({
      index: `/${ACTIVITY}/${blockchain}`,
      account: (accountId: AccountId | ':accountId' = ':accountId') =>
        `/${ACTIVITY}/${blockchain}/${accountId}`,
    }),
  },
  dashboard: '/dashboard',
  dAppDirectory: '/dAppDirectory',
  settings: {
    index: `/${SETTINGS}`,
    activateMnemonic: `/${SETTINGS}/activate`,
  },
  staking: {
    index: `/${STAKING}`,
    dashboard: `/${STAKING}/dashboard`,
    protocols: `/${STAKING}/protocols`,
    myStaking: {
      index: `/${STAKING}/myStaking`,
      blockchain: (blockchain: Blockchain | ':blockchain' = ':blockchain') => ({
        index: `/${STAKING}/myStaking/${blockchain}`,
        history: `/${STAKING}/myStaking/${blockchain}/history`,
        account: (accountId: AccountId | ':accountId' = ':accountId') =>
          getAccountStakingActionPrefix(blockchain, accountId),
      }),
      ...stakingActionsRoutes,
    },
    whatIsStaking: `/${STAKING}/whatIsStaking`,
  },
  portfolio: {
    index: `/${PORTFOLIO}`,
    assets: {
      index: `/${PORTFOLIO}/${ASSETS}`,
      blockchain: (blockchain: Blockchain | ':blockchain' = ':blockchain') => ({
        index: `/${PORTFOLIO}/${ASSETS}/${blockchain}`,
        action: (accountId: AccountId | ':accountId' = ':accountId') =>
          generateBaseActions(
            `/${PORTFOLIO}/${ASSETS}/${blockchain}/${accountId}`,
          ),
        detail: generateAssetDetailRoutes(
          `/${PORTFOLIO}/${ASSETS}/${blockchain}`,
        ),
        exchange: `/${PORTFOLIO}/${ASSETS}/${blockchain}/${EXCHANGE}`,
      }),
      buySell: `/${PORTFOLIO}/${ASSETS}/${BUY_SELL}`,
    },
    accounts: {
      index: `/${PORTFOLIO}/accounts`,
      newlyCreatedHwWallet: `/${PORTFOLIO}/accounts/hw-hint`,
      account: (
        blockchain: Blockchain | ':blockchain' = ':blockchain',
        accountId: AccountId | ':accountId' = ':accountId',
      ) => ({
        index: `/${PORTFOLIO}/accounts/${blockchain}/${accountId}`,
        ...generateBaseActions(
          `/${PORTFOLIO}/accounts/${blockchain}/${accountId}`,
        ),
      }),
      addAccount: {
        index: `/${PORTFOLIO}/accounts/add`,
        // serves as a route where it's not clear yet which HW device is selected
        hw: {
          index: `/${PORTFOLIO}/accounts/add/hw`,
          blockchain: (
            blockchain: Blockchain | ':blockchain' = ':blockchain',
          ) => `/${PORTFOLIO}/accounts/add/hw/${blockchain}`,
        },
        cryptoProviderType: (
          cryptoProviderType:
            | CryptoProviderType
            | ':cryptoProviderType' = ':cryptoProviderType',
        ) => ({
          index: `/${PORTFOLIO}/accounts/add/${cryptoProviderType}`,
          created: `/${PORTFOLIO}/accounts/add/${cryptoProviderType}/created`,
          blockchain: (
            blockchain: Blockchain | ':blockchain' = ':blockchain',
          ) => `/${PORTFOLIO}/accounts/add/${cryptoProviderType}/${blockchain}`,
        }),
      },
    },
    exchange: {
      index: `/${PORTFOLIO}/${EXCHANGE}`,
      action: (blockchain: Blockchain | ':blockchain' = ':blockchain') => ({
        index: `/${PORTFOLIO}/${EXCHANGE}/${blockchain}`,
        exchange: generateBaseActions(`/${PORTFOLIO}/${EXCHANGE}/${blockchain}`)
          .exchange,
      }),
      detail: (exchangeId: string | ':exchangeId' = ':exchangeId') =>
        `/${PORTFOLIO}/${EXCHANGE}/${exchangeId}`,
    },
    nfts: {
      index: `/${PORTFOLIO}/${NFTS}`,
      blockchain: (
        blockchain: Blockchain | 'none' | ':blockchain' = ':blockchain',
      ) => ({
        index: `/${PORTFOLIO}/${NFTS}/${blockchain}`,
        send: (accountId: AccountId | ':accountId' = ':accountId') =>
          `/${PORTFOLIO}/${NFTS}/${blockchain}/${accountId}/send`,
        account: (accountId: AccountId | ':accountId' = ':accountId') => ({
          index: `/${PORTFOLIO}/${NFTS}/${blockchain}/${accountId}`,
          receive: `/${PORTFOLIO}/${NFTS}/${blockchain}/${accountId}/receive`,
          hiddenNFTs: `/${PORTFOLIO}/${NFTS}/${blockchain}/${accountId}/hidden`,
        }),
        detail: generateAssetDetailRoutes(
          `/${PORTFOLIO}/${NFTS}/${blockchain}`,
        ),
      }),
    },
  },
  dexHunter: {
    index: `/${DEXHUNTER}`,
    orderHistory: `/${DEXHUNTER}/orderHistory`,
  },
  walletConnect: {
    connector: {
      index: `/${WALLET_CONNECT}/connector`,
      redirect: `/${WALLET_CONNECT}/connector/redirect/wc`, // `wc` is appended by walletConnect
      window: `/${WALLET_CONNECT}/connector/window/wc`,
    },
    dashboard: `/${WALLET_CONNECT}/dashboard`,
  },
}
