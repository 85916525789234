import i18n from 'i18next'
import {initReactI18next} from 'react-i18next'

import TRANSLATIONS_EN from './resources/en_resource.json'
import {supportedLanguages} from './supportedLanguages'

const {contextSeparator, pluralSeparator} = require('../../i18next/common')

i18n.use(initReactI18next).init({
  supportedLngs: Object.keys(supportedLanguages),
  lng: 'en',
  fallbackLng: 'en',
  defaultNS: 'common',
  nsSeparator: '::',
  keySeparator: false,
  contextSeparator,
  pluralSeparator,
  resources: {
    en: {
      common: TRANSLATIONS_EN,
    },
  },
})

export default i18n
