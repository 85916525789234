import {FileDownload as ExportIcon} from '@mui/icons-material'
import {isBlockchainSubset} from '@nufi/wallet-common'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {csvExportBlockchains} from 'src/blockchainTypes'
import {Button, WithTooltip} from 'src/components'
import type {Blockchain} from 'src/types'
import {useGetBlockchainName} from 'src/utils/translations'

type Props = {
  onExport: () => void
  blockchain: Blockchain
}

export const ExportActivityButton = ({onExport, blockchain}: Props) => {
  const {t} = useTranslation()
  const getBlockchainName = useGetBlockchainName()
  const isExportSupported = isBlockchainSubset(blockchain, csvExportBlockchains)
  const exportButton = (
    <Button
      variant="outlined"
      color="primary"
      onClick={onExport}
      startIcon={<ExportIcon />}
      textTransform="none"
      disabled={!isExportSupported}
    >
      {t('Export History')}
    </Button>
  )

  if (isExportSupported) return exportButton

  return (
    <WithTooltip
      title={t('transaction_export_unsupported_for_blockchain', {
        blockchain: getBlockchainName(blockchain),
      })}
    >
      {exportButton}
    </WithTooltip>
  )
}
