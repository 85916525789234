/* eslint-disable @typescript-eslint/explicit-function-return-type */
import type {
  BaseEvmAccountManager,
  EvmBlockchainApi,
  EvmNetworkConfig,
} from '@nufi/wallet-evm'

import type {AccountsStoreManager} from '../AccountsStoreManager'
import type {AccountInfosUtils} from '../utils/walletUtils'

import {arbitrumOne} from './chains/arbitrumOne/managers'
import {ethereum} from './chains/ethereum/managers'
import {milkomedaC1} from './chains/milkomedaC1/managers'
import {getOpStackManagers} from './chains/opStack/opStackManagers'
import {polygon} from './chains/polygon/managers'
import type {
  EvmAccountNetworkInfo,
  EvmAccountOfflineInfo,
  EvmAccountStoredData,
  EvmBlockchain,
} from './types'

export type EvmWalletManagers<
  TBlockchain extends EvmBlockchain,
  TNetwork extends string,
  TBlockchainApi extends
    EvmBlockchainApi<TBlockchain> = EvmBlockchainApi<TBlockchain>,
> = {
  networkConfig: EvmNetworkConfig<TBlockchain, TNetwork>
  accountsStore: AccountsStoreManager<EvmAccountStoredData>
  accountManager: BaseEvmAccountManager<TBlockchain>
  blockchainApi: TBlockchainApi
} & AccountInfosUtils<
  EvmAccountStoredData,
  EvmAccountNetworkInfo<TBlockchain>,
  EvmAccountOfflineInfo<TBlockchain>
>

export const getEvmManagers = () => {
  return {
    ethereum,
    milkomedaC1,
    polygon,
    arbitrumOne,
    ...getOpStackManagers(),
  }
}

export const getEvmManager = <TBlockchain extends EvmBlockchain>(
  blockchain: TBlockchain,
) => {
  const evmManagers = getEvmManagers()
  return evmManagers[blockchain] as unknown as EvmWalletManagers<
    TBlockchain,
    (typeof evmManagers)[TBlockchain]['networkConfig']['network']
  >
}
