import {Check as DoneStepIcon} from '@mui/icons-material'
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from '@mui/lab'
import {Typography} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'

export const useCreateProfileTimelineStyles = makeStyles((theme) => ({
  dot: {
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  label: {
    paddingTop: theme.spacing(0.25),
  },
  header: {
    marginBottom: theme.spacing(3),
  },
  timelineItem: {
    '&:before': {
      display: 'none',
    },
  },
  timeline: {
    marginLeft: 0,
    paddingLeft: 0,
  },
  connectorPast: {
    background: theme.palette.primary.main,
  },
  connector: {
    height: 50,
  },
}))

type SimpleTimelineProps = {
  steps: Array<string>
  activeStep: string
}

export function SimpleTimeline({steps, activeStep}: SimpleTimelineProps) {
  const classes = useCreateProfileTimelineStyles()

  const activeStepIndex = steps.indexOf(activeStep)
  if (activeStepIndex === -1) throw new Error('Invalid timeline configuration')

  return (
    <Timeline
      position="left"
      className={classes.timeline}
      data-test-id="simple-timeline"
      data-active-step={activeStep}
    >
      {steps.map((label, i, labels) => (
        <TimelineItem key={label} className={classes.timelineItem}>
          <TimelineSeparator>
            {i < activeStepIndex && (
              <TimelineDot color="primary" className={classes.dot}>
                <DoneStepIcon color="inherit" fontSize="small" />
              </TimelineDot>
            )}
            {i === activeStepIndex && (
              <TimelineDot color="primary" className={classes.dot}>
                <Typography lineHeight={1} variant="caption">
                  {i + 1}
                </Typography>
              </TimelineDot>
            )}
            {i > activeStepIndex && (
              <TimelineDot className={classes.dot}>
                <Typography lineHeight={1} variant="caption">
                  {i + 1}
                </Typography>
              </TimelineDot>
            )}
            {i + 1 < labels.length && (
              <TimelineConnector
                className={`${classes.connector} ${
                  i + 1 <= activeStepIndex ? classes.connectorPast : ''
                }`}
              />
            )}
          </TimelineSeparator>
          <TimelineContent>
            <Typography
              className={classes.label}
              variant="subtitle2"
              data-step={activeStep}
            >
              {label}
            </Typography>
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  )
}
