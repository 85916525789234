import {Security as SecurityIcon} from '@mui/icons-material'
import {Grid, Typography, Box} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {Button} from '../../components'
import type {Blockchain, CryptoProviderType} from '../../types'
import {assert, safeAssertUnreachable} from '../../utils/assertion'
import {useCryptoProviderTypeNames} from '../../utils/translations'
import type {AccountInfo, HwVendor} from '../../wallet'
import {useVerifyAccountAddress, isHwVendor} from '../../wallet'
import {gridPlusImages} from '../hw/gridPlus'
import {LedgerFailedVerify, ledgerImages} from '../hw/ledger'
import {TrezorFailedVerify, trezorImages} from '../hw/trezor'
import {Failure} from '../utils/Failure'

type VerificationState = 'loading' | 'error' | 'ready'

type AddressVerificationProviderProps = {
  accountInfo: AccountInfo
  blockchain: Blockchain
  children: ({
    ErrorContent,
    LoadingContent,
    ReadyContent,
    hasHwVerification,
  }: {
    ErrorContent: React.ReactNode
    LoadingContent: React.ReactNode
    ReadyContent: React.ReactNode
    hasHwVerification: boolean
    verificationState: VerificationState
  }) => JSX.Element
}

export const AddressVerificationProvider = ({
  accountInfo,
  blockchain,
  children,
}: AddressVerificationProviderProps) => {
  const {t} = useTranslation()
  const cryptoProviderTypeNames = useCryptoProviderTypeNames()
  const cryptoProviderType = accountInfo.cryptoProviderType
  const addressVerification = useVerifyAccountAddress(accountInfo.blockchain)

  // previously we showed the verification for all hardware wallets except for Flow
  // now we cannot decide this only on the fact that a cryptoProviderType is a hardware wallet
  // because of metamask snap. This behavior deserves further refactor of types such as HwVendor
  // which would mean many changes throughout the codebase, so we avoid it for now
  const getShouldShowAddressVerification = (
    cryptoProviderType: CryptoProviderType,
  ): cryptoProviderType is HwVendor | 'metamask' =>
    cryptoProviderType === 'metamask' ||
    (isHwVendor(cryptoProviderType) &&
      cryptoProviderType !== 'gridPlus' &&
      blockchain !== 'flow')

  const onVerify = async () => {
    assert(getShouldShowAddressVerification(accountInfo.cryptoProviderType))
    await addressVerification.mutateAsyncSilent(accountInfo.id)
  }

  const shouldShowAddressVerification =
    getShouldShowAddressVerification(cryptoProviderType)

  const ErrorContent = (() => {
    if (shouldShowAddressVerification && addressVerification.error) {
      switch (cryptoProviderType) {
        case 'ledger':
          return (
            <LedgerFailedVerify
              blockchain={blockchain}
              onRetry={onVerify}
              error={addressVerification.error}
            />
          )
        case 'trezor':
          return <TrezorFailedVerify onRetry={onVerify} />
        case 'metamask':
        case 'gridPlus':
          return (
            <Failure message="Could not verify address" onRetry={onVerify} />
          )
        default:
          return safeAssertUnreachable(cryptoProviderType)
      }
    }
    return null
  })()

  const LoadingContent = (() => {
    if (shouldShowAddressVerification && addressVerification.isPending) {
      switch (cryptoProviderType) {
        case 'ledger':
        case 'trezor':
        case 'gridPlus':
          return <HwDeviceReadyState hwVendor={cryptoProviderType} />
        case 'metamask':
          return <MetamaskReadyState />
        default:
          return safeAssertUnreachable(cryptoProviderType)
      }
    }
    return null
  })()

  const hwVendorLabel = cryptoProviderTypeNames[cryptoProviderType]

  return children({
    hasHwVerification: shouldShowAddressVerification,
    verificationState: addressVerification.isPending
      ? 'loading'
      : addressVerification.isError
        ? 'error'
        : 'ready',
    ErrorContent,
    LoadingContent,
    ReadyContent: shouldShowAddressVerification ? (
      <Grid item xs={12}>
        <Button
          variant="outlined"
          color="primary"
          onClick={onVerify}
          startIcon={<SecurityIcon />}
          textTransform="none"
          disabled={addressVerification.isPending}
        >
          {cryptoProviderType === 'metamask'
            ? t('verify_address_in_metamask')
            : t('verify_address_on_device', {hwVendor: hwVendorLabel})}
        </Button>
      </Grid>
    ) : null,
  })
}

function HwDeviceReadyState({hwVendor}: {hwVendor: HwVendor}) {
  const {t} = useTranslation()

  const deviceImage = ((hwVendor: HwVendor) => {
    switch (hwVendor) {
      case 'ledger':
        return ledgerImages.verify
      case 'trezor':
        return trezorImages.verify
      case 'gridPlus':
        return gridPlusImages.verify
      default:
        return safeAssertUnreachable(hwVendor)
    }
  })(hwVendor)

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      direction="column"
    >
      <Box display="block" mb={1}>
        {deviceImage}
      </Box>
      <Typography variant="body2" align="center">
        {t(
          'Verify that the displayed address matches the address on your device.',
        )}
      </Typography>
    </Grid>
  )
}

function MetamaskReadyState() {
  const {t} = useTranslation()

  return (
    <Grid container xs={12} alignItems="center" direction="column">
      <Typography variant="body2" align="center">
        {t(
          'Verify that the displayed address matches the address in MetaMask.',
        )}
      </Typography>
    </Grid>
  )
}
