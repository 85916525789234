import {Grid} from '@mui/material'
import type {FlowAccountInfo, FlowTokenId} from '@nufi/wallet-flow'
import {useFormikContext} from 'formik'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {
  QueryGuard,
  TokenSelectField,
  useModalSharedStyles,
} from '../../../../../../components'
import type {AccountInfo} from '../../../../../../types'
import {getHasFormError} from '../../../../../../utils/form'
import {
  useGetTokenMetadata,
  useGetNotSetupTokensForAccount,
} from '../../../../../../wallet/flow'
import {ensureAccountById} from '../../../../../../wallet/utils/common'
import {getTokenOptions} from '../../../../../receive/flow/utils'
import {TokenInfo} from '../ImportTokenUtils'
import CommonDetailsScreen from '../ImportTx/CommonDetailsScreen'

import type {ImportFlowTokenSchema} from './schema'
import type {TokenOption} from './types'

export const FORM_ID = 'import-flow-token-details-form'

type DetailsScreenProps = {
  onBack: () => void
  accounts: AccountInfo[]
  ModalHeader: React.ReactNode
}

export default function DetailsScreen({
  onBack,
  accounts,
  ModalHeader,
}: DetailsScreenProps) {
  const formikProps = useFormikContext<ImportFlowTokenSchema>()
  const {values, handleSubmit} = formikProps
  const accountInfo = ensureAccountById(
    accounts,
    values.accountId,
  ) as FlowAccountInfo

  const {data: setupTokensForAccount, isLoading} =
    useGetNotSetupTokensForAccount({
      address: accountInfo.address,
      cryptoProviderType: accountInfo.cryptoProviderType,
    })

  const options: TokenOption[] = getTokenOptions(
    setupTokensForAccount || [],
    false,
    false,
  )

  return (
    <CommonDetailsScreen
      {...{onBack, accounts}}
      onSubmit={handleSubmit}
      accountId={values.accountId}
      blockchain="flow"
      formId={FORM_ID}
      ModalHeader={ModalHeader}
    >
      <TokenSelectContent isLoading={isLoading} options={options} showDetail />
    </CommonDetailsScreen>
  )
}

type DetailsScreenContentProps = {
  isLoading: boolean
  options: TokenOption[]
  showDetail?: boolean
}

export function TokenSelectContent({
  isLoading,
  options,
  showDetail = false,
}: DetailsScreenContentProps) {
  const {t} = useTranslation()
  const classes = useModalSharedStyles()
  const formikProps = useFormikContext<ImportFlowTokenSchema>()
  const {values, errors, handleChange} = formikProps
  const hasError = getHasFormError(formikProps)
  const selectedOption = options.find((c) => c.id === values.contractId) ?? null

  return (
    <>
      <Grid item xs={12} className={classes.formField}>
        <TokenSelectField
          label={t('Select token')}
          isLoading={isLoading}
          options={options}
          value={selectedOption}
          onChange={(option) => {
            handleChange('contractId')(option?.id || '')
          }}
          error={hasError('contractId')}
          helperText={
            hasError('contractId') && (errors.contractId as unknown as string)
          }
          noOptionsText={t('No options for this account')}
        />
      </Grid>
      {showDetail && selectedOption?.tokenId && (
        <SelectedTokenInfo tokenId={selectedOption.tokenId} />
      )}
    </>
  )
}

const SelectedTokenInfo = ({tokenId}: {tokenId: FlowTokenId}) => {
  const tokenMetadataQuery = useGetTokenMetadata(tokenId)

  return (
    <QueryGuard {...tokenMetadataQuery}>
      {(tokenMetadata) => <TokenInfo token={tokenMetadata} />}
    </QueryGuard>
  )
}
