import {sleep} from '@nufi/frontend-common'
import type {HexString} from '@nufi/wallet-common'
import {withHexPrefix} from '@nufi/wallet-common'
import {ethers} from 'ethers'
import _ from 'lodash'

import {
  arbitrumOneChainIds,
  baseChainIds,
  ethereumChainIds,
  optimismChainIds,
  polygonChainIds,
} from '../chainIds'
import type {UntypedEvmBlockchain} from '../evmBlockchain'
import type {
  AssetTransfersParams,
  EvmAddress,
  EvmChainId,
  EvmNft,
  EvmUnsignedTransaction,
  TokenStandard,
} from '../types'

import {NftApiConnection} from './nftApi'
import type {
  AlchemyAssetTransfersResponse,
  AlchemyTxCategory,
  AlchemyTxEntry,
  TxAssetChangesSimulationResponse,
} from './types'
import {ALCHEMY_ALL_TX_CATEGORIES} from './types'

// https://docs.alchemy.com/reference/transfers-api-quickstart#types-of-transfers
const CHAINS_SUPPORTING_INTERNAL_TRANSFERS: EvmChainId[] = [
  ethereumChainIds.homestead,
  ethereumChainIds.sepolia,
  polygonChainIds.mainnet,
] as const

// https://docs.alchemy.com/reference/simulation-api-endpoints
const CHAINS_SUPPORTING_TX_SIMULATION: EvmChainId[] = [
  ethereumChainIds.homestead,
  ethereumChainIds.sepolia,
  polygonChainIds.mainnet,
  polygonChainIds.amoy,
  optimismChainIds.mainnet,
  optimismChainIds.sepolia,
  arbitrumOneChainIds.mainnet,
  arbitrumOneChainIds.sepolia,
  baseChainIds.mainnet,
  baseChainIds.sepolia,
] as const

/**
 * Ideally we would extend the `ethers.providers.AlchemyProvider` directly.
 * It's however not possible to pass a custom URL to `AlchemyProvider`
 * so we are extending `JsonRpcProvider` instead.
 *
 * If we ever need to use `AlchemyWebSocketProvider` we would also need
 * to extend it as it contains a hard coded reference to `AlchemyProvider`
 * with the hard coded URLs.
 *
 * NOTE: As opposed to `EtherscanProvider`, `JsonRpcProvider` doesn't add
 * a default API key to the requests.
 *
 * JsonRpcBatchProvider could be used if we did not need retry
 * functionality anymore, but it does not support retries
 */

export class _AlchemyProvider<
  TBlockchain extends UntypedEvmBlockchain,
> extends ethers.providers.JsonRpcProvider {
  private nftConnection: NftApiConnection<TBlockchain>
  private chainId: EvmChainId

  constructor(url: string, chainId: EvmChainId) {
    super(
      {
        url,
        // this flag is being set by `AlchemyProvider` so let's set it too
        allowGzip: true,
        // default value in ethers at the time of writing (12)
        // but avoid susceptibility to lib update change
        throttleLimit: 12,
        // ethers applies Exponential BackOff Retry Algorithm as
        // stall = throttleSlotInterval * parseInt(String(Math.random() * Math.pow(2, attempt)))
        // it can get multiplied aggressively, therefore do not set values too high
        throttleSlotInterval: 1000,
        throttleCallback: async (attempt: number) => {
          if (attempt > 0) {
            // https://github.com/ethers-io/ethers.js/discussions/3564#discussioncomment-4273458
            // Neutralize ether's default retry mechanism aggressiveness by adding a moment before each retry
            // Otherwise, many requests would be fired with 0 delay, spamming API and needlessly
            // wait a longer time in the next retry
            // Inspired by https://docs.alchemy.com/reference/throughput#option-4-exponential-backoff
            await sleep(3_000)
          }

          return true
        },
      },
      chainId,
    )

    this.chainId = chainId
    this.nftConnection = new NftApiConnection(url)
  }

  // A ChainId call was being made before each EVM RPC request. The roundtrip for EVM requests was ~500ms so each request basically took a second. This is mainly noticeable during EVM cross chain account discovery (which will be added later).
  // We don't really support "changing backend" for which this feature was mainly implemented in ethers.
  // More info can be found here - https://github.com/ethers-io/ethers.js/issues/901.
  override detectNetwork = async (): Promise<ethers.providers.Network> => {
    if (this._network) {
      return Promise.resolve(this._network)
    }
    return super.getNetwork()
  }

  getNftList = async (
    address: EvmAddress<TBlockchain>,
    blockchain: TBlockchain,
  ): Promise<EvmNft<TBlockchain>[]> =>
    await this.nftConnection.getNftList(address, blockchain)

  getAssetTransfers = async ({
    address,
    direction,
    token,
    pagingParams,
  }: AssetTransfersParams<TBlockchain>): Promise<
    AlchemyAssetTransfersResponse<TBlockchain>
  > => {
    const categories = ((): AlchemyTxCategory[] => {
      if (token) return [token.type.toLowerCase() as Lowercase<TokenStandard>]

      if (!CHAINS_SUPPORTING_INTERNAL_TRANSFERS.includes(this.chainId)) {
        return ALCHEMY_ALL_TX_CATEGORIES.filter((cat) => cat !== 'internal')
      }

      // Use all categories if not interested in specific token
      return [...ALCHEMY_ALL_TX_CATEGORIES]
    })()

    const requestParams = {
      ...(direction === 'in' ? {toAddress: address} : {fromAddress: address}),
      fromBlock: '0x0',
      category: categories,
      ...(token && {contractAddresses: [token.address]}),
      order: 'desc',
      withMetadata: true, // to get block timestamp as well
      excludeZeroValue: false, // zero value txs pay fees as well
      ...(pagingParams && {
        // max hex string number of results to return per call
        maxCount: withHexPrefix(pagingParams.maxCount.toString(16)),
        ...(pagingParams.pageKey && {pageKey: pagingParams.pageKey}),
      }),
    }

    const response = (await this.send('alchemy_getAssetTransfers', [
      requestParams,
    ])) as AlchemyAssetTransfersResponse<TBlockchain, 'raw'>

    // If multiple tokens are minted at once for ERC721, alchemy returns multiple transactions
    // with the same txHash, but different ERC721 data. However, in such a case for ERC1155, it returns
    // a single transaction with an array of minted tokens in erc1155Metadata field. For ease of handling
    // throughout the app and consistency with ERC721, create multiple "duplicate" ERC1155 txs with
    // just a single token in each erc1155Metadata field.
    const spreadTxs: AlchemyTxEntry<TBlockchain, 'processed'>[] =
      response.transfers
        .map((tx) =>
          tx.erc1155Metadata
            ? tx.erc1155Metadata.map((erc1155MetaDatum) => ({
                ...tx,
                erc1155Metadata: erc1155MetaDatum,
              }))
            : tx,
        )
        .flat()

    // Bundle same-hash txs together
    // Note, that since Alchemy returns duplicate tx hashes and we bundle them, this approach can fail on page breaks,
    // i.e. once every 1000 txs, if that breaking tx has more effects (is split up right at the page break)
    // in such a case, it would be shown in the UI twice. However, for this to happen, the user would have to scroll down
    // ca. 100 tx history pages.
    const txBundles = _(spreadTxs)
      .groupBy((tx) => tx.hash)
      .map((txHashGroup, hash) => ({
        hash: hash as HexString,
        txs: txHashGroup,
      }))
      .value()

    return {
      ...response,
      transfers: txBundles,
    }
  }

  simulate = async (
    args: EvmUnsignedTransaction,
  ): Promise<TxAssetChangesSimulationResponse | null> => {
    if (!CHAINS_SUPPORTING_TX_SIMULATION.includes(this.chainId)) {
      return null
    }

    return (await this.send('alchemy_simulateAssetChanges', [
      args,
    ])) as TxAssetChangesSimulationResponse
  }
}
