import {Box, Link, TextField, InputAdornment, Typography} from '@mui/material'
import React, {useState} from 'react'
import {Trans, useTranslation} from 'react-i18next'

import {usePlatformConfig} from 'src/dappConnector/PlatformConfig'
import {useIsBlockchainEnabled} from 'src/features/profile/application'
import {EnableBlockchain} from 'src/features/walletStorage/ui'
import {useRefetch} from 'src/utils/refetch'

import {useCurrentProfileMeta, useProfileInfos} from '../../../appStorage'
import {
  AccountSelectField,
  Alert,
  BatchQueryGuard,
  Button,
  QueryGuard,
  TransBold,
  Web3AuthProviderIcon,
} from '../../../components'
import {formatWeb3AuthProfileName} from '../../../components/formatting'
import type {Web3AuthLoginProvider} from '../../../features/login'
import {useAuthStore, useCurrentLoginInfo} from '../../../store/auth'
import {useDappConnectorStore} from '../../../store/dappConnector'
import theme from '../../../theme/theme'
import type {AccountId, AccountInfo} from '../../../types'
import {assert} from '../../../utils/assertion'
import {useGetBlockchainName} from '../../../utils/translations'
import {useGetAccounts, useHasAccounts} from '../../../wallet'
import {isEvmBlockchain} from '../../../wallet/evm'
import {ensureAccountById} from '../../../wallet/utils/common'
import {
  ActionButtons,
  ChangeAccountHint,
  OriginMessage,
  RejectButton,
} from '../../components'
import {OUTER_CONTENT_WIDTH} from '../../constants'
import {WithLastUsedAccountId} from '../blockchains/evm/changeChain/common'

import {InitNotPossible, ReturnToDappMessage} from './utils'

type ChooseAccountScreenProps = {
  onChooseOtherProfile: () => void
  onReject: () => void
}

export function ChooseAccountScreen({
  onChooseOtherProfile,
  onReject,
}: ChooseAccountScreenProps) {
  const {t} = useTranslation()
  const {authState} = useAuthStore()
  const {blockchain} = useDappConnectorStore()
  assert(blockchain != null, 'ChooseAccount: blockchain must be defined')
  const isBlockchainEnabled = useIsBlockchainEnabled(blockchain)
  const getBlockchainName = useGetBlockchainName()
  const accountsQuery = useGetAccounts(blockchain)
  const profileInfosQuery = useProfileInfos()
  const profileQuery = useCurrentProfileMeta()
  assert(authState.status === 'logged_in')

  // Use the hook instead of checking result of accountsQuery because
  // we need to consider stored accounts because of EVM.
  const hasStoredAccounts = useHasAccounts(blockchain)

  const {refetch} = useRefetch(true)

  return (
    <BatchQueryGuard
      queries={{
        accounts: accountsQuery,
        profileInfos: profileInfosQuery,
        profile: profileQuery,
      }}
    >
      {({accounts, profileInfos, profile}) => {
        // If there are no stored accounts, we ask the user to open the app and add an account.
        // In the case of EVM, if some EVM chain already has an account, `hasStoredAccounts` will
        // be true even for a disabled chain and thus we will move on to the next condition with `isBlockchainEnabled`
        // and we will display the activate chain button. For other chains `hasStoredAccounts` can't be
        // true if the blockchain is disabled i.e. we either display the open app screen or account select.

        // For EVM chains hasStoredAccounts might be true even if there are no accounts returned by the accountsQuery.
        // This happens if the user has some EVM account but the current EVM blockchain is disabled.
        if (!hasStoredAccounts) {
          return (
            <InitNotPossible
              intent={{
                type: 'openLoggedIn',
                loginType: authState.info.loginType,
                action: {
                  type: 'addAccount',
                  blockchain,
                },
              }}
              onReload={refetch}
              onChooseOtherProfile={
                profileInfos.length > 1 ? onChooseOtherProfile : undefined
              }
              primaryMessage={
                <Trans
                  i18nKey="no_accounts_primary_message"
                  t={t}
                  values={{
                    wallet:
                      profile.loginType === 'password' ? profile.name : '',
                    blockchain,
                  }}
                  components={{
                    wallet: (
                      <Box
                        component="span"
                        fontWeight="fontWeightMedium"
                        fontStyle="italic"
                        style={{
                          wordBreak: 'break-all',
                          color: theme.palette.primary.main,
                        }}
                      />
                    ),
                    capitalize: (
                      <Box component="span" textTransform="capitalize" />
                    ),
                  }}
                />
              }
              secondaryMessage={
                <>
                  <Trans
                    i18nKey="no_accounts_secondary_message"
                    t={t}
                    components={{
                      bold: TransBold,
                    }}
                    values={{blockchain: getBlockchainName(blockchain)}}
                  />
                  <ReturnToDappMessage />
                </>
              }
            />
          )
        } else if (!isBlockchainEnabled) {
          return (
            <>
              <Typography variant="h5" textAlign="center">
                {t('blockchain_not_activated', {
                  blockchain: getBlockchainName(blockchain),
                })}
              </Typography>
              <Box mt={2} />
              <Alert severity="info">
                <Typography textAlign="left">
                  {t('blockchain_not_activated_long', {
                    blockchain: getBlockchainName(blockchain),
                  })}
                </Typography>
              </Alert>
              <Box mt={2} />
              <EnableBlockchain
                blockchain={blockchain}
                fullWidth
                variant="contained"
              />
              <Box mt={2} />
              {profileInfos.length > 1 && (
                <Button
                  fullWidth
                  textTransform="none"
                  onClick={onChooseOtherProfile}
                  variant="outlined"
                >
                  {t('Choose another wallet')}
                </Button>
              )}
            </>
          )
        } else {
          // This can happen if blockchain was just enabled, stored accounts have already been updated
          // but accounts from useGetAccounts haven't yet been updated.
          if (accounts == null || accounts.length === 0) {
            return <></>
          }

          return (
            <WithLastUsedAccountId {...{accounts, blockchain}}>
              {(defaultAccountId) => (
                <ChooseAccountScreenContent
                  {...{
                    accounts,
                    onChooseOtherProfile,
                    onReject,
                    defaultAccountId,
                  }}
                />
              )}
            </WithLastUsedAccountId>
          )
        }
      }}
    </BatchQueryGuard>
  )
}

type ChooseAccountScreenContentProps = ChooseAccountScreenProps & {
  accounts: AccountInfo[]
}

function ChooseAccountScreenContent({
  accounts,
  onChooseOtherProfile,
  onReject,
  defaultAccountId,
}: ChooseAccountScreenContentProps & {defaultAccountId: AccountId}) {
  const {
    setSelectedAccount,
    screenInfo,
    blockchain,
    setIdleScreen,
    origin,
    favIconUrl,
  } = useDappConnectorStore()
  const {lastAccountIdHandlers, notificationsPermission} = usePlatformConfig()

  assert(blockchain != null, 'ChooseAccount: blockchain must be defined')
  assert(screenInfo.state === 'init', 'ChooseAccount: invalid screen type')
  assert(origin != null, 'OriginInfo: origin not provided')

  const [accountId, setAccountId] = useState(defaultAccountId)
  const {t} = useTranslation()

  const onAccountClick = async () => {
    await lastAccountIdHandlers?.updateLastAccountId(blockchain, accountId)
    const selectedAccount = ensureAccountById(accounts, accountId)

    screenInfo.onFinish(selectedAccount)
    setSelectedAccount(selectedAccount)

    if (isEvmBlockchain(blockchain)) {
      // Permissions must be requested from inside a user gesture, like a button's
      // click handler.
      if (
        notificationsPermission &&
        !(await notificationsPermission?.hasPermission())
      ) {
        await notificationsPermission.requestPermission({alwaysAsk: false})
        await notificationsPermission.setPermissionsRequested()
      }
    }
    setIdleScreen()
  }

  const accountItems = accounts.map((a) => ({accountId: a.id, ...a}))

  const currentProfileMetaQuery = useCurrentProfileMeta()
  const currentLoginInfo = useCurrentLoginInfo()

  return (
    <>
      <OriginMessage
        message={t('Wants to connect to your NuFi wallet')}
        {...{origin, favIconUrl}}
      />
      <Box mt={4} />
      <Box width={OUTER_CONTENT_WIDTH}>
        <Box mb={2}>
          <QueryGuard {...currentProfileMetaQuery} loadingVariant="centered">
            {(data) => (
              <TextField
                value={
                  currentLoginInfo.loginType === 'web3Auth'
                    ? formatWeb3AuthProfileName(currentLoginInfo.user)
                    : data.name
                }
                label={t('Wallet')}
                variant="outlined"
                disabled
                fullWidth
                InputProps={{
                  startAdornment: currentLoginInfo.loginType === 'web3Auth' && (
                    <InputAdornment position="start">
                      <Web3AuthProviderIcon
                        provider={
                          currentLoginInfo.user
                            .typeOfLogin as Web3AuthLoginProvider
                        }
                        size={18}
                      />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <Link
                      component="button"
                      variant="body2"
                      onClick={(e) => {
                        e.preventDefault()
                        onChooseOtherProfile()
                      }}
                    >
                      {t('Change')}
                    </Link>
                  ),
                }}
              />
            )}
          </QueryGuard>
        </Box>
        <AccountSelectField
          value={accountId}
          label={t('Choose accounts')}
          onChange={(e) => setAccountId(e.target.value as AccountId)}
          items={accountItems}
          blockchain={blockchain}
          showBlockchainIcon
        />
        <Box mt={2}>
          <ActionButtons
            leftButton={<RejectButton onClick={onReject} />}
            rightButton={
              <Button
                textTransform="none"
                fullWidth
                variant="contained"
                color="primary"
                onClick={onAccountClick}
              >
                {t('Connect')}
              </Button>
            }
          />
        </Box>
      </Box>
      {!isEvmBlockchain(blockchain) && (
        <>
          <Box mt={4} />
          <ChangeAccountHint variant="before-init" origin={origin} />
        </>
      )}
    </>
  )
}
