import {Cancel as CancelIcon} from '@mui/icons-material'
import type {TextFieldProps} from '@mui/material'
import {
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Checkbox,
  FormHelperText,
  Grid,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import type {DatePickerProps} from '@mui/x-date-pickers/DatePicker'
import {DatePicker} from '@mui/x-date-pickers/DatePicker'
import _ from 'lodash'
import React from 'react'
import {useTranslation} from 'react-i18next'

import type {CsvExportBlockchain} from '../../../../../blockchainTypes'
import {csvExportBlockchains} from '../../../../../blockchainTypes'
import {
  ModalHeader,
  ModalFooter,
  Icon,
  FooterLayout,
  LabeledIcon,
  AssetIcon,
  FormattedAsset,
  Alert,
  ScrollableChipContainer,
} from '../../../../../components'
import {assertBlockchainIsInSubset} from '../../../../../utils/blockchainGuards'
import type {SelectChangeFn} from '../../../../../utils/form'
import {useGetBlockchainName} from '../../../../../utils/translations'
import type {AccountInfo, AccountId} from '../../../../../wallet/types'
import {ensureAccountByIdAndBlockchain} from '../../../../../wallet/utils/common'

export const EXPORT_FORM_ID = 'export-form-id'

type DateFieldProps = DatePickerProps<Date> & {
  renderInputProps: TextFieldProps
}

export function DateField({renderInputProps, ...dateProps}: DateFieldProps) {
  const classes = useDateFieldStyles()
  return (
    <DatePicker
      {...dateProps}
      slotProps={{
        desktopPaper: {
          classes: {root: classes.datePaper},
        },
        popper: {placement: 'bottom-start'},
        textField: {fullWidth: true, ...renderInputProps},
      }}
    />
  )
}

type SelectItemProps = {
  blockchain: AccountInfo['blockchain']
  name: AccountInfo['name']
  ellipsize?: boolean
}

const SelectItem = ({blockchain, name, ellipsize}: SelectItemProps) => (
  <LabeledIcon
    Icon={<AssetIcon exactSize={24} blockchain={blockchain} />}
    Label={
      <Box maxWidth={ellipsize ? 50 : '100%'}>
        <Typography variant="body1" noWrap>
          {name}
        </Typography>
      </Box>
    }
    iconPosition="start"
  />
)

export type ExportHistorySelectItem = {
  blockchain: CsvExportBlockchain
  accountId: AccountId
}

const CSVExportMenuItem = MenuItem as unknown as (props: {
  children: React.ReactNode
  value: ExportHistorySelectItem
}) => JSX.Element

type ExportSelectAccountsProps = {
  handleChange: SelectChangeFn<ExportHistorySelectItem[]>
  value: Record<CsvExportBlockchain, AccountId[]>
  accounts: AccountInfo[]
  handleDelete: (e: React.MouseEvent, value: ExportHistorySelectItem) => void
  error?: boolean
  helperText?: string
}

const chipsContainerId = 'ExportHistoryChipsContainer'

export const ExportSelectAccounts = ({
  handleChange,
  handleDelete,
  value,
  accounts,
  helperText,
  error,
}: ExportSelectAccountsProps) => {
  const {t} = useTranslation()

  const preventOpeningMenuOnClick = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    id: string,
  ) => {
    const target = e.target as HTMLElement
    if (target.id === id) {
      e.stopPropagation()
    }
  }

  const selectValue = _.entries(value).flatMap(([blockchain, accountIds]) =>
    accountIds.map((accountId) => ({blockchain, accountId})),
  ) as ExportHistorySelectItem[]

  return (
    <FormControl fullWidth variant="outlined" error={error}>
      <InputLabel>{t('Accounts')}</InputLabel>
      <Select
        sx={{
          '& .MuiSelect-select': {
            height: 'auto !important',
          },
        }}
        multiple
        value={selectValue}
        onChange={handleChange}
        label={t('Accounts')}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          sx: {
            maxHeight: 'calc(100% - 230px)',
          },
        }}
        renderValue={(selected) => (
          // When multiple options are selected and scrollbar within input field appears:
          // - using preventOpeningMenuOnClick to enable manipulating scrollbar
          //   within input on mouse hold and not fire opening of the SelectMenu.
          // - using zIndex on Box
          <ScrollableChipContainer
            rows={3}
            id={chipsContainerId}
            onMouseDown={(e) => preventOpeningMenuOnClick(e, chipsContainerId)}
            display="flex"
            alignItems="center"
          >
            {(ChipItem) => (
              <Box display="flex" flexWrap="wrap" zIndex={2} width="100%">
                {selected.map(({blockchain, accountId}) => {
                  const {name} = ensureAccountByIdAndBlockchain(
                    accounts,
                    blockchain,
                    accountId,
                  )

                  return (
                    <ChipItem
                      key={`${blockchain}${accountId}`}
                      label={name}
                      deleteIcon={
                        <CancelIcon
                          onMouseDown={(event) => event.stopPropagation()}
                        />
                      }
                      sx={{m: 0.25}}
                      icon={
                        <AssetIcon exactSize={24} blockchain={blockchain} />
                      }
                      onDelete={(e: React.MouseEvent) =>
                        handleDelete(e, {blockchain, accountId})
                      }
                    />
                  )
                })}
              </Box>
            )}
          </ScrollableChipContainer>
        )}
      >
        {accounts.map(({id, name, cryptoProviderType, blockchain, balance}) => {
          assertBlockchainIsInSubset(blockchain, csvExportBlockchains)
          return (
            <CSVExportMenuItem
              key={`${blockchain}${id}`}
              // Check https://github.com/mui/material-ui/issues/14286
              // for more information on passing object as a value
              value={{blockchain, accountId: id}}
            >
              <Box display="flex" justifyContent="space-between" width="100%">
                <Box display="flex">
                  <Checkbox
                    checked={value[blockchain].includes(id)}
                    color="primary"
                  />
                  <SelectItem
                    {...{
                      name,
                      cryptoProviderType,
                      blockchain,
                    }}
                  />
                </Box>
                <FormattedAsset
                  blockchain={blockchain}
                  amount={balance}
                  isSensitiveInformation
                />
              </Box>
            </CSVExportMenuItem>
          )
        })}
      </Select>
      {error && <FormHelperText sx={{mt: 1}}>{helperText}</FormHelperText>}
    </FormControl>
  )
}

type ExportModalHeaderProps = {
  onClose: () => void
}

export const ExportModalHeader = ({onClose}: ExportModalHeaderProps) => {
  const {t} = useTranslation()

  return (
    <ModalHeader onClose={onClose} hasDivider>
      <LabeledIcon
        Icon={<Icon type="exportIcon" />}
        Label={
          <div>
            <Typography variant="body1">
              {t('Export transaction history')}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {t('CSV/XLS')}
            </Typography>
          </div>
        }
        iconPosition="start"
      />
    </ModalHeader>
  )
}

type ExportModalFooterProps = {
  onClose: () => unknown
  disabled?: boolean
}

export function ExportModalFooter({
  onClose,
  disabled = false,
}: ExportModalFooterProps) {
  const {t} = useTranslation()

  return (
    <ModalFooter hasDivider>
      <FooterLayout
        leftBtnConf={{
          onClick: onClose,
          children: t('Back'),
        }}
        rightBtnConf={{
          textTransform: 'none',
          variant: 'contained',
          form: EXPORT_FORM_ID,
          color: 'primary',
          children: 'Export',
          type: 'submit',
          disabled,
        }}
      />
    </ModalFooter>
  )
}

export function simulateDownload(url: string, filename: string) {
  const download = document.createElement('a')
  download.href = url
  download.setAttribute('download', filename)
  download.click()
  download.remove()
}

const useDateFieldStyles = makeStyles((theme) => ({
  datePaper: {
    background: theme.palette.background.default,
  },
}))

type ExportModalErrorAlertsProps = {
  errorKeys: CsvExportBlockchain[]
}

export const ExportModalErrorAlerts = ({
  errorKeys,
}: ExportModalErrorAlertsProps) => {
  const {t} = useTranslation()
  const getBlockchainName = useGetBlockchainName()

  return (
    <>
      {errorKeys.map((errorKey) => (
        <Grid item key={errorKey}>
          <Alert
            severity="error"
            text={t('could_not_load_blockchain_accounts', {
              blockchain: getBlockchainName(errorKey),
            })}
          />
        </Grid>
      ))}
    </>
  )
}
