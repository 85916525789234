import React from 'react'

import {FullScreenLoading} from 'src/components'

import {useMetamaskContext} from './MetamaskContext'

export const MetamaskInitializationGuard = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const {state: metamaskState} = useMetamaskContext()

  if (!metamaskState.isInitialized) {
    return <FullScreenLoading />
  }

  return children
}
