import type {AccountId} from '@nufi/wallet-common'
import {arraySum} from '@nufi/wallet-common'
import type {
  BaseEvmProfileTokenImport,
  DestructuredEvmProfileTokenImport,
  EvmAccountInfo,
  EvmAddress,
  EvmBlockchain,
  EvmContractAddress,
  EvmERC20TokenList,
  EvmProfileTokenImport,
  EvmTokenBalance as TokenBalance,
  EvmTokenId,
  EvmTokenMetadata,
  EvmTokenTransferParams,
  EvmTokenBalance,
} from '@nufi/wallet-evm'
import {COULD_NOT_FETCH_EVM_TOKEN_DECIMALS} from '@nufi/wallet-evm'
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {useQuery} from '@tanstack/react-query'
import {BigNumber} from 'bignumber.js'
import _ from 'lodash'

import {
  isBlockchainEnabled,
  useIsBlockchainEnabled,
} from 'src/features/profile/application/enabledBlockchains'
import {fetchNeverStaleQuery, useNeverStaleQuery} from 'src/utils/query-utils'
import {getAvailableEvmBlockchains} from 'src/wallet/evm/evmBlockchains'

import {getMockServices} from '../../../../../__tests__/storybook/MockServiceLocator'
import queryClient from '../../../../../queryClient'
import type {AssetAvailableForAccountInfo} from '../../../../public/queries/types'
import {ASSET_UNAVAILABLE_REASON} from '../../../../public/queries/types'
import {TokenImportStoreManagerProvider} from '../../../../TokenImportStoreManager'
import {
  minutesToMilliseconds,
  secondsToMilliseconds,
} from '../../../../utils/common'
import {getEvmManager} from '../../../evmManagers'
import {getEvmNetworkConfig} from '../../../evmNetworkConfig'
import {cachedGetAccounts} from '../core'

import {cachedGetAllNfts} from './nfts'
import {tokensQueryKeys} from './queryKeys'

export {tokensQueryKeys}

const getErc20TokenList =
  <TBlockchain extends EvmBlockchain>(blockchain: TBlockchain) =>
  () =>
    getEvmManager(blockchain).blockchainApi.getErc20TokenList()

getErc20TokenList.__key = <TBlockchain extends EvmBlockchain>(
  blockchain: TBlockchain,
) => tokensQueryKeys.tokenList(blockchain)

export const cachedGetErc20TokenList = async <
  TBlockchain extends EvmBlockchain,
>(
  blockchain: TBlockchain,
) => {
  return fetchNeverStaleQuery({
    queryKey: getErc20TokenList.__key(blockchain),
    queryFn: getErc20TokenList(blockchain),
  })
}

export type GetErc20TokenList = typeof getErc20TokenList

export function useErc20TokenList<TBlockchain extends EvmBlockchain>(
  blockchain: TBlockchain,
  fn: GetErc20TokenList = getErc20TokenList,
  enabled = true,
) {
  return useNeverStaleQuery({
    queryKey: getErc20TokenList.__key(blockchain),
    queryFn: fn(blockchain),
    enabled,
  })
}

const _getTokenMetadata = async <TBlockchain extends EvmBlockchain>(
  blockchain: TBlockchain,
  tokenId: EvmTokenId<TBlockchain>,
  tokenListMap: EvmERC20TokenList<TBlockchain>,
  allTokens: TokenBalance<TBlockchain>[],
) => {
  // try to find token metadata in token list / owned tokens
  const knownMetadata =
    tokenListMap[tokenId] || // present in token list
    allTokens.find((t) => t.token.id === tokenId)?.token ||
    null
  if (knownMetadata) return knownMetadata

  // otherwise, get token/nft metadata onchain
  const onchainMetadata =
    await getEvmManager(blockchain).blockchainApi.getOnchainMetadata(tokenId)
  return onchainMetadata
}

const getTokenMetadata =
  <TBlockchain extends EvmBlockchain>(
    blockchain: TBlockchain,
    tokenId: EvmTokenId<TBlockchain>,
  ) =>
  async () => {
    const tokenListMap = await cachedGetErc20TokenList(blockchain)
    const allTokens = await cachedGetAllTokenBalances(blockchain)

    return _getTokenMetadata(blockchain, tokenId, tokenListMap, allTokens)
  }

function _useGetTokenMetadata<TBlockchain extends EvmBlockchain>(
  blockchain: TBlockchain,
  tokenId: EvmTokenId<TBlockchain>,
  enabled = true,
) {
  return useNeverStaleQuery({
    queryKey: tokensQueryKeys.tokenMetadata.token(blockchain, tokenId),
    queryFn: getTokenMetadata(blockchain, tokenId),
    enabled,
  })
}

export async function cachedGetTokenMetadata<TBlockchain extends EvmBlockchain>(
  blockchain: TBlockchain,
  tokenId: EvmTokenId<TBlockchain>,
) {
  const mockServices = getMockServices()

  return mockServices
    ? mockServices.evm.useGetTokenMetadata(blockchain, tokenId).data!
    : fetchNeverStaleQuery({
        queryKey: tokensQueryKeys.tokenMetadata.token(blockchain, tokenId),
        queryFn: getTokenMetadata(blockchain, tokenId),
      })
}

export type UseGetTokenMetadata = typeof _useGetTokenMetadata

export const useGetTokenMetadata: UseGetTokenMetadata = (...args) => {
  const mockServices = getMockServices()
  return mockServices
    ? mockServices.evm.useGetTokenMetadata(...args)
    : _useGetTokenMetadata(...args)
}

const getTokensMetadata =
  <TBlockchain extends EvmBlockchain>(blockchain: TBlockchain) =>
  async () => {
    // Rather than disabling the `useGetTokensMetadata` query we return empty result
    // from this function if the blockchain is disabled. This is because this
    // function can also get called imperatively from `cachedGetTokensMetadata` and thus
    // the imperative call could return different results than the query. This way
    // we make sure the results are consistent. We don't want the function to continue
    // if blockchain is disabled because e.g. `cachedGetErc20TokenList` would make
    // network requests.
    if (!isBlockchainEnabled(blockchain)) {
      return []
    }

    const tokenList = await cachedGetErc20TokenList(blockchain)
    const allTokens = await cachedGetAllTokenBalances(blockchain)

    const ownedTokenIds = allTokens.map((ta) => ta.token.id)

    const tokensMetadata = await Promise.all(
      ownedTokenIds.map((tokenId) =>
        _getTokenMetadata(blockchain, tokenId, tokenList, allTokens).catch(
          (err) => {
            if (err?.message === COULD_NOT_FETCH_EVM_TOKEN_DECIMALS) {
              // We silently ignore these errors, as we consider them very unlikely,
              // and communicating them back to users is tricky.
              return null
            }
            throw err
          },
        ),
      ),
    )

    return tokensMetadata.filter(
      (t): t is EvmTokenMetadata<TBlockchain> => t != null,
    )
  }

getTokensMetadata.__key = tokensQueryKeys.tokenMetadata.all

export const cachedGetTokensMetadata = <TBlockchain extends EvmBlockchain>(
  blockchain: TBlockchain,
) => {
  return fetchNeverStaleQuery({
    queryKey: getTokensMetadata.__key(blockchain),
    queryFn: getTokensMetadata(blockchain),
  })
}

export const useGetTokensMetadata = <TBlockchain extends EvmBlockchain>(
  blockchain: TBlockchain,
  enabled = true,
) => {
  return useNeverStaleQuery({
    queryKey: getTokensMetadata.__key(blockchain),
    queryFn: getTokensMetadata(blockchain),
    enabled,
  })
}

const getAllTokenBalances =
  <TBlockchain extends EvmBlockchain>(blockchain: TBlockchain) =>
  async (): Promise<
    (EvmTokenBalance<TBlockchain> & {accountId: AccountId})[]
  > => {
    const accounts = await cachedGetAccounts(blockchain)
    const tokenListMap = await cachedGetErc20TokenList(blockchain)
    const profileTokenImports = await cachedGetProfileTokenImports(blockchain)
    const {nftsByAccount} = await cachedGetAllNfts(blockchain)

    if (!accounts) return []
    const tokenListTokenIds = Object.keys(
      tokenListMap,
    ) as EvmTokenId<TBlockchain>[]
    const accountsTokens = (
      await Promise.all(
        accounts.map(async (a) => {
          const accountAddress = a.address
          const addressProfileTokenImports = profileTokenImports.filter(
            (t) => t.owner === accountAddress,
          )

          const tokenIds = [
            ...tokenListTokenIds,
            ...addressProfileTokenImports
              // append only unique token ids
              .filter(
                (t) => !tokenListMap[t.token.id], // && isErc20 // TODO after we distinguish between erc types
              )
              .map((t) => t.token.id),
          ]

          // 0 balance tokens are filtered out
          const tokenBalances = await getEvmManager(
            blockchain,
          ).blockchainApi.getAddressErc20TokenBalances(accountAddress, tokenIds)

          // re-initialize 0 balance profile tokens so they're not lost
          for (const tokenImport of addressProfileTokenImports) {
            if (!tokenBalances[tokenImport.token.id]) {
              tokenBalances[tokenImport.token.id] = new BigNumber(0)
            }
          }

          const accountNftBalances = nftsByAccount[a.id]!.map((nft) => ({
            token: _.omit(nft, 'balance'),
            amount: nft.balance,
            owner: accountAddress,
            accountId: a.id,
          }))

          // TODO: once we distinguish erc types for profile tokens, fetch other ERC tokens by their contracts here
          // and join balances. Right now they will be fetched along with others,
          // returning 0 if "erc20-like" balanceOf not supported

          const accountTokenBalances = Object.entries<BigNumber>(
            tokenBalances,
          ).map(([tokenId, balance]) => ({
            token:
              tokenListMap[tokenId as EvmTokenId<TBlockchain>] ||
              // if not in token list at this point, token info will be surely found in profile
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              addressProfileTokenImports.find((t) => t.token.id === tokenId)!
                .token,
            amount: balance,
            owner: accountAddress,
            accountId: a.id,
          }))

          return [...accountNftBalances, ...accountTokenBalances]
        }),
      )
    ).flat()

    return accountsTokens
  }

getAllTokenBalances.__key = tokensQueryKeys.balances.allTokens

export const cachedGetAllTokenBalances = async <
  TBlockchain extends EvmBlockchain,
>(
  blockchain: TBlockchain,
) => {
  return fetchNeverStaleQuery({
    queryKey: getAllTokenBalances.__key(blockchain),
    queryFn: getAllTokenBalances(blockchain),
  })
}

// currently handles all tokens as erc20 (although token checker smart contract also works for erc721)
export const useAllTokenBalances = <TBlockchain extends EvmBlockchain>(
  blockchain: TBlockchain,
  enabled = true,
) => {
  return useNeverStaleQuery({
    queryKey: getAllTokenBalances.__key(blockchain),
    queryFn: getAllTokenBalances(blockchain),
    enabled,
  })
}

export type EvmProfileTokenImportInfo<TBlockchain extends EvmBlockchain> = {
  owner: EvmProfileTokenImport<TBlockchain>['addresses'][number]
  token: BaseEvmProfileTokenImport<TBlockchain>
}

const getProfileTokenImports =
  <TBlockchain extends EvmBlockchain>(blockchain: TBlockchain) =>
  async (): Promise<EvmProfileTokenImportInfo<TBlockchain>[]> => {
    const accounts = await cachedGetAccounts(blockchain)

    const allTokens =
      TokenImportStoreManagerProvider.instance().getVisibleTokens({
        blockchain,
        chainId: getEvmNetworkConfig(blockchain).chainId,
      }) as EvmProfileTokenImport<TBlockchain>[]

    // de-structure to individual tokens per address
    const destructuredTokens: DestructuredEvmProfileTokenImport<TBlockchain>[] =
      allTokens.flatMap((t) =>
        t.addresses.map((address) => {
          const {addresses: _ignored, ...restProperties} = t
          return {...restProperties, owner: address}
        }),
      )

    const activeAccountAddressSet = new Set(accounts.map((a) => a.address))
    const tokensOfActiveAccounts = destructuredTokens.filter((t) =>
      activeAccountAddressSet.has(t.owner),
    )

    const tokensWithOwners = tokensOfActiveAccounts.map((t) => {
      const {owner, ...restProperties} = t
      return {
        token: restProperties,
        owner,
      }
    })

    return tokensWithOwners
  }

getProfileTokenImports.__key = tokensQueryKeys.profileTokenImports

export const cachedGetProfileTokenImports = async <
  TBlockchain extends EvmBlockchain,
>(
  blockchain: TBlockchain,
) => {
  return fetchNeverStaleQuery({
    queryKey: getProfileTokenImports.__key(blockchain),
    queryFn: getProfileTokenImports(blockchain),
  })
}

export type GetProfileTokenImports = typeof getProfileTokenImports

export const useGetProfileTokenImports = <TBlockchain extends EvmBlockchain>(
  blockchain: TBlockchain,
  fn: GetProfileTokenImports = getProfileTokenImports,
) => {
  return useNeverStaleQuery({
    queryKey: getProfileTokenImports.__key(blockchain),
    queryFn: fn(blockchain),
    // Avoid many unnecessary refreshes in connector
    refetchOnWindowFocus: false,
  })
}

const getKnownTokenIds =
  <TBlockchain extends EvmBlockchain = EvmBlockchain>(
    blockchain: TBlockchain,
  ) =>
  async () => {
    const profileTokenImports = await cachedGetProfileTokenImports(blockchain)
    const tokensList = await cachedGetErc20TokenList(blockchain)

    const profileTokenImportIds = profileTokenImports.map(({token}) => token.id)
    const tokensListIds = Object.keys(tokensList)

    const tokenIds = _.uniq([...profileTokenImportIds, ...tokensListIds])
    return tokenIds as EvmTokenId<EvmBlockchain>[]
  }

getKnownTokenIds.__key = tokensQueryKeys.knownTokenIds

const _useGetKnownTokenIds = <TBlockchain extends EvmBlockchain>(
  blockchain: TBlockchain,
) => {
  return useNeverStaleQuery({
    queryKey: getKnownTokenIds.__key(blockchain),
    queryFn: getKnownTokenIds(blockchain),
    // Meant to be only used in connector.
    refetchOnWindowFocus: false,
  })
}

export type UseGetKnownTokenIds = typeof _useGetKnownTokenIds

export const useGetKnownTokenIds = <TBlockchain extends EvmBlockchain>(
  blockchain: TBlockchain,
) => {
  const mockServices = getMockServices()
  return mockServices
    ? mockServices.evm.useGetKnownTokenIds(blockchain)
    : _useGetKnownTokenIds(blockchain)
}

const getTokens =
  <TBlockchain extends EvmBlockchain>(blockchain: TBlockchain) =>
  async () => {
    const allTokens = await cachedGetAllTokenBalances(blockchain)
    return _(allTokens)
      .groupBy(({token}) => token.id)
      .map((tokenGroup) => ({
        token: tokenGroup[0]!.token, // we are mapping over grouped tokens so this is safe
        amount: arraySum(tokenGroup.map(({amount}) => amount)),
      }))
      .flatten()
      .value()
  }

export const useGetTokens = <TBlockchain extends EvmBlockchain>(
  blockchain: TBlockchain,
  enabled = true,
) => {
  return useNeverStaleQuery({
    queryKey: tokensQueryKeys.index(blockchain),
    queryFn: getTokens(blockchain),
    enabled,
  })
}

export const cachedGetTokens = <TBlockchain extends EvmBlockchain>(
  blockchain: TBlockchain,
) => {
  return fetchNeverStaleQuery({
    queryKey: tokensQueryKeys.index(blockchain),
    queryFn: getTokens(blockchain),
  })
}

const getAccountTokens =
  <TBlockchain extends EvmBlockchain>(
    blockchain: TBlockchain,
    account: Pick<EvmAccountInfo<TBlockchain>, 'id' | 'address'>,
  ) =>
  async () => {
    return (await cachedGetAllTokenBalances(blockchain)).filter(
      (t) => t.owner === account.address,
    )
  }

export type GetAccountTokens = typeof getAccountTokens

export const useGetAccountTokens = <TBlockchain extends EvmBlockchain>(
  blockchain: TBlockchain,
  account: Pick<EvmAccountInfo<TBlockchain>, 'id' | 'address'>,
  fn: GetAccountTokens = getAccountTokens,
  enabled = true,
) => {
  return useNeverStaleQuery({
    queryKey: tokensQueryKeys.accountTokens(blockchain, account.id),
    queryFn: fn(blockchain, account),
    enabled,
  })
}

export const useEvmAccountTokens = (
  account: Pick<EvmAccountInfo<EvmBlockchain>, 'id' | 'address'>,
  enabled = true,
) => {
  const queries = getAvailableEvmBlockchains().map((blockchain) => {
    return {
      blockchain,
      // eslint-disable-next-line react-hooks/rules-of-hooks
      ...useGetAccountTokens(blockchain, account, getAccountTokens, enabled),
    }
  })

  const loadingBlockchains = queries
    .filter((q) => q.isLoading && !q.error)
    .map((q) => q.blockchain)

  const errors = queries
    .filter((q) => !q.isLoading && q.error)
    .map((q) => q.blockchain)

  const tokens = queries
    .filter(
      (q): q is typeof q & {data: NonNullable<(typeof q)['data']>} =>
        q.data != null,
    )
    .map((q) => q.data)
    .flat()
    .map((t) => ({
      token: {
        blockchain: t.token.blockchain,
        id: t.token.id,
        contractAddress: t.token.contractAddress,
      },
      amount: t.amount,
    }))

  return {
    tokens,
    partialLoadings: loadingBlockchains,
    errors,
  }
}

const getTokenBalancesPerAccount =
  <TBlockchain extends EvmBlockchain>(
    blockchain: TBlockchain,
    tokenId: EvmTokenId<TBlockchain> | null,
  ) =>
  async () => {
    const accounts = await cachedGetAccounts(blockchain)
    const allTokenBalances = await cachedGetAllTokenBalances(blockchain)

    return accounts.map((account) => {
      const tokens = allTokenBalances
        .filter((t) => t.owner === account.address)
        .filter((t) => t.token.id === tokenId)

      return {
        accountId: account.id,
        balance: arraySum(tokens?.map((t) => t.amount) ?? []),
      }
    })
  }

getTokenBalancesPerAccount.__key = tokensQueryKeys.balances.tokensPerAccount

export const cachedGetTokenBalancesPerAccount = async <
  TBlockchain extends EvmBlockchain,
>(
  blockchain: TBlockchain,
  tokenId: EvmTokenId<TBlockchain> | null,
) => {
  return fetchNeverStaleQuery({
    queryKey: getTokenBalancesPerAccount.__key(blockchain, tokenId),
    queryFn: getTokenBalancesPerAccount(blockchain, tokenId),
  })
}

// allow null so that the query does not have to be called conditionally
export function useGetTokenBalancesPerAccount<
  TBlockchain extends EvmBlockchain,
>(
  blockchain: TBlockchain,
  tokenId: EvmTokenId<TBlockchain> | null,
  enabled = true,
) {
  return useNeverStaleQuery({
    queryKey: getTokenBalancesPerAccount.__key(blockchain, tokenId),
    queryFn: getTokenBalancesPerAccount(blockchain, tokenId),
    enabled,
  })
}

const getErc20TokenBalance = async <TBlockchain extends EvmBlockchain>(
  blockchain: TBlockchain,
  contractAddress: EvmContractAddress<TBlockchain>,
  address: EvmAddress<TBlockchain>,
) =>
  await getEvmManager(blockchain).blockchainApi.getErc20TokenBalance(
    contractAddress,
    address,
  )

const getErc20TokenBalanceOptions = {staleTime: minutesToMilliseconds(1)} // avoid spamming balance

export type GetErc20TokenBalance = typeof getErc20TokenBalance

// used for fetching token balances ad-hoc in import screen to let the users know if they are importing the right token
export const useGetErc20TokenBalance = <TBlockchain extends EvmBlockchain>(
  blockchain: TBlockchain,
  contractAddress: EvmContractAddress<TBlockchain>,
  address: EvmAddress<TBlockchain>,
  fn: GetErc20TokenBalance = getErc20TokenBalance,
  enabled = true,
) => {
  const isBlockchainEnabled = useIsBlockchainEnabled(blockchain)
  return useQuery({
    queryKey: tokensQueryKeys.balances.tokenContract(
      blockchain,
      address,
      contractAddress,
    ),
    queryFn: () => fn(blockchain, contractAddress, address),
    enabled: isBlockchainEnabled && enabled,
    ...getErc20TokenBalanceOptions,
  })
}

export const useGetIsAssetAvailable = <TBlockchain extends EvmBlockchain>(
  blockchain: TBlockchain,
  tokenId: EvmTokenId<TBlockchain> | null,
  account: EvmAccountInfo<TBlockchain>,
  enabled = true,
) => {
  return useNeverStaleQuery({
    queryKey: tokensQueryKeys.isTokenAvailable(blockchain, tokenId, account.id),
    queryFn: async () => {
      const profileTokenImports = await cachedGetProfileTokenImports(blockchain)
      const tokenListMap = await cachedGetErc20TokenList(blockchain)
      const allNfts = await cachedGetAllNfts(blockchain)

      const isAvailable =
        !tokenId || // native ETH always available
        tokenId in tokenListMap || // tokens in tokenList always available
        tokenId in allNfts.allNfts || // detected nfts always available
        // is in profile for given account
        !!profileTokenImports.find(
          (t) => t.owner === account.address && t.token.id === tokenId,
        )
      return {
        available: isAvailable,
        ...(isAvailable === false && {
          unavailabilityReason: ASSET_UNAVAILABLE_REASON.IMPORT_REQUIRED,
        }),
      } as AssetAvailableForAccountInfo
    },
    enabled,
  })
}

const getTokenTransferGasLimitEstimate =
  <TBlockchain extends EvmBlockchain>(
    blockchain: TBlockchain,
    tokenTransferParams: EvmTokenTransferParams<TBlockchain>,
  ) =>
  async () => {
    return await getEvmManager(
      blockchain,
    ).blockchainApi.estimateErcTokenTransferGasLimit(tokenTransferParams)
  }

export const cachedGetTokenTransferGasLimitEstimate = <
  TBlockchain extends EvmBlockchain,
>(
  blockchain: TBlockchain,
  tokenTransferParams: EvmTokenTransferParams<TBlockchain>,
) =>
  queryClient.fetchQuery({
    queryKey: tokensQueryKeys.contractGasLimitEstimate(
      blockchain,
      tokenTransferParams,
    ),
    queryFn: getTokenTransferGasLimitEstimate(blockchain, tokenTransferParams),
    staleTime: secondsToMilliseconds(15),
  })

export * from './nfts'
