import {useTranslation} from 'react-i18next'
import * as yup from 'yup'

import {MAX_WALLET_NAME_LENGTH} from '../../../constants'
import {useCommonValidations} from '../../../utils/form'

import type {CommonRestoreFormSchema} from './types'

export const useRecoverSchemaWithoutUniqueProfileName = () => {
  const {t} = useTranslation()
  const commonValidation = useCommonValidations()

  return {
    password: yup
      .string()
      .concat(commonValidation.passwordPower)
      .required(t('Password is required')),
    profileName: yup
      .string()
      .test(
        'profile-name-length',
        t('wallet_name_max_length', {length: MAX_WALLET_NAME_LENGTH}),
        (value) => {
          if (value === undefined) return true
          return value.length <= MAX_WALLET_NAME_LENGTH
        },
      )
      .required(t('Wallet name is required')),
    termsChecked: yup.bool().oneOf([true], t('Field must be checked.')),
  }
}

export const commonInitialValues: CommonRestoreFormSchema = {
  password: '',
  profileName: '',
  termsChecked: false,
}
