import {ExpandLess, ExpandMore} from '@mui/icons-material'
import {
  Box,
  Collapse,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'
import BigNumber from 'bignumber.js'
import type {FormikProps} from 'formik'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'

import {Aligner, Button} from '../../../../components'
import {getHasFormError} from '../../../../utils/form'
import type {
  EvmBlockchain,
  LondonGasPriority,
  LondonGasSuggestions,
  EvmGwei,
  EvmWei,
  EvmLondonGasConfig,
} from '../../../../wallet/evm'
import {EVM_TRANSFER_GAS_UNITS} from '../../../../wallet/evm/constants'
import {
  calculateMaxFeePerGas,
  gweiToWei,
  weiToGwei,
} from '../../../../wallet/evm/sdk/utils'

import type {GasFieldProps} from './common'
import {GasField, GasFieldsWrapper, GasOptionsHeader} from './common'
import type {LondonUiGasSchema, LondonGasSchema} from './schema'

export const inferMaxFeePerGasFromForm = <TBlockchain extends EvmBlockchain>({
  maxPriorityFeePerGas,
  suggestedBaseFeePerGas,
}: {
  maxPriorityFeePerGas: string // Gwei-like string
  suggestedBaseFeePerGas: string // Gwei-like string
}): EvmWei<TBlockchain> => {
  return calculateMaxFeePerGas(
    gweiToWei(maxPriorityFeePerGas),
    gweiToWei(suggestedBaseFeePerGas),
  )
}

const getAdjustedSuggestedBaseFeePerGas = <TBlockchain extends EvmBlockchain>(
  suggestedBaseFeePerGas: EvmWei<TBlockchain>,
  gasPriority: LondonGasPriority,
  gasConfig: EvmLondonGasConfig<TBlockchain>,
) => {
  // add a lower bound on base fee in case network base fee is too low
  const safeBaseFeePerGas = gweiToWei(gasConfig.minSafeBaseFee.toString())
  const baseFee = BigNumber.max(suggestedBaseFeePerGas, safeBaseFeePerGas)
  return baseFee.multipliedBy(
    gasConfig.baseFeeMultipliers[gasPriority],
  ) as EvmWei<TBlockchain>
}

export const gasOptionsToInitialFormValues = <
  TBlockchain extends EvmBlockchain,
>(
  gasSuggestions: LondonGasSuggestions<TBlockchain>,
  gasConfig: EvmLondonGasConfig<TBlockchain>,
  gasLimit?: BigNumber,
) => {
  const gasPriority = 'medium' as const

  const adjustedSuggestedBaseFeePerGas = getAdjustedSuggestedBaseFeePerGas(
    gasSuggestions.suggestedBaseFeePerGas,
    gasPriority,
    gasConfig,
  )

  return {
    gasLimit:
      gasLimit != null ? gasLimit.toString() : `${EVM_TRANSFER_GAS_UNITS}`,
    maxPriorityFeePerGas: `${weiToGwei(
      gasSuggestions.mediumPriorityFeePerGas,
    )}`,
    suggestedBaseFeePerGas: `${weiToGwei(adjustedSuggestedBaseFeePerGas)}`,
    showAdvancedOptions: false,
    gasPriority,
  }
}

export type GasOptionsFormValues<TBlockchain extends EvmBlockchain> = {
  type: 'london'
  gasLimit: BigNumber
  maxPriorityFeePerGas: EvmWei<TBlockchain>
  maxFeePerGas: EvmWei<TBlockchain>
}

export const formValueToGasOptions = <
  TBlockchain extends EvmBlockchain,
  TSchema extends LondonUiGasSchema,
>(
  values: TSchema,
): GasOptionsFormValues<TBlockchain> => ({
  type: 'london' as const,
  gasLimit: new BigNumber(values.gasLimit),
  maxPriorityFeePerGas: gweiToWei<TBlockchain>(values.maxPriorityFeePerGas),
  maxFeePerGas: inferMaxFeePerGasFromForm<TBlockchain>(values),
})

export const useAutoRefreshGasSuggestions = <
  TBlockchain extends EvmBlockchain,
  TSchema extends LondonUiGasSchema,
>({
  gasSuggestions,
  formikProps,
  gasConfig,
  enable = true,
}: {
  gasSuggestions: LondonGasSuggestions<TBlockchain>
  formikProps: FormikProps<TSchema>
  gasConfig: EvmLondonGasConfig<TBlockchain>
  enable?: boolean
}) => {
  useEffect(() => {
    // only overwrite last values if one of gas priority buttons is selected
    if (enable && formikProps.values.gasPriority) {
      const gasPriority = formikProps.values.gasPriority

      const maxPriorityFeePerGas = {
        low: gasSuggestions.slowPriorityFeePerGas,
        medium: gasSuggestions.mediumPriorityFeePerGas,
        high: gasSuggestions.highPriorityFeePerGas,
      }[gasPriority]

      const adjustedSuggestedBaseFeePerGas = getAdjustedSuggestedBaseFeePerGas(
        gasSuggestions.suggestedBaseFeePerGas,
        gasPriority,
        gasConfig,
      )

      const maxFeePerGas = calculateMaxFeePerGas(
        maxPriorityFeePerGas,
        adjustedSuggestedBaseFeePerGas,
      )

      formikProps.setValues({
        ...formikProps.values,
        suggestedBaseFeePerGas: weiToGwei(
          adjustedSuggestedBaseFeePerGas,
        ).toFixed(),
        maxPriorityFeePerGas: weiToGwei(maxPriorityFeePerGas).toFixed(),
        maxFeePerGas: weiToGwei(maxFeePerGas).toFixed(),
      })

      // only overwrite last values if one of gas priority buttons is selected
    }
  }, [gasSuggestions, formikProps.values.gasPriority, enable])
}

export type LondonGasOptionsProps<
  TBlockchain extends EvmBlockchain,
  TSchema extends LondonUiGasSchema,
> = {
  formikProps: FormikProps<TSchema>
  gasSuggestions: LondonGasSuggestions<TBlockchain>
  allowResetOnGasSuggestionsChange: boolean
  autoPadding?: number
  gasConfig: EvmLondonGasConfig<TBlockchain>
}

/**
 * Gas options representing London hard fork era.
 * https://ethereum.org/en/developers/docs/gas/
 */
export const LondonGasOptions = <
  TBlockchain extends EvmBlockchain,
  TSchema extends LondonUiGasSchema,
>({
  formikProps,
  gasSuggestions,
  allowResetOnGasSuggestionsChange,
  autoPadding,
  gasConfig,
}: LondonGasOptionsProps<TBlockchain, TSchema>) => {
  const {t} = useTranslation()
  const {
    setFieldValue,
    values: {gasPriority},
  } = formikProps

  useAutoRefreshGasSuggestions({
    enable: allowResetOnGasSuggestionsChange,
    formikProps,
    gasSuggestions,
    gasConfig,
  })

  const _setGasPriority = (priority: LondonGasPriority | null) => {
    const gasPriorityFieldKey: keyof TSchema = 'gasPriority'
    setFieldValue(gasPriorityFieldKey, priority)
  }

  const deselectPrioritySetting = () => _setGasPriority(null)

  // workaround for type safety of values passed to FormControlLabel
  const formControlValues: Record<LondonGasPriority, LondonGasPriority> = {
    low: 'low',
    medium: 'medium',
    high: 'high',
  }

  const formattedMaxFeePerGas = (() => {
    const result = weiToGwei<EvmBlockchain>(
      inferMaxFeePerGasFromForm(formikProps.values),
    )
    if (result.isNaN()) return null
    return <>{result.toFixed()}&nbsp;&nbsp;(GWEI)</>
  })()

  return (
    <>
      <Box pt={1}>
        <Box display="flex" alignItems="center">
          <GasOptionsHeader />
          {gasPriority === null && (
            <Typography pl={1} variant="caption" fontStyle="italic">
              ({t('Using custom gas settings')})
            </Typography>
          )}
        </Box>
      </Box>
      <Box>
        <Typography
          variant="caption"
          component="div"
          sx={{display: 'inline-block', paddingRight: 1}}
        >
          {t('Gas price (per gas unit):')}
        </Typography>
        <Typography
          variant="caption"
          component="div"
          sx={{display: 'inline-block'}}
        >
          {formattedMaxFeePerGas}
        </Typography>
      </Box>
      <Box px={autoPadding || 10} pb={3} justifyContent="center">
        <RadioGroup
          row
          name="row-radio-buttons-group"
          sx={{
            justifyContent: 'space-between',
          }}
          value={gasPriority}
          onChange={(e) => _setGasPriority(e.target.value as LondonGasPriority)}
        >
          <FormControlLabel
            value={formControlValues.low}
            control={<Radio size="small" />}
            label={
              <Typography variant="caption">{t('Low') as string}</Typography>
            }
            labelPlacement="bottom"
          />
          <FormControlLabel
            value={formControlValues.medium}
            control={<Radio size="small" />}
            label={
              // 'Market' label is used because of metamask
              <Typography variant="caption">{t('Market') as string}</Typography>
            }
            labelPlacement="bottom"
          />
          <FormControlLabel
            value={formControlValues.high}
            control={<Radio size="small" />}
            label={
              <Typography variant="caption">{t('High') as string}</Typography>
            }
            labelPlacement="bottom"
          />
        </RadioGroup>
        <Box mt={-3} px={4}>
          <Divider />
        </Box>
      </Box>
      <AdvancedGasFields
        {...{
          formikProps,
          deselectPrioritySetting,
          gasSuggestions,
        }}
      />
    </>
  )
}

const getHasFormGasError = <TSchema extends LondonUiGasSchema>(
  formikProps: FormikProps<TSchema>,
) => {
  // gets only formik errors related to gas schema
  const gasSchemaTypeSafePlaceholder: LondonGasSchema = {
    suggestedBaseFeePerGas: '',
    gasLimit: '',
    maxPriorityFeePerGas: '',
  }
  const gasSchemaKeys = Object.keys(gasSchemaTypeSafePlaceholder) as Array<
    keyof typeof gasSchemaTypeSafePlaceholder
  >
  const hasError = getHasFormError(formikProps, 'after-touched')
  return gasSchemaKeys.some((gasSchemaKey) => hasError(gasSchemaKey))
}

const useGasFeeFieldWarningValidation = <TBlockchain extends EvmBlockchain>(
  lowerLimit: EvmGwei<TBlockchain>,
) => {
  const {t} = useTranslation()
  return (amount: string) => {
    return new BigNumber(amount).isLessThan(lowerLimit)
      ? t('Transaction with this setting may fail or take too long to execute.')
      : false
  }
}

export const AdvancedGasFields = <
  TBlockchain extends EvmBlockchain,
  TSchema extends LondonUiGasSchema,
>({
  gasSuggestions,
  deselectPrioritySetting,
  formikProps,
}: {
  gasSuggestions: LondonGasSuggestions<TBlockchain>
  deselectPrioritySetting: () => void
  formikProps: FormikProps<TSchema>
}) => {
  const hasGasError = getHasFormGasError(formikProps)
  const {
    setFieldValue,
    values: {showAdvancedOptions},
  } = formikProps
  const _setShowAdvancedOptions = (value: boolean) => {
    const showAdvancedOptionsFieldKey: keyof TSchema = 'showAdvancedOptions'
    setFieldValue(showAdvancedOptionsFieldKey, value)
  }
  const toggleShowAdvancedOptions = () =>
    // do not allow users to close advanced options if they have an error
    !hasGasError && _setShowAdvancedOptions(!showAdvancedOptions)
  // automatically open gas options if they have error
  const areAdvancedOptionsOpen = showAdvancedOptions || hasGasError

  const {t} = useTranslation()

  return (
    <>
      <Box pt={1}>
        <Aligner align={'center'}>
          <Button
            textTransform="none"
            variant="text"
            onClick={toggleShowAdvancedOptions}
            endIcon={areAdvancedOptionsOpen ? <ExpandLess /> : <ExpandMore />}
          >
            {t('Advanced gas settings')}
          </Button>
        </Aligner>
      </Box>
      <Collapse in={areAdvancedOptionsOpen}>
        <GasFieldsWrapper>
          <AdvancedGasField
            field="suggestedBaseFeePerGas"
            label={t('Max base fee (GWEI)') as string}
            gasFeeFieldWarningValidation={useGasFeeFieldWarningValidation(
              weiToGwei(gasSuggestions.suggestedBaseFeePerGas),
            )}
            endAdornment={
              <Box>
                <Typography
                  variant="caption"
                  component="span"
                  sx={{paddingRight: 1}}
                >
                  {t('Network base fee:')}
                </Typography>
                <Typography variant="caption" component="span">
                  {weiToGwei(gasSuggestions.suggestedBaseFeePerGas).toFixed()}
                </Typography>
              </Box>
            }
            explanation={t('gas_base_fee_explanation')}
            {...{formikProps, deselectPrioritySetting}}
          />
          <AdvancedGasField
            field="maxPriorityFeePerGas"
            label={t('Max priority fee (GWEI)') as string}
            gasFeeFieldWarningValidation={useGasFeeFieldWarningValidation(
              weiToGwei(gasSuggestions.slowPriorityFeePerGas),
            )}
            explanation={t('gas_priority_fee_explanation')}
            {...{formikProps, deselectPrioritySetting}}
          />
          <AdvancedGasField
            field="gasLimit"
            label={t('Gas Limit') as string}
            explanation={t('gas_limit_explanation')}
            {...{formikProps, deselectPrioritySetting}}
          />
        </GasFieldsWrapper>
      </Collapse>
    </>
  )
}

const AdvancedGasField = (
  props: Omit<GasFieldProps, 'field'> & {field: keyof LondonUiGasSchema},
) => <GasField {...props} />
