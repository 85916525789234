import {useQuery} from '@tanstack/react-query'

import type {
  Currency,
  HistoricalConversionRatesGranularity,
} from 'src/features/conversionRates/domain'
import type {Blockchain} from 'src/types'
import {minutesToMilliseconds} from 'src/wallet/utils/common'

import {conversionRatesService} from './conversionRatesService'
import {conversionRatesQueryKeys} from './queryKeys'

const maxConversionRateAge = minutesToMilliseconds(1)

export function useGetHistoricalConversionRates(
  blockchain: Blockchain,
  currency: Currency,
  granularity: HistoricalConversionRatesGranularity,
) {
  return useQuery({
    queryKey: conversionRatesQueryKeys.historicalRates(
      blockchain,
      currency,
      granularity,
    ),
    queryFn: async () => {
      return await conversionRatesService.getHistoricalConversionRates(
        blockchain,
        currency,
        granularity,
      )
    },
    gcTime: maxConversionRateAge,
    staleTime: maxConversionRateAge,
  })
}
