import {evmChainMetadata, isEvmBlockchain} from '@nufi/wallet-evm'

import type {StakingBlockchain, MoonpayBlockchain} from './blockchainTypes'
import config from './config'
import type {Blockchain, Coin} from './types'
import {assertUnreachable, safeAssertUnreachable} from './utils/assertion'
import {blockchainMetadata} from './wallet/constants'

export const SIDEBAR_WIDTH = 140
export const TOPBAR_WIDTH = 64
export const CONVERSION_RATES_PROVIDER_HEIGHT = 40
export const MAX_WALLET_NAME_LENGTH = 40
export const DASHBOARD_LOGGED_IN_MIN_WIDTH = 1280

// Important not to use default threshold
// Searching in multiple fields (name, collection, ticker, etc.)
// there is high chance of getting noise results with fuzzy search
export const FUSE_TOKEN_SEARCHING_THRESHOLD = 0.35

export const IS_EXCHANGE_ENABLED = !config.isTestnet
export const IS_BUY_SELL_ENABLED = !config.isTestnet
export const IS_EMBEDDED_MOONPAY_SELL_ENABLED =
  config.embeddedMoonpaySellEnabledBlockchains.length > 0

export const THUMBNAIL_GATEWAY = config.thumbnailUrl
export const CID_THUMBNAIL_GATEWAY = `${THUMBNAIL_GATEWAY}?cid=`

export const MAX_ACCOUNT_NAME_CHARACTERS = 28 // https://vacuum.atlassian.net/browse/ADLT-1112

export type SocialMediaType =
  | 'discord'
  | 'medium'
  | 'reddit'
  | 'twitter'
  | 'github'
  | 'linkedin'
  | 'instagram'
  | 'facebook'

export type ModifierKey = 'Control' | 'Alt' | 'Meta' | 'Shift'
export const shortcutKeys = {
  hideAmount: {
    modifier: 'Alt',
    code: 'KeyH', // https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/code use KeyboardEvent.code because other key properties are deprecated or can't be used with Option modifier on MacOs, since it changes their values
    key: 'H',
  },
} as const

export const getBlockchainDecimals = (blockchain: Blockchain) => {
  return blockchainMetadata[blockchain].decimals
}

export const getRenderedBlockchainDecimals = (blockchain: Blockchain) => {
  if (isEvmBlockchain(blockchain)) {
    return evmChainMetadata[blockchain].renderedDecimals
  }
  return getBlockchainDecimals(blockchain)
}

// https://docs.moonpay.com/moonpay/apis-and-webhooks/apis/client-side-apis/currencies#list-all-currencies
export const getMoonpayBlockchainCode = (blockchain: MoonpayBlockchain) => {
  switch (blockchain) {
    case 'cardano':
      return 'ADA'
    case 'solana':
      return 'SOL'
    case 'flow':
      return 'FLOW'
    case 'ethereum':
      return 'ETH'
    default:
      return safeAssertUnreachable(blockchain)
  }
}

export const MINIMUM_FRACTION_DIGITS = 4

export const getBlockchainRewardsAutoStake = (
  blockchain: StakingBlockchain,
) => {
  switch (blockchain) {
    case 'cardano':
      return true
    case 'solana':
      return true
    case 'flow':
      return false
    default:
      return safeAssertUnreachable(blockchain)
  }
}

type ProtocolsOverview = {
  APY: (blockchain: StakingBlockchain) => string
  minimumBalance: (blockchain: StakingBlockchain) => Record<string, string>
  locked: (blockchain: StakingBlockchain) => 'Yes' | 'No'
  validatorFee: (blockchain: StakingBlockchain) => string
}

const getAPY = (blockchain: StakingBlockchain) => {
  switch (blockchain) {
    case 'cardano':
      return '~ 3.5-4.5%'
    case 'solana':
      return '~ 6-7%'
    case 'flow':
      return '~ 9%'
    default:
      return safeAssertUnreachable(blockchain)
  }
}

const getMinimalBalance = (
  blockchain: StakingBlockchain,
): Record<string, string> => {
  switch (blockchain) {
    case 'cardano':
      return {minimum: '3 ADA', networkFee: '~ 0.17 ADA', keyDeposit: '2 ADA'}
    case 'solana':
      return {
        minimum: '0.01 SOL',
        accountCreation: '0.000005 SOL',
        accountActivation: '0.000005 SOL',
        rentFee: '0.00228288 SOL',
      }
    case 'flow':
      return {
        minimum: '0.002 FLOW',
        accountCreation: '0.001 FLOW',
        accountActivation: '0.0001 FLOW',
        rentFee: '0.0001 FLOW',
      }
    default:
      return safeAssertUnreachable(blockchain)
  }
}

export const getIsLocked = (blockchain: StakingBlockchain): 'Yes' | 'No' => {
  switch (blockchain) {
    case 'cardano':
      return 'No'
    case 'solana':
      return 'Yes'
    case 'flow':
      return 'Yes'
    default:
      return safeAssertUnreachable(blockchain)
  }
}

const getValidatorFee = (blockchain: StakingBlockchain): string => {
  switch (blockchain) {
    case 'cardano':
      return '~ 2-4%'
    case 'solana':
      return '~ 5-10%'
    case 'flow':
      return 'Fixed 8%'
    default:
      return safeAssertUnreachable(blockchain)
  }
}

export const protocolsOverview: ProtocolsOverview = {
  APY: getAPY,
  minimumBalance: getMinimalBalance,
  locked: getIsLocked,
  validatorFee: getValidatorFee,
}

const socialMediaLinks: Record<SocialMediaType, string> = {
  discord: 'https://discord.com/invite/jSyVPAXw3w',
  medium: 'https://nufi-official.medium.com/',
  reddit: 'https://www.reddit.com/r/nufiofficial/',
  twitter: 'https://twitter.com/nufiwallet',
  github: 'https://github.com/nufi-official/nufi',
  linkedin: 'https://www.linkedin.com/company/nufiwallet',
  instagram: 'https://www.instagram.com/nufiwallet/',
  facebook: 'https://www.facebook.com/nufiwallet',
}

export const externalLinks = {
  nufiHelpLink: 'https://support.nu.fi',
  nufiContactLink: 'https://support.nu.fi/support/tickets/new',
  stakingInfo: 'https://support.nu.fi/support/solutions/80000433125',
  termsAndConditionsPDF: 'https://nu.fi/legal/nufi-terms-and-conditions.pdf',
  privacyAndCookiesPDF:
    'https://nu.fi/legal/nufi-privacy-and-cookies-policy.pdf',
  conversionRatesProvider: 'https://www.coingecko.com/',
  changellyTermsOfUse: 'https://changelly.com/terms-of-use',
  // currently there is no better source for restricted countries than Changelly's terms of use
  changellyRestrictedCountries: 'https://changelly.com/terms-of-use',
  cardanoCongestionIssues:
    'https://support.nu.fi/support/solutions/articles/80000978817',
  incognitoHelpLink:
    'https://support.nu.fi/support/solutions/articles/80000976246',
  notificationsHelpLinks:
    'https://support.nu.fi/support/solutions/articles/80001062515-turn-on-off-notifications',
  downloadChrome: 'https://www.google.com/chrome/',
  giveFeedback: 'https://nufi.sleekplan.app/',
  socialMediaLinks,
  cardanoEpochInfo:
    'https://support.nu.fi/support/solutions/articles/80000967188',
  solanaEpochInfo:
    'https://support.nu.fi/support/solutions/articles/80000968610',
  flowEpochInfo: 'https://support.nu.fi/support/solutions/articles/80001021612',
  chromeExtensionInfo:
    'https://nufi-official.medium.com/connect-to-nft-marketplaces-dexes-and-other-dapps-with-nufi-wallets-new-chrome-extension-a559ffe4e5a3',
  chromeExtensionLink:
    'https://chrome.google.com/webstore/detail/nufi/gpnihlnnodeiiaakbikldcihojploeca',
  restoreWalletGuideLink:
    'https://support.nu.fi/support/solutions/articles/80001024186-restore-wallet-using-recovery-phrase',
  howToEmulateExtensionLink:
    'https://support.nu.fi/support/solutions/articles/80001046717-wallet-emulation-mode-how-to-connect-to-any-dapp-',
  nufiWeb3AuthInfo:
    'https://support.nu.fi/support/solutions/articles/80001070723',
  nufiWalletConnectInfo:
    'https://support.nu.fi/support/solutions/articles/80001084598',
  nufiWalletConnectNotificationInfo:
    'https://support.nu.fi/support/solutions/articles/80001084598#notifications',
  metamaskUpdate:
    'https://support.metamask.io/managing-my-wallet/using-metamask/how-to-update-the-version-of-metamask',
  metamaskInstall: 'https://metamask.io/download',
  metamaskSnapsDirectory: 'https://snaps.metamask.io',
}

export const getBlockchainExternalLinks = (blockchain: Blockchain) => {
  switch (blockchain) {
    case 'cardano':
      return {
        learnMore:
          'https://support.nu.fi/support/solutions/articles/80000967188-cardano-staking',
      }
    case 'solana':
      return {
        learnMore:
          'https://support.nu.fi/support/solutions/articles/80000968610-solana-staking',
      }
    case 'flow':
      return {
        learnMore: 'https://support.nu.fi/support/solutions', // TODO get proper flow staking link
      }
    default:
      return assertUnreachable()
  }
}

export const blockchainToCoin = (blockchain: Blockchain): Coin => {
  return blockchainMetadata[blockchain].coin
}
