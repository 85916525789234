import React, {useState} from 'react'
import {Redirect, useHistory} from 'react-router-dom'

import {useAvailableExternalLoginProviders} from 'src/features/login'
import {OnboardingModal} from 'src/features/login/ui'
import {useMetamaskContext} from 'src/metamaskSnap/MetamaskContext'
import {MetamaskInitializationGuard} from 'src/metamaskSnap/MetamaskInitializationGuard'
import {routeTo} from 'src/router'

import {LoginFlow} from './LoginFlow'
import {
  InstallSnapContent,
  InstallSnapContentSuccess,
  MetamaskNotDetectedContent,
  UpdateMetamaskContent,
} from './utils'

export const MetamaskLogin = () => {
  const {metamask} = useAvailableExternalLoginProviders()

  if (!metamask) {
    return <Redirect to={routeTo.auth} />
  }

  return (
    <OnboardingModal>
      <MetamaskInitializationGuard>
        <MetamaskLoginContent />
      </MetamaskInitializationGuard>
    </OnboardingModal>
  )
}

const blockchain = 'cardano' as const

const MetamaskLoginContent = () => {
  const {state: metamaskState} = useMetamaskContext()
  const history = useHistory()
  const [showSnapInstallSuccess, setShowSnapInstallSuccess] = useState(
    // It the snap is not originally installed, we will show success screen
    // after installing it.
    !(metamaskState.isInitialized && metamaskState.installedSnap),
  )

  const onCancel = () => history.replace(routeTo.auth)

  if (!metamaskState.isInitialized) {
    return null
  }

  if (!metamaskState.snapsDetected) {
    return <MetamaskNotDetectedContent onCancel={onCancel} />
  }

  if (!metamaskState.isMinimalRequiredVersion) {
    return <UpdateMetamaskContent onCancel={onCancel} />
  }

  if (!metamaskState.installedSnap) {
    return <InstallSnapContent blockchain={blockchain} onCancel={onCancel} />
  }

  if (showSnapInstallSuccess) {
    return (
      <InstallSnapContentSuccess
        blockchain={blockchain}
        onContinue={() => setShowSnapInstallSuccess(false)}
      />
    )
  }

  return <LoginFlow onCancel={onCancel} blockchain={blockchain} />
}
