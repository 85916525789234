import {UNKNOWN_ACCOUNT_NAME} from '@nufi/wallet-common'
import type {FormikHelpers} from 'formik'
import {Formik, FieldArray} from 'formik'
import React from 'react'
import {useHistory} from 'react-router-dom'
import * as yup from 'yup'

import {useIsBlockchainEnabled} from 'src/features/profile/application'
import {useEnableBlockchain} from 'src/features/walletStorage/application/enableBlockchain'
import {useAccountNameValidation} from 'src/utils/account'

import {routeTo} from '../../../../router'
import type {
  AccountInfo,
  DerivationPathType,
  HotVendor,
  HwVendor,
} from '../../../../types'
import {isHotVendorObject, useGenerateAccountName} from '../../../../wallet'

import {AddHwAccountLayout} from './AddAccountHwUtils'
import {ChooseAccountPhase, ConfirmationPhase} from './AddAccountSteps'
import type {AddHwAccountPhase} from './AddAccountUtils'
import {ChooseHotAccountLayout} from './AddAccountUtils'
import type {
  NewAccountWithName,
  AddAccountFieldProps,
  FieldChangeEvent,
  CommonAddAccountFormProps,
} from './types'

type AddAccountFormProps<T extends DerivationPathType> =
  CommonAddAccountFormProps<T> & {
    addAccountPhase: AddHwAccountPhase
    setAddAccountPhase: (phase: AddHwAccountPhase) => void
    cryptoProviderType: HotVendor | HwVendor
  }

export type AddAccountSchema = {
  selectedAccounts: NewAccountWithName[]
}

export function AddAccountForm<T extends DerivationPathType>({
  onSubmit,
  blockchain,
  setAddAccountPhase,
  addAccountPhase,
  ...rest
}: AddAccountFormProps<T>) {
  const generateAccountName = useGenerateAccountName(blockchain)
  const accountNameValidation = useAccountNameValidation()
  const isBlockchainEnabled = useIsBlockchainEnabled(blockchain)
  const enableBlockchain = useEnableBlockchain()

  const history = useHistory()

  const formSchema = yup.object().shape({
    selectedAccounts: yup.array().of(
      yup.object({
        name: accountNameValidation,
      }),
    ),
  })

  // generate names for multiple selected accounts
  const generateNames = (
    namelessAccounts: NewAccountWithName[],
  ): NewAccountWithName[] =>
    namelessAccounts.reduce((accumulator, {account}) => {
      const nextAccount = {
        account,
        name: generateAccountName(
          account,
          accumulator.map(({account, name}) => ({...account, name})),
        ),
      }
      accumulator.push(nextAccount)
      return accumulator
    }, [] as NewAccountWithName[])

  const navigateToSuccess = () =>
    history.push(
      routeTo.portfolio.accounts.addAccount.cryptoProviderType(
        rest.cryptoProviderType,
      ).created,
    )

  const _onSubmit = async (
    {selectedAccounts}: AddAccountSchema,
    formikHelpers: FormikHelpers<AddAccountSchema>,
  ) => {
    if (!isBlockchainEnabled) {
      try {
        await enableBlockchain.mutateAsync(blockchain)
      } catch (e) {
        return
      }
    }
    await onSubmit(
      selectedAccounts.map(({name, account}) => ({...account, name})),
    )
    formikHelpers.resetForm()
    navigateToSuccess()
  }

  return (
    <Formik<AddAccountSchema>
      onSubmit={_onSubmit}
      validationSchema={formSchema}
      initialValues={{selectedAccounts: []}}
    >
      {(formikProps) => {
        const {
          values: {selectedAccounts},
          resetForm,
          setFieldValue,
          handleBlur,
          handleChange,
          handleSubmit,
        } = formikProps
        const selectedAccountsKey: keyof AddAccountSchema = 'selectedAccounts'
        const nameKey: keyof NewAccountWithName = 'name'
        const errors = formikProps.errors as unknown as {
          selectedAccounts: {name: string}[]
        }
        const touched = formikProps.touched as unknown as {
          selectedAccounts: {name: boolean}[]
        }
        return (
          <FieldArray
            validateOnChange={addAccountPhase === 'confirm-account'}
            name={selectedAccountsKey}
            render={({push, remove}) => {
              const onSelectItem = (newAccount: AccountInfo) => {
                const existsIndex = selectedAccounts.findIndex(
                  ({account}) => account.id === newAccount.id,
                )
                if (existsIndex !== -1) {
                  remove(existsIndex)
                } else {
                  const addNewAccount: NewAccountWithName = {
                    name: UNKNOWN_ACCOUNT_NAME, // name to be provided later
                    account: newAccount,
                  }
                  push(addNewAccount)
                }
              }

              const setGeneratedAccountNames = () =>
                setFieldValue(
                  selectedAccountsKey,
                  generateNames(selectedAccounts),
                )
              const getFieldName = (i: number) =>
                `${selectedAccountsKey}[${i}].${nameKey}`
              const getError = (i: number) =>
                touched.selectedAccounts?.[i]?.name &&
                !!errors.selectedAccounts?.[i]?.name
              const getHelperText = (i: number) =>
                touched.selectedAccounts?.[i]?.name &&
                errors.selectedAccounts?.[i]?.name

              return (
                <AddAccountFormContent
                  {...{
                    selectedAccounts,
                    handleSubmit,
                    onSelectItem,
                    setGeneratedAccountNames,
                    blockchain,
                    addAccountPhase,
                    setAddAccountPhase,
                    onSubmit,
                    getFieldHandlers: (index: number) => ({
                      name: getFieldName(index),
                      error: !!getError(index),
                      helperText: getHelperText(index) || undefined,
                      onChange: (e: FieldChangeEvent) =>
                        handleChange(getFieldName(index))(e),
                      onBlur: (e: FieldChangeEvent) =>
                        handleBlur(getFieldName(index))(e),
                    }),
                    resetItems: resetForm,
                    enableBlockchainMutation: enableBlockchain,
                    ...rest,
                  }}
                />
              )
            }}
          />
        )
      }}
    </Formik>
  )
}

type AddAccountFormContentProps<T extends DerivationPathType> =
  AddAccountFormProps<T> & {
    selectedAccounts: NewAccountWithName[]
    onSelectItem: (accountId: AccountInfo) => void
    resetItems: () => void
    handleSubmit: () => void
    setGeneratedAccountNames: () => void
    getFieldHandlers: (index: number) => AddAccountFieldProps
    enableBlockchainMutation: {
      isPending: boolean
      error: unknown
      reset: () => void
    }
  }

const AddAccountFormContent = <T extends DerivationPathType>({
  setGeneratedAccountNames,
  setAddAccountPhase,
  extras,
  addAccountsMutation,
  enableBlockchainMutation,
  ...rest
}: AddAccountFormContentProps<T>) => {
  const onNext = () => {
    setGeneratedAccountNames()
    setAddAccountPhase('confirm-account')
  }
  const commonLayoutProps = {
    setAddAccountPhase,
    addAccountPhase: rest.addAccountPhase,
  }
  const commonChooseAccountPhaseProps = {
    onNext,
    ...extras,
  }

  const combinedMutations = {
    isPending:
      addAccountsMutation.isPending || enableBlockchainMutation.isPending,
    error: addAccountsMutation.error || enableBlockchainMutation.error,
    reset: () => {
      addAccountsMutation.reset()
      enableBlockchainMutation.reset()
    },
  }

  const commonConfirmationPhaseProp = {
    alert: extras?.alert,
    ...combinedMutations,
  }

  return isHotVendorObject(rest) ? (
    <ChooseHotAccountLayout
      {...commonLayoutProps}
      ChooseAccountPhaseContent={
        <ChooseAccountPhase {...commonChooseAccountPhaseProps} {...rest} />
      }
      ConfirmationPhaseContent={
        <ConfirmationPhase {...commonConfirmationPhaseProp} {...rest} />
      }
    />
  ) : (
    <AddHwAccountLayout
      {...commonLayoutProps}
      ChooseAccountPhaseContent={
        <ChooseAccountPhase {...commonChooseAccountPhaseProps} {...rest} />
      }
      ConfirmationPhaseContent={
        <ConfirmationPhase {...commonConfirmationPhaseProp} {...rest} />
      }
    />
  )
}
