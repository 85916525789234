import './initGlobals'
import React from 'react'
import {createRoot} from 'react-dom/client'

import App from './App'
import {initCommonLibs} from './utils/setup'

const initApp = async () => {
  await initCommonLibs()
}

// top-level await avoided using a self-invoking function due to
// errors when parsing by i18next-scanner
;(async () => {
  await initApp()

  const root = createRoot(document.getElementById('root')!)
  root.render(<App />)
})()
