import {
  Box,
  Typography,
  Switch as MuiSwitch,
  FormControlLabel,
} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {getAvailableBlockchains} from 'src/features/availableBlockchains/application'
import {
  useEnabledExistingBlockchains,
  useSettings,
} from 'src/features/profile/application'
import {ManageBlockchainsIconButton} from 'src/features/walletStorage/ui'

import {
  FormattedCurrency,
  CollapsibleSearchField,
  BlockchainSelectField,
} from '../../../components'
import type {Blockchain} from '../../../types'
import {FilterInputWrapper, FiltersWrapper} from '../../../utils/layoutUtils'

type FiltersProps = {
  blockchain: Blockchain | null
  onBlockchainChange: (b: Blockchain | null) => void
  filteredAmount?: number
  zeroBalances: {
    shouldHide: boolean
    toggleHide: () => void
  }
  nfts?: {
    shouldHide: boolean
    toggleHide: () => void
  }
  search?: {
    text: string
    onTextChange: (text: string) => void
  }
}

export function Filters({
  blockchain,
  onBlockchainChange,
  filteredAmount,
  nfts,
  zeroBalances,
  search,
}: FiltersProps) {
  const {t} = useTranslation()
  const {currency} = useSettings()
  const enabledExistingBlockchains = useEnabledExistingBlockchains()
  const disabledExistingBlockchains = getAvailableBlockchains().filter(
    (b) => !enabledExistingBlockchains.includes(b),
  )

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      py={1}
      width="100%"
    >
      <FiltersWrapper>
        <FilterInputWrapper>
          <BlockchainSelectField
            value={blockchain}
            onChange={onBlockchainChange}
            allowedBlockchains={[
              ...enabledExistingBlockchains,
              ...disabledExistingBlockchains,
            ]}
            getOptionDisabled={(b) => !enabledExistingBlockchains.includes(b)}
            label={t('Blockchain')}
            dataTestId="portfolio-filter-blockchain-select-field"
          />
        </FilterInputWrapper>
        <ManageBlockchainsIconButton />
        <FilterInputWrapper>
          {search != null && (
            <CollapsibleSearchField
              value={search.text}
              onChange={search.onTextChange}
            />
          )}
        </FilterInputWrapper>
      </FiltersWrapper>
      <Box display="flex" alignItems="center">
        {filteredAmount != null && (
          <>
            <Typography variant="body2" color="textSecondary">
              {t('Filtered value:')}
            </Typography>
            <Typography variant="body2" fontWeight="medium" pl={1} mr={3}>
              <FormattedCurrency
                convertedBalance={{type: 'success', balance: filteredAmount}}
                currency={currency}
                isSensitiveInformation
              />
            </Typography>
          </>
        )}
        {nfts != null && (
          <Box>
            <FormControlLabel
              control={
                <MuiSwitch
                  checked={nfts.shouldHide}
                  onChange={nfts.toggleHide}
                  size="small"
                />
              }
              label={<Typography variant="body2">{t('Hide NFTs')}</Typography>}
            />
          </Box>
        )}
        <Box>
          <FormControlLabel
            control={
              <MuiSwitch
                checked={zeroBalances.shouldHide}
                onChange={zeroBalances.toggleHide}
                size="small"
              />
            }
            label={
              <Typography variant="body2">{t('Hide zero balances')}</Typography>
            }
          />
        </Box>
      </Box>
    </Box>
  )
}
