import {MoreVert as SplitIcon} from '@mui/icons-material'
import type {SvgIcon, PopperProps} from '@mui/material'
import {
  Grid,
  ClickAwayListener,
  ButtonGroup,
  Grow,
  Paper,
  Popper,
  MenuList,
  MenuItem,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, {useState, useRef} from 'react'

import {WithTooltip} from '../atoms'
import Button from '../atoms/Button'

export type SplitButtonProps = {
  options: Array<{
    label: string
    Icon?: typeof SvgIcon
    onClick?: () => unknown
    disabled?: boolean
    tooltipTitle?: string
    EndIcon?: typeof SvgIcon
  }>
  label?: string
  preventEventsPropagation?: boolean
  popperBoundary?: HTMLElement | null
  popperPlacement?: PopperProps['placement']
  icon?: React.ReactNode
}

// Highly inspired by https://material-ui.com/components/button-group/ (SplitButton),
// added TS & made reusable for our cases
export function SplitButton({
  options,
  label,
  preventEventsPropagation,
  popperBoundary,
  popperPlacement,
  icon,
}: SplitButtonProps) {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const anchorRef = useRef<HTMLDivElement>(null)

  const onMenuItemClick = (event: React.SyntheticEvent, fn?: () => unknown) => {
    preventEventsPropagation && event.stopPropagation()
    fn && fn()
    setOpen(false)
  }

  const onToggle = (event: React.SyntheticEvent) => {
    preventEventsPropagation && event.stopPropagation()
    setOpen((prevOpen) => !prevOpen)
  }

  const onClickOutside = (event: MouseEvent | TouchEvent) => {
    preventEventsPropagation && event.stopPropagation()

    // Do not close if user click on the button itself
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLDivElement)
    ) {
      return
    }
    setOpen(false)
  }

  const Icon = icon ?? <SplitIcon />

  return (
    <Grid container direction="column" alignItems="center">
      <Grid item xs={12}>
        <ButtonGroup ref={anchorRef}>
          {label ? (
            <Button
              size="small"
              textTransform="none"
              onClick={onToggle}
              endIcon={Icon}
              className={classes.button}
            >
              {label || ''}
            </Button>
          ) : (
            <Button variant="text" onClick={onToggle}>
              {Icon}
            </Button>
          )}
        </ButtonGroup>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          className={classes.popper}
          modifiers={
            popperBoundary
              ? [
                  {
                    name: 'preventOverflow',
                    enabled: true,
                    options: {
                      boundary: popperBoundary,
                    },
                  },
                ]
              : undefined
          }
          placement={popperPlacement}
        >
          {({TransitionProps, placement}) => (
            <Grow
              {...TransitionProps}
              /* `Grow does not accept `className` prop */
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper className={classes.poppedPaper} elevation={8}>
                <ClickAwayListener onClickAway={onClickOutside}>
                  <MenuList>
                    {options.map(
                      ({
                        Icon,
                        EndIcon,
                        label,
                        disabled,
                        tooltipTitle,
                        onClick,
                      }) => (
                        <WithTooltip title={tooltipTitle || ''}>
                          <MenuItem
                            key={label}
                            disabled={!!disabled}
                            onClick={(event) => onMenuItemClick(event, onClick)}
                          >
                            {Icon && (
                              <Icon
                                className={classes.actionIcon}
                                color="action"
                                fontSize="small"
                              />
                            )}{' '}
                            {label}
                            {EndIcon && (
                              <EndIcon
                                className={classes.actionIcon}
                                color="action"
                                fontSize="small"
                              />
                            )}
                          </MenuItem>
                        </WithTooltip>
                      ),
                    )}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles((theme) => ({
  popper: {
    zIndex: theme.zIndex.tooltip,
  },
  actionIcon: {
    minWidth: theme.spacing(4),
  },
  button: {
    padding: theme.spacing(0, 2),
    minHeight: 38,
  },
  poppedPaper: {
    backgroundColor: theme.palette.grey['800'],
  },
}))
