import {profileManagerLocator} from 'src/appStorage/profileManager/profileManager'
import type {ProfileSettings} from 'src/appStorage/profileManager/types'
import type {Currency} from 'src/features/conversionRates/domain'
import {cloudSyncServiceLocator} from 'src/features/profileSync/application'
import {useMutation} from 'src/utils/mutation-utils'

import {activateMnemonic, setAmountsVisibility, setCurrency} from '../domain'

import {useSettingsStore} from './store'

const mutateSettings = async (
  fn: (currentSettings: ProfileSettings) => ProfileSettings,
) => {
  const profileManager = profileManagerLocator.instance()

  const {settings} = await profileManager.getProfileData()

  const updatedSettings = fn(settings)

  await profileManager.saveProfileSettings(updatedSettings)

  useSettingsStore.getState().setSettings(updatedSettings)

  await cloudSyncServiceLocator.instance().syncProfileData()
}

export const useActivateMnemonic = () =>
  useMutation('activateMnemonic', async () => {
    await mutateSettings((currentSettings) => activateMnemonic(currentSettings))
  })

export const useSetCurrency = () =>
  useMutation('setCurrency', async ({currency}: {currency: Currency}) => {
    await mutateSettings((currentSettings) =>
      setCurrency(currentSettings, currency),
    )
  })

export const useSetAmountsVisibility = () =>
  useMutation(
    'setAmountsVisibility',
    async ({areAmountsVisible}: {areAmountsVisible: boolean}) => {
      await mutateSettings((currentSettings) =>
        setAmountsVisibility(currentSettings, areAmountsVisible),
      )
    },
  )
