import {Chip, styled} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {TxAccordionRow, ChipRow} from './common'

type TagsProps = {tags: string[]}

export const Tags = ({tags}: TagsProps) => {
  const {t} = useTranslation()
  const hasMultipleTags = tags.length > 1
  return (
    <TxAccordionRow
      summary={
        <ChipRow
          label={`${t('Tags')}:`}
          chipContent={
            hasMultipleTags ? t('tag_count', {count: tags.length}) : tags[0]
          }
        />
      }
      details={
        hasMultipleTags ? (
          <TagsDetailsContainer>
            {tags.map((tag) => (
              <Chip key={tag} label={tag} size="small" />
            ))}
          </TagsDetailsContainer>
        ) : null
      }
    />
  )
}

const TagsDetailsContainer = styled('div')(({theme: {spacing}}) => ({
  display: 'flex',
  gap: spacing(1),
  flexWrap: 'wrap',
}))
