import type {Blockchain} from 'src/types'

// For `platformId` see coingecko's /asset_platforms api endpoint (some blockchains may not have platformId on coingecko)
export const coingeckoBlockchainMetadata = {
  cardano: {
    nativeAssetCoingeckoId: 'cardano',
    platformId: 'cardano',
    supportsQueryingByContractAddress: true,
  },
  solana: {
    nativeAssetCoingeckoId: 'solana',
    platformId: 'solana',
    supportsQueryingByContractAddress: true,
  },
  flow: {
    nativeAssetCoingeckoId: 'flow',
    platformId: 'flow',
    supportsQueryingByContractAddress: true,
  },
  ethereum: {
    nativeAssetCoingeckoId: 'ethereum',
    platformId: 'ethereum',
    supportsQueryingByContractAddress: true,
  },
  // coingecko doesn't support milkomeda but since MILKADA is wrapped ADA
  // we can use the same conversion rates
  milkomedaC1: {
    nativeAssetCoingeckoId: 'cardano',
    platformId: 'milkomeda-cardano',
    supportsQueryingByContractAddress: true,
  },
  optimism: {
    nativeAssetCoingeckoId: 'ethereum',
    platformId: 'optimistic-ethereum',
    supportsQueryingByContractAddress: true,
  },
  polygon: {
    nativeAssetCoingeckoId: 'matic-network',
    platformId: 'polygon-pos',
    supportsQueryingByContractAddress: true,
  },
  arbitrumOne: {
    nativeAssetCoingeckoId: 'ethereum',
    platformId: 'arbitrum-one',
    supportsQueryingByContractAddress: true,
  },
  base: {
    nativeAssetCoingeckoId: 'ethereum',
    platformId: 'base',
    supportsQueryingByContractAddress: true,
  },
} as const satisfies Record<
  Blockchain,
  {
    nativeAssetCoingeckoId: string
    platformId?: string
    supportsQueryingByContractAddress: boolean
  }
>

export type CoingeckoBlockchainMetadata = typeof coingeckoBlockchainMetadata
