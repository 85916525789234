import {Box} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'

export function StakeAccountCardWrapper({
  children,
}: {
  children: React.ReactNode
}) {
  const classes = useStakeAccountCardWrapperStyles()

  return <Box className={classes.layoutWrapper}>{children}</Box>
}

const useStakeAccountCardWrapperStyles = makeStyles((theme) => ({
  tooltip: {
    fontSize: 'large',
  },
  layoutWrapper: {
    cursor: 'pointer',
    background: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    '&:last-child': {
      borderBottom: 'none',
    },
    padding: theme.spacing(1, 0),
  },
}))
