import {Sentry} from '@nufi/frontend-common'
import {useQuery} from '@tanstack/react-query'

import type {TokenConversionRates} from 'src/features/conversionRates/domain'
import {useIsBlockchainEnabled} from 'src/features/profile/application'
import type {Blockchain, TokenMetadata} from 'src/types'
import {useHasAccounts} from 'src/wallet/public/queries/accounts'

import queryClient from '../../../queryClient'
import {cachedGetTokensMetadata as cachedGetCardanoTokensMetadata} from '../../../wallet/cardano/public/queries'
import {cachedGetTokensMetadata as cachedGetEvmTokensMetadata} from '../../../wallet/evm/public/queries'
import {fnPerEvmBlockchain} from '../../../wallet/evm/public/utils'
import {cachedGetTokensMetadata as cachedGetFlowTokensMetadata} from '../../../wallet/flow/public/queries'
import {cachedGetTokensMetadata as cachedGetSolanaTokensMetadata} from '../../../wallet/solana/public/queries'

import type {ConversionRateRefetchParams} from './common'
import {conversionRatesService} from './conversionRatesService'
import {conversionRatesQueryKeys} from './queryKeys'

const getBlockchainTokensConversionRates =
  (blockchain: Blockchain) =>
  async (): Promise<{data?: TokenConversionRates; error?: unknown}> => {
    try {
      const loadBlockchainMetadata = {
        solana: cachedGetSolanaTokensMetadata,
        flow: cachedGetFlowTokensMetadata,
        cardano: cachedGetCardanoTokensMetadata,
        ...fnPerEvmBlockchain(
          (evmBlockchain) => () => cachedGetEvmTokensMetadata(evmBlockchain),
        ),
      }[blockchain]

      const metadata: TokenMetadata[] = await loadBlockchainMetadata()
      const tokensToFetch = metadata.filter((token) =>
        conversionRatesService.supportsToken(token),
      )
      try {
        const rates =
          tokensToFetch.length > 0
            ? // retries handled internally
              await conversionRatesService.getTokenConversionRates(
                tokensToFetch,
              )
            : {}
        return {data: rates}
      } catch (err) {
        Sentry.captureException(err)
        return {
          data: tokensToFetch.reduce((res, t) => {
            res[t.id] = 'not-loaded'
            return res
          }, {} as TokenConversionRates),
        }
      }
    } catch (error) {
      return {error}
    }
  }

export function useGetBlockchainTokensConversionRates(
  blockchain: Blockchain,
  {refetchOptions}: ConversionRateRefetchParams,
) {
  const hasAccounts = useHasAccounts(blockchain)
  const isBlockchainEnabled = useIsBlockchainEnabled(blockchain)
  return useQuery({
    queryKey: conversionRatesQueryKeys.tokens(blockchain),
    queryFn: getBlockchainTokensConversionRates(blockchain),
    ...refetchOptions,
    // Retries handled internally be query fn
    retry: false,
    enabled: isBlockchainEnabled && hasAccounts,
  })
}

export async function getInMemoryBlockchainTokensConversionRates(
  blockchain: Blockchain,
) {
  const queryKey = conversionRatesQueryKeys.tokens(blockchain)
  // Used to return the current data without waiting on a network request that
  // may take long time or fail. We are assuming that this data will be eventually
  // consistent, because the query consuming this data should be invalidated
  // every time this data changes.
  type Response = ReturnType<
    typeof useGetBlockchainTokensConversionRates
  >['data']
  return {...(await queryClient.getQueryData(queryKey))} as Response
}
