import type {SolanaTokenMetadata} from '@nufi/wallet-solana'
import _ from 'lodash'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {SolanaTokenExplorerLink} from '../../../components'
import {assert} from '../../../utils/assertion'
import {NftDetailPage} from '../../nfts/NftDetail'
import {AboutRow} from '../assetDetail/AboutRow'
import type {NftInfo} from '../assetDetail/types'

type SolanaTokenDetailInfoProps = {
  token: SolanaTokenMetadata
  nftInfo: NftInfo
}

export function SolanaTokenDetailInfo({
  token,
  nftInfo,
}: SolanaTokenDetailInfoProps) {
  const {t} = useTranslation()
  const {id} = token

  return nftInfo ? (
    <NftTokenDetailInfo token={token} nftInfo={nftInfo} />
  ) : (
    <>
      <AboutRow label={t('Mint')} value={id} />
      {token.decimals !== undefined && (
        <AboutRow label={t('Decimals')} value={token.decimals} />
      )}
      <AboutRow
        label={t('More details')}
        value={<SolanaTokenExplorerLink tokenId={id} />}
      />
    </>
  )
}

type NftTokenDetailInfoProps = {token: SolanaTokenMetadata; nftInfo: NftInfo}

function NftTokenDetailInfo({nftInfo}: NftTokenDetailInfoProps) {
  const {t} = useTranslation()
  if (!nftInfo) return null
  assert(nftInfo.blockchain === 'solana')

  return (
    <NftDetailPage
      details={[
        {label: t('Description'), value: nftInfo.description},
        {
          label: t('More details'),
          value: <SolanaTokenExplorerLink tokenId={nftInfo.id} />,
        },
      ]}
      traits={nftInfo.traits.map((trait) => ({
        label: _.upperFirst(_.toLower(trait.name)),
        value: trait.value,
      }))}
      blockchain={nftInfo.blockchain}
      nftName={nftInfo.name}
      collectionName={nftInfo.ticker}
    />
  )
}
