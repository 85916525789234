import {profileManagerLocator} from 'src/appStorage/profileManager'
import {useSettingsStore} from 'src/features/profile/application'
import {invalidateAllQueryClientQueries} from 'src/utils/query-utils/utils'
import {TokenImportStoreManagerProvider} from 'src/wallet/TokenImportStoreManager'
import {TokenVisibilityStoreManagerProvider} from 'src/wallet/TokenVisibilityStoreManager'

import {AccountStorageServiceLocator} from './index'

export async function reloadAppState() {
  useSettingsStore
    .getState()
    .setSettings(
      (await profileManagerLocator.instance().getProfileData()).settings,
    )
  // Note that this must be called before queries invalidation, as some queries directly
  // access `AccountStorageService` and `TokenStoreManagerProvider` state.
  // For example we allow adding tokens from the dapp connector, i.e. outside the app,
  // and once user refreshes the page, we want the data to be up-to-date.
  await AccountStorageServiceLocator.instance().reloadInMemoryAccounts()
  await TokenImportStoreManagerProvider.instance().reload()
  await TokenVisibilityStoreManagerProvider.instance().reload()

  await invalidateAllQueryClientQueries()
}
