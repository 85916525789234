import EventEmitter from 'events'

// We are using events emitter, so that we can publish events even from the "domain"
// code without being forced to import from "application" layer, or being forced to
// inject some "publish" function everywhere where we want to use it.
// This should be similar to how we use Sentry anywhere in the codebase.
const warningsEventEmitter = new EventEmitter()

const UPDATE_WARNINGS_EVENT = 'UPDATE_WARNINGS'

export type NonPublishedWarning = {
  key: string[]
  assetsValuePotentiallyAffected: boolean
}

export type Warning = NonPublishedWarning & {
  createdAt: number
}

type UpdateWarningsEventData<T extends NonPublishedWarning> = {
  newWarnings: T[]
  keysToClear?: string[][]
}

export function updateWarnings<T extends NonPublishedWarning>(
  newWarnings: T[],
  keysToClear?: string[][],
): void {
  const warnings = newWarnings.map((w) => ({...w, createdAt: Date.now()}))
  const data: UpdateWarningsEventData<T> = {
    newWarnings: warnings,
    keysToClear,
  }
  warningsEventEmitter.emit(UPDATE_WARNINGS_EVENT, data)
}

export function registerUpdateWarningsListener<T extends Warning>(
  fn: (data: UpdateWarningsEventData<T>) => unknown,
) {
  warningsEventEmitter.addListener(UPDATE_WARNINGS_EVENT, fn)
}

const warningKeysEqual = (key1: string[], key2: string[]) =>
  JSON.stringify(key1) === JSON.stringify(key2)

export function mergeWarnings<T extends Warning>({
  newWarnings,
  currentWarnings,
  keysToClear,
}: {
  newWarnings: T[]
  currentWarnings: T[]
  keysToClear?: string[][]
}): T[] {
  return [
    // new warnings that weren't among current warnings yet
    ...newWarnings.filter(
      (nw) => !currentWarnings.some((cw) => warningKeysEqual(nw.key, cw.key)),
    ),
    // current warnings plus the rest of new warnings (keeping their original ordering), minus the ones to be unpublished
    ...currentWarnings.filter(
      (cw) =>
        newWarnings.some((nw) => warningKeysEqual(nw.key, cw.key)) ||
        !keysToClear?.some((pu) =>
          warningKeysEqual(cw.key.slice(0, pu.length), pu),
        ),
    ),
  ]
}

export function dropWarnings<T extends Warning>({
  warningsToDrop,
  currentWarnings,
}: {
  warningsToDrop: T[]
  currentWarnings: T[]
}): T[] {
  return currentWarnings.filter(
    (w) => !warningsToDrop.some((ctd) => warningKeysEqual(ctd.key, w.key)),
  )
}

export function hasAssetsValuePotentiallyAffectingWarnings<T extends Warning>(
  warnings: T[],
) {
  return warnings.some((w) => w.assetsValuePotentiallyAffected)
}

export function hasNewWarnings<T extends Warning>(
  lastSeen: number | null,
  warnings: T[],
) {
  if (warnings.length === 0) return false
  if (lastSeen == null) return true

  return warnings.some((w) => w.createdAt >= lastSeen)
}
