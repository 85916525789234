import {safeAssertUnreachable} from '@nufi/frontend-common'
import {useTranslation} from 'react-i18next'

import {useGetBlockchainName} from 'src/utils/translations'

import type {CommonAppWarning} from '../domain/commonWarnings'

export const useGetCommonAppWarningTexts = () => {
  const {t} = useTranslation()
  const getBlockchainName = useGetBlockchainName()

  return (warning: CommonAppWarning) => {
    const {type} = warning

    switch (type) {
      case 'PARTIAL_TOKENS_FAILURE':
        return {
          title: t('partial_error_loading_tokens_title', {
            blockchain: getBlockchainName(warning.blockchain),
          }),
          description: t('partial_error_loading_tokens_description'),
        }
      case 'NATIVE_ASSET_FAILURE':
        return {
          title: t('error_loading_native_asset_title', {
            blockchain: getBlockchainName(warning.blockchain),
          }),
          description: t('general_warning_description'),
        }
      case 'TOKENS_FAILURE':
        return {
          title: t('error_loading_tokens_title', {
            blockchain: getBlockchainName(warning.blockchain),
          }),
          description: t('general_warning_description'),
        }
      default:
        return safeAssertUnreachable(type)
    }
  }
}
