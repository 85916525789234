import {useFormikContext} from 'formik'
import {useCallback} from 'react'

export const useFormikWithReducer = <T, A>(
  reducer: (state: T, action: A) => T,
) => {
  const {
    setFieldValue: _ignored1, // omitting because it could break the type contract
    setValues,
    ...formik
  } = useFormikContext<T>()

  const formDispatch = useCallback(
    (action: A) => setValues((prev) => reducer(prev, action)),
    [setValues, reducer],
  )

  return {...formik, setValues, formDispatch}
}
