import React from 'react'

import type {NumberFormat} from 'src/components'
import {SignScreen} from 'src/dappConnector/pages/sign'
import type {SignScreenContext} from 'src/features/dex/dexHunter/application'
import type {CardanoAccountInfo} from 'src/wallet/cardano'

// Do not try to shared with Widget layout props as they
// can evolve independently.
const LAYOUT_PROPS = {
  maxDetailsHeight: 'auto',
  txDataBgColor: 'default',
  screenWidth: '100%',
  signLayoutOverflow: 'auto',
} as const

export const DexHunterSignScreen = ({
  signScreenContext,
  selectedAccount,
  selectedAccountAmountFormat,
  isPasswordLogin,
}: {
  signScreenContext: SignScreenContext
  selectedAccount: CardanoAccountInfo
  selectedAccountAmountFormat?: NumberFormat
  isPasswordLogin: boolean
}) => {
  return (
    <SignScreen
      signState={{
        selectedAccount,
        origin: 'https://app.dexhunter.io/',
        favIconUrl: 'https://app.dexhunter.io/favicon.ico',
        blockchain: 'cardano',
        signData: signScreenContext,
        layoutProps: LAYOUT_PROPS,
        hideProfileInfo: true,
        enforceMnemonicPassword:
          selectedAccount.cryptoProviderType === 'mnemonic' && isPasswordLogin,
        selectedAccountAmountFormat,
      }}
    />
  )
}
