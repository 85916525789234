import {Grid} from '@mui/material'
import React from 'react'

import type {Blockchain, TokenMetadata} from '../types'

import {AssetIcon} from './AssetIcon'
import {ModalHeader} from './visual/Modal'

type IconModalHeaderProps = {
  onClose: () => unknown
  iconElement: React.ReactNode
  children: React.ReactNode
}

export function IconModalHeader({
  onClose,
  iconElement,
  children,
}: IconModalHeaderProps) {
  return (
    <ModalHeader onClose={onClose} hasDivider>
      <Grid container spacing={2} alignItems="center">
        <Grid item>{iconElement}</Grid>
        <Grid item>
          <Grid container direction="column">
            {children}
          </Grid>
        </Grid>
      </Grid>
    </ModalHeader>
  )
}

type AssetIconModalHeaderProps = {
  onClose: () => unknown
  blockchain: Blockchain
  children: React.ReactNode
  tokenMetadata?: TokenMetadata
}

export function AssetIconModalHeader({
  onClose,
  blockchain,
  children,
  tokenMetadata,
}: AssetIconModalHeaderProps) {
  return (
    <IconModalHeader
      onClose={onClose}
      iconElement={
        <AssetIcon
          blockchain={blockchain}
          tokenMetadata={tokenMetadata}
          showBlockchainBadge
        />
      }
      children={children}
    />
  )
}
