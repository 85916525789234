import type {
  FlowAccountInfo,
  FlowSignedTransaction,
  FlowTokenId,
  FlowTxPlan,
  Nanoflow,
} from '@nufi/wallet-flow'
import {Formik, useFormikContext} from 'formik'
import React, {useMemo} from 'react'

import {useLastDefinedData} from 'src/utils/query-utils'

import {ImportTxError, QueryGuard} from '../../../../../../components'
import type {AccountId} from '../../../../../../types'
import {assert} from '../../../../../../utils/assertion'
import {
  findContractMetadataByTokenId,
  getTokenMetadataFromContract,
  useGetMaxSendableFlowAmount,
  useSignTx,
  useSubmitTx,
} from '../../../../../../wallet/flow'
import {ensureAccountById} from '../../../../../../wallet/utils/common'
import {DeviceReadyState} from '../../../../../send/common/utils'
import {
  SignTxFlow,
  useActiveSchema,
  useActiveScreenState,
  useFormikOnSubmit,
} from '../../../../../transaction'
import {useSummarySchema} from '../ImportTx/schema'

import type {ImportFlowTokenSchema} from './schema'
import {useDetailsSchema} from './schema'
import SummaryScreen from './SummaryScreen'
import type {ImportFlowTokenFlowProps} from './types'

export function ImportFlowTokenFlow({
  accountId,
  accounts,
  ...restProps
}: ImportFlowTokenFlowProps) {
  const {maxAmountQuery} = useLastDefinedData({
    maxAmountQuery: useGetMaxSendableFlowAmount({
      accountInfo: ensureAccountById(accounts, accountId),
      txType: 'setupToken',
    }),
  })
  return (
    <QueryGuard
      {...maxAmountQuery}
      ErrorElement={
        <ImportTxError
          blockchain="flow"
          onBack={restProps.onBack}
          onClose={restProps.onClose}
        />
      }
      loadingVariant="centered"
    >
      {({fee: txFee, maxAmount: maxSendableAmount}) => (
        <_ImportFlowTokenFlow
          {...{txFee, maxSendableAmount, accountId, accounts, ...restProps}}
        />
      )}
    </QueryGuard>
  )
}

function _ImportFlowTokenFlow({
  accountId,
  accounts,
  txFee,
  maxSendableAmount,
  ...props
}: ImportFlowTokenFlowProps & {txFee: Nanoflow; maxSendableAmount: Nanoflow}) {
  const submit = useSubmitTx()
  const sign = useSignTx()
  const {setActiveScreen} = useActiveScreenState()
  const account = ensureAccountById(accounts, accountId) as FlowAccountInfo

  const initialValues = {
    accountId,
    contractId: '' as FlowTokenId,
    password: '',
  }
  const schema = useActiveSchema({
    details: useDetailsSchema(account, txFee, maxSendableAmount),
    summary: useSummarySchema({accounts}),
  })

  const onTxSign = async (values: ImportFlowTokenSchema) => {
    const accountInfo = ensureAccountById(accounts, values.accountId)
    const contractMetadata = findContractMetadataByTokenId(
      props.tokenContracts,
      values.contractId,
    )
    assert(!!contractMetadata)
    const txPlan: FlowTxPlan = {
      type: 'setupToken',
      contractMetadata,
      args: null,
    }
    return await sign.mutateAsyncSilent({txPlan, accountInfo})
  }

  const onTxSubmit = async (
    accountId: AccountId,
    signedTx: FlowSignedTransaction,
  ) => await submit.mutateAsyncSilent({accountId, signedTx})

  const onTxSignAndSubmit = async (values: ImportFlowTokenSchema) => {
    const signedTx = await onTxSign(values)
    if (!signedTx) return
    setActiveScreen('submit')
    await onTxSubmit(values.accountId, signedTx)
  }

  const onSubmit = useFormikOnSubmit<ImportFlowTokenSchema>({
    accounts,
    onTxSignAndSubmit,
  })

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={onSubmit}
    >
      <ImportFlowTokenFlowContent
        {...props}
        {...{
          accountId,
          accounts,
          txFee,
          submit,
          sign,
          onTxSubmit,
          onTxSignAndSubmit,
        }}
      />
    </Formik>
  )
}

function ImportFlowTokenFlowContent({
  onClose,
  onBack,
  accounts,
  txFee,
  accountId,
  sign,
  submit,
  onTxSubmit,
  onTxSignAndSubmit,
  renderDetails,
  renderModalHeader,
  renderSubmit,
  tokenContracts,
}: ImportFlowTokenFlowProps & {
  txFee: Nanoflow
  submit: ReturnType<typeof useSubmitTx>
  sign: ReturnType<typeof useSignTx>
  onTxSubmit: (
    accountId: AccountId,
    signedTx: FlowSignedTransaction,
  ) => Promise<unknown>
  onTxSignAndSubmit: (values: ImportFlowTokenSchema) => Promise<unknown>
}) {
  const {setActiveScreen} = useActiveScreenState()
  const account = ensureAccountById(accounts, accountId) as FlowAccountInfo
  const formikProps = useFormikContext<ImportFlowTokenSchema>()

  const tokenMetadata = useMemo(
    () =>
      getTokenMetadataFromContract(
        findContractMetadataByTokenId(
          tokenContracts,
          formikProps.values.contractId,
        ),
      ),
    [tokenContracts, formikProps.values.contractId],
  )

  const summary = {
    account,
    fee: txFee,
    tokenMetadata,
    tokenId: formikProps.values.contractId,
  }

  return (
    <SignTxFlow
      {...{onClose, formikProps, onTxSubmit, DeviceReadyState}}
      onBack={onBack}
      blockchain="flow"
      signProps={sign}
      submitProps={submit}
      onTxSubmit={onTxSubmit}
      onTxSignAndSubmit={onTxSignAndSubmit}
      ModalHeader={renderModalHeader(tokenMetadata)}
      renderDetails={renderDetails}
      renderSummary={() => (
        <SummaryScreen
          onBack={() => setActiveScreen('details')}
          onSubmit={formikProps.handleSubmit}
          ModalHeader={renderModalHeader(tokenMetadata)}
          {...{summary, accounts, onTxSignAndSubmit}}
        />
      )}
      {...(renderSubmit
        ? {
            renderSubmit: (utils) =>
              renderSubmit({...utils}, submit, tokenMetadata),
          }
        : {})}
    />
  )
}
