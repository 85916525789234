import {makeStyles} from '@mui/styles'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {useGetSwap} from 'src/features/swaps/application'

import {Modal, ModalLayout, QueryGuard} from '../../components'
import {useExchangeDetailRouteOptions} from '../../router/portfolio'

import {ExchangeModalHeader} from './components'
import {EXCHANGE_MODAL_WIDTH} from './constants'
import {SwapSuccess} from './screens/swapResult'
import {SwapError} from './SwapError'

type ExchangeModalProps = {
  onClose: () => unknown
}

export const ExchangeDetailModal = ({onClose}: ExchangeModalProps) => {
  const {t} = useTranslation()
  const classes = useStyles()
  const {exchangeId} = useExchangeDetailRouteOptions()
  const swapQuery = useGetSwap(exchangeId)

  return (
    <Modal
      onClose={onClose}
      variant="left"
      dismissable
      className={classes.modal}
    >
      <ModalLayout
        header={
          <ExchangeModalHeader onClose={onClose} title={t('Exchange detail')} />
        }
        body={
          <QueryGuard
            {...swapQuery}
            loadingVariant="centered"
            ErrorElement={
              <SwapError
                error={swapQuery.error}
                errorMessage={t('Could not load the exchange detail')}
              />
            }
          >
            {(swap) => <SwapSuccess {...swap} />}
          </QueryGuard>
        }
      />
    </Modal>
  )
}

const useStyles = makeStyles(() => ({
  modal: {
    width: `${EXCHANGE_MODAL_WIDTH}px !important`, // TODO use sx prop after MUI converting
  },
}))
