import type BigNumber from 'bignumber.js'
import React from 'react'

import type {TokenBlockchain} from 'src/blockchainTypes'
import {AmountVisibilityWrapper} from 'src/components/visual'
import {
  getBlockchainDecimals,
  getRenderedBlockchainDecimals,
  MINIMUM_FRACTION_DIGITS,
} from 'src/constants'
import type {TokenMetadata, Blockchain} from 'src/types'
import {blockchainMetadata} from 'src/wallet/constants'

import {formatNumber} from './formatNumber'
import type {
  FormattedNumberParams,
  FormattedNumberVariantParams,
  NumberFormat,
} from './formatNumber'

type FormattedNativeAmountProps = {
  format?: NumberFormat
  blockchain: Blockchain
  value: BigNumber
  isSensitiveInformation: boolean
}

export function FormattedNativeAmount(props: FormattedNativeAmountProps) {
  const {blockchain, value, isSensitiveInformation, format = 'full'} = props

  const formatProps: FormattedNumberVariantParams =
    format === 'full'
      ? {
          format: 'full',
          maximumFractionDigits: getRenderedBlockchainDecimals(blockchain),
          minimumFractionDigits: MINIMUM_FRACTION_DIGITS,
        }
      : {
          format: 'compact',
        }

  return (
    <FormattedNumber
      value={value}
      {...formatProps}
      decimals={getBlockchainDecimals(blockchain)}
      isSensitiveInformation={isSensitiveInformation}
    />
  )
}

type FormattedTokenAmountProps = {
  format?: NumberFormat
  blockchain: TokenBlockchain
  value: BigNumber
  tokenInfo: TokenMetadata
  isSensitiveInformation: boolean
}

export function FormattedTokenAmount(props: FormattedTokenAmountProps) {
  const {
    blockchain,
    value,
    tokenInfo,
    isSensitiveInformation,
    format = 'full',
  } = props
  const {decimals} = tokenInfo

  const formatProps: FormattedNumberVariantParams =
    format === 'full'
      ? {
          format: 'full',
          maximumFractionDigits: decimals,
          minimumFractionDigits:
            blockchainMetadata[blockchain].minimumFractionDigits,
        }
      : {
          format: 'compact',
        }

  return (
    <FormattedNumber
      value={value}
      {...formatProps}
      decimals={decimals}
      isSensitiveInformation={isSensitiveInformation}
    />
  )
}

type FormattedNumberProps = {
  isSensitiveInformation: boolean
} & FormattedNumberParams

function FormattedNumber({
  value,
  decimals,
  isSensitiveInformation,
  ...rest
}: FormattedNumberProps) {
  return (
    <AmountVisibilityWrapper {...{isSensitiveInformation}}>
      <>
        {formatNumber({
          value,
          decimals,
          ...rest,
        })}
      </>
    </AmountVisibilityWrapper>
  )
}
