import {assert} from '@nufi/frontend-common'
import type {EvmBlockchain} from '@nufi/wallet-evm'
import {objectEntries} from 'common/src/typeUtils'
import _ from 'lodash'

import type {ProfileData} from 'src/appStorage/profileManager/types'
import {setEnabledBlockchains} from 'src/features/profile/domain'
import type {AccountStoredData} from 'src/store/wallet'
import {
  ensureDefaultEvmAccount,
  ensureDefaultStandardAccount,
} from 'src/wallet/AccountsStoreManager'
import type {Blockchain} from 'src/wallet/types'
import {blockchainToWalletKind} from 'src/wallet/walletKind'
import type {WalletKind} from 'src/wallet/walletKind'

import type {AccountsStorageService} from '../domain'

/**
 * Return "ProfileData" with "allEnabledBlockchains" set as "enabledBlockchains"
 * and ensured default account for all "newlyEnabledBlockchains".
 */
export const getProfileDataWithUpdatedEnabledBlockchains = ({
  allEnabledBlockchains,
  newlyEnabledBlockchains,
  profileData,
  getWalletKindAccounts,
  getUpdatedAccounts,
}: {
  allEnabledBlockchains: Blockchain[]
  newlyEnabledBlockchains: Blockchain[]
  profileData: ProfileData
  getWalletKindAccounts: (walletKind: WalletKind) => AccountStoredData[]
  getUpdatedAccounts: AccountsStorageService<AccountStoredData>['getUpdatedAccountsForWalletKinds']
}): ProfileData => {
  const {settings} = profileData

  const newSettings = setEnabledBlockchains(settings, allEnabledBlockchains)

  const walletKindBlockchains = _.groupBy(
    newlyEnabledBlockchains,
    blockchainToWalletKind,
  )

  const walletKindAccountsWithEnsuredDefaultAccount = Object.fromEntries(
    objectEntries(walletKindBlockchains).map(([walletKind, blockchains]) => {
      const _walletKind = walletKind as WalletKind

      const walletKindInMemoryAccounts = getWalletKindAccounts(_walletKind)

      const inMemoryAccountsWithEnsuredDefaultAccount = (() => {
        if (_walletKind !== 'evm') {
          const _accounts = walletKindInMemoryAccounts.map((a) => {
            assert(a.walletKind === _walletKind)
            return a
          })
          return ensureDefaultStandardAccount(_accounts)
        } else {
          const _accounts = walletKindInMemoryAccounts.map((a) => {
            assert(a.walletKind === _walletKind)
            return a
          })
          return blockchains.reduce((acc, blockchain) => {
            return ensureDefaultEvmAccount(acc, blockchain as EvmBlockchain)
          }, _accounts)
        }
      })()

      return [_walletKind, inMemoryAccountsWithEnsuredDefaultAccount]
    }),
  ) as Record<WalletKind, AccountStoredData[]>

  const newAccounts = getUpdatedAccounts(
    walletKindAccountsWithEnsuredDefaultAccount,
  )

  const newProfileData = {
    ...profileData,
    settings: newSettings,
    accounts: newAccounts,
  }

  return newProfileData
}
