import type {ExchangeBlockchain} from '../../blockchainTypes'
import {exchangeBlockchains} from '../../blockchainTypes'
import type {EvmBlockchain} from '../../wallet/evm'
import {evmBlockchains} from '../../wallet/evm'

import {cardanoExchangeDeps} from './blockchains/cardano'
import {evmExchangeDeps} from './blockchains/evm'
import {flowExchangeDeps} from './blockchains/flow'
import {solanaExchangeDeps} from './blockchains/solana'
import type {ExchangeDeps, ExchangeDepsConfig} from './screens/common'

type EvmAndExchangeBlockchain = Extract<ExchangeBlockchain, EvmBlockchain>

const evmBlockchainsExchangeDeps = evmBlockchains
  .filter((b) => exchangeBlockchains.includes(b as ExchangeBlockchain))
  .reduce(
    (res, b) => {
      res[b as EvmAndExchangeBlockchain] = evmExchangeDeps
      return res
    },
    {} as Record<EvmAndExchangeBlockchain, ExchangeDeps>,
  )

export const EXCHANGE_CONFIG: ExchangeDepsConfig = {
  cardano: cardanoExchangeDeps,
  flow: flowExchangeDeps,
  solana: solanaExchangeDeps,
  ...evmBlockchainsExchangeDeps,
}
