import {safeAssertUnreachable} from '@nufi/frontend-common'
import {useTranslation} from 'react-i18next'

import type {ExchangeBlockchain} from 'src/blockchainTypes'
import {commonFromAssetValidation} from 'src/features/exchange/application'
import type {CommonValueFieldConstraints} from 'src/features/exchange/domain'

import type {AccountInfo} from '../../../../types'
import type {SwapTokenData} from '../../types'

import type {DetailsValues} from './schema'

/**
 * The goal of this hook is to return a function with generic validation logic for "account" field, into which
 * blockchain specific properties/constraints can be injected from outside.
 *
 * @param values Values from the exchange details screen.
 * @param fromAccountInfo AccountInfo of the currently selected account or `null` if none is selected.
 * @param fromToken Info about the token we are swapping from. If swapping from native asset this is set to `null`.
 * @param getValueConstraints Function that returns blockchains specific constraints that we plug-in into common validation
 * rules for the amount field.
 * @returns function that can be use as "validate" prop for Formik "validate" prop.
 */
export const useCommonAmountValidation = (
  values: DetailsValues,
  fromAccountInfo: AccountInfo | null,
  fromToken: SwapTokenData | null,
  getValueConstraints: (
    amount: DetailsValues['amount'],
  ) => Promise<CommonValueFieldConstraints | null>,
): ((value: DetailsValues['amount']) => Promise<string | undefined>) => {
  const {t} = useTranslation()

  // This function is meant to be used as the "validate" prop of the Formik <Field /> component.
  return async (
    // We do this because the "amount" in "values" is stale at this time and will only be "fresh" after
    // another re-render cycle which is too late for us.
    humanReadableAmount: DetailsValues['amount'],
  ): Promise<string | undefined> => {
    // There is nothing to validate if "fromAccount" was not selected or value is empty.
    if (!fromAccountInfo || !humanReadableAmount) return undefined

    const result = await commonFromAssetValidation({
      nativeAccountBalance: fromAccountInfo.balance,
      blockchain: fromAccountInfo.blockchain as ExchangeBlockchain,
      tokenId: fromToken?.id || null,
      getValueConstraints,
      humanReadableAmountToExchange: humanReadableAmount,
      assetName: values.fromAsset,
    })

    if (result === undefined) return undefined

    const {type} = result

    switch (type) {
      case 'UNEXPECTED_ERROR':
        return t('Unexpected error. Please contact support.')
      case 'INSUFFICIENT_ACCOUNT_BALANCE':
        return t('Insufficient account balance.')
      case 'INSUFFICIENT_NATIVE_BALANCE_FOR_NETWORK_FEE':
        return t('Insufficient native balance to pay for the network fee.')
      case 'COULD_NOT_GET_DECIMALS':
        return t('Could not fetch token decimals.')
      case 'TOO_MANY_DECIMALS_FOR_KNOWN_ASSET':
        return t('too_many_decimals_for_known_asset', {
          decimals: result.args.decimals,
          asset: result.args.assetName.toUpperCase(),
        })
      default:
        return safeAssertUnreachable(type)
    }
  }
}
