import React, {useEffect, useState} from 'react'

import {useInitProfileTracking} from './trackProfile'

const _ProfileTracking = () => {
  useInitProfileTracking()
  return null
}

export const ProfileTracking = () => {
  // Dirty hack - if profile tracking is initialized too early after
  // login, for some reason not yet understood the tracking queries
  // cause the app to freeze during login, even for several tens of seconds.
  //
  // However, if we wait several seconds before firing up the tracking queries
  // the subsequent tracking queries apparently no longer freeze the app.
  const [shouldLoad, setShouldLoad] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      setShouldLoad(true)
    }, 5000)
  })
  return shouldLoad ? <_ProfileTracking /> : null
}
