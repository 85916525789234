import {Box, styled} from '@mui/material'
import React from 'react'

const TOPBAR_HEIGHT = 70

type TopBarProps = {
  leftComponent?: React.ReactNode
  middleComponent?: React.ReactNode
  rightComponent?: React.ReactNode
}

export function TopBar({
  leftComponent,
  rightComponent,
  middleComponent,
}: TopBarProps) {
  return (
    <TopBarWrapper>
      <Box>{leftComponent}</Box>
      <Box
        position="absolute"
        margin="auto"
        left="50%"
        style={{transform: 'translate(-50%, 0%)'}}
        width="max-content"
      >
        {middleComponent}
      </Box>
      <Box>{rightComponent}</Box>
    </TopBarWrapper>
  )
}

const TopBarWrapper = styled('div')(({theme}) => ({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(1.5, 2.5),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  height: TOPBAR_HEIGHT,
}))
