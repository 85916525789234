import {Download as ImportIcon} from '@mui/icons-material'
import {Grid, Typography} from '@mui/material'
import type BigNumber from 'bignumber.js'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {useFilterEnabledBlockchains} from 'src/features/profile/application'

import type {TokenBlockchain} from '../../../../../blockchainTypes'
import {tokenBlockchains} from '../../../../../blockchainTypes'
import {
  FooterLayout,
  LabeledIcon,
  ModalFooter,
  ModalHeader,
} from '../../../../../components'
import type {AccountId, TokenMetadata} from '../../../../../types'
import {safeAssertUnreachable} from '../../../../../utils/assertion'
import {isEvmBlockchain} from '../../../../../wallet/evm'
import {AboutRow} from '../../../assetDetail/AboutRow'
import {CardanoTokenDetailInfo} from '../../../cardano/TokenDetailInfo'
import {FlowTokenDetailInfo} from '../../../flow/TokenDetailInfo'
import {SolanaTokenDetailInfo} from '../../../solana/TokenDetailInfo'

import {EvmImportTokenDetailInfo} from './evm/TokenDetailInfo'

export const useTokenBlockchains = () => {
  const filterEnabledBlockchains = useFilterEnabledBlockchains()

  const blockchains = filterEnabledBlockchains(tokenBlockchains)
  const blockchainsWithTokenAutoImport =
    filterEnabledBlockchains<TokenBlockchain>(['cardano', 'solana'])

  return {blockchains, blockchainsWithTokenAutoImport}
}

type ImportTokenModalHeaderProps = {
  onClose: () => unknown
  label?: string
}

export function ImportTokenModalHeader({
  onClose,
  label,
}: ImportTokenModalHeaderProps) {
  const {t} = useTranslation()
  return (
    <ModalHeader onClose={onClose} hasDivider>
      <LabeledIcon
        Icon={<ImportIcon />}
        Label={
          <Typography variant="body1">{label || t('Import token')}</Typography>
        }
        iconPosition="start"
      />
    </ModalHeader>
  )
}

type ImportTokenModalFooterProps = {
  onClose: () => unknown
  onSubmit: () => unknown
  blockchain: TokenBlockchain
  accountId?: AccountId
}

export function ImportTokenModalFooter({
  onClose,
  onSubmit,
  blockchain,
  accountId,
}: ImportTokenModalFooterProps) {
  const {t} = useTranslation()
  const {blockchainsWithTokenAutoImport} = useTokenBlockchains()

  return (
    <ModalFooter hasDivider>
      <FooterLayout
        leftBtnConf={{
          onClick: onClose,
          children: t('Back'),
        }}
        rightBtnConf={{
          variant: 'contained',
          type: 'submit',
          children: !blockchainsWithTokenAutoImport.includes(blockchain)
            ? t('Continue')
            : t('Import'),
          onClick: onSubmit,
          disabled:
            blockchainsWithTokenAutoImport.includes(blockchain) || !accountId,
        }}
      />
    </ModalFooter>
  )
}

type TokenInfoProps = {
  token: TokenMetadata
  renderCommonRows?: boolean
  balance?: BigNumber
}

export function TokenInfo({token, balance}: TokenInfoProps) {
  const {blockchain} = token

  const TokenSpecificInfo = (() => {
    switch (blockchain) {
      case 'cardano':
        return (
          <>
            <BaseTokenInfo token={token} />
            <CardanoTokenDetailInfo token={token} nftInfo={null} />
          </>
        )
      case 'solana':
        return (
          <>
            <BaseTokenInfo token={token} />
            <SolanaTokenDetailInfo token={token} nftInfo={null} />
          </>
        )
      case 'flow':
        return (
          <>
            <BaseTokenInfo token={token} />
            <FlowTokenDetailInfo token={token} nftInfo={null} />
          </>
        )
      default: {
        if (isEvmBlockchain(blockchain)) {
          return <EvmImportTokenDetailInfo {...{blockchain, token, balance}} />
        }
        return safeAssertUnreachable(blockchain)
      }
    }
  })()

  return (
    <Grid container direction="column" spacing={1.5} mb={2}>
      {TokenSpecificInfo}
    </Grid>
  )
}

export const BaseTokenInfo = ({token}: Pick<TokenInfoProps, 'token'>) => {
  const {t} = useTranslation()

  return (
    <>
      <AboutRow label={t('Name')} value={token.name ?? t('N/A')} />
      <AboutRow
        label={t('Ticker')}
        value={token.ticker?.toUpperCase() ?? t('N/A')}
      />
    </>
  )
}
