import React, {useEffect, useState} from 'react'

//  Note that the `import type` is crucial to avoid dependency cycles
import type {
  UseGetArbitraryTokensRates,
  UseGetConversionRates,
} from 'src/features/conversionRates/application'
import type {
  ExchangeApi,
  ExchangeAssetsDetails,
} from 'src/features/exchange/domain'

import type {
  CheckProfileConflictFromMnemonic,
  ProfileSettings,
  VerifyPassword,
} from '../../appStorage'
import type {UseCurrentLoginInfo} from '../../store/auth'
import type {
  UseGetTokenMetadata,
  UseGetTokensMetadata,
  UseGetBalancesPerAccount,
  UseAccountTokens,
  UseGetAllNfts,
  UseGetAccounts,
} from '../../wallet'
import type {UseGetCatalystProposals} from '../../wallet/cardano'
import type {
  UseGetKnownTokenIds,
  UseGetTokenMetadata as UseGetEvmTokenMetadata,
} from '../../wallet/evm'
import type {GetFlowCurrentEpochInfo} from '../../wallet/flow'

// !!!
// Do not add new services! This is planned
// to be dropped in the near future.
export type MockServices = {
  // functions
  loadSettings: () => ProfileSettings
  verifyPassword: VerifyPassword
  checkProfileConflictFromMnemonic?: CheckProfileConflictFromMnemonic
  getExchangeAssetsDetails?: () => Promise<ExchangeAssetsDetails>
  getExchangeParams?: ExchangeApi['getExchangeParams']
  getExchangeConditions?: ExchangeApi['getExchangeConditions']
  // hooks
  useGetTokenMetadata: UseGetTokenMetadata
  useGetTokensMetadata: UseGetTokensMetadata
  useGetConversionRates: UseGetConversionRates
  useGetArbitraryTokensRates: UseGetArbitraryTokensRates
  useGetAccounts: UseGetAccounts
  useCurrentLoginInfo: UseCurrentLoginInfo
  useDappConnectorOriginInfo: () => {origin: string; faviconUrl: string}
  useGetBalancesPerAccount?: UseGetBalancesPerAccount
  useAccountTokens: UseAccountTokens
  useGetAllNfts: UseGetAllNfts
  // blockchain specific
  cardano: {
    TX_PLAN_DEBOUNCE_TIMEOUT_IN_MS: number
    useGetCatalystProposals: UseGetCatalystProposals
  }
  evm: {
    useGetKnownTokenIds: UseGetKnownTokenIds
    useGetTokenMetadata: UseGetEvmTokenMetadata
  }
  flow: {
    getCurrentEpochInfo: GetFlowCurrentEpochInfo
  }
}

// Only keep there cross-app services.
// Note that this is expected to be temporary, and will be probably fully removed
// together with MockServices in the future
export type MinimalMockServices = {
  loadSettings: () => ProfileSettings
}

let mocksServices: MockServices | null = null
let minimalMocksServices: MinimalMockServices | null = null

/**
This introduces a so called "service locator pattern" to inject deep
dependencies when testing react components.
Normally this could be achieved using `jest.mock` functions, however,
that is not possible when testing components in storybook (only the mock at webpack
level is, but that has to be global).

The "service locator pattern" is in general consider an anti-pattern and pure on IoC
dependency injection solutions are preferred, however, there exist nothing like this
(or at least is not well adopted) for React. In React dependency injection is easy
for one/two levels, otherwise one faces deep prop-drilling, or one uses Context, which does
basically "service locator pattern". We wanted to avoid context, as it can only be accessed
in React scope, and we absolutely do not need reactivity for these mocks when testing.

The reason for these mocks is to have ability to test complex screens in storybook, with deep components
that can have various app-level side-effects.
Testing in jest with RTL would be possible, but is difficult to write and debug for complex screen
components, due to lack of visual feedback.
*/
export const getMockServices = (): MockServices | null => mocksServices

export const getMinimalMockServices = (): MinimalMockServices | null =>
  minimalMocksServices

type RegisterMockServicesProps = {
  children: React.ReactElement
  services: MockServices | null
  minimalServices: MinimalMockServices | null
}

export function RegisterMockServices({
  children,
  services,
  minimalServices,
}: RegisterMockServicesProps) {
  const [registered, setRegistered] = useState(false)

  useEffect(() => {
    if (services != null) {
      mocksServices = services
    }
    if (minimalServices != null) {
      minimalMocksServices = minimalServices
    }
    setRegistered(true)
    // We do not expect services to change over the lifetime of component
  }, [])

  return registered ? children : <div />
}
