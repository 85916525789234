import {useQuery} from '@tanstack/react-query'

import {secondsToMilliseconds} from 'src/wallet/utils/common'

import {swapsApi} from './swapsApi'

const P = 'swaps'

export const queryKeys = {
  getSwaps: [P, 'getSwaps'],
  getSwap: (id: string) => [P, 'getSwap', id],
}

export function useGetSwaps() {
  return useQuery({
    queryKey: queryKeys.getSwaps,
    queryFn: swapsApi.getSwaps,
  })
}

export function useGetSwap(swapId: string) {
  return useQuery({
    queryKey: queryKeys.getSwap(swapId),
    queryFn: () => swapsApi.getSwap(swapId),
    refetchInterval: secondsToMilliseconds(10),
  })
}
