import {Launch as ExternalIcon} from '@mui/icons-material'
import React from 'react'

import {useCommonStyles} from './common'

type InternalLinkProps = {
  onClick: () => unknown
  title: string
  icon?: boolean
}

export function InternalLink({onClick, title, icon = true}: InternalLinkProps) {
  const classes = useCommonStyles()
  return (
    <div onClick={onClick} className={classes.link}>
      {title}
      {icon && <ExternalIcon color="primary" className={classes.icon} />}
    </div>
  )
}
