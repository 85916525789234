import React from 'react'

import {QueryGuard, StakingModalError} from '../../../../../components'
import type {AccountId} from '../../../../../types'
import {useGetAccounts} from '../../../../../wallet/flow'
import {WithActiveScreenState} from '../../../../transaction'

import {SetupStakingCollectionForm} from './ModalForm'

export default function FlowSetupStakingCollectionModal({
  onClose,
  accountId,
}: {
  onClose: () => unknown
  accountId: AccountId
}) {
  const accountsQuery = useGetAccounts()
  return (
    <WithActiveScreenState initialScreen="summary">
      <QueryGuard
        {...accountsQuery}
        ErrorElement={<StakingModalError blockchain="flow" />}
        loadingVariant="centered"
      >
        {(accounts) => (
          <SetupStakingCollectionForm {...{onClose, accountId, accounts}} />
        )}
      </QueryGuard>
    </WithActiveScreenState>
  )
}
