import {Box, Divider, Typography} from '@mui/material'
import type Bignumber from 'bignumber.js'
import type {FormikProps} from 'formik'
import React from 'react'
import {useTranslation} from 'react-i18next'

import type {StakingBlockchain} from '../../../../../blockchainTypes'
import {
  Alert,
  AmountRow,
  CurrentPasswordField,
  FooterLayout,
  ModalFooter,
  ModalLayout,
  TotalRow,
  useModalSharedStyles,
} from '../../../../../components'
import {useIsPasswordVerificationRequired} from '../../../../../store/auth'
import type {AccountId, AccountInfo} from '../../../../../types'

type Schema = {
  accountId: AccountId
  password: string
}

type SummaryScreenProps<T extends Schema> = {
  blockchain: StakingBlockchain
  ModalHeader: React.ReactNode
  onBack: () => unknown
  onSubmit: () => unknown
  summary: {
    fee: Bignumber
    fromAccount: AccountInfo
  }
  formikProps: FormikProps<T>
  error: React.ReactNode | undefined
}

const FORM_ID = 'setup-staking-collection-summary-form'

export default function SummaryScreen<T extends Schema>({
  onBack,
  onSubmit,
  blockchain,
  summary,
  formikProps,
  ModalHeader,
  error,
}: SummaryScreenProps<T>) {
  const {t} = useTranslation()
  const classes = useModalSharedStyles()
  const isPasswordVerificationRequired = useIsPasswordVerificationRequired(
    summary.fromAccount,
  )

  return (
    <form
      onSubmit={onSubmit}
      noValidate
      id={FORM_ID}
      className={classes.formWrapper}
    >
      <ModalLayout
        header={ModalHeader}
        body={
          <Box p={2}>
            <Box className={classes.commonBottomMargin}>
              <Alert
                text={t('FlowSetupStakingCollection.alert')}
                severity="info"
              />
            </Box>
            <Box className={classes.commonTopMargin}>
              <AmountRow
                label={t('Transaction fee')}
                amount={summary.fee}
                blockchain={blockchain}
              />
              <Divider className={classes.divider} />
              <TotalRow
                nativeAmount={summary.fee}
                blockchain={blockchain}
                totalText={t('Total')}
              />
            </Box>
            <Typography color="error">{error}</Typography>
          </Box>
        }
        footer={
          <ModalFooter hasDivider>
            {isPasswordVerificationRequired && (
              <CurrentPasswordField
                {...{formikProps}}
                className={classes.passwordHeight}
                disabled={
                  Object.keys(formikProps.errors).filter(
                    (errorKey) => errorKey !== 'password',
                  ).length > 0
                }
              />
            )}
            <FooterLayout
              leftBtnConf={{
                onClick: onBack,
                children: t('Back'),
              }}
              rightBtnConf={{
                children: t('Submit'),
                type: 'submit',
                form: FORM_ID,
                disabled: formikProps.isSubmitting || !formikProps.isValid,
              }}
            />
          </ModalFooter>
        }
      />
    </form>
  )
}
