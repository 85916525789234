import {Typography, Box} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import type {LocalProfileId} from 'src/appStorage'
import {formatWeb3AuthProfileName} from 'src/components/formatting'
import type {Web3AuthUserInfo} from 'src/features/login/domain'
import {createWeb3AuthPassword} from 'src/features/login/services'

import {
  Web3AuthProviderIcon,
  MultipleMutationsGuard,
  MutationGuard,
  Web3AuthMigrationInfo,
} from '../../components'
import type {Web3AuthLoginProvider} from '../../features/login'
import {
  useAvailableExternalLoginProviders,
  useCreateAndInitWeb3AuthProfile,
  useLoginToProfile,
  useWeb3AuthLogin,
} from '../../features/login'
import {trackProfileAction} from '../../tracking'
import type {Blockchain, Mnemonic} from '../../types'

import {ExternalProviderButton} from './common'
import {
  ExternalProviderLogin,
  LoginErrorModal,
  AuthStepsLoader,
} from './ExternalProviderLogin'

type Web3AuthLoginBaseProps = {
  onLogin: (values: {loginProvider: Web3AuthLoginProvider}) => Promise<void>
  hasOtherLoginOptions?: boolean
}

export function Web3AuthLoginBase({onLogin}: Web3AuthLoginBaseProps) {
  const {web3Auth} = useAvailableExternalLoginProviders()
  return web3Auth.map((provider) => (
    <ExternalProviderButton
      title={provider}
      key={provider}
      onClick={() => onLogin({loginProvider: provider})}
      startIcon={<Web3AuthProviderIcon provider={provider} />}
    >
      {provider}
    </ExternalProviderButton>
  ))
}

type Web3AuthLoginFlowProps = {
  onLogIntoExistingProfile: (args: {
    profileMnemonic: Mnemonic
    web3AuthUserInfo: Web3AuthUserInfo
    profileId: LocalProfileId
  }) => Promise<void>
  onLogIntoNewProfile: (args: {
    profileMnemonic: Mnemonic
    web3AuthUserInfo: Web3AuthUserInfo
    blockchains: Blockchain[]
  }) => Promise<void>
  preselectedBlockchains?: Blockchain[]
  hasOtherLoginOptions?: boolean
}

export function Web3AuthLoginFlow({
  onLogIntoExistingProfile,
  onLogIntoNewProfile,
  preselectedBlockchains,
  hasOtherLoginOptions = true,
}: Web3AuthLoginFlowProps) {
  const web3Login = useWeb3AuthLogin()
  const {t} = useTranslation()
  const providerLabel =
    web3Login?.data?.web3AuthUserInfo?.typeOfLogin || 'Web3Auth'

  return (
    <ExternalProviderLogin
      {...{
        preselectedBlockchains,
        providerLabel,
      }}
    >
      {({onExternalProfileLoad, setInfoScreen}) => {
        return (
          <>
            <Web3AuthLoginBase
              onLogin={async (values) => {
                try {
                  const {isProfileBackedUp, ...web3LoginResult} =
                    await web3Login.mutateAsync({
                      ...values,
                      sessionTime: 0,
                      onConfirmV2Migration: ({
                        onConfirm,
                        typeOfLogin,
                        email,
                        name,
                      }) => {
                        setInfoScreen({
                          onSubmit: onConfirm,
                          submitContent: t('I understand'),
                          infoContent: (
                            <Box p={2}>
                              <Web3AuthMigrationInfo
                                loginProvider={typeOfLogin}
                                userName={formatWeb3AuthProfileName({
                                  typeOfLogin,
                                  email,
                                  name,
                                })}
                              />
                            </Box>
                          ),
                          title: (
                            <Typography variant="h6">
                              {t('Re-login required')}
                            </Typography>
                          ),
                        })
                      },
                    })
                  const localProfileId =
                    web3LoginResult.localProfileMeta?.localProfileId

                  onExternalProfileLoad({
                    isProfileBackedUp,
                    ...(localProfileId
                      ? {
                          onLogIntoExistingProfile: () =>
                            onLogIntoExistingProfile({
                              ...web3LoginResult,
                              profileId: localProfileId,
                            }),
                        }
                      : {
                          onLogIntoNewProfile: (blockchains) =>
                            onLogIntoNewProfile({
                              ...web3LoginResult,
                              blockchains,
                            }),
                        }),
                  })
                } catch {
                  // errors are handled by mutation guards
                }
              }}
              hasOtherLoginOptions={hasOtherLoginOptions}
            />
            <MutationGuard
              {...web3Login}
              ErrorElement={
                <LoginErrorModal providerLabel={t('social_account')} />
              }
              LoadingElement={<AuthStepsLoader />}
            />
          </>
        )
      }}
    </ExternalProviderLogin>
  )
}

export function Web3AuthLoginApp() {
  const profileLogin = useLoginToProfile()
  const initProfile = useCreateAndInitWeb3AuthProfile()
  const {t} = useTranslation()

  const onLogIntoExistingProfile = async ({
    profileMnemonic,
    web3AuthUserInfo,
    profileId,
  }: {
    profileId: LocalProfileId
    profileMnemonic: Mnemonic
    web3AuthUserInfo: Web3AuthUserInfo
  }) => {
    await profileLogin.onLogin({
      profileId,
      password: createWeb3AuthPassword(profileMnemonic),
      web3AuthUserInfo,
    })
    trackProfileAction('login')
  }

  const onLogIntoNewProfile = async ({
    profileMnemonic,
    web3AuthUserInfo,
    blockchains,
  }: {
    profileMnemonic: Mnemonic
    web3AuthUserInfo: Web3AuthUserInfo
    blockchains: Blockchain[]
  }) => {
    const newProfile = await initProfile.mutateAsync({
      web3AuthUserInfo,
      profileMnemonic,
      blockchains,
    })
    onLogIntoExistingProfile({
      profileId: newProfile.localProfileId,
      profileMnemonic,
      web3AuthUserInfo,
    })
  }

  return (
    <>
      <MultipleMutationsGuard
        mutations={{
          profileLogin,
          initProfile,
        }}
        ErrorElement={<LoginErrorModal providerLabel={t('social_account')} />}
        LoadingElement={<AuthStepsLoader />}
      />
      <Web3AuthLoginFlow
        onLogIntoNewProfile={onLogIntoNewProfile}
        onLogIntoExistingProfile={onLogIntoExistingProfile}
      />
    </>
  )
}
