import type {Lamports, SolanaAccountInfo} from '@nufi/wallet-solana'
import {useTranslation} from 'react-i18next'
import * as yup from 'yup'

import type {AccountId} from '../../../types'
import {validateAssetDecimalsCount} from '../../../utils/form'
import type {BaseDetailsValues} from '../common/schema'

type BaseDetailsSchemaArgs = {
  decimals?: number
}

const _useBaseDetailsSchema = ({decimals}: BaseDetailsSchemaArgs) => {
  const {t} = useTranslation()

  return {
    amount: yup
      .number()
      .typeError(t('Invalid number'))
      .positive(t('Amount must be a positive number.'))
      .required(t('Amount is required.'))
      .test(
        'too-many-decimals',
        t('too_many_decimals_for_asset', {decimals}),
        (amount, context) => {
          if (amount === undefined || decimals === undefined) return true
          // passing original value because JS numbers don't preserve original notation
          // originalValue not exposed in yup TS types, see open issue in yup::
          // https://github.com/jquense/yup/issues/1591
          return validateAssetDecimalsCount(
            (context as unknown as {originalValue: string}).originalValue,
            decimals,
          )
        },
      ),
    accountId: yup.string(),
  }
}

// ensure consistency of keys / values on TS level
export const useBaseDetailsSchema: (
  ...args: Parameters<typeof _useBaseDetailsSchema>
) => {
  [k in keyof BaseDetailsValues]: ReturnType<typeof _useBaseDetailsSchema>[k]
} = _useBaseDetailsSchema

type ValidatorValues = {
  validatorId: string
}

const _useValidatorSchema = () => {
  const {t} = useTranslation()

  return {
    validatorId: yup
      .string()
      .typeError(t('Invalid string'))
      .required(t('Validator is required.')),
  }
}

// ensure consistency of keys / values on TS level
export const useValidatorSchema: (
  ...args: Parameters<typeof _useValidatorSchema>
) => {
  [k in keyof ValidatorValues]: ReturnType<typeof _useValidatorSchema>[k]
} = _useValidatorSchema

type FeePayerSchema = {
  hasSufficientFeeFunds: boolean
}

export const useStakeFeePayerSchema = ({
  account,
  txFee,
}: {
  account: SolanaAccountInfo
  txFee: Lamports
}) => {
  const {t} = useTranslation()

  return {
    hasSufficientFeeFunds: yup.bool().test({
      name: 'fee-not-more-than-fee-payer-balance',
      test() {
        const hasEnoughFunds = account.balance.isGreaterThanOrEqualTo(txFee)
        return (
          hasEnoughFunds ||
          // dynamic error message (propagates account name)
          this.createError({
            message: t('insufficient_fee_payer_funds', {
              accountName: account.name,
            }),
          })
        )
      },
    }),
  }
}

type ActivateBaseDetailsValues = {
  validatorId: string
  accountId: AccountId
}

type ActivateSummaryDetailsValues = {
  password: string
}

export type BaseActivateStakeSchema = ActivateBaseDetailsValues &
  ActivateSummaryDetailsValues &
  FeePayerSchema

type UndelegateSummaryValues = {
  accountId: AccountId
  password: string
}

export type BaseUndelegateStakeSchema = UndelegateSummaryValues & FeePayerSchema

type WithdrawSummaryValues = {
  password: string
}

export type BaseWithdrawStakeSchema = BaseDetailsValues &
  WithdrawSummaryValues &
  FeePayerSchema

export type SetupStakeAccountSchema = BaseActivateStakeSchema & {
  amount: string
}
