import type {IdentitySecret} from '@nufi/wallet-common'
import {mnemonicToSeed} from 'bip39'

import type {Mnemonic} from '../types'

import type {IdentitySecretHash, MnemonicHash, ProfileMetadata} from './types'

export const getMnemonicHash = async (
  mnemonic: Mnemonic,
): Promise<MnemonicHash> => {
  const seed = await mnemonicToSeed(mnemonic)
  const hash = await crypto.subtle.digest('SHA-256', seed)
  return Buffer.from(hash).toString('hex') as MnemonicHash
}

export const getIdentitySecretHash = async (
  identitySecret: IdentitySecret,
): Promise<IdentitySecretHash> => {
  const buffer = Buffer.from(identitySecret, 'hex')
  const hash = await crypto.subtle.digest('SHA-256', buffer)
  return Buffer.from(hash).toString('hex') as IdentitySecretHash
}

export const filterVisibleProfiles = (profiles: ProfileMetadata[]) =>
  profiles.filter((p) => p.loginType === 'password')
