import {Box, Chip, Typography} from '@mui/material'
import type BigNumber from 'bignumber.js'
import React from 'react'
import {useTranslation} from 'react-i18next'

import type {Blockchain, TokenMetadata} from 'src/types'
import type {TokensDiff} from 'src/wallet/dappConnector/types'
import {WithTokenMetadata} from 'src/wallet/public/ui'

import {TxAccordionRow, RowSpaceBetweenWrapper} from './common'
import {
  AssetMovementRow,
  TokenAssetErrorElement,
  TokenAssetLoadingElement,
} from './common/AssetMovementRow'

export type TokenMovementProps = {
  tokensDiff: TokensDiff
  blockchain: Blockchain
  receivingTokensCount: number
  sendingTokensCount: number
  getTokenInfoItems: (tokenMetadata: TokenMetadata) => {
    value: string
    key: string
  }[]
  getExtraAssetContent?: (
    tokenMetadata: TokenMetadata,
    amount: BigNumber | null,
  ) => React.ReactNode
}

export const TokenMovementRow = ({
  tokensDiff,
  blockchain,
  receivingTokensCount,
  getTokenInfoItems,
  getExtraAssetContent,
  sendingTokensCount,
}: TokenMovementProps) => {
  const {t} = useTranslation()
  const isSingleTokenMovement = tokensDiff.length === 1
  return (
    <TxAccordionRow
      summary={
        <>
          {isSingleTokenMovement && tokensDiff[0] ? (
            <TokenAssetRow
              blockchain={blockchain}
              tokenId={tokensDiff[0].tokenId}
              amount={tokensDiff[0].amount}
              getTokenInfoItems={getTokenInfoItems}
              getExtraAssetContent={getExtraAssetContent}
            />
          ) : (
            <RowSpaceBetweenWrapper>
              <Typography variant="body2">{t('Tokens movement')}</Typography>
              <Box display="flex" gap={1}>
                {receivingTokensCount > 0 && (
                  <Chip
                    label={t('receiving_tokens', {count: receivingTokensCount})}
                    color="success"
                    variant="outlined"
                    size="small"
                  />
                )}
                {sendingTokensCount > 0 && (
                  <Chip
                    label={t('sending_tokens', {count: sendingTokensCount})}
                    color="error"
                    variant="outlined"
                    size="small"
                  />
                )}
              </Box>
            </RowSpaceBetweenWrapper>
          )}
        </>
      }
      details={
        !isSingleTokenMovement
          ? tokensDiff.map(({tokenId, amount}) => (
              <RowSpaceBetweenWrapper key={tokenId}>
                <TokenAssetRow
                  blockchain={blockchain}
                  tokenId={tokenId}
                  amount={amount}
                  getTokenInfoItems={getTokenInfoItems}
                  getExtraAssetContent={getExtraAssetContent}
                />
              </RowSpaceBetweenWrapper>
            ))
          : undefined
      }
      divideDetailsChildren={!isSingleTokenMovement}
    />
  )
}

const TokenAssetRow = ({
  tokenId,
  blockchain,
  amount,
  getExtraAssetContent,
  getTokenInfoItems,
}: Pick<
  TokenMovementProps,
  'blockchain' | 'getTokenInfoItems' | 'getExtraAssetContent'
> &
  TokensDiff[number]) => {
  return (
    <WithTokenMetadata
      key={tokenId}
      tokenId={tokenId}
      blockchain={blockchain}
      guardProps={{
        LoadingElement: <TokenAssetLoadingElement />,
        ErrorElement: <TokenAssetErrorElement tokenId={tokenId} />,
      }}
    >
      {(tokenMetadata) => (
        <AssetMovementRow
          type="token"
          amount={amount}
          blockchain={blockchain}
          tokenMetadata={tokenMetadata}
          tokenInfoItems={getTokenInfoItems(tokenMetadata)}
          extraAssetContent={getExtraAssetContent?.(tokenMetadata, amount)}
          withAmountSignPrefix
        />
      )}
    </WithTokenMetadata>
  )
}
