import {useCallback, useRef, useState} from 'react'

const DELAY_MS = 500

type DebouncedFieldProps<T> = {
  defaultValue: T
  onChange: (value: T) => void
  children:
    | ((value: T, onChange: (value: T) => void) => JSX.Element | null)
    | JSX.Element
  delay?: number
}

export function DebouncedField<T>({
  defaultValue,
  onChange,
  children,
  delay = DELAY_MS,
}: DebouncedFieldProps<T>) {
  const [value, _onChange] = useState(defaultValue)
  const timeout = useRef<ReturnType<typeof setTimeout>>()

  const debouncedOnChange = useCallback(
    (_value: T) => {
      _onChange(_value)
      if (timeout.current) clearTimeout(timeout.current)
      timeout.current = setTimeout(() => {
        onChange(_value)
      }, delay)
    },
    [timeout, _onChange, onChange],
  )

  if (children) {
    return typeof children === 'function'
      ? children(value, debouncedOnChange)
      : children
  }
  return null
}
