import {ExpandMore as ExpandMoreIcon} from '@mui/icons-material'
import {
  Accordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
  useTheme,
  styled,
} from '@mui/material'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Light as SyntaxHighlighter} from 'react-syntax-highlighter'
import {a11yDark} from 'react-syntax-highlighter/dist/cjs/styles/hljs'
import swift from 'react-syntax-highlighter/dist/esm/languages/hljs/swift'

import {CopyToClipboard} from '../../../../../../components/CopyToClipboard'

SyntaxHighlighter.registerLanguage('swift', swift)

export function TransactionScript({script}: {script: string}) {
  const {t} = useTranslation()
  const [expanded, setExpanded] = useState(false)
  const theme = useTheme()

  return (
    <Accordion
      expanded={expanded}
      onChange={() => setExpanded((prevExpanded) => !prevExpanded)}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Grid container justifyContent="space-between" gap={2}>
          <Typography variant="subtitle1">{t('Transaction script')}</Typography>
          {expanded && (
            <div onClick={(e) => e.stopPropagation()}>
              <CopyToClipboard
                variant="text"
                label={t('Copy script')}
                value={script}
              />
            </div>
          )}
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <SyntaxHighlighter
          language="swift" // Flow Reference Wallet uses swift, there is no cadence option
          style={a11yDark}
          showLineNumbers
          customStyle={{backgroundColor: theme.palette.background.default}}
        >
          {script}
        </SyntaxHighlighter>
      </AccordionDetails>
    </Accordion>
  )
}

const AccordionDetails = styled(MuiAccordionDetails)(({theme}) => ({
  padding: 0,
  margin: 0,
  border: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.background.default,
}))
