import type {Breakpoint} from '@mui/material'
import {useTheme} from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import React from 'react'

type HiddenProps = {
  children: React.ReactNode | React.ReactNode[]
  breakpoint: Breakpoint
}

export const HideBelowBreakpoint = ({children, breakpoint}: HiddenProps) => {
  const {breakpoints} = useTheme()

  // https://mui.com/material-ui/react-use-media-query/#client-side-only-rendering
  const isMatch = useMediaQuery(breakpoints.down(breakpoint), {
    noSsr: true,
  })
  return isMatch ? (
    <div style={{display: 'none'}}>{children}</div>
  ) : (
    <>{children}</>
  )
}
