import {request} from '@nufi/frontend-common'

import config from 'src/config'
import type {
  DappData,
  DappBlockchainInfo,
  GetDappsParams,
  DappDirectoryApi,
  DappCategory,
} from 'src/features/dappDirectory/domain'

import type {
  CategoryFilter,
  BlockchainFilter,
  Response,
  NameFilter,
  ApiDappData,
  DappCategoryInfo,
} from './types'
import {
  generateStrapiQueryString,
  createFilter,
  formatCmsMediaUrl,
} from './utils'

const transformDAppCategories = (
  dAppCategories: DappCategoryInfo[],
): DappCategory[] => dAppCategories.map(({name}) => name)

const strapiRequest = async <TData>(
  requestParams: Parameters<typeof request>[0],
): Promise<Response<TData>> => (await request(requestParams)) as Response<TData>

const prefixAssetSourceRelativeUrl = (
  dAppsData: Response<DappData[]>,
  cmsUrl: string,
) => {
  const {data} = dAppsData
  const prefixedData = data.map(({icon, ...rest}) => ({
    ...rest,
    icon: icon && {
      ...icon,
      url: formatCmsMediaUrl(icon?.url || '', cmsUrl),
    },
  }))
  return {...dAppsData, data: prefixedData}
}

export class NufiCMSDappDirectoryApi implements DappDirectoryApi {
  private cmsUrl
  private apiUrl

  constructor(cmsUrl: string) {
    this.cmsUrl = cmsUrl
    this.apiUrl = `${cmsUrl}/api`
  }

  getDapps = async ({
    category,
    blockchain,
    pagination,
    searchText,
  }: GetDappsParams) => {
    const filters = {
      ...createFilter<CategoryFilter>(
        ['dapp_categories', 'name'],
        '$eq',
        category,
      ),
      ...createFilter<BlockchainFilter>(
        ['blockchains', 'name'],
        '$eq',
        blockchain,
      ),
      ...createFilter<NameFilter>(['name'], '$containsi', searchText),
    }

    const query = generateStrapiQueryString<ApiDappData>(
      filters,
      ['dapp_categories', 'blockchains', 'icon'],
      // need to sort also by id to prevent duplicates across pages
      // https://github.com/strapi/strapi/issues/15953
      ['featured:desc', 'uaw:desc', 'id:desc'],
      pagination,
    )

    const {data: dappData, ...rest} = await strapiRequest<ApiDappData[]>({
      url: `${this.apiUrl}/dapps?${query}`,
      headers: {'Content-Type': 'application/json'},
    })

    const data = {
      ...rest,
      data: dappData.map(({dapp_categories: dAppCategories, ...dApp}) => ({
        ...dApp,
        dAppCategories: transformDAppCategories(dAppCategories),
      })),
    }

    return prefixAssetSourceRelativeUrl(data, this.cmsUrl)
  }

  getDappCategories = async () =>
    transformDAppCategories(
      (
        await strapiRequest<DappCategoryInfo[]>({
          url: `${this.apiUrl}/dapp-categories`,
          headers: {'Content-Type': 'application/json'},
        })
      ).data,
    )

  getDappBlockchains = async () =>
    (
      await strapiRequest<DappBlockchainInfo[]>({
        url: `${this.apiUrl}/blockchains`,
        headers: {'Content-Type': 'application/json'},
      })
    ).data
}

export const dappDirectoryApi: DappDirectoryApi = new NufiCMSDappDirectoryApi(
  config.cmsUrl,
)
