import {Tabs, Tab} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {
  useLocation,
  useHistory,
  Switch,
  Route,
  Redirect,
  matchPath,
} from 'react-router-dom'

import {routeTo} from '../../router'
import {useTabsStyles} from '../../utils/layoutUtils'
import {useHasActiveStaking} from '../../wallet'

import CardanoStakeDetail from './cardano/StakeDetail'
import {StakingLoading} from './common/Loadings'
import FlowStakeDetail from './flow/StakeDetail'
import MyStakingPage from './MyStaking'
import ProtocolsPage from './Protocols'
import SolanaStakeDetail from './solana/StakeDetail'
import {useStakingBlockchains} from './utils'
import WhatIsStakingPage from './WhatIsStaking'

function StakingTabs() {
  const {t} = useTranslation()
  const location = useLocation()
  const history = useHistory()
  const {commonTabStyles} = useTabsStyles()
  // TODO: consider caching this info, as queries are re-run every time when clicking through tabs
  // and this may get worse with more blockchains in the future
  const hasActiveStakingQuery = useHasActiveStaking()

  const value = (() => {
    if (matchPath(location.pathname, routeTo.staking.dashboard)) {
      return routeTo.staking.dashboard
    }
    if (matchPath(location.pathname, routeTo.staking.protocols)) {
      return routeTo.staking.protocols
    }
    if (matchPath(location.pathname, routeTo.staking.myStaking.index)) {
      return routeTo.staking.myStaking.index
    }
    if (matchPath(location.pathname, routeTo.staking.whatIsStaking)) {
      return routeTo.staking.whatIsStaking
    }
    return routeTo.staking.whatIsStaking
  })()

  const onChange = (event: React.ChangeEvent<unknown>, value: string) => {
    history.push(value)
  }

  const initialStakingRoute = hasActiveStakingQuery.data
    ? routeTo.staking.myStaking.index
    : routeTo.staking.whatIsStaking

  // We are not relying only on `isLoading` as `hasActiveLoadingQuery` sub-queries are
  // settings `isLoading` & are being called from multiple components what can cause
  // infinite re-render loop within this component based on `shouldShowLoading` value.
  // Therefore we also check for `data` || `error`
  const shouldShowLoading =
    hasActiveStakingQuery.isLoading &&
    !hasActiveStakingQuery.data &&
    !hasActiveStakingQuery.error

  return (
    <>
      {shouldShowLoading ? (
        <StakingLoading />
      ) : (
        <>
          <Tabs
            className={commonTabStyles}
            {...{value, onChange}}
            indicatorColor="primary"
            textColor="primary"
          >
            {/* <Tab value={routeTo.staking.dashboard} label={t('Dashboard')} /> */}
            <Tab
              value={routeTo.staking.myStaking.index}
              label={t('My Staking')}
            />
            <Tab
              value={routeTo.staking.protocols}
              label={t('Protocols Overview')}
            />
            <Tab
              value={routeTo.staking.whatIsStaking}
              label={t('What is Staking')}
            />
          </Tabs>

          <Switch>
            {/* <Route path={routeTo.staking.dashboard} component={Placeholder} /> */}
            <Route
              path={routeTo.staking.myStaking.index}
              component={MyStakingPage}
            />
            <Route path={routeTo.staking.protocols} component={ProtocolsPage} />
            <Route
              path={routeTo.staking.whatIsStaking}
              component={WhatIsStakingPage}
            />
            <Redirect path={routeTo.staking.index} to={initialStakingRoute} />
          </Switch>
        </>
      )}
    </>
  )
}

const stakeDetails = {
  solana: SolanaStakeDetail,
  cardano: CardanoStakeDetail,
  flow: FlowStakeDetail,
}

export default function StakingPage() {
  const supportedBlockchains = useStakingBlockchains()

  return (
    <Switch>
      {supportedBlockchains.map((blockchain) => (
        <Route
          key={blockchain}
          path={routeTo.staking.myStaking.blockchain(blockchain).index}
          component={stakeDetails[blockchain]}
        />
      ))}
      <Route component={StakingTabs} />
    </Switch>
  )
}
