import {Box, Link, Paper, Stack, Typography} from '@mui/material'
import type {
  SolanaOnChainAccountInfo,
  SolanaPubKey,
  SolanaTokenId,
  ParsedInstruction,
  ParsedInstructionInputFieldValue,
  EnhancedSolanaTxSimulationResult,
  TransactionSimulationReport,
} from '@nufi/wallet-solana'
import {parseAccounts, parseTransactionSimulation} from '@nufi/wallet-solana'
import {PublicKey} from '@solana/web3.js'
import type {AccountMeta} from '@solana/web3.js'
import BigNumber from 'bignumber.js'
import _ from 'lodash'
import React from 'react'
import {useTranslation} from 'react-i18next'

import type {TokenBlockchain} from '../../../../../blockchainTypes'
import {
  Alert,
  AssetIcon,
  BatchQueryGuard,
  CenteredError,
  FormattedAsset,
  FormattedTokenLabel,
  getSafeAddressExplorerLink,
  QueryGuard,
} from '../../../../../components'
import type {TokenId} from '../../../../../types'
import {assert} from '../../../../../utils/assertion'
import {useGetTokenMetadata} from '../../../../../wallet'
import type {ExternalReceiversInfo} from '../../../../../wallet/dappConnector/types'
import {useGetOnChainAccountsInfo} from '../../../../../wallet/solana'
import {useGetTransactionSimulation} from '../../../../../wallet/solana/public/queries/transaction'
import {useSignContext} from '../../SignContext'
import {ChipRow} from '../common/details/common/TxAccordionRow'
import {TxDetail} from '../common/details/TxDetail'
import {TxDataLayout} from '../common/TxDataLayout'
import type {BlockchainTxDataProps} from '../types'

const blockchain = 'solana'

export function SolanaTxData({
  data,
}: {
  data: BlockchainTxDataProps<typeof blockchain>
}) {
  const {t} = useTranslation()
  const {selectedAccount} = useSignContext()
  assert(selectedAccount?.blockchain === 'solana')
  const mainAccount = selectedAccount.publicKey

  const simulationQuery = useGetTransactionSimulation(data.tx)
  const accountsQuery = useGetOnChainAccountsInfo(parseAccounts(data.tx))

  return (
    <BatchQueryGuard
      queries={{
        simulationQuery,
        accountsQuery,
      }}
      ErrorElement={
        <CenteredError
          error={t('Unable to display the transaction details.')}
        />
      }
      loadingVariant="centered"
    >
      {({
        simulationQuery: {accountPubKeys, simulationResult},
        accountsQuery: accountInfos,
      }) => {
        return (
          <_SolanaTxData
            data={data}
            mainAccount={mainAccount}
            accountPubKeys={accountPubKeys}
            simulationResult={simulationResult}
            accountInfos={accountInfos}
          />
        )
      }}
    </BatchQueryGuard>
  )
}

const getExternalReceiverInfo = (
  simulationAccount: TransactionSimulationReport['accounts'][number],
  mainAccount: TransactionSimulationReport['mainAccount'],
): ExternalReceiversInfo<SolanaTokenId> => {
  if (
    simulationAccount.publicKey.equals(mainAccount) ||
    simulationAccount.token?.accountOwnedByMainAccount
  )
    return {}
  if (
    (!simulationAccount.lamportsDiff ||
      simulationAccount.lamportsDiff.isZero()) &&
    (!simulationAccount.token?.amountDiff ||
      simulationAccount.token.amountDiff.isZero())
  )
    return {}
  return {
    [simulationAccount.publicKey.toBase58()]: {
      native: simulationAccount.lamportsDiff ?? new BigNumber(0),
      tokens: simulationAccount.token?.amountDiff
        ? {
            [simulationAccount.token.mint.toBase58()]:
              simulationAccount.token.amountDiff,
          }
        : {},
    },
  }
}

export type _SolanaTxDataProps = {
  data: BlockchainTxDataProps<typeof blockchain>
  mainAccount: SolanaPubKey
  accountPubKeys: SolanaPubKey[]
  simulationResult: EnhancedSolanaTxSimulationResult | null
  accountInfos: (SolanaOnChainAccountInfo | null)[]
}
export function _SolanaTxData({
  data: {rawTx, parsedInstructions, estimatedFee},
  mainAccount,
  accountPubKeys,
  simulationResult,
  accountInfos,
}: _SolanaTxDataProps) {
  const {t} = useTranslation()

  const simulation = simulationResult
    ? parseTransactionSimulation(mainAccount, accountPubKeys, {
        preInfos: accountInfos,
        simulationResult,
      })
    : null

  const simulationErrors = [
    (!simulationResult || simulationResult.value.err) &&
      t('solana_dapp_connector_simulation_error_other'),
    simulationResult?.blockhashNotFound &&
      t('solana_dapp_connector_simulation_error_blockhash_expired'),
    estimatedFee === null && t('solana_unable_to_estimate_fee'),
  ].filter((e): e is string => !!e)

  const externalReceiversInfo: ExternalReceiversInfo<SolanaTokenId> = simulation
    ? _.merge(
        {},
        ...simulation.accounts.map((a) =>
          getExternalReceiverInfo(a, simulation.mainAccount),
        ),
      )
    : {}

  const tokensDiff = simulation
    ? _.toPairs(simulation.tokenDiffsOwnedByMainAccount)
        .map(([tokenId, amount]) => ({
          tokenId: tokenId as SolanaTokenId,
          amount,
        }))
        .filter(({amount}) => !amount?.isZero())
    : []

  const receivingTokensCount = simulation
    ? _.values(simulation.tokenDiffsOwnedByMainAccount).filter((v) =>
        v?.isGreaterThan(0),
      ).length
    : 0
  const sendingTokensCount = simulation
    ? _.values(simulation.tokenDiffsOwnedByMainAccount).filter((v) =>
        v?.isLessThan(0),
      ).length
    : 0

  return (
    <Stack spacing={2}>
      {simulationErrors.map((error, i) => (
        <Alert key={i} severity="error">
          {error}
        </Alert>
      ))}
      <TxDataLayout
        details={
          <TxDetail
            nativeDiff={simulation?.lamportsDiffMainAccount ?? null}
            tokensMovement={{
              sendingTokensCount,
              receivingTokensCount,
              tokensDiff,
              getTokenInfoItems: (meta) => {
                assert(meta.blockchain === blockchain)

                return [{key: 'Id', value: meta.mint}]
              },
            }}
            externalReceiversInfo={externalReceiversInfo}
            fee={estimatedFee}
            blockchain={blockchain}
            customBottomRows={[
              {
                summary: (
                  <ChipRow
                    label={t('Instructions')}
                    chipContent={parsedInstructions.length}
                  />
                ),
                details: (
                  <>
                    {parsedInstructions.map((i, key) => (
                      <Box key={key}>
                        <Paper variant="outlined">
                          <Box padding={1}>
                            <TxInstruction instruction={i} />
                          </Box>
                        </Paper>
                      </Box>
                    ))}
                  </>
                ),
              },
              {
                summary: (
                  <ChipRow
                    label={t('Simulation slot')}
                    chipContent={simulation?.context.slot ?? t('N/A')}
                  />
                ),
              },
            ]}
          />
        }
        rawTx={Buffer.from(rawTx).toString('hex')}
      />
    </Stack>
  )
}

function TxInstruction({instruction}: {instruction: ParsedInstruction}) {
  const {t} = useTranslation()

  // Note to future maintainers: don't let this get too complex. A few more
  // cases are fine, but if you find yourself adding too many, it's probably a
  // sign to generalize over the cases in the parsing stage instead of dealing
  // with it during UI rendering.
  switch (instruction.program) {
    case 'system':
    case 'token': {
      const keys =
        instruction.program === 'system'
          ? Object.fromEntries(instruction.keys.map((v, i) => [`key_${i}`, v]))
          : instruction.keys
      return (
        <>
          <Typography variant="subtitle2">
            {instruction.instructionName} (
            {t(`solana_program_${instruction.program}`)})
          </Typography>
          <KeyValueList data={keys} />
          <KeyValueList data={instruction.input} />
        </>
      )
    }
    default:
      return (
        <>
          <Typography variant="subtitle2">
            {t('solana_program_other')}
          </Typography>
          <KeyValueList
            data={{
              programId: instruction.programId,
            }}
          />
        </>
      )
  }
}

type ValueType =
  | ParsedInstructionInputFieldValue
  | PublicKey
  | AccountMeta
  | AccountMeta[]
function KeyValueList({data}: {data: Record<string, ValueType>}) {
  // This renders the value, and possibly determines an alias for the key.
  const renderKeyValue = (
    k: string,
    v: ValueType,
  ): string | React.ReactElement | [string, React.ReactElement] | null => {
    if (k === 'lamports') {
      assert(typeof v === 'number' || typeof v === 'bigint')
      return [
        'sol',
        <Typography key={0} variant="body2">
          <FormattedAsset
            amount={new BigNumber(v.toString())}
            blockchain="solana"
            includeAssetSymbol
            isSensitiveInformation={false}
          />
        </Typography>,
      ]
    }
    if (v instanceof PublicKey) {
      return <KeyValueListAddressExplorerLink address={v.toBase58()} />
    }
    if (!Array.isArray(v) && typeof v === 'object') {
      const accountMeta = v as AccountMeta
      const accountId = accountMeta.pubkey.toBase58()
      if (k === 'mint') {
        return (
          <Link
            href={getSafeAddressExplorerLink(accountId, 'solana')}
            target="_blank"
          >
            <TokenOneLineDisplay
              blockchain="solana"
              tokenId={accountId as TokenId}
            />
          </Link>
        )
      }
      return <KeyValueListAddressExplorerLink address={accountId} />
    }
    if (
      typeof v === 'number' ||
      typeof v === 'bigint' ||
      typeof v === 'string'
    ) {
      return v.toString()
    }

    // TODO: this ignores some properties, `multiSigners` for instance.
    return null
  }

  // This renders the key-value pair
  const renderKeyValueRow = (
    k: string,
    v: ValueType,
  ): React.ReactNode | null => {
    const renderLayout = (k: string, v: string | React.ReactElement) => (
      <Box display="flex" justifyContent="space-between" alignItems="baseline">
        <Box marginRight={2}>
          <Typography variant="caption" fontFamily="monospace">
            {k}
          </Typography>
        </Box>
        {typeof v === 'string' ? (
          <Typography
            variant="subtitle2"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {display}
          </Typography>
        ) : (
          v
        )}
      </Box>
    )
    const display = renderKeyValue(k, v)
    if (display === null) return null
    if (typeof display === 'string') {
      return renderLayout(k, display)
    }
    if (Array.isArray(display)) {
      const [k, v] = display
      return renderLayout(k as string, v)
    }
    return renderLayout(k, display)
  }

  return (
    <Box>
      {Object.entries(data).map(([k, v]: [string, ValueType], i: number) => {
        const row = renderKeyValueRow(k, v)
        return row && <Box key={i}>{row}</Box>
      })}
    </Box>
  )
}

const KeyValueListAddressExplorerLink = ({address}: {address: string}) => (
  <Link
    href={getSafeAddressExplorerLink(address, 'solana')}
    target="_blank"
    variant="subtitle2"
    overflow="hidden"
    textOverflow="ellipsis"
  >
    {address}
  </Link>
)

// TODO: Integrate this functionality into the common token display components.
function TokenOneLineDisplay({
  blockchain,
  tokenId,
}: {
  blockchain: TokenBlockchain
  tokenId: TokenId
}) {
  const tokenMetaQuery = useGetTokenMetadata(tokenId, blockchain)
  return (
    <QueryGuard {...tokenMetaQuery}>
      {(meta) => (
        <Box display="flex" alignItems="center">
          <AssetIcon
            exactSize={24}
            blockchain={blockchain}
            tokenMetadata={meta}
          />
          <Box pl={1}>
            <Typography variant="subtitle2" component="div">
              <FormattedTokenLabel tokenMetadata={meta} {...{blockchain}} />
            </Typography>
          </Box>
        </Box>
      )}
    </QueryGuard>
  )
}
