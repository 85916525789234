import type {Blockchain, TokenId} from 'src/types'

import type {RampAsset} from '../../domain'

import type {
  MoonpayCryptoCurrency,
  MoonpayFiatCurrency,
  MoonpayCurrency,
  MoonpaySupportedCoinCode,
} from './constants'
import {
  MOONPAY_COIN_TO_BLOCKCHAIN,
  MOONPAY_NETWORK_CODE_TO_BLOCKCHAIN,
  MOONPAY_CURRENCY_CODE_TO_CURRENCY,
  MOONPAY_SUPPORTED_COINS,
} from './constants'

export type GetTokenId = (
  args:
    | {
        blockchain: Exclude<Blockchain, 'flow'>
        contractAddress: string
      }
    | {
        blockchain: Extract<Blockchain, 'flow'>
        contractAddress: string
        contractName: string
      },
) => Promise<TokenId | null>

export const isMoonpaySupportedCoinCode = (
  code: string,
): code is MoonpaySupportedCoinCode => {
  const coins: readonly string[] = MOONPAY_SUPPORTED_COINS
  return coins.includes(code)
}

export const transformCryptoCurrency = async (
  currency: MoonpayCryptoCurrency,
  isTestnet: boolean,
  getTokenId: GetTokenId,
): Promise<RampAsset | null> => {
  const addressRegex = isTestnet
    ? currency.testnetAddressRegex
    : currency.addressRegex

  if (isMoonpaySupportedCoinCode(currency.code)) {
    return {
      type: 'crypto',
      rampId: currency.code,
      blockchain: MOONPAY_COIN_TO_BLOCKCHAIN[currency.code]!,
      supportsSell: currency.isSellSupported,
      supportsSandbox: currency.supportsTestMode,
      name: currency.name,
      // Moonpay includes contract addresses for native assets, specifically:
      // 0x0000000000000000000000000000000000000000 for ether
      // 0x0000000000000000000000000000000000001010 for matic
      // So we simply ignore them
      tokenId: null,
      addressRegex,
    }
  }

  // Note that this does not check for `currency.metadata.chainId`, as moonpay also
  // exposes 'supportsSandbox' property, which can be used later instead.
  // https://dev.moonpay.com/reference/getcurrencies
  const blockchain =
    MOONPAY_NETWORK_CODE_TO_BLOCKCHAIN[currency.metadata.networkCode]

  const tokenId = await (async () => {
    if (
      blockchain &&
      blockchain !== 'flow' &&
      currency.metadata.contractAddress != null
    ) {
      return await getTokenId({
        blockchain,
        contractAddress: currency.metadata.contractAddress,
      })
    }

    // Moonpay is not sending proper information for flow tokens, thus we are explicitly
    // whitelisting some tokens that it supports.
    if (blockchain === 'flow' && currency.code === 'usdc_flow') {
      return await getTokenId({
        blockchain,
        contractAddress: '0xb19436aae4d94622',
        contractName: 'FiatToken',
      })
    }
    return null
  })()

  if (blockchain != null && tokenId != null) {
    return {
      type: 'crypto',
      rampId: currency.code,
      blockchain,
      supportsSell: currency.isSellSupported,
      supportsSandbox: currency.supportsTestMode,
      name: currency.name,
      tokenId,
      addressRegex,
    }
  }

  return null
}

export const transformFiatCurrency = (
  moonpayCurrency: MoonpayFiatCurrency,
): RampAsset | null => {
  const currency = MOONPAY_CURRENCY_CODE_TO_CURRENCY[moonpayCurrency.code]

  if (currency != null) {
    return {
      type: 'fiat',
      rampId: moonpayCurrency.code,
      currency,
      supportsSell: moonpayCurrency.isSellSupported,
      supportsSandbox:
        // Fiat currencies aren't supposed to have a supportsTestMode field, but just in case:
        (moonpayCurrency as MoonpayCurrency as MoonpayCryptoCurrency)
          .supportsTestMode !== false,
      name: moonpayCurrency.name,
    }
  }

  return null
}
