import {Box, Typography} from '@mui/material'
import type BigNumber from 'bignumber.js'
import React from 'react'

import {WithConversionRates} from 'src/features/conversionRates/ui'
import type {Blockchain, TokenId} from 'src/types'
import {WithWeakTokenMetadata} from 'src/wallet/public/ui'

import {FormattedAsset, FormattedAssetAsCurrency} from './formatting'

export const TxHistoryEffect = ({
  effect,
}: {
  effect: {
    tokenId?: TokenId
    amount: BigNumber
    blockchain: Blockchain
  }
}) => {
  const {tokenId, amount, blockchain} = effect

  return (
    <Box textAlign="end">
      <Typography
        variant="body2"
        sx={{
          color: ({palette}) =>
            amount.isNegative() ? palette.error.light : palette.success.light,
        }}
      >
        <WithWeakTokenMetadata blockchain={blockchain} tokenId={tokenId}>
          {(tokenMetadata) => (
            <FormattedAsset
              amount={amount.abs()}
              blockchain={blockchain}
              tokenMetadata={tokenMetadata}
              isSensitiveInformation
              prefix={amount.isNegative() ? '- ' : '+ '}
            />
          )}
        </WithWeakTokenMetadata>
      </Typography>
      {
        <Typography variant="body2" color="textSecondary">
          <WithWeakTokenMetadata blockchain={blockchain} tokenId={tokenId}>
            {(tokenMetadata) => (
              <WithConversionRates>
                {(conversionRates) => (
                  <FormattedAssetAsCurrency
                    blockchain={blockchain}
                    balance={amount.abs()}
                    isSensitiveInformation
                    tokenMetadata={tokenMetadata}
                    conversionRates={conversionRates}
                  />
                )}
              </WithConversionRates>
            )}
          </WithWeakTokenMetadata>
        </Typography>
      }
    </Box>
  )
}
