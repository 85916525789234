import type {PrefixedHexString} from '@nufi/wallet-common'
import * as yup from 'yup'

export function assertShape<T extends yup.AnySchema>(
  shape: T,
  value: unknown,
): asserts value is yup.Asserts<T> {
  shape.validateSync(value)
}

export const prefixedHex = () =>
  yup.string().matches(/^0x[a-fA-F0-9]+$/) satisfies yup.StringSchema<
    string | undefined
  > as yup.StringSchema<PrefixedHexString | undefined>
