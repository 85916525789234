import {HelpOutline} from '@mui/icons-material'
import {Box, Grid} from '@mui/material'
import type {FlowAccountInfo} from '@nufi/wallet-flow'
import type BigNumber from 'bignumber.js'
import React, {useState, useRef} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'

import {useGetConversionRates} from 'src/features/conversionRates/application'
import type {ConversionRates} from 'src/features/conversionRates/domain'
import {blockchainToWalletKind} from 'src/wallet/walletKind'

import {
  AccordionCard,
  FormattedAssetPair,
  ListHeaderTitle,
  AccountCardCol,
  LabeledIconWithTooltip,
} from '../../../../components'
import {routeTo} from '../../../../router'
import {
  getTotalStakingRewardsBalance,
  getTotalInStakingBalance,
  getTotalInactiveBalance,
} from '../../../../wallet/flow'
import {
  StakeParentCardLayout,
  useStakeParentAccountRowStyles,
  ShowStakingCardDetails,
  StakeAccountList,
} from '../../common/overview/StakeAccountListLayoutUtils'
import {
  ScrollableList,
  NewStakingButton,
} from '../../common/overview/StakeOverviewUtils'
import {getFlowStakeAccountId} from '../utils'

import StakeAccountCard from './StakeAccountCard'
import {StakeAccountListHeader} from './StakeAccountListLayout'

type FlowStakeAccountListProps = {
  accounts: FlowAccountInfo[]
}

export default function FlowStakeAccountList({
  accounts,
}: FlowStakeAccountListProps) {
  const {rates} = useGetConversionRates()
  return (
    <ScrollableList>
      {accounts.map((account) => (
        <FlowStakeAccountListItem
          key={account.id}
          account={account}
          conversionRates={rates}
        />
      ))}
    </ScrollableList>
  )
}

type FlowStakeAccountListItemProps = {
  account: FlowAccountInfo
  conversionRates: ConversionRates
}

function FlowStakeAccountListItem({
  account,
  conversionRates,
}: FlowStakeAccountListItemProps) {
  const actionRef = useRef<HTMLElement>(null)

  const accountRewardSum = getTotalStakingRewardsBalance(account.stakingInfo)
  const accountStakedSum = getTotalInStakingBalance(account.stakingInfo)
  const accountInactiveSum = getTotalInactiveBalance(account.stakingInfo)

  const [expanded, setExpanded] = useState(false)
  const onCardClick = (
    event: React.ChangeEvent<unknown>,
    isExpanded: boolean,
  ) => {
    account.stakingInfo?.isStakingCollectionRegistered &&
      !actionRef?.current?.contains(event.target as Node) &&
      setExpanded(isExpanded)
  }

  return (
    <AccordionCard
      elevation={expanded ? 1 : 0}
      expanded={expanded}
      onCardClick={onCardClick}
      mainContent={
        <StakeParentAccountRow
          account={account}
          rewardAmount={accountRewardSum}
          stakedAmount={accountStakedSum}
          inactiveAmount={accountInactiveSum}
          actionRef={actionRef}
          conversionRates={conversionRates}
        />
      }
      actions={
        <ShowStakingCardDetails
          expanded={expanded}
          length={
            account.stakingInfo?.isStakingCollectionRegistered
              ? account.stakingInfo.delegations.length
              : 0
          }
        />
      }
      details={
        <StakeAccountList
          Header={<StakeAccountListHeader />}
          AccountList={
            account.stakingInfo?.isStakingCollectionRegistered && (
              <>
                {account.stakingInfo.delegations.map((delegation) => (
                  <StakeAccountCard
                    delegationInfo={delegation}
                    accountId={account.id}
                    key={getFlowStakeAccountId(
                      delegation.nodeId,
                      delegation.id,
                    )}
                  />
                ))}
              </>
            )
          }
        />
      }
    />
  )
}

type StakeParentAccountRowProps = {
  account: FlowAccountInfo
  rewardAmount: BigNumber
  stakedAmount: BigNumber
  inactiveAmount: BigNumber
  actionRef: React.RefObject<HTMLElement>
  conversionRates: ConversionRates
}

export function StakeParentAccountRow({
  account,
  rewardAmount,
  stakedAmount,
  inactiveAmount,
  actionRef,
  conversionRates,
}: StakeParentAccountRowProps) {
  const classes = useStakeParentAccountRowStyles()
  const {t} = useTranslation()

  const history = useHistory()
  const openStakeModal = () => {
    if (account.stakingInfo.isStakingCollectionRegistered) {
      history.push(
        routeTo.staking.myStaking.flow.account(account.id).registerDelegator,
      )
    } else {
      history.push(
        routeTo.staking.myStaking.flow.account(account.id).setupStaking,
      )
    }
  }

  return (
    <>
      <Grid container className={classes.headerWrapper}>
        <StakeParentCardLayout
          accountCol={<ListHeaderTitle>{t('Account')}</ListHeaderTitle>}
          balanceCol={
            <ListHeaderTitle>{t('Available balance')}</ListHeaderTitle>
          }
          rewardsCol={<ListHeaderTitle>{t('Reward balance')}</ListHeaderTitle>}
          stakedCol={
            <ListHeaderTitle>
              {
                <LabeledIconWithTooltip
                  Label={t('In Staking')}
                  Icon={<HelpOutline fontSize="small" />}
                  title={t('Committed + Staked') as string}
                  spacing={0.5}
                />
              }
            </ListHeaderTitle>
          }
          inactiveCol={
            <ListHeaderTitle>
              {
                <LabeledIconWithTooltip
                  Label={t('Inactive')}
                  Icon={<HelpOutline fontSize="small" />}
                  title={t('Rewards + Unstaked') as string}
                  spacing={0.5}
                />
              }
            </ListHeaderTitle>
          }
        />
      </Grid>
      <StakeParentCardLayout
        accountCol={
          <AccountCardCol
            walletKind={blockchainToWalletKind(account.blockchain)}
            blockchain={account.blockchain}
            name={account.name}
            cryptoProviderType={account.cryptoProviderType}
          />
        }
        balanceCol={
          <FormattedAssetPair
            amount={account.balance}
            blockchain={account.blockchain}
            isSensitiveInformation
            conversionProps={{
              conversionRates,
            }}
          />
        }
        rewardsCol={
          <FormattedAssetPair
            amount={rewardAmount}
            blockchain={account.blockchain}
            isSensitiveInformation
            conversionProps={{
              conversionRates,
            }}
          />
        }
        stakedCol={
          <FormattedAssetPair
            amount={stakedAmount}
            blockchain={account.blockchain}
            isSensitiveInformation
            conversionProps={{
              conversionRates,
            }}
          />
        }
        inactiveCol={
          <FormattedAssetPair
            amount={inactiveAmount}
            blockchain={account.blockchain}
            isSensitiveInformation
            conversionProps={{
              conversionRates,
            }}
          />
        }
        actionCol={
          <Grid item container justifyContent="flex-end">
            <Box ref={actionRef}>
              <NewStakingButton
                openStakingModal={openStakeModal}
                blockchain={account.blockchain}
              />
            </Box>
          </Grid>
        }
      />
    </>
  )
}
