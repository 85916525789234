import {Box, Button} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import type {MessageToClient} from 'src/dappConnector/types'
import {
  getMoonpayBuyUrl,
  useSignMoonpayUrl,
} from 'src/features/ramp/application'

import type {MoonpayBlockchain} from '../../blockchainTypes'
import {moonpayBlockchains} from '../../blockchainTypes'
import {QueryGuard} from '../../components'
import {
  blockchainToCoin,
  getMoonpayBlockchainCode,
  IS_BUY_SELL_ENABLED,
} from '../../constants'
import {useDappConnectorStore} from '../../store/dappConnector'
import type {AccountOfflineInfo} from '../../types'
import {assert} from '../../utils/assertion'
import {isBlockchainSubset} from '../../utils/blockchainGuards'
import {useGetAccounts} from '../../wallet'
import type {EvmBlockchain} from '../../wallet/evm'
import {isEvmBlockchain, useGetKnownTokenIds} from '../../wallet/evm'
import {useGetAllNfts} from '../../wallet/solana'
import {ensureAccountById} from '../../wallet/utils/common'
import {ActionHeader, ChangeAccountHint} from '../components'

export function IdleScreen() {
  const {t} = useTranslation()
  const {
    selectedAccount,
    blockchain,
    setSelectedAccount: _setSelectedAccount,
    origin,
    favIconUrl,
  } = useDappConnectorStore()
  assert(selectedAccount != null, 'IdleScreen: no selected account')
  assert(origin != null, 'OriginInfo: origin not provided')

  const setSelectedAccount = (account: AccountOfflineInfo) => {
    _setSelectedAccount(account)
    if (isEvmBlockchain(blockchain)) {
      // Hotfix: Nullable operator used to avoid crashes in storybook
      chrome?.runtime?.sendMessage<MessageToClient>({
        senderContext: 'connectorWindow',
        targetContext: 'serviceWorker',
        targetOrigin: origin!,
        type: 'event',
        connectorKind: 'evm',
        method: 'accountChanged',
        args: [{accountId: account.id}],
      })
    }
  }

  return (
    <>
      <PrefetchData />
      <ActionHeader
        message={t('Connected')}
        mode="account-change-possible"
        {...{selectedAccount, setSelectedAccount, origin, favIconUrl}}
      />
      {!isEvmBlockchain(blockchain) && (
        <>
          <Box mt={4} />
          <ChangeAccountHint variant="after-init" origin={origin} />
        </>
      )}
      {IS_BUY_SELL_ENABLED &&
        blockchain &&
        isBlockchainSubset(blockchain, moonpayBlockchains) && (
          <>
            <Box mt={2} />
            <BuyButton {...{blockchain, selectedAccount}} />
          </>
        )}
    </>
  )
}
type BuyButtonProps = {
  selectedAccount: AccountOfflineInfo
  blockchain: MoonpayBlockchain
}

const BuyButton = ({selectedAccount, blockchain}: BuyButtonProps) => {
  const accountsQuery = useGetAccounts(blockchain)
  const {t} = useTranslation()
  const signMoonpayUrl = useSignMoonpayUrl()
  const onBuyCrypto = async (
    blockchain: MoonpayBlockchain,
    address: string,
  ) => {
    const buyMoonpayAddressUrl = getMoonpayBuyUrl({
      currencyCode: getMoonpayBlockchainCode(blockchain),
      walletAddress: address,
      showWalletAddressForm: 'true',
    })
    const signedMoonpayUrl =
      await signMoonpayUrl.mutateAsyncSilent(buyMoonpayAddressUrl)
    const urlToOpen = signedMoonpayUrl || buyMoonpayAddressUrl
    // if signing fails, address won't be pre-filled and needs to be added manually
    window.open(urlToOpen, '_blank')
  }

  return (
    <QueryGuard {...accountsQuery}>
      {(accounts) => {
        const account = ensureAccountById(accounts, selectedAccount.id)
        return (
          <Button
            variant="contained"
            color="primary"
            onClick={() => onBuyCrypto(blockchain, account.address)}
            disabled={signMoonpayUrl.isPending}
            sx={{textAlign: 'center'}}
          >
            {t('buy_crypto_for_account', {coin: blockchainToCoin(blockchain)})}
          </Button>
        )
      }}
    </QueryGuard>
  )
}

function PrefetchData() {
  const {blockchain} = useDappConnectorStore()

  // We are prefetching chosen data by rendering the respective hooks
  // in an "empty" components. The advantage is that we can use "if/else"
  // logic that is difficult to achieve with pure hooks. Also there is no need
  // to introduce dedicated prefetching functions and wrapping them in `useEffect`.
  if (blockchain === 'solana') {
    return <PrefetchSolanaData />
  }
  if (isEvmBlockchain(blockchain)) {
    return <PrefetchEvmData {...{blockchain}} />
  }
  return null
}

function PrefetchEvmData({blockchain}: {blockchain: EvmBlockchain}) {
  useGetKnownTokenIds(blockchain)
  return null
}

function PrefetchSolanaData() {
  // ensure owned nfts are fetched to reduce the amount of single-nft calls
  // due to harsh rate limits
  useGetAllNfts(true)
  return null
}
