import {useFormikWithReducer} from 'src/utils/formikUtils'
import type {InferAction} from 'src/utils/reducerUtils'
import {createReducer} from 'src/utils/reducerUtils'

import type {RampOperation, RampCryptoAsset} from '../../../../../domain'

export type RampFlowAccountField = {
  address: string
}

export type RampFlowDetailsFormFields = {
  operation: RampOperation
  asset?: RampCryptoAsset
  account: RampFlowAccountField
}

const formReducer = createReducer<RampFlowDetailsFormFields>()({
  setOperation(state, payload: {operation: RampOperation}) {
    const isOperationMismatch =
      payload.operation === 'sell' && !state.asset?.supportsSell
    return {
      operation: payload.operation,
      asset: isOperationMismatch ? undefined : state.asset,
      account: {
        address: isOperationMismatch ? '' : state.account.address,
      },
    }
  },

  setAsset(state, payload: {asset?: RampCryptoAsset}) {
    const isBlockchainMismatch =
      payload.asset?.blockchain !== state.asset?.blockchain
    return {
      operation: state.operation,
      asset: payload.asset,
      account: {
        address: isBlockchainMismatch ? '' : state.account.address,
      },
    }
  },

  setAccount(state, payload: {account: RampFlowAccountField}) {
    return {
      operation: state.operation,
      asset: state.asset,
      account: payload.account,
    }
  },
})

export type RampFlowDetailsFormAction = InferAction<typeof formReducer>

export const useRampFlowDetailsForm = () => useFormikWithReducer(formReducer)
