import {customBreakpoints} from './breakpoints'
import {colors} from './palette'
export const outlinedInputShrinkLabelRation = 75 // %
const selectElevation = 8

export default {
  MuiTab: {
    styleOverrides: {
      root: {
        textTransform: 'none',
        letterSpacing: 0.4,
        minWidth: 160,
      } as const,
    },
  },
  MuiChip: {
    variants: [
      {
        props: {size: 'extra-small' as const},
        style: {
          height: 16,
          fontSize: 10,
          padding: 0,
          lineHeight: '1em',
          '.MuiChip-label': {
            padding: '0 7px',
          },
        },
      },
    ],
    styleOverrides: {
      root: {
        padding: '7px 0px',
      },
      label: {
        padding: '0 10px',
      },
    },
  },
  MuiLink: {
    defaultProps: {
      underline: 'hover' as const,
    },
  },
  MuiButton: {
    defaultProps: {
      color: 'default',
    },
    styleOverrides: {
      root: {
        textTransform: 'none',
      },
    },
  } as const,
  MuiAlert: {
    styleOverrides: {
      standardError: {
        backgroundColor: colors.error.background,
        color: colors.error.alertContent,
      },
      standardWarning: {
        backgroundColor: colors.warning.background,
        color: colors.warning.alertContent,
      },
      standardInfo: {
        backgroundColor: colors.info.background,
        color: colors.info.alertContent,
      },
      standardSuccess: {
        backgroundColor: colors.success.background,
        color: colors.success.alertContent,
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      input: {
        [`@media (max-width: ${customBreakpoints.windowsZoomed}px)`]: {
          padding: '13.875px 10.5px',
        },
        [`@media (max-width: ${customBreakpoints.windowsOld}px)`]: {
          padding: '11px 8.5px',
        },
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      outlined: {
        transform: 'translate(14px, 18px) scale(1)',
        [`@media (max-width: ${customBreakpoints.windowsZoomed}px)`]: {
          transform: 'translate(14px, 16px) scale(1)',
        },
        [`@media (max-width: ${customBreakpoints.windowsOld}px)`]: {
          transform: 'translate(14px, 13px) scale(1)',
        },
        '&.MuiInputLabel-shrink': {
          transform: `translate(14px, -9px) scale(${
            outlinedInputShrinkLabelRation / 100
          })`,
        },
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        backgroundImage: 'none',
      },
    },
  },
  MuiInputBase: {
    defaultProps: {
      inputProps: {autoComplete: 'off'},
    },
    styleOverrides: {
      input: {
        height: 24, // insuring equal height of all input elements
      },
    },
  },
  MuiSelect: {
    defaultProps: {
      MenuProps: {
        PaperProps: {elevation: selectElevation},
      },
    },
    styleOverrides: {
      select: {
        height: '24px', // insuring equal height of all input elements
      },
    },
  },
  MuiAutocomplete: {
    defaultProps: {
      componentsProps: {paper: {elevation: selectElevation}},
    },
    styleOverrides: {
      inputRoot: {
        [`@media (max-width: ${customBreakpoints.windowsZoomed}px)`]: {
          padding: 6.375,
        },
        [`@media (max-width: ${customBreakpoints.windowsOld}px)`]: {
          padding: 3.5,
        },
      },
      listbox: {
        '& .MuiAutocomplete-option': {
          minHeight: 'auto',
        },
      },
    },
  },
}
