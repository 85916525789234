import {Paper} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'

import {assertUnreachable} from '../../../../utils/assertion'
import type {CardanoStakingHistoryEntry} from '../../../../wallet/cardano'
import {ScrollableList} from '../../common/overview/StakeOverviewUtils'

import {
  DelegationHistoryEntryRow,
  DeregistrationHistoryEntryRow,
  RegistrationHistoryEntryRow,
  RewardHistoryEntryRow,
  WithdrawalHistoryEntryRow,
} from './StakeHistoryUtils'

type CardanoStakeHistoryListProps = {
  stakeHistory: CardanoStakingHistoryEntry[]
}

function StakeHistoryEntryRow({entry}: {entry: CardanoStakingHistoryEntry}) {
  const type = entry.type
  switch (type) {
    case 'reward':
      return <RewardHistoryEntryRow {...entry} />
    case 'delegate':
      return <DelegationHistoryEntryRow {...entry} />
    case 'register':
      return <RegistrationHistoryEntryRow {...entry} />
    case 'deregister':
      return <DeregistrationHistoryEntryRow {...entry} />
    case 'withdraw':
      return <WithdrawalHistoryEntryRow {...entry} />
    default:
      return assertUnreachable()
  }
}

export default function CardanoStakeHistoryList({
  stakeHistory,
}: CardanoStakeHistoryListProps) {
  const classes = useStyles()
  return (
    <ScrollableList>
      {stakeHistory.map((entry, idx) => (
        <Paper
          key={`${entry.timeIssued.toString()}-${idx}`}
          className={classes.wrapper}
          elevation={0}
        >
          <StakeHistoryEntryRow entry={entry} />
        </Paper>
      ))}
    </ScrollableList>
  )
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    cursor: 'pointer',
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1, 0),
    background: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
  },
}))
