import {commonAppInit} from 'src/appStorage'
import masterProfileManager from 'src/appStorage/masterProfileManager'
import {INIT_WALLET_CONFIGURATIONS} from 'src/features/walletStorage/application'
import type {Blockchain, Mnemonic} from 'src/types'
import {assert} from 'src/utils/assertion'
import {useMutation} from 'src/utils/mutation-utils'
import type {AutoDiscoveryType} from 'src/wallet/utils/walletUtils'

import type {Web3AuthUserInfo} from '../domain'
import {web3AuthLoginService} from '../services'
import type {ConfirmMigrationArgs, Web3AuthLoginProvider} from '../services'

export const useWeb3AuthLogin = () =>
  useMutation(
    'loginWeb3Auth',
    async ({
      onConfirmV2Migration,
      ...args
    }: {
      loginProvider: Web3AuthLoginProvider
      sessionNamespace?: string
      sessionTime?: number
      onConfirmV2Migration: (
        props: {onConfirm: () => void} & ConfirmMigrationArgs,
      ) => void
    }) => {
      await web3AuthLoginService.rawWeb3AuthLogout(args)
      return web3AuthLoginService.rawWeb3AuthLogin({
        ...args,
        confirmV2Migration: (props) =>
          new Promise((resolve) => {
            // Resolve the promise when the callback is called
            onConfirmV2Migration({...props, onConfirm: () => resolve(true)})
          }),
      })
    },
  )

export const useRehydrateWeb3AuthSession = () =>
  useMutation(
    'rehydrateWeb3AuthSession',
    async (args: {sessionNamespace?: string} | void) => {
      return web3AuthLoginService.rawWeb3AuthRehydrateSession({...args})
    },
  )

export const useLogoutWeb3AuthSession = () =>
  useMutation(
    'logoutWeb3AuthSession',
    async (args: {sessionNamespace?: string} | void) => {
      return web3AuthLoginService.rawWeb3AuthLogout({...args})
    },
  )

export const useCreateAndInitWeb3AuthProfile = () =>
  useMutation(
    'createAndInitWeb3AuthProfile',
    async (args: {
      web3AuthUserInfo: Web3AuthUserInfo
      profileMnemonic: Mnemonic
      blockchains: readonly Blockchain[]
    }) => {
      await web3AuthLoginService.initNewProfile(
        args.profileMnemonic,
        args.web3AuthUserInfo,
        args.blockchains,
      )
      const blockchainMap = (() => {
        return args.blockchains.reduce(
          (res, b) => {
            res[b] = 'usedOrFallback'
            return res
          },
          {} as Partial<Record<Blockchain, AutoDiscoveryType>>,
        )
      })()
      await commonAppInit(
        INIT_WALLET_CONFIGURATIONS.getCreateWalletArgs(
          blockchainMap,
          'web3Auth',
        ),
      )

      const profileMeta = await masterProfileManager.findProfile({
        mnemonic: args.profileMnemonic,
        loginType: 'web3Auth',
      })
      assert(profileMeta != null)

      return profileMeta
    },
  )
