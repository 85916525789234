import {styled} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {trackTransactionSubmission} from 'src/tracking'
import {useMutation} from 'src/utils/mutation-utils'

import {MutationGuard} from '../../../components'
import {assert, safeAssertUnreachable} from '../../../utils/assertion'
import type {HwVendor} from '../../../wallet'
import {isHwVendor} from '../../../wallet'
import {ActionHeader} from '../../components'

import {HwSignScreen} from './HwSign'
import {MnemonicSignScreen} from './MnemonicSign'
import {useSignContext} from './SignContext'
import type {SignScreenProps, TopLevelSignScreenProps} from './types'

export function SignScreen(props: TopLevelSignScreenProps) {
  const {t} = useTranslation()

  const {
    selectedAccount,
    signData,
    blockchain,
    origin,
    favIconUrl,
    layoutProps,
    hideProfileInfo,
    enforceMnemonicPassword,
    selectedAccountAmountFormat,
  } = useSignContext()
  assert(selectedAccount != null, 'Sign: no selected account')
  assert(blockchain != null, 'Sign: no selected blockchain')
  assert(origin != null, 'OriginInfo: origin not provided')
  assert(
    signData.state !== 'create-cardano-collateral',
    'Sign: invalid screen type',
  )

  const signMutation = useMutation('dappConnector:sign', () =>
    signData.onSign(props.extraOptionsProps),
  )

  const onSign = async () => {
    assert(selectedAccount != null, 'Sign: no selected account')
    const result = await signMutation.mutateAsyncSilent()
    result?.onFinish()
    if (signData.state === 'sign-tx') {
      trackTransactionSubmission({
        blockchain,
        type: 'dapp_transaction',
        provider: selectedAccount.cryptoProviderType,
        dapp: origin,
      })
    }
  }

  const onFailure = () => {
    signData.onFailure()
  }

  const isHwAccount = isHwVendor(selectedAccount.cryptoProviderType)

  const commonProps = {
    onSign,
    signProps: signMutation,
    onFailure,
    signData: {
      type: signData.state,
      data: signData.data,
    } as SignScreenProps['signData'],
    extraOptionsProps: props.extraOptionsProps,
  }

  const title = (() => {
    switch (signData.state) {
      case 'sign-message':
        return t('Sign message')
      case 'sign-tx':
        return t('Sign transaction')
      case 'sign-vote':
        return t('Sign vote')
      case 'get-hw-wallet-public-keys':
        return t('Get public keys from hardware wallet')
      default:
        return safeAssertUnreachable(signData)
    }
  })()

  return (
    <Wrapper overflow={layoutProps.signLayoutOverflow}>
      <ActionHeader
        message={title}
        selectedAccount={selectedAccount}
        mode="account-change-disabled"
        origin={origin}
        favIconUrl={favIconUrl}
        hideProfileInfo={hideProfileInfo}
        contentWidth={layoutProps.screenWidth}
        selectedAccountAmountFormat={selectedAccountAmountFormat}
      />
      {props.extraOptionsProps?.showOptions ? (
        props.extraOptionsProps.customOptionsScreen
      ) : (
        <>
          <>
            {isHwAccount ? (
              <HwSignScreen
                {...commonProps}
                blockchain={blockchain}
                hwVendor={selectedAccount.cryptoProviderType as HwVendor}
              />
            ) : (
              <MnemonicSignScreen
                {...{...commonProps, enforceMnemonicPassword}}
              />
            )}
          </>
          {/* Passing "error=null" as error UI is handled by children components */}
          <MutationGuard {...signMutation} error={null} />
        </>
      )}
    </Wrapper>
  )
}

const Wrapper = styled('div', {shouldForwardProp: (p) => p !== 'overflow'})<{
  overflow?: string
}>(({theme, overflow}) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  gap: theme.spacing(1),
  ...(overflow && {overflow}),
}))
