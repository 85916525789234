import {
  ApiError,
  ApiErrorCode,
} from /* webpackImportAllow: 'injectionPayload' */ '@nufi/dapp-client-cardano'
import {VotingPurpose} from /* webpackImportAllow: 'injectionPayload' */ '@nufi/wallet-cardano'

export const supportedVotingPurposes = [VotingPurpose.CATALYST]

export function ensureCatalystVotingPurpose(purposes: unknown) {
  if (!Array.isArray(purposes) || purposes.length === 0) {
    throw new ApiError(
      ApiErrorCode.InvalidArgumentError,
      `Invalid Voting Purpose ${JSON.stringify(purposes)}`,
    )
  }
  const unsupportedPurposes = purposes.filter(
    (p) => !supportedVotingPurposes.includes(p),
  )

  if (unsupportedPurposes.length > 0) {
    throw new ApiError(
      ApiErrorCode.UnsupportedVotingPurpose,
      `Unsupported Voting Purpose ${unsupportedPurposes.join(' & ')}`,
      {
        votingPurpose: unsupportedPurposes,
      },
    )
  }
}
