import {Box, Grid} from '@mui/material'
import type {BoxProps} from '@mui/material/Box'
import type {GridProps} from '@mui/material/Grid'
import React from 'react'

type HidableGridItemProps = GridProps &
  BoxProps & {
    children: React.ReactNode
  }

// https://stackoverflow.com/questions/62775719/hide-material-ui-grid-column-on-certain-breakpoints
export default function HidableGridItem({
  children,
  ...rest
}: HidableGridItemProps) {
  return (
    <Box
      component={Grid}
      // MUI TS does not know about `item`, though it exists
      // eslint-disable-next-line
      // @ts-ignore
      item
      {...rest}
    >
      {children}
    </Box>
  )
}
