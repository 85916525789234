import BigNumber from 'bignumber.js'

import {getBlockchainDecimals} from 'src/constants'
import type {
  ConversionRates,
  Currency,
} from 'src/features/conversionRates/domain'
import type {Blockchain, TokenMetadata} from 'src/types'

export type ConvertedBalanceResponse =
  | {
      type: 'success'
      balance: number
    }
  | {
      type: 'rates-unknown'
    }
  | {
      type: 'rates-not-loaded'
    }

export const convertBalance = ({
  balance,
  blockchain,
  conversionRates,
  currency,
  tokenMetadata,
}: {
  balance: BigNumber
  blockchain: Blockchain
  currency: Currency
  conversionRates: ConversionRates
  tokenMetadata?: TokenMetadata
}): ConvertedBalanceResponse => {
  const decimals = tokenMetadata
    ? tokenMetadata?.decimals
    : getBlockchainDecimals(blockchain)

  const convRatesResult = tokenMetadata
    ? conversionRates[tokenMetadata.id]
    : conversionRates[blockchain]

  // If user has no balance of a token, but we nevertheless query it,
  // we just return 0 as this should be reasonable answer in all cases.
  if (balance.isEqualTo(new BigNumber(0))) return {type: 'success', balance: 0}

  // If the whole conversion rates are not available, resp. we do not
  // expect them to be available (e.g. token does not have coingeckoId) we
  // simple return `undefined`.
  if (convRatesResult === undefined) return {type: 'rates-unknown'}

  // If rates are expected to be loaded, we distinguish this case from rates not
  // being available all together. If rates loaded, but the currency is missing
  // we also consider it non-loaded case.
  if (convRatesResult === 'not-loaded') return {type: 'rates-not-loaded'}
  const convRate = convRatesResult[currency]
  if (convRate == null) return {type: 'rates-not-loaded'}

  return {
    type: 'success',
    balance: new BigNumber(balance)
      .dividedBy(new BigNumber(10).pow(decimals))
      .multipliedBy(new BigNumber(convRate.currentRate))
      .toNumber(),
  }
}
