import {Box, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {MutationGuard} from '../../../../../components'
import {Alert} from '../../../../../components/visual'
import type {SignScreenData} from '../../../../../store/dappConnector'
import type {AccountOfflineInfo} from '../../../../../types'
import {isHwVendor} from '../../../../../wallet'
import type {useSignTransaction} from '../../../../../wallet/cardano'
import type {HwVendor} from '../../../../../wallet/types'
import {HwSignScreen} from '../../../sign/HwSign'
import {MnemonicSignScreen} from '../../../sign/MnemonicSign'

type SignTxScreenProps = {
  selectedAccount: AccountOfflineInfo
  txData: SignScreenData<'sign-tx'>
  signProps: ReturnType<typeof useSignTransaction>
  onSign: () => Promise<void>
  onFailure: () => void
}

export function SignTxScreen({
  selectedAccount,
  txData,
  signProps,
  onSign,
  onFailure,
}: SignTxScreenProps) {
  const {t} = useTranslation()
  const isHwAccount = isHwVendor(selectedAccount.cryptoProviderType)

  const collateralAlert = (
    <Box mb={2} mt={2}>
      <Alert severity="warning">
        <Typography>
          {t('dapp_connector_create_cardano_collateral_explanation')}
        </Typography>
      </Alert>
    </Box>
  )

  const commonSignScreenProps = {
    signData: {type: 'sign-tx' as const, data: txData},
    onSign,
    onFailure,
    signProps,
    extraContent: collateralAlert,
  }

  return (
    <>
      {isHwAccount ? (
        <HwSignScreen
          blockchain={'cardano'}
          hwVendor={selectedAccount.cryptoProviderType as HwVendor}
          {...commonSignScreenProps}
        />
      ) : (
        <MnemonicSignScreen {...commonSignScreenProps} />
      )}
      {/* Passing "error=null" as error UI is handled by children components */}
      <MutationGuard {...signProps} error={null} />
    </>
  )
}
