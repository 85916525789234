import type {FormikProps} from 'formik'
import {Formik} from 'formik'
import React, {useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'

import {QueryGuard, StakingModalError} from '../../../../components'
import {routeTo} from '../../../../router'
import {useTrackTxSubmission} from '../../../../tracking'
import type {AppError} from '../../../../types'
import {assert} from '../../../../utils/assertion'
import type {AccountId} from '../../../../wallet'
import type {
  CardanoSignedTx,
  CardanoStakeAccountInfo,
  CardanoPoolIdHex,
  CardanoStakepoolInfo,
  CardanoTxPlan,
} from '../../../../wallet/cardano'
import {
  useGetDelegateTxPlan,
  useSignTransaction,
  useGetStakeAccounts,
  useGetStakepools,
  ensureValidatorById,
  useSubmitTransaction,
  getDepositAmount,
} from '../../../../wallet/cardano'
import {ensureAccountById} from '../../../../wallet/utils/common'
import {
  SignTxFlow,
  SubmitStakeScreen,
  useActiveSchema,
  useActiveScreenState,
  useFormikOnSubmit,
  WithActiveScreenState,
  WithCardanoExtras,
} from '../../../transaction'
import type {RenderSubmitGuard} from '../../../utils/debouncedFormUtils/debouncing'
import {
  getCardanoTxPlanDebounceTimeoutInMs,
  useAsyncDebouncedState,
  useSubmitGuard,
} from '../../../utils/debouncedFormUtils/debouncing'
import {useSummaryDetailsSchema} from '../../common/schema'
import {DeviceReadyState, StakeModalHeader} from '../../common/utils'
import type {ActivateStakeSchema} from '../schema'
import {useBaseDetailsSchema} from '../schema'

import DetailScreen from './StakeModalDetailScreen'
import SummaryScreen from './StakeModalSummaryScreen'

type FormSchema = ActivateStakeSchema

type CardanoStakeModalProps = {
  stakeAccountId: AccountId
  onClose: () => unknown
}

type UICachedTxPlan = {
  data: CardanoTxPlan | null
  error: AppError | null
}

export default function CardanoDelegateAccountModal({
  onClose,
  stakeAccountId,
}: CardanoStakeModalProps) {
  const stakeAccountsQuery = useGetStakeAccounts()
  const stakePoolsQuery = useGetStakepools()
  return (
    <WithActiveScreenState initialScreen={'details'}>
      <QueryGuard
        {...stakeAccountsQuery}
        ErrorElement={<StakingModalError blockchain="cardano" />}
        loadingVariant="centered"
      >
        {(stakeAccounts) => (
          <QueryGuard
            {...stakePoolsQuery}
            ErrorElement={<StakingModalError blockchain="cardano" />}
            loadingVariant="centered"
          >
            {(validators) => (
              <CardanoSelectDelegation
                {...{onClose, stakeAccountId, stakeAccounts, validators}}
              />
            )}
          </QueryGuard>
        )}
      </QueryGuard>
    </WithActiveScreenState>
  )
}

type CardanoSelectDelegationProps = {
  onClose: () => unknown
  stakeAccounts: CardanoStakeAccountInfo[]
  stakeAccountId: AccountId
  validators: CardanoStakepoolInfo[]
}

function CardanoSelectDelegation({
  onClose,
  stakeAccounts,
  stakeAccountId,
  validators,
}: CardanoSelectDelegationProps) {
  const submit = useSubmitTransaction()
  const sign = useSignTransaction()

  const {setActiveScreen} = useActiveScreenState()
  const [txPlan, setTxPlan] = useState<UICachedTxPlan>({
    error: null,
    data: null,
  })
  const stakeAccount = ensureAccountById(stakeAccounts, stakeAccountId)

  const schema = useActiveSchema({
    details: useBaseDetailsSchema(),
    summary: useSummaryDetailsSchema({accounts: stakeAccounts}),
  })

  const initialValues: FormSchema = {
    accountId: stakeAccountId as unknown as AccountId,
    validatorId: stakeAccount.stakingRecommendation?.poolId || '',
    password: '',
  }

  const onTxSign = async (values: FormSchema) => {
    assert(txPlan.data != null, 'Tx plan must be defined')

    return await sign.mutateAsyncSilent({
      accountId: values.accountId,
      txPlan: txPlan.data,
    })
  }

  const onTxSubmit = async (
    accountId: AccountId,
    signedTx: CardanoSignedTx,
  ) => {
    assert(txPlan.data != null, 'Tx plan must be defined')

    return await submit.mutateAsyncSilent({
      signedTx,
      accountId,
      txPlan: txPlan.data,
    })
  }

  const onTxSignAndSubmit = async (values: FormSchema) => {
    const signedTx = await onTxSign(values)
    if (!signedTx) return
    setActiveScreen('submit')
    await onTxSubmit(values.accountId, signedTx)
  }

  const {onPreSubmit, renderSubmitGuard} = useSubmitGuard<FormSchema>({
    onSubmit: () => setActiveScreen('summary'),
  })

  const onSubmit = useFormikOnSubmit<FormSchema>({
    accounts: stakeAccounts,
    onTxSignAndSubmit,
    onDetails: onPreSubmit,
  })

  const getAreDataValid = async (data: FormSchema) => await schema.isValid(data)

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={onSubmit}
    >
      {(formikProps) => (
        <CardanoStakeModalContent
          {...{
            formikProps,
            stakeAccounts,
            onClose,
            onTxSubmit,
            onTxSignAndSubmit,
            setTxPlan,
            txPlan,
            getAreDataValid,
            renderSubmitGuard,
          }}
          submitProps={submit}
          signProps={sign}
          accountId={stakeAccountId as unknown as AccountId}
          validators={validators}
        />
      )}
    </Formik>
  )
}

type CardanoStakeModalContentProps = {
  stakeAccounts: CardanoStakeAccountInfo[]
  formikProps: FormikProps<FormSchema>
  getAreDataValid: (data: FormSchema) => Promise<boolean>
  setTxPlan: (txPlan: UICachedTxPlan) => void
  txPlan: UICachedTxPlan
  onClose: () => unknown
  onTxSubmit: (
    accountId: AccountId,
    signedTx: CardanoSignedTx,
  ) => Promise<unknown>
  onTxSignAndSubmit: (values: FormSchema) => Promise<unknown>
  submitProps: ReturnType<typeof useSubmitTransaction>
  signProps: ReturnType<typeof useSignTransaction>
  accountId: AccountId
  validators: CardanoStakepoolInfo[]
  renderSubmitGuard: RenderSubmitGuard<FormSchema>
}

function CardanoStakeModalContent({
  stakeAccounts,
  formikProps,
  getAreDataValid,
  setTxPlan,
  txPlan,
  onClose,
  onTxSubmit,
  onTxSignAndSubmit,
  submitProps,
  signProps,
  accountId,
  validators,
  renderSubmitGuard,
}: CardanoStakeModalContentProps) {
  const history = useHistory()

  const fetchDelegateTxPlan = useGetDelegateTxPlan()

  const {values} = formikProps
  const stakeAccount = ensureAccountById(stakeAccounts, values.accountId)

  const {onDataChange, submitGuardMeta} = useAsyncDebouncedState<
    FormSchema,
    CardanoTxPlan
  >({
    timeout: getCardanoTxPlanDebounceTimeoutInMs(),
    state: txPlan,
    setState: setTxPlan,
    shouldSkip: async ({values}) => !(await getAreDataValid(values)),
    fn: async ({values}) =>
      await fetchDelegateTxPlan.mutateAsync({
        accountInfo: stakeAccount,
        stakepoolId: values.validatorId as CardanoPoolIdHex,
      }),
    leading: true,
  })

  // try recalculating txPlan
  useEffect(() => {
    if (values.validatorId === '') {
      setTxPlan({data: null, error: null})
      return
    }
    onDataChange({values, formikProps})
  }, [values.accountId, values.validatorId])

  const blockchain = 'cardano'
  const ModalHeader = <StakeModalHeader {...{blockchain, onClose}} />

  const openStakeModal = () =>
    history.push(routeTo.staking.myStaking.cardano.account(accountId).delegate)

  useTrackTxSubmission(submitProps, {
    blockchain,
    provider: stakeAccount.cryptoProviderType,
    type: stakeAccount.isStakingKeyRegistered ? 'stake' : 'register_stake_key',
    id: values.validatorId,
    value: stakeAccount.balance.toNumber(),
  })

  return (
    <WithCardanoExtras
      extras={{txPlan: txPlan.data, txHash: signProps.data?.txHash}}
    >
      <SignTxFlow
        {...{
          onClose,
          formikProps,
          ModalHeader,
          blockchain,
          signProps,
          submitProps,
          DeviceReadyState,
          onTxSignAndSubmit,
          onTxSubmit,
        }}
        initialScreen="details"
        renderDetails={() => {
          const recommendedValidator = ensureValidatorById(
            validators,
            stakeAccount.stakingRecommendation.poolId,
          )
          return (
            <>
              {renderSubmitGuard({formikProps, items: [submitGuardMeta]})}
              <DetailScreen
                accounts={stakeAccounts}
                validators={validators}
                recommendedValidator={recommendedValidator}
                error={txPlan.error}
                {...{blockchain, onClose, formikProps}}
              />
            </>
          )
        }}
        renderSummary={({onBack}) => {
          assert(txPlan.data != null, 'Tx plan must be defined')
          const validatorInfo = ensureValidatorById(
            validators,
            values.validatorId,
          )
          const summary = {
            fee: txPlan.data.fee,
            fromAccount: stakeAccount,
            validatorInfo,
            deposit: getDepositAmount(txPlan.data),
          }

          return (
            <SummaryScreen
              onSubmit={formikProps.handleSubmit}
              {...{formikProps, onClose, blockchain, summary, onBack}}
            />
          )
        }}
        renderSubmit={(signAndSubmitUtils) => {
          const signedTransaction = signProps.data
          assert(!!signedTransaction)
          return (
            <SubmitStakeScreen
              accountId={accountId}
              onBack={signAndSubmitUtils.onSignAndSubmitGoBack}
              onRetry={() => onTxSubmit(accountId, signedTransaction)}
              onNewStaking={() => {
                signAndSubmitUtils.onSignAndSubmitGoBack()
                formikProps.resetForm()
                openStakeModal()
              }}
              {...{onClose, submitProps, blockchain, ModalHeader}}
            />
          )
        }}
      />
    </WithCardanoExtras>
  )
}
