import {Box, Typography} from '@mui/material'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {InlineLoading} from 'src/components'
import type {
  ReferrerInfo,
  OpenLoggedInAppIntent,
  PlatformSpecificAppOpenerParams,
} from 'src/features/appOpener/domain'
import {MetamaskOnboardingStep1, OnboardingModal} from 'src/features/login/ui'
import {MetamaskInitializationGuard} from 'src/metamaskSnap/MetamaskInitializationGuard'
import {safeAssertUnreachable} from 'src/utils/assertion'

import {useAppOpenerProvider} from './viewModel'

function isLocalProfileReferrerInfo(
  p: PlatformSpecificAppOpenerParams,
): p is ReferrerInfo {
  return p.platform === 'extension' || p.customChildWindowId != null
}

type WithAutoLoginProps = {
  platformParams: PlatformSpecificAppOpenerParams
  intent: OpenLoggedInAppIntent
  skipBroadcastLogin?: boolean
  children: JSX.Element
}

export function WithAutoLogin({
  children,
  intent,
  platformParams,
  skipBroadcastLogin = false,
}: WithAutoLoginProps) {
  const {loginType} = intent

  switch (loginType) {
    case 'metamask':
      return (
        <MetamaskInitializationGuard>
          <WithMetamaskAutoLogin {...{children, skipBroadcastLogin}} />
        </MetamaskInitializationGuard>
      )
    case 'password': {
      if (isLocalProfileReferrerInfo(platformParams)) {
        return (
          <WithPasswordAutoLogin
            {...{children, skipBroadcastLogin}}
            referrerInfo={platformParams}
          />
        )
      }
      return children
    }
    case 'web3Auth': {
      if (isLocalProfileReferrerInfo(platformParams)) {
        return (
          <WithWeb3AuthAutoLogin
            {...{children, skipBroadcastLogin}}
            referrerInfo={platformParams}
          />
        )
      }
      return children
    }
    default:
      return safeAssertUnreachable(loginType)
  }
}

type WithMetamaskAutoLoginProps = {
  skipBroadcastLogin: boolean
  children: React.ReactElement
}

type OnboardingState =
  | {open: false}
  | {open: true; onFinishOnboarding: () => void}

function WithMetamaskAutoLogin({
  children,
  skipBroadcastLogin,
}: WithMetamaskAutoLoginProps) {
  const {vm} = useAppOpenerProvider()
  const [onboarding, setOnboarding] = useState<OnboardingState>({
    open: false,
  })
  const {isLoaded} = vm.useMetamaskAutoLogin({
    skipBroadcastLogin,
    onBeforeOnNewProfileLogin: (onLogin) => {
      setOnboarding({open: true, onFinishOnboarding: onLogin})
    },
  })

  if (onboarding.open === true) {
    return (
      <OnboardingModal>
        <MetamaskOnboardingStep1
          onNext={() => {
            onboarding.onFinishOnboarding()
            setOnboarding({open: false})
          }}
          // Extending the app intent query parameter with a blockchain field seems unnecessary at this moment,
          // so 'cardano' is hard-coded here instead.
          blockchain="cardano"
        />
      </OnboardingModal>
    )
  }

  return isLoaded ? children : <AppOpenerLoading />
}

type WithPasswordAutoLoginProps = {
  referrerInfo: ReferrerInfo
  skipBroadcastLogin: boolean
  children: JSX.Element
}

function WithPasswordAutoLogin({
  children,
  skipBroadcastLogin,
  referrerInfo,
}: WithPasswordAutoLoginProps) {
  const {vm} = useAppOpenerProvider()
  const {isLoaded} = vm.usePasswordAutoLogin(referrerInfo, skipBroadcastLogin)
  return isLoaded ? children : <AppOpenerLoading />
}

type WithWeb3AuthAutoLoginProps = {
  referrerInfo: ReferrerInfo
  skipBroadcastLogin: boolean
  children: JSX.Element
}

function WithWeb3AuthAutoLogin({
  children,
  referrerInfo,
  skipBroadcastLogin,
}: WithWeb3AuthAutoLoginProps) {
  const {vm} = useAppOpenerProvider()
  const {isLoaded} = vm.useWeb3AuthAutoLogin(referrerInfo, skipBroadcastLogin)
  return isLoaded ? children : <AppOpenerLoading />
}

export function AppOpenerLoading() {
  const {t} = useTranslation()
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <InlineLoading size={30} />
        <Typography mt={2}>{t('Signing in, please wait ...')}</Typography>
      </Box>
    </Box>
  )
}
