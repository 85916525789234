/* eslint-disable @typescript-eslint/explicit-function-return-type */
import type {MutationKey} from '@tanstack/react-query'

import {cloudSyncServiceLocator} from 'src/features/profileSync/application'
import {invalidateQueryKeys, useMutation} from 'src/utils/mutation-utils'

import {profileManagerLocator} from '../../appStorage'
import type {
  Blockchain,
  TokenVisibility,
  TokenVisibilityBase,
} from '../../types'
import {
  TokenVisibilityStoreManagerProvider,
  hideToken,
  unhideToken,
} from '../TokenVisibilityStoreManager'

export async function _saveTokenVisibilities(
  tokenVisibilities: TokenVisibility[],
): Promise<void> {
  const profileManager = profileManagerLocator.instance()
  await profileManager.saveProfileTokensVisibility(tokenVisibilities)
  TokenVisibilityStoreManagerProvider.instance().setTokenVisibilities(
    tokenVisibilities,
  )

  await cloudSyncServiceLocator.instance().syncProfileData()
}

async function mutateTokenVisibilities(
  fn: (tokenVisibilities: TokenVisibility[]) => TokenVisibility[],
) {
  const profileManager = profileManagerLocator.instance()
  const {tokenVisibilities} = await profileManager.getProfileData()

  const mutatedTokenVisibility = fn(tokenVisibilities)
  await _saveTokenVisibilities(mutatedTokenVisibility)
}

const getTokenVisibilityMutationOptions = (
  invalidationKeys: MutationKey[],
) => ({
  onSuccess: async () => {
    // await invalidation so that UI waits for queries to be updated
    await Promise.all(invalidateQueryKeys(invalidationKeys))
  },
})

export function _useHideTokens(
  blockchain: Blockchain,
  invalidationKeys: MutationKey[],
) {
  return useMutation(
    [blockchain, 'hideTokens'],
    async ({tokens}: {tokens: TokenVisibilityBase[]}) => {
      await mutateTokenVisibilities((tokenVisibilities) =>
        tokens.reduce((acc, t) => hideToken(acc, t), tokenVisibilities),
      )
    },
    getTokenVisibilityMutationOptions(invalidationKeys),
  )
}

export function _useUnhideTokens(
  blockchain: Blockchain,
  invalidationKeys: MutationKey[],
) {
  return useMutation(
    [blockchain, 'unhideTokens'],
    async ({tokens}: {tokens: TokenVisibilityBase[]}) => {
      await mutateTokenVisibilities((tokenVisibilities) =>
        tokens.reduce((acc, t) => unhideToken(acc, t), tokenVisibilities),
      )
    },
    getTokenVisibilityMutationOptions(invalidationKeys),
  )
}
