import {Button} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

type InfinitePagingParams = {
  onNextPage: () => void
  hasNextPage?: boolean
}

export function PagingButton({
  pagingParams,
  isLoading,
  className,
  loadingText,
  label,
  noMoreDataLabel,
}: {
  pagingParams: InfinitePagingParams
  className?: string
  isLoading: boolean
  loadingText?: string
  label?: React.ReactNode
  noMoreDataLabel?: React.ReactNode
}) {
  const {t} = useTranslation()

  return (
    <Button
      disabled={!pagingParams.hasNextPage || isLoading}
      onClick={() => pagingParams.onNextPage()}
      className={className || ''}
    >
      {pagingParams.hasNextPage
        ? isLoading
          ? loadingText || t('Fetching...')
          : label || t('Show more')
        : noMoreDataLabel === undefined
          ? t('No more data')
          : noMoreDataLabel}
    </Button>
  )
}
