import {
  Cancel as ClearInputIcon,
  SelectAll as SelectAllIcon,
  Warning as WarningIcon,
  HelpOutline as HelpIcon,
} from '@mui/icons-material'
import {
  Grid,
  Box,
  Typography,
  ListItemButton,
  ListItemIcon,
  Checkbox,
  ListItemText,
  List,
  styled,
} from '@mui/material'
import React, {useRef, useState} from 'react'
import {Trans, useTranslation} from 'react-i18next'

import {getAvailableBlockchains} from 'src/features/availableBlockchains/application'

import {
  SideBarLayout,
  Button,
  NavigateBack,
  TransBold,
  WithTooltip,
  BlockchainIcon,
} from '../../components'
import type {Blockchain} from '../../types'
import {useGetBlockchainName} from '../../utils/translations'

import {
  useCommonProfileStyles,
  NavigateBackWrapper,
  CreateProfileTimeline,
} from './common'

// Note that this was selected on ad-hoc basis
export const TOO_MANY_BLOCKCHAINS_THRESHOLD = 4

export type ChooseBlockchainsPageProps = {
  onSubmit: (blockchains: Blockchain[]) => unknown
  onBack: () => unknown
}

export function ChooseBlockchainsPage({
  onSubmit: _onSubmit,
  onBack,
}: ChooseBlockchainsPageProps) {
  const commonClasses = useCommonProfileStyles()
  const isSubmitting = useRef(false)
  const onSubmit = (blockchains: Blockchain[]) => {
    if (!isSubmitting.current) {
      isSubmitting.current = true
      _onSubmit?.(Array.from(blockchains).sort() as Blockchain[])
    }
  }
  return (
    <SideBarLayout
      left={<CreateProfileTimeline step="blockchains" />}
      right={
        <Box className={commonClasses.wrapper}>
          <ChooseBlockchains
            onSubmit={onSubmit}
            disabled={isSubmitting.current}
          />
          <NavigateBackWrapper>
            <NavigateBack onBack={onBack} />
          </NavigateBackWrapper>
        </Box>
      }
    />
  )
}

type ChooseBlockchainsProps = Pick<ChooseBlockchainsPageProps, 'onSubmit'> & {
  extraContent?: React.ReactNode
  disabled?: boolean
  customButtonLabel?: string
  preselectedBlockchains?: Blockchain[]
}

export const ChooseBlockchains = ({
  onSubmit: _onSubmit,
  disabled,
  extraContent,
  customButtonLabel,
  preselectedBlockchains = ['cardano'],
}: ChooseBlockchainsProps) => {
  const {t} = useTranslation()
  const commonClasses = useCommonProfileStyles()
  /*
  Note that we do not store the state in Formik as the current form does not
  require any validation and can not really be submitted wrongly.
  Therefore we rather avoid the Formik boilerplate overhead and manage
  'isSubmitting' ourselves.
  */
  const [blockchains, setBlockchains] = useState(
    new Set(preselectedBlockchains),
  )

  const getBlockchainName = useGetBlockchainName()

  const onToggleBlockchain = (blockchain: Blockchain) => {
    const updatedBlockchains = new Set(blockchains)
    if (updatedBlockchains.has(blockchain)) {
      updatedBlockchains.delete(blockchain)
    } else {
      updatedBlockchains.add(blockchain)
    }
    setBlockchains(updatedBlockchains)
  }

  const onSelectAll = () => {
    setBlockchains(new Set(getAvailableBlockchains()))
  }

  const onReset = () => {
    setBlockchains(new Set())
  }

  const onSubmit = () => {
    _onSubmit(Array.from(blockchains).sort() as Blockchain[])
  }
  const displayWarning = blockchains.size >= TOO_MANY_BLOCKCHAINS_THRESHOLD

  return (
    <>
      <Typography variant="h5">{t('Choose blockchains')}</Typography>

      <Typography
        className={commonClasses.headerSubtitle}
        variant="body1"
        color="textSecondary"
      >
        <Trans
          i18nKey="choose_blockchains_info"
          t={t}
          components={{
            bold: TransBold,
          }}
        />
      </Typography>

      <ControlsWrapper>
        <Grid container spacing={1}>
          <Grid item>
            <ControlButton
              label={t('Select all')}
              startIcon={<SelectAllIcon />}
              onClick={onSelectAll}
            />
          </Grid>
          <Grid item>
            <ControlButton
              label={t('Clear')}
              startIcon={<ClearInputIcon />}
              onClick={onReset}
            />
          </Grid>
        </Grid>
        {displayWarning && (
          <WithTooltip
            title={t('choose_blockchains_too_many_accounts_warning')}
          >
            <WarningIcon
              color="warning"
              rtl-data-test-id="choose-blockchains-warning"
            />
          </WithTooltip>
        )}
      </ControlsWrapper>

      <BlockchainList>
        {getAvailableBlockchains().map((blockchain, index, items) => (
          <BlockchainListItem
            key={blockchain}
            onClick={() => onToggleBlockchain(blockchain)}
            divider={index < items.length - 1}
            data-test-id={`choose-blockchains-item-${blockchain}`}
            rtl-data-test-id={`choose-blockchains-item-${blockchain}`}
          >
            <ListItemIcon>
              <Checkbox checked={blockchains.has(blockchain)} />
            </ListItemIcon>
            <ListItemText
              disableTypography
              primary={
                <Box display="flex" alignItems="center">
                  <BlockchainIcon {...{blockchain}} />
                  <Typography ml={1.5}>
                    {getBlockchainName(blockchain)}
                  </Typography>
                  {blockchain === 'flow' && blockchains.has('flow') && (
                    <Box ml={1}>
                      <WithTooltip title={t('choose_blockchains_flow_help')}>
                        <HelpIcon fontSize="small" />
                      </WithTooltip>
                    </Box>
                  )}
                </Box>
              }
            />
          </BlockchainListItem>
        ))}
      </BlockchainList>
      {extraContent}
      <Grid container>
        <Grid item xs={12}>
          <Button
            className={commonClasses.submitButton}
            textTransform="none"
            fullWidth
            variant="contained"
            type="submit"
            color="primary"
            onClick={onSubmit}
            disabled={disabled}
          >
            {customButtonLabel ||
              (blockchains.size > 0 ? t('Continue') : t('Skip'))}
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

type ControlButtonProps = {
  onClick: () => unknown
  startIcon: React.ReactNode
  label: string
}

function ControlButton({onClick, startIcon, label}: ControlButtonProps) {
  return (
    <CompactButton
      onClick={onClick}
      variant="outlined"
      startIcon={startIcon}
      textTransform="none"
    >
      {label}
    </CompactButton>
  )
}

const ControlsWrapper = styled(Box)(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(1),
}))

const BlockchainList = styled(List)(() => ({
  maxHeight: 350,
  overflow: 'auto',
}))

const BlockchainListItem = styled(ListItemButton)(({theme}) => ({
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
}))

const CompactButton = styled(Button)(() => ({
  minHeight: '0!important',
  paddingTop: 3,
  paddingBottom: 3,
}))
