import {combinedQueryKeys} from './combined'
import {coreQueryKeys} from './core'
import {stakeHistoryQueryKeys} from './stakeHistory'
import {stakingQueryKeys} from './staking'
import {tokensQueryKeys} from './tokens'

export * from './core'
export * from './staking'
export * from './tokens'
export * from './combined'
export * from './nfts'
export * from './stakeHistory'
export * from './common'
export * from './fees'

export const queryKeys = {
  ...coreQueryKeys,
  ...stakingQueryKeys,
  ...tokensQueryKeys,
  ...combinedQueryKeys,
  ...stakeHistoryQueryKeys,
}
