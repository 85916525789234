import type {FlowTokenId, FlowTokenMetadata} from '@nufi/wallet-flow'
import {getFlowContractId, parseFlowTokenId} from '@nufi/wallet-flow'
import React from 'react'
import {Route, Switch} from 'react-router-dom'

import {useLastDefinedData} from 'src/utils/query-utils'

import {BatchQueryGuard, TransactionModalError} from '../../../components'
import {routeTo} from '../../../router'
import {useTokenIdFromRoute} from '../../../router/portfolio'
import {useGetAccounts} from '../../../wallet/flow'
import {useGetSupportedTokenContracts} from '../../../wallet/flow/public/queries/tokens/contractMetadata'
import type {AccountId} from '../../../wallet/types'
import {ImportFlowTokenFlow} from '../../portfolio/account/actions/importToken/flow/ImportTokenFlow'
import {useActiveScreenState, WithActiveScreenState} from '../../transaction'

import {ReceiveModalDetailScreen} from './DetailScreen'
import {ReceiveModalSubmitScreen} from './SubmitScreen'
import {FLOW_TOKEN_OPTION, ReceiveTokenHeader} from './utils'

type FlowReceiveModalProps = {
  accountId: AccountId | null
  onClose: () => unknown
}

export const FlowReceiveModal = ({
  accountId,
  onClose,
}: FlowReceiveModalProps) => (
  <WithActiveScreenState initialScreen="details">
    <Switch>
      <Route path={routeTo.portfolio.nfts.index}>
        <FlowReceiveModalBody {...{onClose, accountId}} isNftView />
      </Route>
      <Route>
        <FlowReceiveModalBody {...{onClose, accountId}} isNftView={false} />
      </Route>
    </Switch>
  </WithActiveScreenState>
)

type FlowReceiveModalBodyProps = FlowReceiveModalProps & {
  isNftView: boolean
}

const FlowReceiveModalBody = ({
  accountId,
  onClose,
  isNftView,
}: FlowReceiveModalBodyProps) => {
  const blockchain = 'flow' as const
  const defaultAccountId = accountId || ('' as AccountId)
  const tokenId: FlowTokenId | undefined = useTokenIdFromRoute()
  const {setActiveScreen} = useActiveScreenState()
  const contractId = tokenId ? parseFlowTokenId(tokenId) : null
  const defaultTokenOptionId = contractId
    ? getFlowContractId(contractId.contractAddress, contractId.contractName)
    : // if its not the nftView we default to open the receive modal with pre-selected FLOW native token options
      (!isNftView && FLOW_TOKEN_OPTION.id) || null

  const {accountsQuery, supportedTokenContractsQuery} = useLastDefinedData({
    accountsQuery: useGetAccounts(),
    supportedTokenContractsQuery: useGetSupportedTokenContracts(),
  })

  const renderModalHeader = (tokenMetadata?: FlowTokenMetadata) => (
    <ReceiveTokenHeader
      onClose={onClose}
      isNftView={isNftView}
      tokenMetadata={tokenMetadata}
    />
  )

  return (
    <BatchQueryGuard
      queries={{
        accountInfos: accountsQuery,
        tokenContracts: supportedTokenContractsQuery,
      }}
      ErrorElement={<TransactionModalError />}
      loadingVariant="centered"
    >
      {({accountInfos, tokenContracts}) =>
        accountInfos && (
          <ImportFlowTokenFlow
            onBack={() => setActiveScreen('details')}
            onClose={onClose}
            accounts={accountInfos}
            accountId={defaultAccountId}
            tokenContracts={tokenContracts}
            renderModalHeader={renderModalHeader}
            renderDetails={() => (
              <ReceiveModalDetailScreen
                {...{
                  blockchain,
                  onClose,
                  defaultAccountId,
                  defaultTokenOptionId,
                  accountInfos,
                  isNftView,
                  renderModalHeader,
                  tokenContracts,
                }}
              />
            )}
            renderSubmit={(utils, submitProps, tokenMetadata) => (
              <ReceiveModalSubmitScreen
                {...{
                  ...utils,
                  onClose,
                  blockchain,
                  accountInfos,
                  submitProps,
                  renderModalHeader,
                  isNftView,
                  tokenMetadata,
                }}
              />
            )}
          />
        )
      }
    </BatchQueryGuard>
  )
}
