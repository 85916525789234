import {Typography, Box, TextField} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import type {FormikHelpers} from 'formik'
import {Formik} from 'formik'
import React from 'react'
import {Trans, useTranslation} from 'react-i18next'
import * as yup from 'yup'

import {logout} from 'src/utils/general'

import type {ProfileName} from '../../appStorage'
import {useCurrentProfileMeta, useRemoveCurrentProfile} from '../../appStorage'
import {
  ModalLayout,
  ModalHeader,
  Modal,
  ModalFooter,
  QueryGuard,
  Alert,
  FooterLayout,
} from '../../components'
import theme from '../../theme/theme'
import {getHasFormError, useVerifyPassword} from '../../utils/form'

type DeleteProfileModalProps = {
  onClose: () => void
}

export default function DeleteProfileModal({onClose}: DeleteProfileModalProps) {
  const classes = useStyles()
  const currentProfileMetaQuery = useCurrentProfileMeta()

  return (
    <Modal
      className={classes.modal}
      onClose={onClose}
      variant="centered"
      dismissable
    >
      <QueryGuard {...currentProfileMetaQuery} loadingVariant="centered">
        {(data) => (
          <DeleteProfileScreen profileName={data.name} onClose={onClose} />
        )}
      </QueryGuard>
    </Modal>
  )
}

const useVerifyVerificationText = (profileName: ProfileName) => {
  const {t} = useTranslation()

  return (
    verificationText: string,
    {setErrors, setSubmitting}: FormikHelpers<Schema>,
  ) => {
    if (verificationText && !(verificationText === profileName)) {
      setErrors({verificationText: t('Incorrect Verification text')})
      setSubmitting(false)
      return false
    }
    return true
  }
}

type Schema = {
  password: string
  verificationText: string
}

const FORM_ID = 'delete-profile'

type DeleteProfileScreenProps = {
  profileName: ProfileName
  onClose: () => void
}

const DeleteProfileScreen = ({
  profileName,
  onClose,
}: DeleteProfileScreenProps) => {
  const classes = useStyles()
  const {t} = useTranslation()
  const verifyPassword = useVerifyPassword()
  const verifyVerificationText = useVerifyVerificationText(profileName)
  const removeProfile = useRemoveCurrentProfile()

  const schema = yup.object().shape({
    password: yup.string().required(t('Password is required.')),
    verificationText: yup
      .string()
      .required(t('Verification text is required.')),
  })

  const onSubmit = async (
    values: Schema,
    formikHelpers: FormikHelpers<Schema>,
  ) => {
    const verificationTextIsVerified = verifyVerificationText(
      values.verificationText,
      formikHelpers,
    )
    const passwordVerified = await verifyPassword(
      values.password,
      formikHelpers,
    )
    if (!verificationTextIsVerified || !passwordVerified) return

    await removeProfile.mutateAsyncSilent()
    logout()
  }

  return (
    <Formik
      initialValues={{password: '', verificationText: ''}}
      validationSchema={schema}
      onSubmit={onSubmit}
    >
      {(formikProps) => (
        <form
          onSubmit={formikProps.handleSubmit}
          noValidate
          id={FORM_ID}
          className={classes.form}
        >
          <ModalLayout
            header={<DeleteProfileModalHeader {...{onClose}} />}
            body={
              <Box p={2} minHeight={350}>
                <Alert
                  severity="error"
                  text={
                    <Trans
                      i18nKey="delete_wallet"
                      values={{wallet: profileName}}
                      t={t}
                      components={{
                        wallet: (
                          <Box
                            component="span"
                            fontWeight="fontWeightMedium"
                            fontStyle="italic"
                            color={theme.palette.error.main}
                          />
                        ),
                      }}
                    />
                  }
                />
                <Box mt={2} />
                <TextField
                  className={classes.textField}
                  value={formikProps.values.password}
                  onChange={formikProps.handleChange<
                    keyof typeof formikProps.values
                  >('password')}
                  label={t('Enter wallet password')}
                  error={getHasFormError(formikProps)('password')}
                  type="password"
                  helperText={
                    getHasFormError(formikProps)('password') &&
                    formikProps.errors.password
                  }
                  variant="outlined"
                  fullWidth
                />
                <TextField
                  className={classes.textField}
                  value={formikProps.values.verificationText}
                  onChange={formikProps.handleChange<
                    keyof typeof formikProps.values
                  >('verificationText')}
                  label={
                    <Trans
                      i18nKey="profile_delete_verify"
                      values={{deleteText: profileName}}
                    />
                  }
                  error={getHasFormError(formikProps)('verificationText')}
                  helperText={
                    getHasFormError(formikProps)('verificationText') &&
                    formikProps.errors.verificationText
                  }
                  variant="outlined"
                  fullWidth
                />
              </Box>
            }
            footer={
              <ModalFooter hasDivider>
                <FooterLayout
                  leftBtnConf={{
                    onClick: onClose,
                    children: t('Close'),
                  }}
                  rightBtnConf={{
                    variant: 'contained',
                    color: 'error',
                    children: t('Delete my wallet'),
                    type: 'submit',
                    disabled: removeProfile.isPending,
                  }}
                />
              </ModalFooter>
            }
          />
        </form>
      )}
    </Formik>
  )
}

type DeleteProfileModalHeaderProps = {
  onClose: () => void
}

const DeleteProfileModalHeader = ({onClose}: DeleteProfileModalHeaderProps) => {
  const {t} = useTranslation()

  return (
    <ModalHeader onClose={onClose} hasDivider>
      <Typography variant="h6">
        {t('Are you sure you want to remove this wallet?')}
      </Typography>
    </ModalHeader>
  )
}

const useStyles = makeStyles(() => ({
  form: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  modal: {
    width: 550,
    overflow: 'visible',
  },
  textField: {
    minHeight: 70,
  },
}))
