import {KeyboardArrowDown as ArrowDownIcon} from '@mui/icons-material'
import {
  Paper,
  Grid,
  Divider as MuiDivider,
  Box,
  Typography,
  Chip,
  styled,
} from '@mui/material'
import type {SwapAsset} from 'common'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {getExchangeAssetDetailOrFallback} from 'src/features/exchange/application'
import type {
  ExchangeAssetsDetails,
  ExchangeConditions,
} from 'src/features/exchange/domain'

import {StringEllipsis} from '../../../components/visual/atoms'
import {useCryptoProviderTypeNames} from '../../../utils/translations'
import {isHwVendor} from '../../../wallet'
import type {AccountConf} from '../types'

import {FormattedExchangeAmount} from './FormattedExchangeAmount'

type ExchangeCardSummaryProps = {
  fromAsset: SwapAsset
  toAsset: SwapAsset
  exchangeAssetsDetails: ExchangeAssetsDetails
  toAmount: ExchangeConditions['amountTo']
  fromAmount: ExchangeConditions['amountFrom']
  fromConf: AccountConf
  toConf: AccountConf
}

export function ExchangeCardSummary({
  fromAsset,
  toAsset,
  fromAmount: amount,
  fromConf,
  toConf,
  exchangeAssetsDetails,
  toAmount: exchangeAmount,
}: ExchangeCardSummaryProps) {
  const {t} = useTranslation()
  const fromAssetDetails = getExchangeAssetDetailOrFallback(
    fromAsset,
    exchangeAssetsDetails,
  )
  const toAssetDetails = getExchangeAssetDetailOrFallback(
    toAsset,
    exchangeAssetsDetails,
  )

  const _FromAssetIcon = (
    <img src={fromAssetDetails.image} height="24" width="24" />
  )
  const _ToAssetIcon = <img src={toAssetDetails.image} height="24" width="24" />

  const cryptoProviderTypeNames = useCryptoProviderTypeNames()

  return (
    <Wrapper elevation={0}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Box display="flex" alignItems="center">
          {_FromAssetIcon}
          {fromConf.addressType === 'internal' && fromConf.account ? (
            <>
              <AccountName>{fromConf.account.name}</AccountName>
              {isHwVendor(fromConf.account.cryptoProviderType) && (
                <CryptoProviderType>
                  (
                  {cryptoProviderTypeNames[fromConf.account.cryptoProviderType]}
                  )
                </CryptoProviderType>
              )}
            </>
          ) : (
            <>
              <AccountName>{t('External funds')}</AccountName>
              <Typography variant="body2" color="textSecondary">
                &nbsp;({t('Exchange address will be provided')})
              </Typography>
            </>
          )}
          {fromAssetDetails.protocol?.toUpperCase() !==
            fromAssetDetails.ticker.toUpperCase() && (
            <ProtocolChip label={fromAssetDetails.protocol} />
          )}
        </Box>
        <Typography>
          <FormattedExchangeAmount
            amount={amount}
            swapAssetDetail={exchangeAssetsDetails[fromAsset]!}
            includeAssetSymbol
            isSensitiveInformation={false}
          />
        </Typography>
      </Grid>
      <DividerWrapper>
        <ArrowDownIcon />
        <Divider />
      </DividerWrapper>
      <Grid container justifyContent="space-between" alignItems="center">
        <Box display="flex">
          {_ToAssetIcon}
          {toConf.addressType === 'internal' && toConf.account ? (
            <>
              <AccountName>{toConf.account.name}</AccountName>
              {isHwVendor(toConf.account.cryptoProviderType) && (
                <CryptoProviderType>
                  ({cryptoProviderTypeNames[toConf.account.cryptoProviderType]})
                </CryptoProviderType>
              )}
            </>
          ) : (
            <StringEllipsis
              value={toConf.address}
              length={7}
              maxWidth={300}
              ellipsizedTextSx={{ml: 2}}
            />
          )}
          {toAssetDetails.protocol?.toUpperCase() !==
            toAssetDetails.ticker.toUpperCase() && (
            <ProtocolChip label={toAssetDetails.protocol} />
          )}
        </Box>
        <Typography>
          <FormattedExchangeAmount
            amount={exchangeAmount}
            swapAssetDetail={exchangeAssetsDetails[toAsset]!}
            includeAssetSymbol
            isEstimate
            isSensitiveInformation={false}
          />
        </Typography>
      </Grid>
    </Wrapper>
  )
}

const Wrapper = styled(Paper)(({theme}) => ({
  padding: theme.spacing(2),
  border: `1px solid ${theme.palette.divider}`,
  background: theme.palette.background.default,
}))

const AccountName = styled(Typography)(({theme}) => ({
  marginLeft: theme.spacing(2),
}))

AccountName.defaultProps = {
  variant: 'body2',
}

const DividerWrapper = styled('div')(({theme}) => ({
  padding: theme.spacing(1, 0),
  display: 'flex',
  alignItems: 'center',
}))

const Divider = styled(MuiDivider)(({theme}) => ({
  padding: theme.spacing(1, 0),
  display: 'flex',
  alignItems: 'center',
}))

const ProtocolChip = styled(Chip)(({theme}) => ({
  marginLeft: theme.spacing(1),
}))

ProtocolChip.defaultProps = {
  color: 'primary',
  variant: 'outlined',
  size: 'small',
}

const CryptoProviderType = styled(Typography)(({theme}) => ({
  marginLeft: theme.spacing(1),
}))

CryptoProviderType.defaultProps = {
  variant: 'body2',
  color: 'textSecondary',
}
