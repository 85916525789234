import type {ButtonProps} from '@mui/material'
import {Box, Grid, Typography, useTheme, styled} from '@mui/material'
import {safeAssertUnreachable} from '@nufi/frontend-common'
import {objectEntries, objectKeys} from 'common/src/typeUtils'
import React, {useState} from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'

import {
  SideBarLayout,
  CloseButton,
  NavigateBack,
  Button,
  Alert,
} from '../../../../components'
import {routeTo} from '../../../../router'
import {useAddAccountRouteOptions} from '../../../../router/portfolio'
import type {
  AllAvailableAccountsResponse,
  Blockchain,
  CanNotAddAccountReason as CanNotAddAccountReasonType,
  CryptoProviderType,
  DerivationPathType,
} from '../../../../types'
import {
  useGetBlockchainName,
  useCryptoProviderTypeNames,
} from '../../../../utils/translations'
import {isHotVendor} from '../../../../wallet'

import {AddHotAccountTimeline, PairHardwareAccountTimeline} from './Timelines'

type AddAccountLayoutProps = {
  children: JSX.Element
  leftContent?: JSX.Element
  disableBack?: boolean
  onBack?: () => unknown
  maxWidth?: number
  minWidth?: number
}

export const AddAccountLayout = ({
  children,
  onBack,
  leftContent,
  maxWidth,
  minWidth,
  disableBack,
}: AddAccountLayoutProps) => {
  const theme = useTheme()
  const history = useHistory()
  const onClose = () => history.push(routeTo.portfolio.accounts.index)
  onBack = onBack || (() => history.goBack())

  return (
    <SideBarLayout
      background={theme.palette.background.default}
      left={leftContent}
      right={
        <>
          <CloseButtonWrapper>
            <CloseButton onClose={onClose} />
          </CloseButtonWrapper>
          <Grid
            container
            direction="column"
            sx={getWrapperStyles({maxWidth, minWidth})}
          >
            {children}
            {!disableBack && (
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                sx={{mt: 4}}
              >
                <NavigateBack onBack={onBack} />
              </Grid>
            )}
          </Grid>
        </>
      }
    />
  )
}

export const SectionHeader = ({children}: {children: string}) => (
  <Typography variant="h5">{children}</Typography>
)

export const SectionMessage = ({
  children,
  hasMarginBottom = true,
}: {
  children: string
  hasMarginBottom?: boolean
}) => (
  <Box mt={1} mb={hasMarginBottom ? 4 : 0}>
    <Typography color="textSecondary">{children}</Typography>
  </Box>
)

type SectionNextButtonProps = {
  onClick?: () => unknown
  label: string
  disabled?: boolean
  loading?: boolean
  variant?: ButtonProps['variant']
}

export const SectionNextButton = ({
  label,
  onClick,
  disabled,
  loading,
  variant,
}: SectionNextButtonProps) => (
  <Box mt={4}>
    <Button
      textTransform="none"
      fullWidth
      type={onClick ? 'button' : 'submit'}
      variant={variant || 'contained'}
      color="primary"
      {...{onClick, disabled, loading}}
    >
      {label}
    </Button>
  </Box>
)

export function AccountCreatedPage() {
  const {t} = useTranslation()
  const history = useHistory()
  const {cryptoProviderType} = useAddAccountRouteOptions()

  const onSubmit = () => history.push(routeTo.portfolio.accounts.index)

  const TimelineComponent = isHotVendor(cryptoProviderType)
    ? AddHotAccountTimeline
    : PairHardwareAccountTimeline

  return (
    <AddAccountLayout
      leftContent={<TimelineComponent step="done" />}
      disableBack
    >
      <Box>
        <SectionHeader>
          {t('Congratulations! The accounts have been successfully added.')}
        </SectionHeader>

        <SectionMessage>
          {t('Done! Your account is now ready to use.')}
        </SectionMessage>

        <SectionNextButton
          onClick={onSubmit}
          label={t('Start using accounts')}
        />
      </Box>
    </AddAccountLayout>
  )
}

type CanNotAddAccountViewProps = {
  reason: React.ReactElement
  shouldShowCloseButton?: boolean
}

export function CanNotAddAccountView({
  reason,
  shouldShowCloseButton = true,
}: CanNotAddAccountViewProps) {
  const history = useHistory()
  const onClose = () => history.push(routeTo.portfolio.accounts.index)

  const {t} = useTranslation()
  return (
    <Grid container justifyContent="center" alignItems="center" spacing={1}>
      <Grid item xs={12}>
        <SectionHeader>{t('Cannot create another account')}</SectionHeader>
      </Grid>
      {reason && (
        <Grid item xs={12}>
          <Box mt={2}>
            <Alert severity="warning">{reason}</Alert>
          </Box>
        </Grid>
      )}
      {shouldShowCloseButton && (
        <Grid item xs={12}>
          <SectionNextButton
            onClick={onClose}
            label={t('Close')}
            variant="outlined"
          />
        </Grid>
      )}
    </Grid>
  )
}

type FormattedAccountUnavailabilityReasonProps = {
  reason: CanNotAddAccountReasonType
  blockchain: Blockchain
  cryptoProviderType: CryptoProviderType
}

function FormattedAccountUnavailabilityReason({
  reason,
  blockchain,
  cryptoProviderType,
}: FormattedAccountUnavailabilityReasonProps) {
  const blockchainName = useGetBlockchainName()(blockchain)
  const cryptoProviderTypeName =
    useCryptoProviderTypeNames()[cryptoProviderType]

  return (
    <>
      {reason === 'max-limit' ? (
        <Trans
          i18nKey="maximum_number_of_accounts"
          values={{blockchainName, cryptoProviderTypeName}}
        />
      ) : reason === 'previous-unused' ? (
        <Trans
          i18nKey="previous_account_unused"
          values={{blockchainName, cryptoProviderTypeName}}
        />
      ) : (
        ('' as never)
      )}
    </>
  )
}

export function CanNotAddAccountReason(
  discoveredAccountsQueryResponse: AllAvailableAccountsResponse | undefined,
  blockchain: Blockchain,
  cryptoProviderType: CryptoProviderType,
) {
  return discoveredAccountsQueryResponse &&
    'canNotAddAccountReason' in discoveredAccountsQueryResponse ? (
    <CanNotAddAccountView
      reason={
        <FormattedAccountUnavailabilityReason
          reason={discoveredAccountsQueryResponse.canNotAddAccountReason}
          blockchain={blockchain}
          cryptoProviderType={cryptoProviderType}
        />
      }
      shouldShowCloseButton={false}
    />
  ) : null
}

export type AddHwAccountPhase = 'choose-account' | 'confirm-account'

export const useHotAccountPhase = () =>
  useState<AddHwAccountPhase>('choose-account')

export const useAddHwAccountPhase = () =>
  useState<AddHwAccountPhase>('choose-account')

export type DerivationPathTypeConfig<TDerivationPathType extends string> = {
  [T in TDerivationPathType]: {
    label: string
    description: string
    disabled?: boolean
  }
}

export type DerivationPathTypeState<T extends DerivationPathType> = {
  derivationPathType: T
  setDerivationPathType: (derivationPathType: T) => void
  derivationPathTypeConfig: DerivationPathTypeConfig<T>
}

// Serves more as a wrapper (rather than state) for DerivationPathTypeState
// so it can be passed around more easily. `derivationPathTypeToLabel` is also
// included as it needs to be defined/passed in from the blockchain specific
// components.
export const useDerivationPathTypeState = <T extends DerivationPathType>(
  defaultValue: T,
  derivationPathTypeConfig: DerivationPathTypeConfig<T>,
): DerivationPathTypeState<T> => {
  const [derivationPathType, setDerivationPathType] = useState<T>(defaultValue)

  return {
    derivationPathType,
    setDerivationPathType,
    derivationPathTypeConfig,
  }
}

type DerivationPathTypeButtonsProps<T extends DerivationPathType> = {
  resetSelectedAccounts: () => void
  isLoading: boolean
  derivationPathType: T
  setDerivationPathType: (derivationPathType: T) => void
  derivationPathTypeConfig: DerivationPathTypeConfig<T>
}

export function DerivationPathTypeButtons<T extends DerivationPathType>({
  resetSelectedAccounts,
  isLoading,
  derivationPathType,
  setDerivationPathType,
  derivationPathTypeConfig,
}: DerivationPathTypeButtonsProps<T>) {
  const onChangeDerivationPathType = setDerivationPathType
    ? (derivationPathType: T) => {
        setDerivationPathType(derivationPathType)
        resetSelectedAccounts()
      }
    : () => null

  const getBtnVariant = (_derivationType: T) =>
    derivationPathType === _derivationType ? 'contained' : 'outlined'

  return (
    <>
      {objectKeys(derivationPathTypeConfig).length > 1 &&
        objectEntries(derivationPathTypeConfig).map(
          ([variant, {label, description, disabled}]) => (
            <DerivationPathButton
              key={variant}
              color="primary"
              textTransform="none"
              disableElevation
              disableRipple
              disableFocusRipple
              variant={getBtnVariant(variant as T)}
              disabled={isLoading || disabled}
              onClick={() => onChangeDerivationPathType(variant as T)}
            >
              <Grid container direction="column">
                <Grid item>{label}</Grid>
                <Grid item>
                  <Typography fontSize="small">{description}</Typography>
                </Grid>
              </Grid>
            </DerivationPathButton>
          ),
        )}
    </>
  )
}

type ChooseHotAccountLayoutProps = {
  addAccountPhase: AddHwAccountPhase
  setAddAccountPhase: (addAccountPhase: AddHwAccountPhase) => void
  ChooseAccountPhaseContent: JSX.Element
  ConfirmationPhaseContent: JSX.Element
}

export function ChooseHotAccountLayout({
  addAccountPhase,
  setAddAccountPhase,
  ChooseAccountPhaseContent,
  ConfirmationPhaseContent,
}: ChooseHotAccountLayoutProps) {
  switch (addAccountPhase) {
    case 'choose-account':
      return (
        <AddAccountLayout
          leftContent={<AddHotAccountTimeline step="choose-account" />}
        >
          {ChooseAccountPhaseContent}
        </AddAccountLayout>
      )
    case 'confirm-account':
      return (
        <AddAccountLayout
          leftContent={<AddHotAccountTimeline step="name" />}
          onBack={() => setAddAccountPhase('choose-account')}
        >
          {ConfirmationPhaseContent}
        </AddAccountLayout>
      )
    default:
      return safeAssertUnreachable(addAccountPhase)
  }
}

const CloseButtonWrapper = styled('div')({
  position: 'fixed',
  top: 20,
  right: 20,
})

const DerivationPathButton = styled(Button)(
  ({
    theme: {
      shape: {borderRadius},
    },
  }) => ({
    borderRadius: 0,
    '&:first-child': {
      borderRadius,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    '&:last-child': {
      borderRadius,
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
  }),
)

const getWrapperStyles = ({
  maxWidth,
  minWidth,
}: {
  maxWidth?: number
  minWidth?: number
}) =>
  maxWidth || minWidth
    ? {
        maxWidth: maxWidth || 550,
        minWidth: minWidth || 0,
      }
    : {width: 550}
