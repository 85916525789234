import {Box, Divider, Typography} from '@mui/material'
import {keyBy} from 'lodash'
import React, {useEffect, useRef} from 'react'
import {useTranslation} from 'react-i18next'

import {InlineLoading, QueryGuard} from '../../../../components'
import type {
  Blockchain,
  AccountInfo,
  AccountId,
  AllAvailableAccountsSuccessResponse,
} from '../../../../types'
import {useGetAccounts} from '../../../../wallet'
import {findAccountById} from '../../../../wallet/utils/common'

import {AccountListItem, AccountList, AccountsListLoading} from './common'

type DiscoveredAccountsListProps = {
  accountDiscoveryResponse?: AllAvailableAccountsSuccessResponse
  blockchain: Blockchain
  selectedAccountIds: AccountId[] | null
  setSelectedAccountId: (id: AccountId | null) => void
  CustomLoadingAccountsContent?: React.ReactElement
}

export const DiscoveredAccountsList = ({
  accountDiscoveryResponse,
  blockchain,
  selectedAccountIds,
  setSelectedAccountId,
  CustomLoadingAccountsContent,
}: DiscoveredAccountsListProps) => {
  const {t} = useTranslation()
  const accountsQuery = useGetAccounts(blockchain)

  const onSelectedAccountChange = (id: AccountId | null) => {
    setSelectedAccountId(id)
  }

  usePreselectAccount({
    selectedAccountIds,
    setSelectedAccountId,
    discoveredAccounts: accountDiscoveryResponse,
    existingAccounts: accountsQuery.data,
  })

  return (
    <QueryGuard {...accountsQuery}>
      {(existingAccounts) => {
        if (!accountDiscoveryResponse && CustomLoadingAccountsContent) {
          return CustomLoadingAccountsContent
        }

        const commonProps = {
          blockchain,
          selectedAccountIds,
          onSelectedAccountChange,
          existingAccounts,
        }

        return accountDiscoveryResponse ? (
          <AccountList data-test-id="discovered-accounts-list">
            <AccountsList
              discoveredAccounts={accountDiscoveryResponse.accounts}
              {...commonProps}
            />
            {accountDiscoveryResponse.otherAccounts &&
              accountDiscoveryResponse.otherAccounts.length > 0 && (
                <>
                  <Divider />
                  <Box pl={2}>
                    <Typography variant="overline">
                      {t('Other accounts')}
                    </Typography>
                  </Box>
                  <AccountsList
                    discoveredAccounts={accountDiscoveryResponse.otherAccounts}
                    {...commonProps}
                  />
                </>
              )}
          </AccountList>
        ) : (
          <AccountList>
            <AccountsListLoading>
              <InlineLoading color="inherit" />
              <Typography mt={1}>{t('Loading accounts...')}</Typography>
            </AccountsListLoading>
          </AccountList>
        )
      }}
    </QueryGuard>
  )
}

function AccountsList({
  blockchain,
  discoveredAccounts,
  selectedAccountIds,
  existingAccounts,
  onSelectedAccountChange,
}: {
  blockchain: Blockchain
  discoveredAccounts: AccountInfo[]
  selectedAccountIds: AccountId[] | null
  existingAccounts: AccountInfo[]
  onSelectedAccountChange: (id: AccountId) => void
}) {
  const accountsById = keyBy(existingAccounts, 'id')
  const existingAccountsById = discoveredAccounts.map((account) => ({
    ...account,
    name: accountsById[account.id]?.name,
  }))

  return (
    <>
      {existingAccountsById.map((account) => {
        const isSelected = !!selectedAccountIds?.includes(account.id)
        const accountExists = !!findAccountById(existingAccounts, account.id)
        return (
          <AccountListItem
            button
            key={account.id}
            selected={isSelected}
            disabled={accountExists}
            onClick={() => onSelectedAccountChange(account.id)}
            checkBoxProps={{
              displayCheckbox: !accountExists,
              checked: isSelected,
            }}
            blockchain={blockchain}
            address={account.address ?? account.formattedDerivationPath}
            name={accountExists ? account.name : undefined}
            balance={account.balance}
          />
        )
      })}
    </>
  )
}

type UsePreselectAccountParams = {
  selectedAccountIds: AccountId[] | null
  setSelectedAccountId: (id: AccountId) => void
  existingAccounts?: AccountInfo[]
  discoveredAccounts?: AllAvailableAccountsSuccessResponse<AccountInfo>
}

function usePreselectAccount({
  selectedAccountIds,
  setSelectedAccountId,
  discoveredAccounts,
  existingAccounts,
}: UsePreselectAccountParams) {
  const preselectedAccount = useRef(false)

  useEffect(() => {
    // Avoid possibly preselecting account multiple times,
    // e.g. due to a reference change caused by some unexpected
    // refetch, which could break the UI by always preselecting
    // the same account.
    if (preselectedAccount.current) return

    if (
      selectedAccountIds?.length === 0 &&
      discoveredAccounts != null &&
      existingAccounts != null
    ) {
      for (const account of discoveredAccounts.accounts) {
        if (!existingAccounts.some((a) => a.id === account.id)) {
          setSelectedAccountId(account.id)
          preselectedAccount.current = true
          break
        }
      }
    }
  }, [existingAccounts, discoveredAccounts, selectedAccountIds])
}
