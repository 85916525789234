import {Box, Grid, Typography, Divider} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import type {FlowAccountInfo, FlowNodeInfo} from '@nufi/wallet-flow'
import {FLOW_VALIDATORS} from '@nufi/wallet-flow'
import type {FormikProps} from 'formik'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {useGetNodeIdsToNames} from 'src/wallet/flow/application'

import type {StakingBlockchain} from '../../../../../blockchainTypes'
import {
  AccountSelectField,
  FooterLayout,
  ModalFooter,
  ModalLayout,
  useModalSharedStyles,
  ValidatorSelect,
} from '../../../../../components'
import config from '../../../../../config'
import type {AccountId} from '../../../../../types'
import type {SelectChangeFn} from '../../../../../utils/form'
import {getHasFormError} from '../../../../../utils/form'
import {ensureAccountById} from '../../../../../wallet/utils/common'
import {StakeModalHeader} from '../../../common/utils'
import type {MaxAmountOptions} from '../../types'
import {AmountField, FormattedValidatorOption} from '../../utils'
import {findDelegation} from '../utils'

import type {RegisterDelegatorDetailSchema} from './schema'
import type {ValidatorOption} from './types'
import {FormattedValidatorInfo} from './ValidatorInfo'

const FORM_ID = 'register-delegator-details-form'

type DetailsScreenProps<T extends RegisterDelegatorDetailSchema> = {
  blockchain: StakingBlockchain
  accounts: Array<FlowAccountInfo>
  validators: FlowNodeInfo[]
  formikProps: FormikProps<T>
  disabled?: boolean
  onClose: () => void
  maxAmountOptions?: MaxAmountOptions
}

export const RenderOption = (validatorOption: ValidatorOption) => (
  <FormattedValidatorOption {...{validatorOption}} />
)

export function getRecommendedValidatorId(validators: FlowNodeInfo[]) {
  const validatorId = FLOW_VALIDATORS[
    config.flowNetwork
  ] as ValidatorOption['id']
  return validatorId || validators[0]!.nodeId
}

function useValidatorOptions(validators: FlowNodeInfo[]) {
  const {data} = useGetNodeIdsToNames()
  const validatorOptions: ValidatorOption[] = validators.map((validator) => ({
    id: validator.nodeId,
    name: data ? data[validator.nodeId] : undefined,
    role: validator.role,
  }))
  return validatorOptions
}

function getRecommendedValidatorOption(
  id: ValidatorOption['id'],
  validators: ValidatorOption[],
) {
  return validators.find((v) => v.id === id) || validators[0]!
}

export default function DetailsScreen<T extends RegisterDelegatorDetailSchema>({
  accounts,
  disabled,
  formikProps,
  blockchain,
  onClose,
  validators,
  maxAmountOptions,
}: DetailsScreenProps<T>) {
  const classes = {...useStyles(), ...useModalSharedStyles()}
  const {t} = useTranslation()
  const validatorOptions = useValidatorOptions(validators)

  const {values, handleChange, errors, handleSubmit} = formikProps

  // TODO: remove this, here just for inconsistency (id vs accountId)
  const accountItems = accounts.map((a) => ({
    accountId: a.id,
    ...a,
  }))

  const validator = validatorOptions.find((v) => v.id === values.validatorId)

  const getOptionLabel = ({name, id}: ValidatorOption) => `${name || id}`

  // option is always valid since we let user choose only from valid options
  const getOptionValid = () => true

  const hasError = getHasFormError(formikProps)

  type FormField = keyof typeof values

  const recommendedValidatorId = getRecommendedValidatorId(validators)

  return (
    <form onSubmit={handleSubmit} id={FORM_ID} className={classes.formWrapper}>
      <ModalLayout
        header={<StakeModalHeader {...{onClose, blockchain}} />}
        body={
          <Box className={classes.contentWrapper}>
            <Box p={2}>
              <Grid container>
                <Grid item xs={12}>
                  <AccountSelectField
                    className={classes.formField}
                    label={t('Account')}
                    value={values.accountId}
                    onChange={
                      handleChange<FormField>(
                        'accountId',
                      ) as SelectChangeFn<AccountId>
                    }
                    items={accountItems}
                    blockchain={blockchain}
                    showBlockchainIcon
                  />
                </Grid>
                <Grid item xs={12}>
                  <Divider className={classes.divider} />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle2"
                    className={classes.commonBottomMargin}
                  >
                    {t('Amount to stake')}
                  </Typography>
                </Grid>
                <Grid item xs={12} className={classes.formField}>
                  <AmountField
                    formikProps={formikProps}
                    blockchain="flow"
                    maxAmountOptions={maxAmountOptions}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle2"
                    className={classes.commonBottomMargin}
                  >
                    {t('Validator')}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Box>
                    <ValidatorSelect
                      getOptionId={(option) => option.id}
                      label={t('Name or NodeId')}
                      labelWhenSelected={t('Selected validator')}
                      getOptionLabel={getOptionLabel}
                      getOptionValid={getOptionValid}
                      options={validatorOptions}
                      recommendedOption={getRecommendedValidatorOption(
                        recommendedValidatorId,
                        validatorOptions,
                      )}
                      recommendedGroupLabel={t('Recommended validator')}
                      otherGroupLabel={t('Other')}
                      getOptionKeyword={(option) =>
                        `${option.name || t('Unknown name')} ${option.id} ${
                          option.role
                        }`
                      }
                      value={validator || null}
                      onChange={(option) => {
                        handleChange<FormField>('validatorId')(option?.id || '')
                      }}
                      renderOption={RenderOption}
                      error={hasError('validatorId')}
                      helperText={
                        hasError('validatorId') && <>{errors.validatorId}</>
                      }
                      createNotFoundOptionFromInput={null}
                    />
                  </Box>
                </Grid>
                {validator && (
                  <Grid item xs={12} className={classes.validatorInfo}>
                    <FormattedValidatorInfo
                      validator={validator}
                      delegation={findDelegation(
                        ensureAccountById(accounts, values.accountId),
                        values.validatorId,
                      )}
                    />
                  </Grid>
                )}
              </Grid>
            </Box>
          </Box>
        }
        footer={
          <ModalFooter hasDivider>
            <FooterLayout
              leftBtnConf={{
                onClick: onClose,
                children: t('Back'),
              }}
              rightBtnConf={{
                children: t('Continue'),
                type: 'submit',
                form: FORM_ID,
                disabled,
              }}
            />
          </ModalFooter>
        }
      />
    </form>
  )
}

const useStyles = makeStyles((theme) => ({
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
  },
  validatorInfo: {
    marginLeft: theme.spacing(0.25),
    marginTop: theme.spacing(2),
  },
}))
