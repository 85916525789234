import type {
  Lamports,
  SolanaAccountInfo,
  SolanaMintAddress,
  SolanaTokenAmount,
  SolanaTokenMetadata,
} from '@nufi/wallet-solana'
import type BigNumber from 'bignumber.js'
import _ from 'lodash'

import {
  formatCSVAmount,
  formatCSVTokenAmount,
} from '../../../../../../utils/formatting'
import type {
  CSVSolanaStakeHistoryRewardEntry,
  CSVSolanaTxHistoryEntry,
} from '../../../../../../wallet/solana/public/direct'
import {
  getRewardHistories,
  getTokenMap,
  getTxHistories,
} from '../../../../../../wallet/solana/public/direct'

import {filterEntriesByDate, formatToUTC, TxSummaryType} from './common'
import type {
  CommonEntryFields,
  CreateCSVRows,
  CSVConstants,
  TxEntry,
} from './common'

const createTokenEntries = (
  tokenEffect: SolanaTokenAmount[],
  tokenMetadata: _.Dictionary<SolanaTokenMetadata>,
  commonEntryFields: CommonEntryFields,
): TxEntry[] =>
  tokenEffect.map(({amount, token}) => {
    const ticker = tokenMetadata?.[token.id]?.ticker
    const decimals = tokenMetadata?.[token.id]?.decimals

    return {
      ...(!amount.isNegative()
        ? {
            type: TxSummaryType.RECEIVED,
            received: amount as Lamports,
          }
        : {
            type: TxSummaryType.SENT,
            sent: amount.abs() as Lamports,
          }),
      currency: ticker ? `${ticker} (${token.id})` : token.id,
      decimals,
      isToken: true,
      ...commonEntryFields,
    }
  })

const createNativeEntry = (
  solEffect: Lamports | null,
  fee: Lamports,
  commonEntryFields: CommonEntryFields,
  tokenEffect: SolanaTokenAmount[],
): TxEntry | null => {
  if (solEffect != null && !solEffect.eq(0)) {
    if (solEffect.isPositive()) {
      return {
        ...commonEntryFields,
        type: TxSummaryType.RECEIVED,
        received: solEffect,
        currency: 'SOL',
        isToken: false,
      }
    }
    const sent = solEffect?.abs().minus(fee) as Lamports
    if (sent.eq(0) && tokenEffect.length > 0) return null
    return {
      ...commonEntryFields,
      type: TxSummaryType.SENT,
      sent,
      currency: 'SOL',
      isToken: false,
    }
  }

  return null
}

/**
 * A token transfer without SOL sent can exist. So we can't just add it to the native entry like
 * on Cardano. Keep in mind that we also don't want to include the same fee to multiple rows
 * (because of accounting reasons).
 */
const addFeeToFirstEntry = (entries: TxEntry[], fee: Lamports): TxEntry[] => {
  const [head, ...tail] = entries
  if (head) {
    return [{...head, fee}, ...tail]
  }
  return []
}

const createTransactionEntries = (
  txHistories: CSVSolanaTxHistoryEntry[][],
  tokensMetadatas: _.Dictionary<SolanaTokenMetadata>,
): TxEntry[] =>
  txHistories.flatMap((transactions) =>
    transactions.flatMap((transaction) => {
      const {
        signature: txHash,
        timeIssued,
        solEffect,
        fee,
        tokenEffects,
        accountName,
        address,
      } = transaction
      const commonEntryFields = {
        accountName,
        txHash,
        timeIssued,
        address,
      }
      const native = createNativeEntry(
        solEffect,
        fee,
        commonEntryFields,
        tokenEffects,
      )
      const tokens = createTokenEntries(
        tokenEffects,
        tokensMetadatas,
        commonEntryFields,
      )
      const combined = native != null ? [native, ...tokens] : tokens
      const shouldOmitFee = combined.every(
        (entry) => entry.type === TxSummaryType.RECEIVED,
      )

      return shouldOmitFee ? combined : addFeeToFirstEntry(combined, fee)
    }),
  )

const createRewardsEntries = (
  rewardHistories: CSVSolanaStakeHistoryRewardEntry[][],
) =>
  rewardHistories.flat().map((reward: CSVSolanaStakeHistoryRewardEntry) => ({
    type: TxSummaryType.REWARD_AWARDED,
    timeIssued: reward.timeIssued,
    received: reward.amount,
    currency: 'SOL',
    accountName: reward.accountName,
    isToken: false,
    address: reward.address,
  }))

/**
 * Exported for testing purposes only, call createCSVRows instead
 */
export const _createCSVRows = async (
  csvConstants: CSVConstants,
  startDate: Date,
  endDate: Date,
  txHistoriesPerAccount: CSVSolanaTxHistoryEntry[][],
  rewardHistoriesPerAccount: CSVSolanaStakeHistoryRewardEntry[][],
  tokensMetadatas: Record<SolanaMintAddress, SolanaTokenMetadata>,
) => {
  const filteredTxHistoriesPerAccount = filterEntriesByDate(
    txHistoriesPerAccount,
    startDate,
    endDate,
  )

  const filteredRewardHistoriesPerAccount = filterEntriesByDate(
    rewardHistoriesPerAccount,
    startDate,
    endDate,
  )

  const {delimiter} = csvConstants
  const transactionsEntries: TxEntry[] = createTransactionEntries(
    filteredTxHistoriesPerAccount,
    tokensMetadatas,
  )
  const rewardsEntries = createRewardsEntries(filteredRewardHistoriesPerAccount)
  const entries: Array<
    Omit<TxEntry, 'timeIssued'> & {timeIssued: Date | null}
  > = _.sortBy(
    [...transactionsEntries, ...rewardsEntries],
    (entry) => entry.timeIssued?.getTime() ?? 0,
  )

  return entries.map((entry) => {
    const printAmount = (num: Lamports | BigNumber) =>
      entry.isToken
        ? formatCSVTokenAmount(num, entry.decimals || 0)
        : formatCSVAmount(num, 'solana')
    return {
      date: entry.timeIssued,
      row: [
        entry.timeIssued ? formatToUTC(entry.timeIssued) : null,
        entry.txHash,
        entry.address,
        entry.accountName,
        entry.type,
        entry.received && printAmount(entry.received),
        entry.received !== undefined ? entry.currency : undefined,
        entry.sent && printAmount(entry.sent),
        entry.sent !== undefined ? entry.currency : undefined,
        entry.fee && formatCSVAmount(entry.fee, 'solana'),
        entry.fee !== undefined ? 'SOL' : undefined,
      ]
        .map((value) =>
          value === undefined ? delimiter : `${value}${delimiter}`,
        )
        .join(''),
    }
  })
}

export const createCSVRows: CreateCSVRows<SolanaAccountInfo> = async (
  csvConstants: CSVConstants,
  accounts: SolanaAccountInfo[],
  startDate: Date,
  endDate: Date,
) => {
  const accountIds = accounts.map(({id}) => id)
  if (accountIds.length === 0) return []

  const txHistoriesPerAccount = await getTxHistories(accounts, startDate)
  const tokensMetadatas = await getTokenMap()

  const rewardHistoriesPerAccount = await getRewardHistories(
    accounts,
    startDate,
  )

  return _createCSVRows(
    csvConstants,
    startDate,
    endDate,
    txHistoriesPerAccount,
    rewardHistoriesPerAccount,
    tokensMetadatas,
  )
}
