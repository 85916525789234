import {Search as SearchIcon, Clear as ClearIcon} from '@mui/icons-material'
import type {MenuItemProps} from '@mui/material'
import {
  Box,
  InputAdornment,
  IconButton,
  MenuItem,
  TextField,
  Typography,
  Grid,
  Divider,
  styled,
} from '@mui/material'
import Fuse from 'fuse.js'
import type {CSSProperties} from 'react'
import React, {useState, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import AutoSizer from 'react-virtualized-auto-sizer'
import {FixedSizeList as VirtualizedList} from 'react-window'

import {useModalSharedStyles} from '../Modal'
import type {ChipItemProps} from '../molecules'
import {ScrollableChipContainer} from '../molecules'

export type SelectableListProps<TOption> = {
  fuseOptions: Fuse.IFuseOptions<TOption>
  onItemClick: (
    e: React.MouseEvent<HTMLLIElement, MouseEvent>,
    option: TOption,
  ) => void
  getOptionUniqueKey: (item: TOption) => string
  options: TOption[]
  selectedOptions: TOption[]
  searchPlaceholderText: string
  searchNoResultsText: string
  noSelectedItemsText: string
  selectedTokensListTestId?: string
  getMenuItemProps?: (item: TOption) => MenuItemProps
  getSelectedChipProps: (selected: TOption) => ChipItemProps
  itemHeight: number
  renderItemRightContent: (item: TOption) => React.ReactNode
  renderItemLeftContent: (item: TOption) => React.ReactNode
  itemListLegend?: {
    left: string
    right: string
  }
}

export function SelectableList<TOption>({
  fuseOptions,
  onItemClick,
  options,
  selectedOptions,
  getSelectedChipProps,
  getOptionUniqueKey,
  itemHeight,
  renderItemLeftContent,
  renderItemRightContent,
  getMenuItemProps,
  searchPlaceholderText,
  itemListLegend,
  searchNoResultsText,
  noSelectedItemsText,
  selectedTokensListTestId,
}: SelectableListProps<TOption>) {
  const {t} = useTranslation()
  const classes = useModalSharedStyles()
  const [filterField, setFilterField] = useState('')

  const fuse = useMemo(
    () => new Fuse(options, fuseOptions),
    [options, fuseOptions],
  )

  const filteredOptions =
    filterField === ''
      ? options
      : fuse.search(filterField).map(({item}) => item)

  return (
    <>
      <Grid item className={classes.formField}>
        <TextField
          value={filterField}
          onChange={(e) => setFilterField(e.target.value.toLowerCase())}
          placeholder={searchPlaceholderText}
          variant="outlined"
          inputProps={{
            autoComplete: 'off',
          }}
          fullWidth
          onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
            // MUI auto focuses children inside MENU on keyboard event
            // We need this to prevent triggering autofocus during typing
            e.stopPropagation()
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: filterField && (
              <InputAdornment position="end">
                <IconButton size="small" onClick={() => setFilterField('')}>
                  <ClearIcon fontSize="small" />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item>
        <Typography variant="subtitle2">{t('Selected')}:</Typography>
      </Grid>
      <Grid item className={classes.commonBottomMargin}>
        <ScrollableChipContainer testId={selectedTokensListTestId}>
          {(ChipElement) => (
            <>
              {selectedOptions.length ? (
                selectedOptions.map((selectedOption) => (
                  <ChipElement
                    key={getOptionUniqueKey(selectedOption)}
                    {...getSelectedChipProps(selectedOption)}
                  />
                ))
              ) : (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  minHeight="inherit"
                >
                  <Typography variant="body2" color="textSecondary">
                    {noSelectedItemsText}
                  </Typography>
                </Box>
              )}
            </>
          )}
        </ScrollableChipContainer>
      </Grid>
      <Grid item className={classes.divider}>
        <Divider />
      </Grid>
      <Grid item className={classes.formField} xs={12} minHeight={250}>
        {itemListLegend && (
          <Box
            width="100%"
            display="flex"
            justifyContent="space-between"
            pr={2}
            pb={0.5}
          >
            <Typography variant="subtitle2">{itemListLegend.left}</Typography>
            <Typography variant="subtitle2">{itemListLegend.right}</Typography>
          </Box>
        )}
        {filteredOptions.length ? (
          <ItemList<TOption>
            {...{
              options: filteredOptions,
              onItemClick,
              selectedOptions,
              itemHeight,
              getOptionUniqueKey,
              renderItemLeftContent,
              renderItemRightContent,
              getMenuItemProps,
            }}
          />
        ) : (
          <Box
            height="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Typography variant="h6" component="p">
              {searchNoResultsText}
            </Typography>
          </Box>
        )}
      </Grid>
    </>
  )
}
type ItemListPropsData<TOption> = {
  options: TOption[]
  onItemClick: (
    e: React.MouseEvent<HTMLLIElement, MouseEvent>,
    option: TOption,
  ) => void
  getMenuItemProps?: (item: TOption) => MenuItemProps
  itemHeight: number
  getOptionUniqueKey: (item: TOption) => string
  renderItemRightContent: (item: TOption) => React.ReactNode
  renderItemLeftContent: (item: TOption) => React.ReactNode
}

type VirtualizedRowProps<TOption> = {
  index: number
  style: CSSProperties
  data: ItemListPropsData<TOption>
}

function ItemList<TOption>(props: ItemListPropsData<TOption>) {
  return (
    <AutoSizer>
      {({height, width}) => (
        <VirtualizedList
          itemData={{...props}}
          itemCount={props.options.length}
          itemSize={props.itemHeight}
          height={height}
          width={width}
        >
          {React.memo(({index, style, data}: VirtualizedRowProps<TOption>) => {
            const {
              options,
              onItemClick,
              getMenuItemProps,
              getOptionUniqueKey,
              renderItemLeftContent,
              renderItemRightContent,
            } = data
            const option = options[index]!
            return (
              <Item
                key={getOptionUniqueKey(option)}
                onItemClick={(e) => onItemClick(e, option)}
                renderRightContent={() => renderItemRightContent(option)}
                renderLeftContent={() => renderItemLeftContent(option)}
                style={style}
                {...getMenuItemProps?.(option)}
              />
            )
          })}
        </VirtualizedList>
      )}
    </AutoSizer>
  )
}

type ItemProps = {
  onItemClick: (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => void
  style: CSSProperties
  renderRightContent: () => React.ReactNode
  renderLeftContent: () => React.ReactNode
} & MenuItemProps

const Item = ({
  onItemClick,
  renderRightContent,
  renderLeftContent,
  ...rest
}: ItemProps) => {
  return (
    <MenuItem {...rest} onClick={(e) => onItemClick(e)}>
      <ItemContentWrapper>
        <ItemLeftContentBox>{renderLeftContent()}</ItemLeftContentBox>
        <ItemRightContentBox>{renderRightContent()}</ItemRightContentBox>
      </ItemContentWrapper>
    </MenuItem>
  )
}

const ItemContentWrapper = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
})

const ItemLeftContentBox = styled('div')({
  width: '45%',
  overflow: 'hidden',
})
const ItemRightContentBox = styled('div')({
  width: '45%',
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  textAlign: 'right',
  justifyContent: 'right',
})
