import React, {useContext} from 'react'

import type {AppOpenerViewModel} from 'src/features/appOpener/application'

type AppOpenerContext = {
  vm: AppOpenerViewModel
}

const AppOpenerViewModelContext = React.createContext<AppOpenerContext>(
  {} as AppOpenerContext,
)

export const AppOpenerProvider = ({
  children,
  vm,
}: {
  children: React.ReactElement
  vm: AppOpenerViewModel
}) => {
  return (
    <AppOpenerViewModelContext.Provider value={{vm}}>
      {children}
    </AppOpenerViewModelContext.Provider>
  )
}

export const useAppOpenerProvider = () => useContext(AppOpenerViewModelContext)
