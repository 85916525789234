/* eslint-disable @typescript-eslint/explicit-function-return-type */
import type {Blockchain} from 'src/wallet/types'

const P = 'cross_blockchain'

export const crossBlockchainQueryKeys = {
  index: [P],
  balancesInfo: (enabledBlockchains: readonly Blockchain[]) => [
    P,
    enabledBlockchains,
    'balancesInfo',
  ],
  allTokensMetadata: (enabledBlockchains: readonly Blockchain[]) => [
    P,
    enabledBlockchains,
    'allTokensMetadata',
  ],
  allTokensBalances: (enabledBlockchains: readonly Blockchain[]) => [
    P,
    enabledBlockchains,
    'allTokensBalances',
  ],
}
