/* eslint-disable @typescript-eslint/explicit-function-return-type */

import {arraySum} from '@nufi/wallet-common'
import type {FlowTokenId, FlowTxHistoryEntry} from '@nufi/wallet-flow'
import type {QueryKey} from '@tanstack/react-query'
import {useInfiniteQuery} from '@tanstack/react-query'
import {useMemo} from 'react'

import {useNeverStaleQuery} from 'src/utils/query-utils'

import type {AccountId} from '../../../types'
import {flow} from '../../flowManagers'
import {QUERY_KEY_PREFIX as P} from '../utils'

import {FLOW_TX_HISTORY_PAGE_SIZE} from './constants'
import {cachedGetAccounts, cachedGetTotalNativeBalance} from './core'
import {cachedGetNft, cachedGetTokenBalancesPerAccount} from './tokens'

export const combinedQueryKeys = {
  txHistory: {
    index: [P, 'txHistory'],
    account: (accountId: AccountId) => ({
      index: [...combinedQueryKeys.txHistory.index, accountId],
      token: {
        index: (tokenId: FlowTokenId | null) => [
          ...combinedQueryKeys.txHistory.account(accountId).index,
          tokenId,
        ],
        transfers: (tokenId: FlowTokenId | null) => [
          ...combinedQueryKeys.txHistory.account(accountId).index,
          'transfers',
          tokenId,
        ],
      },
      nftTransfers: [
        ...combinedQueryKeys.txHistory.index,
        accountId,
        'nftTransfers',
      ],
      transactions: [
        ...combinedQueryKeys.txHistory.index,
        accountId,
        'transactions',
      ],
    }),
  },
  balance: {
    index: [P, 'balance'],
    total: (tokenId: FlowTokenId | null) => [
      ...combinedQueryKeys.balance.index,
      'total',
      tokenId,
    ],
    perAccount: (tokenId: FlowTokenId | null) => [
      ...combinedQueryKeys.balance.index,
      'perAccount',
      tokenId,
    ],
  },
} as const

export function useGetTotalBalance(
  tokenId: FlowTokenId | null,
  enabled = true,
) {
  return useNeverStaleQuery({
    queryKey: combinedQueryKeys.balance.total(tokenId),
    queryFn: async () => {
      if (tokenId == null) {
        return await cachedGetTotalNativeBalance()
      } else {
        const tokenBalancesPerAccount =
          await cachedGetTokenBalancesPerAccount(tokenId)
        return arraySum(tokenBalancesPerAccount.map(({balance}) => balance))
      }
    },
    enabled,
  })
}

export function useGetBalancesPerAccount(
  tokenId: FlowTokenId | null,
  enabled = true,
) {
  return useNeverStaleQuery({
    queryKey: combinedQueryKeys.balance.perAccount(tokenId),
    queryFn: async () => {
      if (tokenId == null) {
        const accounts = await cachedGetAccounts()
        return accounts.map((a) => ({
          accountId: a.id,
          balance: a.balance,
        }))
      } else {
        return await cachedGetTokenBalancesPerAccount(tokenId)
      }
    },
    enabled,
  })
}

function flattenTxHistoryQueryPages(
  queryResult: ReturnType<
    typeof useInfiniteQuery<{
      txHistory: FlowTxHistoryEntry[]
    }>
  >,
) {
  return {
    ...queryResult,
    data: queryResult.data?.pages.flatMap((p) => p.txHistory),
  }
}

const useGetPaginatedHistoryQuery = (
  accountId: AccountId,
  tokenId: FlowTokenId | null,
  queryKey: QueryKey,
  pageSize: number,
  enabled: boolean,
) => {
  const historyInfiniteQuery = useInfiniteQuery<{
    txHistory: FlowTxHistoryEntry[]
    offset: number | undefined
  }>({
    queryKey,
    queryFn: async ({pageParam: offset = undefined}) => {
      const accountData = flow.accountsStore.getAccount(accountId)

      const nft = await cachedGetNft(tokenId)

      const {txHistory, newOffset} = await flow.accountManager.getTxHistory(
        accountData,
        tokenId,
        !!nft,
        pageSize,
        offset as number | undefined,
      )

      return {
        txHistory,
        offset: txHistory.length === pageSize ? newOffset : undefined,
      }
    },
    staleTime: Infinity,
    enabled,
    initialPageParam: undefined,
    getNextPageParam: ({offset}) => offset,
  })

  const data = useMemo(
    () => flattenTxHistoryQueryPages(historyInfiniteQuery).data,
    [historyInfiniteQuery.data?.pages, accountId],
  )

  return {
    ...historyInfiniteQuery,
    data,
  }
}

export function useGetTransactionHistory(
  accountId: AccountId,
  tokenId: FlowTokenId | null,
  enabled = true,
) {
  return useGetPaginatedHistoryQuery(
    accountId,
    tokenId,
    tokenId == null
      ? combinedQueryKeys.txHistory.account(accountId).index
      : combinedQueryKeys.txHistory.account(accountId).token.index(tokenId),
    FLOW_TX_HISTORY_PAGE_SIZE,
    enabled,
  )
}
