import {Close as CloseIcon} from '@mui/icons-material'
import {
  Typography,
  Box,
  Grid,
  IconButton,
  FormHelperText,
  styled,
} from '@mui/material'
import type BigNumber from 'bignumber.js'
import React from 'react'
import ContentLoader from 'react-content-loader'
import {Trans, useTranslation} from 'react-i18next'

import {NFTBadge, IpfsImage, ellipsizeString} from 'src/components/visual/atoms'
import type {TwoLabelTextFieldProps} from 'src/components/visual/molecules'
import {TwoLabelTextField} from 'src/components/visual/molecules'
import {ContentLoaderBackground} from 'src/utils/layoutUtils'

type NftData =
  | {isNft: true; nftImgSrc: string; collectionName?: string}
  | {isNft?: false}

export type RowEndAdornmentProps =
  | {
      type: 'deleteIcon'
      onDelete?: () => void
    }
  | {
      type: 'custom'
      content: JSX.Element
    }

export type AssetAmountFieldProps = {
  InfoIcon?: React.ReactNode
  className?: string
  assetName?: string
  ticker?: string
  id: string
  renderAssetIcon: (iconSize: number) => JSX.Element
  FormattedBalance: JSX.Element
  rowEndAdornment?: RowEndAdornmentProps
} & (
  | {
      isViewOnly: true
      amount?: BigNumber
      helperText?: React.ReactNode
    }
  | {
      isViewOnly: false
      disabled?: boolean
      onBlur?: React.FocusEventHandler<HTMLInputElement>
      onChange: (value: string) => void
      error: boolean
      value: string
      helperText?: React.ReactNode
      name: string
      FieldProps?: Partial<TwoLabelTextFieldProps>
    }
) &
  NftData

const ASSET_ICON_CONTAINER_WIDTH = 50
const ICON_SIZE = 35

export function AssetAmountField({
  className,
  InfoIcon,
  renderAssetIcon,
  FormattedBalance,
  assetName: _assetName,
  ticker,
  id,
  ...rest
}: AssetAmountFieldProps) {
  const {t} = useTranslation()
  const commonProps = {
    renderAssetIcon,
    iconContainerWidth: ASSET_ICON_CONTAINER_WIDTH,
    iconSize: ICON_SIZE,
    ...rest,
  }

  const ellipsizedIdentifier = ellipsizeString(id, 10, 5)
  const assetName = _assetName || ellipsizedIdentifier

  return (
    <Box className={className}>
      {rest.isViewOnly ? (
        <AssetAmountFieldContainer
          {...commonProps}
          FormattedBalance={FormattedBalance}
          assetName={assetName}
          amount={rest.amount}
          renderNameLabel={
            InfoIcon
              ? (DefaultLabelName) => (
                  <Box display="flex">
                    {InfoIcon}
                    {DefaultLabelName}
                  </Box>
                )
              : undefined
          }
        />
      ) : (
        <AssetAmountFieldContainer
          {...commonProps}
          RightSection={
            <TwoLabelTextField
              {...{
                onBlur: rest.onBlur,
                value: rest.value,
                error: rest.error,
                name: rest.name,
                disabled: rest.disabled,
                ...rest.FieldProps,
              }}
              placeholder={assetName}
              leftLegend={t('asset_amount', {
                ticker: ticker || ellipsizedIdentifier,
              })}
              rightLegend={
                <Box display="flex" gap={0.5}>
                  <Trans
                    i18nKey="asset_balance"
                    t={t}
                    components={{
                      Balance: FormattedBalance,
                    }}
                  />
                </Box>
              }
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                rest.onChange(e.target.value)
              }
              fullWidth
              InputProps={{
                startAdornment: InfoIcon,
                ...rest.FieldProps?.InputProps,
              }}
            />
          }
        />
      )}
      <Box sx={{ml: `${ASSET_ICON_CONTAINER_WIDTH}px`}}>
        <FormHelperText variant="filled" error margin="dense">
          {'helperText' in rest ? rest.helperText || ' ' : ' '}
        </FormHelperText>
      </Box>
    </Box>
  )
}

export const AssetAmountFieldLoader = () => (
  <ContentLoader
    width="100%"
    height="77"
    backgroundColor={ContentLoaderBackground()}
  >
    <circle cx="20" cy="30" r="17" />
    <rect x="45" y="0" rx="4" width="calc(100% - 45px)" height="57" />
  </ContentLoader>
)

type AssetAmountFieldContainerProps = {
  iconContainerWidth?: number
  iconSize?: number
  renderAssetIcon: (iconSize: number) => JSX.Element
  rowEndAdornment?: RowEndAdornmentProps
} & (
  | {
      RightSection: React.ReactNode
    }
  | {
      FormattedBalance: JSX.Element

      assetName: string
      amount?: BigNumber
      renderNameLabel?: (defaultLabelName: JSX.Element) => JSX.Element
    }
) &
  NftData

function AssetAmountFieldContainer({
  iconContainerWidth = 50,
  iconSize = 35,
  renderAssetIcon,
  ...other
}: AssetAmountFieldContainerProps) {
  const ROW_END_ADORNMENT_WIDTH = 45
  const renderDefaultLabelName = (assetName: string) => (
    <AssetAmountFieldLabel
      firstRow={assetName}
      secondRow={other.isNft ? other.collectionName : undefined}
    />
  )
  return (
    <Grid container spacing={1}>
      <Grid
        item
        width={iconContainerWidth}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Box position="relative" display="flex">
          {renderAssetIcon(iconSize)}
          {/* fetching nft image source can take a while and might also fail */}
          {/* we should render the asset icon as fallback */}
          {other.isNft && (
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
              }}
            >
              <IpfsImage src={other.nftImgSrc} size={iconSize} />
            </Box>
          )}
          {other.isNft && (
            <NFTBadge
              sx={{backgroundColor: 'background.paper'}}
              boxProps={{
                sx: {
                  position: 'absolute',
                  bottom: 0,
                  left: '50%',
                  margin: 0,
                  transform: 'translate(-50%, 40%)',
                },
              }}
            />
          )}
        </Box>
      </Grid>
      <Grid
        item
        maxWidth={`calc(100% - ${iconContainerWidth}px)`}
        alignSelf="center"
        justifySelf="flex-end"
        textAlign="right"
        flexWrap="nowrap"
        width="100%"
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          pl={1}
        >
          <Box display="flex" width="100%" alignItems="center">
            <Box
              width={
                other.rowEndAdornment
                  ? `calc(100% - ${ROW_END_ADORNMENT_WIDTH}px)`
                  : '100%'
              }
            >
              {'RightSection' in other ? (
                other.RightSection
              ) : (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  {other.renderNameLabel?.(
                    renderDefaultLabelName(other.assetName),
                  ) || renderDefaultLabelName(other.assetName)}
                  <Box flexShrink={0} pl={1}>
                    {other.FormattedBalance}
                  </Box>
                </Box>
              )}
            </Box>
            {other.rowEndAdornment && (
              <Box width={ROW_END_ADORNMENT_WIDTH} sx={{pl: 1}}>
                {other.rowEndAdornment.type === 'custom' ? (
                  other.rowEndAdornment.content
                ) : (
                  <DeleteButton
                    disabled={!other.rowEndAdornment.onDelete}
                    onClick={other.rowEndAdornment.onDelete}
                  />
                )}
              </Box>
            )}
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}

type AssetAmountFieldLabelProps = {
  firstRow: string
  secondRow?: string
}

function AssetAmountFieldLabel({
  firstRow,
  secondRow,
}: AssetAmountFieldLabelProps) {
  return (
    <Box
      overflow="hidden"
      display="flex"
      flexDirection="column"
      textAlign="left"
    >
      <Typography
        noWrap
        variant={secondRow ? 'body2' : 'body1'}
        title={firstRow}
      >
        {firstRow}
      </Typography>
      {secondRow && (
        <Typography title={secondRow} variant="body2" color="textSecondary">
          {secondRow}
        </Typography>
      )}
    </Box>
  )
}

const DeleteButton = styled(IconButton)({
  '&.Mui-disabled': {
    opacity: 0.5,
  },
})

DeleteButton.defaultProps = {
  children: <CloseIcon fontSize="small" />,
}
