import {Typography, Tabs, Tab, Box} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {useSetRegularLoggedIn} from 'src/features/login'

import {SideBarLayout} from '../../../components'
import {useStepTracking} from '../../../tracking'
import {useCommonProfileStyles} from '../common'
import SuccessPage from '../ProfileCreatedPage'

import RestoreFromBackup from './RestoreFromBackup'
import RestoreFromMnemonic from './RestoreFromMnemonic'

export default function RestoreProfilePage() {
  const commonClasses = useCommonProfileStyles()
  const classes = useStyles()
  const {t} = useTranslation()
  const [showSuccess, setShowSuccess] = useState(false)
  const setLoggedIn = useSetRegularLoggedIn()
  const [tabValue, setTabValue] = useState(0)

  const onSuccess = () => setShowSuccess(true)

  const [shouldDisableBackupTab, setShouldDisableBackupTab] = useState(false)

  const tabs = [
    {
      key: 'recoveryPhrase',
      label: t('Recovery phrase'),
      renderComponent: (
        <RestoreFromMnemonic
          onSuccess={onSuccess}
          onScreenChanged={(screenType) =>
            setShouldDisableBackupTab(screenType !== 'setMnemonic')
          }
        />
      ),
      disabled: false,
    },
    {
      key: 'backupFile',
      label: t('Backup file'),
      renderComponent: <RestoreFromBackup onSuccess={onSuccess} />,
      disabled: shouldDisableBackupTab,
    },
  ]

  useStepTracking(tabs[tabValue]!.key)

  const handleTabChange = (event: unknown, value: number) => {
    setTabValue(value)
  }
  if (showSuccess) {
    return <SuccessPage type="restored" onSubmit={() => setLoggedIn()} />
  }
  return (
    <SideBarLayout
      right={
        <Box className={commonClasses.widerWrapper}>
          <Typography variant="h5">{t('Restore wallet')}</Typography>
          <Typography
            className={commonClasses.headerSubtitle}
            variant="body1"
            color="textSecondary"
          >
            {t(
              'You will be able to restore your wallet by using a recovery phrase.',
            )}
          </Typography>
          <Tabs
            className={classes.tabs}
            value={tabValue}
            onChange={handleTabChange}
            indicatorColor="primary"
          >
            {tabs.map(({key, label, disabled}) => (
              <Tab key={key} label={label} disabled={disabled} />
            ))}
          </Tabs>
          <Box className={classes.tabsContent}>
            {tabs.map(({key, renderComponent}, i) => (
              <TabPanel key={key} value={tabValue} index={i}>
                {renderComponent}
              </TabPanel>
            ))}
          </Box>
        </Box>
      }
      showAnnouncement
    />
  )
}

type TabPanelProps = {
  children: JSX.Element
  value: number
  index: number
}

function TabPanel({children, value, index}: TabPanelProps) {
  const classes = useStyles()

  return (
    <Box
      visibility={value === index ? 'visible' : 'hidden'}
      className={classes.tabPanel}
    >
      {children}
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  tabs: {
    marginTop: theme.spacing(5),
    [theme.breakpoints.down('lg')]: {
      marginTop: theme.spacing(2),
    },
  },
  // Tabs: (avoid unmount & hacks) START
  // We use:
  // visibility: hidden => (to hide component, but do not unmount it)
  // position: absolute & relative => (to avoid offset caused by hidden component)
  // minHeight: to kind of center component with pre-hidden content, without later "jumps" in the UI
  tabsContent: {
    position: 'relative',
    minHeight: 700,
  },
  tabPanel: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
  },
  // Tabs: (avoid unmount & hacks) END
}))
