import {request, HttpRequestError} from '@nufi/frontend-common'

import type {NufiAuthTokenManager} from '../domain'

const retryIfTokenExpired = async <T = unknown>(
  nufiAuthTokenManager: NufiAuthTokenManager,
  requestFn: () => Promise<T>,
) => {
  try {
    return await requestFn()
  } catch (e) {
    if (e instanceof HttpRequestError && e.httpStatus === 401) {
      await nufiAuthTokenManager.loadApiToken()
      return await requestFn()
    }
    throw e
  }
}

export function makeAuthenticatedRequest(
  nufiAuthTokenManager: NufiAuthTokenManager,
) {
  return async <T = unknown>(
    requestParams: Parameters<typeof request>[0],
  ): Promise<T> => {
    if ('Authorization' in (requestParams.headers || {})) {
      throw Error(
        'Authorization header can not be set because it would be overwritten',
      )
    }

    // In order to avoid client time inconsistencies, we rely on server to let us know
    // if token has expired rather than checking for token expiration in client code.
    return await retryIfTokenExpired(
      nufiAuthTokenManager,
      async () =>
        await request({
          ...requestParams,
          headers: {
            ...requestParams.headers,
            Authorization: `Bearer ${nufiAuthTokenManager.getToken()}`,
          },
        }),
    )
  }
}

export type AuthenticatedRequest = ReturnType<typeof makeAuthenticatedRequest>
