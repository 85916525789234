import {TextField, styled} from '@mui/material'
import React from 'react'

import type {Blockchain, AccountInfo} from '../types'

import {
  AccountSelectItemContent,
  outlinedSelectAccountFieldOverrides,
} from './AccountSelectField'

type ReadOnlyAccountFieldProps = {
  value: AccountInfo
  blockchain: Blockchain
  label?: string
  error?: boolean
  helperText?: React.ReactNode
}

export function ReadOnlyAccountField({
  blockchain,
  value,
  label,
  error,
  helperText,
}: ReadOnlyAccountFieldProps) {
  // inputComponent should expose a ref: https://mui.com/material-ui/react-text-field/#integration-with-3rd-party-input-libraries
  const InputComponent = React.forwardRef<
    HTMLElement,
    {className: string | undefined}
  >(function InputComponent(props, ref) {
    return (
      <div {...props} ref={ref as {current: HTMLDivElement | null}}>
        <AccountSelectItemContent
          {...value}
          accountId={value.id}
          blockchain={blockchain}
          showDescription
          showBlockchainIcon
        />
      </div>
    )
  })

  return (
    <ReadOnlyAccountFieldBase
      aria-readonly
      InputProps={{
        inputComponent: ({className, ...rest}) => (
          <InputComponent className={className} {...rest} />
        ),
      }}
      InputLabelProps={{
        shrink: true,
      }}
      value={''}
      fullWidth
      variant="outlined"
      {...{label, helperText, error}}
    />
  )
}

const ReadOnlyAccountFieldBase = styled(TextField)(({theme: {breakpoints}}) =>
  outlinedSelectAccountFieldOverrides(breakpoints),
)
