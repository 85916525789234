/* eslint-disable */
//Slightly modified version of https://github.com/Joe12387/detectIncognito

// Wrapped into function as this fails if imported by service worker
// via related imports
const getGlobals = () => {
  const _window: any = window
  const _navigator: any = navigator
  const _document: any = document
  return {_window, _navigator, _document}
}

export const detectIncognito = function (
  callback: (arg0: {isPrivate: boolean; browserName: string}) => void,
) {
  let browserName = 'Unknown'

  function __callback(isPrivate: boolean) {
    callback({
      isPrivate,
      browserName,
    })
  }

  function identifyChromium() {
    const {_navigator} = getGlobals()
    const ua = _navigator.userAgent
    if (ua.match(/Edg/)) {
      return 'Edge'
    } else if (ua.match(/Chrome/)) {
      if (_navigator.brave !== undefined) {
        return 'Brave'
      }
      return 'Chrome'
    } else {
      return 'Chromium'
    }
  }

  function assertEvalToString(value: number) {
    return value === eval.toString().length
  }

  function isSafari() {
    const {_navigator} = getGlobals()
    const v = _navigator.vendor
    return v !== undefined && v.indexOf('Apple') === 0
  }

  function isChrome() {
    const {_navigator} = getGlobals()
    const v = _navigator.vendor
    return v !== undefined && v.indexOf('Google') === 0
  }

  function isFirefox() {
    return (
      document.documentElement !== undefined &&
      'MozAppearance' in document?.documentElement?.style
    )
  }

  function isMSIE() {
    const {_navigator} = getGlobals()
    return 'msSaveBlob' in _navigator && assertEvalToString(39)
  }

  /**
   * Safari (Safari for iOS & macOS)
   **/

  function macOS_safari14() {
    const {_window} = getGlobals()
    try {
      _window.safari.pushNotification.requestPermission(
        'https://example.com',
        'private',
        {},
        () => {},
      )
    } catch (e: any) {
      return __callback(!new RegExp('gesture').test(e))
    }
    return __callback(false)
  }

  function iOS_safari14() {
    const {_document} = getGlobals()
    const iframe = _document.createElement('iframe')
    let tripped = false
    iframe.style.display = 'none'
    document.body.appendChild(iframe)

    iframe.contentWindow.applicationCache.addEventListener('error', () => {
      tripped = true
      return __callback(true)
    })

    setTimeout(() => {
      if (!tripped) {
        __callback(false)
      }
    }, 100)
  }

  function oldSafariTest() {
    const {_window} = getGlobals()
    const openDB = _window.openDatabase
    const storage = _window.localStorage
    try {
      openDB(null, null, null, null)
    } catch (e) {
      return __callback(true)
    }
    try {
      storage.setItem('test', '1')
      storage.removeItem('test')
    } catch (e) {
      return __callback(true)
    }
    return __callback(false)
  }

  function safariPrivateTest() {
    const {_window, _navigator} = getGlobals()
    const w = _window
    if (_navigator.maxTouchPoints !== undefined) {
      if (w.safari !== undefined && w.DeviceMotionEvent === undefined) {
        browserName = 'Safari for macOS'
        macOS_safari14()
      } else if (w.DeviceMotionEvent !== undefined) {
        browserName = 'Safari for iOS'
        iOS_safari14()
      } else {
        throw new Error('Could not identify this version of Safari')
      }
    } else {
      oldSafariTest()
    }
  }

  /**
   * Chrome
   **/

  function getQuotaLimit() {
    const {_window} = getGlobals()
    const w = _window
    if (
      w.performance !== undefined &&
      w.performance.memory !== undefined &&
      w.performance.memory.jsHeapSizeLimit !== undefined
    ) {
      return w.performance.memory.jsHeapSizeLimit
    }
    return 1073741824
  }

  // >= 76
  function storageQuotaChromePrivateTest() {
    const {_navigator} = getGlobals()
    _navigator.webkitTemporaryStorage?.queryUsageAndQuota?.(
      (usage: unknown, quota: number) => {
        __callback(quota < getQuotaLimit())
      },
      (e: any) => {
        throw new Error(
          `detectIncognito somehow failed to query storage quota: ${e.message}`,
        )
      },
      // Somehow mostly older Firefox browsers could get into this branch
      // of the code and then crash because webkitTemporaryStorage
      // would be undefined. We were not able to reproduce the issue and fix
      // it properly so we at least handle it gracefully.
      // https://vacuum.atlassian.net/browse/ADLT-1133
    ) || __callback(false)
  }

  // 50 to 75
  function oldChromePrivateTest() {
    const {_window} = getGlobals()
    const fs = _window.webkitRequestFileSystem
    const success = function () {
      __callback(false)
    }
    const error = function () {
      __callback(true)
    }
    fs(0, 1, success, error)
  }

  function chromePrivateTest() {
    if (Promise !== undefined && Promise.allSettled !== undefined) {
      storageQuotaChromePrivateTest()
    } else {
      oldChromePrivateTest()
    }
  }

  /**
   * Firefox
   **/

  function firefoxPrivateTest() {
    const {_navigator} = getGlobals()
    __callback(_navigator.serviceWorker === undefined)
  }

  /**
   * MSIE
   **/

  function msiePrivateTest() {
    const {_window} = getGlobals()
    __callback(_window.indexedDB === undefined)
  }

  function main() {
    storageQuotaChromePrivateTest()
    if (isSafari()) {
      safariPrivateTest()
    } else if (isChrome()) {
      browserName = identifyChromium()
      chromePrivateTest()
    } else if (isFirefox()) {
      browserName = 'Firefox'
      firefoxPrivateTest()
    } else if (isMSIE()) {
      browserName = 'Internet Explorer'
      msiePrivateTest()
    } else {
      //If unknown browser, return false
      __callback(false)
    }
  }

  main()
}
