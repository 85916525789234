import React from 'react'
import {useTranslation} from 'react-i18next'

import {Button} from '../../../components'

type SignButtonProps = {
  disabled?: boolean
  title: string
} & (
  | {
      type: 'button'
      onClick: () => unknown
    }
  | {
      // submission via <form /> onSubmit
      type: 'submit'
    }
)

export function SignButton({title, disabled, ...restProps}: SignButtonProps) {
  const onClick = restProps.type === 'button' ? restProps.onClick : undefined
  return (
    <Button
      fullWidth
      variant="contained"
      color="primary"
      textTransform="none"
      type={restProps.type}
      size="small"
      {...{disabled, onClick}}
    >
      {title}
    </Button>
  )
}

type RetryButtonProps = {
  onClick: () => unknown
  disabled: boolean
}

export function RetryButton({disabled, onClick}: RetryButtonProps) {
  const {t} = useTranslation()
  return (
    <Button
      fullWidth
      variant="outlined"
      color="error"
      textTransform="none"
      type="submit"
      size="small"
      {...{disabled, onClick}}
    >
      {t('Retry')}
    </Button>
  )
}
