import type {StorageDriver} from '@nufi/storage'
import {ContentStore, StoreManager, EncryptionType} from '@nufi/storage'
import type {IdentitySecret} from '@nufi/wallet-common'
import type {SyncCounter} from 'common'
import {v4 as uuidV4} from 'uuid'

import type {Blockchain, Mnemonic} from '../types'
import {getAppVersion} from '../utils/appVersion'
import {assert, safeAssertUnreachable} from '../utils/assertion'
import {broadcastRestoreProfileToOtherTabs} from '../utils/loginBroadcast'

import appStorageDriver from './appStorageDriver'
import {
  ProfileConflictCode,
  DEFAULT_MASTER_PROFILE_DATA,
  StoreType,
} from './constants'
import {AppStorageError} from './errors'
import {isMasterProfileData} from './guards'
import {
  masterProfileMigrations,
  mnemonicMigrations,
  profileDataMigrations,
} from './migrations'
import type {ProfileManager} from './profileManager'
import {
  localProfileManagerLocator,
  profileManagerLocator,
  validateLocalProfileBackup,
} from './profileManager'
import {remoteProfileManagerLocator} from './profileManager/remoteProfileManager'
import type {
  LocalProfileId,
  MasterProfileData,
  ProfileName,
  ProfileMetadata,
  ProfileBackup,
  ProfilePassword,
  ProfileMigrations,
  ProfileMetadataProfileInfo,
  RemoteProfileLoginType,
  LocalProfileLoginType,
  ProfileMetadataProfileSensitiveInfo,
  ProfileLoginType,
} from './types'
import {getMnemonicHash, getIdentitySecretHash} from './utils'

export type ProfileConflictResult =
  | {
      code:
        | ProfileConflictCode.NO_CONFLICT
        | ProfileConflictCode.DIFFERENT_PROFILE_NAME_CONFLICT
    }
  | {
      code: ProfileConflictCode.SAME_PROFILE_EXISTS_CONFLICT
      existingProfile: ProfileMetadata
    }

export class MasterProfileManager {
  private storeManager: StoreManager<MasterProfileData, null>
  private storageDriver: StorageDriver
  private currentProfileId: LocalProfileId | null
  private profileMigrations: ProfileMigrations

  constructor(
    storeManager: StoreManager<MasterProfileData, null>,
    storageDriver: StorageDriver,
    profileMigrations?: ProfileMigrations,
  ) {
    this.storeManager = storeManager
    this.storageDriver = storageDriver
    this.currentProfileId = null
    this.profileMigrations = profileMigrations || {
      mnemonic: {},
      profileData: {},
    }
  }

  init = async (): Promise<void> => {
    await this.storeManager.open({
      initialData: DEFAULT_MASTER_PROFILE_DATA,
      password: null,
    })
  }

  getCurrentProfileId() {
    return this.currentProfileId
  }

  getCurrentProfileData = async (): Promise<ProfileMetadata | null> => {
    const profileId = this.getCurrentProfileId()
    const data = await this.getData()
    return data.profiles.find((p) => p.localProfileId === profileId) ?? null
  }

  getData = async (): Promise<MasterProfileData> =>
    await this.storeManager.getContent()

  findProfile = async ({
    mnemonic,
    loginType,
  }: {
    mnemonic: Mnemonic
    loginType: ProfileLoginType
  }): Promise<ProfileMetadata | null> => {
    const mnemonicHash = await getMnemonicHash(mnemonic)

    const profilesMeta = (await this.getData()).profiles
    return (
      profilesMeta.find(
        (p) =>
          p.loginType === loginType &&
          p.mnemonicStorageType === 'local' &&
          p.mnemonicHash === mnemonicHash,
      ) || null
    )
  }

  findProfileByIdentitySecret = async (
    identitySecret: IdentitySecret,
  ): Promise<ProfileMetadata | null> => {
    const identitySecretHash = await getIdentitySecretHash(identitySecret)

    const profilesMeta = (await this.getData()).profiles
    return (
      profilesMeta.find(
        (p) =>
          p.mnemonicStorageType === 'remote' &&
          p.identitySecretHash === identitySecretHash,
      ) || null
    )
  }

  profileNameExists = async (name: string) => {
    const profilesMeta = (await this.getData()).profiles
    return profilesMeta.some((p) => p.name === name)
  }

  getLastLoggedInLocalProfileId = async (): Promise<LocalProfileId | null> =>
    (await this.storeManager.getContent()).lastLoggedInLocalProfileId

  getCurrentProfileMeta = async (): Promise<ProfileMetadata> => {
    this.ensureProfileIsInitialized()

    const currentProfileMeta = (await this.getData()).profiles.find(
      (p) => p.localProfileId === this.currentProfileId,
    )
    assert(currentProfileMeta != null)
    return currentProfileMeta
  }

  initLocalProfile = async ({
    profileId,
    password,
  }: {
    profileId: LocalProfileId
    password: ProfilePassword
  }): Promise<void> => {
    const profileManager = localProfileManagerLocator.create(
      this.storageDriver,
      profileId,
      password,
      this.profileMigrations,
    )
    await this._init(profileManager, profileId)
  }

  initRemoteProfile = async ({
    profileId,
    identitySecret,
  }: {
    profileId: LocalProfileId
    identitySecret: IdentitySecret
  }): Promise<void> => {
    const profileManager = remoteProfileManagerLocator.create(
      this.storageDriver,
      profileId,
      identitySecret,
      this.profileMigrations,
    )
    await this._init(profileManager, profileId)
  }

  private _init = async (
    profileManager: ProfileManager,
    profileId: LocalProfileId,
  ) => {
    await profileManager.init({action: 'login'})
    await this.setProfileLoggedIn(profileId)
  }

  verifyLocalProfilePassword = async ({
    profileId,
    password,
  }: {
    profileId: LocalProfileId
    password: ProfilePassword
  }): Promise<boolean> => {
    assert(await this.profileIdExists(profileId))
    const profileManager = localProfileManagerLocator.create(
      this.storageDriver,
      profileId,
      password,
      this.profileMigrations,
    )
    return await profileManager.verifyPassword(password)
  }

  createNewRemoteProfile = async ({
    profileName,
    identitySecret,
    loginType,
    enabledBlockchains,
  }: {
    profileName: ProfileName
    identitySecret: IdentitySecret
    loginType: RemoteProfileLoginType
    enabledBlockchains: readonly Blockchain[]
  }): Promise<LocalProfileId> => {
    const existingProfile =
      await this.findProfileByIdentitySecret(identitySecret)

    const createAndInitProfileManager = async (profileId: LocalProfileId) => {
      const profileManager = remoteProfileManagerLocator.create(
        this.storageDriver,
        profileId,
        identitySecret,
        this.profileMigrations,
      )
      await profileManager.init({
        action: 'create-profile',
        enabledBlockchains,
      })
    }

    return await this._createNewProfile({
      createAndInitProfileManager,
      existingProfile,
      walletParams: {
        identitySecret,
        mnemonicStorageType: 'remote',
        loginType,
      },
      profileName,
    })
  }

  createNewLocalProfile = async ({
    profileName,
    password,
    mnemonic,
    isHwUser,
    isMnemonicActivated,
    loginType,
    overwriteExistingProfile,
    enabledBlockchains,
  }: {
    profileName: ProfileName
    password: ProfilePassword
    mnemonic: Mnemonic
    isHwUser: boolean
    isMnemonicActivated: boolean
    loginType: LocalProfileLoginType
    overwriteExistingProfile?: boolean
    enabledBlockchains: readonly Blockchain[]
  }): Promise<LocalProfileId> => {
    const existingProfile = await this.findProfile({
      mnemonic,
      loginType,
    })

    const createAndInitProfileManager = async (profileId: LocalProfileId) => {
      const profileManager = localProfileManagerLocator.create(
        this.storageDriver,
        profileId,
        password,
        this.profileMigrations,
      )
      await profileManager.init({
        mnemonic,
        action: 'create-profile',
        isHwUser,
        isMnemonicActivated,
        enabledBlockchains,
      })
    }

    return await this._createNewProfile({
      createAndInitProfileManager,
      existingProfile,
      walletParams: {
        mnemonic,
        mnemonicStorageType: 'local',
        loginType,
      },
      profileName,
      overwriteExistingProfile,
    })
  }

  _createNewProfile = async ({
    profileName,
    createAndInitProfileManager,
    overwriteExistingProfile,
    existingProfile,
    walletParams,
  }: {
    profileName: ProfileName
    overwriteExistingProfile?: boolean
    existingProfile: ProfileMetadata | null
    createAndInitProfileManager: (profileId: LocalProfileId) => Promise<void>
    walletParams: ProfileMetadataProfileSensitiveInfo
  }): Promise<LocalProfileId> => {
    if (existingProfile && !overwriteExistingProfile) {
      throw new Error(AppStorageError.PROFILE_EXISTS)
    }

    const profileId = await this.generateLocalProfileId()

    await createAndInitProfileManager(profileId)

    await this.addProfile({
      name: profileName,
      localProfileId: profileId,
      ...walletParams,
    })
    await this.setProfileLoggedIn(profileId)

    if (existingProfile && overwriteExistingProfile) {
      await this.removeProfile(existingProfile.localProfileId)
    }

    return profileId
  }

  removeCurrentProfile = async (): Promise<void> => {
    this.ensureProfileIsInitialized()
    const currentProfileId = this.currentProfileId as LocalProfileId

    if (!(await this.profileIdExists(currentProfileId))) {
      throw new Error(AppStorageError.PROFILE_DOES_NOT_EXIST)
    }

    const masterFile = await this.storeManager.getContent()
    await this.storeManager.setContent({
      ...masterFile,
      profiles: masterFile.profiles.filter(
        (profile) => profile.localProfileId !== currentProfileId,
      ),
    })

    const lastLoggedInLocalProfileId =
      await this.getLastLoggedInLocalProfileId()
    if (this.currentProfileId === lastLoggedInLocalProfileId) {
      await this.setLastLoggedInLocalProfileId(null)
    }
    this.currentProfileId = null

    await profileManagerLocator.instance().removeProfile()
    profileManagerLocator.destroy()
  }

  renameCurrentProfile = async ({name}: {name: ProfileName}): Promise<void> => {
    this.ensureProfileIsInitialized()
    const currentProfileId = this.currentProfileId as LocalProfileId

    if (!(await this.profileIdExists(currentProfileId))) {
      throw new Error(AppStorageError.PROFILE_DOES_NOT_EXIST)
    }

    const masterFile = await this.storeManager.getContent()

    await this.storeManager.setContent({
      ...masterFile,
      profiles: masterFile.profiles.map((profile) => {
        if (profile.localProfileId === currentProfileId) {
          profile.name = name
        }
        return profile
      }),
    })
  }

  getCurrentSyncCounter = async (): Promise<SyncCounter> =>
    (await this.getCurrentProfileMeta()).syncCounter as SyncCounter

  setCloudSyncData = async (syncCounter: SyncCounter): Promise<void> => {
    this.ensureProfileIsInitialized()
    const currentProfileId = this.currentProfileId as LocalProfileId

    if (!(await this.profileIdExists(currentProfileId))) {
      throw new Error(AppStorageError.PROFILE_DOES_NOT_EXIST)
    }

    const masterFile = await this.storeManager.getContent()

    await this.storeManager.setContent({
      ...masterFile,
      profiles: masterFile.profiles.map((profile) => {
        if (profile.localProfileId === currentProfileId) {
          profile.syncCounter = syncCounter
          profile.syncedAt = Date.now()
        }
        return profile
      }),
    })
  }

  /** used both for recovery from mnemonic and creation of a new profile */
  checkProfileConflictFromMnemonic = async ({
    profileName,
    mnemonic,
    loginType,
  }: {
    profileName: ProfileName
    mnemonic: Mnemonic
    loginType: LocalProfileLoginType
  }): Promise<ProfileConflictResult> => {
    const existingProfile = await this.findProfile({
      mnemonic,
      loginType,
    })

    if (existingProfile) {
      // ensure the overwritten profile isn't logged in anywhere as this may lead to data corruption, including the current tab
      assert(this.currentProfileId !== existingProfile.localProfileId)
      await broadcastRestoreProfileToOtherTabs(existingProfile.localProfileId)

      if (
        existingProfile.name !== profileName &&
        (await this.profileNameExists(profileName))
      ) {
        return {
          code: ProfileConflictCode.DIFFERENT_PROFILE_NAME_CONFLICT,
        }
      } else {
        return {
          code: ProfileConflictCode.SAME_PROFILE_EXISTS_CONFLICT,
          existingProfile,
        }
      }
    } else {
      if (await this.profileNameExists(profileName)) {
        return {
          code: ProfileConflictCode.DIFFERENT_PROFILE_NAME_CONFLICT,
        }
      } else {
        return {code: ProfileConflictCode.NO_CONFLICT}
      }
    }
  }

  restoreLocalProfileFromBackup = async ({
    profileBackup,
    profileName,
    password,
  }: {
    profileBackup: ProfileBackup
    profileName: ProfileName
    password: ProfilePassword
  }): Promise<void> => {
    const {mnemonicData, profileData, mnemonicHeader, profileHeader} =
      await validateLocalProfileBackup(profileBackup, password)

    const existingProfile = await this.findProfile({
      mnemonic: mnemonicData.mnemonic,
      loginType: 'password',
    })

    if (existingProfile) {
      // ensure the overwritten profile isn't logged in anywhere as this may lead to data corruption, including the current tab
      assert(this.currentProfileId !== existingProfile.localProfileId)

      await broadcastRestoreProfileToOtherTabs(existingProfile.localProfileId)
    }

    const profileId = await this.generateLocalProfileId()
    const profileManager = localProfileManagerLocator.create(
      this.storageDriver,
      profileId,
      password,
      this.profileMigrations,
    )

    await profileManager.init({
      mnemonic: mnemonicData.mnemonic,
      profileParams: profileData,
      mnemonicHeader,
      profileHeader,
      action: 'restore-backup',
    })

    await this.addProfile({
      name: profileName,
      localProfileId: profileId,
      mnemonic: mnemonicData.mnemonic,
      loginType: 'password',
      mnemonicStorageType: 'local',
    })
    await this.setProfileLoggedIn(profileId)

    if (existingProfile) {
      await this.removeProfile(existingProfile.localProfileId)
    }
  }

  private setLastLoggedInLocalProfileId = async (
    lastLoggedInLocalProfileId: LocalProfileId | null,
  ): Promise<void> => {
    const masterFile = await this.storeManager.getContent()
    await this.storeManager.setContent({
      ...masterFile,
      lastLoggedInLocalProfileId,
    })
  }

  private setProfileLoggedIn = async (
    profileId: LocalProfileId,
  ): Promise<void> => {
    this.currentProfileId = profileId
    await this.setLastLoggedInLocalProfileId(profileId)
  }

  private generateLocalProfileId = async (): Promise<LocalProfileId> => {
    const existingProfileIds =
      (await this.getData())?.profiles.map((p) => p.localProfileId) || []

    let profileId: LocalProfileId
    do {
      profileId = uuidV4().replaceAll('-', '') as LocalProfileId
    } while (existingProfileIds.includes(profileId))

    return profileId
  }

  private removeProfile = async (
    localProfileId: LocalProfileId,
  ): Promise<void> => {
    const masterFile = await this.storeManager.getContent()
    await this.storeManager.setContent({
      ...masterFile,
      profiles: masterFile.profiles.filter(
        (profile) => profile.localProfileId !== localProfileId,
      ),
    })
  }

  private addProfile = async ({
    name,
    localProfileId,
    ...restParams
  }: {
    name: ProfileName
    localProfileId: LocalProfileId
  } & ProfileMetadataProfileSensitiveInfo): Promise<void> => {
    const walletParams: ProfileMetadataProfileInfo = await (async () => {
      // We are enumerating properties to avoid e.g. storing mnemonic
      // in plaintext in master profile by accident.
      const {mnemonicStorageType} = restParams
      switch (mnemonicStorageType) {
        case 'remote':
          return {
            mnemonicStorageType: 'remote' as const,
            loginType: restParams.loginType,
            identitySecretHash: await getIdentitySecretHash(
              restParams.identitySecret,
            ),
          }
        case 'local':
          return {
            mnemonicStorageType: 'local' as const,
            loginType: restParams.loginType,
            mnemonicHash: await getMnemonicHash(restParams.mnemonic),
          }
        default:
          return safeAssertUnreachable(mnemonicStorageType)
      }
    })()

    const masterFile = await this.storeManager.getContent()
    await this.storeManager.setContent({
      ...masterFile,
      profiles: [
        ...masterFile.profiles,
        {
          name,
          localProfileId,
          syncCounter: 0 as SyncCounter,
          syncedAt: 0,
          ...walletParams,
        },
      ],
    })
  }

  private profileIdExists = async (
    localProfileId: LocalProfileId,
  ): Promise<boolean> => {
    const profileInfo = await this.getData()
    return (
      profileInfo != null &&
      profileInfo.profiles.some((p) => p.localProfileId === localProfileId)
    )
  }

  private ensureProfileIsInitialized = () => {
    if (!this.currentProfileId) {
      throw new Error(AppStorageError.PROFILE_NOT_INITIALIZED)
    }
  }
}

const profileMigrations = {
  mnemonic: mnemonicMigrations,
  profileData: profileDataMigrations,
}

export const createMasterProfileManager = ({
  storageDriver,
}: {
  storageDriver: StorageDriver
}) => {
  const MASTER_PROFILE_KEY = 'master-profile'
  const masterProfileStoreManager = new StoreManager({
    typeGuard: isMasterProfileData,
    store: new ContentStore({
      storageKey: MASTER_PROFILE_KEY,
      storageDriver,
    }),
    migrations: masterProfileMigrations,
    appVersion: getAppVersion(),
    storeType: StoreType.MasterProfile,
    encryptionType: EncryptionType.None,
  })

  return new MasterProfileManager(
    masterProfileStoreManager,
    storageDriver,
    profileMigrations,
  )
}

export default createMasterProfileManager({
  storageDriver: appStorageDriver.instance(),
})
