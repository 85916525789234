import {Box} from '@mui/material'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {GridPlusFailedSign} from 'src/pages/hw/gridPlus'

import {Alert, Button, InlineError} from '../../../components'
import {LedgerFailedSign} from '../../../pages/hw/ledger'
import {TrezorFailedSign} from '../../../pages/hw/trezor'
import {DeviceReadyState} from '../../../pages/transaction/common'
import {safeAssertUnreachable} from '../../../utils/assertion'
import {useHwVendorNames} from '../../../utils/translations'
import type {HwVendor} from '../../../wallet'
import {RejectButton} from '../../components'
import type {ConnectorBlockchain} from '../../types'

import {MessageData} from './messageData'
import {isHwSignSupported} from './providers'
import {useSignContext} from './SignContext'
import {SignLayout} from './SignLayout'
import {TxData} from './txData'
import type {SignScreenProps} from './types'
import {VoteData} from './voteData/VoteData'

type HwSignScreenProps = SignScreenProps & {
  blockchain: ConnectorBlockchain
  hwVendor: HwVendor
  onFailure: () => void
}

export function HwSignScreen({onFailure, ...restProps}: HwSignScreenProps) {
  const [step, setStep] = useState<'summary' | 'sign'>('summary')

  const onRequestHwSign = () => {
    // Signal that e.g. fees recalculation should stop,
    // so that parent injecting `extraOptionsProps` can react to
    // this change.
    restProps.extraOptionsProps?.onTxSignStart()

    setStep('sign')
  }

  switch (step) {
    case 'summary':
      return (
        <SummaryStep
          onReject={onFailure}
          onRequestHwSign={onRequestHwSign}
          {...restProps}
        />
      )
    case 'sign':
      return <SignStep onFailure={onFailure} {...restProps} />
    default:
      return safeAssertUnreachable(step)
  }
}

type SummaryStepProps = {
  onRequestHwSign: () => void
  onReject: () => void
  blockchain: HwSignScreenProps['blockchain']
  signData: HwSignScreenProps['signData']
  hwVendor: HwSignScreenProps['hwVendor']
  extraOptionsProps?: HwSignScreenProps['extraOptionsProps']
}

function SummaryStep({
  onReject,
  onRequestHwSign,
  blockchain,
  signData,
  hwVendor,
  extraOptionsProps,
}: SummaryStepProps) {
  const {t} = useTranslation()
  const hwVendorNames = useHwVendorNames()
  const shouldDisableSignTx =
    signData.type === 'sign-tx' &&
    !isHwSignSupported('sign-tx', blockchain, hwVendor)
  const shouldDisableSignMessage =
    signData.type === 'sign-message' &&
    !isHwSignSupported('sign-message', blockchain, hwVendor)
  const shouldDisableSignVote =
    signData.type === 'sign-vote' &&
    !isHwSignSupported('sign-vote', blockchain, hwVendor)

  return (
    <SignLayout
      component="div"
      infoContent={
        <Box mb={2} mt={2}>
          {(() => {
            switch (signData.type) {
              case 'sign-tx':
                return !shouldDisableSignTx ? (
                  <TxData data={signData.data} {...{extraOptionsProps}} />
                ) : (
                  <Alert
                    severity="warning"
                    text={t('dapp_connector_sign_tx_not_supported_for_vendor', {
                      hwVendor: hwVendorNames[hwVendor],
                    })}
                  />
                )
              case 'sign-message':
                return !shouldDisableSignMessage ? (
                  <MessageData data={signData.data} />
                ) : (
                  <Alert
                    severity="warning"
                    text={t(
                      'dapp_connector_sign_message_not_supported_for_vendor',
                      {
                        hwVendor: hwVendorNames[hwVendor],
                      },
                    )}
                  />
                )
              case 'sign-vote':
                return !shouldDisableSignVote ? (
                  <VoteData data={signData.data} />
                ) : (
                  <Alert
                    severity="warning"
                    text={t(
                      'dapp_connector_sign_vote_not_supported_for_vendor',
                      {
                        hwVendor: hwVendorNames[hwVendor],
                      },
                    )}
                  />
                )
              case 'get-hw-wallet-public-keys':
                return <></>
              default:
                return safeAssertUnreachable(signData)
            }
          })()}
        </Box>
      }
      actionsProps={{
        leftButton: <RejectButton onClick={onReject} size="small" />,
        rightButton: (
          <Button
            textTransform="none"
            fullWidth
            size="small"
            variant="contained"
            color="primary"
            onClick={onRequestHwSign}
            disabled={
              shouldDisableSignTx ||
              shouldDisableSignMessage ||
              shouldDisableSignVote
            }
          >
            {signData.type !== 'get-hw-wallet-public-keys'
              ? t('Sign')
              : t('Confirm')}
          </Button>
        ),
      }}
    />
  )
}

type SignStepProps = Omit<HwSignScreenProps, 'signData'>

function SignStep({
  onSign,
  signProps,
  hwVendor,
  blockchain,
  onFailure,
  extraContent,
}: SignStepProps) {
  useEffect(() => {
    onSign()
  }, [])

  const {layoutProps} = useSignContext()

  const VendorError = () => {
    switch (hwVendor) {
      case 'trezor':
        return (
          <>
            <TrezorFailedSign onRetry={onSign} />
            <InlineError error={signProps.error} />
          </>
        )
      case 'ledger':
        return (
          <LedgerFailedSign
            blockchain={blockchain}
            onRetry={onSign}
            error={signProps.error}
          />
        )
      case 'gridPlus':
        return <GridPlusFailedSign onRetry={onSign} error={signProps.error} />
      default:
        return safeAssertUnreachable(hwVendor)
    }
  }

  return (
    <Box
      sx={{
        minWidth: layoutProps.screenWidth,
        // Avoid fixed width due to ledger state indicator that may
        // potentially fill more space
        width: 'min-content',
        maxWidth: '100%',
        margin: '0 auto',
      }}
    >
      {extraContent}

      {signProps.error ? (
        <>
          <VendorError />
          <Box mt={2}>
            <RejectButton variant="error" onClick={onFailure} size="small" />
          </Box>
        </>
      ) : (
        <DeviceReadyState hwVendor={hwVendor} />
      )}
    </Box>
  )
}
