import {request} from '@nufi/frontend-common'
import queryString from 'query-string'

import config from 'src/config'
import {colors} from 'src/theme/palette'
import {useMutation} from 'src/utils/mutation-utils'

type BaseParams = {
  apiKey: string
  colorCode: string
}

const baseSearchParams: BaseParams = {
  apiKey: config.moonpayApiKey,
  colorCode: colors.primary.main,
}

const generateMoonpayUrl = (
  action: 'buy' | 'sell',
  params?: Record<string, string>,
) => {
  const baseUrl = `https://${action}.moonpay.com`
  const moonpayQueryParams = {
    ...baseSearchParams,
    ...params,
  }
  return `${baseUrl}?${queryString.stringify(moonpayQueryParams)}`
}

// https://dev.moonpay.com/docs/off-ramp-how-to-configure-user-journey-params
type MoonpaySellParams = {
  baseCurrencyCode: string
}

export const getMoonpaySellUrl = (params?: MoonpaySellParams) =>
  generateMoonpayUrl('sell', params)

// https://docs.moonpay.com/moonpay/implementation-guide/on-ramp/customize-the-widget
type MoonpayBuyParams = {
  showWalletAddressForm?: 'true' | 'false'
  defaultCurrencyCode?: string
  currencyCode?: string
  walletAddress?: string
}

/**
 * when using @param walletAddress signing of the URL is mandatory. Use signMoonpayUrl() to generate signed URL.
 */
export const getMoonpayBuyUrl = (params?: MoonpayBuyParams) =>
  generateMoonpayUrl('buy', params)

export const useSignMoonpayUrl = () =>
  useMutation('signMoonpayUrl', async (moonpayUrl: string) => {
    const response = await request<{signedUrl: string}>({
      url: `${config.backendUrl}/api/v1/moonpay/signUrl`,
      method: 'POST',
      body: JSON.stringify({originalUrl: moonpayUrl}),
      headers: {'Content-Type': 'application/json'},
      timeout: 10000,
    })
    return response.signedUrl
  })
