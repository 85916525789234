import {Box, Grid} from '@mui/material'
import BigNumber from 'bignumber.js'
import type {FormikProps} from 'formik'
import React from 'react'
import {useTranslation} from 'react-i18next'

import type {EvmBlockchain, EvmWei} from '../../../../wallet/evm'
import {EVM_TRANSFER_GAS_UNITS} from '../../../../wallet/evm/constants'
import {gweiToWei, weiToGwei} from '../../../../wallet/evm/sdk/utils'

import {GasField, GasFieldsWrapper, GasOptionsHeader} from './common'
import type {GasFieldProps} from './common'
import type {SimpleGasSchema} from './schema'

export type SimpleGasOptionsProps<T extends SimpleGasSchema> = {
  formikProps: FormikProps<T>
}

export const gasOptionsToInitialFormValues = <
  TBlockchain extends EvmBlockchain,
>(
  gasPrice: EvmWei<TBlockchain>,
  gasLimit?: BigNumber,
) => {
  return {
    gasLimit:
      gasLimit != null ? gasLimit.toString() : `${EVM_TRANSFER_GAS_UNITS}`,
    gasPrice: `${weiToGwei(gasPrice)}`,
  }
}

export type GasOptionsFormValues<TBlockchain extends EvmBlockchain> = {
  type: 'simple'
  gasLimit: BigNumber
  gasPrice: EvmWei<TBlockchain>
}

export const formValueToGasOptions = <
  TBlockchain extends EvmBlockchain,
  TSchema extends SimpleGasSchema,
>(
  values: TSchema,
): GasOptionsFormValues<TBlockchain> => ({
  type: 'simple' as const,
  gasLimit: new BigNumber(values.gasLimit),
  gasPrice: gweiToWei<TBlockchain>(values.gasPrice),
})

/**
 * Gas options preceding London hard fork era.
 * https://ethereum.org/en/developers/docs/gas/
 */
export const SimpleGasOptions = <T extends SimpleGasSchema>({
  formikProps,
}: SimpleGasOptionsProps<T>) => {
  const {t} = useTranslation()

  return (
    <Box pt={1}>
      <GasOptionsHeader />
      <GasFieldsWrapper>
        <Grid container item spacing={1}>
          <Grid item xs={6}>
            <SimpleGasField
              field="gasPrice"
              label={t('Gas Price (Gwei)')}
              explanation={t('gas_price_explanation')}
              formikProps={formikProps}
            />
          </Grid>
          <Grid item xs={6}>
            <SimpleGasField
              field="gasLimit"
              label={t('Gas Limit')}
              explanation={t('gas_limit_explanation')}
              formikProps={formikProps}
            />
          </Grid>
        </Grid>
      </GasFieldsWrapper>
    </Box>
  )
}

const SimpleGasField = (
  props: Omit<GasFieldProps, 'field'> & {field: keyof SimpleGasSchema},
) => <GasField {...props} />
