import React from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'

import {Icon} from 'src/components'
import {routeTo} from 'src/router'

import {ExternalProviderButton} from '../common'

export function LoginWithMetamaskButton() {
  const {t} = useTranslation()
  const history = useHistory()
  return (
    <ExternalProviderButton
      onClick={() => {
        history.push(routeTo.metamaskLogin)
      }}
      startIcon={<Icon type="metamaskIcon" />}
      sx={{position: 'relative'}}
    >
      {t('MetaMask')}
    </ExternalProviderButton>
  )
}
