import {Grid, Box, Typography, TextField} from '@mui/material'
import {Formik} from 'formik'
import React from 'react'
import {useTranslation} from 'react-i18next'
import * as yup from 'yup'

import type {ProfilePassword} from '../../appStorage'
import {SideBarLayout, Button, NavigateBack} from '../../components'
import {getHasFormError, useCommonValidations} from '../../utils/form'

import {
  useCommonProfileStyles,
  NavigateBackWrapper,
  CreateProfileTimeline,
} from './common'
import type {WalletType} from './types'

type Props = {
  onSubmit: (password: ProfilePassword) => unknown
  onBack: () => unknown
  walletType: WalletType
  password?: string
}

export default function ProfilePasswordPage({
  onSubmit,
  onBack,
  walletType,
  password,
}: Props) {
  const {t} = useTranslation()
  const commonClasses = useCommonProfileStyles()
  const commonValidation = useCommonValidations()

  const _formSchema = {
    password: yup
      .string()
      .concat(commonValidation.passwordPower)
      .required(t('Password is required.')),
    confirmedPassword: yup
      .string()
      .oneOf([yup.ref('password')], t('Passwords must match.'))
      .required(t('Passwords must match.')),
  }
  type FormField = keyof typeof _formSchema
  type FormData = {[k in FormField]: string}
  const formSchema = yup.object().shape(_formSchema)

  const onSubmitProfilePassword = (values: FormData) => {
    onSubmit(values.password as ProfilePassword)
  }
  const initialValues: FormData = {
    password: password || '',
    confirmedPassword: password || '',
  }

  return (
    <SideBarLayout
      left={<CreateProfileTimeline step="password" {...{walletType}} />}
      right={
        <Formik
          initialValues={initialValues}
          validationSchema={formSchema}
          onSubmit={onSubmitProfilePassword}
        >
          {(formikProps) => {
            const {handleSubmit, values, errors, isSubmitting, handleChange} =
              formikProps
            const hasError = getHasFormError(formikProps)
            return (
              <Box className={commonClasses.wrapper}>
                <Typography variant="h5">
                  {t('Protect your wallet with a password')}
                </Typography>

                <Typography
                  className={commonClasses.headerSubtitle}
                  variant="body1"
                  color="textSecondary"
                >
                  {t(
                    'This password controls access to your NuFi wallet and encrypts sensitive data. Please store your password securely.',
                  )}
                </Typography>

                <form
                  className={commonClasses.form}
                  onSubmit={handleSubmit}
                  noValidate
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        data-test-id="wallet-password-field"
                        value={values.password}
                        onChange={handleChange<FormField>('password')}
                        label={t('Password')}
                        type="password"
                        error={hasError('password')}
                        helperText={hasError('password') && errors.password}
                        variant="outlined"
                        fullWidth
                        autoFocus
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        data-test-id="wallet-password-confirm-field"
                        value={values.confirmedPassword}
                        onChange={handleChange<FormField>('confirmedPassword')}
                        label={t('Confirm password')}
                        type="password"
                        error={hasError('confirmedPassword')}
                        helperText={
                          hasError('confirmedPassword') &&
                          errors.confirmedPassword
                        }
                        fullWidth
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        className={commonClasses.submitButton}
                        fullWidth
                        textTransform="none"
                        variant="contained"
                        type="submit"
                        color="primary"
                        disabled={isSubmitting}
                      >
                        {t('Continue')}
                      </Button>
                    </Grid>
                  </Grid>
                </form>

                <NavigateBackWrapper>
                  <NavigateBack onBack={onBack} />
                </NavigateBackWrapper>
              </Box>
            )
          }}
        </Formik>
      }
    />
  )
}
