import type BigNumber from 'bignumber.js'
import React from 'react'

import {convertBalance} from 'src/features/assets/domain/conversions'
import type {ConversionRates} from 'src/features/conversionRates/domain'
import {useSettings} from 'src/features/profile/application'
import {spread} from 'src/utils/reactHelpers'

import type {Blockchain, TokenMetadata} from '../../types'

import {FormattedCurrency} from './FormattedCurrency'

type FormattedAssetAsCurrencyProps = {
  balance: BigNumber
  blockchain: Blockchain
  tokenMetadata?: TokenMetadata
  includeCurrencySymbol?: boolean
  isSensitiveInformation?: boolean
  shouldUseCompactNotation?: boolean
  conversionRates: ConversionRates
}

export const FormattedAssetAsCurrency = ({
  balance,
  blockchain,
  tokenMetadata,
  shouldUseCompactNotation,
  includeCurrencySymbol = true,
  isSensitiveInformation = false,
  conversionRates,
}: FormattedAssetAsCurrencyProps) => {
  const {currency} = useSettings()

  return (
    <FormattedCurrency
      convertedBalance={convertBalance({
        balance,
        blockchain,
        currency,
        conversionRates,
        tokenMetadata: tokenMetadata || undefined,
      })}
      {...spread(
        {
          currency,
          includeCurrencySymbol,
          isSensitiveInformation,
          shouldUseCompactNotation,
        },
        FormattedCurrency,
      )}
    />
  )
}
