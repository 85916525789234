import {Box, Grid, Typography, Divider, styled} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import BigNumber from 'bignumber.js'
import clsx from 'clsx'
import type {FormikErrors, FormikProps} from 'formik'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {WithConversionRates} from 'src/features/conversionRates/ui'

import {nftBlockchains, tokenBlockchains} from '../../../blockchainTypes'
import {
  ModalFooter,
  ModalLayout,
  AccountSelectField,
  FormattedAsset,
  FooterLayout,
  useModalSharedStyles,
  createCustomResponsiveClass,
  Alert,
  InlineLoading,
  ExternalOrInternalAddressFieldWithNS,
  DebouncedField,
  Button,
  FormattedAssetAsCurrency,
} from '../../../components'
import type {
  Blockchain,
  TokenAccountBalances,
  AccountId,
  AccountInfo,
  AddressType,
  AccountIdRequestedBalance,
  TokenMetadata,
} from '../../../types'
import {BlockchainSubsetGuard} from '../../../utils/blockchainGuards'
import {getHasFormError} from '../../../utils/form'
import type {SelectChangeFn} from '../../../utils/form'

import {NativeAssetAmountField, TokenAmountField} from './AssetAmountField'
import type {GetAssetFieldArgs} from './AssetAmountField'
import {SendAssetsList} from './SendAssetsList'
import type {BaseSendSchema, NativeAssetSchema, TokenSchema} from './types'
import {getAssetFieldName} from './utils'

const FORM_ID = 'send-form-details'

export const detailsScreenTestIds = {
  amountField: 'send-amount-field',
}

export type SendDetailMultiAssetProps = {
  isNativeAmountFieldReadOnly: boolean
  setIsNativeAmountFieldReadOnly: (readOnly: boolean) => void
}

export type AddressNameServiceResolver = (
  address: string,
) => Promise<{extras?: {image?: string}; address: string} | null>

type AddressNameServiceState = {
  isLoading: boolean
  data: {extras: {image?: string}; address: string} | null
  onSubmit: AddressNameServiceResolver
  shouldEnableNameService: (address: string) => boolean
  getValidateLabel?: (address: string) => string
  getValidateSuccessLabel?: (address: string) => string
}

type AccountBalanceCustomRows = {
  tokenMetadata?: TokenMetadata
} & ({balances: AccountIdRequestedBalance[]} | {balance: BigNumber})

export type DetailsScreenProps<T extends BaseSendSchema> = {
  blockchain: Blockchain
  account: AccountInfo
  accounts: Array<AccountInfo>
  ErrorContent?: React.ReactNode
  InfoContent?: React.ReactNode
  formikProps: FormikProps<T>
  disabled?: boolean
  onClose: () => void
  getAssetFieldProps?: GetAssetFieldArgs
  summary: React.ReactNode
  ModalHeader: React.ReactNode
  tokenBalances?: TokenAccountBalances
  onAddAssetsClick?: () => void
  customField?: React.ReactNode
  addressNameService?: AddressNameServiceState
  customAccountSelectBalances?: {
    primary: AccountBalanceCustomRows
    secondary: AccountBalanceCustomRows
  }
  renderAddressMessage?: (address: string) => React.ReactNode
  sendContext: {type: 'singleAsset'} | {type: 'multiAsset'}
  onBeforeDetailsSubmit?: (data: T, errors: FormikErrors<T>) => Promise<unknown>
}

export default function DetailsScreen<T extends BaseSendSchema>({
  account,
  accounts,
  disabled,
  formikProps,
  blockchain,
  onClose,
  summary,
  ModalHeader,
  tokenBalances,
  ErrorContent,
  InfoContent,
  customField,
  onAddAssetsClick,
  addressNameService,
  customAccountSelectBalances,
  renderAddressMessage,
  getAssetFieldProps,
  sendContext,
  onBeforeDetailsSubmit,
}: DetailsScreenProps<T>) {
  const classes = {...useStyles(), ...useModalSharedStyles()}
  const {t} = useTranslation()

  const isSingleAsset = sendContext.type === 'singleAsset'

  const {
    values,
    handleChange,
    errors,
    touched,
    handleBlur,
    setFieldValue,
    setValues,
  } = formikProps

  const findAccountBalance = (
    balances: AccountIdRequestedBalance[],
    _accountId: AccountId,
  ) =>
    balances.find(({accountId}) => accountId === _accountId)?.balance ||
    new BigNumber(0)

  const accountItems = accounts.map((a) => ({
    accountId: a.id,
    address: a.address,
    name: a.name,
    cryptoProviderType: a.cryptoProviderType,
    ...(customAccountSelectBalances
      ? (() => {
          const {primary, secondary} = customAccountSelectBalances
          return {
            customBalanceRows: {
              primary: {
                tokenMetadata: primary.tokenMetadata,
                balance:
                  'balance' in primary
                    ? primary.balance
                    : findAccountBalance(primary.balances, a.id),
              },
              secondary: {
                tokenMetadata: secondary.tokenMetadata,
                balance:
                  'balance' in secondary
                    ? secondary.balance
                    : findAccountBalance(secondary.balances, a.id),
              },
            },
          }
        })()
      : {
          balance: tokenBalances
            ? tokenBalances.balances.find((t) => t.accountId === a.id)
                ?.balance || new BigNumber(0)
            : a.balance,
        }),
  }))

  const hasErrorAfterSubmit = getHasFormError(formikProps, 'after-submit')
  const hasErrorAfterTouched = getHasFormError(formikProps, 'after-touched')
  type FormField = keyof typeof values

  const toAddressErrorMessage =
    hasErrorAfterSubmit('toAddress') ||
    values.toAddressNameServiceState.status === 'invalid'
      ? (errors.toAddress as string)
      : undefined

  const resolvedAddress =
    values.toAddressNameServiceState.status === 'valid'
      ? values.toAddressNameServiceState.resolvedAddress
      : values.toAddress
  const addressMessage = resolvedAddress
    ? renderAddressMessage?.(resolvedAddress)
    : undefined

  const firstAssetName = getAssetFieldName(0)
  const firstAssetErrorMessage =
    ((touched.assets as unknown as {amount: boolean}[])?.[0]?.amount &&
      (errors.assets as {amount: string}[])?.[0]?.amount) ||
    undefined

  return (
    <ModalLayout
      header={ModalHeader}
      body={
        <form
          onSubmit={async (e) => {
            e.preventDefault()
            await onBeforeDetailsSubmit?.(values, errors)
            formikProps.handleSubmit(e)
          }}
          noValidate
          id={FORM_ID}
        >
          <Box p={2}>
            <Grid container>
              <Grid item xs={12} className={classes.formField}>
                <AccountSelectField
                  label={t('Account')}
                  value={values.accountId}
                  onChange={
                    handleChange<FormField>(
                      'accountId',
                    ) as SelectChangeFn<AccountId>
                  }
                  items={accountItems}
                  blockchain={blockchain}
                  tokenMetadata={tokenBalances?.data}
                  error={hasErrorAfterTouched('accountId')}
                  helperText={
                    hasErrorAfterTouched('accountId') && <>{errors.accountId}</>
                  }
                />
              </Grid>
              <Grid item xs={12} className={classes.divider}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                {/* Without debouncing validation fails due to calling
                too many Cardano-serialization-lib requests. */}
                <DebouncedField
                  delay={300}
                  defaultValue={{
                    address: values.toAddress,
                    addressType: values.addressType,
                  }}
                  onChange={({
                    address,
                    addressType,
                  }: {
                    address: string
                    addressType: AddressType
                  }) => {
                    const valuesToUpdate: Partial<BaseSendSchema> = {
                      toAddress: address,
                      addressType,
                      toAddressNameServiceState:
                        addressNameService?.shouldEnableNameService(address) &&
                        values.addressType === 'external'
                          ? {status: 'not-validated'}
                          : {status: 'disabled'},
                    }
                    // Note that it is important to set values like this and not
                    // using `formikProps.values` as this function is debounced
                    setValues((values) => ({
                      ...values,
                      ...valuesToUpdate,
                    }))
                  }}
                >
                  {(_value, _onChange) => (
                    <ExternalOrInternalAddressFieldWithNS
                      formFieldClass={classes.formField}
                      label={
                        values.addressType === 'internal'
                          ? t('Account')
                          : t('Address')
                      }
                      chipLabel={t('Transfer to')}
                      items={accountItems}
                      onChange={_onChange}
                      value={_value}
                      errorMessage={toAddressErrorMessage}
                      blockchain={blockchain}
                      tokenMetadata={tokenBalances?.data}
                      nameService={
                        addressNameService
                          ? {
                              validateLabel:
                                addressNameService?.getValidateLabel?.(
                                  _value.address,
                                ) || t('Resolve'),
                              validatedLabel:
                                addressNameService?.getValidateSuccessLabel?.(
                                  _value.address,
                                ) || t('Resolved'),
                              validationState: values.toAddressNameServiceState,
                              onValidate: async (address) => {
                                const addressNameServiceResponse =
                                  await addressNameService.onSubmit(address)
                                const field: FormField =
                                  'toAddressNameServiceState'
                                if (addressNameServiceResponse?.address) {
                                  const newValue: BaseSendSchema['toAddressNameServiceState'] =
                                    {
                                      status: 'valid',
                                      resolvedAddress:
                                        addressNameServiceResponse.address,
                                    }
                                  setFieldValue(field, newValue)
                                } else {
                                  const newValue: BaseSendSchema['toAddressNameServiceState'] =
                                    {
                                      status: 'invalid',
                                    }
                                  setFieldValue(field, newValue)
                                }
                              },
                              isLoading: addressNameService.isLoading,
                              extras: addressNameService.data?.extras,
                            }
                          : undefined
                      }
                    />
                  )}
                </DebouncedField>
              </Grid>
              {!hasErrorAfterTouched('toAddress') && addressMessage && (
                <Grid item xs={12} className={classes.commonBottomPadding}>
                  {addressMessage}
                </Grid>
              )}
              <Grid
                item
                xs={12}
                justifyContent="space-between"
                flexDirection="row"
                display="flex"
                alignItems="center"
                className={classes.commonBottomPadding}
              >
                <Typography variant="subtitle2">{t('Assets')}</Typography>
                {onAddAssetsClick && (
                  <Button
                    textTransform="none"
                    color="primary"
                    onClick={() => onAddAssetsClick()}
                  >
                    {t('+ Add assets')}
                  </Button>
                )}
              </Grid>

              {(ErrorContent || InfoContent) && (
                <Grid
                  item
                  xs={12}
                  className={clsx(
                    classes.formField,
                    classes.smallScreenDivider,
                  )}
                >
                  {ErrorContent || InfoContent}
                </Grid>
              )}
              {!tokenBalances && isSingleAsset ? (
                <Grid item xs={12} className={classes.commonBottomPadding}>
                  <NativeAssetAmountField
                    balance={account.balance}
                    blockchain={blockchain}
                    asset={values.assets[0] as NativeAssetSchema}
                    getAssetFieldProps={getAssetFieldProps}
                    errorMessage={firstAssetErrorMessage}
                    onChange={(v) => handleChange(firstAssetName)(v)}
                    name={firstAssetName}
                    onBlur={handleBlur(firstAssetName)}
                  />
                </Grid>
              ) : (
                <BlockchainSubsetGuard
                  blockchain={blockchain}
                  blockchainSubset={[...tokenBlockchains, ...nftBlockchains]}
                >
                  {(tokenBlockchain) => (
                    <Grid
                      item
                      xs={12}
                      className={clsx(
                        customField
                          ? classes.commonBottomMargin
                          : classes.formField,
                      )}
                    >
                      {isSingleAsset && tokenBalances ? (
                        <TokenAmountField
                          errorMessage={firstAssetErrorMessage}
                          onChange={(v) => handleChange(firstAssetName)(v)}
                          name={firstAssetName}
                          onBlur={handleBlur(firstAssetName)}
                          blockchain={blockchain}
                          asset={values.assets[0] as TokenSchema}
                          getAssetFieldProps={getAssetFieldProps}
                          tokenMetadata={tokenBalances.data}
                          balance={
                            tokenBalances.balances.find(
                              (t) => t.accountId === account.id,
                            )?.balance || new BigNumber(0)
                          }
                        />
                      ) : (
                        <SendAssetsList
                          allowDeletion
                          blockchain={tokenBlockchain}
                          account={account}
                          getAssetFieldProps={getAssetFieldProps}
                        />
                      )}
                    </Grid>
                  )}
                </BlockchainSubsetGuard>
              )}
              {customField && (
                <Grid item xs={12}>
                  {customField}
                </Grid>
              )}
            </Grid>
          </Box>
        </form>
      }
      footer={
        <ModalFooter hasDivider>
          {summary}
          <FooterLayout
            leftBtnConf={{
              onClick: onClose,
              children: t('Back'),
            }}
            rightBtnConf={{
              variant: 'contained',
              form: FORM_ID,
              type: 'submit',
              disabled,
              children: t('Continue'),
            }}
          />
        </ModalFooter>
      }
    />
  )
}

export type SendSummaryProps = {
  blockchain: Blockchain
  nativeAmountToSend?: BigNumber
  tokenAmountToSend?: BigNumber
  tokenMetadata?: TokenMetadata
  tokensToSendCount?: number
  feeProps?: {
    fee?: BigNumber
    isLoading?: boolean
    customLabel?: React.ReactNode
  }
  isMultiassetSend?: boolean
}

export function SendSummary({
  blockchain,
  nativeAmountToSend,
  tokenAmountToSend,
  tokenMetadata,
  tokensToSendCount,
  feeProps,
  isMultiassetSend,
}: SendSummaryProps) {
  const {t} = useTranslation()
  const classes = useStyles()
  const renderRow = (label: React.ReactNode, content: React.ReactNode) => (
    <Grid container alignItems="center" justifyContent="space-between">
      <Grid item xs={4}>
        <Typography fontWeight="fontWeightMedium">{label}</Typography>
      </Grid>
      <Grid item xs={8} display="flex" justifyContent="end">
        {content}
      </Grid>
    </Grid>
  )
  const renderFormattedAsset = (
    amount?: BigNumber,
    tokenMetadata?: TokenMetadata,
  ) => (
    <FormattedAsset
      includeAssetSymbol
      amount={amount && !amount.isNaN() ? amount : new BigNumber(0)}
      blockchain={blockchain}
      tokenMetadata={tokenMetadata}
      isSensitiveInformation={false}
    />
  )
  const renderFee = (amount?: BigNumber) => {
    // define font size to prevent potential size differences between text and loader
    const loadingFeeFontSize = 16
    if (feeProps?.isLoading)
      return (
        <Box component="span" fontSize={loadingFeeFontSize}>
          {t('Calculating fee...')} <InlineLoading size={loadingFeeFontSize} />
        </Box>
      )
    else if (!amount) return t('Not calculated')
    else return renderFormattedAssetAsCurrency(amount)
  }
  const renderFormattedAssetAsCurrency = (
    amount?: BigNumber,
    tokenMetadata?: TokenMetadata,
  ) => (
    <Box display="flex" flexWrap="wrap">
      <FormattedAssetSection>
        {renderFormattedAsset(amount, tokenMetadata)}
      </FormattedAssetSection>
      {amount && !amount.isNaN() && (
        <FormattedAssetSection>
          &nbsp;(~&nbsp;
          <WithConversionRates>
            {(conversionRates) => (
              <FormattedAssetAsCurrency
                includeCurrencySymbol
                balance={amount}
                isSensitiveInformation={false}
                tokenMetadata={tokenMetadata}
                blockchain={blockchain}
                conversionRates={conversionRates}
              />
            )}
          </WithConversionRates>
          )
        </FormattedAssetSection>
      )}
    </Box>
  )
  return (
    <Box className={clsx(classes.summary, classes.summaryFontSize)}>
      {renderRow(
        feeProps?.customLabel || t('Network fee:'),
        <>{renderFee(feeProps?.fee)}</>,
      )}
      {renderRow(
        t('Total:'),
        <>
          {tokenAmountToSend ? (
            <Box textAlign="right">
              {renderFormattedAsset(tokenAmountToSend, tokenMetadata)}
              <br />
              {nativeAmountToSend &&
                renderFormattedAssetAsCurrency(
                  nativeAmountToSend.plus(feeProps?.fee || new BigNumber(0)),
                )}
            </Box>
          ) : (
            <>
              {renderFormattedAssetAsCurrency(
                nativeAmountToSend?.plus(feeProps?.fee || new BigNumber(0)),
              )}
              {!!tokensToSendCount && isMultiassetSend && (
                <Box textAlign="right" component="span" ml={0.5}>
                  {' +'} {t('token_count', {count: tokensToSendCount})}
                </Box>
              )}
            </>
          )}
        </>,
      )}
    </Box>
  )
}

export function DefaultErrorCard({text}: {text: string}) {
  return (
    <Box mt={2}>
      <Alert severity="error" text={text} />
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  summary: {
    ...createCustomResponsiveClass(
      theme,
      {default: 100, zoomed: 75, old: 70},
      'minHeight',
    ),
  },
  summaryFontSize: {
    ...createCustomResponsiveClass(
      theme,
      {default: 16, zoomed: 14, old: 13},
      'fontSize',
    ),
  },
}))

const FormattedAssetSection = styled('span')({
  display: 'flex',
  flexWrap: 'nowrap',
  whiteSpace: 'nowrap',
})
