/* eslint-disable @typescript-eslint/explicit-function-return-type */

import {arraySum} from '@nufi/wallet-common'
import {getFlowBalanceReserve} from '@nufi/wallet-flow'
import type {
  FlowAccountDerivationParams,
  FlowAccountInfo,
  FlowTxPlan,
  Nanoflow,
} from '@nufi/wallet-flow'
import {useQuery} from '@tanstack/react-query'
import BigNumber from 'bignumber.js'

import {fetchNeverStaleQuery, useNeverStaleQuery} from 'src/utils/query-utils'

import {getMockServices} from '../../../../__tests__/storybook/MockServiceLocator'
import queryClient from '../../../../queryClient'
import {assert} from '../../../../utils/assertion'
import {discoverHwAccounts, getAccounts} from '../../../public/accounts'
import {
  cachedGetAccounts as cachedGetGenericAccounts,
  useGetAccounts as useGetGenericAccounts,
} from '../../../public/queries/accounts'
import type {AccountId, AllAvailableAccountsResponse} from '../../../types'
import {
  DEFAULT_DISCOVERY_QUERY_OPTIONS,
  minutesToMilliseconds,
} from '../../../utils/common'
import {flow} from '../../flowManagers'
import {
  getAccountInfoForDiscoveryResponse,
  getTotalStakingBalance,
  QUERY_KEY_PREFIX as P,
} from '../utils'

export const coreQueryKeys = {
  accounts: getAccounts.__key(P),
  maxSendableAmount: (id: string, txType: FlowTxPlan['type']) => [
    P,
    'maxSendableAmount',
    id,
    txType,
  ],
  validators: [P, 'validators'],
  discoverHotAccounts: [P, 'discoverHotAccounts'],
  discoverLedgerAccounts: (
    derivationType: FlowAccountDerivationParams['type'],
  ) => [...discoverHwAccounts.__key(P, 'ledger'), derivationType],
  getTransactionFee: [P, 'transactionFee'],
  isStakingEnabled: [P, 'isStakingEnabled'],
  isAddressValid: (address: string) => [P, 'isAddressValid', address],
  resolveAddressName: (name: string) => [P, 'resolveAddressName', name],
  getTotalNativeBalance: [P, 'getTotalNativeBalance'],
  getCurrentEpochInfo: [P, 'getCurrentEpochInfo'],
  getMinimalStake: [P, 'getMinimalStake'],
}

//
// __CACHED__ QUERIES
//

export const useGetAPY = (enabled = true) =>
  useQuery({
    queryKey: ['flowAPY'],
    queryFn: async () => {
      const apy = await flow.wallet.getAPY()
      return new BigNumber(apy)
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled,
  })

export const useGetValidators = () =>
  useQuery({
    queryKey: coreQueryKeys.validators,
    queryFn: async () => await flow.wallet.getValidatorNodes(),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  })

export function useDiscoverAllHotAccounts() {
  return useQuery({
    queryKey: coreQueryKeys.discoverHotAccounts,
    queryFn: async (): Promise<
      AllAvailableAccountsResponse<FlowAccountInfo>
    > => {
      const accounts = flow.accountsStore.getAllAccounts()
      const response = await flow.wallet.discoverAllHotAccounts(accounts)
      return getAccountInfoForDiscoveryResponse(response)
    },
    ...DEFAULT_DISCOVERY_QUERY_OPTIONS,
  })
}

export function useDiscoverAllLedgerAccounts(
  derivationType: FlowAccountDerivationParams['type'],
) {
  return useQuery({
    queryKey: coreQueryKeys.discoverLedgerAccounts(derivationType),
    queryFn: async (): Promise<
      AllAvailableAccountsResponse<FlowAccountInfo>
    > => {
      const accounts = flow.accountsStore.getAllAccounts()
      const response = await flow.wallet.discoverAllLedgerAccounts(
        accounts,
        derivationType,
      )
      return getAccountInfoForDiscoveryResponse(response)
    },
    ...DEFAULT_DISCOVERY_QUERY_OPTIONS,
  })
}

export const useGetIsStakingEnabled = () =>
  useQuery({
    queryKey: coreQueryKeys.isStakingEnabled,
    queryFn: () => flow.wallet.isStakingEnabled(),
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  })

export type GetFlowCurrentEpochInfo =
  typeof flow.blockchainApi.getCurrentEpochInfo

export const useGetCurrentEpochInfo = () => {
  const mockServices = getMockServices()
  return useQuery({
    queryKey: coreQueryKeys.getCurrentEpochInfo,
    queryFn: () => {
      return mockServices
        ? mockServices.flow.getCurrentEpochInfo()
        : flow.blockchainApi.getCurrentEpochInfo()
    },
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  })
}

//
// __COMPUTED__ QUERIES
//

export const cachedGetAccounts = async (): Promise<FlowAccountInfo[]> => {
  return cachedGetGenericAccounts<FlowAccountInfo>('flow')
}

export const useGetAccounts = (enabled = true) => {
  return useGetGenericAccounts<FlowAccountInfo>('flow', enabled)
}

async function getTotalNativeBalance() {
  const accounts = await cachedGetAccounts()

  const balance = arraySum(accounts.map((a) => a.balance))
  const stakingBalance = arraySum(
    accounts.map(({stakingInfo}) => getTotalStakingBalance(stakingInfo)),
  )
  return balance.plus(stakingBalance)
}

getTotalNativeBalance.__key = coreQueryKeys.getTotalNativeBalance

export function cachedGetTotalNativeBalance() {
  return fetchNeverStaleQuery({
    queryKey: getTotalNativeBalance.__key,
    queryFn: getTotalNativeBalance,
  })
}

export function useGetTotalNativeBalance(enabled = true) {
  return useNeverStaleQuery({
    queryKey: getTotalNativeBalance.__key,
    queryFn: getTotalNativeBalance,
    enabled,
  })
}

export type MaxSendableFlowAmountArgs =
  | {
      accountInfo: FlowAccountInfo
      txType: FlowTxPlan['type']
    }
  | undefined

async function _getMaxSendableFlowAmount(args: MaxSendableFlowAmountArgs) {
  const {accountInfo = undefined, txType = undefined} = args != null ? args : {}
  assert(!!accountInfo && !!txType)
  const txFee = await flow.wallet.getTransactionFee()
  const balanceReserve = getFlowBalanceReserve(txType)
  const availableBalance = (
    await flow.accountManager.getSpendableBalance(accountInfo.address)
  ).minus(balanceReserve)
  return {
    maxAmount: BigNumber.max(availableBalance.minus(txFee), 0) as Nanoflow,
    availableBalance,
    fee: txFee,
  }
}

export function useGetMaxSendableFlowAmount(
  args: MaxSendableFlowAmountArgs,
  enabled = true,
) {
  const {accountInfo = undefined, txType = undefined} = args != null ? args : {}
  return useQuery({
    queryKey: coreQueryKeys.maxSendableAmount(
      accountInfo?.id || ('' as AccountId),
      txType || ('' as FlowTxPlan['type']),
    ),
    queryFn: () => _getMaxSendableFlowAmount(args),
    ...useGetMaxSendableFlowAmount.__options,
    enabled,
  })
}

useGetMaxSendableFlowAmount.__options = {
  staleTime: minutesToMilliseconds(1),
  refetchOnWindowFocus: false,
}

export async function getMaxSendableFlowAmountImperative(
  accountInfo: FlowAccountInfo,
  txType: FlowTxPlan['type'],
) {
  return await queryClient.fetchQuery({
    queryKey: coreQueryKeys.maxSendableAmount(accountInfo.id, txType),
    queryFn: () => _getMaxSendableFlowAmount({accountInfo, txType}),
    ...useGetMaxSendableFlowAmount.__options,
  })
}

export async function getIsAddressValidImperative({
  address,
}: {
  address: string
}) {
  return await queryClient.fetchQuery({
    queryKey: coreQueryKeys.isAddressValid(address),
    queryFn: () => flow.wallet.isAddressValid(address),
  })
}

export async function getMinStakableFlowAmountImperative() {
  return await queryClient.fetchQuery({
    queryKey: coreQueryKeys.getMinimalStake,
    queryFn: () => flow.blockchainApi.getMinimalStake(),
  })
}
