import {
  getParsedEthersError,
  RETURN_VALUE_ERROR_CODES,
} from '@enzoferey/ethers-error-parser'
import {assert, safeAssertUnreachable} from '@nufi/frontend-common'
import type {HexString} from '@nufi/wallet-common'
import BigNumber from 'bignumber.js'
import {ethers} from 'ethers'

import type {UntypedEvmBlockchain} from './evmBlockchain'
import type {
  ChecksummedEvmAddress,
  EvmAddress,
  EvmChainId,
  EvmContractAddress,
  EvmFeeHistoryResult,
  EvmGwei,
  EvmTokenId,
  EvmWei,
  PlainEvmAddress,
  TokenStandard,
} from './types'

export const isValidEvmAddress = <TAddress extends EvmAddress>(
  address: string,
): address is TAddress => ethers.utils.isAddress(address)

export const nativeToWei = <TBlockchain extends UntypedEvmBlockchain>(
  input: string,
): EvmWei<TBlockchain> => {
  const simpleFormatInput = Number(input).toLocaleString('en', {
    useGrouping: false,
    minimumFractionDigits: 18, // otherwise would cut the rest of decimals
  }) // transforms scientific format (e.g. 1e7) number to simple number format (e.g. 10000000)
  return new BigNumber(
    ethers.utils.parseEther(simpleFormatInput).toString(), // can throw errors
    // https://vacuum.atlassian.net/browse/ADLT-1345
  ) as EvmWei<TBlockchain>
}

export const weiToNative = <TBlockchain extends UntypedEvmBlockchain>(
  input: EvmWei<TBlockchain>,
): string => ethers.utils.formatUnits(input.toString(), 'ether')

export const gweiToWei = <TBlockchain extends UntypedEvmBlockchain>(
  input: string,
): EvmWei<TBlockchain> =>
  new BigNumber(input).times(1_000_000_000) as EvmWei<TBlockchain>

export const weiToGwei = <TBlockchain extends UntypedEvmBlockchain>(
  input: EvmWei<TBlockchain>,
): EvmGwei<TBlockchain> => input.div(1_000_000_000) as EvmGwei<TBlockchain>

export const calculateMaxFeePerGas = <TBlockchain extends UntypedEvmBlockchain>(
  priorityFeePerGas: EvmWei<TBlockchain>,
  baseFeePerGas: EvmWei<TBlockchain>,
): EvmWei<TBlockchain> =>
  priorityFeePerGas.plus(baseFeePerGas) as EvmWei<TBlockchain>

export const calculateLondonFee = <TBlockchain extends UntypedEvmBlockchain>({
  maxFeePerGas,
  gasLimit,
}: {
  maxFeePerGas: EvmWei<TBlockchain>
  gasLimit: BigNumber
}): EvmWei<TBlockchain> => {
  const fee = maxFeePerGas.times(gasLimit)
  return (!fee.isNaN() ? fee : new BigNumber(0)) as EvmWei<TBlockchain>
}

export const calculateSimpleFee = <TBlockchain extends UntypedEvmBlockchain>({
  gasPrice,
  gasLimit,
}: {
  gasPrice: EvmWei<TBlockchain>
  gasLimit: BigNumber
}): EvmWei<TBlockchain> =>
  calculateLondonFee({maxFeePerGas: gasPrice, gasLimit})

export const pubKeyToAddress = <TBlockchain extends UntypedEvmBlockchain>(
  pubkey: string,
): ChecksummedEvmAddress<TBlockchain> =>
  ethers.utils.computeAddress(pubkey) as ChecksummedEvmAddress<TBlockchain>

export const parseUnits = ethers.utils.parseUnits

export const parseTransaction = ethers.utils.parseTransaction

export const toPlainEvmAddress = <TBlockchain extends UntypedEvmBlockchain>(
  addr: EvmAddress<TBlockchain>,
): PlainEvmAddress<TBlockchain> => {
  return addr.toLocaleLowerCase() as PlainEvmAddress<TBlockchain>
}

export const toChecksummedEvmAddress = <
  TBlockchain extends UntypedEvmBlockchain,
>(
  address: EvmAddress<TBlockchain>,
): ChecksummedEvmAddress<TBlockchain> => {
  return ethers.utils.getAddress(address) as ChecksummedEvmAddress<TBlockchain>
}

const NFT_ID_SEPARATOR = '-'
const getEvmNftId = <TBlockchain extends UntypedEvmBlockchain>(
  contractAddress: EvmContractAddress<TBlockchain>,
  id: string,
): EvmTokenId<TBlockchain> =>
  [contractAddress, id].join(NFT_ID_SEPARATOR) as EvmTokenId<TBlockchain>

export const extractContractAddress = <
  TBlockchain extends UntypedEvmBlockchain,
>(
  id: EvmTokenId<TBlockchain>,
): EvmContractAddress<TBlockchain> =>
  id.split(NFT_ID_SEPARATOR)[0] as EvmContractAddress<TBlockchain>

export const extractNftTokenId = <TBlockchain extends UntypedEvmBlockchain>(
  id: EvmTokenId<TBlockchain>,
): string => id.split(NFT_ID_SEPARATOR)[1]!

export type GetEvmTokenIdParams<TBlockchain extends UntypedEvmBlockchain> = {
  contractAddress: EvmContractAddress<TBlockchain>
} & (
  | {
      standard: Extract<TokenStandard, 'ERC20'>
    }
  | {
      // SPECIAL_NFT is not part of TokenStandard as we do not yet support
      // transfers for it.
      standard: Exclude<TokenStandard, 'ERC20'> | 'SPECIAL_NFT'
      nftWithinCollectionId: string
    }
)

export const getEvmTokenId = <TBlockchain extends UntypedEvmBlockchain>(
  params: GetEvmTokenIdParams<TBlockchain>,
): EvmTokenId<TBlockchain> => {
  const tokenStandard = params.standard
  const checksummedContractAddress = toChecksummedEvmAddress<TBlockchain>(
    params.contractAddress as string as EvmAddress<TBlockchain>,
  ) as string as EvmContractAddress<TBlockchain>

  switch (tokenStandard) {
    case 'ERC20':
      return checksummedContractAddress as string as EvmTokenId<TBlockchain>
    case 'ERC721':
    case 'ERC1155':
    case 'SPECIAL_NFT':
      return getEvmNftId<TBlockchain>(
        checksummedContractAddress,
        params.nftWithinCollectionId,
      )
    default:
      return safeAssertUnreachable(tokenStandard)
  }
}

export const getPriorityFeesPerBlock = (
  result: EvmFeeHistoryResult,
): {
  slow: BigNumber
  medium: BigNumber
  high: BigNumber
}[] =>
  result.reward.map((rewardPerPercentile) => {
    assert(
      rewardPerPercentile?.length === 3,
      `Unexpected count of reward elements: ${rewardPerPercentile?.length}`,
    )

    const [slow, medium, high] = rewardPerPercentile.map(
      (str) => new BigNumber(str),
    )

    return {
      slow: slow!,
      medium: medium!,
      high: high!,
    }
  })

export function handleEthersError<T>(
  error: unknown,
  expectedErrorFallbackReturnValue: T,
): T {
  const parsedEthersError = getParsedEthersError(error as Error)
  if (parsedEthersError.errorCode === RETURN_VALUE_ERROR_CODES.UNKNOWN_ERROR) {
    throw error
  }

  // we expect other errors - contract not found on network, does not support balanceOf, etc.
  return expectedErrorFallbackReturnValue
}

export const toHexString = (
  bigNumberish: ethers.BigNumberish | undefined,
): HexString => {
  assert(bigNumberish != null)
  return ethers.BigNumber.from(bigNumberish).toHexString() as HexString
}

/** Helper for casting a number literal to EvmChainId without losing its literal type. */
export const asEvmChainId = <const T extends number>(
  chainId: T,
): EvmChainId<T> => chainId as EvmChainId<typeof chainId>

/** Helper for converting EvmChainId to CAIP-2 chain id without losing its literal type. */
export const evmChainIdToCaip2 = <T extends EvmChainId>(
  chainId: T,
): `eip155:${ExtractChainNumber<T>}` =>
  `eip155:${chainId}` satisfies `eip155:${EvmChainId}` as `eip155:${ExtractChainNumber<T>}`

type ExtractChainNumber<T extends EvmChainId> =
  T extends EvmChainId<infer U extends number> ? U : never
