/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-use-before-define */

import type {
  NftBlacklist,
  SolanaAccountInfo,
  SolanaNft,
  SolanaTokenId,
  SolanaTokenMetadata,
} from '@nufi/wallet-solana'
import {
  isNftCandidate,
  defaultTokenFromTokenId,
  getIsOcpNft,
} from '@nufi/wallet-solana'

import {fetchNeverStaleQuery, useNeverStaleQuery} from 'src/utils/query-utils'

import config from '../../../../config'
import {getAllNfts as getAllNftsGeneric} from '../../../public/nfts'
import {solana} from '../../solanaManagers'

import {cachedGetNftBlacklist} from './blacklist'
import {cachedGetAccounts} from './common'
import {QUERY_KEY_PREFIX as P} from './utils'

export const nftsQueryKeys = {
  index: [P, 'nfts'],
  nftItem: (id: SolanaTokenId | null) => [...nftsQueryKeys.index, 'nft', id],
  getIsOcpNft: (token: SolanaTokenMetadata) => [P, 'isOcpNft', token.id],
} as const

//
// __CACHED__ QUERIES
//

const getNft = async (tokenId: SolanaTokenId) => {
  const token = defaultTokenFromTokenId(tokenId)
  const onChainMeta = (await solana.wallet.getTokenMetadataOnChain([token]))[0]!
  if (isNftCandidate(onChainMeta)) {
    const nftBlacklist = await cachedGetNftBlacklist()
    return await solana.wallet.getNft(tokenId, nftBlacklist)
  }
  return null
}

export function useGetNft(tokenId: SolanaTokenId, enabled = true) {
  return useNeverStaleQuery({
    queryKey: useGetNft.__key(tokenId),
    queryFn: () => getNft(tokenId),
    enabled,
  })
}

export function cachedGetNft(tokenId: SolanaTokenId) {
  return fetchNeverStaleQuery({
    queryKey: useGetNft.__key(tokenId),
    queryFn: () => getNft(tokenId),
  })
}

useGetNft.__key = nftsQueryKeys.nftItem

async function getAccountNfts(
  account: SolanaAccountInfo,
  nftBlacklist: NftBlacklist,
) {
  const accountNfts = (
    await Promise.all([
      solana.wallet.getStandardNfts(account.publicKey, nftBlacklist),
      solana.wallet.getCompressedNfts(account.publicKey, nftBlacklist),
    ])
  ).flat()
  return config.isNftHidingEnabled
    ? accountNfts
    : accountNfts.filter((nft) => !nft.isHidden)
}

async function getAllNfts() {
  const [accounts, nftBlacklist] = await Promise.all([
    cachedGetAccounts(),
    cachedGetNftBlacklist(),
  ])

  return getAllNftsGeneric({
    blockchain: 'solana',
    accounts,
    getAccountNfts: (account: SolanaAccountInfo) =>
      getAccountNfts(account, nftBlacklist),
    getSingleNftQueryKey: (nft: SolanaNft) => useGetNft.__key(nft.id),
  })
}

getAllNfts.__key = nftsQueryKeys.index

export function cachedGetAllNfts() {
  return fetchNeverStaleQuery({queryKey: getAllNfts.__key, queryFn: getAllNfts})
}

export function useGetAllNfts(enabled: boolean) {
  return useNeverStaleQuery({
    queryKey: getAllNfts.__key,
    queryFn: getAllNfts,
    enabled,
  })
}

export function cachedGetIsOcpNft(token: SolanaTokenMetadata) {
  return fetchNeverStaleQuery({
    queryKey: nftsQueryKeys.getIsOcpNft(token),
    queryFn: () => getIsOcpNft(token, solana.blockchainApi.getAccountInfo),
  })
}
