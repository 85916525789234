import {Link, Typography} from '@mui/material'
import React from 'react'

import {
  getSafeAddressExplorerLink,
  CopyToClipboard,
  ellipsizeString,
} from 'src/components'
import type {Blockchain} from 'src/types'

type EllipsizedAddressProps = {
  address: string
  blockchain: Blockchain
}

export function EllipsizedAddress({
  address,
  blockchain,
}: EllipsizedAddressProps) {
  return (
    <CopyToClipboard
      value={address}
      Label={
        <Typography variant="body2">
          <Link
            href={getSafeAddressExplorerLink(address, blockchain)}
            title={address}
            target="_blank"
            variant="subtitle2"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {ellipsizeString(address, 5, 3)}
          </Link>
        </Typography>
      }
      iconFontSize="inherit"
      hoverableBackgroundFontSize="small"
    />
  )
}
