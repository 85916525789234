import {Box, Divider} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {colors} from '../../../theme/palette'
import {isIncognito} from '../../../utils/userAgentDetection'
import {IncognitoBannerLink} from '../../Links'
import {Button, Icon, Paper} from '../atoms'

import {LabeledIcon} from './LabeledIcon'

export function useIncognitoHandlers() {
  const [isIncognitoWindow, setIsIncognitoWindow] = useState<boolean | null>(
    null,
  )
  const [didHideIncognitoBanner, setDidHideIncognitoBanner] = useState(false)

  useEffect(() => {
    async function fn() {
      setIsIncognitoWindow(await isIncognito())
    }
    fn()
  })

  const showIncognitoBanner = !didHideIncognitoBanner && isIncognitoWindow
  const onClose = () => setDidHideIncognitoBanner(true)

  return {showIncognitoBanner, onClose}
}

type IncognitoBannerProps = {
  onClose: () => void
}

export function IncognitoBanner({onClose}: IncognitoBannerProps) {
  const {t} = useTranslation()
  const classes = useStyles()
  return (
    <>
      <Paper elevation={0} className={classes.wrapper}>
        <Box
          px={3}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <LabeledIcon
            Icon={<Icon type="incognitoIcon" />}
            Label={
              <Box className={classes.text}>
                <IncognitoBannerLink />
              </Box>
            }
            iconPosition="start"
            spacing={1}
          />
          <Button color="primary" onClick={onClose}>
            {t('Dismiss')}
          </Button>
        </Box>
      </Paper>
      <Divider />
    </>
  )
}

export function TopScreenIncognitoBanner({...props}: IncognitoBannerProps) {
  return (
    <Box position="absolute" width="100vw" top={0} zIndex={2}>
      <IncognitoBanner {...props} />
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    background: theme.palette.background.default,
    border: 'none',
  },
  banner: {
    padding: theme.spacing(0, 3),
  },
  text: {
    color: colors.warning.alertContent,
  },
}))
