import type {TooltipProps} from '@mui/material'
import {Tooltip, Box} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'

type WithTooltipType = Omit<TooltipProps, 'children'> & {
  children: React.ReactNode
  fontVariant?: 'small' | 'medium'
}

export default function WithTooltip({
  children,
  ...tooltipProps
}: WithTooltipType) {
  const classes = useStyles()
  const fontVariant = tooltipProps.fontVariant || 'small'
  return (
    <Tooltip
      {...tooltipProps}
      classes={{
        tooltip: fontVariant === 'medium' ? classes.mediumText : undefined,
      }}
    >
      <Box display="flex" alignItems="inherit" justifyContent="inherit">
        {children}
      </Box>
    </Tooltip>
  )
}

const useStyles = makeStyles(() => ({
  mediumText: {
    fontSize: 13,
  },
}))
