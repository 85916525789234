import React from 'react'

import type {DerivationPathType, HwVendor} from '../../../../types'

import {AddAccountForm} from './AddAccountForm'
import type {AddHwAccountPhase} from './AddAccountUtils'
import {useAddHwAccountPhase} from './AddAccountUtils'
import type {CommonAddAccountFormProps} from './types'

export function useAddHwAccountState() {
  const [addAccountPhase, setAddAccountPhase] = useAddHwAccountPhase()
  return {
    addAccountPhase,
    setAddAccountPhase,
  }
}

type AddHwAccountProps<T extends DerivationPathType> =
  CommonAddAccountFormProps<T> & {
    addAccountPhase: AddHwAccountPhase
    setAddAccountPhase: (phase: AddHwAccountPhase) => void
    hwVendor: HwVendor
  }

export function AddHwAccount<T extends DerivationPathType>({
  hwVendor,
  blockchain,
  setAddAccountPhase,
  extras,
  ...rest
}: AddHwAccountProps<T>) {
  return (
    <AddAccountForm
      cryptoProviderType={hwVendor}
      blockchain={blockchain}
      setAddAccountPhase={setAddAccountPhase}
      extras={extras}
      {...rest}
    />
  )
}
