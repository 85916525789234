import type {
  DefaultError,
  FetchQueryOptions,
  QueryFunction,
  QueryKey,
  UseQueryOptions,
} from '@tanstack/react-query'
import {useQuery} from '@tanstack/react-query'

import queryClient from '../../queryClient'

/**
 * `useQuery` wrapper with `staleTime` set to `Infinity`. This means the query cache is never
 * considered stale and won't be invalidated unless invalidation is triggered "manually".
 */
export const useNeverStaleQuery = <
  TQueryFnData = unknown,
  TError = DefaultError,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  args: {
    queryKey: TQueryKey
    queryFn: QueryFunction<TQueryFnData, TQueryKey>
  } & Omit<
    UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
    'queryKey' | 'queryFn' | 'staleTime'
  >,
) =>
  useQuery({
    ...args,
    staleTime: Infinity,
  })

/**
 * `queryClient.fetchQuery` wrapper with `staleTime` set to `Infinity`. This means the query cache
 * is never considered stale and won't be invalidated unless invalidation is triggered "manually".
 */
export const fetchNeverStaleQuery = <
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  args: Omit<
    FetchQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
    'staleTime'
  >,
  // We are avoiding retries as this is meant to be used within `useQuery`
  // resp. `useNeverStaleQuery` that has retry mechanism anyways. Otherwise
  // the retry could be happening on many layers, slowing down the error
  // feedback a lot.
): Promise<TData> =>
  queryClient.fetchQuery({
    ...args,
    staleTime: Infinity,
    retry: false,
  })
