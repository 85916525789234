import {useMetamaskContext} from 'src/metamaskSnap/MetamaskContext'
import {useAuthStore} from 'src/store/auth'
import {useMutation} from 'src/utils/mutation-utils'

import * as metamaskService from '../../services/metamaskLogin'

export const useSetMetamaskProfileLoggedIn = () =>
  useAuthStore((state) => state.setMetamaskLoggedIn)

export const useMetamaskLogin = (
  options?: metamaskService.MetamaskRawLoginOptions,
) => {
  const metamaskContext = useMetamaskContext()
  return useMutation('metamaskLogin', () =>
    metamaskService.metamaskRawLogin(metamaskContext, options),
  )
}
