import {Box, Divider} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {safeAssertUnreachable} from '@nufi/frontend-common'
import type BigNumber from 'bignumber.js'
import clsx from 'clsx'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {commonParseTokenAmount, parseNativeAmount} from 'src/wallet'

import {AmountRow, AssetInfo, TotalRow} from '../../../components'
import type {Blockchain} from '../../../types'

import type {AssetSchema, TxSummarySendContext} from './types'
import {
  ensureNativeAmount,
  ensureSingleTokenAmount,
  getAmountsToSend,
} from './utils'

type TransferDetailsSummaryProps = {
  blockchain: Blockchain
  fee: BigNumber
  assets: AssetSchema[]
  sendContext: TxSummarySendContext
}

export const TransferDetailsSummary = ({
  blockchain,
  assets,
  fee,
  sendContext,
}: TransferDetailsSummaryProps) => {
  const {t} = useTranslation()
  const classes = useStyles()

  const {tokensToSendCount, singleTokenAmount, totalNativeAmountSpent} =
    getAmountsToSend({
      blockchain,
      txFee: fee,
      assets,
      sendContext,
    })

  const assetsWithMetadata = (() => {
    switch (sendContext.type) {
      case 'multiAsset': {
        return assets.map((asset) =>
          asset.type === 'native'
            ? asset
            : {
                ...asset,
                tokenMetadata: sendContext.metadataById[asset.tokenId]!,
              },
        )
      }
      case 'token': {
        return [
          {
            tokenId: sendContext.tokenId,
            amount: ensureSingleTokenAmount(assets),
            type: sendContext.type,
            tokenMetadata: sendContext.tokenMetadata,
          },
        ]
      }
      case 'native': {
        return [{type: sendContext.type, amount: ensureNativeAmount(assets)}]
      }
      default: {
        return safeAssertUnreachable(sendContext)
      }
    }
  })()

  const assetRows = assetsWithMetadata.map((asset) => {
    if (asset.type === 'token') {
      return (
        <Box key={asset.tokenId}>
          <AssetInfo
            type="token"
            tokenMetadata={asset.tokenMetadata}
            amount={commonParseTokenAmount(
              asset.amount,
              asset.tokenMetadata.decimals,
            )}
          />
        </Box>
      )
    } else {
      return (
        <Box key={asset.type}>
          <AssetInfo
            type="native"
            amount={parseNativeAmount(blockchain, asset.amount)}
            blockchain={blockchain}
          />
        </Box>
      )
    }
  })

  const asset =
    sendContext.type !== 'native'
      ? assetsWithMetadata.find(
          (a) => a.type === 'token' && a.tokenId === sendContext.tokenId,
        )
      : undefined

  return (
    <Box>
      {assetRows}
      <AmountRow
        key="fee"
        label={t('Network fees')}
        amount={fee}
        blockchain={blockchain}
      />
      <Divider className={clsx(classes.divider, classes.smallScreenDivider)} />
      <TotalRow
        totalText={t('Total')}
        feeText={t('(Amount + Fees)')}
        tokenMetadata={
          asset?.type === 'token' ? asset.tokenMetadata : undefined
        }
        nativeAmount={totalNativeAmountSpent}
        tokenAmount={singleTokenAmount}
        tokensToSendCount={tokensToSendCount}
        blockchain={blockchain}
      />
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  divider: {
    margin: theme.spacing(0.5, 0),
    [theme.breakpoints.up('windowsOld')]: {
      margin: theme.spacing(0.75, 0),
    },
    [theme.breakpoints.up('windowsZoomed')]: {
      margin: theme.spacing(1, 0),
    },
  },
  smallScreenDivider: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('windowsOld')]: {
      marginTop: theme.spacing(1),
    },
    [theme.breakpoints.up('windowsZoomed')]: {
      marginTop: theme.spacing(0),
    },
  },
}))
