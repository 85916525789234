import {Typography, styled} from '@mui/material'
import React from 'react'

type TxDataDetailsHeaderProps = {
  label: string
}

export const TxDataDetailsHeader = styled(
  ({label}: TxDataDetailsHeaderProps) => (
    <Typography variant="subtitle2" align="center">
      {label}
    </Typography>
  ),
)(({theme}) => ({
  paddingBottom: theme.spacing(1),
}))
