import {safeAssertUnreachable} from '@nufi/frontend-common'
import {Formik} from 'formik'
import React from 'react'
import {useTranslation} from 'react-i18next'
import * as yup from 'yup'

import {IS_EMBEDDED_MOONPAY_SELL_ENABLED} from 'src/constants'

import {RampOperation} from '../../../../domain'
import type {RampCryptoAsset} from '../../../../domain'
import {useRampFlowConfig} from '../../state/rampFlowConfig'
import type {RampFlowState} from '../../state/rampFlowState'

import type {RampFlowDetailsFormFields} from './form/detailsForm'
import type {UnSupportedOperationProps} from './form/RampFlowDetailsForm'
import {RampFlowDetailsForm} from './form/RampFlowDetailsForm'

export type RampFlowDetailsScreenProps = {
  initialAsset: RampFlowState['asset']
  initialAccount: RampFlowState['account']
  initialOperation: RampFlowState['operation']
} & UnSupportedOperationProps

export const RampFlowDetailsScreen = ({
  initialAsset,
  initialAccount,
  initialOperation,
  getIsOperationSupportedInApp,
  renderOperationNotSupportedContent,
}: RampFlowDetailsScreenProps) => {
  const {t} = useTranslation()
  const {dispatch, assetSelectFilter} = useRampFlowConfig()

  type Values = RampFlowDetailsFormFields

  const isSellEnabled = IS_EMBEDDED_MOONPAY_SELL_ENABLED

  const schema = yup
    .object({
      operation: yup
        .mixed<RampOperation>()
        .test(
          'is-sell-enabled',
          t('Selling is not currently available'),
          (operation) => isSellEnabled || operation !== 'sell',
        )
        .required(t('Please select an operation')),
      asset: yup.mixed<RampCryptoAsset>().required(t('Please select an asset')),
      account: yup
        .object({
          address: yup.string().required(t('Please select an account')),
        })
        .required(t('Please select an account')),
    })
    .test('is-asset-supported', ({asset, operation}, ctx) => {
      /* This is an additional check in case the assetSelectFilter changes after the user selects an asset */
      if (!asset || !operation) return true // not filled yet
      if (!assetSelectFilter || assetSelectFilter(asset, operation)) return true // test passed
      // test failed
      return ctx.createError({
        message:
          operation === RampOperation.BUY
            ? t('This asset cannot be bought')
            : operation === RampOperation.SELL
              ? t('This asset cannot be sold')
              : safeAssertUnreachable(operation),
        path: 'asset',
      })
    }) satisfies {__outputType: Values}

  return (
    <Formik<Values>
      initialValues={{
        operation: initialOperation,
        account: initialAccount ?? {address: ''},
        asset: initialAsset,
      }}
      validationSchema={schema}
      onSubmit={(values) => {
        type Validated = yup.InferType<typeof schema>
        const {operation, asset, account} = values as Validated
        switch (operation) {
          case RampOperation.BUY:
            dispatch({
              type: 'proceedToWidget',
              payload: {operation, asset, account},
            })
            return
          case RampOperation.SELL:
            dispatch({
              type: 'proceedToWidget',
              payload: {operation, asset, account},
            })
            // todo(pj)[moonpay]: route to sell widget when implemented
            return
          default:
            safeAssertUnreachable(operation)
        }
      }}
    >
      <RampFlowDetailsForm
        getIsOperationSupportedInApp={getIsOperationSupportedInApp}
        renderOperationNotSupportedContent={renderOperationNotSupportedContent}
      />
    </Formik>
  )
}
