import type {EvmBlockchain} from '@nufi/wallet-evm'
import {isEvmBlockchain} from '@nufi/wallet-evm'

import type {Blockchain} from 'src/types'
import {blockchainMetadata} from 'src/wallet/constants'

export const walletKinds = [
  ...(Object.keys(blockchainMetadata) as Blockchain[]).filter(
    (b): b is Exclude<Blockchain, EvmBlockchain> => !isEvmBlockchain(b),
  ),
  'evm',
] as const

export type WalletKind = (typeof walletKinds)[number]

export function isWalletKind(value: unknown): value is WalletKind {
  return walletKinds.includes(value as (typeof walletKinds)[number])
}

export function blockchainToWalletKind(blockchain: Blockchain): WalletKind {
  return isEvmBlockchain(blockchain) ? 'evm' : blockchain
}
