import {Grid} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {
  HidableGridItem,
  Aligner,
  ListHeaderTitle,
  ListLayoutWrapper,
  ListHeaderWrapper,
} from '../../../../components'

export function StakeHistoryListHeader() {
  const {t} = useTranslation()

  return (
    <ListHeaderWrapper>
      <StakeHistoryListItemLayout
        typeCol={<ListHeaderTitle>{t('Type')}</ListHeaderTitle>}
        amountCol={<ListHeaderTitle>{t('Amount')}</ListHeaderTitle>}
        informationCol={<ListHeaderTitle>{t('Information')}</ListHeaderTitle>}
        explorerCol={<ListHeaderTitle>{t('Explorer')}</ListHeaderTitle>}
      />
    </ListHeaderWrapper>
  )
}

export type StakeHistoryListItemLayoutProps = {
  typeCol: React.ReactNode
  amountCol: React.ReactNode
  informationCol: React.ReactNode
  explorerCol: React.ReactNode
}

export function StakeHistoryListItemLayout({
  typeCol,
  amountCol,
  informationCol,
  explorerCol,
}: StakeHistoryListItemLayoutProps) {
  const classes = useStyles()
  return (
    <ListLayoutWrapper>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item xs={4} md={4}>
          <Aligner align="left">{typeCol}</Aligner>
        </Grid>
        <Grid item xs={2} md={2}>
          <Aligner align="right">{amountCol}</Aligner>
        </Grid>
        <Grid item xs={4} md={3} className={classes.informationColumn}>
          <Aligner align="left">{informationCol}</Aligner>
        </Grid>
        <HidableGridItem xs={2} md={3}>
          <Aligner align="right">{explorerCol}</Aligner>
        </HidableGridItem>
      </Grid>
    </ListLayoutWrapper>
  )
}

const useStyles = makeStyles((theme) => ({
  informationColumn: {
    paddingLeft: theme.spacing(4),
  },
}))
