import type {TypographyProps} from '@mui/material'
import {Box, Typography, Chip} from '@mui/material'
import {assert} from '@nufi/frontend-common'
import {hasEvmBlockchainProp} from '@nufi/wallet-evm'
import BigNumber from 'bignumber.js'
import React from 'react'
import {useTranslation, Trans} from 'react-i18next'

import {isNft} from 'src/wallet'

import {QueryGuard, TransBoldNoBreak} from '../../../../../components'
import type {
  EvmAccountOfflineInfo,
  EvmAddress,
  EvmBlockchain,
  EvmTokenId,
  EvmWei,
} from '../../../../../wallet/evm'
import {
  useGetKnownTokenIds,
  useGetTokenMetadata,
} from '../../../../../wallet/evm'
import type {
  TxAssetChangesSimulationResponse,
  TxAssetChangeSimulationResponse,
} from '../../../../../wallet/evm/sdk/alchemy/types'
import {toPlainEvmAddress} from '../../../../../wallet/evm/sdk/utils'
import type {EvmCustomSignOptions} from '../../customBlockchains/evm'
import {useSignContext} from '../../SignContext'
import {ChipRow} from '../common/details/common'
import {TxDetail} from '../common/details/TxDetail'
import {TxDataLayout} from '../common/TxDataLayout'
import type {BlockchainTxDataProps} from '../types'

import {
  ApprovalAmount,
  GasInfoRow,
  GeneralRow,
  TokenApprovalRow,
  UnknownTokenWarning,
  WarningMessage,
} from './common'
import {
  getNativeDiff,
  getTokenId,
  getTokenMovementsCounts,
  getTokensDiff,
} from './simulationUtils'
import {SimulatingDataLoading} from './WithSimulationData'

type GasActions = {
  label: string
  onClick: () => void
  icon: React.JSX.Element
}[]

type SimulatedTxDataProps = {
  blockchain: EvmBlockchain
  selectedAccount: EvmAccountOfflineInfo<EvmBlockchain>
  txSimulationResult: TxAssetChangesSimulationResponse
  fee: EvmWei<EvmBlockchain>
  warningMessagesContent: React.ReactNode
  txParams: BlockchainTxDataProps<EvmBlockchain>['txParams'] & {
    customGasOptions: EvmCustomSignOptions<EvmBlockchain>
  }
  gasActions: GasActions
}

export const SimulatedTxData = ({
  blockchain,
  selectedAccount,
  txSimulationResult,
  fee,
  warningMessagesContent: _warningMessagesContent,
  txParams,
  gasActions,
}: SimulatedTxDataProps) => {
  const {t} = useTranslation()
  const selectedAccountAddress = toPlainEvmAddress<EvmBlockchain>(
    selectedAccount.address,
  )

  // This hooks is prefetched therefore the data should be there immediately in most cases.
  // We do not wrap it in `QueryGuard` as if loading some of the tokens failed, users would not
  // be able to sign any transactions.
  const knownTokenIds = useGetKnownTokenIds(blockchain).data

  const nativeDiff = getNativeDiff(txSimulationResult, selectedAccount)
  const {receivingTokensCount, sendingTokensCount} = getTokenMovementsCounts(
    txSimulationResult,
    selectedAccount,
  )
  const tokensDiffResponse = getTokensDiff(txSimulationResult, selectedAccount)

  const firstApproval = txSimulationResult.changes.find(
    (c) => c.changeType === 'APPROVE',
  )

  const containsSpecialNftTransaction = txSimulationResult.changes.some(
    (c) => c.assetType === 'SPECIAL_NFT',
  )

  const renderSingleApprovalUI =
    txSimulationResult.changes.length === 1 && firstApproval

  const containsUnknownToken = !!(
    knownTokenIds &&
    txSimulationResult.changes.some((c) => {
      // We only allow importing ERC20 tokens
      if (c.assetType !== 'ERC20') return false
      // Only show warning for incoming tokens
      if (
        toPlainEvmAddress<EvmBlockchain>(
          c.from as unknown as EvmAddress<EvmBlockchain>,
        ) === selectedAccountAddress
      )
        return false
      const tokenId = getTokenId(c)
      if (tokenId == null || knownTokenIds == null) return false
      return !knownTokenIds.includes(tokenId)
    })
  )

  const warningMessagesContent = (
    <>
      {containsSpecialNftTransaction && (
        <WarningMessage
          label={t('evm_connector_ui_no_special_nft_support')}
          severity="error"
          testId="evm_connector_ui_no_special_nft_support"
        />
      )}
      {containsUnknownToken &&
        (renderSingleApprovalUI ? (
          <WarningMessage
            label={t(
              'The token needs to be imported in order to be recognized by NuFi.',
            )}
            testId="single-approve-unknown-token-warning"
          />
        ) : (
          <WarningMessage
            label={t(
              'Transaction contains tokens that need to be imported in order to be recognized by NuFi.',
            )}
            testId="unknown-tokens-warning"
          />
        ))}
      {tokensDiffResponse.hasErrors && (
        <WarningMessage
          label={t(
            'We could not parse all transactions details. Transaction can also have some other effects.',
          )}
          testId="could-not-parse-simulated-data"
        />
      )}
      {_warningMessagesContent}
    </>
  )

  // We only show the custom "Approve token" UI if there is nothing else
  // going on in the transaction
  if (renderSingleApprovalUI) {
    return (
      <TokenApproval
        change={firstApproval}
        {...{
          txParams,
          fee,
          blockchain,
          warningMessagesContent,
        }}
        isUnknownToken={containsUnknownToken}
        gasActions={gasActions}
      />
    )
  }

  // We do not yet know whether some dapps actually combine multiple
  // approvals, or combine approval with other effects.
  // For now we only display that some "approvals" are taking place so that
  // we show at least some information. If it turns out that this is indeed the case
  // we will prepare proper UI for it.
  const approvals = txSimulationResult.changes.filter(
    (c) => c.changeType === 'APPROVE',
  )

  return (
    <Box rtl-data-test-id="evm-tx-data-simulation">
      <TxDataLayout
        details={
          <>
            <TxDetail
              tokensMovement={{
                tokensDiff: tokensDiffResponse.data,
                receivingTokensCount,
                sendingTokensCount,
                getTokenInfoItems: (meta) => {
                  assert(hasEvmBlockchainProp(meta))
                  return [
                    {key: t('Contract'), value: meta.contractAddress},
                    {key: t('Ticker'), value: meta.ticker},
                  ]
                },
                getExtraAssetContent: (meta, amount) => {
                  if (knownTokenIds == null) return null
                  if (
                    !isNft(meta) &&
                    // only show warning for incoming tokens
                    amount?.isPositive() &&
                    !knownTokenIds.includes(
                      meta.id as EvmTokenId<EvmBlockchain>,
                    )
                  ) {
                    return (
                      <UnknownTokenWarning
                        testId={'evm_connector_unknown_token'}
                      />
                    )
                  }
                  return null
                },
              }}
              blockchain={blockchain}
              fee={fee}
              nativeDiff={nativeDiff.minus(fee)}
              customBottomRows={[
                ...(approvals.length > 0
                  ? [
                      {
                        summary: (
                          <ChipRow
                            label={t('Token access approval')}
                            chipContent={
                              <span rtl-data-test-id="token_approvals_count">
                                {approvals.length}
                              </span>
                            }
                          />
                        ),
                        details: (
                          <>
                            {approvals.map((approval, index) => {
                              const tokenId = getTokenId(approval)
                              const isUnknownToken = !!(
                                tokenId != null &&
                                // We only allow importing ERC20 tokens
                                approval.assetType === 'ERC20' &&
                                knownTokenIds != null &&
                                !knownTokenIds.includes(tokenId)
                              )
                              return (
                                <TokenApprovalRow
                                  key={index}
                                  blockchain={blockchain}
                                  tokenId={tokenId}
                                  isUnknownToken={isUnknownToken}
                                  approval={approval}
                                />
                              )
                            })}
                          </>
                        ),
                      },
                    ]
                  : []),
                {
                  summary: t('General'),
                  details: <GeneralRow {...txParams} blockchain={blockchain} />,
                },
                {
                  summary: t('Gas settings'),
                  details: <GasInfoRow {...txParams.customGasOptions} />,
                  actions: gasActions,
                },
              ]}
            />
            <Box mt={1}>{warningMessagesContent}</Box>
          </>
        }
        rawTx={txParams.data as string}
      />
    </Box>
  )
}

type TokenApprovalProps = {
  fee: EvmWei<EvmBlockchain>
  blockchain: EvmBlockchain
  change: TxAssetChangeSimulationResponse
  warningMessagesContent: React.ReactNode
  txParams: BlockchainTxDataProps<EvmBlockchain>['txParams'] & {
    customGasOptions: EvmCustomSignOptions<EvmBlockchain>
  }
  isUnknownToken: boolean
  gasActions: GasActions
}

export function TokenApproval(props: TokenApprovalProps) {
  const tokenId = getTokenId(props.change)

  if (!tokenId) return null

  return <_TokenApproval {...props} tokenId={tokenId} />
}

const TransChip = ({children}: {children?: React.ReactNode}) => (
  <Chip size="small" label={children} />
)

function _TokenApproval({
  blockchain,
  change,
  tokenId,
  warningMessagesContent,
  txParams,
  isUnknownToken,
  gasActions,
}: TokenApprovalProps & {tokenId: EvmTokenId<EvmBlockchain>}) {
  const {t} = useTranslation()
  const {origin} = useSignContext()
  const tokenMetadataQuery = useGetTokenMetadata(blockchain, tokenId)

  // We are using `amount` instead of `rawAmount` as it is simpler to work with, and we can
  // not check the correctness of either one from tokenMetadata anyways.
  const amount = new BigNumber(change.amount)

  return (
    <QueryGuard
      {...tokenMetadataQuery}
      LoadingElement={<SimulatingDataLoading />}
    >
      {(meta) => (
        <TxDataLayout
          txDescription={
            <>
              <Typography variant="h6" textAlign="center">
                {t('Grant permission?')}
              </Typography>

              <Typography
                component="div"
                textAlign="center"
                lineHeight="1.8"
                p={2}
              >
                <Trans
                  i18nKey="evm_approval_sign_info"
                  t={t}
                  components={{
                    Chip: <TransChip />,
                    safeUrl: TransBoldNoBreak,
                    formattedAmount: (
                      <RequestedApprovalAmount
                        {...{
                          tokenId,
                          amount,
                          blockchain,
                        }}
                        displayTicker
                        typographyProps={{
                          variant: 'inherit',
                        }}
                      />
                    ),
                  }}
                  // We are still XSS protected as we render the `origin` inside
                  // <safeUrl /> => which is a react component
                  tOptions={{interpolation: {escapeValue: false}}}
                  values={{
                    origin,
                    ticker: meta.ticker,
                  }}
                />
              </Typography>
              {warningMessagesContent}
            </>
          }
          details={
            <TxDetail
              nativeDiff={null}
              fee={null}
              blockchain={blockchain}
              customBottomRows={[
                {
                  summary: (
                    <ChipRow
                      label={t('Token access approval')}
                      chipContent={
                        <span rtl-data-test-id="sing_token_approval_chip">
                          {1}
                        </span>
                      }
                    />
                  ),
                  details: (
                    <TokenApprovalRow
                      blockchain={blockchain}
                      tokenId={tokenId}
                      isUnknownToken={isUnknownToken}
                      approval={change}
                    />
                  ),
                },

                {
                  summary: t('General'),
                  details: <GeneralRow {...txParams} blockchain={blockchain} />,
                },
                {
                  summary: t('Gas settings'),
                  details: <GasInfoRow {...txParams.customGasOptions} />,
                  actions: gasActions,
                },
              ]}
            />
          }
          rawTx={txParams.data as string}
        />
      )}
    </QueryGuard>
  )
}

function RequestedApprovalAmount({
  tokenId,
  amount,
  blockchain,
  displayTicker,
  typographyProps,
}: {
  tokenId: EvmTokenId<EvmBlockchain>
  amount: BigNumber
  blockchain: EvmBlockchain
  displayTicker: boolean
  typographyProps?: TypographyProps
}) {
  const getTokenMetadataQuery = useGetTokenMetadata(blockchain, tokenId)

  return (
    <QueryGuard {...getTokenMetadataQuery}>
      {(meta) => (
        <ApprovalAmount
          ticker={displayTicker ? meta.ticker : null}
          {...{amount, typographyProps}}
        />
      )}
    </QueryGuard>
  )
}
