import type BigNumber from 'bignumber.js'
import type {FormikProps} from 'formik'
import React from 'react'

import {useLastDefinedData} from 'src/utils/query-utils'

import {QueryGuard, TransactionModalError} from '../../../../components'
import {safeAssertUnreachable} from '../../../../utils/assertion'
import type {
  EvmBlockchain,
  EvmWei,
  LondonGasSuggestions,
  EvmLondonGasConfig,
  EvmSimpleGasConfig,
} from '../../../../wallet/evm'
import {
  useGetGasSuggestions,
  useGetGasPrice,
  cachedGetGasPrice,
  cachedGetGasSuggestions,
  getEvmNetworkConfig,
} from '../../../../wallet/evm'
import type {
  OpStackFeeModelExchangeCustomOptions,
  OpStackFeeModelCustomOptions,
} from '../fee/opStack'
import {WithFeeModelData, getFeeModelData} from '../fee/WithFeeModelData'

import {
  gasOptionsToInitialFormValues as londonGasOptionsToInitialFormValues,
  LondonGasOptions,
  formValueToGasOptions as londonFormValueToGasOptions,
} from './LondonGasOptions'
import type {LondonUiGasSchema, SimpleGasSchema} from './schema'
import {
  gasOptionsToInitialFormValues as simpleGasOptionsToInitialFormValues,
  SimpleGasOptions,
  formValueToGasOptions as simpleFormValueToGasOptions,
} from './SimpleGasOptions'
import type {GasOptionsProps, GasSchema} from './types'

type SimpleCustomOptions = {
  gasLimit?: BigNumber
}

type LondonCustomOptions = {
  allowResetOnGasSuggestionsChange: boolean
  autoPadding?: number
  gasLimit?: BigNumber
}

type WithGasOptionsDataProps<TBlockchain extends EvmBlockchain> = {
  blockchain: TBlockchain
  disableQuery?: boolean
  customLondonOptions?: LondonCustomOptions
  customSimpleOptions?: SimpleCustomOptions
  children: (gasOptionsData: GasOptionsProps<TBlockchain>) => JSX.Element
}

export const WithGasOptionsData = <TBlockchain extends EvmBlockchain>({
  blockchain,
  customLondonOptions,
  customSimpleOptions,
  customOpStackFeeModelOptions,
  ...restProps
}: WithGasOptionsDataProps<TBlockchain> & {
  customOpStackFeeModelOptions: OpStackFeeModelCustomOptions
}) => {
  const networkConfig = getEvmNetworkConfig(blockchain)

  return (
    <WithFeeModelData
      blockchain={blockchain}
      feeModelType={networkConfig.gasConfig.feeModelType}
      disableQuery={restProps.disableQuery}
      customOpStackFeeModelOptions={customOpStackFeeModelOptions}
    >
      {({getTxFee}) => {
        switch (networkConfig.gasConfig.type) {
          case 'pre-london':
            return (
              <WithSimpleGasOptionsData
                customOptions={customSimpleOptions}
                {...{
                  blockchain,
                  gasConfig: networkConfig.gasConfig,
                  getTxFee,
                  ...restProps,
                }}
              />
            )
          case 'london':
            return (
              <WithLondonGasOptionsData
                customOptions={customLondonOptions}
                {...{
                  blockchain,
                  gasConfig: networkConfig.gasConfig,
                  getTxFee,
                  ...restProps,
                }}
              />
            )
          default:
            return safeAssertUnreachable(networkConfig.gasConfig)
        }
      }}
    </WithFeeModelData>
  )
}

export const getGasOptionsData = async <TBlockchain extends EvmBlockchain>({
  blockchain,
  customLondonOptions,
  customSimpleOptions,
  customOpStackFeeModelOptions,
}: Omit<WithGasOptionsDataProps<TBlockchain>, 'children' | 'disableQuery'> & {
  customOpStackFeeModelOptions: OpStackFeeModelExchangeCustomOptions<TBlockchain>
}) => {
  const networkConfig = getEvmNetworkConfig(blockchain)
  const {getTxFee} = await getFeeModelData<TBlockchain>({
    blockchain,
    feeModelType: networkConfig.gasConfig.feeModelType,
    customOpStackFeeModelOptions,
  })
  switch (networkConfig.gasConfig.type) {
    case 'pre-london': {
      const gasPrice = await cachedGetGasPrice(blockchain)
      const gasOptionsData = {
        type: 'simple' as const,
        gasPrice,
      }
      return createSimpleGasOptionsData({
        gasOptionsData,
        customOptions: customSimpleOptions,
        gasConfig: networkConfig.gasConfig as EvmSimpleGasConfig,
        getTxFee,
      })
    }
    case 'london': {
      const gasSuggestions = await cachedGetGasSuggestions(blockchain)
      const gasOptionsData = {
        type: 'london' as const,
        gasSuggestions,
      }
      return createLondonGasOptionsData({
        gasOptionsData,
        customOptions: customLondonOptions,
        gasConfig: networkConfig.gasConfig as EvmLondonGasConfig<TBlockchain>,
        getTxFee,
      })
    }
    default:
      return safeAssertUnreachable(networkConfig.gasConfig)
  }
}

const createSimpleGasOptionsData = <TBlockchain extends EvmBlockchain>({
  customOptions,
  gasOptionsData,
  gasConfig,
  getTxFee,
}: {
  customOptions?: SimpleCustomOptions
  gasOptionsData: {gasPrice: EvmWei<TBlockchain>; type: 'simple'}
  gasConfig: EvmSimpleGasConfig
  getTxFee: (values: GasSchema) => EvmWei<TBlockchain>
}) => {
  return {
    gasConfig,
    gasOptionsData,
    gasOptionsInitialValues: simpleGasOptionsToInitialFormValues(
      gasOptionsData.gasPrice,
      customOptions?.gasLimit,
    ),
    getTxFee,
    formValuesToGasOptions: (values: GasSchema) =>
      simpleFormValueToGasOptions(values as unknown as SimpleGasSchema),
    gasSchemaOptions: {type: 'simple' as const},
    renderGasOptions: (formikProps: FormikProps<GasSchema>) => (
      <SimpleGasOptions
        formikProps={formikProps as unknown as FormikProps<SimpleGasSchema>}
      />
    ),
  }
}

export const WithSimpleGasOptionsData = <TBlockchain extends EvmBlockchain>({
  blockchain,
  disableQuery,
  customOptions,
  children,
  gasConfig,
  getTxFee,
}: WithGasOptionsDataProps<TBlockchain> & {
  customOptions?: SimpleCustomOptions
  gasConfig: EvmSimpleGasConfig
  getTxFee: (values: GasSchema) => EvmWei<TBlockchain>
}) => {
  const {gasPriceQuery} = useLastDefinedData({
    gasPriceQuery: useGetGasPrice(blockchain, !disableQuery),
  })
  return (
    <QueryGuard
      {...gasPriceQuery}
      data={gasPriceQuery.data}
      ErrorElement={<TransactionModalError />}
      loadingVariant="centered"
    >
      {(_gasOptionsData) => {
        const gasOptionsData = {
          type: 'simple' as const,
          gasPrice: _gasOptionsData as EvmWei<TBlockchain>,
        }
        return children(
          createSimpleGasOptionsData({
            gasOptionsData,
            customOptions,
            gasConfig,
            getTxFee,
          }),
        )
      }}
    </QueryGuard>
  )
}

const createLondonGasOptionsData = <TBlockchain extends EvmBlockchain>({
  gasOptionsData,
  customOptions,
  gasConfig,
  getTxFee,
}: {
  customOptions?: LondonCustomOptions
  gasOptionsData: {
    gasSuggestions: LondonGasSuggestions<TBlockchain>
    type: 'london'
  }
  gasConfig: EvmLondonGasConfig<TBlockchain>
  getTxFee: (values: GasSchema) => EvmWei<TBlockchain>
}) => {
  return {
    gasConfig,
    gasOptionsData,
    gasOptionsInitialValues: londonGasOptionsToInitialFormValues(
      gasOptionsData.gasSuggestions,
      gasConfig,
      customOptions?.gasLimit,
    ),
    getTxFee,
    formValuesToGasOptions: (values: GasSchema) =>
      londonFormValueToGasOptions(values as unknown as LondonUiGasSchema),
    gasSchemaOptions: {type: 'london' as const},
    renderGasOptions: (formikProps: FormikProps<GasSchema>) => (
      <LondonGasOptions
        formikProps={formikProps as unknown as FormikProps<LondonUiGasSchema>}
        gasSuggestions={gasOptionsData.gasSuggestions}
        {...(customOptions?.autoPadding != null
          ? {autoPadding: customOptions.autoPadding}
          : {})}
        allowResetOnGasSuggestionsChange={
          customOptions?.allowResetOnGasSuggestionsChange != null
            ? customOptions.allowResetOnGasSuggestionsChange
            : true
        }
        gasConfig={gasConfig}
      />
    ),
  }
}

export const WithLondonGasOptionsData = <TBlockchain extends EvmBlockchain>({
  blockchain,
  disableQuery,
  customOptions,
  gasConfig,
  children,
  getTxFee,
}: WithGasOptionsDataProps<TBlockchain> & {
  customOptions?: LondonCustomOptions
  gasConfig: EvmLondonGasConfig<TBlockchain>
  getTxFee: (values: GasSchema) => EvmWei<TBlockchain>
}) => {
  const {gasSuggestionsQuery} = useLastDefinedData({
    gasSuggestionsQuery: useGetGasSuggestions(blockchain, !disableQuery),
  })
  return (
    <QueryGuard
      {...gasSuggestionsQuery}
      data={gasSuggestionsQuery.data}
      ErrorElement={<TransactionModalError />}
      loadingVariant="centered"
    >
      {(_gasOptionsData) => {
        const gasOptionsData = {
          type: 'london' as const,
          gasSuggestions: _gasOptionsData as LondonGasSuggestions<TBlockchain>,
        }
        return children(
          createLondonGasOptionsData({
            gasOptionsData,
            customOptions,
            gasConfig,
            getTxFee,
          }),
        )
      }}
    </QueryGuard>
  )
}
