import {Box, Typography} from '@mui/material'
import type {FlowDelegationInfo, FlowStakeAccountId} from '@nufi/wallet-flow'
import type BigNumber from 'bignumber.js'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'

import {useGetNodeIdsToNames} from 'src/wallet/flow/application'

import {FormattedAsset, SplitButton, QueryGuard} from '../../../../components'
import {routeTo} from '../../../../router'
import type {AccountId} from '../../../../types'
import {useGetValidators} from '../../../../wallet/flow'
import {StakeAccountCardWrapper} from '../../common/overview/StakeAccountCardUtils'
import {
  FormattedValidatorName,
  FormattedValidatorType,
  getFlowStakeAccountId,
} from '../utils'

import {StakeAccountListItemLayout} from './StakeAccountListLayout'

function CardCol({amount}: {amount: BigNumber}) {
  return (
    <Typography variant="body2">
      <FormattedAsset
        amount={amount}
        blockchain="flow"
        isSensitiveInformation
      />
    </Typography>
  )
}

type StakeAccountCardProps = {
  delegationInfo: FlowDelegationInfo
  accountId: AccountId
}

export default function StakeAccountCard({
  delegationInfo,
  accountId,
}: StakeAccountCardProps) {
  const {t} = useTranslation()
  const {
    tokensStaked,
    tokensCommitted,
    tokensRequestedToUnstake,
    tokensUnstaked,
    tokensRewarded,
    tokensUnstaking,
    nodeId,
    id,
  } = delegationInfo
  const validatorsQuery = useGetValidators()
  const nodeIdsToNamesQuery = useGetNodeIdsToNames()

  return (
    <StakeAccountCardWrapper>
      <StakeAccountListItemLayout
        tableBodyStyles
        validatorCol={
          <QueryGuard {...validatorsQuery}>
            {(validators) => (
              <QueryGuard {...nodeIdsToNamesQuery}>
                {(nodeIdsToNames) => {
                  const validator = validators.find((v) => v.nodeId === nodeId)
                  return (
                    <Box display="flex" flexDirection="column">
                      <Typography variant="body2">
                        <FormattedValidatorName name={nodeIdsToNames[nodeId]} />
                      </Typography>
                      <Typography variant="caption" color="textSecondary">
                        <FormattedValidatorType
                          nodeRole={validator?.role || t('Unknown type')}
                        />
                      </Typography>
                    </Box>
                  )
                }}
              </QueryGuard>
            )}
          </QueryGuard>
        }
        stakedCol={<CardCol amount={tokensStaked} />}
        committedCol={<CardCol amount={tokensCommitted} />}
        rewardsCol={<CardCol amount={tokensRewarded} />}
        unstakedCol={<CardCol amount={tokensUnstaked} />}
        lockedCol={<CardCol amount={tokensUnstaking} />}
        requestedToUnstakeCol={<CardCol amount={tokensRequestedToUnstake} />}
        actionsCol={
          <FlowStakeActionsMenu
            stakeAccountId={getFlowStakeAccountId(nodeId, id)}
            accountId={accountId}
          />
        }
      />
    </StakeAccountCardWrapper>
  )
}

type FlowStakeActionsMenuProps = {
  stakeAccountId: FlowStakeAccountId
  accountId: AccountId
}

function FlowStakeActionsMenu({
  stakeAccountId,
  accountId,
}: FlowStakeActionsMenuProps) {
  const history = useHistory()
  const {t} = useTranslation()

  const stakeAccountActionRoute = routeTo.staking.myStaking.flow
    .account(accountId)
    .stakeAccount(stakeAccountId)

  const openStakeAccountActionModal =
    (type: Exclude<keyof typeof stakeAccountActionRoute, 'index'>) => () =>
      history.push(stakeAccountActionRoute[type])

  return (
    <>
      <SplitButton
        options={[
          {
            label: t('Stake more tokens'),
            onClick: openStakeAccountActionModal('stakeNewTokens'),
          },
          {
            label: t('Withdraw rewards'),
            onClick: openStakeAccountActionModal('withdrawRewards'),
          },
          {
            label: t('Withdraw unstaked'),
            onClick: openStakeAccountActionModal('withdrawUnstaked'),
          },
          {
            label: t('Restake rewards'),
            onClick: openStakeAccountActionModal('restakeRewarded'),
          },
          {
            label: t('Restake unstaked'),
            onClick: openStakeAccountActionModal('restakeUnstaked'),
          },
          {
            label: t('Unstake tokens'),
            onClick: openStakeAccountActionModal('requestUnstaking'),
          },
        ]}
      />
    </>
  )
}
