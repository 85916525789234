import type {
  FlowAccountInfo,
  FlowDelegationInfo,
  FlowSignedTransaction,
  FlowUpdateDelegatorTxType,
  Nanoflow,
} from '@nufi/wallet-flow'
import {flowToNanoflow} from '@nufi/wallet-flow'
import BigNumber from 'bignumber.js'
import type {FormikProps} from 'formik'
import React from 'react'

import {useTrackTxSubmission} from '../../../../../tracking'
import type {AccountId} from '../../../../../types'
import type {useSignTx, useSubmitTx} from '../../../../../wallet/flow'
import {SignTxFlow} from '../../../../transaction'
import type {ActiveScreen} from '../../../common/utils'
import {DeviceReadyState, StakeModalHeader} from '../../../common/utils'
import type {FlowUICachedTxPlan} from '../types'
import {getSendMaxAmountOptions} from '../utils'

import DetailsScreen from './DetailsScreen'
import SummaryScreen from './SummaryScreen'
import type {FormSchema} from './types'
import {
  getAvailableBalanceForStakingTxType,
  getMaxStakingAmountOptions,
  UpdateDelegatorModalHeaderTitle,
} from './utils'

type FlowUpdateDelegatorContentProps = {
  setActiveScreen: (screen: ActiveScreen) => void
  formikProps: FormikProps<FormSchema>
  submitProps: ReturnType<typeof useSubmitTx>
  signProps: ReturnType<typeof useSignTx>
  onClose: () => unknown
  onTxSubmit: (
    accountId: AccountId,
    signedTx: FlowSignedTransaction,
  ) => Promise<unknown>
  onTxSignAndSubmit: (values: FormSchema) => Promise<unknown>
  accountInfo: FlowAccountInfo
  txType: FlowUpdateDelegatorTxType
  delegation: FlowDelegationInfo
  txPlan: FlowUICachedTxPlan | null
}

export function FlowUpdateDelegatorContent({
  accountInfo,
  setActiveScreen,
  formikProps,
  submitProps,
  onClose,
  onTxSubmit,
  onTxSignAndSubmit,
  signProps,
  txType,
  delegation,
  txPlan,
}: FlowUpdateDelegatorContentProps) {
  const summary = {
    fromAccount: accountInfo,
    fee: txPlan?.fee || (new BigNumber(0) as Nanoflow),
    amount: flowToNanoflow(formikProps.values.amount),
    delegation,
  }

  const blockchain = 'flow'
  const ModalHeader = (
    <StakeModalHeader
      customTitle={<UpdateDelegatorModalHeaderTitle {...{txType}} />}
      {...{blockchain, onClose}}
    />
  )
  const amountKey: keyof FormSchema = 'amount' // due to TS
  const maxAmountOptions =
    txType === 'stakeNewTokens'
      ? getSendMaxAmountOptions(accountInfo, txType, formikProps, amountKey)
      : getMaxStakingAmountOptions(
          getAvailableBalanceForStakingTxType(txType, accountInfo, delegation),
          formikProps,
        )

  useTrackTxSubmission(submitProps, {
    blockchain: accountInfo.blockchain,
    provider: accountInfo.cryptoProviderType,
    type: txType,
    value: summary.amount.toNumber(),
    id: delegation.id,
  })

  return (
    <SignTxFlow
      {...{
        onClose,
        formikProps,
        ModalHeader,
        blockchain,
        submitProps,
        DeviceReadyState,
        onTxSignAndSubmit,
      }}
      signProps={signProps}
      onTxSubmit={onTxSubmit}
      initialScreen="details"
      renderDetails={() => (
        <DetailsScreen
          {...{
            blockchain,
            onClose,
            formikProps,
            txType,
            accountInfo,
            delegation,
            ModalHeader,
            maxAmountOptions,
          }}
        />
      )}
      renderSummary={() => (
        <SummaryScreen
          onBack={() => setActiveScreen('details')}
          onSubmit={formikProps.handleSubmit}
          {...{
            summary,
            formikProps,
            onClose,
            blockchain,
            txType,
            ModalHeader,
          }}
        />
      )}
    />
  )
}
