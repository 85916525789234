import React from 'react'

import type {CryptoProviderType} from '../../../wallet'
import {isHotVendor} from '../../../wallet'

import {AddHotAccount} from './AddHotAccount'
import {AddLedgerAccount} from './AddLedgerAccount'
import {AddTrezorAccount} from './AddTrezorAccount'

type AddCardanoAccountProps = {
  cryptoProviderType: CryptoProviderType
}

const AddCardanoAccount = ({cryptoProviderType}: AddCardanoAccountProps) => {
  if (isHotVendor(cryptoProviderType)) {
    return <AddHotAccount {...{cryptoProviderType}} />
  }
  switch (cryptoProviderType) {
    case 'ledger':
      return <AddLedgerAccount />
    case 'trezor':
      return <AddTrezorAccount />
    default:
      return null
  }
}

export default AddCardanoAccount
