import {
  ErrorOutline as ErrorIcon,
  HourglassFullTwoTone as WaitingIcon,
  Check as SuccessIcon,
} from '@mui/icons-material'
import {SvgIcon, styled} from '@mui/material'
import type {SvgIconProps as MuiSvgIconProps} from '@mui/material/SvgIcon'
import React from 'react'

// static assets
import {ReactComponent as AppEmblem} from '../../../../assets/icons/appEmblem.svg'
import {ReactComponent as AppLogo} from '../../../../assets/icons/appLogo.svg'
import {ReactComponent as ArbitrumOneIcon} from '../../../../assets/icons/arbitrumOne.svg'
import {ReactComponent as DefaultArbitrumOneTokenIcon} from '../../../../assets/icons/arbitrumOneToken.svg'
import {ReactComponent as AsteriskIcon} from '../../../../assets/icons/asterisk.svg'
// WEB3 AUTH ICONS OF PROVIDES
import {ReactComponent as DiscordAuthIcon} from '../../../../assets/icons/authProviders/discord.svg'
import {ReactComponent as FacebookAuthIcon} from '../../../../assets/icons/authProviders/facebook.svg'
import {ReactComponent as GoogleAuthIcon} from '../../../../assets/icons/authProviders/google.svg'
import {ReactComponent as TwitterAuthIcon} from '../../../../assets/icons/authProviders/twitter.svg'
import {ReactComponent as BaseIcon} from '../../../../assets/icons/base.svg'
import {ReactComponent as DefaultBaseTokenIcon} from '../../../../assets/icons/baseToken.svg'
import {ReactComponent as BuySellIcon} from '../../../../assets/icons/buySell.svg'
import {ReactComponent as CardanoIcon} from '../../../../assets/icons/cardano.svg'
import {ReactComponent as DefaultCardanoTokenIcon} from '../../../../assets/icons/cardanoToken.svg'
import {ReactComponent as CardanoTokenColorlessIcon} from '../../../../assets/icons/cardanoTokenColorless.svg'
import {ReactComponent as EternlIcon} from '../../../../assets/icons/eternlLogo.svg'
import {ReactComponent as EthereumIcon} from '../../../../assets/icons/ethereum.svg'
import {ReactComponent as DefaultEthereumTokenIcon} from '../../../../assets/icons/ethereumToken.svg'
import {ReactComponent as ExchangeIcon} from '../../../../assets/icons/exchange.svg'
import {ReactComponent as ExportIcon} from '../../../../assets/icons/exportIcon.svg'
import {ReactComponent as FlintIcon} from '../../../../assets/icons/flintLogo.svg'
import {ReactComponent as FlowIcon} from '../../../../assets/icons/flow.svg'
import {ReactComponent as FlowRefWalletIcon} from '../../../../assets/icons/flowRefWalletLogo.svg'
import {ReactComponent as FlowTokenIcon} from '../../../../assets/icons/flowToken.svg'
import {ReactComponent as GrayCube} from '../../../../assets/icons/grayCube.svg'
import {ReactComponent as GrayDot} from '../../../../assets/icons/grayDot.svg'
import {ReactComponent as GreenDot} from '../../../../assets/icons/greenDot.svg'
import {ReactComponent as GridPlusBadgeIcon} from '../../../../assets/icons/gridPlusBadge.svg'
import {ReactComponent as GridPlusIcon} from '../../../../assets/icons/gridPlusLogo.svg'
import {ReactComponent as HelpIcon} from '../../../../assets/icons/Help.svg'
import {ReactComponent as IncognitoIcon} from '../../../../assets/icons/incognito.svg'
import {ReactComponent as LedgerBadgeIcon} from '../../../../assets/icons/ledgerBadge.svg'
import {ReactComponent as LedgerIcon} from '../../../../assets/icons/ledgerLogo.svg'
import {ReactComponent as ListIcon} from '../../../../assets/icons/listIcon.svg'
import {ReactComponent as MetamaskIcon} from '../../../../assets/icons/metamaskLogo.svg'
import {ReactComponent as MilkomedaC1Icon} from '../../../../assets/icons/milkomedaC1.svg'
import {ReactComponent as DefaultMilkomedaTokenIcon} from '../../../../assets/icons/milkomedaC1Token.svg'
import {ReactComponent as OptimismIcon} from '../../../../assets/icons/optimism.svg'
import {ReactComponent as DefaultOptimismTokenIcon} from '../../../../assets/icons/optimismToken.svg'
import {ReactComponent as PhantomIcon} from '../../../../assets/icons/phantomLogo.svg'
import {ReactComponent as PolygonIcon} from '../../../../assets/icons/polygon.svg'
import {ReactComponent as DefaultPolygonTokenIcon} from '../../../../assets/icons/polygonToken.svg'
import {ReactComponent as ReceiveIcon} from '../../../../assets/icons/receive.svg'
import {ReactComponent as RedDot} from '../../../../assets/icons/redDot.svg'
import {ReactComponent as SendIcon} from '../../../../assets/icons/send.svg'
import {ReactComponent as DiscordIcon} from '../../../../assets/icons/social/discord.svg'
import {ReactComponent as FacebookIcon} from '../../../../assets/icons/social/facebook.svg'
import {ReactComponent as GithubIcon} from '../../../../assets/icons/social/github.svg'
import {ReactComponent as InstagramIcon} from '../../../../assets/icons/social/instagram.svg'
import {ReactComponent as LinkedinIcon} from '../../../../assets/icons/social/linkedin.svg'
import {ReactComponent as MediumIcon} from '../../../../assets/icons/social/medium.svg'
import {ReactComponent as RedditIcon} from '../../../../assets/icons/social/reddit.svg'
import {ReactComponent as TwitterIcon} from '../../../../assets/icons/social/twitter.svg'
import {ReactComponent as SolanaIcon} from '../../../../assets/icons/solana.svg'
import {ReactComponent as DefaultSolanaTokenIcon} from '../../../../assets/icons/solanaToken.svg'
import {ReactComponent as TrezorBadgeIcon} from '../../../../assets/icons/trezorBadge.svg'
import {ReactComponent as TrezorIcon} from '../../../../assets/icons/trezorLogo.svg'
import {ReactComponent as WalletConnectIcon} from '../../../../assets/icons/walletConnect.svg'
import {ReactComponent as YellowDot} from '../../../../assets/icons/yellowDot.svg'
import type {SocialMediaType} from '../../../../constants'
import type {Web3AuthLoginProvider} from '../../../../features/login'
import type {
  Blockchain,
  WalletKind,
  CryptoProviderType,
} from '../../../../types'
import type {blockchainMetadata} from '../../../../wallet/constants'

export type BlockchainIconType = Blockchain
export type WalletKindIconType = WalletKind
export type SocialMediaIconType = SocialMediaType
export type TokenIconType = {
  [Property in keyof typeof blockchainMetadata]: `${Property}DefaultTokenIcon`
}[keyof typeof blockchainMetadata]
export type ColoredBadgeIconType = 'yellow' | 'green' | 'gray' | 'red'
export type ActivationIconType = Exclude<ColoredBadgeIconType, 'red'>
export type TransactionStatusIconType = ColoredBadgeIconType
type OtherWalletIconType =
  | 'flintIcon'
  | 'eternlIcon'
  | 'phantomIcon'
  | 'metamaskIcon'
  | 'flowRefWalletIcon'

export type IconType =
  | BlockchainIconType
  | WalletKindIconType
  | SocialMediaIconType
  | TokenIconType
  | OtherWalletIconType
  | 'ledgerIcon'
  | 'trezorIcon'
  | 'gridPlusIcon'
  | 'gridPlusBadgeIcon'
  | 'ledgerBadgeIcon'
  | 'trezorBadgeIcon'
  | ColoredBadgeIconType
  | 'grayCube'
  | 'sendIcon'
  | 'exchangeIcon'
  | 'appEmblem'
  | 'appLogo'
  | 'receiveIcon'
  | 'helpIcon'
  | 'listIcon'
  | 'exportIcon'
  | 'incognitoIcon'
  | 'buySellIcon'
  | 'asteriskIcon'
  | 'walletConnect'
  | 'cardanoTokenColorlessIcon'

// Omit was used as storybook does not infer options well when overriding types
export type IconProps = Omit<MuiSvgIconProps, 'type'> & {
  type: IconType
  exactSize?: number
}

const iconDim = (width: number, height: number) => ({
  width,
  height,
  viewBox: `0 0 ${width} ${height}`,
})
export const iconDims = {
  tiny: {width: 8, height: 8, viewBox: '0 0 8 8'},
  small: {width: 24, height: 24, viewBox: '0 0 24 24'},
  medium: {width: 48, height: 48, viewBox: '0 0 48 48'},
}

export default function Icon({exactSize, type, ...rest}: IconProps) {
  const [_Icon, dims] = (
    {
      cardano: [CardanoIcon, {width: 32, height: 32, viewBox: '0 0 32 32'}],
      cardanoDefaultTokenIcon: [DefaultCardanoTokenIcon, iconDims.small],
      cardanoTokenColorlessIcon: [CardanoTokenColorlessIcon, iconDims.small],
      solana: [SolanaIcon, iconDims.small],
      solanaDefaultTokenIcon: [DefaultSolanaTokenIcon, iconDims.small],
      flow: [FlowIcon, iconDims.small],
      flowDefaultTokenIcon: [FlowTokenIcon, iconDims.small],
      ethereum: [EthereumIcon, iconDims.small],
      evm: [DefaultEthereumTokenIcon, iconDims.small],
      milkomedaC1: [
        MilkomedaC1Icon,
        {width: 40, height: 40, viewBox: '0 0 40 40'},
      ],
      milkomedaC1DefaultTokenIcon: [
        DefaultMilkomedaTokenIcon,
        {width: 40, height: 40, viewBox: '0 0 40 40'},
      ],
      polygon: [PolygonIcon, iconDims.medium],
      polygonDefaultTokenIcon: [DefaultPolygonTokenIcon, iconDims.medium],
      optimism: [
        OptimismIcon,
        {width: '500', height: '500', viewBox: '0 0 500 500'},
      ],
      optimismDefaultTokenIcon: [
        DefaultOptimismTokenIcon,
        {width: '500', height: '500', viewBox: '0 0 500 500'},
      ],
      arbitrumOne: [
        ArbitrumOneIcon,
        {width: '500', height: '500', viewBox: '0 0 500 500'},
      ],
      arbitrumOneDefaultTokenIcon: [
        DefaultArbitrumOneTokenIcon,
        {width: '500', height: '500', viewBox: '0 0 500 500'},
      ],
      base: [BaseIcon, {width: '500', height: '500', viewBox: '0 0 500 500'}],
      baseDefaultTokenIcon: [
        DefaultBaseTokenIcon,
        {width: '500', height: '500', viewBox: '0 0 500 500'},
      ],
      ethereumDefaultTokenIcon: [DefaultEthereumTokenIcon, iconDims.small],
      evmDefaultTokenIcon: [DefaultEthereumTokenIcon, iconDims.small],
      gridPlusIcon: [
        GridPlusIcon,
        {width: '133', height: '45', viewBox: '0 0 133 45'},
      ],
      gridPlusBadgeIcon: [GridPlusBadgeIcon, iconDims.small],
      ledgerIcon: [
        LedgerIcon,
        {width: '89', height: '77', viewBox: '0 0 89 77'},
      ],
      ledgerBadgeIcon: [LedgerBadgeIcon, iconDims.small],
      trezorIcon: [
        TrezorIcon,
        {width: '62', height: '87', viewBox: '0 0 62 87'},
      ],
      trezorBadgeIcon: [TrezorBadgeIcon, iconDims.small],
      flintIcon: [FlintIcon, iconDims.medium],
      eternlIcon: [EternlIcon, iconDims.medium],
      phantomIcon: [PhantomIcon, iconDims.medium],
      metamaskIcon: [
        MetamaskIcon,
        {width: '35', height: '33', viewBox: '0 0 35 33'},
      ],
      flowRefWalletIcon: [FlowRefWalletIcon, iconDims.medium],
      yellow: [YellowDot, iconDims.tiny],
      green: [GreenDot, iconDims.tiny],
      gray: [GrayDot, iconDims.tiny],
      red: [RedDot, iconDims.tiny],
      grayCube: [GrayCube, iconDims.small],
      sendIcon: [SendIcon, iconDims.small],
      exchangeIcon: [ExchangeIcon, iconDims.small],
      receiveIcon: [ReceiveIcon, iconDims.small],
      appLogo: [AppLogo, {width: '86', height: '22', viewBox: '0 0 86 22'}],
      appEmblem: [AppEmblem, {width: '32', height: '32', viewBox: '0 0 32 32'}],
      helpIcon: [HelpIcon, {width: 17, height: 20, viewBox: '0 0 17 20'}],
      listIcon: [ListIcon, iconDims.small],
      incognitoIcon: [IncognitoIcon, iconDims.small],
      exportIcon: [
        ExportIcon,
        {width: '21', height: '14', viewBox: '0 0 21 14'},
      ],
      discord: [DiscordIcon, iconDims.small],
      medium: [MediumIcon, iconDims.small],
      twitter: [
        TwitterIcon,
        {width: 1200, height: 1227, viewBox: '0 0 1200 1227'},
      ],
      reddit: [RedditIcon, iconDims.small],
      github: [GithubIcon, iconDims.small],
      linkedin: [LinkedinIcon, iconDim(50, 50)],
      instagram: [InstagramIcon, iconDims.small],
      facebook: [FacebookIcon, iconDim(1792, 1792)],
      buySellIcon: [BuySellIcon, iconDims.small],
      asteriskIcon: [AsteriskIcon, iconDims.small],
      walletConnect: [WalletConnectIcon, iconDims.small],
    } as const
  )[type]

  return (
    <SvgIcon {...dims} {...rest} style={exactSize ? {fontSize: exactSize} : {}}>
      <_Icon />
    </SvgIcon>
  )
}

export const Web3AuthProviderIcon = styled(
  ({
    provider,
    // rest contains className injected by styled
    ...rest
  }: {
    provider: Web3AuthLoginProvider
  }) => {
    const [Icon, dims] = (
      {
        google: [
          GoogleAuthIcon,
          {width: '32', height: '32', viewBox: '0 0 32 32'},
        ],
        facebook: [
          FacebookAuthIcon,
          {width: '500', height: '500', viewBox: '0 0 500 500'},
        ],
        twitter: [
          TwitterAuthIcon,
          {width: '278', height: '284', viewBox: '0 0 278 284'},
        ],
        discord: [
          DiscordAuthIcon,
          {width: '33', height: '32', viewBox: '0 0 33 32'},
        ],
      } as const
    )[provider]

    return <Icon {...dims} {...rest} />
  },
  {shouldForwardProp: (prop) => prop !== 'size'},
)<{size?: number}>(({theme: {palette}, size}) => ({
  fontSize: size || 24,
  width: '1em',
  height: '1em',
  color: palette.text.disabled,
}))

type BlockchainIconProps = Omit<IconProps, 'type'> & {
  blockchain: Blockchain
}

export const BlockchainIcon = ({blockchain, ...rest}: BlockchainIconProps) => {
  return <Icon {...rest} type={blockchain} />
}

type WalletKindIconProps = Omit<IconProps, 'type'> & {
  walletKind: WalletKind
}

export const WalletKindIcon = ({walletKind, ...rest}: WalletKindIconProps) => {
  return <Icon {...rest} type={walletKind} />
}

type ActivationIconProps = Omit<IconProps, 'type'> & {
  type: ActivationIconType
}

export function ActivationIcon({type, ...rest}: ActivationIconProps) {
  return <Icon {...rest} type={type} exactSize={8} />
}

type TransactionStatusIconProps = Omit<IconProps, 'type'> & {
  type: TransactionStatusIconType
}

export function TransactionStatusIcon({
  type,
  ...rest
}: TransactionStatusIconProps) {
  return <Icon {...rest} type={type} exactSize={8} />
}

export function CryptoProviderIcon({
  cryptoProviderType,
  exactSize,
  ...rest
}: {
  cryptoProviderType: CryptoProviderType
  className?: string
  exactSize?: number
}) {
  const type = (
    {
      trezor: 'trezorBadgeIcon',
      ledger: 'ledgerBadgeIcon',
      gridPlus: 'gridPlusBadgeIcon',
      mnemonic: null,
      metamask: null,
    } as const
  )[cryptoProviderType]

  return (
    <>{type && <Icon {...rest} type={type} exactSize={exactSize || 24} />}</>
  )
}

type IconBackgroundWrapperTypes = {
  padding?: number
}

export const IconBackgroundWrapper = styled('div')<IconBackgroundWrapperTypes>(
  ({theme: {spacing, palette}, padding}) => ({
    display: 'flex',
    borderRadius: '50%',
    backgroundColor: palette.grey[800],
    padding: spacing(padding || 0.75),
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  }),
)

export const StatusIcons = {
  WaitingIcon,
  SuccessIcon,
  ErrorIcon,
}
