import type {TorusKey} from '@toruslabs/torus.js'
import Torus from '@toruslabs/torus.js'
import {CHAIN_NAMESPACES} from '@web3auth/base'
import {CommonPrivateKeyProvider} from '@web3auth/base-provider'
import type {OpenloginUserInfo} from '@web3auth/openlogin-adapter'
import {Web3Auth as Web3AuthSfa} from '@web3auth/single-factor-auth'
import type {TorusVerifierResponse as _TorusVerifierResponse} from 'customauth'
import DirectWebSdk, {SkipTorusKey} from 'customauth'

import type {Web3AuthLoginProvider, Web3AuthNetworkType} from '../types'
import {
  getVerifierIdInfo as _getVerifierIdInfo,
  importPrivateKey as _importPrivateKey,
  copyWeb3AuthSession,
  invalidateAccessTokenIfNeeded,
} from '../utils'

import type {SupportedWeb3AuthLoginProvider} from './constants'
import {supportedLoginProviders, verifierMaps} from './constants'

export type TorusVerifierResponse = _TorusVerifierResponse & {
  idToken: string
  typeOfLogin: SupportedWeb3AuthLoginProvider
}
export interface IWeb3AuthConnectionV2 {
  isSupportedLoginProvider: (
    loginProvider: string,
  ) => loginProvider is SupportedWeb3AuthLoginProvider
  getVerifierIdInfo: (
    loginProvider: SupportedWeb3AuthLoginProvider,
    verifierId: string,
  ) => Promise<{
    keyExists: boolean
    keyCreatedAt?: Date
  }>
  loginVerifier: (
    loginProvider: SupportedWeb3AuthLoginProvider,
  ) => Promise<TorusVerifierResponse>
  loginTorusNetwork: (
    verifierResponse: TorusVerifierResponse,
    options?: {
      sessionTime?: number
      sessionNamespace?: string
    },
  ) => Promise<{userInfo: Partial<OpenloginUserInfo>; privateKey: Buffer}>
  rehydrateSession: (options?: {sessionNamespace?: string}) => Promise<{
    userInfo: Partial<OpenloginUserInfo>
    privateKey: Buffer
  } | null>
  logout: (options?: {sessionNamespace?: string}) => Promise<void>
  importPrivateKey: (params: {
    loginProvider: SupportedWeb3AuthLoginProvider
    verifierId: string
    idToken: string
    privateKey: Buffer
  }) => Promise<TorusKey>
}

export const createWeb3AuthConnectionV2 = (
  web3AuthNetwork: Web3AuthNetworkType,
  web3AuthClientId: string,
  nufiBackendUrl: string,
): IWeb3AuthConnectionV2 => {
  const NATIVE_SESSION_KEY = 'sfa_store'
  const DEFAULT_SESSION_NAMESPACE = 'wallet'

  const CHAIN_CONFIG = {
    chainNamespace: CHAIN_NAMESPACES.OTHER,
    // these are just dummy values, they are not used,
    // but without them Web3Auth throws an error
    chainId: '0x1',
    rpcTarget: 'https://dummy.target',
    displayName: '',
    blockExplorer: '',
    ticker: '',
    tickerName: '',
  }

  const isSupportedLoginProvider = (
    loginProvider: string,
  ): loginProvider is SupportedWeb3AuthLoginProvider =>
    (supportedLoginProviders as Web3AuthLoginProvider[]).includes(
      loginProvider as Web3AuthLoginProvider,
    )

  /**
   * First step of the login flow - login to the social login provider
   * (which is bound to the verifier) for the sole purpose of obtaining
   * the id token from it
   *
   * What is a verifier: https://web3auth.io/docs/auth-provider-setup/verifiers
   */
  const loginVerifier = async (
    loginProvider: SupportedWeb3AuthLoginProvider,
  ) => {
    const torusDirectWebSdk = new DirectWebSdk({
      web3AuthClientId,
      baseUrl: `${nufiBackendUrl}/web3auth`,
      redirectPathName: 'redirect.html',
      redirectToOpener: true,
      network: web3AuthNetwork,
      uxMode: 'popup',
    })
    await torusDirectWebSdk.init({skipSw: true})
    const {userInfo} = await torusDirectWebSdk.triggerLogin({
      typeOfLogin: loginProvider,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      ...verifierMaps[web3AuthNetwork]![loginProvider],
      skipTorusKey: SkipTorusKey.Always,
      checkIfNewKey: false,
      customState: {
        appOrigin: window.location.origin,
      },
    })

    if (!userInfo.idToken && !userInfo.accessToken) {
      throw new Error('Login failed: no id or access token received')
    }

    return {
      ...userInfo,
      // some providers (e.g. google) return idToken, some (e.g. discord) access token
      // but the rest of the logic has notion just of "idToken"
      idToken: userInfo.idToken || userInfo.accessToken,
    } as TorusVerifierResponse
  }

  const getInitializedWeb3AuthSfa = async (options: {
    sessionNamespace: string
    sessionTime?: number
  }): Promise<Web3AuthSfa> => {
    const {sessionNamespace, sessionTime} = options

    copyWeb3AuthSession(
      `${NATIVE_SESSION_KEY}_${sessionNamespace}`,
      NATIVE_SESSION_KEY,
    )

    const privateKeyProvider = new CommonPrivateKeyProvider({
      config: {chainConfig: CHAIN_CONFIG},
    })
    const web3AuthSfa = new Web3AuthSfa({
      privateKeyProvider,
      clientId: web3AuthClientId, // Get your Client ID from Web3Auth Dashboard
      web3AuthNetwork, // ["cyan", "testnet"]
      usePnPKey: false, // Setting this to true returns the same key as PnP Web SDK, By default, this SDK returns CoreKitKey.
      sessionTime,
    })

    await web3AuthSfa.init()

    return web3AuthSfa
  }

  const patchUserInfo = (userInfo: OpenloginUserInfo) => {
    // for some reason web3AuthSfa returns the wrong type for typeOfLogin
    // (e.g. "jwt" instead of "google" for google login)
    // so we patch it here
    for (const [typeOfLogin, {verifier}] of Object.entries(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      verifierMaps[web3AuthNetwork]!,
    )) {
      if (verifier === userInfo.verifier) {
        return {...userInfo, typeOfLogin}
      }
    }

    return userInfo
  }

  /**
   * Second step of the login flow - use the id token obtained from `loginVerifier`
   * to authenticate against the Torus network and retrieve the private key from there
   */
  const loginTorusNetwork: IWeb3AuthConnectionV2['loginTorusNetwork'] = async (
    verifierResponse,
    options,
  ) => {
    const {sessionTime, sessionNamespace} = {
      sessionTime: options?.sessionTime ?? 86400, // 1 day
      sessionNamespace: options?.sessionNamespace ?? DEFAULT_SESSION_NAMESPACE,
    }
    const web3AuthSfa = await getInitializedWeb3AuthSfa({
      sessionTime,
      sessionNamespace,
    })
    await web3AuthSfa.logout().catch(() => null)

    const provider = await web3AuthSfa.connect({
      verifier: verifierResponse.verifier,
      verifierId: verifierResponse.verifierId,
      idToken: verifierResponse.idToken,
      fallbackUserInfo: verifierResponse,
    })

    invalidateAccessTokenIfNeeded({
      nufiBackendUrl,
      accessToken: verifierResponse.idToken,
      loginProvider: verifierResponse.typeOfLogin,
    })

    const privateKey = Buffer.from(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      (await provider!.request({
        method: 'private_key',
      })) as string,
      'hex',
    )
    const userInfo = await web3AuthSfa.getUserInfo()

    if (sessionTime <= 0) {
      await web3AuthSfa.logout().catch(() => null)
    }

    copyWeb3AuthSession(
      NATIVE_SESSION_KEY,
      `${NATIVE_SESSION_KEY}_${sessionNamespace}`,
    )

    return {
      privateKey,
      userInfo: patchUserInfo(userInfo),
    }
  }

  const rehydrateSession: IWeb3AuthConnectionV2['rehydrateSession'] = async (
    options,
  ) => {
    const {sessionNamespace} = {
      sessionNamespace: DEFAULT_SESSION_NAMESPACE,
      ...options,
    }

    const web3AuthSfa = await getInitializedWeb3AuthSfa({
      sessionNamespace,
    })

    if (!web3AuthSfa.connected) {
      return null
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const privateKeyHex = (await web3AuthSfa.provider!.request({
      method: 'private_key',
    })) as string | undefined

    // if the session is expired, the private key is not available
    if (!privateKeyHex) {
      return null
    }

    const privateKey = Buffer.from(privateKeyHex, 'hex')
    const userInfo = await web3AuthSfa.getUserInfo()

    return {
      privateKey,
      userInfo: patchUserInfo(userInfo),
    }
  }

  const logout: IWeb3AuthConnectionV2['logout'] = async (options) => {
    const {sessionNamespace} = {
      sessionNamespace: DEFAULT_SESSION_NAMESPACE,
      ...options,
    }
    const web3AuthSfa = await getInitializedWeb3AuthSfa({
      sessionNamespace,
    })
    await web3AuthSfa.logout().catch(() => null)

    // store the "wiped" session back in the namespaced key, effectively deleting it
    copyWeb3AuthSession(
      NATIVE_SESSION_KEY,
      `${NATIVE_SESSION_KEY}_${sessionNamespace}`,
    )
  }

  const getVerifier = (
    loginProvider: SupportedWeb3AuthLoginProvider,
  ): string => {
    const verifier = verifierMaps[web3AuthNetwork]?.[loginProvider].verifier

    if (!verifier) {
      throw new Error(
        `Verifier not found for network ${web3AuthNetwork} and login provider ${loginProvider}`,
      )
    }

    return verifier
  }

  const getVerifierIdInfo = async (
    loginProvider: SupportedWeb3AuthLoginProvider,
    verifierId: string,
  ) => {
    const verifier = getVerifier(loginProvider)

    return await _getVerifierIdInfo({
      network: web3AuthNetwork,
      verifier,
      verifierId,
    })
  }

  const importPrivateKey = async ({
    loginProvider,
    verifierId,
    idToken,
    privateKey,
  }: {
    loginProvider: SupportedWeb3AuthLoginProvider
    verifierId: string
    idToken: string
    privateKey: Buffer
  }) => {
    const authInstance = new Torus({
      clientId: web3AuthClientId,
      enableOneKey: true,
      network: web3AuthNetwork,
    })
    const result = await _importPrivateKey({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      authInstance,
      network: web3AuthNetwork,
      verifier: getVerifier(loginProvider),
      verifierId,
      privateKey,
      idToken,
    })

    invalidateAccessTokenIfNeeded({
      nufiBackendUrl,
      accessToken: idToken,
      loginProvider,
    })

    return result
  }

  return {
    isSupportedLoginProvider,
    loginVerifier,
    loginTorusNetwork,
    rehydrateSession,
    logout,
    getVerifierIdInfo,
    importPrivateKey,
  }
}
