import BigNumber from 'bignumber.js'
import type {ChangellyError, SwapAsset} from 'common'
import _ from 'lodash'
import React, {useState} from 'react'

import {cachedGetExchangeAssetsDetails} from 'src/features/exchange/application'
import type {
  ExchangeAssetsDetails,
  SwapAssetDetail,
} from 'src/features/exchange/domain'
import {isValidSwapAmount} from 'src/features/swaps/application'
import {createNumberFormatter} from 'src/utils/formatting'

import type {ExchangeBlockchain} from '../../blockchainTypes'
import {AmountVisibilityWrapper} from '../../components'
import {assert} from '../../utils/assertion'

import {
  CHANGELLY_ERROR_TRANSLATION_KEYS,
  blockchainToSwapAsset,
} from './constants'
import type {ActiveScreen} from './types'

export const useExchangeScreenState = () => useState<ActiveScreen>('details')

export const getChangellyErrorTranslationKey = (error: ChangellyError) =>
  CHANGELLY_ERROR_TRANSLATION_KEYS.find(
    (e) => e.code === error.code && e.message === error.message,
  )?.translationKey || null

export const verifyMinMaxAmount = (
  amount: number | string,
  minAmount: BigNumber,
  maxAmount: BigNumber,
) =>
  minAmount.isLessThanOrEqualTo(new BigNumber(amount)) &&
  maxAmount.isGreaterThanOrEqualTo(new BigNumber(amount))

export const getDisabledAssets = (
  assets: ExchangeAssetsDetails,
  type: 'from' | 'to',
) => {
  const filterFieldName = type === 'from' ? 'enabledFrom' : 'enabledTo'
  return Object.entries(assets).reduce<SwapAsset[]>(
    (disabledAssets, [assetName, assetProps]) =>
      assetProps!.enabled && assetProps![filterFieldName]
        ? disabledAssets
        : [...disabledAssets, assetName as SwapAsset],
    [],
  )
}

const getFormattedAmount = ({
  expectedAmount,
  amount,
  canBeEstimate,
}: FormattedAmountProps) => {
  const formatter = createNumberFormatter({
    maximumFractionDigits: 18, // Precision for the exchanged amount so the user knows how much to send
  })
  const getFormattedExchangeAmount = (amount: string) =>
    formatter.format(new BigNumber(amount).toNumber())

  if (isValidSwapAmount(amount)) {
    return getFormattedExchangeAmount(amount)
  }

  return `${canBeEstimate ? '~' : ''}${getFormattedExchangeAmount(
    expectedAmount,
  )}`
}

type FormattedAmountProps = {
  expectedAmount: string
  amount: string | null
  canBeEstimate: boolean
}

export const FormattedAmount = ({...props}: FormattedAmountProps) => (
  <AmountVisibilityWrapper>
    <>{getFormattedAmount(props)}</>
  </AmountVisibilityWrapper>
)

export type GetInitialSwapAssetsParams = {
  exchangeAssetsDetails: ExchangeAssetsDetails
  preferredFromAsset: SwapAsset
  fromAssetFallbackBlockchain?: ExchangeBlockchain
  preferredToAsset: SwapAsset
  toAssetFallbackBlockchain?: ExchangeBlockchain
}

export const getInitialSwapAssets = ({
  exchangeAssetsDetails,
  preferredFromAsset,
  preferredToAsset,
  fromAssetFallbackBlockchain,
  toAssetFallbackBlockchain,
}: GetInitialSwapAssetsParams): {
  initialFromAsset: {swapAsset: SwapAsset; detail: SwapAssetDetail}
  initialToAsset: {swapAsset: SwapAsset; detail: SwapAssetDetail}
} => {
  // All exchange assets details
  const enabledExchangeAssetsDetails = _.pickBy(
    exchangeAssetsDetails,
    (value) => value!.enabled,
  ) as Record<SwapAsset, SwapAssetDetail | undefined>

  // The assets to which we prefer to fallback in case the fallback blockchain is not found
  const _preferredFallbackAssets = [
    blockchainToSwapAsset('cardano'),
    blockchainToSwapAsset('ethereum'),
    blockchainToSwapAsset('solana'),
    blockchainToSwapAsset('polygon'),
  ]
    .filter((asset) => asset in enabledExchangeAssetsDetails)
    .reduce(
      (res, asset) => {
        res[asset] = enabledExchangeAssetsDetails[asset]
        return res
      },
      {} as Record<SwapAsset, SwapAssetDetail | undefined>,
    )

  // Fallback to all assets if not having enough preferred assets
  const preferredFallbackAssets =
    Object.keys(_preferredFallbackAssets).length > 2
      ? _preferredFallbackAssets
      : enabledExchangeAssetsDetails

  assert(Object.keys(enabledExchangeAssetsDetails).length >= 2)

  const fromAsset =
    preferredFromAsset in enabledExchangeAssetsDetails
      ? preferredFromAsset
      : fromAssetFallbackBlockchain &&
          blockchainToSwapAsset(fromAssetFallbackBlockchain) in
            enabledExchangeAssetsDetails
        ? blockchainToSwapAsset(fromAssetFallbackBlockchain)
        : (Object.keys(preferredFallbackAssets) as SwapAsset[])[0]!

  const toAsset =
    preferredToAsset !== fromAsset &&
    preferredToAsset in enabledExchangeAssetsDetails
      ? preferredToAsset
      : toAssetFallbackBlockchain &&
          blockchainToSwapAsset(toAssetFallbackBlockchain) in
            enabledExchangeAssetsDetails
        ? blockchainToSwapAsset(toAssetFallbackBlockchain)
        : (Object.keys(preferredFallbackAssets) as SwapAsset[]).find(
            (asset) => asset !== fromAsset,
          )

  assert(toAsset != null)

  return {
    initialFromAsset: {
      swapAsset: fromAsset,
      detail: enabledExchangeAssetsDetails[fromAsset]!,
    },
    initialToAsset: {
      swapAsset: toAsset,
      detail: enabledExchangeAssetsDetails[toAsset]!,
    },
  }
}

export const getSwapAssetDetailsAsync = async (asset: SwapAsset) => {
  const exchangeAssetsDetails = await cachedGetExchangeAssetsDetails()
  return exchangeAssetsDetails[asset]
}
