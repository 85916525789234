import type {
  OPENLOGIN_NETWORK_TYPE,
  LOGIN_PROVIDER,
} from '@web3auth/openlogin-adapter'

type ValueOf<T> = T[keyof T]

export type Web3AuthNetworkType = OPENLOGIN_NETWORK_TYPE

export const WEB3_AUTH_LOGIN_PROVIDERS = [
  'google',
  'facebook',
  'twitter',
  'discord',
] as const

export type Web3AuthLoginProvider = Extract<
  ValueOf<typeof LOGIN_PROVIDER>,
  (typeof WEB3_AUTH_LOGIN_PROVIDERS)[number]
>
