import type {EvmTokenTransferParams} from '@nufi/wallet-evm'

import type {AccountId} from '../../../../types'

import type {
  EvmAddress,
  EvmBlockchain,
  EvmContractAddress,
  EvmTokenId,
} from './../../../types'

export const tokensQueryKeys = {
  index: <TBlockchain extends EvmBlockchain>(blockchain: TBlockchain) => [
    blockchain,
    'tokens',
  ],
  profileTokenImports: <TBlockchain extends EvmBlockchain>(
    blockchain: TBlockchain,
  ) => [...tokensQueryKeys.index(blockchain), 'profileTokenImports'],
  accountTokens: <TBlockchain extends EvmBlockchain>(
    blockchain: TBlockchain,
    accountId: AccountId,
  ) => [...tokensQueryKeys.index(blockchain), 'account', accountId],
  balances: {
    index: <TBlockchain extends EvmBlockchain>(blockchain: TBlockchain) => [
      ...tokensQueryKeys.index(blockchain),
      'balances',
    ],
    allTokens: <TBlockchain extends EvmBlockchain>(blockchain: TBlockchain) => [
      ...tokensQueryKeys.balances.index(blockchain),
      'all',
    ],
    tokensPerAccount: <TBlockchain extends EvmBlockchain>(
      blockchain: TBlockchain,
      tokenId: EvmTokenId<TBlockchain> | null,
    ) => [...tokensQueryKeys.balances.index(blockchain), 'perAccount', tokenId],
    tokenContract: <TBlockchain extends EvmBlockchain>(
      blockchain: TBlockchain,
      address: EvmAddress<TBlockchain>,
      contractAddress: EvmContractAddress<TBlockchain>,
    ) => [
      ...tokensQueryKeys.balances.index(blockchain),
      'tokenContract',
      address,
      contractAddress,
    ],
  },
  tokenMetadata: {
    index: <TBlockchain extends EvmBlockchain>(blockchain: TBlockchain) => [
      ...tokensQueryKeys.index(blockchain),
      'tokenMetadata',
    ],
    all: <TBlockchain extends EvmBlockchain>(blockchain: TBlockchain) => [
      ...tokensQueryKeys.tokenMetadata.index(blockchain),
      'all',
    ],
    token: <TBlockchain extends EvmBlockchain>(
      blockchain: TBlockchain,
      tokenId: EvmTokenId<TBlockchain> | null,
    ) => [...tokensQueryKeys.tokenMetadata.index(blockchain), 'token', tokenId],
  },
  isTokenAvailable: <TBlockchain extends EvmBlockchain>(
    blockchain: TBlockchain,
    tokenId: EvmTokenId<TBlockchain> | null,
    accountId: AccountId,
  ) => [
    ...tokensQueryKeys.index(blockchain),
    'isTokenAvailable',
    tokenId,
    accountId,
  ],
  knownTokenIds: <TBlockchain extends EvmBlockchain>(
    blockchain: TBlockchain,
  ) => [...tokensQueryKeys.index(blockchain), 'knownTokenIds'],

  // 'tokens' prefix intentionally left out from the following keys as they don't
  // need to be invalidated after "standard" mutations which affect tokens
  tokenList: <TBlockchain extends EvmBlockchain>(blockchain: TBlockchain) => [
    blockchain,
    'tokenList',
  ],
  contractGasLimitEstimate: <TBlockchain extends EvmBlockchain>(
    blockchain: TBlockchain,
    tokenTransferParams: EvmTokenTransferParams<TBlockchain>,
  ) => [blockchain, 'contractGasLimitEstimate', tokenTransferParams] as const,
  imperativeOnchainTokenMetadata: <TBlockchain extends EvmBlockchain>(
    blockchain: TBlockchain,
    contractAddress: EvmContractAddress<TBlockchain>,
  ) => [blockchain, 'imperativeOnchainTokenMetadata', contractAddress],
} as const
