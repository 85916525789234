import {useState} from 'react'
import {useTranslation} from 'react-i18next'
import * as yup from 'yup'

import type {AccountId} from '../../../../../../types'
import {
  getInputFieldValueFromValidation,
  isValidPositiveIntegerFieldValue,
} from '../../../../../../utils/form'
import type {
  EvmBlockchain,
  EvmContractAddress,
  EvmTokenAmount,
  EvmErc20Metadata,
  TokenStandard,
} from '../../../../../../wallet/evm'
import {isValidEvmAddress} from '../../../../../../wallet/evm/sdk/utils'
import {useAmountDecimalValidation} from '../../../../../send/common/schema'

export const IMPORT_TOKEN_TABS = ['search', 'custom'] as const
export type EvmImportTokenTab = (typeof IMPORT_TOKEN_TABS)[number]

export const INITIAL_CUSTOM_FIELD_VALUES = {
  customTokenDecimal: '',
  customTokenSymbol: '',
  customTokenName: '',
} as const

export type DetailsSchema<TBlockchain extends EvmBlockchain> = {
  accountId: AccountId
  tokens: EvmErc20Metadata<TBlockchain>[]
  customContractAddress: EvmContractAddress<TBlockchain>
  customTokenSymbol: string
  customTokenName: string
  customTokenDecimal: string
  customTokenStandard?: TokenStandard
  activeTab: EvmImportTokenTab
}

export type ImportEvmTokenSchema<TBlockchain extends EvmBlockchain> =
  DetailsSchema<TBlockchain>

export type EvmTokenImportActiveScreen = 'details' | 'summary' | 'importResult'

export const useEvmImportTokenScreenState = () =>
  useState<EvmTokenImportActiveScreen>('details')

const _useDetailsSchema = <TBlockchain extends EvmBlockchain>(
  accountTokens: EvmTokenAmount<TBlockchain>[],
) => {
  const {t} = useTranslation()
  const activeTabKey: keyof DetailsSchema<TBlockchain> = 'activeTab'
  const tokenDecimalValidation = useAmountDecimalValidation({
    decimals: 0,
  })

  return {
    accountId: yup.string(),
    tokens: yup
      .array()
      .when(activeTabKey, ([activeTab]: unknown[]) =>
        (activeTab as EvmImportTokenTab) === 'search'
          ? yup.array().min(1, t('Please select a token.'))
          : yup.array(),
      ),
    activeTab: yup.string().oneOf([...IMPORT_TOKEN_TABS]),
    customContractAddress: yup
      .string()
      .when(activeTabKey, ([activeTab]: unknown[]) =>
        (activeTab as EvmImportTokenTab) === 'custom'
          ? yup
              .string()
              .required(t("Please enter the token's contract address."))
              .test(
                'is-valid-contract-address',
                t('Invalid contract address'),
                (value) => {
                  if (value === undefined) return true
                  return isValidEvmAddress(value)
                },
              )
              .test(
                'is-not-duplicate',
                t('Token is already imported.'),
                (value) => {
                  if (value === undefined) return true
                  return !accountTokens.find(
                    (t) => t.token.contractAddress === value,
                  )
                },
              )
              .test(
                'is-ERC20',
                t('Only ERC20 tokens can be imported.'),
                function () {
                  const tokenStandard = this.parent.customTokenStandard
                  if (tokenStandard == null) return true
                  return tokenStandard === 'ERC20'
                },
              )
          : yup.string(),
      ),
    customTokenSymbol: yup
      .string()
      .when(activeTabKey, ([activeTab]: unknown[]) =>
        (activeTab as EvmImportTokenTab) === 'custom'
          ? yup
              .string()
              .required(t('Token symbol is required.'))
              .min(1, t('Token Symbol must be at least one character long.'))
              .max(12, t('Token Symbol can not be longer than 12 characters.'))
          : yup.string(),
      ),
    customTokenName: yup
      .string()
      .when(activeTabKey, ([activeTab]: unknown[]) =>
        (activeTab as EvmImportTokenTab) === 'custom'
          ? yup
              .string()
              .required(t('Token name is required.'))
              .min(1, t('Token Name must be at least one character long.'))
              .max(18, t('Token Name can not be longer than 18 characters.'))
          : yup.string(),
      ),
    customTokenDecimal: yup
      .string()
      .when(activeTabKey, ([activeTab]: unknown[]) =>
        (activeTab as EvmImportTokenTab) === 'custom'
          ? yup
              .string()
              .required(t('Token decimals are required.'))
              .concat(tokenDecimalValidation)
              .test(
                'is-within-bounds',
                // tokens can support even 77 decimals, but our Send and UI is not ready for that
                t('Decimals must be between 0 and 18.'),
                function (amount, context) {
                  if (amount === undefined) return true
                  const fieldAmount = getInputFieldValueFromValidation(context)
                  const isInteger =
                    isValidPositiveIntegerFieldValue(fieldAmount)
                  // 0 is not integer, but valid here
                  if (!isInteger && fieldAmount !== '0') return false
                  const parsedAmount = parseInt(fieldAmount, 10)
                  return parsedAmount >= 0 && parsedAmount <= 18
                },
              )
          : yup.string(),
      ),
    customTokenStandard: yup.string().optional(),
  }
}

// ensure consistency of keys / values on TS level
export const useDetailsSchema: <TBlockchain extends EvmBlockchain>(
  ...args: Parameters<typeof _useDetailsSchema>
) => {
  [k in keyof DetailsSchema<TBlockchain>]: ReturnType<
    typeof _useDetailsSchema
  >[k]
} = _useDetailsSchema
