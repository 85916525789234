import {Box, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import type {
  FlowAccountInfo,
  FlowDelegationInfo,
  FlowNodeId,
  FlowUpdateDelegatorTxType,
  Nanoflow,
} from '@nufi/wallet-flow'
import {nanoFlowToFlow} from '@nufi/wallet-flow'
import type {FormikProps} from 'formik'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {useGetNodeIdsToNames} from 'src/wallet/flow/application'

import {
  AccountTransitionCardContent,
  AmountRow,
  Icon,
  QueryGuard,
  ReadOnlyAccountField,
  TotalRow,
  ValidatorTransitionCardContent,
} from '../../../../../components'
import {
  assertUnreachable,
  safeAssertUnreachable,
} from '../../../../../utils/assertion'
import {useGetValidators} from '../../../../../wallet/flow'
import {
  FormattedValidatorName,
  FormattedValidatorType,
  StakingStringEllipsis,
} from '../../utils'

export const getAvailableBalanceForStakingTxType = (
  txType: FlowUpdateDelegatorTxType,
  accountInfo: FlowAccountInfo,
  delegation: FlowDelegationInfo,
): Nanoflow => {
  switch (txType) {
    case 'stakeNewTokens':
      return accountInfo.balance
    case 'withdrawRewards':
      return delegation.tokensRewarded
    case 'requestUnstaking':
      return delegation.tokensCommitted
        .plus(delegation.tokensStaked)
        .minus(delegation.tokensRequestedToUnstake) as Nanoflow
    case 'restakeRewarded':
      return delegation.tokensRewarded
    case 'restakeUnstaked':
      return delegation.tokensRequestedToUnstake.plus(
        delegation.tokensUnstaked,
      ) as Nanoflow
    case 'withdrawUnstaked':
      return delegation.tokensUnstaked
    default:
      return safeAssertUnreachable(txType)
  }
}

type FlowSummaryTargetContentProps = {validatorId: FlowNodeId; bucket: string}

const FlowTransitionCardValidatorContent = ({
  validatorId,
  bucket,
}: FlowSummaryTargetContentProps) => {
  const validatorsQuery = useGetValidators()
  const nodeIdsToNamesQuery = useGetNodeIdsToNames()
  const classes = useStyles()
  const {t} = useTranslation()

  const ValidatorId = <StakingStringEllipsis value={validatorId} />
  return (
    <ValidatorTransitionCardContent CustomIcon={<Icon type={'grayCube'} />}>
      <QueryGuard
        {...validatorsQuery}
        ErrorElement={ValidatorId}
        loadingVariant="centered"
      >
        {(validators) => (
          <QueryGuard
            {...nodeIdsToNamesQuery}
            ErrorElement={ValidatorId}
            loadingVariant="centered"
          >
            {(nodeIdsToNames) => (
              <Box
                pl={1}
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  className={classes.nodeInfoWrapper}
                >
                  <Typography variant="body2">
                    <FormattedValidatorName
                      name={nodeIdsToNames[validatorId]}
                    />
                    {' - '}
                    <FormattedValidatorType
                      nodeRole={
                        validators.find((v) => v.nodeId === validatorId)
                          ?.role || t('Unknown type')
                      }
                    />
                  </Typography>
                  <Typography variant="body2" display="inline-flex">
                    ({ValidatorId})
                  </Typography>
                </Box>
                <Typography variant="body2" color="textSecondary">
                  ({bucket})
                </Typography>
              </Box>
            )}
          </QueryGuard>
        )}
      </QueryGuard>
    </ValidatorTransitionCardContent>
  )
}

const useStyles = makeStyles(() => ({
  nodeInfoWrapper: {
    maxWidth: '75%',
  },
}))

export function TransitionCardSourceContentProps({
  txType,
  accountInfo,
  delegation,
}: UpdateDelegatorTxTypeProps & {
  delegation: FlowDelegationInfo
  accountInfo: FlowAccountInfo
}) {
  const {t} = useTranslation()
  return (
    <>
      {(() => {
        switch (txType) {
          case 'stakeNewTokens':
            return <AccountTransitionCardContent accountInfo={accountInfo} />
          case 'withdrawRewards':
            return (
              <FlowTransitionCardValidatorContent
                validatorId={delegation.nodeId}
                bucket={t('Rewards')}
              />
            )
          case 'requestUnstaking':
            return (
              <FlowTransitionCardValidatorContent
                validatorId={delegation.nodeId}
                bucket={`${t('Committed')} + ${t('Staked')}`}
              />
            )
          case 'restakeRewarded':
            return (
              <FlowTransitionCardValidatorContent
                validatorId={delegation.nodeId}
                bucket={t('Rewards')}
              />
            )
          case 'restakeUnstaked':
            return (
              <FlowTransitionCardValidatorContent
                validatorId={delegation.nodeId}
                bucket={`${t('Requested to unstaked')} + ${t('Unstaked')}`}
              />
            )
          case 'withdrawUnstaked':
            return (
              <FlowTransitionCardValidatorContent
                validatorId={delegation.nodeId}
                bucket={t('Unstaked')}
              />
            )
          default:
            return assertUnreachable()
        }
      })()}
    </>
  )
}

export function TransitionCardTargetContent({
  txType,
  accountInfo,
  delegation,
}: UpdateDelegatorTxTypeProps & {
  accountInfo: FlowAccountInfo
  delegation: FlowDelegationInfo
}) {
  const {t} = useTranslation()
  return (
    <>
      {(() => {
        switch (txType) {
          case 'stakeNewTokens':
            return (
              <FlowTransitionCardValidatorContent
                validatorId={delegation.nodeId}
                bucket={t('Committed')}
              />
            )
          case 'withdrawRewards':
            return <AccountTransitionCardContent accountInfo={accountInfo} />
          case 'requestUnstaking':
            return (
              <FlowTransitionCardValidatorContent
                validatorId={delegation.nodeId}
                bucket={t('Unstaking')}
              />
            )
          case 'restakeRewarded':
            return (
              <FlowTransitionCardValidatorContent
                validatorId={delegation.nodeId}
                bucket={t('Committed')}
              />
            )
          case 'restakeUnstaked':
            return (
              <FlowTransitionCardValidatorContent
                validatorId={delegation.nodeId}
                bucket={t('Committed')}
              />
            )
          case 'withdrawUnstaked':
            return <AccountTransitionCardContent accountInfo={accountInfo} />
          default:
            return assertUnreachable()
        }
      })()}
    </>
  )
}

export function UpdateDelegatorAccountField({
  txType,
  accountInfo,
  delegation,
}: UpdateDelegatorTxTypeProps & {
  accountInfo: FlowAccountInfo
  delegation: FlowDelegationInfo
}) {
  const {t} = useTranslation()
  return (
    <>
      {(() => {
        const availableBalance = getAvailableBalanceForStakingTxType(
          txType,
          accountInfo,
          delegation,
        )
        switch (txType) {
          case 'stakeNewTokens':
            return (
              <ReadOnlyAccountField
                label={t('Account')}
                value={accountInfo}
                blockchain={'flow'}
              />
            )
          case 'withdrawRewards':
            return (
              <AmountRow
                label={t('Rewards balance')}
                amount={availableBalance}
                blockchain={'flow'}
              />
            )
          case 'requestUnstaking':
            return (
              <AmountRow
                label={t('Available for unstaking')}
                amount={availableBalance}
                blockchain={'flow'}
              />
            )
          case 'restakeRewarded':
            return (
              <AmountRow
                label={t('Available rewards to stake')}
                amount={availableBalance}
                blockchain={'flow'}
              />
            )
          case 'restakeUnstaked':
            return (
              <AmountRow
                label={t('Available unstaked tokens to stake')}
                amount={availableBalance}
                blockchain={'flow'}
              />
            )
          case 'withdrawUnstaked':
            return (
              <AmountRow
                label={t('Available unstaked tokens')}
                amount={availableBalance}
                blockchain={'flow'}
              />
            )
          default:
            return assertUnreachable()
        }
      })()}
    </>
  )
}

export function UpdateDelegatorTotalField({
  txType,
  fee,
  amount,
}: UpdateDelegatorTxTypeProps & {fee: Nanoflow; amount: Nanoflow}) {
  const {t} = useTranslation()
  return (
    <>
      {(() => {
        switch (txType) {
          case 'stakeNewTokens':
            return null
          case 'withdrawRewards':
            return (
              <TotalRow
                nativeAmount={amount.minus(fee)}
                blockchain={'flow'}
                totalText={t('Total to withdraw')}
              />
            )
          case 'requestUnstaking':
            return null
          case 'restakeRewarded':
            return null
          case 'restakeUnstaked':
            return null
          case 'withdrawUnstaked':
            return (
              <TotalRow
                nativeAmount={amount.minus(fee)}
                blockchain={'flow'}
                totalText={t('Total to withdraw')}
              />
            )
          default:
            return assertUnreachable()
        }
      })()}
    </>
  )
}

type UpdateDelegatorTxTypeProps = {
  txType: FlowUpdateDelegatorTxType
}

export function UpdateDelegatorModalHeaderTitle({
  txType,
}: UpdateDelegatorTxTypeProps) {
  const {t} = useTranslation()
  return (
    <>
      {(() => {
        switch (txType) {
          case 'stakeNewTokens':
            return t('Stake new FLOW tokens')
          case 'withdrawRewards':
            return t('Withdraw FLOW rewards')
          case 'requestUnstaking':
            return t('Unstake FLOW')
          case 'restakeRewarded':
            return t('Stake FLOW rewards')
          case 'restakeUnstaked':
            return t('Stake unstaked FLOW')
          case 'withdrawUnstaked':
            return t('Withdraw unstaked FLOW')
          default:
            return assertUnreachable()
        }
      })()}
    </>
  )
}

export function DetailScreenAmountHeading({
  txType,
}: UpdateDelegatorTxTypeProps) {
  const {t} = useTranslation()
  return (
    <>
      {(() => {
        switch (txType) {
          case 'stakeNewTokens':
            return t('Amount to add to staking')
          case 'withdrawRewards':
            return t('Amount of rewards')
          case 'requestUnstaking':
            return t('Amount to unstake')
          case 'restakeRewarded':
            return t('Amount of rewards to stake')
          case 'restakeUnstaked':
            return t('Amount of unstaked to stake')
          case 'withdrawUnstaked':
            return t('Amount of unstaked')
          default:
            return assertUnreachable()
        }
      })()}
    </>
  )
}

export function DetailScreenAlertText({txType}: UpdateDelegatorTxTypeProps) {
  const {t} = useTranslation()
  return (
    <>
      {(() => {
        switch (txType) {
          case 'stakeNewTokens':
            return t('FlowStakeNewTokens.details.alert')
          case 'withdrawRewards':
            return t('FlowWithdrawRewards.details.alert')
          case 'requestUnstaking':
            return t('FlowRequestUnstaking.details.alert')
          case 'restakeRewarded':
            return t('FlowRestakeRewards.details.alert')
          case 'restakeUnstaked':
            return t('FlowRestakeUnstaked.details.alert')
          case 'withdrawUnstaked':
            return t('FlowWithdrawUnstaked.details.alert')
          default:
            return assertUnreachable()
        }
      })()}
    </>
  )
}

export function SummaryScreenAmountHeading({
  txType,
}: UpdateDelegatorTxTypeProps) {
  const {t} = useTranslation()
  return (
    <>
      {(() => {
        switch (txType) {
          case 'stakeNewTokens':
            return t('Amount to add to staking')
          case 'withdrawRewards':
            return t('Amount of rewards')
          case 'requestUnstaking':
            return t('Amount to unstake')
          case 'restakeRewarded':
            return t('Amount of rewards to stake')
          case 'restakeUnstaked':
            return t('Amount of unstaked to stake')
          case 'withdrawUnstaked':
            return t('Amount of unstaked')
          default:
            return assertUnreachable()
        }
      })()}
    </>
  )
}

export function getMaxStakingAmountOptions<TForm extends {amount: string}>(
  balance: Nanoflow,
  formikProps: FormikProps<TForm>,
) {
  return balance.isGreaterThan(0)
    ? {
        onMaxAmount: async () => {
          const amountKey: keyof TForm = 'amount' // due to TS
          formikProps.setFieldValue(amountKey, nanoFlowToFlow(balance))
        },
      }
    : undefined
}

export const ErrorMessageForStakingTxType = (
  txType: FlowUpdateDelegatorTxType,
) => {
  const {t} = useTranslation()
  return (
    <>
      {(() => {
        switch (txType) {
          case 'stakeNewTokens':
            return t('Cannot stake more than available')
          case 'withdrawRewards':
            return t('Cannot withdraw more than available')
          case 'requestUnstaking':
            return t('Cannot unstake more than available')
          case 'restakeRewarded':
            return t('Cannot stake more than available')
          case 'restakeUnstaked':
            return t('Cannot stake more than available')
          case 'withdrawUnstaked':
            return t('Cannot withdraw more than available')
          default:
            return assertUnreachable()
        }
      })()}
    </>
  )
}
