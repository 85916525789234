export enum ASSET_UNAVAILABLE_REASON {
  IMPORT_REQUIRED,
}

export type AssetAvailableForAccountInfo =
  | {
      available: true
      unavailabilityReason?: never
    }
  | {
      available: false
      unavailabilityReason: ASSET_UNAVAILABLE_REASON
    }
