import {
  ArrowDropDown as ArrowDropDownIcon,
  ArrowDropUp as ArrowDropUpIcon,
  CheckCircleOutline as SuccessIcon,
  ErrorOutline as ErrorIcon,
  AccessTime as PendingIcon,
  HelpOutline as HelpIcon,
} from '@mui/icons-material'
import type {SxProps, Theme, IconButtonProps, PaperProps} from '@mui/material'
import {
  Grid,
  Typography,
  IconButton,
  Box,
  Divider,
  Paper,
  Popover,
  styled,
} from '@mui/material'
import React, {useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'

import type {CardanoExtras} from '..'
import {WithCardanoExtras} from '..'
import {
  WithTooltip,
  InlineLoading,
  TextButton,
  Modal,
  ModalLayout,
  AssetIconModalHeader,
  FooterLayout,
  ModalFooter,
  MultilineString,
  CopyToClipboard,
  FormattedDateTime,
  TypographyLoader,
  Button,
} from '../../../components'
import {blockchainToCoin, externalLinks} from '../../../constants'
import {routeTo} from '../../../router'
import type {TransactionInfo} from '../../../store/transactions'
import {useTransactionState} from '../../../store/transactions'
import {
  useListenForTxStateChange,
  useShouldRenderTransactionsInfo,
} from '../../../store/transactions/observerUtils'
import {assert} from '../../../utils/assertion'
import {skipForwardingProps} from '../../../utils/stylingUtils'
import {useGetBlockchainName} from '../../../utils/translations'
import {useAllStoredAccountData} from '../../../wallet'
import type {Blockchain} from '../../../wallet'
import {TxErrorBody} from '../common'

import {TransactionItem} from './TransactionItem'

export const useConfirmTxClearState = () => {
  const [txIdToClear, _setTxIdToClear] = useState<null | string>(null)
  const resetTxIdToClear = () => _setTxIdToClear(null)
  const setTxIdToClear = (id: string) => _setTxIdToClear(id)

  return {txIdToClear, resetTxIdToClear, setTxIdToClear}
}

export function TransactionsInfo() {
  const {clearNonPendingTxs, clear, getVisibleTransactions} =
    useTransactionState()
  const transactions = getVisibleTransactions()
  const accountsData = useAllStoredAccountData()
  const {t} = useTranslation()
  const history = useHistory()

  const {txIdToClear, resetTxIdToClear, setTxIdToClear} =
    useConfirmTxClearState()

  const [showMore, setShowMore] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const isOpen = Boolean(anchorEl)
  const [retryTransactionId, setRetryTransactionId] = useState<
    TransactionInfo['mutationId'] | null
  >(null)

  useListenForTxStateChange(null)
  const shouldRenderTransactionsInfo = useShouldRenderTransactionsInfo()

  const toggleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const onClose = () => {
    resetTxIdToClear()
    setAnchorEl(null)
  }

  const onDetails = (tx: TransactionInfo) => {
    assert(!tx.isPending, 'Transaction state mismatch!')
    if (tx.error) {
      setRetryTransactionId(tx.mutationId)
    } else {
      assert(!!tx.context, 'Transaction context not available!')
      onClose()
      history.push(
        routeTo.portfolio.assets
          .blockchain(tx.context.blockchain)
          .detail.account(tx.context.accountId)
          .tab('history').index,
      )
    }
  }

  const onShowMore = () => setShowMore((curr) => !curr)

  const onClearAll = () => {
    clearNonPendingTxs()
    // As we do not clear pending transactions with the 'clear all'
    // button, we also do not close the popover if we detect any such
    // transactions.
    if (!isSomePending) {
      onClose()
    }
  }

  const onClear = (tx: TransactionInfo) => {
    if (transactions.length === 1) {
      onClose()
    }
    clear(tx)
  }

  const hasError = transactions.some((tx) => !!tx.error)
  const isSomePending = transactions.some((tx) => tx.isPending)
  const areAllPending = transactions.every((tx) => tx.isPending)

  const maximumShownTxStatusAmount = 3
  const canShowMore = transactions.length > maximumShownTxStatusAmount
  const hasMoreTransactions = transactions.length > 1

  const retryTransaction = transactions.find(
    (tx) => tx.mutationId === retryTransactionId,
  )

  const hasPendingTransactions = transactions.length > 0

  const getTakingTooLongLink = (tx: TransactionInfo) => {
    const blockchain = tx.context?.blockchain
    if (blockchain === 'cardano') return externalLinks.cardanoCongestionIssues
    return undefined
  }

  if (!hasPendingTransactions) {
    return null
  }

  if (!shouldRenderTransactionsInfo) {
    return (
      <Box sx={{marginRight: 2}}>
        <TypographyLoader width={100} height={14} />
      </Box>
    )
  }

  return (
    <>
      <TxStatusButton onClick={toggleMenu} isOpen={isOpen}>
        <Grid container direction="row" alignItems="center">
          <Grid
            container
            item
            xs={11}
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item mr={0.5} mt={0.5}>
              {hasError ? (
                <ErrorIcon fontSize="small" color="error" />
              ) : (
                <>
                  {isSomePending ? (
                    <PendingIcon fontSize="small" color="warning" />
                  ) : (
                    <SuccessIcon fontSize="small" color="primary" />
                  )}
                </>
              )}
            </Grid>
            <Grid item>
              <Typography variant="body2">{t('Transaction status')}</Typography>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={1}
            justifyContent="flex-end"
            alignItems="center"
          >
            {isOpen ? (
              <ArrowDropUpIcon fontSize="small" />
            ) : (
              <ArrowDropDownIcon fontSize="small" />
            )}
          </Grid>
        </Grid>
      </TxStatusButton>
      <Popover
        PaperProps={{
          sx: {
            minWidth: 450,
            maxHeight: 500,
            ...commonTopBarPopUpStyles,
          },
        }}
        elevation={1}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        keepMounted
        open={isOpen}
        onClose={onClose}
      >
        <Box py={2} px={3}>
          <Grid container direction="column">
            <Grid container>
              <TxStatusClearAllWrapper>
                <Grid container alignItems="center">
                  <Grid
                    container
                    item
                    xs={8}
                    justifyContent="flex-start"
                    alignItems="flex-end"
                    spacing={1}
                  >
                    <Grid item>
                      <Typography variant="subtitle2">
                        {transactions.length}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        display="block"
                        align="center"
                        color="textSecondary"
                        variant="caption"
                      >
                        {hasMoreTransactions
                          ? t('transactions')
                          : t('transaction')}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={4}
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    {!areAllPending && (
                      <WithTooltip
                        title={t('clear_all_tx_explanation') as string}
                      >
                        <TextButton
                          variant="caption"
                          label={t('Clear')}
                          onClick={() => onClearAll()}
                        />
                        <HelpIcon sx={{fontSize: 15, ml: 1}} color="primary" />
                      </WithTooltip>
                    )}
                  </Grid>
                </Grid>
              </TxStatusClearAllWrapper>
            </Grid>
            {(showMore
              ? transactions
              : transactions.slice(0, maximumShownTxStatusAmount)
            ).map((tx) => (
              <TransactionItem
                key={tx.customId}
                {...{
                  tx,
                  onDetails,
                  onClear,
                  txIdToClear,
                  setTxIdToClear,
                  resetTxIdToClear,
                }}
                showBottomDivider={hasMoreTransactions}
                takingTooLongLink={getTakingTooLongLink(tx)}
                accountName={
                  accountsData.find(
                    (account) => account.id === tx.context?.accountId,
                  )?.name
                }
              />
            ))}
            {canShowMore && (
              <Grid container item justifyContent="center">
                <TextButton
                  onClick={() => onShowMore()}
                  variant="body2"
                  color="textSecondary"
                  label={showMore ? t('Show less') : t('Show more')}
                />
              </Grid>
            )}
          </Grid>
        </Box>
      </Popover>
      <Box my={2}>
        <Divider orientation="vertical" sx={{minHeight: 25}} />
      </Box>
      {retryTransaction && (
        <TransactionRetryModal
          transaction={retryTransaction}
          onClose={() => setRetryTransactionId(null)}
        />
      )}
    </>
  )
}

type TransactionRetryModalProps = {
  transaction: TransactionInfo
  onClose: () => void
}

function TransactionRetryModal({
  transaction,
  onClose,
}: TransactionRetryModalProps) {
  const {t} = useTranslation()
  const blockchainNames = useGetBlockchainName()
  const accountsData = useAllStoredAccountData()
  const {isPending, error, updatedAt} = transaction

  // As we lose transaction context while a retry is ongoing,
  // store blockchain from the initially passed context
  const _blockchain = useMemo(() => transaction.context?.blockchain, [])
  assert(!!_blockchain)

  const {retry} = transaction

  return (
    <Modal onClose={onClose} variant="left">
      <ModalLayout
        header={
          <AssetIconModalHeader onClose={onClose} blockchain={_blockchain}>
            <Typography>
              {t('transaction_status', {
                blockchain: blockchainToCoin(_blockchain),
                status: isPending
                  ? t('is pending')
                  : error
                    ? t('failed')
                    : t('success'),
              })}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {blockchainNames(_blockchain)}
            </Typography>
          </AssetIconModalHeader>
        }
        body={
          <>
            {isPending || !transaction.context ? (
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                direction="column"
                sx={{height: '100%'}}
              >
                <Grid item>
                  <InlineLoading />
                </Grid>
              </Grid>
            ) : (
              <Box p={2}>
                {error ? (
                  <TransactionRetryModalErrorContent
                    error={error}
                    blockchain={_blockchain}
                    cardanoContext={
                      _blockchain === 'cardano'
                        ? ({
                            txHash: transaction.context.transactionId,
                            txPlan: transaction.context.txPlan,
                          } as CardanoExtras)
                        : undefined
                    }
                  />
                ) : (
                  <Grid container direction="column" spacing={2}>
                    <Grid container item direction="column" spacing={1}>
                      <Grid item>
                        <Typography color="textSecondary" variant="subtitle2">
                          {t('Transaction hash')}
                        </Typography>
                      </Grid>
                      <Grid item>
                        {transaction.context.transactionId ? (
                          <CopyToClipboard
                            Label={
                              <MultilineString
                                variant="body2"
                                label={transaction.context.transactionId}
                              />
                            }
                            value={transaction.context.transactionId}
                            iconFontSize="small"
                          />
                        ) : (
                          '-'
                        )}
                      </Grid>
                    </Grid>
                    <Grid container item direction="column" spacing={1}>
                      <Grid item>
                        <Typography color="textSecondary" variant="subtitle2">
                          {t('Account')}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="body1">
                          {
                            accountsData.find(
                              (account) =>
                                account.id === transaction.context?.accountId,
                            )?.name
                          }
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container item direction="column" spacing={1}>
                      <Grid item>
                        <Typography color="textSecondary" variant="subtitle2">
                          {t('Last update')}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="body1">
                          <FormattedDateTime dateTime={updatedAt} />
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Box>
            )}
          </>
        }
        footer={
          <ModalFooter hasDivider>
            {retry != null ? (
              <FooterLayout
                leftBtnConf={{
                  onClick: onClose,
                  children: t('Close'),
                }}
                rightBtnConf={{
                  children: t('Retry transaction'),
                  type: 'submit',
                  disabled: !error,
                  onClick: () => retry(),
                }}
              />
            ) : (
              <Button
                onClick={onClose}
                textTransform="none"
                fullWidth
                variant="outlined"
                color="primary"
              >
                {t('Close')}
              </Button>
            )}
          </ModalFooter>
        }
      />
    </Modal>
  )
}

type TransactionRetryModalErrorContentProps = {
  error: unknown
  blockchain: Blockchain
  cardanoContext?: CardanoExtras
}

function TransactionRetryModalErrorContent({
  error,
  blockchain,
  cardanoContext,
}: TransactionRetryModalErrorContentProps) {
  return (
    <WithCardanoExtras
      extras={{txPlan: cardanoContext?.txPlan, txHash: cardanoContext?.txHash}}
    >
      <TxErrorBody {...{error, blockchain}} type="submit" />
    </WithCardanoExtras>
  )
}

const TxStatusClearAllWrapper = styled(Paper)<PaperProps>(({theme}) => ({
  background: theme.palette.grey.A400,
  width: '100%',
  marginBottom: theme.spacing(2),
  padding: theme.spacing(1, 2),
}))

TxStatusClearAllWrapper.defaultProps = {
  elevation: 0,
}

type TxStatusButtonProps = {isOpen: boolean}

const TxStatusButton = styled(IconButton, {
  shouldForwardProp: (prop) =>
    skipForwardingProps<TxStatusButtonProps>(prop, ['isOpen']),
})<IconButtonProps & TxStatusButtonProps>(({theme, isOpen}) => ({
  borderRadius: theme.shape.borderRadius,
  margin: theme.spacing(1),
  background: isOpen ? theme.palette.background.default : undefined,
  '&:hover': {
    background: theme.palette.background.default,
  },
}))

export const commonTopBarPopUpStyles: SxProps<Theme> = {
  marginTop: 2,
  background: (theme) => theme.palette.grey[800],
}
