import _ from 'lodash'
import {useState, useRef} from 'react'

export type AsyncDebounceArgs<T, U> = {
  fn: (value: U) => Promise<T>
  onSuccess: (value: T) => void
  onFailure: (err: Error) => void
  timeout: number
  shouldSkip?:
    | ((data: U) => Promise<boolean> | ((data: U) => boolean))
    | boolean
  leading?: boolean
}

/**
Create debounced function that avoids to call `onSuccess` and `onFailure` handlers
when underlying async function ends in different order related to other fired
debounced functions. In other words the goal is to avoid race-condition on async handlers.

@param fn Async function to be called with the input supplied to debounced function
@param onSuccess Function to call with the successful result of `fn`
@param onFailure Function to call when `fn` fails
@param shouldSkip Boolean or Function that based on data passed to debounce function determines
whether to run it or not
@param timeout Debounce interval
@param leading if set to `true` will not debounce the initial event, default is `false`

@returns Object holding `debouncedFn` and `debouncedCalcFinished` keys which indicates whether
all started debounced calls already finished.
*/
export function useAsyncDebounce<T, U>(props: AsyncDebounceArgs<T, U>) {
  const [pendingInvocationsCount, setPendingInvocationsCount] = useState(0)
  const lastInvoked = useRef<Date | null>(null)

  const debouncedFn = _asyncDebounce({
    decrementPendingInvocationsCount: () =>
      setPendingInvocationsCount((c) => c - 1),
    incrementPendingInvocationsCount: () =>
      setPendingInvocationsCount((c) => c + 1),
    getLastInvoked: () => lastInvoked.current,
    setLastInvoked: (d) => {
      lastInvoked.current = d
    },
    ...props,
  })

  return {
    finished: pendingInvocationsCount === 0,
    debouncedFn,
  }
}

/**
Create debounced function that avoids to call `onSuccess` and `onFailure` handlers
when underlying async function ends in different order related to other fired
debounced functions. In other words the goal is to avoid race-condition on async handlers.

@param fn Async function to be called with the input supplied to debounced function
@param onSuccess Function to call with the successful result of `fn`
@param onFailure Function to call when `fn` fails
@param shouldSkip Boolean or Function that based on data passed to debounce function determines
whether to run it or not
@param timeout Debounce interval
@param leading if set to `true` will not debounce the initial event, default is `false`

@returns `debouncedFn`
*/
export function getAsyncDebounce<T, U>(props: AsyncDebounceArgs<T, U>) {
  let lastInvoked: Date | null = null

  return _asyncDebounce({
    // We do not care about pending invocations count. If needed, use
    // the above hook instead.
    decrementPendingInvocationsCount: () => {},
    incrementPendingInvocationsCount: () => {},
    getLastInvoked: () => lastInvoked,
    setLastInvoked: (d) => {
      lastInvoked = d
    },
    ...props,
  })
}

function _asyncDebounce<T, U>({
  fn,
  timeout,
  onSuccess,
  onFailure,
  getLastInvoked,
  setLastInvoked,
  incrementPendingInvocationsCount,
  decrementPendingInvocationsCount,
  shouldSkip = false,
  leading = false,
}: AsyncDebounceArgs<T, U> & {
  getLastInvoked: () => Date | null
  setLastInvoked: (d: Date) => void
  incrementPendingInvocationsCount: () => void
  decrementPendingInvocationsCount: () => void
}) {
  const debouncedFn = _.debounce(
    async (data: U) => {
      const invokedAt = new Date()
      setLastInvoked(invokedAt)

      const skip =
        typeof shouldSkip === 'boolean' ? shouldSkip : await shouldSkip(data)
      if (!skip) {
        try {
          incrementPendingInvocationsCount()
          const newValue = await fn(data)

          // Avoid possible race-condition if debounced functions end in different order
          if (getLastInvoked()?.getTime() === invokedAt.getTime()) {
            onSuccess(newValue)
          }
        } catch (err) {
          // Avoid possible race-condition if debounced functions end in different order
          if (getLastInvoked()?.getTime() === invokedAt?.getTime()) {
            onFailure(err as Error)
          }
        } finally {
          decrementPendingInvocationsCount()
        }
      }
      // leading: false => debounce also the initial event
      // trailing: true => always execute last event
    },
    timeout,
    {leading, trailing: true},
  )

  return debouncedFn
}
