import {Typography} from '@mui/material'
import type {FlowTransferType} from '@nufi/wallet-flow'
import React from 'react'
import {useTranslation} from 'react-i18next'

type FormattedFlowInstructionProps = {
  txInstruction: typeof FlowTransferType.type
}

export const FormattedFlowInstruction = ({
  txInstruction,
}: FormattedFlowInstructionProps): React.JSX.Element => {
  const {t} = useTranslation()
  const formattedInstruction = ((): string => {
    switch (txInstruction) {
      case 'Deposit':
      case 'Withdrawal':
        return t('Transfer')
      case 'TokensDeposited':
      case 'TokensWithdrawn':
        return t('Token transfer')
      case 'NftDeposited':
      case 'NftWithdrawn':
        return t('NFT transfer')
      case 'NewDelegatorCreated':
        return t('Delegator added to staking collection')
      case 'DelegatorTokensCommitted':
        return t('Delegator tokens committed')
      case 'DelegatorAddedToStakingCollection':
        return t('New delegator created')
      case 'DelegatorTokensStaked':
        return t('Delegator tokens staked')
      case 'DelegatorTokensUnstaking':
        return t('Delegator tokens unstaking')
      case 'DelegatorTokensUnstaked':
        return t('Delegator tokens unstaked')
      case 'DelegatorRewardsPaid':
        return t('Delegator rewards paid')
      case 'DelegatorUnstakedTokensWithdrawn':
        return t('Delegator unstaked tokens withdrawn')
      case 'DelegatorRewardTokensWithdrawn':
        return t('Delegator reward tokens withdrawn')
      case 'NewVault':
        return t('Token vault created')
      case 'Transaction':
        return t('Transaction')
      default:
        return t('Unknown operation')
    }
  })()

  return <Typography>{formattedInstruction}</Typography>
}
