import {styled} from '@mui/material'
import React from 'react'

import onboardingBgImage from 'src/assets/images/onboarding-bg.png'
import {Modal, Icon} from 'src/components'
import {useBackgroundImageStyles} from 'src/utils/layoutUtils'

export const OnboardingModal = ({children}: {children: React.ReactNode}) => {
  const {bgImage} = useBackgroundImageStyles({
    image: onboardingBgImage,
    backgroundSize: 'cover',
  })
  return (
    <Modal className={bgImage} variant="full-width" open>
      <AppLogo />
      <BodyContainer>{children}</BodyContainer>
    </Modal>
  )
}

const AppLogo = styled((props) => <Icon type="appLogo" {...props} />)({
  position: 'absolute',
  top: 32,
  left: 32,
  height: 32,
  width: 'auto',
})

const BodyContainer = styled('div')({
  width: 600,
  margin: 'auto',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
})
