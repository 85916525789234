/* eslint-disable @typescript-eslint/explicit-function-return-type */

import {createGetStakingHistoryForAccount} from '@nufi/wallet-cardano'
import type {AccountId} from '@nufi/wallet-common'
import {arraySum} from '@nufi/wallet-common'
import {useQuery} from '@tanstack/react-query'
import BigNumber from 'bignumber.js'
import _ from 'lodash'

import {fetchNeverStaleQuery, useNeverStaleQuery} from 'src/utils/query-utils'

import {getCardano} from '../../cardanoManagers'

import {cachedGetAccounts} from './core'
import {QUERY_KEY_PREFIX as P} from './utils'

export const getStakingHistoryForAccount = createGetStakingHistoryForAccount(
  () => getCardano(),
)

export const stakingQueryKeys = {
  index: [P, 'staking'],
  stakeAccounts: () => [...stakingQueryKeys.index, 'stakeAccounts'],
  totalStakedAPY: () => [...stakingQueryKeys.index, 'totalStakedAPY'],
  hasActiveStaking: () => [...stakingQueryKeys.index, 'hasActiveStaking'],
  totalNativeBalance: () => [...stakingQueryKeys.index, 'totalNativeBalance'],
  stakeHistory: {
    index: [P, 'stakeAccountHistory'],
    account: (id: AccountId | null) =>
      [...stakingQueryKeys.stakeHistory.index, 'account', id] as const,
  },
  stakePools: [P, 'stakePools'],
} as const

//
// __CACHED__ QUERIES
//

async function getStakeAccounts() {
  const accounts = await cachedGetAccounts()

  const stakeAccounts =
    await getCardano().wallet.getStakeAccountsDetails(accounts)

  return [...stakeAccounts].sort((a, b) => {
    if (a.isStakingKeyRegistered && !b.isStakingKeyRegistered) {
      return -1
    }
    if (!a.isStakingKeyRegistered && b.isStakingKeyRegistered) {
      return 1
    }
    return a.balance.isLessThan(b.balance) ? 1 : -1
  })
}

getStakeAccounts.__key = stakingQueryKeys.stakeAccounts

export function cachedGetStakeAccounts() {
  return fetchNeverStaleQuery({
    queryKey: getStakeAccounts.__key(),
    queryFn: getStakeAccounts,
  })
}

export function useGetStakeAccounts(enabled = true) {
  return useNeverStaleQuery({
    queryKey: getStakeAccounts.__key(),
    queryFn: getStakeAccounts,
    enabled,
  })
}

const getAccountStakeHistory = (accountId: AccountId) => async () => {
  const account = getCardano().accountsStore.getAccount(accountId)
  const accountStakingHistory = (
    await getStakingHistoryForAccount(account)
  ).sort((a, b) => b.timeIssued.getTime() - a.timeIssued.getTime())
  return accountStakingHistory
}

getAccountStakeHistory.__key = stakingQueryKeys.stakeHistory.account

export function cachedGetAccountStakeHistory(accountId: AccountId) {
  return fetchNeverStaleQuery({
    queryKey: getAccountStakeHistory.__key(accountId),
    queryFn: getAccountStakeHistory(accountId),
  })
}

export function useGetAccountStakeHistory(
  accountId: AccountId,
  enabled = true,
) {
  return useNeverStaleQuery({
    queryKey: getAccountStakeHistory.__key(accountId),
    queryFn: getAccountStakeHistory(accountId),
    enabled,
  })
}

export function useGetStakepools() {
  return useQuery({
    queryKey: stakingQueryKeys.stakePools,
    queryFn: () => getCardano().wallet.getStakepools(),
    refetchOnWindowFocus: false,
  })
}

//
// __COMPUTED__ QUERIES
//

export function useGetTotalStakedAPY(enabled = true) {
  return useNeverStaleQuery({
    queryKey: stakingQueryKeys.totalStakedAPY(),
    queryFn: async () => {
      const stakeAccounts = await cachedGetStakeAccounts()
      const delegatedAccounts = stakeAccounts.filter(
        (a) => !!a.delegation?.poolId,
      )
      if (!delegatedAccounts.length) return new BigNumber(0)

      const [accountStakes, weightedAPYs] = _.unzip(
        delegatedAccounts.map((a) => {
          const stakepoolAPY = a.delegation?.apy || new BigNumber(0)
          const accountStake = a.balance.plus(a.rewards)
          return [accountStake, accountStake.times(stakepoolAPY)]
        }),
      )

      const totalStake = arraySum(accountStakes!)
      if (totalStake.eq(0)) return totalStake
      return arraySum(weightedAPYs!).dividedBy(totalStake)
    },
    enabled,
  })
}

export function useGetHasActiveStaking() {
  return useNeverStaleQuery({
    queryKey: stakingQueryKeys.hasActiveStaking(),
    queryFn: async () => {
      const stakeAccounts = await cachedGetStakeAccounts()
      return stakeAccounts.some((a) => !!a.isStakingKeyRegistered)
    },
  })
}

/**
 * Returns balance with unwithdrawn rewards.
 */
async function getTotalNativeBalance() {
  const stakeAccounts = await cachedGetStakeAccounts()

  const parentAccountBalances = stakeAccounts.map(({balance}) => balance)
  const rewardBalances = stakeAccounts.map(({rewards}) => rewards)
  return arraySum([...parentAccountBalances, ...rewardBalances])
}

getTotalNativeBalance.__key = stakingQueryKeys.totalNativeBalance

export function cachedGetTotalNativeBalance() {
  return fetchNeverStaleQuery({
    queryKey: getTotalNativeBalance.__key(),
    queryFn: getTotalNativeBalance,
  })
}

export function useGetTotalNativeBalance(enabled = true) {
  return useNeverStaleQuery({
    queryKey: getTotalNativeBalance.__key(),
    queryFn: getTotalNativeBalance,
    enabled,
  })
}
