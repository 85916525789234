import {
  Launch as ExternalIcon,
  ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material'
import type {SxProps, Theme} from '@mui/material'
import {
  Card,
  Box,
  Typography,
  Avatar,
  Divider,
  Chip,
  Tooltip,
  styled,
  Link,
} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {useGetDApps} from 'src/features/dappDirectory/application'
import type {DappData} from 'src/features/dappDirectory/domain'

import {
  SafeCenterAligner,
  InlineLoading,
  QueryGuard,
  CenteredError,
  PagingButton,
  BlockchainIcon,
} from '../../components'
import {SIDEBAR_WIDTH, DASHBOARD_LOGGED_IN_MIN_WIDTH} from '../../constants'
import {useResponsiveDimensions} from '../../utils/layoutUtils'
import {useGetBlockchainName} from '../../utils/translations'
import {MAIN_RIGHT_SPACING} from '../loggedInMain/constants'

import type {DAppsFilterTypes} from './types'

const defaultPageSize = 25
const DAPP_WIDTH = 250
const DAPP_GAP = 24
const DAPP_IMG_SIZE = 65
const DAPP_IMG_NAME_GAP = 16
const EXTERNAL_ICON_WIDTH = 24

const useGetPageSize = () => {
  const {width} = useResponsiveDimensions()
  const _availableWidth =
    Math.max(DASHBOARD_LOGGED_IN_MIN_WIDTH, width) -
    SIDEBAR_WIDTH -
    MAIN_RIGHT_SPACING
  const cardsInRow = Math.floor(_availableWidth / (DAPP_WIDTH + DAPP_GAP))
  const numberOfColumns = Math.floor(defaultPageSize / cardsInRow)
  return cardsInRow * numberOfColumns
}

export const DAppDirectoryContent = (props: DAppsFilterTypes) => {
  const {blockchain, category, searchText} = props
  const pageSize = useGetPageSize()

  const dAppsQuery = useGetDApps({
    blockchain,
    category,
    searchText,
    pageSize,
  })
  const {hasNextPage, fetchNextPage, isLoading, isFetching} = dAppsQuery
  const {t} = useTranslation()
  return (
    <QueryGuard
      {...dAppsQuery}
      ErrorElement={<CenteredError error={t('Could not load DApps')} />}
      LoadingElement={
        <SafeCenterAligner>
          <InlineLoading />
        </SafeCenterAligner>
      }
    >
      {(data) =>
        data.length > 0 ? (
          <>
            <Box display="flex" flexWrap="wrap" gap={`${DAPP_GAP}px`} py={2}>
              {data.map((data) => (
                <DAppCards data={data} key={data.id} />
              ))}
            </Box>
            <Box textAlign="center">
              <PagingButton
                label={
                  <Box display="flex" gap={0.5}>
                    {t('Show more')}
                    <ExpandMoreIcon />
                  </Box>
                }
                pagingParams={{onNextPage: fetchNextPage, hasNextPage}}
                isLoading={isLoading || isFetching}
                noMoreDataLabel={null}
              />
            </Box>
          </>
        ) : (
          <SafeCenterAligner>
            <Typography variant="h6">{t('No results')}</Typography>
          </SafeCenterAligner>
        )
      }
    </QueryGuard>
  )
}

type DAppCardsProps = {
  data: DappData
}

const DAppCards = ({
  data: {
    name,
    dAppCategories,
    blockchains,
    icon,
    description,
    dappRadarIcon,
    link,
    featured,
  },
}: DAppCardsProps) => {
  const {t} = useTranslation()
  return (
    <DAppCard>
      <CardContentBox gap={`${DAPP_IMG_NAME_GAP}px`}>
        <CardIcon
          variant="rounded"
          src={icon?.url || dappRadarIcon}
          alt={icon?.caption || name}
        />
        <Box width={`calc(100% - ${DAPP_IMG_NAME_GAP + DAPP_IMG_SIZE}px)`}>
          <Typography
            component={Link}
            color="textPrimary"
            href={link}
            target="_blank"
            maxWidth="100%"
            display="inline-flex"
            alignItems="center"
            className="dapp-link"
          >
            <Typography component="span" noWrap width="100%" variant="body2">
              {name}{' '}
            </Typography>
            <TransitionedExternalIcon />
          </Typography>
          <Typography
            variant="caption"
            fontWeight="light"
            color="textSecondary"
            component="div"
            mt={0.5}
          >
            <DAppCardBlockchains blockchains={blockchains} />
          </Typography>
        </Box>
      </CardContentBox>
      <DAppCardCategories dAppCategories={dAppCategories} />
      <Divider />
      <DappDescription title={description}>{description}</DappDescription>
      {featured && (
        <FeaturedBox>
          <Chip label={t('featured')} size="extra-small" color="primary" />
        </FeaturedBox>
      )}
    </DAppCard>
  )
}

const DAppCardCategories = ({
  dAppCategories,
}: Pick<DappData, 'dAppCategories'>) => {
  const CHIP_SIZE = 20

  return (
    <MaxChildrenGroup
      extraContentSx={{width: CHIP_SIZE, height: CHIP_SIZE}}
      max={2}
      tooltipTitle={
        <div>
          {dAppCategories.map((category) => (
            <Typography component="div" variant="body1" key={category}>
              {category}
            </Typography>
          ))}
        </div>
      }
    >
      {dAppCategories.map((category) => (
        <Chip
          key={category}
          label={category}
          size="small"
          color="primary"
          variant="outlined"
          sx={{height: CHIP_SIZE}}
        />
      ))}
    </MaxChildrenGroup>
  )
}

const DAppCardBlockchains = ({blockchains}: Pick<DappData, 'blockchains'>) => {
  const getBlockchainName = useGetBlockchainName()
  const ICON_SIZE = 18
  return (
    <>
      {blockchains.length < 2 ? (
        <>
          {blockchains.length === 1 && (
            <CardContentBox gap={0.5}>
              <BlockchainIcon
                blockchain={blockchains[0]!.name}
                exactSize={ICON_SIZE}
              />{' '}
              <span>{getBlockchainName(blockchains[0]!.name)}</span>
            </CardContentBox>
          )}
        </>
      ) : (
        <MaxChildrenGroup
          max={4}
          alwaysShowTooltip
          tooltipTitle={blockchains.map(({name: blockchain}) => (
            <ChildrenSpacer direction="y" key={blockchain}>
              <CardContentBox gap={0.5}>
                <BlockchainIcon blockchain={blockchain} exactSize={ICON_SIZE} />{' '}
                <Typography component="span" variant="body1">
                  {getBlockchainName(blockchain)}
                </Typography>
              </CardContentBox>
            </ChildrenSpacer>
          ))}
        >
          {blockchains.map(({name: blockchain}) => (
            <BlockchainIcon
              key={blockchain}
              {...{blockchain}}
              exactSize={ICON_SIZE}
            />
          ))}
        </MaxChildrenGroup>
      )}
    </>
  )
}

const FeaturedBox = styled('div')({
  position: 'absolute',
  top: 0,
  right: 0,
  transform: 'translate(0%, -50%)',
})

const MaxChildrenGroup = ({
  children,
  max,
  tooltipTitle,
  extraContentSx,
  alwaysShowTooltip,
}: {
  children: React.ReactNode[]
  max: number
  tooltipTitle: React.ReactNode
  alwaysShowTooltip?: boolean
  extraContentSx?: SxProps<Theme>
}) => {
  const showExtraContent = children.length > max
  const showTooltip = alwaysShowTooltip || showExtraContent
  const template = (
    <ChildrenSpacer direction="x" sx={{display: 'flex'}}>
      {children.slice(0, max).map((child) => child)}
      {showExtraContent && (
        <Avatar
          sx={{
            bgcolor: 'grey.600',
            width: 18,
            height: 18,
            fontSize: 12,
            fontWeight: 600,
            ...extraContentSx,
          }}
        >
          +{children.length - max}
        </Avatar>
      )}
    </ChildrenSpacer>
  )
  return showTooltip ? (
    <Tooltip title={tooltipTitle}>{template}</Tooltip>
  ) : (
    template
  )
}

const DappDescription = styled(Typography)({
  display: '-webkit-box',
  '-webkit-line-clamp': '4',
  '-webkit-box-orient': 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
})

DappDescription.defaultProps = {
  variant: 'body2',
  color: 'textSecondary',
}

const ChildrenSpacer = styled('div')<{direction: 'x' | 'y'}>(
  ({theme: {spacing}, direction}) => ({
    '& > *': {
      ':not(:last-child)': {
        ...(direction === 'x'
          ? {marginRight: spacing(0.5)}
          : {marginBottom: spacing(2.5)}),
      },
    },
  }),
)

const TransitionedExternalIcon = styled(ExternalIcon)(
  ({theme: {transitions}}) => ({
    fontSize: 'inherit',
    color: 'inherit',
    width: 0,
    transition: `${transitions.create(['width'], {
      duration: transitions.duration.standard,
    })}`,
  }),
)

const CardContentBox = styled('div')<{gap: number | string}>(
  ({theme: {spacing}, gap}) => ({
    display: 'flex',
    alignItems: 'center',
    gap: typeof gap === 'number' ? spacing(gap) : gap,
  }),
)

const DAppCard = styled(Card)(({theme: {spacing, palette}}) => ({
  padding: spacing(1, 2),
  margin: spacing(1.5, 0, 1, 0),
  width: DAPP_WIDTH,
  position: 'relative',
  borderRadius: 8,
  overflow: 'visible',
  '& > *:not(:last-child)': {
    marginBottom: spacing(1),
  },
  '&:hover': {
    '& .dapp-link': {
      color: palette.primary.main,
      '& > svg': {
        width: EXTERNAL_ICON_WIDTH,
      },
    },
  },
}))

const CardIcon = styled(Avatar)(({theme: {spacing, shadows, palette}}) => ({
  marginTop: spacing(-3),
  borderRadius: 8,
  background: `linear-gradient(195deg, ${palette.grey[600]}, ${palette.grey[900]})`,
  color: palette.common.white,
  boxShadow: shadows[4],
  width: DAPP_IMG_SIZE,
  height: DAPP_IMG_SIZE,
  '& .MuiAvatar-img': {
    objectFit: 'fill',
    borderRadius: 8,
  },
}))
