import {Box} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import ContentLoader from 'react-content-loader'

import type {StakingBlockchain} from '../../../blockchainTypes'
import {AssetCard, Paper, ListLayoutWrapper} from '../../../components'
import {SIDEBAR_WIDTH} from '../../../constants'
import {
  useResponsiveDimensions,
  ContentLoaderBackground,
} from '../../../utils/layoutUtils'

export const StakingLoading = () => {
  const dimensions = useResponsiveDimensions()
  const maxWidth = dimensions.width - SIDEBAR_WIDTH

  return (
    <Box pr={6}>
      <ContentLoader
        viewBox={`0 0 ${maxWidth} 400`}
        backgroundColor={ContentLoaderBackground()}
      >
        <rect x="50" y="0" rx="15" width="200" height="50" />
        <rect x="50" y="100" rx="15" width={maxWidth - 120} height="60" />
        <rect x="50" y="200" rx="15" width={maxWidth - 200} height="60" />
      </ContentLoader>
    </Box>
  )
}

export const StakingTabContentLoading = () => {
  const dimensions = useResponsiveDimensions()
  const maxWidth = dimensions.width - SIDEBAR_WIDTH

  return (
    <Box pr={6}>
      <ContentLoader
        viewBox={`0 0 ${maxWidth} 400`}
        backgroundColor={ContentLoaderBackground()}
      >
        <rect x="0" y="60" rx="4" width={maxWidth - 0} height="50" />
        <rect x="0" y="118" rx="4" width={maxWidth - 0} height="50" />
        <rect x="0" y="176" rx="4" width={maxWidth - 0} height="50" />
      </ContentLoader>
    </Box>
  )
}

type AssetItemLoadingProps = {
  blockchain: StakingBlockchain
}

export const AssetItemLoading = ({blockchain}: AssetItemLoadingProps) => {
  const classes = useStyles()
  const dimensions = useResponsiveDimensions()
  const approxAssetCardWidth = 120
  const maxWidth = dimensions.width - SIDEBAR_WIDTH - approxAssetCardWidth
  return (
    <Paper defaultListPadding defaultListMargin className={classes.wrapper}>
      <ListLayoutWrapper>
        <Box display="flex" alignItems="center">
          <Box>
            <AssetCard blockchain={blockchain} />
          </Box>
          <Box flex="1">
            <ContentLoader
              viewBox={`0 0 ${maxWidth} 40`}
              backgroundColor={ContentLoaderBackground()}
            >
              <rect
                x="50"
                y="9"
                rx="4"
                ry="4"
                width={maxWidth - 50}
                height="10"
              />
              <rect
                x="50"
                y="24"
                rx="3"
                ry="3"
                width={maxWidth - 200}
                height="10"
              />
            </ContentLoader>
          </Box>
        </Box>
      </ListLayoutWrapper>
    </Paper>
  )
}

const useStyles = makeStyles(() => ({
  wrapper: {
    opacity: 0.7,
  },
}))
