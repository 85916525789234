import type {IdentitySecret} from '@nufi/wallet-common'
import create from 'zustand'
import type {SetState} from 'zustand'

import type {LocalProfileId} from 'src/appStorage'
import {createCoreDappConnectorStateHandlers} from 'src/store/dappConnector'
import type {
  CoreDappConnectorState,
  CreateCardanoCollateralScreenInfo,
  SignScreenInfo,
  SigningKind,
} from 'src/store/dappConnector'
import logger from 'src/store/logger'
import type {AccountId, Blockchain} from 'src/types'

import type {WidgetBlockchain} from '../blockchains'
import type {SessionInfo, WidgetLoginProps} from '../features/login/domain'

type MainScreenInfo = {
  state: 'main'
}

type SpecificAuthorizeScreenInfo =
  | {
      type: 'authorize-on-user-action'
      onFailure: () => void
      onAuthorize: () => void
    }
  | {
      type: 'auto-authorized'
      onFailure: () => void
    }

export type AuthorizeScreenInfo = {
  state: 'authorize'
} & SpecificAuthorizeScreenInfo

type GrantPermissionScreen = {
  state: 'grantPermission'
  onFailure: () => void
  onSuccess: () => void
}

export type ExternalLoginCommonInfo = {
  origin: string
  favIconUrl: string | null
  onSuccess: () => void
  onFailure: () => void
}

type MetamaskLoginScreenInfo = {
  state: 'metamaskLogin'
} & ExternalLoginCommonInfo

type MetamaskAutoLoginScreenInfo = {
  state: 'metamaskAutoLogin'
  data?: {
    profileId: LocalProfileId
    identitySecret: IdentitySecret
    isProfileBackedUp: boolean
  }
} & ExternalLoginCommonInfo

type Web3AuthLoginScreenInfo = {
  state: 'web3AuthLogin'
} & ExternalLoginCommonInfo

type Web3AuthAutoLoginScreenInfo = {
  state: 'web3AuthAutoLogin'
} & ExternalLoginCommonInfo

type ScreenInfo =
  | MainScreenInfo
  | AuthorizeScreenInfo
  | GrantPermissionScreen
  | MetamaskLoginScreenInfo
  | MetamaskAutoLoginScreenInfo
  | Web3AuthLoginScreenInfo
  | Web3AuthAutoLoginScreenInfo
  | SignScreenInfo<SigningKind>
  | CreateCardanoCollateralScreenInfo

export type EmbeddedRampSupportStatus = 'unknown' | 'enabled' | 'disabled'

type State = CoreDappConnectorState<WidgetBlockchain, ScreenInfo> & {
  screenInfo: ScreenInfo
  sessionInfo: SessionInfo | null
  setSessionInfo: (type: SessionInfo) => void
  registerOriginInfo: (args: {
    origin: string
    favIconUrl: string | null
  }) => void
  setAuthorizeScreen: (args: SpecificAuthorizeScreenInfo) => void
  setGrantPermissionScreen: (
    args: Pick<GrantPermissionScreen, 'onFailure' | 'onSuccess'>,
  ) => void
  loginProps: WidgetLoginProps | null
  setLoginProps: (loginType: WidgetLoginProps | null) => void
  setMetamaskLoginScreen: (params: ExternalLoginCommonInfo) => void
  setMetamaskAutoLoginScreen: (params: ExternalLoginCommonInfo) => void
  setWeb3AuthLoginScreen: (params: ExternalLoginCommonInfo) => void
  setWeb3AuthAutoLoginScreen: (params: ExternalLoginCommonInfo) => void
  embeddedRampSupportStatus: EmbeddedRampSupportStatus
  setEmbeddedRampSupportStatus: (status: EmbeddedRampSupportStatus) => void
}

export const useWidgetStore = create<State>(
  logger<State>('WidgetState:')((set) => ({
    sessionInfo: null,
    setSessionInfo: (sessionInfo) => set({sessionInfo}),
    setGrantPermissionScreen: (args) => {
      set({screenInfo: {state: 'grantPermission', ...args}})
    },
    registerOriginInfo: (args) =>
      set({
        origin: args.origin,
        favIconUrl: args.favIconUrl,
      }),
    loginProps: null,
    setLoginProps: (loginProps) => set({loginProps}),
    setMetamaskLoginScreen: (params) =>
      set({
        screenInfo: {
          state: 'metamaskLogin',
          ...params,
        },
      }),
    setMetamaskAutoLoginScreen: (params) =>
      set({
        screenInfo: {
          state: 'metamaskAutoLogin',
          ...params,
        },
      }),
    setWeb3AuthLoginScreen: (params) =>
      set({
        screenInfo: {
          state: 'web3AuthLogin',
          ...params,
        },
      }),
    setWeb3AuthAutoLoginScreen: (params) =>
      set({
        screenInfo: {
          state: 'web3AuthAutoLogin',
          ...params,
        },
      }),
    setAuthorizeScreen: (params) =>
      set({screenInfo: {state: 'authorize', ...params}}),
    ...createCoreDappConnectorStateHandlers({
      set: set as unknown as SetState<
        CoreDappConnectorState<WidgetBlockchain, ScreenInfo>
      >,
      defaultScreenInfo: {state: 'main'},
      initialScreenInfo: {state: 'main'},
    }),
    embeddedRampSupportStatus: 'unknown',
    setEmbeddedRampSupportStatus: (status) =>
      set({embeddedRampSupportStatus: status}),
  })),
)

const storedSelectedAccountKey = 'nufi:widget:selected:accounts'
type StoredSelectedAccounts = Record<Blockchain, AccountId>

const getStoredSelectedAccounts = () => {
  const storedAccounts = localStorage.getItem(storedSelectedAccountKey)
  if (!storedAccounts) return null

  try {
    const parsedAccounts = JSON.parse(storedAccounts) as StoredSelectedAccounts
    return parsedAccounts
  } catch {
    localStorage.removeItem(storedSelectedAccountKey)
    return null
  }
}

export const getStoredSelectedAccount = (bc: Blockchain) =>
  getStoredSelectedAccounts()?.[bc]

export const setStoredSelectedAccount = (bc: Blockchain, id: AccountId) => {
  const storedAccounts = getStoredSelectedAccounts()
  localStorage.setItem(
    storedSelectedAccountKey,
    JSON.stringify({...storedAccounts, [bc]: id}),
  )
}
