import _ from 'lodash'
import React from 'react'
import {useTranslation} from 'react-i18next'

import type {NftBlockchain} from '../../../blockchainTypes'
import {EvmTokenExplorerLink} from '../../../components'
import {assert} from '../../../utils/assertion'
import type {
  EvmBlockchain,
  EvmNft,
  EvmTokenId,
  EvmTokenMetadata,
} from '../../../wallet/evm'
import {NftDetailPage} from '../../nfts/NftDetail'
import {AboutRow} from '../assetDetail/AboutRow'
import type {NftInfo} from '../assetDetail/types'

type EvmTokenDetailInfoProps<TBlockchain extends EvmBlockchain> = {
  blockchain: TBlockchain
  token: EvmTokenMetadata<TBlockchain>
  nftInfo: NftInfo
}

export function EvmTokenDetailInfo<TBlockchain extends EvmBlockchain>({
  blockchain,
  token,
  nftInfo,
}: EvmTokenDetailInfoProps<TBlockchain>) {
  const {t} = useTranslation()

  return nftInfo ? (
    <NftTokenDetailInfo
      blockchain={blockchain as TBlockchain & NftBlockchain}
      nftInfo={nftInfo as EvmNft<TBlockchain & NftBlockchain>}
    />
  ) : (
    <>
      <AboutRow label={t('Contract')} value={token.id} />
      <AboutRow label={t('Decimals')} value={token.decimals} />
      <AboutRow
        label={t('More details')}
        value={
          <EvmTokenExplorerLink blockchain={blockchain} tokenId={token.id} />
        }
      />
    </>
  )
}

type NftTokenDetailInfoProps<
  TBlockchain extends EvmBlockchain & NftBlockchain,
> = {
  blockchain: TBlockchain
  nftInfo: EvmNft<TBlockchain>
}

function NftTokenDetailInfo<TBlockchain extends EvmBlockchain & NftBlockchain>({
  blockchain,
  nftInfo,
}: NftTokenDetailInfoProps<TBlockchain>) {
  const {t} = useTranslation()
  if (!nftInfo) return null
  assert(nftInfo.blockchain === blockchain)

  return (
    <NftDetailPage
      details={[
        {label: t('Description'), value: nftInfo.description},
        {
          label: t('More details'),
          value: (
            <EvmTokenExplorerLink
              blockchain={blockchain}
              tokenId={
                nftInfo.contractAddress as string as EvmTokenId<TBlockchain>
              }
            />
          ),
        },
      ]}
      traits={(nftInfo.attributes || []).map((trait) => ({
        label: _.upperFirst(_.toLower(trait.trait_type)),
        value: trait.value,
      }))}
      blockchain={nftInfo.blockchain}
      nftName={nftInfo.name}
      collectionName={nftInfo.collectionName}
    />
  )
}
