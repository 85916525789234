import type {AccountId} from '@nufi/wallet-common'
import type {
  FlowAccountInfo,
  FlowAddress,
  FlowTokenId,
  FlowTokenMetadata,
  FlowTxPlan,
} from '@nufi/wallet-flow'
import {useTranslation} from 'react-i18next'
import * as yup from 'yup'

import {getBlockchainDecimals} from 'src/constants'

import {getParentSchema} from '../../../utils/form'
import {cachedGetIsTokenSetupForAddress} from '../../../wallet/flow'
import {
  validateIsAmountMoreOrEqualToBalance,
  useBalanceBiggerThanFeeValidation,
  useValidateSendAmount,
} from '../../staking/flow/actionModals/schemas'
import {
  useAmountDecimalValidation,
  useAmountRequiredValidation,
} from '../common/schema'
import type {OverrideDetailsValidationSchema} from '../common/sendMultipleAssetsModal/types'
import type {BaseSendSchema as FormSchema} from '../common/types'

import {isValidFlowAddress} from './utils'

export const useTransferDetailSchema = ({
  accounts,
  tokenId,
  txType,
}: {
  accounts: FlowAccountInfo[]
  tokenId?: FlowTokenId
  txType: FlowTxPlan['type']
}): OverrideDetailsValidationSchema => {
  const balanceBiggerThanFeeValidation = useBalanceBiggerThanFeeValidation({
    accounts,
    txType,
    getFields: getParentSchema,
  })
  const validateSendNativeAmount = useValidateSendAmount()

  const {t} = useTranslation()

  const nativeDecimalValidation = useAmountDecimalValidation({
    decimals: getBlockchainDecimals('flow'),
  })

  const amountRequiredValidation = useAmountRequiredValidation()

  return (baseValidationSchema, metadataById) => ({
    ...baseValidationSchema,
    ...(tokenId
      ? {
          // tokenTx validations
          assets: baseValidationSchema.assets.concat(
            yup.array().of(
              yup.object().shape({
                // only checks for tx fee in comparison to account's native balance
                // it is comfortable to attach it at this place with current sending setup
                // remove when extending to send multiple assets at once
                amount: balanceBiggerThanFeeValidation,
              }),
            ),
          ),
          toAddress: baseValidationSchema.toAddress.concat(
            yup
              .string()
              .required()
              .test(
                'is-token-setup',
                t(
                  'Cannot send token to this address, the receiver does not have the token set up.',
                ),
                async (address, context) => {
                  if (address === undefined || metadataById === undefined) {
                    return true
                  }
                  const tokenData = metadataById[tokenId] as FlowTokenMetadata
                  if (!tokenData) return true

                  // we need to do a validation on the address from the naming service as well
                  // to determine if it is set up for the token which it should receive
                  const values: FormSchema = context.parent
                  const toAddressNameServiceState =
                    values.toAddressNameServiceState

                  const addressToValidate =
                    toAddressNameServiceState.status === 'valid'
                      ? toAddressNameServiceState.resolvedAddress
                      : address

                  if (!addressToValidate) {
                    // it is responsibility of another validation to if the address exists/is correct
                    return true
                  }
                  if (!(await isValidFlowAddress(addressToValidate))) {
                    // it is responsibility of another validation to check for address validity
                    return true
                  }

                  try {
                    return await cachedGetIsTokenSetupForAddress({
                      contractMetadata: tokenData.contractMetadata,
                      address: addressToValidate as FlowAddress,
                      mustBeFullySetUp: false,
                    })
                  } catch (e) {
                    return false
                  }
                },
              ),
          ),
        }
      : {
          // nativeTx validations
          assets: baseValidationSchema.assets.concat(
            yup.array().of(
              yup.object().shape({
                amount: amountRequiredValidation
                  .concat(nativeDecimalValidation)
                  .concat(
                    yup
                      .string()
                      .test(
                        'amount-not-more-than-max',
                        t('Cannot send more than available'),
                        (amount, context) => {
                          if (amount === undefined) return true
                          const {accountId}: {accountId: AccountId} =
                            getParentSchema(context)

                          return validateIsAmountMoreOrEqualToBalance({
                            accountId,
                            context,
                            accounts,
                          })
                        },
                      ),
                  )
                  .concat(
                    yup
                      .string()
                      .test(
                        'debounced-amount-validation',
                        async (amount, context) => {
                          if (amount === undefined) return true
                          const {accountId}: {accountId: AccountId} =
                            getParentSchema(context)
                          return await validateSendNativeAmount({
                            accountId,
                            amount,
                            accounts,
                            txType,
                            context,
                          })
                        },
                      ),
                  ),
              }),
            ),
          ),
        }),
  })
}
