import type BigNumber from 'bignumber.js'

import type {ExchangeBlockchain} from 'src/blockchainTypes'
import {validateAssetDecimalsCount} from 'src/utils/form'
import type {TokenId} from 'src/wallet'
import {commonParseTokenAmount} from 'src/wallet'
import {assetMainToBaseUnit} from 'src/wallet/utils/parseUtils'

export type CommonValueFieldConstraints = {
  maxDecimals?: number
  fee?: BigNumber
  maxNativeAmount?: BigNumber
  maxTokenAmount?: BigNumber
  hasError?: boolean
}

type CommonFromAssetAmountValidationError =
  | {type: 'UNEXPECTED_ERROR'}
  | {type: 'INSUFFICIENT_ACCOUNT_BALANCE'}
  | {type: 'INSUFFICIENT_NATIVE_BALANCE_FOR_NETWORK_FEE'}
  | {type: 'COULD_NOT_GET_DECIMALS'}
  | {
      type: 'TOO_MANY_DECIMALS_FOR_KNOWN_ASSET'
      args: {
        decimals?: number
        assetName: string
      }
    }

type CommonFromAssetAmountValidationParams = {
  humanReadableAmountToExchange: string
  assetName: string
  nativeAccountBalance: BigNumber
  blockchain: ExchangeBlockchain
  tokenId: TokenId | null
  getValueConstraints: (
    humanReadableAmountToExchange: string,
  ) => Promise<CommonValueFieldConstraints | null>
}

type CommonFromAssetAmountValidationDeps = {
  getTokenDecimals: (
    blockchain: ExchangeBlockchain,
    tokenId: TokenId,
  ) => Promise<number>
}

export const createCommonFromAssetAmountValidation =
  ({getTokenDecimals}: CommonFromAssetAmountValidationDeps) =>
  async ({
    humanReadableAmountToExchange,
    assetName,
    nativeAccountBalance,
    blockchain,
    tokenId,
    getValueConstraints,
  }: CommonFromAssetAmountValidationParams): Promise<
    CommonFromAssetAmountValidationError | undefined
  > => {
    // We pre-validate for obvious error cases before calling the `getValueConstraints`.
    if (tokenId == null) {
      const convertedAmount = assetMainToBaseUnit(
        blockchain,
        humanReadableAmountToExchange,
      )
      if (nativeAccountBalance.isLessThan(convertedAmount)) {
        return {type: 'INSUFFICIENT_ACCOUNT_BALANCE'}
      }
    }

    const valueConstraints = await getValueConstraints(
      humanReadableAmountToExchange,
    )

    // Not much to further validate in this case as all the below validation operates
    // on blockchain specific constraints.
    if (valueConstraints == null) return undefined

    if (
      valueConstraints.maxDecimals != null &&
      !validateAssetDecimalsCount(
        humanReadableAmountToExchange,
        valueConstraints.maxDecimals,
      )
    ) {
      return {
        type: 'TOO_MANY_DECIMALS_FOR_KNOWN_ASSET',
        args: {
          decimals: valueConstraints.maxDecimals,
          assetName,
        },
      }
    }

    if (tokenId != null) {
      // Token asset case
      const maxTokenAmount = valueConstraints.maxTokenAmount

      if (maxTokenAmount != null) {
        if (maxTokenAmount.isEqualTo(0)) {
          return {type: 'INSUFFICIENT_ACCOUNT_BALANCE'}
        }

        let decimals: number
        try {
          decimals = await getTokenDecimals(blockchain, tokenId)
        } catch (err) {
          return {type: 'COULD_NOT_GET_DECIMALS'}
        }

        const convertedAmount = commonParseTokenAmount(
          humanReadableAmountToExchange,
          decimals,
        )

        if (maxTokenAmount.isLessThan(convertedAmount)) {
          return {type: 'INSUFFICIENT_ACCOUNT_BALANCE'}
        }
      }

      if (
        valueConstraints.fee != null &&
        nativeAccountBalance.isLessThan(valueConstraints.fee)
      ) {
        return {
          type: 'INSUFFICIENT_NATIVE_BALANCE_FOR_NETWORK_FEE',
        }
      }
    } else {
      const convertedAmount = assetMainToBaseUnit(
        blockchain,
        humanReadableAmountToExchange,
      )
      if (
        valueConstraints.maxNativeAmount != null &&
        valueConstraints.maxNativeAmount.isLessThan(convertedAmount)
      ) {
        return {type: 'INSUFFICIENT_ACCOUNT_BALANCE'}
      }
    }

    // If some error happened when calculating constraints and none of the above validations
    // returned some meaningful error, we inform the user about it.
    if (valueConstraints.hasError) {
      return {type: 'UNEXPECTED_ERROR'}
    }

    return undefined
  }
