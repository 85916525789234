/* eslint-disable @typescript-eslint/explicit-function-return-type */

import {isAccountActive} from '@nufi/wallet-common'
import _ from 'lodash'

import {fetchNeverStaleQuery, useNeverStaleQuery} from 'src/utils/query-utils'

import {getMockServices} from '../../../__tests__/storybook/MockServiceLocator'
import {existingBlockchains} from '../../../blockchainTypes'
import type {AccountStoredData} from '../../../store/wallet'
import {getAccountStore, useEvmStore} from '../../../store/wallet'
import {notEmpty} from '../../../utils/helpers'
import {getAvailableEvmBlockchains} from '../../evm/evmBlockchains'
import {useEvmQueryPerBlockchain} from '../../evm/public/utils'
import type {AccountInfo, Blockchain} from '../../types'
import {useOfflineAccountsInfo} from '../../utils/accountsQueries'
import {sortAccounts} from '../../utils/common'
import {getAccounts} from '../accounts'

export const cachedGetAccounts = <TAccountInfo extends AccountInfo>(
  blockchain: Blockchain,
) => {
  return fetchNeverStaleQuery({
    queryKey: getAccounts.__key(blockchain),
    queryFn: () => getAccounts.fn<TAccountInfo>(blockchain),
  })
}

export const makeUseGetAccounts = <_TAccountInfo extends AccountInfo>(
  fn: typeof getAccounts.fn,
) =>
  function UseGetAccounts<TAccountInfo extends AccountInfo = _TAccountInfo>(
    blockchain: Blockchain,
    enabled = true,
  ) {
    return useNeverStaleQuery({
      queryKey: getAccounts.__key(blockchain),
      queryFn: () => fn<TAccountInfo>(blockchain),
      enabled,
    })
  }

const _useGetAccounts = makeUseGetAccounts(getAccounts.fn)

export type UseGetAccounts = typeof _useGetAccounts

/**
 * The result of this function might not correspond to the result of `useHasAccounts` because
 * it doesn't return any data for disabled chains. For example when a blockchain is just
 * activated, then `useHasAccounts` might be updated faster than the result of this fn.
 * Thus be careful if your component uses this query for accounts and depends on `useHasAccounts`
 * to determine if there are any accounts - e.g. `ChooseAccountScreen`.
 */
export const useGetAccounts: UseGetAccounts = (...args) => {
  const mockServices = getMockServices()
  return mockServices
    ? mockServices.useGetAccounts(...args)
    : // eslint-disable-next-line react-hooks/rules-of-hooks
      _useGetAccounts(...args)
}

export function useAllAccounts() {
  const queries = Object.fromEntries(
    existingBlockchains.map((blockchain) => [
      blockchain,
      // eslint-disable-next-line react-hooks/rules-of-hooks
      _useGetAccounts(blockchain),
    ]),
  )

  const isLoading = Object.values(queries).some((q) => q.isLoading)
  const loadings = Object.keys(queries).reduce(
    (res, k) => {
      const blockchain = k as Blockchain
      res[blockchain] = !!queries[blockchain]?.isLoading
      return res
    },
    {} as Record<Blockchain, boolean>,
  )

  const data = (() => {
    const allData = Object.values(queries)
      .map((q) => q.data || [])
      .flat()
    return sortAccounts(allData)
  })()

  const errorKeys = Object.entries(queries).reduce(
    (acc, [blockchain, q]) =>
      q.error ? [...acc, blockchain as Blockchain] : acc,
    [] as Blockchain[],
  )

  return {
    data,
    isLoading,
    loadings,
    errorKeys,
  }
}

export function useAllStoredEvmAccounts() {
  return useEvmQueryPerBlockchain(() => useEvmStore.getState().accounts)
}

export type AllStoredEvmAccounts = ReturnType<typeof useAllStoredEvmAccounts>

const flattenAccountData = (accounts: (AccountStoredData[] | null)[]) =>
  accounts.flat().filter(notEmpty)

export function useAllStoredAccountData() {
  return flattenAccountData(
    existingBlockchains.map(
      // This is actually calling a hook. Be careful if adding
      // some conditions to not change order!
      (blockchain) => getAccountStore(blockchain)().accounts,
    ),
  )
}

// For usage in imperative code
export function getAllStoredAccountData() {
  return flattenAccountData(
    existingBlockchains.map(
      (blockchain) => getAccountStore(blockchain).getState().accounts,
    ),
  )
}

/**
 * @returns `true` if there are **stored** accounts for a given blockchain. The result of
 * this function might not correspond to the result of `useGetAccounts` i.e. `useHasAccounts`
 * might be true even if `useGetAccounts` doesn't return any accounts.
 */
export function useHasAccounts(blockchain: Blockchain) {
  return useOfflineAccountsInfo(blockchain).length !== 0
}

const getUsedBlockchains = (accounts: AccountStoredData[]) => {
  return _.uniq(
    accounts
      .filter(isAccountActive)
      .map((a) =>
        a.type === 'standard' ? a.blockchain : getAvailableEvmBlockchains(),
      )
      .flat(),
  )
}

export function useGetHasAccounts() {
  const usedBlockchains = getUsedBlockchains(useAllStoredAccountData())
  return (blockchain: Blockchain) => usedBlockchains.includes(blockchain)
}

// For usage in imperative code
export function getHasAccounts(blockchain: Blockchain) {
  const usedBlockchains = getUsedBlockchains(getAllStoredAccountData())
  return usedBlockchains.includes(blockchain)
}
