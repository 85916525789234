import type {TextFieldProps} from '@mui/material'
import {Typography, Box, TextField} from '@mui/material'
import React from 'react'

import {outlinedInputShrinkLabelRation} from '../../../../theme/components'

export type TwoLabelTextFieldProps = Omit<
  TextFieldProps,
  'sx' | 'label' | 'variant'
> &
  TwoLegendInputLabelProps

export const TwoLabelTextField = ({
  leftLegend,
  rightLegend,
  InputProps,
  InputLabelProps,
  ...rest
}: TwoLabelTextFieldProps) => (
  <TextField
    variant="outlined"
    margin="none"
    sx={{
      ...inputRootOverrides,
      input: {
        '&::placeholder': {color: (theme) => theme.palette.text.secondary},
      },
    }}
    label={<TwoLegendInputLabel {...{leftLegend, rightLegend}} />}
    InputLabelProps={{
      ...InputLabelProps,
      shrink: true,
      sx: inputLabelOverrides,
    }}
    InputProps={{...InputProps, sx: baseInputOverrides}}
    {...rest}
  />
)

type TwoLegendInputLabelProps = {
  leftLegend: string
  rightLegend: React.ReactNode
}
const TwoLegendInputLabel = ({
  leftLegend,
  rightLegend,
}: TwoLegendInputLabelProps) => {
  const commonProps = {
    display: 'flex',
    width: '50%',
    className: customizedInputLegendClassName,
    alignItems: 'flex-end',
  }
  const typoProps = {
    fontSize: 'inherit',
    lineHeight: 'inherit',
    noWrap: true,
    maxWidth: '100%',
    flexShrink: 0,
  }
  return (
    <Box display="flex" width="100%" justifyContent="space-between">
      <Box
        textAlign="left"
        {...commonProps}
        sx={{
          '&:after': {
            marginLeft: '4px',
            ...fakeBorderStyles,
          },
        }}
      >
        <Typography {...typoProps}>{leftLegend}</Typography>
      </Box>
      <Box
        textAlign="right"
        {...commonProps}
        sx={{
          '&:before': {
            marginRight: '4px',
            ...fakeBorderStyles,
          },
        }}
      >
        <Typography {...typoProps}>{rightLegend}</Typography>
      </Box>
    </Box>
  )
}

const baseInputBorderColor = 'rgba(255,255,255,0.23)'
const baseInputBorderWidth = 1 // px
const labelTransferredTopPosition = -9 // px
const fakeBorderTransferredTopPosition = -7.75 // px
const sideLabelSpacing = 14 // px
const customizedInputLegendClassName = 'customizedInputLegend'

const fakeBorderStyles = {
  color: baseInputBorderColor,
  content: '""',
  width: '100%',
  display: 'inline-block',
  transform: `translate(0px, ${fakeBorderTransferredTopPosition}px)`,
  borderBottomColor: 'currentColor',
  borderBottomStyle: 'solid',
  borderBottomWidth: baseInputBorderWidth,
  flexGrow: 1,
}

// inherit proper styles for all input states
const inputRootOverrides = {
  [`&:hover .${customizedInputLegendClassName}::before, &:hover .${customizedInputLegendClassName}::after `]:
    {
      color: 'text.primary',
    },
  [`&:hover .MuiOutlinedInput-notchedOutline`]: {
    borderColor: 'text.primary',
  },
  [`.Mui-focused .${customizedInputLegendClassName}::after,.Mui-focused .${customizedInputLegendClassName}::before`]:
    {
      borderWidth: 2,
      color: 'inherit',
    },
  [`.Mui-error .${customizedInputLegendClassName}::after,.Mui-error .${customizedInputLegendClassName}::before`]:
    {
      color: 'inherit',
    },
  [`.Mui-disabled .${customizedInputLegendClassName}::after,.Mui-disabled .${customizedInputLegendClassName}::before`]:
    {
      color: 'inherit',
    },
}

// enable <label> to spread across whole width
const inputLabelOverrides = {
  width: '100%',
  '&.MuiInputLabel-shrink': {
    transform: `translate(${sideLabelSpacing}px, ${labelTransferredTopPosition}px) scale(1)!important`,
  },
  maxWidth: `calc(100% - ${sideLabelSpacing * 2}px)`,
  fontSize: `${outlinedInputShrinkLabelRation}%`,
}

// extend the inner <legend> styles
const baseInputOverrides = {
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: baseInputBorderColor,
    borderWidth: baseInputBorderWidth,
  },
  '& .MuiOutlinedInput-notchedOutline > legend': {
    width: '100%',
  },
  '& .MuiOutlinedInput-notchedOutline > legend > span': {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
}
