import React from 'react'
import {useTranslation} from 'react-i18next'

import {Alert} from '../../components'
import {useDappConnectorStore} from '../../store/dappConnector'
import {assert} from '../../utils/assertion'
import {useGetBlockchainName} from '../../utils/translations'
import {walletConnectBlockchains} from '../constants'
import {WalletConnectError} from '../core/hooks'
import {isEnabledWalletConnectBlockchain} from '../utils'

export const ErrorScreen = () => {
  const {screenInfo} = useDappConnectorStore()

  assert(screenInfo.state === 'error')
  const {t} = useTranslation()

  const getBlockchainName = useGetBlockchainName()

  const {error} = screenInfo

  if (
    error instanceof WalletConnectError &&
    error.params.errorKey === 'UNSUPPORTED_CHAINS'
  ) {
    return (
      <Alert
        text={t('wallet_connect_session_proposal_unsupported_chain_error', {
          chains: walletConnectBlockchains
            .filter((blockchain) =>
              isEnabledWalletConnectBlockchain(blockchain),
            )
            // create a string with the list of supported blockchains e.g. (Solana, Cardano, Flow)
            .map((blockchain) => getBlockchainName(blockchain))
            .join(', '),
        })}
        severity="info"
      />
    )
  }

  return (
    <Alert text={t('wallet_connect_session_proposal_error')} severity="error" />
  )
}
