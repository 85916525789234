import {assert} from '@nufi/frontend-common'

import type {ProfileSettings} from 'src/appStorage/profileManager/types'
import type {Currency} from 'src/features/conversionRates/domain'
import type {Blockchain} from 'src/types'

export const getUpdatedSettings = (
  currentSettings: ProfileSettings,
  updatedValues: Partial<ProfileSettings>,
) => ({
  ...currentSettings,
  ...updatedValues,
  updatedAt: Date.now(),
})

export const activateMnemonic = (currentSettings: ProfileSettings) =>
  getUpdatedSettings(currentSettings, {isMnemonicActivated: true})

export const setCurrency = (
  currentSettings: ProfileSettings,
  currency: Currency,
) => getUpdatedSettings(currentSettings, {currency})

export const setAmountsVisibility = (
  currentSettings: ProfileSettings,
  areAmountsVisible: boolean,
) => getUpdatedSettings(currentSettings, {areAmountsVisible})

export const setEnabledBlockchains = (
  currentSettings: ProfileSettings,
  enabledBlockchains: Blockchain[],
) => {
  // Make sure there are no duplicate arrays - we use assertion instead of modifying the
  // array so that we'd be notified early if duplicates are included in `enabledBlockchains`
  // indicating an error somewhere up the call chain.
  assert(enabledBlockchains.length === new Set(enabledBlockchains).size)
  return getUpdatedSettings(currentSettings, {
    enabledBlockchains,
  })
}
