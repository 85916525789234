import {ArrowBack as GoBackIcon} from '@mui/icons-material'
import {Box, Divider, Typography, Paper} from '@mui/material'
import BigNumber from 'bignumber.js'
import type {FormikProps} from 'formik'
import {Formik} from 'formik'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import * as yup from 'yup'

import {Button} from '../../../../components'
import {useAutoRefreshGasSuggestions} from '../../../../pages/send/evm/gas/LondonGasOptions'
import type {LondonUiGasSchema} from '../../../../pages/send/evm/gas/schema'
import {
  useLondonGasSchema,
  useSimpleGasSchema,
} from '../../../../pages/send/evm/gas/schema'
import type {GasOptionsProps} from '../../../../pages/send/evm/gas/types'
import {WithGasOptionsData} from '../../../../pages/send/evm/gas/WithGasOptionsData'
import {assert} from '../../../../utils/assertion'
import type {
  EvmBlockchain,
  EvmLondonGasConfig,
  EvmWei,
} from '../../../../wallet/evm'
import {assertIsEvmBlockchain} from '../../../../wallet/evm'
import {EVM_TRANSFER_GAS_UNITS} from '../../../../wallet/evm/constants'
import {SignScreen} from '../Sign'
import {useSignContext} from '../SignContext'
import type {BlockchainTxDataProps} from '../txData/types'

export type EvmCustomSignOptions<TBlockchain extends EvmBlockchain> =
  | {
      type: 'london'
      gasLimit: BigNumber
      maxFeePerGas: EvmWei<TBlockchain>
      maxPriorityFeePerGas: EvmWei<TBlockchain>
    }
  | {
      type: 'simple'
      gasLimit: BigNumber
      gasPrice: EvmWei<TBlockchain>
    }

export function EvmSign() {
  const {signData, blockchain} = useSignContext()
  assertIsEvmBlockchain(blockchain)

  if (signData.state === 'sign-tx') {
    return <EvmSignTx />
  }

  return <SignScreen />
}

function EvmSignTx() {
  const {signData, blockchain} = useSignContext()
  assertIsEvmBlockchain(blockchain)
  assert(signData.state === 'sign-tx', 'Sign: invalid screen type')

  const [isSigning, setIsSigning] = useState(false)
  const onTxSignReset = () => setIsSigning(false)
  const onTxSignStart = () => setIsSigning(true)

  const {txParams} = signData.data.data as BlockchainTxDataProps<
    typeof blockchain
  >
  const gasLimit = txParams.gasLimit
    ? new BigNumber(txParams.gasLimit as string | number)
    : // TODO: estimate gas based on type of contract, as this value would probably be
      // too low, or always require `gasLimit`?
      EVM_TRANSFER_GAS_UNITS

  return (
    <WithGasOptionsData
      blockchain={blockchain}
      disableQuery={isSigning}
      customLondonOptions={{
        // We are auto-resetting gas within this component instead,
        // as otherwise the fee would not update, if the `EvmGasOptions`
        // were not rendered.
        allowResetOnGasSuggestionsChange: false,
        autoPadding: 5,
        gasLimit,
      }}
      customSimpleOptions={{
        gasLimit,
      }}
      customOpStackFeeModelOptions={{unsignedTx: txParams}}
    >
      {(gasProps) => (
        <EvmSignContent
          {...{
            gasProps,
            onTxSignReset,
            onTxSignStart,
          }}
        />
      )}
    </WithGasOptionsData>
  )
}

type AutoRefreshLondonGasSuggestionsProps<TBlockchain extends EvmBlockchain> = {
  formikProps: FormikProps<LondonUiGasSchema>
  gasProps: GasOptionsProps<TBlockchain>
}

function AutoRefreshLondonGasSuggestions<TBlockchain extends EvmBlockchain>({
  formikProps,
  gasProps,
}: AutoRefreshLondonGasSuggestionsProps<TBlockchain>) {
  assert(gasProps.gasOptionsData.type === 'london')
  useAutoRefreshGasSuggestions({
    formikProps,
    gasSuggestions: gasProps.gasOptionsData.gasSuggestions,
    gasConfig: gasProps.gasConfig as EvmLondonGasConfig<TBlockchain>,
    enable: true,
  })
  return null
}

type EvmSignContentProps<TBlockchain extends EvmBlockchain> = {
  gasProps: GasOptionsProps<TBlockchain>
  onTxSignReset: () => void
  onTxSignStart: () => void
}

function EvmSignContent<TBlockchain extends EvmBlockchain>({
  gasProps,
  onTxSignReset,
  onTxSignStart,
}: EvmSignContentProps<TBlockchain>) {
  const {t} = useTranslation()

  const {signData, blockchain, layoutProps} = useSignContext()
  assert(signData.state === 'sign-tx', 'Sign: invalid screen type')
  assertIsEvmBlockchain(blockchain)

  const [showGasOptions, setShowGasOptions] = useState(false)
  const onShowOptions = () => setShowGasOptions(true)
  const onHideOptions = () => setShowGasOptions(false)

  // Note that we do not validate whether a user has enough funds to execute the
  // transaction. The reason for that is the low probability of that happening,
  // a bit tricky UX to propagate it, and the user will anyway get reasonable error
  // when sending the transaction.
  const londonSchema = useLondonGasSchema()
  const simpleSchema = useSimpleGasSchema()
  const validationSchema = yup
    .object()
    .shape(
      gasProps.gasOptionsData.type === 'london' ? londonSchema : simpleSchema,
    )
  const initialValues = gasProps.gasOptionsInitialValues

  return (
    <Formik {...{initialValues, validationSchema}} onSubmit={onHideOptions}>
      {(formikProps) => (
        <>
          {gasProps.gasOptionsData.type === 'london' && (
            <AutoRefreshLondonGasSuggestions
              {...{gasProps}}
              formikProps={
                formikProps as unknown as FormikProps<LondonUiGasSchema>
              }
            />
          )}
          <SignScreen
            extraOptionsProps={{
              type: blockchain,
              data: {
                gasOptions: gasProps.formValuesToGasOptions(formikProps.values),
                fee: gasProps.getTxFee(formikProps.values),
              },
              customOptionsScreen: (
                <Paper
                  sx={{
                    mt: 1,
                    width: layoutProps.screenWidth,
                  }}
                >
                  <Box p={2}>
                    <Typography align="center" variant="h6">
                      {t('Gas settings')}
                    </Typography>
                  </Box>
                  <Divider />
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column',
                      p: 2,
                    }}
                  >
                    <Box>{gasProps.renderGasOptions(formikProps)}</Box>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => formikProps.handleSubmit()}
                      sx={{mt: 1}}
                      textTransform="none"
                      startIcon={<GoBackIcon />}
                    >
                      {t('Save gas settings')}
                    </Button>
                  </Box>
                </Paper>
              ),
              showOptions: showGasOptions,
              ...{onShowOptions, onHideOptions, onTxSignReset, onTxSignStart},
            }}
          />
        </>
      )}
    </Formik>
  )
}
