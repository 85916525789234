import type {ComponentType} from 'react'

import type {Exact} from './typeHelpers'

/**
 * `Spreadable<Props, Target>` returns `Props` or `never`.
 *
 * If it returns `Props` then props of type `Props` can be safely
 * spread into (props for) `Target`, meaning there are no extraneous properties that would
 * silently leak into `Target`.
 *
 * If it returns `never` then extraneous properties have been detected in `Props`.
 */
export type Spreadable<Props extends object, Target> =
  Target extends ComponentType<infer TargetProps>
    ? Exact<Props, Partial<TargetProps>>
    : Exact<Props, Partial<Target>>

export const spread = <P extends object, C>(
  props: P,
  _component: C, // eslint-disable-line @typescript-eslint/no-unused-vars
) => props as Spreadable<P, C>
