import {Box} from '@mui/material'
import {safeAssertUnreachable} from '@nufi/frontend-common'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import confirmImg from 'src/assets/images/onboarding/confirm.png'
import successImg from 'src/assets/images/onboarding/onboarding-success.png'
import {MetamaskOnboardingSteps, OnboardingContent} from 'src/features/login/ui'
import type {Blockchain} from 'src/types'
import {useGetBlockchainName} from 'src/utils/translations'

import {TermsAndConditionsCheckbox} from '../../TermsAndConditionsCheckbox'

type OnboardingState =
  | 'onboarding-steps'
  | 'terms-and-conditions'
  | 'create-profile'

type OnboardingProps = {
  onCancel: () => void
  renderCreateProfileScreen: (
    _SuccessScreen: typeof SuccessScreen,
  ) => React.ReactNode
  blockchain: Blockchain
  skipTermsAndConditions?: boolean
}

export function Onboarding({
  onCancel,
  renderCreateProfileScreen,
  blockchain,
  skipTermsAndConditions,
}: OnboardingProps) {
  const [screen, setScreen] = useState<OnboardingState>('onboarding-steps')

  switch (screen) {
    case 'onboarding-steps':
      return (
        <MetamaskOnboardingSteps
          onBack={onCancel}
          blockchain={blockchain}
          onLastStepFinish={() => {
            if (skipTermsAndConditions) {
              setScreen('create-profile')
            } else {
              setScreen('terms-and-conditions')
            }
          }}
        />
      )
    case 'terms-and-conditions':
      return (
        <TermsAndConditions
          onAccept={() => {
            setScreen('create-profile')
          }}
          onDeny={onCancel}
        />
      )
    case 'create-profile':
      return renderCreateProfileScreen(SuccessScreen)
    default:
      return safeAssertUnreachable(screen)
  }
}

const TermsAndConditions = ({
  onAccept,
  onDeny,
}: {
  onAccept: () => void
  onDeny: () => void
}) => {
  const {t} = useTranslation()
  const [termsChecked, setTermsChecked] = useState<boolean | null>(null)

  return (
    <OnboardingContent
      title={t('Please confirm to continue')}
      imgSrc={confirmImg}
      description={t(
        'You need to confirm our Terms & Conditions and the Privacy Policy to finish creating wallet.',
      )}
      actions={{
        primary: {
          children: t('Continue'),
          onClick: onAccept,
          disabled: !termsChecked,
        },
        secondary: {
          children: t('Cancel'),
          onClick: onDeny,
        },
      }}
    >
      <Box sx={{my: 2, width: '100%'}}>
        <TermsAndConditionsCheckbox
          value={!!termsChecked}
          onChange={() => setTermsChecked(!termsChecked)}
          error={termsChecked === false}
          helperText={t('Field must be checked.')}
        />
      </Box>
    </OnboardingContent>
  )
}

const SuccessScreen = ({
  onContinue,
  blockchain,
}: {
  onContinue: () => void
  blockchain: Blockchain
}) => {
  const {t} = useTranslation()
  const getBlockchainName = useGetBlockchainName()

  return (
    <OnboardingContent
      title={t("You're Good to Go!")}
      imgSrc={successImg}
      description={t('metamask_onboarding_app_success_description', {
        blockchain: getBlockchainName(blockchain),
      })}
      actions={{
        primary: {
          children: t('Continue'),
          onClick: onContinue,
        },
      }}
    />
  )
}
