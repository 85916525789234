import {Box} from '@mui/material'
import React from 'react'

import type {StakingBlockchain} from '../../blockchainTypes'
import {safeAssertUnreachable} from '../../utils/assertion'

import {CardanoMyStakingCard} from './cardano/MyStakingCard'
import {MyStakingListHeader} from './common/myStakingUtils'
import {FlowMyStakingCard} from './flow/MyStakingCard'
import {SolanaMyStakingCard} from './solana/MyStakingCard'
import {useStakingBlockchains} from './utils'

export default function MyStakingPage() {
  const stakingBlockchains = useStakingBlockchains()
  return (
    <Box mt={2}>
      <MyStakingListHeader />
      {stakingBlockchains.map((blockchain) => (
        <_MyStakingCard key={blockchain} {...{blockchain}} />
      ))}
    </Box>
  )
}

function _MyStakingCard({blockchain}: {blockchain: StakingBlockchain}) {
  switch (blockchain) {
    case 'solana':
      return <SolanaMyStakingCard />
    case 'cardano':
      return <CardanoMyStakingCard />
    case 'flow':
      return <FlowMyStakingCard />
    default:
      return safeAssertUnreachable(blockchain)
  }
}
