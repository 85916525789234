import {Box} from '@mui/material'
import React from 'react'

export type ShadowCopyProps = {
  copyCount: number
  content: React.ReactNode
  shadowContent: React.ReactNode
}

export const ShadowCopy = ({
  copyCount,
  content,
  shadowContent,
}: ShadowCopyProps) => (
  <Box position="relative">
    {content}
    {[...Array(copyCount)].map((_, i) => (
      <Box
        key={i}
        position="absolute"
        zIndex={-(i + 1)}
        right={-((i + 1) * 6)}
        top={-((i + 1) * 6)}
        // Visually adjusted for copyCount=2
        // change the formula if more copies are needed in the future
        sx={{filter: `brightness(${(1 / (i + 1)) * (1 / copyCount)})`}}
      >
        {shadowContent}
      </Box>
    ))}
  </Box>
)
