import {Box, Typography} from '@mui/material'
import type {Variant} from '@mui/material/styles/createTypography'
import {HttpRequestError} from '@nufi/frontend-common'
import {BlockhashExpiredError} from '@nufi/wallet-solana'
import {roundToNearestMinutes} from 'date-fns'
import React from 'react'
import {Trans, useTranslation} from 'react-i18next'

import {TransactionExplorerLink, FormattedDateTime} from '../../components'
import {assert} from '../../utils/assertion'
import type {Blockchain} from '../../wallet'
import {slotToDate} from '../../wallet/cardano'

import {useCardanoExtras} from './multiStepFormUtils/extras'

export const submitTestIds = {
  congestionIssues: 'congestion-issues',
}

type UseAdjustErrorPropsRes = {
  titleVariant?: Variant
  title: string | React.ReactNode
  onRetry?: () => unknown
  customContent?: React.ReactNode
}

type UseAdjustErrorPropsParams = {
  error: unknown
  blockchain: Blockchain
  type: 'sign' | 'submit'
  onRetry?: () => unknown
  defaultTitle?: string
}

/**
 * returns {title, onRetry} object adjusted based on the blockchain/error combination
 */
export function useAdjustErrorProps({
  error,
  blockchain,
  onRetry,
  defaultTitle,
  type,
}: UseAdjustErrorPropsParams): UseAdjustErrorPropsRes {
  const {t} = useTranslation()

  const defaults = {
    title:
      defaultTitle ||
      ((type === 'sign'
        ? t('Could not sign the transaction.')
        : 'Could not submit the transaction.') as string),
    onRetry,
  }

  switch (blockchain) {
    case 'solana': {
      if (error instanceof BlockhashExpiredError) {
        return {
          title: (
            <Typography rtl-data-test-id={submitTestIds.congestionIssues}>
              {t('solana_tx_submit_error_blockhash_expired_title')}
            </Typography>
          ),
          onRetry: undefined,
          customContent: (
            <>
              <Typography>
                {t('solana_tx_submit_error_blockhash_expired_suggestion')}
              </Typography>
            </>
          ),
          titleVariant: 'body1',
        }
      }
      break
    }
    case 'cardano': {
      if (error instanceof HttpRequestError) {
        if (error.httpStatus === 504) {
          return {
            title: (
              <Typography rtl-data-test-id={submitTestIds.congestionIssues}>
                <Trans
                  i18nKey="cardano_tx_submit_error_congested_title"
                  t={t}
                  components={{
                    bold: (
                      <Box
                        component="span"
                        style={{wordBreak: 'break-all'}}
                        fontWeight="fontWeightMedium"
                      />
                    ),
                  }}
                />
              </Typography>
            ),
            onRetry: undefined,
            customContent: <CardanoCongestedBlockchainFail />,
            titleVariant: 'body1',
          }
        } else if (error.httpStatus === 425) {
          // error 425 can be returned by blockfrost API (used by adalite-backend)
          // https://docs.blockfrost.io/#tag/Cardano-Transactions/paths/~1tx~1submit/post
          return {
            title: defaults.title,
            onRetry: defaults.onRetry,
            customContent: (
              <Typography>
                {t('cardano_tx_submit_error_full_mempool_title')}
              </Typography>
            ),
            titleVariant: 'body1',
          }
        } else if (error.httpStatus === 400) {
          return {
            title: defaults.title,
            onRetry: undefined,
            customContent: (
              <Typography>
                {error.message.includes('ValueNotConservedUTxO')
                  ? t('cardano_tx_submit_error_value_not_conserved_UTxO')
                  : t('cardano_tx_submit_error_invalid_tx_title')}
              </Typography>
            ),
            titleVariant: 'body1',
          }
        }
      }
      break
    }
    default:
      return defaults
  }

  return defaults
}

function _TransFormattedDateTime({children}: {children?: string}) {
  if (children == null) return null
  return <FormattedDateTime dateTime={new Date(children)} />
}

function CardanoCongestedBlockchainFail() {
  const cardanoExtras = useCardanoExtras()
  // transactions generated by NuFi UI are guaranteed to have ttl set
  assert(!!cardanoExtras && cardanoExtras.txPlan.ttl != null)
  const {t} = useTranslation()

  return (
    <>
      <Typography>
        <Trans
          i18nKey="cardano_tx_submit_error_congested"
          t={t}
          values={{
            slot: cardanoExtras.txPlan.ttl,
            slotToDateString: roundToNearestMinutes(
              slotToDate(
                cardanoExtras.txPlan.ttl,
                cardanoExtras.txPlan.networkInfo,
              ),
            ).toString(),
            txHash: cardanoExtras.txHash,
          }}
          components={{
            TransFormattedDateTime: <_TransFormattedDateTime />,
            bold: (
              <Box
                component="span"
                style={{wordBreak: 'break-all'}}
                fontWeight="fontWeightMedium"
              />
            ),
          }}
        />
      </Typography>
      <Box mt={1}>
        <TransactionExplorerLink
          txId={cardanoExtras.txHash}
          blockchain="cardano"
        />
      </Box>
    </>
  )
}
