/* eslint-disable @typescript-eslint/explicit-function-return-type */

import {getCatalystVotingProposals} from '@nufi/wallet-cardano'
import {useQuery} from '@tanstack/react-query'
import {useMemo} from 'react'

import {getMockServices} from '../../../../__tests__/storybook/MockServiceLocator'

export function _useGetCatalystProposals(enabled = true) {
  return useQuery({
    queryKey: ['catalystProposals'],
    queryFn: async () => {
      return await getCatalystVotingProposals()
    },
    enabled,
    staleTime: 0,
  })
}

export type UseGetCatalystProposals = typeof _useGetCatalystProposals

export const useGetCatalystProposals: UseGetCatalystProposals = (...args) => {
  const mockServices = getMockServices()
  return mockServices
    ? mockServices.cardano.useGetCatalystProposals(...args)
    : _useGetCatalystProposals(...args)
}

export function useGetCatalystProposal(
  votePlanId: string,
  proposalIndex: number,
  enabled = true,
) {
  const proposalsQuery = useGetCatalystProposals(enabled)

  const proposal = useMemo(
    () =>
      proposalsQuery.data != null
        ? proposalsQuery.data.find(
            (p) =>
              p.chain_voteplan_id === votePlanId &&
              p.chain_proposal_index === proposalIndex,
          ) ?? {
            proposal_title: 'Unknown proposal',
            chain_voteplan_id: votePlanId,
            chain_proposal_index: proposalIndex,
            chain_vote_options: {},
          }
        : undefined,
    [proposalsQuery.data],
  )

  return {
    ...proposalsQuery,
    data: proposal,
  }
}
