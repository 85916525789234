import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'

import snapEnabledImg from 'src/assets/images/onboarding/snap-enabled.png'
import {Icon} from 'src/components'
import {externalLinks} from 'src/constants'
import {OnboardingContent} from 'src/features/login/ui'
import {
  useInstallSnapContent,
  useInstallSnapSuccessContent,
  useMetamaskNotDetectedContent,
  useUpdateMetamaskContent,
  useInstallSnapMutation,
} from 'src/metamaskSnap/SnapSetup'
import type {Blockchain} from 'src/types'

type CommonProps = {onCancel: () => void}

export const MetamaskNotDetectedContent = ({onCancel}: CommonProps) => {
  const {t} = useTranslation()
  const {title, description, imageSrc, actionIcon, actionLabel} =
    useMetamaskNotDetectedContent()

  return (
    <OnboardingContent
      title={title}
      description={description}
      imgSrc={imageSrc}
      imageSpacing={false}
      actions={{
        primary: {
          onClick: () => {
            window.open(externalLinks.metamaskInstall, '_blank')
          },
          children: actionLabel,
          startIcon: actionIcon,
        },
        secondary: {
          onClick: onCancel,
          children: t('Cancel'),
        },
      }}
    />
  )
}

export const UpdateMetamaskContent = ({onCancel}: CommonProps) => {
  const {t} = useTranslation()
  const {title, description, imageSrc, actionIcon, actionLabel} =
    useUpdateMetamaskContent()

  return (
    <OnboardingContent
      title={title}
      description={description}
      imgSrc={imageSrc}
      imageSpacing={false}
      actions={{
        primary: {
          onClick: () => {
            window.open(externalLinks.metamaskUpdate, '_blank')
          },
          children: actionLabel,
          startIcon: actionIcon,
        },
        secondary: {
          onClick: onCancel,
          children: t('Cancel'),
        },
      }}
    />
  )
}

export const InstallSnapContent = ({
  blockchain,
  onCancel,
}: {blockchain: Blockchain} & CommonProps) => {
  const {t} = useTranslation()
  const {title, description, imageSrc, actionIcon, actionLabel} =
    useInstallSnapContent(blockchain)

  const installSnapMutation = useInstallSnapMutation()

  useEffect(() => {
    installSnapMutation.mutateAsyncSilent()
  }, [])

  return (
    <OnboardingContent
      title={title}
      description={description}
      imgSrc={imageSrc}
      actions={{
        primary: {
          onClick: () => {
            installSnapMutation.reset()
            installSnapMutation.mutateAsyncSilent()
          },
          children: actionLabel,
          startIcon: actionIcon,
        },
        secondary: {
          onClick: onCancel,
          children: t('Cancel'),
        },
      }}
    />
  )
}

export const InstallSnapContentSuccess = ({
  onContinue,
  blockchain,
}: {
  onContinue: () => void
  blockchain: Blockchain
}) => {
  const {title, description} = useInstallSnapSuccessContent(blockchain)
  const {t} = useTranslation()

  return (
    <OnboardingContent
      title={title}
      description={description}
      imgSrc={snapEnabledImg}
      actions={{
        primary: {
          onClick: onContinue,
          children: t('Let’s get started'),
          startIcon: <Icon type="metamaskIcon" />,
        },
      }}
    />
  )
}
