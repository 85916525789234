import type {IdentitySecretHash, MnemonicHash} from '../appStorage/types'
import config from '../config'

import {tracking} from './sdk'
import type {
  TrackingProfileAction,
  TxTrackingArgs,
  UserProperties,
} from './types'

export const initializeTracking = (options?: {isWidget?: boolean}): void => {
  if (config.GAMeasurementId) {
    tracking.initialize(
      config.GAMeasurementId,
      options?.isWidget
        ? {
            // needed for cross-domain tracking
            // https://developers.google.com/search/blog/2020/01/get-ready-for-new-samesitenone-secure
            gaOptions: {cookieFlags: 'samesite=none;secure'},
          }
        : undefined,
    )
  }
}

export const trackProfileAction = (action: TrackingProfileAction): void => {
  tracking.event(action)
}

export const setUserProperties = (
  properties: Partial<UserProperties>,
): void => {
  tracking.gtag('set', 'user_properties', properties)
}

export const setUserId = (userId: MnemonicHash | IdentitySecretHash): void => {
  tracking.gtag('set', 'user_id', userId)
}

export const trackTransactionSubmission = (args: TxTrackingArgs): void => {
  tracking.event('transaction_submission', {
    ...args,
  })
}

export const setSessionSource = (sessionSource: string): void => {
  tracking.gtag('set', 'campaign_source', sessionSource)
}
