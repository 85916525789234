/* eslint-disable @typescript-eslint/explicit-function-return-type */

import {cardanoAccountNameOptions} from '@nufi/wallet-cardano'
import type {AccountNameOptions} from '@nufi/wallet-common'
import {generateAccountName} from '@nufi/wallet-common'
import {evmAccountNameOptions} from '@nufi/wallet-evm'
import {flowAccountNameOptions} from '@nufi/wallet-flow'
import {solanaAccountNameOptions} from '@nufi/wallet-solana'
import {useMemo} from 'react'

import {blockchainToWalletKind} from 'src/wallet/walletKind'

import type {
  AccountStoredData,
  __UniversalAccountsStoredData,
} from '../../store/wallet'
import {
  useCardanoStore,
  useSolanaStore,
  useFlowStore,
  useEvmStore,
} from '../../store/wallet'
import {getVisibleAccounts} from '../AccountsStoreManager'
import {evm} from '../evm/commonEvmManagers'
import type {EvmAccountDerivationParams} from '../evm/cryptoProviders/types'
import type {Blockchain, AccountInfo, AccountName} from '../types'

import {sortAccounts} from './common'
import {getWalletManagers} from './walletManagerUtils'

// raw accounts data from `zustand` store
function useRawAccounts<D extends AccountStoredData>(
  blockchain: Blockchain,
): D[] {
  const walletKind = blockchainToWalletKind(blockchain)

  const accountsQueries = {
    cardano: useCardanoStore(),
    solana: useSolanaStore(),
    flow: useFlowStore(),
    evm: useEvmStore(),
  }

  return useMemo(() => {
    const accounts = accountsQueries[walletKind].accounts

    // This can happen e.g. if before a profile is initialized, we render
    // some visual component that eventually call some hook that call `useRawAccounts` hook AND
    // a new blockchain was just meanwhile added.
    // Currently such issue occurs when  "<AssetIcon />" is rendered in the header of dapp connector
    // before the profile is initialized.
    // Even if the above example will no longer hold true, similar cases can occur in general.
    if (accounts == null) return []

    const sortedAccounts = sortAccounts<AccountStoredData>(accounts) as D[]
    const visibleAccounts = getVisibleAccounts(sortedAccounts)

    // Accounts of evm blockchains need to also be filtered based on the available paths.
    // This was previously done directly in the `accountsQueries` object above but returning
    // a new object instance from the query caused the `useMemo` callback to be executed every time.
    if (walletKind === 'evm') {
      return visibleAccounts.filter((a) => {
        const supportedDerivationPathTypes =
          evm.wallet.getSupportedDerivationPathTypes(a.cryptoProviderType)
        return supportedDerivationPathTypes.includes(
          (a.derivationParams as EvmAccountDerivationParams).type,
        )
      })
    }

    return visibleAccounts
  }, [accountsQueries[walletKind].accounts])
}

/**
 * @returns offline account info for all **stored** accounts for a blockchain. For EVM
 * blockchains this returns data even if the blockchain is not enabled.
 */
export function useOfflineAccountsInfo<D extends AccountStoredData>(
  blockchain: Blockchain,
  _accounts?: D[],
) {
  const storedAccounts = useRawAccounts(blockchain)
  const accounts = (_accounts ||
    storedAccounts) as __UniversalAccountsStoredData

  return useMemo(
    () => getWalletManagers(blockchain).getAccountOfflineInfos(accounts),
    [accounts],
  )
}

export function useGenerateAccountName(blockchain: Blockchain) {
  const rawAccounts = useRawAccounts(blockchain)
  return (
    newAccountInfo: AccountStoredData | AccountInfo,
    otherNewAccountNames?: {
      name: AccountName
    }[],
  ) => {
    const accountOptions = (() => {
      const walletKind = blockchainToWalletKind(blockchain)
      return {
        cardano: cardanoAccountNameOptions,
        solana: solanaAccountNameOptions,
        flow: flowAccountNameOptions,
        evm: evmAccountNameOptions,
      }[walletKind]
    })() as AccountNameOptions<typeof newAccountInfo>

    return generateAccountName(
      [...rawAccounts, ...(otherNewAccountNames || [])],
      newAccountInfo,
      accountOptions,
    )
  }
}
