import type {
  EncryptionKey,
  KeyValueData,
  StoreManagerContent,
} from '@nufi/storage'
import {decrypt} from '@nufi/storage'
import type {SerializedProfile} from 'common'

import type {ProfileDataEncryptionKey} from './types'

const PROFILE_KEY_DERIVATION_SALT = 'nufi-profile-key'

async function _deriveKey(input: Buffer, salt: string): Promise<CryptoKey> {
  const masterKey = await global.crypto.subtle.importKey(
    'raw',
    input,
    {name: 'PBKDF2'},
    false,
    ['deriveBits', 'deriveKey'],
  )

  return await global.crypto.subtle.deriveKey(
    {
      name: 'PBKDF2',
      salt: Buffer.from(salt, 'utf-8'),
      iterations: 10000,
      hash: 'SHA-256',
    },
    masterKey,
    {name: 'AES-GCM', length: 256},
    false,
    ['encrypt', 'decrypt'],
  )
}

export async function deriveKey(
  buffer: Buffer,
): Promise<ProfileDataEncryptionKey> {
  return (await _deriveKey(
    buffer,
    PROFILE_KEY_DERIVATION_SALT,
  )) as ProfileDataEncryptionKey
}

export const isStringifiedObject = (value: string) => {
  try {
    const json = JSON.parse(value)
    if (typeof json === 'object' && json !== null) {
      return true
    }
  } catch {
    return false
  }

  return false
}

export const decryptSerializedProfile = async (
  data: SerializedProfile,
  profileDataEncryptionKey: EncryptionKey,
): Promise<StoreManagerContent<KeyValueData>> => {
  const {header, data: encryptedProfileData} = JSON.parse(data)
  const profileData = await decrypt({
    value: encryptedProfileData,
    password: profileDataEncryptionKey,
  })
  return {header, data: profileData}
}
