import {Box, Grid, Typography} from '@mui/material'
import {LATTICE_MANAGER_URL, forgetGridPlusPairing} from '@nufi/wallet-common'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {
  Button,
  FooterLayout,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalLayout,
} from 'src/components'

type ResetGridPlusPairingPromptProps = {
  open: boolean
  onClose: () => void
}
const ResetGridPlusPairingPrompt = ({
  open,
  onClose,
}: ResetGridPlusPairingPromptProps) => {
  const {t} = useTranslation()

  return (
    <Modal open={open} onClose={onClose} variant="centered">
      <ModalLayout
        header={
          <ModalHeader onClose={onClose} hasDivider>
            <Typography variant="h6">{t('Reset GridPlus Pairing?')}</Typography>
          </ModalHeader>
        }
        body={
          <Box maxWidth={560} p={3}>
            <Typography color="textSecondary">
              {t(
                'This will ensure a new pairing with your GridPlus hardware wallet is created next time you interact with it',
              )}
            </Typography>
          </Box>
        }
        footer={
          <ModalFooter hasDivider>
            <FooterLayout
              leftBtnConf={{
                onClick: onClose,
                children: t('Cancel'),
              }}
              rightBtnConf={{
                onClick: () => {
                  forgetGridPlusPairing()
                  onClose()
                },
                children: t('Reset'),
              }}
            />
          </ModalFooter>
        }
      />
    </Modal>
  )
}

export const GridPlusTroubleshooting = () => {
  const {t} = useTranslation()
  const [showPairingResetPrompt, setShowPairingResetPrompt] =
    React.useState(false)

  return (
    <>
      <ResetGridPlusPairingPrompt
        open={showPairingResetPrompt}
        onClose={() => setShowPairingResetPrompt(false)}
      />
      <Grid container alignItems="flex-end" spacing={2}>
        <Grid item xs={3}>
          <Button
            fullWidth
            textTransform="none"
            variant="contained"
            type="submit"
            color="secondary"
            onClick={() => setShowPairingResetPrompt(true)}
          >
            {t('Reset pairing')}
          </Button>
        </Grid>
        <Grid item xs={5}>
          <Button
            fullWidth
            textTransform="none"
            variant="contained"
            type="submit"
            color="secondary"
            onClick={() => window.open(LATTICE_MANAGER_URL)}
          >
            {t('Open Lattice Manager')}
          </Button>
        </Grid>
      </Grid>
    </>
  )
}
