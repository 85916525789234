import {
  BarChart as StakeIcon,
  CallReceived as RewardReceived,
  ArrowDownward as Withdrawal,
  Close as Deactivation,
  VerticalSplit as SplitStakeAccount,
  Done as Initialize,
} from '@mui/icons-material'
import {Typography} from '@mui/material'
import {useTheme} from '@mui/material/styles'
import type {
  SolanaDeactivateInstruction,
  SolanaDelegateInstruction,
  SolanaInitializeInstruction,
  SolanaSplitInstruction,
  SolanaStakeHistoryRewardEntry,
  SolanaStakeHistoryTxEntry,
  SolanaWithdrawInstruction,
} from '@nufi/wallet-solana'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {
  FormattedAsset,
  FormattedDateTime,
  IconCol,
  SolanaStakeAccountLink,
  TransactionExplorerLink,
  ValidatorExplorerLink,
} from '../../../../components'
import {StakeHistoryListItemLayout} from '../../common/history/StakeHistoryLayout'
import {
  FormattedAssetEffect,
  FormattedDateTimeWithEpochNo,
  NoValue,
  StakeHistoryInformationColumn,
  StakeHistoryTitleWithAccount,
} from '../../common/history/StakeHistoryUtils'

export function RewardHistoryEntryRow({
  amount,
  accountName,
  timeIssued,
  epoch,
}: SolanaStakeHistoryRewardEntry) {
  const {t} = useTranslation()
  const theme = useTheme()
  return (
    <StakeHistoryListItemLayout
      typeCol={
        <IconCol
          Icon={<RewardReceived />}
          title={
            <StakeHistoryTitleWithAccount
              title={t('Received reward')}
              accountName={accountName}
            />
          }
          description={
            <FormattedDateTimeWithEpochNo
              dateTime={timeIssued}
              epochNo={epoch}
            />
          }
        />
      }
      amountCol={
        <FormattedAssetEffect
          blockchain={'solana'}
          color={theme.palette.info.light}
          amount={amount}
        />
      }
      informationCol={<NoValue />}
      explorerCol={<NoValue />}
    />
  )
}

export function DelegationHistoryEntryRow({
  timeIssued,
  accountName,
  signature,
  voteAccount,
}: SolanaStakeHistoryTxEntry<SolanaDelegateInstruction>) {
  const {t} = useTranslation()
  return (
    <StakeHistoryListItemLayout
      typeCol={
        <IconCol
          Icon={<StakeIcon />}
          title={
            <StakeHistoryTitleWithAccount
              title={t('Delegation')}
              accountName={accountName}
            />
          }
          description={<FormattedDateTime dateTime={timeIssued} />}
        />
      }
      amountCol={<NoValue />}
      informationCol={
        voteAccount ? (
          <StakeHistoryInformationColumn>
            <Typography variant="body2" color="textSecondary">
              {t('To new')}&nbsp;
            </Typography>
            <ValidatorExplorerLink
              blockchain="solana"
              validatorId={voteAccount}
              validatorName={t('Validator')}
            />
          </StakeHistoryInformationColumn>
        ) : (
          <NoValue />
        )
      }
      explorerCol={
        <TransactionExplorerLink blockchain="solana" txId={signature} />
      }
    />
  )
}

export function WithdrawalHistoryEntryRow({
  timeIssued,
  signature,
  accountName,
  amount,
}: SolanaStakeHistoryTxEntry<SolanaWithdrawInstruction>) {
  const {t} = useTranslation()
  return (
    <StakeHistoryListItemLayout
      typeCol={
        <IconCol
          Icon={<Withdrawal />}
          title={
            <StakeHistoryTitleWithAccount
              title={t('Reward withdrawal')}
              accountName={accountName}
            />
          }
          description={<FormattedDateTime dateTime={timeIssued} />}
        />
      }
      amountCol={
        amount ? (
          <FormattedAssetEffect blockchain={'solana'} amount={amount} />
        ) : (
          <NoValue />
        )
      }
      informationCol={<NoValue />}
      explorerCol={
        <TransactionExplorerLink blockchain="solana" txId={signature} />
      }
    />
  )
}

export function DeactivationHistoryEntryRow({
  timeIssued,
  signature,
  accountName,
  stakeAccount,
}: SolanaStakeHistoryTxEntry<SolanaDeactivateInstruction>) {
  const {t} = useTranslation()
  return (
    <StakeHistoryListItemLayout
      typeCol={
        <IconCol
          Icon={<Deactivation />}
          title={
            <StakeHistoryTitleWithAccount
              title={t('Deactivation')}
              accountName={accountName}
            />
          }
          description={<FormattedDateTime dateTime={timeIssued} />}
        />
      }
      amountCol={<NoValue />}
      informationCol={
        stakeAccount ? (
          <StakeHistoryInformationColumn>
            <Typography variant="body2" color="textSecondary">
              {t('Deactivated')}&nbsp;
            </Typography>
            <SolanaStakeAccountLink stakeAccount={stakeAccount} />
          </StakeHistoryInformationColumn>
        ) : (
          <NoValue />
        )
      }
      explorerCol={
        <TransactionExplorerLink blockchain="solana" txId={signature} />
      }
    />
  )
}

export function SplitHistoryEntryRow({
  timeIssued,
  signature,
  accountName,
  amount,
  newStakeAccount,
}: SolanaStakeHistoryTxEntry<SolanaSplitInstruction>) {
  const {t} = useTranslation()
  return (
    <StakeHistoryListItemLayout
      typeCol={
        <IconCol
          Icon={<SplitStakeAccount />}
          title={
            <StakeHistoryTitleWithAccount
              title={t('Split stake account')}
              accountName={accountName}
            />
          }
          description={<FormattedDateTime dateTime={timeIssued} />}
        />
      }
      amountCol={<NoValue />}
      informationCol={
        amount && newStakeAccount ? (
          <StakeHistoryInformationColumn>
            <Typography variant="body2" color="textSecondary">
              {t('Created new')}&nbsp;
            </Typography>
            <SolanaStakeAccountLink stakeAccount={newStakeAccount} />
            <Typography variant="body2" color="textSecondary">
              {t('with')}&nbsp;
            </Typography>
            <FormattedAsset
              blockchain="solana"
              amount={amount}
              isSensitiveInformation
            />
          </StakeHistoryInformationColumn>
        ) : (
          <NoValue />
        )
      }
      explorerCol={
        <TransactionExplorerLink blockchain="solana" txId={signature} />
      }
    />
  )
}

export function InitializeHistoryEntryRow({
  timeIssued,
  signature,
  accountName,
  newStakeAccount,
}: SolanaStakeHistoryTxEntry<SolanaInitializeInstruction>) {
  const {t} = useTranslation()
  return (
    <StakeHistoryListItemLayout
      typeCol={
        <IconCol
          Icon={<Initialize />}
          title={
            <StakeHistoryTitleWithAccount
              title={t('Stake account initialization')}
              accountName={accountName}
            />
          }
          description={<FormattedDateTime dateTime={timeIssued} />}
        />
      }
      amountCol={<NoValue />}
      informationCol={
        newStakeAccount ? (
          <StakeHistoryInformationColumn>
            <Typography variant="body2" color="textSecondary">
              {t('Created new')}&nbsp;
            </Typography>
            <SolanaStakeAccountLink stakeAccount={newStakeAccount} />
          </StakeHistoryInformationColumn>
        ) : (
          <NoValue />
        )
      }
      explorerCol={
        <TransactionExplorerLink blockchain="solana" txId={signature} />
      }
    />
  )
}
