import type {
  FlowAddress,
  FlowSignedTransaction,
  FlowTransactionId,
  FlowTxPlan,
  Nanoflow,
} from '@nufi/wallet-flow'
import {flowToNanoflow} from '@nufi/wallet-flow'

import {useTrackTxSubmission} from 'src/tracking'

import {getBlockchainDecimals} from '../../../constants'
import {assert} from '../../../utils/assertion'
import {
  getMaxSendableFlowAmountImperative,
  useGetMaxSendableFlowAmount,
  useSignTx,
  useSubmitTx,
} from '../../../wallet/flow'
import {useCustomMetadata} from '../CustomMetadataContainer'
import type {
  ExchangeDeps,
  WithAmountFieldProperties,
  WithSignAndSubmitHandlers,
} from '../screens/common'
import {
  WithNoTokensGetAssetTokenData,
  WithSummaryTxFee,
} from '../screens/common'
import {useCommonAmountValidation} from '../screens/details/common'

type CustomMetadata = {
  fee: Nanoflow
}

const WithFlowAmountFieldProperties: WithAmountFieldProperties = ({
  fromAccountInfo,
  values,
  fromToken,
  children,
}) => {
  const {setNonReactiveMetadata} = useCustomMetadata<CustomMetadata>()
  assert(fromAccountInfo == null || fromAccountInfo?.blockchain === 'flow')

  const maxSendableAmountQuery = useGetMaxSendableFlowAmount(
    fromAccountInfo
      ? {
          accountInfo: fromAccountInfo,
          txType: 'transferFlow',
        }
      : undefined,
    !!fromAccountInfo,
  )
  const maxSendableAmountQueryCommonProps = {
    isLoading: maxSendableAmountQuery.isLoading,
    error: maxSendableAmountQuery.error,
  }

  const maxDecimals = getBlockchainDecimals('flow')

  const validate = useCommonAmountValidation(
    values,
    fromAccountInfo,
    fromToken,
    async () => {
      if (!fromAccountInfo) return null
      try {
        const {fee, maxAmount} = await getMaxSendableFlowAmountImperative(
          fromAccountInfo,
          'transferFlow',
        )
        setNonReactiveMetadata('flow', {fee})
        return {fee, maxNativeAmount: maxAmount, maxDecimals}
      } catch (err) {
        return null
      }
    },
  )

  const maxAmountValue = maxSendableAmountQuery.data?.maxAmount
  return children({
    validate,
    data: {
      maxAmount: {
        data:
          maxAmountValue != null
            ? {value: maxAmountValue, type: 'native'}
            : undefined,
        ...maxSendableAmountQueryCommonProps,
      },
      fee: {
        data: maxSendableAmountQuery.data?.fee,
        ...maxSendableAmountQueryCommonProps,
      },
    },
  })
}

const WithFlowSignAndSubmitHandlers: WithSignAndSubmitHandlers<
  FlowSignedTransaction,
  FlowTransactionId
> = ({amount, toAddress, fromAccountInfo, children}) => {
  const sign = useSignTx()
  const submit = useSubmitTx()

  useTrackTxSubmission(submit, {
    blockchain: 'flow',
    provider: fromAccountInfo.cryptoProviderType,
    type: 'transfer_coin',
    value: flowToNanoflow(amount).toNumber(),
  })

  const onTxSign = async () => {
    assert(fromAccountInfo?.blockchain === 'flow')
    const txPlan: FlowTxPlan = {
      type: 'transferFlow',
      args: {
        amount: flowToNanoflow(amount),
        address: toAddress as FlowAddress,
      },
    }
    return await sign.mutateAsyncSilent({
      txPlan,
      accountInfo: fromAccountInfo,
    })
  }

  const onTxSubmit = async (signedTx: FlowSignedTransaction) =>
    await submit.mutateAsyncSilent({accountId: fromAccountInfo.id, signedTx})

  return children({
    signProps: {...sign, mutateAsyncSilent: onTxSign},
    submitProps: {...submit, mutateAsyncSilent: onTxSubmit},
  })
}

export const flowExchangeDeps: ExchangeDeps = {
  WithSignAndSubmitHandlers: WithFlowSignAndSubmitHandlers,
  WithAmountFieldProperties: WithFlowAmountFieldProperties,
  WithSummaryTxFee,
  WithGetAssetTokenData: WithNoTokensGetAssetTokenData,
}
