import {
  Box,
  Divider,
  Stack,
  Typography,
  Alert as MuiAlert,
  AlertTitle,
  styled,
  Link,
} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import type {ScanDomainWarning} from 'src/dappConnector/scanDomain/blowfish'
import {useDappConnectorStore} from 'src/store/dappConnector'

import {Alert, FooterLayout, Modal} from '../../../components'

type SettingsProps = {
  open: boolean
  onReject: () => void
  onContinue: () => void
  originWarnings: ScanDomainWarning[]
}

export function MaliciousSiteWarnings({
  open,
  onContinue,
  onReject,
  originWarnings,
}: SettingsProps) {
  const {t} = useTranslation()
  const {origin} = useDappConnectorStore()

  return (
    <Modal
      open={open}
      variant="full-width"
      onClose={onContinue}
      sx={{bg: ({palette}) => palette.background.default}}
    >
      <Divider />
      <Box p={2} height="100%">
        <Stack spacing={2} px={2} height="100%">
          <FraudDetectedAlert variant="outlined" severity="error">
            <AlertTitle sx={{fontSize: 24}}>{t('Potential fraud!')}</AlertTitle>
            <div>
              <Typography>
                {t('The following warnings have been identified for the')}
              </Typography>
              <div>
                <Link sx={{cursor: 'pointer'}} variant="body2">
                  {origin}
                </Link>
              </div>
            </div>
          </FraudDetectedAlert>
          <Typography fontWeight="bold">
            {t(
              'Please review these warnings carefully before proceeding with any transactions or interactions:',
            )}
          </Typography>
          <Stack spacing={2}>
            {originWarnings.map(({message, severity, kind}) => (
              <Alert
                key={kind}
                severity={severity === 'CRITICAL' ? 'error' : 'warning'}
                children={message}
              />
            ))}
          </Stack>
          <Box sx={{mt: 'auto!important'}}>
            <FooterLayout
              leftBtnConf={{
                onClick: onReject,
                children: t('Reject'),
              }}
              rightBtnConf={{
                variant: 'outlined',
                color: 'error',
                children: t('Continue anyway'),
                onClick: onContinue,
              }}
            />
          </Box>
        </Stack>
      </Box>
    </Modal>
  )
}

const FraudDetectedAlert = styled(MuiAlert)({
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  '& .MuiAlert-icon': {
    fontSize: 80,
  },
})
