import {
  Box,
  Divider,
  Grid,
  TextField,
  Typography,
  Link as MuiLink,
} from '@mui/material'
import type {CoreTypes} from '@walletconnect/types'
import * as wcUtils from '@walletconnect/utils'
import type {FormikHelpers, FormikProps, FormikValues} from 'formik'
import {Field, Formik} from 'formik'
import React, {useEffect, useRef, useState} from 'react'
import {Trans, useTranslation} from 'react-i18next'

import {Alert, BlockchainIcon, Button} from '../components'
import {externalLinks} from '../constants'
import {useGetBlockchainName} from '../utils/translations'

import {walletConnectBlockchains} from './constants'
import {NotificationPermissionSection} from './permissionSections'
import {isEnabledWalletConnectBlockchain, openConnectorWindow} from './utils'

type Schema = {
  uri: string
}

export const Dashboard = () => {
  const onSubmit = (
    values: FormikValues,
    {resetForm}: FormikHelpers<Schema>,
  ) => {
    openConnectorWindow(values.uri)
    resetForm(values)
  }
  return (
    <>
      <Box my={2}>
        <Divider />
      </Box>
      <Grid container columnSpacing={4} justifyContent={'center'}>
        <Grid item xs={5.5}>
          <Formik initialValues={{uri: ''}} onSubmit={onSubmit} validateOnBlur>
            {(formikProps) => (
              <form onSubmit={formikProps.submitForm}>
                <DashboardForm formikProps={formikProps} />
              </form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </>
  )
}

const DashboardForm = ({formikProps}: {formikProps: FormikProps<Schema>}) => {
  const {t} = useTranslation()
  const getBlockchainName = useGetBlockchainName()

  const initialNotificationPermission = useRef(
    'Notification' in window ? Notification.permission : null,
  )
  const [notificationsPermission, setNotificationsPermission] = useState(
    initialNotificationPermission.current,
  )
  const validateUri = (uri: string) => {
    if (!uri || typeof uri !== 'string' || !wcUtils.isValidUrl(uri)) {
      return t('Invalid WalletConnect URI')
    }

    const {version} = wcUtils.parseUri(uri)

    if (version !== 2) {
      return t('Invalid WalletConnect URI')
    }
    return null
  }

  const [dappMetadata, setDappMetadata] = useState<CoreTypes.Metadata | null>(
    null,
  )

  useEffect(() => {
    const fn = (e: MessageEvent) => {
      if (e.data.type === 'walletConnectSessionApproved') {
        setDappMetadata(e.data.data)
      }
    }
    window.addEventListener('message', fn)
    return () => window.removeEventListener('message', fn)
  }, [])

  return (
    <>
      <Grid container spacing={2} direction="column">
        <Grid item>
          <Typography variant="h4">{t('WalletConnect')}</Typography>
        </Grid>
        <Grid item>
          <Alert severity="info">
            <Trans
              i18nKey="wallet_connect_description"
              t={t}
              components={{
                Link: (
                  <MuiLink
                    color="primary"
                    target="_blank"
                    href={externalLinks.nufiWalletConnectInfo}
                  />
                ),
              }}
            />
          </Alert>
        </Grid>
        <Grid item>
          <Typography variant="h6" mb={0.5}>
            {t('Paste the WalletConnect URI')}
          </Typography>
        </Grid>
        <Grid item>
          <Field name="uri" validate={validateUri}>
            {() => (
              <TextField
                hiddenLabel
                value={formikProps.values.uri}
                onChange={(e) => {
                  formikProps.handleChange('uri')(e)
                  setDappMetadata(null)
                }}
                placeholder={'wc:...'}
                error={!!formikProps.errors.uri}
                helperText={formikProps.errors.uri}
                variant="outlined"
                fullWidth
                onBlur={(e) => {
                  // without handleBlur the "touched" fields are not validated
                  const onBlur = formikProps.handleBlur('uri') as (
                    e: unknown,
                  ) => void
                  onBlur(e)
                }}
                autoComplete="off"
              />
            )}
          </Field>
        </Grid>
        <Grid item>
          <Button
            fullWidth
            textTransform="none"
            variant="contained"
            color="primary"
            type="submit"
            disabled={formikProps.values.uri !== '' && !formikProps.isValid}
          >
            {t('Connect')}
          </Button>
        </Grid>
        {dappMetadata && (
          <Grid item>
            <Alert
              text={t('wallet_connect_redirect_dapp', {
                dapp: dappMetadata.name,
              })}
              severity="success"
            />
          </Grid>
        )}

        <Grid item>
          <Grid container spacing={4}>
            <Grid item>
              <NotificationPermissionSection
                initialNotificationPermission={
                  initialNotificationPermission.current
                }
                {...{
                  notificationsPermission,
                  setNotificationsPermission,
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container spacing={4}>
            <Grid item>
              <Typography variant="h6">{t('Supported blockchains')}</Typography>
              {walletConnectBlockchains
                .filter((blockchain) =>
                  isEnabledWalletConnectBlockchain(blockchain),
                )
                .map((blockchain) => {
                  return (
                    <Grid item display="flex" key={blockchain} mt={1.5}>
                      <BlockchainIcon blockchain={blockchain} exactSize={24} />
                      <Typography variant="body1" pl={1}>
                        {getBlockchainName(blockchain)}
                      </Typography>
                    </Grid>
                  )
                })}
              <Typography color="textSecondary" mt={1}>
                {t('More blockchains are coming soon.')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
