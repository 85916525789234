import React from 'react'
import {useTranslation} from 'react-i18next'

import {FullScreenLoading} from '../../components'
import {colors} from '../../theme/palette'

export const ConnectingScreen = () => {
  const {t} = useTranslation()
  return (
    <FullScreenLoading
      description={t('wallet_connect_waiting_for_dapp')}
      backdropSx={{
        'background-color': colors.background.default,
        color: colors.primary.main,
      }}
    />
  )
}
