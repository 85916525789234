import React from 'react'
import type {RouteProps} from 'react-router-dom'
import {Route, Redirect} from 'react-router-dom'

import {routeTo} from '../router'
import {useAuthStore} from '../store/auth'

export const LoggedInRoute = ({children, ...rest}: RouteProps) => {
  const {status} = useAuthStore((state) => state.authState)
  return (
    <Route
      {...rest}
      render={() => {
        if (status === 'initializing') {
          // Note: Loading already rendered from outside
          return null
        }
        if (status === 'logged_out') {
          return <Redirect to={routeTo.auth} />
        }
        return <>{children}</>
      }}
    />
  )
}

export const LoggedOutRoute = ({children, ...rest}: RouteProps) => {
  const {status} = useAuthStore((state) => state.authState)
  return (
    <Route
      {...rest}
      render={() => {
        if (status === 'initializing') {
          // Note: Loading already rendered from outside
          return null
        }
        if (status === 'logged_in') {
          // TODO: change to dashboard once we have it
          return <Redirect to={routeTo.portfolio.index} />
        }
        return <>{children}</>
      }}
    />
  )
}
