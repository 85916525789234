import {Grid} from '@mui/material'
import type {FlowDelegationInfo} from '@nufi/wallet-flow'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {FormattedAsset} from '../../../../../components'
import ValidatorInfoItem from '../../../common/actionModals/StakeModalValidatorInfo'
import {FormattedValidatorType} from '../../utils'

import type {ValidatorOption} from './types'

type FormattedValidatorInfoProps = {
  validator: ValidatorOption
  delegation: FlowDelegationInfo | undefined
}

export function FormattedValidatorInfo({
  validator,
  delegation,
}: FormattedValidatorInfoProps) {
  const {t} = useTranslation()

  return (
    <Grid container direction="row">
      <Grid container>
        <ValidatorInfoItem
          itemName={t('Name')}
          itemValue={validator.name || t('Unknown name')}
        />
        <ValidatorInfoItem
          itemName={t('Type')}
          itemValue={<FormattedValidatorType nodeRole={validator.role} />}
        />
        <ValidatorInfoItem itemName={t('NodeId')} itemValue={validator.id} />
        {delegation && (
          <ValidatorInfoItem
            itemName={t('In staking')}
            itemValue={
              <FormattedAsset
                amount={delegation.tokensCommitted.plus(
                  delegation.tokensStaked,
                )}
                blockchain="flow"
                isSensitiveInformation={false}
              />
            }
          />
        )}
      </Grid>
    </Grid>
  )
}
