import {ethers} from 'ethers'

import {_AlchemyProvider} from '../alchemy'
import type {UntypedEvmBlockchain} from '../evmBlockchain'
import {fetchErc20TokenList} from '../tokenList'
import type {EvmChainId, EvmConnection} from '../types'

export class EvmRpcProvider extends ethers.providers.JsonRpcBatchProvider {
  constructor(rpcUrl: string, chainId: number) {
    super(
      {
        url: rpcUrl,
        allowGzip: true,
      },
      chainId,
    )
  }

  // A ChainId call was being made before each EVM RPC request. The roundtrip for EVM requests was ~500ms so each request basically took a second. This is mainly noticeable during EVM cross chain account discovery (which will be added later).
  // We don't really support "changing backend" for which this feature was mainly implemented in ethers.
  // More info can be found here - https://github.com/ethers-io/ethers.js/issues/901.
  override detectNetwork = async (): Promise<ethers.providers.Network> => {
    if (this._network) {
      return Promise.resolve(this._network)
    }
    return super.getNetwork()
  }
}

export const constructEvmConnection = <
  TBlockchain extends UntypedEvmBlockchain,
>(
  rpcUrl: string,
  chainId: EvmChainId,
  alchemyApiUrl?: string,
): EvmConnection<TBlockchain> => {
  const rpc = new EvmRpcProvider(rpcUrl, chainId)
  if (alchemyApiUrl) {
    return {
      rpc,
      fetchErc20TokenList,
      alchemyApi: new _AlchemyProvider<TBlockchain>(alchemyApiUrl, chainId),
    }
  }
  return {
    rpc,
    fetchErc20TokenList,
  }
}
