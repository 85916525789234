import type {
  Currency,
  HistoricalConversionRatesGranularity,
} from 'src/features/conversionRates/domain'

import type {Blockchain, TokenId} from '../../../types'

const P = 'conversionRates'

export const conversionRatesQueryKeys = {
  index: [P],
  coins: [P, 'coins'],
  tokens: (blockchain: Blockchain) => [P, 'tokens', blockchain],
  all: [P, 'all'],
  tokensById: (blockchain: Blockchain, tokenIds: TokenId[]) => [
    P,
    blockchain,
    tokenIds.join('-'),
  ],
  historicalRates: (
    blockchain: Blockchain,
    currency: Currency,
    granularity: HistoricalConversionRatesGranularity,
  ) => [
    ...conversionRatesQueryKeys.index,
    'historicalConversionRates',
    blockchain,
    currency,
    granularity,
  ],
} as const
