import {Box, Typography} from '@mui/material'
import React from 'react'
import {Trans, useTranslation} from 'react-i18next'

import {useDappConnectorStore} from 'src/store/dappConnector'

import {Alert, Button} from '../../../../../components'
import {assert} from '../../../../../utils/assertion'
import type {EvmBlockchain, EvmChainId} from '../../../../../wallet/evm/types'
import {
  ActionButtons,
  OriginMessage,
  RejectButton,
} from '../../../../components'
import {OUTER_CONTENT_WIDTH} from '../../../../constants'

import {allowedChainIds, NETWORKS_CONF} from './common'

export type ChangeEvmChainProps = {
  onReject: () => unknown
  onAccept: (blockchain: EvmBlockchain) => unknown
  currentChainId: string
  proposedChainId: string
}

export function ChangeEvmChain({
  currentChainId,
  proposedChainId,
  ...restProps
}: ChangeEvmChainProps) {
  const {t} = useTranslation()
  const {origin, favIconUrl} = useDappConnectorStore()
  const chainsConfigs = (() => {
    if (
      !allowedChainIds.includes(parseInt(proposedChainId, 16) as EvmChainId)
    ) {
      return null
    }

    const currentId = parseInt(currentChainId, 16) as keyof typeof NETWORKS_CONF
    const proposedId = parseInt(
      proposedChainId,
      16,
    ) as keyof typeof NETWORKS_CONF

    return {
      currentChain: {
        id: currentId,
        label: NETWORKS_CONF[currentId]!.label,
        blockchain: NETWORKS_CONF[currentId]!.blockchain,
      },
      proposedChain: {
        id: proposedId,
        label: NETWORKS_CONF[proposedId]!.label,
        blockchain: NETWORKS_CONF[proposedId]!.blockchain,
      },
    }
  })()
  assert(origin != null, 'OriginInfo: origin not provided')

  return (
    <Box width={OUTER_CONTENT_WIDTH}>
      <OriginMessage
        message={t('Allow this site to change the network?')}
        {...{origin, favIconUrl}}
      />
      <Box mt={1} />
      {chainsConfigs != null ? (
        <SupportedChange
          {...restProps}
          currentChainConf={chainsConfigs.currentChain}
          proposedChainConf={chainsConfigs.proposedChain}
        />
      ) : (
        <UnsupportedChange
          {...{...restProps, currentChainId, proposedChainId}}
        />
      )}
    </Box>
  )
}

type ChainIdConf = {id: EvmChainId; label: string; blockchain: EvmBlockchain}

type SupportedChangeProps = {
  currentChainConf: ChainIdConf
  proposedChainConf: ChainIdConf
} & Pick<ChangeEvmChainProps, 'onAccept' | 'onReject'>

function SupportedChange({
  onAccept,
  onReject,
  currentChainConf,
  proposedChainConf,
}: SupportedChangeProps) {
  const {t} = useTranslation()

  return (
    <>
      <Box display="flex" justifyContent="center">
        <Box>
          <Transition kind="from" chainConf={currentChainConf} />
          <Transition kind="to" chainConf={proposedChainConf} />
        </Box>
      </Box>

      <Box width="100%" mt={2}>
        <ActionButtons
          leftButton={<RejectButton onClick={onReject} />}
          rightButton={
            <Button
              textTransform="none"
              fullWidth
              variant="contained"
              color="primary"
              onClick={() =>
                onAccept(NETWORKS_CONF[proposedChainConf.id]!.blockchain)
              }
            >
              {t('Change')}
            </Button>
          }
        />
      </Box>
    </>
  )
}

type TransitionProps = {
  kind: 'from' | 'to'
  chainConf: ChainIdConf
}

function Transition({kind, chainConf}: TransitionProps) {
  const {t} = useTranslation()
  return (
    <Box mt={1}>
      <Typography variant="subtitle1" fontWeight="bold">
        {kind === 'from' ? t('From') : t('To')}
      </Typography>
      <Typography>{chainConf.label}</Typography>
      <Typography fontSize="small">Chain ID: {chainConf.id}</Typography>
    </Box>
  )
}

function UnsupportedChange({
  onReject,
  currentChainId,
  proposedChainId,
}: Omit<ChangeEvmChainProps, 'onChange'>) {
  const {t} = useTranslation()
  return (
    <>
      <Alert severity="warning">
        <Typography align="center">
          <Trans
            i18nKey="unsupported_evm_network_change"
            t={t}
            components={{
              bold: <Box component="span" fontWeight="fontWeightBold" />,
            }}
            values={{
              proposedChainId: parseInt(proposedChainId, 16),
              currentChainId: parseInt(currentChainId, 16),
            }}
          />
        </Typography>
      </Alert>
      <Box mt={2} />
      <RejectButton onClick={onReject} />
    </>
  )
}
