import {Box, styled} from '@mui/material'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Redirect, Route, Switch, useHistory} from 'react-router-dom'

import {csvExportBlockchains} from 'src/blockchainTypes'
import {BlockchainSelectField} from 'src/components'
import {useEnabledExistingBlockchains} from 'src/features/profile/application'
import {routeTo} from 'src/router'
import {useActivityRouteOptions} from 'src/router/activity'
import type {AccountId, Blockchain} from 'src/types'
import {BlockchainSubsetGuard} from 'src/utils/blockchainGuards'
import {useScrollableStyles} from 'src/utils/layoutUtils'
import {getDefaultAccountId, useGetAccounts, useHasAccounts} from 'src/wallet'

import {ExportHistoryModal} from '../portfolio/account/actions/exportHistory/ExportHistoryModal'
import {NoAccountsScreen} from '../portfolio/account/NoAccountsScreen'
import {AssetDetailAccounts} from '../portfolio/assetDetail/AssetDetailAccounts'

import {ExportActivityButton} from './ExportActivityButton'

const DEFAULT_BLOCKCHAIN: Blockchain = 'cardano'

const ActivityPage = () => {
  return (
    <Switch>
      <Route
        path={[
          routeTo.activity.blockchain().account(),
          routeTo.activity.blockchain().index,
        ]}
        component={ActivityContent}
      />
      <Redirect
        path={routeTo.activity.index}
        to={routeTo.activity.blockchain(DEFAULT_BLOCKCHAIN).index}
      />
    </Switch>
  )
}

const ActivityContent = () => {
  const {t} = useTranslation()
  const history = useHistory()
  const {blockchain, accountId} = useActivityRouteOptions()
  const enabledBlockchains = useEnabledExistingBlockchains()

  const {data: accounts} = useGetAccounts(blockchain)
  const hasAccounts = useHasAccounts(blockchain)
  const scrollableClasses = useScrollableStyles()

  const [isExportOpen, setIsExportOpen] = useState(false)

  const handleBlockchainChange = (blockchain: Blockchain | null) => {
    if (!blockchain) return
    history.replace(routeTo.activity.blockchain(blockchain).index)
  }

  const handleAccountChange = (account: AccountId) =>
    history.replace(routeTo.activity.blockchain(blockchain).account(account))

  const defaultAccount = getDefaultAccountId(accounts ?? [])
  useEffect(() => {
    if (accountId || !defaultAccount) return

    const newPath = routeTo.activity
      .blockchain(blockchain)
      .account(defaultAccount)
    history.replace(newPath)
  }, [blockchain, accountId, defaultAccount])

  return (
    <ActivityBox className={scrollableClasses.scrollableParent}>
      <HeaderBox>
        <BlockchainSelectField
          sx={{width: '260px'}}
          value={blockchain}
          onChange={handleBlockchainChange}
          allowedBlockchains={enabledBlockchains}
          label={t('Blockchain')}
        />
        {accountId && hasAccounts && (
          <ExportActivityButton
            blockchain={blockchain}
            onExport={() => setIsExportOpen(true)}
          />
        )}
      </HeaderBox>
      <ContentBox>
        {accountId && hasAccounts ? (
          <Box className={scrollableClasses.scrollableList}>
            <AssetDetailAccounts
              isEditable={false}
              onAccountChange={handleAccountChange}
            />
          </Box>
        ) : (
          <NoAccountsScreen blockchain={blockchain} />
        )}
      </ContentBox>
      {isExportOpen && accountId && (
        <BlockchainSubsetGuard
          blockchain={blockchain}
          blockchainSubset={csvExportBlockchains}
        >
          {(csvExportBlockchain) => (
            <ExportHistoryModal
              id={accountId}
              blockchain={csvExportBlockchain}
              onClose={() => setIsExportOpen(false)}
            />
          )}
        </BlockchainSubsetGuard>
      )}
    </ActivityBox>
  )
}

const HeaderBox = styled(Box)(({theme}) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: `${theme.spacing(1)} 0`,
  paddingRight: theme.spacing(2),
}))

const ContentBox = styled(Box)({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  overflow: 'auto',
})

const ActivityBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
})

export default ActivityPage
