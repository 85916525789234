import {Typography} from '@mui/material'
import {assert} from '@nufi/frontend-common'
import BigNumber from 'bignumber.js'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {tokenBlockchains} from '../../../blockchainTypes'
import {
  AssetIconModalHeader,
  TextButton,
  FormattedTokenName,
} from '../../../components'
import type {
  Blockchain,
  TokenMetadata,
  TokenId,
  TokenAmount,
} from '../../../types'
import {safeAssertUnreachable} from '../../../utils/assertion'
import {BlockchainSubsetGuard} from '../../../utils/blockchainGuards'
import {useGetCoinName, useGetBlockchainName} from '../../../utils/translations'
import type {HwVendor} from '../../../wallet'
import {parseNativeAmount, isNft, commonParseTokenAmount} from '../../../wallet'
import {isEvmBlockchain} from '../../../wallet/evm'
import {DeviceReadyState as _DeviceReadyState} from '../../transaction/common'

import type {
  BaseSendSchema,
  NativeAssetSchema,
  TokenSchema,
  AssetSchema,
  SendMultipleAssetsBlockchains,
  TxSummarySendContext,
} from './types'
import {schemaKeys, SendMultipleAssetsBlockchain} from './types'

type SendModalHeaderProps = {
  onClose: () => unknown
  blockchain: Blockchain
  token?: TokenMetadata
  customTitle?: string
}

export const SendModalHeader = ({
  onClose,
  blockchain,
  token,
  customTitle,
}: SendModalHeaderProps): React.ReactNode => {
  const {t} = useTranslation()
  const getCoinName = useGetCoinName()
  const getBlockchainName = useGetBlockchainName()

  const assetName = (() => {
    if (token) {
      return (
        <BlockchainSubsetGuard
          blockchain={blockchain}
          blockchainSubset={tokenBlockchains}
        >
          {(tokenBlockchain) => (
            <FormattedTokenName
              blockchain={tokenBlockchain}
              tokenInfo={token}
              ellipsize
              ellipsizeLength={20}
            />
          )}
        </BlockchainSubsetGuard>
      )
    } else {
      return getCoinName(blockchain)
    }
  })()

  return (
    <AssetIconModalHeader
      onClose={onClose}
      blockchain={blockchain}
      tokenMetadata={token}
    >
      <>
        <Typography variant="body1">
          {customTitle || (
            <>
              {t('send_asset')}
              {token && assetName}
            </>
          )}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {getBlockchainName(blockchain)}
        </Typography>
      </>
    </AssetIconModalHeader>
  )
}

type DeviceReadyStateProps = {hwVendor: HwVendor}

export const DeviceReadyState = ({hwVendor}: DeviceReadyStateProps) => {
  const {t} = useTranslation()
  return (
    <_DeviceReadyState
      {...{hwVendor}}
      alertMessage={t(
        'Always verify that the address displayed on your device exactly matches the recipient one.',
      )}
    />
  )
}

export type ActiveScreen =
  | 'details'
  | 'summary'
  | 'sign-trezor'
  | 'sign-ledger'
  | 'sign-mnemonic'
  | 'submit'

export type MaxAmountButtonProps = {
  onMaxAmount: () => unknown
  disabled?: boolean
  helperText?: string
}

export function MaxAmountButton({onMaxAmount, disabled}: MaxAmountButtonProps) {
  const {t} = useTranslation()

  return (
    <TextButton
      color="text.primary"
      disabled={!!disabled}
      label={t('MAX')}
      onClick={onMaxAmount}
    />
  )
}

export const findTokenAmount = (accountTokens: TokenAmount[], id: TokenId) =>
  accountTokens.find(({token}) => token.id === id)?.amount

export function getMaxTokenAmount(
  blockchain: Blockchain,
  accountTokens: TokenAmount[],
  tokenId: TokenId,
): BigNumber | null {
  switch (blockchain) {
    case 'cardano':
      return (() => {
        const amount = findTokenAmount(accountTokens, tokenId)
        if (!amount) return null
        // max sendable amount should be set at MAX_UINT64
        // https://github.com/input-output-hk/cardano-ledger/blob/4742636af7bbce222647396f1a9f20379817f861/eras/babbage/test-suite/cddl-files/babbage.cddl#L79
        const MAX_UINT64 = new BigNumber(2).pow(64).minus(1)
        return amount.gt(MAX_UINT64) ? MAX_UINT64 : amount
      })()
    default: {
      if (
        isEvmBlockchain(blockchain) ||
        blockchain === 'solana' ||
        blockchain === 'flow'
      ) {
        return (() => {
          const amount = findTokenAmount(accountTokens, tokenId)
          if (!amount) return null
          return amount
        })()
      }
      return safeAssertUnreachable(blockchain)
    }
  }
}

type SendContentUtils = {
  assets: AssetSchema[]
  blockchain: Blockchain
  txFee: BigNumber
  sendContext: TxSummarySendContext
}

export const getAmountsToSend = ({
  blockchain,
  txFee,
  assets,
  sendContext,
}: SendContentUtils) => {
  const nativeAmountField =
    sendContext.type === 'native'
      ? ensureNativeAmount(assets)
      : findNativeAsset(assets)?.amount

  const nativeAmount = nativeAmountField
    ? parseNativeAmount(blockchain, nativeAmountField)
    : new BigNumber(0)

  const tokensToSendCount = findTokens(assets).length
  const totalNativeAmountSpent = nativeAmount.plus(txFee)

  const singleTokenAmount =
    sendContext.type === 'token'
      ? commonParseTokenAmount(
          ensureSingleTokenAmount(assets),
          sendContext.tokenMetadata.decimals,
        )
      : undefined

  return {
    nativeAmount,
    tokensToSendCount,
    totalNativeAmountSpent,
    singleTokenAmount,
  }
}

export const getAssetFieldName = (i: number) =>
  `${schemaKeys.assets}[${i}].amount`

export const findNativeAsset = (
  assets: AssetSchema[],
): NativeAssetSchema | undefined =>
  assets.find(({type}) => type === 'native') as NativeAssetSchema | undefined

export const findTokens = (assets: AssetSchema[]): TokenSchema[] =>
  assets.filter(({type}) => type === 'token') as TokenSchema[]

export const ensureNativeAmount = (assets: AssetSchema[]) => {
  const nativeAsset = findNativeAsset(assets)
  assert(nativeAsset != null, 'ensureNativeAmount: Native asset not found')

  return nativeAsset.amount
}

export const ensureSingleTokenAmount = (assets: AssetSchema[]) => {
  const tokenAssets = findTokens(assets)
  assert(
    tokenAssets.length === 1,
    `ensureSingleTokenAmount: Found ${tokenAssets.length} token assets.`,
  )
  return tokenAssets[0]!.amount
}

/**
 * If in 'validated' name service mode, returns resolved name service address.
 * Otherwise returns value from `toAddress` field (this can be basically any string,
 * valid or invalid).
 */
export function getFormAddress(values: BaseSendSchema) {
  if (
    values.addressType === 'external' &&
    values.toAddressNameServiceState.status === 'valid'
  ) {
    // Name service address should be set at this point
    return values.toAddressNameServiceState.resolvedAddress
  }
  return values.toAddress
}

export function validateMinAmount(
  blockchain: Blockchain,
  amount: string,
  minAmount: BigNumber,
) {
  const nativeAmount = parseNativeAmount(blockchain, amount)
  return (
    nativeAmount.isGreaterThanOrEqualTo(minAmount) &&
    nativeAmount.isGreaterThanOrEqualTo(0)
  )
}

export const isSendMultipleAssetsSupported = (
  blockchain: Blockchain,
): blockchain is SendMultipleAssetsBlockchains =>
  (SendMultipleAssetsBlockchain as Blockchain[]).includes(blockchain)

export const isBalanceNonFungible = (
  isNft: boolean,
  decimals: number,
  balance?: BigNumber,
) => !!(isNft && decimals === 0 && balance?.isEqualTo(1))

export function getInitialTokenAmountFormValue(
  tokenMetadata?: TokenMetadata,
  balance?: BigNumber,
): string {
  if (tokenMetadata == null || balance == null) {
    return ''
  }
  return isBalanceNonFungible(
    isNft(tokenMetadata),
    tokenMetadata.decimals,
    balance,
  )
    ? balance.toString()
    : ''
}
