import {Stack} from '@mui/material'
import {assert} from '@nufi/frontend-common'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {AccountSelectField, Alert} from 'src/components'
import {AddAccount} from 'src/pages/portfolio/sharedActions/addAccount/AddAccount'
import type {AccountInfo} from 'src/types'

import type {RampCryptoAsset} from '../../../../../domain'
import {RampOperation} from '../../../../../domain'
import {WithAccountsQuery} from '../../../components/WithAccounts'
import {useRampFlowConfig} from '../../../state/rampFlowConfig'

import {useRampFlowDetailsForm} from './detailsForm'

export const RampFlowAccountSelect = () => {
  const {t} = useTranslation()
  const {
    formDispatch,
    errors,
    values: {asset, operation, account},
    touched,
  } = useRampFlowDetailsForm()

  const {fixedAccountAddress} = useRampFlowConfig()

  if (fixedAccountAddress) {
    assert(account.address === fixedAccountAddress)
  }

  const renderAccountField = (
    accounts: AccountInfo[],
    asset: RampCryptoAsset,
  ) => {
    const targetAccount = accounts.find((a) => a.address === account.address)
    if (fixedAccountAddress) {
      assert(targetAccount?.address === fixedAccountAddress)
    }

    return (
      <AccountSelectField
        disabled={asset == null || !!fixedAccountAddress}
        label={
          {
            [RampOperation.BUY]: t('Receive to'),
            [RampOperation.SELL]: t('Fund from'),
          }[operation] satisfies string
        }
        items={accounts.map((a) => ({accountId: a.id, ...a}))}
        onChange={(e) => {
          const ta = accounts.find((a) => a.id === e.target.value)
          if (!ta) return
          formDispatch({
            type: 'setAccount',
            payload: {
              account: {
                address: ta.address,
              },
            },
          })
        }}
        value={targetAccount?.id}
        error={!!(touched.account && errors.account?.address)}
        helperText={touched.account ? errors.account?.address : undefined}
        blockchain={asset.blockchain}
        showBlockchainIcon
      />
    )
  }

  return asset ? (
    <WithAccountsQuery blockchain={asset.blockchain}>
      {(accounts) => {
        if (accounts.length === 0) {
          return (
            <Stack spacing={2}>
              <Alert severity="info">
                {t('buy_sell_flow_no_accounts', {blockchain: asset.blockchain})}
              </Alert>
              <AddAccount preselectedBlockchain={asset.blockchain} />
            </Stack>
          )
        }
        return renderAccountField(accounts, asset)
      }}
    </WithAccountsQuery>
  ) : null
}
