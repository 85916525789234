import {Grid, TextField, Box, Typography} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import type {FormikHelpers, FormikProps} from 'formik'
import {Formik} from 'formik'
import React, {useState} from 'react'
import {useTranslation, Trans} from 'react-i18next'
import * as yup from 'yup'

import {
  StorageError,
  AppStorageError,
  useRestoreLocalProfileFromBackup,
  isCorruptedStoreError,
  useCheckProfileConflictFromMnemonic,
  validateLocalProfileBackup,
} from 'src/appStorage'

import type {
  ProfilePassword,
  ProfileName,
  ProfileBackup,
  ProfileMetadata,
} from '../../../appStorage'
import {ProfileConflictCode} from '../../../appStorage/constants'
import {
  Button,
  NavigateBack,
  MutationGuard,
  SingleFileUpload,
  Modal,
  ModalLayout,
  Alert,
  ModalFooter,
  FooterLayout,
  ModalHeader,
} from '../../../components'
import {trackProfileAction} from '../../../tracking'
import {assert, safeAssertUnreachable} from '../../../utils/assertion'
import {getHasFormError} from '../../../utils/form'
import {NavigateBackWrapper} from '../common'
import {TermsAndConditionsCheckbox} from '../TermsAndConditionsCheckbox'

import {
  useRecoverSchemaWithoutUniqueProfileName,
  commonInitialValues,
} from './common'
import type {CommonRestoreFormSchema} from './types'

type Props = {
  onSuccess: () => unknown
}

export default function RestoreFromBackup({onSuccess}: Props) {
  const classes = useStyles()
  const {t} = useTranslation()
  const restoreFromBackup = useRestoreLocalProfileFromBackup()
  const {fetch: checkProfileConflict} = useCheckProfileConflictFromMnemonic()
  const recoverSchema = useRecoverSchemaWithoutUniqueProfileName()
  const [profileBackupFile, setProfileBackupFile] =
    useState<ProfileBackup | null>(null)
  const [existingProfile, setExistingProfile] =
    useState<ProfileMetadata | null>(null)

  const _formSchema = {
    ...recoverSchema,
    file: yup.mixed().required(t('Key file is required')),
  }
  type FormField = keyof Omit<typeof _formSchema, 'file'>
  type FormData = CommonRestoreFormSchema & {
    file: File | null
  }
  const formSchema = yup.object().shape(_formSchema)

  const initialValues: FormData = {
    ...commonInitialValues,
    file: null,
  }

  const onFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    {values, setFieldValue, resetForm}: FormikProps<FormData>,
  ) => {
    resetForm({values: {...initialValues, termsChecked: values.termsChecked}})
    if (!event?.currentTarget?.files?.[0]) return
    const file = event.currentTarget.files[0]
    if (file.name) {
      setFieldValue('profileName', getProfileNameFromFile(file))
    }
    setFieldValue('file', file)
  }

  const restoreProfile = async (
    profileBackup: ProfileBackup,
    password: ProfilePassword,
    profileName: ProfileName,
  ) => {
    await restoreFromBackup.mutateAsync({
      profileBackup,
      password,
      profileName,
    })

    onSuccess()
    trackProfileAction('restore_backup_profile')
  }

  const onSubmit = (
    values: FormData,
    {setErrors, setSubmitting}: FormikHelpers<FormData>,
  ) => {
    if (!values.file) return
    // Note: `handleFileLoad` must be defined using `function` not `const`, due to `this` behavior
    async function handleFileLoad(event: ProgressEvent<FileReader>) {
      if (!event?.target) return
      setErrors({password: undefined})

      try {
        const {
          mnemonicData: {mnemonic},
        } = await validateLocalProfileBackup(
          event.target.result as ProfileBackup,
          values.password as ProfilePassword,
        )

        const profileConflictResults = await checkProfileConflict({
          profileName: values.profileName as ProfileName,
          mnemonic,
          loginType: 'password',
        })
        assert(profileConflictResults != null)
        switch (profileConflictResults.code) {
          case ProfileConflictCode.SAME_PROFILE_EXISTS_CONFLICT:
            setProfileBackupFile(event.target.result as ProfileBackup)
            setExistingProfile(profileConflictResults.existingProfile)
            break

          case ProfileConflictCode.DIFFERENT_PROFILE_NAME_CONFLICT:
            setErrors({
              profileName: t('This name is already used for another wallet.'),
            })
            setSubmitting(false)
            break

          case ProfileConflictCode.NO_CONFLICT:
            await restoreProfile(
              event.target.result as ProfileBackup,
              values.password as ProfilePassword,
              values.profileName as ProfileName,
            )

            setSubmitting(false)
            break

          default:
            safeAssertUnreachable(profileConflictResults)
        }
      } catch (_err: unknown) {
        const err = _err as Error
        if (err.message === AppStorageError.WRONG_PASSWORD) {
          setErrors({password: t('Incorrect password')})
        } else if (err.message === AppStorageError.PROFILE_EXISTS) {
          setErrors({
            file: t('Wallet with this recovery phrase already exists.'),
          })
        } else if (err.message === StorageError.BACKUP_NEWER_THAN_APP) {
          setErrors({
            file: t(
              'The backup requires newer app version. Please upgrade to up to date version.',
            ),
          })
        } else if (isCorruptedStoreError(err)) {
          setErrors({
            file: t(
              'Invalid backup file. Make sure you chose the correct file.',
            ),
          })
        } else {
          setErrors({file: t('Unexpected error.')})
        }

        setSubmitting(false)
      }
    }

    function handleFileError() {
      setErrors({file: t('Unexpected error.')})
      setSubmitting(false)
    }

    const reader = new FileReader()
    reader.onload = handleFileLoad
    reader.onerror = handleFileError
    reader.readAsText(values.file)
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={formSchema}
      onSubmit={onSubmit}
    >
      {(formikProps) => {
        const {handleSubmit, values, errors, isSubmitting, handleChange} =
          formikProps
        const hasError = getHasFormError(formikProps)

        return (
          <form onSubmit={handleSubmit} className={classes.form}>
            <Grid container>
              <Grid item xs={12} className={classes.fileInput}>
                <SingleFileUpload
                  id="key-file-upload"
                  title={t('Upload')}
                  value={values.file}
                  error={errors.file}
                  onChange={(event) => onFileChange(event, formikProps)}
                />
              </Grid>
              {values.file && (
                <>
                  <Grid item xs={12} className={classes.profileName}>
                    <TextField
                      value={values.profileName}
                      data-test-id="backup-profile-name"
                      onChange={handleChange<FormField>('profileName')}
                      label={t('Wallet name')}
                      error={hasError('profileName')}
                      helperText={hasError('profileName') && errors.profileName}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} className={classes.password}>
                    <TextField
                      value={values.password}
                      onChange={handleChange<FormField>('password')}
                      label={t('Password')}
                      type="password"
                      error={hasError('password')}
                      helperText={hasError('password') && errors.password}
                      variant="outlined"
                      fullWidth
                      inputProps={{
                        'data-test-id': 'backup-profile-password',
                      }}
                    />
                  </Grid>
                  <Box my={2}>
                    <TermsAndConditionsCheckbox
                      value={formikProps.values.termsChecked}
                      onChange={formikProps.handleChange<
                        keyof typeof formikProps.values
                      >('termsChecked')}
                      error={getHasFormError(formikProps)('termsChecked')}
                      helperText={formikProps.errors.termsChecked}
                      // Needed as both the `<TermsAndConditionsCheckbox />` will get rendered
                      dataTestId="terms-and-conditions-checkbox-backup"
                    />
                  </Box>
                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      textTransform="none"
                      color="primary"
                      variant="contained"
                      type="submit"
                      disabled={isSubmitting}
                      data-test-id="recover-submit"
                    >
                      {t('Recover')}
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>

            <NavigateBackWrapper
              justifyContent={values.file ? 'center' : 'left'}
            >
              <NavigateBack />
            </NavigateBackWrapper>

            {/* Passing error={null} as we are handling error manually */}
            <MutationGuard {...restoreFromBackup} error={null} />

            {existingProfile != null && (
              <OverwriteProfileModal
                existingProfileName={existingProfile.name}
                onClose={() => {
                  setExistingProfile(null)
                  formikProps.setSubmitting(false)
                }}
                onConfirm={async () => {
                  setExistingProfile(null)

                  try {
                    assert(profileBackupFile != null)
                    await restoreProfile(
                      profileBackupFile,
                      values.password as ProfilePassword,
                      values.profileName as ProfileName,
                    )
                  } catch (_err: unknown) {
                    formikProps.setErrors({file: t('Unexpected error.')})
                    formikProps.setSubmitting(false)
                  }
                }}
              />
            )}
          </form>
        )
      }}
    </Formik>
  )
}

function getProfileNameFromFile(file: File) {
  const name = file.name
  const splitName = name.split('.')
  splitName.pop()
  return (
    splitName
      .join('')
      // macOS file names are stored in their "fully decomposed form" so we need to
      // normalize the file name into NFC (https://unicode.org/reports/tr15/#Norm_Forms)
      // to prevent having different profile names on different platforms for the same file.
      // Not normalizing also causes issues with profile deletion, because on macOS the
      // profile name typed in for verification, which would be in NFC, would not be equal to
      // the actual profile name (which would use NFD) if accented characters were included.
      .normalize('NFC')
  )
}

type OverwriteProfileModalProps = {
  existingProfileName: string
  onClose: () => void
  onConfirm: () => void
}

function OverwriteProfileModal({
  existingProfileName,
  onClose,
  onConfirm,
}: OverwriteProfileModalProps) {
  const classes = useStyles()
  const {t} = useTranslation()

  return (
    <Modal
      className={classes.modal}
      onClose={onClose}
      variant="centered"
      dismissable
    >
      <ModalLayout
        header={
          <ModalHeader onClose={onClose} hasDivider>
            <Typography
              variant="h6"
              data-test-id="backup-overwrite-wallet-warning"
            >
              {t('Are you sure you want to overwrite this wallet?')}
            </Typography>
          </ModalHeader>
        }
        body={
          <Box p={2}>
            <Alert
              severity="error"
              text={
                <Trans
                  i18nKey="overwrite_existing_wallet_alert"
                  t={t}
                  components={{
                    b: <b />,
                  }}
                  values={{existingProfileName}}
                />
              }
            />
          </Box>
        }
        footer={
          <ModalFooter hasDivider>
            <FooterLayout
              leftBtnConf={{
                onClick: onClose,
                children: t('Close'),
              }}
              rightBtnConf={{
                onClick: onConfirm,
                variant: 'contained',
                color: 'error',
                children: t('Overwrite my wallet'),
              }}
            />
          </ModalFooter>
        }
      />
    </Modal>
  )
}

const useStyles = makeStyles((theme) => ({
  form: {
    marginTop: theme.spacing(4),
  },
  fileInput: {
    marginBottom: theme.spacing(2),
  },
  password: {
    marginTop: theme.spacing(2),
  },
  profileName: {
    marginTop: theme.spacing(2),
  },
  modal: {
    width: 550,
    overflow: 'visible',
  },
}))
