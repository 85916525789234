import {useEffect} from 'react'

const TX_SIGNING_REQUEST_KEY = 'tx-signing-request'
const DEFAULT_VALUE = '0'

export const authorizeTxSubmission = (fn: () => unknown) => () => {
  sessionStorage.setItem(TX_SIGNING_REQUEST_KEY, Date.now().toString())
  fn()
}

// This fn prevents us from accidentally signing and submitting "mnemonic" transaction,
// e.g. by setting wrong "state" in some handler and rendering `LightSignMnemonicScreen`
// sooner than desired. This is fairly paranoid, but we require the user to authorize`
// such action in some handler no more than `safeDistanceFromEventInMs` ago using `authorizeTxSubmission`.
// A more robust solution could be built using "idempotency-tokens" but due to possible complexity we
// decided not to implement it.
export const consumeTxSubmissionAuthorization = (
  safeDistanceFromEventInMs: number,
) => {
  const durationFromSignEvent =
    Date.now() -
    parseInt(
      sessionStorage.getItem(TX_SIGNING_REQUEST_KEY) || DEFAULT_VALUE,
      10,
    )

  sessionStorage.setItem(TX_SIGNING_REQUEST_KEY, DEFAULT_VALUE)

  if (durationFromSignEvent > safeDistanceFromEventInMs) {
    throw new Error(
      `Delay between sign tx button click and actual signing invocation was more than ${durationFromSignEvent}ms!`,
    )
  }
}

const clearTxSubmissionAuthorization = () => {
  sessionStorage.setItem(TX_SIGNING_REQUEST_KEY, DEFAULT_VALUE)
}

export const useClearTxSubmissionAuthorization = (onSign: () => void) => {
  useEffect(() => {
    clearTxSubmissionAuthorization()
    onSign()
  }, [])
}
