import {UNKNOWN_ACCOUNT_NAME, isAccountActive} from '@nufi/wallet-common'
import type {EvmAccountStoredData, EvmBlockchain} from '@nufi/wallet-evm'
import _ from 'lodash'

import type {AccountStoredData, BlockchainState} from '../store/wallet'
import type {AccountId, AccountName} from '../types'
import {assert} from '../utils/assertion'

import {getDefaultAccountId} from './utils/common'

export interface AccountsStoreManager<D extends AccountStoredData> {
  getVisibleAccounts(): Array<D>
  getAllAccounts(): Array<D>
  setAccounts(accounts: D[]): void
  getAccount(accountId: AccountId): D
}

type AccountsStore<D extends AccountStoredData> = {
  getState: () => BlockchainState<D>
}

export function getVisibleAccounts<D extends AccountStoredData>(
  accounts: D[],
): D[] {
  return accounts.filter(isAccountActive)
}

export class AccountsStoreManagerImpl<D extends AccountStoredData>
  implements AccountsStoreManager<D>
{
  private accountsStore: AccountsStore<D>

  constructor(accountsStore: AccountsStore<D>) {
    this.accountsStore = accountsStore
  }

  private getAccounts(): {
    visibleAccounts: Array<D>
    allAccounts: Array<D>
  } {
    const {accounts} = this.accountsStore.getState()
    assert(!!accounts)
    return {
      allAccounts: accounts,
      visibleAccounts: getVisibleAccounts(accounts),
    }
  }

  getAllAccounts(): Array<D> {
    return this.getAccounts().allAccounts
  }

  getVisibleAccounts(): Array<D> {
    return this.getAccounts().visibleAccounts
  }

  getAccount(accountId: AccountId): D {
    const accounts = this.getAllAccounts()
    const account = accounts.find((a) => a.id === accountId)
    if (!account) throw new Error(`Account ${accountId} does not exist`)
    return account
  }

  setAccounts = (accounts: D[]): void => {
    const {setAccounts} = this.accountsStore.getState()
    setAccounts(accounts)
  }
}

type _StandardAccountStoredData = Extract<AccountStoredData, {type: 'standard'}>

export function setDefaultStandardAccount<D extends _StandardAccountStoredData>(
  accounts: D[],
  id: AccountId,
): D[] {
  return accounts.map((a) => {
    return a.id === id ? {...a, isDefault: true} : {...a, isDefault: false}
  })
}

export function setDefaultEvmAccount(
  accounts: EvmAccountStoredData[],
  id: AccountId,
  blockchains: EvmBlockchain[],
): EvmAccountStoredData[] {
  return accounts.map((a) => {
    return a.id === id
      ? {
          ...a,
          defaultForBlockchains: _.uniq([
            ...a.defaultForBlockchains,
            ...blockchains,
          ]),
        }
      : {
          ...a,
          defaultForBlockchains: a.defaultForBlockchains.filter(
            (b) => !blockchains.includes(b),
          ),
        }
  })
}

type EnsureDefaultAccountArgs<D extends AccountStoredData> = {
  accounts: D[]
  hasDefaultAccount: boolean
  setDefaultAccountFn: (newAccountId: AccountId) => D[]
}

function _ensureDefaultAccount<D extends AccountStoredData>({
  accounts,
  hasDefaultAccount,
  setDefaultAccountFn,
}: EnsureDefaultAccountArgs<D>): D[] {
  if (hasDefaultAccount) return accounts

  const newAccountId = accounts.find((a) => !a.deletedAt)?.id
  if (!newAccountId) {
    // All accounts are deleted, no need to have a default account.
    return accounts
  }

  return setDefaultAccountFn(newAccountId)
}

export function ensureDefaultStandardAccount<
  D extends _StandardAccountStoredData,
>(accounts: D[]): D[] {
  return _ensureDefaultAccount({
    accounts,
    hasDefaultAccount: getDefaultAccountId(accounts) != null,
    setDefaultAccountFn: (accountId) =>
      setDefaultStandardAccount(accounts, accountId),
  })
}

export function ensureDefaultEvmAccount(
  accounts: EvmAccountStoredData[],
  evmBlockchain: EvmBlockchain,
): EvmAccountStoredData[] {
  return _ensureDefaultAccount({
    accounts,
    hasDefaultAccount: accounts.some((a) =>
      a.defaultForBlockchains.includes(evmBlockchain),
    ),
    setDefaultAccountFn: (newAccountId) =>
      setDefaultEvmAccount(accounts, newAccountId, [evmBlockchain]),
  })
}

type AddAccountsArgs<D extends AccountStoredData> = {
  accounts: D[]
  newAccounts: D[]
  ensureDefaultAccountFn: (accounts: D[]) => D[]
}

/**
 * Add account into array of accounts if the account with the same ID does not already
 * exist. If it exists it will replace it instead. This behavior is useful e.g. when
 * adding an account that was previously soft-deleted.
 */
function _addAccounts<D extends AccountStoredData>({
  accounts,
  newAccounts,
  ensureDefaultAccountFn,
}: AddAccountsArgs<D>): D[] {
  const newAccountsIds = newAccounts.map(({id}) => id)
  // e.g. account was previously soft-deleted
  if (accounts.some(({id}) => newAccountsIds.includes(id))) {
    // replace accounts
    const _accounts = accounts.filter((a) => !newAccountsIds.includes(a.id))
    // if there was no default account (all accounts were soft-deleted)
    // set the new account as default

    const allAccounts = [..._accounts, ...newAccounts]
    return ensureDefaultAccountFn(allAccounts)
  } else {
    const _accounts = [...accounts, ...newAccounts]
    // e.g. if in hw-only mode user can add his first account and we need to mark it as default
    return ensureDefaultAccountFn(_accounts)
  }
}

export function addStandardAccounts<D extends _StandardAccountStoredData>(
  accounts: D[],
  newAccounts: D[],
): D[] {
  return _addAccounts({
    accounts,
    newAccounts,
    ensureDefaultAccountFn: ensureDefaultStandardAccount,
  })
}

export function addEvmAccounts(
  enabledEvmBlockchains: EvmBlockchain[],
  accounts: EvmAccountStoredData[],
  newAccounts: EvmAccountStoredData[],
): EvmAccountStoredData[] {
  return _addAccounts({
    accounts,
    newAccounts,
    ensureDefaultAccountFn: (_accounts) =>
      // Ensure that there is a default account for each enabled evm blockchain.
      // E.g. when two EVM chains (ETH and MILK) are enabled but all EVM accounts have been deleted
      // we need to make sure that both chains have a default account.
      enabledEvmBlockchains.reduce(
        (accAccounts, evmBlockchain) =>
          ensureDefaultEvmAccount(accAccounts, evmBlockchain),
        _accounts,
      ),
  })
}

export function softDeleteStandardAccount<D extends _StandardAccountStoredData>(
  accounts: D[],
  accountId: AccountId,
): D[] {
  const accountsWithUpdatedDefaultAccountId = ((): D[] => {
    const defaultAccountId = getDefaultAccountId(accounts)
    if (accountId !== defaultAccountId) return accounts

    // try to find new default account candidate
    const newDefaultAccountId = accounts.find(
      (a) => isAccountActive(a) && a.id !== accountId,
    )?.id

    return newDefaultAccountId
      ? setDefaultStandardAccount(accounts, newDefaultAccountId)
      : // if no candidate was found unset "isDefault" flag
        accounts.map((a) => ({...a, isDefault: false}))
  })()

  return accountsWithUpdatedDefaultAccountId.map((a) =>
    a.id === accountId
      ? {...a, deletedAt: Date.now(), name: UNKNOWN_ACCOUNT_NAME}
      : a,
  )
}

export function softDeleteEvmAccount(
  accounts: EvmAccountStoredData[],
  accountId: AccountId,
): EvmAccountStoredData[] {
  const accountsWithUpdatedDefaultAccountId = ((): EvmAccountStoredData[] => {
    const {defaultForBlockchains} = accounts.find((a) => a.id === accountId)!
    if (defaultForBlockchains.length === 0) return accounts

    // try to find new default account candidate
    const newDefaultAccountId = accounts.find(
      (a) => isAccountActive(a) && a.id !== accountId,
    )?.id

    return newDefaultAccountId != null
      ? setDefaultEvmAccount(
          accounts,
          newDefaultAccountId,
          defaultForBlockchains,
        )
      : // if no candidate was found clear "defaultForBlockchains"
        accounts.map((a) => ({...a, defaultForBlockchains: []}))
  })()

  return accountsWithUpdatedDefaultAccountId.map((a) =>
    a.id === accountId
      ? {...a, deletedAt: Date.now(), name: UNKNOWN_ACCOUNT_NAME}
      : a,
  )
}

export function renameAccount<D extends AccountStoredData>(
  accounts: D[],
  accountId: AccountId,
  name: AccountName,
): D[] {
  return accounts.map((a) => (a.id === accountId ? {...a, name} : a))
}
