import {Box, Typography, useTheme} from '@mui/material'
import type BigNumber from 'bignumber.js'
import React from 'react'
import {useTranslation} from 'react-i18next'

import type {StakingBlockchain} from '../../../../blockchainTypes'
import {FormattedAsset, FormattedDateTime} from '../../../../components'

type FormattedDateTimeWithEpochNoProps = {
  dateTime: Date | null
  epochNo: number
}

export function FormattedDateTimeWithEpochNo({
  dateTime,
  epochNo,
}: FormattedDateTimeWithEpochNoProps) {
  const {t} = useTranslation()
  return (
    <>
      {dateTime ? <FormattedDateTime dateTime={dateTime} /> : t('N/A')}{' '}
      {t('Epoch')}: {epochNo}
    </>
  )
}

export function NoValue() {
  return (
    <Typography color={'textSecondary'} variant={'body2'}>
      -
    </Typography>
  )
}

type FormattedAssetEffectProps = {
  blockchain: StakingBlockchain
  amount: BigNumber
  color?: string
}

export function FormattedAssetEffect({
  blockchain,
  amount,
  color,
}: FormattedAssetEffectProps) {
  const theme = useTheme()
  const getColor = (amount: BigNumber) => {
    if (amount.gt(0)) return theme.palette.success.light
    if (amount.lt(0)) return theme.palette.error.light
    return theme.palette.text.secondary
  }
  const getSign = (amount: BigNumber) => {
    if (amount.gt(0)) return '+'
    return ''
  }
  return (
    <Typography variant={'body2'} style={{color: color || getColor(amount)}}>
      {getSign(amount)}
      <FormattedAsset
        blockchain={blockchain}
        amount={amount}
        isSensitiveInformation
      />
    </Typography>
  )
}

type StakeHistoryTitleWithAccountProps = {
  title: string
  accountName: string
}

export function StakeHistoryTitleWithAccount({
  title,
  accountName,
}: StakeHistoryTitleWithAccountProps) {
  return (
    <>
      <Typography noWrap>{title}</Typography>&nbsp;
      <Typography variant="body2" color="textSecondary">
        ({accountName})
      </Typography>
    </>
  )
}

type StakeHistoryInformationColumnProps = {
  children: React.ReactNode
}

export function StakeHistoryInformationColumn({
  children,
}: StakeHistoryInformationColumnProps) {
  return (
    <Box display="flex" alignItems="center" flexWrap="wrap">
      {children}
    </Box>
  )
}
