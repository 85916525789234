import type {
  FlowAccountDerivationParams,
  FlowAccountOfflineInfo,
} from '@nufi/wallet-flow'
import {MAX_ACCOUNT_COUNT, MAX_LEDGER_ACCOUNT_COUNT} from '@nufi/wallet-flow'
import React from 'react'
import {useTranslation} from 'react-i18next'

import type {AccountInfo} from '../../../wallet'
import {
  useAddAccounts,
  useDiscoverAllLedgerAccounts,
} from '../../../wallet/flow'
import type {DerivationPathTypeConfig} from '../sharedActions/addAccount/AddAccountUtils'
import {useDerivationPathTypeState} from '../sharedActions/addAccount/AddAccountUtils'
import {
  AddHwAccount,
  useAddHwAccountState,
} from '../sharedActions/addAccount/AddHwAccount'

export const useDerivationPathTypeConfig = (): DerivationPathTypeConfig<
  FlowAccountDerivationParams['type']
> => {
  const {t} = useTranslation()
  return {
    Bip44: {
      label: t('Bip44'),
      description: t("m/44'/539'"),
    },
    LedgerLegacy: {
      label: t('Legacy'),
      description: t("m/44'/1'/769/0/0"),
    },
  }
}

export function AddLedgerAccount() {
  const addHwAccountState = useAddHwAccountState()

  const derivationPathTypeConfig = useDerivationPathTypeConfig()

  const derivationPathTypeState = useDerivationPathTypeState<
    FlowAccountDerivationParams['type']
  >('Bip44', derivationPathTypeConfig)

  const discoveredAccountsQuery = useDiscoverAllLedgerAccounts(
    derivationPathTypeState.derivationPathType,
  )
  const addAccounts = useAddAccounts()

  const onSubmit = async (newAccounts: AccountInfo[]) => {
    await addAccounts.mutateAsync({
      newAccounts: newAccounts as FlowAccountOfflineInfo[],
    })
  }

  return (
    <AddHwAccount
      hwVendor="ledger"
      blockchain="flow"
      addAccountsMutation={addAccounts}
      {...{
        discoveredAccountsQuery,
        derivationPathTypeState,
        onSubmit,
        ...addHwAccountState,
      }}
      extras={{
        maxAccountLimit:
          derivationPathTypeState.derivationPathType === 'LedgerLegacy'
            ? MAX_LEDGER_ACCOUNT_COUNT
            : MAX_ACCOUNT_COUNT,
        prevMustBeUsed: true,
      }}
    />
  )
}
