import {Box, Typography} from '@mui/material'
import type {EvmBlockchain, EvmChainId} from '@nufi/wallet-evm'
import {objectEntries} from 'common/src/typeUtils'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {usePlatformConfig} from 'src/dappConnector/PlatformConfig'
import type {MessageToClient} from 'src/dappConnector/types'
import {useDappConnectorStore} from 'src/store/dappConnector'
import {getEvmManagers} from 'src/wallet/evm/evmManagers'

import {assert} from '../../../../../utils/assertion'
import type {AccountId, AccountInfo, Blockchain} from '../../../../../wallet'
import {getDefaultAccountId} from '../../../../../wallet'

export const NETWORKS_CONF = objectEntries(getEvmManagers()).reduce(
  (acc, [blockchain, {networkConfig}]) => {
    const {chainId, label} = networkConfig
    acc[chainId] = {
      label,
      blockchain,
    }

    return acc
  },
  {} as Record<EvmChainId, {label: string; blockchain: EvmBlockchain}>,
)

export const allowedChainIds = Object.values(getEvmManagers()).map(
  ({networkConfig}) => networkConfig.chainId,
)

export function ChangeNetworkHeader() {
  const {t} = useTranslation()
  return (
    <>
      <Typography textAlign="center" variant="h6">
        {t('Change network')}
      </Typography>
      <Box mt={2} />
    </>
  )
}

type WithLastUsedAccountIdProps = {
  accounts: AccountInfo[]
  blockchain: Blockchain
  children: (defaultAccountId: AccountId) => React.ReactNode
}

export function WithLastUsedAccountId({
  accounts,
  blockchain,
  children,
}: WithLastUsedAccountIdProps) {
  const [defaultAccountId, setDefaultAccountId] = useState<AccountId | null>(
    null,
  )
  const {lastAccountIdHandlers} = usePlatformConfig()

  assert(accounts.length > 0, 'No accounts passed to WithLastUsedAccountId')

  useEffect(() => {
    const fn = async () => {
      /**
       * Set as the default account, the account that was last used
       * as selected account in connector, or the app-level default account,
       * if not such account is found
       */
      const defaultAccountId = getDefaultAccountId(accounts) || null

      const lastUsedAccountId =
        await lastAccountIdHandlers?.getLastUsedAccountId(blockchain)

      if (
        lastUsedAccountId &&
        accounts.some((a) => a.id === lastUsedAccountId)
      ) {
        setDefaultAccountId(lastUsedAccountId)
      } else {
        setDefaultAccountId(defaultAccountId)
      }
    }
    fn()
  }, [blockchain])

  if (defaultAccountId == null) return null

  return <>{children(defaultAccountId)}</>
}

export const emitChainChanged = (chainId: number) => {
  const targetOrigin = useDappConnectorStore.getState().origin!
  chrome.runtime.sendMessage<MessageToClient>({
    senderContext: 'connectorWindow',
    targetContext: 'serviceWorker',
    targetOrigin,
    type: 'event',
    connectorKind: 'evm',
    method: 'chainChanged',
    args: [{chainId}],
  })
}
