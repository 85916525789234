import create from 'zustand'

import type {OpenAppAction} from 'src/features/appOpener/domain'
import logger from 'src/store/logger'

type State = {
  action: OpenAppAction | null
  setAction: (action: OpenAppAction | null) => void
  clearAction: () => void
  referrerTabId: number | null
  setReferrerTabId: (referrerTabId: number) => void
  clearReferrerTabId: () => void
}

export const useAppOpenerStore = create<State>(
  logger<State>('AppOpenerStore:')((set) => ({
    action: null,
    setAction: (action) => set({action}),
    clearAction: () => set({action: null}),
    referrerTabId: null,
    setReferrerTabId: (referrerTabId) => set({referrerTabId}),
    clearReferrerTabId: () =>
      set({
        referrerTabId: null,
      }),
  })),
)
