import {HelpOutline} from '@mui/icons-material'
import {Box} from '@mui/material'
import {
  isAdaDomain,
  isAdaHandle,
  lovelacesToAda,
  tokenFromTokenId,
} from '@nufi/wallet-cardano'
import React, {useRef, useState} from 'react'
import {Trans, useTranslation} from 'react-i18next'

import {
  MutationGuard,
  Alert,
  TextButton,
  WithTooltip,
} from '../../../components'
import {assert} from '../../../utils/assertion'
import type {AccountId} from '../../../wallet'
import {
  formatNativeAmount,
  commonParseTokenAmount,
  commonFormatTokenAmount,
} from '../../../wallet'
import type {
  CardanoAccountInfo,
  CardanoPaymentAddress,
  CardanoSignedTx,
  CardanoTokenId,
  CardanoTxPlan,
  Lovelaces,
  CardanoAddress,
} from '../../../wallet/cardano'
import {
  adaToLovelaces,
  calculateAdaSentOut,
  getMinAdaRequiredFromTxPlan,
  isValidCardanoPaymentAddress,
  useGetMaxTransferAdaTxPlan,
  useGetTransferAssetsTxPlan,
  useResolveAdaHandle,
  useSignTransaction,
  useSubmitTransaction,
  isScriptAddress,
  useResolveAdaDomain,
} from '../../../wallet/cardano'
import {WithCardanoExtras} from '../../transaction'
import type {RenderSubmitGuard} from '../../utils/debouncedFormUtils/debouncing'
import {
  getCardanoTxPlanDebounceTimeoutInMs,
  useAsyncDebouncedState,
} from '../../utils/debouncedFormUtils/debouncing'
import {DefaultErrorCard, detailsScreenTestIds} from '../common/DetailsScreen'
import {SendModalContent} from '../common/sendMultipleAssetsModal/SendMultipleAssetsModalContent'
import type {SendMultiAssetFormInjectedProps} from '../common/sendMultipleAssetsModal/types'
import type {BaseSendSchema as FormSchema} from '../common/types'
import {
  DeviceReadyState,
  getFormAddress,
  findNativeAsset,
  findTokens,
  getAssetFieldName,
} from '../common/utils'

import {
  minAmountValidationErrorKey,
  cardanoTxPlanErrorToString,
  MinAmountButton,
} from './common'
import type {UICachedTxPlan, CardanoSideEffectProps} from './types'

export type CardanoSendModalContentProps =
  SendMultiAssetFormInjectedProps<FormSchema> & {
    renderSubmitGuard?: RenderSubmitGuard<FormSchema>
    onClose: () => unknown
    minAda: Lovelaces
    setMinAda: (a: Lovelaces) => void
  } & CardanoSideEffectProps

export function CardanoSendModalContent({
  accounts,
  formikProps,
  setMinAda,
  metadataById,
  renderSubmitGuard,
  onResolveAdaHandle,
  onResolveAdaDomain,
  onGetTransferAdaTxPlan,
  onSubmitTransaction,
  onSignTransaction,
  minAda,
  schema,
  fromAccount,
  ...rest
}: CardanoSendModalContentProps) {
  const isMultiAssetSend = formikProps.values.assets.length > 1
  const submitProps = useSubmitTransaction(onSubmitTransaction)
  const signProps = useSignTransaction(onSignTransaction)
  const {values, setFieldValue} = formikProps

  const isNativeSendOnly =
    values.assets.length === 1 && values.assets[0]!.type === 'native'

  const [isNativeAmountFieldReadOnly, setIsNativeAmountFieldReadOnly] =
    useState(!isNativeSendOnly)

  const [txPlan, setTxPlan] = useState<UICachedTxPlan>({
    error: null,
    data: null,
  })
  const maxPlanCalculated = useRef(false)
  const {t} = useTranslation()
  const fetchAdaTxPlan = useGetTransferAssetsTxPlan(onGetTransferAdaTxPlan)
  const resolveAdaHandle = useResolveAdaHandle(onResolveAdaHandle)
  const resolveAdaDomain = useResolveAdaDomain(onResolveAdaDomain)
  const fetchMaxAdaTxPlan = useGetMaxTransferAdaTxPlan()
  const formAddress = getFormAddress(values) as CardanoPaymentAddress
  const blockchain = 'cardano'
  const addressValid = isValidCardanoPaymentAddress(formAddress)
  const tokenAmounts = (fromAccount as CardanoAccountInfo).tokensBalance

  const getAreDataValid = async (data: FormSchema) =>
    await schema.isValid(data, {
      context: {
        // we do not want to validate min amount before calculating txPlan, when tokens are present
        // as the min amount depends on token bundle and we calculate it later on from txPlan
        shouldSkipMinAmountValidation: !!findTokens(data.assets).length,
      },
    })
  const {
    onDataChange,
    finished: isTxPlanCalcFinished,
    submitGuardMeta,
  } = useAsyncDebouncedState<FormSchema, CardanoTxPlan>({
    timeout: getCardanoTxPlanDebounceTimeoutInMs(),
    state: txPlan,
    onFailure: (err) => {
      setTxPlan({
        data: null,
        error: err,
      })
    },
    onSuccess: (newTxPlan) => {
      setTxPlan({
        data: newTxPlan,
        error: null,
      })
      const requiredMinAda = getMinAdaRequiredFromTxPlan(newTxPlan)
      setMinAda(requiredMinAda)
      if (isNativeAmountFieldReadOnly && !isNativeSendOnly) {
        const nativeIndex = values.assets.findIndex(
          ({type}) => type === 'native',
        )
        if (nativeIndex > -1) {
          formikProps.setFieldValue(
            getAssetFieldName(nativeIndex),
            lovelacesToAda(requiredMinAda),
          )
        }
      }
    },
    shouldSkip: async ({values}) => {
      // We want to avoid recalculating fee, if this hook was invoked by `MAX` calculation
      if (maxPlanCalculated.current) {
        maxPlanCalculated.current = false
        return true
      }
      // trigger txPlan calculation only in 'addAsset'
      return rest.screenState !== 'details' || !(await getAreDataValid(values))
    },
    fn: async ({values}) => {
      const {assets} = values
      const nativeAmount = findNativeAsset(assets)?.amount || '0'
      const tokensBundle = findTokens(assets).map(({tokenId, amount}) => ({
        amount: commonParseTokenAmount(
          amount,
          metadataById[tokenId as CardanoTokenId]!.decimals,
        ),
        token: tokenFromTokenId(tokenId as CardanoTokenId),
      }))

      const lovelaceAmount = adaToLovelaces(nativeAmount)
      return await fetchAdaTxPlan.mutateAsync({
        accountInfo: fromAccount as CardanoAccountInfo,
        addressTo: formAddress,
        amount: lovelaceAmount,
        tokenAmounts: tokensBundle,
      })
    },
  })

  const onMaxAdaAmount = async (fieldName: string) => {
    if (!isTxPlanCalcFinished) return

    try {
      maxPlanCalculated.current = true

      const plan = await fetchMaxAdaTxPlan.mutateAsync({
        accountInfo: fromAccount as CardanoAccountInfo,
        addressTo: formAddress,
      })
      const maxSendableAmount = calculateAdaSentOut(plan)
      formikProps.setFieldValue(fieldName, lovelacesToAda(maxSendableAmount))
      setTxPlan({data: plan, error: null})
    } catch (err) {
      maxPlanCalculated.current = false
      setTxPlan({data: null, error: err})
    }
  }

  const onSign = async () => {
    assert(txPlan.data != null, 'Tx plan must be defined')

    return await signProps.mutateAsyncSilent({
      accountId: values.accountId as AccountId,
      txPlan: txPlan.data,
    })
  }

  const onSubmit = async (signedTx: CardanoSignedTx) => {
    assert(txPlan.data != null, 'Tx plan must be defined')

    return await submitProps.mutateAsyncSilent({
      signedTx,
      accountId: fromAccount.id,
      txPlan: txPlan.data,
    })
  }

  const humanReadableToAddressName = (() => {
    if (formikProps.values.toAddressNameServiceState.status === 'valid') {
      const toAddress = formikProps.values.toAddress
      if (isAdaHandle(toAddress)) {
        return `${t('ADA Handle')}: ${toAddress}`
      } else if (isAdaDomain(toAddress)) {
        return `${t('ADA Domain')}: ${toAddress}`
      }
    }
    return undefined
  })()

  return (
    <WithCardanoExtras
      extras={{txPlan: txPlan.data, txHash: signProps.data?.txHash}}
    >
      <SendModalContent
        requiredAssets={{
          list: [{type: 'native'}],
          message: t('amount_ada_tooltip'),
        }}
        {...{
          ...rest,
          type: 'multiAsset',
          toAddress: formAddress,
          formikProps,
          blockchain,
          accounts,
          metadataById,
          onDataChange,
          tokenAmounts,
          feeProps: {
            fee: txPlan.data ? txPlan.data.fee : undefined,
            isLoading: !isTxPlanCalcFinished,
          },
          disabled: !!txPlan.error,
          DeviceReadyState,
          signProps: {
            ...signProps,
            mutateAsyncSilent: onSign,
          },
          submitProps: {
            ...submitProps,
            mutateAsyncSilent: onSubmit,
          },
          humanReadableToAddressName,
        }}
        ErrorContent={
          txPlan.error ? (
            <DefaultErrorCard text={cardanoTxPlanErrorToString(txPlan.error)} />
          ) : undefined
        }
        addressNameService={{
          data: isAdaHandle(values.toAddress)
            ? resolveAdaHandle.data || null
            : isAdaDomain(values.toAddress)
              ? resolveAdaDomain.data || null
              : null,
          isLoading: resolveAdaHandle.isPending || resolveAdaDomain.isPending,
          onSubmit: (address: string) =>
            isAdaHandle(address)
              ? resolveAdaHandle.mutateAsyncSilent(address)
              : resolveAdaDomain.mutateAsyncSilent(address),
          shouldEnableNameService: (address: string) =>
            isAdaHandle(address) || isAdaDomain(address),
          getValidateSuccessLabel: (address: string) =>
            isAdaHandle(address)
              ? t('ADA Handle resolved')
              : t('ADA Domain resolved'),
          getValidateLabel: (address: string) =>
            isAdaHandle(address)
              ? t('Resolve ADA Handle')
              : t('Resolve ADA Domain'),
        }}
        getAssetFieldProps={(asset, {errorMessage, name}) => {
          const isNative = asset.type === 'native'
          const disabled =
            isNative && isNativeAmountFieldReadOnly && !isNativeSendOnly
          return {
            inputEndAdornment: disabled ? (
              <TextButton
                color="text.primary"
                label={t('EDIT')}
                onClick={() => setIsNativeAmountFieldReadOnly(false)}
              />
            ) : undefined,
            disabled,
            disableDelete: asset.type === 'native',
            maxAmountOptions:
              asset.type === 'native'
                ? {
                    // TODO make MAX button take into account tokens https://vacuum.atlassian.net/browse/ADLT-1342
                    disabled:
                      !addressValid ||
                      values.assets.some(({type}) => type === 'token'),
                    onMaxAmount: () => onMaxAdaAmount(name),
                    helperText: addressValid
                      ? t('Can not use MAX button when sending tokens.')
                      : t(
                          'Recipient address must be filled before using MAX button.',
                        ),
                  }
                : {
                    onMaxAmount: () => {
                      const {decimals} = metadataById[asset.tokenId]!
                      const max = tokenAmounts.find(
                        ({token}) => token.id === asset.tokenId,
                      )?.amount
                      assert(!!max)
                      setFieldValue(
                        name,
                        commonFormatTokenAmount(max, decimals),
                      )
                    },
                  },
            helperText: (
              <>
                {!!errorMessage &&
                  (errorMessage === (minAmountValidationErrorKey as string) ? (
                    <>
                      {t('amount_too_low')}{' '}
                      {!(values.assets.length > 1) &&
                        t('minimal_ADA_amount', {
                          minAmount: formatNativeAmount(blockchain, minAda),
                        })}
                    </>
                  ) : (
                    errorMessage
                  ))}
                {asset.type === 'native' &&
                  isMultiAssetSend &&
                  minAda?.isGreaterThan(0) && (
                    <MinAmountMessage
                      onClick={() => {
                        formikProps.setFieldValue(name, lovelacesToAda(minAda))
                      }}
                      minAmount={formatNativeAmount(blockchain, minAda)}
                      isError={!!errorMessage}
                      {...{blockchain}}
                    />
                  )}
              </>
            ),
            inputProps:
              asset.type === 'native'
                ? {
                    'rtl-data-test-id': detailsScreenTestIds.amountField,
                  }
                : undefined,
            rowEndAdornment: isNative
              ? {
                  type: 'custom',
                  content: (
                    <WithTooltip title={t('amount_ada_tooltip')}>
                      <HelpOutline fontSize="small" sx={{m: 1}} />
                    </WithTooltip>
                  ),
                }
              : undefined,
          }
        }}
        renderDetails={(DefaultDetailsScreen) => (
          <>
            {renderSubmitGuard?.({formikProps, items: [submitGuardMeta]})}
            {DefaultDetailsScreen}
            {/* Disable UI when using MAX, to have feedback for user & avoid mixing
            txPlan recalculations.
            We handle error elsewhere, used to ensure loading */}
            <MutationGuard {...fetchMaxAdaTxPlan} error={null} />
          </>
        )}
        renderAddressMessage={(address) =>
          isValidCardanoPaymentAddress(address) &&
          isScriptAddress(address as CardanoAddress) ? (
            <Alert
              severity="warning"
              rtl-data-test-id="cardano-script-address-warning"
            >
              <>
                {t(
                  'Sending ADA/tokens to script addresses may result in locked up funds.',
                )}
              </>
            </Alert>
          ) : null
        }
      />
    </WithCardanoExtras>
  )
}

type MinAmountMessageProps = {
  onClick: () => void
  isError: boolean
  minAmount: string
}
const MinAmountMessage = ({
  onClick,
  isError,
  minAmount,
}: MinAmountMessageProps) => {
  const {t} = useTranslation()

  return (
    <Box color={!isError ? 'text.secondary' : 'inherit'}>
      <Trans
        i18nKey="minimal_ADA_amount_button"
        t={t}
        components={{
          Button: <MinAmountButton onClick={onClick} />,
        }}
        values={{
          minAmount,
        }}
      />
    </Box>
  )
}
