import {Box} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {Button} from '../../components'

type RejectButtonProps = {
  onClick: () => unknown
  variant?: 'error' | 'neutral'
  title?: string
  size?: 'small' | 'medium'
}

export function RejectButton({
  title,
  variant = 'neutral',
  ...rest
}: RejectButtonProps) {
  const {t} = useTranslation()
  return (
    <Button
      fullWidth
      variant={variant === 'neutral' ? 'outlined' : 'contained'}
      color={variant === 'neutral' ? 'default' : 'error'}
      textTransform="none"
      {...rest}
    >
      {title || t('Reject')}
    </Button>
  )
}

type PrimaryActionButtonProps = {
  title: string
  disabled?: boolean
  onClick?: () => void
}

export function PrimaryActionButton({
  title,
  disabled,
  onClick,
}: PrimaryActionButtonProps) {
  return (
    <Button
      fullWidth
      variant="contained"
      textTransform="none"
      color="primary"
      {...{onClick, disabled}}
    >
      {title}
    </Button>
  )
}

export type ActionButtonsType = {
  leftButton: React.ReactNode
  rightButton: React.ReactNode
  marginTop?: number
}

export function ActionButtons({
  leftButton,
  rightButton,
  marginTop = 2,
}: ActionButtonsType) {
  return (
    <Box display="flex" mt={marginTop}>
      <Box flex="1">{leftButton}</Box>
      <Box width={12} />
      <Box flex="1">{rightButton}</Box>
    </Box>
  )
}
