import {
  Image as ImageIcon,
  SwapHoriz as SendReceiveIcon,
  BarChart as StakeIcon,
} from '@mui/icons-material'
import {Grid, Box, Typography} from '@mui/material'
import type Icon from '@mui/material/SvgIcon'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {SideBarLayout, Button} from '../../components'

import {useCommonProfileStyles, CreateProfileTimeline} from './common'

type Props = {
  onSubmit: () => unknown
  type: 'created' | 'restored'
}

export default function ProfileCreatedPage({onSubmit, type}: Props) {
  const {t} = useTranslation()
  const commonClasses = useCommonProfileStyles()

  return (
    <SideBarLayout
      left={
        type === 'created' ? <CreateProfileTimeline step="done" /> : undefined
      }
      right={
        <Box className={commonClasses.wrapper}>
          <Typography variant="h5">
            {type === 'created'
              ? t('Done! Your wallet is now ready to use.')
              : t(
                  'Congratulations! Your wallet has been successfully restored.',
                )}
          </Typography>

          <Typography
            className={commonClasses.headerSubtitle}
            variant="body1"
            color="textSecondary"
          >
            {t('You can now do the following:')}
          </Typography>

          <Box mt={3}>
            <InfoRow
              Icon={SendReceiveIcon}
              label={t('Send, receive and exchange assets')}
            />
            <Box mt={2}>
              <InfoRow
                Icon={StakeIcon}
                label={t('Stake your assets and earn passive income')}
              />
            </Box>
            <Box mt={2}>
              <InfoRow
                Icon={ImageIcon}
                label={t('Use the in-app NFT gallery and DEX')}
              />
            </Box>
          </Box>

          <Grid container>
            <Grid item xs={12}>
              <Button
                className={commonClasses.submitButton}
                textTransform="none"
                fullWidth
                variant="contained"
                color="primary"
                onClick={onSubmit}
              >
                {t('Go to Wallet')}
              </Button>
            </Grid>
          </Grid>
        </Box>
      }
    />
  )
}

type InfoRowProps = {
  Icon: typeof Icon
  label: string
}

function InfoRow({Icon, label}: InfoRowProps) {
  return (
    <Box display="flex">
      <Typography color="textSecondary">
        <Icon color="inherit" />
      </Typography>
      <Box pl={2}>
        <Typography variant="body1">{label}</Typography>
      </Box>
    </Box>
  )
}
