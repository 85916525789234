import {
  ExitToApp,
  AccountCircle,
  Settings as SettingsIcon,
  Warning as WarningIcon,
  Feed as FeedIcon,
} from '@mui/icons-material'
import {
  Grid,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Box,
  Popover,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'

import {FormattedMetamaskProfileName} from 'src/components/formatting/FormattedMetamaskProfileName'

import {useCurrentProfileMeta} from '../../appStorage'
import {
  QueryGuard,
  WideReloadButton,
  Icon,
  WithTooltip,
  NaError,
  CardanoCongestionIssues,
  TestnetIndicator,
} from '../../components'
import {FormattedWeb3AuthProfileName} from '../../components/formatting'
import config from '../../config'
import {TOPBAR_WIDTH, externalLinks} from '../../constants'
import {routeTo} from '../../router'
import {useCurrentLoginInfo} from '../../store/auth'
import {logout} from '../../utils/general'
import {useRefetch} from '../../utils/refetch'
import {TotalAssetValue} from '../portfolio/TotalAssetValue'
import {
  commonTopBarPopUpStyles,
  TransactionsInfo,
} from '../transaction/transactionTracking/TransactionsInfo'

import {WarningsIndicator} from './warningsIndicator'

type TopBarProps = {
  onNotificationsClick: () => void
}

export default function TopBar(props: TopBarProps) {
  const {t} = useTranslation()
  const classes = useStyles()
  const {isRefetching, refetch} = useRefetch()

  return (
    <Grid container justifyContent="space-between" className={classes.wrapper}>
      <Grid item>
        <Box display="flex">
          <Icon sx={{height: 'auto'}} type="appLogo" exactSize={86} />
          <Box ml={3} />
          <TotalAssetValue />
        </Box>
      </Grid>
      <Grid item>
        <Grid container alignItems="center">
          <TransactionsInfo />
          {enableWarningPopover && <WarningPopover />}
          {config.isTestnet && (
            <Grid mr={0.5} item>
              <TestnetIndicator />
            </Grid>
          )}
          <Grid item className={classes.reload}>
            <WideReloadButton onReload={refetch} isLoading={isRefetching} />
          </Grid>
          <Grid item>
            <WarningsIndicator />
          </Grid>
          <Grid item>
            <IconButton
              href={externalLinks.nufiHelpLink}
              target="_blank"
              size="large"
            >
              <WithTooltip title={<>{t('Visit Support Page')}</>}>
                <Icon type="helpIcon" />
              </WithTooltip>
            </IconButton>
          </Grid>
          <Grid item>
            <UserMenu {...props} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

function WarningPopover() {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }
  const open = Boolean(anchorEl)

  return (
    <div>
      <IconButton color="warning" onClick={handleClick}>
        <WarningIcon fontSize="inherit" />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          className: classes.popover,
        }}
      >
        <CardanoCongestionIssues />
      </Popover>
    </div>
  )
}

const enableWarningPopover = false

function UserMenu({onNotificationsClick}: TopBarProps) {
  const {t} = useTranslation()
  const classes = useStyles()
  const currentProfileMetaQuery = useCurrentProfileMeta()
  const currentLoginInfo = useCurrentLoginInfo()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (callback?: () => void) => {
    setAnchorEl(null)
    callback && callback()
  }

  return (
    <>
      <IconButton
        onClick={handleMenu}
        size="large"
        data-test-id="navigation-account-menu"
      >
        <AccountCircle className={classes.userAccountIcon} />
      </IconButton>
      <Menu
        elevation={1}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => handleClose()}
        PaperProps={{
          sx: commonTopBarPopUpStyles,
        }}
      >
        <Box
          p={2}
          pt={1}
          border={1}
          borderLeft={0}
          borderRight={0}
          borderTop={0}
          borderColor="divider"
          display="flex"
          justifyContent="center"
        >
          <QueryGuard
            {...currentProfileMetaQuery}
            ErrorElement={<NaError error={t('Could not load wallet name')} />}
          >
            {(data) => {
              switch (currentLoginInfo.loginType) {
                case 'web3Auth':
                  return (
                    <FormattedWeb3AuthProfileName
                      web3AuthUserInfo={currentLoginInfo.user}
                    />
                  )
                case 'metamask':
                  return (
                    <FormattedMetamaskProfileName profileName={data.name} />
                  )

                default:
                  return (
                    <Typography align="center" variant="h6">
                      {data.name}
                    </Typography>
                  )
              }
            }}
          </QueryGuard>
        </Box>
        <MenuItem
          onClick={() => handleClose()}
          component={Link}
          to={routeTo.settings.index}
        >
          <RowIcon
            text={t('Settings')}
            Icon={<SettingsIcon fontSize="small" />}
          />
        </MenuItem>
        <MenuItem onClick={() => handleClose(onNotificationsClick)}>
          <RowIcon text={t('News')} Icon={<FeedIcon fontSize="small" />} />
        </MenuItem>
        <MenuItem
          onClick={() => logout()}
          data-test-id="navigation-account-menu-logout"
        >
          <RowIcon text={t('Logout')} Icon={<ExitToApp fontSize="small" />} />
        </MenuItem>
      </Menu>
    </>
  )
}

type RowIconProps = {
  text: string
  Icon: React.ReactNode
}

function RowIcon({text, Icon}: RowIconProps) {
  const classes = useStyles()

  return (
    <>
      <ListItemIcon classes={{root: classes.ListItemIcon}}>{Icon}</ListItemIcon>
      <ListItemText primary={text} />
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    height: TOPBAR_WIDTH,
    width: '100%',
    background: theme.palette.background.paper,
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 4),
  },
  userAccountIcon: {
    color: theme.palette.action.active,
  },
  reload: {
    color: theme.palette.common.white,
  },
  ListItemIcon: {
    minWidth: 35,
  },
  popover: {
    padding: theme.spacing(1),
  },
}))
