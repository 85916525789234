/*
eslint-disable @typescript-eslint/no-non-null-assertion
*/

import React, {useState} from 'react'
import {useHistory, useLocation} from 'react-router-dom'

import {useSetRegularLoggedIn} from 'src/features/login'

import {useCreateAndInitLocalProfile} from '../../appStorage'
import type {
  ProfileName,
  ProfilePassword,
  ProfileMetadata,
} from '../../appStorage'
import {MutationGuard} from '../../components'
import {routeTo} from '../../router'
import {trackProfileAction, useStepTracking} from '../../tracking'
import {assert, safeAssertUnreachable} from '../../utils/assertion'
import type {Blockchain, Mnemonic} from '../../wallet'

import {ChooseBlockchainsPage} from './ChooseBlockchains'
import ProfileCreatedPage from './ProfileCreatedPage'
import ProfileNamePage from './ProfileNamePage'
import ProfilePasswordPage from './ProfilePasswordPage'
import RecoveryPhraseConfirmPage from './RecoveryPhraseConfirmPage'
import {
  SetupRecoveryPhrasePage,
  SkipRecoveryPhrasePage,
} from './RecoveryPhrasePage'
import type {ProfileCreationStep, WalletType} from './types'
import WalletTypePage from './WalletTypePage'

type Props = {
  profiles: Array<ProfileMetadata>
}

type LocationState = {isHwFlow?: boolean} | undefined

export const useRouteToCreateProfile = () => {
  const history = useHistory()
  return (isHwFlow: boolean) => {
    const locationState: LocationState = {isHwFlow}
    // https://stackoverflow.com/questions/52328891/when-using-hashrouter-in-reactjs-this-props-location-state-is-undefined-on-page
    // !!! Always pass `location.state` via {state: yourState} syntax otherwise it is `undefined` in extension (HashRouter)
    history.push({pathname: routeTo.createProfile, state: locationState})
  }
}

const CreateProfilePage = ({profiles}: Props) => {
  const isHwWalletFlow = !!useLocation<LocationState>().state?.isHwFlow

  const [profileCreationStep, setProfileCreationStep] =
    useState<ProfileCreationStep>(isHwWalletFlow ? 'name' : 'walletType')
  const [walletType, setWalletType] = useState<WalletType | undefined>(
    isHwWalletFlow ? 'hw' : undefined,
  )
  const [name, setName] = useState<ProfileName>()
  const [password, setPassword] = useState<ProfilePassword>()
  const [mnemonic, setMnemonic] = useState<Mnemonic>()
  const [isMnemonicActivated, setIsMnemonicActivated] = useState(false)
  const [plainTextMode, setPlainTextMode] = useState<boolean>(false)
  useStepTracking(profileCreationStep)

  const createProfile = useCreateAndInitLocalProfile()
  const setLoggedIn = useSetRegularLoggedIn()
  const history = useHistory()

  const onSubmitWalletType = (walletType: WalletType) => {
    setWalletType(walletType)
    setProfileCreationStep('name')
  }

  const onSubmitProfileName = (name: ProfileName) => {
    setName(name)
    setProfileCreationStep('password')
  }

  const onSubmitProfilePassword = (password: ProfilePassword) => {
    setPassword(password)
    setProfileCreationStep(
      walletType === 'hw' ? 'optionallySkipMnemonic' : 'writeDownMnemonic',
    )
  }

  const onGoToMnemonicSetup = () => {
    setProfileCreationStep('writeDownMnemonic')
  }

  const onWriteDownMnemonic = (mnemonic: Mnemonic) => {
    setMnemonic(mnemonic)
    setProfileCreationStep('confirmMnemonic')
  }

  const onCreateProfile = async (
    mnemonic: Mnemonic,
    isMnemonicActivated: boolean,
    enabledBlockchains: Blockchain[],
  ) => {
    const isHwUser = walletType === 'hw'

    if (name && password && walletType && mnemonic) {
      await createProfile.mutateAsyncSilent({
        profileName: name,
        password,
        mnemonic,
        isHwUser,
        isMnemonicActivated,
        loginType: 'password',
        enabledBlockchains,
      })
      trackProfileAction('create_new_profile')

      if (walletType === 'hw') {
        await onSubmitSuccess()
      } else {
        setProfileCreationStep('success')
      }
    }
  }

  const onConfirmMnemonic = async () => {
    assert(mnemonic != null)
    setIsMnemonicActivated(true)
    walletType === 'hw'
      ? onCreateProfile(mnemonic, true, [])
      : setProfileCreationStep('chooseBlockchains')
  }

  const onSkipMnemonic = async (mnemonic: Mnemonic) => {
    walletType === 'hw'
      ? onCreateProfile(mnemonic, false, [])
      : setProfileCreationStep('chooseBlockchains')
  }

  const onChooseBlockchains = async (blockchains: Blockchain[]) => {
    assert(mnemonic != null)
    await onCreateProfile(mnemonic, isMnemonicActivated, blockchains)
  }

  const onSubmitSuccess = () => {
    setLoggedIn()
    if (walletType === 'hw') {
      history.replace(routeTo.portfolio.accounts.newlyCreatedHwWallet)
    } else {
      history.replace(routeTo.portfolio.assets.index)
    }
  }

  const onNameBack = () => {
    isHwWalletFlow ? history.goBack() : setProfileCreationStep('walletType')
  }
  const onPasswordBack = () => setProfileCreationStep('name')
  const onSkipMnemonicBack = () => setProfileCreationStep('password')
  const onWriteDownMnemonicBack = () =>
    setProfileCreationStep(
      walletType === 'hw' ? 'optionallySkipMnemonic' : 'password',
    )
  const onConfirmMnemonicBack = () =>
    setProfileCreationStep('writeDownMnemonic')

  const onChooseBlockchainsBack = () =>
    setProfileCreationStep('writeDownMnemonic')

  return (
    <>
      {(() => {
        switch (profileCreationStep) {
          case 'walletType':
            return <WalletTypePage onSubmit={onSubmitWalletType} />
          case 'name':
            return (
              <ProfileNamePage
                onSubmit={onSubmitProfileName}
                onBack={onNameBack}
                walletType={walletType!}
                {...{
                  name,
                  profiles,
                }}
              />
            )
          case 'password':
            return (
              <ProfilePasswordPage
                onSubmit={onSubmitProfilePassword}
                onBack={onPasswordBack}
                walletType={walletType!}
                {...{password}}
              />
            )
          case 'optionallySkipMnemonic':
            return (
              <SkipRecoveryPhrasePage
                onSkipMnemonicSetup={onSkipMnemonic}
                onGoToMnemonicSetup={onGoToMnemonicSetup}
                onBack={onSkipMnemonicBack}
                walletType={walletType!}
              />
            )
          case 'writeDownMnemonic':
            return (
              <SetupRecoveryPhrasePage
                onSubmit={onWriteDownMnemonic}
                onBack={onWriteDownMnemonicBack}
                walletType={walletType!}
                {...{
                  profiles,
                  mnemonic,
                  setPlainTextMode,
                  plainTextMode,
                }}
              />
            )
          case 'confirmMnemonic':
            return (
              <RecoveryPhraseConfirmPage
                onSubmit={onConfirmMnemonic}
                onBack={onConfirmMnemonicBack}
                mnemonic={mnemonic!}
                walletType={walletType!}
                plainTextMode={plainTextMode}
              />
            )
          case 'chooseBlockchains':
            return (
              <ChooseBlockchainsPage
                onSubmit={onChooseBlockchains}
                onBack={onChooseBlockchainsBack}
              />
            )
          case 'success':
            return (
              <ProfileCreatedPage type="created" onSubmit={onSubmitSuccess} />
            )
          default:
            return safeAssertUnreachable(profileCreationStep)
        }
      })()}
      <MutationGuard {...createProfile} />
    </>
  )
}

export default CreateProfilePage
