export const createReducer: CreateReducer =
  () => (caseReducers) => (state, action) =>
    caseReducers[action.type](state, action.payload)

type CaseReducer<TState, TPayload> = (
  state: TState,
  payload: TPayload, // simplification that departs from flux where the whole action is passed
) => TState

type StateReducer<TState, TAction extends {type: unknown; payload: unknown}> = (
  state: TState,
  action: TAction,
) => TState

type CreateReducer = <TState>() => <TPayloads extends object>(caseReducers: {
  [A in keyof TPayloads]: CaseReducer<TState, TPayloads[A]>
}) => StateReducer<
  TState,
  {[A in keyof TPayloads]: {type: A; payload: TPayloads[A]}}[keyof TPayloads]
>

export type InferAction<T> = T extends (
  state: any, // eslint-disable-line @typescript-eslint/no-explicit-any
  action: infer P extends {type: unknown; payload: unknown},
) => any // eslint-disable-line @typescript-eslint/no-explicit-any
  ? P
  : never
