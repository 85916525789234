import {
  AccountBalanceWallet as CreateNewWalletIcon,
  RestorePage as RestoreWalletIcon,
} from '@mui/icons-material'
import {
  Box,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Grid,
  Typography,
  Select,
  Divider,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import type {FormikHelpers} from 'formik'
import {Formik} from 'formik'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'

import {
  useLoginToProfile,
  useAutoLogin,
  useAvailableExternalLoginProviders,
} from 'src/features/login'

import type {
  LocalProfileId,
  ProfilePassword,
  ProfileMetadata,
} from '../../appStorage'
import {
  useLastLoggedInLocalProfileId,
  useVerifyLocalProfilePassword,
  filterVisibleProfiles,
} from '../../appStorage'
import {
  MutationGuard,
  QueryGuard,
  SideBarLayout,
  Button,
  HelpSectionLink,
  ActionCard,
  CenteredError,
} from '../../components'
import {LogoutReasonDialog} from '../../components/AutoLogout'
import {routeTo} from '../../router'
import {trackProfileAction} from '../../tracking'
import type {SelectChangeFn} from '../../utils/form'

import {ExternalProvidersWrapper, useCommonProfileStyles} from './common'
import {LoginWithMetamaskButton} from './metamaskLogin/LoginWithMetamaskButton'
import {Web3AuthLoginApp} from './Web3AuthLogin'

type LoginPageProps = {
  profiles: Array<ProfileMetadata>
}

export default function LoginPage({profiles}: LoginPageProps) {
  const lastLoggedInLocalProfileId = useLastLoggedInLocalProfileId()
  const {t} = useTranslation()
  return (
    <QueryGuard
      {...lastLoggedInLocalProfileId}
      ErrorElement={<CenteredError error={t('Could not load login info')} />}
      loadingVariant="centered"
    >
      {(lastUsedProfileId) => (
        <LoginContentPage
          {...{lastLoggedInLocalProfileId: lastUsedProfileId, profiles}}
        />
      )}
    </QueryGuard>
  )
}

type LoginContentPageProps = {
  profiles: Array<ProfileMetadata>
  lastLoggedInLocalProfileId: LocalProfileId | null
}

function LoginContentPage({
  profiles,
  lastLoggedInLocalProfileId,
}: LoginContentPageProps) {
  const login = useLoginToProfile()
  const history = useHistory()
  const classes = useStyles()
  const commonStyles = useCommonProfileStyles()
  const {t} = useTranslation()

  const goToRestoreSection = () => {
    history.push(routeTo.restoreProfile)
  }

  const goToCreateProfileSection = () => {
    history.push(routeTo.createProfile)
  }

  const onLogin = async (values: {
    profileId: LocalProfileId
    password: ProfilePassword
  }) => {
    await login.onLogin(values)
    trackProfileAction('login')
  }

  const {metamask} = useAvailableExternalLoginProviders()

  return (
    <>
      <SideBarLayout
        right={
          <Box className={clsx(commonStyles.wrapper, classes.loginSpacing)}>
            <Typography variant="h5">{t('Log in to wallet')}</Typography>
            <LoginForm {...{profiles, lastLoggedInLocalProfileId, onLogin}} />
            {/* Passing error={null} as we handle exception as an incorrect password */}
            <MutationGuard {...login} error={null} />
            <ExternalProvidersWrapper>
              <>{metamask && <LoginWithMetamaskButton />}</>

              <Web3AuthLoginApp />
            </ExternalProvidersWrapper>

            <Divider />
            <Box>
              <Grid
                container
                spacing={1}
                className={classes.actionCardsSpacing}
              >
                <Grid item xs={6}>
                  <ActionCard
                    text={t('Create New Wallet')}
                    type="normal"
                    cardType="rectangular"
                    onClick={goToCreateProfileSection}
                    icon={<CreateNewWalletIcon color="primary" />}
                  />
                </Grid>
                <Grid item xs={6}>
                  <ActionCard
                    text={t('Restore Wallet')}
                    type="normal"
                    cardType="rectangular"
                    onClick={goToRestoreSection}
                    icon={<RestoreWalletIcon color="primary" />}
                  />
                </Grid>
              </Grid>
              <HelpSectionLink />
            </Box>
          </Box>
        }
        showAnnouncement
      />
      <LogoutReasonDialog />
    </>
  )
}

type LoginFormProps = LoginContentPageProps & {
  onLogin: (args: {
    profileId: LocalProfileId
    password: ProfilePassword
  }) => Promise<unknown>
  submitButtonProps?: {
    message?: string
    textTransform?: 'none' | 'uppercase'
    renderControls?: (submitButton: React.ReactNode) => React.ReactNode
  }
  formEndContent?: React.ReactNode
}

export function LoginForm({
  profiles,
  lastLoggedInLocalProfileId,
  onLogin,
  submitButtonProps,
  formEndContent,
}: LoginFormProps) {
  const verifyPassword = useVerifyLocalProfilePassword()
  const {t} = useTranslation()
  const classes = useStyles()

  type FormData = {password: string; profileId: string}
  type FormField = keyof FormData

  const visibleProfiles = filterVisibleProfiles(profiles)

  const defaultProfile =
    visibleProfiles.find((p) => p.localProfileId === lastLoggedInLocalProfileId)
      ?.localProfileId || visibleProfiles[0]!.localProfileId

  const initialValues: FormData = {password: '', profileId: defaultProfile}

  const onSubmit = async (
    values: FormData,
    {setErrors}: FormikHelpers<FormData>,
  ) => {
    try {
      setErrors({password: undefined})

      const passwordVerified = await verifyPassword.mutateAsync({
        profileId: values.profileId as LocalProfileId,
        password: values.password as ProfilePassword,
      })

      if (!passwordVerified) {
        setErrors({password: t('Incorrect password')})
        return
      }

      await onLogin({
        profileId: values.profileId as LocalProfileId,
        password: values.password as ProfilePassword,
      })
    } catch (err) {
      setErrors({password: t('Unexpected error.')})
    }
  }

  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues}>
      {(formikProps) => {
        const {handleChange, handleSubmit, values, isSubmitting, errors} =
          formikProps

        const SubmitButton = (
          <Button
            disabled={isSubmitting}
            variant="contained"
            type="submit"
            color="primary"
            fullWidth
            textTransform={submitButtonProps?.textTransform || 'uppercase'}
          >
            {submitButtonProps?.message || t('Log in')}
          </Button>
        )

        return (
          <>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel>{t('Choose Wallet')}</InputLabel>
                    <Select
                      label={t('Choose Wallet')}
                      value={values.profileId}
                      onChange={
                        handleChange<FormField>(
                          'profileId',
                        ) as SelectChangeFn<unknown>
                      }
                    >
                      {visibleProfiles.map(({localProfileId, name}) => (
                        <MenuItem key={localProfileId} value={localProfileId}>
                          <Grid container justifyContent="space-between">
                            <Typography>{name}</Typography>
                          </Grid>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    value={values.password}
                    onChange={handleChange<FormField>('password')}
                    label={t('Password')}
                    type="password"
                    variant="outlined"
                    fullWidth
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12}>
                  {errors.password && (
                    <Typography color="error">{errors.password}</Typography>
                  )}
                </Grid>
                {formEndContent && (
                  <Grid item xs={12} className={classes.formEndContentWrapper}>
                    {formEndContent}
                  </Grid>
                )}
                <Grid item xs={12}>
                  {submitButtonProps?.renderControls
                    ? submitButtonProps.renderControls(SubmitButton)
                    : SubmitButton}
                </Grid>
              </Grid>
            </form>
          </>
        )
      }}
    </Formik>
  )
}

export function AutoLogin() {
  const autoLogin = useAutoLogin()
  return <MutationGuard {...autoLogin} />
}

const useStyles = makeStyles((theme) => ({
  actionCardsSpacing: {
    marginBottom: theme.spacing(3),
  },
  formEndContentWrapper: {
    // Seems that we can not override "padding" introduced by <Grid spacing />
    // without using "important"
    paddingTop: '0!important',
  },
  loginSpacing: {
    '& > :not(:first-child)': {
      marginTop: theme.spacing(2),
    },
  },
}))
