import {Box} from '@mui/material'
import React from 'react'
import {Trans, useTranslation} from 'react-i18next'

export const ChangeAccountAfterInitMessage = () => {
  const {t} = useTranslation()
  return (
    <Trans
      i18nKey="disconnect_dapp_hint_wallet_connect"
      t={t}
      components={{
        bold: <Box component="span" fontWeight="fontWeightMedium" />,
      }}
    />
  )
}

export const ChangeAccountBeforeInitMessage = () => {
  const {t} = useTranslation()

  return (
    <Trans
      i18nKey="disconnect_dapp_initial_hint_wallet_connect"
      t={t}
      components={{
        bold: <Box component="span" fontWeight="fontWeightMedium" />,
      }}
    />
  )
}
