import * as wcUtils from '@walletconnect/utils'
import {stringify} from 'query-string'

import config from '../config'
import {POPUP_HEIGHT, POPUP_WIDTH} from '../dappConnector/constants'
import {routeTo} from '../router'
import {safeAssertUnreachable} from '../utils/assertion'

import type {WalletConnectBlockchain} from './types'

export function openConnectorWindow(uri: string): Window | null {
  const {topic} = wcUtils.parseUri(uri)
  const nufiOrigin = window.location.origin
  const height = POPUP_HEIGHT
  const width = POPUP_WIDTH
  const top = window.screenTop + 50
  const left = window.screenLeft + window.screen.width - width - 50
  return window.open(
    `${nufiOrigin}${routeTo.walletConnect.connector.window}?${stringify({
      uri,
    })}`,
    `nufiWalletConnectWindow${topic}}`,
    `width=${width},height=${height},top=${top},left=${left}`,
  )
}

export const isEnabledWalletConnectBlockchain = (
  blockchain: WalletConnectBlockchain,
): boolean => {
  switch (blockchain) {
    case 'cardano':
      return true
    case 'solana':
      return config.isWalletConnectEnabledSolana
    case 'ethereum':
    case 'optimism':
    case 'polygon':
    case 'milkomedaC1':
    case 'arbitrumOne':
    case 'base':
      return config.isWalletConnectEnabledEvm
    default:
      return safeAssertUnreachable(blockchain)
  }
}

// if page wasn't visited by redirection, most likely the user
// opened it e.g. by clicking the link in browser history
// in which case it would most likely not work so we just redirect
// to the base wallet page
export const isWalletConnectRedirect = () =>
  window.document.referrer || window.history.length === 1

export const redirectToRootIfNotWalletConnectRedirect = () => {
  if (!isWalletConnectRedirect()) {
    window.location.href = window.origin
  }
}
