import type {FormikHelpers} from 'formik'
import {Formik, useFormikContext} from 'formik'
import React from 'react'
import {useTranslation} from 'react-i18next'
import * as yup from 'yup'

import {CenteredError, QueryGuard} from '../../../../../../components'
import type {AccountId} from '../../../../../../types'
import {assert, safeAssertUnreachable} from '../../../../../../utils/assertion'
import {useGetBlockchainName} from '../../../../../../utils/translations'
import {
  useGetAccountTokens,
  useImportEvmTokens,
} from '../../../../../../wallet/evm'
import type {
  ChecksummedEvmAddress,
  EvmContractAddress,
  EvmTokenAmount,
  EvmErc20Metadata,
  EvmTokenName,
  EvmTokenTicker,
  GetAccountTokens,
  GetErc20TokenList,
  ImportEvmTokens as ImportEvmTokensFn,
  GetErc20TokenBalance,
} from '../../../../../../wallet/evm'
import {getEvmTokenId} from '../../../../../../wallet/evm/sdk/utils'
import type {
  EvmAccountInfo,
  EvmBlockchain,
} from '../../../../../../wallet/evm/types'
import {ensureAccountById} from '../../../../../../wallet/utils/common'
import {ImportTokenModalHeader} from '../ImportTokenUtils'

import {DetailsScreen} from './DetailsScreen'
import {TokenImportResultScreen} from './ResultScreen'
import {
  useDetailsSchema,
  useEvmImportTokenScreenState,
  INITIAL_CUSTOM_FIELD_VALUES,
} from './schema'
import type {
  ImportEvmTokenSchema,
  DetailsSchema,
  EvmTokenImportActiveScreen,
} from './schema'
import {SummaryScreen} from './SummaryScreen'

type ImportEvmTokenProps<TBlockchain extends EvmBlockchain> = {
  blockchain: TBlockchain
  accounts: EvmAccountInfo<TBlockchain>[]
  onClose: () => void
  onBack: () => void
  accountId: AccountId
  getErc20TokenList?: GetErc20TokenList
  getAccountTokens?: GetAccountTokens
  importEvmTokens?: ImportEvmTokensFn
  getErc20TokenBalance?: GetErc20TokenBalance
}

export const ImportEvmTokens = <TBlockchain extends EvmBlockchain>({
  blockchain,
  accountId,
  accounts,
  getAccountTokens,
  ...props
}: ImportEvmTokenProps<TBlockchain>) => {
  const {t} = useTranslation()
  const accountInfo = ensureAccountById(
    accounts,
    accountId,
  ) as EvmAccountInfo<TBlockchain>
  const accountTokensQuery = useGetAccountTokens(
    blockchain,
    accountInfo,
    getAccountTokens,
    true,
  )
  return (
    <QueryGuard
      {...accountTokensQuery}
      loadingVariant="centered"
      ErrorElement={
        <CenteredError error={t('Could not load account tokens.')} />
      }
    >
      {(accountTokens) => (
        <ImportEvmTokensWrapper
          {...props}
          {...{accounts, accountId, accountTokens, blockchain}}
        />
      )}
    </QueryGuard>
  )
}

type ImportTokensFn<TBlockchain extends EvmBlockchain> = ({
  address,
  tokens,
}: {
  address: ChecksummedEvmAddress<TBlockchain>
  // Allow only import of ERC20 tokens since all NFTs are retrieved from alchemy
  // and profile tokens are treated as ERC20 tokens
  tokens: EvmErc20Metadata<TBlockchain>[]
}) => Promise<unknown>

type ImportEvmTokensWrapperProps<TBlockchain extends EvmBlockchain> =
  ImportEvmTokenProps<TBlockchain> & {
    accountTokens: EvmTokenAmount<TBlockchain>[]
  }

const ImportEvmTokensWrapper = <TBlockchain extends EvmBlockchain>({
  blockchain,
  accountId,
  accounts,
  accountTokens,
  importEvmTokens,
  ...props
}: ImportEvmTokensWrapperProps<TBlockchain>) => {
  const importTokens = useImportEvmTokens(blockchain, importEvmTokens)
  const [activeScreen, setActiveScreen] = useEvmImportTokenScreenState()
  const account = ensureAccountById(
    accounts,
    accountId,
  ) as EvmAccountInfo<TBlockchain>

  const initialValues: DetailsSchema<TBlockchain> = {
    accountId,
    customContractAddress: '' as EvmContractAddress<TBlockchain>,
    tokens: [],
    activeTab: 'search',
    ...INITIAL_CUSTOM_FIELD_VALUES,
  }

  const schemas = {
    details: useDetailsSchema(accountTokens),
    summary: {},
    importResult: {},
  }
  const schema = yup.object().shape(schemas[activeScreen])

  const onImportTokens: ImportTokensFn<TBlockchain> = async ({
    address,
    tokens,
  }) => {
    assert(tokens.length > 0, 'Tokens array must not be empty.')
    return await importTokens.mutateAsyncSilent({
      address,
      tokens,
    })
  }

  const onSubmit = {
    details: (
      values: ImportEvmTokenSchema<TBlockchain>,
      {
        setSubmitting,
        setValues,
      }: FormikHelpers<ImportEvmTokenSchema<TBlockchain>>,
    ) => {
      if (values.activeTab === 'custom') {
        // token standard should be validated in custom screen
        assert(values.customTokenStandard === 'ERC20')
        setValues({
          ...values,
          tokens: [
            {
              tokenStandard: values.customTokenStandard,
              id: getEvmTokenId({
                contractAddress: values.customContractAddress,
                standard: values.customTokenStandard,
              }),
              isNft: false,
              blockchain,
              contractAddress: values.customContractAddress,
              decimals: parseInt(values.customTokenDecimal, 10),
              name: values.customTokenName as EvmTokenName<TBlockchain>,
              ticker: values.customTokenSymbol as EvmTokenTicker<TBlockchain>,
            },
          ],
        })
      }
      setActiveScreen('summary')
      setSubmitting(false)
    },
    summary: async (values: ImportEvmTokenSchema<TBlockchain>) => {
      setActiveScreen('importResult')
      await onImportTokens({address: account.address, tokens: values.tokens})
    },
    importResult: () => {
      /* */
    },
  }[activeScreen]

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={onSubmit}
    >
      <ImportEvmTokenContent
        {...props}
        {...{
          accountId,
          accounts,
          accountTokens,
          activeScreen,
          blockchain,
          setActiveScreen,
          mutationProps: importTokens,
          onImportTokens,
        }}
      />
    </Formik>
  )
}

type ImportEvmTokenContentProps<TBlockchain extends EvmBlockchain> =
  ImportEvmTokenProps<TBlockchain> & {
    accountTokens: EvmTokenAmount<TBlockchain>[]
  } & {
    activeScreen: EvmTokenImportActiveScreen
    setActiveScreen: (screen: EvmTokenImportActiveScreen) => void
    mutationProps: ReturnType<typeof useImportEvmTokens>
    onImportTokens: ImportTokensFn<TBlockchain>
  }

const ImportEvmTokenContent = <TBlockchain extends EvmBlockchain>({
  blockchain,
  onClose,
  onBack,
  accounts,
  accountId,
  activeScreen,
  setActiveScreen,
  mutationProps,
  onImportTokens,
  accountTokens,
  getErc20TokenList,
  getErc20TokenBalance,
}: ImportEvmTokenContentProps<TBlockchain>) => {
  const {t} = useTranslation()
  const getBlockchainName = useGetBlockchainName()
  const account = ensureAccountById(
    accounts,
    accountId,
  ) as EvmAccountInfo<TBlockchain>
  const formikProps = useFormikContext<ImportEvmTokenSchema<TBlockchain>>()

  const summary = {
    address: account.address,
    tokens: formikProps.values.tokens,
  }

  const _ModalHeader = (
    <ImportTokenModalHeader
      onClose={onClose}
      label={t('import_tokens_header', {
        blockchain: getBlockchainName(blockchain),
      })}
    />
  )

  switch (activeScreen) {
    case 'details':
      return (
        <DetailsScreen
          ModalHeader={_ModalHeader}
          {...{
            blockchain,
            onClose,
            onBack,
            accountId,
            accounts,
            accountTokens,
            getErc20TokenList,
          }}
        />
      )
    case 'summary':
      return (
        <SummaryScreen
          onBack={() => setActiveScreen('details')}
          onSubmit={formikProps.handleSubmit}
          ModalHeader={_ModalHeader}
          {...{blockchain, summary, accounts, getErc20TokenBalance}}
        />
      )
    case 'importResult':
      return (
        <TokenImportResultScreen
          onBack={() => setActiveScreen('summary')}
          ModalHeader={_ModalHeader}
          onRetry={() =>
            onImportTokens({address: summary.address, tokens: summary.tokens})
          }
          {...{
            onClose,
            mutationProps,
          }}
        />
      )
    default:
      return safeAssertUnreachable(activeScreen)
  }
}
