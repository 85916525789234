import {request} from '@nufi/frontend-common'
import BigNumber from 'bignumber.js'
import type {RPCResponseError, RPCResponseSuccess, SwapAsset} from 'common'

import type {ExchangeApi, ValidateAddressResponse} from '../../domain'

import {
  parseExchangeConditions,
  parseExchangeAssetsDetails,
  parseExchangeParams,
  parseCreateSwap,
} from './parsing'
import type {
  ExchangeConditionsResponse,
  ExchangeAssetsDetailsResponse,
  ExchangeParamsResponse,
  ExchangeResponse,
  CreateSwapResponse,
} from './types'

export class ChangellyRequestError extends Error {
  readonly code: number

  constructor(msg: string, code: number) {
    super(msg)
    this.code = code
  }
}

// From https://github.com/changelly/api-changelly/blob/master/lib.js
const generateChangellyRequestId = () =>
  'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    /* eslint-disable no-bitwise */
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8
    /* eslint-enable no-bitwise */
    return v.toString(16)
  })

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const createJSONRPCBody = (method: string, content: Record<string, any>) =>
  JSON.stringify({
    method,
    jsonrpc: '2.0',
    params: content,
    id: generateChangellyRequestId(),
  })

const isRPCResponseError = <T>(
  response: RPCResponseSuccess<T> | RPCResponseError,
): response is RPCResponseError =>
  (response as RPCResponseError).error !== undefined

// wrapper for general request function to handle changelly-specific error logic
async function exchangeRequest<T>(
  requestParams: Parameters<typeof request>[0],
): Promise<ExchangeResponse<T>> {
  return (await request(requestParams)) as ExchangeResponse<T>
}

// Changelly v2 requires all asset parameters to be lowercase
const assetToLowerCase = (asset: SwapAsset) => asset.toLowerCase()

export class ChangellyConnection implements ExchangeApi {
  private apiUrl: string

  constructor(baseUrl: string) {
    this.apiUrl = `${baseUrl}/api/changellyV2/rpc`
  }

  private fetchExchangeAssetsDetails =
    async (): Promise<ExchangeAssetsDetailsResponse> => {
      const response = await exchangeRequest<ExchangeAssetsDetailsResponse>({
        url: `${this.apiUrl}`,
        method: 'POST',
        body: createJSONRPCBody('getCurrenciesFull', {}),
        headers: {'Content-Type': 'application/json'},
      })

      if (isRPCResponseError(response)) {
        throw new ChangellyRequestError(
          response.error.message,
          response.error.code,
        )
      }

      return response.result
    }

  getExchangeAssetsDetails: ExchangeApi['getExchangeAssetsDetails'] =
    async () => {
      const assetsDetails = await this.fetchExchangeAssetsDetails()
      return parseExchangeAssetsDetails(assetsDetails)
    }

  private fetchExchangeConditions = async (
    from: SwapAsset,
    to: SwapAsset,
    amountFrom: string,
  ): Promise<ExchangeConditionsResponse> => {
    const response = await exchangeRequest<ExchangeConditionsResponse>({
      url: `${this.apiUrl}`,
      method: 'POST',
      body: createJSONRPCBody('getExchangeAmount', [
        {
          from: assetToLowerCase(from),
          to: assetToLowerCase(to),
          amountFrom,
        },
      ]),
      headers: {'Content-Type': 'application/json'},
    })

    if (isRPCResponseError(response)) {
      throw new ChangellyRequestError(
        response.error.message,
        response.error.code,
      )
    }

    return response.result
  }

  getExchangeConditions: ExchangeApi['getExchangeConditions'] = async (
    from,
    to,
    amountFrom,
  ) => {
    const response = await this.fetchExchangeConditions(
      from,
      to,
      amountFrom.toString(),
    )
    return parseExchangeConditions(response)
  }

  private fetchExchangeParams = async (
    from: SwapAsset,
    to: SwapAsset,
  ): Promise<ExchangeParamsResponse> => {
    const response = await exchangeRequest<ExchangeParamsResponse>({
      url: `${this.apiUrl}`,
      method: 'POST',
      body: createJSONRPCBody('getPairsParams', [
        {from: assetToLowerCase(from), to: assetToLowerCase(to)},
      ]),
      headers: {'Content-Type': 'application/json'},
    })

    if (isRPCResponseError(response)) {
      throw new ChangellyRequestError(
        response.error.message,
        response.error.code,
      )
    }

    return response.result
  }

  getExchangeParams: ExchangeApi['getExchangeParams'] = async (from, to) => {
    const exchangeParams = await this.fetchExchangeParams(from, to)
    const parsedParams = parseExchangeParams(exchangeParams)
    return {
      minAmount: parsedParams.minAmount,
      // Changelly provides the right amount of decimal places in the minAmount, but not in maxAmount
      maxAmount: new BigNumber(
        parsedParams.maxAmount.toFixed(parsedParams.minAmount.decimalPlaces()),
      ),
    }
  }

  createSwap: ExchangeApi['createSwap'] = async (
    from,
    to,
    address,
    amountFrom,
    // refundAddress must be valid or null, never an empty string ''
    refundAddress,
    extraId,
  ) => {
    const response = await exchangeRequest<CreateSwapResponse>({
      url: `${this.apiUrl}`,
      method: 'POST',
      body: createJSONRPCBody('createTransaction', {
        from: assetToLowerCase(from),
        to: assetToLowerCase(to),
        address,
        amountFrom: amountFrom.toString(),
        refundAddress,
        extraId,
      }),
      headers: {'Content-Type': 'application/json'},
    })

    if (isRPCResponseError(response)) {
      throw new ChangellyRequestError(
        response.error.message,
        response.error.code,
      )
    }

    return parseCreateSwap(response.result)
  }

  validateAddress: ExchangeApi['validateAddress'] = async (
    currency,
    address,
  ) => {
    const response = await exchangeRequest<ValidateAddressResponse>({
      url: `${this.apiUrl}`,
      method: 'POST',
      body: createJSONRPCBody('validateAddress', {
        currency: assetToLowerCase(currency),
        address,
      }),
      headers: {'Content-Type': 'application/json'},
    })

    if (isRPCResponseError(response)) {
      throw new ChangellyRequestError(
        response.error.message,
        response.error.code,
      )
    }

    return response.result
  }
}
