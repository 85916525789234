import {EventEmitter} from 'events'

import {getRandomUUID, setIfDoesNotExist} from '@nufi/dapp-client-core'
import type {
  ConnectorObject,
  InjectedConnectorFactory,
} from '@nufi/dapp-client-core'

import {dappConnectorsConfig} from '../../../../dappConnector/config'
import type {DappConnectorsConfig} from '../../../../dappConnector/config'

const connectorKind = 'evm'

// In order to destruct event emitter into the injected object, we need
// to manually re-assign its properties. This is because EventEmitter uses
// `prototype` to inherit properties, and these are not copied when using
// destructive assignment {...}
const createEmitter = () => {
  const shallowEmitter = new EventEmitter()
  const emitter = {}

  for (const key in shallowEmitter) {
    // It is difficult to ensure type inference here, but we are just
    // re-assigning properties
    // eslint-disable-next-line
    // @ts-ignore
    emitter[key] = shallowEmitter[key]
  }
  return emitter as EventEmitter
}

const legacyEmitter = createEmitter()
const evmProvidersEmitter = createEmitter()

// EIP6963 start
interface EIP6963ProviderInfo {
  uuid: string
  name: string
  icon: string
  rdns: string
}

interface EIP6963ProviderDetail {
  info: EIP6963ProviderInfo
  provider: EvmConnectorObject
}

interface EIP6963RequestProviderEvent extends Event {
  type: 'eip6963:requestProvider'
}

const isEIP6963RequestProviderEvent = (
  e: Event,
): e is EIP6963RequestProviderEvent => {
  return e.type === 'eip6963:requestProvider'
}

const eip6963Emitter = createEmitter()

// EIP6963 end

type EvmProvidersWindow = Window & {
  evmproviders?: Record<'nufi', Partial<NodeJS.EventEmitter>>
}

type LegacyEvmProvidersWindow = {
  ethereum?: Partial<NodeJS.EventEmitter>
}

type EnhancedWindow = LegacyEvmProvidersWindow & EvmProvidersWindow

const emitEvent: NodeJS.EventEmitter['emit'] = (...args) => {
  const enhancedWindow = window as unknown as EnhancedWindow

  // For yet undiscovered reasons https://blueshift.fi/ does some magic
  // and does not work when emitting events via emitter, but works when
  // accessing the emitter via window. Therefore we first try to emit event
  // this way, that should indeed be always possible.
  // We try to emit the event if registered via legacy injection `window.ethereum`
  // and also if registered via the `evmproviders` approach, as we currently do
  // not store information about using which protocol is the dapp communicating with.
  const tryEmittingEvent = (
    emitter: Partial<NodeJS.EventEmitter> | undefined,
  ) => {
    if (emitter != null) {
      const registeredListenersCount = emitter.listenerCount?.(args[0]) || 0
      if (registeredListenersCount > 0) {
        emitter.emit?.(...args)
        return true
      }
    }
    return false
  }

  tryEmittingEvent(enhancedWindow.ethereum)
  tryEmittingEvent(enhancedWindow.evmproviders?.nufi)
  tryEmittingEvent(eip6963Emitter)

  return true
}

const errorLabelsToKeys = {
  'User-Rejected-Request': 4001,
  Unauthorized: 4100,
  'Unsupported-Method': 4200,
  Disconnected: 4900,
  'Chain-Disconnected': 4901,

  // metamask & https://eips.ethereum.org/EIPS/eip-1474#error-codes
  Internal: -32603,
  InvalidInput: -32000,
}

type ErrorType = keyof typeof errorLabelsToKeys

// Based on https://eips.ethereum.org/EIPS/eip-1193#api
export class ProviderRpcError {
  message: string
  readonly code: number
  readonly data?: unknown

  public constructor(type: ErrorType, originalError?: unknown) {
    const errorTypeToMessage = (errorType: ErrorType) =>
      `${errorType[0]}${errorType.slice(1).replaceAll('-', ' ').toLowerCase()}`

    const message = (() => {
      if (
        originalError != null &&
        typeof originalError === 'object' &&
        'message' in originalError
      ) {
        const _originalError = originalError as {
          message: unknown
        }
        if (typeof _originalError.message === 'string')
          return _originalError.message
        return errorTypeToMessage(type)
      }
      return errorTypeToMessage(type)
    })()

    this.message = message

    this.code = errorLabelsToKeys[type]
    this.data = {originalError}
  }
}

type ConnectorState = {
  // Legacy properties: https://docs.metamask.io/guide/ethereum-provider.html#legacy-properties
  // legacy property used e.g. by https://app.blueshift.fi when connecting for the first time
  selectedAddress: string | undefined
  // legacy property used e.g. by https://app.occamx.fi/
  networkVersion: string | undefined
  // legacy property
  chainId: string | undefined
}

type EvmConnectorObject = ConnectorObject & {
  request: (args: {method: string}) => Promise<unknown>
} & ConnectorState &
  EventEmitter

const injectedConnectorFactory: InjectedConnectorFactory<
  DappConnectorsConfig
> = (client) => {
  const connectorState: ConnectorState = {
    selectedAddress: undefined,
    networkVersion: undefined,
    chainId: undefined,
  }

  const isEnabled = async (): Promise<boolean> => {
    if (!(await client.isConnectorWindowOpenAsync())) return false
    return await client.proxy.isEnabled!()
  }

  const getCurrentEthAccounts = async () => {
    if (await isEnabled()) {
      return (await client.sendRequest('eth_requestAccounts', [])) as string[]
    }
    return []
  }

  const refreshStateProperties = async () => {
    const enabled = await isEnabled()

    // `ethereumPublicApi` is used to try whether the call can be resolved, without the user
    // being logged-in (public rpc call). In such a case we default
    // to ethereum blockchain.
    const method = enabled ? 'rpcRequest' : 'ethereumPublicApi'

    // Legacy properties start https://docs.metamask.io/guide/ethereum-provider.html#legacy-properties
    // Use e.g. https://app.occamx.fi/ for testing
    const chainId = (await client.sendRequest(method, [
      'eth_chainId',
      [],
    ])) as unknown as string | undefined

    const networkVersion = (await client.sendRequest(method, [
      'net_version',
      [],
    ])) as unknown as number | undefined

    const selectedAddresses = await getCurrentEthAccounts()

    connectorState.chainId = chainId
    connectorState.networkVersion =
      networkVersion != null ? `${networkVersion}` : undefined
    connectorState.selectedAddress = selectedAddresses[0]
  }

  const emitChainChange = () => {
    emitEvent('chainChanged', connectorState.chainId)
    // legacy event needed e.g. by https://app.occamx.fi/
    // https://docs.metamask.io/guide/ethereum-provider.html#legacy-events
    emitEvent('networkChanged', connectorState.networkVersion)
    // legacy event used by none known wallet
    emitEvent('chainIdChanged', connectorState.chainId)
  }

  const connectAccount = () => {
    const accounts = [connectorState.selectedAddress]
    emitEvent('accountsChanged', accounts)
  }

  const windowMethods = {
    enable: async () => {
      if (!(await client.isConnectorWindowOpenAsync())) {
        await client.openConnectorWindow()
      }
      await client.proxy.enable!() // This will throw on failure

      await refreshStateProperties()
      return [connectorState.selectedAddress]
    },
    isEnabled,
  }

  const metamaskSpecificApi = {
    // ethereum.isConnected(): boolean
    // `isConnected` does not seem to be in eip-1193 standard, but metamask defines it.
    // From metamask spec:
    // "Note that this method has nothing to do with the user's accounts.
    //
    // Set for `false` only for a brief moment, populated with `setTimeout` below:
    isConnected: () => false,
  }

  // Do not block injection with top-level await, but instead refresh state properties
  // in another event-loop "tick", and set `isConnected` to `true`.
  // We need to do this otherwise state properties would not be populated when
  // `isConnected` is `true` initially.
  setTimeout(async () => {
    // Avoid making extra request to connector window in case this script
    // is being loaded by iframes.
    const isConnectorWindowActiveForOtherFrame = await client.sendRequest(
      'isConnectorWindowActiveForOtherFrame',
      [],
    )
    if (!isConnectorWindowActiveForOtherFrame) {
      return
    }

    // Fragile part! Note that this does not fire requests for `eth_chainId` and `net_version`
    // (in `refreshStateProperties`) for every page the user visits as these
    // calls are handled in Evm BlockchainApi without the need to run network call.
    await refreshStateProperties()
    metamaskSpecificApi.isConnected = () => true
    emitEvent('connect', {chainId: connectorState.chainId})

    if (connectorState.selectedAddress != null) {
      // We are not emitting chain change event from here, as it caused
      // infinite reload e.g. for https://disperse.app/.
      // If the above condition is true, it only means that the user refreshed the dapp
      // and we emit `connect` event that anyways informs about the chain id.
      // Warning!
      // When the app refreshes, `connectorState` properties will be `undefined`
      // for a brief moment and thus partially out-of-sync. However all these properties are
      // deprecated, so unless this causes real trouble, we do not care.
      // If this causes some issues, we can try to sync the properties
      // with sessionStorage somehow, but this is more invasive.
      connectAccount()
    }
  }, 0)

  const explicitProxyMethods = [
    'eth_requestAccounts',
    'eth_accounts',
    'eth_sendTransaction',
    'wallet_getPermissions',
    'wallet_requestPermissions',
    'personal_sign',
    'personal_ecRecover',
    'eth_signTypedData',
    'eth_signTypedData_v1',
    'eth_signTypedData_v3',
    'eth_signTypedData_v4',
    'wallet_watchAsset',
  ]

  /* We do not fetch this setting from `getWalletOverrides` because this would cause the inject
  to be asynchronous, which results in race-conditions when injecting as some EVM dapps try to
  access `window.ethereum` object immediately */
  const shouldOverrideMetamask = true

  const connectorObject = {
    isMetaMask: shouldOverrideMetamask,
    enable: async () => {
      // https://docs.metamask.io/guide/ethereum-provider.html#ethereum-enable-deprecated
      return await connectorObject.request({method: 'eth_requestAccounts'})
    },
    ...metamaskSpecificApi,
    // Some dapps still use this deprecated method (e.g. https://stake.lido.fi/)
    // Note that we do not handle all legacy API variants, until we discover dapps that
    // we can test the legacy API with.
    // See https://docs.metamask.io/guide/ethereum-provider.html#legacy-methods
    send: async (args: string | {method: string; params: unknown[]}) => {
      if (typeof args === 'string') {
        return connectorObject.request({method: args})
      } else {
        return connectorObject.request(args)
      }
    },
    // See https://docs.metamask.io/guide/ethereum-provider.html#legacy-methods
    // Can be tested with https://app.chainport.io/
    sendAsync: async (
      args:
        | string
        | {method: string; params: unknown[]; id: string | undefined},
      callback: (err: unknown, data: unknown) => unknown,
    ) => {
      const result = await (async () => {
        if (typeof args === 'string') {
          return await connectorObject.request({method: args})
        } else {
          return await connectorObject.request(args)
        }
      })()

      const data =
        result instanceof ProviderRpcError ? {error: result} : {result}

      callback(undefined, {
        id: typeof args !== 'string' && args.id,
        jsonrpc: '2.0',
        method: typeof args !== 'string' && args.method,
        ...data,
      })
    },
    request: async (args: {method: string; params: unknown[]}) => {
      // See: https://docs.metamask.io/guide/signing-data.html#signing-data-with-metamask
      // This method should not be used by any reasonable dapps
      if (args.method === 'eth_sign') {
        throw new ProviderRpcError(
          'Unsupported-Method',
          '`eth_sign` not supported for security reasons.',
        )
      }

      try {
        let isEnabled = await windowMethods.isEnabled()

        if (args.method === 'wallet_switchEthereumChain') {
          if (!isEnabled) {
            // The switch of EVM chain can not be executed without opening the connector window.
            await windowMethods.enable()
            isEnabled = true
          }

          const targetChainId = (args.params[0] as {chainId: string}).chainId

          // We require the connector to be enabled, so the `connectorState.chainId` must
          // be already defined.
          const currentChainId = connectorState.chainId as string

          if (parseInt(targetChainId, 16) === parseInt(currentChainId, 16)) {
            // Nothing to change. Though it can happen that we are returning
            // `0x01`, but the dapp asks for `0x1`. In such a case we hope that
            // the dapp will be able to work with it anyways
            // see e.g. https://app.1inch.io/
            return null
          }

          const isSuccess = await client.sendRequest(
            'wallet_switchEthereumChain',
            [{proposedChainId: targetChainId, currentChainId}],
          )
          if (isSuccess) {
            await refreshStateProperties()
            emitChainChange()
            return null
          }
        }

        // We do not really support addition of blockchains via dapp, but we do not fail for this method
        // as it may be called before `wallet_switchEthereumChain` for the transition that we support.
        // If the dapp wants to add some blockchain and later use it (but we do not support it), we will fail once
        // `wallet_switchEthereumChain` is called.
        // https://docs.metamask.io/guide/rpc-api.html#wallet-addethereumchain
        if (args.method === 'wallet_addEthereumChain') return null

        if (
          // We do not grant any permissions without users being logged in,
          // so in case of `wallet_requestPermissions` we also ask user to connect
          ['eth_requestAccounts', 'wallet_requestPermissions'].includes(
            args.method,
          )
        ) {
          if (!isEnabled) {
            await windowMethods.enable()
            isEnabled = true
          }
        }

        if (!isEnabled) {
          // Querying permissions is the only permitted operation, when
          // dapp is not connected.
          if (args.method === 'wallet_getPermissions') {
            return []
          }

          // Some dapps are calling this in a loop, which slows down the app a lot.
          // There are no accounts exposed if user is not logged in.
          if (args.method === 'eth_accounts') {
            return []
          }

          // Inspired by https://ethereum.org/en/developers/docs/apis/json-rpc/#json-rpc-methods
          // so that we can avoid hardcoding of all methods, but can still kind-of decide what
          // methods are not supported
          const validRpcPrefixes = ['eth_', 'net_', 'db_', 'ssh_', 'web3_']
          if (
            !validRpcPrefixes.some((prefix) => args.method.startsWith(prefix))
          ) {
            throw new ProviderRpcError('Unauthorized')
          }

          // Try whether the call can be resolved, without the user
          // being logged-in (public rpc call). In such a case we default
          // to ethereum blockchain.
          try {
            return await client.sendRequest('ethereumPublicApi', [
              args.method,
              args.params,
            ])
          } catch (err) {
            throw new ProviderRpcError('Internal', err)
          }
        }

        const isExplicitlySupportedMethod = explicitProxyMethods.includes(
          args.method,
        )
        if (isExplicitlySupportedMethod) {
          return await client.sendRequest(args.method, [args])
        } else {
          return await client.sendRequest('rpcRequest', [
            args.method,
            args.params,
          ])
        }
      } catch (err) {
        if (err instanceof ProviderRpcError) {
          throw err
        }
        // If the error is thrown by connector window, and is sent back via message,
        // the above check will not work, as the type of object will not be preserved.
        // TODO: consider changing Errors across all connector to pure serializable objects.
        if (err && typeof err === 'object' && 'code' in err) {
          const _err = err as {code: number}
          if (Object.values(errorLabelsToKeys).some((v) => v === _err.code)) {
            throw err
          }
        }

        throw new ProviderRpcError('Internal', err)
      }
    },
  } as unknown as EvmConnectorObject

  // bind connectorObject to its state, ensuring propagation
  // of any connectorState changes to connectorObject
  for (const connectorStateKey of Object.keys(connectorState)) {
    Object.defineProperty(connectorObject, connectorStateKey, {
      get: () =>
        connectorState[connectorStateKey as keyof typeof connectorState],
    })
  }

  return {
    connectorKind,
    type: 'simple',
    inject(window) {
      if (process.env.APP_ENABLE_ETHEREUM_CONNECTOR !== 'true') {
        return
      }

      // window.ethereum is used to interact with any EVM network
      const WINDOW_PROP_NAME = 'ethereum'

      if (shouldOverrideMetamask) {
        setIfDoesNotExist(window, [WINDOW_PROP_NAME], {
          ...connectorObject,
          ...legacyEmitter,
        })
      }

      const svgPrefix = 'data:image/svg+xml;base64,' as const
      type SvgPrefixType = typeof svgPrefix

      // eip-5749 injection
      // https://eips.ethereum.org/EIPS/eip-5749
      interface ProviderInfo {
        uuid: string
        name: string
        icon: `${SvgPrefixType}${string}`
        description: string
      }

      function isProviderInfoIcon(
        svgIcon: string,
      ): svgIcon is ProviderInfo['icon'] {
        return svgIcon.startsWith(svgPrefix)
      }

      const icon = dappConnectorsConfig.icons.default
      if (!isProviderInfoIcon(icon)) {
        throw new Error('Incompatible ProviderInfo["icon"]')
      }

      const info: ProviderInfo = {
        description: dappConnectorsConfig.description,
        icon,
        name: dappConnectorsConfig.name,
        uuid: dappConnectorsConfig.connectors.evm.evmprovidersId,
      }

      const provider = {info, ...connectorObject, isMetaMask: false}

      const enhancedWindow = window as unknown as Window & {
        evmproviders?: Record<string, unknown>
      }
      enhancedWindow.evmproviders = enhancedWindow.evmproviders || {}

      const evmproviders = enhancedWindow.evmproviders as NonNullable<
        EvmProvidersWindow['evmproviders']
      >
      evmproviders.nufi = {...provider, ...evmProvidersEmitter}

      // EIP6963 start
      function announceEIP6963Provider() {
        const EIP6963info: EIP6963ProviderInfo = {
          uuid: getRandomUUID(),
          name: dappConnectorsConfig.name,
          icon,
          rdns: 'fi.nu',
        }
        const eip6963ProviderDetails: EIP6963ProviderDetail = {
          info: EIP6963info,
          provider: {...eip6963Emitter, ...connectorObject},
        }
        window.dispatchEvent(
          new CustomEvent('eip6963:announceProvider', {
            detail: Object.freeze(eip6963ProviderDetails),
          }),
        )
      }

      window.addEventListener('eip6963:requestProvider', (e) => {
        if (isEIP6963RequestProviderEvent(e)) {
          announceEIP6963Provider()
        }
      })
      announceEIP6963Provider()

      // EIP6963 end
    },
    async eventHandler(method) {
      // Note that this event is fired when account is chosen for the first time
      if (method === 'connectorWindowOpen') {
        await refreshStateProperties()
        connectAccount()
        // If chain is changed by user during account selection, we
        // inform the dapp via event. But e.g. uniswap is not getting
        // it, so we emit one other delayed event to inform it.
        setTimeout(() => emitChainChange(), 100)
      } else if (method === 'connectorWindowClosed') {
        const currentNetwork = connectorState.networkVersion
        const currentAddress = connectorState.selectedAddress
        await refreshStateProperties()
        if (currentAddress !== connectorState.selectedAddress) {
          emitEvent('accountsChanged', [])
        }
        if (currentNetwork !== connectorState.networkVersion) {
          emitChainChange()
        }
      } else if (method === 'chainChanged') {
        await refreshStateProperties()
        emitChainChange()
      } else if (method === 'accountChanged') {
        await refreshStateProperties()
        const accounts = [connectorState.selectedAddress]
        emitEvent('accountsChanged', accounts)
      }
    },
  }
}

export default injectedConnectorFactory
