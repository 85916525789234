/* eslint-disable @typescript-eslint/explicit-function-return-type */

import type {AccountId} from '@nufi/wallet-common'
import {arraySum} from '@nufi/wallet-common'
import type {
  SolanaTokenId,
  SolanaTransactionHistoryResponse,
} from '@nufi/wallet-solana'
import {useInfiniteQuery} from '@tanstack/react-query'
import BigNumber from 'bignumber.js'

import {fetchNeverStaleQuery, useNeverStaleQuery} from 'src/utils/query-utils'
import {minutesToMilliseconds} from 'src/wallet/utils/common'

import {solana} from '../../solanaManagers'

import {cachedGetAccounts} from './common'
import {cachedGetStakeAccounts} from './staking'
import {cachedGetTokensByAccount, cachedGetTokens} from './tokens'
import {QUERY_KEY_PREFIX as P} from './utils'

//
// __CACHED__ QUERIES
//

export const combinedQueryKeys = {
  txHistory: {
    index: [P, 'txHistory'],
    account: (accountId: AccountId | null) => ({
      index: [...combinedQueryKeys.txHistory.index, accountId],
      token: (tokenId: SolanaTokenId | null) =>
        [
          ...combinedQueryKeys.txHistory.account(accountId).index,
          tokenId,
        ] as const,
    }),
  },
  balance: {
    index: [P, 'balance'],
    accounts: (tokenId: SolanaTokenId | null) => [
      ...combinedQueryKeys.balance.index,
      'account',
      tokenId,
    ],
    native: () => [...combinedQueryKeys.balance.index, 'native'],
    total: (tokenId: SolanaTokenId | null) => [
      ...combinedQueryKeys.balance.index,
      'total',
      tokenId,
    ],
  },
} as const

// universal paginated call for tx history for native and/or token assets
export function useGetPaginatedTransactionHistory(
  accountId: AccountId,
  tokenId: SolanaTokenId | null,
  enabled = true,
) {
  const infiniteQuery = useInfiniteQuery<SolanaTransactionHistoryResponse>({
    queryKey: combinedQueryKeys.txHistory.account(accountId).token(tokenId),
    queryFn: async ({pageParam: before = undefined}) => {
      const account = solana.accountsStore.getAccount(accountId)
      const data = await solana.accountManager.getTransactionHistory({
        account,
        tokenId: tokenId || undefined,
        pagingParams: {
          perAddressLimit: 10,
          before: before as string | undefined,
        },
      })
      return data
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    staleTime: minutesToMilliseconds(5),
    enabled,
    initialPageParam: undefined,
    getNextPageParam: ({
      hasMorePages,
      txHistory,
    }: SolanaTransactionHistoryResponse) =>
      hasMorePages && txHistory.length > 0
        ? txHistory[txHistory.length - 1]!.signature
        : // https://react-query.tanstack.com/guides/infinite-queries
          // required to be strictly undefined by the API in case of no next page
          undefined,
  })

  const txHistoryEntries = infiniteQuery.data?.pages
    .map((paginatedTxHistory) => paginatedTxHistory.txHistory)
    .flat()
  // in case we are interested in a specific tokenId, filter those txs
  const data = tokenId
    ? txHistoryEntries?.filter(
        (entry) =>
          entry.tokenEffects.filter((e) => e.token.id === tokenId).length > 0,
      )
    : txHistoryEntries

  return {
    ...infiniteQuery,
    data,
  }
}

//
// __COMBINED__ QUERIES
//

export function useGetTransactionHistory(
  accountId: AccountId,
  tokenId: SolanaTokenId | null,
  enabled = true,
) {
  const queries = {
    token: useGetPaginatedTransactionHistory(
      accountId,
      tokenId,
      enabled && tokenId != null,
    ),
    native: useGetPaginatedTransactionHistory(
      accountId,
      null,
      enabled && tokenId == null,
    ),
  }

  return queries[tokenId == null ? 'native' : 'token']
}

export function useGetBalancesPerAccount(
  tokenId: SolanaTokenId | null,
  enabled = true,
) {
  return useNeverStaleQuery({
    queryKey: combinedQueryKeys.balance.accounts(tokenId),
    queryFn: async () => {
      const accounts = await cachedGetAccounts()
      if (tokenId == null) {
        return accounts.map((accountInfo) => ({
          accountId: accountInfo.id,
          balance: accountInfo.balance,
        }))
      } else {
        const tokens = await cachedGetTokensByAccount()
        return accounts.map((account) => {
          const tokenAmount = tokens[account.id]!.find(
            (token) => token.token.id === tokenId,
          )
          return {
            accountId: account.id,
            balance: tokenAmount?.amount || new BigNumber(0),
          }
        })
      }
    },
    enabled,
  })
}

async function getTotalNativeBalance() {
  const [accounts, stakeAccounts] = await Promise.all([
    cachedGetAccounts(),
    cachedGetStakeAccounts(),
  ])

  const parentAccountBalances = (accounts || []).map(({balance}) => balance)
  const stakeAccountBalances = stakeAccounts.map(({balance}) => balance)
  return arraySum([...parentAccountBalances, ...stakeAccountBalances])
}

getTotalNativeBalance.__key = combinedQueryKeys.balance.native

export function cachedGetTotalNativeBalance() {
  return fetchNeverStaleQuery({
    queryKey: getTotalNativeBalance.__key(),
    queryFn: getTotalNativeBalance,
  })
}

export function useGetTotalNativeBalance(enabled = true) {
  return useNeverStaleQuery({
    queryKey: getTotalNativeBalance.__key(),
    queryFn: getTotalNativeBalance,
    enabled,
  })
}

export function useGetTotalBalance(
  tokenId: SolanaTokenId | null,
  enabled = true,
) {
  return useNeverStaleQuery({
    queryKey: combinedQueryKeys.balance.total(tokenId),
    queryFn: async () => {
      if (tokenId == null) {
        return cachedGetTotalNativeBalance()
      } else {
        const tokens = await cachedGetTokens()
        const tokenAmount = tokens.find(
          (tokenInfo) => tokenInfo.token.id === tokenId,
        )
        return tokenAmount?.amount ?? new BigNumber(0)
      }
    },
    enabled,
  })
}
