import {Box, Grid} from '@mui/material'
import {makeStyles} from '@mui/styles'
import type {
  FlowAccountInfo,
  FlowTokenContractMetadata,
  FlowTokenId,
  FlowTokenMetadata,
} from '@nufi/wallet-flow'
import {filterSupportedTokenContractsForCryptoProvider} from '@nufi/wallet-flow'
import {useFormikContext} from 'formik'
import React, {useEffect, useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {
  AccountSelectField,
  Button,
  createCustomResponsiveClass,
  ModalFooter,
  ModalLayout,
  useModalSharedStyles,
} from '../../../components'
import type {AccountId, Blockchain} from '../../../types'
import {
  findContractMetadataByTokenId,
  getTokenMetadataFromContract,
} from '../../../wallet/flow'
import {ensureAccountById} from '../../../wallet/utils/common'
import {
  FORM_ID,
  TokenSelectContent,
} from '../../portfolio/account/actions/importToken/flow/DetailsScreen'
import type {ImportFlowTokenSchema} from '../../portfolio/account/actions/importToken/flow/schema'
import type {TokenOption} from '../../portfolio/account/actions/importToken/flow/types'
import {
  mapAccountsToBalances,
  ReceiveModalAddress,
  ReceiveModalAssetName,
} from '../Helpers'

import {
  FLOW_TOKEN_OPTION,
  getTokenOptions,
  ReceiveAddressOrImportToken,
} from './utils'

export type ReceiveModalDetailScreenProps = {
  blockchain: Blockchain
  onClose: () => unknown
  defaultAccountId: AccountId
  defaultTokenOptionId: TokenOption['id'] | null
  accountInfos: FlowAccountInfo[]
  renderModalHeader: (tokenMetadata?: FlowTokenMetadata) => React.ReactElement
  tokenContracts: FlowTokenContractMetadata[]
  isNftView: boolean
}

export function ReceiveModalDetailScreen({
  blockchain,
  defaultAccountId,
  defaultTokenOptionId,
  accountInfos,
  onClose,
  renderModalHeader,
  tokenContracts,
  isNftView,
}: ReceiveModalDetailScreenProps) {
  const classes = {...useStyles(), ...useModalSharedStyles()}
  const {t} = useTranslation()
  const formikProps = useFormikContext<ImportFlowTokenSchema>()
  const {values, handleChange, handleSubmit} = formikProps

  const accountId = values.accountId || defaultAccountId
  const selectedAccount = ensureAccountById(accountInfos, accountId)
  const address = selectedAccount.address

  const options = getTokenOptions(
    filterSupportedTokenContractsForCryptoProvider(
      tokenContracts,
      selectedAccount.cryptoProviderType,
    ),
    isNftView,
    !isNftView,
  )
  const defaultOption = options.find((o) => o.id === defaultTokenOptionId)
  const tokenId =
    options.find((o) => o.id === values.contractId)?.tokenId ||
    defaultOption?.tokenId ||
    null

  useEffect(() => {
    if (!defaultOption) return
    formikProps.setValues({
      accountId,
      contractId: defaultOption.id as FlowTokenId,
      password: '',
    })
  }, [])

  const tokenMetadata = useMemo(
    () =>
      getTokenMetadataFromContract(
        findContractMetadataByTokenId(tokenContracts, tokenId),
      ),
    [tokenContracts, tokenId],
  )

  return (
    <form onSubmit={handleSubmit} id={FORM_ID} className={classes.formWrapper}>
      <ModalLayout
        header={renderModalHeader(tokenMetadata)}
        body={
          <Box p={2}>
            <>
              <Grid container>
                <Grid item xs={12} className={classes.formField}>
                  <AccountSelectField
                    label={t('Account')}
                    value={accountId}
                    onChange={(e) => {
                      handleChange('accountId')(e.target.value as AccountId)
                    }}
                    items={mapAccountsToBalances(accountInfos)}
                    blockchain={blockchain}
                    hideAmount={false}
                    showBlockchainIcon
                  />
                </Grid>
                {/* isLoading is false, because the options are pre-loaded in the previous step */}
                <TokenSelectContent isLoading={false} options={options} />
              </Grid>
              {
                tokenMetadata ? (
                  <ReceiveAddressOrImportToken
                    tokenMetadata={tokenMetadata}
                    address={address}
                  />
                ) : values.contractId === FLOW_TOKEN_OPTION.id ? (
                  <ReceiveModalAddress
                    address={address}
                    isToken={false}
                    blockchain={blockchain}
                    assetName={
                      <ReceiveModalAssetName blockchain={blockchain} />
                    }
                  />
                ) : null // if we no token is selected
              }
            </>
          </Box>
        }
        footer={
          <ModalFooter hasDivider>
            <Button
              textTransform="none"
              onClick={onClose}
              color="primary"
              variant="outlined"
              fullWidth
            >
              {t('Done')}
            </Button>
          </ModalFooter>
        }
      />
    </form>
  )
}

const useStyles = makeStyles((theme) => ({
  verifyHwAddress: {
    overflow: 'auto',
    alignItems: 'center',
    ...createCustomResponsiveClass(
      theme,
      {default: 200, zoomed: 120, old: 100},
      'minHeight',
    ),
  },
}))
