import {HelpOutline} from '@mui/icons-material'
import {Box, Tooltip} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {Route, Switch} from 'react-router-dom'

import {
  BatchQueryGuard,
  LoadingWithLabel,
  Modal,
  StakingEpochInfo,
  WithTooltip,
} from '../../../components'
import {routeTo} from '../../../router'
import {useStakeRouteOptions} from '../../../router/staking'
import {
  useGetEpochInfo,
  useGetEpochScheduleEstimate,
} from '../../../wallet/solana'
import {
  EpochInfoError,
  EpochInfoLoading,
  EpochInfoText,
} from '../common/overview/StakeOverviewUtils'
import {CurrentEpochInfoText} from '../common/utils'
import type {StakeActionsRoutesProps} from '../StakeDetail'
import StakeDetail from '../StakeDetail'

import ActivateStakeModal from './actionModals/ActivateStakeModal'
import SolanaSetupStakeAccountModal from './actionModals/SetupStakeAccountModal'
import WithdrawModal from './actionModals/WithdrawModal'
import StakeHistory from './history/StakeHistory'
import StakeAccountsOverview from './overview/StakeAccountsOverview'

function StakeAccountActionsRoutes({onModalClose}: StakeActionsRoutesProps) {
  const {stakeAccountId} = useStakeRouteOptions()
  return (
    <>
      <Route
        exact
        path={
          routeTo.staking.myStaking.solana
            .account(':accountId')
            .stakeAccount(':stakeAccountId').delegate
        }
      >
        <Modal onClose={onModalClose} variant="left">
          <ActivateStakeModal
            onClose={onModalClose}
            stakeAccountId={stakeAccountId}
          />
        </Modal>
      </Route>
      <Route
        exact
        path={
          routeTo.staking.myStaking.solana
            .account(':accountId')
            .stakeAccount(':stakeAccountId').withdraw
        }
      >
        <Modal onClose={onModalClose} variant="left">
          <WithdrawModal
            onClose={onModalClose}
            stakeAccountId={stakeAccountId}
          />
        </Modal>
      </Route>
    </>
  )
}

function AccountActionsRoutes({onModalClose}: StakeActionsRoutesProps) {
  const {accountId} = useStakeRouteOptions()
  return (
    <Switch>
      <Route
        exact
        path={
          routeTo.staking.myStaking.solana.account(':accountId')
            .setupStakeAccount
        }
      >
        <Modal onClose={onModalClose} variant="left">
          <SolanaSetupStakeAccountModal
            onClose={onModalClose}
            accountId={accountId}
          />
        </Modal>
      </Route>
      <Route
        path={
          routeTo.staking.myStaking.solana
            .account(':accountId')
            .stakeAccount(':stakeAccountId').index
        }
      >
        <StakeAccountActionsRoutes onModalClose={onModalClose} />
      </Route>
    </Switch>
  )
}

const EpochInfo = () => {
  const epochInfoQuery = useGetEpochInfo()
  const epochScheduleEstimateQuery = useGetEpochScheduleEstimate()
  const {t} = useTranslation()
  return (
    <BatchQueryGuard
      queries={{
        epochInfo: epochInfoQuery,
        scheduleEstimate: epochScheduleEstimateQuery,
      }}
      ErrorElement={<EpochInfoError />}
      LoadingElement={<EpochInfoLoading />}
    >
      {({epochInfo, scheduleEstimate}) => {
        const {epoch, slotsInEpoch, slotIndex} = epochInfo
        const slotProgress = 100 * (slotIndex / slotsInEpoch)
        return (
          <Box sx={{display: 'flex', alignItems: 'center'}}>
            <CurrentEpochInfoText value={epoch} />
            <EpochInfoText
              sx={{display: 'flex', alignItems: 'center'}}
              label={t('Slot progress')}
              value={
                <LoadingWithLabel
                  sx={{ml: 1}}
                  progress={slotProgress}
                  content={
                    <Tooltip
                      title={
                        <span>
                          {t('Slot progress')}:<br />
                          {t('epoch_progress_completed', {number: slotIndex})}
                          <br />
                          {t('remaining', {number: slotsInEpoch - slotIndex})}
                        </span>
                      }
                      placement="top-start"
                    >
                      <div>{`${Math.floor(slotProgress)}%`}</div>
                    </Tooltip>
                  }
                />
              }
            />
            {scheduleEstimate && (
              <EpochInfoText
                label={t('Estimated epoch end')}
                date={scheduleEstimate.currentEpochEnd}
              />
            )}
            <WithTooltip
              placement="top"
              title={<StakingEpochInfo blockchain="solana" />}
            >
              <HelpOutline fontSize="small" color="action" />
            </WithTooltip>
          </Box>
        )
      }}
    </BatchQueryGuard>
  )
}

function SolanaStakeDetail() {
  return (
    <StakeDetail
      blockchain={'solana'}
      StakeAccountsOverview={StakeAccountsOverview}
      StakeHistory={StakeHistory}
      AccountActionsRoutes={AccountActionsRoutes}
      EpochInfo={EpochInfo}
    />
  )
}

export default SolanaStakeDetail
