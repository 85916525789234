import assert from 'assert'

import {getIsWebExtension} from '@nufi/frontend-common'
import {CHAIN_NAMESPACES, WALLET_ADAPTERS} from '@web3auth/base'
import {CommonPrivateKeyProvider} from '@web3auth/base-provider'
import {Web3AuthNoModal} from '@web3auth/no-modal'
import type {OpenloginUserInfo} from '@web3auth/openlogin-adapter'
import {OpenloginAdapter} from '@web3auth/openlogin-adapter'

import type {Web3AuthLoginProvider, Web3AuthNetworkType} from './types'
import {
  getVerifierIdInfo as _getVerifierIdInfo,
  copyWeb3AuthSession,
} from './utils'

type VerifierConfig = {
  verifier: string
}

const verifierMaps: Partial<
  Record<
    Web3AuthNetworkType,
    Partial<Record<Web3AuthLoginProvider, VerifierConfig>>
  >
> = {
  // source: https://github.com/torusresearch/torus-website/blob/10061f0ee98f6a6d3bfcb48cfe820a91ac319d0f/.env.production
  mainnet: {
    google: {
      verifier: 'tkey-google',
    },
    facebook: {
      verifier: 'tkey-facebook',
    },
    twitter: {
      verifier: 'tkey-auth0-twitter',
    },
    discord: {
      verifier: 'tkey-discord',
    },
  },
  // source: https://github.com/torusresearch/torus-website/blob/10061f0ee98f6a6d3bfcb48cfe820a91ac319d0f/.env.lrc
  testnet: {
    google: {
      verifier: 'tkey-google-lrc',
    },
    facebook: {
      verifier: 'tkey-facebook-lrc',
    },
    twitter: {
      verifier: 'tkey-auth0-twitter-lrc',
    },
    discord: {
      verifier: 'tkey-discord-lrc',
    },
  },
}

export interface IWeb3AuthConnectionV1 {
  login: (
    loginProvider: Web3AuthLoginProvider,
    options?: {
      sessionTime?: number
      sessionNamespace?: string
    },
  ) => Promise<{userInfo: Partial<OpenloginUserInfo>; privateKey: Buffer}>
  rehydrateSession: (options?: {sessionNamespace?: string}) => Promise<{
    userInfo: Partial<OpenloginUserInfo>
    privateKey: Buffer
  } | null>
  logout: (options?: {sessionNamespace?: string}) => Promise<void>
  getVerifierIdInfo: (
    loginProvider: Web3AuthLoginProvider,
    verifierId: string,
  ) => Promise<{
    keyExists: boolean
    keyCreatedAt?: Date
  }>
}

export const createWeb3AuthConnectionV1 = (
  web3AuthNetwork: Web3AuthNetworkType,
  web3AuthClientId: string,
): IWeb3AuthConnectionV1 => {
  const NATIVE_SESSION_KEY = 'openlogin_store'
  const DEFAULT_SESSION_NAMESPACE = 'wallet'

  const getInitializedWeb3Auth = async (options: {
    sessionNamespace: string
    sessionTime?: number
  }) => {
    const {sessionNamespace, sessionTime} = options
    const chainConfig = {
      chainNamespace: CHAIN_NAMESPACES.OTHER,
      // these are just dummy values, they are not used,
      // but without them Web3Auth throws an error
      chainId: '0x1',
      rpcTarget: 'https://dummy.target',
      displayName: '',
      blockExplorer: '',
      ticker: '',
      tickerName: '',
    }
    copyWeb3AuthSession(
      `${NATIVE_SESSION_KEY}_${sessionNamespace}`,
      NATIVE_SESSION_KEY,
    )
    const web3Auth = new Web3AuthNoModal({
      clientId: web3AuthClientId,
      web3AuthNetwork,
      chainConfig,
      sessionTime,
    })
    const privateKeyProvider = new CommonPrivateKeyProvider({
      config: {chainConfig},
    })

    const openloginAdapter = new OpenloginAdapter({
      privateKeyProvider,
      adapterSettings: {
        clientId: web3AuthClientId,
        network: web3AuthNetwork,
        uxMode: 'popup',
      },
    })
    web3Auth.configureAdapter(openloginAdapter)

    await web3Auth.init()

    return web3Auth
  }

  const _login: IWeb3AuthConnectionV1['login'] = async (
    loginProvider: Web3AuthLoginProvider,
    options,
  ) => {
    const {sessionTime, sessionNamespace} = {
      sessionTime: options?.sessionTime ?? 86400, // 1 day
      sessionNamespace: options?.sessionNamespace ?? DEFAULT_SESSION_NAMESPACE,
    }
    const web3Auth = await getInitializedWeb3Auth({
      sessionTime,
      sessionNamespace,
    })
    await web3Auth.logout().catch(() => null)

    const provider = await web3Auth.connectTo(WALLET_ADAPTERS.OPENLOGIN, {
      mfaLevel: 'none', // Pass on the mfa level of your choice: default, optional, mandatory, none
      loginProvider,
    })

    assert(provider != null)

    const privateKey = Buffer.from(
      (await provider.request({
        method: 'private_key',
      })) as string,
      'hex',
    )
    const userInfo = await web3Auth.getUserInfo()

    if (sessionTime <= 0) {
      await web3Auth.logout().catch(() => null)
    }

    copyWeb3AuthSession(
      NATIVE_SESSION_KEY,
      `${NATIVE_SESSION_KEY}_${sessionNamespace}`,
    )

    return {
      privateKey,
      userInfo,
    }
  }

  const login: IWeb3AuthConnectionV1['login'] = async (
    loginProvider: Web3AuthLoginProvider,
    options,
  ) => {
    // The default redirect back does not work well in full screen on Mac so we remember
    // the current windowId and tabId and try to navigate user back once the web3Auth process
    // is finished.
    let currentWindowId = null
    let currentTabId = null
    if (getIsWebExtension()) {
      try {
        currentWindowId = (await chrome.windows.getCurrent()).id
        currentTabId = (await chrome.tabs.getCurrent())?.id || null
      } catch (err) {
        // This is not critical so we just ignore it
      }
    }

    const loginResult = _login(loginProvider, options)

    if (getIsWebExtension()) {
      if (currentWindowId != null) {
        await chrome.windows.update(currentWindowId, {focused: true})

        if (currentTabId != null) {
          await chrome.tabs.update(currentTabId, {active: true})
        }
      }
    }
    return loginResult
  }

  const rehydrateSession: IWeb3AuthConnectionV1['rehydrateSession'] = async (
    options,
  ) => {
    const {sessionNamespace} = {
      sessionNamespace: DEFAULT_SESSION_NAMESPACE,
      ...options,
    }

    const web3Auth = await getInitializedWeb3Auth({sessionNamespace})

    if (!web3Auth.connected) {
      return null
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const privateKeyHex = (await web3Auth.provider!.request({
      method: 'private_key',
    })) as string | undefined

    // if the session is expired, the private key is not available
    if (!privateKeyHex) {
      return null
    }

    const privateKey = Buffer.from(privateKeyHex, 'hex')
    const userInfo = await web3Auth.getUserInfo()

    return {
      privateKey,
      userInfo,
    }
  }

  const logout: IWeb3AuthConnectionV1['logout'] = async (options) => {
    const {sessionNamespace} = {
      sessionNamespace: DEFAULT_SESSION_NAMESPACE,
      ...options,
    }
    const web3Auth = await getInitializedWeb3Auth({
      sessionNamespace,
    })
    await web3Auth.logout().catch(() => null)

    // store the "wiped" session back in the namespaced key, effectively deleting it
    copyWeb3AuthSession(
      NATIVE_SESSION_KEY,
      `${NATIVE_SESSION_KEY}_${sessionNamespace}`,
    )
  }

  const getVerifierIdInfo = async (
    loginProvider: Web3AuthLoginProvider,
    verifierId: string,
  ) => {
    const verifier = verifierMaps[web3AuthNetwork]?.[loginProvider]?.verifier

    if (!verifier) {
      throw new Error(
        `Verifier not found for network ${web3AuthNetwork} and login provider ${loginProvider}`,
      )
    }

    return await _getVerifierIdInfo({
      network: web3AuthNetwork,
      verifier,
      verifierId,
    })
  }

  return {
    login,
    rehydrateSession,
    logout,
    getVerifierIdInfo,
  }
}
