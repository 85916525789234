import {HelpOutline} from '@mui/icons-material'
import {SOLANA_DEFAULT_REWARDS_EPOCH_DEPTH} from '@nufi/wallet-solana'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {LabeledIconWithTooltip} from '../../../../components'

export const RecentRewardsIconTooltip = () => {
  const {t} = useTranslation()
  const tooltipText = t('rewards_aggregated_over_N_epochs', {
    epochs: SOLANA_DEFAULT_REWARDS_EPOCH_DEPTH,
  })
  return (
    <LabeledIconWithTooltip
      Label={t('Recent rewards')}
      Icon={<HelpOutline fontSize="small" />}
      // Typography textTransform="capitalize" applies it to all words, not just the first one
      title={`${tooltipText[0]!.toUpperCase()}${tooltipText.slice(1)}`}
      spacing={0.5}
    />
  )
}
