import {Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React from 'react'

import {Alert} from '../../components'
import type {ChangeAccountVariants} from '../PlatformConfig'
import {usePlatformConfig} from '../PlatformConfig'

type ChangeAccountHintProps = {
  variant: ChangeAccountVariants
  origin: string | null
}

export function ChangeAccountHint({variant, origin}: ChangeAccountHintProps) {
  const classes = useStyles()
  const {changeAccount} = usePlatformConfig()

  return (
    <Alert severity="info">
      <>
        <Typography variant="body1">{changeAccount?.hints[variant]}</Typography>
        <Typography className={classes.origin} variant="body1">
          {origin || ''}
        </Typography>
      </>
    </Alert>
  )
}

const useStyles = makeStyles((theme) => ({
  origin: {
    wordBreak: 'break-all',
    paddingTop: theme.spacing(0.5),
    fontWeight: 'bold',
  },
}))
