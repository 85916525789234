import {
  ethereumChainIds,
  milkomedaC1ChainIds,
  optimismChainIds,
  polygonChainIds,
  evmChainIdToCaip2,
  arbitrumOneChainIds,
  baseChainIds,
} from '@nufi/wallet-evm'
import type {Web3WalletTypes} from '@walletconnect/web3wallet'

import type {Blockchain} from 'src/types'

import config from '../config'
import {dappConnectorsConfig} from '../dappConnector/config'

export const PROJECT_ID = config.walletConnectProjectId

export const PROJECT_METADATA: Web3WalletTypes.Options['metadata'] = {
  name: dappConnectorsConfig.name,
  description: dappConnectorsConfig.description,
  url: dappConnectorsConfig.website,
  icons: [dappConnectorsConfig.icons.default],
}

/**
 * a central piece of logic, adding a new namespace, or adding a new method/event to this constant
 * automatically requires on TS level to implement it
 */
export const supportedNamespaces = {
  solana: {
    chains: ['solana:4sGjMW1sUnHzSxGspuhpqLDx6wiyjNtZ'],
    methods: ['solana_signTransaction', 'solana_signMessage'],
    events: [],
  },
  cip34: {
    chains: ['cip34:1-764824073', 'cip34:0-1', 'cip34:0-2', 'cip34:0-4'],
    methods: [
      'cardano_signTx',
      'cardano_signData',
      'cardano_submitTx',
      'cardano_getBalance',
      'cardano_getCollateral',
      'cardano_getUtxos',
      'cardano_getNetworkId',
      'cardano_getUsedAddresses',
      'cardano_getUnusedAddresses',
      'cardano_getChangeAddress',
      'cardano_getRewardAddress',
      'cardano_getRewardAddresses',
    ],
    // for now we do not really support these events, but since they are meant to be fired by the
    // wallet, and we do not fire them, we are safe to pretend we support them
    events: [
      'cardano_onNetworkChange',
      'cardano_onAccountChange',
      // to be compatible with MinSwap which develops cardano WalletConnect wallet and dapp examples so this might became the standard
      // https://github.com/minswap/wallet-connect
      'chainChanged',
      'accountsChanged',
    ],
  },
  eip155: {
    chains: [
      evmChainIdToCaip2(ethereumChainIds.homestead),
      evmChainIdToCaip2(ethereumChainIds.sepolia),
      evmChainIdToCaip2(polygonChainIds.mainnet),
      evmChainIdToCaip2(polygonChainIds.amoy),
      evmChainIdToCaip2(optimismChainIds.mainnet),
      evmChainIdToCaip2(optimismChainIds.sepolia),
      evmChainIdToCaip2(milkomedaC1ChainIds.mainnet),
      evmChainIdToCaip2(milkomedaC1ChainIds.devnet),
      evmChainIdToCaip2(arbitrumOneChainIds.mainnet),
      evmChainIdToCaip2(arbitrumOneChainIds.sepolia),
      evmChainIdToCaip2(baseChainIds.mainnet),
      evmChainIdToCaip2(baseChainIds.sepolia),
    ],
    methods: [
      // https://docs.walletconnect.com/walletkit/web/usage#evm-methods--events
      'eth_accounts',
      'eth_requestAccounts',
      'eth_signTypedData',
      'eth_signTypedData_v3',
      'eth_signTypedData_v4',
      'eth_sendTransaction',
      'personal_sign',
      'wallet_addEthereumChain',
      'wallet_switchEthereumChain',
      'wallet_getPermissions',
      'wallet_requestPermissions',
      'wallet_watchAsset',
    ],
    events: [
      // same situation as cardano events above
      'message',
      'connect',
      'disconnect',
      'chainChanged',
      'accountsChanged',
    ],
  },
} as const

export const walletConnectBlockchains = [
  'solana',
  'cardano',
  'ethereum',
  'polygon',
  'optimism',
  'milkomedaC1',
  'arbitrumOne',
  'base',
] as const satisfies readonly Blockchain[]
