import {ApiError, ApiErrorCode} from '@nufi/dapp-client-cardano'
import type {
  BlockDate,
  GovernanceKey,
  Proposal,
  Vote,
  CardanoAddress,
  CardanoTokenBundle,
  Lovelaces,
  OwnUtxo,
  CardanoTxWitness,
  CardanoSignedData,
} from '@nufi/wallet-cardano'
import {
  addressToCslAddress,
  amountToCslAmount,
  utxoToCslUtxo,
  witnessesToCslWitnessSet,
  PUBLIC_KEY_LENGTH,
} from '@nufi/wallet-cardano'
import _ from 'lodash'

import type {AccountId} from '../../../types'
import {isHexString} from '../../../utils/helpers'
import {getCardano} from '../../../wallet/cardano'

import {ensureCatalystVotingPurpose} from './injectedUtils'
import type {Cbor, TransactionUnspentOutput} from './types'

export const fetchAccountInfo = async (accountId: AccountId) =>
  await getCardano().accountManager.getAccountInfo(
    getCardano().accountsStore.getAccount(accountId),
  )

export const encodeAddress = (address: CardanoAddress): Cbor<'address'> =>
  Buffer.from(addressToCslAddress(address).to_bytes()).toString(
    'hex',
  ) as Cbor<'address'>

export const encodeBalance = (
  lovelaces: Lovelaces,
  tokensBalance: CardanoTokenBundle,
): Cbor<'value'> => {
  const cslAmount = amountToCslAmount(lovelaces, tokensBalance)

  return Buffer.from(cslAmount.to_bytes()).toString('hex') as Cbor<'value'>
}

export const encodeUtxo = (utxo: OwnUtxo): TransactionUnspentOutput =>
  Buffer.from(utxoToCslUtxo(utxo).to_bytes()).toString(
    'hex',
  ) as TransactionUnspentOutput

export const encodeWitnesses = (witnesses: CardanoTxWitness[]) =>
  Buffer.from(witnessesToCslWitnessSet(witnesses).to_bytes()).toString(
    'hex',
  ) as Cbor<'transaction_witness_set'>

export const encodeSignedData = ({signature, key}: CardanoSignedData) => ({
  signature: signature.toString('hex') as Cbor<'COSE_Sign1'>,
  key: key.toString('hex') as Cbor<'COSE_Key'>,
})

export function ensureValidDelegations(
  delegations: unknown,
): asserts delegations is GovernanceKey[] {
  const error = new ApiError(
    ApiErrorCode.InvalidArgumentError,
    `Invalid Delegations ${JSON.stringify(delegations)}`,
  )
  if (!_.isArray(delegations) || delegations.length === 0) {
    throw error
  }

  for (const delegation of delegations) {
    if (
      !('weight' in delegation) ||
      !('votingKey' in delegation) ||
      !_.isInteger(delegation.weight) ||
      !(delegation.weight > 0 && delegation.weight < Math.pow(2, 32) - 1) ||
      !isHexString(delegation.votingKey) ||
      delegation.votingKey.length !== PUBLIC_KEY_LENGTH * 2
    )
      throw error
  }
}

function ensureValidProposal(
  proposal: unknown,
): asserts proposal is Proposal[] {
  const error = new ApiError(
    ApiErrorCode.InvalidArgumentError,
    `Invalid Vote Proposal ${JSON.stringify(proposal)}`,
  )

  if (
    !_.isObject(proposal) ||
    !('votePublic' in proposal) ||
    !('votePlanId' in proposal) ||
    !('proposalIndex' in proposal) ||
    !_.isBoolean(proposal.votePublic) ||
    !_.isString(proposal.votePlanId) ||
    !_.isInteger(proposal.proposalIndex)
  ) {
    throw error
  }

  if (!proposal.votePublic) {
    if (!('voteOptions' in proposal && 'voteEncKey' in proposal)) {
      throw error
    }
    if (!_.isNumber(proposal.voteOptions) || proposal.voteOptions === 0) {
      throw error
    }
    if (!_.isString(proposal.voteEncKey)) {
      throw error
    }
  }
}

function ensureValidBlockDate(
  blockDate: unknown,
): asserts blockDate is BlockDate {
  const error = new ApiError(
    ApiErrorCode.InvalidBlockDateError,
    `Invalid Block Date ${JSON.stringify(blockDate)}`,
  )

  if (
    !_.isObject(blockDate) ||
    !('epoch' in blockDate) ||
    !('slot' in blockDate) ||
    !_.isInteger(blockDate.epoch) ||
    !_.isInteger(blockDate.slot)
  ) {
    throw error
  }
}

export function ensureValidVotes(votes: unknown): asserts votes is Vote[] {
  const error = new ApiError(
    ApiErrorCode.InvalidArgumentError,
    `Invalid Votes ${JSON.stringify(votes)}`,
  )
  if (!_.isArray(votes) || votes.length === 0) {
    throw error
  }

  for (const vote of votes) {
    if (
      !('proposal' in vote) ||
      !('choice' in vote) ||
      !('expiration' in vote) ||
      !('purpose' in vote) ||
      !_.isInteger(vote.choice)
    ) {
      throw error
    }

    ensureCatalystVotingPurpose([vote.purpose])
    ensureValidProposal(vote.proposal)
    ensureValidBlockDate(vote.expiration)
  }
}

export function ensureValidVoteSettings(
  settingsJson: unknown,
): asserts settingsJson is string {
  const error = new ApiError(
    ApiErrorCode.InvalidArgumentError,
    `Invalid Vote Settings ${JSON.stringify(settingsJson)}`,
  )

  if (!_.isString(settingsJson)) {
    throw error
  }

  try {
    JSON.parse(settingsJson)
  } catch (e) {
    throw error
  }
}
