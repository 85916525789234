import {Box, Divider, Typography} from '@mui/material'
import type {FormikProps} from 'formik'
import React from 'react'
import {useTranslation} from 'react-i18next'

import type {StakingBlockchain} from '../../../../blockchainTypes'
import {
  ModalLayout,
  ModalFooter,
  StakeTransitionCardSummary,
  FooterLayout,
  Icon,
  useModalSharedStyles,
  AmountRow,
  TotalRow,
  Alert,
  CurrentPasswordField,
  ValidatorTransitionCardContent,
} from '../../../../components'
import {useIsPasswordVerificationRequired} from '../../../../store/auth'
import type {AccountInfo} from '../../../../wallet'
import type {CardanoStakepoolInfo, Lovelaces} from '../../../../wallet/cardano'
import {stakepoolIdToBech32} from '../../../../wallet/cardano'
import {StakeModalHeader} from '../../common/utils'
import {StakingStringEllipsis} from '../../flow/utils'
import type {ActivateStakeSchema} from '../schema'

type SummaryScreenProps<T extends ActivateStakeSchema> = {
  blockchain: StakingBlockchain
  onClose: () => void
  onBack: () => unknown
  onSubmit: () => unknown
  summary: {
    fee: Lovelaces
    fromAccount: AccountInfo
    validatorInfo: CardanoStakepoolInfo
    deposit: Lovelaces
  }
  formikProps: FormikProps<T>
}

const FORM_ID = 'create-stake-account-form-confirm-details'

export default function SummaryScreen<T extends ActivateStakeSchema>({
  onBack,
  onSubmit,
  blockchain,
  onClose,
  summary,
  formikProps,
}: SummaryScreenProps<T>) {
  const {t} = useTranslation()
  const classes = useModalSharedStyles()
  const isPasswordVerificationRequired = useIsPasswordVerificationRequired(
    summary.fromAccount,
  )

  return (
    <form
      onSubmit={onSubmit}
      noValidate
      id={FORM_ID}
      className={classes.formWrapper}
    >
      <ModalLayout
        header={<StakeModalHeader {...{onClose, blockchain}} />}
        body={
          <Box p={2}>
            <StakeTransitionCardSummary
              {...{...summary, blockchain}}
              targetContent={
                <ValidatorTransitionCardContent
                  CustomIcon={<Icon type={'grayCube'} />}
                >
                  <Box pl={1} display={'flex'} alignItems="center">
                    <Typography variant="body2">
                      {summary.validatorInfo?.name || t('Unknown pool')}
                      {summary.validatorInfo?.ticker
                        ? ` - ${summary.validatorInfo?.ticker}`
                        : ''}
                    </Typography>
                    <Typography pl={1} variant="body2" display="inline-flex">
                      (
                      <StakingStringEllipsis
                        value={stakepoolIdToBech32(
                          summary.validatorInfo.poolId,
                        )}
                      />
                      )
                    </Typography>
                  </Box>
                </ValidatorTransitionCardContent>
              }
            />
            <Box my={2}>
              <Alert
                text={t(
                  'Your whole account balance is staked automatically. ADA added to this account in the future is staked automatically. Staked ADA is not locked, you can transfer it anytime.',
                )}
                severity="info"
              />
            </Box>
            <Box className={classes.commonTopMargin}>
              {summary.deposit.gt(0) && (
                <AmountRow
                  label={t('Refundable staking deposit fee')}
                  amount={summary.deposit}
                  blockchain={blockchain}
                />
              )}
              <AmountRow
                label={t('Network fee')}
                amount={summary.fee}
                blockchain={blockchain}
              />
              <Divider className={classes.dividerY} />
              <TotalRow
                nativeAmount={summary.fee.plus(summary.deposit)}
                blockchain={blockchain}
                totalText={t('Total')}
              />
            </Box>
          </Box>
        }
        footer={
          <ModalFooter hasDivider>
            {isPasswordVerificationRequired && (
              <CurrentPasswordField
                {...{formikProps}}
                className={classes.passwordHeight}
              />
            )}
            <FooterLayout
              leftBtnConf={{
                onClick: onBack,
                children: t('Back'),
              }}
              rightBtnConf={{
                children: t('Submit'),
                type: 'submit',
                form: FORM_ID,
                disabled: formikProps.isSubmitting,
              }}
            />
          </ModalFooter>
        }
      />
    </form>
  )
}
