import queryClient from 'src/queryClient'
import {minutesToMilliseconds} from 'src/wallet/utils/common'

import {conversionRatesQueryKeys} from './queryKeys'

export type RefetchOptions = {
  gcTime: number
  staleTime: number
  meta: {
    onSettled: () => void
  }
}

export type ConversionRateRefetchParams = {
  refetchOptions: RefetchOptions
}

export const defaultCacheOptions = {
  gcTime: minutesToMilliseconds(1),
  staleTime: minutesToMilliseconds(1),
  meta: {
    onSettled: () =>
      queryClient.invalidateQueries({queryKey: conversionRatesQueryKeys.all}),
  },
}

export const defaultConversionRatesExtraArgs = {
  refetchOptions: defaultCacheOptions,
}
