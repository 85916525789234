import {useTranslation} from 'react-i18next'
import * as yup from 'yup'

import {MAX_ACCOUNT_NAME_CHARACTERS} from 'src/constants'

export const useAccountNameValidation = () => {
  const {t} = useTranslation()

  return yup
    .string()
    .max(
      MAX_ACCOUNT_NAME_CHARACTERS - 1,
      t('max_account_name_characters', {
        limit: MAX_ACCOUNT_NAME_CHARACTERS,
      }),
    )
    .required(t('Required field.'))
}
