import {FLOW_VALIDATORS} from '@nufi/wallet-flow'
import type {FlowAccountInfo} from '@nufi/wallet-flow'
import type {SolanaCluster, SolanaStakeAccountInfo} from '@nufi/wallet-solana'
import {SOLANA_VALIDATORS} from '@nufi/wallet-solana'
import BigNumber from 'bignumber.js'
import {useEffect, useRef} from 'react'

import {useCurrentProfileMeta} from 'src/appStorage'
import {tokenBlockchains} from 'src/blockchainTypes'
import config from 'src/config'
import {useAssets} from 'src/features/assets'
import {convertBalance} from 'src/features/assets/domain/conversions'
import {useGetConversionRates} from 'src/features/conversionRates/application'
import type {ConversionRates} from 'src/features/conversionRates/domain'
import {useCurrentLoginInfo} from 'src/store/auth'
import type {AccountId, AccountInfo, Blockchain, TokenMetadata} from 'src/types'
import {calculateTotalAssetValue} from 'src/utils/totalAssetValue'
import type {TokenBalancesByAccount} from 'src/wallet'
import {
  useAllAccounts,
  useGetAllTokensMetadata,
  useGetBalances,
  useGetTokenBalancesByAccount,
} from 'src/wallet'
import {useGetStakeAccounts} from 'src/wallet/cardano'
import type {EvmAccountInfo, EvmBlockchain} from 'src/wallet/evm'
import {getAvailableEvmBlockchains, isEvmBlockchain} from 'src/wallet/evm'
import {
  getTotalStakedBalance,
  getTotalStakingBalance,
  useGetAccounts as useFlowAccounts,
} from 'src/wallet/flow'
import {useGetStakeAccountsInfo} from 'src/wallet/solana'

import {TRACKING_CURRENCY} from '../constants'
import {setUserId, setUserProperties} from '../utils'

const sumBalance = <T extends AccountInfo | SolanaStakeAccountInfo>(
  accounts: T[],
  getValue: (t: T) => BigNumber,
) => accounts.reduce((acc, curr) => acc.plus(getValue(curr)), new BigNumber(0))

const useSetStakingProperties = () => {
  const {data: solanaAccountWithStakeAccounts} = useGetStakeAccountsInfo()
  const {data: cardanoStakeAccounts} = useGetStakeAccounts()
  const {data: flowAccountInfos} = useFlowAccounts()

  const alreadyTracked = useRef(false)

  useEffect(() => {
    if (
      solanaAccountWithStakeAccounts &&
      cardanoStakeAccounts &&
      flowAccountInfos &&
      !alreadyTracked.current
    ) {
      alreadyTracked.current = true

      // solana
      const solanaStakeAccounts = solanaAccountWithStakeAccounts
        .map(({stakeAccounts}) => stakeAccounts)
        .flat()
      const solanaStaked = sumBalance(
        solanaStakeAccounts,
        ({activeBalance}) => activeBalance,
      )
      const solanaStakedToRecommended = sumBalance(
        solanaStakeAccounts.filter(
          ({validator}) =>
            validator?.toString() ===
            SOLANA_VALIDATORS[config.solanaClusterName as SolanaCluster],
        ),
        // ? shouldn't the solana cluster be in account info
        ({activeBalance}) => activeBalance,
      )

      // cardano
      const cardanoStakedAccounts = cardanoStakeAccounts.filter(
        ({isStakingKeyRegistered, delegation}) =>
          isStakingKeyRegistered && !!delegation,
      )
      const cardanoStaked = sumBalance(
        cardanoStakedAccounts,
        ({balance, rewards}) => balance.plus(rewards),
      )
      const cardanoStakedToRecommended = sumBalance(
        cardanoStakedAccounts.filter(
          ({stakingRecommendation}) =>
            stakingRecommendation.isInPrivatePoolSet ||
            stakingRecommendation.isInRecommendedPoolSet,
        ),
        ({balance, rewards}) => balance.plus(rewards),
      )

      // flow
      const flowStaked = sumBalance(flowAccountInfos, ({stakingInfo}) =>
        getTotalStakedBalance(stakingInfo),
      )

      const flowStakedToRecommended = sumBalance(
        flowAccountInfos,
        ({stakingInfo}) => {
          if (!stakingInfo.isStakingCollectionRegistered) {
            return new BigNumber(0)
          }
          const delegationsToRecommended = stakingInfo.delegations.filter(
            (d) => d.nodeId === FLOW_VALIDATORS[config.flowNetwork],
          )

          return getTotalStakedBalance({
            ...stakingInfo,
            delegations: delegationsToRecommended,
          })
        },
      )

      setUserProperties({
        solana_staked: solanaStaked.toNumber(),
        solana_to_recommended: solanaStakedToRecommended.toNumber(),
        cardano_staked: cardanoStaked.toNumber(),
        cardano_to_recommended: cardanoStakedToRecommended.toNumber(),
        flow_staked: flowStaked.toNumber(),
        flow_to_recommended: flowStakedToRecommended.toNumber(),
      })
    }
  }, [solanaAccountWithStakeAccounts, cardanoStakeAccounts, flowAccountInfos])
}

const sumFiatBalance = <
  T extends {id: AccountId | null; balance: BigNumber; blockchain: Blockchain},
>(
  accounts: T[],
  tokenBalancesByAccount: TokenBalancesByAccount,
  tokensMetadata: TokenMetadata[],
  conversionRates: ConversionRates,
) =>
  accounts.reduce((acc, {id: accountId, balance, blockchain}) => {
    const convertedBalanceRes = convertBalance({
      balance,
      blockchain,
      currency: TRACKING_CURRENCY,
      conversionRates,
    })
    if (convertedBalanceRes.type === 'success') {
      acc = acc.plus(convertedBalanceRes.balance)
    }
    const accountTokenBalances =
      accountId != null ? tokenBalancesByAccount[accountId] ?? {} : {}
    Object.entries(accountTokenBalances).forEach(([tokenId, tokenBalance]) => {
      const tokenMetadata = tokensMetadata.find(
        (metadata) => metadata.id === tokenId,
      )
      if (!tokenMetadata) {
        return
      }
      const convertedTokenBalanceRes = convertBalance({
        balance: tokenBalance,
        blockchain: tokenMetadata.blockchain,
        currency: TRACKING_CURRENCY,
        conversionRates,
        tokenMetadata,
      })
      if (convertedTokenBalanceRes.type === 'success') {
        acc = acc.plus(convertedTokenBalanceRes.balance)
      }
    })

    return acc
  }, new BigNumber(0))

const useSetBalanceProperties = () => {
  const {data: allAccounts} = useAllAccounts()
  const {data: cardanoStakeAccounts} = useGetStakeAccounts()
  const {data: solanaAccountsWithStakeAccounts} = useGetStakeAccountsInfo()
  const conversionRates = useGetConversionRates()
  const {
    data: allTokensMetadataPerChain,
    isLoading: loadingAllTokensMetadataPerChain,
  } = useGetAllTokensMetadata()
  const {
    data: tokenBalancesByAccount,
    isLoading: loadingTokenBalancesByAccount,
  } = useGetTokenBalancesByAccount()
  const {data: balancesResult, isLoading: loadingTotalBalance} =
    useGetBalances()

  const alreadyTracked = useRef(false)

  useEffect(() => {
    if (
      allAccounts &&
      cardanoStakeAccounts &&
      solanaAccountsWithStakeAccounts &&
      conversionRates &&
      tokenBalancesByAccount &&
      !loadingTokenBalancesByAccount &&
      balancesResult &&
      !loadingTotalBalance &&
      conversionRates.initialLoadingFinished &&
      allTokensMetadataPerChain &&
      !loadingAllTokensMetadataPerChain &&
      !alreadyTracked.current
    ) {
      alreadyTracked.current = true
      const solanaStakeAccounts = solanaAccountsWithStakeAccounts
        .map(({stakeAccounts, cryptoProviderType, blockchain}) =>
          stakeAccounts.map((a) => ({...a, cryptoProviderType, blockchain})),
        )
        .flat()
        .map(({cryptoProviderType, balance, blockchain}) => ({
          id: null,
          balance,
          blockchain,
          cryptoProviderType,
        }))

      const solanaAccounts = solanaAccountsWithStakeAccounts.map(
        ({id, balance, cryptoProviderType, blockchain}) => ({
          id,
          balance,
          cryptoProviderType,
          blockchain,
        }),
      )

      const cardanoAccounts = cardanoStakeAccounts.map(
        ({id, balance, rewards, cryptoProviderType, blockchain}) => ({
          id,
          balance: balance.plus(rewards),
          cryptoProviderType,
          blockchain,
        }),
      )

      const flowAccountInfos = allAccounts.filter(
        (account): account is FlowAccountInfo => account.blockchain === 'flow',
      )

      const flowAccounts = flowAccountInfos.map(
        ({id, balance, cryptoProviderType, blockchain, stakingInfo}) => ({
          id,
          balance: balance.plus(getTotalStakingBalance(stakingInfo)),
          cryptoProviderType,
          blockchain,
        }),
      )

      const evmAccounts = allAccounts.filter(
        (account): account is EvmAccountInfo<EvmBlockchain> =>
          isEvmBlockchain(account.blockchain),
      )

      const accounts = [
        solanaStakeAccounts,
        cardanoAccounts,
        solanaAccounts,
        flowAccounts,
        evmAccounts,
      ].flat()
      const ledgerAccounts = accounts.filter(
        ({cryptoProviderType}) => cryptoProviderType === 'ledger',
      )
      const trezorAccounts = accounts.filter(
        ({cryptoProviderType}) => cryptoProviderType === 'trezor',
      )
      const gridPlusAccounts = accounts.filter(
        ({cryptoProviderType}) => cryptoProviderType === 'gridPlus',
      )
      const mnemonicAccounts = accounts.filter(
        ({cryptoProviderType}) => cryptoProviderType === 'mnemonic',
      )

      const allTokensMetadata = tokenBlockchains.flatMap(
        (blockchain) =>
          allTokensMetadataPerChain.tokensMetadata[blockchain] ?? [],
      )
      const ledgerFiatBalance = sumFiatBalance(
        ledgerAccounts,
        tokenBalancesByAccount,
        allTokensMetadata,
        conversionRates.rates,
      )
      const trezorFiatBalance = sumFiatBalance(
        trezorAccounts,
        tokenBalancesByAccount,
        allTokensMetadata,
        conversionRates.rates,
      )
      const gridPlusFiatBalance = sumFiatBalance(
        gridPlusAccounts,
        tokenBalancesByAccount,
        allTokensMetadata,
        conversionRates.rates,
      )
      const mnemonicFiatBalance = sumFiatBalance(
        mnemonicAccounts,
        tokenBalancesByAccount,
        allTokensMetadata,
        conversionRates.rates,
      )

      setUserProperties({
        ledger_balance: ledgerFiatBalance.toNumber(),
        trezor_balance: trezorFiatBalance.toNumber(),
        gridplus_balance: gridPlusFiatBalance.toNumber(),
        mnemonic_balance: mnemonicFiatBalance.toNumber(),

        total_balance: calculateTotalAssetValue(
          balancesResult,
          TRACKING_CURRENCY,
          conversionRates.rates,
          allTokensMetadataPerChain.tokensMetadata,
        ),
        cardano_balance: balancesResult.coins.cardano?.toNumber() || 0,
        solana_balance: balancesResult.coins.solana?.toNumber() || 0,
        flow_balance: balancesResult.coins.flow?.toNumber() || 0,
        ...(Object.fromEntries(
          getAvailableEvmBlockchains().map((evmBlockchain) => [
            `${evmBlockchain}_balance`,
            balancesResult.coins[evmBlockchain]?.toNumber() || 0,
          ]),
        ) as Record<`${EvmBlockchain}_balance`, number>),
        // TODO: refactor this, so there is an interface which is returned for every blockchain
        // and this central logic does not change
      })
    }
  }, [
    allAccounts,
    cardanoStakeAccounts,
    solanaAccountsWithStakeAccounts,
    conversionRates,
    tokenBalancesByAccount,
    balancesResult,
    allTokensMetadataPerChain,
  ])
}

const useSetAssetsProperties = () => {
  const {data: assets, partialLoadings} = useAssets(
    TRACKING_CURRENCY,
    'availableBalanceToCurrency',
    'asc',
  )
  const alreadyTracked = useRef(false)
  const assetsLoaded = partialLoadings.length === 0

  useEffect(() => {
    if (assets && assetsLoaded && !alreadyTracked.current) {
      alreadyTracked.current = true
      const assetsWithBalance = assets.filter(({totalBalance}) =>
        totalBalance.gt(0),
      )
      const coins = assetsWithBalance.filter(
        ({tokenMetadata}) => !tokenMetadata,
      )
      const tokens = assetsWithBalance.filter(
        ({tokenMetadata}) => !!tokenMetadata,
      )
      setUserProperties({
        n_tokens: tokens.length,
        n_coins: coins.length,
      })
    }
  }, [assets])
}

export const useInitProfileTracking = () => {
  const {data: profileData} = useCurrentProfileMeta()
  const currentLoginInfo = useCurrentLoginInfo()

  const alreadyTracked = useRef(false)
  useEffect(() => {
    if (profileData && !alreadyTracked.current) {
      alreadyTracked.current = true

      const userId =
        profileData.mnemonicStorageType === 'local'
          ? profileData.mnemonicHash
          : profileData.identitySecretHash

      setUserId(userId)
      setUserProperties({
        user_id: userId,
        custom_user_id: userId,
        ...(currentLoginInfo.loginType === 'web3Auth'
          ? {
              is_web3_auth_login: true,
              web3_auth_login_provider: currentLoginInfo.user.typeOfLogin,
              web3_auth_login_version:
                currentLoginInfo.user.currentLoginVersion,
              web3_auth_migrated_from_v1: currentLoginInfo.user.migratedFromV1,
            }
          : {
              is_web3_auth_login: false,
            }),
      })
    }
  }, [profileData])

  useSetStakingProperties()
  useSetBalanceProperties()
  useSetAssetsProperties()
}
