import type {TxHistoryEntry} from '@nufi/wallet-common'
import type BigNumber from 'bignumber.js'

import type {AllAvailableAccountsResponse} from '../../../types'
import {getEvmManager} from '../../evmManagers'
import type {
  EvmAccountInfo,
  EvmAllAvailableAccountsResponse,
  EvmBlockchain,
  EvmTokenId,
  EvmTxHistoryEntry,
} from '../../types'

export const getAccountInfoForDiscoveryResponse = async <
  TBlockchain extends EvmBlockchain,
>(
  blockchain: TBlockchain,
  response: EvmAllAvailableAccountsResponse,
): Promise<AllAvailableAccountsResponse<EvmAccountInfo<TBlockchain>>> =>
  'canNotAddAccountReason' in response
    ? response
    : {
        accounts: await getEvmManager(blockchain).getAccountInfos(
          response.accounts,
        ),
      }

export const formatTxHistoryEntries = <TBlockchain extends EvmBlockchain>(
  blockchain: TBlockchain,
  data: EvmTxHistoryEntry<TBlockchain>[] | undefined,
): TxHistoryEntry<TBlockchain, EvmTokenId<TBlockchain>>[] | undefined =>
  data &&
  data
    .map((tx) => ({
      effects: (tx.weiEffect != null
        ? [
            {
              amount: tx.weiEffect as BigNumber,
              blockchain,
            },
          ]
        : []
      )
        .concat(
          tx.tokenEffects.map((te) => ({
            blockchain: te.token.blockchain,
            amount: te.amount,
            tokenId: te.token.id,
          })),
        )
        .filter((e) => !e.amount.isZero()),
      fee: tx.fee,
      transactionId: tx.txHash,
      time: tx.time,
      operations: [tx.tokenEffects.length ? 'tokenTransfer' : 'transfer'],
    }))
    // self tx can end up without any effects, filter those
    .filter((tx) => tx.effects.length)
