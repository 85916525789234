import BigNumber from 'bignumber.js'

export const initBigNumber = () => {
  // Calling .toString() results in scientific notation for large numbers.
  // This causes issues in UI or when passed to some other libraries e.g.
  // Bignumber used by ethers library.
  // This change in config forces .toString() to avoid scientific notation for all reasonable
  // numbers. Though it is better to use .toFixed() when formatting numbers.
  BigNumber.config({EXPONENTIAL_AT: 1e9})
}
