import _ from 'lodash'
import {useCallback, useEffect, useState} from 'react'

import {useTransactionState} from '../../../store/transactions'
import type {Blockchain} from '../../../types'
import {useEffectWithDelay, useEffectWithPoll} from '../../../utils/helpers'
import {useEnabledEvmBlockchains} from '../../../wallet/evm'

import {updateEvmTransactionsState} from './evm'

const REFRESH_INTERVAL = 5000

export const useShouldRenderTransactionsInfo = () => {
  // Minor UI optimization:
  // If there are some pending tx from previous session of the main app (same tab)
  // it is fine to wait a few seconds before rendering their status, otherwise
  // user could see a transition from "pending" -> "success" immediately on login,
  // but that transition could happen already far in the past, and can be seen
  // as a minor UI glitch.
  const [shouldRenderTransactions, setShouldRenderTransactions] = useState(
    // We can render transactions immediately, if none are labelled as pending
    // from the last session
    useTransactionState
      .getState()
      .getVisibleTransactions()
      .every((t) => !t.isPending),
  )

  const fn = useCallback(
    () => setShouldRenderTransactions(true),
    [setShouldRenderTransactions],
  )

  useEffectWithDelay(fn, 2000)

  return shouldRenderTransactions
}

export const useListenForTxStateChange = (blockchain: Blockchain | null) => {
  const evmEnabledBlockchains = useEnabledEvmBlockchains()
  const checkTxState = useCallback(async () => {
    const {getVisibleTransactions} = useTransactionState.getState()

    // Passing `blockchain` allows for reuse in connector window
    const transactions = getVisibleTransactions()
      .filter(
        (tx) => blockchain == null || tx.context?.blockchain === blockchain,
      )
      // Note that we have to also observe transactions that are still observed
      // by react-query, as if creating more than a single transaction in the current
      // session, we would be only able to observe the last one with react-query.
      //
      // We are interested only in loading txs
      .filter((tx) => tx.isPending)

    const sortedTransactions = _.orderBy(
      transactions,
      (tx) => tx.createdAt,
      'asc',
    )

    await Promise.all(
      evmEnabledBlockchains.map((evmBlockchain) =>
        updateEvmTransactionsState(
          evmBlockchain,
          sortedTransactions.filter(
            (tx) => tx.context?.blockchain === evmBlockchain,
          ),
        ),
      ),
    )
  }, [blockchain])

  // Check on app load
  useEffect(() => {
    checkTxState()
  }, [])

  // Re-check every few seconds
  useEffectWithPoll(checkTxState, REFRESH_INTERVAL)
}
