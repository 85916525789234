/* eslint-disable @typescript-eslint/explicit-function-return-type */

import type {
  FlowAccountInfo,
  FlowAccountStoredData,
  FlowAddressNameServiceProfile,
  FlowNewAccount,
  FlowSignedTransaction,
  FlowTxPlan,
} from '@nufi/wallet-flow'

import {useMutation} from 'src/utils/mutation-utils'

import type {TransactionContext} from '../../../store/transactions'
import {ensureNoPendingTx} from '../../../store/transactions'
import type {AccountId} from '../../types'
import {_saveAccounts} from '../../utils/accountsMutations'
import {flow} from '../flowManagers'

import {accountsInvalidationKeys} from './invalidationKeys'

export const mutationKeys = {
  flowSignTx: ['flowSignTx'],
  flowSubmitTx: ['flowSubmitTx'],
  flowAddAccount: ['flowAddAccount'],
  resolveAddressName: ['resolveAddressName'],
}

type SignTxArgs = {
  txPlan: FlowTxPlan
  accountInfo: FlowAccountInfo
}

export function useSignTx() {
  return useMutation(
    mutationKeys.flowSignTx,
    async ({txPlan, accountInfo}: SignTxArgs) => {
      ensureNoPendingTx(accountInfo.id)
      return await flow.accountManager.signTx(txPlan, accountInfo)
    },
  )
}

type SubmitTxArgs = {
  accountId: AccountId
  signedTx: FlowSignedTransaction
}

export function useSubmitTx() {
  return useMutation(
    mutationKeys.flowSubmitTx,
    async ({signedTx}: SubmitTxArgs) => await flow.wallet.submitTx(signedTx),
    {
      invalidationKeys: accountsInvalidationKeys,
      onMutate: (params): TransactionContext => ({
        blockchain: 'flow' as const,
        accountId: params.accountId,
        // TODO: currently, tx hash is not in the signed transaction figure out the way to get it there
      }),
    },
  )
}

async function saveAccounts(accounts: FlowAccountStoredData[]): Promise<void> {
  await _saveAccounts('flow', accounts)
}

export type AddAccountArgs = {
  newAccounts: FlowNewAccount[]
}

export function useAddAccounts() {
  return useMutation(
    mutationKeys.flowAddAccount,
    async ({newAccounts}: AddAccountArgs) => {
      const accounts = flow.accountsStore.getAllAccounts()
      const updatedAccounts = await flow.wallet.addAccounts(
        accounts,
        newAccounts,
      )
      await saveAccounts(updatedAccounts)
    },
    {invalidationKeys: accountsInvalidationKeys},
  )
}

export type AddressNameResolver = (
  address: string,
) => Promise<FlowAddressNameServiceProfile | null>

export async function resolveAddressName(
  name: string,
): ReturnType<AddressNameResolver> {
  return flow.wallet.getAddressNameServiceProfile(name).catch(() => {
    // We silently ignore errors, and handle them as address name not found
    return null
  })
}

// resolver passed from outside to make components using this hook testable
export function useResolveAddressName(resolver: AddressNameResolver) {
  return useMutation(mutationKeys.resolveAddressName, resolver)
}
