import {
  assetBaseUnitToMain as _assetBaseUnitToMain,
  assetMainToBaseUnit as _assetMainToBaseUnit,
} from '@nufi/wallet-common'
import BigNumber from 'bignumber.js'

import {getBlockchainDecimals} from '../../constants'
import type {Blockchain} from '../../types'
import {safeAssertUnreachable} from '../../utils/assertion'
import {isEvmBlockchain} from '../evm/utils'

const getAssetBase = (blockchain: Blockchain): BigNumber => {
  switch (blockchain) {
    case 'solana':
    case 'cardano':
    case 'flow':
      return new BigNumber(10).pow(getBlockchainDecimals(blockchain))
    default: {
      if (isEvmBlockchain(blockchain)) {
        return new BigNumber(10).pow(getBlockchainDecimals(blockchain))
      }
      return safeAssertUnreachable(blockchain)
    }
  }
}

export const assetBaseUnitToMain = (
  blockchain: Blockchain,
  amount: BigNumber,
): string => _assetBaseUnitToMain(getAssetBase(blockchain), amount)

export const assetMainToBaseUnit = (
  blockchain: Blockchain,
  amount: string,
): BigNumber => _assetMainToBaseUnit(getAssetBase(blockchain), amount)
