import {Typography, styled} from '@mui/material'
import {safeAssertUnreachable} from '@nufi/frontend-common'
import type {CardanoTokenMetadata} from '@nufi/wallet-cardano'
import type {EvmTokenMetadata} from '@nufi/wallet-evm'
import {isEvmBlockchain} from '@nufi/wallet-evm'
import type {FlowTokenMetadata} from '@nufi/wallet-flow'
import React from 'react'

import {WithWeakTokenMetadata} from 'src/wallet/public/ui'
import type {SolanaTokenMetadata} from 'src/wallet/solana'

import type {TokenBlockchain} from '../blockchainTypes'
import {tokenBlockchains} from '../blockchainTypes'
import type {Blockchain, TokenId, TokenMetadata} from '../types'
import {BlockchainSubsetGuard} from '../utils/blockchainGuards'
import {useGetCoinName, useGetNativeAssetName} from '../utils/translations'

import {AssetIcon} from './AssetIcon'
import {
  FormattedCardanoTokenLabel,
  FormattedEvmTokenLabel,
  FormattedFlowTokenLabel,
} from './formatting'

type BreadcrumbBlockchainItemProps = {
  blockchain: Blockchain
  tokenId?: TokenId | null
}

export function BreadcrumbBlockchainItem({
  blockchain,
  tokenId,
}: BreadcrumbBlockchainItemProps) {
  return (
    <WithWeakTokenMetadata blockchain={blockchain} tokenId={tokenId}>
      {(tokenMetadata) => (
        <AssetBreadcrumbWrapper>
          <AssetIcon
            exactSize={22}
            blockchain={blockchain}
            tokenMetadata={tokenMetadata}
            showBlockchainBadge
          />
          <Typography>
            {tokenMetadata ? (
              <BreadcrumbBlockchainTokenText
                blockchain={blockchain}
                tokenMetadata={tokenMetadata}
              />
            ) : (
              <BreadcrumbBlockchainNativeText blockchain={blockchain} />
            )}
          </Typography>
        </AssetBreadcrumbWrapper>
      )}
    </WithWeakTokenMetadata>
  )
}

function BreadcrumbBlockchainNativeText({
  blockchain,
}: {
  blockchain: Blockchain
}) {
  const getNativeAssetName = useGetNativeAssetName()
  const getCoinName = useGetCoinName()

  return (
    <>{`${getNativeAssetName(blockchain)} (${getCoinName(blockchain)})`} </>
  )
}

function BreadcrumbBlockchainTokenText({
  blockchain,
  tokenMetadata,
}: {
  blockchain: Blockchain
  tokenMetadata: TokenMetadata
}) {
  return (
    <BlockchainSubsetGuard
      blockchain={blockchain}
      blockchainSubset={tokenBlockchains}
    >
      {(tokenBlockchain) => (
        <FormattedTokenBreadcrumbs
          blockchain={tokenBlockchain}
          tokenInfo={tokenMetadata}
        />
      )}
    </BlockchainSubsetGuard>
  )
}

const AssetBreadcrumbWrapper = styled('div')(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1.5),
  marginLeft: theme.spacing(0.5),
}))

type FormattedTokenBreadcrumbsProps = {
  blockchain: TokenBlockchain
  tokenInfo: TokenMetadata
}

export function FormattedTokenBreadcrumbs({
  blockchain,
  tokenInfo,
}: FormattedTokenBreadcrumbsProps) {
  const tokenBreadCrumb = ((blockchain: TokenBlockchain) => {
    switch (blockchain) {
      case 'cardano':
        return (
          <FormattedCardanoTokenLabel
            tokenInfo={tokenInfo as CardanoTokenMetadata}
          />
        )
      case 'solana':
        return (tokenInfo as SolanaTokenMetadata).mint
      case 'flow':
        return (
          <FormattedFlowTokenLabel tokenInfo={tokenInfo as FlowTokenMetadata} />
        )
      default: {
        if (isEvmBlockchain(blockchain)) {
          return (
            <FormattedEvmTokenLabel
              tokenInfo={tokenInfo as EvmTokenMetadata<typeof blockchain>}
            />
          )
        }
        return safeAssertUnreachable(blockchain)
      }
    }
  })(blockchain)

  const {name, ticker} = tokenInfo

  return (
    <>{name ? `${name}${ticker ? ` (${ticker})` : ''}` : tokenBreadCrumb}</>
  )
}
