import type {FlowAccountInfo, FlowTxPlan, Nanoflow} from '@nufi/wallet-flow'
import {flowToNanoflow, nanoFlowToFlow} from '@nufi/wallet-flow'
import {useTranslation} from 'react-i18next'
import * as yup from 'yup'

import {useCurrentLoginInfo} from '../../../../store/auth'
import type {AccountId, AccountInfo} from '../../../../types'
import {getMaxSendableFlowAmountImperative} from '../../../../wallet/flow'
import {ensureAccountById} from '../../../../wallet/utils/common'
import {isPasswordVerificationRequired} from '../../../utils'

export type SummarySchema = {
  password: string
}

type SummarySchemaArgs = {
  accounts: Array<AccountInfo>
}

const _useSummarySchema = ({accounts}: SummarySchemaArgs) => {
  const {t} = useTranslation()
  const loginInfo = useCurrentLoginInfo()

  const accountIdKey = 'accountId'
  return {
    password: yup.string().when(accountIdKey, ([accountId]: unknown[]) => {
      const selectedAccount = ensureAccountById(
        accounts,
        accountId as AccountId,
      )
      if (isPasswordVerificationRequired(loginInfo, selectedAccount)) {
        return yup.string().required(t('Password is required.'))
      }
      return yup.string()
    }),
  }
}

// ensure consistency of keys / values on TS level
export const useSummarySchema: (
  ...args: Parameters<typeof _useSummarySchema>
) => {
  [k in keyof SummarySchema]: ReturnType<typeof _useSummarySchema>[k]
} = _useSummarySchema

type AmountMoreOrEqualToBalanceValidationProps = {
  accounts: FlowAccountInfo[]
  availableBalance?: Nanoflow
  errorMessage?: React.ReactNode
}

export const useAmountMoreOrEqualToBalanceValidation = ({
  accounts,
  availableBalance,
  errorMessage,
}: AmountMoreOrEqualToBalanceValidationProps) => {
  const {t} = useTranslation()
  return (
    yup
      .string()
      // `function` keyword needed for `this` to behave correctly
      .test(
        'amount-not-more-than-max',
        (errorMessage as yup.Message) || t('Cannot send more than available'),
        function (amount, context) {
          if (amount === undefined) return true
          const {accountId}: {accountId: AccountId; amount: Nanoflow} =
            this.parent

          return validateIsAmountMoreOrEqualToBalance({
            accountId,
            availableBalance,
            context,
            accounts,
          })
        },
      )
  )
}

export const validateIsAmountMoreOrEqualToBalance = ({
  accounts,
  availableBalance,
  context,
  accountId,
}: Omit<AmountMoreOrEqualToBalanceValidationProps, 'errorMessage'> & {
  context: yup.TestContext
  accountId: FlowAccountInfo['id']
}) => {
  const balance =
    availableBalance || ensureAccountById(accounts, accountId).balance
  // passing original value because JS numbers don't preserve original notation
  // originalValue not exposed in yup TS types, see open issue in yup::
  // https://github.com/jquense/yup/issues/1591
  return balance.isGreaterThanOrEqualTo(
    flowToNanoflow(
      (context as unknown as {originalValue: string}).originalValue,
    ),
  )
}

type SendAmountValidationProps = {
  accounts: FlowAccountInfo[]
  txType: FlowTxPlan['type']
}

export const useSendAmountValidation = ({
  accounts,
  txType,
}: SendAmountValidationProps) => {
  const validateAmount = useValidateSendAmount()
  const amountMoreOrEqualToBalanceValidation =
    useAmountMoreOrEqualToBalanceValidation({
      accounts,
    })
  return amountMoreOrEqualToBalanceValidation.concat(
    yup
      .string()
      .test('debounced-amount-validation', async function (amount, context) {
        if (amount === undefined) return true
        const {accountId}: {accountId: AccountId; amount: Nanoflow} =
          this.parent

        return await validateAmount({
          accountId,
          amount,
          accounts,
          txType,
          context,
        })
      }),
  )
}

export const useValidateSendAmount = () => {
  const {t} = useTranslation()
  return async ({
    accounts,
    txType,
    context: {createError, path},
    accountId,
    amount,
  }: SendAmountValidationProps & {
    amount: string
    context: yup.TestContext
    accountId: FlowAccountInfo['id']
  }) => {
    try {
      const account = ensureAccountById(accounts, accountId)
      const {fee, maxAmount, availableBalance} =
        await getMaxSendableFlowAmountImperative(account, txType)

      if (maxAmount.eq(0)) {
        return createError({
          path,
          message: t('FlowTxPlanError.notEnoughFundsForTx'),
        })
      }

      if (maxAmount.isLessThan(flowToNanoflow(amount.toString()))) {
        return createError({
          path,
          message: t('FlowTxPlanError.balanceLessThanAmountPlusFee', {
            maxAmount: nanoFlowToFlow(maxAmount),
          }),
        })
      }

      if (availableBalance.isLessThan(fee)) {
        const minimalBalance = account.balance.minus(maxAmount) as Nanoflow
        return createError({
          path,
          message: t('FlowTxPlanError.notEnoughFundsForFee', {
            minimalBalance: nanoFlowToFlow(minimalBalance),
          }),
        })
      }
    } catch (e) {
      return createError({
        path,
        message: (e as Error).message,
      })
    }
    return true
  }
}

// for those txs that spend only fee
export const useBalanceBiggerThanFeeValidation = ({
  accounts,
  txType,
  getFields,
}: {
  accounts: FlowAccountInfo[]
  txType: FlowTxPlan['type']
  getFields?: (context: yup.TestContext) => {accountId: AccountId}
}) => {
  const {t} = useTranslation()
  return yup
    .string()
    .test('debounced-amount-validation', async function (amount, context) {
      const {createError, path} = context
      if (amount === undefined) return true
      const fields: {accountId: AccountId} = getFields?.(context) || this.parent
      const account = ensureAccountById(accounts, fields.accountId)
      try {
        const {fee, availableBalance} =
          await getMaxSendableFlowAmountImperative(account, txType)
        if (availableBalance.isLessThan(fee)) {
          const minimalBalance = account.balance.minus(
            availableBalance,
          ) as Nanoflow
          return createError({
            path,
            message: t('FlowTxPlanError.notEnoughFundsForFee', {
              minimalBalance: nanoFlowToFlow(minimalBalance),
            }),
          })
        }
      } catch (e) {
        return createError({
          path,
          message: (e as Error).message,
        })
      }
      return true
    })
}
