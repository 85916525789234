import type {AccountId} from '@nufi/wallet-common'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {Switch, Route, useHistory} from 'react-router-dom'

import type {AssetDetailBlockchain} from '../../../blockchainTypes'
import {routeTo, withQueryString, encodeTokenId} from '../../../router'
import type {BlockchainAssetDetailRoutes} from '../../../router/portfolio'
import {useAssetDetailRouteOptions} from '../../../router/portfolio'

import {AssetDetailAccounts} from './AssetDetailAccounts'
import {AssetDetailInfo} from './AssetDetailInfo'

import {
  AssetDetailActions,
  AssetDetailBalanceInfo,
  AssetDetailTabs,
  AssetDetailLayout,
  AssetDetailHeader,
} from '.'

type AssetDetailProps = {
  onBack: () => unknown
  routes: BlockchainAssetDetailRoutes
  parentSectionLabel: string
  blockchain: AssetDetailBlockchain
}

export const BlockchainAssetDetail = ({
  onBack,
  routes,
  parentSectionLabel,
  blockchain,
}: AssetDetailProps) => {
  const {t} = useTranslation()
  const history = useHistory()
  const {accountId, tokenId} = useAssetDetailRouteOptions()

  const handleAccountChange = (accountId: AccountId) => {
    const newAccountPath = routeTo.portfolio.assets
      .blockchain(blockchain)
      .detail.account(accountId)
      .tab('history').index
    history.replace(withQueryString(newAccountPath, encodeTokenId(tokenId)))
  }

  return (
    <AssetDetailLayout
      blockchain={blockchain}
      Header={
        <AssetDetailHeader
          {...{onBack, tokenId, parentSectionLabel, blockchain}}
        />
      }
      LeftContent={
        <AssetDetailBalanceInfo blockchain={blockchain} tokenId={tokenId} />
      }
      RightContent={
        <AssetDetailActions
          tokenId={tokenId == null ? undefined : tokenId}
          {...{blockchain, accountId}}
        />
      }
      Tabs={
        <>
          <AssetDetailTabs
            tabs={[
              {
                value: withQueryString(
                  routes
                    .blockchain(blockchain)
                    .detail.account(accountId)
                    .tab('history').index,
                  encodeTokenId(tokenId),
                ),
                label: t('Accounts & History'),
              },
              {
                value: withQueryString(
                  routes
                    .blockchain(blockchain)
                    .detail.account(accountId)
                    .tab('info').index,
                  encodeTokenId(tokenId),
                ),
                label: t('Info'),
              },
            ]}
          />
        </>
      }
      TabsContent={
        <Switch>
          <Route
            path={
              routes
                .blockchain(':blockchain')
                .detail.account(':accountId')
                .tab('history').index
            }
            render={(props) => (
              <AssetDetailAccounts
                {...props}
                withTopMargin
                onAccountChange={handleAccountChange}
              />
            )}
          />
          <Route
            path={[
              routes
                .blockchain(':blockchain')
                .detail.account(':accountId')
                .tab('info').index,
              routeTo.portfolio.assets.blockchain(':blockchain').detail.preview,
            ]}
            component={AssetDetailInfo}
          />
        </Switch>
      }
    />
  )
}
