/* eslint-disable @typescript-eslint/explicit-function-return-type */
import type {EvmTxParams} from '@nufi/wallet-evm'
import {buildTokenTransferParams, weiToNative} from '@nufi/wallet-evm'
import type {OpStackBlockchain} from '@nufi/wallet-evm/dist/chains/opStack/blockchainTypes'
import BigNumber from 'bignumber.js'

import {fetchNeverStaleQuery, useNeverStaleQuery} from 'src/utils/query-utils'
import type {
  EvmAddress,
  EvmTokenMetadata,
  EvmUnsignedTransaction,
  EvmWei,
} from 'src/wallet/evm/types'
import {secondsToMilliseconds} from 'src/wallet/utils/common'

import type {AccountId} from '../../../../../../types'
import {getOpStackManager} from '../../opStackManagers'

const coreQueryKeys = {
  getL1Fee: <TBlockchain extends OpStackBlockchain>(
    blockchain: TBlockchain,
    accountId: AccountId,
    tokenMetadata: EvmTokenMetadata<TBlockchain> | null,
  ) => ['getL1Fee', blockchain, accountId, tokenMetadata],
  getL1FeeFromUnsignedTx: (unsignedTransaction: EvmUnsignedTransaction) => [
    'getL1FeeFromUnsignedTx',
    unsignedTransaction,
  ],
}

/**
 * Return a dummy version of the tx params that can be used to estimate the fee on L1
 * The actual cost is based on the size of the transaction, and number of non-zero bytes
 * For this reason we try to use values, as high as possible to mimic a the worst case scenario
 * https://help.optimism.io/hc/en-us/articles/4411895794715-How-do-transaction-gas-fees-on-OP-Mainnet-work-
 */
const getEstimatedMaxTransferTxParams = <TBlockchain extends OpStackBlockchain>(
  accountId: AccountId,
  tokenMetadata: EvmTokenMetadata<TBlockchain> | null,
): EvmTxParams<TBlockchain> & {
  fromAccountId: AccountId
} => {
  // evm chains operate with 256 bit numbers which max is equal to
  // 115792089237316195423570985008687907853269984665640564039457584007913129639935
  // which is just a ridiculously high number of wei and should be enough for any transfer
  // we use a slightly lower number as a mean to get closer to reasonable real life value.
  const maxReasonableWeiTxValue = new BigNumber(
    '99999999999999999999999999999999', // 32 digits
  ) as EvmWei<TBlockchain>

  const baseTransferParams = {
    fromAccountId: accountId,
    // maximum nonce value is 2^64 - 1, but for some reason that fails. So we se it to 2^32
    // which is more then anybody would reasonable ever use
    // https://eips.ethereum.org/EIPS/eip-2681
    nonce: 2 ** 32,
    gasOptions: {
      type: 'london' as const,
      gasLimit: maxReasonableWeiTxValue,
      maxFeePerGas: maxReasonableWeiTxValue,
      maxPriorityFeePerGas: maxReasonableWeiTxValue,
    },
  }
  // using 0x111... address to maximize the number of non-zero bytes
  const dummyAddress =
    '0x1111111111111111111111111111111111111111' as EvmAddress<TBlockchain>
  if (tokenMetadata === null) {
    return {
      variant: 'nativeTransfer',
      toAddress: dummyAddress,
      amount: maxReasonableWeiTxValue,
      ...baseTransferParams,
    }
  }
  return {
    variant: 'tokenTransfer',
    ...baseTransferParams,
    ...buildTokenTransferParams({
      tokenMetadata,
      fromAddress: dummyAddress,
      toAddress: dummyAddress,
      amount: new BigNumber(weiToNative(maxReasonableWeiTxValue)),
    }),
  }
}

const getL1GasQueryOptions = {
  // l1 fee constantly changes, haven't found an interval in which it's get fetched
  // but every 10 seconds should be more then enough
  refetchInterval: secondsToMilliseconds(10),
  // refetchOnWindowFocus causes a lot of unnecessary re-render on window re-focus
  // and the interval is anyway generous.
  refetchOnWindowFocus: false,
}

type UseGetL1FeeArguments<TBlockchain extends OpStackBlockchain> = {
  blockchain: TBlockchain
  accountId: AccountId
  enabled: boolean
  tokenMetadata: EvmTokenMetadata<TBlockchain> | null
}

const getL1Fee = async <TBlockchain extends OpStackBlockchain>(
  blockchain: TBlockchain,
  accountId: AccountId,
  tokenMetadata: EvmTokenMetadata<TBlockchain> | null,
) => {
  const {accountsStore, blockchainApi, networkConfig} =
    getOpStackManager(blockchain)

  const accountData = accountsStore.getAccount(accountId)

  const txParams = getEstimatedMaxTransferTxParams(accountId, tokenMetadata)
  const unsignedTransaction = await blockchainApi.buildUnsignedTransaction(
    {
      version: networkConfig.gasConfig.type,
      ...txParams,
    },
    accountData.publicKey,
  )

  return await blockchainApi.getL1Fee(unsignedTransaction)
}

export const useGetL1Fee = <TBlockchain extends OpStackBlockchain>({
  blockchain,
  accountId,
  tokenMetadata,
  enabled = true,
}: UseGetL1FeeArguments<TBlockchain>) =>
  useNeverStaleQuery({
    queryKey: coreQueryKeys.getL1Fee(blockchain, accountId, tokenMetadata),
    queryFn: async () => getL1Fee(blockchain, accountId, tokenMetadata),
    ...getL1GasQueryOptions,
    enabled,
  })

export const cachedGetL1Fee = <TBlockchain extends OpStackBlockchain>({
  blockchain,
  accountId,
  tokenMetadata,
}: Omit<UseGetL1FeeArguments<TBlockchain>, 'enabled'>) =>
  fetchNeverStaleQuery({
    queryKey: coreQueryKeys.getL1Fee(blockchain, accountId, tokenMetadata),
    queryFn: async () => getL1Fee(blockchain, accountId, tokenMetadata),
  })

type UseGetL1FeeFromUnsignedTxArguments<TBlockchain extends OpStackBlockchain> =
  {
    blockchain: TBlockchain
    unsignedTx: EvmUnsignedTransaction
    enabled?: boolean
  }

export const useGetL1FeeFromUnsignedTx = <
  TBlockchain extends OpStackBlockchain,
>({
  blockchain,
  unsignedTx,
  enabled = true,
}: UseGetL1FeeFromUnsignedTxArguments<TBlockchain>) =>
  useNeverStaleQuery({
    queryKey: coreQueryKeys.getL1FeeFromUnsignedTx(unsignedTx),
    queryFn: async () =>
      getOpStackManager(blockchain).blockchainApi.getL1Fee(unsignedTx),
    ...getL1GasQueryOptions,
    enabled,
  })
