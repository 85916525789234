import {isValidSolanaPubKey} from '@nufi/wallet-solana'
import BigNumber from 'bignumber.js'
import React from 'react'

import {QueryGuard, TransactionModalError} from '../../../components'
import {SendModal} from '../common/sendMultipleAssetsModal/SendMultipleAssetsModal'
import {SendModalContent} from '../common/sendMultipleAssetsModal/SendMultipleAssetsModalContent'
import type {SendMultiAssetFormInjectedProps} from '../common/sendMultipleAssetsModal/types'
import type {BaseSendSchema as FormSchema} from '../common/types'

import {SolanaSendModalContent} from './SendModalContent'
import type {SolanaSendModalProps} from './types'
import {solanaSendModalViewModel} from './viewModel'
import {SolanaSendModalProvider} from './viewModelProvider'

export function SolanaSendModal({
  onClose,
  subScreens = {
    SendModal,
    SendModalContent,
  },
  vm = solanaSendModalViewModel,
}: SolanaSendModalProps) {
  const tokensByAccountQuery = vm.useGetTokensByAccount()
  const blockchain = 'solana'

  return (
    <SolanaSendModalProvider subScreens={subScreens} vm={vm}>
      <QueryGuard
        {...tokensByAccountQuery}
        ErrorElement={<TransactionModalError />}
        loadingVariant="centered"
      >
        {(tokensByAccount) => {
          return (
            <subScreens.SendModal<FormSchema>
              {...{
                type: 'multiAsset',
                blockchain,
                onClose,
                isAddressValid: (address) => isValidSolanaPubKey(address),
                getAvailableTokenBalance: (account, tokenId) => {
                  const matchToken = tokensByAccount[account.id]!.find(
                    ({token}) => token.id === tokenId,
                  )
                  return matchToken?.amount || new BigNumber(0)
                },
              }}
            >
              {(props: SendMultiAssetFormInjectedProps<FormSchema>) => (
                <SolanaSendModalContent
                  {...{
                    onClose,
                    ...props,
                  }}
                  getTokenBalances={(account) => tokensByAccount[account.id]!}
                />
              )}
            </subScreens.SendModal>
          )
        }}
      </QueryGuard>
    </SolanaSendModalProvider>
  )
}
