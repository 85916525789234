import type BigNumber from 'bignumber.js'
import React from 'react'

import {isExchangeBlockchain} from 'src/blockchainTypes'
import {formatSwapAssetTicker} from 'src/features/exchange/application'
import type {SwapAssetDetail} from 'src/features/exchange/domain'

import {
  FormattedNativeAmount,
  AmountVisibilityWrapper,
} from '../../../components'
import {assetMainToBaseUnit} from '../../../wallet/utils/parseUtils'
import {DEFAULT_EXCHANGE_DECIMALS} from '../constants'

type FormattedExchangeAmountProps = {
  amount: BigNumber
  swapAssetDetail: SwapAssetDetail
  includeAssetSymbol?: boolean
  isEstimate?: boolean
  isSensitiveInformation: boolean
}

export const FormattedExchangeAmount = ({
  amount,
  swapAssetDetail,
  includeAssetSymbol,
  isEstimate,
  isSensitiveInformation,
}: FormattedExchangeAmountProps) => {
  const swapAssetBlockchain = swapAssetDetail.blockchain
  const blockchain = isExchangeBlockchain(swapAssetBlockchain)
    ? swapAssetBlockchain
    : null
  return (
    <AmountVisibilityWrapper {...{isSensitiveInformation}}>
      <>
        {isEstimate ? '~' : ''}
        {blockchain ? (
          <FormattedNativeAmount
            blockchain={blockchain}
            value={assetMainToBaseUnit(blockchain, amount.toString())}
            isSensitiveInformation={isSensitiveInformation}
          />
        ) : (
          amount.decimalPlaces(DEFAULT_EXCHANGE_DECIMALS).toString()
        )}{' '}
        {includeAssetSymbol
          ? formatSwapAssetTicker(swapAssetDetail.ticker)
          : ''}
      </>
    </AmountVisibilityWrapper>
  )
}
