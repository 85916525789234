import {Launch as ExternalIcon} from '@mui/icons-material'
import {Box, Grid, Link, Stack, Typography, styled} from '@mui/material'
import {getIsWebExtension, safeAssertUnreachable} from '@nufi/frontend-common'
import type {CardanoAccountInfo} from '@nufi/wallet-cardano'
import type BigNumber from 'bignumber.js'
import React from 'react'
import {Trans, useTranslation} from 'react-i18next'

import {
  Alert,
  BatchQueryGuard,
  Button,
  CardanoTransactionExplorerLink,
  ElevatedCard,
  FormattedDateTime,
  FullScreenLoading,
  LabeledIcon,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalLayout,
  MutationGuard,
  PagingButton,
  StatusIcons,
  useCommonStyles,
} from 'src/components'
import type {DexHunterAccountConnector} from 'src/features/dex/dexHunter/application'
import {
  DEX_HUNTER_ORDERS_LINK,
  useGetCancelDexHunterOrderTx,
  useSignDexHunterTx,
  useSubmitDexHunterTx,
  useDexHunterOrders,
  useDexHunterTokens,
  USER_REJECTED_ERROR,
} from 'src/features/dex/dexHunter/application'
import {useDexHunterState} from 'src/features/dex/dexHunter/application/store'
import {
  getDexHunterOrderAssetsInfo,
  isCancellable,
} from 'src/features/dex/dexHunter/domain'
import type {
  DexHunterAssetInfo,
  DexHunterOrder,
} from 'src/features/dex/dexHunter/domain'
import {useScrollableStyles} from 'src/utils/layoutUtils'
import {useNoticeableLoading} from 'src/utils/refetch'

import {AccountSelectField} from './AccountSelectField'
import {
  OrderHistoryListItemHeader,
  OrderHistoryListItemLayout,
} from './OrderHistoryItem'

type OrderHistoryProps = {
  accounts: CardanoAccountInfo[]
  setSelectedAccount: (account: CardanoAccountInfo | null) => void
  selectedAccount: CardanoAccountInfo
  connector: DexHunterAccountConnector
}

export const OrderHistory = ({
  accounts,
  setSelectedAccount,
  selectedAccount,
  connector,
}: OrderHistoryProps) => {
  const {t} = useTranslation()
  const commonClasses = useCommonStyles()
  const scrollableClasses = useScrollableStyles()

  const ordersQuery = useDexHunterOrders(selectedAccount)
  const tokensQuery = useDexHunterTokens()

  const getCancelOrderTxMutation = useGetCancelDexHunterOrderTx(selectedAccount)
  const signTxMutation = useSignDexHunterTx(selectedAccount, connector)
  const submitTxMutation = useSubmitDexHunterTx(selectedAccount, connector)
  const {registerCancellingOrderId, unregisterCancellingOrderId} =
    useDexHunterState()

  const resetCancelOrderMutations = () => {
    getCancelOrderTxMutation.reset()
    signTxMutation.reset()
    submitTxMutation.reset()
  }

  const onCancelOrder = async (order: DexHunterOrder) => {
    try {
      resetCancelOrderMutations()

      const tx = await getCancelOrderTxMutation.mutateAsync(order)
      const signedTx = await signTxMutation.mutateAsync(tx)
      registerCancellingOrderId(order.id)
      await submitTxMutation.mutateAsync({rawTx: tx, rawTxWitnesses: signedTx})

      // Note that for now we are not aware of reasonable way to observe cancelation process, other
      // than refetching all address orders. For this reason we only let users know to re-fetch
      // orders manually. We might improve the UX once having more info about the possible
      // options from DexHunter, as reasonable approach is not documented in the UI.
    } catch (err) {
      unregisterCancellingOrderId(order.id)
      // error handled via mutation guards
    }
  }

  const signError =
    signTxMutation.error === USER_REJECTED_ERROR
      ? undefined
      : signTxMutation.error

  const showRefreshButtonLoading = useNoticeableLoading(
    ordersQuery.isLoading || ordersQuery.isFetching,
  )

  return (
    <>
      {submitTxMutation.data && (
        <CancelationInProcessModal onClose={resetCancelOrderMutations} />
      )}
      <MutationGuard
        {...getCancelOrderTxMutation}
        LoadingElement={
          <FullScreenLoading description={t('Preparing transaction ...')} />
        }
      />
      <MutationGuard
        {...signTxMutation}
        error={signError}
        LoadingElement={<FullScreenLoading />}
      />
      {/* Submit mutation loading state and errors are only handled via notifications bar. */}
      <HeaderWrapper>
        <Box sx={{display: 'flex', alignItems: 'center'}}>
          <Box width={400} mr={4}>
            <AccountSelectField
              {...{accounts, selectedAccount, setSelectedAccount}}
            />
          </Box>
          {getIsWebExtension() && (
            <Box width={300}>
              <Link
                href={DEX_HUNTER_ORDERS_LINK}
                className={commonClasses.link}
                target="_blank"
              >
                <Typography>{t('View on DexHunter')}</Typography>
                <ExternalIcon color="primary" className={commonClasses.icon} />
              </Link>
            </Box>
          )}
        </Box>
        <Button
          color="primary"
          variant="outlined"
          sx={{minWidth: 120}}
          textTransform="none"
          disabled={showRefreshButtonLoading}
          loading={showRefreshButtonLoading}
          onClick={() => ordersQuery.refetch()}
        >
          {t('Refresh')}
        </Button>
      </HeaderWrapper>
      <BatchQueryGuard
        queries={{
          orders: ordersQuery,
          tokens: tokensQuery,
        }}
      >
        {({orders, tokens}) => (
          <>
            <OrderHistoryListItemHeader />
            <Box className={scrollableClasses.scrollableList}>
              {orders.map((order) => {
                const {fromAsset, toAsset} = getDexHunterOrderAssetsInfo(
                  tokens,
                  order,
                )

                const statusIcon = getStatusIcon(order.status)

                return (
                  <CustomElevatedCard type="light" tableCard key={order.id}>
                    <OrderHistoryListItemLayout
                      key={order.id}
                      createdAtCol={
                        <Typography>
                          <FormattedDateTime
                            dateTime={new Date(order.submissionTime)}
                          />
                        </Typography>
                      }
                      fromCol={
                        <AssetColumn
                          asset={fromAsset}
                          amount={order.fromAmount}
                        />
                      }
                      toCol={
                        <AssetColumn asset={toAsset} amount={order.toAmount} />
                      }
                      statusCol={
                        <LabeledIcon
                          Label={
                            <Typography color="textPrimary">
                              {order.status}
                            </Typography>
                          }
                          Icon={statusIcon}
                        />
                      }
                      dexCol={
                        <DexImage src={order.dex.logo} title={order.dex.name} />
                      }
                      explorerLinkCol={
                        <CardanoTransactionExplorerLink txId={order.txHash} />
                      }
                      actionCol={
                        isCancellable(order) && (
                          <Button
                            onClick={() => onCancelOrder(order)}
                            color="warning"
                          >
                            {t('Cancel')}
                          </Button>
                        )
                      }
                    />
                  </CustomElevatedCard>
                )
              })}
              {ordersQuery.hasNextPage && (
                <Box textAlign="center">
                  <PagingButton
                    isLoading={ordersQuery.isLoading || ordersQuery.isFetching}
                    pagingParams={{
                      onNextPage: ordersQuery.fetchNextPage,
                      hasNextPage: ordersQuery.hasNextPage,
                    }}
                  />
                </Box>
              )}
            </Box>
          </>
        )}
      </BatchQueryGuard>
    </>
  )
}

const {ErrorIcon, SuccessIcon, WaitingIcon} = StatusIcons

export const getStatusIcon = (status: DexHunterOrder['status']) => {
  switch (status) {
    case 'CANCELLED':
    case 'EXPIRED':
      return <ErrorIcon color="error" />
    case 'COMPLETED':
      return <SuccessIcon color="success" />
    case 'PENDING':
    case 'CANCELLING':
    case 'BATCHING':
      return <WaitingIcon />
    case 'LIMIT':
    case 'STOPLOSS':
      return <></>
    default:
      return safeAssertUnreachable(status)
  }
}

type AssetColumnProps = {
  asset: DexHunterAssetInfo
  amount: BigNumber
}

const AssetColumn = ({asset, amount}: AssetColumnProps) => (
  <Box sx={{display: 'flex'}}>
    <Typography sx={{mr: 1}} color="textPrimary">
      {amount.toString()}
    </Typography>
    {asset.info?.ticker || ''}
  </Box>
)

const CancelationInProcessModal = ({onClose}: {onClose: () => void}) => {
  const {t} = useTranslation()
  return (
    <Modal variant="centered" maxWidthOverride={450}>
      <ModalLayout
        header={
          <ModalHeader onClose={() => onClose()} hasDivider>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <Typography variant="h6">
                  {t('Order cancelation in process')}
                </Typography>
              </Grid>
            </Grid>
          </ModalHeader>
        }
        body={
          <Stack p={2} spacing={2}>
            <Typography>
              <Trans
                i18nKey="dex_hunter_cancel_order_info_1"
                t={t}
                components={{
                  Bold: <b />,
                }}
              />
            </Typography>
            <Typography>
              <Trans
                i18nKey="dex_hunter_cancel_order_info_2"
                t={t}
                components={{
                  Bold: <b />,
                }}
              />
            </Typography>
            <Alert severity="info">
              <Typography>{t('dex_hunter_cancel_order_info_3')}</Typography>
            </Alert>
          </Stack>
        }
        footer={
          <ModalFooter hasDivider>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => onClose()}
            >
              {t('Ok')}
            </Button>
          </ModalFooter>
        }
      />
    </Modal>
  )
}

export const ORDER_HISTORY_ITEM_HEIGHT = 50

const CustomElevatedCard = styled(ElevatedCard)(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  cursor: 'pointer',
  height: ORDER_HISTORY_ITEM_HEIGHT,
  background: theme.palette.background.paper,
}))

const HeaderWrapper = styled(Grid)(({theme}) => ({
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(2),
  alignItems: 'center',
  flexDirection: 'row',
  justifyContent: 'space-between',
  display: 'flex',
}))

const DexImage = styled('img')({
  width: '24px',
  height: '24px',
  borderRadius: '24px',
})
