import {Typography, Grid, styled} from '@mui/material'
import type Fuse from 'fuse.js'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {
  LabeledIcon,
  ModalLayout,
  ModalFooter,
  NFTBadge,
  FooterLayout,
  EllipsizedString,
  SelectableList,
  ellipsizeString,
} from '../../../components'

import type {NativeAssetSchema, TokenSchema, AssetSchema, Asset} from './types'

type Option = AssetSchema

type AssetInfo =
  | (NativeAssetSchema & {
      assetName: string
      ticker: string | null
    })
  | (TokenSchema & {
      assetName?: string
      tokenIdentifier: string
      ticker: string | null
      isNft: boolean
    })

export type AddAssetsProps = {
  requiredAssets?: {
    list: Asset[]
    // consider requiring 'message' per asset
    message: string
  }
  assetList: AssetInfo[]
  onSubmit: (value: Option[]) => void
  onBack: () => unknown
  selected: Option[]
  ModalHeader: React.ReactNode
  accountName: string
  renderIcon: (option: Option) => JSX.Element
  renderFormattedAsset: (option: Option) => JSX.Element
  fuseOptions: Fuse.IFuseOptions<AssetInfo>
  searchPlaceholderText: string
}

const isSameAsset = (a: Asset, b: Asset) => {
  if (a.type === 'token' && b.type === 'token') {
    return a.tokenId === b.tokenId
  } else {
    return a.type === 'native' && b.type === 'native'
  }
}

export const AddAssets = ({
  requiredAssets,
  assetList,
  selected,
  ModalHeader,
  onBack,
  onSubmit,
  accountName,
  renderIcon,
  renderFormattedAsset,
  fuseOptions,
  searchPlaceholderText,
}: AddAssetsProps) => {
  const {t} = useTranslation()
  const [selectedAssets, setSelectedAssets] = useState<Option[]>(selected)

  const isAssetSelected = (option: Option) => {
    return selectedAssets.some((x) => isSameAsset(x, option))
  }

  const isRequiredAsset = (option: Option) =>
    !!requiredAssets?.list.find((asset) => isSameAsset(asset, option))

  const onAddAssetsItemClick = (option: Option) => {
    if (!isAssetSelected(option)) {
      const newAsset = assetList.find((asset) => isSameAsset(asset, option))
      if (newAsset == null) return

      setSelectedAssets([
        ...selectedAssets,
        newAsset.type === 'token'
          ? {
              type: 'token',
              tokenId: newAsset.tokenId,
              amount: newAsset.isNft ? newAsset.amount.toString() : '',
            }
          : {
              type: 'native',
              amount: '',
            },
      ])
    } else {
      if (selectedAssets.length === 1 || isRequiredAsset(option)) {
        return
      }
      setSelectedAssets(
        selectedAssets.filter((selected) => !isSameAsset(selected, option)),
      )
    }
  }

  return (
    <ModalLayout
      header={ModalHeader}
      body={
        <Grid container direction="column" p={2} height="100%" wrap="nowrap">
          <SelectableList<AssetInfo>
            options={assetList}
            fuseOptions={fuseOptions}
            searchPlaceholderText={searchPlaceholderText}
            onItemClick={(e, option) => onAddAssetsItemClick(option)}
            selectedOptions={assetList.filter((option) =>
              isAssetSelected(option),
            )}
            getOptionUniqueKey={(option) =>
              option.type === 'token' ? option.tokenId : option.type
            }
            itemHeight={50}
            getMenuItemProps={(option) => ({
              selected: isAssetSelected(option),
              disabled: isRequiredAsset(option),
            })}
            getSelectedChipProps={(selected) => {
              const label = getAssetName(selected)
              return {
                label,
                icon: renderIcon(selected),
                ...(isRequiredAsset(selected)
                  ? {
                      title: requiredAssets?.message,
                    }
                  : {
                      onDelete: () => onAddAssetsItemClick(selected),
                    }),
              }
            }}
            renderItemLeftContent={(option) => (
              <ItemLeftContent {...option} renderIcon={renderIcon} />
            )}
            renderItemRightContent={(option) => (
              <ItemRightContentTypography>
                {renderFormattedAsset(option)}
              </ItemRightContentTypography>
            )}
            itemListLegend={{
              left: t('Asset'),
              right: `${t('Balance')} (${accountName})`,
            }}
            searchNoResultsText={t('No tokens found')}
            noSelectedItemsText={t('No tokens selected')}
          />
        </Grid>
      }
      footer={
        <ModalFooter hasDivider>
          <FooterLayout
            leftBtnConf={{
              onClick: onBack,
              children: t('Back'),
            }}
            rightBtnConf={{
              children: t('Add Selected'),
              type: 'submit',
              onClick: () => onSubmit(selectedAssets),
            }}
          />
        </ModalFooter>
      }
    />
  )
}

const ItemLeftContent = ({
  renderIcon,
  ...rest
}: Pick<AddAssetsProps, 'renderIcon'> & AssetInfo) => {
  const hasSubtitle = rest.assetName && 'tokenIdentifier' in rest
  return (
    <LabeledIcon
      Icon={renderIcon(rest)}
      Label={
        <ItemLeftContentWrapper>
          <ItemLeftContentNameAligner>
            <Typography
              lineHeight={1}
              title={getAssetName(rest)}
              variant={hasSubtitle ? 'body2' : 'body1'}
              noWrap
            >
              {getAssetName(rest)}
            </Typography>
            {'isNft' in rest && rest.isNft && (
              <NFTBadge size="extra-small" boxProps={{lineHeight: 1}} />
            )}
          </ItemLeftContentNameAligner>
          {hasSubtitle && (
            <EllipsizedString
              value={rest.tokenIdentifier}
              startLength={13}
              endLength={8}
              TypographyProps={{
                lineHeight: 1,
                variant: 'caption',
                color: 'textSecondary',
              }}
            />
          )}
        </ItemLeftContentWrapper>
      }
      iconPosition="start"
    />
  )
}

const getAssetName = (selected: AssetInfo) =>
  selected.type === 'native'
    ? selected.assetName
    : selected.assetName || ellipsizeString(selected.tokenIdentifier, 10, 5)

const ItemLeftContentWrapper = styled('div')({
  flex: 1,
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  gap: '5px',
})

const ItemLeftContentNameAligner = styled('div')({
  display: 'flex',
  alignItems: 'flex-end',
})

const ItemRightContentTypography = styled(Typography)({
  width: '100%',
  lineHeight: 1,
})
