import React from 'react'
import {useTranslation} from 'react-i18next'

import {Failure} from 'src/pages/utils/Failure'

import {HelpSection, trezorImages} from './common'

type FailureProps = {
  onRetry?: () => Promise<unknown>
}

const getCommonProps = () => ({
  Image: trezorImages.error,
  HelpSection: <HelpSection severity="error" />,
})

export function TrezorFailedSign(props: FailureProps) {
  const {t} = useTranslation()
  return (
    <Failure
      {...props}
      message={t('Could not sign the transaction.')}
      {...getCommonProps()}
    />
  )
}

export function TrezorFailedExport(props: FailureProps) {
  const {t} = useTranslation()
  return (
    <Failure
      {...props}
      message={t('Could not export Trezor keys.')}
      {...getCommonProps()}
    />
  )
}

export function TrezorFailedVerify(props: FailureProps) {
  const {t} = useTranslation()
  return (
    <Failure
      {...props}
      message={t('Could not verify address.')}
      {...getCommonProps()}
    />
  )
}
