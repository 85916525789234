import {Box, Grid, InputAdornment, TextField, Typography} from '@mui/material'
import type {FormikProps} from 'formik'
import {useFormikContext} from 'formik'
import _ from 'lodash'
import React, {useEffect, useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {
  Alert,
  InlineLoading,
  AssetIcon,
  SelectableList,
  SafeCenterAligner,
} from '../../../../../../components'
import {FUSE_TOKEN_SEARCHING_THRESHOLD} from '../../../../../../constants'
import type {AccountInfo} from '../../../../../../types'
import {getHasFormError} from '../../../../../../utils/form'
import {useGetTokenSearchingKeyLabel} from '../../../../../../utils/translations'
import {getTokenSearchingKeys} from '../../../../../../wallet'
import {
  cachedGetTokenMetadata,
  useErc20TokenList,
} from '../../../../../../wallet/evm'
import type {
  EvmBlockchain,
  EvmErc20Metadata,
  EvmERC20TokenList,
  EvmTokenAmount,
  EvmTokenMetadata,
  GetErc20TokenList,
  EvmTokenSearchingKeys,
} from '../../../../../../wallet/evm'
import {
  getEvmTokenId,
  isValidEvmAddress,
} from '../../../../../../wallet/evm/sdk/utils'
import CommonDetailsScreen from '../ImportTx/CommonDetailsScreen'

import {
  TokenDetailExplorerLink,
  TokenDetailInfo,
  tokenDetailExplorerLinkClassName,
} from './common'
import {ImportTabsContent} from './ImportTabsContent'
import type {TabData} from './ImportTabsContent'
import {INITIAL_CUSTOM_FIELD_VALUES} from './schema'
import type {
  DetailsSchema,
  EvmImportTokenTab,
  ImportEvmTokenSchema,
} from './schema'

const getFormId = (blockchain: EvmBlockchain) =>
  `import-${blockchain}-token-details-form`

export const selectedTokensListId = 'import-evm-details-selected-list'

type DetailsScreenProps<TBlockchain extends EvmBlockchain> = {
  blockchain: TBlockchain
  onBack: () => void
  accounts: AccountInfo[]
  accountTokens: EvmTokenAmount<TBlockchain>[]
  ModalHeader: React.ReactNode
  getErc20TokenList?: GetErc20TokenList
}

export const DetailsScreen = <TBlockchain extends EvmBlockchain>({
  blockchain,
  onBack,
  accounts,
  accountTokens,
  ModalHeader,
  getErc20TokenList,
}: DetailsScreenProps<TBlockchain>) => {
  const formId = getFormId(blockchain)
  const {t} = useTranslation()
  const {values, handleSubmit, setFieldValue, resetForm} =
    useFormikContext<ImportEvmTokenSchema<TBlockchain>>()

  const {data: tokenListResult, isLoading} = useErc20TokenList(
    blockchain,
    getErc20TokenList,
  )
  const handleTabChange = (event: unknown, value: EvmImportTokenTab) => {
    resetForm() // so that tokens are not imported from both 'search' and 'custom' tabs at once
    const activeTabKey: keyof ImportEvmTokenSchema<TBlockchain> = 'activeTab'
    setFieldValue(activeTabKey, value)
  }

  const tokenList = tokenListResult || ({} as EvmERC20TokenList<TBlockchain>)
  const tabs: TabData = [
    {
      name: 'search' as EvmImportTokenTab,
      label: t('Search'),
      renderComponent: (
        <SearchTokensScreenContent
          {...{blockchain, isLoading, tokenList, accountTokens}}
        />
      ),
      isDisabled: false,
    },
    {
      name: 'custom' as EvmImportTokenTab,
      label: t('Custom'),
      renderComponent: (
        <CustomTokenScreenContent
          blockchain={blockchain}
          tokenList={tokenList}
        />
      ),
      isDisabled: false,
    },
  ]

  return (
    <CommonDetailsScreen
      {...{onBack, accounts}}
      onSubmit={handleSubmit}
      accountId={values.accountId}
      blockchain={blockchain}
      formId={formId}
      ModalHeader={ModalHeader}
      variant="stretched"
    >
      <ImportTabsContent
        {...{tabs, activeTab: values.activeTab, onTabChange: handleTabChange}}
      />
    </CommonDetailsScreen>
  )
}

type SearchTokensScreenContentProps<TBlockchain extends EvmBlockchain> = {
  blockchain: TBlockchain
  isLoading: boolean
  tokenList: EvmERC20TokenList<TBlockchain>
  accountTokens: EvmTokenAmount<TBlockchain>[]
}

const SearchTokensScreenContent = <TBlockchain extends EvmBlockchain>({
  blockchain,
  isLoading,
  tokenList,
  accountTokens,
}: SearchTokensScreenContentProps<TBlockchain>) => {
  const {t} = useTranslation()
  const tokensKey: keyof DetailsSchema<TBlockchain> = 'tokens'
  const formikProps = useFormikContext<ImportEvmTokenSchema<TBlockchain>>()
  const {values, setFieldValue, errors} = formikProps
  const {tokens} = values
  const hasError = getHasFormError(formikProps)
  const isSelectedToken = (id: EvmErc20Metadata<TBlockchain>['id']) =>
    tokens.some((token) => token.id === id)
  const onChange = (
    e: React.MouseEvent<HTMLLIElement, MouseEvent>,
    option: EvmErc20Metadata<TBlockchain>,
  ) => {
    // ignore onChange handler if target is anchor (ExplorerLink) or its child node
    const {target, currentTarget} = e
    const anchorElement = currentTarget.querySelector(
      `.${tokenDetailExplorerLinkClassName}`,
    )
    if (anchorElement?.contains(target as Node)) return

    if (isSelectedToken(option.id)) {
      setFieldValue(
        tokensKey,
        values.tokens.filter(({id}) => id !== option.id),
      )
    } else {
      setFieldValue(tokensKey, [...values.tokens, option])
    }
  }
  const keys = getTokenSearchingKeys(blockchain) as EvmTokenSearchingKeys
  const keyLabels = useGetTokenSearchingKeyLabel(blockchain)
  const removeToken = (tokenId: EvmTokenMetadata<TBlockchain>['id']) =>
    setFieldValue(
      tokensKey,
      values.tokens.filter(({id}) => id !== tokenId),
    )

  const tokenOptions = useMemo(() => getEvmTokenOptions(tokenList), [tokenList])
  const tokenImportIds = accountTokens.map(
    (accountToken) => accountToken.token.id,
  )

  const placeholderKeys = (keyLabels as string[]).reduce(
    (text, value, i, array) =>
      text + (i < array.length - 1 ? ', ' : ` ${t('or')} `) + value,
  )

  return isLoading ? (
    <SafeCenterAligner>
      <InlineLoading />
    </SafeCenterAligner>
  ) : (
    <>
      <SelectableList<EvmErc20Metadata<TBlockchain>>
        options={tokenOptions}
        fuseOptions={{
          keys: keys.map((key) => ({
            name: key,
            getFn: (item) => item[key] as string,
          })),
          threshold: FUSE_TOKEN_SEARCHING_THRESHOLD,
        }}
        searchPlaceholderText={`${t('Search by')} ${placeholderKeys}`}
        onItemClick={onChange}
        selectedOptions={tokens}
        getOptionUniqueKey={({id}) => id}
        itemHeight={40}
        getMenuItemProps={({id}) => ({
          selected: isSelectedToken(id),
          disabled: tokenImportIds.includes(id),
        })}
        getSelectedChipProps={(metadata) => ({
          label: metadata.ticker || metadata.name,
          onDelete: () => removeToken(metadata.id),
          icon: (
            <Box ml={1} display="flex">
              <AssetIcon blockchain={blockchain} tokenMetadata={metadata} />
            </Box>
          ),
        })}
        renderItemLeftContent={(option) => (
          <TokenDetailInfo tokenMetadata={option} />
        )}
        renderItemRightContent={(option) => (
          <TokenDetailExplorerLink {...option} />
        )}
        itemListLegend={{
          left: t('Token'),
          right: t('Contact address'),
        }}
        searchNoResultsText={t(
          'No tokens found. For other tokens, use the Custom tab.',
        )}
        noSelectedItemsText={t('No tokens selected')}
        selectedTokensListTestId={selectedTokensListId}
      />
      {hasError(tokensKey) && (
        <Typography color="error" textAlign="right">
          {errors[tokensKey]?.toString()}
        </Typography>
      )}
    </>
  )
}

export const getEvmTokenOptions = <TBlockchain extends EvmBlockchain>(
  allTokens: EvmERC20TokenList<TBlockchain>,
) => {
  const availableTokensForImport = Object.values<EvmErc20Metadata<TBlockchain>>(
    allTokens,
  )
    .map((token) => ({
      ...token,
      tokenId: token.id,
    }))
    // some tokens may have name and ticker missing, so we cannot display them conveniently
    .filter((token) => token.name !== null && token.ticker !== null)
  // alphabetical order not important, but we want to return the same order every time
  return _.sortBy(availableTokensForImport, (t) => t.contractAddress)
}

const CustomTokenScreenContent = <TBlockchain extends EvmBlockchain>({
  blockchain,
  tokenList,
}: {
  blockchain: TBlockchain
  tokenList: EvmERC20TokenList<TBlockchain>
}) => {
  const {t} = useTranslation()
  const formikProps = useFormikContext<ImportEvmTokenSchema<TBlockchain>>()
  const hasError = getHasFormError(formikProps, 'after-touched')
  const {values, setValues} = formikProps
  const [shouldShowLoadingSpinner, setShouldShowLoadingSpinner] =
    React.useState(false)

  const isContractAddressValid = isValidEvmAddress(values.customContractAddress)

  useEffect(
    function resetCustomFieldsOnAddressChange() {
      setValues({...values, ...INITIAL_CUSTOM_FIELD_VALUES})
    },
    [values.customContractAddress],
  )

  const isInTokenList = values.customContractAddress in tokenList

  useEffect(
    function fillCustomFieldsWithMetadata() {
      // allow user to custom-import token from tokenList, but pre-fill the metadata
      if (isInTokenList) {
        const tokenStandard = 'ERC20'
        const tokenId = getEvmTokenId<TBlockchain>({
          contractAddress: values.customContractAddress,
          standard: tokenStandard,
        })
        // retrieve metadata from tokenList, since they're already available
        // and we do not wish to mix tokenList metadata with possibly
        // different on-chain metadata once the token is imported
        const {name, ticker, decimals} = tokenList[tokenId]
        setValues({
          ...values,
          customTokenName: name,
          customTokenSymbol: ticker,
          customTokenDecimal: decimals.toString(),
          customTokenStandard: tokenStandard,
        })
        return
      }

      const tokenId = isContractAddressValid
        ? getEvmTokenId<TBlockchain>({
            contractAddress: values.customContractAddress,
            standard: 'ERC20',
          })
        : null

      if (tokenId) {
        setShouldShowLoadingSpinner(true)
        cachedGetTokenMetadata(blockchain, tokenId)
          .then((metadata) => {
            setShouldShowLoadingSpinner(false)
            const {name, decimals, ticker, tokenStandard} = metadata
            setValues({
              ...values,
              ...(name && {customTokenName: name}),
              ...(ticker && {customTokenSymbol: ticker}),
              // can be number 0, so check against null
              ...(decimals != null && {
                customTokenDecimal: decimals.toString(),
              }),
              customTokenStandard: tokenStandard,
            })
          })
          .catch(() => {
            setShouldShowLoadingSpinner(false)
          })
      }
    },
    [values.customContractAddress, isInTokenList],
  )

  const commonProps = {
    ...{
      formikProps,
      hasError,
      shouldShowLoadingSpinner,
    },
  }
  return (
    <Grid container spacing={3}>
      <CustomTokenTextField
        {...commonProps}
        fieldKey="customContractAddress"
        label={t('Contract address')}
        shouldShowLoadingSpinner={false} // does not affect contract address field
      />
      <CustomTokenTextField
        {...commonProps}
        fieldKey="customTokenSymbol"
        label={t('Token symbol')}
        disabled={isInTokenList}
      />
      <CustomTokenTextField
        {...commonProps}
        fieldKey="customTokenName"
        label={t('Token name')}
        disabled={isInTokenList}
      />
      <CustomTokenTextField
        {...commonProps}
        fieldKey="customTokenDecimal"
        label={t('Decimals')}
        disabled={isInTokenList}
      />
      {isInTokenList && (
        <Grid item xs={12}>
          <Alert severity="info">
            {t('Token metadata retrieved from the token list.')}
          </Alert>
        </Grid>
      )}
    </Grid>
  )
}

const CustomTokenTextField = <TBlockchain extends EvmBlockchain>({
  fieldKey,
  formikProps,
  label,
  hasError,
  shouldShowLoadingSpinner,
  disabled = false,
}: {
  fieldKey: keyof DetailsSchema<TBlockchain>
  formikProps: FormikProps<ImportEvmTokenSchema<TBlockchain>>
  label: string
  hasError: (field: keyof DetailsSchema<TBlockchain>) => boolean
  shouldShowLoadingSpinner: boolean
  disabled?: boolean
}) => {
  type FormField = keyof typeof formikProps.values
  return (
    <Grid item xs={12}>
      <TextField
        value={formikProps.values[fieldKey]}
        onChange={formikProps.handleChange<FormField>(fieldKey)}
        onBlur={(e) => {
          // without handleBlur the "touched" fields are not populated
          const onBlur = formikProps.handleBlur<FormField>(fieldKey) as (
            e: unknown,
          ) => void
          onBlur(e)
        }}
        label={label}
        error={hasError(fieldKey)}
        helperText={hasError(fieldKey) && <>{formikProps.errors[fieldKey]}</>}
        variant="outlined"
        fullWidth
        disabled={disabled || shouldShowLoadingSpinner}
        InputProps={{
          endAdornment: shouldShowLoadingSpinner ? (
            <InputAdornment position="end">
              <InlineLoading size={20} />
            </InputAdornment>
          ) : null,
        }}
      />
    </Grid>
  )
}
