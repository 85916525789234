import {Box, Divider} from '@mui/material'
import type Bignumber from 'bignumber.js'
import React from 'react'
import {useTranslation} from 'react-i18next'

import type {StakingBlockchain} from '../../../../blockchainTypes'
import {AmountRow, TotalRow, useModalSharedStyles} from '../../../../components'

type StakeSummaryProps = {
  blockchain: StakingBlockchain
  amount: Bignumber
  fee: Bignumber
}

export default function StakeSummary({
  blockchain,
  amount,
  fee,
}: StakeSummaryProps) {
  const {t} = useTranslation()
  const classes = useModalSharedStyles()
  return (
    <Box>
      <AmountRow
        label={t('Amount to stake')}
        amount={amount}
        blockchain={blockchain}
      />
      <AmountRow
        label={t('Transaction fee')}
        amount={fee}
        blockchain={blockchain}
      />
      <Divider className={classes.dividerY} />
      <TotalRow
        nativeAmount={amount.plus(fee)}
        blockchain={blockchain}
        totalText={t('Total')}
        feeText={t('(Amount + Fees)')}
      />
    </Box>
  )
}
