import type {
  FlowAccountInfo,
  FlowDelegationInfo,
  FlowUpdateDelegatorTxType,
} from '@nufi/wallet-flow'
import * as yup from 'yup'

import {getBlockchainDecimals} from '../../../../../constants'
import type {AccountId} from '../../../../../types'
import {
  useAmountDecimalValidation,
  useAmountRequiredValidation,
} from '../../../../send/common/schema'
import {
  useBalanceBiggerThanFeeValidation,
  useAmountMoreOrEqualToBalanceValidation,
  useSendAmountValidation,
} from '../schemas'

import {
  ErrorMessageForStakingTxType,
  getAvailableBalanceForStakingTxType,
} from './utils'

export type UpdateDelegatorDetailSchema = {
  amount: string
  accountId: AccountId
}

const _useUpdateDelegatorSchema = ({
  accountInfo,
  txType,
  delegation,
}: {
  accountInfo: FlowAccountInfo
  txType: FlowUpdateDelegatorTxType
  delegation: FlowDelegationInfo
}) => {
  const amountRequiredValidation = useAmountRequiredValidation()
  const decimalAmountValidation = useAmountDecimalValidation({
    decimals: getBlockchainDecimals('flow'),
  })

  const baseAmountValidation = decimalAmountValidation.concat(
    amountRequiredValidation,
  )
  const amountMoreOrEqualToBalanceValidation =
    useAmountMoreOrEqualToBalanceValidation({
      accounts: [accountInfo],
      availableBalance: getAvailableBalanceForStakingTxType(
        txType,
        accountInfo,
        delegation,
      ),
      errorMessage: ErrorMessageForStakingTxType(txType),
    })
  const balanceBiggerThanFeeValidation = useBalanceBiggerThanFeeValidation({
    accounts: [accountInfo],
    txType,
  })
  const sendAmountValidation = useSendAmountValidation({
    accounts: [accountInfo],
    txType,
  })

  return {
    amount:
      txType === 'stakeNewTokens'
        ? baseAmountValidation.concat(sendAmountValidation)
        : baseAmountValidation
            .concat(amountMoreOrEqualToBalanceValidation)
            .concat(balanceBiggerThanFeeValidation),
    accountId: yup.string(),
  }
}

// ensure consistency of keys / values on TS level
export const useUpdateDelegatorSchema: (
  ...args: Parameters<typeof _useUpdateDelegatorSchema>
) => {
  [k in keyof UpdateDelegatorDetailSchema]: ReturnType<
    typeof _useUpdateDelegatorSchema
  >[k]
} = _useUpdateDelegatorSchema
