import type {AddressNameServiceState} from '../../../components'
import type {
  Blockchain,
  TokenId,
  AccountId,
  AddressType,
  TokenMetadata,
} from '../../../types'

export type SendMultipleAssetsBlockchains = Extract<
  Blockchain,
  'cardano' | 'solana'
>

export const SendMultipleAssetsBlockchain: SendMultipleAssetsBlockchains[] = [
  'cardano',
  'solana',
]

export type TxSummarySendContext =
  | {
      type: 'multiAsset'
      tokenId?: TokenId
      metadataById: Record<TokenId, TokenMetadata>
    }
  | {
      type: 'token'
      tokenId: TokenId
      tokenMetadata: TokenMetadata
    }
  | {
      type: 'native'
    }

export type Asset =
  | {
      type: 'token'
      tokenId: TokenId
    }
  | {
      type: 'native'
    }

export type AssetSchema = Asset & {
  amount: string
}

export type TokenSchema = Extract<AssetSchema, {type: 'token'}>

export type NativeAssetSchema = Extract<AssetSchema, {type: 'native'}>

export type BaseDetailsSchema = {
  assets: AssetSchema[]
  toAddress: string
  accountId: AccountId
  addressType: AddressType
  toAddressNameServiceState: AddressNameServiceState
}

export type SummaryDetailsSchema = {
  password: string
}

export type BaseSendSchema = BaseDetailsSchema & SummaryDetailsSchema

export const schemaKeys: {[key in keyof BaseSendSchema]: keyof BaseSendSchema} =
  {
    assets: 'assets',
    toAddress: 'toAddress',
    accountId: 'accountId',
    addressType: 'addressType',
    password: 'password',
    // 'toAddressNameServiceState' may not be used by all blockchains, though making
    // it required in schema simplifies code, as one does not need to handle many 'ifs'
    // and nullability checks. Blockchains that do not use it can simply keep its value
    // set to 'not-validated'
    toAddressNameServiceState: 'toAddressNameServiceState',
  } as const
