import type {Swap, SwapAsset} from 'common'

import type {AuthenticatedRequest} from 'src/features/nufiAuth/infrastructure'

import type {SwapsApi} from '../domain/swapsApi'

export class NuFiSwapsApi implements SwapsApi {
  private apiUrl: string
  private authenticatedRequest: AuthenticatedRequest

  constructor(backendUrl: string, authenticatedRequest: AuthenticatedRequest) {
    this.apiUrl = `${backendUrl}/api/v1/swaps`
    this.authenticatedRequest = authenticatedRequest
  }

  getSwaps: SwapsApi['getSwaps'] = async () => {
    const swapsResponse = await this.authenticatedRequest<SwapResponse[]>({
      url: this.apiUrl,
      headers: {'Content-Type': 'application/json'},
    })

    return swapsResponse.map((s) => parseSwapResponse(s))
  }

  getSwap: SwapsApi['getSwap'] = async (swapId) => {
    const swapResponse = await this.authenticatedRequest<SwapResponse>({
      url: `${this.apiUrl}/${swapId}`,
      headers: {'Content-Type': 'application/json'},
    })

    return parseSwapResponse(swapResponse)
  }

  putSwap: SwapsApi['putSwap'] = async (swap) => {
    const swapResponse = await this.authenticatedRequest<SwapResponse>({
      url: this.apiUrl,
      method: 'PUT',
      body: JSON.stringify(swap),
      headers: {'Content-Type': 'application/json'},
    })

    return parseSwapResponse(swapResponse)
  }
}

type ParsableSwapProps =
  | 'createdAt'
  | 'updatedAt'
  | 'swapCreatedAt'
  | 'currencyFrom'
  | 'currencyTo'

type SwapResponse = Omit<Swap, ParsableSwapProps> & {
  createdAt: string
  updatedAt: string
  swapCreatedAt: string
  currencyFrom: string
  currencyTo: string
}

const parseSwapResponse = (swapResponse: SwapResponse): Swap => ({
  ...swapResponse,
  createdAt: new Date(swapResponse.createdAt),
  updatedAt: new Date(swapResponse.updatedAt),
  swapCreatedAt: new Date(swapResponse.swapCreatedAt),
  currencyFrom: swapResponse.currencyFrom.toUpperCase() as SwapAsset,
  currencyTo: swapResponse.currencyTo.toUpperCase() as SwapAsset,
})
