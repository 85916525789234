import type {
  UseMutationResult,
  UseMutationOptions,
  MutationKey,
  MutationFunction,
} from '@tanstack/react-query'
import {useMutation as _useMutation} from '@tanstack/react-query'

import {invalidateQueryKeys, withSilentError} from './utils'

export type CustomUseMutationResult<
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown,
> = UseMutationResult<TData, TError, TVariables, TContext> & {
  mutateAsyncSilent: UseMutationResult<
    TData | null,
    TError,
    TVariables,
    TContext
  >['mutateAsync']
}

/*
When passing mutationResult object across components and using `unknown` instead `any` e.g.
as `CustomUseMutationResult<string, unknown, unknown, unknown>`, TS is warning about
warnings related to `mutate` and `mutateAsync` properties.

The reason for that is that due to possible run time errors
`{
  ...
  mutateAsync: (variables: someSpecificParam) => any
}
is not assignable to
`{
  ...
  mutateAsync: (variables: unknown) => any
}
even though it seems that one is subset of another.
The reason is that if we performing specific operations on `someSpecificParam`
argument, due to `unknown` in the generic case,
we could pass anything to it and it could fail runtime.
Therefore we introduce `WeakUseMutationResult` type for cases when we do not need
`mutate` or `mutateAsync` properties.
For more advanced cases, solution discussed in https://github.com/vacuumlabs/adalite-2.0/pull/303
could be used.
*/
export type WeakUseMutationResult<
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown,
> = Omit<
  CustomUseMutationResult<TData, TError, TVariables, TContext>,
  'mutate' | 'mutateAsync' | 'mutateAsyncSilent'
>

/*
Motivation:
When using `mutation.mutateAsync` it can both throw an error and set `error` property.
But in cases when we handle `error` declaratively,
we need to silence this thrown error with repetitive `try/catch` blocks.
To avoid this, we introduce `mutation.withSilentError` fn.

Also note that `useErrorBoundary: false` (default), does not mean that the error will not be
thrown, only that we do not plan using our own React Error Boundary.
*/
export const useMutation = <
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown,
>(
  key: string | MutationKey,
  func?: MutationFunction<TData, TVariables>,
  options?: UseMutationOptions<TData, TError, TVariables, TContext> & {
    invalidationKeys?: MutationKey[]
  },
): CustomUseMutationResult<TData, TError, TVariables, TContext> => {
  const ret = _useMutation<TData, TError, TVariables, TContext>({
    mutationKey: typeof key === 'string' ? [key] : key,
    mutationFn: func,
    onSuccess: () => {
      if (options?.invalidationKeys) {
        invalidateQueryKeys(options.invalidationKeys)
      }
    },
    ...options,
  })
  return {
    ...ret,
    mutateAsyncSilent: withSilentError(ret.mutateAsync),
  }
}
