import {setIfDoesNotExist} from '@nufi/dapp-client-core'
import type {InjectedConnectorFactory} from '@nufi/dapp-client-core'
import type {
  SolanaTransaction,
  SolanaTransactionSignature,
  SolanaVersionedTransaction,
} from '@nufi/wallet-solana'
import type {
  SendOptions,
  Transaction,
  VersionedTransaction,
} from '@solana/web3.js'
import bs58 from 'bs58'

import type {DappConnectorsConfig} from '../../../../dappConnector/config'

import type {
  NufiSolanaLegacyConnector,
  NufiWalletStandardConnector,
  _PublicKey,
} from './publicTypes'
import type {API, SerializedTransaction} from './types'
import {initializeWalletStandard} from './walletStandard'

const connectorKind = 'solana'

const uint8ArrayTxToBase64 = (tx: Uint8Array) => {
  return Buffer.from(tx.buffer).toString('base64') as SerializedTransaction
}

export const apiErrorMessages = {
  REJECTED_BY_USER: 'Rejected by user',
} as const
export class SolanaApiError {
  message: string

  public constructor(
    message: (typeof apiErrorMessages)[keyof typeof apiErrorMessages],
  ) {
    this.message = message
  }
}

/* ▼ ▼ ▼ Legacy connector ▼ ▼ ▼ */

const serializeTx = (tx: SolanaTransaction) => {
  return tx.serialize({
    requireAllSignatures: false,
    verifySignatures: false,
  })
}

const isVersionedTransaction = (
  transaction: SolanaTransaction,
): transaction is SolanaVersionedTransaction => 'version' in transaction

const deserializeTx = async (
  serializedTxBase64: string,
  txInstance: SolanaTransaction,
) => {
  // transaction.constructor is the class of the tx object
  // passed from the dApp - this removes the need to import @solana/web3
  // in the injected script which can otherwise cause unexpected issues
  // clashing with the host site runtime environment
  //
  // NOTE: some DApps seem to not pass a proper instance of the Transaction
  // class, this is the case namely with https://stake.solblaze.org
  // which seems to pass just some "polyfill" missing deserialization method.
  // This is why we have below detection of the deserialization method and
  // fallback to (async) import of the whole @solana/web3.js lib. Given its
  // size and potential side-effects we prefer to import the lib only if
  // really needed.
  if (isVersionedTransaction(txInstance)) {
    const VersionedTransactionCls =
      'deserialize' in txInstance.constructor
        ? (txInstance.constructor as typeof VersionedTransaction)
        : (await import('@solana/web3.js')).VersionedTransaction

    return VersionedTransactionCls.deserialize(
      Buffer.from(serializedTxBase64, 'base64'),
    )
  } else {
    const TransactionCls =
      'from' in txInstance.constructor
        ? (txInstance.constructor as typeof Transaction)
        : (await import('@solana/web3.js')).Transaction
    return TransactionCls.from(Buffer.from(serializedTxBase64, 'base64'))
  }
}

const getLegacyConnector = (
  walletStandardConnector: NufiWalletStandardConnector,
): NufiSolanaLegacyConnector => {
  const legacyConnectorOverrides = {
    isNufi: true,
    async signTransaction<T extends Transaction | VersionedTransaction>(
      transaction: T,
    ): Promise<T> {
      const serializedSignedTx = await walletStandardConnector.signTransaction(
        serializeTx(transaction),
      )
      const signedTx = await deserializeTx(
        uint8ArrayTxToBase64(serializedSignedTx),
        transaction,
      )

      if (!isVersionedTransaction(signedTx)) {
        // This is here to match Phantom's undocumented behavior of modifying
        // the original transaction object with the signature.
        // as far as we tested, only "non-versioned" transactions seem to be mutated like this
        for (const {publicKey, signature} of signedTx.signatures) {
          if (signature) {
            transaction.addSignature(publicKey, signature)
          }
        }
      }

      return signedTx as T
    },
    async signAllTransactions<T extends Transaction | VersionedTransaction>(
      transactions: T[],
    ) {
      const signedTransactions = []
      for (const tx of transactions)
        signedTransactions.push(
          await legacyConnectorOverrides.signTransaction(tx),
        )
      return signedTransactions
    },
    signAndSendTransaction: async <
      T extends Transaction | VersionedTransaction,
    >(
      transaction: T,
    ) =>
      walletStandardConnector.signAndSendTransaction(serializeTx(transaction)),
  }

  const legacyConnector = new Proxy(walletStandardConnector, {
    get(target, prop, receiver) {
      if (legacyConnectorOverrides.hasOwnProperty(prop)) {
        return legacyConnectorOverrides[
          prop as keyof typeof legacyConnectorOverrides
        ]
      }
      return Reflect.get(target, prop, receiver)
    },
  }) as NufiSolanaLegacyConnector

  return legacyConnector
}

/* ▲ ▲ ▲ Legacy connector ▲ ▲ ▲ */

const injectedConnectorFactory: InjectedConnectorFactory<
  DappConnectorsConfig
> = (client) => {
  type Fn = (...args: any[]) => unknown
  const events = new Map<string, Fn[]>()
  const emit = (event: string, ...args: any[]) =>
    (events.get(event) ?? []).forEach((fn) => {
      try {
        fn(...args)
      } catch {
        // ignore
      }
    })

  const proxyApi = client.proxy as unknown as API

  const walletStandardConnector: NufiWalletStandardConnector = {
    isConnected: false,
    publicKey: null,
    on(event: string, fn: Fn) {
      const subs = events.get(event) ?? []
      subs.push(fn)
      events.set(event, subs)
    },
    off(event: string, fn: Fn) {
      const subs = events.get(event)
      if (subs)
        events.set(
          event,
          subs.filter((f) => f !== fn),
        )
    },
    async connect(options?: {onlyIfTrusted?: boolean}): Promise<{
      publicKey: _PublicKey
    }> {
      if (options?.onlyIfTrusted && !walletStandardConnector.isConnected) {
        throw new SolanaApiError(apiErrorMessages.REJECTED_BY_USER)
      }
      try {
        if (!client.isConnectorWindowOpen()) await client.openConnectorWindow()
        await client.proxy.connect!()
      } catch (e) {
        // we are ok with treating any failure here as a "rejection by the user"
        // since realistically, that's the only reason why this should fail
        throw new SolanaApiError(apiErrorMessages.REJECTED_BY_USER)
      }
      const publicKeyBytes = new Uint8Array(await proxyApi.getPublicKey())
      const publicKey = {
        toBytes: () => publicKeyBytes,
        toString: () => bs58.encode(publicKeyBytes), // required for opensea
        toBase58: () => bs58.encode(publicKeyBytes), // required for https://stake.solblaze.org
      }
      walletStandardConnector.publicKey = publicKey
      walletStandardConnector.isConnected = true
      emit('connect')
      return {publicKey}
    },
    async disconnect(): Promise<void> {
      try {
        if (client.isConnectorWindowOpen()) {
          await client.proxy.disconnect!()
          await client.closeConnectorWindow()
        }
      } catch {
        // This might fail in weird ways, but we still want the disconnection
        // to go through.
      }
      walletStandardConnector.publicKey = null
      walletStandardConnector.isConnected = false
      emit('disconnect')
    },
    async signTransaction<T extends Uint8Array>(transaction: T): Promise<T> {
      const serializedSignedTx = await proxyApi.signTransaction(
        uint8ArrayTxToBase64(transaction),
      )

      return new Uint8Array(Buffer.from(serializedSignedTx, 'base64')) as T
    },
    async signAllTransactions<T extends Uint8Array>(
      transactions: T[],
    ): Promise<T[]> {
      const signedTransactions = []

      // Until the UI is prepared to deal with multiple transactions at once
      // the best way is to just sign them sequentially.
      for (const tx of transactions)
        signedTransactions.push(
          await walletStandardConnector.signTransaction(tx),
        )

      return signedTransactions
    },
    async signAndSendTransaction<T extends Uint8Array>(
      transaction: T,
      sendOptions?: SendOptions,
    ): Promise<{signature: SolanaTransactionSignature}> {
      return await proxyApi.signAndSendTransaction(
        uint8ArrayTxToBase64(transaction),
        sendOptions,
      )
    },
    async signMessage(message: Uint8Array): Promise<{signature: Uint8Array}> {
      return {
        signature: new Uint8Array(await proxyApi.signMessage([...message])),
      }
    },
    signIn(): Promise<never> {
      throw new Error('not implemented')
    },
  }

  const legacyConnector = getLegacyConnector(walletStandardConnector)

  return {
    connectorKind,
    type: 'withOverrides',
    inject: (window, walletOverrides) => {
      // Wallet Standard approach:
      initializeWalletStandard(walletStandardConnector)

      // When Phantom emulation is enabled we have both `isNufi` and
      // `isPhantom` here. This shouldn't hurt, and enables us to use the exact
      // same connector object in both cases.
      legacyConnector.isPhantom = walletOverrides?.phantom ? true : undefined

      // inject legacy-friendly connector  to work with old dapps:
      setIfDoesNotExist(window, ['nufiSolana'], legacyConnector)
      if (walletOverrides?.phantom) {
        setIfDoesNotExist(window, ['solana'], legacyConnector)
        setIfDoesNotExist(window, ['phantom', 'solana'], legacyConnector) // opensea-specific for phantom emulation
      }
    },
    async eventHandler(method) {
      if (method === 'connectorWindowClosed') {
        await legacyConnector.disconnect()
      }
    },
  }
}

export default injectedConnectorFactory
