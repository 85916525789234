import {Box, Grid} from '@mui/material'
import * as wcUtils from '@walletconnect/utils'
import clsx from 'clsx'
import React, {useEffect, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {Button, SideBarLayout} from '../components'
import {useCommonProfileStyles} from '../pages/profile/common'
import {useQueryParams} from '../router'

import {
  NotificationPermissionSection,
  PopupPermissionSection,
} from './permissionSections'
import {
  redirectToRootIfNotWalletConnectRedirect,
  isWalletConnectRedirect,
  openConnectorWindow,
} from './utils'

export const WalletConnectRedirect = () => {
  const {t} = useTranslation()
  const commonStyles = useCommonProfileStyles()
  const queryParams = useQueryParams()

  const initialNotificationPermission = useRef(
    'Notification' in window ? Notification.permission : null,
  )

  const [notificationsPermission, setNotificationsPermission] = useState(
    initialNotificationPermission.current,
  )

  const [popupsAllowed, setPopupsAllowed] = useState<boolean | null>(null)

  const getUri = (): string => {
    // clicking on NuFi WalletConnect option from the dapp, opens a new tab with uri in the query params
    // this uri is needed to initialize the websocket connection
    const uri = queryParams.uri

    if (!uri || typeof uri !== 'string') {
      window.location.href = window.origin
      throw new Error('Invalid WalletConnect URI')
    }

    const {version} = wcUtils.parseUri(uri)

    if (version !== 2) {
      window.location.href = window.origin
      throw new Error(
        `Invalid WalletConnect URI version (${version}), must use WalletConnect v2`,
      )
    }

    return uri
  }

  useEffect(() => {
    redirectToRootIfNotWalletConnectRedirect()
    if (!isWalletConnectRedirect()) {
      // this return is needed to prevent opening the connector window
      // because the redirect triggered by ensureIsWalletConnectRedirect()
      // is not instantaneous
      return
    }
    const uri = getUri()
    if (popupsAllowed && notificationsPermission !== 'default') {
      const connectorWindow = openConnectorWindow(uri)
      if (connectorWindow) {
        connectorWindow.focus()
        window.close()
      }
    }
  }, [notificationsPermission, popupsAllowed])

  return popupsAllowed !== true ||
    initialNotificationPermission.current === 'default' ? (
    <SideBarLayout
      right={
        <Box className={clsx(commonStyles.wrapper)}>
          <Grid container spacing={4}>
            <Grid item>
              <PopupPermissionSection {...{popupsAllowed, setPopupsAllowed}} />
            </Grid>
            {initialNotificationPermission.current !== 'granted' && (
              <Grid item>
                <NotificationPermissionSection
                  initialNotificationPermission={
                    initialNotificationPermission.current
                  }
                  {...{notificationsPermission, setNotificationsPermission}}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Button
                fullWidth
                color="primary"
                variant="contained"
                onClick={() => {
                  openConnectorWindow(getUri())
                  window.close()
                }}
              >
                {t('Continue')}
              </Button>
            </Grid>
          </Grid>
        </Box>
      }
    />
  ) : null
}
