import type BigNumber from 'bignumber.js'
import React from 'react'
import {useTranslation} from 'react-i18next'

import type {Blockchain, TokenId} from 'src/types'
import type {ExternalReceiversInfo} from 'src/wallet/dappConnector/types'

import {
  TxAccordionRow,
  ChipRow,
  AssetAmount,
  EllipsizedAddress,
  TxDetailRow,
} from './common'

type ExternalReceiversProps<DTokenId extends TokenId = TokenId> = {
  blockchain: Blockchain
  externalReceiversInfo: ExternalReceiversInfo<DTokenId>
}

export function ExternalReceivers<DTokenId extends TokenId = TokenId>({
  externalReceiversInfo,
  blockchain,
}: ExternalReceiversProps<DTokenId>) {
  const {t} = useTranslation()
  const externalAddresses = Object.keys(externalReceiversInfo)

  return (
    <TxAccordionRow
      summary={
        <ChipRow
          label={t('External receivers')}
          chipContent={externalAddresses.length}
        />
      }
      details={
        <>
          {Object.entries(externalReceiversInfo).map(([address, movements]) => (
            <TxDetailRow
              key={address}
              left={
                <EllipsizedAddress address={address} blockchain={blockchain} />
              }
              right={
                <div>
                  <AssetAmount
                    blockchain={blockchain}
                    amount={movements.native}
                  />
                  {Object.entries<BigNumber>(movements.tokens).map(
                    ([tokenId, amount]) => (
                      <AssetAmount
                        key={tokenId}
                        tokenId={tokenId as TokenId}
                        amount={amount}
                        blockchain={blockchain}
                      />
                    ),
                  )}
                </div>
              }
            />
          ))}
        </>
      }
      divideDetailsChildren
    />
  )
}
