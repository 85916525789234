import React from 'react'

import type {AccountInfo, HotVendor} from '../../../types'
import type {EvmAccountInfo, EvmBlockchain} from '../../../wallet/evm'
import {useAddAccounts, useDiscoverAllEvmAccounts} from '../../../wallet/evm'
import {useDerivationPathTypeState} from '../sharedActions/addAccount/AddAccountUtils'
import {AddHotAccount as _AddHotAccount} from '../sharedActions/addAccount/AddHotAccount'

import {
  useDerivationPathTypeConfig,
  useMergedEvmAccountsAlert,
} from './AddAccountUtils'

type AddHotAccountProps<TBlockchain extends EvmBlockchain> = {
  blockchain: TBlockchain
  cryptoProviderType: HotVendor
}

export const AddHotAccount = <TBlockchain extends EvmBlockchain>({
  blockchain,
  cryptoProviderType,
}: AddHotAccountProps<TBlockchain>) => {
  const derivationPathTypeConfig = useDerivationPathTypeConfig('mnemonic')
  const derivationPathTypeState = useDerivationPathTypeState(
    'Bip44Address',
    derivationPathTypeConfig,
  )
  const alert = useMergedEvmAccountsAlert(blockchain)

  const discoveredAccountsQuery = useDiscoverAllEvmAccounts(
    blockchain,
    'mnemonic',
    derivationPathTypeState.derivationPathType,
  )
  const addAccounts = useAddAccounts()

  const onSubmit = async (newAccounts: AccountInfo[]) => {
    await addAccounts.mutateAsync({
      newAccounts: newAccounts as EvmAccountInfo<TBlockchain>[],
    })
  }

  return (
    <_AddHotAccount
      addAccountsMutation={addAccounts}
      {...{
        discoveredAccountsQuery,
        onSubmit,
        derivationPathTypeState,
        cryptoProviderType,
        blockchain,
        extras: {
          alert,
        },
      }}
    />
  )
}
