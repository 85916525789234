import {Grid} from '@mui/material'
import type BigNumber from 'bignumber.js'
import React from 'react'

import {
  FormattedTokenAmount,
  FormattedTokenLabel,
} from '../../../../../../components'
import type {
  EvmTokenMetadata,
  EvmBlockchain,
} from '../../../../../../wallet/evm'

import {TokenDetailInfo} from './common'

type EvmTokenInfoProps<TBlockchain extends EvmBlockchain> = {
  blockchain: EvmBlockchain
  balance?: BigNumber
  token: EvmTokenMetadata<TBlockchain>
}

export const EvmImportTokenDetailInfo = <TBlockchain extends EvmBlockchain>({
  blockchain,
  balance,
  token,
}: EvmTokenInfoProps<TBlockchain>) => {
  return (
    <Grid item display="flex" justifyContent="space-between">
      <TokenDetailInfo<typeof blockchain>
        tokenMetadata={token}
        withExplorerLink
      />
      {balance && (
        <EvmImportTokenBalanceInfo {...{token, balance, blockchain}} />
      )}
    </Grid>
  )
}

type EvmImportTokenBalanceInfoProps<TBlockchain extends EvmBlockchain> = {
  blockchain: EvmBlockchain
  balance: BigNumber
  token: EvmTokenMetadata<TBlockchain>
}

export const EvmImportTokenBalanceInfo = <TBlockchain extends EvmBlockchain>({
  blockchain,
  balance,
  token,
}: EvmImportTokenBalanceInfoProps<TBlockchain>) => {
  return (
    <div>
      <FormattedTokenAmount
        isSensitiveInformation
        blockchain={blockchain}
        value={balance}
        tokenInfo={token}
      />{' '}
      <FormattedTokenLabel blockchain={blockchain} tokenMetadata={token} />
    </div>
  )
}
