import {Box, Divider, Typography} from '@mui/material'
import type {FlowAccountInfo, FlowNodeInfo, Nanoflow} from '@nufi/wallet-flow'
import type {FormikProps} from 'formik'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {useGetNodeIdsToNames} from 'src/wallet/flow/application'

import type {StakingBlockchain} from '../../../../../blockchainTypes'
import {
  Alert,
  AmountRow,
  CurrentPasswordField,
  FooterLayout,
  Icon,
  ModalFooter,
  ModalLayout,
  QueryGuard,
  StakeTransitionCardSummary,
  TotalRow,
  useModalSharedStyles,
  ValidatorTransitionCardContent,
} from '../../../../../components'
import {useIsPasswordVerificationRequired} from '../../../../../store/auth'
import {useTrackTxSubmission} from '../../../../../tracking'
import type {useSubmitTx} from '../../../../../wallet/flow'
import {StakeModalHeader} from '../../../common/utils'
import {FormattedValidatorOption, StakingStringEllipsis} from '../../utils'
import type {SummarySchema} from '../schemas'

type SummaryScreenProps<T extends SummarySchema> = {
  blockchain: StakingBlockchain
  onClose: () => void
  onBack: () => unknown
  onSubmit: () => unknown
  summary: {
    fee: Nanoflow
    amount: Nanoflow
    fromAccount: FlowAccountInfo
    validator: FlowNodeInfo
  }
  formikProps: FormikProps<T>
  submitProps: ReturnType<typeof useSubmitTx>
}

const FORM_ID = 'register-delegator-summary-form'

export default function SummaryScreen<T extends SummarySchema>({
  onBack,
  onSubmit,
  blockchain,
  onClose,
  summary,
  formikProps,
  submitProps,
}: SummaryScreenProps<T>) {
  const {t} = useTranslation()
  const classes = useModalSharedStyles()
  const isPasswordVerificationRequired = useIsPasswordVerificationRequired(
    summary.fromAccount,
  )

  useTrackTxSubmission(submitProps, {
    blockchain: summary.fromAccount.blockchain,
    provider: summary.fromAccount.cryptoProviderType,
    type: 'stakeNewTokens', // we omit the 'registerDelegator` type since we can figure that out based on the validator id
    value: summary.amount.toNumber(),
    id: summary.validator.nodeId,
  })

  return (
    <form
      onSubmit={onSubmit}
      noValidate
      id={FORM_ID}
      className={classes.formWrapper}
    >
      <ModalLayout
        header={<StakeModalHeader {...{onClose, blockchain}} />}
        body={
          <Box p={2}>
            <StakeTransitionCardSummary
              {...{...summary, blockchain}}
              targetContent={
                <ValidatorTransitionCardContent
                  CustomIcon={<Icon type={'grayCube'} />}
                >
                  <SummaryTargetContent validator={summary.validator} />
                </ValidatorTransitionCardContent>
              }
            />
            <Box my={2}>
              <Alert
                text={t('FlowRegisterDelegatorModal.alert')}
                severity="info"
              />
            </Box>
            <Box className={classes.commonTopMargin}>
              <AmountRow
                label={t('Amount to stake')}
                amount={summary.amount}
                blockchain={blockchain}
              />
              <AmountRow
                label={t('Transaction fee')}
                amount={summary.fee}
                blockchain={blockchain}
              />
              <Divider className={classes.dividerY} />
              <TotalRow
                nativeAmount={summary.fee.plus(summary.amount)}
                blockchain={blockchain}
                totalText={t('Total')}
                feeText={t('(Amount + Fees)')}
              />
            </Box>
          </Box>
        }
        footer={
          <ModalFooter hasDivider>
            {isPasswordVerificationRequired && (
              <CurrentPasswordField
                {...{formikProps}}
                className={classes.passwordHeight}
              />
            )}
            <FooterLayout
              leftBtnConf={{
                onClick: onBack,
                children: t('Back'),
              }}
              rightBtnConf={{
                children: t('Submit'),
                type: 'submit',
                form: FORM_ID,
                disabled: formikProps.isSubmitting,
              }}
            />
          </ModalFooter>
        }
      />
    </form>
  )
}

type SummaryTargetContentProps = {validator: FlowNodeInfo}

const SummaryTargetContent = ({validator}: SummaryTargetContentProps) => {
  const nodeIdsToNamesQuery = useGetNodeIdsToNames()

  const ValidatorId = (
    <StakingStringEllipsis value={validator.nodeId} sx={{pl: 1, pr: 1}} />
  )
  return (
    <QueryGuard
      {...nodeIdsToNamesQuery}
      ErrorElement={ValidatorId}
      loadingVariant="centered"
    >
      {(nodeIdsToNames) => (
        <Box pl={1}>
          <Typography variant="body2">
            <FormattedValidatorOption
              validatorOption={{
                role: validator.role,
                id: validator.nodeId,
                name: nodeIdsToNames[validator.nodeId],
              }}
            />
          </Typography>
        </Box>
      )}
    </QueryGuard>
  )
}
