import React from 'react'

import type {FormattedAssetPairProps} from 'src/components'
import {FormattedAssetPair} from 'src/components'
import {WithArbitraryAssetConversionRates} from 'src/features/conversionRates/ui'
import type {TokenId} from 'src/wallet'

export const AssetAmount = ({
  blockchain,
  tokenId,
  amount,
  withAmountSignPrefix,
}: Pick<FormattedAssetPairProps, 'blockchain' | 'amount'> & {
  tokenId?: TokenId
  withAmountSignPrefix?: boolean
}) => (
  <WithArbitraryAssetConversionRates blockchain={blockchain} tokenId={tokenId}>
    {({conversionRates, tokenMetadata}) => {
      const isNegative = amount.isNegative()

      return (
        <FormattedAssetPair
          color={
            withAmountSignPrefix
              ? isNegative
                ? 'error.light'
                : 'success.light'
              : undefined
          }
          amount={amount.absoluteValue()}
          variant="caption"
          prefix={withAmountSignPrefix ? (isNegative ? '- ' : '+ ') : undefined}
          isSensitiveInformation
          ellipsizeLabel
          conversionProps={{
            conversionRates,
          }}
          tokenMetadata={tokenMetadata}
          blockchain={blockchain}
        />
      )
    }}
  </WithArbitraryAssetConversionRates>
)
