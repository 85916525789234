import {isHwVendor} from '@nufi/wallet-common'

import type {LoginInfo} from 'src/features/login/domain'

import type {AccountInfo} from '../../types'

export const isPasswordVerificationRequired = (
  profileLoginInfo: LoginInfo,
  selectedAccount: AccountInfo,
) =>
  profileLoginInfo.loginType === 'password' &&
  !isHwVendor(selectedAccount.cryptoProviderType)

/**
 * Returns the items on the `pageNumber`-th page.
 * @param allItems array containing all the items
 * @param pageNumber the requested page number, starting from 1
 * @param pageSize the number of items that should be on one page
 */
export const getPageItems = <T>(
  allItems: T[],
  pageNumber: number,
  pageSize: number,
): T[] => allItems.slice((pageNumber - 1) * pageSize, pageNumber * pageSize)

export {ReferenceElementContext} from './referenceContext'
