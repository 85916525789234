import type {FormikHelpers} from 'formik'
import React from 'react'

import type {AccountInfo} from '../../../types'
import type {SchemaWithPassword} from '../../../utils/form'
import {useVerifyPassword} from '../../../utils/form'
import {authorizeTxSubmission} from '../signAuthorization'
import {BaseSuccessScreen} from '../Submit'

import type {BaseSignTxFlowProps} from './BaseSignTxFlow'
import {BaseSignTxFlow} from './BaseSignTxFlow'

const detailsAndSummaryStates = [
  'details',
  'summary',
  'signAndSubmit',
  'success',
] as const

export type DetailsAndSummaryTxFlowState =
  (typeof detailsAndSummaryStates)[number]

// Introduced to wrap common logic for `summary` + `details` flows, though
// other flows can implement arbitrary handlers on they own.
// For now it is a quick abstraction to avoid future duplication, this may (feel free to) change
// it when needing to better adjust it for other flows.
export function useDetailsAndSummaryTxFlowSubmit<
  FormSchema extends SchemaWithPassword,
>(
  screenState: DetailsAndSummaryTxFlowState,
  setScreenState: (s: DetailsAndSummaryTxFlowState) => void,
  onDetailsSubmit?: (
    values: FormSchema,
    helpers: FormikHelpers<FormSchema>,
  ) => void,
) {
  const onSummarySubmit = useWithVerifyFormikPassword(
    authorizeTxSubmission(() => setScreenState('signAndSubmit')),
  )
  const onSubmit = (values: FormSchema, helpers: FormikHelpers<FormSchema>) => {
    if (screenState === 'details') {
      if (onDetailsSubmit) {
        onDetailsSubmit(values, helpers)
      } else {
        setScreenState('summary')
        helpers.setSubmitting(false)
      }
    } else if (screenState === 'summary') {
      onSummarySubmit(values, helpers as FormikHelpers<SchemaWithPassword>)
    }
    // ignore rest
  }
  return onSubmit
}

export function getDefaultTxFlowScreens<
  TSignResponse,
  TSubmitResponse extends string,
>({
  resetForm,
  account,
  ModalHeader,
  DeviceReadyState,
  onClose,
  signProps,
  submitProps,
  ...restProps
}: {
  account: AccountInfo
  resetForm: () => void
  onSuccess?: () => void
  onSubmitGoBack?: () => void
  onNavigateToNewTx?: () => void
} & (
  | {
      customNavigationHandlers: {
        onSuccess: () => void
        onSubmitGoBack: () => void
        onNavigateToNewTx: () => void
      }
    }
  | {
      setScreenState: (s: DetailsAndSummaryTxFlowState) => void
    }
) &
  Pick<
    BaseSignTxFlowProps<TSignResponse, TSubmitResponse>,
    'DeviceReadyState' | 'ModalHeader' | 'onClose' | 'signProps' | 'submitProps'
  >) {
  return {
    signAndSubmitScreen: (
      <BaseSignTxFlow
        blockchain={account.blockchain}
        cryptoProviderType={account.cryptoProviderType}
        onBack={() =>
          'customNavigationHandlers' in restProps
            ? restProps.customNavigationHandlers.onSubmitGoBack()
            : restProps.setScreenState('details')
        }
        onSuccess={() =>
          'customNavigationHandlers' in restProps
            ? restProps.customNavigationHandlers.onSuccess()
            : restProps.setScreenState('success')
        }
        {...{ModalHeader, signProps, submitProps, DeviceReadyState, onClose}}
      />
    ),
    successScreen: (
      <BaseSuccessScreen
        accountId={account.id}
        blockchain={account.blockchain}
        ModalHeader={ModalHeader}
        onClose={onClose}
        onNewTransaction={() => {
          signProps.reset()
          submitProps.reset()
          resetForm()
          'customNavigationHandlers' in restProps
            ? restProps.customNavigationHandlers.onNavigateToNewTx()
            : restProps.setScreenState('details')
        }}
        submitProps={submitProps}
      />
    ),
  }
}

export const useWithVerifyFormikPassword = (fn: () => unknown) => {
  const verifyPassword = useVerifyPassword()
  return async (
    values: SchemaWithPassword,
    formikHelpers: FormikHelpers<SchemaWithPassword>,
  ) => {
    const passwordIsCorrect = await verifyPassword(
      // casted to `FormikHelpers<BaseTxSchema>` as verifyPassword has issues with generic type
      values.password,
      formikHelpers as FormikHelpers<SchemaWithPassword>,
    )
    if (!passwordIsCorrect) return
    fn()
  }
}
