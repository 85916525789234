// Kept in .ts file so it can be imported from service-worker

import config from '../../../config'

export const getSolanaTransactionExplorerLink = (txId: string) =>
  `https://solscan.io/tx/${txId}?cluster=${config.solanaClusterName}`

export const getSolanaAddressExplorerLink = (address: string) =>
  `https://solscan.io/account/${address}?cluster=${config.solanaClusterName}`

export const getSolanaStakeAccountLink = (stakeAccount: string) =>
  `https://solscan.io/account/${stakeAccount}?cluster=${config.solanaClusterName}`

export const getSolanaStakeAccountRewardsLink = (stakeAccount: string) =>
  `https://solscan.io/account/${stakeAccount}?cluster=${config.solanaClusterName}#rewards`

export const getSolanaParentAccountRewardsLink = (parentAccount: string) =>
  `https://solscan.io/account/${parentAccount}?cluster=${config.solanaClusterName}#stakeAccounts`

export const getSolanaTokenExplorerLink = (tokenMint: string) =>
  `https://solscan.io/token/${tokenMint}?cluster=${config.solanaClusterName}`
