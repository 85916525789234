import type {TxHistoryEntry, TxHistoryEntryEffect} from '@nufi/wallet-common'
import {arraySum} from '@nufi/wallet-common'
import type {
  FlowAccountInfo,
  FlowAccountOfflineInfo,
  FlowAddress,
  FlowPubKeyInfo,
  FlowStakingInfo,
  Nanoflow,
  FlowTxHistoryEntry,
  FlowTransferInstruction,
  FlowAllAvailableAccountsResponse,
  FlowTokenId,
} from '@nufi/wallet-flow'
import {flowContractsAddresses} from '@nufi/wallet-flow'
import BigNumber from 'bignumber.js'

import config from '../../../config'
import type {Blockchain} from '../../../types'
import type {AllAvailableAccountsResponse} from '../../types'
import {flow} from '../flowManagers'

export const QUERY_KEY_PREFIX: Blockchain = 'flow'

export const getTotalStakingBalance = (
  stakingInfo: FlowStakingInfo,
): Nanoflow => {
  if (!stakingInfo.isStakingCollectionRegistered) {
    return new BigNumber(0) as Nanoflow
  }
  return stakingInfo.delegations.reduce(
    (acc, curr) =>
      acc.plus(
        arraySum([
          curr.tokensCommitted,
          curr.tokensRewarded,
          curr.tokensStaked,
          curr.tokensUnstaked,
          curr.tokensUnstaking,
        ]),
      ),
    new BigNumber(0),
  ) as Nanoflow
}

export const getTotalStakingRewardsBalance = (
  stakingInfo: FlowStakingInfo,
): Nanoflow => {
  if (!stakingInfo.isStakingCollectionRegistered) {
    return new BigNumber(0) as Nanoflow
  }
  return stakingInfo.delegations.reduce(
    (acc, curr) => acc.plus(curr.tokensRewarded),
    new BigNumber(0),
  ) as Nanoflow
}

export const getTotalStakedBalance = (
  stakingInfo: FlowStakingInfo,
): Nanoflow => {
  if (!stakingInfo.isStakingCollectionRegistered) {
    return new BigNumber(0) as Nanoflow
  }
  return stakingInfo.delegations.reduce(
    (acc, curr) =>
      acc.plus(arraySum([curr.tokensStaked, curr.tokensCommitted])),
    new BigNumber(0),
  ) as Nanoflow
}

export const getTotalInStakingBalance = (
  stakingInfo: FlowStakingInfo,
): Nanoflow => {
  if (!stakingInfo.isStakingCollectionRegistered) {
    return new BigNumber(0) as Nanoflow
  }
  return stakingInfo.delegations.reduce(
    (acc, curr) =>
      acc.plus(
        arraySum([
          curr.tokensStaked,
          curr.tokensCommitted,
          curr.tokensUnstaking,
        ]),
      ),
    new BigNumber(0),
  ) as Nanoflow
}

export const getTotalInactiveBalance = (
  stakingInfo: FlowStakingInfo,
): Nanoflow => {
  if (!stakingInfo.isStakingCollectionRegistered) {
    return new BigNumber(0) as Nanoflow
  }
  return stakingInfo.delegations.reduce(
    (acc, curr) =>
      acc.plus(arraySum([curr.tokensUnstaked, curr.tokensRewarded])),
    new BigNumber(0),
  ) as Nanoflow
}

export const sumRewards = (accountsInfo: FlowAccountInfo[]): BigNumber =>
  arraySum(
    accountsInfo.map(({stakingInfo}) =>
      getTotalStakingRewardsBalance(stakingInfo),
    ),
  )

export const sumBalances = (accountsInfo: FlowAccountInfo[]): BigNumber =>
  arraySum(accountsInfo.map(({balance}) => balance))

export const sumStakedBalances = (accountsInfo: FlowAccountInfo[]): BigNumber =>
  arraySum(
    accountsInfo.map(({stakingInfo}) => getTotalStakedBalance(stakingInfo)),
  )

// we cant fetch account info for unused accounts so we create a dummy account info
// so the logic of adding an account is simpler and works only with AccountInfo type
export const getFlowDummyAccountInfo = (
  nextUnusedAccounts: FlowAccountOfflineInfo[],
): FlowAccountInfo[] => {
  const dummyAccountInfos: FlowAccountInfo[] = nextUnusedAccounts.map((a) => ({
    ...a,
    address: null as unknown as FlowAddress,
    balance: new BigNumber(0) as Nanoflow,
    stakingInfo: {isStakingCollectionRegistered: false},
    publicKeyInfo: null as unknown as FlowPubKeyInfo,
  }))
  return dummyAccountInfos
}

export const getAccountInfoForDiscoveryResponse = async (
  response: FlowAllAvailableAccountsResponse,
): Promise<AllAvailableAccountsResponse<FlowAccountInfo>> => {
  if ('canNotAddAccountReason' in response) return response
  const nextUnusedAccountsWithDummyAccountInfo = getFlowDummyAccountInfo(
    flow.getAccountOfflineInfos(response.nextUnusedAccounts),
  )
  return {
    accounts: [
      ...(await flow.getAccountInfos(response.accounts)),
      ...nextUnusedAccountsWithDummyAccountInfo,
    ],
  }
}

export const formatFlowTxHistoryEntries = (
  data: FlowTxHistoryEntry[] | undefined,
): TxHistoryEntry<'flow', FlowTokenId>[] | undefined =>
  data?.map(({transactionId, timeIssued, fee, tokenEffects}) => {
    const operations: FlowTransferInstruction[] =
      // Very small chance that the tokenEffect amount is equal to the fee
      // in which case this would show as a Transaction instead of a Transfer
      tokenEffects.length === 0 ||
      (tokenEffects.length === 1 && tokenEffects[0]!.amount.eq(fee))
        ? ['Transaction']
        : tokenEffects.map((effect) => {
            const isFlow =
              effect.contractAddress ===
              flowContractsAddresses[config.flowNetwork]['0xFLOWTOKENADDRESS']

            if (isFlow) {
              return effect.type === 'Deposit' ? 'Deposit' : 'Withdrawal'
            }
            if (effect.isNft) {
              return effect.type === 'Deposit' ? 'NftDeposited' : 'NftWithdrawn'
            }
            return effect.type === 'Deposit'
              ? 'TokensDeposited'
              : 'TokensWithdrawn'
          })

    // TODO other operations can be extracted from the events

    const effects: TxHistoryEntryEffect<'flow', FlowTokenId>[] =
      tokenEffects.map((effect) => ({
        blockchain: 'flow' as const,
        tokenId:
          effect.contractAddress !==
          flowContractsAddresses[config.flowNetwork]['0xFLOWTOKENADDRESS']
            ? effect.token.id
            : undefined,
        amount:
          effect.type === 'Deposit' ? effect.amount : effect.amount.negated(),
      }))

    return {
      transactionId,
      time: timeIssued,
      fee,
      effects,
      operations: operations as string[],
    }
  })
