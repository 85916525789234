import {Avatar, Box, Grid, Link} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {
  CardanoValidatorExplorerLink,
  FormattedAsset,
  FormattedPercentage,
  ValidatorExplorerLink,
} from '../../../../components'
import type {CardanoStakepoolInfo} from '../../../../wallet/cardano'
import {stakepoolIdToBech32} from '../../../../wallet/cardano'
import ValidatorInfoItem from '../../common/actionModals/StakeModalValidatorInfo'

type FormattedValidatorInfoProps = {
  validatorInfo: CardanoStakepoolInfo | undefined
}

export default function FormattedValidatorInfo({
  validatorInfo,
}: FormattedValidatorInfoProps) {
  const {t} = useTranslation()

  return (
    <Grid container direction="row">
      <Grid container xs={10}>
        <ValidatorInfoItem
          itemName={t('Name')}
          itemValue={
            (validatorInfo?.poolId && validatorInfo?.name && (
              <ValidatorExplorerLink
                blockchain={'cardano'}
                validatorId={validatorInfo.poolId}
                validatorName={validatorInfo.name}
              />
            )) ||
            validatorInfo?.name
          }
        />
        {validatorInfo?.apy ? (
          <ValidatorInfoItem
            itemName={t('APY')}
            itemValue={<FormattedPercentage value={validatorInfo.apy} />}
          />
        ) : null}
        <ValidatorInfoItem
          itemName={t('Ticker')}
          itemValue={validatorInfo?.ticker}
        />
        <ValidatorInfoItem
          itemName={t('PoolId')}
          itemValue={
            <CardanoValidatorExplorerLink
              poolId={validatorInfo?.poolId.toString() as string}
              poolName={validatorInfo?.poolId}
            />
          }
        />
        <ValidatorInfoItem
          itemName={t('Bech32')}
          itemValue={
            validatorInfo?.poolId && stakepoolIdToBech32(validatorInfo.poolId)
          }
        />
        <ValidatorInfoItem
          itemName={t('Tax')}
          tooltipTitle={t('cardano_pool_tax_tooltip')}
          itemValue={
            validatorInfo?.margin &&
            `${validatorInfo.margin.multipliedBy(100).toString()}%`
          }
        />
        <ValidatorInfoItem
          itemName={t('Fixed cost')}
          tooltipTitle={t('cardano_pool_fixed_cost_tooltip')}
          itemValue={
            validatorInfo?.fixedCost && (
              <FormattedAsset
                amount={validatorInfo.fixedCost}
                blockchain={'cardano'}
                isSensitiveInformation={false}
              />
            )
          }
        />
        <ValidatorInfoItem
          itemName={t('Homepage')}
          itemValue={
            validatorInfo?.homepage && (
              <Link
                rel="noopener"
                target="_blank"
                href={validatorInfo.homepage}
              >
                {validatorInfo.homepage}
              </Link>
            )
          }
        />
      </Grid>
      <Grid container xs={2} justifyContent="flex-end">
        {validatorInfo?.iconUrl && (
          <Box my={1} mx={1}>
            <Avatar
              variant="rounded"
              alt="Validator Avatar"
              src={validatorInfo.iconUrl}
            />
          </Box>
        )}
      </Grid>
    </Grid>
  )
}
