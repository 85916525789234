import {asEvmChainId} from './utils'

export const ethereumChainIds = {
  homestead: asEvmChainId(1),
  sepolia: asEvmChainId(11155111),
}

export const milkomedaC1ChainIds = {
  mainnet: asEvmChainId(2001),
  devnet: asEvmChainId(200101),
}

export const polygonChainIds = {
  mainnet: asEvmChainId(137),
  amoy: asEvmChainId(80002),
}

export const optimismChainIds = {
  mainnet: asEvmChainId(10),
  sepolia: asEvmChainId(11155420),
}

export const arbitrumOneChainIds = {
  mainnet: asEvmChainId(42161),
  sepolia: asEvmChainId(421614),
}

export const baseChainIds = {
  mainnet: asEvmChainId(8453),
  sepolia: asEvmChainId(84532),
}
