import {PowerSettingsNew} from '@mui/icons-material'
import {Grid} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'

import {useIsBlockchainEnabled} from 'src/features/profile/application'
import {EnableBlockchain} from 'src/features/walletStorage/ui'

import {
  ElevatedCard,
  AssetCard,
  TextButton,
  TypographyLoader,
} from '../../../components'
import {IS_EXCHANGE_ENABLED} from '../../../constants'
import {routeTo, withQueryString, encodeTokenId} from '../../../router'
import type {Blockchain, TokenId, TokenMetadata} from '../../../types'
import {useHasAccounts} from '../../../wallet'
import type {AccountId} from '../../../wallet'
import {OpenExchangeButton} from '../../exchange/OpenExchangeButton'
import {AddAccount} from '../sharedActions/addAccount/AddAccount'

import {AssetListItemLayout} from './AssetListLayout'

const getAssetItemHandlers = (
  history: {push: (url: string) => void},
  blockchain: Blockchain,
  defaultAccountId: AccountId,
  tokenId?: TokenId,
) => {
  const onClick = () => {
    history.push(
      withQueryString(
        routeTo.portfolio.assets
          .blockchain(blockchain)
          .detail.account(defaultAccountId)
          .tab('history').index,
        encodeTokenId(tokenId),
      ),
    )
  }

  const onSend = (e: React.SyntheticEvent) => {
    e.stopPropagation()
    history.push(
      withQueryString(
        routeTo.portfolio.assets.blockchain(blockchain).action(defaultAccountId)
          .send,
        encodeTokenId(tokenId),
      ),
    )
  }

  const onReceive = (e: React.SyntheticEvent) => {
    e.stopPropagation()
    history.push(
      withQueryString(
        routeTo.portfolio.assets.blockchain(blockchain).action(defaultAccountId)
          .receive,
        encodeTokenId(tokenId),
      ),
    )
  }

  const onExchange = (e: React.SyntheticEvent) => {
    e.stopPropagation()
    history.push(
      withQueryString(
        routeTo.portfolio.assets.blockchain(blockchain).exchange,
        encodeTokenId(tokenId),
      ),
    )
  }

  return {
    onClick,
    onSend,
    onReceive,
    onExchange,
  }
}

type AssetItemLayoutProps = {
  blockchain: Blockchain
  FormattedLabel: React.ReactNode
  priceCol: React.ReactNode
  totalBalanceCol: JSX.Element
  availableBalanceCol: JSX.Element
  tokenMetadata?: TokenMetadata
  defaultAccountId?: AccountId
  isNft: boolean
}

export function AssetItemLayout({
  blockchain,
  FormattedLabel,
  priceCol,
  totalBalanceCol,
  availableBalanceCol,
  tokenMetadata,
  defaultAccountId,
  isNft,
}: AssetItemLayoutProps) {
  const SINGLE_BUTTON_WIDTH = 140

  const classes = useStyles()
  const {t} = useTranslation()

  const history = useHistory()

  // TODO: can route to disabled token for a blockchain, because is routed to defaultIconId. Route to first account which owns the token instead
  const handlers = defaultAccountId
    ? getAssetItemHandlers(
        history,
        blockchain,
        defaultAccountId,
        tokenMetadata?.id,
      )
    : null

  const hasAccounts = useHasAccounts(blockchain)
  const isBlockchainEnabled = useIsBlockchainEnabled(blockchain)

  return (
    <ElevatedCard
      type="light"
      tableCard
      onClick={handlers?.onClick}
      className={classes.wrapper}
      data-test-id="asset-item"
      data-blockchain={blockchain}
      data-token-id={tokenMetadata?.id}
    >
      <AssetListItemLayout
        assetCol={
          <AssetCard
            ellipsizeTitle
            tokenMetadata={tokenMetadata}
            isNft={isNft}
            blockchain={blockchain}
            label={FormattedLabel}
            showBlockchainBadge
          />
        }
        totalBalanceCol={totalBalanceCol}
        availableBalanceCol={availableBalanceCol}
        priceCol={priceCol}
        actionsCol={
          // [AddAccount or EnableBlockchain] No accounts logic is currently duplicated
          // in multiple places. We couldn't think of a way to abstract which would be
          // worth it so we settled on a bit of duplication. All the places with duplication
          // are tagged with the [AddAccount or EnableBlockchain] tag so they would be
          // easily searchable.
          !hasAccounts ? (
            <AddAccount
              preselectedBlockchain={blockchain}
              buttonWidth={SINGLE_BUTTON_WIDTH}
            />
          ) : !isBlockchainEnabled ? (
            <EnableBlockchain
              blockchain={blockchain}
              buttonWidth={SINGLE_BUTTON_WIDTH}
              startIcon={<PowerSettingsNew />}
              displayConfirmationModal
            />
          ) : (
            <Grid container spacing={4} justifyContent="flex-end">
              {/*
              When a blockchain has just been enabled, the default account is not yet set.
              In that case we can't render the action buttons since they depend on default
              account id being set.
              */}
              {handlers == null ? (
                <Grid item>
                  <TypographyLoader width={SINGLE_BUTTON_WIDTH} />
                </Grid>
              ) : (
                <>
                  <Grid item>
                    <TextButton
                      color="textSecondary"
                      fontWeight="medium"
                      onClick={handlers.onSend}
                      label={t('Send')}
                    />
                  </Grid>
                  <Grid item>
                    <TextButton
                      color="textSecondary"
                      fontWeight="medium"
                      onClick={handlers.onReceive}
                      label={t('Receive')}
                    />
                  </Grid>
                  {IS_EXCHANGE_ENABLED && (
                    <Grid item>
                      <OpenExchangeButton
                        variant="text"
                        onOpen={handlers.onExchange}
                        targetAsset={{blockchain, tokenId: tokenMetadata?.id}}
                      />
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          )
        }
      />
    </ElevatedCard>
  )
}

export const ASSET_ITEM_HEIGHT = 60

const useStyles = makeStyles((theme) => ({
  wrapper: {
    cursor: 'pointer',
    height: ASSET_ITEM_HEIGHT,
    background: theme.palette.background.paper,
  },
}))
