// IMPORTANT:
// Avoid other than type imports as this file often needs to be loaded
// to initialize packages that depend on values from ENVs before
// code using them can be loaded.

import {getIsWebExtension} from '@nufi/frontend-common'
import type {CardanoNetwork} from '@nufi/wallet-cardano'
import type {
  EthereumNetwork,
  MilkomedaC1Network,
  OptimismNetwork,
  PolygonNetwork,
  BaseNetwork,
  ArbitrumOneNetwork,
} from '@nufi/wallet-evm'
import type {FlowNetwork} from '@nufi/wallet-flow'
import type {SolanaCluster} from '@nufi/wallet-solana'

import type {Web3AuthNetworkType} from './features/login/infrastructure/web3Auth/types'

const cardanoNetwork = process.env.APP_CARDANO_NETWORK as CardanoNetwork

const config = {
  isProd: process.env.NODE_ENV === 'production',
  backendUrl: process.env.APP_BACKEND_URL as string,
  isTestnet: (process.env.APP_IS_TESTNET as string) === 'true',
  isStorybook: process.env.STORYBOOK_IS_STORYBOOK === 'true',

  // Feature flags
  isEthereumEnabled: (process.env.APP_ENABLE_ETHEREUM as string) === 'true',
  isEthereumConnectorEnabled:
    (process.env.APP_ENABLE_ETHEREUM_CONNECTOR as string) === 'true',
  isCloudSyncEnabled: (process.env.APP_ENABLE_CLOUD_SYNC as string) === 'true',
  isDAppDirectoryEnabled:
    (process.env.APP_ENABLE_DAPP_DIRECTORY as string) === 'true',
  isMilkomedaC1Enabled:
    (process.env.APP_ENABLE_MILKOMEDA_C1 as string) === 'true',
  isMilkomedaC1ConnectorEnabled:
    (process.env.APP_ENABLE_MILKOMEDA_C1_CONNECTOR as string) === 'true',
  isPolygonEnabled: (process.env.APP_ENABLE_POLYGON as string) === 'true',
  isWalletConnectEnabled:
    (process.env.APP_ENABLE_WALLET_CONNECT as string) === 'true',
  isWalletConnectEnabledSolana:
    process.env.APP_ENABLE_WALLET_CONNECT_SOLANA === 'true',
  isWalletConnectEnabledEvm:
    process.env.APP_ENABLE_WALLET_CONNECT_EVM === 'true',
  isOptimismEnabled: (process.env.APP_ENABLE_OPTIMISM as string) === 'true',
  isArbitrumOneEnabled:
    (process.env.APP_ENABLE_ARBITRUM_ONE as string) === 'true',
  isBaseEnabled: (process.env.APP_ENABLE_BASE as string) === 'true',
  isSnapEnabled: (process.env.APP_ENABLE_SNAP as string) === 'true',
  isWidgetEnabled: (process.env.APP_ENABLE_WIDGET as string) === 'true',
  isDexHunterEnabled:
    (process.env.APP_ENABLE_DEXHUNTER as string) === 'true' &&
    cardanoNetwork === 'mainnet',
  isNftHidingEnabled: (process.env.APP_ENABLE_NFT_HIDING as string) === 'true',
  logLevel: (() => {
    const envValue = process.env.APP_LOG_LEVEL as string
    if (envValue === 'debug') return 'debug' as const
    return 'none' as const
  })(),
  isWeb3AuthV2MigrationEnabled:
    (process.env.APP_ENABLE_WEB3_AUTH_V2_MIGRATION as string) === 'true',
  isEmbeddedMoonpayEnabled:
    // the Moonpay widget/SDK is inherently not supported in web extensions
    // because loading of externally hosted js is involved
    !getIsWebExtension() &&
    (process.env.APP_ENABLE_EMBEDDED_MOONPAY as string) === 'true',
  embeddedMoonpaySellEnabledBlockchains: (
    process.env.APP_EMBEDDED_MOONPAY_SELL_ENABLED_BLOCKCHAINS as string
  )
    ?.split(',')
    .filter(Boolean),

  // Snap
  snapOrigin: process.env.APP_SNAP_ORIGIN as string,

  // Solana
  solanaRpcUrl: process.env.APP_SOLANA_RPC_URL as string,
  solanaClusterName: process.env.APP_SOLANA_CLUSTER_NAME as SolanaCluster,

  // Cardano
  cardanoBackendUrl: process.env.APP_CARDANO_BACKEND_URL as string,
  cardanoNetwork,
  cardanoBlockfrostApiKey: process.env.APP_CARDANO_BLOCKFROST_API_KEY as string,
  cardanoBlockfrostUrl: process.env.APP_CARDANO_BLOCKFROST_URL as string,

  // Cardano's DexHunter
  dexHunterPartnerName: process.env.APP_DEXHUNTER_PARTNER_NAME as string,
  dexHunterPartnerCode: process.env.APP_DEXHUNTER_PARTNER_CODE as string,

  // Media gateways
  thumbnailUrl: process.env.APP_THUMBNAIL_URL as string,

  // Sentry
  appSentryDsn: process.env.APP_SENTRY_DSN as string,
  appSentryEnv: process.env.APP_SENTRY_ENV as string,

  // DEV
  demoMnemonic: process.env.APP_DEV_DEMO_MNEMONIC as string,
  demoPassword: process.env.APP_DEV_DEMO_PASSWORD as string,
  autoLogin: (process.env.APP_DEV_AUTO_LOGIN as string) === 'true',
  trezorConnectUrl: process.env.APP_DEV_TREZOR_CONNECT_URL as string,
  mockEvmSubmission: process.env.APP_DEV_MOCK_EVM_SUBMISSION === 'true',
  mockEvmGasOptions: process.env.APP_DEV_MOCK_EVM_GAS_OPTIONS === 'true',

  // Google Analytics
  GAMeasurementId: process.env.APP_GA_MEASUREMENT_ID as string,

  // Flow
  flowNetwork: process.env.APP_FLOW_NETWORK as FlowNetwork,
  flowAccessNodeUrl: process.env.APP_FLOW_ACCESS_NODE_URL as string,
  flowFlowportApiUrl: process.env.APP_FLOW_FLOWPORT_API_URL as string,
  flowdiverApiUrl: process.env.APP_FLOWDIVER_API_URL as string,

  // Ethereum
  ethereumNetwork: process.env.APP_ETHEREUM_NETWORK as EthereumNetwork,
  ethereumAlchemyApiUrl: process.env.APP_ETHEREUM_ALCHEMY_API_URL as string,

  // Milkomeda C1
  milkomedaC1Network: process.env
    .APP_MILKOMEDA_C1_NETWORK as MilkomedaC1Network,
  milkomedaC1RpcUrl: process.env.APP_MILKOMEDA_C1_RPC_URL as string,

  // Polygon
  polygonNetwork: process.env.APP_POLYGON_NETWORK as PolygonNetwork,
  polygonAlchemyApiUrl: process.env.APP_POLYGON_ALCHEMY_API_URL as string,

  optimismNetwork: process.env.APP_OPTIMISM_NETWORK as OptimismNetwork,
  optimismAlchemyApiUrl: process.env.APP_OPTIMISM_ALCHEMY_API_URL as string,

  arbitrumOneNetwork: process.env
    .APP_ARBITRUM_ONE_NETWORK as ArbitrumOneNetwork,
  arbitrumOneAlchemyApiUrl: process.env
    .APP_ARBITRUM_ONE_ALCHEMY_API_URL as string,

  baseNetwork: process.env.APP_BASE_NETWORK as BaseNetwork,
  baseAlchemyApiUrl: process.env.APP_BASE_ALCHEMY_API_URL as string,

  // Backend authentication
  apiEauthPrefix: process.env.APP_API_EAUTH_PREFIX as string,
  // Heroku escapes new line characters in env variables so we need to "un-escape" here
  apiJwtPublicKey: (process.env.APP_API_JWT_PUBLIC_KEY as string)?.replace(
    /\\n/g,
    '\n',
  ),

  // Web3Auth
  web3AuthNetworkV1: process.env
    .APP_WEB3_AUTH_NETWORK_V1 as Web3AuthNetworkType,
  web3AuthClientIdV1: process.env.APP_WEB3_AUTH_CLIENT_ID_V1 as string,
  web3AuthNetworkV2: process.env
    .APP_WEB3_AUTH_NETWORK_V2 as Web3AuthNetworkType,
  web3AuthClientIdV2: process.env.APP_WEB3_AUTH_CLIENT_ID_V2 as string,

  // Moonpay
  moonpayApiKey: process.env.APP_MOONPAY_API_KEY as string,

  isJest: process.env.NODE_ENV === 'jest',

  // CMS
  cmsUrl: process.env.APP_CMS_URL as string,

  // WalletConnect
  walletConnectProjectId: process.env.APP_WALLET_CONNECT_PROJECT_ID as string,

  // Blowfish
  blowfishApiKey: process.env.APP_BLOWFISH_API_KEY as string,
}

const validateConfig = () => {
  if (config.autoLogin && !config.demoMnemonic) {
    throw new Error(
      'ENV(APP_DEV_DEMO_MNEMONIC) is required when ENV(APP_DEV_AUTO_LOGIN) === true',
    )
  }
  if (config.autoLogin && !config.demoPassword) {
    throw new Error(
      'ENV(APP_DEV_DEMO_PASSWORD) is required when ENV(APP_DEV_AUTO_LOGIN) === true',
    )
  }
}

validateConfig()

export default config
