import React from 'react'

import type {DerivationPathType, HotVendor} from '../../../../types'

import {AddAccountForm} from './AddAccountForm'
import {useHotAccountPhase} from './AddAccountUtils'
import type {CommonAddAccountFormProps} from './types'

export function AddHotAccount<T extends DerivationPathType>(
  props: CommonAddAccountFormProps<T> & {cryptoProviderType: HotVendor},
) {
  const [addAccountPhase, setAddAccountPhase] = useHotAccountPhase()
  return (
    <AddAccountForm {...{addAccountPhase, setAddAccountPhase}} {...props} />
  )
}
