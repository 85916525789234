import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import {Typography, styled} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import type {ButtonProps} from 'src/components'
import {Button, FooterLayout} from 'src/components'

type Action = Pick<
  ButtonProps,
  'onClick' | 'children' | 'startIcon' | 'disabled'
>

export const OnboardingContent = ({
  title,
  description,
  imgSrc,
  actions,
  children,
  imageSpacing = true,
  onBack,
}: {
  title: React.ReactNode
  description?: React.ReactNode
  imgSrc?: string
  imageSpacing?: boolean
  children?: React.ReactNode
  actions?: {
    primary: Action
    secondary?: Action
  }
  onBack?: () => void
}) => {
  const {t} = useTranslation()
  return (
    <>
      <Typography variant="h3" variantMapping={{h3: 'h1'}} gutterBottom>
        {title}
      </Typography>
      {description && <Description>{description}</Description>}
      {imgSrc && <OnboardingImage src={imgSrc} spacing={imageSpacing} />}
      {children && children}
      {actions && (
        <>
          {actions.secondary ? (
            <FooterLayout
              leftBtnConf={{
                color: 'secondary',
                variant: 'contained',
                ...actions.secondary,
              }}
              rightBtnConf={{
                color: 'primary',
                variant: 'contained',
                ...actions.primary,
              }}
            />
          ) : (
            <SingleActionWrapper>
              <Button
                fullWidth
                color="primary"
                variant="contained"
                textTransform="none"
                {...actions.primary}
              />
            </SingleActionWrapper>
          )}
        </>
      )}
      {onBack && <BackButton onClick={onBack}>{t('Back')}</BackButton>}
    </>
  )
}

const BackButton = styled(Button)({
  position: 'absolute',
  bottom: 32,
  left: 32,
})

BackButton.defaultProps = {
  startIcon: <ChevronLeftIcon />,
  textTransform: 'none',
}

type OnboardingImageProps = {spacing?: boolean}

const OnboardingImage = styled('img', {
  shouldForwardProp: (prop) => prop !== 'spacing',
})<OnboardingImageProps>(({theme, spacing}) => ({
  height: '50%',
  width: 'auto',
  maxWidth: '100%',
  ...(spacing && {
    margin: theme.spacing(4, 0),
  }),
}))

const SingleActionWrapper = styled('div')({
  margin: '0 auto',
  width: '60%',
})

const Description = styled(Typography)({
  fontSize: '1.125rem',
})

Description.defaultProps = {
  variant: 'body1',
  color: 'textSecondary',
}
