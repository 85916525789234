import queryString from 'query-string'

import type {ProfileLoginType} from 'src/appStorage'
import type {RampOperation} from 'src/features/ramp/domain'
import type {AccountId, Blockchain, TokenId} from 'src/types'

export function toAppOpenerQueryString(
  intent: OpenAppIntent,
  platformParams: PlatformSpecificAppOpenerParams,
): string {
  const params: AppOpenerIntentParams = {
    encodedIntent: JSON.stringify(intent),
    encodedPlatformParams: JSON.stringify(platformParams),
  }
  return queryString.stringify(params)
}

export function parseAppOpenerQueryString(locationQueryString: string) {
  const parsedQueryString = queryString.parse(
    locationQueryString,
  ) as Partial<AppOpenerIntentParams>
  const encodedPlatformParams = parsedQueryString.encodedPlatformParams
  const encodedIntent = parsedQueryString.encodedIntent

  const intent = encodedIntent
    ? (JSON.parse(encodedIntent) as OpenAppIntent)
    : null

  const platformParams = encodedPlatformParams
    ? (JSON.parse(encodedPlatformParams) as PlatformSpecificAppOpenerParams)
    : null

  return {platformParams, intent}
}

export type OpenAppIntent =
  | {
      type: 'openLoggedOut'
    }
  | {
      type: 'openLoggedIn'
      loginType: ProfileLoginType
      action?: {
        type: 'addAccount'
        blockchain: Blockchain
      }
    }
  | {
      type: 'openLoggedIn'
      loginType: ProfileLoginType
      action?: {
        type: 'openBuyAndSell'
        blockchain: Blockchain
        accountId: AccountId
        operation: RampOperation
        tokenId: TokenId | null
      }
    }
  | {
      type: 'openLoggedIn'
      loginType: ProfileLoginType
      action?: {
        type: 'openDexHunter'
        tab: 'main' | 'history'
        accountId: AccountId
      }
    }
  | {
      type: 'openLoggedIn'
      loginType: ProfileLoginType
      action?: {
        type: 'openExchange'
        blockchain: Blockchain
        accountId: AccountId
      }
    }

export type OpenAppAction = NonNullable<
  Extract<OpenAppIntent, {type: 'openLoggedIn'}>['action']
>

export type OpenLoggedInAppIntent = Extract<
  OpenAppIntent,
  {type: 'openLoggedIn'}
>

export type PlatformSpecificAppOpenerParams =
  | {
      platform: 'extension'
      referrerTabId: number
    }
  | {
      platform: 'web'
      customChildWindowId?: string
    }

export type AppOpenerIntentParams = {
  encodedIntent: string
  encodedPlatformParams: string
}
