import {getMockServices} from '../__tests__/storybook/MockServiceLocator'

import {localProfileManagerLocator} from './profileManager'
import type {ProfilePassword} from './types'

export * from './queries'
export * from './mutations'
export * from './initCoreServices'
export * from './utils'
export * from './types'
export * from './errors'
export {default as MasterProfileManagerProvider} from './masterProfileManager'
export * from './profileManager'

export const verifyPassword = async (
  password: ProfilePassword,
): Promise<boolean> => {
  const mockServices = getMockServices()
  return mockServices
    ? mockServices.verifyPassword(password)
    : await localProfileManagerLocator.instance().verifyPassword(password)
}

export type VerifyPassword = typeof verifyPassword
