import type {FlowAccountInfo} from '@nufi/wallet-flow'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {QueryGuard, TransactionModalError} from '../../../../../../components'
import {useGetBlockchainName} from '../../../../../../utils/translations'
import {useGetSupportedTokenContracts} from '../../../../../../wallet/flow'
import {ImportTokenModalHeader} from '../ImportTokenUtils'
import type {ImportTxProps} from '../types'

import DetailsScreen from './DetailsScreen'
import {ImportFlowTokenFlow} from './ImportTokenFlow'

export function ImportFlowTokenTx({
  onClose,
  onBack,
  accountId,
  accounts,
}: Omit<ImportTxProps<FlowAccountInfo>, 'blockchain'>) {
  const {t} = useTranslation()
  const supportedTokenContractsQuery = useGetSupportedTokenContracts()
  const getBlockchainName = useGetBlockchainName()
  const renderModalHeader = () => (
    <ImportTokenModalHeader
      onClose={onClose}
      label={t('import_token_header', {blockchain: getBlockchainName('flow')})}
    />
  )
  const renderDetails = () => (
    <DetailsScreen
      ModalHeader={renderModalHeader()}
      {...{onClose, onBack, accountId, accounts}}
    />
  )
  return (
    <QueryGuard
      {...supportedTokenContractsQuery}
      loadingVariant="centered"
      ErrorElement={<TransactionModalError />}
    >
      {(tokenContracts) => (
        <ImportFlowTokenFlow
          {...{
            onClose,
            onBack,
            accountId,
            accounts,
            renderModalHeader,
            renderDetails,
            tokenContracts,
          }}
        />
      )}
    </QueryGuard>
  )
}
