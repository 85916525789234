/* eslint-disable @typescript-eslint/explicit-function-return-type */

import {Sentry} from '@nufi/frontend-common'
import {determineTxComputeUnitLimit} from '@nufi/wallet-solana'
import type {
  SolanaPubKey,
  SolanaTxFeeParams,
  SolanaTokenMetadata,
} from '@nufi/wallet-solana'
import {useQuery} from '@tanstack/react-query'

import queryClient from '../../../../queryClient'
import {secondsToMilliseconds} from '../../../utils/common'
import {solana} from '../../solanaManagers'

import {cachedGetIsOcpNft} from './nfts'
import {QUERY_KEY_PREFIX as P} from './utils'

export const feeQueryKeys = {
  fetchTxFeeParams: (
    affectedAccounts: SolanaPubKey[],
    containsOcpNft: boolean,
  ) => [
    P,
    'fetchTxFeeParams',
    affectedAccounts.map((a) => a.toString()),
    containsOcpNft,
  ],
  getTxFeeQueryParams: (
    affectedAccounts: SolanaPubKey[],
    includedTokens: SolanaTokenMetadata[],
  ) => [P, 'transactionFee', affectedAccounts, includedTokens.map((t) => t.id)],
} as const

const fetchTxFeeParams = async (
  affectedAccounts: SolanaPubKey[],
  containsOcpNft: boolean,
): Promise<SolanaTxFeeParams> => {
  const [signatureFee, computeUnitPrice] = await Promise.all([
    solana.wallet.getSignatureFee(),
    solana.blockchainApi.getSuggestedComputeUnitPrice(affectedAccounts),
  ])
  const txFeeParams = {
    signatureFee,
    computeUnitPrice,
    computeUnitLimit: determineTxComputeUnitLimit(containsOcpNft),
  }
  // TMP logging to debug tx timeout related issues
  Sentry.addBreadcrumb({
    level: 'info',
    category: 'solana.feeEstimate',
    message: `txFeeParams result: ${JSON.stringify(txFeeParams)}`,
  })

  return txFeeParams
}

/**
 * Wrapped in a query to reduce the number of API calls, dependent on the presence of OCP NFTs.
 */
const cachedFetchTxFeeParams = async (
  affectedAccounts: SolanaPubKey[],
  containsOcpNft: boolean,
) =>
  queryClient.fetchQuery({
    queryKey: feeQueryKeys.fetchTxFeeParams(affectedAccounts, containsOcpNft),
    queryFn: () => fetchTxFeeParams(affectedAccounts, containsOcpNft),
    staleTime: secondsToMilliseconds(15),
  })

const getTxFeeQueryParams = ({
  affectedAccounts,
  includedTokens,
}: {
  affectedAccounts: SolanaPubKey[]
  includedTokens: SolanaTokenMetadata[]
}) => ({
  queryKey: feeQueryKeys.getTxFeeQueryParams(affectedAccounts, includedTokens),
  queryFn: async () => {
    const containsOcpNft = (
      await Promise.all(includedTokens.map(cachedGetIsOcpNft))
    ).some((isOcp) => isOcp)
    return await cachedFetchTxFeeParams(affectedAccounts, containsOcpNft)
  },
})

export async function cachedGetTxFeeParams({
  affectedAccounts = [],
  includedTokens = [],
}: {
  // affectedAccounts optional due to un-prepared architecture for always being able to pass here all the
  // affected accounts from transfer/token/stake txs, since we fetch fee on the top level
  affectedAccounts?: SolanaPubKey[]
  includedTokens?: SolanaTokenMetadata[]
} = {}) {
  return await queryClient.fetchQuery(
    getTxFeeQueryParams({affectedAccounts, includedTokens}),
  )
}

export function useGetTxFeeParams({
  affectedAccounts = [],
  includedTokens = [],
}: {
  // affectedAccounts optional due to un-prepared architecture for always being able to pass here all the
  // affected accounts from transfer/token/stake txs, since we fetch fee on the top level
  affectedAccounts?: SolanaPubKey[]
  includedTokens?: SolanaTokenMetadata[]
} = {}) {
  return useQuery({
    ...getTxFeeQueryParams({affectedAccounts, includedTokens}),
    // HOTFIX: comment next line until we have a solution for freezing the fee in summary/signing screens
    // refetchInterval: secondsToMilliseconds(15), // refresh since fee is dynamic
  })
}

export type UseGetTxFeeParams = typeof useGetTxFeeParams
