import type {FlowAccountInfo, FlowSignedTransaction} from '@nufi/wallet-flow'
import {Formik} from 'formik'
import React from 'react'

import {
  useGetMaxSendableFlowAmount,
  useSignTx,
  useSubmitTx,
} from 'src/wallet/flow'

import {QueryGuard, StakingModalError} from '../../../../../components'
import type {AccountId} from '../../../../../types'
import {ensureAccountById} from '../../../../../wallet/utils/common'
import {
  useActiveSchema,
  useActiveScreenState,
  useFormikOnSubmit,
} from '../../../../transaction'

import {SetupStakingCollectionContent} from './ModalContent'
import {useSetupStakeSummarySchema} from './schema'
import type {FormSchema} from './types'

type SetupStakingCollectionFormProps = {
  onClose: () => unknown
  accountId: AccountId
  accounts: FlowAccountInfo[]
}

export function SetupStakingCollectionForm({
  onClose,
  accountId,
  accounts,
}: SetupStakingCollectionFormProps) {
  const setupStakingCollection = useSignTx()
  const submit = useSubmitTx()
  const {setActiveScreen} = useActiveScreenState()

  const schema = useActiveSchema({
    details: {},
    summary: useSetupStakeSummarySchema({accounts}),
  })

  const initialValues: FormSchema = {
    accountId: accountId || accounts[0]!.id,
    password: '',
  }

  const onTxSign = async (values: FormSchema) =>
    await setupStakingCollection.mutateAsyncSilent({
      txPlan: {type: 'setupStakingCollection', args: null},
      accountInfo: ensureAccountById(accounts, values.accountId),
    })

  const onTxSubmit = async (
    accountId: AccountId,
    signedTx: FlowSignedTransaction,
  ) => await submit.mutateAsyncSilent({accountId, signedTx})

  const onTxSignAndSubmit = async (values: FormSchema) => {
    const signedTx = await onTxSign(values)
    if (!signedTx) return
    setActiveScreen('submit')
    await onTxSubmit(values.accountId as AccountId, signedTx)
  }

  const onSubmit = useFormikOnSubmit<FormSchema>({
    accounts,
    onTxSignAndSubmit,
  })
  return (
    <QueryGuard
      {...useGetMaxSendableFlowAmount({
        accountInfo: ensureAccountById(accounts, accountId),
        txType: 'setupStakingCollection',
      })}
      ErrorElement={<StakingModalError blockchain="flow" />}
      loadingVariant="centered"
    >
      {({fee}) => (
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={onSubmit}
        >
          {(formikProps) => (
            <SetupStakingCollectionContent
              {...{
                formikProps,
                accounts,
                setActiveScreen,
                onClose,
                onTxSubmit,
                onTxSignAndSubmit,
                txFee: fee,
              }}
              submitProps={submit}
              signProps={setupStakingCollection}
            />
          )}
        </Formik>
      )}
    </QueryGuard>
  )
}
