import type {SxProps, Theme} from '@mui/material'
import {
  Typography,
  Grid,
  FormControl,
  Box,
  Select,
  InputLabel,
  MenuItem,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import {useTranslation} from 'react-i18next'

import type {Blockchain} from '../../../types'
import {useGetBlockchainName} from '../../../utils/translations'
import {useIsMobile} from '../../useIsMobile'
import {BlockchainIcon} from '../atoms/Icon'

import SearchableSelect from './SearchableSelect'

export type BlockchainSelectFieldProps<
  TBlockchain extends Blockchain = Blockchain,
> = {
  value: TBlockchain | null
  onChange: (value: TBlockchain | null) => void
  allowedBlockchains: ReadonlyArray<TBlockchain>
  label?: string
  sx?: SxProps<Theme>
  selectClasses?: Record<string, string>
  error?: boolean
  dataTestId?: string
  getBlockchainName?: (value: Blockchain) => string
  getOptionDisabled?: (value: Blockchain) => boolean
  renderOptionExtraContent?: (option: TBlockchain) => React.ReactNode
}

export function BlockchainSelectField<
  TBlockchain extends Blockchain = Blockchain,
>({
  onChange,
  value,
  label,
  allowedBlockchains,
  sx,
  error = false,
  selectClasses = {},
  getBlockchainName: customGetBlockchainName,
  getOptionDisabled,
  dataTestId,
  renderOptionExtraContent,
}: BlockchainSelectFieldProps<TBlockchain>) {
  const classes = useStyles()
  const {t} = useTranslation()
  const isMobileDevice = useIsMobile()
  const defaultGetBlockchainName = useGetBlockchainName()
  const getBlockchainName = customGetBlockchainName || defaultGetBlockchainName
  const items = [...allowedBlockchains]

  return (
    <FormControl variant="outlined" fullWidth data-test-id={dataTestId} sx={sx}>
      {/* For the mobile devices, the UX is better without autocomplete */}
      {isMobileDevice ? (
        <>
          <InputLabel>{label}</InputLabel>
          <Select
            label={label}
            onChange={(e) => onChange((e.target.value as TBlockchain) || null)}
            value={value}
          >
            <MenuItem value="">{t('None')}</MenuItem>
            {items.map((blockchain) => (
              <MenuItem key={blockchain} value={blockchain}>
                <MenuItemContent
                  {...{blockchain, getBlockchainName, renderOptionExtraContent}}
                />
              </MenuItem>
            ))}
          </Select>
        </>
      ) : (
        <SearchableSelect
          {...{onChange, value, selectClasses, label, error, getOptionDisabled}}
          options={items}
          getOptionLabel={(blockchain) => getBlockchainName(blockchain)}
          renderRow={(option) => (
            <MenuItemContent
              blockchain={option}
              {...{getBlockchainName, renderOptionExtraContent}}
            />
          )}
          renderStartAdornment={(blockchain) =>
            blockchain && (
              <Box className={classes.startIcon}>
                <BlockchainIcon blockchain={blockchain} exactSize={24} />
              </Box>
            )
          }
          searchable
          MAX_DISPLAYED_ITEMS={items.length}
        />
      )}
    </FormControl>
  )
}

type MenuItemContentProps<TBlockchain extends Blockchain = Blockchain> = Pick<
  BlockchainSelectFieldProps<TBlockchain>,
  'renderOptionExtraContent'
> & {blockchain: TBlockchain; getBlockchainName: (value: TBlockchain) => string}

const MenuItemContent = <TBlockchain extends Blockchain = Blockchain>({
  blockchain,
  getBlockchainName,
  renderOptionExtraContent,
}: MenuItemContentProps<TBlockchain>) => {
  const classes = useStyles()
  return (
    <Grid container alignItems="center" justifyContent="space-between">
      <Grid item display="flex">
        <BlockchainIcon blockchain={blockchain} exactSize={24} />
        <Typography
          variant="body1"
          className={classes.name}
          {...{'data-test-id': `blockchain-select-${blockchain}`}}
        >
          {getBlockchainName(blockchain)}
        </Typography>
      </Grid>
      <Grid item>
        {renderOptionExtraContent && renderOptionExtraContent(blockchain)}
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles((theme) => ({
  name: {
    paddingLeft: theme.spacing(2),
  },
  startIcon: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}))
