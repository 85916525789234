import {Grid, Box, Typography} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import type {FormikHelpers} from 'formik'
import {Formik} from 'formik'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import * as yup from 'yup'

import type {ProfileName, ProfileMetadata} from '../../appStorage'
import {
  SideBarLayout,
  Button,
  NavigateBack,
  ClearableTextField,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalLayout,
  Alert,
} from '../../components'
import {MAX_WALLET_NAME_LENGTH} from '../../constants'
import {getHasFormError} from '../../utils/form'

import {
  useCommonProfileStyles,
  NavigateBackWrapper,
  CreateProfileTimeline,
  profileNameExists,
} from './common'
import {TermsAndConditionsCheckbox} from './TermsAndConditionsCheckbox'
import type {WalletType} from './types'

type Props = {
  walletType: WalletType
  onSubmit: (name: ProfileName) => unknown
  onBack: () => unknown
  name?: string
  profiles: Array<ProfileMetadata>
}

const useGenerateProfileName = (profiles: Array<ProfileMetadata>) => {
  const {t} = useTranslation()

  let i = 1
  while (profileNameExists(t('profile_name', {index: i}), profiles)) {
    i++
  }

  return t('profile_name', {index: i})
}

export default function ProfileNamePage({
  walletType,
  onSubmit,
  onBack,
  name,
  profiles,
}: Props) {
  const {t} = useTranslation()
  const commonClasses = useCommonProfileStyles()
  const generatedName = useGenerateProfileName(profiles)
  const [showHwWalletAlert, setShowHwWalletAlert] = useState<boolean>(
    walletType === 'hw',
  )
  const [termsChecked, setTermsChecked] = useState<boolean | null>(null)
  const _formSchema = {
    name: yup
      .string()
      .test('unique-profile-name', t('Wallet name exists.'), (value) => {
        if (value === undefined) return true
        return !profileNameExists(value, profiles)
      })
      .test(
        'profile-name-length',
        t('wallet_name_max_length', {length: MAX_WALLET_NAME_LENGTH}),
        (value) => {
          if (value === undefined) return true
          return value.length <= MAX_WALLET_NAME_LENGTH
        },
      )
      .required(t('Name is required.')),
  }
  type FormField = keyof typeof _formSchema
  type FormData = {[k in FormField]: string}
  const formSchema = yup.object().shape(_formSchema)

  const onSubmitProfileName = (
    values: FormData,
    {setSubmitting}: FormikHelpers<FormData>,
  ) => {
    if (termsChecked) {
      onSubmit(values.name as ProfileName)
    } else {
      setTermsChecked(false)
      setSubmitting(false)
    }
  }
  const onHwWalletAlertClose = () => {
    setShowHwWalletAlert(false)
  }

  const initialValues: FormData = {name: name || generatedName}

  return (
    <>
      <SideBarLayout
        left={<CreateProfileTimeline step="name" {...{walletType}} />}
        right={
          <Formik
            initialValues={initialValues}
            validationSchema={formSchema}
            onSubmit={onSubmitProfileName}
          >
            {(formikProps) => {
              const {handleChange, handleSubmit, values, errors, isSubmitting} =
                formikProps
              const hasError = getHasFormError(formikProps)
              return (
                <Box className={commonClasses.wrapper}>
                  <Typography variant="h5">
                    {t('Choose a name for this wallet')}
                  </Typography>

                  <Typography
                    className={commonClasses.headerSubtitle}
                    variant="body1"
                    color="textSecondary"
                  >
                    {t(
                      'This will help you distinguish between multiple wallets saved on the same device.',
                    )}
                  </Typography>

                  <form
                    className={commonClasses.form}
                    onSubmit={handleSubmit}
                    noValidate
                  >
                    <Grid container>
                      <Grid item xs={12}>
                        <ClearableTextField
                          value={values.name}
                          onChange={handleChange<FormField>('name')}
                          label={t('Wallet name')}
                          error={hasError('name')}
                          helperText={hasError('name') && errors.name}
                          variant="outlined"
                          onClear={() => handleChange<FormField>('name')('')}
                          fullWidth
                          autoFocus
                          data-test-id="wallet-name-field"
                        />
                      </Grid>
                      <Grid item xs={12} marginTop={1.5}>
                        <TermsAndConditionsCheckbox
                          value={!!termsChecked}
                          onChange={() => setTermsChecked(!termsChecked)}
                          error={termsChecked === false}
                          helperText={t('Field must be checked.')}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          className={commonClasses.submitButton}
                          textTransform="none"
                          fullWidth
                          variant="contained"
                          type="submit"
                          color="primary"
                          disabled={isSubmitting}
                        >
                          {t('Continue')}
                        </Button>
                      </Grid>
                    </Grid>
                  </form>

                  <NavigateBackWrapper>
                    <NavigateBack onBack={onBack} />
                  </NavigateBackWrapper>
                </Box>
              )
            }}
          </Formik>
        }
      />
      <HwWalletAlert
        open={showHwWalletAlert}
        onClose={() => onHwWalletAlertClose()}
      />
    </>
  )
}

function HwWalletAlert({open, onClose}: {open: boolean; onClose: () => void}) {
  const {t} = useTranslation()
  const classes = useStyles()

  return (
    <Modal open={open} variant="centered" onClose={() => onClose()}>
      <ModalLayout
        header={
          <ModalHeader onClose={() => onClose()} hasDivider>
            <Typography variant="h5">
              {t('Important: Please read carefully')}
            </Typography>
          </ModalHeader>
        }
        body={
          <Box maxWidth={560} p={2} className={classes.modalBody}>
            <Alert
              severity="info"
              text={t(
                'You will be able to pair your hardware wallet and its accounts after choosing a wallet name and setting a password.',
              )}
            />
            <Alert
              severity="info"
              text={t(
                'This password is used to log in each time you visit NuFi. All transactions must be confirmed using the HW device.',
              )}
            />
            <Alert
              severity="info"
              text={t(
                'We support Ledger Nano S/X, Trezor Model T/Safe 3/Safe 5 and GridPlus Lattice1.',
              )}
            />
          </Box>
        }
        footer={
          <ModalFooter hasDivider>
            <Box textAlign="center">
              <Button
                textTransform="none"
                color="primary"
                variant="contained"
                onClick={() => onClose()}
              >
                {t('Got it!')}
              </Button>
            </Box>
          </ModalFooter>
        }
      />
    </Modal>
  )
}

const useStyles = makeStyles((theme) => ({
  modalBody: {
    '& > :not(:first-child)': {
      marginTop: theme.spacing(2),
    },
  },
}))
