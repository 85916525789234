import {Box, Grid, Typography} from '@mui/material'
import {safeAssertUnreachable} from '@nufi/frontend-common'
import type {UseQueryResult} from '@tanstack/react-query'
import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {GridPlusFailedExport} from 'src/pages/hw/gridPlus'

import {Alert, MutationGuard} from '../../../../components'
import type {
  Blockchain,
  CryptoProviderType,
  AccountInfo,
  AccountId,
  AllAvailableAccountsSuccessResponse,
  DerivationPathType,
  AllAvailableAccountsResponse,
} from '../../../../types'
import {ensureAccountById} from '../../../../wallet/utils/common'
import {LedgerFailedExport} from '../../../hw/ledger'
import {TrezorFailedExport} from '../../../hw/trezor'

import type {DerivationPathTypeState} from './AddAccountUtils'
import {
  SectionNextButton,
  SectionHeader,
  SectionMessage,
  CanNotAddAccountReason,
  DerivationPathTypeButtons,
} from './AddAccountUtils'
import {AccountList, AddAccountNameField} from './common'
import {DiscoveredAccountsList} from './DiscoveredAccountsList'
import type {AddAccountFieldProps, NewAccountWithName} from './types'

const useMakeGetErrorElement = (variant: 'choose' | 'confirm') => {
  const {t} = useTranslation()
  return (
    blockchain: Blockchain,
    cryptoProviderType: CryptoProviderType,
    onRetry: () => Promise<unknown>,
    error: unknown,
  ) => {
    switch (cryptoProviderType) {
      case 'ledger':
        return <LedgerFailedExport {...{blockchain, onRetry, error}} />
      case 'trezor':
        return <TrezorFailedExport {...{onRetry}} />
      case 'gridPlus':
        return <GridPlusFailedExport {...{onRetry, error}} />
      case 'mnemonic':
      case 'metamask':
        return (
          <Alert
            severity="error"
            text={
              variant === 'choose'
                ? t('Could not load accounts')
                : t('Could not add account')
            }
          />
        )
      default:
        return safeAssertUnreachable(cryptoProviderType)
    }
  }
}
type ConfirmationPhaseProps = {
  blockchain: Blockchain
  selectedAccounts: NewAccountWithName[]
  cryptoProviderType: CryptoProviderType
  isPending: boolean
  error: unknown
  reset: () => void
  LoadingElement?: React.ReactElement
  handleSubmit: () => void
  alert?: string
} & {getFieldHandlers: (index: number) => AddAccountFieldProps}

export function ConfirmationPhase({
  blockchain,
  selectedAccounts,
  isPending,
  error,
  cryptoProviderType,
  LoadingElement,
  reset,
  getFieldHandlers,
  handleSubmit,
  alert,
}: ConfirmationPhaseProps) {
  const {t} = useTranslation()
  const getErrorElement = useMakeGetErrorElement('confirm')

  if (LoadingElement && isPending) return LoadingElement

  if (error && !isPending) {
    const onRetry = () => Promise.resolve(reset())
    return getErrorElement(blockchain, cryptoProviderType, onRetry, error)
  }

  return (
    <form>
      <SectionHeader>{t('Edit account names (optional)')}</SectionHeader>
      <SectionMessage>
        {t(
          'You can do this at any time from the Accounts screen; click Manage Account on the right then Rename Account.',
        )}
      </SectionMessage>
      <AccountList>
        <Box sx={{p: 2, '& > *': {mb: 3}}}>
          {selectedAccounts.map(({account, name}, i) => {
            return (
              <div key={account.id}>
                <AddAccountNameField
                  {...getFieldHandlers(i)}
                  value={name}
                  address={account.address ?? account.formattedDerivationPath}
                  balance={account.balance}
                  blockchain={blockchain}
                />
              </div>
            )
          })}
        </Box>
      </AccountList>
      {alert && (
        <Box sx={{mt: 1}}>
          <Alert severity="info">{alert}</Alert>
        </Box>
      )}
      <SectionNextButton label={t('Continue')} onClick={handleSubmit} />
      <MutationGuard {...{isPending, error}} />
    </form>
  )
}

type ChooseAccountPhaseProps<T extends DerivationPathType> = {
  blockchain: Blockchain
  onNext: () => void
  selectedAccounts: NewAccountWithName[]
  onSelectItem: (account: AccountInfo) => void
  resetItems: () => void
  cryptoProviderType: CryptoProviderType
  discoveredAccountsQuery: UseQueryResult<AllAvailableAccountsResponse>
  maxAccountLimit?: number
  prevMustBeUsed?: boolean
  derivationPathTypeState?: DerivationPathTypeState<T>
  alert?: string
}

export function ChooseAccountPhase<T extends DerivationPathType>({
  onNext,
  selectedAccounts,
  discoveredAccountsQuery,
  cryptoProviderType,
  maxAccountLimit,
  prevMustBeUsed,
  blockchain,
  derivationPathTypeState,
  alert,
  onSelectItem,
  resetItems,
}: ChooseAccountPhaseProps<T>) {
  const {t} = useTranslation()
  const getErrorElement = useMakeGetErrorElement('choose')

  const CanNotAddAccountReasonComponent = CanNotAddAccountReason(
    discoveredAccountsQuery.data,
    blockchain,
    cryptoProviderType,
  )

  const data: AllAvailableAccountsSuccessResponse | undefined = useMemo(() => {
    const {data: queryData} = discoveredAccountsQuery
    if (!queryData || 'canNotAddAccountReason' in queryData) return undefined
    return queryData
  }, [discoveredAccountsQuery.data])

  const standardAccounts = data?.accounts
  const onSelectedAccountChange = (id: AccountId | null) => {
    if (data && id != null) {
      const {accounts, otherAccounts} = data
      const allAccounts = [...accounts, ...(otherAccounts || [])]
      const account = ensureAccountById(allAccounts, id)
      onSelectItem(account)
    } else {
      resetItems()
    }
  }

  if (discoveredAccountsQuery.error && !discoveredAccountsQuery.isLoading) {
    return getErrorElement(
      blockchain,
      cryptoProviderType,
      discoveredAccountsQuery.refetch,
      discoveredAccountsQuery.error,
    )
  }

  const renderContentProps = {
    discoveredAccounts: data,
    selectedAccountIds:
      selectedAccounts?.map(({account}) => account.id) ?? null,
    setSelectedAccountId: onSelectedAccountChange,
  }

  return (
    <Grid container>
      {derivationPathTypeState && (
        <Grid container item xs={12} mb={2} justifyContent="center">
          <DerivationPathTypeButtons
            resetSelectedAccounts={resetItems}
            isLoading={discoveredAccountsQuery.isLoading}
            {...derivationPathTypeState}
          />
        </Grid>
      )}
      {CanNotAddAccountReasonComponent ? (
        <>{CanNotAddAccountReasonComponent}</>
      ) : (
        <Grid item xs={12}>
          <DiscoveredAccountsList
            accountDiscoveryResponse={data}
            {...{blockchain, ...renderContentProps}}
          />

          {prevMustBeUsed &&
            standardAccounts &&
            standardAccounts.length !== maxAccountLimit && (
              <HelperNote>
                {t(
                  'In order to see more accounts, the last displayed account must be used.',
                )}
              </HelperNote>
            )}

          {standardAccounts && standardAccounts.length === maxAccountLimit && (
            <HelperNote>
              {t('max_hw_account_count', {limit: maxAccountLimit})}
            </HelperNote>
          )}

          {alert && (
            <Box sx={{mt: 1}}>
              <Alert severity="info">{alert}</Alert>
            </Box>
          )}

          <SectionNextButton
            disabled={
              !selectedAccounts.length || discoveredAccountsQuery.isLoading
            }
            onClick={onNext}
            label={
              discoveredAccountsQuery.isLoading
                ? t('Loading...')
                : selectedAccounts
                  ? t('Continue')
                  : t('Choose accounts...')
            }
          />
        </Grid>
      )}
    </Grid>
  )
}

function HelperNote({children}: {children: string}) {
  return (
    <Box mt={2}>
      <Typography variant="caption">{children}</Typography>
    </Box>
  )
}
