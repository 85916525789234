import type {FormikProps, FormikHelpers} from 'formik'
import React from 'react'
import * as yup from 'yup'

import type {WeakUseMutationResult} from 'src/utils/mutation-utils'

import type {AccountId, AccountInfo, Blockchain, HwVendor} from '../../../types'
import {assert, safeAssertUnreachable} from '../../../utils/assertion'
import type {SchemaWithPassword} from '../../../utils/form'
import {useVerifyPassword} from '../../../utils/form'
import {ensureAccountById} from '../../../wallet/utils/common'
import {SignGridPlusScreen} from '../SignGridPlus'
import {SignLedgerScreen} from '../SignLedger'
import {SignMnemonicScreen} from '../SignMnemonic'
import {SignTrezorScreen} from '../SignTrezor'
import {SubmitScreen} from '../Submit'

import type {MultiStepFormContentScreen} from './activeScreen'
import {useActiveScreenState} from './activeScreen'

type BaseTxSchema = {
  accountId: AccountId
  password: string
}

type UseHandleTxOnSubmit<T extends BaseTxSchema> = {
  onTxSignAndSubmit: (values: T) => Promise<unknown>
}

export type HandleTxOnSubmit<T extends BaseTxSchema> = {
  account: AccountInfo
  values: T
}

export function useHandleTxOnSubmit<T extends BaseTxSchema = BaseTxSchema>({
  onTxSignAndSubmit,
}: UseHandleTxOnSubmit<T>) {
  const {setActiveScreen} = useActiveScreenState()

  return async function ({account, values}: HandleTxOnSubmit<T>) {
    switch (account.cryptoProviderType) {
      case 'metamask':
      case 'mnemonic': {
        setActiveScreen('sign-mnemonic')
        await onTxSignAndSubmit(values)
        break
      }
      case 'ledger': {
        setActiveScreen('sign-ledger')
        break
      }
      case 'trezor': {
        setActiveScreen('sign-trezor')
        break
      }
      case 'gridPlus': {
        setActiveScreen('sign-gridplus')
        break
      }
      default:
        safeAssertUnreachable(account.cryptoProviderType)
    }
  }
}

type UseFormikOnSubmitProps<T extends BaseTxSchema> = {
  accounts: Array<AccountInfo>
  onTxSignAndSubmit: (values: T) => Promise<unknown>
  // Note: `any` is intentional here, as this is how formik definitions looks like
  /* eslint-disable @typescript-eslint/no-explicit-any */
  onDetails?: (
    values: T,
    formikHelpers: FormikHelpers<T>,
  ) => void | Promise<any>
  onSummary?: (
    values: T,
    formikHelpers: FormikHelpers<T>,
  ) => void | Promise<any>
  /* eslint-enable @typescript-eslint/no-explicit-any */
}

export function useFormikOnSubmit<T extends BaseTxSchema>({
  accounts,
  onTxSignAndSubmit,
  onDetails,
  onSummary,
}: UseFormikOnSubmitProps<T>) {
  const verifyPassword = useVerifyPassword()
  const {activeScreen, setActiveScreen} = useActiveScreenState()
  const handleTxOnSubmit = useHandleTxOnSubmit({onTxSignAndSubmit})

  const onSubmit = {
    details:
      onDetails ||
      ((values: T, {setSubmitting}: FormikHelpers<T>) => {
        setActiveScreen('summary')
        setSubmitting(false)
      }),
    summary:
      onSummary ||
      (async (values: T, formikHelpers: FormikHelpers<T>) => {
        const passwordIsCorrect = await verifyPassword(
          // casted to `FormikHelpers<BaseTxSchema>` as verifyPassword has issues with generic type
          values.password,
          formikHelpers as FormikHelpers<SchemaWithPassword>,
        )
        if (!passwordIsCorrect) return

        const account = ensureAccountById(accounts, values.accountId)
        await handleTxOnSubmit({values, account})
      }),
    // not handled on formik level
    'sign-mnemonic': () => {
      /**/
    },
    'sign-ledger': () => {
      /**/
    },
    'sign-trezor': () => {
      /**/
    },
    'sign-gridplus': () => {
      /**/
    },
    submit: () => {
      /**/
    },
  }[activeScreen]
  return onSubmit
}

type UseSignAndSubmitUtilsProps<T extends BaseTxSchema> = {
  formikProps: FormikProps<T>
  sign: WeakUseMutationResult<unknown, unknown, unknown, unknown>
  submit: WeakUseMutationResult<unknown, unknown, unknown, unknown>
  onBack?: () => void
  initialScreen?: MultiStepFormContentScreen
}

function useSignAndSubmitUtils<T extends BaseTxSchema>({
  sign,
  submit,
  formikProps,
  onBack,
  initialScreen = 'details',
}: UseSignAndSubmitUtilsProps<T>) {
  const {setActiveScreen} = useActiveScreenState()

  const _onBack = () => {
    onBack ? onBack() : setActiveScreen(initialScreen)
  }

  const onSignAndSubmitGoBack = () => {
    sign.reset()
    submit.reset()
    onBack ? onBack() : setActiveScreen(initialScreen)
  }

  const onNewTransaction = () => {
    onSignAndSubmitGoBack()
    formikProps.resetForm()
  }

  return {
    onBack: _onBack,
    onSignAndSubmitGoBack,
    onNewTransaction,
  }
}

export type SignAndSubmitUtils = ReturnType<typeof useSignAndSubmitUtils>
export type _RenderScreen = (utils: SignAndSubmitUtils) => React.ReactElement

export type SignTxFlowProps<
  T extends BaseTxSchema,
  SignedTx,
  TxHash extends string,
> = {
  onClose: () => void
  blockchain: Blockchain
  ModalHeader: React.ReactNode
  onTxSignAndSubmit: (values: T) => Promise<unknown>
  onTxSubmit: (accountId: AccountId, signedTx: SignedTx) => Promise<unknown>
  formikProps: FormikProps<T>
  signProps: WeakUseMutationResult<SignedTx, unknown, unknown, unknown>
  submitProps: WeakUseMutationResult<TxHash, unknown, unknown, unknown>
  DeviceReadyState: ({hwVendor}: {hwVendor: HwVendor}) => React.ReactElement

  onBack?: () => void
  renderDetails?: _RenderScreen
  renderSummary: _RenderScreen
  renderSignMnemonic?: _RenderScreen
  renderSignLedger?: _RenderScreen
  renderSignTrezor?: _RenderScreen
  renderSignGridPlus?: _RenderScreen
  renderSubmit?: _RenderScreen
  CustomSuccessScreen?: React.ReactNode
  initialScreen?: MultiStepFormContentScreen
}

export function SignTxFlow<
  T extends BaseTxSchema,
  SignedTx,
  TxHash extends string,
>({
  renderDetails,
  renderSummary,
  renderSignMnemonic,
  renderSignLedger,
  renderSignTrezor,
  renderSignGridPlus,
  renderSubmit,
  signProps,
  submitProps,
  formikProps,
  onTxSignAndSubmit,
  onClose,
  onBack,
  ModalHeader,
  blockchain,
  onTxSubmit,
  DeviceReadyState,
  initialScreen,
  CustomSuccessScreen,
}: SignTxFlowProps<T, SignedTx, TxHash>) {
  const {activeScreen} = useActiveScreenState()

  const signAndSubmitUtils = useSignAndSubmitUtils({
    sign: signProps,
    submit: submitProps,
    formikProps,
    initialScreen,
    onBack,
  })
  const {onSignAndSubmitGoBack, onNewTransaction} = signAndSubmitUtils

  switch (activeScreen) {
    case 'details':
      return renderDetails ? renderDetails(signAndSubmitUtils) : null
    case 'summary':
      return renderSummary(signAndSubmitUtils)
    case 'sign-mnemonic':
      return renderSignMnemonic ? (
        renderSignMnemonic(signAndSubmitUtils)
      ) : (
        <SignMnemonicScreen
          onBack={onSignAndSubmitGoBack}
          onRetry={() => onTxSignAndSubmit(formikProps.values)}
          {...{onClose, blockchain, ModalHeader, signProps}}
        />
      )
    case 'sign-ledger':
      return renderSignLedger ? (
        renderSignLedger(signAndSubmitUtils)
      ) : (
        <SignLedgerScreen
          onBack={onSignAndSubmitGoBack}
          onSign={() => onTxSignAndSubmit(formikProps.values)}
          ReadyContent={<DeviceReadyState hwVendor="ledger" />}
          {...{onClose, signProps, blockchain, ModalHeader}}
        />
      )
    case 'sign-trezor':
      return renderSignTrezor ? (
        renderSignTrezor(signAndSubmitUtils)
      ) : (
        <SignTrezorScreen
          onBack={onSignAndSubmitGoBack}
          ReadyContent={<DeviceReadyState hwVendor="trezor" />}
          onSign={() => onTxSignAndSubmit(formikProps.values)}
          {...{onClose, signProps, ModalHeader}}
        />
      )
    case 'sign-gridplus':
      return renderSignGridPlus ? (
        renderSignGridPlus(signAndSubmitUtils)
      ) : (
        <SignGridPlusScreen
          onBack={onSignAndSubmitGoBack}
          ReadyContent={<DeviceReadyState hwVendor="gridPlus" />}
          onSign={() => onTxSignAndSubmit(formikProps.values)}
          {...{onClose, signProps, ModalHeader}}
        />
      )
    case 'submit': {
      const signedTransaction = signProps.data
      assert(!!signedTransaction)
      return renderSubmit ? (
        renderSubmit(signAndSubmitUtils)
      ) : (
        <SubmitScreen
          accountId={formikProps.values.accountId}
          onBack={onSignAndSubmitGoBack}
          onRetry={() =>
            onTxSubmit(formikProps.values.accountId, signedTransaction)
          }
          {...{
            onClose,
            submitProps,
            blockchain,
            ModalHeader,
            onNewTransaction,
            CustomSuccessScreen,
          }}
        />
      )
    }
    default:
      return safeAssertUnreachable(activeScreen)
  }
}

// Using `any` because exact yup.schema type is more complex and we are not performing
// extra operations on it
// eslint-disable-next-line
type WeakObj = Record<string, any>

type UseSchemaProps = {
  details: WeakObj
  summary: WeakObj
}

export function useActiveSchema({details, summary}: UseSchemaProps) {
  const {activeScreen} = useActiveScreenState()
  const schemas = {
    details,
    summary,
    'sign-ledger': {},
    'sign-trezor': {},
    'sign-mnemonic': {},
    'sign-gridplus': {},
    submit: {},
  }
  return yup.object().shape(schemas[activeScreen])
}
