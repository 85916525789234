/* eslint-disable @typescript-eslint/explicit-function-return-type */

import {fetchNeverStaleQuery, useNeverStaleQuery} from 'src/utils/query-utils'
import {cachedGetFungibleTokenList} from 'src/wallet/flow/application'

import {flow} from '../../../flowManagers'

import {tokensQueryKeys} from './queryKeys'

//
// __CACHED__ QUERIES
//

async function getSupportedTokenContracts() {
  const [fungibleTokenContracts, nftContracts] = await Promise.all([
    (await cachedGetFungibleTokenList()).map(
      ({contractMetadata}) => contractMetadata,
    ),
    flow.wallet.getNftCollections(),
  ])
  return [...fungibleTokenContracts, ...nftContracts]
}

export function cachedGetSupportedTokenContracts() {
  return fetchNeverStaleQuery({
    queryKey: tokensQueryKeys.supportedTokenContracts,
    queryFn: getSupportedTokenContracts,
  })
}

export function useGetSupportedTokenContracts(enabled = true) {
  return useNeverStaleQuery({
    queryKey: tokensQueryKeys.supportedTokenContracts,
    queryFn: getSupportedTokenContracts,
    enabled,
  })
}
