/* eslint-disable @typescript-eslint/explicit-function-return-type */

import type {SolanaAccountInfo} from '@nufi/wallet-solana'

import {
  cachedGetAccounts as cachedGetGenericAccounts,
  useGetAccounts as useGetGenericAccounts,
} from '../../../public/queries/accounts'

export const cachedGetAccounts = async (): Promise<SolanaAccountInfo[]> => {
  return cachedGetGenericAccounts<SolanaAccountInfo>('solana')
}

export const useGetAccounts = (enabled = true) => {
  return useGetGenericAccounts<SolanaAccountInfo>('solana', enabled)
}
