import {DeleteForeverOutlined as DeleteIcon} from '@mui/icons-material'
import {Box, Paper} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {Button, SafeCenterAligner} from '../../components'

import DeleteProfileModal from './DeleteProfileModal'
import {useCommonStyles} from './utils'

export default function DeleteProfile() {
  const {t} = useTranslation()
  const classes = useStyles()
  const commonClasses = useCommonStyles()

  const [showDeleteProfileModal, setShowDeleteProfileModal] = useState(false)

  return (
    <Box className={commonClasses.sectionWrapper}>
      <Paper elevation={0} className={classes.deleteProfileInfo}>
        <SafeCenterAligner>
          <Box className={classes.iconWrapper}>
            <DeleteIcon fontSize="inherit" color="error" />
          </Box>
        </SafeCenterAligner>
      </Paper>
      <Button
        className={classes.recovery}
        fullWidth
        textTransform="none"
        variant="contained"
        color="secondary"
        onClick={() => {
          setShowDeleteProfileModal(true)
        }}
      >
        {t('Delete wallet')}
      </Button>

      {showDeleteProfileModal && (
        <DeleteProfileModal
          onClose={() => {
            setShowDeleteProfileModal(false)
          }}
        />
      )}
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  recovery: {
    marginTop: theme.spacing(2),
  },
  deleteProfileInfo: {
    background: theme.palette.action.hover,
    height: 150,
    fontSize: 60,
  },
  iconWrapper: {
    color: theme.palette.action.active,
  },
}))
