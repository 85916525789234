import type {TypographyProps} from '@mui/material'
import {Box, Typography} from '@mui/material'
import React from 'react'

import {getAbsoluteFavIconUrl} from 'src/utils/url'

import {OUTER_CONTENT_WIDTH} from '../constants'

const hostnamePrefixes = ['www', 'app']

export type OriginMessageProps = {
  message: React.ReactElement | string
  origin: string
  favIconUrl: string | null
  originFontVariant?: TypographyProps['variant']
  contentWidth?: number | string
}

export const getOriginAppName = (origin: string) => {
  const originHostname = new URL(origin).hostname
  return originHostname.replace(
    new RegExp(`(^((${hostnamePrefixes.join('|')}).)*)`, ''),
    '',
  )
}

export function OriginMessage({
  message,
  origin,
  favIconUrl,
  originFontVariant,
  contentWidth = OUTER_CONTENT_WIDTH,
}: OriginMessageProps) {
  const originAppName = getOriginAppName(origin)

  const _originFontVariant =
    originFontVariant || origin.length > 20 ? 'body1' : 'h5'

  return (
    <>
      {origin && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width={contentWidth}
          maxWidth="100%"
          mb={1}
          sx={{wordBreak: 'break-all'}}
        >
          {favIconUrl && (
            <Box display="flex" justifyItems="center" pr={2}>
              <img
                width={32}
                height={32}
                src={getAbsoluteFavIconUrl(origin, favIconUrl)}
              />
            </Box>
          )}
          <Typography variant={_originFontVariant}>
            {originAppName || origin}
          </Typography>
        </Box>
      )}
      <Typography
        variant="h6"
        display="flex"
        justifyContent="center"
        width={contentWidth}
        maxWidth="100%"
      >
        {message}
      </Typography>
    </>
  )
}
