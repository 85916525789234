import {Sentry} from '@nufi/frontend-common'
import BigNumber from 'bignumber.js'
import clsx from 'clsx'
import {useFormikContext, FieldArray} from 'formik'
import React from 'react'

import type {NftBlockchain, TokenBlockchain} from '../../../blockchainTypes'
import {
  TokenRowError,
  BatchQueryGuard,
  AssetAmountFieldLoader,
  useModalSharedStyles,
} from '../../../components'
import type {AccountInfo, TokenMetadata} from '../../../types'

import type {GetAssetFieldArgs} from './AssetAmountField'
import {NativeAssetAmountField, TokenAmountField} from './AssetAmountField'
import {useSendModalVM} from './sendMultipleAssetsModal/viewModelProvider'
import {schemaKeys} from './types'
import type {BaseSendSchema} from './types'
import {findTokenAmount, getAssetFieldName} from './utils'

type SendAssetsListProps = {
  blockchain: TokenBlockchain | NftBlockchain
  account: AccountInfo
  allowDeletion?: boolean
  getAssetFieldProps?: GetAssetFieldArgs
}

export function SendAssetsList<T extends BaseSendSchema>({
  blockchain,
  account,
  getAssetFieldProps,
}: SendAssetsListProps) {
  const {
    values,
    setFieldTouched,
    setFieldValue,
    handleBlur,
    errors: _errors,
    touched: _touched,
    handleChange,
  } = useFormikContext<T>()
  const vm = useSendModalVM()
  const nftsQuery = vm.useGetAllNfts(blockchain)
  const tokensMetadataQuery = vm.useGetTokensMetadata(blockchain)
  const accountTokensQuery = vm.useAccountTokens(blockchain, account)
  const classes = useModalSharedStyles()

  const errors = _errors as unknown as {
    assets: {amount: string}[]
  }
  const touched = _touched as unknown as {
    assets: {amount: boolean}[]
  }

  const onDeleteItem = (i: number) => {
    // set removed item as untouched, otherwise next selected item on this position will inherit touched state
    setFieldTouched(getAssetFieldName(i), false)
    setFieldValue(schemaKeys.assets, [
      ...values.assets.slice(0, i),
      ...values.assets.slice(i + 1),
    ])
  }

  const disableFieldDelete = values.assets.length < 2

  return (
    <BatchQueryGuard
      queries={{
        nftsInfo: {
          ...nftsQuery,
          data: nftsQuery.data,
        },
        accountTokens: {
          ...accountTokensQuery,
          data: accountTokensQuery.data,
        },
        tokensMetadata: {
          ...tokensMetadataQuery,
          data: tokensMetadataQuery.data,
        },
      }}
      ErrorElement={
        <TokenRowError
          onClose={(setOpen) => {
            // remove all included tokes when queries fail
            setFieldValue(schemaKeys.assets, [])
            setOpen(false)
          }}
        />
      }
      LoadingElement={<AssetAmountFieldLoader />}
    >
      {({tokensMetadata, nftsInfo, accountTokens}) => (
        <FieldArray
          name={schemaKeys.assets}
          render={() =>
            values.assets.map((asset, i) => {
              const className = clsx(
                values.assets.length > 1 && classes.commonBottomMargin,
              )
              const name = getAssetFieldName(i)
              const errorMessage =
                (touched.assets?.[i]?.amount && errors.assets?.[i]?.amount) ||
                undefined
              const onChange = (v: string) => handleChange(name)(v)
              const onBlur = handleBlur(name)

              if (asset.type === 'native') {
                return (
                  <NativeAssetAmountField
                    balance={account.balance}
                    className={className}
                    errorMessage={errorMessage}
                    onChange={onChange}
                    name={name}
                    onBlur={onBlur}
                    onDelete={
                      disableFieldDelete ? undefined : () => onDeleteItem(i)
                    }
                    blockchain={blockchain}
                    asset={asset}
                    getAssetFieldProps={getAssetFieldProps}
                  />
                )
              } else {
                const {tokenId} = asset
                const tokenMetadata = (tokensMetadata as TokenMetadata[]).find(
                  ({id}) => id === tokenId,
                )

                if (!tokenMetadata) {
                  return (
                    // show closeable error when token metadatas are not found
                    <TokenRowError
                      key={i}
                      onClose={(setOpen) => {
                        onDeleteItem(i)
                        setOpen(false)
                      }}
                    />
                  )
                }
                const nftInfo = nftsInfo?.allNfts[tokenId] || null

                if (nftInfo && nftInfo.id !== tokenMetadata.id) {
                  Sentry.captureMessage(
                    `Token ID mismatch: ${blockchain} NFT ID: (${nftInfo.id}) differs from Token ID: (${tokenMetadata.id}).`,
                  )
                }
                const balance =
                  findTokenAmount(accountTokens, tokenMetadata.id) ||
                  new BigNumber(0)

                return (
                  <TokenAmountField
                    className={className}
                    errorMessage={errorMessage}
                    onChange={onChange}
                    name={name}
                    onBlur={onBlur}
                    onDelete={
                      disableFieldDelete ? undefined : () => onDeleteItem(i)
                    }
                    blockchain={blockchain}
                    asset={asset}
                    getAssetFieldProps={getAssetFieldProps}
                    tokenMetadata={tokenMetadata}
                    nftInfo={nftInfo}
                    balance={balance}
                  />
                )
              }
            })
          }
        />
      )}
    </BatchQueryGuard>
  )
}
