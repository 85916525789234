import {Box, Tab, Tabs, Typography} from '@mui/material'
import React from 'react'

import {WithTooltip} from '../../../../../../components'
import {useTabsStyles} from '../../../../../../utils/layoutUtils'

import type {EvmImportTokenTab} from './schema'

export type TabData = ({
  name: EvmImportTokenTab
  label: string
  renderComponent: JSX.Element
  isDisabled: boolean
  disabledTooltipText?: string
} & (
  | {
      isDisabled: true
      disabledTooltipText: string
    }
  | {
      isDisabled: false
      disabledTooltipText?: never
    }
))[]

type ImportTabsContentProps = {
  tabs: TabData
  onTabChange: (event: unknown, value: EvmImportTokenTab) => void
  activeTab: EvmImportTokenTab
}

export const ImportTabsContent = ({
  tabs,
  onTabChange,
  activeTab,
}: ImportTabsContentProps) => {
  const {commonTabStyles} = useTabsStyles()

  return (
    <Box width="100%" display="flex" flexDirection="column" height="100%">
      <Tabs
        className={commonTabStyles}
        {...{value: activeTab, onChange: onTabChange}}
        indicatorColor="primary"
        textColor="primary"
        sx={{flexBasis: 'fit-content'}}
      >
        {tabs.map(({name, label, isDisabled, disabledTooltipText}) => {
          const renderTabComponent = () => (
            <Tab key={name} label={label} value={name} disabled={isDisabled} />
          )
          return isDisabled ? (
            <WithTooltip title={<Typography>{disabledTooltipText}</Typography>}>
              {renderTabComponent()}
            </WithTooltip>
          ) : (
            renderTabComponent()
          )
        })}
      </Tabs>
      <Box flexBasis="100%">
        {tabs.map(({name, renderComponent}) => (
          <Box
            key={name}
            sx={{
              width: '100%',
              display: name === activeTab ? 'flex' : 'none',
              flexDirection: 'column',
              height: '100%',
            }}
          >
            {renderComponent}
          </Box>
        ))}
      </Box>
    </Box>
  )
}
