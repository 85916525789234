import {Edit} from '@mui/icons-material'
import type {
  ListItemButtonProps,
  ListItemTextProps,
  TextFieldProps,
} from '@mui/material'
import {
  List,
  ListItemButton,
  ListItemIcon,
  Checkbox,
  ListItemText,
  ListItem,
  styled,
  Typography,
  InputAdornment,
} from '@mui/material'
import type BigNumber from 'bignumber.js'
import React from 'react'

import {
  TwoLabelTextField,
  FormattedAsset,
  ellipsizeString,
} from '../../../../components'
import type {Blockchain} from '../../../../types'
import {useEllipsisStyles} from '../../../../utils/layoutUtils'

import type {AddAccountFieldProps} from './types'

type AccountListItemProps = Pick<ListItemButtonProps, 'disabled' | 'selected'> &
  ItemContentProps &
  Partial<Pick<ListItemTextProps, 'primary' | 'secondary'>> & {
    checkBoxProps?: {
      displayCheckbox: boolean
      checked?: boolean
    }
    button?: boolean
    onClick?: React.MouseEventHandler<HTMLDivElement | HTMLLIElement>
  }

export const AccountListItem = ({
  button,
  disabled,
  selected,
  onClick,
  ...rest
}: AccountListItemProps) => {
  const commonItemProps = {
    component: 'li',
    divider: true,
    disabled,
    selected,
    onClick,
    'data-test-id': `account-list-item-${disabled ? 'disabled' : 'enabled'}`,
  }

  return button ? (
    <ListItemButton {...commonItemProps}>
      <AccountListItemContent {...rest} />
    </ListItemButton>
  ) : (
    <ListItem {...commonItemProps}>
      <AccountListItemContent {...rest} />
    </ListItem>
  )
}

type AccountListItemContentProps = Omit<
  AccountListItemProps,
  'button' | 'onClick' | 'selected' | 'disabled'
>

const AccountListItemContent = ({
  checkBoxProps,
  primary,
  secondary,
  ...rest
}: AccountListItemContentProps) => (
  <>
    {checkBoxProps?.displayCheckbox && (
      <ListItemIcon>
        <Checkbox
          edge="start"
          checked={checkBoxProps?.checked}
          tabIndex={-1}
          disableRipple
        />
      </ListItemIcon>
    )}
    <ListItemText
      disableTypography
      primary={primary || <ItemContent {...rest} />}
      secondary={
        secondary ||
        (primary ? <ItemContent {...rest} withSecondContent /> : undefined)
      }
    />
  </>
)

type ItemContentProps = {
  blockchain: Blockchain
  address: string
  name?: string
  balance?: BigNumber
} & ItemContentWrapperProps

const ItemContent = ({
  blockchain,
  address,
  balance,
  name,
  ...rest
}: ItemContentProps) => {
  const classes = useEllipsisStyles()
  return (
    <ItemContentWrapper {...rest}>
      <span className={classes.ellipsis}>
        {ellipsizeString(name || address, 8, 3)}
        {name && (
          <Typography variant="caption" component="span">{` (${ellipsizeString(
            address,
            8,
            3,
          )})`}</Typography>
        )}
      </span>
      <span className={classes.ellipsis}>
        <FormattedAsset
          blockchain={blockchain}
          amount={balance}
          includeAssetSymbol
          isSensitiveInformation
        />
      </span>
    </ItemContentWrapper>
  )
}

type AddAccountNameFieldProps = AddAccountFieldProps &
  Pick<TextFieldProps, 'value'> & {
    address: string
    balance: BigNumber
    blockchain: Blockchain
  }

export const AddAccountNameField = ({
  address,
  balance,
  blockchain,
  ...props
}: AddAccountNameFieldProps) => {
  return (
    <TwoLabelTextField
      leftLegend={ellipsizeString(address, 10, 5)}
      rightLegend={
        <FormattedAsset
          blockchain={blockchain}
          amount={balance}
          includeAssetSymbol
          isSensitiveInformation
        />
      }
      fullWidth
      inputProps={{
        'data-test-id': 'choose-account-name',
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Edit color={props.error ? 'error' : 'primary'} fontSize="small" />
          </InputAdornment>
        ),
      }}
      {...props}
    />
  )
}

export const AccountList = styled(List)(
  ({theme: {palette, spacing, shadows, shape}}) => ({
    marginTop: spacing(2),
    background: palette.background.paper,
    height: 600,
    maxHeight: '50vh',
    overflow: 'auto',
    boxShadow: shadows[2],
    borderRadius: shape.borderRadius,
  }),
)

type ItemContentWrapperProps = {
  withSecondContent?: boolean
}

export const ItemContentWrapper = styled('div')<ItemContentWrapperProps>(
  ({theme: {spacing, typography, palette}, withSecondContent}) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '& > *': {
      width: '50%',
      '&:last-child': {
        textAlign: 'right',
      },
    },
    gap: spacing(1),
    ...typography.body1,
    ...(withSecondContent && {
      paddingTop: spacing(1),
      ...typography.body2,
      color: palette.text.secondary,
    }),
    lineHeight: 1,
  }),
)

export const AccountsListLoading = styled('div')(({theme}) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.grey[400],
}))
