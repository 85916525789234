import type {FilterOptionsState} from '@mui/material'
import {Typography, FormControl, Box, Paper} from '@mui/material'
import Fuse from 'fuse.js'
import React from 'react'

import {WithWeakTokenMetadata} from 'src/wallet/public/ui'

import {FUSE_TOKEN_SEARCHING_THRESHOLD} from '../constants'
import type {Blockchain, TokenMetadata} from '../types'

import {AssetIcon} from './AssetIcon'
import SearchableSelect from './visual/molecules/SearchableSelect'

type Option = {
  id: string
  blockchain: Blockchain
  name: TokenMetadata['name']
  ticker?: TokenMetadata['ticker']
  tokenId?: TokenMetadata['id']
}

export const getTokenOptionLabel = <T extends Option>(option: T) => {
  const name = option.name
  const _ticker = option.ticker ? `(${option.ticker.toUpperCase()})` : undefined
  const ticker =
    // some names already include ticker in (). Simple check to prevent rendering of the ticker twice
    _ticker && !name?.toUpperCase().includes(_ticker) ? _ticker : ''
  return `${name} ${ticker}`
}

type TokenSelectFieldProps<T extends Option> = {
  label: string
  isLoading: boolean
  options: T[]
  value: T | null
  onChange: (value: T | null) => void
  error: boolean
  helperText?: string | false
  noOptionsText?: string
  /**
   * Careful! This isn't type-safe as there may be fields in T that are missing here.
   * It only works if every value of type `Option & {[tokenFieldName]: string}` is assignable to T,
   * meaning T effectively has at most one required field more than `Option`.
   */
  customFieldConfig?: {
    blockchain: T['blockchain']
    tokenFieldName: string
    extraOptionLabel: string
  }
  getOptionDisabled?: (option: T) => boolean
}

export function TokenSelectField<T extends Option>({
  label,
  isLoading,
  options,
  value,
  onChange,
  error,
  helperText,
  noOptionsText,
  customFieldConfig,
  getOptionDisabled,
}: TokenSelectFieldProps<T>) {
  const filter = getFuzzyPriorityAwareFilter()

  return (
    <FormControl variant="outlined" fullWidth>
      <SearchableSelect<T>
        {...{
          label,
          isLoading,
          noOptionsText,
          options,
          value,
          onChange,
          error,
          helperText,
          getOptionLabel: getTokenOptionLabel,
          getOptionDisabled,
        }}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        PaperComponent={({children}) => (
          <Paper sx={{bgcolor: 'background.default'}}>{children}</Paper>
        )}
        renderStartAdornment={(option) =>
          option && (
            <Box ml={1} mr={1} display="flex">
              <WithWeakTokenMetadata
                blockchain={option.blockchain}
                tokenId={option.tokenId}
              >
                {(tokenMetadata) => (
                  <AssetIcon
                    blockchain={option.blockchain}
                    tokenMetadata={tokenMetadata}
                  />
                )}
              </WithWeakTokenMetadata>
            </Box>
          )
        }
        filterOptions={(options, params) => {
          const filtered = filter(options, params)
          if (customFieldConfig) {
            const {inputValue} = params
            // Suggest the creation of a new option for the custom tokens
            const isExisting = options.some(
              (option) => inputValue === option.id,
            )
            if (inputValue !== '' && !isExisting && filtered.length === 0) {
              filtered.push({
                blockchain: customFieldConfig.blockchain,
                name: `${customFieldConfig.extraOptionLabel} "${inputValue}"`,
                [customFieldConfig.tokenFieldName]: inputValue,
                id: inputValue,
                /**
                 * Be careful when using {@link TokenSelectFieldProps.customFieldConfig}.
                 * `T` could possibly have more fields that aren't accounted for here!
                 */
              } as unknown as T)
            }
          }

          return filtered
        }}
        searchable
        renderRow={(option) => (
          <Box display="flex" alignItems="center">
            <WithWeakTokenMetadata
              blockchain={option.blockchain}
              tokenId={option.tokenId}
            >
              {(tokenMetadata) => (
                <AssetIcon
                  blockchain={option.blockchain}
                  tokenMetadata={tokenMetadata}
                />
              )}
            </WithWeakTokenMetadata>
            <Box ml={2}>
              <Typography>{getTokenOptionLabel(option)}</Typography>
            </Box>
          </Box>
        )}
      />
    </FormControl>
  )
}

const getFuzzyPriorityAwareFilter =
  () =>
  <T extends Option>(options: T[], params: FilterOptionsState<T>) => {
    const fuse = new Fuse(options, {
      keys: [
        {name: 'ticker', getFn: (option) => option.ticker || '', weight: 0.6},
        {name: 'name', getFn: (option) => option.name || '', weight: 0.3},
        {name: 'tokenId', getFn: (option) => option.tokenId || '', weight: 0.1},
      ],
      threshold: FUSE_TOKEN_SEARCHING_THRESHOLD,
      useExtendedSearch: true,
    })

    const searchText = params.inputValue

    if (!searchText) return options

    return fuse
      .search({
        // OR needed to specify "include" match for tokenId
        $or: [
          {ticker: searchText},
          {name: searchText},
          // https://fusejs.io/examples.html#extended-search
          // No fuzziness to avoid typos in the blockchain address / token id
          // but provide "contains" functionality to still work for our id representation
          // e.g. flow's joined 'contractAddress-contractName'
          {tokenId: `'${searchText}`},
        ],
      })
      .map(({item}) => item)
  }
