import {Link as MuiLink, Typography, Box} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, {useState, useEffect} from 'react'
import {Trans, useTranslation} from 'react-i18next'

import type {StakingBlockchain} from '../blockchainTypes'
import {externalLinks} from '../constants'
import {colors} from '../theme/palette'
import type {Blockchain} from '../types'

export function HelpSectionLink() {
  const {t} = useTranslation()

  return (
    <Typography
      component="p"
      variant="body2"
      color="textSecondary"
      textAlign="center"
    >
      <Trans
        i18nKey="help_link"
        t={t}
        components={{
          HelpLink: (
            <MuiLink target="_blank" href={externalLinks.nufiHelpLink} />
          ),
          ContactLink: (
            <MuiLink target="_blank" href={externalLinks.nufiContactLink} />
          ),
        }}
      />
    </Typography>
  )
}

export function TermsAndConditionsLink() {
  const {t} = useTranslation()
  const classes = useTermsAndConditionsStyles()

  return (
    <Typography
      component="p"
      className={classes.text}
      variant="caption"
      color="textSecondary"
      align="left"
    >
      <Trans
        i18nKey="terms_conditions_link"
        t={t}
        components={{
          TermsLink: (
            <MuiLink
              href={externalLinks.termsAndConditionsPDF}
              target="_blank"
              rel="noopener"
            />
          ),
          PrivacyLink: (
            <MuiLink
              href={externalLinks.privacyAndCookiesPDF}
              target="_blank"
              rel="noopener"
            />
          ),
        }}
      />
    </Typography>
  )
}

export function HelpDeskLink() {
  const {t} = useTranslation()
  return (
    <Typography
      component="p"
      variant="body2"
      color="textSecondary"
      align="left"
    >
      <Trans
        i18nKey="help_desk_link"
        t={t}
        components={{
          KnowledgeBaseLink: (
            <MuiLink target="_blank" href={externalLinks.nufiHelpLink} />
          ),
          ContactSupportLink: (
            <MuiLink target="_blank" href={externalLinks.nufiContactLink} />
          ),
        }}
      />
    </Typography>
  )
}

export function ConversionRatesProvider() {
  const {t} = useTranslation()
  return (
    <Typography
      component="p"
      variant="caption"
      color="textSecondary"
      align="left"
    >
      <Trans
        i18nKey="conversion_rates_provider"
        t={t}
        components={{
          ProviderLink: (
            <MuiLink
              target="_blank"
              href={externalLinks.conversionRatesProvider}
            />
          ),
        }}
      />
    </Typography>
  )
}

export function GiveFeedbackLink() {
  const {t} = useTranslation()
  return (
    <Typography
      component="p"
      variant="caption"
      color="textSecondary"
      align="left"
    >
      <Trans
        i18nKey="give_feedback_link"
        t={t}
        components={{
          ProviderLink: (
            <MuiLink target="_blank" href={externalLinks.giveFeedback} />
          ),
        }}
      />
    </Typography>
  )
}

export function StillPendingLink({
  timeout = 10000,
  blockchain,
  isDappConnectorWindow = false,
}: {
  timeout?: number
  blockchain: Blockchain
  isDappConnectorWindow?: boolean
}) {
  const [show, setShow] = useState(false)
  const {t} = useTranslation()

  useEffect(() => {
    const timeId = setTimeout(() => {
      setShow(true)
    }, timeout)

    return () => {
      clearTimeout(timeId)
    }
  }, [])

  return (
    <>
      {show && (
        <>
          {blockchain === 'cardano' && (
            <Typography
              component="p"
              variant="caption"
              color="textPrimary"
              align="center"
            >
              <Trans
                i18nKey="still_pending_link"
                t={t}
                components={{
                  Link: (
                    <MuiLink
                      target="_blank"
                      href={externalLinks.cardanoCongestionIssues}
                    />
                  ),
                }}
              />
            </Typography>
          )}
          {!isDappConnectorWindow && (
            <Box mt={1}>
              <Typography
                component="p"
                variant="caption"
                color="textPrimary"
                align="center"
              >
                {t('still_pending_close_text')}
              </Typography>
            </Box>
          )}
        </>
      )}
    </>
  )
}

export function CardanoCongestionIssues() {
  const {t} = useTranslation()
  return (
    <Typography
      component="p"
      variant="caption"
      sx={{color: colors.warning.alertContent}}
      align="left"
    >
      {' '}
      {/* TODO: add color to MUI5 Theme */}
      <Trans
        i18nKey="cardano_congestion_issues"
        t={t}
        components={{
          Link: (
            <MuiLink
              color="primary"
              target="_blank"
              href={externalLinks.cardanoCongestionIssues}
            />
          ),
        }}
      />
    </Typography>
  )
}

export function IncognitoBannerLink() {
  const {t} = useTranslation()
  return (
    <Typography
      component="p"
      lineHeight={1}
      variant="caption"
      color="inherit"
      align="left"
    >
      {' '}
      {/* TODO: add color to MUI5 Theme */}
      <Trans
        i18nKey="incognito_help_link"
        t={t}
        components={{
          Link: (
            <MuiLink
              color="primary"
              target="_blank"
              href={externalLinks.incognitoHelpLink}
            />
          ),
        }}
      />
    </Typography>
  )
}

export const useTermsAndConditionsStyles = makeStyles(() => ({
  text: {
    letterSpacing: 0.4,
  },
}))

export function StakingEpochInfo({
  blockchain,
}: {
  blockchain: StakingBlockchain
}) {
  const {t} = useTranslation()

  const href = {
    cardano: externalLinks.cardanoEpochInfo,
    solana: externalLinks.solanaEpochInfo,
    flow: externalLinks.flowEpochInfo,
  }[blockchain]

  return (
    <Typography component="p" variant="caption" align="left">
      <Trans
        i18nKey="epoch_info"
        t={t}
        tOptions={{context: blockchain, contextType: 'stakingBlockchain'}}
        components={{
          Link: <MuiLink target="_blank" href={href} />,
        }}
      />
    </Typography>
  )
}
