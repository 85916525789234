import {Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {Alert} from 'src/components'

export const ManageBlockchainsError = ({
  error,
}: {
  error: NonNullable<unknown>
}) => {
  const {t} = useTranslation()
  return (
    <Alert severity="error">
      <Typography>
        {t(
          'Unexpected error. Please try again, try reloading the app or contact support.',
        )}
      </Typography>
      {typeof error === 'object' && 'message' in error && (
        <Typography>{error.message as string}</Typography>
      )}
    </Alert>
  )
}
