import {
  MilkomedaC1BlockchainApiImpl,
  constructMilkomedaC1Connection,
  createMilkomedaC1NetworkConfig,
} from '@nufi/wallet-evm'

import config from 'src/config'

import {createEvmManagers} from '../../createEvmManagers'

export const milkomedaC1 = createEvmManagers({
  blockchain: 'milkomedaC1',
  network: config.milkomedaC1Network,
  apiUrl: config.milkomedaC1RpcUrl,
  createNetworkConfig: createMilkomedaC1NetworkConfig,
  createBlockchainApi: (networkConfig) => {
    const connection = constructMilkomedaC1Connection(
      networkConfig.rpcNodeUrl,
      networkConfig.chainId,
      networkConfig.blockExplorerUrl,
    )
    return new MilkomedaC1BlockchainApiImpl(
      connection,
      networkConfig.chainId,
      networkConfig.balanceCheckerContractAddress,
    )
  },
})
