import {KeyboardArrowDown as ArrowDownIcon} from '@mui/icons-material'
import {Paper, Divider, Box, Typography, styled} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import React from 'react'

import {useCryptoProviderTypeNames} from '../utils/translations'
import type {AccountInfo} from '../wallet'
import {isHwVendor} from '../wallet'

import {AssetIcon} from './AssetIcon'
import {useModalSharedStyles, createResponsiveClasses} from './visual/Modal'

type AccountTransitionCardContentProps = {
  accountInfo: AccountInfo
}

export const AccountTransitionCardContent = ({
  accountInfo,
}: AccountTransitionCardContentProps) => {
  const classes = useStyles()
  const cryptoProviderTypeNames = useCryptoProviderTypeNames()
  const accountTypeLabel =
    cryptoProviderTypeNames[accountInfo.cryptoProviderType]

  const _AssetIcon = (
    <AssetIcon blockchain={accountInfo.blockchain} exactSize={24} />
  )
  return (
    <>
      {_AssetIcon}
      <Typography className={classes.accountName} variant="body2">
        {accountInfo.name}
      </Typography>
      {isHwVendor(accountInfo.cryptoProviderType) && (
        <Typography
          className={classes.summaryRowDetail}
          variant="body2"
          color="textSecondary"
        >
          ({accountTypeLabel})
        </Typography>
      )}
    </>
  )
}

type StakeTransitionCardSummaryProps = {
  fromAccount: AccountInfo
  sourceContent?: React.ReactNode
  targetContent: string | React.ReactNode // to allow custom styling from outside
}

export function StakeTransitionCardSummary({
  fromAccount,
  targetContent,
  sourceContent,
}: StakeTransitionCardSummaryProps) {
  const classes = {...useStyles(), ...useModalSharedStyles()}

  return (
    <Paper className={classes.wrapper} elevation={0}>
      <Box display="flex" alignItems="center">
        {sourceContent || (
          <AccountTransitionCardContent accountInfo={fromAccount} />
        )}
      </Box>
      <Box className={classes.dividerWrapper}>
        <ArrowDownIcon />
        <Divider className={clsx(classes.dividerY, classes.cardDivider)} />
      </Box>
      <Box display="flex" alignItems="center">
        {targetContent}
      </Box>
    </Paper>
  )
}

type ValidatorTransitionCardContentProps = {
  children: React.ReactNode
  CustomIcon?: React.ReactNode
}

export const ValidatorTransitionCardContent = ({
  children,
  CustomIcon,
}: ValidatorTransitionCardContentProps) => {
  return (
    <>
      {CustomIcon}
      <SummaryRowDetail>{children}</SummaryRowDetail>
    </>
  )
}

const SummaryRowDetail = styled('div')(({theme}) => ({
  marginLeft: theme.spacing(1),
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  wordBreak: 'break-word',
}))

const useStyles = makeStyles((theme) => {
  const mainTextMarginLeft = theme.spacing(2)
  return {
    wrapper: {
      ...createResponsiveClasses(theme, [2, 'padding']),
      border: `1px solid ${theme.palette.divider}`,
      background: theme.palette.background.default,
    },
    accountName: {
      marginLeft: mainTextMarginLeft,
    },
    breakWord: {
      wordBreak: 'break-word',
    },
    summaryRowDetail: {
      marginLeft: theme.spacing(1),
    },
    dividerWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    cardDivider: {
      flex: 1,
      marginLeft: mainTextMarginLeft,
    },
  }
})
