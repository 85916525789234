import React from 'react'
import {useTranslation} from 'react-i18next'

import type {ConvertedBalanceResponse} from 'src/features/assets/domain/conversions'
import type {Currency} from 'src/features/conversionRates/domain'
import {createNumberFormatter} from 'src/utils/formatting'

import {ValueInCurrencyError} from '../visual'
import {AmountVisibilityWrapper} from '../visual/AmountVisibilityWrapper'

type FormattedCurrencyProps = {
  convertedBalance: ConvertedBalanceResponse
  currency: Currency
  includeCurrencySymbol?: boolean
  isSensitiveInformation: boolean
  shouldUseCompactNotation?: boolean
}

export const formatCurrency = (
  amount: number,
  currency: Currency,
  includeCurrencySymbol = true,
  shouldUseCompactNotation = false,
) => {
  const numberFormatter = createNumberFormatter({
    style: includeCurrencySymbol ? 'currency' : undefined,
    currency: includeCurrencySymbol ? currency : undefined,
    currencyDisplay: includeCurrencySymbol ? 'narrowSymbol' : undefined,
    ...(shouldUseCompactNotation
      ? {
          notation: 'compact',
          maximumFractionDigits: 2,
        }
      : {}),
  })
  return numberFormatter.format(amount)
}

export const FormattedCurrency = ({
  convertedBalance,
  currency,
  includeCurrencySymbol = true,
  isSensitiveInformation,
  shouldUseCompactNotation,
}: FormattedCurrencyProps) => {
  const {t} = useTranslation()
  return (
    <AmountVisibilityWrapper {...{isSensitiveInformation}}>
      <>
        {(() => {
          if (convertedBalance.type === 'rates-unknown') {
            return t('N/A')
          }
          if (convertedBalance.type === 'rates-not-loaded') {
            return <ValueInCurrencyError />
          }
          return formatCurrency(
            convertedBalance.balance,
            currency,
            includeCurrencySymbol,
            shouldUseCompactNotation,
          )
        })()}
      </>
    </AmountVisibilityWrapper>
  )
}
