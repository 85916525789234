import {differenceInSeconds} from 'date-fns'
import _ from 'lodash'

import type {LocalProfileId} from '../../appStorage'
import type {Blockchain} from '../../types'

import type {
  ActiveProfileState,
  KeyValueData,
  StoredEncryptedProfilesState,
  StoredProfilesState,
  TransactionState,
} from './types'

const shouldDropClearedTx = (now: Date, clearedAt: Date) =>
  differenceInSeconds(now, clearedAt) >= 60

type Dependencies = {
  getCurrentProfileId: () => LocalProfileId | null
  decryptProfileTxData: (data: string) => Promise<KeyValueData>
  encryptProfileTxData: (data: KeyValueData) => Promise<string>
}

const getEncryptedProfilesState = async (
  storageKey: string,
): Promise<StoredEncryptedProfilesState | null> => {
  const storedData = localStorage.getItem(storageKey)
  let parsedData: StoredEncryptedProfilesState | null

  if (storedData != null) {
    try {
      parsedData = JSON.parse(storedData)
    } catch (err) {
      // In case the data can not be parsed, there is something very
      // wrong, and we just remove them
      localStorage.removeItem(storageKey)
      return null
    }
  } else {
    return null
  }

  return parsedData
}

const decryptStoredData = async (
  parsedData: StoredEncryptedProfilesState,
  profileId: LocalProfileId,
  decryptProfileTxData: (data: string) => Promise<KeyValueData>,
): Promise<StoredProfilesState | null> => {
  let decryptedProfileData: ActiveProfileState['state'] | null
  try {
    decryptedProfileData = (await decryptProfileTxData(
      parsedData.state[profileId]!,
    )) as ActiveProfileState['state']
  } catch (err) {
    return null
  }

  const response: StoredProfilesState = {
    ...parsedData,
    state: {
      ...parsedData.state,
      [profileId]: decryptedProfileData,
    },
  }

  return response
}

const getPersistentTransactionState = async (
  deps: Dependencies,
  storageKey: string,
): Promise<TransactionState | null> => {
  const currentProfileId = deps.getCurrentProfileId()
  if (currentProfileId == null) return null

  const parsedData = await getEncryptedProfilesState(storageKey)
  if (!parsedData) return null

  const allProfilesState = await decryptStoredData(
    parsedData,
    currentProfileId,
    deps.decryptProfileTxData,
  )
  if (!allProfilesState) return null
  return allProfilesState.state[currentProfileId] as TransactionState
}

const shouldRehydrateInMemoryState = (
  inMemoryState: TransactionState,
  persistentState: TransactionState | null,
): boolean => {
  return JSON.stringify(persistentState) !== JSON.stringify(inMemoryState)
}

export const makeShouldRehydrateOnStorageEvent =
  (deps: Dependencies) =>
  async (
    storageKey: string,
    inMemoryState: TransactionState,
  ): Promise<boolean> => {
    const persistedState = await getPersistentTransactionState(deps, storageKey)
    return shouldRehydrateInMemoryState(inMemoryState, persistedState)
  }

// Note: (richard)
// There are few dangers that comes with "async" syncing of the "whole"
// reactive & persistent state.
// If 2 updates were fired in a small time frame there is a risk of a "lost update".
// TODO: revisit the above issue, as it may not be critical for our use-case, or at least good
// enough for some time being (could be addressed e.g. by symmetric encryption,
// or by maintaining a queue for store update operations)
// Also the store will not be properly loaded on first app `render` though this should not
// be a limit in the current architecture.

/**
 * Storage engine that is meant to be used with `zustand/persist: getStorage` property.
 * @returns object with `getCurrentProfileItem`, `setCurrentProfileItem` and `removeItem` keys. Where:
 * * `getCurrentProfileItem`: will read and decrypt the data for currently logged in profile
 * * `setCurrentProfileItem`: will encrypt and set the data for currently logged in profile
 * * `removeItem`: remove data across all profiles
 */
export const makeTxStorage = (deps: Dependencies) => ({
  getCurrentProfileItem: async (
    storageKey: string,
  ): Promise<ActiveProfileState | null> => {
    const parsedData = await getEncryptedProfilesState(storageKey)
    if (!parsedData) return null

    const profileId = deps.getCurrentProfileId()

    if (profileId == null) {
      // User not logged in yet
      return {
        version: parsedData.version,
        state: {transactions: []},
      }
    }

    const partiallyDecryptedData = await decryptStoredData(
      parsedData,
      profileId,
      deps.decryptProfileTxData,
    )
    if (!partiallyDecryptedData) return null

    const sanitizedTransactions = (
      partiallyDecryptedData.state[profileId] as ActiveProfileState['state']
    ).transactions.map((tx) => ({
      ...tx,
      // recreate `Date` object that is converted to `string` when
      // using JSON.stringify
      updatedAt: new Date(tx.updatedAt),

      // TODO: consider using `string` date instead
      createdAt: new Date(tx.createdAt),
      clearedAt: tx.clearedAt ? new Date(tx.clearedAt) : undefined,
    }))

    const singleProfileData = {
      ...(partiallyDecryptedData.state[
        profileId
      ] as ActiveProfileState['state']),
      transactions: sanitizedTransactions,
    }

    return {...parsedData, state: singleProfileData}
  },
  setCurrentProfileItem: async (
    storageKey: string,
    value: ActiveProfileState,
  ): Promise<void> => {
    const profileId = deps.getCurrentProfileId()

    if (profileId == null) {
      // Avoid updates to the stored data, if no profile was logged in yet,
      // e.g. after initial page load
      return
    }

    const parsedData = await getEncryptedProfilesState(storageKey)
    const updatedProfileValue = await deps.encryptProfileTxData(value.state)

    const updatedData = {
      version: value.version,
      state: {
        ...(parsedData?.state || {}),
        [profileId]: updatedProfileValue,
      },
    }
    localStorage.setItem(storageKey, JSON.stringify(updatedData))
  },
  removeItem: async (storageKey: string): Promise<void> => {
    localStorage.removeItem(storageKey)
  },
})

const topLevelLocalOnlyTxProperties = [
  'mutationId',
  'mutationKey',
  'retry',
] as const

// Called when putting `in-memory` state into storage
export const transformLocalToPersistent = (
  state: TransactionState,
  persistentBlockchains: Blockchain[],
): TransactionState => ({
  ...state,
  transactions: state.transactions
    .filter((tx) =>
      persistentBlockchains.includes(tx.context?.blockchain as Blockchain),
    )
    .filter((tx) => {
      const now = new Date()
      if (tx.clearedAt == null) return tx
      // Transactions are not cleared immediately (after user removes them),
      // so that when syncing
      // across windows (persistent / local), we can decide which transactions
      // from local should be removed, resp. which should be re-added if a race
      // condition occurred (e.g. new tx was added by connector recently).
      // The time of 1 minute should be more than generous for that.
      // Note: if we allow removing pending transactions they can re-appear
      // once their state changes! (within current session only), due to react-query
      // listener.
      return !shouldDropClearedTx(now, tx.clearedAt)
    })
    .map((tx) => _.omit(tx, topLevelLocalOnlyTxProperties)),
})

// Called when setting `in-memory` state during hydrate phase
export const mergePersistentWithLocal = (
  persistedState: unknown,
  currentState: TransactionState,
): TransactionState => ({
  ...currentState,
  // Merging of 'data' properties
  transactions: (() => {
    const result: TransactionState['transactions'] = (() => {
      // Persistent should be dropped on `VERSION` change, but we anyway
      // do some basic checks, and initially treat it as `unknown`
      if (
        typeof persistedState === 'object' &&
        persistedState != null &&
        'transactions' in persistedState
      )
        // [...] is important to ensure new reference
        return [...(persistedState as TransactionState).transactions]
      return []
    })()

    for (const tx of result) {
      const relatedInMemoryTx = currentState.transactions.find(
        (t) => t.customId === tx.customId,
      )
      if (relatedInMemoryTx) {
        for (const localProperty of topLevelLocalOnlyTxProperties) {
          // Keep properties that are not being stored into the storage
          // still in memory.
          // The reassignment is too "dynamic" for TS, but it forces
          // us to keep localOnly properties in sync with `transformLocalToPersistent`.
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          tx[localProperty] = relatedInMemoryTx[localProperty]
        }
      }
    }

    // This loop is here for 2 reasons:
    // 1. Keep tx for blockchains for which we do not yet support persistence in state
    // 2. Due to a paranoia of an edge case, when
    // user added another tx before event from another window arrived.
    // We do not handle "state updates" here, as even if we missed some
    // transition, we would notice it again due to polling for tx state updates.
    const now = new Date()
    for (const tx of currentState.transactions) {
      // Note that this condition works even for transactions removed by user
      // from another window, as they are not removed immediately, but are first assigned
      // `clearedAt` flag, so they would still be found in the `result` array
      // at least for some amount of time
      if (
        (tx.clearedAt == null || !shouldDropClearedTx(now, tx.clearedAt)) &&
        result.every((t) => tx.customId !== t.customId)
      ) {
        result.push(tx)
      }
    }

    return result
  })(),
})
