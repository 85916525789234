import {HelpOutline} from '@mui/icons-material'
import type {FlowAccountInfo} from '@nufi/wallet-flow'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {sumBalances, sumRewards, sumStakedBalances} from 'src/wallet/flow'

import {
  FormattedAsset,
  FormattedPercentage,
  LabeledIconWithTooltip,
  QueryGuard,
} from '../../../../components'
import {blockchainToCoin} from '../../../../constants'
import {useTotalStakedAPY} from '../../../../wallet'
import {
  StakeSummary,
  SummaryCard,
} from '../../common/overview/StakeOverviewUtils'

type StakeSummaryProps = {
  accounts: FlowAccountInfo[]
}

export default function FlowStakeSummary({accounts}: StakeSummaryProps) {
  const {t} = useTranslation()
  // TODO, after approval re-use functions from MyStakingCard

  const totalStakedAPYQuery = useTotalStakedAPY('flow')
  const rewardSum = sumRewards(accounts)
  const stakedSum = sumStakedBalances(accounts)
  const balanceSum = sumBalances(accounts)

  return (
    <StakeSummary>
      <SummaryCard
        label={t('Total Staked APY %')}
        value={
          <QueryGuard {...totalStakedAPYQuery}>
            {(APY) => <FormattedPercentage value={APY} />}
          </QueryGuard>
        }
      />
      <SummaryCard
        label={t('Available balance')}
        value={
          <FormattedAsset
            amount={balanceSum}
            blockchain="flow"
            isSensitiveInformation
          />
        }
      />
      <SummaryCard
        label={
          <LabeledIconWithTooltip
            Label={t('Total rewards')}
            Icon={<HelpOutline fontSize="small" />}
            title={`${blockchainToCoin('flow')} ${t(
              'rewards not yet claimed',
            )}`}
            spacing={0.5}
          />
        }
        value={
          <FormattedAsset
            amount={rewardSum}
            blockchain="flow"
            isSensitiveInformation
          />
        }
      />
      <SummaryCard
        label={t('Total staked')}
        value={
          <FormattedAsset
            amount={stakedSum}
            blockchain="flow"
            isSensitiveInformation
          />
        }
      />
    </StakeSummary>
  )
}
