import {Grid} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {
  Aligner,
  ListHeaderTitle,
  ListHeaderWrapper,
  ListLayoutWrapper,
  NoOverflowAligner,
} from 'src/components'

type OrderHistoryListItemLayoutProps = {
  createdAtCol: React.ReactNode
  fromCol: React.ReactNode
  toCol: React.ReactNode
  dexCol: React.ReactNode
  statusCol: React.ReactNode
  explorerLinkCol: React.ReactNode
  actionCol: React.ReactNode
}

export function OrderHistoryListItemLayout({
  createdAtCol,
  fromCol,
  toCol,
  dexCol,
  statusCol,
  explorerLinkCol,
  actionCol,
}: OrderHistoryListItemLayoutProps) {
  return (
    <ListLayoutWrapper>
      <Grid
        container
        spacing={1}
        sx={{alignItems: 'center', justifyContent: 'space-between'}}
      >
        <Grid item xs={2}>
          <NoOverflowAligner align="left">{createdAtCol}</NoOverflowAligner>
        </Grid>
        <Grid item xs={2}>
          <Aligner align="right">{fromCol}</Aligner>
        </Grid>
        <Grid item xs={2}>
          <Aligner align="right">{toCol}</Aligner>
        </Grid>
        <Grid item xs={1}>
          <Aligner align="right">{dexCol}</Aligner>
        </Grid>
        <Grid item xs={2}>
          <Aligner align="right">{statusCol}</Aligner>
        </Grid>
        <Grid item xs={2}>
          <Aligner align="right">{explorerLinkCol}</Aligner>
        </Grid>
        <Grid item xs={1}>
          <Aligner align="right">{actionCol}</Aligner>
        </Grid>
      </Grid>
    </ListLayoutWrapper>
  )
}

export function OrderHistoryListItemHeader() {
  const {t} = useTranslation()

  return (
    <ListHeaderWrapper>
      <OrderHistoryListItemLayout
        createdAtCol={<ListHeaderTitle>{t('Created at')}</ListHeaderTitle>}
        fromCol={<ListHeaderTitle>{t('From')}</ListHeaderTitle>}
        toCol={<ListHeaderTitle>{t('To')}</ListHeaderTitle>}
        statusCol={<ListHeaderTitle>{t('Status')}</ListHeaderTitle>}
        dexCol={<ListHeaderTitle>{t('DEX')}</ListHeaderTitle>}
        explorerLinkCol={<ListHeaderTitle>{''}</ListHeaderTitle>}
        actionCol={<ListHeaderTitle>{t('Actions')}</ListHeaderTitle>}
      />
    </ListHeaderWrapper>
  )
}
