import {WarningAmber as WarningIcon} from '@mui/icons-material'
import {Box, Tooltip} from '@mui/material'
import type {FlowStakeAccountId} from '@nufi/wallet-flow'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {Route, Switch} from 'react-router-dom'

import {
  LabeledIconWithTooltip,
  LoadingWithLabel,
  Modal,
  QueryGuard,
} from '../../../components'
import {routeTo} from '../../../router'
import {useStakeRouteOptions} from '../../../router/staking'
import {useGetCurrentEpochInfo} from '../../../wallet/flow'
import {
  EpochInfoError,
  EpochInfoLoading,
  EpochInfoText,
} from '../common/overview/StakeOverviewUtils'
import {CurrentEpochInfoText} from '../common/utils'
import type {StakeActionsRoutesProps} from '../StakeDetail'
import StakeDetail from '../StakeDetail'

import GenericUpdateDelegatorModal from './actionModals/genericUpdateDelegator'
import FlowRegisterDelegatorModal from './actionModals/registerDelegator'
import FlowSetupStakingCollectionModal from './actionModals/setupStakingCollection'
import StakeAccountsOverview from './overview/StakeAccountsOverview'
import {useMappedEpochPhases} from './utils'

function StakeAccountActionsRoutes({onModalClose}: StakeActionsRoutesProps) {
  const {accountId, stakeAccountId: _stakeAccountId} = useStakeRouteOptions()
  const stakeAccountId = _stakeAccountId as unknown as FlowStakeAccountId
  return (
    <>
      <Route
        exact
        path={
          routeTo.staking.myStaking.flow
            .account(':accountId')
            .stakeAccount(':stakeAccountId').stakeNewTokens
        }
      >
        <Modal onClose={onModalClose} variant="left">
          <GenericUpdateDelegatorModal
            onClose={onModalClose}
            txType="stakeNewTokens"
            stakeAccountId={stakeAccountId}
            accountId={accountId}
          />
        </Modal>
      </Route>
      <Route
        exact
        path={
          routeTo.staking.myStaking.flow
            .account(':accountId')
            .stakeAccount(':stakeAccountId').withdrawRewards
        }
      >
        <Modal onClose={onModalClose} variant="left">
          <GenericUpdateDelegatorModal
            onClose={onModalClose}
            txType="withdrawRewards"
            stakeAccountId={stakeAccountId}
            accountId={accountId}
          />
        </Modal>
      </Route>
      <Route
        exact
        path={
          routeTo.staking.myStaking.flow
            .account(':accountId')
            .stakeAccount(':stakeAccountId').requestUnstaking
        }
      >
        <Modal onClose={onModalClose} variant="left">
          <GenericUpdateDelegatorModal
            onClose={onModalClose}
            txType="requestUnstaking"
            stakeAccountId={stakeAccountId}
            accountId={accountId}
          />
        </Modal>
      </Route>
      <Route
        exact
        path={
          routeTo.staking.myStaking.flow
            .account(':accountId')
            .stakeAccount(':stakeAccountId').restakeRewarded
        }
      >
        <Modal onClose={onModalClose} variant="left">
          <GenericUpdateDelegatorModal
            onClose={onModalClose}
            txType="restakeRewarded"
            stakeAccountId={stakeAccountId}
            accountId={accountId}
          />
        </Modal>
      </Route>
      <Route
        exact
        path={
          routeTo.staking.myStaking.flow
            .account(':accountId')
            .stakeAccount(':stakeAccountId').restakeUnstaked
        }
      >
        <Modal onClose={onModalClose} variant="left">
          <GenericUpdateDelegatorModal
            onClose={onModalClose}
            txType="restakeUnstaked"
            stakeAccountId={stakeAccountId}
            accountId={accountId}
          />
        </Modal>
      </Route>
      <Route
        exact
        path={
          routeTo.staking.myStaking.flow
            .account(':accountId')
            .stakeAccount(':stakeAccountId').withdrawUnstaked
        }
      >
        <Modal onClose={onModalClose} variant="left">
          <GenericUpdateDelegatorModal
            onClose={onModalClose}
            txType="withdrawUnstaked"
            stakeAccountId={stakeAccountId}
            accountId={accountId}
          />
        </Modal>
      </Route>
    </>
  )
}

function AccountActionsRoutes({onModalClose}: StakeActionsRoutesProps) {
  const {accountId} = useStakeRouteOptions()
  return (
    <Switch>
      <Route
        exact
        path={
          routeTo.staking.myStaking.flow.account(':accountId').registerDelegator
        }
      >
        <Modal onClose={onModalClose} variant="left">
          <FlowRegisterDelegatorModal
            onClose={onModalClose}
            accountId={accountId}
          />
        </Modal>
      </Route>
      <Route
        exact
        path={routeTo.staking.myStaking.flow.account(':accountId').setupStaking}
      >
        <Modal onClose={onModalClose} variant="left">
          <FlowSetupStakingCollectionModal
            onClose={onModalClose}
            accountId={accountId}
          />
        </Modal>
      </Route>
      <Route
        path={
          routeTo.staking.myStaking.flow
            .account(':accountId')
            .stakeAccount(':stakeAccountId').index
        }
      >
        <Modal onClose={onModalClose} variant="left">
          <StakeAccountActionsRoutes onModalClose={onModalClose} />
        </Modal>
      </Route>
    </Switch>
  )
}

export const EpochInfo = () => {
  const epochInfoQuery = useGetCurrentEpochInfo()
  const {t} = useTranslation()
  const mappedEpochPhases = useMappedEpochPhases()
  return (
    <QueryGuard
      {...epochInfoQuery}
      ErrorElement={<EpochInfoError />}
      LoadingElement={<EpochInfoLoading />}
    >
      {(epochInfo) => {
        const {
          counter,
          startView,
          endView,
          currentView,
          epochPhase,
          epochEndTimeEstimate,
        } = epochInfo
        const viewsInEpoch = endView - startView
        const viewIndex = currentView - startView
        const epochProgress = 100 * (viewIndex / viewsInEpoch)
        return (
          <Box sx={{display: 'flex', alignItems: 'center'}}>
            <CurrentEpochInfoText value={counter} />
            <EpochInfoText
              sx={{display: 'flex', alignItems: 'center'}}
              label={t('Epoch progress')}
              value={
                <LoadingWithLabel
                  sx={{ml: 1}}
                  progress={epochProgress}
                  content={
                    <Tooltip
                      title={
                        <span>
                          {t('Epoch progress')}:<br />
                          {t('epoch_progress_views_completed', {viewIndex})}
                          <br />
                          {t('views remaining', {
                            remainingViews: viewsInEpoch - viewIndex,
                          })}
                        </span>
                      }
                      placement="top-start"
                    >
                      <div>{`${Math.floor(epochProgress)}%`}</div>
                    </Tooltip>
                  }
                />
              }
            />
            <EpochInfoText
              label={t('Estimated epoch end')}
              date={epochEndTimeEstimate}
            />
            <EpochInfoText
              label={t('Epoch phase')}
              value={mappedEpochPhases[epochPhase]}
            />
            {epochPhase !== 'staking-auction' && (
              <LabeledIconWithTooltip
                Label={null}
                iconPosition="end"
                Icon={
                  <WarningIcon
                    color="warning"
                    rtl-data-test-id={'flow_epoch_alert'}
                  />
                }
                spacing={-1.5}
                title={t(
                  'Staking actions are temporarily disabled at the end of each epoch, during Epoch setup and Epoch commit',
                )}
              />
            )}
          </Box>
        )
      }}
    </QueryGuard>
  )
}

function FlowStakeDetail() {
  return (
    <StakeDetail
      blockchain={'flow'}
      StakeAccountsOverview={StakeAccountsOverview}
      AccountActionsRoutes={AccountActionsRoutes}
      EpochInfo={EpochInfo}
    />
  )
}

export default FlowStakeDetail
