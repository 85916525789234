import type {
  FlowStakeAccountId,
  FlowUpdateDelegatorTxType,
} from '@nufi/wallet-flow'
import React from 'react'

import {QueryGuard, StakingModalError} from '../../../../../components'
import type {AccountId} from '../../../../../types'
import {
  useGetAccounts,
  useGetIsStakingEnabled,
} from '../../../../../wallet/flow'
import {ensureAccountById} from '../../../../../wallet/utils/common'
import {WithActiveScreenState} from '../../../../transaction'
import {assertIsDelegating, parseFlowStakeAccountId} from '../../utils'
import {StakingDisabledModalError} from '../utils'

import {FlowUpdateDelegatorForm} from './ModalForm'

export default function GenericUpdateDelegatorModal({
  onClose,
  stakeAccountId,
  accountId,
  txType,
}: {
  onClose: () => unknown
  stakeAccountId: FlowStakeAccountId
  accountId: AccountId
  txType: FlowUpdateDelegatorTxType
}) {
  const accountsQuery = useGetAccounts()
  const isStakingEnabledQuery = useGetIsStakingEnabled()
  return (
    <WithActiveScreenState>
      <QueryGuard
        {...isStakingEnabledQuery}
        ErrorElement={<StakingDisabledModalError />}
        loadingVariant="centered"
      >
        {(isStakingEnabled) => {
          if (
            !isStakingEnabled &&
            txType !== 'withdrawRewards' && // withdrawing is not considered a staking actions
            txType !== 'withdrawUnstaked'
          ) {
            return <StakingDisabledModalError />
          }
          return (
            <QueryGuard
              {...accountsQuery}
              ErrorElement={<StakingModalError blockchain="flow" />}
              loadingVariant="centered"
            >
              {(accounts) => {
                const {validatorId, delegatorId} =
                  parseFlowStakeAccountId(stakeAccountId)
                const accountInfo = ensureAccountById(accounts, accountId)
                const delegation = assertIsDelegating(
                  accountInfo.stakingInfo,
                  validatorId,
                  delegatorId,
                )
                return (
                  <FlowUpdateDelegatorForm
                    {...{onClose, accountInfo, txType, delegation, accountId}}
                  />
                )
              }}
            </QueryGuard>
          )
        }}
      </QueryGuard>
    </WithActiveScreenState>
  )
}
