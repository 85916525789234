import {FileCopy as ContentCopy} from '@mui/icons-material'
import type {IconProps, IconButtonProps, SxProps, Theme} from '@mui/material'
import {Box, Tooltip, IconButton, Link} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React, {useState} from 'react'
import {CopyToClipboard as ReactCopyToClipboard} from 'react-copy-to-clipboard'
import {useTranslation} from 'react-i18next'

import {LabeledIcon} from './visual/molecules/LabeledIcon'

type CopyToClipboardProps = {
  value: string
  copyText?: string
  breakCopyText?: boolean
  iconFontSize?: IconProps['fontSize']
  stopPropagation?: boolean
  ariaLabel?: string
} & (
  | {
      variant?: 'custom'
      hoverableBackgroundFontSize?: IconButtonProps['size']
      buttonSx?: SxProps<Theme>
      Label?: React.ReactNode
      content?: React.ReactNode
    }
  | {
      variant: 'text'
      label: string
    }
)

/*
The timeouts magic is here for better UX.
This is placeholder component that we can share among screens, exact details,
library for copy-to-clipboard and UX is subject to change.
*/

export function CopyToClipboard({
  value,
  copyText,
  iconFontSize,
  breakCopyText,
  stopPropagation,
  ariaLabel,
  ...restProps
}: CopyToClipboardProps) {
  const {t} = useTranslation()
  const classes = useStyles()

  const defaultText: string = copyText || t('Copy to clipboard')
  const [tooltipText, setTooltipText] = useState<string | null>(defaultText)

  const onLeave = () => {
    setTimeout(() => {
      setTooltipText(null)
      setTimeout(() => {
        setTooltipText(defaultText)
      }, 100)
    }, 100)
  }

  const onCopy = () => {
    setTooltipText(null)
    setTimeout(() => {
      setTooltipText(t('Copied!'))
    }, 100)
  }

  restProps.variant = restProps.variant || 'custom'

  if (restProps.variant === 'custom') {
    const content = (
      <IconButton
        size={restProps.hoverableBackgroundFontSize || 'large'}
        sx={restProps.buttonSx}
        aria-label={ariaLabel}
      >
        <ContentCopy fontSize={iconFontSize} />
      </IconButton>
    )

    const CopyButton = (
      <ReactCopyToClipboard text={value} onCopy={onCopy}>
        <Box
          onMouseLeave={onLeave}
          display="inline"
          onClick={stopPropagation ? (e) => e.stopPropagation() : undefined}
        >
          {tooltipText != null ? (
            <Tooltip
              title={tooltipText}
              classes={
                breakCopyText ? {tooltip: classes.tooltipNoWordBreak} : {}
              }
            >
              <Box sx={{display: 'inline-flex'}}>
                {restProps.content || content}
              </Box>
            </Tooltip>
          ) : (
            <Box sx={{display: 'inline-flex'}}>
              {restProps.content || content}
            </Box>
          )}
        </Box>
      </ReactCopyToClipboard>
    )

    return (
      <>
        {restProps.Label ? (
          <LabeledIcon
            Label={restProps.Label}
            Icon={CopyButton}
            justifyContent="center"
          />
        ) : (
          CopyButton
        )}
      </>
    )
  } else if (restProps.variant === 'text') {
    return (
      <ReactCopyToClipboard text={value} onCopy={onCopy}>
        <Box onMouseLeave={onLeave} display="inline">
          <Tooltip
            title={tooltipText || ''}
            classes={breakCopyText ? {tooltip: classes.tooltipNoWordBreak} : {}}
          >
            <Box>
              <Link
                component="button"
                variant="caption"
                onClick={(e) => {
                  e.preventDefault()
                }}
              >
                {restProps.label}
              </Link>
            </Box>
          </Tooltip>
        </Box>
      </ReactCopyToClipboard>
    )
  }
  return null
}

const useStyles = makeStyles(() => ({
  tooltipNoWordBreak: {
    wordBreak: 'break-all',
  },
}))
