import {startOfYear} from 'date-fns'
import {Formik} from 'formik'
import _ from 'lodash'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import * as yup from 'yup'

import type {CsvExportBlockchain} from '../../../../../blockchainTypes'
import {
  Modal,
  LoadAccountsModalError,
  InlineLoading,
  SafeCenterAligner,
  ExportHistoryError,
  MutationGuard,
} from '../../../../../components'
import type {AccountId} from '../../../../../wallet/types'

import {maxDateValue, minDateValue} from './constants'
import {ExportHistoryDetailScreen} from './DetailScreen'
import {ExportHistorySubmitScreen} from './SubmitScreen'
import type {ExportHistorySchema} from './types'
import {
  useCsvExportBlockchains,
  useSupportedCSVExportAccounts,
} from './utils/generic'

type ExportHistoryModalProps = {
  onClose: () => void
  id: AccountId
  blockchain: CsvExportBlockchain
}

export const ExportHistoryModal = ({
  onClose,
  id: initialAccountId,
  blockchain: initialAccountBlockchain,
}: ExportHistoryModalProps) => {
  const accountsQuery = useSupportedCSVExportAccounts()
  return (
    <Modal onClose={onClose} variant="left">
      {accountsQuery.isLoading ? (
        <SafeCenterAligner>
          <InlineLoading />
        </SafeCenterAligner>
      ) : accountsQuery.data ? (
        <ExportHistoryModalContent
          onClose={onClose}
          id={initialAccountId}
          blockchain={initialAccountBlockchain}
        />
      ) : (
        <LoadAccountsModalError />
      )}
    </Modal>
  )
}

function ExportHistoryModalContent({
  onClose,
  id: initialAccountId,
  blockchain: initialAccountBlockchain,
}: ExportHistoryModalProps) {
  const {t} = useTranslation()
  const blockchains = useCsvExportBlockchains()

  const accountsQuery = useSupportedCSVExportAccounts()

  const [loadingCSV, setLoadingCSV] = useState(false)
  const [CSVError, setCSVError] = useState(false)

  const errorKeys = _.chain(accountsQuery.errorKeys).compact().uniq().value()

  const schema = yup.object().shape({
    startDate: yup
      .date()
      .typeError(t('Invalid date.'))
      .max(yup.ref('endDate'), t("Start date can't be greater than End date."))
      .min(
        minDateValue,
        t('min_date_validation', {
          date: minDateValue.toLocaleDateString(),
          interpolation: {escapeValue: false},
        }),
      ),
    endDate: yup
      .date()
      .typeError(t('Invalid date.'))
      .max(maxDateValue, t("End date cannot be greater than today's date."))
      .min(
        minDateValue,
        t('min_date_validation', {
          date: minDateValue.toLocaleDateString(),
          interpolation: {escapeValue: false},
        }),
      ),
    blockchainToAccountIds: yup
      .object()
      .test(
        'export-history-min-account-selected-amount',
        t('Required field.'),
        (obj) => {
          const isNonEmpty: boolean =
            _(obj as unknown as Record<CsvExportBlockchain, AccountId[]>)
              .chain()
              .values()
              .flatten()
              .size()
              .value() > 0

          return isNonEmpty
        },
      ),
  })

  const initialValues: ExportHistorySchema = {
    blockchainToAccountIds: {
      ...blockchains.reduce(
        (record, blockchain) => ({...record, [blockchain]: []}),
        {},
      ),
      ...(errorKeys.includes(initialAccountBlockchain)
        ? {}
        : {[initialAccountBlockchain]: [initialAccountId]}),
    } as Record<CsvExportBlockchain, AccountId[]>,
    startDate: startOfYear(new Date()),
    endDate: new Date(),
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={() => setLoadingCSV(true)}
    >
      {(formikProps) => {
        return (
          <>
            <ExportHistoryDetailScreen
              {...{onClose, errorKeys}}
              accounts={accountsQuery.data}
            />
            <MutationGuard
              isPending={loadingCSV}
              error={CSVError}
              ErrorElement={<ExportHistoryError />}
              LoadingElement={
                <ExportHistorySubmitScreen
                  blockchainToAccountIds={
                    formikProps.values.blockchainToAccountIds
                  }
                  startDate={formikProps.values.startDate}
                  endDate={formikProps.values.endDate}
                  setLoadingCSV={setLoadingCSV}
                  setCSVError={setCSVError}
                  accounts={accountsQuery.data}
                />
              }
            />
          </>
        )
      }}
    </Formik>
  )
}
