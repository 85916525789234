import {ChevronRight as BreadcrumbDivider} from '@mui/icons-material'
import {Box} from '@mui/material'
import React from 'react'

import TextButton from '../atoms/TextButton'

type BreadcrumbsProps = {
  items: Array<React.ReactElement>
}

export function Breadcrumbs({items}: BreadcrumbsProps) {
  return (
    <Box display="flex" alignItems="center">
      {items.map((item, index, items) => (
        <React.Fragment key={index}>
          {item}
          {index < items.length - 1 && <BreadcrumbDivider fontSize="small" />}
        </React.Fragment>
      ))}
    </Box>
  )
}

type BreadcrumbLabelProps = {onClick: () => unknown; label: string}

export function BreadcrumbLabel({onClick, label}: BreadcrumbLabelProps) {
  return <TextButton color="textSecondary" {...{onClick, label}} />
}
