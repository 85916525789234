/* eslint-disable @typescript-eslint/explicit-function-return-type */

import type {FlowAccountInfo, FlowNft, FlowTokenId} from '@nufi/wallet-flow'

import {fetchNeverStaleQuery, useNeverStaleQuery} from 'src/utils/query-utils'

import {getAllNfts as getAllNftsGeneric} from '../../../../public/nfts'
import {flow} from '../../../flowManagers'
import {QUERY_KEY_PREFIX as P} from '../../utils'
import {cachedGetAccounts} from '../core'

export const nftsQueryKeys = {
  index: [P, 'nfts'],
  nftItem: (id: FlowTokenId | null) => [...nftsQueryKeys.index, 'nft', id],
} as const

//
// __CACHED__ QUERIES
//

async function getAllNfts() {
  const accounts = await cachedGetAccounts()
  return getAllNftsGeneric({
    blockchain: 'flow',
    accounts,
    getAccountNfts: (account: FlowAccountInfo) =>
      flow.accountManager.getNfts(account.address),
    getSingleNftQueryKey: (nft: FlowNft) => getNft.__key(nft.id),
  })
}

getAllNfts.__key = nftsQueryKeys.index

export function cachedGetAllNfts() {
  return fetchNeverStaleQuery({queryKey: getAllNfts.__key, queryFn: getAllNfts})
}

export function useGetAllNfts(enabled = true) {
  return useNeverStaleQuery({
    queryKey: getAllNfts.__key,
    queryFn: getAllNfts,
    enabled,
  })
}

//
// __COMPUTED__ QUERIES
//

const getNft = (tokenId: FlowTokenId | null) => async () => {
  const {allNfts} = await cachedGetAllNfts()

  if (tokenId == null) return null
  const nft = allNfts[tokenId]
  return nft || null
}

getNft.__key = nftsQueryKeys.nftItem

export function cachedGetNft(tokenId: FlowTokenId | null) {
  return fetchNeverStaleQuery({
    queryKey: getNft.__key(tokenId),
    queryFn: getNft(tokenId),
  })
}

// TODO: allow fetching arbitrary, not just own NFT. It may be needed for dapp connector.
// See cardano implementation
export function useGetNft(tokenId: FlowTokenId, enabled = true) {
  return useNeverStaleQuery({
    queryKey: getNft.__key(tokenId),
    queryFn: getNft(tokenId),
    enabled,
  })
}
