import type {CardanoAccountStoredData} from '@nufi/wallet-cardano'
import type {AccountId, IdentitySecret} from '@nufi/wallet-common'
import type {FlowAccountStoredData} from '@nufi/wallet-flow'
import type {SolanaAccountStoredData} from '@nufi/wallet-solana'
import type {SetState} from 'zustand'
import create from 'zustand'

import type {WalletKind} from 'src/wallet/walletKind'
import {blockchainToWalletKind} from 'src/wallet/walletKind'

import type {EvmAccountStoredData} from '../wallet/evm/types'
import type {
  Mnemonic,
  RootSeed,
  Blockchain,
  TokenImport,
  TokenVisibility,
} from '../wallet/types'

import logger from './logger'

type LocalSecretData = {
  type: 'local'
  seed: RootSeed
  mnemonic: Mnemonic
}

type RemoteSecretData = {
  type: 'remote'
  identitySecret: IdentitySecret
}

export type SecretData = LocalSecretData | RemoteSecretData

type SecretState = {
  data: SecretData | null
  init: (data: SecretData) => void
  reset: () => void
}

// TODO: consider not storing secret data in zustand at all, as we anyway
// do not react to their changes in React components and also
// to improve security guarantees.
export const useSecretStore = create<SecretState>((set) => ({
  data: null,
  init: (data) => {
    set({
      data,
    })
  },
  reset: () => set({data: null}),
}))

export type InitializationState = {
  initialized: boolean
  failedWalletInitializations: (Blockchain | WalletKind)[] | null
  init: (failedWalletInitializations: (Blockchain | WalletKind)[]) => void
  reset: () => void
}

const initialInitializationStateValue = {
  initialized: false,
  failedWalletInitializations: null,
}

export const useInitializationStateStore = create<InitializationState>(
  logger<InitializationState>('InitializationState:')((set) => ({
    ...initialInitializationStateValue,
    init: (failedWalletInitializations: (Blockchain | WalletKind)[]) =>
      set({
        initialized: true,
        failedWalletInitializations,
      }),
    reset: () => set(initialInitializationStateValue),
  })),
)

type BlockchainAccount = {
  id: AccountId
}

export type BlockchainState<T extends BlockchainAccount> = {
  accounts: Array<T> | null
  setAccounts: (accounts: Array<T>) => void
}

function getWalletStore<T extends BlockchainAccount>(
  set: SetState<BlockchainState<T>>,
) {
  return {
    accounts: null,
    setAccounts: (accounts: Array<T>) =>
      set({
        accounts,
      }),
  }
}

// CARDANO

type CardanoState = BlockchainState<CardanoAccountStoredData>

export const useCardanoStore = create<CardanoState>(
  logger<CardanoState>('CardanoState:')((set) =>
    getWalletStore<CardanoAccountStoredData>(set),
  ),
)

// SOLANA

type SolanaState = BlockchainState<SolanaAccountStoredData>

export const useSolanaStore = create<SolanaState>(
  logger<SolanaState>('SolanaState:')((set) =>
    getWalletStore<SolanaAccountStoredData>(set),
  ),
)

// FLOW

type FlowState = BlockchainState<FlowAccountStoredData>

export const useFlowStore = create<FlowState>(
  logger<FlowState>('FlowState:')((set) =>
    getWalletStore<FlowAccountStoredData>(set),
  ),
)

// EVM

export type EvmState = BlockchainState<EvmAccountStoredData>

export const useEvmStore = create<EvmState>(
  logger<EvmState>(`EvmState:`)((set) =>
    getWalletStore<EvmAccountStoredData>(set),
  ),
)

export const getAccountStore = (blockchain: Blockchain) => {
  const walletKind = blockchainToWalletKind(blockchain)
  return {
    cardano: useCardanoStore,
    flow: useFlowStore,
    solana: useSolanaStore,
    evm: useEvmStore,
  }[walletKind]
}

// GENERAL

export type AccountStoredData =
  | CardanoAccountStoredData
  | SolanaAccountStoredData
  | FlowAccountStoredData
  | EvmAccountStoredData

export type WalletKindAccountStoredData<TWalletKind extends WalletKind> =
  Extract<AccountStoredData, {walletKind: TWalletKind}>

/**
Note that the below types are evaluated as "never", though they can be used at places
where we do dynamic loops over multiple wallets & their accounts, without the need to
repeat the same code for every blockchain. In most cases use just `AccountStoredData` type
that should work fine.
*/
export type __UniversalAccountStoredData = SolanaAccountStoredData &
  CardanoAccountStoredData &
  FlowAccountStoredData &
  EvmAccountStoredData

export type __UniversalAccountsStoredData = SolanaAccountStoredData[] &
  CardanoAccountStoredData[] &
  FlowAccountStoredData[] &
  __UniversalAccountStoredData[]

// TOKENS

export type TokenImportState<T extends TokenImport> = {
  tokens: Array<T>
  setTokens: (tokens: Array<T>) => void
}

function getTokenImportStore<T extends TokenImport>(
  set: SetState<TokenImportState<T>>,
) {
  return {
    tokens: [],
    setTokens: (tokens: Array<T>) =>
      set({
        tokens,
      }),
  }
}

type _TokenImportState = TokenImportState<TokenImport>
export const useTokenImportStore = create<_TokenImportState>(
  logger<_TokenImportState>('TokenImportState:')((set) =>
    getTokenImportStore<TokenImport>(set),
  ),
)

export type TokenVisibilityState<T extends TokenVisibility> = {
  tokenVisibilities: T[]
  setTokenVisibilities: (tokens: Array<T>) => void
}

function getTokenVisibilityStore<T extends TokenVisibility>(
  set: SetState<TokenVisibilityState<T>>,
) {
  return {
    tokenVisibilities: [],
    setTokenVisibilities: (tokenVisibilities: Array<T>) =>
      set({
        tokenVisibilities,
      }),
  }
}

type _TokenVisibilityState = TokenVisibilityState<TokenVisibility>
export const useTokenVisibilityStore = create<_TokenVisibilityState>(
  logger<_TokenVisibilityState>('TokenVisibilityState:')((set) =>
    getTokenVisibilityStore<TokenVisibility>(set),
  ),
)
