import {Box, Link, Typography, styled} from '@mui/material'
import {connectSnap} from '@nufi/metamask-snap'
import React from 'react'
import {Trans, useTranslation} from 'react-i18next'

import {ReactComponent as OnboardingLoader} from 'src/assets/icons/onboardingLoader.svg'
import confirmImg from 'src/assets/images/onboarding/confirm.png'
import metamaskNotFoundImg from 'src/assets/images/onboarding/metamask-not-found.png'
import updateMetamaskImg from 'src/assets/images/onboarding/update-metamask.png'
import {Icon} from 'src/components'
import {Modal, ModalHeader, ModalLayout} from 'src/components/visual/Modal'
import {externalLinks} from 'src/constants'
import type {Blockchain} from 'src/types'
import {useMutation} from 'src/utils/mutation-utils'
import {useGetBlockchainName} from 'src/utils/translations'
import {getRefreshKeyLabel} from 'src/utils/userAgentDetection'

import {useMetamaskContext} from './MetamaskContext'

const ModalBase = styled(Modal)(({theme}) => ({
  paddingBottom: theme.spacing(2),
  width: '650px',
}))

const UpdateMetamaskMessage = ({
  textAlign = 'center',
}: {
  textAlign?: 'center' | 'start'
}) => {
  const {t} = useTranslation()
  return (
    <Typography color="textSecondary" sx={{textAlign}}>
      <Trans
        i18nKey="metamask_update"
        t={t}
        components={{
          Link: <Link target="_blank" href={externalLinks.metamaskUpdate} />,
        }}
        values={{
          refreshKey: getRefreshKeyLabel(),
        }}
      />
    </Typography>
  )
}

export function UpdateMetamaskModal({onClose}: {onClose: () => void}) {
  const {t} = useTranslation()

  return (
    <ModalBase onClose={onClose} variant="centered" dismissable>
      <ModalLayout
        header={
          <ModalHeader onClose={onClose} hasDivider>
            <Typography variant="h6">
              {t('MetaMask extension is outdated')}
            </Typography>
          </ModalHeader>
        }
        body={
          <Box sx={{padding: 2}}>
            <UpdateMetamaskMessage textAlign="start" />
          </Box>
        }
      />
    </ModalBase>
  )
}

const MetamaskIcon = <Icon type="metamaskIcon" />

export const useInstallSnapSuccessContent = (blockchain: Blockchain) => {
  const {t} = useTranslation()
  const getBlockchainName = useGetBlockchainName()

  return {
    title: t('metamask_install_snap_success_title', {
      blockchain: getBlockchainName(blockchain),
    }),
    description:
      blockchain === 'cardano'
        ? t('metamask_install_snap_success_description_cardano')
        : t('metamask_install_snap_success_description'),
  }
}

export const useMetamaskNotDetectedContent = () => {
  const {t} = useTranslation()

  return {
    imageSrc: metamaskNotFoundImg,
    title: t('metamask_not_detected_title'),
    description: t('metamask_not_detected_description', {
      refreshKey: getRefreshKeyLabel(),
    }),
    actionLabel: t('Install MetaMask'),
    actionIcon: MetamaskIcon,
  }
}

export const useUpdateMetamaskContent = () => {
  const {t} = useTranslation()

  return {
    imageSrc: updateMetamaskImg,
    title: t('metamask_update_widget_title'),
    description: t('metamask_update_widget_description', {
      refreshKey: getRefreshKeyLabel(),
    }),
    actionLabel: t('Update MetaMask'),
    actionIcon: MetamaskIcon,
  }
}

export const useInstallSnapContent = (blockchain: Blockchain) => {
  const {t} = useTranslation()

  const MetamaskSnapsDirectoryLink = (
    <Link
      sx={{display: 'block'}}
      href={externalLinks.metamaskSnapsDirectory}
      target="_blank"
    />
  )

  return {
    imageSrc: confirmImg,
    title:
      blockchain === 'cardano'
        ? t('metamask_install_snap_title_cardano')
        : t('metamask_install_snap_title'),
    description:
      blockchain === 'cardano' ? (
        <Trans
          i18nKey="metamask_install_snap_description_cardano"
          t={t}
          components={{
            Link: MetamaskSnapsDirectoryLink,
          }}
        />
      ) : (
        <Trans
          i18nKey="metamask_install_snap_description"
          t={t}
          components={{
            Link: MetamaskSnapsDirectoryLink,
          }}
        />
      ),
    actionLabel: t('Retry'),
    actionIcon: MetamaskIcon,
  }
}

export const useInstallSnapMutation = () => {
  const {setInstalledSnap} = useMetamaskContext()

  const installSnapMutation = useMutation('install_snap', async () => {
    const result = await connectSnap()
    setInstalledSnap(result)
  })

  return installSnapMutation
}

const ConnectingToMetaMaskLoader = styled((props) => (
  <div {...props}>
    <OnboardingLoader width="100%" height="100%" viewBox="0 0 200 200" />
    <ConnectingToMetaMaskIcon />
  </div>
))(({theme}) => ({
  width: '60%',
  color: theme.palette.primary.main,
  position: 'relative',
  margin: theme.spacing(4, 0),
}))

const ConnectingToMetaMaskIcon = styled((props) => (
  <Icon type="metamaskIcon" {...props} />
))({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '20%',
  height: '20%',
})

export const useConnectingToMetamaskContent = () => {
  const {t} = useTranslation()

  return {
    title: t('metamask_onboarding_connecting_title'),
    LoaderElement: ConnectingToMetaMaskLoader,
  }
}
