import {
  txHistoryEvmBlockchains,
  exchangeEvmBlockchains,
  tokenEvmBlockchains,
  nftEvmBlockchains,
  explorerLinkEvmBlockchains,
  sendEvmBlockchains,
  assetDetailEvmBlockchains,
  csvExportEvmBlockchains,
  profileTokenImportEvmBlockchains,
  moonpayEvmBlockchains,
  existingEvmBlockchains,
} from './evmBlockchainArrays'

export * from './i18nBlockchainArrays'

export const txHistoryBlockchains = [
  'solana',
  'cardano',
  'flow',
  ...txHistoryEvmBlockchains,
] as const

export const exchangeBlockchains = [
  'solana',
  'cardano',
  'flow',
  ...exchangeEvmBlockchains,
] as const

export const tokenBlockchains = [
  'solana',
  'cardano',
  'flow',
  ...tokenEvmBlockchains,
] as const

export const nftBlockchains = [
  'solana',
  'cardano',
  'flow',
  ...nftEvmBlockchains,
] as const

export const explorerLinkBlockchains = [
  'solana',
  'cardano',
  'flow',
  ...explorerLinkEvmBlockchains,
] as const

export const sendBlockchains = [
  'solana',
  'cardano',
  'flow',
  ...sendEvmBlockchains,
] as const

export const assetDetailBlockchains = [
  'solana',
  'cardano',
  'flow',
  ...assetDetailEvmBlockchains,
] as const

export const _csvExportBlockchains = [
  'solana',
  'cardano',
  'flow',
  ...csvExportEvmBlockchains,
] as const

export const profileTokenImportBlockchains = [
  ...profileTokenImportEvmBlockchains,
] as const

export const moonpayBlockchains = [
  'cardano',
  'flow',
  'solana',
  ...moonpayEvmBlockchains,
] as const

export const existingBlockchains = [
  'solana',
  'cardano',
  'flow',
  ...existingEvmBlockchains,
] as const
