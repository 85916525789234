import type {AccountName} from '@nufi/wallet-common'
import type {
  FlowAccountInfo,
  FlowAddress,
  FlowTxHistoryEntry,
} from '@nufi/wallet-flow'
import _ from 'lodash'

import {flow} from '../../flowManagers'

import {FLOW_TX_HISTORY_PAGE_SIZE} from './constants'

export type CSVFlowTxHistoryEntry = FlowTxHistoryEntry & {
  accountName: AccountName
  accountAddress: FlowAddress
}

export const getTxHistory = async (
  accounts: FlowAccountInfo[],
  startDate: Date,
): Promise<CSVFlowTxHistoryEntry[]> => {
  return (
    await Promise.all(
      accounts.map(async ({id, address, name}) => {
        let history: FlowTxHistoryEntry[] = []
        let shouldContinue = true
        let offset = 0
        while (shouldContinue) {
          const accountData = flow.accountsStore.getAccount(id)
          const {txHistory: chunk, newOffset} =
            await flow.accountManager.getTxHistory(
              accountData,
              null,
              false,
              FLOW_TX_HISTORY_PAGE_SIZE,
              offset,
            )
          history = [...history, ...chunk]

          const oldestTxTime = _.last(chunk)?.timeIssued
          const hasNextPage = chunk.length === FLOW_TX_HISTORY_PAGE_SIZE
          shouldContinue =
            hasNextPage &&
            oldestTxTime != null &&
            oldestTxTime.getTime() >= startDate.getTime()

          offset = newOffset
        }
        return history.map((tx) => ({
          ...tx,
          accountName: name,
          accountAddress: address,
        }))
      }),
    )
  ).flat()
}
