import BigNumber from 'bignumber.js'
import {useTranslation} from 'react-i18next'
import * as yup from 'yup'

import type {LondonGasPriority} from '../../../../wallet/evm'
import {
  EVM_DECIMALS,
  EVM_TRANSFER_GAS_UNITS,
} from '../../../../wallet/evm/constants'
import {
  useAmountDecimalValidation,
  useAmountRequiredValidation,
} from '../../common/schema'

type LondonUiGasOptions = {
  gasPriority: LondonGasPriority | null
  showAdvancedOptions: boolean
}

export type LondonGasSchema = {
  gasLimit: string
  suggestedBaseFeePerGas: string
  maxPriorityFeePerGas: string
}

export type LondonUiGasSchema = LondonGasSchema & LondonUiGasOptions

export const useLondonGasSchema = (): {
  [k in keyof LondonGasSchema]: yup.AnySchema
} => {
  const amountDecimalsValidation = useAmountDecimalValidation({
    decimals: EVM_DECIMALS.GWEI,
  })
  const gasLimitDecimalValidation = useAmountDecimalValidation({
    decimals: 0,
  })
  const amountRequiredValidation = useAmountRequiredValidation()
  const gweiAmountValidation = amountDecimalsValidation.concat(
    amountRequiredValidation,
  )

  const {t} = useTranslation()
  const gasValidationSchema = {
    gasLimit: gasLimitDecimalValidation.concat(amountRequiredValidation).concat(
      yup
        .string()
        .test(
          'not-lower-than-minimum',
          t('gas_limit_below_minimum', {gasLimit: EVM_TRANSFER_GAS_UNITS}),
          (amount) => {
            if (amount === undefined) return true
            return !new BigNumber(amount).isLessThan(EVM_TRANSFER_GAS_UNITS)
          },
        ),
    ),
    // TODO: Max base fee cannot be lower than priority fee
    // TODO: if `max base fee` is zero (e.g. testnets), set it
    // to `maxPriorityFeePerGas` + very small value
    suggestedBaseFeePerGas: gweiAmountValidation,
    maxPriorityFeePerGas: gweiAmountValidation,
  }

  return gasValidationSchema
}

export type SimpleGasSchema = {
  gasLimit: string
  gasPrice: string
}

export const useSimpleGasSchema = (
  minGasLimit: BigNumber = new BigNumber(0),
): {[k in keyof SimpleGasSchema]: yup.AnySchema} => {
  const {t} = useTranslation()
  const amountDecimalsValidation = useAmountDecimalValidation({
    decimals: EVM_DECIMALS.GWEI,
  })
  const gasLimitDecimalValidation = useAmountDecimalValidation({
    decimals: 0,
  })
  const amountRequiredValidation = useAmountRequiredValidation()

  const gasValidationSchema = {
    gasLimit: gasLimitDecimalValidation.concat(amountRequiredValidation).concat(
      yup
        .string()
        .test(
          'not-lower-than-minimum',
          t('gas_limit_below_minimum', {gasLimit: minGasLimit}),
          (amount) => {
            if (amount === undefined) return true
            return !new BigNumber(amount).isLessThan(minGasLimit)
          },
        ),
    ),
    gasPrice: amountDecimalsValidation.concat(amountRequiredValidation),
  }

  return gasValidationSchema
}
