import {Switch, FormControlLabel, TextField} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, {useRef} from 'react'
import {useTranslation} from 'react-i18next'

type PlainTextSwitchProps = {
  state: boolean
  setState: (state: boolean) => void
}

export function PlainTextSwitch({state, setState}: PlainTextSwitchProps) {
  const {t} = useTranslation()

  return (
    <FormControlLabel
      control={
        <Switch
          checked={state}
          onChange={(e) => setState(e.target.checked)}
          color="primary"
        />
      }
      label={t('Plain Text') as string}
    />
  )
}

type PlainTextInputProps = {
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void
  error?: boolean
  mnemonicValue?: string
}

export function PlainTextInput({
  onChange,
  error,
  mnemonicValue = undefined,
}: PlainTextInputProps) {
  const {t} = useTranslation()
  const inputRef = useRef<HTMLInputElement | null>(null)
  const classes = useStyles()
  const selectText = () => {
    inputRef?.current?.select()
  }
  const mnemonicLength = mnemonicValue?.split(' ').length || 0

  return (
    <TextField
      onChange={onChange}
      onClick={mnemonicValue ? () => selectText() : undefined}
      value={mnemonicValue}
      multiline
      fullWidth
      inputRef={inputRef}
      rows={mnemonicLength < 24 ? 3 : 4}
      variant="outlined"
      label={
        mnemonicValue ? undefined : t('Type each word or paste from clipboard')
      }
      inputProps={{
        spellCheck: 'false',
        readOnly: !!mnemonicValue,
        autoComplete: 'off',
        'data-test-id': 'plain-text-mnemonic-field',
      }}
      InputProps={{
        classes: {
          multiline: classes.plainTextModeInput,
          inputMultiline: classes.plainTextModeTypography,
        },
      }}
      error={error}
      helperText={error && t('Required field.')}
    />
  )
}

const useStyles = makeStyles((theme) => ({
  plainTextModeInput: {
    background: theme.palette.background.paper,
    padding: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1.5),
    },
  },
  plainTextModeTypography: {
    lineHeight: '150%',
    textAlign: 'justify',
    letterSpacing: 0.15,
    wordSpacing: 15,
    fontSize: theme.typography.body2.fontSize,
  },
}))
