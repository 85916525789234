import {safeAssertUnreachable} from '@nufi/frontend-common'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import step1Img from 'src/assets/images/onboarding/onboarding-1.png'
import step2Img from 'src/assets/images/onboarding/onboarding-2.png'
import type {Blockchain} from 'src/types'
import {useGetBlockchainName} from 'src/utils/translations'

import {OnboardingContent} from './OnboardingContent'

type Step = 'step-1' | 'step-2'

type OnboardingStepsProps = {
  onLastStepFinish: () => void
  onBack?: () => void
  blockchain: Blockchain
}

export const MetamaskOnboardingSteps = ({
  onLastStepFinish,
  blockchain,
  onBack,
}: OnboardingStepsProps) => {
  const [step, setStep] = useState<Step>('step-1')
  switch (step) {
    case 'step-1':
      return (
        <MetamaskOnboardingStep1
          onNext={() => setStep('step-2')}
          blockchain={blockchain}
          onBack={onBack}
        />
      )
    case 'step-2':
      return (
        <MetamaskOnboardingStep2
          onNext={onLastStepFinish}
          blockchain={blockchain}
        />
      )

    default:
      return safeAssertUnreachable(step)
  }
}

type StepProps = {
  blockchain: Blockchain
  onNext: () => unknown
}

export const MetamaskOnboardingStep1 = ({
  onNext,
  blockchain,
  onBack,
}: StepProps & Pick<OnboardingStepsProps, 'onBack'>) => {
  const {t} = useTranslation()
  const getBlockchainName = useGetBlockchainName()

  return (
    <OnboardingContent
      title={t('metamask_onboarding_app_step_1_title', {
        blockchain: getBlockchainName(blockchain),
      })}
      actions={{
        primary: {
          onClick: onNext,
          children: t('Continue'),
        },
      }}
      imgSrc={step1Img}
      description={t('metamask_onboarding_app_step_1_description', {
        blockchain: getBlockchainName(blockchain),
      })}
      onBack={onBack}
    />
  )
}

const MetamaskOnboardingStep2 = ({onNext, blockchain}: StepProps) => {
  const {t} = useTranslation()
  const getBlockchainName = useGetBlockchainName()

  return (
    <OnboardingContent
      title={t('NuFi - Your Trusted Wallet Partner')}
      actions={{
        primary: {
          onClick: onNext,
          children: t('Continue'),
        },
      }}
      imgSrc={step2Img}
      description={t('metamask_onboarding_app_step_2_description', {
        blockchain: getBlockchainName(blockchain),
      })}
    />
  )
}
