import {assert} from '@nufi/frontend-common'
import type {Mutation, MutationKey} from '@tanstack/react-query'
import _ from 'lodash'

import queryClient from '../../queryClient'
import type {PromiseReturnType} from '../../types'

import type {CustomUseMutationResult} from './useMutation'

export function invalidateQueryKeys(keys: MutationKey[]): Promise<void>[] {
  // The order in which keys are invalidated matters so it needs to be done
  // sequentially. More info can be found in docs/queries.md.
  return keys.map((key) =>
    /**
     * react-query v5 migration workaround:
     * setting {cancelRefetch: false} to restore behavior from RQ3
     * because otherwise some recursively triggered re-fetches
     * get cancelled which results in unwanted query errors and broken data
     * (e.g. token metadata query can be cancelled by recursive queries from total balance)
     * see https://tanstack.com/query/v5/docs/framework/react/guides/migrating-to-react-query-4#consistent-behavior-for-cancelrefetch
     */
    queryClient.invalidateQueries({queryKey: key}, {cancelRefetch: false}),
  )
}

export type MutationInfo<T = unknown> = {
  mutationId: number
  mutationKey: MutationKey
  context?: T | null
  isPending: boolean
  error: unknown
  data: unknown
  updatedAt: Date
  retry: () => Promise<void>
}

// `any` used intentionally
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function withSilentError<F extends (...args: any[]) => Promise<any>>(
  fn: F,
): (...args: Parameters<F>) => Promise<PromiseReturnType<F> | null> {
  return async (
    ...args: Parameters<F>
  ): Promise<PromiseReturnType<F> | null> => {
    try {
      return await fn(...args)
    } catch (err) {
      return null
    }
  }
}

const getMutationInfo = (
  mutation: Mutation,
  updatedAt?: Date,
): MutationInfo => {
  assert(
    mutation.options.mutationKey !== undefined,
    'Mutation without mutation key!',
  )
  return {
    mutationId: mutation.mutationId,
    mutationKey: mutation.options.mutationKey,
    context: mutation.state.context,
    isPending: mutation.state.status === 'pending',
    error: mutation.state.error,
    data: mutation.state.data,
    updatedAt: updatedAt || new Date(),
    async retry(this: MutationInfo) {
      const fn = async () => {
        await mutation.execute()
      }
      await withSilentError(fn)()
    },
  }
}

export const isMutationKeyIncluded = (
  mutationKey: MutationKey | undefined,
  candidateKeys: MutationKey[],
) => _.some(Object.values(candidateKeys), (key) => _.isEqual(key, mutationKey))

export const mutationSubscriber = (
  mutationKeys: MutationKey[],
  callBack: (mutation: MutationInfo) => void,
) =>
  queryClient.getMutationCache().subscribe(({mutation}) => {
    if (
      mutation &&
      isMutationKeyIncluded(mutation.options.mutationKey, mutationKeys)
    ) {
      // Idle status can cause a multitude of unnecessary calls, loading seems to behave better
      if (mutation.state.status !== 'idle') {
        callBack(getMutationInfo(mutation))
      }
    }
  })

// useful when wrapping a mutation, to avoid the need to sync the implementation
// of `mutateAsync` and `mutateAsyncSilent` which can lead to pretty nasty bugs
export const getStaticMutationProperties = <TData, TError>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mutationResult: CustomUseMutationResult<TData, TError, any, any>,
) => ({
  data: mutationResult.data,
  isPending: mutationResult.isPending,
  error: mutationResult.error,
})
