import type {
  FlowAccountInfo,
  FlowNodeInfo,
  FlowSignedTransaction,
  FlowTxPlan,
} from '@nufi/wallet-flow'
import {flowToNanoflow} from '@nufi/wallet-flow'
import React from 'react'

import type {AccountId} from '../../../../../types'
import {useSignTx, useSubmitTx} from '../../../../../wallet/flow'
import {ensureAccountById} from '../../../../../wallet/utils/common'
import {
  useActiveSchema,
  useActiveScreenState,
  useFormikOnSubmit,
} from '../../../../transaction'
import {DebouncedFormik} from '../../../../utils/DebouncedFormik'
import {useSummarySchema} from '../schemas'
import {findDelegation, useSafeAsyncFlowTxPlanState} from '../utils'

import {getRecommendedValidatorId} from './DetailsScreen'
import {FlowStakeModalContent} from './ModalContent'
import {useRegisterDelegatorDetailSchema} from './schema'
import type {FormSchema} from './types'

type FlowRegisterDelegatorFormProps = {
  onClose: () => unknown
  accountId: AccountId
  accounts: FlowAccountInfo[]
  validators: FlowNodeInfo[]
}

export function FlowRegisterDelegatorForm({
  onClose,
  accountId,
  accounts,
  validators,
}: FlowRegisterDelegatorFormProps) {
  const stake = useSignTx()
  const submit = useSubmitTx()
  const {setActiveScreen} = useActiveScreenState()

  const [txPlanState, safeFetchAndUpdateTxPlan] =
    useSafeAsyncFlowTxPlanState<FormSchema>(accounts, 'registerDelegator')

  const schema = useActiveSchema({
    details: useRegisterDelegatorDetailSchema({
      accounts,
    }),
    summary: useSummarySchema({accounts}),
  })

  const initialValues: FormSchema = {
    accountId: accountId || accounts[0]!.id,
    validatorId: getRecommendedValidatorId(validators),
    amount: '',
    password: '',
  }

  const onTxSign = async (values: FormSchema) => {
    const accountInfo = ensureAccountById(accounts, values.accountId)
    const amount = flowToNanoflow(values.amount)
    const nodeId = values.validatorId
    // if there already is a delegation to selected nodeId
    // instead of registering delegator, we just add new tokens
    const existingDelegation = findDelegation(accountInfo, nodeId)
    const txPlan: FlowTxPlan = existingDelegation
      ? {
          type: 'stakeNewTokens',
          args: {amount, nodeId, delegatorId: existingDelegation.id},
        }
      : {
          type: 'registerDelegator',
          args: {amount, nodeId},
        }
    return await stake.mutateAsyncSilent({
      txPlan,
      accountInfo,
    })
  }

  const onTxSubmit = async (
    accountId: AccountId,
    signedTx: FlowSignedTransaction,
  ) => await submit.mutateAsyncSilent({accountId, signedTx})

  const onTxSignAndSubmit = async (values: FormSchema) => {
    const signedTx = await onTxSign(values)
    if (!signedTx) return
    setActiveScreen('submit')
    await onTxSubmit(values.accountId, signedTx)
  }

  const onSubmit = useFormikOnSubmit<FormSchema>({
    accounts,
    onTxSignAndSubmit,
    onDetails: async (v, {setSubmitting, setFieldError}) => {
      const {error} = await safeFetchAndUpdateTxPlan(v, true)
      if (!error) {
        setSubmitting(false)
        setActiveScreen('summary')
      } else {
        setFieldError('amount', (error as Error).message)
      }
    },
  })

  return (
    // TODO: decide if we need to use DebouncedFormik at all
    <DebouncedFormik
      initialValues={initialValues}
      validationSchema={schema}
      onValidated={async ({values, isValid}) => {
        if (isValid) {
          safeFetchAndUpdateTxPlan(values)
        }
      }}
      onSubmit={onSubmit}
    >
      {(formikProps) => (
        <FlowStakeModalContent
          {...{
            formikProps,
            accounts,
            setActiveScreen,
            onClose,
            onTxSubmit,
            onTxSignAndSubmit,
            validators,
            txPlan: txPlanState.data,
          }}
          submitProps={submit}
          signProps={stake}
        />
      )}
    </DebouncedFormik>
  )
}
