import {Sentry} from '@nufi/frontend-common'
import _ from 'lodash'

import {useEvmStore} from 'src/store/wallet'

import type {AccountId} from '../../../types'
import {assert} from '../../../utils/assertion'
import type {
  EvmAccountStoredData,
  EvmBlockchain,
  EvmTransactionHash,
  EvmTxStatus,
} from '../../../wallet/evm'
import {assertIsEvmBlockchain} from '../../../wallet/evm'
import {getEvmManager} from '../../../wallet/evm/evmManagers'
import {ensureAccountById} from '../../../wallet/utils/common'
import type {TransactionInfo} from '../types'

import {updateTransactionState} from './common'

const getEvmAccountLastMinedNonce = async <TBlockchain extends EvmBlockchain>(
  blockchain: TBlockchain,
  accountId: AccountId,
) => {
  const {accounts} = useEvmStore.getState()
  assert(
    accounts != null,
    `getEthAccountLastMinedNonce: ${blockchain} accounts must be defined`,
  )
  const _account = ensureAccountById(
    accounts,
    accountId,
  ) as EvmAccountStoredData
  const evmManager = getEvmManager(blockchain)
  const account = evmManager.accountManager.getAccountOfflineInfo(_account)

  try {
    const lastMinedTxNonce =
      await evmManager.blockchainApi.getLatestMinedTxNonce(account.address)
    return {data: lastMinedTxNonce, error: null}
  } catch (error) {
    // If there is error e.g. due to a http network request failing,
    // we rather do not change the state of tx at all.
    Sentry.captureException(error)
    return {data: null, error}
  }
}

const updateEvmTx = async (
  tx: TransactionInfo,
  lastMinedNonce: number | null,
): Promise<TransactionInfo> => {
  assert(tx.context != null)
  assertIsEvmBlockchain(tx.context.blockchain)

  let status: EvmTxStatus = 'pending'
  try {
    const txId = tx?.context?.transactionId as EvmTransactionHash<
      typeof tx.context.blockchain
    >
    const txStatus = await getEvmManager(
      tx.context.blockchain,
    ).blockchainApi.getTxStatus({
      txHash: txId,
      txNonce: tx.context.nonce,
      lastMinedNonce,
    })

    if (txStatus === 'dropped') {
      return {
        ...tx,
        isPending: false,
        error: undefined,
        context: {
          ...tx.context,
          isDropped: true,
        },
      }
    }
    status = txStatus
  } catch (err) {
    // If there is error e.g. due to a http network request failing,
    // we rather do not change the state of tx at all.
    Sentry.captureException(err)
    return tx
  }
  return {
    ...tx,
    isPending: status === 'pending',
    error: status === 'failure' ? true : undefined,
  }
}

export const updateEvmTransactionsState = async <
  TBlockchain extends EvmBlockchain,
>(
  blockchain: TBlockchain,
  txs: TransactionInfo[],
) => {
  const ethByAccounts = _.groupBy(txs, (tx) => tx.context?.accountId)

  // Note that this is processed in sequence
  for (const accountId in ethByAccounts) {
    const accountTransactions = ethByAccounts[accountId]!
    const {data: lastMinedNonce, error} = await getEvmAccountLastMinedNonce(
      blockchain,
      accountId as AccountId,
    )

    // Do not change state if there was some error, as if the error is due to
    // network, we may not change the state properly.
    if (error == null) {
      // Note that this is processed in sequence
      for (const tx of accountTransactions) {
        // filter out loading somehow
        const updatedTx = await updateEvmTx(tx, lastMinedNonce)
        updateTransactionState({origTx: tx, updatedTx})
      }
    }
  }
}
