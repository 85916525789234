import {SwapVert as BuySellIcon} from '@mui/icons-material'
import {Box, Stack, Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {NavigateBack} from 'src/components'

export const RampFlowHeaderWithTitle = () => {
  const {t} = useTranslation()

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <BuySellIcon color="primary" fontSize="large" />
      <Typography variant="h6" color="textSecondary">
        {t('Buy or Sell Crypto')}
      </Typography>
    </Stack>
  )
}

type RampFlowHeaderWithBackButtonProps = {
  onBack: () => void
}

export const RampFlowHeaderWithBackButton = ({
  onBack,
}: RampFlowHeaderWithBackButtonProps) => {
  const {t} = useTranslation()

  return (
    <Box ml={0.5}>
      <NavigateBack
        onBack={onBack}
        label={
          <Typography
            ml={0.5}
            variant="h6"
            color="currentcolor"
            component="span"
          >
            {t('Cancel flow')}
          </Typography>
        }
      />
    </Box>
  )
}
