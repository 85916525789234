import type {ImportEvmTokenParams} from '../../../../../../store/dappConnector'
import type {
  EvmBlockchain,
  EvmErc20Metadata,
} from '../../../../../../wallet/evm'

import type {TokenDifferences} from './common'

type TokenProperty = 'name' | 'ticker' | 'decimals'

export function getTokenImportEffect<TBlockchain extends EvmBlockchain>(
  requestData: ImportEvmTokenParams<TBlockchain>,
  metadata: EvmErc20Metadata<TBlockchain>,
): {
  differences: TokenDifferences
  updatedMetadata: EvmErc20Metadata<TBlockchain>
} {
  const updatedMetadata: EvmErc20Metadata<TBlockchain> = {...metadata}

  const differences: TokenDifferences = {} as TokenDifferences

  type PropertyPair =
    | {request: 'symbol'; meta: 'ticker'}
    | {request: 'name'; meta: 'name'}
    | {request: 'decimals'; meta: 'decimals'}

  const handleProperty = (label: TokenProperty, propertyPair: PropertyPair) => {
    const currentValue = metadata[propertyPair.meta]
    const suggestedValue = requestData.options[propertyPair.request]
    if (
      (typeof suggestedValue === 'string' ||
        typeof suggestedValue === 'number') &&
      suggestedValue !== currentValue
    ) {
      differences[label] = {
        oldValue: currentValue,
        newValue: suggestedValue,
      }
    }

    // Can we type or handle this more type safe? Seems that generic + type guards
    // case is too much for TS.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    updatedMetadata[propertyPair.meta] =
      suggestedValue || updatedMetadata[propertyPair.meta]
  }

  handleProperty('ticker', {meta: 'ticker', request: 'symbol'})
  handleProperty('name', {meta: 'name', request: 'name'})
  handleProperty('decimals', {meta: 'decimals', request: 'decimals'})

  // Note that we do not check for `requestData.options.image` as it is not
  // part of off-chain metadata even if we fetch any
  return {differences, updatedMetadata}
}
