// Kept in .ts file so it can be imported from service-worker

import type {ExplorerLinkBlockchain} from '../../blockchainTypes'
import {safeAssertUnreachable} from '../../utils/assertion'
import {isEvmBlockchain} from '../../wallet/evm'

import {
  getCardanoAddressExplorerLink,
  getCardanoTransactionExplorerLink,
} from './cardano/getLink'
import {
  getEvmAddressExplorerLink,
  getEvmTransactionExplorerLink,
} from './evm/getLink'
import {
  getFlowTransactionExplorerLink,
  getFlowAddressExplorerLink,
} from './flow/getLink'
import {
  getSolanaAddressExplorerLink,
  getSolanaTransactionExplorerLink,
} from './solana/getLink'

export const getTransactionExplorerLink = (
  txId: string,
  blockchain: ExplorerLinkBlockchain,
) => {
  switch (blockchain) {
    case 'cardano':
      return getCardanoTransactionExplorerLink(txId)
    case 'solana':
      return getSolanaTransactionExplorerLink(txId)
    case 'flow':
      return getFlowTransactionExplorerLink(txId)
    default: {
      if (isEvmBlockchain(blockchain)) {
        return getEvmTransactionExplorerLink(blockchain, txId)
      }
      return safeAssertUnreachable(blockchain)
    }
  }
}

export const getAddressExplorerLink = (
  address: string,
  blockchain: ExplorerLinkBlockchain,
) => {
  switch (blockchain) {
    case 'cardano':
      return getCardanoAddressExplorerLink(address)
    case 'solana':
      return getSolanaAddressExplorerLink(address)
    case 'flow':
      return getFlowAddressExplorerLink(address)
    default: {
      if (isEvmBlockchain(blockchain)) {
        return getEvmAddressExplorerLink(blockchain, address)
      }
      return safeAssertUnreachable(blockchain)
    }
  }
}

export function getAccountExplorerLink(
  address: string,
  blockchain: ExplorerLinkBlockchain,
) {
  switch (blockchain) {
    case 'cardano':
      return getCardanoAddressExplorerLink(address)
    case 'solana':
      return getSolanaAddressExplorerLink(address)
    case 'flow':
      return getFlowAddressExplorerLink(address)
    default: {
      if (isEvmBlockchain(blockchain)) {
        return getEvmAddressExplorerLink(blockchain, address)
      }
      return safeAssertUnreachable(blockchain)
    }
  }
}
