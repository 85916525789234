import React from 'react'
import ContentLoader from 'react-content-loader'

import {
  useResponsiveDimensions,
  ContentLoaderBackground,
} from '../../../utils/layoutUtils'

const _AssetDetailHistoryItemLoading = ({maxWidth}: {maxWidth: number}) => (
  <ContentLoader
    viewBox={`0 0 ${maxWidth} 160`}
    backgroundColor={ContentLoaderBackground()}
  >
    <rect x="0" y="0" rx="4" ry="4" width={maxWidth} height="20" />
    <rect x="0" y="40" rx="4" ry="4" width={maxWidth} height="100" />
  </ContentLoader>
)

export const AssetDetailHistoryItemLoading = () => {
  const dimensions = useResponsiveDimensions()
  return <_AssetDetailHistoryItemLoading maxWidth={dimensions.width} />
}

export const AssetDetailHistoryLoading = () => (
  <>
    <AssetDetailHistoryItemLoading />
    <AssetDetailHistoryItemLoading />
    <AssetDetailHistoryItemLoading />
  </>
)

export function AssetDetailAccountsLoading() {
  return (
    <ContentLoader
      width="100%"
      height="100%"
      backgroundColor={ContentLoaderBackground()}
    >
      <rect x="0" y="30" rx="5" ry="5" width="100%" height="72" />
      <rect x="0" y="110" rx="5" ry="5" width="100%" height="72" />
      <rect x="0" y="190" rx="5" ry="5" width="100%" height="72" />
    </ContentLoader>
  )
}

function NFTInfoDetailLoading({startingPoint = 0}: {startingPoint?: number}) {
  return (
    <>
      <rect
        x="0"
        y={startingPoint + 20}
        rx="5"
        ry="5"
        width="100%"
        height="20"
      />
      <rect
        x="0"
        y={startingPoint + 50}
        rx="5"
        ry="5"
        width="100%"
        height="20"
      />
      <rect
        x="0"
        y={startingPoint + 80}
        rx="5"
        ry="5"
        width="100%"
        height="20"
      />
    </>
  )
}

export function AssetDetailNFTDescriptionLoading() {
  return (
    <ContentLoader
      width="100%"
      height="100%"
      backgroundColor={ContentLoaderBackground()}
    >
      <rect x="0" y="15" rx="5" ry="5" width="100%" height="350" />
      <rect x="0" y="380" rx="5" ry="5" width="100%" height="35" />
      <NFTInfoDetailLoading startingPoint={410} />
    </ContentLoader>
  )
}

export function AssetDetailNFTAboutLoading() {
  return (
    <ContentLoader
      width="100%"
      height="100%"
      backgroundColor={ContentLoaderBackground()}
    >
      <NFTInfoDetailLoading startingPoint={0} />
    </ContentLoader>
  )
}
