import type {TooltipProps, SxProps, Theme} from '@mui/material'
import {Box} from '@mui/material'
import React from 'react'

import WithTooltip from '../atoms/WithTooltip'

type LabeledIconProps = WrappedIconProps & {
  Label: React.ReactNode
  iconPosition?: 'start' | 'end'
  color?: string
  alignItems?: string
  justifyContent?: string
  className?: string
  sx?: SxProps<Theme>
}

type WrappedIconProps = {
  Icon: React.ReactNode
  spacing?: number
  mr?: number
  ml?: number
}

function WrappedIcon({Icon, ...rest}: WrappedIconProps) {
  return (
    <Box component="span" display="flex" {...rest}>
      {Icon}
    </Box>
  )
}

export function LabeledIcon({
  Label,
  Icon,
  iconPosition = 'end',
  spacing,
  ...rest
}: LabeledIconProps) {
  return (
    <Box display="flex" alignItems="center" {...rest}>
      {iconPosition === 'start' && (
        <WrappedIcon mr={spacing ?? 1} Icon={Icon} />
      )}
      {Label}
      {iconPosition === 'end' && <WrappedIcon ml={spacing ?? 1} Icon={Icon} />}
    </Box>
  )
}

type LabeledIconWithTooltipProps = LabeledIconProps & {
  title: TooltipProps['title']
  tooltipFontVariant?: 'small' | 'medium'
}

export function LabeledIconWithTooltip({
  Label,
  Icon,
  title,
  tooltipFontVariant,
  ...rest
}: LabeledIconWithTooltipProps) {
  return (
    <LabeledIcon
      Icon={
        <WithTooltip {...{title}} fontVariant={tooltipFontVariant}>
          {Icon}
        </WithTooltip>
      }
      {...{Label, ...rest}}
    />
  )
}
