/* eslint-disable @typescript-eslint/explicit-function-return-type */
import type {EvmAccountInfo, EvmTxParams} from '@nufi/wallet-evm'

import {useMutation} from 'src/utils/mutation-utils'

import config from '../../../config'
import type {TransactionContext} from '../../../store/transactions'
import {sleep} from '../../../utils/helpers'
import {
  addToken,
  TokenImportStoreManagerProvider,
} from '../../TokenImportStoreManager'
import type {AccountId} from '../../types'
import {_saveAccounts} from '../../utils/accountsMutations'
import {_saveTokenImports} from '../../utils/tokenMutations'
import {evm} from '../commonEvmManagers'
import {useEnabledEvmBlockchains} from '../evmBlockchains'
import {getEvmManager} from '../evmManagers'
import type {
  ChecksummedEvmAddress,
  EvmAccountStoredData,
  EvmAddress,
  EvmBlockchain,
  EvmErc20Metadata,
  EvmProfileTokenImport,
  EvmSignedTransaction,
  EvmTokenMetadata,
  EvmTransactionHash,
} from '../types'

import {
  getEvmCrossChainAccountInvalidationKeys,
  submitTransferInvalidationKeys,
  tokensInvalidationKeys,
} from './invalidationKeys'

export const mutationKeys = {
  addAccounts: () => ['addAccounts'],
  verifyAccountAddress: <TBlockchain extends EvmBlockchain>(
    blockchain: TBlockchain,
  ) => [blockchain, 'verifyAccountAddress'],
  signTransfer: <TBlockchain extends EvmBlockchain>(
    blockchain: TBlockchain,
  ) => [blockchain, 'signTransfer'],
  submitTransfer: <TBlockchain extends EvmBlockchain>(
    blockchain: TBlockchain,
  ) => [blockchain, 'submitTransfer'],
  resolveEns: <TBlockchain extends EvmBlockchain>(blockchain: TBlockchain) => [
    blockchain,
    'resolveEns',
  ],
  importTokens: <TBlockchain extends EvmBlockchain>(
    blockchain: TBlockchain,
  ) => [blockchain, 'importTokens'],
}

const saveAccounts = async (accounts: EvmAccountStoredData[]): Promise<void> =>
  _saveAccounts('evm', accounts)

export type AddAccountArgs = {
  newAccounts: EvmAccountInfo<EvmBlockchain>[]
}

export const useAddAccounts = () => {
  const enabledEvmBlockchains = useEnabledEvmBlockchains()
  return useMutation(
    mutationKeys.addAccounts(),
    async ({newAccounts}: AddAccountArgs) => {
      const accounts = evm.accountsStore.getAllAccounts()
      const updatedAccounts = await evm.wallet.addAccounts(
        enabledEvmBlockchains,
        accounts,
        newAccounts.map(
          ({cryptoProviderType, derivationParams, publicKey, name}) => ({
            cryptoProviderType,
            derivationParams,
            publicKey,
            name,
          }),
        ),
      )
      await saveAccounts(updatedAccounts)
    },
    {
      invalidationKeys: getEvmCrossChainAccountInvalidationKeys(),
    },
  )
}

export function useVerifyAccountAddress<TBlockchain extends EvmBlockchain>(
  blockchain: TBlockchain,
) {
  return useMutation(
    mutationKeys.verifyAccountAddress(blockchain),
    async (accountId: AccountId) => {
      await getEvmManager(blockchain).accountManager.verifyAddress(
        getEvmManager(blockchain).accountsStore.getAccount(accountId),
      )
    },
  )
}

type SignTransferArguments<TBlockchain extends EvmBlockchain> = {
  fromAccountId: AccountId
} & EvmTxParams<TBlockchain>

export const useSignTransfer = <TBlockchain extends EvmBlockchain>(
  blockchain: TBlockchain,
) =>
  useMutation(
    mutationKeys.signTransfer(blockchain),
    ({fromAccountId, ...txParams}: SignTransferArguments<TBlockchain>) => {
      const {accountManager, accountsStore, networkConfig} =
        getEvmManager(blockchain)

      const accountData = accountsStore.getAccount(fromAccountId)

      return accountManager.signTx(accountData, {
        version: networkConfig.gasConfig.type,
        ...txParams,
      })
    },
  )

type SubmitTransferArguments<TBlockchain extends EvmBlockchain> = {
  signedTx: EvmSignedTransaction<TBlockchain>
  accountId: AccountId
}

export const useSubmitTransfer = <TBlockchain extends EvmBlockchain>(
  blockchain: TBlockchain,
) =>
  useMutation(
    mutationKeys.submitTransfer(blockchain),
    async ({signedTx}: SubmitTransferArguments<TBlockchain>) => {
      if (config.mockEvmSubmission) {
        await sleep(2000)
        return 'mock-blockchain-hash' as EvmTransactionHash<TBlockchain>
      }

      return await getEvmManager(
        blockchain,
      ).blockchainApi.sendAndConfirmTransaction(signedTx.body)
    },
    {
      invalidationKeys: submitTransferInvalidationKeys(blockchain),
      onMutate: ({
        accountId,
        signedTx,
      }: SubmitTransferArguments<TBlockchain>): TransactionContext => ({
        blockchain,
        accountId,
        transactionId: signedTx.txHash,
        nonce: signedTx.meta.nonce,
      }),
    },
  )

export type EnsHandleResolver<TBlockchain extends EvmBlockchain> = (
  address: string,
) => Promise<{
  address: EvmAddress<TBlockchain>
  extras: Record<string, unknown>
} | null>

export async function resolveEns<TBlockchain extends EvmBlockchain>(
  blockchain: TBlockchain,
  name: string,
): ReturnType<EnsHandleResolver<TBlockchain>> {
  try {
    const result =
      await getEvmManager(blockchain).blockchainApi.resolveEnsName(name)
    if (!result) return null
    return {
      address: result,
      extras: {},
    }
  } catch (err) {
    return null
  }
}

// resolver passed from outside to make components using this hook testable
export function useResolveEns<TBlockchain extends EvmBlockchain>(
  blockchain: TBlockchain,
  resolver: EnsHandleResolver<TBlockchain>,
) {
  return useMutation(mutationKeys.resolveEns(blockchain), resolver)
}

export const importEvmTokens = async <TBlockchain extends EvmBlockchain>({
  address,
  tokens,
  blockchain,
}: {
  address: ChecksummedEvmAddress<TBlockchain>
  tokens: EvmTokenMetadata<TBlockchain>[]
  blockchain: TBlockchain
}) => {
  // This is important as we allow adding tokens from multiple connectors windows
  // and otherwise we could loose some writes.
  await TokenImportStoreManagerProvider.instance().reload()
  const {networkConfig} = getEvmManager(blockchain)
  let tokensToSave = TokenImportStoreManagerProvider.instance().getAllTokens()
  for (const _token of tokens) {
    const token = (() => {
      if (_token.tokenStandard === 'ERC20') {
        // We want to avoid storing this property into storage until we figure out how to distinguish
        // stored tokens between chains (testnet / mainnet).
        const {existenceConfirmed: _ignored, ...rest} =
          _token as EvmErc20Metadata<TBlockchain>
        return rest as EvmTokenMetadata<TBlockchain>
      }
      return _token
    })()

    tokensToSave = addToken(tokensToSave, {
      ...token,
      updatedAt: Date.now(),
      chainId: networkConfig.chainId,
      addresses: [address],
    } as EvmProfileTokenImport<TBlockchain>)
  }
  await _saveTokenImports(tokensToSave)

  await sleep(1000) // just for better UI
}

export type ImportEvmTokens = typeof importEvmTokens

export function useImportEvmTokens<TBlockchain extends EvmBlockchain>(
  blockchain: TBlockchain,
  fn: ImportEvmTokens = importEvmTokens,
) {
  return useMutation(
    mutationKeys.importTokens(blockchain),
    async ({
      address,
      tokens,
    }: {
      address: ChecksummedEvmAddress<TBlockchain>
      tokens: EvmTokenMetadata<TBlockchain>[]
    }) => await fn({blockchain, address, tokens}),
    {
      invalidationKeys: tokensInvalidationKeys(blockchain),
    },
  )
}
