import {Box} from '@mui/material'
import {useFormikContext} from 'formik'
import type {PropsWithChildren} from 'react'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {
  CurrentPasswordField,
  FooterLayout,
  ModalFooter,
  ModalLayout,
  ReadOnlyAccountField,
  useModalSharedStyles,
} from '../../../../../../components'
import {useIsPasswordVerificationRequired} from '../../../../../../store/auth'
import type {AccountId, AccountInfo, Blockchain} from '../../../../../../types'
import {ensureAccountById} from '../../../../../../wallet/utils/common'

import type {CommonSchema} from './schema'

type CommonSummaryScreenProps = PropsWithChildren<{
  onBack: () => unknown
  onSubmit: () => unknown
  accounts: AccountInfo[]
  accountId: AccountId
  blockchain: Blockchain
  formId: string
  disabled?: boolean
  ModalHeader: React.ReactNode
}>

export default function CommonSummaryScreen({
  onBack,
  onSubmit,
  accounts,
  accountId,
  blockchain,
  formId,
  disabled,
  ModalHeader,
  children,
}: CommonSummaryScreenProps) {
  const {t} = useTranslation()
  const classes = useModalSharedStyles()
  const account = ensureAccountById(accounts, accountId)
  const needsPw = useIsPasswordVerificationRequired(account)
  const formikProps = useFormikContext<CommonSchema>()

  return (
    <form
      onSubmit={onSubmit}
      noValidate
      id={formId}
      className={classes.formWrapper}
    >
      <ModalLayout
        header={ModalHeader}
        body={
          <Box p={2}>
            <ReadOnlyAccountField
              blockchain={blockchain}
              value={account}
              label={t('Account')}
            />
            <Box className={classes.commonTopMargin}>{children}</Box>
          </Box>
        }
        footer={
          <ModalFooter hasDivider>
            {needsPw && (
              <CurrentPasswordField
                {...{formikProps}}
                className={classes.passwordHeight}
              />
            )}
            <FooterLayout
              leftBtnConf={{
                onClick: onBack,
                children: t('Back'),
              }}
              rightBtnConf={{
                children: t('Submit'),
                type: 'submit',
                form: formId,
                disabled,
              }}
            />
          </ModalFooter>
        }
      />
    </form>
  )
}
