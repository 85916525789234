import {useEffect} from 'react'
import {useHistory} from 'react-router-dom'

import {useAppOpenerStore} from 'src/features/appOpener/application'
import {encodeDefaultAccountId as encodeDefaultDexHunterAccountId} from 'src/pages/dexHunter/DexHunter'
import {routeTo, withQueryString} from 'src/router'
import {useRouteToBuySellAction} from 'src/router/portfolio'

// Hack:
// As `AutoLoginRoutingActions` is rendered at the top level the redirect may
// at times collide with top level `Redirect` which causes the routing transition
// being ignored.
const withDelay = (fn: () => void) => {
  setTimeout(fn, 500)
}

export const AutoLoginRoutingActions = () => {
  const history = useHistory()
  const {action, clearAction} = useAppOpenerStore()
  const routeToBuyAndSell = useRouteToBuySellAction()

  useEffect(() => {
    if (action?.type == null) return
    if (action?.type === 'openExchange') {
      const blockchain = action.blockchain
      const accountId = action.accountId
      withDelay(() =>
        history.push(
          routeTo.portfolio.accounts.account(blockchain, accountId).exchange,
        ),
      )
      clearAction()
    }
    if (action?.type === 'openDexHunter') {
      withDelay(() => {
        const path =
          action.tab === 'history'
            ? routeTo.dexHunter.orderHistory
            : routeTo.dexHunter.index

        const url = withQueryString(
          path,
          encodeDefaultDexHunterAccountId(action.accountId),
        )
        history.push(url)
      })
      clearAction()
    }
    if (action?.type === 'openBuyAndSell') {
      withDelay(() => {
        routeToBuyAndSell(action)
      })
      clearAction()
    }
  }, [action])

  return null
}
