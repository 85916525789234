import {Grid, Typography, Box} from '@mui/material'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {getAvailableBlockchains} from 'src/features/availableBlockchains/application'

import {
  Button,
  Modal,
  SideBarLayout,
  SimpleTimeline,
  CloseButton,
  ModalLayout,
  ModalFooter,
  CenteredError,
  FullScreenLoading,
  ModalHeader,
} from '../../components'
import type {Blockchain} from '../../types'
import {safeAssertUnreachable} from '../../utils/assertion'

import {ChooseBlockchains} from './ChooseBlockchains'
import {useCommonProfileStyles} from './common'
import {TermsAndConditionsCheckbox} from './TermsAndConditionsCheckbox'

type OnLogIntoNewProfile = (blockchains: Blockchain[]) => Promise<void>
type OnLogIntoExistingProfile = () => Promise<void>

type LoginScreenState =
  | {type: 'choose-provider'}
  | {type: 'new-profile'; onSubmit: OnLogIntoNewProfile}
  | {
      type: 'backed-up-profile'
      onSubmit: OnLogIntoNewProfile
    }
  | ({
      type: 'info'
    } & PromptModalProps)

type LoginScreenType = LoginScreenState['type']

type OnExternalProfileLoadParams = {
  isProfileBackedUp: boolean
} & (
  | {onLogIntoExistingProfile: OnLogIntoExistingProfile}
  | {onLogIntoNewProfile: OnLogIntoNewProfile}
)

type ExternalProviderLoginProps = {
  children: ({
    onExternalProfileLoad,
  }: {
    onExternalProfileLoad: (data: OnExternalProfileLoadParams) => void
    setInfoScreen: (
      args: Omit<Extract<LoginScreenState, {type: 'info'}>, 'type'>,
    ) => void
  }) => JSX.Element
  providerLabel: string
  blockchainsSelection?: {
    preselectedBlockchains: Blockchain[]
    disableBlockchainsSelect: true
  }
}

export function ExternalProviderLogin({
  blockchainsSelection,
  children,
  providerLabel,
}: ExternalProviderLoginProps) {
  const defaultScreenState = {type: 'choose-provider' as const}

  const [screen, setScreen] = useState<LoginScreenState>(defaultScreenState)

  const onExternalProfileLoad = ({
    isProfileBackedUp,
    ...rest
  }: OnExternalProfileLoadParams) => {
    const shouldSkipBlockchainSelection = getAvailableBlockchains().length < 2

    try {
      if ('onLogIntoExistingProfile' in rest) {
        rest.onLogIntoExistingProfile()
      } else if (isProfileBackedUp || shouldSkipBlockchainSelection) {
        setScreen({
          type: 'backed-up-profile',
          onSubmit: rest.onLogIntoNewProfile,
        })
      } else {
        setScreen({type: 'new-profile', onSubmit: rest.onLogIntoNewProfile})
      }
    } catch (e) {
      // errors are handled by mutation guards and we track login only if successful
    }
  }

  const setInfoScreen = (
    args: Omit<Extract<LoginScreenState, {type: 'info'}>, 'type'>,
  ) => {
    setScreen((prevState) => ({
      type: 'info',
      ...args,
      onSubmit: () => {
        args.onSubmit()
        // return back after submitting
        setScreen(prevState)
      },
    }))
  }

  return (
    <>
      {(() => {
        switch (screen.type) {
          case 'choose-provider':
            return <>{children({onExternalProfileLoad, setInfoScreen})}</>
          case 'info':
            return <InfoModal {...screen} />
          case 'new-profile':
            return (
              <NewProfileScreen
                providerLabel={providerLabel}
                blockchainsSelection={blockchainsSelection}
                onSubmit={screen.onSubmit}
                onClose={() => setScreen(defaultScreenState)}
              />
            )
          case 'backed-up-profile':
            return (
              <BackedUpProfileScreen
                providerLabel={providerLabel}
                onSubmit={() => screen.onSubmit([])}
                onClose={() => setScreen(defaultScreenState)}
              />
            )
          default:
            return safeAssertUnreachable(screen)
        }
      })()}
    </>
  )
}

type PromptModalProps = {
  onSubmit: () => void
  submitContent: string
  infoContent: JSX.Element
  title?: React.ReactNode
}

const InfoModal = ({
  title,
  infoContent,
  onSubmit,
  submitContent,
}: PromptModalProps) => {
  return (
    <Modal variant="centered" open>
      <ModalLayout
        header={
          title ? <ModalHeader hasDivider>{title}</ModalHeader> : undefined
        }
        body={infoContent}
        footer={
          <ModalFooter hasDivider>
            <Button
              sx={{display: 'flex', mx: 'auto'}}
              variant="contained"
              color="primary"
              onClick={onSubmit}
            >
              {submitContent}
            </Button>
          </ModalFooter>
        }
      />
    </Modal>
  )
}

type NewProfileScreenProps = {
  onClose: () => void
  onSubmit: (blockchains: Blockchain[]) => void
} & Pick<ExternalProviderLoginProps, 'blockchainsSelection' | 'providerLabel'>

const NewProfileScreen = ({
  providerLabel,
  onClose,
  onSubmit: _onSubmit,
  blockchainsSelection,
}: NewProfileScreenProps) => {
  const {t} = useTranslation()
  // terms states: null - not touched, true - allows submit, false - triggers error message
  // we don't want to default to false as it would mean the default form state has error
  const [termsChecked, setTermsChecked] = useState<boolean | null>(null)
  const onSubmit = (blockchains: Blockchain[]) => {
    if (termsChecked) {
      _onSubmit(blockchains)
    } else {
      setTermsChecked(false)
    }
  }

  const TcCheckbox = (
    <TermsAndConditionsCheckbox
      value={!!termsChecked}
      onChange={() => setTermsChecked(!termsChecked)}
      error={termsChecked === false}
      helperText={t('Field must be checked.')}
    />
  )

  return (
    <StepsLayout
      providerLabel={providerLabel}
      step="new-profile"
      onClose={onClose}
    >
      {!blockchainsSelection?.disableBlockchainsSelect ? (
        <ChooseBlockchains
          onSubmit={onSubmit}
          extraContent={
            <Grid item xs={12} marginTop={1.5}>
              {TcCheckbox}
            </Grid>
          }
          preselectedBlockchains={blockchainsSelection?.preselectedBlockchains}
          disabled={termsChecked === false}
          customButtonLabel={t('Create wallet')}
        />
      ) : (
        <>
          {TcCheckbox}
          <Button
            textTransform="none"
            fullWidth
            variant="contained"
            type="submit"
            color="primary"
            onClick={() =>
              onSubmit(blockchainsSelection.preselectedBlockchains)
            }
          >
            {t('Continue')}
          </Button>
        </>
      )}
    </StepsLayout>
  )
}

type BackedUpProfileScreenProps = {
  providerLabel: string
  onClose: () => void
  onSubmit: () => void
}

const BackedUpProfileScreen = ({
  providerLabel,
  onClose,
  onSubmit,
}: BackedUpProfileScreenProps) => {
  const {t} = useTranslation()
  // null state serves as "not touched"
  const [termsChecked, setTermsChecked] = useState<boolean | null>(null)
  const onContinue = () => {
    if (termsChecked) {
      onSubmit()
    } else {
      setTermsChecked(false)
    }
  }

  return (
    <StepsLayout
      providerLabel={providerLabel}
      step="backed-up-profile"
      onClose={onClose}
    >
      <Typography variant="h5">{t('Accept terms and conditions')}</Typography>
      <Grid item xs={12} marginTop={1.5}>
        <Typography variant="body2" color="textSecondary">
          {t(
            'You must agree to the Terms and Conditions to proceed with the wallet creation.',
          )}
        </Typography>
      </Grid>
      <Grid item xs={12} marginTop={1.5}>
        <TermsAndConditionsCheckbox
          value={!!termsChecked}
          onChange={() => setTermsChecked(!termsChecked)}
          error={termsChecked === false}
          helperText={t('Field must be checked.')}
        />
      </Grid>
      <Grid item xs={12} marginTop={1.5}>
        <Button
          textTransform="none"
          fullWidth
          variant="contained"
          color="primary"
          onClick={onContinue}
          disabled={termsChecked === false}
        >
          {t('Continue')}
        </Button>
      </Grid>
    </StepsLayout>
  )
}

type StepsLayoutProps = {
  children: React.ReactNode
  onClose: () => void
  step: Exclude<LoginScreenType, 'info'>
  providerLabel: string
}

const StepsLayout = ({
  children,
  onClose,
  providerLabel,
  step,
}: StepsLayoutProps) => {
  const {t} = useTranslation()
  const commonClasses = useCommonProfileStyles()

  const stepsMap: {[key in typeof step]: string} = {
    'choose-provider': t('auth_via_provider', {provider: providerLabel}),
    'new-profile': t('Choose blockchains'),
    'backed-up-profile': t('Accept terms and conditions'),
  }
  const steps = [stepsMap['choose-provider'], stepsMap[step]]

  return (
    <Modal variant="full-width" open>
      <SideBarLayout
        left={
          <div>
            <Typography variant="h5" textAlign="center">
              {t('Create new wallet')}
            </Typography>
            <Box mt={3}>
              <SimpleTimeline steps={steps} activeStep={stepsMap[step]} />
            </Box>
          </div>
        }
        right={
          <div className={commonClasses.wrapper}>
            <CloseButton
              onClose={onClose}
              sx={{position: 'fixed', top: 20, right: 20}}
            />
            {children}
          </div>
        }
      />
    </Modal>
  )
}

export function LoginErrorModal({providerLabel}: {providerLabel: string}) {
  const {t} = useTranslation()
  const [open, setOpen] = useState(true)
  const onClose = () => setOpen(false)

  return (
    <Modal onClose={onClose} variant="centered" open={open} dismissable>
      <ModalLayout
        body={
          <CenteredError
            error={t('failed_to_log_in_via_external_provider', {
              provider: providerLabel,
            })}
          />
        }
        footer={
          <ModalFooter>
            <Grid container justifyContent="center">
              <Button variant="contained" color="primary" onClick={onClose}>
                {t('Close')}
              </Button>
            </Grid>
          </ModalFooter>
        }
      />
    </Modal>
  )
}

export const AuthStepsLoader = () => (
  <FullScreenLoading
    backdropSx={(theme) => ({zIndex: theme.zIndex.modal + 1})}
  />
)
