import BigNumber from 'bignumber.js'
import _ from 'lodash'
import {useMemo} from 'react'

import type {SortDirection} from 'src/features/assets'
import {assertUnreachable} from 'src/utils/assertion'

import {useGetSwaps} from '../queries'

import type {SwapRow, ExchangeHistorySortBy} from './types'
import {isValidSwapAmount, useStatusTranslations} from './utils'

type SwapRowSortable = SwapRow & {
  amountFromSortable: number
  amountToSortable: number
}

const useOrderByConfig = () => {
  const statusTranslations = useStatusTranslations()
  const orderByConfig: {
    [key in ExchangeHistorySortBy]: {
      compareFn: (row: SwapRowSortable) => string | number | Date
      direction: SortDirection
    }
  } = {
    createdAt: {compareFn: (row) => row.createdAt, direction: 'desc'},
    currencyFrom: {compareFn: (row) => row.currencyFrom, direction: 'asc'},
    currencyTo: {compareFn: (row) => row.currencyTo, direction: 'asc'},
    amountFrom: {compareFn: (row) => row.amountFromSortable, direction: 'desc'},
    amountTo: {compareFn: (row) => row.amountToSortable, direction: 'desc'},
    status: {
      compareFn: (row) => statusTranslations[row.status],
      direction: 'asc',
    },
    sortingLabel: {
      compareFn: (row) => row.sortingLabel.toLocaleLowerCase(),
      direction: 'asc',
    },
  }
  return orderByConfig
}

function useSortedData(
  data: SwapRow[],
  sortBy: ExchangeHistorySortBy,
  direction: SortDirection,
): SwapRow[] {
  const sortableData: SwapRowSortable[] = data.map((row) => ({
    ...row,
    amountFromSortable: new BigNumber(
      isValidSwapAmount(row.amountFrom)
        ? row.amountFrom
        : row.amountExpectedFrom,
    ).toNumber(),
    amountToSortable: new BigNumber(
      isValidSwapAmount(row.amountTo) ? row.amountTo : row.amountExpectedTo,
    ).toNumber(),
  }))
  const orderByConfig = useOrderByConfig()

  return useMemo(() => {
    const compareFns = (() => {
      switch (sortBy) {
        case 'createdAt':
          return [
            orderByConfig.createdAt.compareFn,
            orderByConfig.status.compareFn,
          ]
        case 'currencyFrom':
          return [
            orderByConfig.currencyFrom.compareFn,
            orderByConfig.amountFrom.compareFn,
            orderByConfig.createdAt.compareFn,
          ]
        case 'currencyTo':
          return [
            orderByConfig.currencyTo.compareFn,
            orderByConfig.amountTo.compareFn,
            orderByConfig.createdAt.compareFn,
          ]
        case 'status':
          return [
            orderByConfig.status.compareFn,
            orderByConfig.createdAt.compareFn,
          ]
        case 'sortingLabel':
          return [orderByConfig.sortingLabel.compareFn]
        default:
          return assertUnreachable()
      }
    })()

    const sortDirections: SortDirection[] = (() => {
      switch (sortBy) {
        case 'createdAt':
          return [direction, orderByConfig.status.direction]
        case 'currencyFrom':
          return [direction, direction, orderByConfig.createdAt.direction]
        case 'currencyTo':
          return [direction, direction, orderByConfig.createdAt.direction]
        case 'status':
          return [direction, orderByConfig.createdAt.direction]
        case 'sortingLabel':
          return [orderByConfig.sortingLabel.direction]
        default:
          return ['asc']
      }
    })()

    return _.orderBy(sortableData, compareFns, sortDirections)
  }, [sortableData, data, sortBy, direction, orderByConfig])
}

export function useExchangeHistory(
  sortBy: ExchangeHistorySortBy,
  direction: SortDirection,
) {
  const {data: swaps, isLoading, error} = useGetSwaps()

  const swapRows = useMemo(
    () => swaps?.map((swap) => ({...swap, sortingLabel: 'createdAt'})) || [],
    [swaps],
  )

  return {
    data: useSortedData(swapRows, sortBy, direction),
    isLoading,
    error,
  }
}
