import {Box, Divider} from '@mui/material'
import React from 'react'

import {QueryGuard, LoadStakingDataError} from '../../../../components'
import {useScrollableStyles} from '../../../../utils/layoutUtils'
import {useGetStakeAccounts} from '../../../../wallet/cardano'
import {StakingTabContentLoading} from '../../common/Loadings'

import StakeAccountList from './StakeAccountList'
import StakeSummary from './StakeSummary'

export default function StakeAccountsOverview() {
  const stakeAccountsQuery = useGetStakeAccounts()
  const {scrollableParent} = useScrollableStyles()

  return (
    <QueryGuard
      {...stakeAccountsQuery}
      LoadingElement={<StakingTabContentLoading />}
      ErrorElement={<LoadStakingDataError blockchain="cardano" />}
    >
      {(accounts) => (
        <Box className={scrollableParent}>
          <StakeSummary accounts={accounts} />
          <Divider />
          <StakeAccountList accounts={accounts} />
        </Box>
      )}
    </QueryGuard>
  )
}
