import {alpha} from '@mui/material'
import type {ScriptableContext} from 'chart.js'
import React from 'react'
import {Line} from 'react-chartjs-2'

import type {
  HistoricalConversionRates,
  HistoricalConversionRatesGranularity,
  Currency,
} from 'src/features/conversionRates/domain'

import {formatCurrency} from '../..'
import {colors} from '../../../theme/palette'

const mapData = (
  canvas: HTMLCanvasElement,
  fetchedData: HistoricalConversionRates,
  granularity: HistoricalConversionRatesGranularity,
) => {
  const ctx = canvas.getContext('2d')
  const height = canvas.height
  const gradientStroke = ctx?.createLinearGradient(0, 0, 0, height * 0.75)
  gradientStroke?.addColorStop(0, alpha(colors.primary.main, 0.2))
  gradientStroke?.addColorStop(0.4, alpha(colors.primary.main, 0.1))
  gradientStroke?.addColorStop(1, alpha(colors.primary.main, 0))

  const data = {
    labels: [] as string[],
    datasets: [
      {
        label: '',
        data: [] as number[],
        fill: true,
        borderColor: colors.primary.main,
        backgroundColor: gradientStroke,
        borderWidth: 2.0,
        pointRadius: 0.0,
      },
    ],
  }
  const dataPoints = [] as number[]
  fetchedData.forEach((point) => {
    data.labels.push(formatLabel(new Date(point.timestamp), granularity))
    dataPoints.push(point.rate)
  })
  data.datasets[0]!.data = dataPoints
  return data
}

type RateChartProps = {
  data: HistoricalConversionRates
  currency: Currency
  granularity: HistoricalConversionRatesGranularity
}

export const RateChart = ({data, currency, granularity}: RateChartProps) => (
  <Line
    type="line"
    options={{
      responsive: true,
      maintainAspectRatio: false,
      animation: false,
      autoscale: false,
      interaction: {
        intersect: false,
        mode: 'index',
      },
      scales: {
        x: {
          ticks: {
            maxTicksLimit: 6.1,
            maxRotation: 0,
            minRotation: 0,
            color: colors.text.secondary,
          },
          grid: {
            display: false,
          },
        },
        y: {
          ticks: {
            color: colors.text.secondary,
          },
          grid: {
            color: colors.divider,
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          displayColors: false,
          backgroundColor: 'rgb(256, 256, 256)',
          bodyColor: 'rgb(0, 0, 0)',
          titleColor: 'rgb(0, 0, 0)',
          callbacks: {
            label: (context: ScriptableContext<'line'>) =>
              formatCurrency(context.parsed.y, currency),
          },
        },
      },
    }}
    data={(canvas: HTMLCanvasElement) => mapData(canvas, data, granularity)}
  />
)

const formatLabel = (
  dateTime: Date,
  granularity: HistoricalConversionRatesGranularity,
) => {
  switch (granularity) {
    case '1D':
      return new Intl.DateTimeFormat('default', {
        hour: 'numeric',
        minute: 'numeric',
      }).format(dateTime)
    case '1W':
    case '1M':
      return new Intl.DateTimeFormat('default', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      }).format(dateTime)
    default:
      return new Intl.DateTimeFormat('default').format(dateTime)
  }
}
