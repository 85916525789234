import {assert} from '@nufi/frontend-common'
import BigNumber from 'bignumber.js'
import {Formik} from 'formik'
import React, {useState} from 'react'
import * as yup from 'yup'

import {getBlockchainDecimals} from '../../../../constants'
import type {
  TokenId,
  AccountId,
  AccountInfo,
  TokenMetadata,
} from '../../../../wallet'
import {commonParseTokenAmount, parseNativeAmount} from '../../../../wallet'
import {ensureAccountById} from '../../../../wallet/utils/common'
import type {DetailsAndSummaryTxFlowState} from '../../../transaction/multiStepFormUtils/baseSignTxFlowUtils'
import {useDetailsAndSummaryTxFlowSubmit} from '../../../transaction/multiStepFormUtils/baseSignTxFlowUtils'
import {useSubmitGuard} from '../../../utils/debouncedFormUtils/debouncing'
import type {BaseDetailsSchemaArgs} from '../schema'
import {
  useBaseDetailsSchema,
  useSummaryDetailsSchema,
  useSendAssetsValidation,
  staticInitialSendFormValues,
} from '../schema'
import type {BaseSendSchema as FormSchema} from '../types'
import {getInitialTokenAmountFormValue} from '../utils'

import type {
  CommonSendModalFormProps,
  SendMultipleAssetsTxFlowState,
  MultiAssetSendModalProps,
  SingleAssetSendModalProps,
  MultiOrSingleAssetProps,
  SingleAssetTokenOrNativeProps,
} from './types'

type BaseSendModalFormPros<TSchema extends FormSchema> =
  CommonSendModalFormProps<TSchema> & {
    fallbackAccountId: AccountId
    accounts: Array<AccountInfo>
  }

type TokenTxProps = {
  metadataById: Record<TokenId, TokenMetadata>
  getAvailableTokenBalance: (
    account: AccountInfo,
    tokenId: TokenId,
  ) => BigNumber
  preselectedTokensIds?: TokenId[]
}

type MultiAssetProps<TSchema extends FormSchema> = Pick<
  MultiAssetSendModalProps<TSchema>,
  'children'
> &
  TokenTxProps

type SingleAssetProps<TSchema extends FormSchema> =
  SingleAssetSendModalProps<TSchema> &
    SingleAssetTokenOrNativeProps<TokenTxProps>

export type SendModalFormProps<TSchema extends FormSchema> =
  BaseSendModalFormPros<TSchema> &
    MultiOrSingleAssetProps<MultiAssetProps<TSchema>, SingleAssetProps<TSchema>>

export function SendModalForm<TSchema extends FormSchema>({
  accounts,
  blockchain,
  isAddressValid,
  shouldUseDetailsSubmitGuard = false,
  nameServiceAddressLabel,
  getDefaultAccountId,
  fallbackAccountId,
  overrideBaseDetailsValidationSchema,
  overrideInitialValues,
  getIsAssetAmountRequired,
  ...rest
}: SendModalFormProps<TSchema>) {
  const [screenState, setScreenState] =
    useState<SendMultipleAssetsTxFlowState>('details')
  const hasContextDependantFees = 'getFieldsDependentTxFee' in rest
  const [txFee, setTxFee] = useState(new BigNumber(0))

  const metadataById = 'metadataById' in rest ? rest.metadataById : undefined
  const isTokenTransfer =
    (rest.type === 'singleAsset' && rest.txType === 'token') ||
    rest.type === 'multiAsset'

  const isNativeTransfer =
    (rest.type === 'singleAsset' && rest.txType === 'native') ||
    (rest.type === 'multiAsset' && !rest.preselectedTokensIds?.length)

  const defaultAccountId =
    getDefaultAccountId?.({
      accounts,
      defaultAccountId: fallbackAccountId,
      preselectedTokensIds: isTokenTransfer
        ? rest.preselectedTokensIds
        : undefined,
      tokenBalances: 'tokenBalances' in rest ? rest.tokenBalances : undefined,
    }) || fallbackAccountId

  const getTxFee =
    hasContextDependantFees && rest.getFieldsDependentTxFee
      ? rest.getFieldsDependentTxFee
      : undefined

  const baseDetailsSchemaArgs: BaseDetailsSchemaArgs = {
    isAddressValid,
    nameServiceAddressLabel,
  }

  const baseDetailsSchema = useBaseDetailsSchema(baseDetailsSchemaArgs)

  const ensureTokenDecimals = (tokenId: TokenId) => {
    const decimals = metadataById?.[tokenId]?.decimals
    assert(decimals != null)
    return decimals
  }

  const sendAssetsValidation = useSendAssetsValidation<TSchema>({
    blockchain,
    getAvailableBalance: (accountId, asset, fields) => {
      const account: AccountInfo = ensureAccountById(accounts, accountId)
      if (asset.type === 'token') {
        return 'getAvailableTokenBalance' in rest
          ? rest.getAvailableTokenBalance?.(account, asset.tokenId)
          : new BigNumber(0)
      } else {
        return account.balance.minus(getTxFee?.(fields) || new BigNumber(0))
      }
    },
    getParsedNativeAmount: (fieldAmount) =>
      parseNativeAmount(blockchain, fieldAmount),
    getParsedTokenAmount: (fieldAmount, asset) => {
      return commonParseTokenAmount(
        fieldAmount,
        ensureTokenDecimals(asset.tokenId),
      )
    },
    getDecimals: (asset) => {
      if (asset.type === 'token') {
        return ensureTokenDecimals(asset.tokenId)
      } else {
        return getBlockchainDecimals(blockchain)
      }
    },
    getIsRequired: (asset) => getIsAssetAmountRequired?.(asset) ?? true,
  })

  const _initialValues: TSchema = {
    ...staticInitialSendFormValues,
    accountId: defaultAccountId,
    assets: [
      ...(isNativeTransfer
        ? [
            {
              type: 'native',
              amount: '',
            },
          ]
        : []),
      ...(isTokenTransfer && rest.preselectedTokensIds
        ? rest.preselectedTokensIds
            .filter((tokenId) => {
              // filtering out zero-balanced tokens
              // e.g. token has balance = 0 - for all accounts
              // we won't pre-select it
              if (rest.type === 'multiAsset' && rest.isTokenModal) {
                return (
                  tokenId === rest.tokenId &&
                  rest.tokenBalances
                    .find(({accountId}) => accountId === defaultAccountId)
                    ?.balance.gt(0)
                )
              }
              return true
            })
            .map((tokenId) => ({
              type: 'token' as const,
              tokenId,
              amount: getInitialTokenAmountFormValue(
                rest.metadataById[tokenId],
                rest.getAvailableTokenBalance(
                  ensureAccountById(accounts, defaultAccountId),
                  tokenId,
                ),
              ),
            }))
        : []),
    ],
  } as TSchema

  const initialValues =
    overrideInitialValues?.(_initialValues) || _initialValues

  const summaryDetailsSchema = useSummaryDetailsSchema({accounts})

  const baseDetailsValidation = {
    ...baseDetailsSchema,
    assets: baseDetailsSchema.assets.concat(sendAssetsValidation),
  }
  const detailsValidation =
    overrideBaseDetailsValidationSchema?.(
      baseDetailsValidation,
      metadataById,
    ) || baseDetailsValidation

  const schemas = {
    details: detailsValidation,
    addAssets: detailsValidation,
    summary: summaryDetailsSchema,
  }
  const schema: yup.ObjectSchema<Record<string, unknown>> = yup
    .object()
    .shape(schemas[screenState as keyof typeof schemas] || {})

  const {onPreSubmit: onDetailsPreSubmit, renderSubmitGuard} =
    useSubmitGuard<TSchema>({
      onSubmit: () => setScreenState('summary'),
    })

  const onSubmit = useDetailsAndSummaryTxFlowSubmit<TSchema>(
    screenState as DetailsAndSummaryTxFlowState,
    setScreenState,
    shouldUseDetailsSubmitGuard ? onDetailsPreSubmit : undefined,
  )

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={onSubmit}
    >
      {(formikProps) => {
        const commonProps = {
          fromAccount: ensureAccountById(
            accounts,
            formikProps.values.accountId,
          ),
          schema,
          renderSubmitGuard: shouldUseDetailsSubmitGuard
            ? renderSubmitGuard
            : undefined,
          hasPreselectedTokens: isTokenTransfer
            ? !!rest.preselectedTokensIds
            : false,
          formikProps,
          accounts,
          setTxFee,
          txFee:
            hasContextDependantFees && rest.getFieldsDependentTxFee
              ? rest.getFieldsDependentTxFee(formikProps.values)
              : txFee,
        }
        return rest.type === 'multiAsset'
          ? rest.children({
              ...commonProps,
              ...rest,
              screenState,
              setScreenState,
            })
          : rest.children({
              ...commonProps,
              ...rest,
              screenState: screenState as DetailsAndSummaryTxFlowState,
              setScreenState: setScreenState as (
                state: DetailsAndSummaryTxFlowState,
              ) => void,
            })
      }}
    </Formik>
  )
}
