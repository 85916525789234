import {Box, Typography} from '@mui/material'
import React, {useEffect, useState} from 'react'
import {Trans, useTranslation} from 'react-i18next'

import {usePlatformConfig} from 'src/dappConnector/PlatformConfig'
import type {OpenAppIntent} from 'src/features/appOpener/domain'

import {Alert, Button, TransBold, TransBoldNoBreak} from '../../../components'
import {useDappConnectorStore} from '../../../store/dappConnector'
import {sleep} from '../../../utils/helpers'

type OpeNufiProps = {
  intent: OpenAppIntent
}

export function OpenNufi({intent}: OpeNufiProps) {
  const {t} = useTranslation()
  const {openNuFi} = usePlatformConfig()

  return (
    <Button
      fullWidth
      textTransform="none"
      onClick={() => openNuFi(intent)}
      color="primary"
      variant="contained"
    >
      {t('Open NuFi')}
    </Button>
  )
}

type InitNotPossibleProps = {
  primaryMessage: React.ReactNode
  secondaryMessage: React.ReactNode
  intent: OpenAppIntent
  onChooseOtherProfile?: () => void
  onReload: () => Promise<void>
}

export function InitNotPossible({
  primaryMessage,
  secondaryMessage,
  onChooseOtherProfile,
  onReload: _onReload,
  intent,
}: InitNotPossibleProps) {
  const {t} = useTranslation()
  const [isReloading, setIsReloading] = useState(false)

  const onReload = async () => {
    try {
      setIsReloading(true)
      await sleep(1000) // Better UX with fake sleep
      await _onReload()
    } finally {
      setIsReloading(false)
    }
  }

  return (
    <>
      <Typography variant="h5" textAlign="center">
        {primaryMessage}
      </Typography>
      <Box mt={2} />
      <Alert severity="info">
        <Typography textAlign="left">{secondaryMessage}</Typography>
      </Alert>
      <Box mt={2} />
      <OpenNufi {...{intent}} />
      <Box mt={2} />
      <Button
        fullWidth
        textTransform="none"
        onClick={onReload}
        variant="outlined"
        loading={isReloading}
        disabled={isReloading}
      >
        {t('Refresh')}
      </Button>
      <Box mt={2} />
      {onChooseOtherProfile && (
        <Button
          fullWidth
          textTransform="none"
          onClick={onChooseOtherProfile}
          variant="outlined"
        >
          {t('Choose another wallet')}
        </Button>
      )}
    </>
  )
}

export function ReturnToDappMessage() {
  const {t} = useTranslation()
  const {origin} = useDappConnectorStore()
  return (
    <>
      <Trans
        i18nKey="return_to_dapp"
        t={t}
        components={{
          bold: TransBold,
          safeUrl: TransBoldNoBreak,
        }}
        // We are still XSS protected as we render the `origin` inside
        // <safeUrl /> => which is a react component
        tOptions={{interpolation: {escapeValue: false}}}
        values={{origin}}
      />
    </>
  )
}

export type RememberLoginState = {
  rememberLogin: boolean
  setRememberLogin: (rememberLogin: boolean) => void
}

type WithRememberLoginStateProps = {
  children: (data: RememberLoginState) => React.ReactNode
}

export function WithRememberLoginState({
  children,
}: WithRememberLoginStateProps) {
  // Exposed via render prop pattern instead of a simple hook due to the async nature
  // and the need to handle the state when data data were not loaded yet.
  const {rememberLogin, setRememberLogin} = useDappConnectorStore()
  const {rememberLoginHandlers} = usePlatformConfig()

  useEffect(() => {
    const fn = async () => {
      if (rememberLogin == null) {
        // we use the local storage value just to initialize the "zustand" `rememberLogin` property
        // which from this point on is independent from the persistent `rememberLogin` property
        // in order to not be influenced by future connection windows

        const rememberLogin =
          (await rememberLoginHandlers?.getRememberLogin()) || true
        setRememberLogin(rememberLogin)
      }
    }
    fn()
  }, [])

  if (rememberLogin === null) return null
  return <>{children({rememberLogin, setRememberLogin})}</>
}
