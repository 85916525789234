import type {ProfileData} from './types'

export const DEFAULT_PROFILE_DATA: ProfileData = {
  settings: {
    language: 'en',
    currency: 'USD',
    isHwUser: false,
    isMnemonicActivated: true,
    areAmountsVisible: true,
    updatedAt: 0,
    widgetMeta: {
      hasUsedWidgetBefore: false,
    },
    enabledBlockchains: [],
  },
  accounts: [],
  tokenImports: [],
  tokenVisibilities: [],
}
