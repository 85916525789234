import {Download as ImportIcon} from '@mui/icons-material'
import {Box, Grid, Typography} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import type {CSSProperties} from 'react'
import React, {useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import AutoSizer from 'react-virtualized-auto-sizer'
import {FixedSizeList as VirtualizedList} from 'react-window'

import type {AssetRow, BlockchainBoundedError} from 'src/features/assets'

import {tokenBlockchains} from '../../../blockchainTypes'
import {Alert, Button} from '../../../components'
import type {Blockchain} from '../../../types'
import {
  assert,
  assertUnreachable,
  safeAssertUnreachable,
} from '../../../utils/assertion'
import {BlockchainSubsetGuard} from '../../../utils/blockchainGuards'
import {useGetBlockchainName} from '../../../utils/translations'
import ImportToken from '../account/actions/importToken/ImportToken'

import {ASSET_ITEM_HEIGHT} from './AssetItemUtils'
import {AssetItemLoading} from './Loadings'
import {NativeAssetItem} from './NativeAssetItem'
import {TokenAssetItem} from './TokenAssetItem'

type AssetsListProps = {
  data: AssetRow[]
  partialLoadings: Blockchain[]
  errors: BlockchainBoundedError[]
  blockchain: Blockchain | null
}

export function AssetsList({
  data,
  partialLoadings,
  errors,
  blockchain,
}: AssetsListProps) {
  const {t} = useTranslation()
  const [open, setOpen] = useState(false)

  const virtualizedListData: VirtualizedRowData = useMemo(() => {
    const _data = data.map((dataRow) => ({
      type: 'data' as const,
      data: dataRow,
    }))

    const _virtualizedListData: VirtualizedRowData = [
      ..._data,
      ...errors
        .filter((e) => !blockchain || e.blockchain === blockchain)
        .map((error) => ({type: 'error' as const, error})),
    ]
    if (
      partialLoadings.filter((b) => blockchain === null || b === blockchain)
        .length > 0
    ) {
      // Using two loading rows as it looks better than one & avoiding one large loading
      // item, to render all rows with the same width which leads to simpler virtualization
      _virtualizedListData.push({type: 'loading'})
      _virtualizedListData.push({type: 'loading'})
    }
    return _virtualizedListData
  }, [data, errors, partialLoadings, blockchain])

  return (
    <AutoSizer>
      {({height, width}) =>
        virtualizedListData.length === 0 ? (
          <Grid
            container
            direction="column"
            spacing={2}
            alignItems="center"
            justifyContent="center"
            {...{width, height}}
          >
            <Grid item>
              <Typography
                variant="h5"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                {t('No assets matching current criteria')}
              </Typography>
            </Grid>
            <Grid item>
              <Box>
                {open && (
                  <ImportToken
                    blockchain={blockchain || tokenBlockchains[0]}
                    onClose={() => setOpen(false)}
                  />
                )}
                <Button
                  variant="contained"
                  textTransform="none"
                  size="large"
                  color="primary"
                  onClick={() => setOpen(true)}
                  startIcon={<ImportIcon />}
                >
                  {t('Import token')}
                </Button>
              </Box>
            </Grid>
          </Grid>
        ) : (
          <VirtualizedList
            itemData={virtualizedListData}
            itemCount={virtualizedListData.length}
            // `8` is used for space between items
            itemSize={ASSET_ITEM_HEIGHT + 8}
            // without subtracting the `-20` strange flickering occurs
            height={height - 20}
            width={width}
            overscanCount={10}
          >
            {VirtualizedRow}
          </VirtualizedList>
        )
      }
    </AutoSizer>
  )
}

type VirtualizedRowData = Array<
  | {
      type: 'data'
      data: AssetRow
    }
  | {
      type: 'loading'
    }
  | {
      type: 'error'
      error: BlockchainBoundedError
    }
>

type VirtualizedRowProps = {
  index: number
  style: CSSProperties
  data: VirtualizedRowData
}

const VirtualizedRow = React.memo(
  ({index, style, data}: VirtualizedRowProps) => {
    const row = data[index]!

    return (
      <div style={style}>
        {(() => {
          switch (row.type) {
            case 'data': {
              const assetRow = row.data
              return !assetRow.tokenMetadata ? (
                <NativeAssetItem {...assetRow} />
              ) : (
                <BlockchainSubsetGuard
                  blockchain={assetRow.blockchain}
                  blockchainSubset={tokenBlockchains}
                >
                  {(tokenBlockchain) => {
                    assert(!!assetRow.tokenMetadata)
                    return (
                      <TokenAssetItem
                        {...assetRow}
                        tokenMetadata={assetRow.tokenMetadata}
                        blockchain={tokenBlockchain}
                      />
                    )
                  }}
                </BlockchainSubsetGuard>
              )
            }
            case 'error':
              return <LoadAssetsError error={row.error} />
            case 'loading':
              return <AssetItemLoading />
            default:
              return assertUnreachable()
          }
        })()}
      </div>
    )
  },
)

export function LoadAssetsError({error}: {error: BlockchainBoundedError}) {
  const {t} = useTranslation()
  const classes = useStyles()
  const getBlockchainName = useGetBlockchainName()

  const errorText = (() => {
    switch (error.type) {
      case 'native':
        return t('could_not_load_native_portfolio_data', {
          blockchain: getBlockchainName(error.blockchain),
        })
      case 'tokens':
        return t('could_not_load_tokens_portfolio_data', {
          blockchain: getBlockchainName(error.blockchain),
        })
      default:
        return safeAssertUnreachable(error.type)
    }
  })()

  return (
    <Alert className={classes.errorWrapper} severity="error">
      {/* <Alert /> message centering does not work well when using fixed height from outside
        thus also centering `children` from outside. */}
      <Box display="flex" alignItems="center" height="100%">
        <Typography>{errorText}</Typography>
      </Box>
    </Alert>
  )
}

const useStyles = makeStyles(() => ({
  errorWrapper: {
    height: ASSET_ITEM_HEIGHT,
  },
}))
