import type {NufiMessage} from '@nufi/dapp-client-core'
import {decrypt, encrypt} from '@nufi/storage'
import type {CredentialsTransferKey} from '@nufi/storage'

import type {LocalProfileId, ProfileData, ProfilePassword} from 'src/appStorage'
import type {MasterProfileManager} from 'src/appStorage/masterProfileManager'
import type {LocalProfileManager} from 'src/appStorage/profileManager/localProfileManager'
import type {AuthState, Web3AuthUserInfo} from 'src/features/login/domain'
import type {Mnemonic} from 'src/types'
import {assert, safeAssertUnreachable} from 'src/utils/assertion'

import type {SingleKeyAsyncStorage} from '../../storage/domain'

export type TransferLoginDataResponse = {
  encryptionKey: CredentialsTransferKey
  profileData?: ProfileData
}

export type ReferrerInfo =
  | {platform: 'extension'; referrerTabId: number}
  | {platform: 'web'; customChildWindowId: string}

export type AutoLoginApi = {
  requestTransferAutoLoginData: (
    referrerInfo: ReferrerInfo,
  ) => Promise<TransferLoginDataResponse>
}

export type AutoLoginStorageData = {
  profileId: LocalProfileId
  encryptedData: string
}

export type AutoLoginStorage = SingleKeyAsyncStorage<AutoLoginStorageData>

export type TransferAutoLoginDataRequestMessage = NufiMessage & {
  method: 'transferAutoLoginDataRequest'
  customChildWindowId: string
}

export type TransferAutoLoginDataResponseMessage = NufiMessage & {
  method: 'transferAutoLoginDataResponse'
  secret: string
  profileData?: ProfileData
}

export type Web3AuthData = {
  userInfo: Web3AuthUserInfo
  mnemonic: Mnemonic
}

export type AutoLoginData =
  | {
      loginType: 'password'
      password: ProfilePassword
      profileId: LocalProfileId
    }
  | {
      loginType: 'web3Auth'
      password: ProfilePassword
      web3AuthData: Web3AuthData
      profileData?: ProfileData
      // Not providing profileId, as this method
      // is also expected to be used from Widget when we
      // face issues with sandboxed storages.
      // Therefore the profileId will not match.
    }

export type GetAutoLoginData = (
  referrerInfo: ReferrerInfo,
) => Promise<AutoLoginData>

export const createGetAutoLoginData =
  (autoLoginApi: AutoLoginApi, storage: AutoLoginStorage): GetAutoLoginData =>
  async (referrerInfo) => {
    try {
      const {encryptionKey, profileData} =
        await autoLoginApi.requestTransferAutoLoginData(referrerInfo)
      const data = await storage.get()
      await storage.remove()

      assert(data?.profileId != null)
      assert(data?.encryptedData != null)

      const decrypted = (await decrypt({
        password: encryptionKey,
        value: data?.encryptedData,
      })) as AutoLoginData
      return {profileId: data.profileId, profileData, ...decrypted}
    } catch (err) {
      // Ensure that storage gets cleared in all cases
      await storage.remove()
      throw err
    }
  }

type GetAuthState = () => AuthState

export const createInjectAutoLoginData =
  (
    localProfileManager: LocalProfileManager,
    masterProfileManager: MasterProfileManager,
    autoLoginStorage: AutoLoginStorage,
    getAuthState: GetAuthState,
    // Needs to be a function because otherwise tests complain about `crypto` not being
    // defined - I suppose we load `crypto` asynchronously somewhere and it's not defined
    // when global scope is being initialized or something.
    getEncryptionKey: () => string = () => crypto.randomUUID(),
  ) =>
  async () => {
    const password = localProfileManager.exposePassword('autoLogin')
    const profileId = masterProfileManager.getCurrentProfileId()
    assert(profileId != null, 'Must be logged in to transfer password')

    const authState = getAuthState()
    assert(authState.status === 'logged_in')

    const loginType = authState.info.loginType

    const autoLoginData: AutoLoginData = await (async () => {
      switch (loginType) {
        case 'password':
          return {loginType: 'password', password, profileId}
        case 'web3Auth': {
          return {
            loginType: 'web3Auth',
            password,
            web3AuthData: {
              mnemonic: await localProfileManager.getMnemonic(),
              userInfo: authState.info.user,
            },
          }
        }
        case 'metamask':
          throw new Error('Unsupported')
        default:
          return safeAssertUnreachable(loginType)
      }
    })()

    const encryptionKey = getEncryptionKey() as CredentialsTransferKey
    const encryptedData = await encrypt({
      value: autoLoginData,
      password: encryptionKey,
    })
    await autoLoginStorage.set({
      profileId,
      encryptedData,
    })

    const response: TransferLoginDataResponse = {
      encryptionKey,
    }
    return response
  }
