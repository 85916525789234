import {Grid} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import type {SortDirection} from 'src/features/assets'
import type {ExchangeHistorySortBy} from 'src/features/swaps/application'

import {
  Aligner,
  ListLayoutWrapper,
  ListHeaderWrapper,
  NoOverflowAligner,
  SortableHeader,
} from '../../../components'

type ExchangeHistoryListHeaderProps = {
  sortBy: ExchangeHistorySortBy
  setSortBy: (v: ExchangeHistorySortBy) => void
  sortDirection: SortDirection
  defaultSortDirection: SortDirection
}

export function ExchangeHistoryListHeader({
  sortBy,
  setSortBy,
  sortDirection,
  defaultSortDirection,
}: ExchangeHistoryListHeaderProps) {
  const {t} = useTranslation()
  const sortableHeaderProps = {
    sortDirection,
    sortBy,
    setSortBy,
    defaultSortDirection,
  }

  return (
    <ListHeaderWrapper>
      <ExchangeHistoryListItemLayout
        createdAtCol={
          <SortableHeader
            label={t('Date')}
            column="createdAt"
            {...sortableHeaderProps}
          />
        }
        fromCol={
          <SortableHeader
            label={t('From')}
            column="currencyFrom"
            {...sortableHeaderProps}
          />
        }
        toCol={
          <SortableHeader
            label={t('To')}
            column="currencyTo"
            {...sortableHeaderProps}
          />
        }
        statusCol={
          <SortableHeader
            label={t('Status')}
            column="status"
            {...sortableHeaderProps}
          />
        }
      />
    </ListHeaderWrapper>
  )
}

type ExchangeHistoryListItemLayoutProps = {
  createdAtCol: React.ReactNode
  fromCol: React.ReactNode
  toCol: React.ReactNode
  statusCol: React.ReactNode
}

export function ExchangeHistoryListItemLayout({
  createdAtCol,
  fromCol,
  toCol,
  statusCol,
}: ExchangeHistoryListItemLayoutProps) {
  return (
    <ListLayoutWrapper>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        spacing={1.5}
      >
        <Grid item xs={2}>
          <NoOverflowAligner align="left">{createdAtCol}</NoOverflowAligner>
        </Grid>
        <Grid item xs={4}>
          <Aligner align="right">{fromCol}</Aligner>
        </Grid>
        <Grid item xs={4}>
          <Aligner align="right">{toCol}</Aligner>
        </Grid>
        <Grid item xs={2}>
          <Aligner align="right">{statusCol}</Aligner>
        </Grid>
      </Grid>
    </ListLayoutWrapper>
  )
}
