import type {
  CardanoNetwork,
  BlockchainApi,
  HotCryptoProvider,
  CardanoAccountNetworkInfo,
  CardanoAccountOfflineInfo,
  CardanoAccountStoredData,
} from '@nufi/wallet-cardano'
import {
  CardanoConnection,
  getNetworkInfo,
  BlockchainApiImpl,
  MnemonicCryptoProvider,
  TrezorCryptoProvider,
  LedgerCryptoProvider,
  MetamaskCryptoProvider,
  AdaHandleImpl,
  BlockfrostApiImpl,
  AdaDomainsImpl,
  NftApiImpl,
  AccountSource,
  CardanoAccountManager,
  CardanoWallet,
} from '@nufi/wallet-cardano'

import type {ProfileLoginType} from 'src/appStorage'
import {isLocalProfileLoginType} from 'src/appStorage/profileManager/localProfileManager/utils'
import {createServiceLocator} from 'src/utils/serviceLocator'

import config from '../../config'
import {useCardanoStore} from '../../store/wallet'
import {
  AccountsStoreManagerImpl,
  addStandardAccounts as addAccounts,
} from '../AccountsStoreManager'
import {localSecretProvider} from '../secretProvider'
import {createAccountInfosUtils} from '../utils/walletUtils'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function _createCardanoManagers(
  network: CardanoNetwork,
  blockchainApi: BlockchainApi,
  loginType: ProfileLoginType,
) {
  const nftUrl = config.cardanoBackendUrl
  const nftApi = new NftApiImpl(nftUrl)

  const hotCryptoProvider = ((): HotCryptoProvider => {
    if (isLocalProfileLoginType(loginType)) {
      return new MnemonicCryptoProvider(localSecretProvider)
    }
    if (loginType === 'metamask') {
      return new MetamaskCryptoProvider()
    }
    throw new Error('Unsupported login type supplied')
  })()

  const ledgerCryptoProvider = new LedgerCryptoProvider()
  const trezorCryptoProvider = new TrezorCryptoProvider()

  const accountsStore = new AccountsStoreManagerImpl<CardanoAccountStoredData>(
    useCardanoStore,
  )

  const accountManager = new CardanoAccountManager(network, {
    blockchainApi,
    nftApi,
    hotCryptoProvider,
    ledgerCryptoProvider,
    trezorCryptoProvider,
  })

  const accountSource = new AccountSource(
    accountManager,
    blockchainApi,
    network,
  )

  const adaHandle = new AdaHandleImpl(
    getNetworkInfo(network).adaHandlePolicyId,
    blockchainApi,
    new BlockfrostApiImpl(
      `${config.cardanoBlockfrostUrl}/api/v0`,
      config.cardanoBlockfrostApiKey,
    ),
  )

  const adaDomains = new AdaDomainsImpl(
    config.cardanoBlockfrostApiKey,
    `${config.cardanoBlockfrostUrl}/api/v0`,
    network,
  )

  const wallet = new CardanoWallet(network, {
    accountManager,
    accountSource,
    hotCryptoProvider,
    ledgerCryptoProvider,
    trezorCryptoProvider,
    blockchainApi,
    nftApi,
    addAccounts,
  })

  const accountInfoUtils = createAccountInfosUtils<
    CardanoAccountStoredData,
    CardanoAccountNetworkInfo,
    CardanoAccountOfflineInfo
  >(accountManager)

  return {
    wallet,
    accountManager,
    adaHandle,
    adaDomains,
    accountsStore,
    ...accountInfoUtils,
  }
}

const backendUrl = config.cardanoBackendUrl
const cardanoBackendConnection = new CardanoConnection(backendUrl)

export const {init: initCardanoWallet, instance: getCardano} =
  createServiceLocator(
    (
      loginType: ProfileLoginType,
    ): ReturnType<typeof _createCardanoManagers> => {
      return _createCardanoManagers(
        config.cardanoNetwork,
        new BlockchainApiImpl(cardanoBackendConnection),
        loginType,
      )
    },
  )

export type Cardano = ReturnType<typeof getCardano>
