import BigNumber from 'bignumber.js'
import _ from 'lodash'
import React from 'react'
import {useLocation} from 'react-router-dom'

import {useLastDefinedData} from 'src/utils/query-utils'

import {
  BatchQueryGuard,
  TransactionModalError,
  QueryGuard,
} from '../../../../components'
import {
  useBaseActionRouteOptions,
  useTokenIdFromRoute,
} from '../../../../router/portfolio'
import type {
  TokenId,
  TokenMetadata,
  AccountId,
  AccountInfo,
} from '../../../../wallet'
import type {BaseSendSchema} from '../types'

import {SendModalForm} from './SendMultipleAssetsModalForm'
import type {
  CommonSendModalFormProps,
  MultiAssetSendModalProps,
  SingleAssetSendModalProps,
  SendMultiAssetFormInjectedProps,
  SendSingleAssetFormInjectedPros,
  MultiOrSingleAssetProps,
} from './types'
import {sendModalViewModel} from './viewModel'
import type {SendModalViewModel} from './viewModel'
import {SendModalVMProvider, useSendModalVM} from './viewModelProvider'

type MultiAssetProps<T extends BaseSendSchema> = {
  children: (data: SendMultiAssetFormInjectedProps<T>) => JSX.Element
} & Pick<MultiAssetSendModalProps<T>, 'getAvailableTokenBalance'>

type SingleAssetProps<T extends BaseSendSchema> = {
  children: (data: SendSingleAssetFormInjectedPros<T>) => JSX.Element
}

export type SendModalProps<T extends BaseSendSchema> = {
  vm?: SendModalViewModel
} & CommonSendModalFormProps<T> &
  MultiOrSingleAssetProps<MultiAssetProps<T>, SingleAssetProps<T>>

export function SendModal<T extends BaseSendSchema>(props: SendModalProps<T>) {
  const vm = props.vm ?? sendModalViewModel
  const {accountId} = useBaseActionRouteOptions()
  const tokenId = useTokenIdFromRoute()
  const {accountsQuery} = useLastDefinedData({
    accountsQuery: vm.useGetAccounts(props.blockchain),
  })

  return (
    <SendModalVMProvider vm={vm}>
      <QueryGuard
        {...accountsQuery}
        ErrorElement={<TransactionModalError />}
        loadingVariant="centered"
      >
        {(accounts) => {
          const commonProps = {accounts, accountId}
          return props.type === 'multiAsset' ? (
            <SendMultipleAssetsModal
              {...commonProps}
              {...props}
              tokenId={tokenId}
            />
          ) : tokenId ? (
            <SendTokenModal {...commonProps} {...props} tokenId={tokenId} />
          ) : (
            <SendModalForm
              {...{
                ...props,
                txType: 'native',
                accounts,
                fallbackAccountId: accountId,
              }}
            />
          )
        }}
      </QueryGuard>
    </SendModalVMProvider>
  )
}

type CommonSendModalProps = {
  tokenId: TokenId
  accountId: AccountId
  accounts: AccountInfo[]
}

function SendMultipleAssetsModal<T extends BaseSendSchema>({
  blockchain,
  tokenId,
  accountId,
  accounts,
  ...rest
}: CommonSendModalFormProps<T> &
  MultiAssetSendModalProps<T> &
  Omit<CommonSendModalProps, 'tokenId'> & {tokenId?: TokenId}) {
  const vm = useSendModalVM()
  const location = useLocation<{selectedNfts?: TokenId[]}>()
  const selectedNfts = location.state?.selectedNfts
  const {tokensMetadataQuery} = useLastDefinedData({
    tokensMetadataQuery: vm.useGetTokensMetadata(blockchain),
  })
  const {tokenBalancesQuery} = useLastDefinedData({
    tokenBalancesQuery: vm.useGetBalancesPerAccount(
      blockchain,
      tokenId || null,
      !!tokenId,
    ),
  })
  const preselectedTokensIds = selectedNfts || (tokenId ? [tokenId] : undefined)

  return (
    <QueryGuard
      {...tokensMetadataQuery}
      ErrorElement={<TransactionModalError />}
      loadingVariant="centered"
    >
      {(tokensMetadata) => {
        const metadataById = _.keyBy<TokenMetadata>(tokensMetadata, (m) => m.id)
        const commonProps = {
          type: 'multiAsset' as const,
          metadataById,
          accounts,
          blockchain,
          preselectedTokensIds,
          fallbackAccountId: accountId,
          ...rest,
        }
        if (tokenId) {
          return (
            <QueryGuard
              {...tokenBalancesQuery}
              ErrorElement={<TransactionModalError />}
              loadingVariant="centered"
            >
              {(tokenBalances) => {
                return (
                  <SendModalForm
                    isTokenModal
                    {...{...commonProps, tokenId, tokenBalances}}
                  />
                )
              }}
            </QueryGuard>
          )
        } else return <SendModalForm {...commonProps} />
      }}
    </QueryGuard>
  )
}

function SendTokenModal<T extends BaseSendSchema>({
  blockchain,
  tokenId,
  accountId,
  accounts,
  ...rest
}: CommonSendModalFormProps<T> &
  SingleAssetSendModalProps<T> &
  CommonSendModalProps) {
  const vm = useSendModalVM()
  const {tokenData, tokenBalances} = useLastDefinedData({
    tokenData: vm.useGetTokenMetadata(tokenId, blockchain),
    tokenBalances: vm.useGetBalancesPerAccount(blockchain, tokenId),
  })

  return (
    <BatchQueryGuard
      queries={{
        tokenBalances,
        tokenData,
      }}
      ErrorElement={<TransactionModalError />}
      loadingVariant="centered"
    >
      {({tokenBalances, tokenData}) =>
        tokenData && (
          <SendModalForm
            {...{
              type: 'singleAsset',
              metadataById: {[tokenData.id]: tokenData},
              getDecimals: () => tokenData.decimals,
              accounts,
              blockchain,
              fallbackAccountId: accountId,
              preselectedTokensIds: [tokenId],
              txType: 'token',
              tokenBalances,
              tokenId,
              ...rest,
            }}
            getAvailableTokenBalance={(account) =>
              tokenBalances.find((a) => a.accountId === account.id)?.balance ||
              new BigNumber(0)
            }
          />
        )
      }
    </BatchQueryGuard>
  )
}
