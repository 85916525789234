import {useQuery} from '@tanstack/react-query'
import type {SwapAsset} from 'common'
import _ from 'lodash'

import type {
  ExchangeConditions,
  ExchangeParams,
} from 'src/features/exchange/domain'
import {
  decreaseMaxAmountByOffset,
  increaseMinAmountByOffset,
} from 'src/features/exchange/domain'
import {fetchNeverStaleQuery, useNeverStaleQuery} from 'src/utils/query-utils'

import queryClient from '../../../queryClient'

import {exchangeApi} from './exchangeApi'

const P = 'exchange'

export const queryKeys = {
  getExchangeParams: (from: SwapAsset, to: SwapAsset) => [
    P,
    'exchangeParams',
    {from, to},
  ],
  getExchangeAssetsDetails: [P, 'exchangeAssetsDetails'],
  getExchangeConditions: ({from, to, amountFrom}: ExchangeConditionsParams) => [
    P,
    'exchangeConditions',
    {from, to, amountFrom},
  ],
}

async function getExchangeParams(
  from: SwapAsset,
  to: SwapAsset,
): Promise<ExchangeParams> {
  const exactExchangeParams = await exchangeApi.getExchangeParams(from, to)
  return {
    maxAmount: decreaseMaxAmountByOffset(
      exactExchangeParams.maxAmount,
      exactExchangeParams.maxAmount.decimalPlaces(),
    ),
    minAmount: increaseMinAmountByOffset(
      exactExchangeParams.minAmount,
      exactExchangeParams.minAmount.decimalPlaces(),
    ),
  }
}

export function useGetExchangeParams(
  from: SwapAsset,
  to: SwapAsset,
  enabled = true,
) {
  return useQuery({
    queryKey: queryKeys.getExchangeParams(from, to),
    queryFn: () => getExchangeParams(from, to),
    enabled,
  })
}

export async function getExchangeParamsImperative(
  from: SwapAsset,
  to: SwapAsset,
) {
  return await queryClient.fetchQuery({
    queryKey: queryKeys.getExchangeParams(from, to),
    queryFn: () => getExchangeParams(from, to),
  })
}

function getExchangeAssetsDetails() {
  return exchangeApi.getExchangeAssetsDetails()
}

export function useGetExchangeAssetsDetails() {
  return useNeverStaleQuery({
    queryKey: queryKeys.getExchangeAssetsDetails,
    queryFn: getExchangeAssetsDetails,
  })
}

export async function cachedGetExchangeAssetsDetails() {
  return await fetchNeverStaleQuery({
    queryKey: queryKeys.getExchangeAssetsDetails,
    queryFn: getExchangeAssetsDetails,
  })
}

type ExchangeConditionsParams = {
  from: SwapAsset
  to: SwapAsset
  amountFrom: ExchangeConditions['amountFrom']
}

function getExchangeConditions({
  from,
  to,
  amountFrom,
}: ExchangeConditionsParams) {
  return exchangeApi.getExchangeConditions(from, to, amountFrom)
}

export function useGetExchangeConditions({
  from,
  to,
  amountFrom,
  enabled = true,
}: ExchangeConditionsParams & {enabled?: boolean}) {
  return useQuery({
    queryKey: queryKeys.getExchangeConditions({from, to, amountFrom}),
    queryFn: () => getExchangeConditions({from, to, amountFrom}),
    refetchOnWindowFocus: false,
    enabled,
  })
}

const _validateExchangeAddress = _.memoize(
  (currency: SwapAsset, address: string) =>
    exchangeApi.validateAddress(currency, address),
  (currency, address) => currency + address,
)

export const validateExchangeAddress = async (
  currency: SwapAsset,
  address: string,
) => {
  try {
    return await _validateExchangeAddress(currency, address)
  } catch {
    return {result: false}
  }
}
