import {Box, Grid, Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React from 'react'

import {MultilineString} from '../../../components'
import {useEllipsisStyles} from '../../../utils/layoutUtils'

type AboutRowProps = {
  label: string
  value: string | React.ReactNode
  ellipsisValue?: boolean
}

export const AboutRow = ({label, value, ellipsisValue}: AboutRowProps) => {
  const classes = {...useStyles(), ...useEllipsisStyles()}

  return (
    <Grid container justifyContent="space-between" item wrap="nowrap">
      <Grid item mr={4}>
        <Typography noWrap color="textSecondary">
          {label}
        </Typography>
      </Grid>
      <Grid item>
        <Box {...(ellipsisValue ? {maxWidth: 150} : {})}>
          {typeof value === 'string' ? (
            <MultilineString
              className={ellipsisValue ? classes.ellipsis : classes.multiline}
              label={value}
            />
          ) : (
            value
          )}
        </Box>
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles(() => ({
  multiline: {
    wordBreak: 'break-word',
    textAlign: 'justify',
  },
}))
