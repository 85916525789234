import {Star} from '@mui/icons-material'
import {
  Grid,
  FormControl,
  Divider,
  ListSubheader,
  Typography,
  Paper,
  Box,
} from '@mui/material'
import type {FilterOptionsState} from '@mui/material/useAutocomplete'
import {createFilterOptions} from '@mui/material/useAutocomplete'
import makeStyles from '@mui/styles/makeStyles'
import _ from 'lodash'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {LabeledIcon} from './LabeledIcon'
import SearchableSelect from './SearchableSelect'
import type {RenderOptionResult} from './SearchableSelect'

type ValidatorSelectProps<T> = {
  onChange: (value: T | null) => void
  recommendedOption: T
  recommendedGroupLabel: string
  otherGroupLabel: string
  options: Array<T>
  getOptionId: (option: T) => string
  label: string
  labelWhenSelected?: string
  getOptionKeyword: (option: T) => string
  getOptionLabel: (option: T) => string
  getOptionValid: (id: string) => boolean
  createNotFoundOptionFromInput: ((input: string) => T) | null
  className?: string
  helperText?: React.ReactNode
  error?: boolean
  value: T | null
  // renderOption: only affects how is the option displayed in the list,
  // selected option is displayed using getOptionLabel
  renderOption: (option: T) => React.ReactNode
}

export function ValidatorSelect<T>({
  onChange,
  label,
  labelWhenSelected,
  recommendedOption,
  recommendedGroupLabel,
  otherGroupLabel,
  options,
  getOptionId,
  getOptionKeyword,
  getOptionLabel,
  getOptionValid,
  createNotFoundOptionFromInput,
  className = '',
  helperText,
  error,
  value,
  renderOption,
}: ValidatorSelectProps<T>) {
  const classes = useStyles()
  const {t} = useTranslation()
  let invalidOption: string | null = null

  const filterOptions = (options: T[], state: FilterOptionsState<T>) => {
    // NOTE: in order to display the recommended option, the option has to be fist in list
    const allOptions = createFilterOptions<T>({
      stringify: getOptionKeyword,
    })(options, state)

    if (allOptions.length === 0) {
      if (getOptionValid(state.inputValue) && !!createNotFoundOptionFromInput) {
        invalidOption = null
        return [
          recommendedOption,
          createNotFoundOptionFromInput(state.inputValue),
        ]
      }
      invalidOption = state.inputValue
    } else {
      invalidOption = null
    }

    const otherOptions = _.differenceBy(
      allOptions,
      [recommendedOption],
      (option) => getOptionId(option),
    )

    return [recommendedOption, ...otherOptions]
  }

  const renderInvalidOption = () => (
    <Box className={classes.invalidOption}>
      <Typography
        color={'textSecondary'}
        variant="body1"
        className={classes.name}
      >
        {t('No results found for')}: "{invalidOption}"
      </Typography>
    </Box>
  )

  return (
    <>
      <FormControl variant="outlined" fullWidth className={className}>
        <SearchableSelect
          {...{
            onChange,
            getOptionLabel,
            label,
            labelWhenSelected,
            value,
            helperText,
            error,
          }}
          renderGroup={(params) => ({type: 'div', props: null, ...params})}
          options={options}
          filterOptions={filterOptions}
          searchable
          PaperComponent={({children}) => (
            <Paper sx={{pb: 2, bgcolor: 'background.default'}}>
              {children}
              {invalidOption && renderInvalidOption()}
            </Paper>
          )}
          isOptionEqualToValue={(option, value) =>
            JSON.stringify(option) === JSON.stringify(value)
          }
          customRenderRow={(props, renderRow) => {
            const {children, ...other} = props
            // assuming first child is recommended
            // make sure we accidentally don't recommend Group label
            const recommendedOptionIndex = children.findIndex(
              (e) => !('group' in e),
            )
            const otherOptions = [
              ...children.slice(0, recommendedOptionIndex),
              ...children.slice(recommendedOptionIndex + 1),
            ]
            const _renderRow = (option: T) => renderOption(option)

            return (
              <>
                {recommendedOption ? (
                  <div {...other}>
                    <RecommendedOption
                      recommendedOptionComponent={
                        children[
                          recommendedOptionIndex
                        ] as TRecommendedOption<T>
                      }
                      recommendedGroupLabel={recommendedGroupLabel}
                      otherGroupLabel={otherGroupLabel}
                      renderOption={renderOption}
                    />
                  </div>
                ) : (
                  <></>
                )}
                {otherOptions.length ? (
                  renderRow(
                    _renderRow,
                    otherOptions.map((o) => ({type: 'div', key: null, ...o})),
                  )
                ) : (
                  <></>
                )}
              </>
            )
          }}
        />
      </FormControl>
    </>
  )
}

type TRecommendedOption<T> = Extract<RenderOptionResult<T>, {option: T}>

type RecommendedOptionProps<T> = {
  recommendedOptionComponent: TRecommendedOption<T>
  recommendedGroupLabel: string
  otherGroupLabel: string
  renderOption: (option: T) => React.ReactNode
}
function RecommendedOption<T>({
  recommendedOptionComponent,
  recommendedGroupLabel,
  otherGroupLabel,
  renderOption,
}: RecommendedOptionProps<T>) {
  const classes = useStyles()
  return (
    <>
      <ListSubheader sx={{bgcolor: 'background.default'}}>
        {recommendedGroupLabel}
      </ListSubheader>
      <Grid
        {...recommendedOptionComponent.props}
        component="li"
        container
        justifyContent="space-between"
        onMouseDown={(e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
          recommendedOptionComponent.props.onClick &&
            recommendedOptionComponent.props.onClick(e)
        }}
      >
        <LabeledIcon
          Icon={<Star color="primary" fontSize="medium" />}
          Label={renderOption(recommendedOptionComponent.option)}
          iconPosition="start"
        />
      </Grid>
      <Divider className={classes.divider} />
      <ListSubheader sx={{bgcolor: 'background.default'}}>
        {otherGroupLabel}
      </ListSubheader>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  name: {
    paddingLeft: theme.spacing(2),
    display: 'flex',
  },
  divider: {
    marginTop: theme.spacing(1.5),
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  invalidOption: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1.5),
    wordBreak: 'break-all',
  },
}))
