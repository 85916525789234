/* eslint-disable @typescript-eslint/explicit-function-return-type */

import type {TxHistoryEntry} from '@nufi/wallet-common'
import type {SolanaTxHistoryEntry, SolanaTokenId} from '@nufi/wallet-solana'
import type BigNumber from 'bignumber.js'
import _ from 'lodash'

export const formatTxHistoryEntries = (
  data: SolanaTxHistoryEntry[] | undefined,
): TxHistoryEntry<'solana', SolanaTokenId>[] | undefined =>
  data &&
  data.map((tx) => ({
    effects: (tx.solEffect != null
      ? [
          {
            amount: tx.solEffect as BigNumber,
            blockchain: 'solana' as const,
          },
        ]
      : []
    )
      .concat(
        tx.tokenEffects.map((te) => ({
          blockchain: 'solana' as const,
          amount: te.amount,
          tokenId: te.token.id,
        })),
      )
      .filter((e) => !e.amount.isZero()),
    fee: tx.fee,
    transactionId: tx.signature,
    time: tx.timeIssued,
    operations: _.uniq(tx.instructions.map((i) => i.type)),
  }))
