import {Box, Divider} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {
  Modal,
  ModalLayout,
  ModalFooter,
  FooterLayout,
  Alert,
  MutationGuard,
} from '../../../../components'
import type {
  AccountId,
  AccountInfo,
  Blockchain,
  BlockchainAddress,
  TokenImport,
  TokenId,
} from '../../../../wallet'
import {useGetAccounts, useForgetToken} from '../../../../wallet'
import type {EvmAddress} from '../../../../wallet/evm'
import {
  assertIsEvmBlockchain,
  getEvmNetworkConfig,
  isEvmBlockchain,
} from '../../../../wallet/evm'
import {toChecksummedEvmAddress} from '../../../../wallet/evm/sdk/utils'
import {TokenImportStoreManagerProvider} from '../../../../wallet/TokenImportStoreManager'
import {findAccountById} from '../../../../wallet/utils/common'
import {AccountActionModalHeader} from '../utils'

import {TokenInfo} from './importToken/ImportTokenUtils'

type UseForgetModalUtilsProps = {
  accountId: AccountId
  blockchain: Blockchain
  tokenId?: TokenId
  onForgetTokenSuccess?: () => void
}

export const useForgetModalUtils = ({
  accountId,
  blockchain,
  tokenId,
  onForgetTokenSuccess,
}: UseForgetModalUtilsProps) => {
  const useAccountsQuery = useGetAccounts(blockchain)
  const [isModalOpen, setIsForgetTokenOpen] = useState(false)

  if (!useAccountsQuery.data || !!useAccountsQuery.error) {
    return null
  }

  // ensureAccountById may crash the app if the account has just been forgotten
  const accountInfo = findAccountById<AccountInfo>(
    useAccountsQuery.data,
    accountId,
  )
  if (!accountInfo) return null

  const address = accountInfo.address as unknown as BlockchainAddress

  const getTokenArgs = isEvmBlockchain(blockchain)
    ? {
        blockchain,
        id: tokenId,
        address,
        chainId: getEvmNetworkConfig(blockchain).chainId,
      }
    : {
        blockchain,
        id: tokenId,
      }

  const tokenImport =
    tokenId && TokenImportStoreManagerProvider.instance().getToken(getTokenArgs)

  if (!tokenImport) return null

  return {
    ForgetTokenModal: (
      <ForgetToken
        {...{
          token: tokenImport,
          address,
          onClose: () => {
            setIsForgetTokenOpen(false)
          },
          onSuccess: onForgetTokenSuccess,
        }}
      />
    ),
    openModal: () => {
      setIsForgetTokenOpen(true)
    },
    isModalOpen,
  }
}

type ForgetTokenProps = {
  token: TokenImport
  address: BlockchainAddress
  onClose: () => void
  onSuccess?: () => void
}

const ForgetToken = ({
  token,
  address,
  onClose,
  onSuccess,
}: ForgetTokenProps) => {
  const {t} = useTranslation()
  const classes = useStyles()

  const {blockchain} = token
  const forgetToken = useForgetToken(blockchain)

  const onForgetToken = async () => {
    // specific Evm logic
    assertIsEvmBlockchain(token.blockchain)
    const tokenToDelete = {
      ...token,
      addresses: [
        toChecksummedEvmAddress(
          address as string as EvmAddress<typeof token.blockchain>,
        ),
      ],
    }
    await forgetToken.mutateAsyncSilent({token: tokenToDelete})
    onClose()
    onSuccess && onSuccess()
  }

  return (
    <Modal onClose={onClose} variant="centered" dismissable>
      <ModalLayout
        header={
          <AccountActionModalHeader
            label={t('Forget token')}
            {...{blockchain, onClose}}
          />
        }
        body={
          <>
            <Box p={2}>
              <TokenInfo token={token} />
            </Box>
            <Divider />
            <Box className={classes.contentBox}>
              <Alert
                severity="warning"
                text={t(
                  "You can re-add this token at any time using the 'Add Tokens' function.",
                )}
              />
            </Box>
            <MutationGuard {...forgetToken} />
          </>
        }
        footer={
          <ModalFooter hasDivider>
            <FooterLayout
              leftBtnConf={{
                onClick: onClose,
                children: t('Close'),
              }}
              rightBtnConf={{
                variant: 'contained',
                color: 'warning',
                onClick: onForgetToken,
                children: t('Forget token'),
                disabled: forgetToken.isPending,
              }}
            />
          </ModalFooter>
        }
      />
    </Modal>
  )
}

const useStyles = makeStyles((theme) => ({
  contentBox: {
    padding: theme.spacing(2),
  },
}))
