import {Box, styled} from '@mui/material'
import {assert} from '@nufi/frontend-common'
import {isEvmBlockchain} from '@nufi/wallet-evm'
import type {
  EvmAccountInfo,
  EvmAccountStoredData,
  EvmBlockchain,
} from '@nufi/wallet-evm'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import type {AssetAmount} from 'src/features/assets/types'
import type {ConversionRates} from 'src/features/conversionRates/domain'

import {AccordionCard, AccountCardCol} from '../../../../components'
import type {TokenAmount, TokenMetadata} from '../../../../types'
import {LoadAssetsError} from '../../assetList/AssetsList'
import {AccountListHeader} from '../AccountListLayout'
import {
  EvmAccountListHeader,
  EvmAccountListItemLayout,
} from '../EvmAccountListLayout'

import {
  Actions,
  ActionsCol,
  ExpansionButton,
  useAccountListCardCommons,
  useVirtualizedAssetListData,
  VirtualizedAssetList,
} from './common'
import {EvmBlockchainsCol} from './EvmBlockchainsCol'

type EvmAccountListCardProps = {
  storedAccountData: EvmAccountStoredData
  accountInfos: EvmAccountInfo<EvmBlockchain>[]
  tokensMetadata: TokenMetadata[]
  tokens: TokenAmount[]
  partialLoadings: EvmBlockchain[]
  errors: EvmBlockchain[]
  conversionRates?: ConversionRates
}

export function EvmAccountListCard({
  storedAccountData,
  accountInfos,
  tokensMetadata,
  tokens: _tokens,
  errors,
  conversionRates,
}: EvmAccountListCardProps) {
  const {
    hideZeroBalances,
    hideNfts,
    action,
    onCloseAction,
    onOpenAction,
    actionRef,
    expanded,
    setExpanded,
    onCardClick,
    tokensMetadataMap,
    portfolioBlockchain,
  } = useAccountListCardCommons({tokensMetadata})

  assert(portfolioBlockchain == null || isEvmBlockchain(portfolioBlockchain))

  const [cardBlockchain, setCardBlockchain] = useState<EvmBlockchain | null>(
    null,
  )
  // The following are shared by evm accounts so it's OK to take them from the first account.
  const accountInfo = accountInfos[0]!
  const {address} = accountInfo
  const {id, cryptoProviderType, name} = storedAccountData

  const blockchain = portfolioBlockchain || cardBlockchain

  const {t} = useTranslation()

  const tokens = _tokens.filter((t) => tokensMetadataMap[t.token.id] != null)

  const assets: AssetAmount[] = [
    ...accountInfos.map((a) => ({
      type: 'native' as const,
      amount: a.balance,
      blockchain: a.blockchain,
    })),
    ...tokens.map((t) => ({
      type: 'token' as const,
      amount: t.amount,
      blockchain: t.token.blockchain,
      token: t.token,
      tokenMetadata: tokensMetadataMap[t.token.id]!,
    })),
  ]

  const virtualizedAssetListData = useVirtualizedAssetListData({
    assets,
    accountId: id,
    hideZeroBalances,
    hideNfts,
    tokensMetadataMap,
    conversionRates,
    blockchain,
  })

  const filteredAssetsLength = virtualizedAssetListData.assets.length

  return (
    <>
      <AccordionCard
        elevation={expanded ? 1 : 0}
        expanded={expanded}
        onCardClick={onCardClick}
        doNotMountDetailsIfNotExpanded
        mainContent={
          <>
            <EvmAccountListHeader />
            <Box mt={2} />

            <EvmAccountListItemLayout
              titleCol={
                <AccountCardCol
                  {...{
                    walletKind: 'evm',
                    blockchain,
                    name,
                    cryptoProviderType,
                    address,
                  }}
                />
              }
              blockchainsCol={
                <EvmBlockchainsCol
                  accountInfos={accountInfos}
                  tokenAmounts={tokens}
                  tokensMetadataMap={tokensMetadataMap}
                  conversionRates={conversionRates}
                  selectedBlockchain={
                    portfolioBlockchain != null
                      ? portfolioBlockchain
                      : cardBlockchain
                  }
                  showAllBlockchainsCell={portfolioBlockchain == null}
                  onSelectBlockchain={(blockchain: EvmBlockchain | null) => {
                    if (portfolioBlockchain == null) {
                      if (cardBlockchain === blockchain) {
                        setCardBlockchain(null)
                      } else {
                        setCardBlockchain(blockchain)
                        setExpanded(true)
                      }
                    }
                  }}
                />
              }
              actionsCol={
                <ActionsCol
                  blockchain={blockchain}
                  accountInfo={accountInfo}
                  actionRef={actionRef}
                  onOpenAction={onOpenAction}
                />
              }
            />
          </>
        }
        actions={
          <ExpansionButton
            expanded={expanded}
            assetsCount={filteredAssetsLength}
          />
        }
        details={
          <>
            <AssetsHeaderWrapper>
              <AccountListHeader
                titleColText={t('Asset')}
                priceColText={t('Asset value')}
              />
            </AssetsHeaderWrapper>
            {/* We need wrapper` around AutoSizer otherwise it can not size itself properly */}
            <VirtualizedAssetList
              virtualizedAssetListData={virtualizedAssetListData}
            />
            <div key={errors.length}>
              {errors
                .filter((e) => blockchain == null || e === blockchain)
                .map((e) => (
                  <LoadAssetsError error={{type: 'tokens', blockchain: e}} />
                ))}
            </div>
          </>
        }
      />
      <Actions
        action={action}
        walletKind={'evm'}
        blockchain={blockchain}
        accountInfo={accountInfo}
        onCloseAction={onCloseAction}
      />
    </>
  )
}

const AssetsHeaderWrapper = styled(Box)(({theme}) => ({
  background: theme.palette.background.paper,
  padding: theme.spacing(0.5, 2),
  borderBottom: `1px solid ${theme.palette.divider}`,
}))
