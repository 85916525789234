import {Grid} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {
  HidableGridItem,
  Aligner,
  NoOverflowAligner,
  ListHeaderTitle,
  ListLayoutWrapper,
} from '../../../components'

type AccountListHeaderProps = {
  titleColText?: string
  priceColText?: string
  balanceColText?: string
  stakingColText?: string
  actionsColText?: string
}

export function AccountListHeader({
  titleColText,
  priceColText,
  balanceColText,
  stakingColText,
  actionsColText,
}: AccountListHeaderProps) {
  const {t} = useTranslation()

  return (
    <AccountListItemLayout
      titleCol={
        <ListHeaderTitle>{titleColText || t('Account')}</ListHeaderTitle>
      }
      priceCol={
        <ListHeaderTitle>{priceColText || t('Account value')}</ListHeaderTitle>
      }
      balanceCol={
        <ListHeaderTitle>
          {balanceColText || t('Available balance')}
        </ListHeaderTitle>
      }
      stakingCol={
        stakingColText && <ListHeaderTitle>{stakingColText}</ListHeaderTitle>
      }
      actionsCol={
        <ListHeaderTitle>{actionsColText || t('Actions')}</ListHeaderTitle>
      }
    />
  )
}

type AccountListItemLayoutProps = {
  titleCol: React.ReactNode
  priceCol: React.ReactNode
  balanceCol: React.ReactNode
  stakingCol?: React.ReactNode
  actionsCol: React.ReactNode
}

export function AccountListItemLayout({
  titleCol,
  priceCol,
  balanceCol,
  stakingCol,
  actionsCol,
}: AccountListItemLayoutProps) {
  return (
    <ListLayoutWrapper>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item xs={3}>
          <NoOverflowAligner align="left">{titleCol}</NoOverflowAligner>
        </Grid>
        <Grid item xs={2}>
          <Aligner align="right">{priceCol}</Aligner>
        </Grid>
        <Grid item xs={2}>
          <Aligner align="right">{balanceCol}</Aligner>
        </Grid>
        <HidableGridItem md={2} display={{xs: 'none', md: 'block'}}>
          {stakingCol && <Aligner align="right">{stakingCol}</Aligner>}
        </HidableGridItem>
        <Grid item xs={5} md={3}>
          <Aligner align="right">{actionsCol}</Aligner>
        </Grid>
      </Grid>
    </ListLayoutWrapper>
  )
}
