import type {CardanoTransactionHash, CardanoTxPlan} from '@nufi/wallet-cardano'
import type {AccountId} from '@nufi/wallet-common'

import type {TransactionInfo} from 'src/store/transactions'
import {
  pushTransactionImperative,
  useTransactionState,
} from 'src/store/transactions'
import {buildTxBody} from 'src/wallet/cardano'

const txNotificationsCandidates: Record<
  CardanoTransactionHash,
  TransactionInfo['context']
> = {}

export const txStoreActions = {
  registerTxCandidateWithTxPlan: (
    selectedAccountId: AccountId,
    txPlan: CardanoTxPlan,
  ) => {
    const txHash = buildTxBody(txPlan).hash

    txNotificationsCandidates[txHash] = {
      accountId: selectedAccountId,
      blockchain: 'cardano',
      transactionId: txHash,
      txPlan,
    }
  },
  registerRawTxCandidate: (
    selectedAccountId: AccountId,
    txHash: CardanoTransactionHash,
  ) => {
    txNotificationsCandidates[txHash] = {
      accountId: selectedAccountId,
      blockchain: 'cardano',
      transactionId: txHash,
    }
  },
  pushTxCandidate: (
    selectedAccountId: AccountId,
    txHash: CardanoTransactionHash,
  ) => {
    const txNotificationData = txNotificationsCandidates[txHash]

    if (
      txNotificationData != null &&
      selectedAccountId != null &&
      txNotificationData.accountId === selectedAccountId
    ) {
      pushTransactionImperative(txNotificationData)
    }
  },
  updatePendingTx: (
    txHash: CardanoTransactionHash,
    state: {type: 'error'; error: unknown} | {type: 'data'; data: unknown},
  ) => {
    const {getVisibleTransactions, updateTransaction} =
      useTransactionState.getState()

    const pendingTx = getVisibleTransactions().find(
      (t) => t.context?.transactionId === txHash,
    )

    if (pendingTx) {
      const now = new Date()
      updateTransaction({
        ...pendingTx,
        updatedAt: now,
        isPending: false,
        error: state.type === 'error' ? state.error : undefined,
        data: state.type === 'data' ? state.data : undefined,
      })
    }
  },
}
