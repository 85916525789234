import type {ChipProps, BoxProps} from '@mui/material'
import {Box, Chip, Typography} from '@mui/material'
import React from 'react'

const CHIP_HEIGHT = 32
const CONSTANT_LABEL_WIDTH = 50
const CHIP_Y_SPACING = 4
const CHIP_TOTAL_HEIGHT = CHIP_HEIGHT + 2 * CHIP_Y_SPACING

type ScrollableChipContainerProps = {
  children: (Chip: React.ElementType) => React.ReactNode
  rows?: number
  testId?: string
} & Omit<BoxProps, 'children'>

export const ScrollableChipContainer = ({
  children,
  rows = 2,
  testId,
  ...rest
}: ScrollableChipContainerProps) => (
  <Box
    sx={{
      minHeight: CHIP_TOTAL_HEIGHT,
      maxHeight: rows * CHIP_TOTAL_HEIGHT, // Number of chip rows without scroll
      overflow: 'auto',
    }}
    {...rest}
    {...(testId && {
      'rtl-data-test-id': testId,
    })}
  >
    {children(ChipItem)}
  </Box>
)

export type ChipItemProps = {
  label: string
  labelWidth?: number
} & Omit<ChipProps, 'label'>

const ChipItem = ({
  label,
  labelWidth = CONSTANT_LABEL_WIDTH,
  ...rest
}: ChipItemProps) => (
  <Chip
    title={label}
    sx={{
      my: `${CHIP_Y_SPACING}px`,
      height: CHIP_HEIGHT,
      ':not(:last-child)': {mr: 1},
    }}
    label={
      <Typography width={labelWidth} variant="inherit" noWrap>
        {label}
      </Typography>
    }
    {...rest}
  />
)
