import {
  CheckCircle as SuccessIcon,
  Schedule as ClockIcon,
} from '@mui/icons-material'
import {Grid, Box, Typography} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'

import type {WeakUseMutationResult} from 'src/utils/mutation-utils'

import {
  ModalLayout,
  ModalFooter,
  TransactionExplorerLink,
  InternalLink,
  FooterLayout,
  Button,
} from '../../components'
import {routeTo} from '../../router'
import type {Blockchain} from '../../types'
import {assert} from '../../utils/assertion'
import type {AccountId} from '../../wallet'

import {
  TxErrorFooter,
  TxLoadingFooter,
  TxLoadingBody,
  TxBodyLayout,
  TxErrorBody,
} from './common'

// TODO: move these components into folders of actions they belongs to

export type SubmitProps = WeakUseMutationResult<
  string,
  unknown,
  unknown,
  unknown
>

type CoreSubmitScreenProps = {
  blockchain: Blockchain
  onBack: () => void
  onClose: () => void
  onRetry: () => Promise<unknown>
  submitProps: SubmitProps
  ModalHeader: React.ReactNode
}

type SubmitScreenProps = {
  onNewTransaction: (() => unknown) | null
  accountId: AccountId
  CustomSuccessScreen?: React.ReactNode
  CustomSuccessBody?: React.ReactNode
  CustomSuccessFooter?: React.ReactNode
} & CoreSubmitScreenProps

export function BaseSubmitScreen({
  ModalHeader,
  onBack,
  onClose,
  onRetry,
  submitProps,
  blockchain,
}: CoreSubmitScreenProps) {
  const {Body, Footer} = (() => {
    if (submitProps.error) {
      return {
        Body: (
          <TxErrorBody
            type="submit"
            error={submitProps.error}
            {...{onRetry, blockchain}}
          />
        ),
        Footer: <TxErrorFooter {...{onClose, onBack}} />,
      }
    }
    return {
      Body: <TxLoadingBody action="submitting" blockchain={blockchain} />,
      Footer: <TxLoadingFooter closable {...{onClose}} action="submitting" />,
    }
  })()

  return (
    <ModalLayout
      header={ModalHeader}
      body={<TxBodyLayout centered>{Body}</TxBodyLayout>}
      footer={
        <ModalFooter hasDivider={!submitProps.isPending}>{Footer}</ModalFooter>
      }
    />
  )
}

export function BaseSuccessScreen({
  ModalHeader,
  onClose,
  submitProps,
  onNewTransaction,
  blockchain,
  accountId,
  CustomSuccessScreen,
  CustomSuccessBody,
  CustomSuccessFooter,
}: Omit<SubmitScreenProps, 'onRetry' | 'onBack'>) {
  const {t} = useTranslation()

  assert(!!submitProps.data)

  if (CustomSuccessScreen) {
    return <>{CustomSuccessScreen}</>
  }

  return (
    <ModalLayout
      header={ModalHeader}
      body={
        <TxBodyLayout centered>
          {CustomSuccessBody || (
            <SuccessBody
              txHash={submitProps.data}
              {...{blockchain, accountId}}
            />
          )}
        </TxBodyLayout>
      }
      footer={
        <ModalFooter hasDivider={!submitProps.isPending}>
          {CustomSuccessFooter || (
            <SuccessFooter
              leftActionText={t('Close')}
              onLeftAction={onClose}
              onRightAction={() =>
                onNewTransaction ? onNewTransaction() : null
              }
              rightActionText={t('New transaction')}
            />
          )}
        </ModalFooter>
      }
    />
  )
}

export function SubmitScreen({
  ModalHeader,
  onBack,
  onClose,
  onRetry,
  submitProps,
  onNewTransaction,
  blockchain,
  accountId,
  CustomSuccessScreen,
  CustomSuccessBody,
  CustomSuccessFooter,
}: SubmitScreenProps) {
  const {t} = useTranslation()

  if (submitProps.data && !submitProps.error && CustomSuccessScreen) {
    return <>{CustomSuccessScreen}</>
  }

  const {Body, Footer} = (() => {
    if (submitProps.data && !submitProps.error) {
      return {
        Body: CustomSuccessBody || (
          <SuccessBody txHash={submitProps.data} {...{blockchain, accountId}} />
        ),
        Footer: CustomSuccessFooter || (
          <SuccessFooter
            leftActionText={t('Close')}
            onLeftAction={onClose}
            onRightAction={() => (onNewTransaction ? onNewTransaction() : null)}
            rightActionText={t('New transaction')}
          />
        ),
      }
    }
    if (submitProps.error) {
      return {
        Body: (
          <TxErrorBody
            type="submit"
            error={submitProps.error}
            {...{onRetry, blockchain}}
          />
        ),
        Footer: <TxErrorFooter {...{onClose, onBack}} />,
      }
    }
    return {
      Body: <TxLoadingBody action="submitting" blockchain={blockchain} />,
      Footer: <TxLoadingFooter closable {...{onClose}} action="submitting" />,
    }
  })()

  return (
    <ModalLayout
      header={ModalHeader}
      body={<TxBodyLayout centered>{Body}</TxBodyLayout>}
      footer={
        <ModalFooter hasDivider={!submitProps.isPending}>{Footer}</ModalFooter>
      }
    />
  )
}

type SetupStakeAccountSubmitScreenProps = SubmitStakeScreenProps & {
  isStakeAccountCreated: boolean
}

// TODO: find a better place for this
export function SetupStakeAccountSubmitScreen(
  props: SetupStakeAccountSubmitScreenProps,
) {
  const {ModalHeader, onClose, submitProps, blockchain, isStakeAccountCreated} =
    props

  // override the standard submit screen while waiting for stake account creation
  if (submitProps.data && !submitProps.error && !isStakeAccountCreated) {
    return (
      <ModalLayout
        header={ModalHeader}
        body={
          <TxBodyLayout centered>
            <TxLoadingBody
              action="creatingStakeAccount"
              blockchain={blockchain}
            />
          </TxBodyLayout>
        }
        footer={
          <ModalFooter hasDivider={!submitProps.isPending}>
            <TxLoadingFooter
              closable
              action="creatingStakeAccount"
              onClose={onClose}
            />
          </ModalFooter>
        }
      />
    )
  }

  return <SubmitStakeScreen {...props} />
}

type SubmitStakeScreenProps = {
  blockchain: Blockchain
  onBack: () => void
  onClose: () => void
  onNewStaking: () => unknown
  onRetry: () => Promise<unknown>
  submitProps: SubmitProps
  ModalHeader: React.ReactNode
  accountId: AccountId
}

// TODO: find a better place for this
export function SubmitStakeScreen({
  ModalHeader,
  onBack,
  onClose,
  onRetry,
  submitProps,
  onNewStaking,
  blockchain,
  accountId,
}: SubmitStakeScreenProps) {
  const {t} = useTranslation()

  const {Body, Footer} = (() => {
    if (submitProps.data && !submitProps.error) {
      return {
        Body: (
          <SuccessBody
            txHash={submitProps.data}
            {...{blockchain, accountId}}
            customSuccessText={t('Successfully staked')}
          />
        ),
        Footer: (
          <SuccessFooter
            leftActionText={t('New staking')}
            onLeftAction={onNewStaking}
            onRightAction={onClose}
            rightActionText={t('Close')}
          />
        ),
      }
    }
    if (submitProps.error) {
      return {
        Body: (
          <TxErrorBody
            type="submit"
            error={submitProps.error}
            {...{onRetry, blockchain}}
          />
        ),
        Footer: <TxErrorFooter {...{onClose, onBack}} />,
      }
    }
    return {
      Body: <TxLoadingBody action="submitting" blockchain={blockchain} />,
      Footer: <TxLoadingFooter closable {...{onClose}} action="submitting" />,
    }
  })()

  return (
    <ModalLayout
      header={ModalHeader}
      body={<TxBodyLayout centered>{Body}</TxBodyLayout>}
      footer={
        <ModalFooter hasDivider={!submitProps.isPending}>{Footer}</ModalFooter>
      }
    />
  )
}

type UndelegateSubmitScreenProps = {
  blockchain: Blockchain
  onBack: () => void
  onClose: () => void
  onRetry: () => Promise<unknown>
  submitProps: SubmitProps
  ModalHeader: React.ReactNode
  accountId: AccountId
}

export function UndelegateSubmitScreen({
  ModalHeader,
  onBack,
  onClose,
  onRetry,
  submitProps,
  blockchain,
  accountId,
}: UndelegateSubmitScreenProps) {
  const {t} = useTranslation()
  const classes = useStyles()

  const {Body, Footer} = (() => {
    if (submitProps.data && !submitProps.error) {
      return {
        Body: (
          <SuccessBody
            txHash={submitProps.data}
            {...{blockchain, accountId}}
            customSuccessText={t(
              'Your stake account is in the process of being deactivated.',
            )}
            customSuccessIcon={
              <ClockIcon color="inherit" className={classes.icon} />
            }
          />
        ),
        Footer: (
          <Box width="inherit" px={1} py={2}>
            <Button
              textTransform="none"
              onClick={onClose}
              color="primary"
              variant="outlined"
              fullWidth
            >
              {t('Close')}
            </Button>
          </Box>
        ),
      }
    }
    if (submitProps.error) {
      return {
        Body: (
          <TxErrorBody
            type="submit"
            error={submitProps.error}
            {...{onRetry, blockchain}}
          />
        ),
        Footer: <TxErrorFooter {...{onClose, onBack}} />,
      }
    }
    return {
      Body: <TxLoadingBody action="submitting" blockchain={blockchain} />,
      Footer: <TxLoadingFooter closable {...{onClose}} action="submitting" />,
    }
  })()

  return (
    <ModalLayout
      header={ModalHeader}
      body={<TxBodyLayout centered>{Body}</TxBodyLayout>}
      footer={
        <ModalFooter hasDivider={!submitProps.isPending}>{Footer}</ModalFooter>
      }
    />
  )
}

type WithdrawSubmitScreenProps = {
  blockchain: Blockchain
  onBack: () => void
  onClose: () => void
  onRetry: () => Promise<unknown>
  onNewWithdrawal?: () => unknown
  submitProps: SubmitProps
  ModalHeader: React.ReactNode
  accountId: AccountId
}

export function WithdrawSubmitScreen({
  ModalHeader,
  onBack,
  onClose,
  onRetry,
  onNewWithdrawal,
  submitProps,
  blockchain,
  accountId,
}: WithdrawSubmitScreenProps) {
  const {t} = useTranslation()

  const {Body, Footer} = (() => {
    if (submitProps.data && !submitProps.error) {
      return {
        Body: (
          <SuccessBody
            txHash={submitProps.data}
            {...{blockchain, accountId}}
            customSuccessText={t('Rewards successfully withdrawn.')}
          />
        ),
        Footer: onNewWithdrawal ? (
          <SuccessFooter
            leftActionText={t('Withdraw more rewards')}
            onLeftAction={onNewWithdrawal}
            onRightAction={onClose}
            rightActionText={t('Close')}
          />
        ) : (
          <Box width="inherit" px={1} py={2}>
            <Button
              textTransform="none"
              onClick={onClose}
              color="primary"
              variant="outlined"
              fullWidth
            >
              {t('Close')}
            </Button>
          </Box>
        ),
      }
    }
    if (submitProps.error) {
      return {
        Body: (
          <TxErrorBody
            type="submit"
            error={submitProps.error}
            {...{onRetry, blockchain}}
          />
        ),
        Footer: <TxErrorFooter {...{onClose, onBack}} />,
      }
    }
    return {
      Body: <TxLoadingBody action="submitting" blockchain={blockchain} />,
      Footer: <TxLoadingFooter closable {...{onClose}} action="submitting" />,
    }
  })()

  return (
    <ModalLayout
      header={ModalHeader}
      body={<TxBodyLayout centered>{Body}</TxBodyLayout>}
      footer={
        <ModalFooter hasDivider={!submitProps.isPending}>{Footer}</ModalFooter>
      }
    />
  )
}

type SuccessBodyProps = {
  txHash?: string
  blockchain: Blockchain
  accountId?: AccountId
  customSuccessText?: React.ReactNode
  customSuccessIcon?: React.ReactNode
}

export function SuccessBody({
  txHash,
  blockchain,
  accountId,
  customSuccessText,
  customSuccessIcon,
}: SuccessBodyProps) {
  const {t} = useTranslation()
  const classes = useStyles()
  const history = useHistory()

  return (
    <Grid
      spacing={{xs: 1, windowsZoomed: 2}}
      container
      alignItems="center"
      justifyContent="center"
      direction="column"
      className={classes.fullHeight}
    >
      <Grid item>
        <Box className={classes.successIconWrapper}>
          <Box>
            {customSuccessIcon || (
              <SuccessIcon color="inherit" className={classes.icon} />
            )}
          </Box>
        </Box>
      </Grid>
      <Grid item>
        <Typography align="center" variant="h6" lineHeight={1}>
          <span className={classes.successText}>
            {customSuccessText || t('Transaction successfully submitted.')}
          </span>
        </Typography>
      </Grid>
      <Grid item>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          direction={{xs: 'column', windowsZoomed: 'column'}}
          className={classes.explorerLink}
          spacing={1}
        >
          {txHash != null && (
            <Grid item>
              <TransactionExplorerLink {...{blockchain}} txId={txHash} />
            </Grid>
          )}
          {accountId != null && (
            <Grid item>
              <InternalLink
                title={t('View in app')}
                onClick={() =>
                  history.replace(
                    routeTo.portfolio.assets
                      .blockchain(blockchain)
                      .detail.account(accountId)
                      .tab('history').index,
                  )
                }
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

type SuccessFooterProps = {
  onLeftAction: () => unknown
  leftActionText: string
  onRightAction: () => unknown
  rightActionText: string
}

export function SuccessFooter({
  onLeftAction,
  leftActionText,
  onRightAction,
  rightActionText,
}: SuccessFooterProps) {
  return (
    <FooterLayout
      leftBtnConf={{
        onClick: onLeftAction,
        children: leftActionText,
      }}
      rightBtnConf={{
        onClick: onRightAction,
        children: rightActionText,
      }}
    />
  )
}

const useStyles = makeStyles((theme) => ({
  fullHeight: {
    height: '100%',
  },
  successIconWrapper: {
    color: theme.palette.success.main,
  },
  icon: {
    width: 40,
    height: 40,
    [theme.breakpoints.up('windowsZoomed')]: {
      width: 60,
      height: 60,
    },
  },
  successText: {
    fontSize: '90%',
    [theme.breakpoints.up('windowsZoomed')]: {
      fontSize: '100%',
    },
  },
  explorerLink: {
    fontSize: '14px',
    [theme.breakpoints.up('windowsZoomed')]: {
      fontSize: '17px',
    },
  },
}))
