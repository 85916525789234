import {HelpOutline as HelpIcon} from '@mui/icons-material'
import type {SvgIconProps} from '@mui/material'
import {Typography} from '@mui/material'
import {makeStyles} from '@mui/styles'
import type {SwapAsset} from 'common'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {LabeledIconWithTooltip} from '../../../components'

type ExtraIdMessageProps = {
  extraIdName: string
  asset: SwapAsset
}

export const ExtraIdMessage = ({extraIdName, asset}: ExtraIdMessageProps) => {
  const {t} = useTranslation()

  return t('extra_id_alert', {extraIdName, asset})
}

type ExtraIdHelpProps = {
  extraIdName: string
  size?: SvgIconProps['fontSize']
  includeLabel?: boolean
}

export const ExtraIdHelp = ({
  extraIdName,
  size = 'medium',
  includeLabel = false,
}: ExtraIdHelpProps) => {
  const {t} = useTranslation()
  const classes = useStyles()

  return (
    <LabeledIconWithTooltip
      Icon={<HelpIcon className={classes.help} fontSize={size} />}
      Label={
        includeLabel ? (
          <Typography color="textSecondary">{extraIdName}</Typography>
        ) : (
          ''
        )
      }
      title={<>{t('extra_id_help', {extraIdName})}</>}
      spacing={0.5}
    />
  )
}

const useStyles = makeStyles((theme) => ({
  help: {
    color: theme.palette.text.secondary,
  },
}))
