import {Box, Grid, Paper, Typography} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import type {AccountIdTokenBalance} from '@nufi/wallet-common'
import {QRCodeSVG} from 'qrcode.react'
import React, {useState} from 'react'
import {Trans, useTranslation} from 'react-i18next'

import {WithWeakTokenMetadata} from 'src/wallet/public/ui'

import {tokenBlockchains} from '../../blockchainTypes'
import {
  CopyToClipboard,
  AccountSelectField,
  AssetIconModalHeader,
  Button,
  ModalLayout,
  Aligner,
  ModalFooter,
  useModalSharedStyles,
  MultilineString,
  createResponsiveClasses,
  createCustomResponsiveClass,
  FormattedTokenName,
} from '../../components'
import type {
  Blockchain,
  TokenAccountBalances,
  AccountInfo,
  TokenMetadata,
  TokenId,
} from '../../types'
import {assert} from '../../utils/assertion'
import {BlockchainSubsetGuard} from '../../utils/blockchainGuards'
import type {SelectChangeFn} from '../../utils/form'
import {onChangeFactory} from '../../utils/form'
import {useGetCoinName, useGetBlockchainName} from '../../utils/translations'
import type {AccountId} from '../../wallet'
import {ensureAccountById} from '../../wallet/utils/common'

import {AddressVerificationProvider} from './AddressVerificationProvider'

export const mapAccountsToBalances = (
  accountInfos: AccountInfo[],
  balances?: Array<AccountIdTokenBalance>,
) => {
  if (!balances) {
    return accountInfos.map((a) => ({
      accountId: a.id,
      address: a.address,
      balance: a.balance,
      name: a.name,
      cryptoProviderType: a.cryptoProviderType,
    }))
  } else {
    return balances.map(({accountId, balance}) => {
      const accountInfo = accountInfos.find((a) => a.id === accountId)
      assert(accountInfo !== undefined)
      return {
        accountId,
        balance,
        address: accountInfo.address,
        name: accountInfo.name,
        cryptoProviderType: accountInfo.cryptoProviderType,
      }
    })
  }
}

type ReceiveModalAssetNameProps = {
  blockchain: Blockchain
  tokenMetadata?: TokenMetadata
  ellipsize?: boolean
}

export function ReceiveModalAssetName({
  blockchain,
  tokenMetadata,
  ellipsize = true,
}: ReceiveModalAssetNameProps): JSX.Element {
  const getCoinName = useGetCoinName()
  return tokenMetadata ? (
    <BlockchainSubsetGuard
      blockchain={blockchain}
      blockchainSubset={tokenBlockchains}
    >
      {(tokenBlockchain) => (
        <FormattedTokenName
          blockchain={tokenBlockchain}
          tokenInfo={tokenMetadata}
          ellipsize={ellipsize}
        />
      )}
    </BlockchainSubsetGuard>
  ) : (
    <>{getCoinName(blockchain)}</>
  )
}

type ReceiveAddressProps = {
  address: string
  blockchain: Blockchain
  isToken: boolean
  assetName: string | JSX.Element
}

export function ReceiveModalAddress({
  address,
  blockchain,
  isToken,
  assetName,
}: ReceiveAddressProps) {
  const classes = {...useStyles(), ...useModalSharedStyles()}
  const {t} = useTranslation()
  const getBlockchainName = useGetBlockchainName()
  const qrCodeSize = 184
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="subtitle2" align="center" color="textSecondary">
          {isToken ? (
            <Trans
              i18nKey="your_token_receive_address"
              t={t}
              components={{
                AssetName: <>{assetName}</>,
                BlockchainName: <>{getBlockchainName(blockchain)}</>,
              }}
            />
          ) : (
            t('your_receive_address', {
              blockchain: getBlockchainName(blockchain),
            })
          )}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper elevation={0} className={classes.infoPaper}>
          <CopyToClipboard
            Label={<MultilineString label={address} />}
            value={address}
          />
        </Paper>
      </Grid>
      <Aligner align="center" containerClassName={classes.qrCode}>
        <QRCodeSVG value={address} size={qrCodeSize} includeMargin />
      </Aligner>
    </Grid>
  )
}

type ReceiveModalHeaderProps = {
  tokenId: TokenId | undefined
  blockchain: Blockchain
  AssetName?: JSX.Element | string
  onClose: () => void
}

export function ReceiveModalHeader({
  tokenId,
  onClose,
  blockchain,
  AssetName,
}: ReceiveModalHeaderProps) {
  const {t} = useTranslation()
  const getBlockchainName = useGetBlockchainName()
  return (
    <WithWeakTokenMetadata blockchain={blockchain} tokenId={tokenId}>
      {(tokenMetadata) => (
        <AssetIconModalHeader
          onClose={onClose}
          blockchain={blockchain}
          tokenMetadata={tokenMetadata}
        >
          <>
            <Typography variant="body1">
              <Trans
                i18nKey="receive_asset"
                t={t}
                components={{
                  AssetName: <>{AssetName}</>,
                }}
              />
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {getBlockchainName(blockchain)}
            </Typography>
          </>
        </AssetIconModalHeader>
      )}
    </WithWeakTokenMetadata>
  )
}

export type ReceiveModalBodyProps = {
  blockchain: Blockchain
  onClose: () => unknown
  defaultAccountId: AccountId
  accountInfos: AccountInfo[]
  tokenBalances?: TokenAccountBalances
  isNft?: boolean
}

export function ReceiveModalBody({
  blockchain,
  defaultAccountId,
  accountInfos,
  onClose,
  tokenBalances,
  isNft,
}: ReceiveModalBodyProps) {
  const classes = {...useStyles(), ...useModalSharedStyles()}
  const {t} = useTranslation()

  const [formData, setFormData] = useState({accountId: defaultAccountId})
  const onChange = onChangeFactory(formData, setFormData)
  const accountItems = mapAccountsToBalances(
    accountInfos,
    tokenBalances?.balances,
  )

  const selectedAccount = ensureAccountById(accountInfos, formData.accountId)
  const address = selectedAccount.address

  return (
    <AddressVerificationProvider
      accountInfo={selectedAccount}
      blockchain={blockchain}
    >
      {({
        ErrorContent,
        LoadingContent,
        ReadyContent,
        hasHwVerification,
        verificationState,
      }) => (
        <ModalLayout
          header={
            <ReceiveModalHeader
              blockchain={blockchain}
              tokenId={tokenBalances?.data.id}
              onClose={onClose}
              AssetName={
                <ReceiveModalAssetName
                  blockchain={blockchain}
                  tokenMetadata={tokenBalances?.data}
                />
              }
            />
          }
          body={
            <Box p={2}>
              {verificationState === 'error' ? (
                ErrorContent
              ) : (
                <>
                  <Grid container>
                    <Grid item xs={12} className={classes.formField}>
                      <AccountSelectField
                        label={t('Account')}
                        value={formData.accountId}
                        onChange={
                          onChange('accountId') as SelectChangeFn<AccountId>
                        }
                        items={accountItems}
                        blockchain={blockchain}
                        disabled={verificationState !== 'ready'}
                        tokenMetadata={tokenBalances?.data}
                        hideAmount={isNft}
                      />
                    </Grid>
                  </Grid>
                  <ReceiveModalAddress
                    address={address}
                    isToken={!!tokenBalances}
                    blockchain={blockchain}
                    assetName={
                      <ReceiveModalAssetName
                        blockchain={blockchain}
                        tokenMetadata={tokenBalances?.data}
                      />
                    }
                  />
                </>
              )}
            </Box>
          }
          footer={
            verificationState === 'error' ? null : (
              <ModalFooter hasDivider>
                {hasHwVerification && (
                  <Aligner
                    align="center"
                    containerClassName={classes.verifyHwAddress}
                  >
                    {verificationState === 'loading' && LoadingContent}
                    {verificationState === 'ready' && ReadyContent}
                  </Aligner>
                )}
                <Button
                  disabled={verificationState === 'loading'}
                  loading={verificationState === 'loading'}
                  textTransform="none"
                  onClick={onClose}
                  color="primary"
                  variant="contained"
                  fullWidth
                >
                  {verificationState === 'loading'
                    ? t('Waiting for verification')
                    : t('Done')}
                </Button>
              </ModalFooter>
            )
          }
        />
      )}
    </AddressVerificationProvider>
  )
}

const useStyles = makeStyles((theme) => ({
  infoPaper: {
    background: theme.palette.action.selected,
    paddingLeft: theme.spacing(2),
    width: '100%',
    ...createResponsiveClasses(
      theme,
      [2, 'marginTop'],
      [1.5, 'paddingTop'],
      [1.5, 'paddingBottom'],
    ),
  },
  qrCode: {
    ...createResponsiveClasses(theme, [4, 'marginTop']),
  },
  verifyHwAddress: {
    overflow: 'auto',
    alignItems: 'center',
    ...createCustomResponsiveClass(
      theme,
      {default: 200, zoomed: 120, old: 100},
      'minHeight',
    ),
  },
}))
