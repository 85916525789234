import {HelpOutline as HelpIcon} from '@mui/icons-material'
import {Box, TextField, Typography} from '@mui/material'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import * as yup from 'yup'

import {useVolatileImperativeQuery} from 'src/utils/query-utils'

import {
  Button,
  InlineError,
  InlineLoading,
  LabeledIconWithTooltip,
} from '../../../components'
import {isValidPositiveIntegerFieldValue} from '../../../utils/form'
import type {EvmAddress, EvmBlockchain} from '../../../wallet/evm'
import {fetchNextNonce} from '../../../wallet/evm'

export type NonceFieldState =
  | {
      mode: 'custom'
      value: string
    }
  | {
      mode: 'auto'
      value: ''
    }

export const useNonceSchema = () => {
  const {t} = useTranslation()
  const customMode: NonceFieldState['mode'] = 'custom'

  return {
    nonce: yup.object().shape({
      mode: yup.string(),
      value: yup.string().when('mode', {
        is: customMode,
        // We only check whether the nonce is filled in the `custom`
        // mode, as user can not provide invalid value, as we require
        // correct value on the nonce change.
        then: () => yup.string().required(t('Nonce is required.')),
      }),
    }),
  }
}

// Note that this component is `formik` free, so we can run away from it
// easier in the future
type NonceProps<TBlockchain extends EvmBlockchain> = {
  blockchain: TBlockchain
  fromAddress: EvmAddress<TBlockchain>
  state: NonceFieldState
  setState: (state: NonceFieldState) => void
  error: boolean
  helperText?: string
}

export function NonceField<TBlockchain extends EvmBlockchain>({
  blockchain,
  state,
  setState,
  helperText,
  error,
  fromAddress,
}: NonceProps<TBlockchain>) {
  const {t} = useTranslation()

  const onModeToggle = () => {
    if (state.mode === 'auto') {
      setState({mode: 'custom', value: ''})
    } else {
      setState({mode: 'auto', value: ''})
    }
  }

  const onNonceChange = (value: string) => {
    if (
      value === '' ||
      value === '0' ||
      isValidPositiveIntegerFieldValue(value)
    ) {
      setState({
        mode: 'custom',
        value,
      })
    }
  }

  const onInitNonce = (nonce: number) =>
    setState({mode: 'custom', value: nonce.toString()})

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Typography variant="subtitle2">
          <LabeledIconWithTooltip
            Icon={<HelpIcon fontSize="small" />}
            Label={t('Nonce')}
            title={<>{t('nonce_explanation')}</>}
            tooltipFontVariant="medium"
          />
        </Typography>
        <Button
          size="small"
          color="primary"
          sx={{pl: 0.5, pr: 0.5, pt: 0, pb: 0, marginLeft: 0.5}}
          // minHeight is being overridden when passed via `sx` prop
          style={{minHeight: 8}}
          textTransform="none"
          variant="text"
          onClick={onModeToggle}
          disableElevation
        >
          {state.mode === 'auto' ? t('Change') : t('Use recommended')}
        </Button>
      </Box>
      {state.mode === 'custom' ? (
        <CustomNonce
          value={state.value}
          {...{
            blockchain,
            error,
            helperText,
            onNonceChange,
            fromAddress,
            onInitNonce,
          }}
        />
      ) : (
        <Typography variant="caption">{t('automatic_nonce_note')}</Typography>
      )}
    </Box>
  )
}

const NonceLoadingElement = (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}
  >
    <InlineLoading />
  </Box>
)

type CustomNonceProps<TBlockchain extends EvmBlockchain> = {
  fromAddress: EvmAddress<TBlockchain>
  value: NonceFieldState['value']
  onNonceChange: (nonce: string) => void
} & Pick<NonceProps<TBlockchain>, 'error' | 'helperText' | 'blockchain'>

function CustomNonce<TBlockchain extends EvmBlockchain>({
  blockchain,
  fromAddress,
  value,
  onNonceChange,
  error,
  helperText,
}: CustomNonceProps<TBlockchain>) {
  const {t} = useTranslation()

  // We want to avoid cached data between mounts, as we are prefilling
  // the nonce, and also want to prefill with up-to-date data.

  // prettier has trouble with passing generic functions as arguments e.g.  fetchNextNonce<TBlockchain>
  // without calling, for this reason we do (args: FetchNextNonceArgs) => fetchNextNonce<TBlockchain>(args)
  type FetchNextNonceArgs<TBlockchain extends EvmBlockchain> = {
    blockchain: TBlockchain
    address: EvmAddress<TBlockchain>
  }

  const getNextNonce = useVolatileImperativeQuery(
    (args: FetchNextNonceArgs<TBlockchain>) =>
      fetchNextNonce<TBlockchain>(args),
  )

  useEffect(() => {
    const fn = async () => {
      const nonce = await getNextNonce.fetch({blockchain, address: fromAddress})
      if (nonce != null) {
        onNonceChange(nonce.toString())
      }
    }
    fn()
  }, [])

  if (getNextNonce.error) return <InlineError {...{error}} />
  if (getNextNonce.isLoading) return NonceLoadingElement

  return (
    <TextField
      value={value}
      onChange={(e) => onNonceChange(e.target.value)}
      label={t('Nonce')}
      fullWidth
      {...{error, helperText}}
      sx={{mt: 2, mb: 2}}
    />
  )
}
