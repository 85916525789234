import React from 'react'

import {QueryGuard, StakingModalError} from '../../../../../components'
import type {AccountId} from '../../../../../types'
import {
  useGetAccounts,
  useGetValidators,
  useGetIsStakingEnabled,
} from '../../../../../wallet/flow'
import {WithActiveScreenState} from '../../../../transaction'
import {StakingDisabledModalError} from '../utils'

import {FlowRegisterDelegatorForm} from './ModalForm'

export default function FlowRegisterDelegatorModal({
  onClose,
  accountId,
}: {
  onClose: () => unknown
  accountId: AccountId
}) {
  const accountsQuery = useGetAccounts()
  const validatorsQuery = useGetValidators()
  const isStakingEnabledQuery = useGetIsStakingEnabled()

  return (
    <WithActiveScreenState>
      <QueryGuard
        {...isStakingEnabledQuery}
        ErrorElement={<StakingModalError blockchain="flow" />}
        loadingVariant="centered"
      >
        {(isStakingEnabled) => {
          if (!isStakingEnabled) {
            return <StakingDisabledModalError />
          }
          return (
            <QueryGuard
              {...accountsQuery}
              ErrorElement={<StakingModalError blockchain="flow" />}
              loadingVariant="centered"
            >
              {(accounts) => (
                <QueryGuard
                  {...validatorsQuery}
                  ErrorElement={<StakingModalError blockchain="flow" />}
                  loadingVariant="centered"
                >
                  {(validators) => {
                    // we filter out accounts which don't have the staking collection setup
                    // since they cannot delegate
                    const accountsWithStakingCollection = accounts.filter(
                      ({stakingInfo}) =>
                        stakingInfo.isStakingCollectionRegistered,
                    )
                    return (
                      <FlowRegisterDelegatorForm
                        accounts={accountsWithStakingCollection}
                        {...{onClose, accountId, validators}}
                      />
                    )
                  }}
                </QueryGuard>
              )}
            </QueryGuard>
          )
        }}
      </QueryGuard>
    </WithActiveScreenState>
  )
}
