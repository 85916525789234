import {personalSign} from '@metamask/eth-sig-util'
import {assert} from '@nufi/frontend-common'
import type {SecretProvider} from '@nufi/wallet-common'
import type {NewType, NufiUserId} from 'common'
import {JWT_SIGNING_ALGORITHM} from 'common'
import {HDKey} from 'ethereum-cryptography/hdkey'
import {privateToPublic} from 'ethereumjs-util'
import * as jose from 'jose'

import {safeAssertUnreachable} from 'src/utils/assertion'

export type NufiAuthApi = {
  getChallenge(nufiId: NufiUserId): Promise<string>
  getToken(challenge: string, signature: string): Promise<string>
}

// the 1853187689 purpose is derived as `int(asciiToBytes('nufi')) (pseudocode)
//    or in js as parseInt(Buffer.from('nufi').toString('hex'), 16)
const NUFI_WALLET_PATH = "m/1853187689'/0'/0'"

type AuthKeyPairSecret = NewType<'AuthKeyPairSecret', string>

export type GetNufiAuthSecret = () => AuthKeyPairSecret

export const getNufiAuthSecretFromSecretProvider = (
  secretProvider: SecretProvider,
): AuthKeyPairSecret => {
  const {mnemonicStorageType} = secretProvider
  switch (mnemonicStorageType) {
    case 'local':
      return secretProvider.getSeed() as string as AuthKeyPairSecret
    case 'remote':
      return secretProvider.getIdentitySecret() as string as AuthKeyPairSecret
    default:
      return safeAssertUnreachable(mnemonicStorageType)
  }
}

class NufiAuthKeyManager {
  constructor(
    private secret: AuthKeyPairSecret,
    private challengePrefix: string,
  ) {}

  signApiChallenge = async (challenge: string): Promise<string> => {
    const prefixedChallenge = `${this.challengePrefix}${challenge}`

    const {privateKey} = await this.getAuthKeyPair()

    return personalSign({
      privateKey,
      data: prefixedChallenge,
    })
  }

  getAuthKeyPair = async (): Promise<{
    publicKey: Buffer
    privateKey: Buffer
  }> => {
    const authHdNode = await HDKey.fromMasterSeed(
      Buffer.from(this.secret, 'hex'),
    ).derive(NUFI_WALLET_PATH)
    assert(authHdNode.privateKey != null, 'failed to derive ETH private key')
    return {
      publicKey: privateToPublic(Buffer.from(authHdNode.privateKey)),
      privateKey: Buffer.from(authHdNode.privateKey),
    }
  }
}

export class NufiAuthTokenManager {
  private token: string | null = null

  constructor(
    private getSecret: GetNufiAuthSecret,
    private nufiAuthApi: NufiAuthApi,
    private apiJwtPublicKey: string,
    private challengePrefix: string,
  ) {}

  async loadApiToken() {
    const keyManager = new NufiAuthKeyManager(
      this.getSecret(),
      this.challengePrefix,
    )

    const nufiUserId = (await keyManager.getAuthKeyPair()).publicKey.toString(
      'hex',
    ) as NufiUserId

    const challenge = await this.nufiAuthApi.getChallenge(nufiUserId)
    const signature = await keyManager.signApiChallenge(challenge)

    const apiToken = await this.nufiAuthApi.getToken(challenge, signature)

    await this.verifyJwtToken(apiToken)

    this.token = apiToken
  }

  getToken() {
    return this.token
  }

  private verifyJwtToken = async (token: string) => {
    const publicKey = await jose.importSPKI(
      this.apiJwtPublicKey,
      JWT_SIGNING_ALGORITHM,
    )
    try {
      await jose.jwtVerify(token, publicKey)
    } catch (e) {
      // ignore token expiration to prevent issues with locally misconfigured time
      if (!(e instanceof jose.errors.JWTExpired)) {
        throw e
      }
    }
  }
}
