import type {
  PairingTypes,
  ProposalTypes,
  SessionTypes,
} from '@walletconnect/types'
import * as wcUtils from '@walletconnect/utils'
import {secondsToMilliseconds} from 'date-fns'

import {assert} from '../../utils/assertion'

import {walletConnect} from './sdk'

export async function createNewPairing(
  uri: string,
): Promise<PairingTypes.Struct> {
  const {version} = wcUtils.parseUri(uri)

  assert(version === 2, `Unsupported version ${version}`)

  return walletConnect.instance().core.pairing.pair({uri})
}

export function getActiveSession(topic: string): SessionTypes.Struct | null {
  return (
    Object.values(walletConnect.instance().getActiveSessions()).find(
      (sp) => sp.topic === topic,
    ) || null
  )
}

/**
 * To connect with the dapp, a uri is passed from the dapp to the wallet, in our case in query params.
 * This uri contains a pairing topic which is used to create the pairing between the dapp and the wallet.
 * The dapp may use a new pairing topic each time it connects, or MAY reuse already existing topic in case
 * the pairing already exists.
 * When the dapp tries to `connect` with the wallet, it also creates a pending session proposal.
 *
 * The wallet parses the uri and gets the pairing topic. If there already is a pairing between the dapp and the
 * wallet, the pairing is reused and by approving the session proposal the pairing is activated. If there is no
 * existing pairing, the wallet initiates the pairing by calling the `pair` function, triggering the
 * `session_proposal` event and activating the pairing if user approves the session.
 *
 * The function waits to get the session proposal, if it does not get it
 * it returns `null`
 *
 * @param uri WalletConnect uri
 * @returns a session proposal, either an existing pending one, or new one
 */

export async function getSessionProposal(
  uri: string,
): Promise<ProposalTypes.Struct | null> {
  const {topic} = wcUtils.parseUri(uri)
  const existingPairing = walletConnect
    .instance()
    .core.pairing.getPairings()
    .find((pairing) => pairing.topic === topic)

  if (!existingPairing) {
    return await new Promise<ProposalTypes.Struct | null>(async (resolve) => {
      walletConnect.instance().once('session_proposal', (args) => {
        // we only respond to session proposals which match the topic in the url
        // to avoid responding to other session proposals from other windows
        if (topic === args.params.pairingTopic) {
          resolve(args.params)
        }
      })

      // if the session is not proposed within the specified interval we abort
      setTimeout(() => resolve(null), secondsToMilliseconds(10))
      // createNewPairing is what triggers the `session_proposal` event
      await createNewPairing(uri)
    })
  }

  // if we have a existing pairing, we need to reuse it otherwise the dapp
  // will continue to try to pair with the same topic, which would fail with `pairing already exists`

  const pendingSessionProposal = Object.values(
    walletConnect.instance().getPendingSessionProposals(),
  ).find((sp) => sp.pairingTopic === topic)

  assert(!!pendingSessionProposal)

  return pendingSessionProposal
}

export async function disconnectActiveSession(
  session: SessionTypes.Struct,
  errorKey: Parameters<typeof wcUtils.getSdkError>[0],
): Promise<void> {
  return await walletConnect.instance().disconnectSession({
    topic: session.topic,
    reason: wcUtils.getSdkError(errorKey),
  })
}
