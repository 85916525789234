import {Sentry, getIsWebExtension} from '@nufi/frontend-common'

import {ChangellyRequestError} from 'src/features/exchange/infrastructure/changelly'

import config from '../config'

const getSentryEnvironment = (): string => {
  if (getIsWebExtension()) {
    // extension in development environment uses the same .env file as the official extension
    // in the chrome store, so we check for its url, to differentiate it from those extensions built locally
    return chrome.runtime.id === 'gpnihlnnodeiiaakbikldcihojploeca'
      ? config.appSentryEnv
      : 'development'
  }
  return config.appSentryEnv
}

export function initSentry() {
  Sentry.init({
    dsn: config.appSentryDsn,
    environment: getSentryEnvironment(),
    ignoreErrors: [
      // this error keeps spamming our Sentry
      // https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062/16
      'Non-Error promise rejection captured with keys: currentTarget, isTrusted, target, type',
      // Triggered in extension every time dapp is closed/disconnected
      'Could not establish connection. Receiving end does not exist.',
      // Expected error when the Dapp we are communicating with is closed
      // introduced in https://github.com/vacuumlabs/nufi/pull/1727
      'A listener indicated an asynchronous response by returning true, but the message channel closed before a response was received',
    ],
    beforeSend: (event, hint) => {
      if (hint) {
        const err = hint.originalException
        if (err) {
          // Ignore changelly min and max amount errors in sentry as they're expected
          // but log request limit errors, which have the same error code
          if (
            err instanceof ChangellyRequestError &&
            err.message.includes('Invalid amount for pair')
          ) {
            return null
          }
        }
      }
      return event
    },
  })
}

const SENTRY_USER_FEEDBACK_URL =
  'https://sentry.io/api/0/projects/vacuumlabs-sro/nufi/user-feedback/'

export const sendUserFeedback = async (
  eventId: string | null,
  name: string,
  email: string,
  userFeedback: string,
) => {
  // use fetch instead of our request which would fail because Sentry sends no response
  await fetch(SENTRY_USER_FEEDBACK_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `DSN ${config.appSentryDsn}`,
    },
    body: JSON.stringify({
      event_id: eventId,
      name,
      email,
      comments: userFeedback,
    }),
  })
}
