/* eslint-disable @typescript-eslint/explicit-function-return-type */
import type {MutationKey} from '@tanstack/react-query'
import _ from 'lodash'

import {cloudSyncServiceLocator} from 'src/features/profileSync/application'
import {useMutation} from 'src/utils/mutation-utils'

import {profileManagerLocator} from '../../appStorage'
import type {ProfileTokenImportBlockchain} from '../../blockchainTypes'
import type {TokenImport} from '../../types'
import {
  softDeleteToken,
  TokenImportStoreManagerProvider,
} from '../TokenImportStoreManager'

export async function _saveTokenImports(tokens: TokenImport[]): Promise<void> {
  const profileManager = profileManagerLocator.instance()
  await profileManager.saveProfileTokenImports(tokens)
  TokenImportStoreManagerProvider.instance().setTokens(tokens)

  await cloudSyncServiceLocator.instance().syncProfileData()
}

async function mutateTokens(fn: (tokens: TokenImport[]) => TokenImport[]) {
  const profileManager = profileManagerLocator.instance()
  const {tokenImports} = await profileManager.getProfileData()

  const updatedTokens = fn(tokenImports)
  const changedTokens = _.differenceWith(updatedTokens, tokenImports, _.isEqual)

  await _saveTokenImports(
    updatedTokens.map((t) =>
      changedTokens.includes(t) ? {...t, updatedAt: Date.now()} : t,
    ),
  )
}

export function _useForgetToken(
  blockchain: ProfileTokenImportBlockchain,
  invalidationKeys: MutationKey[],
) {
  return useMutation(
    [blockchain, 'forgetToken'],
    async ({token}: {token: TokenImport}) => {
      await mutateTokens((tokens) => softDeleteToken(tokens, token))
    },
    {invalidationKeys},
  )
}
