import React from 'react'

import type {Blockchain, TokenMetadata} from '../types'
import {useGetCoinName, useGetBlockchainName} from '../utils/translations'

import {AssetIcon} from './AssetIcon'
import {NFTBadge} from './visual/atoms/Badges'
import type {IconColSharedProps} from './visual/molecules/IconCol'
import {IconCol} from './visual/molecules/IconCol'

type AssetCardProps = IconColSharedProps & {
  blockchain: Blockchain
  label?: string | React.ReactNode // optional assetName is useful for tokens
  tokenMetadata?: TokenMetadata
  isNft?: boolean
  showBlockchainBadge?: boolean
}

export function AssetCard({
  blockchain,
  label,
  tokenMetadata,
  isNft,
  showBlockchainBadge,
  ...rest
}: AssetCardProps) {
  const getCoinName = useGetCoinName()
  const getBlockchainName = useGetBlockchainName()

  return (
    <IconCol
      Icon={
        <AssetIcon
          exactSize={30}
          blockchain={blockchain}
          tokenMetadata={tokenMetadata}
          showBlockchainBadge={showBlockchainBadge}
        />
      }
      title={label || getCoinName(blockchain)}
      titleIcon={isNft && <NFTBadge size="extra-small" />}
      description={getBlockchainName(blockchain)}
      {...rest}
    />
  )
}
