import {Sentry, getIsWebExtension} from '@nufi/frontend-common'
import {useState, useEffect} from 'react'

import {localProfileManagerLocator} from 'src/appStorage'
import masterProfileManager from 'src/appStorage/masterProfileManager'
import type {
  GetAutoLoginData,
  ReferrerInfo,
} from 'src/features/appOpener/domain'
import {createGetAutoLoginData} from 'src/features/appOpener/domain'
import {useLoginToProfile} from 'src/features/login'
import {useAutoLoginToMainAppMetamaskProfile} from 'src/features/login/application/metamask/mainApp'
import type {Web3AuthAutoLoginService} from 'src/features/login/services'
import {
  createWeb3AuthAutoLoginService,
  web3AuthLoginService,
} from 'src/features/login/services'
import {assert} from 'src/utils/assertion'

function useWithIsLoaded(
  asyncFn: () => Promise<unknown>,
  ignoreAutoLoginError: boolean,
) {
  const [isLoaded, setLoaded] = useState(false)

  useEffect(() => {
    const fn = async () => {
      try {
        await asyncFn()
        setLoaded(true)
      } catch (err) {
        Sentry.captureException(err)
        if (!ignoreAutoLoginError) {
          throw err
        } else {
          setLoaded(true)
        }
      }
    }
    fn()
  }, [])

  return {isLoaded}
}

type UseLoginToMetamaskProfileArgs = {
  skipBroadcastLogin: boolean
  onBeforeOnNewProfileLogin?: (logIn: () => void) => void
}

export type AppOpenerViewModelFactoryDeps = {
  ignoreAutoLoginError: boolean
  initWeb3AuthProfileFromAutoLoginData: Web3AuthAutoLoginService['initProfile']
  loadGetAutoLoginData: () => Promise<GetAutoLoginData>
  useLoginToLocalProfile: (options?: {
    skipBroadcastLogin: boolean
  }) => Pick<ReturnType<typeof useLoginToProfile>, 'onLogin'>
  useLoginToMetamaskProfile: (
    args: UseLoginToMetamaskProfileArgs,
  ) => () => Promise<void>
}

// This is an example of how it could look like, if we used ViewModel factories
// to pass all dependencies from outside.
// Due to the extra complexity avoid inspiring by this unless you have a good
// reason. Otherwise, only keep this as an example reference.
export function _createAppOpenerViewModel(deps: AppOpenerViewModelFactoryDeps) {
  const {
    ignoreAutoLoginError,
    loadGetAutoLoginData,
    useLoginToLocalProfile,
    useLoginToMetamaskProfile,
    initWeb3AuthProfileFromAutoLoginData,
  } = deps

  function usePasswordAutoLogin(
    referrerInfo: ReferrerInfo,
    skipBroadcastLogin: boolean,
  ) {
    const {onLogin} = useLoginToLocalProfile({skipBroadcastLogin})
    return useWithIsLoaded(async () => {
      if (referrerInfo.platform !== 'extension') return false
      const getAutoLoginData = await loadGetAutoLoginData()
      const {password, ...rest} = await getAutoLoginData(referrerInfo)
      assert(rest.loginType === 'password')
      return await onLogin({
        profileId: rest.profileId,
        password,
      })
    }, ignoreAutoLoginError)
  }

  function useWeb3AuthAutoLogin(
    referrerInfo: ReferrerInfo,
    skipBroadcastLogin: boolean,
  ) {
    const {onLogin} = useLoginToLocalProfile({skipBroadcastLogin})
    return useWithIsLoaded(async () => {
      const getAutoLoginData = await loadGetAutoLoginData()
      const autoLoginResult = await getAutoLoginData(referrerInfo)

      assert(autoLoginResult.loginType === 'web3Auth')

      const profileId = await initWeb3AuthProfileFromAutoLoginData(
        autoLoginResult,
        referrerInfo.platform,
      )

      const {password, web3AuthData} = autoLoginResult

      return await onLogin({
        profileId,
        password,
        web3AuthUserInfo: web3AuthData.userInfo,
      })
    }, ignoreAutoLoginError)
  }

  function useMetamaskAutoLogin(args: UseLoginToMetamaskProfileArgs) {
    const onLogin = useLoginToMetamaskProfile(args)
    return useWithIsLoaded(async () => await onLogin(), ignoreAutoLoginError)
  }

  return {usePasswordAutoLogin, useWeb3AuthAutoLogin, useMetamaskAutoLogin}
}

const loadGetAutoLoginData = (() => {
  let getAutoLoginData: GetAutoLoginData | null = null
  return async () => {
    if (getAutoLoginData) return getAutoLoginData

    if (getIsWebExtension()) {
      const {extensionAutoLoginStorage, extensionAutoLoginApi} = await import(
        'src/features/appOpener/infrastructure/webextension'
      )
      getAutoLoginData = createGetAutoLoginData(
        extensionAutoLoginApi,
        extensionAutoLoginStorage,
      )
    } else {
      const {webAutoLoginApi, webAutoLoginStorage} = await import(
        'src/features/appOpener/infrastructure/web'
      )
      getAutoLoginData = createGetAutoLoginData(
        webAutoLoginApi,
        webAutoLoginStorage,
      )
    }
    return getAutoLoginData
  }
})()

export type AppOpenerViewModel = ReturnType<typeof _createAppOpenerViewModel>

export const createAppOpenerViewModel = ({
  ignoreAutoLoginError,
}: {
  ignoreAutoLoginError: boolean
}): AppOpenerViewModel =>
  _createAppOpenerViewModel({
    initWeb3AuthProfileFromAutoLoginData: createWeb3AuthAutoLoginService({
      masterProfileManager,
      localProfileManagerLocator,
      web3AuthLoginService,
    }).initProfile,
    loadGetAutoLoginData,
    useLoginToMetamaskProfile: useAutoLoginToMainAppMetamaskProfile,
    useLoginToLocalProfile: useLoginToProfile,
    ignoreAutoLoginError,
  })
