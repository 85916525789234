import {Grid} from '@mui/material'
import {makeStyles} from '@mui/styles'
import React from 'react'
import {useTranslation} from 'react-i18next'

import type {AssetsSortBy, SortDirection} from 'src/features/assets'

import {
  Aligner,
  ListHeaderTitle,
  ListLayoutWrapper,
  ListHeaderWrapper,
  SortableHeader,
  NoOverflowAligner,
} from '../../../components'

type AssetListHeaderProps = {
  sortBy: AssetsSortBy
  setSortBy: (v: AssetsSortBy) => void
  sortDirection: SortDirection
  defaultSortDirection: SortDirection
}

export function AssetListHeader({
  sortBy,
  setSortBy,
  sortDirection,
  defaultSortDirection,
}: AssetListHeaderProps) {
  const {t} = useTranslation()
  const sortableHeaderProps = {
    sortDirection,
    sortBy,
    setSortBy,
    defaultSortDirection,
  }

  return (
    <ListHeaderWrapper>
      <AssetListItemLayout
        assetCol={
          <SortableHeader
            label={t('Asset')}
            column="sortingLabel"
            {...sortableHeaderProps}
          />
        }
        priceCol={
          <SortableHeader
            label={t('Price (24h)')}
            column="price"
            {...sortableHeaderProps}
          />
        }
        totalBalanceCol={
          <SortableHeader
            label={t('Total balance')}
            column="totalBalanceToCurrency"
            {...sortableHeaderProps}
          />
        }
        availableBalanceCol={
          <SortableHeader
            label={t('Available balance')}
            column="availableBalanceToCurrency"
            {...sortableHeaderProps}
          />
        }
        actionsCol={<ListHeaderTitle>{t('Actions')}</ListHeaderTitle>}
      />
    </ListHeaderWrapper>
  )
}

type AssetListItemLayoutProps = {
  assetCol: React.ReactNode
  totalBalanceCol: React.ReactNode
  availableBalanceCol: React.ReactNode
  priceCol: React.ReactNode
  actionsCol: React.ReactNode
}

export function AssetListItemLayout({
  priceCol,
  actionsCol,
  assetCol,
  availableBalanceCol,
  totalBalanceCol,
}: AssetListItemLayoutProps) {
  const classes = useAssetListItemLayoutStyles()

  return (
    <ListLayoutWrapper>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item xs={3}>
          <NoOverflowAligner align="left">{assetCol}</NoOverflowAligner>
        </Grid>
        <Grid item xs={2}>
          <Aligner align="right">{priceCol}</Aligner>
        </Grid>
        <Grid item xs={2}>
          <Aligner itemClassName={classes.balanceContainer} align="right">
            {totalBalanceCol}
          </Aligner>
        </Grid>
        <Grid item xs={2}>
          <Aligner itemClassName={classes.balanceContainer} align="right">
            {availableBalanceCol}
          </Aligner>
        </Grid>

        <Grid item xs={3}>
          <Aligner align="right">{actionsCol}</Aligner>
        </Grid>
      </Grid>
    </ListLayoutWrapper>
  )
}

const useAssetListItemLayoutStyles = makeStyles((theme) => ({
  balanceContainer: {
    maxWidth: '100%',
    paddingLeft: theme.spacing(2),
  },
}))
