import {Box} from '@mui/material'
import React from 'react'

import {MutationGuard} from '../../../../../components'
import {
  TxErrorBody,
  TxLoadingBody,
} from '../../../../../pages/transaction/common'
import type {useSubmitTransaction} from '../../../../../wallet/cardano'
import {RejectButton} from '../../../../components'

type SubmitTxScreenProps = {
  submitProps: ReturnType<typeof useSubmitTransaction>
  onRetry: () => void
  onRejected: () => void
}

export function SubmitTxScreen({
  submitProps,
  onRetry,
  onRejected,
}: SubmitTxScreenProps) {
  const isSubmitting = submitProps.isPending

  return (
    <div>
      {isSubmitting && (
        <Box mt={4}>
          <TxLoadingBody
            action="submitting"
            blockchain={'cardano'}
            isDappConnectorWindow
          />
        </Box>
      )}
      <MutationGuard
        {...submitProps}
        hideLoader
        ErrorElement={
          <>
            <TxErrorBody
              error={submitProps.error}
              blockchain={'cardano'}
              onRetry={onRetry}
              type="submit"
              size="small"
            />
            <Box pb={2}>
              <RejectButton variant="error" onClick={onRejected} />
            </Box>
          </>
        }
      />
    </div>
  )
}
