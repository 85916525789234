import React, {useEffect, useState} from 'react'

import {FullScreenLoading} from '../../../../components'
import type {AppError} from '../../../../types'
import {sleep} from '../../../../utils/helpers'

type SubmitGuardProps = {
  error: AppError | null
  onSuccess: () => void
  onFailure: () => void
  sleepTimeout: number
  finished: boolean
}
/*
Due to possible race-condition with debounced forms calculations we do the following after submit:

* sleep for 2 x debounceTimeout, so that final debounced function is already running (or finished)
  when sleep is finished
* if some debounced call is still calculating after sleep, wait (!finished)
* disable UI (using full screen loading) while sleeping or loading

This allows the user to submit the form even if some debounced function was not yet calculated,
resulting in better UX & also avoiding race-conditions.
*/
export function SubmitGuard({
  error,
  sleepTimeout,
  onSuccess,
  onFailure,
  finished,
}: SubmitGuardProps) {
  const [initialWaiting, setInitialWaiting] = useState(true)

  useEffect(() => {
    // If error fail immediately
    if (error) {
      onFailure()
      return
    }

    if (!initialWaiting && finished) {
      onSuccess()
    }
  }, [error, finished, initialWaiting])

  // Dependent only on `isInitialWait` so that timeout is not called more than once
  useEffect(() => {
    if (initialWaiting) {
      sleep(sleepTimeout).then(() => {
        setInitialWaiting(false)
      })
    }
  }, [initialWaiting])

  return <FullScreenLoading />
}
