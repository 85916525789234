import {Launch as ExternalIcon} from '@mui/icons-material'
import type {SolanaTokenId} from '@nufi/wallet-solana'
import React from 'react'
import {useTranslation} from 'react-i18next'

import config from '../../../config'
import {ExplorerLink, useCommonStyles, ValidatorLink} from '../common'

import {
  getSolanaStakeAccountLink,
  getSolanaTokenExplorerLink,
  getSolanaTransactionExplorerLink,
} from './getLink'

export * from './getLink'

type SolanaTransactionExplorerLinkProps = {
  txId: string
}

export function SolanaTransactionExplorerLink({
  txId,
}: SolanaTransactionExplorerLinkProps) {
  return <ExplorerLink link={getSolanaTransactionExplorerLink(txId)} />
}

type SolanaTokenExplorerLinkProps = {
  tokenId: SolanaTokenId
}

export function SolanaTokenExplorerLink({
  tokenId,
}: SolanaTokenExplorerLinkProps) {
  return <ExplorerLink link={getSolanaTokenExplorerLink(tokenId)} />
}

type SolanaValidatorLinkProps = {
  validatorId: string
  validatorName: React.ReactNode
}

export function SolanaValidatorLink({
  validatorId,
  validatorName,
}: SolanaValidatorLinkProps) {
  return (
    <ValidatorLink
      link={`https://solanabeach.io/validator/${validatorId}?cluster=${config.solanaClusterName}`}
      linkText={validatorName}
    />
  )
}

type SolanaStakeAccountLinkProps = {
  stakeAccount: string
}

export function SolanaStakeAccountLink({
  stakeAccount,
}: SolanaStakeAccountLinkProps) {
  const {t} = useTranslation()
  const classes = useCommonStyles()
  return (
    <a
      target="_blank"
      href={getSolanaStakeAccountLink(stakeAccount)}
      className={classes.link}
    >
      {t('Stake account')}
      <ExternalIcon color="primary" className={classes.icon} />
    </a>
  )
}
