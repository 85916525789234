import type {FlowTokenMetadata} from '@nufi/wallet-flow'
import React from 'react'

import type {TokenBlockchain} from '../../blockchainTypes'
import type {TokenMetadata} from '../../types'
import {safeAssertUnreachable} from '../../utils/assertion'
import type {CardanoTokenMetadata} from '../../wallet/cardano'
import type {EvmTokenMetadata} from '../../wallet/evm'
import {isEvmBlockchain} from '../../wallet/evm'
import type {SolanaTokenMetadata} from '../../wallet/solana'
import {ConstantStringEllipsis} from '../visual/atoms/TextUtils'

import {
  FormattedCardanoTokenLabel,
  FormattedEvmTokenLabel,
  FormattedFlowTokenLabel,
} from './FormattedTokenLabel'

type WrapperProps = {
  value: string
  ellipsize?: boolean
  ellipsizeLength?: number
}

function EllipsizeWrapper({value, ellipsize, ellipsizeLength}: WrapperProps) {
  return ellipsize ? (
    <ConstantStringEllipsis value={value} length={ellipsizeLength} />
  ) : (
    <>{value}</>
  )
}

type FormattedTokenNameProps = {
  blockchain: TokenBlockchain
  tokenInfo: TokenMetadata
  ellipsize?: boolean
  ellipsizeLength?: number
}

export function FormattedTokenName({
  blockchain,
  tokenInfo,
  ellipsize,
  ellipsizeLength,
}: FormattedTokenNameProps) {
  if (tokenInfo.name != null) {
    return (
      <EllipsizeWrapper
        ellipsize={ellipsize}
        value={tokenInfo.name}
        {...{ellipsizeLength}}
      />
    )
  } else {
    switch (blockchain) {
      case 'solana':
        return (
          <EllipsizeWrapper
            ellipsize={ellipsize}
            value={(tokenInfo as SolanaTokenMetadata).mint}
          />
        )
      case 'cardano':
        return (
          <FormattedCardanoTokenLabel
            ellipsize={ellipsize}
            tokenInfo={tokenInfo as CardanoTokenMetadata}
          />
        )
      case 'flow':
        return (
          <FormattedFlowTokenLabel tokenInfo={tokenInfo as FlowTokenMetadata} />
        )
      default: {
        if (isEvmBlockchain(blockchain)) {
          return (
            <FormattedEvmTokenLabel
              tokenInfo={tokenInfo as EvmTokenMetadata<typeof blockchain>}
            />
          )
        }
        return safeAssertUnreachable(blockchain)
      }
    }
  }
}
