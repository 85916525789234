import {
  useGetTxFeeParams,
  useGetTokensByAccount,
  useGetTokenAccountRent,
  useGetAddressInfo,
  useSubmitTransfer,
  useSignTransferAssets,
} from '../../../wallet/solana'

export const solanaSendModalViewModel = {
  useGetTokensByAccount,
  useGetTxFeeParams,
  useGetTokenAccountRent,
  useGetAddressInfo,
  useSubmitTransfer,
  useSignTransferAssets,
}

export type SolanaSendModalViewModel = typeof solanaSendModalViewModel
