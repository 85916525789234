import {safeAssertUnreachable} from '@nufi/frontend-common'
import type {EvmBlockchain} from '@nufi/wallet-evm'

import i18n from 'src/translations/i18n'
import type {Blockchain} from 'src/types'

export type StructuredNotificationId = {
  blockchain: Blockchain
  txId: string
}

const createChromeNotificationId = (
  blockchain: Blockchain,
  txId: string,
): string => {
  const parsed: StructuredNotificationId = {
    blockchain,
    txId,
  }
  return JSON.stringify(parsed)
}

type EvmNotificationData = {
  nonce: number
  type: 'failed' | 'success' | 'dropped'
  blockchain: EvmBlockchain
  txHash: string
}

// Make sure importing this function doesn't trigger any side effects that fail outside of
// the extension environment, such as accessing window.chrome properties.
export const createEvmChromeNotification = ({
  blockchain,
  txHash,
  nonce,
  type,
}: EvmNotificationData) => {
  const txId = createChromeNotificationId(blockchain, txHash)

  const title = (() => {
    switch (type) {
      case 'dropped':
        return i18n.t('evm_notification_dropped', {nonce})
      case 'failed':
        return i18n.t('evm_notification_failed', {nonce})
      case 'success':
        return i18n.t('evm_notification_success', {nonce})
      default:
        return safeAssertUnreachable(type)
    }
  })()

  // Does nothing in case of web app or not granted permissions
  chrome?.notifications?.create(txId, {
    iconUrl: 'icons/96x96.png',
    type: 'basic',
    title,
    message: i18n.t('Open blockchain explorer'),
    priority: 2,
    requireInteraction: false,
  })
}
