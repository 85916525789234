import {getIsWebExtension} from '@nufi/frontend-common'
import React from 'react'

import config from 'src/config'

import {externalLinks} from '../../../constants'
/*
eslint-disable max-len
*/

export const newsData = [
  {
    appVersion: '13.0.0',
    date: new Date('2024-08-15'),
    heading: 'Release 13.0.0',
    body: (
      <div>
        <h3>New features:</h3>
        <ul>
          <li>Added Base chain support</li>
          <li>Added native support of Solana Blinks</li>
          <li>Added WalletConnect support for EVM DApps</li>
          <li>New transaction details design in DApp connector window</li>
        </ul>
        <h3>Fixes:</h3>
        <ul>
          <li>Fixed missing rates for some Cardano tokens</li>
        </ul>
      </div>
    ),
  },
  {
    appVersion: '12.0.0',
    date: new Date('2024-07-25'),
    heading: 'Release 12.0.0',
    body: (
      <div>
        <h3>New features:</h3>
        <ul>
          <li>
            Added support for GridPlus Lattice1 hardware wallet on Solana and
            EVM chains
          </li>
          <li>
            You can now customize which blockchains to show in your NuFi wallet
          </li>
        </ul>
        <h3>Fixes:</h3>
        <ul>
          <li>
            Fixed transactions being sometimes invalid when sending max SOL
          </li>
          <li>Fixed transaction signing issues on Magic Eden dApp</li>
        </ul>
      </div>
    ),
  },
  {
    appVersion: '11.0.0',
    date: new Date('2024-07-11'),
    heading: 'Release 11.0.0',
    body: (
      <div>
        <h3>New features:</h3>
        <ul>
          <li>Added Arbitrum chain support</li>
          <li>New design of social account login options</li>
        </ul>
        <h3>Fixes:</h3>
        <ul>
          <li>
            Fixed missing or incorrect price change over 24 hours for tokens
          </li>
          <li>
            Fixed connection of web extension with Trezor if Trezor Bridge was
            not installed
          </li>
        </ul>
      </div>
    ),
  },
  {
    appVersion: '10.0.0',
    date: new Date('2024-06-18'),
    heading: 'Release 10.0.0',
    body: (
      <div>
        <h3>New features:</h3>
        <ul>
          <li>Added Optimism chain support</li>
          {!getIsWebExtension() && (
            <li>
              You can now buy/sell crypto for fiat without leaving the app
            </li>
          )}
          <li>New design of EVM accounts management</li>
          <li>
            Improved error handling and stability when submitting Solana
            transactions
          </li>
          <li>
            Recent rewards shown in Solana staking overview now link to
            blockchain explorer, providing better insight
          </li>
          <li>
            You can now view/export your 24-word recovery phrase when logged in
            via social login
          </li>
          <li>
            Deprecated less-used login options (Twitch, Apple, Github, Linkedin,
            Line & WeChat) to focus on most popular social accounts. If you need
            to access NuFi via any of these providers, contact us at{' '}
            <a href="mailto:help@nu.fi">help@nu.fi</a>
          </li>
        </ul>
        <h3>Fixes:</h3>
        <ul>
          <li>Fixed app crashing when trying to import Flow tokens</li>
          <li>Fixed missing DEX logos in DexHunter Orders History</li>
          <li>
            Fixed edge-case that could result in broken creation of Solana stake
            account
          </li>
        </ul>
      </div>
    ),
  },
  {
    appVersion: '9.4.0',
    date: new Date('2024-05-27'),
    heading: 'Release 9.4.0',
    body: (
      <div>
        <h3>New features:</h3>
        <ul>
          <li>DApp connector now warns about potential malicious sites</li>
          <li>Sped up loading of Solana staking overview</li>
          <li>
            In order to improve app performance, show Solana staking rewards
            over last 3 epochs instead of all-time rewards
          </li>
          <li>Updated Solana token list</li>
        </ul>
        <h3>Fixes:</h3>
        <ul>
          <li>
            Fixed wrong transaction details if Solana transaction simulation in
            the DApp connector failed
          </li>
          <li>Fixed possible app crashing if forgetting accounts</li>
          <li>Fixed missing names of Flow validators when staking Flow</li>
          <li>Fixed missing epoch info in Flow staking overview</li>
        </ul>
      </div>
    ),
  },
  {
    appVersion: '9.3.0',
    date: new Date('2024-05-03'),
    heading: 'Release 9.3.0',
    body: (
      <div>
        <h3>New features:</h3>
        <ul>
          <li>DexHunter now available also in NuFi web</li>
          <li>Added DexHunter Order History</li>
          <li>
            Add support for sending Magic Eden Open Creator Protocol Solana NFTs
          </li>
          <li>Improved details about transactions from Solana DApps</li>
          <li>Add price change over 24 hours to price column in Portfolio</li>
        </ul>
        <h3>Fixes:</h3>
        <ul>
          <li>Fix Ledger pairing in DApp connector window</li>
          <li>Fix slow loading of staking information for Solana</li>
          <li>
            Fix Cardano balance in DApps for accounts with multiple addresses
          </li>
        </ul>
      </div>
    ),
  },
  {
    appVersion: '9.2.0',
    date: new Date('2024-04-11'),
    heading: 'Release 9.2.0',
    body: (
      <div>
        <h3>New features:</h3>
        <ul>
          <li>
            Boost tx submission success of Solana DApp transactions via NuFi RPC
            (experimental)
          </li>
          <li>Support Compressed NFTs on Solana</li>
          <li>Allow internal swaps for Solana tokens</li>
          <li>NuFi is now directly supported by most of the EVM dApps</li>
          <li>Use colorful icons in exchange</li>
          <li>Sort portfolio assets by USD value</li>
          <li>Autodiscover more Flow tokens</li>
          <li>
            Use{' '}
            <a target="_blank" href="https://solscan.io">
              solscan.io
            </a>{' '}
            as a default Solana explorer
          </li>
          <li>
            Add transaction explorer links to the dapp connector and asset
            detail screens
          </li>
          <li>Shorten asset decimals in Portfolio</li>
        </ul>
        <h3>Fixes:</h3>
        <ul>
          <li>Keep original wallet icons during Cardano wallet emulation</li>
          <li>Speedup Solana amount load in Portfolio</li>
          <li>Fix EVM notifications</li>
        </ul>
      </div>
    ),
  },
  {
    appVersion: '9.1.0',
    date: new Date('2024-03-07'),
    heading: 'Release 9.1.0',
    body: (
      <div>
        <h3>New features:</h3>
        <ul>
          <li>Faster Solana transactions due to setting priority fees</li>
          <li>Allow adding new account from Asset detail screen</li>
          <li>Allow hiding zero balance accounts</li>
          <li>Warn when sending EVM funds to contract address</li>
        </ul>
        <h3>Fixes:</h3>
        <ul>
          <li>Fixed unreliable/failing NFT page for Solana</li>
          <li>Fixed Solana staking not loading if having too many accounts</li>
        </ul>
      </div>
    ),
  },
  {
    appVersion: '9.0.0',
    date: new Date('2024-02-15'),
    heading: 'Release 9.0.0',
    body: (
      <div>
        <h3>New features:</h3>
        <ul>
          {config.isWeb3AuthV2MigrationEnabled && (
            <li>Streamlined social login experience</li>
          )}
          <li>
            In-app DEX for Cardano tokens powered by{' '}
            <a target="_blank" href="https://www.dexhunter.io/">
              DexHunter
            </a>
          </li>
          <li>Added support for Solana Token22 standard</li>
          <li>Faster loading for Solana tokens</li>
        </ul>
        <h3>Fixes:</h3>
        <ul>
          <li>Fixed swapping of EVM tokens in the Exchange</li>
          <li>
            Fixed Cardano DApp connector refusing to sign some transactions
          </li>
        </ul>
      </div>
    ),
  },
  {
    appVersion: '8.1.0',
    date: new Date('2024-01-19'),
    heading: 'Release 8.1.0',
    body: (
      <div>
        <h3>New features:</h3>
        <ul>
          <li>Added support for Solana Programmable NFTs (pNFTs)</li>
          <li>Streamlined Solana staking process to take just one step</li>
          <li>
            Flint wallet emulation for DApp connector is now off by default
          </li>
          <li>Lilico emulation renamed to "Flow Reference Wallet"</li>
        </ul>
        <h3>Fixes:</h3>
        <ul>
          <li>Fixed multiple bugs related to swapping Polygon</li>
          <li>Fixed missing fiat rates for multiple Solana tokens</li>
        </ul>
      </div>
    ),
  },
  {
    appVersion: '8.0.2',
    date: new Date('2023-01-10'),
    heading: 'Cardano users, we need your help',
    body: (
      <div>
        <p>
          The NuFi team has submitted 2 very special proposals in Project
          Catalyst (Cardano’s on-chain funding initiative) and your support can
          help us win funding.
        </p>
        <p>
          One proposal –{' '}
          <a target="_blank" href="https://cardano.ideascale.com/c/idea/114276">
            Google Wallet
          </a>{' '}
          – is for a service for Cardano dApps that enables Web2 users/crypto
          beginners to connect to a dApp simply by logging in with a social
          account like Google, Twitter, or Facebook – no Cardano wallet needed!
          The other proposal –{' '}
          <a target="_blank" href="https://cardano.ideascale.com/c/idea/113367">
            Cardano in MetaMask
          </a>{' '}
          – is a service that adds MetaMask wallet support to Cardano dApps and
          enables MetaMask’s 30 million users to onboard into Cardano using just
          their MetaMask wallet (with no need to install anything else).
        </p>
        <p>
          <b>
            To vote in Project Catalyst Fund11 and support our proposals, you
            need to register for Project Catalyst using AdaLite (our Cardano
            legacy wallet) before January 15th 2024.
          </b>{' '}
          <a
            target="_blank"
            href="https://nufi-official.medium.com/2024-the-year-the-world-discovered-cardano-thanks-to-nufis-project-catalyst-proposals-14cdeda63f1e"
          >
            In this article
          </a>
          , we’ve explained our groundbreaking ideas and included a how-to guide
          for registering/voting in Project Catalyst.
        </p>
        <p>Many thanks, The AdaLite/NuFi team</p>
      </div>
    ),
  },
  {
    appVersion: '8.0.0',
    date: new Date('2023-12-20'),
    heading: 'Release 8.0.0',
    body: (
      <div>
        <h3>New features:</h3>
        <ul>
          <li>Trezor hardware wallet can now be used also with Solana</li>
          <li>Added WalletConnect support for Solana</li>
        </ul>
        <h3>Fixes:</h3>
        <ul>
          <li>Fixed missing fiat values for some Cardano tokens</li>
        </ul>
      </div>
    ),
  },
  {
    appVersion: '7.2.0',
    date: new Date('2023-12-13'),
    heading: 'Release 7.2.0',
    body: (
      <div>
        <h3>New features:</h3>
        <ul>
          <li>
            Added support for Solana Wallet Standard which makes the NuFi
            extension visible to more DApps
          </li>
        </ul>
        <h3>Fixes:</h3>
        <ul>
          <li>Fixed missing fiat rates for some Solana tokens</li>
        </ul>
      </div>
    ),
  },
  {
    appVersion: '7.1.0',
    date: new Date('2023-11-20'),
    heading: 'Release 7.1.0',
    body: (
      <div>
        <h3>New features:</h3>
        <ul>
          <li>Added support for Flow BR token</li>
        </ul>
        <h3>Fixes:</h3>
        <ul>
          <li>Fixed NuFi extension sometimes not being detected by DApps</li>
          <li>Flow transaction history available again</li>
          <li>Fixed sending of Flow USDC token</li>
          <li>Fixed cloud sync in DApp connector window</li>
        </ul>
      </div>
    ),
  },
  {
    appVersion: '7.0.9',
    date: new Date('2023-10-20'),
    heading: 'Release 7.0.9',
    body: (
      <div>
        <h3>New features:</h3>
        <ul>
          <li>Added REVV Flow token</li>
        </ul>
        <h3>Fixes:</h3>
        <ul>
          <li>Replaced Flowscan Flow explorer for Flowdiver</li>
        </ul>
      </div>
    ),
  },
  {
    appVersion: '7.0.6',
    date: new Date('2023-09-28'),
    heading: 'Release 7.0.6',
    body: (
      <div>
        <h3>New features:</h3>
        <ul>
          <li>Added adadomains support for Cardano</li>
          <li>Added blockchain badge for selected account/asset in Exchange</li>
        </ul>
        <h3>Fixes:</h3>
        <ul>
          <li>Fixed social login on web</li>
        </ul>
      </div>
    ),
  },
  {
    appVersion: '7.0.4',
    date: new Date('2023-09-07'),
    heading: 'Project Catalyst Fund10 voting ends September 14th!',
    body: (
      <div>
        <div>
          If you’re voting, please cast your votes before September 14th, and
          please consider supporting NuFi's proposal: a toolkit that DApps can
          integrate to onboard Web2 and non-crypto users into Cardano (with this
          toolkit, anyone with a social account or email address can connect to
          a DApp simply by logging in). Read more about our proposal and its
          impact on Cardano{' '}
          <a
            target="_blank"
            href="https://nufi-official.medium.com/how-nufis-project-catalyst-proposal-can-onboard-cardano-s-next-1-million-web2-users-4745939c2801"
          >
            here
          </a>
          , and contact us at <a href="mailto:help@nu.fi">help@nu.fi</a> if you
          need any assistance.
        </div>{' '}
      </div>
    ),
  },
  {
    appVersion: '7.0.1',
    date: new Date('2023-08-10'),
    heading: 'We need your help!',
    body: (
      <div>
        <div>
          The NuFi team has submitted a very special proposal in Project
          Catalyst – Cardano’s on-chain funding initiative – and, with your
          support, we can win funding to build a toolkit for DApps to onboard
          Web2 users/crypto beginners into Cardano; users will be able to
          connect to DApps simply by logging in with a social account or email
          address, so even Grandma can get into crypto!{' '}
        </div>{' '}
        <br />
        <div>
          <b>
            To vote in Project Catalyst Fund10 and support our proposal, you
            need to register before August 18th using AdaLite{' '}
          </b>
          (our Cardano legacy wallet). We’ve explained our groundbreaking idea
          and included a how-to guide for registering/voting in Project Catalyst
          in{' '}
          <a
            href="https://nufi-official.medium.com/how-nufis-project-catalyst-proposal-can-onboard-cardano-s-next-1-million-web2-users-4745939c2801"
            target="_blank"
          >
            this article here.
          </a>
        </div>
        <br />
        <div>
          <b>Many thanks, The NuFi team</b>
        </div>
      </div>
    ),
  },
  {
    appVersion: '7.0.1',
    date: new Date('2023-08-10'),
    heading: 'Release 7.0.1',
    body: (
      <div>
        <h3>Fixes:</h3>
        <ul>
          <li>
            WalletConnect connection with MinSwap adn other general improvements
          </li>
          <li>
            Fixed Solana ledger blind signing issue for token and multi-asset
            transactions
          </li>
        </ul>
      </div>
    ),
  },
  {
    appVersion: '7.0.0',
    date: new Date('2023-07-27'),
    heading: 'Release 7.0.0',
    body: (
      <div>
        <h3>New features:</h3>
        <ul>
          <li>
            WalletConnect support added: Now you can also connect your NuFi web
            wallet directly to DApps thanks to WalletConnect. Currently, this is
            a beta feature and Cardano DApps are supported. More blockchains
            coming soon!{' '}
            <a
              href="https://support.nu.fi/support/solutions/articles/80001084598"
              target="_blank"
            >
              Learn more
            </a>
          </li>
        </ul>
      </div>
    ),
  },
  {
    appVersion: '6.2.0',
    date: new Date('2023-06-01'),
    heading: 'Release 6.2.0',
    body: (
      <div>
        <h3>New features:</h3>
        <ul>
          <li>
            Milkomeda C1 transaction history now available inside the wallet
          </li>
          <li>
            Show 'Back to DApp' button inside wallet when navigating to wallet
            from DApp connector
          </li>
          <li>
            Show reload button in the DApp connector header when user is logged
            in
          </li>
          <li>
            Loading indicator and Reload button now separate, so reload button
            is always clickable
          </li>
          <li>
            When opening Exchange, chosen receiving account is now pre-selected
          </li>
          <li>
            When opening Exchange, chosen coin is now pre-selected. Also, chosen
            token is pre-selected if we can verify its ticker with Changelly,
            otherwise the token's blockchain is pre-selected
          </li>
          <li>
            Disable Exchange button for assets that aren't supported by
            Changelly
          </li>
        </ul>
        <h3>Fixes:</h3>
        <ul>
          <li>Fixed fees validation when sending assets</li>
          <li>Fixed inactive "Exchange" button for external swaps</li>
          <li>
            Auto-close DApp connector's Settings screen when user action is
            needed (e.g. Sign a transaction)
          </li>
        </ul>
      </div>
    ),
  },
  {
    appVersion: '6.1.0',
    date: new Date('2023-05-22'),
    heading: 'Release 6.1.0',
    body: (
      <div>
        <h3>New features:</h3>
        <ul>
          <li>Significantly improved wallet loading time</li>
          <li>
            Improved user experience when navigating between DApp connector and
            browser extension wallet
          </li>
          <li>Allow users to overwrite an existing wallet profile</li>
          <li>
            Create a new Flow account if chosen while creating a new wallet
          </li>
          <li>Display native asset balance when sending tokens</li>
          <li>EVM tokens can now be imported from the DApp connector</li>
        </ul>
        <h3>Fixes:</h3>
        <ul>
          <li>Fixed exchanging of EVM assets</li>
          <li>Fixed exchanging of GLQ and EGLQ assets.</li>
          <li>Fixed duplicate items in DApp directory</li>
          <li>Allow sending EVM tokens with more than 9 decimals</li>
          <li>Fixed maximum Cardano transaction size</li>
        </ul>
      </div>
    ),
  },
  {
    appVersion: '6.0.2',
    date: new Date('2023-05-03'),
    heading: 'Release 6.0.2',
    body: (
      <div>
        <h3>New features:</h3>
        <ul>
          <li>
            You can now log into the wallet right from the DApp connector window
          </li>
        </ul>
        <h3>Fixes:</h3>
        <ul>
          <li>Fixed bug in amount validation when sending Ethereum NFTs</li>
          <li>
            Fixed synchronization of seedless wallets when logging in through
            the DApp connector
          </li>
          <li>Fixed swapping of EGLQ token with Changelly</li>
        </ul>
      </div>
    ),
  },
  {
    appVersion: '6.0.0',
    date: new Date('2023-04-20'),
    heading: 'Release 6.0.0',
    body: (
      <div>
        <h3>New features:</h3>
        <ul>
          <li>Polygon blockchain support</li>
          <li>Support for Flow CSV export</li>
          <li>Crypto can be now bought from the DApp connector window</li>
        </ul>
        <h3>Fixes:</h3>
        <ul>
          <li>Fixed exchanging from hw wallet accounts and seedless wallets</li>
          <li>Fixed missing entries/times in Solana staking history</li>
          <li>Fixed portfolio value computation to include tokens as well</li>
        </ul>
      </div>
    ),
  },
  {
    appVersion: '5.0.0',
    date: new Date('2023-03-30'),
    heading: 'Release 5.0.0',
    body: (
      <div>
        <h3>Updates:</h3>
        <ul>
          <li>
            Our terms and conditions have been updated. You can review them{' '}
            <a href={externalLinks.termsAndConditionsPDF} target="_blank">
              here
            </a>
          </li>
        </ul>
        <h3>New features:</h3>
        <ul>
          <li>
            Log in with a social account like Google to create a Web3 wallet
            with no seed phrase to store.{' '}
            <a
              href="https://support.nu.fi/a/solutions/articles/80001070723"
              target="_blank"
            >
              Learn more
            </a>
          </li>
          <li>
            DApp connector now shows EVM transaction effects on wallet balance
            (powered by Alchemy)
          </li>
        </ul>
        <h3>Fixes:</h3>
        <ul>
          <li>
            Fixed broken validation when sending EVM asset amounts exceeding
            1000
          </li>
          <li>
            Fixed exchange history crashes for assets currently not listed by
            Changelly
          </li>
          <li>Faster loading of assets in the portfolio screen</li>
        </ul>
      </div>
    ),
  },
  {
    appVersion: '4.3.0',
    date: new Date('2023-03-10'),
    heading: 'Release 4.3.0',
    body: (
      <div>
        <h3>New features:</h3>
        <ul>
          <li>
            DApp directory section to browse for popular DApps (extension)
          </li>
          <li>
            Choose which blockchains to add accounts for when creating a new
            wallet
          </li>
          <li>Display more information when importing Flow token</li>
        </ul>
        <h3>Fixes:</h3>
        <ul>
          <li>Fixed EVM asset detail loading</li>
          <li>Fixed duplicated items in Flow transactions export</li>
          <li>Fixed changing wallet profiles in DApp connector</li>
          <li>
            Prevent changing EVM blockchain and account from DApp connector
            screen during signing
          </li>
          <li>Improved DApp connector experience on mobile devices</li>
          <li>Faster login into the wallet</li>
        </ul>
      </div>
    ),
  },
  {
    appVersion: '4.2.0',
    date: new Date('2023-02-16'),
    heading: 'Release 4.2.0',
    body: (
      <div>
        <h3>New features:</h3>
        <ul>
          <li>
            Show notifications in Chrome browser for transactions executed with
            DApps (when Pending/Completed/Failed)
          </li>
          <li>
            Flow Reference Wallet emulation: connect NuFi to any Flow DApp that
            supports Flow Reference Wallet
          </li>
          <li>Simplified process for adding new accounts</li>
          <li>
            Allow choosing derivation path when adding Solana mnemonic account
          </li>
        </ul>
        <h3>Fixes:</h3>
        <ul>
          <li>
            Improved performance on Portfolio screens for wallets with many
            assets
          </li>
          <li>Fixed max amount delegation for Flow staking accounts</li>
        </ul>
      </div>
    ),
  },
  {
    appVersion: '4.1.0',
    date: new Date('2023-01-26'),
    heading: 'Release 4.1.0',
    body: (
      <div>
        <h3>New features:</h3>
        <ul>
          <li>Add multiple accounts at once when pairing hardware wallet</li>
          <li>Send Flow assets to .find names</li>
          <li>Display native blockchain badge next to token icons</li>
          <li>Support of Flow account ownership proof to DApps</li>
          {/* cspell:disable-next-line */}
          <li>Switched Cardano explorer from Cardanoscan to Cexplorer</li>
        </ul>
        <h3>Fixes:</h3>
        <ul>
          <li>Improved stability of EVM DApp connector</li>
          <li>Fixed Milkomeda price history graph</li>
          <li>
            Stopped adding Ethereum and Milkomeda “legacy” accounts during
            wallet creation
          </li>
        </ul>
      </div>
    ),
  },
  {
    appVersion: '4.0.0',
    date: new Date('2023-01-16'),
    heading: 'Release 4.0.0',
    body: (
      <div>
        <h3>New features:</h3>
        <ul>
          <li>Added support for Milkomeda C1 sidechain</li>
          <ul>
            <li>Send and receive MilkADA and tokens</li>
            <li>Connect to Milkomeda DApps (using browser extension)</li>
            <li>Import custom ERC-20 tokens</li>
          </ul>
          <li>
            "Keep me logged in" option when using DApps to skip entering your
            password for each transaction
          </li>
          <li>Support for more fungible tokens on Flow blockchain</li>
          <li>Display account address in account select field</li>
        </ul>
        <h3>Fixes:</h3>
        <ul>
          <li>Fixed Cardano token price calculations for low volume tokens</li>
          <li>Added blockchain explorer link to NFT details</li>
          <li>Added time field to transactions history export</li>
          <li>
            Warning about insufficient funds for EVM transactions executed via
            DApps
          </li>
        </ul>
      </div>
    ),
  },
  {
    appVersion: '3.0.0',
    date: new Date('2022-12-01'),
    heading: 'Release 3.0.0',
    body: (
      <div>
        <h3>New features:</h3>
        <ul>
          <li>Ethereum blockchain integration</li>
          <ul>
            <li>Send and receive ETH and tokens</li>
            <li>Connect to Ethereum DApps (using browser extension)</li>
            <li>Visually manage Ethereum NFTs using the NFT gallery</li>
            <li>Import custom ERC-20 tokens</li>
            <li>Browse transactions history</li>
          </ul>
          <li>Added support for Flow fungible tokens</li>
          <li>Added pagination for Flow NFTs</li>
          <li>Added DApp wallet overrides into Settings of the main app</li>
          <li>Restyled DApp connector toolbar</li>
          <li>Improved fuzzy search when importing a token</li>
        </ul>
        <h3>Fixes:</h3>
        <ul>
          <li>Fixed account selection in transactions export</li>
        </ul>
      </div>
    ),
  },
  {
    appVersion: '2.6.0',
    date: new Date('2022-11-10'),
    heading: 'Release 2.6.0',
    body: (
      <div>
        <h3>New features:</h3>
        <ul>
          <li>
            NuFi can now emulate Eternl wallet when connecting to Cardano DApps
          </li>
          <li>Show explorer link for Flow tokens</li>
          <li>
            Added button to switch the 'from/to' assets when using the Exchange
          </li>
        </ul>
        <h3>Fixes:</h3>
        <ul>
          <li>Speed up login to app</li>
          <li>Some NFT thumbnails were not showing</li>
          <li>Fixed interference with certain Ethereum DApps</li>
        </ul>
      </div>
    ),
  },
  {
    appVersion: '2.5.0',
    date: new Date('2022-10-19'),
    heading: 'Release 2.5.0',
    body: (
      <div>
        <h3>New features:</h3>
        <ul>
          <li>Redesigned token sending and add assets flow</li>
          <li>Support for new version of Flow Ledger app</li>
          <li>Fetch Flow NFTs from NFT catalog</li>
          <li>Validate Flow addresses against blockchain</li>
          <li>Added more fields for searching tokens in Portfolio</li>
          <li>Added notifications button to profile dropdown</li>
          <li>Highlight selected period on price chart</li>
        </ul>
        <h3>Fixes:</h3>
        <ul>
          <li>Unify NFT name labels for Cardano</li>
          <li>Fixed empty thumbnails in NFT gallery</li>
          <li>Fixed sending of Flow NFTs with optional ‘from’ address</li>
          <li>Improve handling of no accounts in DApp connector</li>
        </ul>
      </div>
    ),
  },
  {
    appVersion: '2.4.0',
    date: new Date('2022-09-30'),
    heading: 'Release 2.4.0',
    body: (
      <div>
        <h3>New features:</h3>
        <ul>
          <li>
            The extension acts as Flint (Cardano) or Phantom (Solana) wallets by
            default, making the NuFi extension usable even with DApps that don't
            support it directly
          </li>
          <li>Send multiple Solana tokens at once</li>
          <li>Search for NFTs in the NFT gallery</li>
          <li>See the amount of each NFT in the NFT gallery</li>
          <li>Check profile syncing status in Settings</li>
        </ul>
        <h3>Fixes:</h3>
        <ul>
          <li>Fixed alphabetical sorting of Cardano assets</li>
          <li>Fixed asset exchanging to external addresses</li>
        </ul>
      </div>
    ),
  },
  {
    appVersion: '2.3.0',
    date: new Date('2022-09-09'),
    heading: 'Project Catalyst Voting & Release 2.3.0',
    body: (
      <div>
        <ul>
          <li>
            Project Catalyst voting has started: Vote for NuFi's proposal{' '}
            <a
              target="_blank"
              href="https://cardano.ideascale.com/c/idea/419644"
            >
              'Ethereum and Cardano Web3 Wallet'
            </a>{' '}
            (in The Great Migration from Ethereum challenge) before September
            19th.
          </li>
          <li>
            NuFi's proposal will help projects migrate to Cardano by making the
            cross-chain experience easy: you'll be able to manage Ethereum,
            Milkomeda and Cardano accounts side by side, migrate assets between
            chains, and connect to DApps on each chain simultaneously using just
            your NuFi wallet.
          </li>
        </ul>
        <h3>New features:</h3>
        <ul>
          <li>
            A wallet's accounts and settings will now be synchronized across
            devices
          </li>
          <li>Flow transaction history is now available directly in the app</li>
          <li>You can now hide your balances thanks to "Privacy mode"</li>
          <li>Added Solana staking rewards to CSV export</li>
        </ul>
        <h3>Fixes:</h3>
        <ul>
          <li>
            Fixed slow loading of wallets with a large amount (100+) of tokens
          </li>
        </ul>
      </div>
    ),
  },
  {
    appVersion: '2.2.5',
    date: new Date('2022-08-18'),
    heading: 'Release 2.2.5',
    body: (
      <div>
        <h3>New features:</h3>
        <ul>
          <li>Import Flow NFT collections and collectibles from NFT gallery</li>
          <li>
            Added support for ADA Handle's human-readable wallet addresses
          </li>
        </ul>
        <h3>Fixes:</h3>
        <ul>
          <li>
            Fixed signing transactions with NFTs for HW wallets on jpg.store
          </li>
          <li>Fixed issue when sending all Cardano tokens out of the wallet</li>
        </ul>
      </div>
    ),
  },
  {
    appVersion: '2.2.3',
    date: new Date('2022-08-04'),
    heading: 'Release 2.2.3',
    body: (
      <div>
        <h3>New features:</h3>
        <ul>
          <li>
            Explorer link added to 'Transaction Status' for completed/pending
            transactions
          </li>
        </ul>
        <h3>Fixes:</h3>
        <ul>
          <li>Fixed crashing sending of unregistered Cardano tokens</li>
          <li>Fixed Flow NFT collection setup to work with other wallets</li>
        </ul>
      </div>
    ),
  },
  {
    appVersion: '2.2.2a',
    date: new Date('2022-08-02'),
    heading: 'Vote for NuFi in Project Catalyst',
    body: (
      <div>
        <ul>
          <li>
            Register to vote in Project Catalyst before August 4th and support
            NuFi’s proposal: "Ethereum and Cardano Web3 Wallet"
          </li>
          <li>
            Full details and how to register{' '}
            <a
              target="_blank"
              href="https://nufi-official.medium.com/catalyst-voting-starts-soon-e119f740b080"
            >
              here
            </a>
          </li>
        </ul>
      </div>
    ),
  },
  {
    appVersion: '2.2.1',
    date: new Date('2022-07-21'),
    heading: 'Release 2.2.1',
    body: (
      <div>
        <h3>
          Are you a Ledger Nano S user? Please take five minutes to complete{' '}
          <a target="_blank" href="https://iohk.link/NuFi">
            this survey
          </a>{' '}
          to help us improve the user experience of the{' '}
          <b>Cardano app on Ledger</b>.
        </h3>

        <h3>New features:</h3>
        <ul>
          <li>
            Search assets by name and filter by blockchain in the Portfolio
            screen
          </li>
          <li>Simpler creation of a new wallet for hardware wallet users</li>
        </ul>
        <h3>Fixes:</h3>
        <ul>
          <li>Limited decimals used in Exchange for supported blockchains</li>
          <li>Speeded up Solana accounts discovery</li>
          <li>Fixed Chrome extension connector window sometimes not opening</li>
          <li>
            Fixed crashing when viewing asset detail page with no Flow accounts
          </li>
          <li>
            Fixed crashing exchange for assets temporarily disabled by Changelly
          </li>
        </ul>
      </div>
    ),
  },
  {
    appVersion: '2.2.0',
    date: new Date('2022-07-07'),
    heading: 'Release 2.2.0',
    body: (
      <div>
        <h3>NuFi Chrome extension</h3>
        <ul>
          <li>Chrome extension with Cardano DApp connector now available</li>
          <li>
            Connect to DApps like NFT marketplaces, DEXes and more using your
            NuFi wallet
          </li>
          <li>
            Full details and instructions{' '}
            <a target="_blank" href={externalLinks.chromeExtensionInfo}>
              here
            </a>
          </li>
        </ul>
        <h3>New features:</h3>
        <ul>
          <li>
            Filter assets by blockchain, plus hide NFTs and zero balances in
            Portfolio
          </li>
          <li>
            Added fiat values of Cardano tokens (data provided by WingRiders)
          </li>
          <li>Added epoch information to My Staking screen</li>
          <li>Added farm liquidity column to WingRiders DEX farms</li>
          <li>Enabled profile overwriting when restoring from backup file</li>
        </ul>
        <h3>Fixes:</h3>
        <ul>
          <li>Improved asset detail screen with a more compact design</li>
          <li>Improved UI when sending Solana NFTs</li>
          <li>Improved formatting for tokens with large quantities</li>
        </ul>
      </div>
    ),
  },
  {
    appVersion: '2.1.0',
    date: new Date('2022-06-07'),
    heading: 'Release 2.1.0',
    body: (
      <div>
        <h3>New features:</h3>
        <ul>
          <li>
            Send multiple Cardano assets (tokens and NFTs) in a single
            transaction
          </li>
          <li>Buy and sell crypto using card/bank transfer (via Moonpay)</li>
          <li>Use a Ledger hardware wallet via USB on Android devices</li>
          <li>Export transaction history for Solana</li>
        </ul>
        <h3>Fixes:</h3>
        <ul>
          <li>
            Fixed certain cases of the app crashing after transaction submission
          </li>
          <li>UI improvements on Assets screen</li>
        </ul>
      </div>
    ),
  },
  {
    appVersion: '2.0.2',
    date: new Date('2022-05-25'),
    heading: 'Release 2.0.2',
    body: (
      <div>
        <h3>Fixes:</h3>
        <ul>
          <li>Improved Solana staking history loading speed and stability</li>
          <li>Fixed importing of tokens</li>
          <li>
            Fixed Solana NFT Gallery and showing of NFTs on Portfolio screen
          </li>
        </ul>
      </div>
    ),
  },
  {
    appVersion: '2.0.0',
    date: new Date('2022-05-10'),
    heading: 'Release 2.0.0',
    body: (
      <div>
        <h3>New features:</h3>
        <ul>
          <li>Flow blockchain integration</li>
          <ul>
            <li>Send and receive $FLOW and $USDC/$FUSD stablecoins</li>
            <li>
              Store and stake $FLOW using Ledger and non-hardware accounts
            </li>
            <li>Visually manage Flow NFTs using the NFT gallery</li>
          </ul>
          <li>Solana NFT gallery to visually manage Solana NFTs</li>
          <li>Import tokens and display them in your Assets and Accounts</li>
        </ul>
        <h3>Fixes:</h3>
        <ul>
          <li>Fixed bug when staking MAX amount in Solana staking accounts</li>
          <li>
            UI improvements (inc. badges added to distinguish NFTs in Assets
            list)
          </li>
          <li>Improved feedback messages during WingRiders DEX transactions</li>
        </ul>
      </div>
    ),
  },
  {
    appVersion: '1.6.1',
    date: new Date('2022-04-14'),
    heading: 'Release 1.6.1',
    body: (
      <div>
        <h3>New features:</h3>
        <ul>
          <li>Wingriders DEX</li>
          <ul>
            <li>
              Gain on fees by farming on WingRiders DEX from inside the NuFi
              app!
            </li>
            <li>DEX-related transaction history is now visible</li>
          </ul>
          <li>
            Added support for displaying values in 17 more fiat currencies:
          </li>
          <ul>
            <li>
              Added TRY, BRL and MXN + 14 more, see all in wallet Settings
            </li>
          </ul>
        </ul>
        <h3>Fixes:</h3>
        <ul>
          <li>
            Fixed issue when exchanging from ADA using 'My account' option
          </li>
          <li>Percentage of ADA staked is now displayed correctly</li>
          <li>
            Cardano NFTs with missing name in metadata now shown in NFT gallery
          </li>
        </ul>
      </div>
    ),
  },
  {
    appVersion: '1.6.0',
    date: new Date('2022-04-12'),
    heading: 'Release 1.6.0',
    body: (
      <div>
        <h3>New features:</h3>
        <ul>
          <li>WingRiders DEX is now live on mainnet!</li>
          <ul>
            <li>Seamless swaps of Cardano native tokens and ERC-20 assets</li>
            <li>
              Gain on fees by creating liquidity pools or providing liquidity to
              the existing ones
            </li>
            <li>
              All your ADA in pools is automatically staked so you won't lose
              out on staking rewards
            </li>
            <li>
              Directly accessible farming and hardware wallet support coming
              soon to NuFi
            </li>
          </ul>
        </ul>
      </div>
    ),
  },
  {
    appVersion: '1.5.1',
    date: new Date('2022-04-01'),
    heading: 'Release 1.5.1',
    body: (
      <div>
        <h3>Fixes:</h3>
        <ul>
          <li>
            Fix edge case in NFT gallery causing some NFTs to be duplicated
          </li>
          <li>Other minor UI fixes</li>
        </ul>
      </div>
    ),
  },
  {
    appVersion: '1.5.0',
    date: new Date('2022-03-29'),
    heading: 'Release 1.5.0',
    body: (
      <div>
        <h3>New features:</h3>
        <ul>
          <li>
            Show the quantity of an owned NFT and add ability to send multiples
            of it
          </li>
          <li>
            Add social media icons to the footer of the page for easier access
            to community channels
          </li>
          <li>Wingriders DEX testnet</li>
          <ul>
            <li>Upgrade DEX version</li>
            <li>
              Continue using your wallet while transactions are submitting
            </li>
            <li>Add token to token liquidity pool and swap functionality</li>
            <li>Enable reclaims of DEX requests before their deadline</li>
          </ul>
        </ul>
        <h3>Fixes:</h3>
        <ul>
          <li>Speed up profile login</li>
          <li>
            Show an appropriate error message when attempting to send SOL tokens
            with insufficient funds
          </li>
          <li>Fix broken imported profile name encoding on macOS</li>
          <li>Fix staking key hash hex inconsistency with Adalite</li>
        </ul>
      </div>
    ),
  },
  {
    appVersion: '1.4.0',
    date: new Date('2022-03-18'),
    heading: 'Release 1.4.0',
    body: (
      <div>
        <h3>New features:</h3>
        <ul>
          <li>
            Continue using your wallet while transactions are submitting
            <ul>
              <li>
                Submit transaction, then continue without waiting for result
              </li>
              <li>Track transaction status in the top bar</li>
            </ul>
          </li>
          <li>Ability to export Cardano transaction history</li>
          <li>Show names of existing accounts when adding a new one</li>
        </ul>
        <h3>Fixes:</h3>
        <ul>
          <li>Fix the issue with sending Solana tokens to empty accounts</li>
          <li>Enable 'Show account history' option on the accounts screen</li>
        </ul>
      </div>
    ),
  },
  {
    appVersion: '1.3.0',
    date: new Date('2022-03-10'),
    heading: 'Release 1.3.0',
    body: (
      <div>
        <h3>New features:</h3>
        <ul>
          <li>
            In-app crypto-to-crypto exchange
            <ul>
              <li>Trade 100s of cryptocurrencies directly in the NuFi app</li>
              <li>
                Deposit supported cryptocurrencies directly into your NuFi
                wallet
              </li>
              <li>
                {
                  "Access it via Portfolio > 'Exchange' tab, or Assets list > 'Exchange' button"
                }
              </li>
            </ul>
          </li>
        </ul>
        <h3>Fixes:</h3>
        <ul>
          <li>
            Fix 'Manage account' popup overflowing when scrolled to the bottom
            of the page
          </li>
        </ul>
      </div>
    ),
  },
  {
    appVersion: '1.2.0',
    date: new Date('2022-03-03'),
    heading: 'Release 1.2.0',
    body: (
      <div>
        <h3>New features:</h3>
        <ul>
          <li>
            Introduce 'hardware wallet only' mode in which:
            <ul>
              <li>No new accounts will be created automatically</li>
              <li>
                Users can skip recovery phrase confirmation (can be done later
                if needed)
              </li>
            </ul>
          </li>
        </ul>
        <h3>Fixes:</h3>
        <ul>
          <li>Fix crashes for some browser locales</li>
          <li>Add warning about incognito mode before accessing a wallet</li>
          <li>
            Fix incorrect matching of Solana staking accounts to their parent
            accounts
          </li>
        </ul>
      </div>
    ),
  },
  {
    appVersion: '1.1.6',
    date: new Date('2022-02-23'),
    heading: 'Release 1.1.6',
    body: (
      <div>
        <h3>New features:</h3>
        <ul>
          <li>
            Ability to forget/hide any account you don't want to display (from
            Accounts tab, in 'Manage account' menu)
          </li>
          <li>Introduce plain text mode for recovery phrase confirmation</li>
        </ul>
        <h3>Fixes:</h3>
        <ul>
          <li>NFT gallery selection works when selecting lots of NFTs</li>
          <li>
            Fix Stake "MAX" amount for Solana to withhold a portion of funds for
            future transaction fees
          </li>
          <li>
            Loading indicator is always spinning when something is loading in
            the background
          </li>
          <li>
            Activation of Solana stake account does not lead to infinite loading
          </li>
          <li>Cardano account is properly pre-selected when sending</li>
          <li>
            Prevent scientific numbers notation when hitting "MAX" button in
            modals
          </li>
        </ul>
      </div>
    ),
  },
  {
    appVersion: '1.1.5',
    date: new Date('2022-02-08'),
    heading: 'Release 1.1.5',
    body: (
      <div>
        <h3>New features:</h3>
        <ul>
          <li>Added link to validator information page during staking setup</li>
        </ul>
        <h3>Fixes:</h3>
        <ul>
          <li>NFT gallery now displays large numbers of NFTs correctly</li>
          <li>NFT gallery now doesn't display registered fungible tokens</li>
        </ul>
      </div>
    ),
  },
]
