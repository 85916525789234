import type BigNumber from 'bignumber.js'
import React from 'react'

import {tokenBlockchains} from '../../blockchainTypes'
import type {Blockchain, TokenMetadata} from '../../types'
import {BlockchainSubsetGuard} from '../../utils/blockchainGuards'
import {useGetCoinName} from '../../utils/translations'
import {AmountVisibilityWrapper} from '../visual'

import {
  FormattedNativeAmount,
  FormattedTokenAmount,
} from './FormattedAssetAmount'
import type {NumberFormat} from './FormattedAssetAmount'
import {FormattedTokenLabel} from './FormattedTokenLabel'

type FormattedAssetCommonProps = {
  amount?: BigNumber
  blockchain: Blockchain
  includeAssetSymbol?: boolean
  prefix?: string
  hideNonRegistered?: boolean
  // TODO(NFT): this is a quick fix for handling NFT(s)
  // Ideally we would pre-fetch all NFT(s) info and this component
  // would just load it using `useGetNft` hook.
  // Now we want to avoid turning NFT backend down so handled "on-demand"
  // using `hideAmount` prop
  hideAmount?: boolean
  isSensitiveInformation: boolean
  amountFormat?: NumberFormat
}

type FormattedNativeAssetProps = FormattedAssetCommonProps & {
  tokenMetadata?: null
}

type FormattedTokenAssetProps = FormattedAssetCommonProps & {
  tokenMetadata: TokenMetadata
  hideNonRegistered?: boolean
}

type FormattedAssetProps = FormattedTokenAssetProps | FormattedNativeAssetProps

const FormattedNativeAsset = ({
  amount,
  blockchain,
  amountFormat,
  includeAssetSymbol,
  prefix,
  hideAmount,
  isSensitiveInformation,
}: FormattedAssetCommonProps) => {
  const getCoinName = useGetCoinName()

  return (
    <>
      {prefix}{' '}
      {!hideAmount && amount ? (
        <FormattedNativeAmount
          blockchain={blockchain}
          value={amount}
          isSensitiveInformation={isSensitiveInformation}
          format={amountFormat}
        />
      ) : (
        ''
      )}{' '}
      {includeAssetSymbol ? getCoinName(blockchain) : ''}
    </>
  )
}

const FormattedTokenAsset = ({
  amount,
  blockchain,
  amountFormat,
  includeAssetSymbol,
  prefix,
  hideAmount,
  hideNonRegistered,
  isSensitiveInformation,
  tokenMetadata,
}: FormattedTokenAssetProps) => {
  return (
    <BlockchainSubsetGuard
      blockchain={blockchain}
      blockchainSubset={tokenBlockchains}
    >
      {(tokenBlockchain) => (
        <>
          {prefix}{' '}
          {!hideAmount && amount ? (
            <FormattedTokenAmount
              isSensitiveInformation={isSensitiveInformation}
              blockchain={tokenBlockchain}
              value={amount}
              tokenInfo={tokenMetadata}
              format={amountFormat}
            />
          ) : (
            ''
          )}{' '}
          {includeAssetSymbol && (
            <FormattedTokenLabel
              blockchain={tokenBlockchain}
              tokenMetadata={tokenMetadata}
              hideNonRegistered={hideNonRegistered}
            />
          )}
        </>
      )}
    </BlockchainSubsetGuard>
  )
}

export const FormattedAsset = ({
  amount,
  blockchain,
  amountFormat,
  prefix = '',
  includeAssetSymbol = true,
  hideNonRegistered,
  hideAmount = false,
  isSensitiveInformation,
  tokenMetadata,
}: FormattedAssetProps) => {
  return (
    <AmountVisibilityWrapper isSensitiveInformation={isSensitiveInformation}>
      {tokenMetadata ? (
        <FormattedTokenAsset
          amount={amount}
          tokenMetadata={tokenMetadata}
          blockchain={blockchain}
          amountFormat={amountFormat}
          includeAssetSymbol={includeAssetSymbol}
          prefix={prefix}
          hideAmount={hideAmount}
          hideNonRegistered={hideNonRegistered}
          isSensitiveInformation={isSensitiveInformation}
        />
      ) : (
        <FormattedNativeAsset
          amount={amount}
          blockchain={blockchain}
          amountFormat={amountFormat}
          includeAssetSymbol={includeAssetSymbol}
          prefix={prefix}
          hideAmount={hideAmount}
          isSensitiveInformation={isSensitiveInformation}
        />
      )}
    </AmountVisibilityWrapper>
  )
}
