import {isHwVendor} from '@nufi/wallet-common'
import create from 'zustand'

import type {
  AuthState,
  LoginInfo,
  Web3AuthUserInfo,
} from 'src/features/login/domain'

import {getMockServices} from '../__tests__/storybook/MockServiceLocator'
import config from '../config'
import type {AccountInfo, HotVendor} from '../types'
import {assert, safeAssertUnreachable} from '../utils/assertion'

import logger from './logger'

type State = {
  authState: AuthState
  setRegularLoggedIn: () => void
  setMetamaskLoggedIn: () => void
  setWeb3AuthLoggedIn: (info: Web3AuthUserInfo) => void
}

export const useAuthStore = create<State>(
  logger<State>('AuthState:')((set) => ({
    authState: {
      status: config.autoLogin ? 'initializing' : 'logged_out',
    },
    setRegularLoggedIn: () =>
      set({authState: {status: 'logged_in', info: {loginType: 'password'}}}),
    setMetamaskLoggedIn: () =>
      set({authState: {status: 'logged_in', info: {loginType: 'metamask'}}}),
    setWeb3AuthLoggedIn: (web3AuthUser: Web3AuthUserInfo) =>
      set({
        authState: {
          status: 'logged_in',
          info: {loginType: 'web3Auth', user: web3AuthUser},
        },
      }),
  })),
)

export const useCurrentLoginInfo = (): LoginInfo => {
  const mockServices = getMockServices()
  if (mockServices) {
    return mockServices.useCurrentLoginInfo()
  }
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const {authState} = useAuthStore()

  assert(authState.status === 'logged_in')
  return authState.info
}
export type UseCurrentLoginInfo = typeof useCurrentLoginInfo

export function useGetCurrentHotVendor() {
  const {loginType} = useCurrentLoginInfo()
  const hotVendor: HotVendor = (() => {
    switch (loginType) {
      case 'password':
        return 'mnemonic' as const
      case 'web3Auth':
        return 'mnemonic' as const
      case 'metamask':
        return 'metamask' as const
      default:
        return safeAssertUnreachable(loginType)
    }
  })()
  return hotVendor
}

export const useIsPasswordVerificationRequired = (
  account: AccountInfo | null,
): boolean => {
  const loginInfo = useCurrentLoginInfo()

  return !(
    loginInfo.loginType === 'web3Auth' ||
    loginInfo.loginType === 'metamask' ||
    account == null ||
    isHwVendor(account.cryptoProviderType)
  )
}

logger.log('AuthState:', useAuthStore.getState())
