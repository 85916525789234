import BigNumber from 'bignumber.js'
import _ from 'lodash'

import {assert} from '../../utils/assertion'
import type {AccountName, AccountInfo, AccountId, Blockchain} from '../types'

export const DEFAULT_DISCOVERY_QUERY_OPTIONS = {
  gcTime: 0,
  refetchOnWindowFocus: false,
} as const

export function sumAccountInfoBalances<T extends AccountInfo>(
  accounts: Array<T>,
): T['balance'] {
  return accounts.reduce<T['balance']>(
    (res, a) => res.plus(a.balance) as T['balance'],
    new BigNumber(0) as T['balance'],
  )
}

export function hoursToMilliseconds(hours: number): number {
  return 1000 * 60 * 60 * hours
}

export function minutesToMilliseconds(minutes: number): number {
  return 1000 * 60 * minutes
}

export function secondsToMilliseconds(seconds: number): number {
  return 1000 * seconds
}

export function sortAccounts<T extends {name: AccountName}>(
  accounts: T[],
): T[] {
  return _.orderBy<T>(accounts, [(a): string => a.name.toLowerCase()], 'asc')
}

export function findAccountByAddress(
  accounts: AccountInfo[],
  address: string,
  blockchain: Blockchain,
): AccountInfo | undefined {
  return accounts.find(
    (a) => a.address === address && a.blockchain === blockchain,
  )
}

export function ensureAccountByAddress(
  accounts: AccountInfo[],
  address: string,
  blockchain: Blockchain,
): AccountInfo {
  const account = findAccountByAddress(accounts, address, blockchain)
  assert(
    account != null,
    `ensureAccountByAddress: Account with address: ${address} (${blockchain}) not found`,
  )
  return account
}

export function findAccountById<T extends Pick<AccountInfo, 'id'>>(
  accounts: T[],
  id: AccountId,
): T | undefined {
  return accounts.find((a) => a.id === id)
}

export function ensureAccountById<T extends Pick<AccountInfo, 'id'>>(
  accounts: Array<T>,
  id: AccountId,
): T {
  const account = findAccountById(accounts, id)
  assert(account != null, `ensureAccountById: Account with id: ${id} not found`)
  return account
}

export function findAccountByIdAndBlockchain<
  T extends Pick<AccountInfo, 'id' | 'blockchain'>,
>(accounts: T[], blockchain: Blockchain, id: AccountId): T | undefined {
  return accounts.find((a) => a.id === id && a.blockchain === blockchain)
}

export function ensureAccountByIdAndBlockchain<
  T extends Pick<AccountInfo, 'id' | 'blockchain'>,
>(accounts: Array<T>, blockchain: Blockchain, id: AccountId): T {
  const account = findAccountByIdAndBlockchain(accounts, blockchain, id)
  assert(
    account != null,
    `ensureAccountByIdAndBlockchain: Account with id: ${id} and blockchain: ${blockchain} not found`,
  )
  return account
}

export function getDefaultAccountId<
  A extends Pick<AccountInfo, 'id' | 'isDefault'>,
>(accounts: A[]): AccountId | undefined {
  const defaultAccounts = accounts.filter((a) => a.isDefault)
  assert(
    defaultAccounts.length <= 1,
    'There must be always max 1 default account',
  )
  return defaultAccounts.length > 0 ? defaultAccounts[0]!.id : undefined
}
