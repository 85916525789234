import {safeAssertUnreachable} from '@nufi/frontend-common'
import BigNumber from 'bignumber.js'

import {
  createNumberFormatter,
  isGreaterThanMaxSafeInt,
  valueDividedByDecimals,
} from 'src/utils/formatting'

export type NumberFormat = 'full' | 'compact'

// remove leading and trailing zeros
// https://stackoverflow.com/questions/50317500/remove-extra-zeros-from-a-string/50318041#50318041
const removeExtraZeros = (value: string) =>
  value.replace(/^0+(?!\.)|(?:\.|(\..*?))0+$/gm, '$1')

export type FormattedNumberParams = {
  value: BigNumber
  decimals: number
} & FormattedNumberVariantParams

export type FormattedNumberVariantParams =
  | {
      format: Extract<NumberFormat, 'full'>
      minimumFractionDigits: number
      maximumFractionDigits: number
    }
  | {
      format: Extract<NumberFormat, 'compact'>
    }

const compactFormatNumber = ({
  value,
  decimals,
}: Omit<
  Extract<FormattedNumberParams, {format: 'compact'}>,
  'format'
>): string => {
  const decimalValue = valueDividedByDecimals(value, decimals)

  const getFormatter = (maximumFractionDigits: number) => {
    return createNumberFormatter({
      maximumFractionDigits,
      minimumFractionDigits: 0,
      // Rounding inspired by LedgerLive.
      // TS does not know this property
      // eslint-disable-next-line
      // @ts-ignore
      roundingMode: 'floor',
    })
  }

  if (isGreaterThanMaxSafeInt(decimalValue)) {
    return removeExtraZeros(decimalValue.toFormat(0))
  }

  if (decimalValue.isZero()) return '0.00'

  if (decimalValue.isLessThan(new BigNumber(1))) {
    // Allow max 9 decimals
    if (decimalValue.isLessThan(new BigNumber(0.000_000_001))) {
      return '0.00000000..'
    }

    const decimalPart = decimalValue.minus(decimalValue.integerValue())
    const decimalPartString = decimalPart.toFixed().substring(2) // drop leading 0.

    const leadingZerosCount = (() => {
      let zeros = 0
      for (const v of decimalPartString) {
        if (v !== '0') {
          return zeros
        }
        zeros += 1
      }
      return zeros
    })()

    // Allow max 4 non-zero digits after leading zeros, but max 9 digits together
    const maximumFractionDigits = Math.min(leadingZerosCount + 4, 9)
    const numberFormatter = getFormatter(maximumFractionDigits)
    return numberFormatter.format(decimalValue.toNumber())
  }

  // single non-zero digit on the left
  if (decimalValue.isLessThan(new BigNumber(10))) {
    return getFormatter(4).format(decimalValue.toNumber())
  }
  // [2, 8) digits on the left
  if (decimalValue.isLessThan(new BigNumber(10_000_000))) {
    return getFormatter(2).format(decimalValue.toNumber())
  }

  // 8+ digits on the left
  return getFormatter(0).format(decimalValue.toNumber())
}

const fullFormatNumber = ({
  value,
  decimals,
  maximumFractionDigits,
  minimumFractionDigits,
}: Omit<
  Extract<FormattedNumberParams, {format: 'full'}>,
  'format'
>): string => {
  const decimalValue = valueDividedByDecimals(value, decimals)

  if (isGreaterThanMaxSafeInt(decimalValue)) {
    return removeExtraZeros(decimalValue.toFormat(0))
  } else {
    const numberFormatter = createNumberFormatter({
      minimumFractionDigits,
      maximumFractionDigits,
      useGrouping: true,
    })
    return numberFormatter.format(decimalValue.toNumber())
  }
}

export const formatNumber = ({
  value,
  decimals,
  ...rest
}: FormattedNumberParams): string => {
  const {format} = rest
  switch (format) {
    case 'full':
      return fullFormatNumber({
        value,
        decimals,
        maximumFractionDigits: rest.maximumFractionDigits,
        minimumFractionDigits: rest.minimumFractionDigits,
      })
    case 'compact':
      return compactFormatNumber({
        value,
        decimals,
      })
    default:
      return safeAssertUnreachable(format)
  }
}
