import {useQuery} from '@tanstack/react-query'

import {blowfishClient} from './blowfish'

export const useDomainScan = (origin: string | null) =>
  useQuery({
    queryKey: ['scanDomain'],
    queryFn: async () => {
      if (origin) {
        const scanResults = await blowfishClient.scanDomains([origin])
        const domainData = scanResults?.find(
          ({domain}) => domain === origin || origin.includes(domain),
        )
        return domainData?.warnings || null
      }
      return null
    },
    enabled: !!origin,
    staleTime: Infinity,
  })
