import React from 'react'
import {useTranslation} from 'react-i18next'

import {InlineError} from 'src/components'
import {Failure} from 'src/pages/utils/Failure'

import {HelpSection, gridPlusImages} from './common'

type FailureProps = {
  onRetry?: () => Promise<unknown>
  error: unknown
}

const getCommonProps = () => ({
  Image: gridPlusImages.error,
  HelpSection: <HelpSection severity="error" />,
})

export function GridPlusFailedSign(props: FailureProps) {
  const {t} = useTranslation()
  return (
    <>
      <Failure
        {...props}
        message={t('Could not sign the transaction.')}
        {...getCommonProps()}
      />
      <InlineError error={props.error} />
    </>
  )
}

export function GridPlusFailedExport(props: FailureProps) {
  const {t} = useTranslation()
  return (
    <>
      <Failure
        {...props}
        message={t('Could not export GridPlus keys.')}
        {...getCommonProps()}
      />
      <InlineError error={props.error} />
    </>
  )
}
