import type {StorageDriver} from '@nufi/storage'
import {ContentStore, StoreManager, EncryptionType} from '@nufi/storage'
import type {IdentitySecret} from '@nufi/wallet-common'

import type {Blockchain} from 'src/wallet/types'

import {getAppVersion} from '../../utils/appVersion'
import {safeAssertUnreachable} from '../../utils/assertion'
import {StoreType} from '../constants'
import type {LocalProfileId, ProfileMigrations} from '../sharedTypes'

import {DEFAULT_PROFILE_DATA} from './constants'
import {isProfileData} from './guards'
import {ProfileManager, profileManagerLocator} from './profileManager'
import type {
  ProfileData,
  ProfileDataEncryptionKey,
  ProfileStoragePath,
} from './types'
import {deriveKey} from './utils'

const deriveProfileDataEncryptionKey = async (
  identitySecret: IdentitySecret,
): Promise<ProfileDataEncryptionKey> => {
  const buffer = Buffer.from(identitySecret, 'hex')
  return deriveKey(buffer)
}

const profileIdToProfileStoragePath = (
  profileId: LocalProfileId,
): ProfileStoragePath => `profile-${profileId}` as ProfileStoragePath

type InitParams =
  | {action: 'login'}
  | {
      action: 'create-profile'
      enabledBlockchains: readonly Blockchain[]
    }

export class RemoteProfileManager extends ProfileManager {
  private identitySecret: IdentitySecret

  constructor(
    profileStoreManager: StoreManager<ProfileData, ProfileDataEncryptionKey>,
    identitySecret: IdentitySecret,
  ) {
    super('remote', profileStoreManager)
    this.identitySecret = identitySecret
  }

  override init = async (params: InitParams): Promise<void> => {
    const encryptionKey = await deriveProfileDataEncryptionKey(
      this.identitySecret,
    )
    this.setProfileDataEncryptionKey(encryptionKey)

    const profileStoreParams = (() => {
      const action = params.action
      switch (action) {
        case 'create-profile':
          return {
            password: encryptionKey,
            initialData: {
              ...DEFAULT_PROFILE_DATA,
              settings: {
                ...DEFAULT_PROFILE_DATA.settings,
                isHwUser: false,
                isMnemonicActivated: false,
                enabledBlockchains: params.enabledBlockchains,
              },
            },
          }
        case 'login':
          return {password: encryptionKey, initialData: null}
        default:
          return safeAssertUnreachable(action)
      }
    })()

    await this.profileStoreManager.open(profileStoreParams)
  }

  getIdentitySecret = (): IdentitySecret => this.identitySecret

  override removeProfile = async (): Promise<void> => {
    await this.profileStoreManager.removeContent()
  }
}

export const isRemoteProfileManager = (
  profileManager: ProfileManager,
): profileManager is RemoteProfileManager => {
  return profileManager.mnemonicStorageType === 'remote'
}

export const remoteProfileManagerLocator = {
  instance: (): RemoteProfileManager => {
    const instance = profileManagerLocator.instance() as RemoteProfileManager
    if (isRemoteProfileManager(instance)) return instance
    throw new Error('ProfileManager is not remote profile manager')
  },
  create: (
    storageDriver: StorageDriver,
    profileId: LocalProfileId,
    identitySecret: IdentitySecret,
    migrations: ProfileMigrations = {mnemonic: {}, profileData: {}},
  ): RemoteProfileManager => {
    const profileStoreManager = new StoreManager({
      typeGuard: isProfileData,
      store: new ContentStore({
        storageKey: profileIdToProfileStoragePath(profileId),
        storageDriver,
      }),
      migrations: migrations.profileData,
      appVersion: getAppVersion(),
      storeType: StoreType.UserProfileData,
      encryptionType: EncryptionType.Aes256,
    })

    profileManagerLocator.init(
      new RemoteProfileManager(profileStoreManager, identitySecret),
    )
    return profileManagerLocator.instance() as RemoteProfileManager
  },
  destroy: () => {
    profileManagerLocator.destroy()
  },
}
