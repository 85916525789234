import {Box, Link as MuiLink, Typography} from '@mui/material'
import React from 'react'
import {Trans, useTranslation} from 'react-i18next'

import config from '../../config'

import {useCommonStyles} from './utils'

export default function Web3Auth2Factor() {
  const {t} = useTranslation()
  const commonClasses = useCommonStyles()

  return (
    <Box className={commonClasses.sectionWrapper}>
      <Typography>
        <Trans
          i18nKey="web3auth_2fa_redirect_info"
          t={t}
          components={{
            Link: (
              <MuiLink
                href={
                  config.web3AuthNetworkV1 === 'mainnet'
                    ? 'https://app.openlogin.com/wallet/account'
                    : 'https://beta.openlogin.com/wallet/account'
                }
                target="_blank"
              />
            ),
            Link2: (
              <MuiLink
                href={
                  'https://support.nu.fi/support/solutions/articles/80001070617'
                }
                target="_blank"
              />
            ),
          }}
        />
      </Typography>
    </Box>
  )
}
