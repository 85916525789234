import {Box} from '@mui/material'
import type {
  FlowAddress,
  FlowTokenContractMetadata,
  FlowTokenId,
  FlowTokenMetadata,
  FlowTokenName,
} from '@nufi/wallet-flow'
import {getFlowContractId} from '@nufi/wallet-flow'
import {useFormikContext} from 'formik'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {
  Alert,
  Button,
  FormattedTokenName,
  QueryGuard,
  TokensError,
  useModalSharedStyles,
} from '../../../components'
import {useGetCoinName} from '../../../utils/translations'
import {useGetIsTokenSetupForAddress} from '../../../wallet/flow'
import type {ImportFlowTokenSchema} from '../../portfolio/account/actions/importToken/flow/schema'
import type {TokenOption} from '../../portfolio/account/actions/importToken/flow/types'
import {
  ReceiveModalAddress,
  ReceiveModalAssetName,
  ReceiveModalHeader,
} from '../Helpers'

type ReceiveTokenHeaderProps = {
  onClose: () => void
  tokenMetadata?: FlowTokenMetadata
  isNftView: boolean
}

export const ReceiveTokenHeader = ({
  onClose,
  isNftView,
  tokenMetadata,
}: ReceiveTokenHeaderProps) => {
  const getCoinName = useGetCoinName()
  const formikProps = useFormikContext<ImportFlowTokenSchema>()
  const {values} = formikProps
  const {t} = useTranslation()

  const AssetName =
    isNftView && !values.contractId ? (
      t('NFT')
    ) : tokenMetadata ? (
      <FormattedTokenName
        blockchain={'flow'}
        tokenInfo={tokenMetadata}
        ellipsize
        ellipsizeLength={25}
      />
    ) : (
      getCoinName('flow')
    )

  return (
    <ReceiveModalHeader
      tokenId={tokenMetadata?.id}
      onClose={onClose}
      blockchain={'flow'}
      AssetName={AssetName}
    />
  )
}

type ReceiveAddressOrImportTokenProps = {
  tokenMetadata: FlowTokenMetadata
  address: FlowAddress
}

export const ReceiveAddressOrImportToken = ({
  tokenMetadata,
  address,
}: ReceiveAddressOrImportTokenProps) => {
  const {t} = useTranslation()
  const classes = useModalSharedStyles()
  const isTokenSetupQuery = useGetIsTokenSetupForAddress({
    contractMetadata: tokenMetadata.contractMetadata,
    address,
    mustBeFullySetUp: true,
  })
  return (
    <QueryGuard
      ErrorElement={<TokensError />}
      {...isTokenSetupQuery}
      loadingVariant="centered"
    >
      {(isSetup) =>
        isSetup ? (
          <ReceiveModalAddress
            address={address}
            isToken
            blockchain={tokenMetadata.blockchain}
            assetName={
              <ReceiveModalAssetName
                blockchain={tokenMetadata.blockchain}
                tokenMetadata={tokenMetadata}
              />
            }
          />
        ) : (
          <>
            <Alert
              text={t(
                'In order to receive and fully use this token, it must be imported first.',
              )}
              severity="info"
              className={classes.commonBottomMargin}
            />
            <Box width="50%" margin="0 auto">
              <Button
                type="submit"
                textTransform="none"
                color="primary"
                variant="contained"
                fullWidth
              >
                {t('Import')}
              </Button>
            </Box>
          </>
        )
      }
    </QueryGuard>
  )
}

// Native FLOW token option used for lists of flow tokens which should include also the native token
export const FLOW_TOKEN_OPTION = {
  blockchain: 'flow',
  name: 'FLOW' as FlowTokenName,
  id: 'flow',
  tokenId: undefined,
} as const satisfies TokenOption

export const getTokenOptions = (
  tokenContracts: FlowTokenContractMetadata[],
  onlyNfts: boolean,
  includeFlowNativeAsset: boolean,
): TokenOption[] => {
  const tokenOptions = tokenContracts
    .filter((tc) => (onlyNfts ? !tc.isFungible : true))
    .map<TokenOption>((tc) => ({
      blockchain: 'flow',
      name: tc.collection.name as FlowTokenName,
      id: getFlowContractId(tc.contractAddress, tc.contractName),
      // we cast the contractId to tokenId since in the context of importing tokens, contractId is tokenId
      tokenId: getFlowContractId(
        tc.contractAddress,
        tc.contractName,
      ) as unknown as FlowTokenId,
    }))

  if (onlyNfts || !includeFlowNativeAsset) {
    return tokenOptions
  }

  return [FLOW_TOKEN_OPTION, ...tokenOptions]
}
