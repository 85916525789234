import {Grid, Box, Typography, TextField} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import type {FormikHelpers} from 'formik'
import {Formik} from 'formik'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import * as yup from 'yup'

import type {ProfileMetadata, ProfileName} from '../../appStorage'
import {useRenameCurrentProfile} from '../../appStorage'
import {Button, TextButton} from '../../components'
import {MAX_WALLET_NAME_LENGTH} from '../../constants'
import {getHasFormError, useVerifyPassword} from '../../utils/form'
import {profileNameExists} from '../profile/common'

import {useCommonStyles} from './utils'

type RenameProfileProps = {
  profiles: Array<ProfileMetadata>
}

export default function RenameProfile({profiles}: RenameProfileProps) {
  const {t} = useTranslation()
  const classes = useStyles()
  const commonClasses = useCommonStyles()

  const renameProfile = useRenameCurrentProfile()
  const verifyPassword = useVerifyPassword()
  const [showConfirmation, setShowConfirmation] = useState(false)

  const _formSchema = {
    name: yup
      .string()
      .test('unique-profile-name', t('Wallet name exists.'), (value) => {
        if (value === undefined) return true
        return !profileNameExists(value, profiles)
      })
      .test(
        'profile-name-length',
        t('wallet_name_max_length', {length: MAX_WALLET_NAME_LENGTH}),
        (value) => {
          if (value === undefined) return true
          return value.length <= MAX_WALLET_NAME_LENGTH
        },
      )
      .required(t('Name is required.')),
    password: yup.string().required(t('Password is required.')),
  }
  type FormField = keyof typeof _formSchema
  type FormData = {[k in FormField]: string}
  const formSchema = yup.object().shape(_formSchema)

  const initialValues: FormData = {
    name: '',
    password: '',
  }

  const onSubmit = async (
    values: FormData,
    formikHelpers: FormikHelpers<FormData>,
  ) => {
    const passwordVerified = await verifyPassword(
      values.password,
      formikHelpers,
    )
    if (!passwordVerified) return

    await renameProfile.mutateAsync({
      name: values.name as ProfileName,
    })
    setShowConfirmation(true)
  }

  if (showConfirmation) {
    return (
      <Box
        className={clsx(commonClasses.sectionWrapper, classes.section)}
        alignItems="center"
      >
        <Grid container spacing={1}>
          <Grid item>
            <Typography variant="body1">
              {t('Wallet was successfully renamed.')}
            </Typography>
          </Grid>
          <Grid item>
            <TextButton
              onClick={() => setShowConfirmation(false)}
              label={t('Continue')}
            />
          </Grid>
        </Grid>
      </Box>
    )
  }

  return (
    <Formik {...{initialValues, onSubmit}} validationSchema={formSchema}>
      {(formikProps) => {
        const {handleSubmit, values, errors, isSubmitting, handleChange} =
          formikProps

        const hasError = getHasFormError(formikProps)
        return (
          <Box className={clsx(commonClasses.sectionWrapper, classes.section)}>
            <form onSubmit={handleSubmit} noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    value={values.name}
                    onChange={handleChange<FormField>('name')}
                    label={t('Wallet name')}
                    error={hasError('name')}
                    helperText={hasError('name') && errors.name}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    data-test-id="rename-profile-password"
                    value={formikProps.values.password}
                    onChange={formikProps.handleChange<FormField>('password')}
                    label={t('Wallet password')}
                    error={getHasFormError(formikProps)('password')}
                    type="password"
                    helperText={
                      getHasFormError(formikProps)('password') &&
                      formikProps.errors.password
                    }
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    textTransform="none"
                    variant="contained"
                    type="submit"
                    color="secondary"
                    disabled={isSubmitting}
                  >
                    {t('Rename wallet')}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        )
      }}
    </Formik>
  )
}

const useStyles = makeStyles((theme) => ({
  section: {
    marginTop: theme.spacing(2),
  },
}))
